import Typography from "@ui/cdk/Typography";
import CircularProgress from "@ui/core/components/CircularProgress";
import {getDisplayValue} from "../utils";
import PauseCircleFilledRoundedIcon from "@material-ui/icons/PauseCircleFilledRounded";
import classes from "./styles/TimerProgress.module.scss";
import {cls} from "@ui/cdk/util";
import {useTranslation} from "react-i18next";

type TimerProgressProps = {
    value: number;
    progress: number;
    isPopup?: boolean;
    isPaused: boolean;
    isTimesUp: boolean;
};

function TimerProgress(props: TimerProgressProps) {
    const {value, progress, isPopup, isPaused, isTimesUp} = props;

    const {t} = useTranslation();

    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    const displayMinutes = getDisplayValue(minutes);
    const displaySeconds = getDisplayValue(seconds);

    return (
        <div className={classes.root}>
            <CircularProgress
                size={isPopup ? 130 : 240}
                thickness={isPopup ? 4 : 4}
                value={progress}
                variant="determinate"
                classes={{
                    circle: cls(classes.circle, isPopup && isPaused ? classes.circlePaused : ""),
                    svg: cls(classes.svg, isPopup ? classes.svgIsPopup : ""),
                }}
            />
            <div className={classes.info}>
                {isPopup && isPaused && (
                    <div className={classes.pausedInfo}>
                        <PauseCircleFilledRoundedIcon />
                        <Typography color="secondary" variant="xs" fontWeight="bold">
                            {t("g.paused")}
                        </Typography>
                    </div>
                )}

                <Typography variant={isPopup ? "xl4" : "xl6"} fontWeight="boldest">
                    {displayMinutes}:{displaySeconds}
                </Typography>

                {isPopup && isTimesUp && (
                    <Typography variant={isPopup ? "lg" : "xl"} fontWeight="bolder" noWrap>
                        {t("macro.timer.times_up")}
                    </Typography>
                )}
            </div>
        </div>
    );
}

export default TimerProgress;
