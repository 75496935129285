import {
    ConferenceParticipantStatus,
    ParticipantInviteStatus,
    ParticipantStatus,
    SessionSpeaker,
    SessionParticipantFragment,
    ParticipantSource,
    SpeakerDetailsFragment,
} from "@generated/data";
import * as Yup from "yup";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {linkedInRegex} from "../event-landing/utils";

function isValidLinkedInUrl() {
    return this.test({
        name: "isValidLinkedInUrl",
        message: "Please enter a valid LinkedIn URL",
        test: (value) => {
            if (value?.match(linkedInRegex) || !value || (value && value.trim().length === 0)) {
                return true;
            } else {
                return false;
            }
        },
    });
}

function isEmailUnique({eventSpeakerEmails}: NewSpeakerFormValidationSchemaArgs) {
    return this.test({
        name: "isEmailUnique",
        message: "Email address already in use",
        test: (value) => {
            return !eventSpeakerEmails.includes(value);
        },
    });
}

Yup.addMethod(Yup.string, "isValidLinkedInUrl", isValidLinkedInUrl);
Yup.addMethod(Yup.string, "isEmailUnique", isEmailUnique);

export type SpeakerDisplayType = {
    id: string;
    email: string | null;
    avatar: string | null | undefined;
    order?: number;
    token?: string;
    detailsComplete?: boolean;
} & Omit<SessionSpeaker, "participants" | "agendaItemSpeakers">;

type NewSpeakerFormValidationSchemaArgs = {
    eventSpeakerEmails: string[];
};

const maximumCharactersLength = 60;

export const newSpeakerFormSchema = {
    fields: [
        {
            title: "Full name",
            name: "name",
            typeField: "text",
            required: true,
            placeholder: "Name Surname",
        },
        {
            title: "Email address",
            name: "email",
            typeField: "email",
            required: true,
            placeholder: "name@email.com",
        },
        {
            title: "Company",
            name: "company",
            typeField: "text",
            required: false,
            placeholder: "Company name",
        },
        {
            title: "Job title",
            name: "jobTitle",
            typeField: "text",
            required: false,
            placeholder: "Product Lead",
        },
        {
            title: "Linkedin",
            name: "linkedInUrl",
            typeField: "text",
            required: false,
            placeholder: "https://www.linkedin.com/in/joedoe/",
        },
        {
            title: "Photo",
            name: "photoUrl",
            typeField: "text",
            required: false,
            placeholder: "Upload photo",
        },
        {
            title: "Speaker bio",
            name: "bio",
            typeField: "text",
            required: false,
            placeholder: "Tell us a little more about this speaker and their professional background.",
        },
    ],
    initialValues: {
        name: "",
        email: "",
        company: "",
        jobTitle: "",
        linkedInUrl: "",
        photoUrl: "",
        thumbnailUrl: "",
        photoFile: null as unknown as File | null,
        thumbnailFile: null as unknown as File | null,
        bio: "",
    },
    validationSchema: (args: NewSpeakerFormValidationSchemaArgs) =>
        Yup.object().shape({
            name: Yup.string()
                .trim()
                .required("This field is mandatory")
                .max(maximumCharactersLength, `Needs to a have maximum of ${maximumCharactersLength} characters`),
            email: Yup.string()
                .trim()
                .email("Please enter a valid email")
                .required("This field is mandatory")
                //@ts-ignore - Yup doesn't have a type for isEmailUnique which is a custom method
                .isEmailUnique(args),
            //@ts-ignore - Yup doesn't have a type for isValidLinkedInUrl which is a custom method
            linkedInUrl: Yup.string().isValidLinkedInUrl(),
        }),
};

export type SpeakersSortField = "name" | "order" | "email" | "status";
export type SpeakersSort = {
    field: SpeakersSortField;
    direction: "asc" | "desc";
};

export const defaultSpeakersSort: SpeakersSort = {
    field: "order",
    direction: "asc",
};

export function createSpeakersComparator(sort: SpeakersSort) {
    return (a: SpeakerDisplayType, b: SpeakerDisplayType) => {
        let result = 1;

        if (sort.field === "name" && a.name && b.name) {
            result = a.name.localeCompare(b.name);
        }

        //if sort.field === order and both a.order and b.order are numbers
        if (sort.field === "order" && typeof a.order === "number" && typeof b.order === "number") {
            result = a.order - b.order;
        }

        if (sort.field === "email" && a.email && b.email) {
            result = a.email.localeCompare(b.email);
        }
        if (sort.field === "status") {
            result = a.detailsComplete === b.detailsComplete ? 0 : a.detailsComplete ? -1 : 1;
        }

        const direction = sort.direction === "asc" ? 1 : -1;

        return result * direction;
    };
}

export type SpeakerParams = {
    name?: string;
    bio?: string;
    company?: string;
    email: string;
    jobTitle?: string;
    linkedInUrl?: string;
    photoUrl?: string;
    thumbnailUrl?: string;
    participantId?: string;
    id?: string;
    order?: number;
};

export type ParticipantWithSpeakerDetailsPayload = Omit<SessionParticipantFragment, "speakerDetails"> & {
    speakerDetails: Omit<SpeakerDetailsFragment, "participantId">;
};

export const makeParticipantSpeakerData = (
    email?: string,
    speakerDetails?: SpeakerParams,
    rbac?: any
): ParticipantWithSpeakerDetailsPayload => ({
    __typename: "Participant" as const,
    conferenceStatus: ConferenceParticipantStatus.Participant,
    dataWithNullableEmail: {
        email: email?.toLocaleLowerCase() ?? null,
        __typename: "ParticipantDataWithNullableEmail" as const,
        firstName: speakerDetails?.name ?? "",
        lastName: "",
        isGuest: false,
        token: undefined,
        avatar: "",
        userId: "",
    },
    isApproved: true,
    inviteStatus: ParticipantInviteStatus.NeedsAction,
    id: speakerDetails?.participantId ?? makeId(),
    isOwner: false,
    isRecorder: false,
    mockedParticipant: false,
    muted: false,
    rbac: rbac ?? {},
    status: ParticipantStatus.NotJoined,
    changedBy: null,
    statusChangedBy: null,
    speakerDetails: speakerDetails
        ? ({
              id: speakerDetails?.id ?? makeId(),
              name: speakerDetails.name ?? "",
              bio: speakerDetails?.bio ?? "",
              company: speakerDetails?.company ?? "",
              jobTitle: speakerDetails?.jobTitle ?? "",
              linkedInUrl: speakerDetails?.linkedInUrl ?? "",
              photoUrl: speakerDetails?.photoUrl ?? "",
              thumbnailUrl: speakerDetails?.thumbnailUrl ?? "",
          } as any)
        : null,
    askToStartCamera: false,
    askToUnmute: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    hasAttendedSession: false,
    invitedByTheOwner: true,
    source: ParticipantSource.App,
    submittedPasscode: true,
});

export const emptySpeakerDetailsObj = () => ({
    id: makeId(),
    name: "",
    company: "",
    jobTitle: "",
    linkedInUrl: "",
    photoUrl: "",
    thumbnailUrl: "",
    bio: "",
});

export function hasAllSpeakerDetails(speaker: SpeakerDisplayType) {
    const keysToCheck = ["firstName", "email", "bio", "company", "jobTitle", "linkedInUrl", "photoUrl"];

    const s = Object.keys(speaker)
        .filter((key) => keysToCheck.includes(key))
        .reduce((s, currKey) => ((s[currKey] = speaker[currKey]), s), {});

    for (const key in s) {
        if (!speaker[key]) {
            return false;
        }
    }
    return true;
}
