import {isToday, addMinutes, isTomorrow, format, isYesterday, setDayOfYear, getDayOfYear} from "date-fns";

export function alignDate(whichDate?: Date | null | undefined, withDate?: Date) {
    if (!whichDate || !withDate) {
        return whichDate;
    }
    let newDate = new Date(whichDate);
    newDate.setFullYear(withDate.getFullYear());
    newDate = setDayOfYear(newDate, getDayOfYear(withDate));
    newDate.setHours(whichDate.getHours(), whichDate.getMinutes(), 0, 0);
    return newDate;
}

export type dateOptions = {
    date: Date;
    "12h": string;
    "24h": string;
};

type GenerateTimeOptionsArg = {
    dateArg: Date;
    timezoneOffset?: number;
    onlyFutureValues?: boolean | number;
    step?: number;
    addOption?: Date;
    endingMinDate?: Date;
    allowPastDate?: boolean;
    day: Date;
};

/**
 *
 * @param {Date} arg.dateArg The date from which to start generating options
 * the function will determine if the date passed is TODAY
 * if it's not `today` it will generate all options in a day for 24h, starting at 00:00
 * if `today`, it will only generate options beginning with `date`'s hr:min
 *
 * @param {number} arg.timezoneOffset [default=0]
 * this function is designed to compute options relative to new Date()
 * for both today and future days
 * but it can also receive a pre-defined date
 * meaning it will NOT compute options between the current time, and said given date
 * and in order to compute options for the missing minutes, we need to know the timezone offset
 * in case the given date was converted to a different time zone
 *
 * @param {boolean|number} arg.onlyValuesAfter [default=false]
 * if true, will generate options beginning with date `date`
 * if number, will generate options beginning with `date` + this param's value (which should be minutes)
 *
 *
 * @param {number} arg.step [default=15] generates an option every 15 min (cannot be > 60)
 * @returns `Array<dateOptions>`
 *
 * @param {Date} arg.endingMinDate [default=undefined]
 *
 * used when generating options for ending time
 * ending options cannot have values lower than this value
 *
 * @param {Date} arg.day [default=Date]
 *
 * the day for which the options are generated
 *
 * @param {boolean} arg.allowPastDate [default=false]
 *
 * by default options are calculated starting from NOW
 * this option will allow calculation for/from any past date
 */
export function generateAutocompleteHours(arg: GenerateTimeOptionsArg) {
    const {
        dateArg,
        day,
        addOption: optionToAdd,
        onlyFutureValues = false,
        step = 15,
        timezoneOffset = 0,
        endingMinDate,
        allowPastDate = false,
    } = arg;
    if (step > 60) {
        throw new Error("cannot generate selection with intervals larger than 60min");
    }

    const today = allowPastDate ? new Date(new Date(day).setHours(0, 0, 0, 1)!) : addMinutes(new Date(new Date().setSeconds(0, 0)), 1);

    let date = alignDate(dateArg, day) as Date; //new Date(dateArg);

    const todayTime = today.getTime();

    if (!onlyFutureValues && (isYesterday(date) || isToday(date))) {
        // compute options between now and `dateArg` in case dateArg is in the future
        const xx = date;
        if (date.getTime() - todayTime > 60 * 1000) {
            const diff = Math.ceil((xx.getTime() - todayTime) / 1000 / 60);
            if (diff > 0) {
                date = addMinutes(date, -diff);
            }
        }
    }

    const opts: dateOptions[] = [];

    // pushed only if `isToday` and `onlyValuesAfter` === false
    const nowOpt: dateOptions = {
        date: today,
        "12h": format(today, "hh:mm a"),
        "24h": format(today, "HH:mm"),
    };

    let x: number = 0;
    let y: number = 0;

    // reset the date to midnight
    let dd = new Date(new Date(date).setHours(0, 0, 0, 0));
    const xx = new Date(dd);

    const steps = 60 / step - 1;
    let pushedNow = false;
    const addOption = optionToAdd; // ? (alignDate(optionToAdd, date) as Date) : null;
    const minValue = onlyFutureValues && endingMinDate ? endingMinDate : null;
    // technically we should loop from 0 -> 23 (24h)
    // but we actually loop through 2 days
    // and prevent pushing the options we don't need
    if (!onlyFutureValues && !isToday(dd)) {
        opts.push({
            date: dd,
            "12h": format(dd, "hh:mm a"),
            "24h": format(dd, "HH:mm"),
        });
    }
    while (x <= 47) {
        while (y <= steps) {
            const d = addMinutes(dd, step);
            // push current unaltered time only if isToday
            if (onlyFutureValues === false && (isYesterday(d) || isToday(date))) {
                if (d.getTime() > today.getTime() && !pushedNow) {
                    pushedNow = true;

                    opts.push(nowOpt);
                }
            }
            let canContinue =
                onlyFutureValues || isYesterday(d) || isToday(d) || isTomorrow(d)
                    ? onlyFutureValues
                        ? d.getTime() >= addMinutes(today, typeof onlyFutureValues === "boolean" ? 0 : onlyFutureValues).getTime() &&
                          d.getTime() >= (minValue || xx).getTime()
                        : d.getTime() > todayTime
                    : true;

            // generate some options for the next day if `now` is close to midnight
            // if now is before 21:00 PM, next day options will NOT be generated
            // if (((x === 23 && y > steps - 1) || x > 23) && opts[0] && opts[0].date.getHours() < 21 && isSameDay(d, opts[0].date)) {
            //
            //     canContinue = false;
            // }
            if (canContinue && opts[0] && d.getTime() - opts[0].date.getTime() >= 24 * 60 * 60 * 1000) {
                canContinue = false;
            }

            if (!canContinue) {
                dd = d;
                y++;
                continue;
            }
            const opt: dateOptions = {
                date: d,
                "12h": format(d, "hh:mm a"),
                "24h": format(d, "HH:mm"),
            };

            opts.push(opt);
            dd = d;
            y++;
        }
        y = 0;
        x++;
    }

    const tHr = today.getHours();
    const tMin = today.getMinutes();

    let defaultOption: dateOptions | undefined = undefined;

    // defaultOption refers to the first acceptable option for a start time
    // it is calculated within a 15min interval starting from now() for the start
    // for the ending time, it defaults to the first option
    if (addOption && (onlyFutureValues && minValue ? minValue.getTime() <= addOption.getTime() : true)) {
        defaultOption = {
            date: addOption,
            "12h": format(addOption, "hh:mm a"),
            "24h": format(addOption, "HH:mm"),
        };
    } else if (!onlyFutureValues) {
        for (let jj = 0; jj < opts.length; jj++) {
            const o = opts[jj];
            const dx = o.date; // addMinutes(o.date, timezoneOffset);
            const dHr = dx.getHours();
            const dMin = dx.getMinutes();

            // return the option which is closest to a 15 minutes interval
            // i.e. for both 12:01 and 12:14, the closest option will be 12:15
            if (tMin < 15 ? dHr === tHr && dMin === 15 : dHr === (tHr + 1 === 24 ? 0 : tHr + 1) && dMin === 0) {
                defaultOption = o;
                break;
            }
        }
    } else {
        defaultOption = opts[0] || nowOpt;
    }

    if (opts[opts.length - 1]) {
        if (opts[opts.length - 1].date.getHours() === 0) {
            // remove the last item if its 00:00
            // to prevent an adge case
            // where the option falls in the day after tomorrow
            opts.splice(opts.length - 1, 1);
        }
    }

    if (addOption) {
        const optionExists = opts.find((o) => o["24h"] === format(addOption, "HH:mm"));
        if (!optionExists) {
            let firstOptionAfterAdd = opts.findIndex((o) => o.date.getTime() - addOption.getTime() > 5 * 1000);
            if (firstOptionAfterAdd === -1 && opts.length) {
                if (addOption.getTime() > opts[opts.length - 1].date.getTime()) {
                    firstOptionAfterAdd = opts.length - 1;
                }
            }

            if (firstOptionAfterAdd !== -1) {
                opts.splice(firstOptionAfterAdd, 0, {
                    date: addOption,
                    "12h": format(addOption, "hh:mm a"),
                    "24h": format(addOption, "HH:mm"),
                });
            }
        }
    }

    // this shouldn't be needed cause it shouldn't happen
    // TODO @vasi check why this happens

    if (!defaultOption) {
        defaultOption = opts[opts.length - 1] || nowOpt;
    }

    return {
        opts,
        defaultOption: defaultOption!,
    };
}

const isNextDay = (arg: Date, compare: Date) => {
    return (isToday(arg) && isTomorrow(compare)) || (isYesterday(arg) && isToday(compare));
};

type APM = "AM" | "PM";

/**
 * IMPORTANT
 * works ONLY for strings formatted via
 * format(date, "hh:mm a")
 * and
 * format(date, "HH:mm")
 */
export function isPotentialDate(
    str: string,
    options: Array<dateOptions>,
    dateFormat: "12h" | "24h",
    currentSelectedDate: dateOptions | undefined,
    offset: number
) {
    let [hr, min] = str
        .trim()
        .split(" ")[0]
        .split(":")
        .filter((x) => x.length === 2)
        .map((x) => parseInt(x));

    let selectedDateHr = currentSelectedDate ? addMinutes(currentSelectedDate.date, offset).getHours() : null;
    let selectedDateDay = currentSelectedDate ? addMinutes(currentSelectedDate.date, offset).getDay() : null;

    let amPmFromSelected: APM | null = selectedDateHr !== null ? (selectedDateHr >= 12 ? "PM" : "AM") : null;

    let amPmStr: APM | null = dateFormat === "12h" ? (str.substr(-2).trim().toUpperCase() as APM) : amPmFromSelected;
    if (dateFormat === "12h" && (!amPmStr || ["AM", "PM"].indexOf(amPmStr) === -1) && amPmFromSelected) {
        amPmStr = amPmFromSelected;
    }
    if (["AM", "PM"].indexOf((amPmStr || "").toUpperCase()) !== -1) {
        amPmStr = amPmStr!.toUpperCase() as APM;
    }

    if (hr === undefined || min === undefined || hr > 23 || hr < 0 || min < 0 || min > 60) {
        return {isDate: false, optionExists: false, date: null};
    }
    if (amPmStr === "AM" && hr >= 12) {
        hr = hr - 12 === 0 ? 12 : hr - 12;
    }
    if (amPmStr === "PM" && hr < 12) {
        hr = hr + 12 >= 24 ? 0 : hr + 12;
    }

    const existingOption = options.find((o) => o["12h"] === `${hr}:${min === 0 ? "00" : min} ${amPmStr}`);

    const closestOpt = !existingOption
        ? options.find((opt) => {
              const o = addMinutes(opt.date, offset);
              const optHr = o.getHours();
              return (
                  (selectedDateDay != null
                      ? selectedDateDay === o.getDay() ||
                        (isNextDay(currentSelectedDate!.date, o) && min >= 45 && (hr + 1 === 13 || hr + 1 === 24) && amPmStr === "PM")
                      : true) &&
                  (min >= 45
                      ? optHr ===
                        (hr + 1 === 24 || (hr + 1 === 13 && isNextDay(currentSelectedDate!.date, o) && amPmStr === "PM") ? 0 : hr + 1)
                      : optHr === hr) &&
                  (o.getMinutes() > min || hr === optHr - 1) &&
                  opt["12h"].substr(-2) === amPmStr
              );
          })
        : existingOption;

    let date = closestOpt ? addMinutes(new Date(new Date(addMinutes(closestOpt.date, offset)).setHours(hr, min, 0, 0)), -offset) : null;

    if (!date) {
        const last = currentSelectedDate ?? options[options.length - 1];
        if (last) {
            const nd = addMinutes(new Date(new Date(addMinutes(last.date, offset)).setHours(hr, min, 0, 0)), -offset);
            if (nd.getTime() > last.date.getTime()) {
                date = nd;
            }
        }
    } else {
        const first = options[0];
        if (first && date.getTime() < first.date.getTime()) {
            date = null;
        }
    }

    return {
        isDate: date ? true : false,
        date,
        optionExists: existingOption ? true : false,
    };
}
