import {useState, useEffect} from "@workhorse/api/rendering";
import {useParams} from "@workhorse/api/routing";
import Inject from "@workhorse/providers/Injector";
import SessionDataProviders from "@workhorse/providers/SessionDataProviders";
import {TemplatesParams} from "../utils";
import AgendaTemplatesEditorHeader from "./AgendaTemplatesEditorHeader/AgendaTemplatesEditorHeader";
import AgendaTemplatesListHeader from "./AgendaTemplatesListHeader/AgendaTemplatesListHeader";

type AgendaTemplatesHeaderProps = {};

const AgendaTemplatesHeader = (props: AgendaTemplatesHeaderProps) => {
    const {section, templateId: templateIdParam} = useParams<TemplatesParams>();

    const templateId = templateIdParam?.replace("?ai-copilot=true", "");

    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) return null;
    return (
        <Inject target="inHeader" id="templates-dashboard-header" className="overflow-hidden">
            <div className="fullw">
                {templateId ? (
                    <SessionDataProviders isEditMode>
                        <AgendaTemplatesEditorHeader section={section} templateId={templateId} />
                    </SessionDataProviders>
                ) : (
                    <AgendaTemplatesListHeader />
                )}
            </div>
        </Inject>
    );
};

export default AgendaTemplatesHeader;
