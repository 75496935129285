import {OrganizationPermissionType} from "@generated/data";
import {LoadableComponent} from "@loadable/component";
import loadable from "@workhorse/api/loadable";
import {useMemo, useState} from "@workhorse/api/rendering";
import {OnboardingFeature, SeenSegment} from "@workhorse/api/seen";
import {useUserInfo} from "@workhorse/providers/User";
import {createContextProvider} from "../utils/context";
import fallback from "./components/OnboardingStepFallback";
import {useOnboardingFeaturesJourney, useOnboardingFeaturesSeen} from "./hooks";

export type OnboardingFeatureStepComponentProps = {
    type?: "video" | "text";
};

export type GenericFeatures = Record<
    Exclude<
        OnboardingFeature,
        | "onboardingSessionFirstStrike"
        | "onboardingSessionSecondStrikeOwner"
        | "onboardingSessionSecondStrikeMember"
        | "onboardingWorkspaceSetup"
        | "eventsWelcome"
        | "eventsCreate"
    >,
    OnboardingFeatureConfiguration
>;

export type MemberFeatures = Record<
    Exclude<OnboardingFeature, "onboardingSessionSecondStrikeOwner" | "onboardingWorkspaceSetup" | "eventsWelcome" | "eventsCreate">,
    OnboardingFeatureConfiguration
>;

export type OwnerFeatures = Record<
    Exclude<OnboardingFeature, "onboardingSessionSecondStrikeMember" | "eventsWelcome" | "eventsCreate">,
    OnboardingFeatureConfiguration
>;

// Home / Overview onboarding
const HomeOnboarding = loadable(() => import("./components/OnboardingFeatureDialogHome"), {fallback});

// Memory / Passed Sessions onboarding
const MemoryDashboardOnboarding = loadable(() => import("./components/OnboardingFeatureDialogMemory"), {fallback});

// Base Onboarding
const BaseOnboarding = loadable(() => import("./components/OnboardingFeatureDialogBase"), {fallback});

// Agendas Onboarding
const AgendaDashboardOnboarding = loadable(() => import("./components/OnboardingFeatureDialogAgenda"), {fallback});

// Tools Onboarding
const ToolsOnboarding = loadable(() => import("./components/OnboardingFeatureDialogTools"), {fallback});

// Files Onboarding
const FilesOnboarding = loadable(() => import("./components/OnboardingFeatureDialogFiles"), {fallback});

// Booking Onboarding
const BookingDashboardOnboarding = loadable(() => import("./components/OnboardingFeatureDialogBooking"), {fallback});

// Events
const EventsOnboarding = loadable(() => import("./components/OnboardingFeatureDialogEvents"), {fallback});

// Rooms
const RoomsOnboarding = loadable(() => import("./components/OnboardingFeatureDialogRooms"), {fallback});

export type OnboardingFeatureConfiguration = {
    title: string;
    subtitle: string;

    href?: string;

    quest: boolean;

    component?: LoadableComponent<OnboardingFeatureStepComponentProps & React.RefAttributes<HTMLDivElement>>;
};

const genericFeatures: GenericFeatures = {
    home: {
        title: "Home",
        subtitle: "This is your home",
        href: "/",
        quest: false,
        component: HomeOnboarding,
    },
    memoryDashboard: {
        title: "Memory",
        subtitle: "Where you can see passed sessions",
        href: "/memory",
        quest: false,
        component: MemoryDashboardOnboarding,
    },
    base: {
        title: "Calendar",
        subtitle: "Sync calendars & more",
        href: "/base",
        quest: false,
        component: BaseOnboarding,
    },
    agendaDashboard: {
        title: "Agendas",
        subtitle: "Create your first agenda",
        href: "/templates/my-agendas",
        quest: false,
        component: AgendaDashboardOnboarding,
    },
    files: {
        title: "Files",
        subtitle: "Create your first resource",
        href: "/files",
        quest: false,
        component: FilesOnboarding,
    },
    tools: {
        title: "Tools",
        subtitle: "Here you’ll find all the tools and integrations that you can embed into your sessions. Bye-bye screen share",
        href: "/resources",
        quest: false,
        component: ToolsOnboarding,
    },
    bookingDashboard: {
        title: "Book me",
        subtitle: "Create your first bookable session",
        href: "/booking",
        quest: false,
        component: BookingDashboardOnboarding,
    },
    events: {
        title: "Events",
        subtitle: "Create your first webinar",
        href: "/events",
        quest: false,
        component: EventsOnboarding,
    },
    rooms: {
        title: "Rooms",
        subtitle: "Create your first room",
        href: "/rooms",
        quest: false,
        component: RoomsOnboarding,
    },
    helpCenter: {
        title: "Learn more",
        subtitle: "Visit our Help Center.",
        quest: true,
    },
};

const memberFeatures: MemberFeatures = {
    onboardingSessionSecondStrikeMember: {
        title: "Embed a tool during a session",
        subtitle: "Add your first presentation.",
        quest: true,
    },
    onboardingSessionFirstStrike: {
        title: "Your first personalized experience",
        subtitle: "Go through Sessions’ basics",
        quest: true,
    },
    ...genericFeatures,
};

const ownerFeatures: OwnerFeatures = {
    onboardingSessionSecondStrikeOwner: {
        title: "Create a tool for your product",
        subtitle: "Get everything ready and set up.",
        quest: true,
    },
    onboardingSessionFirstStrike: {
        title: "Your first personalized experience",
        subtitle: "Go through Sessions’ basics",
        quest: true,
    },
    onboardingWorkspaceSetup: {
        title: "Set up your organization’s workspace",
        subtitle: "Set up your organization’s workspace",
        quest: true,
    },
    ...genericFeatures,
};

const useFeaturesOnboardingStore = () => {
    const {activeOrganizationPermission} = useUserInfo();

    const isOrganizationOwner = activeOrganizationPermission.permissionType === OrganizationPermissionType.Owner;

    const configuration = isOrganizationOwner ? ownerFeatures : memberFeatures;

    const journey = useOnboardingFeaturesJourney(configuration);

    const [journeyOpen, setJourneyOpen] = useState<boolean>(journey !== false && journey < 100);

    const featuresNotSeen = Object.assign({}, ...Object.keys(genericFeatures).map((f) => ({[f]: false})));

    const [featuresOpen, setFeaturesOpen] = useState<{[x: string]: boolean}>(featuresNotSeen);

    return useMemo(
        () => ({
            configuration,
            journeyOpen,
            setJourneyOpen,
            featuresOpen,
            setFeaturesOpen,
        }),
        [configuration, journeyOpen, setJourneyOpen, featuresOpen, setFeaturesOpen]
    );
};

export const [FeaturesOnboardingProvider, useFeaturesOnboardingConfiguration] = createContextProvider(
    {
        name: "FeaturesOnboarding",
    },
    useFeaturesOnboardingStore
);

export const advanceStep = (currentStep: number, totalComponent: number) => {
    if (currentStep < totalComponent) {
        return currentStep + 1;
    } else {
        return 1;
    }
};
