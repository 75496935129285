import {
    CommitLobbyPictureDocument,
    LobbyPictureImageDetailsFragment,
    ParticipantStatus,
    SessionLifecycle,
    UploadLobbyPictureDocument,
} from "@generated/data";
import environment from "@generated/environment";
import apollo from "@workhorse/api/apollo";
import {makeId} from "@workhorse/api/designer/lib/utils";
import designer from "@workhorse/api/designer";
import toast from "@workhorse/api/toast";
import {rbac} from "@workhorse/api/user";
import {readFragment, writeFragment} from "@workhorse/dataApi";
import {Participant} from "@workhorse/declarations/dataTypes";

export type SubmitRef =
    | {
          submit?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined, onlySetPayload?: boolean) => void;
      }
    | null
    | undefined;

export function isInLobby(participant: Participant) {
    return participant.status === ParticipantStatus.InLobby;
}

export function isOwner(participant: Participant) {
    return participant.isOwner && isInLobby(participant);
}

export function sortByInLobby(a: Participant, b: Participant) {
    if (isInLobby(a) && isInLobby(b)) {
        return 0;
    }

    if (isInLobby(a)) {
        return -1;
    }

    if (isInLobby(b)) {
        return 1;
    }

    return 0;
}

export function sortByOwner(a: Participant, b: Participant) {
    if (isOwner(a) && isOwner(b)) {
        return 0;
    }

    if (isOwner(a)) {
        return -1;
    }

    if (isOwner(b)) {
        return 1;
    }

    return 0;
}

export const sortParticipantsLobbyOrPlayer = (participants: Participant[], sessionLifecycle: SessionLifecycle, _hideVisible?: boolean) => {
    const hideVisible = _hideVisible ?? true;
    return participants
        .slice(0)
        .map((p) => p)
        .filter((p) =>
            hideVisible
                ? p.dataWithNullableEmail.isGuest
                    ? (ParticipantStatus.JoinedSession, ParticipantStatus.InLobby).includes(p.status)
                    : true
                : true
        )
        .sort((a, b) => {
            const A = ((a && a.dataWithNullableEmail && a.dataWithNullableEmail.firstName) || "").toUpperCase().trim();
            const B = ((b && b.dataWithNullableEmail && b.dataWithNullableEmail.firstName) || "").toUpperCase().trim();
            // @ts-expect-error
            const isNumeric = !isNaN(A) || (A?.length > 0 && !isNaN(A?.charAt(0)));
            if (isNumeric) {
                return 1;
            }
            return !B || (A && A < B) ? -1 : !A || A > B ? 1 : 0;
        })
        .sort((a, b) => {
            if (a.status === ParticipantStatus.Kicked && b.status !== ParticipantStatus.Kicked) {
                return 1;
            }
            if (b.status === ParticipantStatus.Kicked && a.status !== ParticipantStatus.Kicked) {
                return -1;
            }
            if (sessionLifecycle === SessionLifecycle.NotStarted) {
                if (a.status === ParticipantStatus.InLobby && b.status !== ParticipantStatus.InLobby) {
                    return -1;
                }
                if (b.status === ParticipantStatus.InLobby && a.status !== ParticipantStatus.InLobby) {
                    return 1;
                }
                return 0;
            }
            if (sessionLifecycle === SessionLifecycle.Started) {
                if (a.status === ParticipantStatus.JoinedSession && b.status !== ParticipantStatus.JoinedSession) {
                    return -1;
                }
                if (b.status === ParticipantStatus.JoinedSession && a.status !== ParticipantStatus.JoinedSession) {
                    return 1;
                }
                return 0;
            }
            return 0;
        })
        .sort((a, b) => {
            const aIsOwner = a.isOwner;
            const aIsAssistant = rbac(a, "session.isAssistant");
            const aIsAdmin = aIsOwner || aIsAssistant;

            const bIsOwner = b.isOwner;
            const bIsAssistant = rbac(b, "session.isAssistant");
            const bIsAdmin = bIsOwner || bIsAssistant;

            return aIsAdmin && !bIsAdmin
                ? -1
                : !aIsAdmin && bIsAdmin
                ? 1
                : aIsOwner && bIsAssistant
                ? -1
                : aIsAssistant && bIsOwner
                ? 1
                : 0;
        });
};

// Lobby picture
const logErrorUpload = (errorMessage: string) => {
    console.error(`Error occurred when uploading lobby picture: ${errorMessage}`);
    toast(`Error occurred when uploading lobby picture`, {type: "error"});
};

export const uploadLobbyPicture = (
    sessionId: string,
    lobbyPictureStructure?: LobbyPictureImageDetailsFragment["lobbyPicture"],
    uploadImage?: File,
    unSplashPhotoDownloadLocationUrl?: string,
    onUpload?: () => void,
    isDefaultImage?: boolean
) => {
    const photoSuffix = Date.now().toString();

    const canBeRepositioned =
        lobbyPictureStructure?.canBeRepositioned !== null && lobbyPictureStructure?.canBeRepositioned !== undefined
            ? lobbyPictureStructure?.canBeRepositioned
            : true;

    if (lobbyPictureStructure?.originalPictureUrl) {
        const updateData = {
            id: lobbyPictureStructure.id,
            originalPictureUrl: lobbyPictureStructure.originalPictureUrl ?? "",
            pictureWasUploaded: lobbyPictureStructure.pictureWasUploaded ?? null,
            y: lobbyPictureStructure.y ?? 50,
            canBeRepositioned,
            isDefaultImage,
        };

        // updateSession({
        //     lobbyPicture: {
        //         __typename: "LobbyPicture",
        //         ...updateData,
        //     },
        // });

        return apollo.client
            .mutate({
                mutation: CommitLobbyPictureDocument,
                variables: {
                    sessionId: sessionId,
                    ...updateData,
                    unsplashDownloadLocationUrl: unSplashPhotoDownloadLocationUrl,
                },
            })
            .then((res) => {
                if (!res.data) {
                    logErrorUpload("Error changing lobby picture!");
                    return;
                }

                canBeRepositioned && onUpload?.();
            });
    } else if (uploadImage) {
        return apollo.client
            .mutate({
                mutation: UploadLobbyPictureDocument,
                variables: {sessionId: sessionId, photoSuffix: photoSuffix},
            })
            .then((uploadUrlRes) => {
                if (!uploadUrlRes || !uploadUrlRes.data?.uploadLobbyPicture) {
                    logErrorUpload("No data returned from server.");
                    return;
                }

                return fetch(uploadUrlRes.data.uploadLobbyPicture.originalUploadUrl, {
                    method: "PUT",
                    body: uploadImage,
                    headers: {
                        "Content-Type": uploadImage?.type ? uploadImage.type : "application/octet-stream",
                    },
                }).then((response) => {
                    if (response.ok) {
                        const updateData = {
                            id: makeId(),
                            originalPictureUrl: `${environment.staticServiceUrl}/sessions/${sessionId}/lobbyPicture-${photoSuffix}_original`,
                            y: 0,
                            pictureWasUploaded: true,
                            canBeRepositioned,
                            isDefaultImage,
                        };

                        // updateSession({
                        //     lobbyPicture: {
                        //         __typename: "LobbyPicture",
                        //         ...updateData,
                        //     },
                        // });

                        return apollo.client
                            .mutate({
                                mutation: CommitLobbyPictureDocument,
                                variables: {
                                    sessionId: sessionId,
                                    ...updateData,
                                },
                            })
                            .then((res) => {
                                if (res.data) {
                                    const oldSession = readFragment({
                                        fragment: "LobbyPictureImageDetailsFragmentDoc",
                                        id: sessionId,
                                    });

                                    if (oldSession) {
                                        writeFragment({
                                            id: sessionId,
                                            fragment: "LobbyPictureImageDetailsFragmentDoc",
                                            data: {
                                                lobbyPicture: {
                                                    __typename: "LobbyPicture",
                                                    ...res.data.commitLobbyPicture,
                                                },
                                            },
                                        });

                                        canBeRepositioned && onUpload?.();
                                    }
                                } else {
                                    logErrorUpload("Error changing lobby picture!");
                                }
                            });
                    } else {
                        logErrorUpload("Fetch response is not 200.");
                    }
                });
            });
    }

    return Promise.resolve();
};
