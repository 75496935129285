import browserInfo from "@workhorse/api/BrowserInfo";

type WebkitWindow = typeof window & {
    webkitAudioContext: AudioContext;
};

const AudioContext = window.AudioContext || (window as WebkitWindow).webkitAudioContext;

export const conferenceContext = new AudioContext();

export function supportsWebAudio() {
    return conferenceContext !== null && !browserInfo.isIOS() && !browserInfo.isSafari();
}
