import {useMutation, useQuery} from "@apollo/client";
import {
    BillingPeriod,
    ChangeSubscriptionPlanDocument,
    OrganizationPlan,
    PaddlePlansDocument,
    UpdateOrganizationDocument,
    PaddleSubscriptionState,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import toast from "@workhorse/api/toast";
import {useUserInfo} from "@workhorse/providers/User";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import Loading from "@workhorse/components/Loading";
import {loadPaddleScript} from "@workhorse/pages/user/profile/UsageEvaluation/utils";
import environment from "@generated/environment";
import clientEvents, {useClientEvent} from "@api/events/client";
import {isReverseTrialActive, isReverseTrialWeirdState} from "@sessions/common/subscription-limitations/utils";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import classes from "./UpgradePlanDialogPayment.module.scss";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Typography from "@ui/cdk/Typography";
import OrderSummary from "./OrderSummary";
import BillingCycle from "./BillingCycle";
import {getPlanTitle} from "@workhorse/pages/user/profile/UsageEvaluation/components/SubscriptionInfo";
import CouponEditor from "./CouponEditor";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {pushDataLayerEvent} from "@workhorse/api/tracking";

type UpgradePlanInlinePaymentProps = {
    plan: OrganizationPlan;
    onClose: () => void;
    billingPeriod: BillingPeriod;
    skipTrialCoupon?: string;
};

const UpgradePlanDialogPaymentScriptLoader = (props: UpgradePlanInlinePaymentProps) => {
    const [loaded, setLoaded] = useState(false);

    const load = async () => {
        await loadPaddleScript();
        setLoaded(true);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // @ts-expect-error
        if (window.Paddle) {
            setLoaded(true);
            return;
        }

        load();
    }, []);

    return loaded ? <UpgradePlanInlinePayment {...props} /> : <Loading aboveAll />;
};

type UpgradePlanDialogPaymentProps = UpgradePlanInlinePaymentProps;

export const UpgradePlanDialogPayment = (props: UpgradePlanDialogPaymentProps) => {
    const imperativeRef = useRef<DialogImperativeRef>(null);

    // useEffect(() => {
    //     imperativeRef.current?.toggle?.();
    // }, []);

    return (
        <Dialog
            classes={{
                paper: classes.paper,
            }}
            open={true}
            onClose={props.onClose}
            imperativeRef={imperativeRef}
        >
            <UpgradePlanDialogPaymentScriptLoader {...props} />
        </Dialog>
    );
};

const UpgradePlanInlinePayment = (props: UpgradePlanInlinePaymentProps) => {
    const user = useUserInfo();

    const {skipTrialCoupon, billingPeriod} = props;

    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<BillingPeriod>(billingPeriod);

    const {data: getPaddlePlansQueryResponse} = useQuery(PaddlePlansDocument);

    const isOfferPlan = !!skipTrialCoupon;
    const {isMobileOrTablet} = useMobile();

    const plan = getPaddlePlansQueryResponse?.paddlePlans?.find((p) => p.plan === props.plan && p.billing_type === selectedBillingPeriod);

    const discountCode =
        user.activeOrganizationPermission.organization.referralDiscountCode ?? user.activeOrganizationPermission.organization.discountCode;

    const isFree = user.activeOrganizationPermission.organization.plan === OrganizationPlan.Free;
    const currentPlan = isFree
        ? null
        : getPaddlePlansQueryResponse?.paddlePlans.find((p) => p.plan === user.activeOrganizationPermission.organization.plan);

    const isCancelled =
        !!(
            user.activeOrganizationPermission.organization.planEffectiveCancellationDate &&
            !user.activeOrganizationPermission.organization.planNextPaymentDate
        ) && user.activeOrganizationPermission.organization.plan !== OrganizationPlan.Free;

    const isTrial = user.activeOrganizationPermission.organization.paddleSubscriptionState === PaddleSubscriptionState.Trialing;

    const isReverseTrial = isReverseTrialActive(user.activeOrganizationPermission.organization.reverseTrialCancellationDate);

    const reverseTrialWeirdState = isReverseTrialWeirdState(user.activeOrganizationPermission.organization.reverseTrialCancellationDate);

    const subscriptionId = user.activeOrganizationPermission.organization.subscriptionId;

    const [updateOrganization] = useMutation(UpdateOrganizationDocument);
    const {onClose} = props;

    const isCsd = environment.authService.appRoot !== environment.authService.originalAppRoot;

    const monthlyPrice = getPaddlePlansQueryResponse?.paddlePlans?.find(
        (p) => p.plan === props.plan && p.billing_type === BillingPeriod.Monthly
    )?.recurring_price?.[0]?.amount;

    const yearlyPrice =
        Math.ceil(
            getPaddlePlansQueryResponse?.paddlePlans?.find((p) => p.plan === props.plan && p.billing_type === BillingPeriod.Yearly)
                ?.recurring_price?.[0]?.amount ?? 0
        ) / 12;

    const couponPermanent = skipTrialCoupon ?? discountCode;

    const [couponState, setCouponState] = useState<string | null | undefined>(couponPermanent);

    useClientEvent("paddle-coupon-on-load", (coupon: string | null) => {
        if (coupon !== couponState) {
            setCouponState(coupon);
        }
    });

    useClientEvent("paddle-coupon-remove", () => {
        setCouponState("");
    });

    useClientEvent("paddle-coupon-change", (coupon: string) => {
        setCouponState(coupon);
    });

    const appliedCoupon = useMemo(() => couponState, [couponState]);

    useLayoutEffect(() => {
        if (!plan) return;
        if (isCsd) {
            clientEvents.emit("subscriptions-unavailable-csd");
            return;
        }

        if (reverseTrialWeirdState) {
            toast("Something went wrong. Please refresh the page and try again. If the problem persists, please contact support");
        }

        if (plan && (!currentPlan || isCancelled || isTrial || isReverseTrial)) {
            // @ts-ignore
            const Paddle = window.Paddle;
            // @ts-ignore
            const rewardfulReferral = window.Rewardful?.referral;

            clientEvents.emit("paddle-set-loading");

            Paddle.Checkout.open({
                product: plan.id,
                email: user.email,
                disableLogout: true,
                coupon: appliedCoupon,
                method: "inline",
                frameTarget: "paddle-frame-target", // className of your checkout <div>
                frameInitialHeight: appliedCoupon ? 709 : 652, // `450` or above
                frameStyle: "width:100%;  min-width:505px; background-color: transparent; border: none;",

                passthrough: JSON.stringify({
                    rewardful: {referral: rewardfulReferral},
                    organizationId: user.activeOrganizationPermission.organization.id,
                }),
                successCallback: (data) => {
                    console.log(data);
                    pushDataLayerEvent({
                        event: "purchasepaddle",
                    });
                    console.log("window?.gtag", window?.gtag);
                    window?.gtag?.("purchasepaddle", "purchasepaddle");

                    updateOrganization({
                        variables: {
                            data: {
                                processingSubscription: {
                                    set: true,
                                },
                            },
                        },
                    });

                    onClose();
                },
                closeCallback: function () {
                    console.log("closed");
                    onClose();
                },
            });
        } else if (subscriptionId) {
            apollo.client
                .mutate({
                    mutation: UpdateOrganizationDocument,
                    variables: {
                        data: {
                            processingSubscription: {
                                set: true,
                            },
                        },
                    },
                })
                .then(() => {
                    apollo.client
                        .mutate({
                            mutation: ChangeSubscriptionPlanDocument,
                            variables: {
                                planId: plan.id,
                            },
                        })
                        .then((res) => {
                            if (!res.data?.changeSubscriptionPlan) {
                                toast("Something went wrong, please try again later.", {
                                    type: "error",
                                });
                                apollo.client.mutate({
                                    mutation: UpdateOrganizationDocument,
                                    variables: {
                                        data: {
                                            processingSubscription: {
                                                set: false,
                                            },
                                        },
                                    },
                                });
                                return;
                            }
                        });
                });

            onClose();
        }
    }, [plan, currentPlan, isCancelled, subscriptionId, user.email, updateOrganization, onClose, isTrial, appliedCoupon]);

    return (
        <div className={classes.root}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography fontWeight="bolder" component="div" variant="xl" color="octonary">
                    Buy {getPlanTitle(plan?.plan)} plan
                </Typography>
            </DialogTitle>

            <div className={classes.content}>
                {monthlyPrice && yearlyPrice && isMobileOrTablet && plan?.plan ? (
                    <BillingCycle
                        monthlyPrice={monthlyPrice}
                        yearlyPrice={yearlyPrice}
                        onSelect={setSelectedBillingPeriod}
                        plan={plan.plan}
                        skipTrialDiscount={!!skipTrialCoupon}
                        selected={selectedBillingPeriod}
                    />
                ) : null}
                <div className={classes.paddleFrame}>
                    <div className="paddle-frame-target"></div>
                </div>
                {plan?.plan ? (
                    <div>
                        {monthlyPrice && yearlyPrice && !isMobileOrTablet ? (
                            <BillingCycle
                                monthlyPrice={monthlyPrice}
                                yearlyPrice={yearlyPrice}
                                onSelect={setSelectedBillingPeriod}
                                plan={plan.plan}
                                skipTrialDiscount={!!skipTrialCoupon}
                                selected={selectedBillingPeriod}
                            />
                        ) : null}
                        <CouponEditor onCouponUpdate={setCouponState} couponState={couponState} couponPermanent={couponPermanent} />
                        <OrderSummary
                            skipTrialDiscount={isOfferPlan}
                            yearlyPrice={yearlyPrice}
                            monthlyPrice={monthlyPrice}
                            isYearly={selectedBillingPeriod === BillingPeriod.Yearly}
                            plan={plan?.plan}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default UpgradePlanDialogPaymentScriptLoader;
