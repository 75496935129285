import Typography from "@ui/cdk/Typography";
import {ReactComponent as NoContactsAddedIcon} from "../../../../assets/media/no-contacts-added.svg";

const EventPeopleTableNoResults = () => {
    return (
        <div className="fullw flex flex-col flex-align-center">
            <NoContactsAddedIcon />
            <Typography className="mt-24" color="tertiary">
                We couldn't find anything.
            </Typography>
            <Typography color="tertiary">Maybe try using another search term.</Typography>
        </div>
    );
};

export default EventPeopleTableNoResults;
