import {useQuery} from "@apollo/client";
import {WorkspaceBookingEventsDocument} from "@sessions/common/generated/data-types";
import {useLocation} from "@workhorse/api/routing";
import classes from "./styles/WorkspaceBookings.module.scss";
import SessionBackground from "../player/session-view-components/SessionBackground";
import Typography from "@ui/cdk/Typography";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import {useMobile} from "@workhorse/providers/MobileProvider";

import WorkspaceBookingCard from "./components/WorkspaceBookingCard";
import {setBranding} from "../user/profile/Theming/utils";
import {BookingViewProvider, setParentNotification, useBookingView, useParentNotification} from "@workhorse/api/booking";

import {useEffect, useRef} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {getView} from "../book";
import GuestLanguageSelector from "@workhorse/components/language-selector/components/GuestLanguageSelector";
import {Trans, useTranslation} from "react-i18next";
import UrlQueryLanguageUpdater from "@workhorse/components/language-selector/UrlQueryLanguageUpdater";

const Bookings = () => {
    const {t} = useTranslation();

    const location = useLocation();
    const {isMobile} = useMobile();
    const [{view}] = useBookingView();

    const [_, setViewState] = useBookingView();

    const bookWrapperRef = useRef<HTMLDivElement>(null);

    const {search} = useLocation();

    // link looks like this: /bookings/:workspaceId
    const workspaceId = location.pathname.split("/")[2];

    const {data, error, loading} = useQuery(WorkspaceBookingEventsDocument, {
        variables: {
            workspaceId,
        },
    });

    useEffect(() => {
        if (!data?.publicWorkspaceInfo?.branding) {
            return;
        }

        setBranding(data?.publicWorkspaceInfo?.branding);
    }, [data?.publicWorkspaceInfo?.branding]);

    // alex ifrim BS
    useEffect(() => {
        const {view, nonce, preview} = getView(search);
        setViewState({view, nonce, preview});
    }, [search, setViewState]);

    const notification = useParentNotification();

    useEffect(() => {
        if (bookWrapperRef.current) {
            setParentNotification({
                loaded: true,
                height: bookWrapperRef.current.scrollHeight < 800 ? bookWrapperRef.current.scrollHeight : 800,
            });
        }
    }, [notification, bookWrapperRef]);

    return (
        <BookingViewProvider>
            <SessionBackground />

            <div className={view === "component" ? classes.rootComponent : classes.root}>
                <div ref={bookWrapperRef} className={classes.pageContent}>
                    {view === "component" ? (
                        <UrlQueryLanguageUpdater />
                    ) : (
                        <div className={classes.header}>
                            <BrandingLogo size="big" customSize={isMobile ? "small" : "big"} />

                            <div className="flex flex-align-center">{<GuestLanguageSelector checkUrlQueryForLanguage />}</div>
                        </div>
                    )}

                    <div className={classes.bodyContent}>
                        {loading ? (
                            <div className="fullw flex relative">
                                <Loading />
                            </div>
                        ) : (
                            <div className={classes.bookingsListContainer}>
                                {/* <Typography className={classes.listTitle}>Available bookings</Typography> */}
                                <div className={classes.bookingsList}>
                                    {data?.workspaceBookingEvents.length ? (
                                        data?.workspaceBookingEvents.map((booking) => {
                                            return <WorkspaceBookingCard key={booking.id} booking={booking} isMobile={isMobile} />;
                                        })
                                    ) : (
                                        <div className="fullw flex flex-col flex-align-center my-20">
                                            <Typography color="secondary" variant="lg" fontWeight="bolder" className="mb-12">
                                                {t("booking.workspace_bookings.no_bookings_1")}
                                            </Typography>

                                            <Typography color="tertiary" fontWeight="bold">
                                                {t("booking.workspace_bookings.no_bookings_2")}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {view !== "component" && (
                        <footer className={classes.footer}>
                            <Trans i18nKey="booking.workspace_bookings.bookings_by">
                                <Typography fontWeight="bolder" className={classes.bookingsBy}>
                                    Bookings by
                                    <br />
                                </Typography>
                                <Typography fontWeight="bolder" variant="lg">
                                    {/* @ts-ignore */}
                                    {{workspace_name: data?.publicWorkspaceInfo?.name || ""}}
                                </Typography>{" "}
                            </Trans>
                        </footer>
                    )}
                </div>
            </div>
        </BookingViewProvider>
    );
};

export default Bookings;
