import {raiseHand} from "@artifacts/conference/api/conferenceControllerApi";
import {ConferenceParticipantStatus, ReactionType} from "@generated/data";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import React, {forwardRef} from "@workhorse/api/rendering";
import {useReaction} from "@workhorse/providers/SessionReactionsProvider";
import commons from "../../../../commons/style.module.scss";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

const RaiseHandTogglerOption = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {participantId, isSpeaker, isRaisingHand} = useParticipantsStore(
        useShallow(({currentParticipant}) => ({
            participantId: currentParticipant?.id ?? "",
            isSpeaker: currentParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker,
            isRaisingHand: currentParticipant?.conferenceStatus == ConferenceParticipantStatus.PendingSpeaker,
        }))
    );

    const {triggerReaction} = useReaction();

    const toggleRaiseHand = async () => {
        if (!isSpeaker) {
            raiseHand(participantId, isRaisingHand);
            if (!isRaisingHand) {
                triggerReaction(ReactionType.Raisehand);
            }
        }
    };

    const keybinds = getGlobalKeybind("toggleRaiseHand");

    return (
        <MenuItem onClick={toggleRaiseHand} ref={ref} disabled={isSpeaker} className="flex-center-all blue">
            <Typography fontWeight="bold">
                {!isRaisingHand ? t("participant.reaction.raise_hand") : t("participant.reaction.stop_raise_hand")}
            </Typography>
            <div className="flex flex-align-center ml-12">
                {keybinds.map((keybind, index) => (
                    <div key={index} className={commons.keyBind}>
                        {keybind}
                    </div>
                ))}
            </div>
        </MenuItem>
    );
});

export default RaiseHandTogglerOption;
