import {AvatarSizeTypes, AvatarVariantTypes} from "@ui/cdk/Avatar/types";
import {BadgeProps} from "@ui/cdk/Badge/types";
import SessionsAvatar from "@ui/cdk/SessionsAvatar";
import {SessionsAvatarIconLeft, SessionsAvatarIconRight} from "@ui/cdk/SessionsAvatar/types";
import Tooltip from "@ui/cdk/Tooltip";
import {getColor, makeInitials} from "@workhorse/util";
import {cls} from "@ui/cdk/util/util";
import {useTranslation} from "react-i18next";

interface ProfilePictureProps {
    firstName?: string | null;
    lastName?: string | null;
    avatar?: string | null;
    disableTooltip?: boolean;
    emailTooltip?: boolean;
    email?: string | null;
    isGuest?: boolean;
    avatarSize?: AvatarSizeTypes;
    avatarVariant?: AvatarVariantTypes;
    badgeProps?: BadgeProps;
    className?: string;
    iconLeft?: SessionsAvatarIconLeft;
    iconRight?: SessionsAvatarIconRight;
    style?: React.CSSProperties;
    withWhiteBorder?: boolean;
    smallBorder?: boolean;
}

export const getParticipantName = (firstName: string | null | undefined, lastName: string | null | undefined, email?: string) => {
    let title = "";
    if (firstName && firstName !== "G") {
        title = title + firstName;
    }
    if (lastName) {
        title = title + " " + lastName;
    }
    if (email && title === "") {
        title = email;
    }
    return title;
};

function getTooltipTitle(emailTooltip: boolean, firstName?: string | null, lastName?: string | null, email?: string | null) {
    const participantName = getParticipantName(firstName, lastName);

    if (emailTooltip && email) {
        return email;
    } else if (participantName) {
        return participantName;
    }

    return email || "Guest";
}

const ProfilePicture = ({
    firstName,
    lastName,
    avatar,
    disableTooltip = false,
    emailTooltip = false,
    email,
    avatarSize,
    avatarVariant,
    badgeProps,
    className,
    iconLeft,
    iconRight,
    style,
    withWhiteBorder,
    smallBorder,
}: ProfilePictureProps) => {
    const {t} = useTranslation();
    let customFirstName, customLastName;

    if (!!lastName) {
        customFirstName = firstName;
        customLastName = lastName;
    } else if (!!firstName) {
        customFirstName = firstName.split(" ")[0];
        customLastName = firstName.split(" ")[1];
    }
    const initials = makeInitials(customFirstName, customLastName);
    const tooltipTitle = getTooltipTitle(emailTooltip, firstName, lastName, email);
    return (
        <div
            className={cls(className)}
            aria-label={
                t("user.aria.x_profile_picture", {
                    name: customFirstName + customLastName,
                }) ?? ""
            }
        >
            <Tooltip title={disableTooltip ? "" : tooltipTitle} placement="top" arrow>
                {avatar ? (
                    <SessionsAvatar
                        imgProps={{src: avatar, alt: "profile-picture"}}
                        size={avatarSize}
                        variant={avatarVariant}
                        badgeProps={badgeProps}
                        iconLeft={iconLeft}
                        iconRight={iconRight}
                        style={style}
                        withWhiteBorder={withWhiteBorder}
                        smallBorder={smallBorder}
                        draggable="false"
                    />
                ) : (
                    <SessionsAvatar
                        backgroundColor={getColor(initials)}
                        size={avatarSize}
                        variant={avatarVariant}
                        badgeProps={badgeProps}
                        iconLeft={iconLeft}
                        iconRight={iconRight}
                        style={style}
                        withWhiteBorder={withWhiteBorder}
                        smallBorder={smallBorder}
                    >
                        {initials}
                    </SessionsAvatar>
                )}
            </Tooltip>
        </div>
    );
};

export default ProfilePicture;
