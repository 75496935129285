import {Extension} from "@tiptap/core";
import {isListElement} from "../helpers";

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        indent: {
            setIndentation: () => ReturnType;
            unsetIndentation: () => ReturnType;
        };
    }
}

const TextIndentationPlugin = Extension.create({
    name: "indent",

    addOptions() {
        return {
            types: ["heading", "paragraph"],
            defaultIndentLevel: 0,
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    indent: {
                        default: this.options.defaultIndentLevel,
                        renderHTML: (attributes) => ({
                            style: `margin-left:${attributes.indent * 4}ch!important;`,
                        }),
                        parseHTML: (element) => parseInt(element.style.marginLeft) || this.options.defaultIndentLevel,
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setIndentation:
                () =>
                ({tr, state, dispatch, editor, commands}) => {
                    tr = tr.setSelection(state.selection);
                    const {doc, selection} = tr;
                    const {from, to} = selection;

                    doc.nodesBetween(from, to, (node) => {
                        const nodeType = node.type.name;
                        const attr = node.attrs;
                        if (nodeType === "paragraph" || nodeType === "heading") {
                            commands.updateAttributes(nodeType, {indent: attr.indent + 1});
                        }
                        return true;
                    });

                    if (tr.docChanged) {
                        dispatch?.(tr);
                        return true;
                    }

                    editor.chain().focus().run();

                    return false;
                },
            unsetIndentation:
                () =>
                ({tr, state, dispatch, editor, commands}) => {
                    tr = tr.setSelection(state.selection);
                    const {doc, selection} = tr;
                    const {from, to} = selection;

                    doc.nodesBetween(from, to, (node) => {
                        const nodeType = node.type.name;
                        const attr = node.attrs;
                        if (nodeType === "paragraph" || nodeType === "heading") {
                            commands.updateAttributes(nodeType, {indent: attr.indent <= 0 ? 0 : attr.indent - 1});
                        }
                        return true;
                    });

                    if (tr.docChanged) {
                        dispatch?.(tr);
                        return true;
                    }

                    editor.chain().focus().run();

                    return false;
                },
        };
    },
    addKeyboardShortcuts() {
        return {
            Tab: () => (!isListElement(this.editor) ? this.editor.commands.setIndentation() : false),
            "Shift-Tab": () => (!isListElement(this.editor) ? this.editor.commands.unsetIndentation() : false),
        };
    },
});

export default TextIndentationPlugin;
