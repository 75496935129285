import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {SvgIconTypeMap} from "@material-ui/core/SvgIcon";
import Button from "@ui/cdk/Button";
import {ButtonVariant} from "@ui/cdk/Button/Button";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import Typography from "@ui/cdk/Typography";
import Dialog from "@ui/core/components/Dialog";
import IconButton from "@ui/core/components/IconButton";
import SvgIcon from "@ui/core/components/SvgIcon";
import React, {PropsWithChildren} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as FileSharingCancelIcon} from "../assets/media/FileSharingCancelIcon.svg";
import classes from "./style/ConfirmationDialog.module.scss";
import StyledDeleteIcon from "./icons/StyledDeleteIcon";

type ConfirmationDialogProps = {
    open: boolean;
    onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    onCancel?: () => void;
    onConfirm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    title: string;
    content: string | JSX.Element | (string | JSX.Element)[];
    cancelButton?: string | JSX.Element;
    submitButton: string | JSX.Element;
    Icon?:
        | OverridableComponent<SvgIconTypeMap<{}, "svg">>
        | React.FunctionComponent<
              React.SVGProps<SVGSVGElement> & {
                  title?: string | undefined;
              }
          >;
    IconElement?: JSX.Element;
    variant?: "warning";
    dialogTitle?: string;
    disableCloseButton?: boolean;
    disableBackdropClick?: boolean;
    disableConfirmButton?: boolean;
    additionalCancelButton?: boolean;
    cancelButtonVariant?: ButtonVariant;
    submitButtonVariant?: ButtonVariant;
    minWidth?: boolean;
    isDeleteConfirmation?: boolean;
    abovePalette?: boolean;
};

const ConfirmationDialog = (props: PropsWithChildren<ConfirmationDialogProps>) => {
    const {
        onClose,
        onCancel,
        onConfirm,
        title,
        content,
        cancelButton,
        submitButton,
        open,
        Icon,
        IconElement,
        variant,
        dialogTitle,
        disableCloseButton = false,
        disableConfirmButton = false,
        additionalCancelButton = false,
        cancelButtonVariant = "secondary",
        submitButtonVariant = "primary",
        minWidth = false,
        children,
        isDeleteConfirmation,
        disableBackdropClick,
    } = props;

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        onClose();
    };

    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick" && disableBackdropClick) {
            return;
        }
        onClose();
    };

    const abovePaletteStyle = props.abovePalette ? {zIndex: 10000} : {};

    return (
        <Dialog
            data-id="confirmation-dialog"
            open={open}
            onClose={handleClose}
            aria-label={"Confirmation Dialog"}
            data-open={open}
            style={abovePaletteStyle}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop",
                },
            }}
            PaperProps={{
                className: cls(classes.paperClass, minWidth && classes.minWidth),
            }}
            classes={{
                container: "standard-dialog-container",
            }}
        >
            <div>
                <div className={cls("flex flex-justify-between")}>
                    <Typography variant="xl3" className={classes.contentHeader}>
                        {!isDeleteConfirmation ? (
                            <>
                                {Icon && <Icon className={cls(classes.confirmationIcon)}></Icon>}
                                {IconElement}
                            </>
                        ) : (
                            <StyledDeleteIcon />
                        )}
                        {title}
                    </Typography>
                    {disableCloseButton ? null : (
                        <IconButton data-id="close-dialog" className={classes.closeBtn} onClick={handleCancel}>
                            <SvgIcon viewBox="0 0 24 24" className={classes.closeIcon}>
                                <FileSharingCancelIcon />
                            </SvgIcon>
                        </IconButton>
                    )}
                </div>
                <div className={cls("flex flex-col", classes.contentBlock)}>
                    <Typography className={classes.contentBody}>{content}</Typography>
                    {children}
                </div>
                <DialogFooter noBorder noPadding>
                    {additionalCancelButton ? (
                        <Button variant="quaternary" onClick={handleCancel}>
                            Cancel
                        </Button>
                    ) : null}

                    {cancelButton ? (
                        isDeleteConfirmation ? (
                            <Button data-id="cancel" withMarginLeft variant={"quaternary"} onClick={onClose}>
                                <Typography color="secondary">{cancelButton}</Typography>
                            </Button>
                        ) : (
                            <Button data-id="cancel" withMarginLeft variant={cancelButtonVariant} onClick={onClose}>
                                {cancelButton}
                            </Button>
                        )
                    ) : null}
                    <Button
                        data-id="confirm"
                        withMarginLeft
                        variant={submitButtonVariant}
                        onClick={onConfirm}
                        disabled={disableConfirmButton}
                        autoFocus
                    >
                        {submitButton}
                    </Button>
                </DialogFooter>
            </div>
        </Dialog>
    );
};

export default ConfirmationDialog;
