import {isObject} from "@workhorse/util";

export function mergeQueryData(existing: any, incoming: any, levels?: number, level?: number) {
    return existing
        ? Object.keys(existing || {}).reduce((out, key) => {
              return Object.assign(
                  out,
                  incoming.hasOwnProperty(key) && !(incoming[key] === undefined)
                      ? {
                            [key]: Array.isArray(incoming[key])
                                ? (existing[key] || [])
                                      .map((o) => {
                                          if (o && typeof o === "object") {
                                              const incomingO = incoming[key].find((oo) => oo && typeof oo === "object" && oo.id === o.id);
                                              if (incomingO) {
                                                  return mergeQueryData(o, incomingO, levels, levels ? (level || 0) + 1 : undefined);
                                              }
                                          }
                                          return o;
                                      })
                                      .concat(
                                          incoming[key].filter((o) => {
                                              if (o && typeof o === "object") {
                                                  const existingO = existing[key].find((oo) => oo && oo.id === o.id);
                                                  if (existingO) {
                                                      return false;
                                                  }
                                              }
                                              return true;
                                          })
                                      )
                                : isObject(incoming[key])
                                ? levels && level && levels === level
                                    ? Object.assign(existing[key] ?? {}, incoming[key])
                                    : mergeQueryData(existing[key], incoming[key], levels, levels ? (level || 0) + 1 : undefined)
                                : incoming[key],
                        }
                      : null
              );
          }, {})
        : incoming;
}

export function deepQueryResultMerge(existing: any, incoming: any, prependOnMerge: boolean = false) {
    return Object.keys(existing || {}).reduce((out, key) => {
        return {
            ...out,
            [key]: Array.isArray(existing[key])
                ? prependOnMerge
                    ? incoming[key].concat(existing[key])
                    : existing[key].concat(incoming[key])
                : isObject(existing[key])
                ? deepQueryResultMerge(existing[key], incoming[key], prependOnMerge)
                : incoming[key],
        };
    }, {});
}
