import {CombinedDesignerAction} from "../../action";
import {makeId} from "../../utils";
import {CreateAgendaItemAction, LocalCreateAgendaItemInput} from "./create-agenda-item";
import {MoveAgendaItem} from "./move-agenda-item";

type InsertAgendaItemActionInput = {
    agendaItem: LocalCreateAgendaItemInput;
    ids: string[];
    location: "before" | "after";
};

export class InsertAgendaItemAction extends CombinedDesignerAction<InsertAgendaItemActionInput> {
    constructor(input: InsertAgendaItemActionInput) {
        super(
            input.ids
                .map((element, index) => {
                    const agendaItemWithId = {...input.agendaItem, id: makeId()};

                    return [
                        () => new CreateAgendaItemAction(agendaItemWithId),
                        () =>
                            new MoveAgendaItem({
                                id: this.commitResults[index * 2],
                                beforeId: input.location === "before" ? element : undefined,
                                afterId: input.location === "after" ? element : undefined,
                            }),
                    ];
                })
                .reduce((acc, cur) => acc.concat(cur), []),
            () => this.commitResults[0]
        );
    }
}
