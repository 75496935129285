import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import noBranding from "../../../../assets/media/empty-box.svg";
import classes from "./style/MyProductTool.module.scss";
import ControlPointRoundedIcon from "@material-ui/icons/ControlPointRounded";

type NoProductToolComponentProps = {
    handleCreateAnotherProductTool: () => void;
    canEditMyProduct?: boolean;
};

const NoProductToolComponent = (props: NoProductToolComponentProps) => {
    return (
        <div className={cls(classes.noProduct, "flex flex-center-all")}>
            <img src={noBranding} className={classes.noProductImage} />
            <Typography className="mb-24" fontWeight="bolder" variant="xl2" color="tertiary">
                {props.canEditMyProduct ? "You haven’t added any products yet" : "You have no products"}
            </Typography>

            <Typography className="mb-36" variant="base" color="tertiary">
                {props.canEditMyProduct
                    ? "As soon as you add a product, you’ll be able to embed it into your agendas and live sessions."
                    : "No products have been added yet."}
            </Typography>

            {props.canEditMyProduct && (
                <Button size="small" variant="tertiary" onClick={props.handleCreateAnotherProductTool}>
                    <ControlPointRoundedIcon className="mr-6" />
                    Add product
                </Button>
            )}
        </div>
    );
};

export default NoProductToolComponent;
