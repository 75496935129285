import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/OnboardingTooltip.module.scss";
import {useTranslation} from "react-i18next";
import {isDesktopApp} from "@sessions/desktop-api";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {HyperlinkExternal} from "@workhorse/util/links";

const isApp = isDesktopApp();

type OnboardingTooltipProps = Omit<ExtractProps<typeof Tooltip>, "children" | "open" | "onClose"> & {
    title: string | JSX.Element;
    articleLink?: string;
    className?: string;
    offset?: string;
    children?: JSX.Element;
};

const OnboardingTooltip = (props: OnboardingTooltipProps) => {
    const {t} = useTranslation();
    const {
        title: titleProp,
        articleLink,
        className,
        placement = "bottom",
        children: childrenProp,
        arrow = true,
        offset,
        ...otherProps
    } = props;

    const title = (
        <div className={cls("flex flex-col", classes.title)} key="onboarding-tooltip-title">
            <Typography variant="sm" key="onboarding-tooltip-title-text">
                {titleProp}
            </Typography>
            {articleLink ? (
                <HyperlinkExternal
                    className={"flex"}
                    href={articleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    key="onboarding-tooltip-title-link"
                >
                    {t("g.learn_more") ?? "Learn more"}
                </HyperlinkExternal>
            ) : null}
        </div>
    );

    const children = childrenProp ? (
        childrenProp
    ) : (
        <InfoOutlinedIcon className={cls(classes.icon, className)} key="onboarding-tooltip-icon" />
    );

    return (
        <Tooltip
            placement={placement}
            {...otherProps}
            title={title}
            classes={{
                tooltip: cls(classes.tooltip, props.classes?.tooltip),
                popper: cls(classes.popper, props.classes?.popper),
                arrow: cls(classes.arrow, props.classes?.arrow),
            }}
            enterTouchDelay={0}
            arrow={arrow}
            interactive
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        offset: offset
                            ? {
                                  enabled: true,
                                  offset: offset,
                              }
                            : undefined,
                    },
                },
            }}
            key="onboarding-tooltip"
        >
            {children}
        </Tooltip>
    );
};

export default OnboardingTooltip;
