import {cls} from "@ui/cdk/util/util";
import {OnboardingFeatures} from "@workhorse/api/onboarding";
import RoomsPageHeader from "./RoomsPageHeader";
import RoomsTableContainer from "./RoomsTableContainer";
import classes from "./style/RoomsContainer.module.scss";

export default function RoomsContainer() {
    return (
        <>
            <RoomsPageHeader key="header" />
            <div className={cls("flex flex-col fullw fullh", classes.root)}>
                <RoomsTableContainer />
            </div>
            <OnboardingFeatures feature="rooms" />
        </>
    );
}
