import {ArtifactDefaultProperties} from "@cli/manifest";
import {GetPspdfkitTokenDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";

/**
 * @todo - Silviu
 * We're trying to generate the token for document type artifacts ahead of time.
 * This function is a stub until we decide on the correct approach;
 */

export const DOCUMENT_RESOURCE_TYPES = [
    "flowos/ppt/resx/Ppt",
    "flowos/doc/resx/Doc",
    "flowos/excel/resx/Excel",
    "flowos/pdf/resx/Pdf",
    "flowos/keynote/resx/Keynote",
    "flowos/pages/resx/Pages",
    "flowos/numbers/resx/Numbers",
];

export async function getDocumentToken(resourceType: string, artifactId: string, documentId: string) {
    try {
        if (!DOCUMENT_RESOURCE_TYPES.includes(resourceType)) {
            return null;
        }

        const data = await apollo.client.query({
            query: GetPspdfkitTokenDocument,
            variables: {documentId: documentId, layer: artifactId},
        });

        return {
            key: "documentToken",
            value: data?.data?.getPspdfkitToken ?? "",
        };
    } catch (error) {
        console.error(error);
        return null;
    }
}
