import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";

import classes from "./BreakoutLeaveNotifier.module.scss";
import Button from "@ui/cdk/Button";
import descriptor from "@generated/artifacts";
import env from "@generated/environment";
import Snackbar from "@ui/core/components/Snackbar";
import {setBreakoutLeaveNotifier} from "../../playerStateVars";
import {useReactiveVar} from "@workhorse/api/state";

export type BreakoutEndNotificationType = {
    onCancel: () => void;
    onConfirm: () => void;
};

function BreakoutLeaveNotifier() {
    const {onCancel: onCancelProp, onConfirm: onConfirmProp, open} = useReactiveVar(setBreakoutLeaveNotifier);
    const icon = descriptor["flowos/breakout"].meta.icon;
    const iconSrc = `${env.publicResourcesCdnRoot ?? ""}/icons/${icon}.svg`;

    const closeNotifier = () => {
        setBreakoutLeaveNotifier({});
    };

    const onCancel = () => {
        if (onCancelProp) {
            onCancelProp();
        }
        closeNotifier();
    };

    const onConfirm = () => {
        if (onConfirmProp) {
            onConfirmProp();
        }
        closeNotifier();
    };

    return (
        <Snackbar
            open={open ? true : false}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <div className={cls("flex", classes.root)}>
                <div className={cls("flex flex11-auto flex-center-all")}>
                    <div className={cls("flex flex00-auto flex-center-all", classes.timeleft)}>
                        <img src={iconSrc} />
                    </div>
                    <div className={cls("flex flex11-auto flex-col", classes.middleTxt)}>
                        <Typography variant="lg" fontWeight="bold">
                            {"Do you want to end all breakout rooms?"}
                        </Typography>
                        <Typography variant="base" style={{whiteSpace: "nowrap"}}>
                            {"Everyone will return to the main session"}
                        </Typography>
                    </div>
                </div>
                <div className={cls("flex flex-center-all")}>
                    <Button className="mr-8" size="medium" variant="tertiary" onClick={onCancel}>
                        {"No"}
                    </Button>
                    <Button data-id="yes-breakout-leave" className="mr-8" size="medium" variant="primary" onClick={onConfirm}>
                        {"Yes"}
                    </Button>
                </div>
            </div>
        </Snackbar>
    );
}

export default BreakoutLeaveNotifier;
