// import {countryToFlag} from "@api/countryUtils";
import Select from "@ui/cdk/Select";
import {cls} from "@ui/cdk/util";
import {languageAllowedHostTypes, languages} from "@workhorse/i18n";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useTranslation} from "react-i18next";
import {LanguageSelectorType} from "../LanguageSelector";
import classes from "./styles/LanguageSelector.module.scss";

type LanguageSelectorComponentProps = {
    language: string;
    onChange: (language: string) => void;
    disabled?: boolean;
} & LanguageSelectorType;

const LanguageSelectorComponent = (props: LanguageSelectorComponentProps) => {
    const {language, onChange, className, selectProps, disabled} = props;

    const {i18n} = useTranslation();
    const hostType = useHostType();

    const isLanguageAllowed = languageAllowedHostTypes.includes(hostType);

    const currentLanguage = languages.find((lang) => lang.code === (isLanguageAllowed ? i18n.language : language))?.code ?? "en";

    const handleChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const language = event.target.value as string;

        if (isLanguageAllowed && i18n.language !== language) {
            i18n.changeLanguage(language);
        }

        onChange(language);
    };

    const options = languages
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((language) => ({
            value: language.code,
            // text: `${countryToFlag(language.flag)}  ${language.name}`,
            text: `${language.name}`,
        }));

    return (
        <div className={cls(classes.languageSelectContainer, className)}>
            <Select
                disabled={disabled}
                classes={{
                    ...selectProps?.classes,
                    root: cls(classes.languageSelectRoot, selectProps?.classes?.root),
                    select: cls("fullw", selectProps?.classes?.select),
                    selectMenu: cls("fullw", selectProps?.classes?.selectMenu),
                }}
                options={options}
                value={currentLanguage}
                onChange={handleChange}
                {...selectProps}
            />
        </div>
    );
};

export default LanguageSelectorComponent;
