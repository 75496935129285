import {ReactNode} from "@workhorse/api/rendering";
import {ForceMuteStatusProvider} from "./ForceMuteProvider";
import {HardMuteProvider} from "./HardMuteProvider";
import {MediaMuteProvider} from "./MediaMuteProvider";
import {SystemSoftMuteProvider} from "./SoftMuteProvider";

export function MuteProvider({children}: {children: ReactNode}) {
    return (
        <SystemSoftMuteProvider>
            <HardMuteProvider>
                <MediaMuteProvider>
                    <ForceMuteStatusProvider>{children}</ForceMuteStatusProvider>
                </MediaMuteProvider>
            </HardMuteProvider>
        </SystemSoftMuteProvider>
    );
}
