import {makeStyles} from "@material-ui/core";

const useDialogStyles = makeStyles((theme) => ({
    buttons: {
        height: theme.typography.pxToRem(80),
        justifyContent: "flex-end",
        padding: theme.spacing(1.75, 3.75),
        // "&.no-cancel $confirmBtn": {
        //     marginLeft: "auto",
        // },
    },
}));

export default useDialogStyles;
