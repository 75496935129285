import {HostType, SyncGoogleDriveDocument} from "@generated/data";
import {cls, triggerMouseEnter} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {memo, useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";
import {WithChildren} from "@workhorse/declarations";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useIsPreJoin, useMyParticipant, useSession} from "@workhorse/providers/SessionDataProviders";
import ReactDOM from "react-dom";
import {ActionCategTreeItem, ActionCategory, categMap} from "./actionCategTree";
import CommandPaletteContent from "./CommandPaletteContent";
import CommandPaletteFooter from "./CommandPaletteFooter";
import CommandPaletteHeader from "./CommandPaletteHeader";
import {
    usePaletteActiveCateg,
    paletteActiveCategDefault,
    usePaletteHasSearchString,
    usePaletteRootSearchString,
    usePaletteOpen,
} from "./CommandPaletteProviders";
import {usePaletteActionArg, usePaletteActionCallback, usePaletteExcludedItems} from "./PaletteActionProvider";
import classes from "./style/CommandPalette.module.scss";
import clientEvents, {useClientEvent} from "@api/events/client";
import {usePaletteResultList, usePaletteResults, usePaletteSearchFilters} from "./CommandPaletteResultsProvider";
import designer from "@workhorse/api/designer";
import {useUserInfo} from "@workhorse/providers/User";
import EmbedLinkDescription from "./EmbedLinkDescription";
import {makeTemplateIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {useLocation} from "@workhorse/api/routing";
import browserInfo from "@workhorse/api/BrowserInfo";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {checkGlobalKeybinds} from "@workhorse/util/keybinds";

export default function CommandPalettePortal() {
    return ReactDOM.createPortal(<CommandPalette />, document.getElementById("command-palette-root")!);
}

function findActionCategItemByShortcutKey(key: NonNullable<ActionCategTreeItem["ctrlShortcut"]>) {
    return categMap.find((obj) => obj.ctrlShortcut === key);
}

export function setHighlightedItem(node?: HTMLDivElement, id?: string) {
    if (!node) {
        return;
    }
    const children = node.querySelectorAll("[data-palette-id]");
    for (const child of children) {
        if (child.classList.contains("highlighted")) {
            child.classList.remove("highlighted");
            if (!id) {
                break;
            }
        }
        const childId = child.getAttribute("data-palette-id");
        if (childId && childId === id) {
            if (!child.classList.contains("highlighted")) {
                child.classList.add("highlighted");
            }
        }
    }
}

export function currentHighlightedItem(node?: HTMLDivElement) {
    let id: string | undefined = undefined;
    if (!node) {
        return id;
    }
    const children = node.querySelectorAll("[data-palette-id]");
    for (const child of children) {
        if (child.classList.contains("highlighted")) {
            id = child.getAttribute("data-palette-id") ?? undefined;
            break;
        }
    }
    return id;
}

const paletteAttr = "data-palette-id";

const linkItem = categMap.find((obj) => obj.categ === ActionCategory.Link && !obj.parentId);

let openCond: boolean = false;

function CommandPaletteEventHandler(props: WithChildren) {
    const {activeCateg: activeCategory, setPaletteActiveCateg} = usePaletteActiveCateg();
    const {excludedItems: currentExcludedItems} = usePaletteExcludedItems();
    const hostType = useHostType();
    const myParticipant = useMyParticipant();
    const isAssistant = rbac({rbac: myParticipant.rbac}, "session.isAssistant");
    const {isGuest, isAuthenticated} = useUserInfo();
    const {isPreJoin} = useIsPreJoin();
    const location = useLocation();
    const session = useSession();
    const isMacOs = browserInfo.isMacOS();
    const [preventPaletteOpen, setOpenIsPrevented] = useState(false);
    useClientEvent("prevent-palette-open", (isPreventOpen) => {
        setOpenIsPrevented(isPreventOpen);
    });
    useClientEvent("palette-keyboard-navigation", ({key}) => {
        switch (key) {
            case "ArrowUp": {
                itemHighlight("prev");
                break;
            }
            case "ArrowDown": {
                itemHighlight("next");
                break;
            }
            case "Enter": {
                const event = new KeyboardEvent("keydown", {key: "Enter", code: "Enter"});
                jumptToItem(event);
                break;
            }
            case "Escape": {
                setOpen(false);
                break;
            }
        }
    });

    const openPaletteSectionFromKey = (e: KeyboardEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        const itemByKey = findActionCategItemByShortcutKey(e.key as NonNullable<ActionCategTreeItem["ctrlShortcut"]>);
        if (!itemByKey || excluded.current.includes(itemByKey.id)) {
            return;
        }
        if (activeCateg.current.name !== itemByKey.categ) {
            setPaletteActiveCateg({
                id: itemByKey.id,
                name: itemByKey.categ,
                parentId: itemByKey.parentId,
                searchStr: undefined,
                isForced: false,
            });
            if (!isOpen.current) {
                setOpen(true);
            }
        }
    };

    useClientEvent("palette-open-section", ({section}) => {
        let keyboardEvent = new KeyboardEvent("keydown", {key: "a", code: "KeyA"});
        switch (section) {
            case "paletteShareAgenda": {
                keyboardEvent = new KeyboardEvent("keydown", {key: "a", code: "KeyA"});
                break;
            }
            case "paletteShareFile": {
                keyboardEvent = new KeyboardEvent("keydown", {key: "f", code: "KeyF"});
                break;
            }
            case "paletteShareLink": {
                keyboardEvent = new KeyboardEvent("keydown", {key: "l", code: "KeyL"});
                break;
            }
            case "paletteShareTool": {
                keyboardEvent = new KeyboardEvent("keydown", {key: "e", code: "KeyT"});
                break;
            }
        }
        openPaletteSectionFromKey(keyboardEvent);
    });

    const canOpenCond =
        !preventPaletteOpen &&
        (session.onboardingType !== SessionOnboardingType.FirstStrike && hostType === HostType.Player
            ? !isPreJoin && (myParticipant.isOwner || (isAssistant && !isGuest)) && !session.childOfBreakoutRooms
                ? true
                : false
            : [HostType.Files, HostType.Resources].includes(hostType) && activeCategory.isForced && activeCategory.name
            ? true
            : hostType === HostType.Templates
            ? !!makeTemplateIdFromRoute(location.pathname) && activeCategory.isForced
            : hostType === HostType.Event && location.pathname.includes("session-agenda")
            ? true
            : hostType === HostType.Rooms && location.pathname.includes("agenda")
            ? true
            : false);

    const canOpen = useRef(canOpenCond);
    canOpen.current = canOpenCond;
    // const canOpen = useRef(true);

    const [open, setOpenState] = useState(false);
    // This is used only to tell if the palette is open or not, if used stateful two palettes are rendered
    const [, setPaletteOpenGlobal] = usePaletteOpen();
    const isOpen = useRef<boolean>(open);
    isOpen.current = open;

    const setOpen = useCallback((mode: boolean | ((currentMode: boolean) => boolean)) => {
        if (canOpen.current) {
            if (designer.state.getDesignerCommitState()) {
                return;
            }

            setOpenState(mode);
            setPaletteOpenGlobal(mode);
            // we should be able to close it regardless of the canOpen state
        } else if (isOpen.current) {
            setOpenState(mode);
            setPaletteOpenGlobal(mode);
        }
    }, []);

    useEffect(() => {
        if (preventPaletteOpen && isOpen.current) {
            setOpen(false);
        }
    }, [preventPaletteOpen]);

    // we use this hack to prevent re-binding the event listeners on state changes
    // cause they're pretty abundant
    const activeCateg = useRef(activeCategory);
    activeCateg.current = activeCategory;

    const excluded = useRef(currentExcludedItems);
    excluded.current = currentExcludedItems;

    const itemHighlight = useCallback((mode: "prev" | "next") => {
        const root = document.getElementById("palette-content-root") as HTMLDivElement;
        const currentId = currentHighlightedItem(root);
        const children = Array.from(root.querySelectorAll(`[${paletteAttr}]`));
        const currentIdx = children.findIndex((el) => el.getAttribute(`${paletteAttr}`) === currentId);
        let nextItem = children[currentIdx + (mode === "next" ? 1 : -1)];
        if (nextItem === undefined) {
            nextItem = children[mode === "next" ? 0 : children.length - 1];
        }
        if (nextItem !== undefined) {
            nextItem.setAttribute("data-direction", mode);
            triggerMouseEnter(nextItem as HTMLElement);
        }
    }, []);

    const jumptToItem = useCallback((e: KeyboardEvent) => {
        const root = document.getElementById("palette-content-root") as HTMLDivElement;
        for (const child of root.querySelectorAll(`[${paletteAttr}]`)) {
            if (child.classList.contains("highlighted")) {
                (child as HTMLElement).click();
                break;
            }
        }
    }, []);

    const onKeyUp = useCallback((e: KeyboardEvent) => {
        const key = e.key ? e.key.toLowerCase() : undefined;
        const keyCond = isMacOs ? e.metaKey : e.ctrlKey;
        if (keyCond || e.altKey || !key) {
            return;
        }
        switch (key) {
            /**
             * backspace binding is done directly on the input (Header component)
             * and... NOTE!
             * is done onKeyDown
             * because we need to allow one more (backspace) keystroke
             * after the input has been emptied
             */
            // case "backspace": {
            //     break;
            // }
            case "arrowup":
            case "arrowdown": {
                itemHighlight(key === "arrowup" ? "prev" : "next");
                break;
            }
            case "enter": {
                jumptToItem(e);
                break;
            }
            default: {
                break;
            }
        }
    }, []);

    const togglePalette = useCallback((e: KeyboardEvent) => {
        const keyCond = isMacOs ? e.metaKey : e.ctrlKey;
        const key = e.key ? e.key.toLowerCase() : undefined;
        if (!keyCond) {
            if (key === "escape" && isOpen.current) {
                setOpen(false);
            }
            return;
        }

        if (keyCond && key === "k") {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();

            setOpen((c) => !c);
            return;
        }

        const action = checkGlobalKeybinds(e);

        if (!action) {
            return;
        }

        if (action?.includes("palette")) {
            openPaletteSectionFromKey(e);
        }
    }, []);

    const onArtifactMessage = useCallback((event: MessageEvent) => {
        const {origin, data} = event;

        if (origin === window.origin && data.source === "pspdfkit") {
            const event = new KeyboardEvent(data.type, data.payload);
            window.dispatchEvent(event);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            designer.myProductToolInPalette();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        window.addEventListener("keydown", togglePalette);
        window.addEventListener("message", onArtifactMessage, {capture: true});
        return () => {
            window.removeEventListener("keydown", togglePalette);
            window.removeEventListener("message", onArtifactMessage);
        };
    }, []);

    const rootRef = useRef<HTMLDivElement | null>(null);

    const outsideClick = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
        if (!rootRef.current || !isOpen.current) {
            return;
        }

        if ((e.target as any).getAttribute("data-extra") === "palette-tooltip-clickable") {
            return;
        }

        const parent = (e.target as any).parentElement;
        if (parent && parent.getAttribute("data-extra") === "palette-tooltip-clickable") {
            return;
        }

        if (rootRef.current.contains(e.target as any)) {
            return;
        }
        setOpen(false);
    }, []);

    const setBackdropStuff = useCallback((closed: boolean) => {
        const root = document.getElementById("command-palette-root");
        if (!root) {
            return;
        }
        if (!closed) {
            root.style.zIndex = "9999";
            root.style.background = "rgba(36, 36, 36, 0.1)";
        } else {
            root.style.zIndex = "-1";
            root.style.background = "none";
        }
    }, []);

    useEffect(() => {
        if (open) {
            setBackdropStuff(false);
            window.addEventListener("keyup", onKeyUp, false);
            window.addEventListener("mousedown", outsideClick);
            clientEvents.emit("palette-open", true);

            apollo.client
                .mutate({
                    mutation: SyncGoogleDriveDocument,
                })
                .catch((err) => {});
        } else {
            clientEvents.emit("palette-open", false);
        }
        return () => {
            setBackdropStuff(true);
            window.removeEventListener("keyup", onKeyUp);
            window.removeEventListener("mousedown", outsideClick);
        };
    }, [open]);

    return open ? (
        <div ref={rootRef} className={cls("flex flex-col overflow-hidden", classes.commandPaletteRoot)}>
            {props.children}
        </div>
    ) : null;
}

function PaletteCleanupOnClose() {
    const {setActionArg} = usePaletteActionArg();
    const {setPaletteActiveCateg} = usePaletteActiveCateg();
    const {setResultList} = usePaletteResultList();
    const {setPaletteResults} = usePaletteResults();
    const {setRootSearchStr} = usePaletteRootSearchString();
    const {setSearchFilters} = usePaletteSearchFilters();
    const {setActionCallback} = usePaletteActionCallback();
    const {setExcludedItems} = usePaletteExcludedItems();
    useEffect(() => {
        return () => {
            setExcludedItems([]);
            setRootSearchStr("");
            setActionArg(undefined);
            setPaletteActiveCateg(paletteActiveCategDefault);
            setResultList([]);
            setPaletteResults({
                agendas: [],
                files: [],
                recent: [],
                tools: [],
            });
            setSearchFilters({});
            setActionCallback(undefined);
        };
    }, []);

    return <></>;
}

const CommandPalette = memo(
    function CommandPalette() {
        const [showLinkDetails, setShowLinkDetails] = useState(false);
        const {isMobile} = useMobile();

        return (
            <CommandPaletteEventHandler>
                <div data-id="command-palette" className={cls("flex flex-col flex00-100 overflow-hidden", classes.commandPaletteRootInner)}>
                    <CommandPaletteHeader setShowLinkDetails={setShowLinkDetails} showLinkDetails={showLinkDetails} />
                    <CommandPaletteContent>
                        {!isMobile && <EmbedLinkDescription show={showLinkDetails} />}
                        {!isMobile && <CommandPaletteFooter />}
                    </CommandPaletteContent>
                </div>
                <PaletteCleanupOnClose />
            </CommandPaletteEventHandler>
        );
    },
    () => true
);
