import Button from "@ui/cdk/Button";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";
import classes from "./style/HideSessionsUIButtons.module.scss";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import DragHandleRoundedIcon from "@material-ui/icons/DragHandleRounded";
import {cls} from "@ui/cdk/util";

function HideSessionsUIButtons() {
    const {
        isSessionsUIHidden,
        sessionsUIHeader,
        sessionsUIFooter,
        setSessionsUIHeader,
        setSessionsUIFooter,
        hoverSessionsUIPanel,
        panelCooldown,
        handlePanelCooldown,
        sessionsUIFooterHovered,
        sessionsUIHeaderHovered,
    } = useSessionsUIToggle();

    const handleToggleHeader = () => {
        if (sessionsUIHeader) {
            handlePanelCooldown();
        }
        setSessionsUIHeader(!sessionsUIHeader);
    };

    const handleToggleFooter = () => {
        if (sessionsUIFooter) {
            handlePanelCooldown();
        }
        setSessionsUIFooter(!sessionsUIFooter);
    };

    const handleHoverButton = (panel: "header" | "footer") => {
        if (!panelCooldown) {
            hoverSessionsUIPanel(panel);
        }
    };

    if (!isSessionsUIHidden) {
        return null;
    }

    return (
        <>
            <div className={cls(classes.root, classes.rootHeader)} onMouseOver={handleHoverButton.bind(null, "header")}>
                <Button
                    variant="plain"
                    size="smallest"
                    onClick={handleToggleHeader}
                    className={cls(classes.button, sessionsUIFooter && classes.buttonOpen)}
                >
                    {sessionsUIHeader || panelCooldown ? (
                        <ExpandMoreRoundedIcon />
                    ) : sessionsUIHeaderHovered ? (
                        <DragHandleRoundedIcon />
                    ) : (
                        <ExpandMoreRoundedIcon />
                    )}
                </Button>
            </div>
            <div className={cls(classes.root, classes.rootFooter)} onMouseOver={handleHoverButton.bind(null, "footer")}>
                <Button
                    variant="plain"
                    size="smallest"
                    onClick={handleToggleFooter}
                    className={cls(classes.button, sessionsUIFooter && classes.buttonOpen)}
                >
                    {sessionsUIFooter || panelCooldown ? (
                        <ExpandLessRoundedIcon />
                    ) : sessionsUIFooterHovered ? (
                        <DragHandleRoundedIcon />
                    ) : (
                        <ExpandLessRoundedIcon />
                    )}
                </Button>
            </div>
        </>
    );
}

export default HideSessionsUIButtons;
