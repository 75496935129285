import LineButton from "@ui/cdk/LineButton";
import Tooltip from "@ui/cdk/Tooltip";
import classes from "./style/AgendaItemCreateButton.module.scss";
import Typography from "@ui/cdk/Typography";
import {isMacOS} from "@workhorse/util";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {ReactComponent as InsertBelow} from "../../../../assets/media/insert-agenda-item-below.svg";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useTranslation} from "react-i18next";

type AgendaItemCreateButtonProps = {
    createNewAgendaItem: () => void;
    inEventSettings?: boolean;
};
const AgendaItemCreateButton = ({createNewAgendaItem, inEventSettings}: AgendaItemCreateButtonProps) => {
    const {t} = useTranslation();
    const macOS = isMacOS();
    const commitInProgress = useDesignerSessionCommitState();

    return (
        <div className={cls("flex flex-center-all", inEventSettings && classes.eventRoot)}>
            <div className="flex flex-center-all flex11-100">
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={
                        <>
                            <Typography className={classes.tooltipText}>{t("agenda.items.add_new_item")}</Typography>
                            <div className="fullw flex flex-center-all ">
                                <span className={classes.symbol}>{macOS ? "⌥" : "Alt"}</span>
                                <span className={classes.symbol}>W</span>
                            </div>
                        </>
                    }
                    arrow
                >
                    <div
                        data-id="add-new-button"
                        className={cls("flex flex-align-center", classes.addBlock, inEventSettings && classes.inEvent)}
                        onClick={commitInProgress ? undefined : createNewAgendaItem}
                        style={{opacity: commitInProgress ? 0.5 : 1}}
                    >
                        <div className={classes.line}></div>
                        <Button
                            className={cls(classes.addBlockButton, "editing")}
                            disabled={commitInProgress}
                            variant="tertiary"
                            data-id="agendas-button-add-block"
                            noFocusBorder
                        >
                            <InsertBelow />
                            {t("agenda.items.add_new_item")}
                        </Button>
                        <div className={classes.line}></div>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default AgendaItemCreateButton;
