import Popover from "@ui/core/components/Popover";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/AgendaSettings.module.scss";
import Typography from "@ui/cdk/Typography";
import Switch from "@ui/cdk/Switch";
import Tooltip from "@ui/cdk/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {mutate} from "@workhorse/dataApi";
import {ConferenceParticipantStatus, SessionFlagsType} from "@generated/data";
import {rbac} from "@workhorse/api/user";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import {useTranslation} from "react-i18next";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type AgendaSettingsOptionsProps = {
    agendaSettingsAnchorEl: HTMLElement | null;
    onAgendaSettingsClose?: () => void;
    inLobby: boolean;
    isAdmin: boolean;
    session: SessionData;
};

const AgendaSettingsOptions = (props: AgendaSettingsOptionsProps) => {
    const {t} = useTranslation();
    const {session, agendaSettingsAnchorEl, onAgendaSettingsClose} = props;

    const currentParticipant = useParticipantsStore(
        useShallow(({currentParticipant}) => ({
            id: currentParticipant?.id,
            isOwner: currentParticipant?.isOwner ?? false,
            isSpeaker:
                currentParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker || !!currentParticipant?.speakerDetails?.id,
        }))
    );
    const isOwner = currentParticipant?.isOwner;
    const isAssistant = rbac(currentParticipant.id, "session.isAssistant");
    const isSpeaker = currentParticipant.isSpeaker;

    const isAgendaAllowed = sessionFlagBasedPermission(session.allowAgendaType, {
        owner: isOwner,
        assistant: isAssistant,
        speaker: isSpeaker,
    });

    const handleToggleHideAgenda = async () => {
        if (!isAgendaAllowed) {
            return;
        }
        await mutate("UpdateOneSessionDocument", {
            variables: {
                where: {
                    id: session.id,
                },
                hasParticipants: false,
                data: {
                    allowAgendaType:
                        session?.allowAgendaType === SessionFlagsType.Everyone ? SessionFlagsType.Hosts : SessionFlagsType.Everyone,
                },
            },
        });
    };

    return (
        <div>
            {agendaSettingsAnchorEl ? (
                <Popover
                    id="agenda-settings-popover"
                    open={Boolean(agendaSettingsAnchorEl)}
                    anchorEl={agendaSettingsAnchorEl}
                    onClose={onAgendaSettingsClose}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    onClick={(e) => e.stopPropagation()}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    classes={{
                        paper: cls(classes.paper),
                    }}
                >
                    <div className={cls(classes.agendaSettingsOptionsWrapper, "flex flex-col")}>
                        <div className="flex flex-row flex-justify-between flex-align-center fullw gap-20">
                            <div className="flex flex-row flex-align-center gap-5">
                                <Typography color="secondary" fontWeight="bold" noWrap>
                                    {t("agenda.menu.agenda_visible") ?? "Agenda visible to attendees"}
                                </Typography>
                                <Tooltip
                                    title={
                                        t("agenda.menu.agenda_visible_description") ??
                                        "If disabled, only you and your co-hosts will be able to see the agenda."
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <InfoOutlinedIcon className={classes.tooltipIcon} />
                                </Tooltip>
                            </div>
                            <Switch
                                data-id="hide-agenda"
                                color="primary"
                                checked={session.allowAgendaType === SessionFlagsType.Everyone}
                                onChange={handleToggleHideAgenda}
                            />
                        </div>
                    </div>
                </Popover>
            ) : (
                <div className={cls(classes.agendaSettingsOptionsWrapper, "flex flex-col")}>
                    <div className="flex flex-row flex-justify-between flex-align-center fullw gap-20">
                        <div className="flex flex-row flex-align-center gap-5">
                            <Typography color="secondary" fontWeight="bold" noWrap>
                                {t("agenda.menu.agenda_visible") ?? "Agenda visible to attendees"}
                            </Typography>
                            <Tooltip
                                title={
                                    t("agenda.menu.agenda_visible_description") ??
                                    "If disabled, only you and your co-hosts will be able to see the agenda."
                                }
                                arrow
                                placement="top"
                            >
                                <InfoOutlinedIcon className={classes.tooltipIcon} />
                            </Tooltip>
                        </div>
                        <Switch
                            data-id="hide-agenda"
                            color="primary"
                            checked={session.allowAgendaType === SessionFlagsType.Everyone}
                            onChange={handleToggleHideAgenda}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AgendaSettingsOptions;
