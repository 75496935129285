import {HostType} from "@generated/data";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {useLocation} from "@workhorse/api/routing";
import {createContextProvider} from "@workhorse/api/utils/context";
import {makeHostTypeFromRoute} from "@workhorse/util/makeHostTypeFromRoute";
import {setPlayStartTs} from "@workhorse/pages/player/components/genericVideoPlayerStateVars";
import apollo from "@workhorse/api/apollo";
import {useSessionIdFromRoute} from "./CurrentSessionIdProvider";
import {writeQuery} from "@workhorse/dataApi";

export function evictLocalQueries(sessionId: string, workspaceId?: string) {
    if (sessionId === "templates_template") {
        return;
    }

    writeQuery("DesignerEditStateDocument", {
        data: {
            designerEditState: {
                __typename: "DesignerEditState",
                sessionId: "",
                isEditing: false,
            },
        },
        broadcast: true,
    });
    apollo.cache.evict({
        fieldName: "sessionIdOfRoom",
        id: "ROOT_QUERY",
    });
    apollo.cache.evict({id: "ROOT_QUERY", fieldName: `agendaItems:{"where":{"sessionId":{"equals":"${sessionId}"}}}`});
    apollo.cache.evict({id: "ROOT_QUERY", fieldName: `session:{"where":{"id":"${sessionId}"}}`});
    apollo.cache.evict({
        id: "ROOT_QUERY",
        fieldName: `artifacts:{"where":{"macroartifactforsession_sessionId":{"equals":"${sessionId}"}}}`,
    });
    apollo.cache.evict({id: "ROOT_QUERY", fieldName: `participants:{"where":{"sessionId":{"equals":"${sessionId}"}}}`});
    apollo.cache.evict({id: "ROOT_QUERY", fieldName: `getCurrentParticipant({"sessionId":"${sessionId}"})`});
    apollo.cache.evict({
        id: "ROOT_QUERY",
        fieldName: `sessionRecordings({"where":{"id": "${sessionId}"}})`,
    });
    if (workspaceId) {
        apollo.cache.evict({id: "ROOT_QUERY", fieldName: `agendaTemplates({"workspaceId":"${workspaceId}"})`});
    }

    apollo.cache.gc();
}

function useHostTypeStore() {
    const location = useLocation();
    const sessionId = useSessionIdFromRoute();
    const nextHostType = makeHostTypeFromRoute(location.pathname);

    const hostType = nextHostType || HostType.Notfound;

    const prevHostType = useRef(hostType);
    const prevSessionId = useRef(sessionId);

    const sessionIdChanged = prevSessionId.current && sessionId !== prevSessionId.current ? true : false;

    const hostTypeChanged = hostType !== prevHostType.current;

    if (sessionIdChanged && prevSessionId.current) {
        if (![HostType.Templates].includes(prevHostType.current)) {
            evictLocalQueries(prevSessionId.current);
        }
    }

    if (hostTypeChanged && prevHostType.current === HostType.Feedback && prevSessionId.current) {
        evictLocalQueries(prevSessionId.current);
    }

    useEffect(() => {
        if (hostTypeChanged) {
            if (hostType !== HostType.Player) {
                setPlayStartTs(null);
            }
        }
        prevHostType.current = hostType;
        prevSessionId.current = sessionId;
    }, [hostType, sessionId]);

    return hostType;
}

export const [HostTypeProvider, useHostType] = createContextProvider(
    {
        name: "HostTypeProvider",
        strict: true,
    },
    useHostTypeStore
);
