import {SessionUpdateInput} from "../../generated/data-types";
import {Session, OmitTypenames} from "../types";

type EventInput = OmitTypenames<NonNullable<NonNullable<NonNullable<Session>["session"]>["event"]>>;
type EventUpdatePayload = NonNullable<SessionUpdateInput["event"]>;

const eventUpdateKeys: Array<keyof EventInput> = [
    "bannerUrl",
    "colorPalette",
    "photoUrl",
    "slug",
    "socialLinks",
    "isPublic",
    "state",
    "hasRegistration",
    "speakerOrderJson",
    "requireApproval",
    "registrationForm",
    "utm",
    "embedUrl",
    "afterRegistrationRedirectUrl",
    "afterRegistrationOfferId",
    "afterRegistrationOffer",
    "maxSeats",
    "seatsTaken",
    "language",
];

function buildEventUpdatePayload(input: EventInput): EventUpdatePayload {
    const payload: EventUpdatePayload["update"] = {};
    // biome-ignore lint/complexity/noForEach: <explanation>
    eventUpdateKeys.forEach((k) => {
        switch (k) {
            case "bannerUrl": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "colorPalette": {
                if (k in input) {
                    payload[k] = input[k];
                }
                break;
            }
            case "photoUrl": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "slug": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "isPublic": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "socialLinks": {
                if (k in input) {
                    payload[k] = input[k];
                }
                break;
            }
            case "registrationForm": {
                if (k in input) {
                    payload[k] = input[k];
                }
                break;
            }
            case "utm": {
                if (k in input) {
                    payload[k] = input[k];
                }
                break;
            }
            case "state": {
                if (k in input) {
                    payload[k] = input[k];
                }
                break;
            }

            case "hasRegistration": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }

            case "requireApproval": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }

            case "speakerOrderJson": {
                if (k in input) {
                    payload[k] = input[k];
                }
                break;
            }

            case "embedUrl": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "afterRegistrationRedirectUrl": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }

            case "afterRegistrationOfferId": {
                if (k in input) {
                    if (input[k]) {
                        payload["afterRegistrationOffer"] = {
                            connect: {id: input[k]!},
                        };
                    } else {
                        payload["afterRegistrationOffer"] = {
                            disconnect: true,
                        };
                    }
                }
                break;
            }

            case "maxSeats": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "language": {
                if (k in input) {
                    payload[k] = {set: input[k]};
                }
                break;
            }

            default:
                break;
        }
    });
    return {
        update: payload,
    };
}

export default buildEventUpdatePayload;
