import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "../../providers/OnboardingSessionProvider/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import Button from "@ui/cdk/Button";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export function OnboardingSlideshowControls(props: WithTooltipChildren) {
    const {tooltip, next} = useOnboardingSession();

    //makes the component rerender when the sessionsUIHidden changes
    const {sessionsUIHidden} = useSessionsUIToggle();

    const skip = !(tooltip === "slideshow-controls" || tooltip === "tool-controls");

    let title = (
        <>
            <Typography className="title" fontWeight="bolder">
                Embed presentations
            </Typography>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                You change slides for everybody by default, but can also let participants click through slides individually.
                <br /> <br />
                Bye-bye share screen!
            </Typography>
        </>
    );

    const handleButtonClick = () => {
        next({
            saveTooltip: true,
        });
    };

    if (tooltip === "tool-controls") {
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        Sharing multiple images or slides?
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    From here you can choose which one to show next.
                </Typography>
                <div className="mt-12 flex fullw flex-justify-end">
                    <Button variant="secondary" size="small" onClick={handleButtonClick}>
                        Got it
                    </Button>
                </div>
            </>
        );
    }

    return (
        <OnboardingTooltip tooltipId={tooltip ?? "-1"} skip={skip} title={title} placement="bottom" arrow={true}>
            {props.children}
        </OnboardingTooltip>
    );
}
