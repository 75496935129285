import {LinearProgress} from "@material-ui/core";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import Popover, {PopoverActions} from "@ui/core/components/Popover";
import {useOrganizationAccess} from "@workhorse/api/access/hooks";
import {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {markAsSeen, SeenSegment, seenSegmentToObj, useSeenSection} from "@workhorse/api/seen";
import OnboardingEmbedSessionDialog from "@workhorse/pages/user/onboarding/components/onboarding-session-dialog/OnboardingEmbedSessionDialog";
import OnboardingSessionDialog from "@workhorse/pages/user/onboarding/components/onboarding-session-dialog/OnboardingSessionDialog";
import {useHistory} from "react-router";
import {useFeaturesOnboardingConfiguration} from "../provider";
import OnboardingFeaturesJourneyGoal from "./OnboardingFeaturesJourneyGoal";
import OnboardingHelCenterDialog from "./OnboardingHelpCenterDialog";
import OnboardingProductToolDialog from "./OnboardingProductToolDialog";
import classes from "./styles/OnboardingFeaturesJourneyPopup.module.scss";
import {useGlobalResizeObserver} from "@workhorse/providers/ResizeObserverProvider";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {Transition} from "react-transition-group";
import {capitalize, isMacOS} from "@workhorse/util";
import OnboardingWorkspaceDialog from "@workhorse/pages/user/onboarding/components/onboarding-workspace/OnboardingWorkspaceDialog";

type OnboardingFeaturesJourneyPopupProps = {
    journey: number;
};

function OnboardingFeaturesJourneyPopup(props: OnboardingFeaturesJourneyPopupProps) {
    const {journey} = props;

    const isMac = isMacOS();

    const actionRef = useRef<PopoverActions>(null);

    const initialJourney = useRef<number>(journey);

    const history = useHistory();
    const {configuration, journeyOpen, setJourneyOpen} = useFeaturesOnboardingConfiguration();

    const organizationAccess = useOrganizationAccess();
    const isOrganizationOwner = organizationAccess.canCreateWorkspaces();

    const [showOnboardingSessionDialog, setShowOnboardingSessionDialog] = useState<boolean>(false);
    const [showHelpCenterDialog, setShowHelpCenterDialog] = useState<boolean>(false);
    const [showProductToolDialog, setShowProductToolDialog] = useState<boolean>(false);
    const [showEmbedSessionDialog, setShowEmbedSessionDialog] = useState<boolean>(false);
    const [showWorkspaceSetupDialog, setShowWorkspaceSetupDialog] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const seenOnboardingSessionFirstStrike = useSeenSection("ONBOARDING_FEATURES.onboardingSessionFirstStrike");
    const seenOnboardingSessionSecondStrikeMember = useSeenSection("ONBOARDING_FEATURES.onboardingSessionSecondStrikeMember");
    const seenOnboardingSessionSecondStrikeOwner = useSeenSection("ONBOARDING_FEATURES.onboardingSessionSecondStrikeOwner");
    const userSeenOnboardingSessionEnd = useSeenSection("GLOBALS.userOnboardingSessionEnd");

    const seenOnboardingSessionSecondStrike = isOrganizationOwner
        ? seenOnboardingSessionSecondStrikeOwner
        : seenOnboardingSessionSecondStrikeMember;

    const handleToggleOnboardingSessionDialog = () => {
        setShowOnboardingSessionDialog((prev) => !prev);
    };

    const handleToggleHelpCenterDialog = () => {
        setShowHelpCenterDialog((prev) => !prev);
    };

    const handleToggleProductToolDialog = () => {
        setShowProductToolDialog((prev) => !prev);
    };

    const handleToggleEmbedSessionDialog = () => {
        setShowEmbedSessionDialog((prev) => !prev);
    };

    const handleWorkspaceSetupDialog = () => {
        setShowWorkspaceSetupDialog((prev) => !prev);
    };

    const handleCloseEmbedSession = () => {
        handleToggleEmbedSessionDialog();
    };

    const handleConfirmEmbedSession = () => {
        handleToggleEmbedSessionDialog();

        if (isOrganizationOwner) {
            handleToggleProductToolDialog();
        }
    };

    const toggle = () => {
        setJourneyOpen((prev) => !prev);

        actionRef.current?.updatePosition();
    };

    const handleClick = async (segment: Exclude<SeenSegment, "GLOBALS.incident">, href?: string) => {
        if (href) {
            const objSegment = seenSegmentToObj(segment, false);
            await markAsSeen(objSegment, true);
            history.push(href);

            return;
        }

        if (segment === "ONBOARDING_FEATURES.onboardingWorkspaceSetup") {
            handleWorkspaceSetupDialog();
            return;
        }

        if (segment === "ONBOARDING_FEATURES.onboardingSessionFirstStrike") {
            handleToggleOnboardingSessionDialog();
            return;
        }

        if (
            ["ONBOARDING_FEATURES.onboardingSessionSecondStrikeMember", "ONBOARDING_FEATURES.onboardingSessionSecondStrikeOwner"].includes(
                segment
            )
        ) {
            setShowTooltip(false);
            handleToggleEmbedSessionDialog();
            return;
        }

        if (segment === "ONBOARDING_FEATURES.helpCenter") {
            handleToggleHelpCenterDialog();
            return;
        }
    };

    useEffect(() => {
        let t: NodeJS.Timeout | null = null;
        if (userSeenOnboardingSessionEnd && seenOnboardingSessionSecondStrike) {
            t = setTimeout(() => {
                setShowTooltip(true);
            }, 1000);
        }

        return () => {
            if (t !== null) {
                clearTimeout(t);
                setShowTooltip(false);
            }
        };
    }, [userSeenOnboardingSessionEnd, seenOnboardingSessionSecondStrike]);

    useEffect(() => {
        if (journey === 100 && journey > initialJourney.current) {
            setJourneyOpen(false);
            actionRef.current?.updatePosition();
        }
    }, [journey]);

    useGlobalResizeObserver(() => {
        if (actionRef.current) {
            actionRef.current.updatePosition();
        }
    });

    const onPopoverClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
    };

    return (
        <>
            <Transition mountOnEnter unmountOnExit in={journeyOpen} timeout={400}>
                {(state) => (
                    <Popover
                        open
                        hideBackdrop
                        disablePortal
                        disableAutoFocus
                        disableEnforceFocus
                        disableEscapeKeyDown
                        disableRestoreFocus
                        disableScrollLock
                        classes={{
                            root: classes.root,
                            paper: cls(classes.paper, classes[`paper${capitalize(state)}`], isMac && "isMacOS"),
                        }}
                        action={actionRef}
                        anchorReference="anchorPosition"
                        anchorPosition={{top: screen.height, left: screen.width}}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        onClose={onPopoverClose}
                    >
                        <div className={cls("py-16 px-24", classes.header)}>
                            <div className="flex flex-items-center">
                                <Typography className={classes.title}>Get started with Sessions 🚀</Typography>

                                <IconButton data-id="close-onboarding-feature-journey" className={classes.closeIconButton} onClick={toggle}>
                                    <CloseRoundedIcon />
                                </IconButton>
                            </div>

                            <Typography className={classes.subTitle}>
                                Learn how to increase your conversions through personalized video experiences.
                            </Typography>
                        </div>

                        <div className={cls("flex flex-align-center gap-12 py-10 px-24", classes.progressWrapper)}>
                            <LinearProgress variant="determinate" value={journey} className={classes.progress} />
                            <Typography className={classes.progressText}>{journey.toFixed(0)}%</Typography>
                        </div>

                        <div>
                            {Object.entries(configuration)
                                .filter(([k, v]) => v.quest)
                                .map(([feature, {title, subtitle, href}]) => {
                                    const segment = `ONBOARDING_FEATURES.${feature}` as Exclude<SeenSegment, "GLOBALS.incident">;

                                    const hasTooltip =
                                        showTooltip &&
                                        segment === "ONBOARDING_FEATURES.onboardingSessionFirstStrike" &&
                                        !seenOnboardingSessionFirstStrike &&
                                        !showOnboardingSessionDialog;

                                    return (
                                        <Tooltip
                                            arrow
                                            title={hasTooltip ? "Continue anytime you want" : ""}
                                            placement="left"
                                            classes={{popper: classes.popper, tooltip: classes.tooltip, arrow: classes.arrow}}
                                            key={`${feature}-tooltip`}
                                            open={hasTooltip}
                                        >
                                            <div>
                                                <OnboardingFeaturesJourneyGoal
                                                    key={feature}
                                                    title={title}
                                                    subtitle={subtitle}
                                                    segment={segment}
                                                    onClick={handleClick.bind(null, segment, href)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                })}
                        </div>
                    </Popover>
                )}
            </Transition>
            {showHelpCenterDialog && <OnboardingHelCenterDialog onClose={handleToggleHelpCenterDialog} />}
            {showProductToolDialog && <OnboardingProductToolDialog onClose={handleToggleProductToolDialog} />}
            {showEmbedSessionDialog && (
                <OnboardingEmbedSessionDialog
                    type={isOrganizationOwner ? "embed-owner" : "embed-member"}
                    onConfirm={handleConfirmEmbedSession}
                    onClose={handleCloseEmbedSession}
                />
            )}
            {showOnboardingSessionDialog && <OnboardingSessionDialog onClose={handleToggleOnboardingSessionDialog} />}
            {showWorkspaceSetupDialog && <OnboardingWorkspaceDialog onClose={handleWorkspaceSetupDialog} />}
        </>
    );
}

export default memo(OnboardingFeaturesJourneyPopup, (pv, cv) => pv.journey === cv.journey);
