import {useMemo} from "@workhorse/api/rendering";
import {JSONContent} from "./api";
import {parseAsTipTapJSON, serialize} from "@api/editor";
import {cls} from "@ui/cdk/util";
import classes from "./styles/EditorSerializedContent.module.scss";

type EditorSerializedContentProps = {
    content?: string | JSONContent | null;
    className?: string;
};

const EditorSerializedContent = (props: EditorSerializedContentProps) => {
    const html = useMemo(() => {
        return serialize(parseAsTipTapJSON(props.content));
    }, [props.content]);

    if (!html) {
        return null;
    }

    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    return <div className={cls(classes.content, props.className)} dangerouslySetInnerHTML={{__html: html}} />;
};

export default EditorSerializedContent;
