import {DesignerApiSessionUpdatePayload as Session} from "@workhorse/declarations/dataTypes";

export abstract class DesignerAction<TInput = any, TInternalState = Partial<Session>> {
    protected nextState?: TInternalState;
    protected previousState?: TInternalState;

    constructor(protected input: TInput) {}

    public abstract commit(): string;

    public canUndo(): boolean {
        return this.previousState !== undefined;
    }

    public canRedo(): boolean {
        return this.nextState !== undefined;
    }

    public setPreviousState(state: TInternalState | undefined): void {
        this.previousState = state;
    }

    public setNextState(state: TInternalState | undefined): void {
        this.nextState = state;
    }

    public getPreviousState(): TInternalState | undefined {
        return this.previousState;
    }

    public getNextState(): TInternalState | undefined {
        return this.nextState;
    }
}

export abstract class CombinedDesignerAction<TInput = any, TInternalState = any> extends DesignerAction<void, any> {
    protected nextState?: TInternalState;
    protected previousState?: TInternalState;

    protected commitResults: string[] = [];
    private cachedActions: DesignerAction<any, any>[] = [];

    constructor(protected actions: (() => DesignerAction<any, any>)[], protected result: () => string) {
        super();
    }

    public commit() {
        for (let action of this.actions) {
            const actionInstance = action();
            this.cachedActions.push(actionInstance);
            this.commitResults.push(actionInstance.commit());
        }

        return this.result();
    }

    public canUndo(): boolean {
        return this.previousState !== undefined;
    }

    public canRedo(): boolean {
        return this.nextState !== undefined;
    }

    public setPreviousState(state: TInternalState | undefined): void {
        this.previousState = state;
    }

    public setNextState(state: TInternalState | undefined): void {
        this.nextState = state;
    }

    public getPreviousState(): TInternalState | undefined {
        return this.previousState;
    }

    public getNextState(): TInternalState | undefined {
        return this.nextState;
    }
}
