import {RnnoiseWorkletNode, loadRnnoise} from "@sessions/noise-reduction";

type WebkitWindow = typeof window & {
    webkitAudioContext: AudioContext;
};

const AudioContext = window.AudioContext || (window as WebkitWindow).webkitAudioContext;

export class NoiseReduction {
    private audioContext = new AudioContext();
    private source: MediaStreamAudioSourceNode;
    private rnnoiseNode?: RnnoiseWorkletNode;
    private gainNode?: GainNode;
    private destination: MediaStreamAudioDestinationNode;
    private destroyed = false;

    constructor(private stream: MediaStream) {
        this.source = this.audioContext.createMediaStreamSource(stream);
        this.destination = this.audioContext.createMediaStreamDestination();

        this.load().catch((e) => {
            console.error(e);
            this.destroy();
        });
    }

    getOutputStream(): MediaStream | undefined {
        return this.destination.stream;
    }

    async load() {
        const wasmUrl = `${window.location.origin}/wasm/rnnoise.wasm`;
        const simdUrl = `${window.location.origin}/wasm/rnnoise_simd.wasm`;
        const workletUrl = `${window.location.origin}/worklet/noise-reduction.js`;
        const modelUrl = `${window.location.origin}/rnnn/sh.rnnn`;

        // const [wasmBinary, model] = await Promise.all([
        //     loadRnnoise({
        //         url: wasmUrl,
        //         simdUrl,
        //     }),
        //     this.loadModel(modelUrl),
        // ]);

        const wasmBinary = await loadRnnoise({
            url: wasmUrl,
            simdUrl,
        });

        if (this.destroyed) {
            return;
        }

        await this.audioContext.audioWorklet.addModule(workletUrl);

        if (this.destroyed) {
            return;
        }

        this.rnnoiseNode = new RnnoiseWorkletNode(this.audioContext, {
            maxChannels: 2,
            wasmBinary,
            // model,
        });

        this.gainNode = new GainNode(this.audioContext, {gain: 1});

        this.source.connect(this.rnnoiseNode);
        this.rnnoiseNode.connect(this.gainNode);
        this.gainNode.connect(this.destination);
    }

    private async loadModel(url: string) {
        try {
            const result = await fetch(url);
            const modelString = await result.text();
            const modelCString = new TextEncoder().encode(modelString + "\x00");
            return modelCString;
        } catch (err) {
            console.log(err);
            return undefined;
        }
    }

    destroy() {
        this.destroyed = true;
        this.destination.disconnect();
        this.gainNode?.disconnect();
        this.rnnoiseNode?.disconnect();
        this.source.disconnect();

        if (this.audioContext.state !== "closed") {
            this.audioContext.close();
        }
    }
}
