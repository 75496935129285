import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useMemo} from "@workhorse/api/rendering";
import {ReactComponent as EnterIcon} from "../../assets/media/enter_icon.svg";
import {ReactComponent as EscIcon} from "../../assets/media/esc_icon.svg";
import {ReactComponent as KeyDownIcon} from "../../assets/media/keydown_icon.svg";
import {ReactComponent as KeyUpIcon} from "../../assets/media/keyup_icon.svg";
import {ReactComponent as TabIcon} from "../../assets/media/tab_icon.svg";
import {ActionCategory} from "./actionCategTree";
import {computeVisibleItems} from "./CommandPaletteContent";
import {useBeResultCount, usePaletteActiveCateg, usePaletteHasSearchString} from "./CommandPaletteProviders";
import {useVisibleCategItems} from "./CommandPaletteResultsProvider";
import classes from "./style/CommandPaletteFooter.module.scss";
import clientEvents, {useClientEvent} from "@api/events/client";
import {useTranslation} from "react-i18next";

type CommandPaletteFooterProps = {};
export default function CommandPaletteFooter(props: CommandPaletteFooterProps) {
    const {t} = useTranslation();

    const {activeCateg} = usePaletteActiveCateg();
    const {visibleItems} = useVisibleCategItems();
    const {hasSearchStr} = usePaletteHasSearchString();
    const beCount = useBeResultCount();

    const showItems = useMemo(() => {
        return computeVisibleItems(activeCateg, hasSearchStr, visibleItems);
    }, [activeCateg.name, activeCateg.id, activeCateg.parentId, visibleItems, hasSearchStr]);

    const showCount = useMemo(() => {
        return (activeCateg.name === ActionCategory.Link && !hasSearchStr ? 0 : showItems.length) + beCount;
    }, [showItems.length, beCount, activeCateg.name, hasSearchStr]);

    const handleClickKey = (key: "ArrowUp" | "ArrowDown" | "Enter" | "Escape") => {
        clientEvents.emit("palette-keyboard-navigation", {
            key: key,
        });
    };

    return (
        <div className={cls("fullw flex flex-align-center pl-10 pt-10 pb-10 pr-20", classes.commandPaletteFooterRoot)}>
            {showCount > 1 && (
                <>
                    <div className="fullh flex flex-items-center">
                        <TabIcon onClick={handleClickKey.bind(null, "ArrowUp")} className={classes.button} />
                        <Typography color="septenary" fontWeight="bold" className="ml-10">
                            {t("g.or") ?? "or"}
                        </Typography>
                        <KeyUpIcon className={cls("ml-10", classes.button)} onClick={handleClickKey.bind(null, "ArrowUp")} />
                        <KeyDownIcon className={cls("ml-10", classes.button)} onClick={handleClickKey.bind(null, "ArrowDown")} />
                        <Typography color="septenary" fontWeight="bold" className="ml-10">
                            {t("palette.to_navigate") ?? "to navigate"}
                        </Typography>
                    </div>
                    <div className={cls("ml-20 mr-20", classes.separator)}></div>
                </>
            )}
            <div className={cls("flex fullh", showItems.length > 0 ? "flex-justify-between" : "flex-justify-end", classes.rightContainer)}>
                {showCount > 0 && (
                    <div className="fullh flex flex-items-center">
                        <EnterIcon onClick={handleClickKey.bind(null, "Enter")} className={classes.button} />
                        <Typography color="septenary" fontWeight="bold" className="ml-10">
                            {t("palette.to_select") ?? "to select"}
                        </Typography>
                    </div>
                )}
                <div className="fullh flex flex-items-center ml-auto">
                    <EscIcon onClick={handleClickKey.bind(null, "Escape")} className={classes.button} />
                    <Typography color="septenary" fontWeight="bold" className="ml-10">
                        {t("palette.to_close") ?? "to close"}
                    </Typography>
                </div>
            </div>
        </div>
    );
}
