import {countriesMap} from "@api/countryUtils";
import {emailRegex} from "@common/validation/utils";
import {GetOrganizationMembersDocument, UpsertOrganizationMembersDocument} from "@generated/data";
import env from "@generated/environment";
import CreateIcon from "@material-ui/icons/Create";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import FileDrop from "@ui/cdk/FileDrop";
import Link from "@ui/cdk/Link";
import Select from "@ui/cdk/Select";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {useMutation} from "@workhorse/api/data";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {makeCancelable} from "@workhorse/api/utils/promise";
import {CSVAlert} from "@workhorse/components/csv";
import {useUserInfo} from "@workhorse/providers/User";
import {CSVResult, getEntriesFromCSV, csvMimeTypes} from "@workhorse/util/csv-entries";
import classes from "./MemberEditDialog.module.scss";
import {toastImportedEmails} from "./utils";

interface CSVRecord {
    email: string;
    firstname?: string | null;
    lastname?: string | null;
    country?: string | null;
}

interface CSVMember {
    email: string;
    firstName: string;
    lastName: string;
    country?: string;
}

function parseRecord(record: unknown): CSVMember | null {
    if (!recordIsValid(record)) {
        return null;
    }

    let countryCode: string | undefined;

    if (record.country != null) {
        const key = record.country.trim().toUpperCase();
        const country = countriesMap[key];
        countryCode = country?.code;
    }

    return {
        email: record.email.trim(),
        firstName: record.firstname?.trim() ?? "",
        lastName: record.lastname?.trim() ?? "",
        country: countryCode,
    };
}

function recordIsValid(record: unknown): record is CSVRecord {
    if (typeof record !== "object") {
        return false;
    }

    if (record == null) {
        return false;
    }

    const email = record["email"];

    if (typeof email !== "string" || email === "") {
        return false;
    }

    if (emailRegex.test(email) === false) {
        return false;
    }

    const firstName = record["firstname"];
    const lastName = record["lastname"];

    if (firstName?.includes("%") || lastName?.includes("%")) {
        return false;
    }

    return true;
}

interface Props {
    open: boolean;
    existingEmails?: string[];
    onClose: () => void;
    setMembersLoading?: (isLoading: boolean) => void;
}

export function MemberCSVDialog(props: Props) {
    const imperativeRef = useRef<DialogImperativeRef>(null);
    const user = useUserInfo();
    const [file, setFile] = useState<File | null | undefined>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [csvResult, setCSVResult] = useState<CSVResult<CSVMember> | null>(null);
    const [workspaceIds, setWorkspaceIds] = useState<string[]>([user.activeWorkspace?.id]);
    const [upsertMembers] = useMutation(UpsertOrganizationMembersDocument);

    const workspaceIdsOptions = user.workspacePermissions.map((w) => ({
        value: w.workspace.id,
        text: w.workspace.name,
    }));

    // const activeWorkspaceId = user.activeWorkspace?.id;

    const duplicateEmails = useMemo(() => {
        if (csvResult == null) {
            return 0;
        }
        if (props.existingEmails == null) {
            return 0;
        }
        if (csvResult.entries.length === 0 || props.existingEmails.length === 0) {
            return 0;
        }

        return csvResult.entries.filter((member) => props.existingEmails?.includes(member.email.toLowerCase())).length;
    }, [props.existingEmails, csvResult]);

    useEffect(() => {
        imperativeRef.current?.toggle?.(props.open);
    }, [props.open]);

    useEffect(() => {
        if (!file) {
            setCSVResult(null);
            return;
        }

        const cancelable = makeCancelable(getEntriesFromCSV(file, ["email"], parseRecord));
        cancelable.then(setCSVResult);

        return () => {
            cancelable.cancel();
        };
    }, [file]);

    const handleFile = (file?: File) => {
        if (!file) {
            return;
        }

        if (!csvMimeTypes.includes(file.type)) {
            setFile(null);
            setError(`${file.name} is not a CSV file.`);
            return;
        }

        setFile(file);
        setError(undefined);
    };

    const onSubmit = async () => {
        if (!csvResult?.entries.length) {
            return;
        }

        props.onClose();
        props?.setMembersLoading?.(true);
        await upsertMembers({
            variables: {
                membersToUpsert: csvResult.entries.map((p) => ({...p, workspaceIds})),
                sendInviteEmail: true,
            },
        })
            .then((res) => {
                const optimisticMembers = [
                    ...(res?.data?.upsertMembersOfOrganization.newMembers ?? []),
                    ...(res?.data?.upsertMembersOfOrganization.updatedMembers ?? []),
                ];
                const created = res.data?.upsertMembersOfOrganization.newMembers.length ?? 0;
                const updated = res.data?.upsertMembersOfOrganization.updatedMembers.length ?? 0;
                const skipped = res.data?.upsertMembersOfOrganization.skippedMembersCount ?? 0;
                toastImportedEmails(created, updated, skipped);

                const existing = apollo.client.readQuery({
                    query: GetOrganizationMembersDocument,
                    variables: {
                        organizationId: user.activeOrganizationPermission.organization.id,
                        excludeSelf: true,
                    },
                });

                apollo.client.writeQuery({
                    query: GetOrganizationMembersDocument,
                    variables: {
                        organizationId: user.activeOrganizationPermission.organization.id,
                        excludeSelf: true,
                    },
                    data: {
                        ...existing,
                        __typename: "Query",
                        organization: {
                            ...existing?.organization,
                            __typename: "Organization",
                            id: existing?.organization?.id ?? "",
                            members: [...(existing?.organization?.members ?? []), ...optimisticMembers],
                        },
                    },
                });

                props?.setMembersLoading?.(false);
            })
            .catch((err) => {
                console.log("err on upsertMembers in CSV Dialog", err);
                toast("Something went wrong while importing your members. Please try again.", {type: "error"});
                props?.setMembersLoading?.(false);
            });

        setFile(null);
        setCSVResult(null);
    };

    const canImport = (csvResult != null && csvResult.entries.length > 0) || workspaceIds.length > 0;
    const importCount = csvResult?.entries.length ?? 0;
    const toCreate = importCount - duplicateEmails;

    return (
        <Dialog
            imperativeRef={imperativeRef}
            onClose={props.onClose}
            BackdropProps={{
                className: "standard-backdrop",
            }}
            classes={{
                container: "standard-dialog-container",
            }}
            PaperProps={{
                className: cls("standard-dialog-paper", classes.dialogPaper),
            }}
        >
            <div className={classes.header}>
                <CreateIcon className={classes.iconEdit} />
                <Typography>Import members</Typography>
            </div>

            <div className="flex flex-col">
                <div className={classes.dialogContent}>
                    <FileDrop
                        accept={[".csv", ...csvMimeTypes].join(", ")}
                        multiple={false}
                        error={error}
                        onDrop={(files) => handleFile(files?.[0])}
                        onBrowse={(files) => handleFile(files?.[0])}
                        title="Drag a file and drop it here"
                        subtitle="You can upload a .csv file directly from your computer."
                    >
                        {csvResult && (
                            <CSVAlert
                                className="mt-16 fullw"
                                file={file}
                                toCreate={toCreate}
                                toUpdate={duplicateEmails}
                                invalidCSV={csvResult.invalidCSV}
                                invalidEntries={csvResult.invalidEntries}
                                totalEntries={csvResult.totalEntries}
                                entryName="member"
                                groupName="workspace"
                            />
                        )}
                    </FileDrop>

                    <Select
                        name="workspaces"
                        data-id="workspaces-select"
                        options={workspaceIdsOptions}
                        menuItemProps={{
                            classes: {
                                root: classes.menuItem,
                                selected: classes.menuItemSelected,
                            },
                        }}
                        value={workspaceIds}
                        onChange={(e) => setWorkspaceIds(e.target.value as string[])}
                        variant="standard"
                        multiple
                        displayEmpty
                        disableUnderline
                        placeholder="Select workspaces"
                        renderValue={(value: string[]) => {
                            if (value.length) {
                                return (
                                    <p>
                                        {value
                                            .map((item) => (workspaceIdsOptions ?? []).find((option) => option.value === item)?.text)
                                            .join(", ")}
                                    </p>
                                );
                            } else {
                                return <div className={classes.selectPlaceholder}>Select workspaces</div>;
                            }
                        }}
                        withCheckboxes
                        classes={{
                            root: classes.workspaceSelect,
                        }}
                        wrapperClassName={cls("mt-12", classes.workspaceSelectContainer)}
                    />

                    <Typography component="div" className="flex flex-align-center mt-12" color="tertiary">
                        You can download a blank CSV example from{" "}
                        <Link
                            data-id="csv-link"
                            href={`${env.staticServiceUrl ?? ""}/examples/example_members_v2.csv`}
                            className={classes.exampleLink}
                        >
                            here
                        </Link>
                        .
                    </Typography>
                </div>
                <div className={classes.dialogFooter}>
                    <Button variant="quaternary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Tooltip title={!canImport ? "Please add a file and select at least one workspace" : ""} arrow placement="top">
                        <Button
                            data-id="confirm"
                            variant="primary"
                            type="submit"
                            withMarginLeft={true}
                            disabled={!canImport}
                            onClick={canImport ? onSubmit : undefined}
                        >
                            Confirm
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
    );
}
