import {Session, OmitTypenames, ArrayItem} from "../types";
import {buildAgendaItemCreatePayload, buildAgendaItemArtifactCreatePayload} from "./buildAgendaItemCreatePayload";
import structuredClone from "@ungap/structured-clone";
import {SessionUpdateInput} from "../../generated/data-types";

type AgendaItemsInput = OmitTypenames<Session>["agendaItems"];
type AgendaItemsUpdatePayload = NonNullable<SessionUpdateInput["agendaItems"]>;
type AgendaItemUpdatePayload = NonNullable<ArrayItem<NonNullable<ArrayItem<AgendaItemsUpdatePayload>["update"]>>>;
type AgendaItemArtifactUpdatePayload = NonNullable<NonNullable<AgendaItemUpdatePayload["data"]["artifact"]>["update"]>;
export type AgendaItemArtifact = NonNullable<ArrayItem<Session["agendaItems"]>["artifact"]>;

export default function buildAgendaItemsUpdatePayload(input: AgendaItemsInput): AgendaItemsUpdatePayload {
    // console.log("buildAgendaItemsUpdatePayload", input);
    let deletedItems = input.filter((obj) => obj && obj.id && obj.isDeleted).map((obj) => obj.id);
    const replacedItems = input
        .filter((obj) => obj && !obj.isDeleted && obj.id && obj.oldId && !(obj.id === obj.oldId))
        .map((obj) => obj.oldId!);
    const newOrUpdatedItems = input.filter((obj) => obj && obj.id && deletedItems.indexOf(obj.id) === -1);
    const newItems = newOrUpdatedItems.filter((obj) => obj && obj.id && !obj.createdAt).map(buildAgendaItemCreatePayload);
    const updateItems = newOrUpdatedItems.filter((obj) => obj && obj.id && !!obj.createdAt).map(buildAgendaItemUpdatePayload);
    deletedItems = deletedItems.concat(replacedItems);
    return {
        ...(deletedItems.length
            ? {
                  delete: deletedItems.map((id) => ({id})),
              }
            : null),
        ...(newItems.length ? {create: newItems} : null),
        ...(updateItems.length
            ? {
                  update: updateItems,
              }
            : null),
    };
}

const agendaItemUpdateKeys: Array<Exclude<keyof AgendaItemUpdatePayload["data"], "id" | "createdAt" | "updatedAt" | "agendaTemplate">> = [
    // "agendaTemplate",
    "artifact",
    "descriptionJson",
    "duration",
    // "endedAt",
    "order",
    "startAt",
    "locked",
    "processing",
    // "timeSpentInSeconds",
    "title",
    "type",
    "agendaItemSpeakers",
    "userConfiguredDuration",
    "speakerNotes",
    "speakerNotesJson",
];

function buildAgendaItemUpdatePayload(agendaItem: NonNullable<ArrayItem<AgendaItemsInput>>): AgendaItemUpdatePayload {
    const payload: AgendaItemUpdatePayload["data"] = {};
    agendaItemUpdateKeys.forEach((k) => {
        switch (k) {
            case "artifact": {
                if (k in agendaItem) {
                    const artifact = agendaItem[k];
                    if (!artifact) {
                        break;
                    }
                    if (!artifact?.createdAt) {
                        payload[k] = {
                            create: buildAgendaItemArtifactCreatePayload(artifact),
                            // ...(artifact.oldId && artifact.id && artifact.oldId !== artifact.id
                            //     ? {
                            //           delete: true,
                            //       }
                            //     : null),
                        };
                    } else {
                        payload[k] = {
                            update: buildAgendaItemArtifactUpdatePayload(artifact),
                        };
                    }
                }
                break;
            }
            case "descriptionJson": {
                if (k in agendaItem) {
                    payload[k] = Array.isArray(agendaItem[k]) ? Object.assign({}, agendaItem[k]) : agendaItem[k];
                }
                break;
            }
            case "duration": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            // modifying endedAt is forbidden
            // case "endedAt": {
            //     if (k in agendaItem) {
            //         payload[k] = {
            //             set: agendaItem[k],
            //         };
            //     }
            //     break;
            // }
            case "locked": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "order": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "startAt": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "timeSpentInSeconds": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "processing": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "title": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "type": {
                if (k in agendaItem) {
                    payload[k] = agendaItem[k];
                }
                break;
            }
            case "userConfiguredDuration": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "agendaItemSpeakers": {
                if (k in agendaItem) {
                    const speakersPayload = buildSpeakersPayload(agendaItem[k], agendaItem);
                    payload[k] = speakersPayload;
                }
                break;
            }
            case "speakerNotes": {
                if (k in agendaItem) {
                    payload[k] = {
                        set: agendaItem[k],
                    };
                }
                break;
            }
            case "speakerNotesJson": {
                if (k in agendaItem) {
                    payload[k] = Array.isArray(agendaItem[k]?.content) ? Object.assign({}, agendaItem[k]) : agendaItem[k];
                }
                break;
            }
            default:
                break;
        }
    });
    return {
        where: {
            id: agendaItem.id,
        },
        data: payload,
    };
}

const artifactUpdateKeys: Array<
    Exclude<
        keyof AgendaItemArtifactUpdatePayload,
        "session" | "id" | "createdAt" | "updatedAt" | "macroArtifactForSession" | "isMacro" | "artifactId" | "endedAt"
    >
> = [
    // "artifactId",
    // "createdAt",
    "deniedSpeakerUserIds",
    "description",
    "durationInSeconds",
    // "endedAt",
    // "id",
    "isConfigured",
    // "isMacro",
    "isSystemArtifact",
    // "macroArtifactForSession",
    "name",
    "order",
    "properties",
    // "resourceSlots",
    "resourceResults",
    // "session",
    "startAt",
    // "updatedAt",
    "userConfiguredDuration",
];

function buildAgendaItemArtifactUpdatePayload(artifact: AgendaItemArtifact): AgendaItemArtifactUpdatePayload {
    const payload: AgendaItemArtifactUpdatePayload = {};
    artifactUpdateKeys.forEach((k) => {
        switch (k) {
            case "deniedSpeakerUserIds": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k] as string[],
                    };
                }
                break;
            }
            case "description": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "durationInSeconds": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            // modifying endedAt is forbidden
            // case "endedAt": {
            //     if (k in artifact) {
            //         payload[k] = {
            //             set: artifact[k],
            //         };
            //     }
            //     break;
            // }
            case "isConfigured": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "isSystemArtifact": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "name": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "order": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "properties": {
                if (k in artifact) {
                    payload[k] = buildArtifactUpdatePropertiesPayload(artifact[k]);
                }
                break;
            }
            case "resourceResults": {
                if (k in artifact) {
                    payload[k] = buildArtifactResources(artifact[k]);
                }

                break;
            }
            case "startAt": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "userConfiguredDuration": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            default: {
                break;
            }
        }
    });
    return payload;
}

type ArtifactPropertiesUpdatePayload = NonNullable<AgendaItemArtifactUpdatePayload["properties"]>;

function buildArtifactUpdatePropertiesPayload(input: AgendaItemArtifact["properties"]): ArtifactPropertiesUpdatePayload {
    const deletedItems = input.filter((obj) => obj && obj.id && obj.isDeleted).map((obj) => obj.id);
    const newOrUpdatedItems = input.filter((obj) => obj && obj.id && deletedItems.indexOf(obj.id) === -1);
    const newItems = newOrUpdatedItems.filter((obj) => obj && obj.id && !obj.createdAt && "key" in obj && "value" in obj);
    const updateItems = newOrUpdatedItems.filter((obj) => obj && obj.id && !!obj.createdAt && "value" in obj);
    return {
        ...(deletedItems.length
            ? {
                  delete: deletedItems.map((id) => ({id})),
              }
            : null),
        ...(newItems.length
            ? {
                  create: newItems.map((p) => {
                      return {
                          id: p.id,
                          key: p.key,
                          value: p.value,
                      };
                  }),
              }
            : null),
        ...(updateItems
            ? {
                  update: updateItems.map((p) => {
                      return {
                          where: {
                              id: p.id,
                          },
                          data: {
                              value: {
                                  set: p.value,
                              },
                          },
                      };
                  }),
              }
            : null),
    };
}

type ArtifactResourceSlotUpdatePayload = NonNullable<AgendaItemArtifactUpdatePayload["resourceResults"]>;

function buildArtifactResources(input: AgendaItemArtifact["resourceResults"]): ArtifactResourceSlotUpdatePayload {
    const deletedItems = input.filter((obj) => obj && obj.id && obj.isDeleted).map((obj) => obj.id);
    const newOrUpdatedItems = input.filter((obj) => obj && obj.id && deletedItems.indexOf(obj.id) === -1);
    const updateItems = newOrUpdatedItems.filter((obj) => obj && obj.id && !!obj.createdAt);

    // @ts-ignore no type for console
    console.log("building - buildArtifactResources", {
        input: structuredClone(input),
        deletedItems: structuredClone(deletedItems),
        newOrUpdatedItems: structuredClone(newOrUpdatedItems),
    });

    return {
        ...(deletedItems.length
            ? {
                  delete: deletedItems.map((id) => ({id})),
              }
            : null),
        ...(newOrUpdatedItems.length > 0
            ? {
                  connect: newOrUpdatedItems.map((obj) => {
                      return {
                          id: obj.id,
                          //   desiredVersion: obj.desiredVersion,
                          //   tag: obj.tag,
                          //   id: obj.id,
                          //   resource: {
                          //       connect: {
                          //           id: obj.resource.id,
                          //       },
                          //   },
                      };
                  }),
              }
            : null),
        // TODO: @Maca/Andrei Codreanu
        // can resources be updated... if yes can they be updated here?
    };
}

type AgendaItemSpeakersPayload = NonNullable<AgendaItemUpdatePayload["data"]["agendaItemSpeakers"]>;

function buildSpeakersPayload(
    input: ArrayItem<AgendaItemsInput>["agendaItemSpeakers"],
    agendaItem: NonNullable<ArrayItem<AgendaItemsInput>>
): AgendaItemSpeakersPayload {
    const deletedItems = input.filter((obj) => obj && obj.id && obj.isDeleted).map((obj) => obj.id);
    const newItems = input.filter((obj) => obj && obj.id && !obj.createdAt && deletedItems.indexOf(obj.id) === -1);
    // const updateItems = newOrUpdatedItems.filter((obj) => obj && obj.id && !!obj.createdAt);

    return {
        ...(deletedItems.length
            ? {
                  delete: deletedItems.map((id) => ({id})),
              }
            : null),
        ...(newItems.length > 0
            ? {
                  create: newItems.map((obj) => {
                      return {
                          id: obj.id,
                          speaker: {
                              connect: {
                                  id: obj.speakerId,
                              },
                          },

                          //   desiredVersion: obj.desiredVersion,
                          //   tag: obj.tag,
                          //   id: obj.id,
                          //   resource: {
                          //       connect: {
                          //           id: obj.resource.id,
                          //       },
                          //   },
                      };
                  }),
              }
            : null),
        // TODO: @Maca/Andrei Codreanu
        // can resources be updated... if yes can they be updated here?
    };
}
