import {artifactApi} from "@generated/artifacts";
import {MobileState} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import classes from "@workhorse/style/CommonModules/microController.module.scss";
import designer from "@workhorse/api/designer";
import {useAgendaItems, useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {AgendaItemType} from "@generated/data";
import {Fragment} from "@workhorse/api/rendering";
import {useResourceResult} from "@workhorse/api/resx/hooks";
import Typography from "@ui/cdk/Typography";

export type AdminMicroControllerProps = {
    session: SessionData;
    inLobby: boolean;
    agendaItemTitle?: string;
    isAssistant?: boolean;
    mobileState: MobileState;
    hasAgenda: boolean;
    onlyAgendaItemWithThisOrder?: number;
};

function AdminMicroController(props: AdminMicroControllerProps) {
    const {id: sessionId, order: currentSessionOrder} = props.session;
    const current = useCurrentAgendaItem();

    const agendaItems = useAgendaItems();
    const isAgendaLessSession = agendaItems.every((a) => a.type === AgendaItemType.Instant);
    const artifactFromProps = isAgendaLessSession ? current.artifact : null;
    const allAgendaItemsAreDummies = agendaItems?.every((a) => a.title === designer.constants.DUMMY_AGENDA_ITEM_TITLE);

    const agendaItemsWithMicros = agendaItems?.filter((agendaItem) => {
        if (props.onlyAgendaItemWithThisOrder === undefined && agendaItem.order !== currentSessionOrder) {
            return false;
        }
        if (agendaItem.processing) {
            return false;
        }
        if (props.onlyAgendaItemWithThisOrder !== undefined) {
            return agendaItem.order === props.onlyAgendaItemWithThisOrder;
        }
        return artifactFromProps
            ? artifactFromProps.id === agendaItem.artifact?.id
            : !!agendaItem && agendaItem.artifact && (agendaItem.order !== currentSessionOrder && allAgendaItemsAreDummies ? false : true);
    });

    return (
        <div className={cls("flex flex00-100 flex-col flex-align-start flex-content-start", classes.microControllerRoot)}>
            {agendaItemsWithMicros.map((agendaItem, i) => {
                const {artifact, title: agendaItemTitle, order = ""} = agendaItem ?? {};
                const Controller =
                    artifact && artifact.artifactId ? ((artifactApi(artifact.artifactId) || {}) as any).MicroController : undefined;

                const {result} = useResourceResult(artifact?.resourceResults?.[0]?.id);
                const resourceContent = result?.resource?.content as any;

                return (
                    <Fragment key={artifact?.id}>
                        {!!Controller && (
                            <>
                                {!allAgendaItemsAreDummies && props.onlyAgendaItemWithThisOrder === undefined ? (
                                    <div className={cls("flex flex-row flex-align-center gap-5", classes.agendaItemTitleWrapper)}>
                                        <Typography component="div" variant="base" className={classes.agendaIndex}>
                                            #{(order as unknown as number) + 1}
                                        </Typography>
                                        <Typography component="div" variant="base" className={classes.agendaItemTitle}>
                                            {agendaItemTitle}
                                        </Typography>
                                    </div>
                                ) : null}
                                <Controller
                                    currentMicro={artifact}
                                    session={props.session}
                                    agendaItemTitle={resourceContent?.name}
                                    key={"controller-" + artifact?.id}
                                    inLobby={props.inLobby}
                                    hasAgenda={props.hasAgenda}
                                />
                            </>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
}

export default AdminMicroController;
