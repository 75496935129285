import AgendaTimeLeftProgress from "@artifacts/agenda/ui/agenda-footer/AgendaTimeLeftProgress";
import AgendaBoxTime from "@artifacts/agenda/ui/agendaBox/components/AgendaBoxTime";
import {AgendaItemType, ConfigurationStep, DrawerState, SessionLifecycle} from "@generated/data";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import designer, {DUMMY_AGENDA_ITEM_TITLE} from "@workhorse/api/designer";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {OnboardingOpenAgenda} from "@workhorse/components/onboarding-tooltips";
import {WithChildren, WithMobileState} from "@workhorse/declarations";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {useDesignerIsEditing, useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useAgendaItems, useCurrentAgendaItem, useMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import {useDrawerLeftToggler, useFullScreenState} from "@workhorse/providers/state";
import {add, addSeconds, sub, subSeconds} from "date-fns";
import {useMemo, useState, useRef} from "@workhorse/api/rendering";
import AgendaActionsMenu from "./components/AgendaActionsMenu";
import AgendaItemsActionsMenu from "./components/AgendaItemsActionsMenu";
import AgendaItemsControls from "./components/AgendaItemsControls";
import classes from "./style/AgendaToggler.module.scss";
import {useUserInfo} from "@workhorse/providers/User";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";
import IconButton from "@ui/core/components/IconButton";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import {ReactComponent as EditAgenda} from "../../assets/media/edit-agenda-icon.svg";
import Tooltip from "@ui/cdk/Tooltip";

type AgendaTogglerProps = {
    inLobby?: boolean;
    isAssistant: boolean;
    isAdmin: boolean;
    session: SessionData;
    isAddingAgenda?: boolean;
} & WithChildren &
    WithMobileState;

const AgendaToggler = (props: AgendaTogglerProps) => {
    const {t} = useTranslation();

    const {isAssistant, isAdmin, inLobby, session, children, mobileState, isAddingAgenda} = props;
    const {isMobile} = mobileState;
    const [drawerLeftState, toggleLeftDrawer, toggleDetach] = useDrawerLeftToggler();
    const {isMobileOrTablet} = useMobile();
    const isTablet = isMobileOrTablet && !isMobile;
    const {isPortrait} = useDeviceOrientation();
    const instanceOfRecurrence = session?.instanceOfRecurrence?.session.id;

    const currentAgendaItem = useCurrentAgendaItem();
    const agendaItems = useAgendaItems();
    const {isGuest} = useUserInfo();
    const {order: sessionOrder} = session;
    const isTemplatePreview = ((session ?? {}) as any).isTemplate ? true : false;
    const isActive = currentAgendaItem?.order === sessionOrder;

    const [agendaActionsAnchorEl, setAgendaActionsAnchorEl] = useState<null | HTMLElement>(null);
    const [agendaItemsAnchorEl, setAgendaItemsAnchorEl] = useState<null | HTMLElement>(null);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [updateRecurrenceConfirmation, setUpdateRecurrenceConfirmation] = useState(false);

    const [showControls, setShowControls] = useState(false);

    const macroArtifacts = useMacroArtifacts();
    const [editState, setEditState] = useDesignerSessionState();
    const [isEditMode, toggleEditMode] = useDesignerIsEditing();

    const isFullScreen = useFullScreenState();

    const timerButtonRef = useRef<HTMLButtonElement>(null);

    const {
        startAt: agendaItemStart,
        endedAt: agendaItemEnded,
        duration: agendaItemDuration,
        timeSpentInSeconds,
        isPaused,
    } = currentAgendaItem;

    const sessionStarted = session?.lifecycle === SessionLifecycle.Started;

    const timeSpent = useMemo(() => {
        return timeSpentInSeconds ?? 0;
    }, [timeSpentInSeconds]);

    const agendaActualStartDate = useMemo(() => {
        return isPaused ? new Date(sub(new Date(), {seconds: timeSpent})) : new Date(subSeconds(new Date(agendaItemStart!), timeSpent));
    }, [agendaItemStart, isPaused]);

    const agendaItemPlannedEnd = agendaItemDuration
        ? isPaused
            ? new Date(add(sub(new Date(), {seconds: timeSpent}), {seconds: agendaItemDuration}))
            : new Date(addSeconds(agendaActualStartDate, agendaItemDuration))
        : new Date();

    const keybind = getGlobalKeybind("toggleExpandAgenda");

    const onToggleLeftDrawer = () => {
        if (agendaItemsAnchorEl) {
            setAgendaItemsAnchorEl(null);
        }

        if (agendaActionsAnchorEl) {
            setAgendaActionsAnchorEl(null);
        }

        if (drawerLeftState?.isClosed) {
            toggleLeftDrawer(DrawerState.FullyOpen, "AgendaToggler:61");
        } else if (drawerLeftState?.isFullyOpen) {
            toggleLeftDrawer(DrawerState.Closed, "AgendaToggler:63");
        }
    };

    if (currentAgendaItem?.title === DUMMY_AGENDA_ITEM_TITLE && !isAddingAgenda) {
        return null;
    }

    const handleClose = (event: any, reason: string) => {
        if (reason === "clickAway" || reason === "escapeKeyDown" || reason === "backdropClick" || reason === "mouseLeave") {
            setAgendaActionsAnchorEl(null);
            setAgendaItemsAnchorEl(null);
            setShowControls(false);
        }
    };

    const onMouseEnterAgendaItems = (event: React.MouseEvent<HTMLElement>) => {
        if (timerButtonRef.current) {
            setAgendaItemsAnchorEl(timerButtonRef.current);
        } else {
            setAgendaItemsAnchorEl(event.currentTarget);
        }

        if (isAdmin || isAssistant) {
            setShowControls(true);
        }
        if (drawerLeftState.isFullyOpen) {
            return;
        }
    };

    const onMouseLeaveActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (isMobile) {
            return;
        }
        handleClose(event, "mouseLeave");
    };

    const onMouseLeaveAgendaItems = (event: React.MouseEvent<HTMLElement>) => {
        if (isMobile) {
            return;
        }

        handleClose(event, "mouseLeave");
        setAgendaItemsAnchorEl(null);
    };

    const onMouseLeaveTimerButton = (event: React.MouseEvent<HTMLElement>) => {
        if (isMobile) {
            return;
        }

        const timerButton = event.currentTarget;

        if (event.clientY < timerButton.getBoundingClientRect().y) {
            return;
        }

        handleClose(event, "mouseLeave");
        setAgendaItemsAnchorEl(null);
    };

    const toggleDeleteDialog = (e?: any) => {
        // sorry for the any
        e?.stopPropagation();
        setDeleteConfirmOpen((prev) => !prev);
    };

    const deleteAgenda = async (e) => {
        e.stopPropagation();

        if (instanceOfRecurrence && !updateRecurrenceConfirmation) {
            setUpdateRecurrenceConfirmation(true);
            return;
        } else {
            setUpdateRecurrenceConfirmation(false);
        }

        designer.undoChanges({
            from: ["agendaItems", "macroArtifacts"],
        });

        designer.api.session.detachAgenda({
            type: AgendaItemType.Instant,
        });

        const agendaId = macroArtifacts.find((a) => a.artifactId === "flowos/agenda")?.id;

        if (agendaId) {
            designer.api.artifact.removeMacroArtifact({
                id: agendaId,
            });
        }

        toggleLeftDrawer(DrawerState.Closed);

        await designer.commit();

        if (isEditMode) {
            setEditState({
                selectedAgendaItemId: null,
            });
            toggleEditMode(false);
        }
        setDeleteConfirmOpen(false);

        handleClose(e, "clickAway");
    };

    const handleEditAgenda = (e) => {
        e.stopPropagation();
        if (drawerLeftState?.isClosed) {
            toggleLeftDrawer(DrawerState.FullyOpen);
        }

        if (drawerLeftState.isDetached) {
            toggleDetach();
        }

        if (designer.state.getSnapshot().configurationStep !== ConfigurationStep.Edit) {
            designer.state.setDesignerEditState(true);
            designer.state.update({
                configurationStep: ConfigurationStep.Edit,
            });
        } else {
            designer.state.setDesignerEditState(false);
            designer.state.update({
                configurationStep: ConfigurationStep.Preview,
            });
            designer.undoChanges({from: ["agendaItems"]});
        }
    };

    return (
        <>
            <OnboardingOpenAgenda>
                <div
                    className={cls(
                        classes.toolsBtnWrapper,
                        sessionStarted && classes.toolBtnWrapperPlayer,
                        inLobby && !isAdmin && (!isAssistant || (isAssistant && isGuest)) && classes.toolBtnWrapperLobbyParticipant,
                        !inLobby && !isAdmin && (!isAssistant || (isAssistant && isGuest)) && classes.toolBtnWrapperPlayerParticipant,
                        session.onboardingType === SessionOnboardingType.FirstStrike && classes.toolBtnWrapperPlayerParticipant,
                        "agenda-toggler-wrapper",
                        inLobby && "is-lobby",
                        isTablet && classes.toolBtnWrapperTablet,
                        !isPortrait && isTablet && classes.tabletLandscape,
                        isFullScreen && "full-screen"
                    )}
                    onMouseLeave={onMouseLeaveTimerButton}
                >
                    <div
                        className={classes.buttonProgressWrapper}
                        aria-owns={agendaActionsAnchorEl ? "agenda-actions-menu" : undefined}
                        aria-haspopup="true"
                        onClick={onToggleLeftDrawer}
                    >
                        {isAddingAgenda ? null : (
                            <AgendaActionsMenu
                                onMouseLeave={onMouseLeaveActionMenu}
                                agendaActionsAnchorEl={
                                    props.session.onboardingType === SessionOnboardingType.FirstStrike ? null : agendaActionsAnchorEl
                                }
                                handleClose={handleClose}
                                drawerLeftState={drawerLeftState}
                                toggleLeftDrawer={toggleLeftDrawer}
                                toggleDetach={toggleDetach}
                                inLobby={!!inLobby}
                                isAssistant={isAssistant}
                                mobileState={mobileState}
                                session={session}
                                agendaItems={agendaItems}
                                onDelete={deleteAgenda}
                                isAdmin={isAdmin}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: 36,
                                    //button width + margin, so it aligns with drawer
                                }}
                            />
                        )}

                        {agendaItemDuration && !inLobby ? (
                            <>
                                <AgendaTimeLeftProgress
                                    actualStart={agendaActualStartDate}
                                    plannedStart={agendaActualStartDate}
                                    plannedEnd={agendaItemPlannedEnd}
                                    className={classes.linearProgress}
                                    mobileState={mobileState}
                                    withBullet={false}
                                    isOnAgendaToggler={true}
                                    color="transparent"
                                    isAgendaPaused={currentAgendaItem.isPaused ?? false}
                                />
                            </>
                        ) : null}
                        <Button
                            noFocusBorder
                            variant="plain"
                            data-id="agenda-toggler"
                            className={cls(
                                classes.toolsButton,
                                inLobby && (isAdmin || isAssistant) && classes.fullToolsButtonLobbyAdmin,
                                inLobby &&
                                    !isAdmin &&
                                    (!isAssistant || (isAssistant && isGuest)) &&
                                    classes.fullToolsButtonLobbyParticipant,
                                !inLobby &&
                                    !isAdmin &&
                                    (!isAssistant || (isAssistant && isGuest)) &&
                                    classes.fullToolsButtonPlayerParticipant,
                                session.onboardingType === SessionOnboardingType.FirstStrike && classes.fullToolsButtonPlayerParticipant,
                                (!agendaItemDuration || inLobby) && classes.noLinearProgress
                            )}
                        >
                            <span className="pl-4 mr-4">
                                {drawerLeftState?.isClosed ? (
                                    <KeyboardArrowRightRoundedIcon />
                                ) : drawerLeftState?.isFullyOpen ? (
                                    <KeyboardArrowLeftRoundedIcon />
                                ) : null}
                            </span>
                            <span className={cls(classes.title)}>
                                <div className="fullw flex flex-row flex-align-center">
                                    <div className="mr-4 fullw flex flex-align-center flex-justify-between">
                                        <Typography color="nonary" fontWeight="bold" noWrap>
                                            {drawerLeftState?.isClosed
                                                ? t("agenda.menu.expand_agenda")
                                                : drawerLeftState?.isFullyOpen
                                                ? t("agenda.menu.collapse_agenda")
                                                : null}
                                        </Typography>
                                        {inLobby && !isMobileOrTablet && (
                                            <div className="flex flex-align-center pt-2">
                                                {keybind.map((key) => (
                                                    <span className={classes.keyBind} key={key}>
                                                        {key}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </span>
                        </Button>
                    </div>
                    {inLobby ? (
                        drawerLeftState?.isClosed ? null : (isAdmin || isAssistant) && !isAddingAgenda ? (
                            // LOBBY
                            <div className="flex flex-row">
                                {editState?.configurationStep === ConfigurationStep.Preview && (
                                    <Tooltip title={t("agenda.menu.edit_agenda") ?? "Edit agenda"} arrow placement="top">
                                        <div className={classes.settingsButtonContainer}>
                                            <IconButton className={classes.editButton} onClick={handleEditAgenda}>
                                                <EditAgenda />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                )}
                                <div className={classes.settingsButtonContainer}>
                                    <Tooltip title={t("agenda.menu.remove_agenda") ?? "Remove agenda"} arrow placement="top">
                                        <IconButton className={classes.closeButton} onClick={toggleDeleteDialog}>
                                            <RemoveCircleOutlineRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : null
                    ) : (
                        <div aria-owns={agendaItemsAnchorEl ? "agenda-items-actions-menu" : undefined} aria-haspopup="true">
                            {drawerLeftState?.isClosed ? (
                                <Button
                                    noFocusBorder
                                    onMouseEnter={onMouseEnterAgendaItems}
                                    variant="plain"
                                    className={classes.timerButton}
                                    ref={timerButtonRef}
                                >
                                    {showControls ? (
                                        <AgendaItemsControls
                                            agendaItems={agendaItems}
                                            session={session}
                                            sessionOrder={sessionOrder}
                                            currentAgendaItem={currentAgendaItem}
                                        />
                                    ) : !session?.backstage ? (
                                        <AgendaBoxTime
                                            duration={agendaItemDuration!}
                                            timeSpentInSeconds={timeSpent!}
                                            startAt={agendaItemStart!}
                                            isActive={isActive}
                                            isPaused={!!currentAgendaItem.isPaused}
                                        />
                                    ) : null}
                                </Button>
                            ) : (isAdmin || isAssistant) && !isAddingAgenda ? (
                                // PLAYER
                                <div className="flex flex-row">
                                    {editState?.configurationStep === ConfigurationStep.Preview && (
                                        <div className={classes.settingsButtonContainer}>
                                            <Tooltip title={t("agenda.menu.edit_agenda") ?? "Edit agenda"} arrow placement="top">
                                                <IconButton className={classes.editButton} onClick={handleEditAgenda}>
                                                    <EditAgenda />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <div className={classes.settingsButtonContainer}>
                                        <Tooltip title={t("agenda.menu.remove_agenda") ?? "Remove agenda"} arrow placement="top">
                                            <IconButton className={classes.closeButton} onClick={toggleDeleteDialog}>
                                                <RemoveCircleOutlineRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            ) : null}

                            {inLobby ? null : (
                                <>
                                    <AgendaItemsActionsMenu
                                        onMouseLeave={onMouseLeaveAgendaItems}
                                        agendaItemsAnchorEl={agendaItemsAnchorEl}
                                        handleClose={handleClose}
                                        inLobby={inLobby!}
                                        sessionId={session?.id}
                                        isAssistant={isAssistant}
                                        isAdmin={isAdmin}
                                        session={session}
                                        isMobile={isMobile}
                                        toggleLeftDrawer={toggleLeftDrawer}
                                        drawerLeftState={drawerLeftState}
                                        isTemplatePreview={isTemplatePreview}
                                        currentAgendaItem={currentAgendaItem}
                                        sessionOrder={sessionOrder}
                                    />
                                </>
                            )}
                        </div>
                    )}

                    <div className={classes.microControllerWrapper}>{children}</div>
                </div>
            </OnboardingOpenAgenda>

            <ConfirmationDialog
                open={deleteConfirmOpen}
                disableCloseButton
                onClose={toggleDeleteDialog}
                onConfirm={deleteAgenda}
                onCancel={toggleDeleteDialog}
                title={"Remove agenda?"}
                content={"Are you sure you want to remove the agenda?"}
                cancelButton={<Typography color="secondary">Cancel</Typography>}
                submitButton={"Remove"}
                IconElement={<PriorityHighRoundedIcon className={classes.removeAgendaDialogIcon} />}
                disableBackdropClick
                cancelButtonVariant="plain"
                submitButtonVariant="destructive"
            />
            <ConfirmationDialog
                key="agenda-on-recurrence-confirmation"
                title="Confirm action"
                content="The current session is part of a recurring session. Any action for agenda will be applied for all sessions, that are not started. Are you sure you want to perform this action? "
                cancelButton={"Cancel"}
                minWidth
                // Icon={EditIcon}
                submitButton="Confirm"
                onClose={() => {
                    setUpdateRecurrenceConfirmation(false);
                }}
                open={updateRecurrenceConfirmation}
                submitButtonVariant="primary"
                onConfirm={deleteAgenda}
            ></ConfirmationDialog>
        </>
    );
};

export default AgendaToggler;
