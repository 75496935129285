import {OneArtifactFragment} from "@generated/data";

type Properties = OneArtifactFragment["properties"];

export type TypecastData = {
    [key: string]: string | null | undefined;
};

const typeCast = (props: Properties): TypecastData => {
    const reduced = props.reduce((out, prop) => ({...out, [prop.key]: prop.value}), {});
    return reduced;
};

export default typeCast;
