import {
    GetFeedbackSessionDocument,
    GetRegistrationAnswersDocument,
    LocalMemoryParticipantsDocument,
    SessionEventState,
    SessionLifecycle,
    SessionParticipantFragment,
} from "@generated/data";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@ui/cdk/Button";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {Participant} from "@workhorse/declarations/dataTypes";
import ParticipantsDialog from "@workhorse/pages/designer/CreateSession/ParticipantsDialog";
import {
    ParticipantPresenceHistoryJsonType,
    getParticipantPressenceInfo,
} from "@workhorse/pages/player/session-view-components/session-participants/utils";
import {useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import {RegistrationForm} from "../event-registration/event-registration";
import {RegistrationAnswers} from "../utils";
import EventPeopleExportCsvDialog from "./EventPeopleExportCsvDialog";
import EventPeopleTable from "./EventPeopleTable";
import ViewAnswersDialog from "./ViewAnswersDialog";
import classes from "./styles/EventPeople.module.scss";
import {getParticipantEventRole} from "./utils";
import DownloadNotification from "@workhorse/pages/player/components/DownloadNotification";
import {useLazyQuery, useQuery} from "@apollo/client";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {FormItem} from "@sessions/forms";

export type EventParticipantRole = "Guest" | "Speaker" | "Host" | "Co-host" | "Invited participant";

export type EventParticipant = SessionParticipantFragment & {
    eventRole: EventParticipantRole;
    attended?: boolean;
    presenceHistory?: {
        joinedAt: string;
        leftAt: string;
        totalSpent: string;
    } | null;
    currentParticipantId?: string;
};

type memoryParticipantsType = Participant & {presenceHistory: ParticipantPresenceHistoryJsonType};

type EventPeopleProps = {
    csvOnly?: boolean;
    includeAttendedStatus?: boolean;
    isMemoryMode: boolean;
    onDone?: () => void;
    registrationAnswers?: RegistrationAnswers;
    eventId?: string;
    memoryParticipants?: memoryParticipantsType[];
    feedbackForm?: RegistrationForm;
};

export function EventPeople(props: EventPeopleProps) {
    const {includeAttendedStatus, isMemoryMode} = props;
    const participants = useParticipants();
    const [selectedPerson, setSelectedPerson] = useState("");
    const [selectedAnswers, setSelectedAnswers] = useState<any>({});

    const [openGuestsDialog, setOpenGuestsDialog] = useState(false);

    const exportCsvImperativeRef = useRef<DialogImperativeRef>();

    const session = useSession();

    const registrationForm = props?.feedbackForm ?? (session?.event?.registrationForm as RegistrationForm | null);
    const registrationAnswers = props.registrationAnswers ?? (session?.event?.registrationAnswers as RegistrationAnswers);
    const requireApproval = session?.event?.requireApproval;
    const hasRegistration = props.feedbackForm ? true : session?.event?.hasRegistration;
    const hasPayments = !!session?.event?.payments[0];

    const emailIndex = registrationForm?.formItems?.findIndex((item) => item.type === "Email") ?? -1;
    const nameIndex = registrationForm?.formItems?.findIndex((item) => item.type === "Name") ?? -1;
    const emailId = registrationForm?.formItems?.[emailIndex]?.id;
    const nameId = registrationForm?.formItems?.[nameIndex]?.id;
    const participantsToUse = isMemoryMode ? props.memoryParticipants : participants;
    const participantsWithRoles = participantsToUse
        ?.filter(
            (p) =>
                p.dataWithNullableEmail.firstName ||
                p.dataWithNullableEmail.lastName ||
                (p.dataWithNullableEmail.email && p.dataWithNullableEmail.email !== " ")
        )
        .map((participant) => ({
            dataWithNullableEmail: {
                firstName: participant?.dataWithNullableEmail?.firstName,
                lastName: participant?.dataWithNullableEmail?.lastName,
                email: participant?.dataWithNullableEmail?.email,
            },
            createdAt: participant?.createdAt,
            id: participant?.id,
            eventRole: getParticipantEventRole(participant),
            registered: true,
            registrationId: participant?.dataWithNullableEmail.token,
            country: registrationAnswers?.answers?.find(
                (a) =>
                    a.registrationId === participant?.dataWithNullableEmail.token ||
                    a.answers.find((a) => a?.formItemId === emailId && a?.value === participant?.dataWithNullableEmail?.email)
            )?.country,
            ...(includeAttendedStatus ? {attended: participant?.hasAttendedSession} : {}),
            presenceHistory: getParticipantPressenceInfo(
                participant as Participant & {presenceHistory: ParticipantPresenceHistoryJsonType},
                session?.actualStart
            ),
            paymentTransaction: participant?.paymentTransaction,
        }));

    let participantsWithAnswers: any[];

    if (Array.isArray(registrationAnswers?.answers)) {
        participantsWithAnswers = registrationAnswers?.answers
            .filter(
                (a) =>
                    participants.findIndex(
                        (p) => p.dataWithNullableEmail.email === a.answers.find((a) => a.formItemId === emailId)?.value
                    ) === -1
            )
            .map((answer) => {
                return {
                    dataWithNullableEmail: {
                        // TODO: maca registration remove hardcoded indexes
                        firstName: answer.answers?.find((a) => a.formItemId === nameId)?.value ?? "",
                        lastName: "",
                        email: answer.answers.find((a) => a.formItemId === emailId)?.value,
                        token: answer.registrationId,
                    },
                    createdAt: answer.registrationDate,
                    id: answer.registrationId,
                    registrationId: answer.registrationId,
                    eventRole: "Guest",
                    registered: false,
                    country: answer.country,
                    currentParticipantId: answer?.currentParticipantId,
                    ...(includeAttendedStatus
                        ? {
                              attended: participants.find(
                                  (p) =>
                                      p.id === answer?.currentParticipantId ??
                                      p.dataWithNullableEmail.email === answer.answers.find((a) => a.formItemId === emailId)?.value
                              )?.hasAttendedSession,
                          }
                        : {}),
                };
            })
            .filter((p) => p.dataWithNullableEmail.email !== undefined);
    } else {
        participantsWithAnswers = [];
    }

    const mergeParticipantWithRoleAndAnswer = (participantsWithRoles: any[], participantsWithAnswers: any[]) => {
        const participantsWithRoleAndAnswer = participantsWithRoles.map((participant) => {
            const participantWithAnswer = participantsWithAnswers.find(
                (p) =>
                    p.currentParticipantId === participant.id || p.dataWithNullableEmail.email === participant.dataWithNullableEmail.email
            );
            return {
                ...participant,
                ...participantWithAnswer,
                dataWithNullableEmail: {
                    ...participant.dataWithNullableEmail,
                    ...participantWithAnswer?.dataWithNullableEmail,
                    firstName: participant?.dataWithNullableEmail?.firstName ?? participantWithAnswer.dataWithNullableEmail.firstName,
                },
            };
        });

        return participantsWithRoleAndAnswer ?? [];
    };

    const peopleMerged = mergeParticipantWithRoleAndAnswer(participantsWithRoles as [], participantsWithAnswers);

    const people = peopleMerged;
    const handleToggleExportCsvDialog = () => {
        exportCsvImperativeRef.current?.toggle?.();
    };

    useEffect(() => {
        if (props.csvOnly) {
            handleToggleExportCsvDialog();
        }
    }, []);

    function closeViewAnswers() {
        setSelectedPerson("");
        setSelectedAnswers({});
    }

    function selectPerson(personId: string) {
        const person = people.find((person) => person.id === personId);
        const answer = {
            registrationDate: person?.createdAt,
            registrationId: person?.id,
            answers: registrationAnswers?.answers?.find(
                (a) =>
                    a.registrationId === person.registrationId ||
                    a.answers.some((a) => a.formItemId === emailId && a.value === person.dataWithNullableEmail.email)
            )?.answers,
        };
        setSelectedAnswers(answer);
        setSelectedPerson(personId);
    }

    function viewAnswers(personId: string) {
        setTimeout(() => {
            selectPerson(personId);
        }, 200);
    }

    const onToggleGuestsDialog = () => {
        if (session?.event?.state !== SessionEventState.Published) {
            return;
        }
        setOpenGuestsDialog((prev) => !prev);
    };
    const invitedParticipants = participants.filter((p) => !p?.isOwner && p?.speakerDetails === null);
    const seatsLimitReached = session?.event?.maxSeats ? Number(session?.event?.seatsTaken) >= Number(session?.event?.maxSeats) : false;

    return (
        <>
            {props.csvOnly ? null : (
                <div className={cls(classes.root)}>
                    <div className={cls("fullh flex flex-col", classes.mainContainer)}>
                        {openGuestsDialog ? (
                            <ParticipantsDialog
                                key="participants-dialog-guest-event"
                                open={openGuestsDialog}
                                onClose={onToggleGuestsDialog}
                                sessionId={session.id}
                                isAssistant={false}
                                sendEmailsAutomatically={true}
                                requestPermissionToJoin={session.requestPermissionToJoin}
                                inLobby={true}
                                sessionLifecycle={SessionLifecycle.Started}
                                participants={invitedParticipants}
                                placeholder="Add email addresses or search contacts"
                                isCreateDialog={true}
                                isEventGuest={true}
                                minimized={true}
                            />
                        ) : null}
                        <div className="flex flex-align-center flex-justify-between mb-30">
                            <div className="flex flex-align-center gap-8">
                                <Typography fontWeight="bolder" variant="xl3" color="secondary">
                                    Participants {people.length > 0 ? `(${people.length})` : ""}
                                </Typography>
                                {seatsLimitReached ? (
                                    <div className={classes.seatsLimitWarningIcon}>
                                        <Typography variant="sm">Event registration limit reached</Typography>
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex flex-align-center flex-justify-center">
                                <Tooltip
                                    placement="left"
                                    arrow
                                    title={
                                        session?.event?.state !== SessionEventState.Published
                                            ? "To invite guests, the event has to be published."
                                            : ""
                                    }
                                >
                                    <Button
                                        size="small"
                                        variant="secondary"
                                        onClick={onToggleGuestsDialog}
                                        className={cls(session?.event?.state !== SessionEventState.Published ? classes.btnDisabled : "")}
                                        data-id="invite-participants-button"
                                    >
                                        Invite participants
                                    </Button>
                                </Tooltip>
                                <Button
                                    size="small"
                                    variant="secondary"
                                    onClick={handleToggleExportCsvDialog}
                                    withMarginLeft
                                    data-id="export-csv-button"
                                >
                                    Export as CSV
                                </Button>
                            </div>
                        </div>
                        <EventPeopleTable
                            requireApproval={requireApproval}
                            people={people}
                            viewAnswers={viewAnswers}
                            sessionId={session?.id}
                            hasRegistration={hasRegistration}
                            hasPayments={hasPayments}
                        />
                    </div>

                    {selectedPerson && (
                        <ViewAnswersDialog
                            onClose={closeViewAnswers}
                            answers={selectedAnswers}
                            form={{
                                items: registrationForm?.formItems ?? [],
                                name: registrationForm?.name ?? "",
                                description: registrationForm?.description ?? "",
                            }}
                        />
                    )}
                </div>
            )}

            <EventPeopleExportCsvDialog
                imperativeRef={exportCsvImperativeRef}
                people={people}
                includeAttendedStatus={props.includeAttendedStatus}
                onClose={handleToggleExportCsvDialog}
                eventName={session?.name}
                hasRegistration={hasRegistration}
                onDone={props.onDone}
                formItems={props.feedbackForm ? props.feedbackForm?.formItems : session?.event?.registrationForm?.formItems}
                formAnswers={registrationAnswers?.answers}
                isMemoryMode={isMemoryMode}
                hasPayments={Boolean(session.event?.payments?.length)}
            />

            {!isMemoryMode && <DownloadNotification className={classes.downloadNotification} />}
        </>
    );
}

export const EventPeopleWrapper = (props: EventPeopleProps) => {
    const [mounted, setMounted] = useState(false);

    const handleToggleExportCsvDialog = () => {
        setMounted(true);
    };

    const onDone = () => {
        setMounted(false);
    };
    const sessionId = useSessionIdFromRoute();
    const {data} = useQuery(LocalMemoryParticipantsDocument, {
        variables: {
            id: sessionId,
        },
        skip: !sessionId || !props.isMemoryMode,
    });

    const {data: feedbackSessionQuery} = useQuery(GetFeedbackSessionDocument, {
        variables: {
            id: sessionId,
        },
        skip: !sessionId || !props.isMemoryMode,
        fetchPolicy: "no-cache",
    });

    const formattedFeedback = useMemo(() => {
        if (!feedbackSessionQuery?.session?.feedbackForm) return {} as RegistrationForm;
        return {
            name: feedbackSessionQuery?.session?.feedbackForm?.title ?? "",
            description: feedbackSessionQuery?.session?.feedbackForm?.description ?? "",
            formItems: (feedbackSessionQuery?.session?.feedbackForm?.questions ?? []) as FormItem[],
        } as RegistrationForm;
    }, [feedbackSessionQuery]);

    const formattedAnswers = useMemo(() => {
        if (!feedbackSessionQuery?.session?.feedbackFormAnswers) return {} as RegistrationAnswers;
        return {
            answers: feedbackSessionQuery?.session?.feedbackFormAnswers ?? [],
        } as RegistrationAnswers;
    }, [feedbackSessionQuery]);

    return props.csvOnly ? (
        <>
            <div className={cls("flex px-16 py-12", classes.downloadButton)}>
                <Button
                    fullWidth={props.csvOnly && props.isMemoryMode}
                    variant="tertiary"
                    size={props.csvOnly && !props.isMemoryMode ? "small" : "medium"}
                    onClick={handleToggleExportCsvDialog}
                >
                    <GetAppIcon /> {props.csvOnly && !props.isMemoryMode ? "" : "Download participants list"}
                </Button>
            </div>

            {mounted && (
                <EventPeople
                    {...props}
                    onDone={onDone}
                    feedbackForm={formattedFeedback}
                    registrationAnswers={formattedAnswers ? formattedAnswers : props.registrationAnswers}
                    memoryParticipants={data?.participants as unknown as memoryParticipantsType[]}
                />
            )}
        </>
    ) : (
        <EventPeople {...props} />
    );
};

export const EventPeopleWrapperWithQuery = (props: EventPeopleProps) => {
    const [mounted, setMounted] = useState(false);

    const [answers, setAnswers] = useState<RegistrationAnswers | null>(null);

    const [loading, setLoading] = useState(false);
    const [getAnswers] = useLazyQuery(GetRegistrationAnswersDocument, {
        onCompleted: (data) => {
            setAnswers(data?.getRegistrationAnswers?.registrationAnswers);
            setLoading(false);
        },
        onError: (error) => {
            setLoading(false);
            console.error(error);
        },
        variables: {
            eventId: props.eventId,
        },
        fetchPolicy: "network-only",
    });
    const sessionId = useSessionIdFromRoute();
    const {data} = useQuery(LocalMemoryParticipantsDocument, {
        variables: {
            id: sessionId,
        },
        skip: !sessionId || !props.isMemoryMode,
    });

    const handleToggleExportCsvDialog = async () => {
        if (!props.eventId) {
            return;
        }

        setLoading(true);
        getAnswers({
            variables: {
                eventId: props.eventId,
            },
        });

        setMounted(true);
    };

    const onDone = () => {
        setMounted(false);
    };

    return props.csvOnly ? (
        <>
            <Button loading={loading} className="mr-16" variant="tertiary" size={"small"} onClick={handleToggleExportCsvDialog}>
                <GetAppIcon />
            </Button>

            {mounted && answers && (
                <EventPeople
                    {...props}
                    registrationAnswers={answers}
                    onDone={onDone}
                    memoryParticipants={data?.participants as unknown as memoryParticipantsType[]}
                />
            )}
        </>
    ) : (
        <EventPeople {...props} />
    );
};
