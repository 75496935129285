import {useEffect} from "@workhorse/api/rendering";
import {useParticipantSetVisibility, VisibilityLocations} from "../../providers/ConferenceRoomProvider";

interface Props {
    location: keyof VisibilityLocations;
    participantId?: string;
}

export function ParticipantVisible(props: Props) {
    const {location, participantId} = props;

    const setVisibility = useParticipantSetVisibility();

    useEffect(() => {
        if (!participantId) {
            return;
        }

        setVisibility(participantId, location, true);

        return () => {
            setVisibility(participantId, location, false);
        };
    }, [setVisibility, location, participantId]);

    return null as unknown as JSX.Element;
}
