import Typography from "@ui/cdk/Typography";
import {useUploadResource, ResourceUploadItem} from "@workhorse/providers/UploadResourceProvider";
import {useEffect} from "react";
import {ActionCategory} from "./actionCategTree";
import {PaletteActiveCateg} from "./CommandPaletteProviders";

interface Props {
    category: PaletteActiveCateg;
}

function getErrorMessage(lastResource?: ResourceUploadItem, error?: unknown) {
    if (error instanceof Error) {
        if (error.message === "no_supported_files") {
            return "This is not a supported file type";
        }
        if (error.message === "no_multiple_files") {
            return "You can’t upload multiple files";
        }
        return error.message;
    }

    if (error) {
        return "There has been an error with your upload!";
    }

    if (lastResource == null || lastResource?.state !== "error") {
        return null;
    }

    const resourceError = lastResource.error;

    if (resourceError instanceof Error) {
        return resourceError.message;
    }

    if (lastResource.resourceType != null) {
        return "There has been an error with your upload!";
    }
}

export function PaletteUploadError(props: Props) {
    const {resources, error, setError, deleteResources} = useUploadResource();
    const isUploadAction = props.category.name === ActionCategory.File;

    useEffect(() => {
        if (isUploadAction) {
            return;
        }

        if (error) {
            setError(undefined);
        }

        const errored = resources.filter((resource) => resource.state === "error");
        if (errored.length > 0) {
            errored.forEach((errored) => deleteResources(errored.namespaceId));
        }
    }, [isUploadAction, resources, error, setError]);

    const lastResource = resources[resources.length - 1] ?? null;
    const errorMessage = getErrorMessage(lastResource, error);

    if (errorMessage == null) {
        return null;
    }

    return (
        <Typography className="pl-12 pt-8" style={{color: "white"}}>
            {errorMessage}
        </Typography>
    );
}
