import Typography from "@ui/cdk/Typography";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import {useOnboardingSession, useWantedPaletteAction, SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {PaletteActiveCateg} from "../command-palette/CommandPaletteProviders";
import {PaletteVisibleItems} from "../command-palette/CommandPaletteResultsProvider";
import {useDeferredValue} from "./utils";

interface Props extends WithTooltipChildren {
    activeCategory: PaletteActiveCateg;
    visibleItems: PaletteVisibleItems;
}

export function OnboardingPaletteGoBack(props: Props) {
    const {onboardingType, tooltip} = useOnboardingSession();
    const action = useWantedPaletteAction(tooltip);

    let shouldGoBack = false;

    if (action?.bySearch === true) {
        shouldGoBack = props.activeCategory.id != null;
    }

    if (action?.byNavigation === true) {
        const isRoot = props.activeCategory.id == null;
        const isInNavigation = props.activeCategory.id != null && action.navigation.includes(props.activeCategory.id);
        const isInAction = action.actionId === props.activeCategory.id;
        shouldGoBack = !isRoot && !isInNavigation && !isInAction;
    }

    if (onboardingType === SessionOnboardingType.NewUser) {
        shouldGoBack = false;
    }

    const title = (
        <Typography variant="sm" fontWeight="normal" color="background">
            Press <i>Backspace</i> to go back.
        </Typography>
    );

    const deferSkip = useDeferredValue(tooltip, !shouldGoBack, true, 1000);

    return (
        <OnboardingTooltip tooltipId={tooltip ?? "-1"} title={title} placement="top-start" arrow={true} skip={deferSkip}>
            {props.children}
        </OnboardingTooltip>
    );
}
