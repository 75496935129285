import artifactDescriptor from "@generated/artifacts";
import {DesignerState, HostType} from "@generated/data";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import DialogContent from "@ui/core/components/DialogContent";
import {Fragment, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import {ForgedAgendaItem, MicroArtifactProps, WithMobileState} from "@workhorse/declarations";
import {evaluateExpression} from "@workhorse/internal/expression";
import {useAgendaItems, useSession} from "@workhorse/providers/SessionDataProviders";
import {isPreviewOpen} from "../player/playerStateVars";
import AgendaBoxPreview from "./preview/AgendaBoxPreview";
import classes from "./styles/DesignerSessionPreview.module.scss";

type DesignerSessionPreviewProps = {
    open: boolean;
    currentTemplateId?: string;
    setEditState: (incoming: Partial<DesignerState>) => void;
    onClose: () => void;
} & WithMobileState;

function DesignerSessionPreview(props: DesignerSessionPreviewProps) {
    const imperativeRef = useRef<DialogImperativeRef>({});
    const itemsBodyRef = useRef<HTMLDivElement | null>(null);

    const scrollContainerRef = useRef<ScrollContainerRef>({});
    const agendaItemsFromHook = useAgendaItems();
    const agendaItems = (agendaItemsFromHook ?? [])?.slice()?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    const session = useSession();

    const wasOpen = useRef(props.open);

    const [activeAgendaItemId, setActiveAgendaItemId] = useState<string | null>(agendaItems?.[0]?.id ?? null);

    const handlePreview = (id: string) => {
        setActiveAgendaItemId(id);
    };

    const currentAgendaItem = agendaItems.find((item) => item.id === activeAgendaItemId) ?? agendaItems?.[0];
    const currentMicro = currentAgendaItem?.artifact;

    const currentMicroComponents = useMemo(() => {
        return currentMicro?.artifactId
            ? artifactDescriptor[currentMicro?.artifactId]?.entries
                  .filter((entry) => {
                      return evaluateExpression({
                          exp: entry.preloadCondition,
                          type: "micro",
                          artifactEvaluation: entry.filter ? entry.filter : () => true,
                          isEditMode: false,
                          hostType: HostType.Player,
                      });
                  })
                  .map((entry) => entry.loader)
            : [];
        // biome-ignore lint/complexity/noBannedTypes: <explanation>
    }, [currentMicro]) as React.ComponentType<MicroArtifactProps<{}>>[];

    useEffect(() => {
        if (imperativeRef.current?.toggle) {
            if (props.open) {
                props.setEditState({
                    isSessionPreview: true,
                });
            }
            imperativeRef.current.toggle(props.open ? true : false);
            isPreviewOpen(true);
        }
        if (!props.open && wasOpen.current) {
            props.setEditState({
                isSessionPreview: false,
            });
            isPreviewOpen(false);
        }
        wasOpen.current = props.open;
    }, [props.open]);

    return (
        <Dialog
            imperativeRef={imperativeRef}
            onClose={props.onClose}
            PaperProps={{
                className: cls(classes.paper),
            }}
            BackdropProps={{
                className: cls("standard-backdrop"),
            }}
        >
            <DialogTitle noBottomBorder className={classes.header} title={"Session preview"} key="title">
                Agenda preview
            </DialogTitle>

            <DialogContent className="flex flex-row gap-10">
                <div className={classes.agendaContainer}>
                    <Typography className="mt-8 mb-12 pl-4" fontWeight="bolder">
                        Agenda
                    </Typography>
                    <ScrollContainer
                        connector={scrollContainerRef}
                        ref={itemsBodyRef}
                        key="agenda-preview-items-scroll-container"
                        id="agenda-preview-items-scroll-container"
                        axis="y"
                        // scrollBarOffsetTop={10}
                        // scrollBarOffsetBottom={10}
                        component="div"
                        className={classes.agendaPreviewScrollContainer}
                    >
                        {agendaItems.map((agendaItem) => (
                            <AgendaBoxPreview
                                agendaItem={agendaItem}
                                key={agendaItem.id}
                                isActive={currentAgendaItem.id === agendaItem.id}
                                setActive={handlePreview}
                            />
                        ))}
                    </ScrollContainer>
                </div>
                <div className={cls(classes.microWrapper, "flex11-auto")}>
                    <Fragment key="micro-loaders">
                        {currentMicro?.artifactId !== "flowos/conference" ? (
                            currentMicroComponents?.map((MicroLoader, i) => {
                                return (
                                    <ErrorBoundary key={"micro-" + i} mobileState={props.mobileState} location="SessionContentMicroLoader">
                                        <Fragment key="micro">
                                            <MicroLoader
                                                agendaItem={currentAgendaItem as ForgedAgendaItem}
                                                isAdmin={true}
                                                isAssistant={false}
                                                mobileState={props.mobileState}
                                                inLobby={false}
                                                sessionId={session.id}
                                                myParticipantId={""}
                                                sessionStarted={false}
                                                isPreviewMode={true}
                                            />
                                        </Fragment>
                                    </ErrorBoundary>
                                );
                            })
                        ) : (
                            <img
                                className={classes.conferenceImage}
                                src="https://site.sessions.flowos.com/_content/images/revamp/sessions-discovery.png?v=1?width=532"
                                alt="placeholder"
                            />
                        )}
                    </Fragment>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default DesignerSessionPreview;
