import designer from "../..";
import {DesignerAction} from "../../action";
import {ArtifactResourceResultFragment} from "@generated/data";
import {replaceArtifact} from "../../utils/agendaItemUtils";
import {AgendaItemArtifact} from "../agenda-item/add-artifact-to-agenda-item";

export type SetArtifactResultInput = {
    resourceResult: ArtifactResourceResultFragment;
    artifactId: string;
    isConfigured?: boolean;
};

export class SetArtifactResult extends DesignerAction<SetArtifactResultInput> {
    commit() {
        const agendaItems = structuredClone(designer.currentAgendaItems() ?? []);
        const agendaItemIndex = agendaItems.findIndex((a) => a.artifact?.id === this.input.artifactId);
        const agendaItem = agendaItems[agendaItemIndex];

        if (agendaItem) {
            const newArtifact = {
                ...structuredClone(agendaItem.artifact),
                resourceResults: [this.input.resourceResult],
                ...(this.input.isConfigured !== undefined ? {isConfigured: this.input.isConfigured} : {}),
            } as AgendaItemArtifact;

            agendaItems[agendaItemIndex!].artifact = {
                ...replaceArtifact(agendaItem.artifact, newArtifact, ["resourceResults"]),
                oldId: agendaItem.artifact?.oldId ?? agendaItem.artifact?.id ?? null,
            };

            designer.updateCurrentSession(
                {
                    agendaItems,
                },
                {
                    strategy: "replace",
                }
            );

            return this.input.resourceResult.id;
        }

        return "";
    }
}
