import {ParticipantsMacroAccess, SessionLifecycle} from "@generated/data";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import Input from "@ui/cdk/Input";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {memo, useCallback, useRef} from "@workhorse/api/rendering";
import {notificationIds} from "@workhorse/api/session-settings/sections/NotificationsSection/NotificationsSection";
import toast from "@workhorse/api/toast";
import MacroHeader from "@workhorse/components/header/MacroHeader";
import {ParticipantMacroArtifactProps} from "@workhorse/declarations";
import {EventPeopleWrapper, EventPeopleWrapperWithQuery} from "@workhorse/pages/event/event/event-people/event-people";
import {answerParticipants} from "@workhorse/pages/player/joinApi";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {getCorrectPlural} from "@workhorse/util/strings";
import {useTranslation} from "react-i18next";
import {useShallow} from "zustand/react/shallow";
import {ParticipantsCategory} from "../utils";
import ParticipantsBackstageWarning from "./ParticipantsBackstageWarning";
import ParticipantsCategoryBlock from "./ParticipantsCategoryBlock";
import ParticipantsFooter from "./ParticipantsFooter";
import classes from "./styles/ParticipantsContainer.module.scss";

type ParticipantsContainerProps = Omit<ParticipantMacroArtifactProps, "artifact"> & {
    onToggle?: () => void;
};

function ParticipantsContainer(props: ParticipantsContainerProps) {
    const {t} = useTranslation();
    const {sessionId, isMemoryMode = false, onToggle, isAdmin, isAssistant, myParticipantId} = props;

    const session = useSession();
    const {isMobile} = useMobile();
    const isFirefox = browserInfo.isFirefox();
    const {searchValue, setSearchValue, hasWaitingToJoin, hasSpeakers, hasInvited, hasNotJoined, currentParticipant} = useParticipantsStore(
        useShallow(({searchValue, setSearchValue, waitingToJoin, speakers, invited, notJoined, currentParticipant}) => ({
            searchValue,
            setSearchValue,
            hasWaitingToJoin: !!waitingToJoin.length,
            hasSpeakers: !!speakers.length,
            hasInvited: !!invited.length,
            hasNotJoined: !!notJoined.length,
            currentParticipant: {
                isSpeaker: !!currentParticipant?.speakerDetails?.id,
            },
        }))
    );

    const isOrganizer = isAdmin || isAssistant;
    const isSpeaker = currentParticipant.isSpeaker;

    const scrollContainerRef = useRef<ScrollContainerRef>({});

    const parentSessionId = session.childOfBreakoutRooms?.parentOfBreakoutRooms?.sessionId;

    const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const canSeeattendees =
        session.participantsMacroAccess === ParticipantsMacroAccess.Everyone ||
        ([ParticipantsMacroAccess.Moderators, ParticipantsMacroAccess.Disabled].includes(session.participantsMacroAccess) &&
            (isSpeaker || isOrganizer));

    const canSeeInvited =
        isOrganizer &&
        ((session.lifecycle !== SessionLifecycle.Ended && hasInvited) || (session.lifecycle === SessionLifecycle.Ended && hasNotJoined));

    const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = setTimeout(() => {
            setSearchValue(e.target.value);
        }, 200);
    };

    const handleClearSearchValue = () => {
        setSearchValue("");
    };

    const handleKeyDownSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            handleClearSearchValue();
        }
    };

    const handleWaitingForApproval = useCallback(
        (participantIds: string[], hasBeenApproved: boolean) =>
            answerParticipants(sessionId, participantIds, hasBeenApproved)
                .then((res) => {
                    if (res.data?.answerWaitingParticipants) {
                        toast(`${getCorrectPlural("Participant", participantIds.length)} ${hasBeenApproved ? "approved" : "declined"}!`, {
                            type: "success",
                        });
                    }
                })
                .catch(() => {
                    toast("Something went wrong...", {type: "error"});
                }),
        [sessionId]
    );

    return (
        <div
            className={cls("flex flex-col", classes.root)}
            role="region"
            aria-label={t("macro.participants.aria.participants_panel") ?? "Participants panel"}
        >
            {isMobile ? null : (
                <MacroHeader
                    marginLess
                    playerRadius
                    title={t("macro.participants.title") ?? "Participants"}
                    artifactId={"participants"}
                    onToggle={onToggle}
                    isMemoryMode={isMemoryMode}
                    extraIcons={
                        isOrganizer && session.participantsMacroAccess === ParticipantsMacroAccess.Disabled && !isMemoryMode ? (
                            <div className={classes.warningIcon}>
                                <Tooltip
                                    title={
                                        t("player.settings.general.widgets.participants.warning") ??
                                        "Participants list is only visible to host & co-hosts."
                                    }
                                    arrow
                                    placement="bottom"
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                        ) : null
                    }
                />
            )}

            <div className="flex flex-center-all">
                <Input
                    data-id="participants-container-search"
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    onKeyDown={handleKeyDownSearchInput}
                    placeholder={t("macro.participants.placeholder.search_for_participants") ?? "Search for participants"}
                    variant="standard"
                    maxCharCount={60}
                    formControlClassName={classes.searchBar}
                    startAdornment={<SearchRoundedIcon />}
                    className="rnd-btn"
                    endAdornment={
                        searchValue ? (
                            <IconButton size="small" onClick={handleClearSearchValue}>
                                <CloseRoundedIcon />
                            </IconButton>
                        ) : undefined
                    }
                />

                {isOrganizer && !!session.event && !isMemoryMode && (
                    <EventPeopleWrapperWithQuery
                        eventId={session.event.id}
                        csvOnly
                        includeAttendedStatus={isMemoryMode}
                        isMemoryMode={isMemoryMode}
                    />
                )}
            </div>

            <ScrollContainer
                component="div"
                className={cls(classes.body, isFirefox && classes.isFirefox, "rnd-btn")}
                id="participants-container-body"
                connector={scrollContainerRef}
                axis="y"
                scrollBarOffsetTop={10}
                scrollBarOffsetBottom={10}
                isMobile={isMobile}
            >
                {isOrganizer && hasWaitingToJoin ? (
                    <ParticipantsCategoryBlock
                        type={ParticipantsCategory.WAITING_TO_JOIN}
                        key={ParticipantsCategory.WAITING_TO_JOIN}
                        listKey={"waitingToJoin"}
                        isOwner={isAdmin}
                        isAssistant={isAssistant}
                        currentParticipantId={myParticipantId}
                        sessionId={sessionId}
                        isMemoryMode={isMemoryMode}
                        onWaitingForApproval={handleWaitingForApproval}
                        sessionActualStart={session?.actualStart}
                        sessionDisabledNotifications={session.disabledNotifications.includes(notificationIds.PARTICIPANTS_ENTER_LEAVE)}
                    />
                ) : null}

                <ParticipantsCategoryBlock
                    type={ParticipantsCategory.MODERATORS}
                    listKey={"moderators"}
                    isOwner={isAdmin}
                    isAssistant={isAssistant}
                    currentParticipantId={myParticipantId}
                    sessionId={sessionId}
                    isMemoryMode={isMemoryMode}
                    onWaitingForApproval={handleWaitingForApproval}
                    key={ParticipantsCategory.MODERATORS}
                    sessionActualStart={session?.actualStart}
                />
                {hasSpeakers ? (
                    <ParticipantsCategoryBlock
                        type={ParticipantsCategory.SPEAKERS}
                        listKey="speakers"
                        isOwner={isAdmin}
                        isAssistant={isAssistant}
                        currentParticipantId={myParticipantId}
                        sessionId={sessionId}
                        isMemoryMode={isMemoryMode}
                        onWaitingForApproval={handleWaitingForApproval}
                        key={ParticipantsCategory.SPEAKERS}
                        sessionActualStart={session?.actualStart}
                    />
                ) : null}

                {canSeeattendees ? (
                    <ParticipantsCategoryBlock
                        type={ParticipantsCategory.ATTENDEES}
                        listKey={"joined"}
                        isOwner={isAdmin}
                        isAssistant={isAssistant}
                        currentParticipantId={myParticipantId}
                        sessionId={sessionId}
                        isMemoryMode={isMemoryMode}
                        onWaitingForApproval={handleWaitingForApproval}
                        key={ParticipantsCategory.ATTENDEES}
                        sessionActualStart={session?.actualStart}
                    />
                ) : null}

                {canSeeInvited ? (
                    <ParticipantsCategoryBlock
                        type={ParticipantsCategory.INVITED}
                        listKey={"notJoined"}
                        isOwner={isAdmin}
                        isAssistant={isAssistant}
                        currentParticipantId={myParticipantId}
                        sessionId={sessionId}
                        isMemoryMode={isMemoryMode}
                        onWaitingForApproval={handleWaitingForApproval}
                        key={ParticipantsCategory.INVITED}
                        sessionActualStart={session?.actualStart}
                    />
                ) : null}

                {session.backstage && <ParticipantsBackstageWarning sessionId={sessionId} />}
            </ScrollContainer>

            {!isMemoryMode && (
                <ParticipantsFooter
                    sessionId={sessionId}
                    parentSessionId={parentSessionId}
                    isOwner={isAdmin}
                    isAssistant={isAssistant}
                    sendEmailsAutomatically={session.sendEmailsAutomatically}
                    requestPermissionToJoin={session.requestPermissionToJoin}
                    sessionLifecycle={session.lifecycle}
                    sessionName={session.name}
                    isRoom={!!session.room?.id}
                    roomSlug={session.room?.slug ?? undefined}
                    provideSessionPasscode={session.provideSessionPasscode}
                    passcodeToJoin={session.passcodeToJoin ?? undefined}
                    sessionHideIcsGuestList={session.hideIcsGuestList}
                />
            )}

            {isOrganizer && isMemoryMode && <EventPeopleWrapper csvOnly includeAttendedStatus isMemoryMode={isMemoryMode} />}
        </div>
    );
}

export default memo(ParticipantsContainer);
