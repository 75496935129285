import {SetSlideDocument, ToggleSlideshowControlsDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";

let setSlideForAllTimeout: NodeJS.Timeout | null = null;

export const setSlideForAll = async (artifactId: string, slide: number) => {
    if (setSlideForAllTimeout) {
        clearTimeout(setSlideForAllTimeout);
    }
    setSlideForAllTimeout = setTimeout(() => {
        apollo.client.mutate({
            mutation: SetSlideDocument,
            variables: {
                artifactId,
                slide: slide.toString(),
            },
        });
    }, 600);
};

export const toggleSlideshowControls = async (artifactId: string, mode: boolean) => {
    return await apollo.client.mutate({
        mutation: ToggleSlideshowControlsDocument,
        variables: {
            artifactId,
            mode: (!!mode).toString(),
        },
    });
};

export const toggleSlideshowControlsWithPage = async (artifactId: string, mode: boolean, slide: number | null) => {
    let slidePromise: Promise<void> | null = null;

    if (slide !== null) {
        slidePromise = apollo.client
            .mutate({
                mutation: SetSlideDocument,
                variables: {
                    artifactId,
                    slide: slide.toString(),
                },
            })
            .then(() => {
                // void
            });
    }

    const togglePromise = apollo.client
        .mutate({
            mutation: ToggleSlideshowControlsDocument,
            variables: {
                artifactId,
                mode: (!!mode).toString(),
            },
        })
        .then(() => {
            // void
        });

    const promises: Array<Promise<void>> = [togglePromise];

    if (slidePromise) {
        promises.push(slidePromise);
    }

    return Promise.all(promises);
};
