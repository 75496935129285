import React, {useEffect} from "@workhorse/api/rendering";
import {useRemoteApplicationState} from "@workhorse/api/application/state";
import {BuildInfoJSON} from "@workhorse/declarations";

type AppStateChanges = {
    isDeployInProgress: boolean;
    isVersionMismatch: boolean;
};

export type OnRemoteAppStateChange = (changes: AppStateChanges) => void;

type WithApplicationStateProps = {
    onStateChange: OnRemoteAppStateChange;
    children?: React.ReactNode | React.ReactNode[];
};

function WithApplicationState(props: WithApplicationStateProps) {
    const {onStateChange} = props;
    const {applicationState} = useRemoteApplicationState();
    const buildInfoJSON = process.env.REACT_APP_BUILD_INFO;
    let buildInfo = {} as BuildInfoJSON;

    if (buildInfoJSON) {
        try {
            buildInfo = JSON.parse(buildInfoJSON);
        } catch (e) {}
    }

    const isDeployInProgress = applicationState?.currentOperation === "DEPLOY_IN_PROGRESS";
    const isVersionMismatch = applicationState?.releaseInfo?.versionTag !== buildInfo.tag;

    useEffect(() => {
        onStateChange({
            isDeployInProgress,
            isVersionMismatch,
        });
    }, [isDeployInProgress, isVersionMismatch]);

    return <>{props.children}</>;
}

export default WithApplicationState;
