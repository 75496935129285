import {useState, useEffect} from "@workhorse/api/rendering";
import MicOnIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import style from "./styles/AudienceParticipant.module.scss";
import {cls} from "@ui/cdk/util";

export default function ConferenceMicIcon(props: {
    className?: string;
    isMuted: boolean;
    isThumbnailMode?: boolean;
    volume: number;
    onClick?: () => void;
}) {
    return (
        <div
            className={cls(
                style.statusIconContainer,
                props.className,
                props.isMuted ? style.statusOff : "",
                props.isThumbnailMode ? style.isThumbnail : ""
            )}
            onClick={props.onClick}
        >
            {props.isMuted ? <MicOffIcon data-id="mic-off" /> : <MicOnIcon data-id="mic-on" />}
            {!props.isMuted ? (
                <div className={style.audioAnimationGradient} style={{transform: `translateY(${-props.volume * 100}%)`}} />
            ) : null}
        </div>
    );
}
