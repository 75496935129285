import {CalendarSettingsDocument, DrawerState, LayoutDocument, LocalUserDocument, AudienceMobileOpenState, HostType} from "@generated/data";
import {DataProxy, TypedDocumentNode} from "@workhorse/api/data";
import {DeepPartial} from "@common/declarations";

export type WriteQueryArg<T extends TypedDocumentNode> = T extends TypedDocumentNode<infer T, infer TVars>
    ? DataProxy.WriteQueryOptions<T, TVars>
    : never;

const layoutDefaults: WriteQueryArg<typeof LayoutDocument> = {
    query: LayoutDocument,
    data: {
        content: {
            __typename: "Content",
            isFullscreen: false,
            audienceMobileOpenState: AudienceMobileOpenState.Closed,
        },
        leftDrawer: {
            __typename: "LeftDrawer",
            state: DrawerState.Closed,
        },
        leftDrawerInPreview: {
            __typename: "LeftDrawerInPreview",
            state: DrawerState.Closed,
        },
        rightDrawerInPreview: {
            __typename: "RightDrawerInPreview",
            state: DrawerState.Closed,
        },
        rightDrawer: {
            __typename: "RightDrawer",
            state: DrawerState.Closed,
        },
    },
};

/**
 * This is a persisted Document
 * It only requires the __typename
 * The missing properties are added via the persistedField function
 */
const calendarSettingsDefault: DeepPartial<WriteQueryArg<typeof CalendarSettingsDocument>> = {
    query: CalendarSettingsDocument,
    data: {
        calendarSettings: {
            __typename: "CalendarSettings",
        },
    },
};

const localUserDefaults: WriteQueryArg<typeof LocalUserDocument> = {
    query: LocalUserDocument,
    data: {
        isAuthenticated: false,
        localUser: {
            __typename: "LocalUser",
            email: "",
            firstName: "",
            lastName: "",
            language: "",
            role: "",
        },
    },
};

export default {
    layoutDefaults,
    calendarSettingsDefault,
    localUserDefaults,
};
