import {TEMPLATE_FOLDER_NAME_FOR_USERS} from "@sessions/common/agenda-templates";
import {AgendaTemplateFragment, DeleteAgendaTemplateDocument} from "@generated/data";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import {dateFormat} from "@ui/cdk/DateFormat";
import TableCell from "@ui/cdk/Table/TableCell";
import TableRow from "@ui/cdk/Table/TableRow";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import apollo from "@workhorse/api/apollo";
import {useMutation} from "@workhorse/api/data";
import {useCallback, useState} from "@workhorse/api/rendering";
import secondsToTime from "@workhorse/api/utils/secondsToTime";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import UsageHistory from "@workhorse/components/usage-history/UsageHistory";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {useUserInfo} from "@workhorse/providers/User";
import {useInView} from "react-intersection-observer";
import AgendaTemplatesTableRowMenu from "./AgendaTemplatesTableRowMenu";
import classes from "./style/AgendaTemplatesTableRow.module.scss";

interface Props {
    index: number;
    atTop: boolean;
    table: HTMLElement | null;
    agenda: AgendaTemplateFragment;
    hasActionsColumn: boolean;
    onClickAgenda: (agendaTemplateId: string) => void;
    tagName: string;
    onDuplicateAgendaTemplate: () => void;
}

const AgendaTemplatesTableRow = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [openDeleteTemplate, setOpenDeleteTemplate] = useState<boolean>(false);
    const [usageHistoryOpen, setUsageHistoryOpen] = useState(false);
    const [doDelete] = useMutation(DeleteAgendaTemplateDocument);

    const {ref, inView} = useInView({
        root: props.table,
        initialInView: props.index < 20,
    });

    const user = useUserInfo();

    const isInView = props.atTop && props.index < 20 ? true : inView;

    const workspaceAccess = useWorkspaceAccess();
    const canDeleteAgenda = workspaceAccess.canDeleteAgenda(props.agenda.user.id);

    const handleClickAgenda: React.MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation();
        if (!e.currentTarget.contains(e.target as Node)) {
            return;
        }
        props.onClickAgenda(props.agenda.id);
    };

    const handleContextMenuOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDeleteTemplate = useCallback(() => {
        setOpenDeleteTemplate((prev) => !prev);
    }, []);

    const toggleUsageHistory = useCallback(() => {
        setUsageHistoryOpen((prev) => !prev);
    }, []);

    const deleteTemplate = useCallback(async () => {
        const deletingId = props.agenda.id;

        await doDelete({
            variables: {
                where: {
                    id: deletingId,
                },
            },
        });

        apollo.cache.evict({
            id: apollo.cache.identify({
                __typename: "AgendaTemplate",
                id: deletingId,
            }),
        });
        apollo.cache.gc();

        toggleDeleteTemplate();
    }, [doDelete, toggleDeleteTemplate, props.agenda]);

    const confirmDelete = useCallback(async () => {
        await deleteTemplate();
    }, [deleteTemplate]);

    // TODO
    const isEditable = true;
    const agendaOwnerName = props.agenda.user.id === user.id ? "Me" : props.agenda.user.firstName + " " + props.agenda.user.lastName;

    if (!isInView) {
        return <tr ref={ref} className={classes.contactRowHeight} />;
    }

    const duration = props.agenda.duration ? secondsToTime(props.agenda.duration, true) : null;

    return (
        <>
            <TableRow
                ref={ref}
                className={cls(classes.contactRow, classes.contactRowHeight)}
                onClick={handleClickAgenda}
                data-id="agendas-entry-preview"
            >
                <TableCell size="large" className={classes.cell}>
                    <Typography
                        data-id="agenda-item-name"
                        color="secondary"
                        // data-private
                    >
                        {props.agenda.name}
                    </Typography>
                </TableCell>

                <TableCell className={classes.cell}>
                    {!duration ? "-" : ""}
                    {duration?.hours ? duration.hours : ""} {duration?.minutes ? duration.minutes : ""}{" "}
                    {duration?.seconds ? duration.seconds : ""}
                </TableCell>
                <TableCell className={classes.cell}>
                    {dateFormat(props.agenda.updatedAt, ["monthShort", "dayOfMonth", {sep: ","}, "year"])}
                    {/* TO DO: add person who last updated */}
                </TableCell>

                {props.tagName !== TEMPLATE_FOLDER_NAME_FOR_USERS && (
                    <TableCell className={cls("flex flex-align-center", classes.cell)}>
                        <ProfilePicture
                            firstName={props.agenda.user.firstName}
                            lastName={props.agenda.user.lastName}
                            avatar={props.agenda.user.profilePicture}
                            avatarSize="S"
                            avatarVariant="rounded"
                            className="mr-8"
                        />
                        {agendaOwnerName.length > 30 ? (
                            <Tooltip title={agendaOwnerName} placement="top" arrow>
                                <Typography component="p">{agendaOwnerName}</Typography>
                            </Tooltip>
                        ) : (
                            <Typography component="p">{agendaOwnerName}</Typography>
                        )}
                    </TableCell>
                )}

                {props.hasActionsColumn && (
                    <TableCell size="medium" className={classes.cell}>
                        <IconButton
                            data-id="more-actions"
                            onClick={handleContextMenuOpen}
                            className={cls(classes.moreBtn, anchorEl && classes.moreBtnActive)}
                        >
                            <MoreVertRoundedIcon />
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>

            {anchorEl && (
                <AgendaTemplatesTableRowMenu
                    anchorEl={anchorEl}
                    canDeleteAgenda={canDeleteAgenda}
                    onClose={handleClose}
                    toggleUsageHistory={toggleUsageHistory}
                    toggleDeleteTemplate={toggleDeleteTemplate}
                    onDuplicateAgendaTemplate={props.onDuplicateAgendaTemplate}
                    isShared={props.tagName !== TEMPLATE_FOLDER_NAME_FOR_USERS}
                />
            )}

            <ConfirmationDialog
                key="delete-template-dialog"
                title="Delete agenda"
                cancelButton="Cancel"
                content="If you delete now, your changes to this agenda will be lost."
                minWidth
                submitButton="Delete"
                onClose={toggleDeleteTemplate}
                open={openDeleteTemplate}
                submitButtonVariant="destructive-secondary"
                onConfirm={confirmDelete}
                variant="warning"
                isDeleteConfirmation
            />
            {usageHistoryOpen ? (
                <UsageHistory
                    open={usageHistoryOpen}
                    onClose={toggleUsageHistory}
                    agendaTemplateId={props.agenda.id}
                    itemTitle={props.agenda.name}
                />
            ) : null}
        </>
    );
};

export default AgendaTemplatesTableRow;
