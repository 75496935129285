import clientEvents from "@api/events/client";
import {
    AgendaItemType,
    AudienceMobileOpenState,
    ConfigurationStep,
    DrawerState,
    GetSessionResourceNamesDocument,
    HostType,
    LayoutContentDocument,
    LeftDrawerDocument,
    PauseAgendaItemDocument,
    PushDrawerStateResult,
    ResourceNameFragment,
    RightDrawerDocument,
    SessionLifecycle,
    SetSessionOrderDocument,
    UpdateArtifactPropertyDocument,
} from "@generated/data";
import apollo, {ApolloOperationContext} from "@workhorse/api/apollo";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import designer from "@workhorse/api/designer";
import {useCallback, useMemo} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";
import {hasNavigation} from "@workhorse/components/drawers/DrawerLeft";
import {mutate, query, readFragment, readQuery, useQuery, writeFragment, writeQuery} from "@workhorse/dataApi";
import {DrawerHookState} from "@workhorse/declarations";
import type {DesignerApiSession as Session} from "@workhorse/declarations/dataTypes";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {setBreakoutLeaveNotifier, startingSession} from "@workhorse/pages/player/playerStateVars";
import {makeHostTypeFromRoute} from "@workhorse/util/makeHostTypeFromRoute";
import {drawerRightActiveComponent, DrawerRightInjectableTag} from "./inject";
import {readCurrentParticipant, readMacroArtifacts} from "./SessionDataProviders";
import {WatchdogPayload} from "./Watchdog/Watchdog";

const DRAWER_STATE_MAP = {
    isFullyOpen: DrawerState.FullyOpen,
    isClosed: DrawerState.Closed,
    isSemiOpen: DrawerState.SemiOpen,
};

export const updateArtifactProperty = (artifactId: string, key: string, value: string) => {
    const inConfigConfirmMode = designer?.state?.getSnapshot()?.configurationConfirm;
    if (inConfigConfirmMode) {
        return;
    }

    apollo.client.mutate({
        mutation: UpdateArtifactPropertyDocument,
        variables: {
            key,
            value,
            artifactId,
        },
        fetchPolicy: "no-cache",
    });
};

export function agendaChanged(oldItems: Session["agendaItems"], newItems: Session["agendaItems"]) {
    const current = oldItems.map((obj) => obj.id);
    const incoming = newItems.filter((obj) => !obj.isDeleted).map((obj) => obj.id);
    return current.length === incoming.length ? current.filter((id) => incoming.includes(id)).length !== incoming.length : true;
}

export const orchestrator = {
    // this one sets the order when instructed to do so
    // ...by "the subscription"
    // it CAN be triggered manually, for testing purposes only
    onSubscriptionNewIndex: (sessionId: string, newIndex: number, currentMicroTstart?: string | undefined | null) => {
        writeFragment({
            fragment: "SessionOrderFragmentDoc",
            data: {
                __typename: "Session",
                order: newIndex,
            },
            id: sessionId,
        });
        // (isPreviewMode ? clientDesigner.client : apollo.client).writeFragment({
        //     id: sessionId,
        //     fragment: SessionOrderFragmentDoc,
        //     data: {
        //         order: newIndex,
        //     },
        // });

        // const sess = (isPreviewMode ? clientDesigner.cache : apollo.cache).readFragment({
        //     fragment: OneSessionFragmentDoc,
        //     fragmentName: "OneSession",
        //     id: apollo.cache.identify({__ref: sessionId}),
        // });

        // // @ts-ignore todo @Maca
        // if (!(currentMicroTstart && sess && sess.artifacts && sess.artifacts.length > 0)) {
        //     return;
        // }

        // // @ts-ignore todo @Maca
        // const micro = sess.artifacts.find((a) => !a.isMacro && a.order === newIndex);
        // // TODO: check if this still works
        // if (!micro || (micro.startAt && micro.startAt.toISOString() === currentMicroTstart)) {
        //     return;
        // }

        if (currentMicroTstart) {
            writeQuery("LocalAgendaItemDocument", {
                variables: {
                    sessionId,
                    order: newIndex,
                },
                data: {
                    __typename: "Query",
                    agendaItems: [
                        {
                            ...readQuery("LocalAgendaItemDocument", {
                                variables: {
                                    sessionId,
                                    order: newIndex,
                                },
                            })?.agendaItems[0]!,
                            startAt: new Date(currentMicroTstart),
                        },
                    ],
                },
            });
        }
    },

    orderBeforePreview: <number | null>null,

    // this one however, is a manual trigger
    // ...but, available only to session owners/admins
    // after this mutation is executed, every client will be notified via subscription
    // and the function above will be executed
    // THIS IS SENT TO THE BACKEND
    // triggering this without an existing session, will have no effect
    gotoIndex: (sessionId: string, newIndex: number, ignoreBorBlocker?: boolean) => {
        const designerState = readQuery("DesignerStateDocument")?.designerState;
        const isPreview = designerState?.isSessionPreview ? true : false;

        const currentOrder = readFragment({
            fragment: "SessionOrderFragmentDoc",
            id: sessionId,
        })?.order;

        if (!isPreview) {
            const agendaItems =
                readQuery("LocalAgendaItemsDocument", {
                    variables: {
                        id: sessionId,
                    },
                })?.agendaItems! ?? [];

            const currentIsBOR = agendaItems.find((a) => a.order === currentOrder)?.artifact?.artifactId === "flowos/breakout";

            if (currentIsBOR && !ignoreBorBlocker) {
                setBreakoutLeaveNotifier({
                    onCancel: () => {
                        return;
                    },
                    onConfirm: () => {
                        orchestrator.gotoIndex(sessionId, newIndex, true);
                    },
                    open: true,
                });
                return;
            }
        }

        if (isPreview) {
            if (designer.currentSession()?.id === sessionId) {
                if (typeof currentOrder === "number" && orchestrator.orderBeforePreview === null) {
                    orchestrator.orderBeforePreview = currentOrder;
                }
                designer.api.session.update({
                    order: newIndex,
                });
            }
        }

        if (!isPreview) {
            apollo.client
                .mutate({
                    mutation: SetSessionOrderDocument,
                    variables: {
                        order: newIndex,
                        sessionId,
                    },
                })
                .then((res) => {
                    // TODO: @vasi implement a queue in the watchdog to process only the last payload containing the order
                    // console.log("writing new order", newIndex);
                    // writeFragment({
                    //     fragment: "SessionOrderFragmentDoc",
                    //     id: sessionId,
                    //     data: {
                    //         __typename: "Session",
                    //         order: newIndex,
                    //     },
                    //     variables: {
                    //         isEditMode: isPreview,
                    //     },
                    // });
                });
        }
    },

    pauseAgendaItem: (sessionId: string) => {
        return apollo.client.mutate({
            mutation: PauseAgendaItemDocument,
            variables: {sessionId},
        });
    },
    // when previewing a session, navigation is allowed locally
    // but the session.order will still change
    // meaning we have to reset it to the value it had before we went in preview mode
    // otherwise it will be preserved and might end up being commited
    resetSessionOrder: (sessionId: string) => {
        if (typeof orchestrator.orderBeforePreview === "number" && designer.currentSession()?.id === sessionId) {
            designer.api.session.update({
                order: orchestrator.orderBeforePreview,
            });
            orchestrator.orderBeforePreview = null;
        }
    },

    onSubscriptionResourceNames: (sessionId: string, resourceNames: ResourceNameFragment[]) => {
        const currentResourceNames = apollo.client.readQuery({
            query: GetSessionResourceNamesDocument,
            variables: {
                id: sessionId,
            },
        });

        apollo.client.writeQuery({
            query: GetSessionResourceNamesDocument,
            variables: {
                id: sessionId,
            },
            data: {
                ...currentResourceNames,
                sessionResourceNames: {
                    ...currentResourceNames?.sessionResourceNames,
                    names: (currentResourceNames?.sessionResourceNames?.names?.concat(resourceNames) ?? resourceNames).map((r) => ({
                        ...r,
                        __typename: "ResourceName" as const,
                    })),
                    __typename: "ResourceNames" as const,
                },
                __typename: "Query",
            },
            broadcast: true,
        });
    },

    onSubscriptionUpsertRecordings: (sessionId: string, recordings: NonNullable<WatchdogPayload["watchdog"]["upsertRecordings"]>) => {
        const latestRecording = recordings.reduce((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? a : b));

        const existing = readQuery("SessionRecordingDocument", {
            variables: {
                where: {
                    id: sessionId,
                },
            },
        });

        writeQuery("SessionRecordingDocument", {
            variables: {
                where: {
                    id: sessionId,
                },
            },
            data: {
                ...existing,
                sessionRecording: {
                    ...(existing?.sessionRecording ?? {}),
                    ...latestRecording,
                },
            },
        });
    },

    onSubscriptionUpsertMacroArtifacts: (
        sessionId: string,
        macroArtifacts: NonNullable<WatchdogPayload["watchdog"]["upsertMacroArtifacts"]>
    ) => {
        const existingData = readMacroArtifacts({sessionId})?.macroArtifacts;
        if (!existingData) {
            return;
        }

        const override = !orchestrator.isOwnerOrAssistant(sessionId);

        writeQuery("LocalMacroArtifactsDocument", {
            variables: {
                id: sessionId,

                // @ts-ignore
                override: override,
            },
            data: {
                __typename: "Query",
                macroArtifacts: existingData
                    .map((macro) => {
                        return macroArtifacts.find((a) => a.id === macro.id) ?? macro;
                    })
                    .concat(macroArtifacts.filter((macro) => !existingData.find((a) => a.id === macro.id))),
            },
        });
        macroArtifacts
            .filter((macro) => !existingData.find((a) => a.id === macro.id))
            .forEach((macro) => {
                if (macro) {
                    // @ts-expect-error
                    clientEvents.emit("artifact-updated", {artifact: macro});
                }
            });
    },

    onSubscriptionUpsertAgendaItems: async (
        sessionId: string,
        agendaItems: NonNullable<WatchdogPayload["watchdog"]["upsertAgendaItems"]>
    ) => {
        const existing =
            readQuery("LocalAgendaItemsDocument", {
                variables: {
                    id: sessionId,
                },
            })?.agendaItems! ?? [];

        const override = !orchestrator.isOwnerOrAssistant(sessionId);

        const newAgendaItems = existing
            .map((agendaItem) => {
                return agendaItems.find((a) => a.id == agendaItem.id) ?? agendaItem;
            })
            .concat(agendaItems.filter((agendaItem) => !existing.find((a) => a.id === agendaItem.id)))
            .sort((a, b) => {
                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
            });

        const hasDesignerSession = designer.state.getSessionId();

        if (hasDesignerSession) {
            const macros =
                readQuery("LocalMacroArtifactsDocument", {
                    variables: {
                        id: sessionId,
                    },
                })?.macroArtifacts! ?? [];

            const hasAgenda = macros.some((macro) => macro.artifactId === "flowos/agenda");

            const step = designer.state.getSnapshot().configurationStep;
            const newIsAgendaLess = structuredClone(newAgendaItems).every(
                (a) => a.type === AgendaItemType.Instant || a.title === designer.constants.DUMMY_AGENDA_ITEM_TITLE
            );

            if (!newIsAgendaLess && hasAgenda && ![ConfigurationStep.Edit, ConfigurationStep.Preview].includes(step)) {
                designer.state.update({
                    configurationStep: ConfigurationStep.Preview,
                });
            }
        }

        if (agendaItems) {
            writeFromFullSession(
                sessionId,
                {
                    agendaItems: newAgendaItems,
                },
                override
            );

            agendaItems.forEach((obj) => {
                if (obj.artifact) {
                    clientEvents.emit("artifact-updated", {artifact: obj.artifact});

                    if (obj.artifact.resourceResults.length) {
                        obj.artifact.resourceResults.forEach((result) => {
                            const current = readQuery("UseResourceResultDocument", {variables: {resultId: result.id}});

                            if (!current?.resourceResult) {
                                writeQuery("UseResourceResultDocument", {
                                    variables: {resultId: result.id},
                                    data: {
                                        __typename: "Query",
                                        resourceResult: result as any,
                                    },
                                });
                            }
                        });
                    }
                }
            });
        }
        return true;
    },

    onSubscriptionDeleteMacroArtifacts: (sessionId: string, artifactIds: string[]) => {
        artifactIds.forEach((p) => {
            apollo.cache.evict({
                id: p,
            });
        });
    },

    onSubscriptionDeleteAgendaItems: (sessionId: string, agendaItemIds: string[]) => {
        agendaItemIds.forEach((p) => {
            apollo.cache.evict({
                id: p,
            });
        });

        const existing =
            readQuery("LocalAgendaItemsDocument", {
                variables: {
                    id: sessionId,
                },
            })?.agendaItems! ?? [];

        if (
            existing.length === 1 &&
            (existing?.[0].type === AgendaItemType.Instant || existing?.[0].title === designer.constants.DUMMY_AGENDA_ITEM_TITLE)
        ) {
            setLeftDrawerState(DrawerState.Closed);
        }
    },

    onSubscriptionDeleteParticipants: (sessionId: string, participantIds: string[]) => {
        participantIds.forEach((p) => {
            apollo.cache.evict({
                id: p,
            });
        });
    },

    updateDesignerCache: (currentParticipant?: SessionData["currentParticipant"]) => {
        if (currentParticipant) {
            designer.api.session.update({
                currentParticipant: {
                    ...(currentParticipant as any),
                    __typename: "Participant",
                },
            });
        }
    },

    isOwnerOrAssistant: (sessionId: string) => {
        const participant = readCurrentParticipant({sessionId});
        const isOwner = participant?.isOwner ?? false;
        const isAssistant = participant ? rbac(participant, "session.isAssistant") : false;

        return isOwner || isAssistant;
    },

    getCurrentParticipant: async (sessionId: string) => {
        return await query("LocalCurrentParticipantDocument", {
            variables: {
                id: sessionId,
            },
            fetchPolicy: "no-cache",
        }).then((res) => res.data?.currentParticipant);
    },

    refetchSession: async (sessionId: string) => {
        // console.log({writingOrder: gotoOrder});
        const current = readQuery("GetSessionDocument", {
            variables: {
                id: sessionId,
            },
        });
        if (current && current.session && !current.session.createdAt) {
            return;
        }
        return await query("FullSessionDocument", {
            variables: {
                id: sessionId,
            },
            fetchPolicy: "no-cache",
        });
    },

    startSession: async (sessionId: string, context?: ApolloOperationContext, backstage?: boolean) => {
        // This causes the presence to be mounted before the session is started
        // const currentParticipant = readCurrentParticipant(sessionId);
        startingSession(true);
        // writeFragment({
        //     fragment: "OneSessionLifecycleFragmentDoc",
        //     id: sessionId,
        //     data: {
        //         __typename: "Session",
        //         id: sessionId,
        //         lifecycle: SessionLifecycle.Started,
        //         actualStart: new Date(),
        //     },
        // });

        return mutate("StartSessionDocument", {
            variables: {
                sessionId,
                backstage,
            },
            context,
        })
            .then((res) => {
                if (res.data?.startSession.lifecycle === SessionLifecycle.Started) {
                    writeFragment({
                        fragment: "OneSessionLifecycleFragmentDoc",
                        id: sessionId,
                        data: {
                            __typename: "Session",
                            ...res.data?.startSession,
                            id: sessionId,
                        },
                    });

                    clientEvents.emit("session-started", true);
                }
                startingSession(false);
                return res;
            })
            .finally(() => {
                startingSession(false);
            });
    },

    startSessionFromBackstage: async (sessionId: string) => {
        // This causes the presence to be mounted before the session is started
        // const currentParticipant = readCurrentParticipant(sessionId);
        startingSession(true);
        // writeFragment({
        //     fragment: "OneSessionLifecycleFragmentDoc",
        //     id: sessionId,
        //     data: {
        //         __typename: "Session",
        //         id: sessionId,
        //         lifecycle: SessionLifecycle.Started,
        //         actualStart: new Date(),
        //     },
        // });

        return mutate("StartSessionFromBackstageDocument", {
            variables: {
                sessionId,
            },
        })
            .then((res) => {
                if (res.data?.startSessionFromBackstage.lifecycle === SessionLifecycle.Started) {
                    writeFragment({
                        fragment: "OneSessionLifecycleFragmentDoc",
                        id: sessionId,
                        data: {
                            __typename: "Session",
                            ...res.data?.startSessionFromBackstage,
                            id: sessionId,
                        },
                    });

                    clientEvents.emit("session-started", true);
                }
                startingSession(false);
                return res;
            })
            .finally(() => {
                startingSession(false);
            });
    },

    endSession: async (sessionId: string) => {
        return await mutate("EndSessionDocument", {
            variables: {
                sessionId,
            },
        });
    },

    onSubscriptionDrawerChange: (sessionId: string, data: PushDrawerStateResult) => {
        const {rightDrawer, leftDrawer} = data;

        if (rightDrawer) {
            if (rightDrawer.activeComponent) {
                drawerRightActiveComponent(rightDrawer.activeComponent as DrawerRightInjectableTag);
            }

            setRightDrawerState(DRAWER_STATE_MAP[rightDrawer.state], rightDrawer.isDetached ?? false);
        }

        if (leftDrawer) {
            setLeftDrawerState(DRAWER_STATE_MAP[leftDrawer.state], !leftDrawer.isDetached, leftDrawer.isDetached ?? false);
        }
    },
};

interface DrawerToggler extends Array<DrawerHookState | Function> {
    0: DrawerHookState;
    1: (newState?: DrawerState | undefined, loc?: string) => void;
    2: (state?: boolean) => void;
}

interface AudienceDrawerToggler extends Array<AudienceMobileOpenState | Function> {
    0: AudienceMobileOpenState;
    1: (newState?: AudienceMobileOpenState) => void;
}

export function setLeftDrawerState(newState: DrawerState, ignoreRightDrawer?: boolean, isDetached?: boolean) {
    const data = readQuery("LeftDrawerDocument");
    const previewData = readQuery("LeftDrawerInPreviewDocument");

    const obj = data?.leftDrawer;

    apollo.client.writeQuery({
        query: LeftDrawerDocument,
        data: {
            leftDrawer: {
                ...obj,
                state: newState,
                isDetached: isDetached ?? false,
            },
        },
    });
}

export function setRightDrawerState(newState: DrawerState, isDetached?: boolean, isPreviewMode?: boolean) {
    const data = readQuery("RightDrawerDocument");

    const obj = data?.rightDrawer;

    const key = "rightDrawer";
    apollo.client.writeQuery({
        query: RightDrawerDocument,
        data: {
            [key]: {
                ...obj,
                state: newState,
                isDetached: isDetached ?? false,
            },
        },
    });
}

export const useDrawerLeftToggler = (): DrawerToggler => {
    const {data} = useQuery("LeftDrawerDocument");

    const obj = data?.leftDrawer;

    const currentState = obj?.state;

    const isClosed = currentState === DrawerState.Closed;
    const isSemiOpen = currentState === DrawerState.SemiOpen;
    const isFullyOpen = currentState === DrawerState.FullyOpen;
    const isDetached = data?.leftDrawer.isDetached ?? false;

    const toggleDetach = useCallback(
        (state?: boolean) => {
            const newState = state ?? !isDetached;
            apollo.client.writeQuery({
                query: LeftDrawerDocument,

                data: {
                    leftDrawer: {
                        state: currentState ?? DrawerState.FullyOpen,
                        isDetached: newState,
                        __typename: "LeftDrawer",
                    },
                },
            });
        },
        [isDetached, currentState]
    );

    const toggle = useCallback(
        (newState?: DrawerState, loc?: string) => {
            const stateToWrite = !newState ? (isClosed || isSemiOpen ? DrawerState.FullyOpen : DrawerState.Closed) : newState;
            // console.log("dw-left", {stateToWrite, isPreview: isPreviewMode, location: loc});

            const hostType = makeHostTypeFromRoute(window.location.pathname);
            const ignoreRightDrawer = hostType === HostType.Memory;
            const isPlayer = hostType === HostType.Player;

            if (hostType && hasNavigation(hostType) && stateToWrite !== DrawerState.Closed) {
                localStorage.setItem("hay.drawerState.left", stateToWrite);
            }

            setLeftDrawerState(stateToWrite, ignoreRightDrawer, isPlayer ? isDetached : false);
        },
        [currentState, isDetached]
    );

    return useMemo(
        () => [
            {
                isClosed,
                isSemiOpen,
                isFullyOpen,
                isDetached,
                state: isClosed ? "isClosed" : isSemiOpen ? "isSemiOpen" : "isFullyOpen",
            },
            toggle,
            toggleDetach,
        ],
        [currentState, isDetached, toggle, toggleDetach]
    );
};

export const useDrawerRightToggler = (): DrawerToggler => {
    const {data} = useQuery("RightDrawerDocument");

    const obj = data?.rightDrawer;

    const currentState = obj?.state;

    const isClosed = currentState === DrawerState.Closed;
    const isSemiOpen = currentState === DrawerState.SemiOpen;
    const isFullyOpen = currentState === DrawerState.FullyOpen;
    const isDetached = data?.rightDrawer.isDetached ?? false;

    const toggleDetach = useCallback(
        (state?: boolean) => {
            const newState = state ?? !isDetached;
            apollo.client.writeQuery({
                query: RightDrawerDocument,

                data: {
                    rightDrawer: {
                        state: currentState ?? DrawerState.FullyOpen,
                        isDetached: newState,
                        __typename: "RightDrawer",
                    },
                },
            });
        },
        [isDetached, currentState]
    );

    const toggle = useCallback(
        (newState?: DrawerState) => {
            const key = "rightDrawer";
            const stateToWrite = !newState ? (isClosed || isSemiOpen ? DrawerState.FullyOpen : DrawerState.Closed) : newState;
            const hostType = makeHostTypeFromRoute(window.location.pathname);
            const isPlayer = hostType === HostType.Player;
            const detachedState = stateToWrite === DrawerState.Closed || !isPlayer ? false : isDetached;

            apollo.client.writeQuery({
                query: RightDrawerDocument,
                data: {
                    [key]: {
                        ...obj,
                        isDetached: detachedState,
                        state: stateToWrite,
                    },
                },
            });
        },
        [currentState, isDetached]
    );

    return useMemo(
        () => [
            {
                isClosed,
                isSemiOpen,
                isDetached,
                isFullyOpen,
                state: isClosed ? "isClosed" : isSemiOpen ? "isSemiOpen" : "isFullyOpen",
            },
            toggle,
            toggleDetach,
        ],
        [isClosed, isSemiOpen, isFullyOpen, isDetached]
    );
};

export const useMobileAudienceDrawerState = (): AudienceDrawerToggler => {
    const {data} = useQuery("LayoutContentDocument");

    const openState = data?.content.audienceMobileOpenState as AudienceMobileOpenState;
    const fullscreenState = data?.content.isFullscreen;

    // The flow is as follows: CLOSED -> FULLY_OPEN -> SEMI_OPEN -> CLOSED
    const toggle = (newState?: AudienceMobileOpenState) => {
        apollo.client.writeQuery({
            query: LayoutContentDocument,
            data: {
                __typename: "Query",
                content: {
                    __typename: "Content",
                    audienceMobileOpenState:
                        typeof newState !== "undefined"
                            ? newState
                            : openState === AudienceMobileOpenState.Closed
                            ? AudienceMobileOpenState.FullyOpen
                            : openState === AudienceMobileOpenState.FullyOpen
                            ? AudienceMobileOpenState.SemiOpen
                            : AudienceMobileOpenState.Closed,
                    isFullscreen: !!fullscreenState,
                },
            },
        });
    };

    return useMemo(() => [openState, toggle], [openState, fullscreenState]);
};

export const useFullScreenState = (): boolean => {
    const {data} = useQuery("LayoutContentDocument");
    const el =
        // @ts-expect-error
        document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;

    const state = data?.content.isFullscreen !== undefined ? data.content.isFullscreen : el;
    return state;
};

export const fullScreenToggle = (event?: Event, mode?: boolean) => {
    const el =
        // @ts-expect-error
        document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;

    const isFullscreen = mode !== undefined ? mode : el ? true : false;

    clientEvents.emit("fullscreen-mode", isFullscreen);

    apollo.client.writeQuery({
        query: LayoutContentDocument,
        data: {
            content: {
                __typename: "Content",
                audienceMobileOpenState: AudienceMobileOpenState.Closed,
                isFullscreen,
            },
        },
    });
};
