import Typography from "@ui/cdk/Typography";
import {useEffect, useState} from "@workhorse/api/rendering";
import {formatTimeLeft} from "@workhorse/pages/home/components/utils";
import {useTranslation} from "react-i18next";

type LobbyStartsInCounterProps = {
    countdownDate: Date;
};

const LobbyStartsInCounter = (props: LobbyStartsInCounterProps) => {
    const {t} = useTranslation();

    const [now, setNow] = useState(new Date());

    const hasStartTimePassed = props.countdownDate ? props.countdownDate < now : false;

    useEffect(() => {
        const countdownTimer = setInterval(() => {
            setNow(new Date());
        }, 30000);

        return () => clearInterval(countdownTimer);
    }, [props.countdownDate]);

    return (
        <Typography data-id="starts-in" className={"ml-20"}>
            {!hasStartTimePassed ? formatTimeLeft(props.countdownDate) : t("lobby.starting_soon")}
        </Typography>
    );
};

export default LobbyStartsInCounter;
