import {SessionLifecycle} from "@generated/data";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useCurrentAgendaItem, useSession} from "@workhorse/providers/SessionDataProviders";

function useSessionInfoStore() {
    const {
        id: sessionId,
        order: currentOrder,
        quickSession: isInstantSession,
        createdAt,
        lifecycle,
        actualStart,
        childOfBreakoutRooms,
        allowCameraType,
        allowMicrophoneType,
        onboardingType,
        transcriptionActive,
        backstage,
    } = useSession();

    const [lastSessionId, setLastSessionId] = useState<string>();

    const sessionIsPublished = !!createdAt;
    const sessionIsStarted = actualStart && lifecycle === SessionLifecycle.Started;
    const parentSessionId = childOfBreakoutRooms?.parentOfBreakoutRooms?.sessionId;

    const {artifact} = useCurrentAgendaItem() ?? {};
    const {id: currentMicroId, artifactId: currentMicroTag = "flowos/conference"} = artifact ?? {};
    const history = useHistory<{breakoutTimeoutDisabled?: boolean; dontUseCachedData?: boolean; fromChild?: boolean} | undefined>();

    const isComingFromChild = history.location.state?.fromChild === true;

    useEffect(() => {
        if (history.location.state && history.location.state.hasOwnProperty("breakoutTimeoutDisabled")) {
            history.location.state.breakoutTimeoutDisabled = false;
        }
    }, [sessionId]);

    useEffect(() => {
        return () => {
            setLastSessionId(sessionId);
        };
    }, [sessionId]);

    return useMemo(
        () => ({
            sessionId,
            currentMicroId,
            currentMicroTag,
            lastSessionId,
            currentOrder,
            isInstantSession,
            sessionIsPublished,
            sessionIsStarted,
            parentSessionId,
            allowCameraType,
            allowMicrophoneType,
            isComingFromChild,
            onboardingType,
            transcriptionActive,
            backstage,
        }),
        [
            sessionId,
            currentMicroId,
            currentMicroTag,
            lastSessionId,
            currentOrder,
            isInstantSession,
            sessionIsPublished,
            sessionIsStarted,
            parentSessionId,
            allowCameraType,
            allowMicrophoneType,
            isComingFromChild,
            onboardingType,
            transcriptionActive,
            backstage,
        ]
    );
}

export const [SessionInfoProvider, useSessionInfo] = createContextProvider(
    {
        name: "SessionInfoProvider",
        strict: true,
    },
    useSessionInfoStore
);
