export const BREAKOUT_ROOMS_INITIAL_CONFIG_PROPERTY_KEY = "InitialBORConfiguration";
export const ALLOW_ROOMS_NAVIGATION_INITIAL_CONFIG_PROPERTY_KEY = "AllowRoomsNavigationInitialConfiguration";

export type BreakoutRoomInitialConfig = {
    id: string;
    name: string;
    description?: string;
    participantIds: string[];
};

export type InitialConfigurationPropertyType = {
    breakoutRooms: BreakoutRoomInitialConfig[];
};

export type AllowRoomsNavigationConfigurationPropertyType = {
    allowRoomNavigation: boolean;
};

export const MAX_NO_ROOMS = 50;
export const MIN_NO_ROOMS = 2;
