import {Fragment, useContext, useEffect, useReducer} from "@workhorse/api/rendering";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";

function PlayerPortal() {
    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set, unset} = useContext(InjectorContext);
    const ids = (Object.keys(existing) as Array<keyof typeof existing>).filter((x) => x !== "classNames");

    useEffect(() => {
        set("inPlayer", inject);
    }, []);

    return (
        <>
            {ids.map((k) => {
                return <Fragment key={k}>{existing[k]}</Fragment>;
            })}
        </>
    );
}

export default PlayerPortal;
