import {createContextProvider} from "@workhorse/api/utils/context";
import {useCameraBackgroundFilterStorage} from "@workhorse/providers/UserDeviceSettingsStorageProvider";

function useVideoTransformStore() {
    const {cameraBackgroundFilter, setCameraBackgroundFilter} = useCameraBackgroundFilterStorage();

    return {
        filter: cameraBackgroundFilter,
        setFilter: setCameraBackgroundFilter,
    };
}

export const [VideoTransformProvider, useVideoTransform] = createContextProvider(
    {
        name: "VideoTransform",
    },
    useVideoTransformStore
);
