import designer from "../..";
import {DesignerAction} from "../../action";
import {CreateAgendaItemInput} from "./create-agenda-item";

export interface UpdateAgendaItemInput {
    id: string;
    agendaItem: Partial<Omit<CreateAgendaItemInput, "artifact">>;
}

export class UpdateAgendaItem extends DesignerAction<UpdateAgendaItemInput> {
    commit() {
        const agendaItems = structuredClone(designer.currentAgendaItems() ?? []);
        const agendaItemIndex = agendaItems.findIndex((a) => a.id === this.input.id);
        if (this.input.agendaItem) {
            // @ts-expect-error
            delete this.input.agendaItem.artifact;
        }
        // console.log("updating with", this.input.agendaItem);
        if (agendaItemIndex !== -1) {
            agendaItems[agendaItemIndex!] = {
                ...agendaItems[agendaItemIndex!],
                ...this.input.agendaItem,
            };

            designer.updateCurrentSession(
                {
                    agendaItems,
                },
                {
                    strategy: "replace",
                }
            );
        }

        return this.input.id;
    }
}
