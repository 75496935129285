import {useEffect} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {RouteHOCProps} from "@workhorse/declarations";

type LoginHOCProps = RouteHOCProps<{ref?: string}>;

const UserLoginSuccess = (props: LoginHOCProps) => {
    useEffect(() => {
        try {
            window.opener["login_popup_callback"]();
        } catch (err) {
            console.error("error in UserLoginSuccess", err);
        }
        window.close();
    }, []);

    return <Loading location="UserLoginSuccess.tsx" />;
};

export default UserLoginSuccess;
