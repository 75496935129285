import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {Utm, UtmSet} from "./types";
import UtmSetEditor from "./utm-set-editor";
import UtmLink from "./utml-link";
import TableBody from "@ui/cdk/Table/TableBody";
import TableCell from "@ui/cdk/Table/TableCell";
import TableHead from "@ui/cdk/Table/TableHead";
import TableRow from "@ui/cdk/Table/TableRow";
import {Table, TableContainer} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";

import classes from "./style/EventAnalytics.module.scss";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {useEffect, useRef, useState, useMemo} from "react";
import EventAnalyticsTableNoResults from "./event-analytics-table-no-results";
import {getCorrectPlural} from "@workhorse/util/strings";
import {SessionUtmAnalytics} from "@common/analytics";
import {deepCompare} from "@workhorse/api/utils/deepCompare";
import toast from "@workhorse/api/toast";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {GetSessionUtmAnalyticsDocument, SessionLifecycle} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type EventAnalyticsnProps = {
    setIsMenuHidden: (isMenuHidden: boolean) => void;
    isMenuHidden: boolean;
};

const generateNewUtmSet = (existingSetNames: string[]): Utm => {
    const setName = "UTM";

    if (!existingSetNames.includes(setName)) {
        return {
            [setName]: {
                utm_campaign: "",
                utm_content: "",
                utm_medium: "",
                utm_source: "",
            },
        };
    }

    let i = 1;
    while (existingSetNames.includes(`${setName} ${i}`)) {
        i++;
    }

    return {
        [`${setName} ${i}`]: {
            utm_campaign: "",
            utm_content: "",
            utm_medium: "",
            utm_source: "",
        },
    };
};

const generateNewUtmSetName = (existingSetNames: string[]): string => {
    const setName = "EXTERNAL";

    if (!existingSetNames.includes(setName)) {
        return setName;
    }

    let i = 1;
    while (existingSetNames.includes(`${setName} ${i}`)) {
        i++;
    }

    return `${setName} ${i}`;
};

const getUtmVisits = (allVisits: SessionUtmAnalytics[], utmSet: UtmSet) => {
    return allVisits.filter((visit) => {
        return (
            (utmSet.utm_source ? visit.utm_source === utmSet.utm_source : true) &&
            (utmSet.utm_medium ? visit.utm_medium === utmSet.utm_medium : true) &&
            (utmSet.utm_campaign ? visit.utm_campaign === utmSet.utm_campaign : true) &&
            (utmSet.utm_content ? visit.utm_content === utmSet.utm_content : true)
        );
    }).length;
};

const getUtmRegistrations = (allRegistrations: SessionUtmAnalytics[], utmSet: UtmSet) => {
    return allRegistrations
        .filter((visit) => !!visit.email)
        .filter((visit) => {
            return (
                (utmSet.utm_source ? visit.utm_source === utmSet.utm_source : true) &&
                (utmSet.utm_medium ? visit.utm_medium === utmSet.utm_medium : true) &&
                (utmSet.utm_campaign ? visit.utm_campaign === utmSet.utm_campaign : true) &&
                (utmSet.utm_content ? visit.utm_content === utmSet.utm_content : true)
            );
        }).length;
};

const EventAnalytics = (props: EventAnalyticsnProps) => {
    const session = useSession();
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () =>
            (session.lifecycle === SessionLifecycle.Started && !session.backstage) ||
            isLocked ||
            session.lifecycle === SessionLifecycle.Ended,
        [session.lifecycle, isLocked, session.backstage]
    );

    const utm = session?.event?.utm as Utm;
    const dialogImperativeRef = useRef<DialogImperativeRef>(null);
    const {mixpanelTrack} = useMixpanelTracker();
    const [utmSet, setUtmSet] = useState<Utm | null>(null);
    const [sessionUtmAnalytics, setSessionUtmAnalytics] = useState<SessionUtmAnalytics[]>([]);

    const onCreate = () => {
        const newSet = generateNewUtmSet(Object.keys(utm ?? {}));

        onOpen(newSet);
        mixpanelTrack("frontend-create-utm-code", "events");
    };

    const utmsToAdd = sessionUtmAnalytics.filter((a) => {
        if (a.utm_source === "Direct") {
            return false;
        }
        const exists = Object.values(utm ?? {}).find((utm) => {
            const toCompare = Object.assign({}, a) as unknown as {[key: string]: string} & {email?: string; timestamp?: string};
            if ("timestamp" in toCompare) {
                delete toCompare.timestamp;
            }
            if ("email" in toCompare) {
                delete toCompare.email;
            }
            Object.keys(toCompare).forEach((key) => {
                if (!toCompare[key]) {
                    delete toCompare[key];
                }
            });
            Object.keys(utm).forEach((key) => {
                if (!utm[key]) {
                    delete utm[key];
                }
            });
            return deepCompare(utm, toCompare);
        });

        if (exists) {
            return false;
        }

        return true;
    });

    const craftedUtm = utmsToAdd.reduce(
        (acc, curr) => {
            const newSetName = generateNewUtmSetName(Object.keys(acc));
            const current = Object.assign({}, curr) as unknown as {[key: string]: string} & {email?: string; timestamp?: string};
            if ("timestamp" in curr) {
                delete current.timestamp;
            }
            if ("email" in curr) {
                delete current.email;
            }
            const exists = Object.values(acc).find((utm) => {
                return deepCompare(utm, current);
            });

            if (!exists) {
                acc[newSetName] = {
                    ...current,
                } as UtmSet;
            }
            return acc;
        },
        {...(utm ?? {})}
    );

    useEffect(() => {
        if (!session) {
            return;
        }
        apollo.client
            .query({
                query: GetSessionUtmAnalyticsDocument,
                variables: {
                    sessionId: session.id,
                },
            })
            .then((queryResponse) => {
                if ((queryResponse.data.getSessionUtmAnalytics as SessionUtmAnalytics[]).length) {
                    const utmAnalytics = queryResponse.data.getSessionUtmAnalytics as SessionUtmAnalytics[];
                    for (const obj of utmAnalytics) {
                        const keys = Object.keys(obj);
                        for (const key of keys) {
                            if (!obj[key]) {
                                delete obj[key];
                            }
                        }
                    }
                    setSessionUtmAnalytics(utmAnalytics);
                }
            });
    }, []);

    const onChange = (utmSetName: string, utmSet: Utm) => {
        const exists = Object.values(craftedUtm ?? {}).find((utm) => {
            return deepCompare(utm, utmSet);
        });
        const notEmpty = Object.values(utmSet).some((v) => !!v);
        if (!notEmpty) {
            toast("This UTM set is empty", {
                type: "error",
            });
            return false;
        }
        if (exists) {
            toast("This UTM set already exists", {
                type: "error",
            });
            return false;
        }

        designer.api.event.update({
            utm: {
                ...(utm ?? {}),
                [utmSetName]: utmSet,
            },
        });
        designer.commit();
        return true;
    };
    const onConfirm = (utmSetName: string, utmSet: Utm) => {
        const changed = onChange(utmSetName, utmSet);
        if (changed) {
            onClose();
        }
    };

    const onRemoveSet = (utmSetName: string) => {
        const newUtm = {...utm};
        delete newUtm[utmSetName];
        designer.api.event.update({
            utm: Object.keys(newUtm).length ? newUtm : null,
        });
        designer.commit();
    };

    const onOpen = (Utm: Utm) => {
        setUtmSet(Utm);
        dialogImperativeRef.current?.toggle?.();
    };

    const onClose = () => {
        dialogImperativeRef.current?.toggle?.();
    };

    const directVisits = sessionUtmAnalytics.filter((a) => a.utm_source === "Direct");

    return (
        <div className="p-32 flex flex-col flex11-100 fullh">
            <div className="flex mb-19 flex-align-center">
                <div className="flex11-100 flex flex-align-center">
                    <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mr-16">
                        Sharing and tracking
                    </Typography>
                    <Typography className={classes.count}>
                        {sessionUtmAnalytics.length} {getCorrectPlural("visit", sessionUtmAnalytics.length)}
                    </Typography>
                </div>
                <Button disabled={eventNotUpdatable} variant="secondary" size="small" onClick={onCreate}>
                    Create UTM code
                </Button>
            </div>
            <TableContainer className={classes.tableContainerRoot}>
                <Table className={classes.tableRoot}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell size="unset">Source</TableCell>
                            <TableCell size="unset">Campaign</TableCell>
                            <TableCell size="unset">Medium</TableCell>
                            <TableCell size="unset">Content</TableCell>
                            <TableCell size="unset">Visits</TableCell>
                            <TableCell size="unset">Registrations</TableCell>

                            <TableCell size="unset"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {directVisits.length ? (
                            <>
                                <TableRow key={"direct"}>
                                    <TableCell ellipsis>
                                        <b>Direct</b>
                                    </TableCell>
                                    <TableCell ellipsis> -</TableCell>
                                    <TableCell ellipsis>-</TableCell>
                                    <TableCell ellipsis>-</TableCell>
                                    <TableCell ellipsis>{directVisits.length}</TableCell>
                                    <TableCell ellipsis>{directVisits.filter((r) => !!r.email).length}</TableCell>

                                    <TableCell key={"direct" + "edit"}>
                                        <div className="flex">
                                            {session.event?.slug ? (
                                                <UtmLink utmSet={{}} slug={session.event?.slug}>
                                                    <Button variant="quaternary">
                                                        <FileCopyOutlinedIcon />
                                                    </Button>
                                                </UtmLink>
                                            ) : null}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : null}

                        {Object.keys(craftedUtm ?? {}).length ? (
                            <>
                                {Object.keys(craftedUtm ?? {})
                                    .sort((a, b) => a.localeCompare(b, undefined, {numeric: true}))
                                    .map((key, index) => {
                                        const visits = getUtmVisits(sessionUtmAnalytics, craftedUtm[key]);
                                        return (
                                            <TableRow key={index}>
                                                <TableCell ellipsis>
                                                    <b>{craftedUtm[key]["utm_source"] || "-"}</b>
                                                </TableCell>
                                                <TableCell ellipsis> {craftedUtm[key]["utm_campaign"] || "-"}</TableCell>
                                                <TableCell ellipsis> {craftedUtm[key]["utm_medium"] || "-"}</TableCell>
                                                <TableCell ellipsis> {craftedUtm[key]["utm_content"] || "-"}</TableCell>
                                                <TableCell ellipsis> {visits}</TableCell>
                                                <TableCell ellipsis> {getUtmRegistrations(sessionUtmAnalytics, craftedUtm[key])}</TableCell>
                                                <TableCell key={index + "edit"}>
                                                    <div className="flex">
                                                        {session.event?.slug ? (
                                                            <UtmLink utmSet={craftedUtm[key]} slug={session.event?.slug}>
                                                                <Button variant="quaternary">
                                                                    <FileCopyOutlinedIcon />
                                                                </Button>
                                                            </UtmLink>
                                                        ) : null}
                                                        {key.includes("EXTERNAL") || visits ? null : (
                                                            <Button
                                                                disabled={eventNotUpdatable}
                                                                withMarginLeft
                                                                variant="quaternary"
                                                                onClick={() => onRemoveSet(key)}
                                                            >
                                                                <DeleteOutlineOutlinedIcon />
                                                            </Button>
                                                        )}

                                                        {key.includes("EXTERNAL") || visits ? null : (
                                                            <Button
                                                                withMarginLeft
                                                                disabled={eventNotUpdatable}
                                                                onClick={() =>
                                                                    onOpen({
                                                                        [key]: utm[key],
                                                                    })
                                                                }
                                                                variant="quaternary"
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </>
                        ) : (
                            <div className={classes.noResults}>
                                <EventAnalyticsTableNoResults onCreate={onCreate} />
                            </div>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog onClose={onClose} imperativeRef={dialogImperativeRef}>
                {utmSet ? (
                    <UtmSetEditor
                        slug={session.event?.slug}
                        onChange={onConfirm}
                        onRemoveSet={onRemoveSet}
                        onCancel={onClose}
                        utm={utmSet}
                    />
                ) : null}
            </Dialog>
        </div>
    );
};

export default EventAnalytics;
