import {makeVar, useReactiveVar} from "@workhorse/api/data";
import {useCallback} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/DesignerBackdrop.module.scss";

export const showBackdrop = makeVar(false);

export const DesignerBackdrop = () => {
    const show = useReactiveVar(showBackdrop);

    const handleClick = useCallback(() => {
        showBackdrop(false);
    }, []);

    return <div className={cls(classes.root, show && classes.show)} onClick={handleClick}></div>;
};
