import {SessionParticipantFragmentDoc} from "@generated/data";
import Avatar from "@ui/cdk/Avatar";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {PreviewVideo, useCameraSettings} from "@workhorse/api/conference2";
import {useDevices} from "@workhorse/api/conference2/providers/MediaProvider/DevicesProvider";
import {Fragment} from "@workhorse/api/rendering";
import {getColor, makeInitials} from "@workhorse/util";
import {resizeGooglePicture} from "@workhorse/util/googleProfile";
import {DeviceChecks} from "../../components/DeviceChecks";
import AnimatedMicrophoneIcon from "../../session-view-components/session-audience/audience-participant-components/AnimatedMicrophoneIcon";
import {usePreJoinSettings} from "../providers/PreJoinSettingsProvider";
import classes from "./styles/PreJoinConferenceVideo.module.scss";
import {brandingVideoTileColor} from "@workhorse/pages/user/profile/Theming/utils";
import {useReactiveVar} from "@apollo/client";
import {customFirstLastName, defaultVideoTileBackgroundColor} from "@common/utils";
import videoStyles from "../../../../api/session-settings/sections/VideoSection/styles/VideoSection.module.scss";
import {useTranslation} from "react-i18next";

function ParticipantAvatar({enabled, participantId}: {enabled: boolean; participantId: string}) {
    if (!enabled) {
        return null;
    }

    const participant = apollo.cache.readFragment({
        fragment: SessionParticipantFragmentDoc,
        id: apollo.cache.identify({__ref: participantId}),
        fragmentName: "SessionParticipant",
    });

    if (!participant) {
        return null;
    }

    const {firstName, lastName, avatar} = participant.dataWithNullableEmail || {};

    const {customFirstName, customLastName} = customFirstLastName(firstName, lastName);

    if (avatar) {
        return (
            <div key="pre-join-conference-video-avatar" className={classes.avatarCircle}>
                <img
                    src={resizeGooglePicture(avatar, 720)}
                    data-id="conference-avatar"
                    className={classes.avatar}
                    key="pre-join-conference-avatar-avatar"
                />
            </div>
        );
    }

    const initials = makeInitials(customFirstName, customLastName);
    const hash = getColor(initials);

    return (
        <div key="pre-join-conference-video-avatar" className={classes.avatarCircle}>
            <img className="fullw fullh" style={{background: hash}} key="pre-join-conference-video-avatar-bg" />
            <Avatar size="XL3" className={classes.initials} backgroundColor={hash} key="pre-join-conference-video-avatar-avatar">
                {initials}
            </Avatar>
        </div>
    );
}

function ParticipantVideo({stream}: {stream: MediaStream | null}) {
    const {cameraMirrorSelfView} = useCameraSettings();

    if (!stream) {
        return null;
    }

    return (
        <PreviewVideo
            data-id="conference-video"
            className={cls("fullw", classes.webcamStream, cameraMirrorSelfView && "inverted")}
            stream={stream}
        />
    );
}

type PreJoinConferenceVideoProps = {
    participantId: string;
    audioPublishingIsDisabled?: boolean;
    videoPublishingIsDisabled?: boolean;
};

const PreJoinConferenceVideo = (props: PreJoinConferenceVideoProps) => {
    const {t} = useTranslation();
    const {audioMuted, videoStream, videoTransformedStream} = useDevices();
    const {setMenuOpen} = usePreJoinSettings();
    const videoTileColor = useReactiveVar(brandingVideoTileColor);
    const {audioPublishingIsDisabled, videoPublishingIsDisabled} = props;

    return (
        <div
            data-id="conference-video-or-avatar"
            className={cls("wrapper169", classes.root)}
            style={{
                backgroundColor: videoTileColor ? videoTileColor : defaultVideoTileBackgroundColor,
            }}
        >
            <div className="inner">
                <DeviceChecks openAudioSettings={() => setMenuOpen(true)} />
                <ParticipantAvatar enabled={!videoStream} participantId={props.participantId} />
                <ParticipantVideo stream={videoTransformedStream ?? videoStream} />

                {audioPublishingIsDisabled || videoPublishingIsDisabled ? (
                    <div className={videoStyles.notification}>
                        {audioPublishingIsDisabled && videoPublishingIsDisabled
                            ? t("participant.status.audio_video_disabled_by_host_extended")
                            : audioPublishingIsDisabled
                            ? t("participant.status.audio_disabled_by_host")
                            : t("participant.status.video_disabled_by_host")}
                    </div>
                ) : null}

                <div className={classes.statusContainer}>
                    {!audioMuted && <AnimatedMicrophoneIcon localAudio={true} isMuted={audioMuted} />}
                </div>
            </div>
        </div>
    );
};

export default PreJoinConferenceVideo;
