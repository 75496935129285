import {useLocation} from "../routing";

type FunctionalityToRemove = "conference";
type UiToRemove =
    | "left_drawer"
    | "right_drawer"
    | "header"
    | "footer"
    | "sub_header"
    | "lazy_dialogs"
    | "aria_anouncer"
    | "recording_consent"
    | "micro_margins"
    | "notifications";

type EmbedMode = "mobile" | "web" | undefined;

export type EmbedQueryParams = {
    functionality: FunctionalityToRemove[];
    ui: UiToRemove[];
};

export const useEmbedSessions = () => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const uiElementsToRemove: EmbedQueryParams["ui"] = params.get("ui")?.split(",") as unknown as UiToRemove[];
    const functionalitiesToRemove: EmbedQueryParams["functionality"] = params
        .get("functionality")
        ?.split(",") as unknown as FunctionalityToRemove[];

    const embedMode = params.get("embed_mode") as EmbedMode;

    return {
        uiElementsToRemove: uiElementsToRemove ?? [],
        functionalitiesToRemove: functionalitiesToRemove ?? [],
        embedMode: embedMode,
    };
};
