import roleBindings from "@generated/artifacts/role-bindings";
import {LocalAgendaItemQuery} from "@generated/data";
import {ParticipantRoleBasedAccessControl} from "@sessions/common/various";
import {Flatten} from "@ui/Forms/types";
import {readFragment, useQuery} from "@workhorse/dataApi";
import {ArrayItem} from "@workhorse/declarations";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import {capitalize} from "@workhorse/util";
import {useMemo} from "../rendering";

export function useLocalUser() {
    const {data} = useQuery("LocalUserDocument");
    return data;
}

type ParticipantRoles = {
    session: {
        isAssistant?: boolean;
    };
};

type ArtifactRoles = {
    agendaItems: {
        edit: [];
    };
};

export type SessionRole = keyof Flatten<ParticipantRoles>;
export type ArtifactRole = keyof Flatten<ArtifactRoles>;
interface RolePieces<T extends keyof ParticipantRoleBasedAccessControl = keyof ParticipantRoleBasedAccessControl>
    extends Array<T | keyof ParticipantRoleBasedAccessControl[T]> {
    0: T;
    1: keyof ParticipantRoleBasedAccessControl[T];
}

export function rbac(
    participantIdOrObj: (Pick<Participant, "rbac"> & {id?: string}) | string | null | undefined,
    role: SessionRole | ArtifactRole,
    agendaItem?: LocalAgendaItemQuery["agendaItems"][0]
) {
    if (!participantIdOrObj) {
        return false;
    }
    const participant =
        typeof participantIdOrObj === "string"
            ? readFragment({
                  fragment: "ParticipantRbacFragmentDoc",
                  id: participantIdOrObj,
              })
            : participantIdOrObj;

    const [scope, subScope] = role.split(".") as RolePieces;

    const isSessionRole = scope === "session";
    const isAgendaItemRole = scope === "agendaItems";

    // const isArtifactRole = (["artifact.edit"] as ArtifactRole[]).indexOf(role as ArtifactRole) !== -1;

    const sessionRbac = ((participant?.rbac as ParticipantRoleBasedAccessControl) || {}).session || {};

    // expecting error here, because the artifact property is just a maybe and speaks to how this should look in the future
    // subject to change, not used, needed for evaluation
    // @ts-expect-error
    const artifactRbac = ((participant?.rbac as ParticipantRoleBasedAccessControl) || {}).artifact || {};

    if (isAgendaItemRole && agendaItem) {
        const id = typeof participantIdOrObj === "string" ? participantIdOrObj : participantIdOrObj?.id;
        if (!id) {
            console.log("no id");
            return false;
        }
        const fullParticipant = readFragment({
            fragment: "ParticipantSpeakerDetailsFragmentDoc",
            id,
        });

        const speakers = agendaItem?.agendaItemSpeakers;
        const hasAccess = speakers?.find((s) => s.speakerId === fullParticipant?.speakerDetails?.id);

        return !!hasAccess;
    }

    const val = (isSessionRole ? sessionRbac[subScope] : artifactRbac[subScope]) ? true : false;
    // if (isSessionRole && subScope === "isAssistant") {
    //     singleUseCaseIsAssistant(val);
    // }
    return val;
}

const assistantActions = {
    session: {
        delete: false,
        edit: false,
        muteEveryone: true,
        start: true,
        endForAll: true,
        artifactNav: true,
        acceptJoinReq: true,
    },
    participants: {
        seeEmails: true,
        invite: true,
        mute: true,
        makeSpeaker: true,
        handDown: true,
        acceptJoin: true,
        askToMute: true,
        askToUnmute: true,
        askToBeSpeaker: true,
        kick: true,
        makeAssistant: true,
        // TODO @Vasi
        // adminActions is used on the widget in the audience
        // and practically includes all actions the owner can take on a participant
        // determine if we can use this, or use individual roles above
        adminActions: true,
    },
    artifacts: roleBindings,
    self: {
        raiseHand: false,
    },
};

type AssistantAction = keyof Flatten<typeof assistantActions>;

type AssistantActionAllowedFunc<T> = (action: T) => {
    [K in ArrayItem<T> extends `${string}/${string}.${infer U}`
        ? `can${Capitalize<U>}`
        : ArrayItem<T> extends `${infer U}.${infer UU}`
        ? `can${Capitalize<U>}${Capitalize<UU>}`
        : string]: boolean;
};

export const assistantActionAllowed = <
    T extends AssistantAction[] = AssistantAction[],
    F extends AssistantActionAllowedFunc<T> = AssistantActionAllowedFunc<T>
>(
    action: T
): ReturnType<F> => {
    return action.reduce((m, n) => {
        if (!!!n) {
            return m;
        }
        const key =
            "can" +
            (n.indexOf("/") > -1 ? n.split("/")[1].split(".")[1] : n)
                .split(".")
                .map((x) => capitalize(x))
                .join("");
        const allowed = actionAllowed(n);
        return {
            ...m,
            [key]: allowed,
        };
    }, {}) as unknown as ReturnType<F>;
};

const actionAllowed = (action: AssistantAction) => {
    const x = (action || "").split(".");
    let y = assistantActions[x[0]];
    if (!y) {
        return false;
    }
    let i = 1;
    while (i < x.length) {
        if (!y.hasOwnProperty(x[i])) {
            y = false;
            break;
        }
        y = y[x[i]];
        i++;
    }
    return y as boolean;
};
