import LinearProgress from "@ui/cdk/LinearProgress";
import {cls} from "@ui/cdk/util/util";
import {useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {useFullScreenState} from "@workhorse/providers/state";
import classes from "./styles/AgendaTimeLeftProgress.module.scss";
import {artifactApi} from "@generated/artifacts";
import {WithMobileState} from "@workhorse/declarations";
import {isAgendaPausedVar} from "./AgendaNavigation";
import {useReactiveVar} from "@apollo/client";
import {LinearProgressColorTypes} from "@ui/cdk/LinearProgress/types";
const {formatTimeAsc, timePercentagePassed} = artifactApi("flowos/agenda");

interface AgendaTimeLeftProgressProps extends WithMobileState {
    inHeader?: boolean;
    actualStart: string | Date;
    plannedStart: string | Date;
    plannedEnd: Date | number;
    className?: string;
    inFooter?: boolean;
    withBullet?: boolean;
    color?: LinearProgressColorTypes;
    isOnAgendaToggler?: boolean;
    isAgendaPaused?: boolean;
}

function AgendaTimeLeftProgress(props: AgendaTimeLeftProgressProps) {
    const {
        inHeader,
        plannedStart,
        actualStart,
        plannedEnd,
        mobileState,
        className,
        inFooter,
        withBullet,
        color,
        isOnAgendaToggler,
        isAgendaPaused,
    } = props;

    const sessionActualStart = new Date(actualStart);
    // TODO @Vasi
    // add plannedEnd on welcomeSessions
    const plannedDuration = new Date(plannedEnd).getTime() - new Date(plannedStart).getTime();
    const makePct = useCallback(
        () => timePercentagePassed(sessionActualStart, new Date(sessionActualStart.getTime() + plannedDuration)),
        [actualStart, plannedEnd, plannedStart, isAgendaPaused, isAgendaPaused]
    );

    const [pct, setPct] = useState(() => makePct());

    const {isMobile} = mobileState;
    const isFullScreen = useFullScreenState();

    useEffect(() => {
        const newPct = makePct();

        if (newPct !== pct) {
            setPct(newPct);
        }

        const t = setInterval(
            () => {
                setPct((currentPct) => {
                    const pctNew = makePct();

                    if (isAgendaPaused) {
                        return currentPct;
                    }

                    if (pctNew !== currentPct) {
                        return pctNew;
                    }

                    return currentPct;
                });
                // execute the interval every 10 sec if plannedDuration > 5 min
                // if < 5 min, every second
            },
            plannedDuration > 5 * 60000 ? 10000 : 1000
        );

        return () => {
            clearInterval(t);
        };
    }, [actualStart, plannedEnd, plannedStart, isAgendaPaused]);

    const hideAgenda = isMobile && isFullScreen;

    return hideAgenda ? null : (
        <LinearProgress
            color={color}
            className={cls(className ? className : classes.progress, inHeader && classes.inHeader)}
            value={pct}
            variant="determinate"
            progressHeight={inFooter ? 8 : 43}
            withBullet={withBullet}
            isOnAgendaToggler={isOnAgendaToggler}
        />
    );
}

export default AgendaTimeLeftProgress;
