import {useState} from "react";
import env from "@generated/environment";
import {AuthService} from "@workhorse/api/authService";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";

const UserHackLogin = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const loginStyle = {
        margin: "auto",
        minHeight: "300px",
        border: "1px solid #eee",
        padding: "20px",
        borderRadius: "12px",
        boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.05)",
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (ev) => {
        ev.preventDefault();

        const response = await fetch(`${env.clientWriteUrl.replace("graphql", "")}api/auth/login`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                ip: "127.0.0.1",
            }),
        });

        if (response.status === 200) {
            const result = await response.json();

            if (env.environmentName === "development") {
                document.cookie = `${env.authService.cookieName}=${result.token}; path=/`;
            } else {
                const path = window.location.hostname.replace("primary.", "").replace("secondary.", "");
                document.cookie = `${env.authService.cookieName}=${result.token}; path=/; domain=${path}`;
            }
            const url = new URL(window.location.href);
            const returnTo = url.searchParams.get("returnTo");
            window.location.href = returnTo ?? "/";
        } else {
            const result = await response.json();
            if (result.error && result.error.message) {
                alert(result.error.message);
            }
        }
    };

    const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
        setEmail(ev.target.value);
    };

    const onChangePassword: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
        setPassword(ev.target.value);
    };

    const onClickLoginPopup: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
        ev.preventDefault();

        AuthService.getInstance().loginWithPopup("email");
    };

    const onClickRegister: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
        ev.preventDefault();

        window.location.pathname = "/user/hack/register";
    };

    return (
        <div id="user-hack-login" style={loginStyle}>
            <form action="#" onSubmit={handleSubmit}>
                <div>
                    <h1>Login</h1>
                </div>
                <div className="flex flex-align-center mb-10">
                    <label id="email-label" htmlFor="email" className="mr-6">
                        Email:
                    </label>
                    <Input id="email" type="text" value={email} onChange={onChangeEmail} />
                </div>
                <div className="flex flex-align-center mb-24">
                    <label id="password-label" htmlFor="password" className="mr-6">
                        Password:
                    </label>
                    <Input id="password" type="password" value={password} onChange={onChangePassword} />
                </div>
                <Button className="mx-auto px-36" type="submit">
                    Submit
                </Button>
            </form>
            <div id="login-other-buttons" className={"flex mt-36 flex-justify-center"}>
                <Button onClick={onClickLoginPopup} className="mr-12" variant="plain">
                    Login with popup
                </Button>
                <Button onClick={onClickRegister} variant="plain">
                    Go to register
                </Button>
            </div>
        </div>
    );
};

export default UserHackLogin;
