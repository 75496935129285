import Typography from "@ui/cdk/Typography";
import classes from "./styles/ParticipantsContainer.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {useState} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {rbac} from "@workhorse/api/user";

type ParticipantsBackstageWarningProps = {
    sessionId: string;
};

const ParticipantsBackstageWarning = (props: ParticipantsBackstageWarningProps) => {
    const {t} = useTranslation();
    const key = `backstage-warning`;
    const hasSeenWarning = localStorage.getItem(key) === props.sessionId;

    const participant = useCurrentParticipant();
    const isAssistant = rbac(participant, "session.isAssistant");
    const isOwner = participant.isOwner;

    const [open, setOpen] = useState(!hasSeenWarning);

    if (!open) {
        return null;
    }

    if (!(isAssistant || isOwner)) {
        return null;
    }

    const handleClose = () => {
        localStorage.setItem(key, props.sessionId);
        setOpen(false);
    };

    return (
        <div className={classes.backstageWarningRoot}>
            <CloseRoundedIcon className={classes.closeButton} onClick={handleClose} />
            <Typography color="secondary" variant="lg" fontWeight="bolder" className="mb-6">
                {t("g.backstage")}
            </Typography>
            <Typography color="secondary">{t("macro.participants.backstage_warning")}</Typography>
        </div>
    );
};

export default ParticipantsBackstageWarning;
