import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {MutableRefObject} from "@workhorse/api/rendering";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {ReactComponent as BulletListIcon} from "../../../assets/media/bullet-list.svg";
import {ReactComponent as ToolsIcon} from "../../../assets/media/navbar-left-icons/tools.svg";
import classes from "./styles/OnboardingNewUserDialog.module.scss";

type OnboardingNewUserDialogProps = {
    imperativeRef: MutableRefObject<DialogImperativeRef>;
    onClose: () => void;
    onEndSession: (e) => Promise<void>;
};

const cardsList = [
    {
        id: "share-a-file",
        title: "Share a file",
        description: "You can bring many different file types directly into your sessions.",
        icon: <ToolsIcon />,
        color: "green",
    },
    {
        id: "open-a-link",
        title: "Open a link",
        description: "It’s as easy as copy-and-paste to open a link and share it with everyone in your meeting.",
        icon: <BulletListIcon />,
        color: "yellow",
    },
    {
        id: "embed-a-tool",
        title: "Embed a tool",
        description: "There are many tools and apps that work in Sessions, plus you can collaborate with others in real-time. ",
        icon: <ToolsIcon />,
        color: "purple",
    },
];

const OnboardingNewUserLeaveDialog = (props: OnboardingNewUserDialogProps) => {
    const {completedFlows} = useOnboardingSession();

    const handleContinue = () => {
        props.onClose();
    };

    const handleEndSession = (e) => {
        props.onEndSession(e);
    };

    const hasFinishedAllFlows = completedFlows.length >= 4;
    const hasFinishedNoFlows = completedFlows.length === 0;

    const isFlowProgressText = hasFinishedAllFlows
        ? "finished-all-flows"
        : hasFinishedNoFlows
        ? "finished-no-flows"
        : "finished-some-flows";

    return (
        <Dialog
            imperativeRef={props.imperativeRef}
            classes={{paper: cls(classes.paper, classes.endSessionDialog), root: classes.root}}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={cls("fullw", classes.content)}>
                <div className={cls("fullw", classes.container)}>
                    <div className="mb-30 ">
                        <Typography className={cls("mb-16", classes.titleOnboarding)}>
                            {hasFinishedAllFlows ? "You did it!" : "Sure you want to leave?"}
                        </Typography>
                        <Typography color="blueGray400" fontWeight="bold">
                            {hasFinishedAllFlows
                                ? "You just finished your first session and learned how to:"
                                : "We were still hoping to show you how to:"}
                        </Typography>
                    </div>
                    <div className="mb-24 fullw flex flex-col">
                        {cardsList.map((card, index) =>
                            hasFinishedAllFlows || (!hasFinishedAllFlows && !completedFlows.includes(card.id)) ? (
                                <div className={cls("flex flex-row items-center", classes.card, card.color)} key={index}>
                                    <div className={classes.cardIcon}>{card.icon}</div>

                                    <div className="flex flex-col">
                                        <Typography className="mb-4" variant="lg">
                                            {card.title}
                                        </Typography>
                                        <Typography className={classes.description} color="secondary">
                                            {card.description}
                                        </Typography>
                                    </div>

                                    {hasFinishedAllFlows ? <CheckCircleRoundedIcon /> : null}
                                </div>
                            ) : null
                        )}
                    </div>
                    <div className="fullw flex">
                        <Button
                            onClick={handleContinue}
                            variant={hasFinishedAllFlows ? "quaternary" : "secondary"}
                            className="flex11-auto mr-12"
                            id={`onboarding-leave-session-continue-exploring-${isFlowProgressText}`}
                        >
                            Continue exploring
                        </Button>
                        <Button
                            onClick={handleEndSession}
                            className="flex11-auto"
                            variant={hasFinishedAllFlows ? "primary" : "destructive-secondary"}
                            id={`onboarding-leave-session-end-session-${isFlowProgressText}`}
                        >
                            End first session
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default OnboardingNewUserLeaveDialog;
