import React from "react";
import IconButton from "@ui/core/components/IconButton";
import SkipPreviousRoundedIcon from "@material-ui/icons/SkipPreviousRounded";
import SkipNextRoundedIcon from "@material-ui/icons/SkipNextRounded";
import {setPidThatChangedArtifact} from "@workhorse/pages/player/playerStateVars";
import {orchestrator} from "@workhorse/providers/state";
import {SessionData, AgendaItem} from "@workhorse/declarations/dataTypes";
import {useBreakoutInfo} from "@artifacts/breakout/providers/BreakoutInfoProvider";
import classes from "../style/AgendaItemsControls.module.scss";
import {cls} from "@ui/cdk/util/util";
import Tooltip from "@ui/cdk/Tooltip";
import PauseRoundedIcon from "@material-ui/icons/PauseRounded";
import PlayArrowRounded from "@material-ui/icons/PlayArrowRounded";

type AgendaItemsControlsProps = {
    agendaItems: AgendaItem[];
    session: SessionData;
    sessionOrder: number;

    currentAgendaItem: AgendaItem;
};
const AgendaItemsControls = (props: AgendaItemsControlsProps) => {
    const {agendaItems, sessionOrder, session, currentAgendaItem} = props;

    const {roomsHaveParticipants} = useBreakoutInfo();
    const {currentParticipant} = session;
    const {pid: myParticipantId} = currentParticipant || {pid: ""};
    const sessionId = session?.id;

    const makeOrder: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        const goto = e.currentTarget.dataset.target as "next" | "prev";
        const agendaItemsSorted = agendaItems
            .slice(0)
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((a) => a.order);
        const remaining = agendaItemsSorted.filter((i) => (goto === "next" ? i > sessionOrder : i < sessionOrder));
        const gotoOrder = goto === "next" ? remaining[0] : remaining[remaining.length - 1];

        if (gotoOrder === null || gotoOrder === undefined || gotoOrder === -1) {
            return;
        }
        setPidThatChangedArtifact(myParticipantId);

        orchestrator.gotoIndex(sessionId, gotoOrder, !roomsHaveParticipants);

        e.stopPropagation();
    };

    const maxOrder = Math.max(...agendaItems.map((a) => a.order));
    const minOrder = Math.min(...agendaItems.map((a) => a.order));

    const prevButtonDisabled = sessionOrder === minOrder ? true : false;
    const nextButtonDisabled = sessionOrder === maxOrder ? true : false;

    const handlePauseAgenda = () => {
        orchestrator.pauseAgendaItem(session.id);
    };

    return (
        <div>
            <IconButton
                data-id="previous-artifact-button"
                disabled={prevButtonDisabled}
                onClick={makeOrder}
                className={classes.button}
                data-target="prev"
            >
                <SkipPreviousRoundedIcon className={cls(prevButtonDisabled ? classes.disabledButtonIcon : classes.buttonIcon)} />
            </IconButton>
            <IconButton className={classes.button} onClick={handlePauseAgenda}>
                {currentAgendaItem.isPaused ? (
                    <PlayArrowRounded className={classes.buttonIcon} />
                ) : (
                    <PauseRoundedIcon className={classes.buttonIcon} />
                )}
            </IconButton>
            <IconButton
                data-id="next-artifact-button"
                disabled={nextButtonDisabled}
                onClick={makeOrder}
                className={classes.button}
                data-target="next"
            >
                <SkipNextRoundedIcon className={cls(nextButtonDisabled ? classes.disabledButtonIcon : classes.buttonIcon)} />
            </IconButton>
        </div>
    );
};

export default AgendaItemsControls;
