// if(process.env.REACT_APP_ALIGN_ENV !== "false") {

//     const CLIENT_PREFIX = /^UI_/i;

//     process.env = {
//         ...process.env,
//         ...Object.keys(process.env)
//             .filter((key) => CLIENT_PREFIX.test(key))
//             .reduce((env, key) => {
//                 const craKey = key.replace(CLIENT_PREFIX, "REACT_APP_");
//                 env[craKey] = process.env[key];
//                 return env;
//             }, {}),
//     };
// }

function getEnvVar(name: string, defaultValue?: string): string {
    const val = process.env[name];
    if(!!val && val.toString().trim().length > 0) return val;
    if(!!defaultValue) return defaultValue;
    return "";
}

function getEnvVarWithDefault(name: string, defaultValue?: string): string {
    return getEnvVar(name, defaultValue)!!;
}

class SystemEnv {

    get environment() {
        return getEnvVarWithDefault("NODE_ENV", `dev`);
    }


    get playground() {
        return getEnvVarWithDefault("ENABLE_PLAYGROUND", `false`);
    }


    get databaseUrl() {
        return getEnvVar("DATABASE_URL");
    }


    get redisHost() {
        return getEnvVar("REDIS_HOST");
    }


    get redisPort() {
        return getEnvVarWithDefault("REDIS_PORT", `6379`);
    }


    get s3FsUri() {
        return getEnvVar("S3_FS_URI");
    }


    get s3PublicBucket() {
        return getEnvVar("S3_PUBLIC_BUCKET");
    }


    get s3PrivateBucket() {
        return getEnvVar("S3_PRIVATE_BUCKET");
    }


    get logstashHost() {
        return getEnvVar("LOGSTASH_HOST");
    }


    get logstashPort() {
        return getEnvVar("LOGSTASH_PORT");
    }


    get notificationServiceUrl() {
        return getEnvVar("NOTIFICATION_SERVICE_URL");
    }


    get centrifugalApiKey() {
        return getEnvVar("CENTRIFUGAL_API_KEY");
    }

    get mailServiceUrl() {
        return getEnvVar("MAIL_SERVICE_URL");
    }


    get defaultMailProvider() {
        return getEnvVar("DEFAULT_MAIL_PROVIDER");
    }


    get chimeApiKey() {
        return getEnvVar("CHIME_API_KEY");
    }


    get chimeApiSecret() {
        return getEnvVar("CHIME_API_SECRET");
    }


    get forceDevPlayerAccess() {
        return getEnvVar("FORCE_DEV_PLAYER_ACCESS");
    }


    get disableStartupTimer() {
        return getEnvVar("DISABLE_STARTUP_TIMER");
    }


    get googleClientSecret() {
        return getEnvVar("GOOGLE_CLIENT_SECRET");
    }


    get gotenbergUrl() {
        return getEnvVar("GOTENBERG_URL");
    }


    get authServiceJwtSigningSecret() {
        return getEnvVar("AUTH_SERVICE_JWT_SIGNING_SECRET");
    }


    get authServiceApiSecretKey() {
        return getEnvVar("AUTH_SERVICE_API_SECRET_KEY");
    }


    get authServiceEnableRegistration() {
        return getEnvVar("AUTH_SERVICE_ENABLE_REGISTRATION");
    }


    get authServiceVerifyEmail() {
        return getEnvVar("AUTH_SERVICE_VERIFY_EMAIL");
    }


    get hubspotAccessToken() {
        return getEnvVar("HUBSPOT_ACCESS_TOKEN");
    }


    get elasticCloudId() {
        return getEnvVar("ELASTIC_CLOUD_ID");
    }


    get elasticCloudSecret() {
        return getEnvVar("ELASTIC_CLOUD_SECRET");
    }


    get analyticsIndexName() {
        return getEnvVar("ANALYTICS_INDEX_NAME");
    }


    get jwtSecret() {
        return getEnvVar("JWT_SECRET");
    }


    get productboardApiToken() {
        return getEnvVar("PRODUCTBOARD_API_TOKEN");
    }


    get slackUserJoinedUrl() {
        return getEnvVar("SLACK_USER_JOINED_URL");
    }


    get slackAppName() {
        return getEnvVar("SLACK_APP_NAME");
    }


    get slackAppIcon() {
        return getEnvVar("SLACK_APP_ICON");
    }


    get giphyApiSecret() {
        return getEnvVar("GIPHY_API_SECRET");
    }


    get maximumUserConcurrentVideos() {
        return getEnvVar("MAXIMUM_USER_CONCURRENT_VIDEOS");
    }


    get miroClientSecret() {
        return getEnvVar("MIRO_CLIENT_SECRET");
    }


    get applicationStateWriteSecret() {
        return getEnvVar("APPLICATION_STATE_WRITE_SECRET");
    }


    get twitchClientSecret() {
        return getEnvVar("TWITCH_CLIENT_SECRET");
    }


    get canvaExtensionClientSecret() {
        return getEnvVar("CANVA_EXTENSION_CLIENT_SECRET");
    }


    get sessionsFlowosMail() {
        return getEnvVar("SESSIONS_FLOWOS_MAIL");
    }


    get youtubeApiKey() {
        return getEnvVar("YOUTUBE_API_KEY");
    }


    get microsoftClientSecret() {
        return getEnvVar("MICROSOFT_CLIENT_SECRET");
    }


    get unsplashApiKey() {
        return getEnvVar("UNSPLASH_API_KEY");
    }


    get convertApiSecret() {
        return getEnvVarWithDefault("CONVERT_API_SECRET", `zBz9FvMmZ9Cc3k5m`);
    }


    get uipathEventsServiceUrl() {
        return getEnvVarWithDefault("UIPATH_EVENTS_SERVICE_URL", `https://uipath-events.dev.sessions.flowos.com`);
    }


    get uipathEventsServiceSecret() {
        return getEnvVarWithDefault("UIPATH_EVENTS_SERVICE_SECRET", `ilovecats`);
    }


    get guestTokenDuration() {
        return getEnvVarWithDefault("GUEST_TOKEN_DURATION", `1440`);
    }


    get evernoteConsumerKey() {
        return getEnvVarWithDefault("EVERNOTE_CONSUMER_KEY", `testintegrations`);
    }


    get evernoteConsumerSecret() {
        return getEnvVarWithDefault("EVERNOTE_CONSUMER_SECRET", `90baf1d924d13d6c`);
    }


    get notionClientSecret() {
        return getEnvVarWithDefault("NOTION_CLIENT_SECRET", `secret_hUtr5kwHFamrUjXSBaUgXpJaMUWJ3ImHMLvl1D7Sp79`);
    }


    get pspdfkitPrivateKey() {
        return getEnvVarWithDefault("PSPDFKIT_PRIVATE_KEY", `wrong-key`);
    }


    get pspdfkitApiKey() {
        return getEnvVarWithDefault("PSPDFKIT_API_KEY", `e5208eb754ccbd84b98efeabac5376b85cc7e3ad`);
    }


    get deleteContactsWorkerDelayInMs() {
        return getEnvVarWithDefault("DELETE_CONTACTS_WORKER_DELAY_IN_MS", `86400000`);
    }


    get deleteContactsWorkerDaysLimit() {
        return getEnvVarWithDefault("DELETE_CONTACTS_WORKER_DAYS_LIMIT", `30`);
    }


    get googleContactsSyncWorkerDelayInMs() {
        return getEnvVarWithDefault("GOOGLE_CONTACTS_SYNC_WORKER_DELAY_IN_MS", `21600000`);
    }


    get microsoftContactsSyncWorkerDelayInMs() {
        return getEnvVarWithDefault("MICROSOFT_CONTACTS_SYNC_WORKER_DELAY_IN_MS", `21600000`);
    }


    get uipathEmailDomain() {
        return getEnvVar("UIPATH_EMAIL_DOMAIN");
    }


    get createOrganizationAdminWorkerDelayInMs() {
        return getEnvVarWithDefault("CREATE_ORGANIZATION_ADMIN_WORKER_DELAY_IN_MS", `300000`);
    }


    get googleDriveWatchChannelExpirationInDays() {
        return getEnvVarWithDefault("GOOGLE_DRIVE_WATCH_CHANNEL_EXPIRATION_IN_DAYS", `7`);
    }


    get googleDriveWatchChannelUpdateDelayInMs() {
        return getEnvVarWithDefault("GOOGLE_DRIVE_WATCH_CHANNEL_UPDATE_DELAY_IN_MS", `172800000`);
    }


    get googleProjectId() {
        return getEnvVar("GOOGLE_PROJECT_ID");
    }


    get transcoderLocation() {
        return getEnvVar("TRANSCODER_LOCATION");
    }


    get transcoderVideoTopicName() {
        return getEnvVar("TRANSCODER_VIDEO_TOPIC_NAME");
    }


    get transcoderRecordingTopicName() {
        return getEnvVar("TRANSCODER_RECORDING_TOPIC_NAME");
    }


    get integrationServiceUrl() {
        return getEnvVar("INTEGRATION_SERVICE_URL");
    }


    get openaiApiKey() {
        return getEnvVar("OPENAI_API_KEY");
    }


    get openaiApiKey2() {
        return getEnvVar("OPENAI_API_KEY2");
    }


    get transVersion() {
        return getEnvVar("TRANS_VERSION");
    }

}

class PublicSystemEnv {

    get enableLogrocket() {
        return getEnvVarWithDefault("REACT_APP_ENABLE_LOGROCKET", `false`);
    }


    get logrocketProject() {
        return getEnvVarWithDefault("REACT_APP_LOGROCKET_PROJECT", `sessions`);
    }


    get alignEnv() {
        return getEnvVar("REACT_APP_ALIGN_ENV");
    }


    get clientReadUrl() {
        return getEnvVar("REACT_APP_CLIENT_READ_URL");
    }


    get clientWriteUrl() {
        return getEnvVar("REACT_APP_CLIENT_WRITE_URL");
    }


    get clientWsUrl() {
        return getEnvVar("REACT_APP_CLIENT_WS_URL");
    }


    get hostType() {
        return getEnvVar("REACT_APP_HOST_TYPE");
    }


    get staticServiceUrl() {
        return getEnvVar("REACT_APP_STATIC_SERVICE_URL");
    }


    get notificationAppId() {
        return getEnvVar("REACT_APP_NOTIFICATION_APP_ID");
    }


    get centrifugalUrl() {
        return getEnvVar("REACT_APP_CENTRIFUGAL_URL");
    }


    get landingPage() {
        return getEnvVar("REACT_APP_LANDING_PAGE");
    }


    get googleClientId() {
        return getEnvVar("REACT_APP_GOOGLE_CLIENT_ID");
    }


    get drawingServiceUrl() {
        return getEnvVar("REACT_APP_DRAWING_SERVICE_URL");
    }


    get whiteboardSyncServerUrl() {
        return getEnvVar("REACT_APP_WHITEBOARD_SYNC_SERVER_URL");
    }


    get gaMeasurementId() {
        return getEnvVar("REACT_APP_GA_MEASUREMENT_ID");
    }


    get innrId() {
        return getEnvVar("REACT_APP_INNR_ID");
    }


    get mixpanelToken() {
        return getEnvVar("REACT_APP_MIXPANEL_TOKEN");
    }


    get googleTagManagerId() {
        return getEnvVar("REACT_APP_GOOGLE_TAG_MANAGER_ID");
    }


    get authServiceRoot() {
        return getEnvVar("REACT_APP_AUTH_SERVICE_ROOT");
    }


    get authServiceAppRoot() {
        return getEnvVar("REACT_APP_AUTH_SERVICE_APP_ROOT");
    }


    get authServiceCookieName() {
        return getEnvVar("REACT_APP_AUTH_SERVICE_COOKIE_NAME");
    }


    get sessionAnalyticsIndexName() {
        return getEnvVar("REACT_APP_SESSION_ANALYTICS_INDEX_NAME");
    }


    get hubspotPortalId() {
        return getEnvVar("REACT_APP_HUBSPOT_PORTAL_ID");
    }


    get hubspotFormId() {
        return getEnvVar("REACT_APP_HUBSPOT_FORM_ID");
    }


    get slackWorkspace() {
        return getEnvVar("REACT_APP_SLACK_WORKSPACE");
    }


    get resourcesCdnRoot() {
        return getEnvVar("REACT_APP_RESOURCES_CDN_ROOT");
    }


    get miroClientId() {
        return getEnvVar("REACT_APP_MIRO_CLIENT_ID");
    }


    get twitchClientId() {
        return getEnvVar("REACT_APP_TWITCH_CLIENT_ID");
    }


    get microsoftClientId() {
        return getEnvVar("REACT_APP_MICROSOFT_CLIENT_ID");
    }


    get microsoftAuthority() {
        return getEnvVar("REACT_APP_MICROSOFT_AUTHORITY");
    }


    get notionClientId() {
        return getEnvVarWithDefault("REACT_APP_NOTION_CLIENT_ID", `e1e234a3-cf76-4564-930e-61d3b13e802e`);
    }


    get pspdfkitServerUrl() {
        return getEnvVarWithDefault("REACT_APP_PSPDFKIT_SERVER_URL", `https://pspdfkit-service.sessions.flowos.dev/`);
    }


    get uipathTemplateTagId() {
        return getEnvVar("REACT_APP_UIPATH_TEMPLATE_TAG_ID");
    }


    get uipathMasterEmail() {
        return getEnvVar("REACT_APP_UIPATH_MASTER_EMAIL");
    }


    get presenceHost() {
        return getEnvVar("REACT_APP_PRESENCE_HOST");
    }


    get myProductToolSocketHost() {
        return getEnvVar("REACT_APP_MY_PRODUCT_TOOL_SOCKET_HOST");
    }


    get eventBaseURL() {
        return getEnvVar("REACT_APP_EVENT_BASE_U_R_L");
    }


    get editorHocuspocusWs() {
        return getEnvVarWithDefault("REACT_APP_EDITOR_HOCUSPOCUS_WS", `wss://editor-streaming.sessions.flowos.dev`);
    }


    get liveKitURL() {
        return getEnvVarWithDefault("REACT_APP_LIVE_KIT_U_R_L", `wss://sessions-prod-judw2oyw.livekit.cloud`);
    }


    get paddleVendorId() {
        return getEnvVarWithDefault("REACT_APP_PADDLE_VENDOR_ID", `13300`);
    }


    get paddleSandbox() {
        return getEnvVarWithDefault("REACT_APP_PADDLE_SANDBOX", `true`);
    }


    get celloProductId() {
        return getEnvVarWithDefault("REACT_APP_CELLO_PRODUCT_ID", `stage-app.sessions.us`);
    }


    get sessionsReleaseVer() {
        return getEnvVarWithDefault("REACT_APP_SESSIONS_RELEASE_VER", `static`);
    }


    get stripeAppUrl() {
        return getEnvVarWithDefault("REACT_APP_STRIPE_APP_URL", `https://dashboard.stripe.com/test`);
    }

    get doptBlocksApiKey() {
        return getEnvVar("REACT_APP_DOPT_BLOCKS_API_KEY");
    }

    get demoSessionId() {
        return getEnvVar("REACT_APP_DEMO_SESSION_ID");
    }

    get paypalAppUrl() {
        return getEnvVarWithDefault("REACT_APP_PAYPAL_APP_URL", `https://www.sandbox.paypal.com`);
    }

}

const systemEnv = new SystemEnv();
const publicSystemEnv = new PublicSystemEnv();

export {systemEnv, publicSystemEnv};
