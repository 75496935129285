import React, {useEffect} from "@workhorse/api/rendering";
import {useNewQuestionsCount} from "../utils";

interface NewQuestionsCountProps {
    setNewQuestionsCount: (count: number) => void;
}

const NewQuestionsCount = (props: NewQuestionsCountProps) => {
    const {setNewQuestionsCount} = props;
    const questionsCount = useNewQuestionsCount();

    useEffect(() => {
        setNewQuestionsCount(questionsCount || 0);
    }, [questionsCount]);

    return <></>;
};

export default NewQuestionsCount;
