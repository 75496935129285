import {AcceptedTimezone, getLocalTimezone} from "@common/utils/timezones";
import {
    GetPublicBookingEventAvailableSlotsQuery,
    PublicBookingEventAvailableSlotFragment,
    PublicBookingEventFragment,
} from "@generated/data";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {updateBookingEventsAvailableSlotsForDay} from "@workhorse/api/booking";
import {useRef, useState, useEffect, useMemo} from "@workhorse/api/rendering";
import {useBreakpoint} from "@workhorse/providers/BreakpointProvider";
import {endOfDay, endOfMonth, format, max, startOfDay, startOfMonth, addMonths, isAfter, isBefore, addMinutes} from "date-fns";
import {getTimezoneOffset} from "date-fns-tz";
import classes from "../styles/BookEventDateTime.module.scss";
import {BookCalendar} from "./BookCalendar";
import {BookTimeSlots} from "./BookTimeSlots";
import {useQuery} from "@workhorse/dataApi";
import BookEventDateTimeSkeleton from "./BookEventDateTimeSkeleton";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {useUserInfo} from "@workhorse/providers/User";
import {localeWeekdayOfMonthDateFnsFormat, getDateFnsLocale} from "@sessions/common/utils/languages";
import Tooltip from "@ui/cdk/Tooltip";
import {dayOfTheWeekToNumber} from "@workhorse/api/calendar";
import {useLocation, useHistory} from "@workhorse/api/routing";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Typography from "@ui/cdk/Typography";
import {BookTroubleshootBanner} from "./BookTroubleshootBanner";

type BookEventDateTimeSlotsProps = {
    value: Date;

    timezone: AcceptedTimezone;
    defaultTimezone: AcceptedTimezone;
    duration?: number;
    event: PublicBookingEventFragment;

    isBookADemo?: boolean;
    bookingError: string | null;

    isReschedule?: boolean;
    bookingTimedOut?: boolean;
    isSubmitting?: boolean;
    isHalfDayClock: boolean;
    isTroubleshootMode?: boolean;
    setIsHalfDayClock(state: boolean): void;

    setBookingError(v: string | null): void;
    onChangeTimeZone: (timeZone: string) => void;
    onChange?: (date: Date | null) => void;
    onFinish?: () => void;
    onCancel?: () => void;
    onBack?: () => void;
};

export const BookEventDateTime = ({
    data,
    setStartDay,
    loading,
    bookingError,
    setBookingError,
    value,
    event,
    timezone,
    defaultTimezone,
    duration,
    isReschedule,
    bookingTimedOut,
    isSubmitting,
    isHalfDayClock,
    isTroubleshootMode,
    onChangeTimeZone,
    onChange,
    onFinish,
    onCancel,
    onBack,
}: BookEventDateTimeSlotsProps & {
    data?: GetPublicBookingEventAvailableSlotsQuery | null;
    setStartDay: (date: Date) => void;
    loading: boolean;
}) => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language;
    const [chosenDate, setChosenDate] = useState<null | Date>(startOfDay(value));
    const [selectedMonth, setSelectedMonth] = useState(() => startOfMonth(chosenDate ?? value));
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date | undefined>(value);
    const {setFieldValue} = useFormikContext();
    const userInfo = useUserInfo();
    const history = useHistory();
    const location = useLocation();

    const hasError = !!bookingError;

    const firstDayOfWeekNo = dayOfTheWeekToNumber(userInfo.firstDayOfWeek ?? event.ownerFirstDayOfWeek ?? "Monday");

    const breakpoint = useBreakpoint();
    const isMobileOrTablet = ["xs", "sm", "md"].indexOf(breakpoint) !== -1;
    const isMultiStepView = breakpoint === "sm";

    const [configStep, setConfigStep] = useState<1 | 2>(1);

    const canTroubleshoot = useMemo(() => {
        return event.collaborators.some((user) => user.userId === userInfo.id) || event.ownerId === userInfo.id;
    }, [userInfo.id, event.ownerId, event.collaborators]);

    const handleOnDateChange = (date: Date) => {
        setChosenDate(date);

        if (selectedTimeSlot) {
            onChange?.(selectedTimeSlot);
        }
    };

    useEffect(() => {
        if (!data?.publicBookingEventAvailableSlots?.length) {
            setChosenDate(null);
            setSelectedTimeSlot(undefined);
        } else {
            const firstSlot = data.publicBookingEventAvailableSlots[0];
            const firstSlotDay = new Date(firstSlot.day);
            const offset = getTimezoneOffset(event.timeZone, value) / (60 * 1000);
            const date = startOfDay(addMinutes(firstSlotDay, offset));

            if (!chosenDate || chosenDate < date) {
                setChosenDate(date);
            }

            if (selectedMonth !== startOfMonth(date)) {
                setSelectedMonth(startOfMonth(date));
            }

            updateBookingEventsAvailableSlotsForDay(date, data.publicBookingEventAvailableSlots, timezone);
        }
    }, [data?.publicBookingEventAvailableSlots]);

    useEffect(() => {
        if (!data?.publicBookingEventAvailableSlots?.length || !timezone || !chosenDate) {
            return;
        }

        updateBookingEventsAvailableSlotsForDay(chosenDate, data.publicBookingEventAvailableSlots, timezone);
    }, [timezone]);

    useEffect(() => {
        if (!loading && hasError) {
            setBookingError(null);
        }
    }, [loading, hasError]);

    const handleOnChangeTimeSlot = (selected: PublicBookingEventAvailableSlotFragment) => {
        if (!selected) {
            setSelectedTimeSlot(undefined);
            onChange?.(null);
            setFieldValue("availableAssignees", []);
        } else {
            const slotDate = new Date(selected.day);
            setSelectedTimeSlot(slotDate);
            setFieldValue("availableAssignees", selected.collaborators);
            onChange?.(slotDate);
        }
    };

    const handleOnChangeMonth = (diff: number) => {
        const newDate = addMonths(selectedMonth, diff);
        const maxDate = event.to ? endOfDay(new Date(event.to)) : undefined;
        if (isAfter(endOfMonth(newDate), new Date())) {
            setSelectedMonth(newDate);

            if (!maxDate || isBefore(newDate, maxDate)) {
                setStartDay(newDate);
                setChosenDate(null);
            }
        }
    };

    const onNextStep = () => {
        setConfigStep(2);
    };

    const onPreviousStep = () => {
        setConfigStep(1);
    };

    const toggleTroubleshoot = () => {
        const params = new URLSearchParams(location.search);
        params.set("troubleshoot", isTroubleshootMode ? "false" : "true");
        history.push({...location, search: params.toString()});
        setChosenDate(null);
    };

    if (loading || !data) {
        return <BookEventDateTimeSkeleton />;
    }

    return (
        <>
            {isMultiStepView ? (
                configStep === 1 ? (
                    <>
                        <div className={classes.calendarHeader}>{t("booking.page.select_a_date_and_time")}</div>
                        <BookCalendar
                            data={data.publicBookingEventAvailableSlots}
                            selectedMonth={selectedMonth}
                            value={chosenDate}
                            timezone={timezone}
                            onChange={handleOnDateChange}
                            onMonthChange={handleOnChangeMonth}
                            firstDayOfWeek={firstDayOfWeekNo}
                            isTroubleshootMode={isTroubleshootMode}
                        />
                        {!data.publicBookingEventAvailableSlots.length ? (
                            <div className={classes.errorText}>{t("booking.page.no_dates_available_for_month")}</div>
                        ) : null}
                        <div className={cls(classes.footer, "flex-col gap-12")}>
                            <div className={classes.footerActions}>
                                {onBack ? (
                                    <Button
                                        variant={isMobileOrTablet ? "tertiary" : "quaternary"}
                                        onClick={onBack}
                                        className={cls("mr-8", classes.footerButtonMobile)}
                                        data-id="previous-month"
                                    >
                                        {t("g.back")}
                                    </Button>
                                ) : null}
                                <Button
                                    className={cls(classes.footerButton, classes.footerButtonMobile)}
                                    onClick={onNextStep}
                                    disabled={!chosenDate}
                                >
                                    {t("g.next")}
                                </Button>
                            </div>
                            {isTroubleshootMode ? (
                                <BookTroubleshootBanner onDone={toggleTroubleshoot} />
                            ) : (
                                <>
                                    {canTroubleshoot ? (
                                        <Button
                                            className={cls("mr-8", classes.troubleshootButton)}
                                            variant={"tertiary"}
                                            onClick={toggleTroubleshoot}
                                        >
                                            <span className="flex flex-align-center gap-6">
                                                {t("g.troubleshoot")}{" "}
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={
                                                        <>
                                                            <Typography variant="sm" className="mb-6" fontWeight="boldest">
                                                                {t("booking.troubleshoot_tooltip_title")}
                                                            </Typography>
                                                            <Typography className="mb-4" variant="sm">
                                                                {t("booking.troubleshoot_tooltip_description")}
                                                            </Typography>
                                                            <Typography variant="sm">{t("booking.troubleshoot_tooltip_note")}</Typography>
                                                        </>
                                                    }
                                                >
                                                    <span>
                                                        <InfoOutlined />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Button>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classes.timeSlotContent}>
                            <div className={cls("flex-justify-between", classes.date)}>
                                {chosenDate ? (
                                    <div>{format(chosenDate, localeWeekdayOfMonthDateFnsFormat[lang])}</div>
                                ) : (
                                    <div>{t("booking.page.select_date")}</div>
                                )}
                            </div>
                            <BookTimeSlots
                                duration={(duration ?? event.duration) / 60}
                                timezone={timezone}
                                defaultTimezone={defaultTimezone}
                                selected={selectedTimeSlot}
                                onChange={handleOnChangeTimeSlot}
                                chosenDate={chosenDate}
                                isHalfDayClock={isHalfDayClock}
                                isTroubleshootMode={isTroubleshootMode}
                                collaborators={event.collaborators}
                                bookingEventId={event.id ?? ""}
                            />
                        </div>
                        <div className={cls(classes.footer, "flex-col gap-12")}>
                            <div className={classes.footerActions}>
                                <Button variant="tertiary" className={classes.footerButtonMobile} onClick={onPreviousStep}>
                                    {t("g.back")}
                                </Button>

                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={
                                        bookingError === "owner_booked"
                                            ? t(`booking.page.error_schedule_owner_booked`) ??
                                              "Owner is already booked for this time slot. Please try again."
                                            : t(`booking.page.error_schedule_network_failure`) ??
                                              "Could not schedule the session due to a network failure. Please try again."
                                    }
                                    open={isReschedule && (!!bookingError || bookingTimedOut)}
                                    disableFocusListener={true}
                                    disableHoverListener={true}
                                    disableTouchListener={true}
                                >
                                    <Button
                                        data-id={isReschedule ? "schedule-session" : "confirm"}
                                        className={cls(classes.footerButton, classes.footerButtonMobile)}
                                        onClick={onFinish}
                                        loading={isSubmitting}
                                        disabled={!selectedTimeSlot || isSubmitting}
                                    >
                                        {!isReschedule
                                            ? t("g.confirm")
                                            : isSubmitting
                                            ? t("booking.page.scheduling")
                                            : t("booking.page.schedule_session")}
                                    </Button>
                                </Tooltip>
                            </div>
                            {isTroubleshootMode ? (
                                <BookTroubleshootBanner onDone={toggleTroubleshoot} />
                            ) : (
                                <>
                                    {canTroubleshoot ? (
                                        <Button
                                            className={cls("mr-8", classes.troubleshootButton)}
                                            variant={"tertiary"}
                                            onClick={toggleTroubleshoot}
                                        >
                                            <span className="flex flex-align-center gap-6">
                                                {t("g.troubleshoot")}{" "}
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={
                                                        <>
                                                            <Typography variant="sm" className="mb-6" fontWeight="boldest">
                                                                {t("booking.troubleshoot_tooltip_title")}
                                                            </Typography>
                                                            <Typography className="mb-4" variant="sm">
                                                                {t("booking.troubleshoot_tooltip_description")}
                                                            </Typography>
                                                            <Typography variant="sm">{t("booking.troubleshoot_tooltip_note")}</Typography>
                                                        </>
                                                    }
                                                >
                                                    <span>
                                                        <InfoOutlined />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Button>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </>
                )
            ) : (
                <>
                    <div className={classes.calendar}>
                        <div className={classes.calendarContent}>
                            <div className={classes.dateSlotContent}>
                                <BookCalendar
                                    data={data.publicBookingEventAvailableSlots}
                                    selectedMonth={selectedMonth}
                                    value={chosenDate}
                                    timezone={timezone}
                                    onChange={handleOnDateChange}
                                    onMonthChange={handleOnChangeMonth}
                                    firstDayOfWeek={firstDayOfWeekNo}
                                    isTroubleshootMode={isTroubleshootMode}
                                />
                                {!data.publicBookingEventAvailableSlots.length ? (
                                    <div className={classes.errorText}>{t("booking.page.no_dates_available_for_month")}</div>
                                ) : null}
                            </div>
                            <div className={classes.separator} />

                            <div className={classes.timeSlotContent}>
                                <div className={classes.date}>
                                    {chosenDate ? (
                                        <div>
                                            {format(chosenDate, localeWeekdayOfMonthDateFnsFormat[lang], {
                                                locale: getDateFnsLocale(lang),
                                            })}
                                        </div>
                                    ) : (
                                        <div>{t("booking.page.select_date")}</div>
                                    )}
                                </div>
                                <BookTimeSlots
                                    duration={(duration ?? event.duration) / 60}
                                    timezone={timezone}
                                    defaultTimezone={defaultTimezone}
                                    selected={selectedTimeSlot}
                                    onChange={handleOnChangeTimeSlot}
                                    chosenDate={chosenDate}
                                    isHalfDayClock={isHalfDayClock}
                                    isTroubleshootMode={isTroubleshootMode}
                                    collaborators={event.collaborators}
                                    bookingEventId={event.id ?? ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        {isTroubleshootMode ? (
                            <BookTroubleshootBanner onDone={toggleTroubleshoot} />
                        ) : (
                            <>
                                {canTroubleshoot ? (
                                    <Button
                                        className={cls("mr-8", classes.troubleshootButton)}
                                        variant={"tertiary"}
                                        onClick={toggleTroubleshoot}
                                    >
                                        <span className="flex flex-align-center gap-6">
                                            {t("g.troubleshoot")}{" "}
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={
                                                    <>
                                                        <Typography variant="sm" className="mb-6" fontWeight="boldest">
                                                            {t("booking.troubleshoot_tooltip_title")}
                                                        </Typography>
                                                        <Typography className="mb-4" variant="sm">
                                                            {t("booking.troubleshoot_tooltip_description")}
                                                        </Typography>
                                                        <Typography variant="sm">{t("booking.troubleshoot_tooltip_note")}</Typography>
                                                    </>
                                                }
                                            >
                                                <span>
                                                    <InfoOutlined />
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </Button>
                                ) : null}
                                <div className={cls(classes.footerActions, "ml-auto")}>
                                    {onBack ? (
                                        <Button className="mr-8" variant={isMobileOrTablet ? "tertiary" : "quaternary"} onClick={onBack}>
                                            {t("g.back")}
                                        </Button>
                                    ) : onCancel ? (
                                        <Button className="mr-8" variant="quaternary" onClick={onCancel}>
                                            {t("g.cancel")}
                                        </Button>
                                    ) : null}

                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={
                                            bookingError === "owner_booked"
                                                ? t(`booking.page.error_schedule_owner_booked`) ??
                                                  "Owner is already booked for this time slot. Please try again."
                                                : t(`booking.page.error_schedule_network_failure`) ??
                                                  "Could not schedule the session due to a network failure. Please try again."
                                        }
                                        open={isReschedule && (!!bookingError || bookingTimedOut)}
                                        disableFocusListener={true}
                                        disableHoverListener={true}
                                        disableTouchListener={true}
                                    >
                                        <Button
                                            data-id={isReschedule ? "schedule-session" : "confirm"}
                                            className={cls(classes.footerButton)}
                                            onClick={onFinish}
                                            loading={isSubmitting}
                                            disabled={!selectedTimeSlot || isSubmitting}
                                        >
                                            {!isReschedule
                                                ? t("g.confirm")
                                                : isSubmitting
                                                ? t("booking.page.scheduling")
                                                : t("booking.page.schedule_session")}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

const BookEventDateTimeLoader = (props: BookEventDateTimeSlotsProps) => {
    const {event, duration, value, isTroubleshootMode} = props;

    const [from, setFromDate] = useState(() => {
        const minDate = max([startOfDay(new Date()), startOfDay(new Date(event.from))]);
        return startOfMonth(max([minDate || value || new Date(), value || new Date()]));
    });

    const to = endOfMonth(from);

    const {data, loading} = useQuery("GetPublicBookingEventAvailableSlotsDocument", {
        variables: {
            id: event.id as string,
            timezone: getLocalTimezone() as string,
            duration: duration,
            from,
            to,
            troubleshoot: isTroubleshootMode,
        },
        fetchPolicy: props.isBookADemo || !!props.bookingError ? "network-only" : undefined,
    });

    const isFirstLoading = useRef(true);

    useEffect(() => {
        if (!isFirstLoading.current || !data?.publicBookingEventAvailableSlots) {
            return;
        }

        isFirstLoading.current = false;

        if (data?.publicBookingEventAvailableSlots?.length) {
            return;
        }

        const nextMonthStart = startOfMonth(addMonths(from, 1));

        if (event.to && event.to < nextMonthStart) {
            return;
        }

        setFromDate(nextMonthStart);
    }, [data?.publicBookingEventAvailableSlots]);

    return <BookEventDateTime data={data} setStartDay={setFromDate} loading={loading} {...props} />;
};

export default BookEventDateTimeLoader;
