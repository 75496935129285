import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/BlockedPermissionsModal.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@ui/core/components/IconButton";
import {useEffect, useRef} from "react";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideoOffIcon from "@material-ui/icons/VideocamOff";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";
import {WithChildren} from "@workhorse/declarations";

interface BlockedPermissionsImgProps {
    hideCamera?: boolean;
    hideMicrophone?: boolean;
}

function IconWrapper(props: WithChildren) {
    return <div className={classes.iconToggler}>{props.children}</div>;
}

export function BlockedPermissionsImg(props: BlockedPermissionsImgProps) {
    return (
        <div className={cls("flex", classes.blockedPermissionImg)}>
            <div className={classes.iconTogglerWrapper}>
                {props.hideCamera !== true && <VideoOffIcon style={{color: "white"}} />}
                {props.hideMicrophone !== true && <MicOffIcon style={{color: "white"}} />}
            </div>
        </div>
    );
}

type BlockedPermissionsDialogProps = {
    open: boolean;
    micBlocked?: boolean;
    cameraBlocked?: boolean;
    onRequest: () => void;
    onClose: () => void;
};

export function RequestPermissionsDialog(props: BlockedPermissionsDialogProps) {
    const {t} = useTranslation();

    const imperativeRef = useRef<DialogImperativeRef>();

    const {onboardingType} = useOnboardingSession();

    useEffect(() => {
        imperativeRef.current?.toggle?.(props.open);
    }, [props.open]);

    console.log(props.cameraBlocked, props.micBlocked, "props.cameraBlocked, props.micBlocked");

    let title = t("devices.v2.cam_and_mic_blocked") ?? "Want to use camera and microphone?";
    let body =
        t("devices.v2.cam_and_mic_blocked_description") ??
        "Please allow Sessions access to your camera and microphone, also make sure that no other app is currently using them. If you skip this, you can still join the session, but others won’t be able to see or hear you.";

    let cta2 = t("devices.v2.cam_and_mic_blocked_cta2") ?? "Continue without camera and microphone";

    if (props.micBlocked && props.cameraBlocked !== true) {
        title = t("devices.v2.mic_blocked") ?? "Want to use microphone?";
        body =
            t("devices.v2.mic_blocked_description") ??
            "Please allow Sessions access to your microphone, also make sure that no other app is currently using it. If you skip this, you can still join the session, but others won’t be able to hear you.";
        cta2 = t("devices.v2.mic_blocked_cta2") ?? "Continue without microphone";
    }

    if (props.cameraBlocked && props.micBlocked !== true) {
        title = t("devices.v2.cam_blocked") ?? "Want to use camera?";
        body =
            t("devices.v2.cam_blocked_description") ??
            "Please allow Sessions access to your camera, also make sure that no other app is currently using it. If you skip this, you can still join the session, but others won’t be able to see you.";
        cta2 = t("devices.v2.cam_blocked_cta2") ?? "Continue without camera";
    }

    if (onboardingType === SessionOnboardingType.NewUser) {
        return null;
    }

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            classes={{
                paper: classes.paper,
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop-with-backdrop-filter",
                },
            }}
            imperativeRef={imperativeRef}
            open={props.open}
            disableBackdropClick
        >
            <div className={cls("flex flex-justify-between", classes.dialogHeader)}>
                <Typography className={classes.dialogHeaderTitle}>{title}</Typography>

                <IconButton onClick={props.onClose} className={cls("p-0 mb-auto", classes.closeButton)}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <div>
                <div>
                    <BlockedPermissionsImg />

                    <Typography variant="lg" className={classes.infoTextSimple}>
                        {body}
                    </Typography>
                </div>
            </div>

            <div className="flex flex-col">
                <Button variant="primary" className={cls("mb-15", classes.footerButton)} onClick={props.onRequest}>
                    {t("devices.v2.blocked_cta") ?? "Use camera and microphone"}
                </Button>
                <Button variant="plain" className={classes.footerButton} onClick={props.onClose}>
                    {cta2}
                </Button>
            </div>
        </Dialog>
    );
}
