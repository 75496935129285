import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import ImageScreen from "../../../assets/media/screen_cant_join_session.svg";
import classes from "../styles/PageNotFound.module.scss";

import {useHistory} from "@workhorse/api/routing";

type PageNotFoundProps = {
    title?: string;
    secondaryTitle?: string;
    body?: string;
};

const PageNotFound = (props: PageNotFoundProps) => {
    const {body, secondaryTitle, title} = props;

    const history = useHistory();

    const onNav = () => {
        history.replace("/");
    };

    return (
        <div className={cls("flex flex-col flex-center-all", classes.root)}>
            <div className={classes.header}>
                <Typography variant="xl7" className={classes.primaryTitle}>
                    {title ?? "404"}
                </Typography>
                <Typography variant="xl3" className={classes.secondaryTitle}>
                    {secondaryTitle ?? "We love keeping you on the edge of your seat"}.
                </Typography>
                <Typography variant="xl3" className={classes.secondaryTitle}>
                    {body ?? "But there's nothing new here."}
                </Typography>
            </div>
            <img className={classes.image} src={ImageScreen} alt="error img" />

            <Button onClick={onNav}>Home</Button>
        </div>
    );
};

export default PageNotFound;
