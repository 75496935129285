/* eslint-disable react/no-unescaped-entities */
import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as SendEndSessionEmailIcon} from "../../assets/send-reminder.svg";
import {useTranslation} from "react-i18next";

const styles = {...genericStyles};

type SessionSendEndSessionEmailProps = {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const SessionSendEndSessionEmailToggler = ({enabled, onChange, showIcon = false, disabled, className}: SessionSendEndSessionEmailProps) => {
    const {t} = useTranslation();
    const handleOnChange = () => onChange(!enabled);

    return (
        <div className={cls(styles.togglerWrapper, className)}>
            {showIcon ? (
                <div className={styles.iconWrapper}>
                    <SendEndSessionEmailIcon />
                </div>
            ) : null}
            <TogglerContainer
                title={
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("player.settings.general.session.email_followup.title")}
                    </Typography>
                }
                secondTitle={
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.session.email_followup.description")}
                    </Typography>
                }
                checked={enabled}
                name="sendEndSessionEmail"
                onChange={handleOnChange}
                togglePlacement="secondTitle"
                label=" "
                className={cls(styles.toggler)}
                disabled={disabled}
            />
        </div>
    );
};

export default SessionSendEndSessionEmailToggler;
