import {ArtifactTag} from "@generated/artifacts";
import {DrawerState} from "@generated/data";
import {useReactiveVar} from "@workhorse/api/data";
import {Fragment, useContext, useEffect, useReducer, useMemo, useRef} from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";
import {drawerRightActiveComponent, DrawerRightInjectableTag} from "@workhorse/providers/inject";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {cls} from "@ui/cdk/util/util";
import {HostType} from "@workhorse/declarations/dataTypes";
import {useDrawerRightWidth} from "@workhorse/providers/useDrawerRightWidth";
import loadable from "@workhorse/api/loadable";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {withErrorBoundary} from "../WithErrorBoundary";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {DraggableDialog} from "@workhorse/pages/player/components/DraggableDialog";

const DrawerRightMobileHeader = loadable(() => import("./components/DrawerRightMobileHeader"));

export const drawerRightDefaultWidth = 364;

export const rightDwWidths: Partial<
    {
        [K in ArtifactTag]: number;
    } & {
        adminController?: number;
    }
> = {
    "flowos/chat": drawerRightDefaultWidth,
    "flowos/qa": drawerRightDefaultWidth,
    "flowos/contextual-notes": drawerRightDefaultWidth,
    adminController: drawerRightDefaultWidth,
};

interface DrawerRightProps extends WithMobileState {
    isFullScreen: boolean;
    hostType: HostType;
}

function DrawerRight(props: DrawerRightProps) {
    const {isFullScreen, mobileState, hostType} = props;
    const {isLobby} = useIsLobby();

    const [drawerRightState, toggle] = useDrawerRightToggler();
    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set, unset} = useContext(InjectorContext);
    const activeComponent = useReactiveVar(drawerRightActiveComponent);
    const {isFullyOpenMobile} = useDrawerRightWidth();
    const {isPortrait} = useDeviceOrientation();

    const activeComponentRef = useRef<DrawerRightInjectableTag | null>(activeComponent);
    activeComponentRef.current = activeComponent;

    const {isMobile} = mobileState;
    const isFullyOpen = drawerRightState.isFullyOpen;

    const isMemory = hostType === HostType.Memory;

    const style = useMemo(
        () =>
            isMobile
                ? {
                      minWidth: isFullyOpenMobile,
                      maxWidth: isFullyOpenMobile,
                      marginLeft: !isFullScreen && isFullyOpen ? `-${isFullyOpenMobile}` : 0,
                      top: 68,
                  }
                : {
                      minWidth: activeComponent ? rightDwWidths[activeComponent] || drawerRightDefaultWidth : drawerRightDefaultWidth,
                      maxWidth: activeComponent ? rightDwWidths[activeComponent] || drawerRightDefaultWidth : drawerRightDefaultWidth,
                      marginLeft: isFullyOpen
                          ? -(rightDwWidths[activeComponent!] ? rightDwWidths[activeComponent!]! : drawerRightDefaultWidth) - 12
                          : 0,
                      top: isFullScreen ? 12 : isMemory ? 79 : 68,
                      bottom: 68,
                  },
        [activeComponent, drawerRightState.state, isMobile, isFullScreen, isMemory]
    );

    const ids = (Object.keys(existing) as Array<keyof typeof existing>).filter((x) => x !== "classNames");
    const className = cls(
        ids.length > 0 && "with-content app-border" + (isLobby ? " is-in-lobby" : ""),
        drawerRightState.state,
        cls.apply(null, existing.classNames)
    );

    useEffect(() => {
        set("inDrawerRight", inject);
    }, []);

    const handleToggleDrawer = () => {
        toggle();
    };

    useEffect(() => {
        if (drawerRightState.state === "isFullyOpen" && isMobile && !isPortrait) {
            toggle(DrawerState.Closed);
            drawerRightActiveComponent(null);
        }

        const activeComponent = activeComponentRef.current;

        if (drawerRightState.state !== "isFullyOpen" && activeComponent && isMobile) {
            drawerRightActiveComponent(null);
        }
    }, [drawerRightState.state, isMobile, isPortrait]);

    const hideDrawer = isMobile && isFullScreen;
    const ariaHidden = isMobile ? !drawerRightState.isFullyOpen : false;

    return !isMobile && drawerRightState.isDetached && drawerRightState.isFullyOpen ? (
        <DraggableDialog id="rightDrawer" width={360} height={640} defaultHeight={640} isSpeakerWidget={false} enableResizing={true}>
            <div className={cls("drawer-right", "isDetached", className)} aria-hidden={ariaHidden}>
                {ids.map((k) => {
                    return <Fragment key={k}>{existing[k]}</Fragment>;
                })}
            </div>
        </DraggableDialog>
    ) : (
        <div
            id={"drawer-right"}
            className={cls("drawer-right", className, hideDrawer && "hidden-but-displayed")}
            style={style}
            aria-hidden={ariaHidden}
        >
            {isMobile && activeComponent !== "flowos/chat" ? <DrawerRightMobileHeader toggleDrawer={handleToggleDrawer} /> : null}
            {ids.map((k) => {
                return <Fragment key={k}>{existing[k]}</Fragment>;
            })}
        </div>
    );
}

export default withErrorBoundary(DrawerRight, true);
