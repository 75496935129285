import browserInfo from "@workhorse/api/BrowserInfo";
import {addDays, isSameYear, isSameMonth} from "date-fns";

export function cls(...args: any[]): string {
    // DO NOT change this to use Array.filter or other methods
    let s = "";
    for (let i = 0; i < arguments.length; i++) {
        if (typeof arguments[i] == "string" && arguments[i]) {
            s = s + " " + arguments[i];
        }
    }
    return s.substr(1);
}

export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeXD(string: string): string {
    if (string.toLowerCase() === "adobexd") {
        return "Adobe XD";
    }

    return capitalize(string);
}

export function collapseWhiteSpace(string: string): string {
    return string.replace(/\s+/g, " ");
}

function makeAgo(
    arg: {years: number; months: number; weeks: number; days: number; hrs: number; mins: number; secs: number},
    opts?: {simple?: boolean}
) {
    const {years, months, weeks, days, hrs, mins, secs} = arg;
    const simple = opts || {};

    const secsTxt = secs ? `${secs}${simple ? "s" : " seconds"}` : null;
    const minsTxt = mins ? `${mins}${simple ? "min" : " minutes"}` : null;
    const hoursTxt = hrs ? `${hrs}${simple ? "h" : " hours"}` : null;
    const daysTxt = days ? `${days}${simple ? "d" : " days"}` : null;
    const weeksTxt = weeks ? `${weeks}${simple ? "w" : " weeks"}` : null;
    const monthsTxt = months ? `${months}${simple ? "mo" : " months"}` : null;
    const yearsTxt = years ? `${years}${simple ? "y" : " years"}` : null;
    const delim = ", ";
    let out = `${secsTxt}`;
    if (years) {
        out = `${yearsTxt}${monthsTxt ? delim + monthsTxt : ""}`;
    } else if (months) {
        out = `${monthsTxt}${weeksTxt ? delim + weeksTxt : ""}`;
    } else if (weeks) {
        out = `${weeksTxt}${daysTxt ? delim + daysTxt : ""}`;
    } else if (days) {
        out = `${daysTxt}${hoursTxt ? delim + hoursTxt : ""}`;
    } else if (hrs) {
        out = `${hoursTxt}${minsTxt ? delim + minsTxt : ""}`;
    } else if (mins) {
        out = `${minsTxt}${secsTxt ? delim + secsTxt : ""}`;
    }
    out += " ago";
    return out;
}

function isLeapYear(year: number) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}

function getDayOfYear(dateArg?: Date) {
    var date = new Date(dateArg ?? new Date());
    var start = new Date(date.getFullYear(), 0, 0);
    var diff = date.getTime() - start.getTime() + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}

function yearsAgo(fromDate: Date, compareTo: Date = new Date()) {
    const now = compareTo;
    if (isSameYear(fromDate, now)) {
        return {years: 0, newPastDate: fromDate};
    }
    let from = new Date(fromDate);
    let years = 0;
    let newPastDate = from;
    while (true) {
        const isLeap = isLeapYear(from.getFullYear());
        const yDays = isLeap ? 366 : 365;
        from = addDays(from, yDays);
        if (from.getTime() <= now.getTime()) {
            newPastDate = from;
            years++;
        } else {
            break;
        }
    }
    return {years, newPastDate};
}

function remainingMonths(fromDate: Date, compareTo: Date = new Date()) {
    const now = compareTo;
    if (isSameMonth(fromDate, now)) {
        return {months: 0, newPastDate: fromDate};
    }
    let from = new Date(fromDate);
    let months = 0;
    let newPastDate = from;
    while (true) {
        const month = from.getMonth();
        from = addDays(from, 30);
        if (from.getTime() <= now.getTime()) {
            const fmonth = from.getMonth();
            const diff = (fmonth < month ? fmonth + 12 : fmonth) - month;
            months = months + diff;
            newPastDate = from;
        } else {
            break;
        }
    }
    return {months, newPastDate};
}

function remainingWeeksAndDays(date: Date, compareTo: Date = new Date()) {
    const now = compareTo;
    const diffMs = now.getTime() - date.getTime();
    let secs = diffMs / 1000;
    let hrs = Math.floor(secs / 60 / 60);
    secs = secs - hrs * 60 * 60;
    let days = Math.floor(hrs / 24);
    hrs = hrs - days * 24;
    let weeks = Math.floor(days / 7);
    days = days - weeks * 7;
    const mins = Math.floor(secs / 60);
    secs = isNaN(secs % 60) ? 0 : secs % 60;
    return {weeks, days, hrs, mins, secs: Math.floor(secs)};
}

export function timeSince(date: Date) {
    const {years, newPastDate} = yearsAgo(date);
    const {months, newPastDate: monthEndDate} = remainingMonths(newPastDate);
    const rest = remainingWeeksAndDays(monthEndDate);

    return makeAgo({years, months, ...rest}, {simple: true});
}

export function togglePalette() {
    const ctrlKey = browserInfo.isMacOS() ? false : true;
    const metaKey = browserInfo.isMacOS() ? true : false;
    window.dispatchEvent(new KeyboardEvent("keydown", {key: "k", ctrlKey, metaKey, bubbles: true, cancelable: true, view: parent.top}));
}

type PaletteAt = {
    key: string;
};

export function triggerPaletteCtrlAlt({key}: PaletteAt) {
    const ctrlKey = true;
    const metaKey = browserInfo.isMacOS() ? true : false;
    window.dispatchEvent(
        new KeyboardEvent("keydown", {key, ctrlKey, metaKey, altKey: true, bubbles: true, cancelable: true, view: parent.top})
    );
}

export function triggerUpArrow() {
    window.dispatchEvent(
        new KeyboardEvent("keyup", {key: "ArrowUp", ctrlKey: false, metaKey: false, bubbles: true, cancelable: true, view: parent.top})
    );
}

export function triggerDownArrow() {
    window.dispatchEvent(
        new KeyboardEvent("keyup", {key: "ArrowDown", ctrlKey: false, metaKey: false, bubbles: true, cancelable: true, view: parent.top})
    );
}

export function triggerMouseLeave(el: HTMLElement) {
    el.dispatchEvent(new MouseEvent("mouseout", {bubbles: true, cancelable: true}));
}

export function triggerMouseEnter(el: HTMLElement) {
    el.dispatchEvent(new MouseEvent("mouseover", {bubbles: true, cancelable: true}));
}

export function roundToDecimals(value: number, decimals: number) {
    const multiplier = Math.pow(10, decimals);
    return Math.round(value * multiplier) / multiplier;
}
