import environment from "@generated/environment";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {ReactComponent as PersonaMultipleIcon} from "../../../../../../assets/media/persona-multiple.svg";
import {ReactComponent as PersonaSingleIcon} from "../../../../../../assets/media/persona-single.svg";
import OnboardingPersonaWrapper from "./OnboardingPersonaWrapper";
import classes from "./styles/OnboardingPersonaUseCase.module.scss";
import {PersonaUseCase} from "./utils";

type OnboardingPersonaUseCaseProps = {
    onClickStep: (step: number) => void;
    value?: PersonaUseCase;
    onChangeValue: (value: PersonaUseCase) => void;
    organizationName: string;
    onChangeOrganizationName: (value: string) => void;
    onSubmit: () => void;
    step: 1 | 2 | 3 | 4;
};

function OnboardingPersonaUseCase(props: OnboardingPersonaUseCaseProps) {
    const {onClickStep, value, onChangeValue, onSubmit, organizationName, onChangeOrganizationName, step} = props;

    const {isMobile} = useMobile();

    const previewTitle = (
        <div className={cls(classes.previewTitle, classes.previewTitleGold)}>
            Let your <span>brand shine.</span>
        </div>
    );

    const previewDescription = (
        <div className={cls(classes.previewDescription, "mb-20")}>
            Showcase your logo, colour palette, URL and more. Plus, set up a workspace and invite your team.
        </div>
    );

    const handleChangeOrganizationName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChangeOrganizationName(e.target.value);
    };

    return (
        <OnboardingPersonaWrapper
            step={step}
            preview={{
                title: previewTitle,
                description: previewDescription,
                body: `${environment.staticServiceUrl}/assets/onboarding/onboarding-usecase.png`,
            }}
            onClickStep={onClickStep}
            classes={{
                rightCol: cls(classes.rightCol, classes.rightColGold),
            }}
        >
            <Typography variant="xl2" fontWeight="bolder" className="mb-32">
                How are you planning to use Sessions?
            </Typography>

            <div className="flex flex-col fullw">
                <div className={cls("mb-20", classes.useCase)} onClick={() => onChangeValue("personal")}>
                    <div className={classes.logo}>
                        <PersonaSingleIcon />
                    </div>
                    <div className="flex flex-col mr-auto">
                        <Typography color="secondary" fontWeight="bold">
                            By myself
                        </Typography>
                        <Typography color="nonary">I’ll be the only one hosting sessions.</Typography>
                    </div>
                    {value === "personal" ? (
                        <CheckCircleRoundedIcon className={classes.checkIconChecked} />
                    ) : (
                        <RadioButtonUncheckedRoundedIcon className={classes.checkIcon} />
                    )}
                </div>
                <div className={classes.useCase} onClick={() => onChangeValue("team")}>
                    <div className={classes.logo}>
                        <PersonaMultipleIcon />
                    </div>
                    <div className="flex flex-col mr-auto">
                        <Typography color="secondary" fontWeight="bold">
                            With my team
                        </Typography>
                        <Typography color="nonary">Multiple people will be hosting sessions and we’ll share files and agendas.</Typography>
                    </div>
                    {value === "team" ? (
                        <CheckCircleRoundedIcon className={classes.checkIconChecked} />
                    ) : (
                        <RadioButtonUncheckedRoundedIcon className={classes.checkIcon} />
                    )}
                </div>
            </div>

            {value === "team" && !isMobile && (
                <Input
                    name="organizationName"
                    formControlClassName="mt-20 p-0"
                    className={classes.input}
                    placeholder="Enter your organization name"
                    value={organizationName}
                    onChange={handleChangeOrganizationName}
                    maxCharCount={60}
                    showTypedCharCount
                />
            )}

            <Button disabled={!value} onClick={onSubmit} className={classes.button} variant="secondary">
                Continue
            </Button>
        </OnboardingPersonaWrapper>
    );
}

export default OnboardingPersonaUseCase;
