import {FileRef, makeEmptyFileRef} from "@api/resx/ref";
import svc from "@api/service/client";
import {makeVar} from "@workhorse/api/state";
import axios from "axios";
import imageCompression from "browser-image-compression";
import {Headers as ResxHeaders} from "../utils";
import ProcessingComponent from "./ProcessingComponent";
import {v4 as uuid} from "uuid";
import {ResourceProcessingStatus} from "@generated/data";

export type {ResxHeaders};
export {ProcessingComponent};

export type UploadError = {
    text: string;
    type: ErrorType;
};

export enum ErrorType {
    MaxLimit,
    WrongType,
    Other,
}

export const editingResource = makeVar<string>("");

export const processingStatusWeight = {
    [ResourceProcessingStatus.Required]: 0,
    [ResourceProcessingStatus.NotStarted]: 1,
    [ResourceProcessingStatus.Queued]: 2,
    [ResourceProcessingStatus.Started]: 3,
    [ResourceProcessingStatus.Error]: 4,
    [ResourceProcessingStatus.Finished]: 5,
};

const compressionOptions = {
    maxSizeMB: 1.2, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
};

// For Apple iWork file types we need to provide the extension in the file name,
// otherwise the converter cannot open the file

const createFileRefId = (fileName: string) => {
    const id = uuid();
    if (fileName.endsWith(".key")) return id + ".key";
    else if (fileName.endsWith(".numbers")) return id + ".numbers";
    else if (fileName.endsWith(".pages")) return id + ".pages";
    else return id;
};

export const uploadOneResource = async (
    file: File,
    onFailure?: (fileName: string, error: ErrorType) => void,
    onProgress?: (fileName: string, progress: number) => void
) => {
    const fileRef = makeEmptyFileRef({fileName: file.name, fileSize: file.size, id: createFileRefId(file.name)});
    const maybeCompressedFile = ["image/jpg", "image/jpeg", "image/png"].includes(file.type)
        ? await imageCompression(file, compressionOptions)
        : file;

    const uploadFileRef = await svc.resx.uploadFileRef(fileRef);

    await axios
        .request({
            url: uploadFileRef.uploadUrl,
            method: "PUT",
            data: maybeCompressedFile,
            headers: {
                "Content-Type": file.type ? file.type : "application/octet-stream",
            },
            onUploadProgress: (p) => {
                if (onProgress) {
                    if (p.loaded / (p.total ?? 1) !== 0) {
                        onProgress(file.name, p.loaded / (p.total ?? 1));
                    }
                }
            },
        })
        .then((res) => {
            if (!res && onFailure) {
                return onFailure(file.name, ErrorType.Other);
            }
        })
        .catch((err) => {
            if (onFailure) {
                return onFailure(file.name, ErrorType.Other);
            }
        });

    return await svc.resx.markFileRefAsUploaded(fileRef);
};

export const createGalleryResource = async (name: string, fileRefs: FileRef[]) => {
    // createDummyCanvaResource(fileRefs);
    return await svc.resx.createResource("flowos/gallery/resx/Gallery", {
        name: name,
        fileRefs,
        filesNumber: fileRefs.length,
        order: fileRefs.map((f) => f.id),
    });
};
export const createCanvaResource = async (name: string, fileRefs: FileRef[], designId: string) => {
    return await svc.resx.createResource("flowos/canva/resx/Canva", {
        name: name,
        fileRefs,
        filesNumber: fileRefs.length,
        order: fileRefs.map((f) => f.id),
        designId,
    });
};
