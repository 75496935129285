import browserInfo from "@workhorse/api/BrowserInfo";
import {useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useBreakpoint} from "./BreakpointProvider";

function useMobileStore() {
    const breakpoint = useBreakpoint();

    const isMobileUA = browserInfo.isMobile();
    const isMobileBP = ["xs", "sm"].includes(breakpoint);
    const isMobile = isMobileBP || isMobileUA;

    const isTabletUA = browserInfo.isTablet() || (!browserInfo.isMobile() && (browserInfo.isIOS() || browserInfo.isIpadOS()));
    const isTabletBP = ["md"].includes(breakpoint);
    const isTablet = isTabletUA || isTabletBP;

    const isMobileOrTabletUA = isMobileUA || isTabletUA;
    const isMobileOrTabletBP = isMobileBP || isTabletBP;
    const isMobileOrTablet = isMobileOrTabletUA || isMobileOrTabletBP;

    return useMemo(
        () => ({
            isMobile,
            isTablet,
            isMobileOrTablet,
            ua: {
                isMobileUA,
                isTabletUA,
                isMobileOrTabletUA,
            },
            bp: {
                isMobileBP,
                isTabletBP,
                isMobileOrTabletBP,
            },
        }),
        [isMobile, isMobileUA, isMobileBP, isTablet, isTabletUA, isTabletBP, isMobileOrTablet, isMobileOrTabletUA, isMobileOrTabletBP]
    );
}

export const [MobileProvider, useMobile] = createContextProvider(
    {
        name: "Mobile",
    },
    useMobileStore
);
