import Autocomplete, {AutocompleteProps} from "@ui/cdk/Autocomplete";
import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {format} from "date-fns";
import {InputProps} from "@ui/cdk/Input";
import classes from "../styles/TimeInputAutocomplete.module.scss";
import {dateOptions, isPotentialDate} from "../../utils";
import {WithClassName} from "@workhorse/declarations";
import {addMinutes} from "date-fns";

type TimeInputAutocompleteProps = WithClassName & {
    options: Array<dateOptions>;
    value: Date;
    onChange: (newValue: dateOptions) => void;
    id: string;
    onChangeInput: AutocompleteProps["onInputChange"];
    small?: boolean;
    dateFormat: "12h" | "24h";
    allowDatesInThePast?: boolean;
    halfWidth?: boolean;
    label?: InputProps["label"];
    error?: boolean;
    isEnd?: boolean;
    classes?: {
        paper?: string;
    };
    offsetInMin: number;
    disableGrouping?: boolean;
    onClose?: () => void;
    disabled?: boolean;
};

const TimeInputAutocomplete = forwardRef((props: TimeInputAutocompleteProps, ref: any) => {
    const {
        options,
        value,
        onChange,
        id,
        onChangeInput: onChangeInputProp,
        small,
        dateFormat,
        allowDatesInThePast,
        halfWidth,
        label,
        error,
        isEnd,
        offsetInMin,
        disableGrouping,
        disabled,
    } = props;

    const formatStr = dateFormat === "12h" ? "hh:mm a" : "HH:mm";

    const onChangeInternal: AutocompleteProps["onChange"] = (_, val: dateOptions) => {
        onChange(val);
    };

    const currentValue = useMemo(
        () =>
            options.find((o) => {
                return format(addMinutes(o.date, offsetInMin), formatStr) === format(addMinutes(value, offsetInMin), formatStr);
            }),
        [dateFormat, value, options]
    );

    const inputValue = useRef(currentValue ? format(addMinutes(currentValue.date, offsetInMin), formatStr) : "");

    useEffect(() => {
        if (currentValue && format(addMinutes(currentValue.date, offsetInMin), formatStr) !== inputValue.current) {
            inputValue.current = format(addMinutes(currentValue.date, offsetInMin), formatStr);
        }
    }, [currentValue]);

    const [_, setUpdate] = useState(new Date().getTime());

    const onBlur: AutocompleteProps["onBlur"] = (e) => {
        const arg = isPotentialDate(inputValue.current, options, dateFormat, currentValue, offsetInMin);
        if (!arg.isDate) {
            setUpdate(new Date().getTime());
        } else {
            // here condition was !arg.optionExists && arg.date, to confirm if is ok
            if (arg.optionExists) {
                //
            } else {
                onChange({
                    date: arg.date as Date,
                    "12h": format(arg.date as Date, "hh:mm a"),
                    "24h": format(arg.date as Date, "HH:mm"),
                });
            }
        }
    };

    const onChangeInput: AutocompleteProps["onInputChange"] = (e, newValue, reason) => {
        inputValue.current = newValue;
        if (onChangeInputProp) {
            onChangeInputProp(e, newValue, reason);
        }
    };

    // const optionsList = useMemo(() => options.map((o) => o[dateFormat]), [options, dateFormat, value]);
    const groupByFn: AutocompleteProps["groupBy"] = useCallback(
        (option: dateOptions) => {
            // return isToday(option.date) ? "Today" : isTomorrow(option.date) ? "Tomorrow" : format(option.date, "MMM dd, yyyy");
            return format(addMinutes(option.date, offsetInMin), "MMM dd, yyyy");
        },
        [options]
    );

    const getOptionLabel: AutocompleteProps["getOptionLabel"] = useCallback(
        (option: dateOptions) => {
            return option[dateFormat] ? format(addMinutes(option.date, offsetInMin), formatStr) : inputValue.current;
        },
        [options]
    );

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e: any) => {
        if (e.type === "keydown" && e.key === "Enter" && !e.shiftKey) {
            // @ts-ignore
            onBlur(e);
        }
    };

    return (
        <div
            className={cls(
                props.className,
                classes.autocompleteRoot,
                small && "small-input",
                dateFormat === "24h" && !halfWidth && classes.smallerInput,
                halfWidth && classes.halfWidth
            )}
        >
            <Autocomplete
                ref={ref}
                id={id}
                key={id + currentValue?.date.toISOString()}
                options={options}
                value={currentValue}
                disabled={!!disabled}
                onChange={onChangeInternal}
                onInputChange={onChangeInput}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onClose={props.onClose}
                label={label}
                error={error}
                helperText={error === true ? " " : undefined}
                freeSolo
                groupBy={!disableGrouping ? groupByFn : undefined}
                getOptionLabel={getOptionLabel}
                // className="autoComplete"
                // classes={{
                //     paper: cls(classes.paper, dateFormat === "24h" && classes.smallerPaper),
                //     focused: classes.focused,
                //     inputRoot: classes.inputRoot,
                //     popper: classes.popper,
                //     root: classes.root,
                //     input: small ? classes.small : "",
                //     listbox: "mozilla-scrollbar",
                // }}
                classes={{
                    paper: props.classes?.paper,
                }}
                disableClearable
                forcePopupIcon={true}
            />
        </div>
    );
});

export default TimeInputAutocomplete;
