import common from "../eventCommons.module.scss";
import eventClasses from "../event.module.scss";
import classes from "./event-skeleton.module.scss";
import {cls} from "@ui/cdk/util";
import Inject from "@workhorse/providers/Injector";

const EventSkeleton = () => {
    return (
        <>
            <Inject target="inHeader" id="events-dashboard-header" className="overflow-hidden">
                <div className="fullw fullh flex flex-align-center">
                    <div className={cls("ml-10 w-25", classes.skeletonItem)}></div>
                </div>
            </Inject>
            <div className={eventClasses.root}>
                <div className={cls(eventClasses.menu, "p-30")}>
                    <div className={cls("mb-30 w-75", classes.skeletonItem, classes.tall, classes.dark)}></div>

                    <div className={cls("px-20 mb-15 w-25", classes.skeletonItem, classes.dark)}></div>
                    <div className={cls("px-20 mb-15 w-50", classes.skeletonItem, classes.dark)}></div>
                    <div className={cls("px-20 mb-15 w-75", classes.skeletonItem, classes.dark)}></div>
                    <div className={cls("px-20 mb-30 w-25", classes.skeletonItem, classes.dark)}></div>
                    <div className={cls("px-20 mb-15 w-50", classes.skeletonItem, classes.dark)}></div>
                    <div className={cls("px-20 mb-15 w-50", classes.skeletonItem, classes.dark)}></div>
                    <div className={cls("px-20 mb-15 w-25", classes.skeletonItem, classes.dark)}></div>
                </div>
                <div className={common.mainContainer}>
                    <div className={cls("mb-30 w-25", classes.skeletonItem, classes.tall, classes.dark)}></div>

                    <div className={cls("mb-30 w-75", classes.skeletonItem, classes.light, classes.tall)}></div>
                    <div className={cls("mb-30 w-50", classes.skeletonItem, classes.light)}></div>
                    <div className={cls("mb-30 w-100", classes.skeletonItem, classes.tall, classes.light)}></div>
                    <div className={cls("mb-30 w-50", classes.skeletonItem, classes.tall, classes.light)}></div>
                    <div className={cls("mb-30 w-75", classes.skeletonItem, classes.light)}></div>
                    <div className={cls("mb-30 w-25", classes.skeletonItem, classes.tall, classes.light)}></div>
                </div>
            </div>
        </>
    );
};

export default EventSkeleton;
