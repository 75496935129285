import {HostType} from "@generated/data";
import {DrawerLeftWidths} from "@workhorse/declarations";
import {useHostType} from "./HostTypeProvider";
import {useFullScreenState} from "./state";

export const useDrawerLeftWidth = (): DrawerLeftWidths => {
    // we'll be using this hook to compute different drawer widths based on location and session current view
    const isFullScreen = useFullScreenState();
    const hostType = useHostType();

    const isPlayer = hostType === HostType.Player;

    return {
        isClosed: 0,
        isSemiOpen: 80,
        isFullyOpen: isPlayer ? 343 : 256,
        isDetached: isPlayer ? 343 : 256,
        isFullyOpenMobile: "100%",
    };
};
