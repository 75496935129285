import {ReactionType} from "@generated/data";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useMyParticipant} from "./SessionDataProviders";
import {useSessionIdFromRoute} from "./CurrentSessionIdProvider";
import {useMutation} from "@workhorse/dataApi";

const MAX_SHOW = 6;
const LOWER_MAX_SHOW = 4;
const TIMEOUT_TIME = 3000;
const LOWER_EXPIRY = 2000;
const TIMEOUT_DURATION = 1000;

type ReactionStateType = {
    id: string;
    pId: string;
    type: ReactionType;
    timestamp: number;
    isExpired: boolean;
    canShow: boolean;
    checksum?: number;
};

const removeReaction = (r: ReactionStateType, now: number) => {
    return r.canShow && now - r.timestamp > TIMEOUT_TIME;
};

type ReactionStore = Array<ReactionStateType>;

function useSessionReactionsStore() {
    const [reactions, reactionsSetter] = useState<ReactionStore>([]);

    const addReaction = useCallback((pId: string, type: ReactionType, checksum: number) => {
        reactionsSetter((current) => {
            const newId = makeId();
            const newReactions = current.concat({
                id: newId,
                pId,
                type,
                timestamp: new Date().getTime(),
                isExpired: false,
                canShow: current.filter((r) => r.canShow).length < MAX_SHOW,
                checksum,
            });

            const nn = fn(newReactions, true);
            if (nn === undefined) {
                return newReactions;
            }
            return nn;
        });
    }, []);

    const fn = useCallback((reactions: ReactionStore, dontUpdate = false) => {
        const now = new Date().getTime();

        const keepReactions = reactions.filter((r) => {
            const remove = removeReaction(r, now);

            if (!dontUpdate && !r.canShow) {
                r.timestamp = r.timestamp + 450;
            }
            return !remove;
        });

        let keepLen = keepReactions.filter((r) => r.canShow && !r.isExpired).length;

        let newReactions = keepReactions.map((r) => {
            if (!r.canShow && keepLen < MAX_SHOW) {
                r.canShow = true;
                keepLen++;
            }
            return r;
        });

        if (newReactions.length > LOWER_MAX_SHOW) {
            newReactions = newReactions.map((r) => {
                if (r.canShow && !r.isExpired && now - r.timestamp > LOWER_EXPIRY) {
                    r.isExpired = true;
                }
                return r;
            });
        }

        if (dontUpdate) {
            return newReactions;
        }
        reactionsSetter(newReactions);
    }, []);

    useEffect(() => {
        const t =
            reactions.length > 0
                ? setTimeout(() => {
                      fn(reactions);
                  }, 350)
                : null;

        return () => {
            if (t !== null) {
                clearTimeout(t);
            }
        };
    }, [reactions]);

    return useMemo(() => {
        return {reactions, addReaction};
    }, [reactions]);
}

export const [SessionReactionsProvider, useSessionReactions] = createContextProvider(
    {
        name: "SessionReactionsProvider",
        strict: true,
    },
    useSessionReactionsStore
);

export function useParticipantReaction(pId: string) {
    const {reactions} = useSessionReactions();
    const participantReactions = reactions.filter((r) => r.pId === pId);
    const lastReactions = participantReactions.filter((r) => r.timestamp >= new Date().getTime() - TIMEOUT_TIME);

    return useMemo(() => {
        return lastReactions;
    }, [lastReactions]);
}

export const useReaction = () => {
    const myParticipant = useMyParticipant();
    const sessionId = useSessionIdFromRoute() ?? "";

    const participantId = myParticipant.id;

    const t = useRef<NodeJS.Timeout | null>(null);

    const participantReactions = useParticipantReaction(participantId);

    const [sendReaction] = useMutation("SendReactionDocument");

    useEffect(() => {
        return () => {
            if (t.current) {
                clearTimeout(t.current);
            }
        };
    }, []);

    const handleSendReaction = useCallback(
        (type: ReactionType) => {
            sendReaction({
                variables: {
                    reactionType: type,
                    sessionId,
                },
            }).catch((e) => console.log(e));
        },
        [sendReaction, sessionId]
    );

    const triggerReaction = useCallback(
        (type: ReactionType) => {
            if (t.current) {
                clearTimeout(t.current);
            }

            t.current = setTimeout(
                () => {
                    handleSendReaction(type);

                    t.current = null;
                },
                participantReactions.length > 0 ? TIMEOUT_DURATION : 0
            );
        },
        [handleSendReaction, participantReactions.length]
    );

    return useMemo(() => {
        return {triggerReaction};
    }, [triggerReaction]);
};
