import descriptor, {ArtifactTag, default as ArtifactsMap} from "@generated/artifacts";
import {ChooseToolStep, ResourceFragment, MyProductToolDocument} from "@generated/data";
import env from "@generated/environment";
import Typography from "@ui/cdk/Typography";
import {ForgedArtifact} from "@workhorse/pages/designer/api/useDesignerArtifacts";
import classes from "./style/AgendaItemArtifact.module.scss";
import {cls} from "@ui/cdk/util/util";
import {useState, memo} from "@workhorse/api/rendering";
import Tooltip from "@ui/cdk/Tooltip";
import {getToolName} from "@workhorse/api/utils/getToolName";
import {useResourceResult} from "@workhorse/api/resx/hooks";
import {ResourceFullWithContent} from "@workhorse/api/resx/utils";
import ShareIcon from "@material-ui/icons/Share";
import {useQuery} from "@workhorse/api/data";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@ui/cdk/Button";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";

type AgendaItemTitleProps = {
    artifact: ForgedArtifact;
    onClick?: (step: ChooseToolStep, agendaItemId: string, artifactId?: string) => void;
    readOnly?: boolean;
    agendaItemId: string;
    isAgendaShared?: boolean;
    inEventSettings?: boolean;
    sessionId: string;
    hasSpeakerNotes: boolean;
    removeTool?: () => void;
};

const artifactsWithoutResources: ArtifactTag[] = [
    "flowos/blackboard",
    "flowos/form",
    "flowos/break",
    "flowos/miro",
    "flowos/conference",
    "flowos/google-docs",
    "flowos/google-sheets",
    "flowos/google-slides",
    "flowos/google-forms",
    "flowos/my-product-tool",
];

const AgendaItemTitle = (props: AgendaItemTitleProps) => {
    const {artifact, onClick, agendaItemId, inEventSettings, sessionId, hasSpeakerNotes, removeTool} = props;
    const {result} = useResourceResult(artifact.resourceResults?.[0]?.id);
    const resource = result?.resource;
    const resourceContent = result?.resource?.content as any;

    const isReadOnly = props.readOnly && !props.isAgendaShared;

    const myProductToolArtifactExists = artifact && artifact.artifactId === "flowos/my-product-tool";

    const {data: myProductToolsQuery} = useQuery(MyProductToolDocument, {
        variables: {
            myProductToolId: myProductToolArtifactExists && artifact?.data?.myProductToolId,
            sessionId: sessionId,
        },
        skip: !artifact?.data?.myProductToolId,
    });

    const onClickArtifact = () => {
        if (isReadOnly) {
            return;
        }
        onClick?.(ChooseToolStep.Preview, agendaItemId, artifact.id);
    };

    const toolName = myProductToolArtifactExists
        ? {nameByResource: myProductToolsQuery?.myProductTool?.title, nameByDescriptor: null}
        : getToolName(artifact.id, artifact.artifactId as ArtifactTag, result?.resource as unknown as ResourceFullWithContent);

    const toolIcon = myProductToolArtifactExists
        ? myProductToolsQuery?.myProductTool?.iconUrl
        : `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[artifact.artifactId]?.meta.icon}.svg`;

    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <div className="flex flex-row gap-6">
            <Tooltip placement="top" arrow title={showTooltip ? resourceContent?.name ?? artifact.name : ""}>
                <Button
                    data-id="agenda-item-share"
                    // we add tabIndex so it can be seen as a relatedTarget when bluring an input
                    tabIndex={0}
                    className={cls(classes.root, isReadOnly && classes.readOnly, "editing_" + agendaItemId)}
                    startIcon={
                        <div className={cls(classes.icon, resource?.isPublic ? "mr-5" : "")}>
                            <img
                                src={toolIcon}
                                style={{
                                    width: 21,
                                    height: 21,
                                }}
                            />

                            {resource?.isPublic ? <ShareIcon className={classes.iconSharedIndicator} /> : null}
                        </div>
                    }
                    size="small"
                    variant="plain"
                >
                    <Typography
                        ref={(ref) => {
                            if (!ref) return;
                            const isOverflowing = ref.offsetWidth < ref.scrollWidth;
                            if (isOverflowing) {
                                setShowTooltip(true);
                            }
                        }}
                        className={cls(classes.artifactTitle, isReadOnly && classes.readOnly, "artifactTitle")}
                        onClick={onClickArtifact}
                    >
                        {toolName.nameByResource || artifact?.name || toolName.nameByDescriptor}
                    </Typography>
                </Button>
            </Tooltip>
            {!isReadOnly && (
                <div className={classes.removeArtifactIcon} onClick={removeTool}>
                    <RemoveCircleOutlineRoundedIcon />
                </div>
            )}
        </div>
    );
};

export default memo(AgendaItemTitle);
