import {ParticipantInviteStatus, SessionLifecycle} from "@generated/data";
import {differenceInMinutes} from "date-fns";
import {useReactiveVar} from "../data";
import {useCallback, useEffect, useState} from "../rendering";
import {useHistory} from "../routing";
import toast from "../toast";
import {TrackingEventCategory, useTrackingEvents} from "../tracking";
import {BaseActionInfo, BaseTooltipActionInfo, RecurrentEventActions} from "./definitions";
import {executeReschedule} from "./reschedule";
import {
    createSessionOccurrence,
    deleteSession,
    deleteSessionOccurrence,
    deleteSessionOccurrenceAndFollowing,
    removeYourselfFromSession,
    updateInviteStatus,
} from "./session";
import {baseActionInfo, baseTooltipEventInfo, discardEventTooltip, requestEventAction} from "./state";
import {eventHasInstance, eventIsRecurrent} from "./utils";

export type UseBaseActionInfo = () => {
    action: false | BaseActionInfo;
    tooltipAction: false | BaseTooltipActionInfo;

    edit: (recurrentEventAction?: RecurrentEventActions) => void;
    delete: (recurrentEventAction?: RecurrentEventActions) => void;
    rsvp: (recurrentEventAction?: RecurrentEventActions) => void;
    reschedule: (recurrentEventAction?: RecurrentEventActions) => void;
    play: () => void;

    discard: () => void;
    discardTooltip: () => void;
};

export const useBaseAction: UseBaseActionInfo = () => {
    const actionInfo = useReactiveVar(baseActionInfo);
    const tooltipAction = useReactiveVar(baseTooltipEventInfo);

    const history = useHistory<{dontUseCachedData?: boolean}>();
    const [trackEvent] = useTrackingEvents();
    const [state, setState] = useState<BaseActionInfo | false>(false);

    const discard = useCallback(() => {
        baseActionInfo(false);
        setState(false);
    }, []);

    const rsvp = useCallback(
        async (recurrentEventAction?: RecurrentEventActions, reason?: string) => {
            if (!actionInfo || actionInfo?.type !== "rsvp") {
                return;
            }

            discard();

            const {event, status} = actionInfo;

            if (!eventIsRecurrent(event) || recurrentEventAction === "all-sessions") {
                await updateInviteStatus(event.instanceOfRecurrence?.sessionId || event.recurrenceParentId || event.id, status, reason);
            } else {
                if (!event.instanceId) {
                    if (event.occurrenceId) {
                        const result = await createSessionOccurrence(event.id, event.occurrenceId);

                        if (result.data?.createSessionOccurrence?.id) {
                            await updateInviteStatus(result.data?.createSessionOccurrence?.id, status);
                        }
                    }
                } else {
                    await updateInviteStatus(event.instanceId, status);
                }
            }
        },
        [discard, actionInfo]
    );

    const reschedule = useCallback(
        async (recurrenceAction: RecurrentEventActions) => {
            if (!actionInfo || actionInfo?.type !== "reschedule") {
                return;
            }

            discard();

            try {
                const durationChanged =
                    differenceInMinutes(actionInfo.to.endDate, actionInfo.to.startDate) !==
                    differenceInMinutes(actionInfo.from.endDate, actionInfo.from.startDate);

                await executeReschedule(actionInfo, recurrenceAction);

                toast(
                    `Session${recurrenceAction === "all-sessions" ? "s" : ""} successfully ${
                        actionInfo.resize && durationChanged ? "resized" : "rescheduled"
                    }.`,
                    {
                        type: "success",
                        position: "top",
                        duration: 3000,
                    }
                );
            } catch (err) {
                console.log(err);

                toast(
                    `There was an error when ${actionInfo.resize ? "resizing" : "rescheduling"} session${
                        recurrenceAction === "all-sessions" ? "s" : ""
                    }`,
                    {
                        type: "error",
                        position: "top",
                        duration: 5000,
                    }
                );

                actionInfo.revert();
            }
        },
        [discard, actionInfo]
    );

    const edit = useCallback(
        async (recurrentEventAction?: RecurrentEventActions) => {
            if (!actionInfo || actionInfo.type !== "edit") {
                return;
            }

            discard();

            const {event} = actionInfo;
            if (eventIsRecurrent(event)) {
                const doEdit = async (id: string, parentId: string) => {
                    if (recurrentEventAction === "this-session") {
                        if (eventIsRecurrent(event) && !eventHasInstance(event) && event.occurrenceId) {
                            const instanceData = await createSessionOccurrence(event.id, event.occurrenceId);

                            if (instanceData.data?.createSessionOccurrence.id) {
                                requestEventAction({
                                    type: "plan",
                                    event: {
                                        ...event,
                                        id: instanceData.data?.createSessionOccurrence.id,
                                    },
                                });
                            }
                        } else if (eventIsRecurrent(event) && eventHasInstance(event)) {
                            requestEventAction({
                                type: "plan",
                                event,
                            });
                        }
                    } else if (recurrentEventAction === "all-sessions") {
                        requestEventAction({
                            type: "plan",
                            event: {
                                ...event,
                                id: parentId,
                            },
                        });
                    } else if (recurrentEventAction === "this-and-following-sessions") {
                        // TODO: to be implemented
                    }
                };

                doEdit(event.id, event.instanceOfRecurrence?.sessionId || event.recurrenceParentId || event.id);
            } else {
                requestEventAction({
                    type: "plan",
                    event,
                });
            }
        },
        [discard, actionInfo]
    );

    const removeYourself = useCallback(
        async (recurrentEventAction?: RecurrentEventActions) => {
            if (!actionInfo || actionInfo.type !== "removeYourself") {
                return;
            }

            discard();

            const {event} = actionInfo;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let promise: Promise<any> | null = null;
            let successMessage: JSX.Element = (
                <p>
                    Session <strong>successfully removed!</strong>
                </p>
            );

            let errorMessage: JSX.Element = <p>There was a problem removing your session!</p>;

            if (recurrentEventAction === "this-session") {
                let sessionId: string | undefined;

                if (eventIsRecurrent(event) && event.occurrenceId && eventHasInstance(event)) {
                    sessionId = event.id;
                }

                if (eventIsRecurrent(event) && event.occurrenceId && event.recurrenceParentId && !eventHasInstance(event)) {
                    const instanceData = await createSessionOccurrence(event.recurrenceParentId, event.occurrenceId);

                    sessionId = instanceData.data?.createSessionOccurrence.id;
                }

                if (!eventIsRecurrent(event) || !event.occurrenceId) {
                    sessionId = event.id;
                }

                if (sessionId) {
                    promise = removeYourselfFromSession(sessionId, undefined, undefined);
                }
            }

            if (recurrentEventAction === "all-sessions") {
                if (eventIsRecurrent(event) && event.occurrenceId) {
                    promise = removeYourselfFromSession(
                        event.instanceOfRecurrence?.sessionId ?? event.recurrenceParentId ?? event.id,
                        undefined,
                        false
                    );
                }

                successMessage = (
                    <p>
                        Sessions <strong>successfully removed!</strong>
                    </p>
                );
                errorMessage = <p>There was a problem removing your sessions!</p>;
            }

            if (recurrentEventAction === "this-and-following-sessions") {
                if (eventIsRecurrent(event) && event.occurrenceId) {
                    promise = removeYourselfFromSession(
                        event.instanceOfRecurrence?.sessionId ?? event.recurrenceParentId ?? event.id,
                        undefined,
                        true
                    );
                }

                successMessage = (
                    <p>
                        Sessions <strong>successfully removed!</strong>
                    </p>
                );
                errorMessage = <p>There was a problem removing your sessions!</p>;
            }

            try {
                if (promise) {
                    await promise;
                }

                toast(successMessage, {
                    type: "success",
                    duration: 3000,
                });
            } catch (err) {
                console.error(err);
                console.error("The error above is related to removing a session");
                console.log("The error above is related to", "actionInfo", actionInfo, "and", "recurrentEventAction", recurrentEventAction);

                toast(errorMessage, {
                    type: "error",
                    duration: 5000,
                });
            }
        },
        [discard, actionInfo]
    );

    const deleteEvent = useCallback(
        async (recurrentEventAction?: RecurrentEventActions) => {
            if (!actionInfo || (actionInfo.type !== "delete" && actionInfo.type !== "delete_task")) {
                return;
            }

            discard();

            const {event} = actionInfo;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let promise: Promise<any> | null = null;
            let successMessage: JSX.Element = (
                <p>
                    Session <strong>successfully deleted!</strong>
                </p>
            );

            let errorMessage: JSX.Element = <p>There was a problem deleting your session!</p>;

            if (recurrentEventAction === "this-session") {
                if (eventIsRecurrent(event) && event.occurrenceId) {
                    // Action `this-session` is sent for both recurrent & non-recurrent sessions
                    promise = deleteSessionOccurrence(
                        event.instanceOfRecurrence?.sessionId ?? event.recurrenceParentId ?? event.id,
                        event.occurrenceId
                    );
                } else {
                    promise = deleteSession(event.id);
                }
            } else if (recurrentEventAction === "all-sessions") {
                // this is only sent back for recurrent sessions
                // even if the session is recurrent, we delete the parent session
                // so that everything is cleaned up
                promise = deleteSession(event.instanceOfRecurrence?.sessionId ?? event.recurrenceParentId ?? event.id);

                successMessage = (
                    <p>
                        Sessions <strong>successfully deleted!</strong>
                    </p>
                );
                errorMessage = <p>There was a problem deleting your sessions!</p>;
            } else if (recurrentEventAction === "this-and-following-sessions" && event.occurrenceId) {
                promise = deleteSessionOccurrenceAndFollowing(event.id, event.occurrenceId);

                successMessage = (
                    <p>
                        Sessions <strong>successfully deleted!</strong>
                    </p>
                );
                errorMessage = <p>There was a problem deleting your sessions!</p>;
            }

            try {
                if (promise) {
                    await promise;
                }

                toast(successMessage, {
                    type: "success",
                    duration: 3000,
                });
            } catch (err) {
                console.error(err);
                console.error("The error above is related to deleting a session");
                console.log("The error above is related to", "actionInfo", actionInfo, "and", "recurrentEventAction", recurrentEventAction);

                toast(errorMessage, {
                    type: "error",
                    duration: 5000,
                });
            }
        },
        [discard, actionInfo]
    );

    const play = useCallback(async () => {
        if (!actionInfo || actionInfo.type !== "play") {
            return;
        }

        discard();

        const {event} = actionInfo;
        let isPastSession = event.lifecycle === SessionLifecycle.Ended;

        if (eventIsRecurrent(event) && !eventHasInstance(event)) {
            isPastSession = event.plannedEnd < new Date();
        }

        if (eventIsRecurrent(event) && !eventHasInstance(event) && event.recurrenceParentId && event.occurrenceId) {
            createSessionOccurrence(event.recurrenceParentId, event.occurrenceId).then((result) => {
                trackEvent("join_session_tooltip", {event_category: TrackingEventCategory.SessionActions});

                const newId = result.data?.createSessionOccurrence?.id;

                if (newId) {
                    history.push(`/session/${result.data?.createSessionOccurrence?.id}`, {
                        dontUseCachedData: true,
                    });
                }
            });
        } else {
            const path = `${!isPastSession ? "" : "/memory"}/session/${event.id}`;

            if (isPastSession) {
                history.push(path, {
                    dontUseCachedData: true,
                });
            } else {
                history.push(path, {
                    dontUseCachedData: true,
                });
            }
            if (!isPastSession) {
                trackEvent("join_session_tooltip", {event_category: TrackingEventCategory.SessionActions});
            }
        }
    }, [discard, actionInfo, history, trackEvent]);

    useEffect(() => {
        if (!actionInfo) {
            return;
        }

        switch (actionInfo.type) {
            case "edit": {
                const isRecurrent = eventIsRecurrent(actionInfo.event);

                if (!isRecurrent) {
                    edit("this-session");
                    discardEventTooltip();
                } else {
                    setState(actionInfo);
                }

                break;
            }

            case "play": {
                play();
                discardEventTooltip();

                break;
            }

            case "rsvp": {
                const isRecurrent = eventIsRecurrent(actionInfo.event);
                const isCancelingBooking = actionInfo.event.isBooking && actionInfo.status === ParticipantInviteStatus.Declined;

                if (isRecurrent || isCancelingBooking) {
                    setState(actionInfo);
                } else {
                    rsvp();
                }
                break;
            }

            default: {
                setState(actionInfo);
                break;
            }
        }
    }, [actionInfo, play, rsvp, edit]);

    return {
        action: state,
        tooltipAction,
        edit,
        delete: deleteEvent,
        delete_task: deleteEvent,
        removeYourself,
        play,
        reschedule,
        rsvp,
        discard,
        discardTooltip: discardEventTooltip,
    };
};
