import Menu from "@ui/core/components/Menu";
import SvgIcon from "@ui/core/components/SvgIcon";
import React, {useCallback, useRef, useState} from "@workhorse/api/rendering";
import Picker from "emoji-picker-react";
import {ReactComponent as ChatIconEmoji} from "../../../../frontend/assets/media/ChatIconEmoji.svg";
import {ToolbarGenericProps, useClickOutside} from "../api";
import {ToolbarItem} from "./components/ToolbarItem";
import classes from "./styles/Toolbar.module.scss";

type ToolbarEmojiState = {
    disabled: boolean;
};

export const ToolbarEmoji = (props: ToolbarGenericProps) => {
    const {editor, onActionDone} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const ref = useRef<HTMLButtonElement>(null);

    const toggleEmojiPicker = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const onEmojiClick = (event, emojiObject) => {
        event.stopPropagation();
        editor.chain().focus().insertContent(emojiObject.emoji).run();
        setAnchorEl(null);
        onActionDone();
    };

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                event.target &&
                !ref.current?.contains(event.target as Node) &&
                !document.getElementById("emoji-picker-inner")?.contains(event.target as Node) &&
                anchorEl
            ) {
                setAnchorEl(null);
            }
        },
        [anchorEl]
    );

    useClickOutside("click", handleClickOutside);
    const onMenuClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
    };

    return (
        <>
            <ToolbarItem
                className={classes.button}
                tooltip="Emoji"
                onClick={toggleEmojiPicker}
                disabled={!editor.isEditable}
                isMobile={props.isMobile}
                ref={ref}
            >
                <SvgIcon viewBox="0 0 24 24">
                    <ChatIconEmoji />
                </SvgIcon>
            </ToolbarItem>

            <Menu
                id="emoji-picker-menu"
                anchorEl={anchorEl}
                keepMounted
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
                MenuListProps={{
                    className: classes.menuListRoot,
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                getContentAnchorEl={undefined}
                PaperProps={{
                    className: classes.paperRoot,
                }}
            >
                {/* https://github.com/mui-org/material-ui/issues/15903#issuecomment-723807594 */}
                <div className={classes.picker} id="emoji-picker-inner">
                    {anchorEl ? (
                        <Picker disableSearchBar disableSkinTonePicker disableAutoFocus onEmojiClick={onEmojiClick} native />
                    ) : null}
                </div>
            </Menu>
        </>
    );
};
