import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import classes from "./style/ConsentToRecordNotification.module.scss";

import {useSessionInfo} from "@workhorse/api/conference2";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {consentToBeingRecorded} from "../../pre-join-screen/api";

import {updateUnreadMessagesCount} from "@artifacts/chat/frontend/utils";
import {updateNewQuestionsCount} from "@artifacts/qa/frontend/utils";
import apollo from "@workhorse/api/apollo";
import {useHistory} from "@workhorse/api/routing";
import {disableNativePopup} from "@workhorse/components/LeavePageNative";
import {useUserInfo} from "@workhorse/providers/User";
// import {rbac} from "@workhorse/api/user";
import {SessionRecordingState, SessionRecordingType} from "@generated/data";

interface ConsentToRecordNotificationProps {
    // handleConfirm: () => void;
    // open: boolean;
    // onNotificationClose: () => void;
    sessionId: string;
    isAgenda?: boolean;
    inLobby?: boolean;
}

const ConsentToRecordNotification = (props: ConsentToRecordNotificationProps) => {
    const {t} = useTranslation();
    const {sessionId, inLobby} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    const currentParticipant = useCurrentParticipant();
    const session = useSessionInfo();
    const user = useUserInfo();
    const isGuest = user.isGuest;
    // const isAssistant = rbac(currentParticipant, "session.isAssistant");

    const transcriptionActive = session.transcriptionActive;
    const {isActive, recordingState, recordingType} = useRecordingProcessor();
    const recordingActive = isActive || recordingState === SessionRecordingState.Starting;

    const localStorageRecordingStarted = localStorage.getItem("transcript-or-recording-started");
    let isParticipantWhoStarted = false;

    if (localStorageRecordingStarted) {
        const sessionWithRecording = localStorageRecordingStarted.split(" ")[0];
        const participantWhoStartedRecordingId = localStorageRecordingStarted.split(" ")[1];

        isParticipantWhoStarted =
            sessionWithRecording === sessionId &&
            participantWhoStartedRecordingId === currentParticipant.id &&
            !currentParticipant.consentedToRecord;
    }

    useEffect(() => {
        if (isParticipantWhoStarted) {
            consentToBeingRecorded(sessionId);
        }
    }, [sessionId, isParticipantWhoStarted]);

    if (user.isRecorder) {
        return null;
    }

    if (!currentParticipant || currentParticipant.consentedToRecord) {
        return null;
    }

    if (!transcriptionActive && !recordingActive) {
        return null;
    }

    if (isParticipantWhoStarted) {
        return null;
    }

    const handleConsentToBeingRecorded = () => {
        setLoading(true);
        consentToBeingRecorded(sessionId);
    };

    const handleLeaveSession = () => {
        disableNativePopup(true);
        updateUnreadMessagesCount(sessionId, undefined, true);
        updateNewQuestionsCount(true);

        if (!isGuest) {
            if (sessionId) {
                apollo.cache.evict({id: sessionId});
                apollo.cache.gc();
            }
        }

        setTimeout(() => {
            if (isGuest) {
                history.push({
                    pathname: `/feedback/${sessionId}/${currentParticipant.id}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });
            } else {
                history.push({
                    pathname: `/feedback/${sessionId}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });
            }
        }, 0);
    };

    const titleKey = recordingActive
        ? recordingType === SessionRecordingType.Livestream
            ? "player.notifications.consent.livestream_started"
            : recordingType === SessionRecordingType.Recording
            ? "player.notifications.consent.recording_started"
            : "player.notifications.consent.livestream_recording_started"
        : "player.notifications.consent.transcription_started";

    const descriptionKey = recordingActive
        ? recordingType === SessionRecordingType.Livestream
            ? "player.notifications.consent.livestream_started.description"
            : recordingType === SessionRecordingType.Recording
            ? "player.notifications.consent.recording_started.description"
            : "player.notifications.consent.livestream_recording_started.description"
        : "player.notifications.consent.transcription_started.description";

    return (
        <div
            data-id="tool-confirmation-notification"
            className={classes.root}
            onMouseEnter={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
            onMouseOver={(e) => e.stopPropagation()}
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={classes.circularMessageWrapper}>
                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            {t(titleKey)}
                        </Typography>
                        <Typography variant="base" className={classes.description}>
                            {t(descriptionKey)}
                        </Typography>
                    </div>
                </div>
                <div className={cls(classes.buttonsContainer)}>
                    <Button
                        data-id="cancel"
                        variant="quaternary"
                        onClick={handleLeaveSession}
                        className={classes.notificationSecondaryButton}
                    >
                        {t("participant.leave_session.leave_session") ?? "Leave session"}
                    </Button>
                    <Button data-id="confirm" onClick={handleConsentToBeingRecorded} loading={loading} disabled={loading}>
                        {t("g.confirm") ?? "Confirm"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConsentToRecordNotification;
