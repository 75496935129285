import {useBreakpoint} from "@workhorse/providers/BreakpointProvider";
import calendarClasses from "../styles/BookCalendar.module.scss";
import timeSlotsClasses from "../styles/BookTimeSlots.module.scss";
import dateTimeClases from "../styles/BookEventDateTime.module.scss";
import classes from "../styles/BookEventDateTimeSkeleton.module.scss";
import {cls} from "@ui/cdk/util";

const CalendarSkeleton = () => {
    return (
        <div className={calendarClasses.root}>
            <div className={calendarClasses.header}>
                <div className={cls(classes.calendarButton, classes.skeletonItem)} />
                <div className={cls(calendarClasses.title, classes.text, classes.skeletonItem)} />
                <div className={cls(classes.calendarButton, classes.skeletonItem)} />
            </div>
            <div className={calendarClasses.weekDays}>
                {Array.from(new Array(7)).map((_, i) => (
                    <div key={`day-${i}`} className={cls(calendarClasses.dayOfWeek, classes.skeletonItem)} />
                ))}
            </div>
            <div className={cls(calendarClasses.wrapper, classes.calendarDays)}>
                {Array.from(new Array(28)).map((_, i) => (
                    <div key={`date-${i}`} className={cls(calendarClasses.day, classes.skeletonItem)} />
                ))}
            </div>
        </div>
    );
};

const TimeSlotsSkeleton = () => {
    return (
        <div className={timeSlotsClasses.root}>
            {Array.from(new Array(4)).map((_, i) => (
                <div key={`slot-${i}`} className={cls(classes.timeSlot, classes.skeletonItem)} />
            ))}
        </div>
    );
};

const BookEventDateTimeSkeleton = () => {
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "sm";

    return isMobile ? (
        <div>
            <div className={dateTimeClases.calendarHeader}></div>
            <CalendarSkeleton />
            <div className={dateTimeClases.footer}></div>
        </div>
    ) : (
        <>
            <div className={dateTimeClases.calendar}>
                <div className={dateTimeClases.calendarContent}>
                    <div className={dateTimeClases.dateSlotContent}>
                        <CalendarSkeleton />
                    </div>
                    <div className={dateTimeClases.separator} />
                    <div className={dateTimeClases.timeSlotContent}>
                        <div className={dateTimeClases.date}>
                            <div className={cls(classes.text, classes.skeletonItem)} />
                        </div>
                        <TimeSlotsSkeleton />
                    </div>
                </div>
            </div>
            <div className={dateTimeClases.footer}>
                <div className={dateTimeClases.timeZone}>
                    <span className={cls(classes.text, classes.skeletonItem)}></span>
                </div>
                <div className={dateTimeClases.footerActions}>
                    <div className={cls(classes.button, classes.skeletonItem)}></div>
                </div>
            </div>
        </>
    );
};

export default BookEventDateTimeSkeleton;
