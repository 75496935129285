import Button from "@ui/cdk/Button";
import {ReactComponent as AddTemplate} from "../../../../assets/media/add-template.svg";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import Typography from "@ui/cdk/Typography";
import classes from "./style/EventAgendaEmptyState.module.scss";
import {cls} from "@ui/cdk/util";

const EventAgendaEmptyState = (props: {readOnly?: boolean; toggleAgendaPalette?: () => void; backstage?: boolean}) => {
    return (
        <div className={cls("fullw flex flex-col flex00-100 flex-center-all mt-10", classes.root)}>
            <AddTemplate className="mb-10" />
            {props.readOnly ? null : (
                <>
                    <div className="mb-20 flex flex-col flex-center-all">
                        <Typography fontWeight="bold" className={classes.title}>
                            {props.backstage
                                ? "While backstage is running, agenda can only be edited from backstage"
                                : "Stay on track with an agenda"}
                        </Typography>
                        {props.backstage ? null : (
                            <Typography className={classes.subtitle}>
                                An agenda adds structure to your session and loads all your files and resources when you need them.
                            </Typography>
                        )}
                    </div>
                    {props.backstage ? null : (
                        <Button variant="plain" onClick={props.toggleAgendaPalette} data-id="add-agenda-button">
                            <OpenInNewRoundedIcon className="mr-5" /> Add agenda
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

export default EventAgendaEmptyState;
