import {getLocalTimezone} from "@common/utils";
import {ParticipantInviteStatus, SessionLifecycle, SessionSettingsSource} from "@generated/data";
import {dateFormat} from "@ui/cdk/DateFormat";
import {IntervalViewMode} from "@workhorse/api/calendar";
import designer from "@workhorse/api/designer";
import {CreateSessionInput} from "@workhorse/api/designer/lib/actions/session/create-session";
import {makeId} from "@workhorse/api/designer/lib/utils";
import extension from "@workhorse/api/extension";
import {getInitialSessionFlags} from "@workhorse/components/CreatePlannedSession/utils";
import {generateSessionName} from "@workhorse/util";
import {differenceInSeconds, isSameYear, isToday, isTomorrow, isYesterday} from "date-fns";
import {t} from "i18next";

export enum SessionPanelViewMode {
    MINIMIZED,
    MAXIMIZED,
}

export enum LocalStorageParams {
    interval = "home_interval",
    orderBy = "home_order_by",
    sortOrder = "home_filter_sort_order",
    participantsEmails = "home_filter_participants_emails",
    live = "home_filter_live",
    ended = "home_filter_ended",
    upcoming = "home_filter_upcoming",
    participantInviteStatuses = "home_filter_invite_statuses",
}

export type ScrollDirection = "onTop" | "onBottom";

export type PrevFetch = {
    countTop: number | undefined;
    countBottom: number | undefined;
    firstEventId: string | undefined;
    lastEventId: string | undefined;
    scrollDirection: ScrollDirection;
};

export type SessionFilters = {
    planned?: boolean;
    quick?: boolean;
    ended?: boolean;
    live?: boolean;
    upcoming?: boolean;
    participantsEmails?: string[];
    participantInviteStatuses?: ParticipantInviteStatus[];
    workspaceId?: string;
};

export const defaultFilters: SessionFilters = {
    planned: true,
    quick: true,
    ended: true,
    live: true,
    upcoming: true,
    participantsEmails: [],
    participantInviteStatuses: [ParticipantInviteStatus.NeedsAction, ParticipantInviteStatus.Tentative, ParticipantInviteStatus.Accepted],
};

export const defaultPrevFetch: PrevFetch = {
    countTop: undefined,
    countBottom: undefined,
    firstEventId: undefined,
    lastEventId: undefined,
    scrollDirection: "onTop",
};

export const formatDay = (intervalViewMode: IntervalViewMode, startAt?: Date | null): string => {
    if (!startAt || intervalViewMode === IntervalViewMode.DAY) {
        return "";
    }
    if (isYesterday(startAt)) {
        return "Yesterday";
    }
    if (isToday(startAt)) {
        return "Today";
    }
    if (isTomorrow(startAt)) {
        return "Tomorrow";
    }

    switch (intervalViewMode) {
        case IntervalViewMode.WEEK:
            return dateFormat(startAt, "weekday");
        case IntervalViewMode.MONTH:
            return dateFormat(startAt, ["dayOfMonth-ss"]);
        case IntervalViewMode.YEAR:
            return dateFormat(startAt, ["dayOfMonth", "monthShort"]);
        default:
            return dateFormat(startAt, ["dayOfMonth", "monthShort", !isSameYear(new Date(startAt), new Date()) && "year"]);
    }
};

export const formatTimeLeft = (startAt: Date, _endTime?: Date, short?: boolean, _startsInText?: string): string => {
    const startsInText = _startsInText ?? `${t("g.time.starts_in")} `;

    const endTime = _endTime ?? new Date();

    const diffSec = differenceInSeconds(new Date(startAt), new Date(endTime));
    let diff = Math.floor(diffSec / 60);

    if (diff === 0) {
        diff = 1;
    }
    if (diff < 0) {
        diff = -1 * diff;
    }

    if (diff < 60) {
        return startsInText + `${diff}${short ? t("g.time.m") : " " + (diff === 1 ? t("g.time.minute") : t("g.time.minutes"))}`;
    }

    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;
    if (hours < 24) {
        return (
            startsInText +
            `${hours}${short ? t("g.time.h") : " " + (hours === 1 ? t("g.time.hour") : t("g.time.hours"))} ${
                minutes > 0 ? `${minutes}${short ? t("g.time.m") : " " + (minutes === 1 ? t("g.time.minute") : t("g.time.minutes"))}` : ""
            }`
        );
    }

    const days = Math.floor(hours / 24);
    const hoursLeft = hours % 24;
    if (days < 30) {
        return (
            startsInText +
            `${days}${short ? t("g.time.d") : " " + (days === 1 ? t("g.time.day") : t("g.time.days"))} ${
                hoursLeft > 0 ? `${hoursLeft}${short ? t("g.time.h") : " " + (hoursLeft === 1 ? t("g.time.hour") : t("g.time.hours"))}` : ""
            }`
        );
    }

    const months = Math.floor(days / 30);
    const daysLeft = days % 30;
    if (months < 12) {
        return (
            startsInText +
            `${months} ${months === 1 ? t("g.time.month") : t("g.time.months")} ${
                daysLeft > 0 ? `${t("g.and")} ${daysLeft} ${daysLeft === 1 ? t("g.time.day") : t("g.time.days")}` : ""
            }`
        );
    }

    const years = Math.floor(months / 12);
    const monthsLeft = months % 12;
    return (
        startsInText +
        `${years} ${years === 1 ? t("g.time.year") : t("g.time.years")} ${
            monthsLeft > 0 ? `${t("g.and")} ${monthsLeft} ${monthsLeft === 1 ? t("g.time.month") : t("g.time.months")}` : ""
        }`
    );
};

export async function createInstantSession(hasAgenda?: boolean) {
    const name = generateSessionName({quickSession: true});

    const isExtension = extension.isExtensionEnabled();

    designer.clearPersistentStorage();
    designer.clearActionsHistory();
    designer.clearSessionStorage();

    const sessionFlags = await getInitialSessionFlags(SessionSettingsSource.Instant);
    const createdSessionId = designer.api.session.create({
        session: {
            name,
            quickSession: true,
            lifecycle: SessionLifecycle.Started,
            timeDependency: false,
            startAt: new Date(),
            transcriptionActive: sessionFlags.autoTranscribing,
            ...sessionFlags,
            __typename: "Session",
        },
        hasAgenda: hasAgenda ?? false,
    });

    designer.state.initializeOrResetState(createdSessionId);

    if (isExtension) {
        extension.drawer.close();
        await designer.commitCreateSession().catch(() => null);
    }

    return {createdSessionId, autoRecording: sessionFlags.autoRecording};
}

export async function createPlannedSession(args?: Partial<CreateSessionInput["session"]>) {
    const currentTimezone = getLocalTimezone();

    const generatedName = generateSessionName();

    const sessionFlags = await getInitialSessionFlags(SessionSettingsSource.Planned);
    /**
     * IMPORTANT
     * never create a new session with startAt and plannedEnd
     * the creator will automatically handle that if the session is planned
     */

    const id = makeId();

    designer.state.initializeOrResetState(id);

    const createdSessionId = designer.api.session.create({
        session: {
            id,
            name: "",
            generatedName,
            timeZone: currentTimezone,
            timeDependency: true,
            ...args,
            ...sessionFlags,
            __typename: "Session",
        },
    });

    return createdSessionId;
}

export async function createTaskSession(args?: Partial<CreateSessionInput["session"]>) {
    const currentTimezone = getLocalTimezone();

    const sessionFlags = await getInitialSessionFlags(SessionSettingsSource.Planned);

    const id = makeId();

    designer.state.initializeOrResetState(id);

    const createdSessionId = designer.api.session.create({
        session: {
            id,
            name: "",
            generatedName: "New Task",
            timeZone: currentTimezone,
            timeDependency: true,
            isTask: true,
            ...args,
            ...sessionFlags,
            __typename: "Session",
        },
    });

    return createdSessionId;
}

export const GET_STARTED_WIDGET_RELEASE_DATE = "2024-04-05T09:45:55.230Z";
export const GET_STARTED_TOUR_VIDEO_RELEASE_DATE = "2024-03-29T10:59:59.653Z";
