import {ResourceLocator} from "@api/resx/locator";
import {WatchQueryFetchPolicy} from "@apollo/client";
import {Resources, ResourcesTypes} from "@generated/artifacts/resources";
import {ResourceSlotBindingsMapType} from "@generated/artifacts/resources/slot-bindings";
import {VersionedSpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {
    ResourceFullFragmentDoc,
    ResourceResultFullFragmentDoc,
    UseResourceDocument,
    UseResourceQuery,
    UseResourceQueryVariables,
    UseResourceResultDocument,
    UseResourceResultQuery,
    UseResourceResultQueryVariables,
    UseResourcesDocument,
    UseResourcesForRenderingDocument,
    UseResourcesForRenderingQuery,
    UseResourcesForRenderingQueryVariables,
} from "@generated/data";
import {makeVar, QueryResult, useQuery} from "@workhorse/api/data";
import {useState, useEffect} from "@workhorse/api/rendering";
import apollo from "../apollo";
import {useWorkspaceAccess} from "../access/hooks";
import {internalFixResource, ResourceFullWithContent, ResultFullWithContent} from "./utils";

export type UseResourceHookTuple<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["resource"]> = {
    query: QueryResult<UseResourceQuery, UseResourceQueryVariables>;
    resource?: ResourceFullWithContent<T, K>;
};

export type UseResourceHookData<T extends ResourceLocator<ResourcesTypes, keyof VersionedSpecifiersTypeMap[ResourcesTypes]["resource"]>> =
    T extends ResourceLocator<infer Q, infer K>
        ? Q extends string
            ? K extends string
                ? UseResourceHookTuple<Q, K>
                : never
            : never
        : never;

export type useResourceResultHookData<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["result"] | undefined> = {
    query: QueryResult<UseResourceResultQuery, UseResourceResultQueryVariables>;
    result?: K extends string ? ResultFullWithContent<T, K> : never;
};

export type getResourceResultData<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["result"] | undefined> = {
    result?: K extends string ? ResultFullWithContent<T, K> : never;
};

export type getResourceData<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["resource"] | undefined> = {
    resource?: K extends string ? ResourceFullWithContent<T, K> : never;
};

export type UseResourcesHookData<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["resource"] | undefined> = {
    query: QueryResult<UseResourceQuery, UseResourceQueryVariables>;
    resources: (K extends string ? ResourceFullWithContent<T, K> : never)[];
};

export type UseResourcesForRenderingHookData<
    T extends ResourcesTypes,
    K extends keyof VersionedSpecifiersTypeMap[T]["resource"] | undefined
> = {
    query: QueryResult<UseResourcesForRenderingQuery, UseResourcesForRenderingQueryVariables>;
    resources: (K extends string ? ResourceFullWithContent<T, K> : never)[];
    loading: boolean;
};

export const useResource = <T extends ResourceLocator<ResourcesTypes, keyof VersionedSpecifiersTypeMap[ResourcesTypes]["resource"]>>(
    locator: T,
    fetchPolicy?: WatchQueryFetchPolicy
): UseResourceHookData<T> => {
    const query = useQuery(UseResourceDocument, {
        variables: {id: locator.id},
        fetchPolicy: fetchPolicy ?? "cache-first",
        skip: !locator.id,
    });
    const data = query?.data;

    if (!data || !data?.resource) return {query} as any;

    return {query, resource: internalFixResource(data.resource!)} as any;
};

type RenderedResourcesContainer = {};

export const renderedContainers = makeVar<{
    [type in ResourcesTypes]?: {
        containers: {[key: string]: RenderedResourcesContainer};
    };
}>({});

export function setRenderContainer(type: ResourcesTypes, container: string) {
    const value = renderedContainers();

    renderedContainers({
        ...value,
        [type]: {
            ...value[type],
            containers: {
                ...(value[type]?.containers ?? {}),
                [container]: {},
            },
        },
    });
}

export function unsetRenderContainer(type: ResourcesTypes, container: string) {
    let value = renderedContainers();
    delete value[type]?.[container];

    renderedContainers(value);
}

export const useResourcesForRendering = <T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["resource"]>(
    type: T,
    version: K,
    container: string,
    workspaceId: string
): UseResourcesForRenderingHookData<T, K> => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setRenderContainer(type, container);

        apollo.client
            .query({
                query: UseResourcesDocument,
                variables: {
                    where: {
                        type: {
                            equals: type,
                        },
                        specificationVersion: {
                            equals: version === "latest" ? Resources[type].latestVersion : (version as string),
                        },
                        workspaceId: {
                            equals: workspaceId,
                        },
                    },
                },
                fetchPolicy: "network-only",
            })
            .then(({data, loading, errors}) => {
                if (loading) {
                    setLoading(true);
                    return;
                }
                if (errors) {
                    setLoading(false);
                    throw errors;
                }

                apollo.client.writeQuery({
                    query: UseResourcesForRenderingDocument,
                    variables: {
                        type,
                        container,
                    },
                    data: {
                        resxResources: {
                            __typename: "ResxResourcesList",
                            hasData: true,
                            resources: [...(data?.resources ?? [])],
                        },
                    },
                });
                setLoading(false);
            })
            .catch((ex) => console.log("failed on initial data", ex));

        return () => {
            unsetRenderContainer(type, container);
        };
    }, [type, container]);

    const query = useQuery(UseResourcesForRenderingDocument, {
        variables: {
            type,
            container,
        },
        fetchPolicy: "cache-only",
        nextFetchPolicy: "cache-only",
    });

    const data = query.data;
    if (!data || !data.resxResources) return {query, loading, resources: []};

    return {query, loading, resources: data.resxResources.resources!.map((res) => internalFixResource<T, K>(res)) as any};
};

export const useResourceResult = <TTag extends keyof ResourceSlotBindingsMapType, TSlot extends keyof ResourceSlotBindingsMapType[TTag]>(
    resultId?: string,
    tag?: TTag,
    slot?: TSlot,
    config?: {
        artifactId: string;
        isPreview?: boolean;
    },
    fetchPolicy?: WatchQueryFetchPolicy
): ResourceSlotBindingsMapType[TTag][TSlot] extends ResourcesTypes
    ? useResourceResultHookData<ResourceSlotBindingsMapType[TTag][TSlot], "latest">
    : never => {
    const query = useQuery(UseResourceResultDocument, {
        variables: {
            resultId: resultId,
        },
        fetchPolicy: fetchPolicy ?? "cache-first",
        skip: !resultId,
    });

    const workspaceAccess = useWorkspaceAccess();

    const data = query.data;

    if (!data || !data.resourceResult) {
        return {query, result: query.loading ? undefined : null} as any;
    }

    let resource: any = data.resourceResult.resource ? internalFixResource(data.resourceResult.resource) : undefined;
    if (!resource && data.resourceResult.resourceContentSnapshot) {
        try {
            resource = {content: JSON.parse(data.resourceResult.resourceContentSnapshot), readOnly: true};
        } catch (e) {
            console.error(e);
        }
    }

    return {
        query,
        result: {
            ...data.resourceResult,
            resource: {
                ...resource,
                readOnly: !workspaceAccess.canEditResource(resource),
            },
        },
    } as any;
};

export const getResourceResult = async <
    TTag extends keyof ResourceSlotBindingsMapType,
    TSlot extends keyof ResourceSlotBindingsMapType[TTag]
>(
    resultId?: string,
    tag?: TTag,
    slot?: TSlot
): Promise<
    ResourceSlotBindingsMapType[TTag][TSlot] extends ResourcesTypes
        ? getResourceResultData<ResourceSlotBindingsMapType[TTag][TSlot], "latest">
        : never
> => {
    const data = await apollo.client.query({
        query: UseResourceResultDocument,
        variables: {
            resultId: resultId,
        },
        fetchPolicy: resultId ? "cache-first" : "cache-only",
    });

    if (!data || !data.data.resourceResult) {
        return {result: null} as any;
    }

    return {
        result: {
            ...data.data.resourceResult,
            resource: data.data.resourceResult.resource ? internalFixResource(data.data.resourceResult.resource!) : undefined,
        },
    } as any;
};

export const getResultFragment = <TTag extends keyof ResourceSlotBindingsMapType, TSlot extends keyof ResourceSlotBindingsMapType[TTag]>(
    resultId?: string,
    tag?: TTag,
    slot?: TSlot
): ResourceSlotBindingsMapType[TTag][TSlot] extends ResourcesTypes
    ? getResourceResultData<ResourceSlotBindingsMapType[TTag][TSlot], "latest">
    : never => {
    if (!resultId) {
        return {result: null} as any;
    }
    const data = apollo.cache.readFragment({
        fragment: ResourceResultFullFragmentDoc,
        fragmentName: "ResourceResultFull",
        id: apollo.cache.identify({
            __ref: resultId,
        }),
    });

    if (!data) {
        return {result: null} as any;
    }
    // @ts-expect-error
    return {
        result: {
            ...data,
            resource: data.resource ? internalFixResource(data.resource) : undefined,
        },
    };
};

export const getResourceFragment = <TTag extends keyof ResourceSlotBindingsMapType, TSlot extends keyof ResourceSlotBindingsMapType[TTag]>(
    resourceId?: string,
    tag?: TTag,
    slot?: TSlot
): ResourceSlotBindingsMapType[TTag][TSlot] extends ResourcesTypes
    ? getResourceData<ResourceSlotBindingsMapType[TTag][TSlot], "latest">
    : never => {
    if (!resourceId) {
        return {resource: undefined} as any;
    }
    const data = apollo.cache.readFragment({
        fragment: ResourceFullFragmentDoc,
        fragmentName: "ResourceFull",
        id: apollo.cache.identify({
            __ref: resourceId,
        }),
    });

    if (!data) {
        return {resource: undefined} as any;
    }

    return {resource: internalFixResource(data)} as any;
};
