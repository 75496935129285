import {useActiveSpeakers, useJoinedSpeaker} from "@workhorse/api/conference2";
import {useActiveSpeakersEnabled} from "../providers";

export function useIsAnySpeaker(participantId: string) {
    const speakers = useJoinedSpeaker();
    const activeSpeakers = useActiveSpeakers();

    const activeSpeakersEnabled = useActiveSpeakersEnabled();

    const isActiveSpeaker = activeSpeakers.findIndex((el) => el.id === participantId) !== -1;
    const isConferenceSpeaker = speakers.findIndex((el) => el.id === participantId) !== -1;

    const isSpeaker = activeSpeakersEnabled ? isActiveSpeaker : isConferenceSpeaker;
    const speakersCount = activeSpeakersEnabled ? activeSpeakers.length : speakers.length;

    return {isSpeaker, speakersCount};
}
