import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/EventAfterRegistrationAction.module.scss";
import {useTranslation} from "react-i18next";
import {SessionLifecycle} from "@generated/data";
import Select from "@ui/cdk/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Input from "@ui/cdk/Input";
import {useState, useMemo} from "@workhorse/api/rendering";
import {isValidUrl} from "@common/validation/utils";
import {useQuery, useMutation} from "@workhorse/dataApi";
import {FormControlLabel} from "@material-ui/core";
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import Button from "@ui/cdk/Button";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {Offer} from "@generated/data";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import designer from "@workhorse/api/designer";
import Loading from "@workhorse/components/Loading";
import LayersRoundedIcon from "@material-ui/icons/LayersRounded";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";

enum ActionType {
    NONE = "NONE",
    REDIRECT_URL = "REDIRECT_URL",
    SHOW_OFFER = "SHOW_OFFER",
}

const EventAfterRegistrationAction = () => {
    const {t} = useTranslation();
    const session = useSession();
    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const [actionType, setActionType] = useState<ActionType>(
        session?.event?.afterRegistrationOfferId
            ? ActionType.SHOW_OFFER
            : session?.event?.afterRegistrationRedirectUrl
            ? ActionType.REDIRECT_URL
            : ActionType.NONE
    );

    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.AFTER_REGISTRATION_ACTION,
    });

    const eventNotUpdatable = useMemo(
        () => [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked,
        [session.lifecycle, isLocked]
    );

    const [errorMessage, setErrorMessage] = useState<string>("");

    const {data, loading} = useQuery("GetOffersDocument", {
        skip: actionType !== ActionType.SHOW_OFFER,
    });

    const options = [
        {value: ActionType.NONE, text: "None"},
        {value: ActionType.REDIRECT_URL, text: "Redirect URL"},
        {value: ActionType.SHOW_OFFER, text: "Custom Offer"},
    ];

    function handleOnChangeAction(event: React.ChangeEvent<HTMLInputElement>) {
        if (!featureIsAvailable) {
            emitFeatureLimitError(t("limitations.notifications.please_upgrade_plan") ?? "");
            return;
        }

        lockEvent({
            variables: {
                id: session.id,
            },
        });

        const action = event.target.value as ActionType;

        setActionType(action);

        if (event.target.value !== ActionType.SHOW_OFFER) {
            designer.api.event.update({
                afterRegistrationOfferId: null,
            });
        }

        if (event.target.value !== ActionType.REDIRECT_URL) {
            designer.api.event.update({
                afterRegistrationRedirectUrl: null,
            });
        }

        designer.commit();
    }

    function handleOnChangeRedirectUrl() {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
    }

    function handleOnBlurRedirectUrl(event) {
        if (event.target.value === "") {
            setErrorMessage("");
            return;
        }
        if (!isValidUrl(event.target.value)) {
            return setErrorMessage("Please enter a valid URL");
        }
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        designer.api.event.update({
            afterRegistrationRedirectUrl: event.target.value,
        });
        designer.commit();
    }

    function handleSelectOffer(event) {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        designer.api.event.update({
            afterRegistrationOfferId: event.target.value,
        });
        designer.commit();
    }

    function goToMyOffers() {
        window.open("/user/offers", "_blank");
    }

    return (
        <div className={cls("flex fullw flex-col mt-12")}>
            <div className={cls("fullw flex flex-row flex-justify-between flex-align-center")}>
                <div className="flex flex-align-center">
                    <div className={classes.iconForm}>
                        <LayersRoundedIcon />
                    </div>
                    <div className="flex flex-col">
                        <Typography fontWeight="bold" color="secondary">
                            Action after someone registers
                        </Typography>
                        <Typography color="tertiary">Set an action that will take place as soon as someone registers.</Typography>
                    </div>
                </div>

                <Select
                    data-id="after-registration-action-select"
                    options={options}
                    value={actionType}
                    IconComponent={ExpandMoreRoundedIcon}
                    onChange={handleOnChangeAction}
                    className={classes.selectInput}
                    MenuProps={{
                        classes: {
                            paper: classes.selectMenuPaper,
                        },
                    }}
                    disabled={eventNotUpdatable}
                />
            </div>

            {actionType === ActionType.REDIRECT_URL && (
                <div className="flex flex-col gap-6 mt-24">
                    <Typography variant="base" fontWeight="bold" color="primary">
                        Redirect URL
                    </Typography>

                    <Input
                        name="after-registration-redirect-url"
                        data-id="after-registration-redirect-url"
                        formControlClassName={classes.formControl}
                        placeholder="https://yourdomain.com"
                        onChange={handleOnChangeRedirectUrl}
                        value={session?.event?.afterRegistrationRedirectUrl}
                        onBlur={handleOnBlurRedirectUrl}
                        disabled={eventNotUpdatable}
                    />
                    {errorMessage && (
                        <Typography variant="sm" color="red400">
                            {errorMessage}
                        </Typography>
                    )}
                </div>
            )}

            {actionType === ActionType.SHOW_OFFER && (
                <div className="flex flex-col gap-6 mt-24">
                    <Typography variant="base" fontWeight="bold" color="primary">
                        Custom offer
                    </Typography>

                    {loading ? (
                        <Loading />
                    ) : data?.userOffers?.length ? (
                        <div className={cls(classes.offersContainer, "flex flex-col flex-justify-start fullw")}>
                            <RadioGroup onChange={handleSelectOffer}>
                                {data?.userOffers?.map((offer: Offer) => {
                                    return (
                                        <FormControlLabel
                                            key={offer.id}
                                            data-id="after-registration-offer-radio-button"
                                            value={offer.id}
                                            checked={session?.event?.afterRegistrationOfferId === offer.id}
                                            label={
                                                <div className={cls("flex flex-row fullw", classes.singleOffer)}>
                                                    <img src={offer.imageUrl} />
                                                    <div className="flex flex-col">
                                                        <Typography variant="base" fontWeight="bold">
                                                            {offer.title}
                                                        </Typography>

                                                        <Typography variant="base">{offer.description}</Typography>
                                                    </div>
                                                </div>
                                            }
                                            control={<Radio size="medium" />}
                                            className={cls("flex flex-row fullw", classes.formControlLabel)}
                                            disabled={eventNotUpdatable}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </div>
                    ) : (
                        <div className="flex flex-row flex-align-center flex-justify-between fullw">
                            <Typography variant="base" className="flex flex-col flex-justify-center flex-align-center">
                                You have no offers
                            </Typography>

                            <Button variant="tertiary" size="small" onClick={goToMyOffers} startIcon={<OpenInNewRoundedIcon />}>
                                My Offers
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default EventAfterRegistrationAction;
