import {cls} from "@ui/cdk/util";
import React, {useEffect} from "@workhorse/api/rendering";
import {useVideoNode} from "../../lib/useVideoNode";
import {useVideoObjectFit} from "../../lib/useVideoObjectFit";
import {BackgroundFilter} from "../../lib/VideoReplacement/VideoReplacementBackgrounds";
import {useCameraSettings} from "../../providers";

type VideoProps = Omit<React.HTMLProps<HTMLVideoElement>, "src" | "ref"> & {
    filterOverride?: BackgroundFilter;
};

interface VideoStreamProps extends VideoProps {
    stream?: MediaStream;
}

export function PreviewVideo(props: VideoStreamProps) {
    const {stream, className, ...rest} = props;

    const {video, setVideo, videoRef} = useVideoNode();
    const {cameraMirrorSelfView} = useCameraSettings();
    useVideoObjectFit(video);

    useEffect(() => {
        if (!video || !stream) {
            return;
        }

        video.srcObject = stream;
    }, [video, stream]);

    return (
        <video
            {...rest}
            ref={setVideo}
            autoPlay={true}
            muted={true}
            controls={false}
            playsInline={true}
            disablePictureInPicture={true}
            className={cls(cameraMirrorSelfView && "inverted", className)}
        />
    );
}

export default PreviewVideo;
