import {Maybe} from "@generated/data";
import descriptor from "@generated/artifacts";
import {ArtifactDescriptor} from "@workhorse/internal/artifact";
import {Session, AgendaItem, Artifact, MacroArtifact, SessionData, DesignerApiSession} from "@workhorse/declarations/dataTypes";
import {useMemo} from "@workhorse/api/rendering";

export type ArtifactWithDescriptor = Artifact & {
    descriptor?: ArtifactDescriptor<any>;
};

export type MacroArtifactWithDescriptor = MacroArtifact & {
    descriptor?: ArtifactDescriptor<any>;
};

export type ForgedArtifact = ArtifactWithDescriptor & {
    components?: React.ComponentType<any>[];
    previewComponents?: React.ComponentType<any>[];
    headerComponents?: React.ComponentType<any>[];
    ghostComponents?: React.ComponentType<any>[];
};

export type ForgedMacroArtifact = MacroArtifactWithDescriptor & {
    components?: React.ComponentType<any>[];
    ghostComponents?: React.ComponentType<any>[];
};

export type ForgedAgendaItem = Omit<AgendaItem, "artifact"> & {
    artifact?: ForgedArtifact;
};

export type SessionWithForgedAgendaItems = Omit<Session, "agendaItems" | "macroArtifacts"> & {
    agendaItems: ForgedAgendaItem[];
    macroArtifacts: ForgedMacroArtifact[];
};

interface DesignerData extends Array<ForgedArtifact | ForgedMacroArtifact[] | (ForgedArtifact | ForgedMacroArtifact)[] | undefined> {
    0: ForgedArtifact | undefined;
    1: (ForgedArtifact | ForgedMacroArtifact)[];
}

function addDescriptorsToAgendaItems(agendaItems: DesignerApiSession["agendaItems"]): ForgedAgendaItem[] {
    return (
        agendaItems.map((agendaItem) => {
            if (agendaItem.artifact) {
                return {
                    ...agendaItem,
                    artifact: {
                        ...agendaItem.artifact,
                        descriptor: descriptor[agendaItem.artifact.artifactId],
                    } as ForgedArtifact,
                };
            }
            return agendaItem as ForgedAgendaItem;
        }) ?? []
    );
}

function useDesignerArtifacts({
    selectedAgendaItemId,
    selectedMicroIdToConfig,
    selectedMicroIdToPreview,
    session,
    macroArtifacts,
    agendaItems,
}: {
    selectedMicroIdToConfig: Maybe<string> | undefined;
    selectedMicroIdToPreview: Maybe<string> | undefined;
    selectedAgendaItemId: Maybe<string> | undefined;
    session: DesignerApiSession["session"];
    agendaItems: DesignerApiSession["agendaItems"];
    macroArtifacts: DesignerApiSession["macroArtifacts"];
}): DesignerData {
    const agendaItemsWithDescriptors = addDescriptorsToAgendaItems(agendaItems);

    const currentAgendaItem = useMemo(() => {
        return agendaItemsWithDescriptors.find(
            (agendaItem) =>
                agendaItem.id === selectedAgendaItemId ||
                (agendaItem.artifact?.id && [selectedMicroIdToPreview, selectedMicroIdToConfig].includes(agendaItem.artifact?.id))
        );
    }, [
        selectedMicroIdToConfig,
        selectedMicroIdToPreview,
        selectedAgendaItemId,
        session?.updatedAt,
        agendaItems.length,
        agendaItemsWithDescriptors,
    ]);

    const micros = useMemo(() => {
        const allMicros = agendaItemsWithDescriptors.map((agendaItem) => agendaItem.artifact).filter((a) => !!a);
        return allMicros;
    }, [
        selectedMicroIdToConfig,
        selectedMicroIdToPreview,
        selectedAgendaItemId,
        session?.updatedAt,
        agendaItems.length,
        agendaItems,
        agendaItemsWithDescriptors,
    ]);

    const currentArtifact = useMemo(() => {
        const currentMicro = micros.find((p) => p?.id === currentAgendaItem?.artifact?.id);

        if (currentMicro) {
            const configs = currentMicro.descriptor?.entries.filter(
                (entry) => entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/config"]
            );
            const previews = currentMicro.descriptor?.entries.filter(
                (entry) => entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/is-preview"]
            );

            const headers = currentMicro.descriptor?.entries.filter(
                (entry) =>
                    entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/is-config-header"]
            );

            currentMicro.components = (configs || []).map((entry) => entry.loader);
            currentMicro.previewComponents = (previews || []).map((entry) => entry.loader);
            currentMicro.headerComponents = (headers || []).map((entry) => entry.loader);
        }

        return currentMicro;
    }, [
        micros,
        currentAgendaItem?.id,
        selectedMicroIdToConfig,
        selectedMicroIdToPreview,
        selectedAgendaItemId,
        session?.updatedAt,
        agendaItems.length,
        agendaItems,
        currentAgendaItem?.artifact,
        agendaItemsWithDescriptors,
    ]);

    const allArtifacts = useMemo(
        () => [...micros, ...macroArtifacts],
        [micros, macroArtifacts.length, session?.updatedAt]
    ) as ForgedArtifact[];

    const ghosts = useMemo(
        () =>
            allArtifacts.filter(
                (artifact) =>
                    (artifact.descriptor?.ghosts || []).length > 0 &&
                    artifact.descriptor!.ghosts!.filter((ghostEntry) => {
                        // const isValid = evaluateExpression({
                        //     exp: ghostEntry.preloadCondition,
                        //     type: !artifact.isMacro ? "micro" : "macro",
                        //     artifactEvaluation: ghostEntry.filter || (() => true),
                        // });
                        const isValid = ghostEntry.preloadCondition.includes("host.isDesigner");

                        if (isValid) {
                            if (!artifact.ghostComponents) {
                                artifact.ghostComponents = [];
                            }
                            artifact.ghostComponents.push(ghostEntry.loader);
                        }
                        return isValid;
                    }).length > 0
            ),
        [allArtifacts]
    );

    return [currentArtifact, ghosts];
}

export default useDesignerArtifacts;
