import {getContactByEmail} from "@common/utils/contacts";
import {OneSessionFragment, ParticipantStatus, SessionLifecycle, SessionParticipantFragment, SpeakerDetailsFragment} from "@generated/data";
import {useRoomStore} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {participantIsAssistant} from "@workhorse/api/conference2/utils";
import {findLastIndex} from "@workhorse/api/designer/lib/utils";
import {useMemo} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";
import {sortParticipantsLobbyOrPlayer} from "@workhorse/pages/player/lobby/utils";
import {useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import {beautifySeconds} from "@workhorse/util/time";
import {differenceInSeconds, max} from "date-fns";

export enum ParticipantsCategory {
    WAITING_TO_JOIN,
    MODERATORS,
    ATTENDEES,
    INVITED,
    SPEAKERS,
}

export type PrticipantListsByCategory = {
    moderatorsParticipants: Participant[];
    waitingToJoinParticipants: Participant[];
    joinedParticipants: Participant[];
    notJoinedParticipants: Participant[];
    invitedParticipants: Participant[];
    speakersParticipants: Participant[];
};

export type ParticipantsCategoryType = {
    title: "waiting_to_join" | "moderators" | "attendees" | "invited" | "speakers";
    color: "Blue" | "Green" | "Purple";
};

export const participantsCategoryInfo: {[key in ParticipantsCategory]: ParticipantsCategoryType} = {
    [ParticipantsCategory.WAITING_TO_JOIN]: {
        title: "waiting_to_join",
        color: "Blue",
    },
    [ParticipantsCategory.MODERATORS]: {
        title: "moderators",
        color: "Green",
    },
    [ParticipantsCategory.ATTENDEES]: {
        title: "attendees",
        color: "Purple",
    },
    [ParticipantsCategory.INVITED]: {
        title: "invited", // "Invited:
        color: "Blue",
    },
    [ParticipantsCategory.SPEAKERS]: {
        title: "speakers",
        color: "Blue",
    },
};

export type ParticipantPresenceHistoryJsonType = {
    totalTimeSpentInSeconds?: number;
    joinLeaveEvents: {
        type: "JOIN" | "LEAVE";
        timestamp: Date;
        timeSpentInSeconds?: number;
    }[];
};

export const sortAllParticipants = (participants: Participant[], sessionLifecycle: SessionLifecycle) => {
    return sortParticipantsLobbyOrPlayer(participants, sessionLifecycle, false);
};

export const filterParticipants = (participants: Participant[], searchValue: string) => {
    if (!searchValue.length) return participants;

    const searchValueLowerCased = searchValue.toLowerCase();
    return participants?.filter((p) => {
        const contact = getContactByEmail(p.dataWithNullableEmail.email);

        const contactFirstNameLowerCased = contact?.firstName.toLowerCase().trim();
        const contactLastNameLowerCased = contact?.lastName.toLowerCase().trim();
        const contactFullNameLowerCased = `${contactFirstNameLowerCased} ${contactLastNameLowerCased}`.trim();

        const firstNameLowerCased = p.dataWithNullableEmail.firstName.toLowerCase().trim();
        const lastNameLowerCased = p.dataWithNullableEmail.lastName.toLowerCase().trim();
        const fullNameLowerCased = `${firstNameLowerCased} ${lastNameLowerCased}`.trim();

        const emailLowerCased = p.dataWithNullableEmail.email?.toLowerCase().trim();

        return (
            contactFullNameLowerCased.includes(searchValueLowerCased) ||
            emailLowerCased?.includes(searchValueLowerCased) ||
            fullNameLowerCased.includes(searchValueLowerCased)
        );
    });
};

export const filterSpeakers = (speakers: SpeakerDetailsFragment[], searchValue: string) => {
    if (!searchValue.length) return speakers;

    const searchValueLowerCased = searchValue.toLowerCase();
    return speakers?.filter((p) => {
        const contact = getContactByEmail(p.email);

        const contactFirstNameLowerCased = contact?.firstName.toLowerCase().trim();
        const contactLastNameLowerCased = contact?.lastName.toLowerCase().trim();
        const contactFullNameLowerCased = `${contactFirstNameLowerCased} ${contactLastNameLowerCased}`.trim();

        const fullNameLowerCased = `${p.name}`.trim();

        const emailLowerCased = p.email?.toLowerCase().trim();

        return (
            contactFullNameLowerCased.includes(searchValueLowerCased) ||
            emailLowerCased?.includes(searchValueLowerCased) ||
            fullNameLowerCased.includes(searchValueLowerCased)
        );
    });
};

export const filterApprovedParticipant = (session: OneSessionFragment, p: SessionParticipantFragment) => {
    if (session.lifecycle === SessionLifecycle.NotStarted) {
        return true;
    }

    if (session.requestPermissionToJoin && !p.isOwner && !rbac(p, "session.isAssistant") && !p.isApproved) {
        return false;
    }

    if (session.provideSessionPasscode && !p.isOwner && !rbac(p, "session.isAssistant") && !p.submittedPasscode) {
        return false;
    }

    return true;
};

export const getParticipantPressenceInfo = (
    participant: Participant & {presenceHistory: ParticipantPresenceHistoryJsonType},
    sessionActualStart?: Date | null | undefined
): {
    joinedAt: string;
    leftAt: string;
    totalSpent: string;
} | null => {
    const eventsArray = participant?.presenceHistory?.joinLeaveEvents ?? [];

    if (eventsArray.length === 0) {
        return null;
    }

    const firstJoinIndex = findLastIndex(eventsArray, (event) => event?.type === "JOIN");
    const lastLeaveIndex = findLastIndex(eventsArray, (event) => event?.type === "LEAVE");

    const firstJoinDate = new Date(eventsArray?.[firstJoinIndex]?.timestamp);

    const whenReallyJoined = max([new Date(firstJoinDate), new Date(sessionActualStart ?? 0)]);

    const userJoinedAt = differenceInSeconds(new Date(whenReallyJoined), new Date(sessionActualStart ?? 0));

    const userLeftAt = differenceInSeconds(new Date(eventsArray?.[lastLeaveIndex]?.timestamp), new Date(sessionActualStart ?? 0));

    const backendTotalTimeSpent = !!participant?.presenceHistory?.totalTimeSpentInSeconds
        ? Math.round(participant?.presenceHistory?.totalTimeSpentInSeconds)
        : 0;
    const calculatedTimeSpent = userLeftAt - userJoinedAt;

    const userTotalTimeSpent = !!backendTotalTimeSpent
        ? backendTotalTimeSpent > calculatedTimeSpent
            ? calculatedTimeSpent
            : backendTotalTimeSpent
        : 0;

    return {
        joinedAt: !Number.isNaN(userJoinedAt) ? beautifySeconds(userJoinedAt) : "-",
        leftAt: !Number.isNaN(userLeftAt) ? beautifySeconds(userLeftAt) : "-",
        totalSpent: !!userTotalTimeSpent ? beautifySeconds(userTotalTimeSpent) : "-",
    };
};

export function useParticipantCount(isMemoryMode?: boolean) {
    const session = useSession();
    const participants = useParticipants();
    const joinedParticipants = useRoomStore((s) => s.joinedParticipants);

    const activeParticipantCount = participants
        .filter((p) => {
            if (isMemoryMode) {
                return p.hasAttendedSession;
            }

            if (joinedParticipants[p.id]) {
                return true;
            }

            return session.lifecycle === SessionLifecycle.Started
                ? [ParticipantStatus.JoinedSession].includes(p.status)
                : [ParticipantStatus.InLobby, ParticipantStatus.JoinedSession].includes(p.status);
        })
        .filter((p) => filterApprovedParticipant(session, p)).length;

    const totalParticipantCount =
        activeParticipantCount +
        participants.filter((p) => {
            if (p.isRecorder) {
                return false;
            }
            if (!p.invitedByTheOwner) {
                return false;
            }

            if (isMemoryMode) {
                return !p.hasAttendedSession;
            }

            if (joinedParticipants[p.id]) {
                return false;
            }

            return ![ParticipantStatus.InLobby, ParticipantStatus.JoinedSession].includes(p.status);
        }).length;

    return useMemo(
        () => ({
            activeParticipantCount,
            totalParticipantCount,
        }),
        [activeParticipantCount, totalParticipantCount]
    );
}

export const getEmptyText = (
    type: ParticipantsCategory,
    hasParticipants: boolean,
    hasSearchValue: boolean,
    isMemoryMode: boolean,
    isLobby: boolean
) => {
    if (!hasParticipants && hasSearchValue) {
        return "g.no_results";
    }

    switch (type) {
        case ParticipantsCategory.MODERATORS: {
            return "macro.participants.placeholder.hosts_offline";
        }

        case ParticipantsCategory.ATTENDEES: {
            if (isMemoryMode) {
                return "macro.participants.placeholder.no_attendees";
            }

            if (isLobby) {
                return "macro.participants.placeholder.no_attendees_lobby";
            }

            return "macro.participants.placeholder.no_attendees_session";
        }

        case ParticipantsCategory.INVITED: {
            if (!hasParticipants) {
                return "macro.participants.placeholder.no_one_invited";
            }
            return "macro.participants.placeholder.everyone_online";
        }

        case ParticipantsCategory.SPEAKERS: {
            return "macro.participants.placeholder.speakers_offline";
        }

        default: {
            return null;
        }
    }
};
