import {forwardRef, useMemo} from "@workhorse/api/rendering";

import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import {CustomInputProps} from "./Input";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/input.module.scss";

export type MuiInputLabelProps = ExtractProps<typeof MuiInputLabel>;
export type MuiFormControlProps = ExtractProps<typeof MuiFormControl>;

const FormControl = forwardRef((props: CustomInputProps & {style?: React.CSSProperties}, ref: any) => {
    const {
        label,
        labelClassName,
        required = false,
        variant = "outlined",
        margin,
        className,
        error,
        info,
        helperText,
        shrinkLabel,
        InputLabelProps,
        size = "medium",
        formControlClassName,
        ...other
    } = props;

    const LabelComponent = useMemo(() => {
        const lblCls = cls(labelClassName, shrinkLabel === false && classes.noShrink);
        return label ? (
            <MuiInputLabel
                {...InputLabelProps}
                shrink={true}
                required={required}
                variant={variant}
                margin={margin}
                className={lblCls}
                key="inp-label"
            >
                {label}
            </MuiInputLabel>
        ) : null;
    }, [label]);

    const HelperComponent = useMemo(() => {
        return (error || info) && helperText ? (
            <MuiFormHelperText className={cls("helper-text", error && "helper-error", info && "helper-info")} key="helper">
                <>{helperText}</>
            </MuiFormHelperText>
        ) : null;
    }, [error, info, helperText]);

    return (
        <MuiFormControl
            ref={ref}
            className={cls(
                classes.formControl,
                classes.inputRoot,
                className,
                shrinkLabel === false && label ? classes.noShrinkLabelFc : null,
                // size === "medium" comparison is not needed because `medium` is the default size
                size === "small" && classes.smallSizeInput,
                size === "large" && classes.largeSizeInput,
                formControlClassName
            )}
            error={error === true && helperText ? true : undefined}
            {...other}
        >
            {LabelComponent}
            {props.children}
            {HelperComponent}
        </MuiFormControl>
    );
});

FormControl.displayName = "FormControl";

export default FormControl;
