export const DUMMY_AGENDA_ITEM_TITLE = "________auto________";

type SessionRolesRbacData = {
    isAssistant: boolean;
};

type AgendaItemRbacData = {
    agendaItemId: string;
    isPresenter: boolean;
};

export type ParticipantRoleBasedAccessControl = {
    agendaItems?: AgendaItemRbacData[];
    session?: SessionRolesRbacData;
};

export enum FileMimeType {
    Archive = "ARCHIVE",
    Audio = "AUDIO",
    Document = "DOCUMENT",
    Image = "IMAGE",
    Pdf = "PDF",
    Presentation = "PRESENTATION",
    Spreadsheet = "SPREADSHEET",
    Unknown = "UNKNOWN",
    Video = "VIDEO",
}
