import {SessionLifecycle, SessionFlagsType, ConferenceParticipantStatus} from "@generated/data";
import {Fragment, useEffect} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";

import {SessionData} from "@workhorse/declarations/dataTypes";
import {useIsLobby, useParticipant} from "@workhorse/providers/SessionDataProviders";
import SessionAudience from "./session-view-components/session-audience/SessionAudience";
import SessionContent from "./session-view-components/SessionContentNew";
import SessionExtra from "./session-view-components/SessionExtra";
import SessionFooter from "./session-view-components/SessionFooter";
import SessionHeader from "./session-view-components/SessionHeader";
import SessionMacros from "./session-view-components/SessionMacros";
import SessionBackground from "./session-view-components/SessionBackground";
import {RECORDING_USER_EMAIL} from "@common/recording/constants";
import {useUserInfo} from "@workhorse/providers/User";
import {useQuery} from "@workhorse/dataApi";
import loadable from "@workhorse/api/loadable";
import {useEmbedSessions} from "@workhorse/api/embed";

const OnePlayerPopups = loadable(() => import("./OnePlayerPopups"));

type SessionViewProps = {
    session: SessionData;
    isJoined: boolean;
};

export default function SessionView(props: SessionViewProps) {
    const {session, isJoined} = props;
    const {
        id: sessionId,
        currentParticipant,
        lifecycle,
        createdAt,
        allowMicrophoneType,
        quickSession,
        event,
        requireConsentToRecord,
        backstage,
    } = session;

    const myParticipantId = currentParticipant.pid;
    const myParticipant = useParticipant({participantId: myParticipantId});
    const isAdmin = myParticipant?.isOwner ?? false;
    const isAssistant = rbac(myParticipant, "session.isAssistant");
    const isSpeaker = !!myParticipant?.speakerDetails?.id || myParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker;
    const sessionStarted = lifecycle === SessionLifecycle.Started;
    const sessionIsPublished = !!createdAt && !(lifecycle === SessionLifecycle.Ended);
    const {isLobby} = useIsLobby();
    const user = useUserInfo();

    const {uiElementsToRemove} = useEmbedSessions();

    const notificationsRemoved = uiElementsToRemove.includes("notifications");

    const isRecordingUser = user?.email === RECORDING_USER_EMAIL;

    return (
        <>
            <SessionBackground />
            <Fragment key="player-header">
                <SessionHeader session={session} isLobby={isLobby} isAdmin={isAdmin} isAssistant={isAssistant} key="session-header" />
            </Fragment>
            {notificationsRemoved ? null : (
                <Fragment key="session-one-popups">
                    <OnePlayerPopups />
                </Fragment>
            )}

            <Fragment key="session-audience">
                {!isLobby && (
                    <SessionAudience
                        session={session}
                        myParticipantId={currentParticipant.pid}
                        isAdmin={isAdmin}
                        isAssistant={isAssistant}
                        sessionStarted={sessionStarted}
                        sessionIsPublished={sessionIsPublished}
                        isJoined={isJoined}
                    />
                )}
            </Fragment>
            <SessionContent
                sessionStarted={sessionStarted}
                isLobby={isLobby}
                key="session-content"
                isAdmin={isAdmin}
                isAssistant={isAssistant}
                sessionId={sessionId}
                isEvent={!!event?.id}
                // TODO: advanced settings
                hardMute={[SessionFlagsType.Hosts, SessionFlagsType.Speakers].includes(allowMicrophoneType)}
                myParticipantId={myParticipantId}
                sessionIsPublished={sessionIsPublished}
                isQuickSession={quickSession}
                requireConsentToRecord={requireConsentToRecord}
            />
            {/* {isRecordingUser ? (
                <div className="flex flex-row ml-auto pr-16 pb-15 gap-3">
                    <Typography fontWeight="bold" variant="base" color="blueGray400">
                        Powered by
                    </Typography>
                    <Typography fontWeight="boldest" variant="base" color="blueGray400">
                        Sessions
                    </Typography>
                </div>
            ) : null} */}

            <Fragment key="macros">
                {sessionIsPublished && (
                    <SessionMacros
                        isLobby={isLobby}
                        isAdmin={isAdmin}
                        isAssistant={isAssistant}
                        sessionId={sessionId}
                        sessionStarted={sessionStarted}
                        myParticipantId={myParticipantId}
                    />
                )}
            </Fragment>

            <Fragment key="player-footer">
                <SessionFooter
                    isLobby={isLobby}
                    session={session}
                    backstage={backstage}
                    isAdmin={isAdmin}
                    isAssistant={isAssistant}
                    sessionStarted={sessionStarted}
                    isSpeaker={isSpeaker}
                />
            </Fragment>

            {notificationsRemoved ? null : (
                <Fragment key="session-extras">
                    {(backstage ? !isLobby : true) && (
                        <SessionExtra
                            isLobby={isLobby}
                            session={session}
                            isAdmin={isAdmin}
                            isAssistant={isAssistant}
                            sessionStarted={sessionStarted}
                        />
                    )}
                </Fragment>
            )}
            <Fragment key="refresh-recorder">{isRecordingUser && <RecorderDetectNewReleaseOrChunkErr />}</Fragment>
        </>
    );
}

function RecorderDetectNewReleaseOrChunkErr() {
    const {data} = useQuery("LocalVersionDocument");
    useEffect(() => {
        if (data?.version.isMismatch || data?.version.chunkLoadErr) {
            console.log(`should refresh for recorder because of ${data?.version.isMismatch ? "version mismatch" : "a chunk load error"}`);
            parent?.top?.location.reload();
        }
    }, [data]);
    return <></>;
}
