import React, {useState, useEffect} from "react";
import Snackbar from "@ui/core/components/Snackbar";
import classes from "./styles/ToolConfirmationModeNotification.module.scss";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {cls} from "@ui/cdk/util/util";
import toast from "@workhorse/api/toast";
import CircularProgressWithLabel from "@ui/cdk/CircularProgressWithLabel/CircularProgressWithLabel";
import Button from "@ui/cdk/Button";

interface ConvertingNotificationProps {
    onNotificationClose: () => void;
}

const ConvertingNotification = (props: ConvertingNotificationProps) => {
    const handleCloseSnackBar = (e) => {
        e.stopPropagation();
        props.onNotificationClose();
    };

    return (
        <div
            className={classes.root}
            onMouseEnter={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
            onMouseOver={(e) => e.stopPropagation()}
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={classes.circularMessageWrapper}>
                    <CircularProgressWithLabel className="svg-blue-500" type="long" variant="indeterminate" thickness={5} />

                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            File is processing
                        </Typography>
                        <Typography variant="base">You will be able to preview it soon.</Typography>
                    </div>
                </div>
                <div className={cls(classes.buttonsContainer)}>
                    <Button onClick={handleCloseSnackBar}>Ok</Button>
                </div>
            </div>
        </div>
    );
};

export default ConvertingNotification;
