import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util/util";
import {useHistory} from "@workhorse/api/routing";
import {SessionSettingsSeenType, markAsSeen, useSeenSection} from "@workhorse/api/seen";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "../styles/SessionTemplateSettingsNotification.module.scss";

type SessionRememberSettingsProps = {
    type: SessionSettingsSeenType;
    isDismissed: boolean;
    setIsDismissed: (value: boolean) => void;
    className?: string;
};

function SessionTemplateSettingsNotification(props: SessionRememberSettingsProps) {
    const {type, isDismissed, setIsDismissed, className} = props;

    const userSeenNotification = useSeenSection(`SESSION_TEMPLATE_SETTINGS.${type}`);

    const history = useHistory();
    const mobileState = useMobile();
    const {isMobile} = mobileState;
    const handleGoToProfile = () => {
        history.push(`/user/workspace/session-settings?type=${type}`);
    };

    const handleMarkAsSeen = () => {
        markAsSeen({
            SESSION_TEMPLATE_SETTINGS: {
                [type]: true,
            },
        });
    };

    const handleDismiss = () => {
        setIsDismissed(true);
    };

    const typeText = type === "planned" ? "session" : type;

    const title = `Default ${typeText} settings`;
    const description = `Set up your default settings for newly created ${typeText}s.`;

    if (userSeenNotification || isDismissed || isMobile) {
        return null;
    }

    return (
        <>
            <div className="mb-auto" />
            <div className={cls(classes.wrapper)}>
                <div className={cls("flex flex-row flex-justify-between", classes.root, className)}>
                    <div className={cls("flex flex-row fullw")}>
                        <div className={classes.iconWrapper}>
                            <SettingsOutlinedIcon />
                        </div>
                        <div className={cls("flex flex-row flex-justify-between flex-items-center fullw")}>
                            <div className="flex flex-col">
                                <Typography variant="lg" fontWeight="bold" className="mb-8">
                                    {title}
                                </Typography>
                                <Typography color="secondary" className="mb-8">
                                    {description}
                                </Typography>
                                <Typography fontWeight="bolder" className={classes.closeText} onClick={handleMarkAsSeen}>
                                    Close and don’t show again
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="ml-12 flex flex-col flex-justify-between">
                        <div className={classes.closeIcon} onClick={handleDismiss}>
                            <CloseRoundedIcon />
                        </div>
                        <Button variant="tertiary" size="small" onClick={handleGoToProfile} className="nowrap">
                            Go to settings
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SessionTemplateSettingsNotification;
