import {forwardRef, useCallback} from "@workhorse/api/rendering";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {useTranslation} from "react-i18next";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import TogglePinParticipantButton, {TogglePinParticipantIcon} from "../../../TogglePinParticipantButton";

type ParticipantPinActionProps = {
    participantId: string;
    hideIfDisabled?: boolean;
};

export const MAX_PINNED_PARTICIPANTS = 4;

const ParticipantPinAction = forwardRef((props: ParticipantPinActionProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {participantId} = props;
    const {pinnedVideoStreamsMap, togglePinnedParticipant, pinnedModeEnabled} = useSessionView();
    const isPinned = pinnedVideoStreamsMap[participantId] === true;

    if (props.hideIfDisabled && !pinnedModeEnabled) {
        return null;
    }

    return (
        <MenuItem data-id="pin-unpin" ref={ref} onClick={() => togglePinnedParticipant(participantId)} disabled={!pinnedModeEnabled}>
            <TogglePinParticipantIcon isPinned={isPinned} />
            <Typography color="quaternary" fontWeight="bold" className="ml-6">
                {!isPinned ? t("participant.action.pin") : t("participant.action.unpin")}
            </Typography>
        </MenuItem>
    );
});

export default ParticipantPinAction;
