import DateFnsAdapter from "@date-io/date-fns";
import {format as dateFnsFormat} from "date-fns";
import {makeStyles} from "@material-ui/core";
import React, {CSSProperties, useEffect, useState} from "@workhorse/api/rendering";
import {DateIOFormats, WithClasses, WithClassName, WithStyle} from "@workhorse/declarations";
import {getOrdinalNumber} from "@workhorse/util";
import {cls} from "@ui/cdk/util/util";
import {t} from "i18next";

const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
const mons = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export type DateFormatClassKey = "root";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.main.palette.text.primary,
    },
}));

const dateFns = new DateFnsAdapter();

type CustomDateFormat = "dayOfMonth-ss" | "meridiem" | "dayInitial";
type ICustomDateFormat = Record<CustomDateFormat, (date: Date) => string>;

type CustomSeparator = {
    sep: string;
    fontWeight?: number;
};

type CustomFormat = {
    format: keyof DateIOFormats | CustomDateFormat;
    fontWeight?: CSSProperties["fontWeight"];
    textTransform?: CSSProperties["textTransform"];
    color?: CSSProperties["color"];
};

export const CustomDateFormatFunctions: ICustomDateFormat = {
    "dayOfMonth-ss": (date) => `${date.getDate()}${getOrdinalNumber(date.getDate())}`, // superscript ordinals: 1st, 2nd, 3rd, 4th...
    dayInitial: (date) => dateFnsFormat(date, "iiiii"),
    meridiem: (date) => dateFnsFormat(date, "a"),
};

export type IDateFormat = keyof DateIOFormats | CustomDateFormat | undefined | false;
export type IDateFormatStyle = IDateFormat | CustomSeparator | CustomFormat;
export type IDateFormatText = IDateFormat | {sep: string};

export type DateFormatProps = WithStyle &
    WithClassName &
    WithClasses<DateFormatClassKey> & {
        date?: Date;
        format?: IDateFormatStyle[];
    };

const formatDate = (date: Date, formatKey: keyof DateIOFormats | CustomDateFormat): string => {
    if (formatKey in CustomDateFormatFunctions) {
        return CustomDateFormatFunctions[formatKey](date);
    } else {
        return dateFns.format(date, formatKey as keyof DateIOFormats);
    }
};

export function dateFormat(
    timestamp: string | Date | number | false | undefined | null | never,
    outputType: IDateFormatText | IDateFormatText[]
): string {
    if (!timestamp) {
        return "";
    }

    const local = new Date(timestamp);

    if (Array.isArray(outputType)) {
        return outputType
            .filter((format) => !!format)
            .reduce((prev, currentOutputType: IDateFormatText) => {
                if (typeof currentOutputType === "string") {
                    return `${prev} ${dateFormat(timestamp, currentOutputType)}`;
                } else if (currentOutputType) {
                    return `${prev}${currentOutputType.sep}`;
                } else {
                    return prev;
                }
            }, "");
    } else {
        if (typeof outputType === "string") {
            return formatDate(local, outputType);
        } else if (outputType) {
            return outputType.sep;
        } else {
            return "";
        }
    }
}

export function translateDateString(ds: string) {
    for (const month of months) {
        ds = ds.replace(new RegExp(month, "gi"), t(`g.date.month.${month}`));
    }

    for (const day of days) {
        ds = ds.replace(new RegExp(day, "gi"), t(`g.date.day.${day}`));
    }

    return ds;
}

const DateFormat = (props: DateFormatProps) => {
    const [format, setFormat] = useState<IDateFormatStyle[]>(
        props.format || [{fontWeight: 500, format: "weekdayShort"}, "monthShort", "dayOfMonth"]
    );

    const [date, setDate] = useState<Date>(props.date || new Date());
    const classes = useStyles();

    useEffect(() => {
        if (props.date) {
            setDate(props.date);
        }
    }, [props.date]);

    useEffect(() => {
        if (props.format) {
            setFormat(props.format);
        }
    }, [props.format]);

    return (
        <span style={props.style} className={cls(classes.root, props.className, props.classes?.root)}>
            {format
                .filter((format) => !!format)
                .map((format, i, arr) => {
                    const next = arr[i + 1];
                    let out = "";
                    let style: CSSProperties = {};

                    let space = " ";
                    if (typeof next === "undefined" || (typeof next === "object" && "sep" in next)) {
                        space = "";
                    }

                    if (typeof format === "object" && "format" in format) {
                        out = dateFormat(date, format.format);
                        style = {...format};
                    } else if (typeof format === "string") {
                        out = dateFormat(date, format);
                    } else if (typeof format === "object" && "sep" in format) {
                        out = format.sep;
                        space = "";
                        style = {...format};
                    }

                    return <span key={i} style={style}>{`${out}${space}`}</span>;
                })}
        </span>
    );
};

export default DateFormat;
