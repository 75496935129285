import {useSubscription} from "@apollo/client";
import {MemorySessionRecordingFragmentDoc, RecordingUpdatedDocument} from "@generated/data";
import {ReactNode} from "@workhorse/api/rendering";

export function RecordingProvider({children}: {children: ReactNode}) {
    useSubscription(RecordingUpdatedDocument, {
        onSubscriptionData: ({client, subscriptionData}) => {
            const data = subscriptionData.data?.onRecordingUpdated;

            if (!data) {
                return;
            }

            client.writeFragment({
                fragment: MemorySessionRecordingFragmentDoc,
                id: data.recording.id,
                data: {
                    __typename: "SessionRecording",
                    id: data.recording.id,
                    version: data.recording.version,
                    processingStatus: data.recording.processingStatus,
                    // startedAt: data.recording.startedAt,
                    state: data.recording.state,
                    downloadUrl: data.recording.downloadUrl,
                    permission: data.recording.permission,
                    name: data.recording.name,
                    type: data.recording.type,
                    livestreamOutputUrls: data.recording.livestreamOutputUrls,
                },
            });
        },
    });

    return <>{children}</>;
}
