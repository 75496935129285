import {ParticipantStatus, SessionLifecycle, ConfigurationStep, AgendaItemType, MemoryAccessType} from "@generated/data";
import designer from "@workhorse/api/designer";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {HistoryState, useHistory} from "@workhorse/api/routing";
import {enableEndSessionModal} from "@workhorse/components/header/headerUtils";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import {useFullScreenState} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import {exitFullScreen} from "@workhorse/util";
import {setPidThatChangedArtifact} from "./playerStateVars";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {evictLocalQueries} from "@workhorse/providers/HostTypeProvider";
import {useJoinedFromOtherDevice} from "@workhorse/api/conference2/providers/JoinedFromOtherDeviceProvider";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";
import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {submittedVar} from "./pre-join-screen/PreJoinScreenEntry";
import {useEmbedSessions} from "@workhorse/api/embed";

type PlayerRedirectProps = {
    session: SessionData;
    isBorAndParent?: boolean;
    setCanMountPlayer: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PlayerRedirects(props: PlayerRedirectProps) {
    const {lifecycle, childOfBreakoutRooms, id: sessionId, createdAt, memoryAccessType, backstage} = props.session;
    const {isGuest} = useUserInfo();
    const [isEditMode, toggleEditMode] = useDesignerIsEditing();

    const {onboardingType} = useOnboardingSession();
    const {setJoinedFromOtherDevice} = useJoinedFromOtherDevice();

    const {embedMode} = useEmbedSessions();
    const isMobileApp = embedMode === "mobile";

    const {isOwner, id, status, hasAttendedSession: meAttendedThisSession} = useCurrentParticipant();
    const participantNotJoined = status !== ParticipantStatus.JoinedSession;

    const {isAssistant} = useParticipantsStore(
        useShallow((state) => ({
            isAssistant: state.amIAssistant,
        }))
    );

    const isOrganizer = isOwner || isAssistant;

    const isFullScreen = useFullScreenState();
    const history = useHistory<HistoryState>();

    const sessionHasEnded = lifecycle === SessionLifecycle.Ended;

    const previousLifeCycle = useRef<SessionLifecycle | undefined>(lifecycle);

    const hasMemoryAccess =
        isOrganizer ||
        (meAttendedThisSession && memoryAccessType === MemoryAccessType.JustAttendees) ||
        memoryAccessType === MemoryAccessType.Public;

    const wasBackstageRef = useRef(backstage);

    useEffect(() => {
        if (wasBackstageRef && !backstage) {
            submittedVar(false);
        }

        wasBackstageRef.current = backstage;
    }, [backstage]);

    useEffect(() => {
        if (lifecycle === SessionLifecycle.Ended && isFullScreen) {
            exitFullScreen();
        }
        if (lifecycle !== previousLifeCycle.current) {
            if (
                lifecycle === SessionLifecycle.Started &&
                previousLifeCycle.current === SessionLifecycle.NotStarted &&
                isEditMode &&
                !childOfBreakoutRooms
            ) {
                toggleEditMode(false);
            }
            if (lifecycle === SessionLifecycle.Ended) {
                enableEndSessionModal(false);
            }

            previousLifeCycle.current = lifecycle;
        }

        if (isOwner) {
            return;
        }
        const designerSessionId = designer.state.getSessionId();

        // if assistant was removed, reset designer
        if (!isAssistant) {
            if (designerSessionId) {
                designer.state.initializeOrResetState(null);
            }
            return;
        }

        if (!designerSessionId || designerSessionId !== sessionId) {
            designer.state.initializeOrResetState(sessionId);
            if (lifecycle !== SessionLifecycle.Started) {
                const agendaItems = designer.currentAgendaItems();
                const hasAgenda = agendaItems.some(
                    (a) => a.type !== AgendaItemType.Instant || a.title !== designer.constants.DUMMY_AGENDA_ITEM_TITLE
                );
                if (hasAgenda) {
                    designer.state.update({
                        configurationStep: ConfigurationStep.Preview,
                    });
                }
            }
        }
    }, [lifecycle, isEditMode, isFullScreen, isAssistant, sessionId, isOwner]);

    useEffect(() => {
        const redirectToBorParent = sessionHasEnded && !!childOfBreakoutRooms;
        let canMountPlayer = true;
        let targetRoute: string | null = null;
        let targetRouteState: HistoryState | undefined = undefined;
        const borChildEnded = history.location.state?.borChildEnded;

        if (redirectToBorParent && childOfBreakoutRooms) {
            targetRouteState = {borChildEnded: true};
            targetRoute = "/session/" + childOfBreakoutRooms.parentOfBreakoutRooms.sessionId;
            evictLocalQueries(childOfBreakoutRooms.parentOfBreakoutRooms.sessionId);
        } else if (sessionHasEnded) {
            canMountPlayer = false;
            if (onboardingType === SessionOnboardingType.NewUser) {
                targetRoute = `/`;
            } else {
                if (borChildEnded !== true && participantNotJoined && !childOfBreakoutRooms && !props.isBorAndParent) {
                    targetRoute = hasMemoryAccess ? `/memory/session/${sessionId}` : `/feedback/${sessionId}`;
                } else {
                    if (isGuest) {
                        targetRoute = `/feedback/${sessionId}/${id}`;
                        targetRouteState = {requestFeedbackAtSessionEnd: true, isParticipantInSession: true};
                    } else {
                        targetRoute = `/feedback/${sessionId}`;
                        targetRouteState = {requestFeedbackAtSessionEnd: true, isParticipantInSession: true};
                    }
                }
            }
        }
        if (targetRoute != null) {
            canMountPlayer = false;
        }
        if (canMountPlayer) {
            const designerSessionId = designer.state.getSessionId();
            if (isOwner || isAssistant) {
                if (!designerSessionId || designerSessionId !== sessionId) {
                    designer.state.initializeOrResetState(sessionId);
                }
                // biome-ignore lint/complexity/noExtraBooleanCast: <explanation>
                if (!!createdAt) {
                    designer.restoreUpdates();
                }
            } else if (designerSessionId && !(isOwner || isAssistant)) {
                designer.state.initializeOrResetState(null);
            }
        }

        props.setCanMountPlayer((c) => (c !== canMountPlayer ? canMountPlayer : c));
        if (sessionHasEnded) {
            localStorage.removeItem(`mounted-${sessionId}`);
        }
        if (targetRoute != null) {
            setPidThatChangedArtifact(null);
            // console.log("history replace", targetRoute, targetRouteState);
            history.replace(targetRoute, targetRouteState);
        }
    }, [lifecycle]);

    useEffect(() => {
        function onStorage(e: StorageEvent) {
            if (e.key !== `mounted-${sessionId}` || e.newValue === null) {
                return;
            }
            if (!isMobileApp) {
                setJoinedFromOtherDevice(true);
            }
        }
        function markAsMounted() {
            // setItem needs a different value every time or the storage event won't be caught in the other tab
            // TODO MEGA-IMPORTANT: prevent accumulation of this key
            // though this key is removed when the session ends if a participant is in the session
            // there is more than one case where the key won't be removed
            // don't see an easy way of doing this without some sort of query to the BE
            localStorage.setItem(`mounted-${sessionId}`, `${Math.floor(Math.random() * 1000000 + 1)}`);
        }
        markAsMounted();
        window.addEventListener("storage", onStorage);
        return () => {
            window.removeEventListener("storage", onStorage);
        };
    }, [lifecycle]);

    return <></>;
}
