import {DrawerState} from "@generated/data";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {ReactComponent as AgendaIcon} from "../../assets/media/agenda_icon.svg";

type DrawerLeftActionButtonProps = {
    onClick?: () => void;
    className?: string;
};

function DrawerLeftActionButton(props: DrawerLeftActionButtonProps) {
    const {onClick, className} = props;

    const [, toggle] = useDrawerLeftToggler();

    const toggleLeftDrawer = () => {
        onClick?.();
        toggle(DrawerState.FullyOpen, "DrawerLeftActionButton:20");
    };

    return (
        <MenuItem className={className} onClick={toggleLeftDrawer}>
            <Typography color="nonary" fontWeight="bold" className="mr-4">
                Agenda
            </Typography>
            <AgendaIcon />
        </MenuItem>
    );
}

export default DrawerLeftActionButton;
