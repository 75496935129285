import Tooltip, {TooltipProps} from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import style from "./onboarding.module.scss";
import React, {useState, useEffect} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";

interface Props extends TooltipProps {
    tooltipId: string;
    skip?: boolean;
    show?: boolean;
    isPaletteTooltip?: boolean;
    noIntroTransition?: boolean;
}

export function OnboardingTooltip(props: Props) {
    const session = useSession();
    const {onboardingType, tooltip: currentTooltip} = useOnboardingSession();
    const [introTransition, setIntroTransition] = useState(true);
    const {isMobile} = useMobile();

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined = undefined;
        if (!props.skip) {
            setIntroTransition(true);

            timer = setTimeout(() => {
                setIntroTransition(false);
            }, 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [props.skip]);

    if ((session.onboardingType == null && !props.show) || isMobile) {
        return <>{props.children}</>;
    }

    const {
        tooltipId,
        skip,
        children,
        open = true,
        classes,
        PopperProps,
        title,
        disableHoverListener = true,
        disableFocusListener = true,
        disableTouchListener = true,
        show: showProp,
        ...rest
    } = props;

    const show = !!(open && currentTooltip === tooltipId && skip !== true) || !!showProp;
    const allProps = show ? rest : {};

    return (
        <Tooltip
            {...allProps}
            title={title}
            open={show}
            classes={{
                ...classes,
                popper: cls(style.popper, classes?.popper, props.isPaletteTooltip && style.paletteTooltip),
                tooltip: cls(
                    style.tooltip,
                    classes?.tooltip,
                    onboardingType === SessionOnboardingType.NewUser && style.animated,
                    props.isPaletteTooltip && style.paletteTooltip,
                    introTransition && !props.noIntroTransition && style.introTransition
                ),
                tooltipArrow: cls(style.tooltipArrow, classes?.tooltipArrow),
            }}
            PopperProps={{
                ...PopperProps,
                style: {
                    ...PopperProps?.style,
                    pointerEvents: "auto",
                },
            }}
            interactive
            disableHoverListener={disableHoverListener}
            disableFocusListener={disableFocusListener}
            disableTouchListener={disableTouchListener}
        >
            {children}
        </Tooltip>
    );
}
