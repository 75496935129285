import {SessionLifecycle} from "@generated/data";
import designer from "@workhorse/api/designer";
import {
    createConferenceArtifact,
    createHelpPageArtifact,
    createPresentationArtifact,
    createSmartBoardArtifact,
    createWebsiteShowCaseArtifact,
} from "./session-onboarding-agenda";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";

export async function createOnboardingSession(onboardingType: SessionOnboardingType, userId: string, website?: string, name?: string) {
    const id = designer.api.session.create({
        session: {
            name: name ?? "Session onboarding",
            lifecycle: SessionLifecycle.Started,
            timeDependency: false,
            quickSession: true,
            onboardingType,
            startAt: new Date(),
        },
        hasAgenda: onboardingType === SessionOnboardingType.FirstStrike,
    });

    designer.state.initializeOrResetState(id);

    if (onboardingType === SessionOnboardingType.FirstStrike) {
        await createConferenceArtifact();
        await createPresentationArtifact(userId);
        await createWebsiteShowCaseArtifact(userId, website);
        await createSmartBoardArtifact(userId);
        await createHelpPageArtifact(userId);
    }

    return designer.commitCreateSession();
}
