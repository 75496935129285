import {createContextProvider} from "@workhorse/api/utils/context";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {useIsPreJoin} from "@workhorse/providers/SessionDataProviders";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDeviceManagerEvents, useDevices} from "./DevicesProvider";

const defaultTimeout = 10;
const maxTimeout = 60;

function useDismiss() {
    const sessionId = useSessionIdFromRoute();
    const dismissKey = `devices:silent-mic-dismissed:${sessionId}`;

    const loadDismissed = useCallback(() => {
        const value = sessionStorage.getItem(dismissKey);
        return value === "1";
    }, [dismissKey]);

    const [dismissed, setDismissed] = useState(loadDismissed);

    const dismissWarning = useCallback(() => {
        sessionStorage.setItem(dismissKey, "1");
        setDismissed(true);
    }, [dismissKey]);

    useEffect(() => {
        setDismissed(loadDismissed());
    }, [loadDismissed]);

    return {dismissed, dismissWarning};
}

function useSilentMicStore() {
    const [isSilent, setIsSilent] = useState(false);
    const [lastSoundTime, setLastSoundTime] = useState(() => Date.now());

    const {audioStream, audioMuted} = useDevices();
    const [timeoutSeconds, setTimeoutSeconds] = useState(defaultTimeout);

    const {dismissed, dismissWarning} = useDismiss();

    const isPreJoin = useIsPreJoin();

    const resetInterval = useCallback(() => {
        setTimeoutSeconds(defaultTimeout);
    }, []);

    useDeviceManagerEvents({
        onAudioDetected: () => {
            setIsSilent(false);
            setLastSoundTime(Date.now());
            setTimeoutSeconds(maxTimeout);
        },
        onAudioSuccess: () => {
            resetInterval();
        },
    });

    useEffect(() => {
        if (dismissed || !audioStream || audioMuted) {
            setIsSilent(false);
            return;
        }

        const elapsed = Date.now() - lastSoundTime;
        const timeout = timeoutSeconds * 1000;

        const id = setTimeout(() => {
            setIsSilent(true);
        }, timeout);

        return () => {
            clearTimeout(id);
        };
    }, [dismissed, audioStream, audioMuted, lastSoundTime, timeoutSeconds]);

    return useMemo(
        () => ({
            isSilent: isSilent,
            shouldShowWarning: !isPreJoin && isSilent && !dismissed && !audioMuted,
            dismiss: dismissWarning,
        }),
        [isPreJoin, isSilent, dismissed, audioMuted, dismissWarning]
    );
}

export const [SilentMicProvider, useSilentMic] = createContextProvider(
    {
        name: "SilentMic",
    },
    useSilentMicStore
);
