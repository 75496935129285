import {useContext, useEffect} from "@workhorse/api/rendering";
import {InjectableIds, InjectorContext} from "./InjectorsProvider";

type InjectableContainerProps = {
    children: React.ReactNode | React.ReactNode[];
    id: string;
    className?: string | undefined;
    target: InjectableIds;
};

function Inject(props: InjectableContainerProps) {
    const {children: component, id, className, target} = props;
    const inject = useContext(InjectorContext);
    const into = target;
    useEffect(() => {
        // preview mode has no injector for header
        // but artifacts or components may try to inject in it either way
        if (!inject[into]) {
            return;
        }
        inject[into]({
            id,
            component,
            className,
        });
        return () => {
            inject[into]({id, remove: true, className});
        };
    }, [component, className]);
    return null as unknown as JSX.Element;
}
export default Inject;
