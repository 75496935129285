import {getDesktopApiOrThrow, isDesktopApp} from "@sessions/desktop-api";

export async function verifyDesktopPermission(type: "microphone" | "camera") {
    if (!isDesktopApp()) {
        return true;
    }

    const api = getDesktopApiOrThrow();
    const platform = await api.getPlatform();

    if (platform === "win32") {
        return verifyWindowsPermission(type);
    }

    if (platform === "darwin") {
        return verifyMacPermission(type);
    }

    return true;
}

async function verifyWindowsPermission(type: "microphone" | "camera") {
    const api = getDesktopApiOrThrow();

    const permission = await api.getMediaAccessStatus(type);

    if (permission === "granted") {
        return true;
    }

    // assume granted, shouldn't be the case
    if (permission === "unknown") {
        return true;
    }

    return false;
}

async function verifyMacPermission(type: "microphone" | "camera") {
    const api = getDesktopApiOrThrow();

    const permission = await api.getMediaAccessStatus(type);

    if (permission === "granted") {
        return true;
    }

    // assume granted, shouldn't be the case
    if (permission === "unknown") {
        return true;
    }

    return api.askForMediaAccess(type).catch((err) => {
        console.error("Mac permission access err:", err);
        return false;
    });
}
