import environment from "@generated/environment";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useUserInfo} from "@workhorse/providers/User";
import {ReactComponent as CheckboxCheckedIcon} from "../../../../../../assets/media/CheckboxCheckedIcon.svg";
import {ReactComponent as CheckboxUncheckedIcon} from "../../../../../../assets/media/CheckboxUncheckedIcon.svg";
import OnboardingPersonaWrapper from "./OnboardingPersonaWrapper";
import classes from "./styles/OnboardingPersonaUseCase.module.scss";
import {meetingTypesOptions} from "./utils";
import {useState} from "@workhorse/api/rendering";

type OnboardingPersonaMeetingTypesProps = {
    onClickStep: (step: number) => void;
    values: string[];
    onChange: (values: string[]) => void;
    otherValue: string;
    onChangeOtherValue: (value: string) => void;
    onSubmit: () => void;
    step: 1 | 2 | 3 | 4;
};

function OnboardingPersonaMeetingTypes(props: OnboardingPersonaMeetingTypesProps) {
    const {onClickStep, values, onChange, onSubmit, step, otherValue, onChangeOtherValue} = props;

    const user = useUserInfo();

    const [otherError, setOtherError] = useState<string | null>(null);

    const handleClick = (value: string) => {
        if (values.includes(value)) {
            onChange(values.filter((o) => o !== value));
            return;
        }

        onChange([...values, value]);
    };

    const handleChangeOtherValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const targetValue = e.target.value;

        onChangeOtherValue(targetValue);

        if (values.includes("other") && !targetValue.trim().length) {
            setOtherError("This field is required!");

            return;
        }

        setOtherError(null);
    };

    const handleSubmit = () => {
        if (values.includes("other") && !otherValue.trim().length) {
            setOtherError("This field is required!");

            return;
        }

        onSubmit();
    };
    const previewTitle = (
        <div className={cls(classes.previewTitle, classes.previewTitleBlue)}>
            Hello, {user.firstName}! You can now try the <span>Pro Plan for free for 10 days.</span>
        </div>
    );

    const previewDescription = (
        <div className={classes.previewDescription}>
            Start exploring and see what an impact Sessions can make on the way you work. There’s no need to add your credit card details.
        </div>
    );

    return (
        <OnboardingPersonaWrapper
            step={step}
            preview={{
                title: previewTitle,
                description: previewDescription,
                body: `${environment.staticServiceUrl}/assets/onboarding/onboarding-meeting.png`,
            }}
            onClickStep={onClickStep}
            classes={{
                rightCol: cls(classes.rightCol, classes.rightColBlue),
            }}
        >
            <Typography variant="xl2" fontWeight="bolder" className="mb-16">
                What are you planning to do in Sessions?
            </Typography>

            <div className={classes.useCaseOptionContainer}>
                {meetingTypesOptions.map((o) => (
                    <div className={cls(classes.useCase, classes.useCaseOption)} onClick={() => handleClick(o.value)} key={o.value}>
                        <Typography color="secondary" fontWeight="bold" className="mr-auto">
                            {o.text}
                        </Typography>

                        {values.includes(o.value) ? <CheckboxCheckedIcon className={classes.checkedCheckbox} /> : <CheckboxUncheckedIcon />}
                    </div>
                ))}
            </div>

            {values.includes("other") && (
                <>
                    <Input
                        name="meetingTypesOther"
                        formControlClassName="mt-20 p-0"
                        className={cls(classes.input, otherError ? classes.inputError : "")}
                        placeholder="Other"
                        value={otherValue}
                        onChange={handleChangeOtherValue}
                        maxCharCount={60}
                        showTypedCharCount
                        required
                        error={!!otherError}
                    />

                    {otherError ? <Typography className={classes.errorText}>{otherError}</Typography> : null}
                </>
            )}

            <Button disabled={!values.length} onClick={handleSubmit} className={classes.button}>
                Let&apos;s start
            </Button>
        </OnboardingPersonaWrapper>
    );
}

export default OnboardingPersonaMeetingTypes;
