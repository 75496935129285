import {getContactByEmail} from "@common/utils/contacts";
import Button from "@ui/cdk/Button";
import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {RemoteVideo} from "@workhorse/api/conference2";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {getParticipantName} from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import classes from "./style/ScreenShareContainer.module.scss";
import {useTranslation} from "react-i18next";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {LocalShareVideo} from "@workhorse/api/conference2/components/LocalVideo/LocalShareVideo";

type HeaderProps = {
    sessionId: string;
    sharingParticipantId: string;
    isLocalUserSharing: boolean;
};

function ScreenShareHeader(props: HeaderProps) {
    const {t} = useTranslation();

    const participant = useParticipant({participantId: props.sharingParticipantId});

    const resetId = useRef<NodeJS.Timeout | null>(null);
    const [hidden, setHidden] = useState(true);

    const participantData = participant?.dataWithNullableEmail;
    const contact = participantData?.email ? getContactByEmail(participantData.email) : null;
    const displayFirstName = contact ? contact.firstName : participantData?.firstName;
    const displayLastName = contact ? contact.lastName : participantData?.lastName;
    const fullName = getParticipantName(displayFirstName, displayLastName);

    const resetHidden = () => {
        if (resetId.current !== null) {
            clearTimeout(resetId.current);
        }
        resetId.current = setTimeout(() => {
            setHidden(true);
        }, 2000);
        setHidden(false);
    };

    useEffect(() => {
        return () => {
            if (resetId.current !== null) {
                clearTimeout(resetId.current);
            }
        };
    }, []);

    return (
        <div onMouseMove={resetHidden} onTouchStart={resetHidden} className={cls(classes.headerContainer, hidden && classes.hidden)}>
            <Typography data-id="share-screen-owner" className={cls("flex flex-center-all", classes.headerLeft)}>
                {props.isLocalUserSharing
                    ? t("player.share_screen.your_shared_screen") ?? "Your shared screen"
                    : t("player.share_screen.someones_screen", {name: fullName}) ?? `${fullName}'s screen`}
            </Typography>
        </div>
    );
}

type MaskProps = {
    isLocalUserSharing: boolean;
};

function ScreenShareMask(props: MaskProps) {
    const {t} = useTranslation();
    const {stopShareScreen, removeMask} = useScreenShareOptions();

    return (
        <div className={classes.mask}>
            <div className={classes.maskContainer}>
                <div>
                    <Typography variant="xl" fontWeight="bold">
                        {t("player.share_screen.you_are_now_sharing") ?? "You are now sharing your screen"}
                    </Typography>
                    <Typography>
                        {t("player.share_screen.you_are_now_sharing_description") ??
                            "To avoid an infinity mirror, don’t share your entire screen or browser window, share just a tab or a different window instead."}
                    </Typography>
                </div>
                <div className={classes.maskActions}>
                    <Button variant="secondary" size="small" onClick={stopShareScreen}>
                        {t("player.share_screen.stop_presenting") ?? "Stop presenting"}
                    </Button>
                    <Button variant="tertiary" size="small" onClick={removeMask}>
                        {t("g.ignore") ?? "Ignore"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

type ScreenShareContainerType = {
    sessionId: string;
    isGridMode: boolean;
    sharingParticipantId: string;
    isLocalUserSharing: boolean;
    withMask: boolean;
};

const getAspectRatio = (w: number, h: number): [number, "landscape" | "portrait"] => (w >= h ? [w / h, "landscape"] : [h / w, "portrait"]);

type InnerShareScreenContainerState = {
    width: number;
    height: number;
};

const ScreenShareContainer = (props: ScreenShareContainerType) => {
    const {sessionId, sharingParticipantId, isGridMode, isLocalUserSharing, withMask} = props;
    const innerStateRef = useRef<InnerShareScreenContainerState>({
        width: 0,
        height: 0,
    });

    const [ref, refNode] = useLocalResizeObserver<HTMLDivElement>(() => {
        const {width, height} = innerStateRef.current;

        handleVideoResize(width, height);
    });

    const handleVideoResize = useCallback(
        (vWidth: number, vHeight: number) => {
            if (!refNode || vWidth === 0 || vHeight === 0) {
                return;
            }

            const box = refNode.getBoundingClientRect();
            const boxWidth = box.width - 30;
            const boxHeight = box.height - 30;

            const [_, boxOrientation] = getAspectRatio(boxWidth, boxHeight);
            const [videoAspectRatio, videoOrientation] = getAspectRatio(vWidth, vHeight);

            let width: number = vWidth,
                height: number = vHeight;

            switch (`${boxOrientation}-${videoOrientation}` as `${typeof boxOrientation}-${typeof videoOrientation}`) {
                case "landscape-landscape":
                case "portrait-landscape":
                    height = Math.min(boxHeight, boxWidth / videoAspectRatio);
                    width = height * videoAspectRatio;
                    break;
                case "portrait-portrait":
                case "landscape-portrait":
                    width = Math.min(boxWidth, boxHeight / videoAspectRatio);
                    height = width * videoAspectRatio;
                    break;
            }

            innerStateRef.current = {
                ...innerStateRef.current,
                width: vWidth,
                height: vHeight,
            };

            refNode.style.setProperty("--app-share-screen-width", `${width}px`);
            refNode.style.setProperty("--app-share-screen-height", `${height}px`);
        },
        [refNode]
    );

    return (
        <div ref={ref} className={cls("flex flex-col flex-center-all", classes.root, isGridMode && "gridMode")}>
            <ScreenShareHeader sessionId={sessionId} sharingParticipantId={sharingParticipantId} isLocalUserSharing={isLocalUserSharing} />
            {isLocalUserSharing ? (
                <LocalShareVideo data-id="share-screen-video-local" participantId={sharingParticipantId} onResize={handleVideoResize} />
            ) : (
                <RemoteVideo
                    data-id="share-screen-video"
                    participantId={sharingParticipantId}
                    objectFit={false}
                    onResize={handleVideoResize}
                    isShareScreenVideo={true}
                />
            )}
            {isLocalUserSharing && withMask ? <ScreenShareMask isLocalUserSharing={isLocalUserSharing} /> : null}
        </div>
    );
};

export default ScreenShareContainer;
