import {ForgedArtifact, ForgedMacroArtifact} from "@workhorse/api/player";
import {useAgendaItems} from "@workhorse/providers/SessionDataProviders";

const DesignerGhostRenderer = (props: {ghosts: (ForgedArtifact | ForgedMacroArtifact)[]; inPopover?: boolean}) => {
    const {ghosts, inPopover} = props;

    const agendaItems = useAgendaItems();

    return (
        <>
            {ghosts
                .filter((a) => a.id)
                .map((artifactWithGhost, j) =>
                    artifactWithGhost.ghostComponents?.map((GhostComponent, i) => {
                        const agendaItem = agendaItems.find((x) => x.artifact?.id === artifactWithGhost.id);
                        return (
                            <GhostComponent
                                key={"ghost-loader-" + artifactWithGhost.id + j + i + artifactWithGhost.id}
                                agendaItem={agendaItem}
                                isAdmin={true}
                                inPopover={inPopover}
                                isAssistant={false}
                            />
                        );
                    })
                )}
        </>
    );
};

export default DesignerGhostRenderer;
