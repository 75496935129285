import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import classes from "../../../../styles/GenericSection.module.scss";
import {ReactComponent as TranscriptTogglerIcon} from "../../../../../../assets/media/macro-toggler-transcript.svg";
import {useTranslation} from "react-i18next";
import {SendSessionFlagsDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";

type WidgetTranscriptTogglerProps = {
    enabled: boolean;
    onChange: (macroArtifactId: string) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    transcriptionActive?: boolean;
    sessionId?: string;
};

const WidgetTranscriptToggler = (props: WidgetTranscriptTogglerProps) => {
    const {enabled, onChange, showIcon, disabled, className, transcriptionActive, sessionId} = props;

    const {t} = useTranslation();

    const handleOnChange = (e) => {
        if (!e.target.checked && transcriptionActive) {
            stopTranscript();
        }
        onChange("flowos/transcript");
    };

    const stopTranscript = () => {
        apollo.client.mutate({
            mutation: SendSessionFlagsDocument,
            variables: {
                sessionId: sessionId ?? "",
                flags: {
                    transcriptionActive: false,
                },
            },
        });
    };

    return (
        <div className={cls(classes.togglerWrapper, className)}>
            {showIcon ? (
                <div className={classes.iconWrapper}>
                    <TranscriptTogglerIcon />
                </div>
            ) : null}
            <TogglerContainer
                title={
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("macro.transcript.title")}
                    </Typography>
                }
                secondTitle={
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.widgets.transcript.description")}
                    </Typography>
                }
                checked={enabled}
                name="transcriptWidget"
                onChange={handleOnChange}
                togglePlacement="secondTitle"
                label=" "
                className={cls(classes.toggler)}
                disabled={disabled}
            />
        </div>
    );
};

export default WidgetTranscriptToggler;
