import React from "@workhorse/api/rendering";
import {useOnboardingBackdrop} from "@workhorse/providers/OnboardingSessionProvider";
import {Overlay} from "../overlay";
import {OverlayPortal} from "../overlay/overlay";

export function OverlayOnboarding() {
    const location = useOnboardingBackdrop();
    if (!location) {
        return null;
    }
    return (
        <OverlayPortal>
            <Overlay />
        </OverlayPortal>
    );
}
