interface VideoFxConfigBlur {
    blur: number;
}

interface VideoFxConfigImage {
    image: string;
}

export type VideoFxConfig = VideoFxConfigBlur | VideoFxConfigImage;

export function isVideoFxConfigBlur(config: VideoFxConfig): config is VideoFxConfigBlur {
    return "blur" in config;
}

export function isVideoFxConfigImage(config: VideoFxConfig): config is VideoFxConfigImage {
    return "image" in config;
}
