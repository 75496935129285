import {WithMobileState} from "@workhorse/declarations";
import ErrorBoundary from "./ErrorBoundary";

type WithErrorBoundaryProps = WithMobileState;
export function withErrorBoundary<T extends WithErrorBoundaryProps>(WrappedComponent: React.ComponentType<T>, propsPassThrough = false) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithBoundary = (props: T) => {
        const {mobileState, ...rest} = props;

        return (
            <ErrorBoundary mobileState={mobileState} location={displayName}>
                <WrappedComponent {...(rest as T)} {...(propsPassThrough ? {mobileState} : {})} />
            </ErrorBoundary>
        );
    };

    ComponentWithBoundary.displayName = `withErrorBoundary(${displayName})`;

    return ComponentWithBoundary;
}
