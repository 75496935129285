import svc from "@api/service/client";
import {PptV1} from "@sessions/common/resources";
import {CreatePspdfkitDocumentDocument, FindResourceByNameDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {setArtifactResourceResult} from "@workhorse/api/designer/methods";

async function findResourceByName(userId: string, name: string, type: string) {
    const resources = await apollo.client.query({
        query: FindResourceByNameDocument,
        variables: {
            content: {
                contains: `"name":"${name}"`,
            },
            userId: {
                equals: userId,
            },
            type: {
                equals: type,
            },
        },
    });

    const resource = resources.data.resources[0];

    if (resource == null) {
        return null;
    }

    return resource.id;
}

async function findOrCreatePresentationResource(userId: string, documentName: string, documentUrl: string) {
    const existingResource = await findResourceByName(userId, documentName, "flowos/ppt/resx/Ppt");

    if (existingResource) {
        return existingResource;
    }

    const res = await apollo.client.mutate({
        mutation: CreatePspdfkitDocumentDocument,
        variables: {
            documentUrl,
        },
    });

    if (res.data?.createPspdfkitDocument) {
        const resourceLocator = await svc.resx.createResource(
            "flowos/ppt/resx/Ppt",
            {
                name: documentName,
                type: "PPT",
                documentId: res.data.createPspdfkitDocument.documentId,
            } as PptV1,
            {
                isHidden: true,
            }
        );

        await svc.resx.updateResourceProcessingStatus(resourceLocator.id, {
            status: "FINISHED",
        });

        return resourceLocator.id;
    }
}

export async function setPresentationResource(userId: string, artifactId: string, documentName: string, documentUrl: string) {
    const resourceId = await findOrCreatePresentationResource(userId, documentName, documentUrl);

    if (resourceId) {
        await setArtifactResourceResult({
            id: artifactId,
            resourceSlotData: {
                type: "flowos/ppt/resx/Ppt",
                config: {
                    resource: resourceId,
                },
            },
        });
    }
}

async function findOrCreateIframeResource(userId: string, name: string, url: string) {
    const existingResource = await findResourceByName(userId, name, "flowos/iframe/resx/Iframe");

    if (existingResource) {
        return existingResource;
    }

    const resourceLocator = await svc.resx.createResource(
        "flowos/iframe/resx/Iframe",
        {
            name,
            url,
        },
        {
            isHidden: true,
        }
    );

    return resourceLocator.id;
}

export async function setIframeResource(userId: string, artifactId: string, name: string, url: string) {
    const resourceId = await findOrCreateIframeResource(userId, name, url);
    await setArtifactResourceResult({
        id: artifactId,
        resourceSlotData: {
            type: "flowos/iframe/resx/Iframe",
            config: {
                resource: resourceId,
            },
        },
    });
}

async function findOrCreateSmartBoardResource(userId: string, name: string) {
    const existingResource = await findResourceByName(userId, name, "flowos/blackboard/resx/Blackboard");

    if (existingResource) {
        return existingResource;
    }

    const resourceLocator = await svc.resx.createResource(
        "flowos/blackboard/resx/Blackboard",
        {
            name,
            roomId: makeId(),
        },
        {
            isHidden: true,
        }
    );

    return resourceLocator.id;
}

export async function setSmartBoardResource(userId: string, artifactId: string, name: string) {
    const resourceId = await findOrCreateSmartBoardResource(userId, name);

    await setArtifactResourceResult({
        id: artifactId,
        resourceSlotData: {
            type: "flowos/blackboard/resx/Blackboard",
            config: {
                resource: resourceId,
            },
        },
    });
}

async function findOrCreateYoutubeResource(userId: string, name: string, url: string) {
    const existingResource = await findResourceByName(userId, name, "flowos/iframe/resx/Iframe");

    if (existingResource) {
        return existingResource;
    }

    const resourceLocator = await svc.resx.createResource(
        "flowos/youtube/resx/Youtube",
        {
            name,
            type: "Youtube",
            videoSource: url,
        },
        {
            isHidden: true,
        }
    );

    return resourceLocator.id;
}

export async function setYoutubeResource(userId: string, artifactId: string, name: string, url: string) {
    const resourceId = await findOrCreateYoutubeResource(userId, name, url);
    await setArtifactResourceResult({
        id: artifactId,
        resourceSlotData: {
            type: "flowos/iframe/resx/Iframe",
            config: {
                resource: resourceId,
            },
        },
    });
}
