import Typography from "@ui/cdk/Typography";
import {ReactComponent as EditAgendaIcon} from "../../../../player/assets/media/edit_agenda.svg";
import {ConfigurationStep} from "@generated/data";
import designer from "@workhorse/api/designer";
import {DrawerHookState} from "@workhorse/declarations";
import {DrawerState} from "@generated/data";
import classes from "../style/AgendaActionsMenu.module.scss";
import {isMacOS} from "@workhorse/util";
import {useTranslation} from "react-i18next";

const EditAgenda = () => {
    const {t} = useTranslation();
    const isMac = isMacOS();

    return (
        <div className="flex flex-row flex-items-center flex-justify-between fullw">
            <div className="flex flex-row flex-items-center">
                <EditAgendaIcon className="mr-10" />
                <Typography color="nonary" fontWeight="bold" className="mr-4">
                    {t("agenda.menu.edit_agenda") ?? "Edit agenda"}
                </Typography>
            </div>
            <div className="flex flex-align-center">
                <div className={classes.commandKey}>{!isMac ? "Alt" : "⌥"}</div>
                <div className={classes.commandKey}>Z</div>
            </div>
        </div>
    );
};

export default EditAgenda;
