export enum ConferenceLogLevel {
    DEBUG = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3,

    NONE = 100,
}

const defaultLogLevel = ConferenceLogLevel.DEBUG;

const notAllowedFn = (..._args: any[]) => {};

function createLogFn(allowed: boolean, fn: (...args: any[]) => void) {
    if (allowed) {
        return fn;
    }
    return notAllowedFn;
}

export function createRoomLogger(namespace: string, level = defaultLogLevel) {
    const allowDebug = level <= ConferenceLogLevel.DEBUG;
    const allowInfo = level <= ConferenceLogLevel.INFO;
    const allowWarn = level <= ConferenceLogLevel.WARN;
    const allowError = level <= ConferenceLogLevel.ERROR;

    return {
        debug: createLogFn(allowDebug, (...args: any[]) => {
            console.debug(`[${namespace}]`, ...args);
        }),
        info: createLogFn(allowInfo, (...args: any[]) => {
            console.info(`[${namespace}]`, ...args);
        }),
        warn: createLogFn(allowWarn, (...args: any[]) => {
            console.warn(`[${namespace}]`, ...args);
        }),
        error: createLogFn(allowError, (...args: any[]) => {
            console.error(`[${namespace}]`, ...args);
        }),
    };
}
