import {DrawerState} from "@generated/data";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {TrackingEventCategory, useMixpanelTracker, useTrackingEvents} from "@workhorse/api/tracking";
import {rbac} from "@workhorse/api/user";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import Inject from "@workhorse/providers/Injector";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useIsLobby, useSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import {useShallow} from "zustand/react/shallow";
import TimerToggler from "../TimerToggler";
import {getInitialPopupState} from "../utils";
import TimerContainer from "./TimerContainer";
import TimerPopup from "./TimerPopup";

type TimerWrapperProps = {
    isMemoryMode: boolean;
};

function TimerWrapper(props: TimerWrapperProps) {
    const {isMemoryMode} = props;

    const {isRecorder} = useUserInfo();

    const {isLobby} = useIsLobby();

    const {isMobile} = useMobile();
    const session = useSession();
    const {timerStartAt, timerInitialDuration, timerCurrentDuration, timerState} = session;
    const currentParticipant = useParticipantsStore(
        useShallow(({currentParticipant}) => ({id: currentParticipant?.id ?? "", isOwner: currentParticipant?.isOwner ?? false}))
    );

    const isOwner = currentParticipant.isOwner;
    const isAssistant = rbac(currentParticipant.id, "session.isAssistant");
    const prevIsAssistant = useRef<boolean>(isAssistant);

    const isOrganizer = isOwner || isAssistant;

    const [trackEvent] = useTrackingEvents();
    const {mixpanelTrack} = useMixpanelTracker();
    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive, setActiveComponent} = useDrawerRightActiveComponent("timer");

    const [showPopup, setShowPopup] = useState<boolean>(getInitialPopupState(timerState, timerStartAt, timerCurrentDuration));
    const [isPopupMinimized, setIsPopupMinimized] = useState<boolean>(false);

    const closePopupTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const isMacroHidden = session?.restrictedWidgets.disabled?.some((x) => x === "timer");

    const closeDrawerTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // the component may become inactive due to some other one becoming active
        // AFTER we just clicked the toggler
        // meaning, that if some other component becomes active during the 500ms timeout
        // the timeout will execute after, emptying the drawer
        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }

        if (isActive) {
            trackEvent("timer_toggled", {event_category: TrackingEventCategory.PlayerActions});
        }
    }, [isActive, trackEvent]);

    const onToggle = () => {
        const toggleDrawerCond = ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) || (isActive && rightDrawerState.isFullyOpen);

        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }

        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent("timer");
        } else if (isActive) {
            setActiveComponent(null);
        }

        if (toggleDrawerCond) {
            toggleRightDrawer();
        }
        if (!isActive && isMemoryMode) {
            mixpanelTrack("frontend-timer", "memory");
        }
    };

    useEffect(() => {
        if (isActive && isMacroHidden) {
            toggleRightDrawer(DrawerState.Closed);
            setActiveComponent(null);
        }
    }, [isMacroHidden, isLobby]);

    useEffect(() => {
        if (prevIsAssistant.current && !isAssistant) {
            toggleRightDrawer(DrawerState.Closed);
            setActiveComponent(null);
        }

        prevIsAssistant.current = isAssistant;
    }, [isAssistant]);

    useEffect(() => {
        if (closePopupTimeoutRef.current) {
            clearTimeout(closePopupTimeoutRef.current);
        }

        setShowPopup(getInitialPopupState(timerState, timerStartAt, timerCurrentDuration));
    }, [timerStartAt, timerState]);

    useEffect(() => {
        return () => {
            if (closePopupTimeoutRef.current) {
                clearTimeout(closePopupTimeoutRef.current);
            }
        };
    }, []);

    const handleClosePopup = () => {
        if (closePopupTimeoutRef.current) {
            clearTimeout(closePopupTimeoutRef.current);
        }

        closePopupTimeoutRef.current = setTimeout(() => {
            setShowPopup(false);
        }, 3000);
    };

    if (isMacroHidden) {
        return <></>;
    }

    return (
        <>
            {isActive && isOrganizer ? (
                <Inject target="inDrawerRight" id="player-timer-drawer-right">
                    <TimerContainer
                        isMemoryMode={isMemoryMode}
                        initialDuration={timerInitialDuration ?? undefined}
                        timerStartAt={timerStartAt ?? undefined}
                    />
                </Inject>
            ) : !isRecorder && showPopup ? (
                <Inject target="inPlayer" id="player-timer-popup" key="player-timer-popup">
                    <TimerPopup
                        isPopupMinimized={isPopupMinimized}
                        setIsPopupMinimized={setIsPopupMinimized}
                        onTimesUp={handleClosePopup}
                        initialDuration={timerInitialDuration ?? undefined}
                        timerStartAt={timerStartAt ?? undefined}
                        isMobile={isMobile}
                    />
                </Inject>
            ) : null}

            {isOrganizer && (
                <Inject target="inFooterRight" id="macro-timer-toggler">
                    <TimerToggler artifactId={"timer"} isLobby={isLobby} onToggle={onToggle} isActive={isActive} inDrawer={false} />
                </Inject>
            )}
        </>
    );
}

export default TimerWrapper;
