function addZero(value: string) {
    return value.length === 1 ? "0" + value : value;
}

export function beautifySeconds(elapsedSeconds: number) {
    const hours = Math.floor(elapsedSeconds / 3600).toString();
    const minutes = Math.floor((elapsedSeconds % 3600) / 60).toString();
    const seconds = Math.floor((elapsedSeconds % 3600) % 60).toString();

    return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
}
