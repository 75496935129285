import {Offer} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "../styles/OfferNotification.module.scss";
import Button from "@ui/cdk/Button";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {offerShow} from "@workhorse/pages/player/playerStateVars";
import {openExternalLink} from "@workhorse/util/links";

type OfferNotificationProps = {
    offer: Offer | null;
    isMobile: boolean;
};

const OfferNotification = (props: OfferNotificationProps) => {
    const {offer, isMobile} = props;

    function handleOfferClick() {
        if (!offer?.ctaUrl) return;
        const urlToOpen = offer?.ctaUrl ? offer?.ctaUrl : "";
        try {
            new URL(urlToOpen);
            openExternalLink(urlToOpen);
        } catch {
            openExternalLink(`https://${urlToOpen}`);
        }
    }

    function handleClose() {
        offerShow(null);
    }

    if (!offer) return null;

    return (
        <div className={classes.root}>
            <div className={cls(classes.offerNotificationImageWrapper)}>
                <img src={props.offer?.imageUrl} />
            </div>
            <div className={cls("flex flex-col gap-10 flex-align-start flex-justify-between fullh px-24 py-15")}>
                <div className="flex flex-col">
                    <Typography variant="xl2" fontWeight="bolder" color="primary" className={classes.offerNotificationTitle}>
                        {props.offer?.title}
                    </Typography>
                    <Typography variant="base" color="secondary" className={classes.offerNotificationDescription}>
                        {props.offer?.description}
                    </Typography>
                </div>

                <Button onClick={handleOfferClick} onTouchEnd={handleOfferClick} endIcon={<OpenInNewRoundedIcon />} fullWidth={isMobile}>
                    {props.offer?.ctaText}
                </Button>
            </div>

            <div className={classes.closeIcon} onClick={handleClose} onTouchEnd={handleClose}>
                <CloseRoundedIcon />
            </div>
        </div>
    );
};

export default OfferNotification;
