import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {forwardRef, useState} from "react";
import styles from "./style/tagInput.module.scss";
import {TagInputProps} from "./types";
import {cls, capitalize} from "@ui/cdk/util/util";

function TagInputWithRef(props: TagInputProps, ref: any) {
    const {
        /**
         * @param {ReactElement} avatar Avatar element
         */
        avatar,
        /**
         * @param {Boolean} clickable If true, the chip will appear clickable, and will raise when pressed, even if the onClick prop is not defined. If false, the chip will not be clickable, even if onClick prop is defined. This can be used, for example, along with the component prop to indicate an anchor Chip is clickable.
         */
        clickable,
        /**
         * @param {TagColorTypes} color The color of the component. @default "green"
         */
        color = "green",
        /**
         * @param {Boolean} disabled If true, the chip should be displayed in a disabled state. @default false
         */
        disabled = false,
        /**
         * @param {Function} onRemove Callback function fired when the delete icon is clicked. If set, the delete icon will be shown.
         */
        onRemove,
        /**
         * @param {TagVariantTypes} variant The variant to use. @default "default"
         */
        variant = "medium",
        /**
         * @param {String} variant Adds a className to the root.
         */
        className,
        /**
         * @param {Boolean} selected Applies selected styles if the element is selected. @default false
         */
        selected = false,
        /**
         * @param {Boolean} error Displays error styles if the input has errors @default false
         */
        error = false,
        /**
         * @param {RegExp} delimiters Regex string containing delimiter characters
         */
        delimiters,
        /**
         * @param {Boolean} editable If true, on double click the tag becomes an input @default true
         */
        editable = true,
        ...otherProps
    } = props;

    const [activeInput, setActiveInput] = useState(false);

    const [currentValue, setCurrentValue] = useState(otherProps.defaultValue);

    const handleDoubleClick = () => {
        if (editable) {
            setActiveInput(true);
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setActiveInput(false);
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const handleInput = (event) => {
        setCurrentValue(event.target.value);
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };

    const handleKeyDown = (event) => {
        if (delimiters?.test(event.key) || event.key === "Enter") {
            setActiveInput(false);
        }
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    };

    const classNames = cls(
        "fosTagInput",
        styles.root,
        styles[`color${capitalize(color)}`],
        styles[`variant${capitalize(variant)}`],
        disabled && styles.disabled,
        clickable && styles.clickable,
        error && cls("error", styles.error),
        selected && styles.selected,
        activeInput && styles.activeInput,
        !avatar && styles.withPaddingLeft,
        className
    );

    return (
        <div className={classNames} ref={ref} key="avatar">
            {avatar && (
                <div className={styles.avatarContainer} key="avatar-img-container">
                    {avatar}
                </div>
            )}
            {activeInput ? (
                <input
                    autoFocus
                    key="tag-input"
                    {...otherProps}
                    onBlur={handleBlur}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}
                    value={currentValue}
                ></input>
            ) : (
                <>
                    <div className={styles.container} key="avatar-text-container" onDoubleClick={handleDoubleClick}>
                        {currentValue}
                    </div>
                    {onRemove ? <CloseRoundedIcon className={styles.removeIcon} onClick={onRemove} key="avatar-remove-icon" /> : null}
                </>
            )}
        </div>
    );
}

const TagInput = forwardRef(TagInputWithRef) as (props: TagInputProps & {ref?: unknown}) => ReturnType<typeof TagInputWithRef>;

export {TagInputWithRef};
export default TagInput;
