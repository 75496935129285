import {getContactByEmail} from "@common/utils/contacts";
import {
    ConferenceParticipantStatus,
    OneSessionFragment,
    ParticipantStatus,
    SessionLifecycle,
    SessionParticipantFragment,
} from "@generated/data";
import {participantIsAssistant} from "@workhorse/api/conference2/utils";
import {rbac} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";
import {ParticipantsRoster} from "../ConferenceRoomProvider";

export type PrticipantListsByCategory = {
    moderatorsParticipants: Participant[];
    waitingToJoinParticipants: Participant[];
    joinedParticipants: Participant[];
    notJoinedParticipants: Participant[];
    invitedParticipants: Participant[];
    speakersParticipants: Participant[];
    conferenceSpeakers: Participant[];
    conferenceInvitedSpeakers: Participant[];
    conferenceJoinedSpeakers: Participant[];
    conferencePendingSpeakers: Participant[];
};

export const sortAllParticipants = (participants: Participant[]) => {
    return [...participants].sort((a, b) => {
        const A = (a?.dataWithNullableEmail?.firstName || "").toUpperCase().trim();
        const B = (b?.dataWithNullableEmail?.firstName || "").toUpperCase().trim();
        // @ts-expect-error
        const isNumeric = !isNaN(A) || (A?.length > 0 && !isNaN(A?.charAt(0)));

        if (isNumeric) {
            return 1;
        }

        return !B || (A && A < B) ? -1 : !A || A > B ? 1 : 0;
    });
};

export const searchParticipants = (participants: Participant[], searchValue: string) => {
    if (!searchValue.length) return participants;

    const searchValueLowerCased = searchValue.toLowerCase();

    return participants?.filter((p) => {
        const contact = getContactByEmail(p.dataWithNullableEmail.email);

        const contactFirstNameLowerCased = contact?.firstName.toLowerCase().trim();
        const contactLastNameLowerCased = contact?.lastName.toLowerCase().trim();
        const contactFullNameLowerCased = `${contactFirstNameLowerCased} ${contactLastNameLowerCased}`.trim();

        const firstNameLowerCased = p.dataWithNullableEmail.firstName.toLowerCase().trim();
        const lastNameLowerCased = p.dataWithNullableEmail.lastName.toLowerCase().trim();
        const fullNameLowerCased = `${firstNameLowerCased} ${lastNameLowerCased}`.trim();

        const emailLowerCased = p.dataWithNullableEmail.email?.toLowerCase().trim();

        return `${contactFullNameLowerCased}${emailLowerCased}${fullNameLowerCased}`.includes(searchValueLowerCased);
    });
};

export const filterApprovedParticipant = (session: OneSessionFragment, p: SessionParticipantFragment) => {
    if (session.lifecycle === SessionLifecycle.NotStarted) {
        return true;
    }

    if (session.requestPermissionToJoin && !p.isOwner && !rbac(p, "session.isAssistant") && !p.isApproved) {
        return false;
    }

    if (session.provideSessionPasscode && !p.isOwner && !rbac(p, "session.isAssistant") && !p.submittedPasscode) {
        return false;
    }

    return true;
};

export const splitParticipants = (
    participants: Participant[],
    roster: ParticipantsRoster,
    session: OneSessionFragment,
    isLobby: boolean,
    isMemoryMode: boolean,
    fullList?: boolean
) => {
    const agg: PrticipantListsByCategory = {
        moderatorsParticipants: [],
        waitingToJoinParticipants: [],
        joinedParticipants: [],
        notJoinedParticipants: [],
        invitedParticipants: [],
        speakersParticipants: [],
        conferenceSpeakers: [],
        conferenceInvitedSpeakers: [],
        conferenceJoinedSpeakers: [],
        conferencePendingSpeakers: [],
    };

    for (const p of participants) {
        // Participant is joined?
        let isJoined = p.status === ParticipantStatus.JoinedSession || roster[p.id] === true;

        if (isLobby) {
            isJoined = p.status === ParticipantStatus.InLobby || roster[p.id] === true;
        }

        if (isMemoryMode) {
            isJoined = p.hasAttendedSession;
        }

        // Is waiting to join
        if (p.status === ParticipantStatus.WaitForApproval) {
            agg.waitingToJoinParticipants.push(p);
        }

        // Is Moderator
        if (
            (p.isOwner || participantIsAssistant(p)) &&
            p.status !== ParticipantStatus.WaitForApproval &&
            p.status !== ParticipantStatus.Kicked &&
            isJoined
        ) {
            agg.moderatorsParticipants.push(p);
        }

        // Is Speaker

        if (p.speakerDetails != null && !p?.isOwner && !participantIsAssistant(p) && isJoined) {
            agg.speakersParticipants.push(p);
        }

        // is Joined
        if (
            !fullList &&
            !p.isOwner &&
            !participantIsAssistant(p) &&
            p.status !== ParticipantStatus.WaitForApproval &&
            p.status !== ParticipantStatus.Kicked &&
            isJoined &&
            filterApprovedParticipant(session, p) &&
            !p.speakerDetails
        ) {
            agg.joinedParticipants.push(p);
        }

        if (
            fullList &&
            p.status !== ParticipantStatus.WaitForApproval &&
            p.status !== ParticipantStatus.Kicked &&
            isJoined &&
            filterApprovedParticipant(session, p)
        ) {
            agg.joinedParticipants.push(p);
        }

        // Is not joined
        if (
            !p.isOwner &&
            p.invitedByTheOwner &&
            p.status !== ParticipantStatus.WaitForApproval &&
            p.status !== ParticipantStatus.Kicked &&
            !isJoined
        ) {
            agg.notJoinedParticipants.push(p);
        }

        // is invited
        if (!p.isOwner && p.invitedByTheOwner) {
            agg.invitedParticipants.push(p);
        }
        if (p.conferenceStatus === ConferenceParticipantStatus.Speaker || !!p.speakerDetails?.id || p.mockedParticipant) {
            agg.conferenceSpeakers.push(p);
            if (p.status === "JOINED_SESSION") {
                agg.conferenceJoinedSpeakers.push(p);
            }
        }

        if (p.conferenceStatus === ConferenceParticipantStatus.InvitedToSpeak) {
            agg.conferenceInvitedSpeakers.push(p);
        }

        if (p.conferenceStatus === ConferenceParticipantStatus.PendingSpeaker) {
            agg.conferencePendingSpeakers.push(p);
        }
    }
    return agg;
};

export const buildConferenceParticipants = (
    participants: Participant[],
    roster: ParticipantsRoster,
    currentParticipantId: string,
    isLobby: boolean
): {conferenceAudience: Participant[]; conferenceSpotlight: Participant[]} => {
    if (isLobby) {
        return {
            conferenceAudience: [],
            conferenceSpotlight: [],
        };
    }

    const self: Participant[] = [];
    const owners: Participant[] = [];
    const assistants: Participant[] = [];
    const spotlightParticipants: Participant[] = [];
    const raisedHands: Participant[] = [];
    const others: Participant[] = [];

    for (const p of participants) {
        let isJoined = p.status === ParticipantStatus.JoinedSession || roster[p.id] === true;

        if (!isJoined) {
            continue;
        }

        if (p.id === currentParticipantId) {
            self.push(p);
            continue;
        }

        if (p.isOwner) {
            owners.push(p);
            continue;
        }

        if (rbac(p, "session.isAssistant")) {
            assistants.push(p);
            continue;
        }

        if (p.conferenceStatus === ConferenceParticipantStatus.Speaker) {
            spotlightParticipants.push(p);
            continue;
        }

        if (p.conferenceStatus === ConferenceParticipantStatus.PendingSpeaker) {
            raisedHands.push(p);
            continue;
        }

        others.push(p);
    }

    const audienceParticipants = [...self, ...owners, ...assistants, ...spotlightParticipants, ...raisedHands, ...others];

    return {conferenceAudience: audienceParticipants, conferenceSpotlight: spotlightParticipants};
};

export const getParticipantsCount = (
    participants: Participant[],
    roster: ParticipantsRoster,
    session: OneSessionFragment,
    isMemoryMode?: boolean
) => {
    let totalParticipantsCount: number = 0;
    let activeParticipantsCount: number = 0;

    for (const p of participants) {
        if (p.isRecorder) {
            continue;
        }

        if (isMemoryMode && !p.hasAttendedSession) {
            continue;
        }

        const hasAttendedStatus = [ParticipantStatus.InLobby, ParticipantStatus.JoinedSession].includes(p.status) || roster[p.id] === true;

        const isApproved = hasAttendedStatus && filterApprovedParticipant(session, p);

        if (isApproved) {
            totalParticipantsCount += 1;
            activeParticipantsCount += 1;
            continue;
        }

        if (!p.invitedByTheOwner) {
            continue;
        }

        if (!hasAttendedStatus) {
            totalParticipantsCount += 1;
        }
    }

    return {totalParticipantsCount, activeParticipantsCount};
};
