import {ArtifactTag} from "@generated/artifacts";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {MacroArtifact} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";
import {makeId} from "../../utils";

type ReplaceMacroArtifactType = DeepMandatoryWithNullVals<MacroArtifact>;
export interface ReplaceMacroArtifactInput {
    id: string;
    artifact: Partial<Omit<ReplaceMacroArtifactType, "data">>;
}

const diffConstants = {
    isDeleted: false,
    oldId: null,
    update: null,
    createdAt: null as unknown as Date,
    updatedAt: null as unknown as Date,
};

export class ReplaceMacroArtifact extends DesignerAction<ReplaceMacroArtifactInput> {
    commit() {
        const macroArtifacts = structuredClone(designer.currentMacroArtifacts() ?? []);
        const removeIndex = macroArtifacts.findIndex((a) => a.id === this.input.id);
        if (removeIndex === -1) {
            return this.input.id;
        }

        const currentMacro = macroArtifacts[removeIndex!];
        const newMacroId = this.input.artifact.id && this.input.artifact.id !== currentMacro.id ? this.input.artifact.id : makeId();
        const now = null as unknown as Date;

        const {artifactId, isConfigured, description = "", properties, name = ""} = this.input.artifact;

        const newMacro: ReplaceMacroArtifactType = {
            id: newMacroId,
            oldId: currentMacro.oldId ?? currentMacro.id ?? null,
            __typename: "Artifact",
            artifactId: artifactId ?? currentMacro.artifactId,
            startAt: null,
            endedAt: null,
            durationInSeconds: null,
            isConfigured: typeof isConfigured === undefined ? true : isConfigured || false,
            description,
            order: null,
            data: null,
            properties: (currentMacro.properties || [])
                .filter((p) => !p.isDeleted && p.createdAt)
                .map((p) => {
                    return {
                        ...p,
                        isDeleted: true,
                    };
                })
                .concat(
                    (properties || []).map((p) => {
                        return {
                            ...p,
                            id: makeId(),
                            isDeleted: false,
                            update: null,
                            createdAt: now,
                            updatedAt: now,
                            oldId: null,
                        };
                    })
                ),
            isMacro: true,
            name,
            isDeleted: false,
            update: null,
            createdAt: now,
            updatedAt: now,
        };

        macroArtifacts[removeIndex!] = newMacro;

        designer.updateCurrentSession(
            {
                macroArtifacts,
            },
            {
                strategy: "replace",
            }
        );

        return this.input.id;
    }
}
