import loadable from "@workhorse/api/loadable";
import {ReactComponent as LivestreamingIcon} from "../../../../assets/media/livestream-icon.svg";

import {SessionSettingsSection} from "../../definitions";

const LivestreamingSection = loadable(() => import("./LivestreamingSection"));
LivestreamingSection.displayName = "Loadable:SessionSection";

const LivestreamingSectionDefinition: SessionSettingsSection<"general.livestreaming"> = {
    translateKey: "general.livestreaming",
    kind: "general.livestreaming",
    title: "Access",
    component: LivestreamingSection,
    icon: LivestreamingIcon,
    rbac: {
        "session.isAssistant": true,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default LivestreamingSectionDefinition;
