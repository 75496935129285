import {EditOrganizationMembersDocument, OrganizationPermissionType} from "@generated/data";
import Select from "@ui/cdk/Select";
import {cls} from "@ui/cdk/util";
import {useEffect} from "@workhorse/api/rendering";
import {useState} from "@workhorse/api/state";
import classes from "../WorkspaceUserManagementRole/UserManagementRoleSelect.module.scss";
import {beautifyRoleName} from "./utils";
import {useMutation} from "@apollo/client";
import toast from "@workhorse/api/toast";

interface Props {
    className?: string;
    value?: OrganizationPermissionType;
    loading?: boolean;
    large?: boolean;
    onChange: (value: OrganizationPermissionType) => void;
    disabled?: boolean;
}

export function OrganizationUserManagementRoleSelect(props: Props) {
    const selectOptions = [
        {
            value: OrganizationPermissionType.Member,
            text: beautifyRoleName(OrganizationPermissionType.Member),
        },
        {
            value: OrganizationPermissionType.Owner,
            text: beautifyRoleName(OrganizationPermissionType.Owner),
        },
    ];

    return (
        <div className={classes.root}>
            <Select
                data-id="role-select"
                classes={{root: cls(classes.permissionSelect, props.large && "large")}}
                disableUnderline
                variant="standard"
                value={props.value}
                disabled={!!props.disabled}
                wrapperClassName={props.className}
                onChange={(e) => props.onChange(e.target.value as OrganizationPermissionType)}
                onClick={(e) => e.stopPropagation()}
                options={selectOptions}
            />
        </div>
    );
}

interface WithUpdateProps {
    className?: string;
    userId: string;
    userOrganizationRole: OrganizationPermissionType;
    organizationId: string;
    disabled?: boolean;
}

export function OrganizationUserManagementRoleSelectWithUpdate(props: WithUpdateProps) {
    const [value, setValue] = useState(props.userOrganizationRole);

    const [editMembers] = useMutation(EditOrganizationMembersDocument, {
        onError: (err) => {
            setValue(props.userOrganizationRole);
            toast(err.message, {
                type: "error",
            });
        },
    });

    useEffect(() => {
        setValue(props.userOrganizationRole);
    }, [props.userOrganizationRole]);

    const handleChange = (value: OrganizationPermissionType) => {
        console.log("updating with value", value);
        if (value === props.userOrganizationRole) {
            return;
        }
        setValue(value);
        editMembers({
            variables: {
                membersToEdit: {userId: props.userId, role: value},
            },
        });
    };

    return <OrganizationUserManagementRoleSelect disabled={props.disabled} value={value} onChange={handleChange} />;
}
