import {WithMobileState, WithSessionEditState} from "@workhorse/declarations";
import SessionDataProviders from "@workhorse/providers/SessionDataProviders";
import AgendaTemplatesMain from "./AgendaTemplatesMain/AgendaTemplatesMain";
import TemplateProviders from "./TemplateProviders";

type TemplatesProps = WithSessionEditState & WithMobileState;

const Templates = (props: TemplatesProps) => {
    return (
        <TemplateProviders>
            <SessionDataProviders isEditMode>
                <AgendaTemplatesMain {...props} />
            </SessionDataProviders>
        </TemplateProviders>
    );
};

export default Templates;
