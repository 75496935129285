import {useClientEvent} from "@api/events/client";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import {isDesktopApp} from "@sessions/desktop-api";
import LinearProgress from "@ui/cdk/LinearProgress";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useState} from "@workhorse/api/rendering";
import {useDownloadStatus, useShowFileFolder} from "@workhorse/providers/DownloadProvider";
import {prettyBytes} from "@workhorse/util/bytes";
import {useTranslation} from "react-i18next";
import NotificationWrapper from "./NotificationWrapper";
import classes from "./style/DownloadNotification.module.scss";

type DownloadNotificationEntryProps = {
    id: string;
    onClose?: () => void;
    className?: string;
};

export const DownloadNotificationEntry = (props: DownloadNotificationEntryProps) => {
    const {id, onClose, className} = props;

    const item = useDownloadStatus(id);
    const show = useShowFileFolder();

    if (!item) {
        return null;
    }

    const {t} = useTranslation();

    const displayProgress = (item.progress * 100) | 0;

    return (
        <NotificationWrapper className={cls(classes.itemRoot, className)} onClose={onClose}>
            <div className="flex fullw">
                <div className={classes.itemIcon}>
                    <DescriptionOutlinedIcon />
                </div>
                <div className={classes.itemContent}>
                    <div className="flex flex-justify-between">
                        <Typography className="mr-6" fontWeight="bold" noWrap>
                            {item.fileName}
                        </Typography>
                    </div>
                    <div className="flex flex-align-center gap-10">
                        {item.totalBytes != null && <Typography color="tertiary">{prettyBytes(item.totalBytes)}</Typography>}
                        {item.completed && item.savePath && (
                            <>
                                -{" "}
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (item.savePath) {
                                            show(item.savePath);
                                        }
                                    }}
                                    className="cursor-pointer"
                                >
                                    {t("g.show_in_folder")}
                                </Link>
                            </>
                        )}
                    </div>
                    <div className="flex flex-align-center mt-4">
                        {item.inProgress && (
                            <>
                                <LinearProgress value={displayProgress} variant="determinate" />
                                <Typography fontWeight="bold" className="ml-12">
                                    {displayProgress}%
                                </Typography>
                            </>
                        )}
                        {(item.cancelled || item.error) && (
                            <>
                                <ErrorOutlineRoundedIcon className={classes.itemFailedIcon} />
                                <Typography color="red400">Failed</Typography>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </NotificationWrapper>
    );
};

type DownloadNotificationProps = {
    className?: string;
    location?: string;
};

const DownloadNotification = (props: DownloadNotificationProps) => {
    const {className} = props;

    const [showingId, setShowingId] = useState<string | null>(null);

    useClientEvent("file-downloading", (id: string, location?: string) => {
        if (props.location !== location) {
            return;
        }

        setShowingId(id);
    });

    const handleClose = () => {
        setShowingId(null);
    };

    if (!showingId || !isDesktopApp()) {
        return null;
    }

    return <DownloadNotificationEntry id={showingId} onClose={handleClose} className={className} />;
};

export default DownloadNotification;
