import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {Fragment, useState} from "@workhorse/api/rendering";
import SettingsIcon from "@material-ui/icons/Settings";
import classes from "./styles/ParticipantsCategoryBlock.module.scss";
import Menu from "@ui/cdk/Menu";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import Checkbox from "@ui/cdk/Checkbox";
import Typography from "@ui/cdk/Typography";
import {
    setAllowScreenShare,
    setSessionDisableParticipantsVideo,
    setSessionHardMute,
    softMuteAllParticipants,
    softStopCameraAllParticipants,
    setEnableReactions,
} from "@workhorse/api/conference/sessionApi";
import {useCurrentParticipant, useIsLobby, useSession} from "@workhorse/providers/SessionDataProviders";
import VoiceOverOffRoundedIcon from "@material-ui/icons/VoiceOverOffRounded";
import MenuItem from "@ui/cdk/MenuItem";
import toast from "@workhorse/api/toast";
import {ParticipantsCategory, participantsCategoryInfo} from "../utils";
import VideocamOffRoundedIcon from "@material-ui/icons/VideocamOffRounded";
import {SessionFlagsType} from "@generated/data";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useTranslation} from "react-i18next";

type ParticipantsCategoryMenuProps = {
    type: ParticipantsCategory;
    participants: Participant[];
};

function ParticipantsCategoryMenu(props: ParticipantsCategoryMenuProps) {
    const {t} = useTranslation();

    const {type, participants} = props;
    const session = useSession();
    const {isLobby} = useIsLobby();

    const {id: sessionId, allowMicrophoneType, enableReactionsType, allowScreenShareType, allowCameraType} = session || {};

    const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        if (!menuEl) {
            setMenuEl(event.currentTarget);
        } else {
            setMenuEl(null);
        }
    };

    const isSpeakersMenu = type === ParticipantsCategory.SPEAKERS;

    const participantsIds = isSpeakersMenu
        ? participants.filter((p) => p?.speakerDetails !== null)?.map((s) => s.id)
        : participants.filter((p) => p?.speakerDetails === null)?.map((s) => s.id);

    const handleMuteAllGuests = () => {
        softMuteAllParticipants(sessionId, participantsIds)
            .then((res) => {
                if (res.data?.sendSessionEvent) {
                    toast(
                        t(
                            isSpeakersMenu
                                ? "player.notifications.mic.all_speakers_muted"
                                : "player.notifications.mic.all_participants_muted"
                        ) ?? `All ${isSpeakersMenu ? "speakers" : "participants"} muted!`,
                        {
                            type: "success",
                        }
                    );
                }
            })
            .catch(() => {
                toast(
                    t(
                        isSpeakersMenu
                            ? "player.notifications.camera.error_muting_all_speakers"
                            : "player.notifications.camera.error_muting_all_participants"
                    ) ?? `Something went wrong with muting all ${isSpeakersMenu ? "speakers" : "participants"}!`,
                    {type: "error"}
                );
            });
    };

    const handleStopCameraAllGuests = () => {
        softStopCameraAllParticipants(sessionId, participantsIds)
            .then((res) => {
                if (res.data?.sendSessionEvent) {
                    toast(
                        t(
                            isSpeakersMenu
                                ? "player.notifications.camera.all_speakers_camera_off"
                                : "player.notifications.camera.all_participants_camera_off"
                        ) ?? `All ${isSpeakersMenu ? "speakers'" : "participants'"} cameras disabled!`,
                        {
                            type: "success",
                        }
                    );
                }
            })
            .catch(() => {
                toast(
                    t(
                        isSpeakersMenu
                            ? "player.notifications.camera.error_disabling_all_speakers_cameras"
                            : "player.notifications.camera.error_disabling_all_participants_cameras"
                    ) ?? `Something went wrong with disabling all ${isSpeakersMenu ? "speakers'" : "participants'"} cameras!`,
                    {
                        type: "error",
                    }
                );
            });
    };

    const handleEnableReactions = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked ? SessionFlagsType.Everyone : SessionFlagsType.Speakers;
        setEnableReactions(sessionId, value);
    };

    const allowScreenShareAction = () => {
        setAllowScreenShare(
            sessionId,
            [SessionFlagsType.Speakers, SessionFlagsType.Hosts].includes(allowScreenShareType)
                ? SessionFlagsType.Everyone
                : SessionFlagsType.Speakers
        );
    };

    const hardMuteAction = () => {
        setSessionHardMute(
            sessionId,
            [SessionFlagsType.Speakers, SessionFlagsType.Hosts].includes(allowMicrophoneType)
                ? SessionFlagsType.Everyone
                : SessionFlagsType.Speakers
        );
    };

    const hardStopCameraAction = () => {
        setSessionDisableParticipantsVideo(
            sessionId,
            [SessionFlagsType.Speakers, SessionFlagsType.Hosts].includes(allowCameraType)
                ? SessionFlagsType.Everyone
                : SessionFlagsType.Speakers
        );
    };

    function getCheckboxState(flagType: SessionFlagsType) {
        return ![SessionFlagsType.Speakers, SessionFlagsType.Hosts].includes(flagType);
    }

    return (
        <Fragment key="participants-category-menu">
            <IconButton
                data-id="attendees-settings"
                size="small"
                onClick={handleToggle}
                className={cls(classes.menuToggler, menuEl && classes.menuTogglerActive)}
                aria-label="category settings"
            >
                <SettingsIcon />
            </IconButton>
            {menuEl ? (
                <Menu
                    anchorEl={menuEl}
                    keepMounted
                    open={Boolean(menuEl)}
                    onClose={handleToggle}
                    className="rnd-btn"
                    MenuListProps={{
                        className: classes.menuList,
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        className: classes.menuPaper,
                    }}
                    getContentAnchorEl={null}
                >
                    {!isLobby && [
                        <MenuItem data-id="mute-all" key="mute-all" className={classes.menuItem} onClick={handleMuteAllGuests}>
                            <VoiceOverOffRoundedIcon />
                            <Typography color="quaternary" fontWeight="bold">
                                {t(`macro.participants.group_actions.mute_all`) +
                                    " " +
                                    t(`macro.participants.category.${participantsCategoryInfo[type].title.toLowerCase()}`).toLowerCase()}
                            </Typography>
                        </MenuItem>,
                        <MenuItem data-id="stop-camera" key="stop-camera" className={classes.menuItem} onClick={handleStopCameraAllGuests}>
                            <VideocamOffRoundedIcon />
                            <Typography color="quaternary" fontWeight="bold">
                                {t(`macro.participants.group_actions.stop_camera_all`) +
                                    " " +
                                    t(`macro.participants.category.${participantsCategoryInfo[type].title.toLowerCase()}`).toLowerCase()}
                            </Typography>
                        </MenuItem>,
                        // !isSpeakersMenu && <div key="separator" className={classes.menuSeparator} />,
                    ]}
                    {!isSpeakersMenu ? (
                        <div>
                            <Typography fontWeight="bolder" color="secondary" className="p-8">
                                {t(`macro.participants.category_allow_to.${participantsCategoryInfo[type].title.toLowerCase()}`)}
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox data-id="share-screen" />}
                                label={
                                    <Typography color="quaternary" fontWeight="bold">
                                        {t(`macro.participants.permissions.share_screen`)}
                                    </Typography>
                                }
                                labelPlacement="end"
                                checked={getCheckboxState(allowScreenShareType)}
                                onChange={allowScreenShareAction}
                                className={classes.menuItem}
                            />
                            <FormControlLabel
                                control={<Checkbox data-id="react" />}
                                label={
                                    <Typography color="quaternary" fontWeight="bold">
                                        {t(`macro.participants.permissions.react`)}
                                    </Typography>
                                }
                                labelPlacement="end"
                                checked={getCheckboxState(enableReactionsType)}
                                onChange={handleEnableReactions}
                                className={classes.menuItem}
                            />
                            <FormControlLabel
                                control={<Checkbox data-id="use-mic" />}
                                label={
                                    <Typography color="quaternary" fontWeight="bold">
                                        {t(`macro.participants.permissions.use_mic`)}
                                    </Typography>
                                }
                                labelPlacement="end"
                                checked={getCheckboxState(allowMicrophoneType)}
                                onChange={hardMuteAction}
                                className={classes.menuItem}
                            />
                            <FormControlLabel
                                control={<Checkbox data-id="use-camera" />}
                                label={
                                    <Typography color="quaternary" fontWeight="bold">
                                        {t(`macro.participants.permissions.use_camera`)}
                                    </Typography>
                                }
                                labelPlacement="end"
                                checked={getCheckboxState(allowCameraType)}
                                onChange={hardStopCameraAction}
                                className={classes.menuItem}
                            />
                        </div>
                    ) : null}
                </Menu>
            ) : null}
        </Fragment>
    );
}

export default ParticipantsCategoryMenu;
