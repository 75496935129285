import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {SessionSettingsGroup, SessionSettingsSelection} from "../definitions";
import styles from "../styles/SessionSettingsGroupItem.module.scss";
import {SessionSettingsSectionItem} from "./SessionSettingsSectionItem";
import {useTranslation} from "react-i18next";

type SessionSettingsGroupItemProps = SessionSettingsGroup &
    JSX.IntrinsicElements["div"] & {
        onSelectSection: (section: SessionSettingsSelection) => void;

        selected?: SessionSettingsSelection;
        touched: Record<SessionSettingsSelection, boolean>;
        errors: Record<SessionSettingsSelection, string | undefined>;
    } & {
        isOwner: boolean;
        isAssistant: boolean;
    };

export const SessionSettingsGroupItem = ({
    translateKey,
    name,
    notice,
    sections,
    onSelectSection,
    selected,
    touched,
    errors,
    className,
    isOwner,
    isAssistant,
    ...rest
}: SessionSettingsGroupItemProps) => {
    const {t} = useTranslation();

    return (
        <div {...rest} className={cls(styles.root, className)}>
            <Typography fontWeight="bolder" variant="base">
                {t(`player.settings.${translateKey}`) || name}
            </Typography>

            <div className="flex flex-col gap-10">
                {Object.entries(sections).map(([key, section]) => (
                    <SessionSettingsSectionItem
                        {...section}
                        key={key}
                        handleClick={onSelectSection.bind(null, section.kind)}
                        touched={touched[section.kind]}
                        error={errors[section.kind]}
                        selected={section.kind === selected}
                    />
                ))}
            </div>
        </div>
    );
};
