import descriptor, {ArtifactTag, default as ArtifactsMap} from "@generated/artifacts";
import {FullSessionQuery, ChooseToolStep, MyProductToolDocument} from "@generated/data";
import env from "@generated/environment";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import React from "@workhorse/api/rendering";
import classes from "./styles/DesignerToolPopoverHeader.module.scss";
import {getToolName} from "@workhorse/api/utils/getToolName";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {useQuery} from "@workhorse/api/data";
import {WithSessionEditState} from "@workhorse/declarations";

type DesignerToolPopoverHeaderProps = {
    artifactTag?: ArtifactTag;
    isMobile: boolean;
    onClose?: () => void;
    currentArtifact: FullSessionQuery["agendaItems"][0]["artifact"];
    currentStep: WithSessionEditState["sessionEditState"]["toolStep"];
    sessionId: string;
};

function DesignerToolPopoverHeader(props: DesignerToolPopoverHeaderProps) {
    const {artifactTag, isMobile, onClose, currentArtifact, currentStep, sessionId} = props;

    const myProductToolArtifactExists = artifactTag && artifactTag === "flowos/my-product-tool";

    const {data: myProductToolsQuery} = useQuery(MyProductToolDocument, {
        variables: {
            myProductToolId: myProductToolArtifactExists && currentArtifact?.data?.myProductToolId,
            sessionId: sessionId,
        },
        skip: !currentArtifact?.data?.myProductToolId,
    });

    const artifactDisplayName =
        (myProductToolArtifactExists
            ? myProductToolsQuery?.myProductTool?.title
            : artifactTag && descriptor[artifactTag]?.meta?.displayName) ?? "";

    const artifactIcon = myProductToolArtifactExists
        ? myProductToolsQuery?.myProductTool?.iconUrl
        : (artifactTag && `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[artifactTag].meta.icon}.svg`) ?? "";

    const hasResource = !!currentArtifact?.resourceResults?.[0]?.id;
    const resourceName = getToolName(
        currentArtifact?.id ?? "",
        currentArtifact?.artifactId as ArtifactTag
        // TODO - maca - data api
        // currentArtifact?.resourceSlots?.[0]?.resource
    );

    let setupTitle = "Setup " + artifactDisplayName;
    if (props.artifactTag === "flowos/breakout") {
        setupTitle = "Set up your breakout rooms";
    }

    let subTitle = "Select what resource you want to present";
    if (props.artifactTag === "flowos/breakout") {
        subTitle = "Create rooms and assign participants";
    }

    return (
        <div className={cls(classes.root, currentStep === ChooseToolStep.Config && classes.hidden)}>
            {currentStep === ChooseToolStep.SelectTool ? (
                <div>
                    <Typography component="div" className={cls("mb-5", classes.title)} variant="xl" color="octonary" fontWeight="boldest">
                        Attach a tool
                    </Typography>
                    {isMobile ? null : (
                        <Typography component="div" variant="lg" color="eleventh" fontWeight="bold">
                            Which tool would you like to use during this session?
                        </Typography>
                    )}
                </div>
            ) : null}

            {currentStep === ChooseToolStep.ManageTool ? (
                <div className="flex flex-align-center">
                    <div className="flex">
                        {artifactTag ? (
                            <div className={classes.imageWrapper}>
                                <img className={classes.artifactImage} src={artifactIcon} />
                            </div>
                        ) : null}
                        <div className={cls(classes.header, "flex flex-col flex-align-start flex-justify-center")}>
                            <Typography component="div" variant="xl" color="octonary" fontWeight="boldest">
                                {artifactDisplayName}
                            </Typography>
                            {isMobile || !hasResource ? null : (
                                <Typography component="div" variant="lg" color="eleventh">
                                    Currently presenting <strong>{resourceName.nameByResource}</strong>
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}

            {currentStep === ChooseToolStep.Preview && isMobile ? (
                <div className="flex flex-align-center">
                    <div className="flex">
                        {artifactTag ? (
                            <div className={classes.imageWrapper}>
                                <img
                                    className={classes.artifactImage}
                                    src={`${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[artifactTag].meta.icon}.svg`}
                                />
                            </div>
                        ) : null}
                        <div className={classes.header}>
                            <Typography component="div" variant="xl" color="secondary" fontWeight="boldest">
                                Configure {artifactDisplayName}
                            </Typography>
                        </div>
                    </div>
                </div>
            ) : null}
            <IconButton className={classes.closeBtn} onClick={onClose}>
                <CloseRoundedIcon />
            </IconButton>
        </div>
    );
}

export default DesignerToolPopoverHeader;
