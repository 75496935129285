import clientEvents from "@api/events/client";
import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import OnboardingFeatureToggler from "@workhorse/api/onboarding/components/OnboardingFeatureToggler";
import {useHistory, useParams} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import CelloButton from "@workhorse/components/CelloButton";
import FeedbackButton from "@workhorse/components/FeedbackButton";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import Inject from "@workhorse/providers/Injector";
import CopyToClipboard from "react-copy-to-clipboard";
import {ReactComponent as CopyBlocksIcon} from "../../assets/media/copy_blocks_icon.svg";
import common from "../event/event/eventCommons.module.scss";
import classes from "../events/components/style/EventsContainer.module.scss";
import {setPreviousLocation} from "../player/playerStateVars";
import RoomsHeaderBreadcrumbs from "./RoomsHeaderBreadcrumbs";

type RoomsPageHeaderProps = {
    roomName?: string;
    roomLink?: string;
    roomUrl?: string;
    isRoomActive?: boolean;
    shouldWarnAboutSlug?: boolean;
};

function RoomsPageHeader(props: RoomsPageHeaderProps) {
    const params = useParams<{sessionId?: string; roomId?: string}>();
    const noParam = !params.sessionId && !params.roomId;
    const isCreateRoute = params.sessionId && params.sessionId.toLowerCase() === "create";
    const isCommiting = useDesignerSessionCommitState();
    const {mixpanelTrack} = useMixpanelTracker();
    const {canCreateRooms, triggerGenericAccessToast} = useWorkspaceAccess();

    const history = useHistory();

    const createOrUpdateRoom = () => {
        clientEvents.emit("commit-room", true);
        mixpanelTrack(isCreateRoute ? "frontend-create-room" : "frontend-update-room", "rooms");
    };

    const handleLinkCopied = () => {
        toast(<p>Link copied</p>);
        mixpanelTrack("frontend-copy-room-link", "rooms");
    };

    const handleJoinRoom = () => {
        if (!props.roomUrl) {
            return;
        }

        setPreviousLocation(null);
        history.push(props.roomUrl);
        mixpanelTrack("frontend-join-room", "rooms");
    };

    const onClick = (e) => {
        if (!canCreateRooms()) {
            e.preventDefault();
            e.stopPropagation();
            triggerGenericAccessToast();
            return;
        }
        mixpanelTrack("frontend-try-new-room", "rooms");
    };

    return (
        <Inject target="inHeader" id={`rooms-header`}>
            <div className="flex fullw flex-justify-between flex-items-center">
                <div className="flex flex-align-center overflow-hidden">
                    <RoomsHeaderBreadcrumbs roomName={noParam ? undefined : isCreateRoute ? "New Room" : props.roomName} />
                    {noParam && <OnboardingFeatureToggler feature="rooms" />}
                </div>

                <div className="flex flex-items-center">
                    {noParam ? (
                        <Button
                            component={Link}
                            to="/rooms/create"
                            variant="primary"
                            onClick={onClick}
                            data-id="rooms-create-room-button"
                            className={cls(common.actionButton, common.actionLink)}
                        >
                            New room
                        </Button>
                    ) : params.roomId ? (
                        <div className="flex flex-items-center nowrap mr-5 ml-8 py-5">
                            <CopyToClipboard text={props.roomLink!} onCopy={handleLinkCopied}>
                                <Button
                                    data-id="copy-room-link"
                                    variant="plain"
                                    size="small"
                                    startIcon={<CopyBlocksIcon />}
                                    // className={classes.copyLinkButton}
                                >
                                    Copy room link
                                </Button>
                            </CopyToClipboard>

                            {props.isRoomActive && (
                                <>
                                    <div className={classes.separator} />

                                    <Button variant="primary" size="small" data-id="join-session-button" onClick={handleJoinRoom}>
                                        Enter room
                                    </Button>
                                </>
                            )}
                        </div>
                    ) : (
                        <Tooltip
                            title={
                                props?.shouldWarnAboutSlug ? "Please save or discard the URL changes first and then update the Room." : ""
                            }
                            arrow
                            placement="bottom"
                        >
                            <Button
                                variant="primary"
                                onClick={createOrUpdateRoom}
                                data-id="rooms-save-update-room-button"
                                disabled={isCommiting || props?.shouldWarnAboutSlug}
                                className={cls(common.actionButton, common.actionLink)}
                            >
                                {isCreateRoute ? "Create room" : "Update room"}
                            </Button>
                        </Tooltip>
                    )}
                    {!params.roomId && (
                        <>
                            <FeedbackButton className="ml-12" />
                            <CelloButton className="ml-12" />
                        </>
                    )}
                </div>
            </div>
        </Inject>
    );
}

export default RoomsPageHeader;
