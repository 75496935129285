import {TypedDocumentNode as DocumentNode} from "@graphql-typed-document-node/core";
import {LazyQueryHookOptions, useLazyQuery} from "@workhorse/api/data";
import {useCallback, useEffect, useRef} from "@workhorse/api/rendering";
import {getDoc, Ast, QueryDoc, readQuery, writeQuery, UseQueryOptions} from ".";
import {deepQueryResultMerge} from "./dataApiUtils";

export type PaginationMode = "continuous" | "single-page";

export function DataFetcher<
    T extends QueryDoc,
    D extends Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V extends Ast[T] extends DocumentNode<any, infer U> ? U : never
>(props: {
    queryDoc: T;
    options?: UseQueryOptions<T>;
    onCompleted?: (data: D) => void;
    paginateRef: React.MutableRefObject<((mode: PaginationMode, lazyOptions: LazyQueryHookOptions<D, V>) => void) | undefined>;
    skipMainQueryWhenPaginatingRef: React.MutableRefObject<boolean>;
}) {
    const {queryDoc, options, paginateRef, skipMainQueryWhenPaginatingRef} = props;

    const paginationMode = useRef<PaginationMode>("continuous");

    const [lazyFetch] = useLazyQuery(getDoc(queryDoc), {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (lazyData) => {
            const current = readQuery(queryDoc, {variables: options?.variables} as any);
            writeQuery(queryDoc, {
                data:
                    paginationMode.current === "continuous"
                        ? deepQueryResultMerge(current, lazyData, ((options || {}).variables || ({} as any)).hasOwnProperty("before"))
                        : lazyData,
                // pagination is usually done with different variables than the initial ones
                // which leads to different datasets in the cache
                // for that reason, we merge pagination results with the initial query
                variables: options?.variables,
            });
        },
    });

    const paginate = useCallback(
        (mode: PaginationMode, lazyOptions: LazyQueryHookOptions<D, V>) => {
            // the writeQuery after the lazyFetch is completed will cause a rerender in the queryRenderer that will query the initial page over the network and override the cache
            // to prevent this, we make sure the next fetch done by the main useQuery from the queryRenderer will be cache only, so it will use the cache data resulted by the merge of the lazy fetch result
            // skipMainQueryWhenPaginatingRef.current will be set true here and will be set to false on the next rendering cycle in the queryRenderer
            skipMainQueryWhenPaginatingRef.current = true;
            paginationMode.current = mode;
            lazyFetch(lazyOptions as any);
        },
        [lazyFetch]
    );

    useEffect(() => {
        if (!paginateRef.current) {
            paginateRef.current = paginate;
        }
    }, [paginate]);
    return <></>;
}
