import {useState, useRef, useCallback} from "@workhorse/api/rendering";

export function useVideoNode() {
    const [video, setVideo] = useState<HTMLVideoElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const setVideoRef = useCallback((node: HTMLVideoElement | null) => {
        setVideo(node);
        videoRef.current = node;
    }, []);

    return {
        video,
        videoRef,
        setVideo: setVideoRef,
    };
}
