import {makeStyles} from "@material-ui/core";
import Dialog, {CustomDialogProps} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cloneElement} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        padding: theme.spacing(3.75),
        borderRadius: 16,
        maxWidth: 526,
        width: "100%",

        "& .close-dialog": {
            top: 18,
            right: 18,
            borderRadius: 0,
            [theme.breakpoints.down("sm")]: {
                top: 0,
                right: 0,
            },
        },
    },
    headerIcon: {
        marginRight: theme.spacing(1.5),
        width: 36,
        height: 36,
    },
    headerText: {
        fontWeight: "bold",
        letterSpacing: 0.24,
        color: theme.main.palette.text.primary,
    },
}));

type ActionConfirmationDialogProps = CustomDialogProps & {
    headerIcon?: JSX.Element | string;
    headerText: JSX.Element | string;
};

const ActionConfirmationDialog = (props: ActionConfirmationDialogProps): JSX.Element => {
    const {headerIcon, headerText, BackdropProps, ...otherProps} = props;

    const classes = useStyles();

    let icon;
    switch (typeof headerIcon) {
        case "string":
            icon = <img src={headerIcon} className={classes.headerIcon} />;
            break;
        case "object":
            icon = cloneElement(headerIcon, {className: cls(classes.headerIcon, headerIcon.props.className)});
            break;
        default:
            break;
    }

    return (
        <Dialog
            data-id="action-confirmation-dialog"
            classes={{paper: classes.paperRoot}}
            BackdropProps={{
                ...BackdropProps,
                classes: {
                    ...BackdropProps?.classes,
                    root: cls(BackdropProps?.classes?.root, "standard-backdrop"),
                },
            }}
            {...otherProps}
        >
            <div className="flex flex-items-center">
                {icon ? icon : null}
                <Typography variant="xl3" className={classes.headerText}>
                    {headerText}
                </Typography>
            </div>
            {props.children}
        </Dialog>
    );
};

export default ActionConfirmationDialog;
