import TableCell from "@ui/cdk/Table/TableCell";
import TableHead from "@ui/cdk/Table/TableHead";
import TableRow from "@ui/cdk/Table/TableRow";
import {cls} from "@ui/cdk/util";
import Table from "@ui/core/components/Table";
import TableBody from "@ui/core/components/TableBody";
import TableContainer from "@ui/core/components/TableContainer";
import ResxHeader from "./header/ResxHeader";
import classes from "./styles/ResxTableSkeleton.module.scss";

const numberOfSkeletons = Array.from(Array(6).keys());

type ResxTableSkeletonProps = {
    withHeader?: boolean;
};

const ResxTableSkeleton = (props: ResxTableSkeletonProps) => {
    const {withHeader} = props;

    const nope = () => {};

    return (
        <div className={classes.root}>
            {withHeader ? (
                <ResxHeader
                    key="skeleton-header"
                    artifactId={""}
                    inPopover={false}
                    type={"flowos/canva/resx/Canva"}
                    onDoubleClick={nope}
                ></ResxHeader>
            ) : null}

            <TableContainer>
                <Table>
                    <TableHead className="px-10">
                        <tr>
                            <TableCell size="small">
                                <div className={cls(classes.skeletonItem, classes.tall)}></div>
                            </TableCell>
                            <TableCell size="large">
                                <div className={cls(classes.skeletonItem, classes.tall)}></div>
                            </TableCell>
                            <TableCell size="unset">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell size="unset">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell size="unset">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell size="unset">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell
                                size="medium"
                                // className="flex flex-align-center"
                            >
                                <div className={cls(classes.skeletonItem, classes.small)}></div>
                            </TableCell>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {numberOfSkeletons.map((s, i) => (
                            <TableRow key={`${s}_${i}`} className={classes.row}>
                                <TableCell size="small">
                                    <div className={cls(classes.skeletonItem, classes.tall)}></div>
                                </TableCell>
                                <TableCell size="large">
                                    <div className={classes.skeletonItem}></div>
                                </TableCell>
                                <TableCell size="unset">
                                    <div className={classes.skeletonItem}></div>
                                </TableCell>
                                <TableCell size="unset">
                                    <div className={classes.skeletonItem}></div>
                                </TableCell>
                                <TableCell size="unset">
                                    <div className={classes.skeletonItem}></div>
                                </TableCell>
                                <TableCell size="unset">
                                    <div className={classes.skeletonItem}></div>
                                </TableCell>
                                <TableCell size="medium">
                                    <div className={cls(classes.skeletonItem, classes.small)}></div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ResxTableSkeleton;
