import clientEvents from "@api/events/client";
import svc from "@api/service/client";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {useEffect} from "@workhorse/api/rendering";
import {editingResource} from "../../../api";
import {useHistory} from "@workhorse/api/routing";
import {modifyResourceCounter} from "./utils";

type CreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    isToolsSection?: boolean;
};

const FormCreateForm = (props: CreateFormProps) => {
    const history = useHistory();
    useEffect(() => {
        svc.resx
            .createResource("flowos/form/resx/Form", {
                name: "New Form",
                description: "This is your new form",
                items: [],
            })
            .then((r) => {
                modifyResourceCounter("flowos/form/resx/Form", "add");
                props.handleClose();

                setTimeout(() => {
                    clientEvents.emit("click-resource-row", {
                        resourceId: r.id,
                    });

                    if (!props.isToolsSection) {
                        editingResource(r.id);
                    } else {
                        history.push(`/resources/form/` + r.id);
                    }
                }, 100);
            });
    }, []);

    return <></>;
};
export default FormCreateForm;
