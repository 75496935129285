import loadable from "@workhorse/api/loadable";
import {ResourcesRouteMatch, RouteHOCProps} from "@workhorse/declarations";

const FilesEntryPoint = loadable(() => import("./components/FilesEntryPoint"));

type FilesHOCProps = RouteHOCProps<ResourcesRouteMatch>;

function FilesHOC(props: FilesHOCProps): JSX.Element {
    return (
        <div className="route-hoc flex-align-start resx-hoc" key="files-hoc">
            <FilesEntryPoint key="files-entry-point" />
        </div>
    );
}

export default FilesHOC;
