import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import classes from "./onboarding.module.scss";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {useEffect} from "@workhorse/api/rendering";
import {useDeferredValue} from "./utils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {cls} from "@ui/cdk/util";
import {useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";

type OnboardingToolMenuProps = WithTooltipChildren & {
    shouldSkip?: boolean;
    placement?: "top" | "bottom" | "left" | "right";
    variant?: "stop-share";
};

export function OnboardingToolMenu(props: OnboardingToolMenuProps) {
    const {tooltip, next} = useOnboardingSession();
    const currentAgendaItem = useCurrentAgendaItem();

    let fileName = "file";
    if (currentAgendaItem?.artifact?.artifactId) {
        switch (currentAgendaItem.artifact.artifactId) {
            case "flowos/gallery": {
                fileName = "image";
                break;
            }
            case "flowos/pdf":
            case "flowos/doc":
            case "flowos/excel":
            case "flowos/pages":
            case "flowos/numbers": {
                fileName = "document";
                break;
            }
            case "flowos/ppt":
            case "flowos/keynote": {
                fileName = "presentation";
                break;
            }
        }
    }

    let title = (
        <>
            <div className="flex flex-align-center">
                <InfoOutlinedIcon className="mr-6" />
                <Typography className="title" fontWeight="bolder">
                    Share settings
                </Typography>
            </div>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                Each embedded tool or file has settings – hover here to change them. You can also stop sharing from here.
            </Typography>
        </>
    );

    if (props.variant === "stop-share") {
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        Stop the share
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    {`Click here to remove the ${fileName} and go back to conference mode.`}
                </Typography>
            </>
        );
    }

    const skip = (tooltip !== "share-settings" || props.shouldSkip) ?? false;
    const delay = props.variant === "stop-share" ? 1000 : 3000;
    const deferredSkip = useDeferredValue(tooltip, skip, true, delay);

    return (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement={props.placement ?? "top"}
            arrow={true}
            skip={deferredSkip}
            classes={{tooltip: cls(classes.tooltipBigger, classes.ignorePointer), popper: classes.ignorePointer}}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
