import {ChooseToolStep, DesignerState} from "@generated/data";
import {cls} from "@ui/cdk/util";
import designer from "@workhorse/api/designer";
import {insertAgendaItemAbove, insertAgendaItemBelow} from "@workhorse/api/designer/methods";
import {useCallback, useRef, useState} from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";
import KeyboardEvents from "../KeyboardEvents/KeyboardEvents";
import AgendaTemplatesEditorSubHeaderMenu from "./AgendaTemplatesEditorSubHeaderMenu/AgendaTemplatesEditorSubHeaderMenu";
import classes from "./style/AgendaTemplatesEditorSubHeader.module.scss";
import Remove from "@material-ui/icons/Remove";
import Checkbox from "@ui/cdk/Checkbox";
import {useUserInfo} from "@workhorse/providers/User";
import Typography from "@ui/cdk/Typography";

type AgendaTemplatesEditorSubHeaderProps = {
    isReadOnly: boolean;
    selected: string[];
    setEditState: (update: Partial<Omit<DesignerState, "__typename">>) => void;
    createNewAgendaItem: () => void;
    toggleDeleteAgendaItems: () => void;
    beforeUpdateMultiple: () => void;
    onOpenConfigDialog: (agendaItemId?: string) => void;
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    selectedWithTool: number;
    commit: () => Promise<void>;
    unlockAgendaItem: (id?: string) => void;
    inEventSection?: boolean;
} & WithMobileState;

const AgendaTemplatesEditorSubHeader = (props: AgendaTemplatesEditorSubHeaderProps) => {
    const {
        isReadOnly,
        selected,
        setEditState,
        createNewAgendaItem,
        toggleDeleteAgendaItems,
        beforeUpdateMultiple,
        onOpenConfigDialog,
        setSelected,
        mobileState,
        selectedWithTool,
        unlockAgendaItem,
        commit,
        inEventSection,
    } = props;

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);

    const user = useUserInfo();

    const undo = useCallback(() => designer.undo(), []);
    const redo = useCallback(() => designer.redo(), []);

    const bulkDelete = useCallback(() => {
        toggleDeleteAgendaItems();
    }, [toggleDeleteAgendaItems]);

    const duplicate = useCallback(async () => {
        const promises = selected.map((id) => {
            return new Promise((resolve, reject) => {
                designer.api.agendaItem.duplicate({
                    id,
                    callBack: () => {
                        resolve(true);
                    },
                });
            });
        });

        await Promise.all(promises);
        commit();
    }, [selected, commit]);

    const insertAbove = useCallback(() => {
        insertAgendaItemAbove(selected, 0, inEventSection ? undefined : user.id);
        commit();
    }, [selected, commit]);

    const insertBelow = useCallback(() => {
        insertAgendaItemBelow(selected, 0, inEventSection ? undefined : user.id);
        commit();
    }, [selected, commit]);

    const addTool = useCallback(() => {
        beforeUpdateMultiple();

        setEditState({
            toolStep: ChooseToolStep.SelectTool,
            selectedAgendaItemId: selected[0],
        });

        onOpenConfigDialog?.();
    }, [beforeUpdateMultiple, setEditState, selected, onOpenConfigDialog]);

    const removeTool = useCallback(() => {
        selected.forEach((id) => {
            designer.api.agendaItem.removeArtifact({
                agendaItemId: id,
            });
        });
    }, [selected]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setTimeout(() => {
            unlockAgendaItem();
        }, 100);
    };

    return (
        <>
            <div
                className={cls(
                    "flex flex-align-center",
                    classes.root,
                    !isReadOnly && selected.length ? classes.open : "",
                    inEventSection && classes.inEventSection
                )}
            >
                {/* <div className="flex flex-align-center">
                <Typography color="eleventh" fontWeight="bolder">
                    AGENDA ITEMS
                </Typography>

                {!isReadOnly ? (
                    <Button data-id="import-from-template" className="mx-15" onClick={openImport} variant="plain">
                        Import from template
                    </Button>
                ) : null}
            </div> */}

                {!isReadOnly && selected.length ? (
                    <>
                        <div className="my-auto">
                            <Checkbox
                                data-id="select-all"
                                checked={!!selected.length}
                                onChange={handleCheckboxChange}
                                checkedIcon={
                                    <div className={classes.removeIcon}>
                                        <Remove />
                                    </div>
                                }
                            />
                        </div>

                        <AgendaTemplatesEditorSubHeaderMenu
                            horizontal={true}
                            selected={selected}
                            insertAbove={insertAbove}
                            insertBelow={insertBelow}
                            bulkDelete={bulkDelete}
                            duplicate={duplicate}
                            addTool={addTool}
                            removeTool={removeTool}
                            undo={undo}
                            redo={redo}
                            selectedWithTool={selectedWithTool}
                        />

                        <Typography color="tertiary" className="ml-auto" fontWeight="bold">
                            {selected.length}
                            {selected.length === 1 ? " item" : " items"} selected
                        </Typography>
                    </>
                ) : null}

                <KeyboardEvents
                    selected={selected}
                    addTool={addTool}
                    removeTool={removeTool}
                    insertAbove={insertAbove}
                    insertBelow={insertBelow}
                    bulkDelete={bulkDelete}
                    createNewAgendaItem={createNewAgendaItem}
                    duplicate={duplicate}
                    hiddenInputRef={hiddenInputRef}
                />
                {/* {!isReadOnly ? <ImportTemplateDialog open={openImportDialog} onClose={closeImport} mobileState={mobileState} /> : null} */}
            </div>
            <input ref={hiddenInputRef} className={"hidden-but-displayed editing"} autoFocus={false}></input>
        </>
    );
};

export default AgendaTemplatesEditorSubHeader;
