import descriptor, {ArtifactTag, default as ArtifactsMap} from "@generated/artifacts";
import SlotBindingsMap from "@generated/artifacts/resources/slot-bindings";
import {FullSessionQuery, AgendaItemType, ChooseToolStep, MyProductToolDocument} from "@generated/data";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import {getMaxSessionOrder} from "@workhorse/api/designer/lib/utils";
import {updateArtifactProperty} from "@workhorse/providers/state";
import classes from "./styles/DesignerToolManager.module.scss";
import {useQuery} from "@workhorse/api/data";

type DesignerToolManagerProps = {
    sessionId: string;
    agendaItemId: string;
    onConfirm: (doNotCommit?: boolean) => void;
    currentArtifact: FullSessionQuery["agendaItems"][0]["artifact"];
    agendaItems: FullSessionQuery["agendaItems"];
};

const artifactsThatCanChangeResource = [
    ...Object.keys(SlotBindingsMap),
    "flowos/google-docs",
    "flowos/google-sheets",
    "flowos/google-slides",
    "flowos/google-forms",
    "flowos/miro",
];

const artifactsThatReattach: ArtifactTag[] = ["flowos/miro"];

const DesignerToolManager = (props: DesignerToolManagerProps) => {
    const {agendaItemId, onConfirm, currentArtifact, agendaItems} = props;

    const [, setEditState] = useDesignerSessionState();

    const myProductToolArtifactExists = currentArtifact && currentArtifact?.artifactId === "flowos/my-product-tool";

    const {data: myProductToolsQuery} = useQuery(MyProductToolDocument, {
        variables: {
            myProductToolId: myProductToolArtifactExists && currentArtifact?.data?.myProductToolId,
            sessionId: props.sessionId,
        },
        skip: !currentArtifact?.data?.myProductToolId,
    });

    const artifactName =
        currentArtifact?.artifactId && currentArtifact?.artifactId === "flowos/my-product-tool"
            ? myProductToolsQuery?.myProductTool?.title
            : (currentArtifact?.artifactId && descriptor[currentArtifact?.artifactId].meta?.displayName) ?? "";
    const canChangeResource = currentArtifact?.artifactId ? artifactsThatCanChangeResource.includes(currentArtifact?.artifactId) : false;

    const actuallyChangeResource = () => {
        setEditState({
            toolStep: ChooseToolStep.Config,
            selectedMicroIdToConfig: currentArtifact?.id,
        });
    };

    const handleChangeTool = () => {
        setEditState({
            toolStep: ChooseToolStep.SelectTool,
            selectedAgendaItemId: agendaItemId,
        });
    };

    const handleDetachTool = async () => {
        const order = getMaxSessionOrder() + 1;
        designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Instant,
            order,
            doNotUpdateCreatePls: true,
        });

        designer.api.session.update({
            order,
        });

        onConfirm();
    };

    const reAttachTool = async () => {
        // TODO: this is only needed for miro. Find a better implementation when doing the next artifact that behaves the same
        const currentAgendaItem = agendaItems.find((a) => a.id === agendaItemId);
        if (currentAgendaItem?.artifact?.id) {
            updateArtifactProperty(currentAgendaItem.artifact?.id, "source", "");
        }

        onConfirm(true);
    };

    const handleChangeResource = () => {
        if (!canChangeResource || !currentArtifact?.artifactId) {
            return;
        }
        if (artifactsThatReattach.includes(currentArtifact.artifactId as ArtifactTag)) {
            reAttachTool();
        } else {
            actuallyChangeResource();
        }
    };

    let resourceChangeTitle = "Change resource";
    let resourceChangeDescription = "Choose or add another resource";
    let managementChangeDescription = "Attach another tool to your session";
    let detachTitle = `Detach ${artifactName}`;
    let detachDescription = "You'll switch back to Conference view";

    if (currentArtifact?.artifactId === "flowos/breakout") {
        resourceChangeTitle = "Change room setup";
        resourceChangeDescription = "Add, remove or rename rooms";
        managementChangeDescription = "End Breakout and add another tool";
        detachTitle = `End ${artifactName}`;
        detachDescription = "Everyone will return to the main session";
    }

    return (
        <div className={cls("p-30", classes.root)}>
            <Typography color="quaternary" className="mb-15">
                Edit {artifactName}
            </Typography>
            <div
                data-id="change-tool-resource"
                className={cls(!canChangeResource ? classes.disabled : "", classes.actionSection, "mb-50")}
                onClick={handleChangeResource}
            >
                <div className="flex11-100">
                    <Typography fontWeight="bold" className={classes.actionTitle}>
                        {resourceChangeTitle}
                    </Typography>
                    <Typography fontWeight="bold" className="mt-9" variant="sm" color="eleventh">
                        {resourceChangeDescription}
                    </Typography>
                </div>
                <ArrowForwardIcon className={classes.actionIcon} />
            </div>

            <Typography color="quaternary" className="mb-15">
                Tools management
            </Typography>

            <div data-id="change-tool" className={cls(classes.actionSection, "mb-15")} onClick={handleChangeTool}>
                <div className="flex11-100">
                    <Typography fontWeight="bold" className={classes.actionTitle}>
                        Change tool
                    </Typography>
                    <Typography fontWeight="bold" className="mt-9" variant="sm" color="eleventh">
                        {managementChangeDescription}
                    </Typography>
                </div>
                <ArrowForwardIcon className={classes.actionIcon} />
            </div>

            <div data-id="detach-tool" className={classes.actionSection} onClick={handleDetachTool}>
                <div className="flex11-100">
                    <Typography fontWeight="bold" className={cls(classes.actionTitle, classes.red)}>
                        {detachTitle}
                    </Typography>
                    <Typography fontWeight="bold" className="mt-9" variant="sm" color="eleventh">
                        {detachDescription}
                    </Typography>
                </div>
                <RemoveCircleOutlineIcon className={cls(classes.actionIcon, classes.red)} />
            </div>
        </div>
    );
};

export default DesignerToolManager;
