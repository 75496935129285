import React from "@workhorse/api/rendering";
import MuiDialog from "@material-ui/core/Dialog";
import {ExtractProps} from "@workhorse/declarations";

/**
 * @deprecated please refer to the useDialog hook
 */
type DialogProps = ExtractProps<typeof MuiDialog>;

export default function Dialog(props: DialogProps) {
    return <MuiDialog {...props} />;
}
