import {useBreakoutNavigation} from "@artifacts/breakout/api/hooks";
import {getSortedRoomsFromConfig} from "@artifacts/breakout/ui/hooks/useConfigApi";
import {BreakoutRoomsConfigFragment} from "@generated/data";
import ArrowDropDownRounded from "@material-ui/icons/ArrowDropDownRounded";
import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {StopRecordingDialog} from "@workhorse/pages/player/components/RecordingDialogs/StopRecordingDialog";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";
import {Transition} from "react-transition-group";
import {ReactComponent as BreakoutIcon} from "../../../../assets/media/breakout_icon.svg";
import classes from "./BreakoutMenu.module.scss";
import {BreakoutMenuItem} from "./BreakoutMenuItem";
import {BreakoutMenuPortal} from "./BreakoutMenuPortal";
import {useTranslation} from "react-i18next";

interface Props {
    roomName: string;
    sessionId: string;
    parentName: string;
    participantId?: string;
    isAdmin: boolean;
    isAssistant: boolean;
    config: BreakoutRoomsConfigFragment;
}

export function BreakoutMenu(props: Props) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [roomInfoMap, setRoomInfoMap] = useState<Record<string, boolean | undefined>>({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [outgoingRoomId, setOutgoingRoomId] = useState<string | undefined>();

    const getRoomParticipantsCount = (roomId: string) => {
        return props.config.participantsMap.filter((participant) => participant.desiredRoomId === roomId).length;
    };

    const rooms = useMemo(() => getSortedRoomsFromConfig(props.config.breakoutRooms), [props.config.breakoutRooms]);

    const navigate = useBreakoutNavigation();
    const {isActive, stop} = useRecordingProcessor();

    const goToRoom = (roomId: string) => {
        if (props.sessionId === roomId) {
            return;
        }

        if (isActive) {
            setDialogOpen(true);
            setOutgoingRoomId(roomId);
        } else {
            navigate(roomId, true, true);
        }
    };

    const handleOk = () => {
        setDialogOpen(false);
        stop();
        if (outgoingRoomId) {
            navigate(outgoingRoomId, true, true);
        }
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    const handleRoomInfo = useCallback((roomId: string, value: boolean) => {
        setRoomInfoMap((map) => {
            if (map[roomId] === value) {
                return map;
            }
            return {
                ...map,
                [roomId]: value,
            };
        });
    }, []);

    const notify = props.isAdmin || props.isAssistant;
    const parent = props.config.parentOfBreakoutRooms;
    const notifications = Object.values(roomInfoMap).filter((el) => el === true).length;
    const canNavigate = props.isAdmin || props.isAssistant || props.config.allowRoomNavigation;

    return (
        <>
            <div className={classes.root}>
                {!canNavigate && (
                    <Typography variant="lg" color="tertiary">
                        {props.roomName}
                    </Typography>
                )}
                {canNavigate && (
                    <DropDown title={props.roomName} notifications={notifications} open={open} setOpen={setOpen}>
                        <BreakoutMenuItem
                            title={t("artifacts.breakout.main_room") ?? ""}
                            roomId={parent.sessionId}
                            current={false}
                            artifactId={props.config.artifactIdOfBreakoutRooms}
                            notify={notify}
                            showParticipants={true}
                            participants={getRoomParticipantsCount(parent.sessionId)}
                            onClick={() => goToRoom(parent.sessionId)}
                            onChange={handleRoomInfo}
                        />
                        {rooms.map((room) => (
                            <BreakoutMenuItem
                                key={room.id}
                                title={room.name}
                                roomId={room.id}
                                artifactId={props.config.artifactIdOfBreakoutRooms}
                                notify={notify}
                                current={props.sessionId === room.id}
                                participants={getRoomParticipantsCount(room.id)}
                                onClick={() => goToRoom(room.id)}
                                onChange={handleRoomInfo}
                            />
                        ))}
                    </DropDown>
                )}
            </div>
            <StopRecordingDialog open={dialogOpen} onOk={handleOk} onCancel={handleCancel} />
        </>
    );
}

interface TogglerProps {
    open: boolean;
    notifications: number;
    children: ReactNode;
    onClick: () => void;
}

function Toggler(props: TogglerProps) {
    return (
        <Typography
            data-id="current-room-title"
            component="div"
            variant="sm"
            fontWeight="bold"
            className={cls(classes.toggler, props.open && "isOpen")}
            onClick={props.onClick}
        >
            <BreakoutIcon className="mr-4" />
            <p>{props.children}</p>
            <ArrowDropDownRounded className="ml-auto" />
            {props.notifications !== 0 && <span className="count">{props.notifications}</span>}
        </Typography>
    );
}

interface DropDownProps {
    title: string;
    notifications: number;
    children: ReactNode;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DropDown(props: DropDownProps) {
    const {open, setOpen} = props;
    const [height, setHeight] = useState(0);
    const [setNode] = useLocalResizeObserver((width, height) => setHeight(height + 16));

    const {isSessionsUIHidden, sessionsUIHeader} = useSessionsUIToggle();

    useEffect(() => {
        if (isSessionsUIHidden && !sessionsUIHeader) {
            setOpen(false);
        }
    }, [isSessionsUIHidden, sessionsUIHeader, setOpen]);

    const maxHeight = `min(${height}px, calc(100vh - 200px))`;

    const transitionStyles = {
        entering: {maxHeight},
        entered: {maxHeight},
        exiting: {maxHeight: 0},
        exited: {maxHeight: 0},
    };

    const inner = (state: string) => (
        <>
            <Toggler open={open} onClick={() => setOpen((open) => !open)} notifications={props.notifications}>
                {props.title}
            </Toggler>
            <BreakoutMenuPortal>
                <div
                    className={cls(classes.dropdown, isSessionsUIHidden && classes.dropdownSessionsUi, state)}
                    style={transitionStyles[state]}
                >
                    <div className={classes.container}>
                        <div ref={setNode}>{props.children}</div>
                    </div>
                </div>
            </BreakoutMenuPortal>
        </>
    );

    return (
        <Transition in={height !== 0 && open} timeout={300}>
            {inner}
        </Transition>
    );
}
