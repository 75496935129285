import Typography from "@ui/cdk/Typography";
import classes from "./styles/GoogleWorkspaceEventWarning.module.scss";
import ChooseSessions from "../../../assets/media/choose-sessions.png";
import Logo from "../../../assets/media/sessions-logos/sessions-logo-big-light.svg";
import FooterGeneric from "@workhorse/components/footer/components/FooterGeneric";
import {useHistory} from "@workhorse/api/routing";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useMutation} from "@workhorse/api/data";
import {CheckSessionExistsDocument} from "@generated/data";

type GoogleWorkspaceEventWarningProps = {
    sessionId: string;
};

function GoogleWorkspaceEventWarning(props: GoogleWorkspaceEventWarningProps) {
    const {sessionId} = props;
    const isMounted = useRef(true);
    const history = useHistory();
    const [sessionExists, setSessionExists] = useState<boolean | null>(null);

    const [checkSessionExists] = useMutation(CheckSessionExistsDocument);

    const checkSession = useCallback(async () => {
        if (!sessionId || !isMounted.current) {
            return;
        }

        const res = await checkSessionExists({variables: {sessionId: sessionId}});

        setSessionExists(!!res.data?.checkSessionExists?.exists);
    }, [checkSessionExists, history, sessionId]);

    useEffect(() => {
        isMounted.current = true;
        checkSession().catch((err) => console.error(err));

        return () => {
            isMounted.current = false;
        };
    }, [checkSession, sessionId]);

    useEffect(() => {
        if (sessionExists) {
            history.push(`/session/${sessionId}`);
        }
    }, [sessionExists]);

    if (!isMounted.current || sessionExists == null) {
        return null;
    }

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <img src={Logo} alt="Sessions" />
            </header>
            <div className={classes.card}>
                <Typography className={classes.title}>Your session is not created and published yet!</Typography>
                <Typography className={classes.subtitle}>Please save the Google Calendar event to create and publish it.</Typography>
                <img alt="choose sessions" src={ChooseSessions} className={classes.image} />
            </div>
            <FooterGeneric />
        </div>
    );
}

export default GoogleWorkspaceEventWarning;
