import {ConnectionQuality, RemoteParticipant} from "livekit-client";

export enum RoomDataType {
    Volume = "volume",
    Quality = "quality",
}

export type RoomDataVolume = {
    type: RoomDataType.Volume;
    volume: number;
};

export type RoomDataQuality = {
    type: RoomDataType.Quality;
    quality: ConnectionQuality;
};

export type RoomData = RoomDataVolume | RoomDataQuality;

export function encodeRoomData(data: RoomData): Uint8Array {
    const json = JSON.stringify(data);
    const encoder = new TextEncoder();
    return encoder.encode(json);
}

export function decodeRoomData(data: Uint8Array): RoomData | null {
    const decoder = new TextDecoder();
    const json = decoder.decode(data);
    try {
        return JSON.parse(json);
    } catch (err) {
        return null;
    }
}

function isRoomData(data: unknown): data is RoomData {
    if (typeof data !== "object" || data == null) {
        return false;
    }

    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    return data["type"] != null;
}

function isVolumeData(data: RoomData): data is RoomDataVolume {
    return data.type === RoomDataType.Volume;
}

function isQualityData(data: RoomData): data is RoomDataQuality {
    return data.type === RoomDataType.Quality;
}

interface RoomDataHandler {
    onVolumeData: (data: RoomDataVolume, from: string) => void;
    onQualityData: (data: RoomDataQuality, from: string) => void;
}

export function handleRoomData(data: Uint8Array, participant?: RemoteParticipant, handlers?: RoomDataHandler) {
    const decoded = decodeRoomData(data);
    let from = participant ? participant.identity : "server";

    if (!isRoomData(decoded)) {
        return;
    }

    if (isVolumeData(decoded)) {
        return handlers?.onVolumeData(decoded, from);
    }

    if (isQualityData(decoded)) {
        return handlers?.onQualityData(decoded, from);
    }
}
