import {JSONContent} from "@api/editor";
import {getLocalTimezone} from "@common/utils/timezones";
import {ContactInfoFragment, SessionEventBySlugDocument, SessionFlags, SessionLifecycle, SessionParticipantFragment} from "@generated/data";
import environment from "@generated/environment";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import {removeTypenameKey} from "@sessions/common/diff/computeDiff";
import Input from "@ui/cdk/Input";
import SimpleTimezonePicker from "@ui/cdk/SimpleTimezonePicker";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls, collapseWhiteSpace} from "@ui/cdk/util";
import InputLabel from "@ui/core/components/InputLabel";
import {useOrganizationAccess, useUserAccess} from "@workhorse/api/access/hooks";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import SessionSendReminder from "@workhorse/api/session-settings/sections/SessionSection/components/SessionSendReminder";
import toast from "@workhorse/api/toast";
import {EditorInput} from "@workhorse/components/editor";
import {useMutation, writeQuery} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useCurrentParticipant, useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import {addMinutes} from "date-fns";
import CopyToClipboard from "react-copy-to-clipboard";
import {v4 as uuid} from "uuid";
import isURL from "validator/lib/isURL";
import {isWorkspaceGroup} from "../../../contacts/utils/contact";
import {EventsTimePicker} from "../../components/event-timepicker";
import inputClasses from "../../create-event.module.scss";
import commonClasses from "../eventCommons.module.scss";
import classes from "./event-settings.module.scss";
import {makeParticipantAssistantData, useEventSettingsContactFetcher} from "./utils";
import {useUserInfo} from "@workhorse/providers/User";
import {composeSlug, getBaseUrl, getSlugParts, removeSpecialChars} from "@workhorse/util/url";
import EventCollaborators from "../../event-collaborators/event-collaborators";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import IconButton from "@ui/core/components/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {makeVar, useReactiveVar} from "@apollo/client";

const localTimezone = getLocalTimezone();

type EventSettingsProps = {
    isRoom?: boolean;
    onChange?: () => void;
    setShouldWarnAboutSlug?: (shouldWarn: boolean) => void;
};

export function EventSettings(props: EventSettingsProps) {
    const session = useSession();
    const eventIsPublished = session?.event?.state === "PUBLISHED";
    const user = useUserInfo();

    const {lockedSlugPart, editableSlugPart} = getSlugParts(session?.event?.slug ?? "");
    const host = `${environment.eventBaseURL}/`;
    const workspaceSlug = user?.activeWorkspace?.slug ?? "";

    // const baseURL = getBaseUrl(environment.eventBaseURL, userName, organizationId, organizationName);

    const [valueName, setValueName] = useState(session.name || "");
    const [errorName, setErrorName] = useState("");
    const [errorUrl, setErrorUrl] = useState("");
    const [valueDescription, setValueDescription] = useState(session.description || "");
    const [valueUrl, setValueUrl] = useState(editableSlugPart || "");
    const [isEditingSlug, setIsEditingSlug] = useState(false);
    const [inputValue, setInputValue] = useState(editableSlugPart ?? "");
    const [lockedPart, setLockedPart] = useState(lockedSlugPart ?? "");

    const baseURL = `${host}${lockedPart}` + (lockedPart ? "/" : "");

    const organizationAccess = useOrganizationAccess();
    const canInviteMembers = organizationAccess.canEditMembers();

    const access = useUserAccess();
    const canAddSmsReminder = access.workspace().canAddSmsReminder();

    const urlRef = useRef<HTMLInputElement>();

    const [lockEvent] = useMutation("LockEventDocument");

    const isLocked = useDesignerSessionLocked();
    const {mixpanelTrack} = useMixpanelTracker();

    const eventNotUpdatable = useMemo(
        () =>
            (session.lifecycle === SessionLifecycle.Started && !session.backstage) ||
            isLocked ||
            session.lifecycle === SessionLifecycle.Ended,
        [session.lifecycle, isLocked, session.backstage]
    );

    const participants = useParticipants();

    const currentParticipant = useCurrentParticipant();

    const [collaborators, setCollaborators] = useState(() => {
        return participants.filter((p) => p.rbac?.session?.isAssistant && p.id !== currentParticipant.id);
    });

    const eventOwner = useMemo(() => {
        return participants.find((p) => p.isOwner);
    }, [participants]);

    const participantAssistants = useMemo(() => {
        return participants.filter((p) => p.rbac?.session?.isAssistant);
    }, [participants]);

    const assistantUserIds = useMemo(() => {
        return participantAssistants
            .filter((p) => p.dataWithNullableEmail !== undefined && p.dataWithNullableEmail.userId !== undefined)
            .map((p) => p.dataWithNullableEmail.userId as string);
    }, [participantAssistants]);

    const assistantUserEmails = useMemo(() => {
        return participantAssistants
            .filter((p) => p.dataWithNullableEmail !== undefined && p.dataWithNullableEmail.email !== undefined)
            .map((p) => p.dataWithNullableEmail.email as string);
    }, [participantAssistants]);

    const speakerUserEmails = useMemo(() => {
        return participants.filter((p) => p.dataWithNullableEmail && p.speakerDetails).map((p) => p.dataWithNullableEmail.email as string);
    }, [participants]);

    const regularUserEmails = useMemo(() => {
        return participants
            .filter((p) => p.dataWithNullableEmail && !p.rbac?.session?.isAssistant && !p.speakerDetails)
            .map((p) => p.dataWithNullableEmail.email as string);
    }, [participants]);

    const [updateParticipants] = useMutation("UpdateParticipantsInSessionStandaloneDocument", {});

    const tags = useMemo(() => assistantUserIds.map((uid) => ({type: "contact" as const, value: `u-${uid}`})), [assistantUserIds]);

    useEffect(() => {
        setValueName(session.name);
    }, [session.name]);

    useEffect(() => {
        setValueDescription(session.description ?? "");
    }, [session.description]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValueName(collapseWhiteSpace(e.target.value).trim());
        setErrorName("");
    };

    const handleDescriptionChange = (content: JSONContent) => {
        setValueDescription(JSON.stringify(content));
    };

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // const slug = removeSpecialChars(e.target.value);

        if (isEditingSlug) {
            setInputValue(e.target.value);
        } else {
            setValueUrl(e.target.value);
        }

        setErrorUrl("");
    };

    const onBlurUrl = async () => {
        const newUrl = removeSpecialChars(valueUrl);
        const composedSlug = composeSlug(lockedSlugPart, newUrl);

        if (composedSlug === session?.event?.slug) {
            setIsEditingSlug(false);
            return;
        }

        const {data} = await apollo.client.query({
            query: SessionEventBySlugDocument,
            variables: {
                slug: composedSlug,
            },
            fetchPolicy: "network-only",
        });

        const urlExists = !data?.sessionEvent?.event?.slug ? false : data?.sessionEvent?.event?.slug !== session.event?.slug;

        const allowedUrlValue = urlExists ? newUrl + "-" + uuid() : newUrl;

        setValueUrl(allowedUrlValue);

        const composedSlugWithAllowedValue = composeSlug(lockedSlugPart, allowedUrlValue);

        if (allowedUrlValue.length >= 3 && allowedUrlValue.length <= 200 && isURL(baseURL + allowedUrlValue)) {
            designer.api.event.update({
                slug: composedSlugWithAllowedValue,
            });
            designer.commit();
        } else {
            setErrorUrl("Please enter a valid URL");
        }
        setIsEditingSlug(false);
    };

    const onBlurTitle = () => {
        if (valueName.trim().length === 0) {
            setErrorName("Title cannot be empty");
            return;
        }

        designer.api.session.update({
            name: valueName,
        });
        designer.commit();
    };

    const onBlurDescription = () => {
        designer.api.session.update({
            description: valueDescription,
        });
        designer.commit();
    };

    const titleRef = useRef<HTMLInputElement>();
    const timezone = (session.timeZone || localTimezone) as typeof localTimezone;

    const startDate = session.startAt ? new Date(session.startAt) : new Date();
    const endDate = session.plannedEnd ? new Date(session.plannedEnd) : addMinutes(startDate, 15);

    const handleOnSessionLinkCopy = () => {
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
        mixpanelTrack("frontend-copy-event-link", "events");
    };

    function handleAddAssistants(members: SessionParticipantFragment[]) {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        const validEmails = members.map((x) => x.dataWithNullableEmail?.email).filter(Boolean);

        const newParticipantEmails = validEmails.filter(
            (x) => x && !assistantUserEmails.includes(x) && !speakerUserEmails.includes(x) && !regularUserEmails.includes(x)
        );
        const existingParticipantEmails = validEmails.filter(
            (x) => x && !assistantUserEmails.includes(x) && (speakerUserEmails.includes(x) || regularUserEmails.includes(x))
        );

        const newParticipantsData = newParticipantEmails.map((email) => ({
            ...makeParticipantAssistantData(email!, false, false, {
                session: {
                    isAssistant: true,
                },
            }),
            id: makeId(),
        }));

        const existingParticipantsData: any[] = [];

        participants.map((p) => {
            if (
                p?.dataWithNullableEmail?.email &&
                existingParticipantEmails.includes(p.dataWithNullableEmail.email) &&
                !p.rbac?.session?.isAssistant &&
                eventOwner?.dataWithNullableEmail?.email !== p.dataWithNullableEmail.email
            ) {
                const participantPayload = removeTypenameKey({
                    ...p,
                    rbac: {
                        ...p.rbac,

                        session: {
                            isAssistant: true,
                        },
                    },
                });

                existingParticipantsData.push(participantPayload);
            }
        });

        const upsertParticipantJSONs = [...newParticipantsData, ...existingParticipantsData].map((p) => ({
            ...p,
            isApproved: true,
            submittedPasscode: true,
            invitedByTheOwner: true,
        }));

        if (upsertParticipantJSONs.length > 0) {
            updateParticipants({
                variables: {
                    sessionId: session.id,
                    upsertParticipantJSONs,
                },
            })
                .then((res) => {
                    // refetch participants instead of evicting from cache
                    if (res.data?.updateParticipantsInSession) {
                        writeQuery("LocalParticipantsDocument", {
                            variables: {
                                id: session.id,
                            },
                            data: {
                                __typename: "Query",
                                participants: res.data.updateParticipantsInSession.participants,
                            },
                        });
                    }
                })
                .finally(() => {
                    designer.state.setDesignerCommitState(false);
                });
        }
        mixpanelTrack("frontend-add-team-members", "events");
    }

    function handleRemoveAssistant(email: string) {
        const participant = participantAssistants.find((p) => p.dataWithNullableEmail?.email === email);
        lockEvent({
            variables: {
                id: session.id,
            },
        });

        if (participant) {
            if (participant.speakerDetails !== null) {
                const participantPayload = removeTypenameKey(participant);

                // @ts-ignore
                delete participantPayload.speakerDetails.participantId;

                updateParticipants({
                    variables: {
                        sessionId: session.id,
                        upsertParticipantJSONs: [
                            {
                                ...participantPayload,
                                isApproved: true,
                                submittedPasscode: true,
                                rbac: {},
                            },
                        ],
                    },
                })
                    .then((res) => {
                        // refetch participants instead of evicting from cache
                        if (res.data?.updateParticipantsInSession) {
                            writeQuery("LocalParticipantsDocument", {
                                variables: {
                                    id: session.id,
                                },
                                data: {
                                    __typename: "Query",
                                    participants: res.data.updateParticipantsInSession.participants,
                                },
                            });
                        }
                    })
                    .finally(() => {
                        designer.state.setDesignerCommitState(false);
                    });
            } else {
                updateParticipants({
                    variables: {
                        sessionId: session.id,
                        deletedParticipantIds: [participant.id],
                    },
                }).then(() => {
                    apollo.cache.evict({id: participant.id});
                });
            }
        }
    }

    const handleOnChange = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey], hasChanged = true) => {
        if (!props.isRoom && hasChanged) {
            lockEvent({
                variables: {
                    id: session.id,
                },
            });
        }

        designer.api.session.update({
            [key]: value,
        });

        props.onChange?.();

        if (!props.isRoom && hasChanged) {
            designer.commit();
        }
        mixpanelTrack("frontend-add-reminder", "events");
    };

    const handleEditUrl = () => {
        urlRef.current?.focus();
        setIsEditingSlug(true);
        props.setShouldWarnAboutSlug?.(true);
        if (lockedPart !== workspaceSlug) {
            setLockedPart(workspaceSlug);
        }
    };

    const handleOnMouseDownOnUrl = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if (document.activeElement !== urlRef.current && !isEditingSlug) {
            e.preventDefault();
        }
    };

    const saveEditSlug = () => {
        const newValue = removeSpecialChars(inputValue);
        const slug = composeSlug(lockedPart, inputValue);

        setValueUrl(newValue);
        setIsEditingSlug(false);

        designer.api.event.update({
            slug,
        });

        designer.commit();
        props.onChange?.();
        props.setShouldWarnAboutSlug?.(false);
    };

    const cancelEditSlug = () => {
        setInputValue(editableSlugPart);
        setLockedPart(lockedSlugPart);
        setIsEditingSlug(false);
        props.setShouldWarnAboutSlug?.(false);
    };

    const handleSlugBlurInEdit: React.FocusEventHandler<HTMLInputElement> = (e) => {
        const newValue = e.target.value === "" ? removeSpecialChars(valueName) : removeSpecialChars(e.target.value);
        setInputValue(newValue);
    };

    return (
        <div className={commonClasses.mainContainer}>
            <Typography fontWeight="bolder" variant="xl3" className="mb-30" color="secondary">
                General details
            </Typography>
            <div className={errorName ? "mb-12" : ""}>
                <InputLabel htmlFor="event-title" className={inputClasses.inputLabel}>
                    Event name <span>*</span>
                </InputLabel>
                <Input
                    required
                    id="event-title"
                    disabled={eventNotUpdatable}
                    inputRef={titleRef}
                    className={inputClasses.input}
                    value={valueName || (session?.name ?? "")}
                    placeholder="Event name"
                    maxCharCount={250}
                    // defaultValue={session?.name ?? ""}
                    formControlClassName={inputClasses.formControl}
                    showTypedCharCount
                    onChange={handleTitleChange}
                    onBlur={onBlurTitle}
                    error={!!errorName}
                    helperText={errorName}
                    data-id="event-input-name"
                    // inputProps={{
                    //     "data-private": "lipsum",
                    // }}
                />
            </div>

            <div className="flex flex-row flex-align-center gap-8 fullw mt-10">
                <Input
                    required
                    id="event-url"
                    data-id="event-url"
                    inputRef={urlRef}
                    disabled={eventNotUpdatable || !isEditingSlug}
                    className={cls(
                        inputClasses.editUrlInput,
                        eventNotUpdatable && inputClasses.editUrlInputDisabled,
                        !isEditingSlug && inputClasses.editUrlInputDisabled
                    )}
                    value={inputValue}
                    placeholder={""}
                    formControlClassName={inputClasses.formControl}
                    onChange={handleUrlChange}
                    onBlur={isEditingSlug ? handleSlugBlurInEdit : onBlurUrl}
                    startAdornment={<Typography className={inputClasses.baseUrl}>{baseURL}</Typography>}
                    error={!!errorUrl}
                    helperText={errorUrl}
                    onMouseDown={handleOnMouseDownOnUrl}
                    readOnly={!isEditingSlug}
                    endAdornment={
                        <div className="flex flex-center-all gap-12">
                            {!isEditingSlug ? (
                                <div className={inputClasses.editUrlWrapper}>
                                    <Typography
                                        onClick={handleEditUrl}
                                        variant="base"
                                        fontWeight="bold"
                                        className={inputClasses.editUrlButton}
                                        data-id="event-link-edit-url"
                                    >
                                        Edit
                                    </Typography>
                                </div>
                            ) : (
                                <>
                                    <div className="flex flex-row flex-align-center">
                                        <Typography
                                            variant="base"
                                            fontWeight="bold"
                                            className={inputClasses.cancelSlugEditButton}
                                            onClick={cancelEditSlug}
                                            color="red400"
                                        >
                                            Cancel
                                        </Typography>
                                        <Typography
                                            variant="base"
                                            fontWeight="bold"
                                            className={inputClasses.saveSlugEditButton}
                                            onClick={saveEditSlug}
                                        >
                                            Update
                                        </Typography>
                                    </div>
                                </>
                            )}
                        </div>
                    }
                    // inputProps={{
                    //     "data-private": "lipsum",
                    // }}
                />

                <CopyToClipboard text={baseURL + valueUrl} onCopy={handleOnSessionLinkCopy}>
                    <IconButton className={classes.urlCopyIcon}>
                        <FileCopyOutlinedIcon />
                    </IconButton>
                </CopyToClipboard>
            </div>
            {eventIsPublished && isEditingSlug && (
                <div className={commonClasses.warningMessageContainer}>
                    <WarningRoundedIcon />
                    <Typography variant="sm">
                        If you change this link, the old one won't work anymore. Don&apos;t forget to share it again if you already shared
                        it.
                    </Typography>
                </div>
            )}
            <div className="flex flex-align-center mt-30">
                <InputLabel htmlFor="event-description" className={cls(inputClasses.inputLabel, "mr-6")}>
                    Description
                </InputLabel>
                <Tooltip
                    title={
                        <div>
                            <Typography variant="sm">This info will be displayed on your event’s landing page.</Typography>
                        </div>
                    }
                    placement="right"
                    arrow
                >
                    <HelpOutlineRoundedIcon className={cls(commonClasses.infoIcon, "mb-8")} />
                </Tooltip>
            </div>
            <EditorInput
                data-id="event-input-description"
                className={inputClasses.input}
                readOnly={eventNotUpdatable}
                value={valueDescription}
                placeholder="Description of the event"
                maxCharCount={5000}
                formControlClassName={inputClasses.formControl}
                showTypedCharCount
                onChange={handleDescriptionChange}
                onBlur={onBlurDescription}
            />
            <InputLabel className={inputClasses.inputLabel + " mt-20"}>
                Date & time <span>*</span>
            </InputLabel>
            <EventsTimePicker
                startDate={startDate}
                endDate={endDate}
                disabled={eventNotUpdatable}
                timezone={timezone}
                onDateChange={(start, end) => {
                    designer.api.session.update({
                        startAt: start,
                        plannedEnd: end,
                    });
                    designer.commit();
                }}
                isHalfDayClock={user.halfDayClock}
            />
            <div className="flex flex-row fullw mt-20 my-4">
                <div className={inputClasses.separator} />
                <div className="fullw">
                    <InputLabel className={inputClasses.inputLabel}>Time zone</InputLabel>
                    <SimpleTimezonePicker
                        className={inputClasses.timezoneSelect}
                        defaultValue={timezone}
                        disabled={eventNotUpdatable}
                        onChange={(tz, tzStr) => {
                            if (tzStr !== timezone) {
                                designer.api.session.update({
                                    timeZone: tzStr,
                                });
                                designer.commit();
                            }
                        }}
                        date={startDate}
                    />
                </div>
            </div>

            <EventCollaborators
                collaborators={collaborators}
                setCollaborators={setCollaborators}
                organizationName={user.activeOrganizationPermission.organization.name}
                onAddEventCollaborators={handleAddAssistants}
                onRemoveEventCollaborator={handleRemoveAssistant}
            />

            {!props.isRoom && (
                <div className="flex flex-wrap flex-align-start row-gap-16 column-gap-32">
                    <SessionSendReminder
                        emailsValue={session.reminders}
                        messagesValue={session.messageReminders}
                        onChangeEmails={handleOnChange.bind(null, "reminders")}
                        onChangeMessages={handleOnChange.bind(null, "messageReminders")}
                        showIcon={true}
                        disabled={eventNotUpdatable}
                        withSmsReminders={canAddSmsReminder}
                        isEvent={true}
                    />
                </div>
            )}
        </div>
    );
}
