import {TableRowProps} from "@material-ui/core";
import MuiTableRow from "@material-ui/core/TableRow";
import {cls} from "@ui/cdk/util";
import {forwardRef} from "react";
import styles from "./style/TableRow.module.scss";

const TableRow = forwardRef((props: TableRowProps, ref?: any) => {
    const {
        /**
         * @param {boolean} selected Checks if the line is currently selected. @default "false"
         */
        selected = false,

        ...rest
    } = props;

    return (
        <MuiTableRow {...rest} ref={ref} className={cls(`fosTableRow`, styles.tableRow, rest.className, selected && styles.selected)}>
            {rest.children}
        </MuiTableRow>
    );
});

TableRow.displayName = "TableRow";

export default TableRow;
