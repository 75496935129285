import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Button from "@ui/cdk/Button";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {Fragment, useCallback, useState} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {SessionView, useSessionView} from "@workhorse/providers/SessionViewProvider";
import {SESSION_VIEW_MENU} from "../definitions";
import classes from "../styles/PlayerSessionViewMenu.module.scss";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {useSessionInfo} from "@workhorse/api/conference2";
import Switch from "@ui/cdk/Switch";
import commons from "../../commons/style.module.scss";
import {useTranslation} from "react-i18next";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type PlayerSessionViewMenuProps = {
    className?: string;
};

export default function PlayerSessionViewMenu(props: PlayerSessionViewMenuProps) {
    const {t} = useTranslation();
    const {className} = props;

    const {
        ctx,
        chosen: savedChosen,
        forcedView,
        forced,
        sync,
        set,
        setSync,
        view,
        defaultView,
        setForceWidgetSizeToMax,
        forceWidgetSizeToMax,
    } = useSessionView();
    const session = useSessionInfo();
    const sessionId = session.sessionId;

    const {isMobileOrTablet} = useMobile();

    const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);

    const currentParticipant = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            id: currentParticipant?.id ?? "",
            isOwner: currentParticipant?.isOwner,
            isAssistant: amIAssistant,
        }))
    );

    const isOwner = currentParticipant.isOwner;
    const isAssistant = currentParticipant.isAssistant;
    const isOrganizer = isOwner || isAssistant;

    const options = {
        variables: {
            sessionId,
        },
    };

    const onData = useCallback((data: SubscriptionPayload<"OnPushSessionViewDocument">) => {
        if (!data || !data.onPushSessionView) {
            return;
        }

        const {sessionView} = data.onPushSessionView;
        set(sessionView as SessionView, false);
    }, []);

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        if (!menuEl) {
            setMenuEl(event.currentTarget);
        } else {
            setMenuEl(null);
        }
    };

    const onToggleSync = () => {
        return setSync(sync ? "none" : view);
    };

    const toggleWidgetMaxSize = () => {
        setForceWidgetSizeToMax((current) => !current);
    };

    const chosen = forced ? forcedView : savedChosen;

    const ChosenIcon = SESSION_VIEW_MENU[view]?.icon[ctx];
    const chosenLabel = t(`header.session_view.${SESSION_VIEW_MENU[view]?.translateKey}`) ?? SESSION_VIEW_MENU[view]?.label;

    return (
        <>
            <GenericSubscriber document="OnPushSessionViewDocument" onData={onData} options={options} key="push-sessionview-subscriber" />
            <Fragment key="session-view-menu">
                <Tooltip
                    title={
                        forced
                            ? t("header.session_view.info_tooltip_forced") ??
                              "You cannot change the session view. It is enforced by the session organizers"
                            : ""
                    }
                >
                    <Button
                        size="smallest"
                        variant="plain"
                        onClick={handleToggle}
                        className={cls(
                            classes.menuToggler,
                            menuEl && classes.menuTogglerActive,
                            isMobileOrTablet && classes.menuTogglerMobile,
                            forced && classes.disabled,
                            sync && classes.sync,
                            className
                        )}
                        data-id="player-dropdown-viewmode"
                        aria-hidden={true}
                    >
                        {ChosenIcon ? <ChosenIcon className={classes.icon} /> : null}

                        {!isMobileOrTablet && (
                            <Typography color="secondary" fontWeight="bolder">
                                {chosenLabel ?? ""}
                            </Typography>
                        )}

                        <ExpandMoreRoundedIcon />
                    </Button>
                </Tooltip>
                {menuEl ? (
                    <Menu
                        anchorEl={menuEl}
                        keepMounted
                        open={Boolean(menuEl)}
                        onClose={handleToggle}
                        MenuListProps={{
                            className: classes.menuList,
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        PaperProps={{
                            className: classes.menuPaper,
                        }}
                        getContentAnchorEl={null}
                        aria-hidden={true}
                    >
                        <Typography component="li" color="secondary" fontWeight="bolder" className={classes.menuTitle}>
                            {t("header.session_view.my_view_mode") ?? "My view mode"}
                        </Typography>

                        {Object.entries(SESSION_VIEW_MENU).map(
                            ([
                                key,
                                {
                                    translateKey,
                                    label,
                                    icon: {tool: ToolIcon, conference: ConferenceIcon},
                                    keybind,
                                },
                            ]) => {
                                const isDisabled = (ctx === "tool" && key === "collaboration") || forced;
                                return (
                                    <MenuItem
                                        data-id="view-mode-item"
                                        key={key}
                                        className={cls(classes.menuItem, sync && classes.sync, forced && classes.disabled)}
                                        onClick={isDisabled ? undefined : set.bind(null, key, sync)}
                                        disabled={isDisabled}
                                    >
                                        <div className="fullw flex flex-align-center flex-justify-between">
                                            <div className="flex flex-align-center">
                                                <>
                                                    {ctx === "tool" ? (
                                                        <ToolIcon className={classes.icon} />
                                                    ) : (
                                                        <ConferenceIcon className={classes.icon} />
                                                    )}
                                                </>
                                                <span>{t(`header.session_view.${translateKey}`) ?? label}</span>
                                                <>{chosen === key ? <CheckRoundedIcon className={classes.checkIcon} /> : null}</>
                                            </div>
                                            {keybind && !isDisabled ? (
                                                <div className="flex flex-align-center">
                                                    {keybind.map((key, index) => (
                                                        <div className={commons.keyBind} key={index}>
                                                            {key}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    </MenuItem>
                                );
                            }
                        )}

                        <li className={classes.participants}>
                            <Switch
                                data-id="full-size-videos-switch"
                                checked={forceWidgetSizeToMax}
                                onChange={toggleWidgetMaxSize}
                                name={""}
                                id={""}
                                label={t("header.session_view.force_video_maxsize") ?? "Allow videos to occupy all available space"}
                            />
                        </li>

                        {isOrganizer && (
                            <li className={classes.participants}>
                                <Switch
                                    data-id="enforce-view-switch"
                                    checked={sync}
                                    onChange={onToggleSync}
                                    name={""}
                                    id={""}
                                    label={t("header.session_view.enforce_view_mode") ?? "Enforce host view"}
                                />
                            </li>
                        )}
                    </Menu>
                ) : null}
            </Fragment>
        </>
    );
}
