export default function getLastDayOccurrence(date: Date, day: string) {
    const d = new Date(new Date(date).getTime());

    const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    if (days.includes(day)) {
        const modifier = (d.getDay() + days.length - days.indexOf(day)) % 7 || 7;
        // TODO: @Mihai Macaneata
        // this is the only place left in the app where setDate is used
        // if this causes issues, use the same pattern as in src/frontend/ui/cdk/DateTimePicker/utils.ts (alignDate)
        d.setDate(d.getDate() - modifier);
    }
    return d;
}
