import {PaletteItemAgenda, PaletteItemBase, PaletteItemFile, PaletteItemMode, PaletteItemTool} from "@generated/data";

export type GoogleFileData = {
    id: string;
    mimeType: string;
    name: string;
    permission: "owner" | "reader" | "commenter" | "writer" | "none";
    url?: string | null;
    updatedAt?: Date | null;
};

export function isPaletteItemFile(item: PaletteItemBase): item is PaletteItemFile {
    return item.mode === PaletteItemMode.File;
}

export function isPaletteItemTool(item: PaletteItemBase): item is PaletteItemTool {
    return item.mode === PaletteItemMode.Tool;
}

export function isPaletteItemAgenda(item: PaletteItemBase): item is PaletteItemAgenda {
    return item.mode === PaletteItemMode.Agenda;
}
