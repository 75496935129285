import {ConferenceParticipantStatus, SessionParticipantFragment} from "@generated/data";
import {useActiveSpeakers, useJoinedSpeaker} from "@workhorse/api/conference2";
import {useParticipantSpeakerScores, useParticipantVolumes} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {useMemo, useRef} from "@workhorse/api/rendering";
import {useMyParticipant} from "@workhorse/providers/SessionDataProviders";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import {SpeakerPopupVideo} from "./SpeakerPopupVideo";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {RecordingView} from "@workhorse/api/session-settings/sections/RecordingSection/components/RecordingViewSelect";
import {RECORDING_USER_EMAIL} from "@common/recording/constants";

function getSpeakerWithHighestScore(participants: SessionParticipantFragment[], volumes: Record<string, number | undefined>) {
    let selectedSpeaker: SessionParticipantFragment | undefined;
    let selectedVolume: number | undefined;

    for (const participant of participants) {
        const volume = volumes[participant.id] ?? 0;

        if (selectedVolume == null || selectedVolume <= volume) {
            selectedVolume = volume ?? 0;
            selectedSpeaker = participant;
        }
    }

    return selectedSpeaker;
}

function useSelectedSpeaker(): SessionParticipantFragment | undefined {
    const poserSpeakers = useJoinedSpeaker();
    const speakers = poserSpeakers.filter((poser) => poser.conferenceStatus === ConferenceParticipantStatus.Speaker);
    const activeSpeakers = useActiveSpeakers();
    const scores = useParticipantSpeakerScores();
    const highestScore = getSpeakerWithHighestScore(speakers.length ? speakers : activeSpeakers, scores);
    const lastSpeaker = useRef<SessionParticipantFragment | undefined>(highestScore);

    if (highestScore) {
        lastSpeaker.current = highestScore;
    }

    const speaker = highestScore ?? lastSpeaker.current;

    if (!speaker) {
        return;
    }

    return speaker;
}

type SpotlightPopupProps = {
    draggable?: boolean;
    controls?: boolean;
};

function SpeakerPopupEntry(props: SpotlightPopupProps) {
    const selection = useSelectedSpeaker();
    const currentParticipant = useMyParticipant();

    const user = useUserInfo();
    const session = useSession();

    const shouldHide = session.recordingPresentationView === RecordingView.Speaker && user?.email === RECORDING_USER_EMAIL;

    return useMemo(() => {
        if (shouldHide) {
            return null;
        }

        if (!selection) {
            return null;
        }

        const {firstName, lastName, avatar} = selection.dataWithNullableEmail ?? {};

        return (
            <SpeakerPopupVideo
                firstName={firstName}
                lastName={lastName}
                avatar={avatar}
                participantId={selection.id}
                isLocalParticipant={selection?.id === currentParticipant?.id}
                draggable={true}
                controls={true}
                participant={selection}
            />
        );
    }, [selection]);
}

export function SpeakerPopup() {
    const {popup} = useSessionView();

    if (!popup) {
        return null;
    }

    return <SpeakerPopupEntry />;
}
