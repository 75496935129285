import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";

/**
 * It retrieves the language from the URL query parameters and compares it with the current i18n language.
 *
 * If the languages are different, it updates the i18n language to match the one in the URL.
 *
 * @returns null
 */

const UrlQueryLanguageUpdater = () => {
    const {search} = useLocation();

    const urlParams = new URLSearchParams(search);
    const language = urlParams.get("language");

    const {i18n} = useTranslation();

    if (language && i18n.language !== language) {
        i18n.changeLanguage(language);
    }

    return null;
};

export default UrlQueryLanguageUpdater;
