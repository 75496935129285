import {HostType} from "@generated/data";
import {useCallback, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {isSessionSection, SessionSettingsGroupMap, SessionSettingsSelection} from "./definitions";

type SessionSettingsStore =
    | {
          open: false;
          group?: SessionSettingsGroupMap;
          section?: SessionSettingsSelection;
      }
    | {
          open: true;
          group: SessionSettingsGroupMap;
          section?: SessionSettingsSelection;
      }
    | {
          open: true;
          group: SessionSettingsGroupMap;
          section: SessionSettingsSelection;
      };

const useSessionSettingsDialogStore = () => {
    const hostType = useHostType();
    const [store, setStore] = useState<SessionSettingsStore>({
        open: false,
    });

    const show = useCallback((sectionOrGroup: SessionSettingsSelection | SessionSettingsGroupMap) => {
        let group: SessionSettingsGroupMap, section: SessionSettingsSelection | undefined;

        if (isSessionSection(sectionOrGroup)) {
            group = sectionOrGroup.split(".")[0] as SessionSettingsGroupMap;
            section = sectionOrGroup;
        } else {
            group = sectionOrGroup;
        }

        return setStore({open: true, section, group});
    }, []);

    const hide = useCallback(() => setStore({open: false}), []);

    useEffect(() => {
        if (hostType !== HostType.Player) {
            setStore({
                open: false,
            });
        }
    }, [hostType]);

    return useMemo(
        () => ({
            store,
            show,
            hide,
        }),
        [store, show, hide]
    );
};

export const [SessionSettingsDialogProvider, useSessionSettingsDialog] = createContextProvider(
    {
        name: "SessionSettingsDialog",
    },
    useSessionSettingsDialogStore
);

export const useSessionSettingsDialogDisplayStore = () => {
    const {show} = useSessionSettingsDialog();

    return useMemo(() => ({show}), [show]);
};

export const [SessionSettingsDialogDisplayProvider, useSessionSettingsDialogDisplay] = createContextProvider(
    {
        name: "SessionSettingsDialogDisplay",
    },
    useSessionSettingsDialogDisplayStore
);
