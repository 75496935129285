import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useEffect} from "@workhorse/api/rendering";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";

type PlayerHocCleanupProps = {
    sessionId: string;
};

export function PlayerHocCleanup(props: PlayerHocCleanupProps) {
    const [designerState, setDesignerState] = useDesignerSessionState();
    const hostType = useHostType();

    useEffect(() => {
        if (hostType !== HostType.Player) {
            return;
        }
        if (designerState.templateMode) {
            setDesignerState({
                templateMode: false,
            });
        }
    }, [props.sessionId]);

    return <></>;
}
