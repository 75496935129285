import React, {forwardRef} from "@workhorse/api/rendering";
import MuiIconButton, {IconButtonProps} from "@material-ui/core/IconButton";
import {ariaLabelBasedOnClass} from "@workhorse/util/strings";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/IconButton.module.scss";

export default forwardRef(function IconButton(props: IconButtonProps, ref: any) {
    let buttonProps = props as any;

    if ((props.className || props["data-id"]) && props["aria-label"] === undefined) {
        const ariaLabel = ariaLabelBasedOnClass(props.className || props["data-id"]);
        if (ariaLabel) {
            buttonProps = {
                ...props,
                "aria-label": ariaLabel,
            };
        }
    }

    return <MuiIconButton ref={ref} {...buttonProps} className={cls(classes.root, props.className)} />;
});
