import browserInfo from "@workhorse/api/BrowserInfo";
import {RemoteTrackPublication, Track, VideoQuality} from "livekit-client";
import {createRoomLogger} from "./ConferenceRoomLogger";
import {isAnyLocationVisible, VisibilityLocations, VisibilityMap} from "./ConferenceRoomVisibility";

const logger = createRoomLogger("QUALITY");
const isMobile = browserInfo.isMobile();

function mapQuality(q: VideoQuality) {
    return Object.keys(VideoQuality).find((k) => VideoQuality[k] === q);
}

function countLocations(map: VisibilityMap, counter: (locations: VisibilityLocations) => boolean) {
    let count = 0;

    for (const key in map) {
        const locations = map[key];
        if (locations && counter(locations)) {
            count++;
        }
    }

    return count;
}

function createQualityBuilder() {
    const qualities: Array<Readonly<[boolean, VideoQuality, string | undefined]>> = [];

    const handler = {
        add: (quality: VideoQuality, condition: boolean, reason?: string) => {
            qualities.push([condition, quality, reason] as const);
            return handler;
        },
        build: (fallback: VideoQuality = VideoQuality.LOW) => {
            for (const [condition, quality, reason] of qualities) {
                if (condition) {
                    return {quality, reason: reason ?? "Unspecified"};
                }
            }
            return {quality: fallback, reason: "Conditions unmet, falling back"};
        },
    };

    return handler;
}

export function setIncomingQuality(
    pub: RemoteTrackPublication,
    visibilityMap: VisibilityMap,
    participantId: string,
    incomingVideosDisabled: boolean
) {
    const name = participantId;

    const locations = visibilityMap[participantId];

    if (pub.source === Track.Source.ScreenShare) {
        const quality = VideoQuality.HIGH;
        pub.setVideoQuality(quality);
        logger.debug("share-screen", mapQuality(quality), "-", name);
        return;
    }

    if (incomingVideosDisabled) {
        pub.setEnabled(false);
        return;
    }

    if (!locations || !isAnyLocationVisible(locations)) {
        pub.setEnabled(false);
        logger.debug("not visible anywhere, turning OFF", "-", name, locations);
        return;
    }

    if (locations.spotlight) {
        const count = countLocations(visibilityMap, (locations) => locations.grid);

        const {quality, reason} = createQualityBuilder()
            .add(VideoQuality.HIGH, !isMobile && count <= 4, "Not mobile and less than 4")
            .add(VideoQuality.MEDIUM, !isMobile && count > 4, "Not mobile and more than 4")
            .add(VideoQuality.MEDIUM, isMobile, "Mobile")
            .build();

        pub.setVideoQuality(quality);
        logger.debug("spotlight", mapQuality(quality), "-", name, locations, {count}, reason);
        return;
    }

    if (locations.floatingSpeaker) {
        const quality = VideoQuality.MEDIUM;
        pub.setVideoQuality(quality);
        logger.debug("floating speaker", mapQuality(quality), "-", name, locations);
        return;
    }

    if (locations.grid) {
        const count = countLocations(visibilityMap, (locations) => locations.grid);

        const {quality, reason} = createQualityBuilder()
            .add(VideoQuality.HIGH, !isMobile && count <= 4, "Not mobile and less than 4")
            .add(VideoQuality.MEDIUM, !isMobile && count > 4, "Not mobile and more than 4")
            .add(VideoQuality.MEDIUM, isMobile, "Mobile")
            .build();

        pub.setVideoQuality(quality);
        logger.debug("grid", mapQuality(quality), "-", name, locations, {count}, reason);
        return;
    }

    pub.setVideoQuality(VideoQuality.LOW);
    logger.debug("not spotlight", mapQuality(VideoQuality.LOW), "-", name, locations);
}
