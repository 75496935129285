import {BackgroundFilter, BackgroundFilterGeneric} from "@workhorse/api/conference2/lib/VideoReplacement";
import React from "@workhorse/api/rendering";
import {ReactComponent as NoFilterIcon} from "../../../../../assets/media/filter-none.svg";
import {ReactComponent as BlurFilterIcon} from "../../../../../assets/media/filter-blur.svg";
import {ReactComponent as LightBlurFilterIcon} from "../../../../..//assets/media/filter-light-blur.svg";
import {cls} from "@ui/cdk/util";
import Typography from "@ui/cdk/Typography/Typography";
import sectionStyles from "../styles/BackgroundEffectsSection.module.scss";
import genericStyles from "../../../styles/GenericSection.module.scss";
import videoStyles from "../../VideoSection/styles/VideoSection.module.scss";
import {useTranslation} from "react-i18next";

const styles = {...genericStyles, ...videoStyles, ...sectionStyles};

type ParticipantBackgroundFilterGenericSelectProps = {
    filter: BackgroundFilterGeneric;
    selected: boolean;
    onClick: () => void;
    className?: string;
};

const ParticipantBackgroundFilterGenericSelect = (props: ParticipantBackgroundFilterGenericSelectProps) => {
    const {t} = useTranslation();

    const {filter, selected, onClick, className} = props;

    let SVG: React.FunctionComponent, label: string;

    switch (filter) {
        case BackgroundFilter.NONE:
            SVG = NoFilterIcon;
            label = t("player.settings.device.background_effects.effect_no_effect") ?? "No effect";

            break;
        case BackgroundFilter.BLUR:
            SVG = BlurFilterIcon;
            label = t("player.settings.device.background_effects.effect_strong_blur") ?? "Strong blur";
            break;

        case BackgroundFilter.LIGHTBLUR:
        default:
            SVG = LightBlurFilterIcon;
            label = t("player.settings.device.background_effects.effect_light_blur") ?? "Light blur";
            break;
    }

    return (
        <div className={cls(styles.filter, className, selected && "selected")}>
            <div data-id={label.replace(/\s/g, "-").toLowerCase()} className={styles.filterSvg} onClick={onClick}>
                <SVG />
            </div>
            <Typography variant="xs" color="blueGray400" className={styles.filterTitle}>
                {label}
            </Typography>
        </div>
    );
};

export default ParticipantBackgroundFilterGenericSelect;
