import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {getCorrectPlural} from "@workhorse/util/strings";
import classes from "./csv-alert.module.scss";

interface CSVAlertProps {
    className?: string;
    file?: File | null;
    invalidCSV: boolean;
    invalidEntries: number;
    toUpdate: number;
    toCreate: number;
    totalEntries: number;
    entryName: string;
    groupName: string;
}

export function CSVAlert(props: CSVAlertProps) {
    const title = (
        <AlertTitle>
            <Typography className="flex" fontWeight="bold">
                <InsertDriveFileIcon className="mr-4" />
                {props.file?.name}
            </Typography>
        </AlertTitle>
    );

    const className = cls(classes.alert, props.className);

    if (props.invalidCSV) {
        return (
            <Alert className={className} icon={false} severity="error">
                {title}
                Invalid CSV file.
            </Alert>
        );
    }

    if (props.invalidEntries > 0) {
        const toImport = props.toUpdate + props.toCreate;

        return (
            <Alert className={className} icon={false} severity="warning">
                {title}
                {toImport} {getCorrectPlural(props.entryName, toImport)} from {props.totalEntries} CSV{" "}
                {getCorrectPlural("value", props.totalEntries)} will be added to the {props.groupName}.
            </Alert>
        );
    }

    if (props.toCreate && props.toUpdate) {
        return (
            <Alert className={className} icon={false}>
                {title}
                {props.toCreate} {getCorrectPlural(props.entryName, props.toCreate)} will be added to the {props.groupName} and{" "}
                {props.toUpdate} {getCorrectPlural(props.entryName, props.toUpdate)} information will be updated.
            </Alert>
        );
    }

    if (props.toUpdate) {
        return (
            <Alert className={className} icon={false}>
                {title}
                {props.toUpdate} {getCorrectPlural(props.entryName, props.toUpdate)} information will be updated.
            </Alert>
        );
    }

    if (props.toCreate) {
        return (
            <Alert className={className} icon={false}>
                {title}
                {props.toCreate} {getCorrectPlural(props.entryName, props.toCreate)} will be added to the {props.groupName}.
            </Alert>
        );
    }

    return null;
}
