import {SessionParticipantFragment, SpeakerDetailsFragment} from "@generated/data";
import Checkbox from "@ui/cdk/Checkbox";
import TableCell from "@ui/cdk/Table/TableCell";
import TableRow from "@ui/cdk/Table/TableRow";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import classes from "./style/MySpeakersTableRow.module.scss";

type MyTableSpeakersTableRowProps = {
    isSelected: boolean;
    speaker: SpeakerDetailsFragment;
    onToggleSelectSpeaker: (speakerId: string) => void;
};

function MyTableSpeakersTableRow(props: MyTableSpeakersTableRowProps) {
    const {isSelected, speaker, onToggleSelectSpeaker} = props;

    const {email, name, id: speakerId, photoUrl: avatar} = speaker;

    const fullName = name?.trim();

    return (
        <TableRow className={classes.root} data-id="speaker-entry-row">
            <TableCell size="small">
                <Checkbox
                    className="mr-5"
                    checked={isSelected}
                    onChange={() => onToggleSelectSpeaker(speakerId)}
                    data-id="speaker-checkbox-toggle"
                />
            </TableCell>

            <TableCell size="unset">
                <div className="flex flex-align-center">
                    <ProfilePicture
                        avatar={avatar}
                        avatarVariant="rounded"
                        avatarSize="L"
                        firstName={fullName}
                        className="mr-12"
                        email={email}
                    />
                    <div className="flex flex-col mr-12">
                        <Typography color="primary" fontWeight="bold" noWrap data-private data-id="speaker-full-name">
                            {fullName}
                        </Typography>
                        <Typography color="nonary" fontWeight="normal" noWrap data-private data-id="speaker-email">
                            {email}
                        </Typography>
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
}

export default MyTableSpeakersTableRow;
