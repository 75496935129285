import {forwardRef} from "@workhorse/api/rendering";
import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";
import {ReactComponent as TogglerParticipants} from "../../../../assets/media/macro-toggler-participants.svg";
import classes from "./styles/ParticipantsToggler.module.scss";
import {useParticipantCount} from "./utils";

type ParticipantsTogglerProps = {
    artifactId: string;
    notInLobby: boolean;
    onToggle: () => void;
    isActive: boolean;
    inDrawer: boolean;
    isMemoryMode: boolean;
    isOrganizer: boolean;
    isRoom: boolean;
    isCountHidden: boolean;
};

const ParticipantsToggler = forwardRef((props: ParticipantsTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {artifactId, notInLobby, onToggle, isActive, inDrawer, isOrganizer, isRoom, isCountHidden, isMemoryMode} = props;

    const {activeParticipantCount, totalParticipantCount} = useParticipantCount(isMemoryMode);

    const {isMobileOrTablet} = useMobile();
    const {tooltip} = useOnboardingSession();

    const handleClick = () => {
        onToggle();
    };

    return (
        <ArtifactActionsButton
            badgeContent={0}
            onClick={handleClick}
            isActive={isActive}
            tooltipPlacement={tooltip === "participants-footer" ? "left" : undefined}
            MacroIcon={
                <div className="flex flex-items-center">
                    <TogglerParticipants />
                    {!isMobileOrTablet && !isCountHidden && (
                        <div className={classes.numberBadge}>
                            {activeParticipantCount}
                            {isOrganizer && !isRoom && <> / {totalParticipantCount}</>}
                        </div>
                    )}
                </div>
            }
            buttonDataId="player-button-participants"
            artifactId={artifactId}
            isLobby={!notInLobby}
            inDrawer={inDrawer}
            className={classes.btn}
            ref={ref}
            label={t("macro.participants.title") ?? "Participants"}
            ariaLabel={`${t("macro.participants.title")}, ${activeParticipantCount} active`}
        />
    );
});

export default ParticipantsToggler;
