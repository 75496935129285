import toast from "@workhorse/api/toast";
import {getCorrectPlural} from "@workhorse/util/strings";

export function toastImportedEmails(created: number, updated: number, skipped: number) {
    const messages: string[] = [];

    if (created > 0) {
        const createdMessage = `${created} ${getCorrectPlural("email", created)} imported.`;
        messages.push(createdMessage);
    }

    if (updated > 0) {
        const updatedMessage = `${updated} ${getCorrectPlural("email", updated)} updated.`;
        messages.push(updatedMessage);
    }

    if (skipped > 0) {
        const skippedMessage = `${skipped} ${getCorrectPlural("email", skipped)} skipped.`;
        messages.push(skippedMessage);
    }

    if (messages.length === 0) {
        return;
    }

    toast(messages.join(" "), {
        type: "success",
    });
}

export function toastErrorMessage(errorMessage?: string | null) {
    if (!errorMessage) {
        return;
    }

    toast(errorMessage, {
        type: "error",
    });
}
