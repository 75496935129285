import {makeStyles} from "@material-ui/core";
import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as ChromeLogo} from "../../assets/media/chrome-logo.svg";
import {ReactComponent as SafariLogo} from "../../assets/media/safari-logo.svg";
import BrandingLogo from "@ui/cdk/BrandingLogo";

const useStyles = makeStyles((theme) => ({
    root: {
        background: `linear-gradient(to bottom, ${theme.main.palette.basic.background}, ${theme.main.palette.gray[50]})`,
        fontFamily: theme.typography.fontFamily,
    },

    header: {
        padding: theme.spacing(0, 11.25),
        alignItems: "flex-end",
        height: 72,
    },
    headerLogo: {
        width: 164.5,
        maxWidth: "75%",
    },

    footer: {
        padding: theme.spacing(0, 11.25),
        height: 60,
    },
    footerCopyright: {
        color: theme.main.palette.text.senary,
    },
    footerLink: {
        color: theme.main.palette.blue[500],
        fontWeight: 500,
        marginRight: 36,
        textDecoration: "none",
        "&:last-child": {
            marginRight: 0,
        },
    },
    content: {
        margin: "auto",
        padding: theme.spacing(5, 3.75, 3.75, 3.75),
        borderRadius: 20,
        boxShadow: "0 25px 50px 0 rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.1)",
        background: theme.main.palette.elevation.surface[0],
        maxWidth: 404,
    },
    contentHeader: {
        fontWeight: "bold",
        "&.fosTypography": {
            color: theme.main.palette.text.primary,
            marginBottom: theme.spacing(2),
        },
    },
    contentSubheader: {
        color: theme.main.palette.text.primary,
        textAlign: "center",
    },
    browsers: {
        maxWidth: 318,
        marginTop: theme.spacing(3.75),
    },
    browserLogo: {
        width: 120,
        height: 120,
        marginBottom: theme.spacing(1.5),
    },
    browserHeader: {
        fontWeight: 600,
        color: theme.main.palette.text.primary,
        textAlign: "center",
    },
    browserSubheader: {
        fontWeight: "normal",
    },
    separator: {
        position: "relative",
        textAlign: "center",
        color: theme.main.palette.text.senary,
        maxWidth: 326,
        "&.fosTypography": {
            margin: theme.spacing(3.75, 0),
        },
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: `calc(50% - 0.5px)`,
            height: 1,
            width: "calc(50% - 24px)",
            backgroundColor: theme.main.palette.gray[200],
        },
        "&:before": {
            right: 0,
        },
        "&:after": {
            left: 0,
        },
    },
}));

interface UserBrowserPrompt {
    isBlocker: boolean;
    handleUserContinue: () => void;
}

const UserBrowserPrompt = (props: UserBrowserPrompt) => {
    const classes = useStyles();

    const {isBlocker, handleUserContinue} = props;

    const handleClick = () => {
        handleUserContinue();
    };

    return (
        <div className={cls("fullh flex flex-col", classes.root)}>
            <header className={cls("flex", classes.header)}>
                <BrandingLogo className={classes.headerLogo} />
            </header>
            <div className={cls("fullw flex flex-col flex-center-all", classes.content)}>
                <Typography variant="xl3" className={classes.contentHeader}>
                    Unsupported browser
                </Typography>
                <Typography className={classes.contentSubheader}>You’re using a web browser we don’t support yet.</Typography>
                <Typography className={classes.contentSubheader}>Please use one of these options to improve your experience.</Typography>
                <div className={cls("fullw flex flex-justify-between", classes.browsers)}>
                    <div>
                        <ChromeLogo className={classes.browserLogo} />
                        <Typography className={classes.browserHeader}>Chrome</Typography>
                        <Typography className={cls(classes.browserHeader, classes.browserSubheader)}>Latest version</Typography>
                    </div>
                    <div>
                        <SafariLogo className={classes.browserLogo} />
                        <Typography className={classes.browserHeader}>Safari</Typography>
                        <Typography className={cls(classes.browserHeader, classes.browserSubheader)}>Latest version</Typography>
                    </div>
                </div>
                {!isBlocker && (
                    <>
                        <Typography className={cls("fullw", classes.separator)}>OR</Typography>
                        <Button variant="primary" paddingLarge fullWidth onClick={handleClick}>
                            Continue
                        </Button>
                    </>
                )}
            </div>
            <footer className={cls("flex flex-justify-between flex-align-start", classes.footer)}>
                <Typography variant="lg" className={classes.footerCopyright}>
                    Copyright ©{new Date().getFullYear()} Sessions. All Rights Reserved
                </Typography>

                <div className="flex flex-align-center">
                    <Link
                        className={classes.footerLink}
                        target="_blank"
                        underline="none"
                        href={getHelpCenterArticleLink("knowledge-base/terms-privacy/privacy-policy")}
                    >
                        <Typography component="span" variant="lg">
                            Privacy Policy
                        </Typography>
                    </Link>
                    <Link
                        className={classes.footerLink}
                        target="_blank"
                        underline="none"
                        href={getHelpCenterArticleLink("knowledge-base/terms-privacy/terms-and-conditions")}
                    >
                        <Typography component="span" variant="lg">
                            Terms &amp; Conditions
                        </Typography>
                    </Link>
                </div>
            </footer>
        </div>
    );
};

export default UserBrowserPrompt;
