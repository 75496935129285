import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import MicOff from "@material-ui/icons/MicOff";
import Typography from "@ui/cdk/Typography";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useDevices, useForceMuteStatus, useSilentMic} from "@workhorse/api/conference2";
import {makeVar} from "@workhorse/api/data";
import {ReactNode, useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import {microNotify} from "../../micro-notification-center";
import {MuteNotification, MuteNotificationType} from "../../micro-notification-center/notifications/MuteNotification";
import {videoBasedArtifacts} from "../LeftNotificationsWrapper";
import NotificationWrapper from "../NotificationWrapper";
import classes from "../style/NotificationWrapper.module.scss";
import {MutedAudioAnalyzer} from "./MutedAudioAnalyser";
import classes2 from "./MutedInformation.module.scss";

export const isSpeaking = makeVar(false);

function Notification({children, className, onClose}: {children?: ReactNode; className?: string; onClose?: () => void}) {
    return (
        <NotificationWrapper className={className} onClose={onClose}>
            <MicOff className={classes.mic} />
            <div className="flex flex-col">{children}</div>
        </NotificationWrapper>
    );
}

function MutedAndSpeaking() {
    const {t} = useTranslation();
    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
    };

    useEffect(() => {
        setTimeout(() => {
            close();
        }, 5000);
    }, []);

    if (show === false) {
        return null;
    }

    return (
        <Notification onClose={close}>
            <Typography>
                <strong>{t("player.notifications.mic.are_you_speaking") ?? "Are you speaking?"}</strong> <br />
                {t("player.notifications.mic.your_microphone_is_muted") ?? "Your microphone is muted. Click “Unmute mic” to turn it on."}
            </Typography>
        </Notification>
    );
}

function UnmutedAndSilent({onClose}: {onClose?: () => void}) {
    const {t} = useTranslation();

    const title = t("player.notifications.mic.are_you_speaking") ?? "Are you speaking?";
    const content =
        t("player.notifications.mic.silent_mic_warning") ??
        "We cannot detect sound from your microphone. If you're experiencing audio issues, please ensure your microphone is connected and unmuted, both physically and in your operating system's sound settings.";
    const contentSafari =
        t("player.notifications.mic.silent_mic_safari_warning") ?? "Also make sure the microphone is unmuted in the URL address bar.";

    return (
        <Notification onClose={onClose}>
            <Typography className={classes2.silentDetector}>
                <strong>{title}</strong> <br />
                {content} {browserInfo.isSafari() ? contentSafari : ""}
            </Typography>
        </Notification>
    );
}

function HardMuteInfo({bySystem, onExit}: {bySystem: boolean; onExit?: () => void}) {
    const {t} = useTranslation();

    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
    };

    useEffect(() => {
        setTimeout(() => {
            close();
        }, 5000);
        return () => {
            onExit?.();
        };
    }, [onExit]);

    if (show === false) {
        return <Notification className={classes.smallNotification} />;
    }

    return (
        <Notification className={"large"} onClose={close}>
            <Typography>
                {bySystem
                    ? t("player.notifications.mic.muted_by_system") ?? "You have been muted by the system."
                    : t("player.notifications.mic.muted_by_host") ?? "You have been muted by the host."}{" "}
                {t("player.notifications.mic.choose_state_after_unmute") ??
                    "You can still choose your mic’s state but it will apply once you will be un-muted."}
            </Typography>
        </Notification>
    );
}

function HardMuteExit({muted, bySystem, onExit}: {muted: boolean; bySystem: boolean; onExit?: () => void}) {
    const {t} = useTranslation();

    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
    };

    useEffect(() => {
        setTimeout(() => {
            close();
        }, 5000);
        return () => {
            onExit?.();
        };
    }, [onExit]);

    if (show === false) {
        return null;
    }

    return (
        <Notification className={"large"} onClose={close}>
            <Typography>
                {bySystem
                    ? t("player.notifications.mic.unmuted_by_system") ?? "You have been un-muted by the system."
                    : t("player.notifications.mic.unmuted_by_host") ?? "You have been un-muted by the host."}
                <br />
                {muted
                    ? t("player.notifications.mic.your_microphone_is_now_muted") ?? "Your microphone is now muted."
                    : t("player.notifications.mic.your_microphone_is_now_unmuted") ?? "Your microphone is now un-muted."}
            </Typography>
        </Notification>
    );
}

interface MutedInformationProps {
    artifactId: SimpleArtifactsTag;
    muteEveryone: boolean;
    renderingForAdmin: boolean;
    forcedMute: boolean;
}

export const MutedInformation = (props: MutedInformationProps) => {
    const {artifactId} = props;

    const isVideoArtifact = videoBasedArtifacts.includes(artifactId);

    const {audioEnabled, audioMuted} = useDevices();
    const muted = !audioEnabled || audioMuted;
    const {muteStatus, muteType} = useForceMuteStatus();
    const hardMute = muteStatus === "hard";

    const {shouldShowWarning: showSilentMic, dismiss: dismissSilentMic} = useSilentMic();

    const [hardMuteExit, setHardMuteExit] = useState(false);
    const enableHardMuteExit = useCallback(() => setHardMuteExit(true), []);
    const disableHardMuteExit = useCallback(() => setHardMuteExit(false), []);

    const [userSpeaking, setUserSpeaking] = useState(false);
    const onUserSpeaking = useCallback(() => setUserSpeaking(true), []);

    useEffect(() => {
        setUserSpeaking(false);
    }, [muted, hardMute]);

    useEffect(() => {
        setHardMuteExit(false);
    }, [muted]);

    useEffect(() => {
        if (muteStatus === "soft" && muteType === "soft") {
            microNotify<MuteNotificationType>({
                component: MuteNotification,
                message: {
                    action: "mute-everyone",
                },
                type: "mute-unmute",
            });
        }

        if (muteStatus === "soft" && muteType === "individual") {
            microNotify<MuteNotificationType>({
                component: MuteNotification,
                message: {
                    action: "soft-mute",
                },
                type: "mute-unmute",
            });
        }

        if (muteStatus === "soft" && muteType === "media") {
            microNotify<MuteNotificationType>({
                component: MuteNotification,
                message: {
                    action: "media-mute",
                },
                type: "mute-unmute",
            });
        }

        if (muted && muteStatus === "mic-on" && muteType === "reset") {
            microNotify<MuteNotificationType>({
                component: MuteNotification,
                message: {
                    action: "unmute",
                },
                type: "mute-unmute",
            });
        }
    }, [muteStatus, muteType]);

    let info;
    if (hardMuteExit) {
        info = <HardMuteExit muted={muted} bySystem={muteType === "media"} onExit={disableHardMuteExit} />;
    }

    if (muted && hardMute) {
        info = <HardMuteInfo bySystem={muteType === "media"} onExit={enableHardMuteExit} />;
    }

    if (muted && !hardMute && userSpeaking && !isVideoArtifact) {
        info = <MutedAndSpeaking />;
    }

    if (!muted && showSilentMic) {
        info = <UnmutedAndSilent onClose={dismissSilentMic} />;
    }

    return (
        <>
            {info}
            {muted && <MutedAudioAnalyzer onSpeak={onUserSpeaking} />}
        </>
    );
};
