import {PartialDeep} from "type-fest";

export interface Environment {
    production: boolean;
    enablePlayground: boolean;

    enableLogrocket: boolean;
    logrocketProject: string;

    enableRecurrence?: boolean;

    environmentName?: string;

    enableBuildInfo: boolean;

    databaseUrl: string;
    s3PublicBucket: string;
    s3PrivateBucket: string;
    s3FsUri: string;

    clientReadUrl: string;
    clientWriteUrl: string;
    clientWsUrl: string;

    redis: {
        host: string;
        port: number;
    };

    landingPage: string;

    logstashHost: string;
    logstashPort: string;

    notificationServiceUrl: string;
    notificationAppId: string;

    centrifugalUrl: string;
    centrifugalApiKey: string;

    chimeApiKey: string;
    chimeApiSecret: string;

    mailServiceUrl: string;
    defaultMailProvider: string;

    disableEmailSending?: boolean;

    forceDevPlayerAccess: string;
    disableStartupTimer?: string;

    google: {
        clientId: string;
        clientSecret: string;
    };

    disableConferencing: boolean;

    drawingServiceUrl: string;

    gotenbergUrl: string;

    analyticsProduction?: boolean;
    gaMeasurementId?: string;
    innerTrendsId?: string;
    mixpanelToken?: string;
    googleTagManagerId?: string;
    staticServiceUrl: string;

    authService: {
        root: string;
        appRoot: string;
        originalAppRoot?: string;
        cookieName: string;
        jwtSigningSecret: string;
        apiSecretKey: string;
        enableRegistration: string;
        verifyEmail: string;
    };

    hubspotAccessToken?: string;

    elasticCloud: {
        id: string;
        secret: string;
    };

    analyticsIndexName: string;
    sessionAnalyticsIndexName: string;

    jwtSecret: string;

    hubspotPortalId: string;

    hubspotFormId: string;
    productboardApiToken?: string;

    publicResourcesCdnRoot?: string;

    slack: {
        userJoinedUrl?: string;
        appName?: string;
        appIcon?: string;
        workspace?: string;
    };

    giphy: {
        apiSecret?: string;
    };

    maximumUserConcurrentVideos: string;

    miroClientId: string;
    miroClientSecret: string;

    applicationStateWriteSecret: string;

    allowDebugErrors?: boolean;
    disableAppRemoteState?: boolean;

    helpCenterArticleBase?: string;
    resourcesBase?: string;

    canvaExtensionClientSecret: string;
    twitchClientId: string;
    twitchClientSecret: string;

    sessionsFlowosMail: string;

    devtoolsEnabled?: boolean;

    csd?: {
        disable: boolean;
        root: string;
        namespace: string;
        serviceName: string;
        secretName: string;
        realDomain: string;
    };

    youtubeApiKey: string;

    microsoft: {
        clientId: string;
        clientSecret: string;
        authority: string;
    };

    systemUpdateUrl?: string;

    unsplashApiKey: string;

    disableHelpIndicator?: boolean;

    convertApiSecret: string;

    uipathEventsServiceUrl: string;
    uipathEventsServiceSecret: string;

    recordingServer: {
        host: string;
        bucketName: string;
        publicRootUrl: string;
    };

    searching: {
        indexSuffix: string;
    };

    guestTokenDuration: number;

    evernote: {
        consumerKey: string;
        consumerSecret: string;
    };

    notion: {
        clientId: string;
        clientSecret: string;
    };

    pspdfkit: {
        privateKey: string;
        serverUrl: string;
        apiKey: string;
    };

    deleteContactsWorkerDelayInMs: number;
    deleteContactsWorkerDaysLimit: number;

    googleContactsSyncWorkerDelayInMs: number;
    microsoftContactsSyncWorkerDelayInMs: number;

    uipathTemplateTagId: string;
    uipathMasterEmail: string;
    uipathEmailDomain: string;

    createOrganizationAdminWorkerDelayInMs: number;

    presence: {
        host: string;
        channel: string;
    };

    myProductToolSocketHost: string;
    eventBaseURL: string;

    googleDriveWatchChannelExpirationInDays: number;
    googleDriveWatchChannelUpdateDelayInMs: number;

    googleProjectId: string;
    transcoderLocation: string;
    transcoderVideoTopicName: string;
    transcoderRecordingTopicName: string;

    integrationServiceUrl: string;

    chimeWebhookSecret: string;

    remoteRecorder: {
        apiUrl: string;
        apiSecret: string;
        publicBaseUrl: string;
    };

    openaiApiKey: string;
    openaiApiKey2: string;
    editorHocuspocusWs: string;
    whiteboardSyncServerUrl: string;
    transVersion?: string;

    cnameDomain: string;
    liveKitURL: string;

    paddleVendorId: string;
    paddleSandbox: boolean;
    celloProductId: string;

    desktopAppDownloadURL: string;
    sessionsReleaseVer: string;
    stripeAppUrl: string;
    doptBlocksApiKey: string;
    demoSessionId: string;
    paypalAppUrl: string;
}

export function partialEnvironment<T extends PartialDeep<Environment>>(env: T): T {
    return env;
}
