import {RoomDataFragment} from "@generated/data";
import ControlPointDuplicateRoundedIcon from "@material-ui/icons/ControlPointDuplicateRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@ui/cdk/Button";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import Switch from "@ui/cdk/Switch";
import TableCell from "@ui/cdk/Table/TableCell";
import TableRow from "@ui/cdk/Table/TableRow";
import {cls} from "@ui/cdk/util";
import Fade from "@ui/core/components/Fade";
import IconButton from "@ui/core/components/IconButton";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import React, {useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {useUserInfo} from "@workhorse/providers/User";
import CopyToClipboard from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import RoomsTableCell from "./RoomsTableCell";
import classes from "./style/RoomsTableRow.module.scss";
import {cloneRoom, RoomsTableCellType} from "./utils";

type RoomsTableRowProps = {
    room: RoomDataFragment;
    tableColumns: Array<{
        id: string;
        label: string;
        type: RoomsTableCellType;
        isSearchInput?: boolean;
    }>;
    onDelete: (id: string) => void;
    disableRoom: (id: string) => void;
    enableRoom: (roomId: string) => void;
    extra: {
        halfDayClock: boolean;
    };
};

function RoomsTableRow(props: RoomsTableRowProps) {
    const {room, tableColumns} = props;
    const {halfDayClock} = props.extra;
    const {sessionTemplate} = room;

    const {t} = useTranslation();

    const history = useHistory();

    const user = useUserInfo();
    const {mixpanelTrack} = useMixpanelTracker();

    const roomOwnerId = room.user.id;
    const isRoomOwner = user.id === roomOwnerId;

    const workspaceAccess = useWorkspaceAccess();
    const canCloneRoom = workspaceAccess.canCloneRoom(roomOwnerId);
    const canToggleRoom = workspaceAccess.canToggleRoom(roomOwnerId);
    const canEditRoom = workspaceAccess.canEditRoom(roomOwnerId);
    const canDeleteRoom = workspaceAccess.canDeleteRoom(roomOwnerId);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const roomSessionInstanceUrl = `https://${parent.top?.location.host}/room/${room.slug}`;

    const ownerName = isRoomOwner ? t("participant.g.you") ?? "You" : `${room.user.firstName} ${room.user.lastName}`.trim();

    const formattedEvent = {
        name: sessionTemplate?.name,
        createdAt: sessionTemplate?.createdAt,
        active: room.active,
        owner: ownerName,
        copyLink: roomSessionInstanceUrl,
        enterRoom: room.slug,
    };

    const handleCloneRoom: React.MouseEventHandler<HTMLLIElement> = (e) => {
        if (e?.detail && e.detail > 1) {
            return;
        }
        cloneRoom(room.id).then((res) => {
            if (!res.data?.cloneRoom?.id) {
                // TODO: maca - find a way to get the error code here
                if (res.errors?.[0]?.message?.includes("limit")) {
                    return;
                }
                toast("Something went wrong when trying to clone the room", {type: "error"});
            } else {
                history.push({
                    pathname: `/rooms/${res.data.cloneRoom.sessionTemplate?.id}`,
                });
            }
        });
        mixpanelTrack("frontend-clone-room", "rooms");
    };

    const handleOpenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleCloseMenu();
        props.onDelete(room.id);
        mixpanelTrack("frontend-delete-room", "rooms");
    };

    const disableRoom: React.MouseEventHandler<HTMLLIElement> = () => {
        handleCloseMenu();
        props.disableRoom(room.id);
    };

    const enableRoom: React.MouseEventHandler<HTMLLIElement> = () => {
        handleCloseMenu();
        props.enableRoom(room.id);
    };

    const handleNavToRoom = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        handleCloseMenu();
        history.push({
            pathname: `/rooms/${room.id}/instances`,
        });
    };

    const handleNavToEditRoom = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        handleCloseMenu();
        history.push({
            pathname: `/rooms/${sessionTemplate?.id}`,
        });
        mixpanelTrack("frontend-edit-room", "rooms");
    };

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        handleNavToRoom(e);
    };

    const handleOnSessionLinkCopy = () => {
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
        handleCloseMenu();
        mixpanelTrack("frontend-copy-room-link", "rooms");
    };

    return (
        <>
            <TableRow className={classes.root} onClick={handleClick} data-id="rooms-entry-preview">
                {tableColumns.map((column) => (
                    <RoomsTableCell
                        key={column.id}
                        type={column.type}
                        value={formattedEvent[column.id]}
                        isRoomActive={room.active}
                        halfDayClock={halfDayClock}
                        source={room.sessionTemplate?.source}
                    />
                ))}
                <TableCell size="medium">
                    <IconButton
                        onClick={handleOpenMenu}
                        className={cls(classes.moreBtn, anchorEl && classes.moreBtnActive)}
                        data-id="room-menu-entry-more-options"
                    >
                        <MoreVert />
                    </IconButton>
                </TableCell>
            </TableRow>

            <Menu
                id="fade-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
                classes={{paper: classes.moreMenuPaper}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                getContentAnchorEl={null}
            >
                {canToggleRoom && (
                    <Button
                        data-id="disable-room-button"
                        variant="plain"
                        startIcon={<Switch checked={room.active} color="default" />}
                        onClick={room.active ? disableRoom : enableRoom}
                        noFocusBorder
                        component={MenuItem}
                        className={cls("flex-justify-start", classes.menuItem, room.active && "blue")}
                    >
                        {room.active ? "Disable this room" : "Enable this room"}
                    </Button>
                )}

                <CopyToClipboard text={roomSessionInstanceUrl} onCopy={handleOnSessionLinkCopy}>
                    <MenuItem className={classes.menuItem} data-id="rooms-entry-copy-link">
                        <FileCopyOutlinedIcon />
                        Copy room link
                    </MenuItem>
                </CopyToClipboard>
                {canCloneRoom && (
                    <MenuItem className={classes.menuItem} data-id="rooms-entry-clone-room" onClick={handleCloneRoom}>
                        <ControlPointDuplicateRoundedIcon />
                        Clone room
                    </MenuItem>
                )}
                {canEditRoom && (
                    <MenuItem onClick={handleNavToEditRoom} className={classes.menuItem} data-id="edit-room">
                        <EditIcon />
                        Edit room
                    </MenuItem>
                )}
                {canDeleteRoom && (
                    <MenuItem className={cls(classes.menuItem, "red")} onClick={handleDelete} data-id="delete-room" variant="destructive">
                        <DeleteRoundedIcon />
                        Delete room
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}

export default RoomsTableRow;
