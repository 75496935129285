import {
    CustomArtifactText,
    CustomElement,
    CustomGenericElement,
    CustomGenericText,
    CustomText,
    Editor,
    EditorElement,
    EditorHeadingElement,
    EditorListElement,
    EditorListItemElement,
    JSONContent,
    SlateGenericElement,
} from "./definitions";

export const isGenericText = (text: JSONContent): text is CustomGenericText => {
    if (text.text) {
        return true;
    }

    return false;
};

export const isArtifactText = (text: CustomText): text is CustomArtifactText => {
    return !isGenericText(text);
};

export const isEditorGenericElement = (text: JSONContent): text is CustomGenericElement => {
    if ("type" in text) {
        return true;
    }

    return false;
};

export const isSlateGenericElement = (element: JSONContent): element is SlateGenericElement => {
    if (element.alignment) {
        return true;
    }

    return false;
};

export const isEditorHeadingElement = (type: EditorElement): type is EditorHeadingElement => {
    return type === "heading";
};

export const isEditorListElement = (type: EditorElement): type is EditorListElement | EditorListItemElement => {
    return ["bulletList", "orderedList", "taskList"].includes(type);
};

export const isEditorListItemElement = (type: EditorElement): type is EditorListItemElement => {
    return type === "listItem" || type === "taskItem";
};

export const isEditorTaskListElement = (type: EditorElement): type is EditorListItemElement => {
    return type === "taskList";
};

export const isEditorTaskItemElement = (type: EditorElement): type is EditorListItemElement => {
    return type === "taskItem";
};

export const isEditorParagraphElement = (type: EditorElement): type is EditorListItemElement => {
    return type === "paragraph";
};

export const isEditorArtifactElement = (element: CustomElement): element is CustomElement => {
    if ("order" in element) {
        return true;
    }

    return false;
};

export const isText = (element: CustomText | CustomElement): element is CustomText => {
    if ("text" in element) {
        return true;
    }

    return false;
};

export const isEditorElement = (element: CustomText | CustomElement): element is CustomElement => {
    if ("type" in element) {
        return true;
    }

    return false;
};

export const isEmptyNode = (node: JSONContent): boolean => {
    try {
        return !!(node.type && !node.content);
    } catch (err) {
        return true;
    }
};

export const isListElement = (editor: Editor) => {
    return editor.isActive("bulletList") || editor.isActive("orderedList");
};

export const getEmptyValue = () => {
    return {
        type: "doc",
        content: [
            {
                type: "paragraph",
                attrs: {
                    indent: 0,
                    textAlign: "left",
                },
            },
        ],
    };
};
