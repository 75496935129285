import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import {WithMobileState} from "@workhorse/declarations";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import ScreenShareContainer from "./ScreenShareContainer";
import {useEffect, useState} from "@workhorse/api/rendering";

type ScreenShareRendererType = {
    microId: string;
    artifactId: string;
    sessionId: string;
    myParticipantId: string;
} & WithMobileState;

export function persistFloatingState(sessionId: string, value: boolean) {
    localStorage.setItem(`ss-floating-${sessionId}`, value ? "1" : "0");
}

function getDrawerElement() {
    return document.querySelector("#with-drawer-right-content");
}

const ScreenShareRenderer = (props: ScreenShareRendererType) => {
    const {sessionId, myParticipantId} = props;
    const {grid} = useSessionView();

    const {shareMask, sharingParticipantId, stream} = useScreenShareOptions();
    const [root] = useState<HTMLDivElement | null>(() => document.querySelector(":root"));

    useEffect(() => {
        if (!sharingParticipantId && !stream) {
            return;
        }

        root?.style.setProperty("--app-micro-visibility", "hidden");

        return () => {
            root?.style.setProperty("--app-micro-visibility", "visible");
        };
    }, [sharingParticipantId, stream]);

    if (!sharingParticipantId && !stream) {
        return null;
    }

    return (
        <ErrorBoundary key={`share-screen-error-${sessionId}`} mobileState={props.mobileState} location="ScreenShareRenderer">
            <ScreenShareContainer
                isGridMode={grid}
                sessionId={props.sessionId}
                sharingParticipantId={stream ? myParticipantId : sharingParticipantId!}
                isLocalUserSharing={!!stream}
                withMask={shareMask}
                key="share-screen-container"
            />
        </ErrorBoundary>
    );
};

export default ScreenShareRenderer;
