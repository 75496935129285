import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {HyperlinkExternal} from "@workhorse/util/links";
import classes from "./styles/FooterGeneric.module.scss";

function FooterGeneric() {
    const privacyPolicyLink = getHelpCenterArticleLink("knowledge-base/terms-privacy/privacy-policy");
    const termsAndConditionsLink = getHelpCenterArticleLink("knowledge-base/terms-privacy/terms-and-conditions");

    return (
        <footer className={cls("flex flex-justify-between", classes.footer)}>
            <Typography variant="base" className={classes.footerCopyright}>
                Copyright ©{new Date().getFullYear()} Sessions. All Rights Reserved
            </Typography>

            <div className={cls("flex flex-align-center", classes.linksContainer)}>
                <HyperlinkExternal className={classes.footerLink} target="_blank" rel="noreferrer" href={privacyPolicyLink}>
                    Privacy Policy
                </HyperlinkExternal>

                <div className={classes.spacer} />

                <HyperlinkExternal className={classes.footerLink} target="_blank" rel="noreferrer" href={termsAndConditionsLink}>
                    Terms &amp; Conditions
                </HyperlinkExternal>
            </div>
        </footer>
    );
}

export default FooterGeneric;
