import {cs, de, es, fr, enUS, it, ko, pt, ro, ja, nl, pl, zhCN, zhTW, ptBR, ru, sl} from "date-fns/locale";

export const localeWeekdayOfMonthDateFnsFormat: {
    [key: string]: string;
} = {
    en: "EEEE, MMMM d",
    enUS: "EEEE, MMMM d",
    fr: "EEEE, d MMMM",
    de: "EEEE, d. MMMM",
    es: "EEEE, d 'de' MMMM",
    pt: "EEEE, d 'de' MMMM",
    ko: "EEEE, MMMM d",
    cz: "EEEE, d MMMM",
    it: "EEEE, d MMMM",
    ro: "EEEE, d MMMM",
    ja: "EEEE, MMMM d",
    nl: "EEEE, d MMMM",
    pl: "EEEE, d MMMM",
    zhCN: "EEEE, MMMM d",
    zhTW: "EEEE, MMMM d",
    ptBR: "EEEE, d 'de' MMMM",
    ru: "EEEE, d MMMM",
    sl: "EEEE, d MMMM",
};

export const localeMonthDayDateFnsFormat: {
    [key: string]: string;
} = {
    en: "MMMM d, yyyy",
    enUS: "MMMM d, yyyy",
    fr: "d MMMM yyyy",
    de: "d. MMMM yyyy",
    es: "d 'de' MMMM yyyy",
    pt: "d 'de' MMMM yyyy",
    ko: "MMMM d, yyyy",
    cz: "d. MMMM yyyy",
    it: "d MMMM yyyy",
    ro: "d MMMM yyyy",
    ja: "MMMM d, yyyy",
    nl: "d MMMM yyyy",
    pl: "d MMMM yyyy",
    zhCN: "MMMM d, yyyy",
    zhTW: "MMMM d, yyyy",
    ptBR: "d 'de' MMMM yyyy",
    ru: "d MMMM yyyy",
    sl: "d MMMM yyyy",
};

export const localeShortMonthDayDateFnsFormat: {
    [key: string]: string;
} = {
    en: "MMM d, YYY",
    enUS: "MMM d, YYY",
    fr: "d MMM YYY",
    de: "d. MMM YYY",
    es: "d 'de' MMM YYY",
    pt: "d 'de' MMM YYY",
    ko: "MMM d, YYY",
    cz: "d. MMM YYY",
    it: "d MMM YYY",
    ro: "d MMM YYY",
    ja: "MMM d, YYY",
    nl: "d MMM YYY",
    pl: "d MMM YYY",
    zhCN: "MMM d, YYY",
    zhTW: "MMM d, YYY",
    ptBR: "d 'de' MMM YYY",
    ru: "d MMM YYY",
    sl: "d MMM YYY",
};

/**
 * Returns the date-fns locale object for the given locale code. Can be used in the date-fns format function.
 *
 * @param locale The locale code.
 * @returns The date-fns locale.
 * @example
 * getDateFnsLocale("en");
 * // => enUS
 *
 **/

export const getDateFnsLocale = (locale: string) => {
    switch (locale) {
        case "en":
        case "enUS":
            return enUS;
        case "fr":
            return fr;
        case "de":
            return de;
        case "es":
            return es;
        case "pt":
            return pt;
        case "ko":
            return ko;
        case "cz":
            return cs;
        case "cs":
            return cs;
        case "it":
            return it;
        case "ro":
            return ro;
        case "ja":
            return ja;
        case "nl":
            return nl;
        case "pl":
            return pl;
        case "zh-CN":
        case "zhCN":
            return zhCN;
        case "zh-TW":
        case "zhTW":
            return zhTW;
        case "pt-BR":
        case "ptBR":
            return ptBR;
        case "ru":
            return ru;
        case "sl":
            return sl;
        default:
            return enUS;
    }
};
