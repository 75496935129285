import FormatBoldRoundedIcon from "@material-ui/icons/FormatBoldRounded";
import FormatItalicRoundedIcon from "@material-ui/icons/FormatItalicRounded";
import FormatUnderlinedRoundedIcon from "@material-ui/icons/FormatUnderlinedRounded";
import FormatStrikethroughSRoundedIcon from "@material-ui/icons/StrikethroughSRounded";

import {useCallback, useMemo} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {EditorTextFormatting, toggleMark, ToolbarGenericProps} from "../api";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";

type TipTapMarks = Exclude<EditorTextFormatting, "strickenThrough">;

export type ToolbarMarkProps = ToolbarGenericProps & {
    exclude?: TipTapMarks[];
};

const FORMATTING: Record<TipTapMarks, {title: string; icon: JSX.Element}> = {
    bold: {title: "Bold", icon: <FormatBoldRoundedIcon />},
    italic: {title: "Italic", icon: <FormatItalicRoundedIcon />},
    strike: {title: "Strikethrough", icon: <FormatStrikethroughSRoundedIcon />},
    underline: {title: "Underline", icon: <FormatUnderlinedRoundedIcon />},
};

export const ToolbarMarks = (props: ToolbarMarkProps) => {
    const {editor} = props;

    const onActionDone = useMemo(() => props.onActionDone, []);

    const handleClick = useCallback(
        (mark: TipTapMarks) => {
            toggleMark(editor, mark);
            onActionDone();
        },
        [editor, onActionDone]
    );

    return (
        <>
            {Object.entries(FORMATTING).map(([mark, info]) =>
                props.exclude?.includes(mark as TipTapMarks) ? null : (
                    <ToolbarItem
                        key={mark}
                        tooltip={info.title}
                        className={cls(classes.button, editor?.isActive(mark) && classes.buttonActive)}
                        onClick={() => handleClick(mark as TipTapMarks)}
                        disabled={!editor.isEditable}
                        isMobile={props.isMobile}
                    >
                        {info.icon}
                    </ToolbarItem>
                )
            )}
        </>
    );
};
