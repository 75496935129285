import Input from "@ui/cdk/Input";

import PermIdentityRoundedIcon from "@material-ui/icons/PermIdentityRounded";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import FormikFieldWrapper from "@ui/cdk/FormikFieldWrapper";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {collapseWhiteSpace} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {removeTypenameKey} from "@workhorse/api/designer/lib/utils";
import {useMemo, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useMutation, writeQuery} from "@workhorse/dataApi";
import {useParticipants} from "@workhorse/providers/SessionDataProviders";
import imageCompression from "browser-image-compression";
import {Form, Formik, FormikProps} from "formik";
import common from "../eventCommons.module.scss";
import classes from "./style/NewSpeakerDialog.module.scss";
import {makeParticipantSpeakerData, newSpeakerFormSchema, ParticipantWithSpeakerDetailsPayload} from "./utils";

type FormValuesProps = {
    email: string;
    name: string;
    bio: string;
    company: string;
    jobTitle: string;
    linkedInUrl: string;
    photoUrl: string;
    thumbnailUrl: string;
    photoFile: File | null;
    thumbnailFile: File | null;
};

type NewSpeakerDialogProps = {
    closeNewSpeaker: () => void;
    sessionId: string;
    mode: "edit" | "add";
    speakerId: string | null;
    newSpeakerData: {
        firstName: string;
        lastName: string;
        email: string;
    } | null;
    setNewSpeakerData: React.Dispatch<
        React.SetStateAction<{
            firstName: string;
            lastName: string;
            email: string;
        } | null>
    >;
    orderArray: string[];
    eventSpeakerEmails: string[];
};

const compressionOptions = {
    maxSizeMB: 1.2, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
};

const compressionOptionsThumbnail = {
    maxSizeMB: 0.2, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 200, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    maxIteration: 30, // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
};

const NewSpeakerDialog = (props: NewSpeakerDialogProps) => {
    const {closeNewSpeaker, sessionId, mode, speakerId, newSpeakerData, setNewSpeakerData, orderArray, eventSpeakerEmails} = props;

    const dialogRef = useRef<DialogImperativeRef>();

    const [loading, setLoading] = useState(false);

    const participants = useParticipants();
    const participantToEdit = participants.find((x) => x.speakerDetails !== null && x?.speakerDetails?.id === speakerId);
    const isParticipantUser = !participantToEdit?.dataWithNullableEmail.isGuest ?? false;

    const otherParticipantsEmails = useMemo(() => {
        return participants
            .map((participant) => participant.dataWithNullableEmail.email)
            .filter((email) => {
                if (participantToEdit?.dataWithNullableEmail.email === email) {
                    return false;
                }
                return true;
            });
    }, [participants, participantToEdit]);

    const formRef = useRef<FormikProps<FormValuesProps>>(null);

    const fields = newSpeakerFormSchema.fields;
    const photoFieldIndex = fields.findIndex((field) => field.name === "photoUrl");
    const bioFieldIndex = fields.findIndex((field) => field.name === "bio");

    const [updateParticipants] = useMutation("UpdateParticipantsInSessionStandaloneDocument", {});
    const [uploadSessionSpeakerPhoto] = useMutation("UploadSessionSpeakerPhotoDocument", {});
    const [confirmUploadSessionSpeakerPhoto] = useMutation("ConfirmUploadSessionSpeakerPhotoDocument", {});

    const participantInitialValues = {
        email: participantToEdit?.dataWithNullableEmail.email ?? "",
        name: participantToEdit?.speakerDetails?.name ?? "",
        bio: participantToEdit?.speakerDetails?.bio ?? "",
        company: participantToEdit?.speakerDetails?.company ?? "",
        jobTitle: participantToEdit?.speakerDetails?.jobTitle ?? "",
        linkedInUrl: participantToEdit?.speakerDetails?.linkedInUrl ?? "",
        photoUrl: participantToEdit?.speakerDetails?.photoUrl ?? "",
        thumbnailUrl: participantToEdit?.speakerDetails?.thumbnailUrl ?? "",
        photoFile: null,
        thumbnailFile: null,
    };

    const formInitialValues = participantToEdit
        ? participantInitialValues
        : newSpeakerData
        ? {
              ...newSpeakerFormSchema.initialValues,
              ...newSpeakerData,
          }
        : newSpeakerFormSchema.initialValues;

    const onPhotoChange = async (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
        setLoading(true);
        try {
            if (e.target?.files?.length && e.target.files?.length > 0) {
                const file = e.target.files[0];
                const maximumUploadSize = 5;
                const sizeMb = parseFloat((file.size / (1024 * 1024)).toFixed(2));

                const acceptedTypes = ["image/jpeg", "image/jpg", "image/png"];
                if (!acceptedTypes.includes(file.type)) {
                    toast("Please upload one of the following formats: jpg, png, jpeg", {
                        type: "error",
                    });
                    throw Error("Bad format");
                }
                if (sizeMb > maximumUploadSize) {
                    toast("Speaker photo can't be bigger than 5MB.", {
                        type: "error",
                    });
                    throw Error("Max file size exceeded");
                }

                const thumbnailFile = await imageCompression(file, compressionOptionsThumbnail);
                const photoFile = await imageCompression(file, compressionOptions);

                setFieldValue("photoFile", photoFile);
                setFieldValue("thumbnailFile", thumbnailFile);
                setLoading(false);
            } else {
                throw Error("Upload canceled");
            }
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    async function uploadAndGetPhotoUrl(file: File | null, participantId: string, isThumbnail?: boolean) {
        setLoading(true);

        try {
            if (!file) {
                setLoading(false);
                return;
            }

            const cacheBuster = Date.now().toString();

            const logError = (err: string) => {
                console.error(`Error occurred when uploading profile picture: ${err}`);
                toast(`Error occurred when uploading profile picture`, {type: "error"});
                setLoading(false);
                throw Error(err);
            };

            const newPhoto = await uploadSessionSpeakerPhoto({
                variables: {sessionId: sessionId, participantId: participantId, cacheBuster: cacheBuster, isThumbnail: isThumbnail},
            })
                .then((uploadUrlRes) => {
                    if (!uploadUrlRes || !uploadUrlRes.data?.uploadSessionSpeakerPhoto) {
                        logError("No data returned from server.");
                        return null;
                    }

                    return fetch(uploadUrlRes.data.uploadSessionSpeakerPhoto, {
                        method: "PUT",
                        body: file,
                        headers: {
                            "Content-Type": !!file.type ? file.type : "application/octet-stream",
                        },
                    }).then((fetchResponse) => {
                        if (fetchResponse.ok) {
                            return confirmUploadSessionSpeakerPhoto({
                                variables: {
                                    sessionId: sessionId,
                                    participantId: participantId,
                                    cacheBuster: cacheBuster,
                                    isThumbnail: isThumbnail,
                                },
                            }).then((confirmationResponse) => {
                                setLoading(false);

                                if (!confirmationResponse || !confirmationResponse.data?.confirmUploadSessionSpeakerPhoto) {
                                    logError("No data returned for upload confirmation.");
                                    return null;
                                } else {
                                    return confirmationResponse.data.confirmUploadSessionSpeakerPhoto;
                                }
                            });
                        } else {
                            logError("Fetch response is not 200.");
                            return null;
                        }
                    });
                })
                .catch((err) => {
                    logError(`Error occurred when uploading profile picture: ${err}`);
                    return null;
                });

            return newPhoto;
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    }

    const handleClose = () => {
        closeNewSpeaker();
        setNewSpeakerData(null);
    };

    const handleSubmit = async (values: FormValuesProps) => {
        let participantData: ParticipantWithSpeakerDetailsPayload | null = null;
        const editingParticipant = !participantToEdit
            ? participants.find((x) => x?.speakerDetails?.id === speakerId || x?.dataWithNullableEmail?.email === values.email)
            : participantToEdit;

        if (!editingParticipant) {
            participantData = makeParticipantSpeakerData(values.email?.toLocaleLowerCase(), {
                id: undefined,
                email: values.email?.toLocaleLowerCase(),
                name: values.name,
                bio: values.bio,
                company: values.company,
                jobTitle: values.jobTitle,
                linkedInUrl: values.linkedInUrl,
                photoUrl: values.photoUrl,
                thumbnailUrl: values.thumbnailUrl,
                participantId: undefined,
            });
        } else {
            const spkDetails = {
                id: editingParticipant.speakerDetails?.id,
                name: values.name ?? editingParticipant.speakerDetails?.name,
                bio: values.bio ?? editingParticipant.speakerDetails?.bio,
                company: values.company ?? editingParticipant.speakerDetails?.company,
                jobTitle: values.jobTitle ?? editingParticipant.speakerDetails?.jobTitle,
                linkedInUrl: values.linkedInUrl ?? editingParticipant.speakerDetails?.linkedInUrl,
                photoUrl: values.photoUrl ?? editingParticipant.speakerDetails?.photoUrl,
                thumbnailUrl: values.thumbnailUrl ?? editingParticipant.speakerDetails?.thumbnailUrl,
            };

            const newEmail = otherParticipantsEmails.includes(values.email?.toLocaleLowerCase())
                ? editingParticipant.dataWithNullableEmail.email?.toLocaleLowerCase()
                : values.email?.toLocaleLowerCase();
            // if (otherParticipantsEmails.includes(values.email)) {
            //     toast("There's already a person in the event with this email.", {
            //         type: "info",
            //     });
            // }

            participantData = {
                ...removeTypenameKey(editingParticipant),
                dataWithNullableEmail: {
                    ...editingParticipant.dataWithNullableEmail,
                    email: newEmail?.toLocaleLowerCase(),
                },
                speakerDetails: {
                    ...spkDetails,
                    id: spkDetails.id! as string,
                },
            };
        }

        if (participantData) {
            const newPhoto = await uploadAndGetPhotoUrl(values.photoFile, participantData.id);
            const newThumbnail = await uploadAndGetPhotoUrl(values.thumbnailFile, participantData.id, true);
            if (newPhoto && participantData?.speakerDetails !== undefined && newPhoto !== values.photoUrl) {
                participantData.speakerDetails.photoUrl = newPhoto;
            }

            if (newThumbnail && participantData?.speakerDetails !== undefined && newThumbnail !== values.thumbnailUrl) {
                participantData.speakerDetails.thumbnailUrl = newThumbnail;
            }

            designer.state.setDesignerCommitState(true);
            await updateParticipants({
                variables: {
                    sessionId: sessionId,
                    deletedParticipantIds: [],
                    upsertParticipantJSONs: [{...participantData, isApproved: true, submittedPasscode: true}],
                },
                fetchPolicy: "no-cache",
            })
                .then((res) => {
                    if (res.data?.updateParticipantsInSession) {
                        writeQuery("LocalParticipantsDocument", {
                            variables: {
                                id: sessionId,
                            },
                            data: {
                                __typename: "Query",
                                participants: res.data.updateParticipantsInSession.participants,
                            },
                        });

                        if (!editingParticipant) {
                            designer.api.event.update({
                                speakerOrderJson: {
                                    speakers: [
                                        ...(orderArray || []),
                                        ...res.data.updateParticipantsInSession.participants
                                            .filter(
                                                (p) => p.speakerDetails && p.speakerDetails.id && !orderArray?.includes(p.speakerDetails.id)
                                            )
                                            .map((p) => p.speakerDetails?.id),
                                    ].filter((x, i, a) => a.indexOf(x) === i) as string[],
                                },
                            });
                            designer.commit();
                        }
                    }
                })
                .finally(() => {
                    designer.state.setDesignerCommitState(false);
                });
        }
    };

    const eventSpeakerEmailsWithoutEditing = useMemo(
        () =>
            participantToEdit
                ? eventSpeakerEmails.filter(
                      (email) => email?.toLocaleLowerCase() !== participantToEdit.dataWithNullableEmail.email?.toLocaleLowerCase()
                  )
                : eventSpeakerEmails,
        [participantToEdit, eventSpeakerEmails]
    );

    return (
        <Dialog open imperativeRef={dialogRef} classes={{paper: classes.root}} onClose={closeNewSpeaker}>
            <Typography fontWeight="bolder" color="primary" variant="xl" className="mb-8">
                {mode === "add" ? "Add speaker" : "Edit speaker"}
            </Typography>
            <Typography color="nonary" className="mb-16">
                Add the details of your speaker below
            </Typography>

            <Formik
                innerRef={formRef}
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={() => newSpeakerFormSchema.validationSchema({eventSpeakerEmails: eventSpeakerEmailsWithoutEditing})}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    const cleanedValues = Object.keys(values).reduce((acc, key) => {
                        if (typeof values[key] === "string") {
                            acc[key] = collapseWhiteSpace(values[key]).trim();
                        } else {
                            acc[key] = values[key];
                        }
                        return acc;
                    }, {} as FormValuesProps);

                    await handleSubmit(cleanedValues);
                    setSubmitting(false);
                    setNewSpeakerData(null);
                    closeNewSpeaker();
                }}
            >
                {({values, touched, errors, isSubmitting, setFieldValue}) => {
                    const isEditingUser =
                        (mode === "edit" && participantToEdit && participantToEdit !== undefined && isParticipantUser) ||
                        (!!newSpeakerData?.firstName && !!newSpeakerData?.email);
                    const formValues = participantToEdit ? participantInitialValues : values;

                    let isValuesChanged = false;

                    if (mode === "edit" && participantToEdit) {
                        for (const key in participantInitialValues) {
                            if (participantInitialValues[key] !== values[key]) {
                                isValuesChanged = true;
                                break;
                            }
                        }
                    }

                    const clearPhotoUrl = () => {
                        setFieldValue("photoUrl", "");
                        setFieldValue("photoFile", null);
                        setFieldValue("thumbnailUrl", "");
                        setFieldValue("thumbnailFile", null);

                        isValuesChanged = true;
                    };

                    const photoUrl = values.thumbnailFile ? URL.createObjectURL(values.thumbnailFile) : values.thumbnailUrl;

                    return (
                        <Form className={classes.formContainer}>
                            <div className="flex flex11-auto flex-col flex-justify-between">
                                <div className={classes.formInputContainer}>
                                    {newSpeakerFormSchema.fields.slice(0, 5).map((field) => {
                                        const shouldDisable = field.name === "email" && isEditingUser;

                                        return (
                                            <FormikFieldWrapper
                                                key={`new-speaker-form-field-${field.name}`}
                                                name={field.name}
                                                as={Input}
                                                value={formValues[field.name]}
                                                data-id={`new-speaker-input-${field.name}`}
                                                inputProps={{
                                                    className: classes.formInput,
                                                    "data-private": "lipsum",
                                                }}
                                                labelClassName={classes.formInputLabel}
                                                label={
                                                    <>
                                                        {field.title} {field.required ? <span className={classes.required}>*</span> : null}
                                                    </>
                                                }
                                                maxCharCount={60}
                                                disabled={shouldDisable}
                                                error={!!errors[field.name] && touched[field.name]}
                                                helperText={errors[field.name]}
                                                placeholder={field.placeholder}
                                                formControlClassName={cls(
                                                    classes.fieldWrapper,
                                                    ["company", "jobTitle"].includes(field.name) ? classes.fieldWrapperHalf : ""
                                                )}
                                            />
                                        );
                                    })}

                                    <div className="mt-16">
                                        <label className={classes.formInputLabel}>Photo</label>
                                        <div className="flex flex-items-center mt-6">
                                            {photoUrl ? (
                                                <img className={classes.photoImage} src={photoUrl} alt="speaker-photo" />
                                            ) : (
                                                <div className={classes.photoImage}>
                                                    <PermIdentityRoundedIcon />
                                                </div>
                                            )}
                                            {photoUrl ? (
                                                <div className="flex flex-items-center">
                                                    <Button
                                                        variant="plain"
                                                        size="smallest"
                                                        htmlFor="photoFileUpload"
                                                        component="label"
                                                        className={cls(classes.photoButton, classes.photoButtonBlue)}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        variant="plain"
                                                        size="smallest"
                                                        component="label"
                                                        className={classes.photoButton}
                                                        onClick={clearPhotoUrl}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Button
                                                    variant="tertiary"
                                                    size="smallest"
                                                    htmlFor="photoFileUpload"
                                                    component="label"
                                                    className={classes.photoButton}
                                                >
                                                    Upload photo
                                                </Button>
                                            )}
                                        </div>
                                    </div>

                                    <input
                                        id="photoFileUpload"
                                        name="photoFile"
                                        type="file"
                                        accept="image/*"
                                        className={classes.uploadInput}
                                        onChange={(event) => {
                                            onPhotoChange(event, setFieldValue);
                                        }}
                                        data-private="lipsum"
                                    />

                                    <FormikFieldWrapper
                                        key={`new-speaker-form-field-bio`}
                                        name={"bio"}
                                        as={Input}
                                        value={formValues["bio"]}
                                        inputProps={{
                                            className: classes.formInput,
                                            "data-private": "lipsum",
                                        }}
                                        labelClassName={classes.formInputLabel}
                                        label={fields[bioFieldIndex].title}
                                        required={fields[bioFieldIndex].required}
                                        maxCharCount={500}
                                        multiline={true}
                                        rows={5}
                                        showTypedCharCount={true}
                                        error={!!errors[fields[bioFieldIndex].name] && touched[fields[bioFieldIndex].name]}
                                        helperText={errors[fields[bioFieldIndex].name]}
                                        placeholder={fields[bioFieldIndex].placeholder}
                                        formControlClassName={classes.fieldWrapper}
                                    />
                                </div>

                                <div className={"fullw flex flex-row-reverse mt-32"}>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting || (mode === "edit" && !isValuesChanged)}
                                        loading={isSubmitting}
                                        className={cls("ml-10 p-12", common.actionButton)}
                                        data-id="confirm-speaker-button"
                                    >
                                        {mode === "add" ? "Create speaker" : "Save changes"}
                                    </Button>
                                    <Button
                                        onClick={handleClose}
                                        variant="plain"
                                        className={cls(common.secondaryButton, classes.cancelButton)}
                                        data-id="cancel-speaker-button"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default NewSpeakerDialog;
