import {useClientEvent} from "@api/events/client";
import {OrganizationPermissionType} from "@generated/data";
import {ReactNode, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import UpgradePlanDialog from "@workhorse/components/subscriptions/UpgradePlanDialog/UpgradePlanDialog";
import {useUserInfo} from "./User";
import {useTranslation} from "react-i18next";
import UpgradeNotification from "@workhorse/components/subscriptions/UpgradeNotification/UpgradeNotification";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";

const UpgradePlanDialogProvider = ({children}: {children: ReactNode}) => {
    const {t} = useTranslation();
    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const user = useUserInfo();
    const isOrganizationOwner = user.activeOrganizationPermission.permissionType === OrganizationPermissionType.Owner;

    const onShowUpgradeDialog = () => {
        setShowUpgradeDialog(true);
    };

    const onShowNotification = () => {
        setShowNotification(true);
    };

    const toastForMember = (message: string) => {
        toast(message, {
            type: "error",
        });
    };

    const handleCloseDialog = () => {
        setShowUpgradeDialog(false);
        setShowNotification(false);
    };

    const messageRef = useRef("");

    const featureRef = useRef<LimitedFeature | null>(null);

    useClientEvent("feature-limit-error", ({feature, message, display}) => {
        const translatedMessage = !Array.isArray(message)
            ? (message.key ? t(message.key, message.options) : t(message)).toString()
            : t(message[0].key, message[0].options).toString();

        featureRef.current = feature;
        // Don't overwrite the upgrade dialog message with a notification
        if (!(showUpgradeDialog && !(display === "notification"))) {
            messageRef.current = message;
        }

        if (isOrganizationOwner) {
            if (display === "notification" && !showUpgradeDialog) {
                onShowNotification();
            } else {
                onShowUpgradeDialog();
            }
        } else {
            toastForMember(translatedMessage);
        }
    });

    return (
        <>
            {showNotification && !showUpgradeDialog ? (
                <UpgradeNotification
                    onClose={() => setShowNotification(false)}
                    title={Array.isArray(messageRef.current) ? messageRef.current[0] : messageRef.current}
                    subtitle={Array.isArray(messageRef.current) ? messageRef.current[1] : null}
                />
            ) : null}

            {showUpgradeDialog ? (
                <UpgradePlanDialog
                    onClose={handleCloseDialog}
                    title={messageRef.current ?? "To use this feature please upgrade your plan"}
                    limitedFeature={featureRef.current}
                ></UpgradePlanDialog>
            ) : null}

            {children}
        </>
    );
};

export default UpgradePlanDialogProvider;
