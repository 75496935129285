import loadable from "@workhorse/api/loadable";
import {ReactComponent as PermissionsIcon} from "../assets/session-permissions-icon.svg";
import {SessionSettingsSection} from "../../definitions";

const SessionSection = loadable(() => import("./SessionSection"));
SessionSection.displayName = "Loadable:SessionSection";

const SessionSectionDefinition: SessionSettingsSection<"general.session"> = {
    translateKey: "general.session",
    kind: "general.session",
    title: "Access",
    component: SessionSection,
    icon: PermissionsIcon,
    rbac: {
        "session.isAssistant": true,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default SessionSectionDefinition;
