import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import {useGlobalVideoState, useGlobalVideoStateSetter} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";

function PauseVideosOn() {
    const {t} = useTranslation();

    return (
        <>
            <PlayIcon />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.camera.play_all_incoming_streams") ?? "Pause video strams"}
            </Typography>
        </>
    );
}

function PauseVideosOff() {
    const {t} = useTranslation();

    return (
        <>
            <PauseIcon />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.camera.pause_all_incoming_streams") ?? "Resume video streams"}
            </Typography>
        </>
    );
}

const GlobalVideoToggler = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const pauseAllIncomingVideos = useGlobalVideoState();
    const toggleIncomingVideos = useGlobalVideoStateSetter();

    const handleOnToggle = () => {
        toggleIncomingVideos(!pauseAllIncomingVideos);
    };

    return (
        <MenuItem ref={ref} onClick={handleOnToggle}>
            {pauseAllIncomingVideos && <PauseVideosOn />}
            {!pauseAllIncomingVideos && <PauseVideosOff />}
        </MenuItem>
    );
});

export default GlobalVideoToggler;
