import VideoOnIcon from "@material-ui/icons/Videocam";
import VideoOffIcon from "@material-ui/icons/VideocamOff";
import PauseIcon from "@material-ui/icons/Pause";
import style from "./styles/AudienceParticipant.module.scss";
import {cls} from "@ui/cdk/util";
import Tooltip from "@ui/cdk/Tooltip";

export default function ConferenceVideoIcon(props: {isOff: boolean; isThumbnailMode?: boolean; isPaused?: boolean; pausedTxt: string}) {
    return (
        <div
            className={cls(
                style.statusIconContainer,
                props.isOff && !props.isPaused && style.statusOff,
                props.isThumbnailMode && style.isThumbnail
            )}
        >
            {props.isOff && !props.isPaused ? (
                <VideoOffIcon data-id="video-off" />
            ) : props.isPaused ? (
                <Tooltip title={props.pausedTxt}>
                    <PauseIcon data-id="video-paused" />
                </Tooltip>
            ) : (
                <VideoOnIcon data-id="video-on" />
            )}
        </div>
    );
}
