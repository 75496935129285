import {MemoryAccessType} from "@generated/data";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import Select from "@ui/cdk/Select";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as MemoryAccess} from "../../assets/memory-access.svg";
import {useTranslation} from "react-i18next";

type MemoryAccessOptionProps = {
    value: MemoryAccessType;
    onChange: (value: MemoryAccessType) => void;
    className?: string;
    showIcon?: boolean;
    disabled?: boolean;
};

const MemoryAccessOption = (props: MemoryAccessOptionProps) => {
    const {value, onChange, className, showIcon = false, disabled} = props;

    const {t} = useTranslation();

    const options = [
        {
            value: MemoryAccessType.Private,
            text: t("g.session.values_attended.moderators") ?? "Moderators",
        },
        {
            value: MemoryAccessType.JustAttendees,
            text: t("g.session.values_attended.just_attendees") ?? "Attendees",
        },
        {
            value: MemoryAccessType.Public,
            text: t("g.session.values_attended.public") ?? "Anyone with the link",
        },
    ];

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const memoryAccessType = event.target.value as MemoryAccessType;
        onChange(memoryAccessType);
    };

    return (
        <div className={cls(genericStyles.togglerWrapper, className)}>
            {showIcon ? (
                <div className={genericStyles.iconWrapper}>
                    <MemoryAccess />
                </div>
            ) : null}
            <div className="flex flex-justify-between flex-align-center fullw">
                <div className={genericStyles.textWrapper}>
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("player.settings.general.access.memory_access.title") ?? "Memory access"}
                    </Typography>
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.access.memory_access.description") ??
                            "Allow participants access to the recording, resources, and analytics"}
                    </Typography>
                </div>
                <Select
                    data-id="memory-access-settings"
                    onChange={handleOnChange}
                    value={value}
                    options={options}
                    IconComponent={ArrowDropDownRoundedIcon}
                    disabled={disabled}
                    className={genericStyles.selectInput}
                    MenuProps={{
                        classes: {
                            paper: genericStyles.selectMenuPaper,
                        },
                    }}
                    wrapperClassName={genericStyles.selectFormControl}
                />
            </div>
        </div>
    );
};

export default MemoryAccessOption;
