import {MemberFragment, SessionParticipantFragment} from "@generated/data";
import TableBody from "@ui/cdk/Table/TableBody";
import TableCell from "@ui/cdk/Table/TableCell";
import {TableCellSize} from "@ui/cdk/Table/TableCell/types";
import TableHead from "@ui/cdk/Table/TableHead";
import TableHeadSortButton from "@ui/cdk/Table/TableHeadSortButton";
import TableRow from "@ui/cdk/Table/TableRow";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import {MutableRefObject, useMemo, useRef, useState} from "@workhorse/api/rendering";
import WorkspaceMemberSelector from "@workhorse/pages/designer/CreateSession/WorkspaceMemberSelector";
import {ParticipantWithSpeakerDetailsPayload} from "../event/event-speakers/utils";
import EventCollaboratorsTableRow from "./event-collaborators-table-row";
import classes from "./event-collaborators-table.module.scss";
import WorkspaceMembersListEmpty from "@workhorse/pages/designer/CreateSession/WorkspaceMembersListEmpty";
import {useParticipants} from "@workhorse/providers/SessionDataProviders";

type EventCollaboratorsTableProps = {
    collaborators: SessionParticipantFragment[];
    onAddMember: (member: MemberFragment) => void;
    onRemoveCollaborator: (id: string) => void;
    organizationName: string | undefined | null;
};

function EventCollaboratorsTable(props: EventCollaboratorsTableProps) {
    const {collaborators, onAddMember, onRemoveCollaborator, organizationName} = props;

    const participants = useParticipants();
    const owner = participants
        .filter((p) => p.isOwner)
        ?.map((p) => p.dataWithNullableEmail.email)
        .filter((p) => p) as string[] | undefined;

    const collaboratorEmails = useMemo(() => collaborators.map((col) => col.dataWithNullableEmail.email ?? ""), [collaborators]);

    return (
        <>
            <div className="mx-24 mb-12">
                <WorkspaceMemberSelector filteredMemberEmails={collaboratorEmails.concat(owner ?? [])} onAddMember={onAddMember} />
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table className={"flex flex-col overflow-hidden"}>
                    <TableBody className={classes.tableBody}>
                        {collaborators.length > 0 ? (
                            collaborators.map((collaborator) => (
                                <EventCollaboratorsTableRow
                                    key={collaborator.id}
                                    collaborator={collaborator}
                                    onRemove={onRemoveCollaborator}
                                />
                            ))
                        ) : (
                            <WorkspaceMembersListEmpty
                                organizationName={organizationName}
                                collaboratorEmails={collaboratorEmails}
                                title="Add some collaborators to your event"
                                subtitle="You can add your workspace members as collaborators"
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default EventCollaboratorsTable;
