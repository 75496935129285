import Button from "@ui/cdk/Button";
import {useCallback, useEffect} from "@workhorse/api/rendering";
import {ReactComponent as AgendaToggleIcon} from "../../../assets/media/agendaToggleIcon.svg";

import {togglePalette} from "@ui/cdk/util/util";
import {paletteActiveCategDefault, usePaletteActiveCateg} from "@workhorse/components/command-palette/CommandPaletteProviders";
import {ActionCategory, categMap} from "@workhorse/components/command-palette/actionCategTree";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {isMacOS} from "@workhorse/util";

import classes from "./styles/LobbyFooterAddAgendaButton.module.scss";
import {useTranslation} from "react-i18next";

const LobbyFooterAddAgendaButton = () => {
    const {t} = useTranslation();
    const {setPaletteActiveCateg} = usePaletteActiveCateg();

    const mobileState = useMobile();
    const {isMobileOrTablet} = mobileState;
    const isMac = isMacOS();

    const agendaCategItem = categMap.find((obj) => obj.categ === ActionCategory.Agenda);

    const toggleAgendaPalette = useCallback(() => {
        togglePalette();
        setPaletteActiveCateg(
            agendaCategItem
                ? {
                      id: agendaCategItem.id,
                      name: agendaCategItem.categ,
                      parentId: agendaCategItem.parentId,
                      searchStr: undefined,
                      isForced: false,
                  }
                : paletteActiveCategDefault
        );
    }, []);

    useEffect(() => {
        return () => {
            setPaletteActiveCateg(paletteActiveCategDefault);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.altKey && event.key === "a") {
                toggleAgendaPalette();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Button
            noFocusBorder
            className={classes.buttonAnimation}
            variant="tertiary"
            startIcon={<AgendaToggleIcon />}
            onClick={toggleAgendaPalette}
        >
            {t("palette.agendas.add_agenda")}
            {!isMobileOrTablet && (
                <div className="ml-12 flex flex-align-center mt-2">
                    <div className={classes.keyBind}>{isMac ? "⌘" : "Ctrl"}</div>
                    <div className={classes.keyBind}>{isMac ? "⌥" : "Alt"}</div>
                    <div className={classes.keyBind}>A</div>
                </div>
            )}
        </Button>
    );
};

export default LobbyFooterAddAgendaButton;
