import {AgendaTemplateDocument, AgendaTemplateFragment, GenerateTemplateNameDocument, GetAgendaTemplatesDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {TEMPLATE_FOLDER_NAME_FOR_USERS, SHARED_TEMPLATE_TAG_NAME, SHARED_WORKSPACE_TAG_NAME} from "@sessions/common/agenda-templates";
import {AgendaTemplateSort} from "./AgendaTemplatesList/AgendaTemplatesTable/AgendaTemplatesTable";
import {readRemoteUser} from "@workhorse/api/user";

export const generateTemplateName = (nameToCheckFor: string, iteration?: number) => {
    const currentTemplate = designer.currentSession();

    // TODO: maca - multiple workspaces
    const existingNames = [];

    if (!iteration) {
        const nameAlreadyUsed = existingNames.findIndex((s) => s === nameToCheckFor) !== -1;
        if (!nameAlreadyUsed) {
            return nameToCheckFor;
        }

        iteration = 1;
    }

    const nameAlreadyUsed = existingNames.findIndex((s) => s === nameToCheckFor + " #" + iteration) !== -1;

    if (nameAlreadyUsed) {
        return generateTemplateName(nameToCheckFor, iteration + 1);
    }
    return nameToCheckFor + " #" + iteration;
};

export const generateAgendaItemName = (nameToCheckFor: string, iteration?: number) => {
    const agendaItems = designer.currentAgendaItems();

    const existingNames = agendaItems.map((a) => a.title) ?? [];

    if (!iteration) {
        const nameAlreadyUsed = existingNames.findIndex((s) => s === nameToCheckFor) !== -1;
        if (!nameAlreadyUsed) {
            return nameToCheckFor;
        }

        iteration = 1;
    }

    const nameAlreadyUsed = existingNames.findIndex((s) => s === nameToCheckFor + " #" + iteration) !== -1;

    if (nameAlreadyUsed) {
        return generateAgendaItemName(nameToCheckFor, iteration + 1);
    }
    return nameToCheckFor + " #" + iteration;
};

export const generateTemplateNameQuery = async (name: string) => {
    return await apollo.client.query({
        query: GenerateTemplateNameDocument,
        fetchPolicy: "network-only",
        variables: {
            name,
        },
    });
};

export const updateTemplateNameInList = (name: string, workspaceId: string) => {
    const designerSessionId = designer.currentSession()?.id;
    if (!designerSessionId) {
        console.error("You are editing a template that is not loaded into designer api. This shouldn't happen");

        return;
    }
    const id = designerSessionId.includes("_template") ? designerSessionId.replace("_template", "") : designerSessionId;

    const existing = apollo.cache.readQuery({
        query: GetAgendaTemplatesDocument,
        variables: {
            workspaceId: workspaceId,
        },
    });

    const agendas = existing?.agendaTemplates?.map((a) => {
        if (a?.id !== id) {
            return a;
        }

        return {
            ...a,
            name,
        };
    });

    apollo.client.writeQuery({
        query: GetAgendaTemplatesDocument,
        variables: {
            workspaceId: workspaceId,
        },
        data: {
            ...existing,
            agendaTemplates: agendas,
        },
    });
};

export const getTemplate = async (id?: string, noCache?: boolean) => {
    const templateFromCache = await apollo.client.query({
        query: AgendaTemplateDocument,
        variables: {
            where: {
                id,
            },
        },
        fetchPolicy: "cache-only",
    });

    const userData = readRemoteUser();

    if (!noCache && templateFromCache?.data.agendaTemplate) {
        const isPublic = templateFromCache.data?.agendaTemplate?.isPublic;

        return templateFromCache.data
            ? ({
                  ...templateFromCache,
                  data: {
                      ...templateFromCache.data,
                      agendaTemplate: {
                          ...templateFromCache.data.agendaTemplate,
                          isPublic,
                      },
                  },
              } as typeof templateFromCache)
            : templateFromCache;
    } else {
        const t = await apollo.client.query({
            query: AgendaTemplateDocument,
            variables: {
                where: {
                    id,
                },
            },
            fetchPolicy: noCache ? "network-only" : "cache-first",
        });
        const isPublic = t.data.agendaTemplate?.isPublic;
        return t.data
            ? ({
                  ...t,
                  data: {
                      ...t.data,
                      agendaTemplate: {
                          ...t.data.agendaTemplate,
                          isPublic,
                      },
                  },
              } as typeof t)
            : t;
    }
};

export function isVisibleOnScreen(domElement) {
    return new Promise((resolve) => {
        const o = new IntersectionObserver(([entry]) => {
            resolve(entry.intersectionRatio === 1);
            o.disconnect();
        });
        o.observe(domElement);
    });
}

export type TemplatesSectionPaths = "my-agendas" | "shared" | "workspace";

type TemplatesSectionsOptions = {
    title: string;
    path: TemplatesSectionPaths;
}[];

export const templatesSections: TemplatesSectionsOptions = [
    {
        title: TEMPLATE_FOLDER_NAME_FOR_USERS,
        path: "my-agendas",
    },
    {
        title: SHARED_WORKSPACE_TAG_NAME,
        path: "workspace",
    },
    {
        title: SHARED_TEMPLATE_TAG_NAME,
        path: "shared",
    },
];

export type TemplatesParams = {
    section: TemplatesSectionPaths;
    templateId?: string;
};

export function createTemplatesComparator(sort: AgendaTemplateSort) {
    return (a: AgendaTemplateFragment, b: AgendaTemplateFragment) => {
        let result = 1;

        if (sort.field === "name") {
            result = a.name.localeCompare(b.name);
        }
        if (sort.field === "duration") {
            result = a.duration - b.duration;
        }
        if (sort.field === "updatedAt") {
            result = new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        }
        if (sort.field === "owner") {
            result = a.user.firstName.localeCompare(b.user.firstName);
        }

        const direction = sort.direction === "asc" ? 1 : -1;

        return result * direction;
    };
}

export const getTemplateSectionByRoute = (params: TemplatesParams) => {
    return templatesSections.find((s) => s.path === params.section);
};
