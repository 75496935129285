import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {MacroArtifact} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";

export interface UpdateMacroArtifactInput {
    id: string;
    artifact: Partial<DeepMandatoryWithNullVals<MacroArtifact>>;
}

export class UpdateMacroArtifact extends DesignerAction<UpdateMacroArtifactInput> {
    commit() {
        const macroArtifacts = designer.currentMacroArtifacts()?.slice(0);
        const index = macroArtifacts.findIndex((a) => a.id === this.input.id);
        if (index !== -1) {
            macroArtifacts[index!] = {
                ...macroArtifacts[index!],
                ...this.input.artifact,
            };

            designer.updateCurrentSession(
                {
                    macroArtifacts,
                },
                {
                    strategy: "replace",
                }
            );
        }

        return this.input.id;
    }
}
