import {useEffect, useRef} from "@workhorse/api/rendering";
import {useUserInfo} from "@workhorse/providers/User";
import {useDevices, useForceMuteStatus} from "../../providers";
import {useOrderedAsyncReplaceableTask} from "../useAsyncReplaceableTask";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useLocalAudioPublisher} from "../../providers/ConferenceRoomProvider/ConferenceRoomLocalAudioProvider";
import {RoomDataType, useConferenceRoom, useRoomSendData, useRoomSetVolume} from "../../providers/ConferenceRoomProvider";
import {DataPacket_Kind} from "livekit-client";
import {throttle} from "throttle-debounce";
import toast from "@workhorse/api/toast";
import Button from "@ui/cdk/Button";
import {useIsMounted} from "@workhorse/api/isMounted";

export function AudioInputStreamController({disableBroadcast}: {disableBroadcast?: boolean}) {
    const {audioStream, audioMuted, noiseSuppressedStream, deviceManager, setAudioEnabled, setAudioMuted} = useDevices();
    const {startPublishingAudio, stopPublishingAudio, setMicrophoneEnabled} = useLocalAudioPublisher();

    const {isRecorder} = useUserInfo();
    const {transcriptionActive} = useSession();

    const isMounted = useIsMounted();

    const {room, roomStatus} = useConferenceRoom();
    const isConnected = roomStatus.connected;

    const sendData = useRoomSendData();
    const setVolume = useRoomSetVolume();

    const {muteStatus} = useForceMuteStatus();
    const forceMute = muteStatus === "hard" || isRecorder || disableBroadcast;
    const device = noiseSuppressedStream ?? audioStream;

    const audioMuteEnqueue = useOrderedAsyncReplaceableTask();

    const handleError = () => {
        if (!isMounted()) {
            return;
        }

        setAudioEnabled(false);
        toast(
            <div className="flex flex-row">
                <p style={{width: 240}}>Something went wrong with your audio stream.</p>
                <Button
                    className="ml-8 mt-auto mb-auto"
                    variant="plain"
                    size="smallest"
                    onClick={() => {
                        setAudioEnabled(true);
                        setAudioMuted(false);
                        toast("", {
                            remove: true,
                            uid: "toast-audio-publish-error",
                        });
                    }}
                >
                    Try Again
                </Button>
            </div>,
            {type: "error", permanent: true, uid: "toast-audio-publish-error"}
        );
    };

    // The localization will trigger a rerender, but we don't want to rerun the effect.
    const handleErrorRef = useRef(handleError);
    handleErrorRef.current = handleError;

    // Toggle local mute
    useEffect(() => {
        if (device == null || forceMute || disableBroadcast) {
            audioMuteEnqueue(() => setMicrophoneEnabled(false));
            return;
        }
        if (audioMuted) {
            audioMuteEnqueue(() => setMicrophoneEnabled(false));
        } else {
            audioMuteEnqueue(() => setMicrophoneEnabled(true));
        }
    }, [device, forceMute, audioMuted, setMicrophoneEnabled, disableBroadcast]);

    // Toggle transcription
    useEffect(() => {
        if (device == null || forceMute || !transcriptionActive || disableBroadcast) {
            deviceManager.audio.muteTranscriber();
            return;
        }

        if (audioMuted) {
            deviceManager.audio.muteTranscriber();
        } else {
            deviceManager.audio.unmuteTranscriber();
        }
    }, [device, forceMute, audioMuted, transcriptionActive, deviceManager, disableBroadcast]);

    const audioEnqueue = useOrderedAsyncReplaceableTask();

    // Attach stream to livekit
    useEffect(() => {
        if (!device || disableBroadcast) {
            audioEnqueue(() => stopPublishingAudio());
            return;
        }

        audioEnqueue(async () => {
            const err = await startPublishingAudio(device);
            if (err) {
                handleErrorRef.current();
            }
        });
    }, [device, deviceManager, audioEnqueue, startPublishingAudio, stopPublishingAudio, setAudioEnabled, disableBroadcast]);

    // Send volume to other participants
    useEffect(() => {
        if (forceMute || audioMuted) {
            return;
        }

        const handleVolume = throttle(70, (volume: number) => {
            const localParticipant = room?.localParticipant.identity;

            if (localParticipant) {
                setVolume(localParticipant, volume);
            }

            if (volume === 0 || !isConnected) {
                return;
            }

            sendData(
                {
                    type: RoomDataType.Volume,
                    volume,
                },
                DataPacket_Kind.LOSSY
            );
        });

        deviceManager.events.on("audio-volume", handleVolume);

        return () => {
            deviceManager.events.off("audio-volume", handleVolume);
        };
    }, [deviceManager, room, forceMute, audioMuted, isConnected, sendData, setVolume]);

    return null as unknown as JSX.Element;
}
