import {AudioAnimationProps} from "./AnimatedMicrophoneIcon";
import {cls} from "@ui/cdk/util";
import style from "./styles/AudienceParticipant.module.scss";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {useDevices} from "@workhorse/api/conference2";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import ConnectionQualityIcon from "./ConnectionQualityIcon";
import LocalVideoIcon from "./LocalVideoIcon";
import AnimatedMicrophoneIcon from "./AnimatedMicrophoneIcon";
import LocalUploadInfo from "./LocalUploadInfo";

type ParticipantIconsProps = {
    className?: string;
    participantId: string;
    isThumbnailMode?: boolean;
};

export default function LocalParticipantIcons(props: ParticipantIconsProps & AudioAnimationProps) {
    const status = useRemoteParticipantStatus(props.participantId);
    const sharingContent = false;

    const {hasAudioInput, hasVideoInput, videoStream, audioEnabled, audioMuted} = useDevices();

    const onMicClick = () => {
        if (!hasAudioInput) {
            return;
        }

        const toggler = document.getElementsByClassName("local-audio-toggler")[0];

        if (!toggler) {
            return;
        }

        toggler.dispatchEvent(new MouseEvent("click", {bubbles: true}));
    };

    const onVideoClick = () => {
        if (!hasVideoInput) {
            return;
        }

        const toggler = document.getElementsByClassName("local-video-toggler")[0];

        if (!toggler) {
            return;
        }

        toggler.dispatchEvent(new MouseEvent("click", {bubbles: true}));
    };

    return (
        <>
            <span className={cls(style.participantIcons, props.className)}>
                {sharingContent && (
                    <div className={cls(style.statusIconContainer, props.isThumbnailMode ? style.isThumbnail : "")}>
                        <ScreenShareIcon data-id="share-screen-icon" className="participant-icon" />
                    </div>
                )}

                {/* <LocalUploadInfo isThumbnailMode={props.isThumbnailMode} /> */}

                <ConnectionQualityIcon
                    isLocal={true}
                    connectionQuality={status.connectionQuality}
                    isThumbnailMode={props.isThumbnailMode}
                />

                <LocalVideoIcon
                    className={hasVideoInput ? "cursor-pointer" : undefined}
                    isThumbnailMode={props.isThumbnailMode}
                    onClick={onVideoClick}
                    participantId={props.participantId}
                />

                <AnimatedMicrophoneIcon
                    className={hasAudioInput ? "cursor-pointer" : undefined}
                    isMuted={!audioEnabled || audioMuted}
                    isThumbnailMode={props.isThumbnailMode}
                    onClick={onMicClick}
                    participantId={props.participantId}
                />
            </span>
        </>
    );
}
