import {ConnectionState, Room} from "livekit-client";

export async function waitForConnected(room: Room) {
    if (!room) {
        return;
    }

    if (room.state === ConnectionState.Connected) {
        return;
    }

    return new Promise<void>((resolve) => {
        const done = () => {
            clearInterval(intervalId);
            room.off("connected", done);
            resolve();
        };

        // safeguard in case the event doesn't fire, we encountered some event emitter issues in livekit
        const intervalId = setInterval(() => {
            if (room.state === ConnectionState.Connected) {
                done();
            }
        }, 100);

        room.on("connected", done);
    });
}
