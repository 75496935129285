import {FieldPolicy} from "@workhorse/api/data";

export const persistedField = <TFieldType>(
    key: string,
    defaultValue: TFieldType,
    validation?: (value: TFieldType) => boolean
): FieldPolicy<TFieldType, TFieldType, TFieldType> => {
    const lsValue = localStorage.getItem(key);
    let value: TFieldType;

    if (lsValue !== null) {
        value = JSON.parse(lsValue);

        if (validation && !validation(value)) {
            value = defaultValue;
            localStorage.setItem(key, JSON.stringify(value));
        }
    } else {
        value = defaultValue;
        localStorage.setItem(key, JSON.stringify(value));
    }

    return {
        read() {
            return value;
        },
        merge(_, incoming) {
            if (value !== incoming) {
                localStorage.setItem(key, JSON.stringify(incoming));
                value = incoming;
            }

            return incoming;
        },
    };
};
