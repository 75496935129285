import {cls} from "@ui/cdk/util";
import {SessionData} from "@workhorse/declarations/dataTypes";
import PreJoinInfoForm, {FormikValuesType} from "./PreJoinInfoForm";
import PreJoinInfoPermission from "./PreJoinInfoPermission";
import classes from "./styles/PreJoinInfoContainer.module.scss";
import {useUserInfo} from "@workhorse/providers/User";
import PreJoinInfoSession from "./PreJoinInfoSession";
import {useReactiveVar} from "@apollo/client";
import {sessionBackground} from "@workhorse/pages/user/profile/Theming/utils";
import {useMemo} from "@workhorse/api/rendering";
import {useSeenSection} from "@workhorse/api/seen";
import {SessionRecordingType} from "@sessions/common/generated/data-types";

type PreJoinInfoContainerProps = {
    session: SessionData;

    isMobile?: boolean;
    isPortrait?: boolean;
    showNetworkIssueMsg?: boolean;

    handleRequestPermission: (values: FormikValuesType) => void;
    handleProvidePasscode: (values: FormikValuesType) => void;
    handleUpdateGuestInfo: (values: FormikValuesType) => void;

    onSubmit: (values: FormikValuesType) => void;

    isSubmitting: boolean;
    isGuest: boolean;
    isOwner: boolean;
    isAssistant: boolean;
    tooManyAttempts: boolean;
    isRequestPermissionToJoin: boolean;
    isProvideSessionPasscode: boolean;
    provideSessionPasscode: boolean;
    passcodeToJoin?: string;
    requiresConsentToRecord: boolean;
    recordingActive: boolean;
    requestState: "denied" | "requesting" | "none";
    isProvidePasscodeFailed: boolean;
    setIsProvidePasscodeFailed: (value: boolean) => void;
    recordingType?: SessionRecordingType;
};

type BreakoutGuestDetails = {
    parentSessionId: string;
    name?: string;
    email?: string;
} | null;

const PreJoinInfoContainer = (props: PreJoinInfoContainerProps) => {
    const {
        session,
        isMobile,
        isPortrait,
        handleRequestPermission,
        handleProvidePasscode,
        handleUpdateGuestInfo,
        isSubmitting,
        isGuest,
        isOwner,
        isAssistant,
        tooManyAttempts,
        isRequestPermissionToJoin,
        isProvideSessionPasscode,
        provideSessionPasscode,
        requiresConsentToRecord,
        requestState,
        onSubmit,
        passcodeToJoin,
        isProvidePasscodeFailed,
        setIsProvidePasscodeFailed,
        recordingType,
    } = props;

    const user = useUserInfo();

    const parentSessionId = session?.childOfBreakoutRooms?.parentOfBreakoutRooms.sessionId;
    const isBreakoutChild = !!session.childOfBreakoutRooms;
    const breakoutGuestDetails = useMemo(() => {
        if (isBreakoutChild) {
            try {
                const breakoutGuestDetailsStr = localStorage.getItem("breakoutGuestDetails");
                if (breakoutGuestDetailsStr) {
                    const guestDetails = JSON.parse(breakoutGuestDetailsStr) as BreakoutGuestDetails;
                    if (guestDetails?.parentSessionId === parentSessionId) {
                        return guestDetails;
                    }
                }
            } catch (e) {
                console.error(`something went wrong decoding guest details for breakout room`);
            }
        }
        return null;
    }, [isBreakoutChild, parentSessionId]);

    const savedName =
        isBreakoutChild && isGuest
            ? breakoutGuestDetails?.name ?? localStorage.getItem("guestUsername")
            : localStorage.getItem("guestUsername");
    const displayName = isGuest && savedName ? savedName.trim() : `${user.firstName} ${user.lastName}`.trim();

    const background = useReactiveVar(sessionBackground);

    const trimmedEmail = isBreakoutChild && isGuest ? breakoutGuestDetails?.email ?? user.email.trim() : user.email.trim();

    const isRoom = session?.room?.active;
    const isEvent = session?.event;
    const isBooking = session?.isBooking;
    const isOnboarding = useSeenSection("GLOBALS.getStartedWidget");

    const isOwnerOnboarding = isOwner && !isOnboarding && !isBreakoutChild && !isRoom && !isEvent && !isBooking;

    return (
        <div className={cls("flex flex-center-all", classes.wrapper)}>
            {isRequestPermissionToJoin && (requestState !== "none" || tooManyAttempts) ? (
                <div className={cls("fullw", classes.root, background ? classes.withBackground : classes.noBackground)}>
                    <PreJoinInfoPermission
                        onRequestPermission={handleRequestPermission}
                        tooManyAttempts={tooManyAttempts}
                        isPermissionDenied={requestState === "denied" || tooManyAttempts}
                        name={displayName}
                        email={trimmedEmail}
                        requestPermissionToJoin={isRequestPermissionToJoin}
                        isSubmitting={isSubmitting}
                        isGuest={isGuest}
                        askForGuestEmail={isGuest && session.askForGuestEmail}
                        isProvideSessionPasscode={isProvideSessionPasscode}
                        provideSessionPasscode={provideSessionPasscode}
                        passcodeToJoin={passcodeToJoin}
                        requiresConsentToRecord={requiresConsentToRecord}
                        transcriptionActive={session.transcriptionActive}
                        recordingActive={props.recordingActive}
                        isOwner={isOwner}
                        isAssistant={isAssistant}
                        isProvidePasscodeFailed={isProvidePasscodeFailed}
                        setIsProvidePasscodeFailed={setIsProvidePasscodeFailed}
                    />
                </div>
            ) : (
                <div className={cls("flex flex-col fullw", background ? classes.withBackground : classes.noBackground)}>
                    {!isMobile && <PreJoinInfoSession session={session} isOwnerOnboarding={isOwnerOnboarding} />}
                    <PreJoinInfoForm
                        name={displayName}
                        email={session.askForGuestEmail && trimmedEmail.endsWith("@flowos.com") ? "" : trimmedEmail}
                        requestPermissionToJoin={isRequestPermissionToJoin}
                        onSubmit={
                            isProvideSessionPasscode && !isRequestPermissionToJoin
                                ? handleProvidePasscode
                                : isRequestPermissionToJoin
                                ? handleRequestPermission
                                : isGuest
                                ? handleUpdateGuestInfo
                                : onSubmit
                        }
                        isSubmitting={isSubmitting}
                        isBorChild={isBreakoutChild}
                        isGuest={isGuest}
                        askForGuestEmail={isGuest && session.askForGuestEmail}
                        showNetworkIssueMsg={props.showNetworkIssueMsg}
                        isProvideSessionPasscode={isProvideSessionPasscode}
                        provideSessionPasscode={provideSessionPasscode}
                        passcodeToJoin={passcodeToJoin}
                        requiresConsentToRecord={requiresConsentToRecord}
                        transcriptionActive={session.transcriptionActive}
                        recordingActive={props.recordingActive}
                        isOwner={isOwner}
                        isAssistant={isAssistant}
                        isProvidePasscodeFailed={isProvidePasscodeFailed}
                        setIsProvidePasscodeFailed={setIsProvidePasscodeFailed}
                        isOwnerOnboarding={isOwnerOnboarding}
                        recordingType={recordingType}
                    />
                </div>
            )}
        </div>
    );
};

export default PreJoinInfoContainer;
