import {BreakoutProvider} from "@artifacts/breakout/providers/BreakoutInfoProvider";
import RemoteApplicationStateProvider, {RemoteApplicationStateSubscriber} from "@workhorse/api/application/RemoteApplicationStateProvider";
import browserInfo from "@workhorse/api/BrowserInfo";
import loadable from "@workhorse/api/loadable";
import {RecordingProvider} from "@workhorse/api/recording";
import {ResxProvider} from "@workhorse/api/resx/ResxProvider";
import {ResxResultProvider} from "@workhorse/api/resx/ResxResultProvider";
import {Tracking} from "@workhorse/api/tracking";
import {WithChildren} from "@workhorse/declarations";
import {BreakoutConfigProvider} from "@workhorse/pages/player/components/BreakoutMenu/BreakoutConfigProvider";
import {useIsAuthAllowedRoute} from "@workhorse/pages/user/auth/AuthAllComponents";
import Onboarding from "@workhorse/pages/user/onboarding/Onboarding";
import AuthRoutes from "./AuthRoutes";
import {BreakpointProvider} from "./BreakpointProvider";
import BrowserAndMobileChecker from "./BrowserAndMobileChecker";
import {DeviceOrientationProvider} from "./DeviceOrientationProvider";
import DrawersStateManager from "./DrawersStateManager";
import InjectorsProvider from "./InjectorsProvider";
import {KeyboardProvider} from "./KeyboardProvider";
import {MobileProvider} from "./MobileProvider";
import {OnboardingSessionProvider} from "./OnboardingSessionProvider";
import {SessionsUIToggleProvider} from "./SessionsUIToggleProvider";
import ThemeProvider from "./ThemeProvider";
import ToastProvider from "./ToastProvider/ToastProvider";
import {UploadResourceProvider} from "./UploadResourceProvider";
import {UserProvider, WithUser} from "./User";
import {AsyncDialogRendererProvider} from "./AsyncDialog";
import {FeaturesOnboardingProvider} from "@workhorse/api/onboarding/provider";
import {RecordingTimerProvider} from "./RecordingTimerProvider";
import {AiGeneratedAgendaProvider} from "./AiGeneratedAgendaProvider";
import UpgradePlanDialogProvider from "./UpgradePlanDialogProvider";
import PaddleProvider from "./PaddleProvider";
import WorkspaceBrandingProvider from "./WorkspaceBrandingProvider";
import WorkspaceMyProductToolInitializer from "./WorkspaceMyProductToolInitializer";

const OrientationDetector = loadable(() => import("@workhorse/pages/player/OrientationDetector"), {
    fallback: <></>,
});

const isDeviceMobile = browserInfo.isMobile();

function WithAuthRoute(props: WithChildren) {
    const isAuthRoute = useIsAuthAllowedRoute();

    if (isAuthRoute) {
        return <AuthRoutes />;
    }

    return <>{props.children}</>;
}

function CustomProviders(props: WithChildren) {
    return (
        <>
            <BreakpointProvider>
                <MobileProvider>
                    <DeviceOrientationProvider>
                        <KeyboardProvider>
                            <ThemeProvider key="theme-provider">
                                <ToastProvider key="toast-provider" />
                                {isDeviceMobile ? <OrientationDetector key="orientation-detector" /> : null}
                                <RemoteApplicationStateProvider key="remote-app-state-provider">
                                    <RemoteApplicationStateSubscriber key="remo-sub" />
                                    <WithAuthRoute>
                                        <BrowserAndMobileChecker>
                                            <InjectorsProvider>
                                                <DrawersStateManager isMobile={isDeviceMobile} key="drawers-state-manager" />
                                                <UserProvider key="user-provider">
                                                    <WorkspaceBrandingProvider key="workspace-branding-provider" />
                                                    <WorkspaceMyProductToolInitializer key="workspace-my-product-tool-initializer" />
                                                    <PaddleProvider>
                                                        <UpgradePlanDialogProvider>
                                                            <BreakoutProvider>
                                                                <Tracking />
                                                                <WithUser provider={ResxProvider}>
                                                                    <WithUser provider={ResxResultProvider}>
                                                                        <WithUser provider={Onboarding} allowlist={["USER"]}>
                                                                            <AiGeneratedAgendaProvider>
                                                                                <RecordingTimerProvider>
                                                                                    <WithUser provider={RecordingProvider}>
                                                                                        <AsyncDialogRendererProvider>
                                                                                            <UploadResourceProvider>
                                                                                                <BreakoutConfigProvider>
                                                                                                    <SessionsUIToggleProvider>
                                                                                                        <OnboardingSessionProvider>
                                                                                                            <FeaturesOnboardingProvider>
                                                                                                                {props.children}
                                                                                                            </FeaturesOnboardingProvider>
                                                                                                        </OnboardingSessionProvider>
                                                                                                    </SessionsUIToggleProvider>
                                                                                                </BreakoutConfigProvider>
                                                                                            </UploadResourceProvider>
                                                                                        </AsyncDialogRendererProvider>
                                                                                    </WithUser>
                                                                                </RecordingTimerProvider>
                                                                            </AiGeneratedAgendaProvider>
                                                                        </WithUser>
                                                                    </WithUser>
                                                                </WithUser>
                                                            </BreakoutProvider>
                                                        </UpgradePlanDialogProvider>
                                                    </PaddleProvider>
                                                </UserProvider>
                                            </InjectorsProvider>
                                        </BrowserAndMobileChecker>
                                    </WithAuthRoute>
                                </RemoteApplicationStateProvider>
                            </ThemeProvider>
                        </KeyboardProvider>
                    </DeviceOrientationProvider>
                </MobileProvider>
            </BreakpointProvider>
        </>
    );
}

export default CustomProviders;
