import {AuthService} from "@workhorse/api/authService";
import {useEffect} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {RouteHOCProps} from "@workhorse/declarations";

type LoginHOCProps = RouteHOCProps<{ref?: string}>;

const UserLogin = (props: LoginHOCProps) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const loginPopup = params.get("popup");
        if (loginPopup === "true") {
            sessionStorage.setItem("login_method", "popup");
        }

        AuthService.getInstance().login("/user/login-success");
    }, []);

    return <Loading location="UserLogin.tsx" />;
};

export default UserLogin;
