import {useReactiveVar} from "@workhorse/api/data";
import React, {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {Transition} from "react-transition-group";
import {clearAllNotifications, clearAllNotificationsOfType, MicroNotification, microNotification} from "./api";
import {MicroNotificationComponent} from "./MicroNotification";
import classes from "./styles/MicroNotificationCenter.module.scss";

const DEBOUNCE_TIME = 3000;
const TRANSITION_TIME = 100;

type MicroNotificationCenterProps = {
    setHasVisibleChildren?: (hasVisibleChildren: boolean) => void;
};

const MicroNotificationCenter = ({setHasVisibleChildren}: MicroNotificationCenterProps) => {
    const [timeout, updateTimeout] = useState<ReturnType<typeof setTimeout>>();

    const ref = useRef<MicroNotification | undefined>(undefined);

    const notifications = useReactiveVar(microNotification);
    const currentNotification = notifications[0];
    const [show, setShow] = useState(false);

    const hide = () => {
        setShow(false);
        setHasVisibleChildren?.(false);
    };

    const cleanup = () => {
        setTimeout(() => {
            const n = microNotification();
            n.shift();
            microNotification([...n]);
        }, TRANSITION_TIME);
    };

    useEffect(() => {
        if (notifications.length) {
            setShow(true);
            setHasVisibleChildren?.(true);
        }
    }, [notifications]);

    useEffect(() => {
        if (!notifications.length || !currentNotification) {
            return;
        }

        if (currentNotification.variant === "persistent") {
            return;
        }

        if (!ref.current || ref.current.type !== currentNotification.type) {
            // first notification or when the type changes
            updateTimeout(setTimeout(hide, DEBOUNCE_TIME));
        } else if (ref.current && ref.current.type === currentNotification.type) {
            // when current notification is updated
            if (timeout) {
                clearTimeout(timeout);
            }

            updateTimeout(setTimeout(hide, DEBOUNCE_TIME));
        }

        ref.current = {...currentNotification, componentProps: [...currentNotification.componentProps]};
    }, [currentNotification]);

    const handleCloseNotification = (e: React.MouseEvent<HTMLDivElement>) => {
        if (timeout) {
            clearTimeout(timeout);
        }

        hide();

        if (currentNotification.onClose) {
            currentNotification.onClose();
            clearAllNotificationsOfType(currentNotification.type);
        }

        e.stopPropagation();
    };

    const handleClickNotification = () => {
        if (currentNotification.onClick) {
            currentNotification.onClick();
            clearAllNotificationsOfType(currentNotification.type);
        }
    };

    useEffect(() => {
        clearAllNotifications();

        return () => {
            clearAllNotifications();
        };
    }, []);

    if (!currentNotification) {
        return null;
    }

    return (
        <div key="notification-root" onClick={handleClickNotification} className="flex fullw">
            <Transition in={show} timeout={TRANSITION_TIME} onExited={cleanup}>
                {(state) => (
                    <MicroNotificationComponent
                        key={`notification-${currentNotification.id}`}
                        notification={currentNotification}
                        onClose={handleCloseNotification}
                        disableClose={currentNotification.disableClose}
                        className={cls(
                            classes.transition,
                            classes[state],
                            currentNotification.position === "topCenter" && classes.topCenter,
                            currentNotification.className || ""
                        )}
                    />
                )}
            </Transition>
        </div>
    );
};

export default memo(MicroNotificationCenter);
