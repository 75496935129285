import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import InfoTooltip from "@workhorse/components/InfoTooltip";
import genericStyles from "../../styles/GenericSection.module.scss";
import {useTranslation} from "react-i18next";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import Select from "@ui/cdk/Select";
import {CollectFeedbackType, SessionLifecycle} from "@generated/data";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import FeedbackFormsSelector from "./FeedbackFormsSelector";
import designer from "@workhorse/api/designer";

const styles = {...genericStyles};

type OwnerFeedbackTogglerProps = {
    value: CollectFeedbackType;
    onChange: (enabled: CollectFeedbackType) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    isRoom?: boolean;
    feedbackFormId?: string | null;
    sessionLifecycle?: SessionLifecycle;
    onSelectFeedbackForm?: (formId: string) => void;
};

const OwnerFeedbackToggler = ({
    value,
    onChange,
    showIcon = false,
    disabled,
    className,
    isRoom,
    feedbackFormId,
    sessionLifecycle,
    onSelectFeedbackForm,
}: OwnerFeedbackTogglerProps) => {
    const {t} = useTranslation();

    const options = [
        {value: CollectFeedbackType.None, text: t("g.none") || "None"},
        {
            value: CollectFeedbackType.SessionsFeedback,
            text: t("player.settings.general.leave_session.request_feedback.sessions_feedback") || "Session feedback",
        },
        {
            value: CollectFeedbackType.OwnerFeedback,
            text: t("player.settings.general.leave_session.request_feedback.owner_feedback") ?? "Session feedback",
        },
    ];

    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.EXIT_PAGE_FEEDBACK,
    });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as CollectFeedbackType;
        if (!featureIsAvailable) {
            emitFeatureLimitError(t("limitations.notifications.please_upgrade_plan") ?? "");
            return;
        }

        if (value !== CollectFeedbackType.OwnerFeedback) {
            designer.api.session.update({
                feedbackFormId: null,
                feedbackForm: null,
            });
        }

        if (sessionLifecycle === SessionLifecycle.Started) {
            designer.commit();
        }
        onChange(value);
    };

    return (
        <div className="flex flex-col gap-12">
            <div className={cls(styles.togglerWrapper, className)}>
                {showIcon ? (
                    <div className={styles.iconWrapper}>
                        <FeedbackOutlinedIcon />
                    </div>
                ) : null}

                <div className="flex flex-justify-between flex-align-center fullw">
                    <div className={styles.textWrapper}>
                        <div className="flex flex-row flex-align-center gap-5">
                            <Typography variant="base" fontWeight="bold" color="secondary">
                                {t("player.settings.general.leave_session.request_feedback.title") ?? "Collect feedback"}
                            </Typography>
                            {value === CollectFeedbackType.OwnerFeedback && (
                                <InfoTooltip
                                    key="info-owner-feedback"
                                    arrow
                                    placement="top"
                                    title={
                                        t("player.settings.general.leave_session.request_feedback.info_tooltip.title") ??
                                        "Only visible to host"
                                    }
                                    openOnHover={true}
                                    info={
                                        t("player.settings.general.leave_session.request_feedback.info_tooltip.body") ??
                                        "The feedback from this session will only be visible to the host."
                                    }
                                    className="mt-auto ml-5"
                                ></InfoTooltip>
                            )}
                        </div>
                        <Typography color="quaternary" component="span">
                            {t("player.settings.general.leave_session.request_feedback.description")}
                        </Typography>
                    </div>

                    <Select
                        data-id="collect-feedback-settings"
                        options={options}
                        value={value}
                        IconComponent={ExpandMoreRoundedIcon}
                        onChange={handleOnChange}
                        className={genericStyles.selectInput}
                        MenuProps={{
                            classes: {
                                paper: genericStyles.selectMenuPaper,
                            },
                        }}
                        wrapperClassName={genericStyles.selectFormControl}
                        disabled={disabled}
                    />
                </div>
            </div>
            {value === CollectFeedbackType.OwnerFeedback ? (
                <FeedbackFormsSelector
                    onSelectFeedbackForm={onSelectFeedbackForm}
                    feedbackFormId={feedbackFormId}
                    showIcon={showIcon}
                    sessionLifecycle={sessionLifecycle}
                />
            ) : null}
        </div>
    );
};

export default OwnerFeedbackToggler;
