import {getDesktopApiOrThrow, isDesktopApp, Platform} from "@sessions/desktop-api";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";

function useDesktopInfoStore() {
    const [platform, setPlatform] = useState<Platform>();

    useEffect(() => {
        if (!isDesktopApp()) {
            return;
        }

        getDesktopApiOrThrow()
            .getPlatform()
            .then((platform) => {
                setPlatform(platform);
            })
            .catch(() => {});
    }, []);

    return useMemo(
        () => ({
            platform,
            isDesktopApp: isDesktopApp(),
        }),
        [platform]
    );
}

export const [DesktopInfoProvider, useDesktopInfo] = createContextProvider(
    {
        name: "DesktopInfo",
    },
    useDesktopInfoStore
);
