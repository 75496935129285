import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";

import Menu from "@ui/cdk/Menu";
import Typography from "@ui/cdk/Typography";
import {useState} from "@workhorse/api/rendering";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import classes from "./style/GiveAccessListItem.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@ui/core/components/IconButton";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {AccessListItemInfoFragment} from "@generated/data";

type GiveAccessListItemProps = {
    user: AccessListItemInfoFragment["user"];
    id: string;
    removeAccess: (id: string) => void;
};

const GiveAccessListItem = (props: GiveAccessListItemProps) => {
    const {user, id} = props;

    const removeAccess = () => {
        props.removeAccess(id);
    };

    return (
        <>
            <div data-id="collaborator-list-item" className={cls("flex flex-align-center flex-justify-between", classes.listItem)}>
                <div className={cls("flex flex-align-center")}>
                    <ProfilePicture
                        className={classes.itemProfilePicture}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        avatar={user.profilePicture}
                        avatarSize="M"
                        avatarVariant="rounded"
                        key={user.id}
                    />
                    <div className="fullw flex flex-col mr-10">
                        <Typography className={classes.itemName} fontWeight="bolder" component="div" color="octonary">
                            {user.firstName + " " + user.lastName}
                        </Typography>
                        <Typography className={classes.itemName} component="div" color="tertiary" variant="sm">
                            {user.email}
                        </Typography>
                    </div>
                </div>
                <IconButton onClick={removeAccess} className={classes.closeButton}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
        </>
    );
};

export default GiveAccessListItem;
