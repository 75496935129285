import loadable from "@workhorse/api/loadable";
import {ReactComponent as RecordingMenuIcon} from "../assets/recording-menu.svg";
import {SessionSettingsSection} from "../../definitions";

const RecordingSection = loadable(() => import("./RecordingSection"));
RecordingSection.displayName = "Loadable:SessionSection";

const RecordingSectionDefinition: SessionSettingsSection<"general.recording"> = {
    translateKey: "general.recording",
    kind: "general.recording",
    title: "Access",
    component: RecordingSection,
    icon: RecordingMenuIcon,
    rbac: {
        "session.isAssistant": true,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default RecordingSectionDefinition;
