import {AcceptedTimezone, getLocalTimezone} from "@common/utils/timezones";
import {
    BookingEventFragment,
    BookingEventLitItemFragment,
    BookingEventUpdateInput,
    BookingFragment,
    BookingFragmentDoc,
    BookingScheduleFragment,
    BookingsCountFragment,
    BookingsCountFragmentDoc,
    CancelBookedEventDocument,
    CancelBookingDocument,
    ChangeBookingAssigneeMutationVariables,
    CloneBookingEventDocument,
    DeleteBookingCollaboratorDocument,
    DeleteBookingDocument,
    DeleteBookingEventDocument,
    GetBookingEventListDocument,
    GetBookingEventsCountDocument,
    GetBookingSchedulesListDocument,
    GetPublicBookingAvailableDateSlotsDocument,
    Offer,
    PublicBookingEventAvailableSlotFragment,
    RescheduleBookedEventDocument,
    SeenBookingsDocument,
    ToggleBookingEventDocument,
    UpsertBookingEventDocument,
    UpsertBookingScheduleDocument,
    GetBookedEventDocument,
} from "@generated/data";
import {BOOKING_ALL_DAYS} from "@workhorse/api/booking";
import {mutate} from "@workhorse/dataApi";
import {BookingScheduleCreate} from "@workhorse/pages/newBooking/NewBooking";
import {addHours, addMinutes} from "date-fns";
import {getTimezoneOffset} from "date-fns-tz";
import {v4 as uuid} from "uuid";
import apollo, {ApolloOperationContext} from "../apollo";
import {makeVar, useReactiveVar} from "../data";
import {useEffect} from "../rendering";
import toast from "../toast";
import {
    BookSlotData,
    CancelBookedEventInput,
    RescheduleBookedEventInput,
    UpsertBookingColaborator,
    UpsertBookingEvent,
    UpsertBookingSchedule,
} from "./definitions";
import {useBookingView} from "./providers";

type WindowParentNotification = {
    nonce: string;
    height: number;
    loaded: boolean;
};

export enum BookingsFilterType {
    ALL = "All",
    ACTIVE = "Active",
    DISABLED = "Disabled",
}

export enum BookingsFilterOwner {
    ALL = "All Owners",
    ME = "Me",
}

export type BookingsFilterValue = {
    type: BookingsFilterType;
    owner: string;
};

const parentNotification = makeVar<WindowParentNotification>({nonce: "", height: 0, loaded: false});

export const setParentNotification = (notification: Partial<WindowParentNotification>) => {
    if (window.top === window) {
        return;
    }

    let changed = false;
    const savedNotification = parentNotification();

    Object.entries(notification).forEach(([key, value]) => (savedNotification[key] !== value ? (changed = true) : null));

    if (changed) {
        parentNotification({...savedNotification, ...notification});
    }
};

export const useParentNotification = () => {
    const notification = useReactiveVar(parentNotification);
    const [{nonce}] = useBookingView();

    useEffect(() => {
        if (notification.nonce !== "" && notification.height !== 0 && notification.loaded) {
            window.parent.postMessage({height: notification.height, nonce: notification.nonce}, "*");
        }
    }, [notification]);

    useEffect(() => {
        parentNotification({...parentNotification(), nonce});
    }, [nonce]);

    useEffect(() => {
        const handler = (event: MessageEvent<{nonce: string} & {resetHeight: true}>) => {
            if (event.data.nonce !== nonce) {
                return;
            }

            if ("resetHeight" in event.data) {
                setParentNotification({height: 0});
            }
        };

        window.addEventListener("message", handler);

        return () => {
            window.removeEventListener("message", handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return notification;
};

export const windowRedirect = (url: string, replace?: boolean) => {
    if (window.top === window) {
        if (replace) {
            return window.location.replace(url);
        }

        window.open(url, "_self");
    }
    const storedNotification = parentNotification();

    return window.parent.postMessage(
        {
            nonce: storedNotification.nonce,
            redirect: true,
            url,
            replace,
        },
        "*"
    );
};

export const notifyParentOfClose = () => {
    if (window.top === window) {
        return;
    }

    window.parent.postMessage({close: true, nonce: parentNotification().nonce}, "*");
};

const ADD_EVENT_TO_LIST_TRIGGER: (keyof BookingEventLitItemFragment)[] = ["id", "name", "disabled"];
export const DEFAULT_BOOKING_EVENT_DURATION = 45 * 60;

const shouldUpdateList = (input: BookingEventUpdateInput): boolean =>
    !!Object.keys(input).find((field) => ADD_EVENT_TO_LIST_TRIGGER.includes(field as keyof BookingEventLitItemFragment));

const addEventToList = (event: BookingEventFragment) => {
    const data = apollo.client.readQuery({
        query: GetBookingEventListDocument,
        variables: {
            workspaceId: event.workspaceId,
        },
    });

    if (!data) {
        return;
    }

    const bookingEvents: BookingEventLitItemFragment[] = [
        ...(data.bookingEvents || []).filter((bookingEvent) => bookingEvent.id !== event.id),
        {
            __typename: event.__typename,
            id: event.id,
            name: event.name,
            disabled: event.disabled,
            isListed: event.isListed,
            duration: event.duration,
            additionalDuration: event.additionalDuration,
            schedule: event.schedule,
            agenda: event.agenda,
            seen: event.seen,
            bookingsCount: event.bookingsCount,
            owner: event.owner,
            collaborators: event.collaborators,
            workspaceId: event.workspaceId,
            payments: [],
        },
    ].sort((a, b) => a.name.localeCompare(b.name));

    apollo.client.writeQuery({
        query: GetBookingEventListDocument,
        variables: {
            workspaceId: event.workspaceId,
        },
        data: {
            ...data,
            bookingEvents,
        },
    });
};

const addScheduleToList = (schedule: BookingScheduleFragment) => {
    const data = apollo.client.readQuery({
        query: GetBookingSchedulesListDocument,
    });

    if (!data) {
        return;
    }

    const bookingSchedules: BookingScheduleFragment[] = [
        ...(data.bookingSchedules || []).filter((bookingSchedule) => bookingSchedule.id !== schedule.id),
        schedule,
    ].sort((a, b) => a.name.localeCompare(b.name));

    apollo.client.writeQuery({
        query: GetBookingSchedulesListDocument,
        data: {
            ...data,
            bookingSchedules,
        },
    });
};

export const updateBookingEventsCount = (newCounter: {all?: number; disabled?: number}) => {
    const data = apollo.client.readQuery({
        query: GetBookingEventsCountDocument,
    });

    if (!data) {
        return;
    }

    if (data.bookingEventsCount) {
        const newAllValue = data.bookingEventsCount?.all + (newCounter.all ? newCounter.all : 0);
        const newDisabledValue = data.bookingEventsCount?.disabled + (newCounter.disabled ? newCounter.disabled : 0);

        apollo.client.writeQuery({
            query: GetBookingEventsCountDocument,
            data: {
                ...data,
                bookingEventsCount: {
                    ...data.bookingEventsCount,
                    all: newAllValue,
                    disabled: newDisabledValue,
                },
            },
        });
    }
};

export const upsertBookingEvent = async ({create, update}: UpsertBookingEvent, id?: string): Promise<BookingEventFragment> => {
    return new Promise((resolve, reject) => {
        apollo.client
            .mutate({
                mutation: UpsertBookingEventDocument,
                variables: {
                    id,
                    ...(!id && create ? {create} : {update}),
                },
            })
            .then(({data, errors}) => {
                if (errors || !data?.upsertOneBookingEvent) {
                    if (errors) {
                        console.error(errors);
                    }

                    reject("There was a problem when upserting the booking event");
                } else {
                    if (data?.upsertOneBookingEvent && (create || (update && shouldUpdateList(update)))) {
                        addEventToList(data.upsertOneBookingEvent);
                    }

                    resolve(data.upsertOneBookingEvent);
                }
            })
            .catch((err) => {
                console.log("in catch", err);
            });
    });
};

export const toggleBookingEvent = async (id: string, disabled: boolean, cancelBookings: boolean, reason = "") => {
    await apollo.client.mutate({
        mutation: ToggleBookingEventDocument,
        variables: {id, disabled, reason, cancelBookings},
    });
};

export const deleteBookingEvent = async (id: string, reason: string) => {
    await apollo.client
        .mutate({
            mutation: DeleteBookingEventDocument,
            variables: {id, reason},
        })
        .then(() => {
            apollo.cache.evict({id});
            apollo.cache.gc();
        });
};

export const cloneBookingEvent = async (id: string) => {
    const bookingEvent = await apollo.client.mutate({
        mutation: CloneBookingEventDocument,
        variables: {id},
    });

    if (!bookingEvent.data?.cloneOneBookingEvent) {
        toast(<p>There was an error cloning the booking event.</p>, {
            type: "error",
        });
        throw new Error("Could not duplicate booking event");
    }

    addEventToList(bookingEvent.data.cloneOneBookingEvent);
    return bookingEvent.data.cloneOneBookingEvent;
};

export const upsertBookingSchedule = async (
    {create, update}: UpsertBookingSchedule,
    id?: string,
    context?: ApolloOperationContext
): Promise<BookingScheduleFragment> => {
    return new Promise((resolve, reject) => {
        apollo.client
            .mutate({
                mutation: UpsertBookingScheduleDocument,
                variables: {
                    id,
                    ...(!id && create ? {create} : {update}),
                },
                context,
            })
            .then(({data, errors}) => {
                if (errors || !data?.upsertOneBookingSchedule) {
                    toast(<p>There was an error saving your booking schedule</p>, {
                        type: "error",
                    });

                    if (errors) {
                        console.error(errors);
                    }

                    reject("There was a problem when upserting the booking schedule");
                } else if (data?.upsertOneBookingSchedule) {
                    addScheduleToList(data.upsertOneBookingSchedule);

                    resolve(data.upsertOneBookingSchedule);
                }
            });
    });
};

export const bookedSlot = makeVar<
    | {date: Date; id: string; token?: string; timezone: AcceptedTimezone; duration?: number; bookingId: string}
    | undefined
    | {
          date: Date;
          id: string;
          token?: string;
          timezone: AcceptedTimezone;
          duration?: number;
          afterRegistrationRedirectUrl?: string;
          afterRegistrationOffer?: Offer;
      }
    | undefined
>(undefined);

export const bookSlot = (variables: BookSlotData, timezone: AcceptedTimezone, operationContext?: ApolloOperationContext) => {
    return mutate("BookEventDocument", {
        variables: {
            ...variables,
            additionalInformation: {
                data: variables.additionalInformation,
            },
        },
        errorPolicy: "all",
        context: operationContext,
    }).then(({errors, data}) => {
        if (errors || !data?.bookEvent) {
            if (errors) {
                console.error(errors);
            }

            const error = errors?.some((error) => error.message === "Owner already booked.") ? "owner_booked" : "network_failure";
            return {error};
        } else {
            apollo.client.writeQuery({
                query: GetBookedEventDocument,
                variables: {
                    id: data.bookEvent.id as string,
                    token: data.bookEvent.token || undefined,
                    includeEvent: false,
                },
                data: {
                    bookedEvent: {
                        ...data.bookEvent,
                    },
                },
            });

            return data.bookEvent;
        }
    });
};

export const upsertCollaborator = async ({create, update}: UpsertBookingColaborator, id?: string, context?: ApolloOperationContext) => {
    return await mutate("UpsertBookingCollaboratorDocument", {
        variables: {id, create, update},
        context,
    }).then(({errors, data}) => {
        if (errors || !data?.upsertOneBookingCollaborator) {
            if (errors) {
                console.error(errors);
            }
            throw new Error("There was a problem when upserting a booking collaborator");
        } else {
            return data.upsertOneBookingCollaborator;
        }
    });
};

export const deleteBookingCollaborator = async (id: string) => {
    await apollo.client
        .mutate({
            mutation: DeleteBookingCollaboratorDocument,
            variables: {id},
        })
        .then(() => {
            apollo.cache.evict({id});
            apollo.cache.gc();
        });
};

export const reassignBooking = async (vars: ChangeBookingAssigneeMutationVariables, context?: ApolloOperationContext) => {
    return await mutate("ChangeBookingAssigneeDocument", {
        variables: vars,
        context,
    }).then(({errors, data}) => {
        if (errors || !data?.changeBookingAssignee) {
            if (errors) {
                console.error(errors);
            }
            throw new Error("There was a problem when changing booking assignee");
        } else {
            apollo.client.writeFragment({
                fragment: BookingFragmentDoc,
                fragmentName: "Booking",
                id: vars.bookingId,
                data: {
                    ...data.changeBookingAssignee,
                },
            });

            return data.changeBookingAssignee;
        }
    });
};

export const getAvailableSlotsForDay = (day: Date, slots: PublicBookingEventAvailableSlotFragment[], timezone: AcceptedTimezone) => {
    const offset = (getTimezoneOffset(getLocalTimezone(), day) - getTimezoneOffset(timezone, day)) / (60 * 1000);
    const wantedDay = addMinutes(day, offset);

    const wantedSlots = slots.filter((slot) => {
        const day = new Date(slot.day);
        return day >= wantedDay && day < addHours(wantedDay, 24);
    });

    return wantedSlots;
};

export const updateBookingEventsAvailableSlotsForDay = (
    day: Date,
    slots: PublicBookingEventAvailableSlotFragment[],
    timezone: AcceptedTimezone
) => {
    const bookTimeSlots = getAvailableSlotsForDay(day, slots, timezone);

    apollo.cache.writeQuery({
        query: GetPublicBookingAvailableDateSlotsDocument,
        data: {bookTimeSlots},
    });
};

const updateBookingsCount = (id: string, fields: Partial<Record<keyof Omit<BookingsCountFragment, "__typename" | "id">, number>>) => {
    const countData = apollo.cache.readFragment({
        fragment: BookingsCountFragmentDoc,
        fragmentName: "BookingsCount",
        id: apollo.cache.identify({__ref: `${id}-count`, __typename: "BookingsCount"}),
    });

    if (!countData) {
        return;
    }

    const updatedData = Object.entries(fields).reduce((acc, [key, value]) => ({...acc, [key]: countData[key] + value}), {});

    apollo.cache.writeFragment({
        fragment: BookingsCountFragmentDoc,
        fragmentName: "BookingsCount",
        id: `${id}-count`,
        data: {
            ...countData,
            ...updatedData,
        },
    });
};

export const cancelBooking = (bookingEventId: string, booking: BookingFragment, reason: string) => {
    if (!booking.session?.id) {
        return;
    }

    // eager eviction of the underlining session
    apollo.cache.evict({id: booking.session?.id});
    apollo.cache.gc();

    return apollo.client
        .mutate({
            mutation: CancelBookingDocument,
            variables: {
                id: booking.id,
                reason,
            },
        })
        .then(() => {
            updateBookingsCount(bookingEventId, {canceled: 1, upcoming: booking.session ? -1 : 0});
        });
};

export const deleteBooking = async (bookingEventId: string, id: string) => {
    await apollo.client.mutate({
        mutation: DeleteBookingDocument,
        variables: {id},
    });

    apollo.cache.evict({id});
    apollo.cache.gc();

    updateBookingsCount(bookingEventId, {canceled: -1, all: -1});
};

export const seenBookings = (ids: string[]) => {
    if (!ids.length) {
        return;
    }

    return apollo.client.mutate({
        mutation: SeenBookingsDocument,
        variables: {
            ids,
        },
    });
};

export const cancelBookedEvent = async (variables: CancelBookedEventInput, operationContext?: ApolloOperationContext) => {
    return await apollo.client
        .mutate({
            mutation: CancelBookedEventDocument,
            variables: variables,
            context: operationContext,
        })
        .then(({errors, data}) => {
            if (errors || !data?.cancelBookedEvent) {
                if (errors) {
                    console.error(errors);
                }
                throw new Error("There was a problem when canceling this booked event.");
            } else {
                bookedSlot(undefined);
                return data.cancelBookedEvent;
            }
        });
};

export const rescheduleBookedEvent = async (
    variables: RescheduleBookedEventInput,
    timezone: AcceptedTimezone,
    operationContext?: ApolloOperationContext
) => {
    return await apollo.client
        .mutate({
            mutation: RescheduleBookedEventDocument,
            variables: {
                ...variables,
                additionalInformation: {
                    data: variables.additionalInformation,
                },
            },
            context: operationContext,
        })
        .then(({errors, data}) => {
            if (errors || !data?.rescheduleBookedEvent) {
                if (errors) {
                    console.error(errors);
                }
                throw new Error("There was a problem when rescheduling this event.");
            } else {
                bookedSlot({
                    date: variables.timeSlot,
                    id: data.rescheduleBookedEvent.sessionId,
                    bookingId: data.rescheduleBookedEvent.id as string,
                    timezone,
                    token: data.rescheduleBookedEvent.token || undefined,
                });
                return data.rescheduleBookedEvent;
            }
        });
};

export const generateScheduleName = (schedules: BookingScheduleFragment[], name: string, iterator?: number) => {
    const desiredName = iterator ? `${name} (${iterator})` : name;
    const sameNameSchedules = schedules.some(({name}) => name.toUpperCase() === desiredName.toUpperCase());

    if (sameNameSchedules) {
        return generateScheduleName(schedules, name, (iterator ?? 0) + 1);
    }

    return desiredName;
};
export const saveSchedule = async (
    schedules: BookingScheduleFragment[] | undefined = [],
    schedule: BookingScheduleCreate,
    isCreate: boolean
): Promise<BookingScheduleFragment> => {
    const input = {
        name: isCreate ? generateScheduleName(schedules, schedule.name) : schedule.name,
        days: BOOKING_ALL_DAYS.reduce((acc, day) => ({...acc, [day]: schedule.days.includes(day)}), {}),
        hours: BOOKING_ALL_DAYS.reduce((acc, day) => ({...acc, [day]: schedule.hours[day].map(([start, end]) => ({start, end}))}), {}),
        timeZone: "",
    };

    const newSchedule = await upsertBookingSchedule(
        isCreate ? {create: input} : {update: input},
        isCreate ? undefined : schedule.id ?? uuid()
    );

    return newSchedule;
};
