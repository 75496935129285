import SearchIcon from "@material-ui/icons/Search";
import Input from "@ui/cdk/Input";
import {cls} from "@ui/cdk/util";
import {useMemo, useState} from "@workhorse/api/rendering";
import classes from "../styles/BookTimeZones.module.scss";
import {getAcceptedTimezonesList} from "@common/utils/timezones";
import Typography from "@ui/cdk/Typography";
import TogglerContainer from "@ui/cdk/TogglerContainer";
import {useTranslation} from "react-i18next";

type BookTimeZoneProps = {
    timeZone: string;
    onChange: (timeZone: string) => void;
    chosenDate: Date;
    isHalfDayClock?: boolean;
    onTimeChange: (halfDay: boolean) => void;
};

export const BookTimeZone = ({timeZone, onChange, chosenDate, isHalfDayClock, onTimeChange}: BookTimeZoneProps) => {
    const {t} = useTranslation();
    const [search, setSearch] = useState("");

    const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };
    const timezones = useMemo(() => getAcceptedTimezonesList(chosenDate), [chosenDate]);
    const result = Object.entries<string>(timezones).filter(([_, value]) => value.toLowerCase().includes(search.toLowerCase()));

    const onClockChange = () => onTimeChange(!isHalfDayClock);

    return (
        <div className={classes.root}>
            <div className={classes.searchWrapper}>
                <Input
                    startAdornment={<SearchIcon className={classes.searchIcon} />}
                    className={classes.search}
                    formControlClassName={classes.searchControl}
                    placeholder={t("g.search") ?? ""}
                    onChange={handleOnSearchChange}
                    autoFocus
                />
            </div>
            <div className={classes.list}>
                <div>
                    {!result.length ? (
                        <div className="px-16 pb-16">{t("g.time.no_timezones_match")}</div>
                    ) : (
                        result.map(([key, value]) => (
                            <div
                                key={key}
                                className={cls(classes.listItem, timeZone === key && classes.listItemSelected)}
                                onClick={onChange.bind(null, key)}
                            >
                                {value}
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div className={classes.timeFormat}>
                <TogglerContainer
                    title={<Typography className={classes.settingsTitle}>{t("g.time.time_format")}</Typography>}
                    secondTitle={""}
                    checked={!!isHalfDayClock}
                    name="halfclock"
                    onChange={onClockChange}
                    className="fullw"
                    label={t("g.time.time_format_12_hour") ?? ""}
                    labelLeft={t("g.time.time_format_24_hour") ?? ""}
                    isSwitch
                />
            </div>
        </div>
    );
};
