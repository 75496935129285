import {Route, Switch, useLocation} from "@workhorse/api/routing";
import {Tracking} from "@workhorse/api/tracking";
import AuthAllComponents, {authPaths} from "@workhorse/pages/user/auth/AuthAllComponents";

const AuthRoutes = () => {
    const location = useLocation();
    return (
        <>
            <Tracking key="tracking" />
            <Switch location={location} key={location.pathname}>
                {Object.keys(authPaths).map((authRouteKey) => {
                    return <Route {...authPaths[authRouteKey]} component={AuthAllComponents[authRouteKey]} key={authRouteKey} />;
                })}
            </Switch>
        </>
    );
};

export default AuthRoutes;
