import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {useOnboardingSession, useIsWantedAgenda} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {TooltipButtonArrow, WithTooltipChildren} from "./onboarding-tooltips-utils";
import {cls} from "@ui/cdk/util";
import style from "./onboarding.module.scss";
import List from "@ui/core/components/List";
import ListItem from "@ui/core/components/ListItem";

export function OnboardingMicro(props: WithTooltipChildren) {
    const {tooltip, next} = useOnboardingSession();
    const selectedTooltip = tooltip?.startsWith("micro-") ? tooltip : "-1";
    const isWantedAgenda = useIsWantedAgenda(selectedTooltip);
    const skip = selectedTooltip === "-1" || !isWantedAgenda;

    let title = "";
    let content = <></>;
    let dataIdString = "";

    if (tooltip === "micro-website") {
        title = "Embed your website";
        content = (
            <>Let your participants browse your website right here inside your session. It’s great for sales calls, demos, and training.</>
        );
        dataIdString = "onboarding-button-strike1-step3-next";
    }

    if (tooltip === "micro-smartboard") {
        title = "Tools for every meeting type";
        content = (
            <List>
                <ListItem className={style.tooltipBullet}>Sessions was built for next-level engagement and interactivity.</ListItem>
                <ListItem className={style.tooltipBullet}>
                    Whether you host one-on-one demos or major online events, we’ve got the right tools to keep everyone motivated and
                    interested.
                </ListItem>
                <ListItem className={style.tooltipBullet}>You can embed a tool whenever you need it.</ListItem>
                <ListItem className={style.tooltipBullet}>
                    If you need multiple tools, add them to an agenda and enjoy a seamless experience.
                </ListItem>
            </List>
        );
        dataIdString = "onboarding-button-strike1-step4-next";
    }

    return (
        <OnboardingTooltip
            tooltipId={selectedTooltip}
            title={
                <>
                    <Typography className="title" fontWeight="bolder">
                        {title}
                    </Typography>

                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        {content}
                    </Typography>

                    <Button
                        variant="primary"
                        onClick={() =>
                            next({
                                delay: 1000,
                            })
                        }
                        className={cls("ml-auto mt-8", style.button)}
                        size="small"
                        data-id={dataIdString}
                    >
                        Next <TooltipButtonArrow />
                    </Button>
                </>
            }
            classes={{
                tooltip: style.microTooltip,
            }}
            placement="right"
            arrow={true}
            skip={skip}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
