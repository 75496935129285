import {makeVar} from "@workhorse/api/state";

interface ReleaseNotesVar {
    enable: boolean;
    toggleManual: boolean; // used when dialog is manual opened from help section
    source?: "notification";
}

export const releaseNotesDialogEnabled = makeVar<ReleaseNotesVar>({
    enable: false,
    toggleManual: false,
});
