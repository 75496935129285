import {AddOrganizationMemberDocument, GetOrganizationMembersDocument, SetMemberWorkspaceRoleDocument} from "@generated/data";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useMutation} from "@workhorse/api/data";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import classes from "./styles/InviteMemberConfirmDialog.module.scss";
import toast from "@workhorse/api/toast";
import {useUserInfo} from "@workhorse/providers/User";

type InviteMemberConfirmDialogProps = {
    open: boolean;
    member: {
        email: string;
    };
    content?: {title: string; body: string};
    onClose: () => void;
    onSuccess?: () => void;
    roleId?: string;
};

const InviteMemberConfirmDialog = (props: InviteMemberConfirmDialogProps) => {
    const {open, onClose, member, onSuccess, content, roleId} = props;

    // TODO: maca - multiple workspaces - maybe this should come from a user selection ?
    const user = useUserInfo();
    const workspaceId = user.activeWorkspace.id;

    const [loading, setLoading] = useState(false);
    const [addMember] = useMutation(AddOrganizationMemberDocument, {
        onError: (err) => {
            console.error(err);
            toast(`Error adding member!`, {type: "error"});
            onClose();
        },
        update: (cache, {data}) => {
            if (!data?.addMemberToOrganization.newMember) {
                return;
            }

            const existingQuery = cache.readQuery({
                query: GetOrganizationMembersDocument,
                variables: {
                    organizationId: user.activeOrganizationPermission.organization.id,
                    excludeSelf: false,
                },
            });

            if (!existingQuery?.organization) {
                return;
            }

            cache.writeQuery({
                query: GetOrganizationMembersDocument,
                variables: {
                    organizationId: user.activeOrganizationPermission.organization.id,
                    excludeSelf: false,
                },
                data: {
                    ...existingQuery,
                    organization: {
                        ...existingQuery.organization,
                        members: [data.addMemberToOrganization.newMember, ...existingQuery.organization.members],
                    },
                },
            });
        },
    });
    const [setRole] = useMutation(SetMemberWorkspaceRoleDocument);

    const dialogRef = useRef<DialogImperativeRef>(null);

    const onAddMember = async () => {
        setLoading(true);
        try {
            const res = await addMember({
                variables: {
                    memberToAdd: {
                        workspaceIds: [workspaceId],
                        email: member.email.trim().toLowerCase(),
                        sendInviteEmail: true,
                    },
                },
                refetchQueries: ["GetContactsAndGroups"],
                awaitRefetchQueries: true,
            });

            if (!res.data) {
                toast(`Error adding member!`, {type: "error"});
                return onClose();
            }

            const duplicatedEmail = res.data?.addMemberToOrganization?.duplicatedEmail;

            if (duplicatedEmail === true) {
                return onSuccess?.();
            }

            if (roleId) {
                const userId = res?.data?.addMemberToOrganization?.newMember?.id;

                // TODO: maca permissions
                // const role = WorkspacePermissionType.Editor;

                await setRole({
                    variables: {
                        userId: userId ?? "",
                        newRoleId: roleId,
                    },
                });
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        dialogRef.current?.toggle?.(open);
    }, [open]);

    const message = (props.content?.body ?? "").replace("{{email}}", member.email);

    return (
        <Dialog
            imperativeRef={dialogRef}
            onClose={onClose}
            BackdropProps={{
                className: "standard-backdrop",
            }}
            classes={{
                container: "standard-dialog-container",
            }}
            PaperProps={{
                className: cls("standard-dialog-paper", classes.dialogPaper),
            }}
        >
            <div className="flex flex-align-center">
                <Typography variant="xl2" fontWeight="boldest" color="duodenary">
                    {props.content?.title ?? "Invite to workspace"}
                </Typography>
            </div>
            <div className="my-20 fullw">
                <Typography variant="base" fontWeight="normal" color="tertiary">
                    {message}
                </Typography>
            </div>
            <div className={classes.buttons}>
                <Button variant="quaternary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" type="button" onClick={onAddMember} withMarginLeft loading={loading}>
                    Confirm
                </Button>
            </div>
        </Dialog>
    );
};

export default InviteMemberConfirmDialog;
