import {ToolbarGenericProps} from "../api";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";

export const ToolbarHistory = (props: ToolbarGenericProps) => {
    const {editor} = props;

    return (
        <>
            <ToolbarItem
                tooltip="Undo"
                className={classes.button}
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.isEditable || !editor.can().undo()}
                isMobile={props.isMobile}
            >
                <UndoRoundedIcon />
            </ToolbarItem>
            <ToolbarItem
                tooltip="Redo"
                className={classes.button}
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.isEditable || !editor.can().redo()}
                isMobile={props.isMobile}
            >
                <RedoRoundedIcon />
            </ToolbarItem>
        </>
    );
};
