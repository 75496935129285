import CssBaseline from "@ui/core/components/CssBaseline";
import CssOverrides from "@ui/CssOverrides";
import apollo from "@workhorse/api/apollo";
import {SessionInfoProvider} from "@workhorse/api/conference2/providers/SessionInfoProvider";
import {ApolloProvider} from "@workhorse/api/data";
import {useEffect} from "@workhorse/api/rendering";
import {Router} from "@workhorse/api/routing";
import {WithSessionSettingsProvider} from "@workhorse/api/session-settings";
import CommandPalette from "@workhorse/components/command-palette/CommandPalette";
import CommandPaletteProviders from "@workhorse/components/command-palette/CommandPaletteProviders";
import {
    CommandPaletteActionArgProvider,
    CommandPaletteActionCbProvider,
    PaletteExcludedItemsProvider,
} from "@workhorse/components/command-palette/PaletteActionProvider";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import {OnlineOfflineDetector} from "@workhorse/components/OnlineOfflineDetector";
import {WithChildren} from "@workhorse/declarations";
import {MeetingProvider} from "../api/conference2";
import GuestDisallowedRoutes from "../GuestDisallowedRoutes";
import Layout from "../providers/Layout";
import MainRoutes from "../providers/MainRoutes";
import "../style/main.scss";
import {AsyncDialogs} from "./AsyncDialogs";
import BrandingProvider from "./SessionBrandingProvider";
import ComputeVisibleParticipants from "./ComputeVisibleParticipants";
import {CurrentSessionIdProvider} from "./CurrentSessionIdProvider";
import CustomProviders from "./CustomProviders";
import GlobalNotificationsProvider from "./GlobalNotificationsProvider";
import {HostTypeProvider, useHostType} from "./HostTypeProvider";
import {MainLoadingProvider} from "./MainLoadingState";
import {useMobile} from "./MobileProvider";
import {ChimeIsBlockedProvider, LocalNetworkStateProvider} from "./PresenceProvider";
import {ResizeObserverProvider} from "./ResizeObserverProvider";
import {SessionDataProvidersWithDesignerProviders} from "./SessionDataProviders";
import {SessionReactionsProvider} from "./SessionReactionsProvider";
import {SessionViewProvider} from "./SessionViewProvider";
import {StorageProvider} from "./StorageProvider";
import {UserAgentFeaturesProvider} from "./UserAgentFeaturesProvider";
import {VisibleParticipantsProvider} from "./VisibleParticipantsProvider";
import {RecordingProcessorProvider} from "@workhorse/providers/RecordingProcessorProvider";
import {useQuery} from "@workhorse/dataApi";
import ChunkLoadErrorComponent from "@workhorse/components/ChunkLoadErrorComponent";
import {LocalParticipantsProvider} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {AgendaItemLimitDialog} from "./AgendaLimitDialog";
import {DesktopInfoProvider} from "./DesktopInfoProvider";

// const Devtools = loadable(() => import("../components/devtools"), {
//     fallback: <></>,
// });

function WithErrorBoundry(props: WithChildren) {
    const mobileState = useMobile();
    return (
        <ErrorBoundary key="main-err-boundary" location="AppEntry" mobileState={mobileState}>
            {props.children}
        </ErrorBoundary>
    );
}

function WithChunkLoadErrorPage(props: WithChildren) {
    const {data} = useQuery("LocalVersionDocument");

    return <>{!data?.version.chunkLoadErr ? props.children : <ChunkLoadErrorComponent key="error-comp-chunk" withHeader={true} />}</>;
}

function AppEntry() {
    return (
        <WithErrorBoundry>
            <LocalNetworkStateProvider>
                <ChimeIsBlockedProvider>
                    <OnlineOfflineDetector>
                        {/* {(env.devtoolsEnabled && <Devtools key="devtools" />) || null} */}
                        <GuestDisallowedRoutes key="guest-disallowed-routes">
                            <RecordingProcessorProvider>
                                <MainLoadingProvider>
                                    <SessionReactionsProvider key="session-reactions-provider">
                                        <VisibleParticipantsProvider>
                                            <ComputeVisibleParticipants>
                                                <Layout>
                                                    <MainRoutes key="main-routes" />

                                                    <GlobalNotificationsProvider key="calendar-updates-provider" />
                                                </Layout>
                                            </ComputeVisibleParticipants>
                                        </VisibleParticipantsProvider>
                                    </SessionReactionsProvider>
                                </MainLoadingProvider>
                            </RecordingProcessorProvider>
                        </GuestDisallowedRoutes>
                    </OnlineOfflineDetector>
                </ChimeIsBlockedProvider>
            </LocalNetworkStateProvider>
        </WithErrorBoundry>
    );
}

function GtagLoader() {
    const hostType = useHostType();
    useEffect(() => {
        // const existingScript = document.head.querySelector("#GA_MEASUREMENT");
        // function initGtag(){
        //     // @ts-expect-error
        //     window.dataLayer = window.dataLayer || [];
        //     // @ts-expect-error
        //     if(window.dataLayer.length && disable){
        //         window['ga-disable-TRACKING-ID'] = true;
        //         return;
        //     }
        //     // @ts-expect-error
        //     window.gtag = function(...arg: any[]) { dataLayer.push(arguments); }
        //     // @ts-expect-error
        //     gtag("js", new Date());
        //     // @ts-expect-error
        //     gtag("config", env.gaMeasurementId);
        // }
        // if(hostType !== HostType.Player){
        //     if(!existingScript){
        //         loadScript(`https://www.googletagmanager.com/gtag/js?id=${env.gaMeasurementId}`,"GA_MEASUREMENT").then(() => initGtag());
        //     }
        //     else{
        //         initGtag();
        //     }
        // }
    }, [hostType]);
    return <></>;
}

function Providers() {
    return (
        <DesktopInfoProvider>
            <UserAgentFeaturesProvider>
                <StorageProvider>
                    <ApolloProvider client={apollo.client}>
                        {/* Passing empty callback to BrowserRouter to disable/block default browser prompt */}
                        <ResizeObserverProvider>
                            <Router basename="/" getUserConfirmation={() => {}}>
                                <CurrentSessionIdProvider>
                                    <HostTypeProvider>
                                        <GtagLoader />

                                        <SessionDataProvidersWithDesignerProviders>
                                            <SessionInfoProvider>
                                                <BrandingProvider />
                                                <CustomProviders>
                                                    <MeetingProvider>
                                                        <LocalParticipantsProvider />
                                                        <WithChunkLoadErrorPage>
                                                            <CssBaseline key="css-baseline" />
                                                            <CssOverrides key="css-overrides" />
                                                            <SessionViewProvider>
                                                                <WithSessionSettingsProvider>
                                                                    <CommandPaletteProviders>
                                                                        <PaletteExcludedItemsProvider>
                                                                            <CommandPaletteActionCbProvider>
                                                                                <CommandPaletteActionArgProvider>
                                                                                    <CommandPalette />
                                                                                    <AsyncDialogs />
                                                                                    <AppEntry />
                                                                                    <AgendaItemLimitDialog />
                                                                                </CommandPaletteActionArgProvider>
                                                                            </CommandPaletteActionCbProvider>
                                                                        </PaletteExcludedItemsProvider>
                                                                    </CommandPaletteProviders>
                                                                </WithSessionSettingsProvider>
                                                            </SessionViewProvider>
                                                        </WithChunkLoadErrorPage>
                                                    </MeetingProvider>
                                                </CustomProviders>
                                            </SessionInfoProvider>
                                        </SessionDataProvidersWithDesignerProviders>
                                    </HostTypeProvider>
                                </CurrentSessionIdProvider>
                            </Router>
                        </ResizeObserverProvider>
                    </ApolloProvider>
                </StorageProvider>
            </UserAgentFeaturesProvider>
        </DesktopInfoProvider>
    );
}

export default Providers;
