import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import Button from "@ui/cdk/Button";
import {useMemo, useRef} from "@workhorse/api/rendering";
import {OnboardingFeature} from "@workhorse/api/seen";
import {useOnboardingFeature} from "../hooks";
import {OnboardingFeatureConfiguration} from "../provider";
import {getDataIdString, getHelpCenterLink} from "../utils";
import classes from "./styles/OnboardingFeaturePlaceholder.module.scss";
import {openExternalLink} from "@workhorse/util/links";

export type OnboardingFeaturePlaceholderProps = {
    feature: OnboardingFeature;
};

function OnboardingFeaturePlaceholder(props: OnboardingFeaturePlaceholderProps) {
    const {feature} = props;

    const contentRef = useRef<HTMLDivElement>(null);

    const config = useOnboardingFeature(feature);
    const {component} = config as Required<OnboardingFeatureConfiguration>;

    const handleLearnMore = () => {
        const link = getHelpCenterLink(feature);
        if (link) {
            openExternalLink(link);
        }
    };

    const Content = useMemo(
        () => (props: {type: "video" | "text"; ref: React.RefObject<HTMLDivElement>}) => {
            const {type, ref} = props;
            const Comp = component;
            Comp.displayName = "OnboardingFeaturePlaceholderContent";

            return <Comp type={type} ref={ref} />;
        },
        [component]
    );
    const dataIdString = getDataIdString(feature);

    return (
        <div className={classes.root}>
            <div className={classes.videoContainer}>
                <Content ref={contentRef} type="video" />
            </div>
            <div className={classes.content}>
                <div className={classes.textContainer}>
                    <Content ref={contentRef} type="text" />
                </div>

                <div className="my-auto flex flex-col flex-justify-center">
                    <Button
                        variant="tertiary"
                        size="small"
                        className={classes.button}
                        onClick={handleLearnMore}
                        data-id={dataIdString + "maybe-later"}
                    >
                        Learn more
                        <OpenInNewRoundedIcon className="ml-4" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default OnboardingFeaturePlaceholder;
