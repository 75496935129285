import FormatIndentDecreaseRounded from "@material-ui/icons/FormatIndentDecreaseRounded";
import FormatIndentIncreaseRounded from "@material-ui/icons/FormatIndentIncreaseRounded";
import {useCallback} from "@workhorse/api/rendering";
import {isArtifactElement, ToolbarGenericProps} from "../api";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";

export const ToolbarIndentation = (props: ToolbarGenericProps) => {
    const {editor, onActionDone} = props;

    const handleIncreaseIndent = useCallback(() => {
        editor.chain().focus().setIndentation().run();
        onActionDone();
    }, []);

    const handleDecreaseIndent = useCallback(() => {
        editor.chain().focus().unsetIndentation().run();
        onActionDone();
    }, []);

    const disabled = isArtifactElement(editor) || editor.isEditable === false;

    return (
        <>
            <ToolbarItem
                tooltip="Increase indentation"
                className={classes.button}
                onClick={handleIncreaseIndent}
                disabled={disabled}
                isMobile={props.isMobile}
            >
                <FormatIndentIncreaseRounded />
            </ToolbarItem>
            <ToolbarItem
                tooltip="Decrease indentation"
                className={classes.button}
                onClick={handleDecreaseIndent}
                disabled={disabled}
                isMobile={props.isMobile}
            >
                <FormatIndentDecreaseRounded />
            </ToolbarItem>
        </>
    );
};
