import {HostType} from "@generated/data";

const routeToHostType = {
    session: HostType.Player,
    user: HostType.User,
    organization: HostType.User,
    workspace: HostType.User,
    designer: HostType.Designer,
    waiting: HostType.Waiting,
    test: HostType.Test,
    memory: HostType.Memory,
    base: HostType.Base,
    feedback: HostType.Feedback,
    templates: HostType.Templates,
    unsubscribe: HostType.Unsubscribe,
    "session-ended": HostType.Sessionended,
    connect: HostType.Connect,
    disconnect: HostType.Disconnect,
    join: HostType.Player,
    contacts: HostType.Contacts,
    resources: HostType.Resources,
    "join-event": HostType.User,
    book: HostType.Book,
    "booked-event": HostType.BookedEvent,
    booking: HostType.Booking,
    "booking-event": HostType.NewBooking,
    bookings: HostType.Bookings,
    events: HostType.Events,
    event: HostType.Event,
    files: HostType.Files,
    rooms: HostType.Rooms,
    room: HostType.Player,
    "choose-organization": HostType.ChooseOrganization,
    "organization-invite": HostType.OrganizationInvite,
    payments: HostType.Payments,
};

export const makeHostTypeFromRoute = (pathnameOrHostType: string, sessionIsPublished?: boolean) => {
    const path = pathnameOrHostType === "/" || pathnameOrHostType === "" ? HostType.Home : pathnameOrHostType;

    let hostType = Object.values(HostType).find((string) => string.toLowerCase() === path.toLowerCase());
    let sessionId: string | null | undefined = null;
    if (!hostType) {
        const pieces = pathnameOrHostType.split("/").filter((x: string) => !!(x || "").trim());
        hostType = typeof pieces[0] == "undefined" ? HostType.Base : routeToHostType[pieces[0].toLowerCase()];
        sessionId = pieces[1] || undefined;
    }
    return hostType;
};
