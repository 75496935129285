import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {notificationIds} from "../NotificationsSection";
import {NotificationSettings} from "@workhorse/providers/UserDeviceSettingsStorageProvider";
import {useTranslation} from "react-i18next";

type SettingsReactionsTogglerProps = {
    toggleNotifications: (notificationId: notificationIds) => void;
    enabled: boolean;
    className?: string;
};

const SettingsReactionsToggler = ({enabled, toggleNotifications, className}: SettingsReactionsTogglerProps) => {
    const {t} = useTranslation();
    const handleOnChange = () => {
        toggleNotifications(notificationIds.REACTIONS);
    };

    return (
        <TogglerContainer
            title={
                <Typography variant="base" fontWeight="bold" color="secondary">
                    {t("player.settings.general.notifications.reactions.title") ?? "Reactions"}
                </Typography>
            }
            secondTitle={
                <Typography color="quaternary" component="span" className="mt-8">
                    {t("player.settings.general.notifications.reactions.description") ??
                        "If checked, you will receive notifications when people react."}
                </Typography>
            }
            checked={enabled}
            name="settingsReaction"
            onChange={handleOnChange}
            togglePlacement="secondTitle"
            label=" "
            className={cls(genericStyles.toggler, genericStyles.withSeparator, className)}
        />
    );
};

export default SettingsReactionsToggler;
