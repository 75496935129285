import LayoutColumn from "@ui/cdk/Layout/LayoutWithSidebar/LayoutColumn";
import LayoutGrid from "@ui/cdk/Layout/LayoutWithSidebar/LayoutGrid";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {useEffect} from "@workhorse/api/rendering";
import {useParams} from "@workhorse/api/routing";
import {WithMobileState} from "@workhorse/declarations";
import {useDesignerIsEditing, useDesignerSessionId} from "@workhorse/providers/DesignerSessionDataProviders";
import {evictLocalQueries} from "@workhorse/providers/HostTypeProvider";
import AgendaTemplateWatchdog from "../AgendaTemplateWatchdog";
import AgendaTemplatesEditorContainer from "../AgendaTemplatesEditor/AgendaTemplatesEditorContainer";
import AgendaTemplatesHeader from "../AgendaTemplatesHeader/AgendaTemplatesHeader";
import AgendaTemplatesListContainer from "../AgendaTemplatesList/AgendaTemplatesListContainer";
import TemplatesLeftMenu from "../TemplatesLeftMenu/TemplatesLeftMenu";
import {TemplatesParams} from "../utils";
import classes from "./style/AgendaTemplatesMain.module.scss";
import {useUserInfo} from "@workhorse/providers/User";
import OnboardingAgendaTemplateDialog from "../AgendaTemplatesOnboardingDialog/AgendaTemplatesOnboardingDialog";
import {useSeenSection} from "@workhorse/api/seen";

type AgendaTemplatesMainProps = WithMobileState;

const AgendaTemplatesMain = (props: AgendaTemplatesMainProps) => {
    const {templateId: templateIdParam} = useParams<TemplatesParams>();

    const templateId = templateIdParam?.replace("?ai-copilot=true", "");

    const designerId = useDesignerSessionId();
    const [isEditMode, toggleEditMode] = useDesignerIsEditing();

    const {activeWorkspace} = useUserInfo();
    const userSeenOnboardingAgendaTemplate = useSeenSection("GLOBALS.userOnboardingAgenda");

    useEffect(() => {
        const sessionIdInDesigner = designer.currentSession()?.id;
        if (sessionIdInDesigner) {
            // This will help us identify in logrocket if the designer session was not cleaned up correctly
            console.error("designer session was not cleaned up correctly", sessionIdInDesigner);
            evictLocalQueries(sessionIdInDesigner);
        }

        designer.state.update({templateMode: true});
        designer.state.setDesignerEditState(true);
        return () => {
            designer.state.update({templateMode: false});
            designer.state.setDesignerEditState(false);
            const sessionIdInDesigner = designer.currentSession()?.id;
            if (sessionIdInDesigner) {
                evictLocalQueries(sessionIdInDesigner, activeWorkspace?.id);
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            if (isEditMode) {
                toggleEditMode(false);
            }
        };
    }, [isEditMode]);

    useEffect(() => {
        return () => {
            apollo.cache.evict({
                id: apollo.cache.identify({
                    __typename: "AgendaTemplate",
                    id: templateId + "_template",
                }),
            });
            apollo.cache.gc();
        };
    }, [templateId]);

    return (
        <div className="fullw fullh flex flex-col flex11-100">
            <AgendaTemplatesHeader />
            <LayoutGrid className={classes.layoutGrid}>
                {templateId ? null : (
                    <LayoutColumn left>
                        <TemplatesLeftMenu />
                    </LayoutColumn>
                )}
                <LayoutColumn noPadding notScrollable right className={templateId && designerId ? classes.layoutGridRight : ""}>
                    {!templateId ? (
                        <AgendaTemplatesListContainer></AgendaTemplatesListContainer>
                    ) : (
                        <AgendaTemplatesEditorContainer
                            templateId={templateId}
                            key={templateId}
                            mobileState={props.mobileState}
                        ></AgendaTemplatesEditorContainer>
                    )}
                </LayoutColumn>
            </LayoutGrid>
            {!userSeenOnboardingAgendaTemplate && <OnboardingAgendaTemplateDialog />}

            {templateId ? <AgendaTemplateWatchdog agendaTemplateId={templateId} /> : null}
        </div>
    );
};

export default AgendaTemplatesMain;
