import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {forwardRef} from "@workhorse/api/rendering";
import MacroTranscriptIcon from "@workhorse/components/MacroTranscriptIcon";
import {completeOnboardingCard} from "@artifacts/conference/ui/LonelySpeakerOnboardingV2";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type TranscriptTogglerProps = {
    artifactId: string;
    myParticipantId: string;
    notInLobby: boolean;
    onToggle: () => void;
    isActive: boolean;
    inDrawer: boolean;
    unreadCount?: number;
    sessionId: string;
};

const TranscriptToggler = forwardRef((props: TranscriptTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {artifactId, notInLobby, onToggle, isActive, unreadCount, inDrawer, sessionId} = props;

    const {mixpanelTrack} = useMixpanelTracker();

    const handleClick = () => {
        completeOnboardingCard(sessionId, "transcript", (type) => mixpanelTrack("frontend-onboarding-lonely-completed", type, "player"));
        onToggle();
    };

    return (
        <ArtifactActionsButton
            badgeContent={(!isActive && unreadCount) || 0}
            onClick={handleClick}
            isActive={isActive}
            MacroIcon={<MacroTranscriptIcon />}
            buttonDataId="player-button-transcript"
            artifactId={artifactId}
            isLobby={!notInLobby}
            inDrawer={inDrawer}
            ref={ref}
        />
    );
});

export default {
    TranscriptToggler,
};
