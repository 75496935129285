import {HTMLProps, useEffect, useState, useRef} from "@workhorse/api/rendering";
import {RemoteTrack} from "livekit-client";
import {useVideoNode} from "../../lib/useVideoNode";
import {useVideoObjectFit} from "../../lib/useVideoObjectFit";
import {useConferenceParticipant} from "../../providers/ConferenceRoomProvider";
import classes from "../../styles/video.module.scss";

interface Props extends Omit<HTMLProps<HTMLVideoElement>, "ref" | "onResize"> {
    objectFit?: boolean;
    onBind?: () => void;
    onUnbind?: () => void;
    onResize?: (width: number, height: number) => void;
    participantId: string;
    isShareScreenVideo?: boolean;
    isAudience?: boolean;
}

export function RemoteVideo(props: Props) {
    const {objectFit, className, onBind, onUnbind, onResize, participantId, isShareScreenVideo, isAudience, ...rest} = props;

    const conferenceParticipant = useConferenceParticipant(participantId);
    const {camera, screen_share, isPausedDueToCongestion} = conferenceParticipant;
    const publication = isShareScreenVideo ? screen_share : camera;

    // // testing
    // const remoteStatsRef: RemoteStatsRef = useRef({});
    // end testing

    const {video, setVideo} = useVideoNode();
    useVideoObjectFit(video);

    useEffect(() => {
        if (!video || !publication) {
            return;
        }

        let currentTrack = publication.track ?? null;

        // // testing
        // let t: NodeJS.Timeout | null = null;

        // const unsetBitrateTracker = () => {
        //     if (t != null) {
        //         clearInterval(t);
        //         t = null;
        //     }
        // };

        // const setBitrateTracker = (track: RemoteTrack) => {
        //     unsetBitrateTracker();
        //     t = setInterval(() => {
        //         const currentBitrate = Math.round(track.currentBitrate / 1024);

        //         remoteStatsRef.current?.setBitrate?.(currentBitrate);
        //     }, 500);
        // };

        function onVideoElChange(this: HTMLVideoElement, event: Event) {
            onResize?.(this.videoWidth, this.videoHeight);
            // remoteStatsRef.current?.setDimensions?.({
            //     height: this.videoHeight,
            //     width: this.videoWidth,
            // });
        }

        const attachTrack = (track: RemoteTrack) => {
            currentTrack?.detach(video);
            currentTrack = track;
            currentTrack.attach(video);
        };

        const detachTrack = () => {
            // // testing
            // unsetBitrateTracker();
            // // end testing
            currentTrack?.detach(video);
            currentTrack = null;
        };

        if (currentTrack) {
            attachTrack(currentTrack);
            // // testing
            // setBitrateTracker(currentTrack);
            // // end testing
        }

        video.addEventListener("resize", onVideoElChange);

        publication.on("subscribed", attachTrack);
        publication.on("unsubscribed", detachTrack);

        return () => {
            detachTrack();
            video.removeEventListener("resize", onVideoElChange);
            publication.off("subscribed", attachTrack);
            publication.off("unsubscribed", detachTrack);
        };
    }, [video, publication, publication?.track]);

    return (
        <>
            <video
                {...rest}
                ref={setVideo}
                autoPlay={true}
                muted={true}
                controls={false}
                playsInline={true}
                disableRemotePlayback={true}
                data-size={isAudience ? "small" : "large"}
                className={`ch-remote-video--${participantId ?? "none"} ${classes.chimeVideo} ${className ?? ""}`}
            />
            {/* <RemoteStats iRef={remoteStatsRef} isAudience={!!isAudience} /> */}
        </>
    );
}
