import PageNotFound from "../base/components/PageNotFound";

const Unauthorized = () => {
    return (
        <div className="fullw flex flex-col flex-center-all full-fixed">
            <PageNotFound title="Unauthorized" secondaryTitle="You don't have access to this page." body="" />
        </div>
    );
};

export default Unauthorized;
