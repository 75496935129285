import Typography from "@ui/cdk/Typography";
import {ReactComponent as OfferEmptyStateGraphic} from "../assets/offer_empty_state_graphic.svg";
import classes from "../styles/OfferEmptyState.module.scss";
import {cls} from "@ui/cdk/util";
import {useTranslation} from "react-i18next";

type OfferEmptyStateProps = {
    isOrganizer: boolean;
};

const OfferEmptyState = (props: OfferEmptyStateProps) => {
    const {isOrganizer} = props;
    const {t} = useTranslation();
    return (
        <div className={cls("flex flex-col fullw fullh flex-align-center flex-justify-center px-20", classes.root)}>
            <div className="mb-32">
                <OfferEmptyStateGraphic />
            </div>
            <Typography variant="lg" fontWeight="bolder" color="primary" className="mb-8">
                {isOrganizer
                    ? t("macro.offers.empty_state.create") ?? "Create a special offer"
                    : t("macro.offers.empty_state.no_offers") ?? "No offers"}
            </Typography>

            <Typography variant="base" color="secondary">
                {isOrganizer
                    ? t("macro.offers.empty_state.entice_participants") ?? "Entice your participants with a special offer."
                    : t("macro.offers.empty_state.no_offers_shared") ?? "No offers have been shared yet."}
            </Typography>
            {isOrganizer && (
                <Typography variant="base" color="secondary">
                    {t("macro.offers.empty_state.show") ?? "Show it on-screen during a live session."}
                </Typography>
            )}
        </div>
    );
};

export default OfferEmptyState;
