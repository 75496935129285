import React, {forwardRef, useImperativeHandle} from "react";
import Menu from "@ui/cdk/Menu";
import Input from "@ui/cdk/Input";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/AgendaGeneratorMenu.module.scss";
import {ReactComponent as ArrowCircleUp} from "../../../assets/media/arrow_circle_up.svg";
import Typography from "@ui/cdk/Typography/Typography";
import GeneratedAgendaPreview from "./GeneratedAgendaPreview";
import Button from "@ui/cdk/Button";
import {useAiGeneratedAgenda} from "@workhorse/providers/AiGeneratedAgendaProvider";
import {useAgendaItems} from "@workhorse/providers/SessionDataProviders";
import {useRef, useState} from "@workhorse/api/rendering";
import AgendaImportConfirmationDialog from "./AgendaImportConfirmationDialog";
import LinearProgress from "@ui/cdk/LinearProgress";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Checkbox from "@ui/cdk/Checkbox";
import {handleCreateOrInsertAiAgenda} from "../utils";
import AiGenerateError from "./AiGenerateError";
import {ReactComponent as AiCopilotFeatured} from "../../../../frontend/assets/media/ai-copilot-featured.svg";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@ui/cdk/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {useTranslation} from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@ui/core/components/IconButton";
import DurationInputMask from "@ui/cdk/DurationInputMask";
import {AIGeneratedAgendaItem} from "../AgendaGeneratorMessenger";
import {Portal} from "@material-ui/core";

type AgendaGeneratorMenuProps = {
    menuAnchorEl: null | HTMLElement | SVGSVGElement;
    visibleInputValue: string;
    startGenerating: boolean;
    onAiInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmitAiInput: () => void;
    onAiPreviewToggle: (e: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>) => void;
    setStartGenerating: (value: boolean) => void;
    generateClicked: boolean;
    setGenerateClicked: (value: boolean) => void;
    numberOfAgendaItems: number;
    onNumberOfAgendaItemsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    agendaDuration: number;
    onAgendaDurationChange: (time: number) => void;
    increaseDuration: (event: React.MouseEvent<HTMLButtonElement>) => void;
    decreaseDuration: (event: React.MouseEvent<HTMLButtonElement>) => void;
    increaseAgendaItems: () => void;
    decreaseAgendaItems: () => void;
    isSingleAgendaItem: boolean;
    mode?: "popup" | "inline";
    handleUseAgendaItem?: (generatedAgendaItem: AIGeneratedAgendaItem) => void;
    inputPortalId?: string;
    classes?: {
        portalContainer?: string;
        inputFormControl?: string;
        input?: string;
        generateButton?: string;
        agendaHeader?: string;
        agendaFooter?: string;
    };
    agendaPlaceholder?: React.ReactNode;
    hideAgendaItemCheckbox?: boolean;
};

const AgendaGeneratorMenu = forwardRef((props: AgendaGeneratorMenuProps, ref) => {
    const {t} = useTranslation();
    const {
        visibleInputValue,
        menuAnchorEl,
        onAiInputChange,
        onSubmitAiInput,
        startGenerating,
        onAiPreviewToggle,
        setStartGenerating,
        generateClicked,
        setGenerateClicked,
        numberOfAgendaItems,
        onNumberOfAgendaItemsChange,
        agendaDuration,
        onAgendaDurationChange,
        increaseDuration,
        decreaseDuration,
        increaseAgendaItems,
        decreaseAgendaItems,
        isSingleAgendaItem,
        handleUseAgendaItem,
        mode = "popup",
        inputPortalId,
        hideAgendaItemCheckbox,
    } = props;
    const dialogImperativeRef = useRef<DialogImperativeRef>();

    const {saveGeneratedAgenda, generatedAgenda, selectedAgendaItems, selectAgendaItems, errorMessage} = useAiGeneratedAgenda();

    const agendaItems = useAgendaItems();

    const isInline = mode === "inline";

    const [replaceAgenda, setReplaceAgenda] = useState<boolean>(true);

    const handleConfirmImport = (event?: React.MouseEvent<HTMLElement>) => {
        if (!isInline) {
            dialogImperativeRef.current?.toggle?.();
        }

        handleCreateOrInsertAiAgenda(agendaItems, generatedAgenda, selectedAgendaItems, replaceAgenda);

        saveGeneratedAgenda(null);
        selectAgendaItems(null);
        setGenerateClicked(false);

        if (event && !isInline) {
            onAiPreviewToggle(event);
        }
    };

    const handleCancelImport = (event: React.MouseEvent<HTMLElement>) => {
        setStartGenerating(false);
        saveGeneratedAgenda(null);
        selectAgendaItems(null);
        setGenerateClicked(false);
        onAiPreviewToggle(event);
    };

    const handleClickOutside = (event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>) => {
        setStartGenerating(false);
        saveGeneratedAgenda(null);
        selectAgendaItems(null);
        setGenerateClicked(false);
        onAiPreviewToggle(event);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmitAiInput();
        }
    };

    const handleImportAgenda = (e) => {
        if (isSingleAgendaItem) {
            handleUseAgendaItem?.(generatedAgenda[0]);
            return;
        }

        if (replaceAgenda && !isInline) {
            dialogImperativeRef.current?.toggle?.();
            setGenerateClicked(false);
        } else {
            handleConfirmImport(e);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return handleImportAgenda;
        },
        [handleImportAgenda]
    );

    const handleDialogClose = () => {
        dialogImperativeRef.current?.toggle?.();
    };

    const onReplaceAgenda = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReplaceAgenda(e.target.checked);
    };

    const handleBlurAgendaDuration = (parsed: number, value?: number, event?: React.FocusEvent<HTMLInputElement>) => {
        onAgendaDurationChange(parsed);
    };

    //somehow Safari allows to type letters in number input, so we need to prevent it
    const onKeyPressNumericInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!e.key.match(/^[0-9]+$/) && e.key !== "Backspace") {
            e.preventDefault();
        }
    };

    const disabledImport = (generatedAgenda && !generatedAgenda.length) || startGenerating;
    const importBtnText = isSingleAgendaItem
        ? t("ai_generator.import_topic", {count: 1})
        : !selectedAgendaItems.length
        ? t("agenda.menu.use_agenda")
        : t("ai_generator.import_topic", {count: selectedAgendaItems.length});

    const content = (
        <>
            <li className={cls("flex flex-col fullw", "p-16", generatedAgenda?.length && classes.header, props.classes?.agendaHeader)}>
                {isInline ? null : (
                    <div className={classes.menuHeader}>
                        <div className="flex flex-row flex-align-center gap-8">
                            <AiCopilotFeatured />
                            <Typography fontWeight="bold" variant="lg" color="primary">
                                {t("g.ai_copilot")}
                            </Typography>
                            <Tooltip placement="right" title={t("ai_generator.info_tooltip_let_ai_create_agenda") ?? ""} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>

                        <CloseIcon className={classes.closeIcon} onClick={handleClickOutside} />
                    </div>
                )}
                {!isSingleAgendaItem && !isInline && (
                    <div className="fullw flex flex-col flex-align-center gap-12 mb-12">
                        <div
                            className={cls(
                                classes.agendaDetailsMenuItem,
                                "fullw flex flex-row flex-align-center flex-justify-between gap-6"
                            )}
                        >
                            <Typography variant="base" color="primary">
                                Agenda items
                            </Typography>
                            <div className="flex flex-row flex-align-center gap-6">
                                <IconButton
                                    className={classes.addRemoveButton}
                                    onClick={decreaseAgendaItems}
                                    disabled={numberOfAgendaItems <= 0}
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <Input
                                    onChange={onNumberOfAgendaItemsChange}
                                    onKeyDown={onKeyPressNumericInput}
                                    value={numberOfAgendaItems <= 0 ? undefined : numberOfAgendaItems}
                                    disabled={startGenerating}
                                    placeholder={"Items" ?? ""}
                                    name="aiGeneratedNumberOfAgendaItems"
                                    type="number"
                                    className={classes.durationInput}
                                    formControlClassName="flex-justify-center"
                                    inputProps={{
                                        "data-private": "lipsum",
                                    }}
                                />
                                <IconButton
                                    className={classes.addRemoveButton}
                                    onClick={increaseAgendaItems}
                                    disabled={numberOfAgendaItems >= 99}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div
                            className={cls(
                                classes.agendaDetailsMenuItem,
                                "fullw flex flex-row flex-align-center flex-justify-between gap-6"
                            )}
                        >
                            <Typography variant="base" color="primary">
                                Duration
                            </Typography>

                            <div className="flex flex-row flex-align-center gap-6">
                                <IconButton className={classes.addRemoveButton} onClick={decreaseDuration} disabled={agendaDuration <= 0}>
                                    <RemoveIcon />
                                </IconButton>

                                <DurationInputMask
                                    autoFocus={false}
                                    showIcon={false}
                                    autoSize
                                    formattedClassName={cls(classes.timeDisplay, agendaDuration === 0 && classes.timeDisplayZero)}
                                    value={agendaDuration}
                                    short
                                    className={cls(classes.timeInput)}
                                    handleBlur={handleBlurAgendaDuration}
                                ></DurationInputMask>
                                <IconButton
                                    className={classes.addRemoveButton}
                                    onClick={increaseDuration}
                                    disabled={agendaDuration >= 86400}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                )}

                {inputPortalId ? (
                    <Portal container={document.getElementById(inputPortalId)}>
                        <div className={cls("flex flex-col gap-10", props.classes?.portalContainer)}>
                            <div className="flex flex-col">
                                <Typography variant="sm" className="mb-2" color="secondary" fontWeight="bold">
                                    Event topic
                                </Typography>

                                <Input
                                    onChange={onAiInputChange}
                                    value={visibleInputValue}
                                    disabled={startGenerating}
                                    placeholder={t("ai_generator.placeholder_2") ?? ""}
                                    classes={{
                                        input: cls(classes.portaledInput, props.classes?.input),
                                    }}
                                    className="mb-8"
                                    inputProps={{"data-private": "lipsum"}}
                                    onKeyDown={handleKeyDown}
                                    rows={1}
                                    multiline
                                    formControlClassName={props.classes?.inputFormControl}
                                />
                            </div>
                            <Button
                                variant={generateClicked ? "secondary" : "primary"}
                                onClick={visibleInputValue ? onSubmitAiInput : undefined}
                                loading={startGenerating}
                                startIcon={
                                    !startGenerating && <ArrowCircleUp className={generateClicked ? classes.tryAgainIcon : undefined} />
                                }
                                className={cls("ml-auto mb-auto", props.classes?.generateButton)}
                                disabled={!visibleInputValue || startGenerating}
                                size="small"
                            >
                                {startGenerating
                                    ? t("ai_generator.generating")
                                    : generateClicked
                                    ? t("ai_generator.try_again")
                                    : t("ai_generator.generate")}
                            </Button>
                        </div>
                    </Portal>
                ) : (
                    <div className={cls(classes.menuItem, "fullw", classes.inputMenuItem, "flex11-100")}>
                        <Input
                            onChange={onAiInputChange}
                            value={visibleInputValue}
                            disabled={startGenerating}
                            placeholder={t("ai_generator.placeholder_2") ?? ""}
                            inputProps={{"data-private": "lipsum"}}
                            onKeyDown={handleKeyDown}
                            rows={generatedAgenda.length > 0 ? 1 : 7}
                            multiline
                        />
                    </div>
                )}

                {inputPortalId ? null : (
                    <div className="fullw flex flex-align-center mt-12">
                        {!generateClicked ? (
                            <Typography className={classes.generateInfo}>{t("ai_generator.incorrect_results_disclaimer")}</Typography>
                        ) : null}
                        <Button
                            variant={generateClicked ? "secondary" : "primary"}
                            onClick={visibleInputValue ? onSubmitAiInput : undefined}
                            loading={startGenerating}
                            startIcon={!startGenerating && <ArrowCircleUp className={generateClicked ? classes.tryAgainIcon : undefined} />}
                            className="ml-auto"
                            disabled={!visibleInputValue || startGenerating}
                            size="small"
                        >
                            {startGenerating
                                ? t("ai_generator.generating")
                                : generateClicked
                                ? t("ai_generator.try_again")
                                : t("ai_generator.generate")}
                        </Button>
                    </div>
                )}
            </li>
            {errorMessage && !generatedAgenda?.length ? (
                <li>
                    <AiGenerateError requestedComponentType="an agenda" />{" "}
                </li>
            ) : null}
            {generatedAgenda?.length ? (
                <li className="flex flex-col overflow-hidden">
                    {!isInline && <div className={cls(classes.separator, classes.dividerTop)} />}
                    <div className={cls("flex flex-col flex11-100 fullh overflow-auto pr-8 ml-16 mr-4")} id="agenda-preview-scroll-section">
                        <GeneratedAgendaPreview
                            generatedAgenda={generatedAgenda}
                            selectAgendaItems={selectAgendaItems}
                            selectedAgendaItems={selectedAgendaItems}
                            isSingleAgendaItem={isSingleAgendaItem}
                            hideCheckbox={hideAgendaItemCheckbox}
                        />
                        {startGenerating && (
                            <div className={cls("flex flex-align-center flex-justify-center fullw my-16", !isSingleAgendaItem && "pl-40")}>
                                <LinearProgress variant="indeterminate" progressHeight={5} />
                            </div>
                        )}
                    </div>

                    <div
                        className={cls(
                            "flex flex-row flex-align-center flex-justify-between sticky-bottom-0 p-16",
                            isSingleAgendaItem ? "flex-justify-end" : "",
                            classes.footer,
                            props.classes?.agendaFooter
                        )}
                    >
                        {!isSingleAgendaItem && !isInline && (
                            <div className="flex flex-row flex-align-center">
                                <Checkbox value={replaceAgenda} onChange={onReplaceAgenda} defaultChecked={true} />
                                <Typography>{t("ai_generator.replace_current_agenda")}</Typography>
                            </div>
                        )}
                        {isInline ? null : (
                            <div className="flex flex-row flex-align-center">
                                <div className={classes.menuItem}>
                                    <Button onClick={handleCancelImport} variant="tertiary" size="small">
                                        {t("g.cancel")}
                                    </Button>
                                </div>
                                <div className={classes.menuItem}>
                                    <Button size="small" onClick={handleImportAgenda} disabled={disabledImport} withMarginLeft>
                                        {importBtnText}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </li>
            ) : props.agendaPlaceholder ? (
                props.agendaPlaceholder
            ) : null}
        </>
    );

    return (
        <div>
            <AgendaImportConfirmationDialog
                imperativeRef={dialogImperativeRef}
                onDialogClose={handleDialogClose}
                onConfirmImport={handleConfirmImport}
            />
            <div className="flex flex-row">
                {mode === "popup" ? (
                    <Menu
                        id="agenda-generator-menu"
                        key="agenda-generator-menu-container"
                        open={Boolean(menuAnchorEl)}
                        anchorEl={menuAnchorEl}
                        variant="menu"
                        onClick={(e) => e.stopPropagation()}
                        onClose={handleClickOutside}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        classes={{
                            paper: cls(classes.tooltip, classes.paper, generatedAgenda?.length && classes.paperWithAgendaItems),
                            list: cls(classes.list),
                        }}
                        className={classes.menu}
                        getContentAnchorEl={null}
                    >
                        {content}
                    </Menu>
                ) : (
                    <div>{content}</div>
                )}
            </div>
        </div>
    );
});

export default AgendaGeneratorMenu;
