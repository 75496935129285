import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useDownloadFile} from "@workhorse/providers/DownloadProvider";
import {format} from "date-fns";
import {FormItem} from "@sessions/forms";
import classes from "../../../contacts/components/groups/styles/ExportCsvDialog.module.scss";
import {RegistrationAnswer} from "../utils";
import {EventParticipant} from "./event-people";
import {CheckoutSessionStatus} from "@generated/data";

type ExportCsvDialogProps = {
    people: EventParticipant[];
    imperativeRef: React.MutableRefObject<DialogImperativeRef>;
    onClose: () => void;
    eventName: string;
    hasRegistration?: boolean;
    formItems?: FormItem[];
    formAnswers?: RegistrationAnswer[];
    includeAttendedStatus?: boolean;
    onDone?: () => void;
    isMemoryMode?: boolean;
    hasPayments?: boolean;
};

function arrayMove(arr: unknown[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

const insert = (arr: unknown[], index: number, newItem: unknown) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
];

function EventPeopleExportCsvDialog(props: ExportCsvDialogProps) {
    const {
        imperativeRef,
        onClose,
        people,
        eventName,
        hasRegistration,
        formItems,
        formAnswers,
        includeAttendedStatus,
        onDone,
        isMemoryMode,
        hasPayments,
    } = props;
    const formItemsCsvHeaders = formItems?.map((item) => item?.question);

    const nameIndex = formItems?.findIndex((item) => item.type === "Name") ?? 0;
    const emailIndex = formItems?.findIndex((item) => item.type === "Email") ?? 0;

    const nameId = formItems?.[nameIndex]?.id;
    const emailId = formItems?.[emailIndex]?.id;

    let orderedAnswers: any[] | undefined = structuredClone(formAnswers);
    orderedAnswers = orderedAnswers?.map((a) => {
        return {
            ...a,
            answers:
                formItems?.map((item) => {
                    const answer = a.answers.find((answer: any) => answer.formItemId === item.id);
                    return answer || {formItemId: item.id, value: ""};
                }) ?? [],
        };
    });

    const formAnswersCsvBody = orderedAnswers?.map((item) => {
        if (item?.answers?.length) {
            // const nameItem = item.answers.find((rowAnswer) => rowAnswer.formItemId === nameId) ?? {formItemId: "", value: ""};
            // const emailItem = item.answers.find((rowAnswer) => rowAnswer.formItemId === emailId);

            const participantFirstName =
                people.find((p) => p.id === item.currentParticipantId || p?.currentParticipantId === item.currentParticipantId)
                    ?.dataWithNullableEmail.firstName ?? "";
            const participantLastName =
                people.find((p) => p.id === item.currentParticipantId || p?.currentParticipantId === item.currentParticipantId)
                    ?.dataWithNullableEmail.lastName ?? "";
            const participantEmail =
                people.find((p) => p.id === item.currentParticipantId || p?.currentParticipantId === item.currentParticipantId)
                    ?.dataWithNullableEmail.email ?? "";
            const nameItem = item.answers.find((rowAnswer) => rowAnswer.formItemId === nameId) ?? {
                    formItemId: "",
                    value: `${participantFirstName + participantLastName}`,
                } ?? {formItemId: "", value: ""};
            const emailItem = item.answers.find((rowAnswer) => rowAnswer.formItemId === emailId) ?? {
                formItemId: "",
                value: participantEmail,
            };

            const rowFiltered = item.answers.filter(
                (rowAnswer) => ![nameItem?.formItemId, emailItem?.formItemId].includes(rowAnswer.formItemId)
            );

            item.answers = insert(rowFiltered, 0, nameItem);
            item.answers = insert(item.answers, 1, emailItem);
        }

        return item?.answers?.map((a, index) => {
            const formItem = formItems?.find((item) => item.id === a?.formItemId);

            const isMultipleAnswers = Array.isArray(a?.value);
            if (isMultipleAnswers || formItem?.type === "SingleChoice" || (formItem?.type === "Dropdown" && typeof a.value === "number")) {
                const values = formItem?.settings.choices.filter((choice, choiceIndex) => {
                    return isMultipleAnswers ? a?.value?.includes(choiceIndex) : a?.value === choiceIndex;
                });
                return values.length ? values.join(";") : "-";
            }

            if (formItem?.type === "Consent" && !a?.value) {
                return "false";
            }

            if (formItem?.type === "Numeric" && !!a?.value) {
                return a.value.toString();
            }

            if (formItem?.type === "Rating" && !!a?.value) {
                return a.value.toString();
            }

            return a?.value || "-";
        });
    });

    const downloadFile = useDownloadFile();

    const handleExportContacts = async () => {
        const data = people;
        let records;

        if (hasRegistration) {
            let csvHeadersProcessed = [...(formItemsCsvHeaders ?? [])];

            const shouldMoveItemsAround = emailIndex !== 1 || nameIndex !== 0;
            if (shouldMoveItemsAround) {
                const nameItem = csvHeadersProcessed.find((item, index) => index === nameIndex) ?? "Name";
                const emailItem = csvHeadersProcessed.find((item, index) => index === emailIndex) ?? "Email";

                const csvHeadersProcessedFiltered = csvHeadersProcessed.filter((item) => ![nameItem, emailItem].includes(item));

                csvHeadersProcessed = insert(csvHeadersProcessedFiltered, 0, nameItem);
                csvHeadersProcessed = insert(csvHeadersProcessed, 1, emailItem);
            }

            csvHeadersProcessed = insert(csvHeadersProcessed, 2, "Role");
            csvHeadersProcessed = insert(csvHeadersProcessed, 3, "Registration Date");

            const processedData = data.map((item, index) => {
                const participantEmail = item.dataWithNullableEmail.email;
                let answersForEmail = formAnswersCsvBody?.find((answer) => answer?.[1] === participantEmail);
                if (answersForEmail) {
                    const country =
                        formAnswers?.find((answer) => {
                            return (
                                // @ts-expect-error
                                answer.registrationId === item.registrationId ||
                                answer.answers.find((a) => a?.formItemId === emailId && a?.value === participantEmail)
                            );
                        })?.country ?? "-";

                    answersForEmail = insert(answersForEmail, 2, item.eventRole);
                    answersForEmail = insert(answersForEmail, 3, format(new Date(item.createdAt), "dd/MM/yyyy"));

                    const paymentStatus = hasPayments
                        ? item.paymentTransaction?.checkoutSessionStatus === CheckoutSessionStatus.CheckoutInitiated
                            ? "Checkout Initiated"
                            : item.paymentTransaction?.checkoutSessionStatus === CheckoutSessionStatus.CheckoutExpired
                            ? "Checkout expired"
                            : item.paymentTransaction?.checkoutSessionStatus === CheckoutSessionStatus.CheckoutCompleted
                            ? "Completed"
                            : "-"
                        : null;

                    return (
                        answersForEmail
                            .map((a) =>
                                a &&
                                (a.toString().includes(",") || a.toString().includes(".") || a.includes("#") || a.toString().includes("\n"))
                                    ? `"${a}"`
                                    : a
                            )
                            .join(",") +
                        `,${country}` +
                        (paymentStatus ? `,${paymentStatus}` : "") +
                        (includeAttendedStatus ? `,${!!item.attended}` : "") +
                        (isMemoryMode
                            ? `,${item?.presenceHistory?.joinedAt ?? "-"}, ${item?.presenceHistory?.leftAt ?? "-"},${
                                  item?.presenceHistory?.totalSpent ?? "-"
                              }`
                            : "") +
                        " \n"
                    );
                } else {
                    return `${item.dataWithNullableEmail.firstName} ${item.dataWithNullableEmail.lastName},${
                        item.dataWithNullableEmail.email
                    },${item.eventRole},${format(new Date(item.createdAt), "dd/MM/yyyy")},${Array.from(
                        Array(csvHeadersProcessed.length - 3).keys()
                    )
                        .map((i) => "-")
                        .join(",")}${hasPayments ? `,-` : ""}${includeAttendedStatus ? `,${!!item.attended}` : ""} ${
                        isMemoryMode ? `,${item.presenceHistory?.joinedAt ?? "-"}` : ""
                    } ${isMemoryMode ? `,${item.presenceHistory?.leftAt ?? "-"}` : ""} ${
                        isMemoryMode ? `,${item.presenceHistory?.totalSpent ?? "-"}` : ""
                    }\n`;
                }
            });

            records =
                `${csvHeadersProcessed
                    .map((h) => (h && (h?.includes(",") || h?.includes(".") || h?.includes("#")) ? `"${h}"` : h))
                    ?.join(",")},Country${hasPayments ? ",Payment status" : ""}${includeAttendedStatus ? ",Attended" : ""}${
                    isMemoryMode ? ",Joined At,Left At,Total Time" : ""
                } \n` + processedData?.join("");
        } else {
            records = data.reduce((prev, current) => {
                return (
                    prev +
                    `${current.dataWithNullableEmail.firstName} ${current.dataWithNullableEmail.lastName}, ${
                        current.dataWithNullableEmail.email
                    }, ${current.eventRole}, ${format(new Date(current.createdAt), "dd/MM/yyyy")}${
                        includeAttendedStatus ? `,${!!current.attended}` : ""
                    }, ${current?.presenceHistory?.joinedAt ?? "-"},${current?.presenceHistory?.leftAt ?? "-"},${
                        current?.presenceHistory?.totalSpent ?? "-"
                    }\n`
                );
            }, `Name,Email,Role,Registration Date${includeAttendedStatus ? ",Attended" : ""},Joined at,Left at,Total time \n`);
        }

        const csvContent = "data:Application/octet-stream," + records;
        const beforeFixEncodedUri = encodeURI(csvContent);
        const encodedUri = beforeFixEncodedUri.replaceAll("#", "%23");

        downloadFile(encodedUri, {
            fileName: `${eventName}_participants.csv`,
        });

        onDone?.();
        onClose();
    };

    return (
        <Dialog
            imperativeRef={imperativeRef}
            onClose={() => {
                onClose();
                onDone?.();
            }}
            BackdropProps={{
                className: classes.backdrop,
            }}
            PaperProps={{
                className: classes.paper,
            }}
        >
            <div className={classes.header}>
                <Typography variant="xl" fontWeight="boldest">
                    Export participants
                </Typography>
            </div>

            <div className={classes.content}>
                <Typography>
                    Export <strong>all participants</strong> in a .csv file?
                </Typography>
            </div>

            <div className={cls("flex flex-justify-end", classes.footer)}>
                <Button
                    className={classes.cancelButton}
                    variant="quaternary"
                    onClick={() => {
                        onClose();
                        onDone?.();
                    }}
                >
                    Cancel
                </Button>
                <Button className="ml-12" onClick={handleExportContacts}>
                    Export
                </Button>
            </div>
        </Dialog>
    );
}

export default EventPeopleExportCsvDialog;
