import {ArrayItem, DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {AgendaItem, Artifact} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";
import {makeId} from "../../utils";
type Property = NonNullable<ArrayItem<DeepMandatoryWithNullVals<Artifact>["properties"]>>;
type InputArtifact = Partial<
    Omit<DeepMandatoryWithNullVals<Artifact>, "data" | "properties"> & {properties: Array<Pick<Property, "key" | "value">>}
>;

type Data = {[K in string]: string};

export function makeArtifactPropertiesAndData(existingProps?: Array<Property>, incomingProps?: Array<Pick<Property, "key" | "value">>) {
    // this is by design... createdAt and updatedAt can never be defined on the client
    const now = null as unknown as Date;
    const existingIndexes: Array<string> = [];
    const newProps = incomingProps
        ?.filter((p, i) => {
            const exists = existingProps?.findIndex((ep) => ep.key === p.key) !== -1;

            if (exists) {
                existingIndexes.push(p.key);
            }
            return !exists;
        })
        .map((p) => ({
            ...p,
            id: makeId(),
            createdAt: now,
            updatedAt: now,
            isDeleted: false,
            __typename: "Property" as const,
            oldId: null,
            update: null,
        }));

    const mergedProps = existingProps
        ?.map((p) => {
            const indexInIncoming = existingIndexes.indexOf(p.key);
            const isUpdate = indexInIncoming !== -1;
            if (isUpdate) {
                return {
                    ...p,

                    value: incomingProps?.[indexInIncoming].value ?? p.value,
                };
            } else return p;
        })
        .concat(newProps ?? []);

    return {
        properties: mergedProps ?? [],
    };
}

export interface UpdateArtifactInput {
    id: string;
    artifact: InputArtifact;
    mockResult?: boolean;
}

export class UpdateArtifact extends DesignerAction<UpdateArtifactInput> {
    commit() {
        const agendaItems = designer.currentAgendaItems()?.slice(0);
        const index = agendaItems.findIndex((a) => a.artifact && a.artifact.id === this.input.id);
        if (index !== -1) {
            const targetAgendaItem = agendaItems[index!];

            let updateData = {
                // TODO: @vasi add oldId to nested relations if present in this.input.artifact
                ...targetAgendaItem.artifact!,
                ...this.input.artifact,
                oldId: targetAgendaItem.artifact?.oldId ?? targetAgendaItem.artifact?.id ?? null,
                ...makeArtifactPropertiesAndData(targetAgendaItem.artifact?.properties, this.input.artifact.properties),
            };

            if (this.input.mockResult !== undefined) {
                if (this.input.mockResult) {
                    updateData = {
                        ...updateData,
                        resourceResults:
                            updateData.resourceResults.map((r) => ({
                                ...r,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            })) ?? [],
                        properties: updateData.properties.map((property) => ({
                            ...property,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                        })),
                    };
                } else {
                    const currentResults = updateData.resourceResults.map((r) => ({
                        id: r.id,
                        createdAt: r.createdAt.getTime(),
                    }));

                    const min = Math.min(...currentResults.map((r) => r.createdAt));
                    const resultToResetId = currentResults.find((r) => r.createdAt === min)?.id;
                    if (resultToResetId || !!updateData.properties?.length) {
                        updateData = {
                            ...updateData,
                            resourceResults:
                                updateData.resourceResults.map((r) =>
                                    r.id === resultToResetId
                                        ? {
                                              ...r,
                                              createdAt: null as unknown as Date,
                                              updatedAt: null as unknown as Date,
                                          }
                                        : r
                                ) ?? [],
                            properties: updateData.properties.map((property) => ({
                                ...property,
                                createdAt: null as unknown as Date,
                                updatedAt: null as unknown as Date,
                            })),
                        };
                    }
                }
            }

            agendaItems[index!] = {
                ...targetAgendaItem,
                artifact: updateData,
            };

            designer.updateCurrentSession(
                {
                    agendaItems,
                },
                {
                    strategy: "replace",
                }
            );
        }

        return this.input.id;
    }
}
