import React, {useMemo} from "@workhorse/api/rendering";
import {AcceptedTimezone, getTimezoneOffset} from "@common/utils/timezones";
import {InputProps} from "../Input/Input";
import TimeRangePicker, {OnTimeRangePickerInputChange} from "./components/TimePicker/TimeRangePicker";
import FormHelperText from "@ui/core/components/FormHelperText";
import {cls} from "@ui/cdk/util/util";
import classes from "./components/styles/TimePicker.module.scss";
import {OnChangeArgs} from "./DatePicker";

type onChangeArgs = {
    startDate: Date;
    endDate: Date;
};
export type DateTimePickerProps = {
    startDate: Date;
    endDate: Date;
    onChange?: (date: onChangeArgs) => void;
    minDuration?: number | null;
    allowDatesInThePast?: boolean;
    small?: boolean;
    fromToLabels?: boolean;
    fullWidth?: boolean;
    variant?: "outlined" | null;
    separators?: boolean;
    label?: InputProps["label"];
    onInputChange?: OnTimeRangePickerInputChange;
    error?: boolean;
    helperText?: string;
    info?: boolean;
    errorOn?: "start" | "end";
    forTimezone: AcceptedTimezone;
    localTimezone: AcceptedTimezone;
    setValuesOnMount?: boolean;
    dateFormat: "12h" | "24h";
    inlineCalendar?: boolean;
    inSessionDialog?: boolean;
    classes?: {
        timeInput?: string;
        dateInput?: string;
        autocompletePaper?: string;
    };
    refStart: React.MutableRefObject<any>;
    refEnd: React.MutableRefObject<any>;
    onCalendarChooseDay: (value: Partial<OnChangeArgs>) => void;
    onClose?: () => void;
    disabled?: boolean;
    datesOnly?: boolean;
};

const TimePicker = (props: DateTimePickerProps) => {
    const {
        startDate /*  = startOfDay(new Date()) */,
        endDate /*  = endOfDay(new Date()) */,
        onChange,
        onInputChange,
        onCalendarChooseDay,
        minDuration,
        allowDatesInThePast = false,
        small,
        fromToLabels,
        inSessionDialog,
        variant = "outlined",
        fullWidth = false,
        separators = false,
        error,
        helperText,
        info,
        label,
        errorOn,
        forTimezone,
        localTimezone,
        setValuesOnMount,
        dateFormat,
        refStart,
        refEnd,
        disabled,
        datesOnly,
    } = props;

    const timezoneDiffInMin = useMemo(() => {
        return forTimezone && localTimezone ? getTimezoneOffset(forTimezone, localTimezone) : 0;
    }, [forTimezone, localTimezone]);

    const onChangeDateFrom = (date: Date) => {
        if (onChange) {
            onChange({
                startDate: date,
                endDate: new Date(date.getTime() + (endDate.getTime() - startDate.getTime())),
            });
        }
    };

    const onChangeDateTo = (date: Date, userSelectedStart?: Date) => {
        if (onChange) {
            onChange({
                startDate: userSelectedStart ?? startDate,
                endDate: date,
            });
        }
    };

    return (
        <div className={cls("flex flex-wrap", classes.picker, fullWidth && classes.fullWidth)}>
            <TimeRangePicker
                minDuration={minDuration}
                small={small}
                dateFormat={dateFormat}
                dateFrom={startDate!}
                dateTo={endDate!}
                setDateFrom={onChangeDateFrom}
                setDateTo={onChangeDateTo}
                onInputChange={onInputChange}
                onCalendarChooseDay={onCalendarChooseDay}
                allowDatesInThePast={allowDatesInThePast}
                disabled={disabled}
                fromToLabels={fromToLabels}
                inSessionDialog={inSessionDialog}
                separators={separators}
                fullWidth={fullWidth}
                label={label}
                errorOn={errorOn}
                offset={timezoneDiffInMin}
                setValuesOnMount={setValuesOnMount}
                inlineCalendar={props.inlineCalendar}
                forTimezone={props.forTimezone}
                localTimezone={props.localTimezone}
                classes={{
                    dateInput: props.classes?.dateInput,
                    timeInput: props.classes?.timeInput,
                    autocompletePaper: props.classes?.autocompletePaper,
                }}
                refStart={refStart}
                refEnd={refEnd}
                onClose={props.onClose}
                datesOnly={datesOnly}
            />
            {(error || info) && helperText ? (
                <FormHelperText className={cls("helper-text", classes.helperText, classes[error ? "error" : "info"])} key="helper">
                    <>{helperText}</>
                </FormHelperText>
            ) : null}
        </div>
    );
};

export default TimePicker;
