import React, {useState, useEffect} from "react";
import Snackbar from "@ui/core/components/Snackbar";
import classes from "./styles/RequestControlNotification.module.scss";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {cls} from "@ui/cdk/util/util";
import toast from "@workhorse/api/toast";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import {useTranslation} from "react-i18next";

interface RequestControlNotificationProps {
    handleRequestControl: () => void;
    firstRequest: boolean;
    onNotificationClose: () => void;
}

const RequestControlNotification = (props: RequestControlNotificationProps) => {
    const {t} = useTranslation();
    const {handleRequestControl, firstRequest} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [progress, setProgress] = useState(100);
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress == 0 ? 100 : prevProgress - 10));
        }, 900);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress === 0) {
            handleCloseSnackBar();
        }
    }, [progress]);

    useEffect(() => {
        setIsOpen(firstRequest);
    }, []);

    const handleCloseSnackBar = () => {
        setIsOpen(false);
        props.onNotificationClose();
    };

    const handleOnControlRequest = () => {
        handleCloseSnackBar();
        toast(t("player.notifications.control_status.request_success") ?? "The request was successfully sent.", {type: "success"});
        handleRequestControl();
    };

    return (
        <Snackbar
            open={isOpen}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            className={classes.root}
            autoHideDuration={9500}
            onClose={handleCloseSnackBar}
            disableWindowBlurListener
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={classes.circularMessageWrapper}>
                    <CircularProgressWithLabel type="long" variant="determinate" thickness={5} value={progress} />

                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            {t("player.notifications.control_status.ask_for_control") ?? "Ask for control?"}
                        </Typography>
                        <Typography variant="base">
                            {t("player.notifications.control_status.ask_for_control_description") ??
                                "If the host allows it, you’ll be able to interact with what’s on screen."}
                        </Typography>
                    </div>
                </div>
                <div className={cls(classes.buttonsContainer)}>
                    <button className={classes.notificationPrimaryButton} onClick={handleOnControlRequest}>
                        {t("player.notifications.control_status.request_control") ?? "Request control"}
                    </button>
                </div>
                <div className={classes.closeIcon} onClick={handleCloseSnackBar}>
                    <CloseRoundedIcon />
                </div>
            </div>
        </Snackbar>
    );
};

export default RequestControlNotification;
