import React from "@workhorse/api/rendering";
import loadable from "@workhorse/api/loadable";
import {RouteHOCProps} from "@workhorse/declarations";

const NotFound = loadable(() => import("./404"));

interface NotFoundHOCProps extends RouteHOCProps<never> {}

/***
 * why the hoc
 * see ../base/index.tsx
 */
function NotFoundHOC(props: NotFoundHOCProps) {
    return (
        <div className="flex flex-col flex-center-all fullw fullh not-found-hoc" key="not-found-hoc">
            <NotFound {...props} />
        </div>
    );
}

export default NotFoundHOC;
