import {cls} from "@ui/cdk/util";
import sectionStyles from "../styles/BackgroundEffectsSection.module.scss";
import genericStyles from "../../../styles/GenericSection.module.scss";
import videoStyles from "../../VideoSection/styles/VideoSection.module.scss";

const styles = {...genericStyles, ...videoStyles, ...sectionStyles};

type ParticipantBackgroundFilterImageSelectProps = {
    preview: string;
    selected: boolean;
    onClick: () => void;
    className?: string;
};

const ParticipantBackgroundFilterImageSelect = (props: ParticipantBackgroundFilterImageSelectProps) => {
    const {preview, selected, onClick, className} = props;

    return (
        <div className={cls(styles.filter, className, selected && "selected")}>
            <div className={styles.filterImage}>
                <img src={preview} alt={"Default Filter Image"} onClick={onClick} />
            </div>
        </div>
    );
};

export default ParticipantBackgroundFilterImageSelect;
