import {useSession} from "@workhorse/providers/SessionDataProviders";
import {TemplatesSectionPaths} from "../../utils";
import AgendaTemplatesEditorHeaderActionsContainer from "./Components/AgendaTemplatesEditorHeaderActionsContainer";
import AgendaTemplatesEditorHeaderBreadcrumbs from "./Components/AgendaTemplatesEditorHeaderBreadcrumbs";

type AgendaTemplatesEditorHeaderProps = {
    templateId: string;
    section: TemplatesSectionPaths;
};

const AgendaTemplatesEditorHeader = (props: AgendaTemplatesEditorHeaderProps) => {
    const session = useSession();

    return (
        <div className="flex flex-justify-between py-5">
            <AgendaTemplatesEditorHeaderBreadcrumbs templateName={session.name} section={props.section} />

            <AgendaTemplatesEditorHeaderActionsContainer templateId={props.templateId} />
        </div>
    );
};

export default AgendaTemplatesEditorHeader;
