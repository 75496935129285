import {FileRef} from "@api/resx/ref";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {VideoV1} from "@sessions/common/resources";
import {FileMimeType} from "@sessions/common/various";
import {ProcessingResx} from "@workhorse/pages/resources/components/ResxFileProgressPreview";
import {createGalleryResource} from "../../../api";
import {ResourceRowExtra} from "../../../utils";
import UploadInputHidden from "./components/UploadInputHidden";
import {modifyResourceCounter} from "./utils";

type GalleryCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    onDoubleClick: (
        resourceId: ResourceRowExtra["id"],
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
        videoType?: VideoV1["type"]
    ) => void;
    onChangeProcessingResx?: (values?: ProcessingResx) => void;
};

const GalleryCreateForm = (props: GalleryCreateFormProps) => {
    const {handleClose, onChangeProcessingResx, type} = props;

    const createResources = async (uploadedFiles: FileRef[]) => {
        if (uploadedFiles.length > 0) {
            const resxName = uploadedFiles.length > 1 ? "Gallery" : uploadedFiles[0].fileName || "Gallery";

            const res = await createGalleryResource(resxName, uploadedFiles);

            onChangeProcessingResx?.({
                id: res.id,
                type: "flowos/gallery/resx/Gallery",
                isUploading: false,
            });

            modifyResourceCounter("flowos/gallery/resx/Gallery", "add", true);

            handleClose();
        }
    };

    return (
        <UploadInputHidden
            acceptedTypes={[FileMimeType.Image]}
            acceptedTypesText={"images"}
            dropDescriptionText={"You can upload .jpg, .png, .svg directly from your computer."}
            createResources={createResources}
            onClose={handleClose}
            multipleUpload={true}
            onChangeProcessingResx={onChangeProcessingResx}
            type={type}
        />
    );
};
export default GalleryCreateForm;
