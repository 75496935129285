import {rbac} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";

/**
 * IMPORTANT!!!
 * do not use this unless you're rendering a list of participants and you want to know which is an admin
 * YOUR ROLE AS AN ADMIN... comes from somewhere else
 * @param participant | null | undefined
 * @returns boolean
 */
export function participantIsAdmin(participant?: Participant | null) {
    return !participant ? false : participant.isOwner;
}

/**
 * IMPORTANT!!!
 * do not use this unless you're rendering a list of participants and you want to know which of them are assitants
 * YOUR ROLE AS AN ASSISTANT... comes from somewhere else
 * @param participant | null | undefined
 * @returns boolean
 */
export function participantIsAssistant(participant?: Participant | null) {
    return !participant || !participant.rbac ? false : rbac(participant, "session.isAssistant");
}
