export * from "./contacts";
export * from "./timezones";

export const safelyParseJSON = (json: string, defaultValue: any) => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return defaultValue;
    }
};

export const defaultVideoTileBackgroundColor = "#344054";

export const customFirstLastName = (firstName: string, lastName: string) => {
    let customFirstName = "";
    let customLastName = "";

    if (lastName) {
        customFirstName = firstName;
        customLastName = lastName;
    } else if (firstName) {
        customFirstName = firstName.split(" ")[0];
        customLastName = firstName.split(" ")[1] || "";
    }

    return {customFirstName, customLastName};
};
