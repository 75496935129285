import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import Typography from "@ui/cdk/Typography";
import {AgendaItemSpeaker} from "@generated/data";
import {useParticipants} from "@workhorse/providers/SessionDataProviders";
import classes from "./style/EventAgendaItemAddedSpeakers.module.scss";

type Props = {
    speaker: AgendaItemSpeaker;
    variant?: "default" | "small";
};

const EventAgendaItemAddedSpeakers = (props: Props) => {
    const {speaker, variant} = props;
    const participants = useParticipants();
    const currentParticipant = participants.find((p) => p.speakerDetails?.id === speaker.speakerId);
    const speakerAvatar = currentParticipant?.speakerDetails?.thumbnailUrl || currentParticipant?.speakerDetails?.photoUrl || undefined;

    return (
        <div className="flex flex-row">
            <ProfilePicture
                avatar={speakerAvatar}
                avatarVariant="rounded"
                avatarSize="S"
                firstName={currentParticipant?.dataWithNullableEmail.firstName}
                lastName={currentParticipant?.dataWithNullableEmail.lastName}
                className={variant === "small" ? classes.variantSmall : classes.variantDefault}
                email={currentParticipant?.dataWithNullableEmail.email}
                withWhiteBorder={variant === "small"}
            />
            {variant !== "small"
                ? (currentParticipant?.dataWithNullableEmail.firstName || currentParticipant?.dataWithNullableEmail.lastName) && (
                      <Typography fontWeight="bolder" color="secondary" noWrap>
                          {currentParticipant?.dataWithNullableEmail.firstName + " " + currentParticipant?.dataWithNullableEmail.lastName}
                      </Typography>
                  )
                : null}
        </div>
    );
};

export default EventAgendaItemAddedSpeakers;
