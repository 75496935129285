import BrandingLogo from "@ui/cdk/BrandingLogo";
import {cls} from "@ui/cdk/util";
import UserProfileMenu from "@workhorse/components/header/UserProfileMenu";
import {useHistory} from "react-router-dom";
import CelloButton from "../CelloButton";
import classes from "./styles/NavigationHeader.module.scss";

const NavigationHeader = () => {
    const history = useHistory();

    const navigateTo = (url: string) => {
        history.push(url);
    };

    return (
        <div className={cls(classes.container, classes.container)}>
            <BrandingLogo variant="dark" size="small" className={classes.logo} onClick={navigateTo.bind(null, "/")} />

            <div className="flex flex-items-center">
                <CelloButton className={classes.celloButton} />
                <UserProfileMenu className="ml-12" />
            </div>
        </div>
    );
};

export default NavigationHeader;
