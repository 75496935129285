import {LimitedFeature} from "./features";

export enum OrganizationPlan {
    AppsumoTier_1 = "APPSUMO_TIER_1",
    AppsumoTier_2 = "APPSUMO_TIER_2",
    AppsumoTier_3 = "APPSUMO_TIER_3",
    AppsumoTier_4 = "APPSUMO_TIER_4",
    AppsumoTier_5 = "APPSUMO_TIER_5",
    Business = "BUSINESS",
    Free = "FREE",
    LtdTier_1 = "LTD_TIER_1",
    LtdTier_2 = "LTD_TIER_2",
    LtdTier_3 = "LTD_TIER_3",
    LtdTier_4 = "LTD_TIER_4",
    LtdTier_5 = "LTD_TIER_5",
    LtdTier_6 = "LTD_TIER_6",
    Pro = "PRO",
    ProLtd = "PRO_LTD",
    ProLtdSaaszilla = "PRO_LTD_SAASZILLA",
    Solo = "SOLO",
    Starter = "STARTER",
    SessionsSupporter = "SESSIONS_SUPPORTER",
}

export const limitationsByFeatures = (feature: LimitedFeature) => {
    switch (feature) {
        case LimitedFeature.RECORDING:
            return recordingLimitByPlan(1);
        case LimitedFeature.LIVESTREAM:
            return livestreamLimitByPlan;
        case LimitedFeature.COPILOT:
            return isCopilotEnabledByPlan;
        case LimitedFeature.ORGANIZATION_MEMBERS:
            return maximumNumberOfMembersInOrganization;
        case LimitedFeature.MEMORY:
            return memoryHistoryLimitationByPlanInDays;
        case LimitedFeature.EVENTS:
            return maximumNumberOfEventsPerPlan;
        case LimitedFeature.SESSION_LENGTH:
            return sessionLengthLimitationByPlanInSeconds;
        case LimitedFeature.BRANDING:
            return isCustomBrandingEnabledByPlan;
        case LimitedFeature.PARTICIPANTS_IN_SESSION:
            return maximumParticipantsInSessionForPlan;
        case LimitedFeature.PUBLIC_API:
            return isPublicApiEnabledByPlan;
        case LimitedFeature.ROOMS:
            return roomsLimitByPlan;
        case LimitedFeature.BOOKING:
            return bookingLimitByPlan;
        case LimitedFeature.MY_PRODUCT_TOOL:
            return myProductLimitByPlan;
        case LimitedFeature.TRANSCRIPT:
            return isTranscriptEnabledByPlan;
        case LimitedFeature.CNAME:
            return maximumCnamesByPlan;
        case LimitedFeature.CUSTOM_SMTP_SERVER:
            return maximumCustomSmtpServersByPlan;
        case LimitedFeature.CUSTOM_MESSAGE_EXTERNAL_PROVIDER:
            return maximumCustomMessageExternalProvidersByPlan;
        case LimitedFeature.HUBSPOT:
            return isHubspotEnabledByPlan;
        case LimitedFeature.PABLY:
            return isPablyEnabledByPlan;
        case LimitedFeature.ZAPIER:
            return isZapierEnabledByPlan;
        case LimitedFeature.CUSTOM_EMAIL:
            return isCustomEmailEnabledByPlan;
        case LimitedFeature.WEBHOOKS:
            return areWebhooksEnabledByPlan;
        case LimitedFeature.WHITE_LABELING:
            return isWhiteLabelingEnabledByPlan;
        case LimitedFeature.WORKSPACES:
            return workspacesByPlan;
        case LimitedFeature.EXIT_PAGE_OFFER:
            return isExitPageOfferEnabledByPlan;
        case LimitedFeature.EXIT_PAGE_FEEDBACK:
            return isExitPageFeedbackEnabledByPlan;
        case LimitedFeature.AFTER_REGISTRATION_ACTION:
            return isAfterRegistrationActionEnabledByPlan;
        case LimitedFeature.OFFER_WIDGET:
            return isOfferWidgetEnabledByPlan;
        case LimitedFeature.STRIPE:
            return isStripeEnabledByPlan;
        case LimitedFeature.PAYPAL:
            return isPayPalEnabledByPlan;
        case LimitedFeature.PAYMENTS:
            return isPaymentsEnabled;
        default:
            return {};
    }
};

export const sessionLengthLimitationByPlanInSeconds: Record<OrganizationPlan, number> = {
    FREE: 1 * 60 * 45, // 45 minutes
    SOLO: 4 * 60 * 60, // 4 hours
    STARTER: 4 * 60 * 60, // 4 hours
    PRO: 12 * 60 * 60, // 12 hours
    BUSINESS: 24 * 60 * 60, // 24 hours
    PRO_LTD: 8 * 60 * 60, // 12 hours
    PRO_LTD_SAASZILLA: 24 * 60 * 60, // 24 hours
    APPSUMO_TIER_1: 3 * 60 * 60, // 3 hours
    APPSUMO_TIER_2: 6 * 60 * 60, // 6 hours
    APPSUMO_TIER_3: 12 * 60 * 60, // 12 hours
    APPSUMO_TIER_4: 12 * 60 * 60, // 12 hours
    APPSUMO_TIER_5: 12 * 60 * 60, // 12 hours
    LTD_TIER_1: 3 * 60 * 60, // 3 hours
    LTD_TIER_2: 6 * 60 * 60, // 6 hours
    LTD_TIER_3: 12 * 60 * 60, // 12 hours
    LTD_TIER_4: 12 * 60 * 60, // 12 hours
    LTD_TIER_5: 12 * 60 * 60, // 12 hours
    LTD_TIER_6: 24 * 60 * 60, // 24 hours
    SESSIONS_SUPPORTER: 24 * 60 * 60, // 24 hours
};

export const maximumParticipantsInSessionForPlan: Record<OrganizationPlan, number> = {
    FREE: 3,
    SOLO: 50,
    STARTER: 50,
    PRO: 500,
    BUSINESS: 3000,
    PRO_LTD: 250,
    PRO_LTD_SAASZILLA: 500,
    APPSUMO_TIER_1: 45,
    APPSUMO_TIER_2: 90,
    APPSUMO_TIER_3: 150,
    APPSUMO_TIER_4: 300,
    APPSUMO_TIER_5: 1000,
    LTD_TIER_1: 45,
    LTD_TIER_2: 90,
    LTD_TIER_3: 150,
    LTD_TIER_4: 300,
    LTD_TIER_5: 1000,
    LTD_TIER_6: 3000,
    SESSIONS_SUPPORTER: 5000,
};

export const maximumNumberOfEventsPerPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 1,
    STARTER: 3,
    PRO: 20,
    BUSINESS: 50,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: 1,
    APPSUMO_TIER_2: 3,
    APPSUMO_TIER_3: 6,
    APPSUMO_TIER_4: 20,
    APPSUMO_TIER_5: 50,
    LTD_TIER_1: 1,
    LTD_TIER_2: 3,
    LTD_TIER_3: 6,
    LTD_TIER_4: 20,
    LTD_TIER_5: 50,
    LTD_TIER_6: 100,
    SESSIONS_SUPPORTER: 200,
};

export const recordingLimitByPlan: (members: number, extraHours?: number) => Record<OrganizationPlan, number> = (members, extraHours) => ({
    FREE: 0,
    SOLO: 20 * 60 * 60 * 1000, // 20 hours
    STARTER: 30 * 60 * 60 * 1000 + (members > 1 ? members * 36000000 : 0) + (extraHours ?? 0) * 3600000,
    PRO: Infinity,
    BUSINESS: Infinity,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: Infinity,
    APPSUMO_TIER_2: Infinity,
    APPSUMO_TIER_3: Infinity,
    APPSUMO_TIER_4: Infinity,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: Infinity,
    LTD_TIER_2: Infinity,
    LTD_TIER_3: Infinity,
    LTD_TIER_4: Infinity,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
});

export const livestreamLimitByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 3 * 60 * 60 * 1000, // 3 hours
    STARTER: 9 * 60 * 60 * 1000,
    PRO: 60 * 60 * 60 * 1000,
    BUSINESS: 150 * 60 * 60 * 1000,
    PRO_LTD: 60 * 60 * 60 * 1000,
    PRO_LTD_SAASZILLA: 60 * 60 * 60 * 1000,
    APPSUMO_TIER_1: 3 * 60 * 60 * 1000,
    APPSUMO_TIER_2: 10 * 60 * 60 * 1000,
    APPSUMO_TIER_3: 15 * 60 * 60 * 1000,
    APPSUMO_TIER_4: 30 * 60 * 60 * 1000,
    APPSUMO_TIER_5: 70 * 60 * 60 * 1000,
    LTD_TIER_1: 3 * 60 * 60 * 1000,
    LTD_TIER_2: 10 * 60 * 60 * 1000,
    LTD_TIER_3: 15 * 60 * 60 * 1000,
    LTD_TIER_4: 30 * 60 * 60 * 1000,
    LTD_TIER_5: 70 * 60 * 60 * 1000,
    LTD_TIER_6: 140 * 60 * 60 * 1000,
    SESSIONS_SUPPORTER: 200 * 60 * 60 * 1000,
};
export const isCloudRecordingEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isCustomBrandingEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};
export const isWhiteLabelingEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: false,
    STARTER: false,
    PRO: false,
    BUSINESS: true,
    PRO_LTD: false,
    PRO_LTD_SAASZILLA: false,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: false,
    APPSUMO_TIER_3: false,
    APPSUMO_TIER_4: false,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: false,
    LTD_TIER_2: false,
    LTD_TIER_3: false,
    LTD_TIER_4: false,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const bookingLimitByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 3,
    STARTER: 3,
    PRO: Infinity,
    BUSINESS: Infinity,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: Infinity,
    APPSUMO_TIER_2: Infinity,
    APPSUMO_TIER_3: Infinity,
    APPSUMO_TIER_4: Infinity,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: Infinity,
    LTD_TIER_2: Infinity,
    LTD_TIER_3: Infinity,
    LTD_TIER_4: Infinity,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
};

export const roomsLimitByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 3,
    STARTER: 3,
    PRO: Infinity,
    BUSINESS: Infinity,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: Infinity,
    APPSUMO_TIER_2: Infinity,
    APPSUMO_TIER_3: Infinity,
    APPSUMO_TIER_4: Infinity,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: Infinity,
    LTD_TIER_2: Infinity,
    LTD_TIER_3: Infinity,
    LTD_TIER_4: Infinity,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
};

export const isCopilotEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: false,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const maximumNumberOfMembersInOrganization: Record<OrganizationPlan, number> = {
    FREE: 1,
    SOLO: 1,
    STARTER: 2,
    PRO: 20,
    BUSINESS: 200,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: 15,
    APPSUMO_TIER_2: 30,
    APPSUMO_TIER_3: 50,
    APPSUMO_TIER_4: 75,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: 15,
    LTD_TIER_2: 30,
    LTD_TIER_3: 50,
    LTD_TIER_4: 75,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
};
export const memoryHistoryLimitationByPlanInDays: Record<OrganizationPlan, number> = {
    FREE: 7,
    SOLO: 90,
    STARTER: 90,
    PRO: Infinity,
    BUSINESS: Infinity,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: 90,
    APPSUMO_TIER_2: Infinity,
    APPSUMO_TIER_3: Infinity,
    APPSUMO_TIER_4: Infinity,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: 90,
    LTD_TIER_2: Infinity,
    LTD_TIER_3: Infinity,
    LTD_TIER_4: Infinity,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
};

export const areWebhooksEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: false,
    STARTER: false,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: false,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: false,
    LTD_TIER_2: false,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isPublicApiEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: true,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const myProductLimitByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 1,
    STARTER: 1,
    PRO: Infinity,
    BUSINESS: Infinity,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: Infinity,
    APPSUMO_TIER_2: Infinity,
    APPSUMO_TIER_3: Infinity,
    APPSUMO_TIER_4: Infinity,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: Infinity,
    LTD_TIER_2: Infinity,
    LTD_TIER_3: Infinity,
    LTD_TIER_4: Infinity,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
};

export const isHubspotEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: false,
    STARTER: false,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: false,
    APPSUMO_TIER_3: false,
    APPSUMO_TIER_4: false,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: false,
    LTD_TIER_2: false,
    LTD_TIER_3: false,
    LTD_TIER_4: false,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isZapierEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: false,
    STARTER: false,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: false,
    APPSUMO_TIER_3: false,
    APPSUMO_TIER_4: false,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: false,
    LTD_TIER_2: false,
    LTD_TIER_3: false,
    LTD_TIER_4: false,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isPablyEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: false,
    STARTER: false,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: false,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: false,
    LTD_TIER_2: false,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isTranscriptEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const maximumCnamesByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 1,
    STARTER: 1,
    PRO: 3,
    BUSINESS: 5,
    PRO_LTD: 3,
    PRO_LTD_SAASZILLA: 3,
    APPSUMO_TIER_1: 1,
    APPSUMO_TIER_2: 1,
    APPSUMO_TIER_3: 3,
    APPSUMO_TIER_4: 4,
    APPSUMO_TIER_5: 5,
    LTD_TIER_1: 1,
    LTD_TIER_2: 1,
    LTD_TIER_3: 3,
    LTD_TIER_4: 4,
    LTD_TIER_5: 5,
    LTD_TIER_6: 10,
    SESSIONS_SUPPORTER: 20,
};

export const maximumCustomSmtpServersByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 1,
    STARTER: 1,
    PRO: 3,
    BUSINESS: 5,
    PRO_LTD: 3,
    PRO_LTD_SAASZILLA: 3,
    APPSUMO_TIER_1: 1,
    APPSUMO_TIER_2: 1,
    APPSUMO_TIER_3: 3,
    APPSUMO_TIER_4: 4,
    APPSUMO_TIER_5: 5,
    LTD_TIER_1: 1,
    LTD_TIER_2: 1,
    LTD_TIER_3: 3,
    LTD_TIER_4: 4,
    LTD_TIER_5: 5,
    LTD_TIER_6: 10,
    SESSIONS_SUPPORTER: 20,
};

export const maximumCustomMessageExternalProvidersByPlan: Record<OrganizationPlan, number> = {
    FREE: 0,
    SOLO: 1,
    STARTER: 1,
    PRO: 3,
    BUSINESS: 5,
    PRO_LTD: 3,
    PRO_LTD_SAASZILLA: 3,
    APPSUMO_TIER_1: 1,
    APPSUMO_TIER_2: 1,
    APPSUMO_TIER_3: 3,
    APPSUMO_TIER_4: 4,
    APPSUMO_TIER_5: 5,
    LTD_TIER_1: 1,
    LTD_TIER_2: 1,
    LTD_TIER_3: 3,
    LTD_TIER_4: 4,
    LTD_TIER_5: 5,
    LTD_TIER_6: 10,
    SESSIONS_SUPPORTER: 20,
};

export const isMessageExternalProvidersEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isCustomSmtpServerEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isCustomEmailEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isExitPageOfferEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isExitPageFeedbackEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: true,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isAfterRegistrationActionEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isOfferWidgetEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const extraTeamMembersByPlan: Record<OrganizationPlan, number | null> = {
    FREE: 0,
    SOLO: 0,
    STARTER: 9,
    PRO: 80,
    BUSINESS: null,
    PRO_LTD: null,
    PRO_LTD_SAASZILLA: null,
    APPSUMO_TIER_1: null,
    APPSUMO_TIER_2: null,
    APPSUMO_TIER_3: null,
    APPSUMO_TIER_4: null,
    APPSUMO_TIER_5: null,
    LTD_TIER_1: null,
    LTD_TIER_2: null,
    LTD_TIER_3: null,
    LTD_TIER_4: null,
    LTD_TIER_5: null,
    LTD_TIER_6: null,
    SESSIONS_SUPPORTER: null,
};

export const workspacesByPlan: Record<OrganizationPlan, number> = {
    FREE: 1,
    SOLO: 1,
    STARTER: 5,
    PRO: 25,
    BUSINESS: 50,
    PRO_LTD: 25,
    PRO_LTD_SAASZILLA: 25,
    APPSUMO_TIER_1: 2,
    APPSUMO_TIER_2: 5,
    APPSUMO_TIER_3: 10,
    APPSUMO_TIER_4: 25,
    APPSUMO_TIER_5: 50,
    LTD_TIER_1: 2,
    LTD_TIER_2: 5,
    LTD_TIER_3: 10,
    LTD_TIER_4: 25,
    LTD_TIER_5: 50,
    LTD_TIER_6: 100,
    SESSIONS_SUPPORTER: 200,
};

export const sessionsPerMonthByPlan: Record<OrganizationPlan, number> = {
    FREE: Infinity,
    SOLO: Infinity,
    STARTER: Infinity,
    PRO: Infinity,
    BUSINESS: Infinity,
    PRO_LTD: Infinity,
    PRO_LTD_SAASZILLA: Infinity,
    APPSUMO_TIER_1: Infinity,
    APPSUMO_TIER_2: Infinity,
    APPSUMO_TIER_3: Infinity,
    APPSUMO_TIER_4: Infinity,
    APPSUMO_TIER_5: Infinity,
    LTD_TIER_1: Infinity,
    LTD_TIER_2: Infinity,
    LTD_TIER_3: Infinity,
    LTD_TIER_4: Infinity,
    LTD_TIER_5: Infinity,
    LTD_TIER_6: Infinity,
    SESSIONS_SUPPORTER: Infinity,
};

export const everGreenPerMonthByPlan: Record<OrganizationPlan, string> = {
    FREE: "Coming soon",
    SOLO: "Coming soon",
    STARTER: "Coming soon",
    PRO: "Coming soon",
    BUSINESS: "Coming soon",
    PRO_LTD: "Coming soon",
    PRO_LTD_SAASZILLA: "Coming soon",
    APPSUMO_TIER_1: "Coming soon",
    APPSUMO_TIER_2: "Coming soon",
    APPSUMO_TIER_3: "Coming soon",
    APPSUMO_TIER_4: "Coming soon",
    APPSUMO_TIER_5: "Coming soon",
    LTD_TIER_1: "Coming soon",
    LTD_TIER_2: "Coming soon",
    LTD_TIER_3: "Coming soon",
    LTD_TIER_4: "Coming soon",
    LTD_TIER_5: "Coming soon",
    LTD_TIER_6: "Coming soon",
    SESSIONS_SUPPORTER: "Coming soon",
};

export const onDemandWebinarsPerMonthByPlan: Record<OrganizationPlan, string> = {
    FREE: "Coming soon",
    SOLO: "Coming soon",
    STARTER: "Coming soon",
    PRO: "Coming soon",
    BUSINESS: "Coming soon",
    PRO_LTD: "Coming soon",
    PRO_LTD_SAASZILLA: "Coming soon",
    APPSUMO_TIER_1: "Coming soon",
    APPSUMO_TIER_2: "Coming soon",
    APPSUMO_TIER_3: "Coming soon",
    APPSUMO_TIER_4: "Coming soon",
    APPSUMO_TIER_5: "Coming soon",
    LTD_TIER_1: "Coming soon",
    LTD_TIER_2: "Coming soon",
    LTD_TIER_3: "Coming soon",
    LTD_TIER_4: "Coming soon",
    LTD_TIER_5: "Coming soon",
    LTD_TIER_6: "Coming soon",
    SESSIONS_SUPPORTER: "Coming soon",
};

export const allFeaturesEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: true,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const featuresEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const breakoutRoomsEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const onDemandWebinarsEnabledByPlan = featuresEnabledByPlan;
export const customLandingPagesEnabledByPlan = featuresEnabledByPlan;
export const permanentMeetingRoomsEnabledByPlan = featuresEnabledByPlan;
export const bookingsEnabledByPlan = featuresEnabledByPlan;

export const chatEnabledByPlan = allFeaturesEnabledByPlan;
export const whiteboardsEnabledByPlan = allFeaturesEnabledByPlan;
export const pollsEnabledByPlan = allFeaturesEnabledByPlan;
export const takeawaysEnabledByPlan = allFeaturesEnabledByPlan;
export const offersEnabledByPlan = featuresEnabledByPlan;
export const calendarEnabledByPlan = allFeaturesEnabledByPlan;
export const memoryEnabledByPlan = allFeaturesEnabledByPlan;
export const videoConferencingEnabledByPlan = allFeaturesEnabledByPlan;
export const cloudRecordingEnabledByPlan = featuresEnabledByPlan;
export const embedFilesToolsAppsEnabledByPlan = allFeaturesEnabledByPlan;
export const interactiveAgendasEnabledByPlan = allFeaturesEnabledByPlan;
export const webinarsEnabledByPlan = featuresEnabledByPlan;
export const evergreenWebinarsEnabledByPlan = featuresEnabledByPlan;

export const coBrowsingEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const liveStreamingEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const teamRoutingEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const analyticsTypeByPlan: Record<OrganizationPlan, string> = {
    FREE: "Standard",
    SOLO: "Standard",
    STARTER: "Standard",
    PRO: "Advanced",
    BUSINESS: "Custom",
    PRO_LTD: "Advanced",
    PRO_LTD_SAASZILLA: "Advanced",
    APPSUMO_TIER_1: "Standard",
    APPSUMO_TIER_2: "Standard",
    APPSUMO_TIER_3: "Standard",
    APPSUMO_TIER_4: "Standard",
    APPSUMO_TIER_5: "Advanced",
    LTD_TIER_1: "Standard",
    LTD_TIER_2: "Standard",
    LTD_TIER_3: "Standard",
    LTD_TIER_4: "Standard",
    LTD_TIER_5: "Advanced",
    LTD_TIER_6: "Advanced",
    SESSIONS_SUPPORTER: "Advanced",
};

export const customIntegrationsEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: false,
    STARTER: false,
    PRO: false,
    BUSINESS: true,
    PRO_LTD: false,
    PRO_LTD_SAASZILLA: false,
    APPSUMO_TIER_1: false,
    APPSUMO_TIER_2: false,
    APPSUMO_TIER_3: false,
    APPSUMO_TIER_4: false,
    APPSUMO_TIER_5: false,
    LTD_TIER_1: false,
    LTD_TIER_2: false,
    LTD_TIER_3: false,
    LTD_TIER_4: false,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const onBoardingByPlan: Record<OrganizationPlan, string> = {
    FREE: "Self onboarding",
    SOLO: "Self onboarding",
    STARTER: "Lite onboarding",
    PRO: "Lite onboarding",
    BUSINESS: "Full onboarding",
    PRO_LTD: "Full onboarding",
    PRO_LTD_SAASZILLA: "Full onboarding",
    APPSUMO_TIER_1: "Full onboarding",
    APPSUMO_TIER_2: "Full onboarding",
    APPSUMO_TIER_3: "Full onboarding",
    APPSUMO_TIER_4: "Full onboarding",
    APPSUMO_TIER_5: "Full onboarding",
    LTD_TIER_1: "Full onboarding",
    LTD_TIER_2: "Full onboarding",
    LTD_TIER_3: "Full onboarding",
    LTD_TIER_4: "Full onboarding",
    LTD_TIER_5: "Full onboarding",
    LTD_TIER_6: "Full onboarding",
    SESSIONS_SUPPORTER: "Full onboarding",
};

export const supportChannelsByPlan: Record<OrganizationPlan, string> = {
    FREE: "Email <span class='block lighter hide-medium'>48 hours max response time</span>",
    SOLO: "Email <span class='block lighter hide-medium'>48 hours max response time</span>",
    STARTER: "Email <span class='block lighter hide-medium'>48 hours max response time</span>",
    PRO: "Email <span class='block lighter hide-medium'>48 hours max response time</span>",
    BUSINESS: "Email + Live Chat",
    PRO_LTD: "Email + Live Chat",
    PRO_LTD_SAASZILLA: "Email + Live Chat",
    APPSUMO_TIER_1: "Email + Live Chat",
    APPSUMO_TIER_2: "Email + Live Chat",
    APPSUMO_TIER_3: "Email + Live Chat",
    APPSUMO_TIER_4: "Email + Live Chat",
    APPSUMO_TIER_5: "Email + Live Chat",
    LTD_TIER_1: "Email + Live Chat",
    LTD_TIER_2: "Email + Live Chat",
    LTD_TIER_3: "Email + Live Chat",
    LTD_TIER_4: "Email + Live Chat",
    LTD_TIER_5: "Email + Live Chat",
    LTD_TIER_6: "Email + Live Chat",
    SESSIONS_SUPPORTER: "Email + Live Chat",
};

export const customerSuccessManagerByPlan: Record<OrganizationPlan, string> = {
    FREE: "",
    SOLO: "",
    STARTER: "",
    PRO: "",
    BUSINESS: "Weekly walk-in sessions",
    PRO_LTD: "Weekly walk-in sessions",
    PRO_LTD_SAASZILLA: "Weekly walk-in sessions",
    APPSUMO_TIER_1: "",
    APPSUMO_TIER_2: "",
    APPSUMO_TIER_3: "",
    APPSUMO_TIER_4: "",
    APPSUMO_TIER_5: "",
    LTD_TIER_1: "",
    LTD_TIER_2: "",
    LTD_TIER_3: "",
    LTD_TIER_4: "",
    LTD_TIER_5: "",
    LTD_TIER_6: "",
    SESSIONS_SUPPORTER: "",
};

export const isStripeEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isPayPalEnabledByPlan: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};

export const isPaymentsEnabled: Record<OrganizationPlan, boolean> = {
    FREE: false,
    SOLO: true,
    STARTER: true,
    PRO: true,
    BUSINESS: true,
    PRO_LTD: true,
    PRO_LTD_SAASZILLA: true,
    APPSUMO_TIER_1: true,
    APPSUMO_TIER_2: true,
    APPSUMO_TIER_3: true,
    APPSUMO_TIER_4: true,
    APPSUMO_TIER_5: true,
    LTD_TIER_1: true,
    LTD_TIER_2: true,
    LTD_TIER_3: true,
    LTD_TIER_4: true,
    LTD_TIER_5: true,
    LTD_TIER_6: true,
    SESSIONS_SUPPORTER: true,
};
