import React, {PropsWithChildren} from "@workhorse/api/rendering";
import {WithClasses, WithClassName, WithStyle} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/LayoutColumn.module.scss";
export type LayoutColumnClassKey = "root" | "left" | "right" | "unscrollable";

type LayoutColumnProps = WithClasses<LayoutColumnClassKey> &
    WithStyle &
    WithClassName &
    PropsWithChildren<{
        left?: boolean;
        right?: boolean;
        large?: boolean;
        relative?: boolean;
        notScrollable?: boolean;
        noPadding?: boolean;
    }>;

const LayoutColumn = (props: LayoutColumnProps) => {
    const largeStyle = props.large ? {width: 390} : {};
    return (
        <div
            className={cls(
                classes.root,
                props.left && classes.left,
                props.right && classes.right,
                props.left && props.classes?.left,
                props.right && props.classes?.right,
                props.relative && "relative",
                props.notScrollable && classes.unscrollable,
                props.noPadding && classes.noPadding,
                props.className
            )}
            style={{...props.style, ...largeStyle}}
        >
            {props.children}
        </div>
    );
};

export default LayoutColumn;
