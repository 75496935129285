export const defaultEventRegistrationForm = (title: string, formItems: any[]) => {
    const slicedTitle = title.slice(0, 232);

    const nameField = formItems?.find((item) => item?.type === "Name");
    const emailField = formItems?.find((item) => item?.type === "Email");

    return {
        name: `${slicedTitle} Registration Form`,
        description: "",
        formItems: [nameField, emailField],
    };
};

export type RegistrationAnswer = {
    registrationDate: Date;
    registrationId: string;
    country?: string;
    answers: {
        formItemId: string;
        value: string | string[] | unknown;
    }[];
    currentParticipantId?: string;
};

export type RegistrationAnswers = {
    answers: RegistrationAnswer[];
};
