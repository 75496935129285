import {ContactInfoFragment} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import Paper from "@ui/core/components/Paper";
import {getColor, makeInitials} from "@workhorse/util";
import {MouseEventHandler} from "react";
import {ContactFetcherDetails} from "./ContactFetcherDetails";
import classes from "./styles/ContactFetcherPaper.module.scss";

interface Props {
    index: number;
    search?: string;
    selected: boolean;
    contact: ContactInfoFragment;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export function ContactFetcherContact(props: Props) {
    const content: string[] = [];

    if (props.contact.firstName) {
        content.push(props.contact.firstName);
    }

    if (props.contact.lastName) {
        content.push(props.contact.lastName);
    }

    const initials = makeInitials(props.contact.firstName, props.contact.lastName);
    const profilePicture = props.contact.userProfilePicture || undefined;
    const backgroundColor = !profilePicture ? getColor(initials) : undefined;

    return (
        <Paper
            elevation={2}
            className={cls("flex flex-items-center", classes.paperClass, props.selected && classes.selected)}
            onPointerDown={props.onClick}
            id={`result-paper-${props.index}`}
        >
            <ContactFetcherDetails
                classes={{
                    wrap: classes.infoBlock,
                    details: classes.searchResultEmail,
                    content: classes.searchResultName,
                }}
                search={props.search || ""}
                content={content.join(" ")}
                details={props.contact.email}
                avatar={profilePicture}
                initials={initials}
                avatarBackground={backgroundColor}
                delimiter={<div className={classes.dot} />}
            />
        </Paper>
    );
}
