import clientEvents from "@api/events/client";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {ReactComponent as AddAgendaIcon} from "../../../../../../components/command-palette/icons/add-agenda.svg";
import commons from "../../../../commons/style.module.scss";

const AddAgendaOption = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();

    const onClick = () => {
        clientEvents.emit("palette-open-section", {
            section: "paletteShareAgenda",
        });
    };

    const keybinds = getGlobalKeybind("paletteShareAgenda");

    return (
        <MenuItem ref={ref} onClick={onClick}>
            <div className="flex fullw flex-align-center flex-justify-between">
                <div className="flex flex-align-center">
                    <AddAgendaIcon className="mr-12" />
                    <Typography color="secondary" fontWeight="bold">
                        {t("participant.share.share_agenda")}
                    </Typography>
                </div>
                <div className="flex flex-align-center ml-12">
                    {keybinds.map((keybind, index) => (
                        <div key={index} className={commons.keyBind}>
                            {keybind}
                        </div>
                    ))}
                </div>
            </div>
        </MenuItem>
    );
});

export default AddAgendaOption;
