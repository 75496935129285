import Typography from "@ui/cdk/Typography/Typography";
import {ReactComponent as ErrorIllustration} from "../../../../frontend/assets/media/you-re-lost.svg";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/AiGenerateError.module.scss";

type AiGenerateErrorProps = {
    requestedComponentType: string;
};

const AiGenerateError = (props: AiGenerateErrorProps) => {
    return (
        <div className={cls(classes.errorContainer, "flex flex-col flex-justify-center flex-align-center gap-32")}>
            <ErrorIllustration />
            <div className="flex flex-col flex-align-center flex-justify-center gap-8">
                <Typography fontWeight="bold" variant="lg" color="primary">
                    Something didn&apos;t work
                </Typography>
                <Typography color="blueGray400">
                    We&apos;re sorry, but we were unable to generate {props.requestedComponentType} for your topic. Please try refining your
                    topic and try again.
                </Typography>
            </div>
        </div>
    );
};

export default AiGenerateError;
