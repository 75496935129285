import classes from "./styles/WorkspaceMemberSelectorEntry.module.scss";
import {MemberFragment} from "@generated/data";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";

type WorkspaceMemberSelectorEntryProps = {
    member: MemberFragment;
    onClick: (member: MemberFragment) => void;
    handleBlur: () => void;
    clearInput: () => void;
};

function WorkspaceMemberSelectorEntry(props: WorkspaceMemberSelectorEntryProps) {
    const {member, onClick, handleBlur, clearInput} = props;

    const {id: memberId, firstName, lastName, profilePicture, email} = member;

    const handleOnClickMember = () => {
        onClick(member);
        handleBlur();
        clearInput();
    };

    return (
        <ParticipantDisplayDetails
            showEmail={!!email}
            showName
            showProfilePicture
            firstName={firstName}
            lastName={lastName}
            email={email}
            avatar={profilePicture}
            isUser
            className={classes.root}
            avatarSize="L"
            renderingForAdmin
            renderingForAssistant
            participantIsAssistant={false}
            onClick={handleOnClickMember}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            key={memberId}
        />
    );
}

export default WorkspaceMemberSelectorEntry;
