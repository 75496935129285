import {createContextProvider} from "@workhorse/api/utils/context";
import {useMemo, useState} from "react";

function usePreJoinSettingsStore() {
    const [menuOpen, setMenuOpen] = useState(false);

    return useMemo(
        () => ({
            menuOpen,
            setMenuOpen,
        }),
        [menuOpen, setMenuOpen]
    );
}

export const [PreJoinSettingsProvider, usePreJoinSettings] = createContextProvider(
    {
        name: "PreJoinSettings",
    },
    usePreJoinSettingsStore
);
