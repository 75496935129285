import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";
import {ArrayItem} from "@workhorse/declarations";
import {makeId} from "../../utils";
import {replaceArtifact} from "../../utils/agendaItemUtils";

export type AgendaItemArtifact = NonNullable<ArrayItem<DesignerApiSession["agendaItems"]>["artifact"]>;

export type AddArtifactToAgendaItemInput = {
    agendaItemId: string;
    overrides: Partial<AgendaItemArtifact>;
    addDefaultArtifact?: boolean;
};

export function createDefaultArtifactForAgendaItem(agendaItemId: string, artifact: Partial<AgendaItemArtifact>): AgendaItemArtifact {
    const now = null as unknown as Date;
    const {
        __typename = "Artifact",
        id = makeId(),

        artifactId = "flowos/conference",
        data = {},
        description = "",
        durationInSeconds = 0,
        name = "",
        endedAt = null,
        createdAt = now,
        updatedAt = now,
        isConfigured = false,
        isMacro = false,
        isSystemArtifact = false,
        order = 0,
        properties = [],
        resourceResults = [],
        startAt = null,

        userConfiguredDuration = false,
    } = artifact;

    return {
        id,
        oldId: id,
        __typename,
        artifactId,
        createdAt,
        data,
        description,
        durationInSeconds,
        endedAt,
        isConfigured,
        isDeleted: false,
        update: null,
        isMacro,
        isSystemArtifact,
        name,
        order,
        properties,
        startAt,
        updatedAt,
        resourceResults,
        userConfiguredDuration,
    };
}

export class AddArtifactToAgendaItem extends DesignerAction<AddArtifactToAgendaItemInput> {
    commit() {
        const agendaItems = structuredClone(designer.currentAgendaItems() ?? []);
        const newArtifactId = makeId();
        const agendaItemIndex = agendaItems.findIndex((a) => a.id === this.input.agendaItemId);
        const agendaItem = agendaItems[agendaItemIndex];

        if (agendaItem) {
            const newArtifact = this.input.addDefaultArtifact
                ? {
                      ...designer.constants.makeDefaultConferenceArtifact(newArtifactId, agendaItem.id),
                      ...this.input.overrides,
                  }
                : createDefaultArtifactForAgendaItem(this.input.agendaItemId, this.input.overrides);

            agendaItems[agendaItemIndex!].artifact = {
                ...replaceArtifact(agendaItem.artifact, newArtifact),
                // ...createNestedDiffWhenReplace(agendaItem.artifact, newArtifact),
                oldId: agendaItem.artifact?.oldId ?? agendaItem.artifact?.id ?? null,
            };

            designer.updateCurrentSession(
                {
                    agendaItems,
                },
                {
                    strategy: "replace",
                }
            );

            return this.input.overrides?.id ?? newArtifact.id;
        }

        return "";
    }
}
