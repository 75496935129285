import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import classes from "./styles/ToolMenuItems.module.scss";
import {ArtifactTag} from "@generated/artifacts";
import {filesType} from "../../AddToolsInPlayerButton";
import commons from "../../../../commons/style.module.scss";

import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {isMacOS} from "@workhorse/util";
import {useTranslation} from "react-i18next";

type DetachFileOptionProps = {
    onClick: () => void;
    isShareScreenMenu?: boolean;
    currentMicroTag: ArtifactTag;
};

const DetachFileOption = forwardRef(
    ({onClick, isShareScreenMenu, currentMicroTag}: DetachFileOptionProps, ref: React.MutableRefObject<unknown>) => {
        const {t} = useTranslation();
        const {tooltip, nextFlow} = useOnboardingSession();

        const isFile = filesType.includes(currentMicroTag);
        const isMac = isMacOS();

        const handleClick = () => {
            if (tooltip === "share-settings" && isFile) {
                nextFlow({
                    completeFlow: true,
                    nextTooltip: 0,
                });
            }
            onClick();
        };

        return (
            <MenuItem
                data-id="detach"
                ref={ref}
                onClick={handleClick}
                variant="destructive"
                noDivider
                classes={{
                    root: classes.destructiveMenuOption,
                }}
            >
                <div className="fullw flex flex-align-center flex-justify-between">
                    <div className="flex flex-align-center">
                        <RemoveCircleOutlineOutlinedIcon className={classes.detachIcon} />
                        <Typography color={!isShareScreenMenu ? "blueGray400" : "red400"} fontWeight="bold" className={classes.detachFile}>
                            {isFile
                                ? t("player.footer.tool.remove_file") ?? "Remove file"
                                : t("player.footer.tool.remove_tool") ?? "Remove tool"}
                        </Typography>
                    </div>

                    <div className="flex flex-align-center ml-16">
                        <div className={commons.keyBind}>{!isMac ? "Alt" : "⌥"}</div>
                        <div className={commons.keyBind}>B</div>
                    </div>
                </div>
            </MenuItem>
        );
    }
);

export default DetachFileOption;
