import useBranding from "@workhorse/pages/user/profile/Theming/useBranding";
import {useCurrentParticipant, useSession} from "./SessionDataProviders";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";

const SessionBrandingProvider = () => {
    const {id: sessionId, createdAt} = useSession()! || {};
    const myParticipant = useCurrentParticipant();

    const hostType = useHostType();
    const isMemoryHostType = hostType === HostType.Memory;

    // branding for Memory host type is handled in MemoryProviders

    useBranding({
        sessionId: sessionId && !isMemoryHostType ? sessionId : undefined,
        isOwnedByCurrentUser: myParticipant?.isOwner ?? false,
        sessionExists: !!createdAt,
    });

    return <></>;
};

export default SessionBrandingProvider;
