import {useRemoteApplicationState} from "@workhorse/api/application/RemoteApplicationStateProvider";
import {useSeenSection} from "@workhorse/api/seen";
import {useUser} from "@workhorse/components/user";
import {WithChildren} from "@workhorse/declarations";
import OnboardingTerms from "./onboarding-terms/OnBoardingTerms";
import OnboardingPersonaContainer from "./onboarding-persona/OnboardingPersonaContainer";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";
import {useUserInfo} from "@workhorse/providers/User";

type OnboardingWrapperProps = {} & WithChildren;

function OnboardingWrapper(props: OnboardingWrapperProps) {
    const {children} = props;

    const user = useUserInfo();

    const hostType = useHostType();
    const isPlayer = hostType === HostType.Player;
    const isFeedback = hostType === HostType.Feedback;

    const {applicationState} = useRemoteApplicationState();

    const userLastLoginTimestamp = user.lastLoginDate ? new Date(user.lastLoginDate ?? 0)?.getTime() : null;
    const tosLastUpdateTimestamp = applicationState.releaseInfo?.tosUpdateTimestamp ?? 0;

    const userLoggedAfterTOSUpdate = !userLastLoginTimestamp ? true : tosLastUpdateTimestamp - userLastLoginTimestamp < 0;
    const userSeenTerms = useSeenSection("GLOBALS.userOnboardingTerms");

    const hasCompletedOnboardingPersona = !!user.activeOrganizationPermission.organization.onboardingCompleted;

    const userRegisteredTimestamp = new Date(user.createdAt).getTime();

    const userRegisteredBeforeTOSUpdate = userRegisteredTimestamp - tosLastUpdateTimestamp < 0;

    const isOnboardingTerms = !userSeenTerms && userRegisteredBeforeTOSUpdate && userLoggedAfterTOSUpdate;

    const organizationName = user.activeOrganizationPermission.organization.name ?? user.firstName + " " + user.lastName;

    if (isOnboardingTerms) {
        return <OnboardingTerms onbordingExistingUsers={true} />;
    }

    if (!hasCompletedOnboardingPersona && !isPlayer && !isFeedback) {
        return (
            <OnboardingPersonaContainer
                organizationName={organizationName}
                onboarding={user.activeOrganizationPermission.organization.onboarding as JSON}
                userId={user.id}
            />
        );
    }

    return <>{children}</>;
}

export default OnboardingWrapper;
