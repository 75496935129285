import {GetResourceCountDocument} from "@generated/data";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useQuery} from "@workhorse/api/data";
import {capitalize} from "@workhorse/util";
import {useUserInfo} from "@workhorse/providers/User";
import {emptyResxCountVariables} from "../resx/table/header/CreateForm/utils";
import {FlatCategItem, toolType} from "./actionCategTree";
import classes from "./style/EmbedLinkDescription.module.scss";
import {useTranslation} from "react-i18next";

type PaletteItemInfoAreaProps = {
    item: FlatCategItem;
};

const pluginArtifacts = {
    "flowos/figma": {
        title: "Figma plugin",
        description: "Install our Figma plugin to export your designs directly to Sessions.",
        cta: "Get plugin",
        link: "https://www.figma.com/community/plugin/1011512061726401104/Sessions",

        translateKeyTitle: "artifacts.figma.palette.info_title",
        translateKeyDescription: "artifacts.figma.palette.info_description",
        translateKeyCta: "artifacts.figma.palette.info_cta",
    },
    "flowos/canva": {
        title: "Canva app",
        description: "Use our Canva app to export your designs directly to Sessions.",
        cta: "Go to app",
        link: "https://www.canva.com/apps/AAEmyTgqqBk-sessions",

        translateKeyTitle: "artifacts.canva.palette.info_title",
        translateKeyDescription: "artifacts.canva.palette.info_description",
        translateKeyCta: "artifacts.canva.palette.info_cta",
    },
    "flowos/adobexd": {
        title: "Adobe XD plugin",
        description: "Install our Adobe XD plugin to export your designs directly to Sessions.",
        cta: "Get plugin",
        link: "https://exchange.adobe.com/apps/cc/b5b659ac",

        translateKeyTitle: "artifacts.adobexd.palette.info_title",
        translateKeyDescription: "artifacts.adobexd.palette.info_description",
        translateKeyCta: "artifacts.adobexd.palette.info_cta",
    },
};

export default function PaletteItemInfoArea(props: PaletteItemInfoAreaProps) {
    const {activeWorkspace} = useUserInfo();
    const {t} = useTranslation();
    const {item} = props;

    //get artifact name
    const tag = item?.artifactTag ? toolType[item.artifactTag] ?? "" : "";
    const tagForVariable = capitalize(tag);

    const pluginArtifact = item.artifactTag ? pluginArtifacts[item.artifactTag] : null;

    const {data: resourcesCountQuery, loading} = useQuery(GetResourceCountDocument, {
        variables: {...emptyResxCountVariables, ["include" + tagForVariable]: true, workspaceId: activeWorkspace.id},
        fetchPolicy: "cache-first",
        skip: !pluginArtifact,
    });

    if (pluginArtifact && resourcesCountQuery?.getResourcesCount[tag + "Count"] === 0) {
        return (
            <div className={cls("fullw flex flex-col py-24 px-20")}>
                <Typography className={cls("mb-10", classes.white)} variant="sm" fontWeight="bold">
                    {t(pluginArtifact.translateKeyTitle) ?? pluginArtifact.title}
                </Typography>
                <Typography variant="sm" fontWeight="bold" color="septenary" className="mb-16">
                    {t(pluginArtifact.translateKeyDescription) ?? pluginArtifact.description}
                </Typography>
                <Link href={pluginArtifact.link} target="_blank" className={classes.infoButton}>
                    <GetAppRoundedIcon />
                    {t(pluginArtifact.translateKeyCta) ?? pluginArtifact.cta}
                </Link>
            </div>
        );
    } else {
        return null;
    }
}
