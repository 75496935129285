import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {useRef} from "@workhorse/api/rendering";
import {ReactComponent as NewFormIcon} from "../../../../assets/media/new-form-icon.svg";
import classes from "./style/AddFieldDialog.module.scss";

type AddFieldDialogProps = {
    onClose: () => void;
    mode?: "create" | "create-custom" | "edit";
};

const AddFieldDialog = (props: AddFieldDialogProps) => {
    const {mode, onClose} = props;

    const dialogRef = useRef<DialogImperativeRef>();

    const title = mode === "edit" ? "Edit form field" : mode === "create-custom" ? "Custom field" : "New form field";
    const subtitle =
        mode === "edit"
            ? "Edit you registration field"
            : mode === "create-custom"
            ? "Set up your custom registration field"
            : "Choose a predefined list or create one from scratch";

    return (
        <Dialog
            open
            imperativeRef={dialogRef}
            classes={{paper: classes.root}}
            onClose={onClose}
            disableAutoFocus
            disableEnforceFocus
            disableScrollLock
        >
            <NewFormIcon className={classes.icon} />
            <Typography color="primary" variant="xl" fontWeight="bolder" className="mt-16">
                {title}
            </Typography>
            <Typography color="nonary" className="mt-8">
                {subtitle}
            </Typography>
            <div id="field-add-edit-slot" className={classes.content}></div>
        </Dialog>
    );
};

export default AddFieldDialog;
