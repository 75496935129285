import {ConferenceParticipantStatus} from "@generated/data";
import env from "@generated/environment";
import SvgIcon from "@ui/core/components/SvgIcon";
import {Participant} from "@workhorse/declarations/dataTypes";
import {ReactComponent as RaiseHandIcon} from "../../../components/conferenceControls/hand.svg";
import style from "./styles/AudienceParticipant.module.scss";

export type AudienceParticipantStatusInfoProps = {
    participant: Participant;
    renderingForAdmin: boolean;
};

export function AudienceParticipantConferenceStatusInfo(props: AudienceParticipantStatusInfoProps) {
    const {participant} = props;
    const isRaisingHand = participant.conferenceStatus === ConferenceParticipantStatus.PendingSpeaker;
    const isSpeaker = participant.conferenceStatus === ConferenceParticipantStatus.Speaker;

    return (
        <>
            {isRaisingHand && (
                <SvgIcon viewBox="0 0 36 36" className={style.participantStatusInfo}>
                    <RaiseHandIcon />
                </SvgIcon>
            )}

            {isSpeaker && <img className={style.participantStatusInfo} src={(env.publicResourcesCdnRoot ?? "") + "/icons/spotlight.svg"} />}
        </>
    );
}
