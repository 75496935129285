import {makeStyles} from "@material-ui/core";
import React from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {WithClassName} from "@workhorse/declarations";

const useStyles = makeStyles((theme) => ({
    dialogFooterRoot: {
        justifyContent: "flex-end",
        height: 80,
        flex: "0 0 80px",
        padding: theme.spacing(1.75, 3.75),
        borderTop: `solid 1px ${theme.main.palette.divider.primary}`,
        marginTop: "auto",
        "&.gray-bkg": {
            background: theme.main.palette.elevation.background[0],
        },
    },
    noBorder: {
        borderTop: "none",
    },
    noPadding: {
        padding: 0,
        height: "unset",
    },
}));

type DialogFooterProps = WithClassName & {
    children?: React.ReactNode[] | React.ReactNode;
    gray?: true;
    noBorder?: boolean;
    noPadding?: boolean;
};

function DialogFooter(props: DialogFooterProps) {
    const classes = useStyles();
    const {gray = false, noBorder, noPadding} = props;

    return (
        <div
            className={cls(
                "flex flex-align-center",
                classes.dialogFooterRoot,
                gray && "gray-bkg",
                noBorder && classes.noBorder,
                noPadding && classes.noPadding,
                props.className
            )}
        >
            {props.children}
        </div>
    );
}

export default DialogFooter;
