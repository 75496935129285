import {forwardRef, useRef} from "@workhorse/api/rendering";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {MacroArtifactProps} from "@workhorse/declarations";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {ReactComponent as TogglerNotes} from "../../../frontend/assets/media/macro-toggler-takeaways.svg";

type TakeawaysTogglerProps = MacroArtifactProps & {
    inDrawer: boolean;
    isMemoryMode: boolean;
};

const TakeawaysToggler = forwardRef((props: TakeawaysTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {isLobby} = useIsLobby();
    const {artifact, mobileState, inDrawer, isMemoryMode} = props;
    const {isMobile} = mobileState;

    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive, setActiveComponent} = useDrawerRightActiveComponent(artifact.artifactId);
    const closeDrawerTimeout = useRef<NodeJS.Timeout | null>(null);

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }
        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent(artifact.artifactId);
        } else if (isActive) {
            setActiveComponent(null);
        }
        const toggleDrawerCond = ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) || (isActive && rightDrawerState.isFullyOpen);

        if (toggleDrawerCond) {
            toggleRightDrawer();
        }
    };

    return (
        <ArtifactActionsButton
            badgeContent={0}
            onClick={onClick}
            isActive={isActive}
            MacroIcon={<TogglerNotes />}
            buttonDataId="player-button-takeaways"
            artifactId={artifact.artifactId}
            isLobby={isLobby}
            inDrawer={inDrawer}
            ref={ref}
        />
    );
});

export default TakeawaysToggler;
