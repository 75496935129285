import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {useDevices, useForceMuteStatus, isNotAllowedError} from "@workhorse/api/conference2";
import {forwardRef} from "@workhorse/api/rendering";
import commons from "../../../../commons/style.module.scss";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";

function AudioOn() {
    const {t} = useTranslation();
    return <Typography fontWeight="bold">{t("participant.mic.turn_off")}</Typography>;
}

function AudioOff() {
    const {t} = useTranslation();
    return <Typography fontWeight="bold">{t("participant.mic.turn_on")}</Typography>;
}

interface Props {
    onAudioToggle?: () => void;
}

const AudioTogglerOption = forwardRef<unknown, Props>((props, ref) => {
    const {audioEnabled, audioMuted, audioError, hasAudioInput} = useDevices();
    const {muteStatus} = useForceMuteStatus();

    const active = audioEnabled && !audioMuted && !isNotAllowedError(audioError);
    const forceMute = muteStatus === "hard";
    const canEnable = hasAudioInput && !forceMute;

    const keybinds = getGlobalKeybind("toggleAudio");

    return (
        <MenuItem ref={ref} onClick={props.onAudioToggle} disabled={!canEnable} className={`flex-center-all ${active ? "blue" : "red"}`}>
            {!canEnable && <AudioOff />}
            {canEnable && active && <AudioOn />}
            {canEnable && !active && <AudioOff />}
            <div className="flex flex-align-center ml-12">
                {keybinds.map((keybind, index) => (
                    <div key={index} className={commons.keyBind}>
                        {keybind}
                    </div>
                ))}
            </div>
        </MenuItem>
    );
});

export default AudioTogglerOption;
