import {ConfigurationStep, GeneratedAgendaItemPayload} from "@generated/data";
import Button from "@ui/cdk/Button";
import {useState, memo, useEffect, useRef} from "../rendering";
import AgendaGeneratorMessenger, {AIGeneratedAgendaItem} from "./AgendaGeneratorMessenger";
import {ReactComponent as AiCopilotIcon} from "../../assets/media/ai_copilot_icon.svg";
import AgendaGeneratorMenu from "./components/AgendaGeneratorMenu";
import {useAiGeneratedAgenda} from "@workhorse/providers/AiGeneratedAgendaProvider";
import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import designer from "../designer";
import {commitUpdateAgendaTemplate} from "@workhorse/api/designer/methods";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {HostType} from "@workhorse/declarations/dataTypes";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {isCopilotEnabledByPlan} from "@sessions/common/subscription-limitations";
import {useUserInfo} from "@workhorse/providers/User";
import clientEvents from "@api/events/client";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {useTranslation} from "react-i18next";
import {generateAgendaPrompt} from "./utils";
import IconButton from "@ui/core/components/IconButton";
import classes from "./style/AgendaGeneratorContainer.module.scss";
import {cls} from "@ui/cdk/util";

type AgendaGeneratorContainerProps = {
    sessionId: string;
    onAgendaItemGenerated: (
        agendaItem: Omit<NonNullable<Omit<GeneratedAgendaItemPayload, "__typename">["agendaItem"]>, "__typename">
    ) => void;
    onAgendaGenerationError: (error: string) => void;
    disabled?: boolean;
    isEventAgenda?: boolean;
    isSingleAgendaItem: boolean;
    agendaItemId?: string;
    handleUseAgendaItem?: (generatedAgendaItem: AIGeneratedAgendaItem) => void;
    isRoom?: boolean;
};

const AgendaGeneratorContainer = (props: AgendaGeneratorContainerProps) => {
    const {t} = useTranslation();
    const togglerRef = useRef<HTMLButtonElement | null>(null);

    const [aiInput, setAiInput] = useState<string>("");
    const [startGenerating, setStartGenerating] = useState<boolean>(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement | HTMLInputElement | SVGSVGElement>(null);
    const [generateClicked, setGenerateClicked] = useState<boolean>(false);
    const {activeOrganizationPermission} = useUserInfo();
    const organization = activeOrganizationPermission.organization;

    const featureAvailable = !!isCopilotEnabledByPlan[organization?.plan];

    const [numberOfAgendaItems, setNumberOfAgendaItems] = useState<number>(props.isSingleAgendaItem ? 1 : 0);
    const [agendaDuration, setAgendaDuration] = useState<number>(0);
    const [visibleInputValue, setVisibleInputValue] = useState<string>("");

    const {handleOnError, saveGeneratedAgenda} = useAiGeneratedAgenda();
    const [sessionEditState, setEditState] = useDesignerSessionState();

    const {isLobby} = useIsLobby();
    const hostType = useHostType();
    const isPlayer = hostType === HostType.Player;

    const onAiInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVisibleInputValue(e.target.value);
        const promptForChatGPT = generateAgendaPrompt(e.target.value, agendaDuration, numberOfAgendaItems);
        setAiInput(promptForChatGPT);
        handleOnError("");
    };

    const onSubmitAiInput = () => {
        if (aiInput) {
            saveGeneratedAgenda(null);
            setStartGenerating(true);
            setGenerateClicked(true);
            handleOnError("");
        }
    };

    const handleEditNumberOfAgendaItems = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value) > 99 ? 99 : Number(e.target.value);
        setNumberOfAgendaItems(value);
        const promptForChatGPT = generateAgendaPrompt(visibleInputValue, agendaDuration, value);
        setAiInput(promptForChatGPT);
    };

    const handleEditAgendaDuration = (time: number) => {
        setAgendaDuration(time);
        const promptForChatGPT = generateAgendaPrompt(visibleInputValue, time, numberOfAgendaItems);
        setAiInput(promptForChatGPT);
    };

    const increaseDuration = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        const time = agendaDuration + 60 * 5;

        if (time > 60 * 60 * 24) {
            return;
        }
        setAgendaDuration(time);

        const promptForChatGPT = generateAgendaPrompt(visibleInputValue, time, numberOfAgendaItems);
        setAiInput(promptForChatGPT);
    };

    const decreaseDuration = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        const time = Math.max(0, agendaDuration - 60 * 5);
        setAgendaDuration(time);

        const promptForChatGPT = generateAgendaPrompt(visibleInputValue, time, numberOfAgendaItems);
        setAiInput(promptForChatGPT);
    };

    const increaseAgendaItems = () => {
        setNumberOfAgendaItems((prev) => (prev >= 99 ? prev : prev + 1));

        const promptForChatGPT = generateAgendaPrompt(visibleInputValue, agendaDuration, numberOfAgendaItems + 1);
        setAiInput(promptForChatGPT);
    };

    const decreaseAgendaItems = () => {
        setNumberOfAgendaItems((prev) => (prev <= 0 ? prev : prev - 1));

        const promptForChatGPT = generateAgendaPrompt(visibleInputValue, agendaDuration, numberOfAgendaItems - 1);
        setAiInput(promptForChatGPT);
    };

    const onAgendaGenerationFinished = () => {
        setStartGenerating(false);
    };

    const onAgendaGenerationError = (err: string) => {
        setStartGenerating(false);
        props.onAgendaGenerationError(err);
    };

    const onAiPreviewToggle = async (
        e?: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>,
        ref?: React.MutableRefObject<HTMLButtonElement | null>
    ) => {
        if (menuAnchorEl) {
            setAiInput("");
            setVisibleInputValue("");
            setMenuAnchorEl(null);
            setStartGenerating(false);
            handleOnError("");
            setEditState({
                configurationStep: ConfigurationStep.Edit,
                configurationConfirm: null,
            });

            if (props.isEventAgenda && !props.isRoom) {
                await designer.commit();
                return;
            }

            if (isLobby || isPlayer) {
                return;
            }

            await commitUpdateAgendaTemplate();
        }
        if (featureAvailable) {
            setEditState({
                configurationStep: ConfigurationStep.Edit,
            });
            setMenuAnchorEl(e?.currentTarget ?? ref?.current ?? null);
        } else {
            clientEvents.emit("feature-limit-error", {
                feature: LimitedFeature.COPILOT,
                message: "limitations.notifications.ai_copilot_not_available",
            });
        }
    };

    useEffect(() => {
        const t = setTimeout(() => {
            const params = new URLSearchParams(window.location.search);
            const shouldOpenAiCoPilot = params.get("ai-copilot") === "true";

            if (!props.disabled && shouldOpenAiCoPilot) {
                onAiPreviewToggle(undefined, togglerRef);

                const newUrl = new URL(location.href);
                newUrl.searchParams.delete("ai-copilot");
                window.history.replaceState({}, document.title, newUrl.href);
            }
        }, 500);

        return () => {
            clearTimeout(t);
        };
    }, []);

    return (
        <>
            {menuAnchorEl ? (
                <AgendaGeneratorMenu
                    visibleInputValue={visibleInputValue}
                    menuAnchorEl={menuAnchorEl}
                    onAiInputChange={onAiInputChange}
                    onSubmitAiInput={onSubmitAiInput}
                    startGenerating={startGenerating}
                    onAiPreviewToggle={onAiPreviewToggle}
                    setStartGenerating={setStartGenerating}
                    generateClicked={generateClicked}
                    setGenerateClicked={setGenerateClicked}
                    onAgendaDurationChange={handleEditAgendaDuration}
                    onNumberOfAgendaItemsChange={handleEditNumberOfAgendaItems}
                    agendaDuration={agendaDuration}
                    numberOfAgendaItems={numberOfAgendaItems}
                    increaseDuration={increaseDuration}
                    decreaseDuration={decreaseDuration}
                    increaseAgendaItems={increaseAgendaItems}
                    decreaseAgendaItems={decreaseAgendaItems}
                    isSingleAgendaItem={props.isSingleAgendaItem}
                    handleUseAgendaItem={props.handleUseAgendaItem}
                />
            ) : null}

            {!props.isSingleAgendaItem ? (
                <Button
                    variant="plain"
                    size="small"
                    onClick={onAiPreviewToggle}
                    startIcon={<AiCopilotIcon />}
                    disabled={props.disabled}
                    ref={togglerRef}
                    data-id="ai-copilot-button"
                    className="px-6"
                >
                    {t("g.ai_copilot")}
                </Button>
            ) : (
                <IconButton
                    onClick={onAiPreviewToggle}
                    disabled={props.disabled}
                    className={cls(classes.iconButton, menuAnchorEl ? classes.active : "")}
                    ref={togglerRef}
                    data-id="ai-copilot-button"
                >
                    <AiCopilotIcon />
                </IconButton>
            )}

            <AgendaGeneratorMessenger
                sessionId={props.sessionId}
                prompt={aiInput}
                startGenerating={startGenerating}
                onAgendaGenerationFinished={onAgendaGenerationFinished}
                onAgendaItemGenerated={props.onAgendaItemGenerated}
                onAgendaGenerationError={onAgendaGenerationError}
            />
        </>
    );
};

export default memo(AgendaGeneratorContainer, (prevProps, nextProps) =>
    prevProps.disabled !== nextProps.disabled ? false : prevProps.sessionId === nextProps.sessionId
);
