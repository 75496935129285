import {cls} from "@ui/cdk/util";
import {memo, useMemo} from "@workhorse/api/rendering";
import {CSSTransition} from "react-transition-group";
import {
    SessionSettingsGlobalState,
    SessionSettingsSection,
    SessionSettingsSectionProps,
    SessionSettingsSectionState,
    SessionSettingsSelection,
} from "../definitions";
import {ungroupedSections} from "../configuration";
import styles from "../styles/Sections.module.scss";

type SectionsProps<TSection extends string = SessionSettingsSelection> = JSX.IntrinsicElements["div"] & {
    onValidate: (kind: TSection, message?: string) => void;
    onTouch: (kind: TSection, touched: boolean) => void;
    onSubmitDone: (kind: TSection) => void;
    onGlobalStateChange: (kind: TSection, newState: SessionSettingsSectionState<TSection>) => void;

    sections: SessionSettingsSection[];
    selected?: number;
    submitting: boolean;

    submittingDone: Partial<Record<TSection, boolean>>;
    touched: Record<TSection, boolean>;
    errors: Record<TSection, string | undefined>;
    globalState: SessionSettingsGlobalState;
    onGoToRecordingSection?: () => void;
};

type TransitionedSectionProps = SectionsProps & {
    index: number;
    section: SessionSettingsSection;
};

const MemoizedComponent = memo(
    ({component: Component, kind, ...rest}: SessionSettingsSectionProps & Pick<SessionSettingsSection, "component" | "kind">) => (
        <div className={styles.content}>
            <Component {...rest} />
        </div>
    ),
    (pv, cv) => {
        const {settingsStateCheck} = ungroupedSections[cv.kind];

        return Object.entries(pv).reduce(
            (acc, [key, value]) =>
                acc && (key === "globalState" ? !!settingsStateCheck?.(pv.globalState, cv.globalState) : Object.is(value, cv[key])),
            true
        );
    }
);

const getIsSubmitting = (
    kind: SessionSettingsSelection,
    {submitting, touched, submittingDone}: Pick<SectionsProps, "submitting" | "touched" | "submittingDone">
) => submitting && (touched[kind] ? submittingDone[kind] !== true : false);

const TransitionedSection = ({
    index,
    section: {kind, component: Component},
    onValidate,
    onTouch,
    onSubmitDone,
    onGlobalStateChange,
    sections,
    selected,
    submitting,
    submittingDone,
    touched,
    errors,
    globalState,
    onGoToRecordingSection,
}: TransitionedSectionProps) => {
    const previousSubmitting = index > 0 && getIsSubmitting(sections[index - 1].kind, {submitting, touched, submittingDone});
    const currentSubmitting = getIsSubmitting(kind, {submitting, touched, submittingDone});

    // we only submit only one section at a time
    const shouldSubmit = !previousSubmitting && currentSubmitting;

    const handleOnTouch = useMemo(() => onTouch.bind(null, kind), [onTouch, kind]);
    const handleOnSubmitDone = useMemo(() => onSubmitDone.bind(null, kind), [onSubmitDone, kind]);
    const handleOnValidate = useMemo(() => onValidate.bind(null, kind), [onValidate, kind]);
    const handleOnGlobalStateChange = useMemo(() => onGlobalStateChange.bind(null, kind), [onGlobalStateChange, kind]);

    return (
        <CSSTransition timeout={100} in={selected === index} classNames={styles} mountOnEnter>
            <div className={styles.item} role="region">
                <MemoizedComponent
                    kind={kind}
                    component={Component}
                    onTouch={handleOnTouch}
                    onSubmitDone={handleOnSubmitDone}
                    onValidate={handleOnValidate}
                    onSettingsStateUpdate={handleOnGlobalStateChange}
                    submitting={shouldSubmit}
                    touched={touched[kind]}
                    error={errors[kind]}
                    globalState={globalState}
                    onGoToRecordingSection={onGoToRecordingSection}
                />
            </div>
        </CSSTransition>
    );
};

export default function Sections({
    onValidate,
    onTouch,
    onSubmitDone,
    onGlobalStateChange,
    sections,
    selected,
    submitting,
    submittingDone,
    touched,
    errors,
    globalState,
    ...rest
}: SectionsProps) {
    return (
        <div {...rest} className={cls(rest.className, styles.root)}>
            {sections.map((section, index) => (
                <TransitionedSection
                    key={section.kind}
                    section={section}
                    index={index}
                    onValidate={onValidate}
                    onTouch={onTouch}
                    onSubmitDone={onSubmitDone}
                    onGlobalStateChange={onGlobalStateChange}
                    sections={sections}
                    selected={selected}
                    submitting={submitting}
                    submittingDone={submittingDone}
                    touched={touched}
                    errors={errors}
                    globalState={globalState}
                    onGoToRecordingSection={rest.onGoToRecordingSection}
                />
            ))}
        </div>
    );
}
