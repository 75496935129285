import {useCurrentAgendaItem, useMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import DetachFileOption from "../../../PlayerTools/ToolMenu/components/DetachFileOption";
import LocalParticipantControlMenu from "../../LocalParticipantControlMenu";
import AddAgendaOption from "./AddAgendaOption";
import EmbedFileOption from "./EmbedFileOption";
import OpenLinkOption from "./OpenLinkOption";
import ShareScreenTogglerOption from "./ShareScreenTogglerOption";
import classes from "./styles/ShareScreenMenu.module.scss";
import UseToolOption from "./UseToolOption";
import {getMaxSessionOrder} from "@workhorse/api/designer/lib/utils";
import designer from "@workhorse/api/designer";
import {AgendaItemType} from "@generated/data";

type ShareScreenMenuProps = {
    children: JSX.Element;
    disabled?: boolean;
    canEnable: boolean;
    sessionId: string;
    isAdmin: boolean;
    isAssistant: boolean;
    participantId: string;
};

const ShareScreenMenu = (props: ShareScreenMenuProps) => {
    const {children, disabled, canEnable, sessionId, isAdmin, isAssistant, participantId} = props;

    const currentAgendaItem = useCurrentAgendaItem();
    const macroArtifacts = useMacroArtifacts();

    const currentMicroTag = currentAgendaItem?.artifact?.artifactId;
    const hasAgenda = macroArtifacts.findIndex((m) => m.artifactId === "flowos/agenda") !== -1;

    const onDetach = () => {
        const order = getMaxSessionOrder() + 1;
        designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Instant,
            order,
            doNotUpdateCreatePls: true,
        });

        designer.api.session.update({
            order,
        });
        designer.commit();
    };

    const paletteOptions = [
        <EmbedFileOption key="share-screen-embed-file" />,
        <OpenLinkOption key="share-screen-open-link" />,
        <UseToolOption key="share-screen-use-tool" />,
        <AddAgendaOption key="share-screen-add-agenda" />,
        <li className="separator" key="local-audio-separator" />,
        <ShareScreenTogglerOption
            canEnable={canEnable}
            sessionId={sessionId}
            isAdmin={isAdmin}
            isAssistant={isAssistant}
            participantId={participantId}
            key="share-screen-toggle"
        />,
    ];

    const detachOptions = [
        <DetachFileOption currentMicroTag={currentMicroTag!} isShareScreenMenu onClick={onDetach} key="detach-file" />,
        <li className="separator" key="local-audio-separator" />,
        <ShareScreenTogglerOption
            canEnable={canEnable}
            sessionId={sessionId}
            isAdmin={isAdmin}
            isAssistant={isAssistant}
            key="share-screen-toggle"
            participantId={participantId}
        />,
    ];

    return disabled ? (
        children
    ) : (
        <LocalParticipantControlMenu
            options={currentMicroTag && currentMicroTag !== "flowos/conference" && !hasAgenda ? detachOptions : paletteOptions}
            classes={{popper: classes.popper, tooltip: classes.tooltip}}
            {...props}
        />
    );
};

export default ShareScreenMenu;
