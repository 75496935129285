import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {useRef, useState, useEffect} from "@workhorse/api/rendering";
import classes from "./styles/OnboardingWorkspaceDialog.module.scss";
import OnboardingWorkspace from "./OnboardingWorkspace";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@ui/core/components/IconButton";
import {useUserInfo} from "@workhorse/providers/User";
import Loading from "@workhorse/components/Loading";
import loadable from "@loadable/component";
import WaveIcon from "../../../../../assets/media/wave.png";
import CustomBrandingIcon from "../../../../../assets/media/custom-branding.png";
import AddMembersIcon from "../../../../../assets/media/add-members.png";
import {useReactiveVar} from "@apollo/client";
import {brandingLogo, sessionBackground} from "@workhorse/pages/user/profile/Theming/utils";
import {useMutation} from "@workhorse/api/data";
import {GetRemoteUserDocument, UpdateOrganizationDocument} from "@generated/data";
import toast from "@workhorse/api/toast";

const WorkspaceContentCompany = loadable(() => import("./components/WorkspaceContentCompany"), {
    fallback: <Loading />,
});
const WorkspaceContentCustomBranding = loadable(() => import("./components/WorkspaceContentCustomBranding"), {
    fallback: <Loading />,
});
const WorkspaceContentMembers = loadable(() => import("./components/WorkspaceContentMembers"), {
    fallback: <Loading />,
});

export type ContentInfo = {
    headerIcon: string;
    headerText: string;
    description: string;
    children: JSX.Element;
};

export type WorkspacePreviewData = {
    companyName: string;
    companyWebsite: string;
    companyLogo: string;
    sessionBackground?: string | null;
};

type OnboardingWorkspaceDialogProps = {
    onClose?: () => void;
};

function OnboardingWorkspaceDialog(props: OnboardingWorkspaceDialogProps) {
    const {onClose} = props;
    const [currentStep, setCurrentStep] = useState<number>(() => {
        try {
            const lastViewedStep = localStorage.getItem("onboarding.workspace.step");
            const parsedStep = parseInt(lastViewedStep ?? "");
            return !Number.isNaN(parsedStep) ? parsedStep : 1;
        } catch (error) {
            console.error("error on getting last viewed step from onboarding workspace", error);
            return 1;
        }
    });
    const dialogRef = useRef<DialogImperativeRef>();
    const user = useUserInfo();

    const logo = useReactiveVar(brandingLogo);
    const background = useReactiveVar(sessionBackground);

    const [updateOrganization] = useMutation(UpdateOrganizationDocument, {
        refetchQueries: [
            {
                query: GetRemoteUserDocument,
            },
        ],
    });

    const handleCloseWorkspaceSetupDialog = () => {
        if (currentStep === 3) {
            if (user.activeOrganizationPermission.organization.isActivated) {
                onClose?.();
                return;
            }

            updateOrganization({
                variables: {
                    data: {
                        isActivated: {
                            set: true,
                        },
                    },
                },
            })
                .then((res) => {
                    if (res?.data?.updateOneOrganization?.isActivated) {
                        onClose?.();
                    }
                })
                .catch(() => {
                    toast("Something went wrong. Please try again", {type: "error"});
                });
        } else {
            onClose?.();
        }
    };

    const initialWorkspacePreviewData: WorkspacePreviewData = {
        companyName: "",
        companyWebsite: "",
        companyLogo: "",
    };

    const organization = user.activeOrganizationPermission.organization;

    const updatedWorkspacePreviewData: WorkspacePreviewData = {
        ...initialWorkspacePreviewData,
        companyName: organization.name ?? initialWorkspacePreviewData.companyName,
        companyWebsite: organization.companyWebsite ?? initialWorkspacePreviewData.companyWebsite,
        companyLogo: logo ?? initialWorkspacePreviewData.companyLogo,
        sessionBackground: background,
    };

    const [workspacePreviewData, setWorkspacePreviewData] = useState<WorkspacePreviewData>(updatedWorkspacePreviewData);

    useEffect(() => {
        if (!logo && !background) {
            return;
        }
        setWorkspacePreviewData((prev) => ({
            ...prev,
            companyLogo: logo || initialWorkspacePreviewData.companyLogo,
            sessionBackground: background || initialWorkspacePreviewData.sessionBackground,
        }));
    }, [logo, background]);

    const contentInfo: {[key: number]: ContentInfo} = {
        1: {
            headerIcon: WaveIcon,
            headerText: "Set up your workspace",
            description: "Let’s make your workspace an awesome place to be.",
            children: (
                <WorkspaceContentCompany
                    workspacePreviewData={workspacePreviewData}
                    setWorkspacePreviewData={setWorkspacePreviewData}
                    setCurrentStep={setCurrentStep}
                />
            ),
        },
        2: {
            headerIcon: CustomBrandingIcon,
            headerText: "Make Sessions yours!",
            description: "Let your branding take center stage – it’ll be visible on your workspace and all your meetings.",
            children: (
                <WorkspaceContentCustomBranding
                    workspacePreviewData={workspacePreviewData}
                    setWorkspacePreviewData={setWorkspacePreviewData}
                    setCurrentStep={setCurrentStep}
                    brandingId={user.activeWorkspace?.branding?.id}
                />
            ),
        },
        3: {
            headerIcon: AddMembersIcon,
            headerText: "Who else is on the team?",
            description: "Your workspace is all set up and it looks great. Now it’s time to invite your teammates. ",
            children: (
                <WorkspaceContentMembers
                    onCloseDialog={onClose}
                    organizationId={organization.id}
                    isOrganizationActivated={organization.isActivated}
                />
            ),
        },
    };

    const stepCount = Object.keys(contentInfo).length;

    return (
        <Dialog imperativeRef={dialogRef} open classes={{paper: classes.root}} onClose={onClose}>
            <div className={classes.header}>
                <Typography color="secondary" fontWeight="bold">
                    Step {currentStep} of {stepCount}
                </Typography>
                <IconButton onClick={handleCloseWorkspaceSetupDialog}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <OnboardingWorkspace contentInfo={contentInfo[currentStep]} workspacePreviewData={workspacePreviewData} />
        </Dialog>
    );
}

export default OnboardingWorkspaceDialog;
