import loadable from "@workhorse/api/loadable";
import {useHistory} from "react-router-dom";

const ChooseOrganization = loadable(() => import("./ChooseOrganization"));

function ChooseOrganizationHOC(): JSX.Element {
    const history = useHistory();

    return (
        <div className="route-hoc flex-align-start choose-organization-hoc">
            <ChooseOrganization />
        </div>
    );
}

export default ChooseOrganizationHOC;
