import {FileMimeType} from "@sessions/common/various";
import {makeStyles} from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Typography from "@ui/cdk/Typography";
import {FileTypes, filterFiles, getFileHeader, mergeFiles} from "@workhorse/api/file_explorer/fileExplorer";
import React, {useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {WithChildren, WithClassName} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";

const maxBytesAllowed = 5 * Math.pow(2, 30);

export const formatBytes = (bytes: number, decimals: number = 0) => {
    if (bytes === 0) return "0 B";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

type FilesDropzoneProps = WithChildren &
    WithClassName & {
        acceptedTypes?: (FileMimeType | {type: FileMimeType; multiple: boolean})[];
        onUploadFiles?: (files: FileTypes) => void;
        onNoFileSystem?: (files: File[]) => void;
        multipleUpload?: boolean;
        disabled?: boolean;
        isStaticDropzone?: boolean;
        dropText?: string;
        classes?: {
            dropzone?: string;
            icon?: string;
            isOver?: string;
            dropText?: string;
        };
    };

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
    dropzone: {
        borderRadius: 12,
        color: theme.main.palette.primary[500],
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        pointerEvents: "none",
        backgroundColor: theme.main.palette.elevation.onSurface[0],
        borderColor: theme.main.palette.primary[500],
        border: "2px dashed",
        width: "100%",
        height: "100%",
    },
    icon: {
        width: 60,
        height: 60,
        marginBottom: theme.spacing(2),
    },
    isOver: {
        backgroundColor: theme.main.palette.elevation.onSurface[0],
        borderColor: theme.main.palette.primary[500],
    },
    dropText: {
        fontWeight: 500,
    },
}));

const FilesDropzone = (props: FilesDropzoneProps) => {
    const customStyles = useStyles();

    const {
        acceptedTypes,
        onUploadFiles,
        multipleUpload = true,
        disabled = false,
        children,
        className,
        classes,
        onNoFileSystem,
        isStaticDropzone = false,
        dropText = "Drop your files here",
    } = props;
    const acceptedFileTypes = acceptedTypes?.map((x) => (typeof x === "string" ? x : x.type));

    const [isOver, setIsOver] = useState(false);

    const fileSizeMax = maxBytesAllowed;

    const dropzoneRef = useRef<HTMLDivElement>(null);

    const checkDragOverOnTarget = (event: DragEvent) => {
        if (!disabled && !dropzoneRef.current?.contains(event.target as Node)) {
            setIsOver(false);
        }
    };

    useEffect(() => {
        document.addEventListener("dragover", checkDragOverOnTarget);
        return () => {
            document.removeEventListener("dragover", checkDragOverOnTarget);
        };
    }, []);

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        !disabled && setIsOver(true);
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        !disabled && setIsOver(true);
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        !disabled && setIsOver(false);
    };

    const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (disabled) {
            return;
        }

        setIsOver(false);

        if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
            return;
        }

        if (event.dataTransfer.files.length > 1 && !multipleUpload) {
            return toast(`Multiple files upload not allowed. Please drop only one file.`, {
                type: "error",
                duration: 4000,
            });
        }

        const files = Array.from(event.dataTransfer.files);

        if (onNoFileSystem) {
            let filteredFiles: File[] = [];
            if (acceptedTypes?.length) {
                for (let file of files) {
                    const fileType = (await getFileHeader(file)) as FileMimeType;
                    if (acceptedTypes.indexOf(fileType) === -1) {
                        toast(`File ${file.name} couldn't be uploaded because type is not supported`, {
                            type: "error",
                            duration: 4000,
                        });
                    } else {
                        filteredFiles = [...filteredFiles, file];
                    }
                }
            } else {
                filteredFiles = [...files];
            }
            onNoFileSystem(filteredFiles);
            return;
        }

        await filterFiles(files, fileSizeMax, acceptedFileTypes).then((filteredFiles) => {
            onUploadFiles?.(mergeFiles({}, filteredFiles));
        });
    };

    const DropZone = (
        <div className={cls(classes?.dropzone, customStyles.dropzone, isOver && customStyles.isOver)}>
            <CloudUpload className={cls(customStyles.icon, classes?.icon)} />
            <Typography className={cls(customStyles.dropText, classes?.dropText)}>{dropText}</Typography>
        </div>
    );

    return (
        <div
            ref={dropzoneRef}
            className={cls(customStyles.root, className, isOver && isStaticDropzone && classes?.isOver)}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {isStaticDropzone ? (
                <>
                    {DropZone}
                    {children}
                </>
            ) : isOver ? (
                DropZone
            ) : (
                children
            )}
        </div>
    );
};

export default FilesDropzone;
