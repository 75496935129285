import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import commons from "../../commons/style.module.scss";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {forwardRef} from "@workhorse/api/rendering";
import {SessionRecordingType} from "@generated/data";

interface Props {
    active: boolean;
    onToggle?: () => void;
    disabled?: boolean;
    recordingType: SessionRecordingType;
}

function RecordingStart(props: {text: string}) {
    return <Typography fontWeight="bold">{props.text}</Typography>;
}

function RecordingStop(props: {text: string}) {
    return <Typography fontWeight="bold">{props.text}</Typography>;
}

export const RecordingTogglerButton = forwardRef<unknown, Props>((props, ref) => {
    const keybinds = getGlobalKeybind("toggleRecording");

    const {t} = useTranslation();

    const recordingSelected =
        props.recordingType === SessionRecordingType.Recording || props.recordingType === SessionRecordingType.RecordingLivestream;
    const isLivestreaming = props.active && !recordingSelected;

    const textKey = props.active ? "participant.recording.stop_recording" : "participant.recording.start_recording";

    return (
        <div>
            <MenuItem
                ref={ref}
                onClick={props.onToggle}
                className={`flex-center-all ${props.active && recordingSelected ? "red" : "blue"}`}
                disabled={props.disabled || isLivestreaming}
            >
                {recordingSelected ? (
                    <>
                        {props.active && <RecordingStart text={t(textKey)} />}
                        {!props.active && <RecordingStop text={t(textKey)} />}
                    </>
                ) : (
                    <RecordingStart text={t(textKey)} />
                )}
                <div className="flex flex-align-center ml-12">
                    {keybinds.map((keybind, index) => (
                        <div key={`rec-toggler-${index}`} className={commons.keyBind}>
                            {keybind}
                        </div>
                    ))}
                </div>
            </MenuItem>
        </div>
    );
});
