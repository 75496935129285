import Typography from "@ui/cdk/Typography";
import classes from "./style/RoomsTableEmptyState.module.scss";
import Button from "@ui/cdk/Button";
import TableBody from "@ui/cdk/Table/TableBody";
import TableRow from "@ui/cdk/Table/TableRow";
import TableCell from "@ui/cdk/Table/TableCell";
import OnboardingFeaturePlaceholder from "@workhorse/api/onboarding/components/OnboardingFeaturePlaceholder";
import {useHistory} from "@workhorse/api/routing";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";

type Props = {
    isSearching: boolean;
};
function RoomsTableEmptyState(props: Props) {
    const history = useHistory();

    const {canCreateRooms, triggerGenericAccessToast} = useWorkspaceAccess();

    const handleClick = () => {
        if (!canCreateRooms()) {
            triggerGenericAccessToast();
            return;
        }
        history.push("/rooms/create");
    };

    return (
        <TableBody className={classes.root}>
            <TableRow>
                <TableCell className="flex flex-col flex-align-center">
                    {props.isSearching ? (
                        <>
                            <Typography fontWeight="bold" color="quaternary" className={classes.title}>
                                No rooms found
                            </Typography>
                            <Typography color="quaternary" className={classes.subtitle}>
                                Maybe try using another search term
                            </Typography>
                            <Button onClick={handleClick} className="mt-24" data-id="rooms-empty-create-room-button">
                                New room
                            </Button>
                        </>
                    ) : (
                        <OnboardingFeaturePlaceholder feature="rooms" />
                    )}
                </TableCell>
            </TableRow>
        </TableBody>
    );
}

export default RoomsTableEmptyState;
