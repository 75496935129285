import Typography from "@ui/cdk/Typography";
import React from "react";
import {useState, useEffect} from "@workhorse/api/rendering";
import classes from "./styles/ExitPageOffersContainer.module.scss";
import {useQuery} from "@workhorse/dataApi";
import {cls} from "@ui/cdk/util";
import {Offer, SessionLifecycle} from "@generated/data";
import {FormControlLabel} from "@material-ui/core";
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import designer from "@workhorse/api/designer";
import {ReactComponent as NoOffersGraphic} from "../assets/no-offers-graphic.svg";
import Button from "@ui/cdk/Button";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";

type ExitPageOffersContainerProps = {
    exitPageOfferId: string | undefined;
    sessionLifecycle?: SessionLifecycle;
    onSelectOffer?: (offerId: string) => void;
};

const ExitPageOffersContainer = (props: ExitPageOffersContainerProps) => {
    const {exitPageOfferId, sessionLifecycle, onSelectOffer} = props;
    const {data, loading} = useQuery("GetOffersDocument", {});

    function handleSelectOffer(event: React.ChangeEvent<HTMLInputElement>) {
        designer.api.session.update({
            exitPageOfferId: event.target.value,
        });

        onSelectOffer?.(event.target.value);

        if (!!sessionLifecycle) {
            designer.commit();
        }
    }

    function goToMyOffers() {
        window.open("/user/offers", "_blank");
    }

    return (
        <div className="flex flex-col flex-justify-start fullw">
            <Typography variant="base" fontWeight="bold" className="mt-6 mb-16">
                My offers
            </Typography>

            {data?.userOffers?.length ? (
                <div className={cls(classes.offersContainer, "flex flex-col flex-justify-start fullw mt-16")}>
                    <RadioGroup onChange={handleSelectOffer}>
                        {data?.userOffers?.map((offer: Offer) => {
                            return (
                                <FormControlLabel
                                    key={offer.id}
                                    data-id="exit-page-offer-radio-button"
                                    value={offer.id}
                                    checked={exitPageOfferId === offer.id}
                                    label={
                                        <div className={cls("flex flex-row fullw", classes.singleOffer)}>
                                            <img src={offer.imageUrl} />
                                            <div className="flex flex-col">
                                                <Typography variant="base" fontWeight="bold">
                                                    {offer.title}
                                                </Typography>

                                                <Typography variant="base">{offer.description}</Typography>
                                            </div>
                                        </div>
                                    }
                                    control={<Radio size="medium" />}
                                    className={cls("flex flex-row fullw", classes.formControlLabel)}
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
            ) : (
                <div className="flex flex-col flex-center-all">
                    <div className="my-32">
                        <NoOffersGraphic />
                    </div>

                    <Typography fontWeight="bold" variant="lg" className="flex flex-col flex-justify-center flex-align-center mb-8">
                        You have no offers
                    </Typography>
                    <Typography
                        fontWeight="bold"
                        color="secondary"
                        variant="base"
                        className="flex flex-col flex-justify-center flex-align-center"
                    >
                        Go to the Offers section to create your first offer.
                    </Typography>
                    <Button variant="tertiary" className="mt-32" onClick={goToMyOffers} startIcon={<OpenInNewRoundedIcon />}>
                        My Offers
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ExitPageOffersContainer;
