import {cls} from "@ui/cdk/util";
import classes from "./styles/OnboardingPersonaWrapper.module.scss";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useEffect, useState} from "@workhorse/api/rendering";
import Typography from "@ui/cdk/Typography";
import OnboardingPersonaProgress from "./OnboardingPersonaProgress";
import {ReactComponent as EclipseSmallIcon} from "../../../icons/eclipse_small.svg";
import {ReactComponent as EclipseBigIcon} from "../../../icons/eclipse_big.svg";

type OnboardingPersonaWrappperProps = {
    children: React.ReactNode;
    step: 1 | 2 | 3 | 4;
    preview: {
        title: string | JSX.Element;
        description: string | JSX.Element;
        body: string;
    };
    onClickStep: (step: number) => void;
    classes?: {
        root?: string;
        leftCol?: string;
        rightCol?: string;
    };
};

function OnboardingPersonaWrappper(props: OnboardingPersonaWrappperProps) {
    const {children, step, preview, onClickStep} = props;

    const {
        bp: {isTabletBP},
    } = useMobile();

    const [isLargeScreen, setIsLargeScreen] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1920);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={cls(classes.root, props.classes?.root)}>
            <div className={cls(classes.leftColumn, props.classes?.leftCol)}>
                <header className={classes.leftColumnHeader}>
                    <BrandingLogo />
                </header>
                <div className={classes.leftColumnContent}>
                    <OnboardingPersonaProgress step={step} onClick={onClickStep} />
                    {children}
                </div>
            </div>
            {!isTabletBP && (
                <div className={cls(classes.rightColumn, props.classes?.rightCol)}>
                    {isLargeScreen ? <EclipseBigIcon /> : <EclipseSmallIcon />}

                    <div className={cls(classes.rightColumnInner, isLargeScreen && classes.rightColumnInnerLarge)}>
                        <Typography component="div" variant="xl5" fontWeight="bolder" className={classes.rightColumnTitle}>
                            {preview.title}
                        </Typography>
                        <Typography component="div" variant="lg" fontWeight="bold" className={classes.rightColumnDescription}>
                            {preview.description}
                        </Typography>

                        <div className={cls(classes.rightColumnBody)}>
                            <img src={preview.body} alt="onboarding-image" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OnboardingPersonaWrappper;
