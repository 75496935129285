import {useState, useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {PaletteActionArg} from "./actionCategTree";

export type PaletteExcludeItems = string[];

function usePaletteExcludedActionsStore() {
    const [excludedItems, setExcludedItems] = useState<PaletteExcludeItems>([]);

    return useMemo(
        () => ({
            excludedItems,
            setExcludedItems,
        }),
        [excludedItems]
    );
}

export const [PaletteExcludedItemsProvider, usePaletteExcludedItems] = createContextProvider(
    {
        name: "PaletteExcludedItemsProvider",
        strict: true,
    },
    usePaletteExcludedActionsStore
);

export type PaletteActionCallback = (...arg: any) => any;

function usePaletteActionCbStore() {
    const [actionCallback, setActionCallback] = useState<PaletteActionCallback | undefined>(undefined);

    return useMemo(
        () => ({
            actionCallback,
            setActionCallback,
        }),
        [actionCallback]
    );
}

export const [CommandPaletteActionCbProvider, usePaletteActionCallback] = createContextProvider(
    {
        name: "CommandPaletteActionCbProvider",
        strict: true,
    },
    usePaletteActionCbStore
);

function usePaletteActionArgStore() {
    const [actionArg, setActionArg] = useState<PaletteActionArg | undefined>(undefined);

    return useMemo(
        () => ({
            actionArg,
            setActionArg,
        }),
        [actionArg]
    );
}

export const [CommandPaletteActionArgProvider, usePaletteActionArg] = createContextProvider(
    {
        name: "CommandPaletteActionArgProvider",
        strict: true,
    },
    usePaletteActionArgStore
);
