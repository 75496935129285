import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import {useSessionInfo} from "@workhorse/api/conference2";
import {useCallback, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {
    categTree,
    categMap,
    categMapForSearch,
    FinalAction,
    FlatCategItem,
    ActionCategory,
    PaletteActionArg,
    CategSearchItem,
} from "@workhorse/components/command-palette/actionCategTree";
import {useCurrentAgendaItem} from "../SessionDataProviders";
import {useSessionIdFromRoute} from "../CurrentSessionIdProvider";

export enum SessionOnboardingType {
    NewUser = "new-user",
    FirstStrike = "first-strike",
    SecondStrikeOwner = "second-strike-owner",
    SecondStrikeMember = "second-strike-member",
}

const tooltips: {[key in SessionOnboardingType]: {[key: string]: string[]}} = {
    [SessionOnboardingType.NewUser]: {
        "initial-flow": ["get-started", "palette-generic-info", "palette-generic-and-file-info"],
        "share-a-file": ["palette-file-info", "palette-demo-file", "tool-controls", "share-settings"],
        "open-a-link": ["palette-link-info", "tool-controls"],
        "embed-a-tool": ["palette-tool-info"],
        "end-session": ["footer-end-session"],
    },
    [SessionOnboardingType.FirstStrike]: {
        "initial-flow": [
            "agenda",
            "begin-guide",
            // "slideshow-controls",
            "micro-website",
            "micro-smartboard",
            "footer-tools",
        ],
    },
    [SessionOnboardingType.SecondStrikeOwner]: {
        "initial-flow": [
            "tool-dialog",
            "palette-my-product",
            "my-product-header",
            "participants-footer",
            "palette-embed-info",
            "footer-tools",
        ],
    },
    [SessionOnboardingType.SecondStrikeMember]: {
        "initial-flow": ["palette-upload-file", "participants-footer", "footer-tools"],
    },
};

const backdrops = {
    mediaPermissions: ["media-permissions"],
    agenda: ["agenda"],
    player: ["micro-website", "micro-smartboard"],
    productHeader: ["my-product-header"],
};

const wantedAgenda = {
    "begin-guide": "Slideshow presentation",
    "slideshow-controls": "Slideshow presentation",
    "micro-website": "Showcase a website",
    "micro-smartboard": "Brainstorm like a pro",
} as const;

interface PaletteActionTooltip {
    actionId: string;
    bySearch: boolean;
    byNavigation: boolean;
    byCheck?: (arg: PaletteActionArg) => boolean;
    navigation: string[];
    searchItem?: CategSearchItem;
}

function createPaletteNavigation(item: FlatCategItem | undefined, navigation: string[] = []): string[] {
    if (!item?.parentId) {
        return navigation;
    }
    const parent = categMap.find((parent) => parent.id === item.parentId);
    if (!parent) {
        return navigation;
    }
    return createPaletteNavigation(parent, [parent.id, ...navigation]);
}

function createPaletteActionTooltip(
    actionId: string | undefined,
    bySearch: boolean,
    byNavigation: boolean,
    byCheck?: (arg: PaletteActionArg) => boolean
): PaletteActionTooltip | undefined {
    if (!actionId) {
        return;
    }

    const item = categMap.find((item) => item.id === actionId);
    const navigation = createPaletteNavigation(item);

    const searchItem = categMapForSearch.find((search) => {
        return search.id === actionId;
    });

    return {
        actionId,
        bySearch,
        byNavigation,
        byCheck,
        searchItem,
        navigation,
    };
}

function createPaletteArtifactTooltip(
    tag: SimpleArtifactsTag,
    bySearch: boolean,
    byNavigation: boolean,
    byCheck?: (arg: PaletteActionArg) => boolean
): PaletteActionTooltip | undefined {
    const item = categMap.find((item) => {
        return item.actionArg?.action === FinalAction.Create && item.actionArg?.artifactTag === tag;
    });
    return createPaletteActionTooltip(item?.id, bySearch, byNavigation, byCheck);
}

let uploadAction = categTree[ActionCategory.File].next?.[0];
let linkAction = categTree[ActionCategory.Link].next?.[0];
let embedToolAction = categTree[ActionCategory.Tool].next?.[0];

let wantedPaletteActionMap = {
    "palette-my-product": createPaletteArtifactTooltip("flowos/my-product-tool", true, false),
    "palette-upload-file": createPaletteActionTooltip(uploadAction?.id, false, true, (arg) => {
        return arg.action === FinalAction.Use && arg.item?.mode === "file";
    }),
    "palette-generic-info": false,
    "palette-file-info": createPaletteActionTooltip(uploadAction?.id, false, true, (arg) => {
        return arg.action === FinalAction.Use && arg.item?.mode === "file";
    }),
    "palette-demo-file": createPaletteActionTooltip(uploadAction?.id, false, true, (arg) => {
        return arg.action === FinalAction.Use && arg.item?.mode === "file";
    }),

    "palette-link-info": createPaletteActionTooltip(linkAction?.id, false, true, (arg) => {
        return arg.action === FinalAction.Use && arg.item?.mode === "tool";
    }),
    "palette-tool-info": createPaletteActionTooltip(embedToolAction?.id, false, true, (arg) => {
        return arg.action === FinalAction.Use && arg.item?.mode === "tool";
    }),
} as const;

let wantedPaletteTooltips = Object.keys(wantedPaletteActionMap);

export function makeWantedPaletteActionMap() {
    uploadAction = categTree[ActionCategory.File].next?.[0];
    linkAction = categTree[ActionCategory.Link].next?.[0];
    embedToolAction = categTree[ActionCategory.Tool].next?.[0];

    wantedPaletteActionMap = {
        "palette-my-product": createPaletteArtifactTooltip("flowos/my-product-tool", true, false),
        "palette-upload-file": createPaletteActionTooltip(uploadAction?.id, false, true, (arg) => {
            return arg.action === FinalAction.Use && arg.item?.mode === "file";
        }),
        "palette-generic-info": false,
        "palette-file-info": createPaletteActionTooltip(uploadAction?.id, false, true, (arg) => {
            return arg.action === FinalAction.Use && arg.item?.mode === "file";
        }),
        "palette-demo-file": createPaletteActionTooltip(uploadAction?.id, false, true, (arg) => {
            return arg.action === FinalAction.Use && arg.item?.mode === "file";
        }),
        "palette-link-info": createPaletteActionTooltip(linkAction?.id, false, true, (arg) => {
            return arg.action === FinalAction.Use && arg.item?.mode === "tool";
        }),
        "palette-tool-info": createPaletteActionTooltip(embedToolAction?.id, false, true, (arg) => {
            return arg.action === FinalAction.Use && arg.item?.mode === "tool";
        }),
    } as const;

    wantedPaletteTooltips = Object.keys(wantedPaletteActionMap);
}

type OnboardingBackdrops = keyof typeof backdrops;

interface NextOptions {
    delay?: number;
    ifTooltip?: string;
    actionArg?: PaletteActionArg;
    nextTooltip?: number;
    nextFlow?: string;
    saveTooltip?: boolean;
}

interface NextFlowOptions extends NextOptions {
    completeFlow?: boolean;
}

interface CompleteFlowOptions {
    flowName: string;
    nextFlow?: string;
    nextTooltip?: number;
    delay?: number;
}

function isTooltip(type: SessionOnboardingType | undefined, tooltip: string, index: number, flow?: string): boolean {
    return tooltip === getTooltip(type, index, flow);
}

function getTooltip(type?: SessionOnboardingType | null, index?: number | null, flow?: string | null): string | null {
    if (type == null || index == null || flow == null) {
        return null;
    }
    return tooltips[type][flow][index] ?? null;
}

export function getWantedPaletteAction(tooltip: string | null): PaletteActionTooltip | null {
    if (!tooltip) {
        return null;
    }
    return wantedPaletteActionMap[tooltip] ?? null;
}

function getCompletedFlows(sessionId: string | null | undefined, type: SessionOnboardingType | undefined): string[] {
    if (!sessionId || !type) {
        return [];
    }

    const completedFlows = localStorage.getItem(`onboarding-session-completed-flows-${sessionId}-${type}`);

    try {
        return JSON.parse(completedFlows || "[]");
    } catch (err) {
        return [];
    }
}

function getSavedStep(sessionId: string | null | undefined, type: SessionOnboardingType | undefined, flow: string | undefined): number {
    if (!sessionId || !type || !flow) {
        return 0;
    }

    const savedStep = localStorage.getItem(`onboarding-session-saved-step-${sessionId}-${type}-${flow}`);

    return Number(savedStep ?? 0);
}

function useOnboardingSessionStore() {
    const {onboardingType} = useSessionInfo();

    const type = onboardingType as SessionOnboardingType | undefined;
    const typeRef = useRef(type);
    typeRef.current = type;

    const flows = useMemo(() => (type ? Object.keys(tooltips[type]) : []), [type]);
    const flowsRef = useRef(flows);
    flowsRef.current = flows;

    const sessionId = useSessionIdFromRoute();

    const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);

    const [completedFlows, setCompletedFlows] = useState<string[]>(() => getCompletedFlows(sessionId, type));
    const completedFlowsRef = useRef(completedFlows);
    completedFlowsRef.current = completedFlows;

    const initialFlow = flows[0];
    const [flow, setFlow] = useState<string | undefined>(initialFlow);
    const flowRef = useRef(flow);
    flowRef.current = flow;

    const [index, setIndex] = useState<number>(-1);

    const availableFlows = useMemo(() => flows.filter((f) => !completedFlows.includes(f)), [flows, completedFlows]);
    const availableFlowsRef = useRef(availableFlows);
    availableFlowsRef.current = availableFlows;

    const loadSavedStep = useCallback(() => {
        setIndex(getSavedStep(sessionId, type, flow));
    }, [sessionId, type, flow]);

    const readSavedStep = useCallback(() => {
        return getSavedStep(sessionId, type, flow);
    }, [sessionId, type, flow]);

    const clearDelay = useCallback(() => {
        setDelay((current) => {
            if (current != null) {
                clearTimeout(current);
            }
            return null;
        });
    }, []);

    const updateDelay = useCallback(
        (delay: number | undefined) => {
            if (delay == null) {
                return;
            }

            setDelay((current) => {
                if (current != null) {
                    clearTimeout(current);
                }
                return setTimeout(clearDelay, delay);
            });
        },
        [clearDelay]
    );

    const reset = useCallback(() => {
        setIndex(-1);
        clearDelay();
    }, [clearDelay]);

    const incrementIndex = useCallback(
        (
            current: number,
            ifTooltip: string | undefined,
            actionArg: PaletteActionArg | undefined,
            delay: number | undefined,
            nextTooltip: number | undefined,
            nextFlow: string | undefined,
            saveTooltip: boolean | undefined
        ) => {
            const type = typeRef.current;
            const flow = nextFlow ?? flowRef.current;

            if (actionArg != null) {
                const wantedAction = getWantedPaletteAction(getTooltip(type, current, flow));
                const passedCheck = wantedAction?.byCheck?.(actionArg) ?? false;
                if (!passedCheck) {
                    if (!actionArg.actionId) {
                        return current;
                    }

                    if (!wantedAction?.actionId) {
                        return current;
                    }

                    if (wantedAction.actionId !== actionArg.actionId) {
                        return current;
                    }
                }
            }

            if (ifTooltip != null && !isTooltip(type, ifTooltip, current, flow)) {
                return current;
            }

            updateDelay(delay);

            const nextStep = current === -1 ? getSavedStep(sessionId, type, flow) : current + 1;

            const nextIndex = nextTooltip ?? nextStep;

            if (saveTooltip) {
                localStorage.setItem(`onboarding-session-saved-step-${sessionId}-${type}-${flow}`, String(nextIndex));
            }

            return nextIndex;
        },
        [updateDelay, sessionId]
    );

    const next = useCallback(
        (options?: NextOptions) => {
            setIndex((current) =>
                incrementIndex(
                    current,
                    options?.ifTooltip,
                    options?.actionArg,
                    options?.delay,
                    options?.nextTooltip,
                    options?.nextFlow,
                    options?.saveTooltip
                )
            );
        },
        [incrementIndex]
    );

    const nextFlow = useCallback(
        (options?: NextFlowOptions) => {
            const type = typeRef.current;
            const flows = flowsRef.current;
            const completedFlows = completedFlowsRef.current;
            let availableFlows = availableFlowsRef.current;

            const {nextFlow, completeFlow, ...otherOptions} = options ?? {};

            if (type == null || flows == null) {
                return;
            }

            setFlow((curr) => {
                if (!curr) {
                    const initialCompletedFlows = getCompletedFlows(sessionId, type);
                    setCompletedFlows(initialCompletedFlows);
                    const initialAvailableFlows = flows.filter((f) => !initialCompletedFlows.includes(f));
                    return initialAvailableFlows[0];
                }

                if (completeFlow) {
                    const newCompletedFlows = [...completedFlows, curr];
                    availableFlows = flows.filter((f) => !newCompletedFlows.includes(f));
                    localStorage.setItem(`onboarding-session-completed-flows-${sessionId}-${type}`, JSON.stringify(newCompletedFlows));
                    setCompletedFlows(newCompletedFlows);
                }

                const nextCurrFlow = nextFlow && availableFlows.includes(nextFlow) ? nextFlow : availableFlows[0];

                next({
                    ...otherOptions,
                    nextFlow,
                });

                return nextCurrFlow;
            });
        },
        [sessionId, next]
    );

    const completeFlow = useCallback(
        (options: CompleteFlowOptions) => {
            const type = typeRef.current;
            const flows = flowsRef.current;
            const completedFlows = completedFlowsRef.current;
            let availableFlows = availableFlowsRef.current;
            const targetFlow = availableFlows.includes(options.flowName) ? options.flowName : null;

            function complete() {
                console.log("completeFlow", {type, flows, completedFlows, availableFlows, targetFlow});

                if (type == null || flows == null || targetFlow == null) {
                    return;
                }

                setCompletedFlows((curr) => {
                    const newCompletedFlows = [...curr];
                    if (!newCompletedFlows.includes(targetFlow)) {
                        newCompletedFlows.push(targetFlow);
                    }

                    availableFlows = flows.filter((f) => !newCompletedFlows.includes(f));
                    localStorage.setItem(`onboarding-session-completed-flows-${sessionId}-${type}`, JSON.stringify(newCompletedFlows));
                    return newCompletedFlows;
                });

                next({
                    nextTooltip: -1,
                });
            }

            function goNext() {
                const flowToFollow = options.nextFlow ?? availableFlows.filter((f) => f !== targetFlow)[0];

                nextFlow({
                    nextFlow: flowToFollow,
                    nextTooltip: 0,
                });
            }

            if (options.delay) {
                complete();
                setTimeout(goNext, options.delay);
            } else {
                complete();
                goNext();
            }
        },
        [nextFlow, sessionId]
    );

    return useMemo(() => {
        return {
            onboardingType: type,
            index,
            tooltip: delay ? null : getTooltip(type, index, flow),
            flow,
            completedFlows,
            availableFlows,
            next,
            nextFlow,
            reset,
            setIndex,
            completeFlow,
            loadSavedStep,
            readSavedStep,
        };
    }, [type, index, delay, flow, completedFlows, availableFlows, next, nextFlow, reset, completeFlow, loadSavedStep, readSavedStep]);
}

function getBackdropLocation(tooltip: string | null): OnboardingBackdrops | undefined {
    if (!tooltip) {
        return;
    }

    const locations = Object.keys(backdrops) as OnboardingBackdrops[];

    for (const location of locations) {
        const locationTooltips = backdrops[location];
        if (locationTooltips.includes(tooltip)) {
            return location;
        }
    }
}

export function useWantedAgendaTitle(tooltip: string | null): string | null {
    if (tooltip == null) {
        return null;
    }
    return wantedAgenda[tooltip] ?? null;
}

export function useIsWantedAgenda(tooltip: string | null) {
    const wantedTitle = useWantedAgendaTitle(tooltip);
    const agendaItem = useCurrentAgendaItem();

    if (wantedTitle == null) {
        return true;
    }

    return wantedTitle === agendaItem?.title;
}

export function useIsPaletteWanted(tooltip: string | null) {
    return wantedPaletteTooltips.includes(tooltip ?? "");
}

export function useWantedPaletteAction(tooltip: string | null): PaletteActionTooltip | null {
    return getWantedPaletteAction(tooltip);
}

export function useOnboardingBackdrop() {
    const {tooltip} = useOnboardingSession();
    const isWantedAgenda = useIsWantedAgenda(tooltip);

    if (!isWantedAgenda) {
        return null;
    }

    return getBackdropLocation(tooltip);
}

export const [OnboardingSessionProvider, useOnboardingSession] = createContextProvider(
    {
        name: "OnboardSession",
    },
    useOnboardingSessionStore
);
