import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import {useDeferredValue} from "./utils";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {cls} from "@ui/cdk/util";
import classes from "./onboarding.module.scss";

export function OnboardingPaletteResults(props: WithTooltipChildren) {
    const {tooltip} = useOnboardingSession();
    const {isTablet} = useMobile();

    const title = (
        <>
            <div className="flex flex-align-center">
                <InfoOutlinedIcon className="mr-6" />
                <Typography className="title" fontWeight="bolder">
                    Or select one of these files
                </Typography>
            </div>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                Here are some demo files for you to try out.
            </Typography>
        </>
    );

    const skip = tooltip !== "palette-demo-file";
    const deferredSkip = useDeferredValue(tooltip, skip, true, 1000);

    return (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement={!isTablet ? "right-start" : "top-end"}
            classes={{
                popper: isTablet ? classes.popperTablet : "",
                tooltip: cls(isTablet ? classes.tooltipTablet : ""),
            }}
            arrow={true}
            skip={deferredSkip}
            isPaletteTooltip={true}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
