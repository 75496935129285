import {BackgroundUsageWarning, useBackgroundUsageWarning} from "./BackgroundUsageWarning";
import {useOnlineOfflineDetector} from "@workhorse/components/OnlineOfflineDetector";
import toast from "@workhorse/api/toast";
import CircularProgress from "@ui/core/components/CircularProgress";
import {AudioCorruptWarning, useAudioCorruptWarning} from "./AudioCorruptedWarning";
import {useEffect} from "react";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useTranslation} from "react-i18next";
import { useAudioSystemMuteWarning, AudioSystemMuteWarning } from "./AudioSystemMuteWarning";

export function PlayerWarnings() {
    const backgroundUsage = useBackgroundUsageWarning();
    const audioWarning = useAudioCorruptWarning();
    // const audioSystemMuteWarning = useAudioSystemMuteWarning();

    const online = useOnlineOfflineDetector();

    if (online.chimeIsBlocked) {
        return <ChimeIsBlocked />;
    }

    if (online.showUnstableConnection()) {
        return <UnstableConnection />;
    }

    if (online.showConnectionDown()) {
        return <ConnectionDown />;
    }

    if (audioWarning.show) {
        return <AudioCorruptWarning onDismiss={audioWarning.onDismiss} onSettings={audioWarning.onSettings} />;
    }

    // if (audioSystemMuteWarning.show) {
    //     return <AudioSystemMuteWarning onDismiss={audioSystemMuteWarning.onDismiss} onSettings={audioSystemMuteWarning.onSettings} />;
    // }

    if (backgroundUsage.show) {
        return <BackgroundUsageWarning onDismiss={backgroundUsage.onDismiss} onSettings={backgroundUsage.onSettings} />;
    }

    return <></>;
}

function UnstableConnection() {
    const {t} = useTranslation();
    useEffect(() => {
        const id = makeId();

        toast(t("player.notifications.network.unstable_connection_description") ?? "", {
            type: "networkIssue",
            position: "top",
            uid: id,
            permanent: true,
            title: t("player.notifications.network.unstable_connection") ?? "",
        });

        return () => {
            toast("", {
                uid: id,
                remove: true,
            });
        };
    }, []);

    return <></>;
}

function ConnectionDown() {
    const {t} = useTranslation();
    useEffect(() => {
        const id = makeId();

        toast(t("player.notifications.network.reconnecting_description"), {
            type: "networkIssue",
            position: "top",
            uid: id,
            permanent: true,
            title: t("player.notifications.network.reconnecting") ?? "",
            icon: <CircularProgress color="inherit" className="flex flex-center-all" />,
        });

        return () => {
            toast("", {
                uid: id,
                remove: true,
            });
        };
    }, []);

    return <></>;
}

function ChimeIsBlocked() {
    const {t} = useTranslation();
    useEffect(() => {
        const id = makeId();

        toast(t("player.notifications.network.chime_blocked_description") ?? "", {
            type: "networkIssue",
            position: "top",
            uid: id,
            permanent: true,
            title: t("player.notifications.network.chime_blocked") ?? "",
        });

        return () => {
            toast("", {
                uid: id,
                remove: true,
            });
        };
    }, []);

    return <></>;
}
