import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {ReactComponent as TogglerChat} from "../../../frontend/assets/media/macro-toggler-chat.svg";
import {forwardRef} from "@workhorse/api/rendering";
import {useChatUnreadMessageCount} from "./utils";

type ChatTogglerProps = {
    artifactId: string;
    myParticipantId: string;
    notInLobby: boolean;
    onToggle: () => void;
    isActive: boolean;
    inDrawer: boolean;
    sessionId: string;
};

const MacroChatToggler = forwardRef((props: ChatTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {artifactId, notInLobby, onToggle, isActive, inDrawer, sessionId} = props;

    const unreadCount = useChatUnreadMessageCount(sessionId, undefined);

    return (
        <ArtifactActionsButton
            badgeContent={(!isActive && unreadCount) || 0}
            onClick={onToggle}
            isActive={isActive}
            MacroIcon={<TogglerChat />}
            buttonDataId="player-button-chat"
            artifactId={artifactId}
            isLobby={!notInLobby}
            inDrawer={inDrawer}
            ref={ref}
        />
    );
});

export default MacroChatToggler;
