export const currencies = [
    {label: "United States dollar", value: "USD", symbol: "$", min: 100},
    {label: "Australian dollar", value: "AUD", symbol: "$", min: 100},
    {label: "Brazilian real 2", value: "BRL", symbol: "R$", min: 100},
    {label: "Canadian dollar", value: "CAD", symbol: "$", min: 100},
    {label: "Chinese Renmenbi 3", value: "CNY", symbol: "¥", min: 100},
    {label: "Czech koruna", value: "CZK", symbol: "Kč", min: 1500},
    {label: "Danish krone", value: "DKK", symbol: "kr", min: 250},
    {label: "Euro", value: "EUR", symbol: "€", min: 100},
    {label: "Hong Kong dollar", value: "HKD", symbol: "$", min: 400},
    {label: "Hungarian forint 1", value: "HUF", symbol: "Ft", min: 17500},
    {label: "Israeli new shekel", value: "ILS", symbol: "₪", min: 100},
    {label: "Japanese yen 1", value: "JPY", symbol: "¥", min: 500},
    {label: "Malaysian ringgit 3", value: "MYR", symbol: "RM", min: 200},
    {label: "Mexican peso", value: "MXN", symbol: "$", min: 1000},
    {label: "New Taiwan dollar 1", value: "TWD", symbol: "$", min: 100},
    {label: "New Zealand dollar", value: "NZD", symbol: "$", min: 100},
    {label: "Norwegian krone", value: "NOK", symbol: "kr", min: 300},
    {label: "Philippine peso", value: "PHP", symbol: "₱", min: 100},
    {label: "Polish złoty", value: "PLN", symbol: "zł", min: 200},
    {label: "Pound sterling", value: "GBP", symbol: "£", min: 100},
    {label: "Russian ruble", value: "RUB", symbol: "₽", min: 100},
    {label: "Singapore dollar", value: "SGD", symbol: "$", min: 100},
    {label: "Swedish krona", value: "SEK", symbol: "kr", min: 300},
    {label: "Swiss franc", value: "CHF", symbol: "Fr", min: 100},
    {label: "Thai baht", value: "THB", symbol: "฿", min: 1000},
] as const;

const zeroDecimalCurrencies = [
    "BIF",
    "CLP",
    "DJF",
    "GNF",
    "JPY",
    "KMF",
    "KRW",
    "MGA",
    "PYG",
    "RWF",
    "UGX",
    "VND",
    "VUV",
    "XAF",
    "XOF",
    "XPF",
];

export const convertToSmallestUnit = (amount: number, currency: string) => {
    if (zeroDecimalCurrencies.includes(currency)) {
        return amount;
    }
    return Math.round(amount * 100);
};

export const convertToCurrencyUnit = (amount: number, currency: string) => {
    if (zeroDecimalCurrencies.includes(currency)) {
        return amount;
    }

    return amount / 100;
};
