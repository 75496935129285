import React, {forwardRef} from "@workhorse/api/rendering";
import MuiTableBody from "@material-ui/core/TableBody";
import {ExtractProps} from "@workhorse/declarations";

type MuiTableBodyProps = ExtractProps<typeof MuiTableBody>;

function TableBodyWithRef(props: MuiTableBodyProps, ref: any) {
    return (
        <MuiTableBody
            classes={{
                root: "mozilla-scrollbar",
            }}
            {...props}
            ref={ref}
        />
    );
}

const TableBody = forwardRef(TableBodyWithRef) as (props: MuiTableBodyProps & {ref?: any}) => ReturnType<typeof TableBodyWithRef>;

export default TableBody;
