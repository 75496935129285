import FormatAlignCenterRoundedIcon from "@material-ui/icons/FormatAlignCenterRounded";
import FormatAlignLeftRoundedIcon from "@material-ui/icons/FormatAlignLeftRounded";
import FormatAlignRightRoundedIcon from "@material-ui/icons/FormatAlignRightRounded";
import Popover from "@ui/core/components/Popover";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {
    CollaborationEditor,
    EditorAlignment,
    isArtifactElement,
    isListElement,
    SessionsEditor,
    ToolbarGenericProps,
    useClickOutside,
} from "../api";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";

const ALIGNMENTS: Record<EditorAlignment, JSX.Element> = {
    left: <FormatAlignLeftRoundedIcon />,
    center: <FormatAlignCenterRoundedIcon />,
    right: <FormatAlignRightRoundedIcon />,
};

function getAlignment(editor: SessionsEditor) {
    if (editor.isActive({textAlign: "right"})) {
        return "right";
    }
    if (editor.isActive({textAlign: "center"})) {
        return "center";
    }

    return "left";
}

export const ToolbarAlignment = (props: ToolbarGenericProps) => {
    const {editor, onActionDone} = props;
    const [alignment, setAlignment] = useState(() => getAlignment(editor));
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const ref = useRef<HTMLButtonElement>(null);

    const onEditorUpdate = useCallback(() => {
        return setAlignment(getAlignment(editor));
    }, [setAlignment]);

    useEffect(() => {
        editor.on("update", onEditorUpdate);

        return () => {
            editor.off("update", onEditorUpdate);
        };
    }, []);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (event.target && !ref.current?.contains(event.target as Node) && anchorEl) {
                setAnchorEl(undefined);
            }
        },
        [anchorEl]
    );

    useClickOutside("click", handleClickOutside);

    const handleSetAlignment = useCallback(
        (alignment: EditorAlignment) => {
            editor.chain().focus().setTextAlign(alignment).run();
            setAnchorEl(undefined);
            onActionDone();
        },
        [editor, onActionDone]
    );

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(
        (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            if (reason === "backdropClick") {
                return;
            }
            setAnchorEl(undefined);
            onActionDone();
        },
        [onActionDone]
    );

    const disabled = isArtifactElement(editor) || isListElement(editor) || (editor as CollaborationEditor).editing === false;

    return (
        <>
            <ToolbarItem
                tooltip="Alignments"
                className={classes.button}
                onClick={handleOpenMenu}
                disabled={disabled}
                isMobile={props.isMobile}
                ref={ref}
            >
                {ALIGNMENTS[alignment]}
            </ToolbarItem>

            <Popover
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
            >
                <div className={classes.horizontalList}>
                    {Object.entries(ALIGNMENTS).map(([alignment, icon]) => (
                        <ToolbarItem
                            key={alignment}
                            tooltip={`Align ${alignment}`}
                            className={cls(classes.button, editor.isActive({textAlign: alignment}) && classes.buttonActive)}
                            onClick={() => handleSetAlignment(alignment as EditorAlignment)}
                            isMobile={props.isMobile}
                        >
                            {icon}
                        </ToolbarItem>
                    ))}
                </div>
            </Popover>
        </>
    );
};
