import {usePaletteResults} from "./CommandPaletteResultsProvider";
import classes from "./style/CommandPaletteContent.module.scss";
import {cls} from "@ui/cdk/util";
import PaletteRenderOneResult, {ResultListProps} from "./PaletteRenderOneResult";
import Typography from "@ui/cdk/Typography";
import {useOnboardingSession, SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingPaletteResults} from "../onboarding-tooltips/onboarding-palette-results";
import {useTranslation} from "react-i18next";

export default function FileResults(props: ResultListProps) {
    const {t} = useTranslation();
    const {highlightId} = props;
    const {paletteResults} = usePaletteResults();
    const {onboardingType} = useOnboardingSession();

    const isOnboardingSession = onboardingType === SessionOnboardingType.NewUser;

    const items = paletteResults.files.filter((x) => (isOnboardingSession ? x.id.startsWith("sr_onboardingResource") : x));

    return items.length ? (
        <>
            {isOnboardingSession ? (
                <OnboardingPaletteResults>
                    <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
                        {t("palette.files.demo_files") ?? "Demo files"}
                    </Typography>
                </OnboardingPaletteResults>
            ) : (
                <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
                    {t("palette.files.title") ?? "Files"}
                </Typography>
            )}

            {items.map((item) => {
                return (
                    <PaletteRenderOneResult
                        item={item}
                        key={item.id}
                        highlighted={highlightId === item.id}
                        setActionArg={props.setActionArg}
                        setPaletteActiveCateg={props.setPaletteActiveCateg}
                        rootRef={props.rootRef}
                        scrollIntoView={props.scrollIntoView}
                        onHighlightText={t("palette.files.share_this_file") ?? `Share this file`}
                    />
                );
            })}
        </>
    ) : null;
}
