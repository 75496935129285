import clientEvents from "@api/events/client";
import {ApolloLink, FetchResult, Observable} from "@apollo/client";
import {getMainDefinition} from "@apollo/client/utilities";
import {readQuery, writeQuery} from "@workhorse/dataApi";
import {Subscription} from "centrifuge";
import {v4 as uuidv4} from "uuid";
import {RemoteApplicationState} from "./application/state";
import {CentrifugeService} from "./centrifugoService";
import toast from "./toast";

const lastOffset: {[K in string]: number} = {};

function makeChannelSubscriber(
    observer: ZenObservable.SubscriptionObserver<
        FetchResult<
            {
                [key: string]: any;
            },
            Record<string, any>,
            Record<string, any>
        >
    >,
    subscriptions: Subscription[],
    subscriptionChannel: string,
    dataKey: string
) {
    return (token: string) => {
        const channels = subscriptionChannel.split(", ") as string[];
        const centrifuge = CentrifugeService.getInstance(token).centrifuge;

        for (const channel of channels) {
            const existingSubscription = centrifuge.getSubscription(channel);

            if (existingSubscription) {
                if (existingSubscription.state === "unsubscribed") {
                    existingSubscription.subscribe();
                }

                existingSubscription
                    // .on("subscribed", (ctx) => {
                    //     console.log(`CENTRIFUGE: subscribed to existing ${channel}`, ctx);
                    // })
                    .on("error", (err) => {
                        console.error(`CENTRIFUGE: error on existing ${channel}`, err);
                    })
                    .on("publication", (ctx) => {
                        observer.next({
                            data: {
                                [dataKey]: ctx.data,
                            },
                        });
                    });

                continue;
            }

            const subscription = centrifuge
                .newSubscription(channel)
                .on("error", (err) => {
                    console.error(`CENTRIFUGE: error on ${channel}`, err);
                })
                .on("subscribed", (ctx) => {
                    if (channel.startsWith("watchdog")) {
                        clientEvents.emit("watchdog-mounted", true);
                    }
                    // console.log(`CENTRIFUGE: subscribed to ${channel}`, ctx);
                })
                .on("publication", (ctx) => {
                    // console.log(`CENTRIFUGE: received publication for ${channel}`, ctx);
                    observer.next({
                        data: {
                            [dataKey]: ctx.data,
                        },
                    });
                });

            subscription.subscribe();

            subscriptions.push(subscription);
        }
    };
}

const versionUid = uuidv4();
let versionMismatchPopupOpen = false;

function showVersionMismatchPopup(hide?: boolean, isSession?: boolean) {
    const currentState = readQuery("LocalVersionDocument")?.version;
    if (hide) {
        versionMismatchPopupOpen = false;
        toast(null, {
            remove: true,
            uid: versionUid,
        });
        writeQuery("LocalVersionDocument", {
            data: {
                __typename: "Query",
                version: {
                    isMismatch: false,
                    chunkLoadErr: currentState?.chunkLoadErr ?? false,
                    __typename: "Version",
                },
            },
        });
        return;
    }
    if (isSession && !currentState?.isMismatch) {
        writeQuery("LocalVersionDocument", {
            data: {
                __typename: "Query",
                version: {
                    isMismatch: true,
                    chunkLoadErr: currentState?.chunkLoadErr ?? false,
                    __typename: "Version",
                },
            },
        });
    }
    if (versionMismatchPopupOpen) {
        return;
    }
    versionMismatchPopupOpen = true;
    toast(
        "Please refresh your tab to prevent unexpected errors.",
        // createElement(
        //     "div",
        //     {style: {display: "flex", width: "100%", flexWrap: "wrap"}},
        //     createElement("span", {style: {paddingBottom: 8, flex: "0 0 100%"}}, "Please refresh your tab to prevent unexpected errors."),
        //     createElement(
        //         Button,
        //         {
        //             variant: "primary",
        //             onClick: () => {
        //                 window.location.reload();
        //             },
        //         },
        //         "Refresh now"
        //     )
        // ),
        {
            type: "networkIssue",
            position: "top",
            uid: versionUid,
            permanent: true,
            // className: "",
            title: "A new version of Sessions is available",
            // preventClose: true,
        }
    );
}

export const subscriptionManagerLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
        const subscriptions: Subscription[] = [];
        let channels: string[] = [];

        const sub = forward(operation).subscribe(
            async (data) => {
                const response = operation.getContext().response;
                const subscriptionChannel = response.headers.get("X-Subscription");
                const subscriptionToken = response.headers.get("X-Subscription-Token");
                const encodedProperties = response.headers.get("x-svc-properties");
                if (operation.operationName === "GetRemoteApplicationState") {
                    const releaseTs = ((data?.data as any)?.getApplicationState?.state as RemoteApplicationState)?.releaseInfo
                        ?.releaseTimestamp;
                    if (releaseTs) {
                        localStorage.setItem("last_release_ts", releaseTs.toString());
                    }
                } else if (operation.operationName !== "ApplicationStateUpdated") {
                    const isSession =
                        !!window && (window.location.pathname.startsWith("/session") || window.location.pathname.startsWith("/room"));
                    const lastReleaseTimestamp = response.headers.get("X-Last-Release");
                    const localVersion = localStorage.getItem("last_release_ts") ?? null;
                    if (localVersion != null && lastReleaseTimestamp !== "0" && lastReleaseTimestamp !== localVersion) {
                        showVersionMismatchPopup(false, isSession);
                        console.log(`Version mismatch - existing: ${localVersion}, incoming: ${lastReleaseTimestamp}`);
                    } else if (versionMismatchPopupOpen && localVersion && lastReleaseTimestamp && localVersion === lastReleaseTimestamp) {
                        showVersionMismatchPopup(true);
                    }
                }

                if (!!encodedProperties && encodedProperties.trim().length > 0) {
                    const definition = getMainDefinition(operation.query);
                    const op = definition.kind === "OperationDefinition" ? definition.operation : "none";
                    try {
                        const properties = JSON.parse(atob(encodedProperties));

                        if (Object.keys(properties).length > 0) {
                            clientEvents.emit("contextual-properties", properties, {
                                operationName: operation.operationName,
                                operationType: op,
                                operationVariables: operation.variables,
                                operationResult: data,
                            });
                        }
                        //emit client events for these properties
                    } catch (ex) {}
                }

                if (!subscriptionChannel || !subscriptionToken) {
                    observer.next(data);
                    observer.complete();
                    return;
                }

                channels = subscriptionChannel.split(", ") as string[];

                const trySubscribeToChannel = makeChannelSubscriber(
                    observer,
                    subscriptions,
                    subscriptionChannel,
                    (getMainDefinition(operation.query).selectionSet.selections[0] as any).name.value
                );

                trySubscribeToChannel(subscriptionToken);
            },
            (error) => {
                observer.error(error);
                observer.complete();
            }
        );
        return () => {
            if (channels.length) {
                subscriptions.forEach((subscription, i) => {
                    const chan = subscription.channel;
                    if (channels.includes(chan)) {
                        subscription.unsubscribe();
                        subscriptions.splice(i, 1);
                        delete lastOffset[chan];
                    }
                });
            }
            observer.complete();
            sub.unsubscribe();
        };
    });
});
