import browserInfo from "@workhorse/api/BrowserInfo";
import {VideoFxConfig} from "./VideoReplacementConfig";
import {createVideoReplacementLogger} from "./VideoReplacementLogger";
import ReplacementWorker from "./VideoReplacementProcessor.worker?worker";
import {VideoReplacementProcessorWorkerAdapter} from "./VideoReplacementProcessorWorkerAdapter";

const logger = createVideoReplacementLogger("processor");

export class VideoReplacementProcessor {
    private started = false;
    private stopped = false;

    private videoElement: HTMLVideoElement;
    private videoWidth: number;
    private videoHeight: number;

    private inputOffscreenCanvas: OffscreenCanvas;
    private inputOffscreenCanvasContext: OffscreenCanvasRenderingContext2D;

    private outputCanvas: HTMLCanvasElement;
    private outputOffscreenCanvas: OffscreenCanvas;

    private webglCanvas: OffscreenCanvas;
    private helperOffscreenCanvas: OffscreenCanvas;
    private maskOffscreenCanvas: OffscreenCanvas;

    private outputStream: MediaStream | undefined;

    private worker: Worker;
    private adapter: VideoReplacementProcessorWorkerAdapter;

    private intervalId: NodeJS.Timeout;

    constructor(private stream: MediaStream, private config: VideoFxConfig, private frameRate = 15) {
        const {width, height} = this.getSizeFromStream(stream);

        this.inputOffscreenCanvas = this.createOffscreenCanvas(width, height);
        this.inputOffscreenCanvasContext = this.createOffscreenCanvasContext(this.inputOffscreenCanvas);

        this.helperOffscreenCanvas = this.createOffscreenCanvas(width, height);
        this.maskOffscreenCanvas = this.createOffscreenCanvas(width, height);

        this.webglCanvas = this.createOffscreenCanvas(width, height);

        this.outputCanvas = this.createOutputCanvas(width, height);
        this.outputOffscreenCanvas = this.transferControlToOffscreen(this.outputCanvas);

        this.worker = new ReplacementWorker();
        this.adapter = new VideoReplacementProcessorWorkerAdapter(this.worker);

        this.videoElement = this.createVideo(stream, width, height);

        this.adapter.init({
            webglCanvas: this.webglCanvas,
            helperCanvas: this.helperOffscreenCanvas,
            outputCanvas: this.outputOffscreenCanvas,
            maskCanvas: this.maskOffscreenCanvas,
            config: this.config,
            isFirefox: browserInfo.isFirefox(),
            origin: window.location.origin,
        });

        // TODO: make it adaptive
        this.intervalId = setInterval(() => {
            this.process();
        }, 1000 / this.frameRate + 2);
    }

    stop() {
        this.started = false;
        this.stopped = true;
        this.destroyWorker();
        this.removeVideo();
        clearInterval(this.intervalId);
    }

    updateConfig(config) {
        this.config = config;
        this.adapter.update({
            config,
        });
    }

    setInputStream(stream: MediaStream) {
        const {width, height} = this.getSizeFromStream(stream);
        this.videoElement.srcObject = stream;
        this.adjustCanvasSize(width, height);
    }

    getOutputStream() {
        if (this.outputStream) {
            return this.outputStream;
        }

        this.outputStream = this.outputCanvas.captureStream(this.frameRate);
        return this.outputStream;
    }

    private start() {
        if (this.started) {
            return;
        }

        this.started = true;
        this.process();
    }

    private process() {
        if (!this.started) {
            logger.log("processor not started");
            return;
        }

        if (this.stopped) {
            logger.log("processor stopped");
            return;
        }

        this.inputOffscreenCanvasContext.drawImage(this.videoElement, 0, 0, this.videoWidth, this.videoHeight);
        const imageData = this.inputOffscreenCanvasContext.getImageData(0, 0, this.videoWidth, this.videoHeight);

        this.adapter.process({
            buffer: imageData.data.buffer,
            width: imageData.width,
            height: imageData.height,
        });
    }

    private destroyWorker() {
        this.adapter.stop();

        setTimeout(() => {
            this.worker.terminate();
        }, 250);
    }

    private createOffscreenCanvas(width: number, height: number) {
        try {
            return new OffscreenCanvas(width, height);
        } catch (e) {
            logger.error("cannot create offscreen canvas", e);
            throw e;
        }
    }

    private createOffscreenCanvasContext(canvas: OffscreenCanvas) {
        try {
            const ctx = canvas.getContext("2d", {
                willReadFrequently: true,
            });

            if (!ctx) {
                throw new Error("No context found");
            }

            return ctx as OffscreenCanvasRenderingContext2D;
        } catch (e) {
            logger.error("cannot create offscreen canvas context", e);
            throw e;
        }
    }

    private createOutputCanvas(width: number, height: number) {
        try {
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            return canvas;
        } catch (e) {
            logger.error("cannot create output canvas", e);
            throw e;
        }
    }

    private transferControlToOffscreen(canvas: HTMLCanvasElement) {
        try {
            return canvas.transferControlToOffscreen();
        } catch (e) {
            logger.error("cannot create output offscreen canvas", e);
            throw e;
        }
    }

    private async adjustCanvasSize(width: number, height: number) {
        if (this.videoWidth === width && this.videoHeight === height) {
            return;
        }

        this.videoWidth = width;
        this.videoHeight = height;

        this.inputOffscreenCanvas.width = width;
        this.inputOffscreenCanvas.height = height;

        this.outputCanvas.width = width;
        this.outputCanvas.height = height;

        this.outputOffscreenCanvas.width = width;
        this.outputOffscreenCanvas.height = height;

        this.webglCanvas.width = width;
        this.webglCanvas.height = height;

        this.helperOffscreenCanvas.width = width;
        this.helperOffscreenCanvas.height = height;
    }

    private createVideo(stream: MediaStream, width: number, height: number) {
        this.videoWidth = width;
        this.videoHeight = height;

        const videoElement = document.createElement("video");
        videoElement.setAttribute("autoplay", "");
        videoElement.setAttribute("muted", "");
        videoElement.setAttribute("playsinline", "");
        videoElement.style.display = "none";
        videoElement.srcObject = stream;

        videoElement.onplay = () => {
            logger.log("video started");
        };

        videoElement.onloadeddata = () => {
            this.handleVideoLoaded();
        };

        videoElement.onresize = () => {
            this.adjustCanvasSize(videoElement.videoWidth, videoElement.videoHeight);
        };

        document.body.appendChild(videoElement);

        return videoElement;
    }

    private removeVideo() {
        this.videoElement.remove();
    }

    private handleVideoLoaded() {
        this.start();
    }

    private getSizeFromStream(stream: MediaStream) {
        const videoTrack = stream.getVideoTracks()[0];

        if (!videoTrack) {
            throw new Error("No video track found");
        }

        const settings = videoTrack.getSettings();
        return {width: settings.width ?? 0, height: settings.height ?? 0};
    }
}
