import MuiRadio, {RadioProps} from "@material-ui/core/Radio";
import type {Theme} from "@material-ui/core";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as RadioCheckedIcon} from "../../../../frontend/assets/media/RadioCheckedIcon.svg";
import {ReactComponent as RadioUncheckedIcon} from "../../../../frontend/assets/media/RadioUncheckedIcon.svg";
import styles from "./radio.module.scss";

type CDKRadioProps = Omit<RadioProps, "color"> & {
    variant?: keyof Pick<Theme["main"]["palette"], "gray" | "blue" | "purple" | "green" | "yellow" | "red">;
};

const Radio = (props: CDKRadioProps) => {
    const color = props.variant ?? "blue";
    const radioClasses = cls(styles["size-" + props.size ?? ""], styles["color-" + color]);
    return (
        <MuiRadio
            className={radioClasses}
            classes={{
                root: radioClasses,
                checked: "",
            }}
            checkedIcon={props.checkedIcon || <RadioCheckedIcon className={styles.checkedIcon} />}
            icon={props.icon || <RadioUncheckedIcon />}
            color="default"
            {...props}
        />
    );
};

export default Radio;
