import Typography from "@ui/cdk/Typography";
import classes from "./styles/GroupListItemRenderer.module.scss";
import PeopleRounded from "@material-ui/icons/PeopleRounded";
import Star from "@material-ui/icons/StarRounded";
import Delete from "@material-ui/icons/DeleteRounded";
import {DummyGroupNames} from "../../utils/declarations";
import {cls} from "@ui/cdk/util";
import {ContactGroupSource} from "@generated/data";
import {ReactComponent as Microsoft365Icon} from "../../../../assets/media/microsoft-365-logo.svg";
import {ReactComponent as GoogleIcon} from "../../../../assets/media/profile-google.svg";
import Tooltip from "@ui/cdk/Tooltip";
import {ContactsCollection} from "../../utils/contacts-map";
import {ReactComponent as OrganizationGroup} from "../../../../assets/media/contacts-organization.svg";

interface IconProps {
    source?: ContactGroupSource;
    isRemovedGroup: boolean;
    isFavoriteGroup: boolean;
    badgeClassName?: string;
    isOrganizationGroup?: boolean;
}

export function GroupIcon(props: IconProps) {
    if (props.isFavoriteGroup) {
        return <Star />;
    }

    if (props.isRemovedGroup) {
        return <Delete />;
    }

    if (props.source === ContactGroupSource.Google) {
        return <GoogleIcon />;
    }

    if (props.source === ContactGroupSource.Microsoft) {
        return <Microsoft365Icon />;
    }

    if (props.isOrganizationGroup) {
        return <OrganizationGroup />;
    }

    return <PeopleRounded />;
}

interface Props {
    name: string;
    source?: ContactGroupSource;
    collection: ContactsCollection;
    selectedKey: string;
    collectionKey: string;
    onSelect: (category: string) => void;
    isOrganizationGroup?: boolean;
}

export function GroupListItem(props: Props) {
    const count = props.collection.getByKey(props.collectionKey).length;

    const title = props.name.length > 20 ? props.name : "";
    const isSelected = props.selectedKey === props.collectionKey;
    const isFavoriteGroup = props.name === DummyGroupNames.FAVORITES;
    const isRemovedGroup = props.name === DummyGroupNames.BIN;

    const handleSelectGroup = () => {
        props.onSelect(props.collectionKey);
        // TODO Check this later
        // localStorage.removeItem(ContactsLocalStorageParams.contactId);
    };

    return (
        <Tooltip arrow={true} title={title} placement="top">
            <div data-id="group-item" className={cls(classes.root, isSelected && classes.selected)} onClick={handleSelectGroup}>
                <GroupIcon
                    isFavoriteGroup={isFavoriteGroup}
                    isRemovedGroup={isRemovedGroup}
                    source={props.source}
                    badgeClassName={classes.badge}
                    isOrganizationGroup={props.isOrganizationGroup}
                />
                <Typography data-id="group-item-name" className={classes.name}>
                    {props.name}
                </Typography>
                <Typography data-id="group-item-contacts" className={classes.count}>
                    {count}
                </Typography>
            </div>
        </Tooltip>
    );
}
