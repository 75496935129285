import {WithChildren, WithClassName} from "@workhorse/declarations";
import {CSSProperties} from "@workhorse/api/rendering";
import classes from "./PositionedContent.module.scss";
import {cls} from "@ui/cdk/util";

type PositionedContentProps = WithChildren & Pick<CSSProperties, "top" | "bottom" | "left" | "right" | "width" | "height"> & WithClassName;

export default function PositionedContent({children, className, ...style}: PositionedContentProps) {
    return (
        <div data-id="speaker-popup" className={cls(classes.wrapper, className)} style={style}>
            {children}
        </div>
    );
}
