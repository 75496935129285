import {cls} from "@ui/cdk/util";
import eventsClasses from "../events/components/style/EventsHeader.module.scss";
import classes from "./style/RoomsHeader.module.scss";
import FilterOwner from "@ui/cdk/FilterOwner/FilterOwner";
import {getCorrectPlural} from "@workhorse/util/strings";

type RoomsHeaderProps = {
    count: number;
    ownerFilter: string[];
    setOwnerFilter: (ownerFilter: string[]) => void;
    roomOwners: {
        id: string;
        firstName: string;
        lastName: string;
    }[];
};

const RoomsHeader = (props: RoomsHeaderProps) => {
    const {count, ownerFilter, setOwnerFilter, roomOwners} = props;

    const disableFilterOwner = count === 0 || roomOwners.length <= 1;

    return (
        <div className={cls("flex flex-align-center flex-justify-between", eventsClasses.root)}>
            <FilterOwner
                ownerFilter={ownerFilter}
                setOwnerFilter={setOwnerFilter}
                owners={roomOwners}
                disabled={disableFilterOwner}
                isAgendaSection={false}
            />

            <div className={cls(eventsClasses.counter, classes.counter)}>
                {count} {getCorrectPlural("room", count)}
            </div>
        </div>
    );
};

export default RoomsHeader;
