import descriptor, {ArtifactTag} from "@generated/artifacts";
import {ChooseToolStep, MyProductToolDocument} from "@generated/data";
import Check from "@material-ui/icons/Check";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import React from "@workhorse/api/rendering";
import {getResourceResult} from "@workhorse/api/resx/hooks";
import {WithMobileState} from "@workhorse/declarations";
import {getDesignerArtifact} from "@workhorse/providers/DesignerSessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import classes from "./styles/DesignerToolHeader.module.scss";
import {useQuery} from "@workhorse/api/data";

interface DesignerToolHeaderProps extends WithMobileState {
    artifactId: string;
    sessionId: string;
    inDialog?: boolean;
    readOnly?: boolean;
    resultId?: string;
}

function DesignerToolHeader(props: DesignerToolHeaderProps) {
    const {artifactId, readOnly, resultId} = props;
    const {isMobile} = props.mobileState;
    const [{selectedMicroIdToPreview, selectedMacroIdToPreview, toolStep}, setEditState] = useDesignerSessionState();

    const currentStep = toolStep;
    const artifact = getDesignerArtifact(artifactId);
    const previewArtifactId = selectedMicroIdToPreview || selectedMacroIdToPreview;

    const shouldQueryForMyProductTool = !!artifact?.data.myProductToolId;
    const {data: myProductToolQuery} = useQuery(MyProductToolDocument, {
        variables: {
            myProductToolId: artifact?.data.myProductToolId,
            sessionId: props.sessionId,
        },
        skip: !shouldQueryForMyProductTool,
    });

    const hasConfig = !artifact
        ? true
        : artifact?.artifactId &&
          descriptor[artifact.artifactId].entries.some(
              (entry) => entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/config"]
          );

    const hasPreview = !artifact
        ? true
        : artifact?.artifactId &&
          descriptor[artifact.artifactId].entries.some(
              (entry) => entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/is-preview"]
          );

    const hasResource = !artifact
        ? false
        : descriptor[artifact.artifactId as ArtifactTag].capabilities.some((c) => c.type === "resx") && resultId;

    const artifactDisplayName = shouldQueryForMyProductTool
        ? myProductToolQuery?.myProductTool?.title
        : artifact?.artifactId
        ? descriptor[artifact?.artifactId]?.meta?.displayName
        : "";

    const handleSelectItem = async (step: ChooseToolStep | null) => {
        const resourceData: {
            selectedResourceId?: string;
        } = {};
        if (hasResource) {
            const {result} = await getResourceResult(resultId);

            const res = result?.resource;

            if (res?.id) {
                resourceData.selectedResourceId = res.id;
            }
        }

        setEditState({
            toolStep: step,
            ...(step === ChooseToolStep.Config && selectedMicroIdToPreview
                ? {
                      selectedMicroIdToConfig: selectedMicroIdToPreview,
                      // selectedMicroIdToPreview: null,
                      ...resourceData,
                  }
                : {}),
        });
        // addToolStep(step);
    };

    const canClickConfig = artifactId;
    const canClickPreview = previewArtifactId && artifact?.isConfigured;

    const getStep = (_number: number) => {
        if (isMobile) {
            return _number - 1;
        }
        return _number;
    };

    const getConfigStepText = () => {
        if (currentStep !== ChooseToolStep.SelectTool && artifact) {
            switch (artifact.artifactId) {
                case "flowos/breakout": {
                    return "Room setup";
                }
                default: {
                    return "Set up";
                }
            }
        } else {
            return "Set up";
        }
    };

    const configStepText = getConfigStepText();

    return (
        <div className={classes.root}>
            {isMobile ? null : (
                <div
                    className={cls(classes.item, currentStep === ChooseToolStep.SelectTool ? classes.active : classes.complete)}
                    onClick={handleSelectItem.bind(null, ChooseToolStep.SelectTool)}
                >
                    {readOnly ? null : (
                        <>
                            <div className={classes.numberContainer}>{currentStep === ChooseToolStep.SelectTool ? "1" : <Check />}</div>
                            <Typography className={classes.titleSelected}>
                                {currentStep === ChooseToolStep.SelectTool ? "Select a tool" : artifactDisplayName}
                            </Typography>
                        </>
                    )}
                </div>
            )}
            {hasConfig ? (
                <div
                    className={cls(
                        classes.item,
                        canClickConfig && classes.cursorPointer,
                        currentStep === ChooseToolStep.Config
                            ? classes.active
                            : currentStep === ChooseToolStep.Preview
                            ? classes.complete
                            : ""
                    )}
                    onClick={canClickConfig ? handleSelectItem.bind(null, ChooseToolStep.Config) : undefined}
                >
                    {readOnly ? null : (
                        <>
                            <div className={classes.numberContainer}>{currentStep === ChooseToolStep.Preview ? <Check /> : getStep(2)}</div>
                            <Typography className={classes.titleSelected}>{isMobile ? "Configure tool" : configStepText}</Typography>
                        </>
                    )}
                </div>
            ) : null}
            {hasPreview ? (
                <div
                    className={cls(
                        classes.item,
                        canClickPreview && classes.cursorPointer,
                        currentStep === ChooseToolStep.Preview ? classes.active : ""
                    )}
                    onClick={canClickPreview ? handleSelectItem.bind(null, ChooseToolStep.Preview) : undefined}
                >
                    {readOnly ? null : (
                        <>
                            <div className={classes.numberContainer}>{hasConfig ? getStep(3) : getStep(2)}</div>
                            <Typography>Preview</Typography>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default DesignerToolHeader;
