import {HostType} from "@generated/data";
import {PlayerArtifactDescription} from "@workhorse/internal/artifact";

import {ArtifactType} from "./artifact";
import contextData from "./context";

export interface IEvaluateExpression {
    exp: string;
    type: ArtifactType;
    artifactEvaluation?: Function;
    // currentArtifact?: PlayerArtifactDescription;
    // artifacts?: PlayerArtifactDescription[];
    isPreviewMode?: boolean;
    isEditMode?: boolean;
    hostType: HostType;
}

export const evaluateExpression = ({exp, type, isPreviewMode, isEditMode, hostType}: IEvaluateExpression) => {
    const context = contextData(hostType);
    // this is needed for the session preview mode when its rendered on top of the edit state
    const host: typeof context.host =
        isPreviewMode || isEditMode
            ? {
                  ...context.host,
                  isDesigner: false,
                  isPlayer: true,
              }
            : context.host;

    return host[exp.replace("host.", "")];
};
