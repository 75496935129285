import {useEffect, useState} from "@workhorse/api/rendering";

export function useCreateAudioStream(deviceId?: string) {
    const [stream, setStream] = useState<MediaStream>();

    useEffect(() => {
        if (!deviceId) {
            setStream(undefined);
            return;
        }

        let mounted = true;
        let stream: MediaStream | undefined;

        const createStream = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId,
                    },
                });

                if (mounted) {
                    setStream(stream);
                } else {
                    stream.getTracks().forEach((track) => track.stop());
                }

                return stream;
            } catch (e) {
                //
            }
        };

        createStream().then();

        return () => {
            mounted = false;
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [deviceId]);

    return stream;
}
