import {DrawerState, HostType} from "@generated/data";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {hasNavigation} from "@workhorse/components/drawers/DrawerLeft";
import {useUser} from "@workhorse/components/user";
import {DrawerHookState} from "@workhorse/declarations";
import {useSessionIdFromRoute} from "./CurrentSessionIdProvider";
import {useHostType} from "./HostTypeProvider";
import {drawerRightActiveComponent} from "./inject";
import {useMobile} from "./MobileProvider";
import {useDrawerLeftToggler, useDrawerRightToggler} from "./state";

type DrawerLeftDefaultState = {
    [K in HostType]?: keyof Omit<DrawerHookState, "state">;
};

function getLeftDrawerState<T extends keyof Omit<DrawerHookState, "state"> | undefined>(defaultState: T, hostType: HostType): T {
    if (!hasNavigation(hostType)) {
        return defaultState;
    }
    const prevState = localStorage.getItem("hay.drawerState.left") as DrawerState | undefined;
    if (prevState === undefined) {
        return defaultState;
    }
    return (
        prevState === DrawerState.FullyOpen
            ? "isFullyOpen"
            : prevState === DrawerState.SemiOpen
            ? "isSemiOpen"
            : prevState === DrawerState.Closed
            ? "isClosed"
            : defaultState
    ) as T;
}

type DrawerRightDefaultState = {
    [K in HostType]?: keyof Omit<DrawerHookState, "state">;
};

type DrawersStateManagerProps = {
    isMobile: boolean;
};

const drawerLeft: DrawerLeftDefaultState = {
    HOME: "isFullyOpen",
    USER: "isFullyOpen",
    BASE: "isFullyOpen",
    BOOKING: "isFullyOpen",
    CONTACTS: "isFullyOpen",
    MEMORY: "isFullyOpen",
    TEMPLATES: "isFullyOpen",
    RESOURCES: "isFullyOpen",
    EVENTS: "isFullyOpen",
    EVENT: "isFullyOpen",

    DESIGNER: "isClosed",
    PLAYER: "isClosed",
    FEEDBACK: "isClosed",
    BOOK: "isClosed",
    ROOMS: "isFullyOpen",
};

const drawerRight: DrawerRightDefaultState = {
    HOME: "isClosed",
    DESIGNER: "isClosed",
    PLAYER: "isClosed",
    FEEDBACK: "isClosed",
    USER: "isClosed",
    BASE: "isClosed",
    BOOK: "isClosed",
    BOOKING: "isClosed",
    CONTACTS: "isClosed",
    EVENTS: "isClosed",
    ROOMS: "isClosed",
    MEMORY: "isClosed",
};

function DrawersStateManager(props: DrawersStateManagerProps) {
    const {isMobile} = useMobile();
    const {id: userId} = useUser();

    const hostType = useHostType();
    const sessionId = useSessionIdFromRoute();
    const prevSessId = useRef(sessionId);
    const sessionChanged = sessionId && prevSessId.current && sessionId !== prevSessId.current ? new Date().getTime() : null;

    prevSessId.current = sessionId;
    const prevHostType = useRef(hostType);
    const forceLeftDrawerState = null;

    const [leftDrawer, toggleDrawerLeft] = useDrawerLeftToggler();
    const [rightDrawer, toggleDrawerRight] = useDrawerRightToggler();

    const drawerStateByHostType = getLeftDrawerState(drawerLeft[hostType], hostType);

    const newLeftDrawerState = isMobile
        ? DrawerState.Closed
        : forceLeftDrawerState ||
          (drawerStateByHostType === "isClosed"
              ? DrawerState.Closed
              : drawerStateByHostType === "isSemiOpen"
              ? DrawerState.SemiOpen
              : DrawerState.FullyOpen);

    const newRightDrawerState = isMobile
        ? DrawerState.Closed
        : forceLeftDrawerState ||
          (drawerRight[hostType] === "isClosed"
              ? DrawerState.Closed
              : drawerRight[hostType] === "isSemiOpen"
              ? DrawerState.SemiOpen
              : DrawerState.FullyOpen);

    useEffect(() => {
        if (hostType !== HostType.Player || sessionChanged) {
            drawerRightActiveComponent(null);
        }
    }, [hostType, sessionChanged]);

    useEffect(() => {
        const hostTypeChanged = hostType !== prevHostType.current;
        prevHostType.current = hostType;

        if (forceLeftDrawerState || (drawerStateByHostType && leftDrawer.state !== drawerStateByHostType)) {
            if (userId !== "mocked" || newLeftDrawerState !== DrawerState.FullyOpen) {
                toggleDrawerLeft(newLeftDrawerState, "DrawersStateManager:123");
            }
        }

        if (drawerRight[hostType] && hostTypeChanged && rightDrawer.state !== drawerRight[hostType]) {
            toggleDrawerRight(newRightDrawerState);
        }
    }, [hostType, forceLeftDrawerState, newLeftDrawerState, newRightDrawerState, sessionChanged, userId]);

    return <></>;
}

export default DrawersStateManager;
