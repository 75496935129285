import Checkbox from "@ui/cdk/Checkbox";
import Popover from "@ui/core/components/Popover";
import classes from "./style/FilterOwner.module.scss";
import {cls} from "@ui/cdk/util";
import Input from "@ui/cdk/Input";
import React, {useState} from "@workhorse/api/rendering";
import Tooltip from "@ui/cdk/Tooltip";

type FilterOwnerPopoverProps = {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    ownerFilter: string[];
    setOwnerFilter: (ownerFilter: string[]) => void;
    owners: {
        id: string;
        firstName: string;
        lastName: string;
    }[];
};

const FilterOwnerPopover = (props: FilterOwnerPopoverProps) => {
    const {anchorEl, onClose, setOwnerFilter, ownerFilter, owners} = props;
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
    };

    const handleOwnerFilterChange = (ownerId: string) => {
        const newState = ownerFilter.includes(ownerId) ? ownerFilter.filter((id) => id !== ownerId) : [...ownerFilter, ownerId];
        setOwnerFilter(newState);
        clearSearchTerm();
    };

    const filteredOwners = owners.filter((owner) => {
        const fullName = owner.firstName + " " + owner.lastName;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return (
        <Popover
            id="events-filter-popover"
            open={true}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            classes={{paper: cls("flex flex-col", classes.rootOwnerFilterPopover)}}
        >
            <Input
                classes={{
                    root: cls("flex-grow", classes.input),
                }}
                formControlClassName={classes.inputContainer}
                value={searchTerm}
                onChange={handleSearchTermChange}
            />
            <div className={cls("flex flex-col", classes.ownersFilterList)}>
                {filteredOwners.length ? (
                    filteredOwners.map((owner, index) => (
                        <div
                            key={owner.id}
                            className={cls("flex flex-align-center", classes.listItem)}
                            onClick={handleOwnerFilterChange.bind(null, owner.id)}
                            data-id={`owner-${index}`}
                        >
                            <Checkbox checked={ownerFilter.includes(owner.id)} />
                            {(owner.firstName + " " + owner.lastName).length > 30 ? (
                                <Tooltip
                                    title={
                                        <>
                                            {owner.firstName} {owner.lastName}
                                        </>
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <p>
                                        {owner.firstName} {owner.lastName}
                                    </p>
                                </Tooltip>
                            ) : (
                                <p>
                                    {owner.firstName} {owner.lastName}
                                </p>
                            )}
                        </div>
                    ))
                ) : (
                    <div className={cls("flex fullw flex-center-all", classes.noOwnersFound)}>No owners found</div>
                )}
            </div>
        </Popover>
    );
};

export default FilterOwnerPopover;
