import {SessionSettingsConfiguration, SessionSettingsSection, SessionSettingsSelection} from "./definitions";
import AccessSectionDefinition from "./sections/AccessSection/AccessSectionDefinition";
import AudioSectionDefinition from "./sections/AudioSection";
import BackgroundEffectsSectionDefinition from "./sections/BackgroundEffectsSection";
import NotificationsSectionDefinition from "./sections/NotificationsSection/NotificationsSectionDefinition";
import SessionSectionDefinition from "./sections/SessionSection/SessionSectionDefinition";
import VideoSectionDefinition from "./sections/VideoSection";
import WidgetsSectionDefinition from "./sections/WidgetsSection/WidgetsSectionDefinition";
import AutomationSectionDefinition from "./sections/AutomationSection/AutomationSectionDefinition";
import RecordingSectionDefinition from "./sections/RecordingSection/RecordingSectionDefinition";
import LivestreamingSectionDefinition from "./sections/LivestreamingSection/LivestreamingSectionDefinition";
import LeaveSessionSectionDefinition from "./sections/LeaveSessionSection/LeaveSessionSectionDefinition";

const configuration: SessionSettingsConfiguration = {
    device: {
        translateKey: "device_preferences",
        name: "Device Preferences",
        sections: {
            video: VideoSectionDefinition,
            audio: AudioSectionDefinition,
            backgroundEffects: BackgroundEffectsSectionDefinition,
            notifications: NotificationsSectionDefinition,
        },
    },
    general: {
        translateKey: "general_settings",
        name: "Moderator",
        sections: {
            session: SessionSectionDefinition,
            permissions: AccessSectionDefinition,
            widgets: WidgetsSectionDefinition,
            automation: AutomationSectionDefinition,
            recording: RecordingSectionDefinition,
            livestreaming: LivestreamingSectionDefinition,
            exitPage: LeaveSessionSectionDefinition,
        },
        rbac: {
            "session.isAssistant": true,
            isOwner: true,
            isUser: false,
            isGuest: false,
        },
    },
};

export const sectionList = Object.entries(configuration).reduce(
    (acc, [_, {sections}]) => [...acc, ...Object.values(sections).filter((section: SessionSettingsSection) => section.show !== false)],
    []
) as SessionSettingsSection[];

export const ungroupedSections = sectionList.reduce((acc, section) => ({...acc, [section.kind]: section}), {}) as Record<
    SessionSettingsSelection,
    SessionSettingsSection
>;

export default configuration;
