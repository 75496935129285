import React from "@workhorse/api/rendering";
import DialogContent from "@ui/core/components/DialogContent";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: `solid 1px ${theme.main.palette.cdkPalette.border.primary}`,
        borderBottom: `solid 1px ${theme.main.palette.cdkPalette.border.primary}`,
        flex: "1 1 auto",
        display: "flex",
        height: "100%",
        padding: 0,
        paddingTop: "0!important",
    },
}));

const DialogGrid = (props) => {
    const classes = useStyles();

    return (
        <DialogContent
            classes={{
                root: classes.root,
            }}
        >
            {props.children}
        </DialogContent>
    );
};

export default DialogGrid;
