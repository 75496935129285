// const debouncedUserJoinedLeft = makeVar<UserJoinedOrLeftMessage[]>([]);

import {useBreakoutInfo} from "@artifacts/breakout/providers/BreakoutInfoProvider";
import {useSessionInfo} from "@workhorse/api/conference2/providers/SessionInfoProvider";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useBreakoutConfig} from "../components/BreakoutMenu/BreakoutConfigProvider";
import {MicroNotificationMessage, microNotify} from "./api";

export type UserJoinedOrLeftMessage = {
    participantId: string;
    user: string;
    isRoom?: boolean;
    action: "left" | "entered";
};

export type MicroNotificationsDebouncerRef = {
    notify?: (message: MicroNotificationMessage<UserJoinedOrLeftMessage>) => void;
    /**
     * @description Used for debouncing, cleanup and sending the message to the Micro Notification Center
     *
     * @param message @type {MicroNotificationMessage<TMessage>} - the last added message to the list
     * @param messages @type {MicroNotificationMessage<TMessage>} - the list of messages to be sent to the Micro Notification Center
     *
     * @returns @type {true} - sends the message to the Micro Notification Center
     * @returns @type {MicroNotificationMessage<TMessage>[]} - the filtered list to be debounced and sent to the Micro Notification Center
     */
    compute: (
        messages: MicroNotificationMessage<UserJoinedOrLeftMessage>[],
        message: MicroNotificationMessage<UserJoinedOrLeftMessage>
    ) => MicroNotificationMessage<UserJoinedOrLeftMessage>[] | true;
};

export type MicroNotificationsDebouncerProps = {
    connectorRef: React.MutableRefObject<MicroNotificationsDebouncerRef>;
};

export function MicroNotificationsDebouncer<TMessage>({connectorRef}: MicroNotificationsDebouncerProps) {
    const [debouncedMessages, setMessages] = useState<MicroNotificationMessage<UserJoinedOrLeftMessage>[]>([]);
    const [borConfig] = useBreakoutConfig();
    const {parentSessionId, sessionId} = useSessionInfo();
    const {onlineParticipants, debounceNotifications} = useBreakoutInfo();
    const onlineParticipantsRef = useRef(onlineParticipants);
    onlineParticipantsRef.current = onlineParticipants;

    const checkBorStatus = useCallback(
        (action: "left" | "entered", participantId: string) => {
            if (!borConfig && !debounceNotifications) {
                return false;
            }

            if (action === "entered") {
                return onlineParticipantsRef.current.includes(participantId);
            }

            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(onlineParticipantsRef.current.includes(participantId));
                }, 1500);
            });
        },
        [borConfig, debounceNotifications]
    );

    const notify: MicroNotificationsDebouncerRef["notify"] = useCallback(
        async (message: MicroNotificationMessage<UserJoinedOrLeftMessage>) => {
            const {action, participantId} = message.message;
            const blockNotification = await checkBorStatus(action, participantId);

            if (blockNotification) {
                return;
            }

            message.message.isRoom = !!parentSessionId;

            setMessages((messages) => {
                const newMessages = connectorRef.current.compute(messages, message);

                if (newMessages !== true) {
                    return newMessages;
                } else {
                    microNotify(message);
                    return messages;
                }
            });
        },
        [connectorRef, parentSessionId, checkBorStatus]
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (debouncedMessages.length) {
                debouncedMessages.forEach((message) => microNotify(message));

                setMessages([]);
            }
        }, 3000);

        return () => clearTimeout(timeout);
    }, [debouncedMessages]);

    useEffect(() => {
        connectorRef.current = {
            ...connectorRef.current,
            notify,
        };
    }, [connectorRef, notify]);

    return null;
}
