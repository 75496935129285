import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DurationInputMask from "@ui/cdk/DurationInputMask";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {showBackdrop} from "@workhorse/pages/designer/DesignerBackdrop";
import classes from "./style/AgendaItemTime.module.scss";
type AgendaItemTimeProps = {
    duration: number;
    id: string;
    readOnly: boolean;
    locked: boolean;
    onBlur?: () => Promise<void>;
    onFocus?: () => Promise<void>;
    inEventSettings?: boolean;
    hideTimeControls?: boolean;
};
const AgendaItemTime = (props: AgendaItemTimeProps) => {
    const {duration: externalDuration, id, readOnly, locked, onFocus: onFocusProp, inEventSettings, hideTimeControls} = props;

    const [duration, setDuration] = useState(externalDuration ?? 0);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        setDuration(externalDuration ?? 0);
    }, [externalDuration]);

    const commitTimeOutRef = useRef<NodeJS.Timeout | null>(null);

    const batchForCommit = () => {
        if (commitTimeOutRef?.current) {
            clearTimeout(commitTimeOutRef.current);
        }

        commitTimeOutRef.current = setTimeout(() => {
            props.onBlur?.();
        }, 2000);
    };

    const onSubmitDuration = (parsed: number, value?: number, event?: React.FocusEvent<HTMLInputElement>) => {
        showBackdrop(false);
        if (!parsed || parsed < 0) {
            parsed = 0;
        }

        setDuration(parsed);

        updateDuration(parsed);
        let isEditingTarget = false;
        if (event) {
            const relatedTarget = event?.relatedTarget as HTMLElement;
            isEditingTarget = relatedTarget?.classList.contains("editing_" + id);
        }

        if (!isEditingTarget) {
            batchForCommit();
        }
    };

    const updateDuration = (duration?: number) => {
        if (id && inEventSettings) {
            designer.api.agendaItem.update({
                id: id,
                agendaItem: {
                    duration,
                },
            });

            designer.commit({
                source: "agenda",
            });
            return;
        }

        if (id) {
            designer.api.agendaItem.update({
                id: id,
                agendaItem: {
                    duration,
                },
            });
        }
    };

    const addTime = (event) => {
        event.stopPropagation();
        const time = duration + 60 * 5;
        setDuration(time);

        onSubmitDuration(time);
    };

    const subTime = (event) => {
        event.stopPropagation();
        const time = Math.max(0, duration - 60 * 5);
        setDuration(time);

        onSubmitDuration(time);
    };

    const onFocus = useCallback(
        async (event: React.FocusEvent<HTMLInputElement>) => {
            setIsInputFocused(true);

            const relatedTarget = event?.relatedTarget as HTMLElement;
            const isEditingTarget = relatedTarget?.classList.contains("editing_" + id);
            if (!isEditingTarget) {
                await onFocusProp?.();
            }
        },
        [onFocusProp, id, setIsInputFocused]
    );

    const handleBlur = (parsed: number, value?: number, event?: React.FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(false);
        onSubmitDuration(parsed, value, event);
    };

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.repeat) {
                event.stopPropagation();
                event.preventDefault();
                return;
            }

            if (isInputFocused) {
                if (event.code === "ArrowUp") {
                    event.stopPropagation();
                    event.preventDefault();

                    addTime(event);
                    return;
                } else if (event.code === "ArrowDown") {
                    event.stopPropagation();
                    event.preventDefault();

                    subTime(event);
                    return;
                }
            }
        },
        [isInputFocused, addTime, subTime]
    );

    useEffect(() => {
        document.body.addEventListener("keydown", handleKeyDown);

        return () => {
            document.body.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <div className={cls("flex flex-align-center flex-center-all fullw")}>
            <Typography
                component="div"
                variant="sm"
                fontWeight="bold"
                color="octonary"
                className={cls("flex", classes.artifactTotalTime, readOnly && classes.readOnly)}
            >
                <div className={cls("flex gap-10 fullw", classes.timeInputWrap)}>
                    {hideTimeControls ? null : (
                        <div className="flex flex-center-all">
                            <button
                                disabled={readOnly || locked || duration === 0}
                                className={cls(classes.timeButton, !readOnly && locked ? classes.locked : "")}
                                onClick={subTime}
                            >
                                <RemoveIcon />
                            </button>
                        </div>
                    )}

                    <div data-id="agendaItem-duration" className="fullw">
                        <DurationInputMask
                            autoFocus={false}
                            showIcon={false}
                            autoSize
                            formattedClassName={cls(classes.timeDisplay, duration === 0 && classes.timeDisplayZero)}
                            value={duration}
                            onFocus={onFocus}
                            short
                            readOnly={readOnly || locked}
                            className={cls(classes.timeInput, "editing_" + id)}
                            handleBlur={handleBlur}
                        ></DurationInputMask>
                    </div>
                    {hideTimeControls ? null : (
                        <div className="flex flex-center-all">
                            <button
                                disabled={readOnly || locked}
                                className={cls(classes.timeButton, !readOnly && locked ? classes.locked : "")}
                                onClick={addTime}
                            >
                                <AddIcon />
                            </button>
                        </div>
                    )}
                </div>
            </Typography>
        </div>
    );
};

export default AgendaItemTime;
