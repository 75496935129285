import {TEMPLATE_FOLDER_NAME_FOR_USERS} from "@sessions/common/agenda-templates";
import FilterOwner from "@ui/cdk/FilterOwner/FilterOwner";
import {cls} from "@ui/cdk/util";
import {getCorrectPlural} from "@workhorse/util/strings";
import classes from "./style/AgendaTemplatesSectionHeader.module.scss";

type AgendaTemplatesSectionHeaderProps = {
    owners: {
        id: string;
        firstName: string;
        lastName: string;
    }[];
    ownerFilter: string[];
    setOwnerFilter: (ownerFilter: string[]) => void;
    noOfAgendas: number;
    tagName: string;
};

const AgendaTemplatesSectionHeader = (props: AgendaTemplatesSectionHeaderProps) => {
    const {owners, ownerFilter, setOwnerFilter, noOfAgendas, tagName} = props;

    const filterOwnerDisabled = owners.length <= 1 || noOfAgendas === 0 || tagName === TEMPLATE_FOLDER_NAME_FOR_USERS;

    return (
        <div className={cls("fullw flex flex-justify-between flex-align-center", classes.root)}>
            <FilterOwner
                owners={owners}
                ownerFilter={ownerFilter}
                setOwnerFilter={setOwnerFilter}
                disabled={filterOwnerDisabled}
                isAgendaSection={true}
            />
            <div className={classes.counter}>
                {noOfAgendas} {getCorrectPlural("agenda", noOfAgendas)}
            </div>
        </div>
    );
};

export default AgendaTemplatesSectionHeader;
