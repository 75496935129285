import descriptor, {ArtifactTag} from "@generated/artifacts";
import {ResourceFragment} from "@generated/data";
// import {getArtifactResourceSlot} from "@workhorse/api/resx/hooks";
import {useMemo} from "../rendering";
import {ResourceFullWithContent} from "../resx/utils";

export function staticToolName(artifactId: string | undefined, artifactTag: ArtifactTag, resource?: ResourceFullWithContent<never, any>) {
    return {
        // @ts-expect-error
        nameByResource: resource && resource.content && resource.content.name,
        nameByDescriptor:
            artifactTag && artifactTag !== "flowos/conference" && descriptor[artifactTag] && descriptor[artifactTag].meta
                ? (descriptor[artifactTag].meta || {}).displayName
                : (descriptor["flowos/conference"].meta || {}).displayName,
    };
}

export function getToolName(artifactId: string | undefined, artifactTag: ArtifactTag, resource?: ResourceFullWithContent<never, any>) {
    return {
        // @ts-expect-error
        nameByResource: resource && resource.content && resource.content.name,
        nameByDescriptor:
            artifactTag && artifactTag !== "flowos/conference" && descriptor[artifactTag] && descriptor[artifactTag].meta
                ? (descriptor[artifactTag].meta || {}).displayName
                : (descriptor["flowos/conference"].meta || {}).displayName,
    };
}
