import loadable from "@workhorse/api/loadable";
import {SessionSettingsSection} from "../../definitions";
import {ReactComponent as NotificationsIcon} from "../assets/session-notifications-icon.svg";

const NotificationsSection = loadable(() => import("./NotificationsSection"));
NotificationsSection.displayName = "Loadable:NotificationsSection";

const NotificationsSectionDefinition: SessionSettingsSection<"device.notifications"> = {
    translateKey: "device.notifications",
    kind: "device.notifications",
    title: "Notifications",
    component: NotificationsSection,
    icon: NotificationsIcon,
    rbac: {
        "session.isAssistant": true,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default NotificationsSectionDefinition;
