import {SessionFlagsType} from "@generated/data";
import MutedInformation from "./MutedInformation";
import ReactionsWrapper from "./Reactions/ReactionsWrapper";
import RecordingInformation from "./RecordingInformation";
import TranscriptInformation from "./TranscriptInformation";
import classes from "./style/LeftNotificationsWrapper.module.scss";
import {cls} from "@ui/cdk/util";
import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";

export const videoBasedArtifacts: Partial<SimpleArtifactsTag>[] = ["flowos/video", "flowos/vimeo", "flowos/youtube"];

type LeftNotificationsWrapperProps = {
    muteEveryone: string;
    enableReactionsType: SessionFlagsType;
    artifactId: SimpleArtifactsTag;
    sessionId: string;
    isAdmin: boolean;
    isAssistant: boolean;
    isRecorder: boolean;
    disabledNotifications: string[];
};

function LeftNotificationsWrapper(props: LeftNotificationsWrapperProps) {
    const {enableReactionsType, sessionId, artifactId, muteEveryone, isAdmin, isAssistant, isRecorder, disabledNotifications} = props;

    const isVideoArtifact = videoBasedArtifacts.includes(artifactId);

    return (
        <div className={cls(classes.root, isVideoArtifact && classes.rootVideoArtifact)}>
            {enableReactionsType && <ReactionsWrapper sessionId={sessionId} disabledNotifications={disabledNotifications} />}

            {isRecorder ? null : (
                <>
                    <MutedInformation
                        key={`muted-info-${sessionId}`}
                        muteEveryone={muteEveryone === "true"}
                        renderingForAdmin={isAdmin || isAssistant}
                        forcedMute={false}
                        artifactId={artifactId}
                    />
                    <RecordingInformation />
                    <TranscriptInformation />
                </>
            )}
        </div>
    );
}

export default LeftNotificationsWrapper;
