import {AgendaItemType} from "@generated/data";
import designer from "@workhorse/api/designer";
import {DesignerAction} from "../../action";
import {createAgendaItem} from "../agenda-item/create-agenda-item";

interface DetachAgendaInput {
    type?: AgendaItemType;
}

export class DetachAgendaAction extends DesignerAction<DetachAgendaInput> {
    commit() {
        const agendaItems =
            structuredClone(designer.currentAgendaItems() || [])
                .sort((a, b) => (a.order < b.order ? -1 : 1))
                // we can now delete items that are not created
                // .filter((a) => !!a.createdAt)
                .map((a) => ({
                    ...a,
                    isDeleted: true as boolean | null,
                })) ?? [];

        const newOne = createAgendaItem({
            order: 0,
            type: this.input.type ?? AgendaItemType.Instant,
            title: designer.constants.DUMMY_AGENDA_ITEM_TITLE,
        });

        agendaItems.push(newOne);

        designer.updateCurrentSession(
            {
                session: {
                    order: 0,
                    createdFromTemplateId: null,
                },
                agendaItems,
            },
            {
                strategy: "replace",
            }
        );

        return newOne.id;
    }
}
