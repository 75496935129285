import {AgendaItemType, AllTypeNamesWithoutInputs, OneSessionFragment, OneSessionFragmentDoc} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {readQuery} from "@workhorse/dataApi";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {DesignerApiSession as Session} from "@workhorse/declarations/dataTypes";
import deepmerge from "deepmerge";
import {PartialDeep} from "type-fest";
import {v4 as uuid} from "uuid";
import designer from ".";

export const makeId = () => uuid();

export const makeEmpty = <T extends AllTypeNamesWithoutInputs>(
    type: T,
    options: {
        id?: string;
        setUpdatedAt?: boolean;
        setCreatedAt?: boolean;
    } = {}
) => {
    let data: {
        createdAt?: Date;
        updatedAt?: Date;
    } = {};

    if (options.setCreatedAt) {
        data.createdAt = new Date();
    }

    if (options.setUpdatedAt) {
        data.updatedAt = new Date();
    }

    return {
        __typename: type,
        id: options.id ?? makeId(),
        ...data,
    };
};

export const typename = <T extends AllTypeNamesWithoutInputs>(type: T) => {
    return type;
};

export function patch<T, P extends PartialDeep<T>>(input: T, patch: P): T {
    return deepmerge<T, P>(input, patch, {
        arrayMerge: (target: any[], source: any[]) => source,
    }) as T;
}

export function strategicPatch<T, P extends PartialDeep<T>>(input: T, patch: P): T {
    return deepmerge<T, P>(input, patch) as T;
}

export const move = (arr: any[], from: number, to: number) => {
    const clone = [...arr];
    Array.prototype.splice.call(clone, to, 0, Array.prototype.splice.call(clone, from, 1)[0]);
    return clone;
};

export function removeTypenameKey<T>(
    data: T,
    opts?: {
        preserveTimestamps?: boolean;
        preserveDiffFields?: boolean;
    },
    level: number = 0
): T {
    const input = !level ? structuredClone(data) : data;
    if (typeof input == "object" && input != null) {
        // console.log("object");
        if (Array.isArray(input)) {
            // console.log("array");
            input.forEach((item) => {
                removeTypenameKey(item, opts, level + 1);
            });
        } else {
            const keys = Object.keys(input);
            for (let key of keys) {
                if (
                    ["__typename", "data", "update"]
                        .concat(opts?.preserveTimestamps ? [] : ["createdAt", "updatedAt"])
                        .concat(opts?.preserveDiffFields ? [] : ["oldId", "isDeleted"])
                        .includes(key)
                ) {
                    delete input[key];
                } else {
                    removeTypenameKey(input[key], opts, level + 1);
                }
            }
        }
    }
    return input;
}

export const durationBalancer = (
    agendaItemsArg: Session["agendaItems"],
    startAt?: Date | null,
    plannedEnd?: Date | null
): Session["agendaItems"] => {
    const agendaItems = structuredClone(agendaItemsArg).filter((a) => !a.isDeleted);
    // .sort((a, b) => b.order - a.order);
    const sessionDurationInSeconds = startAt && plannedEnd ? (new Date(plannedEnd).getTime() - new Date(startAt).getTime()) / 1000 : null;

    if (!sessionDurationInSeconds || agendaItems.length < 1) {
        return agendaItems;
    }

    let remainingTimeInSeconds = sessionDurationInSeconds;
    // - configuredDurationInSeconds;
    const unConfiguredAgendaItems = agendaItems;
    // .filter((p) => !p.userConfiguredDuration);
    if (unConfiguredAgendaItems.length === 0) {
        return agendaItems;
    }

    const timeForEachArtifactInMinutes = Math.floor(remainingTimeInSeconds / 60 / unConfiguredAgendaItems.length);

    if (timeForEachArtifactInMinutes < 1) {
        return agendaItems.map((a) => ({...a, duration: 60}));
    }

    const updateArtifact = (
        agendaItem: (typeof agendaItems)[0],
        totalRemainingTimeInSeconds: number,
        timeForArtifactInMinutes: number,
        lastAgendaItem: boolean
    ) => {
        const agendaItemIndex = agendaItems.findIndex((a) => a.id === agendaItem.id);
        if (agendaItemIndex !== -1) {
            agendaItems[agendaItemIndex].duration = lastAgendaItem ? totalRemainingTimeInSeconds : timeForArtifactInMinutes * 60;
        }

        return lastAgendaItem ? 0 : totalRemainingTimeInSeconds - timeForArtifactInMinutes * 60;
    };

    for (let i = 0; i < unConfiguredAgendaItems.length; i++) {
        remainingTimeInSeconds = updateArtifact(
            unConfiguredAgendaItems[i],
            remainingTimeInSeconds,
            timeForEachArtifactInMinutes,
            i === unConfiguredAgendaItems.length - 1
        );
    }
    return agendaItems;
};

export function getMaxSessionOrder() {
    const agendaItems = designer.currentAgendaItems();
    return (
        Math.max.apply(
            null,
            agendaItems.filter((a) => !a.isDeleted)?.map((a) => a.order)
        ) ?? 0
    );
}

export const computeSessionOrder = (agendaItems: Session["agendaItems"], sessionId: string, currentOrder: number) => {
    const existingAgendaItems = readQuery("LocalAgendaItemsDocument", {
        variables: {
            id: sessionId,
        },
    })?.agendaItems;

    if (!existingAgendaItems || !existingAgendaItems.length) {
        return 0;
    }

    if (
        agendaItems
            .filter((a) => !a.isDeleted)
            .every((a) => {
                return a.type === AgendaItemType.Instant;
            })
    ) {
        return Math.max.apply(
            null,
            agendaItems.map((a) => a.order)
        );
    }

    const currentAgendaItemForThatOrder = agendaItems.find((a) => a.order === currentOrder);

    const orderInDesigner = agendaItems.find((a) => a.id === currentAgendaItemForThatOrder?.id)?.order;

    return orderInDesigner ?? 0;
};

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) {
            return l;
        }
    }
    return -1;
}
