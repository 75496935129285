import designer from "@workhorse/api/designer";
import {DesignerAction} from "../../action";

interface RemoveAgendaItemInput {
    id: string;
    // this is used only for preview. Do not use it for other purposes
    forceDelete?: boolean;
}

export class RemoveAgendaItemAction extends DesignerAction<RemoveAgendaItemInput> {
    commit() {
        let agendaItems = structuredClone(designer.currentAgendaItems() || []).sort((a, b) => (a.order < b.order ? -1 : 1));
        const session = designer.currentSession();

        const indexToDelete = agendaItems.findIndex((agendaItem) => agendaItem.id === this.input.id);

        if (session && indexToDelete !== -1) {
            const agendaItem = agendaItems[indexToDelete!];

            if (agendaItem.processing) {
                if (agendaItem.createdAt) {
                    agendaItem.isDeleted = true;
                    agendaItems[indexToDelete!] = agendaItem;
                } else {
                    agendaItems.splice(indexToDelete, 1);
                }

                designer.updateCurrentSession(
                    {
                        agendaItems,
                    },
                    {
                        strategy: "replace",
                    }
                );
                return this.input.id;
            }

            const lenBeforeDelete = agendaItems.filter((a) => !a.isDeleted).length;

            // the following part applies only for when deleting items that HAVE a createdAt field
            if (agendaItem.createdAt) {
                agendaItem.isDeleted = true;
                agendaItems[indexToDelete!] = agendaItem;
            }
            // deleting un-commited data should pretty much stay as before
            else if (!agendaItem.createdAt) {
                agendaItems.splice(indexToDelete, 1);
            }

            if (session.order > lenBeforeDelete - 2) {
                designer.api.session.update({
                    order: lenBeforeDelete - 2 < 0 ? 0 : lenBeforeDelete - 2,
                });
            }

            agendaItems.forEach((a) => {
                if (a.order > agendaItem.order) {
                    // decrement the order of any agendaItems that have an order strictly larger than the one we just deleted
                    a.order = a.order - 1;
                }
            });

            if (this.input.forceDelete) {
                agendaItems = agendaItems.filter((a) => !a.isDeleted);
            }
        }

        designer.updateCurrentSession(
            {
                agendaItems,
            },
            {
                strategy: "replace",
            }
        );

        return this.input.id;
    }
}
