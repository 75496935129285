import loadable from "@workhorse/api/loadable";
import {RouteHOCProps, UserRouteMatch} from "@workhorse/declarations";

// this is one component that (probably) shouldn't be lazy-loaded
// still... the user routes are rendered on-demand
// after the onboarding or whatever... they become less accessed
// TBD
const User = loadable(() => import("./User"));
const Organization = loadable(() => import("./organization/Organization"));
const Workspace = loadable(() => import("./workspace/Workspace"));

interface UserHOCProps extends RouteHOCProps<UserRouteMatch> {}

function UserHOC(props: UserHOCProps) {
    const {location} = props;

    const isOrganizationSettings = location.pathname.includes("/user/organization");

    const isWorkspaceSettings = location.pathname.includes("/user/workspace");

    return (
        <div className="route-hoc user-hoc" key="user-hoc">
            {isOrganizationSettings ? (
                <Organization location={location} />
            ) : isWorkspaceSettings ? (
                <Workspace location={location} />
            ) : (
                <User location={location} />
            )}
        </div>
    );
}

export default UserHOC;
