import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import classes from "./event-header.module.scss";
import common from "./eventCommons.module.scss";

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {useHistory} from "@workhorse/api/routing";

import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";

import {
    DesignerSessionChangedByDocument,
    DesignerSessionLockedDocument,
    GetContactsDocument,
    PublishEventDocument,
    SessionEventState,
    SessionLifecycle,
} from "@generated/data";
import environment from "@generated/environment";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import Tooltip from "@ui/cdk/Tooltip";
import Fade from "@ui/core/components/Fade";
import apollo from "@workhorse/api/apollo";
import {deleteSession} from "@workhorse/api/calendar/session";
import {useMutation} from "@workhorse/api/data";
import designer from "@workhorse/api/designer";
import toast from "@workhorse/api/toast";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {Session} from "@workhorse/declarations/dataTypes";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {
    useDesignerSessionChangedBy,
    useDesignerSessionCommitState,
    useDesignerSessionLocked,
} from "@workhorse/providers/DesignerSessionDataProviders";
import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {orchestrator} from "@workhorse/providers/state";
import CopyToClipboard from "react-copy-to-clipboard";
import {openExternalLink} from "@workhorse/util/links";
import {enableEventLeaveModal} from "@workhorse/components/header/headerUtils";
import ShareIcon from "@material-ui/icons/Share";
import {EventEmbedDialog} from "./event-embed-dialog";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {paymentConfigState, upsertPaymentConfigs, usePaymentsHasUpdates} from "./event-payments/upsertPaymentConfigs";

type EventHeaderActionsProps = {
    session: Session;
    shouldWarnAboutSlug?: boolean;
};

const EventHeaderActions = (props: EventHeaderActionsProps) => {
    const {session, shouldWarnAboutSlug} = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [disabledStart, setDisabledStart] = useState(false);

    const shareEventDialogImperativeRef = useRef<DialogImperativeRef>();

    const history = useHistory();
    const currentParticipant = useCurrentParticipant();
    const commitInProgress = useDesignerSessionCommitState();

    const isLocked = useDesignerSessionLocked();
    const changedBy = useDesignerSessionChangedBy();
    const someoneEdited = changedBy?.length;

    const eventNotUpdatable = useMemo(
        () =>
            (session.lifecycle === SessionLifecycle.Started && !session.backstage) ||
            isLocked ||
            session.lifecycle === SessionLifecycle.Ended,
        [session.lifecycle, isLocked, session.backstage]
    );

    const [editedBy, setEditedBy] = useState<{firstName?: string; lastName?: string; photo?: string} | null>(null);

    const [disabled, setDisabled] = useState(false);

    const [timeoutNotify, setTimeoutNotify] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [publishEventError, setPublishEventError] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const {mixpanelTrack} = useMixpanelTracker();
    const paymentsUpdates = usePaymentsHasUpdates();

    useMemo(() => {
        if (!isLocked) {
            setEditedBy(null);
            return;
        }
        const lockedByEmail = apollo.cache.readQuery({
            query: DesignerSessionLockedDocument,
        })?.designerSessionLocked?.locked;

        if (lockedByEmail) {
            apollo.client
                .query({
                    query: GetContactsDocument,
                    variables: {
                        where: {
                            email: {
                                equals: lockedByEmail,
                            },
                        },
                    },
                })
                .then((r) => {
                    if (r.data?.contacts?.[0]) {
                        setEditedBy({
                            firstName: r.data.contacts[0].firstName,
                            lastName: r.data.contacts[0].lastName,
                            photo: r.data.contacts[0].userProfilePicture ?? undefined,
                        });
                    }
                });
        }
    }, [isLocked]);

    const [PublishEvent] = useMutation(PublishEventDocument);

    const openMenu = Boolean(menuAnchorEl);
    const canStartEvent = useMemo(() => {
        if (!disabledStart && session.startAt) {
            const difference = new Date(session.startAt).getTime() - new Date().getTime();
            if (session.lifecycle !== SessionLifecycle.NotStarted && !session.backstage) {
                return false;
            }
            if (session.event?.state === SessionEventState.Draft) {
                return false;
            }
            if (difference < 60 * 60 * 1000) {
                return true;
            }
        }

        return false;
    }, [session.startAt, session.lifecycle, disabledStart, session.event?.state, session.backstage]);

    const baseURL = environment.eventBaseURL + "/";

    useEffect(() => {
        if (session.lifecycle === SessionLifecycle.Started && !session.backstage) {
            window.location.href = environment.eventBaseURL + "/" + session.event?.slug;
        }
    }, [session.lifecycle, session.event?.slug, session.backstage]);

    const handleClickMenuPopover = useCallback(
        (event) => {
            setMenuAnchorEl(event.currentTarget);
        },
        [setMenuAnchorEl]
    );

    const handleCloseMenuPopover = useCallback(() => {
        setMenuAnchorEl(null);
    }, []);

    const handleOnSessionLinkCopy = () => {
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
        handleCloseMenuPopover();
        mixpanelTrack("frontend-copy-event-link", "events");
    };

    const toggleDeleteConfirmation = () => {
        setOpenDeleteDialog(!openDeleteDialog);
    };

    const handleDeleteEvent = async () => {
        await deleteSession(session.id);
        toggleDeleteConfirmation();
        history.replace("/events");
    };
    const joinEvent = () => {
        designer.state.initializeOrResetState(null);
        designer.clearPersistentStorage();
        designer.clearSessionStorage();
        designer.clearActionsHistory();
        window.location.href = `/session/${session.id}`;
    };

    const handlePreviewEvent = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const url = baseURL + session.event?.slug;

        e.preventDefault();
        e.stopPropagation();
        openExternalLink(url);
        handleCloseMenuPopover();
        mixpanelTrack("frontend-preview-event", "events");
    };

    const handlePublishEvent = async () => {
        setDisabled(true);

        setTimeoutNotify(false);
        setTimedOut(false);
        setPublishEventError(false);
        setTooltipOpen(false);

        if (session?.event?.id) {
            designer.api.event.update({
                state: SessionEventState.Published,
            });
            await designer.commit({
                persistEvent: true,
                source: "event-save",
            });

            await upsertPaymentConfigs(session.event?.id);

            await PublishEvent({
                variables: {
                    id: session.event.id,
                },
                context: {
                    notifyAfterMs: 5000,
                    shouldRetry: false,
                    abortAfterMs: 35000,
                    notifyCb: (failed, retryAttemptNo) => {
                        setTimeoutNotify((c) => (c !== failed ? failed : c));
                    },
                    timedOutCb: (failed, retryAttemptNo) => {
                        if (!failed) {
                            setPublishEventError(false);
                        }
                        setTimedOut((c) => (c !== failed ? failed : c));
                    },
                },
            })
                .then((res) => {
                    if (res?.data?.publishEvent) {
                        toast("Event published successfully!", {
                            type: "success",
                        });
                    }
                })
                .catch((error) => {
                    setPublishEventError(true);
                    console.log("publish event error: ", error);
                });
        } else {
            toast("Event not found!", {
                type: "error",
            });
        }
        mixpanelTrack("frontend-publish-event", "events");

        setDisabled(false);
    };

    const changes = apollo.cache.readQuery({
        query: DesignerSessionChangedByDocument,
    })?.designerSessionChangedBy?.changedBy?.length;

    const startSession = async () => {
        setDisabledStart(true);
        let startData;

        if (changes || isLocked) {
            enableEventLeaveModal(true);
            setDisabledStart(false);

            return;
        }

        if (session.backstage) {
            startData = await orchestrator.startSessionFromBackstage(session.id);
        } else {
            startData = await orchestrator.startSession(session.id);
        }
        setDisabledStart(false);
        if (startData?.data?.startSession?.lifecycle === SessionLifecycle.Started) {
            joinEvent();
        }
    };

    const joinBackstage = async () => {
        joinEvent();
    };

    const commitEventUpdate = async () => {
        await designer.commit({
            persistEvent: true,
            source: "event-save",
        });

        if (session.event?.id) {
            await upsertPaymentConfigs(session.event?.id);
        }

        mixpanelTrack("frontend-save-changes", "events");
    };

    const btnDisabled = publishEventError || timedOut ? false : disabled || timeoutNotify;

    const tooltipMsg = publishEventError || timedOut ? "Could not publish the event due to a network failure. Please try again." : "";

    const disableTooltip = !(publishEventError || timedOut);

    const actionsDisabled = eventNotUpdatable || commitInProgress || btnDisabled;

    const onPublishEventTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const onPublishEventTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleOnToggleShareDialog = () => {
        if (shareEventDialogImperativeRef?.current?.toggle) {
            shareEventDialogImperativeRef?.current?.toggle();
        }
        if (!shareEventDialogImperativeRef?.current?.isOpen) {
            mixpanelTrack("frontend-add-to-website", "events");
        }
    };

    return (
        <>
            <div className={cls("flex relative flex-align-center", classes.actionsContainer)}>
                <Button
                    variant="plain"
                    className={cls(classes.moreActions, openMenu && classes.moreActionsOpen)}
                    onClick={handleClickMenuPopover}
                >
                    <MoreVertRoundedIcon />
                </Button>

                <Button variant="plain" className={common.tertiaryButton} onClick={handlePreviewEvent} data-id="event-button-preview">
                    <OpenInNewRoundedIcon className="mr-6" />
                    Preview event
                </Button>
                {isLocked ? null : (
                    <>
                        {session.event?.state === SessionEventState.Draft ? (
                            <Tooltip
                                arrow
                                placement="bottom"
                                title={tooltipMsg}
                                open={publishEventError || timedOut || tooltipOpen}
                                onOpen={onPublishEventTooltipOpen}
                                onClose={onPublishEventTooltipClose}
                                disableFocusListener={disableTooltip}
                                disableHoverListener={disableTooltip}
                                disableTouchListener={disableTooltip}
                            >
                                <Button
                                    disabled={actionsDisabled}
                                    // loading={actionsDisabled}
                                    className={cls(common.actionButton, "editing")}
                                    onClick={handlePublishEvent}
                                    data-id="event-button-publish"
                                >
                                    Publish event
                                </Button>
                            </Tooltip>
                        ) : null}
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={shouldWarnAboutSlug ? "Please save or discard the URL changes first and then update the Event." : ""}
                        >
                            <Button
                                disabled={actionsDisabled || (!someoneEdited && !paymentsUpdates)}
                                className={cls(common.actionButton, "editing")}
                                onClick={shouldWarnAboutSlug ? undefined : commitEventUpdate}
                                // loading={actionsDisabled}
                                data-id="event-button-save-changes"
                            >
                                Save changes
                            </Button>
                        </Tooltip>
                    </>
                )}
                {editedBy ? (
                    <div className={cls("flex flex-align-center", classes.editingContainer)}>
                        <ProfilePicture
                            firstName={editedBy.firstName}
                            lastName={editedBy.lastName}
                            avatar={editedBy.photo}
                            avatarSize="S"
                            avatarVariant="rounded"
                        />
                        <div className={classes.editedText}>{editedBy.firstName} editing...</div>
                    </div>
                ) : null}
            </div>

            <Menu
                id="fade-menu"
                open={openMenu}
                anchorEl={menuAnchorEl}
                onClose={handleCloseMenuPopover}
                TransitionComponent={Fade}
                classes={{paper: classes.moreMenuPaper}}
                anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                transformOrigin={{horizontal: "center", vertical: -11}}
                getContentAnchorEl={null}
            >
                <MenuItem
                    className={cls(classes.menuItem)}
                    onClick={joinBackstage}
                    data-id="event-button-lobby"
                    variant="secondary"
                    disabled={session.event?.state !== SessionEventState.Published}
                >
                    <PlayArrowRoundedIcon />
                    Join lobby
                </MenuItem>

                <CopyToClipboard text={baseURL + session.event?.slug} onCopy={handleOnSessionLinkCopy}>
                    <MenuItem className={cls(classes.menuItem)} data-id="event-button-copy-link">
                        <FileCopyOutlinedIcon />
                        Copy event link
                    </MenuItem>
                </CopyToClipboard>

                <MenuItem onClick={handleOnToggleShareDialog} className={cls(classes.menuItem)} data-id="event-button-embed">
                    <ShareIcon />
                    Add to website
                </MenuItem>

                {currentParticipant.isOwner && session.lifecycle !== SessionLifecycle.Started ? (
                    <MenuItem
                        className={cls(classes.menuItem, "red")}
                        onClick={toggleDeleteConfirmation}
                        data-id="delete-event"
                        variant="destructive"
                    >
                        <DeleteRoundedIcon />
                        Delete event
                    </MenuItem>
                ) : null}
            </Menu>

            <ConfirmationDialog
                key="more-options-menu-delete-event-dialog"
                title="Delete event?"
                content="Are you sure you want to delete this event?"
                cancelButton="Cancel"
                minWidth
                submitButton="Delete"
                onClose={toggleDeleteConfirmation}
                open={openDeleteDialog}
                submitButtonVariant="destructive-secondary"
                onConfirm={handleDeleteEvent}
                variant="warning"
                isDeleteConfirmation
            />

            <EventEmbedDialog
                onClose={handleOnToggleShareDialog}
                eventSlug={session?.event?.slug}
                eventPayments={session?.event?.payments}
                imperativeRef={shareEventDialogImperativeRef}
                utm={session?.event?.utm}
                eventName={session?.name}
            />
        </>
    );
};

export default EventHeaderActions;
