import {RECORDING_USER_EMAIL} from "@common/recording/constants";
import artifactMap from "@generated/artifacts";
import type {SimpleArtifactsTag as ArtifactTag} from "@generated/artifacts/simple-map";
import {SendSessionFlagsDocument, SessionView as GeneratedSessionView} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useSpeakers} from "@workhorse/api/conference2";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";

import {createContextProvider} from "@workhorse/api/utils/context";
import {MAX_PINNED_PARTICIPANTS} from "@workhorse/pages/player/components/conferenceControls/OneParticipantActions/components/ParticipantPinAction";
import {useKeyboard} from "./KeyboardProvider";
import {useMobile} from "./MobileProvider";
import {useCurrentAgendaItem, useMyParticipant, useSession} from "./SessionDataProviders";
import {useContextualizedStorage} from "./StorageProvider";
import {useUserInfo} from "./User";
import {RecordingView} from "@workhorse/api/session-settings/sections/RecordingSection/components/RecordingViewSelect";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

export type SessionView = "presentation" | "collaboration" | "spotlight" | "audience";
export type SessionViewContext = "conference" | "tool";

const GENERATED_SESSION_VIEW_MAP: Record<GeneratedSessionView, SessionView> = {
    [GeneratedSessionView.Focus]: "presentation",
    [GeneratedSessionView.Presentation]: "presentation",
    [GeneratedSessionView.Collaboration]: "collaboration",
    [GeneratedSessionView.Default]: "collaboration",
    [GeneratedSessionView.None]: "collaboration",
    [GeneratedSessionView.Spotlight]: "spotlight",
    [GeneratedSessionView.Audience]: "audience",
};

const SESSION_VIEW_MAP: Record<SessionView | "none", GeneratedSessionView> = {
    presentation: GeneratedSessionView.Presentation,
    collaboration: GeneratedSessionView.Collaboration,
    spotlight: GeneratedSessionView.Spotlight,
    audience: GeneratedSessionView.Audience,
    none: GeneratedSessionView.None,
};

const DEFAULT_SESSION_VIEW = "collaboration";
const DEFAULT_SESSION_VIEW_IN_TOOL = "spotlight";

const updateView = async (sessionId: string, view: SessionView | "none") =>
    await apollo.client.mutate({
        mutation: SendSessionFlagsDocument,
        variables: {
            sessionId,
            flags: {
                view: SESSION_VIEW_MAP[view],
            },
        },
    });

const getArtifactId = (agendaItem: ReturnType<typeof useCurrentAgendaItem>): ArtifactTag =>
    agendaItem.artifact?.artifactId ? agendaItem.artifact?.artifactId : "flowos/conference";

const getView = (agendaItem: ReturnType<typeof useCurrentAgendaItem>): SessionView => {
    const output = artifactMap[getArtifactId(agendaItem)].defaultView as SessionView;
    return normalizeView(output);
};

function normalizeView(view: SessionView): SessionView {
    if (view === "presentation") {
        return "spotlight";
    }

    return view;
}

function decideRecordingView(
    context: SessionViewContext,
    areSpeakers: boolean,
    isSharing: boolean,
    recordingConferenceView: RecordingView,
    recordingPresentationView: RecordingView
) {
    if (areSpeakers) {
        return "spotlight";
    }

    const recordingView = context === "tool" || isSharing ? recordingPresentationView : recordingConferenceView;

    if (recordingView === RecordingView.Audience) {
        return "audience";
    }

    if (recordingView === RecordingView.Spotlight) {
        return "spotlight";
    }

    if (recordingView === RecordingView.Speaker) {
        return "spotlight";
    }

    if (recordingView === RecordingView.Gallery) {
        return "collaboration";
    }

    return "presentation";
}

const getViewContext = (agendaItem: ReturnType<typeof useCurrentAgendaItem>): SessionViewContext =>
    getArtifactId(agendaItem) !== "flowos/conference" ? "tool" : "conference";

const useSessionViewStore = () => {
    const {isKeyboardOpen} = useKeyboard();
    const {isMobile} = useMobile();

    const session = useSession();
    const agendaItem = useCurrentAgendaItem();

    const recordingConferenceView = (session.recordingConferenceView as RecordingView) ?? RecordingView.Gallery;
    const recordingPresentationView = (session.recordingPresentationView as RecordingView) ?? RecordingView.Spotlight;

    const speakers = useSpeakers();
    const areSpeakers = speakers.length > 0;

    const user = useUserInfo();
    const isRecordingUser = user?.email === RECORDING_USER_EMAIL;

    const {sharingParticipantId, isLocalUserSharing} = useScreenShareOptions();
    const isSharing = isLocalUserSharing || !!sharingParticipantId;

    const storage = useContextualizedStorage(session.id);
    const [myPinnedVideoStreams, setPinnedVideoStreams] = useState<string[]>(
        storage.getContextualizedItem("sessionStorage", "myPinnedVideoStreams", [])
    );

    const pinnedVideoStreamsMap = useMemo(() => {
        const map: Record<string, boolean | undefined> = {};

        for (const id of myPinnedVideoStreams) {
            map[id] = true;
        }

        return map;
    }, [myPinnedVideoStreams]);

    useEffect(() => {
        storage.setContextualizedItem("sessionStorage", "myPinnedVideoStreams", myPinnedVideoStreams);
    }, [myPinnedVideoStreams]);

    const ref = useRef({sessionId: session.id, ready: false});
    ref.current.sessionId = session.id;

    const {isOwner, isAssistant} = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            isOwner: currentParticipant?.isOwner ?? false,
            isAssistant: amIAssistant,
        }))
    );

    const isOrganizer = isOwner || isAssistant;

    const [forceWidgetSizeToMax, setForceWidgetSizeToMax] = useState(!!isRecordingUser && !!session.fullSizeVideosInRecording);

    const [storedView, setStoredView] = useState<SessionView>(() =>
        getViewContext(agendaItem) === "tool" ? DEFAULT_SESSION_VIEW_IN_TOOL : DEFAULT_SESSION_VIEW
    );

    const [hiddenAudienceBar, setHiddenAudienceBar] = useState(false);

    useEffect(() => {
        setForceWidgetSizeToMax((current) => (isRecordingUser ? !!session.fullSizeVideosInRecording : !!session.id ? current : false));
    }, [session?.fullSizeVideosInRecording, session?.id, isRecordingUser]);

    const setMyPinnedVideoStreams: React.Dispatch<React.SetStateAction<string[]>> = useCallback(
        (arg) => {
            const sessionView = session.view || GeneratedSessionView.None;

            const forced = !isOrganizer && sessionView !== GeneratedSessionView.None;
            const sync = isOrganizer && sessionView !== GeneratedSessionView.None;

            const ctx = isSharing ? "tool" : getViewContext(agendaItem);
            const view = forced || sync ? GENERATED_SESSION_VIEW_MAP[session.view] : storedView;
            if (ctx === "conference" && view === "collaboration") {
                // when pinning while in grid mode, must switch to audience mode
                set("audience", false);
            }
            setPinnedVideoStreams((current) => {
                // whether by accident or not, cannot pin while view is forced
                if (forced) {
                    return current;
                }
                let output = typeof arg === "function" ? arg(current) : arg;
                if (ctx === "conference" && view === "collaboration") {
                    // given this case switches to audience
                    // we don't want to remember whatever pinned ids we had before
                    output = output.filter((id) => !current.includes(id));
                }
                if (output.length > 4) {
                    return output.slice(0, 3);
                }
                return output;
                // technically, you can't pin more than one participant while in a tool context
                // because there's only one max floating widget
                // but the requirement is to allow pinning of up to 4 widgets
                // and make them show up at the beginning of the audience while showing the last one pinned in the floating widget
                // if(ctx === "tool" && view === "presentation" && output.length > 1){
                //     return output.slice(0,1);
                // }
                // return output;
            });
        },
        [session.view, isOrganizer, isSharing, storedView, agendaItem?.id]
    );

    const togglePinnedParticipant = useCallback(
        (participantId: string) => {
            setMyPinnedVideoStreams((current) => {
                if (current.includes(participantId)) {
                    return current.filter((id) => id !== participantId);
                }

                if (current.length === MAX_PINNED_PARTICIPANTS) {
                    toast(`You can pin up to ${MAX_PINNED_PARTICIPANTS} participants at a time.`, {
                        type: "error",
                        duration: 5000,
                    });
                    return current;
                }

                return [...current, participantId];
            });
        },
        [setMyPinnedVideoStreams]
    );

    const set = useCallback(
        (newContextualView: SessionView, force: boolean) => {
            if (force) {
                updateView(ref.current.sessionId, newContextualView);
            }

            setStoredView(newContextualView);
            storage.setContextualizedItem("localStorage", "user.sessionView", newContextualView, ref.current.sessionId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setStoredView]
    );

    // Force session view to the current host view
    const setSync = useCallback((view: SessionView | "none") => {
        updateView(ref.current.sessionId, view);
    }, []);

    // On first load, check if user had previously set the view or default locally stored view.
    // Also check if any speakers are in spotlight which sould default to `Speakers` view mode.
    useEffect(() => {
        if (!ref.current.ready && session.id && agendaItem.id) {
            ref.current.ready = true;
            const ctx = getViewContext(agendaItem);

            const predefinedView = speakers.length ? "spotlight" : ctx === "tool" ? DEFAULT_SESSION_VIEW_IN_TOOL : DEFAULT_SESSION_VIEW;
            const storedSessionView = storage.getContextualizedItem(
                "localStorage",
                "user.sessionView",
                predefinedView,
                ref.current.sessionId
            );
            const selectedView = ["spotlight", "audience"].includes(storedSessionView)
                ? storedSessionView
                : ctx === "tool"
                ? DEFAULT_SESSION_VIEW_IN_TOOL
                : DEFAULT_SESSION_VIEW;
            setStoredView(selectedView);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.id, agendaItem.id]);

    useEffect(() => {
        if (isRecordingUser) {
            return;
        }
        if (storedView === "collaboration" && getViewContext(agendaItem) === "tool") {
            setStoredView(DEFAULT_SESSION_VIEW_IN_TOOL);
        }
    }, [storedView, agendaItem.id]);

    useEffect(() => {
        if (!isSharing) {
            return;
        }

        if (isRecordingUser) {
            return;
        }

        if (storedView === "collaboration") {
            setStoredView("audience");
        }
    }, [storedView, isSharing]);

    useEffect(() => {
        if (!isSharing) {
            return;
        }
        return () => {
            setStoredView(DEFAULT_SESSION_VIEW);
        };
    }, [isSharing]);

    useEffect(() => {
        if (!session.id) {
            setStoredView(DEFAULT_SESSION_VIEW);
            storage.removeContextualizedItem("sessionStorage", "myPinnedVideoStreams");
        }
    }, [session]);

    return useMemo(() => {
        const sessionView = session.view || GeneratedSessionView.None;

        const forced = !isOrganizer && sessionView !== GeneratedSessionView.None;
        const sync = isOrganizer && sessionView !== GeneratedSessionView.None;

        const ctx = isSharing ? "tool" : getViewContext(agendaItem);
        let view = forced || sync ? GENERATED_SESSION_VIEW_MAP[session.view.toUpperCase()] : storedView;

        if (isRecordingUser && !forced) {
            view = decideRecordingView(
                getViewContext(agendaItem),
                areSpeakers,
                isSharing,
                recordingConferenceView,
                recordingPresentationView
            );
        }

        let audience = view === "audience";
        let grid = ctx === "conference" && view === "collaboration";
        let popup = ctx === "tool" && (view === "presentation" || view === "spotlight");

        // const pinnedModeEnabled = !forced; // !(ctx === "tool") && (!forced || view === "audience");
        const pinnedModeEnabled = false;

        if (isMobile) {
            audience = false;
            popup = false;
        }

        if (isKeyboardOpen) {
            audience = false;
        }

        const viewInfo = {
            ctx,
            view: normalizeView(view),
            chosen: normalizeView(storedView),
            avatar: isMobile,
            audience,
            grid,
            popup,
            set,
            setSync,
            forced,
            sync,
            forcedView: normalizeView(GENERATED_SESSION_VIEW_MAP[sessionView]),
            defaultView: normalizeView(isSharing ? "audience" : getView(agendaItem)),
            pinnedModeEnabled,
            myPinnedVideoStreams,
            hiddenAudienceBar,
            pinnedVideoStreamsMap,
            setMyPinnedVideoStreams,
            forceWidgetSizeToMax,
            setForceWidgetSizeToMax,
            setHiddenAudienceBar,
            togglePinnedParticipant,
        };

        return viewInfo;
    }, [
        agendaItem.artifact?.artifactId,
        set,
        storedView,
        areSpeakers,
        isSharing,
        isMobile,
        isKeyboardOpen,
        isOrganizer,
        session.view,
        myPinnedVideoStreams,
        forceWidgetSizeToMax,
        hiddenAudienceBar,
        pinnedVideoStreamsMap,
        setMyPinnedVideoStreams,
        setForceWidgetSizeToMax,
        togglePinnedParticipant,
        recordingConferenceView,
        recordingPresentationView,
    ]);
};

export const [SessionViewProvider, useSessionView] = createContextProvider(
    {
        name: "SessionView",
    },
    useSessionViewStore
);
