// TODO: maca - multiple workspaces - the most important one
import {AccessListItemInfoFragment, MemberFragment} from "@generated/data";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@ui/cdk/Button";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Switch from "@ui/cdk/Switch";
import TableBody from "@ui/cdk/Table/TableBody";
import TableCell from "@ui/cdk/Table/TableCell";
import {TableCellSize} from "@ui/cdk/Table/TableCell/types";
import TableHead from "@ui/cdk/Table/TableHead";
import TableHeadSortButton from "@ui/cdk/Table/TableHeadSortButton";
import TableRow from "@ui/cdk/Table/TableRow";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Dialog from "@ui/core/components/Dialog";
import DialogContent from "@ui/core/components/DialogContent";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import IconButton from "@ui/core/components/IconButton";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import {commitUpdateAgendaTemplate} from "@workhorse/api/designer/methods";
import {useCallback, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import WorkspaceMemberSelector from "@workhorse/pages/designer/CreateSession/WorkspaceMemberSelector";
import WorkspaceMembersListEmpty from "@workhorse/pages/designer/CreateSession/WorkspaceMembersListEmpty";
import {useCurrentTemplate} from "@workhorse/pages/templates/TemplateProviders";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {getCorrectPlural} from "@workhorse/util/strings";
import GiveAccessListItem from "./GiveAccessListItem";
import classes from "./style/GiveAccessDialog.module.scss";

type GiveAccessDialogProps = {
    open: boolean;
    onClose: () => void;
    giveAccess: (email: string) => Promise<boolean>;
    removeAccess: (id: string) => Promise<void>;
    resourceId?: string;
    agendatemplateId?: string;
    accessList: AccessListItemInfoFragment[];
    refetchAccessList: () => Promise<void>;
};

const GiveAccessDialog = (props: GiveAccessDialogProps) => {
    const {open, onClose, giveAccess, removeAccess, accessList, refetchAccessList} = props;

    const user = useUserInfo();
    const template = useCurrentTemplate();
    const commitInProgress = useDesignerSessionCommitState();

    const {userId: agendaOwnerId, isPublic: isAgendaPublic} = template;

    const workspaceAccess = useWorkspaceAccess();
    const canShareAgendaWithWorkspace = workspaceAccess.canShareAgendaWithWorkspace(agendaOwnerId);

    const [localAccessList, setLocalAccessList] = useState<AccessListItemInfoFragment[]>(accessList || []);

    const [localPublicAgenda, setLocalPublicAgenda] = useState<boolean>(!!isAgendaPublic);

    const [disabledPublicAgendaSwitch, setDisabledPublicAgendaSwitch] = useState<boolean>(false);

    // @ts-ignore TODO: jonny - fix this
    const collaboratorEmails = useMemo(() => localAccessList.map((item) => item.user.email), [localAccessList]);

    const handleToggleLocalPublicAgenda = () => {
        setLocalPublicAgenda(!localPublicAgenda);
    };

    const handleAddMember = (member: MemberFragment) => {
        setLocalAccessList((prev) => [
            ...prev,
            {
                id: member?.id,
                email: member?.email,
                user: {
                    id: member?.id,
                    firstName: member?.firstName,
                    lastName: member?.lastName,
                    email: member?.email,
                    profilePicture: member?.profilePicture ?? undefined,
                },
            },
        ]);
    };

    const removeFromList = (id: string) => {
        setLocalAccessList((prev) => prev.filter((item) => item.id !== id));
    };

    const handleConfirm = async () => {
        if (isAgendaPublic !== localPublicAgenda) {
            handleTogglePublicAgenda();
        }

        const totalEmails: string[] = [];

        for (const item of localAccessList) {
            totalEmails.push(item.user.email);
        }

        const emailsToAdd = [
            ...new Set(totalEmails.filter((emailToAdd) => accessList.findIndex((access) => access.user.email === emailToAdd) === -1)),
        ];
        const peopleToRemove = accessList.filter((item) => !localAccessList.find((access) => access.id === item.id));

        let hasError = false;

        for (const email of emailsToAdd) {
            const access = await giveAccess(email);
            if (!access) {
                hasError = true;
            }
        }

        for (const item of peopleToRemove) {
            removeAccess(item.id);
        }
        if (!hasError) {
            onClose();
        } else {
            refetchAccessList();
        }
        hasError = false;
    };

    useEffect(() => {
        setLocalAccessList(accessList);
    }, [accessList]);

    const handleTogglePublicAgenda = useCallback(async () => {
        setDisabledPublicAgendaSwitch(true);

        if (!isAgendaPublic) {
            const updated = await commitUpdateAgendaTemplate(true, undefined, false, true);

            if (updated?.isPublic) {
                setLocalPublicAgenda(true);
            }
        } else {
            const updated = await commitUpdateAgendaTemplate(false, undefined, false, true);

            if (!updated?.isPublic) {
                setLocalPublicAgenda(false);
            }
        }

        setDisabledPublicAgendaSwitch(false);
    }, [isAgendaPublic]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.root,
            }}
        >
            <DialogTitle className={cls("flex flex-align-center flex-justify-between", classes.header)}>
                <div className="flex flex-row items-center">
                    <Typography fontWeight="boldest" component="div" variant="xl" color="octonary">
                        Share agenda
                    </Typography>

                    <Typography variant="sm" color="secondary" fontWeight="bold" className={classes.count}>
                        {localAccessList.length} {getCorrectPlural("member", localAccessList.length)}
                    </Typography>
                </div>
                <IconButton onClick={props.onClose} className={classes.closeButton}>
                    <CloseRoundedIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className={cls("flex flex-col", classes.content)}>
                <WorkspaceMemberSelector filteredMemberEmails={collaboratorEmails} onAddMember={handleAddMember} />

                <TableContainer className={classes.tableContainer}>
                    <Table className={"flex flex-col overflow-hidden"}>
                        <TableBody className={classes.tableBody}>
                            {localAccessList.length > 0 ? (
                                <div className={classes.accessList}>
                                    {localAccessList.map((accessListItem) => (
                                        <GiveAccessListItem
                                            key={accessListItem.id}
                                            user={accessListItem.user}
                                            id={accessListItem.id}
                                            removeAccess={removeFromList}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <WorkspaceMembersListEmpty
                                    collaboratorEmails={collaboratorEmails}
                                    organizationName={user.activeOrganizationPermission.organization.name}
                                    title="Share this agenda with your workspace members"
                                    subtitle="You can add your workspace members to collaborate on the agenda"
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {canShareAgendaWithWorkspace && (
                    <div className={classes.workspaceAccess}>
                        <Typography variant="lg" fontWeight="bolder" color="secondary" className=" mb-8">
                            Workspace access
                        </Typography>
                        <div data-id="agendas-toggle-workspace-access">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={localPublicAgenda}
                                        onChange={handleToggleLocalPublicAgenda}
                                        disabled={disabledPublicAgendaSwitch || commitInProgress}
                                    />
                                }
                                label={<Typography>Grant access to workspace members to use this agenda</Typography>}
                                className={classes.workspaceAccessControl}
                            />
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogFooter className={classes.footer}>
                <Button data-id="agendas-button-close-share" variant="quaternary" onClick={onClose} key="cancel">
                    Cancel
                </Button>
                <Button data-id="agendas-button-share-done" variant="primary" onClick={handleConfirm} key="submit">
                    Done
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default GiveAccessDialog;
