import Typography from "@ui/cdk/Typography";
import classes from "./style/AgendaTemplatesTableEmptyStates.module.scss";
import Button from "@ui/cdk/Button";
import {useHistory} from "@workhorse/api/routing";
import OnboardingFeaturePlaceholder from "@workhorse/api/onboarding/components/OnboardingFeaturePlaceholder";

type AgendaTemplatesTableEmptyStatesProps = {
    isSearching: boolean;
};

const AgendaTemplatesTableEmptyStates = (props: AgendaTemplatesTableEmptyStatesProps) => {
    const history = useHistory();

    const handleNewAgenda = () => {
        history.push("/templates/my-agendas?create=create");
    };

    return (
        <>
            <div className={classes.root}>
                {props.isSearching ? (
                    <>
                        <Typography color="quaternary" fontWeight="bold" className={classes.title}>
                            No results found
                        </Typography>
                        <Typography color="quaternary" className={classes.subtitle}>
                            Create an agenda page to get started.
                        </Typography>
                        <Button className="mt-24" onClick={handleNewAgenda}>
                            New agenda
                        </Button>
                    </>
                ) : (
                    <OnboardingFeaturePlaceholder feature="agendaDashboard" />
                )}
            </div>
        </>
    );
};

export default AgendaTemplatesTableEmptyStates;
