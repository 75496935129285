import {ConferenceToolProps} from "@artifacts/conference/ui/MicroConferenceUi";
import artifactDescriptor, {ArtifactTag} from "@generated/artifacts";
import {cls} from "@ui/cdk/util";
import browserInfo from "@workhorse/api/BrowserInfo";
import React, {Fragment, useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";
import {getToolName} from "@workhorse/api/utils/getToolName";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import Loading from "@workhorse/components/Loading";
import {OnboardingPresentationControls} from "@workhorse/components/onboarding-tooltips/onboarding-presentation-controls";
import {MicroArtifactProps, WithChildren, WithMobileState} from "@workhorse/declarations";
import {evaluateExpression} from "@workhorse/internal/expression";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {useCurrentAgendaItem, useSession} from "@workhorse/providers/SessionDataProviders";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import ScreenShareRenderer from "../components/ScreenShareRenderer";
import {SpeakerPopup} from "../components/SpeakerPopup";
import SizerForIframeInMicros from "./SizerForIframeInMicros";
import {useShallow} from "zustand/react/shallow";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useEmbedSessions} from "@workhorse/api/embed";

const MicroConferenceLoader = artifactDescriptor["flowos/conference"].entries.find((entry) => entry.preloadCondition === "host.isPlayer")
    ?.loader as React.ComponentType<ConferenceToolProps>;

type MicroArtifactLoaderProps = {
    isAdmin: boolean;
    isAssistant: boolean;
    isLobby: boolean;
    sessionId: string;
    sessionStarted: boolean;
    myParticipantId: string;
    isQuickSession: boolean;
} & WithMobileState;

export default function MicroArtifactLoader(props: MicroArtifactLoaderProps) {
    const {isAssistant, isAdmin, mobileState, isLobby, sessionId, sessionStarted, myParticipantId, isQuickSession} = props;
    const currentAgendaItem = useCurrentAgendaItem();
    const {createdAt} = currentAgendaItem?.artifact ?? {};
    const currentMicroId = currentAgendaItem.artifact?.id ?? "";
    const artifactTag = currentAgendaItem.artifact?.artifactId ?? "flowos/conference";
    const isConferenceTool = artifactTag === "flowos/conference";

    const {functionalitiesToRemove} = useEmbedSessions();
    const conferenceDisabled = functionalitiesToRemove.includes("conference");

    const toolName = getToolName(
        currentMicroId,
        artifactTag ?? "flowos/conference"
        // TODO - @maca - data api
        // currentAgendaItem!.artifact!.resourceSlots?.[0]?.resource
    );

    const hostType = useHostType();

    const currentMicroComponents = useMemo(() => {
        return artifactTag
            ? artifactDescriptor[artifactTag]?.entries
                  .filter((entry) => {
                      return evaluateExpression({
                          exp: entry.preloadCondition,
                          type: "micro",
                          artifactEvaluation: entry.filter ? entry.filter : () => true,
                          isEditMode: false,
                          hostType,
                      });
                  })
                  .map((entry) => entry.loader)
            : [];
        // biome-ignore lint/complexity/noBannedTypes: <explanation>
    }, [currentMicroId, artifactTag]) as React.ComponentType<MicroArtifactProps<{}>>[];

    const prevMicroTag = useRef(artifactTag);

    useEffect(() => {
        prevMicroTag.current = artifactTag;
    }, [currentMicroId]);

    const toolChanged = artifactTag !== prevMicroTag.current;

    const preventMicroReMount = !toolChanged && artifactTag === "flowos/conference";
    const microRenderCond = isQuickSession || createdAt || isConferenceTool;
    // const myParticipant = useParticipant({participantId: myParticipantId});

    if (isConferenceTool && conferenceDisabled) {
        return <div className="bigWhiteScreen"></div>;
    }

    return (
        <>
            {((artifactTag && currentMicroId) || isQuickSession ? true : false) && (
                <WithSessionViewWrapper
                    isConferenceTool={isConferenceTool}
                    currentMicroTag={artifactTag}
                    currentMicroId={currentMicroId}
                    isAdmin={isAdmin}
                    isAssistant={isAssistant}
                    mobileState={mobileState}
                    toolName={toolName}
                    currentAgendaItemTitle={currentAgendaItem.title}
                    sessionId={sessionId}
                    myParticipantId={myParticipantId}
                >
                    <>
                        <Fragment key="micro-loaders">
                            {currentMicroComponents?.map((MicroLoader, i) => {
                                const isArtifactAssistant = rbac(myParticipantId, "agendaItems.edit", currentAgendaItem);
                                return (
                                    <ErrorBoundary
                                        key={"micro-" + (preventMicroReMount ? "loader" : currentMicroId + "-loader-" + i)}
                                        mobileState={mobileState}
                                        location="SessionContentMicroLoader"
                                    >
                                        <Fragment key="micro">
                                            {microRenderCond && (
                                                <MicroLoader
                                                    agendaItem={currentAgendaItem}
                                                    isAdmin={isAdmin}
                                                    isAssistant={isAssistant || isArtifactAssistant}
                                                    mobileState={mobileState}
                                                    inLobby={isLobby}
                                                    sessionId={sessionId}
                                                    myParticipantId={myParticipantId}
                                                    sessionStarted={sessionStarted}
                                                />
                                            )}
                                        </Fragment>
                                        <Fragment key="preload">{!microRenderCond && <Loading />}</Fragment>
                                    </ErrorBoundary>
                                );
                            })}
                        </Fragment>
                        <SizerForIframeInMicros key="sizer-for-micros" />
                    </>
                </WithSessionViewWrapper>
            )}
        </>
    );
}

type WithSessionViewWrapperProps = {
    isConferenceTool: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    currentMicroId: string;
    currentMicroTag: ArtifactTag;
    currentAgendaItemTitle?: string | null;
    toolName: ConferenceToolProps["toolName"];
    sessionId: string;
    myParticipantId: string;
} & WithChildren &
    WithMobileState;

function WithSessionViewWrapper(props: WithSessionViewWrapperProps) {
    const {mobileState, currentMicroId, currentMicroTag, myParticipantId} = props;

    const session = useSession()!;
    const isFirefox = browserInfo.isFirefox();
    const {ctx: viewContext, view} = useSessionView();
    const isMyTool = currentMicroTag === "flowos/my-product-tool";

    const {functionalitiesToRemove, uiElementsToRemove} = useEmbedSessions();

    const paddingsRemoved = uiElementsToRemove.includes("micro_margins");
    const conferenceDisabled = functionalitiesToRemove.includes("conference");

    const {canEnterSession} = useParticipantsStore(
        useShallow((state) => ({
            canEnterSession: state.canEnterSession,
        }))
    );

    if (!canEnterSession) {
        return null;
    }

    return (
        <div key="micro-main" className={cls("flex flex-col micro-main-window restricted-padding relative", isFirefox && "isFirefox")}>
            <div
                className={cls(
                    "micro-inner flex flex00-100 flex-col relative",
                    isMyTool && "no-border no-bkg my-tool",
                    viewContext === "conference" && "gridModeActive",
                    paddingsRemoved && "no-gutters"
                )}
            >
                {session.onboardingType === SessionOnboardingType.NewUser ? (
                    <OnboardingPresentationControls>
                        {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
                        <>{props.children}</>
                    </OnboardingPresentationControls>
                ) : (
                    props.children
                )}
            </div>

            <Fragment>
                {!conferenceDisabled && (
                    <ScreenShareRenderer
                        microId={currentMicroId}
                        artifactId={currentMicroTag}
                        sessionId={session.id}
                        mobileState={mobileState}
                        myParticipantId={myParticipantId}
                    />
                )}
            </Fragment>

            {!conferenceDisabled ? (
                <ErrorBoundary key={"speaker-popup"} location={"MicroArtifactLoader->SpeakerPopup"} mobileState={mobileState}>
                    <SpeakerPopup />
                </ErrorBoundary>
            ) : null}
        </div>
    );
}
