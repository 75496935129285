import artifactDescriptor, {ArtifactTag} from "@generated/artifacts";
import env from "@generated/environment";
import {ReactComponent as ChatIcon} from "../assets/media/macro-toggler-chat.svg";
import {ReactComponent as ParticipantsIcon} from "../assets/media/macro-toggler-participants.svg";
import {ReactComponent as PollsIcon} from "../assets/media/macro-toggler-polls.svg";
import {ReactComponent as QAIcon} from "../assets/media/macro-toggler-qa.svg";
import {ReactComponent as NotesIcon} from "../assets/media/macro-toggler-notes.svg";
import {ReactComponent as TimerIcon} from "../assets/media/macro-toggler-timer.svg";
import {ReactComponent as TranscriptIcon} from "../assets/media/macro-toggler-transcript.svg";
import {ReactComponent as TakeawaysIcon} from "../assets/media/macro-toggler-takeaways.svg";

import LoyaltyRoundedIcon from "@material-ui/icons/LoyaltyRounded";

export const getMacroArtifactIcon = (artifactId: ArtifactTag | "offers" | "timer") => {
    switch (artifactId) {
        case "flowos/chat":
            return <ChatIcon />;
        case "flowos/polls":
            return <PollsIcon />;
        case "flowos/qa":
            return <QAIcon />;
        case "flowos/contextual-notes":
            return <NotesIcon />;
        case "flowos/transcript":
            return <TranscriptIcon />;
        case "flowos/takeaways":
            return <TakeawaysIcon />;
        case "offers":
            return <LoyaltyRoundedIcon />;
        case "timer":
            return <TimerIcon />;

        default:
            return <ParticipantsIcon />;
    }
};

export const getArtifactImage = (src: string) => {
    return `${env.publicResourcesCdnRoot ?? ""}/icons/${src}.svg`;
};

export const getArtifactImageById = (artifactId: ArtifactTag) =>
    `${env.publicResourcesCdnRoot ?? ""}/icons/${artifactDescriptor[artifactId]?.meta?.icon}.svg`;
