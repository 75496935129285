import {SessionFlags, SessionFlagsType, SessionLifecycle} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useMemo} from "@workhorse/api/rendering";
import SessionAllowShareScreenSelector from "@workhorse/api/session-settings/sections/SessionSection/components/SessionAllowShareScreenSelector";
import SessionDisableParticipantsCameraSelector from "@workhorse/api/session-settings/sections/SessionSection/components/SessionDisableParticipantsCameraSelector";
import SessionHardMuteSelector from "@workhorse/api/session-settings/sections/SessionSection/components/SessionHardMuteSelector";
import SessionHideNonStreamingParticipantsToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionHideNonStreamingParticipantsToggler";
import SessionReactionsSelector from "@workhorse/api/session-settings/sections/SessionSection/components/SessionReactionsSelector";
import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import commonClasses from "../eventCommons.module.scss";
import classes from "./event-permissions.module.scss";

type EventPermissionsProps = {
    isRoom?: boolean;
    onChange?: () => void;
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
};

export function EventPermissions(props: EventPermissionsProps) {
    const {isRoom, sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed} = props;
    const session = useSession();

    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => (!isRoom ? [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked : false),
        [session.lifecycle, isLocked]
    );

    const handleOnChange = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey]) => {
        if (!isRoom) {
            lockEvent({
                variables: {
                    id: session.id,
                },
            });
        }

        designer.api.session.update({
            [key]: value,
        });
        props.onChange?.();

        if (!isRoom) {
            designer.commit();
        }
    };

    return (
        <div className={commonClasses.mainContainer}>
            <div className="flex flex-align-center flex-justify-between">
                <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-24">
                    Participants permissions
                </Typography>
            </div>

            <SessionAllowShareScreenSelector
                value={session.allowScreenShareType}
                onChange={handleOnChange.bind(null, "allowScreenShareType")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />
            <div className={classes.separator} />

            <SessionReactionsSelector
                value={session.enableReactionsType as SessionFlagsType}
                onChange={handleOnChange.bind(null, "enableReactionsType")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <SessionDisableParticipantsCameraSelector
                value={session.allowCameraType}
                onChange={handleOnChange.bind(null, "allowCameraType")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <SessionHardMuteSelector
                value={session.allowMicrophoneType}
                onChange={handleOnChange.bind(null, "allowMicrophoneType")}
                disabled={eventNotUpdatable}
                showIcon={true}
            />

            <div className={classes.separator} />

            <SessionHideNonStreamingParticipantsToggler
                enabled={session.hideNonStreamingParticipants}
                onChange={handleOnChange.bind(null, "hideNonStreamingParticipants")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <SessionTemplateSettingsNotification
                type="event"
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={commonClasses.sessionTemplateNotification}
            />
        </div>
    );
}
