import {ResourceProcessingDetails} from "@api/resx/processing";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {VersionedSpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {ResourceFullFragment, ResourceResultFullFragment} from "@generated/data";

export type ResourceFullWithContent<
    T extends ResourcesTypes = never,
    K extends keyof VersionedSpecifiersTypeMap[T]["resource"] = any
> = Omit<ResourceFullFragment, "content" | "processingDetails"> & {
    content: VersionedSpecifiersTypeMap[T]["resource"][K];
    processingDetails: ResourceProcessingDetails;
};

export type ResultFullWithContent<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["result"]> = Omit<
    ResourceResultFullFragment,
    "content" | "resource"
> & {
    content: VersionedSpecifiersTypeMap[T]["result"][K];
    resource?: ResourceFullWithContent<T, K>;
};

export function internalFixResource<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["resource"]>(
    resource: ResourceFullFragment
): ResourceFullWithContent<T, K> {
    const content = JSON.parse(resource?.content);
    const processingDetails = JSON.parse(resource.processingDetails);

    return {
        ...resource,
        content,
        processingDetails,
    };
}

export function getResourceFromFragment<T extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[T]["resource"]>(
    resource: ResourceFullFragment
): ResourceFullWithContent<T, K> {
    return internalFixResource<T, K>(resource);
}
