import {useEffect} from "@workhorse/api/rendering";
import {setWorkerTimeout} from "@workhorse/timerWorker";

type WebkitWindow = typeof window & {
    webkitAudioContext: AudioContext;
};

export const AudioContext = window.AudioContext || (window as WebkitWindow).webkitAudioContext;

export class AudioDbAnalyzer {
    private stopped = false;
    private frameIndex = 0;
    private lastDecimal: number;
    private data: Uint8Array;

    constructor(public analyserNode: AnalyserNode, public cb: (db: number) => void, private useWorker = false) {
        this.data = new Uint8Array(analyserNode.fftSize);
    }

    private analyserNodeCallback = () => {
        if (this.stopped) {
            return;
        }

        if (this.frameIndex === 0) {
            this.analyserNode.getByteTimeDomainData(this.data);
            const lowest = 0.01;
            let max = lowest;

            for (const f of this.data) {
                max = Math.max(max, (f - 128) / 128);
            }

            const decimal = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);

            if (this.lastDecimal !== decimal) {
                this.lastDecimal = decimal;
                this.cb(decimal);
            }
        }

        this.frameIndex = (this.frameIndex + 1) % 2;

        if (this.useWorker) {
            setWorkerTimeout(this.analyserNodeCallback, 16);
        } else {
            requestAnimationFrame(this.analyserNodeCallback);
        }
    };

    start = () => {
        this.stopped = false;
        this.analyserNodeCallback();
    };

    stop = () => {
        this.stopped = true;
    };
}

export function useAudioDbAnalyzer(analyserNode: AnalyserNode | null, cb?: (db: number) => void) {
    useEffect(() => {
        if (!analyserNode || !cb) {
            return;
        }

        const analyzer = new AudioDbAnalyzer(analyserNode, cb);
        analyzer.start();

        return () => {
            analyzer.stop();
        };
    }, [analyserNode, cb]);
}
