import {createElement, forwardRef} from "react";
import Badge from "../Badge/Badge";
import styles from "./style/avatar.module.scss";
import {AvatarComponents, AvatarProps} from "./types";
import {cls, capitalize} from "@ui/cdk/util/util";

function AvatarWithRef<T extends AvatarComponents = "div">(props: AvatarProps<T>, ref?: any) {
    const {
        /**
         * @param {Node | Node[]} component Component type. @default "div"
         */
        component = props.imgProps?.src ? "img" : "div",
        /**
         * @param {AvatarVariantTypes} variant The variant of the avatar component. @default "circular"
         */
        variant = "circular",
        /**
         * @param {Node | Node[]} children Used to render icon or text elements inside the component if src is not set. This can be an element, or just a string.
         */
        children,
        /**
         * @param {AvatarImgProps} imgProps Attributes applied to the img element if the component is used to display an image. It can be used to listen for the loading error event.
         */
        imgProps,
        /**
         * @param {JSX.Element} iconLeft Adds an icon to the bottom left of the component.
         */
        iconLeft,
        /**
         * @param {JSX.Element} iconRight Adds an icon to the bottom right of the component.
         */
        iconRight,
        /**
         * @param {AvatarSizeTypes} size The size of the component. @default "M"
         */
        size = "M",
        /**
         * @param {BadgeProps} badgeProps Passes the props to the Badge component through the Avatar.
         */
        badgeProps,
        /**
         * @param {String | JSX.Element} labelPrimary Adds a primary label to the right of the component.
         */
        labelPrimary,
        /**
         * @param {String | JSX.Element} labelSecondary Adds a secondary label to the right of the component.
         */
        labelSecondary,
        /**
         * @param {String} backgroundColor The background color of the component. @default "$main-palette-blue-200"
         */
        backgroundColor,
        /**
         * @param {Boolean} className Makes the border of the Avatar white. @default false
         */
        withWhiteBorder = false,
        /**
         * @param {String} className Adds a className to the root of the component.
         */
        className,
        /**
         *  @param {Boolean} set size 1px for border
         */
        smallBorder = false,
        ...rest
    } = props;

    const classNamesRoot = cls("fosAvatar", styles.root, className);

    const classNamesAvatar = cls(
        styles.avatarRoot,
        size && styles[`avatarSize${size}`],
        variant && styles[`avatarVariant${capitalize(variant)}`]
    );

    return (
        <div className={classNamesRoot} ref={ref} key="avatar">
            <Badge className={classNamesAvatar} {...badgeProps} key="avatar-badge" role="presentation">
                {createElement(
                    component,
                    {
                        className: cls(
                            styles.avatar,
                            "avatar",
                            withWhiteBorder && styles.withWhiteBorder,
                            smallBorder && styles.smallBorder
                        ),
                        ...imgProps,
                        ...rest,
                        key: "avatar-component",
                        style: {backgroundColor},
                        "aria-hidden": "true",
                    },
                    children
                )}
                {iconRight && (
                    <div className={styles.iconRight} key="avatar-icon-right">
                        {iconRight}
                    </div>
                )}
                {iconLeft && (
                    <div className={styles.iconLeft} key="avatar-icon-left">
                        {iconLeft}
                    </div>
                )}
            </Badge>
            {(labelPrimary || labelSecondary) && (
                <div className={styles.textRoot} key="avatar-text">
                    {labelPrimary && (
                        <div className={styles.labelPrimary} key="avatar-text-primary">
                            {labelPrimary}
                        </div>
                    )}
                    {labelSecondary && (
                        <div className={styles.labelSecondary} key="avatar-text-secondary">
                            {labelSecondary}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const Avatar = forwardRef(AvatarWithRef) as (props: AvatarProps & {ref?: any}) => ReturnType<typeof AvatarWithRef>;

export {AvatarWithRef};

export default Avatar;
