import {
    ConfigurationStep,
    DesignerState,
    DesignerCommitState,
    DesignerSessionId,
    DesignerEditState,
    DesignerSessionLocked,
} from "@generated/data";

export const designerDefaultState: DesignerState = {
    sessionId: "",
    selectedAgendaItemId: null,
    selectedMacroIdToConfig: null,
    selectedMicroIdToConfig: null,
    selectedMacroIdToPreview: null,
    selectedMicroIdToPreview: null,
    selectedResourceId: null,
    typeOfBlockToAdd: null,
    isAddingBlock: false,
    isConfig: false,
    isPreview: false,
    deleteAgendaItemIds: [],
    templateMode: false,
    toolStep: null,
    isSessionPreview: false,
    __typename: "DesignerState",
    checkedRecurrenceEditForSessionId: null,
    recurrenceEditType: null,
    configurationStep: ConfigurationStep.Preview,
    dirty: true,
    followUpOfSessionId: null,
    preventRestore: false,
    configurationConfirm: null,
    configInProgress: false,
};

export const designerCommitDefaultState: DesignerCommitState = {
    __typename: "DesignerCommitState",
    commitInProgress: false,
};

export const designerSessionIdDefault: DesignerSessionId = {
    __typename: "DesignerSessionId",
    sessionId: null,
};

export const designerSessionLockedDefault: DesignerSessionLocked = {
    __typename: "DesignerSessionLocked",
    locked: undefined,
};

export const designerEditStateDefault: DesignerEditState = {
    __typename: "DesignerEditState",
    sessionId: "",
    isEditing: false,
};
