import {ConferenceParticipantStatus} from "@generated/data";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {
    useHaveActionsOnParticipant,
    useParticipantActions,
} from "@workhorse/api/conference2/providers/ParticipantsProvider/ParticipantActionsProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {ReactComponent as StopSpotlight} from "../../../frontend/assets/media/stop-spotlight-icon.svg";
import {ReactComponent as MaximizeIcon} from "../../../frontend/assets/media/maximize-popup.svg";
import {ReactComponent as MinimizeIcon} from "../../../frontend/assets/media/minimize-popup.svg";
import {setParticipantConferenceRole} from "../api/conferenceControllerApi";
import classes from "./styles/SpeakerActionsContainer.module.scss";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import {toggleAssistantRole} from "@artifacts/conference/api/conferenceControllerApi";
import Tooltip from "@ui/cdk/Tooltip";
import {useTranslation} from "react-i18next";
import TogglePinParticipantButton from "@workhorse/pages/player/components/TogglePinParticipantButton";

type SpeakerActionsContainerProps = {
    showPinButton?: boolean;
    participantId: string;
    hasSpotlightBtn?: boolean;
    location: string;
    onResetPosition?: () => void;
    isMinimized?: boolean;
    toggleSize?: () => void;
    resize?: boolean;
    sessionId: string;
    participantIsAssistant: boolean;
};

const SpeakerActionsContainer = (props: SpeakerActionsContainerProps) => {
    const {t} = useTranslation();
    const {participantId, hasSpotlightBtn, location, onResetPosition, resize, isMinimized, toggleSize, participantIsAssistant, sessionId} =
        props;

    const {value, open, isOpen} = useParticipantActions();
    const [hasActions, actions] = useHaveActionsOnParticipant(participantId);
    const {selfRemoveFromSpotlight, removeFromSpotlight, makeAssistant, showMakeAssistantDisabled, removeAssistant} = actions;
    const {isMobile} = useMobile();

    const handleLeaveSpotlight = () => {
        setParticipantConferenceRole(participantId, ConferenceParticipantStatus.Participant);
    };

    const handleMoreOptions = (e: React.MouseEvent<HTMLButtonElement>) => {
        open({
            participantId: participantId,
            anchorEl: e.currentTarget,
            classes: {
                paper: classes.moreOptionsPaper,
            },
            className: `${location}-speaker-actions-container`,
            anchorOrigin: {vertical: "bottom", horizontal: "right"},
            transformOrigin: {vertical: "top", horizontal: "right"},
        });
    };

    const isMoreOptionsMenuOpen =
        participantId === value?.participantId && value.className === `${location}-speaker-actions-container` && isOpen;

    const handleAssistantRole = () => {
        toggleAssistantRole(participantId, sessionId);
    };

    return (
        <div className={cls("speaker-actions-container", classes.root, isMoreOptionsMenuOpen && "open")}>
            {props.showPinButton && <TogglePinParticipantButton className="mr-auto ml-0" participantId={props.participantId} />}

            {!isMinimized && !showMakeAssistantDisabled && !isMobile && (makeAssistant || removeAssistant) ? (
                <Button
                    data-id="stop-spotlight"
                    variant="plain"
                    onClick={handleAssistantRole}
                    className={cls("rnd-btn", classes.button, classes.buttonSpotlight)}
                    noFocusBorder
                >
                    <StarRoundedIcon />{" "}
                    <span>{t(participantIsAssistant ? "participant.action.remove_assistant" : "participant.action.make_assistant")}</span>
                </Button>
            ) : null}

            {hasSpotlightBtn && (selfRemoveFromSpotlight || removeFromSpotlight) && !isMobile && (
                <Button
                    data-id="stop-spotlight"
                    variant="plain"
                    onClick={handleLeaveSpotlight}
                    className={cls("rnd-btn", classes.button, classes.buttonSpotlight)}
                >
                    <StopSpotlight /> <span>{t("participant.action.stop_spotlight")}</span>
                </Button>
            )}

            {hasActions ? (
                resize && isMinimized ? null : (
                    <IconButton
                        data-id="speaker-actions"
                        onClick={handleMoreOptions}
                        className={cls("rnd-btn", classes.button, classes.buttonMoreOptions)}
                    >
                        <MoreVertRoundedIcon />
                    </IconButton>
                )
            ) : null}

            {resize ? (
                isMinimized ? (
                    <IconButton onClick={toggleSize} className={cls("rnd-btn", classes.button, classes.buttonMoreOptions)}>
                        <MaximizeIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={toggleSize} className={cls("rnd-btn", classes.button)}>
                        <MinimizeIcon />
                    </IconButton>
                )
            ) : null}
        </div>
    );
};

export default SpeakerActionsContainer;
