import {StreamManager, StreamManagerResult} from "./StreamManager";
import {equals} from "ramda";
import {getDeviceIdFromStream} from "../../utils";
import {Queue} from "async-await-queue";
import {AudioAnalyzer} from "../../hooks/useLocalAudioDbAnalyzer";
import {AudioStreamTranscriber} from "./AudioStreamTranscriber";
import {NoiseReduction} from "../NoiseReduction";
import {verifyDesktopPermission} from "./desktop-permission";

export interface AudioStreamManagerResult extends StreamManagerResult {
    noiseSuppressedStream?: MediaStream;
}

export class AudioStreamManager extends StreamManager<AudioStreamManagerResult> {
    protected noiseReduction = false;
    protected audioAnalyzer?: AudioAnalyzer;

    audioDetected = false;
    audioDetectionTimeout?: number;

    audioTranscriber?: AudioStreamTranscriber;
    audioTranscriberMuted = true;
    audioTranscriberQueue = new Queue<void>(1);

    private noiseReductionProcessor: NoiseReduction | null = null;

    getDefaultConstraints() {
        const googleConstraints = {
            googEchoCancellation: true,
            googEchoCancellation2: true,
            googAutoGainControl: true,
            googAutoGainControl2: true,
            googNoiseSuppression: true,
            googNoiseSuppression2: true,
            googHighpassFilter: true,
        } as MediaTrackConstraints;

        const constraints: MediaTrackConstraints = {
            ...googleConstraints,
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            channelCount: {
                ideal: 1,
            },
        };
        return constraints;
    }

    createConstraints(constraints?: MediaTrackConstraints | null): MediaStreamConstraints {
        return {
            video: false,
            audio: {
                ...this.getDefaultConstraints(),
                ...constraints,
            },
        };
    }

    updateConstraints(constraints?: MediaTrackConstraints | null) {
        this.constraints = this.createConstraints(constraints);
    }

    constraintsDidChange(constraints?: MediaTrackConstraints | null) {
        return !equals(this.constraints, this.createConstraints(constraints));
    }

    async stopStream() {
        this.audioDetected = false;
        window.clearTimeout(this.audioDetectionTimeout);
        this.audioAnalyzer?.stop();
        this.audioTranscriber?.stop();
        this.stopNoiseReduction();
        return super.stopStream();
    }

    async updateNoiseReduction(enabled: boolean) {
        this.noiseReduction = enabled;
        this.stopNoiseReduction();
        return this.createNoiseReduction();
    }

    async setStream(constraints?: MediaTrackConstraints | null) {
        const desktopPermission = await verifyDesktopPermission("microphone");

        // true by default for non-desktop
        if (!desktopPermission) {
            return this.createDesktopNotAllowedResult();
        }

        const result = await this.replaceStream(this.createConstraints(constraints));

        if (result.error) {
            this.events.emit("audio-error", result.error);
        }

        if (result.stream) {
            this.audioAnalyzer = new AudioAnalyzer(result.stream, this.onAudioVolume, false, true);
            this.audioAnalyzer.start();
            this.events.emit("audio-success");
        }

        const deviceId = getDeviceIdFromStream(result.stream);

        if (deviceId) {
            this.updateConstraints({
                ...constraints,
                deviceId: {exact: deviceId},
            });
        }

        return result;
    }

    protected stopNoiseReduction() {
        this.noiseReductionProcessor?.destroy();
        this.noiseReductionProcessor = null;
    }

    protected createNoiseReduction() {
        if (!this.noiseReduction || !this.stream || !this.isActive()) {
            return;
        }

        try {
            this.noiseReductionProcessor = new NoiseReduction(this.stream);
            return this.noiseReductionProcessor.getOutputStream();
        } catch (e) {
            console.error("noise reduction: failed to create", e);
        }
    }

    protected createDesktopNotAllowedResult(): AudioStreamManagerResult {
        const error = new DOMException("Not allowed by system", "NotAllowedError");
        this.events.emit("audio-error", error);

        return {
            error,
            stream: null,
        };
    }

    muteTranscriber() {
        this.audioTranscriberMuted = true;
        this.audioTranscriber?.stop();
    }

    unmuteTranscriber() {
        this.audioTranscriberMuted = false;
        this.audioTranscriber?.start();
    }

    protected createTranscriber() {
        if (!this.stream) {
            return;
        }

        try {
            this.audioTranscriber = new AudioStreamTranscriber(this.stream, this.audioTranscriberQueue);

            if (!this.audioTranscriberMuted) {
                this.audioTranscriber.start();
            }
        } catch (e) {
            console.error("transcriber: failed to create", e);
        }
    }

    protected async createStream(constraints: MediaStreamConstraints) {
        const result: AudioStreamManagerResult = await super.createStream(constraints);
        // result.noiseSuppressedStream =  this.createNoiseReduction();
        this.createTranscriber();
        return result;
    }

    protected onAudioVolume = (db: number) => {
        this.events.emit("audio-volume", db);
        this.audioTranscriber?.addNoise(db);

        if (db === 0) {
            return;
        }

        this.audioDetected = true;
        this.events.emit("audio-detected");
        window.clearTimeout(this.audioDetectionTimeout);

        this.audioDetectionTimeout = window.setTimeout(() => {
            this.audioDetected = false;
        }, 1000);
    };
}
