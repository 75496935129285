import type {SessionUpdateInput} from "../../generated/data-types";

import buildAgendaItemsUpdatePayload from "./buildAgendaItemsUpdatePayload";
import buildEventUpdatePayload from "./buildEventUpdatePayload";
import buildMacroArtifactsUpdatePayload from "./buildMacroArtifactsUpdatePayload";
import {Session, OmitTypenames, ArrayItem} from "../types";
import buildRoomUpdatePayload from "./buildRoomUpdatePayload";

// if the Session model ever changes, add excluded fields that cannot be updated, in this type
// everything will fail in cascade and force you to adapt the logic below
type ExcludedSessionKeys =
    | "actualStart"
    | "artifacts"
    | "chat"
    | "childOfBreakoutRooms"
    | "chimeMeeting"
    | "createdAt"
    | "deletionInProgress"
    | "draftForLiveSession"
    | "flow"
    | "id"
    | "lifecycle"
    | "liveDraft"
    | "notifications"
    | "occurrenceId"
    | "updatedAt"
    | "endedAt"
    | "createdFromYourFirstSessionTemplate"
    | "followUpOfSessionId"
    | "isChildOfBreakoutRooms"
    | "recordings"
    | "speakerUserIds"
    | "templateFolder"

    // participants never get updated via this method
    | "participants";

type SessionUpdateableKeys = Exclude<keyof SessionUpdateInput, ExcludedSessionKeys>;

const updateableKeys: Array<SessionUpdateableKeys> = [
    "agendaItems",
    "allowScreenShareType",
    "description",
    "enableReactionsType",
    "endByWorker",
    "allowMicrophoneType",
    "isPrivate",
    "allowAgendaType",
    "memoryAccessType",
    "macroArtifacts",
    "name",
    "createdFromTemplate",
    "order",
    "plannedEnd",
    "quickSession",
    "transcriptionActive",
    "recurrenceData",
    "requestPermissionToJoin",
    "requireConsentToRecord",
    "sendEmailsAutomatically",
    "hideNonStreamingParticipants",
    "reminderInMinutes",
    "autoRecording",
    "askForGuestEmail",
    "allowCameraType",
    "shareScreenPid",
    "source",
    "startAt",
    "timeDependency",
    "timeZone",
    "onboardingType",
    "event",
    "room",
    "reminders",
    "messageReminders",
    "syncToAgenda",
    "restrictedWidgets",
    "autoTranscribing",
    "disabledNotifications",
    "autoStartSession",
    "sendEndSessionEmail",
    "fullSizeVideosInRecording",
    "recordingConferenceView",
    "recordingPresentationView",
    "passcodeToJoin",
    "provideSessionPasscode",
    "bypassSessionPasscode",
    "groupChatAccess",
    "privateChatAccess",
    "participantsMacroAccess",
    "exitPageOfferId",
    "exitPageOffer",
    "collectFeedback",
    "showExitPageCta",
    "hideIcsGuestList",
    "livestreamOutputUrls",
    "recordingType",
    "feedbackFormId",
    "feedbackForm",
];

type SessionUpdateInputPayload = OmitTypenames<Session>;

export default function buildUpdateSessionPayload(input: SessionUpdateInputPayload, sessionData: Session): SessionUpdateInput {
    const {agendaItems, macroArtifacts, session} = input;

    if (!session) {
        throw new Error(`cannot build update payload for session because... no session duah!`);
    }
    // const changedData = designer.changedData();
    const updatePayload: Pick<SessionUpdateInput, SessionUpdateableKeys> = {};
    // console.log("recurrence: 0 ", session);
    // biome-ignore lint/complexity/noForEach: <explanation>
    updateableKeys.forEach((k) => {
        switch (k) {
            case "allowScreenShareType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "description": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "enableReactionsType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "endByWorker": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "createdFromTemplate": {
                // this field is not included in session fragments so we use the id only to connect. The other operations are done in backend
                // redundant check for lifecycle
                if (session.createdFromTemplateId && session.lifecycle !== "STARTED") {
                    updatePayload[k] = {
                        connect: {
                            id: session.createdFromTemplateId,
                        },
                    };
                }
                break;
            }
            case "allowMicrophoneType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            // case "instanceOfRecurrence": {
            // TODO: @Maca/Andrei Codreanu
            // can this be updated here?
            // if(k in session){
            //     updatePayload[k] = {
            //         set: session[k]
            //     }
            // }
            // break;
            // }
            case "isPrivate": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k] as boolean,
                    };
                }
                break;
            }
            case "memoryAccessType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "allowAgendaType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "hideNonStreamingParticipants": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "reminderInMinutes": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }

            case "autoRecording": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }

            case "passcodeToJoin": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "provideSessionPasscode": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "bypassSessionPasscode": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "groupChatAccess": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "privateChatAccess": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "participantsMacroAccess": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "name": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "order": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "plannedEnd": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "quickSession": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "room": {
                if (session.room?.createdAt) {
                    updatePayload[k] = buildRoomUpdatePayload(session.room);
                }
                break;
            }
            case "syncToAgenda": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "reminders": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "messageReminders": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "autoTranscribing": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "restrictedWidgets": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "disabledNotifications": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "autoStartSession": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "sendEndSessionEmail": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "recordingConferenceView": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "fullSizeVideosInRecording": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "recordingType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "livestreamOutputUrls": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "recordingPresentationView": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "recurrenceData": {
                // let ruleId = "";
                // const sessionRulePair = session[k]?.sessionRulePairs?.find((x) => x.sessionId === session.id);
                // if (!sessionRulePair) {
                const ruleId = session[k]?.id;
                if (!ruleId && session[k]?.createdAt) {
                    break;
                }
                // } else {
                //     ruleId = sessionRulePair.ruleId;
                // }

                // console.group();
                // console.log("commit recurrence data");
                // console.log("recurrenceData", session[k]);
                // console.log("startAt", session.startAt);
                // console.log("ruleId", ruleId);
                // console.groupEnd();

                if (k in session) {
                    // console.log("here", session[k]);
                    const recurrenceData = session[k];
                    updatePayload[k] = recurrenceData?.createdAt
                        ? buildRecurrenceDataUpdatePayload(recurrenceData)
                        : {
                              create: {
                                  id: recurrenceData?.id,
                                  // dtStart must have the exact time of day as the session startAt,
                                  // otherwise creating an occurrence for it will fail
                                  dtStart: session.startAt ?? sessionData?.session?.startAt ?? recurrenceData!.dtStart,
                                  frequency: recurrenceData!.frequency,
                                  interval: recurrenceData?.interval ? recurrenceData?.interval : undefined,
                                  byDay:
                                      recurrenceData?.byDay && recurrenceData?.byDay.length > 0 ? {set: recurrenceData?.byDay} : undefined,
                                  bySetPos: recurrenceData?.bySetPos && recurrenceData?.bySetPos > 0 ? recurrenceData?.bySetPos : undefined,
                                  byMonthDay:
                                      recurrenceData?.byMonthDay && recurrenceData?.byMonthDay.length > 0
                                          ? {set: recurrenceData?.byMonthDay}
                                          : undefined,
                                  byMonth: recurrenceData?.byMonth?.length ? {set: recurrenceData?.byMonth} : undefined,
                                  count: recurrenceData?.count,
                                  until: recurrenceData?.until,
                              },
                          };
                }

                break;
            }
            case "requestPermissionToJoin": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "requireConsentToRecord": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "allowCameraType": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "askForGuestEmail": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "sendEmailsAutomatically": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "shareScreenPid": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "source": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }
            case "startAt": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "timeDependency": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "timeZone": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "agendaItems": {
                if (agendaItems) {
                    updatePayload[k] = buildAgendaItemsUpdatePayload(agendaItems);
                }
                break;
            }
            case "event": {
                if (session.event) {
                    updatePayload[k] = buildEventUpdatePayload(session.event);
                }
                break;
            }
            case "macroArtifacts": {
                if (macroArtifacts) {
                    updatePayload[k] = buildMacroArtifactsUpdatePayload(macroArtifacts);
                }
                break;
            }
            case "onboardingType": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }
            case "transcriptionActive": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }

            case "exitPageOfferId": {
                if (k in session) {
                    updatePayload.exitPageOffer = {
                        connect: {
                            id: session[k]!,
                        },
                    };
                }

                break;
            }

            case "collectFeedback": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "showExitPageCta": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            case "hideIcsGuestList": {
                if (k in session) {
                    updatePayload[k] = session[k];
                }
                break;
            }

            // case "sessionTagId": {
            //     if (k in session) {
            //         updatePayload.tag = {
            //             connect: {
            //                 id: session[k]!,
            //             },
            //         };
            //     }

            //     break;
            // }

            case "feedbackFormId": {
                if (k in session) {
                    if (session[k]) {
                        updatePayload["feedbackForm"] = {
                            connect: {
                                id: session[k],
                            },
                        };
                    } else {
                        updatePayload["feedbackForm"] = {
                            disconnect: true,
                        };
                    }
                }

                break;
            }

            default: {
                break;
            }
        }
    });

    return updatePayload;
}

type RecurrenceDataUpdateInput = NonNullable<NonNullable<SessionUpdateInputPayload["session"]>["recurrenceData"]>;

type RuleKeys = NonNullable<ArrayItem<RecurrenceDataUpdateInput>>;

const recurrenceRuleUpdateKeys: Array<
    keyof Pick<RuleKeys, "frequency" | "interval" | "byDay" | "bySetPos" | "byMonthDay" | "until" | "count" | "byMonth">
> = ["frequency", "interval", "byDay", "bySetPos", "byMonthDay", "until", "count", "byMonth"];

function buildRecurrenceDataUpdatePayload(recurrenceData: RecurrenceDataUpdateInput): SessionUpdateInput["recurrenceData"] {
    // console.log("on update recu", recurrenceData);
    return {
        update: recurrenceRuleUpdateKeys.reduce((all, k) => {
            if (!(k in recurrenceData)) {
                return all;
            }
            switch (k) {
                case "frequency": {
                    all[k] = recurrenceData[k] ?? null;
                    break;
                }
                case "interval": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                case "byDay": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                case "bySetPos": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                case "byMonthDay": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                case "until": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                case "count": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                case "byMonth": {
                    all[k] = {
                        set: recurrenceData[k] ?? null,
                    };
                    break;
                }
                default: {
                    break;
                }
            }
            return all;
        }, {} as NonNullable<NonNullable<SessionUpdateInput["recurrenceData"]>["update"]>),
    };
}
