import TableRow from "@ui/cdk/Table/TableRow";
import TableCell from "@ui/cdk/Table/TableCell";
import Typography from "@ui/cdk/Typography";
import {EventParticipant} from "./event-people";
import classes from "./styles/EventPeopleTableRow.module.scss";
import Button from "@ui/cdk/Button";
import {dateFormat} from "@ui/cdk/DateFormat";
import {useMutation, writeQuery} from "@workhorse/dataApi";
import {makeParticipantAssistantData} from "../event-settings/utils";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {CheckoutSessionStatus} from "@generated/data";
import environment from "@generated/environment";
import Badge from "@ui/cdk/Badge";
import Link from "@ui/cdk/Link";

type EventPeopleTableRowProps = {
    participant: any;
    requireApproval?: boolean;
    viewAnswers?: React.Dispatch<React.SetStateAction<string>>;
    hasRegistration?: boolean;
    sessionId?: string;
    hasPayments?: boolean;
};

const EventPeopleTableRow = (props: EventPeopleTableRowProps) => {
    const {participant, requireApproval, viewAnswers, hasRegistration, sessionId, hasPayments} = props;

    const [updateParticipants] = useMutation("UpdateParticipantsInSessionStandaloneDocument", {});

    const handleViewAnswers = () => {
        viewAnswers?.(participant.dataWithNullableEmail.token ?? participant.id);
    };
    const handleApprove = () => {
        const newParticipantsData = {
            ...makeParticipantAssistantData(
                participant.dataWithNullableEmail.email,
                false,
                false,
                {
                    session: {
                        isAssistant: false,
                    },
                },
                participant.id,
                participant.dataWithNullableEmail.firstName,
                participant.dataWithNullableEmail.lastName
            ),
            id: makeId(),
        };

        if (sessionId) {
            updateParticipants({
                variables: {
                    sessionId: sessionId,
                    upsertParticipantJSONs: [newParticipantsData],
                },
            }).then((res) => {
                // refetch participants instead of evicting from cache
                if (res.data?.updateParticipantsInSession) {
                    writeQuery("LocalParticipantsDocument", {
                        variables: {
                            id: sessionId,
                        },
                        data: {
                            __typename: "Query",
                            participants: res.data.updateParticipantsInSession.participants,
                        },
                    });
                }
            });
        }
    };

    const formattedDate = dateFormat(participant.createdAt, ["weekday", {sep: ","}, "monthShort", "dayOfMonth", "year"]);

    const showViewAnswersButton =
        hasRegistration && !["Co-host", "Host", "Speaker", "Invited participant"].includes(participant?.eventRole);

    const paymentStatus =
        participant.paymentTransaction?.checkoutSessionStatus === CheckoutSessionStatus.CheckoutInitiated
            ? "Checkout Initiated"
            : participant.paymentTransaction?.checkoutSessionStatus === CheckoutSessionStatus.CheckoutExpired
            ? "Checkout expired"
            : participant.paymentTransaction?.checkoutSessionStatus === CheckoutSessionStatus.CheckoutCompleted
            ? "Completed"
            : "-";

    const paymentTransactionLink =
        paymentStatus === "Completed" && participant.paymentTransaction?.paymentIntendId
            ? `${environment.stripeAppUrl}/payments/${participant.paymentTransaction?.paymentIntendId}`
            : null;

    return (
        <TableRow key={participant.id} className={classes.root}>
            <TableCell size="large">
                <Typography>
                    {participant.dataWithNullableEmail.firstName} {participant.dataWithNullableEmail.lastName}
                </Typography>
            </TableCell>
            <TableCell size="unset">
                <Typography noWrap>{participant.dataWithNullableEmail.email}</Typography>
            </TableCell>
            <TableCell size="unset">
                <Typography noWrap>{participant.eventRole}</Typography>
            </TableCell>
            <TableCell size="unset">
                <Typography noWrap>{participant.country ?? "-"}</Typography>
            </TableCell>
            <TableCell size="unset">
                <Typography noWrap>{formattedDate}</Typography>
            </TableCell>
            {hasRegistration && (
                <TableCell size="unset">
                    {showViewAnswersButton ? (
                        <Button variant="plain" noFocusBorder onClick={handleViewAnswers}>
                            <Typography>See responses</Typography>
                        </Button>
                    ) : (
                        <Button className={classes.noAnswers} variant="quaternary" noFocusBorder onClick={handleViewAnswers} disabled>
                            <Typography>-</Typography>
                        </Button>
                    )}
                </TableCell>
            )}
            {requireApproval && (
                <TableCell size="unset">
                    {!participant.registered && (
                        <Button variant="primary" size="small" onClick={handleApprove}>
                            Approve
                        </Button>
                    )}
                </TableCell>
            )}
            {hasPayments && (
                <TableCell size="unset">
                    <Badge>{paymentStatus}</Badge>
                    {paymentTransactionLink ? (
                        <Link href={paymentTransactionLink} target="_blank" className="p-0 mt-4">
                            View payment
                        </Link>
                    ) : null}
                </TableCell>
            )}
        </TableRow>
    );
};

export default EventPeopleTableRow;
