import loadable from "@workhorse/api/loadable";
import {Route} from "@workhorse/api/routing";

const PaypalCapturePayment = loadable(() => import("./paypal/PaypalCapturePayment"));
const PaypalCancelPayment = loadable(() => import("./paypal/PaypalCancelPayment"));

// a HOC for the Base and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child

function PaymentsHOC(): JSX.Element {
    return (
        <>
            <Route exact path="/payments/paypal/capture" render={() => <PaypalCapturePayment />} />
            <Route exact path="/payments/paypal/cancel" render={() => <PaypalCancelPayment />} />
        </>
    );
}

export default PaymentsHOC;
