import {cls} from "@ui/cdk/util/util";
import {ReactNode, useEffect, useLayoutEffect, useRef} from "@workhorse/api/rendering";
import {screenWidth as utilScreenWidth, screenHeight as utilScreenHeight, screenWidth} from "@workhorse/util";
import {Rnd} from "react-rnd";
import classes from "./DraggableDialog.module.scss";
import {DraggableDialogPositioning} from "./DraggableDialogPositioning";
import {DraggableDialogWrapper} from "./DraggableDialogWrapper";
import {DraggableDialogEvent, useDraggableDialog} from "./useDraggableDialog";

const gap = 30;
const globalDefaultHeight = 185;

const globalMinimizedWidth = 246;
const globalMinimizedHeight = 52;

interface Props {
    id: string;
    width: number;
    height: number;
    children?: ReactNode;
    events?: DraggableDialogEvent;
    defaultPosition?: "top-center" | "top-right" | "top-left";
    className?: string;
    wrapperClassName?: string;
    onMinimizeChange?: (minimized: boolean) => void;
    enableResizing?: boolean;
    isSpeakerWidget?: boolean;
    /**
     * `data-id` prop for the content wrapper.
     */
    dataId?: string;
    /**
     * Default / initial width for the content.
     * If `aspectRatio` is present, this will be adjusted for the ratio sizes.
     */
    defaultWidth?: number;
    /**
     * Default / initial height for the content;
     * If `aspectRatio` is present, the width will be calculated based on this.
     */
    defaultHeight?: number;
    /**
     * Minimum width for the content.
     * If `aspectRatio` is present, this will be adjusted for the ratio sizes.
     */
    minWidth?: number;
    /**
     * Minimum height the content can have.
     * Used when minimized or on resize.
     */
    minHeight?: number;
    /**
     * Maintain width / height aspect ratio.
     * If this is set, initial width will be ajusted to accomodate the ratio
     * and it will be mantaibed during resize;
     * @example 1.33 for a `3 / 4` aspect ratio
     * @important this may override `defaultWidth`
     */
    aspectRatio?: number;
}

export function DraggableDialog(props: Props) {
    const {
        width,
        height,
        events,
        className,
        onMinimizeChange,
        enableResizing = true,
        isSpeakerWidget,
        defaultHeight = globalDefaultHeight,
        defaultWidth,
        aspectRatio,
        minHeight,
        minWidth,
        wrapperClassName,
    } = props;
    const screenHeight = utilScreenHeight();
    const screenWidth = utilScreenWidth();

    const isPortrait = screenWidth / screenHeight < 1;

    let initialHeight = isSpeakerWidget ? Math.floor((isPortrait ? screenWidth : screenHeight) * (isPortrait ? 0.3 : 0.25)) : defaultHeight;

    if (initialHeight < defaultHeight) {
        initialHeight = defaultHeight;
    }

    const initialWidth = aspectRatio ? initialHeight * aspectRatio : defaultWidth ?? width;

    const minimizedWidth = isSpeakerWidget ? initialWidth : minWidth ?? initialWidth;
    const minimizedHeight = isSpeakerWidget ? initialHeight : minHeight ?? initialHeight;

    const {
        size,
        position,
        windowWidth,
        windowHeight,
        pointerEvents,
        onDragStart,
        onDragStop,
        onResizeStart,
        onResizeStop,
        rightCorner,
        handleRightCornerChange,
        setPosition,
        minimized,
    } = useDraggableDialog(gap, defaultHeight, globalMinimizedWidth, globalMinimizedHeight, width, height, events, onMinimizeChange);

    const maxWidth = windowWidth - 50;
    const maxHeight = initialHeight; // windowHeight - 50;

    const childrenContainerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!rightCorner && !position && props.defaultPosition === "top-center") {
            setTimeout(() => {
                const rect = childrenContainerRef.current?.getBoundingClientRect();
                if (rect) {
                    setPosition({x: -rect.right, y: -windowHeight + rect.top});
                }
            }, 500);
        }
    }, []);

    if (!rightCorner && !position) {
        return <DraggableDialogPositioning onChange={handleRightCornerChange} windowWidth={windowWidth} windowHeight={windowHeight} />;
    }

    const offsetX =
        props.defaultPosition === "top-left"
            ? windowWidth - gap
            : props.defaultPosition === "top-center"
            ? (windowWidth + initialWidth - Math.abs(rightCorner?.x ?? 0)) / 2
            : initialWidth + gap;

    return (
        <>
            <DraggableDialogWrapper
                id={props.id}
                gap={gap}
                defaultWidth={initialWidth}
                defaultHeight={initialHeight}
                size={size}
                position={position}
                pointerEvents={pointerEvents}
                className={wrapperClassName}
            >
                <Rnd
                    data-id={props.id || "speaker"}
                    bounds={"#" + props.id}
                    className={cls(classes.rnd, className)}
                    enableResizing={enableResizing}
                    minWidth={minimized ? globalMinimizedWidth : minimizedWidth}
                    minHeight={minimized ? globalMinimizedHeight : minimizedHeight}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    default={{
                        x: rightCorner ? rightCorner.x - offsetX : position?.x!,
                        y: rightCorner ? rightCorner.y + gap : position?.y!,
                        width: initialWidth,
                        height: initialHeight,
                    }}
                    size={size}
                    position={position}
                    onDragStart={onDragStart}
                    onDragStop={onDragStop}
                    onResizeStart={onResizeStart}
                    onResizeStop={onResizeStop}
                    lockAspectRatio={aspectRatio}
                    cancel=".rnd-btn"
                >
                    {props.children}
                </Rnd>
            </DraggableDialogWrapper>

            <DraggableDialogPositioning onChange={handleRightCornerChange} windowWidth={windowWidth} windowHeight={windowHeight} />
        </>
    );
}
