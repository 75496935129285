import CircularProgress from "@ui/core/components/CircularProgress";
import {useEffect, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/AudienceParticipant.module.scss";
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@workhorse/api/data";
import {brandingVideoTileColor} from "@workhorse/pages/user/profile/Theming/utils";
import {defaultVideoTileBackgroundColor} from "@common/utils";

export const AudienceParticipantConnecting = () => {
    const {t} = useTranslation();
    const [dots, setDots] = useState<"." | ".." | "...">(".");

    const videoTileColor = useReactiveVar(brandingVideoTileColor);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((dots) => {
                switch (dots) {
                    case ".":
                        return "..";
                    case "..":
                        return "...";
                    case "...":
                        return ".";
                    default:
                        return ".";
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div
            data-id="participant-connecting"
            className={cls("flex flex-center-all", classes.connecting)}
            style={{backgroundColor: videoTileColor ? videoTileColor : defaultVideoTileBackgroundColor}}
        >
            <div className="flex flex-align-center px-10 m-auto">
                <CircularProgress className={classes.participantLoadingIcon} size={14} thickness={5} />
                <p className={classes.connectingText}>
                    {t("participant.status.connecting") ?? "Connecting"}
                    {dots}
                    <span className={classes.connectingHiddenDots}>{"...".substring(0, 3 - dots.length)}</span>
                </p>
            </div>
        </div>
    );
};
