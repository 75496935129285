import {EditorElement, EditorTextFormatting, JSONContent, generateHTML, generateText, generateJSON} from "./definitions";
import {
    getEmptyValue,
    isEditorGenericElement,
    isEditorHeadingElement,
    isEditorListElement,
    isEditorListItemElement,
    isEditorParagraphElement,
    isEditorTaskItemElement,
    isEditorTaskListElement,
    isGenericText,
    isSlateGenericElement,
} from "./helpers";
import {
    StarterKit,
    TextAlignPlugin,
    UnderlinePlugin,
    TextIndentationPlugin,
    LinkPlugin,
    TaskListPlugin,
    TaskListItemPlugin,
} from "./plugins";
import * as linkify from "linkifyjs";

linkify.options.defaults.defaultProtocol = "https";

export const getCommonExtentions = (collaborative?: boolean) => {
    return [
        StarterKit.configure({history: collaborative ? false : undefined}),
        TextAlignPlugin.configure({
            types: ["heading", "paragraph"],
        }),
        UnderlinePlugin,
        TextIndentationPlugin,
        LinkPlugin.configure({
            openOnClick: false,
        }),
        TaskListPlugin,
        TaskListItemPlugin,
    ];
};

export const isEditorEmpty = (doc?: JSONContent) => {
    return !doc || !doc.content || (doc.content.length === 1 && !doc.content[0].content);
};

export const isSlateLike = (json: JSONContent) => {
    if (!json) return false;

    if (Array.isArray(json)) {
        return true;
    }

    if (!json.type) {
        return true;
    }

    return false;
};

export const parseAsTipTapJSON = (value?: JSONContent | string | null): JSONContent => {
    try {
        let content = value;
        if (!content) {
            return getEmptyValue();
        }

        if (typeof content === "string") {
            if (content.startsWith("<")) {
                // Old html content uses hard breaks for new lines, which tiptap isn't happy with.
                content = content.replace(/<br>/g, "");
                return generateJSON(content, getCommonExtentions());
            } else if (content.startsWith("{") || content.startsWith("[")) {
                content = JSON.parse(content) as JSONContent;
            } else {
                return mapToEditorParagraph(content);
            }
        }

        if (Array.isArray(content) && !content.length) {
            return getEmptyValue();
        }

        if (isSlateLike(content)) {
            const children = slateToTiptap(Array.isArray(content) ? content : Object.values(content));
            return {type: "doc", content: children};
        }

        return content;
    } catch (error) {
        console.warn("Error parsing tiptap content", error);
        console.info(value);
        return getEmptyValue();
    }
};

export const generateTextContent = (content: JSONContent) => {
    const json = parseAsTipTapJSON(content);
    return generateText(json, getCommonExtentions());
};

type SerializedElementClassNames = Partial<Record<EditorTextFormatting | EditorElement | "artifact" | "generic", string>>;

export const serializeTiptap = (document: JSONContent) => {
    try {
        return generateHTML(document, getCommonExtentions());
    } catch {
        return null;
    }
};

export const slateToTiptap = (nodes: JSONContent[], parentType?: string): JSONContent[] => {
    return nodes.reduce((agg, node) => {
        const schema: JSONContent = {};

        if (isGenericText(node) && node.text.length) {
            schema.type = "text";
            schema.text = node.text;
            schema.marks = [];

            if (node.bold) {
                schema.marks?.push({type: "bold"});
            }
            if (node.italic) {
                schema.marks?.push({type: "italic"});
            }
            if (node.underline) {
                schema.marks?.push({type: "underline"});
            }
            if (node.strickenThrough) {
                schema.marks?.push({type: "strike"});
            }

            if (parentType !== "paragraph") {
                agg.push({
                    type: "paragraph",
                    attrs: {
                        textAlign: "left",
                        indent: 0,
                    },
                    content: [schema],
                });
            } else {
                agg.push(schema);
            }
        } else if (isSlateGenericElement(node)) {
            schema.attrs = {};
            schema.attrs.textAlign = node.alignment;
            schema.attrs.indent = node.indentation;

            if (node.children) {
                schema.content = slateToTiptap(node.children, node.type);
            }

            switch (node.type) {
                case "h1":
                    schema.type = "heading";
                    schema.attrs.level = 1;
                    break;
                case "h2":
                    schema.type = "heading";
                    schema.attrs.level = 2;
                    break;
                case "h3":
                    schema.type = "heading";
                    schema.attrs.level = 3;
                    break;
                case "h4":
                    schema.type = "heading";
                    schema.attrs.level = 4;
                    break;
                case "h5":
                    schema.type = "heading";
                    schema.attrs.level = 5;
                    break;
                case "h6":
                    schema.type = "heading";
                    schema.attrs.level = 6;
                    break;
                case "ol":
                    schema.type = "orderedList";
                    schema.attrs.start = 1;
                    break;
                case "ul":
                    schema.type = "bulletList";
                    break;
                case "li":
                    schema.type = "listItem";
                    break;
                default:
                    schema.type = "paragraph";
                    break;
            }

            agg.push(schema);
        }

        return agg;
    }, [] as JSONContent[]);
};

export const serialize = (json: JSONContent, classNames: SerializedElementClassNames = {}) => {
    if (!json || (Array.isArray(json) && !json.length) || !Object.keys(json).length) {
        return "";
    }
    let html = serializeTiptap(json as JSONContent);

    return html ?? "";
};
export const setTakeawaysValue = (artifactName: string, editorValue: string | JSON) => {
    const value = typeof editorValue === "string" ? JSON.parse(editorValue) : (editorValue as unknown);

    if (isEditorEmpty(value)) {
        return null;
    }

    const takeaway = serialize(value);

    return {
        name: artifactName.replace(/\.svg/gi, ".png"), // make sure all images are pngs
        takeaway,
    };
};

export const mapNoteToEvernote = (note: JSONContent, parent?: JSONContent) => {
    if (isEditorEmpty(note)) {
        return null;
    }

    const {content = []} = note;
    return [...content]
        .map((node) => {
            if (isEditorGenericElement(node)) {
                if (isEditorTaskListElement(node.type)) {
                    return mapNoteToEvernote(node);
                }
                if (isEditorTaskItemElement(node.type)) {
                    return `<en-todo checked="${node.attrs?.checked ?? false}" />${generateText(node, getCommonExtentions())}`;
                }
                console.log(serialize({type: "doc", content: [node]}));
                return serialize({type: "doc", content: [node]});
            }
        })
        .flat()
        .join("<br/>");
};

const MAP_HEADING_TO_NOTION_HEADING: Record<string, string> = {
    "heading-1": "heading_1",
    "heading-2": "heading_1",
    "heading-3": "heading_2",
    "heading-4": "heading_2",
    "heading-5": "heading_3",
    "heading-6": "heading_3",
    paragraph: "paragraph",
    bulletList: "numbered_list_item",
    orderedList: "numbered_list_item",
    "orderedList-listItem": "numbered_list_item",
    "bulletList-listItem": "bulleted_list_item",
    taskList: "to_do",
    "taskList-taskItem": "to_do",
};

const NOTION_DEFAULT_TEXT_ANNOTATIONS = {
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
};

function mapMarksToNotion(marks: {type: string; attrs?: Record<string, string | null>}[]) {
    return marks.reduce(
        (agg, item) => {
            switch (item.type) {
                case "link":
                    agg["link"] = {url: item.attrs?.href};
                    break;
                case "strike":
                    agg.annotations.strikethrough = true;
                    break;
                default:
                    agg.annotations[item.type] = true;
                    break;
            }

            return agg;
        },
        {annotations: {...NOTION_DEFAULT_TEXT_ANNOTATIONS}}
    );
}

export const mapNoteToNotion = (nodes: JSONContent, parent?: JSONContent) => {
    const {content} = parseAsTipTapJSON(nodes);

    if (!content) return [];

    return [...content]
        .map((node) => {
            if (isGenericText(node)) {
                const {annotations, link} = (
                    node.marks ? mapMarksToNotion(node.marks) : {annotations: NOTION_DEFAULT_TEXT_ANNOTATIONS}
                ) as {annotations: Record<string, boolean>; link?: {url?: string}};
                return {
                    type: "text" as const,
                    text: {
                        content: node.text,
                        link,
                    },
                    annotations,
                };
            } else if (isEditorGenericElement(node)) {
                if (!isEditorListItemElement(node.type) && !isEditorListElement(node.type) && !isEditorHeadingElement(node.type)) {
                    if (isEditorParagraphElement(node.type) && isEditorListItemElement((parent?.type ?? "") as EditorElement)) {
                        return mapNoteToNotion({type: "doc", content: node.content});
                    }
                    const type = MAP_HEADING_TO_NOTION_HEADING[node.type];

                    return {
                        type,
                        [type]: {
                            rich_text: mapNoteToNotion({type: "doc", content: node.content}, node),
                        },
                    };
                } else if (isEditorHeadingElement(node.type)) {
                    const type = MAP_HEADING_TO_NOTION_HEADING[`${node.type}-${node.attrs?.level}`];

                    return {
                        type,
                        [type]: {
                            rich_text: mapNoteToNotion({type: "doc", content: node.content}, node),
                        },
                    };
                } else if (isEditorListItemElement(node.type)) {
                    const type = MAP_HEADING_TO_NOTION_HEADING[node.type] || MAP_HEADING_TO_NOTION_HEADING[`${parent?.type}-${node.type}`];
                    const rich_text = mapNoteToNotion({type: "doc", content: node.content}, node);
                    const children = mapNoteToNotion({content: node.content.filter((node) => !isGenericText(node))}, node);

                    return {
                        type,
                        [type]: {
                            rich_text,
                            children: children?.length === 0 ? undefined : children,
                            checked: node.attrs?.checked ?? undefined,
                        },
                    };
                } else if (isEditorListElement(node.type)) {
                    return mapNoteToNotion({type: "doc", content: node.content}, node);
                } else {
                    throw new Error(`Cannot convert ${JSON.stringify(node)} to Notion`);
                }
            } else {
                throw new Error(`Cannot convert ${JSON.stringify(node)} to Notion`);
            }
        })
        .flat();
};

export const mapToEditorParagraph = (text: string): JSONContent => {
    const content = text
        .split("\n")
        .filter(Boolean)
        .map((item) => ({
            type: "paragraph",
            attrs: {
                textAlign: "left",
                indent: 0,
            },
            content: [
                {
                    type: "text",
                    text: item,
                },
            ],
        }));

    return {
        type: "doc",
        content: content,
    };
};
