import {makeStyles} from "@material-ui/core";
import {MicroNotificationProps} from "..";
import {ReactComponent as BadgeAssistant} from "../../../../assets/media/badge-assistant-notification.svg";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    badgeAssistant: {
        width: 20,
        height: 20,
        minWidth: 20,
        marginRight: theme.spacing(0.5),
    },
}));

export type AssistantRoleNotificationType = {user: string; action: "assigned" | "unassigned"};

export const AssistantRoleNotification = (props: MicroNotificationProps<AssistantRoleNotificationType>) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {user: displayName, action} = props.messages[0].message;

    return (
        <div className="flex flex-items-center">
            <BadgeAssistant className={classes.badgeAssistant} />
            {action === "assigned"
                ? t("player.notifications.role.made_assistant", {host_name: displayName})
                : t("player.notifications.role.removed_assistant", {host_name: displayName})}
        </div>
    );
};
