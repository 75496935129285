import {getExternalEventDates} from "@common/calendar";
import {
    CalendarCustomEvent,
    CalendarEventCustomFragmentDoc,
    CalendarProvider,
    CalendarSettingsDocument,
    ExternalCalendar,
    ExternalCalendarFilter,
    ExternalCalendarFragment,
    ExternalEventsAndCalendars,
    GetCalendarEventsDocument,
    GetCalendarEventsQuery,
    ParticipantInviteStatus,
} from "@generated/data";
import apollo from "../apollo";
import {makeVar} from "../state";
import {CalendarPeriod, ForgedExternalCalendarEventRuleInfo} from "./definitions";

export const requestExternalCalendars = async (period: CalendarPeriod, disableExternal = false) => {
    if (disableExternal) {
        return null;
    }

    const res = await apollo.client.query({
        query: GetCalendarEventsDocument,
        fetchPolicy: "network-only",
        variables: {
            from: period.startDate,
            until: period.endDate,
            includeExternal: true,
            includeNormal: false,
            includeRecurrent: false,
        },
    });

    return res.data;
};

export const calendarExternalEventsLoading = makeVar<"load" | "loading" | "finished">("loading");

export const computeExternalCalendar = (
    calendarsAndEventsObjects: Pick<GetCalendarEventsQuery, "getAppleEvents" | "getMicrosoftEvents" | "getGoogleEvents">
) => {
    const calendars = [
        ...(calendarsAndEventsObjects.getGoogleEvents ? calendarsAndEventsObjects.getGoogleEvents.calendars : []),
        ...(calendarsAndEventsObjects.getMicrosoftEvents ? calendarsAndEventsObjects.getMicrosoftEvents.calendars : []),
        ...(calendarsAndEventsObjects.getAppleEvents ? calendarsAndEventsObjects.getAppleEvents.calendars : []),
    ];

    const events = [
        ...(calendarsAndEventsObjects.getGoogleEvents ? calendarsAndEventsObjects.getGoogleEvents.events : []),
        ...(calendarsAndEventsObjects.getMicrosoftEvents ? calendarsAndEventsObjects.getMicrosoftEvents.events : []),
        ...(calendarsAndEventsObjects.getAppleEvents ? calendarsAndEventsObjects.getAppleEvents.events : []),
    ];

    appendExternalCalendars(calendars);

    // console.log(
    //     "COMPUTING EXTERNAL CALENDAR",
    //     {calendarsAndEventsObjects},
    //     apollo.client.readQuery({
    //         query: GetCalendarEventsDocument,
    //         variables: {
    //             from: new Date(0),
    //             until: new Date(0),
    //             includeExternal: true,
    //             includeNormal: true,
    //             includeRecurrent: true,
    //         },
    //     })
    // );

    events.forEach((event) => {
        const fragment = apollo.cache.readFragment({
            id: apollo.cache.identify(event),
            fragment: CalendarEventCustomFragmentDoc,
            fragmentName: "CalendarEventCustom",
        });

        // console.log({event, fragment}, apollo.cache.identify(event));

        if (!fragment) {
            return;
        }

        if (fragment.externalEvent?.start && fragment.externalEvent?.end && !fragment.occurrenceId) {
            const [startAt, startStr, plannedEnd, endStr, allDay] = getExternalEventDates(
                fragment.externalEvent.start,
                fragment.externalEvent.end
            );

            apollo.cache.writeFragment({
                id: apollo.cache.identify(event),
                fragment: CalendarEventCustomFragmentDoc,
                fragmentName: "CalendarEventCustom",
                data: {
                    ...fragment,
                    startAt,
                    plannedEnd,
                    currentParticipantInviteStatus: EXTERNAL_RSV_MAP[event.externalEvent?.rsvp || "needsAction"],
                    externalEvent: {
                        ...fragment.externalEvent,
                        type: calendars.find((c) => c.id === fragment.externalEvent?.calendarId)?.type,
                        name: event.name || "(No subject)",
                    },
                },
            });

            // console.log(
            //     "WROTE FRAGMENT",
            //     apollo.cache.readFragment({
            //         id: apollo.cache.identify(event),
            //         fragment: CalendarEventCustomFragmentDoc,
            //         fragmentName: "CalendarEventCustom",
            //     })
            // );
        }
    });

    calendarExternalEventsLoading("finished");
};

const EXTERNAL_RSV_MAP: Record<string, ParticipantInviteStatus> = {
    accepted: ParticipantInviteStatus.Accepted,
    declined: ParticipantInviteStatus.Declined,
    tentative: ParticipantInviteStatus.Tentative,
    needsAction: ParticipantInviteStatus.NeedsAction,
};

export const appendExternalCalendars = async (calendars: ExternalCalendarFragment[]): Promise<void> => {
    const data = apollo.cache.readQuery({
        query: CalendarSettingsDocument,
        returnPartialData: true,
    });

    // console.log("APPENDING EXTERNAL CALENDARS", {data, calendars});
    if (data && data.calendarSettings) {
        apollo.client.writeQuery({
            query: CalendarSettingsDocument,
            data: {
                ...data,
                calendarSettings: {
                    ...data?.calendarSettings,
                    externalCalendars: calendars.map((externalCalendar) => {
                        const ec = data.calendarSettings.externalCalendars.find((ec) => ec.calendarId === externalCalendar.id);

                        let enabled = ec?.enabled;
                        if (enabled === undefined) {
                            enabled = true;
                        }

                        const ret = {
                            __typename: "ExternalCalendarFilter",
                            calendarId: externalCalendar.id,
                            calendarTitle: externalCalendar.name,
                            type: externalCalendar.type,
                            enabled,
                        } as ExternalCalendarFilter;

                        return ret;
                    }),
                },
            },
        });
    }
};

const evictEventsByEmail = (calendarsAndEvents: ExternalEventsAndCalendars, email: string) => {
    const calendarIds = calendarsAndEvents.calendars.filter((item) => item.email === email).map((c) => c.id);

    calendarsAndEvents.calendars.forEach((item) => {
        if (calendarIds.includes(item.id)) {
            apollo.cache.evict({id: apollo.cache.identify(item)});
        }
    });

    calendarsAndEvents.events.forEach((item) => {
        if (item.externalEvent?.calendarId && calendarIds.includes(item.externalEvent.calendarId)) {
            apollo.cache.evict({id: apollo.cache.identify(item)});
        }
    });
};

export const evictProviderEvents = (providerType: CalendarProvider, email: string) => {
    const existingCalendarEvents = apollo.cache.readQuery({
        query: GetCalendarEventsDocument,
        returnPartialData: true,
        variables: {
            from: new Date(0),
            until: new Date(0),
            includeExternal: true,
            includeNormal: true,
            includeRecurrent: true,
        },
    });

    if (!existingCalendarEvents) {
        return;
    }

    const {getAppleEvents, getMicrosoftEvents, getGoogleEvents} = existingCalendarEvents;

    if (providerType === CalendarProvider.Apple && getAppleEvents?.calendars.length && getAppleEvents.events.length) {
        evictEventsByEmail(getAppleEvents as ExternalEventsAndCalendars, email);
    } else if (providerType === CalendarProvider.Microsoft && getMicrosoftEvents?.calendars.length && getMicrosoftEvents.events.length) {
        evictEventsByEmail(getMicrosoftEvents as ExternalEventsAndCalendars, email);
    } else if (providerType === CalendarProvider.Google && getGoogleEvents?.calendars.length && getGoogleEvents.events.length) {
        evictEventsByEmail(getGoogleEvents as ExternalEventsAndCalendars, email);
    }

    apollo.cache.gc();
};
