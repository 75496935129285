import {memo, useEffect, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import Loading from "@workhorse/components/Loading";
import {MyProductToolService, NavigateEventData} from "../api/MyProductToolService";
import classes from "./style/MyProductToolPlayerContainer.module.scss";
import {cls} from "@ui/cdk/util/util";
import {useUserInfo} from "@workhorse/providers/User/UserInfoProvider";
interface MyProductToolIframeComponentProps {
    artifactId: string;
    startingDetails?: {
        url: string;
        toolId: string;
        ownerOfToolId: string;
        timezone: string;
    };
    onProductToolServiceReady?: (service: MyProductToolService) => void;
    onNavigateDataChange?: (data: NavigateEventData) => void;
    isPreviewMode?: boolean;
}

const MyProductToolIframeComponent = (props: MyProductToolIframeComponentProps) => {
    const {onNavigateDataChange, onProductToolServiceReady} = props;
    const [myProductToolService] = useState(new MyProductToolService(props.artifactId));
    const [iframeUrl, setIframeUrl] = useState<string | null>(null);
    const {isRecorder} = useUserInfo();

    useEffect(() => {
        if (!myProductToolService.initialized) {
            myProductToolService.initialize({
                initCallback: (data) => {
                    if (!data.ok) {
                        toast("Something went wrong!", {type: "error"});
                        return;
                    }

                    if (props.startingDetails && !data.started) {
                        myProductToolService.start(
                            props.startingDetails.toolId,
                            props.startingDetails.ownerOfToolId,
                            props.startingDetails.url,
                            props.startingDetails.timezone
                        );
                    }
                },
                startCallback: (startResult) => {
                    if (startResult.error) {
                        console.error("[MyProductToolService] Error: ", startResult.error);
                        toast("Something went wrong!", {type: "error"});
                        return;
                    }

                    const url = startResult.url;

                    if (!startResult.ok || !url) {
                        toast("Something went wrong!", {type: "error"});
                        return;
                    }

                    setIframeUrl(url as string);
                    if (onProductToolServiceReady) {
                        onProductToolServiceReady(myProductToolService);
                    }
                },
                navigateDataCallback: (navigateData) => {
                    if (onNavigateDataChange) {
                        onNavigateDataChange(navigateData);
                    }
                },
            });
        }

        return () => {
            if (myProductToolService.initialized) {
                myProductToolService.disconnect();
            }
        };
    }, [myProductToolService, props.startingDetails, onProductToolServiceReady, onNavigateDataChange]);

    return iframeUrl ? (
        <iframe
            allow={isRecorder ? undefined : "clipboard-read; clipboard-write"}
            className={cls(classes.iframe, props.isPreviewMode ? classes.iframeInPreview : undefined)}
            src={iframeUrl}
        />
    ) : (
        <Loading />
    );
};

// This component shall never be rerendered due to props change
// Unmount and remount if you want
export default memo(MyProductToolIframeComponent, () => {
    return true;
});
