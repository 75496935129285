export const GOOGLE_SYNC_SCOPES = [
    "profile",
    "email",
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/calendar.readonly",
    "https://www.googleapis.com/auth/calendar.events",
    "https://www.googleapis.com/auth/contacts.readonly",
    "https://www.googleapis.com/auth/gmail.send",
];

export const GOOGLE_SYNC_SCOPES_NO_MAIL = [
    "profile",
    "email",
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/calendar.readonly",
    "https://www.googleapis.com/auth/calendar.events",
    "https://www.googleapis.com/auth/contacts.readonly",
];

export const GOOGLE_SYNC_SCOPES_ONLY_MAIL = ["https://www.googleapis.com/auth/gmail.send", "email", "profile"];

export const MICROSOFT_SYNC_SCOPES = ["email", "Contacts.Read", "Calendars.Read", "Calendars.ReadWrite", "Mail.Send"];
export const MICROSOFT_SYNC_SCOPES_NO_MAIL = ["email", "Contacts.Read", "Calendars.ReadWrite", "Calendars.Read"];
export const MICROSOFT_SYNC_SCOPES_ONLY_MAIL = ["email", "Mail.Send"];
