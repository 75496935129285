import * as AstDoc from "@generated/data";
import {TypedDocumentNode as DocumentNode} from "@graphql-typed-document-node/core";
import apollo, {ApolloOperationContext} from "@workhorse/api/apollo";
import {
    ApolloQueryResult,
    DataProxy,
    FetchResult,
    LazyQueryHookOptions,
    MutationHookOptions,
    MutationOptions as ApolloMutationOptions,
    MutationTuple,
    NetworkStatus,
    QueryHookOptions,
    QueryOptions as ApolloQueryOptions,
    QueryResult,
    SubscriptionHookOptions,
    useMutation as useMutationOriginal,
    useQuery as useQueryOriginal,
    useSubscription as useSubscriptionOriginal,
} from "@workhorse/api/data";
import React, {createElement, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {ExtractProps, IsObjectOrArray} from "@workhorse/declarations";
import {mergeQueryData} from "./dataApiUtils";
import {DataFetcher, PaginationMode} from "./DataFetcher";
import {AllEvents, dataEvents} from "./events";
import {addToQueue} from "./queue";
// type FactoryKeys<T extends typeof AllFactories = typeof AllFactories> = {
//     [K in keyof T]: K extends `create${string}${"List" | "Order" | "Create" | "Scalar" | "Update" | "Upsert" | "Where"}${string}Mock`
//         ? never
//         : K;
// }[keyof T];
export * from "./events";
export type {Ast};

type Ast = typeof AstDoc;

type AstKey = keyof Ast;

export type GetPayload<T extends AstKey> = Ast[T] extends DocumentNode<infer U, any> ? U : never;

export type MutationDoc = {
    [K in AstKey]: Ast[K] extends DocumentNode<infer U, any> ? (U extends {__typename?: "Mutation"} ? K : never) : never;
}[AstKey];

export type QueryDoc = {
    [K in AstKey]: Ast[K] extends DocumentNode<infer U, any> ? (U extends {__typename?: "Query"} ? K : never) : never;
}[AstKey];

export type SubscriptionDoc = {
    [K in AstKey]: Ast[K] extends DocumentNode<infer U, any> ? (U extends {__typename?: "Subscription"} ? K : never) : never;
}[AstKey];

type FragmentDoc = {
    [K in AstKey]: Ast[K] extends DocumentNode<infer U, unknown>
        ? U extends {__typename?: "Subscription" | "Mutation" | "Query"}
            ? never
            : K
        : never;
}[AstKey];

export function getDoc<T extends AstKey>(doc: T) {
    return AstDoc[doc];
}

function fragmentOpts<T extends AstKey>(doc: T, id: string) {
    return {
        fragment: getDoc(doc),
        fragmentName: doc.split("FragmentDoc")[0],
        id: apollo.cache.identify({__ref: id}),
    };
}

export function readFragment<T extends FragmentDoc, D = Ast[T] extends DocumentNode<infer U, any> ? U : never>(arg: {
    fragment: T;
    id: string;
    variables?: {[K in string]: any};
}): D | null {
    return apollo.client.readFragment({
        ...fragmentOpts(arg.fragment, arg.id),
    }) as any;
}

type WriteFragmentOptions<
    T extends FragmentDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
> = Pick<DataProxy.WriteFragmentOptions<D, V>, "data" | "variables" | "broadcast">;

export function writeFragment<T extends FragmentDoc>(arg: {fragment: T; id: string} & WriteFragmentOptions<T>) {
    apollo.client.writeFragment({
        broadcast: true,
        ...fragmentOpts(arg.fragment, arg.id),
        data: arg.data,
    });
}

export type ReadQueryOptions<T extends QueryDoc, V = Ast[T] extends DocumentNode<any, infer U> ? U : never> = V extends never
    ? null
    : Pick<DataProxy.Query<V, T>, "id" | "variables"> & {
          variables?: Pick<DataProxy.Query<V, T>, "variables">["variables"] & {
              noDiff?: boolean;
          };
      };

export function readQuery<T extends QueryDoc, D extends Ast[T] extends DocumentNode<infer U, any> ? U : never>(
    queryDoc: T,
    options?: ReadQueryOptions<T>
): D | null {
    return apollo.cache.readQuery({
        query: getDoc(queryDoc),

        optimistic: true,
        returnPartialData: true,
        ...(options as any),
    }) as D;
}

type WriteQueryOptions<
    T extends QueryDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never,
    O extends Pick<DataProxy.WriteQueryOptions<D, V>, "id" | "variables"> = Pick<
        DataProxy.WriteQueryOptions<D, V>,
        "id" | "variables" | "broadcast"
    >,
    DD = DataProxy.WriteQueryOptions<D, V>["data"]
> = O & {
    partialData?: Partial<{
        // TODO: adapt this for arrays as well @vasi
        [K in keyof DD]: IsObjectOrArray<NonNullable<DD[K]>> extends true ? Partial<NonNullable<DD[K]>> : DD[K];
    }>;
    data?: DD;
};

export function writeQuery<T extends QueryDoc>(query: T, options?: WriteQueryOptions<T>) {
    const {partialData, data: optionsData, ...other} = options || ({} as WriteQueryOptions<T>);
    let data = optionsData;
    if (partialData && optionsData) {
        console.warn(
            `Cannot write both "partialData" and "data" to query="${query}". "data" will take precedence. Please remove either one of them`
        );
    }
    if (!optionsData && !partialData) {
        throw new Error(`Cannot write to query="${query}" because neither "data" nor "partialData" were provided`);
    }
    if (partialData && !data) {
        const existingData = readQuery(query, {
            variables: other?.variables,
        } as ReadQueryOptions<T>);
        if (!existingData || typeof existingData !== "object") {
            throw new Error(`Cannot write partial data to query="${query}" because there is no data in the cache to merge with`);
        }
        data = mergeQueryData(existingData, partialData) as WriteQueryOptions<T>["data"];
    }

    return apollo.client.writeQuery({
        query: getDoc(query),

        data,
        broadcast: true,
        ...(other as any),
    });
}

type QueryOptions<TData, TVars> = TData extends never
    ? null
    : TVars extends never
    ? null
    : Pick<ApolloQueryOptions<TVars, TData>, "variables" | "fetchPolicy" | "errorPolicy"> & {
          context?: ApolloOperationContext;
      };

export async function query<
    T extends QueryDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
>(queryDoc: T, options?: QueryOptions<D, V>): Promise<ApolloQueryResult<D>> {
    // return apollo.client.query as any;
    return (await apollo.client.query({
        query: getDoc(queryDoc),
        fetchPolicy: "no-cache",
        ...(options as any),
    })) as unknown as Promise<ApolloQueryResult<D>>;
}

type MutationOptions<
    T,
    A = T extends keyof Ast ? Ast[T] : never,
    D = A extends DocumentNode<infer U, any> ? U : never,
    V = A extends DocumentNode<any, infer U> ? U : never
> = Pick<ApolloMutationOptions<D, V>, "optimisticResponse" | "variables" | "update" | "errorPolicy"> & {
    fetchPolicy?: "no-cache" | "default";
    context?: ApolloOperationContext;
    refetchQueries?: QueryName[];
};

export async function mutate<T extends MutationDoc = MutationDoc, D = Ast[T] extends DocumentNode<infer U, any> ? U : never>(
    mutationDoc: T,
    options?: MutationOptions<T>
): Promise<FetchResult<D, Record<string, any>, Record<string, any>>> {
    return (await apollo.client.mutate({
        mutation: getDoc(mutationDoc),
        ...(options as any),
        fetchPolicy: options?.fetchPolicy === "default" ? undefined : options?.fetchPolicy ?? "no-cache",
    })) as any;
}

export type UseQueryOptions<
    T extends QueryDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
> = Pick<
    QueryHookOptions<D, V>,
    // | "variables"
    "skip" | "client" | "fetchPolicy" | "nextFetchPolicy" | "returnPartialData" | "notifyOnNetworkStatusChange" | "onCompleted"
> & {
    variables?: Pick<QueryHookOptions<D, V>, "variables">["variables"] & {
        noDiff?: boolean;
    };
    context?: ApolloOperationContext;
};

const allowReturnPartialDataForDocs: Array<QueryDoc> = [
    "FullSessionDocument",
    "GetSessionDocument",
    "LocalAgendaItemsDocument",
    "LocalMacroArtifactsDocument",
    "LocalParticipantsDocument",
    "LocalMemoryParticipantsDocument",
    "LocalMemoryParticipantDocument",
    "LocalCurrentParticipantDocument",
    "LocalMacroArtifactDocument",
    "LocalAgendaItemDocument",
    "LocalArtifactDocument",
    "GetMemorySessionDocument",
    "LocalAgendaItemFindOneDocument",
];

// export type UseQueryOptions<TData, TVars> = Pick<
//     QueryHookOptions<TData, TVars>,
//     "variables" | "skip" | "client" | "fetchPolicy" | "nextFetchPolicy" | "returnPartialData"
// >;

export function useQuery<
    T extends QueryDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
>(query: T, options?: UseQueryOptions<T>): QueryResult<D, V> {
    // TODO: @vasi make sure that options actually don't contain any of the unallowed props
    // for now, restriction is at a type-level alone
    const {fetchPolicy, nextFetchPolicy, ...other} = (options ?? {}) as UseQueryOptions<T>;
    const forceCacheOnly = allowReturnPartialDataForDocs.indexOf(query) !== -1;
    return useQueryOriginal(getDoc(query), {
        // preserving apollo defaults
        fetchPolicy: fetchPolicy ?? (forceCacheOnly ? "cache-only" : "cache-first"),
        ...(nextFetchPolicy ? {nextFetchPolicy} : null),
        returnPartialData: true,
        optimistic: false,
        ...(other as any),
    }) as any;
}

type QueryName = QueryDoc extends `${infer QDoc}Document` ? QDoc : never;

type UseMutationOptions<TData, TVars> = Pick<
    MutationHookOptions<TData, TVars>,
    "variables" | "optimisticResponse" | "client" | "awaitRefetchQueries"
> & {
    fetchPolicy?: "no-cache" | "default";
    context?: ApolloOperationContext;
    refetchQueries?: QueryName[];
};

export function useMutation<
    T extends MutationDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
>(mutation: T, options?: UseMutationOptions<D, V>): MutationTuple<D, V> {
    return useMutationOriginal(getDoc(mutation), {
        ...(options as any),
        fetchPolicy: options?.fetchPolicy === "default" ? undefined : options?.fetchPolicy ?? "no-cache",
    }) as any;
}

export type UseSubscriptionOptions<TData, TVars, TQuery extends QueryDoc = QueryDoc> = Pick<
    SubscriptionHookOptions<TData, TVars>,
    "variables" | "skip" | "onSubscriptionData" | "shouldResubscribe" | "fetchPolicy"
> & {
    waitForQuery?: {
        query: TQuery;
    } & ReadQueryOptions<TQuery>;
    useCallbackQueue?: boolean;
};

export type SubscriptionOptionsByDoc<
    T extends SubscriptionDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
> = UseSubscriptionOptions<D, V>;

export type UseSubscriptionReturnType<TData, TVars> = {
    variables: TVars | undefined;
    loading: boolean;
    data?: TData | undefined;
    error?: ReturnType<typeof useSubscriptionOriginal>["error"];
};

export type SubscriptionPayload<
    T extends SubscriptionDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never,
    X = NonNullable<UseSubscriptionReturnType<D, V>["data"]>
> = {
    [K in keyof X]-?: NonNullable<X[K]>;
};

async function ensureQueryWasFetched<T extends QueryDoc>(query: T, options?: ReadQueryOptions<T>) {
    return new Promise<boolean>((resolve, reject) => {
        let tries = 0;
        let exists = false;
        if (tries === 10 && !exists) {
            resolve(false);
        }
        const interval = setInterval(() => {
            exists = !!readQuery(query, options);
            if (!exists) {
                tries++;
            } else {
                clearInterval(interval);
                resolve(true);
            }
        }, 500);
    });
}

export function useSubscription<
    T extends SubscriptionDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never,
    TQuery extends QueryDoc = QueryDoc
>(subscription: T, options?: UseSubscriptionOptions<D, V, TQuery>): UseSubscriptionReturnType<D, V> {
    const {useCallbackQueue = false, waitForQuery, onSubscriptionData, ...other} = options || {};
    const payloadHandler = useRef(onSubscriptionData);
    payloadHandler.current = onSubscriptionData;

    const opts = useMemo<SubscriptionHookOptions<D, V>>(
        () => ({
            // fetchPolicy: "cache-first",
            ...(other as any),

            onSubscriptionData: async (payload) => {
                let canCallHandler = true;
                if (waitForQuery) {
                    canCallHandler = await ensureQueryWasFetched(waitForQuery.query, {variables: waitForQuery.variables} as any);
                }
                if (canCallHandler) {
                    if (useCallbackQueue) {
                        addToQueue(
                            () =>
                                new Promise((resolve, reject) => {
                                    payloadHandler.current?.(payload);
                                    dataEvents.emit(
                                        `on${subscription.replace("Document", "")}` as AllEvents,
                                        payload.subscriptionData.data
                                    );
                                    resolve();
                                })
                        );
                    } else {
                        payloadHandler.current?.(payload);
                        dataEvents.emit(`on${subscription.replace("Document", "")}` as AllEvents, payload.subscriptionData.data);
                    }
                } else {
                    if (payloadHandler.current) {
                        console.warn(`${subscription} handler was not called because data for ${waitForQuery?.query} doesn't exist`);
                    }
                }
            },
        }),
        [waitForQuery?.variables]
    );
    return useSubscriptionOriginal(getDoc(subscription), opts as any) as any;
}

type AProps = {
    a: string;
    b: boolean;
};

function A(props: AProps) {
    return <div>ceva</div>;
}

type NonNullableQueryResult<T, X = NonNullable<T>> = {
    [K in keyof X]-?: IsObjectOrArray<NonNullable<X[K]>> extends true ? Exclude<NonNullable<X[K]>, undefined> : X[K];
};

export type MutationCommitPayload<
    T extends MutationDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never
> = NonNullableQueryResult<D>;

export type QueryPayload<
    T extends QueryDoc,
    D = Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V = Ast[T] extends DocumentNode<any, infer U> ? U : never
> = {
    data: NonNullableQueryResult<D>;
    paginateRef: MutableRefObject<(mode: PaginationMode, lazyOptions: LazyQueryHookOptions<D, V>) => void>;
    refetch?: (variables?: Partial<V>) => Promise<ApolloQueryResult<D>>;
};

export function QueryRenderer<
    C,
    T extends QueryDoc,
    D extends Ast[T] extends DocumentNode<infer U, any> ? U : never,
    V extends Ast[T] extends DocumentNode<any, infer U> ? U : never,
    P extends C extends React.ComponentType<any> ? Omit<ExtractProps<C>, "data" | "paginateRef"> : never
>(arg: {
    queryDoc: T;
    options?: UseQueryOptions<T> | ((props: P) => UseQueryOptions<T>);
    onCompleted?: (data: D, props: P) => void;
    component: C;
    preloader?: React.ReactNode | React.ReactNode[] | false | ((props: P) => React.ReactNode | React.ReactNode[]);
    error?: React.ReactNode | React.ReactNode[] | false | ((props: P) => React.ReactNode | React.ReactNode[]);
}): React.ComponentType<P> {
    return function QueryRendererMiddleMan(props: P) {
        // Because of Memory / Player - where we do separate calls
        // We need the error to be a state
        // If all goes well, this state will never be changed
        const [error, setError] = useState(false);

        const options = useMemo(() => {
            return typeof arg.options === "function" ? arg.options(props) : arg.options;
        }, [arg.options, props]) as UseQueryOptions<any>;
        const paginateRef = useRef<(mode: PaginationMode, lazyOptions: LazyQueryHookOptions<D, V>) => void>();

        const skipMainQueryWhenPaginatingRef = useRef(false);

        // returnPartialData is a dangerous feature which is why by default it's not allowed
        // on the QueryRenderer
        // long story short, returnPartialData produces an empty object meaning the component
        // that needs the data, will receive it, but may crash due to non-existing params
        // even though data exists... but still empty untill the network fetch is completed
        const allowReturnPartialData = allowReturnPartialDataForDocs.indexOf(arg.queryDoc) !== -1;

        const isTemplateSessionLoader = (arg as any).isTemplateSessionLoader || false;
        const isPlayerSessionLoader = (arg as any).isPlayerSessionLoader || false;
        const isMemorySessionLoader = (arg as any).isMemorySessionLoader || false;

        const onCompleted = useCallback(
            (payload: D) => {
                if (arg.onCompleted) {
                    arg?.onCompleted?.(payload, props);
                }
            },
            [props]
        );

        const hookOpts: UseQueryOptions<any> = useMemo(() => {
            return {
                ...options,
                returnPartialData: allowReturnPartialData,
                ...(allowReturnPartialData ? {fetchPolicy: "cache-only", nextFetchPolicy: "cache-only"} : null),
                optimistic: false,
                notifyOnNetworkStatusChange: true,
                onCompleted,
            };
        }, [options]);

        const {
            data,
            called,
            networkStatus,
            loading,
            refetch,
            error: queryError,
        } = useQuery(arg.queryDoc, {
            ...hookOpts,
            // used only for pagination
            // skipMainQueryWhenPaginatingRef.current was set true in the DataFetcher to prevent the useQuery from fetching with the original variables. The DataFetcher will merge the paginated values over the original query and will make this one return what we consider to be the correct data in this case (the paginated data written over the original query)
            fetchPolicy: skipMainQueryWhenPaginatingRef.current ? "cache-only" : hookOpts.fetchPolicy,
        } as any);

        if (skipMainQueryWhenPaginatingRef.current) {
            skipMainQueryWhenPaginatingRef.current = false;
        }

        const prevNetworkStatus = useRef(networkStatus);
        const isCachedData = networkStatus === NetworkStatus.ready && prevNetworkStatus.current === networkStatus && !loading && called;

        useEffect(() => {
            prevNetworkStatus.current = networkStatus;
        }, [networkStatus]);

        const gotData =
            (!!data || isCachedData) &&
            (allowReturnPartialData
                ? Object.keys(data ?? ({} as any)).length > ((data as any)?.__typename ? 1 : 0) &&
                  ((data as any)?.session || isPlayerSessionLoader || isMemorySessionLoader || isTemplateSessionLoader
                      ? Object.keys((data as any)?.session ?? {}).length > 0
                      : true)
                : true);

        useEffect(() => {
            // @ts-ignore
            if (isTemplateSessionLoader && options?.variables?.id) {
                // @ts-ignore
                options.variables.id = options.variables.id.replace("_template", "");
            }

            if ((isPlayerSessionLoader || isMemorySessionLoader || isTemplateSessionLoader) && !gotData && !options.skip) {
                query(
                    isMemorySessionLoader
                        ? "FullMemorySessionDocument"
                        : isTemplateSessionLoader
                        ? "GetAgendaTemplateForEditingDocument"
                        : "FullSessionDocument",
                    {
                        ...options,
                        fetchPolicy: "no-cache",
                        errorPolicy: "all",
                    }
                )
                    .then((res) => {
                        if (res.error || res.errors) {
                            setError(true);
                        }
                    })
                    .catch((err) => {
                        setError(true);
                        console.log("catched queryRenderer err", err);
                    });
            }
        }, []);

        return (
            <>
                {/* TODO @kelvin2200 this takes 1s to evaluate, pls fix */}
                <DataFetcher
                    skipMainQueryWhenPaginatingRef={skipMainQueryWhenPaginatingRef}
                    key="data-fetcher"
                    paginateRef={paginateRef}
                    options={options as any}
                    queryDoc={arg.queryDoc}
                />
                {gotData &&
                    createElement(
                        arg.component as unknown as React.ComponentType<P>,
                        Object.assign({}, props, {
                            data,
                            paginateRef,
                            refetch,
                            key: "renderer",
                        })
                    )}
                {(error || queryError) && arg.error ? arg.error : null}
                {(!data || (data && typeof data === "object" && !Array.isArray(data) && !Object.keys(data ?? {})?.length)) &&
                    (arg.preloader === false ? null : arg.preloader || <Loading />)}
            </>
        );
    };
}

// function X(){
//     const {data} = useQuery("LocalAgendaItemDocument",{
//         variables:{
//             order: 0,
//             sessionId: ""
//         }
//     })

//     data.
// }

// function getFragmentDefinitions(fragment: FragmentDoc){
//     return AstDoc[fragment].definitions;
// }

// function buildFromFragmentDefinition(definitions: readonly DefinitionNode[], partialData?: any){
//     const data = partialData || {};
//     const firstFragmentDef = definitions.find(d => d.kind === "FragmentDefinition");
//     const __typeName = firstFragmentDef?.kind === "FragmentDefinition" ? firstFragmentDef.typeCondition : undefined;
//     return definitions.reduce((m,definition) => {
//         if(definition.kind === "FragmentDefinition"){

//             return definition.selectionSet.selections.reduce((mm,field) => {
//                 return Object.assign(mm, field.kind === "Field" ? {
//                     [field.alias?.value ?? field.name.value]: field.selectionSet ?
//                 } : field.kind === "FragmentSpread" ? buildFromFragmentDefinition(getFragmentDefinitions(field.name.value + "FragmentDoc" as FragmentDoc)) : {})
//             },m);
//         }
//         return m;
//     }, __typeName ? {__typeName} : {})
// }

// function mergeData<T extends QueryDoc | FragmentDoc, D = Ast[T] extends DocumentNode<infer U, any> ? U : never>(
//     doc: T,
//     data?: DeepPartial<D>
// ) {
//     const ast = AstDoc[doc];
//     const queryDefinition = ast.definitions.find(d => d.kind === "OperationDefinition" && d.operation === "query");
//     const typeName =
//     const fragmentDefinition = ast.definitions.find(d => )
//     return {
//         __typename: ast.definitions[0].
//     }
//     return ast;
// }
