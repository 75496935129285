import {BOOK_EVENT_MAX_GUESTS_COUNT} from "@common/validation/book-event.validation";
import {GetUserCountryCodeDocument, PublicBookingEventFragment} from "@generated/data";
import {AcceptedTimezone} from "@generated/timezones";
import Button from "@ui/cdk/Button";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {AuthService} from "@workhorse/api/authService";
import {QuestionnareForm, ScheduleBookingInput} from "@workhorse/api/booking";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useBreakpoint} from "@workhorse/providers/BreakpointProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {FormikErrors, FormikTouched} from "formik";
import {FormBuilderState, FormRenderer} from "@sessions/forms";
import {useTranslation} from "react-i18next";
import classes from "../styles/BookEventDetails.module.scss";
import BookEventDetailsGuests from "./BookEventDetailsGuests";
import {useQuery} from "@workhorse/api/data";
import {BookEventDetailsHeader} from "./BookEventDetailsHeader";

type BookEventDetailsProps = {
    event: PublicBookingEventFragment;
    values: ScheduleBookingInput;
    errors: FormikErrors<ScheduleBookingInput>;
    touched: FormikTouched<ScheduleBookingInput>;
    timezone: AcceptedTimezone;
    pendingGuestRef: React.MutableRefObject<string>;

    onFinish: () => Promise<true | undefined | void> | void;
    onBack: () => void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (field: keyof ScheduleBookingInput, newValue: any) => void;
    onBlur: (field: string) => void;
    bookingError?: string | null;
    bookingTimedOut?: boolean;
    reschedule?: boolean;
    hasNextStep?: boolean;
    duration?: number;
    questionnare?: QuestionnareForm;
    isPreview?: boolean;
};

export const BookEventDetails = ({
    event,
    values,
    errors,
    touched,
    onFinish,
    onBack,
    onChange,
    onBlur,
    pendingGuestRef,
    bookingError,
    bookingTimedOut,
    questionnare,
    isPreview,
}: BookEventDetailsProps) => {
    const {t} = useTranslation();
    const {role, email, firstName, lastName, id} = useUserInfo();
    const isUser = role === "USER";
    const [isSubmitting, setIsSubmitting] = useState(false);
    const hasForm = questionnare?.enabled && questionnare?.formItems && questionnare?.formItems?.length > 0;
    useEffect(() => {
        if (bookingError || bookingTimedOut) {
            setIsSubmitting(false);
        }
    }, [bookingError, bookingTimedOut, touched, errors]);

    const breakpoint = useBreakpoint();
    const isMobileOrTablet = ["xs", "sm", "md"].indexOf(breakpoint) !== -1;

    const onFinishClicked = (e: any) => {
        if (e.detail > 1) {
            return;
        }

        if (event.ownerId === id) {
            toast(<p>{t("booking.page.cant_book_own_page")}</p>, {
                type: "error",
            });
        } else {
            setIsSubmitting(true);
            onFinish();
        }
    };

    const onSubmitForm = async (form: FormBuilderState) => {
        if (!isPreview && event.ownerId === id) {
            toast(<p>{t("booking.page.cant_book_own_page")}</p>, {
                type: "error",
            });
        } else {
            setIsSubmitting(true);
            onChange("questionnare", form);
            const finishedSubmitting = await onFinish();

            if (finishedSubmitting) {
                setIsSubmitting(false);
            }
        }
    };

    const guestsLabel = useMemo(() => questionnare?.formItems?.find((item) => item.type === "Guests")?.question, [questionnare?.formItems]);

    const nameValue = isUser && firstName ? `${firstName} ${lastName ?? ""}`.trim() : null;
    const emailValue = isUser && email ? email.trim() : null;

    const hasEmail = !!emailValue;

    const formItems = useMemo(() => {
        if (isPreview) {
            return questionnare?.formItems;
        }
        return questionnare?.formItems?.map((item) => (item.type === "Email" && hasEmail ? {...item, disabled: true} : item));
    }, [questionnare?.formItems, hasEmail, isPreview]);

    const answers: {value: string; formItemId: string}[] = useMemo(() => {
        if (isPreview || !questionnare?.formItems) {
            return [];
        }

        return questionnare?.formItems.map((item) => {
            const currentValue = (values.questionnare?.answers as any[])?.find((a) => a.formItemId === item.id)?.value;

            if (item.type === "Name") {
                return {
                    value: currentValue ?? nameValue ?? "",
                    formItemId: item.id as string,
                };
            }

            if (item.type === "Email") {
                return {
                    value: emailValue ?? currentValue ?? "",
                    formItemId: item.id as string,
                };
            }

            return {
                value: currentValue ?? "",
                formItemId: item.id as string,
            };
        });
    }, [questionnare?.formItems, values.questionnare, emailValue, nameValue, isPreview]);

    const handleLogOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        AuthService.getInstance().logout(window.location.href);
    };

    const {data} = useQuery(GetUserCountryCodeDocument);
    const userCountryCode = data?.getUserCountryCode ?? undefined;
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>New booking</div>
            </div>
            {hasForm && questionnare.description ? (
                <div className={classes.description}>
                    <Typography color="tertiary" className={classes.descriptionText}>
                        {questionnare.description}
                    </Typography>
                </div>
            ) : null}

            {hasForm ? (
                <FormRenderer
                    isMobile={false}
                    handleSubmit={onSubmitForm}
                    form={{
                        items: formItems ?? [],
                        description: "",
                    }}
                    answers={{
                        answers,
                    }}
                    showInitialValue
                    submitAdornment={
                        <Button
                            // disabled={isSubmitting}
                            onClick={onBack}
                            variant={isMobileOrTablet ? "tertiary" : "quaternary"}
                            data-id="back-button"
                        >
                            {t("g.back")}
                        </Button>
                    }
                    submitText={isSubmitting ? t("booking.page.scheduling") : t("booking.page.schedule_session")}
                    submitTooltip={
                        bookingError === "owner_booked"
                            ? t(`booking.page.error_schedule_owner_booked`) ??
                              "Unfortunately this slot is not available anymore, please choose another one."
                            : bookingError === "network_failure" || bookingTimedOut
                            ? t(`booking.page.error_schedule_network_failure`) ??
                              "Could not schedule the session due to a network failure. Please try again."
                            : undefined
                    }
                    submitDisabled={isSubmitting || Object.keys(errors).length !== 0}
                    classes={{
                        container: classes.formContainer,
                        headerContainer: classes.headerContainer,
                        title: classes.title,
                        description: classes.description,
                        submitContainer: classes.submitContainer,
                        submit: classes.footerButton,
                    }}
                    hide
                    emailEndAdornment={
                        hasEmail && !isPreview ? (
                            <Button size="smallest" className={classes.logoutBtn} onClick={handleLogOut}>
                                {t("user.log_out")}
                            </Button>
                        ) : undefined
                    }
                    userCountryCode={userCountryCode}
                >
                    <BookEventDetailsGuests
                        isUser={isUser}
                        exclude={emailValue ?? ""}
                        values={values.guests}
                        errors={
                            typeof errors.guests === "string"
                                ? t(errors.guests, {max: BOOK_EVENT_MAX_GUESTS_COUNT}) ?? ""
                                : errors.guests?.filter((x) => !!x)
                        }
                        onChange={onChange.bind(null, "guests")}
                        onBlur={onBlur.bind(null, "guests")}
                        pendingGuestRef={pendingGuestRef}
                        label={guestsLabel as string | undefined}
                    />
                </FormRenderer>
            ) : (
                <div className={classes.footer}>
                    <Tooltip
                        arrow
                        placement="top"
                        title={
                            bookingError === "owner_booked"
                                ? t(`booking.page.error_schedule_owner_booked`) ??
                                  "Unfortunately this slot is not available anymore, please choose another one."
                                : t(`booking.page.error_schedule_network_failure`) ??
                                  "Could not schedule the session due to a network failure. Please try again."
                        }
                        open={!!bookingError || bookingTimedOut}
                        disableFocusListener={true}
                        disableHoverListener={true}
                        disableTouchListener={true}
                    >
                        <Button
                            loading={isSubmitting}
                            onClick={onFinishClicked}
                            disabled={isSubmitting || Object.keys(errors).length !== 0}
                            data-id="schedule-session"
                        >
                            {isSubmitting ? t("booking.page.scheduling") : t("booking.page.schedule_session")}
                        </Button>
                    </Tooltip>
                    <Button
                        disabled={isSubmitting}
                        className={classes.backBtn}
                        onClick={onBack}
                        variant={isMobileOrTablet ? "tertiary" : "quaternary"}
                    >
                        {t("g.back")}
                    </Button>
                </div>
            )}
        </div>
    );
};
