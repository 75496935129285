import FormControl from "@ui/cdk/Input/FormControl";
import Editor from "./Editor";
import {ExtractProps} from "@workhorse/declarations";
import React, {useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "./styles/EditorInput.module.scss";
import {SessionsEditor} from "./api";
import InputCharCounter from "@ui/cdk/Input/InputCharCounter";
import {generateTextContent, parseAsTipTapJSON} from "@api/editor";

type EditorInputProps = ExtractProps<typeof FormControl> & ExtractProps<typeof Editor>;

const EditorInput = (props: EditorInputProps) => {
    const {
        value,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        debounceChange,
        maxCharCount,
        editorRef,
        classes: customClasses,
        showTypedCharCount = false,
        renderToolbarOnFocus = false,
        toolbarClassName,
        ...rest
    } = props;
    const [isFocused, setIsFocused] = useState(false);
    const {isMobile} = useMobile();

    const iRef = useRef<undefined | {setCount(n: number): void}>();
    const internalEditorRef = useRef<SessionsEditor | null>(null);

    const onInputFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        setIsFocused(true);
        onFocus?.(e);
    };

    const onInputBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        setIsFocused(false);
        onBlur?.(e);
    };

    const onContentChanged = (content, str, length) => {
        iRef.current?.setCount(length);
        onChange?.(content, str, length);
    };

    const onReady = () => {
        if (internalEditorRef.current) {
            if (iRef.current) {
                iRef.current.setCount(internalEditorRef.current.storage.characterCount.characters());
            }
            if (editorRef?.current) {
                editorRef.current = internalEditorRef.current;
            }
        }
    };

    return (
        <FormControl variant="outlined" {...rest} shrinkLabel={false} className={classes.formControl}>
            <div className={cls(classes.base, isFocused && "focused")}>
                <Editor
                    className={classes.editor}
                    classes={{editable: classes.editable}}
                    value={value}
                    placeholder={placeholder}
                    onChange={onContentChanged}
                    onBlur={onInputBlur}
                    onFocus={onInputFocus}
                    debounceChange={debounceChange}
                    maxLength={maxCharCount}
                    editorRef={internalEditorRef}
                    hideToolbar={isMobile}
                    renderToolbarOnFocus={renderToolbarOnFocus}
                    toolbarClassName={toolbarClassName}
                    onReady={onReady}
                />
                {showTypedCharCount && (
                    <div className={classes.counter}>
                        <InputCharCounter
                            maxCharCount={maxCharCount}
                            value={value ? generateTextContent(parseAsTipTapJSON(value)) : ""}
                            iRef={iRef}
                        />
                    </div>
                )}
                <span className={cls(classes.fieldset, isFocused && "focused")} />
            </div>
        </FormControl>
    );
};

export default EditorInput;
