import UAParser from "ua-parser-js";
import {detect} from "detect-browser";

class BrowserInfo {
    private parser = new UAParser();
    public info = this.parser.getResult();

    private browserName = detect()?.name.toLowerCase() || "unknown";

    private firefoxBrowsers = ["firefox", "mozilla"];
    private chromiumBrowsers = ["chrome", "chromium", "edge", "edge-chromium", "chromium-webview", "android browser", "opera", "samsung"];
    private webkitBrowsers = ["crios", "fxios", "safari", "ios", "ios-webview", "edge-ios"];

    public isMobile() {
        return this.info.device.type === "mobile";
    }

    public isTablet() {
        return this.info.device.type === "tablet";
    }

    public isChromium() {
        return this.chromiumBrowsers.some((browser) => this.isBrowser(browser));
    }

    public isWebkit() {
        return this.webkitBrowsers.some((browser) => this.isBrowser(browser));
    }

    public isEdge() {
        return this.isBrowser("edge-chromium");
    }

    public isSafari() {
        return this.isBrowser("safari");
    }

    public isOpera() {
        return this.isBrowser("opera");
    }

    public isFirefox() {
        return this.firefoxBrowsers.some((browser) => this.isBrowser(browser));
    }

    public isIOS() {
        return this.info.os.name?.toLowerCase() === "ios";
    }

    public isIOSFirefox() {
        return this.isBrowser("fxios");
    }

    public isIOSChrome() {
        return this.isBrowser("crios");
    }

    public isIOSEdge() {
        return this.isBrowser("edge-ios");
    }

    public isMacOS() {
        return this.info.os.name?.toLowerCase()?.trim() === "mac os";
    }

    public OS() {
        if (this.info.os.name?.indexOf("Mac") !== -1) {
            return "Mac";
        } else if (this.info.os.name?.indexOf("Linux") !== -1) {
            return "Linux";
        } else if (this.info.os.name?.indexOf("Win") !== -1) {
            return "Windows";
        } else {
            return "Unknown";
        }
    }

    public isIpadOS() {
        const isIpadCheck1 =
            "ontouchstart" in window &&
            "DeviceOrientationEvent" in window &&
            ["mac os", "ipados"].indexOf((this.info.os.name || "").toLowerCase()) !== -1;

        const hasTouch = navigator.maxTouchPoints != null;
        const isIpadCheck2 = hasTouch && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);

        return this.isIOS() || isIpadCheck1 || isIpadCheck2;
    }

    public isAndroid() {
        return this.info.os.name?.toLowerCase() === "android";
    }

    public isSamsungInternet() {
        return this.isBrowser("samsung");
    }

    public isDesktopSafari() {
        return this.isSafari() && this.isMacOS();
    }

    private isBrowser(name: string) {
        return this.browserName === name;
    }

    public isAppleShit(minVersion?: number) {
        const isSafariOrChromium = this.isSafari() || this.isChromium();
        const isIpadOrIphone = this.isIpadOS() || this.isIOS();
        const gotMinV = minVersion != null;
        const safariV = this.isSafari() && this.info.browser.version ? parseInt(this.info.browser.version) : null;

        return isIpadOrIphone && isSafariOrChromium
            ? gotMinV
                ? (
                      this.isIpadOS() && safariV
                          ? safariV >= minVersion!
                          : this.info.os.version && parseInt(this.info.os.version) >= minVersion!
                  )
                    ? true
                    : false
                : true
            : false;
    }
}

const browserInfo = new BrowserInfo();

export default browserInfo;
