import React, {PropsWithChildren} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {WithClasses, WithClassName, WithStyle} from "@workhorse/declarations";
import {makeStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";

export type DialogColumnClassKey = "root" | "left" | "right" | "unscrollable";

const useStyles = makeStyles((theme) =>
    createStyles<DialogColumnClassKey, {}>({
        root: {
            display: "flex",
            flexDirection: "column",
            "&.relative": {
                position: "relative",
            },
        },
        left: {
            padding: theme.spacing(3.75),
            borderRight: `solid 1px ${theme.main.palette.cdkPalette.border.primary}`,
            width: 270,
            minWidth: 270,
            overflowX: "hidden",
            overflowY: "auto",
        },
        right: {
            background: theme.main.palette.elevation.surface[0],
            overflowX: "hidden",
            overflowY: "auto",
            width: "100%",
        },
        unscrollable: {
            height: "100%",
            overflow: "hidden",
        },
    })
);

type DialogColumnProps = WithClasses<DialogColumnClassKey> &
    WithStyle &
    WithClassName &
    PropsWithChildren<{
        left?: boolean;
        right?: boolean;
        large?: boolean;
        relative?: boolean;
        notScrollable?: boolean;
    }>;

const DialogColumn = (props: DialogColumnProps) => {
    const classes = useStyles();
    const largeStyle = props.large ? {width: 390} : {};
    return (
        <div
            className={cls(
                classes.root,
                props.left && classes.left,
                props.right && classes.right,
                props.left && props.classes?.left,
                props.right && props.classes?.right,
                props.relative && "relative",
                props.notScrollable && classes.unscrollable,
                props.className
            )}
            style={{...props.style, ...largeStyle}}
        >
            {props.children}
        </div>
    );
};

export default DialogColumn;
