import {cls} from "@ui/cdk/util";
import classes from "./style/NavigationUserDetailsItem.module.scss";
import Typography from "@ui/cdk/Typography";
import Link from "@ui/cdk/Link";
import {LinkProps} from "@ui/cdk/Link/types";

type NavigationUserDetailsItemProps = Omit<LinkProps, "icon" | "children"> & {
    icon: JSX.Element;
    endIcon?: JSX.Element;
    text: string;
    variant?: "regular" | "destructive" | "primary";
};

export default function NavigationUserDetailsItem({icon, endIcon, text, className, variant, ...rest}: NavigationUserDetailsItemProps) {
    return (
        <Link
            className={cls(
                "flex",
                "flex-center-all",
                classes.container,
                variant === "destructive" ? classes.destructive : "",
                variant === "primary" ? classes.primary : "",
                className
            )}
            {...rest}
        >
            <div className="flex flex-align-center mr-auto">
                {icon}
                <Typography fontWeight="bold" component="p" variant="base" className={classes.text}>
                    {text}
                </Typography>
            </div>
            {endIcon}
        </Link>
    );
}
