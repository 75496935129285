import {SessionsShallowSearchDocument, ShallowSearchResult} from "@generated/data";
import PeopleIcon from "@material-ui/icons/People";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import {dateFormat} from "@ui/cdk/DateFormat";
import Time from "@ui/cdk/Time";
import TimeRange from "@ui/cdk/TimeRange";
import {collapseWhiteSpace} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useUserInfo} from "@workhorse/providers/User";
import Highlighter from "react-highlight-words";
import AttachDiagIcon from "../../assets/media/attach-diag-icon.svg";
import SearchResultItem, {SearchResultItemProps} from "./SearchResultItem";
import {cleanText, getSessionResultIconAndTooltip} from "./utils";

type SearchResultsListProps = {
    term: string;
    checkNextPageResults: React.MutableRefObject<(() => void) | null>;
    isSearching: boolean;
    setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
    setHasResults: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchResultsList = ({term, checkNextPageResults, isSearching, setIsSearching, setHasResults}: SearchResultsListProps) => {
    const [allResults, setAllResults] = useState<SearchResultItemProps[]>([]);
    const [latestResults, setLatestResults] = useState<ShallowSearchResult["sessions"]>([]);
    const [latestPage, setLatestPage] = useState<string | null>(null);
    const user = useUserInfo();

    async function doSearch(term: string) {
        const shallowSearchResult = await apollo.client.query({
            query: SessionsShallowSearchDocument,
            fetchPolicy: "no-cache",
            variables: {
                term,
                pageToken: latestPage,
                time: new Date(),
            },
        }); //svc.search.shallowSearch(term, latestPage, Date.now());
        setLatestResults(shallowSearchResult.data.sessionsShallowSearch.sessions ?? []);
        setLatestPage(shallowSearchResult.data.sessionsShallowSearch?.pageToken ?? null);
        setIsSearching(false);
    }

    function checkForMoreResults() {
        // if the last page had results, doSearch for the next one
        if (latestResults.length) {
            doSearch(term);
        }
    }

    useEffect(() => {
        checkNextPageResults.current = checkForMoreResults;
    }, [checkForMoreResults]);

    useEffect(() => {
        // initial search after results reset
        if (term !== "" && isSearching && latestPage === null && allResults.length === 0) {
            doSearch(term);
        }
    }, [isSearching, latestPage, allResults]);

    useEffect(() => {
        // reset results when the term changes
        setHasResults(false);
        setAllResults([]);
        setLatestPage(null);
        setIsSearching(true);
    }, [term]);

    useEffect(() => {
        // when the latest results change, add them to the results list
        const resultsToAdd = latestResults.map((session) => {
            const startDate = session.lifecycle === "NOT_STARTED" ? session.plannedStart : session.start;

            const descriptionDate = (
                <div className="flex">
                    {startDate ? dateFormat(new Date(startDate), ["normalDate", "year"]) : ""}
                    <div className="mx-2"> </div>
                    {startDate ? (
                        session.end ? (
                            <TimeRange
                                start={startDate ? new Date(startDate) : new Date()}
                                end={session.end ? new Date(session.end) : new Date()}
                                timePropsStart={{relativeMeridian: true}}
                                timePropsEnd={{relativeMeridian: true}}
                            />
                        ) : session.lifecycle === "STARTED" ? (
                            <>
                                {"Live - Started at "}
                                <Time className="ml-4" time={new Date(startDate || 0)} isHalfDayClock={user.halfDayClock} />
                            </>
                        ) : null
                    ) : null}
                </div>
            );

            const highlight: JSX.Element[] = [];
            let foundByName = false;

            const termSplit = term.trim().split(" ");

            if (session.highlight.name) {
                foundByName = true;
                highlight.push(
                    <div>
                        <Highlighter searchWords={termSplit} autoEscape={true} textToHighlight={collapseWhiteSpace(session.name.trim())} />
                    </div>
                );
            } else {
                if (session.highlight.participants) {
                    if (session.highlight.participants.length === 1) {
                        highlight.push(<PersonRoundedIcon />);
                    } else {
                        highlight.push(<PeopleIcon />);
                    }
                    const noOfElements = Math.min(session.highlight.participants.length, 3);
                    for (let i = 0; i < noOfElements; i++) {
                        if ((session.highlight.participants[i].email ?? "").includes("<em>")) {
                            highlight.push(
                                <div className="flex">
                                    <Highlighter
                                        searchWords={termSplit}
                                        autoEscape={true}
                                        textToHighlight={cleanText(session.highlight.participants[i].email ?? "")}
                                    />
                                </div>
                            );
                            if (noOfElements > 1 && i + 1 < noOfElements) {
                                highlight.push(<div>{"; "}</div>);
                            }
                        } else {
                            highlight.push(
                                <div className="flex">
                                    <Highlighter
                                        searchWords={termSplit}
                                        autoEscape={true}
                                        textToHighlight={cleanText(session.highlight.participants[i].name ?? "")}
                                    />
                                </div>
                            );
                            if (noOfElements > 1 && i + 1 < noOfElements) {
                                highlight.push(<div>{"; "}</div>);
                            }
                        }
                    }
                }
                if (session.highlight.resourceNames) {
                    highlight.push(<img src={AttachDiagIcon}></img>);
                    const noOfElements = Math.min(session.highlight.resourceNames.length, 3);
                    for (let i = 0; i < Math.min(session.highlight.resourceNames.length, 3); i++) {
                        highlight.push(
                            <div className="flex">
                                <Highlighter
                                    searchWords={termSplit}
                                    autoEscape={true}
                                    textToHighlight={cleanText(session.highlight.resourceNames[i] ?? "")}
                                />
                            </div>
                        );
                        if (noOfElements > 1 && i + 1 < noOfElements) {
                            highlight.push(<div>{"; "}</div>);
                        }
                    }
                }
            }

            const highlightElement = (
                <div className="flex flex-align-center">
                    {highlight.slice(0, 6).map((item) => {
                        return item;
                    })}
                </div>
            );

            const info = getSessionResultIconAndTooltip(session.ownedBySelf, undefined, {
                isRecurrent: session.recurrenceId !== null,
                isEvent: session.isEvent,
                isBooking: session.isBooking,
            });

            return {
                title: foundByName ? highlight[0] : <p>{collapseWhiteSpace(session.name.trim())}</p>,
                description: descriptionDate,

                link: session.lifecycle === "ENDED" ? `/memory/session/${session.id}` : `/session/${session.id}`,
                iconUrl: info.iconUrl,
                tooltip: info.tooltip,
                color: info.color,
                foundByName: foundByName,
                highlight: highlightElement,
            };
        });
        if (resultsToAdd.length) {
            setHasResults(true);
        }
        setAllResults(allResults.concat(resultsToAdd));
    }, [latestResults]);

    return (
        <>
            {allResults.map((item, index) => (
                <SearchResultItem
                    key={`result-session-${index}`}
                    title={item.title}
                    description={item.description}
                    link={item.link}
                    iconUrl={item.iconUrl ?? ""}
                    highlight={item.highlight}
                    foundByName={item.foundByName}
                    tooltip={item.tooltip}
                    color={item.color || "blue"}
                />
            ))}
        </>
    );
};

export default SearchResultsList;
