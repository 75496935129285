import {makeStyles} from "@material-ui/core";
import Button from "@ui/cdk/Button";
import React from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: "14px 30px",
        justifyContent: "flex-end",
    },
}));

interface ModalFooterProps {
    isAdmin: boolean;
    assistantCanEndSessionForAll: boolean;
    isAssistant: boolean;
    closeDialog: () => void;
    handleEndSession: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    onboardingType?: SessionOnboardingType | null;
    loading?: boolean;
}

const ModalFooter = (props: ModalFooterProps) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {isAdmin, closeDialog, handleEndSession, isAssistant, assistantCanEndSessionForAll, className, onboardingType, loading} = props;
    const dataIdString =
        onboardingType === SessionOnboardingType.FirstStrike
            ? "onboarding-button-strike1-step4-leave"
            : onboardingType === SessionOnboardingType.SecondStrikeMember || onboardingType === SessionOnboardingType.SecondStrikeOwner
            ? "onboarding-button-strike2-leave"
            : "player-dialog-leave-session";
    return (
        <div className={cls("flex", classes.footer, className)}>
            <Button type="button" variant="quaternary" onClick={closeDialog}>
                {t("g.cancel")}
            </Button>
            <Button
                data-id={dataIdString}
                type="button"
                variant="destructive-secondary"
                withMarginLeft
                onClick={handleEndSession}
                loading={loading}
                disabled={loading}
            >
                {isAdmin || (isAssistant && assistantCanEndSessionForAll)
                    ? t("participant.leave_session.end_session")
                    : t("participant.leave_session.leave_session")}
            </Button>
        </div>
    );
};

export default ModalFooter;
