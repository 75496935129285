import {useQuery} from "@apollo/client";
import {CustomSmsMessageTemplatesDocument, OrganizationPermissionType, WorkspaceMessageExternalProviderDocument} from "@generated/data";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {SessionMessageReminderJsonType, SessionMessageRemindersJsonType} from "@sessions/common/reminders/index";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input/Input";
import Link from "@ui/cdk/Link";
import Select from "@ui/cdk/Select";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useUserAccess} from "@workhorse/api/access/hooks";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";
import {ReactComponent as SmsReminderIcon} from "../../assets/sms-reminder.svg";
import classes from "../styles/SessionSendReminder.module.scss";

type SessionSendReminderProps = {
    messagesValue?: SessionMessageRemindersJsonType;
    onChangeMessages?: (SessionMessageRemindersJsonType, hasChanged?: boolean) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    triggerHasChanges?: () => void;

    isEvent?: boolean;
    isBooking?: boolean;
};

const SessionSendReminderSMSMessages = ({
    messagesValue,
    onChangeMessages,
    showIcon = false,
    disabled,
    className,
    triggerHasChanges,
    isEvent,
    isBooking,
}: SessionSendReminderProps) => {
    const {t} = useTranslation();

    const unitOptions = [
        {
            value: "minutes",
            text: t("g.time.minutes"),
            textSingular: t("g.time.minute"),
        },
        {value: "hours", text: t("g.time.hours"), textSingular: t("g.time.hour")},
        {value: "days", text: t("g.time.days"), textSingular: t("g.time.day")},
        {value: "weeks", text: t("g.time.weeks"), textSingular: t("g.time.week")},
    ];

    const user = useUserInfo();
    const userOrgRole = user.activeOrganizationPermission.permissionType;

    const access = useUserAccess();
    const canAddSmsReminder = access.workspace().canAddSmsReminder();

    const intialRender = useRef<boolean>(false);

    const {loading: workspaceProviderLoading, data: workspaceProviderData} = useQuery(WorkspaceMessageExternalProviderDocument, {
        variables: {
            workspaceId: user?.activeWorkspace?.id,
        },
        fetchPolicy: "cache-and-network",
    });
    const hasWorkspaceProvider = !!workspaceProviderData?.workspaceMessageExternalProvider;

    const fieldsDisabled = disabled || !hasWorkspaceProvider || !canAddSmsReminder;

    const {loading: templatesLoading, data: smsTemplatesData} = useQuery(CustomSmsMessageTemplatesDocument);
    const loading = workspaceProviderLoading || templatesLoading;

    const availableTemplates =
        smsTemplatesData?.customMessageTemplates?.filter((t) =>
            isEvent ? t.templateId === "EVENT_MESSAGE_REMINDER" : isBooking ? t.templateId === "BOOKING_MESSAGE_REMINDER" : true
        ) ?? [];

    const [messageReminders, setMessageReminders] = useState<SessionMessageReminderJsonType[]>(() => {
        if (
            messagesValue &&
            "smsReminders" in messagesValue &&
            messagesValue.smsReminders !== null &&
            messagesValue.smsReminders !== undefined
        ) {
            const formattedReminders = messagesValue.smsReminders.map((reminder) => {
                const minutes =
                    reminder.minutes /
                    (reminder.unit === "minutes" ? 1 : reminder.unit === "hours" ? 60 : reminder.unit === "days" ? 1440 : 10080);
                return {
                    minutes,
                    unit: reminder.unit,
                    customMessageTemplateId: reminder.customMessageTemplateId,
                    name: reminder.name,
                };
            });
            return formattedReminders;
        }

        return [];
    });

    const handleAddMessageReminder = () => {
        const hasOneHourReminder = messageReminders?.some((reminder) => reminder?.unit === "hours" && reminder?.minutes === 1);
        const lastReminderValue = messageReminders?.length ? messageReminders?.[messageReminders?.length - 1].minutes : 0;

        const foundTemplate = availableTemplates[0];

        if (!foundTemplate) {
            return;
        }

        setMessageReminders([
            ...messageReminders,
            {
                minutes: hasOneHourReminder ? lastReminderValue + 1 : 1,
                unit: "hours",
                customMessageTemplateId: foundTemplate.id,
                name: foundTemplate.name,
            },
        ]);

        triggerHasChanges?.();
    };

    const handleMessageValueChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newReminders = [...messageReminders];
        newReminders[index].minutes = parseInt(event.target.value);
        setMessageReminders(newReminders);
        triggerHasChanges?.();
    };

    const handleMessageUnitChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newReminders = [...messageReminders];
        newReminders[index].unit = event.target.value;

        setMessageReminders(newReminders);

        triggerHasChanges?.();
    };

    const handleMessageTemplateChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newReminders = [...messageReminders];
        newReminders[index].customMessageTemplateId = event.target.value;

        const foundTemplate = availableTemplates.find((template) => template.id === event.target.value);
        if (foundTemplate) {
            newReminders[index].name = foundTemplate.name;
        }

        setMessageReminders(newReminders);

        triggerHasChanges?.();
    };

    const handleRemoveMessageReminder = (index: number) => {
        const newReminders = [...messageReminders];
        newReminders.splice(index, 1);
        setMessageReminders(newReminders);

        triggerHasChanges?.();
    };

    useEffect(() => {
        const formattedReminders: SessionMessageReminderJsonType[] = messageReminders.map((reminder) => {
            const minutes =
                reminder?.minutes *
                (reminder?.unit === "minutes" ? 1 : reminder?.unit === "hours" ? 60 : reminder?.unit === "days" ? 1440 : 10080);
            return {
                minutes,
                unit: reminder?.unit,
                customMessageTemplateId: reminder?.customMessageTemplateId,
                name: reminder?.name,
            };
        });

        onChangeMessages?.({smsReminders: formattedReminders}, intialRender.current);

        if (!intialRender.current) {
            intialRender.current = true;
        }
    }, [messageReminders]);

    return (
        <div className="flex flex-col flex-justify-between flex-align-start fullw">
            <div className={classes.titleWrapper}>
                {showIcon ? <div className={classes.iconWrapper}>{<SmsReminderIcon />}</div> : null}

                <Typography variant="base" fontWeight="bold" color="secondary">
                    {t("session.dialog.sms_reminders")}
                </Typography>

                <Tooltip
                    title={
                        t("session.dialog.sms_reminders_info_tooltip") ||
                        "Send out SMS reminders to your participants before a session starts. Remember to ask for their phone number in the registration form."
                    }
                    arrow
                    placement="top"
                >
                    <InfoOutlinedIcon className={classes.tooltipIcon} />
                </Tooltip>
            </div>

            <div className={cls(classes.remindersWrapper, "flex flex-col my-10")}>
                {messageReminders?.map((reminder: SessionMessageReminderJsonType, index: number) => (
                    <div className="flex flex-row flex-align-center fullw gap-12 mb-10" key={`reminder-${index}`}>
                        <Input
                            className={classes.reminderValueInput}
                            type="number"
                            onChange={handleMessageValueChange.bind(null, index)}
                            value={reminder.minutes}
                            inputProps={{
                                min: 1,
                            }}
                            disabled={fieldsDisabled}
                        />
                        <Select
                            data-id="email-reminder-select-unit"
                            className={classes.selectInput}
                            options={unitOptions}
                            value={reminder.unit}
                            IconComponent={ExpandMoreRoundedIcon}
                            onChange={handleMessageUnitChange.bind(null, index)}
                            MenuProps={{
                                classes: {
                                    paper: classes.selectMenuPaper,
                                },
                            }}
                            disabled={fieldsDisabled}
                            renderValue={(value: string) =>
                                reminder?.minutes === 1 ? unitOptions.find((option) => option.value === value)?.textSingular : value
                            }
                        />

                        {reminder?.customMessageTemplateId && (
                            <Select
                                data-id="email-reminder-select-template"
                                className={classes.selectInput}
                                options={availableTemplates.map((template) => ({value: template.id, text: template.name}))}
                                value={reminder.customMessageTemplateId}
                                IconComponent={ExpandMoreRoundedIcon}
                                onChange={handleMessageTemplateChange.bind(null, index)}
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenuPaper,
                                    },
                                }}
                                disabled={fieldsDisabled || availableTemplates.length === 0}
                                renderValue={(id: string) =>
                                    availableTemplates.find((template) => template.id === id)?.name ?? "Can't find template"
                                }
                            />
                        )}

                        <IconButton
                            className={classes.deleteButton}
                            onClick={() => {
                                handleRemoveMessageReminder(index);
                            }}
                        >
                            <DeleteOutlineRoundedIcon />
                        </IconButton>
                    </div>
                ))}
                {messageReminders.length > 0 && (
                    <Typography variant="sm" color="secondary" className="mt-6 mb-10">
                        Please make sure that you’ve set up Messaging Geographic Permissions in Twilio, otherwise participants from other
                        countries won’t receive your messages.
                    </Typography>
                )}
                {messageReminders.length > 4 ? null : loading ? (
                    <div className={classes.loadingContainer}>
                        <Loading />
                    </div>
                ) : hasWorkspaceProvider ? (
                    availableTemplates.length > 0 ? (
                        <Button variant="plain" onClick={handleAddMessageReminder} className={classes.addReminderButton}>
                            {t("session.dialog.add_reminder")}
                        </Button>
                    ) : (
                        <p>
                            No custom SMS message templates found.{" "}
                            <Link to="/user/workspace/sms-templates" target="_blank" className={cls("p-0", classes.selectWorkspaceLink)}>
                                Go to your SMS templates
                            </Link>{" "}
                            and create a template to enable SMS reminders.
                        </p>
                    )
                ) : userOrgRole === OrganizationPermissionType.Owner ? (
                    <p>
                        No messaging providers found.{" "}
                        <Link to="/user/workspace/general" target="_blank" className={cls("p-0", classes.selectWorkspaceLink)}>
                            Go to your workspace settings
                        </Link>{" "}
                        and select a provider to enable SMS reminders.
                    </p>
                ) : (
                    <p>
                        No messaging providers found. Please contact the organization owner in order to assign a messaging provider to this
                        workspace.
                    </p>
                )}
            </div>
        </div>
    );
};

export default SessionSendReminderSMSMessages;
