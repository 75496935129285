import {forwardRef} from "@workhorse/api/rendering";
import MuiBadge, {BadgeProps} from "@material-ui/core/Badge";

function BadgeNoRef(props: BadgeProps, ref: any) {
    return <MuiBadge {...props} overlap="rectangular" ref={ref} />;
}

const Badge = forwardRef(BadgeNoRef) as typeof BadgeNoRef;

export default Badge;
