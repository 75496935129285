import Typography from "@ui/cdk/Typography";
import classes from "./styles/WorkspacePreview.module.scss";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import {WorkspacePreviewData} from "../OnboardingWorkspaceDialog";

type WorkspacePreviewSmallProps = {} & WorkspacePreviewData;

const WorkspacePreviewSmall = (props: WorkspacePreviewSmallProps) => {
    const {companyName, companyLogo, companyWebsite, sessionBackground} = props;

    return (
        <svg viewBox="0 0 830 780" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root}>
            <g clip-path="url(#clip0_618_365288)">
                <path d="M0 20C0 8.9543 8.9543 0 20 0H830V780H20C8.95429 780 0 771.046 0 760V20Z" fill="url(#paint0_linear_618_365288)" />
                <g filter="url(#filter0_d_618_365288)">
                    <g clip-path="url(#clip1_618_365288)">
                        <rect
                            x="110"
                            y="50.4355"
                            width="1158.48"
                            height="679.128"
                            rx="16.09"
                            fill={sessionBackground ? `url(#sessionBackground)` : "#F8FAFC"}
                        />
                        <foreignObject x="121.5" y="62.5" width="300" height="29" rx="2.5" className={classes.header}>
                            <div>{companyLogo ? <img src={companyLogo} alt="company-logo" /> : <BrandingLogo size="small" />}</div>
                        </foreignObject>

                        <g clip-path="url(#clip2_618_365288)">
                            <rect x="122.067" y="103.661" width="205.953" height="613.835" rx="9.65403" fill="white" />
                            <rect x="130" y="112" width="189.863" height="129.097" rx="9.65403" fill="#F8FAFC" />
                            <rect x="141.788" y="136.953" width="167.336" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="151.389" width="57" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="122.518" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <line
                                x1="141.665"
                                y1="203.688"
                                x2="308.197"
                                y2="203.688"
                                stroke="#EDF2F7"
                                stroke-width="0.804502"
                                stroke-linecap="round"
                            />
                            <rect x="247.478" y="217.007" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <path
                                d="M297.74 229.051C301.266 229.051 303.225 229.051 304.505 227.771C305.785 226.491 305.785 224.531 305.785 221.006C305.785 217.481 305.785 215.521 304.505 214.241C303.225 212.961 301.266 212.961 297.74 212.961C294.215 212.961 292.255 212.961 290.975 214.241C289.695 215.521 289.695 217.481 289.695 221.006C289.695 224.531 289.695 226.491 290.975 227.771C292.255 229.051 294.215 229.051 297.74 229.051Z"
                                fill="#F59E0B"
                            />
                            <rect x="130" y="253.097" width="189.863" height="129.097" rx="9.65403" fill="#F8FAFC" />
                            <rect x="141.788" y="278.05" width="167.336" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="292.485" width="57" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="263.614" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <line
                                x1="141.665"
                                y1="344.784"
                                x2="308.197"
                                y2="344.784"
                                stroke="#EDF2F7"
                                stroke-width="0.804502"
                                stroke-linecap="round"
                            />
                            <rect x="247.478" y="358.104" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <path
                                d="M297.74 370.148C301.266 370.148 303.225 370.148 304.505 368.868C305.785 367.588 305.785 365.628 305.785 362.103C305.785 358.577 305.785 356.618 304.505 355.338C303.225 354.058 301.266 354.058 297.74 354.058C294.215 354.058 292.255 354.058 290.975 355.338C289.695 356.618 289.695 358.577 289.695 362.103C289.695 365.628 289.695 367.588 290.975 368.868C292.255 370.148 294.215 370.148 297.74 370.148Z"
                                fill="#9AB4E1"
                            />
                            <rect x="130" y="394.193" width="189.863" height="129.097" rx="9.65403" fill="#F8FAFC" />
                            <rect x="141.788" y="419.146" width="167.336" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="433.582" width="57" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="404.711" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <line
                                x1="141.665"
                                y1="485.881"
                                x2="308.197"
                                y2="485.881"
                                stroke="#EDF2F7"
                                stroke-width="0.804502"
                                stroke-linecap="round"
                            />
                            <rect x="247.478" y="499.2" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <path
                                d="M297.74 511.244C301.266 511.244 303.225 511.244 304.505 509.964C305.785 508.684 305.785 506.725 305.785 503.199C305.785 499.674 305.785 497.714 304.505 496.434C303.225 495.154 301.266 495.154 297.74 495.154C294.215 495.154 292.255 495.154 290.975 496.434C289.695 497.714 289.695 499.674 289.695 503.199C289.695 506.725 289.695 508.684 290.975 509.964C292.255 511.244 294.215 511.244 297.74 511.244Z"
                                fill="#E590AC"
                            />
                            <rect x="130" y="535.29" width="189.863" height="129.097" rx="9.65403" fill="#F8FAFC" />
                            <rect x="141.788" y="560.243" width="167.336" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="574.679" width="57" height="8" rx="1" fill="#CBD5E1" />
                            <rect x="141.788" y="545.808" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <line
                                x1="141.665"
                                y1="626.978"
                                x2="308.197"
                                y2="626.978"
                                stroke="#EDF2F7"
                                stroke-width="0.804502"
                                stroke-linecap="round"
                            />
                            <rect x="247.478" y="640.297" width="35" height="8" rx="1" fill="#CBD5E1" />
                            <path
                                d="M297.74 652.341C301.266 652.341 303.225 652.341 304.505 651.061C305.785 649.781 305.785 647.821 305.785 644.296C305.785 640.771 305.785 638.811 304.505 637.531C303.225 636.251 301.266 636.251 297.74 636.251C294.215 636.251 292.255 636.251 290.975 637.531C289.695 638.811 289.695 640.771 289.695 644.296C289.695 647.821 289.695 649.781 290.975 651.061C292.255 652.341 294.215 652.341 297.74 652.341Z"
                                fill="#60EBBC"
                            />
                        </g>
                        <rect
                            x="121.665"
                            y="103.259"
                            width="206.757"
                            height="614.64"
                            rx="10.0563"
                            stroke="#EDF2F7"
                            stroke-width="0.804502"
                        />
                        <rect x="340.088" y="103.661" width="128.67" height="72.4052" rx="6.43602" fill="#FCD34D" />
                        <rect x="480.758" y="103.661" width="128.67" height="72.4052" rx="6.43602" fill="#95809D" />
                        <rect x="621.428" y="103.661" width="128.67" height="72.4052" rx="6.43602" fill="#FAA6CE" />
                        <rect x="762.098" y="103.661" width="128.67" height="72.4052" rx="6.43602" fill="#60EBBC" />
                        <rect width="916.328" height="481.897" transform="translate(340.088 188.134)" fill="white" />
                        <path d="M430.44 268.29L427.407 252.519L440.752 260.404L434.08 262.224L430.44 268.29Z" fill="#FFCE34" />
                        <path
                            d="M528.099 271.929H448.031C447.361 271.929 446.817 272.472 446.817 273.142V291.339C446.817 292.009 447.361 292.552 448.031 292.552H528.099C528.769 292.552 529.312 292.009 529.312 291.339V273.142C529.312 272.472 528.769 271.929 528.099 271.929Z"
                            fill="#FFCE34"
                        />
                        <path d="M497.215 533.776L494.182 518.005L507.526 525.89L500.854 527.71L497.215 533.776Z" fill="#9AB4E1" />
                        <path
                            d="M622.777 537.416H514.806C514.136 537.416 513.593 537.959 513.593 538.629V556.827C513.593 557.497 514.136 558.04 514.806 558.04H622.777C623.447 558.04 623.99 557.497 623.99 556.827V538.629C623.99 537.959 623.447 537.416 622.777 537.416Z"
                            fill="#9AB4E1"
                        />
                        <path d="M758.678 367.245L755.646 351.474L768.99 359.359L762.318 361.179L758.678 367.245Z" fill="#A78BFA" />
                        <path
                            d="M862.403 370.883H776.269C775.599 370.883 775.056 371.426 775.056 372.096V390.293C775.056 390.963 775.599 391.507 776.269 391.507H862.403C863.073 391.507 863.616 390.963 863.616 390.293V372.096C863.616 371.426 863.073 370.883 862.403 370.883Z"
                            fill="#A78BFA"
                        />
                        <path
                            d="M349.654 188.536H1250.7C1255.81 188.536 1259.95 192.678 1259.95 197.788V229.566H340.402V197.788C340.402 192.678 344.544 188.536 349.654 188.536Z"
                            fill="white"
                        />
                        <path
                            d="M358.102 204.358V213.744C358.102 214.482 358.705 215.085 359.442 215.085H368.828C369.566 215.085 370.169 214.482 370.169 213.744V204.358C370.169 203.621 369.566 203.018 368.828 203.018H359.442C358.705 203.018 358.102 203.621 358.102 204.358Z"
                            fill="#CBD5E1"
                        />
                        <path
                            d="M382.236 204.358V213.744C382.236 214.482 382.84 215.085 383.577 215.085H392.963C393.7 215.085 394.304 214.482 394.304 213.744V204.358C394.304 203.621 393.7 203.018 392.963 203.018H383.577C382.84 203.018 382.236 203.621 382.236 204.358Z"
                            fill="#F1F5F9"
                        />
                        <rect x="420.45" y="196.179" width="669.346" height="25.7441" rx="4.02251" fill="#F8FAFC" />
                        <foreignObject x="431.71" y="196.179" width="669.346" height="25.7441" rx="4.02251" className={classes.website}>
                            <div>
                                <Typography variant="sm" color="quaternary">
                                    {companyWebsite}
                                </Typography>
                            </div>
                        </foreignObject>

                        <path
                            d="M349.654 188.536H1250.7C1255.81 188.536 1259.95 192.678 1259.95 197.788V229.566H340.402V197.788C340.402 192.678 344.544 188.536 349.654 188.536Z"
                            stroke="#EDF2F7"
                            stroke-width="0.804502"
                        />
                        <rect x="618.849" y="684.513" width="40.2251" height="30.5711" rx="6.03377" fill="white" />
                        <path
                            d="M632.928 695.105V704.491C632.928 705.229 633.531 705.832 634.269 705.832H643.654C644.392 705.832 644.995 705.229 644.995 704.491V695.105C644.995 694.368 644.392 693.765 643.654 693.765H634.269C633.531 693.765 632.928 694.368 632.928 695.105Z"
                            fill="#CBD5E1"
                        />
                        <rect
                            x="618.849"
                            y="684.513"
                            width="40.2251"
                            height="30.5711"
                            rx="6.03377"
                            stroke="#EDF2F7"
                            stroke-width="0.804502"
                        />
                        <rect x="673.555" y="684.513" width="40.2251" height="30.5711" rx="6.03377" fill="white" />
                        <path
                            d="M687.634 695.105V704.491C687.634 705.229 688.237 705.832 688.975 705.832H698.36C699.098 705.832 699.701 705.229 699.701 704.491V695.105C699.701 694.368 699.098 693.765 698.36 693.765H688.975C688.237 693.765 687.634 694.368 687.634 695.105Z"
                            fill="#CBD5E1"
                        />
                        <rect
                            x="673.555"
                            y="684.513"
                            width="40.2251"
                            height="30.5711"
                            rx="6.03377"
                            stroke="#EDF2F7"
                            stroke-width="0.804502"
                        />
                        <rect x="728.261" y="684.513" width="40.2251" height="30.5711" rx="6.03377" fill="white" />
                        <path
                            d="M742.34 695.105V704.491C742.34 705.229 742.943 705.832 743.681 705.832H753.067C753.804 705.832 754.407 705.229 754.407 704.491V695.105C754.407 694.368 753.804 693.765 753.067 693.765H743.681C742.943 693.765 742.34 694.368 742.34 695.105Z"
                            fill="#CBD5E1"
                        />
                        <rect
                            x="728.261"
                            y="684.513"
                            width="40.2251"
                            height="30.5711"
                            rx="6.03377"
                            stroke="#EDF2F7"
                            stroke-width="0.804502"
                        />
                        <path
                            d="M792.219 695.105V704.491C792.219 705.229 792.822 705.832 793.56 705.832H802.945C803.683 705.832 804.286 705.229 804.286 704.491V695.105C804.286 694.368 803.683 693.765 802.945 693.765H793.56C792.822 693.765 792.219 694.368 792.219 695.105Z"
                            fill="#FF1B34"
                        />
                        <rect x="828.018" y="684.513" width="40.2251" height="30.5711" rx="6.03377" fill="white" />
                        <rect
                            x="828.018"
                            y="684.513"
                            width="40.2251"
                            height="30.5711"
                            rx="6.03377"
                            stroke="#EDF2F7"
                            stroke-width="0.804502"
                        />
                    </g>
                </g>
            </g>
            <defs>
                {sessionBackground ? (
                    <pattern
                        id="sessionBackground"
                        patternUnits="objectBoundingBox"
                        preserveAspectRatio="xMidYMid slice"
                        width="100%"
                        height="100%"
                    >
                        <image href={sessionBackground} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" />
                    </pattern>
                ) : null}
                <filter
                    id="filter0_d_618_365288"
                    x="60"
                    y="25.4355"
                    width="1258.48"
                    height="779.128"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="25" />
                    <feGaussianBlur stdDeviation="25" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_365288" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_365288" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_618_365288"
                    x1="-5.79052e-06"
                    y1="99.4118"
                    x2="886.59"
                    y2="263.537"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#394960" />
                    <stop offset="1" stop-color="#334155" />
                </linearGradient>
                <clipPath id="clip0_618_365288">
                    <path d="M0 20C0 8.9543 8.9543 0 20 0H830V780H20C8.95429 780 0 771.046 0 760V20Z" fill="white" />
                </clipPath>
                <clipPath id="clip1_618_365288">
                    <rect x="110" y="50.4355" width="1158.48" height="679.128" rx="16.09" fill="white" />
                </clipPath>
                <clipPath id="clip2_618_365288">
                    <rect x="122.067" y="103.661" width="205.953" height="613.835" rx="9.65403" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WorkspacePreviewSmall;
