import Typography from "@ui/cdk/Typography";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import classes from "./onboarding.module.scss";

interface Props extends WithTooltipChildren {
    hide?: boolean;
}

export function OnboardingParticipantsFooter(props: Props) {
    const title = (
        <>
            <Typography className="title" fontWeight="bolder">
                Invite others to your session
            </Typography>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                You can invite participants via email, or simply copy and share the session link with them.
            </Typography>
        </>
    );

    return (
        <OnboardingTooltip
            tooltipId="participants-footer"
            title={title}
            placement="top"
            arrow={true}
            skip={props.hide}
            classes={{tooltip: classes.tooltipBigger}}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
