import {publicSystemEnv, systemEnv} from "@generated/environment/system";
import {defaultEnvironment} from "./default";
import {Environment} from "./Environment";

let isDevPreview = false;
let location = "";
let devPreviewTag = "";
const devPreviewBaseUrls = ["dev.sessions.us", "sessions.flowos.dev"];
const deploymentName = process.env.REACT_APP_DEPLOYMENT_NAME!;
const eventBaseUrl = process.env.EVENT_BASE_URL;

if (typeof window !== "undefined") {
    isDevPreview = devPreviewBaseUrls.some((devPreviewBaseUrl) => window.location.host.indexOf(devPreviewBaseUrl) >= 0);
    location = window.location.host;
    devPreviewTag = location.replace(`.${devPreviewBaseUrls[0]}`, "").replace(`.${devPreviewBaseUrls[1]}`, "");
} else {
    devPreviewTag = deploymentName;
}

const getEventBaseUrl = () => {
    if (typeof window !== "undefined" && !isDevPreview) {
        const url = new URL(window.location.href);
        const eventBaseUrl = `https://event.${url.hostname}`;
        return eventBaseUrl;
    }

    if (eventBaseUrl) {
        return eventBaseUrl;
    }

    return `https://event.${location}`;
};

console.log("[LOCATION]", {isDevPreview, location, devPreviewTag});

const fallbackDeploymentName = "qa";
const isClient = typeof window !== "undefined";
const originalHost = `${deploymentName ?? fallbackDeploymentName}.sessions.flowos.dev`;
const baseHost = isClient ? location : originalHost;

const baseUrls = {
    originalApp: `https://${originalHost}`,
    api: `https://api.${baseHost}`,
    wsApi: `wss://api.${baseHost}`,
    auth: `https://auth.${baseHost}`,
    app: `https://${baseHost}`,
    event: `https://event.${baseHost}`,
};

type Base = {
    [key in keyof typeof baseUrls]: {to: (path: string) => string; self: string};
};

const base: Base = Object.keys(baseUrls).reduce((acc, key) => {
    const self = baseUrls[key as keyof typeof baseUrls];
    acc[key] = {
        to: (path: string) => `${self}${path}`,
        self,
    };
    return acc;
}, {} as any);

const environment: Environment = {
    ...defaultEnvironment,

    environmentName: devPreviewTag,
    devtoolsEnabled: false,
    // devtoolsEnabled: devPreviewTag !== "staging",
    production: systemEnv.environment == "production",
    allowDebugErrors: true,
    enablePlayground: systemEnv.playground == "true",
    databaseUrl: systemEnv.databaseUrl || "",
    s3PublicBucket: systemEnv.s3PublicBucket || "",
    s3PrivateBucket: systemEnv.s3PrivateBucket || "",
    s3FsUri: systemEnv.s3FsUri || "",

    enableBuildInfo: true,
    enableRecurrence: true,

    clientReadUrl: base.api.to("/graphql"),
    clientWriteUrl: base.api.to("/graphql"),
    clientWsUrl: base.wsApi.to("/graphql"),

    landingPage: publicSystemEnv.landingPage,

    redis: {
        host: systemEnv.redisHost,
        port: parseInt(systemEnv.redisPort),
    },

    logstashHost: systemEnv.logstashHost,
    logstashPort: systemEnv.logstashPort,

    notificationServiceUrl: systemEnv.notificationServiceUrl,
    notificationAppId: publicSystemEnv.notificationAppId,

    centrifugalUrl: publicSystemEnv.centrifugalUrl,
    centrifugalApiKey: systemEnv.centrifugalApiKey,

    chimeApiKey: systemEnv.chimeApiKey,
    chimeApiSecret: systemEnv.chimeApiSecret,

    mailServiceUrl: systemEnv.mailServiceUrl,
    defaultMailProvider: systemEnv.defaultMailProvider,
    disableEmailSending: false,

    forceDevPlayerAccess: "false",
    disableStartupTimer: "false",

    google: {
        clientId: publicSystemEnv.googleClientId,
        clientSecret: systemEnv.googleClientSecret,
    },

    disableConferencing: false,

    drawingServiceUrl: publicSystemEnv.drawingServiceUrl,

    gotenbergUrl: systemEnv.gotenbergUrl,

    gaMeasurementId: publicSystemEnv.gaMeasurementId,
    innerTrendsId: publicSystemEnv.innrId,
    mixpanelToken: publicSystemEnv.mixpanelToken,
    googleTagManagerId: publicSystemEnv.googleTagManagerId,
    staticServiceUrl: publicSystemEnv.staticServiceUrl,

    authService: {
        root: base.auth.self,
        appRoot: base.app.self,
        originalAppRoot: base.originalApp.self,
        cookieName: `${deploymentName}-auth.token`,
        apiSecretKey: systemEnv.authServiceApiSecretKey,
        jwtSigningSecret: systemEnv.authServiceJwtSigningSecret,
        enableRegistration: systemEnv.authServiceEnableRegistration,
        verifyEmail: systemEnv.authServiceVerifyEmail,
    },

    hubspotAccessToken: systemEnv.hubspotAccessToken,

    elasticCloud: {
        id: systemEnv.elasticCloudId,
        secret: systemEnv.elasticCloudSecret,
    },

    analyticsIndexName: systemEnv.analyticsIndexName,
    sessionAnalyticsIndexName: publicSystemEnv.sessionAnalyticsIndexName,

    jwtSecret: systemEnv.jwtSecret,

    hubspotPortalId: publicSystemEnv.hubspotPortalId,

    hubspotFormId: publicSystemEnv.hubspotFormId,
    productboardApiToken: systemEnv.productboardApiToken,

    publicResourcesCdnRoot: publicSystemEnv.resourcesCdnRoot,

    slack: {
        userJoinedUrl: systemEnv.slackUserJoinedUrl,
        appName: systemEnv.slackAppName,
        appIcon: systemEnv.slackAppIcon,
        workspace: publicSystemEnv.slackWorkspace,
    },

    giphy: {
        apiSecret: systemEnv.giphyApiSecret,
    },

    maximumUserConcurrentVideos: systemEnv.maximumUserConcurrentVideos,

    miroClientId: publicSystemEnv.miroClientId,
    miroClientSecret: systemEnv.miroClientSecret,

    applicationStateWriteSecret: systemEnv.applicationStateWriteSecret,
    disableAppRemoteState: false,
    disableHelpIndicator: true,

    helpCenterArticleBase: "https://resources.sessions.us/resources",
    resourcesBase: "https://resources.sessions.us",

    canvaExtensionClientSecret: systemEnv.canvaExtensionClientSecret,
    twitchClientId: "nr081x9bh36p8rfgejac734eyayvhl",
    twitchClientSecret: "7f4g31z5pqh281wnyodrysnoh0b8bc",

    sessionsFlowosMail: "sessions-qa@invitations.flowos.com",

    csd: {
        disable: true,
        root: "dev.sessions.us",
        namespace: "workhorse-qa-frontend-service-9rhqmasy",
        serviceName: "workhorse-qa-frontend-service-xfqxrfkx",
        secretName: "ssl-custom-sessions-us",
        realDomain: "qa.dev.sessions.us",
    },

    youtubeApiKey: "AIzaSyDJrW6vNqrQo0-uQe_vScnOtYX15WJ61jY",

    microsoft: {
        clientId: publicSystemEnv.microsoftClientId,
        clientSecret: systemEnv.microsoftClientSecret,
        authority: publicSystemEnv.microsoftAuthority,
    },

    unsplashApiKey: systemEnv.unsplashApiKey,

    convertApiSecret: systemEnv.convertApiSecret,

    uipathEventsServiceUrl: systemEnv.uipathEventsServiceUrl,
    uipathEventsServiceSecret: systemEnv.uipathEventsServiceSecret,

    recordingServer: {
        host: "https://recording-service.sessions.flowos.dev",
        bucketName: "recording-store-dev-private-bucket-7070706",
        publicRootUrl: "https://storage.googleapis.com/recording-store-dev-private-bucket-7070706",
    },

    searching: {
        indexSuffix: deploymentName,
    },

    evernote: {
        consumerKey: systemEnv.evernoteConsumerKey,
        consumerSecret: systemEnv.evernoteConsumerSecret,
    },

    notion: {
        clientId: publicSystemEnv.notionClientId,
        clientSecret: systemEnv.notionClientSecret,
    },

    pspdfkit: {
        privateKey: systemEnv.pspdfkitPrivateKey,
        serverUrl: publicSystemEnv.pspdfkitServerUrl,
        apiKey: systemEnv.pspdfkitApiKey,
    },

    deleteContactsWorkerDelayInMs: Number.parseInt(systemEnv.deleteContactsWorkerDelayInMs),
    deleteContactsWorkerDaysLimit: Number.parseInt(systemEnv.deleteContactsWorkerDaysLimit),

    googleContactsSyncWorkerDelayInMs: Number.parseInt(systemEnv.googleContactsSyncWorkerDelayInMs),
    microsoftContactsSyncWorkerDelayInMs: Number.parseInt(systemEnv.microsoftContactsSyncWorkerDelayInMs),

    uipathTemplateTagId: publicSystemEnv.uipathTemplateTagId,
    uipathMasterEmail: publicSystemEnv.uipathMasterEmail,
    uipathEmailDomain: systemEnv.uipathEmailDomain,

    createOrganizationAdminWorkerDelayInMs: Number.parseInt(systemEnv.createOrganizationAdminWorkerDelayInMs),

    presence: {
        host: publicSystemEnv.presenceHost,
        channel: "prsvc",
    },

    myProductToolSocketHost: publicSystemEnv.myProductToolSocketHost,
    eventBaseURL: getEventBaseUrl(),

    googleDriveWatchChannelExpirationInDays: Number.parseInt(systemEnv.googleDriveWatchChannelExpirationInDays),
    googleDriveWatchChannelUpdateDelayInMs: Number.parseInt(systemEnv.googleDriveWatchChannelUpdateDelayInMs),

    googleProjectId: systemEnv.googleProjectId,
    transcoderLocation: systemEnv.transcoderLocation,
    transcoderVideoTopicName: `projects/${systemEnv.googleProjectId}/topics/transcoder-${deploymentName}-video-status`,
    transcoderRecordingTopicName: `projects/${systemEnv.googleProjectId}/topics/transcoder-${deploymentName}-recording-status`,

    integrationServiceUrl: systemEnv.integrationServiceUrl,

    chimeWebhookSecret: "qXgFGaQZffmUFl33MRuf!4E4TbAQOF8VJYAQKiS0pmcVG72/rkReHAJxrBFwpl1o",
    openaiApiKey: systemEnv.openaiApiKey,
    openaiApiKey2: systemEnv.openaiApiKey2,
    editorHocuspocusWs: publicSystemEnv.editorHocuspocusWs,
    whiteboardSyncServerUrl: publicSystemEnv.whiteboardSyncServerUrl,
    liveKitURL: publicSystemEnv.liveKitURL,
    paddleVendorId: publicSystemEnv.paddleVendorId,
    paddleSandbox: true,
    celloProductId: publicSystemEnv.celloProductId,
    desktopAppDownloadURL: "https://desktop-app.sessions.us/download/channel/stable",
    enableLogrocket: publicSystemEnv.enableLogrocket === "true",
    logrocketProject: publicSystemEnv.logrocketProject,
    sessionsReleaseVer: publicSystemEnv.sessionsReleaseVer,
    stripeAppUrl: publicSystemEnv.stripeAppUrl,
    doptBlocksApiKey: publicSystemEnv.doptBlocksApiKey,
    demoSessionId: publicSystemEnv.demoSessionId,
    paypalAppUrl: publicSystemEnv.paypalAppUrl,
};

export default environment;
