import {ResourcesTypes} from "@generated/artifacts/resources";
import {GetResourceCountDocument, GetResourceCountQueryVariables, ResourcesCount, GetRemoteUserDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";

export type ResourceCountKey = keyof ResourcesCount;

const filesKeys = [
    "includePpt",
    "includeDoc",
    "includeExcel",
    "includePdf",
    "includeKeynote",
    "includePages",
    "includeNumbers",
    "includeVideo",
    "includeGallery",
];

const toolsKeys = [
    "includeAirtable",
    "includeAdobexd",
    "includeBlackboard",
    "includeCanva",
    "includeFigma",
    "includeForm",
    "includeMentimeter",
    "includePitch",
    "includeSlido",
    "includeTwitch",
    "includeVimeo",
    "includeYoutube",
    "includeBoardmix",
];

export const emptyResxCountVariables = {
    ...Object.fromEntries(filesKeys.map((key) => [key, false])),
    ...Object.fromEntries(toolsKeys.map((key) => [key, false])),
    workspaceId: undefined,
} as GetResourceCountQueryVariables;

export const filesQueryVariables = {
    ...Object.fromEntries(filesKeys.map((key) => [key, true])),
    ...Object.fromEntries(toolsKeys.map((key) => [key, false])),
    workspaceId: undefined,
} as GetResourceCountQueryVariables;

export const toolsQueryVariables = {
    ...Object.fromEntries(filesKeys.map((key) => [key, false])),
    ...Object.fromEntries(toolsKeys.map((key) => [key, true])),
    workspaceId: undefined,
} as GetResourceCountQueryVariables;

export function modifyResourceCounter(resourceType: ResourcesTypes, mode: "add" | "subtract", isFiles?: boolean) {
    const user = apollo.client.readQuery({
        query: GetRemoteUserDocument,
    });
    const workspaceId = user?.getRemoteUser.user?.workspacePermissions.find((wp) => wp.activeWorkspace)?.workspace.id;
    //key should match 'artifactnameCount', where artifactname is artifact.meta.name
    const res = resourceType.split("/")[3].toLowerCase();
    const key = (res + "Count") as ResourceCountKey;

    const data = apollo.client.readQuery({
        query: GetResourceCountDocument,
        variables: isFiles ? {...filesQueryVariables, workspaceId} : {...toolsQueryVariables, workspaceId},
    });

    const value = data ? data.getResourcesCount[key] : undefined;

    if (!data || value === undefined || value === null || typeof value !== "number") {
        return;
    } else {
        apollo.cache.writeQuery({
            query: GetResourceCountDocument,
            variables: isFiles ? {...filesQueryVariables, workspaceId} : {...toolsQueryVariables, workspaceId},
            data: {
                getResourcesCount: {
                    ...data.getResourcesCount,
                    [key]: mode === "add" ? value + 1 : value - 1,
                },
            },
        });
    }
}
