import {MemberFragment, Participant, SessionParticipantFragment} from "@generated/data";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import DialogContent from "@ui/core/components/DialogContent";
import DialogTitle from "@ui/core/components/DialogTitle";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useRef, useState} from "@workhorse/api/rendering";
import {getCorrectPlural} from "@workhorse/util/strings";
import {makeParticipantAssistantData} from "../event/event-settings/utils";
import {makeParticipantSpeakerData, ParticipantWithSpeakerDetailsPayload} from "../event/event-speakers/utils";
import classes from "./event-collaborators-dialog.module.scss";
import EventCollaboratorsTable from "./event-collaborators-table";

type EventCollaboratorsDialogProps = {
    collaborators: SessionParticipantFragment[];
    setCollaborators: (collaborators: SessionParticipantFragment[]) => void;
    onClose: () => void;
    organizationName: string | undefined | null;
    onAddEventCollaborators?: (localCollaborators: SessionParticipantFragment[]) => void;
    onRemoveEventCollaborator?: (email: string) => void;
};

function EventCollaboratorsDialog(props: EventCollaboratorsDialogProps) {
    const {collaborators, onClose, setCollaborators, organizationName, onAddEventCollaborators, onRemoveEventCollaborator} = props;

    const [localCollaborators, setLocalCollaborators] = useState<SessionParticipantFragment[]>(collaborators);

    const dialogRef = useRef<DialogImperativeRef>();

    const handleSubmit = () => {
        setCollaborators(localCollaborators);
        onAddEventCollaborators?.(localCollaborators);
        onClose();
    };

    const handleAddMember = (member: MemberFragment) => {
        const newParticipantData = makeParticipantAssistantData(
            member.email,
            false,
            false,
            {
                session: {
                    isAssistant: true,
                },
            },
            undefined,
            member.firstName,
            member.lastName
        ) as unknown as SessionParticipantFragment;

        setLocalCollaborators((prev) => [...prev, newParticipantData]);
    };

    const handleRemoveCollaborator = (id: string) => {
        const collaboratorToRemove = localCollaborators.find((c) => c.id === id);
        onRemoveEventCollaborator?.(collaboratorToRemove?.dataWithNullableEmail.email ?? "");
        setLocalCollaborators((prev) => prev.filter((c) => c.id !== id));
    };

    return (
        <Dialog open imperativeRef={dialogRef} classes={{paper: classes.root}} onClose={onClose}>
            <DialogTitle className={cls("flex flex-align-center flex-justify-between", classes.header)}>
                <div className="flex flex-items-center mb-6">
                    <Typography color="primary" variant="xl" fontWeight="bolder">
                        Event collaborators
                    </Typography>
                    <Typography variant="sm" color="secondary" fontWeight="bold" className={classes.count}>
                        {localCollaborators.length} {getCorrectPlural("collaborator", localCollaborators.length)}
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent className={cls("flex flex-col", classes.content)}>
                <EventCollaboratorsTable
                    collaborators={localCollaborators}
                    onAddMember={handleAddMember}
                    onRemoveCollaborator={handleRemoveCollaborator}
                    organizationName={organizationName}
                />
            </DialogContent>

            <DialogFooter className={classes.footer}>
                <Button variant="plain" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirm
                </Button>
            </DialogFooter>
        </Dialog>
    );
}

export default EventCollaboratorsDialog;
