import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Remove from "@material-ui/icons/Remove";
import Button from "@ui/cdk/Button";
import Checkbox from "@ui/cdk/Checkbox";
import Typography from "@ui/cdk/Typography";
import {getCorrectPlural} from "@workhorse/util/strings";
import classes from "./style/EventSpeakersTableActionHead.module.scss";

export type EventSpeakersTableActionHeadProps = {
    toggleSelectAll: (set: boolean) => void;
    allSelected: boolean;
    selected: string[];
    onToggleDeleteDialog: (ids: string[]) => void;
};

const EventSpeakersTableActionHead = (props: EventSpeakersTableActionHeadProps) => {
    const {toggleSelectAll, allSelected, onToggleDeleteDialog, selected} = props;

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleSelectAll(e.target.checked);
    };

    const handleDelete = () => {
        onToggleDeleteDialog(selected);
    };

    const speakersCorrectPlural = getCorrectPlural("speaker", selected.length);

    return (
        <div key={"event-speakers-table-action-head"} className={classes.root}>
            <Checkbox
                data-id="select-all"
                checked={!!selected.length}
                onChange={handleCheckboxChange}
                checkedIcon={
                    <div className={classes.removeIcon}>
                        <Remove />
                    </div>
                }
            />

            <Button
                data-id="delete-speakers"
                variant="destructive-quaternary"
                size="small"
                className={classes.removeBtn}
                disabled={!selected.length}
                onClick={handleDelete}
                noFocusBorder
            >
                <DeleteRoundedIcon className="mr-5" />
                Remove {speakersCorrectPlural} {selected.length !== selected.length ? `(${selected.length})` : ""}
            </Button>

            <Typography color="quaternary" fontWeight="bold" className={classes.counter}>
                {selected.length} {speakersCorrectPlural} selected
            </Typography>
        </div>
    );
};

export default EventSpeakersTableActionHead;
