import {limitationsByFeatures, OrganizationPlan} from ".";

export enum LimitedFeature {
    RECORDING = "RECORDING",
    LIVESTREAM = "LIVESTREAM",
    COPILOT = "COPILOT",
    ORGANIZATION_MEMBERS = "ORGANIZATION_MEMBERS",
    EVENTS = "EVENTS",
    SESSION_LENGTH = "SESSION_LENGTH",
    BRANDING = "BRANDING",
    PARTICIPANTS_IN_SESSION = "PARTICIPANTS_IN_SESSION",
    MEMORY = "MEMORY",
    COBROWSING = "COBROWSING",
    WEBHOOKS = "WEBHOOKS",
    BOOKING = "BOOKING",
    PUBLIC_API = "PUBLIC_API",
    ROOMS = "ROOMS",
    MY_PRODUCT_TOOL = "MY_PRODUCT_TOOL",
    TRANSCRIPT = "TRANSCRIPT",
    CNAME = "CNAME",
    CUSTOM_SMTP_SERVER = "CUSTOM_SMTP_SERVER",
    CUSTOM_MESSAGE_EXTERNAL_PROVIDER = "CUSTOM_MESSAGE_EXTERNAL_PROVIDER",
    HUBSPOT = "HUBSPOT",
    PABLY = "PABLY",
    ZAPIER = "ZAPIER",
    CUSTOM_EMAIL = "CUSTOM_EMAIL",
    WHITE_LABELING = "WHITE_LABELING",
    WORKSPACES = "WORKSPACES",
    EXIT_PAGE_OFFER = "EXIT_PAGE_OFFER",
    EXIT_PAGE_FEEDBACK = "EXIT_PAGE_FEEDBACK",
    AFTER_REGISTRATION_ACTION = "AFTER_REGISTRATION_ACTION",
    OFFER_WIDGET = "OFFER_WIDGET",
    STRIPE = "STRIPE",
    PAYPAL = "PAYPAL",
    PAYMENTS = "PAYMENTS",
}

export const getNextAvailablePlan = (plan: OrganizationPlan, limitedFeature?: LimitedFeature | null) => {
    switch (plan) {
        case OrganizationPlan.Free:
            return getFirstAvailablePlan(OrganizationPlan.Solo, limitedFeature);
        case OrganizationPlan.Solo:
            return getFirstAvailablePlan(OrganizationPlan.Starter, limitedFeature);
        case OrganizationPlan.Pro:
            return getFirstAvailablePlan(OrganizationPlan.Business, limitedFeature);
        case OrganizationPlan.Business:
            return OrganizationPlan.Business;
        case OrganizationPlan.AppsumoTier_1:
            return getFirstAvailablePlan(OrganizationPlan.AppsumoTier_2, limitedFeature);
        case OrganizationPlan.AppsumoTier_2:
            return getFirstAvailablePlan(OrganizationPlan.AppsumoTier_3, limitedFeature);
        case OrganizationPlan.AppsumoTier_3:
            return getFirstAvailablePlan(OrganizationPlan.AppsumoTier_4, limitedFeature);
        case OrganizationPlan.AppsumoTier_4:
            return getFirstAvailablePlan(OrganizationPlan.AppsumoTier_5, limitedFeature);
        case OrganizationPlan.AppsumoTier_5:
            return getFirstAvailablePlan(OrganizationPlan.Business, limitedFeature);
        case OrganizationPlan.ProLtd:
            return getFirstAvailablePlan(OrganizationPlan.Business, limitedFeature);
        case OrganizationPlan.ProLtdSaaszilla:
            return getFirstAvailablePlan(OrganizationPlan.Business, limitedFeature);
        default:
            return getFirstAvailablePlan(OrganizationPlan.Solo, limitedFeature);
    }
};

export const getFirstAvailablePlan = (suggestedPlan: OrganizationPlan, feature?: LimitedFeature | null) => {
    if (!feature || (typeof limitationsByFeatures(feature)[suggestedPlan] === "boolean" && limitationsByFeatures(feature)[suggestedPlan])) {
        return suggestedPlan;
    }

    const allPlans = [OrganizationPlan.Solo, OrganizationPlan.Starter, OrganizationPlan.Pro, OrganizationPlan.Business];

    for (const plan of allPlans) {
        if (typeof limitationsByFeatures(feature)[plan] === "number" && typeof limitationsByFeatures(feature)[suggestedPlan] === "number") {
            if (limitationsByFeatures(feature)[plan] > limitationsByFeatures(feature)[suggestedPlan]) {
                return plan;
            }
        } else if (limitationsByFeatures(feature)[plan]) {
            return plan;
        }
    }
    return suggestedPlan;
};

// export const getPreviousPlan = (plan: OrganizationPlan) => {
//     switch (plan) {
//         case OrganizationPlan.Free:
//             return OrganizationPlan.Free;
//         case OrganizationPlan.Pro:
//             return OrganizationPlan.Free;
//         case OrganizationPlan.Business:
//             return OrganizationPlan.Pro;
//         case OrganizationPlan.AppsumoTier_1:
//             return OrganizationPlan.Free;
//         case OrganizationPlan.AppsumoTier_2:
//             return OrganizationPlan.AppsumoTier_1;
//         case OrganizationPlan.AppsumoTier_3:
//             return OrganizationPlan.AppsumoTier_2;
//         case OrganizationPlan.AppsumoTier_4:
//             return OrganizationPlan.AppsumoTier_3;
//         case OrganizationPlan.AppsumoTier_5:
//             return OrganizationPlan.AppsumoTier_4;
//         case OrganizationPlan.Enterprise:
//             return OrganizationPlan.Business;
//         case OrganizationPlan.ProLtd:
//             return OrganizationPlan.Pro;
//         case OrganizationPlan.ProLtdSaaszilla:
//             return OrganizationPlan.Pro;
//         default:
//             return OrganizationPlan.Pro;
//     }
// };

export const isFeatureSuggestedInNextPlan = (currentPlan: OrganizationPlan, feature: LimitedFeature) => {
    if ([LimitedFeature.PARTICIPANTS_IN_SESSION, LimitedFeature.SESSION_LENGTH, LimitedFeature.ORGANIZATION_MEMBERS].includes(feature)) {
        return true;
    }
    return limitationsByFeatures(feature)[currentPlan] !== limitationsByFeatures(feature)[getNextAvailablePlan(currentPlan)];
};
