import MuiTabs from "@material-ui/core/Tabs";
import {ExtractProps} from "@workhorse/declarations";
import {makeStyles} from "@material-ui/core";
import {cls} from "../util";

const useStyles = makeStyles((theme) => ({
    indicatorPrimary: {
        backgroundColor: theme.main.palette.primary[500],
    },
    textPrimary: {
        color: theme.main.palette.primary[500],
    },
}));

export default function Tabs(props: ExtractProps<typeof MuiTabs>) {
    const classes = useStyles();
    return (
        <MuiTabs
            {...props}
            TabIndicatorProps={{
                className: cls(
                    props.indicatorColor === "primary" ? classes.indicatorPrimary : props.TabIndicatorProps?.className,
                    props.textColor === "primary" ? classes.textPrimary : props.TabIndicatorProps?.className
                ),
            }}
        />
    );
}
