import {useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useUser} from "@workhorse/components/user";

const useUserNotesStore = () => {
    const {notesFlags} = useUser();

    return useMemo(() => ({notesFlags}), [notesFlags]);
};

export const [UserNotesProvider, useUserNotes] = createContextProvider(
    {
        name: "UserNotes",
    },
    useUserNotesStore
);
