export type ReleaseNote = {
    version: number;
    component: JSX.Element | null;
};

const releaseList = {
    2009000: import("./releaseNotes/sessions_290"),
    2008900: import("./releaseNotes/sessions_289"),
    2008800: import("./releaseNotes/sessions_288"),
    2008700: import("./releaseNotes/sessions_287"),
    2008600: import("./releaseNotes/sessions_286"),
    2008500: import("./releaseNotes/sessions_285"),
    2008400: import("./releaseNotes/sessions_284"),
    2008300: import("./releaseNotes/sessions_283"),
    2008200: import("./releaseNotes/sessions_282"),
    2008100: import("./releaseNotes/sessions_281"),
    2008000: import("./releaseNotes/sessions_280"),
    2007900: import("./releaseNotes/sessions_279"),
    2007800: import("./releaseNotes/sessions_278"),
    2007700: import("./releaseNotes/sessions_277"),
    2007600: import("./releaseNotes/sessions_276"),
    2007500: import("./releaseNotes/sessions_275"),
    2007400: import("./releaseNotes/sessions_274"),
    2007300: import("./releaseNotes/sessions_273"),
    2007200: import("./releaseNotes/sessions_272"),
    2007100: import("./releaseNotes/sessions_271"),
    2007000: import("./releaseNotes/sessions_270"),
    2006900: import("./releaseNotes/sessions_269"),
    2006800: import("./releaseNotes/sessions_268"),
    2006700: import("./releaseNotes/sessions_267"),
    2006600: import("./releaseNotes/sessions_266"),
    2006500: import("./releaseNotes/sessions_265"),
    2006400: import("./releaseNotes/sessions_264"),
    2006300: import("./releaseNotes/sessions_263"),
    2006200: import("./releaseNotes/sessions_262"),
    2006100: import("./releaseNotes/sessions_261"),
    2006000: import("./releaseNotes/sessions_260"),
    2005900: import("./releaseNotes/sessions_259"),
    2005800: import("./releaseNotes/sessions_258"),
    2005700: import("./releaseNotes/sessions_257"),
    2005600: import("./releaseNotes/sessions_256"),
    2005500: import("./releaseNotes/sessions_255"),
    2005400: import("./releaseNotes/sessions_254"),
    2005300: import("./releaseNotes/sessions_253"),
    2005200: import("./releaseNotes/sessions_252"),
    2005100: import("./releaseNotes/sessions_251"),
    2005000: import("./releaseNotes/sessions_250"),
    2004900: import("./releaseNotes/sessions_249"),
    2004800: import("./releaseNotes/sessions_248"),
    2004700: import("./releaseNotes/sessions_247"),
    2004600: import("./releaseNotes/sessions_246"),
    2004500: import("./releaseNotes/sessions_245"),
    2004400: import("./releaseNotes/sessions_244"),
    2004300: import("./releaseNotes/sessions_243"),
    2004200: import("./releaseNotes/sessions_242"),
    2004100: import("./releaseNotes/sessions_241"),
    2004000: import("./releaseNotes/sessions_240"),
    2003900: import("./releaseNotes/sessions_239"),
    2003800: import("./releaseNotes/sessions_238"),
    2003700: import("./releaseNotes/sessions_237"),
    2003600: import("./releaseNotes/sessions_236"),
    2003500: import("./releaseNotes/sessions_235"),
    2003400: import("./releaseNotes/sessions_234"),
    2003300: import("./releaseNotes/sessions_233"),
    2003200: import("./releaseNotes/sessions_232"),
    2003100: import("./releaseNotes/sessions_231"),
    2003000: import("./releaseNotes/sessions_230"),
    2002900: import("./releaseNotes/sessions_229"),
    2002800: import("./releaseNotes/sessions_228"),
    2002700: import("./releaseNotes/sessions_227"),
    2002600: import("./releaseNotes/sessions_226"),
    2002500: import("./releaseNotes/sessions_225"),
    2002400: import("./releaseNotes/sessions_224"),
    2002300: import("./releaseNotes/sessions_223"),
    2002200: import("./releaseNotes/sessions_222"),
    2002100: import("./releaseNotes/sessions_221"),
    2002000: import("./releaseNotes/sessions_220"),
    2001900: import("./releaseNotes/sessions_219"),
    2001800: import("./releaseNotes/sessions_218"),
    2001700: import("./releaseNotes/sessions_217"),
    2001600: import("./releaseNotes/sessions_216"),
    2001500: import("./releaseNotes/sessions_215"),
    2001400: import("./releaseNotes/sessions_214"),
    2001300: import("./releaseNotes/sessions_213"),
    2001200: import("./releaseNotes/sessions_212"),
    2001100: import("./releaseNotes/sessions_211"),
    2001000: import("./releaseNotes/sessions_210"),
    2000900: import("./releaseNotes/sessions_209"),
    2000800: import("./releaseNotes/sessions_208"),
    2000700: import("./releaseNotes/sessions_207"),
    2000600: import("./releaseNotes/sessions_206"),
    2000500: import("./releaseNotes/sessions_205"),
    2000400: import("./releaseNotes/sessions_204"),
    2000300: import("./releaseNotes/sessions_203"),
    2000200: import("./releaseNotes/sessions_202"),
    2000100: import("./releaseNotes/sessions_201"),
    2000000: import("./releaseNotes/sessions_200"),
    1900900: import("./releaseNotes/sessions_199"),
    1900800: import("./releaseNotes/sessions_198"),
    1900700: import("./releaseNotes/sessions_197"),
    1900600: import("./releaseNotes/sessions_196"),
    1900500: import("./releaseNotes/sessions_195"),
    1900400: import("./releaseNotes/sessions_194"),
    1900300: import("./releaseNotes/sessions_193"),
    1900200: import("./releaseNotes/sessions_192"),
    1900100: import("./releaseNotes/sessions_191"),
    1900000: import("./releaseNotes/sessions_19"),
    1800400: import("./releaseNotes/sessions_184"),
    1800300: import("./releaseNotes/sessions_183"),
    1800200: import("./releaseNotes/sessions_182"),
    1800000: import("./releaseNotes/sessions_18"),
    1600000: import("./releaseNotes/sessions_16"),
    1500100: import("./releaseNotes/sessions_151"),
    1400500: import("./releaseNotes/sessions_145"),
    1400400: import("./releaseNotes/sessions_144"),
    1400300: import("./releaseNotes/sessions_143"),
    1400200: import("./releaseNotes/sessions_142"),
    1400100: import("./releaseNotes/sessions_141"),
    1400000: import("./releaseNotes/sessions_14"),
};

const showReleaseNotes = async (notes: {[key: number]: Promise<{default: ReleaseNote}>}, user: {lastVersion: number}) => {
    let _notes: ReleaseNote[] = [];

    for (let noteKey in notes) {
        // if (parseInt(noteKey) > user.lastVersion) {
        _notes.push((await notes[noteKey]).default);
        // }
    }

    return _notes.reverse();
};

const getReleaseNotes = async (lastVersionSeen: number) => await showReleaseNotes(releaseList, {lastVersion: lastVersionSeen});

export const getLatestVersionNumber = () => Math.max(...Object.keys(releaseList).map((x) => parseInt(x)));

export default getReleaseNotes;
