import {useMutation} from "@apollo/client";
import {DeleteAgendaTemplateDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {useCallback, useState} from "@workhorse/api/rendering";
import {useHistory, useParams} from "@workhorse/api/routing";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import UsageHistory from "@workhorse/components/usage-history/UsageHistory";
import {TemplatesParams} from "../../utils";
import AgendaTemplatesEditorActionsMenu from "./components/AgendaTemplatesEditorActionsMenu";

type AgendaTemplatesEditorActionsProps = {
    canDeleteAgenda: boolean;
    currentTemplateId: string;
    currentTemplateName: string;
};

function AgendaTemplatesEditorActions(props: AgendaTemplatesEditorActionsProps) {
    const {canDeleteAgenda, currentTemplateId, currentTemplateName} = props;

    const [openDeleteTemplate, setOpenDeleteTemplate] = useState<boolean>(false);
    const [usageHistoryOpen, setUsageHistoryOpen] = useState(false);

    const {section, templateId} = useParams<TemplatesParams>();

    const [doDelete] = useMutation(DeleteAgendaTemplateDocument);

    const history = useHistory();

    const toggleDeleteTemplate = useCallback(() => {
        setOpenDeleteTemplate((prev) => !prev);
    }, []);
    const toggleUsageHistory = useCallback(() => {
        setUsageHistoryOpen((prev) => !prev);
    }, []);

    const deleteTemplate = useCallback(async () => {
        const deletingId = templateId;
        if (!deletingId) {
            return;
        }
        designer.state.initializeOrResetState(null);
        designer.clearPersistentStorage();
        designer.clearActionsHistory();

        await doDelete({
            variables: {
                where: {
                    id: deletingId,
                },
            },
        });

        history.push("/templates/" + section);

        toggleDeleteTemplate();
    }, [templateId, doDelete, history, section, toggleDeleteTemplate]);

    const confirmDelete = useCallback(async () => {
        await deleteTemplate();
    }, [deleteTemplate]);

    return (
        <>
            <AgendaTemplatesEditorActionsMenu
                canDeleteAgenda={canDeleteAgenda}
                toggleUsageHistory={toggleUsageHistory}
                toggleDeleteTemplate={toggleDeleteTemplate}
            />
            <ConfirmationDialog
                key="delete-template-dialog"
                title="Delete agenda"
                cancelButton="Cancel"
                content="If you delete now, your changes to this agenda will be lost."
                minWidth
                submitButton="Delete"
                onClose={toggleDeleteTemplate}
                open={openDeleteTemplate}
                submitButtonVariant="destructive-secondary"
                onConfirm={confirmDelete}
                variant="warning"
                isDeleteConfirmation
            />
            {usageHistoryOpen ? (
                <UsageHistory
                    open={usageHistoryOpen}
                    onClose={toggleUsageHistory}
                    agendaTemplateId={currentTemplateId}
                    itemTitle={currentTemplateName}
                />
            ) : null}
        </>
    );
}

export default AgendaTemplatesEditorActions;
