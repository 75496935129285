import {createContextProvider} from "@workhorse/api/utils/context";
import {LocalAudioTrack, Room, Track} from "livekit-client";
import {useMemo} from "react";
import {useLocalTrackManager} from "./ConferenceRoomLocalTrack";
import {createRoomLogger} from "./ConferenceRoomLogger";
import {useConferenceRoom} from "./ConferenceRoomProvider";

const logger = createRoomLogger("AUDIO-PUBLISHER");

function getMediaStreamAudioTrack(stream: MediaStream): MediaStreamTrack | null {
    return stream.getAudioTracks()[0] ?? null;
}

function createLocalAudioTrack(stream: MediaStream): LocalAudioTrack | null {
    const streamTrack = getMediaStreamAudioTrack(stream);

    if (!streamTrack) {
        return null;
    }

    const track = new LocalAudioTrack(
        streamTrack,
        {
            deviceId: streamTrack.getSettings().deviceId,
        },
        true
    );

    track.mediaStream = stream;
    track.source = Track.Source.Microphone;

    return track;
}

function publishToRoom(room: Room, stream: MediaStream, track: LocalAudioTrack) {
    return room.localParticipant.publishTrack(track, {
        source: Track.Source.Microphone,
        stream: room.localParticipant.identity,
    });
}

function useLocalAudioPublisherStore() {
    const {room} = useConferenceRoom();

    const {setEnabled, publishOrReplaceTrack, unpublishTrack} = useLocalTrackManager(
        room,
        Track.Source.Microphone,
        logger,
        publishToRoom,
        createLocalAudioTrack,
        getMediaStreamAudioTrack,
        true
    );

    return useMemo(
        () => ({
            setMicrophoneEnabled: setEnabled,
            startPublishingAudio: publishOrReplaceTrack,
            stopPublishingAudio: unpublishTrack,
        }),
        [setEnabled, publishOrReplaceTrack, unpublishTrack]
    );
}

export const [ConferenceRoomLocalAudioProvider, useLocalAudioPublisher] = createContextProvider(
    {
        name: "ConferenceRoomLocalAudioProvider",
    },
    useLocalAudioPublisherStore
);
