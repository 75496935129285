import {useHistory, useParams} from "@workhorse/api/routing";
import {useQuery} from "@workhorse/dataApi";
import {createContextProvider} from "@workhorse/api/utils/context";
import {WithChildren} from "@workhorse/declarations";
import apollo from "@workhorse/api/apollo";
import {AgendaTemplate, GetAgendaTemplatesDocument, UserUpdatesDocument} from "@generated/data";
import {useSubscription} from "@workhorse/api/data";
import {TemplatesParams} from "./utils";
import {readRemoteUser} from "@workhorse/api/user";
import {activeWorkspacePermissions} from "@workhorse/providers/User/utils";
import {useUserInfo} from "@workhorse/providers/User";

function useCurrentTemplateStore() {
    const params = useParams<{templateId: string}>();

    const templateId = params.templateId?.replace("?ai-copilot=true", "");

    const {data} = useQuery("AgendaTemplateDocument", {
        variables: {
            where: {
                id: templateId,
            },
        },
        skip: !templateId,
        fetchPolicy: "cache-first",
        nextFetchPolicy: "cache-only",
    });

    if (!data?.agendaTemplate) {
        return {}! as unknown as AgendaTemplate;
    }

    return data.agendaTemplate! as AgendaTemplate;
}

const [CurrentTemplateProvider, useCurrentTemplate] = createContextProvider(
    {
        name: "CurrentTemplateProvider",
        strict: true,
    },
    useCurrentTemplateStore
);

export {useCurrentTemplate};

const UserSubscriptionForTemplate = () => {
    const user = useUserInfo();

    useSubscription(UserUpdatesDocument, {
        onSubscriptionData: (data) => {
            const existing = apollo.cache.readQuery({
                query: GetAgendaTemplatesDocument,
                variables: {
                    workspaceId: user.activeWorkspace.id,
                },
            });

            const incomingIds = data.subscriptionData.data?.userUpdates?.agendaTemplates?.map((i) => i.id);

            const existingPrivateTemplates = existing?.agendaTemplates?.filter(
                (i) => i?.user.id === user.id && !incomingIds?.includes(i.id)
            );

            apollo.cache.writeQuery({
                query: GetAgendaTemplatesDocument,
                variables: {
                    workspaceId: user.activeWorkspace.id,
                },
                data: {
                    ...existing,
                    agendaTemplates: (existingPrivateTemplates ?? [])?.concat(
                        data.subscriptionData.data?.userUpdates?.agendaTemplates ?? []
                    ),
                },
            });
        },
    });

    return null;
};

export default function TemplateProviders(props: WithChildren) {
    return (
        <>
            <UserSubscriptionForTemplate />
            <CurrentTemplateProvider>{props.children}</CurrentTemplateProvider>
        </>
    );
}
