import {SpeakerDetailsFragment} from "@generated/data";
import Checkbox from "@ui/cdk/Checkbox";
import TableBody from "@ui/cdk/Table/TableBody";
import TableCell from "@ui/cdk/Table/TableCell";
import {TableCellSize} from "@ui/cdk/Table/TableCell/types";
import TableHead from "@ui/cdk/Table/TableHead";
import TableHeadSortButton from "@ui/cdk/Table/TableHeadSortButton";
import TableRow from "@ui/cdk/Table/TableRow";
import {cls} from "@ui/cdk/util";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import {useMemo, useState} from "@workhorse/api/rendering";
import {filterSpeakers} from "@workhorse/pages/player/session-view-components/session-participants/utils";
import {PeopleSort, PeopleSortField} from "../event-people/utils";
import MySpeakersSearchbar from "./MySpeakersSearchbar";
import MySpeakersTablePlaceholder from "./MySpeakersTablePlaceholder";
import MyTableSpeakersTableRow from "./MyTableSpeakersTableRow";
import classes from "./style/MySpeakersTable.module.scss";

const mySpeakersTableHeadCells = [
    {id: "checkbox", label: "", size: "small" as TableCellSize},
    {id: "name", label: "Name", size: "unset" as TableCellSize},
];

const sortableHeadCells: PeopleSortField[] = ["name"];

type MySpeakersTableProps = {
    speakers: SpeakerDetailsFragment[];
    openNewSpeaker: () => void;
    selectedSpeakers: string[];
    setSelectedSpeakers: React.Dispatch<React.SetStateAction<string[]>>;
};

function MySpeakersTable(props: MySpeakersTableProps) {
    const {speakers, openNewSpeaker, selectedSpeakers, setSelectedSpeakers} = props;

    const [sort, setSort] = useState<PeopleSort>({
        field: "name",
        direction: "asc",
    });

    const [searchSpeakerValue, setSearchSpeakerValue] = useState<string>("");

    const sortedSpeakers = speakers;

    const filteredSortedSpeakers = useMemo(() => filterSpeakers(sortedSpeakers, searchSpeakerValue), [sortedSpeakers, searchSpeakerValue]);

    const filteredSortedSpeakerIds = useMemo(() => filteredSortedSpeakers.map((s) => s.id), [filteredSortedSpeakers]);

    const allSpeakersSelected = useMemo(
        () => (selectedSpeakers.length > 0 ? filteredSortedSpeakers.every((s) => selectedSpeakers.includes(s.id)) : false),
        [filteredSortedSpeakers, selectedSpeakers]
    );

    const toggleSelectAll = () => {
        setSelectedSpeakers((prev) =>
            allSpeakersSelected
                ? selectedSpeakers.filter((id) => !filteredSortedSpeakerIds.includes(id))
                : [...new Set([...prev, ...filteredSortedSpeakerIds])]
        );
    };

    const handleToggleSelectSpeaker = (speakerId: string) => {
        console.log("speakerId", speakerId);
        setSelectedSpeakers((prev) => (prev.includes(speakerId) ? prev.filter((s) => s !== speakerId) : [...prev, speakerId]));
    };

    return (
        <>
            <MySpeakersSearchbar value={searchSpeakerValue} setValue={setSearchSpeakerValue} />
            <TableContainer className={classes.tableContainer}>
                <Table className={"flex flex-col overflow-hidden"}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {mySpeakersTableHeadCells.map((cell) => (
                                <TableCell
                                    key={cell.id}
                                    size={cell.size}
                                    className={cls(cell.id === "checkbox" ? classes.cellCheckbox : "")}
                                >
                                    <div className="flex flex-align-center">
                                        {cell.id === "checkbox" ? (
                                            <Checkbox
                                                checked={filteredSortedSpeakers.length > 0 ? Boolean(allSpeakersSelected) : false}
                                                disabled={!filteredSortedSpeakers.length}
                                                onChange={toggleSelectAll}
                                                data-id="all-speaker-checkbox"
                                            />
                                        ) : (
                                            cell.label
                                        )}
                                        {/* {sortableHeadCells.includes(cell.id as PeopleSortField) && (
                                            <TableHeadSortButton
                                                columnId={cell.id}
                                                activeColumnId={sort.field}
                                                direction={sort.direction}
                                                onSort={setSort}
                                                canSort={true}
                                            />
                                        )} */}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {filteredSortedSpeakers.length > 0 ? (
                            filteredSortedSpeakers.map((speaker) => (
                                <MyTableSpeakersTableRow
                                    key={speaker.id}
                                    speaker={speaker}
                                    isSelected={selectedSpeakers.includes(speaker.id)}
                                    onToggleSelectSpeaker={handleToggleSelectSpeaker}
                                />
                            ))
                        ) : (
                            <MySpeakersTablePlaceholder hasSpeakers={speakers.length > 0} openNewSpeaker={openNewSpeaker} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default MySpeakersTable;
