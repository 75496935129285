import {SessionParticipantFragment} from "@generated/data";
import {EventParticipant, EventParticipantRole} from "./event-people";

export function getParticipantEventRole(participant: SessionParticipantFragment): EventParticipantRole {
    if (participant?.isOwner) {
        return "Host";
    }

    if (!!participant?.speakerDetails) {
        return "Speaker";
    }

    if (participant?.rbac?.session?.isAssistant) {
        return "Co-host";
    }

    if (participant?.invitedByTheOwner) {
        return "Invited participant";
    }

    return "Guest";
}

export type PeopleSortField = "name" | "email" | "role" | "registrationDate";
export type PeopleSort = {
    field: PeopleSortField;
    direction: "asc" | "desc";
};

export function createPeopleComparator(sort: PeopleSort) {
    return (a: EventParticipant, b: EventParticipant) => {
        let result = 1;

        if (sort.field === "name" && a.dataWithNullableEmail?.firstName && b.dataWithNullableEmail?.firstName) {
            result = a.dataWithNullableEmail?.firstName?.localeCompare(b.dataWithNullableEmail?.firstName);
        }
        if (sort.field === "email" && a.dataWithNullableEmail.email && b.dataWithNullableEmail.email) {
            result = a.dataWithNullableEmail.email.localeCompare(b.dataWithNullableEmail.email);
        }
        if (sort.field === "role") {
            result = a.eventRole === b.eventRole ? 0 : a.eventRole ? -1 : 1;
        }
        if (sort.field === "registrationDate") {
            const aDate = new Date(a.createdAt);
            const bDate = new Date(b.createdAt);
            result = aDate.getTime() - bDate.getTime();
        }

        const direction = sort.direction === "asc" ? 1 : -1;

        return result * direction;
    };
}
