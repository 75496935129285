import Providers from "./providers";

if (!window.structuredClone) {
    import("@ungap/structured-clone").then((r) => {
        window.structuredClone = r.default;
    });
}

// @ts-ignore
window.addEventListener("error", (e) => {
    // prompt user to confirm refresh
    if (/^ResizeObserver loop limit exceeded/.test(e.message)) {
        e.stopImmediatePropagation();
        console.log("Ignored: ResizeObserver loop limit exceeded");
        return true;
    }
    if (/^Uncaught SyntaxError: Unexpected token/.test(e.message) || /Unexpected token < in JSON at/.test(e.message)) {
        // null checks on window.location.href aren't necessary at the moment
        // but will be necessary in the future in case we run the app on server side
        const reqUrl = ((e.error || {}).response || {}).url || "";
        console.log("caught error in refresh event listener: ", e.message, " -in- ", window?.location?.href, " -request url- ", reqUrl);
        if (!!reqUrl && /\.(js)$/.test(reqUrl)) {
            window.location.reload();
        }
    }
});

function App() {
    return (
        <>
            <Providers />
            <div id="command-palette-root" data-listening={["Ctrl", "k", "l", "Escape"]} className="flex" />
        </>
    );
}

export default App;
