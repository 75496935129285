import {useClientEvent} from "@api/events/client";
import {cls} from "@ui/cdk/util/util";
import {Fragment, useCallback, useContext, useEffect, useReducer, useRef, useState} from "@workhorse/api/rendering";
import {HostType} from "@workhorse/declarations/dataTypes";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import DrawerRightActionMenu from "../../drawers/components/DrawerRightActionMenu";
import {useTranslation} from "react-i18next";

type FooterRightPortalProps = {
    setClassName: (className: string) => void;
};

function FooterRightPortal(props: FooterRightPortalProps) {
    const {t} = useTranslation();
    const {setClassName} = props;
    const {isMobileOrTablet} = useMobile();

    const hostType = useHostType();

    const {isLobby} = useIsLobby();
    const isMemory = hostType === HostType.Memory;
    const isPlayer = hostType === HostType.Player;

    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set, unset} = useContext(InjectorContext);
    const ids = (Object.keys(existing) as Array<keyof typeof existing>)
        .filter((x) => x !== "classNames")
        .sort((a, b) => {
            //sort these so they look in the dom the same as in the UI
            //screen readers will parse these in the order they appear in the dom

            const exA = existing[a] as any;
            const exB = existing[b] as any;

            if (exA?.props?.order && exB?.props?.order) {
                return exA?.props?.order - exB?.props?.order;
            }
            return 0;
        });
    const gotComponents = ids.filter((x) => !!existing[x]).length > 0;
    const className = cls(gotComponents && "with-content app-border", cls.apply(null, existing.classNames));

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        set("inFooterRight", inject);
    }, []);

    useEffect(() => {
        setClassName(gotComponents ? "with-content" : "");
    }, [gotComponents]);

    const hidden = (isMobileOrTablet && !isPlayer && !isMemory) || !ref.current?.childNodes.length;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLLIElement | null>(null);

    const handleToggleMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    useClientEvent("macro-clicked", () => {
        handleClose();
    });

    return (
        <div
            id={"app-footer-right"}
            className={cls(
                "app-footer-right",
                className,
                isLobby && "in-lobby",
                isMemory && "in-memory",
                anchorEl && "menu-open",
                hidden && "hidden-but-displayed",
                isMobileOrTablet && "in-gadgets"
            )}
            ref={ref}
            role="region"
            aria-label={t("player.footer.aria.interaction_tools") ?? "Interaction tools"}
        >
            {isMobileOrTablet ? (
                <DrawerRightActionMenu anchorEl={anchorEl} onToggleMenu={handleToggleMenu} onClose={handleClose} isLobby={isLobby}>
                    {ids.map((k) => {
                        return existing[k];
                    })}
                </DrawerRightActionMenu>
            ) : (
                ids.map((k) => {
                    return <Fragment key={k}>{existing[k]}</Fragment>;
                })
            )}
        </div>
    );
}

export default FooterRightPortal;
