import {ContactGroupInfoFragment, ContactInfoFragment} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import Paper from "@ui/core/components/Paper";
import {GroupIcon} from "@workhorse/pages/contacts/components/groups/GroupListItem";
import {DummyGroupNames} from "@workhorse/pages/contacts/utils/declarations";
import {MouseEventHandler} from "react";
import {ContactFetcherDetails} from "./ContactFetcherDetails";
import classes from "./styles/ContactFetcherPaper.module.scss";

interface Props {
    selected: boolean;
    index: number;
    search?: string;
    group: ContactGroupInfoFragment;
    groupContacts: ContactInfoFragment[];
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export function ContactFetcherGroup(props: Props) {
    const isFavoriteGroup = props.group.name === DummyGroupNames.FAVORITES;
    const isRemovedGroup = props.group.name === DummyGroupNames.BIN;

    return (
        <Paper
            elevation={2}
            className={cls("flex flex-items-center", classes.paperClass, props.selected && classes.selected)}
            onPointerDown={props.onClick}
            id={`result-paper-${props.index}`}
        >
            <ContactFetcherDetails
                classes={{
                    wrap: classes.infoBlock,
                    details: classes.searchResultEmail,
                    content: classes.searchResultName,
                }}
                search={props.search || ""}
                content={props.group.name}
                details={`${props.groupContacts.length} Members`}
                highlightDetails={false}
                initials={<GroupIcon isFavoriteGroup={isFavoriteGroup} isRemovedGroup={isRemovedGroup} />}
                delimiter={<div className={classes.dot} />}
            />
        </Paper>
    );
}
