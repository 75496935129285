import {forwardRef, Fragment} from "react";
import styles from "./style/linearProgress.module.scss";
import {LinearProgressProps} from "./types";
import {cls, capitalize} from "@ui/cdk/util/util";

function LinearProgressWithRef(props: LinearProgressProps, ref?: any) {
    const {
        /**
         * @param {LinearProgressColorTypes} color The color of the component. @default "blue"
         */
        color = "blue",
        /**
         * @param {Number} value The value of the progress indicator for the determinate and indeterminate variants. Value between 0 and 100.
         */
        value,
        /**
         * @param {LinearProgressVariantTypes} variant The variant to use. Use indeterminate when there is no progress value. @default "indeterminate"
         */
        variant = "indeterminate",
        /**
         * @param {Number} variant A number value in ms to animate the progress bar from the value prop to 0%.
         */
        animationDuration,
        /**
         * @param {JSX.Element} startAdornment Pass an Element to be displayed at the start of the progress bar.
         */
        startAdornment,
        /**
         * @param {JSX.Element} endAdornment Pass an Element to be displayed at the end of the progress bar.
         */
        endAdornment,
        /**
         * @param {JSX.Element | String} topLabel Pass an Element to be displayed at the top-left of the progress bar.
         */
        topLabel,
        /**
         * @param {JSX.Element | String} bottomLabel Pass an Element to be displayed at the bottom-left of the progress bar.
         */
        bottomLabel,
        /**
         * @param {Boolean} progressLabel Displays the value prop at the top-right of the progress bar.
         */
        progressLabel,
        /**
         * @param {Number} progressHeight The height of the progress bar. @default 4px
         */
        progressHeight = 4,
        /**
         * @param {Boolean} withBullet Displays the bullet on the progress bar.
         */
        withBullet = false,
        /**
         * @param {Boolean} onAgendaToggler Sets the border-radius to 12px 0 0 12px for the progress bar in the Agenda Toggler button. @default false
         */
        isOnAgendaToggler = false,
        ...rest
    } = props;

    const classNamesRoot = cls("fosLinearProgress", styles.root);
    const classNamesProgressBar = cls(styles[`color${capitalize(color)}`], styles.progressBar, "progress");
    return (
        <div className={classNamesRoot} ref={ref} {...rest} key="linear-progressbar">
            {startAdornment && (
                <div className={styles.startAdornment} key="linear-progressbar-start-adornment">
                    {startAdornment}
                </div>
            )}
            <div className={styles.progressBarContent} key="linear-progressbar-content">
                {(topLabel || progressLabel) && (
                    <div className={styles.topContent} key="linear-progressbar-top-content">
                        {topLabel && <span key="linear-progressbar-top-content-label">{topLabel}</span>}
                        {progressLabel && (
                            <span className={styles.progressValue} key="linear-progressbar-top-content-progress">
                                {value}%
                            </span>
                        )}
                    </div>
                )}
                <div
                    className={classNamesProgressBar}
                    style={{
                        height: progressHeight,
                        overflow: withBullet ? "visible" : "hidden",
                        borderRadius: isOnAgendaToggler ? "8px 0 0 8px" : "12px",
                    }}
                    key="linear-progressbar-root"
                >
                    {variant === "indeterminate" ? (
                        <Fragment key="linear-progressbar-indeterminate">
                            <div className={cls("progress-inner", styles.inc)} key="linear-progressbar-indeterminate-inc" />
                            <div className={cls("progress-inner", styles.dec)} key="linear-progressbar-indeterminate-dec" />
                        </Fragment>
                    ) : (
                        <Fragment key="linear-progressbar-determinate">
                            <div
                                className={cls(animationDuration ? styles.progressBarAnimation : "")}
                                style={{width: `${value}%`, animationDuration: `${animationDuration}ms`}}
                            />

                            {withBullet ? (
                                <div
                                    style={{
                                        left: `${value}%`,
                                        animationDuration: `${animationDuration}ms`,
                                    }}
                                    className={cls(styles.progressBullet)}
                                />
                            ) : null}
                        </Fragment>
                    )}
                </div>

                {bottomLabel && (
                    <div className={styles.bottomContent} key="linear-progressbar-bottom-label">
                        {bottomLabel}
                    </div>
                )}
            </div>
            {endAdornment && (
                <div className={styles.endAdornment} key="linear-progressbar-end-adornment">
                    {endAdornment}
                </div>
            )}
        </div>
    );
}

const LinearProgress = forwardRef(LinearProgressWithRef) as (
    props: LinearProgressProps & {ref?: any}
) => ReturnType<typeof LinearProgressWithRef>;

export {LinearProgressWithRef};
export default LinearProgress;
