import {GetCalendarEventWithParticipantsDocument} from "@generated/data";
import apollo from "../apollo";
import {makeVar} from "../data";
import {BaseActionInfo, BaseCreateEventInfo, BaseTooltipActionInfo, BaseTooltipCreateActionInfo} from "./definitions";
import {eventIsRecurrent} from "./utils";
import toast from "../toast";

export const baseActionInfo = makeVar<BaseActionInfo | false>(false);
export const baseTooltipEventInfo = makeVar<BaseTooltipActionInfo | false>(false);
export const shouldOpenCalendarConnectionsDialog = makeVar<boolean>(false);
export const createEventTooltipInfo = makeVar<BaseTooltipCreateActionInfo | false>(false);

export const requestEventAction = (actionInfo: BaseActionInfo): void => {
    baseActionInfo(actionInfo);

    if (("event" in actionInfo && eventIsRecurrent(actionInfo.event)) || actionInfo.type === "rsvp") {
        discardEventTooltip();
    }
};

export const discardEventAction = (): void => {
    baseActionInfo(false);
};

export const requestEventTooltip = (eventInfo: BaseTooltipActionInfo): void => {
    apollo.client
        .query({query: GetCalendarEventWithParticipantsDocument, variables: {id: eventInfo.event.id}})
        .then((res) => {
            const event = res.data.calendarEventWithParticipants;

            if (event?.id) {
                baseTooltipEventInfo({
                    ...eventInfo,
                    event: {
                        ...eventInfo.event,
                        instanceOfRecurrence: eventInfo.event.instanceOfRecurrence
                            ? {
                                  ...eventInfo.event.instanceOfRecurrence,
                                  isParticipantInRecurrenceParent: !!event.instanceOfRecurrence?.isParticipantInRecurrenceParent,
                              }
                            : null,
                        participants: event.participants,
                    },
                });
            }
        })
        .catch(() => {
            toast("Something went wrong with displaying session's info!", {type: "error"});
        });
};

export const discardEventTooltip = (): void => {
    baseTooltipEventInfo(false);
};

export const requestCreateEventTooltip = (eventInfo: BaseTooltipCreateActionInfo): void => {
    createEventTooltipInfo(eventInfo);
};

export const discardCreateEventTooltip = (): void => {
    createEventTooltipInfo(false);
};
