import {ConnectionQuality} from "livekit-client";
import {ReactComponent as LowSignal} from "../../../../../assets/media/signal_none.svg";
import {ReactComponent as LostSignal} from "../../../../../assets/media/signal_lost_v1.svg";
import {cls} from "@ui/cdk/util";
import style from "./styles/AudienceParticipant.module.scss";
import Tooltip from "@ui/cdk/Tooltip";

type ConnectionQualityIconProps = {
    connectionQuality?: ConnectionQuality;
    isThumbnailMode?: boolean;
    isLocal?: boolean;
};

export default function ConnectionQualityIcon(props: ConnectionQualityIconProps) {
    const {connectionQuality, isThumbnailMode, isLocal} = props;

    const Icon =
        connectionQuality === ConnectionQuality.Poor ? LowSignal : connectionQuality === ConnectionQuality.Lost ? LostSignal : null;

    if (Icon == null) {
        return null;
    }

    if (connectionQuality == null) {
        return null;
    }

    return (
        <Tooltip
            title={`Low available bandwidth.${
                props.isLocal ? " For improved performance please consider switching off some/all incoming video streams." : ""
            }`}
        >
            <div
                className={cls(
                    style.statusIconContainer,
                    isThumbnailMode && style.isThumbnail,
                    // connectionQuality === ConnectionQuality.Excellent && style.statusMed,
                    // connectionQuality === ConnectionQuality.Good && style.statusMed,
                    connectionQuality === ConnectionQuality.Lost && style.statusOff
                )}
            >
                <Icon />
            </div>
        </Tooltip>
    );
}
