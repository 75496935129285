import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {useMobile} from "@workhorse/providers/MobileProvider";
import LoyaltyRoundedIcon from "@material-ui/icons/LoyaltyRounded";
import classes from "./styles/OffersToggler.module.scss";
import {forwardRef} from "@workhorse/api/rendering";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";
import {Session} from "@workhorse/declarations/dataTypes";

type OffersTogglerProps = {
    artifactId: string;
    notInLobby: boolean;
    onToggle: () => void;
    isActive: boolean;
    inDrawer: boolean;
    isMemoryMode: boolean;
    session: Session;
    isOrganizer: boolean;
    isMacroHidden: boolean;
};

const OffersToggler = forwardRef((props: OffersTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {artifactId, notInLobby, onToggle, isActive, inDrawer, isMemoryMode, session, isOrganizer, isMacroHidden} = props;

    const {isMobileOrTablet} = useMobile();
    const {tooltip} = useOnboardingSession();

    const handleClick = () => {
        onToggle();
    };

    return (
        <>
            {isOrganizer || !isMacroHidden ? (
                <ArtifactActionsButton
                    badgeContent={0}
                    onClick={handleClick}
                    isActive={isActive}
                    tooltipPlacement={tooltip === "offers-footer" ? "left" : undefined}
                    MacroIcon={
                        <div className="flex flex-items-center">
                            <LoyaltyRoundedIcon />
                        </div>
                    }
                    buttonDataId="player-button-offers"
                    artifactId={artifactId}
                    isLobby={!notInLobby}
                    inDrawer={inDrawer}
                    className={classes.btn}
                    ref={ref}
                    label={t("macro.offers.title") ?? "Offers"}
                    ariaLabel={t("macro.offers.title") ?? "Offers"}
                />
            ) : null}
        </>
    );
});

export default OffersToggler;
