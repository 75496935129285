import loadable from "@loadable/component";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Button from "@ui/cdk/Button";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import extension from "@workhorse/api/extension";
import {useMemo} from "@workhorse/api/rendering";
import {useHistory, useLocation} from "@workhorse/api/routing";
import {useSeenSection} from "@workhorse/api/seen";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import Loading from "@workhorse/components/Loading";
import {useUserInfo} from "@workhorse/providers/User";
import {isBefore} from "date-fns";
import {ReactComponent as CalendarPlusIcon} from "../../../assets/media/calendar-plus.svg";
import {ReactComponent as EventsIcon} from "../../../assets/media/navbar-left-icons/events.svg";
import {ReactComponent as RoomsIcon} from "../../../assets/media/navbar-left-icons/rooms.svg";
import {ReactComponent as BookingIcon} from "../../../assets/media/navbar-left-icons/bookings.svg";
import {ReactComponent as TasksIcon} from "../../../assets/media/tasks_icon.svg";

import classes from "./style/NavigationCreateMenu.module.scss";

enum MenuOption {
    INSTANT_SESSION,
    PLANNED_SESSION,
    ROOM,
    EVENT,
    BOOKING,
    TASK,
}

type MenuOptionType = {
    type: MenuOption;
    icon: JSX.Element;
    title: string;
    subtitle: string;
    className?: string;
    dataId: string;
};

const CreatePlannedSession = loadable(() => import("@workhorse/components/CreatePlannedSession"), {
    fallback: <Loading />,
});

const menuOptions: MenuOptionType[] = [
    {
        type: MenuOption.INSTANT_SESSION,
        icon: <AddCircleOutlineRoundedIcon />,
        title: "Start a session",
        subtitle: "right away",
        className: classes.instant,
        dataId: "quick",
    },
    {
        type: MenuOption.PLANNED_SESSION,
        icon: <CalendarPlusIcon />,
        title: "Schedule a session",
        subtitle: "for later",
        className: classes.planned,
        dataId: "planned",
    },
    {
        type: MenuOption.EVENT,
        icon: <EventsIcon />,
        title: "Create an event",
        subtitle: "a webinar or workshop",
        className: classes.event,
        dataId: "event",
    },
    {
        type: MenuOption.ROOM,
        icon: <RoomsIcon />,
        title: "Create a room",
        subtitle: "with a permanent link",
        className: classes.room,
        dataId: "room",
    },
    {
        type: MenuOption.BOOKING,
        icon: <BookingIcon />,
        title: "Create a booking link",
        subtitle: "for your services",
        className: classes.booking,
        dataId: "booking",
    },
    {
        type: MenuOption.TASK,
        icon: <TasksIcon />,
        title: "Create a task",
        subtitle: "and set time for yourself",
        className: classes.tasks,
        dataId: "task",
    },
];

const schedulerSamMenuOptions: MenuOptionType[] = [
    {
        type: MenuOption.BOOKING,
        icon: <BookingIcon />,
        title: "Create a booking link",
        subtitle: "for your services",
        className: classes.booking,
        dataId: "booking",
    },
];

type NavigationCreateMenuProps = {
    className?: string;
    menuAnchorEl: HTMLButtonElement | null;
    setMenuAnchorEl: (menuAnchorEl: HTMLButtonElement | null) => void;
};

const eventOnboardingReleaseDate = new Date("2024-04-15T13:08:36.342Z");

function NavigationCreateMenu(props: NavigationCreateMenuProps) {
    const {className, menuAnchorEl, setMenuAnchorEl} = props;

    const userSeenOnboardingRoomsWelcome = useSeenSection("GLOBALS.userOnboardingRoomsWelcome");
    const userSeenOnboardingRoomsCreate = useSeenSection("GLOBALS.userOnboardingRoomsCreate");
    const userSeenGetStartedWidget = useSeenSection("GLOBALS.getStartedWidget");

    const seenEventsOnboarding = useSeenSection("ONBOARDING_FEATURES.eventsWelcome");

    const user = useUserInfo();

    const showOnboarding =
        !seenEventsOnboarding && isBefore(eventOnboardingReleaseDate, new Date(user.organizationPermissions?.[0].organization.createdAt));

    const history = useHistory();
    const location = useLocation<{}>();

    const access = useWorkspaceAccess();

    const {mixpanelTrack} = useMixpanelTracker();

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const onboardingMeetingTypes = user.activeOrganizationPermission.organization.onboarding?.onboarding_meeting_types;
    const isSchedulerSAM = onboardingMeetingTypes?.some((meetingType) => ["interviews", "demos", "trainings"]?.includes(meetingType?.key));

    const handleToggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (menuAnchorEl) {
            setMenuAnchorEl(null);
        } else {
            setMenuAnchorEl(event.currentTarget);
        }

        event.stopPropagation();
    };

    const handleCreateInstantSession = async () => {
        history.push("/?createSession=instant");
    };

    const handleCreatePlannedSession = async () => {
        history.push("/?createSession=planned");
    };

    const handleCreateTaskSession = async () => {
        history.push("/?createSession=task");
    };

    const handleGoToRoom = async () => {
        if (!userSeenOnboardingRoomsWelcome || !userSeenOnboardingRoomsCreate) {
            history.push("/rooms");

            return;
        }

        history.push("/rooms/create");
    };

    const handleGoToEvent = () => {
        if (showOnboarding) {
            history.push("/events");
            return;
        }

        history.push("/event");
    };

    const handleClickOption = (type: MenuOption) => {
        setMenuAnchorEl(null);

        if (type === MenuOption.INSTANT_SESSION) {
            if (!access.canCreateSessions()) {
                access.triggerGenericAccessToast();
                return;
            }
            handleCreateInstantSession();
            mixpanelTrack("frontend-create-session", "menu", "instant");
        }

        if (type === MenuOption.PLANNED_SESSION) {
            if (!access.canCreateSessions()) {
                access.triggerGenericAccessToast();
                return;
            }
            handleCreatePlannedSession();
            mixpanelTrack("frontend-try-create-session", "menu", "planned");
        }

        if (type === MenuOption.TASK) {
            if (!access.canCreateSessions()) {
                access.triggerGenericAccessToast();
                return;
            }

            handleCreateTaskSession();
            mixpanelTrack("frontend-try-create-session", "menu", "planned");
        }

        if (type === MenuOption.ROOM) {
            if (!access.canCreateRooms()) {
                access.triggerGenericAccessToast();
                return;
            }
            handleGoToRoom();
        }

        if (type === MenuOption.EVENT) {
            if (!access.canCreateEvents()) {
                access.triggerGenericAccessToast();
                return;
            }
            handleGoToEvent();
            mixpanelTrack("frontend-create-session", "menu", "event");
        }

        if (type === MenuOption.BOOKING) {
            mixpanelTrack("frontend-create-booking", "menu", "booking");
            history.push("/booking", {
                openCreate: true,
            });
        }
    };

    const menuOptionsToUse = isSchedulerSAM && !userSeenGetStartedWidget ? schedulerSamMenuOptions : menuOptions;

    return (
        <>
            <Button
                className={cls(classes.toggler, menuAnchorEl ? classes.togglerOpen : "", className)}
                onClick={handleToggleMenu}
                data-id="nav-create-button"
            >
                <AddCircleOutlineRoundedIcon />
                <span>Create</span>
                <KeyboardArrowDownRoundedIcon />
            </Button>
            <Menu
                open={Boolean(menuAnchorEl)}
                anchorEl={menuAnchorEl}
                onClose={handleToggleMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                getContentAnchorEl={null}
                classes={{
                    paper: classes.menuPaper,
                }}
            >
                {menuOptionsToUse.map((o) => (
                    <MenuItem
                        data-id={"nav-create-" + o.dataId}
                        className={cls(classes.menuItem, o.className)}
                        onClick={() => handleClickOption(o.type)}
                        key={"nav-create-" + o.type}
                    >
                        <div className={classes.menuItemIcon}>{o.icon}</div>
                        <div className="flex flex-col">
                            <Typography color="secondary" fontWeight="bolder">
                                {o.title}
                            </Typography>
                            <Typography color="nonary">{o.subtitle}</Typography>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default NavigationCreateMenu;
