import {AcceptedTimezone} from "@common/utils/timezones";
import {PublicBookingEventAvailableSlot} from "@generated/data";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import Button from "@ui/cdk/Button";
import DateFormat, {dateFormat, translateDateString} from "@ui/cdk/DateFormat";
import {cls} from "@ui/cdk/util";
import {getAvailableSlotsForDay, setParentNotification, updateBookingEventsAvailableSlotsForDay} from "@workhorse/api/booking";
import {calendarEndOfMonth, CalendarPeriod, calendarStartOfMonth, daysOfTheWeekArray, WeekStartsOn} from "@workhorse/api/calendar";
import {useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {addDays, endOfMonth, isAfter, isBefore, isSameDay, startOfMonth} from "date-fns";
import classes from "../styles/BookCalendar.module.scss";
import {useTranslation} from "react-i18next";

export type BookCalendarProps = {
    data: PublicBookingEventAvailableSlot[];
    selectedMonth: Date;
    value: Date | null;
    timezone: AcceptedTimezone;
    onChange: (date?: Date) => void;
    onMonthChange: (diff: number) => void;
    firstDayOfWeek?: WeekStartsOn;
    isTroubleshootMode?: boolean;
};

export const BookCalendar = ({value, timezone, selectedMonth, onChange, onMonthChange, data, firstDayOfWeek}: BookCalendarProps) => {
    const {t} = useTranslation();

    const renderDayNames = () => {
        return daysOfTheWeekArray("DD", firstDayOfWeek ?? 1).map((dn, i) => (
            <div className={classes.dayOfWeek} key={`${dn}-${i}`}>
                {t(`g.date.day.${dn.toLowerCase()}`)}
            </div>
        ));
    };

    const handleOnChange = (date: Date) => {
        onChange(date);

        updateBookingEventsAvailableSlotsForDay(date, data, timezone);
    };

    const renderDays = useMemo(() => {
        const days: JSX.Element[] = [];

        const calendarView: CalendarPeriod = {
            startDate: calendarStartOfMonth(selectedMonth, firstDayOfWeek ?? 1),
            endDate: calendarEndOfMonth(selectedMonth, firstDayOfWeek ?? 1),
        };

        const currentMonth: CalendarPeriod = {
            startDate: startOfMonth(selectedMonth),
            endDate: endOfMonth(selectedMonth),
        };

        for (let day = calendarView.startDate; !isAfter(day, calendarView.endDate); day = addDays(day, 1)) {
            const availableSlots = getAvailableSlotsForDay(day, data, timezone);

            const hasAvailableSlots = !!availableSlots.length;

            const isNotThisMonth = isBefore(day, currentMonth.startDate) || isAfter(day, currentMonth.endDate);
            const isDisabled = !hasAvailableSlots;
            const isSelected = value && !isNotThisMonth && isSameDay(day, value);

            const isAvailable = !isDisabled && !isNotThisMonth && !isSelected;

            days.push(
                <div
                    key={day.toISOString()}
                    onClick={handleOnChange.bind(this, day)}
                    className={cls(
                        classes.day,
                        isNotThisMonth && classes.notThisMonth,
                        isDisabled && classes.disabled,
                        isAvailable && classes.available,
                        isSelected && classes.selected
                    )}
                    data-id={`calendar-day${isAvailable ? "-available" : ""}`}
                >
                    <span>{day.getDate()}</span>
                </div>
            );
        }

        return days;
    }, [selectedMonth, handleOnChange, value, timezone, timezone]);

    const updatedSlots = useRef(false);

    useEffect(() => {
        setParentNotification({loaded: true});

        if (data && value && !updatedSlots.current) {
            updateBookingEventsAvailableSlotsForDay(value, data, timezone);

            updatedSlots.current = true;
        }
    }, [data, timezone]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Button
                    variant="tertiary"
                    size="large"
                    className={cls(classes.iconButton)}
                    onClick={() => onMonthChange(-1)}
                    data-id="previous-month"
                >
                    <ChevronLeftRoundedIcon />
                </Button>
                <div className={classes.title} data-id="current-month">
                    {translateDateString(dateFormat(selectedMonth, ["month", {sep: " "}, "year"]))}
                    {/* <DateFormat date={selectedMonth} format={["month", {sep: ", "}, "year"]} /> */}
                </div>
                <Button variant="tertiary" className={cls(classes.iconButton)} onClick={() => onMonthChange(1)} data-id="next-month">
                    <ChevronRightRoundedIcon />
                </Button>
            </div>
            <div className={classes.weekDays}>{renderDayNames()}</div>
            <div className={classes.wrapper}>{renderDays}</div>
        </div>
    );
};
