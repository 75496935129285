import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import browserInfo from "@workhorse/api/BrowserInfo";
import {forwardRef, useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {isMacOS} from "@workhorse/util";
import NotAllowedDialog from "../../../RecordingDialogs/NotAllowedDialog";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import {rbac} from "@workhorse/api/user";
import commons from "../../../../commons/style.module.scss";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {emitStopScreenShare} from "@workhorse/api/conference/sessionApi";

type ShareScreenTogglerOptionProps = {
    canEnable: boolean;
    sessionId: string;
    isAdmin: boolean;
    isAssistant: boolean;
    participantId: string;
};

const ShareScreenTogglerOption = forwardRef((props: ShareScreenTogglerOptionProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {canEnable, sessionId, isAdmin, isAssistant, participantId} = props;

    const {startShareScreen, stopShareScreen, sharingParticipantId, isLocalUserSharing, shareDisabled} = useScreenShareOptions();

    const sharingParticipant = useParticipant({participantId: sharingParticipantId!});
    const participantIsOrganizer = sharingParticipant?.isOwner || rbac(sharingParticipant, "session.isAssistant");

    const isMac = isMacOS();

    const shareEnabled = !!sharingParticipantId;

    const isMounted = useIsMounted();

    const isSharingActive = isLocalUserSharing || (!!sharingParticipantId && (isAdmin || isAssistant));

    const [sharing, setSharing] = useState<boolean>(isSharingActive);

    const [trackEvent] = useTrackingEvents();

    const isChromium = browserInfo.isChromium();

    const notAllowedDialogRef = useRef<DialogImperativeRef>();

    const togglingRef = useRef(false);

    const keybinds = getGlobalKeybind("toggleScreenShare");

    useEffect(() => {
        setSharing(isSharingActive);
    }, [isSharingActive]);

    const handleToggleNotAllowedDialog = useCallback(() => {
        notAllowedDialogRef.current?.toggle?.();
    }, []);

    const toggleShareScreen = async () => {
        if (!canEnable) {
            return;
        }

        if (sharing) {
            if (isLocalUserSharing) {
                stopShareScreen();
            } else if (sharingParticipantId) {
                emitStopScreenShare(sessionId, [sharingParticipantId]);
            }

            setSharing(false);
            return;
        }

        if (togglingRef.current) {
            return;
        }

        trackEvent("start-share-screen", {event_category: TrackingEventCategory.PlayerActions});

        try {
            togglingRef.current = true;
            await startShareScreen();
            if (isMounted()) {
                setSharing(true);
            }
        } catch (e) {
            if (isMounted()) {
                setSharing(false);
            }
            if (
                e instanceof Error &&
                e.name === "NotAllowedError" &&
                isMac &&
                (!isChromium || (isChromium && e.message === "Permission denied by system"))
            ) {
                handleToggleNotAllowedDialog();
            }
        } finally {
            togglingRef.current = false;
        }
    };

    return (
        <>
            <MenuItem
                ref={ref}
                className={`flex-center-all ${!shareEnabled ? "blue" : "red"}`}
                onClick={toggleShareScreen}
                disabled={!canEnable}
                data-id="share-screen-button"
            >
                <Typography fontWeight="bold">
                    {!shareEnabled && canEnable
                        ? t("participant.share.share_screen")
                        : t(
                              `participant.share.stop_share_screen${
                                  isLocalUserSharing ? "" : participantIsOrganizer ? "_moderator" : "_participant"
                              }`
                          )}
                </Typography>
                <div className="flex flex-align-center ml-12">
                    {keybinds.map((keybind, index) => (
                        <div key={index} className={commons.keyBind}>
                            {keybind}
                        </div>
                    ))}
                </div>
            </MenuItem>
            <NotAllowedDialog dialogRef={notAllowedDialogRef} onClick={handleToggleNotAllowedDialog} />
        </>
    );
});

export default ShareScreenTogglerOption;
