import {cls} from "@ui/cdk/util/util";
import {resizeGooglePicture} from "@workhorse/util/googleProfile";
import style from "./styles/AudienceParticipant.module.scss";

export type AudienceParticipantAvatarProps = {
    avatarImg: string;
    isAvatarMode: boolean;
};

export function AudienceParticipantAvatar(props: AudienceParticipantAvatarProps) {
    const src = props.isAvatarMode ? resizeGooglePicture(props.avatarImg, 100) : resizeGooglePicture(props.avatarImg, 160, 90);

    return (
        <div className={cls(style.backgroundAvatar, props.isAvatarMode && "avatar-mode")}>
            <img src={src} alt="" />
        </div>
    );
}
