import {useEffect, useRef} from "@workhorse/api/rendering";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {drawerRightActiveComponent} from "@workhorse/providers/inject";
import {DrawerState, GetParticipantsDocument, LocalParticipantsDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";

type Props = {isAssistant: boolean; sessionId: string; isLobby: boolean; quickSession: boolean; dontOpenParticipantsDrawer: boolean};

export default function OnIsAssistantChanged({isAssistant, sessionId, isLobby, quickSession, dontOpenParticipantsDrawer}: Props) {
    const prevAssistant = useRef(isAssistant);
    const openedParticipants = useRef(false);
    const [, toggleRightDrawer] = useDrawerRightToggler();

    useEffect(() => {
        if (!dontOpenParticipantsDrawer && isLobby && !openedParticipants.current && !quickSession) {
            openedParticipants.current = true;
            drawerRightActiveComponent("participants");
            toggleRightDrawer(DrawerState.FullyOpen);
        }
    }, [isLobby]);

    useEffect(() => {
        if (prevAssistant.current !== isAssistant) {
            prevAssistant.current = isAssistant;
            apollo.client
                .query({
                    query: GetParticipantsDocument,
                    fetchPolicy: "no-cache",
                    variables: {
                        where: {
                            sessionId,
                        },
                    },
                })
                .then((res) => {
                    if (res.data.participants.length) {
                        apollo.client.writeQuery({
                            query: LocalParticipantsDocument,
                            variables: {
                                id: sessionId,
                            },
                            data: {
                                __typename: "Query",
                                participants: res.data.participants,
                            },
                        });
                    }
                });
        }
    }, [isAssistant]);
    return <></>;
}
