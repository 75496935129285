import {SessionTimerState, SetSessionTimerDocument} from "@generated/data";
import PauseCircleFilledRoundedIcon from "@material-ui/icons/PauseCircleFilledRounded";
import PlayCircleFilledRoundedIcon from "@material-ui/icons/PlayCircleFilledRounded";
import Button from "@ui/cdk/Button";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useMutation} from "@workhorse/api/data";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useIsLobby, useSession} from "@workhorse/providers/SessionDataProviders";
import {differenceInSeconds} from "date-fns";
import {useTranslation} from "react-i18next";
import {ReactComponent as MaximizeIcon} from "../../../../../assets/media/maximize-popup.svg";
import {ReactComponent as MinimizeIcon} from "../../../../../assets/media/minimize-popup.svg";
import {ReactComponent as StopTimerIcon} from "../../../../../assets/media/stop-timer.svg";
import {getDisplayValue, getIntialTimer} from "../utils";
import classes from "./styles/TimerBody.module.scss";
import TimerInput from "./TimerInput";
import TimerProgress from "./TimerProgress";

type TimerBodyProps = {
    className?: string;
    isPopup?: boolean;
    onTimesUp?: () => void;
    isPopupMinimized?: boolean;
    setIsPopupMinimized?: React.Dispatch<React.SetStateAction<boolean>>;
};

function TimerBody(props: TimerBodyProps) {
    const {className, isPopup, onTimesUp, isPopupMinimized, setIsPopupMinimized} = props;

    const {isLobby} = useIsLobby();

    const {t} = useTranslation();

    const session = useSession();
    const {id: sessionId, timerStartAt, timerInitialDuration, timerCurrentDuration, timerState} = session;

    const secondsDifference = differenceInSeconds(new Date(), timerStartAt ? new Date(timerStartAt) : new Date());

    const secondsPassed = secondsDifference < 0 ? 0 : secondsDifference;

    const secondsLeft = (timerCurrentDuration ?? 0) - secondsPassed > 0 ? (timerCurrentDuration ?? 0) - secondsPassed : 0;

    const initialValue = timerCurrentDuration ? (secondsLeft > 0 ? secondsLeft : getIntialTimer(isPopup)) : getIntialTimer(isPopup);
    const initialTotalValue = timerInitialDuration ?? getIntialTimer(isPopup);

    const [totalValue, setTotalValue] = useState<number>(initialTotalValue);
    const [value, setValue] = useState<number>(initialValue);

    const prevValueRef = useRef<number>(value);

    const isTimerDone = secondsLeft === 0 && timerState === SessionTimerState.Started;
    const [isTimesUp, setIsTimesUp] = useState<boolean>(isTimerDone);

    const isTimerActive = timerState === SessionTimerState.Started && !isTimesUp;
    const isTimerPaused = timerState === SessionTimerState.Paused;

    const editTimerTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [setSessionTimer] = useMutation(SetSessionTimerDocument);

    const handleEditTimer = (currentDuration: number, initialDuration: number) => {
        if (editTimerTimeoutRef.current) {
            clearTimeout(editTimerTimeoutRef.current);
        }

        editTimerTimeoutRef.current = setTimeout(() => {
            setSessionTimer({
                variables: {
                    sessionId,
                    state: isTimesUp ? SessionTimerState.Stopped : session.timerState,
                    currentDuration,
                    initialDuration,
                },
            }).catch(() => toast(t("macro.timer.error.edit") ?? "", {type: "error"}));
        }, 500);
    };

    const handleStartTimer = () => {
        prevValueRef.current = value;

        if (editTimerTimeoutRef.current) {
            clearTimeout(editTimerTimeoutRef.current);
        }

        setSessionTimer({
            variables: {
                sessionId,
                state: SessionTimerState.Started,
                currentDuration: value,
                initialDuration: isTimesUp ? value : totalValue,
            },
        }).catch(() => toast(t("macro.timer.error.start") ?? "", {type: "error"}));

        setIsTimesUp(false);
    };

    const handleStopTimer = () => {
        setSessionTimer({
            variables: {
                sessionId,
                state: SessionTimerState.Stopped,
                currentDuration: null,
                initialDuration: null,
            },
        }).catch(() => toast(t("macro.timer.error.stop") ?? "", {type: "error"}));

        setIsTimesUp(false);
    };

    const handlePauseTimer = () => {
        prevValueRef.current = value;

        setSessionTimer({
            variables: {
                sessionId,
                state: SessionTimerState.Paused,
                currentDuration: value,
                initialDuration: totalValue,
            },
        }).catch(() => toast(t("macro.timer.error.pause") ?? "", {type: "error"}));
    };

    useEffect(() => {
        if (!timerStartAt || isTimesUp) {
            if (isTimesUp) {
                onTimesUp?.();
                setIsTimesUp(true);
                setValue(getIntialTimer(isPopup));
                setTotalValue(getIntialTimer(isPopup));
            }

            return;
        }

        const interval = setInterval(() => {
            setValue((prev) => {
                const next = prev - 1;

                if (next === 0) {
                    setIsTimesUp(true);
                }

                return next;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [timerStartAt, isTimesUp]);

    useEffect(() => {
        return () => {
            if (editTimerTimeoutRef.current) {
                clearTimeout(editTimerTimeoutRef.current);
            }
        };
    }, []);

    const progress =
        timerInitialDuration && !isTimesUp ? Math.floor((isTimerActive ? value : prevValueRef.current) * 100) / totalValue : 100;

    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    const displayMinutes = getDisplayValue(minutes);
    const displaySeconds = getDisplayValue(seconds);

    return (
        <div className={cls("flex flex-col", className)}>
            {isPopup && (
                <div className={classes.header}>
                    <div className="flex flex-items-center">
                        <Typography fontWeight="bolder">{t("macro.timer.title")}</Typography>
                        {isPopupMinimized && (
                            <Typography variant="sm" color="nonary" fontWeight="bold" className={classes.headerTimer}>
                                {displayMinutes}:{displaySeconds}
                            </Typography>
                        )}
                    </div>
                    <IconButton onClick={() => setIsPopupMinimized?.(!isPopupMinimized)}>
                        {isPopupMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
                    </IconButton>
                </div>
            )}
            {isPopup && isPopupMinimized ? null : (
                <div className={cls(classes.root, isPopup ? classes.rootIsPopup : "")}>
                    <TimerProgress value={value} progress={progress} isPopup={isPopup} isPaused={isTimerPaused} isTimesUp={isTimesUp} />
                    {isPopup ? null : (
                        <>
                            {isTimerActive ? null : (
                                <TimerInput
                                    value={value}
                                    setValue={setValue}
                                    setTotalValue={setTotalValue}
                                    isActive={isTimerActive}
                                    prevValueRef={prevValueRef}
                                    onEditTimer={handleEditTimer}
                                />
                            )}
                            <div className={classes.buttons}>
                                {isTimerActive ? (
                                    <>
                                        <Button variant="tertiary" className={classes.pauseBtn} onClick={handlePauseTimer}>
                                            <PauseCircleFilledRoundedIcon /> {t("g.pause")}
                                        </Button>
                                        <Button variant="destructive-tertiary" onClick={handleStopTimer}>
                                            <StopTimerIcon /> {t("g.stop")}
                                        </Button>
                                    </>
                                ) : isTimerPaused ? (
                                    <>
                                        <Button variant="tertiary" className={classes.pauseBtn} onClick={handleStartTimer}>
                                            <PlayCircleFilledRoundedIcon /> {t("g.resume")}
                                        </Button>
                                        <Button variant="destructive-tertiary" onClick={handleStopTimer}>
                                            <StopTimerIcon /> {t("g.stop")}
                                        </Button>
                                    </>
                                ) : (
                                    <div className="flex flex-col flex-items-center gap-12">
                                        {isTimesUp && !!timerCurrentDuration && (
                                            <Typography variant="xl" fontWeight="bolder">
                                                {t("macro.timer.times_up")}
                                            </Typography>
                                        )}

                                        <Tooltip
                                            title={
                                                isLobby
                                                    ? t("macro.timer.info_tooltip_only_live_session") ?? ""
                                                    : session.backstage
                                                    ? t("macro.timer.info_tooltip_not_backstage") ?? ""
                                                    : ""
                                            }
                                            placement="top"
                                            arrow
                                        >
                                            <Button
                                                onClick={!isLobby && !session.backstage && value > 0 ? handleStartTimer : undefined}
                                                variant={session.transcriptionActive ? "tertiary" : "primary"}
                                                noFocusBorder
                                                className={isLobby || session.backstage || value === 0 ? classes.disabledButton : ""}
                                            >
                                                {t("macro.timer.start_timer")}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default TimerBody;
