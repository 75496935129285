import AddToPhotosRoundedIcon from "@material-ui/icons/AddToPhotosRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import UAParser from "ua-parser-js";
import classes from "./style/AgendaTemplatesEditorSubHeaderMenu.module.scss";
import {ReactComponent as InsertAbove} from "../../../../../assets/media/insert-agenda-item-above.svg";
import {ReactComponent as InsertBelow} from "../../../../../assets/media/insert-agenda-item-below.svg";
import {ReactComponent as RemoveTool} from "../../../../../assets/media/remove-tool.svg";
import {getCorrectPlural} from "@workhorse/util/strings";

type AgendaMenuProps = {
    insertAbove: () => void;
    insertBelow: () => void;
    bulkDelete: () => void;
    duplicate: () => void;
    addTool: () => void;
    selected: string[];
    undo: () => void;
    redo: () => void;
    selectedWithTool: number;
    removeTool?: () => void;
    horizontal?: boolean;
};

const AgendaTemplatesEditorSubHeaderMenu = forwardRef((props: AgendaMenuProps, ref: any) => {
    const {horizontal} = props;
    const os = new UAParser().getOS().name;
    const isMacOS = os === "Mac OS";

    return [
        // isGuest ? null : (
        //     <MenuItem
        //         horizontal={horizontal}
        //         disabled={!props.selected.length}
        //         onClick={props.addTool}
        //         key="agenda-template-menu-addtool"
        //         ref={ref}
        //         className={classes.menuItem}
        //     >
        //         <AddCircleOutlineIcon className={classes.blueIcon} key="agenda-template-menu-addtool-icon" />
        //         <Typography className={classes.blueText} key="agenda-template-menu-addtool-desc">
        //             Add a tool
        //         </Typography>
        //     </MenuItem>
        // ),
        // <div key="divider-1" className={classes.divider}></div>,
        <MenuItem
            data-id="insert-above"
            horizontal={horizontal}
            disabled={!props.selected.length}
            onClick={props.insertAbove}
            key="agenda-template-menu-insertabove"
            ref={ref}
            className={classes.menuItem}
        >
            <InsertAbove className={classes.blueIcon} key="agenda-template-menu-insertabove-icon" />
            <Typography className={classes.blueText} key="agenda-template-menu-insertabove-desc">
                Insert above
            </Typography>
        </MenuItem>,

        <MenuItem
            data-id="insert-below"
            horizontal={horizontal}
            disabled={!props.selected.length}
            onClick={props.insertBelow}
            key="agenda-template-menu-insertbelow"
            ref={ref}
            className={classes.menuItem}
        >
            <InsertBelow className={classes.blueIcon} key="agenda-template-menu-insertbelow-icon" />
            <Typography className={classes.blueText} key="agenda-template-menu-insertbelow-desc">
                Insert below
            </Typography>
        </MenuItem>,

        <MenuItem
            data-id="duplicate"
            horizontal={horizontal}
            disabled={!props.selected.length}
            onClick={props.duplicate}
            key="agenda-template-menu-duplicate"
            ref={ref}
            className={classes.menuItem}
        >
            <AddToPhotosRoundedIcon className={classes.blueIcon} key="agenda-template-menu-duplicate-icon" />

            <Typography className={classes.blueText} key="agenda-template-menu-duplicate-desc">
                Duplicate {getCorrectPlural("item", props.selected.length)}
            </Typography>
        </MenuItem>,
        <div key="divider-2" className={classes.divider}></div>,

        props.selectedWithTool > 0 ? (
            <MenuItem
                horizontal={horizontal}
                data-id="remove-tool"
                variant="tertiary"
                disabled={!props.selected.length}
                onClick={props.removeTool}
                key="agenda-template-menu-removetool"
                ref={ref}
                className={classes.menuItem}
                noDivider
            >
                <RemoveTool className={classes.purpleIcon} key="agenda-template-menu-removetool-icon" />
                <Typography key="agenda-template-menu-removetool-desc">
                    Remove {getCorrectPlural("tool", props.selectedWithTool)} ({props.selectedWithTool})
                </Typography>
            </MenuItem>
        ) : null,

        // Disabled Undo / Redo
        // <MenuItem onClick={props.undo} key="agenda-template-menu-undo">
        //     <UndoIcon className={classes.grayIcon} key="agenda-template-menu-undo-icon" />
        //     <Typography key="agenda-template-menu-undo-desc">Undo</Typography>
        //     <Typography className={classes.key} variant="sm" key="agenda-template-menu-undo-key">
        //         {isMacOS ? "⌘ + ⌥" : "Ctrl + Alt"} + Z
        //     </Typography>
        // </MenuItem>,

        // <MenuItem onClick={props.redo} key="agenda-template-menu-redo">
        //     <RedoIcon className={classes.grayIcon} key="agenda-template-menu-redo-icon" />
        //     <Typography key="agenda-template-menu-redo-desc">Redo</Typography>
        //     <Typography className={classes.key} variant="sm" key="agenda-template-menu-redo-key">
        //         {isMacOS ? "⌘ + ⌥" : "Ctrl + Alt"} + Y
        //     </Typography>
        // </MenuItem>,

        <MenuItem
            data-id="delete-agenda-items"
            disabled={!props.selected.length}
            variant="destructive"
            horizontal={horizontal}
            onClick={props.bulkDelete}
            key="agenda-template-menu-bulkdelete"
            ref={ref}
            noDivider
            className={classes.menuItem}
        >
            <DeleteRoundedIcon className={classes.redIcon} key="agenda-template-menu-bulkdelete-icon" />
            <Typography key="agenda-template-menu-bulkdelete-desc">Remove {getCorrectPlural("item", props.selected.length)}</Typography>
        </MenuItem>,
    ] as unknown as JSX.Element;
});

export default AgendaTemplatesEditorSubHeaderMenu;
