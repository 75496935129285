import {SessionData} from "@workhorse/declarations/dataTypes";
import SessionView from "./SessionViewNew";

type PlayerProps = {
    session: SessionData;
    isJoined: boolean;
};

export default function Player(props: PlayerProps) {
    return <SessionView isJoined={props.isJoined} session={props.session} />;
}
