import {CalendarEventCustomFragment, SortOrder} from "@generated/data";
import {differenceInMilliseconds, differenceInMinutes} from "date-fns";
import {EventOrderBy} from "@workhorse/api/calendar";

const computeSortMode = (number: 1 | 0 | -1, mode?: SortOrder): number => {
    return mode === SortOrder.Desc ? -1 * number : number;
};

const sortEventsByStartDate = (e1: CalendarEventCustomFragment, e2: CalendarEventCustomFragment, mode?: SortOrder): number => {
    const e1Start = e1.startAt;
    const e2Start = e2.startAt;
    const e1End = e1.plannedEnd;
    const e2End = e2.plannedEnd;

    if (!e1Start || !e2Start) {
        return computeSortMode(1, mode);
    }

    const e1StartAt = new Date(e1Start);
    const e1PlannedEnd = new Date(e1End);
    const e2StartAt = new Date(e2Start);
    const e2PlannedEnd = new Date(e2End);

    if (e1StartAt > e2StartAt) {
        return computeSortMode(1, mode);
    }

    if (e1StartAt < e2StartAt) {
        return computeSortMode(-1, mode);
    }

    if (differenceInMinutes(e1StartAt, e1PlannedEnd) >= differenceInMinutes(e2StartAt, e2PlannedEnd)) {
        return computeSortMode(1, mode);
    }

    return computeSortMode(-1, mode);
};

const sortEventsByEndDate = (e1: CalendarEventCustomFragment, e2: CalendarEventCustomFragment, mode?: SortOrder): number => {
    const e1End = e1.plannedEnd;
    const e2End = e2.plannedEnd;

    const e1EndDate = new Date(e1End);
    const e2EndDate = new Date(e2End);

    if (e1EndDate < e2EndDate) {
        return computeSortMode(-1, mode);
    }

    return computeSortMode(1, mode);
};

const sortEventsByParticipantCount = (e1: CalendarEventCustomFragment, e2: CalendarEventCustomFragment, mode?: SortOrder): number => {
    if (e1.participants.length < e2.participants.length) {
        return computeSortMode(-1, mode);
    }

    return computeSortMode(1, mode);
};

const sortEventsByDuration = (e1: CalendarEventCustomFragment, e2: CalendarEventCustomFragment, mode?: SortOrder): number => {
    const e1Start = e1.startAt;
    const e2Start = e2.startAt;
    const e1End = e1.plannedEnd;
    const e2End = e2.plannedEnd;

    if (!e1Start || !e2Start) {
        return computeSortMode(1, mode);
    }

    const e1EndDate = new Date(e1End);
    const e1StartAt = new Date(e1Start);
    const e2EndDate = new Date(e2End);
    const e2StartAt = new Date(e2Start);

    if (differenceInMilliseconds(e1EndDate, e1StartAt) < differenceInMilliseconds(e2EndDate, e2StartAt)) {
        return computeSortMode(-1, mode);
    }

    return computeSortMode(1, mode);
};

export const sortEvents = (
    events: CalendarEventCustomFragment[],
    sortingCriteria?: EventOrderBy,
    mode?: SortOrder
): CalendarEventCustomFragment[] => {
    switch (sortingCriteria) {
        case EventOrderBy.StartDate:
            return events.sort((e1, e2) => sortEventsByStartDate(e1, e2, mode));
        case EventOrderBy.EndDate:
            return events.sort((e1, e2) => sortEventsByEndDate(e1, e2, mode));
        case EventOrderBy.Duration:
            return events.sort((e1, e2) => sortEventsByDuration(e1, e2, mode));
        default:
            return events.sort((e1, e2) => sortEventsByStartDate(e1, e2, mode));
    }
};
