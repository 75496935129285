/*********
 * Generic Subscriptions Component
 * Author: Vasi M
 *
 * the useSubscription hook, will always trigger a component update when it receives data
 * which is an anti-pattern in React
 *
 * the purpose of the wrapper is to feed the received payload
 * to an outer function, where the user has control over what to do with it
 * but without affecting the HOC when said data is received
 * whether an action should be taken to actually update the HOC
 * is now at the user's discretion rather than forced
 *
 * FYI
 * wrapping the hook in another hook will NOT prevent a re-render
 *
 * TODO
 * determine if memoization can/should be applied
 * when the parent rendering the GenericSubscriber updates due to some condition
 */

import React from "@workhorse/api/rendering";
import {
    SubscriptionDoc,
    useSubscription,
    SubscriptionOptionsByDoc,
    SubscriptionPayload,
    QueryDoc,
    ReadQueryOptions,
} from "@workhorse/dataApi";
export type {SubscriptionPayload};

function GenericSubscriber<T extends SubscriptionDoc, TQuery extends QueryDoc = QueryDoc>(props: {
    document: T;
    onData?: (payload: SubscriptionPayload<T>) => void;
    options?: Omit<SubscriptionOptionsByDoc<T>, "onData" | "waitForQuery">;
    waitForQuery?: {
        query: TQuery;
    } & ReadQueryOptions<TQuery>;
    useCallbackQueue?: boolean;
    children?: React.ReactNode | React.ReactNode[];
}) {
    const config: Omit<SubscriptionOptionsByDoc<T>, "onData"> = {
        ...props.options,
        onSubscriptionData: ({subscriptionData}) => {
            const {loading, data, error} = subscriptionData;
            if (error && props.document) {
                throw new Error(`GenericSubscriber Error: subscription doc=${props.document}, error=${JSON.stringify(error)}`);
            }
            if (!loading && data && props.onData) {
                // @ts-ignore
                props.onData(data);
            }
        },
    };
    useSubscription(props.document, {
        ...config,
        waitForQuery: props.waitForQuery,
        useCallbackQueue: props.useCallbackQueue,
    });
    return <>{props.children || null}</>;
}

export default GenericSubscriber;
