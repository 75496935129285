import {useEffect} from "@workhorse/api/rendering";
import {AudioContext, AudioDbAnalyzer} from "../lib/useAudioAnalyzer";
import {stopMediaStream} from "../utils";
import {useCreateAudioStream} from "./useCreateAudioStream";

export function initializeStreamAnalyser(stream: MediaStream) {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(stream);

    const analyserNode = audioContext.createAnalyser();
    analyserNode.smoothingTimeConstant = 0.2;
    analyserNode.fftSize = 256;
    audioSource.connect(analyserNode);

    return {audioSource, audioContext, analyserNode};
}

export class AudioAnalyzer {
    private audioContext: AudioContext;
    private analyserNode: AnalyserNode;
    private audioSource: MediaStreamAudioSourceNode;
    private clonedStream: MediaStream;
    private dbAnalyzer: AudioDbAnalyzer;

    constructor(stream: MediaStream, cb: (db: number) => void, protected cloneStream = false, useWorker = false) {
        const {audioContext, analyserNode, audioSource} = initializeStreamAnalyser(this.clone(stream));

        this.audioContext = audioContext;
        this.analyserNode = analyserNode;
        this.audioSource = audioSource;

        this.dbAnalyzer = new AudioDbAnalyzer(analyserNode, cb, useWorker);
    }

    protected clone(stream: MediaStream) {
        if (!this.cloneStream) {
            return stream;
        }

        this.clonedStream = stream.clone();
        this.clonedStream.getAudioTracks().forEach((track) => (track.enabled = true));
        this.clonedStream.getVideoTracks().forEach((track) => this.clonedStream.removeTrack(track));
        return this.clonedStream;
    }

    start() {
        this.dbAnalyzer.start();
    }

    stop() {
        this.dbAnalyzer.stop();
        this.audioSource.disconnect();
        this.analyserNode.disconnect();

        if (this.audioContext.state !== "closed") {
            this.audioContext.close().catch((e) => console.error(e));
        }

        stopMediaStream(this.clonedStream);
    }
}

export function useLocalAudioDbAnalyzer(deviceId: string, cb?: (db: number) => void) {
    const stream = useCreateAudioStream(deviceId);
    useStreamAudioDbAnalyzer(stream, cb);
}

export function useStreamAudioDbAnalyzer(stream?: MediaStream, cb?: (db: number) => void) {
    useEffect(() => {
        if (!stream || !cb) {
            return;
        }

        const analyzer = new AudioAnalyzer(stream, cb, true);
        analyzer.start();

        return () => {
            analyzer.stop();
        };
    }, [stream, cb]);
}
