import {usePaletteResults} from "./CommandPaletteResultsProvider";
import classes from "./style/CommandPaletteContent.module.scss";
import {cls} from "@ui/cdk/util";
import React, {useMemo, Fragment} from "@workhorse/api/rendering";
import Typography from "@ui/cdk/Typography";
import PaletteRenderOneResult, {ResultListProps} from "./PaletteRenderOneResult";
import {categMap} from "./actionCategTree";
import {useTranslation} from "react-i18next";
import {PaletteItemBase, PaletteItemFileType} from "@generated/data";

export default function ToolResults(props: ResultListProps) {
    const {t} = useTranslation();
    const {hasSearchStr, highlightId} = props;
    const {paletteResults} = usePaletteResults();
    const items = paletteResults.tools;

    const itemMapByTool = useMemo(() => {
        return items.reduce((all, item) => {
            const {tool_type} = item;
            const title = categMap.find((obj) => obj.toolType && obj.toolType === tool_type)?.title;
            const key = title ?? "unknown";
            if (!all[key]) {
                all[key] = [];
            }
            all[key].push(item);
            return all;
        }, {});
    }, [hasSearchStr, items]);

    const titleCount = Object.keys(itemMapByTool).length;
    const containerHeight = 50 * items.length + (hasSearchStr ? titleCount * 22 : 0);

    return items.length ? (
        <>
            {!hasSearchStr && (
                <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
                    {t("palette.tools.title") ?? "Tools"}
                </Typography>
            )}
            {hasSearchStr
                ? (Object.keys(itemMapByTool) as Array<PaletteItemFileType>).map((title) => {
                      return (
                          <Fragment key={title}>
                              <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
                                  {title}
                              </Typography>
                              {itemMapByTool[title].map((item: PaletteItemBase) => {
                                  return (
                                      <PaletteRenderOneResult
                                          item={item}
                                          key={item.id}
                                          highlighted={item.id === highlightId}
                                          setActionArg={props.setActionArg}
                                          setPaletteActiveCateg={props.setPaletteActiveCateg}
                                          rootRef={props.rootRef}
                                          scrollIntoView={props.scrollIntoView}
                                          onHighlightText={t("palette.tools.embed_this_tool") ?? "Embed this tool"}
                                      />
                                  );
                              })}
                          </Fragment>
                      );
                  }, {})
                : items.map((item) => {
                      return (
                          <PaletteRenderOneResult
                              item={item}
                              key={item.id}
                              highlighted={item.id === highlightId}
                              setActionArg={props.setActionArg}
                              setPaletteActiveCateg={props.setPaletteActiveCateg}
                              rootRef={props.rootRef}
                              scrollIntoView={props.scrollIntoView}
                              onHighlightText={t("palette.tools.embed_this_tool") ?? "Embed this tool"}
                          />
                      );
                  })}
        </>
    ) : null;
}
