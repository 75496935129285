import {
    ConfirmMyProductToolIconUploadDocument,
    DeleteOneMyProductToolDocument,
    GetMyProductToolIconUploadUrlDocument,
    MyProductToolsDocument,
    UpsertOneMyProductToolDocument,
} from "@generated/data";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useUserAccess} from "@workhorse/api/access/hooks";
import {useMutation, useQuery, useReactiveVar} from "@workhorse/api/data";
import designer from "@workhorse/api/designer";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import Loading from "@workhorse/components/Loading";
import {useUserInfo} from "@workhorse/providers/User";
import {v4 as uuid} from "uuid";
import {brandingLogo} from "../Theming/utils";
import NoProductToolComponent from "./NoProductToolComponent";
import classes from "./style/MyProductToolProfileContainer.module.scss";
import ToolComponent, {MyProductToolEditableFields} from "./ToolComponent";
import ToolComponentForm from "./ToolComponentForm";
import ToolComponentListItem from "./ToolComponentListItem";
import Tooltip from "@ui/cdk/Tooltip";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import ControlPointRoundedIcon from "@material-ui/icons/ControlPointRounded";

import common from "../../styles/UserSettingsCommons.module.scss";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type MyProductToolContainerProps = {
    onConfirm?: () => void;
    confirmDisabled?: boolean;
    allowInitialDataSubmit?: boolean;
    classes?: {
        root?: string;
        header?: string;
        body?: string;
        footer?: string;
    };
    isOnboarding?: boolean;
};

const MyProductToolContainer = (props: MyProductToolContainerProps) => {
    const user = useUserInfo();
    const access = useUserAccess();
    const {mixpanelTrack} = useMixpanelTracker();

    const customBrandingLogo = useReactiveVar(brandingLogo);

    const workspaceId = user.activeWorkspace.id;

    const {data: tools, loading} = useQuery(MyProductToolsDocument, {
        variables: {workspaceId},
    });
    const [getPictureUploadUrl] = useMutation(GetMyProductToolIconUploadUrlDocument);
    const [confirmToolUrlUploaded] = useMutation(ConfirmMyProductToolIconUploadDocument);
    const [upsertOneMyProductTool] = useMutation(UpsertOneMyProductToolDocument);
    const [deleteOneMyProductTool] = useMutation(DeleteOneMyProductToolDocument);

    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.MY_PRODUCT_TOOL,
        value: tools?.myProductTools.length ?? 0,
    });

    const [newToolFormValues, setNewToolFormValues] = useState({
        id: "",
        description: "",
        enabled: true,
        title: user.activeOrganizationPermission.organization.companyName ?? "",
        url: user.activeOrganizationPermission.organization.companyWebsite ?? "",
        consentOwnershipOfIcon: false,
        iconUrl: customBrandingLogo ?? "",
    });

    const newToolImperativeRef: React.MutableRefObject<DialogImperativeRef> = useRef({});

    useEffect(() => {
        if (props.isOnboarding && tools?.myProductTools.length === 0) {
            handleCreateDefaultProductTool();
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    const canEditMyProduct = access.workspace().canSeeMyProductTool();

    if (!tools?.myProductTools) {
        toast("Something went wrong fetching the tools!", {type: "error"});
    }

    async function handleUpsertOneProductTool(upsertData: MyProductToolEditableFields & {iconUrl?: string}, id?: string) {
        const existingToolNumber = tools?.myProductTools.length ?? 0;
        const toolId = id ?? uuid();
        let bool = false;

        await upsertOneMyProductTool({
            variables: {
                where: {id: toolId},
                update: {
                    description: {set: upsertData.description},
                    title: {set: upsertData.title},
                    url: {set: upsertData.url},
                    enabled: {set: upsertData.enabled},
                    consentOwnershipOfIcon: {set: upsertData.consentOwnershipOfIcon},
                    iconUrl: upsertData.iconUrl ? {set: upsertData.iconUrl} : undefined,
                },
                create: {
                    description: upsertData.description,
                    title: upsertData.title,
                    url: upsertData.url,
                    enabled: upsertData.enabled,
                    iconUrl: upsertData.iconUrl ?? "",
                    consentOwnershipOfIcon: upsertData.consentOwnershipOfIcon,
                },
            },
            update: async (cache, res) => {
                const existing = cache.readQuery({
                    query: MyProductToolsDocument,
                    variables: {workspaceId},
                });

                if (res.data && !existing?.myProductTools.find((p) => p.id === toolId)) {
                    cache.writeQuery({
                        query: MyProductToolsDocument,
                        variables: {workspaceId},
                        data: {
                            ...tools,
                            myProductTools: [...(tools?.myProductTools ?? []), res.data.upsertOneMyProductTool],
                        },
                    });
                }

                await designer.myProductToolInPalette();
            },
        }).then((res) => {
            if (!res.data) {
                toast("Something went wrong creating the tool!", {type: "error"});
            } else {
                // onConfirm prop is received in onboarding dialog. calling it will close the dialog and redirects to a new session
                if (props.onConfirm) {
                    if (existingToolNumber !== 0) {
                        props.onConfirm();
                    }
                } else {
                    toast("Success!", {type: "success"});
                }
                bool = true;
            }
        });

        return bool;
    }

    const handleDeleteProductTool = (toolId: string) => {
        deleteOneMyProductTool({
            variables: {
                where: {id: toolId},
            },
            update: (cache, res) => {
                if (res.data?.deleteOneMyProductTool) {
                    cache.evict({id: res.data.deleteOneMyProductTool.id});
                } else {
                    toast("Something went wrong delete the tool!", {type: "error"});
                }
            },
        });
    };

    async function handleUploadProductToolIcon(toolId: string, file: File) {
        const photoSuffix = Date.now().toString();
        let url = "";

        const uploadImageRes = await getPictureUploadUrl({
            variables: {
                photoSuffix,
                myProductToolId: toolId,
            },
        });

        if (!uploadImageRes.data) {
            toast("Something went wrong uploading the product tool icon!", {type: "error"});
            return "";
        }

        const iconUploadUrlRes = await fetch(uploadImageRes.data.getMyProductToolIconUploadUrl, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type ? file.type : "application/octet-stream",
            },
        });

        if (iconUploadUrlRes.ok) {
            const confirmUploadRes = await confirmToolUrlUploaded({
                variables: {
                    myProductToolId: toolId,
                    photoSuffix,
                },
                update: (cache, res) => {
                    const existing = cache.readQuery({
                        query: MyProductToolsDocument,
                    });

                    if (res.data && !existing?.myProductTools.find((p) => p.id === toolId)) {
                        cache.writeQuery({
                            query: MyProductToolsDocument,
                            data: {
                                ...tools,
                                myProductTools: [...(tools?.myProductTools ?? []), res.data.confirmMyProductToolIconUpload],
                            },
                        });
                    }
                },
            });

            if (confirmUploadRes.data) {
                url = confirmUploadRes.data.confirmMyProductToolIconUpload.iconUrl;
            } else {
                toast("Something went wrong uploading the product tool icon!", {type: "error"});
            }
        } else {
            toast("Something went wrong uploading the product tool icon!", {type: "error"});
        }

        return url;
    }

    const handleAddProductTool = () => {
        if (!featureIsAvailable) {
            emitFeatureLimitError("limitations.notifications.cobrowsing_not_available");
            return;
        }

        const newId = uuid();

        setNewToolFormValues({
            id: newId,
            description: "",
            enabled: true,
            title: "",
            url: "",
            consentOwnershipOfIcon: false,
            iconUrl: customBrandingLogo ?? "",
        });

        if (newToolImperativeRef?.current?.toggle) {
            newToolImperativeRef.current.toggle();
        }

        mixpanelTrack("frontend-add-my-product-tool", "workspace");
    };

    const handleCloseAddProductDialog = () => {
        if (newToolImperativeRef?.current?.toggle) {
            newToolImperativeRef.current.toggle();
        }
    };

    const handleCreateDefaultProductTool = () => {
        handleUpsertOneProductTool({
            description: "",
            enabled: true,
            title: user.activeOrganizationPermission.organization.companyName ?? "",
            url: user.activeOrganizationPermission.organization.companyWebsite ?? "",
            consentOwnershipOfIcon: false,
            iconUrl: customBrandingLogo ?? "",
        });
    };

    return (
        <>
            {props.isOnboarding && tools?.myProductTools.length ? (
                <ToolComponent
                    key={tools.myProductTools[0].id}
                    toolData={tools.myProductTools[0]}
                    userId={user.id}
                    timezone={user.timezone}
                    onUpsert={handleUpsertOneProductTool}
                    onUploadProductToolIcon={handleUploadProductToolIcon}
                    onDeleteProductTool={handleDeleteProductTool}
                    classes={props.classes}
                    isConfirmDisabled={props.confirmDisabled}
                    allowInitialDataSubmit={props.allowInitialDataSubmit}
                    isOnboarding={props.isOnboarding}
                />
            ) : (
                <div className={cls(common.root, "fullh")}>
                    <div className={cls("fullw flex flex-justify-between")}>
                        <div className="flex flex-col">
                            <div className="flex flex-align-center mb-4">
                                <Typography variant="xl" fontWeight="bolder">
                                    My product tools
                                </Typography>
                                <Tooltip
                                    title={"You can add multiple digital products. Everyone in your workspace will have access to them."}
                                    arrow
                                    placement="top"
                                >
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <Typography color="tertiary">
                                Create tools for your digital products and bring them into your sessions.
                            </Typography>
                        </div>
                        {canEditMyProduct && (
                            <Button className="mb-auto" size="small" variant="tertiary" onClick={handleAddProductTool}>
                                <ControlPointRoundedIcon className="mr-6" />
                                Add product
                            </Button>
                        )}
                    </div>
                    {tools?.myProductTools.length ? (
                        <div className="flex flex-col fullw gap-16">
                            {tools.myProductTools.map((tool) => {
                                return (
                                    <ToolComponentListItem
                                        key={tool.id}
                                        toolData={tool}
                                        userId={user.id}
                                        timezone={user.timezone}
                                        onUpsert={handleUpsertOneProductTool}
                                        onUploadProductToolIcon={handleUploadProductToolIcon}
                                        onDeleteProductTool={handleDeleteProductTool}
                                        classes={props.classes}
                                        isConfirmDisabled={props.confirmDisabled}
                                        allowInitialDataSubmit={props.allowInitialDataSubmit}
                                        canEditMyProduct={canEditMyProduct}
                                    />
                                );
                            })}
                        </div>
                    ) : props.isOnboarding ? (
                        <Loading />
                    ) : (
                        <NoProductToolComponent handleCreateAnotherProductTool={handleAddProductTool} canEditMyProduct={canEditMyProduct} />
                    )}
                </div>
            )}

            <Dialog
                imperativeRef={newToolImperativeRef}
                key="edit-product-dialog"
                disableBackdropClick={true}
                onEscapeKeyDown={handleCloseAddProductDialog}
            >
                <DialogTitle title="Edit product" className="px-30 py-20">
                    <div className="fullw flex flex-col">
                        <Typography variant="lg" fontWeight="bold">
                            My Product tool
                        </Typography>
                        <Typography color="tertiary">
                            Bring your digital product directly into your sessions – it’s great for demos and training.
                        </Typography>
                    </div>
                    <IconButton className={classes.closeBtn} onClick={handleCloseAddProductDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <ToolComponentForm
                    key={newToolFormValues.id}
                    toolData={newToolFormValues}
                    userId={user.id}
                    timezone={user.timezone}
                    onUpsert={handleUpsertOneProductTool}
                    onUploadProductToolIcon={handleUploadProductToolIcon}
                    onDeleteProductTool={handleDeleteProductTool}
                    classes={props.classes}
                    isConfirmDisabled={props.confirmDisabled}
                    allowInitialDataSubmit={props.allowInitialDataSubmit}
                    onConfirm={handleCloseAddProductDialog}
                />
            </Dialog>
        </>
    );
};

export default MyProductToolContainer;
