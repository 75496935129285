import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import ConferenceAudience from "./ConferenceAudience";

type SessionAudienceParticipantsProps = {
    sessionId: string;
    myParticipantId: string;
    isJoined: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    sessionIsPublished: boolean;
};

function SessionAudienceParticipants(props: SessionAudienceParticipantsProps) {
    const {isAdmin = false, sessionId, isAssistant, myParticipantId} = props;

    const {audience} = useSessionView();

    return audience ? (
        <ConferenceAudience isAdmin={isAdmin} isAssistant={isAssistant} sessionId={sessionId} myParticipantId={myParticipantId} />
    ) : (
        <></>
    );
}

export default SessionAudienceParticipants;
