import {useDevices, useDeviceManagerEvents, isNotAllowedError} from "@workhorse/api/conference2";
import {useState, useRef, useEffect} from "@workhorse/api/rendering";
import {BlockedPermissionsDialog} from "../BlockedPermissionsModal";
import {NoDevicesDialog} from "./NoDevicesDialog";
import {RequestPermissionsDialog} from "./RequestPermissionsDialog";
import env from "@generated/environment";
import {usePreJoinOnboarding} from "@workhorse/pages/user/onboarding/api/prejoin-onboarding";
import {PreJoinExplainDemoDialog} from "./PreJoinExplainDemoDialog";
import {AudioRemovedDialog} from "./AudioRemovedDialog";

function DevicesInformationDialogs(props: {isRecorder: boolean}) {
    const {
        ready: devicesReady,
        audioError,
        videoError,
        hasAudioInput,
        deviceManager,
        hasVideoInput,
        setAudioEnabled,
        setVideoEnabled,
    } = useDevices();

    const micBlocked = isNotAllowedError(audioError);
    const cameraBlocked = isNotAllowedError(videoError);

    const [audioEnded, setAudioEnded] = useState(false);

    const [permissionsDialogClosed, setPermissionsDialogClosed] = useState(false);
    const [stillBlockedDialogOpen, setStillBlockedDialogOpen] = useState(false);
    const [noDevicesDialogClosed, setNoDevicesDialogClosed] = useState(false);
    const [initPermissionsBlocked, setInitPermissionsBlocked] = useState(false);

    const secondAttempt = useRef(false);

    const {showPreJoinOnboarding, dismissPreJoinOnboarding} = usePreJoinOnboarding();

    useDeviceManagerEvents({
        onAudioNotAllowedError() {
            if (secondAttempt.current) {
                secondAttempt.current = false;
                setStillBlockedDialogOpen(true);
            }
        },
        onVideoNotAllowedError() {
            if (secondAttempt.current) {
                secondAttempt.current = false;
                setStillBlockedDialogOpen(true);
            }
        },
        onInitNotAllowedError() {
            setInitPermissionsBlocked(true);
        },
        onAudioEnded() {
            setAudioEnded(true);
        },
    });

    const handleRequest = async () => {
        setPermissionsDialogClosed(true);

        if (hasAudioInput) {
            setAudioEnabled(true);
        }
        if (hasVideoInput) {
            setVideoEnabled(true);
        }

        secondAttempt.current = true;
    };

    useEffect(() => {
        if (props.isRecorder) {
            deviceManager.handleInteraction();
            setNoDevicesDialogClosed(true);
        }
    }, [props.isRecorder, deviceManager, setNoDevicesDialogClosed]);

    if (env.environmentName === "at" || props.isRecorder) {
        return null;
    }

    if (showPreJoinOnboarding) {
        return <PreJoinExplainDemoDialog onClose={dismissPreJoinOnboarding} />;
    }

    if (audioEnded) {
        return <AudioRemovedDialog onClose={() => setAudioEnded(false)} />;
    }

    if (devicesReady && !hasAudioInput && !noDevicesDialogClosed) {
        return (
            <NoDevicesDialog
                open={true}
                hasCamera={hasVideoInput}
                onClose={() => {
                    deviceManager.handleInteraction();
                    setNoDevicesDialogClosed(true);
                }}
            />
        );
    }

    if (devicesReady && !noDevicesDialogClosed && !permissionsDialogClosed && initPermissionsBlocked) {
        return (
            <RequestPermissionsDialog
                open={true}
                micBlocked={micBlocked}
                cameraBlocked={cameraBlocked}
                onClose={() => {
                    deviceManager.handleInteraction();
                    setPermissionsDialogClosed(true);
                }}
                onRequest={() => {
                    handleRequest();
                }}
            />
        );
    }

    if (stillBlockedDialogOpen) {
        return (
            <BlockedPermissionsDialog
                open={true}
                micBlocked={micBlocked}
                cameraBlocked={cameraBlocked}
                onClose={() => {
                    deviceManager.handleInteraction();
                    setStillBlockedDialogOpen(false);
                }}
            />
        );
    }

    return null;
}

export default DevicesInformationDialogs;
