import Button from "@ui/cdk/Button";
import Dialog from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import {useRef} from "@workhorse/api/rendering";
import CloseIcon from "@material-ui/icons/Close";
import classes from "./style/ConfirmDialog.module.scss";
import {cls} from "@ui/cdk/util";
import {EventAccessType} from "./event-registration";

type Props = {
    onClose(): void;
    onConfirm(): void;
    type: EventAccessType;
};

const ConfirmDialog = (props: Props) => {
    const {type, onClose, onConfirm} = props;

    const ref = useRef();
    return (
        <Dialog
            open
            imperativeRef={ref}
            PaperProps={{
                className: cls(classes.paper, "standard-dialog-paper"),
            }}
            BackdropProps={{
                className: cls(classes.backdrop, "standard-backdrop"),
            }}
            onClose={onClose}
            onEscapeKeyDown={onClose}
            disableBackdropClick={true}
        >
            <DialogTitle className={classes.dialogHeader} key="dialog-title">
                <div className="flex mb-4">
                    <Typography variant="xl3" fontWeight="boldest" className="mr-36">
                        Are you sure?
                    </Typography>
                </div>

                <IconButton onClick={onClose} className={classes.closeBtn}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div className={classes.content}>
                <Typography>{`Are you sure you want to make this event ${
                    type === EventAccessType.FreeAccess ? "public" : "private"
                }?`}</Typography>
                <Typography>
                    {`${
                        type === EventAccessType.FreeAccess ? "Public" : "Private"
                    } event types cannot have payments enabled. The payment config for this event will be removed.`}
                </Typography>
            </div>
            <DialogFooter className={classes.dialogFooter}>
                <Button variant="quaternary" onClick={onClose}>
                    <Typography color="secondary">Cancel</Typography>
                </Button>
                <Button data-id="confirm-button" variant={"destructive-secondary"} onClick={onConfirm} withMarginLeft>
                    {`Make ${type === EventAccessType.FreeAccess ? "public" : "private"}`}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmDialog;
