import {ConfigurationStep, DesignerState as DesignerStateStruct} from "@generated/data";
import {useMemo} from "@workhorse/api/rendering";
import {readQuery, writeQuery, useQuery} from "@workhorse/dataApi";
import {orchestrator} from "@workhorse/providers/state";
import {
    designerDefaultState,
    designerCommitDefaultState,
    designerSessionIdDefault,
    designerSessionLockedDefault,
    designerEditStateDefault,
} from "./client-defaults";

class DesignerState {
    public initializeOrResetState = (sessionId: string | null, configurationStep?: ConfigurationStep, isTemplateMode?: boolean) => {
        writeQuery("DesignerSessionIdDocument", {
            data: {
                __typename: "Query",
                designerSessionId: {
                    __typename: "DesignerSessionId",
                    sessionId,
                },
            },
        });
        writeQuery("DesignerStateDocument", {
            data: {
                __typename: "Query",
                designerState: {
                    ...designerDefaultState,
                    ...(configurationStep ? {configurationStep: configurationStep} : {}),
                    sessionId: sessionId!,
                    ...(isTemplateMode ? {templateMode: isTemplateMode} : {}),
                },
            },
        });
        writeQuery("DesignerCommitStateDocument", {
            data: {
                __typename: "Query",
                designerCommitState: designerCommitDefaultState,
            },
        });
        writeQuery("DesignerEditStateDocument", {
            data: {
                __typename: "Query",
                designerEditState: {
                    ...designerEditStateDefault,
                    sessionId: sessionId!,
                    ...(isTemplateMode ? {isEditing: true} : {}),
                },
            },
        });

        // writeQuery("DesignerSessionLockedDocument", {
        //     data: {
        //         __typename: "Query",
        //         designerSessionLocked: designerSessionLockedDefault,
        //     },
        // });
    };

    public getSnapshot = () => {
        const data = readQuery("DesignerStateDocument");

        return data?.designerState ?? ({} as DesignerStateStruct);
    };

    public getSessionId = () => {
        return readQuery("DesignerSessionIdDocument")?.designerSessionId?.sessionId;
    };

    public setDesignerCommitState = (isCommiting: boolean) => {
        writeQuery("DesignerCommitStateDocument", {
            data: {
                __typename: "Query",
                designerCommitState: {
                    __typename: "DesignerCommitState",
                    commitInProgress: isCommiting,
                },
            },
        });
    };

    public getDesignerCommitState = () => {
        return readQuery("DesignerCommitStateDocument")?.designerCommitState?.commitInProgress;
    };

    public setDesignerEditState = (isEditing: boolean) => {
        const sessionId = this.getSessionId()!;
        writeQuery("DesignerEditStateDocument", {
            data: {
                __typename: "Query",
                designerEditState: {
                    __typename: "DesignerEditState",
                    isEditing,
                    sessionId,
                },
            },
        });
    };

    public getDesignerEditState = () => {
        return readQuery("DesignerEditStateDocument", {})?.designerEditState?.isEditing;
    };

    public update = (incoming: Partial<Omit<DesignerStateStruct, "__typename">>) => {
        const current = this.getSnapshot();

        const isConfigMode = incoming.selectedMacroIdToConfig || incoming.selectedMicroIdToConfig ? true : false;
        let isPreviewMode = incoming.selectedMacroIdToPreview || incoming.selectedMicroIdToPreview ? true : false;

        // if somehow both states are true, prioritize config over preview
        if (isConfigMode && isPreviewMode) {
            isPreviewMode = false;
        }

        // TODO: @vasi, the order should be written on the "update" object of the session
        // when navigating in preview
        // and remove it when closing the preview
        // if (incoming.isSessionPreview === false && current.isSessionPreview && current.currentSessionId) {
        //     // when going out of preview mode, we make sure to reset the session's order to what it was before
        //     // because the preview mode allows navigation
        //     // which alters the session.order param
        //     orchestrator.resetSessionOrder(current.currentSessionId);
        // }

        writeQuery("DesignerStateDocument", {
            data: {
                designerState: {
                    ...current,
                    ...incoming,
                    isConfig: isConfigMode,
                    isPreview: isPreviewMode,
                },
            },
        });
    };
}

const designerState = new DesignerState();

export default designerState;
