import {createContextProvider} from "@workhorse/api/utils/context";

declare global {
    interface Navigator {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canShare?: (data?: ShareData) => boolean;
    }
}

type UserAgentFeature =
    | "fullScreen"
    | "clipboard"
    | "broadcast"
    | "networkInfo"
    | "payments"
    | "vibration"
    | "battery"
    | "share"
    | "geolocation"
    | "pictureInPicture"
    | "speech";

type UserAgentFeatureList = Record<UserAgentFeature, boolean>;

const useUserAgentFeatureStore = () => {
    const list: UserAgentFeatureList = {
        fullScreen: Boolean("fullscreenEnabled" in document),
        clipboard: Boolean("clipboard" in navigator),
        broadcast: Boolean("BroadcastChannel" in window),
        networkInfo: Boolean("connection" in window.navigator),
        payments: Boolean("PaymentRequest" in window),
        vibration: Boolean("vibrate" in navigator),
        battery: Boolean("getBattery" in navigator),
        share: Boolean("share" in navigator && "canShare" in navigator),
        geolocation: Boolean("geolocation" in navigator),
        pictureInPicture: Boolean("pictureInPictureEnabled" in document),
        speech: Boolean("SpeechRecognition" in window || "webkitSpeechRecognition" in window),
    };

    return list;
};

export const [UserAgentFeaturesProvider, useUserAgentFeatures] = createContextProvider(
    {
        name: "UserAgentFeatures",
    },
    useUserAgentFeatureStore
);

export type WithUserAgentFeaturesProps = {
    features: Record<UserAgentFeature, boolean>;
};

export function WithUserAgentFeatures<TProps>(
    Component: React.FC<TProps & WithUserAgentFeaturesProps>
): React.FC<Omit<TProps, "features">> {
    const displayName = Component.displayName || Component.name || "Component";

    const ComponentWithTheme = (props: Exclude<TProps, "features">) => {
        const features = useUserAgentFeatures();

        return <Component {...props} features={features} />;
    };

    ComponentWithTheme.displayName = `WithUserAgentFeature(${displayName})`;

    return ComponentWithTheme;
}
