import loadable from "@workhorse/api/loadable";
import {SessionSettingsSection} from "../../definitions";
import {ReactComponent as SettingsIcon} from "../assets/session-settings-icon.svg";

const AccessSection = loadable(() => import("./AccessSection"));
AccessSection.displayName = "Loadable:AccessSection";

const AccessSectionDefinition: SessionSettingsSection<"general.permissions"> = {
    kind: "general.permissions",
    title: "Participants permissions",
    translateKey: "general.permissions",
    component: AccessSection,
    icon: SettingsIcon,
    rbac: {
        "session.isAssistant": true,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default AccessSectionDefinition;
