import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import {CustomResolvers} from "./types";

export const Room: CustomResolvers["Room"] = {
    ...fieldResolvers.Room,
    sessionInstances: {
        merge: false,
    },
    sharedWithWorkspaces: {
        merge: false,
    },
};
