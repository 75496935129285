import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {MutableRefObject} from "react";
import classes from "./style/NotAllowedDialog.module.scss";
import Link from "@ui/cdk/Link";
import {cls} from "@ui/cdk/util";

type NotAllowedDialogProps = {
    onClick: () => void;
    dialogRef: MutableRefObject<DialogImperativeRef>;
};

const NotAllowedDialog = (props: NotAllowedDialogProps) => {
    return (
        <Dialog
            imperativeRef={props.dialogRef}
            disableBackdropClick
            BackdropProps={{
                className: "standard-backdrop",
            }}
            PaperProps={{
                className: cls("standard-dialog-paper", classes.paper),
            }}
            key="not-allowed-dialog"
        >
            <Typography variant="xl2" color="primary" fontWeight="bolder" className={classes.title} key="not-allowed-dialog-title">
                Can't share your screen
            </Typography>
            <Typography variant="base" color="secondary" className={classes.body} key="not-allowed-dialog-body">
                Your browser might not have screen-recording permission on your computer. Go to{" "}
                <Link href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture" target="_blank">
                    System preferences
                </Link>
                .
            </Typography>
            <Button className={classes.btn} onClick={props.onClick} key="not-allowed-dialog-btn">
                Dismiss
            </Button>
        </Dialog>
    );
};

export default NotAllowedDialog;
