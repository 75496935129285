import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import CircularProgress from "@ui/core/components/CircularProgress";
import PreJoinInfoForm, {FormikValuesType} from "./PreJoinInfoForm";
import classes from "./styles/PreJoinInfoPermission.module.scss";
import {useTranslation} from "react-i18next";

const PermissionPending = (props: PreJoinInfoPermissionProps) => {
    const {t} = useTranslation();
    return (
        <div data-id="permission-pending" className={cls("flex-center-all", classes.root)}>
            <CircularProgress className={classes.logo} thickness={6} size={83} />
            <Typography variant="xl3" fontWeight="boldest" className={classes.title}>
                {t("prejoin.waiting_for_host_permission")}
            </Typography>
            <Typography color="quaternary">{t("prejoin.this_is_a_private_session.host_has_to_approve")}</Typography>
        </div>
    );
};

const PermissionDenied = (props: PreJoinInfoPermissionProps) => {
    const {t} = useTranslation();
    return (
        <div data-id="permission-denied" className={cls(classes.root)}>
            <Typography variant="xl3" fontWeight="boldest" className={cls("text-center", classes.title)}>
                {t("prejoin.host_declined")}
            </Typography>
            <Typography component="div" color="quaternary" className="text-center mb-8">
                {props.tooManyAttempts ? (
                    <Typography>{t("prejoin.declined_too_many_attempts")}</Typography>
                ) : (
                    <>
                        <Typography>{t("prejoin.declined_1")}</Typography>
                        <Typography>{t("prejoin.declined_2")}</Typography>
                    </>
                )}
            </Typography>
            {props.tooManyAttempts ? null : (
                <PreJoinInfoForm
                    name={props.name}
                    email={props.email}
                    requestPermissionToJoin={props.requestPermissionToJoin}
                    onSubmit={props.onRequestPermission}
                    isSubmitting={props.isSubmitting}
                    isGuest={props.isGuest}
                    isPermissionDenied
                    askForGuestEmail={props.askForGuestEmail}
                    isProvideSessionPasscode={props.isProvideSessionPasscode}
                    provideSessionPasscode={props.provideSessionPasscode}
                    passcodeToJoin={props.passcodeToJoin}
                    requiresConsentToRecord={props.requiresConsentToRecord}
                    transcriptionActive={props.transcriptionActive}
                    recordingActive={props.recordingActive}
                    isOwner={props.isOwner}
                    isAssistant={props.isAssistant}
                    isProvidePasscodeFailed={props.isProvidePasscodeFailed}
                    isBorChild={false}
                    setIsProvidePasscodeFailed={props.setIsProvidePasscodeFailed}
                />
            )}
        </div>
    );
};

type PreJoinInfoPermissionProps = {
    onRequestPermission: (values: FormikValuesType) => void;
    tooManyAttempts: boolean;
    isPermissionDenied: boolean;
    requestPermissionToJoin: boolean;
    isSubmitting: boolean;
    name: string;
    email: string;
    isGuest: boolean;
    askForGuestEmail: boolean;
    isProvideSessionPasscode: boolean;
    provideSessionPasscode: boolean;
    passcodeToJoin?: string;
    requiresConsentToRecord: boolean;
    transcriptionActive: boolean;
    recordingActive: boolean;
    isOwner: boolean;
    isAssistant: boolean;
    isProvidePasscodeFailed: boolean;
    setIsProvidePasscodeFailed: (value: boolean) => void;
};

const PreJoinInfoPermission = (props: PreJoinInfoPermissionProps) => {
    return props.isPermissionDenied ? <PermissionDenied {...props} /> : <PermissionPending {...props} />;
};

export default PreJoinInfoPermission;
