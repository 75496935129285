import MuiPopover, {PopoverClassKey as MuiPopoverClassKey} from "@material-ui/core/Popover";
import React, {forwardRef} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";

export type {PopoverActions, PopoverPosition} from "@material-ui/core/Popover";

export type PopoverClassKey = MuiPopoverClassKey;

export type PopoverProps = ExtractProps<typeof MuiPopover>;

export default forwardRef(function Popover(props: PopoverProps, ref) {
    return <MuiPopover {...props} ref={ref} />;
});
