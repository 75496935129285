import Typography from "@ui/cdk/Typography";
import {SessionOnboardingType, useOnboardingSession, useWantedPaletteAction} from "@workhorse/providers/OnboardingSessionProvider";
import {FlatCategItem, categTree, ActionCategory} from "../command-palette/actionCategTree";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import {useDeferredValue} from "./utils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useState, useEffect, useRef, useLayoutEffect} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "./onboarding.module.scss";
import {cls} from "@ui/cdk/util";

interface OnboardingPaletteActionProps extends WithTooltipChildren {
    item: FlatCategItem;
}

export function OnboardingPaletteAction(props: OnboardingPaletteActionProps) {
    const {onboardingType, flow, tooltip, next, nextFlow, index} = useOnboardingSession();
    const action = useWantedPaletteAction(tooltip);
    const [noIntroTransition, setNoIntroTransition] = useState(false);

    //moved these inside the component because the categ ids might get re-generated and the values would get outdated
    const shareFileAction = categTree[ActionCategory.File];
    const uploadFileAction = shareFileAction.next?.[0];
    const shareLinkAction = categTree[ActionCategory.Link];
    const shareToolAction = categTree[ActionCategory.Tool];

    const indexRef = useRef(index);
    indexRef.current = index;

    const {isTablet} = useMobile();

    let skip = true;
    let title = (
        <Typography fontWeight="bold" color="background">
            Select your tool and press Enter
        </Typography>
    );
    let placement: "right" | "top-end" | "bottom-end" = !isTablet ? "right" : "top-end";

    if (action?.actionId === props.item.id) {
        skip = false;
    }

    if (action?.byNavigation === true && action.navigation.includes(props.item.id)) {
        skip = false;
    }

    if (onboardingType === SessionOnboardingType.NewUser && props.item.parentId) {
        skip = false;
    }

    if (props.item.id === shareFileAction.id) {
        title = (
            <Typography fontWeight="bold" color="background">
                Start by sharing a file
            </Typography>
        );
    }

    if (uploadFileAction && props.item.id === uploadFileAction.id) {
        title = (
            <Typography fontWeight="bold" color="background">
                Click here to upload a new file
            </Typography>
        );
    }

    if (onboardingType === SessionOnboardingType.NewUser) {
        // Main palette => Share a file => Upload a file
        if (uploadFileAction && props.item.id === uploadFileAction.id) {
            title = (
                <>
                    <div className="flex flex-align-center">
                        <InfoOutlinedIcon className="mr-6" />
                        <Typography className="title" fontWeight="bolder">
                            Upload a file
                        </Typography>
                    </div>
                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        Click on “Upload a file” and choose one of your existing files.
                    </Typography>
                </>
            );
        } else {
            // Main palette => Share a file
            title = (
                <>
                    <div className="flex flex-align-center">
                        <InfoOutlinedIcon className="mr-6" />
                        <Typography className="title" fontWeight="bolder">
                            Share a file
                        </Typography>
                    </div>
                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        Click on “Share a file” to upload a file from your computer. Give it a try!
                    </Typography>
                </>
            );
        }

        if (props.item.id === shareLinkAction.id) {
            // Main palette => Open a link
            title = (
                <>
                    <div className="flex flex-align-center">
                        <InfoOutlinedIcon className="mr-6" />
                        <Typography className="title" fontWeight="bolder">
                            Open a link
                        </Typography>
                    </div>
                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        You can open any link in Sessions, from videos to websites, as long as the source allows it.
                    </Typography>
                </>
            );
        }

        if (props.item.parentId === shareLinkAction.id) {
            // Main palette => Open a link => Paste a link => Link result

            // the children in 'Paste a link' have no onClick
            // if the user types something, the result that is rendered will have onClick
            if (props.children.props.onClick) {
                title = (
                    <>
                        <div className="flex flex-align-center">
                            <InfoOutlinedIcon className="mr-6" />
                            <Typography className="title" fontWeight="bolder">
                                Click to embed
                            </Typography>
                        </div>
                        <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                            Click on “Embed this resource” or press Enter.
                        </Typography>
                    </>
                );

                if (isTablet) {
                    placement = "bottom-end";
                }
            } else {
                title = <></>;
                skip = true;
            }
        }

        if (props.item.id === shareToolAction.id) {
            // Main palette => Embed a tool
            title = (
                <>
                    <div className="flex flex-align-center">
                        <InfoOutlinedIcon className="mr-6" />
                        <Typography className="title" fontWeight="bolder">
                            Embed a tool
                        </Typography>
                    </div>
                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        There are many tools that work in Sessions and allow you to collaborate with others in real-time. Let’s embed one.
                    </Typography>
                </>
            );
        }

        // Main palette => Embed a tool => Tool selected (Whiteboard, etc.)
        if (props.item.categ === ActionCategory.Tool && props.item.isAction) {
            title = (
                <>
                    <div className="flex flex-align-center">
                        <InfoOutlinedIcon className="mr-6" />
                        <Typography className="title" fontWeight="bolder">
                            Use this tool
                        </Typography>
                    </div>
                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        {`Click "${props.item.title?.trim()}" to use this tool.`}
                    </Typography>
                </>
            );
        }

        // There's a separate tooltip for the tool list: onboarding-tools-action-list
        if (props.item.parentId === shareToolAction.id) {
            skip = true;
            title = <></>;
        }

        if (shareFileAction && props.item.id === shareFileAction.id && tooltip === "palette-generic-and-file-info") {
            skip = false;
        }
    }

    const deferSkip = useDeferredValue(tooltip, skip, true, 1000);

    useLayoutEffect(() => {
        const advanceTooltipTimer1 = setTimeout(() => {
            if (tooltip === "palette-generic-and-file-info" && indexRef.current < 3) {
                //don't want to show the tooltip intro animation again when we've already started showing this tooltip at the previous step
                setNoIntroTransition(true);

                nextFlow({
                    nextTooltip: 0,
                    completeFlow: true,
                });
            }
        }, 2000);

        const advanceTooltipTimer2 = setTimeout(() => {
            if (
                uploadFileAction &&
                props.item.id === uploadFileAction.id &&
                props.item.parentId === shareFileAction.id &&
                tooltip === "palette-file-info" &&
                indexRef.current < 1
            ) {
                setNoIntroTransition(true);

                next({
                    nextTooltip: 1,
                });
            }
        }, 3000);

        return () => {
            clearTimeout(advanceTooltipTimer1);
            clearTimeout(advanceTooltipTimer2);
        };
    }, [next, nextFlow, props.item.id, props.item.parentId, tooltip]);

    useEffect(() => {
        const advanceTooltipTimer0 = setTimeout(() => {
            if (tooltip === "palette-generic-info" && indexRef.current < 2) {
                next({
                    nextTooltip: 2,
                });
            }
        }, 5000);

        return () => {
            clearTimeout(advanceTooltipTimer0);
        };
    }, [tooltip, deferSkip, next, props.item.id, props.item.parentId, nextFlow]);

    return (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement={placement}
            arrow={true}
            skip={noIntroTransition ? skip : deferSkip}
            classes={{tooltip: cls("ml-24", isTablet ? classes.tooltipTablet : ""), popper: isTablet ? classes.popperTablet : ""}}
            isPaletteTooltip={true}
            noIntroTransition={noIntroTransition}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
