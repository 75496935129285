import React from "@workhorse/api/rendering";
import MuiTableBody, {TableBodyProps, TableBodyTypeMap} from "@material-ui/core/TableBody";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";

/**
 * @deprecated use cdk component please
 */
const TableBody: OverridableComponent<TableBodyTypeMap> = (props: TableBodyProps) => {
    return <MuiTableBody {...props} />;
};

export default TableBody;
