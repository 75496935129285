import {cls} from "@ui/cdk/util";
import sectionStyles from "../styles/BackgroundEffectsSection.module.scss";
import genericStyles from "../../../styles/GenericSection.module.scss";
import videoStyles from "../../VideoSection/styles/VideoSection.module.scss";
import IconButton from "@ui/core/components/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {useState} from "@workhorse/api/rendering";
import {useMutation} from "@workhorse/dataApi";
import {DeleteCustomBackgroundDocument, GetUserCustomBackgroundsDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {useTranslation} from "react-i18next";

const styles = {...genericStyles, ...videoStyles, ...sectionStyles};

type ParticipantBackgroundCustomImageSelectProps = {
    url: string;
    selected: boolean;
    onClick: () => void;
    className?: string;
    id: string;
};

const ParticipantBackgroundCustomImageSelect = (props: ParticipantBackgroundCustomImageSelectProps) => {
    const {t} = useTranslation();
    const {url, selected, onClick, className, id} = props;

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteCustomBackground] = useMutation("DeleteCustomBackgroundDocument", {
        refetchQueries: ["GetUserCustomBackgrounds"],
    });
    const onMouseOverBackgroundImage = () => {
        setShowDelete(true);
    };

    const onMouseOutBackgroundImage = () => {
        setShowDelete(false);
    };

    const handleDeleteBackgroundImage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setShowDelete(false);

        await deleteCustomBackground({
            variables: {
                backgroundUrl: url,
            },
        }).then((res) => {
            const deletedBackground = res.data?.deleteCustomBackground?.customBackgrounds?.find((cb) => cb.id === id);
            if (!deletedBackground) {
                apollo.cache.evict({
                    id: apollo.cache.identify({id}),
                });
                apollo.cache.gc();
            }
        });
    };

    const onDeleteBackgroundImage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setOpenDeleteDialog(true);
    };

    const handleCloseConfirmationDialog = () => {
        setShowDelete(false);
        setOpenDeleteDialog(false);
    };

    return (
        <div className={cls(styles.filter, className, selected && "selected")}>
            <div className={cls(styles.filterImage)}>
                <img
                    src={url}
                    alt="CustomBackground"
                    onClick={onClick}
                    onMouseEnter={onMouseOverBackgroundImage}
                    onMouseOut={onMouseOutBackgroundImage}
                    className={cls(showDelete ? styles.filterImageWithDelete : "")}
                />

                {showDelete ? (
                    <span className={styles.deleteButtonWrapper} onMouseEnter={onMouseOverBackgroundImage}>
                        <IconButton size="medium" className={styles.deleteButton} onClick={onDeleteBackgroundImage}>
                            <DeleteIcon />
                        </IconButton>
                    </span>
                ) : null}
            </div>

            <ConfirmationDialog
                key="confirm-delete-virtual-background-image"
                title={t("player.settings.device.background_effects.delete_background")}
                content={<div className="pl-7">{t("player.settings.device.background_effects.delete_background_description")}</div>}
                cancelButton={t("g.cancel") ?? ""}
                minWidth
                submitButton={t("g.delete") ?? ""}
                onClose={handleCloseConfirmationDialog}
                open={openDeleteDialog}
                submitButtonVariant="destructive-secondary"
                onConfirm={handleDeleteBackgroundImage}
                variant="warning"
                isDeleteConfirmation
            />
        </div>
    );
};

export default ParticipantBackgroundCustomImageSelect;
