import {MutationOptions, QueryOptions, WatchQueryFetchPolicy} from "@apollo/client";
import {
    Exact,
    GetRemoteUserDocument,
    GetRemoteUserQuery,
    UpdateOneUserDocument,
    UpdateOneUserMutation,
    UserUpdateInput,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useQuery} from "@workhorse/api/data";

export async function loadRemoteUserFromDb(
    apolloQueryOptions?: Omit<
        QueryOptions<
            Exact<{
                [key: string]: never;
            }>,
            GetRemoteUserQuery
        >,
        "query"
    >
) {
    return apollo.client.query({
        query: GetRemoteUserDocument,
        fetchPolicy: "network-only",
        ...apolloQueryOptions,
    });
}

export const readRemoteUser = () => {
    return apollo.cache.diff<GetRemoteUserQuery>({
        query: GetRemoteUserDocument,
        optimistic: true,
        returnPartialData: true,
    }).result;
};

/**
 *
 * Updates the user stored in the apollo cache
 * * one of `user` or `getRemoteUser` MUST be provided
 * * both of which can be just partial datasets
 * * if `getRemoteUser` is provided, the entire `getRemoteUser` object will be merged
 * * if `user` is provided, only `getRemoteUser.user` will be updated
 * * if both `user` and `getRemoteUser` are provided, `getRemoteUser` takes precedence
 *
 * @param user
 * @param getRemoteUser
 * @returns
 */
export const updateCachedRemoteUser = (opts: {
    user?: Partial<NonNullable<GetRemoteUserQuery["getRemoteUser"]>["user"]>;
    getRemoteUser?: Partial<NonNullable<GetRemoteUserQuery["getRemoteUser"]>>;
}) => {
    const current = readRemoteUser();
    if (!current?.getRemoteUser.user?.id) {
        return;
    }

    apollo.client.writeQuery({
        query: GetRemoteUserDocument,
        data: {
            __typename: "Query",
            getRemoteUser: {
                ...current?.getRemoteUser,
                ...(opts.getRemoteUser ?? {
                    user: {
                        ...current?.getRemoteUser.user,
                        ...opts.user,
                    },
                }),
                updateTs: new Date(),
            },
        },
        broadcast: true,
    });
};

export const useRemoteUser = (
    options: {
        returnPartialData?: boolean;
        fetchPolicy?: WatchQueryFetchPolicy;
        nextFetchPolicy?: WatchQueryFetchPolicy;
    } = {}
) => {
    const remoteUser = useQuery(GetRemoteUserDocument, {
        ...options,
    });

    return remoteUser;
};

export const updateRemoteUser = async (
    data: UserUpdateInput,
    options?: Omit<MutationOptions<UpdateOneUserMutation>, "mutation" | "variables">
) => {
    const res = await apollo.client.mutate({
        mutation: UpdateOneUserDocument,
        variables: {data},
        ...options,
    });

    if (res.data?.updateOneUser) {
        updateCachedRemoteUser({user: res.data?.updateOneUser});
    }

    return res;
};
