import {useSessionInfo} from "@workhorse/api/conference2";
import {useEffect} from "@workhorse/api/rendering";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";

type Props = {
    setIsOnboarding: (value: boolean | null) => void;
};

const PaletteContentSessionInfoGetter = (props: Props) => {
    const session = useSessionInfo();

    useEffect(() => {
        if (session.onboardingType) {
            props.setIsOnboarding(session.onboardingType === SessionOnboardingType.NewUser);
        }
    }, [props, session.onboardingType]);

    return <></>;
};

export default PaletteContentSessionInfoGetter;
