// @ts-nocheck Remove comment and check when needed

import {getHelpCenterArticleLink} from "@workhorse/api/help/article";

export const onboardingTerms: {key: string; title: string; body: string | JSX.Element}[] = [
    {
        key: "terms-and-conditions",
        title: "Terms and Conditions",
        body: (
            <>
                <p>
                    <strong>Last updated on: (15 January 2024)</strong>
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p className="text-center">
                                    <strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING SESSIONS </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>
                    Welcome to Sessions, the platform that helps teams drive better customer engagement through video collaboration.
                    Sessions is provided by <strong>Sessions Technologies Inc</strong>, and its affiliates
                </p>
                <p>
                    These terms &amp; conditions (the &ldquo;<strong>Terms</strong>&rdquo;), serve as a legal binding agreement between the
                    User (as defined below) and Sessions which govern the User&rsquo;s and its Affiliates&rsquo; license to access and use
                    our website: sessions.us and our app and/or platform at https://app.sessions.us Sessions ( the &bdquo;
                    <strong>Platform</strong>&rdquo;) and any other associated services provided by us directly, (collectively, the &ldquo;
                    <strong>Services</strong>&rdquo;). These terms apply regardless of the pricing plan you choose BY CLICKING/CHECKING THE
                    &ldquo;I AGREE&rdquo; BUTTON/BOX, ACCESSING THE SESSIONS WEBSITE OR BY UTILIZING THE PLATFORM THE APP AND/OR ANY OTHER
                    RELATED SERVICES YOU AGREE/ACCEPT TO BE BOUND BY THESE TERMS AND CONDITIONS AND INCORPORATED POLICIES.
                </p>
                <p>
                    By accepting these Terms, You: (1) agree to this Terms on behalf of the organization, business, or other legal entity
                    for which you act (&ldquo;User,&rdquo; &ldquo;you,&rdquo; or &ldquo;your&rdquo;); and (2) represent and warrant that you
                    have the authority to bind User to this Agreement. If you do not have such authority, or if you do not agree with this
                    Agreement, you must not accept this Agreement and may not use the Services. You will be considered a User even if you
                    access the Services for personal use only, (i.e. not in connection with an organization, business, or other legal
                    entity). If you are under age 16, you may only use the Services with the consent of your parent or legal guardian.
                    Please be sure your parent or legal guardian has reviewed and discussed these Terms with you.
                </p>
                <p>
                    If you are accessing or using the Services on behalf of an Organization, then your organization is legally and
                    financially responsible for your access to and use of the Service as well as for the use of your Sessions account by
                    others affiliated with the organization, including any employees, agents or contractors. For the avoidance of doubt, the
                    Organization, company, or other legal entity for which you act will be considered the &ldquo;User&rdquo; under this
                    Terms.
                </p>
                <p>
                    As part of these Terms, you agree to comply with the most recent version of our{" "}
                    <a href="https://resources.sessions.us/resources/help-center/knowledge-base/terms-privacy/acceptable-use-policy">
                        Acceptable Use Policy
                    </a>{" "}
                    and our{" "}
                    <a href="https://resources.sessions.us/resources/help-center/knowledge-base/terms-privacy/privacy-policy">
                        Privacy Policy
                    </a>
                    , which are incorporated by reference into these Terms. If you access or use the Services, or continue accessing or
                    using the Services after being notified of a change to the Terms, Privacy Policy or the Acceptable Use Policy, you
                    confirm that you have read, understand and agree to be bound by the Terms, Privacy Policy and the Acceptable Use Policy.
                </p>
                <p>
                    If you do not agree with these Terms, the Acceptable Use Policy, the Privacy Policy or any other policy referred to
                    herein, do not access or use the Services. For the use of the Services, it is necessary to read and accept these terms
                    and conditions in full. We will provide the Services, and you may access and use the Services in accordance with these
                    Terms.
                </p>
                <p>
                    We do not guarantee that our Platform, or any content on it, will always be available or be uninterrupted. We may
                    suspend or withdraw or restrict the availability of all or any part of our Platform for business and operational
                    reasons. We will try to give you reasonable notice of any suspension or withdrawal.
                </p>
                <h2>
                    <strong>1. Definitions</strong>
                </h2>
                <p>In these Terms the following terms will be used for references:</p>
                <ul>
                    <li>
                        <p>
                            &rdquo;<strong>we&rdquo;, &rdquo;us&rdquo;, &rdquo;Sessions&rdquo;, </strong>will refer collectively to{" "}
                            <strong>Sessions Technologies Inc </strong>and our existing Affiliates
                        </p>
                    </li>
                    <li>
                        <p>
                            an <strong>&ldquo;Affiliate&rdquo; </strong>in these Terms, means any entity that directly or indirectly
                            controls or is controlled by, or is under common control with, the party specified. For purposes of this
                            definition, &ldquo;control&rdquo; means direct or indirect ownership of more than fifty percent (50%) of the
                            voting interests of the subject entity.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>you&rdquo;, &rdquo;your&rdquo; </strong>will refer to the User (either with a paid or a free
                            subscription plan) acting either on behalf of an organization or on behalf of yourself. A User can be the Host,
                            the Assistant, Moderator, Attendee, Guest or any type of participant to a Session or , who uses the Services.
                            Details about these roles can be found in the Help center section Roles in a Meeting. A paying User will be
                            referred to as Customer.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Special license&rdquo;</strong> refers to licenses granted by us, from time to time, to early
                            adopters, power users or brand ambassadors, including but not limited to ProLdt License, Pro Ambassador, ProLdt
                            Saaszilla, Pro
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Session&rdquo; </strong>will refer to the specific meeting organized and hosted using Our
                            Platform.
                        </p>
                    </li>
                    <li>
                        <p>
                            &ldquo;<strong>Subscription Plan&rdquo;</strong> means the applicable subscription level of packaged
                            functionality and services as detailed on Session&rsquo;s website.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Initial Subscription Term&rdquo;</strong>: if you have chosen a monthly billing cycle, 1 month.
                            If you have chosen an annual billing cycle, 1 year.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Renewal Period&rdquo;: </strong>the period described in clause 3.1.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Subscription Fees&rdquo;</strong>: the subscription fees payable by the Customers for accessing
                            our Services, as set out in our pricing and subscription information pages.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Subscription Term&rdquo;:</strong> the Initial Subscription Term together with any subsequent
                            Renewal Periods.
                        </p>
                    </li>
                    <li>
                        <p>
                            &rdquo;<strong>Workspace&rdquo; </strong>means a digital storage area created with your account on our Platform
                            from where you can invite members, create and share agendas and resources with the members;
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>2. General Rules</strong>
                </h2>
                <p>
                    2.1. <u>System requirements.</u>
                </p>
                <p>
                    Use of the Services requires one or more compatible devices, Internet access, and certain software, and may require
                    obtaining updates or upgrades from time to time. Because use of the Services involves hardware, software, and Internet
                    access, Your ability to access and use the Services may be affected by the performance of these factors. High speed
                    Internet access is recommended. You acknowledge and agree that such system requirements, which may be changed from time
                    to time, are Your responsibility.
                </p>
                <p>
                    2.2. <u>No relationship between the Platform and the Users.</u>
                </p>
                <p>
                    There is no connection between the Platform and the Users and no affiliation herein. The Users are solely responsible
                    for the content and activities they perform on the Platform. Any kind of disputes will be settled between the Users,
                    without the Platform&rsquo;s interference.
                </p>
                <h2>
                    <strong>3. Subscriptions</strong>
                </h2>
                <p>
                    <em>
                        Brief: This section outlines the options available to users, permitting both free and paid access through
                        Subscription Plans billed on a recurring basis. It clarifies the automatic renewal process and the ability for
                        existing users before December 2023 to have a default Free Subscription. Additionally, it details the 14-day free
                        trial, allowing users to cancel before it ends to avoid automatic enrollment into a paid plan. The policy also
                        covers upgrading or downgrading Subscriptions, changes in billing frequency, and prorated charges. Payment
                        processing is handled securely through Paddle, with no direct access to user payment information by Sessions.
                        Cancellation of Subscription renewals does not result in refunds for the current period. Refunds for paid
                        Subscriptions are generally non-applicable,
                    </em>
                </p>
                <p>
                    <strong>3.1. Free or paid Services</strong>
                </p>
                <p>
                    You can use our Services for free or as a paid service (&rdquo;Paid Service&rdquo;), by purchasing a Subscription Plan.
                </p>
                <p>
                    You will be billed in advance on a recurring and periodic basis (such as monthly or annually), depending on the type of
                    Subscription plan you select when purchasing the Subscription.
                </p>
                <p>
                    All specifications regarding Subscription are visible on the pricing page available{" "}
                    <a href="https://sessions.us/pricing#tablePricing">here</a>. You understand and agree that we can discretionary change
                    the level of packaged functionality and services for each Subscription plan. You will be informed if this happens.
                </p>
                <p>
                    At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel
                    it or we cancel it.
                </p>
                <p>
                    Please keep in mind that only users with admin powers within the organization have the right to activate, modify cancel
                    or in any way operate a Subscription plan
                </p>
                <p>
                    <strong>3.2. Ongoing Subscriptions </strong>
                </p>
                <p>
                    Except for the Users owning a Special License, all the existing and registered users at the date of launching the
                    Subscription plans (December 2023) will by default be considered the owners of a Free Subscription. No credit card is
                    necessary for having and using a free subscription.
                </p>
                <p>
                    <strong>3.3 Free Trial Period</strong>
                </p>
                <p>
                    Each Subscription plan has a free trial of 14 days. In order to access the free trial you must activate the Subscription
                    plan and introduce the payment details.
                </p>
                <p>
                    Users can cancel their free trial at any time during the trial period to avoid automatic enrollment into a paid
                    subscription. Cancellation within the trial period will not result in any charges and You will be able to access the
                    Subscription plan until the end of Your free trial.
                </p>
                <p>
                    If the user does not cancel the trial before the expiration date, they will be automatically enrolled in a paid
                    Subscription plan unless otherwise specified.
                </p>
                <p>
                    Sessions reserves the right to refuse, suspend, or terminate any free trial offer or account at its discretion,
                    particularly in cases of suspected abuse or violation of terms.
                </p>
                <p>
                    <strong>3.4 Upgrade &amp; Downgrade Subscriptions</strong>
                </p>
                <ul>
                    <li>
                        <p>
                            Each User may upgrade to the next level of User Subscriptions or add further services from your account settings
                            and by paying the additional fees. You can also downgrade your Subscription plan.
                        </p>
                    </li>
                    <li>
                        <p>
                            If the two plans you're moving a subscription between have the same billing frequency (eg. both are monthly),
                            you will retain the same billing dates. If the plans do not have the same billing frequency (eg. you move from
                            monthly to yearly billing), the plan will be billed at a new interval immediately, starting on the date of the
                            change. As an example: You switch from one monthly plan to another, the billing date does not change. However,
                            if you switch from a monthly to an annual plan, you will be billed immediately, and the annual billing date will
                            be recalculated from the day of the switch (date of plan switch + 1 year).
                        </p>
                    </li>
                    <li>
                        <p>
                            If the change moves you into a situation where a payment is required (for example moving to a more expensive
                            plan), you will automatically be charged with the additional payment.
                        </p>
                    </li>
                    <li>
                        <p>
                            When changing plans we will by default prorate the subscription costs. For example, if you are on a $10 monthly
                            plan and are billed on the 1st of every month, you execute a plan change half way through the billing cycle (in
                            this case, mid-month on a monthly cycle - eg. the 15th of the 30-day month). In this case, you've already paid
                            $10 and used half of that (because you were in the middle of the month). When you switch to the new plan which
                            has a price of $30.00:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    The unused portion of your current billing cycle is applied as a credit, in this case: $10 * (15 days
                                    unused / 30 days total) = $5.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The cost of the new plan for the remaining billing cycle (ie. the outstanding 15 days left in the month)
                                    is a debit of: $30 * (15 days remaining / 30 days total) = $15.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The final prorated cost of switching from the Basic to the Pro plan is: +$5 credit - $15 debit = -$10
                                    charge.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Depending on whether you are set to be billed immediately, you will either be charged $10 instantly, or
                                    this amount will be added onto their upcoming recurring payment amount. If you are not billed
                                    immediately, the next billed amount would include the prorated charge with the payment required on the
                                    new plan for the upcoming month. Thus the next billed amount would be for a total of $40 ($30 for their
                                    upcoming billing interval, and $10 for the prorated amount from the plan change)
                                </p>
                            </li>
                            <li>
                                <p>
                                    Since this example is a monthly-to-monthly plan change, it would not alter the billing date or cycle
                                    even if the customer is billed immediately.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    <strong>3.5 Billing. </strong>
                </p>
                <p>
                    To handle payment details securely, we utilize Paddle (the &ldquo;Payment Processor&rdquo;) as our payment processing
                    platform which means we never directly handle your payment details.{" "}
                    <strong>We do not have access to any of your payment data.</strong> Paddle manages all aspects related to subscription
                    payments. Paddle adheres to stringent data privacy and security standards, having successfully completed a Service
                    Organization Control (SOC) 2 Type 2 audit for 2023. Additionally, Paddle is compliant with GDPR and PCI DSS SAQ A
                    requirements. For more information about Paddle, please read <a href="https://www.paddle.com/">here</a>.
                </p>
                <p>
                    The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in
                    addition to these Terms. We are not responsible for errors by the Payment Processor. By choosing to use Paid Services,
                    you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid
                    Services in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to charge
                    your chosen payment provider. We reserve the right to correct any errors or mistakes that the Payment Processor makes
                    even if it has already requested or received payment.
                </p>
                <p>
                    <strong>3.6 Subscription cancellations </strong>
                </p>
                <p>
                    You may cancel Your Subscription renewal either through your account settings page or by contacting us. You will not
                    receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the
                    Service until the end of Your current Subscription period.
                    <br /> If you decide to cancel the renewal, you should cancel your subscription prior to the next billing term. Once
                    subscription is canceled we are eligible to downgrade the user to free plan and if there are storage limits that exceeds
                    that available for the free plan, we can delete items in order to meet the data storage requirements of the free plan.
                </p>
                <p>
                    <strong>3.7 Refunds</strong>
                </p>
                <p>Except when required by law, paid Subscription fees are non-refundable.</p>
                <p>
                    Certain refund requests for Subscriptions may be considered by us on a case-by-case basis and granted at our sole
                    discretion.
                </p>
                <p>For any questions or concerns regarding a payment made to Sessions, please, contact our Customer Support</p>
                <p>We have no obligation to provide a refund or credit for reasons including but not limited to, if you:</p>
                <ol>
                    <li>
                        <p>have changed your mind about a purchased Service.</p>
                    </li>
                    <li>
                        <p>bought our Services by mistake.</p>
                    </li>
                    <li>
                        <p>do not have sufficient expertise to use the Services.</p>
                    </li>
                    <li>
                        <p>do not have any need to use the Services</p>
                    </li>
                </ol>
                <h2>
                    <strong>4. Integration with other Apps</strong>
                </h2>
                <p>
                    <em>
                        Brief: Sessions is an all-in-all platform which allows you to have meetings and display other elements in the same
                        working space where viewers can see them without going through the hassle of changing screen and other apps. In this
                        section you will find how Sessions integrates with other Apps so you can deliver the most amazing sessions.
                    </em>
                </p>
                <ul>
                    <li>
                        <p>
                            Sessions can embed certain links in different tabs. It does so by <em>framing</em> the content, which will be
                            delivered &bdquo;as it is&rdquo;. Please take into consideration that certain websites or certain video can be
                            protected by their owner and you will need to ask for their permission in order to embed the content in
                            Sessions.
                        </p>
                    </li>
                    <li>
                        <p>
                            Sessions does not download any content from the links you provide to us and does not change any kind of privacy
                            policies you might have in place for those links.
                        </p>
                    </li>
                    <li>
                        <p>
                            If Sessions will allow you to login in a certain app (such as Miro), the login will be made on that app and not
                            on Sessions. Sessions will not, at any time, see your username, password, or any other kind of personal
                            information, nor will it save it.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>5. Recordings</strong>
                </h2>
                <p>
                    <em>
                        Brief: Sessions can allow the recording of the entire meeting. In this section, you will find out what does that
                        mean and how it can impact you.
                    </em>
                </p>
                <ul>
                    <li>
                        <p>
                            Only specific roles can record a session or set the meeting to be automatically recorded. Any other person does
                            not have the right to record the meeting. By using the Services, you are giving Session consent to store
                            recordings for any or all sessions that you join, if such recordings are stored in our systems.
                        </p>
                    </li>
                    <li>
                        <p>
                            For more information about recording please consult the{" "}
                            <a href="https://resources.sessions.us/resources/help-center">💁 Help Center</a>
                        </p>
                    </li>
                    <li>
                        <p>
                            If you choose to download the recording, you are responsible for storing and processing it according to all
                            recording laws and data processing regulations.
                        </p>
                    </li>
                    <li>
                        <p>
                            When a Session is recorded, you will be notified of this and the &rdquo;recording&rdquo; button will be visible.
                            If you do not consent to being recorded, you can choose to leave the session.
                        </p>
                    </li>
                    <li>
                        <p>
                            The Host is solely responsible for gathering the Attendees&rsquo;s consent for recording and complying with all
                            applicable laws and regulations.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                Sessions is not responsible for any kind of damage, loss, intent of loss or any other prejudice that might
                                occur from the act of recording and/or the use of recordings. You acknowledge and understand that the sole
                                responsible for the recorded content is the Host and any kind of solicitation must be addressed to the Host
                                and not to Sessions.
                            </strong>
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>6. Disputes</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            We are not responsible for any kind of prejudice that might occur from the participants interaction within a
                            Session or within the Workspace .
                        </p>
                    </li>
                    <li>
                        <p>
                            Each User is responsible for the content they will upload and present during a session or within the Workspace,
                            as well as for any other kind of interactions that will take place between with the other Users.
                        </p>
                    </li>
                    <li>
                        <p>
                            If you participated in a paid session, all refund requests as well as any other solicitations must be addressed
                            to the Host.
                        </p>
                    </li>
                    <li>
                        <p>
                            You should check all the details of a Session before attending one. In case you are not sure about specific
                            points, you may get into contact with the Host and ask for further details.{" "}
                            <strong>
                                Please be aware that We do not have any kind of responsibility for the sessions organized and hosted by the
                                Host or for the content made available by any User on the Platform.
                            </strong>
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>7. How you may use the material on the Platform</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            We are the owner or the licensee of all intellectual property rights contained in the material published on our
                            website and Platform. Those works are protected by copyright laws and treaties around the world. All such rights
                            are reserved.
                        </p>
                    </li>
                    <li>
                        <p>
                            You may download for your personal use the information shared with you during a session or within the Workspace,
                            according to the instructions provided by the Host/Admin and according to these Terms. You must always keep the
                            downloaded information safe and not share it with anybody else unless you have the express clearance from the
                            user who uploaded such information .
                        </p>
                    </li>
                    <li>
                        <p>
                            You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way,
                            and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from
                            any accompanying text. For the content provided by the other Users during the session or within the Workspace,
                            you must ask for their consent before modifying or altering in any way the content provided.
                        </p>
                    </li>
                    <li>
                        <p>
                            If you choose to record the Session you host or attend, on your own, by using external tools (such as screen
                            recorders), then You are solely responsible for complying with all recording laws and data processing
                            regulations. Sessions is not responsible for any kind of damage, loss, intent of loss or any other prejudice
                            that might occur from the act of recording and/or the use of recordings
                        </p>
                    </li>
                    <li>
                        <p>
                            You must not use any part of the content on our site for commercial purposes without obtaining a license to do
                            so from us or our licensors.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>8. Availability of the Platform</strong>
                </h2>
                <p>
                    We&rsquo;ll do our best to be available 24 hours a day, seven days a week and 365 days a year. However, you agree that
                    Sessions may, in its absolute discretion, and for certain reasons and at any time, with or without notice interrupt or
                    suspend your right to access the whole or any part of the Services.
                </p>
                <p>
                    Sessions may also interrupt or suspend the provision of the whole or any part of the Services to Users whom Sessions
                    reasonably believes have infringed upon other users&rsquo; rights or violated the terms of this Agreement.
                </p>
                <h2>
                    <strong>9. We are not responsible for the websites we link to</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            Where our Platform contains links to other sites and resources provided by third parties, these links are
                            provided for your information only. Such links should not be interpreted as approval by us of those linked
                            websites or information you may obtain from them.
                        </p>
                    </li>
                    <li>
                        <p>We have no control over the contents of those sites or resources.</p>
                    </li>
                </ul>
                <h2>
                    <strong>10. User-generated content is not approved by us</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            This Platform may include information and materials uploaded by other Users of the Platform. This information
                            and these materials have not been verified or approved by Us. The views expressed by other users on our Platform
                            do not represent our views or values.
                        </p>
                    </li>
                    <li>
                        <p>
                            The content you, as a User, share or use within the Platform is your responsibility. We do not verify this
                            content and cannot interfere with it. During a session, the Host is solely responsible for the content the
                            participants are sharing. In case you consider the content to be offensive or in any other kind infringing,
                            please contact the Host first-hand and express the situation. If the Host does not handle the situation in a
                            manner you are satisfied, you may contact Us in order to further investigate the issue.
                        </p>
                    </li>
                    <li>
                        <p>Any kind of content you or others share or post on the Platform, is your responsibility.</p>
                    </li>
                </ul>
                <h2>
                    <strong>11. Your Content</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            Some of our Services are designed to let you upload, submit, store, send, receive, or sharetext, files, images,
                            photos, video, sounds, software, works of authorship, or other materials ("Content".)
                        </p>
                    </li>
                    <li>
                        <p>
                            You have no obligation to provide any Content to our services and you&rsquo;re free to choose the content that
                            you want to provide. If you choose to upload or share Content, please make sure you have the necessary rights to
                            do so and that the content is lawful. For example, if you choose to use a specific logo in the Custom branding
                            section or in My product section, you confirm that you have the right to use and upload such logo.
                        </p>
                    </li>
                    <li>
                        <p>
                            You agree that You are solely responsible for the Content sent or transmitted by You or displayed or uploaded by
                            You in using the Services and for compliance with all Laws pertaining to the Content, including, but not limited
                            to, Laws requiring You to obtain the consent of a third party to use the Content and to provide appropriate
                            notices of third party rights. You represent and warrant that You have the right to upload the Content to
                            Sessions and that such use does not violate or infringe on any rights of any third party.
                        </p>
                    </li>
                    <li>
                        <p>
                            Under no circumstances will We be liable in any way for any (a) Content that is transmitted or viewed while
                            using the Services, (b) errors or omissions in the Content, or (c) any loss or damage of any kind incurred as a
                            result of the use of, access to, or denial of access to Content. Although we are not responsible for any
                            Content, we may delete any Content, at any time without notice to You, if we become aware that it violates any
                            provision of this Agreement, or any law. You retain copyright and any other rights You already hold in Content
                            which You submit, post or display on or through, the Services.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>12. Ownership, use and intellectual property rights</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            The intellectual property rights in the Platform and in any text, images, video, audio or other multimedia
                            content, software or other information or material submitted to or accessible from the Platform are owned by us
                            and our licensors.
                        </p>
                    </li>
                    <li>
                        <p>
                            We and our licensors reserve all our intellectual property rights (including, but not limited to, all copyright,
                            trade marks, domain names, design rights, database rights, patents and all other intellectual property rights of
                            any kind) whether registered or unregistered anywhere in the world. This means, for example, that we remain
                            owners of them and are free to use them as we see fit.
                        </p>
                    </li>
                    <li>
                        <p>
                            Nothing in these Terms grants you any legal rights in the Platform or the other than as necessary for you to
                            access it. You agree not to adjust, try to circumvent or delete any notices contained on the Platform (including
                            any intellectual property notices) and in particular, in any digital rights or other security technology
                            embedded or contained within the Platform.
                        </p>
                    </li>
                    <li>
                        <p>
                            The content provided by any User during a session or within the Workspace, including the material and
                            information posted in the Resources area are subject to the such User's intellectual property rights. If you
                            would like to use, alter, modify and further amend those materials, you should first get in contact with the
                            user r and ask for specific permission.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Our permission to use your Content</u>. Your content remains yours, which means that you retain any
                            intellectual property rights that you have in your Content. However, some Sessions functionalities imply the use
                            of your content. Therefore, by accepting these Terms you give us the permission to use your content through a
                            worldwide, royalty-free, sublicensable, perpetual, license to use, modify, publicly perform, publicly display,
                            reproduce, and distribute your Content
                            <u>
                                <strong>for the limited purpose of providing the services</strong>
                            </u>
                            <u>.</u> This license: (a) only covers your intellectual property rights and shall not apply to any
                            publicly-available factual information, (b) allows us to host, reproduce, distribute, communicate, and use your
                            content according to these Terms and other applicable policies &mdash; for example, to save your content in
                            Session&rsquo;s memory, (c) allows Us to publish Your Content if you made it available to others. If you remove
                            your protected Content from our Services, we will stop making it available to the public provided that you did
                            not already share your content with other users before removing it (in this case, even though we remove the
                            content from your account, we can't control how the other user uses/used your content).
                        </p>
                    </li>
                </ul>
                <h3>
                    <strong>12.1. Prohibited Use</strong>
                </h3>
                <ul>
                    <li>
                        <p>
                            You agree that You will not use, the Services to: (i) modify, disassemble, decompile, prepare derivative works
                            of, reverse engineer or otherwise attempt to gain access to the source code of the Services; (ii) knowingly or
                            negligently use the Services in a way that abuses, interferes with, or disrupts our networks, Your accounts, or
                            the Services; (iii) engage in activity that is illegal, fraudulent, false, or misleading, (iv) transmit through
                            the Services any material that may infringe the intellectual property or other rights of third parties or Flow;
                            (v) build or benchmark a competitive product or service, or copy any features, functions or graphics of the
                            Services; or (vi) use the Services to communicate any message or material that is harassing, libelous,
                            threatening, obscene, indecent, would violate the intellectual property rights of any party or is otherwise
                            unlawful, that would give rise to civil liability, or that constitutes or encourages conduct that could
                            constitute a criminal offense, under any applicable law or regulation; (vii) upload or transmit any software,
                            Content or code that does or is intended to harm, disable, destroy or adversely affect performance of the
                            Services in any way or which does or is intended to harm or extract information or data from other hardware,
                            software or networks of Flow or other users of Services; (viii) engage in any activity or use the Services in
                            any manner that could damage, disable, overburden, impair or otherwise interfere with or disrupt the Services,
                            or any servers or networks connected to the Services or Flow's security systems. (ix) use the Services in
                            violation of any Flow policy or in a manner that violates applicable law, including but not limited to
                            anti-spam, export control, privacy, and anti-terrorism laws and regulations and laws requiring the consent of
                            subjects of audio and video recordings, and You agree that You are solely responsible for compliance with all
                            such laws and regulations.
                        </p>
                    </li>
                </ul>
                <h3>
                    <strong>12.2. Limitations on Use.</strong>
                </h3>
                <ul>
                    <li>
                        <p>
                            You may not reproduce, resell, or distribute the Services or any reports or data generated by the Services for
                            any purpose unless You have been specifically permitted to do so under a separate agreement with us. You may not
                            offer or enable any third parties to use the Services purchased by You, display on any website or otherwise
                            publish the Services or any Content obtained from a Service (other than Content created by You) or otherwise
                            generate income from the Services or use the Services for the development, production or marketing of a service
                            or product substantially similar to the Services.
                        </p>
                    </li>
                    <li>
                        <p>
                            As a Sessions User, you have the ability to add your clients as team members within dedicated Workspaces,
                            provided that such action complies with fair usage policies set forth by Sessions. Fair usage is determined at
                            the discretion of Sessions and should align with reasonable and customary utilization for collaborative purposes
                            within the platform.
                        </p>
                    </li>
                </ul>
                <h3>
                    <strong>12.3. COPYRIGHT</strong>
                </h3>
                <ul>
                    <li>
                        <p>
                            You may not post, modify, distribute, or reproduce in any way copyrighted material, trademarks, rights of
                            publicity or other proprietary rights without obtaining the prior written consent of the owner of such
                            proprietary rights. We may deny access to the Services to any Customer who is alleged to infringe Session or
                            another party's copyright.
                        </p>
                    </li>
                    <li>
                        <p>
                            We retain ownership of all proprietary rights in the Services and in all trade names, trademarks, service marks,
                            logos, and domain names associated or displayed with the Services.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>13. We are not responsible for loss or damage suffered by you</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            Whether you are a consumer or a business user, we do not exclude or limit in any way our liability to you where
                            it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or
                            the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
                        </p>
                    </li>
                    <li>
                        <p>
                            We exclude all implied conditions, warranties, representations, or other terms that may apply to our Platform or
                            any content on it.
                        </p>
                    </li>
                    <li>
                        <p>
                            We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of
                            statutory duty, or otherwise, even if foreseeable, arising under or in connection with: ◦ use of, or inability
                            to use, our site; or ◦ use of or reliance on any content displayed on our site.
                        </p>
                    </li>
                </ul>
                <p>In particular, we will not be liable for:</p>
                <ul>
                    <li>
                        <p>loss of profits, sales, business, or revenue;</p>
                    </li>
                    <li>
                        <p>business interruption;</p>
                    </li>
                    <li>
                        <p>loss of anticipated savings;</p>
                    </li>
                    <li>
                        <p>loss of business opportunity, goodwill or reputation; or any indirect or consequential loss or damage.</p>
                    </li>
                </ul>
                <h2>
                    <strong>14. How we may use your personal information</strong>
                </h2>
                <p>
                    We will only use your personal information as set out in our{" "}
                    <a href="https://resources.sessions.us/resources/help-center/knowledge-base/terms-privacy/privacy-policy">
                        🔎 Privacy Policy
                    </a>
                </p>
                <p>.</p>
                <h2>
                    <strong>15. Uploading content to our Platform</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            Whenever you make use of a feature that allows you to upload content to our Platform, or to make contact with
                            other users of our Platform, you must comply with the content standards set out in our{" "}
                            <a href="https://resources.sessions.us/resources/help-center/knowledge-base/terms-privacy/acceptable-use-policy">
                                📄 Acceptable Use Policy
                                <br />
                            </a>
                            .
                        </p>
                    </li>
                    <li>
                        <p>
                            You warrant that any such contribution does comply with those standards, and you will be liable to us and
                            indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we
                            suffer as a result of your breach of warranty.
                        </p>
                    </li>
                    <li>
                        <p>
                            Any content you upload to our Platform will be considered non-confidential and non-proprietary. You retain all
                            of your ownership rights in your content, but you are required to grant us and other users of our Platform, as
                            the case may be, a limited license to use, store and copy that content and to distribute and make it available
                            to third parties according to the Terms.
                        </p>
                    </li>
                    <li>
                        <p>
                            We also have the right to disclose your identity to any third party who is claiming that any content posted or
                            uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to
                            privacy.
                        </p>
                    </li>
                    <li>
                        <p>You are solely responsible for securing and backing up your content.</p>
                    </li>
                    <li>
                        <p>We do not store terrorist content.</p>
                    </li>
                </ul>
                <h2>
                    <strong>16. We are not responsible for viruses and you must not introduce them</strong>
                </h2>
                <ul>
                    <li>
                        <p>We do not guarantee that our Platform will be secure or free from bugs or viruses.</p>
                    </li>
                    <li>
                        <p>
                            You are responsible for configuring your information technology, computer programmes and platform to access our
                            Platform. You should use your own virus protection software.
                        </p>
                    </li>
                    <li>
                        <p>
                            You must not misuse our Platform by knowingly introducing viruses, trojans, worms, logic bombs or other material
                            that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the
                            server on which our site is stored or any server, computer or database connected to our site. You must not
                            attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this
                            provision, you would commit a criminal offence. We will report any such breach to the relevant law enforcement
                            authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of
                            such a breach, your right to use our site will cease immediately.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>17. We are not responsible for events outside our control</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            If the provisioning of the Services is delayed by an event outside our control, we will contact you as soon as
                            reasonably possible to let you know and we will take steps to minimize the effect of the delay. Provided we have
                            acted reasonably in letting you know about the delay where it is possible for us to do so, we will not be liable
                            for delays caused by the event.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>18. We are not responsible for third party websites and services</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            The Services may contain links or referrals to third party and other independent websites and services which are
                            not provided by us. Such websites and services are not under our control, and we are not responsible for and
                            have not checked and approved their content or their privacy policies (if any). You will need to make your own
                            independent judgment about whether to use them, including whether to buy any products or services offered by
                            them
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>19. Rules about linking to our Platform</strong>
                </h2>
                <ul>
                    <li>
                        <p>
                            You may link to our home page, provided you do so in a way that is fair and legal and does not damage our
                            reputation or take advantage of it.
                        </p>
                    </li>
                    <li>
                        <p>
                            You must not establish a link in such a way as to suggest any form of association, approval or endorsement on
                            our part where none exists.
                        </p>
                    </li>
                </ul>
                <h2>
                    <strong>20. Other Terms</strong>
                </h2>
                <p>
                    20.1.{" "}
                    <u>
                        <strong>Governing Law &amp; Jurisdiction</strong>
                    </u>
                    <u>.</u> All matters arising out of or relating to these Terms, are governed by, and construed in accordance with, the
                    laws of the State of New York, United States of America, without regard to the conflict of laws provisions thereof to
                    the extent such principles or rules would require or permit the application of the laws of any jurisdiction other than
                    those of the State of New York.
                </p>
                <p>
                    Requirement to File Within One Year. Notwithstanding any other statute of limitations, a claim or dispute under these
                    Terms must be filled within one year of when it could first be filed, or such claim will be permanently barred.
                </p>
                <p>
                    20.2.{" "}
                    <u>
                        <strong>Open Source</strong>
                    </u>
                    . The Platform may include open source software, which is bound by its own licensing. Please refer to the software for
                    further information.
                </p>
                <p>
                    20.3.{" "}
                    <u>
                        <strong>Other Software</strong>
                    </u>
                    . In order to deliver the Services, the Platform may use other software that will be incorporated in the Platform.
                    Please refer to the specific software for further information about licensing and other specifications.
                </p>
                <p>
                    20.4.{" "}
                    <u>
                        <strong>Security</strong>
                    </u>
                    . You are responsible for configuring your devices for Platform access. You should use your own virus protection
                    software.
                </p>
                <p>
                    20.5.{" "}
                    <u>
                        <strong>Entire Agreement</strong>
                    </u>
                    . These Terms constitute the whole legal agreement between you and Flow and replace any prior applicable Terms and
                    Conditions that governed the service prior to the Last Updated date specified above.
                </p>
                <h2>
                    <strong>21. Changes to the Terms</strong>
                </h2>
                <ul>
                    <li>
                        <p>We may amend these Terms from time to time, for instance when we update the functionality of our Services.</p>
                    </li>
                    <li>
                        <p>
                            We will use commercially reasonable efforts to generally notify all users of any changes to these Terms, such as
                            through a notice on our Platform, however, you should look at the Terms regularly to check for such changes.
                        </p>
                    </li>
                    <li>
                        <p>
                            We will also update the &ldquo;Last Updated&rdquo; date at the top of these Terms, which reflect the effective
                            date of such Terms.
                        </p>
                    </li>
                    <li>
                        <p>
                            If you continue to access or use of the Services after the date of the new Terms this constitutes your
                            acceptance of the new Terms. If you do not agree to the new Terms, you must stop accessing or using the
                            Services.
                        </p>
                    </li>
                </ul>
            </>
        ),
    },
];
