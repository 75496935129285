import {AgendaItemType, SessionLifecycle} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import loadable from "@workhorse/api/loadable";
import {Fragment} from "@workhorse/api/rendering";
import {SessionData} from "@workhorse/declarations/dataTypes";
import Inject from "@workhorse/providers/Injector";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useAgendaItems, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import AgendaToggler from "../components/AgendaToggler/AgendaToggler";
import AddToolsInPlayerButton from "../components/PlayerTools/AddToolsInPlayerButton";
import classes from "./styles/FooterLobby.module.scss";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import LobbyFooterAddAgendaButton from "./LobbyFooterAddAgendaButton";

const LobbyFooterContent = loadable(() => import("./LobbyFooterContent"));

export type FooterLobbyProps = {
    session: SessionData;
    isAdmin: boolean;
    isAssistant: boolean;
    isSpeaker: boolean;
};

function FooterLobby(props: FooterLobbyProps) {
    const {isAdmin, isAssistant, session, isSpeaker} = props;
    const {lifecycle, allowAgendaType} = session;

    const {isLobby} = useIsLobby();

    const agendaItems = useAgendaItems();

    const mobileState = useMobile();
    const {isMobile, isMobileOrTablet} = mobileState;

    const noAgenda =
        !agendaItems.length ||
        (agendaItems.length == 1 &&
            (agendaItems[0].title == designer.constants.DUMMY_AGENDA_ITEM_TITLE || agendaItems[0].type === AgendaItemType.Instant));

    const allowAgenda = sessionFlagBasedPermission(allowAgendaType, {
        owner: isAdmin,
        assistant: isAssistant,
        speaker: isSpeaker,
    });
    const canSeeAgenda = noAgenda ? false : (allowAgenda || isAssistant || isAdmin) && !isMobile;

    if (lifecycle === SessionLifecycle.Started && !isLobby) {
        return null;
    }

    return (
        <Inject
            target="inFooter"
            id="lobby-footer"
            className={isMobile ? cls("flex with-content app-border in-lobby flex-center-all", classes.injectedComp) : ""}
        >
            <>
                <Fragment key="lobby-footer-content">{isMobile && <LobbyFooterContent {...props} />}</Fragment>
                <Fragment key="lobby-micro-controller">
                    {canSeeAgenda && !isMobile && (
                        <AgendaToggler
                            session={session}
                            inLobby={true}
                            isAdmin={isAdmin}
                            isAssistant={isAssistant}
                            mobileState={mobileState}
                        >
                            {/* {isAdmin || isAssistant ? (
                                <MicroControllerInjector
                                    session={session}
                                    isAssistant={isAssistant}
                                    mobileState={mobileState}
                                    inLobby={true}
                                />
                            ) : null} */}
                        </AgendaToggler>
                    )}
                </Fragment>
                {!isMobileOrTablet ? (
                    <Fragment key="lobby-footer-add-tools">
                        {noAgenda && (isAdmin || isAssistant) ? (
                            <LobbyFooterAddAgendaButton />
                        ) : (
                            <AddToolsInPlayerButton
                                hasMicroController={false}
                                isAssistant={isAssistant}
                                className={classes.shareButton}
                                isAdmin={isAdmin}
                                hideText={isMobileOrTablet}
                                session={session}
                            />
                        )}
                    </Fragment>
                ) : null}
            </>
        </Inject>
    );
}

export default FooterLobby;
