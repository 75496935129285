import environment from "@generated/environment";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/EventPayments.module.scss";
import {ReactComponent as AddPaymentIllustration} from "../../../../assets/media/add_payment.svg";

type Props = {
    onEnable: () => void;
    canEnable: boolean;
    hasRegistration?: boolean;
    eventId: string;
};

const EventPaymentsEmptyState = (props: Props) => {
    const {canEnable, hasRegistration} = props;

    const handleAddProvider = () => {
        window.open(`${environment.authService.originalAppRoot}/user/accounts`, "_blank");
    };

    return (
        <div className={cls("fullw flex flex-col flex11-auto flex-center-all mt-10")}>
            <div>
                <AddPaymentIllustration />
            </div>
            <Typography variant="xl" fontWeight="bolder" color="tertiary" className="mb-8">
                Add an entry fee for your event
            </Typography>
            {!hasRegistration ? (
                <Typography color="tertiary" variant="lg" className="mb-32">
                    Payments can be added to open registration event only. Enable registration form to use this feature.
                </Typography>
            ) : canEnable ? (
                <Typography color="tertiary" variant="lg" className="mb-32">
                    You can add your event collaborators as speakers or invite someone else
                </Typography>
            ) : (
                <Typography color="tertiary" variant="lg" className="mb-32">
                    In order to enable this feature you need to connect with a payment provider.
                </Typography>
            )}
            {!hasRegistration ? (
                <Link to={`/event/${props.eventId}/registration`}>
                    <Button variant="plain" data-id="add-agenda-button">
                        Enable registration form
                    </Button>
                </Link>
            ) : canEnable ? (
                <Button
                    variant="tertiary"
                    className={classes.tertiaryButton}
                    data-id="booking-button-add-payments"
                    onClick={props.onEnable}
                >
                    <AddRoundedIcon className="mr-4" /> Add payment
                </Button>
            ) : (
                <Button variant="plain" onClick={handleAddProvider} data-id="add-agenda-button">
                    <OpenInNewRoundedIcon className="mr-5" /> Connect payment provider
                </Button>
            )}
        </div>
    );
};

export default EventPaymentsEmptyState;
