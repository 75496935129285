import {v4 as uuidv4} from "uuid";
import {existingToasts} from "../providers/ToastProvider/ToastProvider";

type ToastPosition = "top" | "right" | "bottom" | "left";

type ToastOptions = {
    duration?: number;
    position?: ToastPosition;
    type?: "info" | "warning" | "error" | "success" | "networkIssue";
    permanent?: boolean;
    remove?: boolean;
    uid?: string;
    className?: string;
    title?: string;
    icon?: React.ReactNode;
    preventClose?: boolean;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type ToastObject = {content: string | React.ReactNode} & ToastOptions & {uid: string; timeout: any};

const toast = (content: string | React.ReactNode, options?: ToastOptions) => {
    const toastObject = Object.assign(
        {
            position: "top",
            type: "info",
            permanent: false,
            remove: false,
        },
        !options?.permanent && !options?.remove ? {duration: 3500} : {},
        options || {},
        {
            uid: options?.uid ?? uuidv4(),
            content,
            timeout: null,
        }
    );
    const existing = existingToasts();

    if (options?.remove) {
        existingToasts(
            existing.filter((t) => {
                if (t.uid === toastObject.uid) {
                    if (t.timeout) {
                        clearTimeout(t.timeout);
                    }
                }
                return t.uid !== toastObject.uid;
            })
        );
    } else {
        const toastExists = existing.find((o) => o.uid === toastObject.uid);
        if (!toastExists) {
            existingToasts(existing.concat(toastObject));
        }
    }
};

export default toast;
