import {ResourceProcessingStatus} from "@generated/data";
import {useEffect, useState} from "@workhorse/api/rendering";
import Converting from "./Converting";
import ProcessingFinishedConfirmation from "./ProcessingFinishedConfirmation";

type ConversionNotificationsProps = {
    isConfigured: boolean;
    processingStatus: ResourceProcessingStatus;
    progress?: boolean;
    inLobby?: boolean;
};

const ConversionNotifications = (props: ConversionNotificationsProps) => {
    const {isConfigured, progress, processingStatus} = props;

    const [showLoading, setShowLoading] = useState(progress);

    const cancelLoading = () => {
        setShowLoading(false);
    };
    return (
        <>
            {!isConfigured && processingStatus === ResourceProcessingStatus.Finished ? (
                <ProcessingFinishedConfirmation processingStatus={processingStatus} inLobby={props.inLobby} />
            ) : null}
            {progress && showLoading ? <Converting cancel={cancelLoading} /> : null}
        </>
    );
};

export default ConversionNotifications;
