import {DrawerState} from "@generated/data";
import {cls} from "@ui/cdk/util";
import {useCallback, useEffect, useState} from "@workhorse/api/rendering";
import useUnfocusedTabSoundNotification from "@workhorse/api/useUnfocusedTabSoundNotification";

import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {useDataEvent} from "@workhorse/dataApi";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useCurrentParticipant, useIsPreJoin} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {drawerRightActiveComponent} from "@workhorse/providers/inject";
import {useDrawerLeftToggler, useDrawerRightToggler} from "@workhorse/providers/state";
import PreJoinScreenHeader from "../header/PreJoinScreenHeader";
import {changeGuestDetails, provideSessionPasscode, requestPermissionToJoin} from "./api";
import PreJoinConferenceContainer from "./components/PreJoinConferenceContainer";
import PreJoinInfoContainer from "./components/PreJoinInfoContainer";
import {FormikValuesType} from "./components/PreJoinInfoForm";
import {PreJoinSettingsProvider} from "./providers/PreJoinSettingsProvider";
import classes from "./styles/PreJoinScreen.module.scss";
import PlayerKeyboardEvents from "../session-view-components/PlayerKeyboardEvents";
import SessionBackground from "../session-view-components/SessionBackground";
import {useTranslation} from "react-i18next";
import UILanguageUpdater from "../session-view-components/UILanguageUpdater";
import toast from "@workhorse/api/toast";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type PreJoinScreenProps = {
    session: SessionData;
    onSubmit: () => void;
    shouldPlaySound: boolean;
    showNetworkIssueMsg?: boolean;
    recordingActive: boolean;
    audioPublishingIsDisabled?: boolean;
    videoPublishingIsDisabled?: boolean;
};

const MAX_ATTEMPTS_COUNT = 3;

function PrejoinHookSetter() {
    const [, toggleDrawerRight] = useDrawerRightToggler();
    const [, toggleDrawerLeft] = useDrawerLeftToggler();
    const {setIsPreJoin} = useIsPreJoin();
    useEffect(() => {
        setIsPreJoin(true);
        toggleDrawerRight(DrawerState.Closed);
        toggleDrawerLeft(DrawerState.Closed);
        drawerRightActiveComponent(null);
        return () => {
            setIsPreJoin(false);
        };
    }, []);
    return <></>;
}

const PreJoinScreen = (props: PreJoinScreenProps) => {
    const {t} = useTranslation();
    const {session, onSubmit, shouldPlaySound, audioPublishingIsDisabled, videoPublishingIsDisabled} = props;
    const {isMobile} = useMobile();
    const isBreakoutChild = !!session.childOfBreakoutRooms;

    const {isPortrait} = useDeviceOrientation();
    const currentParticipant = useCurrentParticipant();

    const savedRequestPermissionFailedAttempts = Number(sessionStorage.getItem(`request-permission-denied-${session.id}`) ?? 0);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [requestState, setRequestState] = useState<"denied" | "requesting" | "none">("none");
    const [requestPermissionFailedAttempts, setRequestPermissionFailedAttempts] = useState<number>(savedRequestPermissionFailedAttempts);
    const [isProvidePasscodeFailed, setIsProvidePasscodeFailed] = useState<boolean>(false);

    const user = useUserInfo();
    const isGuest = user.isGuest;

    const isOwner = currentParticipant.isOwner;

    const {isAssistant} = useParticipantsStore(
        useShallow(({amIAssistant}) => ({
            isAssistant: amIAssistant,
        }))
    );

    const tooManyAttempts = requestPermissionFailedAttempts >= MAX_ATTEMPTS_COUNT;

    const isRequestPermissionToJoin =
        !isOwner && !isAssistant && session.requestPermissionToJoin && currentParticipant.isApproved === false;

    const isProvideSessionPasscode =
        !isOwner && !isAssistant && session.provideSessionPasscode && currentParticipant.submittedPasscode === false;

    useUnfocusedTabSoundNotification(shouldPlaySound);

    const handleUpdateGuestInfo = useCallback(
        async (values: FormikValuesType, doNotSubmit = false) => {
            const {name, rememberMe, email, consentedToRecord} = values;

            setIsSubmitting(true);

            await changeGuestDetails(session.id, name, session.askForGuestEmail ? email : undefined)
                .then((res) => {
                    if (res.data?.changeGuestDetails) {
                        if (!doNotSubmit) {
                            onSubmit();
                            setIsSubmitting(false);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setIsSubmitting(false);
                });

            if (rememberMe) {
                localStorage.setItem("guestUsername", name);
            } else if (localStorage.getItem("guestUsername")) {
                localStorage.removeItem("guestUsername");
            }

            localStorage.setItem("breakoutGuestDetails", JSON.stringify({parentSessionId: session.id, name, email}));

            return true;
        },
        [onSubmit, session.id, session.askForGuestEmail, isBreakoutChild]
    );

    const handleRequestPermission = async (values: FormikValuesType) => {
        setIsSubmitting(true);

        if (isGuest) {
            await handleUpdateGuestInfo(values, currentParticipant.isApproved ? false : true);
        }

        if (isProvideSessionPasscode) {
            const hasPermission = (await provideSessionPasscode(session.id, values.passcode)).data?.provideSessionPasscode
                .submittedPasscode;

            if (!hasPermission) {
                setIsProvidePasscodeFailed(true);
                setIsSubmitting(false);

                return;
            }
        }

        setRequestState("requesting");

        requestPermissionToJoin(session.id)
            .then((res) => {
                if (res.data?.requestPermissionToJoin) {
                    setIsSubmitting(false);
                }
            })
            .catch((e) => {
                toast(t("prejoin.error.general"), {type: "error"});
                setIsSubmitting(false);
            });
    };

    const handleProvidePasscode = async (values: FormikValuesType) => {
        setIsSubmitting(true);

        if (isGuest && values) {
            await handleUpdateGuestInfo(values, true);
        }

        provideSessionPasscode(session.id, values.passcode)
            .then((res) => {
                if (res.data?.provideSessionPasscode.submittedPasscode) {
                    onSubmit();
                } else {
                    setIsProvidePasscodeFailed(true);
                }
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsProvidePasscodeFailed(true);
                setIsSubmitting(false);
            });
    };

    const requiresConsentToRecord = session.requireConsentToRecord;

    const onResponseReceive = useCallback(
        (data: SubscriptionPayload<"WatchdogPrivateDocument">) => {
            if (!data.watchdogPrivate.approvalRequestsResponse) {
                return;
            }
            if (data.watchdogPrivate.approvalRequestsResponse.hasBeenApproved) {
                // this is by design
                // don't call onSubmit here
                // onSubmit();
            } else {
                setRequestPermissionFailedAttempts(requestPermissionFailedAttempts + 1);
                setRequestState("denied");
                sessionStorage.setItem(`request-permission-denied-${session.id}`, String(requestPermissionFailedAttempts + 1));
            }
        },
        [requestPermissionFailedAttempts, onSubmit, session.id]
    );

    useDataEvent("onWatchdogPrivate", onResponseReceive);

    useEffect(() => {
        if (session.requestPermissionToJoin === false && requestState !== "none") {
            setRequestState("none");
        }
    }, [requestState, session.requestPermissionToJoin]);

    useEffect(() => {
        ariaAnnouncerMessageVar(
            `${t("aria_announcer.about_to_join_session")}. ${
                isRequestPermissionToJoin ? t("aria_announcer.select_request_to_join") : t("aria_announcer.select_join_session")
            }`
        );
    }, []);

    return (
        <>
            <UILanguageUpdater />
            <SessionBackground />
            <PreJoinSettingsProvider>
                <PreJoinScreenHeader />
                <PrejoinHookSetter />

                <div className={cls(classes.root, isPortrait && classes.portrait, isMobile && classes.mobile)}>
                    <div className={classes.inner}>
                        <PreJoinConferenceContainer
                            key={`conference-${session.id}`}
                            participantId={session.currentParticipant.pid}
                            sessionId={session.id}
                            isMobile={isMobile}
                            isPortrait={isPortrait}
                            audioPublishingIsDisabled={audioPublishingIsDisabled}
                            videoPublishingIsDisabled={videoPublishingIsDisabled}
                        />
                        <PreJoinInfoContainer
                            key={`info-${session.id}`}
                            handleRequestPermission={handleRequestPermission}
                            handleProvidePasscode={handleProvidePasscode}
                            handleUpdateGuestInfo={handleUpdateGuestInfo}
                            onSubmit={onSubmit}
                            session={session}
                            isAssistant={isAssistant}
                            tooManyAttempts={tooManyAttempts}
                            isGuest={isGuest}
                            isOwner={isOwner}
                            isMobile={isMobile}
                            isSubmitting={isSubmitting}
                            isRequestPermissionToJoin={isRequestPermissionToJoin}
                            isProvideSessionPasscode={isProvideSessionPasscode}
                            provideSessionPasscode={session.provideSessionPasscode}
                            requestState={requestState}
                            passcodeToJoin={session.passcodeToJoin ?? undefined}
                            requiresConsentToRecord={requiresConsentToRecord}
                            recordingActive={props.recordingActive}
                            isProvidePasscodeFailed={isProvidePasscodeFailed}
                            setIsProvidePasscodeFailed={setIsProvidePasscodeFailed}
                            recordingType={session?.recordingType}
                        />
                    </div>
                </div>
            </PreJoinSettingsProvider>

            <PlayerKeyboardEvents />
        </>
    );
};

export default PreJoinScreen;
