import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useEffect, useState} from "@workhorse/api/rendering";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {addMilliseconds, differenceInMilliseconds, differenceInSeconds, isAfter} from "date-fns";
import classes from "../styles/PlayerHeader.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import secondsToTime from "@workhorse/api/utils/secondsToTime";
import {useTranslation} from "react-i18next";

type PlayerSessionTimeElapsedProps = {
    session: SessionData;
};

function PlayerSessionTimeElapsed(props: PlayerSessionTimeElapsedProps) {
    const {t} = useTranslation();
    const {session} = props;

    const {actualStart, plannedEnd, startAt, backstage} = session;

    const [duration, setDuration] = useState<number>();

    useEffect(() => {
        const compute = () => {
            if (!actualStart) {
                return;
            }

            const secondsPassed = differenceInSeconds(new Date(), new Date(actualStart));

            setDuration(Math.max(0, secondsPassed));
        };

        compute();

        const interval = setInterval(() => {
            compute();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [actualStart]);

    const sessionShouldHaveFinished =
        startAt && actualStart && plannedEnd
            ? isAfter(new Date(), addMilliseconds(new Date(actualStart), differenceInMilliseconds(new Date(plannedEnd), new Date(startAt))))
            : false;

    const displayDuration = secondsToTime(duration || 0, true, false, false, true, false);
    const displayTotalDuration =
        startAt && plannedEnd
            ? secondsToTime(differenceInSeconds(new Date(plannedEnd), new Date(startAt)), true, false, false, true, false)
            : null;

    const currentDurationShort = displayDuration.hours ? (
        <>
            {displayDuration.hours}:{displayDuration.minutes ?? "00"}
        </>
    ) : (
        <>
            {displayDuration.minutes ?? "00"}:{displayDuration.seconds ?? "00"}
        </>
    );

    const currentDurationFull = (
        <>
            {displayDuration.hours ?? "00"}:{displayDuration.minutes ?? "00"}:{displayDuration.seconds ?? "00"}
        </>
    );

    const totalDuration = !displayTotalDuration ? null : (
        <>
            {displayTotalDuration.hours ?? "00"}:{displayTotalDuration.minutes ?? "00"}:{displayTotalDuration.seconds ?? "00"}
        </>
    );

    const title = (
        <div>
            {t("player.header.elapsed_time")}: {currentDurationFull}
            {totalDuration ? <span> / {totalDuration}</span> : ""}
        </div>
    );

    if (!duration) {
        return null;
    }

    return backstage ? (
        <Typography
            variant="sm"
            fontWeight="bold"
            color="primary"
            className={cls(classes.timeElapsed, sessionShouldHaveFinished && classes.timeElapsedFinished)}
            aria-label="Session duration"
            role="timer"
        >
            {t("session.state.not_started") ?? "Not started"}
        </Typography>
    ) : (
        <Tooltip
            title={title}
            placement="bottom"
            classes={{
                tooltip: cls(classes.timeElapsedTooltip, displayTotalDuration && classes.timeElapsedTooltipTotal),
            }}
        >
            <Typography
                variant="sm"
                fontWeight="bold"
                color="primary"
                className={cls(classes.timeElapsed, sessionShouldHaveFinished && classes.timeElapsedFinished)}
                aria-label={t("player.header.session_duration") ?? "Session duration"}
                role="timer"
            >
                {currentDurationShort}
            </Typography>
        </Tooltip>
    );
}

export default PlayerSessionTimeElapsed;
