import {emailRegex} from "@common/validation/utils";
import {
    ConferenceParticipantStatus,
    ConfigurationStep,
    ContactInfoFragment,
    GetContactsAndGroupsDocument,
    Participant,
    ParticipantInviteStatus,
    ParticipantStatus,
    SessionLifecycle,
} from "@generated/data";
import Close from "@material-ui/icons/Close";
import Button from "@ui/cdk/Button";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import {InputProps} from "@ui/cdk/Input";
import {cls} from "@ui/cdk/util/util";
import Dialog from "@ui/core/components/Dialog";
import IconButton from "@ui/core/components/IconButton";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {MutableRefObject, useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {rbac} from "@workhorse/api/user";
import {query, useMutation, writeQuery} from "@workhorse/dataApi";
import {WithClasses} from "@workhorse/declarations";
import {Participant as DesignerParticipant, Session} from "@workhorse/declarations/dataTypes";
import {SubmitRef} from "@workhorse/pages/player/lobby/utils";
import {useKeyboard} from "@workhorse/providers/KeyboardProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useMyParticipant, useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import {getPendingEmailsByCache, useContactFetcher, maxParticipantsNumber} from "./ContactFetcher";
import ParticipantsDialogContent from "./ParticipantsDialogContent";
import classes from "./styles/ParticipantsDialog.module.scss";
import {useCurrentArtifactControlStatus} from "@workhorse/components/resx/utils/auxiliary";
import {takeControl} from "@workhorse/pages/player/header/components/control-status/PlayerSessionControlStatus";
import {useTranslation} from "react-i18next";
import {ApolloCancelRef} from "@workhorse/api/apolloTypes";
import {useQuery} from "@apollo/client";
import {useUser} from "@workhorse/components/user";
import updateSessionFlags from "@workhorse/api/session-settings/utils/updateSessionFlags";
// import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";

export type AssistantsStatus = {id: string; isAssistant: boolean}[];

const participantDefaults = (email?: string, isEventGuest?: boolean, submittedPasscode = false) => ({
    __typename: "Participant" as const,
    conferenceStatus: ConferenceParticipantStatus.Participant,
    dataWithNullableEmail: {
        email: email ? email.toLocaleLowerCase() : null,
        __typename: "ParticipantDataWithNullableEmail" as const,
        firstName: "",
        isGuest: false,
        token: isEventGuest ? makeId() : "",
        lastName: "",
        avatar: "",
        userId: "",
    },
    isApproved: false,
    inviteStatus: ParticipantInviteStatus.NeedsAction,
    isOwner: false,
    mockedParticipant: false,
    muted: false,
    rbac: {},
    status: ParticipantStatus.NotJoined,
    visible: true,
    changedBy: null,
    statusChangedBy: null,
    submittedPasscode,
});

export const makeOptimisticParticipant = (p: Partial<Participant>): Participant => {
    return {
        ...p,
        id: p.id ?? "",
        status: p.status ?? ParticipantStatus.InLobby,
        mockedParticipant: false,
        isOwner: p.isOwner ?? false,
        __typename: "Participant" as const,
        muted: p.muted ?? false,
        inviteStatus: p.inviteStatus ?? ParticipantInviteStatus.NeedsAction,
        isApproved: p.isApproved ?? true,
        changedBy: p.changedBy ?? null,
        conferenceStatus: p.conferenceStatus ?? ConferenceParticipantStatus.Participant,
        dataWithNullableEmail: {
            firstName: p.dataWithNullableEmail?.firstName ?? "",
            lastName: p.dataWithNullableEmail?.lastName ?? "",
            email: p.dataWithNullableEmail?.email ?? "",
            avatar: p.dataWithNullableEmail?.avatar ?? "",
            isGuest: p.dataWithNullableEmail?.isGuest ?? false,
            userId: p.dataWithNullableEmail?.userId ?? "",
            token: p.dataWithNullableEmail?.token ?? "",
            __typename: "ParticipantDataWithNullableEmail" as const,
        },
        rbac: p.rbac ?? {},
        sessionId: p.sessionId ?? "",
        // @ts-expect-error  TODO: fix this type
        session: p.session! as unknown as Session,
        statusChangedBy: p.statusChangedBy ?? null,
        presenceHistory: p.presenceHistory ?? [],
        lastSeenTimestamp: null,
        updatedAt: new Date(),
        submittedPasscode: p.submittedPasscode ?? true,
    };
};

type ParticipantsDialogProps = {
    open: boolean;
    onClose: () => void;
    sessionId: string;
    liveSessionId?: string;
    participants?: DesignerParticipant[];
    sendEmailsAutomatically?: boolean;
    requestPermissionToJoin?: boolean;
    sessionLifecycle?: SessionLifecycle | null;
    isSessionDialog?: boolean;
    isAssistant?: boolean;
    isCreateDialog?: boolean;
    inputLabel?: InputProps["label"];
    participantsRef?: React.MutableRefObject<SubmitRef>;
    minimized?: boolean;
    inLobby?: boolean;
    toggleMinimize?: () => void;
    placeholder?: string;
    optimisticParticipantsRef?: MutableRefObject<DesignerParticipant[] | null>;
    isEventGuest?: boolean;

    onToggleICSGuestList?: () => void;
    hideIcsGuestList?: boolean;
} & WithClasses<"contactFetcher">;

const ParticipantsDialog = (props: ParticipantsDialogProps) => {
    const {t} = useTranslation();
    const {
        open,
        onClose,
        sessionId,
        liveSessionId,
        isSessionDialog,
        isCreateDialog,
        inputLabel,
        participantsRef,
        sessionLifecycle,
        minimized,
        toggleMinimize,
        optimisticParticipantsRef,
        isAssistant,
        inLobby,
        isEventGuest,
    } = props;

    const {controllingParticipantId} = useCurrentArtifactControlStatus();

    const {isKeyboardOpen} = useKeyboard();
    const sessionData = useSession();
    const mobileState = useMobile();

    const participantsFromQuery = useParticipants();

    const myParticipant = useMyParticipant();

    const sessionParticipants = (participantsFromQuery.length ? participantsFromQuery : props.participants) as Array<Partial<Participant>>;

    const [trackEvent] = useTrackingEvents();
    const currentUser = useUser();

    const [automaticEmailInvitation] = useState(props.sendEmailsAutomatically);

    const contactFetcherApi = useContactFetcher();

    const [newParticipants, setNewParticipants] = useState<Partial<Participant>[]>(
        designer.participantsPayload?.upsertParticipantJSONs ?? []
    );

    const [removedParticipants, setRemoveParticipants] = useState<string[]>(
        Array.isArray(designer.participantsPayload?.deletedParticipantIds)
            ? designer.participantsPayload?.deletedParticipantIds?.filter((p) => !!p) ?? []
            : typeof designer.participantsPayload?.deletedParticipantIds === "string"
            ? [designer.participantsPayload?.deletedParticipantIds]
            : [] ?? []
    );

    const {data} = useQuery(GetContactsAndGroupsDocument);

    const participants = sessionParticipants
        .concat(newParticipants)
        .filter((p) => p.id && !removedParticipants.includes(p.id) && !p.isOwner && p.dataWithNullableEmail?.email !== currentUser.email);

    const pendingRef = useRef(contactFetcherApi.pending);
    pendingRef.current = contactFetcherApi.pending;

    const newParticipantsRef = useRef(newParticipants);
    newParticipantsRef.current = newParticipants;

    const deletedParticipantIdsRef = useRef(removedParticipants);
    deletedParticipantIdsRef.current = removedParticipants;
    const submitedRef = useRef(false);

    const shouldbypassSessionPasscode = !!sessionData.provideSessionPasscode && !!sessionData.bypassSessionPasscode;

    useEffect(() => {
        if (isSessionDialog) {
            if (submitedRef.current) {
                return;
            }

            const upsertParticipantJSONs = participants.map((p) => {
                const hasAssistantStatus = assistantsRef.current.find((a) => a.id === p.id);
                return !hasAssistantStatus
                    ? p
                    : {
                          // don't recreate every object
                          ...p,
                          rbac: {
                              ...(p.rbac ?? {}),
                              session: {
                                  ...(p.rbac?.session ?? {}), // future proofing
                                  isAssistant: hasAssistantStatus.isAssistant,
                              },
                          },
                      };
            });
            if (upsertParticipantJSONs.length || removedParticipants.length) {
                designer.participantsPayload = {
                    sessionId: sessionId,
                    upsertParticipantJSONs: upsertParticipantJSONs.slice(0, 250),
                    deletedParticipantIds: removedParticipants,
                };
            } else {
                designer.participantsPayload = null;
            }
        }
    }, [participants, isSessionDialog, removedParticipants, sessionId]);

    useEffect(() => {
        return () => {
            if (submitedRef.current) {
                return;
            }
            if (isSessionDialog) {
                const pending = pendingRef.current;
                const pendingEmails = getPendingEmailsByCache(pending);
                const validPending = pendingEmails
                    .map((x) => x.toLocaleLowerCase())
                    .filter((x) => !!emailRegex.test(x))
                    .filter((x) => newParticipantsRef.current.findIndex((p) => p.dataWithNullableEmail?.email === x) === -1);

                if (validPending.length !== pending.length && !isSessionDialog) {
                    setError(true);
                }

                const participantsToBeAdded = validPending.map((email) => ({
                    ...participantDefaults(email, isEventGuest, shouldbypassSessionPasscode),
                    id: makeId(),
                }));

                const upsertParticipantJSONs = newParticipantsRef.current.concat(participantsToBeAdded).map((p) => {
                    const hasAssistantStatus = assistantsRef.current.find((a) => a.id === p.id);
                    return !hasAssistantStatus
                        ? p
                        : {
                              // don't recreate every object
                              ...p,
                              rbac: {
                                  ...(p.rbac ?? {}),
                                  session: {
                                      ...(p.rbac?.session ?? {}), // future proofing
                                      isAssistant: hasAssistantStatus.isAssistant,
                                  },
                              },
                          };
                });

                if (upsertParticipantJSONs.length || deletedParticipantIdsRef.current.length) {
                    designer.participantsPayload = {
                        sessionId: sessionId,
                        upsertParticipantJSONs,
                        deletedParticipantIds: deletedParticipantIdsRef.current,
                    };
                } else {
                    designer.participantsPayload = null;
                }

                // setNewParticipants((prev) => prev.concat(participantsToBeAdded));
            }
        };
    }, []);

    // the only update operation is making the participant assistant
    const [assistants, setAssistants] = useState<AssistantsStatus>([]);

    const assistantsRef = useRef(assistants);
    assistantsRef.current = assistants;

    const [error, setError] = useState(false);
    const currentEmailRef = useRef("");

    const [updateParticipants] = useMutation("UpdateParticipantsInSessionStandaloneDocument");

    const handleRemoveParticipant = (id: string) => {
        const isNewParticipant = newParticipants.find((p) => p.id === id);
        if (isNewParticipant) {
            setNewParticipants((prev) => prev.filter((p) => p.id !== id));
        } else {
            setRemoveParticipants((prevState) => (prevState.includes(id) ? prevState : [...prevState, id]));
        }
    };

    const handleSubmit = (event?: React.MouseEvent<HTMLButtonElement>, onlySetPayload?: boolean) => {
        if (event && event.detail > 1) {
            handleCloseDialog();
            return;
        }
        submitedRef.current = true;

        const existingParticipantsEmails = sessionParticipants
            .map((x) => x.dataWithNullableEmail?.email ?? "")
            .filter((p) => p) as string[];

        const pending = contactFetcherApi.pending;

        const pendingEmails = getPendingEmailsByCache(pending);
        const validPending = pendingEmails
            .filter((x) => !!emailRegex.test(x))
            .filter((x) => newParticipants.findIndex((p) => p.dataWithNullableEmail?.email === x) === -1)
            .filter((x) => !existingParticipantsEmails.includes(x));

        if (validPending.length !== pending.length && !isSessionDialog) {
            setError(true);
        }
        let participantsToBeAdded = [
            ...newParticipants
                .filter((p) => p.id && !removedParticipants.includes(p.id))
                .concat(
                    validPending.map((email) => ({
                        ...participantDefaults(email, isEventGuest, shouldbypassSessionPasscode),
                        id: makeId(),
                    }))
                ),
        ];

        const allEmails = [
            ...sessionParticipants.map((p) => p.dataWithNullableEmail?.email ?? p?.id ?? ""), // For guests that don't have email and join in lobby, we check for their id
            ...participantsToBeAdded.map((c) => c.dataWithNullableEmail?.email ?? c?.id),
            ...validPending,
        ].filter((p, idx, self) => p && self.indexOf(p) === idx);

        // // Make sure that we add just the maximum allowed number of participants
        allEmails.reverse().splice(0, allEmails.length - (maxParticipantsNumber - 1));
        allEmails.reverse();

        if (currentEmailRef.current) {
            if (emailRegex.test(currentEmailRef.current)) {
                if (
                    allEmails.includes(currentEmailRef.current.toLowerCase()) ||
                    existingParticipantsEmails.includes(currentEmailRef.current.toLowerCase()) ||
                    currentEmailRef.current === sessionParticipants.find((p) => p.isOwner)?.dataWithNullableEmail?.email
                ) {
                    const isParticipantBanned =
                        participants.find((p) => p.dataWithNullableEmail?.email === currentEmailRef.current)?.status ===
                        ParticipantStatus.Kicked;

                    const toastMessage = isParticipantBanned
                        ? t("participant.dialog.cannot_invite_banned_participant")
                        : t("participant.dialog.email_already_invited");

                    toast(toastMessage, {type: "warning", duration: 3000});
                } else {
                    participantsToBeAdded = [
                        ...participantsToBeAdded,
                        {
                            ...participantDefaults(currentEmailRef.current, isEventGuest, shouldbypassSessionPasscode),
                            id: makeId(),
                        },
                    ];
                }
            } else {
                setError(true);
            }
        }

        const newEmails = participantsToBeAdded.map((p) => p.dataWithNullableEmail?.email);

        trackEvent("added_participants", {
            event_category: TrackingEventCategory.SessionActions,
            event_label: "added_participants",
            value: newEmails.length,
        });

        const participantsCreateData = participantsToBeAdded.map((p) => {
            const hasAssistantStatus = assistants.find((a) => a.id === p.id);
            return !hasAssistantStatus
                ? p
                : {
                      // don't recreate every object
                      ...p,
                      rbac: {
                          ...(p.rbac ?? {}),
                          session: {
                              ...(p.rbac?.session ?? {}), // future proofing
                              isAssistant: hasAssistantStatus.isAssistant,
                          },
                      },
                  };
        });

        const participantsUpdateData = sessionParticipants
            .filter((p) => assistants.findIndex((a) => a.id === p.id) !== -1)
            .map((p) => ({
                ...p,
                rbac: {
                    session: {
                        isAssistant: assistants.find((a) => a.id === p.id)?.isAssistant ?? false,
                    },
                },
            }));

        const controllingParticipantRevokedAssistant = assistants.find((a) => a.id === controllingParticipantId && !a.isAssistant);
        const controllingParticipantRemoved = removedParticipants.find((p) => p === controllingParticipantId);

        const participantToRevokeControl = controllingParticipantRevokedAssistant?.id ?? controllingParticipantRemoved;

        if (participantToRevokeControl) {
            takeControl(sessionId, myParticipant.id);
        }

        const bannedInvitedParticipants = sessionParticipants
            .filter(
                (p) =>
                    p.status === ParticipantStatus.Kicked &&
                    p.dataWithNullableEmail?.email &&
                    pendingEmails.includes(p.dataWithNullableEmail.email)
            )
            .map((p) => ({
                id:
                    sessionData.childOfBreakoutRooms?.participantsMap.find(
                        (pm) =>
                            p.dataWithNullableEmail?.userId &&
                            (pm.participantInParent.userId === p.dataWithNullableEmail.userId ||
                                pm.participantInParent.guestId === p.dataWithNullableEmail.userId)
                    )?.participantInParent.id ?? p.id,
                dataWithNullableEmail: p.dataWithNullableEmail,
                status: ParticipantStatus.NotJoined,
            }));

        // Make sure we don't send ids that exists only on client to the server
        const deletedParticipantIds = removedParticipants
            .filter((id) => {
                const existingParticipantWithId = sessionParticipants.find((p) => p.id === id);
                if (
                    existingParticipantWithId?.dataWithNullableEmail?.email &&
                    existingParticipantsEmails.includes(existingParticipantWithId?.dataWithNullableEmail?.email) &&
                    pendingEmails.includes(existingParticipantWithId?.dataWithNullableEmail?.email)
                ) {
                    return false;
                }
                return sessionParticipants.findIndex((pp) => pp.id === id) !== -1;
            })
            .map(
                (p) =>
                    sessionData.childOfBreakoutRooms?.participantsMap.find((pm) => pm.participantInBreakoutRoom?.id === p)
                        ?.participantInParent.id ?? p
            );

        const participantsUpsertData = [...participantsCreateData, ...participantsUpdateData, ...bannedInvitedParticipants].filter((p) => {
            if (p.id && !deletedParticipantIds.includes(p.id)) {
                if ((p as Partial<Participant>).rbac?.session?.isAssistant && !(p as Partial<Participant>).isApproved) {
                    (p as Partial<Participant>).isApproved = true;
                }

                if ((p as Partial<Participant>).rbac?.session?.isAssistant && !(p as Partial<Participant>).submittedPasscode) {
                    (p as Partial<Participant>).submittedPasscode = true;
                }
                return true;
            }
            return false;
        });

        if (optimisticParticipantsRef) {
            optimisticParticipantsRef.current = participantsUpsertData
                .filter((p) => p.id && !removedParticipants.includes(p.id))
                .concat(
                    sessionParticipants.filter(
                        (p) =>
                            p?.id && participantsUpsertData.findIndex((pp) => pp.id === p.id) === -1 && !removedParticipants.includes(p.id)
                    ) ?? []
                )
                .map((p) => makeOptimisticParticipant(p));
        }

        if (onlySetPayload) {
            if (!participantsUpsertData.length && !deletedParticipantIds.length) {
                return;
            }

            designer.participantsPayload = {
                sessionId: sessionId,
                deletedParticipantIds,
                upsertParticipantJSONs: participantsUpsertData,
            };
        } else {
            // Handle only open and close of the dialog. No need to send the mutation
            if (
                !participantsUpsertData.length &&
                !deletedParticipantIds.length &&
                automaticEmailInvitation === props.sendEmailsAutomatically
            ) {
                handleCloseDialog();

                return;
            }
            designer.state.setDesignerCommitState(true);
            updateParticipants({
                variables: {
                    sessionId: sessionId,
                    deletedParticipantIds,
                    upsertParticipantJSONs: participantsUpsertData,
                },
                fetchPolicy: "no-cache",
            })
                .then((res) => {
                    if (res.data?.updateParticipantsInSession) {
                        writeQuery("LocalParticipantsDocument", {
                            variables: {
                                id: sessionId,
                            },
                            data: {
                                __typename: "Query",
                                participants: res.data.updateParticipantsInSession.participants,
                            },
                        });
                    }
                })
                .finally(() => {
                    designer.state.setDesignerCommitState(false);
                });
        }
        if (isEventGuest) {
            handleCloseDialog();
        }
        if (!isCreateDialog) {
            handleCloseDialog();
        }
    };

    const setParticipantsRef = () => {
        if (isSessionDialog && participantsRef) {
            if (!participantsRef.current) {
                participantsRef.current = {};
            }
            participantsRef.current.submit = handleSubmit;
        }
    };

    useEffect(() => {
        setParticipantsRef();
    }, [handleSubmit]);

    const handleCloseDialog = () => {
        setNewParticipants([]);
        setRemoveParticipants([]);
        setAssistants([]);
        onClose();
        setError(false);
    };

    const pendingGetUserNamesForEmails = useRef<string[]>([]);
    const getUserNamesTimeout = useRef<NodeJS.Timeout | null>(null);
    const getUserNamesCancelRef = useRef<ApolloCancelRef>({});

    const [isFetchingUserNames, setIsFetchingUserNames] = useState(false);

    const fetchPendingUserNames = async () => {
        if (!pendingGetUserNamesForEmails.current.length) {
            return;
        }
        const emails = pendingGetUserNamesForEmails.current.slice(0);
        pendingGetUserNamesForEmails.current = [];
        setIsFetchingUserNames(true);
        const response = await query("GetUserNamesDocument", {
            variables: {
                emails,
            },
            fetchPolicy: "no-cache",
            context: {
                cancelRef: getUserNamesCancelRef.current,
                shouldRetry: false,
            },
        }).catch((err) => {
            setIsFetchingUserNames(false);
            console.error("failed to getUserNames for email list", JSON.stringify(emails));
            toast(t("participant.dialog.invite_participants_error"), {
                type: "error",
            });
        });

        if (response?.data?.getUserNames?.users) {
            const processed: string[] = [];
            response.data.getUserNames.users.forEach((user) => {
                if (user?.email) {
                    processed.push(user.email);
                    processEmail(user.email, user);
                }
            });
            const guests = emails.filter((str) => !processed.includes(str));
            if (guests.length) {
                guests.forEach((guestEmail) => {
                    processEmail(guestEmail);
                });
            }
        }
        setIsFetchingUserNames(false);
    };

    const handleAddEmailsToList = (email: string) => {
        if (getUserNamesTimeout.current != null) {
            clearTimeout(getUserNamesTimeout.current);
        }
        pendingGetUserNamesForEmails.current.push(email);
        getUserNamesTimeout.current = setTimeout(fetchPendingUserNames, 500);
    };

    useEffect(() => {
        return () => {
            if (getUserNamesTimeout.current != null) {
                clearTimeout(getUserNamesTimeout.current);
            }
            getUserNamesCancelRef.current?.abort?.();
        };
    }, []);

    const processEmail = (
        email: string,
        user?: {email?: string | null; firstName?: string | null; lastName?: string | null; userProfilePicture?: string | null}
    ) => {
        const foundParticipant =
            newParticipants.find((p) => p.dataWithNullableEmail?.email?.toLowerCase() === email?.toLowerCase()) ||
            participants.find((p) => p.dataWithNullableEmail?.email?.toLowerCase() === email.toLowerCase()) ||
            data?.contacts?.find((c) => c.email.toLowerCase() === email.toLowerCase()) ||
            email === sessionParticipants.find((p) => p.isOwner)?.dataWithNullableEmail?.email;

        if (foundParticipant) {
            const isParticipantBanned =
                participants.find((p) => p.dataWithNullableEmail?.email === email)?.status === ParticipantStatus.Kicked;

            if (!isParticipantBanned) {
                toast(t("participant.dialog.email_already_invited"), {type: "warning", duration: 3000});
                return;
            }
        }

        const existingParticipantWithSameEmail = sessionParticipants.find(
            (p) => p.dataWithNullableEmail?.email?.toLowerCase() === email.toLowerCase()
        );
        // If participant is already in session and was removed and added in a single action, don't add it again, remove it from removed
        if (existingParticipantWithSameEmail?.id && removedParticipants.indexOf(existingParticipantWithSameEmail.id) !== -1) {
            setRemoveParticipants(removedParticipants.filter((p) => p !== existingParticipantWithSameEmail.id));

            return;
        }

        if (existingParticipantWithSameEmail && existingParticipantWithSameEmail.status === ParticipantStatus.Kicked) {
            setNewParticipants((prevState) => [
                ...prevState,
                {
                    ...existingParticipantWithSameEmail,
                    id:
                        sessionData.childOfBreakoutRooms?.participantsMap.find(
                            (pm) =>
                                existingParticipantWithSameEmail.dataWithNullableEmail?.userId &&
                                (pm.participantInParent.userId === existingParticipantWithSameEmail.dataWithNullableEmail.userId ||
                                    pm.participantInParent.guestId === existingParticipantWithSameEmail.dataWithNullableEmail.userId)
                        )?.participantInParent.id ?? existingParticipantWithSameEmail.id,
                    status: ParticipantStatus.NotJoined,
                },
            ]);

            return;
        }

        setNewParticipants((prevState) => [
            ...prevState,
            {
                ...participantDefaults(email, isEventGuest, shouldbypassSessionPasscode),
                id: makeId(),
                dataWithNullableEmail: {
                    ...participantDefaults(email, isEventGuest).dataWithNullableEmail,
                    firstName: user?.firstName ?? "",
                    lastName: user?.lastName ?? "",
                    avatar: user?.userProfilePicture ?? "",
                    isGuest: !user,
                },
            },
        ]);
    };

    const isContactBanned = (email: string) => {
        const foundContact =
            participants.find((p) => p.dataWithNullableEmail?.email === email) ||
            newParticipants.find((p) => p.dataWithNullableEmail?.email === email) ||
            email === sessionParticipants.find((p) => p.isOwner)?.dataWithNullableEmail?.email;

        if (foundContact == null) {
            return false;
        }

        return participants.find((p) => p.dataWithNullableEmail?.email === email)?.status === ParticipantStatus.Kicked;
    };

    const isParticipantKicked = (email: string) => {
        const existing = sessionParticipants.find((p) => p.dataWithNullableEmail?.email?.toLowerCase() === email);
        if (!existing) {
            return false;
        }
        return existing.status === ParticipantStatus.Kicked;
    };

    const getParticipantId = (contact: ContactInfoFragment, isKicked: boolean) => {
        if (!isKicked) {
            return makeId();
        }

        const participant = sessionParticipants.find((p) => p.dataWithNullableEmail?.email?.toLowerCase() === contact.email.toLowerCase());

        const userId = participant?.dataWithNullableEmail?.userId;

        if (!userId) {
            return makeId();
        }

        const found = sessionData.childOfBreakoutRooms?.participantsMap.find((participant) => {
            return participant.participantInParent.userId === userId || participant.participantInParent.guestId === userId;
        });
        return found?.participantInParent?.id || makeId();
    };

    const handleAddContactsToList = (incomingContacts: ContactInfoFragment[]) => {
        const participantsEmails = [...participants.map((p) => p.dataWithNullableEmail?.email)];

        let filteredContacts = incomingContacts
            .filter((c) => !participantsEmails.includes(c.email.toLowerCase()) && !isContactBanned(c.email.toLowerCase()))
            .map((c) => {
                const isKicked = isParticipantKicked(c.email.toLowerCase());
                const id = getParticipantId(c, isKicked);
                const defaults = participantDefaults(c.email, isEventGuest, shouldbypassSessionPasscode);
                return {
                    ...defaults,
                    id,
                    dataWithNullableEmail: {
                        ...defaults.dataWithNullableEmail,
                        firstName: c.firstName,
                        lastName: c.lastName,
                        avatar: c.userProfilePicture,
                    },
                    status: isKicked ? ParticipantStatus.NotJoined : defaults.status,
                };
            });

        for (const contact of filteredContacts) {
            const existingParticipantWithSameEmail = sessionParticipants.find(
                (p) => p.dataWithNullableEmail?.email?.toLowerCase() === (contact.dataWithNullableEmail?.email ?? "").toLowerCase()
            );
            // If participant is already in session and was removed and added in a single action, don't add it again, remove it from removed
            if (existingParticipantWithSameEmail?.id && removedParticipants.indexOf(existingParticipantWithSameEmail.id) !== -1) {
                setRemoveParticipants(removedParticipants.filter((p) => p !== existingParticipantWithSameEmail.id));

                filteredContacts = filteredContacts.filter((p) => p.id !== contact.id);
            }
        }

        setNewParticipants((prevState) => [...prevState, ...filteredContacts]);
    };

    const handleToggleAssistantRole = (participantId: string) => {
        const isAssistant = rbac(participantId, "session.isAssistant");

        setAssistants((prevState) => {
            const alreadyInState = prevState.find((p) => p.id === participantId);
            if (alreadyInState) {
                return prevState.map((p) =>
                    p.id === participantId
                        ? {
                              ...p,
                              isAssistant: !p.isAssistant,
                          }
                        : p
                );
            } else {
                return [...prevState, {id: participantId, isAssistant: !isAssistant}];
            }
        });
    };

    const allEmails = participants.map((p) => p.dataWithNullableEmail?.email).filter((p) => !!p) as string[];

    if (isSessionDialog) {
        return (
            <ParticipantsDialogContent
                key="invite-participants-session-dialog"
                classes={{contactFetcher: props.classes?.contactFetcher ?? ""}}
                actualContacts={data?.contacts?.filter((c) => c.email).map((c) => c.email) ?? []}
                emailsOnly={allEmails ?? []}
                contacts={data?.contacts ?? []}
                contactFetcherApi={contactFetcherApi}
                contactFetcherInputLabel={inputLabel}
                currentEmailRef={currentEmailRef}
                error={error}
                handleAddContactsToList={handleAddContactsToList}
                handleAddParticipantWithoutContactToList={handleAddEmailsToList}
                handleRemoveParticipant={handleRemoveParticipant}
                isSessionDialog={isSessionDialog}
                liveSessionId={liveSessionId}
                participants={participants}
                handleToggleAssistantRole={handleToggleAssistantRole}
                sessionId={sessionId}
                sessionLifecycle={sessionLifecycle}
                assistants={assistants}
                hideMakeAssistant={!!sessionData.instanceOfRecurrence?.session.id}
                isAssistant={isAssistant}
                inLobby={inLobby}
                setError={setError}
                minimized={minimized}
                mobileState={mobileState}
                toggleParticipantsMinimizer={toggleMinimize}
                placeholder={props.placeholder}
                isCreateDialog={isCreateDialog}
                isFetchingUserNames={isFetchingUserNames}
                hideICSParticipantsList={props.hideIcsGuestList !== undefined ? props.hideIcsGuestList : sessionData.hideIcsGuestList}
                handleToggleICSParticipantsList={props.onToggleICSGuestList}
            />
        );
    }

    return (
        <>
            <Dialog
                data-id="invite-participants-dialog"
                data-listening={["Enter", "Tab", "ArrowDown", "ArrowUp"]}
                open={open}
                onClose={onClose}
                className={cls(classes.dialog, isSessionDialog && classes.isSessionDialog, "rnd-btn")}
                disableBackdropClick
                onEscapeKeyDown={handleCloseDialog}
                BackdropProps={{
                    className: "standard-backdrop",
                }}
                PaperProps={{
                    className: cls("standard-dialog-paper", classes.dialogPaper, isKeyboardOpen && classes.fullHeight),
                }}
                classes={{
                    container: "standard-dialog-container",
                }}
            >
                <DialogTitle className={classes.dialogTitle} title={t("participant.dialog.invite_participants") ?? ""}></DialogTitle>

                <IconButton className={classes.close} onClick={handleCloseDialog}>
                    <Close />
                </IconButton>

                <ParticipantsDialogContent
                    key="invite-participants-main"
                    actualContacts={data?.contacts?.filter((c) => c.email).map((c) => c.email) ?? []}
                    emailsOnly={allEmails ?? []}
                    contacts={[]}
                    contactFetcherApi={contactFetcherApi}
                    participants={participants}
                    currentEmailRef={currentEmailRef}
                    error={error}
                    handleAddContactsToList={handleAddContactsToList}
                    handleAddParticipantWithoutContactToList={handleAddEmailsToList}
                    handleRemoveParticipant={handleRemoveParticipant}
                    liveSessionId={liveSessionId}
                    isAssistant={isAssistant}
                    hideMakeAssistant={!!sessionData.instanceOfRecurrence?.session.id}
                    assistants={assistants}
                    inLobby={inLobby}
                    handleToggleAssistantRole={handleToggleAssistantRole}
                    sessionId={sessionId}
                    sessionLifecycle={sessionLifecycle}
                    setError={setError}
                    mobileState={mobileState}
                    placeholder={props.placeholder}
                    isCreateDialog={isCreateDialog}
                    isEventGuest={isEventGuest}
                    isFetchingUserNames={isFetchingUserNames}
                    hideICSParticipantsList={props.hideIcsGuestList !== undefined ? props.hideIcsGuestList : sessionData.hideIcsGuestList}
                    handleToggleICSParticipantsList={props.onToggleICSGuestList}
                    isInstantSession={sessionData.quickSession}
                />

                <div className={cls("flex", classes.buttons)}>
                    <Button variant="quaternary" onClick={handleCloseDialog} className="cancel-btn" key="cancel">
                        {t("g.back")}
                    </Button>
                    <Button data-id="done-add-participants-button" type="submit" onClick={handleSubmit} withMarginLeft>
                        {t("g.done")}
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default ParticipantsDialog;
