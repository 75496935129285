export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const myProductUrlRegex = new RegExp(
    /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
);

const regex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z0-9]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export function isValidUrl(value: string) {
    if (value.startsWith("http://") || value.startsWith("https://")) {
        try {
            const url = new URL(value);
            if (!url.hostname.match(regex)) {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    } else {
        try {
            const url = new URL(`https://${value}`);
            if (!url.hostname.match(regex)) {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    }
}
