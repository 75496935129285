import React, {forwardRef, useCallback} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import FormControl from "@ui/core/components/FormControl";
import MenuItem from "@ui/core/components/MenuItem";
import MuiSelect from "@material-ui/core/Select";
import classes from "./style/Select.module.scss";
import {cls} from "@ui/cdk/util/util";
import Tooltip from "../Tooltip";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import Checkbox from "../Checkbox";
import Typography from "../Typography";

export type SelectProps = ExtractProps<typeof MuiSelect> & {
    showLabel?: boolean;
    labelProps?: JSX.IntrinsicAttributes;
    options?: string[] | {text: string; value: string; img?: string}[];
    menuItemProps?: ExtractProps<typeof MenuItem>;
    formatText?: (value: string) => string;
    fallbackValue?: string;
    wrapperClassName?: string;
    dontComputeValue?: boolean;
    selectedEndAdornment?: React.ReactNode;
    withCheckboxes?: boolean;
    renderOption?: (option: any, index: number) => React.ReactNode;
};

const Select = forwardRef(
    (
        {
            showLabel,
            labelProps,
            label,
            options,
            menuItemProps,
            placeholder,
            formatText,
            fallbackValue,
            wrapperClassName,
            IconComponent,
            dontComputeValue,
            selectedEndAdornment,
            withCheckboxes,
            renderOption,
            ...other
        }: SelectProps,
        ref: React.MutableRefObject<any | null>
    ) => {
        const renderValue = useCallback(
            (value) => {
                if (!value && fallbackValue) {
                    return fallbackValue;
                }
                if (formatText) {
                    return formatText(value);
                }

                const hackedValue = other.value;
                if (options && options?.length && options?.[0] && typeof options[0] === "object" && hackedValue) {
                    const selected = ([...(options ?? [])] as {text: string; value: string; img?: string}[])?.find(
                        (o) => o?.value === hackedValue
                    );

                    return (
                        <>
                            {selected?.img ? (
                                <img
                                    style={{
                                        maxWidth: 30,
                                        maxHeight: 30,
                                    }}
                                    src={selected?.img}
                                />
                            ) : null}
                            {selected?.text ?? ""}
                        </>
                    );
                }

                if (value) {
                    return <>{value}</>;
                }

                return <div className={classes.placeholder}>{placeholder ?? ""} </div>;
            },
            [other.value, placeholder]
        );

        return (
            <FormControl className={cls(classes.formControl, wrapperClassName)}>
                {showLabel ? (
                    <label className={classes.label} {...(labelProps ?? {})}>
                        {other.required ? (
                            <Tooltip placement="top" arrow={true} title="This is a mandatory field.">
                                <sup className={classes.sup}>{"* "}</sup>
                            </Tooltip>
                        ) : null}
                        {label}
                    </label>
                ) : null}
                <MuiSelect
                    displayEmpty={true}
                    renderValue={dontComputeValue ? undefined : renderValue}
                    ref={ref}
                    variant="outlined"
                    inputProps={{
                        ...other.inputProps,
                        className: cls(classes.input, other.inputProps?.className),
                    }}
                    classes={{
                        ...other.classes,
                        select: cls(classes.select, other.classes?.select),
                    }}
                    className={classes.selectRoot}
                    IconComponent={IconComponent ?? ArrowDropDownRoundedIcon}
                    {...other}
                    MenuProps={{
                        classes: {
                            ...other.MenuProps?.classes,
                            paper: cls(classes.paper, other.MenuProps?.classes?.paper),
                            list: cls(other.MenuProps?.classes?.list, classes.list),
                        },
                        anchorOrigin: other.MenuProps?.anchorOrigin ?? {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: other.MenuProps?.transformOrigin ?? {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    {options && options.length
                        ? options.map((item, index) => {
                              let val: string, text: string;

                              if (typeof item === "object") {
                                  val = item.value;
                                  text = item.text;
                              } else {
                                  val = text = item;
                              }

                              return (
                                  <MenuItem
                                      data-id="menu-item"
                                      className={cls(classes.menuItem, menuItemProps?.className ?? "")}
                                      classes={{
                                          ...menuItemProps?.classes,
                                          selected: classes.selected,
                                      }}
                                      {...menuItemProps}
                                      key={index}
                                      value={val ?? fallbackValue}
                                  >
                                      {renderOption ? (
                                          renderOption(item, index)
                                      ) : (
                                          <>
                                              <div className="fullw flex flex-align-center flex-justify-between">
                                                  <div className="flex flex-align-center fullw minw0">
                                                      {other.multiple && withCheckboxes && (
                                                          <Checkbox checked={Array.isArray(other.value) && other.value?.includes(val)} />
                                                      )}
                                                      {item?.img ? (
                                                          <img
                                                              style={{
                                                                  maxWidth: 30,
                                                                  maxHeight: 30,
                                                              }}
                                                              src={item.img}
                                                          />
                                                      ) : null}
                                                      <Typography noWrap>
                                                          {formatText ? formatText(text ?? fallbackValue) : text ?? fallbackValue}
                                                      </Typography>
                                                  </div>
                                                  {other.value === val && selectedEndAdornment ? selectedEndAdornment : null}
                                              </div>
                                          </>
                                      )}
                                  </MenuItem>
                              );
                          })
                        : null}
                </MuiSelect>
            </FormControl>
        );
    }
);

Select.displayName = "Select";

export default Select;
