import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls, triggerDownArrow} from "@ui/cdk/util";
import React, {useCallback, useMemo, useRef, createElement, useEffect, useState} from "@workhorse/api/rendering";
import classes from "./style/CommandPaletteHeader.module.scss";
import BackspaceIcon from "@material-ui/icons/BackspaceOutlined";
import {paletteActiveCategDefault, usePaletteActiveCateg, usePaletteRootSearchString} from "./CommandPaletteProviders";
import {ActionCategory, categById, categMap, parentOfCateg} from "./actionCategTree";
import {ReactComponent as SearchIcon} from "./icons/search-icon-main.svg";
import CommandPaletteRootSearch from "./CommandPaletteRootSearch";
import CommandPaletteSearch from "./CommandPaletteSearch";
import {useVisibleCategItems} from "./CommandPaletteResultsProvider";
import ClearIcon from "@material-ui/icons/Cancel";
import IconButton from "@ui/core/components/IconButton";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {OnboardingPaletteGoBack, OnboardingPaletteSearch} from "../onboarding-tooltips";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useClientEvent} from "@api/events/client";
import {useTranslation} from "react-i18next";

type CommandPaletteHeaderProps = {
    setShowLinkDetails: React.Dispatch<React.SetStateAction<boolean>>;
    showLinkDetails: boolean;
};

const normalizeToolTypeName = (name: string) => {
    return name.toLowerCase().replace(/ /g, "_").replace("_", ".");
};

export default function CommandPaletteHeader(props: CommandPaletteHeaderProps) {
    const {t} = useTranslation();
    const {rootSearchStr, setRootSearchStr} = usePaletteRootSearchString();
    const {activeCateg, setPaletteActiveCateg} = usePaletteActiveCateg();
    const {visibleItems} = useVisibleCategItems();
    const {isMobile} = useMobile();

    const current = useMemo(() => {
        if (visibleItems.isCommand) {
            const item = categMap.find((obj) => visibleItems.items.includes(obj.id));
            return {
                item,
                parent: categMap.find((obj) => obj.id === item?.parentId),
            };
        }
        return {
            item: categById(activeCateg.name, activeCateg.id),
            parent: categById(activeCateg.name, activeCateg.parentId),
        };
    }, [activeCateg.id, activeCateg.name, activeCateg.parentId, visibleItems.isCommand]);

    // console.log(current);

    const searchbarRef = useRef<HTMLInputElement>(null);

    const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const val = e.target.value;
        setRootSearchStr(val);
    }, []);

    const goBack = useCallback(() => {
        const {name, id, parentId, prevCateg} = activeCateg;
        if (prevCateg) {
            setPaletteActiveCateg({
                ...prevCateg,
                prevCateg: undefined,
            });
            return;
        }
        if (!name) {
            // if no categ name, there's no upper level we can go back to
            return;
        }
        if (!parentId) {
            // if no parentId, the only thing we can do is set `activeCateg.name` to undefined
            // and go back to the palette root
            setPaletteActiveCateg(paletteActiveCategDefault);
            return;
        }
        // alas, if we have both activeCateg.name && parentId
        // we can go up a level
        const parent = categById(name, parentId);
        setPaletteActiveCateg({
            id: parent?.id,
            name,
            parentId: parent?.parentId,
            isForced: false,
            searchStr: undefined,
        });
    }, [activeCateg.id]);

    const navBack: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            if (e.key === "Tab") {
                e.preventDefault();
                e.stopPropagation();
                triggerDownArrow();
                return;
            }
            if (e.currentTarget.value.length === 0 && e.key === "Backspace" && !activeCateg.isForced) {
                goBack();
            }
        },
        [activeCateg.id]
    );

    const onSearchInputFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
        const len = e.target.value.length;
        // if (e.target.selectionEnd !== len || e.target.selectionStart !== len) {
        //     e.target.setSelectionRange(len, len);
        // }
        if (e.target.parentElement) {
            if (!e.target.parentElement.classList.contains("Mui-focused")) {
                e.target.parentElement.classList.add("Mui-focused");
            }
        }
    };

    useClientEvent("palette-search-value", ({value}) => {
        setRootSearchStr(value);
    });

    useEffect(() => {
        if (!searchbarRef.current) {
            return;
        }
        if (activeCateg.searchStr !== rootSearchStr) {
            searchbarRef.current.value = activeCateg.searchStr ?? "";
            setRootSearchStr(activeCateg.searchStr ?? "");
        }
        searchbarRef.current.focus();
        if (activeCateg.name !== ActionCategory.Link && props.showLinkDetails) {
            props.setShowLinkDetails(false);
        }
    }, [activeCateg.id, activeCateg.name]);

    const searchPlaceholder =
        (activeCateg.targetId && categMap.find((obj) => obj.id === activeCateg.targetId)?.linkPlaceholder) ??
        ((current.item?.searchPlaceholderTranslateKey ? t(`${current.item?.searchPlaceholderTranslateKey}`) : "") ||
            (current.item?.toolType ? t(`artifacts.${normalizeToolTypeName(current.item?.toolType)}.search_placeholder`) : "") ||
            current.item?.searchPlaceholder) ??
        t("palette.placeholder_search") ??
        "Type a command, paste a link or search...";

    const clearSearchStr = useCallback(() => {
        setRootSearchStr("");
    }, []);

    const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        setTimeout(() => {
            e.target.focus();
            if (e.target.parentElement) {
                if (!e.target.parentElement.classList.contains("Mui-focused")) {
                    e.target.parentElement.classList.add("Mui-focused");
                }
            }
        }, 0);
    }, []);

    const selectEntireText: React.MouseEventHandler<HTMLInputElement> = useCallback((e) => {
        if (searchbarRef.current) {
            searchbarRef.current.setSelectionRange(0, searchbarRef.current.value.length);
        }
    }, []);

    const toggleLinkDetails = useCallback(() => {
        props.setShowLinkDetails((c) => !c);
    }, []);

    return (
        <div className={cls("fullw flex flex-col", classes.commandPaletteHeaderRoot, activeCateg.isForced && classes.noBackBtn)}>
            {!activeCateg.isForced && (
                <OnboardingPaletteGoBack activeCategory={activeCateg} visibleItems={visibleItems}>
                    <Typography component="span" variant="sm" className={cls("flex", classes.navBack)} fontWeight="bold">
                        <span className={"flex flex-align-center btn-back"} onClick={goBack}>
                            {!activeCateg.name ? (
                                <>{`${t("palette.share_palette")}${isMobile ? "" : ` (${browserInfo.isMacOS() ? "⌘" : "Ctrl"} + k)`}`}</>
                            ) : (
                                <>
                                    {t("palette.back_to_home") ?? "Back to home "}
                                    (<BackspaceIcon style={{height: 12, width: 12}} /> )
                                </>
                            )}
                        </span>
                    </Typography>
                </OnboardingPaletteGoBack>
            )}

            <OnboardingPaletteSearch
                activeCategory={activeCateg}
                visibleItems={visibleItems}
                currentValue={searchbarRef.current?.value ?? ""}
            >
                <Input
                    data-id="palette-command"
                    size="large"
                    placeholder={searchPlaceholder}
                    inputRef={searchbarRef}
                    onChange={onSearchChange}
                    onBlur={onBlur}
                    onKeyDown={navBack}
                    formControlClassName={classes.searchInputFc}
                    autoComplete="off"
                    startAdornment={
                        <span className={cls("flex flex-center-all", classes.searchIcon)}>
                            {current.parent?.activeIcon
                                ? createElement(current.parent?.activeIcon)
                                : createElement(
                                      typeof current.parent?.icon === "string" || current.parent?.icon === undefined
                                          ? isMobile
                                              ? SearchRoundedIcon
                                              : SearchIcon
                                          : current.parent.icon,
                                      null,
                                      null
                                  )}
                        </span>
                    }
                    endAdornment={
                        <>
                            {rootSearchStr && (
                                <IconButton onClick={clearSearchStr} className={classes.clearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                            {activeCateg.name == ActionCategory.Link && !isMobile && (
                                <Typography
                                    className={cls("mr-6", classes.showDetailsBtn)}
                                    color="blue500"
                                    fontWeight="bolder"
                                    onClick={toggleLinkDetails}
                                >
                                    {props.showLinkDetails ? t("g.hide_details") ?? "Hide details" : t("g.show_details") ?? "Show details"}
                                </Typography>
                            )}
                        </>
                    }
                    autoFocus
                    onFocus={onSearchInputFocus}
                    value={rootSearchStr ?? ""}
                    onDoubleClick={selectEntireText}
                    id="command-palette-search"
                    inputProps={{
                        "data-private": "lipsum",
                    }}
                />
            </OnboardingPaletteSearch>

            <CommandPaletteRootSearch
                activeCateg={activeCateg}
                setPaletteActiveCateg={setPaletteActiveCateg}
                searchStr={rootSearchStr}
                // setSearchStrInCateg={setSearchStrInCateg}
                setRootSearchStr={setRootSearchStr}
            />

            <CommandPaletteSearch activeCateg={activeCateg} searchStr={rootSearchStr} />
        </div>
    );
}
