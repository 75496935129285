import InfoIcon from "@material-ui/icons/InfoOutlined";
import Button from "@ui/cdk/Button";
import Checkbox from "@ui/cdk/Checkbox";
import FormikFieldWrapper from "@ui/cdk/FormikFieldWrapper";
import Input from "@ui/cdk/Input";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import InputLabel from "@ui/core/components/InputLabel";
import {useReactiveVar} from "@workhorse/api/data";
import React, {useEffect, useRef} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {Form, Formik, FormikProps} from "formik";
import isURL from "validator/lib/isURL";
import * as Yup from "yup";
import {ReactComponent as UploadIcon} from "../../../../assets/media/file-upload.svg";
import BlankProduct from "../../../../assets/media/icon-my-product-no-bg.svg";
import {brandingLogo} from "../Theming/utils";
import classes from "./style/MyProductTool.module.scss";
import formClasses from "./style/ToolComponentForm.module.scss";
import {ToolComponentProps} from "./ToolComponent";

const maxCharCountProductTitle = 20;
const maxCharCountProductDescription = 200;
const maxCharCountProductUrl = 300;

const schema = {
    fields: [
        {
            placeholder: "E.g. https://app.domain.com",
            name: "url",
            typeField: "text",
        },
        {
            placeholder: "E.g. Product Demo",
            name: "title",
            typeField: "text",
        },
        {
            placeholder: "E.g. This tool showcases our organization’s product and how it works.",
            name: "description",
            typeField: "text",
        },
    ],

    validationSchema: Yup.object().shape({
        url: Yup.string()
            .trim()
            .required("This field is mandatory")
            .max(maxCharCountProductUrl, `Needs to a have maximum of ${maxCharCountProductUrl} characters`)
            .test("isUrlValid", "Please enter a valid URL", (value) => isURL(value ?? "")),
        title: Yup.string()
            .trim()
            .required("This field is mandatory")
            .max(maxCharCountProductTitle, `Needs to a have maximum of ${maxCharCountProductTitle} characters`),
        description: Yup.string()
            .trim()
            .max(maxCharCountProductDescription, `Needs to a have maximum of ${maxCharCountProductDescription} characters`),
        consentOwnershipOfIcon: Yup.boolean().oneOf([true], "The consent must be checked"),
    }),
};

type FormikValues = {
    url: string;
    title: string;
    description: string;
    consentOwnershipOfIcon: boolean;
};

type ToolComponentFormProps = ToolComponentProps & {
    onConfirm?: () => void;
    onPreview?: () => void;
};

const ToolComponentForm = (props: ToolComponentFormProps) => {
    const {toolData, onUploadProductToolIcon, onUpsert, isOnboarding, allowInitialDataSubmit, onConfirm} = props;

    const formRef = useRef<FormikProps<FormikValues>>(null);
    const customBrandingLogo = useReactiveVar(brandingLogo);

    const [imageFile, setImageFile] = React.useState<File | undefined>(undefined);
    const [imageUrl, setImageUrl] = React.useState<string>(toolData.iconUrl || customBrandingLogo || BlankProduct);

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formRef.current?.setFieldValue(event.target.name, event.target.checked);
    };

    async function handleSubmit(values: FormikValues) {
        let iconUrl = "";
        if (imageFile) {
            iconUrl = await onUploadProductToolIcon(toolData.id, imageFile);
        }

        if (iconUrl) {
            onUpsert({...toolData, ...values, iconUrl: iconUrl}, toolData.id);
        } else {
            onUpsert({...toolData, ...values}, toolData.id);
        }

        onConfirm?.();
    }

    const onChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target?.files?.length) {
            return;
        }

        const file = e.target.files[0];

        const maximumUploadSize = 5;
        const sizeMb = parseFloat((file.size / (1024 * 1024)).toFixed(2));

        if (sizeMb > maximumUploadSize) {
            toast("Profile picture can't exceed 5MB", {type: "error"});
            return;
        }

        e.target.value = "";

        //make blob url from file
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64data = reader.result;
            if (base64data) {
                setImageUrl(base64data.toString());
            }
        };

        setImageFile(file);
    };

    const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
        if (formRef.current?.errors[e.target.name]) {
            formRef.current.setFieldError(e.target.name, undefined);
        }
    };

    const initialValues: FormikValues = {
        url: toolData.url,
        title: toolData.title,
        description: toolData.description,
        consentOwnershipOfIcon: toolData.consentOwnershipOfIcon,
    };

    useEffect(() => {
        if ((!imageUrl && customBrandingLogo) || (imageUrl === BlankProduct && customBrandingLogo)) {
            setImageUrl(customBrandingLogo);
        }
    }, [customBrandingLogo, imageUrl]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema.validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnMount
            validateOnBlur
            enableReinitialize
        >
            {({values, errors, touched, isValid, isSubmitting}) => {
                const haveValuesChanged =
                    (values.title !== props.toolData.title ||
                        values.description !== props.toolData.description ||
                        values.url !== props.toolData.url ||
                        allowInitialDataSubmit ||
                        imageFile) &&
                    values.consentOwnershipOfIcon;

                const canPreview = !errors[schema.fields[0].name];

                return (
                    <Form className="flex flex-col fullw fullh" onChange={handleChange}>
                        <div className={formClasses.form}>
                            <div className={classes.logoUploadContainer}>
                                <InputLabel htmlFor="product-logo-uploader" className={classes.logoUploaderLabel}>
                                    Tool logo
                                    <Tooltip
                                        placement="top"
                                        title="We’ll use this to create an icon for your tool. For best results, your logo should be square."
                                        arrow
                                    >
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                </InputLabel>
                                <div className="flex flex-row">
                                    <div className={classes.logoUploaderRoot} data-id={isOnboarding && "onboarding-input-strike2-logo"}>
                                        <input
                                            accept=".png, .jpg, .jpeg"
                                            className={classes.uploadInput}
                                            id="product-logo-uploader"
                                            type="file"
                                            name="product-logo-uploader"
                                            onChange={(e) => onChangePicture(e)}
                                        />

                                        <label htmlFor="product-logo-uploader">
                                            <img className={classes.logo} src={imageUrl} />
                                            <span className={classes.uploadIcon}>
                                                <UploadIcon />
                                            </span>
                                        </label>
                                    </div>

                                    <div className={classes.checkboxContainer}>
                                        <Checkbox
                                            className="mr-5"
                                            checked={values.consentOwnershipOfIcon}
                                            name="consentOwnershipOfIcon"
                                            onChange={handleFieldChange}
                                        />
                                        <Typography fontWeight="normal" variant="base" color="secondary" className="m-0 p-0">
                                            I confirm that I have the right to use and upload this logo
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <FormikFieldWrapper
                                key={schema.fields[0].name}
                                name={schema.fields[0].name}
                                as={Input}
                                value={values[schema.fields[0].name]}
                                error={!!errors[schema.fields[0].name] && touched[schema.fields[0].name]}
                                helperText={errors[schema.fields[0].name]}
                                placeholder={schema.fields[0].placeholder}
                                maxCharCount={maxCharCountProductUrl}
                                label={
                                    <Typography color="secondary">
                                        Product root URL <span>*</span>
                                    </Typography>
                                }
                                formControlClassName={classes.field}
                                labelClassName={classes.label}
                                data-id={isOnboarding && "onboarding-input-strike2-url"}
                            />

                            <FormikFieldWrapper
                                key={schema.fields[1].name}
                                name={schema.fields[1].name}
                                as={Input}
                                value={values[schema.fields[1].name]}
                                error={!!errors[schema.fields[1].name] && touched[schema.fields[1].name]}
                                helperText={errors[schema.fields[1].name]}
                                placeholder={schema.fields[1].placeholder}
                                label={
                                    <Typography color="secondary">
                                        Tool name <span>*</span>
                                    </Typography>
                                }
                                showTypedCharCount
                                maxCharCount={maxCharCountProductTitle}
                                formControlClassName={classes.field}
                                labelClassName={classes.label}
                                data-id={isOnboarding && "onboarding-input-strike2-name"}
                            />

                            <FormikFieldWrapper
                                key={schema.fields[2].name}
                                name={schema.fields[2].name}
                                as={Input}
                                value={values[schema.fields[2].name]}
                                error={!!errors[schema.fields[2].name] && touched[schema.fields[2].name]}
                                helperText={errors[schema.fields[2].name]}
                                placeholder={schema.fields[2].placeholder}
                                label={<Typography color="secondary">Tool description</Typography>}
                                maxCharCount={maxCharCountProductDescription}
                                multiline
                                rows={4}
                                showTypedCharCount
                                formControlClassName={classes.field}
                                labelClassName={classes.label}
                                data-id={isOnboarding && "onboarding-input-strike2-description"}
                            />
                        </div>

                        <div className={cls(classes.buttonsContainer, "flex flex-row mt-auto gap-15", props.classes?.footer)}>
                            {props.onPreview && (
                                <Button
                                    variant="plain"
                                    onClick={props.onPreview}
                                    className={!canPreview ? classes.disabledPreview : undefined}
                                    disabled={!canPreview}
                                    data-id={isOnboarding && "onboarding-button-strike2-preview"}
                                >
                                    Preview tool
                                </Button>
                            )}

                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!isValid || isSubmitting || !!props.isConfirmDisabled || !haveValuesChanged}
                                data-id={isOnboarding && "onboarding-button-strike2-confirm"}
                                loading={isSubmitting}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ToolComponentForm;
