import Autocomplete from "@ui/cdk/Autocomplete";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import {InputProps} from "../Input/Input";
import {getLocalTimezone, getAcceptedTimezonesList, AcceptedTimezone} from "@common/utils/timezones";

const localTimezone = getLocalTimezone();

type MuiAutocompleteProps = ExtractProps<typeof Autocomplete>;

type SimpleTimezonePickerProps = {
    defaultValue?: AcceptedTimezone;
    label?: InputProps["label"];
    required?: boolean;
    className?: string;
    onChange?: (newTimezoneGmtFormal: string, newTimezoneStringFormat: string) => void;
    helperText?: string;
    errorText?: string;
    disabled?: boolean;
    date?: Date;
};

function SimpleTimezonePicker(props: SimpleTimezonePickerProps) {
    const {defaultValue, label, required, className, date, onChange, helperText} = props;

    const timezones = useMemo(() => getAcceptedTimezonesList(date ?? new Date()), [date]);

    const [selectedTimezone, setSelectedTimezone] = useState(() => {
        const zone = defaultValue || localTimezone;

        return timezones[zone] || undefined;
    });

    useEffect(() => {
        const zone = defaultValue || localTimezone;

        setSelectedTimezone(timezones[zone] || undefined);
    }, [defaultValue, date]);

    const [update, setUpdate] = useState(new Date().getTime());

    const inputValue = useRef(selectedTimezone);

    const onTimezoneChange: MuiAutocompleteProps["onChange"] = (event, value, reason, details) => {
        if (value === null) {
            return;
        }
        const key = Object.keys(timezones).find((k) => timezones[k] === value);
        if (key) {
            setSelectedTimezone(timezones[key]);
            inputValue.current = timezones[key];
        }
        if (onChange && key) {
            onChange(value as string, key);
        }
    };

    useEffect(() => {
        const key = Object.keys(timezones).find((k) => timezones[k] === selectedTimezone);
        if (onChange && key) {
            onChange(selectedTimezone as string, key);
        }
    }, []);

    const onBlur: MuiAutocompleteProps["onBlur"] = (e) => {
        if (inputValue.current !== selectedTimezone) {
            setUpdate(new Date().getTime());
        }
    };

    const onInputChange: MuiAutocompleteProps["onInputChange"] = (e, value, reason) => {
        if (reason === "clear") {
            // Reset to local timezone at input clear
            inputValue.current = timezones[localTimezone];
            setSelectedTimezone(timezones[localTimezone]);
            if (onChange && timezones[localTimezone]) {
                onChange(timezones[localTimezone] as string, localTimezone);
            }
        } else {
            inputValue.current = value;
        }
    };

    return (
        <Autocomplete
            fullWidth
            id="timeZonePicker"
            value={selectedTimezone}
            options={Object.values(timezones)}
            onChange={onTimezoneChange}
            disabled={!!props.disabled}
            onInputChange={onInputChange}
            onBlur={onBlur}
            label={label}
            required={required}
            className={className}
        />
    );
}

export default SimpleTimezonePicker;
