import {SessionFlagsType} from "@generated/data";
import {rbac} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";

const shouldDisableFlag = (currentParticipant: Participant, sessionFlag: SessionFlagsType) => {
    const isOwner = currentParticipant?.isOwner;
    const isAssistant = rbac(currentParticipant, "session.isAssistant");
    const isSpeaker = !!currentParticipant?.speakerDetails;

    if (isOwner || isAssistant) {
        return false;
    }
    if (sessionFlag === SessionFlagsType.Everyone) {
        return false;
    }
    if (isSpeaker && sessionFlag !== SessionFlagsType.Hosts) {
        return false;
    }

    return true;
};

export default shouldDisableFlag;
