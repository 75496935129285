import env from "@generated/environment";
import React, {ReactNode} from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";
import {setWorkerTimeout} from "@workhorse/timerWorker";
import ErrorComponent from "./ErrorComponent";
import WithApplicationState, {OnRemoteAppStateChange} from "./WithApplicationState";
import {readQuery, writeQuery} from "@workhorse/dataApi";

const errStateMessage = {
    error: {
        primaryTitle: "Something went wrong...",
        secondaryTitle: "Sorry for the detour! Please refresh the page.",
    },
    isDeployInProgress: {
        primaryTitle: "Sessions is under maintenance",
        secondaryTitle: "Your browser will refresh automatically once maintenance operations are completed",
    },
    isVersionMismatch: {
        primaryTitle: "New version released!",
        secondaryTitle: "We just launched a new version, please refresh the page",
    },
};

let refreshTimer: string | null = null;
const failedChunkLoadCount: {[K in string]: number} = {};

export default class ErrorBoundary extends React.Component<{children: ReactNode} & WithMobileState & {location: string}> {
    public state = {
        hasError: false,
        isDeployInProgress: false,
        isVersionMismatch: false,
        isChunkLoadErr: false,
    };

    private static getDerivedStateFromError(error) {
        const message = (error?.message ?? "").trim().toLowerCase();
        const isCancelledPrematurely = message == "observable cancelled prematurely";

        if (isCancelledPrematurely) {
            console.warn("Observable cancelled prematurely. Ignoring");
        }

        return {hasError: !isCancelledPrematurely};
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const isChunkErr = /Loading chunk [\d]+ failed/.test(error?.message);
        const isCSSChunkErr = /Loading CSS chunk [\d]+ failed/.test(error?.message);
        const reqUrl = (((error as any) || {}).response || {}).url || "";
        const hasError = isChunkErr || isCSSChunkErr || /\.(js)$/.test(reqUrl);

        if (hasError) {
            const currentState = readQuery("LocalVersionDocument")?.version;
            writeQuery("LocalVersionDocument", {
                data: {
                    __typename: "Query",
                    version: {
                        isMismatch: currentState?.isMismatch ?? false,
                        chunkLoadErr: true,
                        __typename: "Version",
                    },
                },
            });
            this.setState({
                isChunkLoadErr: true,
            });
        }

        console.log("[ErrorBoundary]: ", {
            location: this.props.location,
            error,
            errorInfo,
            isChunkErr,
            isCSSChunkErr,
        });
    }

    private onLeave = () => {
        this.setState({hasError: false});
    };

    private onRemoteAppStateChange: OnRemoteAppStateChange = (newRemoteApplicationState) => {
        this.setState((state) => ({
            ...state,
            newRemoteApplicationState,
        }));
    };

    public render() {
        const disableAppRemoteState = env.disableAppRemoteState ? localStorage.getItem("enableAppRemoteAppState") === "false" : false;
        const errorType = this.state.hasError
            ? "error"
            : disableAppRemoteState
            ? null
            : this.state.isDeployInProgress
            ? "isDeployInProgress"
            : this.state.isVersionMismatch
            ? "isVersionMismatch"
            : null;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const {primaryTitle, secondaryTitle} = errStateMessage[errorType!] || {};

        return (
            <WithApplicationState onStateChange={this.onRemoteAppStateChange}>
                {errorType ? (
                    <ErrorComponent
                        key="error-comp"
                        primaryTitle={primaryTitle}
                        secondaryTitle={secondaryTitle}
                        onLeave={this.onLeave}
                        withHeader={true}
                        mobileState={this.props.mobileState}
                    />
                ) : (
                    this.props.children
                )}
            </WithApplicationState>
        );
    }
}
