import {
    useDevices,
    isDisplayDevice,
    supportsWebRTC,
    isUnreadableDeviceError,
    isNotAllowedError,
    useDeviceManagerEvents,
} from "@workhorse/api/conference2";
import {ReactNode, useMemo, useState, useRef} from "react";
import {BlockedPermissionsDialog} from "../BlockedPermissionsModal";
import {DeviceCheckWarning} from "./DeviceCheckWarning";
import {useTranslation} from "react-i18next";

interface Props {
    openAudioSettings?: () => void;
    isOnAudioSettings?: boolean;
}

export function DeviceChecks(props: Props) {
    const {t} = useTranslation();
    const {
        ready,
        audioError,
        videoError,
        audioInputs,
        audioOutputs,
        hasAudioInput,
        hasVideoInput,
        getCurrentAudioDevice,
        getCurrentSpeakerDevice,
        setAudioEnabled,
        setVideoEnabled,
    } = useDevices();

    const isForcedRequest = useRef(false);

    useDeviceManagerEvents({
        whenReady: false,
        onAudioSuccess() {
            isForcedRequest.current = false;
        },
        onAudioError() {
            if (isForcedRequest.current) {
                setShowPopover(true);
            }
            isForcedRequest.current = false;
        },
        onVideoSuccess() {
            isForcedRequest.current = false;
        },
        onVideoError() {
            if (isForcedRequest.current) {
                setShowPopover(true);
            }
            isForcedRequest.current = false;
        },
    });

    const isAudioInputUnreadable = isUnreadableDeviceError(audioError);
    const isVideoInputUnreadable = isUnreadableDeviceError(videoError);

    const isAudioNotAllowed = isNotAllowedError(audioError);
    const isVideoNotAllowed = isNotAllowedError(videoError);

    const audioInputId = getCurrentAudioDevice();
    const audioOutputId = getCurrentSpeakerDevice();

    const [showPopover, setShowPopover] = useState(false);

    const inputIsDisplayDevice = useMemo(
        () => (audioInputId == null ? false : isDisplayDevice(audioInputId, audioInputs)),
        [audioInputId, audioInputs]
    );

    const outputIsDisplayDevice = useMemo(
        () => (audioOutputId == null ? false : isDisplayDevice(audioOutputId, audioOutputs)),
        [audioOutputId, audioOutputs]
    );

    const hasWebRTCSupport = useMemo(() => supportsWebRTC(), []);

    const [hdmiInputDismissed, setHDMIInputDismissed] = useState(() => {
        return localStorage.getItem("hdmi.display-input.dismissed") === "1";
    });
    const [hdmiOutputDismissed, setHDMIOutputDismissed] = useState(() => {
        return localStorage.getItem("hdmi.display-output.dismissed") === "1";
    });
    const [permissionsDismissed, setPermissionsDismissed] = useState(false);
    const [hardwareDismissed, setHardwareDismissed] = useState(false);
    const [webRTCDismissed, setWebRTCDismissed] = useState(false);
    const [videoInputUnreadableDismissed, setVideoInputUnreadableDismissed] = useState(false);
    const [audioInputUnreadableDismissed, setAudioInputUnreadableDismissed] = useState(false);

    const requestPermissions = async () => {
        if (!hasAudioInput && !hasVideoInput) {
            return;
        }
        isForcedRequest.current = true;
        if (hasAudioInput) {
            setAudioEnabled(true);
        }
        if (hasVideoInput) {
            setVideoEnabled(true);
        }
    };

    const handleSetHDMIInputDismissed = (permanently: boolean) => {
        setHDMIInputDismissed(true);

        if (permanently) {
            localStorage.setItem("hdmi.display-input.dismissed", "1");
        }
    };

    const handleSetHDMIOutputDismissed = (permanently: boolean) => {
        setHDMIOutputDismissed(true);

        if (permanently) {
            localStorage.setItem("hdmi.display-output.dismissed", "1");
        }
    };

    const handleSetPermissionsDismissed = () => {
        setPermissionsDismissed(true);
    };

    const handleSetHardwareDismissed = () => {
        setHardwareDismissed(true);
    };

    const handleSetWebRTCDismissed = () => {
        setWebRTCDismissed(true);
    };

    const handleSetVideoInputUnreadableDismissed = () => {
        setVideoInputUnreadableDismissed(true);
    };

    const handleSetAudioInputUnreadableDismissed = () => {
        setAudioInputUnreadableDismissed(true);
    };

    const handleClosePopover = () => {
        setShowPopover(false);
    };

    let element: ReactNode;

    if (!hdmiInputDismissed && inputIsDisplayDevice) {
        element = (
            <DeviceCheckWarning
                title={t("devices.display_microphone") ?? "Display microphone"}
                body={
                    t("devices.display_microphone_description") ??
                    "Microphone is set to a display device, like a TV or monitor. If it's not working, please check your audio devices."
                }
                primaryAction={t("g.dont_show_again") ?? "Don't show again"}
                secondaryAction={props.isOnAudioSettings ? undefined : t("g.settings") ?? "Settings"}
                onPrimaryAction={() => handleSetHDMIInputDismissed(true)}
                onSecondaryAction={props.openAudioSettings}
                onClose={() => handleSetHDMIInputDismissed(false)}
            />
        );
    }

    if (!hdmiOutputDismissed && outputIsDisplayDevice) {
        element = (
            <DeviceCheckWarning
                title={t("devices.display_speakers") ?? "Display speakers"}
                body={
                    t("devices.display_speakers_description") ??
                    "Audio is set to a display device, like a TV or monitor. If there's no sound, please check your audio devices."
                }
                primaryAction={t("g.dont_show_again") ?? "Don't show again"}
                secondaryAction={props.isOnAudioSettings ? undefined : t("g.settings") ?? "Settings"}
                onPrimaryAction={() => handleSetHDMIOutputDismissed(true)}
                onSecondaryAction={props.openAudioSettings}
                onClose={() => handleSetHDMIOutputDismissed(false)}
            />
        );
    }

    const microphoneBlocked = hasAudioInput && isAudioNotAllowed;
    const cameraBlocked = hasVideoInput && isVideoNotAllowed;

    if (!permissionsDismissed && ready && (microphoneBlocked || cameraBlocked)) {
        let title = t("devices.cam_and_mic_blocked") ?? "Your mic and camera are blocked";
        let body = t("devices.please_unblock_them") ?? "Please click on “Request permissions” to unblock them.";

        if (microphoneBlocked && !cameraBlocked) {
            title = t("devices.mic_blocked") ?? "Your mic is blocked";
            body = t("devices.please_unblock_it") ?? "Please click on “Request permissions” to unblock it.";
        }

        if (cameraBlocked && !microphoneBlocked) {
            title = t("devices.cam_blocked") ?? "Your camera is blocked";
            body = t("devices.please_unblock_it") ?? "Please click on “Request permissions” to unblock it.";
        }

        element = (
            <DeviceCheckWarning
                title={title}
                body={body}
                primaryAction={t("devices.request_permissions") ?? "Request permissions"}
                readMoreLink="permissions"
                onPrimaryAction={requestPermissions}
                onClose={handleSetPermissionsDismissed}
            />
        );
    }

    if (!hardwareDismissed && ready && !hasAudioInput && !hasVideoInput) {
        element = (
            <DeviceCheckWarning
                title={t("devices.hardware_not_found") ?? "Hardware not found"}
                body={
                    t("devices.hardware_not_found_description") ?? (
                        <>
                            Looks like your <strong>camera</strong> and <strong>microphone</strong> are not connected. Please check your
                            hardware and when ready allow browser permissions to test your setup.
                        </>
                    )
                }
                onClose={handleSetHardwareDismissed}
            />
        );
    }

    if (!webRTCDismissed && !hasWebRTCSupport) {
        element = (
            <DeviceCheckWarning
                title={t("devices.webrtc_test") ?? "WebRTC Test"}
                body={
                    t("devices.webrtc_test_description") ??
                    "Your computer security software is blocking connection. Please check settings or contact your administrator."
                }
                onClose={handleSetWebRTCDismissed}
            />
        );
    }

    if (!videoInputUnreadableDismissed && isVideoInputUnreadable) {
        element = (
            <DeviceCheckWarning
                title={t("devices.video_input_unreadable") ?? "There seems to be a problem with your camera"}
                body={
                    t("devices.video_input_unreadable_description") ??
                    "Make sure it's not being used by another application. If not, switch to another camera."
                }
                onClose={handleSetVideoInputUnreadableDismissed}
            />
        );
    }

    if (!audioInputUnreadableDismissed && isAudioInputUnreadable) {
        element = (
            <DeviceCheckWarning
                title={t("devices.audio_input_unreadable") ?? "There seems to be a problem with your microphone"}
                body={
                    t("devices.audio_input_unreadable_description") ??
                    "Make sure it's not being used by another application. If not, switch to another microphone."
                }
                onClose={handleSetAudioInputUnreadableDismissed}
            />
        );
    }

    return (
        <>
            {element}
            <BlockedPermissionsDialog
                open={showPopover}
                micBlocked={microphoneBlocked}
                cameraBlocked={cameraBlocked}
                onClose={handleClosePopover}
            />
        </>
    );
}
