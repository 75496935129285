import {useMutation} from "@apollo/client";
import {CreateWorkspaceDocument, RemoteUserFragmentDoc, UploadBrandingAssetDocument} from "@generated/data";
import {CircularProgress} from "@material-ui/core";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import imageCompression from "browser-image-compression";
import classes from "./style/NavigationSelectWorkspaceMenu.module.scss";
import {ReactComponent as SessionsLogoSmall} from "../../../assets/media/sessions-logos/sessions-logo-small.svg";
import {getBrandingDetails} from "@workhorse/pages/user/profile/Theming/utils";

type AddWorkspaceDialogProps = {
    open: boolean;
    onClose: () => void;
    userId: string;
    organizationName: string;
    noOfWorkspaces: number;
};

type AddWorkspaceDialogValues = {
    name: string;
    slug?: string;
    logomark?: string | null;
    cname?: string;
};

const compressionOptions = {
    maxSizeMB: 0.7,
    maxWidthOrHeight: 1080,
};

const sessionsLogomark = "https://site.sessions.flowos.com/_content/images/sessions-logo-small-flat.svg";

function AddWorkspaceDialog(props: AddWorkspaceDialogProps) {
    const {open, onClose, userId, organizationName, noOfWorkspaces} = props;

    const dialogRef = useRef<DialogImperativeRef>(null);

    const [createWorkspace] = useMutation(CreateWorkspaceDocument);
    const [uploadLogomark] = useMutation(UploadBrandingAssetDocument);

    const uploadAsset = useCallback(async (file: File, compress?: boolean) => {
        try {
            setIsUploading(true);
            const maximumUploadSize = 5;
            const sizeMb = parseFloat((file.size / (1024 * 1024)).toFixed(2));
            const acceptedTypes = ["image/jpeg", "image/jpg", "image/png"];
            console.log(file);
            if (!acceptedTypes.includes(file.type)) {
                toast("Please upload one of the following formats: jpg, png, jpeg", {
                    type: "error",
                });
                return;
            }

            if (sizeMb > maximumUploadSize) {
                toast("File can't exceed 5mb", {
                    type: "error",
                });
                return;
            }

            const maybeCompressedFile =
                compress && ["image/jpg", "image/jpeg", "image/png"].includes(file.type)
                    ? await imageCompression(file, compressionOptions)
                    : file;

            const cacheBuster = Date.now().toString();
            const uploadUrlRes = await uploadLogomark({variables: {cacheBuster}});
            const {uploadUrl, publicUrl} = uploadUrlRes.data?.uploadBrandingAsset ?? {};

            if (!uploadUrl || !publicUrl) {
                throw new Error("No data returned from server.");
            }

            const fetchResponse = await fetch(uploadUrl, {
                method: "PUT",
                body: maybeCompressedFile,
                headers: {
                    "Content-Type": maybeCompressedFile.type ? maybeCompressedFile.type : "application/octet-stream",
                },
            });

            if (fetchResponse.ok) {
                return publicUrl;
            } else {
                throw new Error("Fetch response is not 200.");
            }
        } catch (err) {
            console.error(`Error occurred when uploading profile picture: ${err.message}`);
            setIsUploading(false);
            return toast(`Error occurred when uploading profile picture`, {type: "error"});
        }
    }, []);

    const [values, setValues] = useState<AddWorkspaceDialogValues>({
        name: "",
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleSetLogomark = useCallback(
        (url: string) => {
            setValues({
                ...values,
                logomark: url,
            });
        },
        [values]
    );

    const handleCreateWorkspace = async () => {
        setLoading(true);

        const defaultBranding = getBrandingDetails();

        createWorkspace({
            variables: {
                name: values.name,
                slug: values.slug,
                branding: {
                    sourcePalette: defaultBranding.basePalette,
                    palette: defaultBranding.newPalette,
                    styleTag: defaultBranding.styleTagContent,
                    active: true,
                    logomark: values.logomark,
                },
            },
        })
            .then((res) => {
                if (res.data?.createWorkspace) {
                    const existingUser = apollo.client.readFragment({
                        fragment: RemoteUserFragmentDoc,
                        fragmentName: "RemoteUser",
                        id: apollo.cache.identify({__typename: "User", id: userId}),
                    });

                    if (existingUser) {
                        apollo.cache.writeFragment({
                            fragment: RemoteUserFragmentDoc,
                            fragmentName: "RemoteUser",
                            id: apollo.cache.identify(existingUser),
                            data: {
                                ...existingUser,
                                workspacePermissions: [
                                    ...existingUser.workspacePermissions.filter(
                                        (p) => res.data?.createWorkspace.find((x) => x.id === p.id) == null
                                    ),
                                    ...res.data.createWorkspace,
                                ],
                            },
                        });
                    }
                    setValues({
                        name: "",
                    });
                    setLoading(false);
                    handleCloseDialog();
                }
                if (!res.data) {
                    setError("The provided name is already in use or is invalid.");
                    setLoading(false);
                    return;
                }
            })
            .catch((err) => {
                console.log("error on create workspace", err);
                setError("The provided name is already in use or is invalid.");
                setLoading(false);
            });
    };

    function handleChangeIcon(e, setFieldValue) {
        uploadAsset(e.target.files[0], true).then((url) => {
            if (url) {
                handleSetLogomark(url);
                setIsUploading(false);
            }
        });
    }

    function handleCloseDialog() {
        onClose();
        setValues({
            name: "",
        });
        setError(null);
        setLoading(false);
    }

    useEffect(() => {
        dialogRef.current?.toggle?.(open);
    }, [open]);

    return (
        <Dialog
            imperativeRef={dialogRef}
            onClose={handleCloseDialog}
            onBackdropClick={handleCloseDialog}
            PaperProps={{
                className: classes.addWorkspaceDialogPaper,
            }}
        >
            <div className={classes.header}>
                <Typography variant="xl" fontWeight="bolder">
                    Add a workspace
                </Typography>
                <Typography color="tertiary">{`Create a new workspace for ${organizationName || "your organization"}`}</Typography>
            </div>
            <div className={cls("gap-16 flex flex-col", classes.content)}>
                <div className="flex flex-row flex-justify-between fullw">
                    <div>
                        {isUploading ? (
                            <CircularProgress size={25} thickness={7} />
                        ) : (
                            <img src={values.logomark || sessionsLogomark} className={classes.workspaceIcon} />
                        )}
                    </div>
                    <label htmlFor="workspace-icon-uploader" role="button">
                        <input
                            accept=".png, .jpg, .jpeg, .ico"
                            className={classes.uploadInput}
                            id="workspace-icon-uploader"
                            type="file"
                            name="logomark"
                            onChange={(e) => handleChangeIcon(e, handleSetLogomark)}
                            // onClick={(e) => {
                            //     setValues({
                            //         ...values,
                            //         logomark: null,
                            //     });
                            // }}
                        />

                        <Button variant="plain" size="small" component="label" htmlFor="workspace-icon-uploader" disabled={isUploading}>
                            Change icon
                        </Button>
                    </label>
                </div>

                <div className="fullw flex flex-col">
                    <Typography color="secondary" fontWeight="bold" id="workspace-name" variant="sm">
                        Workspace name
                    </Typography>
                    <Input
                        value={values.name}
                        name="name"
                        onChange={handleInputChange}
                        formControlClassName="px-0"
                        maxCharCount={250}
                        showTypedCharCount
                        placeholder="Enter a name for your workspace"
                        aria-labelledby="workspace-name"
                    />
                    <Typography color="red400" variant="sm" className="pl-4 mt-2">
                        {error}
                    </Typography>
                </div>

                <div className="fullw flex flex-col">
                    <Typography color="secondary" fontWeight="bold" id="workspace-slug" variant="sm">
                        Slug
                    </Typography>
                    <Input
                        value={values.slug || ""}
                        name="slug"
                        onChange={handleInputChange}
                        formControlClassName="px-0"
                        maxCharCount={250}
                        showTypedCharCount
                        placeholder="Enter a slug for your workspace"
                        aria-labelledby="workspace-slug"
                    />
                    <Typography color="red400" variant="sm" className="pl-4 mt-2">
                        {error}
                    </Typography>
                </div>
            </div>
            <div className={cls("flex fullw gap-12 flex-justify-end", classes.footer)}>
                <Button variant="tertiary" onClick={handleCloseDialog}>
                    Cancel
                </Button>

                <Button
                    variant="primary"
                    onClick={handleCreateWorkspace}
                    disabled={values.name.trim().length === 0 || loading}
                    loading={loading}
                >
                    Create workspace
                </Button>
            </div>
        </Dialog>
    );
}

export default AddWorkspaceDialog;
