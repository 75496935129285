import React, {forwardRef} from "@workhorse/api/rendering";
import MuiMenuItem, {MenuItemTypeMap} from "@material-ui/core/MenuItem";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";

/** @deprecated Please use the cdk component instead. */
const MenuItem = forwardRef((props: any, ref: React.MutableRefObject<any>) => {
    return <MuiMenuItem {...props} ref={ref} />;
}) as OverridableComponent<MenuItemTypeMap>;

export default MenuItem;
