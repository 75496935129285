import environment from "@generated/environment";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {useRef} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {HyperlinkExternal} from "@workhorse/util/links";
import {useTranslation} from "react-i18next";
import classes from "./styles/OnboardingSessionDialog.module.scss";

type OnboardingRoomsWelcomeDialogProps = {
    onClose?: () => void;
};

function OnboardingRoomsWelcomeDialog(props: OnboardingRoomsWelcomeDialogProps) {
    const {onClose} = props;

    const {t} = useTranslation();

    const {mixpanelTrack} = useMixpanelTracker();

    const dialogRef = useRef<DialogImperativeRef>();

    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.ROOMS,
    });

    const handleMarkAsSeen = async (isSkip: boolean) => {
        await markAsSeen({
            GLOBALS: {
                userOnboardingRoomsWelcome: true,
                userOnboardingRoomsCreate: isSkip ? true : undefined,
            },
        });
    };

    const handleCloseDialog = () => {
        mixpanelTrack("frontend-onboarding-rooms-welcome-dismissed", "rooms");
        handleMarkAsSeen(true);
        onClose?.();
    };

    const handleConfirm = () => {
        if (!featureIsAvailable) {
            emitFeatureLimitError(t("limitations.notifications.please_upgrade_plan") ?? "");
            handleMarkAsSeen(true);
            onClose?.();
            return;
        }

        mixpanelTrack("frontend-onboarding-rooms-welcome", "rooms");
        handleMarkAsSeen(false);
        onClose?.();
    };

    return (
        <Dialog
            imperativeRef={dialogRef}
            open
            classes={{paper: cls(classes.root, classes.rootBase)}}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={cls("flex flex-col", classes.content)}>
                <img className={cls("fullw", classes.image)} src={`${environment.staticServiceUrl}/onboarding/rooms/welcome.png`} />
                <div className={classes.info}>
                    <div className={classes.title}>
                        <Typography variant="xl3" color="primary" fontWeight="boldest">
                            Unlock Your SESSIONS Rooms 📑
                        </Typography>
                    </div>
                    <Typography component="div" color="secondary" variant="lg" className={classes.description}>
                        Rooms allow you to have a meeting space with a permanent link. The link can be customized when creating the room,
                        and it will not change after that. Inside a room, you can host an unlimited number of sessions. After each session
                        that takes place in a room ends, you will find it in 🧠{" "}
                        <HyperlinkExternal target="_blank" href={getHelpCenterArticleLink("memory")} rel="noreferrer">
                            Memory
                        </HyperlinkExternal>
                        .
                    </Typography>
                    <Typography component="div" color="secondary" variant="lg" fontWeight="bold" className={classes.description}>
                        Ready to draft your first room effortlessly?
                    </Typography>
                </div>
                <div className={classes.footer}>
                    <Button noFocusBorder variant="tertiary" onClick={handleCloseDialog}>
                        Skip
                    </Button>
                    <Button noFocusBorder variant="primary" onClick={handleConfirm}>
                        Create Room
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default OnboardingRoomsWelcomeDialog;
