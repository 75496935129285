import {makeVar, useReactiveVar} from "@apollo/client";
import {ConferenceParticipantStatus, ParticipantStatus, SessionFlagsType, SessionLifecycle, SessionRecordingState} from "@generated/data";
import {MeetingJoiner} from "@workhorse/api/conference2/lib/MeetingJoiner";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {useDevices} from "@workhorse/api/conference2/providers/MediaProvider";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useEmbedSessions} from "@workhorse/api/embed";
import {memo, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState} from "@workhorse/api/rendering";
import {useLocation} from "@workhorse/api/routing";
import {useSessionSettingsDialog} from "@workhorse/api/session-settings";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {rbac} from "@workhorse/api/user";
import {SubscriptionPayload, useDataEvent} from "@workhorse/dataApi";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {usePreJoinOnboarding} from "@workhorse/pages/user/onboarding/api/prejoin-onboarding";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useCurrentParticipant, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {consentToBeingRecorded} from "./api";
import PreJoinScreen from "./PreJoinScreen";

type LocationState =
    | undefined
    | {
          forcePreJoin?: boolean;
          skipPreJoin?: string;
      };

type SkipStatus = "pending" | "skip" | "doNotSkip";

export const skipPreJoinId = makeId();

interface PreJoinScreenEntryProps {
    skip?: boolean;
    session: SessionData;
    isOwner: boolean;
    children: ReactNode;
    isJoined: boolean;
}

export const submittedVar = makeVar<boolean>(false);

function PreJoinScreenEntry(props: PreJoinScreenEntryProps) {
    const submitted = useReactiveVar(submittedVar);
    const setSubmitted = (value: boolean) => {
        submittedVar(value);
    };
    const sessionOrParentId = props.session.childOfBreakoutRooms?.parentOfBreakoutRooms.sessionId ?? props.session.id;
    const currentParticipant = useCurrentParticipant();
    const [trackEvent] = useTrackingEvents();
    const location = useLocation<{ownerJustCreatedInstantSession: boolean}>();
    const previousLifecycle = useRef<SessionLifecycle>(props.session.lifecycle);
    const [waitForApproval, setWaitForApproval] = useState(false);
    const {allowCameraType, allowMicrophoneType} = props.session;

    const {functionalitiesToRemove} = useEmbedSessions();
    const conferenceDisabled = functionalitiesToRemove.includes("conference");

    if (!previousLifecycle.current && props.session.lifecycle) {
        previousLifecycle.current = props.session.lifecycle;
    }

    const {isLobby} = useIsLobby();
    const isAssistant = rbac(currentParticipant, "session.isAssistant");
    const {start, stop} = useDevices();
    const {stop: stopShare} = useScreenShareOptions();

    const {showPreJoinOnboarding} = usePreJoinOnboarding();

    const currentParticipantIsSpeaker = !!currentParticipant?.speakerDetails?.id;

    const canMountVideoPublisher =
        allowCameraType === SessionFlagsType.Everyone ||
        currentParticipant.isOwner ||
        isAssistant ||
        currentParticipant.conferenceStatus === ConferenceParticipantStatus.Speaker ||
        currentParticipantIsSpeaker;

    const canMountAudioPublisher =
        allowMicrophoneType === SessionFlagsType.Everyone ||
        currentParticipant.isOwner ||
        isAssistant ||
        currentParticipant.conferenceStatus === ConferenceParticipantStatus.Speaker ||
        currentParticipantIsSpeaker;

    const {
        store: {open: settingsOpen},
    } = useSessionSettingsDialog();

    const {isActive, recordingState} = useRecordingProcessor();
    const recordingActive = isActive || recordingState === SessionRecordingState.Starting;

    const initialSkip = useRef<boolean | undefined>(props.skip);

    const shouldPlaySound = initialSkip.current !== props.skip && !props.isOwner;

    const isRequestPermissionToJoin = useRef(
        !props.isOwner &&
            !isAssistant &&
            props.session.requestPermissionToJoin &&
            currentParticipant.isApproved === false &&
            currentParticipant.status !== ParticipantStatus.JoinedSession
    );
    isRequestPermissionToJoin.current =
        !props.isOwner &&
        !isAssistant &&
        props.session.requestPermissionToJoin &&
        currentParticipant.isApproved === false &&
        currentParticipant.status !== ParticipantStatus.JoinedSession;

    const handleSubmit = async () => {
        if (isRequestPermissionToJoin.current) {
            setWaitForApproval(true);
        }

        const consentedToRecord =
            props.session.requireConsentToRecord &&
            (props.session.transcriptionActive || recordingActive) &&
            !currentParticipant.consentedToRecord
                ? true
                : false;
        if (consentedToRecord) {
            consentToBeingRecorded(props.session.id);
        }
        sessionStorage.setItem(`session-prejoin-confirmed-${sessionOrParentId}-${currentParticipant.id}`, "1");
        trackEvent("player_page_accessed", {event_category: TrackingEventCategory.PlayerActions});
        setSubmitted(true);
    };

    const ownerJustCreatedInstantSession = location.state?.ownerJustCreatedInstantSession ?? false;

    const canEnterSession =
        props.session.event && props.session.backstage ? !!(props.isOwner || currentParticipant.speakerDetails || isAssistant) : true;

    let shouldStartDevices =
        conferenceDisabled || !canEnterSession
            ? false
            : (settingsOpen || !isLobby) && props.session.lifecycle !== SessionLifecycle.Ended && canEnterSession;

    if (props.session.onboardingType === SessionOnboardingType.NewUser) {
        shouldStartDevices = false;
    }

    useEffect(() => {
        if (shouldStartDevices) {
            start();
        }

        return () => {
            stop();
            stopShare();
        };
    }, [shouldStartDevices, start, stop, stopShare]);

    const skipPreJoin =
        // please don't remove the commented code, we've been through so many iterations of this
        // that we never know when it's gonna be a requirement to disable the prejoin for instant sessions again
        // and the condition is not that straightforward in order to just rewrite it
        // (props.session.quickSession && !props.session.createdAt && props.isOwner) ||
        // ownerJustCreatedInstantSession ||
        currentParticipant.isRecorder || conferenceDisabled;

    const showPrejoin = conferenceDisabled
        ? false
        : canEnterSession
        ? skipPreJoin || props.session.onboardingType === SessionOnboardingType.NewUser
            ? false
            : props.session.lifecycle === SessionLifecycle.Started && !submitted
        : false;

    useEffect(() => {
        if (ownerJustCreatedInstantSession) {
            window.history.replaceState({}, document.title);
        }
    }, [ownerJustCreatedInstantSession]);

    /**
     * this exists here due to the following reason:
     * for sessions with request permission to join
     * `PreJoinScreen` is not rendered anymore after a participant has been approved
     * meaning there's no longer a listener to call `handleSubmit()`
     *
     * `PreJoinScreen` is not rendered anymore after the participant has beend approved
     * because it caused a flicker when transitioning from waiting to approved (for a split second the prejoin form would appear)
     *
     * now... once approved, PreJoinScreen gets unmounted
     * if not approved, nothing changes
     */
    const onParticipantApproved = useCallback(
        (data: SubscriptionPayload<"WatchdogPrivateDocument">) => {
            if (
                !data.watchdogPrivate.approvalRequestsResponse ||
                data.watchdogPrivate.approvalRequestsResponse.participantId !== currentParticipant.id
            ) {
                return;
            }
            if (data.watchdogPrivate.approvalRequestsResponse.hasBeenApproved) {
                handleSubmit();
            }
        },
        [sessionOrParentId]
    );

    useDataEvent("onWatchdogPrivate", onParticipantApproved);
    const wasLobbyAndJustStarted =
        previousLifecycle.current === SessionLifecycle.NotStarted && props.session.lifecycle === SessionLifecycle.Started;

    useLayoutEffect(() => {
        if (submitted && !showPrejoin && waitForApproval) {
            setWaitForApproval(false);
        }
    }, [showPrejoin, submitted, waitForApproval]);

    useEffect(() => {
        return () => {
            setSubmitted(false);
        };
    }, []);

    return (
        <>
            {canEnterSession &&
            props.session.lifecycle === SessionLifecycle.Started &&
            props.session.createdAt &&
            currentParticipant.updatedAt &&
            !showPrejoin &&
            !waitForApproval &&
            !conferenceDisabled ? (
                <WithMeetingJoiner
                    myParticipantId={currentParticipant.id}
                    sessionId={props.session.id}
                    canMountPublishers={true}
                    canMountAudioPublisher={canMountAudioPublisher}
                    canMountVideoPublisher={canMountVideoPublisher}
                />
            ) : null}
            {(props.session.lifecycle === SessionLifecycle.NotStarted ||
                ((wasLobbyAndJustStarted || isRequestPermissionToJoin.current) && showPrejoin)) &&
            props.session.createdAt &&
            currentParticipant.updatedAt &&
            canEnterSession &&
            !conferenceDisabled ? (
                <WithMeetingJoiner
                    myParticipantId={currentParticipant.id}
                    sessionId={props.session.id}
                    canMountPublishers={false}
                    isLobby={true}
                    canMountAudioPublisher={false}
                    canMountVideoPublisher={false}
                />
            ) : null}
            {!showPrejoin && props.children}
            {showPrejoin && (
                <PreJoinScreen
                    session={props.session}
                    onSubmit={handleSubmit}
                    shouldPlaySound={shouldPlaySound}
                    showNetworkIssueMsg={false}
                    recordingActive={recordingActive}
                    audioPublishingIsDisabled={!canMountAudioPublisher}
                    videoPublishingIsDisabled={!canMountVideoPublisher}
                />
            )}
        </>
    );
}

type WithMeetingJoinerProps = {
    myParticipantId: string;
    sessionId: string;
    canMountPublishers: boolean;
    isLobby?: boolean;
    canMountAudioPublisher: boolean;
    canMountVideoPublisher: boolean;
};

const WithMeetingJoiner = memo(function WithMeetingJoiner(props: WithMeetingJoinerProps) {
    return (
        <MeetingJoiner
            isLobby={props.isLobby}
            sessionId={props.sessionId}
            myParticipantId={props.myParticipantId}
            canMountPublishers={props.canMountPublishers}
            canMountAudioPublisher={props.canMountAudioPublisher}
            canMountVideoPublisher={props.canMountVideoPublisher}
        />
    );
});

export default PreJoinScreenEntry;
