import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import Select from "@ui/cdk/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {SessionView} from "@workhorse/providers/SessionViewProvider";
import {FunctionComponent} from "react";
import classes from "./RecordingViewSelect.module.scss";

const styles = {...genericStyles};

interface Props {
    title: string;
    icon: React.ReactNode;
    description: string;
    value?: string | null;
    defaultValue: RecordingView;
    onChange: (val: RecordingView) => void;
    className?: string;
    options: SelectOption[];
}

interface SelectOption {
    value: RecordingView;
    text: string;
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export enum RecordingView {
    Spotlight = "spotlight",
    Speaker = "speaker",
    Audience = "audience",
    Gallery = "gallery",
}

const recordingViews = Object.values(RecordingView);

export function isValidRecordingView(val: string): val is RecordingView {
    return recordingViews.includes(val as RecordingView);
}

export function getRecordingViewWithFallback(value: string | null | undefined, fallback: RecordingView): RecordingView {
    if (value == null) {
        return fallback;
    }

    if (!isValidRecordingView(value)) {
        return fallback;
    }

    return value;
}

export function RecordingViewSelect(props: Props) {
    const currentValue = getRecordingViewWithFallback(props.value, props.defaultValue);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value as RecordingView;
        props.onChange(val);
    };

    return (
        <div className={cls(styles.togglerWrapper, props.className)}>
            <div className={styles.iconWrapper}>{props.icon}</div>
            <div className={cls("flex flex-justify-between flex-align-center fullw")}>
                <div className={styles.textWrapper}>
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {props.title}
                    </Typography>
                    <Typography color="quaternary" component="span">
                        {props.description}
                    </Typography>
                </div>

                <Select
                    data-id="reactions-settings"
                    className={styles.selectInput}
                    options={props.options}
                    value={currentValue}
                    IconComponent={ExpandMoreRoundedIcon}
                    onChange={handleOnChange}
                    MenuProps={{
                        classes: {
                            paper: styles.selectMenuPaper,
                        },
                    }}
                    wrapperClassName={styles.selectFormControl}
                    renderOption={(option: SelectOption) => {
                        const Icon = option.icon;
                        return (
                            <div className="flex flex-align-center">
                                <option.icon className={classes.selectorIcon} /> <p className="mt-auto mb-auto">{option.text}</p>
                            </div>
                        );
                    }}
                    renderValue={(value: string) => {
                        const item = props.options.find((item) => item.value === value);

                        if (!item) {
                            return value;
                        }

                        return (
                            <div className="flex">
                                <item.icon className={classes.selectorIcon} /> <p className="mt-auto mb-auto">{item.text}</p>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}
