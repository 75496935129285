import {AudienceMobileOpenState} from "@generated/data";
import React, {ReactNode, useCallback, useEffect} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useMobileAudienceDrawerState} from "@workhorse/providers/state";
import {setMaxRowsParticipants} from "../../playerStateVars";
import {ConferenceAudienceScrollDesktop} from "./ConferenceAudienceScrollDesktop";
import {ConferenceAudienceScrollMobile} from "./ConferenceAudienceScrollMobile";

type ConferenceAudienceMobileWrapperProps = {
    children: ReactNode;
    onMount?: (node: HTMLDivElement | null) => void;
};

// Footer opens to fit the number of active participants in session.
// 3 participants per row, min rows number: 1, max rows number: 4
export const computeMaxRows = (participantsCount: number): number => {
    return Math.min(Math.max(Math.ceil(participantsCount / 3), 1), 4);
};

export function ConferenceAudienceScrollWrapper(props: ConferenceAudienceMobileWrapperProps) {
    const {children, onMount} = props;

    const [audienceDrawerOpen, toggleAudienceDrawer] = useMobileAudienceDrawerState();
    const {isMobile} = useMobile();

    const numberOfItems = React.Children.toArray(children).filter(Boolean).length;

    const handleToggleAudience = useCallback(
        (shouldClose: boolean, maxRows: number, audienceDrawerOpen: AudienceMobileOpenState) => {
            // The flow is to open it only at click and go through states as follows: CLOSED -> FULLY_OPEN -> SEMI_OPEN -> CLOSED
            let newState = audienceDrawerOpen;

            switch (audienceDrawerOpen) {
                case AudienceMobileOpenState.Closed:
                    if (maxRows === 1) {
                        newState = AudienceMobileOpenState.SemiOpen;
                        break;
                    }
                    newState = AudienceMobileOpenState.FullyOpen;
                    break;
                case AudienceMobileOpenState.FullyOpen:
                    if (shouldClose) {
                        newState = AudienceMobileOpenState.SemiOpen;
                    }
                    break;
                case AudienceMobileOpenState.SemiOpen:
                    if (shouldClose) {
                        newState = AudienceMobileOpenState.Closed;
                    }
                    break;
                default:
                    if (maxRows === 1) {
                        newState = AudienceMobileOpenState.SemiOpen;
                        break;
                    }
                    newState = AudienceMobileOpenState.FullyOpen;
                    break;
            }

            toggleAudienceDrawer(newState);
        },
        [toggleAudienceDrawer]
    );

    // Adjust the container size based on participants enter/leave actions
    useEffect(() => {
        const maxRows = computeMaxRows(numberOfItems);
        setMaxRowsParticipants(maxRows);
        if (isMobile && [AudienceMobileOpenState.SemiOpen, AudienceMobileOpenState.FullyOpen].indexOf(audienceDrawerOpen) !== -1) {
            handleToggleAudience(false, maxRows, audienceDrawerOpen);
        }
    }, [isMobile, numberOfItems, audienceDrawerOpen, handleToggleAudience]);

    if (!isMobile) {
        return (
            <ConferenceAudienceScrollDesktop numberOfItems={numberOfItems} onMount={onMount}>
                {children}
            </ConferenceAudienceScrollDesktop>
        );
    }

    const isGrid = audienceDrawerOpen === AudienceMobileOpenState.FullyOpen;

    return (
        <ConferenceAudienceScrollMobile numberOfItems={numberOfItems} isGrid={isGrid} onMount={onMount}>
            {children}
        </ConferenceAudienceScrollMobile>
    );
}
