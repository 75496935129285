import {cls} from "@ui/cdk/util";
import classes from "./styles/OnboardingPersonaProgress.module.scss";

type OnboardingPersonaProgress = {
    step: 1 | 2 | 3 | 4;
    onClick: (step: number) => void;
};

const steps = new Array(4).fill(null);

function OnboardingPersonaProgress(props: OnboardingPersonaProgress) {
    const {step, onClick} = props;

    return (
        <div className={classes.root}>
            {steps.map((_, i) => {
                const currentStep = i + 1;
                const isActive = step >= currentStep;
                const canNavigate = currentStep >= 1 && step > currentStep;

                return (
                    <div
                        className={cls(classes.step, isActive ? classes.stepActive : "", canNavigate ? classes.canNavigate : "")}
                        key={`onboarding-persona-progress-${i}`}
                        onClick={() => (canNavigate ? onClick(currentStep) : undefined)}
                    />
                );
            })}
        </div>
    );
}

export default OnboardingPersonaProgress;
