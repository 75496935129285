import {FormOwnerCanAnswerDocument, FormParticipantsCanSeeResultsDocument, FormToggleAnonymityDocument} from "@generated/data";
import env from "@generated/environment";
import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import React, {useRef, useState} from "@workhorse/api/rendering";
import {MicroControllerProps} from "@workhorse/declarations";
import classes from "@workhorse/style/CommonModules/microController.module.scss";
import {Field, Form, Formik, FormikProps} from "formik";
import svc from "@api/service/client";
import artifactDescriptor from "@generated/artifacts";
import designer from "@workhorse/api/designer";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import {useTranslation} from "react-i18next";
interface FormValuesProps {
    participantsCanViewAnswers: boolean;
    ownerCanAnswer: boolean;
    anonymousMode: boolean;
}

export const setParticipantsCanViewAnswers = (artifactId: string, mode: "true" | "false") => {
    return apollo.client.mutate({
        mutation: FormParticipantsCanSeeResultsDocument,
        variables: {
            mode,
            artifactId,
        },
        fetchPolicy: "no-cache",
    });
};

export const setOwnerCanAnswer = (artifactId: string, mode: "true" | "false") => {
    return apollo.client.mutate({
        mutation: FormOwnerCanAnswerDocument,
        variables: {
            mode,
            artifactId,
        },
        fetchPolicy: "no-cache",
    });
};

export const setFormAnonymity = (artifactId: string, mode: "true" | "false") => {
    return apollo.client.mutate({
        mutation: FormToggleAnonymityDocument,
        variables: {
            mode,
            artifactId,
        },
        fetchPolicy: "no-cache",
    });
};

const FormMicroController = (props: MicroControllerProps): JSX.Element => {
    const {t} = useTranslation();
    const [isEditMode] = useDesignerIsEditing();
    const [disabled, setDisabled] = useState(false);
    const artifact = props.currentMicro;
    const descriptor = artifactDescriptor[artifact.artifactId];

    const formRef = useRef<FormikProps<FormValuesProps>>(null);

    const iconSrc = `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor?.meta?.icon}.svg`;

    const initialValues = {
        ownerCanAnswer: artifact.data?.ownerCanAnswer === "true",
        participantsCanViewAnswers: artifact.data?.participantsCanViewAnswers === "true",
        anonymousMode: artifact.data?.anonymousMode === "true",
    } as FormValuesProps;

    const onSubmit = (values: FormValuesProps) => {};

    const onChangeParticipantsCanViewAnswers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(true);

        svc.resx.updateResult(artifact.resourceResults[0].id, "flowos/form/resx/Form", {
            properties: {
                participantsCanViewAnswers: event.target.checked ? true : false,
            },
        });

        setParticipantsCanViewAnswers(artifact.id, event.target.checked ? "true" : "false").then(() => {
            setTimeout(() => {
                setDisabled(false);
            }, 1000);
        });

        formRef.current?.setFieldValue("participantsCanViewAnswers", event.target.checked);
    };

    const onChangeOwnerCanAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(true);

        svc.resx.updateResult(artifact.resourceResults[0].id, "flowos/form/resx/Form", {
            properties: {
                ownerCanAnswer: event.target.checked ? true : false,
            },
        });

        setOwnerCanAnswer(artifact.id, event.target.checked ? "true" : "false").then(() => {
            setTimeout(() => {
                setDisabled(false);
            }, 1000);
        });

        formRef.current?.setFieldValue("ownerCanAnswer", event.target.checked);
    };

    const onChangeFormAnonymity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(true);

        svc.resx.updateResult(artifact.resourceResults[0].id, "flowos/form/resx/Form", {
            properties: {
                anonymousMode: event.target.checked ? true : false,
            },
        });

        setFormAnonymity(artifact.id, event.target.checked ? "true" : "false").then(() => {
            setTimeout(() => {
                setDisabled(false);
            }, 1000);
        });

        formRef.current?.setFieldValue("anonymousMode", event.target.checked);
    };

    return (
        <>
            {props.hasAgenda ? (
                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.titleContainer)}>
                    <div className="flex fullw">
                        <img className={classes.artifactIcon} src={iconSrc}></img>
                        <Typography className={cls(classes.artifactTitle, classes.label)}>
                            {props.agendaItemTitle && props.agendaItemTitle !== designer.constants.DUMMY_AGENDA_ITEM_TITLE
                                ? props.agendaItemTitle
                                : "Forms"}
                        </Typography>
                    </div>
                </div>
            ) : null}

            <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
                {({values}) => (
                    <Form className={cls("flex flex-wrap flex00-auto fullw")}>
                        <Field name="ownerCanAnswer" disabled={disabled}>
                            {() => (
                                <div
                                    className={cls(
                                        "flex flex-justify-between flex-align-center fullw",
                                        classes.settingsContainer,
                                        !props.hasAgenda ? classes.settingsNoMarginTop : undefined
                                    )}
                                >
                                    <Typography className={classes.settingsTitle}>
                                        {t("artifacts.form.allow_organizers_to_answer")}
                                    </Typography>
                                    <Switch
                                        data-id="allow-host-assistant"
                                        color="primary"
                                        onChange={onChangeOwnerCanAnswer}
                                        checked={values.ownerCanAnswer?.toString() === "true"}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="participantsCanViewAnswers" disabled={disabled}>
                            {() => (
                                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.settingsContainer)}>
                                    <Typography className={classes.settingsTitle}>
                                        {t("artifacts.form.show_results_to_participants")}
                                    </Typography>
                                    <Switch
                                        data-id="show-answers"
                                        color="primary"
                                        onChange={onChangeParticipantsCanViewAnswers}
                                        checked={values.participantsCanViewAnswers?.toString() === "true"}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="anonymousMode" disabled={disabled}>
                            {() => (
                                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.settingsContainer)}>
                                    <Typography className={classes.settingsTitle}>{t("artifacts.form.show_anonymous_results")}</Typography>
                                    <Switch
                                        data-id="show-anonymous"
                                        color="primary"
                                        onChange={onChangeFormAnonymity}
                                        checked={values.anonymousMode?.toString() === "true"}
                                    />
                                </div>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default {
    MicroController: FormMicroController,
};
