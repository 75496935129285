export type ChimeDeviceType = {
    deviceId: string;
    label: string;
};

export function findDeviceWithFallback(devices: MediaDeviceInfo[] | ChimeDeviceType[], deviceId: string, fallbackId = "default") {
    if (devices.length === 0) {
        return;
    }

    const device = devices.find((device) => device.deviceId === deviceId);
    if (device) {
        return device;
    }

    if (deviceId !== fallbackId) {
        const fallback = devices.find((device) => device.deviceId === fallbackId);
        if (fallback) {
            return fallback;
        }
    }

    return devices[0];
}

export async function enumerateDevicesSafely(): Promise<MediaDeviceInfo[]> {
    if (!navigator.mediaDevices) {
        return [];
    }
    return navigator.mediaDevices.enumerateDevices().catch((err) => {
        console.log(err);
        return [];
    });
}

export async function requestDeviceSafely(constraints: MediaStreamConstraints): Promise<[MediaStream | null, null] | [null, Error]> {
    if (!navigator.mediaDevices) {
        return [null, null];
    }

    return navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => [stream, null] as [MediaStream, null])
        .catch((error) => {
            console.log(error);
            return [null, error] as [null, Error];
        });
}

export function hasCamera(devices: MediaDeviceInfo[]) {
    return devices.some((device) => device.kind === "videoinput");
}

export function hasMicrophone(devices: MediaDeviceInfo[]) {
    return devices.some((device) => device.kind === "audioinput");
}

export function hasSpeakers(devices: MediaDeviceInfo[]) {
    return devices.some((device) => device.kind === "audiooutput");
}

export function getCameras(devices: MediaDeviceInfo[]) {
    return devices.filter((device) => device.kind === "videoinput");
}

export function getMicrophones(devices: MediaDeviceInfo[]) {
    return devices.filter((device) => device.kind === "audioinput");
}

export function getSpeakers(devices: MediaDeviceInfo[]) {
    return devices.filter((device) => device.kind === "audiooutput");
}

export function areDummyDevices(devices: MediaDeviceInfo[]) {
    return devices.some((device) => device.label === "");
}

export function isUnreadableDeviceError(e: unknown): e is DOMException {
    if (!(e instanceof DOMException)) {
        return false;
    }
    return ["AbortError", "NotReadableError"].includes(e.name);
}

export function isNotAllowedError(e: unknown): e is DOMException {
    if (!(e instanceof DOMException)) {
        return false;
    }
    return ["NotAllowedError"].includes(e.name);
}

export function isOverconstrainedError(e: unknown): e is DOMException {
    if (!(e instanceof DOMException)) {
        return false;
    }
    return ["OverconstrainedError"].includes(e.name);
}

export function isNotFoundError(e: unknown): e is DOMException {
    if (!(e instanceof DOMException)) {
        return false;
    }
    return ["NotFoundError"].includes(e.name);
}

export function hasCameraPermission(devices: MediaDeviceInfo[]) {
    return devices.some((device) => device.kind === "videoinput" && device.label);
}

export function hasMicrophonePermission(devices: MediaDeviceInfo[]) {
    return devices.some((device) => device.kind === "audioinput" && device.label);
}

export function stopMediaStream(stream?: MediaStream | null) {
    if (!stream) {
        return;
    }

    for (const track of stream.getTracks()) {
        track.stop();
    }
}

export function getAudioCapabilities() {
    if (!navigator.mediaDevices) {
        return null;
    }

    const supported = navigator.mediaDevices.getSupportedConstraints() as MediaTrackSupportedConstraints & {
        channelCount?: boolean;
    };

    return {
        sampleRate: supported.sampleRate,
        sampleSize: supported.sampleSize,
        channelCount: supported.channelCount,
    };
}

export function isDisplayDevice(device: string | MediaDeviceInfo, devices: MediaDeviceInfo[]) {
    const deviceId = typeof device === "string" ? device : device.deviceId;
    const label = devices.find((d) => d.deviceId === deviceId)?.label.toLowerCase();

    if (!label) {
        return false;
    }

    return label.includes("hdmi") || label.includes("displayport") || label.includes("display");
}

export function getDevicesByGroups(devices: MediaDeviceInfo[]) {
    const map: Record<string, MediaDeviceInfo[]> = {};

    for (const device of devices) {
        if (map[device.groupId] == null) {
            map[device.groupId] = [];
        }
        map[device.groupId].push(device);
    }

    return Object.values(map);
}

export function getStreamSettings(stream?: MediaStream | null) {
    return stream?.getTracks()?.[0]?.getSettings() ?? null;
}

export function getDeviceIdFromStream(stream?: MediaStream | null) {
    return getStreamSettings(stream)?.deviceId?.toString() ?? null;
}

export function getDeviceIdFromConstraint(constraints: MediaTrackConstraints | null) {
    if (constraints?.deviceId == null) {
        return null;
    }
    return getValueFromConstrainDOMString(constraints.deviceId);
}

export function getValueFromConstrainDOMString(item: ConstrainDOMString): string | null {
    if (Array.isArray(item)) {
        return item[0] ?? null;
    }

    if (typeof item === "object") {
        const result = item.exact ?? item.ideal;
        if (Array.isArray(result)) {
            return result[0] ?? null;
        }
        return result ?? null;
    }

    return item ?? null;
}
