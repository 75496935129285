import {PaymentConfigFragment, SessionEvent} from "@generated/data";
import CodeIconRounded from "@material-ui/icons/CodeRounded";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import DialogContent from "@ui/core/components/DialogContent";
import Select from "@ui/cdk/Select";
import {MutableRefObject, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import CopyToClipboard from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {ReactComponent as EmbedComponentSelected} from "../../../assets/media/screen-embed-component-selected.svg";
import {ReactComponent as EmbedComponent} from "../../../assets/media/screen-embed-component.svg";
import {ReactComponent as EmbedPageSelected} from "../../../assets/media/screen-embed-page-selected.svg";
import {ReactComponent as EmbedPage} from "../../../assets/media/screen-embed-page.svg";
import {ReactComponent as EmbedWidgetSelected} from "../../../assets/media/screen-embed-widget-selected.svg";
import {ReactComponent as EmbedWidget} from "../../../assets/media/screen-embed-widget.svg";
import classes from "./event-embed-dialog.module.scss";
import LanguageSelectorComponent from "@workhorse/components/language-selector/components/LanguageSelectorComponent";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {UtmSet} from "../event/event-analytics/types";
import Tooltip from "@ui/cdk/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import environment from "@generated/environment";
import {useSeenSection} from "@workhorse/api/seen";
import {PaymentsEmbeddingNotification} from "./event-payments/PaymentsEmbeddingNotification";

enum EmbeddingType {
    page = "page",
    component = "component",
    registerForm = "registerForm",
}

type EmbeddingInformation = {
    type: EmbeddingType;
    title: string;
    description: string;
    default: JSX.Element;
    selected: JSX.Element;

    width?: string;
    height?: string;
};

const embedTypes: Record<EmbeddingType, EmbeddingInformation> = {
    [EmbeddingType.page]: {
        type: EmbeddingType.page,
        title: "Embed the landing page" /* "Embed page" */,
        description:
            "booking.widget.embed_page_description" /* "Add a scheduling page to your website. Recommended to use it in a separate page" */,
        default: <EmbedPage />,
        selected: <EmbedPageSelected />,
        width: "100%",
        height: "100%",
    },
    [EmbeddingType.component]: {
        type: EmbeddingType.component,
        title: "Embed " /* "Embed component" */,
        description:
            "Add the landing page as a component, without the header and footer, into an existing page of your website" /* "Add a scheduling component into an existing page of your website" */,
        default: <EmbedComponent />,
        selected: <EmbedComponentSelected />,
        width: "100%",
        height: "100%",
    },
    [EmbeddingType.registerForm]: {
        type: EmbeddingType.registerForm,
        title: "Embed register form" /* "Embed component" */,
        description:
            "Add the register form into an existing page of your website" /* "Add a scheduling component into an existing page of your website" */,
        default: <EmbedWidget />,
        selected: <EmbedWidgetSelected />,
        width: "100%",
        height: "600px",
    },
};

type EmbedButtonType = {
    title: string;
    description: string;
    defaultIcon: JSX.Element;
    selectedIcon: JSX.Element;
    onSelect: () => void;
    type: EmbeddingType;
    selected: EmbeddingType;
};

const EmbedButton = (props: EmbedButtonType) => {
    const isSelected = props.type === props.selected;

    return (
        <div
            data-id={props.title.toLowerCase().replace(/\s/g, "-")}
            className={cls("flex flex-col flex-align-center", classes.embedButton, isSelected && classes.isSelected)}
            onClick={props.onSelect}
        >
            {isSelected ? props.selectedIcon : props.defaultIcon}
            <Typography fontWeight="bolder" color="secondary" className="mb-5">
                {props.title}
            </Typography>
            <Typography variant="sm" color="eleventh">
                {props.description}
            </Typography>
        </div>
    );
};

type EventEmbedDialogProps = {
    imperativeRef: MutableRefObject<DialogImperativeRef>;
    eventSlug: string | undefined;
    onClose: () => void;
    utm: UtmSet[];
    eventName: string;
    eventPayments?: PaymentConfigFragment[] | null;
};

function getUtmString(utm: UtmSet | undefined) {
    if (!utm) {
        return "";
    }

    let utmString = "";
    for (const [key, value] of Object.entries(utm)) {
        if (value !== "" && value !== "Direct") {
            utmString += `&${key}=${value}`;
        }
    }

    return utmString;
}

export const EventEmbedDialog = ({onClose, eventSlug, imperativeRef, utm, eventName, eventPayments}: EventEmbedDialogProps) => {
    const [embeddingType, setEmbeddingType] = useState<EmbeddingType>(EmbeddingType.page);
    const [language, setLanguage] = useState<string>("en");
    const [selectedUTM, setSelectedUTM] = useState<UtmSet | undefined>({
        utm_campaign: "Direct",
        utm_content: "",
        utm_medium: "",
        utm_source: "Direct",
    });
    const {t} = useTranslation();
    const seendEmbeddingNotification = useSeenSection("GLOBALS.eventPaymentsEmbedding");

    const utmString = selectedUTM ? getUtmString(selectedUTM) : "";

    const embedCode = `
<script defer src="${environment.eventBaseURL}/embed/event.js"></script>
<div data-sessions-event-id="${eventSlug}" data-sessions-embed-type="${embeddingType}" data-sessions-event-lang="${language}" data-sessions-event-utm="${utmString}"></div>
    `.trim();

    const handleSelect = (embedType: EmbeddingType) => {
        setEmbeddingType(embedType);
    };

    const handleLinkCopied = () => {
        toast(<p>{t("g.toast.link_copied")}</p>);
        handleOnClose();
    };

    const resetSelect = () => {
        setEmbeddingType(EmbeddingType.page);
    };

    const handleOnClose = () => {
        resetSelect();
        onClose();
    };

    const handleChangeLanguage = (language: string) => {
        setLanguage(language);
    };

    const handleChangeUTM = (event: React.ChangeEvent<HTMLInputElement>) => {
        const utmToSelect = Object.keys(utm)
            .map((key) => utm[key])
            .find((utm) => utm.utm_source === event.target.value);

        if (utmToSelect) {
            setSelectedUTM(utmToSelect ?? undefined);
        }
    };

    const utmWithDirect = {
        "Direct UTM": {
            utm_campaign: "Direct",
            utm_content: "",
            utm_medium: "",
            utm_source: "Direct",
        },
        ...utm,
    };

    const options = !!utmWithDirect
        ? Object.keys(utmWithDirect)?.map((key) => ({
              value: utmWithDirect[key]?.utm_source as string,
              text: utmWithDirect[key]?.utm_source as string,
          }))
        : [];

    const hasPayments = !!eventPayments?.length;

    return (
        <Dialog imperativeRef={imperativeRef} onClose={resetSelect}>
            <DialogTitle>
                <div className="flex flex-col">
                    <Typography
                        fontWeight="boldest"
                        className={cls(classes.dialogTitle, "mb-5")}
                        component="div"
                        variant="xl3"
                        color="secondary"
                        noWrap
                    >
                        {eventName}
                    </Typography>
                    <Typography component="div" variant="sm" color="tertiary">
                        {t("booking.widget.how_to_add")}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <div className={"flex flex-col"}>
                    <div className={"flex fullw mb-20"}>
                        {Object.values(embedTypes).map((embed) => (
                            <EmbedButton
                                key={`embed-${embed.type}`}
                                title={t(embed.title)}
                                description={t(embed.description)}
                                defaultIcon={embed.default}
                                selectedIcon={embed.selected}
                                type={embed.type}
                                selected={embeddingType}
                                onSelect={handleSelect.bind(null, embed.type)}
                            />
                        ))}
                    </div>

                    <div className="flex flex-row flex-align-center gap-12 fullw my-10">
                        <div>
                            <div className="flex flex-row flex-align-center gap-6 pl-6">
                                <Typography variant="sm" color="secondary" fontWeight="bold">
                                    Language
                                </Typography>
                                <Tooltip
                                    title="Select a language to embed for your event. English is the default setting."
                                    arrow
                                    placement="top"
                                >
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <LanguageSelectorComponent
                                language={language}
                                onChange={handleChangeLanguage}
                                className={classes.languageSelector}
                            />
                        </div>
                        <div>
                            <div className="flex flex-row flex-align-center gap-6 pl-6">
                                <Typography variant="sm" color="secondary" fontWeight="bold">
                                    Sharing and tracking
                                </Typography>
                                <Tooltip
                                    title="Select a custom UTM code to embed for your event. The default is 'Direct' (no UTM tracking codes). To create one, access Sharing and tracking menu for your Event."
                                    arrow
                                    placement="top"
                                >
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <Select
                                data-id="embedded-utm-selector"
                                options={options}
                                value={(selectedUTM?.utm_source as string) ?? ""}
                                IconComponent={ExpandMoreRoundedIcon}
                                onChange={handleChangeUTM}
                                className={classes.selectInput}
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenuPaper,
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-align-center">
                            <CodeIconRounded className={cls("mr-5", classes.embedIcon)} />
                            <Typography variant="sm" color="secondary" fontWeight="bold">
                                {t("booking.widget.embed_code")}
                            </Typography>
                        </div>
                        <div className={classes.codeInput}>
                            <Input
                                data-id="embed-code"
                                className={classes.codeInput}
                                classes={{root: classes.multilineInput}}
                                multiline
                                readOnly
                                value={embedCode}
                            ></Input>
                        </div>
                    </div>
                    {hasPayments && !seendEmbeddingNotification ? (
                        <div className="my-12">
                            <PaymentsEmbeddingNotification />
                        </div>
                    ) : null}
                </div>
            </DialogContent>
            <DialogFooter>
                <Button variant="quaternary" onClick={handleOnClose}>
                    {t("g.close")}
                </Button>

                <CopyToClipboard text={embedCode} onCopy={handleLinkCopied}>
                    <Button data-id="copy-code" className="ml-12">
                        {t("booking.widget.copy_code")}
                    </Button>
                </CopyToClipboard>
            </DialogFooter>
        </Dialog>
    );
};
