import Loading from "@workhorse/components/Loading";
import {useQuery} from "@workhorse/dataApi";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {FeedbackForm, SessionLifecycle} from "@generated/data";
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import {FormControlLabel} from "@material-ui/core";
import classes from "./styles/FeedbackFormsSelector.module.scss";
import Button from "@ui/cdk/Button";
import {ReactComponent as NoOffersGraphic} from "../assets/no-offers-graphic.svg";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {useHistory} from "@workhorse/api/routing";
import designer from "@workhorse/api/designer";
type FeedbackFormsSelectorProps = {
    showIcon?: boolean;
    className?: string;
    feedbackFormId?: string | null;
    sessionLifecycle?: SessionLifecycle;
    onSelectFeedbackForm?: (formId: string) => void;
};

const FeedbackFormsSelector = (props: FeedbackFormsSelectorProps) => {
    const {showIcon = false, className, feedbackFormId, sessionLifecycle, onSelectFeedbackForm} = props;
    const {data, loading} = useQuery("GetFeedbackFormsDocument");

    const history = useHistory();

    function handleSelectForm(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        designer.api.session.update({
            feedbackFormId: value,
        });

        if (sessionLifecycle) {
            designer.commit();
        }
        onSelectFeedbackForm?.(value);
    }

    function goToMyForms() {
        history.push("/user/feedback-forms");
    }

    if (loading) return <Loading />;

    return (
        <div className="flex flex-col flex-justify-start fullw mb-12">
            <Typography variant="base" fontWeight="bold" className="mt-6 mb-8">
                My feedback forms
            </Typography>

            {data?.userFeedbackForms?.length ? (
                <div className={cls(classes.formsContainer, "flex flex-col flex-justify-start fullw mt-16")}>
                    <RadioGroup onChange={handleSelectForm}>
                        {data?.userFeedbackForms?.map((form: FeedbackForm) => {
                            return (
                                <FormControlLabel
                                    key={form.id}
                                    data-id="exit-page-offer-radio-button"
                                    value={form.id}
                                    checked={feedbackFormId === form.id}
                                    label={
                                        <div className={cls("flex flex-row fullw", classes.singleForm)}>
                                            <div className="flex flex-col">
                                                <Typography variant="base" fontWeight="bold">
                                                    {form.title}
                                                </Typography>

                                                <Typography variant="base">{form.description}</Typography>
                                            </div>
                                        </div>
                                    }
                                    control={<Radio size="medium" />}
                                    className={cls("flex flex-row fullw", classes.formControlLabel)}
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
            ) : (
                <div className="flex flex-col flex-center-all">
                    <div className="my-32">
                        <NoOffersGraphic />
                    </div>

                    <Typography fontWeight="bold" variant="lg" className="flex flex-col flex-justify-center flex-align-center mb-8">
                        You have no feedback forms
                    </Typography>
                    <Typography
                        fontWeight="bold"
                        color="secondary"
                        variant="base"
                        className="flex flex-col flex-justify-center flex-align-center"
                    >
                        Go to the My Feedback Forms section to create your first offer.
                    </Typography>
                    <Button variant="tertiary" className="mt-32" onClick={goToMyForms} startIcon={<OpenInNewRoundedIcon />}>
                        My Feedback Forms
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FeedbackFormsSelector;
