import env from "@generated/environment";

// let worker: Worker | null = null;

export function setupEventUnlockerWorker() {
    // if (!worker) {
    //     worker = new Worker("/event-unlock-worker.js");
    // }
}

export function sendWorkerMessage(sessionId: string): boolean | null {
    // if (!worker) {
    //     console.log("no worker");
    //     return null;
    // }
    // console.log("seding message to worker");

    navigator.serviceWorker?.controller?.postMessage({
        type: "EVENTUNLOCK",
        sessionId,
        url: env.clientWriteUrl,
    });

    // worker.postMessage({
    //     __sessions: true,
    //     sessionId,
    // });

    return true;
}
