export const memberFormSchema = {
    fields: [
        {
            title: "First name",
            name: "firstName",
            typeField: "text",
            required: false,
            placeholder: "Joe",
        },
        {
            title: "Last name",
            name: "lastName",
            typeField: "text",
            required: false,
            placeholder: "Doe",
        },
    ],
    initialValues: {},
};
