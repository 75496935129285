import {ContactInfoFragment, SpeakerInfoFragment} from "@generated/data";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {getPendingContactsAndEmailsByCache, Tag, TagType} from "@workhorse/pages/designer/CreateSession/ContactFetcher";

import {ConferenceParticipantStatus, ParticipantInviteStatus, ParticipantStatus} from "@generated/data";
import {makeId} from "@workhorse/api/designer/lib/utils";

export function useEventSettingsContactFetcher(params: {
    ownerUserId: string | undefined;
    ownerEmail: string | undefined | null;
    onAddItem: (contacts: ContactInfoFragment[], emails: string[], speakers: SpeakerInfoFragment[]) => void;
    onRemoveItem: (email: string) => void;
    initial?: (() => Tag[]) | Tag[];
    setError: (error: string) => void;
}) {
    const {ownerUserId, ownerEmail, onAddItem, onRemoveItem, initial, setError} = params;
    const [pending, setPending] = useState<Tag[]>([]);

    const updatedPendingInitialRef = useRef(false);

    useEffect(() => {
        if (initial?.length && !updatedPendingInitialRef.current) {
            setPending(initial);
            updatedPendingInitialRef.current = true;
        }
    }, [initial]);

    const pendingMap = useMemo(() => {
        const map = new Map<string, string | undefined>();
        for (const item of pending) {
            const key = item.type + "$" + item.value;
            map[key] = true;
        }
        return map;
    }, [pending]);

    const addPendingItem = useCallback(
        (type: TagType, value: string) => {
            setPending((current) => {
                const index = current.findIndex((item) => item.type === type && item.value === value);
                if (index >= 0) {
                    return current;
                }
                const item = {
                    type,
                    value,
                };
                //add only contacts or groups
                if (type === "contact" || type === "group") {
                    if (value === `u-${ownerUserId}`) {
                        setError("The event owner is already a team member by default");
                        return current;
                    } else {
                        setError("");
                        return [...current, item];
                    }
                } else {
                    setError("Only workspace members can be added");
                    return current;
                }
            });
            const {contacts, emails} = getPendingContactsAndEmailsByCache([{type, value}]);

            //if by any chance the user has two contacts with the same email, we will only add one
            const filteredContacts = contacts.filter((contact) => contact.email !== ownerEmail);
            if (ownerEmail && contacts.map((contact) => contact.email).includes(ownerEmail)) {
                setError("The event owner is already a team member by default");
                onRemoveItem(ownerEmail);
            }

            onAddItem(filteredContacts, [], []);
        },
        [onAddItem, ownerUserId, setError, ownerEmail, onRemoveItem]
    );

    const removePendingItem = useCallback(
        (type: TagType, value: string) => {
            const {contacts, emails} = getPendingContactsAndEmailsByCache([{type, value}]);
            if (contacts.length > 0) {
                const emailToRemove = contacts[0].email;
                onRemoveItem(emailToRemove);
            }

            if (emails.length > 0) {
                const emailToRemove = emails[0];
                onRemoveItem(emailToRemove);
            }

            setPending((current) => {
                const index = current.findIndex((item) => item.type === type && item.value === value);
                if (index < 0) {
                    return current;
                }
                const copy = [...current];
                copy.splice(index, 1);
                return copy;
            });
            setError("");
        },
        [onRemoveItem, setError]
    );

    const updatePendingItem = useCallback((type: TagType, value: string, newValue: string) => {
        setPending((current) => {
            const index = current.findIndex((item) => item.type === type && item.value === value);
            if (index < 0) {
                return current;
            }
            const copy = [...current];
            copy[index] = {
                type,
                value: newValue,
            };
            return copy;
        });
    }, []);

    const itemIsPending = useCallback(
        (type: TagType, value: string) => {
            const key = type + "$" + value;
            return pendingMap[key] === true;
        },
        [pendingMap]
    );

    const pendingPop = useCallback(() => {
        setPending((current) => {
            return current.slice(0, current.length - 1);
        });
    }, []);

    return {
        pending,
        addPendingItem,
        updatePendingItem,
        removePendingItem,
        itemIsPending,
        pendingPop,
        setPending,
    };
}

export const makeParticipantAssistantData = (
    email?: string,
    requestPermission?: boolean,
    requestPasscode?: boolean,
    rbac?: any,
    guestToken?: string,
    firstName?: string,
    lastName?: string
) => ({
    __typename: "Participant" as const,
    conferenceStatus: ConferenceParticipantStatus.Participant,
    dataWithNullableEmail: {
        email: email ?? null,
        __typename: "ParticipantDataWithNullableEmail" as const,
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        isGuest: guestToken ? true : false,
        token: guestToken || undefined,
        avatar: "",
        userId: "",
    },
    guest: guestToken
        ? {
              email: email ?? "",
              firstName: firstName ?? "",
              lastName: lastName ?? "",
              token: guestToken,
              hasCompletedGuestDetails: !!guestToken,
          }
        : undefined,
    isApproved: requestPermission ? false : true,
    inviteStatus: ParticipantInviteStatus.NeedsAction,
    id: makeId(),
    isOwner: false,
    mockedParticipant: false,

    muted: false,
    rbac: rbac ?? {},
    status: ParticipantStatus.NotJoined,
    visible: true,
    changedBy: null,
    statusChangedBy: null,
    passcodeSubmitted: requestPasscode ? false : true,
});

export const eventTitleRegex = new RegExp(/^[a-zA-Z0-9-._~:/?#@!$&`()*+,;=%\s+]*$/);
export const eventUrlRegex = new RegExp(/^[a-zA-Z0-9-._~:/]*$/);
export const emojiRegex = new RegExp(/[^\p{L}\p{N}\p{P}\p{Z}{^$}]/gu);
export const notAllowedRegex = new RegExp(/[|&;$%@#?\"<>()+,%]/g);
