import Typography from "@ui/cdk/Typography";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "../styles/PlayerHeader.module.scss";
import {ReactComponent as EditIcon} from "../../../../assets/media/edit-icon.svg";
import {cls} from "@ui/cdk/util";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import {useTranslation} from "react-i18next";

type PlayerSessionTitleProps = {
    isAdmin: boolean;
    isAssistant: boolean;
    isLobby: boolean;
    name: string;
};

function PlayerSessionTitle(props: PlayerSessionTitleProps) {
    const {t} = useTranslation();
    const {isAdmin, isAssistant, name, isLobby} = props;

    const isOrganizer = isAdmin || isAssistant;

    const {isMobileOrTablet} = useMobile();

    const {show} = useSessionSettingsDialogDisplay();

    const handleOnShowSettings = () => {
        if (isMobileOrTablet) {
            if (isOrganizer) {
                show("general");
            } else {
                show("device");
            }
        } else {
            if (isOrganizer) {
                show("general.session");
            }
        }
    };

    const disabled = !isMobileOrTablet ? !isOrganizer : false;

    return (
        //button so it can be focused and tabbed to
        <button
            className={cls(classes.sessionName, isOrganizer && classes.sessionNameOrganizer, isLobby && classes.sessionNameLobby)}
            onClick={handleOnShowSettings}
            data-id="player-button-sessionname"
            aria-label={t("player.header.aria.session_info") ?? "Session info"}
            disabled={disabled}
        >
            <Typography fontWeight="bolder" noWrap aria-label={t("player.header.aria.session_name") ?? "Session name"}>
                {name}
            </Typography>
            {isOrganizer ? (
                <EditIcon className={classes.sessionNameIcon} />
            ) : isMobileOrTablet ? (
                <SettingsRoundedIcon className={classes.sessionNameIcon} />
            ) : null}
        </button>
    );
}

export default PlayerSessionTitle;
