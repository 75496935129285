import {makeId} from "@workhorse/api/designer/lib/utils";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useState, useEffect, useCallback} from "@workhorse/api/rendering";

type Task = () => Promise<void>;

interface PendingTask {
    id: string;
    callback: Task;
}

function resolve(task: PendingTask) {
    return task.callback().catch((e) => console.log(e));
}

function removeCurrentTask(current: PendingTask | undefined, incoming: PendingTask) {
    if (current?.id === incoming.id) {
        return undefined;
    }
    return current;
}

export function useOrderedAsyncReplaceableTask() {
    const isMounted = useIsMounted();
    const [running, setRunning] = useState(false);
    const [pending, setPending] = useState<PendingTask>();

    const replace = useCallback((task: Task) => {
        setPending({
            id: makeId(),
            callback: task,
        });
    }, []);

    useEffect(() => {
        if (running) {
            return;
        }

        if (!pending) {
            return;
        }

        const onFinish = () => {
            if (!isMounted()) {
                return;
            }
            setRunning(false);
        };

        setRunning(true);
        setPending((current) => removeCurrentTask(current, pending));
        resolve(pending).then(onFinish);
    }, [running, pending, isMounted]);

    return replace;
}
