import {useState, useMemo} from "@workhorse/api/rendering";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Dialog from "@ui/core/components/Dialog";
import DialogContent from "@ui/core/components/DialogContent";
import IconButton from "@ui/core/components/IconButton";
import Button from "@ui/cdk/Button";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import classes from "./style/AddSpeakerDialog.module.scss";
import {SpeakerDisplayType} from "../event-speakers/utils";
import AddSpeakerDialogListItem from "./AddSpeakerDialogListItem";
import designer from "@workhorse/api/designer";
import {useAgendaItems, useParticipants} from "@workhorse/providers/SessionDataProviders";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {ReactComponent as AddSpeakerIcon} from "../../../../assets/media/speaker-icon.svg";
import common from "../eventCommons.module.scss";
import Input from "@ui/cdk/Input";
import {ReactComponent as AddSpeaker} from "../../../../assets/media/add-speaker2.svg";
import {useHistory} from "@workhorse/api/routing";
import InputAdornment from "@ui/core/components/InputAdornment";

type AddSpeakerDialogProps = {
    open: boolean;
    onClose: () => void;
    agendaItemId: string;
    eventId: string;
};

const AddSpeakerDialog = (props: AddSpeakerDialogProps) => {
    const {open, onClose, agendaItemId, eventId} = props;

    const participantsFromQuery = useParticipants();
    const agendaItems = useAgendaItems();
    const history = useHistory();

    const currentSpeakersIds: string[] =
        agendaItems?.find((a) => a.id === agendaItemId)?.agendaItemSpeakers.map((speaker) => speaker.speakerId) ?? [];

    const currentAgendaItem = agendaItems.find((a) => a.id === agendaItemId);

    const [selectedSpeakerIds, setSelectedSpeakerIds] = useState<string[]>(currentSpeakersIds);
    const [searchValue, setSearchValue] = useState<string>("");

    const speakers: Array<SpeakerDisplayType> = useMemo(() => {
        const speakers: Array<SpeakerDisplayType> = participantsFromQuery
            ?.filter((participant) => participant.speakerDetails !== null)

            .slice()
            ?.sort((a, b) => {
                if (a.speakerDetails?.name && b.speakerDetails?.name) {
                    return a.speakerDetails.name.localeCompare(b.speakerDetails.name);
                } else {
                    return 0;
                }
            })
            ?.map((participant) => {
                return {
                    id: participant?.speakerDetails?.id!,
                    participantId: participant.id!,
                    email: participant.dataWithNullableEmail.email || null,
                    firstName: participant.dataWithNullableEmail.firstName,
                    lastName: participant.dataWithNullableEmail.lastName,
                    ...participant.speakerDetails,
                    createdAt: participant.createdAt,
                    updatedAt: participant.updatedAt,
                    avatar: participant.dataWithNullableEmail.avatar,
                };
            });

        return speakers || [];
    }, [participantsFromQuery]);

    const filteredSpeakers = useMemo(() => {
        return speakers.filter((speaker) => {
            if (searchValue === "") {
                return true;
            }
            if (speaker) {
                if (speaker?.name?.toLowerCase().includes(searchValue.toLowerCase())) {
                    return true;
                }
                if (speaker?.email?.toLowerCase().includes(searchValue.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
    }, [speakers, searchValue]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const handleConfirm = () => {
        //return if no changes
        if (currentSpeakersIds === selectedSpeakerIds) {
            onClose();
            return;
        }

        const speakersToAdd = selectedSpeakerIds
            .filter((speakerId) => !currentSpeakersIds.includes(speakerId))
            .map((speakerId) => {
                return {
                    __typename: "AgendaItemSpeaker",
                    createdAt: null as unknown as Date,
                    isDeleted: false,
                    oldId: null,
                    id: makeId(),
                    speakerId: speakerId,
                    update: null,
                    updatedAt: null as unknown as Date,
                    agendaItemId,
                };
            });

        const speakersIdsToRemove = currentSpeakersIds.filter((speakerId) => !selectedSpeakerIds.includes(speakerId));
        const speakersToRemove =
            currentAgendaItem?.agendaItemSpeakers
                .filter((s) => speakersIdsToRemove.includes(s.speakerId))
                .map((s) => {
                    return {
                        ...s,
                        isDeleted: true,
                    };
                }) ?? [];

        designer.api.agendaItem.update({
            id: agendaItemId,
            agendaItem: {
                agendaItemSpeakers: [
                    ...((currentAgendaItem?.agendaItemSpeakers.filter((s) => !speakersIdsToRemove.includes(s.speakerId)) as any) ?? []),
                    ...speakersToAdd,
                    ...speakersToRemove,
                ],
            },
        });

        designer.commit();
        onClose();
    };

    const handleSelectSpeaker = (id: string) => {
        if (selectedSpeakerIds.includes(id)) {
            setSelectedSpeakerIds(selectedSpeakerIds.filter((speakerId) => speakerId !== id));
        } else {
            setSelectedSpeakerIds([...selectedSpeakerIds, id]);
        }
    };

    const handleNavToSpeakers = () => {
        history.push(`/event/${eventId}/session-speakers`);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.root,
            }}
        >
            <DialogTitle className={cls("flex flex-align-center", classes.header)}>
                <AddSpeakerIcon className={classes.headerIcon} />
                <Typography fontWeight="boldest" component="div" variant="xl2" color="octonary">
                    Add speakers
                </Typography>

                <IconButton onClick={props.onClose} className={classes.closeButton}>
                    <CloseRoundedIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className={cls("flex flex-col", classes.content)}>
                {speakers.length ? (
                    <>
                        {/* search input is shown only if there are at least 7 speakers */}
                        {speakers.length < 7 ? (
                            <Typography variant="lg" fontWeight="bolder" className="mb-14">
                                Event speakers
                            </Typography>
                        ) : (
                            <div className={classes.searchInputContainer}>
                                <Input
                                    onChange={handleSearchChange}
                                    value={searchValue}
                                    placeholder="Search event speakers"
                                    classes={{
                                        root: classes.searchInputRoot,
                                        input: classes.searchInput,
                                    }}
                                    endAdornment={
                                        searchValue ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    type="submit"
                                                    size="small"
                                                    className={classes.searchIcon}
                                                    onClick={() => setSearchValue("")}
                                                >
                                                    <CloseRoundedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : undefined
                                    }
                                />
                            </div>
                        )}
                        <div className={cls("flex flex-col fullh flex11-auto", classes.speakersList)}>
                            {filteredSpeakers.length ? (
                                filteredSpeakers.map((singleSpeaker) => {
                                    return (
                                        <AddSpeakerDialogListItem
                                            key={singleSpeaker.id}
                                            speaker={singleSpeaker || {}}
                                            currentSpeakersIds={selectedSpeakerIds}
                                            onSelectSpeaker={handleSelectSpeaker}
                                        />
                                    );
                                })
                            ) : (
                                <div className="fullh fullw flex flex11-auto">
                                    <div className={"flex flex-col fullw my-auto flex-align-center"}>
                                        <Typography variant="lg" fontWeight="bold" className="mx-auto" color="tertiary">
                                            We couldn&apos;t find anything.
                                        </Typography>
                                        <Typography className="mx-auto" color="tertiary">
                                            Maybe try using another search term.
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    // empty state when there are no speakers added
                    <div className="flex flex-col flex11-auto flex-align-center fullw fullh flex-justify-center mb-10">
                        <AddSpeaker className={classes.emptyStateIcon} />

                        <Typography variant="lg" fontWeight="bolder" className="mb-8" color="tertiary">
                            Who will lead this topic?
                        </Typography>

                        <Button onClick={handleNavToSpeakers} variant="plain">
                            Invite a speaker
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogFooter>
                <Button
                    data-id="add-speakers-back-button"
                    variant="quaternary"
                    onClick={onClose}
                    key="cancel"
                    className={classes.cancelButton}
                >
                    Cancel
                </Button>
                <Button
                    data-id="add-speakers-confirm-button"
                    variant="primary"
                    withMarginLeft
                    onClick={handleConfirm}
                    key="submit"
                    className={cls(common.actionButton, classes.confirmButton)}
                    disabled={speakers.length === 0 || (currentSpeakersIds.length === 0 && selectedSpeakerIds.length === 0)}
                >
                    {currentSpeakersIds.length === 0 ? "Add speakers" : "Confirm"}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default AddSpeakerDialog;
