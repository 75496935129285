import descriptor, {ArtifactTag} from "@generated/artifacts";
import {FullSessionQuery, MyProductToolDocument} from "@generated/data";
import env from "@generated/environment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {getToolName} from "@workhorse/api/utils/getToolName";
import classes from "./styles/DesignerToolPreviewHeader.module.scss";
import {useQuery, useReactiveVar} from "@workhorse/api/data";
import myProductDefaultIcon from "../../../../assets/media/icon-my-product-no-bg.svg";
import {brandingLogo} from "@workhorse/pages/user/profile/Theming/utils";

type DesignerToolPreviewHeaderProps = {
    artifactTag?: ArtifactTag;
    currentArtifact: FullSessionQuery["agendaItems"][0]["artifact"];
    sessionId: string;
};

function DesignerToolPreviewHeader(props: DesignerToolPreviewHeaderProps) {
    const {artifactTag, currentArtifact, sessionId} = props;

    const customBrandingLogo = useReactiveVar(brandingLogo);

    const shouldQueryForMyProductTool = !!currentArtifact?.data.myProductToolId;
    const {data: myProductToolQuery} = useQuery(MyProductToolDocument, {
        variables: {
            myProductToolId: currentArtifact?.data.myProductToolId,
            sessionId: sessionId,
        },
        skip: !shouldQueryForMyProductTool,
    });

    const artifactDisplayName =
        currentArtifact?.artifactId === "flowos/my-product-tool"
            ? myProductToolQuery?.myProductTool?.title
            : (artifactTag && descriptor[artifactTag]?.meta?.displayName) ?? "";

    const myProductToolIcon = myProductToolQuery?.myProductTool?.iconUrl || customBrandingLogo || myProductDefaultIcon;
    const toolIcon =
        currentArtifact?.artifactId === "flowos/my-product-tool"
            ? myProductToolIcon
            : (artifactTag && `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[artifactTag].meta.icon}.svg`) ?? "";

    const resourceName = getToolName(
        currentArtifact?.id ?? "",
        currentArtifact?.artifactId as ArtifactTag
        // TODO - maca - data api
        // currentArtifact?.resourceSlots?.[0]?.resource
    );

    return (
        <div className={classes.root}>
            <div className="flex flex-align-center">
                <div className="flex">
                    {artifactTag ? (
                        <div className={classes.imageWrapper}>
                            <img className={classes.artifactImage} src={toolIcon} />
                        </div>
                    ) : null}
                    <div className={classes.header}>
                        <Typography component="div" variant="xl2" color="secondary" fontWeight="bold">
                            {artifactDisplayName}
                        </Typography>
                        {resourceName.nameByResource ? (
                            <Typography
                                component="div"
                                variant="base"
                                color="blueGray400"
                                className="flex flex-align-center"
                                fontWeight="bolder"
                            >
                                <FiberManualRecordIcon className={cls("mx-12", classes.dotIcon)} /> {resourceName.nameByResource}
                            </Typography>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesignerToolPreviewHeader;
