import * as Yup from "yup";

export const ROOM_NAME_MAX_LENGTH = 250;
export const ROOM_DESCRIPTION_MAX_LENGTH = 2000;
export const ROOM_URL_MAX_LENGTH = 200;

export const roomValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required("* Adding a room name is mandatory")
        .max(ROOM_NAME_MAX_LENGTH, `* Needs to have a maximum of ${ROOM_NAME_MAX_LENGTH} characters`),
    description: Yup.string()
        .nullable()
        .optional()
        .trim()
        .max(ROOM_DESCRIPTION_MAX_LENGTH, `* Needs to have a maximum of ${ROOM_DESCRIPTION_MAX_LENGTH} characters`),
    slug: Yup.string()
        .trim()
        .required("* Adding a room url is mandatory")
        .max(ROOM_URL_MAX_LENGTH, `* Needs to have a maximum of ${ROOM_URL_MAX_LENGTH} characters`),
});
