import {parse} from "csv-parse/browser/esm";

export const csvMimeTypes = [
    "text/csv",
    "application/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export interface CSVResult<T> {
    entries: T[];
    totalEntries: number;
    invalidCSV: boolean;
    invalidEntries: number;
}

type CSVParseRecord<T> = (record: unknown) => T | null;

export async function getEntriesFromCSV<T>(file: File, required: string[], parseRecord: CSVParseRecord<T>): Promise<CSVResult<T>> {
    const buffer = await file.arrayBuffer();
    return new Promise<CSVResult<T>>((resolve, reject) => parseEntries<T>(Buffer.from(buffer), required, parseRecord, resolve, reject));
}

function parseEntries<T>(
    buffer: Buffer,
    required: string[],
    parseRecord: CSVParseRecord<T>,
    resolve: (result: CSVResult<T>) => void,
    reject: (reason: unknown) => void
) {
    const parser = parse(buffer, {delimiter: ",", relaxQuotes: true, trim: true, skipEmptyLines: true});

    let index = 0;
    let invalidCSV = false;
    let invalidEntries = 0;
    let columns: string[] = [];
    const entries: T[] = [];

    const readColumns = (record: unknown) => {
        columns = getColumns(record);

        if (required.every((column) => columns.includes(column))) {
            return true;
        }

        invalidCSV = true;
        handleEnd();
        return false;
    };

    const handleEnd = () => {
        resolve({entries, invalidEntries, totalEntries: index - 1, invalidCSV});
    };

    const handleRead = () => {
        let record: unknown;

        while ((record = parser.read()) !== null) {
            index++;

            if (index === 1) {
                const valid = readColumns(record);
                if (!valid) {
                    break;
                }
                continue;
            }

            const map = mapRecordToColumns(record, columns);
            const entry = parseRecord(map);

            if (entry == null) {
                console.log(entry, record);
                invalidEntries++;
                continue;
            }

            entries.push(entry);
        }
    };

    parser.on("readable", handleRead);
    parser.on("error", reject);
    parser.on("end", handleEnd);
}

function getColumns(record: unknown): string[] {
    if (!Array.isArray(record)) {
        return [];
    }
    const filtered = record.filter((item) => typeof item === "string");
    return filtered.map((item) => item.replace(/\s/g, "").replaceAll("-", "").replaceAll("_", "").toLowerCase());
}

function mapRecordToColumns(record: unknown, columns: string[]) {
    if (!Array.isArray(record)) {
        return null;
    }

    const map: Record<string, unknown> = {};
    columns.forEach((column, index) => {
        map[column] = record[index];
    });

    return map;
}
