import Typography from "@ui/cdk/Typography";
import React from "react";
import artifactDescriptor, {ArtifactTag} from "@generated/artifacts";
import AcUnitOutlinedIcon from "@material-ui/icons/AcUnitOutlined";
import env from "@generated/environment";
import classes from "../style/AgendaItemsActionsMenu.module.scss";
import {serialize} from "@api/editor";
import {setPidThatChangedArtifact} from "@workhorse/pages/player/playerStateVars";
import {orchestrator} from "@workhorse/providers/state";
import {AgendaItem, SessionData} from "@workhorse/declarations/dataTypes";
import {useBreakoutInfo} from "@artifacts/breakout/providers/BreakoutInfoProvider";
import {DrawerHookState} from "@workhorse/declarations";
import {DrawerState} from "@generated/data";
import AgendaBoxTime from "@artifacts/agenda/ui/agendaBox/components/AgendaBoxTime";
import {cls} from "@ui/cdk/util/util";

type AgendaItemMenuItemProps = {
    title: string;
    description: string;
    duration: number;
    order: number;
    id: string;
    artifact: any;
    changeArtifactButtonEnabled: boolean;
    inLobby: boolean;
    sessionId: string;
    session: SessionData;
    isMobile: boolean;
    toggleLeftDrawer: (newState?: DrawerState | undefined) => void;
    isActive: boolean;
    timeSpentInSeconds: number;
    startAt: Date;
    index: number;
    agendaItems: AgendaItem[];
    displayedAgendaItems: AgendaItem[];
    isAssistant: boolean;
    isAdmin: boolean;
    isPaused: boolean;
};

const AgendaItemMenuItem = (props: AgendaItemMenuItemProps) => {
    const {
        title,
        description,
        duration,
        order,
        artifact,
        id,
        sessionId,
        session,
        isMobile,
        toggleLeftDrawer,
        isActive,
        timeSpentInSeconds,
        startAt,
        index,
        displayedAgendaItems,
        isAssistant,
        isAdmin,
        isPaused,
    } = props;

    const {currentParticipant, backstage} = session;
    const descriptor = artifactDescriptor[(artifact?.artifactId as ArtifactTag) || "flowos/conference"];
    const icon = descriptor?.meta?.icon ? (
        <img src={`${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor?.meta?.icon}.svg`} />
    ) : (
        <AcUnitOutlinedIcon />
    );
    const {roomsHaveParticipants} = useBreakoutInfo();

    const descriptionSerialized = serialize(description as any);
    const artifactOrder = order || 0;

    const {pid: myParticipantId} = currentParticipant || {pid: ""};

    const handleChangeOrder = (event: React.MouseEvent<HTMLElement>) => {
        setPidThatChangedArtifact(myParticipantId);
        orchestrator.gotoIndex(sessionId, artifactOrder, !roomsHaveParticipants);

        if (isMobile) {
            toggleLeftDrawer(DrawerState.Closed);
        }
    };

    const isFirst = displayedAgendaItems.some((item) => item.order === 0);
    const isPassed = (index === 0 && !isActive) || (index === 1 && !isActive && !isFirst);

    return (
        <div
            className={cls(
                classes.agendaItemWrapper,
                isActive && classes.activeAgendaItemWrapper,
                isPassed && classes.passedAgendaItemWrapper,
                !isAssistant && !isAdmin && classes.agendaItemWrapperNoClick
            )}
            onClick={isAssistant || isAdmin ? handleChangeOrder : undefined}
        >
            <div className="flex flex-row flex-justify-between fullw mb-12">
                <Typography variant="sm" fontWeight="bold" color="blueGray400" className={classes.agendaIndex}>
                    #{order + 1}
                </Typography>
                {backstage ? null : (
                    <Typography variant="sm" fontWeight="bold" color="blueGray400">
                        <AgendaBoxTime
                            duration={duration!}
                            timeSpentInSeconds={timeSpentInSeconds!}
                            startAt={startAt}
                            isActive={isActive}
                            isPaused={isPaused}
                        />
                    </Typography>
                )}
            </div>
            <Typography
                variant="base"
                fontWeight="bold"
                color="primary"
                noWrap
                // data-private
            >
                {title}
            </Typography>
            {description ? (
                <div
                    className={classes.previewAgendaDescription}
                    dangerouslySetInnerHTML={{
                        __html: descriptionSerialized,
                    }}
                    // data-private
                ></div>
            ) : null}
            <div>
                {!!artifact?.artifactId && artifact.artifactId !== "flowos/conference" ? (
                    <>
                        <li className="separator" />
                        <div className="flex flex-row fullw mt-8 gap-4">
                            {icon}
                            <Typography variant="sm" color="primary" component="p" data-private>
                                {artifact?.name}
                            </Typography>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default AgendaItemMenuItem;
