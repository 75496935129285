import designer from "../..";
import {DesignerAction} from "../../action";

export interface RemoveMacroArtifactInput {
    id?: string;
    artifactTag?: string;
}

export class RemoveMacroArtifact extends DesignerAction<RemoveMacroArtifactInput> {
    commit() {
        const macroArtifacts = structuredClone(designer.currentMacroArtifacts() ?? []);
        if (!this.input.id && !this.input.artifactTag) {
            return "false";
        }
        const removeIndex = macroArtifacts.findIndex((a) =>
            this.input.id ? a.id === this.input.id : this.input.artifactTag ? a.artifactId === this.input.artifactTag : false
        );
        const macroToRemoveId = macroArtifacts[removeIndex!].id;
        if (removeIndex !== -1) {
            const macro = macroArtifacts[removeIndex!];
            if (macro.createdAt) {
                macro.isDeleted = true;
                macroArtifacts[removeIndex!] = macro;
            } else {
                macroArtifacts.splice(removeIndex, 1);
            }

            designer.updateCurrentSession(
                {
                    macroArtifacts,
                },
                {
                    strategy: "replace",
                }
            );
        }

        return macroToRemoveId;
    }
}
