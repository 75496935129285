import {useEffect} from "@workhorse/api/rendering";
import {removeBranding, setBranding} from "@workhorse/pages/user/profile/Theming/utils";
import {useUserInfo} from "./User";

const WorkspaceBrandingProvider = () => {
    const user = useUserInfo();

    const {activeWorkspace} = user;

    const branding = activeWorkspace?.branding;

    useEffect(() => {
        if (branding?.active) {
            setBranding(branding);
        } else {
            removeBranding();
        }
    }, [activeWorkspace?.id, branding]);

    return <></>;
};

export default WorkspaceBrandingProvider;
