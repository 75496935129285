import designer from "../..";
import {DesignerAction} from "../../action";

export interface LockAgendaItemLockInput {
    id: string;
    locked: string | null;
}

export class UpdateAgendaItemLock extends DesignerAction<LockAgendaItemLockInput> {
    commit() {
        const agendaItems = structuredClone(designer.currentAgendaItems() ?? []);
        const agendaItemIndex = agendaItems.findIndex((a) => a.id === this.input.id);
        const shouldLock = !!agendaItems[agendaItemIndex!].createdAt && agendaItems[agendaItemIndex!].locked !== this.input.locked;

        if (agendaItemIndex !== -1 && shouldLock) {
            agendaItems[agendaItemIndex!] = {
                ...agendaItems[agendaItemIndex!],
                locked: this.input.locked,
            };

            designer.updateCurrentSession(
                {
                    agendaItems,
                },
                {
                    strategy: "replace",
                }
            );
        }

        return shouldLock.toString();
    }
}
