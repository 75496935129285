import Input from "@ui/cdk/Input";
import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import genericStyles from "../../../styles/GenericSection.module.scss";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import classes from "../styles/SessionRequestPasscode.module.scss";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import Checkbox from "@ui/cdk/Checkbox";

// Allows all digits, letters and special characters
const validationRegex = /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;

const MIN_CHAR_COUNT = 6;
const MAX_CHAR_COUNT = 12;

export const generateSessionPasscode = (length?: number) =>
    Math.random()
        .toString(36)
        .substr(2, length ?? 6);

const styles = {...genericStyles, ...classes};

type SessionRequestPasscodeProps = {
    enabledRequest?: boolean;
    enabledBypass?: boolean;
    passcodeValue?: string;
    onChangeToggleRequest?: (enabled: boolean) => void;
    onChangeToggleBypass?: (enabled: boolean) => void;
    onChangePasscodeValue?: (value: string | null) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const SessionRequestPasscode = ({
    enabledRequest = false,
    enabledBypass = false,
    passcodeValue = "",
    onChangeToggleRequest,
    onChangeToggleBypass,
    onChangePasscodeValue,
    showIcon = false,
    disabled,
    className,
}: SessionRequestPasscodeProps) => {
    const {t} = useTranslation();

    const [value, setValue] = useState<string>(passcodeValue);
    const [error, setError] = useState<"minLength" | "forbiddenCharacters" | null>(null);

    const handleChangeToggleRequest = () => {
        onChangeToggleRequest?.(!enabledRequest);
    };

    const handleChangeToggleBypass = () => {
        onChangeToggleBypass?.(!enabledBypass);
    };

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setValue(value);

        if (value.length < MIN_CHAR_COUNT) {
            setError("minLength");
            return;
        }

        if (!validationRegex.test(value)) {
            setError("forbiddenCharacters");
            return;
        }

        if (error) {
            setError(null);
        }
    };

    const handleBlurValue = (e: React.FocusEvent<HTMLInputElement>) => {
        if (error) {
            return;
        }

        onChangePasscodeValue?.(value);
    };

    useEffect(() => {
        if (passcodeValue && passcodeValue !== value) {
            setValue(passcodeValue);
        }
    }, [passcodeValue]);

    return (
        <div className={cls(styles.togglerWrapper, styles.togglerWrapperWithFooter, className)}>
            <div className="flex fullw">
                {showIcon ? (
                    <div className={styles.iconWrapper}>
                        <LockRoundedIcon />
                    </div>
                ) : null}
                <TogglerContainer
                    title={
                        <Typography variant="base" fontWeight="bold" color="secondary">
                            {t("player.settings.general.access.request_passcode.title")}
                        </Typography>
                    }
                    secondTitle={
                        <Typography color="quaternary" component="span">
                            {t("player.settings.general.access.request_passcode.description")}
                        </Typography>
                    }
                    checked={enabledRequest}
                    name="requestPasscode"
                    onChange={handleChangeToggleRequest}
                    togglePlacement="secondTitle"
                    label=" "
                    className={cls(styles.toggler)}
                    disabled={disabled}
                />
            </div>

            {onChangeToggleBypass && enabledRequest && (
                <FormControlLabel
                    control={<Checkbox />}
                    label={
                        <Typography variant="base" color="secondary">
                            {t("player.settings.general.access.request_passcode.checkbox.title")}
                        </Typography>
                    }
                    labelPlacement="end"
                    checked={enabledBypass}
                    onChange={handleChangeToggleBypass}
                    className={styles.bypassCheckbox}
                    disabled={disabled}
                />
            )}

            {onChangePasscodeValue && enabledRequest && (
                <div className="flex flex-col fullw">
                    <Input
                        data-id="participants-container-search"
                        value={value}
                        onChange={handleChangeValue}
                        onBlur={handleBlurValue}
                        placeholder={t("g.form.field_placeholder.passcode") ?? undefined}
                        // placeholder={"Enter passcode"}
                        variant="standard"
                        maxCharCount={MAX_CHAR_COUNT}
                        showTypedCharCount
                        formControlClassName={styles.inputRoot}
                        className={cls("rnd-btn", styles.input)}
                        disabled={disabled}
                        error={!!error}
                    />
                    {!!error && (
                        <Typography variant="sm" className={styles.errorText}>
                            {error === "minLength" &&
                                t("player.settings.general.access.request_passcode.input.error.min_length", {MIN_CHAR_COUNT})}
                            {error === "forbiddenCharacters" &&
                                t("player.settings.general.access.request_passcode.input.error.forbidden_characters")}
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
};

export default SessionRequestPasscode;
