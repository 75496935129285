import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/UsageHistoryEmptyState.module.scss";

const UsageHistoryEmptyState = () => {
    return (
        <div className={cls("flex flex-col fullh my-auto", classes.root)}>
            <div className="flex flex-col flex-align-center my-auto">
                <Typography color="secondary" fontWeight="bolder" variant="xl">
                    No sessions found
                </Typography>

                <Typography color="tertiary" className="mt-10">
                    This resource or agenda has not been used yet.
                </Typography>
            </div>
        </div>
    );
};

export default UsageHistoryEmptyState;
