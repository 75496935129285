import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import {cls} from "@ui/cdk/util";
import {useRef} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useTranslation} from "react-i18next";
import {SessionSettingsSectionProps} from "../../definitions";
import genericStyles from "../../styles/GenericSection.module.scss";
import updateSessionFlags from "../../utils/updateSessionFlags";
import ChatAndQuestionsToggler from "./components/ChatAndQuestionsToggler";
import ParticipantsJoiningSessionNotificationToggler from "./components/ParticipantsJoiningSessionNotificationToggler";
import SettingsReactionsToggler from "./components/SettingsReactionsToggler";

export enum notificationIds {
    PARTICIPANTS_ENTER_LEAVE = "participantsEnterLeave",
    CHAT_AND_QUESTIONS = "chatAndQuestions",
    REACTIONS = "reactions",
}

const NotificationsSection = ({
    onSubmitDone,
    onTouch,
    onValidate,
    onSettingsStateUpdate,
    submitting,
    touched,
    error,
    globalState,
    className,
    ...rest
}: SessionSettingsSectionProps<"device.notifications">) => {
    const {isMobile} = useMobile();

    const {t} = useTranslation();

    const scrollContainerRef = useRef<ScrollContainerRef>({});
    const session = useSession();

    const handleOnChange = (notificationId: notificationIds) => {
        updateSessionFlags(session?.id, {
            disabledNotifications: session.disabledNotifications.some((x) => x === notificationId)
                ? session.disabledNotifications.filter((x) => x !== notificationId)
                : session.disabledNotifications.concat([notificationId as string]),
        });
    };

    const isChatAndQuestionsDisabled = session?.disabledNotifications?.some((x) => x === "chatAndQuestions");
    const isReactionsDisabled = session?.disabledNotifications?.some((x) => x === "reactions");
    const isParticipantsEnterLeave = session?.disabledNotifications?.some((x) => x === "participantsEnterLeave");

    return (
        <div {...rest} className={cls(className, genericStyles.root)}>
            <ScrollContainer
                component="div"
                id="notifications-section-options-wrapper"
                connector={scrollContainerRef}
                axis="y"
                scrollBarOffsetTop={5}
                scrollBarOffsetBottom={5}
                isMobile={isMobile}
                key="session-section-options-wrapper"
                className={genericStyles.optionsWrapper}
            >
                <ParticipantsJoiningSessionNotificationToggler
                    enabled={!isParticipantsEnterLeave}
                    toggleNotifications={handleOnChange}
                    className="pb-20"
                />
                <ChatAndQuestionsToggler enabled={!isChatAndQuestionsDisabled} toggleNotifications={handleOnChange} className="pb-20" />
                <SettingsReactionsToggler enabled={!isReactionsDisabled} toggleNotifications={handleOnChange} className="pb-20" />
            </ScrollContainer>
        </div>
    );
};

export default NotificationsSection;
