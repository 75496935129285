import {useState, useEffect, useMemo} from "@workhorse/api/rendering";
import Input from "@ui/cdk/Input";
import {cls, collapseWhiteSpace} from "@ui/cdk/util";
import classes from "./style/EventsLandingPageSocialLinks.module.scss";
import Typography from "@ui/cdk/Typography";
import {capitalize} from "@workhorse/util";
import designer from "@workhorse/api/designer";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {socialLinksRegex} from "./utils";
import {formatLinkHttps} from "../../../../../artifacts/iframe/api/iframeUtils";
import {useMutation} from "@workhorse/dataApi";

type SocialUrls = {
    linkedin: string;
    facebook: string;
    twitter: string;
    youtube: string;
    slack: string;
    instagram: string;
};

const EventsLandingPageSocialLinks = ({readOnly}: {readOnly: boolean}) => {
    const {event, id} = useSession();

    const [eventLinks, setEventLinks] = useState(event?.socialLinks);
    const [lockEvent] = useMutation("LockEventDocument");

    const [errors, setErrors] = useState({
        linkedin: false,
        facebook: false,
        twitter: false,
        youtube: false,
        slack: false,
        instagram: false,
    });

    useEffect(() => {
        setEventLinks(event?.socialLinks);
    }, [event?.socialLinks]);

    const sortEventLinks = (eventLinks: SocialUrls): SocialUrls => {
        const sorted = Object.keys(eventLinks)
            .sort()
            .reduce((obj, key) => {
                obj[key] = eventLinks[key];
                return obj;
            }, {});
        return sorted as SocialUrls;
    };

    const sortedEventLinks = useMemo<SocialUrls>(() => sortEventLinks(eventLinks), [eventLinks]);

    const handleLinksChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEventLinks({...eventLinks, [e.target.name]: collapseWhiteSpace(e.target.value).trim()});
        setErrors({...errors, [e.target.name]: false});
    };

    const onBlurLinks = () => {
        const linksToUpdate: SocialUrls = {
            linkedin: "",
            facebook: "",
            twitter: "",
            youtube: "",
            slack: "",
            instagram: "",
        };
        let shouldUpdate = false;

        // checks if link is valid and different from the one in the event
        Object.keys(eventLinks).forEach((key) => {
            if (eventLinks[key] !== event?.socialLinks[key]) {
                if (socialLinksRegex[key].test(eventLinks[key])) {
                    linksToUpdate[key] = formatLinkHttps(eventLinks[key]);
                    shouldUpdate = true;
                } else {
                    // if user had a link and cleared it
                    if (eventLinks[key] === "" && event?.socialLinks[key] !== "") {
                        linksToUpdate[key] = " ";
                        shouldUpdate = true;
                    } else {
                        setErrors({...errors, [key]: true});
                    }
                }
            }
        });

        if (shouldUpdate) {
            // add valid links, don't add empty ones to payload, set the ones to be cleared to empty string
            const linksPayload = Object.fromEntries(
                Object.entries(linksToUpdate)
                    .filter(([_, v]) => v !== "")
                    .map(([k, value]) => [k, value.trim() ? value : ""])
            );

            designer.api.event.update({
                socialLinks: {...event?.socialLinks, ...linksPayload},
            });
            designer.commit();
        } else {
            lockEvent({variables: {id}});
        }
    };

    const getSocialLinksLabels = (name: string) => {
        if (name === "linkedin") {
            return "LinkedIn";
        } else if (name === "youtube") {
            return "YouTube";
        } else {
            return capitalize(name);
        }
    };

    return (
        <div className={classes.configuration}>
            <div className={cls("mt-40 flex flex-justify-between", classes.title)}>
                <Typography component="div" color="tertiary" variant="lg">
                    Social Links
                </Typography>
            </div>
            {Object.entries(sortedEventLinks).map((singleUrl, index) => {
                return (
                    <div className={classes.socialLinkInputWrapper} key={index}>
                        <Typography variant="sm" color="tertiary" className="flex flex-align-center flex-justify-between">
                            {getSocialLinksLabels(singleUrl[0])} URL
                        </Typography>
                        <Input
                            name={singleUrl[0]}
                            id={singleUrl[0]}
                            type="text"
                            disabled={readOnly}
                            className={cls(classes.socialLinkInput)}
                            autoFocus={false}
                            value={singleUrl[1]}
                            formControlClassName={classes.socialLinkInputWrapper}
                            onChange={handleLinksChange}
                            onBlur={onBlurLinks}
                            error={errors[singleUrl[0]]}
                            helperText={errors[singleUrl[0]] ? `Invalid ${capitalize(singleUrl[0])} URL` : ""}
                            inputProps={{
                                "data-private": "lipsum",
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default EventsLandingPageSocialLinks;
