import {SessionFlagsType} from "@generated/data";

export const sessionFlagBasedPermission = (
    flag: SessionFlagsType,
    role: {
        owner: boolean;
        assistant: boolean;
        speaker: boolean;
    }
) => {
    if (flag === SessionFlagsType.Everyone) {
        return true;
    }

    if (flag === SessionFlagsType.Hosts) {
        return role["owner"] || role["assistant"] || false;
    }

    if (flag === SessionFlagsType.Speakers) {
        return role["speaker"] || role["owner"] || role["assistant"] || false;
    }
    return false;
};
