import {askParticipant} from "@artifacts/conference/api/conferenceControllerApi";
import {ConferenceParticipantStatus} from "@generated/data";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useCallback, useEffect, useState, useRef, useMemo} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {rbac} from "@workhorse/api/user";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useDataEvent} from "@workhorse/dataApi";
import {useIsPreJoin} from "@workhorse/providers/SessionDataProviders";
import {useDevices} from "../MediaProvider";
import {useHardMute} from "./HardMuteProvider";
import {useMediaMute} from "./MediaMuteProvider";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import {useSessionInfo} from "../SessionInfoProvider";
import {useParticipantsStore} from "../ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type ForceMuteStatus = "unset" | "mic-on" | "soft" | "hard";
type MuteType = "media" | "hard" | "soft" | "individual" | "reset";

type UserStatus = {
    isMuted: boolean;
    isSpeaker: boolean;
    isAssistant: boolean;
    isOwner: boolean;
    lastMutedValue: boolean;
    selfStatusChange: boolean;
    wasPromoted?: boolean;
    wasDemoted?: boolean;
};

type StatusHistory = {
    muteType?: MuteType;
    muteStatus: ForceMuteStatus;
    userStatus: UserStatus;
};

function getLatestStatus(list: StatusHistory[]) {
    if (list.length > 0) {
        return list[list.length - 1];
    }
}

function isUserPromoted(current: UserStatus) {
    return current.isSpeaker || current.isAssistant;
}

function wasUserPromoted(prev: UserStatus, current: UserStatus) {
    if ((prev.isSpeaker || prev.isAssistant) && prev.wasPromoted !== true) {
        return false;
    }
    return isUserPromoted(current);
}

function wasUserDemoted(prev: UserStatus, current: UserStatus) {
    if (!prev.isSpeaker && !prev.isAssistant && prev.wasDemoted !== true) {
        return false;
    }

    return !isUserPromoted(current);
}

function createCurrentStatus(prev: StatusHistory, muteType: MuteType, muteStatus: ForceMuteStatus, userStatus: UserStatus): StatusHistory {
    return {
        muteType,
        muteStatus,
        userStatus: {
            ...userStatus,
            wasPromoted: wasUserPromoted(prev.userStatus, userStatus),
            wasDemoted: wasUserDemoted(prev.userStatus, userStatus),
        },
    };
}

function handleMediaMuteTransitions(muteStatus: ForceMuteStatus, userStatus: UserStatus, prevList: StatusHistory[]): StatusHistory[] {
    const prevStatus = getLatestStatus(prevList);
    if (!prevStatus || prevStatus.muteType !== "media") {
        return [{muteStatus, userStatus, muteType: "media"}];
    }

    const currentStatus = createCurrentStatus(prevStatus, "media", muteStatus, userStatus);
    // const {wasPromoted, selfStatusChange} = currentStatus.userStatus;

    // if (wasPromoted && selfStatusChange && !userStatus.isMuted && !prevStatus.userStatus.isMuted) {
    //     currentStatus.muteStatus = "mic-on";
    // }

    return [prevStatus, currentStatus];
}

function handleHardMuteTransitions(muteStatus: ForceMuteStatus, userStatus: UserStatus, prevList: StatusHistory[]): StatusHistory[] {
    const prevStatus = getLatestStatus(prevList);
    if (!prevStatus || prevStatus.muteType !== "hard") {
        return [{muteStatus, userStatus, muteType: "hard"}];
    }

    const currentStatus = createCurrentStatus(prevStatus, "hard", muteStatus, userStatus);
    const {isSpeaker, wasPromoted} = currentStatus.userStatus;

    if (wasPromoted && isSpeaker) {
        currentStatus.muteStatus = "mic-on";
    }

    return [prevStatus, currentStatus];
}

function handleSoftMuteTransitions(muteStatus: ForceMuteStatus, userStatus: UserStatus, prevList: StatusHistory[]): StatusHistory[] {
    const prevStatus = getLatestStatus(prevList);
    if (!prevStatus || prevStatus.muteType !== "soft") {
        return [{muteStatus, userStatus, muteType: "soft"}];
    }

    const currentStatus = createCurrentStatus(prevStatus, "soft", muteStatus, userStatus);
    const {isSpeaker, wasPromoted} = currentStatus.userStatus;

    if (wasPromoted && isSpeaker) {
        currentStatus.muteStatus = "mic-on";
    }

    return [prevStatus, currentStatus];
}

function handleIndividualMuteTransitions(muteStatus: ForceMuteStatus, userStatus: UserStatus, prevList: StatusHistory[]): StatusHistory[] {
    const prevStatus = getLatestStatus(prevList);
    if (!prevStatus || prevStatus.muteType !== "individual") {
        return [{muteStatus, userStatus, muteType: "individual"}];
    }

    const currentStatus = createCurrentStatus(prevStatus, "individual", muteStatus, userStatus);
    const {isSpeaker, wasPromoted} = currentStatus.userStatus;

    if (wasPromoted && isSpeaker) {
        currentStatus.muteStatus = "mic-on";
    }

    return [prevStatus, currentStatus];
}

function handleResetMuteTransition(userStatus: UserStatus, prevList: StatusHistory[]): StatusHistory[] {
    const prevStatus = getLatestStatus(prevList);
    if (!prevStatus) {
        return [{muteStatus: "unset", userStatus, muteType: "reset"}];
    }

    const currentStatus = createCurrentStatus(prevStatus, "reset", "unset", userStatus);
    const {isSpeaker, wasPromoted} = currentStatus.userStatus;

    if (prevStatus.muteType === "media" && prevStatus.muteStatus === "soft" && userStatus.lastMutedValue === false) {
        currentStatus.muteStatus = "mic-on";
    }

    if (wasPromoted && isSpeaker) {
        currentStatus.muteStatus = "mic-on";
    }

    return [prevStatus, currentStatus];
}

export function useForceMuteStatusStore() {
    const {currentParticipantId, exists, isOwner, isSpeaker, selfStatusChange, isAssistant} = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            currentParticipantId: currentParticipant?.id,
            isAssistant: amIAssistant,
            exists: !!currentParticipant,
            isOwner: currentParticipant?.isOwner ?? false,
            isSpeaker:
                currentParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker || !!currentParticipant?.speakerDetails?.id,
            selfStatusChange:
                currentParticipant?.statusChangedBy?.participantId === currentParticipant?.id && currentParticipant?.id != null,
        }))
    );

    const {isPreJoin} = useIsPreJoin();

    const {audioMuted, setAudioMuted} = useDevices();

    const lastMutedValue = audioMuted;

    const media = useMediaMute();
    const hard = useHardMute();

    const [individualMute, setIndividualMute] = useState(false);
    const [sessionSoftMute, setSessionSoftMute] = useState(false);

    const isMuted = individualMute;
    const isSoftMuted = sessionSoftMute;

    const {allowMicrophoneType} = useSessionInfo();

    const isMicrophoneAllowed = sessionFlagBasedPermission(allowMicrophoneType, {
        owner: isOwner,
        assistant: isAssistant,
        speaker: isSpeaker,
    });

    useDataEvent("onWatchdogPrivate", (data) => {
        if (isPreJoin) {
            return;
        }

        if (data?.watchdogPrivate?.softMuteAll === true) {
            if (!isSpeaker && !isAssistant && !isOwner) {
                setSessionSoftMute(true);
                setAudioMuted(true);
            }
        }

        if (data.watchdogPrivate?.participantIndividualMute) {
            setIndividualMute(true);
            setAudioMuted(true);
        }
    });

    const [status, setStatus] = useState<StatusHistory[]>([]);

    const pushMediaMute = useCallback((muteStatus: ForceMuteStatus, userStatus: UserStatus) => {
        setStatus((prevList) => handleMediaMuteTransitions(muteStatus, userStatus, prevList));
    }, []);

    const pushHardMute = useCallback((muteStatus: ForceMuteStatus, userStatus: UserStatus) => {
        setStatus((prevList) => handleHardMuteTransitions(muteStatus, userStatus, prevList));
    }, []);

    const pushSoftMute = useCallback((muteStatus: ForceMuteStatus, userStatus: UserStatus) => {
        setStatus((prevList) => handleSoftMuteTransitions(muteStatus, userStatus, prevList));
    }, []);

    const pushIndividualMute = useCallback((muteStatus: ForceMuteStatus, userStatus: UserStatus) => {
        setStatus((prevList) => handleIndividualMuteTransitions(muteStatus, userStatus, prevList));
    }, []);

    const resetStatus = useCallback((userStatus: UserStatus) => {
        setStatus((prevList) => handleResetMuteTransition(userStatus, prevList));
    }, []);

    useEffect(() => {
        if (!exists) {
            return;
        }

        const userStatus: UserStatus = {
            isMuted,
            isOwner,
            isSpeaker,
            isAssistant,
            lastMutedValue,
            selfStatusChange,
        };

        const resetSoftMute = () => {
            setIndividualMute(false);
            setSessionSoftMute(false);
        };

        if (!isMicrophoneAllowed) {
            resetSoftMute();
            pushHardMute("hard", userStatus);
        }

        if (media.mediaMuted) {
            resetSoftMute();

            // Always hard mute on ios
            if (browserInfo.isIOS()) {
                pushMediaMute("hard", userStatus);
                return;
            }

            if (isOwner || isAssistant || isSpeaker) {
                pushMediaMute("soft", userStatus);
                return;
            }

            pushMediaMute("hard", userStatus);
            return;
        }

        if (hard.hardMuted) {
            if (!isSpeaker && !isAssistant && !isOwner) {
                resetSoftMute();
                pushHardMute("hard", userStatus);
                return;
            }
        }

        if (isSoftMuted) {
            if (!isSpeaker && !isAssistant && !isOwner) {
                pushSoftMute("soft", userStatus);
                return;
            }

            pushSoftMute("unset", userStatus);
            return;
        }

        if (isMuted) {
            pushIndividualMute("soft", userStatus);
            return;
        }

        resetStatus(userStatus);
    }, [
        exists,
        hard,
        media.mediaMuted,
        isSpeaker,
        isAssistant,
        isOwner,
        isMuted,
        isSoftMuted,
        lastMutedValue,
        selfStatusChange,
        pushMediaMute,
        pushHardMute,
        pushSoftMute,
        pushIndividualMute,
        resetStatus,
        isMicrophoneAllowed,
    ]);

    useEffect(() => {
        if (audioMuted === false) {
            setIndividualMute(false);
            setSessionSoftMute(false);
        }
    }, [audioMuted]);

    const wasSpeaker = useRef(isSpeaker);

    useEffect(() => {
        if (!wasSpeaker.current && isSpeaker) {
            setIndividualMute(false);
            setSessionSoftMute(false);
        }

        // Ask the speaker to unmute if they were promoted to speaker
        if (isSpeaker && audioMuted && wasSpeaker.current === false) {
            askParticipant(currentParticipantId ?? "", {askToUnmute: true}).catch(() => {
                toast("Something went wrong with asking the participant to unmute.", {type: "error"});
            });
        }

        wasSpeaker.current = isSpeaker;
    }, [isSpeaker, audioMuted]);

    const latestStatus = getLatestStatus(status);

    return useMemo(() => {
        return {
            muteStatus: latestStatus?.muteStatus,
            muteType: latestStatus?.muteType,
        };
    }, [latestStatus?.muteStatus, latestStatus?.muteType]);
}

export const [ForceMuteStatusProvider, useForceMuteStatus] = createContextProvider(
    {
        name: "ForceMuteStatus",
    },
    useForceMuteStatusStore
);
