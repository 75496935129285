import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as AllowShareScreenIcon} from "../../assets/allow-share-screen.svg";
import Select from "@ui/cdk/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {SessionFlagsType} from "@generated/data";
import {useTranslation} from "react-i18next";

const styles = {...genericStyles};

type SessionAllowShareScreenSelectorProps = {
    value: SessionFlagsType;

    onChange: (val: SessionFlagsType) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const SessionAllowShareScreenSelector = ({
    value,
    onChange,
    showIcon = false,
    disabled,
    className,
}: SessionAllowShareScreenSelectorProps) => {
    const {t} = useTranslation();

    const options = [
        {value: SessionFlagsType.Everyone, text: t("g.session.values_role.everyone") ?? "Everyone"},
        {value: SessionFlagsType.Speakers, text: t("g.session.values_role.speakers_hosts") ?? "Speakers & Hosts"},
        {value: SessionFlagsType.Hosts, text: t("g.session.values_role.hosts") ?? "Hosts"},
    ];

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value as SessionFlagsType;
        onChange(val);
    };

    return (
        <div className={cls(styles.togglerWrapper, className)}>
            {showIcon ? (
                <div className={styles.iconWrapper}>
                    <AllowShareScreenIcon />
                </div>
            ) : null}

            <div className="flex flex-justify-between flex-align-center fullw">
                <div className={styles.textWrapper}>
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("player.settings.general.session.screen_share.title")}
                    </Typography>
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.session.screen_share.description")}
                    </Typography>
                </div>

                <Select
                    data-id="screen-sharing-settings"
                    className={styles.selectInput}
                    options={options}
                    value={value}
                    IconComponent={ExpandMoreRoundedIcon}
                    onChange={handleOnChange}
                    MenuProps={{
                        classes: {
                            paper: styles.selectMenuPaper,
                        },
                    }}
                    wrapperClassName={styles.selectFormControl}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default SessionAllowShareScreenSelector;
