import {useEffect, useState} from "@workhorse/api/rendering";

export function useDeferredValue(id: string | null | undefined, value: boolean, deferValue: boolean, delay = 1000) {
    const [defer, setDefer] = useState<boolean>(true);

    useEffect(() => {
        setDefer(true);

        const t = setTimeout(() => {
            setDefer(false);
        }, delay);

        return () => {
            clearTimeout(t);
        };
    }, [value, id, delay]);

    return defer ? deferValue : value;
}

export type OnboardingLonelinessType = "agenda" | "transcript" | "participants";
