import {WithChildren} from "@workhorse/declarations";
import Loading from "@workhorse/components/Loading";
import environment from "@generated/environment";
import {routes} from "@workhorse/pages";
import {useHostType} from "./providers/HostTypeProvider";
import {useEffect, useState} from "./api/rendering";
import {useUserInfo} from "./providers/User";
import {AuthService} from "@workhorse/api/authService";

function GuestDisallowedRoutes(props: WithChildren) {
    const {isGuest} = useUserInfo();
    const currentHostType = useHostType();
    const [mounted, setMounted] = useState(false);

    const blockRender = isGuest && currentHostType && routes[currentHostType] && !routes[currentHostType].guestAllowed;

    useEffect(() => {
        if (blockRender) {
            console.log(
                `[GuestDisallowedRoutes] removeItem access_token ${isGuest}, ${currentHostType}, ${JSON.stringify(routes[currentHostType])}`
            );
            AuthService.getInstance().logout(environment.landingPage);
        } else if (!mounted) {
            setMounted(true);
        }
    }, [blockRender, currentHostType]);

    if (blockRender) {
        return <Loading location="GuestDisallowedRoutes.tsx" />;
    }

    return (mounted ? props.children : null) as unknown as JSX.Element;
}

export default GuestDisallowedRoutes;
