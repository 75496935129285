import {mutate, readQuery} from "@workhorse/dataApi";
import {DesignerApiSession, DesignerApiSession as Session} from "@workhorse/declarations/dataTypes";
import {makeSessionIdFromRoute, isEventRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {
    readDesignerAgendaItems,
    readDesignerCurrentParticipant,
    readDesignerMacroArtifacts,
    readDesignerParticipants,
    readDesignerSession,
} from "@workhorse/providers/DesignerSessionDataProviders";
import {capitalize} from "@workhorse/util";
import {PartialDeep} from "type-fest";
import {DesignerAction} from "./action";
import buildCreateSessionPayload from "./buildCreateSessionPayload";
import {OmitTypenames} from "@sessions/common/diff/types";
import buildUpdateSessionPayload from "@sessions/common/diff/payloads/buildUpdateSessionPayload";
import {createDiff, extractDiff, ingestDiff, removeNewArrayItems} from "@sessions/common/diff/computeDiff";
import {createDiff as createDiffCollaborative} from "@sessions/common/diff/computeDiffCollaborative";

import * as constants from "./constants";
import designerState from "./designer-state";
import {makeProxy} from "./proxy";
import {patch, removeTypenameKey, strategicPatch} from "./utils";
import {
    AgendaItemType,
    ConfigurationConfirmType,
    ConfigurationStep,
    DesignerState,
    SessionLifecycle,
    SessionNoCacheFragment,
    UpdateParticipantsInSessionMutationVariables,
    MyProductToolsDocument,
    HostType,
    GetRemoteUserDocument,
} from "@generated/data";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import {
    FinalAction,
    ActionCategTreeItem,
    makeArtifactItems,
    makeCategTree,
    makeCategMapForSearch,
    makeCategMap,
} from "@workhorse/components/command-palette/actionCategTree";
import myProductDefaultIcon from "../../../assets/media/icon-my-product-no-bg.svg";
import {makeWantedPaletteActionMap} from "@workhorse/providers/OnboardingSessionProvider";
import {ERROR_NO_SESSION_ID} from "./constants";
import apollo, {ApolloOperationContext} from "@workhorse/api/apollo";
import {makeHostTypeFromRoute} from "@workhorse/util/makeHostTypeFromRoute";
import {activeWorkspacePermissions} from "@workhorse/providers/User/utils";
import {PartialObjectDeep} from "type-fest/source/partial-deep";

const DESIGNER_MAX_HISTORY = 40;

interface Touple extends Array<number> {
    0: number;
    1: number;
}

class DesignerApi {
    public state = designerState;
    private _api = makeProxy(this.pushAction.bind(this));
    private batchedActionPointers: Array<Touple> = [];

    public productToolInitialized = false;

    public myProductToolInPalette = async () => {
        const remoteUserQuery = apollo.cache.readQuery({
            query: GetRemoteUserDocument,
        });
        const workspaceId = activeWorkspacePermissions(remoteUserQuery?.getRemoteUser.user?.workspacePermissions)?.workspace?.id;

        if (workspaceId) {
            const {data} = await apollo.client.query({
                query: MyProductToolsDocument,
                variables: {
                    workspaceId,
                },
                fetchPolicy: "cache-first",
            });
            const myProductTools = data.myProductTools;

            if (myProductTools?.length) {
                const addTools = myProductTools
                    .filter((t) => t.enabled)
                    .map((t) => {
                        return {
                            id: t.id,
                            description: t.description,
                            icon: t.iconUrl || myProductDefaultIcon,
                            title: t.title,
                            isHidden: false,
                            excludeFromRootSearch: false,
                            artifactTag: "flowos/my-product-tool",
                            hasNoResource: true,
                            actionArg: {
                                action: FinalAction.Create,
                                isTool: true,
                                artifactTag: "flowos/my-product-tool",
                            },
                            next: [],
                        } as ActionCategTreeItem;
                    });
                const removeTools = myProductTools.filter((t) => !t.enabled).map((t) => t.id);

                if (addTools.length || removeTools.length) {
                    makeArtifactItems(addTools.length ? addTools : undefined, removeTools.length ? removeTools : undefined);
                }

                makeCategTree();
                makeCategMapForSearch();
                makeCategMap();
                makeWantedPaletteActionMap();
            }
            this.productToolInitialized = true;
        }
    };

    public get api() {
        return this._api;
    }

    public participantsPayload: UpdateParticipantsInSessionMutationVariables | null = null;

    private actions = Array.from({length: DESIGNER_MAX_HISTORY}, () => undefined as unknown as DesignerAction);
    private actionPointer = 0;

    // init(sessionId: string) {
    //     this.state.initializeOrResetState(sessionId);
    // }

    public constants = constants;
    public storageKey = constants.storageKey;

    public currentAgendaItems(returnUnchangedData?: boolean) {
        return readDesignerAgendaItems({
            noDiff: returnUnchangedData,
        }) as Session["agendaItems"];
    }

    public currentParticipants(returnUnchangedData?: boolean) {
        return readDesignerParticipants({
            noDiff: returnUnchangedData,
        }) as Session["participants"];
    }

    public currentMacroArtifacts(returnUnchangedData?: boolean) {
        return readDesignerMacroArtifacts({
            noDiff: returnUnchangedData,
        }) as Session["macroArtifacts"];
    }

    public currentCurrentParticipant(returnUnchangedData?: boolean) {
        return readDesignerCurrentParticipant({
            noDiff: returnUnchangedData,
        }) as Session["currentParticipant"];
    }

    public currentSession(returnUnchangedData?: boolean) {
        return readDesignerSession({
            noDiff: returnUnchangedData,
        }) as Session["session"];
    }

    public changedData(returnUnchangedData?: boolean): Session | undefined {
        return {
            session: this.currentSession(returnUnchangedData),
            agendaItems: this.currentAgendaItems(returnUnchangedData),
            macroArtifacts: this.currentMacroArtifacts(returnUnchangedData),
            participants: this.currentParticipants(returnUnchangedData),
            currentParticipant: this.currentCurrentParticipant(returnUnchangedData),
        };
    }

    public currentData() {
        return this.changedData(true);
    }

    /**
     *
     * @deprecated
     * DO NOT FUCKING USE THIS
     * this method is used ONLY internally by the designer methods
     * if you need to update/create something
     * FUCKING USE the api specific method
     */
    public updateCurrentSession(
        update: PartialDeep<Session>,
        options: {
            strategy?: "patch" | "strategicPatch" | "replace";
            undoChanges?: boolean;
            // use this only for cases when you are not editing existing agenda items. The update payload will not be created correctly because of this
            skipDiff?: boolean;
        } = {strategy: "patch", undoChanges: false, skipDiff: false}
    ): Partial<Session> {
        const updateClone = structuredClone(update);
        // const uu = structure / dClone(update);
        const isCreate = !this.currentSession()?.id;

        const updateKeys = Object.keys(updateClone) as Array<keyof typeof update>;
        let current: Partial<Session> = {};
        let diff: Partial<Session> = updateClone as Partial<Session>;
        //@csmn23

        if (options.skipDiff && options.strategy === "replace" && update) {
            diff = update as Partial<Session>;
        } else {
            if (!isCreate) {
                const withDiff = structuredClone(
                    updateKeys.reduce((all, k) => {
                        // IMPORTANT: by efault this.currentSession/AgendaItems() etc (with NO arguments)
                        // returns CHANGED data
                        // if you pass and arg = true, it will return UNCHANGED data
                        all[k] = this["current" + capitalize(k)](options.skipDiff ? false : true);
                        return all;
                    }, {}) as Partial<Session>
                );

                // diff will be applied to `withDiff` which is why we clone it before that happens
                current = structuredClone(withDiff);
                if (!options.skipDiff) {
                    if (this.currentSession()?.event) {
                        createDiffCollaborative(withDiff, updateClone, options.undoChanges);
                    } else {
                        createDiff(withDiff, updateClone, options.undoChanges);
                    }
                }
                diff = withDiff;
            }
        }

        let data: Partial<Session> = {} as Partial<Session>;

        const patchStrategy = options.strategy ?? "patch";

        if (patchStrategy === "replace") {
            data = diff as Partial<Session>;
        } else if (patchStrategy === "patch") {
            data = patch(current ?? {}, diff);
        } else if (patchStrategy === "strategicPatch") {
            data = strategicPatch(current ?? {}, diff);
        }

        const id = isCreate ? data.session?.id : this.currentSession()?.id;

        if (data && id) {
            writeFromFullSession(id, data);
            this.saveToPersistentStorage();
        }

        return data;
    }

    public extractDiff(agendaItemToResetId?: string) {
        // by default, this.currentData() returns UN-ALTERED data
        // but in this case wee need the altered data too
        const currentData = this.currentData();

        const itemToReset = agendaItemToResetId ? currentData?.agendaItems.find((a) => a.id === agendaItemToResetId) : null;

        if (itemToReset && currentData) {
            currentData.agendaItems = currentData?.agendaItems.filter((a) => a.id !== agendaItemToResetId);
        }

        const changedData = agendaItemToResetId ? Object.assign({}, structuredClone(this.changedData()!)) : this.changedData()!;

        if (itemToReset) {
            const index = changedData.agendaItems.findIndex((a) => a.id === agendaItemToResetId);
            const nullDate = null as unknown as Date;
            changedData.agendaItems[index] = {
                ...itemToReset,
                createdAt: nullDate,
                updatedAt: nullDate,
                artifact: itemToReset?.artifact
                    ? {
                          ...itemToReset?.artifact,
                          createdAt: nullDate,
                          updatedAt: nullDate,
                          resourceResults:
                              itemToReset?.artifact?.resourceResults?.map((r) => ({
                                  ...r,
                                  createdAt: nullDate,
                                  updatedAt: nullDate,
                              })) ?? [],
                          properties: itemToReset?.artifact?.properties?.map((p) => ({
                              ...p,
                              createdAt: nullDate,
                              updatedAt: nullDate,
                          })),
                      }
                    : null,
            };
        }

        const diff = extractDiff(currentData, changedData, true);
        return diff;
    }

    public restoreUpdates() {
        // this causes some weird issues when both owner and assistant are editing and constantly refreshing
        // new business requirements are that uncommited changes will be lost when the user refreshes
        // const unalteredData = structuredClone(this.currentData()!);
        // const diffFromStorage = localStorage.getItem(this.storageKey.SESSION);
        // if (!diffFromStorage) {
        //     return;
        // }
        // let diff: any | undefined = undefined;
        // try {
        //     diff = JSON.parse(diffFromStorage);
        // } catch (e) {
        //     console.log(`Error: cannot decode session diff from storage for sessionId=${unalteredData?.session?.id}`);
        // }
        // if (!diff || !diff.session || !diff.session.id || diff.session.id !== unalteredData?.session?.id) {
        //     return;
        // }
        // if (
        //     Object.keys(diff).length == 1 &&
        //     diff.session &&
        //     Object.keys(diff.session).filter((k) => ["id", "__typename", "isDeleted"].indexOf(k) === -1).length === 0
        // ) {
        //     return;
        // }
        // ingestDiff(unalteredData, diff);
        // this.updateCurrentSession(unalteredData, {strategy: "replace"});
    }

    // if you want to undo changes to the session
    // you would pass ["session"] as an arg
    // and only changes made to the session will be discarded
    // you can pass whatever combination of keys like ["session","agendaItems"]
    // and only that data will be reverted
    // TODO: @vasi make undo granular so we can undo changes made only to i.e. the artifact on an agenda Item
    public undoChanges(arg: {from: Array<keyof Session>}) {
        const sessionIsCommited = !!this.currentSession(false)?.createdAt;
        if (!sessionIsCommited) {
            return;
        }
        // console.log("undoing changes");
        const unalteredData = structuredClone(
            arg.from.reduce((all, k) => {
                // true to mark we're extracting UN-ALTERED data
                all[k] = this["current" + capitalize(k)](true);
                return all;
            }, {}) as Partial<Session>
        );

        if (unalteredData) {
            removeNewArrayItems(unalteredData);
            this.updateCurrentSession(unalteredData, {
                strategy: "replace",
                undoChanges: true,
            });
        }
    }

    public saveToPersistentStorage() {
        const isEvent = isEventRoute(window.location.pathname);
        if (isEvent) {
            return;
        }
        // localStorage.setItem(this.storageKey.STATE, JSON.stringify(this.state.getSnapshot()));
        localStorage.setItem(this.storageKey.SESSION_ID, this.state.getSessionId() ?? "");
        // console.log("saving session to storage", this.currentData());
        const session = this.currentSession();
        if (session?.id) {
            const sessionIsCommited = !!session.createdAt;
            // console.log("calling extract diff", {sessionIsCommited});
            // if (sessionIsCommited) {
            //     console.log("saving diff", this.extractDiff());
            // }
            //else {
            //     // console.log("saving uncommited", this.currentData());
            // }
            sessionStorage.setItem(this.storageKey.SESSION, JSON.stringify(sessionIsCommited ? this.extractDiff() : this.currentData()));
        }
    }

    // public reloadStateFromPersistentStorage() {
    //     const lastDesignerStateStored = localStorage.getItem("designer-state");
    //     const snapShot = this.state.getSnapshot();
    //     let lastState: DesignerState = snapShot;
    //     try {
    //         lastState = (lastDesignerStateStored ? JSON.parse(lastDesignerStateStored) : snapShot) as DesignerState;
    //     } catch (e) {
    //         throw new Error("cannot decode storage session when restoring draft");
    //     }

    //     this.state.update(lastState);
    // }

    public clearPersistentStorage() {
        // console.log("clearing storage");
        this.clearSessionStorage();
        sessionStorage.removeItem(this.storageKey.STATE);
        sessionStorage.removeItem(this.storageKey.EDIT_STATE);
        sessionStorage.removeItem(this.storageKey.SESSION_ID);
    }

    public canRestoreDraft(sessionId: string) {
        const storageSessionId = readQuery("DesignerSessionIdDocument")?.designerSessionId?.sessionId;
        const wasEditing = readQuery("DesignerEditStateDocument")?.designerEditState?.isEditing;
        const storageSession = readQuery("GetSessionDocument", {
            variables: {
                id: storageSessionId!,
                // @ts-expect-error
                loadFromStorage: true,
            },
            skip: !storageSessionId,
        })?.session;

        return sessionId && sessionId === storageSessionId && (wasEditing || (storageSession && !storageSession.createdAt)) ? true : false;

        // const lastSessionIdData = localStorage.getItem(this.storageKey.SESSION_ID);
        // const wasEditingData = localStorage.getItem(this.storageKey.EDIT_STATE);

        // let lastSessionId: DesignerSessionIdQuery;
        // let lastEditState: DesignerEditStateQuery;
        // try {
        //     lastSessionId = (lastSessionIdData ? JSON.parse(lastSessionIdData) : {}) as DesignerSessionIdQuery;
        // } catch (e) {
        //     throw new Error("cannot json decode storage sessionId when checking if can restore draft");
        // }

        // try {
        //     lastSessionId = (lastSessionIdData ? JSON.parse(lastSessionIdData) : {}) as DesignerSessionIdQuery;
        // } catch (e) {
        //     throw new Error("cannot json decode storage sessionId when checking if can restore draft");
        // }

        // const snapShot = this.state.getSnapshot();
        // let lastState: DesignerState = snapShot;
        // try {
        //     lastState = (lastDesignerStateStored ? JSON.parse(lastDesignerStateStored) : snapShot) as DesignerState;
        // } catch (e) {
        //     throw new Error("cannot decode storage session when restoring draft");
        // }

        // return (
        //     lastState.currentSessionId === lastSession.session?.id &&
        //     (sessionIdFromUrl ? sessionIdFromUrl === lastSession.session?.id : true)
        // );
        // // return (
        // //     !!lastSessionItem &&
        // //     !!localStorage.getItem("designer-last-session") &&
        // //     JSON.parse(lastSessionItem).id !== this.state.getSnapshot().currentSessionId
        // // );
    }

    public canRestoreTemplate() {
        const snapShot = this.state.getSnapshot();
        const lastSessionStored = localStorage.getItem("designer-last-session");
        const lastDesignerStateStored = localStorage.getItem("designer-state");
        let lastSession: Session;

        const designerState: DesignerState = snapShot;
        try {
            lastSession = (lastSessionStored ? JSON.parse(lastSessionStored) : {}) as Session;
        } catch (e) {
            throw new Error("cannot decode storage session when restoring draft");
        }

        if (!designerState.templateMode) {
            return false;
        }
        if (lastSession.session?.id) {
            return true;
        }
        return false;
    }

    // public restoreDraft() {
    //     this.clearActionsHistory();

    //     try {
    //         const lastSession = JSON.parse(localStorage.getItem("designer-last-session")!);
    //         const lastDesignerState = JSON.parse(localStorage.getItem("designer-state")!);

    //         this.state.setCurrentSession(lastDesignerState.currentSessionId);
    //         this.updateCurrentSession(lastSession, {strategy: "replace"});

    //         /**NO toggleEditModeHere please
    //          * it interferes with other places where editMode is toggled programatically
    //          */
    //         return true;
    //     } catch (error) {
    //         console.log("error when parsing state or session from local storage", error);
    //         return false;
    //     }
    // }

    public pushAction(action: DesignerAction): string {
        if (this.actionPointer === DESIGNER_MAX_HISTORY - 1) {
            this.actions.shift();
            this.actionPointer--;
        } else {
            if (this.actionPointer !== DESIGNER_MAX_HISTORY - 1) {
                for (let idx = this.actionPointer + 1; idx < DESIGNER_MAX_HISTORY; idx++) {
                    this.actions[idx] = undefined!;
                }
            }
        }

        this.actions[this.actionPointer] = action;
        this.actionPointer++;

        action.setPreviousState(this.changedData());
        const result = action.commit();
        if (!this.state.getSnapshot().dirty) {
            this.state.update({
                dirty: true,
            });
        }
        action.setNextState(this.changedData());

        this.saveToPersistentStorage();

        return result;
    }

    public startBatching() {
        this.batchedActionPointers = this.batchedActionPointers.concat([[this.actionPointer, this.actionPointer]]);
    }

    public resetBatching() {
        this.batchedActionPointers = [];
    }

    public stopBatching() {
        const lastIndex = this.batchedActionPointers.length - 1;
        this.batchedActionPointers[lastIndex] = [this.batchedActionPointers[lastIndex][0], this.actionPointer];

        if (this.batchedActionPointers[lastIndex][1] - this.batchedActionPointers[lastIndex][0] <= 1) {
            this.batchedActionPointers.pop();
        }
    }

    public undo(args?: {field: "agendaItem" | "all"; id?: string}) {
        if (this.canUndo()) {
            const {field = "all", id} = args ?? {};
            const batchedActionIndex = this.batchedActionPointers.findIndex((p) => p[1] === this.actionPointer);

            if (batchedActionIndex !== -1) {
                const actionsCount = this.batchedActionPointers[batchedActionIndex][1] - this.batchedActionPointers[batchedActionIndex][0];
                const undoCount = [...Array(actionsCount).keys()];
                this.batchedActionPointers.pop();

                for (const i of undoCount) {
                    const action = this.actions[this.actionPointer - 1]!;

                    const changedData = structuredClone(this.changedData());

                    const prevState = structuredClone(action.getPreviousState());
                    let currentDataWithPrevItem = prevState;

                    switch (field) {
                        // For doing undo on a single field, we extract and ingest the diff from 2 identical objects that contain the latest version of our state, except for the object that we doing the undo for
                        // that object will come from the previous state
                        // TODO: make this fully generic
                        case "agendaItem":
                            if (id && prevState) {
                                currentDataWithPrevItem = {
                                    ...changedData,
                                    agendaItems: changedData?.agendaItems.map((a) =>
                                        a.id === id ? prevState?.agendaItems?.find((prevA) => prevA?.id === id) ?? a : a
                                    ),
                                };
                            }
                            break;

                        default:
                            break;
                    }

                    const canUndo = action.canUndo();

                    if (currentDataWithPrevItem && changedData && canUndo) {
                        const diff = extractDiff(changedData, currentDataWithPrevItem);
                        ingestDiff(changedData, diff);
                        this.updateCurrentSession(changedData, {strategy: "replace"});
                    }
                    this.actionPointer--;
                }
            } else {
                const action = this.actions[this.actionPointer - 1]!;
                const prevState = structuredClone(action.getPreviousState());
                const currentData = structuredClone(this.currentData());
                if (prevState && currentData) {
                    const diff = extractDiff(currentData, prevState);
                    ingestDiff(currentData, diff);
                    this.updateCurrentSession(currentData, {strategy: "replace"});
                }
                this.actionPointer--;
            }
        }
    }

    public canUndo() {
        return this.actions[this.actionPointer - 1] != null && this.actions[this.actionPointer - 1].canUndo();
    }

    public redo() {
        if (this.canRedo()) {
            const action = this.actions[this.actionPointer]!;
            this.updateCurrentSession(action.getNextState() as PartialObjectDeep<DesignerApiSession, {}>, {strategy: "replace"});
            this.actionPointer++;
        }
    }

    public canRedo() {
        return this.actions[this.actionPointer] !== undefined && this.actions[this.actionPointer].canRedo();
    }

    public clearActionsHistory() {
        this.actions = Array.from({length: DESIGNER_MAX_HISTORY}, () => undefined as unknown as DesignerAction);
        this.actionPointer = 0;
    }

    async commitCreateSession(operationContext?: ApolloOperationContext) {
        const changedData = this.changedData()!;
        if (!changedData?.session?.id) {
            console.error(`cannot commit session because it doesn't have an id`);
            this.clearActionsHistory();
            this.clearSessionStorage();
            if (this.participantsPayload) {
                this.participantsPayload = null;
            }
            this.undoChanges({
                from: ["agendaItems", "macroArtifacts", "session"],
            });
            throw new Error(ERROR_NO_SESSION_ID);
        }
        const participantsPayload = structuredClone(this.participantsPayload);
        const current = removeTypenameKey(changedData, {
            preserveDiffFields: true,
        });
        const currentState = this.state.getSnapshot();

        const isInstantSession = current.session?.quickSession === true;

        // const currentState = this.state.getSnapshot();
        // const gotParticipantsPayload = !!this.participantsPayload;

        this.state.initializeOrResetState(current.session!.id);
        if (isInstantSession) {
            this.state.setDesignerEditState(false);
        } else {
            this.state.setDesignerEditState(true);
        }
        this.state.setDesignerCommitState(true);

        const commitDataTemp = buildCreateSessionPayload(current);
        const commitData = removeTypenameKey(commitDataTemp);

        return await mutate("CreateOneSessionDocument", {
            variables: {
                data: commitData,
            },
            context: operationContext,
            fetchPolicy: "no-cache",
        })
            .then(async (createResponse) => {
                let session: SessionNoCacheFragment | undefined = undefined;

                if (participantsPayload) {
                    await mutate("UpdateParticipantsInSessionDocument", {
                        variables: {
                            ...participantsPayload,
                            ...(currentState.followUpOfSessionId ? {followUpOfSessionId: currentState.followUpOfSessionId} : {}),
                        },
                        fetchPolicy: "no-cache",
                    }).then((participantUpdateResponse) => {
                        session = participantUpdateResponse.data?.updateParticipantsInSession ?? undefined;
                    });
                    this.participantsPayload = null;
                } else {
                    session = createResponse.data?.createSession ?? undefined;
                }
                if (session?.id) {
                    this.clearActionsHistory();
                    this.clearSessionStorage();
                    if (session.agendaItems.some((a) => a.type === AgendaItemType.Planned)) {
                        this.state.update({
                            configurationStep: ConfigurationStep.Preview,
                        });
                    }
                }
                return session;
            })
            .finally(() => {
                if (this.participantsPayload) {
                    this.participantsPayload = null;
                }
                this.undoChanges({
                    from: ["agendaItems", "macroArtifacts"],
                });
                this.state.setDesignerCommitState(false);
            });
    }

    public clearSessionStorage() {
        sessionStorage.removeItem(this.storageKey.SESSION);
    }

    async commit(args?: {
        persistEvent?: boolean;
        releaseLock?: boolean;
        agendaItemToResetId?: string;
        isApplyingTemplate?: boolean;
        bypassEventCheck?: boolean;
        forceOrderUpdate?: number;
        createdFromTemplateId?: string;
        source?: "agenda" | "session" | "event-edit" | "event-save";
    }) {
        const {
            agendaItemToResetId,
            bypassEventCheck,
            createdFromTemplateId,
            forceOrderUpdate,
            isApplyingTemplate,
            persistEvent,
            releaseLock,
            source,
        } = args ?? {};

        const currentState = this.state.getSnapshot();
        this.state.setDesignerCommitState(true);

        const diff = this.extractDiff(agendaItemToResetId) as PartialDeep<Session>;

        console.log("designer commit", diff);

        const unalteredData = structuredClone(this.currentData()!);
        const clonedDiff = structuredClone(diff);

        const sessionId = this.currentSession()?.id;

        const hostType = makeHostTypeFromRoute(location.pathname);

        const isPlayer = hostType === HostType.Player;

        if (!sessionId) {
            return;
        }

        const isEvent = bypassEventCheck || (isPlayer ? false : this.currentSession()?.event?.id ?? false);

        const shouldSendDiff = this.currentSession()?.lifecycle === SessionLifecycle.NotStarted || this.currentSession()?.backstage;
        if (sessionId && isEvent && shouldSendDiff) {
            if (diff.participants) {
                // biome-ignore lint/performance/noDelete: <explanation>
                delete diff.participants;
            }
            if (diff.currentParticipant) {
                // biome-ignore lint/performance/noDelete: <explanation>
                delete diff.currentParticipant;
            }

            await mutate("SendSessionDiffDocument", {
                variables: {
                    sessionId,
                    diff: diff,
                    persist: persistEvent,
                    ...(releaseLock ? {releaseLock} : {}),
                },
            });
            this.state.setDesignerCommitState(false);

            return;
        }

        // return null as unknown as Promise<SessionNoCacheFragment | undefined>;

        // second arg = true to preserve createdAt and updatedAt timestamps
        // thirg arg = true to preserve oldId's in order to diferentiate between create and update
        // fourth arg = true to preserve the isDeleted param
        const diffStripped = removeTypenameKey(diff, {preserveDiffFields: true, preserveTimestamps: true}) as OmitTypenames<Session>;

        // console.log("state", this.state.getSnapshot(), this.currentSession());

        if (!diffStripped.session && sessionId) {
            // @ts-expect-error do not fix this
            diffStripped.session = {
                __typename: "Session",
                id: sessionId,
            };
        }

        if (createdFromTemplateId && diffStripped.session && !diffStripped.session?.createdFromTemplateId) {
            diffStripped.session = {
                ...diffStripped.session,
                createdFromTemplateId,
            };
        }

        const sessionIdFromRoute = makeSessionIdFromRoute(location.pathname);

        const currentData = this.currentData()!;
        let payload = buildUpdateSessionPayload(diffStripped, currentData);

        const participantsPayload = structuredClone(this.participantsPayload);

        const hasParticipants = !!participantsPayload;

        // console.log("update payload", payload);
        // return;

        // when creating a recurrence by updating a planned session and we are in lobby (that's why we add sessionIdFromRoute in the condition)
        // we need to trigger tryJoin so we can get the id of the recurrence instance where we should be redirected
        const shouldTriggerTryJoin = !!payload.recurrenceData?.create && sessionIdFromRoute;

        // we are already previewing this tool, so the diff wont see a order change
        if (this.state.getSnapshot().configurationConfirm === ConfigurationConfirmType.Tool) {
            payload = {
                ...payload,
                order: {set: this.currentData()?.session?.order ?? 0},
            };
        }

        return await mutate("UpdateOneSessionDocument", {
            variables: {
                where: {
                    id: sessionId,
                },
                hasParticipants,
                data: {
                    ...payload,
                    ...(forceOrderUpdate ? {order: {set: forceOrderUpdate}} : {}),
                },
                isApplyingTemplate,
            },
            fetchPolicy: "no-cache",
        })
            .then(async (res) => {
                if (shouldTriggerTryJoin && res.data?.updateSession?.id) {
                    const tryJoinRes = await mutate("TryJoinSessionDocument", {
                        variables: {
                            sessionId: res.data?.updateSession?.id,
                        },
                    }).catch((err) => {
                        console.log("designer tryjoin error", err);
                    });
                    if (tryJoinRes?.data?.tryJoinSession) {
                        if (tryJoinRes.data.tryJoinSession?.instanceId) {
                            window.location.href = `/session/${tryJoinRes.data.tryJoinSession?.instanceId}`;
                            return;
                        }
                    }

                    // window.location.reload();
                }
                let session: SessionNoCacheFragment | undefined = undefined;
                if (participantsPayload) {
                    await mutate("UpdateParticipantsInSessionDocument", {
                        variables: {
                            ...participantsPayload,
                            ...(currentState.followUpOfSessionId ? {followUpOfSessionId: currentState.followUpOfSessionId} : {}),
                        },
                        fetchPolicy: "no-cache",
                    }).then((participantUpdateResponse) => {
                        session = participantUpdateResponse.data?.updateParticipantsInSession ?? undefined;
                    });
                    this.participantsPayload = null;
                } else {
                    session = res.data?.updateSession ?? undefined;
                }
                if (session?.id) {
                    this.state.setDesignerCommitState(false);
                    this.clearPersistentStorage();
                }

                // console.log("after update", {
                //     sessionInCache: structuredClone(this.currentData()),
                //     response: structuredClone(session),
                //     stateSessId: this.state.getSessionId(),
                // });

                // if (session?.recurrenceData?.createdAt) {
                //     window.location.reload();
                // }
                return session;
            })
            .finally(() => {
                if (this.participantsPayload) {
                    this.participantsPayload = null;
                }
                this.state.setDesignerCommitState(false);
            })
            .catch((e) => {
                // If this request fails, the user is probably stuck with a bad payload in his cache
                window.location.reload();
            });
    }
}

const designer = new DesignerApi();

export default designer;
