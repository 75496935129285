import {CalendarEventCustomFragment, SessionLifecycle} from "@generated/data";
import {
    addDays,
    addYears,
    differenceInHours,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    isAfter,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subYears,
} from "date-fns";
import {CalendarEventInput, IntervalViewMode} from "./definitions";

export const eventIsRecurrent = (event: CalendarEventCustomFragment): boolean => {
    return !!event.recurrenceData || !!event.instanceOfRecurrence;
};

export const eventHasInstance = (event: CalendarEventCustomFragment): boolean => {
    return !!event.instanceOfRecurrence;
};

export const eventHasPassed = (event: CalendarEventCustomFragment): boolean => {
    return !isAfter(new Date(event.plannedEnd), Date.now()) ? true : event.lifecycle === SessionLifecycle.Ended;
};

export const eventHasNotPassed = (event: CalendarEventCustomFragment): boolean => {
    return new Date(event.startAt) > new Date() && event.lifecycle === SessionLifecycle.NotStarted;
};

export const backstageInFuture = (event: CalendarEventCustomFragment): boolean => {
    return new Date(event.startAt) > new Date() && !!event.backstage;
};

export const getUniqueSet = (list: (string | null | undefined)[]): string[] => {
    return Array.from(
        list.reduce((set, id) => {
            if (typeof id !== "undefined" || id !== null) {
                set.add(id);
            }

            return set;
        }, new Set())
    ) as string[];
};

export const getCalendarEventId = (event: CalendarEventCustomFragment) =>
    `${event.instanceOfRecurrence?.sessionId || event.id}${event.occurrenceId ? `-${new Date(event.occurrenceId).toISOString()}` : ""}`;

export const getCalendarEventInput = (event: CalendarEventCustomFragment): CalendarEventInput => {
    const allDay = differenceInHours(event.plannedEnd, event.startAt) >= 24;

    return {
        allDay,
        start: event.startAt,
        end: event.plannedEnd,

        id: event.externalEvent ? event.id : getCalendarEventId(event),
        title: event.name,

        // we do this so we can handle the coloring ourselves
        color: "transparent",
        editable: !allDay && event.isOwner,

        extendedProps: {
            source: event,
            groupId: event.instanceOfRecurrence?.sessionId || event.recurrenceParentId || event.id,
        },
    };
};

export const getExternalCalendarEventInput = (event: CalendarEventCustomFragment): CalendarEventInput => {
    const allDay = differenceInHours(event.plannedEnd, event.startAt) >= 24;

    return {
        allDay,
        start: event.startAt,
        end: event.plannedEnd,

        id: event.id,
        title: event.name,

        // we do this so we can handle the coloring ourselves
        color: "transparent",
        editable: event.externalEvent ? false : !allDay && event.isOwner,

        extendedProps: {
            source: event,
            groupId: event.externalEvent ? event.id : event.instanceOfRecurrence?.sessionId || event.recurrenceParentId || event.id,
        },
    };
};

export function getIntervalDates(interval: IntervalViewMode) {
    const now = new Date();

    switch (interval) {
        case IntervalViewMode.DAY:
            return {start: startOfDay(now), end: endOfDay(now)};
        case IntervalViewMode.WEEK:
            return {start: addDays(startOfWeek(now), 1), end: addDays(endOfWeek(now), 1)}; // Because the start of week is sunday
        case IntervalViewMode.MONTH:
            return {start: startOfMonth(now), end: endOfMonth(now)};
        case IntervalViewMode.YEAR:
            return {start: startOfYear(now), end: endOfYear(now)};
        default:
            return {start: startOfYear(subYears(now, 50)), end: endOfYear(addYears(now, 50))};
    }
}
