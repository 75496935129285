import i18n from "i18next";
import {makeHostTypeFromRoute} from "./util/makeHostTypeFromRoute";
import resourcesToBackend from "i18next-resources-to-backend";
import {initReactI18next} from "react-i18next";
import en from "./locales/en/common.json?url";
import fr from "./locales/fr/common.json?url";
import de from "./locales/de/common.json?url";
import es from "./locales/es/common.json?url";
import it from "./locales/it/common.json?url";
import ko from "./locales/ko/common.json?url";
import cz from "./locales/cz/common.json?url";
import pt from "./locales/pt/common.json?url";
import ro from "./locales/ro/common.json?url";
import ja from "./locales/ja/common.json?url";
import nl from "./locales/nl/common.json?url";
import pl from "./locales/pl/common.json?url";
import zhCN from "./locales/zhCN/common.json?url";
import zhTW from "./locales/zhTW/common.json?url";
import ptBR from "./locales/ptBR/common.json?url";
import ru from "./locales/ru/common.json?url";
import sl from "./locales/sl/common.json?url";
import {HostType} from "@generated/data";

export const languageAllowedHostTypes = [HostType.Player, HostType.Feedback, HostType.Book, HostType.BookedEvent];

type Language = {
    key: string;
    code: string;
    flag: string;
    name?: string;
};
export const languages: Language[] = [
    {
        key: "english",
        code: "en",
        flag: "us",
        name: "English",
    },
    {
        key: "french",
        code: "fr",
        flag: "fr",
        name: "Français",
    },
    {
        key: "german",
        code: "de",
        flag: "de",
        name: "Deutsch",
    },
    {
        key: "spanish",
        code: "es",
        flag: "es",
        name: "Español",
    },
    {
        key: "portuguese",
        code: "pt",
        flag: "pt",
        name: "Português",
    },
    {
        key: "korean",
        code: "ko",
        flag: "kr",
        name: "한국어",
    },

    {
        key: "czech",
        code: "cz",
        flag: "cz",
        name: "Český",
    },
    {
        key: "italian",
        code: "it",
        flag: "it",
        name: "Italiano",
    },
    {
        key: "romanian",
        code: "ro",
        flag: "ro",
        name: "Română",
    },
    {
        key: "japanese",
        code: "ja",
        flag: "jp",
        name: "日本語",
    },
    {
        key: "dutch",
        code: "nl",
        flag: "nl",
        name: "Nederlands",
    },
    {
        key: "polish",
        code: "pl",
        flag: "pl",
        name: "Polski",
    },
    {
        key: "chinese-simplified",
        code: "zhCN",
        flag: "cn",
        name: "简体中文",
    },
    {
        key: "chinese-traditional",
        code: "zhTW",
        flag: "tw",
        name: "繁體中文",
    },
    {
        key: "brazilian-portuguese",
        code: "ptBR",
        flag: "br",
        name: "Português do Brasil",
    },
    {
        key: "russian",
        code: "ru",
        flag: "ru",
        name: "Русский",
    },
    {
        key: "slovene",
        code: "sl",
        flag: "sl",
        name: "Slovenščina",
    },
];

function getInitialLanguage() {
    const hostType = makeHostTypeFromRoute(window.location.pathname);

    const savedLanguage = localStorage.getItem("user-language");

    const appLanguages = languages.map((l) => l.code);
    const browserLanguages = navigator.languages;

    const preferredLanguage = browserLanguages.find((l) => appLanguages.includes(l));

    const isLanguageAllowed = hostType && languageAllowedHostTypes.includes(hostType);

    return !isLanguageAllowed ? "en" : savedLanguage ?? preferredLanguage ?? "en";
}

const staticUrls = {
    en,
    fr,
    de,
    es,
    it,
    ko,
    cz,
    pt,
    ro,
    ja,
    nl,
    pl,
    zhCN,
    zhTW,
    ptBR,
    ru,
    sl,
};

i18n.use(resourcesToBackend((lng, namespace) => fetch(staticUrls[lng]).then((res) => res.json())))
    .use(initReactI18next)
    .init({
        defaultNS: "common",
        ns: "common",
        react: {
            useSuspense: false,
        },
        lng: getInitialLanguage(),
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
