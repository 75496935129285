import Box from "@material-ui/core/Box/Box";
import CircularProgress, {CircularProgressProps} from "@material-ui/core/CircularProgress";
import Typography from "@ui/cdk/Typography";
import classes from "./style/CircularProgressWithLabel.module.scss";

const CircularProgressWithLabel = (props: CircularProgressProps & {value?: number; type: string}) => {
    const progress = !props.value ? null : props.type === "long" ? Math.round(props.value) / 10 : Math.round(props.value) / 2 / 10;

    return (
        <Box sx={{position: "relative", display: "inline-flex"}}>
            <CircularProgress variant={props.variant ?? "determinate"} {...props} />
            {props.value ? (
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="lg" fontWeight="bolder" className={classes.counterDigit}>
                        {progress}
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

export default CircularProgressWithLabel;
