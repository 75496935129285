import Button from "@ui/cdk/Button";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useEffect, useRef} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useUserInfo} from "@workhorse/providers/User";
import {useDevices} from "../../providers";
import {useLocalVideoPublisher} from "../../providers/ConferenceRoomProvider/ConferenceRoomLocalVideoProvider";
import {useOrderedAsyncReplaceableTask} from "../useAsyncReplaceableTask";

export function VideoInputStreamController({disableBroadcast}: {disableBroadcast?: boolean}) {
    // TODO: recording - move this somwhere top level
    const {isRecorder} = useUserInfo();

    const {videoStream, videoTransformedStream, setVideoEnabled} = useDevices();
    const stream = isRecorder ? null : videoTransformedStream ?? videoStream;
    const {startPublishingVideo, stopPublishingVideo} = useLocalVideoPublisher();

    const videoEnqueue = useOrderedAsyncReplaceableTask();

    const isMounted = useIsMounted();

    const handleError = () => {
        if (!isMounted()) {
            return;
        }

        setVideoEnabled(false);

        toast(
            <div className="flex flex-row">
                <p style={{width: 240}}>Something went wrong with your video stream.</p>
                <Button
                    className="ml-8 mt-auto mb-auto"
                    variant="plain"
                    size="smallest"
                    onClick={() => {
                        setVideoEnabled(true);
                        toast("", {
                            remove: true,
                            uid: "toast-video-publish-error",
                        });
                    }}
                >
                    Try Again
                </Button>
            </div>,
            {type: "error", permanent: true, uid: "toast-video-publish-error"}
        );
    };

    // The localization will trigger a rerender, but we don't want to rerun the effect.
    const handleErrorRef = useRef(handleError);
    handleErrorRef.current = handleError;

    useEffect(() => {
        if (!stream || disableBroadcast) {
            videoEnqueue(stopPublishingVideo);
            return;
        }
        videoEnqueue(async () => {
            const err = await startPublishingVideo(stream);

            if (err) {
                handleErrorRef.current();
            }
        });
    }, [stream, videoEnqueue, startPublishingVideo, stopPublishingVideo, disableBroadcast, setVideoEnabled]);

    return null as unknown as JSX.Element;
}
