/* eslint-disable react/no-unescaped-entities */
import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as AutoTranscribingIcon} from "../../../../../pages/player/assets/media/transcript-header-info.svg";
import {useTranslation} from "react-i18next";
import {SessionOptionWarning} from "./SessionOptionWarning";
import Tooltip from "@ui/cdk/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const styles = {...genericStyles};

type SessionAutoTranscribingProps = {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    hideWarning?: boolean;
};

const SessionAutoTranscribingToggler = ({
    enabled,
    onChange,
    showIcon = false,
    disabled,
    className,
    hideWarning,
}: SessionAutoTranscribingProps) => {
    const {t} = useTranslation();
    const handleOnChange = () => {
        onChange(!enabled);
    };

    return (
        <Tooltip
            title={disabled ? "Enable the Transcript Widget to be able to enable the Automatic Transcribing feature." : ""}
            placement="top"
            arrow
        >
            <div className={cls(styles.togglerWrapper, styles.togglerWrapperWithFooter, className)}>
                <div className="flex fullw">
                    {showIcon ? (
                        <div className={styles.iconWrapper}>
                            <AutoTranscribingIcon />
                        </div>
                    ) : null}

                    <TogglerContainer
                        title={
                            <Typography variant="base" fontWeight="bold" color="secondary">
                                {t("player.settings.general.session.automatic_transcribing.title")}
                            </Typography>
                        }
                        secondTitle={
                            <Typography color="quaternary" component="span">
                                {t("player.settings.general.session.automatic_transcribing.description")}
                            </Typography>
                        }
                        checked={enabled}
                        name="autoTranscribing"
                        onChange={handleOnChange}
                        togglePlacement="secondTitle"
                        label=" "
                        className={cls(styles.toggler)}
                        disabled={disabled}
                    />
                </div>
                {enabled && !hideWarning && (
                    <SessionOptionWarning
                        className="mt-8"
                        description={t("player.settings.general.session.automatic_transcribing.disclaimer")}
                    />
                )}
            </div>
        </Tooltip>
    );
};

export default SessionAutoTranscribingToggler;
