import {Portal} from "@material-ui/core";
import {cls} from "@ui/cdk/util/util";
import {MutableRefObject, memo, useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {WithClasses, WithClassName} from "@workhorse/declarations";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useDebounce} from "use-debounce";
import {ySyncPluginKey} from "y-prosemirror";
import {
    EditorPlugin,
    ToolbarDirection,
    ToolbarGenericProps,
    ToolbarItems,
    ToolbarTheme,
    useEditor,
    EditorContent,
    SessionsEditor,
    JSONContent,
    HocuspocusProvider,
    CollaborationCursorPlugin,
    CollaborationPlugin,
} from "./api";
import {Toolbar} from "./toolbar";
import {useUserInfo} from "@workhorse/providers/User";
import {getCommonExtentions, parseAsTipTapJSON} from "@api/editor";
import env from "@generated/environment";
import {CharacterCountPlugin, PlaceholderPlugin} from "@api/editor/plugins";
import {getHexColor, makeInitials} from "@workhorse/util";
import {FocusEventHandler, useLayoutEffect} from "react";
import {EditorEvents} from "@tiptap/react";
import classes from "./styles/Editor.module.scss";

export type EditorProps = WithClassName &
    WithClasses<"editable"> & {
        /**
         * @description the initial value to display
         *
         * @default [type: "paragraph", alignment: "left", {children: [{text: ""}]}]
         */
        value?: JSONContent | string | null;

        /**
         * @description what to show when no text is present
         *
         * @default "Write some text"
         */
        placeholder?: string;

        /**
         * @description callback
         */
        onChange?: (newValue: JSONContent, str: string, length: number) => void;
        onReady?: () => void;
        /**
         * @description onBlur event listener
         *
         * @default  undefined
         */
        onBlur?: FocusEventHandler<HTMLDivElement>;

        /**
         * @description onFocus event listener
         *
         * @default  undefined
         */
        onFocus?: FocusEventHandler<HTMLDivElement>;

        /**
         * @description debounce `onChange` callback
         * @unit `milliseconds`
         */
        debounceChange?: number;

        /**
         * @description if the editor is in readonly mode
         *
         * @default false
         */
        readOnly?: boolean;

        /**
         * @description the plugin list to decorate the editor with. The Editor is already bundled with `withReact` and `withHistory`.
         *
         * @default  []
         */
        plugins?: EditorPlugin[];

        /**
         * The maxLength of allowed characters
         */
        maxLength?: number;

        /**
         * @description show toolbar only when the editor is focused
         *
         * @default  false
         */
        renderToolbarOnFocus?: boolean;

        /**
         * @description className of portal target
         *
         * @default  undefined
         */
        toolbarPortal?: string;

        /**
         * @description if the editor should focus on mount
         *
         * @default  false
         */
        autoFocus?: boolean;

        /**
         * @description describes how the toolbar should look
         *
         * @default  "default"
         */
        toolbarTheme?: ToolbarTheme;

        /**
         * @description toolbarItems to be hidden
         *
         * @default  []
         */
        hideToolbarItems?: ToolbarItems[];

        /**
         * @description toolbar direction
         *
         * @default  "horizontal"
         */
        toolbarDirection?: ToolbarDirection;

        /**
         * @description toolbar className
         *
         * @default  undefined
         */
        toolbarClassName?: string;
        hideToolbar?: boolean;
        editor: SessionsEditor;
        collaborative?: boolean;
        collaborationToken?: string;
        documentId?: string;
        editorRef?: MutableRefObject<SessionsEditor | null>;
        /**
         * @description needed a way to update the editor when the value changes without it being synced
         *
         * @default  false
         */
        updateWhenValueChanges?: boolean;
    };

const Editor = (props: EditorProps) => {
    const {
        onChange = (value) => {
            console.warn("`Editor.onChange` not provided", value);
        },
        onBlur: onBlurExternal,
        onFocus: onFocusExternal,
        debounceChange = 0,
        readOnly = false,
        className,
        renderToolbarOnFocus = false,
        toolbarPortal,
        autoFocus = false,
        toolbarTheme = "default",
        toolbarDirection = "horizontal",
        hideToolbarItems = [],
        toolbarClassName,
        editor,
        hideToolbar,
        value: initialValue,
        onReady,
    } = props;

    const {isMobile} = useMobile();
    const [value, setValue] = useState<JSONContent | null>(null);
    const [debounced] = useDebounce(value, debounceChange);

    const ref = useRef<HTMLDivElement>(null);

    const onContentChange = useCallback(({editor, transaction}: EditorEvents["update"]) => {
        if (transaction.docChanged && !transaction.getMeta(ySyncPluginKey)) {
            setValue(editor.getJSON());
        }
    }, []);

    const onFocus: FocusEventHandler<HTMLDivElement> = (e) => {
        onFocusExternal?.(e);

        if (renderToolbarOnFocus) {
            setFocusedInternal(true);
        }
    };

    const onBlur: FocusEventHandler<HTMLDivElement> = (e) => {
        const toolbarElement = document.querySelector(".toolbar-element");
        const isToolbarRelated = e.relatedTarget ? toolbarElement?.contains(e.relatedTarget as Node) : false;

        if (!isToolbarRelated) {
            if (renderToolbarOnFocus) {
                setFocusedInternal(false);
            }
            onBlurExternal?.(e);
        }
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const clipboardText = e.clipboardData.getData("text/plain");
        let textToInsert = clipboardText;

        if (props.maxLength) {
            const currentText = editor?.storage.characterCount.characters();
            const spaceRemaining = props?.maxLength - currentText;

            if (clipboardText.length > spaceRemaining) {
                textToInsert = clipboardText.slice(0, spaceRemaining);
            }

            if (spaceRemaining === 0 && clipboardText.length >= currentText) {
                editor.commands.deleteSelection();
                const newCurrentText = editor?.storage.characterCount.characters();
                const newSpaceRemaining = props?.maxLength - newCurrentText;
                textToInsert = clipboardText.slice(0, newSpaceRemaining);
            }
        }

        editor.commands.insertContent(textToInsert);
    };

    useEffect(() => {
        editor.on("update", onContentChange);

        return () => {
            editor.off("update", onContentChange);
        };
    }, []);

    useEffect(() => {
        if (editor.isEditable && debounced) {
            onChange(debounced as any, editor.getText(), editor.storage.characterCount.characters());
        }
    }, [debounced]);

    useEffect(() => {
        editor.setEditable(!readOnly);

        if (!readOnly && !renderToolbarOnFocus && autoFocus) {
            editor.chain().focus().run();
        }
    }, [readOnly]);

    useEffect(() => {
        if (typeof onReady === "function") {
            onReady();
        }
    }, [onReady]);

    useEffect(() => {
        if (props.updateWhenValueChanges && props.value) {
            editor.commands.setContent(props.value, false);
        }
    }, [props.updateWhenValueChanges, props.value]);

    const [focusedInternal, setFocusedInternal] = useState(false);

    const toolbarRenderingCondition = readOnly || hideToolbar ? false : !renderToolbarOnFocus ? true : focusedInternal;

    const toolbarPortalContainer = toolbarPortal ? document.getElementById(toolbarPortal) : undefined;

    const isLimitReached = props.maxLength && editor.storage.characterCount.characters() >= props.maxLength;

    const toolbarProps: ToolbarGenericProps = {
        onActionDone: focus,
        hideToolbarItems,
        toolbarDirection,
        toolbarTheme,
        isMobile,
        className: toolbarClassName,
        editor,
    };

    if (!editor) {
        return null;
    }

    return (
        <div className={cls(classes.editor, className)}>
            {toolbarPortal ? (
                <Portal container={toolbarPortalContainer}>{toolbarRenderingCondition ? <Toolbar {...toolbarProps} /> : null}</Portal>
            ) : toolbarRenderingCondition ? (
                <Toolbar {...toolbarProps} />
            ) : null}
            <div className={cls("mozilla-scrollbar", classes.sizeFull, classes.document)} ref={ref}>
                <div data-private className={classes.sizeFull}>
                    <EditorContent onPaste={handleOnPaste} editor={editor} onFocus={onFocus} onBlur={onBlur} className={classes.sizeFull} />
                </div>
            </div>
            {props?.maxLength && !props.readOnly && (
                <div className={cls(classes.charactersCount, isLimitReached && classes.charactersCountLimitReached)}>
                    {focusedInternal && (
                        <>
                            {editor.storage.characterCount.characters()}/{props.maxLength}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const EditorWrapper = (props: Omit<EditorProps, "editor">) => {
    const {firstName, lastName} = useUserInfo();
    const value = props.value ? parseAsTipTapJSON(props.value) : null;

    const collaborationProvider = useRef<HocuspocusProvider>();

    const extensions = useMemo(() => {
        const commonExtentions = getCommonExtentions(props.collaborative);
        const config = [
            ...commonExtentions,
            PlaceholderPlugin.configure({placeholder: props.placeholder}),
            CharacterCountPlugin.configure({limit: props.maxLength}),
        ];

        if (props.collaborative && props.collaborationToken && props.documentId) {
            const provider = new HocuspocusProvider({
                url: env.editorHocuspocusWs,
                name: props.documentId,
                token: props.collaborationToken,
                onSynced: () => props.onReady?.(),
                preserveConnection: false,
            });

            collaborationProvider.current = provider;

            config.push(
                CollaborationPlugin.configure({
                    document: provider.document,
                }),

                CollaborationCursorPlugin.configure({
                    provider: provider,
                    user: {
                        name: `${firstName} ${lastName}`,
                        color: getHexColor(makeInitials(firstName, lastName)),
                    },
                })
            );
        }

        return config;
    }, [props.collaborative, props.collaborationToken, props.documentId, props.placeholder]);

    const editor = useEditor(
        {
            extensions,
            editable: !props.readOnly,
            content: !props.collaborative ? parseAsTipTapJSON(value) : null,
            editorProps: {
                attributes: {
                    class: cls("editor", classes.editable, props.classes?.editable),
                    "data-id": "textbox",
                },
            },
        },
        [props.collaborative, props.placeholder, extensions]
    );

    useEffect(() => {
        return () => {
            collaborationProvider.current?.destroy();
        };
    }, []);

    useLayoutEffect(() => {
        if (props.editorRef) {
            props.editorRef.current = editor;
        }
    }, [editor]);

    if (!editor) {
        return null;
    }

    return <Editor {...props} onReady={props.collaborative ? undefined : props.onReady} editor={editor} />;
};

export default memo(EditorWrapper, (prev, current) => {
    if (prev.readOnly !== current.readOnly) {
        return false;
    }

    if (prev.toolbarPortal !== current.toolbarPortal) {
        return false;
    }

    if (prev.onBlur !== current.onBlur) {
        return false;
    }

    if (prev.placeholder !== current.placeholder) {
        return false;
    }

    if (prev.onFocus !== current.onFocus) {
        return false;
    }

    if (current.updateWhenValueChanges && prev.value !== current.value) {
        return false;
    }

    return true;
});
