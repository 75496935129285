import type {DocumentNode} from "graphql";

export function usePregeneratedHashes(hashes: Record<string, string | undefined>) {
    return (doc: DocumentNode): string => {
        const operationDefinition = doc.definitions.find((def) => def.kind === "OperationDefinition");

        if (!operationDefinition || operationDefinition.kind !== "OperationDefinition") {
            console.warn("[Persisted Queries] Cannot find OperationDefinition from", doc);
            return "";
        }

        if (!operationDefinition.name) {
            console.warn("[Persisted Queries] Name missing from operation definition", operationDefinition);
            return "";
        }

        const hash = hashes[operationDefinition.name.value];

        if (!hash) {
            console.warn(`[Persisted Queries] Cannot find pregerated hash for ${operationDefinition.name.value}`);
            return "";
        }

        return hash;
    };
}
