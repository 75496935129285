import apollo from "@workhorse/api/apollo";
import {CheckUrlDocument} from "@generated/data";

export enum AirtableLinkType {
    AIRTABLE_LINK = "airtableLink",
    EMBED_CODE = "embedCode",
}

export const airtableLinkRegex = /^((https|http):\/\/)?(www\.)?airtable.com\/(embed\/)*/i;

export const handleAirtableLink = (linkToCheck: string) => {
    const linkEmbed = linkToCheck.includes("airtable.com/embed/")
        ? linkToCheck
        : linkToCheck.replace("airtable.com/", "airtable.com/embed/");

    const linkParams = linkEmbed.includes("?") ? linkEmbed : linkEmbed + "?backgroundColor=gray&viewControls=on";

    return linkParams;
};

export const handleAirtableRegex = (linkToCheck: string) => {
    const isLinkValid = airtableLinkRegex.test(linkToCheck.toString());
    return !isLinkValid ? "This is not a valid Airtable link!" : null;
};

export const checkIfIframeable = async (linkToCheck: string) => {
    const isUrlValid = await apollo.client.query({
        query: CheckUrlDocument,
        variables: {
            url: linkToCheck,
        },
    });

    const isValid = isUrlValid.data.iframeCheckUrl?.isFrameable;

    return !isValid ? "This is not a valid Airtable link!" : null;
};
