import {
    CheckGuestCodeDocument,
    CheckGuestCodeOutcome,
    JoinWithInviteLinkDocument,
    LocalUserDocument,
    SessionLifecycle,
    UpdateGuestInfoDocument,
} from "@generated/data";
import {collapseWhiteSpace} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {AuthService} from "@workhorse/api/authService";
import {useMutation} from "@workhorse/api/data";
import React, {useEffect, useState} from "@workhorse/api/rendering";
import {useHistory, useParams} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import Loading from "@workhorse/components/Loading";
import {useUser} from "@workhorse/components/user";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import CodeAlreadyUsedDialog from "./CodeAlreadyUsedDialog";
import GuestInfoDialog from "./GuestInfoDialog";

interface UserInviteCodeProps {
    token?: string;
    sessionId?: string | null;
    onDone?: () => void;
}

const UserInviteCode: React.FC<UserInviteCodeProps> = (props: UserInviteCodeProps) => {
    const [needInfo, setNeedInfo] = useState(false);
    const [codeAlreadyUsed, setCodeAlreadyUsed] = useState(false);
    const [sessionLifecycle, setSessionLifecycle] = useState<SessionLifecycle>(SessionLifecycle.NotStarted);
    const [sessionId, setSessionId] = useState<string | null>(null);

    const hostType = useHostType();

    const history = useHistory();
    const params = useParams<{inviteCode: string}>();
    const user = useUser();

    const [trackEvent] = useTrackingEvents();

    const [joinWithInviteLink, joinWithInviteLinkResult] = useMutation(JoinWithInviteLinkDocument);
    const [checkCode] = useMutation(CheckGuestCodeDocument);
    const [updateGuest] = useMutation(UpdateGuestInfoDocument);

    const guestToken = props.token ?? params.inviteCode;

    useEffect(() => {
        checkCode({variables: {code: guestToken, sessionId: props.sessionId}}).then((res) => {
            if (!res || !res.data || !res.data.checkGuestCode) {
                toast(<p>Error joining...</p>, {type: "error"});
                return;
            }

            if (res.data.checkGuestCode.sessionLifecycle) {
                setSessionLifecycle(res.data.checkGuestCode.sessionLifecycle);
            }

            if (res.data.checkGuestCode.sessionId) {
                setSessionId(res.data.checkGuestCode.sessionId);
            }

            switch (res.data.checkGuestCode.outcome) {
                case CheckGuestCodeOutcome.SessionEnded:
                    history.push(
                        res.data.checkGuestCode.hasMemoryAccess
                            ? `/memory/session/${res.data.checkGuestCode.sessionId}?token=${guestToken}`
                            : `/feedback/${res.data.checkGuestCode.sessionId}`
                    );
                    break;
                case CheckGuestCodeOutcome.ResetToken:
                    console.log(`[UserInviteCode] removeItem access_token`);
                    AuthService.getInstance().logout(window.location.href);
                    break;
                case CheckGuestCodeOutcome.ValidNeedInfo:
                    setNeedInfo(true);
                    break;
                case CheckGuestCodeOutcome.CodeAlreadyUsed:
                    setCodeAlreadyUsed(true);
                    break;
                case CheckGuestCodeOutcome.ValidButNotForHim:
                    toast(<p>You are not invited to this session!</p>, {type: "error"});
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 5000);
                    break;
                case CheckGuestCodeOutcome.NotValid:
                    toast(
                        <p>
                            Session has <strong>ended.</strong>
                        </p>,
                        {type: "error"}
                    );
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 3000);
                    break;
                case CheckGuestCodeOutcome.ValidRedirectToSession:
                    history.push(`/session/${res.data.checkGuestCode.sessionId}`);
                    // for urls that have the structure /session/id?token=token, doing the history push will not clear the token in memory
                    // resulting in a redirect loop caused by this mutation being called in a loop. That's why we call onDone here.
                    props.onDone?.();
                    break;
                case CheckGuestCodeOutcome.Valid: {
                    const language = localStorage.getItem("user-language") ?? undefined;

                    joinWithInviteLink({variables: {code: guestToken, isAuthenticated: false, language}})
                        .then((res) => {
                            if (!res.data?.joinWithInviteLink) {
                                toast(<p>Error joining...</p>, {type: "error"});
                                return;
                            }

                            if (res.data.joinWithInviteLink.error) {
                                if (res.data.joinWithInviteLink.error === "need_login") {
                                    history.push(`/session/${res.data.joinWithInviteLink.sessionId}`);
                                    return;
                                }
                                toast(<p>{res.data.joinWithInviteLink.error}</p>, {type: "error"});
                                return;
                            }

                            if (res.data.joinWithInviteLink.linkAlreadyUsed && res.data.joinWithInviteLink.sessionId) {
                                history.push(`/session/${res.data.joinWithInviteLink.sessionId}`);
                                return;
                            }

                            if (res.data.joinWithInviteLink.access_token) {
                                AuthService.getInstance()
                                    .loginGuest(res.data.joinWithInviteLink.access_token, {
                                        sessionId: res.data.joinWithInviteLink.sessionId,
                                    })
                                    .then((x) => {
                                        apollo.client.writeQuery({
                                            query: LocalUserDocument,
                                            data: {
                                                isAuthenticated: true,
                                                localUser: {
                                                    __typename: "LocalUser",
                                                    email: "pending@guest.sessions.us",
                                                    firstName: "pending",
                                                    lastName: "guest",
                                                    role: "GUEST",
                                                    language: "en-GB",
                                                },
                                            },
                                        });

                                        if (props.onDone) {
                                            props.onDone();
                                        } else {
                                            if (x && x.sessionId) {
                                                history.push(`/session/${x.sessionId}`);
                                            }
                                        }
                                    });
                            }
                        })
                        .catch((err) => {
                            console.error(`Error joining as guest: ${err}`);
                        });
                    break;
                }

                default:
                    break;
            }
        });
    }, [joinWithInviteLink, checkCode, guestToken, history, user.role, user.isAuthenticated, props, setSessionLifecycle]);

    const onInfoSubmit = (name: string) => {
        const nameWithWhitespaceCollapsed = collapseWhiteSpace(name);

        updateGuest({
            variables: {
                code: guestToken,
                name: nameWithWhitespaceCollapsed,
            },
        }).then((res) => {
            if (res.data && res.data.setGuestInfo) {
                joinWithInviteLink({variables: {code: guestToken, isAuthenticated: false}})
                    .then((res) => {
                        if (!res.data?.joinWithInviteLink) {
                            toast(<p>Error joining...</p>, {type: "error"});
                            return;
                        }

                        if (res.data.joinWithInviteLink.error) {
                            toast(<p>{res.data.joinWithInviteLink.error}</p>, {type: "error"});
                            return;
                        }

                        if (res.data.joinWithInviteLink.linkAlreadyUsed && res.data.joinWithInviteLink.sessionId) {
                            history.push(`/session/${res.data.joinWithInviteLink.sessionId}`);
                            return;
                        }

                        if (res.data.joinWithInviteLink.access_token) {
                            AuthService.getInstance()
                                .loginGuest(res.data.joinWithInviteLink.access_token, {
                                    sessionId: res.data.joinWithInviteLink.sessionId,
                                })
                                .then((x) => {
                                    apollo.client.writeQuery({
                                        query: LocalUserDocument,
                                        data: {
                                            isAuthenticated: true,
                                            localUser: {
                                                __typename: "LocalUser",
                                                email: "pending@guest.sessions.us",
                                                firstName: "pending",
                                                lastName: "guest",
                                                role: "GUEST",
                                                language: "en-GB",
                                            },
                                        },
                                    });

                                    if (props.onDone) {
                                        props.onDone();
                                    } else {
                                        if (x && x.sessionId) {
                                            history.push(`/session/${x.sessionId}`);
                                        }
                                    }

                                    trackEvent("guest_joined_session", {event_category: TrackingEventCategory.GuestActions});
                                });
                        }
                    })
                    .catch((err) => {
                        console.error(`Error joining as guest: ${err}`);
                    });
            } else {
                // Error
                console.error(`Error joining as guest`);
            }
        });
    };

    useEffect(() => {
        if (!joinWithInviteLinkResult.called && sessionLifecycle === SessionLifecycle.Started) {
            onInfoSubmit("");
        }
    }, [joinWithInviteLinkResult.called, onInfoSubmit]);

    return sessionLifecycle === SessionLifecycle.Started ? (
        <Loading />
    ) : needInfo && sessionId ? (
        <GuestInfoDialog sessionId={sessionId} hostType={hostType} onSubmit={onInfoSubmit} />
    ) : codeAlreadyUsed ? (
        <CodeAlreadyUsedDialog />
    ) : (
        <Loading />
    );
};

export default UserInviteCode;
