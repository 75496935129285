import {
    MemoryAccessType,
    MemoryListSessionFragment,
    MemorySessionRecordingFragment,
    MemorySessionRecordingFragmentDoc,
    ParticipantsMacroAccess,
    SessionFilterEnum,
    SessionFlagsType,
    SessionOrderByInput,
    SortOrder,
} from "@generated/data";
import {RestrictedWidgetsType} from "@sessions/common/permissions";
import apollo from "@workhorse/api/apollo";

export const FIRST_PAGE = 0;

// Persisted only in memory context
export enum TempLocalStorageParams {
    currentPage = "memory_current_page",
    orderBy = "memory_order_by",
    searchTerm = "memory_search_term",
}

// Persisted generally
export enum MemoryLocalStorageParams {
    owner = "memory_owner",
    invited = "memory_invited",
    sessions = "memory_filter_sessions",
    attended = "memory_filter_attended",
    declined = "memory_filter_declined",
    withRecording = "memory_filter_withRecording",
    withoutRecording = "memory_filter_withoutRecording",
    participantsEmails = "memory_filter_participants_emails",
    resources = "memory_filter_resources",
    interval = "memory_interval",
    withSessionTags = "memory_filter_withSessionTags",
}

export type SessionsFilters = {
    owner: boolean;
    invited: boolean;
    sessions: SessionFilterEnum;
    attended: boolean;
    declined: boolean;
    withRecording: boolean;
    withoutRecording: boolean;
    participantsEmails?: string[];
    withSessionTags?: string[];
};

export type MemorySessionsListOrderBy = {
    dbSort: SessionOrderByInput[];
    participantsCount?: SortOrder;
    participantStatus?: SortOrder;
    recording?: SortOrder;
    sessionTags?: SortOrder;
};

export const defaultFilters: SessionsFilters = {
    owner: true,
    invited: true,
    sessions: SessionFilterEnum.All,
    attended: true,
    declined: true,
    withRecording: true,
    withoutRecording: true,
    participantsEmails: [],
    withSessionTags: [],
};

export const types: SessionFilterEnum[] = [
    SessionFilterEnum.All,
    SessionFilterEnum.Bookings,
    SessionFilterEnum.Events,
    SessionFilterEnum.Rooms,
];

export function getSessionRecordings(recordingIds: string[]) {
    const recordings: MemorySessionRecordingFragment[] = [];

    for (const recordingId of recordingIds) {
        const data = apollo.cache.readFragment({
            fragment: MemorySessionRecordingFragmentDoc,
            fragmentName: "MemorySessionRecording",
            id: apollo.cache.identify({__ref: recordingId}),
        });
        if (data?.id) {
            recordings.push(data);
        }
    }

    return recordings.slice().sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
        });
    });
}

export const memoryAccessTypeOptions = [
    {value: MemoryAccessType.Private, label: "Hosts"},
    {value: MemoryAccessType.JustAttendees, label: "Attendees"},
    {value: MemoryAccessType.Public, label: "Anyone with the link"},
];

export const hideAgendaOptions = [
    {value: SessionFlagsType.Hosts, label: "Moderators"},
    {value: SessionFlagsType.Speakers, label: "Moderators and speakers"},
    {value: SessionFlagsType.Everyone, label: "Everyone"},
];

export const getTypeDisplayName = (session: MemoryListSessionFragment) => {
    if (session.room) {
        return "Room";
    } else if (session.event) {
        return "Event";
    } else if (session.isBooking) {
        return "Booking";
    } else if (session.quickSession) {
        return "Instant";
    } else {
        return "Planned";
    }
};

/**
 * Determines whether a user can see a macro based on the restrictedWidgets field on Session.
 * ** This is used to show/hide macros in MEMORY. **
 *
 * @param restrictedWidgets - The restricted widgets configuration.
 * @param artifactId - The ID of the artifact.
 * @param hasAttended - Indicates whether the user has attended.
 * @param isHost - Indicates whether the user is the host.
 * @returns A boolean value indicating whether the user can see the macro.
 */
export const canUserSeeMacroInMemory = (
    restrictedWidgets: string | RestrictedWidgetsType,
    artifactId: string,
    hasAttended: boolean,
    isHost: boolean
) => {
    if (!restrictedWidgets) {
        return true;
    }

    const restrictedWidgetsState =
        typeof restrictedWidgets === "string" ? (JSON.parse(restrictedWidgets ?? "") as RestrictedWidgetsType) : restrictedWidgets;

    if (restrictedWidgetsState.disabled?.includes(artifactId)) {
        return false;
    }

    if (restrictedWidgetsState.public?.includes(artifactId)) {
        if (!hasAttended) {
            return false;
        }
    }

    if (restrictedWidgetsState.attendees?.includes(artifactId) && !isHost) {
        return false;
    }

    return true;
};

/**
 * Retrieves the restricted macros based on the provided parameters.
 * @param restrictedWidgets - The restricted widgets configuration.
 * @param hasAttended - Indicates whether the user has attended the event.
 * @param isHost - Indicates whether the user is the host of the event.
 * @returns An array of restricted macros (artifactIds).
 */
export const getRestrictedMacros = (restrictedWidgets: string | RestrictedWidgetsType, hasAttended: boolean, isHost: boolean) => {
    if (isHost || !restrictedWidgets) {
        return [];
    }

    let rW: string[] = [];

    const restrictedWidgetsState =
        typeof restrictedWidgets === "string" ? (JSON.parse(restrictedWidgets ?? "") as RestrictedWidgetsType) : restrictedWidgets;

    if (restrictedWidgetsState.disabled) {
        rW = rW.concat(restrictedWidgetsState.disabled);
    }

    if (restrictedWidgetsState.public) {
        if (!hasAttended) {
            rW = rW.concat(restrictedWidgetsState.public);
        }
    }

    if (restrictedWidgetsState.attendees && !isHost) {
        rW = rW.concat(restrictedWidgetsState.attendees);
    }

    return rW;
};

/**
 * Determines whether the participants macro should be hidden based on the given access level and user roles.
 *
 * **Used in Player **
 * @param participantMacroAccess - The access level for the participants macro.
 * @param isHost - Indicates whether the user is a host.
 * @param isSpeaker - Indicates whether the user is a speaker.
 * @returns True if the participants macro should be hidden, false otherwise.
 */
export const isParticipantsMacroHidden = (participantMacroAccess: ParticipantsMacroAccess, isHost: boolean, isSpeaker: boolean) => {
    if (participantMacroAccess === ParticipantsMacroAccess.Everyone) {
        return false;
    }

    if (participantMacroAccess === ParticipantsMacroAccess.Disabled) {
        return true;
    }

    if (participantMacroAccess === ParticipantsMacroAccess.Moderators && !isHost && !isSpeaker) {
        return true;
    }

    return false;
};
