import MuiDialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import React, {useEffect, useState} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import {ButtonVariant} from "./Button/Button";
import useDialogStyles from "./dialogStyles";

type DialogProps = ExtractProps<typeof MuiDialog>;
type OmitThisProps<T> = Omit<T, "open">;

const useStyles = makeStyles((theme) => ({
    closeDialogButton: {
        width: theme.typography.pxToRem(80),
        height: theme.typography.pxToRem(80),
        position: "absolute",
        top: 0,
        right: 0,
        color: theme.main.palette.text.secondary,
        [theme.breakpoints.down("sm")]: {
            width: "unset",
            height: "unset",
        },
    },

    backdrop: {
        backgroundColor: "rgba(100, 116, 139, 0.8)",
    },

    paper: {
        borderRadius: theme.typography.pxToRem(12),
        minHeight: theme.typography.pxToRem(342),
        minWidth: theme.typography.pxToRem(100),
        width: theme.typography.pxToRem(639),
        maxWidth: "90%",
        height: "auto",
        display: "flex",
        justifyContent: "space-between",
    },

    header: {
        minHeight: theme.typography.pxToRem(80),
        justifyContent: "flex-start",
        padding: "0 40px 0 24px",
        "& > .fosTypography": {
            fontWeight: 500,
            color: theme.main.palette.text.primary,
        },
    },

    iconBlock: {
        flex: "0 0 90px",
    },

    contentBlock: {
        padding: theme.spacing(3),
        "&.no-icon": {
            paddingLeft: theme.spacing(6.25),
        },
    },

    contentHeader: {
        fontWeight: 700,
        color: theme.main.palette.text.primary,
        "&.fosTypography": {
            marginBottom: theme.spacing(2.5),
        },
    },

    contentSubheader: {
        color: theme.main.palette.text.tertiary,
    },

    [theme.breakpoints.down("sm")]: {
        contentHeader: {
            "&.fosTypography": {
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
        contentBlock: {
            flexDirection: "column",
        },
    },
}));

export type ConfirmationDialogImperativeRef = {
    toggle?: (mode?: boolean) => void;
};

type ConfirmationDialogProps = {
    connector: React.MutableRefObject<ConfirmationDialogImperativeRef>;
    onReject?: () => void;
    onConfirm?: () => void;
    requireExplicitConfirmation?: boolean;
    title: string;
    message: string;
    info?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    icon?: JSX.Element;
    children?: React.ReactNode | React.ReactNode[];
    requireExplicitCloseAction?: boolean;
    classes?: {
        contentHeader?: string;
        contentSubheader?: string;
        contentBlock?: string;
    };
    onToggle?: (state: boolean) => void;
    confirmButtonVariant?: ButtonVariant;
    cancelButtonVariant?: ButtonVariant;
    isMobile: boolean;
};

function ConfirmationDialog(props: ConfirmationDialogProps) {
    const classes = useStyles();
    const buttonClasses = useDialogStyles();
    const {
        onConfirm,
        onReject,
        requireExplicitConfirmation = false,
        requireExplicitCloseAction = false,
        title,
        message,
        info,
        cancelButtonText,
        confirmButtonText,
        icon = null,
        cancelButtonVariant = "quaternary",
        confirmButtonVariant = "primary",
        isMobile,
    } = props;
    const [open, setOpen] = useState(false);
    const toggle = (mode?: boolean) => setOpen((c) => (typeof mode === "boolean" ? mode : !c));
    const onClose = () => setOpen(false);

    useEffect(() => {
        props.connector.current.toggle = toggle;

        if (props.onToggle) {
            props.onToggle(open);
        }

        return () => {
            props.connector.current = {};
        };
    }, [open]);

    const confirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        if (!requireExplicitCloseAction) {
            onClose();
        }
    };

    const reject = () => {
        if (onReject) {
            onReject();
        }
        onClose();
    };

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            BackdropProps={{
                className: "standard-backdrop",
            }}
            PaperProps={{
                className: cls("flex flex-col", classes.paper),
            }}
            classes={{
                container: "standard-dialog-container",
            }}
        >
            {requireExplicitConfirmation ? null : (
                <IconButton className={classes.closeDialogButton} onClick={onClose}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            )}
            <div className={cls("flex flex00-auto flex-align-center", classes.header)}>
                <Typography variant="xl" component="span">
                    {title}
                </Typography>
            </div>

            <div className={cls("flex", classes.contentBlock, !icon && "no-icon", props.classes?.contentBlock)}>
                {isMobile ? (
                    <>
                        <div className={cls("flex flex11-auto")}>
                            {icon ? <div className={classes.iconBlock}>{icon}</div> : null}
                            <Typography variant="xl3" className={cls(classes.contentHeader, props.classes?.contentHeader)}>
                                {message}
                            </Typography>
                        </div>
                        {info ? (
                            <Typography className={cls(classes.contentSubheader, props.classes?.contentSubheader)}>{info}</Typography>
                        ) : null}
                    </>
                ) : (
                    <>
                        {icon ? <div className={classes.iconBlock}>{icon}</div> : null}

                        <div className={cls("flex flex11-auto flex-col")}>
                            <Typography variant="xl3" className={cls(classes.contentHeader, props.classes?.contentHeader)}>
                                {message}
                            </Typography>
                            {info ? (
                                <Typography className={cls(classes.contentSubheader, props.classes?.contentSubheader)}>{info}</Typography>
                            ) : null}
                        </div>
                    </>
                )}
            </div>

            {props.children}

            <div className={cls("flex flex00-auto", buttonClasses.buttons, !cancelButtonText && "no-cancel")}>
                {cancelButtonText ? (
                    <Button variant={cancelButtonVariant} onClick={reject}>
                        {cancelButtonText}
                    </Button>
                ) : null}
                {confirmButtonText ? (
                    <Button variant={confirmButtonVariant} withMarginLeft onClick={confirm}>
                        {confirmButtonText}
                    </Button>
                ) : null}
            </div>
        </MuiDialog>
    );
}

export default ConfirmationDialog;
