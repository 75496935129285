import {ProductToolFragment} from "@generated/data";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {cls} from "@ui/cdk/util/util";
import Paper from "@ui/core/components/Paper";
import React, {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import classes from "./style/MyProductTool.module.scss";
import ToolComponentForm from "./ToolComponentForm";
import ToolComponentPreviewDialog from "./ToolComponentPreviewDialog";

export type MyProductToolEditableFields = Pick<
    ProductToolFragment,
    "url" | "title" | "description" | "enabled" | "consentOwnershipOfIcon" | "iconUrl"
>;

export interface ToolComponentProps {
    toolData: MyProductToolEditableFields & Pick<ProductToolFragment, "id" | "iconUrl">;
    userId: string;
    timezone: string;
    isConfirmDisabled?: boolean;
    onUpsert: (upsertData: MyProductToolEditableFields, id?: string) => Promise<boolean>;
    onUploadProductToolIcon: (toolId: string, file: File) => Promise<string>;
    onDeleteProductTool: (toolId: string) => void;
    allowInitialDataSubmit?: boolean;
    classes?: {
        root?: string;
        header?: string;
        body?: string;
        footer?: string;
    };
    isOnboarding?: boolean;
}

const ToolComponent = (props: ToolComponentProps) => {
    const previewDialogImperativeRef: React.MutableRefObject<DialogImperativeRef> = useRef({});

    const [inPreview, setInPreview] = useState<boolean>(false);

    useEffect(() => {
        if (inPreview && previewDialogImperativeRef.current?.toggle) {
            previewDialogImperativeRef.current.toggle();
        }
    }, [inPreview]);

    const handleOpenPreviewDialog = () => {
        setInPreview(true);
    };

    const handleClosePreviewDialog = () => {
        setInPreview(false);
    };

    return (
        <div className={cls(classes.root, props.classes?.root)}>
            {inPreview && (
                <ToolComponentPreviewDialog {...props} imperativeRef={previewDialogImperativeRef} onClose={handleClosePreviewDialog} />
            )}

            <Paper elevation={0} className={cls(classes.body, props.classes?.body)}>
                <ToolComponentForm {...props} onPreview={handleOpenPreviewDialog} />
            </Paper>
        </div>
    );
};

export default memo(ToolComponent, (prev, next) => {
    const keys = Object.keys(prev.toolData) as Array<keyof (typeof prev)["toolData"]>;

    for (const key of keys) {
        if (prev.toolData[key] !== next.toolData[key]) {
            return false;
        }
    }

    return true;
});
