import {DeleteUserGoogleProvidersDocument, SaveGoogleTokenDocument} from "@generated/data";
import env from "@generated/environment";
import {useMutation} from "@workhorse/api/data";
import {useCallback, useMemo} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {loadScript} from "@workhorse/util";

declare global {
    // @ts-ignore
    const google: typeof import("google.accounts");
}

interface CodeAuthResponse {
    code: string;
    scope: string;
    state: string;
    authuser: string;
    hd: string;
    prompt: string;
}

interface CodeErrorResponse {
    error: string;
    error_description: string;
    error_uri: string;
}

type CodeResponse = CodeAuthResponse | CodeErrorResponse;

export type UseGoogleLoginProps = {
    onLogin?: (email?: string) => void;
    onLoginFailure?: () => void;

    onLogout?: () => void;
    onLogoutFailure?: () => void;
};

type UseGoogleLoginReturnType = [(scopes: string[]) => void, (email: string) => void];

export const useGoogleLogin = (props: UseGoogleLoginProps): UseGoogleLoginReturnType => {
    const {onLogin, onLoginFailure, onLogout, onLogoutFailure} = props;

    const [saveToken] = useMutation(SaveGoogleTokenDocument);
    const [removeToken] = useMutation(DeleteUserGoogleProvidersDocument);

    const request = useCallback(
        (scopes: string[]) => {
            loadScript("https://accounts.google.com/gsi/client", "google-identity-services").then((script) => {
                const authCallback = (res: CodeResponse) => {
                    console.log(`Google response: ${JSON.stringify(res)}`);
                    if ("error" in res) {
                        console.error(`Google login error: [${res.error}] ${res.error_description}`);
                        onLoginFailure && onLoginFailure();
                    } else {
                        saveToken({
                            variables: {
                                code: res.code,
                                redirectUri: document.location.origin,
                            },
                        }).then((res) => {
                            markAsSeen({
                                GLOBALS: {
                                    syncEmailNotification: true,
                                },
                            });
                            onLogin?.(res.data?.saveGoogleToken?.email ?? undefined);
                        });
                    }
                };

                const client = google.accounts.oauth2.initCodeClient({
                    client_id: env.google.clientId,
                    scope: scopes.join(" "),
                    ux_mode: "popup",
                    redirect_uri: document.location.origin,
                    callback: authCallback,
                    error_callback: onLoginFailure,
                });

                client.requestCode();
            });
        },
        [onLogin, onLoginFailure, saveToken]
    );

    const disconnect = useCallback(
        (email: string) => {
            removeToken({
                variables: {
                    email,
                },
            })
                .then((response) => {
                    if (response.data?.deleteUserGoogleProviders) {
                        onLogout && onLogout();
                    }
                })
                .catch(() => {
                    onLogoutFailure?.();
                });
        },
        [onLogout, onLogoutFailure, removeToken]
    );

    return useMemo(() => [request, disconnect], [request, disconnect]);
};
