import {useClientEvent} from "@api/events/client";
import {AgendaItemType, ChooseToolStep} from "@generated/data";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Typography from "@ui/cdk/Typography";
import {cls, togglePalette} from "@ui/cdk/util/util";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import AgendaGeneratorContainer from "@workhorse/api/aiGenerator/AgendaGeneratorContainer";
import {AIGeneratedAgendaItem} from "@workhorse/api/aiGenerator/AgendaGeneratorMessenger";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {FocusedAgendaItem} from "@workhorse/api/designer/lib/utils/agendaItemUtils";
import {commitUpdateAgendaTemplate} from "@workhorse/api/designer/methods";
import {manager} from "@workhorse/api/dndClient";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {useParams} from "@workhorse/api/routing";
import {useCoolState} from "@workhorse/api/state";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import secondsToTime from "@workhorse/api/utils/secondsToTime";
import AccessList from "@workhorse/components/access/AccessList/AccessList";
import {ActionCategory, categMap} from "@workhorse/components/command-palette/actionCategTree";
import {paletteActiveCategDefault, usePaletteActiveCateg} from "@workhorse/components/command-palette/CommandPaletteProviders";
import {usePaletteExcludedItems} from "@workhorse/components/command-palette/PaletteActionProvider";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {QueryRenderer} from "@workhorse/dataApi";
import {WithMobileState} from "@workhorse/declarations";
import DesignerToolPlaceholder from "@workhorse/pages/designer/ToolConfiguration/DesignerToolPlaceholder";
import {useAiGeneratedAgenda} from "@workhorse/providers/AiGeneratedAgendaProvider";
import {evictLocalQueries} from "@workhorse/providers/HostTypeProvider";
import {useAgendaItems, useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {format} from "date-fns";
import {DndProvider} from "react-dnd";
import {useCurrentTemplate} from "../TemplateProviders";
import {getTemplate} from "../utils";
import AgendaItemCreateButton from "./AgendaItemCreateButton/AgendaItemCreateButton";
import AgendaItemEditor from "./AgendaItemEditor/AgendaItemEditor";
import AgendaItemDragPreview from "./AgendaItemEditor/components/AgendaItemDragPreview/AgendaItemDragPreview";
import AgendaTemplatesEditorActions from "./AgendaTemplatesEditorActions/AgendaTemplatesEditorActions";
import AgendaTemplatesEditorName from "./AgendaTemplatesEditorName/AgendaTemplatesEditorName";
import AgendaTemplatesEditorSubHeader from "./AgendaTemplatesEditorSubHeader/AgendaTemplatesEditorSubHeader";
import classes from "./style/AgendaTemplatesEditor.module.scss";

type AgendaTemplateEditorProps = {
    templateId: string;
} & WithMobileState;

const AgendaTemplatesEditorContainer = (props: AgendaTemplateEditorProps) => {
    const {mobileState} = props;

    const agendaItems = useAgendaItems();

    const {setExcludedItems} = usePaletteExcludedItems();
    const {setPaletteActiveCateg} = usePaletteActiveCateg();
    const {mixpanelTrack} = useMixpanelTracker();
    const currentDesignerTemplate = useSession();

    const user = useUserInfo();

    const params = useParams<{templateId: string}>();

    const templateId = params.templateId;

    const {isPublic, isReadOnly, userId: agendaOwnerId, accessList, updatedAt} = useCurrentTemplate();
    const [status, setStatus] = useCoolState<"updatedAt" | "saved">("updatedAt");

    const createNewAgendaItem = useCallback(() => {
        const id = designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Planned,
            title: "",
            locked: user.id,
        });

        mixpanelTrack("frontend-add-agenda-item", "agendas");
        commitAfterUpdate();

        setStatus("saved");
    }, []);

    const isCollaborativeRef = useRef(!!accessList?.length);
    isCollaborativeRef.current = !!accessList?.length;

    const readOnlyRef = useRef(isReadOnly);
    readOnlyRef.current = isReadOnly;

    const setEditState = designer.state.update;

    const [openDeleteSelected, setOpenDeleteSelected] = useState<boolean>(false);

    const [updateMultiple, setUpdateMultiple] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);

    const [selected, setSelected] = useState<string[]>([]);
    const selectedRef = useRef(selected);
    selectedRef.current = selected;

    //used to show the AI copilot button only on the latest focused item
    const [focusedAgendaItem, setFocusedAgendaItem] = useState<FocusedAgendaItem | null>(null);

    const dialogRef = useRef<DialogImperativeRef>(null);

    const scrollContainerRef = useRef<ScrollContainerRef>({});

    const workspaceAccess = useWorkspaceAccess();
    const canShareAgenda = workspaceAccess.canShareAgenda(agendaOwnerId);
    const canDeleteAgenda = workspaceAccess.canDeleteAgenda(agendaOwnerId);

    const {saveGeneratedAgenda, handleOnError} = useAiGeneratedAgenda();

    // we delay setting this status to account for saving
    const onFocusItem = () => setTimeout(() => setStatus("saved"), 500);

    const onToggle = useCallback(() => {
        setExcludedItems(categMap.filter((obj) => obj.categ === ActionCategory.Agenda).map((obj) => obj.id));
        setPaletteActiveCateg({
            ...paletteActiveCategDefault,
            isForced: true,
        });
        setTimeout(() => {
            togglePalette();
        }, 0);
        // dialogRef.current?.toggle?.();
    }, []);

    const beforeUpdateMultiple = useCallback(() => {
        setUpdateMultiple(true);
    }, []);

    const lockAgendaItem = useCallback(
        async (id: string) => {
            if (!isCollaborativeRef.current) {
                return false;
            }

            const didLock = designer.api.agendaItem.lock({
                id,
                locked: user.id,
            });

            // TODO: @maca double check this for all cases
            if (didLock === "true") {
                await commitUpdateAgendaTemplate();
                return true;
            }
            return false;
        },
        [user.id]
    );

    const unlockAgendaItem = useCallback(
        async (id?: string) => {
            const toUnlock = id ? [{id}] : agendaItems.filter((a) => a.locked === user.id && !selectedRef.current.includes(a.id));
            if (!toUnlock.length) {
                return false;
            }

            toUnlock.forEach((a) => {
                designer.api.agendaItem.lock({
                    id: a.id,
                    locked: null,
                });
            });
            await commitUpdateAgendaTemplate();
            setStatus("saved");
            return true;
        },
        [agendaItems, user.id]
    );

    const unlockAgendaItemRef = useRef(unlockAgendaItem);
    unlockAgendaItemRef.current = unlockAgendaItem;

    const onOpenConfigDialog = useCallback(
        async (agendaItemId?: string) => {
            if (agendaItemId) {
                await lockAgendaItem(agendaItemId);
            }

            setEditState({
                dirty: false,
            });

            onToggle();
        },
        [lockAgendaItem, onToggle, setEditState]
    );

    const onSelect = useCallback(
        (id: string) => {
            setStatus("saved");
            setSelected((prev) => {
                if (prev.includes(id)) {
                    unlockAgendaItem(id);
                    return prev.filter((i) => i !== id);
                } else {
                    lockAgendaItem(id);
                    return [...prev, id];
                }
            });
        },
        [lockAgendaItem, unlockAgendaItem]
    );

    const commitAfterUpdate = useCallback(async () => {
        await commitUpdateAgendaTemplate();
        setStatus("saved");
    }, []);

    useEffect(() => {
        setSelected([]);
    }, [templateId]);

    const onCloseToolsDialog = useCallback(() => {
        dialogRef.current?.toggle?.();

        setEditState({
            selectedMicroIdToConfig: null,
            selectedMicroIdToPreview: null,
            selectedAgendaItemId: null,
            dirty: true,
        });
    }, [setEditState]);

    useClientEvent("finished-config-process", async (agendaItemId) => {
        if (agendaItemId) {
            setEditState({
                selectedMicroIdToConfig: null,
                selectedMicroIdToPreview: null,
                selectedAgendaItemId: null,
                dirty: true,
            });

            const unlocked = await unlockAgendaItem();
            if (!unlocked) {
                commitAfterUpdate();
            }
        }
    });

    // this is needed to unlock the agenda item in case user opens and closes the palette without doing any changes
    useClientEvent("palette-open", async (isOpen) => {
        if (!isOpen) {
            if (designer.state.getSnapshot().configInProgress) {
                return;
            }
            setEditState({
                selectedMicroIdToConfig: null,
                selectedMicroIdToPreview: null,
                selectedAgendaItemId: null,
                dirty: true,
            });

            const unlocked = await unlockAgendaItem();
            if (!unlocked) {
                commitAfterUpdate();
            }
        }
    });

    const toggleDeleteAgendaItems = useCallback(() => {
        setOpenDeleteSelected((prev) => !prev);
    }, []);

    const handleDeleteAgendaItems = useCallback(() => {
        selected.forEach((id) => {
            designer.api.agendaItem.remove({id});
        });
        setSelected([]);
        toggleDeleteAgendaItems();
        commitAfterUpdate?.();
        setStatus("saved");
    }, [selected, toggleDeleteAgendaItems, commitAfterUpdate]);

    const commitBeforeUnload = useCallback(
        async (unmounting?: boolean) => {
            if (readOnlyRef.current) {
                return;
            }
            setSelected([]);
            selectedRef.current = [];

            unlockAgendaItem();
            await commitUpdateAgendaTemplate(undefined, undefined, unmounting);
        },
        [unlockAgendaItem]
    );

    const commitBeforeUnloadRef = useRef(commitBeforeUnload);
    commitBeforeUnloadRef.current = commitBeforeUnload;

    useEffect(() => {
        return () => {
            commitBeforeUnloadRef.current(true);
            const sessionIdInDesigner = designer.currentSession()?.id;
            if (sessionIdInDesigner) {
                evictLocalQueries(sessionIdInDesigner, user.activeWorkspace?.id);
            }
        };
    }, []);

    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            commitBeforeUnloadRef.current(true);
        };

        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, []);

    const openPreview = useCallback(
        (step: ChooseToolStep, agendaItemId: string, artifactId?: string) => {
            setEditState({
                toolStep: ChooseToolStep.Preview,
                selectedAgendaItemId: agendaItemId,
                selectedMacroIdToPreview: artifactId,
            });
            dialogRef.current?.toggle?.();
        },
        [setEditState]
    );

    const totalAgendaDuration = useMemo(
        () => agendaItems.reduce((acc, item) => (acc += item.duration ? item.duration : 0), 0),
        [agendaItems]
    );

    const timeItems = secondsToTime(totalAgendaDuration, true, true, true, false);

    const sortedAgendaItems = useMemo(() => agendaItems?.slice().sort((a, b) => a.order - b.order), [agendaItems]);
    const selectedWithTool = useMemo(() => {
        let withTool = 0;
        selected.forEach((id) => {
            const agendaItem = sortedAgendaItems.find((a) => a.id === id);
            if (agendaItem?.artifact?.artifactId !== "flowos/conference") {
                withTool++;
            }
        });
        return withTool;
    }, [sortedAgendaItems, selected]);

    const onFirstAccess = async () => {
        await getTemplate(templateId, true);
    };

    const onNewAgendaItemGenerated = async (agendaItem: AIGeneratedAgendaItem) => {
        saveGeneratedAgenda({...agendaItem, id: makeId()});
    };

    const onAgendaGenerationError = (error: string) => {
        console.log("ERROR on AI Generated Agenda from AGENDA TEMPLATES", error);
        handleOnError("There was a problem on generating the agenda. Please try again later.");
    };

    const handleOpenPreview = useCallback(() => {
        setPreviewOpen(true);
    }, []);

    const handleClosePreview = useCallback(() => {
        setPreviewOpen(false);
    }, []);

    return (
        <>
            <div className={cls("flex flex-items-center", classes.header)}>
                <div className="flex11-auto mr-12 overflow-hidden">
                    {currentDesignerTemplate?.id ? (
                        <AgendaTemplatesEditorName
                            onBlur={commitAfterUpdate}
                            isReadOnly={!!isReadOnly}
                            value={currentDesignerTemplate?.name}
                        />
                    ) : null}
                    <div className="flex flex-items-center">
                        <div className={classes.status}>
                            {status === "updatedAt" ? <>Updated on {format(new Date(updatedAt ?? Date.now()), "MMM dd, h:mm a")}</> : null}
                            {status === "saved" ? <>Changes are automatically saved</> : null}
                        </div>

                        {totalAgendaDuration > 0 && <div className={classes.bulletSeparator}>&#8226;</div>}
                        {totalAgendaDuration > 0 && (
                            <div className={cls("flex flex-items-center ", classes.totalTimeContainer)}>
                                <Typography fontWeight="bolder" color="secondary" variant="base" component="div">
                                    {totalAgendaDuration >= 60 * 60 ? (
                                        <>
                                            {timeItems.hours}&nbsp;
                                            {timeItems.minutes}
                                        </>
                                    ) : (
                                        `${totalAgendaDuration / 60} minutes`
                                    )}
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.headerButtonsContainer}>
                    {/* show access list to people who can edit the template */}
                    {/* show give access button only to owner */}
                    {(canShareAgenda || !isReadOnly) && (
                        <AccessList onFirstAccess={onFirstAccess} agendatemplateId={templateId} canGiveAccess={canShareAgenda} />
                    )}
                    <div className={classes.verticalSeparator} />
                    <AgendaGeneratorContainer
                        sessionId={currentDesignerTemplate?.id}
                        onAgendaGenerationError={onAgendaGenerationError}
                        onAgendaItemGenerated={onNewAgendaItemGenerated}
                        isSingleAgendaItem={false}
                    />

                    {/* <IconButton className={classes.previewAgendaButton} onClick={handleOpenPreview}>
                        <VisibilityRoundedIcon />
                    </IconButton> */}

                    {/* <DesignerSessionPreview
                        onClose={handleClosePreview}
                        currentTemplateId={templateId}
                        open={previewOpen}
                        session={currentDesignerTemplate}
                        setEditState={setEditState}
                        mobileState={{
                            isMobile: false,
                        }}
                    /> */}

                    <AgendaTemplatesEditorActions
                        canDeleteAgenda={canDeleteAgenda}
                        currentTemplateId={currentDesignerTemplate.id}
                        currentTemplateName={currentDesignerTemplate.name}
                    />
                </div>
            </div>

            {isReadOnly ? null : (
                <AgendaTemplatesEditorSubHeader
                    createNewAgendaItem={createNewAgendaItem}
                    selected={selected}
                    isReadOnly={!!isReadOnly}
                    setEditState={setEditState}
                    commit={commitAfterUpdate}
                    unlockAgendaItem={unlockAgendaItem}
                    toggleDeleteAgendaItems={toggleDeleteAgendaItems}
                    beforeUpdateMultiple={beforeUpdateMultiple}
                    onOpenConfigDialog={onOpenConfigDialog}
                    mobileState={mobileState}
                    setSelected={setSelected}
                    selectedWithTool={selectedWithTool}
                />
            )}
            {/* @ts-expect-error dnd provider types missmatch */}
            <DndProvider manager={manager} key="dnd-provider-agenda">
                <div
                    key={templateId}
                    id="agendaTemplatesEditorScrollContainer"
                    className={cls("mozilla-scrollbar", classes.agendaBodyContainer)}
                >
                    {sortedAgendaItems.map((item, index) => (
                        <AgendaItemEditor
                            commitAfterUpdate={commitAfterUpdate}
                            isAgendaShared={isPublic || false}
                            scrollContainerRef={scrollContainerRef}
                            onSelect={onSelect}
                            userId={user.id}
                            unlockAgendaItemRef={unlockAgendaItemRef}
                            lockAgendaItem={lockAgendaItem}
                            openPreview={openPreview}
                            agendaItemsLength={agendaItems.length}
                            openConfigDialog={onOpenConfigDialog}
                            readOnly={!!isReadOnly}
                            isSelected={selected.includes(item.id)}
                            key={item.id + index}
                            agendaItem={item}
                            mobileState={props.mobileState}
                            sessionId={currentDesignerTemplate?.id}
                            onFocus={onFocusItem}
                            focusedAgendaItem={focusedAgendaItem}
                            setFocusedAgendaItem={setFocusedAgendaItem}
                        />
                    ))}
                </div>

                <AgendaItemDragPreview />
            </DndProvider>

            {!isReadOnly ? <AgendaItemCreateButton createNewAgendaItem={createNewAgendaItem} /> : null}
            <Dialog
                PaperProps={{
                    className: classes.paper,
                }}
                imperativeRef={dialogRef}
                onClose={onCloseToolsDialog}
                disableBackdropClick
                data-open={(!!dialogRef.current?.isOpen).toString()}
                showCloseBtn={true}
            >
                {currentDesignerTemplate?.id ? (
                    <DesignerToolPlaceholder
                        className={cls("flex flex-col restricted-padding flex flex-col flex11-auto")}
                        currentSessionDetails={currentDesignerTemplate}
                        inDialog={true}
                        onClose={onCloseToolsDialog}
                        readOnly={true}
                        mobileState={mobileState}
                        useConfigForBorPreview
                    />
                ) : null}
            </Dialog>

            {!isReadOnly ? (
                <>
                    <ConfirmationDialog
                        key="templates-delete-agenda-items-dialog"
                        title={`Delete agenda item${selected.length > 1 ? "s" : ""}?`}
                        content="Your agenda will be adjusted accordingly."
                        cancelButton={"Cancel"}
                        minWidth
                        submitButton="Delete"
                        onClose={toggleDeleteAgendaItems}
                        open={openDeleteSelected}
                        submitButtonVariant="destructive-secondary"
                        onConfirm={handleDeleteAgendaItems}
                        variant="warning"
                        isDeleteConfirmation
                    />
                </>
            ) : null}
        </>
    );
};

const TemplateEditor = QueryRenderer({
    component: AgendaTemplatesEditorContainer,

    queryDoc: "GetSessionDocument",
    options: (props) => {
        return {
            variables: {
                id: props.templateId + "_template",
            },
        };
    },

    // @ts-ignore
    isTemplateSessionLoader: true,
});

export default TemplateEditor;
