import {LocalUserDocument, SendMarketingEventDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {AuthService, UserInfo} from "@workhorse/api/authService";
import {useEffect, useState} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {WithChildren} from "@workhorse/declarations";
import RemoteUserChecker from "../RemoteUserChecker";
import {DateTime} from "luxon";

export type UserJWTStructure = {
    permissions: string[] | undefined;
    firstName: string;
    lastName: string;
    email: string;
    sub: string;
    exp: number;
    iat: number;
};

const RemoteUserProvider = (props: WithChildren) => {
    const [done, setDone] = useState(false);
    // const [trackEvent] = useTrackingEvents();

    const onLoginSuccess = (user: UserInfo) => {
        const userLoginCheck = localStorage.getItem("user_login_check");
        if (userLoginCheck) {
            const lastDate = DateTime.fromMillis(parseInt(userLoginCheck));
            const diff = DateTime.now().diff(lastDate, "hours").as("hours");
            if (diff < 24) {
                return;
            }

            localStorage.setItem("user_login_check", DateTime.now().toMillis().toString());
        } else {
            localStorage.setItem("user_login_check", DateTime.now().toMillis().toString());
        }

        const date = new Date();
        date.setUTCHours(0, 0, 0, 0);

        apollo.client
            .mutate({
                mutation: SendMarketingEventDocument,
                variables: {
                    email: user.email,
                    eventName: "lastlogindate",
                    eventBody: date.getTime().toString(),
                    sendOnce: false,
                },
            })
            .then(() => {
                const csdReturn = localStorage.getItem("after_login_csd_return");
                if (csdReturn) {
                    localStorage.removeItem("after_login_csd_return");
                    window.location.replace(csdReturn);
                }
            })
            .catch((err) => {
                console.error("cannot update user after sending marketing event");
            });

        // trackEvent("login_successful", {event_category: TrackingEventCategory.LoginActions});
    };

    const authService = AuthService.getInstance();

    useEffect(() => {
        const processUserToken = (user: UserInfo | null) => {
            // console.log("processing user token", user);
            if (user != null) {
                const userRole = user.permissions.find((x) => x.toUpperCase() === "USER" || x.toUpperCase() === "GUEST");

                apollo.cache.writeQuery({
                    query: LocalUserDocument,
                    data: {
                        isAuthenticated: true,
                        localUser: {
                            __typename: "LocalUser",
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            role: (userRole ?? "GUEST").toUpperCase(),
                            language: "en-GB", // English by default
                        },
                    },
                });

                if (userRole?.toUpperCase() === "USER") {
                    onLoginSuccess(user);
                }

                setDone(true);
            } else {
                apollo.cache.writeQuery({
                    query: LocalUserDocument,
                    data: {
                        isAuthenticated: false,
                        localUser: null,
                    },
                });
                setDone(true);
            }
        };

        authService.getUser(processUserToken);
    }, [authService]);

    return done ? <RemoteUserChecker>{props.children}</RemoteUserChecker> : <Loading location="RemoteUserProvider.tsx" />;
};

export default RemoteUserProvider;
