import {AgendaItemType, ConfigurationStep, DrawerState} from "@generated/data";
import environment from "@generated/environment";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {readMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import {setLeftDrawerState} from "@workhorse/providers/state";
import {createProperty, createSimpleDescription, getValidWebsiteUrl} from "./session-onboarding-agenda";
import {setIframeResource, setPresentationResource, setSmartBoardResource, setYoutubeResource} from "./session-onboarding-resources";

const createConferenceTool = (title: string, description: string) => {
    designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Planned,
        title: title,
        descriptionJson: createSimpleDescription(description),
        duration: 300,
    });
};

const createSmartBoardTool = async (userId: string) => {
    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Try out a whiteboard",
        descriptionJson: createSimpleDescription("Feel free to doodle :)"),
        duration: 300,
    });

    const artifactId = makeId();
    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/blackboard",
            isConfigured: true,
        },
    });

    await setSmartBoardResource(userId, artifactId, "Whiteboard");
};

const createIframeTool = async (userId: string) => {
    const validWebsite = await getValidWebsiteUrl("https://www.wikipedia.org/");

    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Embed ANYTHING",
        descriptionJson: createSimpleDescription("Interact with what’s on screen."),
        duration: 300,
    });

    const artifactId = makeId();
    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/iframe",
            isConfigured: true,
            properties: [
                createProperty("isScrollable", "true"),
                createProperty("enableParticipantControls", "true"),
                createProperty("doReload", new Date().toISOString()),
            ],
        },
    });

    await setIframeResource(userId, artifactId, "Wikipedia", validWebsite);
};

export async function createPresentationTool(userId: string) {
    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Who is SESSIONS?",
        descriptionJson: createSimpleDescription("Find out more about us and our mission."),
        duration: 300,
    });

    const artifactId = makeId();

    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/ppt",
            isConfigured: true,
            properties: [createProperty("currentSlide", "0")],
        },
    });

    const presentationName = "Presentation";
    const presentationUrl = `${environment.staticServiceUrl}/assets/onboarding/strike1/presentation/share_with_sessions.pptx`;

    await setPresentationResource(userId, artifactId, presentationName, presentationUrl);
}

const createYoutubeTool = async (userId: string) => {
    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Next-level interactivity",
        descriptionJson: createSimpleDescription("SESSIONS was designed to grab people’s attention."),
        duration: 300,
    });

    const artifactId = makeId();
    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/youtube",
            isConfigured: true,
        },
    });

    const url = "https://youtu.be/ATQyaeV4Y70?si=wAhgmc7XBCOzLcop";

    await setYoutubeResource(userId, artifactId, "YouTube", url);
};

export const createLonelinessOnboardingAgenda = async (userId: string) => {
    designer.undoChanges({
        from: ["agendaItems", "macroArtifacts"],
    });

    const macroArtifacts = readMacroArtifacts()?.macroArtifacts;

    const hasAgenda = macroArtifacts?.findIndex((a) => a.artifactId === "flowos/agenda") !== -1;

    designer.api.session.detachAgenda({
        type: AgendaItemType.Instant,
    });

    setLeftDrawerState(DrawerState.FullyOpen);

    if (hasAgenda) {
        return;
    }

    designer.api.artifact.addMacroArtifact({
        artifactName: "Agenda",
        artifactTag: "flowos/agenda",
        overrides: {
            isConfigured: true,
        },
    });

    await createConferenceTool("Welcome!", "It’s great to have you here.");
    await createSmartBoardTool(userId);
    await createConferenceTool("Let’s get interactive!", "Write something in Chat, Questions, or Notes.");
    await createPresentationTool(userId);
    await createYoutubeTool(userId);
    await createIframeTool(userId);

    designer.state.setDesignerEditState(false);
    designer.state.update({
        configurationStep: ConfigurationStep.Preview,
    });

    designer.commit();
};
