import CloseIcon from "@material-ui/icons/Close";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import MoreVert from "@material-ui/icons/MoreVert";
import Badge from "@ui/cdk/Badge";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import Fade from "@ui/core/components/Fade";
import IconButton from "@ui/core/components/IconButton";
import Paper from "@ui/core/components/Paper";
import React, {useRef, useState} from "@workhorse/api/rendering";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import BlankProductTool from "../../../../assets/media/icon-my-product-no-bg.svg";
import {ReactComponent as IconToolsDisabled} from "../../../../assets/media/icon-tools-disabled.svg";
import classes from "./style/ToolComponentListItem.module.scss";
import {ToolComponentProps} from "./ToolComponent";
import ToolComponentForm from "./ToolComponentForm";
import ToolComponentPreviewDialog from "./ToolComponentPreviewDialog";

import common from "../../styles/UserSettingsCommons.module.scss";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type ToolComponentListItemProps = ToolComponentProps & {
    canEditMyProduct?: boolean;
};

const ToolComponentListItem = (props: ToolComponentListItemProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const {mixpanelTrack} = useMixpanelTracker();

    const previewDialogImperativeRef: React.MutableRefObject<DialogImperativeRef> = useRef({});
    const editDialogImperativeRef: React.MutableRefObject<DialogImperativeRef> = useRef({});

    const handleOpenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setIsDeleteDialogOpen(true);
        handleCloseMenu();
    };

    const handleCloseDeleteConfirmation = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleOpenPreviewDialog = () => {
        if (previewDialogImperativeRef?.current?.toggle) {
            previewDialogImperativeRef.current.toggle();
        }
        mixpanelTrack("frontend-preview-my-product-tool", "workspace");
    };

    const handleClosePreviewDialog = () => {
        if (previewDialogImperativeRef?.current?.toggle) {
            previewDialogImperativeRef.current.toggle();
        }
    };

    const handleOpenEditDialog = () => {
        if (editDialogImperativeRef?.current?.toggle) {
            editDialogImperativeRef.current.toggle();
        }
        handleCloseMenu();
    };

    const handleCloseEditDialog = () => {
        if (editDialogImperativeRef?.current?.toggle) {
            editDialogImperativeRef.current.toggle();
        }
    };

    const handleDelete = () => {
        props.onDeleteProductTool(props.toolData.id);
        handleCloseMenu();
    };

    const handleToggleToolEnabled = () => {
        props.onUpsert({...props.toolData, enabled: !props.toolData.enabled}, props.toolData.id);
    };

    return (
        <>
            <div className={cls(common.card, "fullw flex flex-align-center flex-justify-between")}>
                <div className={cls(common.row, common.large)}>
                    <div className="flex flex-col">
                        <div className="flex flex-align-center mb-8">
                            <div className={classes.logo}>
                                <img src={props.toolData?.iconUrl || BlankProductTool} alt="tool logo" />
                            </div>
                            <Typography variant="lg" fontWeight="bolder">
                                {props.toolData.title}
                            </Typography>
                            <Badge className={cls(classes.badge, props.toolData.enabled && classes.enabled)} variant="outlined" color="red">
                                {props.toolData.enabled ? "Active" : "Disabled"}
                            </Badge>
                        </div>
                        {props.toolData.description && <Typography color="tertiary">{props.toolData.description}</Typography>}
                    </div>
                    <div className="flex flex-align-center flex-row-reverse">
                        {props.canEditMyProduct && (
                            <IconButton
                                onClick={handleOpenMenu}
                                className={cls("ml-16", classes.moreBtn, anchorEl && classes.moreBtnActive)}
                                data-id="more-actions"
                            >
                                <MoreVert />
                            </IconButton>
                        )}
                        <Button variant="quaternary" onClick={handleOpenPreviewDialog} size="small">
                            Preview product
                        </Button>
                    </div>
                </div>
            </div>

            <Menu
                id="fade-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
                classes={{paper: classes.moreMenuPaper}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                getContentAnchorEl={null}
            >
                <MenuItem data-id="edit-product" onClick={handleOpenEditDialog}>
                    <CreateRoundedIcon />
                    Edit product
                </MenuItem>
                <MenuItem data-id="disable-product" onClick={handleToggleToolEnabled}>
                    <IconToolsDisabled />
                    {`${props.toolData.enabled ? "Disable" : "Enable"}`} product
                </MenuItem>
                <MenuItem onClick={handleOpenDeleteConfirmation} data-id="delete-product" variant="destructive">
                    <DeleteOutlineRoundedIcon />
                    Delete product
                </MenuItem>
            </Menu>

            <Dialog
                imperativeRef={editDialogImperativeRef}
                key="edit-product-dialog"
                disableBackdropClick={true}
                onEscapeKeyDown={handleCloseEditDialog}
            >
                <DialogTitle title="Edit product" className="px-30 py-20">
                    <div className="flex flex-col">
                        <Typography variant="lg" fontWeight="bold">
                            My Product tool
                        </Typography>
                        <Typography color="tertiary">
                            Bring your digital product directly into your sessions – it’s great for demos and training.
                        </Typography>
                    </div>
                    <IconButton onClick={handleCloseEditDialog} className={classes.closeBtn}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <ToolComponentForm {...props} onConfirm={handleCloseEditDialog} />
            </Dialog>

            <ToolComponentPreviewDialog {...props} imperativeRef={previewDialogImperativeRef} onClose={handleClosePreviewDialog} />

            <ConfirmationDialog
                key="delete-product-confirmation-dialog"
                open={isDeleteDialogOpen}
                isDeleteConfirmation={true}
                title={`Delete product?`}
                content={<>{`Are you sure you want to delete this product"?`}</>}
                onClose={handleCloseDeleteConfirmation}
                onConfirm={handleDelete}
                cancelButton={"Cancel"}
                submitButton={"Delete"}
                submitButtonVariant={"destructive-secondary"}
            />
        </>
    );
};

export default ToolComponentListItem;
