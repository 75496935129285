import MicOnIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import {cls} from "@ui/cdk/util/util";
import MenuItem from "@ui/cdk/MenuItem";
import {useDevices, useDeviceManagerEvents, useForceMuteStatus, isNotAllowedError, useSilentMic} from "@workhorse/api/conference2";
import React, {forwardRef, MouseEventHandler, useRef} from "@workhorse/api/rendering";
import LocalAudioMenu from "./components/LocalAudioMenu";
import classes from "./styles/LocalAudioToggler.module.scss";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {WarningRounded as WarningIcon} from "@material-ui/icons";

import {getGlobalKeybindString} from "@workhorse/util/keybinds";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useTranslation} from "react-i18next";

type LocalAudioTogglerProps = {
    className?: string;
    shouldSaveDataToStorage?: boolean;
    permissionDeniedText?: string;
    sessionId: string;
    initialState?: boolean;
    isPreJoin?: boolean;
    onToggle?: () => void;
    showTooltip?: (e?: unknown, message?: string) => void;
    onPermissionBlocked?: (element?: HTMLElement | null) => void;
    onDeviceUnreadable?: (element?: HTMLElement | null) => void;
};

function NoMic({className}: {className?: string}) {
    return <MicOffIcon fontSize="small" className={className} />;
}

function MicEnabled({className}: {className?: string}) {
    return <MicOnIcon fontSize="small" className={className} />;
}

function MicDisabled({className}: {className?: string}) {
    return <MicOffIcon fontSize="small" className={className} />;
}

interface SelectorProps {
    active: boolean;
    forceMute: boolean;
    hasAudioInput: boolean;
    activeClass?: string;
    inactiveClass?: string;
    disabledClass?: string;
}

function IconSelector(props: SelectorProps) {
    if (!props.hasAudioInput) {
        return <NoMic className={props.disabledClass} />;
    }

    if (!props.active) {
        return <MicDisabled className={props.inactiveClass} />;
    }

    return <MicEnabled className={props.activeClass} />;
}

function classSelector(props: SelectorProps) {
    if (!props.hasAudioInput) {
        return props.disabledClass;
    }

    if (!props.active) {
        return props.activeClass;
    }

    return props.inactiveClass;
}

function SilentMicWarningIcon() {
    return <WarningIcon className={classes.silentMicWarningIcon} style={{width: 16, height: 16}} />;
}

const LocalAudioToggler = forwardRef((props: LocalAudioTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {showTooltip, isPreJoin} = props;

    const toggleClicked = useRef(false);

    const {isMobileOrTablet} = useMobile();
    const {audioEnabled, audioMuted, audioError, hasAudioInput, toggleAudio} = useDevices();
    const {muteStatus} = useForceMuteStatus();

    const {shouldShowWarning} = useSilentMic();

    const forceMute = muteStatus === "hard" && !isPreJoin;

    const active = audioEnabled && !audioMuted && !isNotAllowedError(audioError);

    const popoverRef = useRef<HTMLLIElement | null>(null);

    const handleMouseEnter: MouseEventHandler<HTMLLIElement> = (e) => {
        let message = isPreJoin
            ? t(active ? "prejoin.will_join_with_mic_on" : "prejoin.will_join_with_mic_off") ?? ""
            : forceMute
            ? t("prejoin.mic_is_disabled") ?? ""
            : "";

        if (!hasAudioInput) {
            message = t("prejoin.no_mic") ?? "";
        }

        if (!message) {
            return;
        }

        showTooltip?.(e, message);
    };

    const handleMouseLeave = () => {
        showTooltip?.();
    };

    const handleAudioToggle = () => {
        if (!hasAudioInput || forceMute) {
            return;
        }
        toggleClicked.current = true;
        toggleAudio();

        ariaAnnouncerMessageVar(t(active ? "aria_announcer.mic_off" : "aria_announcer.mic_on") ?? "");
    };

    const rootCls = cls(
        props.className,
        classes.root,
        classSelector({
            active,
            forceMute,
            hasAudioInput,
            activeClass: "active-red",
        })
    );

    useDeviceManagerEvents({
        onAudioSuccess() {
            props.onPermissionBlocked?.(null);
            toggleClicked.current = false;
        },
        onAudioNotAllowedError() {
            if (toggleClicked.current) {
                props.onPermissionBlocked?.(popoverRef.current);
                showTooltip?.();
            }
            toggleClicked.current = false;
        },
        onAudioUnreadableError() {
            if (toggleClicked.current) {
                props.onDeviceUnreadable?.(popoverRef.current);
                showTooltip?.();
            }
            toggleClicked.current = false;
        },
    });

    const keybind = getGlobalKeybindString("toggleAudio");

    return (
        <LocalAudioMenu disabled={isPreJoin || isMobileOrTablet || forceMute} onAudioToggle={handleAudioToggle}>
            <MenuItem
                ref={popoverRef}
                classes={{
                    root: "local-audio-toggler",
                }}
                data-id="mute-unmute"
                button
                className={rootCls}
                onClick={handleAudioToggle}
                disabled={!hasAudioInput || forceMute}
                data-cy-audio-toggle
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                role="button"
                tabIndex={0}
                aria-label={`${t(active ? "participant.mic.turn_off" : "participant.mic.turn_on") ?? ""} ${
                    isMobileOrTablet ? "" : `, (${keybind})`
                }`}
            >
                {shouldShowWarning && <SilentMicWarningIcon />}
                <IconSelector active={active} forceMute={forceMute} hasAudioInput={hasAudioInput} activeClass="active-red" />
            </MenuItem>
        </LocalAudioMenu>
    );
});

export default LocalAudioToggler;
