import {BookingViewProvider} from "@workhorse/api/booking";

import Bookings from "./Bookings";

const BookingsHOC = () => {
    return (
        <BookingViewProvider>
            <Bookings />
        </BookingViewProvider>
    );
};

export default BookingsHOC;
