import {emailRegex} from "./utils";
import * as Yup from "yup";

export const BOOK_EVENT_MAX_NAME = 100;
export const BOOK_EVENT_ADDITIONAL_INFORMATION_MAX_LENGTH = 200;
export const BOOK_EVENT_MAX_GUESTS_COUNT = 5;

//translation keys get sent as error messages, and the translation happens in the component
//consts are imported in the component and used in the t function as additional function params

export const bookEventValidationSchema = Yup.object().shape({
    additionalInformation: Yup.string().trim().max(BOOK_EVENT_ADDITIONAL_INFORMATION_MAX_LENGTH, "g.form.max_length"),
    guests: Yup.array()
        .of(Yup.string().matches(emailRegex, "g.form.needs_to_be_valid_email"))
        .min(0)
        .max(BOOK_EVENT_MAX_GUESTS_COUNT, "g.form.max_no_of_guests"),
});
