import Tooltip, {TooltipProps} from "@ui/cdk/Tooltip";
import React, {useEffect, useState} from "@workhorse/api/rendering";
import {WithClassName} from "@workhorse/declarations";

type TooltipData = {
    content?: TooltipProps["title"];
    anchorEl?: HTMLElement;
};

export type OneTooltipRef = React.MutableRefObject<{
    setData?: (newData: TooltipData) => void;
}>;

type OneTooltipProps = {
    connector: OneTooltipRef;
} & WithClassName;

function OneTooltip(props: OneTooltipProps) {
    const {connector, className} = props;

    const [tooltipData, setTooltipData] = useState<TooltipData>({});

    useEffect(() => {
        connector.current.setData = (newData: TooltipData) =>
            setTooltipData((c) => ({
                anchorEl: newData.anchorEl,
                content: newData.content || c.content,
            }));

        return () => {
            connector.current.setData = undefined;
        };
    }, []);

    return (
        <Tooltip
            title={tooltipData.content || ""}
            PopperProps={{
                anchorEl: tooltipData.anchorEl,
            }}
            placement="top"
            arrow
            key="participant-controls-tooltip"
            open={Boolean(tooltipData.anchorEl)}
            interactive
            classes={{
                popper: className,
            }}
        >
            <span style={{display: "none"}} />
        </Tooltip>
    );
}

export default OneTooltip;
