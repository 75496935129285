import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import classes from "./style/AgendaTemplatesEditorHeaderActions.module.scss";

type AgendaTemplatesEditorHeaderActionsProps = {
    agendaItemsLength: number;

    openPreview: () => void;
    onUseAgenda: () => void;
};

const AgendaTemplatesEditorHeaderActions = (props: AgendaTemplatesEditorHeaderActionsProps) => {
    const {
        agendaItemsLength,

        openPreview,
        onUseAgenda,
    } = props;

    return (
        <div className={cls("flex relative", classes.root)}>
            <Button
                data-id="agendas-button-preview"
                size="small"
                className={classes.previewButton}
                variant="plain"
                onClick={openPreview}
                disabled={!agendaItemsLength}
            >
                <VisibilityIcon className="mr-4" /> Preview agenda
            </Button>
            <Button
                size="small"
                className={classes.useAgendaButton}
                variant="primary"
                onClick={onUseAgenda}
                disabled={!agendaItemsLength}
                data-id="agenda-button-useagenda"
            >
                Start a test session
            </Button>
        </div>
    );
};

export default AgendaTemplatesEditorHeaderActions;
