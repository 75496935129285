import {memo} from "@workhorse/api/rendering";
import ReleaseNotesLoader from "@workhorse/components/releaseNotes/ReleaseNotesLoader";
import ReportProblemLoader from "@workhorse/components/reportProblem/ReportProblemLoader";
import {WithHostType, WithMobileState} from "@workhorse/declarations";

function LazyDialogs(props: WithMobileState & WithHostType & {isGuest: boolean}) {
    const {mobileState, hostType, isGuest} = props;
    return (
        <>
            <ReportProblemLoader key="report-problem-loader" mobileState={mobileState} />
            {isGuest ? null : <ReleaseNotesLoader hostType={hostType} key="release-notes-loader" />}
        </>
    );
}

export default memo(LazyDialogs, () => true);
