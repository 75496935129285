import React, {forwardRef} from "@workhorse/api/rendering";
import MuiAccordionSummary, {AccordionSummaryProps} from "@material-ui/core/AccordionSummary";

function AccordionSummaryWithRef(props: AccordionSummaryProps, ref: any) {
    return <MuiAccordionSummary {...props} ref={ref} />;
}

const AccordionSummary = forwardRef(AccordionSummaryWithRef);

export default AccordionSummary as typeof AccordionSummaryWithRef;
