import {useCallback, useEffect, useMemo, useState} from "@workhorse/api/rendering";
// https://github.com/pubkey/broadcast-channel#methods
import {BroadcastChannel} from "broadcast-channel";

export enum UseLoginPopupMessage {
    Success = "Success",
    Error = "Error",
    NoPageSelected = "NoPageSelected",
    Declined = "Declined",
    Blocked = "Blocked",
}

export type UseLoginPopupProps = {
    eventName: string;
    onLogin?: (email?: string) => void;
    onLoginFailure?: (message?: UseLoginPopupMessage) => void;
};

export const useLoginPopup = (props: UseLoginPopupProps) => {
    const {eventName, onLogin, onLoginFailure} = props;

    // We use this timer, that ticks every second, to identify if the page has closed.
    // We need this hack because there is no other way to detect if the page has closed due to CORS.
    // Apparently also used by google & facebook
    const [timer, setTimer] = useState<ReturnType<typeof setInterval>>();
    const [_, setSuccess] = useState<boolean>();

    const request = useCallback(
        async (url: string) => {
            setTimer(undefined);
            setSuccess(undefined);

            const w = 800,
                h = 800;

            let top = window.screen.height - h;
            top = top > 0 ? top / 2 : 0;

            let left = window.screen.width - w;
            left = left > 0 ? left / 2 : 0;

            const win = window.open(url, "_blank", `top=${top}, left=${left}, height=${h}, width=${w}, modal=yes, alwaysRaised=yes`);

            if (!win) {
                onLoginFailure?.(UseLoginPopupMessage.Blocked);
            }
            if (eventName === "office-365-login") {
                setTimer(() => {
                    const interval = setInterval(() => {
                        if ((!win || win.closed) && !win?.self?.closed) {
                            onLoginFailure?.(UseLoginPopupMessage.Blocked);
                            clearInterval(interval);
                        }
                    }, 1000);

                    return interval;
                });
            }
        },
        [onLoginFailure, eventName]
    );

    useEffect(() => {
        return () => {
            timer && clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        const cb = (message: {status: UseLoginPopupMessage; email?: string}) => {
            console.log("cb called", message);
            if (message.status === UseLoginPopupMessage.Success) {
                setSuccess(true);
                onLogin?.(message.email);
            } else {
                setSuccess(false);
                onLoginFailure?.(message.status as UseLoginPopupMessage);
            }
        };

        const EventBroadcastChannel = new BroadcastChannel(eventName);
        EventBroadcastChannel.onmessage = cb;

        return () => {
            EventBroadcastChannel.close();
        };
    }, [onLogin, onLoginFailure, eventName, timer]);

    return useMemo(() => request, [request]);
};
