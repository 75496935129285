import MoreVertIcon from "@material-ui/icons/MoreVert";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useParticipantActions} from "@workhorse/api/conference2";
import {memo} from "@workhorse/api/rendering";
import classes from "./style/AudienceParticipantActions.module.scss";

type AudienceParticipantActionsProps = {
    participantId: string;
};

const AudienceParticipantActions = (props: AudienceParticipantActionsProps) => {
    const {participantId} = props;

    const {value, open, isOpen} = useParticipantActions();

    const handleOnParticipantActions = (e: React.MouseEvent<HTMLButtonElement>) => {
        open({
            anchorEl: e.currentTarget,
            participantId,
            classes: {
                paper: classes.moreOptionsPaper,
            },
            className: "audience-participant-actions",
            anchorOrigin: {vertical: "bottom", horizontal: "center"},
            transformOrigin: {vertical: "top", horizontal: "center"},
        });
    };

    const isMoreOptionsMenuOpen = participantId === value?.participantId && value.className === "audience-participant-actions" && isOpen;

    return (
        <IconButton
            data-id="audience-participant-more-actions"
            onClick={handleOnParticipantActions}
            className={cls("audience-participant-actions", classes.root, isMoreOptionsMenuOpen && "open")}
        >
            <MoreVertIcon />
        </IconButton>
    );
};

export default memo(AudienceParticipantActions, (pv, cv) => pv.participantId !== cv.participantId);
