import apollo from "@workhorse/api/apollo";
import {makeVar} from "@workhorse/api/data";
import type {JSONContent, EnabledPluggedArtifact} from "@api/editor";
import {SaveTakeawaysDocument} from "@generated/data";
import TakeawaysToggler from "./toggler";

const save = (artifactId: string, updatedProperties: {key: string; value: string}[], editMode: boolean) => {
    apollo.client.mutate({
        mutation: SaveTakeawaysDocument,
        variables: {
            artifactId,
            properties: updatedProperties,
        },
    });
};

const saveTakeaways = (artifactId: string, value: JSONContent, editMode: boolean) => {
    save(artifactId, [{key: "takeawaysNote", value: JSON.stringify(value)}], editMode);
};

export const takeawaysArtifactsStatus = makeVar<EnabledPluggedArtifact[]>([]);

export default {
    TakeawaysToggler,
    takeawaysArtifactsStatus,
    saveTakeaways,
};
