import {CustomResolvers} from "./types";
import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import * as GeneratedResolvers from "./generatedResolvers";

const allResolvers = fieldResolvers as CustomResolvers;

(Object.keys(GeneratedResolvers) as Array<keyof typeof GeneratedResolvers>).forEach((k) => {
    allResolvers[k] = {...(allResolvers[k] ?? {}), ...GeneratedResolvers[k]};
});

// FIXME: rename event to sessionEvent everywhere
allResolvers.Event = {...(allResolvers.Event ?? {}), ...GeneratedResolvers.SessionEvent};

export default allResolvers;
