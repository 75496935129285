import TableBody from "@ui/cdk/Table/TableBody";
import TableCell from "@ui/cdk/Table/TableCell";
import {TableCellSize} from "@ui/cdk/Table/TableCell/types";
import TableHead from "@ui/cdk/Table/TableHead";
import TableHeadSortButton from "@ui/cdk/Table/TableHeadSortButton";
import TableRow from "@ui/cdk/Table/TableRow";
import TableSearchInput from "@ui/cdk/Table/TableSearchInput/TableSearchInput";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import {FuseOptions, useFuseWorker} from "@workhorse/api/fuse";
import React, {useMemo, useState} from "@workhorse/api/rendering";
import {useDebounce} from "use-debounce";
import {EventParticipant} from "./event-people";
import EventPeopleTableNoResults from "./EventPeopleTableNoResults";
import EventPeopleTableRow from "./EventPeopleTableRow";
import classes from "./styles/EventPeopleTable.module.scss";
import {createPeopleComparator, PeopleSortField} from "./utils";

type EventPeopleTableProps = {
    people: any[];
    requireApproval?: boolean;
    viewAnswers?: (personId: string) => void;
    sessionId?: string;
    hasRegistration?: boolean;
    hasPayments?: boolean;
};

const fuseOptions: FuseOptions<EventParticipant> = {
    keys: ["dataWithNullableEmail.firstName", "dataWithNullableEmail.lastName", "dataWithNullableEmail.email"],
    threshold: 0.5,
    shouldSort: true,
};

const peopleTableHeadCells = [
    {id: "name", searchInput: true, size: "large" as TableCellSize},
    {id: "email", label: "Email", size: "unset" as TableCellSize},
    {id: "role", label: "Role"},
    {id: "country", label: "Country"},
    {id: "registrationDate", label: "Registration date"},
];

const sortableHeadCells: PeopleSortField[] = ["name", "email", "role", "registrationDate"];

const EventPeopleTable = (props: EventPeopleTableProps) => {
    const {people, viewAnswers, requireApproval, sessionId, hasRegistration, hasPayments} = props;

    const [sort, setSort] = useState<{
        field: PeopleSortField;
        direction: "asc" | "desc";
    }>({field: "name", direction: "asc"});

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

    const isSearching = searchTerm.length > 0;
    const disabledSearch = people.length === 0;

    const sortedPeople = useMemo(() => people.slice().sort(createPeopleComparator(sort)), [people, sort]);
    const [filteredPeople] = useFuseWorker(sortedPeople, debouncedSearchTerm, fuseOptions);

    return (
        <TableContainer className={classes.tableContainerRoot}>
            <Table className={cls(classes.tableRoot)}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {peopleTableHeadCells.map((cell) => (
                            <TableCell key={cell.id} size={cell.size ?? "unset"}>
                                <div className="flex flex-align-center">
                                    {cell.label && <Typography>{cell.label}</Typography>}
                                    {cell.searchInput && (
                                        <div>
                                            <TableSearchInput
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                                                disabled={disabledSearch}
                                                onClear={() => setSearchTerm("")}
                                                placeholder="Search participants"
                                            />
                                        </div>
                                    )}
                                    {sortableHeadCells.includes(cell.id as PeopleSortField) && (
                                        <TableHeadSortButton
                                            columnId={cell.id}
                                            activeColumnId={sort.field}
                                            direction={sort.direction}
                                            onSort={setSort}
                                            canSort={true}
                                        />
                                    )}
                                </div>
                            </TableCell>
                        ))}

                        {hasRegistration && <TableCell size="unset">Registration form</TableCell>}
                        {requireApproval && <TableCell size="unset">Approval</TableCell>}
                        {hasPayments && <TableCell size="unset">Payments</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {/* no need for an empty state since there is always at least one person in the event */}
                    {isSearching && filteredPeople.length === 0 && (
                        <div className={classes.noResults}>
                            <EventPeopleTableNoResults />
                        </div>
                    )}
                    {filteredPeople.map((person) => (
                        <EventPeopleTableRow
                            participant={person}
                            sessionId={sessionId}
                            requireApproval={requireApproval}
                            key={person.id}
                            hasRegistration={hasRegistration}
                            viewAnswers={viewAnswers}
                            hasPayments={hasPayments}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EventPeopleTable;
