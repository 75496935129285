export const timezones = {
    "Pacific/Niue": "UTC-11:00 Pacific, Niue (Niue Time)",
    "Pacific/Pago_Pago": "UTC-11:00 Pacific, Pago Pago (American Samoa Time)",
    "America/Adak": "UTC-10:00 America, Adak (HAT (Adak))",
    "Pacific/Honolulu": "UTC-10:00 Pacific, Honolulu (HST)",
    "Pacific/Rarotonga": "UTC-10:00 Pacific, Rarotonga (Cook Islands Time)",
    "Pacific/Tahiti": "UTC-10:00 Pacific, Tahiti (Tahiti Time)",
    "America/Anchorage": "UTC-09:00 America, Anchorage (AKT)",
    "America/Juneau": "UTC-09:00 America, Juneau (AKT)",
    "America/Metlakatla": "UTC-09:00 America, Metlakatla (AKT)",
    "America/Nome": "UTC-09:00 America, Nome (AKT)",
    "America/Sitka": "UTC-09:00 America, Sitka (AKT)",
    "America/Yakutat": "UTC-09:00 America, Yakutat (AKT)",
    "Pacific/Gambier": "UTC-09:00 Pacific, Gambier (Gambier Time)",
    "Pacific/Marquesas": "UTC-09:30 Pacific, Marquesas (Marquesas Time)",
    "America/Los_Angeles": "UTC-08:00 America, Los Angeles (PT)",
    "America/Tijuana": "UTC-08:00 America, Tijuana (PT)",
    "America/Vancouver": "UTC-08:00 America, Vancouver (PT)",
    "Pacific/Pitcairn": "UTC-08:00 Pacific, Pitcairn (Pitcairn Islands Time)",
    "America/Boise": "UTC-07:00 America, Boise (MT)",
    "America/Cambridge_Bay": "UTC-07:00 America, Cambridge Bay (MT)",
    "America/Chihuahua": "UTC-07:00 America, Chihuahua (Chihuahua Time)",
    "America/Dawson": "UTC-07:00 America, Dawson (Dawson Time)",
    "America/Dawson_Creek": "UTC-07:00 America, Dawson Creek (MST)",
    "America/Denver": "UTC-07:00 America, Denver (MT)",
    "America/Edmonton": "UTC-07:00 America, Edmonton (MT)",
    "America/Fort_Nelson": "UTC-07:00 America, Fort Nelson (MST)",
    "America/Hermosillo": "UTC-07:00 America, Hermosillo (Hermosillo Time)",
    "America/Inuvik": "UTC-07:00 America, Inuvik (MT)",
    "America/Mazatlan": "UTC-07:00 America, Mazatlan (Mazatlan Time)",
    "America/Ojinaga": "UTC-07:00 America, Ojinaga (MT)",
    "America/Phoenix": "UTC-07:00 America, Phoenix (MST)",
    "America/Whitehorse": "UTC-07:00 America, Whitehorse (Whitehorse Time)",
    "America/Yellowknife": "UTC-07:00 America, Yellowknife (MT)",
    "America/Bahia_Banderas": "UTC-06:00 America, Bahia Banderas (CT)",
    "America/Belize": "UTC-06:00 America, Belize (CST)",
    "America/Chicago": "UTC-06:00 America, Chicago (CT)",
    "America/Costa_Rica": "UTC-06:00 America, Costa Rica (CST)",
    "America/El_Salvador": "UTC-06:00 America, El Salvador (CST)",
    "America/Guatemala": "UTC-06:00 America, Guatemala (CST)",
    "America/Indiana/Knox": "UTC-06:00 America, Indiana, Knox (CT)",
    "America/Indiana/Tell_City": "UTC-06:00 America, Indiana, Tell City (CT)",
    "America/Managua": "UTC-06:00 America, Managua (CST)",
    "America/Matamoros": "UTC-06:00 America, Matamoros (CT)",
    "America/Menominee": "UTC-06:00 America, Menominee (CT)",
    "America/Merida": "UTC-06:00 America, Merida (CT)",
    "America/Mexico_City": "UTC-06:00 America, Mexico City (CT)",
    "America/Monterrey": "UTC-06:00 America, Monterrey (CT)",
    "America/North_Dakota/Beulah": "UTC-06:00 America, North Dakota, Beulah (CT)",
    "America/North_Dakota/Center": "UTC-06:00 America, North Dakota, Center (CT)",
    "America/North_Dakota/New_Salem": "UTC-06:00 America, North Dakota, New Salem (CT)",
    "America/Rainy_River": "UTC-06:00 America, Rainy River (CT)",
    "America/Rankin_Inlet": "UTC-06:00 America, Rankin Inlet (CT)",
    "America/Regina": "UTC-06:00 America, Regina (CST)",
    "America/Resolute": "UTC-06:00 America, Resolute (CT)",
    "America/Swift_Current": "UTC-06:00 America, Swift Current (CST)",
    "America/Tegucigalpa": "UTC-06:00 America, Tegucigalpa (CST)",
    "America/Winnipeg": "UTC-06:00 America, Winnipeg (CT)",
    "Pacific/Easter": "UTC-06:00 Pacific, Easter (Easter Time)",
    "Pacific/Galapagos": "UTC-06:00 Pacific, Galapagos (Galapagos Time)",
    "America/Bogota": "UTC-05:00 America, Bogota (Colombia Time)",
    "America/Cancun": "UTC-05:00 America, Cancun (EST)",
    "America/Detroit": "UTC-05:00 America, Detroit (ET)",
    "America/Eirunepe": "UTC-05:00 America, Eirunepe (Eirunepe Time)",
    "America/Grand_Turk": "UTC-05:00 America, Grand Turk (ET)",
    "America/Guayaquil": "UTC-05:00 America, Guayaquil (Ecuador Time)",
    "America/Havana": "UTC-05:00 America, Havana (Cuba Time)",
    "America/Indiana/Indianapolis": "UTC-05:00 America, Indiana, Indianapolis (ET)",
    "America/Indiana/Marengo": "UTC-05:00 America, Indiana, Marengo (ET)",
    "America/Indiana/Petersburg": "UTC-05:00 America, Indiana, Petersburg (ET)",
    "America/Indiana/Vevay": "UTC-05:00 America, Indiana, Vevay (ET)",
    "America/Indiana/Vincennes": "UTC-05:00 America, Indiana, Vincennes (ET)",
    "America/Indiana/Winamac": "UTC-05:00 America, Indiana, Winamac (ET)",
    "America/Iqaluit": "UTC-05:00 America, Iqaluit (ET)",
    "America/Jamaica": "UTC-05:00 America, Jamaica (EST)",
    "America/Kentucky/Louisville": "UTC-05:00 America, Kentucky, Louisville (ET)",
    "America/Kentucky/Monticello": "UTC-05:00 America, Kentucky, Monticello (ET)",
    "America/Lima": "UTC-05:00 America, Lima (Peru Time)",
    "America/New_York": "UTC-05:00 America, New York (ET)",
    "America/Nipigon": "UTC-05:00 America, Nipigon (ET)",
    "America/Panama": "UTC-05:00 America, Panama (EST)",
    "America/Pangnirtung": "UTC-05:00 America, Pangnirtung (ET)",
    "America/Port-au-Prince": "UTC-05:00 America, Port-au-Prince (ET)",
    "America/Rio_Branco": "UTC-05:00 America, Rio Branco (Rio Branco Time)",
    "America/Thunder_Bay": "UTC-05:00 America, Thunder Bay (ET)",
    "America/Toronto": "UTC-05:00 America, Toronto (ET)",
    "America/Asuncion": "UTC-04:00 America, Asuncion (Paraguay Time)",
    "America/Barbados": "UTC-04:00 America, Barbados (AST)",
    "America/Boa_Vista": "UTC-04:00 America, Boa Vista (Boa Vista Time)",
    "America/Campo_Grande": "UTC-04:00 America, Campo Grande (Campo Grande Time)",
    "America/Caracas": "UTC-04:00 America, Caracas (Venezuela Time)",
    "America/Cuiaba": "UTC-04:00 America, Cuiaba (Cuiaba Time)",
    "America/Glace_Bay": "UTC-04:00 America, Glace Bay (AT)",
    "America/Goose_Bay": "UTC-04:00 America, Goose Bay (AT)",
    "America/Guyana": "UTC-04:00 America, Guyana (Guyana Time)",
    "America/Halifax": "UTC-04:00 America, Halifax (AT)",
    "America/La_Paz": "UTC-04:00 America, La Paz (Bolivia Time)",
    "America/Manaus": "UTC-04:00 America, Manaus (Manaus Time)",
    "America/Martinique": "UTC-04:00 America, Martinique (AST)",
    "America/Moncton": "UTC-04:00 America, Moncton (AT)",
    "America/Porto_Velho": "UTC-04:00 America, Porto Velho (Porto Velho Time)",
    "America/Puerto_Rico": "UTC-04:00 America, Puerto Rico (AST)",
    "America/Santiago": "UTC-04:00 America, Santiago (Chile Time)",
    "America/Santo_Domingo": "UTC-04:00 America, Santo Domingo (AST)",
    "America/Thule": "UTC-04:00 America, Thule (AT)",
    "Atlantic/Bermuda": "UTC-04:00 Atlantic, Bermuda (AT)",
    "America/Araguaina": "UTC-03:00 America, Araguaina (Araguaina Time)",
    "America/Argentina/Buenos_Aires": "UTC-03:00 America, Argentina, Buenos Aires (Buenos Aires Time)",
    "America/Argentina/Catamarca": "UTC-03:00 America, Argentina, Catamarca (Catamarca Time)",
    "America/Argentina/Cordoba": "UTC-03:00 America, Argentina, Cordoba (Cordoba Time)",
    "America/Argentina/Jujuy": "UTC-03:00 America, Argentina, Jujuy (Jujuy Time)",
    "America/Argentina/La_Rioja": "UTC-03:00 America, Argentina, La Rioja (La Rioja Time)",
    "America/Argentina/Mendoza": "UTC-03:00 America, Argentina, Mendoza (Mendoza Time)",
    "America/Argentina/Rio_Gallegos": "UTC-03:00 America, Argentina, Rio Gallegos (Rio Gallegos Time)",
    "America/Argentina/Salta": "UTC-03:00 America, Argentina, Salta (Salta Time)",
    "America/Argentina/San_Juan": "UTC-03:00 America, Argentina, San Juan (San Juan Time)",
    "America/Argentina/San_Luis": "UTC-03:00 America, Argentina, San Luis (San Luis Time)",
    "America/Argentina/Tucuman": "UTC-03:00 America, Argentina, Tucuman (Tucuman Time)",
    "America/Argentina/Ushuaia": "UTC-03:00 America, Argentina, Ushuaia (Ushuaia Time)",
    "America/Bahia": "UTC-03:00 America, Bahia (Bahia Time)",
    "America/Belem": "UTC-03:00 America, Belem (Belem Time)",
    "America/Cayenne": "UTC-03:00 America, Cayenne (French Guiana Time)",
    "America/Fortaleza": "UTC-03:00 America, Fortaleza (Fortaleza Time)",
    "America/Maceio": "UTC-03:00 America, Maceio (Maceio Time)",
    "America/Miquelon": "UTC-03:00 America, Miquelon (St. Pierre & Miquelon Time)",
    "America/Montevideo": "UTC-03:00 America, Montevideo (Uruguay Time)",
    "America/Nuuk": "UTC-03:00 America, Nuuk (Nuuk Time)",
    "America/Paramaribo": "UTC-03:00 America, Paramaribo (Suriname Time)",
    "America/Punta_Arenas": "UTC-03:00 America, Punta Arenas (Punta Arenas Time)",
    "America/Recife": "UTC-03:00 America, Recife (Recife Time)",
    "America/Santarem": "UTC-03:00 America, Santarem (Santarem Time)",
    "America/Sao_Paulo": "UTC-03:00 America, Sao Paulo (Sao Paulo Time)",
    "Antarctica/Palmer": "UTC-03:00 Antarctica, Palmer (Palmer Time)",
    "Antarctica/Rothera": "UTC-03:00 Antarctica, Rothera (Rothera Time)",
    "Atlantic/Stanley": "UTC-03:00 Atlantic, Stanley (Falkland Islands Time)",
    "America/St_Johns": "UTC-03:30 America, St Johns (St. John’s Time)",
    "America/Noronha": "UTC-02:00 America, Noronha (Noronha Time)",
    "Atlantic/South_Georgia": "UTC-02:00 Atlantic, South Georgia (South Georgia & South Sandwich Islands Time)",
    "America/Scoresbysund": "UTC-01:00 America, Scoresbysund (Ittoqqortoormiit Time)",
    "Atlantic/Azores": "UTC-01:00 Atlantic, Azores (Azores Time)",
    "Atlantic/Cape_Verde": "UTC-01:00 Atlantic, Cape Verde (Cape Verde Time)",
    "Africa/Abidjan": "UTC Africa, Abidjan (GMT)",
    "Africa/Bissau": "UTC Africa, Bissau (GMT)",
    "Africa/Monrovia": "UTC Africa, Monrovia (GMT)",
    "Africa/Sao_Tome": "UTC Africa, Sao Tome (GMT)",
    "America/Danmarkshavn": "UTC America, Danmarkshavn (GMT)",
    "Antarctica/Troll": "UTC Antarctica, Troll (Troll Time)",
    "Atlantic/Canary": "UTC Atlantic, Canary (Canary Time)",
    "Atlantic/Faroe": "UTC Atlantic, Faroe (Faroe Islands Time)",
    "Atlantic/Madeira": "UTC Atlantic, Madeira (Madeira Time)",
    "Atlantic/Reykjavik": "UTC Atlantic, Reykjavik (GMT)",
    "Europe/Lisbon": "UTC Europe, Lisbon (Portugal Time)",
    "Europe/London": "UTC Europe, London (United Kingdom Time)",
    "Africa/Algiers": "UTC+01:00 Africa, Algiers (Algeria Time)",
    "Africa/Casablanca": "UTC+01:00 Africa, Casablanca (Morocco Time)",
    "Africa/Ceuta": "UTC+01:00 Africa, Ceuta (Ceuta Time)",
    "Africa/El_Aaiun": "UTC+01:00 Africa, El Aaiun (Western Sahara Time)",
    "Africa/Lagos": "UTC+01:00 Africa, Lagos (Nigeria Time)",
    "Africa/Ndjamena": "UTC+01:00 Africa, Ndjamena (Chad Time)",
    "Africa/Tunis": "UTC+01:00 Africa, Tunis (Tunisia Time)",
    "Europe/Amsterdam": "UTC+01:00 Europe, Amsterdam (Netherlands Time)",
    "Europe/Andorra": "UTC+01:00 Europe, Andorra (Andorra Time)",
    "Europe/Belgrade": "UTC+01:00 Europe, Belgrade (Serbia Time)",
    "Europe/Berlin": "UTC+01:00 Europe, Berlin (Germany Time)",
    "Europe/Brussels": "UTC+01:00 Europe, Brussels (Belgium Time)",
    "Europe/Budapest": "UTC+01:00 Europe, Budapest (Hungary Time)",
    "Europe/Copenhagen": "UTC+01:00 Europe, Copenhagen (Denmark Time)",
    "Europe/Dublin": "UTC+01:00 Europe, Dublin (Ireland Time)",
    "Europe/Gibraltar": "UTC+01:00 Europe, Gibraltar (Gibraltar Time)",
    "Europe/Luxembourg": "UTC+01:00 Europe, Luxembourg (Luxembourg Time)",
    "Europe/Madrid": "UTC+01:00 Europe, Madrid (Spain Time)",
    "Europe/Malta": "UTC+01:00 Europe, Malta (Malta Time)",
    "Europe/Monaco": "UTC+01:00 Europe, Monaco (Monaco Time)",
    "Europe/Oslo": "UTC+01:00 Europe, Oslo (Norway Time)",
    "Europe/Paris": "UTC+01:00 Europe, Paris (France Time)",
    "Europe/Prague": "UTC+01:00 Europe, Prague (Czechia Time)",
    "Europe/Rome": "UTC+01:00 Europe, Rome (Italy Time)",
    "Europe/Stockholm": "UTC+01:00 Europe, Stockholm (Sweden Time)",
    "Europe/Tirane": "UTC+01:00 Europe, Tirane (Albania Time)",
    "Europe/Vienna": "UTC+01:00 Europe, Vienna (Austria Time)",
    "Europe/Warsaw": "UTC+01:00 Europe, Warsaw (Poland Time)",
    "Europe/Zurich": "UTC+01:00 Europe, Zurich (Switzerland Time)",
    "Africa/Cairo": "UTC+02:00 Africa, Cairo (Egypt Time)",
    "Africa/Johannesburg": "UTC+02:00 Africa, Johannesburg (South Africa Time)",
    "Africa/Juba": "UTC+02:00 Africa, Juba (South Sudan Time)",
    "Africa/Khartoum": "UTC+02:00 Africa, Khartoum (Sudan Time)",
    "Africa/Maputo": "UTC+02:00 Africa, Maputo (Mozambique Time)",
    "Africa/Tripoli": "UTC+02:00 Africa, Tripoli (Libya Time)",
    "Africa/Windhoek": "UTC+02:00 Africa, Windhoek (Namibia Time)",
    "Asia/Amman": "UTC+02:00 Asia, Amman (Jordan Time)",
    "Asia/Beirut": "UTC+02:00 Asia, Beirut (Lebanon Time)",
    "Asia/Damascus": "UTC+02:00 Asia, Damascus (Syria Time)",
    "Asia/Famagusta": "UTC+02:00 Asia, Famagusta (Famagusta Time)",
    "Asia/Gaza": "UTC+02:00 Asia, Gaza (Gaza Time)",
    "Asia/Hebron": "UTC+02:00 Asia, Hebron (Hebron Time)",
    "Asia/Jerusalem": "UTC+02:00 Asia, Jerusalem (Israel Time)",
    "Asia/Nicosia": "UTC+02:00 Asia, Nicosia (Nicosia Time)",
    "Europe/Athens": "UTC+02:00 Europe, Athens (Greece Time)",
    "Europe/Bucharest": "UTC+02:00 Europe, Bucharest (Romania Time)",
    "Europe/Chisinau": "UTC+02:00 Europe, Chisinau (Moldova Time)",
    "Europe/Helsinki": "UTC+02:00 Europe, Helsinki (Finland Time)",
    "Europe/Kaliningrad": "UTC+02:00 Europe, Kaliningrad (Kaliningrad Time)",
    "Europe/Kiev": "UTC+02:00 Europe, Kiev (Ukraine Time)",
    "Europe/Riga": "UTC+02:00 Europe, Riga (Latvia Time)",
    "Europe/Sofia": "UTC+02:00 Europe, Sofia (Bulgaria Time)",
    "Europe/Tallinn": "UTC+02:00 Europe, Tallinn (Estonia Time)",
    "Europe/Uzhgorod": "UTC+02:00 Europe, Uzhgorod (Uzhhorod Time)",
    "Europe/Vilnius": "UTC+02:00 Europe, Vilnius (Lithuania Time)",
    "Europe/Zaporozhye": "UTC+02:00 Europe, Zaporozhye (Zaporozhye Time)",
    "Africa/Nairobi": "UTC+03:00 Africa, Nairobi (Kenya Time)",
    "Asia/Baghdad": "UTC+03:00 Asia, Baghdad (Iraq Time)",
    "Asia/Qatar": "UTC+03:00 Asia, Qatar (Qatar Time)",
    "Asia/Riyadh": "UTC+03:00 Asia, Riyadh (Saudi Arabia Time)",
    "Europe/Istanbul": "UTC+03:00 Europe, Istanbul (Turkey Time)",
    "Europe/Kirov": "UTC+03:00 Europe, Kirov (Kirov Time)",
    "Europe/Minsk": "UTC+03:00 Europe, Minsk (Belarus Time)",
    "Europe/Moscow": "UTC+03:00 Europe, Moscow (Moscow Time)",
    "Europe/Simferopol": "UTC+03:00 Europe, Simferopol (Simferopol Time)",
    "Europe/Volgograd": "UTC+03:00 Europe, Volgograd (Volgograd Time)",
    "Asia/Tehran": "UTC+03:30 Asia, Tehran (Iran Time)",
    "Asia/Baku": "UTC+04:00 Asia, Baku (Azerbaijan Time)",
    "Asia/Dubai": "UTC+04:00 Asia, Dubai (United Arab Emirates Time)",
    "Asia/Tbilisi": "UTC+04:00 Asia, Tbilisi (Georgia Time)",
    "Asia/Yerevan": "UTC+04:00 Asia, Yerevan (Armenia Time)",
    "Europe/Astrakhan": "UTC+04:00 Europe, Astrakhan (Astrakhan Time)",
    "Europe/Samara": "UTC+04:00 Europe, Samara (Samara Time)",
    "Europe/Saratov": "UTC+04:00 Europe, Saratov (Saratov Time)",
    "Europe/Ulyanovsk": "UTC+04:00 Europe, Ulyanovsk (Ulyanovsk Time)",
    "Indian/Mahe": "UTC+04:00 Indian, Mahe (Seychelles Time)",
    "Indian/Mauritius": "UTC+04:00 Indian, Mauritius (Mauritius Time)",
    "Indian/Reunion": "UTC+04:00 Indian, Reunion (Réunion Time)",
    "Asia/Kabul": "UTC+04:30 Asia, Kabul (Afghanistan Time)",
    "Antarctica/Mawson": "UTC+05:00 Antarctica, Mawson (Mawson Time)",
    "Asia/Aqtau": "UTC+05:00 Asia, Aqtau (Aqtau Time)",
    "Asia/Aqtobe": "UTC+05:00 Asia, Aqtobe (Aqtobe Time)",
    "Asia/Ashgabat": "UTC+05:00 Asia, Ashgabat (Turkmenistan Time)",
    "Asia/Atyrau": "UTC+05:00 Asia, Atyrau (Atyrau Time)",
    "Asia/Dushanbe": "UTC+05:00 Asia, Dushanbe (Tajikistan Time)",
    "Asia/Karachi": "UTC+05:00 Asia, Karachi (Pakistan Time)",
    "Asia/Oral": "UTC+05:00 Asia, Oral (Oral Time)",
    "Asia/Qyzylorda": "UTC+05:00 Asia, Qyzylorda (Qyzylorda Time)",
    "Asia/Samarkand": "UTC+05:00 Asia, Samarkand (Samarkand Time)",
    "Asia/Tashkent": "UTC+05:00 Asia, Tashkent (Uzbekistan Time)",
    "Asia/Yekaterinburg": "UTC+05:00 Asia, Yekaterinburg (Yekaterinburg Time)",
    "Indian/Kerguelen": "UTC+05:00 Indian, Kerguelen (French Southern Territories Time)",
    "Indian/Maldives": "UTC+05:00 Indian, Maldives (Maldives Time)",
    "Asia/Colombo": "UTC+05:30 Asia, Colombo (Sri Lanka Time)",
    "Asia/Kolkata": "UTC+05:30 Asia, Kolkata (India Time)",
    "Asia/Kathmandu": "UTC+05:45 Asia, Kathmandu (Nepal Time)",
    "Antarctica/Vostok": "UTC+06:00 Antarctica, Vostok (Vostok Time)",
    "Asia/Almaty": "UTC+06:00 Asia, Almaty (Almaty Time)",
    "Asia/Bishkek": "UTC+06:00 Asia, Bishkek (Kyrgyzstan Time)",
    "Asia/Dhaka": "UTC+06:00 Asia, Dhaka (Bangladesh Time)",
    "Asia/Omsk": "UTC+06:00 Asia, Omsk (Omsk Time)",
    "Asia/Qostanay": "UTC+06:00 Asia, Qostanay (Kostanay Time)",
    "Asia/Thimphu": "UTC+06:00 Asia, Thimphu (Bhutan Time)",
    "Asia/Urumqi": "UTC+06:00 Asia, Urumqi (Urumqi Time)",
    "Indian/Chagos": "UTC+06:00 Indian, Chagos (British Indian Ocean Territory Time)",
    "Asia/Yangon": "UTC+06:30 Asia, Yangon (Myanmar (Burma) Time)",
    "Indian/Cocos": "UTC+06:30 Indian, Cocos (Cocos (Keeling) Islands Time)",
    "Antarctica/Davis": "UTC+07:00 Antarctica, Davis (Davis Time)",
    "Asia/Bangkok": "UTC+07:00 Asia, Bangkok (Thailand Time)",
    "Asia/Barnaul": "UTC+07:00 Asia, Barnaul (Barnaul Time)",
    "Asia/Ho_Chi_Minh": "UTC+07:00 Asia, Ho Chi Minh (Vietnam Time)",
    "Asia/Hovd": "UTC+07:00 Asia, Hovd (Hovd Time)",
    "Asia/Jakarta": "UTC+07:00 Asia, Jakarta (Jakarta Time)",
    "Asia/Krasnoyarsk": "UTC+07:00 Asia, Krasnoyarsk (Krasnoyarsk Time)",
    "Asia/Novokuznetsk": "UTC+07:00 Asia, Novokuznetsk (Novokuznetsk Time)",
    "Asia/Novosibirsk": "UTC+07:00 Asia, Novosibirsk (Novosibirsk Time)",
    "Asia/Pontianak": "UTC+07:00 Asia, Pontianak (Pontianak Time)",
    "Asia/Tomsk": "UTC+07:00 Asia, Tomsk (Tomsk Time)",
    "Indian/Christmas": "UTC+07:00 Indian, Christmas (Christmas Island Time)",
    "Asia/Brunei": "UTC+08:00 Asia, Brunei (Brunei Time)",
    "Asia/Choibalsan": "UTC+08:00 Asia, Choibalsan (Choibalsan Time)",
    "Asia/Hong_Kong": "UTC+08:00 Asia, Hong Kong (Hong Kong SAR China Time)",
    "Asia/Irkutsk": "UTC+08:00 Asia, Irkutsk (Irkutsk Time)",
    "Asia/Kuala_Lumpur": "UTC+08:00 Asia, Kuala Lumpur (Malaysia Time)",
    "Asia/Kuching": "UTC+08:00 Asia, Kuching (Kuching Time)",
    "Asia/Macau": "UTC+08:00 Asia, Macau (Macao SAR China Time)",
    "Asia/Makassar": "UTC+08:00 Asia, Makassar (Makassar Time)",
    "Asia/Manila": "UTC+08:00 Asia, Manila (Philippines Time)",
    "Asia/Shanghai": "UTC+08:00 Asia, Shanghai (China Time)",
    "Asia/Singapore": "UTC+08:00 Asia, Singapore (Singapore Time)",
    "Asia/Taipei": "UTC+08:00 Asia, Taipei (Taiwan Time)",
    "Asia/Ulaanbaatar": "UTC+08:00 Asia, Ulaanbaatar (Ulaanbaatar Time)",
    "Australia/Perth": "UTC+08:00 Australia, Perth (Perth Time)",
    "Australia/Eucla": "UTC+08:45 Australia, Eucla (Eucla Time)",
    "Asia/Chita": "UTC+09:00 Asia, Chita (Chita Time)",
    "Asia/Dili": "UTC+09:00 Asia, Dili (Timor-Leste Time)",
    "Asia/Jayapura": "UTC+09:00 Asia, Jayapura (Jayapura Time)",
    "Asia/Khandyga": "UTC+09:00 Asia, Khandyga (Khandyga Time)",
    "Asia/Pyongyang": "UTC+09:00 Asia, Pyongyang (North Korea Time)",
    "Asia/Seoul": "UTC+09:00 Asia, Seoul (South Korea Time)",
    "Asia/Tokyo": "UTC+09:00 Asia, Tokyo (Japan Time)",
    "Asia/Yakutsk": "UTC+09:00 Asia, Yakutsk (Yakutsk Time)",
    "Pacific/Palau": "UTC+09:00 Pacific, Palau (Palau Time)",
    "Australia/Adelaide": "UTC+09:30 Australia, Adelaide (Adelaide Time)",
    "Australia/Broken_Hill": "UTC+09:30 Australia, Broken Hill (Broken Hill Time)",
    "Australia/Darwin": "UTC+09:30 Australia, Darwin (Darwin Time)",
    "Antarctica/Macquarie": "UTC+10:00 Antarctica, Macquarie (Macquarie Time)",
    "Asia/Ust-Nera": "UTC+10:00 Asia, Ust-Nera (Ust-Nera Time)",
    "Asia/Vladivostok": "UTC+10:00 Asia, Vladivostok (Vladivostok Time)",
    "Australia/Brisbane": "UTC+10:00 Australia, Brisbane (Brisbane Time)",
    "Australia/Hobart": "UTC+10:00 Australia, Hobart (Hobart Time)",
    "Australia/Lindeman": "UTC+10:00 Australia, Lindeman (Lindeman Time)",
    "Australia/Melbourne": "UTC+10:00 Australia, Melbourne (Melbourne Time)",
    "Australia/Sydney": "UTC+10:00 Australia, Sydney (Sydney Time)",
    "Pacific/Chuuk": "UTC+10:00 Pacific, Chuuk (Chuuk Time)",
    "Pacific/Guam": "UTC+10:00 Pacific, Guam (Guam Time)",
    "Pacific/Port_Moresby": "UTC+10:00 Pacific, Port Moresby (Port Moresby Time)",
    "Australia/Lord_Howe": "UTC+10:30 Australia, Lord Howe (Lord Howe Time)",
    "Antarctica/Casey": "UTC+11:00 Antarctica, Casey (Casey Time)",
    "Asia/Magadan": "UTC+11:00 Asia, Magadan (Magadan Time)",
    "Asia/Sakhalin": "UTC+11:00 Asia, Sakhalin (Sakhalin Time)",
    "Asia/Srednekolymsk": "UTC+11:00 Asia, Srednekolymsk (Srednekolymsk Time)",
    "Pacific/Bougainville": "UTC+11:00 Pacific, Bougainville (Bougainville Time)",
    "Pacific/Efate": "UTC+11:00 Pacific, Efate (Vanuatu Time)",
    "Pacific/Guadalcanal": "UTC+11:00 Pacific, Guadalcanal (Solomon Islands Time)",
    "Pacific/Kosrae": "UTC+11:00 Pacific, Kosrae (Kosrae Time)",
    "Pacific/Norfolk": "UTC+11:00 Pacific, Norfolk (Norfolk Island Time)",
    "Pacific/Noumea": "UTC+11:00 Pacific, Noumea (New Caledonia Time)",
    "Pacific/Pohnpei": "UTC+11:00 Pacific, Pohnpei (Pohnpei Time)",
    "Asia/Anadyr": "UTC+12:00 Asia, Anadyr (Anadyr Time)",
    "Asia/Kamchatka": "UTC+12:00 Asia, Kamchatka (Kamchatka Time)",
    "Pacific/Auckland": "UTC+12:00 Pacific, Auckland (New Zealand Time)",
    "Pacific/Fiji": "UTC+12:00 Pacific, Fiji (Fiji Time)",
    "Pacific/Funafuti": "UTC+12:00 Pacific, Funafuti (Tuvalu Time)",
    "Pacific/Kwajalein": "UTC+12:00 Pacific, Kwajalein (Kwajalein Time)",
    "Pacific/Majuro": "UTC+12:00 Pacific, Majuro (Marshall Islands Time)",
    "Pacific/Nauru": "UTC+12:00 Pacific, Nauru (Nauru Time)",
    "Pacific/Tarawa": "UTC+12:00 Pacific, Tarawa (Tarawa Time)",
    "Pacific/Wake": "UTC+12:00 Pacific, Wake (Wake Time)",
    "Pacific/Wallis": "UTC+12:00 Pacific, Wallis (Wallis & Futuna Time)",
    "Pacific/Chatham": "UTC+12:45 Pacific, Chatham (Chatham Time)",
    "Pacific/Apia": "UTC+13:00 Pacific, Apia (Samoa Time)",
    "Pacific/Fakaofo": "UTC+13:00 Pacific, Fakaofo (Tokelau Time)",
    "Pacific/Kanton": "UTC+13:00 Pacific, Kanton (Enderbury Time)",
    "Pacific/Tongatapu": "UTC+13:00 Pacific, Tongatapu (Tonga Time)",
    "Pacific/Kiritimati": "UTC+14:00 Pacific, Kiritimati (Kiritimati Time)"
};

export type AcceptedTimezone = keyof typeof timezones;
