import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {rbac} from "@workhorse/api/user";
import Inject from "@workhorse/providers/Injector";
import {useIsLobby, useSession} from "@workhorse/providers/SessionDataProviders";
import {useShallow} from "zustand/react/shallow";
import PlayerHeader from "./PlayerHeader";

export default function PreJoinScreenHeader() {
    const session = useSession();
    const {isLobby} = useIsLobby();
    const myParticipant = useParticipantsStore(
        useShallow(({currentParticipant}) => ({id: currentParticipant?.id ?? "", isOwner: currentParticipant?.isOwner ?? false}))
    );
    const isAdmin = myParticipant.isOwner;
    const isAssistant = rbac(myParticipant.id, "session.isAssistant");

    return (
        <Inject target="inHeader" id="header-pre-join-screen" className="fullw flex-justify-between">
            <PlayerHeader isLobby={isLobby} session={session} isAdmin={isAdmin} isAssistant={isAssistant} isPreJoin={true} />
        </Inject>
    );
}
