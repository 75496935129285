import environment from "@generated/environment";
import {io, Socket} from "socket.io-client";

interface StartReadyEventData {
    ok: boolean;
    url?: string;
    error?: string;
}
interface InitReadyEventData {
    ok: boolean;
    started: boolean;
}
export interface NavigateEventData {
    url: string;
    backEnabled: boolean;
    forwardEnabled: boolean;
}

export class MyProductToolService {
    private containerId: string;
    private socket: Socket;
    private pingTimeout: NodeJS.Timeout;
    private initCallback?: (data: InitReadyEventData) => void;
    private startCallback?: (data: StartReadyEventData) => void;
    private navigateDataCallback?: (data: NavigateEventData) => void;

    public initialized = false;

    constructor(artifactId: string) {
        this.containerId = artifactId;
    }

    private connect() {
        this.socket = io(environment.myProductToolSocketHost, {
            query: {
                containerId: this.containerId,
            },
        });

        this.socket.on("init-ready", (data: InitReadyEventData) => {
            console.log("[MyProductToolService] init-ready", data);
            if (this.initCallback) {
                this.initCallback(data);
            }
        });

        this.socket.on("pong", () => {
            console.log("[MyProductToolService] pong");
        });

        this.socket.on("start-ready", (data: StartReadyEventData) => {
            console.log("[MyProductToolService] start-ready", data);
            if (this.startCallback) {
                this.startCallback(data);
            }
        });

        this.socket.on("navigate", (data: NavigateEventData) => {
            console.log("[MyProductToolService] navigate", data);
            if (this.navigateDataCallback) {
                this.navigateDataCallback(data);
            }
        });
    }

    private ping() {
        console.log("[MyProductToolService] ping");
        this.socket.emit("ping");
    }

    private init() {
        this.initialized = true;

        console.log("[MyProductToolService] init");
        this.socket.emit("init");
        this.pingTimeout = setInterval(() => {
            this.ping();
        }, 20_000);
    }

    async initialize(options: {
        initCallback: (data: InitReadyEventData) => void;
        startCallback: (data: StartReadyEventData) => void;
        navigateDataCallback: (data: NavigateEventData) => void;
    }) {
        this.initCallback = options.initCallback;
        this.startCallback = options.startCallback;
        this.navigateDataCallback = options.navigateDataCallback;

        this.connect();
        this.init();
    }

    public start(toolId: string, userId: string, url: string, timezone: string) {
        console.log("[MyProductToolService] start", {session: `${toolId}-${userId}`, url, timezone});
        this.socket.emit("start", {session: `${toolId}-${userId}`, url, timezone});
    }

    public goBack() {
        console.log("[MyProductToolService] goBack");
        this.socket.emit("go-back");
    }

    public refresh() {
        console.log("[MyProductToolService] refresh");
        this.socket.emit("refresh");
    }

    public goForward() {
        console.log("[MyProductToolService] goForward");
        this.socket.emit("go-forward");
    }

    public getNavigationState() {
        this.socket.emit("get-navigation-state");
    }

    public disconnect() {
        this.initialized = false;
        console.log("[MyProductToolService] disconnect");
        if (this.pingTimeout) {
            clearInterval(this.pingTimeout);
        }
        this.socket.disconnect();
    }
}
