import {SessionIdOfRoomDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useEffect, useMemo} from "@workhorse/api/rendering";
import {useLocation, useParams} from "@workhorse/api/routing";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useQuery, writeQuery} from "@workhorse/dataApi";

export const isEventRoute = (location: string) => {
    const locationPieces = location.split("/").filter((x) => !!x.trim());

    const isEventRoute = (locationPieces[0] || "").toLowerCase() === "event";
    return isEventRoute;
};

export function makeSessionIdFromRoute(location: string) {
    const locationPieces = location.split("/").filter((x) => !!x.trim());
    const isSessionRoute =
        (locationPieces[0] || "").toLowerCase() === "session" ||
        (locationPieces[0] || "").toLowerCase() === "join" ||
        (locationPieces[0] || "").toLowerCase() === "event" ||
        ((locationPieces[0] || "").toLowerCase() === "memory" && (locationPieces[1] || "").toLowerCase() === "session") ||
        (locationPieces[0] || "").toLowerCase() === "session-ended" ||
        (locationPieces[0] || "").toLowerCase() === "feedback";

    const isEventRoute = (locationPieces[0] || "").toLowerCase() === "event";

    const locSessId = isEventRoute
        ? locationPieces[locationPieces.length - 2]
        : locationPieces
              .filter((x) => x !== "session" && !!x)
              .slice(0)
              .pop();

    return isSessionRoute && !!locSessId?.trim() ? locSessId.trim() : null;
}

// This was created because we no longer want makeSessionIdFromRoute to return an id for a template
export function makeTemplateIdFromRoute(location: string) {
    const locationPieces = location.split("/").filter((x) => !!x.trim());
    const isTemplateRoute = (locationPieces[0] || "").toLowerCase() === "templates";

    const locSessId = locationPieces
        .filter((x) => !["shared", "workspace", "my-agendas", "templates"].includes(x) && !!x)
        .slice(0)
        .pop();

    return isTemplateRoute && !!locSessId?.trim() ? locSessId.trim() : null;
}

export function isRoom() {
    const pathname = window.location.pathname;
    return pathname.toLowerCase().startsWith("/room/");
}

export function makeRoomSlugFromRoute(pathname: string) {
    return encodeURI(decodeURI(pathname.split("/room/", 2)[1])).toLowerCase();
}

export async function getSessionIdByRoomSlug(roomSlug: string) {
    const {data} = await apollo.client
        .query({
            query: SessionIdOfRoomDocument,
            variables: {
                roomSlug,
            },
        })
        .catch((e) => {
            console.error("Could not get session id in room", e);
            return {data: null};
        });

    return data?.sessionIdOfRoom?.sessionId ?? null;
}

function useCurrentSessionIdProvider() {
    const location = useLocation();
    const sessIdFromRoute = makeSessionIdFromRoute(location.pathname);
    const isRoom = location.pathname.toLowerCase().startsWith("/room/");
    const roomSlug = isRoom ? encodeURI(location.pathname.split("/room/", 2)?.[1]?.trim?.()).toLowerCase() : undefined;

    const {data} = useQuery("SessionIdOfRoomDocument", {
        variables: {
            roomSlug: roomSlug!,
        },
        skip: !roomSlug,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        if (!roomSlug) {
            apollo.cache.evict({
                fieldName: "sessionIdOfRoom",
                id: "ROOT_QUERY",
            });
            apollo.cache.gc();
        }
    }, [roomSlug, data]);
    const isNonExistentRoom = data?.sessionIdOfRoom?.sessionId === null && data?.sessionIdOfRoom?.active === false;

    const sessionId = isRoom ? (isNonExistentRoom ? "non-existent" : data?.sessionIdOfRoom?.sessionId) : sessIdFromRoute;

    return useMemo(() => sessionId ?? null, [sessionId]);
}

export const [CurrentSessionIdProvider, useSessionIdFromRoute] = createContextProvider(
    {
        name: "CurrentSessionIdProviderProvider",
        strict: false,
    },
    useCurrentSessionIdProvider
);
