import {SessionFlags, SessionLifecycle} from "@generated/data";
import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import MemoryAccessOption from "@workhorse/api/session-settings/sections/AccessSection/components/MemoryAccessOption";
import OwnerFeedbackToggler from "@workhorse/api/session-settings/sections/LeaveSessionSection/OwnerFeedbackToggler";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMemo} from "@workhorse/api/rendering";
import designer from "@workhorse/api/designer";
import commonClasses from "../eventCommons.module.scss";
import classes from "./event-leave-settings.module.scss";
import Typography from "@ui/cdk/Typography";
import ExitPageOfferToggler from "@workhorse/api/session-settings/sections/LeaveSessionSection/ExitPageOfferToggler";

type EventLeaveSettingsProps = {
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
    isRoom?: boolean;
    onChange?: () => void;
};
const EventLeaveSettings = (props: EventLeaveSettingsProps) => {
    const {isRoom, sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed} = props;

    const session = useSession();

    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => (!isRoom ? [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked : false),
        [session.lifecycle, isLocked]
    );

    const handleOnChange = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey]) => {
        if (!isRoom) {
            lockEvent({
                variables: {
                    id: session.id,
                },
            });
        }

        designer.api.session.update({
            [key]: value,
        });

        props.onChange?.();

        if (!isRoom) {
            designer.commit();
        }
    };

    return (
        <div className={commonClasses.mainContainer}>
            <div className="flex flex-align-center flex-justify-between">
                <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-24">
                    After leaving the event
                </Typography>
            </div>

            <OwnerFeedbackToggler
                value={session.collectFeedback}
                onChange={handleOnChange.bind(null, "collectFeedback")}
                showIcon={true}
                disabled={eventNotUpdatable}
                feedbackFormId={session.feedbackFormId}
                onSelectFeedbackForm={handleOnChange.bind(null, "feedbackFormId")}
            />

            <ExitPageOfferToggler
                value={session.showExitPageCta}
                onChange={handleOnChange.bind(null, "showExitPageCta")}
                showIcon={true}
                disabled={eventNotUpdatable}
                exitPageOfferId={session.exitPageOfferId!}
                onSelectOffer={handleOnChange.bind(null, "exitPageOfferId")}
            />

            <SessionTemplateSettingsNotification
                type="event"
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={commonClasses.sessionTemplateNotification}
            />
        </div>
    );
};

export default EventLeaveSettings;
