import {OrganizationPermissionType} from "@generated/data";
import {UserInfo} from "@workhorse/providers/User/UserInfoProvider";
import {AccessUtilities} from "./AccessUtilities";

type OrganizationPermission = UserInfo["organizationPermissions"][0];

export class OrganizationAccess extends AccessUtilities {
    public organizationId: string;
    public role: OrganizationPermissionType;

    constructor(public userId: string, permission: OrganizationPermission) {
        super();
        this.role = permission.permissionType;
        this.organizationId = permission.organization.id;
    }

    hasRole = (...roles: OrganizationPermissionType[]) => {
        return roles.includes(this.role);
    };

    /** Organization */

    canEditOrganization = () => {
        return this.hasRole(OrganizationPermissionType.Owner);
    };

    /** Membership **/

    canEditMembers = () => {
        return this.hasRole(OrganizationPermissionType.Owner);
    };

    /** Workspaces */

    canCreateWorkspaces = () => {
        return this.hasRole(OrganizationPermissionType.Owner);
    };

    /** CNAME */

    canAddCname = () => {
        return this.hasRole(OrganizationPermissionType.Owner);
    };

    /** Pricing Plan **/

    canSeePricingPlan = () => {
        return this.hasRole(OrganizationPermissionType.Owner);
    };
}
