import {OnboardingFeatures} from "@workhorse/api/onboarding";
import {memo, useEffect, useRef} from "@workhorse/api/rendering";
import {useHistory, useParams} from "@workhorse/api/routing";
import {useUserInfo} from "@workhorse/providers/User";
import {useCurrentTemplate} from "../TemplateProviders";
import {TemplatesParams} from "../utils";
import AgendaTemplatesList from "./AgendaTemplatesList";

const AgendaTemplatesListContainer = () => {
    const history = useHistory();
    const params = useParams<TemplatesParams>();

    const {isReadOnly} = useCurrentTemplate();

    const readOnlyRef = useRef(isReadOnly);

    useEffect(() => {
        readOnlyRef.current = isReadOnly;
    }, [isReadOnly]);

    const user = useUserInfo();

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);

    const onClick = async (agendaTemplateId: string) => {
        if (!params.templateId) {
            history.push({
                pathname: params.section + "/" + agendaTemplateId,
            });
        }
    };

    return (
        <>
            <OnboardingFeatures feature="agendaDashboard" />
            <AgendaTemplatesList onClick={onClick} workspaceId={user.activeWorkspace?.id ?? ""} />
            <input ref={hiddenInputRef} className={"hidden-but-displayed"} autoFocus={false}></input>
        </>
    );
};

export default memo(AgendaTemplatesListContainer);
