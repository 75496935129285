import {
    AddContactDocument,
    ConferenceParticipantStatus,
    ContactGroupInfoFragment,
    ContactGroupSource,
    CreateGroupDocument,
    DeleteContactsFromBinDocument,
    DeleteGroupDocument,
    DeleteMicrosoftConnectionDocument,
    DeleteUserGoogleProvidersDocument,
    GoogleSyncedEmailFragment,
    ImportCsvContactsDocument,
    MicrosoftSyncedEmailFragment,
    MoveContactsToBinDocument,
    ParticipantInviteStatus,
    ParticipantStatus,
    RecoverContactsFromBinDocument,
    UpdateContactDocument,
    UpdateGroupDocument,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import extension from "@workhorse/api/extension";
import {createInstantSession, createPlannedSession} from "@workhorse/pages/home/components/utils";
import {CSVContact} from "../components/groups/ImportCsvDialog";
import {ContactsConnections} from "../ContactsEntrypoint";

export const createContact = (firstName: string, lastName: string, email: string, company?: string | null, companyRole?: string | null) => {
    return apollo.client.mutate({
        mutation: AddContactDocument,
        variables: {
            data: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                company: company,
                companyRole: companyRole,
            },
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export const updateContact = (
    contactId?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    company?: string | null,
    companyRole?: string | null,
    favorite?: boolean | null
) => {
    if (!contactId) {
        return Promise.reject();
    }
    return apollo.client.mutate({
        mutation: UpdateContactDocument,
        variables: {
            where: {
                id: contactId,
            },
            data: {
                company: {set: company},
                companyRole: {set: companyRole},
                email: {set: email},
                firstName: {set: firstName},
                lastName: {set: lastName},
                favorite: favorite,
            },
        },
    });
};

export const toggleFavoriteRole = async (contactId: string, email: string, favorite: boolean) => {
    return apollo.client.mutate({
        mutation: UpdateContactDocument,
        variables: {
            where: {
                id: contactId,
            },
            data: {
                email: {set: email},
                favorite: favorite,
            },
        },
    });
};

export const moveContactsToBin = (contactsIds: string[], fromGroupId?: string) => {
    return apollo.client.mutate({
        mutation: MoveContactsToBinDocument,
        variables: {
            contactsIds,
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export const recoverContactsFromBin = (contactsIds: string[]) => {
    return apollo.client.mutate({
        mutation: RecoverContactsFromBinDocument,
        variables: {
            contactsIds,
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export const deleteContactsFromBin = (contactsIds?: string[]) => {
    return apollo.client.mutate({
        mutation: DeleteContactsFromBinDocument,
        variables: {
            contactsIds: contactsIds ?? [],
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export const createGroup = async (name: string, contactIds: string[]) => {
    return apollo.client.mutate({
        mutation: CreateGroupDocument,
        variables: {
            name,
            contactIds,
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export const updateGroup = async (groupId: string, name?: string, addContactIds?: string[], removeContactIds?: string[]) => {
    return apollo.client.mutate({
        mutation: UpdateGroupDocument,
        variables: {
            id: groupId,
            name: name,
            addContactIds: addContactIds ?? [],
            removeContactIds: removeContactIds ?? [],
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export const deleteGroup = async (groupId: string) => {
    return apollo.client.mutate({
        mutation: DeleteGroupDocument,
        variables: {
            groupId,
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

// Session now & session later
const participantDefaults = (email?: string, requestPermission?: boolean, requestPasscode?: boolean) => ({
    __typename: "Participant" as const,
    conferenceStatus: ConferenceParticipantStatus.Participant,
    dataWithNullableEmail: {
        email: email ?? null,
        __typename: "ParticipantDataWithNullableEmail" as const,
        firstName: "",
        isGuest: false,
        token: "",
        lastName: "",
        avatar: "",
        userId: "",
    },
    isApproved: requestPermission ? false : true,
    inviteStatus: ParticipantInviteStatus.NeedsAction,
    isOwner: false,
    mockedParticipant: false,
    muted: false,
    rbac: {},
    status: ParticipantStatus.NotJoined,
    visible: true,
    changedBy: null,
    statusChangedBy: null,
    submittedPasscode: requestPasscode ? false : true,
});

export async function onSessionNow(emails: string[]) {
    const isExtension = extension.isExtensionEnabled();

    const {createdSessionId, autoRecording} = await createInstantSession();

    const participantsToBeAdded = emails.map((email) => ({
        ...participantDefaults(email),
        id: makeId(),
    }));

    if (!createdSessionId) {
        return;
    }

    designer.state.initializeOrResetState(createdSessionId);

    if (isExtension) {
        extension.drawer.close();
    }

    designer.participantsPayload = {
        sessionId: createdSessionId,
        deletedParticipantIds: [],
        upsertParticipantJSONs: participantsToBeAdded,
    };

    return designer
        .commitCreateSession()
        .then(() => {
            designer.state.initializeOrResetState(null);

            //TO DO
            window.open(`/session/${createdSessionId}${autoRecording ? "?recordingStarted=true" : ""}`, "_blank");
        })
        .catch((err) => {
            console.error(err);
        });
}

export async function onSessionLater(emails: string[], setCreatedSessionId: (string) => void) {
    const createdSessionId = await createPlannedSession();

    const participantsToBeAdded = emails.map((email) => ({
        ...participantDefaults(email),
        id: makeId(),
    }));

    if (createdSessionId) {
        designer.participantsPayload = {
            sessionId: createdSessionId,
            deletedParticipantIds: [],
            upsertParticipantJSONs: participantsToBeAdded,
        };

        setCreatedSessionId(createdSessionId);
    }
}

// Contacts connections
export function disconnectGoogleAccount(email: string) {
    return apollo.client.mutate({
        mutation: DeleteUserGoogleProvidersDocument,
        variables: {
            email,
            provider: "google-contacts",
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
}

export function disconnectMicrosoftAccount(email: string) {
    return apollo.client.mutate({
        mutation: DeleteMicrosoftConnectionDocument,
        variables: {
            email,
            provider: "microsoft-contacts",
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
}

export const onImportCsvContacts = (contacts: CSVContact[], groupId: string | null, groupName: string | null) => {
    return apollo.client.mutate({
        mutation: ImportCsvContactsDocument,
        variables: {
            contacts,
            groupId,
        },
        refetchQueries: ["GetContactsAndGroups"],
    });
};

export function hasContactsPermission(sync: GoogleSyncedEmailFragment | MicrosoftSyncedEmailFragment) {
    return (
        // TODO: liviu - ts-nocheck was removed from this file. This breaks. Please fix
        // @ts-ignore
        sync.permissions?.some((permission) => permission.granted === true && permission.permission.toLowerCase() === "contacts") ?? false
    );
}

function getContactsConnectionsEmails(sync: GoogleSyncedEmailFragment | MicrosoftSyncedEmailFragment) {
    return (
        // TODO: liviu - ts-nocheck was removed from this file. This breaks. Please fix
        sync
            // @ts-ignore
            .filter(hasContactsPermission)
            .map((sync) => sync.email)
            .filter((email) => email != null) as string[]
    );
}

export function getConnectionEmails(connections: ContactsConnections): string[] {
    // TODO: liviu - ts-nocheck was removed from this file. This breaks. Please fix
    // @ts-ignore
    const googleEmails = getContactsConnectionsEmails(connections.google);
    // TODO: liviu - ts-nocheck was removed from this file. This breaks. Please fix
    // @ts-ignore
    const msEmails = getContactsConnectionsEmails(connections.microsoft);
    return [...googleEmails, ...msEmails];
}

export function isValidGroup(group: ContactGroupInfoFragment, syncedEmails: string[]) {
    // TODO: liviu - ts-nocheck was removed from this file. This breaks. Please fix
    // @ts-ignore
    const syncSources: ContactGroupSource[] = [ContactGroupSource.Google, ContactGroupSource.Microsoft];
    if (!syncSources.includes(group.source)) {
        return true;
    }
    // @ts-ignore
    return syncedEmails.includes(group.externalSyncedEmail);
}
