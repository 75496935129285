import {Participant, RecurrenceEditType} from "@generated/data";
import env from "@generated/environment";
import loadable from "@loadable/component";
import CalendarToday from "@material-ui/icons/CalendarToday";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";
import Schedule from "@material-ui/icons/Schedule";
import Button from "@ui/cdk/Button";
import {dateFormat} from "@ui/cdk/DateFormat";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Time from "@ui/cdk/Time";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {getPlayerDomainForSession} from "@workhorse/api/csd";
import designer from "@workhorse/api/designer";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";
import ShareSessionLink from "@workhorse/pages/designer/CreateSession/ShareSessionLink";
import {useDesignerSessionCommitState, useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import Inject from "@workhorse/providers/Injector";
import SessionDataProviders, {useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import LobbyCoverImageContainer from "./LobbyCoverImageContainer";
import LobbyOwnerAvatar from "./LobbyOwnerAvatar";
import LobbyStartsInCounter from "./LobbyStartsInCounter";
import LobbyTimeLeftCounter from "./LobbyTimeLeftCounter";
import SendCalendarInviteMenu from "./SendCalendarInviteMenu";
import StartSessionButton from "./StartSessionButton";
import classes from "./styles/Lobby.module.scss";
import lobbyPictureStyle from "./styles/LobbyCoverImage.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {EditorSerializedContent} from "@workhorse/components/editor";
import {useTranslation} from "react-i18next";
import {getDateFnsLocale, localeWeekdayOfMonthDateFnsFormat} from "@sessions/common/utils/languages";
import {format} from "date-fns";
import {useMixpanelTracker} from "@workhorse/api/tracking";

const CreatePlannedSessionDialog = loadable(() => import("@workhorse/components/CreatePlannedSession"));
const LobbyCoverImageMenuContainer = loadable(() => import("./LobbyCoverImageMenuContainer"));

type LobbyProps = WithMobileState & {
    isAdmin: boolean;
    isAssistant: boolean;
    sessionIsPublished: boolean;
};

function Lobby(props: LobbyProps) {
    const {t, i18n} = useTranslation();
    const lang = i18n.language;
    const isMounted = useIsMounted();
    const session = useSession()!;
    const {mixpanelTrack} = useMixpanelTracker();

    const {mobileState, isAdmin, isAssistant, sessionIsPublished} = props;
    const {isMobile} = mobileState;
    const [sessionEditState, setEditState] = useDesignerSessionState();

    const [coverMenuAnchorEl, setCoverMenuAnchorEl] = useState<HTMLElement | null>(null);
    const editSessionDialogRef = useRef<DialogImperativeRef>({});
    const actionToUseAfterConfirmingRecurrenceEditRef = useRef<(() => void) | null>(null);
    const commitInProgress = useDesignerSessionCommitState();

    const [editSessionDialogOpen, setEditSessionDialogOpen] = useState(false);
    const [isCoverImageUploaded, setIsCoverImageUploaded] = useState(false);
    const [isRepositioning, setIsRepositioning] = useState(false);

    const {isGuest, halfDayClock: userHalfDayClock} = useUserInfo();
    const halfDayClock = isGuest ? session.ownerHalfDayClock ?? false : userHalfDayClock;

    const startAt = new Date(session.startAt ?? new Date());
    const plannedEnd = new Date(session.plannedEnd ?? new Date());

    const isSessionAnotherYear = startAt.getFullYear() !== new Date().getFullYear();
    const isSessionOnMultipleDays = startAt.getDay() !== plannedEnd.getDay();
    const [isDefaultLobbyImage, setIsDefaultLobbyImage] = useState(false);

    const [nameTooltipOpen, setNameTooltipOpen] = useState(false);

    const toggleNameTooltip = () => {
        setNameTooltipOpen(!nameTooltipOpen);
    };

    const handleToggleEditSessionDialog = () => {
        if (session.event) {
            window.location.href = `/event/${session.id}/general`;
            return;
        }

        if (editSessionDialogOpen) {
            setEditSessionDialogOpen(false);
        } else {
            const designerSessionId = designer.state.getSessionId();
            if (!designerSessionId) {
                designer.state.initializeOrResetState(session.id);
            }
            setEditSessionDialogOpen(true);
        }
    };

    const handleEditSessionDetails = () => {
        actionToUseAfterConfirmingRecurrenceEditRef.current = handleToggleEditSessionDialog;
        editRecurrence();
    };

    const editRecurrence = () => {
        // if (session.instanceOfRecurrence?.session.id && !checkedRecurrenceEditForSessionId) {
        //     recurrenceEditDialogRef.current?.updateActionInfo({
        //         type: "edit",
        //         event: generateSessionForRecurrenceCheck(session),
        //     });

        //     recurrenceEditDialogRef.current?.toggle?.();
        // } else {
        actionToUseAfterConfirmingRecurrenceEditRef.current?.();
        // }
    };

    const coverImageMenuTogglerRef = useRef<HTMLButtonElement>(null);
    const coverImageMenuTogglerRefMobile = useRef<HTMLButtonElement>(null);

    const handleToggleCoverMenu = () => {
        setCoverMenuAnchorEl(coverImageMenuTogglerRef.current ?? coverImageMenuTogglerRefMobile.current);
    };

    const handleEditCover = () => {
        actionToUseAfterConfirmingRecurrenceEditRef.current = handleToggleCoverMenu;
        mixpanelTrack("frontend-change-cover", "lobby");
        editRecurrence();
    };

    const handleCloseCoverMenu = () => {
        setCoverMenuAnchorEl(null);
    };

    const [domain, setDomain] = useState(env.csd?.realDomain!);
    const reqPerm = useRef(session.requestPermissionToJoin);
    reqPerm.current = session.requestPermissionToJoin;

    useEffect(() => {
        getPlayerDomainForSession(session.id).then((domain) => {
            setDomain(domain);
        });
    }, []);

    const titleTooltipText = session?.name ?? "";
    const showTitleTooltip = titleTooltipText?.length > 40;

    const sessionState = t(`session.state.${session.backstage ? "not_started" : session.lifecycle.toLowerCase()}`);

    return (
        <>
            <div
                data-id="player-lobby-root"
                className={cls(classes.root, session.lobbyPicture?.originalPictureUrl && classes.withCoverImage)}
            >
                <div className={classes.lobbyContentWrapper}>
                    <Inject target="inSubHeader" id="lobby-picture" className={lobbyPictureStyle.coverImageContainer}>
                        {session.lobbyPicture?.originalPictureUrl ? (
                            <LobbyCoverImageContainer
                                isMobile={isMobile}
                                setIsCoverImageUploaded={setIsCoverImageUploaded}
                                isCoverImageUploaded={isCoverImageUploaded}
                                session={session}
                                ref={coverImageMenuTogglerRef}
                                handleToggleCoverMenu={handleEditCover}
                                isRepositioning={isRepositioning}
                                setIsRepositioning={setIsRepositioning}
                                isAdmin={isAdmin}
                                isAssistant={isAssistant}
                                isDefaultLobbyImage={isDefaultLobbyImage}
                            />
                        ) : (
                            <div />
                        )}
                    </Inject>
                    <div data-id="player-lobby-content" className={classes.content}>
                        {((session.startAt && session.plannedEnd) || !sessionIsPublished) && !isMobile ? (
                            <div className={cls("flex-justify-between", classes.timeContainer)}>
                                <div className={classes.timeAndDate}>
                                    <div className={"flex flex-row flex-align-center"}>
                                        <CalendarToday />
                                        <div className={isSessionOnMultipleDays ? "flex flex-col" : ""}>
                                            <Typography data-id="session-date">
                                                {format(startAt, localeWeekdayOfMonthDateFnsFormat[lang] ?? "EEEE, MMMM d", {
                                                    locale: getDateFnsLocale(lang),
                                                })}
                                            </Typography>
                                            {isSessionOnMultipleDays ? (
                                                <Typography data-id="session-multiple-days">
                                                    {format(
                                                        plannedEnd ?? startAt,
                                                        localeWeekdayOfMonthDateFnsFormat[lang] ?? "EEEE, MMMM d",
                                                        {
                                                            locale: getDateFnsLocale(lang),
                                                        }
                                                    )}
                                                </Typography>
                                            ) : null}
                                        </div>
                                        {isSessionAnotherYear ? <Typography>{`, ${dateFormat(startAt, ["year"])}`}</Typography> : null}
                                    </div>
                                    <div className={cls(classes.verticalSeparator, "mx-32")}></div>
                                    <div className="flex flex-row flex-align-center">
                                        <Schedule />
                                        <Typography data-id="session-time">
                                            <Time time={startAt} isHalfDayClock={halfDayClock} />
                                            {" - "}
                                            <Time time={plannedEnd} isHalfDayClock={halfDayClock} />
                                        </Typography>
                                    </div>
                                </div>

                                {isAdmin || isAssistant ? (
                                    <StartSessionButton
                                        className={classes.startSessionButton}
                                        session={session}
                                        sessionEditState={sessionEditState}
                                        isAdmin={isAdmin}
                                        isAssistant={isAssistant}
                                    />
                                ) : isGuest ? (
                                    <SendCalendarInviteMenu session={session} key="send-calendar-invite" />
                                ) : (
                                    <LobbyTimeLeftCounter countdownDate={startAt} />
                                )}
                            </div>
                        ) : null}
                        <div className={cls(classes.restOfContent)}>
                            <LobbyOwnerAvatar />
                            <div className={cls(classes.title, isMobile && classes.order0)}>
                                <Tooltip
                                    title={showTitleTooltip ? titleTooltipText : ""}
                                    placement={"top"}
                                    arrow
                                    onClick={isMobile ? toggleNameTooltip : undefined}
                                    open={isMobile ? nameTooltipOpen : undefined}
                                >
                                    <Typography data-id="session-title" component="h2" variant="xl3">
                                        {session.name}
                                    </Typography>
                                </Tooltip>
                                {isMobile ? (
                                    <ShareSessionLink url={`${window.location.protocol}//${domain}/session/${session?.id}`} />
                                ) : null}
                            </div>

                            <div className={cls(classes.subHeaderActions, isMobile && classes.order3)}>
                                {isAdmin ? (
                                    <div className="flex flex-items-center">
                                        <Button
                                            data-id="edit-session"
                                            disabled={commitInProgress}
                                            onClick={handleEditSessionDetails}
                                            variant="plain"
                                            className={classes.headerAction}
                                            noFocusBorder
                                        >
                                            <EditIcon />
                                            {session.event ? t("lobby.edit_event") : t("lobby.edit_session")}
                                        </Button>

                                        {!isMobile && startAt ? <LobbyStartsInCounter countdownDate={startAt} /> : null}

                                        {isMobile ? (
                                            <Button
                                                ref={coverImageMenuTogglerRefMobile}
                                                onClick={handleEditCover}
                                                variant="plain"
                                                className={classes.headerAction}
                                            >
                                                <ImageIcon />
                                                {t("lobby.edit_cover")}
                                            </Button>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="flex flex-items-center">
                                        <div className={classes.sessionState}>{sessionState}</div>
                                        {!isMobile && startAt ? <LobbyStartsInCounter countdownDate={startAt} /> : null}
                                    </div>
                                )}

                                {isAdmin || isAssistant ? <div className="flex flex-items-center"></div> : null}
                            </div>

                            {session.description ? (
                                <Typography
                                    data-id="description"
                                    className={cls(classes.description, isMobile && classes.order2)}
                                    variant="lg"
                                    component="div"
                                >
                                    <EditorSerializedContent content={session.description} />
                                </Typography>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            <SessionDataProviders isEditMode>
                {isAdmin && editSessionDialogOpen ? (
                    <CreatePlannedSessionDialog
                        key="edit-session-dialog-lobby"
                        sessionId={session.id}
                        open={editSessionDialogOpen}
                        dialogImperativeRef={editSessionDialogRef}
                        inLobby={true}
                        onClose={handleToggleEditSessionDialog}
                    />
                ) : null}

                {isAdmin ? (
                    <LobbyCoverImageMenuContainer
                        setIsCoverImageUploaded={setIsCoverImageUploaded}
                        session={session}
                        anchorEl={coverMenuAnchorEl}
                        onClose={handleCloseCoverMenu}
                        setIsRepositioning={setIsRepositioning}
                        mobileState={mobileState}
                        isDefaultLobbyImage={isDefaultLobbyImage}
                        setIsDefaultLobbyImage={setIsDefaultLobbyImage}
                        isLobby={true}
                    />
                ) : null}
            </SessionDataProviders>
        </>
    );
}

export default Lobby;
