import Inject from "@workhorse/providers/Injector";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/FooterPlayer.module.scss";
import {SessionData} from "@workhorse/declarations/dataTypes";
import AddToolsInPlayerButton from "./components/PlayerTools/AddToolsInPlayerButton";
import AgendaToggler from "./components/AgendaToggler/AgendaToggler";
import MicroControllerInjector from "./microController/MicroControllerInjector";
import {ConfigurationStep} from "@generated/data";

import {Fragment} from "@workhorse/api/rendering";
import SessionDataProviders, {useMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import designer from "@workhorse/api/designer";

type FooterPlayerProps = {
    myParticipantId: string;
    isAdmin: boolean;
    isAssistant: boolean;
    session: SessionData;
    inLobby: boolean;
    isSpeaker: boolean;
};

function FooterPlayer(props: FooterPlayerProps) {
    const {session, isAdmin, isAssistant, inLobby, isSpeaker} = props;
    const {allowAgendaType} = session;

    const mobileState = useMobile();
    const {isMobile, isMobileOrTablet} = mobileState;

    const macroArtifacts = useMacroArtifacts();

    const hasAgenda = macroArtifacts?.findIndex((ma) => ma.artifactId === "flowos/agenda") !== -1;

    const isAgendaAllowed = sessionFlagBasedPermission(allowAgendaType, {
        owner: isAdmin,
        assistant: isAssistant,
        speaker: isSpeaker,
    });

    const isAddingAgenda = designer.state.getSnapshot().configurationStep === ConfigurationStep.Create;

    console.log("isAddingAgenda", isAddingAgenda);

    const canSeeAgenda = (hasAgenda || isAddingAgenda) && (isAgendaAllowed || isAdmin || isAssistant);

    return (
        <Inject target="inFooter" id="player-footer-add-tools" className={cls(inLobby && "in-lobby")}>
            <div className={cls("flex fullh flex11-100 footer-player", classes.footerPlayer)}>
                <Fragment key="no-agenda">
                    {!isMobileOrTablet && (isAdmin || isAssistant) ? (
                        <AddToolsInPlayerButton
                            hasMicroController={isAdmin || isAssistant}
                            isAssistant={isAssistant}
                            isAddingAgenda={isAddingAgenda}
                            className={cls(classes.addToolsButton, isMobileOrTablet ? classes.addToolsButtonMobile : "")}
                            isAdmin={isAdmin}
                            hideText={isMobileOrTablet}
                            session={session}
                        />
                    ) : null}

                    {canSeeAgenda && (
                        <AgendaToggler
                            session={session}
                            isAdmin={isAdmin}
                            isAssistant={isAssistant}
                            inLobby={inLobby}
                            isAddingAgenda={isAddingAgenda}
                            mobileState={mobileState}
                        >
                            {/* {isAdmin || isAssistant ? (
                                <MicroControllerInjector
                                    session={session}
                                    isAssistant={isAssistant}
                                    mobileState={mobileState}
                                    key={`micro-controller-injector-${session.id}`}
                                    inLobby={false}
                                />
                            ) : null} */}
                        </AgendaToggler>
                    )}
                </Fragment>
            </div>
        </Inject>
    );
}

export default FooterPlayer;
