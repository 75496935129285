import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import {useMemo, useState} from "@workhorse/api/rendering";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import classes from "./style/EventSpeakersTableRow.module.scss";
import Fade from "@ui/core/components/Fade";
import {cls} from "@ui/cdk/util";
import Checkbox from "@ui/cdk/Checkbox";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {SpeakerDisplayType} from "./utils";
import EditIcon from "@material-ui/icons/Edit";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import Tooltip from "@ui/cdk/Tooltip";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import TableCell from "@ui/cdk/Table/TableCell";
import TableRow from "@ui/cdk/Table/TableRow";

import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "@workhorse/api/toast";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import browserInfo from "@workhorse/api/BrowserInfo";

type EventSpeakersTableRowProps = {
    speaker: SpeakerDisplayType;
    setSpeakerToEdit: (speakerId: string) => void;
    setSelected: (speakerId: string[]) => void;
    selected: string[];
    removeSpeakers: (speakerIds: string[]) => void;
    readOnly: boolean;
    handleSpeakerOrderChange: (speakerId: string, mode: "increment" | "decrement") => void;
    thisSpeakerOrder: number;
    maxOrder: number;
    baseLink: string;
    currentSpeakerOrderSort?: "asc" | "desc" | undefined;
    withOrderColumn?: boolean;
};

const EventSpeakersTableRow = (props: EventSpeakersTableRowProps) => {
    const {
        speaker,
        setSpeakerToEdit,
        selected,
        setSelected,
        removeSpeakers,
        readOnly,
        withOrderColumn,
        handleSpeakerOrderChange,
        maxOrder,
        baseLink,
        thisSpeakerOrder,
        currentSpeakerOrderSort,
    } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const isSelected = useMemo(() => selected.includes(speaker.id), [selected, speaker.id]);
    const isLocked = useDesignerSessionLocked();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditSpeaker = () => {
        setSpeakerToEdit(speaker.id);
        handleClose();
    };

    const handleSelectSpeaker = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.checked ? [...selected, speaker.id] : selected.filter((id) => id !== speaker.id));
    };

    const handleRemoveSpeaker = () => {
        removeSpeakers([speaker.id]);
        handleClose();
    };

    const handleIncrementOrder = () => {
        handleSpeakerOrderChange(speaker.id, "increment");
    };

    const handleDecrementOrder = () => {
        handleSpeakerOrderChange(speaker.id, "decrement");
    };

    const handleSpeakerLinkCopy = () => {
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
        handleClose();
    };

    const speakerTokenUrl = useMemo(() => {
        return `${baseLink}${speaker.token ? `?token=${speaker.token}` : ""}`;
    }, [speaker, baseLink]);

    const firstName = speaker && speaker?.name ? speaker.name.split(" ")[0] : "Speaker";
    const lastName = speaker && speaker?.name ? speaker.name.slice(firstName.length + 1) : "";

    return (
        <>
            <TableRow className={cls(classes.root, isSelected ? classes.selected : "")}>
                <TableCell size="small">
                    {readOnly ? null : <Checkbox className="mr-5" checked={selected.includes(speaker.id)} onChange={handleSelectSpeaker} />}
                </TableCell>

                {withOrderColumn && (
                    <TableCell size="unset" className={classes.cellOrder}>
                        <div className="fullw fullh flex flex-align-center">
                            <div className={classes.orderContainer}>
                                <div className={classes.orderLabel}>
                                    <Typography fontWeight="bold" color="quaternary" noWrap>
                                        {thisSpeakerOrder >= 0 ? thisSpeakerOrder + 1 : ""}
                                    </Typography>
                                </div>
                                <div className={classes.orderButtons}>
                                    <IconButton
                                        className={classes.orderButton}
                                        onClick={currentSpeakerOrderSort === "asc" ? handleDecrementOrder : handleIncrementOrder}
                                        disabled={
                                            (currentSpeakerOrderSort === "asc" && speaker.order === 0) ||
                                            (currentSpeakerOrderSort === "desc" && speaker.order === maxOrder) ||
                                            maxOrder === 0 ||
                                            isLocked
                                        }
                                    >
                                        <KeyboardArrowUpRoundedIcon />
                                    </IconButton>
                                    <IconButton
                                        className={classes.orderButton}
                                        onClick={currentSpeakerOrderSort === "asc" ? handleIncrementOrder : handleDecrementOrder}
                                        disabled={
                                            (currentSpeakerOrderSort === "asc" && speaker.order === maxOrder) ||
                                            (currentSpeakerOrderSort === "desc" && speaker.order === 0) ||
                                            maxOrder === 0 ||
                                            isLocked
                                        }
                                    >
                                        <KeyboardArrowDownRoundedIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </TableCell>
                )}

                <TableCell size="unset">
                    <div className="flex flex-align-center">
                        <ProfilePicture
                            avatar={speaker.thumbnailUrl || speaker.photoUrl || undefined}
                            avatarVariant="rounded"
                            avatarSize="M"
                            firstName={firstName}
                            lastName={lastName}
                            className="mr-12"
                            email={speaker.email}
                        />
                        {speaker.name && (
                            <Typography fontWeight="boldest" className="mr-12" noWrap data-private data-id="speaker-name">
                                {speaker.name}
                            </Typography>
                        )}
                    </div>
                </TableCell>
                <TableCell size="unset">
                    <Typography fontWeight="bold" color="tertiary" noWrap data-private data-id="speaker-email">
                        {speaker.email}
                    </Typography>
                </TableCell>
                <TableCell size="medium">
                    <div className="flex flex-align-center">
                        {!speaker.detailsComplete ? (
                            <Tooltip
                                title={
                                    <div className="p-5">
                                        <Typography fontWeight="bolder" className="mb-5">
                                            Missing info
                                        </Typography>
                                        <Typography variant="sm" className="mb-5">
                                            You haven’t completed this speaker’s profile yet.
                                        </Typography>
                                        <Typography variant="sm" className="mb-5">
                                            Click on the More Options icon for info.
                                        </Typography>
                                    </div>
                                }
                                arrow
                                placement="top"
                            >
                                <InfoOutlinedIcon className={cls("ml-8", classes.warningInfo)} data-id="status-partial" />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={
                                    <div className="p-5">
                                        <Typography fontWeight="bolder" className="mb-5">
                                            Speaker details complete
                                        </Typography>
                                        <Typography variant="sm" className="mb-5">
                                            This speaker&apos;s details are complete.
                                        </Typography>
                                    </div>
                                }
                                arrow
                                placement="top"
                            >
                                <CheckCircleOutlineRoundedIcon className={cls("ml-8", classes.successInfo)} data-id="status-complete" />
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
                <TableCell className={cls(browserInfo.isTablet() && classes.hideCell, classes.hideCellMd, "pr-0")} size="medium">
                    <div className="fullw fullh flex flex-justify-end flex-items-center">
                        <CopyToClipboard text={speakerTokenUrl}>
                            <IconButton onClick={handleSpeakerLinkCopy} className={classes.copyBtn} data-id="speaker-copy-link">
                                <Tooltip title={"Copy speaker link"} placement="top" arrow>
                                    <LinkRoundedIcon className={classes.moreVertIcon} />
                                </Tooltip>
                            </IconButton>
                        </CopyToClipboard>
                    </div>
                </TableCell>
                <TableCell size="medium">
                    <div className="flex flex-justify-center">
                        {readOnly ? null : (
                            <IconButton data-id="event-speaker-menu" className={classes.moreButton} onClick={handleClick}>
                                <MoreVertRoundedIcon />
                            </IconButton>
                        )}
                    </div>
                </TableCell>
            </TableRow>

            {anchorEl && (
                <Menu
                    id="fade-menu"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    classes={{paper: classes.moreMenuPaper}}
                    anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                    transformOrigin={{horizontal: "right", vertical: "top"}}
                    getContentAnchorEl={null}
                >
                    <MenuItem onClick={handleEditSpeaker} data-id="edit-speaker">
                        <EditIcon /> Edit speaker details
                    </MenuItem>

                    <MenuItem
                        className={cls(classes.menuItem, "red")}
                        onClick={handleRemoveSpeaker}
                        data-id="remove-speaker"
                        variant="destructive"
                    >
                        <CloseRoundedIcon /> Remove speaker
                    </MenuItem>
                </Menu>
            )}
        </>
    );
};

export default EventSpeakersTableRow;
