import MyProductToolIframeComponent from "@artifacts/my-product-tool/ui/MyProductToolIframeComponent";
import Close from "@material-ui/icons/Close";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import React from "@workhorse/api/rendering";
import {v4 as uuid} from "uuid";
import BlankProductTool from "../../../../assets/media/icon-my-product-no-bg.svg";
import classes from "./style/MyProductTool.module.scss";
import {ToolComponentProps} from "./ToolComponent";

type ToolComponentPreviewDialogProps = ToolComponentProps & {
    imperativeRef: React.MutableRefObject<DialogImperativeRef | null>;
    onClose: () => void;
};

const ToolComponentPreviewDialog = (props: ToolComponentPreviewDialogProps) => {
    const {toolData, onClose} = props;

    return (
        <Dialog
            id="preview-memory-micro-dialog"
            imperativeRef={props.imperativeRef}
            PaperProps={{
                className: cls(classes.paper, "standard-dialog-paper"),
            }}
            BackdropProps={{
                className: cls(classes.backdrop, "standard-backdrop"),
            }}
            disableBackdropClick={true}
            onEscapeKeyDown={onClose}
        >
            <DialogTitle className={classes.dialogHeader} key="dialog-title">
                <div className={classes.logoDialogHeader}>
                    <img src={toolData?.iconUrl || BlankProductTool} alt="tool logo" />
                </div>
                <Typography className="ml-8" variant="lg">
                    {toolData?.title || "My Product"}
                </Typography>
                <IconButton onClick={onClose} className={classes.closeBtn}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <MyProductToolIframeComponent
                artifactId={uuid()}
                startingDetails={{
                    ownerOfToolId: props.userId,
                    timezone: props.timezone,
                    toolId: toolData.id,
                    url: toolData.url ?? "",
                }}
            />
            <DialogFooter key="dialog-footer" className={classes.dialogFooter}>
                <Button data-id="back-button" variant="primary" onClick={onClose} key="cancel">
                    Done
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default ToolComponentPreviewDialog;
