import Button from "@ui/cdk/Button";
import {WithJoinTooltip} from "./WithJoinTooltip";
import classes from "./styles/PreJoinSubmitButton.module.scss";
import {useTranslation} from "react-i18next";

type PreJoinSubmitButtonProps = {
    onSubmit: () => void;
    isSubmitting: boolean;
    requestPermissionToJoin: boolean;
    isPermissionDenied?: boolean;

    showNetworkIssueMsg: boolean;

    className?: string;
    disabled?: boolean;
    loading?: boolean;
    isOwnerOnboarding?: boolean;
};

const PreJoinSubmitButton = (props: PreJoinSubmitButtonProps) => {
    const {t} = useTranslation();
    const {
        onSubmit,
        isSubmitting,
        requestPermissionToJoin,
        isPermissionDenied,
        showNetworkIssueMsg,
        className,
        disabled,
        loading,
        isOwnerOnboarding,
    } = props;

    return (
        <WithJoinTooltip enabled={disabled ?? false}>
            <Button
                data-id="prejoin-button-joinnow"
                size="large"
                onClick={onSubmit}
                className={className ?? classes.root}
                disabled={isSubmitting || disabled}
                component={isSubmitting ? "span" : "button"}
                loading={isSubmitting || loading}
            >
                {isPermissionDenied
                    ? t("prejoin.try_again")
                    : requestPermissionToJoin
                    ? t("prejoin.request_to_join")
                    : showNetworkIssueMsg
                    ? t("prejoin.reconnect")
                    : isOwnerOnboarding
                    ? t("prejoin.start_test_session")
                    : t("prejoin.join_now")}
            </Button>
        </WithJoinTooltip>
    );
};

export default PreJoinSubmitButton;
