import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import commons from "../../commons/style.module.scss";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {forwardRef} from "@workhorse/api/rendering";
import {SessionRecordingType} from "@generated/data";
import Tooltip from "@ui/cdk/Tooltip";

import {ReactComponent as LivestreamIcon} from "../../../../assets/media/livestream-icon.svg";
import {ReactComponent as LivestreamRecordingIcon} from "../../../../assets/media/livestream-recording-icon.svg";

interface Props {
    active: boolean;
    onToggle?: () => void;
    disabled?: boolean;
    recordingType: SessionRecordingType;
    livestreamDisabledError?: string | null;
}

export const LivestreamTogglerButton = forwardRef<unknown, Props>((props, ref) => {
    const keybinds = getGlobalKeybind("toggleLivestream");

    const {t} = useTranslation();

    const livestreamSelected =
        props.recordingType === SessionRecordingType.RecordingLivestream || props.recordingType === SessionRecordingType.Livestream;

    const isRecording = props.active && !livestreamSelected;

    const textKey = props.active ? "participant.recording.stop_livestreaming" : "participant.recording.start_livestreaming";

    const ButtonIcon = props.recordingType === SessionRecordingType.RecordingLivestream ? <LivestreamRecordingIcon /> : <LivestreamIcon />;

    return (
        <Tooltip arrow title={props.livestreamDisabledError || ""}>
            <div>
                <MenuItem
                    ref={ref}
                    onClick={props.onToggle}
                    className={`flex-center-all ${props.active && livestreamSelected ? "red" : "blue"}`}
                    disabled={props.disabled || !!props.livestreamDisabledError || isRecording}
                >
                    <Typography fontWeight="bold">{t(textKey)}</Typography>

                    <div className="flex flex-align-center ml-12">
                        {keybinds.map((keybind, index) => (
                            <div key={`stream-toggler-${index}`} className={commons.keyBind}>
                                {keybind}
                            </div>
                        ))}
                    </div>
                </MenuItem>
            </div>
        </Tooltip>
    );
});

LivestreamTogglerButton.displayName = "RecordingTogglerButton";
