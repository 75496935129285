import {cls, capitalize} from "@ui/cdk/util/util";
import {forwardRef} from "react";
import styles from "./style/badge.module.scss";
import {BadgeProps} from "./types";

function BadgeWithRef(props: BadgeProps, ref?: any) {
    const {
        /**
         * @param {String | Number} content Component content
         */
        content,
        /**
        /**
         * @param {BadgeVariants} variant The variant of the badge component @variant "filled" | "outlined" @default 'filled'
         */
        variant = "filled",
        /**
        /**
         * @param {BadgeSize} size The size of the badge component @size "small | medium | large" @default 'medium'
         */
        size = "medium",
        /**
         * @param {Node} children The children of the component.
         */
        children,
        /**
         * @param {BadgeColors} color The color of the badge content. @default "blue"
         */
        color = "blue",
        /**
         * @param {Boolean} invisible If true, the badge will be invisible. @default false
         */
        invisible = false,
        /**
         * @param {Number} max Max count to show. When above the max, it should show ${content}+ @default 99
         */
        max = 99,
        /**
         * @param {Number} min Controls whether the badge is hidden when content is below the minimum value.
         */
        min = 0,
        /**
         * @param {BadgePositionTypes} position Badge Position relative to children @default "topRight"
         */
        position = "topRight",
        /**
         * @param {String} className Adds a className to the root of the component.
         */
        className,
        ...rest
    } = props;

    const isString = typeof content === "string" && content.length > 0;
    const isNumber = typeof content === "number" && content > 0 && content >= min;

    const classNamesRoot = cls("fosBadge", styles.root, className);

    const classNamesBadge = cls(
        styles[`badge${capitalize(variant)}`],
        styles[`bg${color && capitalize(color)}`],
        styles[`badge${capitalize(size)}`],
        styles[`position${capitalize(position)}`]
    );

    const contentDisplay = isString ? content : isNumber && content && content < max ? content : `${max}+`;

    return (
        <div className={classNamesRoot} ref={ref} key="badge">
            {children}
            {(!isString && !isNumber) || invisible ? null : (
                <div className={classNamesBadge} {...rest} key="badge-content">
                    {contentDisplay}
                </div>
            )}
        </div>
    );
}

const Badge = forwardRef(BadgeWithRef) as (props: BadgeProps & {ref?: unknown}) => ReturnType<typeof BadgeWithRef>;

export {BadgeWithRef};
export default Badge;
