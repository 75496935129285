import Checkbox from "@ui/cdk/Checkbox";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import React from "react";
import {SpeakerDisplayType} from "../event-speakers/utils";
import classes from "./style/AddSpeakerDialogListItem.module.scss";

type Props = {
    speaker: SpeakerDisplayType;
    currentSpeakersIds: string[];
    onSelectSpeaker: (speakerId: string) => void;
};

const AddSpeakerDialogListItem = (props: Props) => {
    const {speaker, currentSpeakersIds, onSelectSpeaker} = props;

    const isSelected = currentSpeakersIds.includes(speaker.id);

    const handleSelectSpeaker = () => {
        onSelectSpeaker(speaker.id);
    };

    const firstName = speaker && speaker?.name ? speaker.name.split(" ")[0] : "Speaker";
    const lastName = speaker && speaker?.name ? speaker.name.slice(firstName.length + 1) : "";

    return (
        <div
            key={speaker.id}
            className={cls("flex flex-align-center", classes.root, isSelected ? classes.selected : "")}
            onClick={handleSelectSpeaker}
        >
            <div className="fullw flex flex-align-center flex-justify-between">
                <div className="flex flex-align-center">
                    <ProfilePicture
                        avatar={speaker.thumbnailUrl || speaker.photoUrl || undefined}
                        avatarVariant="rounded"
                        avatarSize="L"
                        firstName={firstName}
                        lastName={lastName}
                        className="mr-12"
                        email={speaker.email}
                    />
                    <div className="flex flex-col">
                        {speaker.name && (
                            <Typography color="secondary" fontWeight="bolder">
                                {speaker.name}
                            </Typography>
                        )}
                        <Typography data-id="speaker-email" className={classes.email}>
                            {speaker.email}{" "}
                        </Typography>
                    </div>
                </div>

                <Checkbox className={classes.checkbox} checked={isSelected} />
            </div>
        </div>
    );
};

export default AddSpeakerDialogListItem;
