import {useState} from "@workhorse/api/rendering";
import ConferenceMicIcon from "./ConferenceMicIcon";
import {useDeviceManagerEvents} from "@workhorse/api/conference2";
import {useParticipantVolume} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";

interface WithParticipantID {
    participantId: string;
}

interface WithLocalAudioProps {
    localAudio: boolean;
}

export type AudioAnimationProps = WithParticipantID | WithLocalAudioProps;

interface Props {
    className?: string;
    isMuted: boolean;
    isThumbnailMode?: boolean;
    onClick?: () => void;
}

export default function AnimatedMicrophoneIcon(props: Props & AudioAnimationProps) {
    if (isParticipantIdProp(props)) {
        return <AudioAnimationWithParticipantId {...props} />;
    }

    if (isLocalAudio(props) && props.localAudio) {
        return <LocalAudioAnimation {...props} />;
    }

    return null;
}

function isParticipantIdProp(props: AudioAnimationProps): props is WithParticipantID {
    return "participantId" in props;
}

function isLocalAudio(props: AudioAnimationProps): props is WithLocalAudioProps {
    return "localAudio" in props;
}

function AudioAnimationWithParticipantId(props: Props & WithParticipantID) {
    const volume = useParticipantVolume(props.participantId);
    return <ConferenceMicIcon {...props} volume={volume} />;
}

function LocalAudioAnimation(props: Props & WithLocalAudioProps) {
    const [volume, setVolume] = useState(0);
    useDeviceManagerEvents({
        onAudioVolume: setVolume,
    });
    return <ConferenceMicIcon {...props} volume={volume} />;
}
