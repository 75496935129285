import {AgendaItemSpeaker, ChooseToolStep} from "@generated/data";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import LockIcon from "@material-ui/icons/Lock";
import Button from "@ui/cdk/Button";
import {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import designer from "@workhorse/api/designer";
import {Fragment, memo, useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {WithMobileState} from "@workhorse/declarations";
import {AgendaItem} from "@workhorse/declarations/dataTypes";
import {DragSourceMonitor, useDrag} from "react-dnd";
import {ReactComponent as InsertAbove} from "../../../../assets/media/insert-agenda-item-above.svg";
import {ReactComponent as InsertBelow} from "../../../../assets/media/insert-agenda-item-below.svg";
import {ReactComponent as SpeakerIcon} from "../../../../assets/media/speaker-icon.svg";
import AgendaItemArtifact from "./components/AgendaItemArtifact/AgendaItemArtifact";
import AgendaItemDescription from "./components/AgendaItemDescription/AgendaItemDescription";
import AgendaItemDropZone from "./components/AgendaItemDropZone/AgendaItemDropZone";
import AgendaItemTime from "./components/AgendaItemTime/AgendaItemTime";
import AgendaItemTitle from "./components/AgendaItemTitle/AgendaItemTitle";
import AgendaItemUserEditing from "./components/AgendaItemUserEditing/AgendaItemUserEditing";
import classes from "./style/AgendaItemEditor.module.scss";

import {mapToEditorParagraph} from "@api/editor";
import {InitialConfigurationPropertyType} from "@artifacts/breakout/api/constants";
import AddToPhotosRoundedIcon from "@material-ui/icons/AddToPhotosRounded";
import AvatarGroup from "@ui/cdk/AvatarGroup";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import AgendaGeneratorContainer from "@workhorse/api/aiGenerator/AgendaGeneratorContainer";
import {AIGeneratedAgendaItem} from "@workhorse/api/aiGenerator/AgendaGeneratorMessenger";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {FocusedAgendaItem} from "@workhorse/api/designer/lib/utils/agendaItemUtils";
import {insertAgendaItemAbove, insertAgendaItemBelow} from "@workhorse/api/designer/methods";
import toast from "@workhorse/api/toast";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {
    AgendaBoxSpeakerNotes,
    AgendaBoxSpeakerNotesToggler,
} from "@workhorse/pages/designer/Agenda/agendaBox/Components/AgendaBoxSpeakerNotes";
import EventAgendaItemAddedSpeakers from "@workhorse/pages/event/event/event-agenda/EventAgendaItemAddedSpeakers";
import {useAiGeneratedAgenda} from "@workhorse/providers/AiGeneratedAgendaProvider";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useTranslation} from "react-i18next";
import {ReactComponent as AddAttachmentIcon} from "../../../../assets/media/agenda-attachment-icon.svg";

type AgendaItemEditorProps = {
    agendaItem: AgendaItem;
    isSelected?: boolean;
    readOnly?: boolean;
    openConfigDialog?: (agendaItemId: string) => Promise<void> | void;
    onSelect?: (id: string) => void;
    // dialogState?: boolean | undefined;
    scrollContainerRef?: React.MutableRefObject<ScrollContainerRef>;
    agendaItemsLength?: number;
    isAgendaShared?: boolean;
    commitAfterUpdate?: () => Promise<void>;
    lockAgendaItem?: (agendaItemId: string) => Promise<boolean>;
    userId?: string;
    openPreview?: (step: ChooseToolStep, agendaItemId: string, artifactId?: string) => void;
    unlockAgendaItemRef?: React.MutableRefObject<(id?: string) => Promise<boolean>>;
    inEventSettings?: boolean;
    onAddSpeaker?: (agendaItemId: string) => void;
    sessionId?: string;
    hideTime?: boolean;
    onFocus?: () => void;
    commitInProgress?: boolean;
    focusedAgendaItem?: FocusedAgendaItem | null;
    setFocusedAgendaItem?: (agendaItem: FocusedAgendaItem) => void;
} & WithMobileState;

const AgendaItemEditor = (props: AgendaItemEditorProps) => {
    const {t} = useTranslation();
    const {
        agendaItem,
        openConfigDialog,
        onSelect,
        isSelected,
        readOnly: readOnlyProp,
        scrollContainerRef,
        agendaItemsLength,
        isAgendaShared,
        commitAfterUpdate: commit,
        lockAgendaItem,
        userId,
        unlockAgendaItemRef,
        mobileState,
        // dialogState,
        openPreview,
        inEventSettings,
        onAddSpeaker,
        sessionId,
        hideTime,
        onFocus,
        commitInProgress,
        focusedAgendaItem,
        setFocusedAgendaItem,
    } = props;

    const [openDeleteSelected, setOpenDeleteSelected] = useState<boolean>(false);
    const [shouldUpdateEditorContent, setShouldUpdateEditorContent] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<string>("");
    const isEventLocked = useDesignerSessionLocked();

    const {saveGeneratedAgenda, handleOnError} = useAiGeneratedAgenda();
    const {mixpanelTrack} = useMixpanelTracker();
    const isLocked = useMemo(() => !!(agendaItem.locked && agendaItem.locked !== userId), [agendaItem.locked, userId]);

    const readOnly = useMemo(() => !!readOnlyProp, [readOnlyProp]);

    const toggleDeleteAgendaItems = useCallback(() => {
        setOpenDeleteSelected((prev) => !prev);
    }, []);

    const unlockAgendaItem = useCallback(
        (id?: string) => {
            return unlockAgendaItemRef?.current?.(id);
        },
        [unlockAgendaItemRef]
    );

    const commitAfterUpdate = useCallback(async () => {
        const updated = await unlockAgendaItem();
        if (!updated) {
            await commit?.();
        }
    }, [commit, unlockAgendaItem]);

    const remove = useCallback(() => {
        const deletionAllowed = designer.currentAgendaItems().filter((a) => !a.isDeleted).length > 1;
        if (!deletionAllowed) {
            toast("Agenda cannot be empty", {
                type: "error",
            });
            setOpenDeleteSelected(false);
            return;
        }

        designer.api.agendaItem.remove({id: agendaItem.id});
        //if id exists in selected array, use onSelect to remove it
        if (isSelected && onSelect) {
            onSelect(agendaItem.id);
        }
        commitAfterUpdate?.();
    }, [agendaItem.id, isSelected, onSelect, commitAfterUpdate]);

    const insertAbove = () => {
        insertAgendaItemAbove([agendaItem.id], 0, userId);
        commitAfterUpdate?.();
    };

    const insertBelow = () => {
        insertAgendaItemBelow([agendaItem.id], 0, userId);
        commitAfterUpdate?.();
    };

    const setEditState = designer.state.update;

    // TODO: move this to container

    const startConfiguring = useCallback(() => {
        setEditState({
            toolStep: ChooseToolStep.SelectTool,
            selectedAgendaItemId: agendaItem.id,
            selectedMicroIdToPreview: null,
            selectedMicroIdToConfig: null,
        });
        openConfigDialog?.(agendaItem.id);
        mixpanelTrack("frontend-add-resource", "agendas");
    }, [agendaItem.id, openConfigDialog, setEditState]);

    const [{isDragging}, drag] = useDrag({
        item: {type: "templates-agenda-item", id: agendaItem?.id, isSelected: !!isSelected},
        begin(monitor) {
            const canDrag = monitor.canDrag();
            if (canDrag) {
                lockAgendaItem?.(agendaItem.id);
            }
        },
        end(draggedItem, monitor) {
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                unlockAgendaItem();
            }
            if (inEventSettings) {
                designer.commit({
                    source: "agenda",
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const hasDescription = useMemo(() => agendaItem.descriptionJson.type === "doc", [agendaItem.descriptionJson]);

    const slateToolbarContainerRef = useRef<HTMLDivElement>(null);

    const handleFocus = useCallback(
        (value: "title" | "description" | "duration") => {
            setFocusedAgendaItem?.({agendaItemId: agendaItem?.id ?? "", elem: value});
        },
        [agendaItem.id, setFocusedAgendaItem]
    );

    const onFocusDescription = useCallback(
        async (preventLocking?: boolean) => {
            handleFocus("description");

            if (slateToolbarContainerRef?.current) {
                scrollContainerRef?.current?.scrollChildIntoView?.({
                    node: slateToolbarContainerRef.current,
                    offset: 90,
                });
            }
            if (!preventLocking) {
                await lockAgendaItem?.(agendaItem.id);
            }
            onFocus?.();
        },
        [agendaItem.id, lockAgendaItem, scrollContainerRef, handleFocus]
    );

    const onFocusTitle = () => {
        handleFocus("title");
    };

    const handleFocusAgendaItem = useCallback(async () => {
        await lockAgendaItem?.(agendaItem.id);
        onFocus?.();
    }, [agendaItem.id, lockAgendaItem, handleFocus]);

    const removeTool = () => {
        designer.api.agendaItem.removeArtifact({
            agendaItemId: agendaItem.id,
        });
        commitAfterUpdate?.();
    };

    const Parent = useMemo(() => {
        if (agendaItem.agendaItemSpeakers?.length !== undefined) {
            return agendaItem.agendaItemSpeakers?.length < 12 ? Fragment : AvatarGroup;
        } else {
            return Fragment;
        }
    }, [agendaItem.agendaItemSpeakers?.length]);

    useEffect(() => {
        if (agendaItem && !agendaItem.artifact) {
            designer.api.agendaItem.addArtifact({
                addDefaultArtifact: true,
                agendaItemId: agendaItem.id,
                overrides: {},
            });
        }
    }, []);

    const onNewAgendaItemGenerated = async (agendaItem: AIGeneratedAgendaItem) => {
        const agendaWithId = {...agendaItem, id: makeId()};
        saveGeneratedAgenda(agendaWithId);
    };

    const onAgendaItemGenerationError = (error: string) => {
        console.log("ERROR on AI Generated Agenda from AGENDA", error);
        handleOnError(t("agenda.toast.generate_agenda_error") ?? "");
    };

    const handleUpdateEditorContent = () => {
        setShouldUpdateEditorContent(true);
    };

    const duplicate = () => {
        const artifact = agendaItem?.artifact;

        if (!artifact || artifact.artifactId !== "flowos/breakout") {
            designer.api.agendaItem.duplicate({
                id: agendaItem?.id,
            });
            return;
        }

        const config = JSON.parse(artifact.data["InitialBORConfiguration"]) as InitialConfigurationPropertyType | undefined;
        config?.breakoutRooms.forEach((room) => (room.id = makeId()));

        designer.api.agendaItem.duplicate({
            id: agendaItem?.id,
            overrides: {
                artifact: {
                    properties: [
                        {
                            __typename: "Property",
                            key: "InitialBORConfiguration",
                            value: JSON.stringify(config),
                            updatedAt: null as unknown as Date,
                            createdAt: null as unknown as Date,
                            isDeleted: false,
                            update: null,
                            oldId: null,
                        },
                    ],
                    data: {
                        InitialBORConfiguration: JSON.stringify(config),
                    },
                },
            },
        });
    };

    const remainingSpeakers = useMemo(() => {
        if (agendaItem?.agendaItemSpeakers?.length <= 2) return "";
        return (
            <div className="flex flex-col flex-align-start gap-10">
                {agendaItem?.agendaItemSpeakers?.slice(3)?.map((speaker) => {
                    return <EventAgendaItemAddedSpeakers speaker={speaker as AgendaItemSpeaker} key={speaker.id} variant={"default"} />;
                })}
            </div>
        );
    }, [agendaItem?.agendaItemSpeakers?.length]);

    const hasSpeakerNotes = !isLocked && (!readOnly || !!agendaItem.speakerNotes?.length);

    const onFocusAgendaItem = () => {
        setIsFocused(agendaItem.id);
        handleFocus("title");
    };
    const onBlurAgendaItem = () => {
        setIsFocused("");
    };

    const handleUseAgendaItem = (generatedAgendaItem: AIGeneratedAgendaItem) => {
        if (agendaItem?.id && generatedAgendaItem) {
            designer.api.agendaItem.update({
                id: agendaItem?.id,

                agendaItem: {
                    title: generatedAgendaItem?.name,
                    descriptionJson: mapToEditorParagraph(generatedAgendaItem?.description),
                    duration: generatedAgendaItem?.duration * 60,
                },
            });
            handleUpdateEditorContent();
        }
    };

    const isAgendaItemFocused = useMemo(() => isFocused === agendaItem.id, [isFocused, agendaItem.id]);
    return isDragging ? (
        <div className={classes.dragging}></div>
    ) : (
        <>
            {agendaItem?.order === 0 ? (
                <AgendaItemDropZone
                    scrollContainerRef={scrollContainerRef}
                    afterArtifactId={undefined}
                    isDragging={isDragging}
                ></AgendaItemDropZone>
            ) : null}

            <div className={classes.agendaItemWrapper}>
                <div
                    onFocus={onFocusAgendaItem}
                    onBlur={onBlurAgendaItem}
                    data-id={`agendaItem-${agendaItem?.order}`}
                    className={cls(
                        classes.agendaItem,
                        isAgendaItemFocused && classes.selected,
                        readOnly && classes.readOnly,
                        isLocked && !readOnly && classes.locked,
                        inEventSettings && classes.inEventSettings,
                        "flex flex-row"
                    )}
                >
                    <div className="flex flex-col fullw">
                        <div
                            id={`slate-toolbar-slot-${agendaItem?.id}`}
                            ref={slateToolbarContainerRef}
                            className={cls(classes.toolbarWrapper)}
                        ></div>
                        <div
                            id={`edit-speaker-notes-slate-toolbar-slot-${agendaItem?.id}`}
                            ref={slateToolbarContainerRef}
                            className={cls(classes.toolbarWrapper)}
                        ></div>
                        {readOnly ? null : isLocked ? (
                            <LockIcon className={classes.lockIcon} />
                        ) : (
                            <div>
                                <Button
                                    ref={drag}
                                    size="smallest"
                                    variant="plain"
                                    className={cls(classes.dragButton, "p-0 hide-when-no-hovered")}
                                >
                                    <DragIndicatorIcon className={classes.dragIcon} />
                                </Button>
                            </div>
                        )}
                        <div className="flex flex-align-start flex11-100 p-20">
                            <div className="flex flex-col flex11-100">
                                {readOnly || isLocked ? null : (
                                    <div className={cls("hide-when-no-hovered", classes.topMenu)}>
                                        <IconButton
                                            onClick={insertAbove}
                                            className={cls(classes.topMenuButton, "editing")}
                                            data-id="insert-above"
                                        >
                                            <InsertAbove />
                                        </IconButton>

                                        <IconButton
                                            onClick={insertBelow}
                                            className={cls(classes.topMenuButton, "editing")}
                                            data-id="insert-below"
                                        >
                                            <InsertBelow />
                                        </IconButton>

                                        <IconButton
                                            onClick={duplicate}
                                            className={cls(classes.topMenuButton, "editing")}
                                            data-id="duplicate"
                                        >
                                            <AddToPhotosRoundedIcon />
                                        </IconButton>

                                        <IconButton className={cls(classes.topMenuButton, "editing")} data-id="ai-generator">
                                            <AgendaGeneratorContainer
                                                sessionId={sessionId!}
                                                onAgendaGenerationError={onAgendaItemGenerationError}
                                                onAgendaItemGenerated={onNewAgendaItemGenerated}
                                                agendaItemId={agendaItem?.id}
                                                handleUseAgendaItem={handleUseAgendaItem}
                                                isSingleAgendaItem={true}
                                            />
                                        </IconButton>

                                        <IconButton className={classes.topMenuButton} onClick={toggleDeleteAgendaItems} data-id="delete">
                                            <DeleteOutlineRoundedIcon />
                                        </IconButton>
                                    </div>
                                )}
                                <div className="fullw flex flex-align-center pr-16">
                                    <div className={classes.agendaItemOrder}>
                                        <Typography variant="xl" fontWeight="bolder">
                                            {agendaItem.order + 1}.
                                        </Typography>
                                    </div>
                                    <AgendaItemTitle
                                        readOnly={readOnly || isLocked}
                                        value={agendaItem.title ?? ""}
                                        id={agendaItem.id}
                                        inEventSettings={inEventSettings}
                                        onFocusInput={onFocusTitle}
                                        onFocusAgendaItem={handleFocusAgendaItem}
                                        onBlur={commitAfterUpdate}
                                        // dialogState={dialogState}
                                        className={(readOnly && hasDescription) || !readOnly ? "mb-8" : ""}
                                        order={agendaItem.order}
                                    />
                                </div>
                                <div className="flex flex-align-center mr-16">
                                    {(readOnly && hasDescription) || !readOnly ? (
                                        <AgendaItemDescription
                                            readOnly={readOnly || isLocked}
                                            value={agendaItem.descriptionJson}
                                            id={agendaItem.id}
                                            inEventSettings={inEventSettings}
                                            onBlur={commitAfterUpdate}
                                            onFocusDescription={onFocusDescription}
                                            className={readOnly ? "mb-15" : ""}
                                            shouldUpdateEditorContent={shouldUpdateEditorContent}
                                            setShouldUpdateEditorContent={setShouldUpdateEditorContent}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <ConfirmationDialog
                            key="templates-delete-agenda-item-dialog"
                            title={t("agenda.confirm.delete_agenda_items_one") ?? `Delete agenda item`}
                            content={t("agenda.confirm.delete_agenda_items_description") ?? "Your agenda will be adjusted accordingly."}
                            cancelButton={t("g.cancel") ?? "Cancel"}
                            minWidth
                            submitButton={t("g.delete") ?? "Delete"}
                            onClose={toggleDeleteAgendaItems}
                            open={openDeleteSelected}
                            submitButtonVariant="destructive-secondary"
                            onConfirm={remove}
                            variant="warning"
                            isDeleteConfirmation
                        />
                        <div className={classes.separator} />
                        <div className={classes.agendaItemExtraSection}>
                            {isLocked ? null : agendaItem.artifact &&
                              Object.keys(agendaItem.artifact ?? {}).length &&
                              agendaItem?.artifact?.artifactId !== "flowos/conference" ? (
                                <>
                                    <AgendaItemArtifact
                                        readOnly={readOnly || isLocked}
                                        onClick={openPreview}
                                        agendaItemId={agendaItem.id}
                                        artifact={agendaItem.artifact}
                                        isAgendaShared={isAgendaShared}
                                        inEventSettings={inEventSettings}
                                        sessionId={sessionId as string}
                                        hasSpeakerNotes={hasSpeakerNotes}
                                        removeTool={removeTool}
                                    />
                                </>
                            ) : readOnly ? null : (
                                <Button
                                    data-id="agendas-button-share-agenda-item"
                                    className={cls(
                                        "nowrap",
                                        "flex flex-align-center",
                                        classes.addToolButton,
                                        `editing_${agendaItem.id}`,
                                        inEventSettings && classes.addToolButtonInEventSettings
                                    )}
                                    variant="tertiary"
                                    size="smallest"
                                    disabled={!!commitInProgress}
                                    onClick={startConfiguring}
                                >
                                    <AddAttachmentIcon /> Add resource
                                </Button>
                            )}

                            {onAddSpeaker &&
                            agendaItem.agendaItemSpeakers?.length !== undefined &&
                            agendaItem.agendaItemSpeakers.length > 0 ? (
                                <div className={cls("flex flex-row flex-align-center no-overflow", classes.assignedSpeakersContainer)}>
                                    <Button
                                        onClick={readOnly ? undefined : onAddSpeaker.bind(null, agendaItem.id)}
                                        variant="plain"
                                        className={cls(
                                            classes.changeSpeakerButton,
                                            inEventSettings && classes.addToolButtonInEventSettings
                                        )}
                                        data-id="change-speakers"
                                        noFocusBorder
                                        size="smallest"
                                    >
                                        <Parent
                                            {...(agendaItem.agendaItemSpeakers?.length !== undefined &&
                                            agendaItem.agendaItemSpeakers.length < 12
                                                ? null
                                                : {max: 12, avatarSize: "S", spacing: 17, avatarVariant: "rounded"})}
                                            key={
                                                agendaItem.agendaItemSpeakers?.length !== undefined &&
                                                agendaItem.agendaItemSpeakers.length < 12
                                                    ? "list"
                                                    : "stack"
                                            }
                                        >
                                            {agendaItem.agendaItemSpeakers?.slice(0, 3)?.map((speaker) => {
                                                return (
                                                    <EventAgendaItemAddedSpeakers
                                                        speaker={speaker as AgendaItemSpeaker}
                                                        key={speaker.id}
                                                        variant={agendaItem.agendaItemSpeakers.length >= 2 ? "small" : "default"}
                                                    />
                                                );
                                            })}

                                            {agendaItem.agendaItemSpeakers.length >= 2 && (
                                                <div className="flex flex-row">
                                                    {agendaItem?.agendaItemSpeakers?.slice(3)?.length > 0 && (
                                                        <Tooltip
                                                            title={remainingSpeakers ? remainingSpeakers : ""}
                                                            placement="top"
                                                            arrow
                                                            classes={{
                                                                tooltip: classes.speakersTooltip,
                                                            }}
                                                        >
                                                            <div className={classes.remainingSpeakersAvatar}>
                                                                +{agendaItem?.agendaItemSpeakers?.slice(3)?.length}
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                    <div className="px-6">
                                                        {t("participant.mic.speakers.title") ?? "Speakers"}:{" "}
                                                        {agendaItem.agendaItemSpeakers.length}
                                                    </div>
                                                </div>
                                            )}
                                        </Parent>
                                    </Button>
                                </div>
                            ) : null}
                            {inEventSettings &&
                            onAddSpeaker &&
                            !readOnly &&
                            agendaItem.agendaItemSpeakers?.length !== undefined &&
                            agendaItem.agendaItemSpeakers.length <= 0 ? (
                                <Button
                                    data-id="event-button-add-speakers"
                                    className={cls(
                                        "nowrap",
                                        classes.addSpeakerButton,
                                        inEventSettings && classes.addToolButtonInEventSettings
                                    )}
                                    variant="tertiary"
                                    size="smallest"
                                    onClick={onAddSpeaker.bind(null, agendaItem.id)}
                                >
                                    <SpeakerIcon className="mr-4" /> Presenter
                                </Button>
                            ) : null}

                            {!readOnly && isLocked && agendaItem.locked ? (
                                <AgendaItemUserEditing userEditingId={agendaItem.locked} />
                            ) : null}

                            {!isLocked && (
                                <AgendaBoxSpeakerNotesToggler
                                    readOnly={readOnly}
                                    speakerNotesJson={agendaItem.speakerNotesJson}
                                    agendaItemId={agendaItem.id}
                                />
                            )}
                        </div>
                    </div>

                    <div className={classes.agendaTimeWrapper}>
                        <AgendaItemTime
                            onFocus={handleFocusAgendaItem}
                            onBlur={commitAfterUpdate}
                            duration={agendaItem.duration ?? 0}
                            id={agendaItem.id}
                            readOnly={readOnly}
                            locked={isLocked}
                            inEventSettings={inEventSettings}
                            hideTimeControls={hideTime}
                        />
                    </div>
                </div>
                {!isLocked && (
                    <AgendaBoxSpeakerNotes
                        readOnly={readOnly}
                        speakerNotesJson={agendaItem.speakerNotesJson}
                        agendaItemId={agendaItem.id}
                        onFocus={handleFocusAgendaItem}
                        onBlur={commitAfterUpdate}
                        inEventSettings={inEventSettings}
                        withseparator
                        isSetupMode
                        standalone={true}
                        isAgendaTemplates={true}
                    />
                )}
            </div>

            <AgendaItemDropZone
                scrollContainerRef={scrollContainerRef}
                afterArtifactId={agendaItem?.id}
                isDragging={isDragging}
            ></AgendaItemDropZone>
        </>
    );
};

export default memo(AgendaItemEditor);
