import {updateUnreadMessagesCount} from "@artifacts/chat/frontend/utils";
import {updateNewQuestionsCount} from "@artifacts/qa/frontend/utils";
import apollo from "@workhorse/api/apollo";
import {useCallback} from "@workhorse/api/rendering";
import {skipPreJoinId} from "@workhorse/pages/player/pre-join-screen/PreJoinScreenEntry";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {useHistory} from "react-router-dom";

export {useConfigApi as useBreakoutConfigApi} from "../ui/hooks/useConfigApi";

export function useBreakoutNavigation() {
    const {replace} = useHistory();
    const sessionId = useSessionIdFromRoute();

    return useCallback(
        (roomId: string, dontUseCachedData?: boolean, fromChild?: boolean) => {
            if (sessionId) {
                updateUnreadMessagesCount(sessionId, undefined, true);
            }
            updateNewQuestionsCount(true);

            apollo.cache.evict({id: roomId});
            apollo.cache.gc();

            replace("/session/" + roomId, {
                breakoutTimeoutDisabled: false,
                skipPreJoin: skipPreJoinId,
                dontUseCachedData,
                fromChild,
            });
        },
        [replace]
    );
}
