import PageNotFound from "../base/components/PageNotFound";

const UnableToJoin = () => {
    return (
        <div className="fullw flex flex-col flex-center-all full-fixed">
            <PageNotFound
                title="Sorry, you can’t join this session"
                secondaryTitle="Your host reached their maximum number of participants"
                body=""
            />
        </div>
    );
};

export default UnableToJoin;
