// @ts-nocheck We assume codegen is safe
import {TypedDocumentNode as DocumentNode} from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: Date;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    Json: any;
    ArtifactTag: GeneratedOverrides.ArtifactTag;
    UntypedArtifactData: any;
    Any: any;
    BigInt: any;
    Byte: any;
    Currency: any;
    Duration: any;
    EmailAddress: any;
    Function: (...args: any[]) => any;
    GUID: any;
    HSL: any;
    HSLA: any;
    HexColorCode: any;
    IPv4: any;
    IPv6: any;
    ISBN: any;
    JSON: any;
    JSONObject: any;
    /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
    JWT: any;
    LocalDate: any;
    LocalEndTime: any;
    LocalTime: any;
    Long: any;
    MAC: any;
    NegativeFloat: any;
    NegativeInt: any;
    NonEmptyString: any;
    NonNegativeFloat: any;
    NonNegativeInt: any;
    NonPositiveFloat: any;
    NonPositiveInt: any;
    Object: any;
    ObjectID: any;
    PhoneNumber: any;
    Port: any;
    PositiveFloat: any;
    PositiveInt: any;
    PostalCode: any;
    RGB: any;
    RGBA: any;
    SafeInt: any;
    Time: any;
    URL: any;
    USCurrency: any;
    UUID: any;
    UnsignedFloat: any;
    UnsignedInt: any;
    UtcOffset: any;
    Void: any;
};

export type AccessListItem = {
    __typename?: "AccessListItem";
    agendatemplateId?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    resourceId?: Maybe<Scalars["String"]>;
    user: User;
    userId: Scalars["String"];
};

export type AccessListItemCreateInput = {
    agendaTemplate?: Maybe<ConnectOneInput>;
    resource?: Maybe<ConnectOneInput>;
    user: UserConnectInput;
};

export type AccessListItemWhereInput = {
    agendatemplateId?: Maybe<StringNullableFilter>;
    resourceId?: Maybe<StringNullableFilter>;
};

export type AddMemberToOrganizationPayload = {
    country?: Maybe<Scalars["String"]>;
    department?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    sendInviteEmail?: Maybe<Scalars["Boolean"]>;
    workspaceIds: Array<Scalars["String"]>;
};

export type AddMemberToOrganizationResponse = {
    __typename?: "AddMemberToOrganizationResponse";
    duplicatedEmail?: Maybe<Scalars["Boolean"]>;
    newMember?: Maybe<User>;
};

export type AfterRegistrationOffer = {
    connect?: Maybe<WhereIdInput>;
    disconnect?: Maybe<Scalars["Boolean"]>;
};

export type AgendaItem = {
    __typename?: "AgendaItem";
    agendaItemSpeakers: Array<AgendaItemSpeaker>;
    agendaTemplate?: Maybe<AgendaTemplate>;
    artifact?: Maybe<Artifact>;
    createdAt: Scalars["DateTime"];
    descriptionJson?: Maybe<Scalars["Json"]>;
    duration?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isPaused?: Maybe<Scalars["Boolean"]>;
    locked?: Maybe<Scalars["String"]>;
    oldId?: Maybe<Scalars["String"]>;
    order: Scalars["Int"];
    processing: Scalars["Boolean"];
    speakerNotes?: Maybe<Scalars["String"]>;
    speakerNotesJson?: Maybe<Scalars["Json"]>;
    startAt?: Maybe<Scalars["DateTime"]>;
    timeSpentInSeconds?: Maybe<Scalars["Int"]>;
    title?: Maybe<Scalars["String"]>;
    type: AgendaItemType;
    update?: Maybe<AgendaItemDiff>;
    updatedAt: Scalars["DateTime"];
    userConfiguredDuration: Scalars["Boolean"];
};

export type AgendaItemCreateInput = {
    agendaItemSpeakers?: Maybe<AgendaItemSpeakerCreateManyWithoutAgendaItemInput>;
    artifact?: Maybe<ArtifactCreateOneInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descriptionJson?: Maybe<Scalars["Json"]>;
    duration?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    locked?: Maybe<Scalars["String"]>;
    order: Scalars["Int"];
    processing?: Maybe<Scalars["Boolean"]>;
    speakerNotes?: Maybe<Scalars["String"]>;
    speakerNotesJson?: Maybe<Scalars["Json"]>;
    startAt?: Maybe<Scalars["DateTime"]>;
    timeSpentInSeconds?: Maybe<Scalars["Int"]>;
    title?: Maybe<Scalars["String"]>;
    type: AgendaItemType;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    userConfiguredDuration?: Maybe<Scalars["Boolean"]>;
};

export type AgendaItemCreateManyInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<AgendaItemCreateInput>>;
};

export type AgendaItemDiff = {
    __typename?: "AgendaItemDiff";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descriptionJson?: Maybe<Scalars["Json"]>;
    duration?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    newId?: Maybe<Scalars["ID"]>;
    isPaused?: Maybe<Scalars["Boolean"]>;
    locked?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    processing?: Maybe<Scalars["Boolean"]>;
    speakerNotes?: Maybe<Scalars["String"]>;
    speakerNotesJson?: Maybe<Scalars["Json"]>;
    startAt?: Maybe<Scalars["DateTime"]>;
    timeSpentInSeconds?: Maybe<Scalars["Int"]>;
    title?: Maybe<Scalars["String"]>;
    type?: Maybe<AgendaItemType>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    userConfiguredDuration?: Maybe<Scalars["Boolean"]>;
};

export type AgendaItemSpeaker = {
    __typename?: "AgendaItemSpeaker";
    agendaItem: AgendaItem;
    agendaItemId: Scalars["String"];
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    oldId?: Maybe<Scalars["String"]>;
    speaker: SessionSpeaker;
    speakerId: Scalars["String"];
    update?: Maybe<AgendaItemSpeakerDiff>;
    updatedAt: Scalars["DateTime"];
};

export type AgendaItemSpeakerCreateInput = {
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    speaker: SessionSpeakerCreateOneWithoutAgendaItemSpeakersInput;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AgendaItemSpeakerCreateManyWithoutAgendaItemInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<AgendaItemSpeakerCreateInput>>;
};

export type AgendaItemSpeakerDiff = {
    __typename?: "AgendaItemSpeakerDiff";
    agendaItemId?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    newId?: Maybe<Scalars["ID"]>;
    speakerId?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AgendaItemSpeakerUpdateManyInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<AgendaItemSpeakerCreateInput>>;
    delete?: Maybe<Array<WhereIdInput>>;
};

export enum AgendaItemType {
    Instant = "INSTANT",
    Planned = "PLANNED",
}

export type AgendaItemUpdateDataInput = {
    agendaItemSpeakers?: Maybe<AgendaItemSpeakerUpdateManyInput>;
    artifact?: Maybe<ArtifactUpdateOneInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    descriptionJson?: Maybe<Scalars["Json"]>;
    duration?: Maybe<NullableIntFieldUpdateOperationsInput>;
    endedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    locked?: Maybe<NullableStringFieldUpdateOperationsInput>;
    order?: Maybe<IntFieldUpdateOperationsInput>;
    processing?: Maybe<BoolFieldUpdateOperationsInput>;
    speakerNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
    speakerNotesJson?: Maybe<Scalars["Json"]>;
    startAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    timeSpentInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
    title?: Maybe<NullableStringFieldUpdateOperationsInput>;
    type?: Maybe<AgendaItemType>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    userConfiguredDuration?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type AgendaItemUpdateManyInput = {
    create?: Maybe<Array<AgendaItemCreateInput>>;
    delete?: Maybe<Array<WhereIdInput>>;
    update?: Maybe<Array<AgendaItemUpdateWithWhereUniqueInput>>;
};

export type AgendaItemUpdateWithWhereUniqueInput = {
    data: AgendaItemUpdateDataInput;
    where: WhereIdInput;
};

export type AgendaItemWhereInput = {
    id?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    sessionId?: Maybe<Scalars["String"]>;
};

export type AgendaTemplate = {
    __typename?: "AgendaTemplate";
    accessList: Array<AccessListItem>;
    agendaItems: Array<AgendaItem>;
    createdAt: Scalars["DateTime"];
    description?: Maybe<Scalars["String"]>;
    duration: Scalars["Int"];
    hasEditorAccess: Scalars["Boolean"];
    id: Scalars["ID"];
    isPublic: Scalars["Boolean"];
    isReadOnly?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    user: User;
    userId: Scalars["String"];
    workspaceId: Scalars["String"];
};

export type AgendaTemplateCreateInput = {
    agendaItems?: Maybe<AgendaItemCreateManyInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    user: UserConnectInput;
};

export type AgendaTemplateUpdateInput = {
    agendaItems?: Maybe<AgendaItemUpdateManyInput>;
    description?: Maybe<NullableStringFieldUpdateOperationsInput>;
    isPublic?: Maybe<NullableBoolFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AgendaTemplateUpdateOneWithoutSessionsInput = {
    connect?: Maybe<WhereIdInput>;
    disconnect?: Maybe<Scalars["Boolean"]>;
};

export type AgendaTemplateWatchdogResult = {
    __typename?: "AgendaTemplateWatchdogResult";
    agendaTemplate?: Maybe<AgendaTemplate>;
    deletedAgendaItemIds?: Maybe<Array<Scalars["String"]>>;
    deletedAgendaTemplate?: Maybe<Scalars["Boolean"]>;
    id: Scalars["String"];
    upsertAgendaItems?: Maybe<Array<AgendaItem>>;
};

export type AppleExternalProvider = {
    __typename?: "AppleExternalProvider";
    accessToken: Scalars["String"];
    email: Scalars["String"];
    id: Scalars["ID"];
    name: Scalars["String"];
    principal: Scalars["String"];
    rootUrl: Scalars["String"];
    tokenType: Scalars["String"];
    user: User;
};

export type AppleSyncedEmailData = {
    __typename?: "AppleSyncedEmailData";
    calendarProviderId?: Maybe<Scalars["String"]>;
    calendars?: Maybe<Array<ExternalCalendar>>;
    email?: Maybe<Scalars["String"]>;
};

export type ApplicationState = {
    __typename?: "ApplicationState";
    id: Scalars["ID"];
    state: Scalars["Json"];
    updatedAt: Scalars["DateTime"];
};

export type ApprovalRequestsAsOwnerResult = {
    __typename?: "ApprovalRequestsAsOwnerResult";
    requestForParticipantIdResolved?: Maybe<Scalars["String"]>;
    requestingParticipant?: Maybe<Participant>;
};

export type Artifact = {
    __typename?: "Artifact";
    agendaItems: Array<AgendaItem>;
    artifactId: Scalars["ArtifactTag"];
    breakoutRoomsConfig?: Maybe<BreakoutRoomsConfiguration>;
    createdAt: Scalars["DateTime"];
    data?: Maybe<Scalars["UntypedArtifactData"]>;
    deniedSpeakerUserIds: Array<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    descriptor?: Maybe<ArtifactDescriptor>;
    durationInSeconds?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    isConfigured?: Maybe<Scalars["Boolean"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isMacro: Scalars["Boolean"];
    isSystemArtifact: Scalars["Boolean"];
    name: Scalars["String"];
    oldId?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    properties: Array<Property>;
    resourceResults: Array<ResourceResult>;
    startAt?: Maybe<Scalars["DateTime"]>;
    update?: Maybe<ArtifactDiff>;
    updatedAt: Scalars["DateTime"];
    userConfiguredDuration: Scalars["Boolean"];
};

export type ArtifactCreatedeniedSpeakerUserIdsInput = {
    set?: Maybe<Array<Scalars["String"]>>;
};

export type ArtifactCreateInput = {
    artifactId: Scalars["String"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    deniedSpeakerUserIds?: Maybe<ArtifactCreatedeniedSpeakerUserIdsInput>;
    description?: Maybe<Scalars["String"]>;
    durationInSeconds?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    isConfigured?: Maybe<Scalars["Boolean"]>;
    isMacro?: Maybe<Scalars["Boolean"]>;
    isSystemArtifact?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    order?: Maybe<Scalars["Int"]>;
    properties?: Maybe<PropertyCreateManyWithoutArtifactInput>;
    resourceResults?: Maybe<ResourceResultUpdateManyInput>;
    startAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    userConfiguredDuration?: Maybe<Scalars["Boolean"]>;
};

export type ArtifactCreateManyInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<ArtifactCreateInput>>;
};

export type ArtifactCreateOneInput = {
    connect?: Maybe<WhereIdInput>;
    create?: Maybe<ArtifactCreateInput>;
};

export type ArtifactdeniedSpeakerUserIdsInput = {
    set?: Maybe<Array<Scalars["String"]>>;
};

export type ArtifactDescriptor = {
    __typename?: "ArtifactDescriptor";
    targetApiVersion?: Maybe<Scalars["String"]>;
    tag?: Maybe<Scalars["String"]>;
    meta?: Maybe<ArtifactMetaDescriptor>;
    capabilities?: Maybe<CapabilityDescriptor>;
    dependencies?: Maybe<DependencyDescriptor>;
    supportedTypes?: Maybe<SupportedTypesDescriptor>;
    entries?: Maybe<Array<Maybe<ClientEntryDescriptor>>>;
    ghosts?: Maybe<Array<Maybe<ClientEntryDescriptor>>>;
    featureTags?: Maybe<Array<Maybe<ArtifactFeatureTag>>>;
};

export type ArtifactDiff = {
    __typename?: "ArtifactDiff";
    artifactId?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    deniedSpeakerUserIds?: Maybe<Array<Scalars["String"]>>;
    description?: Maybe<Scalars["String"]>;
    durationInSeconds?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    newId?: Maybe<Scalars["ID"]>;
    isConfigured?: Maybe<Scalars["Boolean"]>;
    isMacro?: Maybe<Scalars["Boolean"]>;
    isSystemArtifact?: Maybe<Scalars["Boolean"]>;
    name?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    startAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    userConfiguredDuration?: Maybe<Scalars["Boolean"]>;
};

export type ArtifactFeatureTag = {
    __typename?: "ArtifactFeatureTag";
    type: Scalars["String"];
    tag: Scalars["String"];
    text: Scalars["String"];
};

export type ArtifactMetaAuthorDescriptor = {
    __typename?: "ArtifactMetaAuthorDescriptor";
    vendor?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
};

export type ArtifactMetaDescriptor = {
    __typename?: "ArtifactMetaDescriptor";
    name?: Maybe<Scalars["String"]>;
    displayName?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    version?: Maybe<Scalars["String"]>;
    author?: Maybe<ArtifactMetaAuthorDescriptor>;
    icon?: Maybe<Scalars["String"]>;
};

export type ArtifactsConversionJob = {
    __typename?: "ArtifactsConversionJob";
    artifactId: Scalars["String"];
    fileId: Scalars["String"];
    completed?: Maybe<Scalars["Boolean"]>;
    url: Scalars["String"];
};

export type ArtifactUpdateInput = {
    artifactId?: Maybe<StringFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    deniedSpeakerUserIds?: Maybe<ArtifactdeniedSpeakerUserIdsInput>;
    description?: Maybe<NullableStringFieldUpdateOperationsInput>;
    durationInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
    endedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    isConfigured?: Maybe<NullableBoolFieldUpdateOperationsInput>;
    isMacro?: Maybe<BoolFieldUpdateOperationsInput>;
    isSystemArtifact?: Maybe<BoolFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    order?: Maybe<NullableIntFieldUpdateOperationsInput>;
    properties?: Maybe<PropertyUpdateManyWithoutArtifactInput>;
    resourceResults?: Maybe<ResourceResultUpdateManyInput>;
    startAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    userConfiguredDuration?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ArtifactUpdateManyWithoutMacroArtifactForSessionInput = {
    create?: Maybe<Array<ArtifactCreateInput>>;
    delete?: Maybe<Array<WhereIdInput>>;
    update?: Maybe<Array<ArtifactUpdateWithWhereUniqueInput>>;
};

export type ArtifactUpdateOneInput = {
    create?: Maybe<ArtifactCreateInput>;
    delete?: Maybe<Scalars["Boolean"]>;
    update?: Maybe<ArtifactUpdateInput>;
};

export type ArtifactUpdateWithWhereUniqueInput = {
    data: ArtifactUpdateInput;
    where: WhereIdInput;
};

export enum AudienceMobileOpenState {
    Closed = "CLOSED",
    SemiOpen = "SEMI_OPEN",
    FullyOpen = "FULLY_OPEN",
}

export enum BillingPeriod {
    Monthly = "MONTHLY",
    Yearly = "YEARLY",
}

export enum BlockType {
    Micro = "MICRO",
    Macro = "MACRO",
}

export type BookedEvent = {
    __typename?: "BookedEvent";
    additionalInformation?: Maybe<Scalars["Json"]>;
    afterRegistrationOffer?: Maybe<Offer>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    checkoutStatus?: Maybe<CheckoutSessionStatus>;
    checkoutUrl?: Maybe<Scalars["String"]>;
    duration?: Maybe<Scalars["Int"]>;
    event?: Maybe<PublicBookingEvent>;
    guests?: Maybe<Array<Maybe<Scalars["String"]>>>;
    id?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    participantEmail?: Maybe<Scalars["String"]>;
    participantName?: Maybe<Scalars["String"]>;
    questionnare?: Maybe<Scalars["Json"]>;
    requestPayment?: Maybe<Scalars["Boolean"]>;
    /** The ID of the booked event session */
    sessionId: Scalars["String"];
    sessionLifecycle: SessionLifecycle;
    startAt: Scalars["DateTime"];
    status?: Maybe<Scalars["String"]>;
    timeZone?: Maybe<Scalars["String"]>;
    /** The guest token */
    token?: Maybe<Scalars["String"]>;
};

export type Booking = {
    __typename?: "Booking";
    additionalInformation: Scalars["Json"];
    assignee?: Maybe<BookingCollaborator>;
    event: BookingEvent;
    guests: Array<Scalars["String"]>;
    id: Scalars["ID"];
    name: Scalars["String"];
    participantEmail: Scalars["String"];
    participantName: Scalars["String"];
    paymentTransaction?: Maybe<PaymentTransaction>;
    questionnare?: Maybe<Scalars["Json"]>;
    seen: Scalars["Boolean"];
    session?: Maybe<Session>;
};

export enum BookingCollaborationDistribution {
    Availability = "AVAILABILITY",
    Priority = "PRIORITY",
}

export type BookingCollaborator = {
    __typename?: "BookingCollaborator";
    bookingEvent: BookingEvent;
    bookings: Array<Booking>;
    bookingsCount?: Maybe<Scalars["Int"]>;
    enabled: Scalars["Boolean"];
    externalCalendarConnections: Scalars["Json"];
    id: Scalars["ID"];
    isOwner?: Maybe<Scalars["Boolean"]>;
    priority?: Maybe<Scalars["Int"]>;
    rbac: Scalars["Json"];
    schedule?: Maybe<BookingSchedule>;
    user: User;
};

export type BookingEvent = {
    __typename?: "BookingEvent";
    additionalDuration: Array<Scalars["Int"]>;
    additionalRules?: Maybe<Scalars["Json"]>;
    afterRegistrationOffer?: Maybe<Offer>;
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    agenda?: Maybe<AgendaTemplate>;
    allowAdditionalGuests: Scalars["Boolean"];
    booking: Array<Booking>;
    bookingsCount: Scalars["Int"];
    buffer?: Maybe<Scalars["Json"]>;
    collaborationDistribution: BookingCollaborationDistribution;
    collaborators: Array<BookingCollaborator>;
    description?: Maybe<Scalars["String"]>;
    disabled: Scalars["Boolean"];
    duration: Scalars["Int"];
    from: Scalars["String"];
    id: Scalars["ID"];
    ignoreSharedCalendars: Scalars["Boolean"];
    isListed: Scalars["Boolean"];
    name: Scalars["String"];
    owner: User;
    payments: Array<PaymentConfig>;
    questionnare?: Maybe<Scalars["Json"]>;
    schedule?: Maybe<BookingSchedule>;
    scheduleSource?: Maybe<BookingEventScheduleSource>;
    scheduleWindow?: Maybe<Scalars["Int"]>;
    seen: Scalars["Boolean"];
    settings?: Maybe<Scalars["Json"]>;
    slug?: Maybe<Scalars["String"]>;
    temporarySchedule?: Maybe<BookingSchedule>;
    timeZone?: Maybe<Scalars["String"]>;
    to?: Maybe<Scalars["String"]>;
    workspaceId: Scalars["String"];
};

export type BookingEventContact = {
    __typename?: "BookingEventContact";
    attendance: BookingEventContactAttendanceEnum;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
    isGuest: Scalars["Boolean"];
    isOwner: Scalars["Boolean"];
    lastName?: Maybe<Scalars["String"]>;
    participantType: BookingEventContactParticipantType;
    registrationAnswers: Array<BookingEventContactRegistrationAnswer>;
    sessionDate?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
    sessionStatus: BookingEventContactSessionStatus;
};

export enum BookingEventContactAttendanceEnum {
    Attended = "Attended",
    NotAttended = "NotAttended",
}

export enum BookingEventContactParticipantType {
    Guest = "guest",
    Main = "main",
}

export type BookingEventContactRegistrationAnswer = {
    __typename?: "BookingEventContactRegistrationAnswer";
    key: Scalars["String"];
    value: Scalars["String"];
};

export enum BookingEventContactSessionStatus {
    Ended = "Ended",
    Upcoming = "Upcoming",
}

export type BookingEventCreateInput = {
    additionalDuration?: Maybe<Array<Scalars["Int"]>>;
    additionalRules?: Maybe<Scalars["Json"]>;
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    agendaId?: Maybe<Scalars["ID"]>;
    allowAdditionalGuests?: Maybe<Scalars["Boolean"]>;
    buffer?: Maybe<Scalars["Json"]>;
    collaborationDistribution?: Maybe<BookingCollaborationDistribution>;
    description?: Maybe<Scalars["String"]>;
    disabled?: Maybe<Scalars["Boolean"]>;
    duration: Scalars["Int"];
    from: Scalars["String"];
    ignoreSharedCalendars?: Maybe<Scalars["Boolean"]>;
    isListed?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    questionnare?: Maybe<Scalars["Json"]>;
    scheduleId?: Maybe<Scalars["ID"]>;
    scheduleWindow?: Maybe<Scalars["Int"]>;
    settings: Scalars["Json"];
    slug: Scalars["String"];
    temporaryScheduleId?: Maybe<Scalars["ID"]>;
    timeZone: Scalars["String"];
    to?: Maybe<Scalars["String"]>;
};

export enum BookingEventScheduleSource {
    Collaborator = "COLLABORATOR",
    Default = "DEFAULT",
}

export type BookingEventsCount = {
    __typename?: "BookingEventsCount";
    all: Scalars["Int"];
    disabled: Scalars["Int"];
    id?: Maybe<Scalars["ID"]>;
};

export type BookingEventUpdateEvent = {
    __typename?: "BookingEventUpdateEvent";
    bookingEventId: Scalars["String"];
    updatedBy: Scalars["String"];
};

export type BookingEventUpdateInput = {
    additionalDuration?: Maybe<Array<Scalars["Int"]>>;
    additionalRules?: Maybe<Scalars["Json"]>;
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    agendaId?: Maybe<Scalars["ID"]>;
    allowAdditionalGuests?: Maybe<Scalars["Boolean"]>;
    buffer?: Maybe<Scalars["Json"]>;
    collaborationDistribution?: Maybe<BookingCollaborationDistribution>;
    description?: Maybe<Scalars["String"]>;
    disabled?: Maybe<Scalars["Boolean"]>;
    duration?: Maybe<Scalars["Int"]>;
    from?: Maybe<Scalars["String"]>;
    ignoreSharedCalendars?: Maybe<Scalars["Boolean"]>;
    isListed?: Maybe<Scalars["Boolean"]>;
    name?: Maybe<Scalars["String"]>;
    questionnare?: Maybe<Scalars["Json"]>;
    scheduleId?: Maybe<Scalars["ID"]>;
    scheduleSource?: Maybe<BookingEventScheduleSource>;
    scheduleWindow?: Maybe<Scalars["Int"]>;
    settings?: Maybe<Scalars["Json"]>;
    slug?: Maybe<Scalars["String"]>;
    temporaryScheduleId?: Maybe<Scalars["ID"]>;
    timeZone?: Maybe<Scalars["String"]>;
    to?: Maybe<Scalars["String"]>;
};

export type BookingEventWhereInput = {
    id?: Maybe<StringFilter>;
};

export type BookingFormAnswers = {
    __typename?: "BookingFormAnswers";
    answers?: Maybe<Scalars["Json"]>;
    form?: Maybe<Scalars["Json"]>;
};

export type BookingSchedule = {
    __typename?: "BookingSchedule";
    createdBy: User;
    days: Scalars["Json"];
    default: Scalars["Boolean"];
    hours: Scalars["Json"];
    id: Scalars["ID"];
    maxDuration?: Maybe<Scalars["Int"]>;
    name: Scalars["String"];
    temporary: Scalars["Boolean"];
    timeZone?: Maybe<Scalars["String"]>;
    usedBy?: Maybe<Array<BookingScheduleUsedBy>>;
};

export type BookingScheduleCreateInput = {
    days: Scalars["Json"];
    hours: Scalars["Json"];
    name: Scalars["String"];
    temporary?: Maybe<Scalars["Boolean"]>;
    temporaryForEventId?: Maybe<Scalars["ID"]>;
    timeZone?: Maybe<Scalars["String"]>;
};

export type BookingScheduleUpdateInput = {
    days?: Maybe<Scalars["Json"]>;
    hours?: Maybe<Scalars["Json"]>;
    name?: Maybe<Scalars["String"]>;
    timeZone?: Maybe<Scalars["String"]>;
};

export type BookingScheduleUsedBy = {
    __typename?: "BookingScheduleUsedBy";
    eventId: Scalars["String"];
    eventName: Scalars["String"];
};

export type BookingScheduleWhereUniqueInput = {
    id?: Maybe<Scalars["String"]>;
};

export type BookingsCount = {
    __typename?: "BookingsCount";
    all: Scalars["Int"];
    canceled: Scalars["Int"];
    id?: Maybe<Scalars["ID"]>;
    upcoming: Scalars["Int"];
};

export type BookingSlugUniqueResult = {
    __typename?: "BookingSlugUniqueResult";
    isUnique?: Maybe<Scalars["Boolean"]>;
};

export type BookingWhereInput = {
    assigneeId?: Maybe<StringFilter>;
    event?: Maybe<BookingEventWhereInput>;
    session?: Maybe<SessionWhereInput>;
};

export type BoolFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["Boolean"]>;
};

export type BoolFilter = {
    equals?: Maybe<Scalars["Boolean"]>;
    not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
    equals?: Maybe<Scalars["Boolean"]>;
    not?: Maybe<NestedBoolNullableFilter>;
};

export type Branding = {
    __typename?: "Branding";
    active: Scalars["Boolean"];
    id: Scalars["ID"];
    logo?: Maybe<Scalars["String"]>;
    logomark?: Maybe<Scalars["String"]>;
    navbarTheme?: Maybe<Scalars["String"]>;
    palette: Scalars["Json"];
    primaryLogo?: Maybe<Scalars["String"]>;
    secondaryLogo?: Maybe<Scalars["String"]>;
    sessionBackground?: Maybe<Scalars["String"]>;
    sourcePalette: Scalars["Json"];
    styleTag: Scalars["String"];
    videoTileColor?: Maybe<Scalars["String"]>;
};

export type BrandingAsset = {
    __typename?: "BrandingAsset";
    publicUrl?: Maybe<Scalars["String"]>;
    uploadUrl?: Maybe<Scalars["String"]>;
};

export type BrandingCreateInput = {
    active: Scalars["Boolean"];
    favicon?: Maybe<Scalars["String"]>;
    logo?: Maybe<Scalars["String"]>;
    logomark?: Maybe<Scalars["String"]>;
    navbarTheme?: Maybe<Scalars["String"]>;
    palette: Scalars["Json"];
    sessionBackground?: Maybe<Scalars["String"]>;
    sourcePalette: Scalars["Json"];
    styleTag: Scalars["String"];
    videoTileColor?: Maybe<Scalars["String"]>;
    workspaceId?: Maybe<Scalars["String"]>;
};

export type BrandingUpdateInput = {
    active: Scalars["Boolean"];
    id: Scalars["String"];
    logo?: Maybe<Scalars["String"]>;
    logomark?: Maybe<Scalars["String"]>;
    navbarTheme?: Maybe<Scalars["String"]>;
    palette: Scalars["Json"];
    sessionBackground?: Maybe<Scalars["String"]>;
    sourcePalette: Scalars["Json"];
    styleTag: Scalars["String"];
    videoTileColor?: Maybe<Scalars["String"]>;
};

export type BrandingWhereInput = {
    id: Scalars["String"];
};

export type BreakoutRoomsConfiguration = {
    __typename?: "BreakoutRoomsConfiguration";
    allowRoomNavigation: Scalars["Boolean"];
    artifactIdOfBreakoutRooms: Scalars["String"];
    breakoutRooms: Array<Session>;
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    parentOfBreakoutRooms: Session;
    participantsMap: Array<BreakoutRoomsParticipantMap>;
    updatedAt: Scalars["DateTime"];
};

export type BreakoutRoomsParticipantMap = {
    __typename?: "BreakoutRoomsParticipantMap";
    createdAt: Scalars["DateTime"];
    desiredRoomId?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    participantId: Scalars["String"];
    participantInBreakoutRoom?: Maybe<Participant>;
    participantInParent: Participant;
    participantinbreakoutroom_participantId?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
};

export type CalendarCustomEvent = {
    __typename?: "CalendarCustomEvent";
    backstage?: Maybe<Scalars["Boolean"]>;
    currentParticipantInviteStatus: ParticipantInviteStatus;
    description?: Maybe<Scalars["String"]>;
    event?: Maybe<EventForEvent>;
    externalEvent?: Maybe<ExternalCalendarEvent>;
    id: Scalars["String"];
    instanceId?: Maybe<Scalars["String"]>;
    instanceOfRecurrence?: Maybe<InstanceOfRecurrenceForEvent>;
    isAssistant: Scalars["Boolean"];
    isBillable: Scalars["Boolean"];
    isBooking: Scalars["Boolean"];
    isEvent: Scalars["Boolean"];
    isOwner: Scalars["Boolean"];
    isRoom: Scalars["Boolean"];
    isTask?: Maybe<Scalars["Boolean"]>;
    lifecycle: SessionLifecycle;
    memoryAccessType: MemoryAccessType;
    name: Scalars["String"];
    occurrenceId?: Maybe<Scalars["DateTime"]>;
    organizationId?: Maybe<Scalars["String"]>;
    participants: Array<ParticipantForEvent>;
    plannedEnd: Scalars["DateTime"];
    quickSession: Scalars["Boolean"];
    recurrenceData?: Maybe<RecurrenceData>;
    recurrenceParentId?: Maybe<Scalars["String"]>;
    startAt: Scalars["DateTime"];
    timeZone?: Maybe<Scalars["String"]>;
    workspaceId?: Maybe<Scalars["String"]>;
};

export type CalendarNotificationResult = {
    __typename?: "CalendarNotificationResult";
    deleteSessionId?: Maybe<Scalars["String"]>;
    upsertSession?: Maybe<UpsertCalendarEvent>;
};

export enum CalendarProvider {
    Apple = "APPLE",
    Google = "GOOGLE",
    Microsoft = "MICROSOFT",
    Sessions = "SESSIONS",
}

export type CalendarSettings = {
    __typename?: "CalendarSettings";
    view: CalendarView;
    live: Scalars["Boolean"];
    invitations: Scalars["Boolean"];
    bookings: Scalars["Boolean"];
    events: Scalars["Boolean"];
    tasks?: Maybe<Scalars["Boolean"]>;
    externalCalendars: Array<ExternalCalendarFilter>;
};

export enum CalendarView {
    Days = "DAYS",
    Weeks = "WEEKS",
    Months = "MONTHS",
}

export type CancelBookedEventInput = {
    bookedEventId: Scalars["String"];
    participantId: Scalars["String"];
    reason?: Maybe<Scalars["String"]>;
    token?: Maybe<Scalars["String"]>;
};

export type CapabilityDescriptor = {
    __typename?: "CapabilityDescriptor";
    type?: Maybe<Scalars["String"]>;
};

export type ChangePasswordResult = {
    __typename?: "ChangePasswordResult";
    invalidPassword: Scalars["Boolean"];
    isSuccess: Scalars["Boolean"];
};

export type ChatFilesSubscriptionPayload = {
    __typename?: "ChatFilesSubscriptionPayload";
    downloadLink: Scalars["String"];
    id: Scalars["String"];
};

export type ChatGroup = {
    __typename?: "ChatGroup";
    clientOnly: Scalars["Boolean"];
    createdAt: Scalars["DateTime"];
    fetchedMessagesOverNetwork: Scalars["Boolean"];
    id: Scalars["ID"];
    messages: Array<ChatMessage>;
    participants: Array<Participant>;
    unreadCount: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type ChatGroupMessagesArgs = {
    orderBy?: Maybe<CreatedAtOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    take?: Maybe<Scalars["Int"]>;
};

export type ChatMessage = {
    __typename?: "ChatMessage";
    body: Scalars["String"];
    chatGroupId?: Maybe<Scalars["String"]>;
    chatmessageId?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    globalMessage?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    participant: Participant;
    participantId: Scalars["String"];
    replyToMessage?: Maybe<ChatMessage>;
    updatedAt: Scalars["DateTime"];
};

export type ChatMessageReplySubscriptionPayload = {
    __typename?: "ChatMessageReplySubscriptionPayload";
    body: Scalars["String"];
    createdAt: Scalars["DateTime"];
    files: Array<ChatFilesSubscriptionPayload>;
    id: Scalars["String"];
    participant: ParticipantSubscriptionPayload;
};

export type ChatMessageSubscriptionPayload = {
    __typename?: "ChatMessageSubscriptionPayload";
    body: Scalars["String"];
    createdAt: Scalars["DateTime"];
    files: Array<ChatFilesSubscriptionPayload>;
    id: Scalars["String"];
    participant: ParticipantSubscriptionPayload;
    replyToMessage?: Maybe<ChatMessageReplySubscriptionPayload>;
};

export type ChatMessageWhereInput = {
    NOT?: Maybe<Array<ChatMessageWhereInput>>;
    OR?: Maybe<Array<ChatMessageWhereInput>>;
    chatGroupId?: Maybe<StringNullableFilter>;
    chatmessageId?: Maybe<StringNullableFilter>;
    globalMessage?: Maybe<BoolNullableFilter>;
    id?: Maybe<StringFilter>;
    participantId?: Maybe<StringFilter>;
    sessionId?: Maybe<StringFilter>;
};

export type ChatUpdateWatchdogResult = {
    __typename?: "ChatUpdateWatchdogResult";
    deleteChatFile?: Maybe<DeleteChatFile>;
    deleteChatMessage?: Maybe<DeleteChatMessage>;
    newChatFile?: Maybe<MessageFile>;
    newChatGroup?: Maybe<ChatGroup>;
    newChatMessage?: Maybe<ChatMessage>;
};

export enum CheckGuestCodeOutcome {
    CodeAlreadyUsed = "CODE_ALREADY_USED",
    NotValid = "NOT_VALID",
    ResetToken = "RESET_TOKEN",
    SessionEnded = "SESSION_ENDED",
    Valid = "VALID",
    ValidButNotForHim = "VALID_BUT_NOT_FOR_HIM",
    ValidNeedInfo = "VALID_NEED_INFO",
    ValidRedirectToSession = "VALID_REDIRECT_TO_SESSION",
}

export type CheckGuestCodeResult = {
    __typename?: "CheckGuestCodeResult";
    hasMemoryAccess?: Maybe<Scalars["Boolean"]>;
    outcome: CheckGuestCodeOutcome;
    sessionId?: Maybe<Scalars["String"]>;
    sessionLifecycle?: Maybe<SessionLifecycle>;
};

export enum CheckoutSessionStatus {
    CheckoutCompleted = "CHECKOUT_COMPLETED",
    CheckoutExpired = "CHECKOUT_EXPIRED",
    CheckoutInitiated = "CHECKOUT_INITIATED",
}

export type CheckRegistrationResponse = {
    __typename?: "CheckRegistrationResponse";
    email?: Maybe<Scalars["String"]>;
    isSpeaker?: Maybe<Scalars["Boolean"]>;
    registered: Scalars["Boolean"];
    registrationId?: Maybe<Scalars["String"]>;
    requireLogin?: Maybe<Scalars["Boolean"]>;
    requirePayment?: Maybe<Scalars["Boolean"]>;
};

export type CheckSessionExistsResult = {
    __typename?: "CheckSessionExistsResult";
    askForGuestEmail?: Maybe<Scalars["Boolean"]>;
    eventSlug?: Maybe<Scalars["String"]>;
    exists?: Maybe<Scalars["Boolean"]>;
    guestAuthIsValid?: Maybe<Scalars["Boolean"]>;
    isParentOfRecurrence?: Maybe<Scalars["Boolean"]>;
    participantId?: Maybe<Scalars["String"]>;
    sessionLifecycle?: Maybe<SessionLifecycle>;
};

export enum ChooseToolStep {
    SelectTool = "SELECT_TOOL",
    Config = "CONFIG",
    Preview = "PREVIEW",
    ManageTool = "MANAGE_TOOL",
}

export type ClientEntryDescriptor = {
    __typename?: "ClientEntryDescriptor";
    id?: Maybe<Scalars["String"]>;
    preloadCondition?: Maybe<Scalars["String"]>;
};

export type ClonePaymentConfigInput = {
    amount?: Maybe<Scalars["Int"]>;
    currency?: Maybe<Scalars["String"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    id: Scalars["String"];
    ownerId: Scalars["String"];
    providers?: Maybe<PaymentsConfigConnectProvidersInput>;
    terms?: Maybe<Scalars["String"]>;
};

export type ClonePaymentsCreateInput = {
    create: Array<ClonePaymentConfigInput>;
};

export type Cname = {
    __typename?: "Cname";
    cnameForWorkspaces: Array<Workspace>;
    domain: Scalars["String"];
    favicon?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    status: Scalars["String"];
    target: Scalars["String"];
};

export enum CollectFeedbackType {
    None = "NONE",
    OwnerFeedback = "OWNER_FEEDBACK",
    SessionsFeedback = "SESSIONS_FEEDBACK",
}

export enum ConferenceParticipantStatus {
    InvitedToSpeak = "INVITED_TO_SPEAK",
    Participant = "PARTICIPANT",
    PendingSpeaker = "PENDING_SPEAKER",
    Speaker = "SPEAKER",
}

export type ConferenceToken = {
    __typename?: "ConferenceToken";
    lobby: Scalars["String"];
    main: Scalars["String"];
};

export type ConfigOption = {
    __typename?: "ConfigOption";
    id: Scalars["String"];
    module?: Maybe<Module>;
    key: Scalars["String"];
    value: Scalars["String"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type ConfigOptionCreateInput = {
    id?: Maybe<Scalars["String"]>;
    key: Scalars["String"];
    value: Scalars["String"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    module?: Maybe<ModuleCreateOneWithoutOptionsInput>;
};

export type ConfigOptionCreateManyWithoutModuleInput = {
    create?: Maybe<Array<ConfigOptionCreateWithoutModuleInput>>;
    connect?: Maybe<Array<ConfigOptionWhereUniqueInput>>;
};

export type ConfigOptionCreateWithoutModuleInput = {
    id?: Maybe<Scalars["String"]>;
    key: Scalars["String"];
    value: Scalars["String"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ConfigOptionListRelationFilter = {
    every?: Maybe<ConfigOptionWhereInput>;
    some?: Maybe<ConfigOptionWhereInput>;
    none?: Maybe<ConfigOptionWhereInput>;
};

export type ConfigOptionScalarWhereInput = {
    AND?: Maybe<Array<ConfigOptionScalarWhereInput>>;
    OR?: Maybe<Array<ConfigOptionScalarWhereInput>>;
    NOT?: Maybe<Array<ConfigOptionScalarWhereInput>>;
    id?: Maybe<StringFilter>;
    key?: Maybe<StringFilter>;
    value?: Maybe<StringFilter>;
    createdAt?: Maybe<DateTimeFilter>;
    updatedAt?: Maybe<DateTimeFilter>;
    moduleId?: Maybe<StringNullableFilter>;
};

export type ConfigOptionUpdateInput = {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    key?: Maybe<StringFieldUpdateOperationsInput>;
    value?: Maybe<StringFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    module?: Maybe<ModuleUpdateOneWithoutOptionsInput>;
};

export type ConfigOptionUpdateManyDataInput = {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    key?: Maybe<StringFieldUpdateOperationsInput>;
    value?: Maybe<StringFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConfigOptionUpdateManyWithoutModuleInput = {
    create?: Maybe<Array<ConfigOptionCreateWithoutModuleInput>>;
    connect?: Maybe<Array<ConfigOptionWhereUniqueInput>>;
    set?: Maybe<Array<ConfigOptionWhereUniqueInput>>;
    disconnect?: Maybe<Array<ConfigOptionWhereUniqueInput>>;
    delete?: Maybe<Array<ConfigOptionWhereUniqueInput>>;
    update?: Maybe<Array<ConfigOptionUpdateWithWhereUniqueWithoutModuleInput>>;
    updateMany?: Maybe<Array<ConfigOptionUpdateManyWithWhereNestedInput>>;
    deleteMany?: Maybe<Array<ConfigOptionScalarWhereInput>>;
    upsert?: Maybe<Array<ConfigOptionUpsertWithWhereUniqueWithoutModuleInput>>;
};

export type ConfigOptionUpdateManyWithWhereNestedInput = {
    where: ConfigOptionScalarWhereInput;
    data: ConfigOptionUpdateManyDataInput;
};

export type ConfigOptionUpdateWithoutModuleDataInput = {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    key?: Maybe<StringFieldUpdateOperationsInput>;
    value?: Maybe<StringFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConfigOptionUpdateWithWhereUniqueWithoutModuleInput = {
    where: ConfigOptionWhereUniqueInput;
    data: ConfigOptionUpdateWithoutModuleDataInput;
};

export type ConfigOptionUpsertWithWhereUniqueWithoutModuleInput = {
    where: ConfigOptionWhereUniqueInput;
    update: ConfigOptionUpdateWithoutModuleDataInput;
    create: ConfigOptionCreateWithoutModuleInput;
};

export type ConfigOptionWhereInput = {
    AND?: Maybe<Array<ConfigOptionWhereInput>>;
    OR?: Maybe<Array<ConfigOptionWhereInput>>;
    NOT?: Maybe<Array<ConfigOptionWhereInput>>;
    id?: Maybe<StringFilter>;
    key?: Maybe<StringFilter>;
    value?: Maybe<StringFilter>;
    createdAt?: Maybe<DateTimeFilter>;
    updatedAt?: Maybe<DateTimeFilter>;
    module?: Maybe<ModuleWhereInput>;
    moduleId?: Maybe<StringNullableFilter>;
};

export type ConfigOptionWhereUniqueInput = {
    id?: Maybe<Scalars["String"]>;
};

export enum ConfigurationConfirmType {
    Tool = "TOOL",
    Agenda = "AGENDA",
}

export enum ConfigurationStep {
    Empty = "EMPTY",
    Hover = "HOVER",
    Import = "IMPORT",
    Create = "CREATE",
    Preview = "PREVIEW",
    Edit = "EDIT",
}

export type ConnectOneInput = {
    connect?: Maybe<WhereIdInput>;
};

export type ContactCreateWithoutOwnerAndGroupInput = {
    company?: Maybe<Scalars["String"]>;
    companyRole?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
};

export type ContactGroupCreateWithoutOwnerAndGroupInput = {
    contactIds: Array<Scalars["String"]>;
    name: Scalars["String"];
};

export enum ContactGroupSource {
    App = "APP",
    Google = "GOOGLE",
    Microsoft = "MICROSOFT",
}

export type ContactGroupUpdateOneCustom = {
    contacts?: Maybe<ModifiedContacts>;
    name?: Maybe<Scalars["String"]>;
};

export enum ContactSource {
    App = "APP",
    Csv = "CSV",
    Google = "GOOGLE",
    Microsoft = "MICROSOFT",
}

export type ContactUpdateWithoutOwnerAndGroupInput = {
    company?: Maybe<StringFieldUpdateOperationsInput>;
    companyRole?: Maybe<StringFieldUpdateOperationsInput>;
    email: StringFieldUpdateOperationsInput;
    favorite?: Maybe<Scalars["Boolean"]>;
    firstName?: Maybe<StringFieldUpdateOperationsInput>;
    lastName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ContactWhereInput = {
    email?: Maybe<StringFilter>;
};

export type Content = {
    __typename?: "Content";
    isFullscreen: Scalars["Boolean"];
    audienceMobileOpenState: AudienceMobileOpenState;
};

export enum ControlStatus {
    Controlling = "CONTROLLING",
    Requesting = "REQUESTING",
}

export type Converting = {
    __typename?: "Converting";
    artifacts?: Maybe<Array<Maybe<ArtifactsConversionJob>>>;
};

export type CreateBookingCollaboratorInput = {
    bookingEventId: Scalars["String"];
    enabled?: Maybe<Scalars["Boolean"]>;
    isOwner?: Maybe<Scalars["Boolean"]>;
    priority?: Maybe<Scalars["Int"]>;
    rbac: Scalars["Json"];
    scheduleId?: Maybe<Scalars["String"]>;
    userId: Scalars["String"];
};

export type CreatedAtOrderByInput = {
    createdAt: SortOrder;
};

export type CreateFeedbackInput = {
    deviceInfo?: Maybe<Scalars["Json"]>;
    message?: Maybe<Scalars["String"]>;
    rating: Scalars["Int"];
    sessionId: Scalars["String"];
    tags?: Maybe<Array<Scalars["String"]>>;
    technicalDetails: Scalars["Json"];
};

export type CurrentHost = {
    __typename?: "CurrentHost";
    type?: Maybe<HostType>;
};

export type CustomBackground = {
    __typename?: "CustomBackground";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    updatedAt: Scalars["DateTime"];
    url: Scalars["String"];
    user: User;
};

export type CustomContact = {
    __typename?: "CustomContact";
    company?: Maybe<Scalars["String"]>;
    companyRole?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    externalId?: Maybe<Scalars["String"]>;
    externalSyncedEmail?: Maybe<Scalars["String"]>;
    favorite: Scalars["Boolean"];
    firstName: Scalars["String"];
    group: Array<CustomContactGroup>;
    id: Scalars["String"];
    inviteStatus?: Maybe<UserInviteStatus>;
    lastName: Scalars["String"];
    source: ContactSource;
    userProfilePicture?: Maybe<Scalars["String"]>;
    userSuspended: Scalars["Boolean"];
};

export type CustomContactGroup = {
    __typename?: "CustomContactGroup";
    externalId?: Maybe<Scalars["String"]>;
    externalSyncedEmail?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
    name: Scalars["String"];
    source: ContactGroupSource;
};

export type CustomEmail = {
    __typename?: "CustomEmail";
    body?: Maybe<Scalars["Json"]>;
    bodyTitle?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    customEmailContent: Array<CustomEmailContent>;
    disableSending: Scalars["Boolean"];
    id: Scalars["ID"];
    subject?: Maybe<Scalars["String"]>;
    templateId: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type CustomEmailContent = {
    __typename?: "CustomEmailContent";
    customEmailId: Scalars["String"];
    hidden: Scalars["Boolean"];
    id: Scalars["ID"];
    type: CustomEmailContentType;
    value: Scalars["String"];
};

export enum CustomEmailContentType {
    ButtonCtaCancel = "BUTTON_CTA_CANCEL",
    ButtonCtaPrimary = "BUTTON_CTA_PRIMARY",
    ButtonCtaSecondary = "BUTTON_CTA_SECONDARY",
    SectionAccesslink = "SECTION_ACCESSLINK",
    SectionAccesslinkContent1 = "SECTION_ACCESSLINK_CONTENT1",
    SectionAccesslinkContent2 = "SECTION_ACCESSLINK_CONTENT2",
    SectionAddtionalinfo = "SECTION_ADDTIONALINFO",
    SectionAddtionalinfoContent = "SECTION_ADDTIONALINFO_CONTENT",
    SectionCanceledBy = "SECTION_CANCELED_BY",
    SectionDescription = "SECTION_DESCRIPTION",
    SectionFooter = "SECTION_FOOTER",
    SectionFormanswers = "SECTION_FORMANSWERS",
    SectionSignature = "SECTION_SIGNATURE",
    SectionTerms = "SECTION_TERMS",
}

export type CustomEmailSignatureResult = {
    __typename?: "CustomEmailSignatureResult";
    footer?: Maybe<Scalars["Json"]>;
    signature?: Maybe<Scalars["Json"]>;
};

export type CustomMessageTemplate = {
    __typename?: "CustomMessageTemplate";
    body: Scalars["String"];
    config: Scalars["Json"];
    createdAt: Scalars["DateTime"];
    disableSending: Scalars["Boolean"];
    id: Scalars["ID"];
    name: Scalars["String"];
    templateId: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type CustomSmtpServer = {
    __typename?: "CustomSmtpServer";
    additionalConfig: Scalars["Json"];
    assignedToWorkspaces: Array<Workspace>;
    configType: CustomSmtpServerConfigType;
    createdAt: Scalars["DateTime"];
    enabled: Scalars["Boolean"];
    host: Scalars["String"];
    id: Scalars["ID"];
    name: Scalars["String"];
    organizationId: Scalars["String"];
    password: Scalars["String"];
    port: Scalars["Int"];
    senderDomain: Scalars["String"];
    senderEmail: Scalars["String"];
    senderName?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    username: Scalars["String"];
    verificationCode: Scalars["String"];
    verified: Scalars["Boolean"];
};

export enum CustomSmtpServerConfigType {
    ExternalSmtp = "EXTERNAL_SMTP",
    Spf = "SPF",
}

export type CustomSmtpSpfServerTxtData = {
    __typename?: "CustomSmtpSpfServerTxtData";
    host: Scalars["String"];
    value: Scalars["String"];
};

export type CustomSmtpSpfServerVerificationResponse = {
    __typename?: "CustomSmtpSpfServerVerificationResponse";
    mailjetVerification: Scalars["Boolean"];
    spfVerification: Scalars["Boolean"];
};

export type CustomSubDomainConfig = {
    __typename?: "CustomSubDomainConfig";
    domain: Scalars["String"];
    fqdn: Scalars["String"];
    id: Scalars["ID"];
    root: Scalars["String"];
    user?: Maybe<User>;
};

export type DateTimeFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["DateTime"]>;
};

export type DateTimeFilter = {
    equals?: Maybe<Scalars["DateTime"]>;
    gt?: Maybe<Scalars["DateTime"]>;
    gte?: Maybe<Scalars["DateTime"]>;
    in?: Maybe<Array<Scalars["DateTime"]>>;
    lt?: Maybe<Scalars["DateTime"]>;
    lte?: Maybe<Scalars["DateTime"]>;
    not?: Maybe<NestedDateTimeFilter>;
    notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableFilter = {
    equals?: Maybe<Scalars["DateTime"]>;
    gt?: Maybe<Scalars["DateTime"]>;
    gte?: Maybe<Scalars["DateTime"]>;
    in?: Maybe<Array<Scalars["DateTime"]>>;
    lt?: Maybe<Scalars["DateTime"]>;
    lte?: Maybe<Scalars["DateTime"]>;
    not?: Maybe<NestedDateTimeNullableFilter>;
    notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type DefaultEmailSender = {
    __typename?: "DefaultEmailSender";
    createdAt: Scalars["DateTime"];
    customSmtpServerId?: Maybe<Scalars["String"]>;
    externalProviderId?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    memberPermissionId: Scalars["String"];
    senderEmail: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DefaultMessageSender = {
    __typename?: "DefaultMessageSender";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    memberPermissionId: Scalars["String"];
    messageExternalProviderId: Scalars["String"];
    name: Scalars["String"];
    senderNumber: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeleteChatFile = {
    __typename?: "DeleteChatFile";
    chatGroupId?: Maybe<Scalars["String"]>;
    chatmessageId: Scalars["String"];
    id: Scalars["String"];
};

export type DeleteChatMessage = {
    __typename?: "DeleteChatMessage";
    chatGroupId?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
};

export type DeleteQuestion = {
    __typename?: "DeleteQuestion";
    id: Scalars["String"];
};

export type DeleteRoomResult = {
    __typename?: "DeleteRoomResult";
    deleted?: Maybe<Scalars["Boolean"]>;
};

export type DeleteWorkspaceResult = {
    __typename?: "DeleteWorkspaceResult";
    errorMessage?: Maybe<Scalars["String"]>;
    success?: Maybe<Scalars["Boolean"]>;
};

export type DependencyDescriptor = {
    __typename?: "DependencyDescriptor";
    name?: Maybe<Scalars["String"]>;
    version?: Maybe<Scalars["String"]>;
};

export type DesignerCommitState = {
    __typename?: "DesignerCommitState";
    commitInProgress: Scalars["Boolean"];
};

export type DesignerEditState = {
    __typename?: "DesignerEditState";
    sessionId: Scalars["String"];
    isEditing: Scalars["Boolean"];
};

export type DesignerRestoreDraft = {
    __typename?: "DesignerRestoreDraft";
    success: Scalars["Boolean"];
};

export type DesignerSessionChangedBy = {
    __typename?: "DesignerSessionChangedBy";
    changedBy?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DesignerSessionId = {
    __typename?: "DesignerSessionId";
    sessionId?: Maybe<Scalars["String"]>;
};

export type DesignerSessionLocked = {
    __typename?: "DesignerSessionLocked";
    locked?: Maybe<Scalars["String"]>;
};

export type DesignerState = {
    __typename?: "DesignerState";
    sessionId: Scalars["String"];
    typeOfBlockToAdd?: Maybe<BlockType>;
    selectedMacroIdToConfig?: Maybe<Scalars["String"]>;
    selectedMicroIdToConfig?: Maybe<Scalars["String"]>;
    selectedMacroIdToPreview?: Maybe<Scalars["String"]>;
    selectedMicroIdToPreview?: Maybe<Scalars["String"]>;
    selectedAgendaItemId?: Maybe<Scalars["String"]>;
    selectedResourceId?: Maybe<Scalars["String"]>;
    deleteAgendaItemIds: Array<Scalars["String"]>;
    toolStep?: Maybe<ChooseToolStep>;
    isConfig: Scalars["Boolean"];
    isPreview: Scalars["Boolean"];
    isAddingBlock: Scalars["Boolean"];
    templateMode: Scalars["Boolean"];
    isSessionPreview: Scalars["Boolean"];
    checkedRecurrenceEditForSessionId?: Maybe<Scalars["String"]>;
    recurrenceEditType?: Maybe<RecurrenceEditType>;
    configurationStep: ConfigurationStep;
    dirty: Scalars["Boolean"];
    followUpOfSessionId?: Maybe<Scalars["String"]>;
    preventRestore: Scalars["Boolean"];
    configurationConfirm?: Maybe<ConfigurationConfirmType>;
    configInProgress: Scalars["Boolean"];
};

export enum DrawerState {
    Closed = "CLOSED",
    SemiOpen = "SEMI_OPEN",
    FullyOpen = "FULLY_OPEN",
}

export type EditOrganizationMembersPayload = {
    country?: Maybe<Scalars["String"]>;
    department?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    role?: Maybe<OrganizationPermissionType>;
    sendInviteEmail?: Maybe<Scalars["Boolean"]>;
    userId: Scalars["String"];
    workspaceIds?: Maybe<Array<Scalars["String"]>>;
};

export type EditOrganizationMembersResponse = {
    __typename?: "EditOrganizationMembersResponse";
    errorMessage?: Maybe<Scalars["String"]>;
    updatedMembers?: Maybe<Array<User>>;
};

export type EntityUsingCustomMessageTemplate = {
    __typename?: "EntityUsingCustomMessageTemplate";
    id: Scalars["String"];
    name: Scalars["String"];
};

export type EventForEvent = {
    __typename?: "EventForEvent";
    id: Scalars["String"];
    slug: Scalars["String"];
    state?: Maybe<SessionEventState>;
};

export enum EvernoteSyncStatus {
    NotSynced = "NOT_SYNCED",
    Synced = "SYNCED",
    TokenExpired = "TOKEN_EXPIRED",
}

export type ExcludedDateCreateManyWithoutRuleInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<ExcludedDateCreateWithoutRuleInput>>;
};

export type ExcludedDateCreateWithoutRuleInput = {
    id?: Maybe<Scalars["String"]>;
    occurrenceId: Scalars["DateTime"];
};

export type ExecResult = {
    __typename?: "ExecResult";
    error?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    result?: Maybe<Scalars["String"]>;
    success: Scalars["Boolean"];
};

export enum ExitPageCtaType {
    CustomCta = "CUSTOM_CTA",
    None = "NONE",
    SessionsCta = "SESSIONS_CTA",
}

export type ExitPageOfferUpdateInput = {
    connect?: Maybe<WhereIdInput>;
};

export type ExternalAccount = {
    __typename?: "ExternalAccount";
    client: Scalars["String"];
    config: Scalars["Json"];
    externalId: Scalars["String"];
    id: Scalars["ID"];
    user: User;
};

export type ExternalCalendar = {
    __typename?: "ExternalCalendar";
    email: Scalars["String"];
    id: Scalars["String"];
    isPrimary?: Maybe<Scalars["Boolean"]>;
    isShared: Scalars["Boolean"];
    name?: Maybe<Scalars["String"]>;
    pushEvents?: Maybe<Scalars["Boolean"]>;
    type: CalendarProvider;
};

export type ExternalCalendarEvent = {
    __typename?: "ExternalCalendarEvent";
    calendarId: Scalars["String"];
    end?: Maybe<ExternalCalendarEventDate>;
    id: Scalars["String"];
    isBusy?: Maybe<Scalars["Boolean"]>;
    name?: Maybe<Scalars["String"]>;
    recurrence?: Maybe<Array<Scalars["String"]>>;
    rsvp?: Maybe<Scalars["String"]>;
    start?: Maybe<ExternalCalendarEventDate>;
    type?: Maybe<CalendarProvider>;
};

export type ExternalCalendarEventDate = {
    __typename?: "ExternalCalendarEventDate";
    date?: Maybe<Scalars["String"]>;
    dateTime?: Maybe<Scalars["String"]>;
    timeZone?: Maybe<Scalars["String"]>;
};

export type ExternalCalendarFilter = {
    __typename?: "ExternalCalendarFilter";
    calendarId: Scalars["String"];
    calendarTitle: Scalars["String"];
    enabled: Scalars["Boolean"];
    type: CalendarProvider;
};

export type ExternalClient = {
    __typename?: "ExternalClient";
    claims: Scalars["Json"];
    clientId: Scalars["String"];
    clientSecret?: Maybe<Scalars["String"]>;
    iconUrl: Scalars["String"];
    id: Scalars["ID"];
};

export type ExternalEventsAndCalendars = {
    __typename?: "ExternalEventsAndCalendars";
    calendars: Array<ExternalCalendar>;
    events: Array<CalendarCustomEvent>;
};

export type ExternalProvider = {
    __typename?: "ExternalProvider";
    accessToken: Scalars["String"];
    expiryDate: Scalars["String"];
    id: Scalars["ID"];
    idToken: Scalars["String"];
    name: Scalars["String"];
    options: Scalars["Json"];
    refreshToken: Scalars["String"];
    scope: Scalars["String"];
    tokenCache?: Maybe<Scalars["String"]>;
    tokenType: Scalars["String"];
    user: User;
};

export type Feedback = {
    __typename?: "Feedback";
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    lastName?: Maybe<Scalars["String"]>;
    message?: Maybe<Scalars["String"]>;
    rating: Scalars["Int"];
    sentToProductBoard: Scalars["Boolean"];
    sessionId: Scalars["String"];
    sessionName: Scalars["String"];
    source: FeedbackSource;
    technicalDetails: Scalars["Json"];
};

export type FeedbackForm = {
    __typename?: "FeedbackForm";
    createdAt: Scalars["DateTime"];
    description: Scalars["String"];
    id: Scalars["ID"];
    questions: Scalars["Json"];
    title: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    userId: Scalars["String"];
};

export type FeedbackFormInput = {
    description: Scalars["String"];
    questions: Scalars["Json"];
    title: Scalars["String"];
};

export type FeedbackFormsCount = {
    __typename?: "FeedbackFormsCount";
    bookingsWithForm?: Maybe<ListAndCount>;
    eventsWithForm?: Maybe<ListAndCount>;
    sessionsWithForm?: Maybe<ListAndCount>;
    settingsWithForm?: Maybe<ListAndCount>;
};

export type FeedbackFormUpdateInput = {
    connect?: Maybe<WhereIdNullableInput>;
    disconnect?: Maybe<Scalars["Boolean"]>;
};

export enum FeedbackSource {
    Designer = "Designer",
    Player = "Player",
}

export type GeneratedAgendaItem = {
    __typename?: "GeneratedAgendaItem";
    description: Scalars["String"];
    duration: Scalars["Int"];
    name: Scalars["String"];
    order: Scalars["Int"];
};

export type GeneratedAgendaItemPayload = {
    __typename?: "GeneratedAgendaItemPayload";
    agendaItem?: Maybe<GeneratedAgendaItem>;
    error?: Maybe<Scalars["String"]>;
    titleAndDescription?: Maybe<GeneratedTitleAndDescription>;
};

export type GeneratedPollsPayload = {
    __typename?: "GeneratedPollsPayload";
    answers: Array<Scalars["String"]>;
    error?: Maybe<Scalars["String"]>;
    question: Scalars["String"];
};

export type GeneratedPublicApiKey = {
    __typename?: "GeneratedPublicApiKey";
    id: Scalars["String"];
    role: PublicApiKeyRole;
    token: Scalars["String"];
};

export type GeneratedTitleAndDescription = {
    __typename?: "GeneratedTitleAndDescription";
    description: Scalars["String"];
    title: Scalars["String"];
};

export type GeneratePublicGuestIfNecessaryResponse = GuestLoginSessionIdResponse | NoSessionFoundResponse;

export enum GenerateSummaryAction {
    Rewrite = "rewrite",
    Summarize = "summarize",
}

export type GetRemoteUserResult = {
    __typename?: "GetRemoteUserResult";
    guestDoesNotExist?: Maybe<Scalars["Boolean"]>;
    updateTs: Scalars["DateTime"];
    user?: Maybe<User>;
    userSuspended?: Maybe<Scalars["Boolean"]>;
};

export type GetUserNameResult = {
    __typename?: "GetUserNameResult";
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    userProfilePicture?: Maybe<Scalars["String"]>;
};

export type GetUserNamesResult = {
    __typename?: "GetUserNamesResult";
    users?: Maybe<Array<Maybe<GetUserNameResult>>>;
};

export enum GiphyMediaType {
    Gifs = "gifs",
    Stickers = "stickers",
    Text = "text",
    Videos = "videos",
}

export type GlobalNotificationsResult = {
    __typename?: "GlobalNotificationsResult";
    calendar?: Maybe<CalendarNotificationResult>;
    oneOrganizationPermissionUpdate?: Maybe<UserToOrganizationPermission>;
    organizationPermissionsUpdate?: Maybe<Array<UserToOrganizationPermission>>;
    organizationUpdate?: Maybe<Organization>;
    userUpdate?: Maybe<User>;
    workspacePermissionsUpdate?: Maybe<Array<UserToWorkspacePermission>>;
};

export type GoogleDocTextWrite = {
    delay?: Maybe<Scalars["Int"]>;
    letterByLetter?: Maybe<Scalars["Boolean"]>;
    text: Scalars["String"];
};

export enum GoogleFileType {
    Document = "document",
    Form = "form",
    Presentation = "presentation",
    Spreadsheet = "spreadsheet",
}

export enum GooglePermissions {
    Calendar = "Calendar",
    Contacts = "Contacts",
    Drive = "Drive",
    Email = "Email",
    MailSend = "MailSend",
    Profile = "Profile",
}

export type GoogleSyncedEmailData = {
    __typename?: "GoogleSyncedEmailData";
    calendarProviderId?: Maybe<Scalars["String"]>;
    calendars?: Maybe<Array<ExternalCalendar>>;
    email?: Maybe<Scalars["String"]>;
    permissions: Array<GoogleSyncedEmailGrantedPermission>;
};

export type GoogleSyncedEmailGrantedPermission = {
    __typename?: "GoogleSyncedEmailGrantedPermission";
    granted: Scalars["Boolean"];
    permission: Scalars["String"];
};

export type GuestCreateOneWithoutParticipantInSessionsInput = {
    connect?: Maybe<WhereIdInput>;
    create?: Maybe<GuestCreateWithoutParticipantInSessionsInput>;
};

export type GuestCreateWithoutParticipantInSessionsInput = {
    consumed?: Maybe<Scalars["Boolean"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    firstName: Scalars["String"];
    hasCompletedGuestDetails?: Maybe<Scalars["Boolean"]>;
    id?: Maybe<Scalars["String"]>;
    language?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    notesFlags?: Maybe<Scalars["Json"]>;
    token?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GuestLoginSessionIdResponse = {
    __typename?: "GuestLoginSessionIdResponse";
    guestLoginSessionId: Scalars["String"];
};

export type GuestUpdateInput = {
    language?: Maybe<Scalars["String"]>;
    notesFlags?: Maybe<Scalars["Json"]>;
};

export type GuestWhereInput = {
    email?: Maybe<StringNullableFilter>;
};

export type Highlight = {
    __typename?: "Highlight";
    name?: Maybe<Scalars["String"]>;
    participants?: Maybe<Array<ParticipantHighlight>>;
    resourceNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum HostType {
    Home = "HOME",
    Base = "BASE",
    Book = "BOOK",
    Booking = "BOOKING",
    NewBooking = "NEW_BOOKING",
    BookedEvent = "BOOKED_EVENT",
    Studio = "STUDIO",
    Player = "PLAYER",
    Designer = "DESIGNER",
    Buildup = "BUILDUP",
    Notfound = "NOTFOUND",
    Test = "TEST",
    Memory = "MEMORY",
    Feedback = "FEEDBACK",
    Unsubscribe = "UNSUBSCRIBE",
    Templates = "TEMPLATES",
    User = "USER",
    Connect = "CONNECT",
    Disconnect = "DISCONNECT",
    Sessionended = "SESSIONENDED",
    Waiting = "WAITING",
    Contacts = "CONTACTS",
    Resources = "RESOURCES",
    Events = "EVENTS",
    Event = "EVENT",
    Files = "FILES",
    Devicetest = "DEVICETEST",
    Rooms = "ROOMS",
    Unauthorized = "UNAUTHORIZED",
    UnableToJoin = "UNABLE_TO_JOIN",
    ChooseOrganization = "CHOOSE_ORGANIZATION",
    OrganizationInvite = "ORGANIZATION_INVITE",
    Payments = "PAYMENTS",
    Bookings = "BOOKINGS",
}

export type HubspotContact = {
    __typename?: "HubspotContact";
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    id: Scalars["ID"];
    lastName: Scalars["String"];
    status: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
};

/** IFrameCheckUrl Return Type */
export type IframeCheckUrlPayload = {
    __typename?: "IframeCheckUrlPayload";
    contentType: Scalars["String"];
    fileName?: Maybe<Scalars["String"]>;
    fileSize?: Maybe<Scalars["Int"]>;
    isFrameable?: Maybe<Scalars["Boolean"]>;
    reason?: Maybe<Scalars["String"]>;
};

export type InstanceOfRecurrenceForEvent = {
    __typename?: "InstanceOfRecurrenceForEvent";
    id: Scalars["String"];
    isParticipantInRecurrenceParent?: Maybe<Scalars["Boolean"]>;
    sessionId: Scalars["String"];
};

export type IntFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["Int"]>;
};

export type IntFilter = {
    equals?: Maybe<Scalars["Int"]>;
    gt?: Maybe<Scalars["Int"]>;
    gte?: Maybe<Scalars["Int"]>;
    in?: Maybe<Array<Scalars["Int"]>>;
    lt?: Maybe<Scalars["Int"]>;
    lte?: Maybe<Scalars["Int"]>;
    not?: Maybe<NestedIntFilter>;
    notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type IntNullableFilter = {
    equals?: Maybe<Scalars["Int"]>;
    gt?: Maybe<Scalars["Int"]>;
    gte?: Maybe<Scalars["Int"]>;
    in?: Maybe<Array<Scalars["Int"]>>;
    lt?: Maybe<Scalars["Int"]>;
    lte?: Maybe<Scalars["Int"]>;
    not?: Maybe<NestedIntNullableFilter>;
    notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type IsNullDbFilter = {
    is?: Maybe<Array<IsNullDbFilter>>;
};

export type JoinWithInviteLinkResult = {
    __typename?: "joinWithInviteLinkResult";
    access_token?: Maybe<Scalars["String"]>;
    error?: Maybe<Scalars["String"]>;
    linkAlreadyUsed?: Maybe<Scalars["Boolean"]>;
    sessionId?: Maybe<Scalars["String"]>;
};

export type JoinWithPublicLinkResult = {
    __typename?: "JoinWithPublicLinkResult";
    fatalError?: Maybe<Scalars["String"]>;
    joinDetails?: Maybe<SessionJoinDetails>;
    redirectTo?: Maybe<Scalars["String"]>;
    redirectToLogin?: Maybe<Scalars["Boolean"]>;
    validationError?: Maybe<Scalars["String"]>;
};

export type JsonFilter = {
    equals?: Maybe<Scalars["Json"]>;
};

export type LeftDrawer = {
    __typename?: "LeftDrawer";
    isDetached?: Maybe<Scalars["Boolean"]>;
    state: DrawerState;
};

export type LeftDrawerInPreview = {
    __typename?: "LeftDrawerInPreview";
    state: DrawerState;
};

export type ListAndCount = {
    __typename?: "ListAndCount";
    count: Scalars["Int"];
    list?: Maybe<Array<Scalars["String"]>>;
};

export type LobbyPicture = {
    __typename?: "LobbyPicture";
    aspect?: Maybe<Scalars["Float"]>;
    canBeRepositioned?: Maybe<Scalars["Boolean"]>;
    createdAt: Scalars["DateTime"];
    croppedPictureUrl?: Maybe<Scalars["String"]>;
    height?: Maybe<Scalars["Float"]>;
    id: Scalars["ID"];
    isDefaultImage?: Maybe<Scalars["Boolean"]>;
    originalPictureUrl?: Maybe<Scalars["String"]>;
    pictureWasUploaded?: Maybe<Scalars["Boolean"]>;
    sessionId: Scalars["String"];
    unit?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    width?: Maybe<Scalars["Float"]>;
    x?: Maybe<Scalars["Float"]>;
    y?: Maybe<Scalars["Float"]>;
};

export type LobbyPictureCreateInput = {
    create?: Maybe<LobbyPictureCreateWithoutSessionInput>;
};

export type LobbyPictureCreateWithoutSessionInput = {
    aspect?: Maybe<Scalars["Float"]>;
    canBeRepositioned?: Maybe<Scalars["Boolean"]>;
    croppedPictureUrl?: Maybe<Scalars["String"]>;
    height?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["String"]>;
    originalPictureUrl?: Maybe<Scalars["String"]>;
    pictureWasUploaded?: Maybe<Scalars["Boolean"]>;
    unit?: Maybe<Scalars["String"]>;
    width?: Maybe<Scalars["Float"]>;
    x?: Maybe<Scalars["Float"]>;
    y?: Maybe<Scalars["Float"]>;
};

export type LobbyPictureUploadUrls = {
    __typename?: "LobbyPictureUploadUrls";
    originalUploadUrl: Scalars["String"];
};

export type LocalUser = {
    __typename?: "LocalUser";
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    role: Scalars["String"];
    language: Scalars["String"];
};

export enum MemoryAccessType {
    JustAttendees = "JUST_ATTENDEES",
    Private = "PRIVATE",
    Public = "PUBLIC",
}

export type MemoryFieldAnalytics = {
    __typename?: "MemoryFieldAnalytics";
    count: Scalars["Float"];
    estimate?: Maybe<Scalars["Float"]>;
    percentageDiffFromAverage?: Maybe<Scalars["Float"]>;
};

export type MessageExternalProvider = {
    __typename?: "MessageExternalProvider";
    apiKey?: Maybe<Scalars["String"]>;
    apiSecret?: Maybe<Scalars["String"]>;
    assignedToWorkspaces: Array<Workspace>;
    authToken?: Maybe<Scalars["String"]>;
    clientId: Scalars["String"];
    createdAt: Scalars["DateTime"];
    enabled: Scalars["Boolean"];
    id: Scalars["ID"];
    messageType: MessageExternalProviderMessageType;
    name: Scalars["String"];
    options: Scalars["Json"];
    organizationId: Scalars["String"];
    providerType: MessageExternalProviderType;
    senderNumber: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    verified: Scalars["Boolean"];
};

export enum MessageExternalProviderMessageType {
    Sms = "SMS",
}

export enum MessageExternalProviderType {
    Twilio = "TWILIO",
}

export type MessageFile = {
    __typename?: "MessageFile";
    chatmessageId?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    downloadLink?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    name?: Maybe<Scalars["String"]>;
    participantId?: Maybe<Scalars["String"]>;
    size?: Maybe<Scalars["Int"]>;
    type?: Maybe<Scalars["String"]>;
};

export type MessageFileWhereInput = {
    chatMessage?: Maybe<ChatMessageWhereInput>;
};

export enum MicrosoftPermissions {
    Calendar = "Calendar",
    Contacts = "Contacts",
    MailSend = "MailSend",
}

export type MicrosoftSyncedEmailData = {
    __typename?: "MicrosoftSyncedEmailData";
    calendarProviderId?: Maybe<Scalars["String"]>;
    calendars?: Maybe<Array<ExternalCalendar>>;
    email?: Maybe<Scalars["String"]>;
    permissions: Array<MicrosoftSyncedEmailGrantedPermission>;
};

export type MicrosoftSyncedEmailGrantedPermission = {
    __typename?: "MicrosoftSyncedEmailGrantedPermission";
    granted: Scalars["Boolean"];
    permission: Scalars["String"];
};

export type ModifiedContacts = {
    addContactIds?: Maybe<Array<Scalars["String"]>>;
    removeContactIds?: Maybe<Array<Scalars["String"]>>;
};

export type Module = {
    __typename?: "Module";
    id: Scalars["String"];
    name: Scalars["String"];
    isPublic: Scalars["Boolean"];
    isReadonly: Scalars["Boolean"];
    type: ModuleType;
    options: Array<ConfigOption>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type ModuleOptionsArgs = {
    take?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    cursor?: Maybe<ConfigOptionWhereUniqueInput>;
};

export type ModuleCreateInput = {
    id?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    isPublic: Scalars["Boolean"];
    isReadonly: Scalars["Boolean"];
    type: ModuleType;
    createdAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    options?: Maybe<ConfigOptionCreateManyWithoutModuleInput>;
};

export type ModuleCreateOneWithoutOptionsInput = {
    create?: Maybe<ModuleCreateWithoutOptionsInput>;
    connect?: Maybe<ModuleWhereUniqueInput>;
};

export type ModuleCreateWithoutOptionsInput = {
    id?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    isPublic: Scalars["Boolean"];
    isReadonly: Scalars["Boolean"];
    type: ModuleType;
    createdAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum ModuleType {
    File = "File",
    Value = "Value",
}

export type ModuleUpdateInput = {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
    isReadonly?: Maybe<BoolFieldUpdateOperationsInput>;
    type?: Maybe<ModuleType>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    options?: Maybe<ConfigOptionUpdateManyWithoutModuleInput>;
};

export type ModuleUpdateOneWithoutOptionsInput = {
    create?: Maybe<ModuleCreateWithoutOptionsInput>;
    connect?: Maybe<ModuleWhereUniqueInput>;
    disconnect?: Maybe<Scalars["Boolean"]>;
    delete?: Maybe<Scalars["Boolean"]>;
    update?: Maybe<ModuleUpdateWithoutOptionsDataInput>;
    upsert?: Maybe<ModuleUpsertWithoutOptionsInput>;
};

export type ModuleUpdateWithoutOptionsDataInput = {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
    isReadonly?: Maybe<BoolFieldUpdateOperationsInput>;
    type?: Maybe<ModuleType>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModuleUpsertWithoutOptionsInput = {
    update: ModuleUpdateWithoutOptionsDataInput;
    create: ModuleCreateWithoutOptionsInput;
};

export type ModuleWhereInput = {
    AND?: Maybe<Array<ModuleWhereInput>>;
    OR?: Maybe<Array<ModuleWhereInput>>;
    NOT?: Maybe<Array<ModuleWhereInput>>;
    id?: Maybe<StringFilter>;
    name?: Maybe<StringFilter>;
    isPublic?: Maybe<BoolFilter>;
    isReadonly?: Maybe<BoolFilter>;
    type?: Maybe<ModuleType>;
    options?: Maybe<ConfigOptionListRelationFilter>;
    createdAt?: Maybe<DateTimeFilter>;
    updatedAt?: Maybe<DateTimeFilter>;
};

export type ModuleWhereUniqueInput = {
    id?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
};

export type Mutation = {
    __typename?: "Mutation";
    acceptOrganizationInvite: UserToOrganizationPermission;
    addContactsToBin?: Maybe<Scalars["Boolean"]>;
    addGuestToSession?: Maybe<Scalars["Boolean"]>;
    addMemberToOrganization: AddMemberToOrganizationResponse;
    addOffersInSession: Array<Maybe<Offer>>;
    answerParticipant?: Maybe<Scalars["Boolean"]>;
    answerWaitingParticipants?: Maybe<Scalars["Boolean"]>;
    askParticipant?: Maybe<Scalars["Boolean"]>;
    assignCnameWorkspaceId: Cname;
    bookEvent?: Maybe<BookedEvent>;
    cancelBookedEvent?: Maybe<BookedEvent>;
    cancelBooking?: Maybe<Booking>;
    cancelPaypalPayment?: Maybe<PaypalPayment>;
    capturePaypalPayment?: Maybe<PaypalPayment>;
    changeBookingAssignee?: Maybe<Booking>;
    changeGoogleFilePermission?: Maybe<Scalars["Boolean"]>;
    changeGoogleFilesPermission?: Maybe<Scalars["Boolean"]>;
    changeGuestDetails: Participant;
    changePassword?: Maybe<ChangePasswordResult>;
    changeSubscriptionPlan?: Maybe<Scalars["Boolean"]>;
    checkCodeCustomSmtpServer: Scalars["Boolean"];
    checkCodeMessageExternalProvider: Scalars["Boolean"];
    checkGoogleFileInUse: Array<Session>;
    checkGuestCode?: Maybe<CheckGuestCodeResult>;
    checkRegistration?: Maybe<CheckRegistrationResponse>;
    checkSessionExists?: Maybe<CheckSessionExistsResult>;
    cleanupCsd?: Maybe<Scalars["Boolean"]>;
    cloneOneBookingEvent?: Maybe<BookingEvent>;
    cloneRoom?: Maybe<Room>;
    commitAgendaTemplate: AgendaTemplate;
    commitCustomBackground: User;
    commitLobbyPicture: LobbyPicture;
    configureCsd?: Maybe<CustomSubDomainConfig>;
    confirmMyProductToolIconUpload: MyProductTool;
    confirmProfilePictureUpload?: Maybe<User>;
    confirmUploadAnonymousFileToArtifact: Scalars["String"];
    confirmUploadBrandingLogo?: Maybe<Scalars["String"]>;
    confirmUploadEventImage?: Maybe<Scalars["String"]>;
    confirmUploadFileForChatMessage?: Maybe<MessageFile>;
    confirmUploadSessionSpeakerPhoto?: Maybe<Scalars["String"]>;
    createCname: Cname;
    createCustomMessageTemplate: CustomMessageTemplate;
    createCustomSmtpServer: CustomSmtpServer;
    createCustomSmtpSpfServer: CustomSmtpServer;
    createFeedback: Feedback;
    createFeedbackForm?: Maybe<FeedbackForm>;
    createGoogleFile?: Maybe<Scalars["String"]>;
    createHubspotContact: Scalars["Boolean"];
    createMessageExternalProvider: MessageExternalProvider;
    createOneAccessListItem?: Maybe<AccessListItem>;
    createOneAgendaTemplate?: Maybe<AgendaTemplate>;
    createOneBranding?: Maybe<Branding>;
    createOneConfigOption: ConfigOption;
    createOneContact?: Maybe<CustomContact>;
    createOneContactGroup: CustomContactGroup;
    createOneModule: Module;
    createOneOffer?: Maybe<Offer>;
    createOnePoll?: Maybe<Poll>;
    createOneQuestion?: Maybe<Question>;
    createOneWorkspaceRole?: Maybe<WorkspaceRole>;
    createOwnerFeedback: Feedback;
    createParticipants?: Maybe<Scalars["Boolean"]>;
    createPspdfkitDocument?: Maybe<PspdfKitDocumentResponse>;
    createPublicApiKey?: Maybe<GeneratedPublicApiKey>;
    createSession: Session;
    createSessionOccurrence: Session;
    createSessionRecording: Scalars["String"];
    createSubscriptionModifier?: Maybe<Scalars["Boolean"]>;
    createTestSession?: Maybe<Session>;
    createTranscript?: Maybe<Scalars["Boolean"]>;
    createUploadUrlForTranscript: TranscriptUploadUrl;
    createWebhook: Webhook;
    createWorkspace: Array<UserToWorkspacePermission>;
    deleteBookingSchedule?: Maybe<BookingSchedule>;
    deleteCname: Scalars["Boolean"];
    deleteContactsFromBin?: Maybe<Array<Scalars["String"]>>;
    deleteCoverHistoryImage?: Maybe<Scalars["Boolean"]>;
    deleteCustomBackground: User;
    deleteCustomEmail: CustomEmail;
    deleteCustomMessageTemplate: CustomMessageTemplate;
    deleteCustomSmtpServer: CustomSmtpServer;
    deleteDefaultEmailSender: DefaultEmailSender;
    deleteDefaultMessageSender: DefaultMessageSender;
    deleteDocument?: Maybe<Scalars["Boolean"]>;
    deleteFeedbackForm?: Maybe<FeedbackForm>;
    deleteGoogleFiles?: Maybe<Scalars["Boolean"]>;
    deleteManyUsers: Array<User>;
    deleteMessageExternalProvider: MessageExternalProvider;
    deleteOneAccessListItem?: Maybe<AccessListItem>;
    deleteOneAgendaTemplate?: Maybe<AgendaTemplate>;
    deleteOneBooking?: Maybe<Booking>;
    deleteOneBookingCollaborator?: Maybe<BookingCollaborator>;
    deleteOneBookingEvent?: Maybe<BookingEvent>;
    deleteOneBranding?: Maybe<Branding>;
    deleteOneChatMessage?: Maybe<ChatMessage>;
    deleteOneConfigOption?: Maybe<ConfigOption>;
    deleteOneContactGroup?: Maybe<Scalars["Boolean"]>;
    deleteOneMessageFile?: Maybe<MessageFile>;
    deleteOneModule?: Maybe<Module>;
    deleteOneMyProductTool?: Maybe<MyProductTool>;
    deleteOneOffer?: Maybe<Offer>;
    deleteOnePoll?: Maybe<Poll>;
    deleteOneQuestion?: Maybe<Question>;
    deleteOneSession?: Maybe<Session>;
    deleteOneWorkspaceRole?: Maybe<Scalars["Boolean"]>;
    deleteOrganization?: Maybe<Scalars["Boolean"]>;
    deletePaymentConfig?: Maybe<PaymentConfig>;
    deletePaypalAccount?: Maybe<Scalars["Boolean"]>;
    deleteRoom?: Maybe<DeleteRoomResult>;
    deleteSelf?: Maybe<User>;
    deleteSessionOccurrence?: Maybe<Scalars["Boolean"]>;
    deleteSessionOccurrenceAndFollowing?: Maybe<Scalars["Boolean"]>;
    deleteSessionRecordings?: Maybe<Scalars["Boolean"]>;
    deleteSessions?: Maybe<Scalars["Boolean"]>;
    deleteStripeAccount?: Maybe<Scalars["Boolean"]>;
    deleteUserAppleProviders?: Maybe<Scalars["Boolean"]>;
    deleteUserEvernoteProviders?: Maybe<Scalars["Boolean"]>;
    deleteUserGoogleProviders?: Maybe<Scalars["Boolean"]>;
    deleteUserHubspotProviders?: Maybe<Scalars["Boolean"]>;
    deleteUserMicrosoftProviders?: Maybe<Scalars["Boolean"]>;
    deleteUserNotionProviders?: Maybe<Scalars["Boolean"]>;
    deleteWebhook?: Maybe<Scalars["Boolean"]>;
    deleteWorkspace?: Maybe<DeleteWorkspaceResult>;
    discardEventChanges?: Maybe<Scalars["Boolean"]>;
    disconnectCnameWorkspaceId: Cname;
    editOrganizationMembers: EditOrganizationMembersResponse;
    endBackstage: Session;
    endOldSessionsForAdmin?: Maybe<Scalars["String"]>;
    endSession: Session;
    execSvc: ExecResult;
    exportContactsToCsv?: Maybe<Scalars["String"]>;
    generateCustomSmtpSpfServerTxtData: CustomSmtpSpfServerTxtData;
    getMyProductToolIconUploadUrl: Scalars["String"];
    giveConsentToRecord: Participant;
    importCsvContacts?: Maybe<Array<CustomContact>>;
    joinWithInviteLink?: Maybe<JoinWithInviteLinkResult>;
    joinWithPublicLink: JoinWithPublicLinkResult;
    kickFromSession?: Maybe<Scalars["Boolean"]>;
    lockEvent?: Maybe<Scalars["Boolean"]>;
    mapUserHubspotProperties?: Maybe<Scalars["Boolean"]>;
    markQuestionAsAnswering?: Maybe<QuestionsContainer>;
    muteParticipant?: Maybe<Scalars["Boolean"]>;
    pauseAgendaItem: AgendaItem;
    provideSessionPasscode: Participant;
    publishEvent?: Maybe<SessionEvent>;
    pushDrawerState?: Maybe<Scalars["Boolean"]>;
    pushSessionView?: Maybe<Scalars["Boolean"]>;
    recoverContactsFromBin?: Maybe<Scalars["Boolean"]>;
    registerToEvent?: Maybe<RegisterToEventResponse>;
    removeMembersFromOrganization: Scalars["Int"];
    removeOfferFromSession?: Maybe<Offer>;
    removeParticipant?: Maybe<Scalars["Boolean"]>;
    removeSkipSeen?: Maybe<Scalars["Boolean"]>;
    removeUserProfilePicture?: Maybe<User>;
    renameGoogleFile?: Maybe<Scalars["String"]>;
    requestControl?: Maybe<Scalars["Boolean"]>;
    requestPermissionToJoin: Participant;
    requestUserDeleteConfirmationCode?: Maybe<Scalars["Boolean"]>;
    rescheduleBookedEvent?: Maybe<BookedEvent>;
    revokePublicApiKey?: Maybe<Scalars["Boolean"]>;
    revokePublicApiKeys?: Maybe<Scalars["Boolean"]>;
    runFirstLoginEvents?: Maybe<User>;
    saveContextualNote?: Maybe<Participant>;
    saveEvernoteNote?: Maybe<Scalars["Boolean"]>;
    saveEvernoteToken?: Maybe<Scalars["Boolean"]>;
    saveGoogleToken?: Maybe<SaveGoogleTokenResponse>;
    saveHubspotToken?: Maybe<Scalars["Boolean"]>;
    saveMicrosoftToken?: Maybe<SaveMicrosoftTokenResponse>;
    saveNotionNote?: Maybe<Scalars["Boolean"]>;
    saveNotionToken?: Maybe<NotionSyncStatus>;
    savePaypalAccount?: Maybe<Scalars["Boolean"]>;
    saveSessionSettings?: Maybe<SessionSettings>;
    saveStripeToken?: Maybe<Scalars["Boolean"]>;
    seenBookings: Array<Booking>;
    sendAnalyticsEvent?: Maybe<Scalars["Boolean"]>;
    sendChatMessage: ChatMessage;
    sendContextualNote?: Maybe<Scalars["Boolean"]>;
    sendInviteEmails: Array<User>;
    sendMarketingEvent?: Maybe<Scalars["Boolean"]>;
    sendMixpanelPing?: Maybe<Scalars["Boolean"]>;
    sendReaction?: Maybe<Scalars["Boolean"]>;
    sendSessionAnalytics?: Maybe<Scalars["String"]>;
    sendSessionDiff?: Maybe<Scalars["Boolean"]>;
    sendSessionEndEmailsToSelf?: Maybe<Scalars["Boolean"]>;
    sendSessionEvent?: Maybe<Scalars["Boolean"]>;
    sendTakeaways?: Maybe<Scalars["Boolean"]>;
    setAllowScreenShare?: Maybe<Session>;
    setApplicationState?: Maybe<ApplicationState>;
    setControl?: Maybe<Scalars["Boolean"]>;
    setDefaultEmailSender?: Maybe<Scalars["Boolean"]>;
    setEnableReactions?: Maybe<Session>;
    setGuestInfo?: Maybe<Scalars["Boolean"]>;
    setMemberWorkspaceRole: User;
    setNotionDefaultParentPage?: Maybe<Scalars["Boolean"]>;
    setSessionDisableParticipantsVideo?: Maybe<Session>;
    setSessionFlags?: Maybe<Session>;
    setSessionHardMute?: Maybe<Session>;
    setSessionTimer?: Maybe<Scalars["Boolean"]>;
    shareParticipantScreen?: Maybe<Scalars["String"]>;
    shareRoom: Room;
    showOffer?: Maybe<Offer>;
    /** Marks the segment as seen (for skipping purposes). Default: 3 days. */
    skipSeen?: Maybe<Scalars["Boolean"]>;
    startSession: Session;
    startSessionFromBackstage: Session;
    stopAnsweringQuestions?: Maybe<QuestionsContainer>;
    stopCameraParticipant?: Maybe<Scalars["Boolean"]>;
    stopSessionRecording?: Maybe<Scalars["Boolean"]>;
    submitPollAnswers: Scalars["Boolean"];
    submitProblem?: Maybe<Scalars["Boolean"]>;
    subscribeUserToAllMarketingEmails?: Maybe<Scalars["Boolean"]>;
    suspendOrReactivateMembers: Array<UserToOrganizationPermission>;
    switchOrganization: Array<UserToOrganizationPermission>;
    switchWorkspace: Array<UserToWorkspacePermission>;
    syncAppleAccount?: Maybe<SyncAppleAccountResponse>;
    syncGoogleDrive?: Maybe<Scalars["Boolean"]>;
    toggleBookingEvent?: Maybe<BookingEvent>;
    toggleEventPublic?: Maybe<Scalars["Boolean"]>;
    toggleQuestionAsAnswered: Question;
    toggleQuestionUpVote: Question;
    toggleRoomActiveState?: Maybe<ToogleRoomResult>;
    toggleTranscriptionPin: SessionTranscript;
    tourStarted?: Maybe<Scalars["Boolean"]>;
    tryJoinSession?: Maybe<TryJoinSessionResult>;
    unlockEvent?: Maybe<Scalars["Boolean"]>;
    updateAppleExternalProvider?: Maybe<Scalars["Boolean"]>;
    updateBreakoutRoomsConfig: BreakoutRoomsConfiguration;
    updateCnameFavicon: Cname;
    updateCustomEmailSignature: CustomEmailSignatureResult;
    updateCustomEmailsLanguage: Scalars["String"];
    updateCustomMessageTemplate: CustomMessageTemplate;
    updateCustomSmtpServer: CustomSmtpServer;
    updateExternalProvider?: Maybe<Scalars["Boolean"]>;
    updateFeedbackForm?: Maybe<FeedbackForm>;
    updateFeedbackFormAnswers?: Maybe<Session>;
    updateMessageExternalProvider: MessageExternalProvider;
    updateOneAgendaTemplate?: Maybe<AgendaTemplate>;
    updateOneArtifact?: Maybe<Artifact>;
    updateOneBranding?: Maybe<Branding>;
    updateOneConfigOption?: Maybe<ConfigOption>;
    updateOneContact: CustomContact;
    updateOneContactGroup: CustomContactGroup;
    updateOneGuest?: Maybe<Scalars["Boolean"]>;
    updateOneModule?: Maybe<Module>;
    updateOneOffer?: Maybe<Offer>;
    updateOneOrganization?: Maybe<Organization>;
    updateOnePoll?: Maybe<Poll>;
    updateOneUser: User;
    updateOneWorkspaceRole?: Maybe<WorkspaceRole>;
    updateOrganizationOnboarding?: Maybe<Organization>;
    updateParticipantConferenceStatus?: Maybe<Participant>;
    updateParticipantInviteStatus?: Maybe<Participant>;
    updateParticipantsInSession?: Maybe<Session>;
    updateSession?: Maybe<Session>;
    updateSessionOrder: Session;
    updateSessionRecording?: Maybe<Scalars["Boolean"]>;
    updateSessionTags: Session;
    updateUnsubscribePreference?: Maybe<UnsubscribePreference>;
    updateUserOnboarding?: Maybe<User>;
    updateWebhook: Webhook;
    updateWorkspace?: Maybe<Workspace>;
    uploadAnonymousFileToArtifact: Scalars["String"];
    uploadBrandingAsset?: Maybe<BrandingAsset>;
    uploadBrandingLogo?: Maybe<Scalars["String"]>;
    uploadEventImage?: Maybe<Scalars["String"]>;
    uploadFileForChatMessage?: Maybe<UploadAnonymousFileResult>;
    uploadLobbyPicture?: Maybe<LobbyPictureUploadUrls>;
    uploadOfferAsset?: Maybe<OfferAsset>;
    uploadProblemScreenshot?: Maybe<UploadAnonymousFileResult>;
    uploadSessionSpeakerPhoto?: Maybe<Scalars["String"]>;
    uploadUserProfilePicture?: Maybe<Scalars["String"]>;
    upsertCustomEmail: CustomEmail;
    upsertDefaultEmailSender: DefaultEmailSender;
    upsertDefaultMessageSender: DefaultMessageSender;
    upsertMembersOfOrganization: UpsertMembersOfOrganizationResponse;
    upsertOneBookingCollaborator?: Maybe<BookingCollaborator>;
    upsertOneBookingEvent?: Maybe<BookingEvent>;
    upsertOneBookingSchedule?: Maybe<BookingSchedule>;
    upsertOneMyProductTool?: Maybe<MyProductTool>;
    upsertPaymentConfig?: Maybe<PaymentConfig>;
    verifyCustomSmtpServer: Scalars["Boolean"];
    verifyCustomSmtpSpfServer: CustomSmtpSpfServerVerificationResponse;
    verifyMessageExternalProvider: Scalars["Boolean"];
    writeToDocument?: Maybe<Scalars["Boolean"]>;
};

export type MutationAcceptOrganizationInviteArgs = {
    organizationId: Scalars["String"];
};

export type MutationAddContactsToBinArgs = {
    contactsIds: Array<Scalars["String"]>;
};

export type MutationAddGuestToSessionArgs = {
    customFields?: Maybe<Scalars["Json"]>;
    email: Scalars["String"];
    jobTitle?: Maybe<Scalars["String"]>;
    marketing: Scalars["Boolean"];
    name: Scalars["String"];
    sessionId: Scalars["String"];
    terms: Scalars["Boolean"];
};

export type MutationAddMemberToOrganizationArgs = {
    memberToAdd: AddMemberToOrganizationPayload;
};

export type MutationAddOffersInSessionArgs = {
    offersIds: Array<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type MutationAnswerParticipantArgs = {
    askToStartCamera?: Maybe<Scalars["Boolean"]>;
    askToUnmute?: Maybe<Scalars["Boolean"]>;
    sessionId: Scalars["String"];
};

export type MutationAnswerWaitingParticipantsArgs = {
    hasBeenApproved: Scalars["Boolean"];
    participantIds: Array<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type MutationAskParticipantArgs = {
    askToStartCamera?: Maybe<Scalars["Boolean"]>;
    askToUnmute?: Maybe<Scalars["Boolean"]>;
    participantId: Scalars["String"];
};

export type MutationAssignCnameWorkspaceIdArgs = {
    id: Scalars["String"];
    workspaceId: Scalars["String"];
};

export type MutationBookEventArgs = {
    additionalInformation: Scalars["Json"];
    availableAssignees: Array<Scalars["String"]>;
    bookingEventId: Scalars["String"];
    duration?: Maybe<Scalars["Int"]>;
    guests: Array<Scalars["String"]>;
    isPreview?: Maybe<Scalars["Boolean"]>;
    paymentProvider?: Maybe<Scalars["String"]>;
    questionnare?: Maybe<Scalars["Json"]>;
    timeSlot: Scalars["DateTime"];
    timeZone: Scalars["String"];
};

export type MutationCancelBookedEventArgs = {
    bookedEventId: Scalars["String"];
    reason?: Maybe<Scalars["String"]>;
    token?: Maybe<Scalars["String"]>;
};

export type MutationCancelBookingArgs = {
    id: Scalars["String"];
    reason: Scalars["String"];
};

export type MutationCancelPaypalPaymentArgs = {
    orderId: Scalars["String"];
    paymentId: Scalars["String"];
};

export type MutationCapturePaypalPaymentArgs = {
    orderId: Scalars["String"];
    paymentId: Scalars["String"];
};

export type MutationChangeBookingAssigneeArgs = {
    bookingId: Scalars["String"];
    collaboratorId: Scalars["String"];
};

export type MutationChangeGoogleFilePermissionArgs = {
    fileId: Scalars["String"];
    role: Scalars["String"];
};

export type MutationChangeGoogleFilesPermissionArgs = {
    fileIds: Array<Scalars["String"]>;
    role: Scalars["String"];
};

export type MutationChangeGuestDetailsArgs = {
    email?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type MutationChangePasswordArgs = {
    newPassword: Scalars["String"];
    oldPassword: Scalars["String"];
};

export type MutationChangeSubscriptionPlanArgs = {
    planId: Scalars["String"];
};

export type MutationCheckCodeCustomSmtpServerArgs = {
    code: Scalars["String"];
    id: Scalars["String"];
};

export type MutationCheckCodeMessageExternalProviderArgs = {
    code: Scalars["String"];
    id: Scalars["String"];
};

export type MutationCheckGoogleFileInUseArgs = {
    fileId: Scalars["String"];
};

export type MutationCheckGuestCodeArgs = {
    code: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
};

export type MutationCheckRegistrationArgs = {
    email: Scalars["String"];
    sessionEventId: Scalars["ID"];
};

export type MutationCheckSessionExistsArgs = {
    sessionId: Scalars["String"];
};

export type MutationCloneOneBookingEventArgs = {
    id: Scalars["String"];
};

export type MutationCloneRoomArgs = {
    roomId: Scalars["String"];
};

export type MutationCommitAgendaTemplateArgs = {
    newAgendaTemplate: Scalars["Json"];
};

export type MutationCommitCustomBackgroundArgs = {
    uploadUrl: Scalars["String"];
};

export type MutationCommitLobbyPictureArgs = {
    canBeRepositioned?: Maybe<Scalars["Boolean"]>;
    id: Scalars["String"];
    isDefaultImage?: Maybe<Scalars["Boolean"]>;
    originalPictureUrl: Scalars["String"];
    pictureWasUploaded?: Maybe<Scalars["Boolean"]>;
    sessionId: Scalars["String"];
    unsplashDownloadLocationUrl?: Maybe<Scalars["String"]>;
    x?: Maybe<Scalars["Float"]>;
    y?: Maybe<Scalars["Float"]>;
};

export type MutationConfigureCsdArgs = {
    domain: Scalars["String"];
};

export type MutationConfirmMyProductToolIconUploadArgs = {
    myProductToolId: Scalars["String"];
    photoSuffix: Scalars["String"];
};

export type MutationConfirmProfilePictureUploadArgs = {
    photoSuffix: Scalars["String"];
};

export type MutationConfirmUploadAnonymousFileToArtifactArgs = {
    artifactId: Scalars["String"];
    fileName: Scalars["String"];
};

export type MutationConfirmUploadBrandingLogoArgs = {
    cacheBuster: Scalars["String"];
};

export type MutationConfirmUploadEventImageArgs = {
    cacheBuster: Scalars["String"];
    eventId: Scalars["String"];
    primary: Scalars["Boolean"];
};

export type MutationConfirmUploadFileForChatMessageArgs = {
    name: Scalars["String"];
    sessionId: Scalars["String"];
    size: Scalars["Int"];
    type: Scalars["String"];
    uuid: Scalars["String"];
};

export type MutationConfirmUploadSessionSpeakerPhotoArgs = {
    cacheBuster: Scalars["String"];
    isThumbnail?: Maybe<Scalars["Boolean"]>;
    participantId: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationCreateCnameArgs = {
    domain: Scalars["String"];
    target: Scalars["String"];
};

export type MutationCreateCustomMessageTemplateArgs = {
    body: Scalars["String"];
    config?: Maybe<Scalars["Json"]>;
    disableSending?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    templateId: Scalars["String"];
};

export type MutationCreateCustomSmtpServerArgs = {
    additionalConfig?: Maybe<Scalars["Json"]>;
    configType: CustomSmtpServerConfigType;
    enabled?: Maybe<Scalars["Boolean"]>;
    host: Scalars["String"];
    name: Scalars["String"];
    password: Scalars["String"];
    port: Scalars["Int"];
    senderEmail: Scalars["String"];
    senderName?: Maybe<Scalars["String"]>;
    username: Scalars["String"];
    verified?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateCustomSmtpSpfServerArgs = {
    additionalConfig?: Maybe<Scalars["Json"]>;
    configType: CustomSmtpServerConfigType;
    enabled?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    senderDomain: Scalars["String"];
    senderEmail: Scalars["String"];
    senderName?: Maybe<Scalars["String"]>;
    verified: Scalars["Boolean"];
};

export type MutationCreateFeedbackArgs = {
    data: CreateFeedbackInput;
};

export type MutationCreateFeedbackFormArgs = {
    feedbackForm: FeedbackFormInput;
};

export type MutationCreateGoogleFileArgs = {
    name: Scalars["String"];
    type: Scalars["String"];
};

export type MutationCreateMessageExternalProviderArgs = {
    apiKey?: Maybe<Scalars["String"]>;
    apiSecret?: Maybe<Scalars["String"]>;
    authToken?: Maybe<Scalars["String"]>;
    clientId: Scalars["String"];
    enabled?: Maybe<Scalars["Boolean"]>;
    messageType: MessageExternalProviderMessageType;
    name: Scalars["String"];
    options?: Maybe<Scalars["Json"]>;
    providerType: MessageExternalProviderType;
    senderNumber: Scalars["String"];
    verified?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateOneAccessListItemArgs = {
    data: AccessListItemCreateInput;
};

export type MutationCreateOneAgendaTemplateArgs = {
    data: AgendaTemplateCreateInput;
};

export type MutationCreateOneBrandingArgs = {
    data?: Maybe<BrandingCreateInput>;
};

export type MutationCreateOneConfigOptionArgs = {
    data: ConfigOptionCreateInput;
};

export type MutationCreateOneContactArgs = {
    data: ContactCreateWithoutOwnerAndGroupInput;
};

export type MutationCreateOneContactGroupArgs = {
    data?: Maybe<ContactGroupCreateWithoutOwnerAndGroupInput>;
};

export type MutationCreateOneModuleArgs = {
    data: ModuleCreateInput;
};

export type MutationCreateOneOfferArgs = {
    offer: OfferInput;
    sessionId?: Maybe<Scalars["String"]>;
};

export type MutationCreateOnePollArgs = {
    data: PollCreateInput;
};

export type MutationCreateOneQuestionArgs = {
    questionContainerId: Scalars["String"];
    text: Scalars["String"];
};

export type MutationCreateOneWorkspaceRoleArgs = {
    data: WorkspaceRoleCreateInput;
};

export type MutationCreateOwnerFeedbackArgs = {
    data: CreateFeedbackInput;
};

export type MutationCreateParticipantsArgs = {
    nr: Scalars["Int"];
    secret: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationCreatePspdfkitDocumentArgs = {
    documentUrl: Scalars["String"];
};

export type MutationCreatePublicApiKeyArgs = {
    integration?: Maybe<PublicApiIntegration>;
    name: Scalars["String"];
    role?: Maybe<PublicApiKeyRole>;
};

export type MutationCreateSessionArgs = {
    data: SessionCreateInput;
    meetingRegion?: Maybe<Scalars["String"]>;
};

export type MutationCreateSessionOccurrenceArgs = {
    occurrenceId: Scalars["DateTime"];
    sessionId: Scalars["String"];
};

export type MutationCreateSessionRecordingArgs = {
    extraInput?: Maybe<UpdateRecordingInput>;
    recordingId: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationCreateSubscriptionModifierArgs = {
    modifierType: SubscriptionModifierType;
    quantity: Scalars["Int"];
};

export type MutationCreateTestSessionArgs = {
    participantsNr: Scalars["Int"];
};

export type MutationCreateTranscriptArgs = {
    duration?: Maybe<Scalars["Int"]>;
    language?: Maybe<Scalars["String"]>;
    noise: Scalars["Int"];
    participantId: Scalars["String"];
    sessionId: Scalars["String"];
    timestamp: Scalars["Float"];
    url: Scalars["String"];
};

export type MutationCreateUploadUrlForTranscriptArgs = {
    mimeType: Scalars["String"];
    participantId: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationCreateWebhookArgs = {
    headers?: Maybe<Array<Maybe<WebhookHeaderInput>>>;
    permissionType: WebhookPermissionType;
    trigger: WebhookTrigger;
    url: Scalars["String"];
};

export type MutationCreateWorkspaceArgs = {
    branding?: Maybe<BrandingCreateInput>;
    name: Scalars["String"];
    slug?: Maybe<Scalars["String"]>;
};

export type MutationDeleteBookingScheduleArgs = {
    id: Scalars["String"];
};

export type MutationDeleteCnameArgs = {
    id: Scalars["String"];
};

export type MutationDeleteContactsFromBinArgs = {
    contactsIds?: Maybe<Array<Scalars["String"]>>;
};

export type MutationDeleteCoverHistoryImageArgs = {
    id: Scalars["String"];
    pictureWasUploaded: Scalars["Boolean"];
    url?: Maybe<Scalars["String"]>;
};

export type MutationDeleteCustomBackgroundArgs = {
    backgroundUrl: Scalars["String"];
};

export type MutationDeleteCustomEmailArgs = {
    id: Scalars["String"];
};

export type MutationDeleteCustomMessageTemplateArgs = {
    id: Scalars["String"];
};

export type MutationDeleteCustomSmtpServerArgs = {
    id: Scalars["String"];
};

export type MutationDeleteDefaultEmailSenderArgs = {
    workspaceId: Scalars["String"];
};

export type MutationDeleteDefaultMessageSenderArgs = {
    workspaceId: Scalars["String"];
};

export type MutationDeleteDocumentArgs = {
    documentUrl: Scalars["String"];
    session: WhereIdInput;
};

export type MutationDeleteFeedbackFormArgs = {
    feedbackFormId: Scalars["String"];
};

export type MutationDeleteGoogleFilesArgs = {
    fileIds: Array<Scalars["String"]>;
};

export type MutationDeleteManyUsersArgs = {
    emails: Array<Scalars["String"]>;
};

export type MutationDeleteMessageExternalProviderArgs = {
    id: Scalars["String"];
};

export type MutationDeleteOneAccessListItemArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneAgendaTemplateArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneBookingArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneBookingCollaboratorArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneBookingEventArgs = {
    reason: Scalars["String"];
    where: WhereIdInput;
};

export type MutationDeleteOneBrandingArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneChatMessageArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneConfigOptionArgs = {
    where: ConfigOptionWhereUniqueInput;
};

export type MutationDeleteOneContactGroupArgs = {
    groupId: Scalars["String"];
};

export type MutationDeleteOneMessageFileArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneModuleArgs = {
    where: ModuleWhereUniqueInput;
};

export type MutationDeleteOneMyProductToolArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneOfferArgs = {
    offerId: Scalars["String"];
};

export type MutationDeleteOnePollArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneQuestionArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOneSessionArgs = {
    reason?: Maybe<Scalars["String"]>;
    where: WhereIdInput;
};

export type MutationDeleteOneWorkspaceRoleArgs = {
    where: WhereIdInput;
};

export type MutationDeleteOrganizationArgs = {
    organizationId?: Maybe<Scalars["String"]>;
};

export type MutationDeletePaymentConfigArgs = {
    id: Scalars["String"];
};

export type MutationDeleteRoomArgs = {
    reason?: Maybe<Scalars["String"]>;
    roomId: Scalars["String"];
};

export type MutationDeleteSelfArgs = {
    confirmationCode: Scalars["String"];
};

export type MutationDeleteSessionOccurrenceArgs = {
    occurrenceId: Scalars["DateTime"];
    sessionId: Scalars["String"];
};

export type MutationDeleteSessionOccurrenceAndFollowingArgs = {
    occurrenceId: Scalars["DateTime"];
    sessionId: Scalars["String"];
};

export type MutationDeleteSessionRecordingsArgs = {
    recordingIds: Array<Scalars["String"]>;
};

export type MutationDeleteSessionsArgs = {
    secret: Scalars["String"];
    where?: Maybe<SessionWhereInput>;
};

export type MutationDeleteUserAppleProvidersArgs = {
    emails: Array<Scalars["String"]>;
};

export type MutationDeleteUserGoogleProvidersArgs = {
    email: Scalars["String"];
    provider?: Maybe<Scalars["String"]>;
};

export type MutationDeleteUserMicrosoftProvidersArgs = {
    emails: Array<Scalars["String"]>;
    provider?: Maybe<Scalars["String"]>;
};

export type MutationDeleteWebhookArgs = {
    id: Scalars["String"];
};

export type MutationDeleteWorkspaceArgs = {
    workspaceId: Scalars["String"];
};

export type MutationDiscardEventChangesArgs = {
    id: Scalars["String"];
};

export type MutationDisconnectCnameWorkspaceIdArgs = {
    id: Scalars["String"];
    workspaceId: Scalars["String"];
};

export type MutationEditOrganizationMembersArgs = {
    membersToEdit: Array<EditOrganizationMembersPayload>;
    organizationId?: Maybe<Scalars["String"]>;
};

export type MutationEndBackstageArgs = {
    sessionId: Scalars["String"];
};

export type MutationEndOldSessionsForAdminArgs = {
    count: Scalars["Int"];
    secret: Scalars["String"];
    where: SessionWhereInput;
};

export type MutationEndSessionArgs = {
    sessionId: Scalars["String"];
};

export type MutationExecSvcArgs = {
    args?: Maybe<Scalars["String"]>;
    operation: Scalars["String"];
};

export type MutationExportContactsToCsvArgs = {
    contactsIds: Array<Scalars["String"]>;
    groupId?: Maybe<Scalars["String"]>;
};

export type MutationGenerateCustomSmtpSpfServerTxtDataArgs = {
    senderDomain: Scalars["String"];
};

export type MutationGetMyProductToolIconUploadUrlArgs = {
    myProductToolId: Scalars["String"];
    photoSuffix: Scalars["String"];
};

export type MutationGiveConsentToRecordArgs = {
    sessionId: Scalars["String"];
};

export type MutationImportCsvContactsArgs = {
    contacts: Array<ContactCreateWithoutOwnerAndGroupInput>;
    groupId?: Maybe<Scalars["String"]>;
};

export type MutationJoinWithInviteLinkArgs = {
    code: Scalars["String"];
    isAuthenticated: Scalars["Boolean"];
    language?: Maybe<Scalars["String"]>;
};

export type MutationJoinWithPublicLinkArgs = {
    email?: Maybe<Scalars["String"]>;
    joinToken?: Maybe<Scalars["String"]>;
    language?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationKickFromSessionArgs = {
    ban: Scalars["Boolean"];
    kickedParticipantId: Scalars["String"];
    session: WhereIdInput;
};

export type MutationLockEventArgs = {
    id: Scalars["String"];
    setChanged?: Maybe<Scalars["Boolean"]>;
};

export type MutationMapUserHubspotPropertiesArgs = {
    config?: Maybe<UserHubspotEventConfig>;
    mappings?: Maybe<Array<Maybe<UserHubspotSessionsPropertyMapping>>>;
};

export type MutationMarkQuestionAsAnsweringArgs = {
    questionBeingAnsweredId: Scalars["String"];
    questionsContainerId: Scalars["String"];
};

export type MutationMuteParticipantArgs = {
    participantId: Scalars["String"];
};

export type MutationPauseAgendaItemArgs = {
    sessionId: Scalars["String"];
};

export type MutationProvideSessionPasscodeArgs = {
    passcode: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationPublishEventArgs = {
    sessionEventId: Scalars["ID"];
};

export type MutationPushDrawerStateArgs = {
    leftDrawer?: Maybe<PushDrawerStateInput>;
    rightDrawer?: Maybe<PushDrawerStateInput>;
    sessionId: Scalars["String"];
};

export type MutationPushSessionViewArgs = {
    sessionId: Scalars["String"];
    sessionView: Scalars["String"];
};

export type MutationRecoverContactsFromBinArgs = {
    contactsIds: Array<Scalars["String"]>;
};

export type MutationRegisterToEventArgs = {
    analyticsId?: Maybe<Scalars["String"]>;
    paymentProvider?: Maybe<Scalars["String"]>;
    registrationAnswer: Scalars["Json"];
    sessionEventId: Scalars["ID"];
};

export type MutationRemoveMembersFromOrganizationArgs = {
    emails: Array<Scalars["String"]>;
    organizationId: Scalars["String"];
};

export type MutationRemoveOfferFromSessionArgs = {
    offerId: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationRemoveParticipantArgs = {
    sessionId: Scalars["String"];
    soft?: Maybe<Scalars["Boolean"]>;
    upcoming?: Maybe<Scalars["Boolean"]>;
};

export type MutationRemoveSkipSeenArgs = {
    segments: Array<Scalars["String"]>;
};

export type MutationRenameGoogleFileArgs = {
    fileId: Scalars["String"];
    name: Scalars["String"];
};

export type MutationRequestControlArgs = {
    sessionId: Scalars["String"];
};

export type MutationRequestPermissionToJoinArgs = {
    sessionId: Scalars["String"];
};

export type MutationRescheduleBookedEventArgs = {
    additionalInformation: Scalars["Json"];
    bookedEventId: Scalars["String"];
    guests: Array<Scalars["String"]>;
    paymentProvider?: Maybe<Scalars["String"]>;
    questionnare?: Maybe<Scalars["Json"]>;
    timeSlot: Scalars["DateTime"];
    timeZone: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
};

export type MutationRevokePublicApiKeyArgs = {
    id: Scalars["String"];
};

export type MutationRevokePublicApiKeysArgs = {
    ids: Array<Scalars["String"]>;
};

export type MutationRunFirstLoginEventsArgs = {
    celloUcc?: Maybe<Scalars["String"]>;
    externalReferral?: Maybe<Scalars["String"]>;
    lastVersionSeen: Scalars["Int"];
    previousSessionDetails?: Maybe<PreviousSessionDetails>;
    rewardfulReferral?: Maybe<Scalars["String"]>;
    utmCookies?: Maybe<UtmCookies>;
};

export type MutationSaveContextualNoteArgs = {
    noteJson: Scalars["Json"];
    session: WhereIdInput;
};

export type MutationSaveEvernoteNoteArgs = {
    noteJson: Scalars["Json"];
    title: Scalars["String"];
};

export type MutationSaveEvernoteTokenArgs = {
    data: Scalars["String"];
};

export type MutationSaveGoogleTokenArgs = {
    code: Scalars["String"];
    redirectUri?: Maybe<Scalars["String"]>;
};

export type MutationSaveHubspotTokenArgs = {
    data: Scalars["String"];
};

export type MutationSaveMicrosoftTokenArgs = {
    code: Scalars["String"];
    scopes: Scalars["String"];
};

export type MutationSaveNotionNoteArgs = {
    note: Scalars["String"];
    title?: Maybe<Scalars["String"]>;
};

export type MutationSaveNotionTokenArgs = {
    code: Scalars["String"];
};

export type MutationSavePaypalAccountArgs = {
    accountStatus?: Maybe<Scalars["String"]>;
    consentStatus?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
    isEmailConfirmed?: Maybe<Scalars["String"]>;
    merchantId: Scalars["String"];
    permissionsGranted?: Maybe<Scalars["String"]>;
    productIntentId?: Maybe<Scalars["String"]>;
    riskStatus?: Maybe<Scalars["String"]>;
};

export type MutationSaveSessionSettingsArgs = {
    settingsFlags: SessionSettingsFlagsInput;
};

export type MutationSaveStripeTokenArgs = {
    code: Scalars["String"];
};

export type MutationSeenBookingsArgs = {
    ids: Array<Scalars["String"]>;
};

export type MutationSendAnalyticsEventArgs = {
    data?: Maybe<Scalars["Json"]>;
    eventName: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
};

export type MutationSendChatMessageArgs = {
    body: Scalars["String"];
    chatGroupId?: Maybe<Scalars["String"]>;
    fileId?: Maybe<Scalars["String"]>;
    participantIds?: Maybe<Array<Scalars["String"]>>;
    replyToMessageId?: Maybe<Scalars["String"]>;
    sendToAllBreakoutRooms?: Maybe<Scalars["Boolean"]>;
    sessionId: Scalars["String"];
};

export type MutationSendContextualNoteArgs = {
    sendEmail?: Maybe<Scalars["Boolean"]>;
    sendToEvernote?: Maybe<Scalars["Boolean"]>;
    sendToNotion?: Maybe<Scalars["Boolean"]>;
    sessionId: Scalars["String"];
};

export type MutationSendInviteEmailsArgs = {
    emails: Array<Scalars["String"]>;
    forceSend?: Maybe<Scalars["Boolean"]>;
};

export type MutationSendMarketingEventArgs = {
    email: Scalars["String"];
    eventBody: Scalars["String"];
    eventName: Scalars["String"];
    sendOnce: Scalars["Boolean"];
};

export type MutationSendMixpanelPingArgs = {
    secret: Scalars["String"];
};

export type MutationSendReactionArgs = {
    reactionType: ReactionType;
    sessionId: Scalars["String"];
};

export type MutationSendSessionAnalyticsArgs = {
    data: Scalars["Json"];
    sessionId: Scalars["String"];
};

export type MutationSendSessionDiffArgs = {
    diff: Scalars["Json"];
    id: Scalars["String"];
    persist?: Maybe<Scalars["Boolean"]>;
    releaseLock?: Maybe<Scalars["Boolean"]>;
};

export type MutationSendSessionEndEmailsToSelfArgs = {
    sessionId: Scalars["String"];
};

export type MutationSendSessionEventArgs = {
    data: SessionEventInput;
    participantsIds: Array<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type MutationSendTakeawaysArgs = {
    sessionId: Scalars["String"];
};

export type MutationSetAllowScreenShareArgs = {
    allowScreenShareType: SessionFlagsType;
    sessionId: Scalars["String"];
};

export type MutationSetApplicationStateArgs = {
    newApplicationState: Scalars["Json"];
    secret: Scalars["String"];
};

export type MutationSetControlArgs = {
    participantId: Scalars["String"];
    sessionId: Scalars["String"];
    willControlTool: Scalars["Boolean"];
};

export type MutationSetDefaultEmailSenderArgs = {
    email: Scalars["String"];
};

export type MutationSetEnableReactionsArgs = {
    enableReactionsType: SessionFlagsType;
    sessionId: Scalars["String"];
};

export type MutationSetGuestInfoArgs = {
    code: Scalars["String"];
    name: Scalars["String"];
};

export type MutationSetMemberWorkspaceRoleArgs = {
    newRoleId: Scalars["String"];
    userId: Scalars["String"];
};

export type MutationSetNotionDefaultParentPageArgs = {
    parentPageId: Scalars["String"];
};

export type MutationSetSessionDisableParticipantsVideoArgs = {
    allowCameraType: SessionFlagsType;
    sessionId: Scalars["String"];
};

export type MutationSetSessionFlagsArgs = {
    flags: SessionFlags;
    sessionId: Scalars["String"];
};

export type MutationSetSessionHardMuteArgs = {
    hardMute: SessionFlagsType;
    sessionId: Scalars["String"];
};

export type MutationSetSessionTimerArgs = {
    currentDuration?: Maybe<Scalars["Int"]>;
    initialDuration?: Maybe<Scalars["Int"]>;
    sessionId: Scalars["String"];
    state: SessionTimerState;
};

export type MutationShareParticipantScreenArgs = {
    sessionId?: Maybe<Scalars["String"]>;
    stopPresenting?: Maybe<Scalars["Boolean"]>;
};

export type MutationShareRoomArgs = {
    roomId: Scalars["String"];
    shareWithWorkspaceInput?: Maybe<RoomShareWorkspaceInput>;
    userEmails?: Maybe<Array<Scalars["String"]>>;
};

export type MutationShowOfferArgs = {
    offerId?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type MutationSkipSeenArgs = {
    expiry?: Maybe<Scalars["Int"]>;
    segment: Scalars["String"];
};

export type MutationStartSessionArgs = {
    backstage?: Maybe<Scalars["Boolean"]>;
    session: WhereIdInput;
};

export type MutationStartSessionFromBackstageArgs = {
    session: WhereIdInput;
};

export type MutationStopAnsweringQuestionsArgs = {
    questionsContainerId: Scalars["String"];
};

export type MutationStopCameraParticipantArgs = {
    participantId: Scalars["String"];
};

export type MutationStopSessionRecordingArgs = {
    sessionId: Scalars["String"];
};

export type MutationSubmitPollAnswersArgs = {
    chosenOptionIds?: Maybe<Array<Scalars["String"]>>;
    pollId: Scalars["String"];
    textAnswer?: Maybe<Scalars["String"]>;
};

export type MutationSubmitProblemArgs = {
    category: ReportProblemCategory;
    description: Scalars["String"];
    deviceInfo?: Maybe<Scalars["Json"]>;
    screenshotUuid?: Maybe<Scalars["String"]>;
    session?: Maybe<Scalars["String"]>;
    trace?: Maybe<Scalars["String"]>;
};

export type MutationSubscribeUserToAllMarketingEmailsArgs = {
    subscribe: Scalars["Boolean"];
};

export type MutationSuspendOrReactivateMembersArgs = {
    emails: Array<Scalars["String"]>;
    organizationId?: Maybe<Scalars["String"]>;
    suspend: Scalars["Boolean"];
};

export type MutationSwitchOrganizationArgs = {
    organizationId: Scalars["String"];
};

export type MutationSwitchWorkspaceArgs = {
    workspaceId: Scalars["String"];
};

export type MutationSyncAppleAccountArgs = {
    password: Scalars["String"];
    username: Scalars["String"];
};

export type MutationToggleBookingEventArgs = {
    cancelBookings: Scalars["Boolean"];
    disabled: Scalars["Boolean"];
    id: Scalars["String"];
    reason: Scalars["String"];
};

export type MutationToggleEventPublicArgs = {
    isPublic: Scalars["Boolean"];
    sessionId: Scalars["ID"];
};

export type MutationToggleQuestionAsAnsweredArgs = {
    question: WhereIdInput;
    value: Scalars["Boolean"];
};

export type MutationToggleQuestionUpVoteArgs = {
    question: WhereIdInput;
};

export type MutationToggleRoomActiveStateArgs = {
    active?: Maybe<Scalars["Boolean"]>;
    roomId: Scalars["String"];
};

export type MutationToggleTranscriptionPinArgs = {
    id: Scalars["String"];
};

export type MutationTryJoinSessionArgs = {
    sessionId: Scalars["String"];
};

export type MutationUnlockEventArgs = {
    id: Scalars["String"];
};

export type MutationUpdateAppleExternalProviderArgs = {
    id: Scalars["String"];
    syncCalendarId?: Maybe<Scalars["String"]>;
};

export type MutationUpdateBreakoutRoomsConfigArgs = {
    allowRoomNavigation?: Maybe<Scalars["Boolean"]>;
    artifactId: Scalars["String"];
    deleteBreakoutRoomIds?: Maybe<Array<Scalars["String"]>>;
    mainBreakoutRoom?: Maybe<UpdateMainBreakoutRoom>;
    upsertBreakoutRooms?: Maybe<Array<UpsertBreakoutRoomInput>>;
};

export type MutationUpdateCnameFaviconArgs = {
    faviconUrl?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
};

export type MutationUpdateCustomEmailSignatureArgs = {
    footer?: Maybe<Scalars["Json"]>;
    signature?: Maybe<Scalars["Json"]>;
};

export type MutationUpdateCustomEmailsLanguageArgs = {
    language: Scalars["String"];
};

export type MutationUpdateCustomMessageTemplateArgs = {
    body?: Maybe<Scalars["String"]>;
    config?: Maybe<Scalars["Json"]>;
    disableSending?: Maybe<Scalars["Boolean"]>;
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateCustomSmtpServerArgs = {
    assignToWorkspaceId?: Maybe<Scalars["String"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    host?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    password?: Maybe<Scalars["String"]>;
    port?: Maybe<Scalars["Int"]>;
    removeFromWorkspaceId?: Maybe<Scalars["String"]>;
    senderEmail?: Maybe<Scalars["String"]>;
    senderName?: Maybe<Scalars["String"]>;
    username?: Maybe<Scalars["String"]>;
    verified?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateExternalProviderArgs = {
    id: Scalars["String"];
    syncCalendarId?: Maybe<Scalars["String"]>;
};

export type MutationUpdateFeedbackFormArgs = {
    feedbackForm: FeedbackFormInput;
    feedbackFormId: Scalars["String"];
};

export type MutationUpdateFeedbackFormAnswersArgs = {
    answers: Scalars["Json"];
    sessionId: Scalars["String"];
};

export type MutationUpdateMessageExternalProviderArgs = {
    apiKey?: Maybe<Scalars["String"]>;
    apiSecret?: Maybe<Scalars["String"]>;
    assignToWorkspaceId?: Maybe<Scalars["String"]>;
    authToken?: Maybe<Scalars["String"]>;
    clientId?: Maybe<Scalars["String"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    removeFromWorkspaceId?: Maybe<Scalars["String"]>;
    senderNumber?: Maybe<Scalars["String"]>;
    verified?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateOneAgendaTemplateArgs = {
    data: AgendaTemplateUpdateInput;
    where: WhereIdInput;
};

export type MutationUpdateOneArtifactArgs = {
    artifactId: Scalars["String"];
    properties: Array<PropertyCreateCustomInput>;
};

export type MutationUpdateOneBrandingArgs = {
    data?: Maybe<BrandingUpdateInput>;
};

export type MutationUpdateOneConfigOptionArgs = {
    data: ConfigOptionUpdateInput;
    where: ConfigOptionWhereUniqueInput;
};

export type MutationUpdateOneContactArgs = {
    data: ContactUpdateWithoutOwnerAndGroupInput;
    where: WhereIdInput;
};

export type MutationUpdateOneContactGroupArgs = {
    data?: Maybe<ContactGroupUpdateOneCustom>;
    where: WhereIdInput;
};

export type MutationUpdateOneGuestArgs = {
    data?: Maybe<GuestUpdateInput>;
};

export type MutationUpdateOneModuleArgs = {
    data: ModuleUpdateInput;
    where: ModuleWhereUniqueInput;
};

export type MutationUpdateOneOfferArgs = {
    offer: OfferInput;
    offerId: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
};

export type MutationUpdateOneOrganizationArgs = {
    data: OrganizationUpdateInput;
};

export type MutationUpdateOnePollArgs = {
    data: PollUpdateInput;
    where: WhereIdInput;
};

export type MutationUpdateOneUserArgs = {
    data?: Maybe<UserUpdateInput>;
};

export type MutationUpdateOneWorkspaceRoleArgs = {
    data: WorkspaceRoleCreateInput;
    where: WhereIdInput;
};

export type MutationUpdateOrganizationOnboardingArgs = {
    onboardingCompleted?: Maybe<Scalars["Boolean"]>;
    organizationName?: Maybe<Scalars["String"]>;
    size?: Maybe<Scalars["String"]>;
    userOnboarding?: Maybe<UserOnboardingInput>;
};

export type MutationUpdateParticipantConferenceStatusArgs = {
    newStatus: ConferenceParticipantStatus;
    where: WhereIdInput;
};

export type MutationUpdateParticipantInviteStatusArgs = {
    inviteStatus: ParticipantInviteStatus;
    reason?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type MutationUpdateParticipantsInSessionArgs = {
    deletedParticipantIds?: Maybe<Array<Scalars["String"]>>;
    followUpOfSessionId?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
    upsertParticipantJSONs?: Maybe<Array<Scalars["Json"]>>;
};

export type MutationUpdateSessionArgs = {
    data: SessionUpdateInput;
    isApplyingTemplate?: Maybe<Scalars["Boolean"]>;
    where: WhereIdInput;
};

export type MutationUpdateSessionOrderArgs = {
    order: Scalars["Int"];
    sessionId: Scalars["String"];
};

export type MutationUpdateSessionRecordingArgs = {
    input: UpdateRecordingInput;
    recordingId: Scalars["String"];
};

export type MutationUpdateSessionTagsArgs = {
    sessionId: Scalars["String"];
    tags: SessionTagsInput;
};

export type MutationUpdateUnsubscribePreferenceArgs = {
    sendAppEmails: Scalars["Boolean"];
    token: Scalars["String"];
};

export type MutationUpdateUserOnboardingArgs = {
    data?: Maybe<UserOnboardingInput>;
};

export type MutationUpdateWebhookArgs = {
    headers?: Maybe<Array<Maybe<WebhookHeaderInput>>>;
    id: Scalars["String"];
    trigger?: Maybe<WebhookTrigger>;
    url?: Maybe<Scalars["String"]>;
};

export type MutationUpdateWorkspaceArgs = {
    name?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    workspaceId: Scalars["String"];
};

export type MutationUploadAnonymousFileToArtifactArgs = {
    artifactId: Scalars["String"];
    fileName: Scalars["String"];
};

export type MutationUploadBrandingAssetArgs = {
    cacheBuster: Scalars["String"];
};

export type MutationUploadBrandingLogoArgs = {
    cacheBuster: Scalars["String"];
};

export type MutationUploadEventImageArgs = {
    cacheBuster: Scalars["String"];
    eventId: Scalars["String"];
    primary: Scalars["Boolean"];
};

export type MutationUploadFileForChatMessageArgs = {
    sessionId: Scalars["String"];
};

export type MutationUploadLobbyPictureArgs = {
    photoSuffix: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationUploadOfferAssetArgs = {
    cacheBuster: Scalars["String"];
};

export type MutationUploadSessionSpeakerPhotoArgs = {
    cacheBuster: Scalars["String"];
    isThumbnail?: Maybe<Scalars["Boolean"]>;
    participantId: Scalars["String"];
    sessionId: Scalars["String"];
};

export type MutationUploadUserProfilePictureArgs = {
    photoSuffix: Scalars["String"];
};

export type MutationUpsertCustomEmailArgs = {
    body?: Maybe<Scalars["Json"]>;
    bodyTitle?: Maybe<Scalars["String"]>;
    customEmailContent?: Maybe<Array<UpsertCustomEmailContentInput>>;
    disableSending?: Maybe<Scalars["Boolean"]>;
    id?: Maybe<Scalars["String"]>;
    subject?: Maybe<Scalars["String"]>;
    templateId: Scalars["String"];
};

export type MutationUpsertDefaultEmailSenderArgs = {
    customSmtpServerId?: Maybe<Scalars["String"]>;
    externalProviderId?: Maybe<Scalars["String"]>;
    senderEmail: Scalars["String"];
    workspaceId: Scalars["String"];
};

export type MutationUpsertDefaultMessageSenderArgs = {
    messageExternalProviderId: Scalars["String"];
    workspaceId: Scalars["String"];
};

export type MutationUpsertMembersOfOrganizationArgs = {
    membersToUpsert: Array<UpsertMembersOfOrganizationPayload>;
    sendInviteEmail?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpsertOneBookingCollaboratorArgs = {
    create?: Maybe<CreateBookingCollaboratorInput>;
    update?: Maybe<UpdateBookingCollaboratorInput>;
    where: WhereIdNullableInput;
};

export type MutationUpsertOneBookingEventArgs = {
    create?: Maybe<BookingEventCreateInput>;
    update?: Maybe<BookingEventUpdateInput>;
    where: WhereIdNullableInput;
};

export type MutationUpsertOneBookingScheduleArgs = {
    create?: Maybe<BookingScheduleCreateInput>;
    update?: Maybe<BookingScheduleUpdateInput>;
    where: BookingScheduleWhereUniqueInput;
};

export type MutationUpsertOneMyProductToolArgs = {
    create?: Maybe<MyProductToolCreateInput>;
    update?: Maybe<MyProductToolUpdateInput>;
    where: WhereIdInput;
};

export type MutationUpsertPaymentConfigArgs = {
    create?: Maybe<PaymentConfigCreateInput>;
    update?: Maybe<PaymentConfigUpdateInput>;
};

export type MutationVerifyCustomSmtpServerArgs = {
    id: Scalars["String"];
};

export type MutationVerifyCustomSmtpSpfServerArgs = {
    senderDomain: Scalars["String"];
};

export type MutationVerifyMessageExternalProviderArgs = {
    id: Scalars["String"];
    receiverNumber: Scalars["String"];
};

export type MutationWriteToDocumentArgs = {
    documentUrl: Scalars["String"];
    index: Scalars["Int"];
    session: WhereIdInput;
    text: Array<GoogleDocTextWrite>;
};

export type MyProductTool = {
    __typename?: "MyProductTool";
    consentOwnershipOfIcon: Scalars["Boolean"];
    description: Scalars["String"];
    enabled: Scalars["Boolean"];
    iconUrl: Scalars["String"];
    id: Scalars["ID"];
    ownerTimezone?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    url: Scalars["String"];
};

export type MyProductToolOwnerTimezoneArgs = {
    sessionId?: Maybe<Scalars["String"]>;
};

export type MyProductToolCreateInput = {
    consentOwnershipOfIcon?: Maybe<Scalars["Boolean"]>;
    description: Scalars["String"];
    enabled?: Maybe<Scalars["Boolean"]>;
    iconUrl: Scalars["String"];
    title: Scalars["String"];
    url: Scalars["String"];
};

export type MyProductToolUpdateInput = {
    consentOwnershipOfIcon?: Maybe<BoolFieldUpdateOperationsInput>;
    description?: Maybe<StringFieldUpdateOperationsInput>;
    enabled?: Maybe<BoolFieldUpdateOperationsInput>;
    iconUrl?: Maybe<StringFieldUpdateOperationsInput>;
    title?: Maybe<StringFieldUpdateOperationsInput>;
    url?: Maybe<StringFieldUpdateOperationsInput>;
};

export type MyProductToolUpsertWithWhereUniqueWithoutWorkspaceInput = {
    create: MyProductToolCreateInput;
    update: MyProductToolUpdateInput;
    where: WhereIdInput;
};

export type NameOrderByInput = {
    name?: Maybe<SortOrder>;
};

export type NestedBoolFilter = {
    equals?: Maybe<Scalars["Boolean"]>;
    not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
    equals?: Maybe<Scalars["Boolean"]>;
    not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
    equals?: Maybe<Scalars["DateTime"]>;
    gt?: Maybe<Scalars["DateTime"]>;
    gte?: Maybe<Scalars["DateTime"]>;
    in?: Maybe<Array<Scalars["DateTime"]>>;
    lt?: Maybe<Scalars["DateTime"]>;
    lte?: Maybe<Scalars["DateTime"]>;
    not?: Maybe<NestedDateTimeFilter>;
    notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableFilter = {
    equals?: Maybe<Scalars["DateTime"]>;
    gt?: Maybe<Scalars["DateTime"]>;
    gte?: Maybe<Scalars["DateTime"]>;
    in?: Maybe<Array<Scalars["DateTime"]>>;
    lt?: Maybe<Scalars["DateTime"]>;
    lte?: Maybe<Scalars["DateTime"]>;
    not?: Maybe<NestedDateTimeNullableFilter>;
    notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type NestedIntFilter = {
    equals?: Maybe<Scalars["Int"]>;
    gt?: Maybe<Scalars["Int"]>;
    gte?: Maybe<Scalars["Int"]>;
    in?: Maybe<Array<Scalars["Int"]>>;
    lt?: Maybe<Scalars["Int"]>;
    lte?: Maybe<Scalars["Int"]>;
    not?: Maybe<NestedIntFilter>;
    notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableFilter = {
    equals?: Maybe<Scalars["Int"]>;
    gt?: Maybe<Scalars["Int"]>;
    gte?: Maybe<Scalars["Int"]>;
    in?: Maybe<Array<Scalars["Int"]>>;
    lt?: Maybe<Scalars["Int"]>;
    lte?: Maybe<Scalars["Int"]>;
    not?: Maybe<NestedIntNullableFilter>;
    notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type NestedStringFilter = {
    contains?: Maybe<Scalars["String"]>;
    endsWith?: Maybe<Scalars["String"]>;
    equals?: Maybe<Scalars["String"]>;
    gt?: Maybe<Scalars["String"]>;
    gte?: Maybe<Scalars["String"]>;
    in?: Maybe<Array<Scalars["String"]>>;
    lt?: Maybe<Scalars["String"]>;
    lte?: Maybe<Scalars["String"]>;
    not?: Maybe<NestedStringFilter>;
    notIn?: Maybe<Array<Scalars["String"]>>;
    startsWith?: Maybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
    contains?: Maybe<Scalars["String"]>;
    endsWith?: Maybe<Scalars["String"]>;
    equals?: Maybe<Scalars["String"]>;
    gt?: Maybe<Scalars["String"]>;
    gte?: Maybe<Scalars["String"]>;
    in?: Maybe<Array<Scalars["String"]>>;
    lt?: Maybe<Scalars["String"]>;
    lte?: Maybe<Scalars["String"]>;
    not?: Maybe<NestedStringNullableFilter>;
    notIn?: Maybe<Array<Scalars["String"]>>;
    startsWith?: Maybe<Scalars["String"]>;
};

export type NewQuestionsCount = {
    __typename?: "NewQuestionsCount";
    count: Scalars["Int"];
};

export type NoSessionFoundResponse = {
    __typename?: "NoSessionFoundResponse";
    noSessionFound: Scalars["Boolean"];
};

export type Notification = {
    __typename?: "Notification";
    body?: Maybe<Scalars["String"]>;
    cta?: Maybe<Scalars["String"]>;
    ctaUrl?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    title?: Maybe<Scalars["String"]>;
};

export type NotionParentPage = {
    __typename?: "NotionParentPage";
    default: Scalars["Boolean"];
    id: Scalars["String"];
    title: Scalars["String"];
};

export enum NotionSyncStatus {
    NotSynced = "NOT_SYNCED",
    NoPagesSelected = "NO_PAGES_SELECTED",
    Synced = "SYNCED",
}

export type NotNullDbFilter = {
    isNot?: Maybe<Array<NotNullDbFilter>>;
};

export type NullableBoolFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["Boolean"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["DateTime"]>;
};

export type NullableDbFilter = {
    is?: Maybe<IsNullDbFilter>;
    isNot?: Maybe<NotNullDbFilter>;
};

export type NullableIntFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["Int"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["String"]>;
};

export type Offer = {
    __typename?: "Offer";
    createdAt: Scalars["DateTime"];
    ctaText: Scalars["String"];
    ctaUrl: Scalars["String"];
    description: Scalars["String"];
    id: Scalars["ID"];
    imageUrl: Scalars["String"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    oldId?: Maybe<Scalars["String"]>;
    sessionId?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    update?: Maybe<OfferDiff>;
    updatedAt: Scalars["DateTime"];
    userId: Scalars["String"];
};

export type OfferAsset = {
    __typename?: "OfferAsset";
    publicUrl?: Maybe<Scalars["String"]>;
    uploadUrl?: Maybe<Scalars["String"]>;
};

export type OfferContainerUpdatePayload = {
    __typename?: "OfferContainerUpdatePayload";
    deleted?: Maybe<Scalars["Boolean"]>;
    list?: Maybe<Scalars["Boolean"]>;
    offer?: Maybe<Array<Maybe<Offer>>>;
};

export type OfferDiff = {
    __typename?: "OfferDiff";
    createdAt?: Maybe<Scalars["DateTime"]>;
    ctaText?: Maybe<Scalars["String"]>;
    ctaUrl?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    newId?: Maybe<Scalars["ID"]>;
    imageUrl?: Maybe<Scalars["String"]>;
    sessionId?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    userId?: Maybe<Scalars["String"]>;
};

export type OfferInput = {
    ctaText: Scalars["String"];
    ctaUrl: Scalars["String"];
    description: Scalars["String"];
    imageUrl: Scalars["String"];
    title: Scalars["String"];
};

export type OnboardingFieldType = {
    key: Scalars["String"];
    value: Scalars["String"];
};

export type OnGoogleContactsSyncFinishedResult = {
    __typename?: "OnGoogleContactsSyncFinishedResult";
    userEmail: Scalars["String"];
};

export type OnMicrosoftContactsSyncFinishedResult = {
    __typename?: "OnMicrosoftContactsSyncFinishedResult";
    userEmail: Scalars["String"];
};

export type OnPushSessionViewResult = {
    __typename?: "OnPushSessionViewResult";
    participantId: Scalars["String"];
    sessionView: Scalars["String"];
};

export type OnReactionSendResult = {
    __typename?: "OnReactionSendResult";
    participantId: Scalars["String"];
    reactionType: ReactionType;
};

export type OnRecordingUpdated = {
    __typename?: "OnRecordingUpdated";
    recording: SessionRecording;
};

export type OnSessionDiffSendResult = {
    __typename?: "onSessionDiffSendResult";
    collaborativeSessionJson?: Maybe<Scalars["Json"]>;
    corrupted?: Maybe<Scalars["Boolean"]>;
    discarded?: Maybe<Scalars["Boolean"]>;
    persisted?: Maybe<Scalars["Boolean"]>;
    sessionDiffJson?: Maybe<Scalars["Json"]>;
    sessionId: Scalars["String"];
};

export type OrderOrderByInput = {
    order: SortOrder;
};

export type Organization = {
    __typename?: "Organization";
    appSumoInvoiceItemId?: Maybe<Scalars["String"]>;
    billingPeriod?: Maybe<BillingPeriod>;
    celloUcc?: Maybe<Scalars["String"]>;
    companyDomain?: Maybe<Scalars["String"]>;
    companyName?: Maybe<Scalars["String"]>;
    companyWebsite?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    discountCode?: Maybe<Scalars["String"]>;
    discountCodeLabel?: Maybe<Scalars["String"]>;
    eventActivity: Scalars["Json"];
    id: Scalars["ID"];
    isActivated: Scalars["Boolean"];
    lastOrderId?: Maybe<Scalars["String"]>;
    livestreamActivity?: Maybe<Scalars["Float"]>;
    members: Array<User>;
    membersCount: Scalars["Int"];
    name?: Maybe<Scalars["String"]>;
    onboarding?: Maybe<Scalars["Json"]>;
    onboardingCompleted: Scalars["Boolean"];
    paddleSubscriptionState?: Maybe<PaddleSubscriptionState>;
    permanentPlan?: Maybe<OrganizationPlan>;
    plan: OrganizationPlan;
    planEffectiveCancellationDate?: Maybe<Scalars["DateTime"]>;
    planNextPaymentDate?: Maybe<Scalars["DateTime"]>;
    processingSubscription?: Maybe<Scalars["Boolean"]>;
    recordingActivity?: Maybe<Scalars["Float"]>;
    referralDiscountCode?: Maybe<Scalars["String"]>;
    reverseTrialCancellationDate?: Maybe<Scalars["DateTime"]>;
    reverseTrialExpired?: Maybe<Scalars["Boolean"]>;
    size?: Maybe<Scalars["String"]>;
    subscriptionId?: Maybe<Scalars["String"]>;
    subscriptionModifiers: Array<SubscriptionModifier>;
    subscriptionTrialUsed: Scalars["Boolean"];
};

export type OrganizationMembersArgs = {
    excludeSelf?: Maybe<Scalars["Boolean"]>;
};

export enum OrganizationPermissionType {
    Member = "MEMBER",
    Owner = "OWNER",
}

export enum OrganizationPlan {
    AppsumoTier_1 = "APPSUMO_TIER_1",
    AppsumoTier_2 = "APPSUMO_TIER_2",
    AppsumoTier_3 = "APPSUMO_TIER_3",
    AppsumoTier_4 = "APPSUMO_TIER_4",
    AppsumoTier_5 = "APPSUMO_TIER_5",
    Business = "BUSINESS",
    Free = "FREE",
    LtdTier_1 = "LTD_TIER_1",
    LtdTier_2 = "LTD_TIER_2",
    LtdTier_3 = "LTD_TIER_3",
    LtdTier_4 = "LTD_TIER_4",
    LtdTier_5 = "LTD_TIER_5",
    LtdTier_6 = "LTD_TIER_6",
    Pro = "PRO",
    ProLtd = "PRO_LTD",
    ProLtdSaaszilla = "PRO_LTD_SAASZILLA",
    SessionsSupporter = "SESSIONS_SUPPORTER",
    Solo = "SOLO",
    Starter = "STARTER",
}

export type OrganizationUpdateInput = {
    companyDomain?: Maybe<NullableStringFieldUpdateOperationsInput>;
    companyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
    companyWebsite?: Maybe<NullableStringFieldUpdateOperationsInput>;
    isActivated?: Maybe<BoolFieldUpdateOperationsInput>;
    name?: Maybe<NullableStringFieldUpdateOperationsInput>;
    onboarding?: Maybe<Scalars["Json"]>;
    onboardingCompleted?: Maybe<BoolFieldUpdateOperationsInput>;
    processingSubscription?: Maybe<BoolFieldUpdateOperationsInput>;
    size?: Maybe<NullableStringFieldUpdateOperationsInput>;
    suspended?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type OrganizationWhereInput = {
    AND?: Maybe<Array<OrganizationWhereInput>>;
    NOT?: Maybe<Array<OrganizationWhereInput>>;
    OR?: Maybe<Array<OrganizationWhereInput>>;
    companyDomain?: Maybe<StringNullableFilter>;
    companyName?: Maybe<StringNullableFilter>;
    companyWebsite?: Maybe<StringNullableFilter>;
    createdAt?: Maybe<DateTimeFilter>;
    id?: Maybe<StringFilter>;
    isActivated?: Maybe<BoolFilter>;
    memberPermissions?: Maybe<UserToOrganizationPermissionListRelationFilter>;
    name?: Maybe<StringNullableFilter>;
    plan?: Maybe<OrganizationPlan>;
    suspended?: Maybe<BoolFilter>;
    updatedAt?: Maybe<DateTimeFilter>;
};

export type PaddlePaymentDetails = {
    __typename?: "PaddlePaymentDetails";
    amount: Scalars["Float"];
    currency: Scalars["String"];
    date: Scalars["String"];
};

export type PaddlePaymentInfo = {
    __typename?: "PaddlePaymentInfo";
    card_type?: Maybe<Scalars["String"]>;
    expiry_date?: Maybe<Scalars["String"]>;
    last_four_digits?: Maybe<Scalars["String"]>;
    payment_method: Scalars["String"];
};

export type PaddlePlan = {
    __typename?: "PaddlePlan";
    billing_period: Scalars["Int"];
    billing_type: Scalars["String"];
    id: Scalars["String"];
    initial_price?: Maybe<Array<PaddlePlanPrice>>;
    modifiers?: Maybe<Array<PaddleSubscriptionModifierPrice>>;
    name: Scalars["String"];
    plan: OrganizationPlan;
    recurring_price?: Maybe<Array<PaddlePlanPrice>>;
    trial_days?: Maybe<Scalars["Int"]>;
};

export type PaddlePlanPrice = {
    __typename?: "PaddlePlanPrice";
    amount: Scalars["Float"];
    currency: Scalars["String"];
};

export type PaddleSubscriptionModifierPrice = {
    __typename?: "PaddleSubscriptionModifierPrice";
    id: Scalars["ID"];
    pricePerUnitInDollars: Scalars["Float"];
    type: SubscriptionModifierType;
};

export enum PaddleSubscriptionState {
    Active = "ACTIVE",
    Deleted = "DELETED",
    PastDue = "PAST_DUE",
    Paused = "PAUSED",
    Trialing = "TRIALING",
}

export type PaletteItemAgenda = PaletteItemBase & {
    __typename?: "PaletteItemAgenda";
    agendaId: Scalars["String"];
    id: Scalars["String"];
    isPublic?: Maybe<Scalars["Boolean"]>;
    lastUsed?: Maybe<Scalars["DateTime"]>;
    mode: PaletteItemMode;
    name: Scalars["String"];
    sharedWith?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PaletteItemBase = {
    id: Scalars["String"];
    isPublic?: Maybe<Scalars["Boolean"]>;
    lastUsed?: Maybe<Scalars["DateTime"]>;
    mode: PaletteItemMode;
    name: Scalars["String"];
};

export type PaletteItemFile = PaletteItemBase & {
    __typename?: "PaletteItemFile";
    file_type: PaletteItemFileType;
    id: Scalars["String"];
    isPublic?: Maybe<Scalars["Boolean"]>;
    lastUsed?: Maybe<Scalars["DateTime"]>;
    mode: PaletteItemMode;
    name: Scalars["String"];
    resourceId?: Maybe<Scalars["String"]>;
};

export enum PaletteItemFileType {
    Doc = "doc",
    Excel = "excel",
    Image = "image",
    Keynote = "keynote",
    Numbers = "numbers",
    Odp = "odp",
    Ods = "ods",
    Odt = "odt",
    Pages = "pages",
    Pdf = "pdf",
    Presentation = "presentation",
    Video = "video",
}

export enum PaletteItemMode {
    Agenda = "agenda",
    File = "file",
    Tool = "tool",
}

export enum PaletteItemPermissionType {
    Private = "private",
    Public = "public",
}

export type PaletteItemTool = PaletteItemBase & {
    __typename?: "PaletteItemTool";
    id: Scalars["String"];
    isPublic?: Maybe<Scalars["Boolean"]>;
    lastUsed?: Maybe<Scalars["DateTime"]>;
    mode: PaletteItemMode;
    name: Scalars["String"];
    resourceId?: Maybe<Scalars["String"]>;
    tool_type: PaletteItemToolType;
};

export enum PaletteItemToolType {
    Adobexd = "adobexd",
    Airtable = "airtable",
    Boardmix = "boardmix",
    Canva = "canva",
    Figma = "figma",
    Form = "form",
    GoogleDocs = "google_docs",
    GoogleForms = "google_forms",
    GooglePresentation = "google_presentation",
    GoogleSheets = "google_sheets",
    Mentimeter = "mentimeter",
    Miro = "miro",
    Pitch = "pitch",
    Slido = "slido",
    Smartboard = "smartboard",
    Twitch = "twitch",
    Vimeo = "vimeo",
    Youtube = "youtube",
}

export type PaletteSearchInput = {
    fileType?: Maybe<Array<PaletteItemFileType>>;
    limit?: Maybe<Scalars["Int"]>;
    mode?: Maybe<Array<PaletteItemMode>>;
    offset?: Maybe<Scalars["Int"]>;
    orderBy?: Maybe<PaletteSearchOrderBy>;
    permissions?: Maybe<Array<PaletteItemPermissionType>>;
    query?: Maybe<Scalars["String"]>;
    toolType?: Maybe<Array<PaletteItemToolType>>;
};

export enum PaletteSearchOrderBy {
    Default = "default",
    Recent = "recent",
}

export type Participant = {
    __typename?: "Participant";
    askToStartCamera: Scalars["Boolean"];
    askToUnmute: Scalars["Boolean"];
    changedBy?: Maybe<ParticipantChangedBy>;
    conferenceStatus: ConferenceParticipantStatus;
    conferenceToken?: Maybe<ConferenceToken>;
    consentedToRecord: Scalars["Boolean"];
    contextualNoteJson: Scalars["Json"];
    controlStatus?: Maybe<ControlStatus>;
    createdAt: Scalars["DateTime"];
    dataWithNullableEmail: ParticipantDataWithNullableEmail;
    guestId?: Maybe<Scalars["String"]>;
    hasAttendedSession: Scalars["Boolean"];
    id: Scalars["ID"];
    inviteStatus: ParticipantInviteStatus;
    invitedByTheOwner: Scalars["Boolean"];
    isApproved: Scalars["Boolean"];
    isInSession?: Maybe<Scalars["Boolean"]>;
    isMainParticipant?: Maybe<Scalars["Boolean"]>;
    isOwner: Scalars["Boolean"];
    isRecorder: Scalars["Boolean"];
    lastSeenTimestamp?: Maybe<Scalars["DateTime"]>;
    mockedParticipant: Scalars["Boolean"];
    muted: Scalars["Boolean"];
    paymentTransaction?: Maybe<PaymentTransaction>;
    pinnedTranscriptions: Array<SessionTranscript>;
    presenceHistory?: Maybe<Scalars["Json"]>;
    rbac: Scalars["Json"];
    session: Session;
    sessionId: Scalars["String"];
    source: ParticipantSource;
    speakerDetails?: Maybe<SessionSpeaker>;
    status: ParticipantStatus;
    statusChangedBy?: Maybe<ParticipantStatusChangedBy>;
    submittedPasscode: Scalars["Boolean"];
    updatedAt: Scalars["DateTime"];
    userId?: Maybe<Scalars["String"]>;
};

export enum ParticipantAction {
    Ban = "BAN",
    Enter = "ENTER",
    Invite = "INVITE",
    Kick = "KICK",
    Leave = "LEAVE",
}

export enum ParticipantChangedBy {
    Assistant = "ASSISTANT",
    Himself = "HIMSELF",
    Owner = "OWNER",
    System = "SYSTEM",
}

export type ParticipantChangedByData = {
    __typename?: "ParticipantChangedByData";
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    participantId: Scalars["String"];
    unableToBecomeSpeaker?: Maybe<Scalars["Boolean"]>;
};

export type ParticipantCreateInput = {
    ControlStatus?: Maybe<ControlStatus>;
    askToStartCamera?: Maybe<Scalars["Boolean"]>;
    askToUnmute?: Maybe<Scalars["Boolean"]>;
    changedBy?: Maybe<ParticipantChangedBy>;
    conferenceStatus?: Maybe<ConferenceParticipantStatus>;
    connectionId?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    guest?: Maybe<GuestCreateOneWithoutParticipantInSessionsInput>;
    hasAttendedSession?: Maybe<Scalars["Boolean"]>;
    id?: Maybe<Scalars["String"]>;
    inviteStatus?: Maybe<ParticipantInviteStatus>;
    invitedByTheOwner?: Maybe<Scalars["Boolean"]>;
    isApproved?: Maybe<Scalars["Boolean"]>;
    isOwner?: Maybe<Scalars["Boolean"]>;
    isRecorder?: Maybe<Scalars["Boolean"]>;
    lastChimePresenceTs?: Maybe<Scalars["String"]>;
    mockedParticipant?: Maybe<Scalars["Boolean"]>;
    muted?: Maybe<Scalars["Boolean"]>;
    presenceHistory?: Maybe<Scalars["Json"]>;
    rbac?: Maybe<Scalars["Json"]>;
    source?: Maybe<ParticipantSource>;
    status?: Maybe<ParticipantStatus>;
    submittedPasscode?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<UserConnectInput>;
};

export type ParticipantCreateManyInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<ParticipantCreateInput>>;
};

export type ParticipantDataWithNullableEmail = {
    __typename?: "ParticipantDataWithNullableEmail";
    avatar?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    firstName: Scalars["String"];
    isGuest: Scalars["Boolean"];
    lastName: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
    userId: Scalars["String"];
};

export type ParticipantForEvent = {
    __typename?: "ParticipantForEvent";
    avatar?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    firstName: Scalars["String"];
    id: Scalars["String"];
    inviteStatus: ParticipantInviteStatus;
    isGuest: Scalars["Boolean"];
    isOwner: Scalars["Boolean"];
    lastName: Scalars["String"];
    rbac: Scalars["Json"];
    status: ParticipantStatus;
    token?: Maybe<Scalars["String"]>;
};

export type ParticipantHighlight = {
    __typename?: "ParticipantHighlight";
    email?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
};

export enum ParticipantInviteStatus {
    Accepted = "ACCEPTED",
    Declined = "DECLINED",
    NeedsAction = "NEEDS_ACTION",
    Tentative = "TENTATIVE",
}

export type ParticipantListRelationFilter = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<ParticipantCreateInput>>;
    every?: Maybe<ParticipantWhereInput>;
    none?: Maybe<ParticipantWhereInput>;
    some?: Maybe<ParticipantWhereInput>;
};

export type ParticipantSearchResult = {
    __typename?: "ParticipantSearchResult";
    data: Participant;
    presentInSessions: Scalars["Int"];
};

export enum ParticipantSessionState {
    Assistant = "ASSISTANT",
    CanJoin = "CAN_JOIN",
    ExceededOrganizationLimits = "EXCEEDED_ORGANIZATION_LIMITS",
    NotAllowed = "NOT_ALLOWED",
    Owner = "OWNER",
}

export enum ParticipantsMacroAccess {
    Disabled = "DISABLED",
    Everyone = "EVERYONE",
    Moderators = "MODERATORS",
}

export enum ParticipantSource {
    App = "APP",
    GoogleWorkspace = "GOOGLE_WORKSPACE",
}

export enum ParticipantStatus {
    InLobby = "IN_LOBBY",
    JoinedSession = "JOINED_SESSION",
    Kicked = "KICKED",
    NotJoined = "NOT_JOINED",
    WaitForApproval = "WAIT_FOR_APPROVAL",
}

export type ParticipantStatusChangedBy = {
    __typename?: "ParticipantStatusChangedBy";
    participantId?: Maybe<Scalars["String"]>;
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    unableToBecomeSpeaker?: Maybe<Scalars["Boolean"]>;
};

export type ParticipantSubscriptionPayload = {
    __typename?: "ParticipantSubscriptionPayload";
    dataWithNullableEmail?: Maybe<ParticipantDataWithNullableEmail>;
    id: Scalars["String"];
};

export type ParticipantWhereInput = {
    AND?: Maybe<Array<ParticipantWhereInput>>;
    OR?: Maybe<Array<ParticipantWhereInput>>;
    guest?: Maybe<GuestWhereInput>;
    id?: Maybe<Scalars["String"]>;
    inviteStatus?: Maybe<ParticipantInviteStatus>;
    isOwner?: Maybe<BoolFilter>;
    lastSeenTimestamp?: Maybe<DateTimeNullableFilter>;
    sessionId?: Maybe<Scalars["String"]>;
    user?: Maybe<UserWhereInput>;
};

export type PaymentConfig = {
    __typename?: "PaymentConfig";
    amount: Scalars["Int"];
    bookingEvent?: Maybe<BookingEvent>;
    currency: Scalars["String"];
    enabled: Scalars["Boolean"];
    id: Scalars["ID"];
    owner: User;
    ownerId: Scalars["String"];
    paymentProviders?: Maybe<Array<Maybe<PaymentProvider>>>;
    sessionEvent?: Maybe<SessionEvent>;
    terms?: Maybe<Scalars["String"]>;
};

export type PaymentConfigCreateInput = {
    amount: Scalars["Int"];
    bookingEventId?: Maybe<Scalars["String"]>;
    currency: Scalars["String"];
    enabled: Scalars["Boolean"];
    paymentProviderIds: Array<Scalars["String"]>;
    sessionEventId?: Maybe<Scalars["String"]>;
    terms?: Maybe<Scalars["String"]>;
};

export type PaymentConfigUpdateInput = {
    amount?: Maybe<Scalars["Int"]>;
    currency?: Maybe<Scalars["String"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    id: Scalars["String"];
    paymentProviderIds?: Maybe<Array<Scalars["String"]>>;
    terms?: Maybe<Scalars["String"]>;
};

export enum PaymentIntendStatus {
    PaymentCanceled = "PAYMENT_CANCELED",
    PaymentCreated = "PAYMENT_CREATED",
    PaymentFailed = "PAYMENT_FAILED",
    PaymentSucceeded = "PAYMENT_SUCCEEDED",
}

export type PaymentProvider = {
    __typename?: "PaymentProvider";
    accountName?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
    name: Scalars["String"];
};

export type PaymentsConfigConnectProvidersInput = {
    connect: Array<WhereIdInput>;
};

export type PaymentTransaction = {
    __typename?: "PaymentTransaction";
    checkoutSessionId: Scalars["String"];
    checkoutSessionStatus: CheckoutSessionStatus;
    checkoutSessionUrl?: Maybe<Scalars["String"]>;
    externalProviderId?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    participant?: Maybe<Participant>;
    paymentIntendId?: Maybe<Scalars["String"]>;
    paymentIntendStatus?: Maybe<PaymentIntendStatus>;
    paymentProvider?: Maybe<PaymentProvider>;
    paymentProviderType?: Maybe<Scalars["String"]>;
};

export type PaypalPayment = {
    __typename?: "PaypalPayment";
    paymentId: Scalars["String"];
    paymentStatus: Scalars["String"];
    redirectUrl?: Maybe<Scalars["String"]>;
};

export type PayPalSyncedAccount = {
    __typename?: "PayPalSyncedAccount";
    merchantId?: Maybe<Scalars["String"]>;
    providerId?: Maybe<Scalars["String"]>;
};

export type Poll = {
    __typename?: "Poll";
    active: Scalars["Boolean"];
    completed: Scalars["Boolean"];
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    makeResultsAnonymous: Scalars["Boolean"];
    numberOfUniqueVotes: Scalars["Int"];
    options: Array<PollOption>;
    pollType: PollType;
    questionBody: Scalars["String"];
    resultsSubmitted: Scalars["Boolean"];
    showResultsToParticipants: Scalars["Boolean"];
    textAnswers: Array<PollTextAnswer>;
};

export type PollCreateInput = {
    active: Scalars["Boolean"];
    completed?: Maybe<Scalars["Boolean"]>;
    makeResultsAnonymous?: Maybe<Scalars["Boolean"]>;
    options: PollOptionCreateManyWithoutPollInput;
    pollType: PollType;
    questionBody: Scalars["String"];
    session: ConnectOneInput;
    showResultsToParticipants?: Maybe<Scalars["Boolean"]>;
};

export type PollOption = {
    __typename?: "PollOption";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    numberOfVotes?: Maybe<Scalars["Int"]>;
    optionBody: Scalars["String"];
    participantIds: Array<Scalars["String"]>;
    submittedByOwner: Scalars["Boolean"];
};

export type PollOptionCreateManyWithoutPollInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<PollOptionCreateWithoutPollInput>>;
};

export type PollOptionCreateWithoutPollInput = {
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    optionBody: Scalars["String"];
    submittedByOwner: Scalars["Boolean"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PollOptionScalarWhereInput = {
    AND?: Maybe<Array<PollOptionScalarWhereInput>>;
    NOT?: Maybe<Array<PollOptionScalarWhereInput>>;
    OR?: Maybe<Array<PollOptionScalarWhereInput>>;
    createdAt?: Maybe<DateTimeFilter>;
    id?: Maybe<StringFilter>;
    optionBody?: Maybe<StringFilter>;
    pollId?: Maybe<StringFilter>;
    submittedByOwner?: Maybe<BoolFilter>;
    updatedAt?: Maybe<DateTimeFilter>;
};

export type PollOptionUpdateManyWithoutPollInput = {
    deleteMany?: Maybe<Array<PollOptionScalarWhereInput>>;
    upsert?: Maybe<Array<PollOptionUpsertWithWhereUniqueWithoutPollInput>>;
};

export type PollOptionUpdateWithoutPollDataInput = {
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    optionBody?: Maybe<StringFieldUpdateOperationsInput>;
    submittedByOwner?: Maybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PollOptionUpsertWithWhereUniqueWithoutPollInput = {
    create: PollOptionCreateWithoutPollInput;
    update: PollOptionUpdateWithoutPollDataInput;
    where: WhereIdInput;
};

export type PollSubscriptionOwnerPayload = {
    __typename?: "PollSubscriptionOwnerPayload";
    allPollsOnSession?: Maybe<Session>;
    individualPollOptions?: Maybe<Array<PollOption>>;
    individualPollTextAnswers?: Maybe<Array<Poll>>;
    individualPolls?: Maybe<Array<Poll>>;
    pollUniqueVotes?: Maybe<Poll>;
};

export type PollSubscriptionParticipantPayload = {
    __typename?: "PollSubscriptionParticipantPayload";
    allPollsOnSession?: Maybe<Session>;
    individualPollOptions?: Maybe<Array<PollOption>>;
    individualPollTextAnswers?: Maybe<Array<Poll>>;
    individualPolls?: Maybe<Array<Poll>>;
    pollUniqueVotes?: Maybe<Poll>;
};

export type PollTextAnswer = {
    __typename?: "PollTextAnswer";
    id: Scalars["ID"];
    participantId: Scalars["String"];
    poll: Poll;
    pollId: Scalars["String"];
    text: Scalars["String"];
};

export enum PollType {
    MultipleChoice = "MULTIPLE_CHOICE",
    SingleChoice = "SINGLE_CHOICE",
    Text = "TEXT",
}

export type PollUpdateInput = {
    active?: Maybe<BoolFieldUpdateOperationsInput>;
    completed?: Maybe<BoolFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    makeResultsAnonymous?: Maybe<BoolFieldUpdateOperationsInput>;
    options?: Maybe<PollOptionUpdateManyWithoutPollInput>;
    pollType?: Maybe<PollType>;
    questionBody?: Maybe<StringFieldUpdateOperationsInput>;
    showResultsToParticipants?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type PreviousSessionDetails = {
    previousParticipantId: Scalars["String"];
    sendEmailFromSessionId: Scalars["String"];
};

export enum PrivateChatAccess {
    Disabled = "DISABLED",
    Everyone = "EVERYONE",
    Moderators = "MODERATORS",
}

export type Property = {
    __typename?: "Property";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    key: Scalars["String"];
    oldId?: Maybe<Scalars["String"]>;
    update?: Maybe<PropertyDiff>;
    updatedAt: Scalars["DateTime"];
    value: Scalars["String"];
};

export type PropertyCreateCustomInput = {
    key: Scalars["String"];
    value: Scalars["String"];
};

export type PropertyCreateManyWithoutArtifactInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    create?: Maybe<Array<PropertyCreateWithoutArtifactInput>>;
};

export type PropertyCreateWithoutArtifactInput = {
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    key: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    value: Scalars["String"];
};

export type PropertyDiff = {
    __typename?: "PropertyDiff";
    createdAt?: Maybe<Scalars["DateTime"]>;
    newId?: Maybe<Scalars["ID"]>;
    key?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    value?: Maybe<Scalars["String"]>;
};

export type PropertyUpdateManyWithoutArtifactInput = {
    create?: Maybe<Array<PropertyCreateWithoutArtifactInput>>;
    delete?: Maybe<Array<WhereIdInput>>;
    update?: Maybe<Array<PropertyUpdateWithWhereUniqueWithoutArtifactInput>>;
};

export type PropertyUpdateWithoutArtifactDataInput = {
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    key?: Maybe<StringFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PropertyUpdateWithWhereUniqueWithoutArtifactInput = {
    data: PropertyUpdateWithoutArtifactDataInput;
    where: WhereIdInput;
};

export type PspdfKitDocumentResponse = {
    __typename?: "PspdfKitDocumentResponse";
    documentId: Scalars["String"];
    title: Scalars["String"];
};

export enum PublicApiIntegration {
    ActivePieces = "ACTIVE_PIECES",
    Pabbly = "PABBLY",
    Zapier = "ZAPIER",
}

export type PublicApiKey = {
    __typename?: "PublicApiKey";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    integration?: Maybe<PublicApiIntegration>;
    name: Scalars["String"];
    role?: Maybe<PublicApiKeyRole>;
    tokenPreview: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    userId: Scalars["String"];
};

export type PublicApiKeyCreateInput = {
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    role?: Maybe<PublicApiKeyRole>;
};

export enum PublicApiKeyRole {
    Guest = "GUEST",
    User = "USER",
}

export type PublicBookingEvent = {
    __typename?: "PublicBookingEvent";
    additionalDuration?: Maybe<Array<Scalars["Int"]>>;
    afterRegistrationOffer?: Maybe<Offer>;
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    allowAdditionalGuests?: Maybe<Scalars["Boolean"]>;
    branding?: Maybe<Branding>;
    collaborators: Array<PublicBookingEventCollaborator>;
    days: Scalars["Json"];
    description?: Maybe<Scalars["String"]>;
    disabled: Scalars["Boolean"];
    duration: Scalars["Int"];
    from: Scalars["DateTime"];
    hours: Scalars["Json"];
    id?: Maybe<Scalars["ID"]>;
    name: Scalars["String"];
    organizationPlan?: Maybe<OrganizationPlan>;
    ownerFirstDayOfWeek?: Maybe<Scalars["String"]>;
    ownerFirstName: Scalars["String"];
    ownerHalfDayClock?: Maybe<Scalars["Boolean"]>;
    ownerId: Scalars["String"];
    ownerLanguage?: Maybe<Scalars["String"]>;
    ownerLastName: Scalars["String"];
    ownerProfilePicture?: Maybe<Scalars["String"]>;
    payments?: Maybe<Array<PaymentConfig>>;
    questionnare?: Maybe<Scalars["Json"]>;
    scheduleWindow?: Maybe<Scalars["Int"]>;
    slug?: Maybe<Scalars["String"]>;
    timeZone: Scalars["String"];
    to?: Maybe<Scalars["DateTime"]>;
};

export type PublicBookingEventAvailableSlot = {
    __typename?: "PublicBookingEventAvailableSlot";
    available?: Maybe<Scalars["Boolean"]>;
    collaborators?: Maybe<Array<Scalars["String"]>>;
    conflicts?: Maybe<Array<Maybe<Scalars["String"]>>>;
    day: Scalars["DateTime"];
    id: Scalars["ID"];
    slot: Scalars["Int"];
};

export type PublicBookingEventCollaborator = {
    __typename?: "PublicBookingEventCollaborator";
    collaboratorId: Scalars["String"];
    userId: Scalars["String"];
    userName: Scalars["String"];
};

export type PublicBookingEventListItem = {
    __typename?: "PublicBookingEventListItem";
    additionalDuration?: Maybe<Array<Scalars["Int"]>>;
    description?: Maybe<Scalars["String"]>;
    duration: Scalars["Int"];
    id?: Maybe<Scalars["ID"]>;
    name: Scalars["String"];
    ownerFirstName: Scalars["String"];
    ownerId: Scalars["String"];
    ownerLastName: Scalars["String"];
    ownerProfilePicture?: Maybe<Scalars["String"]>;
    payments?: Maybe<Array<PaymentConfig>>;
    slug?: Maybe<Scalars["String"]>;
};

export type PublicWorkspaceInfo = {
    __typename?: "PublicWorkspaceInfo";
    branding?: Maybe<Branding>;
    id?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
};

export type PushDrawerState = {
    __typename?: "PushDrawerState";
    activeComponent?: Maybe<Scalars["String"]>;
    isDetached?: Maybe<Scalars["Boolean"]>;
    state: Scalars["String"];
};

export type PushDrawerStateInput = {
    activeComponent?: Maybe<Scalars["String"]>;
    isDetached?: Maybe<Scalars["Boolean"]>;
    state: Scalars["String"];
};

export type PushDrawerStateResult = {
    __typename?: "PushDrawerStateResult";
    leftDrawer?: Maybe<PushDrawerState>;
    rightDrawer?: Maybe<PushDrawerState>;
};

export type QaQuestionsContainerUpdatePayload = {
    __typename?: "QaQuestionsContainerUpdatePayload";
    question?: Maybe<Question>;
};

export type Query = {
    __typename?: "Query";
    accessListItems: Array<AccessListItem>;
    agendaItems: Array<AgendaItem>;
    agendaTemplate?: Maybe<AgendaTemplate>;
    agendaTemplates: Array<AgendaTemplate>;
    artifact?: Maybe<Artifact>;
    artifacts: Array<Artifact>;
    bookTimeSlots?: Maybe<Array<PublicBookingEventAvailableSlot>>;
    bookedEvent?: Maybe<BookedEvent>;
    booking?: Maybe<Booking>;
    bookingEvent?: Maybe<BookingEvent>;
    bookingEventContacts?: Maybe<Array<BookingEventContact>>;
    bookingEvents: Array<BookingEvent>;
    bookingEventsCount?: Maybe<BookingEventsCount>;
    bookingSchedules: Array<BookingSchedule>;
    bookings: Array<Booking>;
    bookingsCount?: Maybe<BookingsCount>;
    branding?: Maybe<Branding>;
    calendarEventWithParticipants?: Maybe<CalendarCustomEvent>;
    calendarEvents: Array<CalendarCustomEvent>;
    calendarRecurrentEvents: Array<CalendarCustomEvent>;
    calendarSettings: CalendarSettings;
    chatGroups: Array<ChatGroup>;
    chatMessages: Array<ChatMessage>;
    checkIfBookingSlugUnique?: Maybe<BookingSlugUniqueResult>;
    checkIfRoomSlugUniq?: Maybe<RoomSlugUniqResult>;
    configOption?: Maybe<ConfigOption>;
    configOptions: Array<ConfigOption>;
    contactGroups: Array<CustomContactGroup>;
    contacts?: Maybe<Array<CustomContact>>;
    content: Content;
    converting: Converting;
    countRecentlyJoinedUsers?: Maybe<Scalars["Int"]>;
    csdOfSession?: Maybe<CustomSubDomainConfig>;
    currentHost?: Maybe<CurrentHost>;
    customMessageTemplates: Array<CustomMessageTemplate>;
    customSmtpServers: Array<CustomSmtpServer>;
    customStuff: Scalars["String"];
    descriptor?: Maybe<ArtifactDescriptor>;
    designerCommitState?: Maybe<DesignerCommitState>;
    designerEditState?: Maybe<DesignerEditState>;
    designerRestoreUncommitedDraft?: Maybe<DesignerRestoreDraft>;
    designerSessionChangedBy?: Maybe<DesignerSessionChangedBy>;
    designerSessionId?: Maybe<DesignerSessionId>;
    designerSessionLocked?: Maybe<DesignerSessionLocked>;
    designerState?: Maybe<DesignerState>;
    entitiesUsingCustomMessageTemplate: Array<EntityUsingCustomMessageTemplate>;
    externalClients: Array<ExternalClient>;
    feedbackForm?: Maybe<FeedbackForm>;
    feedbackFormCount?: Maybe<FeedbackFormsCount>;
    generateTemplateName: Scalars["String"];
    generateUserToken?: Maybe<Scalars["String"]>;
    getAllCnames: Array<Cname>;
    getAppleEvents: ExternalEventsAndCalendars;
    getAppleSyncedEmails?: Maybe<Array<AppleSyncedEmailData>>;
    getApplicationState?: Maybe<ApplicationState>;
    getBookingAnswers?: Maybe<BookingFormAnswers>;
    getCelloToken: Scalars["String"];
    getCentrifugoToken: Scalars["String"];
    getCnameInfo: Cname;
    getCurrentParticipant: Participant;
    getCustomBackgroundUploadUrl: Scalars["String"];
    getCustomEmailSignature: CustomEmailSignatureResult;
    getCustomEmails: Array<CustomEmail>;
    getCustomEmailsLanguage: Scalars["String"];
    getDefaultEmailSender?: Maybe<Scalars["String"]>;
    getEditorToken: Scalars["String"];
    getEvernoteAuthUrl?: Maybe<Scalars["String"]>;
    getGoogleAuthUrl?: Maybe<Scalars["String"]>;
    getGoogleEvents: ExternalEventsAndCalendars;
    getGoogleFile?: Maybe<Scalars["String"]>;
    getGoogleFiles?: Maybe<Scalars["String"]>;
    getGoogleSyncedEmails?: Maybe<Array<GoogleSyncedEmailData>>;
    getHubspotAuthUrl?: Maybe<Scalars["String"]>;
    getHubspotDetails?: Maybe<HubspotContact>;
    getMicrosoftEvents: ExternalEventsAndCalendars;
    getMicrosoftSyncedEmails?: Maybe<Array<MicrosoftSyncedEmailData>>;
    getMiroToken?: Maybe<Scalars["String"]>;
    getNotionParentPages?: Maybe<Array<NotionParentPage>>;
    getPayPalSyncedAccount?: Maybe<PayPalSyncedAccount>;
    getPaymentProviders: Array<PaymentProvider>;
    getPaymentTransaction?: Maybe<PaymentTransaction>;
    getPaypalIntegrationUrl?: Maybe<Scalars["String"]>;
    getProviderLinkedConfigs: Array<PaymentConfig>;
    getPspdfkitToken: Scalars["String"];
    getQuestionsFromSession: Array<Question>;
    getRegistrationAnswers?: Maybe<Scalars["Json"]>;
    getRemoteUser: GetRemoteUserResult;
    getResourcesCount: ResourcesCount;
    /** It's used by the integration service */
    getRootUrl: Scalars["String"];
    getSessionAnalytics?: Maybe<SessionAnalytics>;
    getSessionCalendarFile?: Maybe<SessionCalendarFile>;
    getSessionMessagesCount?: Maybe<Scalars["Int"]>;
    getSessionQuestionsCount?: Maybe<Scalars["Int"]>;
    getSessionUtmAnalytics?: Maybe<Scalars["Json"]>;
    getSessionsByAgendaTemplateId: Array<SessionsUsingResourceResult>;
    getSessionsByResourceId: Array<SessionsUsingResourceResult>;
    getSessionsForAdmin?: Maybe<Array<Session>>;
    getSkipTrialCoupon?: Maybe<Scalars["String"]>;
    getSlidoUrlDetails?: Maybe<SlidoUrlDetails>;
    getSpeakersHistory: Array<SessionSpeaker>;
    getStripeOAuthUrl?: Maybe<Scalars["String"]>;
    getStripeSyncedAccount?: Maybe<StripeSyncedAccount>;
    getTwitchUrlDetails?: Maybe<TwitchUrlDetails>;
    getUnsubscribePreference?: Maybe<UnsubscribePreference>;
    getUserCount?: Maybe<Scalars["Int"]>;
    getUserCountryCode?: Maybe<Scalars["String"]>;
    getUserHubspotEvents?: Maybe<Scalars["Json"]>;
    getUserHubspotProperties?: Maybe<Scalars["Json"]>;
    getUserNames?: Maybe<GetUserNamesResult>;
    getWebsiteDetails?: Maybe<WebsiteDetails>;
    /** Gets the title of an external website */
    getWebsiteTitle?: Maybe<Scalars["String"]>;
    giphySearch?: Maybe<Scalars["Json"]>;
    /** Used to determine if the users' participant has answered this form */
    hasParticipantAnsweredForm?: Maybe<Scalars["Boolean"]>;
    /** Checks if URL is embeddable in iframe */
    iframeCheckUrl?: Maybe<IframeCheckUrlPayload>;
    isAuthenticated: Scalars["Boolean"];
    isEvernoteSynced?: Maybe<EvernoteSyncStatus>;
    isGuestSpeaker?: Maybe<Scalars["Boolean"]>;
    isHubspotSynced?: Maybe<Scalars["String"]>;
    isNotionSynced?: Maybe<Scalars["String"]>;
    isRoomSessionInstanceEmpty?: Maybe<RoomIsEmptyResult>;
    leftDrawer: LeftDrawer;
    leftDrawerInPreview: LeftDrawerInPreview;
    localUser?: Maybe<LocalUser>;
    messageExternalProviders: Array<MessageExternalProvider>;
    messageFiles: Array<MessageFile>;
    module?: Maybe<Module>;
    modules: Array<Module>;
    myProductTool: MyProductTool;
    myProductTools: Array<MyProductTool>;
    newQuestionsCount: NewQuestionsCount;
    notifications?: Maybe<Array<Maybe<Notification>>>;
    organization?: Maybe<Organization>;
    ownerFeedbacks?: Maybe<Array<Maybe<Feedback>>>;
    paddleOfferPlans: Array<PaddlePlan>;
    paddlePlans: Array<PaddlePlan>;
    paddleSubscriptionDetails?: Maybe<SubscriptionDetailsPayload>;
    paddleTrialPlans: Array<PaddlePlan>;
    participants: Array<Participant>;
    publicApiKeys: Array<PublicApiKey>;
    publicBookingEvent?: Maybe<PublicBookingEvent>;
    publicBookingEventAvailableSlots: Array<PublicBookingEventAvailableSlot>;
    publicWorkspaceInfo?: Maybe<PublicWorkspaceInfo>;
    questionsContainer?: Maybe<QuestionsContainer>;
    requestFeedback: RequestFeedback;
    resource?: Maybe<Resource>;
    resourceResult?: Maybe<ResourceResult>;
    resources: Array<Resource>;
    resxResources?: Maybe<ResxResourcesList>;
    rightDrawer: RightDrawer;
    rightDrawerInPreview: RightDrawerInPreview;
    room?: Maybe<Room>;
    rooms: Array<Room>;
    runDevTest?: Maybe<Scalars["Int"]>;
    searchPalette: Array<PaletteItemBase>;
    searchParticipantsByEmails?: Maybe<Array<ParticipantSearchResult>>;
    searchSessionTranscripts: Array<SessionTranscriptContent>;
    searchUnsplashPhotos: Array<UnSplashPhotoCategories>;
    session?: Maybe<Session>;
    sessionBranding?: Maybe<Branding>;
    sessionBrandingFromToken?: Maybe<Branding>;
    sessionEvent?: Maybe<Session>;
    sessionEvents: Array<Session>;
    sessionEventsCount: Scalars["Int"];
    sessionEventsOwners: Array<User>;
    sessionIdOfRoom?: Maybe<SessionIdOfRoom>;
    sessionOffers: Array<Offer>;
    sessionRecording?: Maybe<SessionRecording>;
    sessionResourceNames?: Maybe<ResourceNames>;
    sessionSettings: Array<SessionSettings>;
    sessionsShallowSearch: ShallowSearchResult;
    summaryTranslation?: Maybe<Scalars["String"]>;
    syncHubspotContacts?: Maybe<Scalars["String"]>;
    transcriptContentTranslation?: Maybe<SessionTranscriptContent>;
    transcriptFile: TranscriptFile;
    transcripts: Array<SessionTranscript>;
    unreadSessionChatCount: Scalars["Int"];
    userFeedbackForms: Array<FeedbackForm>;
    userOffers: Array<Offer>;
    userOrganizations?: Maybe<Array<Organization>>;
    userOrganizationsPaddleSubscriptionDetails?: Maybe<Array<Maybe<SubscriptionDetailsPayload>>>;
    userPaddleTransactions?: Maybe<Array<UserPaddleTransaction>>;
    userSessions?: Maybe<UserSessionsResult>;
    version: Version;
    webhooks: Array<Webhook>;
    workspace?: Maybe<Workspace>;
    workspaceBookingEvents: Array<PublicBookingEventListItem>;
    workspaceMessageExternalProvider?: Maybe<MessageExternalProvider>;
    workspacePublicBranding?: Maybe<Branding>;
    workspacePublicEvents?: Maybe<Array<Session>>;
    workspaceSenderEmails: Array<WorkspaceSenderEmail>;
    zapierKey?: Maybe<PublicApiKey>;
};

export type QueryAccessListItemsArgs = {
    where?: Maybe<AccessListItemWhereInput>;
};

export type QueryAgendaItemsArgs = {
    orderBy?: Maybe<OrderOrderByInput>;
    where: AgendaItemWhereInput;
};

export type QueryAgendaTemplateArgs = {
    where: WhereIdInput;
};

export type QueryAgendaTemplatesArgs = {
    workspaceId: Scalars["String"];
};

export type QueryArtifactArgs = {
    where: WhereIdInput;
};

export type QueryArtifactsArgs = {
    macroartifactforsession_sessionId: Scalars["String"];
};

export type QueryBookedEventArgs = {
    id: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
};

export type QueryBookingArgs = {
    where: WhereIdInput;
};

export type QueryBookingEventArgs = {
    where: WhereIdInput;
};

export type QueryBookingEventContactsArgs = {
    includeSelf?: Maybe<Scalars["Boolean"]>;
    where: WhereIdInput;
};

export type QueryBookingEventsArgs = {
    orderBy?: Maybe<NameOrderByInput>;
    workspaceId: Scalars["String"];
};

export type QueryBookingSchedulesArgs = {
    orderBy?: Maybe<NameOrderByInput>;
};

export type QueryBookingsArgs = {
    cursor?: Maybe<Scalars["ID"]>;
    first?: Maybe<Scalars["Int"]>;
    where?: Maybe<BookingWhereInput>;
};

export type QueryBookingsCountArgs = {
    id: Scalars["String"];
};

export type QueryBrandingArgs = {
    where: BrandingWhereInput;
};

export type QueryCalendarEventWithParticipantsArgs = {
    id: Scalars["ID"];
};

export type QueryCalendarEventsArgs = {
    from: Scalars["DateTime"];
    until: Scalars["DateTime"];
};

export type QueryChatGroupsArgs = {
    orderBy?: Maybe<CreatedAtOrderByInput>;
    sessionId: Scalars["String"];
    skip?: Maybe<Scalars["Int"]>;
    take?: Maybe<Scalars["Int"]>;
};

export type QueryChatMessagesArgs = {
    before?: Maybe<Scalars["String"]>;
    last?: Maybe<Scalars["Int"]>;
    orderBy?: Maybe<CreatedAtOrderByInput>;
    where: ChatMessageWhereInput;
};

export type QueryCheckIfBookingSlugUniqueArgs = {
    slug: Scalars["String"];
};

export type QueryCheckIfRoomSlugUniqArgs = {
    roomSlug: Scalars["String"];
};

export type QueryConfigOptionArgs = {
    where: ConfigOptionWhereUniqueInput;
};

export type QueryConfigOptionsArgs = {
    where?: Maybe<ConfigOptionWhereInput>;
    take?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    cursor?: Maybe<ConfigOptionWhereUniqueInput>;
};

export type QueryContactsArgs = {
    includeSelf?: Maybe<Scalars["Boolean"]>;
    where?: Maybe<ContactWhereInput>;
};

export type QueryCountRecentlyJoinedUsersArgs = {
    since?: Maybe<Scalars["DateTime"]>;
};

export type QueryCsdOfSessionArgs = {
    sessionId: Scalars["String"];
};

export type QueryEntitiesUsingCustomMessageTemplateArgs = {
    templateId: Scalars["String"];
};

export type QueryFeedbackFormArgs = {
    feedbackFormId: Scalars["String"];
};

export type QueryFeedbackFormCountArgs = {
    feedbackFormId: Scalars["String"];
};

export type QueryGenerateTemplateNameArgs = {
    name: Scalars["String"];
};

export type QueryGenerateUserTokenArgs = {
    permissions?: Maybe<Array<Scalars["String"]>>;
};

export type QueryGetAppleEventsArgs = {
    emails?: Maybe<Array<Scalars["String"]>>;
    from: Scalars["DateTime"];
    until: Scalars["DateTime"];
};

export type QueryGetBookingAnswersArgs = {
    sessionId: Scalars["String"];
};

export type QueryGetCnameInfoArgs = {
    id: Scalars["String"];
};

export type QueryGetCurrentParticipantArgs = {
    sessionId: Scalars["String"];
};

export type QueryGetCustomEmailSignatureArgs = {
    workspacePermissionsId: Scalars["String"];
};

export type QueryGetCustomEmailsArgs = {
    workspacePermissionsId: Scalars["String"];
};

export type QueryGetCustomEmailsLanguageArgs = {
    workspacePermissionsId: Scalars["String"];
};

export type QueryGetEditorTokenArgs = {
    artifactId: Scalars["String"];
    readOnly?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetGoogleEventsArgs = {
    from?: Maybe<Scalars["DateTime"]>;
    until?: Maybe<Scalars["DateTime"]>;
};

export type QueryGetGoogleFileArgs = {
    fileId: Scalars["String"];
};

export type QueryGetGoogleFilesArgs = {
    fileName?: Maybe<Scalars["String"]>;
    fileType?: Maybe<GoogleFileType>;
    nextPageToken?: Maybe<Scalars["String"]>;
};

export type QueryGetHubspotDetailsArgs = {
    email: Scalars["String"];
    token: Scalars["String"];
};

export type QueryGetMicrosoftEventsArgs = {
    from: Scalars["DateTime"];
    until: Scalars["DateTime"];
};

export type QueryGetPaymentTransactionArgs = {
    id: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
};

export type QueryGetProviderLinkedConfigsArgs = {
    id: Scalars["String"];
};

export type QueryGetPspdfkitTokenArgs = {
    documentId: Scalars["String"];
    layer?: Maybe<Scalars["String"]>;
};

export type QueryGetQuestionsFromSessionArgs = {
    session: WhereIdInput;
};

export type QueryGetRegistrationAnswersArgs = {
    eventId: Scalars["String"];
};

export type QueryGetResourcesCountArgs = {
    workspaceId?: Maybe<Scalars["String"]>;
};

export type QueryGetRootUrlArgs = {
    sessionId?: Maybe<Scalars["String"]>;
    target: Scalars["String"];
};

export type QueryGetSessionAnalyticsArgs = {
    sessionId: Scalars["String"];
};

export type QueryGetSessionCalendarFileArgs = {
    sessionId: Scalars["String"];
};

export type QueryGetSessionMessagesCountArgs = {
    sessionId: Scalars["String"];
};

export type QueryGetSessionQuestionsCountArgs = {
    session: WhereIdInput;
};

export type QueryGetSessionUtmAnalyticsArgs = {
    sessionId: Scalars["String"];
};

export type QueryGetSessionsByAgendaTemplateIdArgs = {
    agendaTemplateId: Scalars["String"];
};

export type QueryGetSessionsByResourceIdArgs = {
    resourceId: Scalars["String"];
};

export type QueryGetSessionsForAdminArgs = {
    secret: Scalars["String"];
    where?: Maybe<SessionWhereInput>;
};

export type QueryGetSlidoUrlDetailsArgs = {
    url: Scalars["String"];
};

export type QueryGetTwitchUrlDetailsArgs = {
    url: Scalars["String"];
};

export type QueryGetUnsubscribePreferenceArgs = {
    token: Scalars["String"];
};

export type QueryGetUserNamesArgs = {
    emails: Array<Scalars["String"]>;
};

export type QueryGetWebsiteDetailsArgs = {
    icon?: Maybe<Scalars["Boolean"]>;
    idealIconSize?: Maybe<Scalars["Int"]>;
    titleFromOG?: Maybe<Scalars["Boolean"]>;
    url: Scalars["String"];
};

export type QueryGetWebsiteTitleArgs = {
    url: Scalars["String"];
};

export type QueryGiphySearchArgs = {
    limit?: Maybe<Scalars["Int"]>;
    offset: Scalars["Int"];
    query?: Maybe<Scalars["String"]>;
    type?: Maybe<GiphyMediaType>;
};

export type QueryHasParticipantAnsweredFormArgs = {
    artifactWhere: WhereIdInput;
    resourceResultWhere: WhereIdInput;
};

export type QueryIframeCheckUrlArgs = {
    url: Scalars["String"];
};

export type QueryIsGuestSpeakerArgs = {
    sessionId: Scalars["String"];
    token: Scalars["String"];
};

export type QueryIsRoomSessionInstanceEmptyArgs = {
    roomId: Scalars["String"];
};

export type QueryMessageFilesArgs = {
    orderBy?: Maybe<CreatedAtOrderByInput>;
    where: MessageFileWhereInput;
};

export type QueryModuleArgs = {
    where: ModuleWhereUniqueInput;
};

export type QueryModulesArgs = {
    where?: Maybe<ModuleWhereInput>;
    take?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    cursor?: Maybe<ModuleWhereUniqueInput>;
};

export type QueryMyProductToolArgs = {
    myProductToolId: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
};

export type QueryMyProductToolsArgs = {
    workspaceId: Scalars["String"];
};

export type QueryOrganizationArgs = {
    organizationId: Scalars["String"];
};

export type QueryOwnerFeedbacksArgs = {
    sessionId: Scalars["String"];
};

export type QueryParticipantsArgs = {
    take?: Maybe<Scalars["Int"]>;
    where: ParticipantWhereInput;
};

export type QueryPublicApiKeysArgs = {
    workspaceId: Scalars["String"];
};

export type QueryPublicBookingEventArgs = {
    slug: Scalars["String"];
    timezone: Scalars["String"];
};

export type QueryPublicBookingEventAvailableSlotsArgs = {
    duration?: Maybe<Scalars["Int"]>;
    from: Scalars["DateTime"];
    id: Scalars["String"];
    timezone: Scalars["String"];
    to: Scalars["DateTime"];
    troubleshoot?: Maybe<Scalars["Boolean"]>;
};

export type QueryPublicWorkspaceInfoArgs = {
    workspaceId: Scalars["String"];
};

export type QueryQuestionsContainerArgs = {
    where: WhereIdInput;
};

export type QueryResourceArgs = {
    where: WhereIdInput;
};

export type QueryResourceResultArgs = {
    where: WhereIdInput;
};

export type QueryResourcesArgs = {
    where?: Maybe<ResourceWhereInput>;
};

export type QueryResxResourcesArgs = {
    type: Scalars["String"];
    container: Scalars["String"];
};

export type QueryRoomArgs = {
    where: WhereIdInput;
};

export type QueryRoomsArgs = {
    workspaceId: Scalars["String"];
};

export type QuerySearchPaletteArgs = {
    input?: Maybe<PaletteSearchInput>;
};

export type QuerySearchParticipantsByEmailsArgs = {
    take?: Maybe<Scalars["Int"]>;
    term: Array<Scalars["String"]>;
};

export type QuerySearchSessionTranscriptsArgs = {
    query: Scalars["String"];
    sessionId: Scalars["String"];
};

export type QuerySearchUnsplashPhotosArgs = {
    page: Scalars["Int"];
    perPage: Scalars["Int"];
    searchQuery?: Maybe<Scalars["String"]>;
};

export type QuerySessionArgs = {
    checkPlan?: Maybe<Scalars["Boolean"]>;
    includeRegistrationAnswers?: Maybe<Scalars["Boolean"]>;
    where: WhereIdInput;
};

export type QuerySessionBrandingArgs = {
    sessionId: Scalars["String"];
};

export type QuerySessionBrandingFromTokenArgs = {
    where?: Maybe<SessionBrandingFromTokenWhereInput>;
};

export type QuerySessionEventArgs = {
    where: SessionEventWhereUniqueInput;
};

export type QuerySessionEventsArgs = {
    eventFilters?: Maybe<Array<SessionEventFilters>>;
    orderByDate?: Maybe<SortOrder>;
    orderByName?: Maybe<SortOrder>;
    ownerIdsFilter?: Maybe<Array<Scalars["String"]>>;
    searchTerm?: Maybe<Scalars["String"]>;
    skip?: Maybe<Scalars["Int"]>;
    take?: Maybe<Scalars["Int"]>;
    workspaceId: Scalars["String"];
};

export type QuerySessionEventsCountArgs = {
    eventFilters?: Maybe<Array<SessionEventFilters>>;
    ownerIdsFilter?: Maybe<Array<Scalars["String"]>>;
    searchTerm?: Maybe<Scalars["String"]>;
    workspaceId: Scalars["String"];
};

export type QuerySessionIdOfRoomArgs = {
    roomSlug: Scalars["String"];
};

export type QuerySessionOffersArgs = {
    sessionId: Scalars["String"];
};

export type QuerySessionRecordingArgs = {
    where: WhereIdInput;
};

export type QuerySessionResourceNamesArgs = {
    id: Scalars["String"];
};

export type QuerySessionSettingsArgs = {
    workspaceId: Scalars["String"];
};

export type QuerySessionsShallowSearchArgs = {
    pageToken?: Maybe<Scalars["String"]>;
    term: Scalars["String"];
    time: Scalars["DateTime"];
};

export type QuerySummaryTranslationArgs = {
    language: Scalars["String"];
    summary: Scalars["String"];
};

export type QueryTranscriptContentTranslationArgs = {
    language: Scalars["String"];
    transcriptId: Scalars["String"];
};

export type QueryTranscriptFileArgs = {
    format: Scalars["String"];
    preferredLanguage?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
    timezoneOffset: Scalars["Int"];
};

export type QueryTranscriptsArgs = {
    before?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
    take: Scalars["Int"];
};

export type QueryUserOrganizationsArgs = {
    where?: Maybe<OrganizationWhereInput>;
};

export type QueryUserPaddleTransactionsArgs = {
    page: Scalars["Int"];
};

export type QueryUserSessionsArgs = {
    attended: Scalars["Boolean"];
    declined: Scalars["Boolean"];
    from?: Maybe<Scalars["DateTime"]>;
    invited?: Maybe<Scalars["Boolean"]>;
    lifecycle?: Maybe<Array<SessionLifecycle>>;
    orderBy?: Maybe<Array<SessionOrderByInput>>;
    owner?: Maybe<Scalars["Boolean"]>;
    participantEmails?: Maybe<Array<Scalars["String"]>>;
    participantInviteStatuses?: Maybe<Array<ParticipantInviteStatus>>;
    searchTerm?: Maybe<Scalars["String"]>;
    sessions?: Maybe<SessionFilterEnum>;
    skip?: Maybe<Scalars["Int"]>;
    sortByDuration?: Maybe<SortOrder>;
    sortByParticipantStatus?: Maybe<SortOrder>;
    sortByParticipants?: Maybe<SortOrder>;
    sortByRecording?: Maybe<SortOrder>;
    sortBySessionTags?: Maybe<SortOrder>;
    take?: Maybe<Scalars["Int"]>;
    until?: Maybe<Scalars["DateTime"]>;
    withRecording: Scalars["Boolean"];
    withSessionTags?: Maybe<Array<Scalars["String"]>>;
    withoutRecording: Scalars["Boolean"];
    workspaceId?: Maybe<Scalars["String"]>;
};

export type QueryWebhooksArgs = {
    userId: Scalars["String"];
    workspaceId: Scalars["String"];
};

export type QueryWorkspaceArgs = {
    workspaceId: Scalars["String"];
};

export type QueryWorkspaceBookingEventsArgs = {
    workspaceId: Scalars["String"];
};

export type QueryWorkspaceMessageExternalProviderArgs = {
    workspaceId: Scalars["String"];
};

export type QueryWorkspacePublicBrandingArgs = {
    workspaceId: Scalars["ID"];
};

export type QueryWorkspacePublicEventsArgs = {
    workspaceId: Scalars["ID"];
};

export type QueryWorkspaceSenderEmailsArgs = {
    workspaceId: Scalars["String"];
};

export type Question = {
    __typename?: "Question";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    isAnonymous?: Maybe<Scalars["Boolean"]>;
    markedAsAnswered: Scalars["Boolean"];
    participantId: Scalars["String"];
    text: Scalars["String"];
    /** Says if this question is upVoted by the user */
    upVotedByUser: Scalars["Boolean"];
    /** The number of upVotes that this question has received */
    upVotes: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type QuestionContainerCreateInput = {
    create?: Maybe<QuestionContainerCreateWithoutSessionInput>;
};

export type QuestionContainerCreateWithoutSessionInput = {
    id?: Maybe<Scalars["String"]>;
};

export type QuestionsContainer = {
    __typename?: "QuestionsContainer";
    currentlyAnsweringQuestion?: Maybe<Question>;
    id: Scalars["ID"];
    questions: Array<Question>;
};

export type QuestionsContainerQuestionsArgs = {
    before?: Maybe<Scalars["String"]>;
    last?: Maybe<Scalars["Int"]>;
    orderBy?: Maybe<CreatedAtOrderByInput>;
    where?: Maybe<QuestionWhereInput>;
};

export type QuestionWhereInput = {
    markedAsAnswered?: Maybe<BoolFilter>;
};

export enum ReactionType {
    Astonished = "ASTONISHED",
    Celebrate = "CELEBRATE",
    Clap = "CLAP",
    Dislike = "DISLIKE",
    Flushed = "FLUSHED",
    Laugh = "LAUGH",
    Like = "LIKE",
    Love = "LOVE",
    Raisehand = "RAISEHAND",
}

export enum RecordingProcessingStatus {
    Error = "ERROR",
    Finished = "FINISHED",
    NotStarted = "NOT_STARTED",
    Queued = "QUEUED",
    Started = "STARTED",
}

export type RecurrenceData = {
    __typename?: "RecurrenceData";
    byDay: Array<WeekDay>;
    byMonth: Array<Scalars["Int"]>;
    byMonthDay: Array<Scalars["Int"]>;
    bySetPos?: Maybe<Scalars["Int"]>;
    count?: Maybe<Scalars["Int"]>;
    createdAt: Scalars["DateTime"];
    dtStart: Scalars["DateTime"];
    exclude: Array<Scalars["DateTime"]>;
    firstInstance?: Maybe<Session>;
    frequency: RepeatingFrequency;
    id: Scalars["ID"];
    instancesOccurrenceIds: Array<Scalars["DateTime"]>;
    interval?: Maybe<Scalars["Int"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    oldId?: Maybe<Scalars["String"]>;
    session: Session;
    sessionId: Scalars["String"];
    until?: Maybe<Scalars["DateTime"]>;
    update?: Maybe<RecurrenceDataDiff>;
    updatedAt: Scalars["DateTime"];
    wkst?: Maybe<WeekDay>;
};

export type RecurrenceDataCreateOneWithoutSessionInput = {
    connect?: Maybe<WhereIdInput>;
    create?: Maybe<RecurrenceDataCreateWithoutSessionInput>;
};

export type RecurrenceDataCreateWithoutSessionInput = {
    byDay?: Maybe<RecurrenceRuleCreateByDayInput>;
    byMonth?: Maybe<RecurrenceRuleCreateByMonthInput>;
    byMonthDay?: Maybe<RecurrenceRuleCreateByMonthDayInput>;
    bySetPos?: Maybe<Scalars["Int"]>;
    count?: Maybe<Scalars["Int"]>;
    dtStart: Scalars["DateTime"];
    frequency: RepeatingFrequency;
    id?: Maybe<Scalars["String"]>;
    interval?: Maybe<Scalars["Int"]>;
    until?: Maybe<Scalars["DateTime"]>;
    wkst?: Maybe<WeekDay>;
};

export type RecurrenceDataDiff = {
    __typename?: "RecurrenceDataDiff";
    byDay?: Maybe<Array<WeekDay>>;
    byMonth?: Maybe<Array<Scalars["Int"]>>;
    byMonthDay?: Maybe<Array<Scalars["Int"]>>;
    bySetPos?: Maybe<Scalars["Int"]>;
    count?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtStart?: Maybe<Scalars["DateTime"]>;
    exclude?: Maybe<Array<Scalars["DateTime"]>>;
    frequency?: Maybe<RepeatingFrequency>;
    newId?: Maybe<Scalars["ID"]>;
    instancesOccurrenceIds?: Maybe<Array<Scalars["DateTime"]>>;
    interval?: Maybe<Scalars["Int"]>;
    sessionId?: Maybe<Scalars["String"]>;
    until?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type RecurrenceDataUpdateOneWithoutSessionInput = {
    create?: Maybe<RecurrenceDataCreateWithoutSessionInput>;
    delete?: Maybe<Scalars["Boolean"]>;
    update?: Maybe<RecurrenceDataUpdateWithoutSessionDataInput>;
};

export type RecurrenceDataUpdateWithoutSessionDataInput = {
    byDay?: Maybe<RecurrenceRuleUpdateByDayInput>;
    byMonth?: Maybe<RecurrenceRuleUpdateByMonthInput>;
    byMonthDay?: Maybe<RecurrenceRuleUpdateByMonthDayInput>;
    bySetPos?: Maybe<NullableIntFieldUpdateOperationsInput>;
    count?: Maybe<NullableIntFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    dtStart?: Maybe<DateTimeFieldUpdateOperationsInput>;
    frequency?: Maybe<RepeatingFrequency>;
    interval?: Maybe<NullableIntFieldUpdateOperationsInput>;
    until?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    wkst?: Maybe<WeekDay>;
};

export enum RecurrenceEditType {
    ThisSession = "THIS_SESSION",
    ThisAndTheFollowing = "THIS_AND_THE_FOLLOWING",
    AllSessions = "ALL_SESSIONS",
}

export type RecurrenceRuleCreateByDayInput = {
    set?: Maybe<Array<WeekDay>>;
};

export type RecurrenceRuleCreateByMonthDayInput = {
    set?: Maybe<Array<Scalars["Int"]>>;
};

export type RecurrenceRuleCreateByMonthInput = {
    set?: Maybe<Array<Scalars["Int"]>>;
};

export type RecurrenceRuleUpdateByDayInput = {
    set?: Maybe<Array<WeekDay>>;
};

export type RecurrenceRuleUpdateByMonthDayInput = {
    set?: Maybe<Array<Scalars["Int"]>>;
};

export type RecurrenceRuleUpdateByMonthInput = {
    set?: Maybe<Array<Scalars["Int"]>>;
};

export type RegisterToEventResponse = {
    __typename?: "RegisterToEventResponse";
    checkoutUrl?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    isSpeaker?: Maybe<Scalars["Boolean"]>;
    registrationId?: Maybe<Scalars["String"]>;
    requireLogin?: Maybe<Scalars["Boolean"]>;
    requirePayment?: Maybe<Scalars["Boolean"]>;
};

export enum RepeatingFrequency {
    Daily = "DAILY",
    Minutely = "MINUTELY",
    Monthly = "MONTHLY",
    Secondly = "SECONDLY",
    Weekly = "WEEKLY",
    Yearly = "YEARLY",
}

export type ReportedProblem = {
    __typename?: "ReportedProblem";
    id: Scalars["ID"];
};

export enum ReportProblemCategory {
    Incident = "incident",
    Problem = "problem",
    Question = "question",
    Task = "task",
}

export type RequestFeedback = {
    __typename?: "RequestFeedback";
    sessionId: Scalars["String"];
    source: FeedbackSource;
    given: Scalars["Boolean"];
};

export type Resource = {
    __typename?: "Resource";
    content: Scalars["String"];
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    isPublic: Scalars["Boolean"];
    isTemplate: Scalars["Boolean"];
    owner: User;
    processingDetails: Scalars["String"];
    processingStatus: ResourceProcessingStatus;
    readOnly?: Maybe<Scalars["Boolean"]>;
    type: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    workspaceId: Scalars["String"];
};

export type ResourceName = {
    __typename?: "ResourceName";
    artifactId: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    resultId: Scalars["String"];
};

export type ResourceNames = {
    __typename?: "ResourceNames";
    names?: Maybe<Array<ResourceName>>;
};

export enum ResourceProcessingStatus {
    Error = "ERROR",
    Finished = "FINISHED",
    NotRequired = "NOT_REQUIRED",
    NotStarted = "NOT_STARTED",
    Queued = "QUEUED",
    Required = "REQUIRED",
    Started = "STARTED",
}

export type ResourceResult = {
    __typename?: "ResourceResult";
    content?: Maybe<Scalars["Json"]>;
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    oldId?: Maybe<Scalars["String"]>;
    resource?: Maybe<Resource>;
    resourceContentSnapshot: Scalars["String"];
    resourceId?: Maybe<Scalars["String"]>;
    status?: Maybe<ResourceResultStatus>;
    type: Scalars["String"];
    update?: Maybe<ResourceResultDiff>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ResourceResultDiff = {
    __typename?: "ResourceResultDiff";
    content?: Maybe<Scalars["Json"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    newId?: Maybe<Scalars["ID"]>;
    resourceContentSnapshot?: Maybe<Scalars["String"]>;
    resourceId?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum ResourceResultStatus {
    Finished = "FINISHED",
    Touched = "TOUCHED",
    Untouched = "UNTOUCHED",
}

export type ResourceResultUpdateManyInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    delete?: Maybe<Array<WhereIdInput>>;
};

export type ResourcesCount = {
    __typename?: "ResourcesCount";
    adobexdCount?: Maybe<Scalars["Int"]>;
    airtableCount?: Maybe<Scalars["Int"]>;
    blackboardCount?: Maybe<Scalars["Int"]>;
    boardmixCount?: Maybe<Scalars["Int"]>;
    canvaCount?: Maybe<Scalars["Int"]>;
    docCount?: Maybe<Scalars["Int"]>;
    excelCount?: Maybe<Scalars["Int"]>;
    figmaCount?: Maybe<Scalars["Int"]>;
    formCount?: Maybe<Scalars["Int"]>;
    galleryCount?: Maybe<Scalars["Int"]>;
    keynoteCount?: Maybe<Scalars["Int"]>;
    mentimeterCount?: Maybe<Scalars["Int"]>;
    numbersCount?: Maybe<Scalars["Int"]>;
    pagesCount?: Maybe<Scalars["Int"]>;
    pdfCount?: Maybe<Scalars["Int"]>;
    pitchCount?: Maybe<Scalars["Int"]>;
    pptCount?: Maybe<Scalars["Int"]>;
    slidoCount?: Maybe<Scalars["Int"]>;
    twitchCount?: Maybe<Scalars["Int"]>;
    videoCount?: Maybe<Scalars["Int"]>;
    vimeoCount?: Maybe<Scalars["Int"]>;
    youtubeCount?: Maybe<Scalars["Int"]>;
};

export type ResourceWhereInput = {
    content?: Maybe<StringFilter>;
    createdAt?: Maybe<DateTimeNullableFilter>;
    specificationVersion?: Maybe<StringFilter>;
    type?: Maybe<StringFilter>;
    userId?: Maybe<StringFilter>;
    workspaceId?: Maybe<StringFilter>;
};

export type ResxResourcesList = {
    __typename?: "ResxResourcesList";
    hasData: Scalars["Boolean"];
    resources: Array<Resource>;
};

export type ResxResourceUpdatePayload = {
    __typename?: "ResxResourceUpdatePayload";
    resourceId: Scalars["String"];
    resourceType: Scalars["String"];
    specificationVersion: Scalars["String"];
};

export type ResxResultUpdatePayload = {
    __typename?: "ResxResultUpdatePayload";
    resultId: Scalars["String"];
    resultType: Scalars["String"];
    specificationVersion: Scalars["String"];
};

export type RightDrawer = {
    __typename?: "RightDrawer";
    isDetached?: Maybe<Scalars["Boolean"]>;
    state: DrawerState;
};

export type RightDrawerInPreview = {
    __typename?: "RightDrawerInPreview";
    state: DrawerState;
};

export type Room = {
    __typename?: "Room";
    active: Scalars["Boolean"];
    activeSessionId?: Maybe<Scalars["String"]>;
    activeSessionInstance?: Maybe<Session>;
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    oldId?: Maybe<Scalars["String"]>;
    sessionInstances: Array<Session>;
    sessionTemplate: Session;
    sessionTemplateId: Scalars["String"];
    sharedWithUsers: Array<User>;
    sharedWithWorkspaces: Array<Workspace>;
    slug?: Maybe<Scalars["String"]>;
    update?: Maybe<RoomDiff>;
    updatedAt: Scalars["DateTime"];
    user: User;
};

export type RoomCreateOneWithoutSessionInstancesInput = {
    connect?: Maybe<WhereIdInput>;
    create?: Maybe<RoomCreateWithoutSessionInstancesInput>;
};

export type RoomCreateWithoutSessionInstancesInput = {
    active?: Maybe<Scalars["Boolean"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    deleted?: Maybe<Scalars["Boolean"]>;
    id?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type RoomDiff = {
    __typename?: "RoomDiff";
    active?: Maybe<Scalars["Boolean"]>;
    activeSessionId?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    newId?: Maybe<Scalars["ID"]>;
    sessionTemplateId?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type RoomIsEmptyResult = {
    __typename?: "RoomIsEmptyResult";
    isEmpty?: Maybe<Scalars["Boolean"]>;
};

export type RoomShareWorkspaceInput = {
    share: Scalars["Boolean"];
    workspaceId: Scalars["String"];
};

export type RoomSlugUniqResult = {
    __typename?: "RoomSlugUniqResult";
    isUnique?: Maybe<Scalars["Boolean"]>;
};

export type RoomUpdateOneWithoutSessionInstancesInput = {
    create?: Maybe<RoomCreateWithoutSessionInstancesInput>;
    delete?: Maybe<Scalars["Boolean"]>;
    update?: Maybe<RoomUpdateWithoutSessionInstancesDataInput>;
};

export type RoomUpdateWithoutSessionInstancesDataInput = {
    active?: Maybe<BoolFieldUpdateOperationsInput>;
    deleted?: Maybe<BoolFieldUpdateOperationsInput>;
    slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SaveGoogleTokenResponse = {
    __typename?: "SaveGoogleTokenResponse";
    email?: Maybe<Scalars["String"]>;
};

export type SaveMicrosoftTokenResponse = {
    __typename?: "SaveMicrosoftTokenResponse";
    email?: Maybe<Scalars["String"]>;
};

export type Session = {
    __typename?: "Session";
    actualStart?: Maybe<Scalars["DateTime"]>;
    agendaItems: Array<AgendaItem>;
    allowAgendaType: SessionFlagsType;
    allowCameraType: SessionFlagsType;
    allowCollaborationInLobby: Scalars["Boolean"];
    allowMicrophoneType: SessionFlagsType;
    allowScreenShareType: SessionFlagsType;
    askForGuestEmail: Scalars["Boolean"];
    autoRecording: Scalars["Boolean"];
    autoStartSession: Scalars["Boolean"];
    autoTranscribing: Scalars["Boolean"];
    backstage: Scalars["Boolean"];
    byTimezonePlannedEnd?: Maybe<Scalars["DateTime"]>;
    byTimezoneStartAt?: Maybe<Scalars["DateTime"]>;
    bypassSessionPasscode: Scalars["Boolean"];
    childOfBreakoutRooms?: Maybe<BreakoutRoomsConfiguration>;
    collaborativeSession?: Maybe<Scalars["Json"]>;
    collectFeedback: CollectFeedbackType;
    createdAt: Scalars["DateTime"];
    createdFromTemplateId?: Maybe<Scalars["String"]>;
    createdFromYourFirstSessionTemplate: Scalars["Boolean"];
    currentParticipant: Participant;
    description?: Maybe<Scalars["String"]>;
    disabledNotifications: Array<Scalars["String"]>;
    enableReactionsType: SessionFlagsType;
    endByWorker: Scalars["Boolean"];
    endedAt?: Maybe<Scalars["DateTime"]>;
    event?: Maybe<SessionEvent>;
    exitPageOffer?: Maybe<Offer>;
    exitPageOfferId?: Maybe<Scalars["String"]>;
    feedbackForm?: Maybe<FeedbackForm>;
    feedbackFormAnswers: Array<Scalars["Json"]>;
    feedbackFormId?: Maybe<Scalars["String"]>;
    fullSizeVideosInRecording: Scalars["Boolean"];
    generatedName?: Maybe<Scalars["String"]>;
    groupChatAccess: Scalars["Boolean"];
    hideIcsGuestList: Scalars["Boolean"];
    hideNonStreamingParticipants: Scalars["Boolean"];
    id: Scalars["ID"];
    instanceOfRecurrence?: Maybe<RecurrenceData>;
    isBooking: Scalars["Boolean"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isPrivate: Scalars["Boolean"];
    isTask?: Maybe<Scalars["Boolean"]>;
    lifecycle: SessionLifecycle;
    livestreamOutputUrls: Scalars["Json"];
    lobbyAccess: Scalars["Boolean"];
    lobbyPicture?: Maybe<LobbyPicture>;
    macroArtifacts: Array<Artifact>;
    memoryAccessType: MemoryAccessType;
    messageReminders: Scalars["Json"];
    name: Scalars["String"];
    nullableCurrentParticipant?: Maybe<Participant>;
    occurrenceId?: Maybe<Scalars["DateTime"]>;
    offers: Array<Offer>;
    oldId?: Maybe<Scalars["String"]>;
    onboardingType?: Maybe<Scalars["String"]>;
    order: Scalars["Int"];
    organizationPlan?: Maybe<OrganizationPlan>;
    ownerHalfDayClock?: Maybe<Scalars["Boolean"]>;
    ownerLanguage?: Maybe<Scalars["String"]>;
    participants: Array<Participant>;
    participantsMacroAccess: ParticipantsMacroAccess;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    plannedEnd?: Maybe<Scalars["DateTime"]>;
    polls: Array<Poll>;
    privateChatAccess: PrivateChatAccess;
    provideSessionPasscode: Scalars["Boolean"];
    questionsContainer?: Maybe<QuestionsContainer>;
    quickSession: Scalars["Boolean"];
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType: SessionRecordingType;
    recordings: Array<SessionRecording>;
    recurrenceData?: Maybe<RecurrenceData>;
    recurrenceDataId?: Maybe<Scalars["String"]>;
    reminderInMinutes?: Maybe<Scalars["Int"]>;
    reminders: Scalars["Json"];
    requestPermissionToJoin: Scalars["Boolean"];
    requireConsentToRecord: Scalars["Boolean"];
    restrictedWidgets: Scalars["Json"];
    room?: Maybe<Room>;
    roomId?: Maybe<Scalars["String"]>;
    sendEmailsAutomatically: Scalars["Boolean"];
    sendEndSessionEmail: Scalars["Boolean"];
    sessionTags: Array<SessionTag>;
    shareScreenPid?: Maybe<Scalars["String"]>;
    showAgendaInLobby: Scalars["Boolean"];
    showExitPageCta: ExitPageCtaType;
    source: SessionSource;
    speakerUserIds: Array<Scalars["String"]>;
    startAt?: Maybe<Scalars["DateTime"]>;
    syncToAgenda: Scalars["Boolean"];
    templateForRooms: Array<Room>;
    timeDependency: Scalars["Boolean"];
    timeZone?: Maybe<Scalars["String"]>;
    timerCurrentDuration?: Maybe<Scalars["Int"]>;
    timerInitialDuration?: Maybe<Scalars["Int"]>;
    timerStartAt?: Maybe<Scalars["DateTime"]>;
    timerState: SessionTimerState;
    transcriptionActive: Scalars["Boolean"];
    update?: Maybe<SessionDiff>;
    updatedAt: Scalars["DateTime"];
    view: SessionView;
    workspace: Workspace;
    workspaceId: Scalars["String"];
};

export type SessionMacroArtifactsArgs = {
    orderBy?: Maybe<OrderOrderByInput>;
};

export type SessionParticipantsArgs = {
    onlyOwner?: Maybe<Scalars["Boolean"]>;
    onlyOwnerSpeakerAssistant?: Maybe<Scalars["Boolean"]>;
};

export type SessionAnalytics = {
    __typename?: "SessionAnalytics";
    chatMessages: Scalars["Int"];
    participantsAttended: Scalars["Int"];
    participantsRegistered: Scalars["Int"];
    participantsTimeInSessionInSeconds: Scalars["Float"];
    polls: Scalars["Int"];
    questions: Scalars["Int"];
};

export type SessionBrandingFromTokenWhereInput = {
    token: Scalars["String"];
};

export type SessionCalendarFile = {
    __typename?: "SessionCalendarFile";
    google: Scalars["String"];
    ics?: Maybe<Scalars["String"]>;
    outlook: Scalars["String"];
    outlookOffice: Scalars["String"];
    yahoo: Scalars["String"];
};

export type SessionCreateInput = {
    actualStart?: Maybe<Scalars["DateTime"]>;
    agendaItems?: Maybe<AgendaItemCreateManyInput>;
    allowAgendaType?: Maybe<SessionFlagsType>;
    allowCameraType?: Maybe<SessionFlagsType>;
    allowCollaborationInLobby?: Maybe<Scalars["Boolean"]>;
    allowMicrophoneType?: Maybe<SessionFlagsType>;
    allowScreenShareType?: Maybe<SessionFlagsType>;
    askForGuestEmail?: Maybe<Scalars["Boolean"]>;
    autoRecording?: Maybe<Scalars["Boolean"]>;
    autoStartSession?: Maybe<Scalars["Boolean"]>;
    autoTranscribing?: Maybe<Scalars["Boolean"]>;
    autopilot?: Maybe<Scalars["Boolean"]>;
    bypassSessionPasscode?: Maybe<Scalars["Boolean"]>;
    collectFeedback?: Maybe<CollectFeedbackType>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdFromTemplate?: Maybe<ConnectOneInput>;
    createdFromYourFirstSessionTemplate?: Maybe<Scalars["Boolean"]>;
    description?: Maybe<Scalars["String"]>;
    disabledNotifications?: Maybe<Array<Maybe<Scalars["String"]>>>;
    enableReactionsType?: Maybe<SessionFlagsType>;
    endByWorker?: Maybe<Scalars["Boolean"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    event?: Maybe<SessionEventCreateOneWithoutSessionInput>;
    exitPageOffer?: Maybe<ExitPageOfferUpdateInput>;
    exitPageOfferId?: Maybe<Scalars["String"]>;
    feedbackForm?: Maybe<FeedbackFormUpdateInput>;
    followUpOfSessionId?: Maybe<Scalars["String"]>;
    fullSizeVideosInRecording?: Maybe<Scalars["Boolean"]>;
    groupChatAccess?: Maybe<Scalars["Boolean"]>;
    hideIcsGuestList?: Maybe<Scalars["Boolean"]>;
    hideNonStreamingParticipants?: Maybe<Scalars["Boolean"]>;
    id?: Maybe<Scalars["String"]>;
    isChildOfBreakoutRooms?: Maybe<Scalars["Boolean"]>;
    isPrivate?: Maybe<Scalars["Boolean"]>;
    isTask?: Maybe<Scalars["Boolean"]>;
    lifecycle?: Maybe<SessionLifecycle>;
    livestreamOutputUrls?: Maybe<Scalars["Json"]>;
    lobbyAccess?: Maybe<Scalars["Boolean"]>;
    macroArtifacts?: Maybe<ArtifactCreateManyInput>;
    memoryAccessType?: Maybe<MemoryAccessType>;
    messageReminders?: Maybe<Scalars["Json"]>;
    name: Scalars["String"];
    occurrenceId?: Maybe<Scalars["DateTime"]>;
    onboardingType?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    participants?: Maybe<ParticipantCreateManyInput>;
    participantsMacroAccess?: Maybe<ParticipantsMacroAccess>;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    plannedEnd?: Maybe<Scalars["DateTime"]>;
    privateChatAccess?: Maybe<PrivateChatAccess>;
    provideSessionPasscode?: Maybe<Scalars["Boolean"]>;
    questionsContainer?: Maybe<QuestionContainerCreateInput>;
    quickSession?: Maybe<Scalars["Boolean"]>;
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType?: Maybe<SessionRecordingType>;
    recurrenceData?: Maybe<RecurrenceDataCreateOneWithoutSessionInput>;
    reminderInMinutes?: Maybe<Scalars["Int"]>;
    reminders?: Maybe<Scalars["Json"]>;
    requestPermissionToJoin?: Maybe<Scalars["Boolean"]>;
    requireConsentToRecord?: Maybe<Scalars["Boolean"]>;
    restrictedWidgets?: Maybe<Scalars["Json"]>;
    room?: Maybe<RoomCreateOneWithoutSessionInstancesInput>;
    sendEmailsAutomatically: Scalars["Boolean"];
    sendEndSessionEmail?: Maybe<Scalars["Boolean"]>;
    sessionTags?: Maybe<SessionTagsInput>;
    shareScreenPid?: Maybe<Scalars["String"]>;
    showAgendaInLobby?: Maybe<Scalars["Boolean"]>;
    showExitPageCta?: Maybe<ExitPageCtaType>;
    source?: Maybe<SessionSource>;
    speakerUserIds?: Maybe<SessionCreatespeakerUserIdsInput>;
    startAt?: Maybe<Scalars["DateTime"]>;
    syncToAgenda?: Maybe<Scalars["Boolean"]>;
    timeDependency?: Maybe<Scalars["Boolean"]>;
    timeZone?: Maybe<Scalars["String"]>;
    transcriptionActive?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    view?: Maybe<SessionView>;
};

export type SessionCreatespeakerUserIdsInput = {
    set?: Maybe<Array<Scalars["String"]>>;
};

export type SessionDiff = {
    __typename?: "SessionDiff";
    actualStart?: Maybe<Scalars["DateTime"]>;
    allowAgendaType?: Maybe<SessionFlagsType>;
    allowCameraType?: Maybe<SessionFlagsType>;
    allowCollaborationInLobby?: Maybe<Scalars["Boolean"]>;
    allowMicrophoneType?: Maybe<SessionFlagsType>;
    allowScreenShareType?: Maybe<SessionFlagsType>;
    askForGuestEmail?: Maybe<Scalars["Boolean"]>;
    autoRecording?: Maybe<Scalars["Boolean"]>;
    autoStartSession?: Maybe<Scalars["Boolean"]>;
    autoTranscribing?: Maybe<Scalars["Boolean"]>;
    backstage?: Maybe<Scalars["Boolean"]>;
    bypassSessionPasscode?: Maybe<Scalars["Boolean"]>;
    collaborativeSession?: Maybe<Scalars["Json"]>;
    collectFeedback?: Maybe<CollectFeedbackType>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdFromTemplateId?: Maybe<Scalars["String"]>;
    createdFromYourFirstSessionTemplate?: Maybe<Scalars["Boolean"]>;
    description?: Maybe<Scalars["String"]>;
    disabledNotifications?: Maybe<Array<Scalars["String"]>>;
    enableReactionsType?: Maybe<SessionFlagsType>;
    endByWorker?: Maybe<Scalars["Boolean"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    exitPageOfferId?: Maybe<Scalars["String"]>;
    feedbackFormAnswers?: Maybe<Array<Scalars["Json"]>>;
    feedbackFormId?: Maybe<Scalars["String"]>;
    fullSizeVideosInRecording?: Maybe<Scalars["Boolean"]>;
    groupChatAccess?: Maybe<Scalars["Boolean"]>;
    hideIcsGuestList?: Maybe<Scalars["Boolean"]>;
    hideNonStreamingParticipants?: Maybe<Scalars["Boolean"]>;
    newId?: Maybe<Scalars["ID"]>;
    isBooking?: Maybe<Scalars["Boolean"]>;
    isPrivate?: Maybe<Scalars["Boolean"]>;
    isTask?: Maybe<Scalars["Boolean"]>;
    lifecycle?: Maybe<SessionLifecycle>;
    livestreamOutputUrls?: Maybe<Scalars["Json"]>;
    lobbyAccess?: Maybe<Scalars["Boolean"]>;
    memoryAccessType?: Maybe<MemoryAccessType>;
    messageReminders?: Maybe<Scalars["Json"]>;
    name?: Maybe<Scalars["String"]>;
    occurrenceId?: Maybe<Scalars["DateTime"]>;
    onboardingType?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    ownerHalfDayClock?: Maybe<Scalars["Boolean"]>;
    ownerLanguage?: Maybe<Scalars["String"]>;
    participantsMacroAccess?: Maybe<ParticipantsMacroAccess>;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    plannedEnd?: Maybe<Scalars["DateTime"]>;
    privateChatAccess?: Maybe<PrivateChatAccess>;
    provideSessionPasscode?: Maybe<Scalars["Boolean"]>;
    quickSession?: Maybe<Scalars["Boolean"]>;
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType?: Maybe<SessionRecordingType>;
    recurrenceDataId?: Maybe<Scalars["String"]>;
    reminderInMinutes?: Maybe<Scalars["Int"]>;
    reminders?: Maybe<Scalars["Json"]>;
    requestPermissionToJoin?: Maybe<Scalars["Boolean"]>;
    requireConsentToRecord?: Maybe<Scalars["Boolean"]>;
    restrictedWidgets?: Maybe<Scalars["Json"]>;
    roomId?: Maybe<Scalars["String"]>;
    sendEmailsAutomatically?: Maybe<Scalars["Boolean"]>;
    sendEndSessionEmail?: Maybe<Scalars["Boolean"]>;
    shareScreenPid?: Maybe<Scalars["String"]>;
    showAgendaInLobby?: Maybe<Scalars["Boolean"]>;
    showExitPageCta?: Maybe<ExitPageCtaType>;
    source?: Maybe<SessionSource>;
    speakerUserIds?: Maybe<Array<Scalars["String"]>>;
    startAt?: Maybe<Scalars["DateTime"]>;
    syncToAgenda?: Maybe<Scalars["Boolean"]>;
    timeDependency?: Maybe<Scalars["Boolean"]>;
    timeZone?: Maybe<Scalars["String"]>;
    timerCurrentDuration?: Maybe<Scalars["Int"]>;
    timerInitialDuration?: Maybe<Scalars["Int"]>;
    timerStartAt?: Maybe<Scalars["DateTime"]>;
    timerState?: Maybe<SessionTimerState>;
    transcriptionActive?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    view?: Maybe<SessionView>;
    workspaceId?: Maybe<Scalars["String"]>;
};

export type SessionEvent = {
    __typename?: "SessionEvent";
    afterRegistrationOffer?: Maybe<Offer>;
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    bannerUrl?: Maybe<Scalars["String"]>;
    colorPalette?: Maybe<Scalars["Json"]>;
    createdAt: Scalars["DateTime"];
    embedUrl?: Maybe<Scalars["String"]>;
    hasRegistration: Scalars["Boolean"];
    id: Scalars["ID"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isPublic: Scalars["Boolean"];
    language: Scalars["String"];
    maxSeats?: Maybe<Scalars["Int"]>;
    oldId?: Maybe<Scalars["String"]>;
    organizationDetails: Organization;
    payments: Array<PaymentConfig>;
    photoUrl?: Maybe<Scalars["String"]>;
    registrationAnswers?: Maybe<Scalars["Json"]>;
    registrationForm?: Maybe<Scalars["Json"]>;
    requireApproval: Scalars["Boolean"];
    seatsTaken?: Maybe<Scalars["Int"]>;
    session: Session;
    sessionId: Scalars["String"];
    slug: Scalars["String"];
    socialLinks?: Maybe<Scalars["Json"]>;
    speakerOrderJson: Scalars["Json"];
    state: SessionEventState;
    update?: Maybe<SessionEventDiff>;
    updatedAt: Scalars["DateTime"];
    utm?: Maybe<Scalars["Json"]>;
};

export type SessionEventCreateOneWithoutSessionInput = {
    connect?: Maybe<WhereIdInput>;
    create?: Maybe<SessionEventCreateWithoutSessionInput>;
};

export type SessionEventCreateWithoutSessionInput = {
    afterRegistrationOffer?: Maybe<AfterRegistrationOffer>;
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    bannerUrl?: Maybe<Scalars["String"]>;
    colorPalette?: Maybe<Scalars["Json"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    embedUrl?: Maybe<Scalars["String"]>;
    hasRegistration?: Maybe<Scalars["Boolean"]>;
    id?: Maybe<Scalars["String"]>;
    isPrivate?: Maybe<Scalars["Boolean"]>;
    isPublic?: Maybe<Scalars["Boolean"]>;
    maxSeats?: Maybe<Scalars["Int"]>;
    payments?: Maybe<ClonePaymentsCreateInput>;
    photoUrl?: Maybe<Scalars["String"]>;
    registrationAnswers?: Maybe<Scalars["Json"]>;
    registrationForm?: Maybe<Scalars["Json"]>;
    requireApproval?: Maybe<Scalars["Boolean"]>;
    seatsTaken?: Maybe<Scalars["Int"]>;
    slug: Scalars["String"];
    socialLinks?: Maybe<Scalars["Json"]>;
    speakerOrderJson?: Maybe<Scalars["Json"]>;
    state: SessionEventState;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    utm?: Maybe<Scalars["Json"]>;
};

export type SessionEventDiff = {
    __typename?: "SessionEventDiff";
    afterRegistrationOfferId?: Maybe<Scalars["String"]>;
    afterRegistrationRedirectUrl?: Maybe<Scalars["String"]>;
    bannerUrl?: Maybe<Scalars["String"]>;
    colorPalette?: Maybe<Scalars["Json"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    embedUrl?: Maybe<Scalars["String"]>;
    hasRegistration?: Maybe<Scalars["Boolean"]>;
    newId?: Maybe<Scalars["ID"]>;
    isPublic?: Maybe<Scalars["Boolean"]>;
    language?: Maybe<Scalars["String"]>;
    maxSeats?: Maybe<Scalars["Int"]>;
    photoUrl?: Maybe<Scalars["String"]>;
    registrationAnswers?: Maybe<Scalars["Json"]>;
    registrationForm?: Maybe<Scalars["Json"]>;
    requireApproval?: Maybe<Scalars["Boolean"]>;
    seatsTaken?: Maybe<Scalars["Int"]>;
    sessionId?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    socialLinks?: Maybe<Scalars["Json"]>;
    speakerOrderJson?: Maybe<Scalars["Json"]>;
    state?: Maybe<SessionEventState>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    utm?: Maybe<Scalars["Json"]>;
};

export enum SessionEventFilters {
    Draft = "DRAFT",
    Ended = "ENDED",
    Live = "LIVE",
    Published = "PUBLISHED",
}

export type SessionEventInput = {
    softMuteAll?: Maybe<Scalars["Boolean"]>;
    softStopCameraAll?: Maybe<Scalars["Boolean"]>;
    stopScreenShare?: Maybe<Scalars["Boolean"]>;
};

export enum SessionEventState {
    Draft = "DRAFT",
    Published = "PUBLISHED",
}

export type SessionEventUpdateOneWithoutSessionInput = {
    create?: Maybe<SessionEventCreateWithoutSessionInput>;
    update?: Maybe<SessionEventUpdateWithoutSessionDataInput>;
};

export type SessionEventUpdateWithoutSessionDataInput = {
    afterRegistrationOffer?: Maybe<AfterRegistrationOffer>;
    afterRegistrationOfferId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    afterRegistrationRedirectUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
    bannerUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
    colorPalette?: Maybe<Scalars["Json"]>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    embedUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
    hasRegistration?: Maybe<BoolFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
    isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
    maxSeats?: Maybe<NullableIntFieldUpdateOperationsInput>;
    payments?: Maybe<ClonePaymentsCreateInput>;
    photoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
    registrationAnswers?: Maybe<Scalars["Json"]>;
    registrationForm?: Maybe<Scalars["Json"]>;
    requireApproval?: Maybe<BoolFieldUpdateOperationsInput>;
    seatsTaken?: Maybe<NullableIntFieldUpdateOperationsInput>;
    slug?: Maybe<StringFieldUpdateOperationsInput>;
    socialLinks?: Maybe<Scalars["Json"]>;
    speakerOrderJson?: Maybe<Scalars["Json"]>;
    state?: Maybe<SessionEventState>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    utm?: Maybe<Scalars["Json"]>;
};

export type SessionEventWhereUniqueInput = {
    id?: Maybe<Scalars["String"]>;
    sessionId?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
};

export enum SessionFilterEnum {
    All = "all",
    Bookings = "bookings",
    Events = "events",
    Rooms = "rooms",
}

export type SessionFlags = {
    allowAgendaType?: Maybe<SessionFlagsType>;
    allowCameraType?: Maybe<SessionFlagsType>;
    allowCollaborationInLobby?: Maybe<Scalars["Boolean"]>;
    allowMicrophoneType?: Maybe<SessionFlagsType>;
    allowScreenShareType?: Maybe<SessionFlagsType>;
    askForGuestEmail?: Maybe<Scalars["Boolean"]>;
    autoRecording?: Maybe<Scalars["Boolean"]>;
    autoStartSession?: Maybe<Scalars["Boolean"]>;
    autoTranscribing?: Maybe<Scalars["Boolean"]>;
    bypassSessionPasscode?: Maybe<Scalars["Boolean"]>;
    collectFeedback?: Maybe<CollectFeedbackType>;
    disabledNotifications?: Maybe<Array<Maybe<Scalars["String"]>>>;
    enableReactionsType?: Maybe<SessionFlagsType>;
    fullSizeVideosInRecording?: Maybe<Scalars["Boolean"]>;
    groupChatAccess?: Maybe<Scalars["Boolean"]>;
    hideIcsGuestList?: Maybe<Scalars["Boolean"]>;
    hideNonStreamingParticipants?: Maybe<Scalars["Boolean"]>;
    livestreamOutputUrls?: Maybe<Scalars["Json"]>;
    lobbyAccess?: Maybe<Scalars["Boolean"]>;
    memoryAccessType?: Maybe<MemoryAccessType>;
    messageReminders?: Maybe<Scalars["Json"]>;
    participantsMacroAccess?: Maybe<ParticipantsMacroAccess>;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    privateChatAccess?: Maybe<PrivateChatAccess>;
    provideSessionPasscode?: Maybe<Scalars["Boolean"]>;
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType?: Maybe<SessionRecordingType>;
    reminderInMinutes?: Maybe<Scalars["Int"]>;
    reminders?: Maybe<Scalars["Json"]>;
    requestPermissionToJoin?: Maybe<Scalars["Boolean"]>;
    requireConsentToRecord?: Maybe<Scalars["Boolean"]>;
    restrictedWidgets?: Maybe<Scalars["Json"]>;
    sendEndSessionEmail?: Maybe<Scalars["Boolean"]>;
    showAgendaInLobby?: Maybe<Scalars["Boolean"]>;
    showExitPageCta?: Maybe<ExitPageCtaType>;
    transcriptionActive?: Maybe<Scalars["Boolean"]>;
    view?: Maybe<SessionView>;
};

export enum SessionFlagsType {
    Everyone = "EVERYONE",
    Hosts = "HOSTS",
    Speakers = "SPEAKERS",
}

export type SessionIdOfRoom = {
    __typename?: "SessionIdOfRoom";
    active?: Maybe<Scalars["Boolean"]>;
    sessionId?: Maybe<Scalars["String"]>;
};

export type SessionJoinDetails = {
    __typename?: "SessionJoinDetails";
    access_token: Scalars["String"];
    sessionId: Scalars["String"];
};

export enum SessionLifecycle {
    Ended = "ENDED",
    NotStarted = "NOT_STARTED",
    Started = "STARTED",
}

export type SessionOrderByInput = {
    actualStart?: Maybe<SortOrder>;
    endedAt?: Maybe<SortOrder>;
    order?: Maybe<SortOrder>;
    quickSession?: Maybe<SortOrder>;
};

export type SessionParticipantUpdatesResult = {
    __typename?: "SessionParticipantUpdatesResult";
    action?: Maybe<ParticipantAction>;
    changedBy?: Maybe<ParticipantChangedByData>;
    participant: Participant;
};

export type SessionRecording = {
    __typename?: "SessionRecording";
    createdAt: Scalars["DateTime"];
    downloadUrl?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    livestreamOutputUrls: Scalars["Json"];
    name: Scalars["String"];
    permission: SessionRecordingPermission;
    processingStatus: RecordingProcessingStatus;
    startedAt?: Maybe<Scalars["DateTime"]>;
    state: SessionRecordingState;
    type: SessionRecordingType;
    version: Scalars["Int"];
};

export type SessionRecordingListRelationFilter = {
    every?: Maybe<SessionRecordingWhereInput>;
    none?: Maybe<SessionRecordingWhereInput>;
    some?: Maybe<SessionRecordingWhereInput>;
};

export enum SessionRecordingPermission {
    Private = "PRIVATE",
    Public = "PUBLIC",
}

export enum SessionRecordingState {
    Completed = "COMPLETED",
    InProgress = "IN_PROGRESS",
    Starting = "STARTING",
    Stopping = "STOPPING",
}

export enum SessionRecordingType {
    Livestream = "LIVESTREAM",
    Recording = "RECORDING",
    RecordingLivestream = "RECORDING_LIVESTREAM",
}

export type SessionRecordingWhereInput = {
    AND?: Maybe<Array<SessionRecordingWhereInput>>;
    NOT?: Maybe<Array<SessionRecordingWhereInput>>;
    OR?: Maybe<Array<SessionRecordingWhereInput>>;
    createdAt?: Maybe<DateTimeFilter>;
    downloadUrl?: Maybe<StringNullableFilter>;
    id?: Maybe<StringFilter>;
    name?: Maybe<StringFilter>;
    permission?: Maybe<SessionRecordingPermission>;
    processingStatus?: Maybe<RecordingProcessingStatus>;
    session?: Maybe<SessionWhereInput>;
    sessionId?: Maybe<StringFilter>;
    startedAt?: Maybe<DateTimeNullableFilter>;
    state?: Maybe<SessionRecordingState>;
    updatedAt?: Maybe<DateTimeFilter>;
    user?: Maybe<UserWhereInput>;
    userId?: Maybe<StringFilter>;
    version?: Maybe<IntFilter>;
};

export type SessionResult = {
    __typename?: "SessionResult";
    end?: Maybe<Scalars["String"]>;
    highlight: Highlight;
    id: Scalars["String"];
    isBooking: Scalars["Boolean"];
    isEvent: Scalars["Boolean"];
    lifecycle: SessionLifecycle;
    name: Scalars["String"];
    ownedBySelf: Scalars["Boolean"];
    plannedStart?: Maybe<Scalars["String"]>;
    recurrenceId?: Maybe<Scalars["String"]>;
    start?: Maybe<Scalars["String"]>;
};

export type SessionSettings = {
    __typename?: "SessionSettings";
    allowAgendaType: SessionFlagsType;
    allowCameraType: SessionFlagsType;
    allowMicrophoneType: SessionFlagsType;
    allowScreenShareType: SessionFlagsType;
    askForGuestEmail: Scalars["Boolean"];
    autoRecording: Scalars["Boolean"];
    autoStartSession: Scalars["Boolean"];
    autoTranscribing: Scalars["Boolean"];
    bypassSessionPasscode: Scalars["Boolean"];
    collectFeedback: CollectFeedbackType;
    disabledNotifications: Array<Scalars["String"]>;
    enableReactionsType: SessionFlagsType;
    exitPageOfferId?: Maybe<Scalars["String"]>;
    feedbackFormId?: Maybe<Scalars["String"]>;
    fullSizeVideosInRecording: Scalars["Boolean"];
    groupChatAccess: Scalars["Boolean"];
    hideIcsGuestList: Scalars["Boolean"];
    hideNonStreamingParticipants: Scalars["Boolean"];
    id: Scalars["ID"];
    livestreamOutputUrls: Scalars["Json"];
    memoryAccessType: MemoryAccessType;
    messageReminders: Scalars["Json"];
    participantsMacroAccess: ParticipantsMacroAccess;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    privateChatAccess: PrivateChatAccess;
    provideSessionPasscode: Scalars["Boolean"];
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType: SessionRecordingType;
    reminders: Scalars["Json"];
    requestPermissionToJoin: Scalars["Boolean"];
    requireConsentToRecord: Scalars["Boolean"];
    restrictedWidgets: Scalars["Json"];
    sendEndSessionEmail: Scalars["Boolean"];
    sessionSource: SessionSettingsSource;
    showExitPageCta: ExitPageCtaType;
};

export type SessionSettingsFlagsInput = {
    allowAgendaType: SessionFlagsType;
    allowCameraType: SessionFlagsType;
    allowMicrophoneType: SessionFlagsType;
    allowScreenShareType: SessionFlagsType;
    askForGuestEmail: Scalars["Boolean"];
    autoRecording: Scalars["Boolean"];
    autoStartSession: Scalars["Boolean"];
    autoTranscribing: Scalars["Boolean"];
    bypassSessionPasscode: Scalars["Boolean"];
    collectFeedback: CollectFeedbackType;
    disabledNotifications: Array<Scalars["String"]>;
    enableReactionsType: SessionFlagsType;
    exitPageOfferId?: Maybe<Scalars["String"]>;
    feedbackFormId?: Maybe<Scalars["String"]>;
    fullSizeVideosInRecording: Scalars["Boolean"];
    groupChatAccess: Scalars["Boolean"];
    hideIcsGuestList: Scalars["Boolean"];
    hideNonStreamingParticipants: Scalars["Boolean"];
    livestreamOutputUrls: Scalars["Json"];
    memoryAccessType: MemoryAccessType;
    messageReminders: Scalars["Json"];
    participantsMacroAccess: ParticipantsMacroAccess;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    privateChatAccess: PrivateChatAccess;
    provideSessionPasscode: Scalars["Boolean"];
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType: SessionRecordingType;
    reminders: Scalars["Json"];
    requestPermissionToJoin: Scalars["Boolean"];
    requireConsentToRecord: Scalars["Boolean"];
    restrictedWidgets: Scalars["Json"];
    sendEndSessionEmail: Scalars["Boolean"];
    sessionSource: SessionSettingsSource;
    showExitPageCta: ExitPageCtaType;
};

export enum SessionSettingsSource {
    Booking = "BOOKING",
    Event = "EVENT",
    Instant = "INSTANT",
    Planned = "PLANNED",
    Room = "ROOM",
}

export enum SessionSource {
    App = "APP",
    Book = "BOOK",
    BrowserExtension = "BROWSER_EXTENSION",
    Event = "EVENT",
    GoogleWorkspace = "GOOGLE_WORKSPACE",
    Room = "ROOM",
    Slack = "SLACK",
}

export type SessionSpeaker = {
    __typename?: "SessionSpeaker";
    agendaItemSpeakers: Array<AgendaItemSpeaker>;
    bio?: Maybe<Scalars["String"]>;
    company?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    email?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    jobTitle?: Maybe<Scalars["String"]>;
    linkedInUrl?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    photoUrl?: Maybe<Scalars["String"]>;
    thumbnailUrl?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
};

export type SessionSpeakerCreateOneWithoutAgendaItemSpeakersInput = {
    connect?: Maybe<WhereIdInput>;
    create?: Maybe<SessionSpeakerCreateWithoutAgendaItemSpeakersInput>;
};

export type SessionSpeakerCreateWithoutAgendaItemSpeakersInput = {
    bio?: Maybe<Scalars["String"]>;
    company?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    jobTitle?: Maybe<Scalars["String"]>;
    linkedInUrl?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    photoUrl?: Maybe<Scalars["String"]>;
    thumbnailUrl?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SessionSpeakerWhereInput = {
    id?: Maybe<StringFilter>;
};

export type SessionsUsingResourceResult = {
    __typename?: "SessionsUsingResourceResult";
    endedAt?: Maybe<Scalars["DateTime"]>;
    isBooking?: Maybe<Scalars["Boolean"]>;
    isEvent?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    ownerFirstName?: Maybe<Scalars["String"]>;
    ownerId?: Maybe<Scalars["String"]>;
    ownerLastName?: Maybe<Scalars["String"]>;
    ownerProfilePicture?: Maybe<Scalars["String"]>;
    recurrenceId?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
    sessionLifecycle: SessionLifecycle;
    startAt?: Maybe<Scalars["DateTime"]>;
};

export type SessionTag = {
    __typename?: "SessionTag";
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type SessionTagsInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    disconnect?: Maybe<Array<WhereIdInput>>;
};

export enum SessionTimerState {
    Paused = "PAUSED",
    Started = "STARTED",
    Stopped = "STOPPED",
}

export type SessionTranscript = {
    __typename?: "SessionTranscript";
    content: Array<SessionTranscriptContent>;
    id: Scalars["ID"];
    participantId: Scalars["String"];
    sessionId: Scalars["String"];
    sourceAudio: Scalars["String"];
    sourceLanguage: Scalars["String"];
    sourceTimestamp: Scalars["DateTime"];
};

export type SessionTranscriptContentArgs = {
    preferredLanguage?: Maybe<Scalars["String"]>;
};

export type SessionTranscriptContent = {
    __typename?: "SessionTranscriptContent";
    id: Scalars["ID"];
    isOriginal: Scalars["Boolean"];
    language: Scalars["String"];
    text: Scalars["String"];
    transcriptionId: Scalars["String"];
};

export type SessionUpdateInput = {
    actualStart?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    agendaItems?: Maybe<AgendaItemUpdateManyInput>;
    allowAgendaType?: Maybe<SessionFlagsType>;
    allowCameraType?: Maybe<SessionFlagsType>;
    allowCollaborationInLobby?: Maybe<BoolFieldUpdateOperationsInput>;
    allowMicrophoneType?: Maybe<SessionFlagsType>;
    allowScreenShareType?: Maybe<SessionFlagsType>;
    askForGuestEmail?: Maybe<BoolFieldUpdateOperationsInput>;
    autoRecording?: Maybe<BoolFieldUpdateOperationsInput>;
    autoStartSession?: Maybe<Scalars["Boolean"]>;
    autoTranscribing?: Maybe<BoolFieldUpdateOperationsInput>;
    autopilot?: Maybe<NullableBoolFieldUpdateOperationsInput>;
    bypassSessionPasscode?: Maybe<Scalars["Boolean"]>;
    collectFeedback?: Maybe<CollectFeedbackType>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    createdFromTemplate?: Maybe<AgendaTemplateUpdateOneWithoutSessionsInput>;
    createdFromYourFirstSessionTemplate?: Maybe<BoolFieldUpdateOperationsInput>;
    deletionInProgress?: Maybe<NullableBoolFieldUpdateOperationsInput>;
    description?: Maybe<NullableStringFieldUpdateOperationsInput>;
    disabledNotifications?: Maybe<Array<Maybe<Scalars["String"]>>>;
    enableReactionsType?: Maybe<SessionFlagsType>;
    endByWorker?: Maybe<BoolFieldUpdateOperationsInput>;
    endedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    event?: Maybe<SessionEventUpdateOneWithoutSessionInput>;
    exitPageOffer?: Maybe<ExitPageOfferUpdateInput>;
    exitPageOfferId?: Maybe<Scalars["String"]>;
    feedbackForm?: Maybe<FeedbackFormUpdateInput>;
    feedbackFormAnswers?: Maybe<Scalars["Json"]>;
    feedbackFormId?: Maybe<Scalars["String"]>;
    followUpOfSessionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    fullSizeVideosInRecording?: Maybe<Scalars["Boolean"]>;
    groupChatAccess?: Maybe<Scalars["Boolean"]>;
    hideIcsGuestList?: Maybe<Scalars["Boolean"]>;
    hideNonStreamingParticipants?: Maybe<BoolFieldUpdateOperationsInput>;
    id?: Maybe<StringFieldUpdateOperationsInput>;
    isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
    lifecycle?: Maybe<SessionLifecycle>;
    livestreamOutputUrls?: Maybe<Scalars["Json"]>;
    lobbyAccess?: Maybe<BoolFieldUpdateOperationsInput>;
    macroArtifacts?: Maybe<ArtifactUpdateManyWithoutMacroArtifactForSessionInput>;
    memoryAccessType?: Maybe<MemoryAccessType>;
    messageReminders?: Maybe<Scalars["Json"]>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    occurrenceId?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    onboardingType?: Maybe<NullableStringFieldUpdateOperationsInput>;
    order?: Maybe<IntFieldUpdateOperationsInput>;
    participantsMacroAccess?: Maybe<ParticipantsMacroAccess>;
    passcodeToJoin?: Maybe<Scalars["String"]>;
    plannedEnd?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    privateChatAccess?: Maybe<PrivateChatAccess>;
    provideSessionPasscode?: Maybe<Scalars["Boolean"]>;
    quickSession?: Maybe<BoolFieldUpdateOperationsInput>;
    recordingConferenceView?: Maybe<Scalars["String"]>;
    recordingPresentationView?: Maybe<Scalars["String"]>;
    recordingType?: Maybe<SessionRecordingType>;
    recurrenceData?: Maybe<RecurrenceDataUpdateOneWithoutSessionInput>;
    reminderInMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
    reminders?: Maybe<Scalars["Json"]>;
    requestPermissionToJoin?: Maybe<BoolFieldUpdateOperationsInput>;
    requireConsentToRecord?: Maybe<BoolFieldUpdateOperationsInput>;
    restrictedWidgets?: Maybe<Scalars["Json"]>;
    room?: Maybe<RoomUpdateOneWithoutSessionInstancesInput>;
    sendEmailsAutomatically?: Maybe<BoolFieldUpdateOperationsInput>;
    sendEndSessionEmail?: Maybe<Scalars["Boolean"]>;
    sessionTags?: Maybe<SessionTagsInput>;
    shareScreenPid?: Maybe<NullableStringFieldUpdateOperationsInput>;
    showAgendaInLobby?: Maybe<BoolFieldUpdateOperationsInput>;
    showExitPageCta?: Maybe<ExitPageCtaType>;
    source?: Maybe<SessionSource>;
    speakerUserIds?: Maybe<SessionUpdatespeakerUserIdsInput>;
    startAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    syncToAgenda?: Maybe<BoolFieldUpdateOperationsInput>;
    timeDependency?: Maybe<BoolFieldUpdateOperationsInput>;
    timeZone?: Maybe<NullableStringFieldUpdateOperationsInput>;
    transcriptionActive?: Maybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    view?: Maybe<SessionView>;
};

export type SessionUpdatespeakerUserIdsInput = {
    set?: Maybe<Array<Scalars["String"]>>;
};

export enum SessionView {
    Audience = "AUDIENCE",
    Collaboration = "COLLABORATION",
    Default = "DEFAULT",
    Focus = "FOCUS",
    None = "NONE",
    Presentation = "PRESENTATION",
    Spotlight = "SPOTLIGHT",
}

export type SessionWatchdogUpdatePrivateResult = {
    __typename?: "SessionWatchdogUpdatePrivateResult";
    approvalRequestsAsOwner?: Maybe<ApprovalRequestsAsOwnerResult>;
    approvalRequestsResponse?: Maybe<WaitForApprovalInSessionResult>;
    chatUpdate?: Maybe<ChatUpdateWatchdogResult>;
    id: Scalars["String"];
    nameOfJoinDeniedParticipant?: Maybe<Scalars["String"]>;
    participantIndividualMute?: Maybe<Scalars["Boolean"]>;
    participantIndividualStopCamera?: Maybe<Scalars["Boolean"]>;
    sessionEndedByLimitation?: Maybe<Scalars["Int"]>;
    softMuteAll?: Maybe<Scalars["Boolean"]>;
    softStopCameraAll?: Maybe<Scalars["Boolean"]>;
    stopScreenShare?: Maybe<Scalars["Boolean"]>;
};

export type SessionWatchdogUpdateResult = {
    __typename?: "SessionWatchdogUpdateResult";
    breakoutRoomArtifact?: Maybe<Artifact>;
    chatUpdate?: Maybe<ChatUpdateWatchdogResult>;
    childOfBreakoutRooms?: Maybe<BreakoutRoomsConfiguration>;
    deleteQuestion?: Maybe<DeleteQuestion>;
    deletedAgendaItemIds?: Maybe<Array<Scalars["String"]>>;
    deletedMacroArtifactIds?: Maybe<Array<Scalars["String"]>>;
    deletedParticipantIds?: Maybe<Array<Scalars["String"]>>;
    deletedSession?: Maybe<Scalars["Boolean"]>;
    drawerState?: Maybe<PushDrawerStateResult>;
    id: Scalars["String"];
    newTranscript?: Maybe<SessionTranscript>;
    resourceNames?: Maybe<Array<ResourceName>>;
    session?: Maybe<Session>;
    upsertAgendaItems?: Maybe<Array<AgendaItem>>;
    upsertMacroArtifacts?: Maybe<Array<Artifact>>;
    upsertParticipants?: Maybe<Array<SessionParticipantUpdatesResult>>;
    upsertRecordings?: Maybe<Array<SessionRecording>>;
};

export type SessionWhereInput = {
    AND?: Maybe<Array<SessionWhereInput>>;
    OR?: Maybe<Array<SessionWhereInput>>;
    actualStart?: Maybe<DateTimeNullableFilter>;
    booking?: Maybe<NullableDbFilter>;
    childOfBreakoutRooms?: Maybe<WhereIdInput>;
    createdAt?: Maybe<DateTimeNullableFilter>;
    endedAt?: Maybe<DateTimeNullableFilter>;
    event?: Maybe<NullableDbFilter>;
    isChildOfBreakoutRooms?: Maybe<BoolNullableFilter>;
    lifecycle?: Maybe<SessionLifecycle>;
    participants?: Maybe<ParticipantListRelationFilter>;
    plannedEnd?: Maybe<DateTimeNullableFilter>;
    quickSession?: Maybe<BoolNullableFilter>;
    recordings?: Maybe<SessionRecordingListRelationFilter>;
    room?: Maybe<NullableDbFilter>;
    startAt?: Maybe<DateTimeNullableFilter>;
};

export type ShallowSearchResult = {
    __typename?: "ShallowSearchResult";
    pageToken?: Maybe<Scalars["String"]>;
    sessions: Array<SessionResult>;
};

export type SlidoUrlDetails = {
    __typename?: "SlidoUrlDetails";
    title?: Maybe<Scalars["String"]>;
};

export enum SortOrder {
    Asc = "asc",
    Desc = "desc",
}

export type SpeakerDetailsWhereInput = {
    id?: Maybe<StringFilter>;
    isNot?: Maybe<SessionSpeakerWhereInput>;
};

export type StringChunkPayload = {
    __typename?: "StringChunkPayload";
    chunk: Scalars["String"];
    model?: Maybe<Scalars["String"]>;
};

export type StringFieldUpdateOperationsInput = {
    set?: Maybe<Scalars["String"]>;
};

export type StringFilter = {
    contains?: Maybe<Scalars["String"]>;
    endsWith?: Maybe<Scalars["String"]>;
    equals?: Maybe<Scalars["String"]>;
    gt?: Maybe<Scalars["String"]>;
    gte?: Maybe<Scalars["String"]>;
    in?: Maybe<Array<Scalars["String"]>>;
    lt?: Maybe<Scalars["String"]>;
    lte?: Maybe<Scalars["String"]>;
    not?: Maybe<NestedStringFilter>;
    notIn?: Maybe<Array<Scalars["String"]>>;
    startsWith?: Maybe<Scalars["String"]>;
};

export type StringNullableFilter = {
    contains?: Maybe<Scalars["String"]>;
    endsWith?: Maybe<Scalars["String"]>;
    equals?: Maybe<Scalars["String"]>;
    gt?: Maybe<Scalars["String"]>;
    gte?: Maybe<Scalars["String"]>;
    in?: Maybe<Array<Scalars["String"]>>;
    lt?: Maybe<Scalars["String"]>;
    lte?: Maybe<Scalars["String"]>;
    not?: Maybe<NestedStringNullableFilter>;
    notIn?: Maybe<Array<Scalars["String"]>>;
    startsWith?: Maybe<Scalars["String"]>;
};

export type StripeSyncedAccount = {
    __typename?: "StripeSyncedAccount";
    accountId?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    providerId?: Maybe<Scalars["String"]>;
};

export type Subscription = {
    __typename?: "Subscription";
    applicationStateChanges?: Maybe<ApplicationState>;
    globalNotifications?: Maybe<GlobalNotificationsResult>;
    moduleUpdated?: Maybe<Module>;
    onBookingEventUpdate?: Maybe<BookingEventUpdateEvent>;
    onGenerateAnything?: Maybe<StringChunkPayload>;
    onGeneratePoll?: Maybe<GeneratedPollsPayload>;
    onGoogleContactsSyncFinished?: Maybe<OnGoogleContactsSyncFinishedResult>;
    onMicrosoftContactsSyncFinished?: Maybe<OnMicrosoftContactsSyncFinishedResult>;
    onNewBooking?: Maybe<BookingEvent>;
    onPushSessionView?: Maybe<OnPushSessionViewResult>;
    onReactionSend?: Maybe<OnReactionSendResult>;
    onRecordingUpdated?: Maybe<OnRecordingUpdated>;
    onSessionDiffSend?: Maybe<OnSessionDiffSendResult>;
    onShowOffer?: Maybe<OfferContainerUpdatePayload>;
    onStartGenerateAgenda?: Maybe<GeneratedAgendaItemPayload>;
    onStartGenerateSummary?: Maybe<StringChunkPayload>;
    pollUpdatesForOwners?: Maybe<PollSubscriptionOwnerPayload>;
    pollUpdatesForParticipants?: Maybe<PollSubscriptionParticipantPayload>;
    questionAnsweredForParticipant?: Maybe<Question>;
    questionsBeingAnswered?: Maybe<Question>;
    questionsContainerUpdate?: Maybe<QaQuestionsContainerUpdatePayload>;
    resxResourceUpdated?: Maybe<ResxResourceUpdatePayload>;
    resxResultUpdated?: Maybe<ResxResultUpdatePayload>;
    userUpdates?: Maybe<UserUpdatesPayload>;
    watchdog?: Maybe<SessionWatchdogUpdateResult>;
    watchdogAgendaTemplate?: Maybe<AgendaTemplateWatchdogResult>;
    watchdogPrivate?: Maybe<SessionWatchdogUpdatePrivateResult>;
};

export type SubscriptionOnBookingEventUpdateArgs = {
    id: Scalars["String"];
};

export type SubscriptionOnGenerateAnythingArgs = {
    prompt: Scalars["String"];
    subscriptionId: Scalars["String"];
    temperature: Scalars["Float"];
};

export type SubscriptionOnGeneratePollArgs = {
    prompt: Scalars["String"];
};

export type SubscriptionOnGoogleContactsSyncFinishedArgs = {
    userEmail: Scalars["String"];
};

export type SubscriptionOnMicrosoftContactsSyncFinishedArgs = {
    userEmail: Scalars["String"];
};

export type SubscriptionOnPushSessionViewArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionOnReactionSendArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionOnSessionDiffSendArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionOnShowOfferArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionOnStartGenerateAgendaArgs = {
    includeTitleAndDescription?: Maybe<Scalars["Boolean"]>;
    prompt: Scalars["String"];
    sessionId: Scalars["String"];
};

export type SubscriptionOnStartGenerateSummaryArgs = {
    action: GenerateSummaryAction;
    language: Scalars["String"];
    prompt?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
};

export type SubscriptionPollUpdatesForOwnersArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionPollUpdatesForParticipantsArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionQuestionAnsweredForParticipantArgs = {
    questionsContainerId: Scalars["String"];
};

export type SubscriptionQuestionsBeingAnsweredArgs = {
    questionsContainerId: Scalars["String"];
};

export type SubscriptionQuestionsContainerUpdateArgs = {
    questionsContainerId: Scalars["String"];
};

export type SubscriptionResxResultUpdatedArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionWatchdogArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionWatchdogAgendaTemplateArgs = {
    agendaTemplateId: Scalars["String"];
};

export type SubscriptionWatchdogPrivateArgs = {
    sessionId: Scalars["String"];
};

export type SubscriptionDetailsPayload = {
    __typename?: "SubscriptionDetailsPayload";
    cancel_url: Scalars["String"];
    last_payment?: Maybe<PaddlePaymentDetails>;
    next_payment?: Maybe<PaddlePaymentDetails>;
    organization_id: Scalars["String"];
    payment_information?: Maybe<PaddlePaymentInfo>;
    state: PaddleSubscriptionState;
    update_url: Scalars["String"];
    user_email: Scalars["String"];
};

export type SubscriptionModifier = {
    __typename?: "SubscriptionModifier";
    id: Scalars["ID"];
    quantity: Scalars["Int"];
    type: SubscriptionModifierType;
};

export enum SubscriptionModifierType {
    MoreRecordingHours = "MORE_RECORDING_HOURS",
    MoreUsers = "MORE_USERS",
}

export type SupportedTypesDescriptor = {
    __typename?: "SupportedTypesDescriptor";
    type?: Maybe<Scalars["String"]>;
};

export type SyncAppleAccountResponse = {
    __typename?: "SyncAppleAccountResponse";
    email?: Maybe<Scalars["String"]>;
};

export type TemplateTagCreateManyWithoutAgendaTemplatesInput = {
    connect?: Maybe<Array<WhereIdInput>>;
};

export type TemplateTagUpdateManyInput = {
    connect?: Maybe<Array<WhereIdInput>>;
    disconnect?: Maybe<Array<WhereIdInput>>;
};

export type ToogleRoomResult = {
    __typename?: "ToogleRoomResult";
    active?: Maybe<Scalars["Boolean"]>;
};

export type TranscriptFile = {
    __typename?: "TranscriptFile";
    createdAt: Scalars["DateTime"];
    format: Scalars["String"];
    id: Scalars["ID"];
    path: Scalars["String"];
    sessionId: Scalars["String"];
    timezoneOffset: Scalars["Int"];
    url: Scalars["String"];
};

export type TranscriptUploadUrl = {
    __typename?: "TranscriptUploadUrl";
    uploadUrl: Scalars["String"];
    url: Scalars["String"];
};

export type TryJoinSessionResult = {
    __typename?: "TryJoinSessionResult";
    instanceId?: Maybe<Scalars["String"]>;
    isRoom?: Maybe<Scalars["Boolean"]>;
    participant?: Maybe<Participant>;
    redirectTo?: Maybe<Scalars["String"]>;
    state: ParticipantSessionState;
    tokenNeedsRefresh?: Maybe<Scalars["Boolean"]>;
};

export type TwitchUrlDetails = {
    __typename?: "TwitchUrlDetails";
    title?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
};

export type UnSplashPhoto = {
    __typename?: "UnSplashPhoto";
    download_location: Scalars["String"];
    name: Scalars["String"];
    regularUrl: Scalars["String"];
    thumbnailUrl: Scalars["String"];
    username: Scalars["String"];
};

export type UnSplashPhotoCategories = {
    __typename?: "UnSplashPhotoCategories";
    category: Scalars["String"];
    photos: Array<UnSplashPhoto>;
};

export type UnsubscribePreference = {
    __typename?: "UnsubscribePreference";
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    id: Scalars["ID"];
    sendAppEmails: Scalars["Boolean"];
    token: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type UpdateBookingCollaboratorInput = {
    enabled?: Maybe<Scalars["Boolean"]>;
    externalCalendarConnections?: Maybe<Scalars["Json"]>;
    isOwner?: Maybe<Scalars["Boolean"]>;
    priority?: Maybe<Scalars["Int"]>;
    rbac?: Maybe<Scalars["Json"]>;
    scheduleId?: Maybe<Scalars["String"]>;
};

export type UpdateMainBreakoutRoom = {
    participantIds?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateRecordingInput = {
    downloadUrl?: Maybe<Scalars["String"]>;
    externalProviderId?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    permission?: Maybe<SessionRecordingPermission>;
    processingStatus?: Maybe<RecordingProcessingStatus>;
    recordingListId?: Maybe<Scalars["String"]>;
    segmentIndex?: Maybe<Scalars["Int"]>;
    state?: Maybe<SessionRecordingState>;
    version?: Maybe<Scalars["Int"]>;
};

export type UploadAnonymousFileResult = {
    __typename?: "UploadAnonymousFileResult";
    uploadUrl: Scalars["String"];
    uuid: Scalars["String"];
};

export type UpsertBreakoutRoomInput = {
    description?: Maybe<Scalars["String"]>;
    id: Scalars["String"];
    name: Scalars["String"];
    participantIds?: Maybe<Array<Scalars["String"]>>;
};

export type UpsertCalendarEvent = {
    __typename?: "UpsertCalendarEvent";
    deletedParticipantIds?: Maybe<Array<Scalars["String"]>>;
    entireEvent?: Maybe<CalendarCustomEvent>;
    sessionId: Scalars["String"];
    upsertParticipants?: Maybe<Array<ParticipantForEvent>>;
};

export type UpsertCustomEmailContentInput = {
    hidden: Scalars["Boolean"];
    id?: Maybe<Scalars["String"]>;
    type: CustomEmailContentType;
    value: Scalars["String"];
};

export type UpsertMembersOfOrganizationPayload = {
    country?: Maybe<Scalars["String"]>;
    department?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    workspaceIds?: Maybe<Array<Scalars["String"]>>;
};

export type UpsertMembersOfOrganizationResponse = {
    __typename?: "UpsertMembersOfOrganizationResponse";
    newMembers: Array<User>;
    skippedMembersCount: Scalars["Int"];
    updatedMembers: Array<User>;
};

export type UrlHistory = {
    __typename?: "UrlHistory";
    id: Scalars["ID"];
    url: Scalars["String"];
};

export type User = {
    __typename?: "User";
    company?: Maybe<Scalars["String"]>;
    concurrentVideosProcessing: Scalars["Int"];
    country?: Maybe<Scalars["String"]>;
    countryCode?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    customBackgrounds: Array<CustomBackground>;
    customSubDomainConfig?: Maybe<CustomSubDomainConfig>;
    defaultBookingCalendars: Scalars["Json"];
    defaultLobbyPicture?: Maybe<LobbyPicture>;
    department?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    emailNotificationsEnabled: Scalars["Boolean"];
    emailNotificationsMinutes: Scalars["Int"];
    externalCalendarsSettings: Scalars["Json"];
    externalReferral?: Maybe<Scalars["String"]>;
    firstDayOfWeek: WeekDay;
    firstLogin: Scalars["Boolean"];
    firstName: Scalars["String"];
    flags?: Maybe<Scalars["Json"]>;
    forms?: Maybe<Scalars["Json"]>;
    halfDayClock: Scalars["Boolean"];
    id: Scalars["ID"];
    industry?: Maybe<Scalars["String"]>;
    jobRole?: Maybe<Scalars["String"]>;
    language: Scalars["String"];
    lastActivityDate: Scalars["DateTime"];
    lastLoginDate?: Maybe<Scalars["DateTime"]>;
    lastName: Scalars["String"];
    lastVersionSeen?: Maybe<Scalars["Int"]>;
    lobbyPictureLinksHistory: Array<UrlHistory>;
    lobbyPicturesUploadedByUserHistory: Array<UrlHistory>;
    marketingEmailsEnabled: Scalars["Boolean"];
    meetingRegion?: Maybe<Scalars["String"]>;
    notesFlags?: Maybe<Scalars["Json"]>;
    onboarding?: Maybe<Scalars["Json"]>;
    organizationPermissions: Array<UserToOrganizationPermission>;
    participantInSessions: Array<Participant>;
    profilePicture?: Maybe<Scalars["String"]>;
    pushNotificationsEnabled: Scalars["Boolean"];
    pushNotificationsMinutes: Scalars["Int"];
    seen?: Maybe<Scalars["Json"]>;
    seenHomeIntercom: Scalars["Boolean"];
    seenTour: Scalars["Boolean"];
    seenWelcomeSession: Scalars["Boolean"];
    tags: Array<SessionTag>;
    timezone: Scalars["String"];
    webhooks: Array<Webhook>;
    workspacePermissions: Array<UserToWorkspacePermission>;
};

export type UserLobbyPictureLinksHistoryArgs = {
    orderBy?: Maybe<CreatedAtOrderByInput>;
};

export type UserLobbyPicturesUploadedByUserHistoryArgs = {
    orderBy?: Maybe<CreatedAtOrderByInput>;
};

export type UserConnectInput = {
    connect: UserWhereUniqueInput;
};

export type UserHubspotEventConfig = {
    createLead?: Maybe<Scalars["Boolean"]>;
};

export type UserHubspotSessionsPropertyMapping = {
    defaultValue?: Maybe<Scalars["String"]>;
    hubspotObjectType: Scalars["String"];
    hubspotProperty: Scalars["String"];
    sessionsProperty: Scalars["String"];
};

export enum UserInviteStatus {
    Active = "ACTIVE",
    Error = "ERROR",
    InviteNotSent = "INVITE_NOT_SENT",
    InviteSending = "INVITE_SENDING",
    InviteSent = "INVITE_SENT",
    LinkAccessed = "LINK_ACCESSED",
}

export type UserOnboardingInput = {
    industry?: Maybe<OnboardingFieldType>;
    meetingTypes?: Maybe<Array<OnboardingFieldType>>;
    planning?: Maybe<OnboardingFieldType>;
    role?: Maybe<OnboardingFieldType>;
    termsAccepted?: Maybe<Scalars["Boolean"]>;
};

export type UserPaddleTransaction = {
    __typename?: "UserPaddleTransaction";
    amount?: Maybe<Scalars["String"]>;
    checkoutId?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["String"]>;
    currency?: Maybe<Scalars["String"]>;
    orderId?: Maybe<Scalars["String"]>;
    productId?: Maybe<Scalars["Int"]>;
    receiptUrl?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["String"]>;
    subscriptionId?: Maybe<Scalars["String"]>;
    subscriptionStatus?: Maybe<Scalars["String"]>;
};

export type UserSessionsResult = {
    __typename?: "UserSessionsResult";
    hasSearchTerm?: Maybe<Scalars["Boolean"]>;
    sessions?: Maybe<Array<Session>>;
    totalCount: Scalars["Int"];
};

export type UserTagNameInput = {
    name?: Maybe<Scalars["String"]>;
};

export type UserTagUpdateInput = {
    delete?: Maybe<WhereIdInput>;
    upsert?: Maybe<UserTagUpsertInput>;
};

export type UserTagUpsertInput = {
    create: UserTagNameInput;
    update: UserTagNameInput;
    where: WhereIdInput;
};

export type UserToOrganizationPermission = {
    __typename?: "UserToOrganizationPermission";
    activeOrganization: Scalars["Boolean"];
    id: Scalars["ID"];
    inviteStatus: UserInviteStatus;
    organization: Organization;
    organizationId: Scalars["String"];
    permissionType: OrganizationPermissionType;
    suspended: Scalars["Boolean"];
};

export type UserToOrganizationPermissionListRelationFilter = {
    every?: Maybe<UserToOrganizationPermissionWhereInput>;
    none?: Maybe<UserToOrganizationPermissionWhereInput>;
    some?: Maybe<UserToOrganizationPermissionWhereInput>;
};

export type UserToOrganizationPermissionWhereInput = {
    AND?: Maybe<Array<UserToOrganizationPermissionWhereInput>>;
    NOT?: Maybe<Array<UserToOrganizationPermissionWhereInput>>;
    OR?: Maybe<Array<UserToOrganizationPermissionWhereInput>>;
    id?: Maybe<StringFilter>;
    organization?: Maybe<OrganizationWhereInput>;
    organizationId?: Maybe<StringFilter>;
    permissionType?: Maybe<OrganizationPermissionType>;
    user?: Maybe<UserWhereUniqueInput>;
    userId?: Maybe<StringFilter>;
};

export type UserToWorkspacePermission = {
    __typename?: "UserToWorkspacePermission";
    activeWorkspace: Scalars["Boolean"];
    id: Scalars["ID"];
    inviteStatus: UserInviteStatus;
    role: WorkspaceRole;
    workspace: Workspace;
};

export type UserUpdateInput = {
    company?: Maybe<Scalars["String"]>;
    country?: Maybe<Scalars["String"]>;
    defaultBookingCalendars?: Maybe<Scalars["Json"]>;
    department?: Maybe<Scalars["String"]>;
    emailNotificationsEnabled?: Maybe<Scalars["Boolean"]>;
    emailNotificationsMinutes?: Maybe<Scalars["Int"]>;
    externalCalendarsSettings?: Maybe<Scalars["Json"]>;
    firstDayOfWeek?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    forms?: Maybe<Scalars["Json"]>;
    halfDayClock?: Maybe<Scalars["Boolean"]>;
    industry?: Maybe<Scalars["String"]>;
    jobRole?: Maybe<Scalars["String"]>;
    language?: Maybe<Scalars["String"]>;
    lastLoginDate?: Maybe<Scalars["DateTime"]>;
    lastName?: Maybe<Scalars["String"]>;
    lastVersionSeen?: Maybe<Scalars["Int"]>;
    meetingRegion?: Maybe<Scalars["String"]>;
    notesFlags?: Maybe<Scalars["Json"]>;
    pushNotificationsEnabled?: Maybe<Scalars["Boolean"]>;
    pushNotificationsMinutes?: Maybe<Scalars["Int"]>;
    seen?: Maybe<Scalars["Json"]>;
    seenHomeIntercom?: Maybe<Scalars["Boolean"]>;
    seenTour?: Maybe<Scalars["Boolean"]>;
    seenWelcomeSession?: Maybe<Scalars["Boolean"]>;
    tags?: Maybe<UserTagUpdateInput>;
    timezone?: Maybe<Scalars["String"]>;
};

export type UserUpdatesPayload = {
    __typename?: "UserUpdatesPayload";
    agendaTemplates?: Maybe<Array<AgendaTemplate>>;
    id: Scalars["String"];
    resource?: Maybe<Resource>;
};

export type UserWhereInput = {
    email?: Maybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
    email?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["String"]>;
};

export type UtmCookies = {
    utmCampaign?: Maybe<Scalars["String"]>;
    utmMedium?: Maybe<Scalars["String"]>;
    utmSource?: Maybe<Scalars["String"]>;
    utmTimestamp?: Maybe<Scalars["String"]>;
    websiteLocation?: Maybe<Scalars["String"]>;
};

export type Version = {
    __typename?: "Version";
    isMismatch: Scalars["Boolean"];
    chunkLoadErr?: Maybe<Scalars["Boolean"]>;
};

export type WaitForApprovalInSessionResult = {
    __typename?: "WaitForApprovalInSessionResult";
    hasBeenApproved: Scalars["Boolean"];
    participantId?: Maybe<Scalars["String"]>;
};

export type Webhook = {
    __typename?: "Webhook";
    createdAt: Scalars["String"];
    headers: Array<WebhookHeader>;
    id: Scalars["String"];
    integration?: Maybe<PublicApiIntegration>;
    permissionType: WebhookPermissionType;
    trigger: WebhookTrigger;
    updatedAt: Scalars["String"];
    url: Scalars["String"];
    userId?: Maybe<Scalars["String"]>;
    workspaceId?: Maybe<Scalars["String"]>;
};

export type WebhookHeader = {
    __typename?: "WebhookHeader";
    key: Scalars["String"];
    value: Scalars["String"];
};

export type WebhookHeaderInput = {
    key: Scalars["String"];
    value: Scalars["String"];
};

export enum WebhookPermissionType {
    Personal = "PERSONAL",
    Workspace = "WORKSPACE",
}

export enum WebhookTrigger {
    BookingCreated = "BOOKING_CREATED",
    BookingEnded = "BOOKING_ENDED",
    BookingStarted = "BOOKING_STARTED",
    BookingUpdated = "BOOKING_UPDATED",
    EventCreated = "EVENT_CREATED",
    EventEnded = "EVENT_ENDED",
    EventNewRegistration = "EVENT_NEW_REGISTRATION",
    EventPublished = "EVENT_PUBLISHED",
    EventStarted = "EVENT_STARTED",
    EventUpdated = "EVENT_UPDATED",
    RecordingStatusChanged = "RECORDING_STATUS_CHANGED",
    SessionCreated = "SESSION_CREATED",
    SessionEnded = "SESSION_ENDED",
    SessionStarted = "SESSION_STARTED",
    SessionUpdated = "SESSION_UPDATED",
    SummaryReady = "SUMMARY_READY",
    TakeawayReady = "TAKEAWAY_READY",
    TranscriptReady = "TRANSCRIPT_READY",
}

export type WebsiteDetails = {
    __typename?: "WebsiteDetails";
    description?: Maybe<Scalars["String"]>;
    icon?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
};

export enum WeekDay {
    Fr = "FR",
    Mo = "MO",
    Sa = "SA",
    Su = "SU",
    Th = "TH",
    Tu = "TU",
    We = "WE",
}

export type WhereIdInput = {
    id: Scalars["String"];
};

export type WhereIdNullableInput = {
    id?: Maybe<Scalars["String"]>;
};

export type Workspace = {
    __typename?: "Workspace";
    branding?: Maybe<Branding>;
    id: Scalars["ID"];
    members: Array<User>;
    membersCount: Scalars["Int"];
    name: Scalars["String"];
    organizationId: Scalars["String"];
    roles: Array<WorkspaceRole>;
    slug?: Maybe<Scalars["String"]>;
    webhooks: Array<Webhook>;
};

export type WorkspaceMembersArgs = {
    excludeSelf?: Maybe<Scalars["Boolean"]>;
};

export enum WorkspacePermission {
    AccessMemberContacts = "ACCESS_MEMBER_CONTACTS",
    AddSmsReminder = "ADD_SMS_REMINDER",
    CreateBookings = "CREATE_BOOKINGS",
    CreateEvents = "CREATE_EVENTS",
    CreateRooms = "CREATE_ROOMS",
    CreateSessions = "CREATE_SESSIONS",
    ListWorkspaceBookings = "LIST_WORKSPACE_BOOKINGS",
    ManageAgendaTemplates = "MANAGE_AGENDA_TEMPLATES",
    ManageBookings = "MANAGE_BOOKINGS",
    ManageBranding = "MANAGE_BRANDING",
    ManageCustomSubdomain = "MANAGE_CUSTOM_SUBDOMAIN",
    ManageEvents = "MANAGE_EVENTS",
    ManageMembers = "MANAGE_MEMBERS",
    ManageMyProductTool = "MANAGE_MY_PRODUCT_TOOL",
    ManageResources = "MANAGE_RESOURCES",
    ManageRoles = "MANAGE_ROLES",
    ManageRooms = "MANAGE_ROOMS",
    ReferralAccess = "REFERRAL_ACCESS",
    UseRecording = "USE_RECORDING",
    WebhookAccess = "WEBHOOK_ACCESS",
}

export type WorkspaceRole = {
    __typename?: "WorkspaceRole";
    clientOnly?: Maybe<Scalars["Boolean"]>;
    createdAt: Scalars["DateTime"];
    id: Scalars["ID"];
    name: Scalars["String"];
    permissions: Array<WorkspacePermission>;
};

export type WorkspaceRoleCreateInput = {
    name?: Maybe<Scalars["String"]>;
    permissions?: Maybe<Array<Maybe<WorkspacePermission>>>;
};

export type WorkspaceSenderEmail = {
    __typename?: "WorkspaceSenderEmail";
    customSmtpServerId?: Maybe<Scalars["String"]>;
    externalProviderId?: Maybe<Scalars["String"]>;
    isDefault: Scalars["Boolean"];
    senderEmail: Scalars["String"];
    workspaceSenderEmailType: WorkspaceSenderEmailType;
};

export enum WorkspaceSenderEmailType {
    Google = "GOOGLE",
    Microsoft = "MICROSOFT",
    Smtp = "SMTP",
}

export type GetBreakoutConfigQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetBreakoutConfigQuery = {__typename?: "Query"} & {
    artifact?: Maybe<
        {__typename?: "Artifact"} & {breakoutRoomsConfig?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>}
    >;
};

export type GetRoomInfoQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetRoomInfoQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & Pick<Session, "id"> & {
                questionsContainer?: Maybe<
                    {__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id"> & {
                            questions: Array<{__typename?: "Question"} & Pick<Question, "id" | "markedAsAnswered">>;
                        }
                >;
                participants: Array<
                    {__typename?: "Participant"} & Pick<Participant, "id" | "status" | "isOwner" | "rbac" | "conferenceStatus">
                >;
            }
    >;
};

export type UpdateBreakoutConfigMutationVariables = Exact<{
    artifactId: Scalars["String"];
    deleteBreakoutRoomIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    upsertBreakoutRooms?: Maybe<Array<UpsertBreakoutRoomInput> | UpsertBreakoutRoomInput>;
    mainBreakoutRoom?: Maybe<UpdateMainBreakoutRoom>;
    allowRoomNavigation?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateBreakoutConfigMutation = {__typename?: "Mutation"} & {
    updateBreakoutRoomsConfig: {__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment;
};

export type GetChatMessagesQueryVariables = Exact<{
    sessionId: Scalars["String"];
    take: Scalars["Int"];
    before?: Maybe<Scalars["String"]>;
    skipFiles: Scalars["Boolean"];
    chatGroupId?: Maybe<Scalars["String"]>;
}>;

export type GetChatMessagesQuery = {__typename?: "Query"} & {
    chatMessages: Array<{__typename?: "ChatMessage"} & OneChatMessageFragment>;
    messageFiles?: Maybe<Array<{__typename?: "MessageFile"} & OneChatFileFragment>>;
};

export type GetChatGroupsQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetChatGroupsQuery = {__typename?: "Query"} & {chatGroups: Array<{__typename?: "ChatGroup"} & OneChatGroupFragment>};

export type GetUnreadSessionChatCountQueryVariables = Exact<{[key: string]: never}>;

export type GetUnreadSessionChatCountQuery = {__typename?: "Query"} & Pick<Query, "unreadSessionChatCount">;

export type GiphySearchQueryVariables = Exact<{
    offset: Scalars["Int"];
    limit?: Maybe<Scalars["Int"]>;
    type?: Maybe<GiphyMediaType>;
    query?: Maybe<Scalars["String"]>;
}>;

export type GiphySearchQuery = {__typename?: "Query"} & Pick<Query, "giphySearch">;

export type SendChatMessageMutationVariables = Exact<{
    sessionId: Scalars["String"];
    chatGroupId?: Maybe<Scalars["String"]>;
    body: Scalars["String"];
    fileId?: Maybe<Scalars["String"]>;
    replyToMessageId?: Maybe<Scalars["String"]>;
    sendToAllBreakoutRooms?: Maybe<Scalars["Boolean"]>;
    participantIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type SendChatMessageMutation = {__typename?: "Mutation"} & {sendChatMessage: {__typename?: "ChatMessage"} & Pick<ChatMessage, "id">};

export type UploadFileForChatMessageMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type UploadFileForChatMessageMutation = {__typename?: "Mutation"} & {
    uploadFileForChatMessage?: Maybe<{__typename?: "UploadAnonymousFileResult"} & Pick<UploadAnonymousFileResult, "uuid" | "uploadUrl">>;
};

export type ConfirmUploadFileForChatMessageMutationVariables = Exact<{
    uuid: Scalars["String"];
    sessionId: Scalars["String"];
    name: Scalars["String"];
    type: Scalars["String"];
    size: Scalars["Int"];
}>;

export type ConfirmUploadFileForChatMessageMutation = {__typename?: "Mutation"} & {
    confirmUploadFileForChatMessage?: Maybe<{__typename?: "MessageFile"} & OneChatFileFragment>;
};

export type DeleteOneMessageFileMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneMessageFileMutation = {__typename?: "Mutation"} & {
    deleteOneMessageFile?: Maybe<{__typename?: "MessageFile"} & Pick<MessageFile, "id">>;
};

export type DeleteOneChatMessageMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneChatMessageMutation = {__typename?: "Mutation"} & {
    deleteOneChatMessage?: Maybe<{__typename?: "ChatMessage"} & Pick<ChatMessage, "id">>;
};

export type OneChatMessageFragment = {__typename?: "ChatMessage"} & Pick<
    ChatMessage,
    "id" | "body" | "globalMessage" | "createdAt" | "chatmessageId" | "updatedAt" | "participantId" | "chatGroupId"
> & {replyToMessage?: Maybe<{__typename?: "ChatMessage"} & Pick<ChatMessage, "id" | "body" | "participantId" | "createdAt">>};

export type OneChatFileFragment = {__typename?: "MessageFile"} & Pick<
    MessageFile,
    "id" | "name" | "type" | "size" | "createdAt" | "downloadLink" | "chatmessageId" | "participantId"
>;

export type OneChatGroupFragment = {__typename?: "ChatGroup"} & {
    messages: Array<{__typename?: "ChatMessage"} & OneChatMessageFragment>;
} & OneChatGroupWithoutMessagesFragment;

export type OneChatGroupWithoutMessagesFragment = {__typename?: "ChatGroup"} & Pick<
    ChatGroup,
    "id" | "clientOnly" | "unreadCount" | "fetchedMessagesOverNetwork"
> & {participants: Array<{__typename?: "Participant"} & Pick<Participant, "id">>};

export type SetParticipantConferenceStatusMutationVariables = Exact<{
    participantId: Scalars["String"];
    newStatus: ConferenceParticipantStatus;
}>;

export type SetParticipantConferenceStatusMutation = {__typename?: "Mutation"} & {
    updateParticipantConferenceStatus?: Maybe<{__typename?: "Participant"} & Pick<Participant, "id">>;
};

export type KickParticipantFromSessionMutationVariables = Exact<{
    participantId: Scalars["String"];
    ban: Scalars["Boolean"];
    session: WhereIdInput;
}>;

export type KickParticipantFromSessionMutation = {__typename?: "Mutation"} & Pick<Mutation, "kickFromSession">;

export type AskParticipantMutationVariables = Exact<{
    participantId: Scalars["String"];
    askToUnmute?: Maybe<Scalars["Boolean"]>;
    askToStartCamera?: Maybe<Scalars["Boolean"]>;
}>;

export type AskParticipantMutation = {__typename?: "Mutation"} & Pick<Mutation, "askParticipant">;

export type AnswerParticipantMutationVariables = Exact<{
    sessionId: Scalars["String"];
    askToUnmute?: Maybe<Scalars["Boolean"]>;
    askToStartCamera?: Maybe<Scalars["Boolean"]>;
}>;

export type AnswerParticipantMutation = {__typename?: "Mutation"} & Pick<Mutation, "answerParticipant">;

export type SaveContextualNoteMutationVariables = Exact<{
    session: WhereIdInput;
    noteJson: Scalars["Json"];
}>;

export type SaveContextualNoteMutation = {__typename?: "Mutation"} & {
    saveContextualNote?: Maybe<{__typename?: "Participant"} & Pick<Participant, "id" | "contextualNoteJson">>;
};

export type SendContextualNoteMutationVariables = Exact<{
    sessionId: Scalars["String"];
    sendEmail?: Maybe<Scalars["Boolean"]>;
    sendToEvernote?: Maybe<Scalars["Boolean"]>;
    sendToNotion?: Maybe<Scalars["Boolean"]>;
}>;

export type SendContextualNoteMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendContextualNote">;

export type UpdateOneGuestMutationVariables = Exact<{
    data?: Maybe<GuestUpdateInput>;
}>;

export type UpdateOneGuestMutation = {__typename?: "Mutation"} & Pick<Mutation, "updateOneGuest">;

export type GetNotesSyncInfoQueryVariables = Exact<{[key: string]: never}>;

export type GetNotesSyncInfoQuery = {__typename?: "Query"} & Pick<Query, "isNotionSynced" | "isEvernoteSynced"> & {
        getNotionParentPages?: Maybe<Array<{__typename?: "NotionParentPage"} & NotionParentPageFragment>>;
    };

export type HaveIAnsweredFormQueryVariables = Exact<{
    artifactId: Scalars["String"];
    resourceResultId: Scalars["String"];
}>;

export type HaveIAnsweredFormQuery = {__typename?: "Query"} & Pick<Query, "hasParticipantAnsweredForm">;

export type FormParticipantsCanSeeResultsMutationVariables = Exact<{
    artifactId: Scalars["String"];
    mode: Scalars["String"];
}>;

export type FormParticipantsCanSeeResultsMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<{__typename?: "Artifact"} & Pick<Artifact, "id">>;
};

export type FormOwnerCanAnswerMutationVariables = Exact<{
    artifactId: Scalars["String"];
    mode: Scalars["String"];
}>;

export type FormOwnerCanAnswerMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<{__typename?: "Artifact"} & Pick<Artifact, "id">>;
};

export type FormToggleAnonymityMutationVariables = Exact<{
    artifactId: Scalars["String"];
    mode: Scalars["String"];
}>;

export type FormToggleAnonymityMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<{__typename?: "Artifact"} & Pick<Artifact, "id">>;
};

export type GetGoogleAuthUrlQueryVariables = Exact<{[key: string]: never}>;

export type GetGoogleAuthUrlQuery = {__typename?: "Query"} & Pick<Query, "getGoogleAuthUrl">;

export type GetGoogleFilesQueryVariables = Exact<{
    fileName?: Maybe<Scalars["String"]>;
    fileType?: Maybe<GoogleFileType>;
    nextPageToken?: Maybe<Scalars["String"]>;
}>;

export type GetGoogleFilesQuery = {__typename?: "Query"} & Pick<Query, "getGoogleFiles">;

export type GetGoogleFileQueryVariables = Exact<{
    fileId: Scalars["String"];
}>;

export type GetGoogleFileQuery = {__typename?: "Query"} & Pick<Query, "getGoogleFile">;

export type ChangeGoogleFilePermissionMutationVariables = Exact<{
    fileId: Scalars["String"];
    role: Scalars["String"];
}>;

export type ChangeGoogleFilePermissionMutation = {__typename?: "Mutation"} & Pick<Mutation, "changeGoogleFilePermission">;

export type ChangeGoogleFilesPermissionMutationVariables = Exact<{
    fileIds: Array<Scalars["String"]> | Scalars["String"];
    role: Scalars["String"];
}>;

export type ChangeGoogleFilesPermissionMutation = {__typename?: "Mutation"} & Pick<Mutation, "changeGoogleFilesPermission">;

export type CreateGoogleFileMutationVariables = Exact<{
    name: Scalars["String"];
    type: Scalars["String"];
}>;

export type CreateGoogleFileMutation = {__typename?: "Mutation"} & Pick<Mutation, "createGoogleFile">;

export type DeleteGoogleFilesMutationVariables = Exact<{
    fileIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DeleteGoogleFilesMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteGoogleFiles">;

export type RenameGoogleFileMutationVariables = Exact<{
    fileId: Scalars["String"];
    name: Scalars["String"];
}>;

export type RenameGoogleFileMutation = {__typename?: "Mutation"} & Pick<Mutation, "renameGoogleFile">;

export type CheckGoogleFileInUseMutationVariables = Exact<{
    fileId: Scalars["String"];
}>;

export type CheckGoogleFileInUseMutation = {__typename?: "Mutation"} & {
    checkGoogleFileInUse: Array<
        {__typename?: "Session"} & Pick<Session, "id" | "name" | "startAt" | "plannedEnd" | "occurrenceId"> & {
                recurrenceData?: Maybe<{__typename?: "RecurrenceData"} & DesignerSessionRecurrenceDataFragment>;
            }
    >;
};

export type CheckUrlQueryVariables = Exact<{
    url: Scalars["String"];
}>;

export type CheckUrlQuery = {__typename?: "Query"} & {
    iframeCheckUrl?: Maybe<
        {__typename?: "IframeCheckUrlPayload"} & Pick<
            IframeCheckUrlPayload,
            "isFrameable" | "reason" | "contentType" | "fileName" | "fileSize"
        >
    >;
};

export type GetWebsiteTitleQueryVariables = Exact<{
    url: Scalars["String"];
}>;

export type GetWebsiteTitleQuery = {__typename?: "Query"} & Pick<Query, "getWebsiteTitle">;

export type GetWebsiteDetailsQueryVariables = Exact<{
    url: Scalars["String"];
    icon?: Maybe<Scalars["Boolean"]>;
    idealIconSize?: Maybe<Scalars["Int"]>;
    titleFromOG?: Maybe<Scalars["Boolean"]>;
}>;

export type GetWebsiteDetailsQuery = {__typename?: "Query"} & {
    getWebsiteDetails?: Maybe<{__typename?: "WebsiteDetails"} & Pick<WebsiteDetails, "title" | "description" | "icon">>;
};

export type GetPspdfkitTokenQueryVariables = Exact<{
    documentId: Scalars["String"];
    layer?: Maybe<Scalars["String"]>;
}>;

export type GetPspdfkitTokenQuery = {__typename?: "Query"} & Pick<Query, "getPspdfkitToken">;

export type CreatePspdfkitDocumentMutationVariables = Exact<{
    documentUrl: Scalars["String"];
}>;

export type CreatePspdfkitDocumentMutation = {__typename?: "Mutation"} & {
    createPspdfkitDocument?: Maybe<{__typename?: "PspdfKitDocumentResponse"} & Pick<PspdfKitDocumentResponse, "documentId">>;
};

export type CreateOnePollMutationVariables = Exact<{
    data: PollCreateInput;
}>;

export type CreateOnePollMutation = {__typename?: "Mutation"} & {createOnePoll?: Maybe<{__typename?: "Poll"} & Pick<Poll, "id">>};

export type DeleteOnePollMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOnePollMutation = {__typename?: "Mutation"} & {deleteOnePoll?: Maybe<{__typename?: "Poll"} & Pick<Poll, "id">>};

export type UpdateOnePollMutationVariables = Exact<{
    data: PollUpdateInput;
    where: WhereIdInput;
}>;

export type UpdateOnePollMutation = {__typename?: "Mutation"} & {updateOnePoll?: Maybe<{__typename?: "Poll"} & Pick<Poll, "id">>};

export type SubmitPollAnswersMutationVariables = Exact<{
    pollId: Scalars["String"];
    chosenOptionIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    textAnswer?: Maybe<Scalars["String"]>;
}>;

export type SubmitPollAnswersMutation = {__typename?: "Mutation"} & Pick<Mutation, "submitPollAnswers">;

export type PollUpdatesForOwnerSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type PollUpdatesForOwnerSubscription = {__typename?: "Subscription"} & {
    pollUpdatesForOwners?: Maybe<
        {__typename?: "PollSubscriptionOwnerPayload"} & {
            allPollsOnSession?: Maybe<
                {__typename?: "Session"} & Pick<Session, "id"> & {
                        polls: Array<
                            {__typename?: "Poll"} & Pick<
                                Poll,
                                | "id"
                                | "questionBody"
                                | "active"
                                | "completed"
                                | "pollType"
                                | "createdAt"
                                | "makeResultsAnonymous"
                                | "showResultsToParticipants"
                                | "numberOfUniqueVotes"
                            > & {
                                    options: Array<
                                        {__typename?: "PollOption"} & Pick<
                                            PollOption,
                                            "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
                                        >
                                    >;
                                    textAnswers: Array<
                                        {__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">
                                    >;
                                }
                        >;
                    }
            >;
            individualPolls?: Maybe<
                Array<
                    {__typename?: "Poll"} & Pick<
                        Poll,
                        | "id"
                        | "questionBody"
                        | "active"
                        | "completed"
                        | "pollType"
                        | "createdAt"
                        | "makeResultsAnonymous"
                        | "showResultsToParticipants"
                        | "numberOfUniqueVotes"
                    > & {
                            options: Array<
                                {__typename?: "PollOption"} & Pick<
                                    PollOption,
                                    "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
                                >
                            >;
                            textAnswers: Array<{__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">>;
                        }
                >
            >;
            individualPollOptions?: Maybe<
                Array<
                    {__typename?: "PollOption"} & Pick<
                        PollOption,
                        "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
                    >
                >
            >;
            individualPollTextAnswers?: Maybe<
                Array<
                    {__typename?: "Poll"} & Pick<Poll, "id"> & {
                            textAnswers: Array<{__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">>;
                        }
                >
            >;
            pollUniqueVotes?: Maybe<{__typename?: "Poll"} & Pick<Poll, "id" | "numberOfUniqueVotes">>;
        }
    >;
};

export type PollUpdatesForParticipantSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type PollUpdatesForParticipantSubscription = {__typename?: "Subscription"} & {
    pollUpdatesForParticipants?: Maybe<
        {__typename?: "PollSubscriptionParticipantPayload"} & {
            allPollsOnSession?: Maybe<
                {__typename?: "Session"} & Pick<Session, "id"> & {
                        polls: Array<
                            {__typename?: "Poll"} & Pick<
                                Poll,
                                | "id"
                                | "questionBody"
                                | "active"
                                | "completed"
                                | "pollType"
                                | "createdAt"
                                | "makeResultsAnonymous"
                                | "showResultsToParticipants"
                                | "numberOfUniqueVotes"
                            > & {
                                    options: Array<
                                        {__typename?: "PollOption"} & Pick<
                                            PollOption,
                                            "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
                                        >
                                    >;
                                    textAnswers: Array<
                                        {__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">
                                    >;
                                }
                        >;
                    }
            >;
            individualPolls?: Maybe<
                Array<
                    {__typename?: "Poll"} & Pick<
                        Poll,
                        | "id"
                        | "questionBody"
                        | "active"
                        | "completed"
                        | "pollType"
                        | "createdAt"
                        | "makeResultsAnonymous"
                        | "showResultsToParticipants"
                        | "numberOfUniqueVotes"
                    > & {
                            options: Array<
                                {__typename?: "PollOption"} & Pick<
                                    PollOption,
                                    "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
                                >
                            >;
                            textAnswers: Array<{__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">>;
                        }
                >
            >;
            individualPollOptions?: Maybe<
                Array<
                    {__typename?: "PollOption"} & Pick<
                        PollOption,
                        "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
                    >
                >
            >;
            individualPollTextAnswers?: Maybe<
                Array<
                    {__typename?: "Poll"} & Pick<Poll, "id"> & {
                            textAnswers: Array<{__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">>;
                        }
                >
            >;
            pollUniqueVotes?: Maybe<{__typename?: "Poll"} & Pick<Poll, "id" | "numberOfUniqueVotes">>;
        }
    >;
};

export type SessionPollsForQueryFragment = {__typename?: "Session"} & {
    polls: Array<{__typename?: "Poll"} & Pick<Poll, "resultsSubmitted"> & OnePollFragment>;
};

export type OnePollFragment = {__typename?: "Poll"} & Pick<
    Poll,
    | "id"
    | "questionBody"
    | "active"
    | "completed"
    | "pollType"
    | "createdAt"
    | "makeResultsAnonymous"
    | "showResultsToParticipants"
    | "numberOfUniqueVotes"
> & {
        options: Array<{__typename?: "PollOption"} & OnePollOptionFragment>;
        textAnswers: Array<{__typename?: "PollTextAnswer"} & OnePollTextAnswerFragment>;
    };

export type OnePollOptionFragment = {__typename?: "PollOption"} & Pick<
    PollOption,
    "id" | "optionBody" | "submittedByOwner" | "createdAt" | "participantIds" | "numberOfVotes"
>;

export type OnePollTextAnswerFragment = {__typename?: "PollTextAnswer"} & Pick<PollTextAnswer, "id" | "text" | "participantId">;

export type OneQuestionFragment = {__typename?: "Question"} & Pick<
    Question,
    "id" | "text" | "createdAt" | "updatedAt" | "markedAsAnswered" | "upVotes" | "upVotedByUser" | "isAnonymous" | "participantId"
>;

export type QuestionParticipantFragment = {__typename?: "Participant"} & Pick<Participant, "id" | "status"> & {
        dataWithNullableEmail: {__typename?: "ParticipantDataWithNullableEmail"} & Pick<
            ParticipantDataWithNullableEmail,
            "firstName" | "lastName" | "email" | "isGuest" | "avatar" | "token" | "userId"
        >;
    };

export type GetSessionQuestionsQueryVariables = Exact<{
    containerId: Scalars["String"];
}>;

export type GetSessionQuestionsQuery = {__typename?: "Query"} & {
    questionsContainer?: Maybe<
        {__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id"> & {
                questions: Array<{__typename?: "Question"} & OneQuestionFragment>;
            }
    >;
};

export type GetSessionQuestionsCountQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetSessionQuestionsCountQuery = {__typename?: "Query"} & Pick<Query, "getSessionQuestionsCount">;

export type GetNewQuestionsCountQueryVariables = Exact<{[key: string]: never}>;

export type GetNewQuestionsCountQuery = {__typename?: "Query"} & {
    newQuestionsCount: {__typename?: "NewQuestionsCount"} & Pick<NewQuestionsCount, "count">;
};

export type GetQuestionsFromSessionQueryVariables = Exact<{
    session: WhereIdInput;
}>;

export type GetQuestionsFromSessionQuery = {__typename?: "Query"} & {
    getQuestionsFromSession: Array<{__typename?: "Question"} & OneQuestionFragment>;
};

export type CreateQuestionMutationVariables = Exact<{
    containerId: Scalars["String"];
    text: Scalars["String"];
}>;

export type CreateQuestionMutation = {__typename?: "Mutation"} & {
    createOneQuestion?: Maybe<{__typename?: "Question"} & Pick<Question, "id">>;
};

export type ToggleQuestionAsAnsweredMutationVariables = Exact<{
    question: WhereIdInput;
    value: Scalars["Boolean"];
}>;

export type ToggleQuestionAsAnsweredMutation = {__typename?: "Mutation"} & {
    toggleQuestionAsAnswered: {__typename?: "Question"} & OneQuestionFragment;
};

export type MarkQuestionAsAnsweringMutationVariables = Exact<{
    questionBeingAnsweredId: Scalars["String"];
    questionsContainerId: Scalars["String"];
}>;

export type MarkQuestionAsAnsweringMutation = {__typename?: "Mutation"} & {
    markQuestionAsAnswering?: Maybe<{__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id">>;
};

export type StopAnsweringQuestionsMutationVariables = Exact<{
    questionsContainerId: Scalars["String"];
}>;

export type StopAnsweringQuestionsMutation = {__typename?: "Mutation"} & {
    stopAnsweringQuestions?: Maybe<{__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id">>;
};

export type DeleteOneQuestionMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneQuestionMutation = {__typename?: "Mutation"} & {
    deleteOneQuestion?: Maybe<{__typename?: "Question"} & Pick<Question, "id">>;
};

export type QaTogglePublicPrivateMutationVariables = Exact<{
    artifactId: Scalars["String"];
    properties: Array<PropertyCreateCustomInput> | PropertyCreateCustomInput;
}>;

export type QaTogglePublicPrivateMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<{__typename?: "Artifact"} & OneArtifactFragment>;
};

export type QaToggleUpVoteMutationVariables = Exact<{
    question: WhereIdInput;
}>;

export type QaToggleUpVoteMutation = {__typename?: "Mutation"} & {toggleQuestionUpVote: {__typename?: "Question"} & OneQuestionFragment};

export type QaUpdateSubscriptionVariables = Exact<{
    containerId: Scalars["String"];
}>;

export type QaUpdateSubscription = {__typename?: "Subscription"} & {
    questionsContainerUpdate?: Maybe<
        {__typename?: "QaQuestionsContainerUpdatePayload"} & {question?: Maybe<{__typename?: "Question"} & OneQuestionFragment>}
    >;
};

export type QuestionsBeingAnsweredSubscriptionVariables = Exact<{
    containerId: Scalars["String"];
}>;

export type QuestionsBeingAnsweredSubscription = {__typename?: "Subscription"} & {
    questionsBeingAnswered?: Maybe<{__typename?: "Question"} & OneQuestionFragment>;
};

export type QuestionAnsweredForParticipantSubscriptionVariables = Exact<{
    containerId: Scalars["String"];
}>;

export type QuestionAnsweredForParticipantSubscription = {__typename?: "Subscription"} & {
    questionAnsweredForParticipant?: Maybe<{__typename?: "Question"} & OneQuestionFragment>;
};

export type GetSlidoUrlDetailsQueryVariables = Exact<{
    url: Scalars["String"];
}>;

export type GetSlidoUrlDetailsQuery = {__typename?: "Query"} & {
    getSlidoUrlDetails?: Maybe<{__typename?: "SlidoUrlDetails"} & Pick<SlidoUrlDetails, "title">>;
};

export type GetEditorTokenQueryVariables = Exact<{
    artifactId: Scalars["String"];
    readOnly?: Maybe<Scalars["Boolean"]>;
}>;

export type GetEditorTokenQuery = {__typename?: "Query"} & Pick<Query, "getEditorToken">;

export type GetTranscriptionsQueryVariables = Exact<{
    sessionId: Scalars["String"];
    take: Scalars["Int"];
    before?: Maybe<Scalars["String"]>;
    preferredLanguage?: Maybe<Scalars["String"]>;
    participantId: Scalars["String"];
    skipPinned: Scalars["Boolean"];
}>;

export type GetTranscriptionsQuery = {__typename?: "Query"} & {
    transcripts: Array<{__typename?: "SessionTranscript"} & OneTranscriptMessageFragment>;
    participants?: Maybe<
        Array<
            {__typename?: "Participant"} & {pinnedTranscriptions: Array<{__typename?: "SessionTranscript"} & OneTranscriptMessageFragment>}
        >
    >;
};

export type GetTranslatedTranscriptionQueryVariables = Exact<{
    transcriptId: Scalars["String"];
    language: Scalars["String"];
}>;

export type GetTranslatedTranscriptionQuery = {__typename?: "Query"} & {
    transcriptContentTranslation?: Maybe<
        {__typename?: "SessionTranscriptContent"} & Pick<SessionTranscriptContent, "id" | "text" | "language">
    >;
};

export type GetSummaryTranslationQueryVariables = Exact<{
    summary: Scalars["String"];
    language: Scalars["String"];
}>;

export type GetSummaryTranslationQuery = {__typename?: "Query"} & Pick<Query, "summaryTranslation">;

export type SearchSessionTranscriptsQueryVariables = Exact<{
    sessionId: Scalars["String"];
    query: Scalars["String"];
}>;

export type SearchSessionTranscriptsQuery = {__typename?: "Query"} & {
    searchSessionTranscripts: Array<
        {__typename?: "SessionTranscriptContent"} & Pick<
            SessionTranscriptContent,
            "id" | "text" | "language" | "isOriginal" | "transcriptionId"
        >
    >;
};

export type GetTranscriptFileQueryVariables = Exact<{
    sessionId: Scalars["String"];
    format: Scalars["String"];
    timezoneOffset: Scalars["Int"];
    preferredLanguage?: Maybe<Scalars["String"]>;
}>;

export type GetTranscriptFileQuery = {__typename?: "Query"} & {
    transcriptFile: {__typename?: "TranscriptFile"} & Pick<TranscriptFile, "id" | "path" | "url" | "createdAt">;
};

export type TogglePinTranscriptionMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type TogglePinTranscriptionMutation = {__typename?: "Mutation"} & {
    toggleTranscriptionPin: {__typename?: "SessionTranscript"} & Pick<SessionTranscript, "id">;
};

export type OneTranscriptMessageFragment = {__typename?: "SessionTranscript"} & Pick<
    SessionTranscript,
    "id" | "sessionId" | "participantId" | "sourceTimestamp" | "sourceLanguage"
> & {content: Array<{__typename?: "SessionTranscriptContent"} & Pick<SessionTranscriptContent, "id" | "text" | "language">>};

export type GetTwitchUrlDetailsQueryVariables = Exact<{
    url: Scalars["String"];
}>;

export type GetTwitchUrlDetailsQuery = {__typename?: "Query"} & {
    getTwitchUrlDetails?: Maybe<{__typename?: "TwitchUrlDetails"} & Pick<TwitchUrlDetails, "type" | "title">>;
};

export type AccessListItemInfoFragment = {__typename?: "AccessListItem"} & Pick<
    AccessListItem,
    "id" | "agendatemplateId" | "resourceId"
> & {user: {__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName" | "profilePicture" | "email">};

export type AccessListItemsQueryVariables = Exact<{
    where?: Maybe<AccessListItemWhereInput>;
}>;

export type AccessListItemsQuery = {__typename?: "Query"} & {
    accessListItems: Array<{__typename?: "AccessListItem"} & AccessListItemInfoFragment>;
};

export type CreateOneAccessListItemMutationVariables = Exact<{
    data: AccessListItemCreateInput;
}>;

export type CreateOneAccessListItemMutation = {__typename?: "Mutation"} & {
    createOneAccessListItem?: Maybe<{__typename?: "AccessListItem"} & AccessListItemInfoFragment>;
};

export type DeleteOneAccessListItemMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneAccessListItemMutation = {__typename?: "Mutation"} & {
    deleteOneAccessListItem?: Maybe<{__typename?: "AccessListItem"} & Pick<AccessListItem, "id">>;
};

export type BookingEventLitItemFragment = {__typename?: "BookingEvent"} & Pick<
    BookingEvent,
    | "id"
    | "workspaceId"
    | "name"
    | "disabled"
    | "isListed"
    | "duration"
    | "additionalDuration"
    | "scheduleSource"
    | "seen"
    | "bookingsCount"
    | "slug"
> & {
        schedule?: Maybe<{__typename?: "BookingSchedule"} & BookingScheduleFragment>;
        temporarySchedule?: Maybe<{__typename?: "BookingSchedule"} & BookingScheduleFragment>;
        agenda?: Maybe<{__typename?: "AgendaTemplate"} & Pick<AgendaTemplate, "id" | "name">>;
        owner: {__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName" | "profilePicture">;
        collaborators: Array<{__typename?: "BookingCollaborator"} & BookingCollaboratorFragment>;
        payments: Array<{__typename?: "PaymentConfig"} & PaymentConfigFragment>;
    };

export type BookingScheduleFragment = {__typename?: "BookingSchedule"} & Pick<
    BookingSchedule,
    "id" | "name" | "default" | "timeZone" | "hours" | "days" | "maxDuration" | "temporary"
> & {usedBy?: Maybe<Array<{__typename?: "BookingScheduleUsedBy"} & Pick<BookingScheduleUsedBy, "eventId" | "eventName">>>};

export type BookingCollaboratorScheduleFragment = {__typename?: "BookingSchedule"} & Pick<
    BookingSchedule,
    "id" | "name" | "timeZone" | "hours" | "days" | "maxDuration" | "temporary"
> & {usedBy?: Maybe<Array<{__typename?: "BookingScheduleUsedBy"} & Pick<BookingScheduleUsedBy, "eventId" | "eventName">>>};

export type BookingEventFragment = {__typename?: "BookingEvent"} & Pick<
    BookingEvent,
    | "description"
    | "additionalDuration"
    | "from"
    | "to"
    | "scheduleWindow"
    | "questionnare"
    | "settings"
    | "timeZone"
    | "buffer"
    | "additionalRules"
    | "slug"
    | "ignoreSharedCalendars"
    | "collaborationDistribution"
    | "allowAdditionalGuests"
    | "afterRegistrationRedirectUrl"
    | "afterRegistrationOfferId"
> &
    BookingEventLitItemFragment;

export type PublicBookingEventBrandingFragment = {__typename?: "Branding"} & Pick<
    Branding,
    "id" | "logo" | "sessionBackground" | "styleTag"
>;

export type PublicBookingEventAvailableSlotFragment = {__typename?: "PublicBookingEventAvailableSlot"} & Pick<
    PublicBookingEventAvailableSlot,
    "id" | "day" | "slot" | "collaborators" | "available" | "conflicts"
>;

export type PublicBookingEventFragment = {__typename?: "PublicBookingEvent"} & Pick<
    PublicBookingEvent,
    | "id"
    | "name"
    | "description"
    | "slug"
    | "disabled"
    | "duration"
    | "additionalDuration"
    | "ownerId"
    | "ownerFirstName"
    | "ownerLastName"
    | "ownerProfilePicture"
    | "ownerFirstDayOfWeek"
    | "ownerHalfDayClock"
    | "ownerLanguage"
    | "timeZone"
    | "from"
    | "to"
    | "days"
    | "hours"
    | "questionnare"
    | "allowAdditionalGuests"
    | "organizationPlan"
    | "afterRegistrationRedirectUrl"
> & {
        collaborators: Array<
            {__typename?: "PublicBookingEventCollaborator"} & Pick<PublicBookingEventCollaborator, "collaboratorId" | "userId" | "userName">
        >;
        branding?: Maybe<{__typename?: "Branding"} & PublicBookingEventBrandingFragment>;
        payments?: Maybe<Array<{__typename?: "PaymentConfig"} & PaymentConfigFragment>>;
        afterRegistrationOffer?: Maybe<{__typename?: "Offer"} & OfferFragment>;
    };

export type BookedEventFragment = {__typename?: "BookedEvent"} & Pick<
    BookedEvent,
    | "id"
    | "name"
    | "status"
    | "startAt"
    | "additionalInformation"
    | "timeZone"
    | "sessionId"
    | "sessionLifecycle"
    | "duration"
    | "token"
    | "participantName"
    | "participantEmail"
    | "guests"
    | "requestPayment"
    | "checkoutStatus"
    | "checkoutUrl"
    | "questionnare"
>;

export type BookedSessionParticipantFragment = {__typename?: "Participant"} & Pick<
    Participant,
    "id" | "inviteStatus" | "userId" | "isOwner"
>;

export type BookedSessionFragment = {__typename?: "Session"} & Pick<
    Session,
    "id" | "name" | "description" | "lifecycle" | "startAt" | "plannedEnd" | "endedAt"
> & {participants: Array<{__typename?: "Participant"} & BookedSessionParticipantFragment>};

export type BookingFragment = {__typename?: "Booking"} & Pick<
    Booking,
    "id" | "name" | "seen" | "guests" | "participantName" | "participantEmail" | "additionalInformation" | "questionnare"
> & {
        assignee?: Maybe<
            {__typename?: "BookingCollaborator"} & Pick<BookingCollaborator, "id" | "isOwner"> & {
                    user: {__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName" | "profilePicture">;
                }
        >;
        session?: Maybe<{__typename?: "Session"} & BookedSessionFragment>;
        paymentTransaction?: Maybe<{__typename?: "PaymentTransaction"} & PaymentTransactionFragment>;
    };

export type BookingsCountFragment = {__typename?: "BookingsCount"} & Pick<BookingsCount, "id" | "canceled" | "upcoming" | "all">;

export type BookingCollaboratorFragment = {__typename?: "BookingCollaborator"} & Pick<
    BookingCollaborator,
    "id" | "enabled" | "isOwner" | "rbac" | "priority" | "bookingsCount" | "externalCalendarConnections"
> & {
        user: {__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName" | "email" | "profilePicture">;
        schedule?: Maybe<{__typename?: "BookingSchedule"} & BookingCollaboratorScheduleFragment>;
    };

export type WorkspaceBookingEventsQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type WorkspaceBookingEventsQuery = {__typename?: "Query"} & {
    workspaceBookingEvents: Array<
        {__typename?: "PublicBookingEventListItem"} & Pick<
            PublicBookingEventListItem,
            | "id"
            | "name"
            | "description"
            | "slug"
            | "duration"
            | "additionalDuration"
            | "ownerId"
            | "ownerFirstName"
            | "ownerLastName"
            | "ownerProfilePicture"
        > & {payments?: Maybe<Array<{__typename?: "PaymentConfig"} & PaymentConfigFragment>>}
    >;
    publicWorkspaceInfo?: Maybe<
        {__typename?: "PublicWorkspaceInfo"} & Pick<PublicWorkspaceInfo, "id" | "name"> & {
                branding?: Maybe<{__typename?: "Branding"} & PublicBookingEventBrandingFragment>;
            }
    >;
};

export type BookingEventContactFragment = {__typename?: "BookingEventContact"} & Pick<
    BookingEventContact,
    "id" | "isGuest" | "isOwner" | "email" | "firstName" | "lastName" | "participantType" | "attendance" | "sessionStatus" | "sessionDate"
> & {
        registrationAnswers: Array<
            {__typename?: "BookingEventContactRegistrationAnswer"} & Pick<BookingEventContactRegistrationAnswer, "key" | "value">
        >;
    };

export type GetBookingEventListQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type GetBookingEventListQuery = {__typename?: "Query"} & {
    bookingEvents: Array<{__typename?: "BookingEvent"} & BookingEventLitItemFragment>;
};

export type GetBookingEventQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetBookingEventQuery = {__typename?: "Query"} & {bookingEvent?: Maybe<{__typename?: "BookingEvent"} & BookingEventFragment>};

export type GetBookingContactsQueryVariables = Exact<{
    where: WhereIdInput;
    includeSelf: Scalars["Boolean"];
}>;

export type GetBookingContactsQuery = {__typename?: "Query"} & {
    bookingEventContacts?: Maybe<Array<{__typename?: "BookingEventContact"} & BookingEventContactFragment>>;
};

export type GetBookingSchedulesListQueryVariables = Exact<{[key: string]: never}>;

export type GetBookingSchedulesListQuery = {__typename?: "Query"} & {
    bookingSchedules: Array<{__typename?: "BookingSchedule"} & BookingScheduleFragment>;
};

export type GetPublicBookingEventQueryVariables = Exact<{
    slug: Scalars["String"];
    timezone: Scalars["String"];
}>;

export type GetPublicBookingEventQuery = {__typename?: "Query"} & {
    publicBookingEvent?: Maybe<{__typename?: "PublicBookingEvent"} & PublicBookingEventFragment>;
};

export type GetPublicBookingEventAvailableSlotsQueryVariables = Exact<{
    id: Scalars["String"];
    timezone: Scalars["String"];
    duration?: Maybe<Scalars["Int"]>;
    from: Scalars["DateTime"];
    to: Scalars["DateTime"];
    troubleshoot?: Maybe<Scalars["Boolean"]>;
}>;

export type GetPublicBookingEventAvailableSlotsQuery = {__typename?: "Query"} & {
    publicBookingEventAvailableSlots: Array<{__typename?: "PublicBookingEventAvailableSlot"} & PublicBookingEventAvailableSlotFragment>;
};

export type GetPublicBookingAvailableDateSlotsQueryVariables = Exact<{[key: string]: never}>;

export type GetPublicBookingAvailableDateSlotsQuery = {__typename?: "Query"} & {
    bookTimeSlots?: Maybe<Array<{__typename?: "PublicBookingEventAvailableSlot"} & PublicBookingEventAvailableSlotFragment>>;
};

export type GetEventBookingsQueryVariables = Exact<{
    where?: Maybe<BookingWhereInput>;
    first?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["ID"]>;
}>;

export type GetEventBookingsQuery = {__typename?: "Query"} & {bookings: Array<{__typename?: "Booking"} & BookingFragment>};

export type GetEventBookingsInitialQueryVariables = Exact<{
    bookingEventId: Scalars["String"];
    from?: Maybe<Scalars["DateTime"]>;
    to?: Maybe<Scalars["DateTime"]>;
    first?: Maybe<Scalars["Int"]>;
}>;

export type GetEventBookingsInitialQuery = {__typename?: "Query"} & {
    bookingsCount?: Maybe<{__typename?: "BookingsCount"} & BookingsCountFragment>;
    bookings: Array<{__typename?: "Booking"} & BookingFragment>;
};

export type GetBookingsCountQueryVariables = Exact<{
    bookingEventId: Scalars["String"];
}>;

export type GetBookingsCountQuery = {__typename?: "Query"} & {
    bookingsCount?: Maybe<{__typename?: "BookingsCount"} & BookingsCountFragment>;
};

export type GetBookingEventsCountQueryVariables = Exact<{[key: string]: never}>;

export type GetBookingEventsCountQuery = {__typename?: "Query"} & {
    bookingEventsCount?: Maybe<{__typename?: "BookingEventsCount"} & Pick<BookingEventsCount, "id" | "all" | "disabled">>;
};

export type GetBookedEventQueryVariables = Exact<{
    id: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
    includeEvent: Scalars["Boolean"];
}>;

export type GetBookedEventQuery = {__typename?: "Query"} & {
    bookedEvent?: Maybe<
        {__typename?: "BookedEvent"} & {
            event?: Maybe<{__typename?: "PublicBookingEvent"} & PublicBookingEventFragment>;
        } & BookedEventFragment
    >;
};

export type UpsertBookingEventMutationVariables = Exact<{
    id?: Maybe<Scalars["String"]>;
    create?: Maybe<BookingEventCreateInput>;
    update?: Maybe<BookingEventUpdateInput>;
}>;

export type UpsertBookingEventMutation = {__typename?: "Mutation"} & {
    upsertOneBookingEvent?: Maybe<{__typename?: "BookingEvent"} & BookingEventFragment>;
};

export type DeleteBookingEventMutationVariables = Exact<{
    id: Scalars["String"];
    reason: Scalars["String"];
}>;

export type DeleteBookingEventMutation = {__typename?: "Mutation"} & {
    deleteOneBookingEvent?: Maybe<{__typename?: "BookingEvent"} & Pick<BookingEvent, "id">>;
};

export type ToggleBookingEventMutationVariables = Exact<{
    id: Scalars["String"];
    disabled: Scalars["Boolean"];
    cancelBookings: Scalars["Boolean"];
    reason: Scalars["String"];
}>;

export type ToggleBookingEventMutation = {__typename?: "Mutation"} & {
    toggleBookingEvent?: Maybe<{__typename?: "BookingEvent"} & BookingEventFragment>;
};

export type CloneBookingEventMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type CloneBookingEventMutation = {__typename?: "Mutation"} & {
    cloneOneBookingEvent?: Maybe<{__typename?: "BookingEvent"} & BookingEventFragment>;
};

export type BookEventMutationVariables = Exact<{
    id: Scalars["String"];
    timeSlot: Scalars["DateTime"];
    duration?: Maybe<Scalars["Int"]>;
    additionalInformation: Scalars["Json"];
    guests: Array<Scalars["String"]> | Scalars["String"];
    questionnare?: Maybe<Scalars["Json"]>;
    timeZone: Scalars["String"];
    availableAssignees: Array<Scalars["String"]> | Scalars["String"];
    paymentProvider?: Maybe<Scalars["String"]>;
    isPreview?: Maybe<Scalars["Boolean"]>;
}>;

export type BookEventMutation = {__typename?: "Mutation"} & {bookEvent?: Maybe<{__typename?: "BookedEvent"} & BookedEventFragment>};

export type UpsertBookingScheduleMutationVariables = Exact<{
    id?: Maybe<Scalars["String"]>;
    create?: Maybe<BookingScheduleCreateInput>;
    update?: Maybe<BookingScheduleUpdateInput>;
}>;

export type UpsertBookingScheduleMutation = {__typename?: "Mutation"} & {
    upsertOneBookingSchedule?: Maybe<{__typename?: "BookingSchedule"} & BookingScheduleFragment>;
};

export type UpsertBookingCollaboratorMutationVariables = Exact<{
    id?: Maybe<Scalars["String"]>;
    create?: Maybe<CreateBookingCollaboratorInput>;
    update?: Maybe<UpdateBookingCollaboratorInput>;
}>;

export type UpsertBookingCollaboratorMutation = {__typename?: "Mutation"} & {
    upsertOneBookingCollaborator?: Maybe<{__typename?: "BookingCollaborator"} & BookingCollaboratorFragment>;
};

export type DeleteBookingCollaboratorMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteBookingCollaboratorMutation = {__typename?: "Mutation"} & {
    deleteOneBookingCollaborator?: Maybe<{__typename?: "BookingCollaborator"} & Pick<BookingCollaborator, "id">>;
};

export type CancelBookingMutationVariables = Exact<{
    id: Scalars["String"];
    reason: Scalars["String"];
}>;

export type CancelBookingMutation = {__typename?: "Mutation"} & {cancelBooking?: Maybe<{__typename?: "Booking"} & BookingFragment>};

export type DeleteBookingMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteBookingMutation = {__typename?: "Mutation"} & {deleteOneBooking?: Maybe<{__typename?: "Booking"} & Pick<Booking, "id">>};

export type SeenBookingsMutationVariables = Exact<{
    ids: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SeenBookingsMutation = {__typename?: "Mutation"} & {
    seenBookings: Array<{__typename?: "Booking"} & Pick<Booking, "id" | "seen">>;
};

export type CancelBookedEventMutationVariables = Exact<{
    bookedEventId: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
    reason?: Maybe<Scalars["String"]>;
}>;

export type CancelBookedEventMutation = {__typename?: "Mutation"} & {
    cancelBookedEvent?: Maybe<{__typename?: "BookedEvent"} & Pick<BookedEvent, "id">>;
};

export type RescheduleBookedEventMutationVariables = Exact<{
    bookedEventId: Scalars["String"];
    token?: Maybe<Scalars["String"]>;
    timeSlot: Scalars["DateTime"];
    additionalInformation: Scalars["Json"];
    guests: Array<Scalars["String"]> | Scalars["String"];
    timeZone: Scalars["String"];
    paymentProvider?: Maybe<Scalars["String"]>;
}>;

export type RescheduleBookedEventMutation = {__typename?: "Mutation"} & {
    rescheduleBookedEvent?: Maybe<{__typename?: "BookedEvent"} & BookedEventFragment>;
};

export type ChangeBookingAssigneeMutationVariables = Exact<{
    bookingId: Scalars["String"];
    collaboratorId: Scalars["String"];
}>;

export type ChangeBookingAssigneeMutation = {__typename?: "Mutation"} & {
    changeBookingAssignee?: Maybe<{__typename?: "Booking"} & BookingFragment>;
};

export type OnNewBookingSubscriptionVariables = Exact<{[key: string]: never}>;

export type OnNewBookingSubscription = {__typename?: "Subscription"} & {
    onNewBooking?: Maybe<
        {__typename?: "BookingEvent"} & Pick<BookingEvent, "id" | "bookingsCount" | "seen"> & {
                booking: Array<{__typename?: "Booking"} & BookingFragment>;
            }
    >;
};

export type IsBookingSlugUniqueQueryVariables = Exact<{
    slug: Scalars["String"];
}>;

export type IsBookingSlugUniqueQuery = {__typename?: "Query"} & {
    checkIfBookingSlugUnique?: Maybe<{__typename?: "BookingSlugUniqueResult"} & Pick<BookingSlugUniqueResult, "isUnique">>;
};

export type GetExternalCalendarsQueryVariables = Exact<{
    from: Scalars["DateTime"];
    until: Scalars["DateTime"];
}>;

export type GetExternalCalendarsQuery = {__typename?: "Query"} & {
    getGoogleEvents: {__typename?: "ExternalEventsAndCalendars"} & {
        calendars: Array<{__typename?: "ExternalCalendar"} & ExternalCalendarFragment>;
    };
    getMicrosoftEvents: {__typename?: "ExternalEventsAndCalendars"} & {
        calendars: Array<{__typename?: "ExternalCalendar"} & ExternalCalendarFragment>;
    };
    getAppleEvents: {__typename?: "ExternalEventsAndCalendars"} & {
        calendars: Array<{__typename?: "ExternalCalendar"} & ExternalCalendarFragment>;
    };
};

export type OnBookingEventUpdateSubscriptionVariables = Exact<{
    id: Scalars["String"];
}>;

export type OnBookingEventUpdateSubscription = {__typename?: "Subscription"} & {
    onBookingEventUpdate?: Maybe<{__typename?: "BookingEventUpdateEvent"} & Pick<BookingEventUpdateEvent, "bookingEventId" | "updatedBy">>;
};

export type GetBookingAnswersQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetBookingAnswersQuery = {__typename?: "Query"} & {
    getBookingAnswers?: Maybe<{__typename?: "BookingFormAnswers"} & Pick<BookingFormAnswers, "form" | "answers">>;
};

export type CalendarEventCustomFragment = {__typename?: "CalendarCustomEvent"} & Pick<
    CalendarCustomEvent,
    | "isOwner"
    | "isAssistant"
    | "id"
    | "name"
    | "lifecycle"
    | "startAt"
    | "plannedEnd"
    | "isBillable"
    | "occurrenceId"
    | "instanceId"
    | "quickSession"
    | "isBooking"
    | "isEvent"
    | "isRoom"
    | "currentParticipantInviteStatus"
    | "memoryAccessType"
    | "backstage"
    | "workspaceId"
    | "recurrenceParentId"
    | "timeZone"
    | "organizationId"
    | "isTask"
    | "description"
> & {
        instanceOfRecurrence?: Maybe<
            {__typename?: "InstanceOfRecurrenceForEvent"} & Pick<
                InstanceOfRecurrenceForEvent,
                "id" | "sessionId" | "isParticipantInRecurrenceParent"
            >
        >;
        participants: Array<{__typename?: "ParticipantForEvent"} & CalendarEventParticipantFragment>;
        recurrenceData?: Maybe<{__typename?: "RecurrenceData"} & CalendarEventRecurrenceDataFragment>;
        event?: Maybe<{__typename?: "EventForEvent"} & CalendarEventEventCustomFragment>;
        externalEvent?: Maybe<{__typename?: "ExternalCalendarEvent"} & ExternalCalendarEventFragment>;
    };

export type CalendarEventEventCustomFragment = {__typename?: "EventForEvent"} & Pick<EventForEvent, "id" | "slug" | "state">;

export type CalendarEventParticipantFragment = {__typename?: "ParticipantForEvent"} & Pick<
    ParticipantForEvent,
    "id" | "status" | "isOwner" | "inviteStatus" | "email" | "firstName" | "lastName" | "avatar" | "token" | "isGuest" | "rbac"
>;

export type CalendarEventRecurrenceDataFragment = {__typename?: "RecurrenceData"} & Pick<
    RecurrenceData,
    | "sessionId"
    | "dtStart"
    | "frequency"
    | "count"
    | "interval"
    | "until"
    | "byDay"
    | "byMonth"
    | "byMonthDay"
    | "bySetPos"
    | "wkst"
    | "exclude"
    | "instancesOccurrenceIds"
> & {firstInstance?: Maybe<{__typename?: "Session"} & Pick<Session, "id">>};

export type ExternalCalendarFragment = {__typename?: "ExternalCalendar"} & Pick<
    ExternalCalendar,
    "id" | "type" | "name" | "email" | "isShared"
>;

export type ExternalCalendarEventFragment = {__typename?: "ExternalCalendarEvent"} & Pick<
    ExternalCalendarEvent,
    "id" | "name" | "calendarId" | "type" | "recurrence" | "rsvp" | "isBusy"
> & {
        start?: Maybe<{__typename?: "ExternalCalendarEventDate"} & Pick<ExternalCalendarEventDate, "date" | "dateTime" | "timeZone">>;
        end?: Maybe<{__typename?: "ExternalCalendarEventDate"} & Pick<ExternalCalendarEventDate, "date" | "dateTime" | "timeZone">>;
    };

export type GetCalendarEventWithParticipantsQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GetCalendarEventWithParticipantsQuery = {__typename?: "Query"} & {
    calendarEventWithParticipants?: Maybe<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>;
};

export type GetCalendarEventsQueryVariables = Exact<{
    from: Scalars["DateTime"];
    until: Scalars["DateTime"];
    includeNormal: Scalars["Boolean"];
    includeRecurrent: Scalars["Boolean"];
    includeExternal: Scalars["Boolean"];
}>;

export type GetCalendarEventsQuery = {__typename?: "Query"} & {
    calendarEvents?: Maybe<Array<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>>;
    calendarRecurrentEvents?: Maybe<Array<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>>;
    getGoogleEvents?: Maybe<
        {__typename?: "ExternalEventsAndCalendars"} & {
            events: Array<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>;
            calendars: Array<{__typename?: "ExternalCalendar"} & ExternalCalendarFragment>;
        }
    >;
    getMicrosoftEvents?: Maybe<
        {__typename?: "ExternalEventsAndCalendars"} & {
            events: Array<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>;
            calendars: Array<{__typename?: "ExternalCalendar"} & ExternalCalendarFragment>;
        }
    >;
    getAppleEvents?: Maybe<
        {__typename?: "ExternalEventsAndCalendars"} & {
            events: Array<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>;
            calendars: Array<{__typename?: "ExternalCalendar"} & ExternalCalendarFragment>;
        }
    >;
};

export type UpdateParticipantInviteStatusMutationVariables = Exact<{
    sessionId: Scalars["String"];
    inviteStatus: ParticipantInviteStatus;
    reason?: Maybe<Scalars["String"]>;
}>;

export type UpdateParticipantInviteStatusMutation = {__typename?: "Mutation"} & {
    updateParticipantInviteStatus?: Maybe<{__typename?: "Participant"} & Pick<Participant, "id" | "inviteStatus">>;
};

export type DeleteSessionMutationVariables = Exact<{
    session: WhereIdInput;
}>;

export type DeleteSessionMutation = {__typename?: "Mutation"} & {deleteOneSession?: Maybe<{__typename?: "Session"} & Pick<Session, "id">>};

export type DeleteSessionOccurrenceMutationVariables = Exact<{
    sessionId: Scalars["String"];
    occurrenceId: Scalars["DateTime"];
}>;

export type DeleteSessionOccurrenceMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteSessionOccurrence">;

export type DeleteSessionOccurrenceAndFollowingMutationVariables = Exact<{
    sessionId: Scalars["String"];
    occurrenceId: Scalars["DateTime"];
}>;

export type DeleteSessionOccurrenceAndFollowingMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteSessionOccurrenceAndFollowing">;

export type CreateSessionOccurrenceMutationVariables = Exact<{
    sessionId: Scalars["String"];
    occurrenceId: Scalars["DateTime"];
}>;

export type CreateSessionOccurrenceMutation = {__typename?: "Mutation"} & {
    createSessionOccurrence: {__typename?: "Session"} & Pick<Session, "id">;
};

export type RemoveYourselfFromSessionMutationVariables = Exact<{
    sessionId: Scalars["String"];
    soft?: Maybe<Scalars["Boolean"]>;
    upcoming?: Maybe<Scalars["Boolean"]>;
}>;

export type RemoveYourselfFromSessionMutation = {__typename?: "Mutation"} & Pick<Mutation, "removeParticipant">;

export type CalendarSettingsQueryVariables = Exact<{[key: string]: never}>;

export type CalendarSettingsQuery = {__typename?: "Query"} & {
    calendarSettings: {__typename?: "CalendarSettings"} & Pick<
        CalendarSettings,
        "view" | "live" | "invitations" | "bookings" | "events" | "tasks"
    > & {
            externalCalendars: Array<
                {__typename?: "ExternalCalendarFilter"} & Pick<ExternalCalendarFilter, "calendarId" | "calendarTitle" | "enabled" | "type">
            >;
        };
};

export type DeleteMicrosoftConnectionMutationVariables = Exact<{
    email: Scalars["String"];
    provider?: Maybe<Scalars["String"]>;
}>;

export type DeleteMicrosoftConnectionMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteUserMicrosoftProviders">;

export type SyncAppleAccountMutationVariables = Exact<{
    email: Scalars["String"];
    password: Scalars["String"];
}>;

export type SyncAppleAccountMutation = {__typename?: "Mutation"} & {
    syncAppleAccount?: Maybe<{__typename?: "SyncAppleAccountResponse"} & Pick<SyncAppleAccountResponse, "email">>;
};

export type DeleteAppleCalendarConnectionMutationVariables = Exact<{
    email: Scalars["String"];
}>;

export type DeleteAppleCalendarConnectionMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteUserAppleProviders">;

export type EnforceSpotlightMutationVariables = Exact<{
    artifactId: Scalars["String"];
    mode: Scalars["String"];
}>;

export type EnforceSpotlightMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<{__typename?: "Artifact"} & Pick<Artifact, "id">>;
};

export type SetEnableReactionsMutationVariables = Exact<{
    sessionId: Scalars["String"];
    enableReactionsType: SessionFlagsType;
}>;

export type SetEnableReactionsMutation = {__typename?: "Mutation"} & {
    setEnableReactions?: Maybe<{__typename?: "Session"} & Pick<Session, "enableReactionsType">>;
};

export type SetAllowScreenShareMutationVariables = Exact<{
    sessionId: Scalars["String"];
    allowScreenShareType: SessionFlagsType;
}>;

export type SetAllowScreenShareMutation = {__typename?: "Mutation"} & {
    setAllowScreenShare?: Maybe<{__typename?: "Session"} & Pick<Session, "allowScreenShareType">>;
};

export type SetShareScreenParticipantIdMutationVariables = Exact<{
    sessionId: Scalars["String"];
    stopPresenting: Scalars["Boolean"];
}>;

export type SetShareScreenParticipantIdMutation = {__typename?: "Mutation"} & Pick<Mutation, "shareParticipantScreen">;

export type SetSessionHardMuteMutationVariables = Exact<{
    sessionId: Scalars["String"];
    hardMute: SessionFlagsType;
}>;

export type SetSessionHardMuteMutation = {__typename?: "Mutation"} & {
    setSessionHardMute?: Maybe<{__typename?: "Session"} & Pick<Session, "allowMicrophoneType">>;
};

export type SetSessionDisableParticipantsVideoMutationVariables = Exact<{
    sessionId: Scalars["String"];
    allowCameraType: SessionFlagsType;
}>;

export type SetSessionDisableParticipantsVideoMutation = {__typename?: "Mutation"} & {
    setSessionDisableParticipantsVideo?: Maybe<{__typename?: "Session"} & Pick<Session, "allowCameraType">>;
};

export type SendSessionEventMutationVariables = Exact<{
    sessionId: Scalars["String"];
    data: SessionEventInput;
    participantsIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SendSessionEventMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendSessionEvent">;

export type MuteParticipantMutationVariables = Exact<{
    participantId: Scalars["String"];
}>;

export type MuteParticipantMutation = {__typename?: "Mutation"} & Pick<Mutation, "muteParticipant">;

export type StopCameraParticipantMutationVariables = Exact<{
    participantId: Scalars["String"];
}>;

export type StopCameraParticipantMutation = {__typename?: "Mutation"} & Pick<Mutation, "stopCameraParticipant">;

export type CustomEmailContentFragment = {__typename?: "CustomEmailContent"} & Pick<CustomEmailContent, "id" | "type" | "value" | "hidden">;

export type GetCustomEmailsQueryVariables = Exact<{
    workspacePermissionsId: Scalars["String"];
}>;

export type GetCustomEmailsQuery = {__typename?: "Query"} & Pick<Query, "getCustomEmailsLanguage"> & {
        getCustomEmails: Array<
            {__typename?: "CustomEmail"} & Pick<
                CustomEmail,
                "id" | "templateId" | "subject" | "body" | "bodyTitle" | "createdAt" | "updatedAt" | "disableSending"
            > & {customEmailContent: Array<{__typename?: "CustomEmailContent"} & CustomEmailContentFragment>}
        >;
        getCustomEmailSignature: {__typename?: "CustomEmailSignatureResult"} & Pick<CustomEmailSignatureResult, "signature" | "footer">;
    };

export type UpsertCustomEmailMutationVariables = Exact<{
    id?: Maybe<Scalars["String"]>;
    templateId: Scalars["String"];
    subject?: Maybe<Scalars["String"]>;
    body?: Maybe<Scalars["Json"]>;
    bodyTitle?: Maybe<Scalars["String"]>;
    customEmailContent?: Maybe<Array<UpsertCustomEmailContentInput> | UpsertCustomEmailContentInput>;
    disableSending?: Maybe<Scalars["Boolean"]>;
}>;

export type UpsertCustomEmailMutation = {__typename?: "Mutation"} & {
    upsertCustomEmail: {__typename?: "CustomEmail"} & Pick<
        CustomEmail,
        "id" | "templateId" | "subject" | "body" | "bodyTitle" | "createdAt" | "updatedAt" | "disableSending"
    > & {customEmailContent: Array<{__typename?: "CustomEmailContent"} & CustomEmailContentFragment>};
};

export type UpdateCustomEmailSignatureMutationVariables = Exact<{
    signature?: Maybe<Scalars["Json"]>;
    footer?: Maybe<Scalars["Json"]>;
}>;

export type UpdateCustomEmailSignatureMutation = {__typename?: "Mutation"} & {
    updateCustomEmailSignature: {__typename?: "CustomEmailSignatureResult"} & Pick<CustomEmailSignatureResult, "signature" | "footer">;
};

export type UpdateCustomEmailsLanguageMutationVariables = Exact<{
    language: Scalars["String"];
}>;

export type UpdateCustomEmailsLanguageMutation = {__typename?: "Mutation"} & Pick<Mutation, "updateCustomEmailsLanguage">;

export type DeleteCustomEmailMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteCustomEmailMutation = {__typename?: "Mutation"} & {
    deleteCustomEmail: {__typename?: "CustomEmail"} & Pick<CustomEmail, "id" | "templateId">;
};

export type DesignerCommitStateQueryVariables = Exact<{[key: string]: never}>;

export type DesignerCommitStateQuery = {__typename?: "Query"} & {
    designerCommitState?: Maybe<{__typename?: "DesignerCommitState"} & Pick<DesignerCommitState, "commitInProgress">>;
};

export type CreateOneSessionMutationVariables = Exact<{
    data: SessionCreateInput;
    meetingRegion?: Maybe<Scalars["String"]>;
}>;

export type CreateOneSessionMutation = {__typename?: "Mutation"} & {createSession: {__typename?: "Session"} & SessionNoCacheFragment};

export type UpdateOneSessionMutationVariables = Exact<{
    where: WhereIdInput;
    data: SessionUpdateInput;
    hasParticipants: Scalars["Boolean"];
    isApplyingTemplate?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateOneSessionMutation = {__typename?: "Mutation"} & {
    updateSession?: Maybe<{__typename?: "Session"} & Pick<Session, "id"> & SessionNoCacheFragment>;
};

export type UpdateParticipantsInSessionMutationVariables = Exact<{
    deletedParticipantIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    upsertParticipantJSONs?: Maybe<Array<Scalars["Json"]> | Scalars["Json"]>;
    sessionId: Scalars["String"];
    followUpOfSessionId?: Maybe<Scalars["String"]>;
}>;

export type UpdateParticipantsInSessionMutation = {__typename?: "Mutation"} & {
    updateParticipantsInSession?: Maybe<{__typename?: "Session"} & SessionNoCacheFragment>;
};

export type UpdateParticipantsInSessionStandaloneMutationVariables = Exact<{
    deletedParticipantIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    upsertParticipantJSONs?: Maybe<Array<Scalars["Json"]> | Scalars["Json"]>;
    sessionId: Scalars["String"];
    followUpOfSessionId?: Maybe<Scalars["String"]>;
}>;

export type UpdateParticipantsInSessionStandaloneMutation = {__typename?: "Mutation"} & {
    updateParticipantsInSession?: Maybe<
        {__typename?: "Session"} & Pick<Session, "id"> & {participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>}
    >;
};

export type DesignerStateQueryVariables = Exact<{[key: string]: never}>;

export type DesignerStateQuery = {__typename?: "Query"} & {
    designerState?: Maybe<
        {__typename?: "DesignerState"} & Pick<
            DesignerState,
            | "sessionId"
            | "selectedAgendaItemId"
            | "selectedMacroIdToConfig"
            | "selectedMicroIdToConfig"
            | "selectedMacroIdToPreview"
            | "selectedMicroIdToPreview"
            | "typeOfBlockToAdd"
            | "isAddingBlock"
            | "isConfig"
            | "isPreview"
            | "selectedResourceId"
            | "templateMode"
            | "toolStep"
            | "deleteAgendaItemIds"
            | "isSessionPreview"
            | "checkedRecurrenceEditForSessionId"
            | "recurrenceEditType"
            | "configurationStep"
            | "dirty"
            | "followUpOfSessionId"
            | "preventRestore"
            | "configurationConfirm"
            | "configInProgress"
        >
    >;
};

export type DesignerSessionIdQueryVariables = Exact<{[key: string]: never}>;

export type DesignerSessionIdQuery = {__typename?: "Query"} & {
    designerSessionId?: Maybe<{__typename?: "DesignerSessionId"} & Pick<DesignerSessionId, "sessionId">>;
};

export type DesignerSessionLockedQueryVariables = Exact<{[key: string]: never}>;

export type DesignerSessionLockedQuery = {__typename?: "Query"} & {
    designerSessionLocked?: Maybe<{__typename?: "DesignerSessionLocked"} & Pick<DesignerSessionLocked, "locked">>;
};

export type DesignerSessionChangedByQueryVariables = Exact<{[key: string]: never}>;

export type DesignerSessionChangedByQuery = {__typename?: "Query"} & {
    designerSessionChangedBy?: Maybe<{__typename?: "DesignerSessionChangedBy"} & Pick<DesignerSessionChangedBy, "changedBy">>;
};

export type DesignerEditStateQueryVariables = Exact<{[key: string]: never}>;

export type DesignerEditStateQuery = {__typename?: "Query"} & {
    designerEditState?: Maybe<{__typename?: "DesignerEditState"} & Pick<DesignerEditState, "sessionId" | "isEditing">>;
};

export type DesignerRestoreUncommitedDraftQueryVariables = Exact<{[key: string]: never}>;

export type DesignerRestoreUncommitedDraftQuery = {__typename?: "Query"} & {
    designerRestoreUncommitedDraft?: Maybe<{__typename?: "DesignerRestoreDraft"} & Pick<DesignerRestoreDraft, "success">>;
};

export type PaymentProviderFragment = {__typename?: "PaymentProvider"} & Pick<PaymentProvider, "id" | "name" | "accountName">;

export type PaymentConfigFragment = {__typename?: "PaymentConfig"} & Pick<
    PaymentConfig,
    "id" | "enabled" | "amount" | "currency" | "terms" | "ownerId"
> & {paymentProviders?: Maybe<Array<Maybe<{__typename?: "PaymentProvider"} & PaymentProviderFragment>>>};

export type PaymentTransactionFragment = {__typename?: "PaymentTransaction"} & Pick<
    PaymentTransaction,
    "id" | "checkoutSessionStatus" | "paymentIntendId" | "paymentIntendStatus" | "paymentProviderType"
> & {paymentProvider?: Maybe<{__typename?: "PaymentProvider"} & PaymentProviderFragment>};

export type GetPaymentProvidersQueryVariables = Exact<{[key: string]: never}>;

export type GetPaymentProvidersQuery = {__typename?: "Query"} & {
    getPaymentProviders: Array<{__typename?: "PaymentProvider"} & PaymentProviderFragment>;
};

export type GetProviderLinkedConfigsQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetProviderLinkedConfigsQuery = {__typename?: "Query"} & {
    getProviderLinkedConfigs: Array<
        {__typename?: "PaymentConfig"} & Pick<PaymentConfig, "id"> & {
                sessionEvent?: Maybe<
                    {__typename?: "SessionEvent"} & Pick<SessionEvent, "id"> & {
                            session: {__typename?: "Session"} & Pick<Session, "id" | "name">;
                        }
                >;
                bookingEvent?: Maybe<{__typename?: "BookingEvent"} & Pick<BookingEvent, "id" | "name">>;
            }
    >;
};

export type UpsertPaymentConfigMutationVariables = Exact<{
    create?: Maybe<PaymentConfigCreateInput>;
    update?: Maybe<PaymentConfigUpdateInput>;
}>;

export type UpsertPaymentConfigMutation = {__typename?: "Mutation"} & {
    upsertPaymentConfig?: Maybe<{__typename?: "PaymentConfig"} & PaymentConfigFragment>;
};

export type DeletePaymentConfigMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeletePaymentConfigMutation = {__typename?: "Mutation"} & {
    deletePaymentConfig?: Maybe<{__typename?: "PaymentConfig"} & PaymentConfigFragment>;
};

export type SessionRecordingFragment = {__typename?: "SessionRecording"} & Pick<
    SessionRecording,
    | "id"
    | "state"
    | "name"
    | "type"
    | "livestreamOutputUrls"
    | "permission"
    | "downloadUrl"
    | "startedAt"
    | "processingStatus"
    | "version"
    | "createdAt"
>;

export type SessionRecordingQueryVariables = Exact<{
    where: WhereIdInput;
}>;

export type SessionRecordingQuery = {__typename?: "Query"} & {
    sessionRecording?: Maybe<{__typename?: "SessionRecording"} & SessionRecordingFragment>;
};

export type RecordingUpdatedSubscriptionVariables = Exact<{[key: string]: never}>;

export type RecordingUpdatedSubscription = {__typename?: "Subscription"} & {
    onRecordingUpdated?: Maybe<
        {__typename?: "OnRecordingUpdated"} & {recording: {__typename?: "SessionRecording"} & MemorySessionRecordingFragment}
    >;
};

export type ResourceFragment = {__typename?: "Resource"} & Pick<
    Resource,
    "id" | "content" | "createdAt" | "type" | "readOnly" | "processingDetails" | "processingStatus" | "isPublic" | "isTemplate"
>;

export type ResourceFullFragment = {__typename?: "Resource"} & Pick<
    Resource,
    | "id"
    | "createdAt"
    | "updatedAt"
    | "readOnly"
    | "type"
    | "content"
    | "workspaceId"
    | "isTemplate"
    | "isPublic"
    | "processingStatus"
    | "processingDetails"
> & {owner: {__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName">};

export type UseResourceQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type UseResourceQuery = {__typename?: "Query"} & {resource?: Maybe<{__typename?: "Resource"} & ResourceFullFragment>};

export type UseResourcesQueryVariables = Exact<{
    where?: Maybe<ResourceWhereInput>;
}>;

export type UseResourcesQuery = {__typename?: "Query"} & {resources: Array<{__typename?: "Resource"} & ResourceFullFragment>};

export type FindResourceByNameQueryVariables = Exact<{
    content?: Maybe<StringFilter>;
    userId?: Maybe<StringFilter>;
    type?: Maybe<StringFilter>;
}>;

export type FindResourceByNameQuery = {__typename?: "Query"} & {resources: Array<{__typename?: "Resource"} & ResourceFullFragment>};

export type UseResourcesForRenderingQueryVariables = Exact<{
    type: Scalars["String"];
    container: Scalars["String"];
}>;

export type UseResourcesForRenderingQuery = {__typename?: "Query"} & {
    resxResources?: Maybe<
        {__typename?: "ResxResourcesList"} & Pick<ResxResourcesList, "hasData"> & {
                resources: Array<{__typename?: "Resource"} & ResourceFullFragment>;
            }
    >;
};

export type UseResourceResultQueryVariables = Exact<{
    resultId: Scalars["String"];
}>;

export type UseResourceResultQuery = {__typename?: "Query"} & {
    resourceResult?: Maybe<{__typename?: "ResourceResult"} & Pick<ResourceResult, "id"> & ResourceResultFullFragment>;
};

export type ResourceResultFullFragment = {__typename?: "ResourceResult"} & Pick<
    ResourceResult,
    "id" | "resourceContentSnapshot" | "resourceId" | "type" | "content"
> & {resource?: Maybe<{__typename?: "Resource"} & ResourceFullFragment>};

export type ResourceNameFragment = {__typename?: "ResourceName"} & Pick<ResourceName, "artifactId" | "name" | "resultId">;

export type GetSessionResourceNamesQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetSessionResourceNamesQuery = {__typename?: "Query"} & {
    sessionResourceNames?: Maybe<
        {__typename?: "ResourceNames"} & {names?: Maybe<Array<{__typename?: "ResourceName"} & ResourceNameFragment>>}
    >;
};

export type ResxResourceUpdatedNotifySubscriptionVariables = Exact<{[key: string]: never}>;

export type ResxResourceUpdatedNotifySubscription = {__typename?: "Subscription"} & {
    resxResourceUpdated?: Maybe<
        {__typename?: "ResxResourceUpdatePayload"} & Pick<ResxResourceUpdatePayload, "resourceId" | "resourceType" | "specificationVersion">
    >;
};

export type ResxResultUpdatedNotifySubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type ResxResultUpdatedNotifySubscription = {__typename?: "Subscription"} & {
    resxResultUpdated?: Maybe<
        {__typename?: "ResxResultUpdatePayload"} & Pick<ResxResultUpdatePayload, "resultId" | "resultType" | "specificationVersion">
    >;
};

export type SkipSeenMutationVariables = Exact<{
    segment: Scalars["String"];
    expiry?: Maybe<Scalars["Int"]>;
}>;

export type SkipSeenMutation = {__typename?: "Mutation"} & Pick<Mutation, "skipSeen">;

export type GetUserCustomBackgroundsQueryVariables = Exact<{[key: string]: never}>;

export type GetUserCustomBackgroundsQuery = {__typename?: "Query"} & {
    getRemoteUser: {__typename?: "GetRemoteUserResult"} & {
        user?: Maybe<
            {__typename?: "User"} & Pick<User, "id"> & {
                    customBackgrounds: Array<{__typename?: "CustomBackground"} & Pick<CustomBackground, "id" | "url">>;
                }
        >;
    };
};

export type GetCustomBackgroundUploadUrlQueryVariables = Exact<{[key: string]: never}>;

export type GetCustomBackgroundUploadUrlQuery = {__typename?: "Query"} & Pick<Query, "getCustomBackgroundUploadUrl">;

export type CommitCustomBackgroundMutationVariables = Exact<{
    uploadUrl: Scalars["String"];
}>;

export type CommitCustomBackgroundMutation = {__typename?: "Mutation"} & {
    commitCustomBackground: {__typename?: "User"} & Pick<User, "id"> & {
            customBackgrounds: Array<{__typename?: "CustomBackground"} & Pick<CustomBackground, "id" | "url">>;
        };
};

export type DeleteCustomBackgroundMutationVariables = Exact<{
    backgroundUrl: Scalars["String"];
}>;

export type DeleteCustomBackgroundMutation = {__typename?: "Mutation"} & {
    deleteCustomBackground: {__typename?: "User"} & Pick<User, "id"> & {
            customBackgrounds: Array<{__typename?: "CustomBackground"} & Pick<CustomBackground, "id" | "url">>;
        };
};

export type SubscriptionDetailsFragment = {__typename?: "SubscriptionDetailsPayload"} & Pick<
    SubscriptionDetailsPayload,
    "organization_id" | "cancel_url" | "update_url" | "state" | "user_email"
> & {
        last_payment?: Maybe<{__typename?: "PaddlePaymentDetails"} & Pick<PaddlePaymentDetails, "amount" | "currency" | "date">>;
        next_payment?: Maybe<{__typename?: "PaddlePaymentDetails"} & Pick<PaddlePaymentDetails, "amount" | "currency" | "date">>;
        payment_information?: Maybe<
            {__typename?: "PaddlePaymentInfo"} & Pick<
                PaddlePaymentInfo,
                "card_type" | "last_four_digits" | "expiry_date" | "payment_method"
            >
        >;
    };

export type PaddleSubscriptionDetailsQueryVariables = Exact<{[key: string]: never}>;

export type PaddleSubscriptionDetailsQuery = {__typename?: "Query"} & {
    paddleSubscriptionDetails?: Maybe<{__typename?: "SubscriptionDetailsPayload"} & SubscriptionDetailsFragment>;
};

export type UserOrganizationsPaddleSubscriptionDetailsQueryVariables = Exact<{[key: string]: never}>;

export type UserOrganizationsPaddleSubscriptionDetailsQuery = {__typename?: "Query"} & {
    userOrganizationsPaddleSubscriptionDetails?: Maybe<
        Array<Maybe<{__typename?: "SubscriptionDetailsPayload"} & SubscriptionDetailsFragment>>
    >;
};

export type PaddlePlansQueryVariables = Exact<{[key: string]: never}>;

export type PaddlePlansQuery = {__typename?: "Query"} & {
    paddlePlans: Array<
        {__typename?: "PaddlePlan"} & Pick<PaddlePlan, "id" | "name" | "plan" | "billing_type" | "billing_period" | "trial_days"> & {
                initial_price?: Maybe<Array<{__typename?: "PaddlePlanPrice"} & Pick<PaddlePlanPrice, "currency" | "amount">>>;
                recurring_price?: Maybe<Array<{__typename?: "PaddlePlanPrice"} & Pick<PaddlePlanPrice, "currency" | "amount">>>;
                modifiers?: Maybe<
                    Array<
                        {__typename?: "PaddleSubscriptionModifierPrice"} & Pick<
                            PaddleSubscriptionModifierPrice,
                            "type" | "pricePerUnitInDollars"
                        >
                    >
                >;
            }
    >;
};

export type PaddleOfferPlansQueryVariables = Exact<{[key: string]: never}>;

export type PaddleOfferPlansQuery = {__typename?: "Query"} & {
    paddleOfferPlans: Array<
        {__typename?: "PaddlePlan"} & Pick<PaddlePlan, "id" | "name" | "plan" | "billing_type" | "billing_period" | "trial_days"> & {
                initial_price?: Maybe<Array<{__typename?: "PaddlePlanPrice"} & Pick<PaddlePlanPrice, "currency" | "amount">>>;
                recurring_price?: Maybe<Array<{__typename?: "PaddlePlanPrice"} & Pick<PaddlePlanPrice, "currency" | "amount">>>;
                modifiers?: Maybe<
                    Array<
                        {__typename?: "PaddleSubscriptionModifierPrice"} & Pick<
                            PaddleSubscriptionModifierPrice,
                            "type" | "pricePerUnitInDollars"
                        >
                    >
                >;
            }
    >;
};

export type GetSkipTrialCouponQueryVariables = Exact<{[key: string]: never}>;

export type GetSkipTrialCouponQuery = {__typename?: "Query"} & Pick<Query, "getSkipTrialCoupon">;

export type PaddleTrialPlansQueryVariables = Exact<{[key: string]: never}>;

export type PaddleTrialPlansQuery = {__typename?: "Query"} & {
    paddleTrialPlans: Array<
        {__typename?: "PaddlePlan"} & Pick<PaddlePlan, "id" | "name" | "plan" | "billing_type" | "billing_period" | "trial_days"> & {
                initial_price?: Maybe<Array<{__typename?: "PaddlePlanPrice"} & Pick<PaddlePlanPrice, "currency" | "amount">>>;
                recurring_price?: Maybe<Array<{__typename?: "PaddlePlanPrice"} & Pick<PaddlePlanPrice, "currency" | "amount">>>;
                modifiers?: Maybe<
                    Array<
                        {__typename?: "PaddleSubscriptionModifierPrice"} & Pick<
                            PaddleSubscriptionModifierPrice,
                            "type" | "pricePerUnitInDollars"
                        >
                    >
                >;
            }
    >;
};

export type UserPaddleTransactionsInfoFragment = {__typename?: "UserPaddleTransaction"} & Pick<
    UserPaddleTransaction,
    | "orderId"
    | "checkoutId"
    | "amount"
    | "currency"
    | "status"
    | "createdAt"
    | "productId"
    | "subscriptionId"
    | "subscriptionStatus"
    | "receiptUrl"
>;

export type UserPaddleTransactionsQueryVariables = Exact<{
    page: Scalars["Int"];
}>;

export type UserPaddleTransactionsQuery = {__typename?: "Query"} & {
    userPaddleTransactions?: Maybe<Array<{__typename?: "UserPaddleTransaction"} & UserPaddleTransactionsInfoFragment>>;
};

export type ChangeSubscriptionPlanMutationVariables = Exact<{
    planId: Scalars["String"];
}>;

export type ChangeSubscriptionPlanMutation = {__typename?: "Mutation"} & Pick<Mutation, "changeSubscriptionPlan">;

export type CreateSubscriptionModifierMutationVariables = Exact<{
    modifierType: SubscriptionModifierType;
    quantity: Scalars["Int"];
}>;

export type CreateSubscriptionModifierMutation = {__typename?: "Mutation"} & Pick<Mutation, "createSubscriptionModifier">;

export type OneSessionUsingResultFragment = {__typename?: "SessionsUsingResourceResult"} & Pick<
    SessionsUsingResourceResult,
    | "sessionId"
    | "sessionLifecycle"
    | "name"
    | "startAt"
    | "endedAt"
    | "ownerId"
    | "ownerFirstName"
    | "ownerLastName"
    | "ownerProfilePicture"
    | "recurrenceId"
    | "isEvent"
    | "isBooking"
>;

export type GetSessionsUsingResourceQueryVariables = Exact<{
    resourceId: Scalars["String"];
}>;

export type GetSessionsUsingResourceQuery = {__typename?: "Query"} & {
    getSessionsByResourceId: Array<{__typename?: "SessionsUsingResourceResult"} & OneSessionUsingResultFragment>;
};

export type GetSessionsUsingAgendaTemplateQueryVariables = Exact<{
    agendaTemplateId: Scalars["String"];
}>;

export type GetSessionsUsingAgendaTemplateQuery = {__typename?: "Query"} & {
    getSessionsByAgendaTemplateId: Array<{__typename?: "SessionsUsingResourceResult"} & OneSessionUsingResultFragment>;
};

export type ContactInfoWithoutGroupsFragment = {__typename?: "CustomContact"} & Pick<
    CustomContact,
    | "id"
    | "company"
    | "companyRole"
    | "email"
    | "firstName"
    | "lastName"
    | "favorite"
    | "source"
    | "externalSyncedEmail"
    | "userProfilePicture"
    | "userSuspended"
    | "inviteStatus"
>;

export type ContactInfoFragment = {__typename?: "CustomContact"} & {
    group: Array<{__typename?: "CustomContactGroup"} & Pick<CustomContactGroup, "id" | "name">>;
} & ContactInfoWithoutGroupsFragment;

export type ContactGroupInfoFragment = {__typename?: "CustomContactGroup"} & Pick<
    CustomContactGroup,
    "id" | "name" | "source" | "externalSyncedEmail"
>;

export type GetContactsAndGroupsQueryVariables = Exact<{
    includeSelf?: Maybe<Scalars["Boolean"]>;
}>;

export type GetContactsAndGroupsQuery = {__typename?: "Query"} & {
    contacts?: Maybe<Array<{__typename?: "CustomContact"} & ContactInfoFragment>>;
    contactGroups: Array<{__typename?: "CustomContactGroup"} & ContactGroupInfoFragment>;
    getGoogleSyncedEmails?: Maybe<Array<{__typename?: "GoogleSyncedEmailData"} & GoogleSyncedEmailFragment>>;
    getMicrosoftSyncedEmails?: Maybe<Array<{__typename?: "MicrosoftSyncedEmailData"} & MicrosoftSyncedEmailFragment>>;
};

export type GetContactsQueryVariables = Exact<{
    where: ContactWhereInput;
    includeSelf?: Maybe<Scalars["Boolean"]>;
}>;

export type GetContactsQuery = {__typename?: "Query"} & {contacts?: Maybe<Array<{__typename?: "CustomContact"} & ContactInfoFragment>>};

export type CreateGroupMutationVariables = Exact<{
    name: Scalars["String"];
    contactIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type CreateGroupMutation = {__typename?: "Mutation"} & {
    createOneContactGroup: {__typename?: "CustomContactGroup"} & ContactGroupInfoFragment;
};

export type UpdateGroupMutationVariables = Exact<{
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    addContactIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    removeContactIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type UpdateGroupMutation = {__typename?: "Mutation"} & {
    updateOneContactGroup: {__typename?: "CustomContactGroup"} & ContactGroupInfoFragment;
};

export type DeleteGroupMutationVariables = Exact<{
    groupId: Scalars["String"];
}>;

export type DeleteGroupMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteOneContactGroup">;

export type AddContactMutationVariables = Exact<{
    data: ContactCreateWithoutOwnerAndGroupInput;
}>;

export type AddContactMutation = {__typename?: "Mutation"} & {
    createOneContact?: Maybe<{__typename?: "CustomContact"} & ContactInfoFragment>;
};

export type UpdateContactMutationVariables = Exact<{
    where: WhereIdInput;
    data: ContactUpdateWithoutOwnerAndGroupInput;
}>;

export type UpdateContactMutation = {__typename?: "Mutation"} & {updateOneContact: {__typename?: "CustomContact"} & ContactInfoFragment};

export type MoveContactsToBinMutationVariables = Exact<{
    contactsIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type MoveContactsToBinMutation = {__typename?: "Mutation"} & Pick<Mutation, "addContactsToBin">;

export type RecoverContactsFromBinMutationVariables = Exact<{
    contactsIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type RecoverContactsFromBinMutation = {__typename?: "Mutation"} & Pick<Mutation, "recoverContactsFromBin">;

export type DeleteContactsFromBinMutationVariables = Exact<{
    contactsIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DeleteContactsFromBinMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteContactsFromBin">;

export type ImportCsvContactsMutationVariables = Exact<{
    contacts: Array<ContactCreateWithoutOwnerAndGroupInput> | ContactCreateWithoutOwnerAndGroupInput;
    groupId?: Maybe<Scalars["String"]>;
}>;

export type ImportCsvContactsMutation = {__typename?: "Mutation"} & {
    importCsvContacts?: Maybe<Array<{__typename?: "CustomContact"} & ContactInfoFragment>>;
};

export type ExportContactsToCsvMutationVariables = Exact<{
    contactsIds: Array<Scalars["String"]> | Scalars["String"];
    groupId?: Maybe<Scalars["String"]>;
}>;

export type ExportContactsToCsvMutation = {__typename?: "Mutation"} & Pick<Mutation, "exportContactsToCsv">;

export type OnGoogleContactsSyncFinishedSubscriptionVariables = Exact<{
    userEmail: Scalars["String"];
}>;

export type OnGoogleContactsSyncFinishedSubscription = {__typename?: "Subscription"} & {
    onGoogleContactsSyncFinished?: Maybe<
        {__typename?: "OnGoogleContactsSyncFinishedResult"} & Pick<OnGoogleContactsSyncFinishedResult, "userEmail">
    >;
};

export type OnMicrosoftContactsSyncFinishedSubscriptionVariables = Exact<{
    userEmail: Scalars["String"];
}>;

export type OnMicrosoftContactsSyncFinishedSubscription = {__typename?: "Subscription"} & {
    onMicrosoftContactsSyncFinished?: Maybe<
        {__typename?: "OnMicrosoftContactsSyncFinishedResult"} & Pick<OnMicrosoftContactsSyncFinishedResult, "userEmail">
    >;
};

export type RecurrenceDataNoDiffFragment = {__typename?: "RecurrenceData"} & Pick<
    RecurrenceData,
    | "id"
    | "dtStart"
    | "frequency"
    | "until"
    | "count"
    | "interval"
    | "byDay"
    | "byMonth"
    | "byMonthDay"
    | "bySetPos"
    | "exclude"
    | "wkst"
    | "createdAt"
    | "updatedAt"
> & {firstInstance?: Maybe<{__typename?: "Session"} & Pick<Session, "id">>};

export type DesignerSessionRecurrenceDataFragment = {__typename?: "RecurrenceData"} & Pick<RecurrenceData, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "RecurrenceDataDiff"} & RecurrenceDataDiffDataFragment>;
    } & RecurrenceDataNoDiffFragment;

export type ConvertingQueryVariables = Exact<{[key: string]: never}>;

export type ConvertingQuery = {__typename?: "Query"} & {
    converting: {__typename?: "Converting"} & {
        artifacts?: Maybe<
            Array<
                Maybe<{__typename?: "ArtifactsConversionJob"} & Pick<ArtifactsConversionJob, "artifactId" | "fileId" | "completed" | "url">>
            >
        >;
    };
};

export type SearchParticipantsByEmailsQueryVariables = Exact<{
    term: Array<Scalars["String"]> | Scalars["String"];
    take?: Maybe<Scalars["Int"]>;
}>;

export type SearchParticipantsByEmailsQuery = {__typename?: "Query"} & {
    searchParticipantsByEmails?: Maybe<
        Array<{__typename?: "ParticipantSearchResult"} & {data: {__typename?: "Participant"} & SessionParticipantFragment}>
    >;
};

export type GetSessionSettingsQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type GetSessionSettingsQuery = {__typename?: "Query"} & {
    sessionSettings: Array<{__typename?: "SessionSettings"} & SessionSettingsFragmentFragment>;
};

export type SearchOneSessionInfoFragment = {__typename?: "Session"} & Pick<
    Session,
    "id" | "name" | "startAt" | "plannedEnd" | "endedAt" | "updatedAt" | "description"
> & {
        macroArtifacts: Array<{__typename?: "Artifact"} & SearchOneArtifactInfoFragment>;
        participants: Array<{__typename?: "Participant"} & SearchParticipantInfoFragment>;
    } & SessionOrderFragment &
    OneSessionLifecycleFragment;

export type SearchOneArtifactInfoFragment = {__typename?: "Artifact"} & Pick<
    Artifact,
    "id" | "artifactId" | "name" | "description" | "startAt" | "endedAt" | "durationInSeconds" | "updatedAt" | "isMacro" | "order" | "data"
> &
    ArtifactPropsFragment;

export type SearchParticipantInfoFragment = {__typename?: "Participant"} & Pick<
    Participant,
    "id" | "status" | "conferenceStatus" | "isOwner" | "inviteStatus"
> & {
        dataWithNullableEmail: {__typename?: "ParticipantDataWithNullableEmail"} & Pick<
            ParticipantDataWithNullableEmail,
            "email" | "firstName" | "lastName" | "avatar" | "isGuest" | "token" | "userId"
        >;
        session: {__typename?: "Session"} & Pick<Session, "id">;
    };

export type SessionSettingsFragmentFragment = {__typename?: "SessionSettings"} & Pick<
    SessionSettings,
    | "id"
    | "sessionSource"
    | "requestPermissionToJoin"
    | "requireConsentToRecord"
    | "enableReactionsType"
    | "askForGuestEmail"
    | "allowCameraType"
    | "allowMicrophoneType"
    | "allowScreenShareType"
    | "hideNonStreamingParticipants"
    | "autoRecording"
    | "memoryAccessType"
    | "allowAgendaType"
    | "reminders"
    | "messageReminders"
    | "restrictedWidgets"
    | "autoTranscribing"
    | "disabledNotifications"
    | "autoStartSession"
    | "sendEndSessionEmail"
    | "fullSizeVideosInRecording"
    | "recordingConferenceView"
    | "recordingPresentationView"
    | "passcodeToJoin"
    | "provideSessionPasscode"
    | "bypassSessionPasscode"
    | "groupChatAccess"
    | "privateChatAccess"
    | "participantsMacroAccess"
    | "exitPageOfferId"
    | "collectFeedback"
    | "showExitPageCta"
    | "hideIcsGuestList"
    | "livestreamOutputUrls"
    | "recordingType"
    | "feedbackFormId"
>;

export type SaveSessionSettingsMutationVariables = Exact<{
    settingsFlags: SessionSettingsFlagsInput;
}>;

export type SaveSessionSettingsMutation = {__typename?: "Mutation"} & {
    saveSessionSettings?: Maybe<{__typename?: "SessionSettings"} & SessionSettingsFragmentFragment>;
};

export type NotificationsQueryVariables = Exact<{[key: string]: never}>;

export type NotificationsQuery = {__typename?: "Query"} & {
    notifications?: Maybe<Array<Maybe<{__typename?: "Notification"} & Pick<Notification, "id" | "title" | "body" | "cta" | "ctaUrl">>>>;
};

export type AllSessionsQueryVariables = Exact<{
    orderBy?: Maybe<Array<SessionOrderByInput> | SessionOrderByInput>;
    from?: Maybe<Scalars["DateTime"]>;
    until?: Maybe<Scalars["DateTime"]>;
    take?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    owner?: Maybe<Scalars["Boolean"]>;
    invited?: Maybe<Scalars["Boolean"]>;
    attended: Scalars["Boolean"];
    declined: Scalars["Boolean"];
    sessions: SessionFilterEnum;
    withRecording: Scalars["Boolean"];
    withoutRecording: Scalars["Boolean"];
    withSessionTags?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    participantEmails?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    lifecycle?: Maybe<Array<SessionLifecycle> | SessionLifecycle>;
    participantInviteStatuses?: Maybe<Array<ParticipantInviteStatus> | ParticipantInviteStatus>;
    sortByParticipants?: Maybe<SortOrder>;
    sortByParticipantStatus?: Maybe<SortOrder>;
    sortByRecording?: Maybe<SortOrder>;
    sortByDuration?: Maybe<SortOrder>;
    sortBySessionTags?: Maybe<SortOrder>;
    searchTerm?: Maybe<Scalars["String"]>;
    workspaceId?: Maybe<Scalars["String"]>;
}>;

export type AllSessionsQuery = {__typename?: "Query"} & {
    userSessions?: Maybe<
        {__typename?: "UserSessionsResult"} & Pick<UserSessionsResult, "totalCount" | "hasSearchTerm"> & {
                sessions?: Maybe<Array<{__typename?: "Session"} & MemoryListSessionFragment>>;
            }
    >;
};

export type GetMemorySessionQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetMemorySessionQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & {
            childOfBreakoutRooms?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
            recordings: Array<{__typename?: "SessionRecording"} & MemorySessionRecordingFragment>;
        } & OneSessionFragment
    >;
};

export type LocalMemoryParticipantsQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type LocalMemoryParticipantsQuery = {__typename?: "Query"} & {
    participants: Array<{__typename?: "Participant"} & MemorySessionParticipantFragment>;
};

export type LocalMemoryParticipantQueryVariables = Exact<{
    participantId: Scalars["String"];
}>;

export type LocalMemoryParticipantQuery = {__typename?: "Query"} & {
    participants: Array<{__typename?: "Participant"} & MemorySessionParticipantFragment>;
};

export type FullMemorySessionQueryVariables = Exact<{
    id: Scalars["String"];
    checkPlan?: Maybe<Scalars["Boolean"]>;
    includeRegistrationAnswers?: Maybe<Scalars["Boolean"]>;
}>;

export type FullMemorySessionQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & {
            childOfBreakoutRooms?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
            recordings: Array<{__typename?: "SessionRecording"} & MemorySessionRecordingFragment>;
        } & OneSessionFragment
    >;
    macroArtifacts: Array<{__typename?: "Artifact"} & OneMacroArtifactFragment>;
    agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>;
    participants: Array<{__typename?: "Participant"} & MemorySessionParticipantFragment>;
    currentParticipant: {__typename?: "Participant"} & CurrentParticipantDataFragment;
};

export type GetFeedbackSessionQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetFeedbackSessionQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & Pick<
            Session,
            | "startAt"
            | "lifecycle"
            | "name"
            | "organizationPlan"
            | "collectFeedback"
            | "showExitPageCta"
            | "feedbackFormAnswers"
            | "workspaceId"
        > & {__id: Session["id"]} & {
                exitPageOffer?: Maybe<{__typename?: "Offer"} & OfferFragment>;
                currentParticipant: {__typename?: "Participant"} & Pick<Participant, "id" | "isOwner">;
                feedbackForm?: Maybe<{__typename?: "FeedbackForm"} & FeedbackFormFragment>;
            }
    >;
};

export type GetNullableCurrentParticipantIdQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetNullableCurrentParticipantIdQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & {__id: Session["id"]} & {
            nullableCurrentParticipant?: Maybe<{__typename?: "Participant"} & Pick<Participant, "id">>;
        }
    >;
};

export type GetGuestInviteSessionQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetGuestInviteSessionQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & Pick<
            Session,
            "id" | "name" | "description" | "startAt" | "plannedEnd" | "organizationPlan" | "ownerHalfDayClock" | "ownerLanguage"
        > &
            LobbyPictureImageDetailsFragment
    >;
};

export type GetSessionMessagesCountQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetSessionMessagesCountQuery = {__typename?: "Query"} & Pick<Query, "getSessionMessagesCount">;

export type MemoryListSessionFragment = {__typename?: "Session"} & Pick<
    Session,
    | "id"
    | "name"
    | "actualStart"
    | "startAt"
    | "plannedEnd"
    | "lifecycle"
    | "endedAt"
    | "isBooking"
    | "quickSession"
    | "memoryAccessType"
    | "allowAgendaType"
    | "hideNonStreamingParticipants"
    | "autoRecording"
    | "recordingType"
    | "restrictedWidgets"
    | "collectFeedback"
    | "showExitPageCta"
> & {
        sessionTags: Array<{__typename?: "SessionTag"} & Pick<SessionTag, "id" | "name">>;
        currentParticipant: {__typename?: "Participant"} & Pick<
            Participant,
            "lastSeenTimestamp" | "hasAttendedSession" | "inviteStatus" | "isOwner" | "rbac"
        > & {pid: Participant["id"]};
        participants: Array<{__typename?: "Participant"} & MemoryListSessionParticipantFragment>;
        recordings: Array<{__typename?: "SessionRecording"} & MemorySessionRecordingFragment>;
        event?: Maybe<{__typename?: "SessionEvent"} & Pick<SessionEvent, "id">>;
        room?: Maybe<{__typename?: "Room"} & Pick<Room, "id" | "slug">>;
        workspace: {__typename?: "Workspace"} & Pick<Workspace, "id">;
        recurrenceData?: Maybe<{__typename?: "RecurrenceData"} & Pick<RecurrenceData, "id">>;
    };

export type MemorySessionRecordingFragment = {__typename?: "SessionRecording"} & Pick<
    SessionRecording,
    "id" | "type" | "livestreamOutputUrls" | "permission" | "version" | "processingStatus" | "startedAt" | "downloadUrl" | "state" | "name"
>;

export type MemorySessionParticipantFragment = {__typename?: "Participant"} & Pick<Participant, "presenceHistory"> &
    SessionParticipantFragment;

export type MemoryListSessionParticipantFragment = {__typename?: "Participant"} & Pick<Participant, "id" | "lastSeenTimestamp">;

export type CapturePayPalOrderMutationVariables = Exact<{
    paymentId: Scalars["String"];
    orderId: Scalars["String"];
}>;

export type CapturePayPalOrderMutation = {__typename?: "Mutation"} & {
    capturePaypalPayment?: Maybe<{__typename?: "PaypalPayment"} & Pick<PaypalPayment, "paymentId" | "paymentStatus" | "redirectUrl">>;
};

export type CancelPayPalOrderMutationVariables = Exact<{
    paymentId: Scalars["String"];
    orderId: Scalars["String"];
}>;

export type CancelPayPalOrderMutation = {__typename?: "Mutation"} & {
    cancelPaypalPayment?: Maybe<{__typename?: "PaypalPayment"} & Pick<PaypalPayment, "paymentId" | "paymentStatus" | "redirectUrl">>;
};

export type SetSessionTimerMutationVariables = Exact<{
    sessionId: Scalars["String"];
    state: SessionTimerState;
    initialDuration?: Maybe<Scalars["Int"]>;
    currentDuration?: Maybe<Scalars["Int"]>;
}>;

export type SetSessionTimerMutation = {__typename?: "Mutation"} & Pick<Mutation, "setSessionTimer">;

export type RoomUserFragment = {__typename?: "User"} & Pick<User, "id" | "email" | "firstName" | "lastName" | "profilePicture">;

export type RoomSummaryFragment = {__typename?: "Session"} & Pick<
    Session,
    | "id"
    | "name"
    | "description"
    | "source"
    | "timeDependency"
    | "actualStart"
    | "endedAt"
    | "createdAt"
    | "updatedAt"
    | "endByWorker"
    | "createdFromTemplateId"
    | "timeZone"
    | "generatedName"
    | "byTimezoneStartAt"
    | "byTimezonePlannedEnd"
> &
    SessionPlannedStartFragment &
    SessionPlannedEndFragment &
    OneSessionLifecycleFragment;

export type RoomDataFragment = {__typename?: "Room"} & Pick<Room, "id" | "active" | "slug" | "createdAt"> & {
        activeSessionInstance?: Maybe<{__typename?: "Session"} & Pick<Session, "id" | "actualStart" | "lifecycle">>;
        sessionTemplate: {__typename?: "Session"} & RoomSummaryFragment;
        user: {__typename?: "User"} & RoomUserFragment;
    };

export type MyRoomsQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type MyRoomsQuery = {__typename?: "Query"} & {rooms: Array<{__typename?: "Room"} & RoomDataFragment>};

export type DeleteRoomMutationVariables = Exact<{
    roomId: Scalars["String"];
    reason?: Maybe<Scalars["String"]>;
}>;

export type DeleteRoomMutation = {__typename?: "Mutation"} & {
    deleteRoom?: Maybe<{__typename?: "DeleteRoomResult"} & Pick<DeleteRoomResult, "deleted">>;
};

export type CloneRoomMutationVariables = Exact<{
    roomId: Scalars["String"];
}>;

export type CloneRoomMutation = {__typename?: "Mutation"} & {cloneRoom?: Maybe<{__typename?: "Room"} & RoomDataFragment>};

export type ToggleRoomActiveStateMutationVariables = Exact<{
    roomId: Scalars["String"];
    active?: Maybe<Scalars["Boolean"]>;
}>;

export type ToggleRoomActiveStateMutation = {__typename?: "Mutation"} & {
    toggleRoomActiveState?: Maybe<{__typename?: "ToogleRoomResult"} & Pick<ToogleRoomResult, "active">>;
};

export type ShareRoomMutationVariables = Exact<{
    roomId: Scalars["String"];
    shareWithWorkspaceInput: RoomShareWorkspaceInput;
    userEmails?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ShareRoomMutation = {__typename?: "Mutation"} & {shareRoom: {__typename?: "Room"} & RoomWithSessionInstancesFragment};

export type IsRoomSessionInstanceEmptyQueryVariables = Exact<{
    roomId: Scalars["String"];
}>;

export type IsRoomSessionInstanceEmptyQuery = {__typename?: "Query"} & {
    isRoomSessionInstanceEmpty?: Maybe<{__typename?: "RoomIsEmptyResult"} & Pick<RoomIsEmptyResult, "isEmpty">>;
};

export type RoomSessionInstanceFragment = {__typename?: "Session"} & Pick<
    Session,
    "id" | "name" | "description" | "startAt" | "actualStart" | "endedAt" | "lifecycle"
>;

export type RoomWithSessionInstancesFragment = {__typename?: "Room"} & Pick<Room, "id" | "slug" | "active"> & {
        sessionInstances: Array<{__typename?: "Session"} & RoomSessionInstanceFragment>;
        sessionTemplate: {__typename?: "Session"} & Pick<
            Session,
            "id" | "name" | "description" | "createdAt" | "autoRecording" | "autoTranscribing" | "requestPermissionToJoin"
        >;
        user: {__typename?: "User"} & RoomUserFragment;
        sharedWithWorkspaces: Array<{__typename?: "Workspace"} & Pick<Workspace, "id">>;
        sharedWithUsers: Array<{__typename?: "User"} & RoomUserFragment>;
    };

export type RoomInstancesQueryVariables = Exact<{
    roomId: Scalars["String"];
}>;

export type RoomInstancesQuery = {__typename?: "Query"} & {room?: Maybe<{__typename?: "Room"} & RoomWithSessionInstancesFragment>};

export type IsRoomSlugUniqQueryVariables = Exact<{
    roomSlug: Scalars["String"];
}>;

export type IsRoomSlugUniqQuery = {__typename?: "Query"} & {
    checkIfRoomSlugUniq?: Maybe<{__typename?: "RoomSlugUniqResult"} & Pick<RoomSlugUniqResult, "isUnique">>;
};

export type AgendaTemplateOwnerFragment = {__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName" | "profilePicture">;

export type AgendaTemplateFragment = {__typename?: "AgendaTemplate"} & Pick<
    AgendaTemplate,
    "id" | "name" | "updatedAt" | "duration" | "isPublic" | "workspaceId" | "hasEditorAccess"
> & {user: {__typename?: "User"} & AgendaTemplateOwnerFragment};

export type AgendaTemplateBasicInfoFragment = {__typename?: "AgendaTemplate"} & Pick<
    AgendaTemplate,
    "id" | "name" | "description" | "updatedAt"
>;

export type FullAgendaTemplateFragment = {__typename?: "AgendaTemplate"} & Pick<
    AgendaTemplate,
    "description" | "createdAt" | "hasEditorAccess" | "userId"
> & {
        agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>;
        accessList: Array<{__typename?: "AccessListItem"} & Pick<AccessListItem, "id">>;
    } & AgendaTemplateFragment &
    AgendaTemplatePermissionsFragment;

export type AgendaTemplatePermissionsFragment = {__typename?: "AgendaTemplate"} & Pick<AgendaTemplate, "id" | "isPublic" | "isReadOnly">;

export type AgendaTemplateQueryVariables = Exact<{
    where: WhereIdInput;
}>;

export type AgendaTemplateQuery = {__typename?: "Query"} & {
    agendaTemplate?: Maybe<{__typename?: "AgendaTemplate"} & FullAgendaTemplateFragment>;
};

export type GetAgendaTemplateForEditingQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetAgendaTemplateForEditingQuery = {__typename?: "Query"} & {
    agendaTemplate?: Maybe<{__typename?: "AgendaTemplate"} & FullAgendaTemplateFragment>;
};

export type CommitAgendaTemplateMutationVariables = Exact<{
    newAgendaTemplate: Scalars["Json"];
}>;

export type CommitAgendaTemplateMutation = {__typename?: "Mutation"} & {
    commitAgendaTemplate: {__typename?: "AgendaTemplate"} & FullAgendaTemplateFragment;
};

export type DeleteAgendaTemplateMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteAgendaTemplateMutation = {__typename?: "Mutation"} & {
    deleteOneAgendaTemplate?: Maybe<{__typename?: "AgendaTemplate"} & Pick<AgendaTemplate, "id">>;
};

export type GenerateTemplateNameQueryVariables = Exact<{
    name: Scalars["String"];
}>;

export type GenerateTemplateNameQuery = {__typename?: "Query"} & Pick<Query, "generateTemplateName">;

export type GetAgendaTemplatesQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type GetAgendaTemplatesQuery = {__typename?: "Query"} & {
    agendaTemplates: Array<{__typename?: "AgendaTemplate"} & AgendaTemplateFragment>;
};

export type UpdateOneAgendaTemplateMutationVariables = Exact<{
    where: WhereIdInput;
    data: AgendaTemplateUpdateInput;
}>;

export type UpdateOneAgendaTemplateMutation = {__typename?: "Mutation"} & {
    updateOneAgendaTemplate?: Maybe<{__typename?: "AgendaTemplate"} & FullAgendaTemplateFragment>;
};

export type CreateOneAgendaTemplateMutationVariables = Exact<{
    data: AgendaTemplateCreateInput;
}>;

export type CreateOneAgendaTemplateMutation = {__typename?: "Mutation"} & {
    createOneAgendaTemplate?: Maybe<{__typename?: "AgendaTemplate"} & FullAgendaTemplateFragment>;
};

export type WatchdogAgendaTemplateSubscriptionVariables = Exact<{
    agendaTemplateId: Scalars["String"];
}>;

export type WatchdogAgendaTemplateSubscription = {__typename?: "Subscription"} & {
    watchdogAgendaTemplate?: Maybe<
        {__typename?: "AgendaTemplateWatchdogResult"} & Pick<
            AgendaTemplateWatchdogResult,
            "id" | "deletedAgendaTemplate" | "deletedAgendaItemIds"
        > & {
                agendaTemplate?: Maybe<{__typename?: "AgendaTemplate"} & AgendaTemplateBasicInfoFragment>;
                upsertAgendaItems?: Maybe<Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>>;
            }
    >;
};

export type GetSyncedEmailsQueryVariables = Exact<{[key: string]: never}>;

export type GetSyncedEmailsQuery = {__typename?: "Query"} & {
    getGoogleSyncedEmails?: Maybe<Array<{__typename?: "GoogleSyncedEmailData"} & GoogleSyncedEmailFragment>>;
    getMicrosoftSyncedEmails?: Maybe<Array<{__typename?: "MicrosoftSyncedEmailData"} & MicrosoftSyncedEmailFragment>>;
    getAppleSyncedEmails?: Maybe<Array<{__typename?: "AppleSyncedEmailData"} & AppleSyncedEmailFragment>>;
};

export type GetAllSyncedAccountsQueryVariables = Exact<{[key: string]: never}>;

export type GetAllSyncedAccountsQuery = {__typename?: "Query"} & Pick<Query, "isNotionSynced" | "isEvernoteSynced" | "isHubspotSynced"> & {
        getGoogleSyncedEmails?: Maybe<Array<{__typename?: "GoogleSyncedEmailData"} & GoogleSyncedEmailFragment>>;
        getMicrosoftSyncedEmails?: Maybe<Array<{__typename?: "MicrosoftSyncedEmailData"} & MicrosoftSyncedEmailFragment>>;
        getAppleSyncedEmails?: Maybe<Array<{__typename?: "AppleSyncedEmailData"} & AppleSyncedEmailFragment>>;
        getNotionParentPages?: Maybe<Array<{__typename?: "NotionParentPage"} & NotionParentPageFragment>>;
        zapierKey?: Maybe<{__typename?: "PublicApiKey"} & Pick<PublicApiKey, "id" | "tokenPreview">>;
        getStripeSyncedAccount?: Maybe<{__typename?: "StripeSyncedAccount"} & StripeSyncedAccountFragment>;
        getPayPalSyncedAccount?: Maybe<{__typename?: "PayPalSyncedAccount"} & PayPalSyncedAccountFragment>;
    };

export type AppleSyncedEmailFragment = {__typename?: "AppleSyncedEmailData"} & Pick<
    AppleSyncedEmailData,
    "email" | "calendarProviderId"
> & {calendars?: Maybe<Array<{__typename?: "ExternalCalendar"} & Pick<ExternalCalendar, "id" | "name" | "isPrimary" | "pushEvents">>>};

export type UpdateAppleExternalProviderMutationVariables = Exact<{
    providerId: Scalars["String"];
    syncCalendarId?: Maybe<Scalars["String"]>;
}>;

export type UpdateAppleExternalProviderMutation = {__typename?: "Mutation"} & Pick<Mutation, "updateAppleExternalProvider">;

export type UpdateExternalProviderMutationVariables = Exact<{
    providerId: Scalars["String"];
    syncCalendarId?: Maybe<Scalars["String"]>;
}>;

export type UpdateExternalProviderMutation = {__typename?: "Mutation"} & Pick<Mutation, "updateExternalProvider">;

export type DeleteUserGoogleProvidersMutationVariables = Exact<{
    email: Scalars["String"];
    provider?: Maybe<Scalars["String"]>;
}>;

export type DeleteUserGoogleProvidersMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteUserGoogleProviders">;

export type SaveGoogleTokenMutationVariables = Exact<{
    code: Scalars["String"];
    redirectUri?: Maybe<Scalars["String"]>;
}>;

export type SaveGoogleTokenMutation = {__typename?: "Mutation"} & {
    saveGoogleToken?: Maybe<{__typename?: "SaveGoogleTokenResponse"} & Pick<SaveGoogleTokenResponse, "email">>;
};

export type GoogleSyncedEmailFragment = {__typename?: "GoogleSyncedEmailData"} & Pick<
    GoogleSyncedEmailData,
    "email" | "calendarProviderId"
> & {
        permissions: Array<
            {__typename?: "GoogleSyncedEmailGrantedPermission"} & Pick<GoogleSyncedEmailGrantedPermission, "permission" | "granted">
        >;
        calendars?: Maybe<Array<{__typename?: "ExternalCalendar"} & Pick<ExternalCalendar, "id" | "name" | "isPrimary" | "pushEvents">>>;
    };

export type SaveNotionTokenMutationVariables = Exact<{
    code: Scalars["String"];
}>;

export type SaveNotionTokenMutation = {__typename?: "Mutation"} & Pick<Mutation, "saveNotionToken">;

export type SaveNotionNoteMutationVariables = Exact<{
    title?: Maybe<Scalars["String"]>;
    note: Scalars["String"];
}>;

export type SaveNotionNoteMutation = {__typename?: "Mutation"} & Pick<Mutation, "saveNotionNote">;

export type SetNotionDefaultParentPageMutationVariables = Exact<{
    parentPageId: Scalars["String"];
}>;

export type SetNotionDefaultParentPageMutation = {__typename?: "Mutation"} & Pick<Mutation, "setNotionDefaultParentPage">;

export type DeleteUserNotionProvidersMutationVariables = Exact<{[key: string]: never}>;

export type DeleteUserNotionProvidersMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteUserNotionProviders">;

export type NotionParentPageFragment = {__typename?: "NotionParentPage"} & Pick<NotionParentPage, "id" | "title" | "default">;

export type GetEvernoteAuthUrlQueryVariables = Exact<{[key: string]: never}>;

export type GetEvernoteAuthUrlQuery = {__typename?: "Query"} & Pick<Query, "getEvernoteAuthUrl">;

export type SaveEvernoteTokenMutationVariables = Exact<{
    data: Scalars["String"];
}>;

export type SaveEvernoteTokenMutation = {__typename?: "Mutation"} & Pick<Mutation, "saveEvernoteToken">;

export type SaveEvernoteNoteMutationVariables = Exact<{
    title: Scalars["String"];
    noteJson: Scalars["Json"];
}>;

export type SaveEvernoteNoteMutation = {__typename?: "Mutation"} & Pick<Mutation, "saveEvernoteNote">;

export type DeleteUserEvernoteProvidersMutationVariables = Exact<{[key: string]: never}>;

export type DeleteUserEvernoteProvidersMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteUserEvernoteProviders">;

export type MicrosoftSyncedEmailFragment = {__typename?: "MicrosoftSyncedEmailData"} & Pick<
    MicrosoftSyncedEmailData,
    "email" | "calendarProviderId"
> & {
        permissions: Array<
            {__typename?: "MicrosoftSyncedEmailGrantedPermission"} & Pick<MicrosoftSyncedEmailGrantedPermission, "permission" | "granted">
        >;
        calendars?: Maybe<Array<{__typename?: "ExternalCalendar"} & Pick<ExternalCalendar, "id" | "name" | "isPrimary" | "pushEvents">>>;
    };

export type SaveMicrosoftTokenMutationVariables = Exact<{
    code: Scalars["String"];
    scopes: Scalars["String"];
}>;

export type SaveMicrosoftTokenMutation = {__typename?: "Mutation"} & {
    saveMicrosoftToken?: Maybe<{__typename?: "SaveMicrosoftTokenResponse"} & Pick<SaveMicrosoftTokenResponse, "email">>;
};

export type GetHubspotDetailsQueryVariables = Exact<{
    email: Scalars["String"];
    token: Scalars["String"];
}>;

export type GetHubspotDetailsQuery = {__typename?: "Query"} & {
    getHubspotDetails?: Maybe<{__typename?: "HubspotContact"} & Pick<HubspotContact, "email" | "firstName" | "lastName" | "status">>;
};

export type GetHubspotAuthUrlQueryVariables = Exact<{[key: string]: never}>;

export type GetHubspotAuthUrlQuery = {__typename?: "Query"} & Pick<Query, "getHubspotAuthUrl">;

export type SaveHubspotTokenMutationVariables = Exact<{
    data: Scalars["String"];
}>;

export type SaveHubspotTokenMutation = {__typename?: "Mutation"} & Pick<Mutation, "saveHubspotToken">;

export type DeleteUserHubspotProvidersMutationVariables = Exact<{[key: string]: never}>;

export type DeleteUserHubspotProvidersMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteUserHubspotProviders">;

export type StripeSyncedAccountFragment = {__typename?: "StripeSyncedAccount"} & Pick<
    StripeSyncedAccount,
    "providerId" | "accountId" | "email" | "name"
>;

export type GetStripeOAuthUrlQueryVariables = Exact<{[key: string]: never}>;

export type GetStripeOAuthUrlQuery = {__typename?: "Query"} & Pick<Query, "getStripeOAuthUrl">;

export type SaveStripeTokenMutationVariables = Exact<{
    code: Scalars["String"];
}>;

export type SaveStripeTokenMutation = {__typename?: "Mutation"} & Pick<Mutation, "saveStripeToken">;

export type DeleteStripeProviderMutationVariables = Exact<{[key: string]: never}>;

export type DeleteStripeProviderMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteStripeAccount">;

export type PayPalSyncedAccountFragment = {__typename?: "PayPalSyncedAccount"} & Pick<PayPalSyncedAccount, "providerId" | "merchantId">;

export type GetPayPalIntegrationUrlQueryVariables = Exact<{[key: string]: never}>;

export type GetPayPalIntegrationUrlQuery = {__typename?: "Query"} & Pick<Query, "getPaypalIntegrationUrl">;

export type SavePaypalAccountMutationVariables = Exact<{
    id: Scalars["String"];
    merchantId: Scalars["String"];
    permissionsGranted?: Maybe<Scalars["String"]>;
    accountStatus?: Maybe<Scalars["String"]>;
    consentStatus?: Maybe<Scalars["String"]>;
    productIntentId?: Maybe<Scalars["String"]>;
    isEmailConfirmed?: Maybe<Scalars["String"]>;
    riskStatus?: Maybe<Scalars["String"]>;
}>;

export type SavePaypalAccountMutation = {__typename?: "Mutation"} & Pick<Mutation, "savePaypalAccount">;

export type DeletePayPalProviderMutationVariables = Exact<{[key: string]: never}>;

export type DeletePayPalProviderMutation = {__typename?: "Mutation"} & Pick<Mutation, "deletePaypalAccount">;

export type FeedbackFormFragment = {__typename?: "FeedbackForm"} & Pick<
    FeedbackForm,
    "id" | "title" | "description" | "questions" | "createdAt" | "updatedAt" | "userId"
>;

export type GetFeedbackFormsQueryVariables = Exact<{[key: string]: never}>;

export type GetFeedbackFormsQuery = {__typename?: "Query"} & {
    userFeedbackForms: Array<{__typename?: "FeedbackForm"} & FeedbackFormFragment>;
};

export type GetFeedbackFormQueryVariables = Exact<{
    feedbackFormId: Scalars["String"];
}>;

export type GetFeedbackFormQuery = {__typename?: "Query"} & {feedbackForm?: Maybe<{__typename?: "FeedbackForm"} & FeedbackFormFragment>};

export type GetFeedbackFormsCountQueryVariables = Exact<{
    feedbackFormId: Scalars["String"];
}>;

export type GetFeedbackFormsCountQuery = {__typename?: "Query"} & {
    feedbackFormCount?: Maybe<
        {__typename?: "FeedbackFormsCount"} & {
            settingsWithForm?: Maybe<{__typename?: "ListAndCount"} & Pick<ListAndCount, "list" | "count">>;
            sessionsWithForm?: Maybe<{__typename?: "ListAndCount"} & Pick<ListAndCount, "list" | "count">>;
            bookingsWithForm?: Maybe<{__typename?: "ListAndCount"} & Pick<ListAndCount, "list" | "count">>;
            eventsWithForm?: Maybe<{__typename?: "ListAndCount"} & Pick<ListAndCount, "list" | "count">>;
        }
    >;
};

export type CreateFeedbackFormMutationVariables = Exact<{
    feedbackForm: FeedbackFormInput;
}>;

export type CreateFeedbackFormMutation = {__typename?: "Mutation"} & {
    createFeedbackForm?: Maybe<{__typename?: "FeedbackForm"} & FeedbackFormFragment>;
};

export type DeleteFeedbackFormMutationVariables = Exact<{
    feedbackFormId: Scalars["String"];
}>;

export type DeleteFeedbackFormMutation = {__typename?: "Mutation"} & {
    deleteFeedbackForm?: Maybe<{__typename?: "FeedbackForm"} & FeedbackFormFragment>;
};

export type UpdateFeedbackFormMutationVariables = Exact<{
    feedbackFormId: Scalars["String"];
    feedbackForm: FeedbackFormInput;
}>;

export type UpdateFeedbackFormMutation = {__typename?: "Mutation"} & {
    updateFeedbackForm?: Maybe<{__typename?: "FeedbackForm"} & FeedbackFormFragment>;
};

export type UpdateFeedbackFormAnswersMutationVariables = Exact<{
    sessionId: Scalars["String"];
    answers: Scalars["Json"];
}>;

export type UpdateFeedbackFormAnswersMutation = {__typename?: "Mutation"} & {
    updateFeedbackFormAnswers?: Maybe<{__typename?: "Session"} & OneSessionFragment>;
};

export type ProductToolFragment = {__typename?: "MyProductTool"} & Pick<
    MyProductTool,
    "id" | "description" | "iconUrl" | "url" | "enabled" | "title" | "consentOwnershipOfIcon" | "ownerTimezone"
>;

export type MyProductToolsQueryVariables = Exact<{
    sessionIdForOwnerTimezone?: Maybe<Scalars["String"]>;
    workspaceId: Scalars["String"];
}>;

export type MyProductToolsQuery = {__typename?: "Query"} & {myProductTools: Array<{__typename?: "MyProductTool"} & ProductToolFragment>};

export type GetMyProductToolIconUploadUrlMutationVariables = Exact<{
    photoSuffix: Scalars["String"];
    myProductToolId: Scalars["String"];
}>;

export type GetMyProductToolIconUploadUrlMutation = {__typename?: "Mutation"} & Pick<Mutation, "getMyProductToolIconUploadUrl">;

export type ConfirmMyProductToolIconUploadMutationVariables = Exact<{
    photoSuffix: Scalars["String"];
    myProductToolId: Scalars["String"];
    sessionIdForOwnerTimezone?: Maybe<Scalars["String"]>;
}>;

export type ConfirmMyProductToolIconUploadMutation = {__typename?: "Mutation"} & {
    confirmMyProductToolIconUpload: {__typename?: "MyProductTool"} & ProductToolFragment;
};

export type UpsertOneMyProductToolMutationVariables = Exact<{
    update: MyProductToolUpdateInput;
    create: MyProductToolCreateInput;
    where: WhereIdInput;
    sessionIdForOwnerTimezone?: Maybe<Scalars["String"]>;
}>;

export type UpsertOneMyProductToolMutation = {__typename?: "Mutation"} & {
    upsertOneMyProductTool?: Maybe<{__typename?: "MyProductTool"} & ProductToolFragment>;
};

export type DeleteOneMyProductToolMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneMyProductToolMutation = {__typename?: "Mutation"} & {
    deleteOneMyProductTool?: Maybe<{__typename?: "MyProductTool"} & Pick<MyProductTool, "id">>;
};

export type BrandingInfoFragment = {__typename?: "Branding"} & Pick<
    Branding,
    | "id"
    | "palette"
    | "sourcePalette"
    | "logo"
    | "sessionBackground"
    | "styleTag"
    | "active"
    | "logomark"
    | "navbarTheme"
    | "videoTileColor"
>;

export type BrandingQueryVariables = Exact<{
    where: BrandingWhereInput;
}>;

export type BrandingQuery = {__typename?: "Query"} & {branding?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>};

export type SessionBrandingQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type SessionBrandingQuery = {__typename?: "Query"} & {sessionBranding?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>};

export type SessionBrandingFromTokenQueryVariables = Exact<{
    where: SessionBrandingFromTokenWhereInput;
}>;

export type SessionBrandingFromTokenQuery = {__typename?: "Query"} & {
    sessionBrandingFromToken?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>;
};

export type CreateOneBrandingMutationVariables = Exact<{
    data: BrandingCreateInput;
}>;

export type CreateOneBrandingMutation = {__typename?: "Mutation"} & {
    createOneBranding?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>;
};

export type DeleteOneBrandingMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneBrandingMutation = {__typename?: "Mutation"} & {
    deleteOneBranding?: Maybe<{__typename?: "Branding"} & Pick<Branding, "id">>;
};

export type UploadBrandingAssetMutationVariables = Exact<{
    cacheBuster: Scalars["String"];
}>;

export type UploadBrandingAssetMutation = {__typename?: "Mutation"} & {
    uploadBrandingAsset?: Maybe<{__typename?: "BrandingAsset"} & Pick<BrandingAsset, "uploadUrl" | "publicUrl">>;
};

export type ConfirmUploadBrandingLogoMutationVariables = Exact<{
    cacheBuster: Scalars["String"];
}>;

export type ConfirmUploadBrandingLogoMutation = {__typename?: "Mutation"} & Pick<Mutation, "confirmUploadBrandingLogo">;

export type UpdateOneBrandingMutationVariables = Exact<{
    data: BrandingUpdateInput;
}>;

export type UpdateOneBrandingMutation = {__typename?: "Mutation"} & {
    updateOneBranding?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>;
};

export type ConfigCsdMutationVariables = Exact<{
    domain: Scalars["String"];
}>;

export type ConfigCsdMutation = {__typename?: "Mutation"} & {
    configureCsd?: Maybe<{__typename?: "CustomSubDomainConfig"} & Pick<CustomSubDomainConfig, "id" | "fqdn" | "domain" | "root">>;
};

export type CleanupCsdMutationVariables = Exact<{[key: string]: never}>;

export type CleanupCsdMutation = {__typename?: "Mutation"} & Pick<Mutation, "cleanupCsd">;

export type GetUserHubspotPropertiesQueryVariables = Exact<{[key: string]: never}>;

export type GetUserHubspotPropertiesQuery = {__typename?: "Query"} & Pick<Query, "getUserHubspotProperties">;

export type GetUserHubspotEventsQueryVariables = Exact<{[key: string]: never}>;

export type GetUserHubspotEventsQuery = {__typename?: "Query"} & Pick<Query, "getUserHubspotEvents">;

export type MapUserHubspotPropertiesMutationVariables = Exact<{
    mappings?: Maybe<Array<UserHubspotSessionsPropertyMapping> | UserHubspotSessionsPropertyMapping>;
    config?: Maybe<UserHubspotEventConfig>;
}>;

export type MapUserHubspotPropertiesMutation = {__typename?: "Mutation"} & Pick<Mutation, "mapUserHubspotProperties">;

export type GetCountRecentlyJoinedUsersQueryVariables = Exact<{
    since?: Maybe<Scalars["DateTime"]>;
}>;

export type GetCountRecentlyJoinedUsersQuery = {__typename?: "Query"} & Pick<Query, "countRecentlyJoinedUsers">;

export type MemberFragment = {__typename?: "User"} & Pick<
    User,
    "id" | "email" | "firstName" | "lastName" | "profilePicture" | "country" | "department" | "createdAt" | "lastLoginDate"
> & {
        organizationPermissions: Array<
            {__typename?: "UserToOrganizationPermission"} & Pick<
                UserToOrganizationPermission,
                "id" | "permissionType" | "suspended" | "inviteStatus" | "organizationId"
            >
        >;
        workspacePermissions: Array<
            {__typename?: "UserToWorkspacePermission"} & Pick<UserToWorkspacePermission, "id" | "inviteStatus"> & {
                    role: {__typename?: "WorkspaceRole"} & WorkspaceRoleInfoFragment;
                    workspace: {__typename?: "Workspace"} & Pick<Workspace, "id" | "name">;
                }
        >;
    };

export type GetOrganizationMembersQueryVariables = Exact<{
    organizationId: Scalars["String"];
    excludeSelf?: Maybe<Scalars["Boolean"]>;
}>;

export type GetOrganizationMembersQuery = {__typename?: "Query"} & {
    organization?: Maybe<
        {__typename?: "Organization"} & Pick<Organization, "id"> & {members: Array<{__typename?: "User"} & MemberFragment>}
    >;
};

export type GetWorkspaceMembersQueryVariables = Exact<{
    workspaceId: Scalars["String"];
    excludeSelf?: Maybe<Scalars["Boolean"]>;
}>;

export type GetWorkspaceMembersQuery = {__typename?: "Query"} & {
    workspace?: Maybe<{__typename?: "Workspace"} & Pick<Workspace, "id"> & {members: Array<{__typename?: "User"} & MemberFragment>}>;
};

export type AddOrganizationMemberMutationVariables = Exact<{
    memberToAdd: AddMemberToOrganizationPayload;
}>;

export type AddOrganizationMemberMutation = {__typename?: "Mutation"} & {
    addMemberToOrganization: {__typename?: "AddMemberToOrganizationResponse"} & Pick<AddMemberToOrganizationResponse, "duplicatedEmail"> & {
            newMember?: Maybe<{__typename?: "User"} & MemberFragment>;
        };
};

export type UpsertOrganizationMembersMutationVariables = Exact<{
    membersToUpsert: Array<UpsertMembersOfOrganizationPayload> | UpsertMembersOfOrganizationPayload;
    sendInviteEmail: Scalars["Boolean"];
}>;

export type UpsertOrganizationMembersMutation = {__typename?: "Mutation"} & {
    upsertMembersOfOrganization: {__typename?: "UpsertMembersOfOrganizationResponse"} & Pick<
        UpsertMembersOfOrganizationResponse,
        "skippedMembersCount"
    > & {newMembers: Array<{__typename?: "User"} & MemberFragment>; updatedMembers: Array<{__typename?: "User"} & MemberFragment>};
};

export type UpdateOrganizationMutationVariables = Exact<{
    data: OrganizationUpdateInput;
}>;

export type UpdateOrganizationMutation = {__typename?: "Mutation"} & {
    updateOneOrganization?: Maybe<
        {__typename?: "Organization"} & Pick<
            Organization,
            "id" | "name" | "isActivated" | "companyName" | "companyWebsite" | "onboardingCompleted" | "processingSubscription"
        >
    >;
};

export type EditOrganizationMembersMutationVariables = Exact<{
    membersToEdit: Array<EditOrganizationMembersPayload> | EditOrganizationMembersPayload;
    organizationId?: Maybe<Scalars["String"]>;
}>;

export type EditOrganizationMembersMutation = {__typename?: "Mutation"} & {
    editOrganizationMembers: {__typename?: "EditOrganizationMembersResponse"} & Pick<EditOrganizationMembersResponse, "errorMessage"> & {
            updatedMembers?: Maybe<Array<{__typename?: "User"} & MemberFragment>>;
        };
};

export type DeleteOrganizationMutationVariables = Exact<{
    organizationId?: Maybe<Scalars["String"]>;
}>;

export type DeleteOrganizationMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteOrganization">;

export type SendInviteEmailsMutationVariables = Exact<{
    emails: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SendInviteEmailsMutation = {__typename?: "Mutation"} & {sendInviteEmails: Array<{__typename?: "User"} & MemberFragment>};

export type SuspendOrReactivateMembersMutationVariables = Exact<{
    emails: Array<Scalars["String"]> | Scalars["String"];
    suspend: Scalars["Boolean"];
    organizationId?: Maybe<Scalars["String"]>;
}>;

export type SuspendOrReactivateMembersMutation = {__typename?: "Mutation"} & {
    suspendOrReactivateMembers: Array<
        {__typename?: "UserToOrganizationPermission"} & Pick<UserToOrganizationPermission, "id" | "permissionType" | "suspended">
    >;
};

export type SetMemberWorkspaceRoleMutationVariables = Exact<{
    userId: Scalars["String"];
    newRoleId: Scalars["String"];
}>;

export type SetMemberWorkspaceRoleMutation = {__typename?: "Mutation"} & {setMemberWorkspaceRole: {__typename?: "User"} & MemberFragment};

export type OfferFragment = {__typename?: "Offer"} & Pick<
    Offer,
    "id" | "title" | "description" | "imageUrl" | "ctaText" | "ctaUrl" | "createdAt" | "updatedAt" | "sessionId" | "userId"
>;

export type EventOfferFragment = {__typename?: "Offer"} & Pick<
    Offer,
    "id" | "title" | "description" | "imageUrl" | "ctaText" | "ctaUrl" | "createdAt" | "updatedAt" | "sessionId" | "userId"
>;

export type GetOffersQueryVariables = Exact<{[key: string]: never}>;

export type GetOffersQuery = {__typename?: "Query"} & {userOffers: Array<{__typename?: "Offer"} & OfferFragment>};

export type GetSessionOffersQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetSessionOffersQuery = {__typename?: "Query"} & {sessionOffers: Array<{__typename?: "Offer"} & OfferFragment>};

export type CreateOfferMutationVariables = Exact<{
    offer: OfferInput;
    sessionId?: Maybe<Scalars["String"]>;
}>;

export type CreateOfferMutation = {__typename?: "Mutation"} & {createOneOffer?: Maybe<{__typename?: "Offer"} & OfferFragment>};

export type DeleteOfferMutationVariables = Exact<{
    offerId: Scalars["String"];
}>;

export type DeleteOfferMutation = {__typename?: "Mutation"} & {deleteOneOffer?: Maybe<{__typename?: "Offer"} & OfferFragment>};

export type UpdateOfferMutationVariables = Exact<{
    offerId: Scalars["String"];
    offer: OfferInput;
    sessionId?: Maybe<Scalars["String"]>;
}>;

export type UpdateOfferMutation = {__typename?: "Mutation"} & {updateOneOffer?: Maybe<{__typename?: "Offer"} & OfferFragment>};

export type UpdateOfferImageMutationVariables = Exact<{
    cacheBuster: Scalars["String"];
}>;

export type UpdateOfferImageMutation = {__typename?: "Mutation"} & {
    uploadOfferAsset?: Maybe<{__typename?: "OfferAsset"} & Pick<OfferAsset, "uploadUrl" | "publicUrl">>;
};

export type AddOffersInSessionMutationVariables = Exact<{
    offersIds: Array<Scalars["String"]> | Scalars["String"];
    sessionId: Scalars["String"];
}>;

export type AddOffersInSessionMutation = {__typename?: "Mutation"} & {
    addOffersInSession: Array<Maybe<{__typename?: "Offer"} & OfferFragment>>;
};

export type RemoveOfferFromSessionMutationVariables = Exact<{
    offerId: Scalars["String"];
    sessionId: Scalars["String"];
}>;

export type RemoveOfferFromSessionMutation = {__typename?: "Mutation"} & {
    removeOfferFromSession?: Maybe<{__typename?: "Offer"} & OfferFragment>;
};

export type OnShowOfferSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type OnShowOfferSubscription = {__typename?: "Subscription"} & {
    onShowOffer?: Maybe<
        {__typename?: "OfferContainerUpdatePayload"} & Pick<OfferContainerUpdatePayload, "list" | "deleted"> & {
                offer?: Maybe<Array<Maybe<{__typename?: "Offer"} & OfferFragment>>>;
            }
    >;
};

export type ShowOfferInSessionMutationVariables = Exact<{
    sessionId: Scalars["String"];
    offerId?: Maybe<Scalars["String"]>;
}>;

export type ShowOfferInSessionMutation = {__typename?: "Mutation"} & {showOffer?: Maybe<{__typename?: "Offer"} & OfferFragment>};

export type UpdateOneUserMutationVariables = Exact<{
    data?: Maybe<UserUpdateInput>;
}>;

export type UpdateOneUserMutation = {__typename?: "Mutation"} & {updateOneUser: {__typename?: "User"} & RemoteUserFragment};

export type RemoveUserProfilePictureMutationVariables = Exact<{[key: string]: never}>;

export type RemoveUserProfilePictureMutation = {__typename?: "Mutation"} & {
    removeUserProfilePicture?: Maybe<{__typename?: "User"} & Pick<User, "id" | "profilePicture">>;
};

export type UploadProfilePictureMutationVariables = Exact<{
    photoSuffix: Scalars["String"];
}>;

export type UploadProfilePictureMutation = {__typename?: "Mutation"} & Pick<Mutation, "uploadUserProfilePicture">;

export type ConfirmUploadProfilePictureMutationVariables = Exact<{
    photoSuffix: Scalars["String"];
}>;

export type ConfirmUploadProfilePictureMutation = {__typename?: "Mutation"} & {
    confirmProfilePictureUpload?: Maybe<{__typename?: "User"} & Pick<User, "id" | "profilePicture">>;
};

export type DeleteManyUsersMutationVariables = Exact<{
    emails: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DeleteManyUsersMutation = {__typename?: "Mutation"} & {deleteManyUsers: Array<{__typename?: "User"} & Pick<User, "id">>};

export type GetWorkspaceSenderEmailsQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type GetWorkspaceSenderEmailsQuery = {__typename?: "Query"} & {
    workspaceSenderEmails: Array<
        {__typename?: "WorkspaceSenderEmail"} & Pick<
            WorkspaceSenderEmail,
            "customSmtpServerId" | "externalProviderId" | "workspaceSenderEmailType" | "senderEmail" | "isDefault"
        >
    >;
};

export type UpsertDefaultEmailSenderMutationVariables = Exact<{
    workspaceId: Scalars["String"];
    senderEmail: Scalars["String"];
    customSmtpServerId?: Maybe<Scalars["String"]>;
    externalProviderId?: Maybe<Scalars["String"]>;
}>;

export type UpsertDefaultEmailSenderMutation = {__typename?: "Mutation"} & {
    upsertDefaultEmailSender: {__typename?: "DefaultEmailSender"} & Pick<DefaultEmailSender, "id">;
};

export type DeleteDefaultEmailSenderMutationVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type DeleteDefaultEmailSenderMutation = {__typename?: "Mutation"} & {
    deleteDefaultEmailSender: {__typename?: "DefaultEmailSender"} & Pick<DefaultEmailSender, "id">;
};

export type WebhookFragment = {__typename?: "Webhook"} & Pick<
    Webhook,
    "id" | "trigger" | "url" | "userId" | "workspaceId" | "permissionType" | "integration" | "createdAt" | "updatedAt"
> & {headers: Array<{__typename?: "WebhookHeader"} & Pick<WebhookHeader, "key" | "value">>};

export type GetWebhooksQueryVariables = Exact<{
    userId: Scalars["String"];
    workspaceId: Scalars["String"];
}>;

export type GetWebhooksQuery = {__typename?: "Query"} & {webhooks: Array<{__typename?: "Webhook"} & WebhookFragment>};

export type CreateWebhookMutationVariables = Exact<{
    headers?: Maybe<Array<Maybe<WebhookHeaderInput>> | Maybe<WebhookHeaderInput>>;
    trigger: WebhookTrigger;
    url: Scalars["String"];
    permissionType: WebhookPermissionType;
}>;

export type CreateWebhookMutation = {__typename?: "Mutation"} & {createWebhook: {__typename?: "Webhook"} & WebhookFragment};

export type UpdateWebhookMutationVariables = Exact<{
    headers?: Maybe<Array<Maybe<WebhookHeaderInput>> | Maybe<WebhookHeaderInput>>;
    trigger: WebhookTrigger;
    url: Scalars["String"];
    id: Scalars["String"];
}>;

export type UpdateWebhookMutation = {__typename?: "Mutation"} & {updateWebhook: {__typename?: "Webhook"} & WebhookFragment};

export type DeleteWebhookMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteWebhookMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteWebhook">;

export type WorkspaceRoleInfoFragment = {__typename?: "WorkspaceRole"} & Pick<
    WorkspaceRole,
    "id" | "permissions" | "createdAt" | "name" | "clientOnly"
>;

export type WorkspaceRolesQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type WorkspaceRolesQuery = {__typename?: "Query"} & {
    workspace?: Maybe<
        {__typename?: "Workspace"} & Pick<Workspace, "id"> & {roles: Array<{__typename?: "WorkspaceRole"} & WorkspaceRoleInfoFragment>}
    >;
};

export type CreateOneWorkspaceRoleMutationVariables = Exact<{
    data: WorkspaceRoleCreateInput;
}>;

export type CreateOneWorkspaceRoleMutation = {__typename?: "Mutation"} & {
    createOneWorkspaceRole?: Maybe<{__typename?: "WorkspaceRole"} & WorkspaceRoleInfoFragment>;
};

export type UpdateOneWorkspaceRoleMutationVariables = Exact<{
    where: WhereIdInput;
    data: WorkspaceRoleCreateInput;
}>;

export type UpdateOneWorkspaceRoleMutation = {__typename?: "Mutation"} & {
    updateOneWorkspaceRole?: Maybe<{__typename?: "WorkspaceRole"} & WorkspaceRoleInfoFragment>;
};

export type UpdateWorkspaceMutationVariables = Exact<{
    workspaceId: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
}>;

export type UpdateWorkspaceMutation = {__typename?: "Mutation"} & {
    updateWorkspace?: Maybe<{__typename?: "Workspace"} & Pick<Workspace, "id" | "slug" | "name">>;
};

export type DeleteWorkspaceMutationVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type DeleteWorkspaceMutation = {__typename?: "Mutation"} & {
    deleteWorkspace?: Maybe<{__typename?: "DeleteWorkspaceResult"} & Pick<DeleteWorkspaceResult, "success" | "errorMessage">>;
};

export type DeleteOneWorkspaceRoleMutationVariables = Exact<{
    where: WhereIdInput;
}>;

export type DeleteOneWorkspaceRoleMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteOneWorkspaceRole">;

export type PublicApiKeyInfoFragment = {__typename?: "PublicApiKey"} & Pick<
    PublicApiKey,
    "id" | "userId" | "name" | "role" | "tokenPreview" | "createdAt"
>;

export type PublicApiKeysQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type PublicApiKeysQuery = {__typename?: "Query"} & {publicApiKeys: Array<{__typename?: "PublicApiKey"} & PublicApiKeyInfoFragment>};

export type CreatePublicApiKeyMutationVariables = Exact<{
    name: Scalars["String"];
    role?: Maybe<PublicApiKeyRole>;
    integration?: Maybe<PublicApiIntegration>;
}>;

export type CreatePublicApiKeyMutation = {__typename?: "Mutation"} & {
    createPublicApiKey?: Maybe<{__typename?: "GeneratedPublicApiKey"} & Pick<GeneratedPublicApiKey, "id" | "token" | "role">>;
};

export type RevokePublicApiKeyMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type RevokePublicApiKeyMutation = {__typename?: "Mutation"} & Pick<Mutation, "revokePublicApiKey">;

export type RevokePublicApiKeysMutationVariables = Exact<{
    ids: Array<Scalars["String"]> | Scalars["String"];
}>;

export type RevokePublicApiKeysMutation = {__typename?: "Mutation"} & Pick<Mutation, "revokePublicApiKeys">;

export type GetCelloTokenQueryVariables = Exact<{[key: string]: never}>;

export type GetCelloTokenQuery = {__typename?: "Query"} & Pick<Query, "getCelloToken">;

export type GetRemoteUserQueryVariables = Exact<{[key: string]: never}>;

export type GetRemoteUserQuery = {__typename?: "Query"} & {
    getRemoteUser: {__typename?: "GetRemoteUserResult"} & Pick<GetRemoteUserResult, "guestDoesNotExist" | "userSuspended" | "updateTs"> & {
            user?: Maybe<{__typename?: "User"} & RemoteUserFragment>;
        };
};

export type RunFirstLoginEventsMutationVariables = Exact<{
    previousSessionDetails?: Maybe<PreviousSessionDetails>;
    externalReferral?: Maybe<Scalars["String"]>;
    utmCookies?: Maybe<UtmCookies>;
    celloUcc?: Maybe<Scalars["String"]>;
    rewardfulReferral?: Maybe<Scalars["String"]>;
    lastVersionSeen: Scalars["Int"];
}>;

export type RunFirstLoginEventsMutation = {__typename?: "Mutation"} & {
    runFirstLoginEvents?: Maybe<{__typename?: "User"} & Pick<User, "id" | "firstLogin" | "seen">>;
};

export type GetCentrifugoTokenQueryVariables = Exact<{[key: string]: never}>;

export type GetCentrifugoTokenQuery = {__typename?: "Query"} & Pick<Query, "getCentrifugoToken">;

export type SeenSectionsFragment = {__typename?: "User"} & Pick<User, "id" | "seen">;

export type WorkspacePermissionFragment = {__typename?: "UserToWorkspacePermission"} & Pick<
    UserToWorkspacePermission,
    "id" | "activeWorkspace"
> & {
        role: {__typename?: "WorkspaceRole"} & WorkspaceRoleInfoFragment;
        workspace: {__typename?: "Workspace"} & Pick<Workspace, "id" | "name" | "slug" | "membersCount" | "organizationId"> & {
                branding?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>;
            };
    };

export type RemoteUserFragment = {__typename?: "User"} & Pick<
    User,
    | "id"
    | "createdAt"
    | "email"
    | "firstName"
    | "lastName"
    | "company"
    | "industry"
    | "jobRole"
    | "profilePicture"
    | "language"
    | "emailNotificationsEnabled"
    | "emailNotificationsMinutes"
    | "pushNotificationsEnabled"
    | "pushNotificationsMinutes"
    | "timezone"
    | "halfDayClock"
    | "seenTour"
    | "seenHomeIntercom"
    | "seenWelcomeSession"
    | "lastVersionSeen"
    | "firstLogin"
    | "lastLoginDate"
    | "forms"
    | "externalReferral"
    | "onboarding"
    | "flags"
    | "notesFlags"
    | "department"
    | "country"
    | "meetingRegion"
    | "externalCalendarsSettings"
    | "firstDayOfWeek"
    | "defaultBookingCalendars"
    | "marketingEmailsEnabled"
> & {
        organizationPermissions: Array<
            {__typename?: "UserToOrganizationPermission"} & Pick<
                UserToOrganizationPermission,
                "id" | "permissionType" | "activeOrganization" | "inviteStatus" | "suspended"
            > & {organization: {__typename?: "Organization"} & OrganizationOnUserFragment}
        >;
        workspacePermissions: Array<{__typename?: "UserToWorkspacePermission"} & WorkspacePermissionFragment>;
        customSubDomainConfig?: Maybe<
            {__typename?: "CustomSubDomainConfig"} & Pick<CustomSubDomainConfig, "id" | "fqdn" | "domain" | "root">
        >;
        customBackgrounds?: Maybe<Array<{__typename?: "CustomBackground"} & Pick<CustomBackground, "id" | "url">>>;
        defaultLobbyPicture?: Maybe<
            {__typename?: "LobbyPicture"} & Pick<
                LobbyPicture,
                | "id"
                | "croppedPictureUrl"
                | "originalPictureUrl"
                | "sessionId"
                | "aspect"
                | "height"
                | "width"
                | "x"
                | "y"
                | "unit"
                | "pictureWasUploaded"
                | "canBeRepositioned"
                | "createdAt"
                | "updatedAt"
            >
        >;
        tags: Array<{__typename?: "SessionTag"} & Pick<SessionTag, "id" | "name">>;
    } & SeenSectionsFragment;

export type OrganizationOnUserFragment = {__typename?: "Organization"} & Pick<
    Organization,
    | "id"
    | "name"
    | "companyName"
    | "plan"
    | "companyWebsite"
    | "appSumoInvoiceItemId"
    | "isActivated"
    | "size"
    | "onboarding"
    | "createdAt"
    | "onboardingCompleted"
    | "lastOrderId"
    | "subscriptionTrialUsed"
    | "subscriptionId"
    | "billingPeriod"
    | "planNextPaymentDate"
    | "planEffectiveCancellationDate"
    | "reverseTrialCancellationDate"
    | "reverseTrialExpired"
    | "celloUcc"
    | "membersCount"
    | "processingSubscription"
    | "recordingActivity"
    | "livestreamActivity"
    | "discountCode"
    | "discountCodeLabel"
    | "referralDiscountCode"
    | "eventActivity"
    | "paddleSubscriptionState"
    | "permanentPlan"
> & {subscriptionModifiers: Array<{__typename?: "SubscriptionModifier"} & Pick<SubscriptionModifier, "id" | "quantity" | "type">>};

export type ExecSvcClientMutationVariables = Exact<{
    operation: Scalars["String"];
    args?: Maybe<Scalars["String"]>;
}>;

export type ExecSvcClientMutation = {__typename?: "Mutation"} & {
    execSvc: {__typename?: "ExecResult"} & Pick<ExecResult, "id" | "success" | "error" | "result">;
};

export type AllRemoteApplicationStateFragment = {__typename?: "ApplicationState"} & Pick<ApplicationState, "id" | "state" | "updatedAt">;

export type GetRemoteApplicationStateQueryVariables = Exact<{[key: string]: never}>;

export type GetRemoteApplicationStateQuery = {__typename?: "Query"} & {
    getApplicationState?: Maybe<{__typename?: "ApplicationState"} & AllRemoteApplicationStateFragment>;
};

export type ApplicationStateUpdatedSubscriptionVariables = Exact<{[key: string]: never}>;

export type ApplicationStateUpdatedSubscription = {__typename?: "Subscription"} & {
    applicationStateChanges?: Maybe<{__typename?: "ApplicationState"} & AllRemoteApplicationStateFragment>;
};

export type MyProductToolQueryVariables = Exact<{
    myProductToolId: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
    sessionIdForOwnerTimezone?: Maybe<Scalars["String"]>;
}>;

export type MyProductToolQuery = {__typename?: "Query"} & {myProductTool: {__typename?: "MyProductTool"} & ProductToolFragment};

export type GetMiroTokenQueryVariables = Exact<{[key: string]: never}>;

export type GetMiroTokenQuery = {__typename?: "Query"} & Pick<Query, "getMiroToken">;

export type SetSlideMutationVariables = Exact<{
    artifactId: Scalars["String"];
    slide: Scalars["String"];
}>;

export type SetSlideMutation = {__typename?: "Mutation"} & {updateOneArtifact?: Maybe<{__typename?: "Artifact"} & Pick<Artifact, "id">>};

export type ToggleSlideshowControlsMutationVariables = Exact<{
    artifactId: Scalars["String"];
    mode: Scalars["String"];
}>;

export type ToggleSlideshowControlsMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<{__typename?: "Artifact"} & Pick<Artifact, "id">>;
};

export type OnStartGenerateAgendaSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
    prompt: Scalars["String"];
    includeTitleAndDescription?: Maybe<Scalars["Boolean"]>;
}>;

export type OnStartGenerateAgendaSubscription = {__typename?: "Subscription"} & {
    onStartGenerateAgenda?: Maybe<
        {__typename?: "GeneratedAgendaItemPayload"} & Pick<GeneratedAgendaItemPayload, "error"> & {
                agendaItem?: Maybe<
                    {__typename?: "GeneratedAgendaItem"} & Pick<GeneratedAgendaItem, "name" | "duration" | "description" | "order">
                >;
                titleAndDescription?: Maybe<
                    {__typename?: "GeneratedTitleAndDescription"} & Pick<GeneratedTitleAndDescription, "title" | "description">
                >;
            }
    >;
};

export type OnGenerateAnythingSubscriptionVariables = Exact<{
    prompt: Scalars["String"];
    temperature: Scalars["Float"];
    subscriptionId: Scalars["String"];
}>;

export type OnGenerateAnythingSubscription = {__typename?: "Subscription"} & {
    onGenerateAnything?: Maybe<{__typename?: "StringChunkPayload"} & Pick<StringChunkPayload, "chunk">>;
};

export type OnGeneratePollSubscriptionVariables = Exact<{
    prompt: Scalars["String"];
}>;

export type OnGeneratePollSubscription = {__typename?: "Subscription"} & {
    onGeneratePoll?: Maybe<{__typename?: "GeneratedPollsPayload"} & Pick<GeneratedPollsPayload, "question" | "answers" | "error">>;
};

export type OnStartGenerateSummarySubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
    language: Scalars["String"];
    action: GenerateSummaryAction;
    prompt?: Maybe<Scalars["String"]>;
}>;

export type OnStartGenerateSummarySubscription = {__typename?: "Subscription"} & {
    onStartGenerateSummary?: Maybe<{__typename?: "StringChunkPayload"} & Pick<StringChunkPayload, "chunk" | "model">>;
};

export type GetCsdForSessionQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetCsdForSessionQuery = {__typename?: "Query"} & {
    csdOfSession?: Maybe<{__typename?: "CustomSubDomainConfig"} & Pick<CustomSubDomainConfig, "fqdn">>;
};

export type GetRootUrlQueryVariables = Exact<{
    sessionId: Scalars["String"];
    target: Scalars["String"];
}>;

export type GetRootUrlQuery = {__typename?: "Query"} & Pick<Query, "getRootUrl">;

export type ToggleEventPublicMutationVariables = Exact<{
    sessionId: Scalars["ID"];
    isPublic: Scalars["Boolean"];
}>;

export type ToggleEventPublicMutation = {__typename?: "Mutation"} & Pick<Mutation, "toggleEventPublic">;

export type SyncGoogleDriveMutationVariables = Exact<{[key: string]: never}>;

export type SyncGoogleDriveMutation = {__typename?: "Mutation"} & Pick<Mutation, "syncGoogleDrive">;

export type SearchUnsplashPhotosQueryVariables = Exact<{
    searchQuery?: Maybe<Scalars["String"]>;
    page: Scalars["Int"];
    perPage: Scalars["Int"];
}>;

export type SearchUnsplashPhotosQuery = {__typename?: "Query"} & {
    searchUnsplashPhotos: Array<
        {__typename?: "UnSplashPhotoCategories"} & Pick<UnSplashPhotoCategories, "category"> & {
                photos: Array<
                    {__typename?: "UnSplashPhoto"} & Pick<
                        UnSplashPhoto,
                        "regularUrl" | "thumbnailUrl" | "name" | "username" | "download_location"
                    >
                >;
            }
    >;
};

export type SubmitProblemMutationVariables = Exact<{
    screenshotUuid?: Maybe<Scalars["String"]>;
    description: Scalars["String"];
    deviceInfo?: Maybe<Scalars["Json"]>;
    trace?: Maybe<Scalars["String"]>;
    session?: Maybe<Scalars["String"]>;
    category: ReportProblemCategory;
}>;

export type SubmitProblemMutation = {__typename?: "Mutation"} & Pick<Mutation, "submitProblem">;

export type UploadProblemScreenshotMutationVariables = Exact<{[key: string]: never}>;

export type UploadProblemScreenshotMutation = {__typename?: "Mutation"} & {
    uploadProblemScreenshot?: Maybe<{__typename?: "UploadAnonymousFileResult"} & Pick<UploadAnonymousFileResult, "uuid" | "uploadUrl">>;
};

export type GetExternalClientsQueryVariables = Exact<{[key: string]: never}>;

export type GetExternalClientsQuery = {__typename?: "Query"} & {
    externalClients: Array<
        {__typename?: "ExternalClient"} & Pick<ExternalClient, "id" | "clientId" | "clientSecret" | "iconUrl" | "claims">
    >;
};

export type GetUserNamesQueryVariables = Exact<{
    emails: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetUserNamesQuery = {__typename?: "Query"} & {
    getUserNames?: Maybe<
        {__typename?: "GetUserNamesResult"} & {
            users?: Maybe<
                Array<
                    Maybe<
                        {__typename?: "GetUserNameResult"} & Pick<
                            GetUserNameResult,
                            "firstName" | "lastName" | "userProfilePicture" | "email"
                        >
                    >
                >
            >;
        }
    >;
};

export type GetUserCountryCodeQueryVariables = Exact<{[key: string]: never}>;

export type GetUserCountryCodeQuery = {__typename?: "Query"} & Pick<Query, "getUserCountryCode">;

export type ConfirmUploadEventImageMutationVariables = Exact<{
    primary: Scalars["Boolean"];
    eventId: Scalars["String"];
    cacheBuster: Scalars["String"];
}>;

export type ConfirmUploadEventImageMutation = {__typename?: "Mutation"} & Pick<Mutation, "confirmUploadEventImage">;

export type UploadEventImageMutationVariables = Exact<{
    primary: Scalars["Boolean"];
    eventId: Scalars["String"];
    cacheBuster: Scalars["String"];
}>;

export type UploadEventImageMutation = {__typename?: "Mutation"} & Pick<Mutation, "uploadEventImage">;

export type SessionEventBySlugQueryVariables = Exact<{
    slug: Scalars["String"];
}>;

export type SessionEventBySlugQuery = {__typename?: "Query"} & {
    sessionEvent?: Maybe<{__typename?: "Session"} & {event?: Maybe<{__typename?: "SessionEvent"} & Pick<SessionEvent, "slug">>}>;
};

export type UploadSessionSpeakerPhotoMutationVariables = Exact<{
    sessionId: Scalars["String"];
    participantId: Scalars["String"];
    cacheBuster: Scalars["String"];
    isThumbnail?: Maybe<Scalars["Boolean"]>;
}>;

export type UploadSessionSpeakerPhotoMutation = {__typename?: "Mutation"} & Pick<Mutation, "uploadSessionSpeakerPhoto">;

export type ConfirmUploadSessionSpeakerPhotoMutationVariables = Exact<{
    sessionId: Scalars["String"];
    participantId: Scalars["String"];
    cacheBuster: Scalars["String"];
    isThumbnail?: Maybe<Scalars["Boolean"]>;
}>;

export type ConfirmUploadSessionSpeakerPhotoMutation = {__typename?: "Mutation"} & Pick<Mutation, "confirmUploadSessionSpeakerPhoto">;

export type SpeakerDetailsFragment = {__typename?: "SessionSpeaker"} & Pick<
    SessionSpeaker,
    "name" | "bio" | "company" | "id" | "photoUrl" | "thumbnailUrl" | "linkedInUrl" | "jobTitle" | "email"
>;

export type SpeakerInfoFragment = {__typename?: "Participant"} & Pick<Participant, "id"> & {
        dataWithNullableEmail: {__typename?: "ParticipantDataWithNullableEmail"} & Pick<
            ParticipantDataWithNullableEmail,
            "avatar" | "email"
        >;
        speakerDetails?: Maybe<{__typename?: "SessionSpeaker"} & SpeakerDetailsFragment>;
    };

export type GetSpeakersHistoryQueryVariables = Exact<{[key: string]: never}>;

export type GetSpeakersHistoryQuery = {__typename?: "Query"} & {
    getSpeakersHistory: Array<{__typename?: "SessionSpeaker"} & SpeakerDetailsFragment>;
};

export type OneEventListItemFragment = {__typename?: "Session"} & Pick<Session, "id" | "name" | "startAt" | "backstage" | "lifecycle"> & {
        participants: Array<
            {__typename?: "Participant"} & Pick<Participant, "id" | "isOwner" | "userId"> & {
                    dataWithNullableEmail: {__typename?: "ParticipantDataWithNullableEmail"} & Pick<
                        ParticipantDataWithNullableEmail,
                        "firstName" | "lastName"
                    >;
                }
        >;
        event?: Maybe<
            {__typename?: "SessionEvent"} & Pick<
                SessionEvent,
                "id" | "state" | "bannerUrl" | "isPublic" | "slug" | "maxSeats" | "seatsTaken"
            > & {payments: Array<{__typename?: "PaymentConfig"} & PaymentConfigFragment>}
        >;
    };

export type GetUserEventsListQueryVariables = Exact<{
    take?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    eventFilters?: Maybe<Array<SessionEventFilters> | SessionEventFilters>;
    ownerIdsFilter?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    orderByDate?: Maybe<SortOrder>;
    orderByName?: Maybe<SortOrder>;
    searchTerm?: Maybe<Scalars["String"]>;
    includeEventsCount: Scalars["Boolean"];
    workspaceId: Scalars["String"];
}>;

export type GetUserEventsListQuery = {__typename?: "Query"} & MakeOptional<Pick<Query, "sessionEventsCount">, "sessionEventsCount"> & {
        sessionEvents: Array<{__typename?: "Session"} & OneEventListItemFragment>;
    };

export type SessionEventsOwnersQueryVariables = Exact<{[key: string]: never}>;

export type SessionEventsOwnersQuery = {__typename?: "Query"} & {
    sessionEventsOwners: Array<{__typename?: "User"} & Pick<User, "id" | "firstName" | "lastName">>;
};

export type PublishEventMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type PublishEventMutation = {__typename?: "Mutation"} & {publishEvent?: Maybe<{__typename?: "SessionEvent"} & SessionEventFragment>};

export type OwnerFeedbacksQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type OwnerFeedbacksQuery = {__typename?: "Query"} & {
    ownerFeedbacks?: Maybe<
        Array<
            Maybe<
                {__typename?: "Feedback"} & Pick<
                    Feedback,
                    | "id"
                    | "firstName"
                    | "lastName"
                    | "email"
                    | "sessionId"
                    | "sessionName"
                    | "source"
                    | "message"
                    | "rating"
                    | "technicalDetails"
                    | "sentToProductBoard"
                    | "createdAt"
                >
            >
        >
    >;
};

export type GiveFeedbackMutationVariables = Exact<{
    data: CreateFeedbackInput;
}>;

export type GiveFeedbackMutation = {__typename?: "Mutation"} & {createFeedback: {__typename?: "Feedback"} & Pick<Feedback, "id">};

export type GiveOwnerFeedbackMutationVariables = Exact<{
    data: CreateFeedbackInput;
}>;

export type GiveOwnerFeedbackMutation = {__typename?: "Mutation"} & {createOwnerFeedback: {__typename?: "Feedback"} & Pick<Feedback, "id">};

export type TryJoinSessionMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type TryJoinSessionMutation = {__typename?: "Mutation"} & {
    tryJoinSession?: Maybe<
        {__typename?: "TryJoinSessionResult"} & Pick<
            TryJoinSessionResult,
            "state" | "instanceId" | "tokenNeedsRefresh" | "redirectTo" | "isRoom"
        > & {participant?: Maybe<{__typename?: "Participant"} & {pid: Participant["id"]; type: "Participant"}>}
    >;
};

export type DeleteBookingScheduleMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteBookingScheduleMutation = {__typename?: "Mutation"} & {
    deleteBookingSchedule?: Maybe<{__typename?: "BookingSchedule"} & Pick<BookingSchedule, "id">>;
};

export type AnswerParticipantsMutationVariables = Exact<{
    hasBeenApproved: Scalars["Boolean"];
    participantIds: Array<Scalars["String"]> | Scalars["String"];
    sessionId: Scalars["String"];
}>;

export type AnswerParticipantsMutation = {__typename?: "Mutation"} & Pick<Mutation, "answerWaitingParticipants">;

export type StartSessionMutationVariables = Exact<{
    sessionId: Scalars["String"];
    backstage?: Maybe<Scalars["Boolean"]>;
}>;

export type StartSessionMutation = {__typename?: "Mutation"} & {
    startSession: {__typename?: "Session"} & Pick<Session, "id" | "lifecycle" | "actualStart">;
};

export type StartSessionFromBackstageMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type StartSessionFromBackstageMutation = {__typename?: "Mutation"} & {
    startSessionFromBackstage: {__typename?: "Session"} & Pick<Session, "id" | "lifecycle" | "actualStart">;
};

export type EndSessionMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type EndSessionMutation = {__typename?: "Mutation"} & {endSession: {__typename?: "Session"} & Pick<Session, "id">};

export type EndBackstageMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type EndBackstageMutation = {__typename?: "Mutation"} & {endBackstage: {__typename?: "Session"} & Pick<Session, "id">};

export type SessionIdOfRoomQueryVariables = Exact<{
    roomSlug: Scalars["String"];
}>;

export type SessionIdOfRoomQuery = {__typename?: "Query"} & {
    sessionIdOfRoom?: Maybe<{__typename?: "SessionIdOfRoom"} & Pick<SessionIdOfRoom, "sessionId" | "active">>;
};

export type SendReactionMutationVariables = Exact<{
    sessionId: Scalars["String"];
    reactionType: ReactionType;
}>;

export type SendReactionMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendReaction">;

export type OnReactionSendSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type OnReactionSendSubscription = {__typename?: "Subscription"} & {
    onReactionSend?: Maybe<{__typename?: "OnReactionSendResult"} & Pick<OnReactionSendResult, "participantId" | "reactionType">>;
};

export type OnPushSessionViewSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type OnPushSessionViewSubscription = {__typename?: "Subscription"} & {
    onPushSessionView?: Maybe<{__typename?: "OnPushSessionViewResult"} & Pick<OnPushSessionViewResult, "participantId" | "sessionView">>;
};

export type PushSessionViewMutationVariables = Exact<{
    sessionId: Scalars["String"];
    sessionView: Scalars["String"];
}>;

export type PushSessionViewMutation = {__typename?: "Mutation"} & Pick<Mutation, "pushSessionView">;

export type RequestArtifactControlMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type RequestArtifactControlMutation = {__typename?: "Mutation"} & Pick<Mutation, "requestControl">;

export type SetArtifactControlMutationVariables = Exact<{
    sessionId: Scalars["String"];
    participantId: Scalars["String"];
    willControlTool: Scalars["Boolean"];
}>;

export type SetArtifactControlMutation = {__typename?: "Mutation"} & Pick<Mutation, "setControl">;

export type GetCalendarFileQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetCalendarFileQuery = {__typename?: "Query"} & {
    getSessionCalendarFile?: Maybe<
        {__typename?: "SessionCalendarFile"} & Pick<SessionCalendarFile, "ics" | "google" | "yahoo" | "outlook" | "outlookOffice">
    >;
};

export type GetCoverLinksHistoryQueryVariables = Exact<{[key: string]: never}>;

export type GetCoverLinksHistoryQuery = {__typename?: "Query"} & {
    getRemoteUser: {__typename?: "GetRemoteUserResult"} & {
        user?: Maybe<
            {__typename?: "User"} & Pick<User, "id"> & {
                    lobbyPictureLinksHistory: Array<{__typename?: "UrlHistory"} & Pick<UrlHistory, "id" | "url">>;
                    lobbyPicturesUploadedByUserHistory: Array<{__typename?: "UrlHistory"} & Pick<UrlHistory, "id" | "url">>;
                }
        >;
    };
};

export type UploadLobbyPictureMutationVariables = Exact<{
    sessionId: Scalars["String"];
    photoSuffix: Scalars["String"];
}>;

export type UploadLobbyPictureMutation = {__typename?: "Mutation"} & {
    uploadLobbyPicture?: Maybe<{__typename?: "LobbyPictureUploadUrls"} & Pick<LobbyPictureUploadUrls, "originalUploadUrl">>;
};

export type DeleteCoverHistoryImageMutationVariables = Exact<{
    pictureWasUploaded: Scalars["Boolean"];
    id: Scalars["String"];
    url?: Maybe<Scalars["String"]>;
}>;

export type DeleteCoverHistoryImageMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteCoverHistoryImage">;

export type CommitLobbyPictureMutationVariables = Exact<{
    id: Scalars["String"];
    sessionId: Scalars["String"];
    originalPictureUrl: Scalars["String"];
    y?: Maybe<Scalars["Float"]>;
    pictureWasUploaded?: Maybe<Scalars["Boolean"]>;
    canBeRepositioned?: Maybe<Scalars["Boolean"]>;
    unsplashDownloadLocationUrl?: Maybe<Scalars["String"]>;
    isDefaultImage?: Maybe<Scalars["Boolean"]>;
}>;

export type CommitLobbyPictureMutation = {__typename?: "Mutation"} & {
    commitLobbyPicture: {__typename?: "LobbyPicture"} & Pick<
        LobbyPicture,
        "id" | "originalPictureUrl" | "pictureWasUploaded" | "y" | "canBeRepositioned" | "createdAt" | "updatedAt" | "isDefaultImage"
    >;
};

export type ChangeGuestDetailsMutationVariables = Exact<{
    sessionId: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
}>;

export type ChangeGuestDetailsMutation = {__typename?: "Mutation"} & {
    changeGuestDetails: {__typename?: "Participant"} & Pick<Participant, "id" | "status">;
};

export type RequestPermissionToJoinMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type RequestPermissionToJoinMutation = {__typename?: "Mutation"} & {
    requestPermissionToJoin: {__typename?: "Participant"} & Pick<Participant, "id" | "status">;
};

export type ProvideSessionPasscodeMutationVariables = Exact<{
    sessionId: Scalars["String"];
    passcode: Scalars["String"];
}>;

export type ProvideSessionPasscodeMutation = {__typename?: "Mutation"} & {
    provideSessionPasscode: {__typename?: "Participant"} & Pick<Participant, "id" | "submittedPasscode">;
};

export type GiveConsentToRecordMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GiveConsentToRecordMutation = {__typename?: "Mutation"} & {
    giveConsentToRecord: {__typename?: "Participant"} & Pick<Participant, "id" | "consentedToRecord">;
};

export type GetResourceCountQueryVariables = Exact<{
    workspaceId?: Maybe<Scalars["String"]>;
    includePdf: Scalars["Boolean"];
    includePpt: Scalars["Boolean"];
    includeDoc: Scalars["Boolean"];
    includeExcel: Scalars["Boolean"];
    includeKeynote: Scalars["Boolean"];
    includePages: Scalars["Boolean"];
    includeNumbers: Scalars["Boolean"];
    includeVideo: Scalars["Boolean"];
    includeGallery: Scalars["Boolean"];
    includeAirtable: Scalars["Boolean"];
    includeAdobexd: Scalars["Boolean"];
    includeBlackboard: Scalars["Boolean"];
    includeCanva: Scalars["Boolean"];
    includeFigma: Scalars["Boolean"];
    includeForm: Scalars["Boolean"];
    includeMentimeter: Scalars["Boolean"];
    includePitch: Scalars["Boolean"];
    includeSlido: Scalars["Boolean"];
    includeTwitch: Scalars["Boolean"];
    includeVimeo: Scalars["Boolean"];
    includeYoutube: Scalars["Boolean"];
    includeBoardmix: Scalars["Boolean"];
}>;

export type GetResourceCountQuery = {__typename?: "Query"} & {
    getResourcesCount: {__typename?: "ResourcesCount"} & MakeOptional<
        Pick<
            ResourcesCount,
            | "pptCount"
            | "pdfCount"
            | "docCount"
            | "excelCount"
            | "keynoteCount"
            | "pagesCount"
            | "numbersCount"
            | "videoCount"
            | "galleryCount"
            | "airtableCount"
            | "adobexdCount"
            | "blackboardCount"
            | "canvaCount"
            | "figmaCount"
            | "formCount"
            | "mentimeterCount"
            | "pitchCount"
            | "slidoCount"
            | "twitchCount"
            | "vimeoCount"
            | "youtubeCount"
            | "boardmixCount"
        >,
        | "pptCount"
        | "pdfCount"
        | "docCount"
        | "excelCount"
        | "keynoteCount"
        | "pagesCount"
        | "numbersCount"
        | "videoCount"
        | "galleryCount"
        | "airtableCount"
        | "adobexdCount"
        | "blackboardCount"
        | "canvaCount"
        | "figmaCount"
        | "formCount"
        | "mentimeterCount"
        | "pitchCount"
        | "slidoCount"
        | "twitchCount"
        | "vimeoCount"
        | "youtubeCount"
        | "boardmixCount"
    >;
};

export type UnsubscribePreferencesQueryVariables = Exact<{
    token: Scalars["String"];
}>;

export type UnsubscribePreferencesQuery = {__typename?: "Query"} & {
    getUnsubscribePreference?: Maybe<
        {__typename?: "UnsubscribePreference"} & Pick<UnsubscribePreference, "id" | "email" | "sendAppEmails">
    >;
};

export type UpdateUnsubscribePreferenceMutationVariables = Exact<{
    token: Scalars["String"];
    sendAppEmails: Scalars["Boolean"];
}>;

export type UpdateUnsubscribePreferenceMutation = {__typename?: "Mutation"} & {
    updateUnsubscribePreference?: Maybe<{__typename?: "UnsubscribePreference"} & Pick<UnsubscribePreference, "sendAppEmails">>;
};

export type SendMarketingEventMutationVariables = Exact<{
    email: Scalars["String"];
    eventName: Scalars["String"];
    eventBody: Scalars["String"];
    sendOnce: Scalars["Boolean"];
}>;

export type SendMarketingEventMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendMarketingEvent">;

export type JoinWithPublicLinkMutationVariables = Exact<{
    email?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    sessionId: Scalars["String"];
    language?: Maybe<Scalars["String"]>;
}>;

export type JoinWithPublicLinkMutation = {__typename?: "Mutation"} & {
    joinWithPublicLink: {__typename?: "JoinWithPublicLinkResult"} & Pick<
        JoinWithPublicLinkResult,
        "validationError" | "fatalError" | "redirectToLogin" | "redirectTo"
    > & {joinDetails?: Maybe<{__typename?: "SessionJoinDetails"} & Pick<SessionJoinDetails, "access_token" | "sessionId">>};
};

export type CheckSessionExistsMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type CheckSessionExistsMutation = {__typename?: "Mutation"} & {
    checkSessionExists?: Maybe<
        {__typename?: "CheckSessionExistsResult"} & Pick<
            CheckSessionExistsResult,
            "exists" | "guestAuthIsValid" | "participantId" | "askForGuestEmail" | "sessionLifecycle" | "isParentOfRecurrence" | "eventSlug"
        >
    >;
};

export type JoinWithInviteLinkMutationVariables = Exact<{
    code: Scalars["String"];
    isAuthenticated: Scalars["Boolean"];
    language?: Maybe<Scalars["String"]>;
}>;

export type JoinWithInviteLinkMutation = {__typename?: "Mutation"} & {
    joinWithInviteLink?: Maybe<
        {__typename?: "joinWithInviteLinkResult"} & Pick<
            JoinWithInviteLinkResult,
            "error" | "access_token" | "sessionId" | "linkAlreadyUsed"
        >
    >;
};

export type CheckGuestCodeMutationVariables = Exact<{
    code: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
}>;

export type CheckGuestCodeMutation = {__typename?: "Mutation"} & {
    checkGuestCode?: Maybe<
        {__typename?: "CheckGuestCodeResult"} & Pick<CheckGuestCodeResult, "outcome" | "sessionId" | "sessionLifecycle" | "hasMemoryAccess">
    >;
};

export type UpdateGuestInfoMutationVariables = Exact<{
    code: Scalars["String"];
    name: Scalars["String"];
}>;

export type UpdateGuestInfoMutation = {__typename?: "Mutation"} & Pick<Mutation, "setGuestInfo">;

export type UpdateUserOnboardingMutationVariables = Exact<{
    planning?: Maybe<OnboardingFieldType>;
    role?: Maybe<OnboardingFieldType>;
    meetingTypes?: Maybe<Array<OnboardingFieldType> | OnboardingFieldType>;
    termsAccepted?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateUserOnboardingMutation = {__typename?: "Mutation"} & {
    updateUserOnboarding?: Maybe<{__typename?: "User"} & Pick<User, "id" | "onboarding">>;
};

export type SendAnalyticsEventMutationVariables = Exact<{
    eventName: Scalars["String"];
    sessionId?: Maybe<Scalars["String"]>;
    data?: Maybe<Scalars["Json"]>;
}>;

export type SendAnalyticsEventMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendAnalyticsEvent">;

export type UpdateOrganizationOnboardingMutationVariables = Exact<{
    size?: Maybe<Scalars["String"]>;
    userOnboarding?: Maybe<UserOnboardingInput>;
    organizationName?: Maybe<Scalars["String"]>;
    onboardingCompleted?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateOrganizationOnboardingMutation = {__typename?: "Mutation"} & {
    updateOrganizationOnboarding?: Maybe<
        {__typename?: "Organization"} & Pick<
            Organization,
            | "id"
            | "size"
            | "onboarding"
            | "onboardingCompleted"
            | "name"
            | "plan"
            | "reverseTrialCancellationDate"
            | "subscriptionTrialUsed"
        >
    >;
};

export type ChangePasswordMutationVariables = Exact<{
    oldPassword: Scalars["String"];
    newPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = {__typename?: "Mutation"} & {
    changePassword?: Maybe<{__typename?: "ChangePasswordResult"} & Pick<ChangePasswordResult, "isSuccess" | "invalidPassword">>;
};

export type GlobalNotificationsSubscriptionVariables = Exact<{[key: string]: never}>;

export type GlobalNotificationsSubscription = {__typename?: "Subscription"} & {
    globalNotifications?: Maybe<
        {__typename?: "GlobalNotificationsResult"} & {
            calendar?: Maybe<
                {__typename?: "CalendarNotificationResult"} & Pick<CalendarNotificationResult, "deleteSessionId"> & {
                        upsertSession?: Maybe<
                            {__typename?: "UpsertCalendarEvent"} & Pick<UpsertCalendarEvent, "sessionId" | "deletedParticipantIds"> & {
                                    upsertParticipants?: Maybe<
                                        Array<
                                            {__typename?: "ParticipantForEvent"} & Pick<
                                                ParticipantForEvent,
                                                | "id"
                                                | "status"
                                                | "isOwner"
                                                | "inviteStatus"
                                                | "email"
                                                | "firstName"
                                                | "lastName"
                                                | "avatar"
                                                | "token"
                                                | "isGuest"
                                                | "rbac"
                                            >
                                        >
                                    >;
                                    entireEvent?: Maybe<{__typename?: "CalendarCustomEvent"} & CalendarEventCustomFragment>;
                                }
                        >;
                    }
            >;
            organizationUpdate?: Maybe<
                {__typename?: "Organization"} & Pick<
                    Organization,
                    | "id"
                    | "planEffectiveCancellationDate"
                    | "planNextPaymentDate"
                    | "subscriptionId"
                    | "paddleSubscriptionState"
                    | "reverseTrialCancellationDate"
                    | "reverseTrialExpired"
                    | "plan"
                    | "processingSubscription"
                    | "recordingActivity"
                    | "livestreamActivity"
                    | "billingPeriod"
                    | "referralDiscountCode"
                > & {
                        subscriptionModifiers: Array<
                            {__typename?: "SubscriptionModifier"} & Pick<SubscriptionModifier, "id" | "quantity" | "type">
                        >;
                    }
            >;
            workspacePermissionsUpdate?: Maybe<
                Array<
                    {__typename?: "UserToWorkspacePermission"} & Pick<UserToWorkspacePermission, "id" | "activeWorkspace"> & {
                            role: {__typename?: "WorkspaceRole"} & WorkspaceRoleInfoFragment;
                            workspace: {__typename?: "Workspace"} & Pick<Workspace, "id" | "name" | "slug"> & {
                                    branding?: Maybe<{__typename?: "Branding"} & BrandingInfoFragment>;
                                };
                        }
                >
            >;
            organizationPermissionsUpdate?: Maybe<
                Array<
                    {__typename?: "UserToOrganizationPermission"} & Pick<
                        UserToOrganizationPermission,
                        "id" | "permissionType" | "inviteStatus"
                    > & {organization: {__typename?: "Organization"} & OrganizationOnUserFragment}
                >
            >;
            oneOrganizationPermissionUpdate?: Maybe<
                {__typename?: "UserToOrganizationPermission"} & Pick<UserToOrganizationPermission, "id" | "permissionType" | "inviteStatus">
            >;
            userUpdate?: Maybe<{__typename?: "User"} & Pick<User, "id" | "marketingEmailsEnabled">>;
        }
    >;
};

export type CreteHubspotContactMutationVariables = Exact<{[key: string]: never}>;

export type CreteHubspotContactMutation = {__typename?: "Mutation"} & Pick<Mutation, "createHubspotContact">;

export type UpdateArtifactPropertyMutationVariables = Exact<{
    artifactId: Scalars["String"];
    key: Scalars["String"];
    value: Scalars["String"];
}>;

export type UpdateArtifactPropertyMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<
        {__typename?: "Artifact"} & Pick<Artifact, "id"> & {
                properties: Array<{__typename?: "Property"} & Pick<Property, "id" | "key" | "value" | "createdAt" | "updatedAt">>;
            }
    >;
};

export type PushDrawerStateMutationVariables = Exact<{
    sessionId: Scalars["String"];
    leftDrawer?: Maybe<PushDrawerStateInput>;
    rightDrawer?: Maybe<PushDrawerStateInput>;
}>;

export type PushDrawerStateMutation = {__typename?: "Mutation"} & Pick<Mutation, "pushDrawerState">;

export type SessionOrderFragment = {__typename?: "Session"} & Pick<Session, "order">;

export type SessionUpdatedAtFragment = {__typename?: "Session"} & Pick<Session, "updatedAt">;

export type SessionPlannedEndFragment = {__typename?: "Session"} & Pick<Session, "id" | "plannedEnd">;

export type OneSessionLifecycleFragment = {__typename?: "Session"} & Pick<Session, "id" | "lifecycle" | "actualStart">;

export type SessionPlannedStartFragment = {__typename?: "Session"} & Pick<Session, "id" | "startAt">;

export type OneSessionParticipantsFragment = {__typename?: "Session"} & {
    participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>;
};

export type OneAgendaItemNoDiffFragment = {__typename?: "AgendaItem"} & Pick<
    AgendaItem,
    | "id"
    | "type"
    | "createdAt"
    | "endedAt"
    | "updatedAt"
    | "startAt"
    | "title"
    | "userConfiguredDuration"
    | "descriptionJson"
    | "order"
    | "duration"
    | "timeSpentInSeconds"
    | "processing"
    | "locked"
    | "speakerNotes"
    | "isPaused"
    | "speakerNotesJson"
> & {
        artifact?: Maybe<{__typename?: "Artifact"} & OneArtifactFragment>;
        agendaItemSpeakers: Array<{__typename?: "AgendaItemSpeaker"} & AgendaItemSpeakerFragment>;
    };

export type AgendaItemSpeakerFragment = {__typename?: "AgendaItemSpeaker"} & Pick<AgendaItemSpeaker, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "AgendaItemSpeakerDiff"} & AgendaItemSpeakerDiffDataFragment>;
    } & AgendaItemSpeakerNoDiffDataFragment;

export type AgendaItemSpeakerNoDiffDataFragment = {__typename?: "AgendaItemSpeaker"} & Pick<
    AgendaItemSpeaker,
    "id" | "agendaItemId" | "speakerId" | "createdAt" | "updatedAt"
>;

export type FullAgendaItemFragment = {__typename?: "AgendaItem"} & Pick<AgendaItem, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "AgendaItemDiff"} & AgendaItemDiffDataFragment>;
    } & OneAgendaItemNoDiffFragment;

export type BreakoutRoomsConfigFragment = {__typename?: "BreakoutRoomsConfiguration"} & Pick<
    BreakoutRoomsConfiguration,
    "id" | "allowRoomNavigation" | "artifactIdOfBreakoutRooms"
> & {
        parentOfBreakoutRooms: {__typename?: "Session"} & Pick<Session, "name"> & {sessionId: Session["id"]} & {
                questionsContainer?: Maybe<
                    {__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id"> & {
                            questions: Array<{__typename?: "Question"} & Pick<Question, "id" | "markedAsAnswered">>;
                        }
                >;
            };
        participantsMap: Array<
            {__typename?: "BreakoutRoomsParticipantMap"} & Pick<BreakoutRoomsParticipantMap, "id" | "desiredRoomId"> & {
                    participantInParent: {__typename?: "Participant"} & Pick<
                        Participant,
                        "id" | "sessionId" | "status" | "isOwner" | "rbac" | "conferenceStatus" | "userId" | "guestId"
                    >;
                    participantInBreakoutRoom?: Maybe<
                        {__typename?: "Participant"} & Pick<
                            Participant,
                            "id" | "sessionId" | "status" | "isOwner" | "rbac" | "conferenceStatus"
                        >
                    >;
                }
        >;
        breakoutRooms: Array<
            {__typename?: "Session"} & Pick<Session, "id" | "name" | "description"> & {
                    questionsContainer?: Maybe<
                        {__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id"> & {
                                questions: Array<{__typename?: "Question"} & Pick<Question, "id" | "markedAsAnswered">>;
                            }
                    >;
                }
        >;
    };

export type SessionRoomNoDiffFragment = {__typename?: "Room"} & Pick<Room, "id" | "active" | "slug" | "createdAt">;

export type SessionRoomFragment = {__typename?: "Room"} & Pick<Room, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "RoomDiff"} & RoomDiffDataFragment>;
    } & SessionRoomNoDiffFragment;

export type OneSessionNoDiffFragment = {__typename?: "Session"} & Pick<
    Session,
    | "id"
    | "name"
    | "description"
    | "timeDependency"
    | "actualStart"
    | "endedAt"
    | "createdAt"
    | "isPrivate"
    | "syncToAgenda"
    | "updatedAt"
    | "backstage"
    | "shareScreenPid"
    | "quickSession"
    | "endByWorker"
    | "sendEmailsAutomatically"
    | "occurrenceId"
    | "createdFromTemplateId"
    | "timeZone"
    | "source"
    | "transcriptionActive"
    | "isBooking"
    | "generatedName"
    | "byTimezoneStartAt"
    | "byTimezonePlannedEnd"
    | "view"
    | "onboardingType"
    | "exitPageOfferId"
    | "organizationPlan"
    | "ownerHalfDayClock"
    | "ownerLanguage"
    | "workspaceId"
    | "timerStartAt"
    | "timerCurrentDuration"
    | "timerInitialDuration"
    | "timerState"
    | "feedbackFormId"
    | "feedbackFormAnswers"
    | "isTask"
    | "restrictedWidgets"
> & {
        questionsContainer?: Maybe<
            {__typename?: "QuestionsContainer"} & Pick<QuestionsContainer, "id"> & {
                    currentlyAnsweringQuestion?: Maybe<{__typename?: "Question"} & OneQuestionFragment>;
                }
        >;
        recurrenceData?: Maybe<{__typename?: "RecurrenceData"} & DesignerSessionRecurrenceDataFragment>;
        instanceOfRecurrence?: Maybe<
            {__typename?: "RecurrenceData"} & Pick<RecurrenceData, "id"> & {
                    session: {__typename?: "Session"} & Pick<Session, "id" | "createdAt">;
                }
        >;
        currentParticipant: {__typename?: "Participant"} & {pid: Participant["id"]};
        event?: Maybe<{__typename?: "SessionEvent"} & SessionEventFragment>;
        room?: Maybe<{__typename?: "Room"} & SessionRoomFragment>;
        exitPageOffer?: Maybe<{__typename?: "Offer"} & SessionOfferFragment>;
        sessionTags: Array<{__typename?: "SessionTag"} & Pick<SessionTag, "id" | "name">>;
        feedbackForm?: Maybe<{__typename?: "FeedbackForm"} & FeedbackFormFragment>;
    } & SessionOrderFragment &
    SessionPlannedStartFragment &
    SessionPlannedEndFragment &
    OneSessionLifecycleFragment &
    LobbyPictureImageDetailsFragment &
    OneSessionFlagsNoDiffFragment &
    SessionPollsForQueryFragment;

export type SessionOfferFragment = {__typename?: "Offer"} & Pick<Offer, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "OfferDiff"} & OfferDiffDataFragment>;
    } & OfferFragment;

export type SessionEventFragment = {__typename?: "SessionEvent"} & Pick<SessionEvent, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "SessionEventDiff"} & SessionEventDiffDataFragment>;
    } & SessionEventNoDiffFragment;

export type SessionEventNoDiffFragment = {__typename?: "SessionEvent"} & Pick<
    SessionEvent,
    | "id"
    | "state"
    | "bannerUrl"
    | "photoUrl"
    | "colorPalette"
    | "socialLinks"
    | "isPublic"
    | "slug"
    | "createdAt"
    | "updatedAt"
    | "registrationForm"
    | "utm"
    | "hasRegistration"
    | "requireApproval"
    | "registrationAnswers"
    | "speakerOrderJson"
    | "embedUrl"
    | "afterRegistrationRedirectUrl"
    | "afterRegistrationOfferId"
    | "maxSeats"
    | "seatsTaken"
    | "language"
> & {
        afterRegistrationOffer?: Maybe<{__typename?: "Offer"} & EventOfferFragment>;
        payments: Array<{__typename?: "PaymentConfig"} & PaymentConfigFragment>;
    };

export type OneSessionFlagsNoDiffNoIdFragment = {__typename?: "Session"} & Pick<
    Session,
    | "lobbyAccess"
    | "showAgendaInLobby"
    | "allowCollaborationInLobby"
    | "requestPermissionToJoin"
    | "requireConsentToRecord"
    | "enableReactionsType"
    | "askForGuestEmail"
    | "allowCameraType"
    | "allowMicrophoneType"
    | "allowScreenShareType"
    | "hideNonStreamingParticipants"
    | "reminderInMinutes"
    | "autoRecording"
    | "memoryAccessType"
    | "allowAgendaType"
    | "reminders"
    | "messageReminders"
    | "restrictedWidgets"
    | "autoTranscribing"
    | "disabledNotifications"
    | "autoStartSession"
    | "sendEndSessionEmail"
    | "fullSizeVideosInRecording"
    | "recordingConferenceView"
    | "recordingPresentationView"
    | "passcodeToJoin"
    | "provideSessionPasscode"
    | "bypassSessionPasscode"
    | "groupChatAccess"
    | "privateChatAccess"
    | "participantsMacroAccess"
    | "collectFeedback"
    | "showExitPageCta"
    | "hideIcsGuestList"
    | "livestreamOutputUrls"
    | "recordingType"
>;

export type OneSessionFlagsNoDiffFragment = {__typename?: "Session"} & Pick<Session, "id"> & OneSessionFlagsNoDiffNoIdFragment;

export type OneSessionFragment = {__typename?: "Session"} & Pick<Session, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "SessionDiff"} & SessionDiffDataFragment>;
    } & OneSessionNoDiffFragment;

export type LobbyPictureImageDetailsFragment = {__typename?: "Session"} & {
    lobbyPicture?: Maybe<
        {__typename?: "LobbyPicture"} & Pick<
            LobbyPicture,
            "id" | "originalPictureUrl" | "pictureWasUploaded" | "y" | "canBeRepositioned" | "createdAt" | "updatedAt"
        >
    >;
};

export type CurrentHostQueryVariables = Exact<{[key: string]: never}>;

export type CurrentHostQuery = {__typename?: "Query"} & {currentHost?: Maybe<{__typename?: "CurrentHost"} & Pick<CurrentHost, "type">>};

export type ParticipantRbacFragment = {__typename?: "Participant"} & Pick<Participant, "rbac">;

export type CurrentParticipantRbacFragment = {__typename?: "Participant"} & Pick<Participant, "rbac">;

export type CurrentParticipantDataFragment = {__typename?: "Participant"} & Pick<
    Participant,
    | "id"
    | "status"
    | "isApproved"
    | "isOwner"
    | "conferenceStatus"
    | "isRecorder"
    | "contextualNoteJson"
    | "updatedAt"
    | "lastSeenTimestamp"
    | "inviteStatus"
    | "hasAttendedSession"
    | "submittedPasscode"
    | "consentedToRecord"
> & {speakerDetails?: Maybe<{__typename?: "SessionSpeaker"} & Pick<SessionSpeaker, "id">>} & CurrentParticipantRbacFragment;

export type ParticipantStatusFragment = {__typename?: "Participant"} & Pick<Participant, "status">;

export type ParticipantSpeakerDetailsFragment = {__typename?: "Participant"} & {
    speakerDetails?: Maybe<{__typename?: "SessionSpeaker"} & SpeakerDetailsFragment>;
};

export type ParticipantPaymentTransactionFragment = {__typename?: "PaymentTransaction"} & Pick<
    PaymentTransaction,
    "id" | "checkoutSessionStatus" | "paymentIntendId" | "paymentIntendStatus"
> & {paymentProvider?: Maybe<{__typename?: "PaymentProvider"} & Pick<PaymentProvider, "id" | "name" | "accountName">>};

export type SessionParticipantFragment = {__typename?: "Participant"} & Pick<
    Participant,
    | "id"
    | "hasAttendedSession"
    | "mockedParticipant"
    | "isOwner"
    | "isApproved"
    | "inviteStatus"
    | "invitedByTheOwner"
    | "source"
    | "isRecorder"
    | "lastSeenTimestamp"
    | "updatedAt"
    | "createdAt"
    | "askToUnmute"
    | "askToStartCamera"
    | "controlStatus"
    | "submittedPasscode"
    | "isMainParticipant"
> & {
        dataWithNullableEmail: {__typename?: "ParticipantDataWithNullableEmail"} & Pick<
            ParticipantDataWithNullableEmail,
            "email" | "firstName" | "lastName" | "avatar" | "isGuest" | "token" | "userId"
        >;
        paymentTransaction?: Maybe<{__typename?: "PaymentTransaction"} & ParticipantPaymentTransactionFragment>;
    } & ParticipantStatusFragment &
    OneParticipantConferenceStatusFragment &
    ParticipantRbacFragment &
    ParticipantSpeakerDetailsFragment;

export type OneParticipantConferenceStatusFragment = {__typename?: "Participant"} & Pick<
    Participant,
    "conferenceStatus" | "changedBy" | "muted"
> & {
        statusChangedBy?: Maybe<
            {__typename?: "ParticipantStatusChangedBy"} & Pick<
                ParticipantStatusChangedBy,
                "participantId" | "firstName" | "lastName" | "unableToBecomeSpeaker"
            >
        >;
    };

export type OneMacroArtifactNoDiffFragment = {__typename?: "Artifact"} & Pick<
    Artifact,
    | "id"
    | "artifactId"
    | "startAt"
    | "endedAt"
    | "durationInSeconds"
    | "updatedAt"
    | "createdAt"
    | "isMacro"
    | "isConfigured"
    | "name"
    | "description"
    | "order"
> &
    ArtifactPropsMacroFragment;

export type OneMacroArtifactFragment = {__typename?: "Artifact"} & Pick<Artifact, "data" | "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "ArtifactDiff"} & ArtifactDiffDataFragment>;
    } & OneMacroArtifactNoDiffFragment;

export type ResourceResultsNoDiffFragment = {__typename?: "ResourceResult"} & Pick<ResourceResult, "id" | "createdAt" | "updatedAt">;

export type ArtifactResourceResultFragment = {__typename?: "ResourceResult"} & Pick<ResourceResult, "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "ResourceResultDiff"} & ResourceResultDiffDataFragment>;
    } & ResourceResultsNoDiffFragment;

export type OneSessionMacroArtifactsFragment = {__typename?: "Session"} & {
    macroArtifacts: Array<{__typename?: "Artifact"} & OneMacroArtifactFragment>;
};

export type OneSessionAgendaItemsFragment = {__typename?: "Session"} & {
    agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>;
};

export type OneArtifactNoDiffFragment = {__typename?: "Artifact"} & Pick<
    Artifact,
    | "id"
    | "artifactId"
    | "startAt"
    | "endedAt"
    | "durationInSeconds"
    | "createdAt"
    | "updatedAt"
    | "isMacro"
    | "isConfigured"
    | "name"
    | "description"
    | "order"
    | "userConfiguredDuration"
    | "isSystemArtifact"
> & {resourceResults: Array<{__typename?: "ResourceResult"} & ArtifactResourceResultFragment>} & ArtifactPropsFragment;

export type OneArtifactFragment = {__typename?: "Artifact"} & Pick<Artifact, "data" | "isDeleted" | "oldId"> & {
        update?: Maybe<{__typename?: "ArtifactDiff"} & ArtifactDiffDataFragment>;
    } & OneArtifactNoDiffFragment;

export type ArtifactPropsFragment = {__typename?: "Artifact"} & {
    properties: Array<
        {__typename?: "Property"} & Pick<Property, "id" | "key" | "value" | "createdAt" | "updatedAt" | "isDeleted" | "oldId"> & {
                update?: Maybe<{__typename?: "PropertyDiff"} & PropertyDiffDataFragment>;
            }
    >;
};

export type ArtifactPropsMacroFragment = {__typename?: "Artifact"} & {
    properties: Array<
        {__typename?: "Property"} & Pick<Property, "id" | "key" | "value" | "createdAt" | "updatedAt" | "isDeleted" | "oldId"> & {
                update?: Maybe<{__typename?: "PropertyDiff"} & PropertyDiffDataFragment>;
            }
    >;
};

export type SetSessionOrderMutationVariables = Exact<{
    sessionId: Scalars["String"];
    order: Scalars["Int"];
}>;

export type SetSessionOrderMutation = {__typename?: "Mutation"} & {updateSessionOrder: {__typename?: "Session"} & Pick<Session, "id">};

export type UpdateSessionTagsMutationVariables = Exact<{
    sessionId: Scalars["String"];
    tags: SessionTagsInput;
}>;

export type UpdateSessionTagsMutation = {__typename?: "Mutation"} & {
    updateSessionTags: {__typename?: "Session"} & Pick<Session, "id"> & {
            sessionTags: Array<{__typename?: "SessionTag"} & Pick<SessionTag, "id" | "name">>;
        };
};

export type PauseAgendaItemMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type PauseAgendaItemMutation = {__typename?: "Mutation"} & {pauseAgendaItem: {__typename?: "AgendaItem"} & Pick<AgendaItem, "id">};

export type SessionNoCacheFragment = {__typename?: "Session"} & {
    macroArtifacts: Array<{__typename?: "Artifact"} & OneMacroArtifactFragment>;
    agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>;
    participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>;
    currentParticipant: {__typename?: "Participant"} & CurrentParticipantDataFragment;
    childOfBreakoutRooms?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
} & OneSessionNoDiffFragment;

export type GetSessionQueryVariables = Exact<{
    id: Scalars["String"];
    includeRegistrationAnswers?: Maybe<Scalars["Boolean"]>;
}>;

export type GetSessionQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & {
            childOfBreakoutRooms?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
        } & OneSessionFragment
    >;
};

export type LocalAgendaItemsQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type LocalAgendaItemsQuery = {__typename?: "Query"} & {agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>};

export type LocalAgendaItemQueryVariables = Exact<{
    sessionId: Scalars["String"];
    order?: Maybe<Scalars["Int"]>;
    agendaItemId?: Maybe<Scalars["String"]>;
}>;

export type LocalAgendaItemQuery = {__typename?: "Query"} & {agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>};

export type LocalAgendaItemFindOneQueryVariables = Exact<{
    agendaItemId?: Maybe<Scalars["String"]>;
    sessionId: Scalars["String"];
}>;

export type LocalAgendaItemFindOneQuery = {__typename?: "Query"} & {
    agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>;
};

export type LocalMacroArtifactsQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type LocalMacroArtifactsQuery = {__typename?: "Query"} & {
    macroArtifacts: Array<{__typename?: "Artifact"} & OneMacroArtifactFragment>;
};

export type LocalMacroArtifactQueryVariables = Exact<{
    artifactId: Scalars["String"];
}>;

export type LocalMacroArtifactQuery = {__typename?: "Query"} & {
    macroArtifact?: Maybe<{__typename?: "Artifact"} & OneMacroArtifactFragment>;
};

export type LocalArtifactQueryVariables = Exact<{
    artifactId: Scalars["String"];
}>;

export type LocalArtifactQuery = {__typename?: "Query"} & {artifact?: Maybe<{__typename?: "Artifact"} & OneArtifactFragment>};

export type LocalParticipantsQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type LocalParticipantsQuery = {__typename?: "Query"} & {
    participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>;
};

export type LocalParticipantQueryVariables = Exact<{
    participantId: Scalars["String"];
}>;

export type LocalParticipantQuery = {__typename?: "Query"} & {
    participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>;
};

export type LocalCurrentParticipantQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type LocalCurrentParticipantQuery = {__typename?: "Query"} & {
    currentParticipant: {__typename?: "Participant"} & CurrentParticipantDataFragment;
};

export type LocalSessionOrderQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type LocalSessionOrderQuery = {__typename?: "Query"} & {session?: Maybe<{__typename?: "Session"} & Pick<Session, "order">>};

export type FullSessionQueryVariables = Exact<{
    id: Scalars["String"];
    includeRegistrationAnswers?: Maybe<Scalars["Boolean"]>;
}>;

export type FullSessionQuery = {__typename?: "Query"} & {
    session?: Maybe<
        {__typename?: "Session"} & Pick<Session, "collaborativeSession"> & {
                childOfBreakoutRooms?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
            } & OneSessionFragment
    >;
    macroArtifacts: Array<{__typename?: "Artifact"} & OneMacroArtifactFragment>;
    agendaItems: Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>;
    participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>;
    currentParticipant: {__typename?: "Participant"} & CurrentParticipantDataFragment;
};

export type GetParticipantConferenceTokenQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetParticipantConferenceTokenQuery = {__typename?: "Query"} & {
    currentParticipant: {__typename?: "Participant"} & Pick<Participant, "id"> & {
            conferenceToken?: Maybe<{__typename?: "ConferenceToken"} & Pick<ConferenceToken, "lobby" | "main">>;
        };
};

export type SendSessionDiffMutationVariables = Exact<{
    sessionId: Scalars["String"];
    diff: Scalars["Json"];
    persist?: Maybe<Scalars["Boolean"]>;
    releaseLock?: Maybe<Scalars["Boolean"]>;
}>;

export type SendSessionDiffMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendSessionDiff">;

export type DiscardEventChangesMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DiscardEventChangesMutation = {__typename?: "Mutation"} & Pick<Mutation, "discardEventChanges">;

export type UnlockEventMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type UnlockEventMutation = {__typename?: "Mutation"} & Pick<Mutation, "unlockEvent">;

export type LockEventMutationVariables = Exact<{
    id: Scalars["String"];
    setChanged?: Maybe<Scalars["Boolean"]>;
}>;

export type LockEventMutation = {__typename?: "Mutation"} & Pick<Mutation, "lockEvent">;

export type OnSessionDiffSendSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type OnSessionDiffSendSubscription = {__typename?: "Subscription"} & {
    onSessionDiffSend?: Maybe<
        {__typename?: "onSessionDiffSendResult"} & Pick<
            OnSessionDiffSendResult,
            "sessionId" | "collaborativeSessionJson" | "sessionDiffJson" | "persisted" | "discarded" | "corrupted"
        >
    >;
};

export type GetParticipantsQueryVariables = Exact<{
    where: ParticipantWhereInput;
}>;

export type GetParticipantsQuery = {__typename?: "Query"} & {
    participants: Array<{__typename?: "Participant"} & SessionParticipantFragment>;
};

export type LayoutContentQueryVariables = Exact<{[key: string]: never}>;

export type LayoutContentQuery = {__typename?: "Query"} & {
    content: {__typename?: "Content"} & Pick<Content, "isFullscreen" | "audienceMobileOpenState">;
};

export type LeftDrawerQueryVariables = Exact<{[key: string]: never}>;

export type LeftDrawerQuery = {__typename?: "Query"} & {leftDrawer: {__typename?: "LeftDrawer"} & Pick<LeftDrawer, "state" | "isDetached">};

export type RightDrawerQueryVariables = Exact<{[key: string]: never}>;

export type RightDrawerQuery = {__typename?: "Query"} & {
    rightDrawer: {__typename?: "RightDrawer"} & Pick<RightDrawer, "state" | "isDetached">;
};

export type LeftDrawerInPreviewQueryVariables = Exact<{[key: string]: never}>;

export type LeftDrawerInPreviewQuery = {__typename?: "Query"} & {
    leftDrawerInPreview: {__typename?: "LeftDrawerInPreview"} & Pick<LeftDrawerInPreview, "state">;
};

export type RightDrawerInPreviewQueryVariables = Exact<{[key: string]: never}>;

export type RightDrawerInPreviewQuery = {__typename?: "Query"} & {
    rightDrawerInPreview: {__typename?: "RightDrawerInPreview"} & Pick<RightDrawerInPreview, "state">;
};

export type LayoutQueryVariables = Exact<{[key: string]: never}>;

export type LayoutQuery = {__typename?: "Query"} & {
    leftDrawer: {__typename?: "LeftDrawer"} & Pick<LeftDrawer, "state">;
    rightDrawer: {__typename?: "RightDrawer"} & Pick<RightDrawer, "state">;
    leftDrawerInPreview: {__typename?: "LeftDrawerInPreview"} & Pick<LeftDrawerInPreview, "state">;
    rightDrawerInPreview: {__typename?: "RightDrawerInPreview"} & Pick<RightDrawerInPreview, "state">;
    content: {__typename?: "Content"} & Pick<Content, "isFullscreen" | "audienceMobileOpenState">;
};

export type SendSessionFlagsMutationVariables = Exact<{
    sessionId: Scalars["String"];
    flags: SessionFlags;
}>;

export type SendSessionFlagsMutation = {__typename?: "Mutation"} & {
    setSessionFlags?: Maybe<{__typename?: "Session"} & Pick<Session, "view"> & OneSessionFlagsNoDiffFragment>;
};

export type ChatUpdateFragment = {__typename?: "ChatUpdateWatchdogResult"} & {
    newChatMessage?: Maybe<{__typename?: "ChatMessage"} & OneChatMessageFragment>;
    newChatFile?: Maybe<{__typename?: "MessageFile"} & OneChatFileFragment>;
    newChatGroup?: Maybe<{__typename?: "ChatGroup"} & OneChatGroupWithoutMessagesFragment>;
    deleteChatMessage?: Maybe<{__typename?: "DeleteChatMessage"} & Pick<DeleteChatMessage, "id" | "chatGroupId">>;
    deleteChatFile?: Maybe<{__typename?: "DeleteChatFile"} & Pick<DeleteChatFile, "id" | "chatGroupId" | "chatmessageId">>;
};

export type WatchdogSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type WatchdogSubscription = {__typename?: "Subscription"} & {
    watchdog?: Maybe<
        {__typename?: "SessionWatchdogUpdateResult"} & Pick<
            SessionWatchdogUpdateResult,
            "id" | "deletedSession" | "deletedMacroArtifactIds" | "deletedAgendaItemIds" | "deletedParticipantIds"
        > & {
                session?: Maybe<{__typename?: "Session"} & OneSessionNoDiffFragment>;
                resourceNames?: Maybe<Array<{__typename?: "ResourceName"} & Pick<ResourceName, "resultId" | "name" | "artifactId">>>;
                upsertMacroArtifacts?: Maybe<Array<{__typename?: "Artifact"} & OneMacroArtifactFragment>>;
                upsertAgendaItems?: Maybe<Array<{__typename?: "AgendaItem"} & FullAgendaItemFragment>>;
                upsertRecordings?: Maybe<Array<{__typename?: "SessionRecording"} & SessionRecordingFragment>>;
                upsertParticipants?: Maybe<Array<{__typename?: "SessionParticipantUpdatesResult"} & ParticipantUpdateFragment>>;
                chatUpdate?: Maybe<{__typename?: "ChatUpdateWatchdogResult"} & ChatUpdateFragment>;
                newTranscript?: Maybe<
                    {__typename?: "SessionTranscript"} & Pick<
                        SessionTranscript,
                        "id" | "sessionId" | "participantId" | "sourceAudio" | "sourceLanguage" | "sourceTimestamp"
                    > & {
                            content: Array<
                                {__typename?: "SessionTranscriptContent"} & Pick<SessionTranscriptContent, "id" | "text" | "language">
                            >;
                        }
                >;
                deleteQuestion?: Maybe<{__typename?: "DeleteQuestion"} & Pick<DeleteQuestion, "id">>;
                drawerState?: Maybe<
                    {__typename?: "PushDrawerStateResult"} & {
                        leftDrawer?: Maybe<{__typename?: "PushDrawerState"} & Pick<PushDrawerState, "state" | "isDetached">>;
                        rightDrawer?: Maybe<
                            {__typename?: "PushDrawerState"} & Pick<PushDrawerState, "state" | "isDetached" | "activeComponent">
                        >;
                    }
                >;
                breakoutRoomArtifact?: Maybe<
                    {__typename?: "Artifact"} & Pick<Artifact, "id"> & {
                            breakoutRoomsConfig?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
                        }
                >;
                childOfBreakoutRooms?: Maybe<{__typename?: "BreakoutRoomsConfiguration"} & BreakoutRoomsConfigFragment>;
            }
    >;
};

export type WatchdogPrivateSubscriptionVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type WatchdogPrivateSubscription = {__typename?: "Subscription"} & {
    watchdogPrivate?: Maybe<
        {__typename?: "SessionWatchdogUpdatePrivateResult"} & Pick<
            SessionWatchdogUpdatePrivateResult,
            | "id"
            | "participantIndividualMute"
            | "participantIndividualStopCamera"
            | "softMuteAll"
            | "softStopCameraAll"
            | "stopScreenShare"
            | "nameOfJoinDeniedParticipant"
            | "sessionEndedByLimitation"
        > & {
                approvalRequestsAsOwner?: Maybe<
                    {__typename?: "ApprovalRequestsAsOwnerResult"} & Pick<
                        ApprovalRequestsAsOwnerResult,
                        "requestForParticipantIdResolved"
                    > & {
                            requestingParticipant?: Maybe<
                                {__typename?: "Participant"} & Pick<Participant, "id"> & {
                                        dataWithNullableEmail: {__typename?: "ParticipantDataWithNullableEmail"} & Pick<
                                            ParticipantDataWithNullableEmail,
                                            "firstName" | "lastName" | "avatar" | "isGuest" | "email" | "token" | "userId"
                                        >;
                                    }
                            >;
                        }
                >;
                approvalRequestsResponse?: Maybe<
                    {__typename?: "WaitForApprovalInSessionResult"} & Pick<
                        WaitForApprovalInSessionResult,
                        "hasBeenApproved" | "participantId"
                    >
                >;
                chatUpdate?: Maybe<{__typename?: "ChatUpdateWatchdogResult"} & ChatUpdateFragment>;
            }
    >;
};

export type UserUpdatesSubscriptionVariables = Exact<{[key: string]: never}>;

export type UserUpdatesSubscription = {__typename?: "Subscription"} & {
    userUpdates?: Maybe<
        {__typename?: "UserUpdatesPayload"} & Pick<UserUpdatesPayload, "id"> & {
                agendaTemplates?: Maybe<Array<{__typename?: "AgendaTemplate"} & AgendaTemplateFragment>>;
                resource?: Maybe<{__typename?: "Resource"} & ResourceFullFragment>;
            }
    >;
};

export type ParticipantUpdateFragment = {__typename?: "SessionParticipantUpdatesResult"} & Pick<
    SessionParticipantUpdatesResult,
    "action"
> & {
        participant: {__typename?: "Participant"} & SessionParticipantFragment;
        changedBy?: Maybe<
            {__typename?: "ParticipantChangedByData"} & Pick<
                ParticipantChangedByData,
                "participantId" | "firstName" | "lastName" | "unableToBecomeSpeaker"
            >
        >;
    };

export type LocalUserQueryVariables = Exact<{[key: string]: never}>;

export type LocalUserQuery = {__typename?: "Query"} & Pick<Query, "isAuthenticated"> & {
        localUser?: Maybe<{__typename?: "LocalUser"} & Pick<LocalUser, "email" | "firstName" | "lastName" | "role" | "language">>;
    };

export type SaveTakeawaysMutationVariables = Exact<{
    artifactId: Scalars["String"];
    properties: Array<PropertyCreateCustomInput> | PropertyCreateCustomInput;
}>;

export type SaveTakeawaysMutation = {__typename?: "Mutation"} & {
    updateOneArtifact?: Maybe<
        {__typename?: "Artifact"} & Pick<Artifact, "id"> & {
                properties: Array<{__typename?: "Property"} & Pick<Property, "id" | "key" | "value" | "createdAt" | "updatedAt">>;
            }
    >;
};

export type SendTakeawaysMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type SendTakeawaysMutation = {__typename?: "Mutation"} & Pick<Mutation, "sendTakeaways">;

export type LocalVersionQueryVariables = Exact<{[key: string]: never}>;

export type LocalVersionQuery = {__typename?: "Query"} & {version: {__typename?: "Version"} & Pick<Version, "isMismatch" | "chunkLoadErr">};

export type CreateWorkspaceMutationVariables = Exact<{
    name: Scalars["String"];
    slug?: Maybe<Scalars["String"]>;
    branding?: Maybe<BrandingCreateInput>;
}>;

export type CreateWorkspaceMutation = {__typename?: "Mutation"} & {
    createWorkspace: Array<{__typename?: "UserToWorkspacePermission"} & WorkspacePermissionFragment>;
};

export type SwitchWorkspaceMutationVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type SwitchWorkspaceMutation = {__typename?: "Mutation"} & {
    switchWorkspace: Array<{__typename?: "UserToWorkspacePermission"} & Pick<UserToWorkspacePermission, "id" | "activeWorkspace">>;
};

export type SwitchOrganizationMutationVariables = Exact<{
    organizationId: Scalars["String"];
}>;

export type SwitchOrganizationMutation = {__typename?: "Mutation"} & {
    switchOrganization: Array<
        {__typename?: "UserToOrganizationPermission"} & Pick<UserToOrganizationPermission, "id" | "activeOrganization">
    >;
};

export type AcceptOrganizationInviteMutationVariables = Exact<{
    organizationId: Scalars["String"];
}>;

export type AcceptOrganizationInviteMutation = {__typename?: "Mutation"} & {
    acceptOrganizationInvite: {__typename?: "UserToOrganizationPermission"} & Pick<UserToOrganizationPermission, "id" | "inviteStatus">;
};

export type RemoveMembersFromOrganizationMutationVariables = Exact<{
    emails: Array<Scalars["String"]> | Scalars["String"];
    organizationId: Scalars["String"];
}>;

export type RemoveMembersFromOrganizationMutation = {__typename?: "Mutation"} & Pick<Mutation, "removeMembersFromOrganization">;

export type SessionsShallowSearchQueryVariables = Exact<{
    term: Scalars["String"];
    pageToken?: Maybe<Scalars["String"]>;
    time: Scalars["DateTime"];
}>;

export type SessionsShallowSearchQuery = {__typename?: "Query"} & {
    sessionsShallowSearch: {__typename?: "ShallowSearchResult"} & Pick<ShallowSearchResult, "pageToken"> & {
            sessions: Array<
                {__typename?: "SessionResult"} & Pick<
                    SessionResult,
                    | "id"
                    | "recurrenceId"
                    | "name"
                    | "ownedBySelf"
                    | "plannedStart"
                    | "start"
                    | "end"
                    | "lifecycle"
                    | "isBooking"
                    | "isEvent"
                > & {
                        highlight: {__typename?: "Highlight"} & Pick<Highlight, "name" | "resourceNames"> & {
                                participants?: Maybe<
                                    Array<{__typename?: "ParticipantHighlight"} & Pick<ParticipantHighlight, "name" | "email">>
                                >;
                            };
                    }
            >;
        };
};

export type CreateSessionRecordingMutationVariables = Exact<{
    sessionId: Scalars["String"];
    recordingId: Scalars["String"];
    extraInput?: Maybe<UpdateRecordingInput>;
}>;

export type CreateSessionRecordingMutation = {__typename?: "Mutation"} & Pick<Mutation, "createSessionRecording">;

export type UpdateSessionRecordingMutationVariables = Exact<{
    recordingId: Scalars["String"];
    input: UpdateRecordingInput;
}>;

export type UpdateSessionRecordingMutation = {__typename?: "Mutation"} & Pick<Mutation, "updateSessionRecording">;

export type StopSessionRecordingMutationVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type StopSessionRecordingMutation = {__typename?: "Mutation"} & Pick<Mutation, "stopSessionRecording">;

export type DeleteSessionRecordingsMutationVariables = Exact<{
    recordingIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DeleteSessionRecordingsMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteSessionRecordings">;

export type CustomSmtpServerFragment = {__typename?: "CustomSmtpServer"} & Pick<
    CustomSmtpServer,
    | "id"
    | "name"
    | "host"
    | "port"
    | "username"
    | "password"
    | "senderEmail"
    | "senderName"
    | "senderDomain"
    | "verified"
    | "enabled"
    | "configType"
> & {assignedToWorkspaces: Array<{__typename?: "Workspace"} & Pick<Workspace, "id">>};

export type CustomSmtpServersQueryVariables = Exact<{[key: string]: never}>;

export type CustomSmtpServersQuery = {__typename?: "Query"} & {
    customSmtpServers: Array<{__typename?: "CustomSmtpServer"} & CustomSmtpServerFragment>;
};

export type CreateCustomSmtpServerMutationVariables = Exact<{
    name: Scalars["String"];
    host: Scalars["String"];
    port: Scalars["Int"];
    username: Scalars["String"];
    password: Scalars["String"];
    senderEmail: Scalars["String"];
    senderName?: Maybe<Scalars["String"]>;
    additionalConfig?: Maybe<Scalars["Json"]>;
    verified?: Maybe<Scalars["Boolean"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    configType: CustomSmtpServerConfigType;
}>;

export type CreateCustomSmtpServerMutation = {__typename?: "Mutation"} & {
    createCustomSmtpServer: {__typename?: "CustomSmtpServer"} & CustomSmtpServerFragment;
};

export type CreateCustomSpfServerMutationVariables = Exact<{
    name: Scalars["String"];
    senderEmail: Scalars["String"];
    senderName?: Maybe<Scalars["String"]>;
    senderDomain: Scalars["String"];
    additionalConfig?: Maybe<Scalars["Json"]>;
    verified: Scalars["Boolean"];
    enabled: Scalars["Boolean"];
    configType: CustomSmtpServerConfigType;
}>;

export type CreateCustomSpfServerMutation = {__typename?: "Mutation"} & {
    createCustomSmtpSpfServer: {__typename?: "CustomSmtpServer"} & CustomSmtpServerFragment;
};

export type UpdateCustomSmtpServerMutationVariables = Exact<{
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    senderName?: Maybe<Scalars["String"]>;
    host?: Maybe<Scalars["String"]>;
    port?: Maybe<Scalars["Int"]>;
    username?: Maybe<Scalars["String"]>;
    password?: Maybe<Scalars["String"]>;
    senderEmail?: Maybe<Scalars["String"]>;
    verified?: Maybe<Scalars["Boolean"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    assignToWorkspaceId?: Maybe<Scalars["String"]>;
    removeFromWorkspaceId?: Maybe<Scalars["String"]>;
}>;

export type UpdateCustomSmtpServerMutation = {__typename?: "Mutation"} & {
    updateCustomSmtpServer: {__typename?: "CustomSmtpServer"} & CustomSmtpServerFragment;
};

export type DeleteCustomSmtpServerMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteCustomSmtpServerMutation = {__typename?: "Mutation"} & {
    deleteCustomSmtpServer: {__typename?: "CustomSmtpServer"} & Pick<CustomSmtpServer, "id">;
};

export type SendCustomSmtpVerificationCodeEmailMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type SendCustomSmtpVerificationCodeEmailMutation = {__typename?: "Mutation"} & Pick<Mutation, "verifyCustomSmtpServer">;

export type CheckCustomSmtpVerificationCodeMutationVariables = Exact<{
    id: Scalars["String"];
    code: Scalars["String"];
}>;

export type CheckCustomSmtpVerificationCodeMutation = {__typename?: "Mutation"} & Pick<Mutation, "checkCodeCustomSmtpServer">;

export type GenerateCustomSmtpSpfServerTxtDataMutationVariables = Exact<{
    senderDomain: Scalars["String"];
}>;

export type GenerateCustomSmtpSpfServerTxtDataMutation = {__typename?: "Mutation"} & {
    generateCustomSmtpSpfServerTxtData: {__typename?: "CustomSmtpSpfServerTxtData"} & Pick<CustomSmtpSpfServerTxtData, "host" | "value">;
};

export type VerifyCustomSmtpSpfServerMutationVariables = Exact<{
    senderDomain: Scalars["String"];
}>;

export type VerifyCustomSmtpSpfServerMutation = {__typename?: "Mutation"} & {
    verifyCustomSmtpSpfServer: {__typename?: "CustomSmtpSpfServerVerificationResponse"} & Pick<
        CustomSmtpSpfServerVerificationResponse,
        "mailjetVerification" | "spfVerification"
    >;
};

export type CreateUploadUrlForTranscriptMutationVariables = Exact<{
    sessionId: Scalars["String"];
    participantId: Scalars["String"];
    mimeType: Scalars["String"];
}>;

export type CreateUploadUrlForTranscriptMutation = {__typename?: "Mutation"} & {
    createUploadUrlForTranscript: {__typename?: "TranscriptUploadUrl"} & Pick<TranscriptUploadUrl, "uploadUrl" | "url">;
};

export type CreateTranscriptMutationVariables = Exact<{
    sessionId: Scalars["String"];
    participantId: Scalars["String"];
    url: Scalars["String"];
    timestamp: Scalars["Float"];
    noise: Scalars["Int"];
    language?: Maybe<Scalars["String"]>;
    duration?: Maybe<Scalars["Int"]>;
}>;

export type CreateTranscriptMutation = {__typename?: "Mutation"} & Pick<Mutation, "createTranscript">;

export type GetSessionUtmAnalyticsQueryVariables = Exact<{
    sessionId: Scalars["String"];
}>;

export type GetSessionUtmAnalyticsQuery = {__typename?: "Query"} & Pick<Query, "getSessionUtmAnalytics">;

export type CnameFragment = {__typename?: "Cname"} & Pick<Cname, "id" | "domain" | "status" | "target" | "favicon"> & {
        cnameForWorkspaces: Array<{__typename?: "Workspace"} & Pick<Workspace, "id">>;
    };

export type GetAllCnamesQueryVariables = Exact<{[key: string]: never}>;

export type GetAllCnamesQuery = {__typename?: "Query"} & {getAllCnames: Array<{__typename?: "Cname"} & CnameFragment>};

export type GetCnameInfoQueryVariables = Exact<{
    id: Scalars["String"];
}>;

export type GetCnameInfoQuery = {__typename?: "Query"} & {getCnameInfo: {__typename?: "Cname"} & CnameFragment};

export type CreateCnameMutationVariables = Exact<{
    domain: Scalars["String"];
    target: Scalars["String"];
}>;

export type CreateCnameMutation = {__typename?: "Mutation"} & {createCname: {__typename?: "Cname"} & CnameFragment};

export type DeleteCnameMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteCnameMutation = {__typename?: "Mutation"} & Pick<Mutation, "deleteCname">;

export type UpdateCnameFaviconMutationVariables = Exact<{
    id: Scalars["String"];
    faviconUrl?: Maybe<Scalars["String"]>;
}>;

export type UpdateCnameFaviconMutation = {__typename?: "Mutation"} & {updateCnameFavicon: {__typename?: "Cname"} & CnameFragment};

export type AssignCnameWorkspaceIdMutationVariables = Exact<{
    id: Scalars["String"];
    workspaceId: Scalars["String"];
}>;

export type AssignCnameWorkspaceIdMutation = {__typename?: "Mutation"} & {assignCnameWorkspaceId: {__typename?: "Cname"} & CnameFragment};

export type DisconnectCnameWorkspaceIdMutationVariables = Exact<{
    id: Scalars["String"];
    workspaceId: Scalars["String"];
}>;

export type DisconnectCnameWorkspaceIdMutation = {__typename?: "Mutation"} & {
    disconnectCnameWorkspaceId: {__typename?: "Cname"} & CnameFragment;
};

export type SearchPaletteQueryVariables = Exact<{
    input?: Maybe<PaletteSearchInput>;
}>;

export type SearchPaletteQuery = {__typename?: "Query"} & {
    searchPalette: Array<
        | ({__typename: "PaletteItemAgenda"} & Pick<
              PaletteItemAgenda,
              "agendaId" | "sharedWith" | "id" | "isPublic" | "lastUsed" | "mode" | "name"
          >)
        | ({__typename: "PaletteItemFile"} & Pick<
              PaletteItemFile,
              "resourceId" | "file_type" | "id" | "isPublic" | "lastUsed" | "mode" | "name"
          >)
        | ({__typename: "PaletteItemTool"} & Pick<
              PaletteItemTool,
              "resourceId" | "tool_type" | "id" | "isPublic" | "lastUsed" | "mode" | "name"
          >)
    >;
};

export type GetRegistrationAnswersQueryVariables = Exact<{
    eventId: Scalars["String"];
}>;

export type GetRegistrationAnswersQuery = {__typename?: "Query"} & Pick<Query, "getRegistrationAnswers">;

export type RequestDeleteAccountConfirmCodeMutationVariables = Exact<{[key: string]: never}>;

export type RequestDeleteAccountConfirmCodeMutation = {__typename?: "Mutation"} & Pick<Mutation, "requestUserDeleteConfirmationCode">;

export type DeleteAccountMutationVariables = Exact<{
    confirmationCode: Scalars["String"];
}>;

export type DeleteAccountMutation = {__typename?: "Mutation"} & {deleteSelf?: Maybe<{__typename?: "User"} & Pick<User, "id">>};

export type UserOrganizationsForDeleteAccountQueryVariables = Exact<{
    where?: Maybe<OrganizationWhereInput>;
}>;

export type UserOrganizationsForDeleteAccountQuery = {__typename?: "Query"} & {
    userOrganizations?: Maybe<
        Array<
            {__typename?: "Organization"} & Pick<
                Organization,
                | "id"
                | "name"
                | "plan"
                | "planEffectiveCancellationDate"
                | "planNextPaymentDate"
                | "paddleSubscriptionState"
                | "subscriptionId"
                | "membersCount"
            > & {
                    members: Array<
                        {__typename?: "User"} & Pick<User, "id" | "email" | "firstName" | "lastName"> & {
                                organizationPermissions: Array<
                                    {__typename?: "UserToOrganizationPermission"} & Pick<
                                        UserToOrganizationPermission,
                                        "id" | "inviteStatus" | "suspended" | "permissionType"
                                    > & {organization: {__typename?: "Organization"} & Pick<Organization, "id" | "name">}
                                >;
                            }
                    >;
                }
        >
    >;
};

export type MessageExternalProviderFragment = {__typename?: "MessageExternalProvider"} & Pick<
    MessageExternalProvider,
    | "id"
    | "providerType"
    | "messageType"
    | "name"
    | "senderNumber"
    | "options"
    | "verified"
    | "enabled"
    | "organizationId"
    | "clientId"
    | "authToken"
    | "apiKey"
    | "apiSecret"
> & {assignedToWorkspaces: Array<{__typename?: "Workspace"} & Pick<Workspace, "id">>};

export type MessageExternalProvidersQueryVariables = Exact<{[key: string]: never}>;

export type MessageExternalProvidersQuery = {__typename?: "Query"} & {
    messageExternalProviders: Array<{__typename?: "MessageExternalProvider"} & MessageExternalProviderFragment>;
};

export type WorkspaceMessageExternalProviderQueryVariables = Exact<{
    workspaceId: Scalars["String"];
}>;

export type WorkspaceMessageExternalProviderQuery = {__typename?: "Query"} & {
    workspaceMessageExternalProvider?: Maybe<{__typename?: "MessageExternalProvider"} & MessageExternalProviderFragment>;
};

export type CreateMessageExternalProviderMutationVariables = Exact<{
    providerType: MessageExternalProviderType;
    messageType: MessageExternalProviderMessageType;
    name: Scalars["String"];
    clientId: Scalars["String"];
    authToken?: Maybe<Scalars["String"]>;
    apiKey?: Maybe<Scalars["String"]>;
    apiSecret?: Maybe<Scalars["String"]>;
    senderNumber: Scalars["String"];
    options?: Maybe<Scalars["Json"]>;
    verified?: Maybe<Scalars["Boolean"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
}>;

export type CreateMessageExternalProviderMutation = {__typename?: "Mutation"} & {
    createMessageExternalProvider: {__typename?: "MessageExternalProvider"} & MessageExternalProviderFragment;
};

export type UpdateMessageExternalProviderMutationVariables = Exact<{
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    senderNumber?: Maybe<Scalars["String"]>;
    verified?: Maybe<Scalars["Boolean"]>;
    enabled?: Maybe<Scalars["Boolean"]>;
    clientId?: Maybe<Scalars["String"]>;
    authToken?: Maybe<Scalars["String"]>;
    apiKey?: Maybe<Scalars["String"]>;
    apiSecret?: Maybe<Scalars["String"]>;
    assignToWorkspaceId?: Maybe<Scalars["String"]>;
    removeFromWorkspaceId?: Maybe<Scalars["String"]>;
}>;

export type UpdateMessageExternalProviderMutation = {__typename?: "Mutation"} & {
    updateMessageExternalProvider: {__typename?: "MessageExternalProvider"} & MessageExternalProviderFragment;
};

export type SendCodeMessageExternalProviderMutationVariables = Exact<{
    id: Scalars["String"];
    receiverNumber: Scalars["String"];
}>;

export type SendCodeMessageExternalProviderMutation = {__typename?: "Mutation"} & Pick<Mutation, "verifyMessageExternalProvider">;

export type CheckCodeMessageExternalProviderMutationVariables = Exact<{
    id: Scalars["String"];
    code: Scalars["String"];
}>;

export type CheckCodeMessageExternalProviderMutation = {__typename?: "Mutation"} & Pick<Mutation, "checkCodeMessageExternalProvider">;

export type DeleteMessageExternalProviderMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteMessageExternalProviderMutation = {__typename?: "Mutation"} & {
    deleteMessageExternalProvider: {__typename?: "MessageExternalProvider"} & Pick<MessageExternalProvider, "id">;
};

export type CustomSmsMessageTemplateFragment = {__typename?: "CustomMessageTemplate"} & Pick<
    CustomMessageTemplate,
    "id" | "templateId" | "disableSending" | "name" | "body" | "config"
>;

export type CustomSmsMessageTemplatesQueryVariables = Exact<{[key: string]: never}>;

export type CustomSmsMessageTemplatesQuery = {__typename?: "Query"} & {
    customMessageTemplates: Array<{__typename?: "CustomMessageTemplate"} & CustomSmsMessageTemplateFragment>;
};

export type CreateCustomSmsMessageTemplateMutationVariables = Exact<{
    templateId: Scalars["String"];
    name: Scalars["String"];
    body: Scalars["String"];
    config?: Maybe<Scalars["Json"]>;
    disableSending: Scalars["Boolean"];
}>;

export type CreateCustomSmsMessageTemplateMutation = {__typename?: "Mutation"} & {
    createCustomMessageTemplate: {__typename?: "CustomMessageTemplate"} & CustomSmsMessageTemplateFragment;
};

export type UpdateCustomSmsMessageTemplateMutationVariables = Exact<{
    id: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    body?: Maybe<Scalars["String"]>;
    config?: Maybe<Scalars["Json"]>;
    disableSending?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateCustomSmsMessageTemplateMutation = {__typename?: "Mutation"} & {
    updateCustomMessageTemplate: {__typename?: "CustomMessageTemplate"} & CustomSmsMessageTemplateFragment;
};

export type GetEntitiesUsingSmsTemplateQueryVariables = Exact<{
    templateId: Scalars["String"];
}>;

export type GetEntitiesUsingSmsTemplateQuery = {__typename?: "Query"} & {
    entitiesUsingCustomMessageTemplate: Array<
        {__typename?: "EntityUsingCustomMessageTemplate"} & Pick<EntityUsingCustomMessageTemplate, "id" | "name">
    >;
};

export type DeleteCustomSmsMessageTemplateMutationVariables = Exact<{
    id: Scalars["String"];
}>;

export type DeleteCustomSmsMessageTemplateMutation = {__typename?: "Mutation"} & {
    deleteCustomMessageTemplate: {__typename?: "CustomMessageTemplate"} & Pick<CustomMessageTemplate, "id">;
};

export type SubscribeUserToAllMarketingEmailsMutationVariables = Exact<{
    subscribe: Scalars["Boolean"];
}>;

export type SubscribeUserToAllMarketingEmailsMutation = {__typename?: "Mutation"} & Pick<Mutation, "subscribeUserToAllMarketingEmails">;

export type AgendaItemDiffDataFragment = {__typename?: "AgendaItemDiff"} & Pick<
    AgendaItemDiff,
    | "createdAt"
    | "descriptionJson"
    | "duration"
    | "endedAt"
    | "newId"
    | "isPaused"
    | "locked"
    | "order"
    | "processing"
    | "speakerNotes"
    | "speakerNotesJson"
    | "startAt"
    | "timeSpentInSeconds"
    | "title"
    | "type"
    | "updatedAt"
    | "userConfiguredDuration"
>;

export type AgendaItemSpeakerDiffDataFragment = {__typename?: "AgendaItemSpeakerDiff"} & Pick<
    AgendaItemSpeakerDiff,
    "agendaItemId" | "createdAt" | "newId" | "speakerId" | "updatedAt"
>;

export type ArtifactDiffDataFragment = {__typename?: "ArtifactDiff"} & Pick<
    ArtifactDiff,
    | "artifactId"
    | "createdAt"
    | "deniedSpeakerUserIds"
    | "description"
    | "durationInSeconds"
    | "endedAt"
    | "newId"
    | "isConfigured"
    | "isMacro"
    | "isSystemArtifact"
    | "name"
    | "order"
    | "startAt"
    | "updatedAt"
    | "userConfiguredDuration"
>;

export type OfferDiffDataFragment = {__typename?: "OfferDiff"} & Pick<
    OfferDiff,
    "createdAt" | "ctaText" | "ctaUrl" | "description" | "newId" | "imageUrl" | "sessionId" | "title" | "updatedAt" | "userId"
>;

export type PropertyDiffDataFragment = {__typename?: "PropertyDiff"} & Pick<
    PropertyDiff,
    "createdAt" | "newId" | "key" | "updatedAt" | "value"
>;

export type RecurrenceDataDiffDataFragment = {__typename?: "RecurrenceDataDiff"} & Pick<
    RecurrenceDataDiff,
    | "byDay"
    | "byMonth"
    | "byMonthDay"
    | "bySetPos"
    | "count"
    | "createdAt"
    | "dtStart"
    | "exclude"
    | "frequency"
    | "newId"
    | "instancesOccurrenceIds"
    | "interval"
    | "sessionId"
    | "until"
    | "updatedAt"
>;

export type ResourceResultDiffDataFragment = {__typename?: "ResourceResultDiff"} & Pick<
    ResourceResultDiff,
    "content" | "createdAt" | "newId" | "resourceContentSnapshot" | "resourceId" | "type" | "updatedAt"
>;

export type RoomDiffDataFragment = {__typename?: "RoomDiff"} & Pick<
    RoomDiff,
    "active" | "activeSessionId" | "createdAt" | "newId" | "sessionTemplateId" | "slug" | "updatedAt"
>;

export type SessionDiffDataFragment = {__typename?: "SessionDiff"} & Pick<
    SessionDiff,
    | "actualStart"
    | "allowAgendaType"
    | "allowCameraType"
    | "allowCollaborationInLobby"
    | "allowMicrophoneType"
    | "allowScreenShareType"
    | "askForGuestEmail"
    | "autoRecording"
    | "autoStartSession"
    | "autoTranscribing"
    | "backstage"
    | "bypassSessionPasscode"
    | "collaborativeSession"
    | "collectFeedback"
    | "createdAt"
    | "createdFromTemplateId"
    | "createdFromYourFirstSessionTemplate"
    | "description"
    | "disabledNotifications"
    | "enableReactionsType"
    | "endByWorker"
    | "endedAt"
    | "exitPageOfferId"
    | "feedbackFormAnswers"
    | "feedbackFormId"
    | "fullSizeVideosInRecording"
    | "groupChatAccess"
    | "hideIcsGuestList"
    | "hideNonStreamingParticipants"
    | "newId"
    | "isBooking"
    | "isPrivate"
    | "isTask"
    | "lifecycle"
    | "livestreamOutputUrls"
    | "lobbyAccess"
    | "memoryAccessType"
    | "messageReminders"
    | "name"
    | "occurrenceId"
    | "onboardingType"
    | "order"
    | "ownerHalfDayClock"
    | "ownerLanguage"
    | "participantsMacroAccess"
    | "passcodeToJoin"
    | "plannedEnd"
    | "privateChatAccess"
    | "provideSessionPasscode"
    | "quickSession"
    | "recordingConferenceView"
    | "recordingPresentationView"
    | "recordingType"
    | "recurrenceDataId"
    | "reminderInMinutes"
    | "reminders"
    | "requestPermissionToJoin"
    | "requireConsentToRecord"
    | "restrictedWidgets"
    | "roomId"
    | "sendEmailsAutomatically"
    | "sendEndSessionEmail"
    | "shareScreenPid"
    | "showAgendaInLobby"
    | "showExitPageCta"
    | "source"
    | "speakerUserIds"
    | "startAt"
    | "syncToAgenda"
    | "timeDependency"
    | "timeZone"
    | "timerCurrentDuration"
    | "timerInitialDuration"
    | "timerStartAt"
    | "timerState"
    | "transcriptionActive"
    | "updatedAt"
    | "view"
    | "workspaceId"
>;

export type SessionEventDiffDataFragment = {__typename?: "SessionEventDiff"} & Pick<
    SessionEventDiff,
    | "afterRegistrationOfferId"
    | "afterRegistrationRedirectUrl"
    | "bannerUrl"
    | "colorPalette"
    | "createdAt"
    | "embedUrl"
    | "hasRegistration"
    | "newId"
    | "isPublic"
    | "language"
    | "maxSeats"
    | "photoUrl"
    | "registrationAnswers"
    | "registrationForm"
    | "requireApproval"
    | "seatsTaken"
    | "sessionId"
    | "slug"
    | "socialLinks"
    | "speakerOrderJson"
    | "state"
    | "updatedAt"
    | "utm"
>;

export const OneChatGroupWithoutMessagesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneChatGroupWithoutMessages"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ChatGroup"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "clientOnly"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "unreadCount"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "fetchedMessagesOverNetwork"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneChatGroupWithoutMessagesFragment, unknown>;
export const OneChatMessageFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneChatMessage"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ChatMessage"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "body"}},
                    {kind: "Field", name: {kind: "Name", value: "globalMessage"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "chatmessageId"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                    {kind: "Field", name: {kind: "Name", value: "chatGroupId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "replyToMessage"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "body"}},
                                {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneChatMessageFragment, unknown>;
export const OneChatGroupFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneChatGroup"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ChatGroup"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneChatGroupWithoutMessages"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "messages"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "createdAt"},
                                            value: {kind: "EnumValue", value: "desc"},
                                        },
                                    ],
                                },
                            },
                            {kind: "Argument", name: {kind: "Name", value: "take"}, value: {kind: "IntValue", value: "1"}},
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatMessage"}}],
                        },
                    },
                ],
            },
        },
        ...OneChatGroupWithoutMessagesFragmentDoc.definitions,
        ...OneChatMessageFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneChatGroupFragment, unknown>;
export const QuestionParticipantFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "QuestionParticipant"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "dataWithNullableEmail"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                                {kind: "Field", name: {kind: "Name", value: "avatar"}},
                                {kind: "Field", name: {kind: "Name", value: "token"}},
                                {kind: "Field", name: {kind: "Name", value: "userId"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<QuestionParticipantFragment, unknown>;
export const OneTranscriptMessageFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneTranscriptMessage"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionTranscript"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                    {kind: "Field", name: {kind: "Name", value: "sourceTimestamp"}},
                    {kind: "Field", name: {kind: "Name", value: "sourceLanguage"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "content"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "preferredLanguage"},
                                value: {kind: "Variable", name: {kind: "Name", value: "preferredLanguage"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "text"}},
                                {kind: "Field", name: {kind: "Name", value: "language"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneTranscriptMessageFragment, unknown>;
export const AccessListItemInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AccessListItemInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AccessListItem"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "agendatemplateId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "user"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "resourceId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AccessListItemInfoFragment, unknown>;
export const BookingScheduleFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingSchedule"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingSchedule"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "default"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "hours"}},
                    {kind: "Field", name: {kind: "Name", value: "days"}},
                    {kind: "Field", name: {kind: "Name", value: "maxDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "temporary"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "usedBy"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "eventId"}},
                                {kind: "Field", name: {kind: "Name", value: "eventName"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingScheduleFragment, unknown>;
export const BookingCollaboratorScheduleFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingCollaboratorSchedule"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingSchedule"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "hours"}},
                    {kind: "Field", name: {kind: "Name", value: "days"}},
                    {kind: "Field", name: {kind: "Name", value: "maxDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "temporary"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "usedBy"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "eventId"}},
                                {kind: "Field", name: {kind: "Name", value: "eventName"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingCollaboratorScheduleFragment, unknown>;
export const BookingCollaboratorFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingCollaborator"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingCollaborator"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "enabled"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "rbac"}},
                    {kind: "Field", name: {kind: "Name", value: "priority"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "user"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "schedule"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingCollaboratorSchedule"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "bookingsCount"}},
                    {kind: "Field", name: {kind: "Name", value: "externalCalendarConnections"}},
                ],
            },
        },
        ...BookingCollaboratorScheduleFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BookingCollaboratorFragment, unknown>;
export const PaymentProviderFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PaymentProvider"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaymentProvider"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "accountName"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaymentProviderFragment, unknown>;
export const PaymentConfigFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PaymentConfig"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaymentConfig"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "enabled"}},
                    {kind: "Field", name: {kind: "Name", value: "amount"}},
                    {kind: "Field", name: {kind: "Name", value: "currency"}},
                    {kind: "Field", name: {kind: "Name", value: "terms"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paymentProviders"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentProvider"}}],
                        },
                    },
                ],
            },
        },
        ...PaymentProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<PaymentConfigFragment, unknown>;
export const BookingEventLitItemFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingEventLitItem"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "disabled"}},
                    {kind: "Field", name: {kind: "Name", value: "isListed"}},
                    {kind: "Field", name: {kind: "Name", value: "duration"}},
                    {kind: "Field", name: {kind: "Name", value: "additionalDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "scheduleSource"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "schedule"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingSchedule"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "temporarySchedule"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingSchedule"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agenda"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "seen"}},
                    {kind: "Field", name: {kind: "Name", value: "bookingsCount"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "owner"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "collaborators"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingCollaborator"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "payments"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                        },
                    },
                ],
            },
        },
        ...BookingScheduleFragmentDoc.definitions,
        ...BookingCollaboratorFragmentDoc.definitions,
        ...PaymentConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BookingEventLitItemFragment, unknown>;
export const BookingEventFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingEvent"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "BookingEventLitItem"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "additionalDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "from"}},
                    {kind: "Field", name: {kind: "Name", value: "to"}},
                    {kind: "Field", name: {kind: "Name", value: "scheduleWindow"}},
                    {kind: "Field", name: {kind: "Name", value: "questionnare"}},
                    {kind: "Field", name: {kind: "Name", value: "settings"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "buffer"}},
                    {kind: "Field", name: {kind: "Name", value: "additionalRules"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "ignoreSharedCalendars"}},
                    {kind: "Field", name: {kind: "Name", value: "collaborationDistribution"}},
                    {kind: "Field", name: {kind: "Name", value: "allowAdditionalGuests"}},
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationRedirectUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationOfferId"}},
                ],
            },
        },
        ...BookingEventLitItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BookingEventFragment, unknown>;
export const PublicBookingEventAvailableSlotFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PublicBookingEventAvailableSlot"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PublicBookingEventAvailableSlot"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "day"}},
                    {kind: "Field", name: {kind: "Name", value: "slot"}},
                    {kind: "Field", name: {kind: "Name", value: "collaborators"}},
                    {kind: "Field", name: {kind: "Name", value: "available"}},
                    {kind: "Field", name: {kind: "Name", value: "conflicts"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<PublicBookingEventAvailableSlotFragment, unknown>;
export const PublicBookingEventBrandingFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PublicBookingEventBranding"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Branding"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "logo"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionBackground"}},
                    {kind: "Field", name: {kind: "Name", value: "styleTag"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<PublicBookingEventBrandingFragment, unknown>;
export const OfferFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "Offer"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Offer"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "ctaText"}},
                    {kind: "Field", name: {kind: "Name", value: "ctaUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OfferFragment, unknown>;
export const PublicBookingEventFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PublicBookingEvent"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PublicBookingEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "disabled"}},
                    {kind: "Field", name: {kind: "Name", value: "duration"}},
                    {kind: "Field", name: {kind: "Name", value: "additionalDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerId"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerFirstName"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerLastName"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerProfilePicture"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerFirstDayOfWeek"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerHalfDayClock"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerLanguage"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "collaborators"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "collaboratorId"}},
                                {kind: "Field", name: {kind: "Name", value: "userId"}},
                                {kind: "Field", name: {kind: "Name", value: "userName"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "branding"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicBookingEventBranding"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "from"}},
                    {kind: "Field", name: {kind: "Name", value: "to"}},
                    {kind: "Field", name: {kind: "Name", value: "days"}},
                    {kind: "Field", name: {kind: "Name", value: "hours"}},
                    {kind: "Field", name: {kind: "Name", value: "questionnare"}},
                    {kind: "Field", name: {kind: "Name", value: "allowAdditionalGuests"}},
                    {kind: "Field", name: {kind: "Name", value: "organizationPlan"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "payments"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationRedirectUrl"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "afterRegistrationOffer"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...PublicBookingEventBrandingFragmentDoc.definitions,
        ...PaymentConfigFragmentDoc.definitions,
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<PublicBookingEventFragment, unknown>;
export const BookedEventFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookedEvent"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookedEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "additionalInformation"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionLifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "duration"}},
                    {kind: "Field", name: {kind: "Name", value: "token"}},
                    {kind: "Field", name: {kind: "Name", value: "participantName"}},
                    {kind: "Field", name: {kind: "Name", value: "participantEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "guests"}},
                    {kind: "Field", name: {kind: "Name", value: "requestPayment"}},
                    {kind: "Field", name: {kind: "Name", value: "checkoutStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "checkoutUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "questionnare"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookedEventFragment, unknown>;
export const BookedSessionParticipantFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookedSessionParticipant"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookedSessionParticipantFragment, unknown>;
export const BookedSessionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookedSession"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookedSessionParticipant"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                ],
            },
        },
        ...BookedSessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BookedSessionFragment, unknown>;
export const PaymentTransactionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PaymentTransaction"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaymentTransaction"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "checkoutSessionStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "paymentIntendId"}},
                    {kind: "Field", name: {kind: "Name", value: "paymentIntendStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "paymentProviderType"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paymentProvider"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentProvider"}}],
                        },
                    },
                ],
            },
        },
        ...PaymentProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<PaymentTransactionFragment, unknown>;
export const BookingFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "Booking"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Booking"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "seen"}},
                    {kind: "Field", name: {kind: "Name", value: "guests"}},
                    {kind: "Field", name: {kind: "Name", value: "participantName"}},
                    {kind: "Field", name: {kind: "Name", value: "participantEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "additionalInformation"}},
                    {kind: "Field", name: {kind: "Name", value: "questionnare"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "assignee"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "user"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                            {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                            {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookedSession"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paymentTransaction"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentTransaction"}}],
                        },
                    },
                ],
            },
        },
        ...BookedSessionFragmentDoc.definitions,
        ...PaymentTransactionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BookingFragment, unknown>;
export const BookingsCountFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingsCount"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingsCount"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "canceled"}},
                    {kind: "Field", name: {kind: "Name", value: "upcoming"}},
                    {kind: "Field", name: {kind: "Name", value: "all"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingsCountFragment, unknown>;
export const BookingEventContactFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BookingEventContact"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BookingEventContact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "participantType"}},
                    {kind: "Field", name: {kind: "Name", value: "attendance"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionDate"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "registrationAnswers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "key"}},
                                {kind: "Field", name: {kind: "Name", value: "value"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingEventContactFragment, unknown>;
export const CalendarEventParticipantFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CalendarEventParticipant"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ParticipantForEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "avatar"}},
                    {kind: "Field", name: {kind: "Name", value: "token"}},
                    {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                    {kind: "Field", name: {kind: "Name", value: "rbac"}},
                    {kind: "Field", name: {kind: "Name", value: "token"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<CalendarEventParticipantFragment, unknown>;
export const CalendarEventRecurrenceDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CalendarEventRecurrenceData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "RecurrenceData"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "dtStart"}},
                    {kind: "Field", name: {kind: "Name", value: "frequency"}},
                    {kind: "Field", name: {kind: "Name", value: "count"}},
                    {kind: "Field", name: {kind: "Name", value: "interval"}},
                    {kind: "Field", name: {kind: "Name", value: "until"}},
                    {kind: "Field", name: {kind: "Name", value: "byDay"}},
                    {kind: "Field", name: {kind: "Name", value: "byMonth"}},
                    {kind: "Field", name: {kind: "Name", value: "byMonthDay"}},
                    {kind: "Field", name: {kind: "Name", value: "bySetPos"}},
                    {kind: "Field", name: {kind: "Name", value: "wkst"}},
                    {kind: "Field", name: {kind: "Name", value: "exclude"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "firstInstance"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {kind: "Field", name: {kind: "Name", value: "instancesOccurrenceIds"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<CalendarEventRecurrenceDataFragment, unknown>;
export const CalendarEventEventCustomFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CalendarEventEventCustom"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "EventForEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<CalendarEventEventCustomFragment, unknown>;
export const ExternalCalendarEventFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ExternalCalendarEvent"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ExternalCalendarEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "calendarId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "type"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "start"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "date"}},
                                {kind: "Field", name: {kind: "Name", value: "dateTime"}},
                                {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "end"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "date"}},
                                {kind: "Field", name: {kind: "Name", value: "dateTime"}},
                                {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "recurrence"}},
                    {kind: "Field", name: {kind: "Name", value: "rsvp"}},
                    {kind: "Field", name: {kind: "Name", value: "isBusy"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ExternalCalendarEventFragment, unknown>;
export const CalendarEventCustomFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CalendarEventCustom"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CalendarCustomEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "isAssistant"}},
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                    {kind: "Field", name: {kind: "Name", value: "isBillable"}},
                    {kind: "Field", name: {kind: "Name", value: "occurrenceId"}},
                    {kind: "Field", name: {kind: "Name", value: "instanceId"}},
                    {kind: "Field", name: {kind: "Name", value: "quickSession"}},
                    {kind: "Field", name: {kind: "Name", value: "isBooking"}},
                    {kind: "Field", name: {kind: "Name", value: "isEvent"}},
                    {kind: "Field", name: {kind: "Name", value: "isRoom"}},
                    {kind: "Field", name: {kind: "Name", value: "currentParticipantInviteStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "memoryAccessType"}},
                    {kind: "Field", name: {kind: "Name", value: "backstage"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "recurrenceParentId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "instanceOfRecurrence"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "isParticipantInRecurrenceParent"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventParticipant"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "recurrenceData"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventRecurrenceData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "event"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventEventCustom"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "externalEvent"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendarEvent"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "organizationId"}},
                    {kind: "Field", name: {kind: "Name", value: "isTask"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                ],
            },
        },
        ...CalendarEventParticipantFragmentDoc.definitions,
        ...CalendarEventRecurrenceDataFragmentDoc.definitions,
        ...CalendarEventEventCustomFragmentDoc.definitions,
        ...ExternalCalendarEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CalendarEventCustomFragment, unknown>;
export const ExternalCalendarFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ExternalCalendar"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ExternalCalendar"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "isShared"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ExternalCalendarFragment, unknown>;
export const CustomEmailContentFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CustomEmailContent"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CustomEmailContent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "value"}},
                    {kind: "Field", name: {kind: "Name", value: "hidden"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<CustomEmailContentFragment, unknown>;
export const SessionRecordingFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionRecording"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionRecording"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "livestreamOutputUrls"}},
                    {kind: "Field", name: {kind: "Name", value: "permission"}},
                    {kind: "Field", name: {kind: "Name", value: "downloadUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "startedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "processingStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "version"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionRecordingFragment, unknown>;
export const ResourceFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "Resource"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Resource"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "content"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "readOnly"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {kind: "Field", name: {kind: "Name", value: "processingDetails"}},
                    {kind: "Field", name: {kind: "Name", value: "processingStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                    {kind: "Field", name: {kind: "Name", value: "isTemplate"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResourceFragment, unknown>;
export const ResourceFullFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ResourceFull"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Resource"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "readOnly"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "content"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                    {kind: "Field", name: {kind: "Name", value: "isTemplate"}},
                    {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "owner"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "processingStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "processingDetails"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResourceFullFragment, unknown>;
export const ResourceResultFullFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ResourceResultFull"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ResourceResult"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "resourceContentSnapshot"}},
                    {kind: "Field", name: {kind: "Name", value: "resourceId"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "content"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resource"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceFull"}}],
                        },
                    },
                ],
            },
        },
        ...ResourceFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ResourceResultFullFragment, unknown>;
export const ResourceNameFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ResourceName"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ResourceName"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "resultId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResourceNameFragment, unknown>;
export const SubscriptionDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SubscriptionDetails"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SubscriptionDetailsPayload"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "organization_id"}},
                    {kind: "Field", name: {kind: "Name", value: "cancel_url"}},
                    {kind: "Field", name: {kind: "Name", value: "update_url"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                    {kind: "Field", name: {kind: "Name", value: "user_email"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "last_payment"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "amount"}},
                                {kind: "Field", name: {kind: "Name", value: "currency"}},
                                {kind: "Field", name: {kind: "Name", value: "date"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "next_payment"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "amount"}},
                                {kind: "Field", name: {kind: "Name", value: "currency"}},
                                {kind: "Field", name: {kind: "Name", value: "date"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "payment_information"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "card_type"}},
                                {kind: "Field", name: {kind: "Name", value: "last_four_digits"}},
                                {kind: "Field", name: {kind: "Name", value: "expiry_date"}},
                                {kind: "Field", name: {kind: "Name", value: "payment_method"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubscriptionDetailsFragment, unknown>;
export const UserPaddleTransactionsInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "UserPaddleTransactionsInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "UserPaddleTransaction"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "orderId"}},
                    {kind: "Field", name: {kind: "Name", value: "checkoutId"}},
                    {kind: "Field", name: {kind: "Name", value: "amount"}},
                    {kind: "Field", name: {kind: "Name", value: "currency"}},
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "productId"}},
                    {kind: "Field", name: {kind: "Name", value: "subscriptionId"}},
                    {kind: "Field", name: {kind: "Name", value: "subscriptionStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "receiptUrl"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserPaddleTransactionsInfoFragment, unknown>;
export const OneSessionUsingResultFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionUsingResult"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionsUsingResourceResult"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionLifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerId"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerFirstName"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerLastName"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerProfilePicture"}},
                    {kind: "Field", name: {kind: "Name", value: "recurrenceId"}},
                    {kind: "Field", name: {kind: "Name", value: "isEvent"}},
                    {kind: "Field", name: {kind: "Name", value: "isBooking"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneSessionUsingResultFragment, unknown>;
export const ContactInfoWithoutGroupsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ContactInfoWithoutGroups"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CustomContact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "company"}},
                    {kind: "Field", name: {kind: "Name", value: "companyRole"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "favorite"}},
                    {kind: "Field", name: {kind: "Name", value: "source"}},
                    {kind: "Field", name: {kind: "Name", value: "externalSyncedEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "userProfilePicture"}},
                    {kind: "Field", name: {kind: "Name", value: "userSuspended"}},
                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ContactInfoWithoutGroupsFragment, unknown>;
export const ContactInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ContactInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CustomContact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ContactInfoWithoutGroups"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "group"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...ContactInfoWithoutGroupsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ContactInfoFragment, unknown>;
export const ContactGroupInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ContactGroupInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CustomContactGroup"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "source"}},
                    {kind: "Field", name: {kind: "Name", value: "externalSyncedEmail"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ContactGroupInfoFragment, unknown>;
export const SessionOrderFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionOrder"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "order"}}]},
        },
    ],
} as unknown as DocumentNode<SessionOrderFragment, unknown>;
export const OneSessionLifecycleFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionLifecycle"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneSessionLifecycleFragment, unknown>;
export const PropertyDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PropertyDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PropertyDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "key"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "value"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<PropertyDiffDataFragment, unknown>;
export const ArtifactPropsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ArtifactProps"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "properties"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "key"}},
                                {kind: "Field", name: {kind: "Name", value: "value"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "update"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PropertyDiffData"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "isDeleted"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "oldId"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...PropertyDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ArtifactPropsFragment, unknown>;
export const SearchOneArtifactInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SearchOneArtifactInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "durationInSeconds"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "isMacro"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ArtifactProps"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "data"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...ArtifactPropsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SearchOneArtifactInfoFragment, unknown>;
export const SearchParticipantInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SearchParticipantInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "dataWithNullableEmail"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "avatar"}},
                                {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                                {kind: "Field", name: {kind: "Name", value: "token"}},
                                {kind: "Field", name: {kind: "Name", value: "userId"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "conferenceStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchParticipantInfoFragment, unknown>;
export const SearchOneSessionInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SearchOneSessionInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionOrder"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionLifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "macroArtifacts"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SearchOneArtifactInfo"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SearchParticipantInfo"}}],
                        },
                    },
                ],
            },
        },
        ...SessionOrderFragmentDoc.definitions,
        ...OneSessionLifecycleFragmentDoc.definitions,
        ...SearchOneArtifactInfoFragmentDoc.definitions,
        ...SearchParticipantInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SearchOneSessionInfoFragment, unknown>;
export const SessionSettingsFragmentFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionSettingsFragment"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionSettings"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionSource"}},
                    {kind: "Field", name: {kind: "Name", value: "requestPermissionToJoin"}},
                    {kind: "Field", name: {kind: "Name", value: "requireConsentToRecord"}},
                    {kind: "Field", name: {kind: "Name", value: "enableReactionsType"}},
                    {kind: "Field", name: {kind: "Name", value: "askForGuestEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "allowCameraType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowMicrophoneType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowScreenShareType"}},
                    {kind: "Field", name: {kind: "Name", value: "hideNonStreamingParticipants"}},
                    {kind: "Field", name: {kind: "Name", value: "autoRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "memoryAccessType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowAgendaType"}},
                    {kind: "Field", name: {kind: "Name", value: "reminders"}},
                    {kind: "Field", name: {kind: "Name", value: "messageReminders"}},
                    {kind: "Field", name: {kind: "Name", value: "restrictedWidgets"}},
                    {kind: "Field", name: {kind: "Name", value: "autoTranscribing"}},
                    {kind: "Field", name: {kind: "Name", value: "disabledNotifications"}},
                    {kind: "Field", name: {kind: "Name", value: "autoStartSession"}},
                    {kind: "Field", name: {kind: "Name", value: "sendEndSessionEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "fullSizeVideosInRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingConferenceView"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingPresentationView"}},
                    {kind: "Field", name: {kind: "Name", value: "passcodeToJoin"}},
                    {kind: "Field", name: {kind: "Name", value: "provideSessionPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "bypassSessionPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "groupChatAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "privateChatAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "participantsMacroAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "exitPageOfferId"}},
                    {kind: "Field", name: {kind: "Name", value: "collectFeedback"}},
                    {kind: "Field", name: {kind: "Name", value: "showExitPageCta"}},
                    {kind: "Field", name: {kind: "Name", value: "hideIcsGuestList"}},
                    {kind: "Field", name: {kind: "Name", value: "livestreamOutputUrls"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingType"}},
                    {kind: "Field", name: {kind: "Name", value: "feedbackFormId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionSettingsFragmentFragment, unknown>;
export const MemoryListSessionParticipantFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "MemoryListSessionParticipant"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "lastSeenTimestamp"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<MemoryListSessionParticipantFragment, unknown>;
export const MemorySessionRecordingFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "MemorySessionRecording"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionRecording"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "livestreamOutputUrls"}},
                    {kind: "Field", name: {kind: "Name", value: "permission"}},
                    {kind: "Field", name: {kind: "Name", value: "version"}},
                    {kind: "Field", name: {kind: "Name", value: "processingStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "startedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "downloadUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<MemorySessionRecordingFragment, unknown>;
export const MemoryListSessionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "MemoryListSession"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "isBooking"}},
                    {kind: "Field", name: {kind: "Name", value: "quickSession"}},
                    {kind: "Field", name: {kind: "Name", value: "memoryAccessType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowAgendaType"}},
                    {kind: "Field", name: {kind: "Name", value: "hideNonStreamingParticipants"}},
                    {kind: "Field", name: {kind: "Name", value: "autoRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingType"}},
                    {kind: "Field", name: {kind: "Name", value: "restrictedWidgets"}},
                    {kind: "Field", name: {kind: "Name", value: "collectFeedback"}},
                    {kind: "Field", name: {kind: "Name", value: "showExitPageCta"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionTags"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "currentParticipant"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", alias: {kind: "Name", value: "pid"}, name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "lastSeenTimestamp"}},
                                {kind: "Field", name: {kind: "Name", value: "hasAttendedSession"}},
                                {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                {kind: "Field", name: {kind: "Name", value: "rbac"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemoryListSessionParticipant"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "recordings"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionRecording"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "event"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "room"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "slug"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspace"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "recurrenceData"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
        ...MemoryListSessionParticipantFragmentDoc.definitions,
        ...MemorySessionRecordingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MemoryListSessionFragment, unknown>;
export const ParticipantStatusFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ParticipantStatus"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "status"}}]},
        },
    ],
} as unknown as DocumentNode<ParticipantStatusFragment, unknown>;
export const OneParticipantConferenceStatusFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneParticipantConferenceStatus"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "conferenceStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "changedBy"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "statusChangedBy"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "unableToBecomeSpeaker"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "muted"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneParticipantConferenceStatusFragment, unknown>;
export const ParticipantRbacFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ParticipantRbac"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "rbac"}}]},
        },
    ],
} as unknown as DocumentNode<ParticipantRbacFragment, unknown>;
export const SpeakerDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SpeakerDetails"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionSpeaker"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "bio"}},
                    {kind: "Field", name: {kind: "Name", value: "company"}},
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "photoUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "linkedInUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "jobTitle"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SpeakerDetailsFragment, unknown>;
export const ParticipantSpeakerDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ParticipantSpeakerDetails"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "speakerDetails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SpeakerDetails"}}],
                        },
                    },
                ],
            },
        },
        ...SpeakerDetailsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ParticipantSpeakerDetailsFragment, unknown>;
export const ParticipantPaymentTransactionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ParticipantPaymentTransaction"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaymentTransaction"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "checkoutSessionStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "paymentIntendId"}},
                    {kind: "Field", name: {kind: "Name", value: "paymentIntendStatus"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paymentProvider"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "accountName"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ParticipantPaymentTransactionFragment, unknown>;
export const SessionParticipantFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionParticipant"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "dataWithNullableEmail"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "avatar"}},
                                {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                                {kind: "Field", name: {kind: "Name", value: "token"}},
                                {kind: "Field", name: {kind: "Name", value: "userId"}},
                            ],
                        },
                    },
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ParticipantStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "hasAttendedSession"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneParticipantConferenceStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "mockedParticipant"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ParticipantRbac"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "isApproved"}},
                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "invitedByTheOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "source"}},
                    {kind: "Field", name: {kind: "Name", value: "isRecorder"}},
                    {kind: "Field", name: {kind: "Name", value: "lastSeenTimestamp"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "askToUnmute"}},
                    {kind: "Field", name: {kind: "Name", value: "askToStartCamera"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ParticipantSpeakerDetails"}},
                    {kind: "Field", name: {kind: "Name", value: "controlStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "submittedPasscode"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paymentTransaction"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ParticipantPaymentTransaction"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "isMainParticipant"}},
                ],
            },
        },
        ...ParticipantStatusFragmentDoc.definitions,
        ...OneParticipantConferenceStatusFragmentDoc.definitions,
        ...ParticipantRbacFragmentDoc.definitions,
        ...ParticipantSpeakerDetailsFragmentDoc.definitions,
        ...ParticipantPaymentTransactionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionParticipantFragment, unknown>;
export const MemorySessionParticipantFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "MemorySessionParticipant"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}},
                    {kind: "Field", name: {kind: "Name", value: "presenceHistory"}},
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MemorySessionParticipantFragment, unknown>;
export const SessionPlannedStartFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionPlannedStart"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionPlannedStartFragment, unknown>;
export const SessionPlannedEndFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionPlannedEnd"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionPlannedEndFragment, unknown>;
export const RoomSummaryFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RoomSummary"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "source"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionPlannedStart"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionPlannedEnd"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionLifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "timeDependency"}},
                    {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "endByWorker"}},
                    {kind: "Field", name: {kind: "Name", value: "createdFromTemplateId"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "generatedName"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "byTimezoneStartAt"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "byTimezonePlannedEnd"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...SessionPlannedStartFragmentDoc.definitions,
        ...SessionPlannedEndFragmentDoc.definitions,
        ...OneSessionLifecycleFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RoomSummaryFragment, unknown>;
export const RoomUserFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RoomUser"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "User"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoomUserFragment, unknown>;
export const RoomDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RoomData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Room"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "active"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "activeSessionInstance"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                                {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionTemplate"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomSummary"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "user"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomUser"}}],
                        },
                    },
                ],
            },
        },
        ...RoomSummaryFragmentDoc.definitions,
        ...RoomUserFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RoomDataFragment, unknown>;
export const RoomSessionInstanceFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RoomSessionInstance"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoomSessionInstanceFragment, unknown>;
export const RoomWithSessionInstancesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RoomWithSessionInstances"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Room"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "active"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionInstances"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomSessionInstance"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionTemplate"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "description"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "autoRecording"}},
                                {kind: "Field", name: {kind: "Name", value: "autoTranscribing"}},
                                {kind: "Field", name: {kind: "Name", value: "requestPermissionToJoin"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "user"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomUser"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sharedWithWorkspaces"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sharedWithUsers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomUser"}}],
                        },
                    },
                ],
            },
        },
        ...RoomSessionInstanceFragmentDoc.definitions,
        ...RoomUserFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RoomWithSessionInstancesFragment, unknown>;
export const AgendaTemplateBasicInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaTemplateBasicInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaTemplate"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AgendaTemplateBasicInfoFragment, unknown>;
export const AgendaTemplateOwnerFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaTemplateOwner"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "User"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AgendaTemplateOwnerFragment, unknown>;
export const AgendaTemplateFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaTemplate"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaTemplate"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "duration"}},
                    {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                    {kind: "Field", name: {kind: "Name", value: "hasEditorAccess"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "user"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaTemplateOwner"}}],
                        },
                    },
                ],
            },
        },
        ...AgendaTemplateOwnerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AgendaTemplateFragment, unknown>;
export const AgendaTemplatePermissionsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaTemplatePermissions"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaTemplate"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isPublic"},
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "client"},
                                arguments: [
                                    {kind: "Argument", name: {kind: "Name", value: "always"}, value: {kind: "BooleanValue", value: true}},
                                ],
                            },
                        ],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isReadOnly"},
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "client"},
                                arguments: [
                                    {kind: "Argument", name: {kind: "Name", value: "always"}, value: {kind: "BooleanValue", value: true}},
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AgendaTemplatePermissionsFragment, unknown>;
export const ResourceResultsNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ResourceResultsNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ResourceResult"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResourceResultsNoDiffFragment, unknown>;
export const ResourceResultDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ResourceResultDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ResourceResultDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "content"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "resourceContentSnapshot"}},
                    {kind: "Field", name: {kind: "Name", value: "resourceId"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResourceResultDiffDataFragment, unknown>;
export const ArtifactResourceResultFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ArtifactResourceResult"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ResourceResult"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ResourceResultsNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceResultDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...ResourceResultsNoDiffFragmentDoc.definitions,
        ...ResourceResultDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ArtifactResourceResultFragment, unknown>;
export const OneArtifactNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneArtifactNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "durationInSeconds"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "isMacro"}},
                    {kind: "Field", name: {kind: "Name", value: "isConfigured"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ArtifactProps"}},
                    {kind: "Field", name: {kind: "Name", value: "userConfiguredDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "isSystemArtifact"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resourceResults"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ArtifactResourceResult"}}],
                        },
                    },
                ],
            },
        },
        ...ArtifactPropsFragmentDoc.definitions,
        ...ArtifactResourceResultFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneArtifactNoDiffFragment, unknown>;
export const ArtifactDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ArtifactDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ArtifactDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "deniedSpeakerUserIds"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "durationInSeconds"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "isConfigured"}},
                    {kind: "Field", name: {kind: "Name", value: "isMacro"}},
                    {kind: "Field", name: {kind: "Name", value: "isSystemArtifact"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "userConfiguredDuration"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<ArtifactDiffDataFragment, unknown>;
export const OneArtifactFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneArtifact"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneArtifactNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "data"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ArtifactDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...OneArtifactNoDiffFragmentDoc.definitions,
        ...ArtifactDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneArtifactFragment, unknown>;
export const AgendaItemSpeakerNoDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaItemSpeakerNoDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaItemSpeaker"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "agendaItemId"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerId"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AgendaItemSpeakerNoDiffDataFragment, unknown>;
export const AgendaItemSpeakerDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaItemSpeakerDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaItemSpeakerDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "agendaItemId"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerId"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AgendaItemSpeakerDiffDataFragment, unknown>;
export const AgendaItemSpeakerFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaItemSpeaker"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaItemSpeaker"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "AgendaItemSpeakerNoDiffData"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaItemSpeakerDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...AgendaItemSpeakerNoDiffDataFragmentDoc.definitions,
        ...AgendaItemSpeakerDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AgendaItemSpeakerFragment, unknown>;
export const OneAgendaItemNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneAgendaItemNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaItem"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "userConfiguredDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "descriptionJson"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "artifact"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneArtifact"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "duration"}},
                    {kind: "Field", name: {kind: "Name", value: "timeSpentInSeconds"}},
                    {kind: "Field", name: {kind: "Name", value: "processing"}},
                    {kind: "Field", name: {kind: "Name", value: "locked"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItemSpeakers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaItemSpeaker"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "speakerNotes"}},
                    {kind: "Field", name: {kind: "Name", value: "isPaused"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerNotesJson"}},
                ],
            },
        },
        ...OneArtifactFragmentDoc.definitions,
        ...AgendaItemSpeakerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneAgendaItemNoDiffFragment, unknown>;
export const AgendaItemDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AgendaItemDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaItemDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "descriptionJson"}},
                    {kind: "Field", name: {kind: "Name", value: "duration"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "isPaused"}},
                    {kind: "Field", name: {kind: "Name", value: "locked"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {kind: "Field", name: {kind: "Name", value: "processing"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerNotes"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerNotesJson"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "timeSpentInSeconds"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "userConfiguredDuration"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AgendaItemDiffDataFragment, unknown>;
export const FullAgendaItemFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "FullAgendaItem"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaItem"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneAgendaItemNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaItemDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...OneAgendaItemNoDiffFragmentDoc.definitions,
        ...AgendaItemDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<FullAgendaItemFragment, unknown>;
export const FullAgendaTemplateFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "FullAgendaTemplate"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AgendaTemplate"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "AgendaTemplate"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "AgendaTemplatePermissions"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "hasEditorAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "accessList"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
        ...AgendaTemplateFragmentDoc.definitions,
        ...AgendaTemplatePermissionsFragmentDoc.definitions,
        ...FullAgendaItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<FullAgendaTemplateFragment, unknown>;
export const AppleSyncedEmailFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AppleSyncedEmail"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "AppleSyncedEmailData"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "calendarProviderId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendars"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "isPrimary"}},
                                {kind: "Field", name: {kind: "Name", value: "pushEvents"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AppleSyncedEmailFragment, unknown>;
export const GoogleSyncedEmailFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "GoogleSyncedEmail"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "GoogleSyncedEmailData"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "permissions"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "permission"}},
                                {kind: "Field", name: {kind: "Name", value: "granted"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "calendarProviderId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendars"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "isPrimary"}},
                                {kind: "Field", name: {kind: "Name", value: "pushEvents"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GoogleSyncedEmailFragment, unknown>;
export const NotionParentPageFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "NotionParentPage"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "NotionParentPage"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "default"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<NotionParentPageFragment, unknown>;
export const MicrosoftSyncedEmailFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "MicrosoftSyncedEmail"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "MicrosoftSyncedEmailData"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "permissions"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "permission"}},
                                {kind: "Field", name: {kind: "Name", value: "granted"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "calendarProviderId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendars"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "isPrimary"}},
                                {kind: "Field", name: {kind: "Name", value: "pushEvents"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MicrosoftSyncedEmailFragment, unknown>;
export const StripeSyncedAccountFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "StripeSyncedAccount"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "StripeSyncedAccount"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "providerId"}},
                    {kind: "Field", name: {kind: "Name", value: "accountId"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<StripeSyncedAccountFragment, unknown>;
export const PayPalSyncedAccountFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PayPalSyncedAccount"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PayPalSyncedAccount"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "providerId"}},
                    {kind: "Field", name: {kind: "Name", value: "merchantId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<PayPalSyncedAccountFragment, unknown>;
export const ProductToolFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ProductTool"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "MyProductTool"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "iconUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "url"}},
                    {kind: "Field", name: {kind: "Name", value: "enabled"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "consentOwnershipOfIcon"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "ownerTimezone"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionIdForOwnerTimezone"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProductToolFragment, unknown>;
export const WorkspaceRoleInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "WorkspaceRoleInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "WorkspaceRole"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "permissions"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "clientOnly"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<WorkspaceRoleInfoFragment, unknown>;
export const MemberFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "Member"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "User"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                    {kind: "Field", name: {kind: "Name", value: "country"}},
                    {kind: "Field", name: {kind: "Name", value: "department"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "lastLoginDate"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "organizationPermissions"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                                {kind: "Field", name: {kind: "Name", value: "suspended"}},
                                {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                {kind: "Field", name: {kind: "Name", value: "organizationId"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspacePermissions"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "role"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspaceRoleInfo"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "workspace"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...WorkspaceRoleInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MemberFragment, unknown>;
export const WebhookFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "Webhook"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Webhook"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "trigger"}},
                    {kind: "Field", name: {kind: "Name", value: "url"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                    {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                    {kind: "Field", name: {kind: "Name", value: "integration"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "headers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "key"}},
                                {kind: "Field", name: {kind: "Name", value: "value"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<WebhookFragment, unknown>;
export const PublicApiKeyInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "PublicApiKeyInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PublicApiKey"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "role"}},
                    {kind: "Field", name: {kind: "Name", value: "tokenPreview"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<PublicApiKeyInfoFragment, unknown>;
export const OrganizationOnUserFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OrganizationOnUser"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Organization"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "companyName"}},
                    {kind: "Field", name: {kind: "Name", value: "plan"}},
                    {kind: "Field", name: {kind: "Name", value: "companyWebsite"}},
                    {kind: "Field", name: {kind: "Name", value: "appSumoInvoiceItemId"}},
                    {kind: "Field", name: {kind: "Name", value: "isActivated"}},
                    {kind: "Field", name: {kind: "Name", value: "size"}},
                    {kind: "Field", name: {kind: "Name", value: "onboarding"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "onboardingCompleted"}},
                    {kind: "Field", name: {kind: "Name", value: "lastOrderId"}},
                    {kind: "Field", name: {kind: "Name", value: "plan"}},
                    {kind: "Field", name: {kind: "Name", value: "subscriptionTrialUsed"}},
                    {kind: "Field", name: {kind: "Name", value: "subscriptionId"}},
                    {kind: "Field", name: {kind: "Name", value: "billingPeriod"}},
                    {kind: "Field", name: {kind: "Name", value: "planNextPaymentDate"}},
                    {kind: "Field", name: {kind: "Name", value: "lastOrderId"}},
                    {kind: "Field", name: {kind: "Name", value: "planEffectiveCancellationDate"}},
                    {kind: "Field", name: {kind: "Name", value: "reverseTrialCancellationDate"}},
                    {kind: "Field", name: {kind: "Name", value: "reverseTrialExpired"}},
                    {kind: "Field", name: {kind: "Name", value: "celloUcc"}},
                    {kind: "Field", name: {kind: "Name", value: "membersCount"}},
                    {kind: "Field", name: {kind: "Name", value: "processingSubscription"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingActivity"}},
                    {kind: "Field", name: {kind: "Name", value: "livestreamActivity"}},
                    {kind: "Field", name: {kind: "Name", value: "discountCode"}},
                    {kind: "Field", name: {kind: "Name", value: "discountCodeLabel"}},
                    {kind: "Field", name: {kind: "Name", value: "referralDiscountCode"}},
                    {kind: "Field", name: {kind: "Name", value: "eventActivity"}},
                    {kind: "Field", name: {kind: "Name", value: "paddleSubscriptionState"}},
                    {kind: "Field", name: {kind: "Name", value: "permanentPlan"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "subscriptionModifiers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "quantity"}},
                                {kind: "Field", name: {kind: "Name", value: "type"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OrganizationOnUserFragment, unknown>;
export const BrandingInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BrandingInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Branding"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "palette"}},
                    {kind: "Field", name: {kind: "Name", value: "sourcePalette"}},
                    {kind: "Field", name: {kind: "Name", value: "logo"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionBackground"}},
                    {kind: "Field", name: {kind: "Name", value: "styleTag"}},
                    {kind: "Field", name: {kind: "Name", value: "active"}},
                    {kind: "Field", name: {kind: "Name", value: "logomark"}},
                    {kind: "Field", name: {kind: "Name", value: "navbarTheme"}},
                    {kind: "Field", name: {kind: "Name", value: "videoTileColor"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<BrandingInfoFragment, unknown>;
export const WorkspacePermissionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "WorkspacePermission"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "UserToWorkspacePermission"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "activeWorkspace"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "role"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspaceRoleInfo"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspace"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "slug"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "branding"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "membersCount"}},
                                {kind: "Field", name: {kind: "Name", value: "organizationId"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...WorkspaceRoleInfoFragmentDoc.definitions,
        ...BrandingInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WorkspacePermissionFragment, unknown>;
export const SeenSectionsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SeenSections"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "User"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "seen"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SeenSectionsFragment, unknown>;
export const RemoteUserFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RemoteUser"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "User"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "email"}},
                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                    {kind: "Field", name: {kind: "Name", value: "company"}},
                    {kind: "Field", name: {kind: "Name", value: "industry"}},
                    {kind: "Field", name: {kind: "Name", value: "jobRole"}},
                    {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                    {kind: "Field", name: {kind: "Name", value: "language"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "organizationPermissions"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                                {kind: "Field", name: {kind: "Name", value: "activeOrganization"}},
                                {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                {kind: "Field", name: {kind: "Name", value: "suspended"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "organization"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OrganizationOnUser"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspacePermissions"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspacePermission"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "emailNotificationsEnabled"}},
                    {kind: "Field", name: {kind: "Name", value: "emailNotificationsMinutes"}},
                    {kind: "Field", name: {kind: "Name", value: "pushNotificationsEnabled"}},
                    {kind: "Field", name: {kind: "Name", value: "pushNotificationsMinutes"}},
                    {kind: "Field", name: {kind: "Name", value: "timezone"}},
                    {kind: "Field", name: {kind: "Name", value: "halfDayClock"}},
                    {kind: "Field", name: {kind: "Name", value: "seenTour"}},
                    {kind: "Field", name: {kind: "Name", value: "seenHomeIntercom"}},
                    {kind: "Field", name: {kind: "Name", value: "seenWelcomeSession"}},
                    {kind: "Field", name: {kind: "Name", value: "lastVersionSeen"}},
                    {kind: "Field", name: {kind: "Name", value: "firstLogin"}},
                    {kind: "Field", name: {kind: "Name", value: "lastLoginDate"}},
                    {kind: "Field", name: {kind: "Name", value: "forms"}},
                    {kind: "Field", name: {kind: "Name", value: "externalReferral"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "customSubDomainConfig"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "fqdn"}},
                                {kind: "Field", name: {kind: "Name", value: "domain"}},
                                {kind: "Field", name: {kind: "Name", value: "root"}},
                            ],
                        },
                    },
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SeenSections"}},
                    {kind: "Field", name: {kind: "Name", value: "onboarding"}},
                    {kind: "Field", name: {kind: "Name", value: "timezone"}},
                    {kind: "Field", name: {kind: "Name", value: "flags"}},
                    {kind: "Field", name: {kind: "Name", value: "notesFlags"}},
                    {kind: "Field", name: {kind: "Name", value: "department"}},
                    {kind: "Field", name: {kind: "Name", value: "country"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "customBackgrounds"},
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "skip"},
                                arguments: [
                                    {kind: "Argument", name: {kind: "Name", value: "if"}, value: {kind: "BooleanValue", value: true}},
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "url"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "meetingRegion"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "defaultLobbyPicture"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "croppedPictureUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "originalPictureUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "aspect"}},
                                {kind: "Field", name: {kind: "Name", value: "height"}},
                                {kind: "Field", name: {kind: "Name", value: "width"}},
                                {kind: "Field", name: {kind: "Name", value: "x"}},
                                {kind: "Field", name: {kind: "Name", value: "y"}},
                                {kind: "Field", name: {kind: "Name", value: "unit"}},
                                {kind: "Field", name: {kind: "Name", value: "pictureWasUploaded"}},
                                {kind: "Field", name: {kind: "Name", value: "canBeRepositioned"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "externalCalendarsSettings"}},
                    {kind: "Field", name: {kind: "Name", value: "firstDayOfWeek"}},
                    {kind: "Field", name: {kind: "Name", value: "defaultBookingCalendars"}},
                    {kind: "Field", name: {kind: "Name", value: "marketingEmailsEnabled"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "tags"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...OrganizationOnUserFragmentDoc.definitions,
        ...WorkspacePermissionFragmentDoc.definitions,
        ...SeenSectionsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RemoteUserFragment, unknown>;
export const AllRemoteApplicationStateFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "AllRemoteApplicationState"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ApplicationState"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<AllRemoteApplicationStateFragment, unknown>;
export const SpeakerInfoFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SpeakerInfo"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "dataWithNullableEmail"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "avatar"}},
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "speakerDetails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SpeakerDetails"}}],
                        },
                    },
                ],
            },
        },
        ...SpeakerDetailsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SpeakerInfoFragment, unknown>;
export const OneEventListItemFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneEventListItem"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "backstage"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {kind: "Argument", name: {kind: "Name", value: "onlyOwner"}, value: {kind: "BooleanValue", value: true}},
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "dataWithNullableEmail"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                            {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                        ],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "userId"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "event"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "state"}},
                                {kind: "Field", name: {kind: "Name", value: "bannerUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                                {kind: "Field", name: {kind: "Name", value: "slug"}},
                                {kind: "Field", name: {kind: "Name", value: "maxSeats"}},
                                {kind: "Field", name: {kind: "Name", value: "seatsTaken"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "payments"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...PaymentConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneEventListItemFragment, unknown>;
export const SessionUpdatedAtFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionUpdatedAt"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "updatedAt"}}]},
        },
    ],
} as unknown as DocumentNode<SessionUpdatedAtFragment, unknown>;
export const OneSessionParticipantsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionParticipants"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneSessionParticipantsFragment, unknown>;
export const LobbyPictureImageDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "LobbyPictureImageDetails"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "lobbyPicture"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "originalPictureUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "pictureWasUploaded"}},
                                {kind: "Field", name: {kind: "Name", value: "y"}},
                                {kind: "Field", name: {kind: "Name", value: "canBeRepositioned"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LobbyPictureImageDetailsFragment, unknown>;
export const OneQuestionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneQuestion"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Question"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "text"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "markedAsAnswered"}},
                    {kind: "Field", name: {kind: "Name", value: "upVotes"}},
                    {kind: "Field", name: {kind: "Name", value: "upVotedByUser"}},
                    {kind: "Field", name: {kind: "Name", value: "isAnonymous"}},
                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneQuestionFragment, unknown>;
export const OneSessionFlagsNoDiffNoIdFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionFlagsNoDiffNoId"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "lobbyAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "showAgendaInLobby"}},
                    {kind: "Field", name: {kind: "Name", value: "allowCollaborationInLobby"}},
                    {kind: "Field", name: {kind: "Name", value: "requestPermissionToJoin"}},
                    {kind: "Field", name: {kind: "Name", value: "requireConsentToRecord"}},
                    {kind: "Field", name: {kind: "Name", value: "enableReactionsType"}},
                    {kind: "Field", name: {kind: "Name", value: "askForGuestEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "allowCameraType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowMicrophoneType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowScreenShareType"}},
                    {kind: "Field", name: {kind: "Name", value: "hideNonStreamingParticipants"}},
                    {kind: "Field", name: {kind: "Name", value: "reminderInMinutes"}},
                    {kind: "Field", name: {kind: "Name", value: "autoRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "memoryAccessType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowAgendaType"}},
                    {kind: "Field", name: {kind: "Name", value: "reminders"}},
                    {kind: "Field", name: {kind: "Name", value: "messageReminders"}},
                    {kind: "Field", name: {kind: "Name", value: "restrictedWidgets"}},
                    {kind: "Field", name: {kind: "Name", value: "autoTranscribing"}},
                    {kind: "Field", name: {kind: "Name", value: "disabledNotifications"}},
                    {kind: "Field", name: {kind: "Name", value: "autoStartSession"}},
                    {kind: "Field", name: {kind: "Name", value: "sendEndSessionEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "fullSizeVideosInRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingConferenceView"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingPresentationView"}},
                    {kind: "Field", name: {kind: "Name", value: "passcodeToJoin"}},
                    {kind: "Field", name: {kind: "Name", value: "provideSessionPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "bypassSessionPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "groupChatAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "privateChatAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "participantsMacroAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "collectFeedback"}},
                    {kind: "Field", name: {kind: "Name", value: "showExitPageCta"}},
                    {kind: "Field", name: {kind: "Name", value: "hideIcsGuestList"}},
                    {kind: "Field", name: {kind: "Name", value: "livestreamOutputUrls"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingType"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneSessionFlagsNoDiffNoIdFragment, unknown>;
export const OneSessionFlagsNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionFlagsNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionFlagsNoDiffNoId"}},
                ],
            },
        },
        ...OneSessionFlagsNoDiffNoIdFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneSessionFlagsNoDiffFragment, unknown>;
export const RecurrenceDataNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RecurrenceDataNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "RecurrenceData"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "dtStart"}},
                    {kind: "Field", name: {kind: "Name", value: "frequency"}},
                    {kind: "Field", name: {kind: "Name", value: "until"}},
                    {kind: "Field", name: {kind: "Name", value: "count"}},
                    {kind: "Field", name: {kind: "Name", value: "interval"}},
                    {kind: "Field", name: {kind: "Name", value: "byDay"}},
                    {kind: "Field", name: {kind: "Name", value: "byMonth"}},
                    {kind: "Field", name: {kind: "Name", value: "byMonthDay"}},
                    {kind: "Field", name: {kind: "Name", value: "bySetPos"}},
                    {kind: "Field", name: {kind: "Name", value: "exclude"}},
                    {kind: "Field", name: {kind: "Name", value: "wkst"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "firstInstance"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<RecurrenceDataNoDiffFragment, unknown>;
export const RecurrenceDataDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RecurrenceDataDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "RecurrenceDataDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "byDay"}},
                    {kind: "Field", name: {kind: "Name", value: "byMonth"}},
                    {kind: "Field", name: {kind: "Name", value: "byMonthDay"}},
                    {kind: "Field", name: {kind: "Name", value: "bySetPos"}},
                    {kind: "Field", name: {kind: "Name", value: "count"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "dtStart"}},
                    {kind: "Field", name: {kind: "Name", value: "exclude"}},
                    {kind: "Field", name: {kind: "Name", value: "frequency"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "instancesOccurrenceIds"}},
                    {kind: "Field", name: {kind: "Name", value: "interval"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "until"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<RecurrenceDataDiffDataFragment, unknown>;
export const DesignerSessionRecurrenceDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "DesignerSessionRecurrenceData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "RecurrenceData"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "RecurrenceDataNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RecurrenceDataDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...RecurrenceDataNoDiffFragmentDoc.definitions,
        ...RecurrenceDataDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<DesignerSessionRecurrenceDataFragment, unknown>;
export const OnePollOptionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OnePollOption"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PollOption"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                    {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                    {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnePollOptionFragment, unknown>;
export const OnePollTextAnswerFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OnePollTextAnswer"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PollTextAnswer"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "text"}},
                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnePollTextAnswerFragment, unknown>;
export const OnePollFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OnePoll"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Poll"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "questionBody"}},
                    {kind: "Field", name: {kind: "Name", value: "active"}},
                    {kind: "Field", name: {kind: "Name", value: "completed"}},
                    {kind: "Field", name: {kind: "Name", value: "pollType"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "options"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OnePollOption"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "textAnswers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OnePollTextAnswer"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "makeResultsAnonymous"}},
                    {kind: "Field", name: {kind: "Name", value: "showResultsToParticipants"}},
                    {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                ],
            },
        },
        ...OnePollOptionFragmentDoc.definitions,
        ...OnePollTextAnswerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OnePollFragment, unknown>;
export const SessionPollsForQueryFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionPollsForQuery"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "polls"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "resultsSubmitted"}},
                                {kind: "FragmentSpread", name: {kind: "Name", value: "OnePoll"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...OnePollFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionPollsForQueryFragment, unknown>;
export const EventOfferFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "EventOffer"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Offer"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "ctaText"}},
                    {kind: "Field", name: {kind: "Name", value: "ctaUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<EventOfferFragment, unknown>;
export const SessionEventNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionEventNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                    {kind: "Field", name: {kind: "Name", value: "bannerUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "photoUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "colorPalette"}},
                    {kind: "Field", name: {kind: "Name", value: "socialLinks"}},
                    {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "registrationForm"}},
                    {kind: "Field", name: {kind: "Name", value: "utm"}},
                    {kind: "Field", name: {kind: "Name", value: "hasRegistration"}},
                    {kind: "Field", name: {kind: "Name", value: "requireApproval"}},
                    {kind: "Field", name: {kind: "Name", value: "registrationAnswers"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerOrderJson"}},
                    {kind: "Field", name: {kind: "Name", value: "embedUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationRedirectUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationOfferId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "afterRegistrationOffer"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "EventOffer"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "maxSeats"}},
                    {kind: "Field", name: {kind: "Name", value: "seatsTaken"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "payments"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "language"}},
                ],
            },
        },
        ...EventOfferFragmentDoc.definitions,
        ...PaymentConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionEventNoDiffFragment, unknown>;
export const SessionEventDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionEventDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionEventDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationOfferId"}},
                    {kind: "Field", name: {kind: "Name", value: "afterRegistrationRedirectUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "bannerUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "colorPalette"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "embedUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "hasRegistration"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                    {kind: "Field", name: {kind: "Name", value: "language"}},
                    {kind: "Field", name: {kind: "Name", value: "maxSeats"}},
                    {kind: "Field", name: {kind: "Name", value: "photoUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "registrationAnswers"}},
                    {kind: "Field", name: {kind: "Name", value: "registrationForm"}},
                    {kind: "Field", name: {kind: "Name", value: "requireApproval"}},
                    {kind: "Field", name: {kind: "Name", value: "seatsTaken"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "socialLinks"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerOrderJson"}},
                    {kind: "Field", name: {kind: "Name", value: "state"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "utm"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionEventDiffDataFragment, unknown>;
export const SessionEventFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionEvent"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionEvent"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionEventNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionEventDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...SessionEventNoDiffFragmentDoc.definitions,
        ...SessionEventDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionEventFragment, unknown>;
export const SessionRoomNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionRoomNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Room"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "active"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionRoomNoDiffFragment, unknown>;
export const RoomDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "RoomDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "RoomDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "active"}},
                    {kind: "Field", name: {kind: "Name", value: "activeSessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionTemplateId"}},
                    {kind: "Field", name: {kind: "Name", value: "slug"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoomDiffDataFragment, unknown>;
export const SessionRoomFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionRoom"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Room"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionRoomNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...SessionRoomNoDiffFragmentDoc.definitions,
        ...RoomDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionRoomFragment, unknown>;
export const OfferDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OfferDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "OfferDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "ctaText"}},
                    {kind: "Field", name: {kind: "Name", value: "ctaUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                    {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OfferDiffDataFragment, unknown>;
export const SessionOfferFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionOffer"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Offer"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OfferDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
        ...OfferDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionOfferFragment, unknown>;
export const FeedbackFormFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "FeedbackForm"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "FeedbackForm"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "title"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "questions"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<FeedbackFormFragment, unknown>;
export const OneSessionNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionOrder"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionPlannedStart"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionPlannedEnd"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionLifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "timeDependency"}},
                    {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
                    {kind: "Field", name: {kind: "Name", value: "syncToAgenda"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "backstage"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "LobbyPictureImageDetails"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "questionsContainer"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "currentlyAnsweringQuestion"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "shareScreenPid"}},
                    {kind: "Field", name: {kind: "Name", value: "quickSession"}},
                    {kind: "Field", name: {kind: "Name", value: "endByWorker"}},
                    {kind: "Field", name: {kind: "Name", value: "sendEmailsAutomatically"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionFlagsNoDiff"}},
                    {kind: "Field", name: {kind: "Name", value: "occurrenceId"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "recurrenceData"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "DesignerSessionRecurrenceData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "instanceOfRecurrence"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "session"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {kind: "FragmentSpread", name: {kind: "Name", value: "SessionPollsForQuery"}},
                    {kind: "Field", name: {kind: "Name", value: "createdFromTemplateId"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "source"}},
                    {kind: "Field", name: {kind: "Name", value: "transcriptionActive"}},
                    {kind: "Field", name: {kind: "Name", value: "isBooking"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "generatedName"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "byTimezoneStartAt"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "byTimezonePlannedEnd"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "currentParticipant"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "Field", alias: {kind: "Name", value: "pid"}, name: {kind: "Name", value: "id"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "view"}},
                    {kind: "Field", name: {kind: "Name", value: "onboardingType"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "event"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionEvent"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "room"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionRoom"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "exitPageOffer"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionOffer"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "exitPageOfferId"}},
                    {kind: "Field", name: {kind: "Name", value: "organizationPlan"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerHalfDayClock"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerLanguage"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                    {kind: "Field", name: {kind: "Name", value: "timerStartAt"}},
                    {kind: "Field", name: {kind: "Name", value: "timerCurrentDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "timerInitialDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "timerState"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionTags"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "feedbackForm"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "feedbackFormId"}},
                    {kind: "Field", name: {kind: "Name", value: "feedbackFormAnswers"}},
                    {kind: "Field", name: {kind: "Name", value: "isTask"}},
                    {kind: "Field", name: {kind: "Name", value: "restrictedWidgets"}},
                ],
            },
        },
        ...SessionOrderFragmentDoc.definitions,
        ...SessionPlannedStartFragmentDoc.definitions,
        ...SessionPlannedEndFragmentDoc.definitions,
        ...OneSessionLifecycleFragmentDoc.definitions,
        ...LobbyPictureImageDetailsFragmentDoc.definitions,
        ...OneQuestionFragmentDoc.definitions,
        ...OneSessionFlagsNoDiffFragmentDoc.definitions,
        ...DesignerSessionRecurrenceDataFragmentDoc.definitions,
        ...SessionPollsForQueryFragmentDoc.definitions,
        ...SessionEventFragmentDoc.definitions,
        ...SessionRoomFragmentDoc.definitions,
        ...SessionOfferFragmentDoc.definitions,
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneSessionNoDiffFragment, unknown>;
export const SessionDiffDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionDiffData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionDiff"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                    {kind: "Field", name: {kind: "Name", value: "allowAgendaType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowCameraType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowCollaborationInLobby"}},
                    {kind: "Field", name: {kind: "Name", value: "allowMicrophoneType"}},
                    {kind: "Field", name: {kind: "Name", value: "allowScreenShareType"}},
                    {kind: "Field", name: {kind: "Name", value: "askForGuestEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "autoRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "autoStartSession"}},
                    {kind: "Field", name: {kind: "Name", value: "autoTranscribing"}},
                    {kind: "Field", name: {kind: "Name", value: "backstage"}},
                    {kind: "Field", name: {kind: "Name", value: "bypassSessionPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "collaborativeSession"}},
                    {kind: "Field", name: {kind: "Name", value: "collectFeedback"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "createdFromTemplateId"}},
                    {kind: "Field", name: {kind: "Name", value: "createdFromYourFirstSessionTemplate"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "disabledNotifications"}},
                    {kind: "Field", name: {kind: "Name", value: "enableReactionsType"}},
                    {kind: "Field", name: {kind: "Name", value: "endByWorker"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "exitPageOfferId"}},
                    {kind: "Field", name: {kind: "Name", value: "feedbackFormAnswers"}},
                    {kind: "Field", name: {kind: "Name", value: "feedbackFormId"}},
                    {kind: "Field", name: {kind: "Name", value: "fullSizeVideosInRecording"}},
                    {kind: "Field", name: {kind: "Name", value: "groupChatAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "hideIcsGuestList"}},
                    {kind: "Field", name: {kind: "Name", value: "hideNonStreamingParticipants"}},
                    {kind: "Field", name: {kind: "Name", value: "newId"}},
                    {kind: "Field", name: {kind: "Name", value: "isBooking"}},
                    {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
                    {kind: "Field", name: {kind: "Name", value: "isTask"}},
                    {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                    {kind: "Field", name: {kind: "Name", value: "livestreamOutputUrls"}},
                    {kind: "Field", name: {kind: "Name", value: "lobbyAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "memoryAccessType"}},
                    {kind: "Field", name: {kind: "Name", value: "messageReminders"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "occurrenceId"}},
                    {kind: "Field", name: {kind: "Name", value: "onboardingType"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerHalfDayClock"}},
                    {kind: "Field", name: {kind: "Name", value: "ownerLanguage"}},
                    {kind: "Field", name: {kind: "Name", value: "participantsMacroAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "passcodeToJoin"}},
                    {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                    {kind: "Field", name: {kind: "Name", value: "privateChatAccess"}},
                    {kind: "Field", name: {kind: "Name", value: "provideSessionPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "quickSession"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingConferenceView"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingPresentationView"}},
                    {kind: "Field", name: {kind: "Name", value: "recordingType"}},
                    {kind: "Field", name: {kind: "Name", value: "recurrenceDataId"}},
                    {kind: "Field", name: {kind: "Name", value: "reminderInMinutes"}},
                    {kind: "Field", name: {kind: "Name", value: "reminders"}},
                    {kind: "Field", name: {kind: "Name", value: "requestPermissionToJoin"}},
                    {kind: "Field", name: {kind: "Name", value: "requireConsentToRecord"}},
                    {kind: "Field", name: {kind: "Name", value: "restrictedWidgets"}},
                    {kind: "Field", name: {kind: "Name", value: "roomId"}},
                    {kind: "Field", name: {kind: "Name", value: "sendEmailsAutomatically"}},
                    {kind: "Field", name: {kind: "Name", value: "sendEndSessionEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "shareScreenPid"}},
                    {kind: "Field", name: {kind: "Name", value: "showAgendaInLobby"}},
                    {kind: "Field", name: {kind: "Name", value: "showExitPageCta"}},
                    {kind: "Field", name: {kind: "Name", value: "source"}},
                    {kind: "Field", name: {kind: "Name", value: "speakerUserIds"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "syncToAgenda"}},
                    {kind: "Field", name: {kind: "Name", value: "timeDependency"}},
                    {kind: "Field", name: {kind: "Name", value: "timeZone"}},
                    {kind: "Field", name: {kind: "Name", value: "timerCurrentDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "timerInitialDuration"}},
                    {kind: "Field", name: {kind: "Name", value: "timerStartAt"}},
                    {kind: "Field", name: {kind: "Name", value: "timerState"}},
                    {kind: "Field", name: {kind: "Name", value: "transcriptionActive"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "view"}},
                    {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionDiffDataFragment, unknown>;
export const OneSessionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSession"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...OneSessionNoDiffFragmentDoc.definitions,
        ...SessionDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneSessionFragment, unknown>;
export const ArtifactPropsMacroFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ArtifactPropsMacro"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "properties"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "key"}},
                                {kind: "Field", name: {kind: "Name", value: "value"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "update"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PropertyDiffData"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "isDeleted"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "oldId"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...PropertyDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ArtifactPropsMacroFragment, unknown>;
export const OneMacroArtifactNoDiffFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneMacroArtifactNoDiff"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                    {kind: "Field", name: {kind: "Name", value: "startAt"}},
                    {kind: "Field", name: {kind: "Name", value: "endedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "durationInSeconds"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "isMacro"}},
                    {kind: "Field", name: {kind: "Name", value: "isConfigured"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "description"}},
                    {kind: "Field", name: {kind: "Name", value: "order"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "ArtifactPropsMacro"}},
                ],
            },
        },
        ...ArtifactPropsMacroFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneMacroArtifactNoDiffFragment, unknown>;
export const OneMacroArtifactFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneMacroArtifact"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Artifact"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifactNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "data"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "update"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ArtifactDiffData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isDeleted"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "oldId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
        ...OneMacroArtifactNoDiffFragmentDoc.definitions,
        ...ArtifactDiffDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneMacroArtifactFragment, unknown>;
export const OneSessionMacroArtifactsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionMacroArtifacts"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "macroArtifacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "order"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                        },
                    },
                ],
            },
        },
        ...OneMacroArtifactFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneSessionMacroArtifactsFragment, unknown>;
export const OneSessionAgendaItemsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneSessionAgendaItems"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OneSessionAgendaItemsFragment, unknown>;
export const CurrentParticipantRbacFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CurrentParticipantRbac"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "rbac"}}]},
        },
    ],
} as unknown as DocumentNode<CurrentParticipantRbacFragment, unknown>;
export const CurrentParticipantDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CurrentParticipantData"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Participant"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                    {kind: "Field", name: {kind: "Name", value: "isApproved"}},
                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                    {kind: "Field", name: {kind: "Name", value: "conferenceStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "isRecorder"}},
                    {kind: "Field", name: {kind: "Name", value: "contextualNoteJson"}},
                    {kind: "FragmentSpread", name: {kind: "Name", value: "CurrentParticipantRbac"}},
                    {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    {kind: "Field", name: {kind: "Name", value: "lastSeenTimestamp"}},
                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                    {kind: "Field", name: {kind: "Name", value: "hasAttendedSession"}},
                    {kind: "Field", name: {kind: "Name", value: "submittedPasscode"}},
                    {kind: "Field", name: {kind: "Name", value: "consentedToRecord"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "speakerDetails"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
        ...CurrentParticipantRbacFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CurrentParticipantDataFragment, unknown>;
export const BreakoutRoomsConfigFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "BreakoutRoomsConfig"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "BreakoutRoomsConfiguration"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "allowRoomNavigation"}},
                    {kind: "Field", name: {kind: "Name", value: "artifactIdOfBreakoutRooms"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "parentOfBreakoutRooms"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", alias: {kind: "Name", value: "sessionId"}, name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "questionsContainer"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "questions"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "markedAsAnswered"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participantsMap"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "desiredRoomId"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "participantInParent"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                            {kind: "Field", name: {kind: "Name", value: "status"}},
                                            {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                            {kind: "Field", name: {kind: "Name", value: "rbac"}},
                                            {kind: "Field", name: {kind: "Name", value: "conferenceStatus"}},
                                            {kind: "Field", name: {kind: "Name", value: "userId"}},
                                            {kind: "Field", name: {kind: "Name", value: "guestId"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "participantInBreakoutRoom"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                            {kind: "Field", name: {kind: "Name", value: "status"}},
                                            {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                            {kind: "Field", name: {kind: "Name", value: "rbac"}},
                                            {kind: "Field", name: {kind: "Name", value: "conferenceStatus"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "breakoutRooms"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "description"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "questionsContainer"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "questions"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "markedAsAnswered"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BreakoutRoomsConfigFragment, unknown>;
export const SessionNoCacheFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "SessionNoCache"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Session"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionNoDiff"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "macroArtifacts"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "currentParticipant"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CurrentParticipantData"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "childOfBreakoutRooms"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                        },
                    },
                ],
            },
        },
        ...OneSessionNoDiffFragmentDoc.definitions,
        ...OneMacroArtifactFragmentDoc.definitions,
        ...FullAgendaItemFragmentDoc.definitions,
        ...SessionParticipantFragmentDoc.definitions,
        ...CurrentParticipantDataFragmentDoc.definitions,
        ...BreakoutRoomsConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionNoCacheFragment, unknown>;
export const OneChatFileFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "OneChatFile"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "MessageFile"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "type"}},
                    {kind: "Field", name: {kind: "Name", value: "size"}},
                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                    {kind: "Field", name: {kind: "Name", value: "downloadLink"}},
                    {kind: "Field", name: {kind: "Name", value: "chatmessageId"}},
                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<OneChatFileFragment, unknown>;
export const ChatUpdateFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ChatUpdate"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "ChatUpdateWatchdogResult"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "newChatMessage"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatMessage"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "newChatFile"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatFile"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "newChatGroup"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatGroupWithoutMessages"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteChatMessage"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "chatGroupId"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteChatFile"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "chatGroupId"}},
                                {kind: "Field", name: {kind: "Name", value: "chatmessageId"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...OneChatMessageFragmentDoc.definitions,
        ...OneChatFileFragmentDoc.definitions,
        ...OneChatGroupWithoutMessagesFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ChatUpdateFragment, unknown>;
export const ParticipantUpdateFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "ParticipantUpdate"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "SessionParticipantUpdatesResult"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "action"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participant"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changedBy"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "unableToBecomeSpeaker"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ParticipantUpdateFragment, unknown>;
export const CustomSmtpServerFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CustomSMTPServer"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CustomSmtpServer"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "host"}},
                    {kind: "Field", name: {kind: "Name", value: "port"}},
                    {kind: "Field", name: {kind: "Name", value: "username"}},
                    {kind: "Field", name: {kind: "Name", value: "password"}},
                    {kind: "Field", name: {kind: "Name", value: "senderEmail"}},
                    {kind: "Field", name: {kind: "Name", value: "senderName"}},
                    {kind: "Field", name: {kind: "Name", value: "senderDomain"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "assignedToWorkspaces"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                    {kind: "Field", name: {kind: "Name", value: "verified"}},
                    {kind: "Field", name: {kind: "Name", value: "enabled"}},
                    {kind: "Field", name: {kind: "Name", value: "configType"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<CustomSmtpServerFragment, unknown>;
export const CnameFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "Cname"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "Cname"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "domain"}},
                    {kind: "Field", name: {kind: "Name", value: "status"}},
                    {kind: "Field", name: {kind: "Name", value: "target"}},
                    {kind: "Field", name: {kind: "Name", value: "favicon"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "cnameForWorkspaces"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CnameFragment, unknown>;
export const MessageExternalProviderFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "MessageExternalProvider"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "MessageExternalProvider"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "providerType"}},
                    {kind: "Field", name: {kind: "Name", value: "messageType"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "senderNumber"}},
                    {kind: "Field", name: {kind: "Name", value: "options"}},
                    {kind: "Field", name: {kind: "Name", value: "verified"}},
                    {kind: "Field", name: {kind: "Name", value: "enabled"}},
                    {kind: "Field", name: {kind: "Name", value: "organizationId"}},
                    {kind: "Field", name: {kind: "Name", value: "clientId"}},
                    {kind: "Field", name: {kind: "Name", value: "authToken"}},
                    {kind: "Field", name: {kind: "Name", value: "apiKey"}},
                    {kind: "Field", name: {kind: "Name", value: "apiSecret"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "assignedToWorkspaces"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MessageExternalProviderFragment, unknown>;
export const CustomSmsMessageTemplateFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: {kind: "Name", value: "CustomSMSMessageTemplate"},
            typeCondition: {kind: "NamedType", name: {kind: "Name", value: "CustomMessageTemplate"}},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "id"}},
                    {kind: "Field", name: {kind: "Name", value: "templateId"}},
                    {kind: "Field", name: {kind: "Name", value: "disableSending"}},
                    {kind: "Field", name: {kind: "Name", value: "name"}},
                    {kind: "Field", name: {kind: "Name", value: "body"}},
                    {kind: "Field", name: {kind: "Name", value: "config"}},
                ],
            },
        },
    ],
} as unknown as DocumentNode<CustomSmsMessageTemplateFragment, unknown>;
export const GetBreakoutConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBreakoutConfig"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "artifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "breakoutRoomsConfig"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...BreakoutRoomsConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBreakoutConfigQuery, GetBreakoutConfigQueryVariables>;
export const GetRoomInfoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetRoomInfo"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "questionsContainer"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "questions"},
                                                arguments: [
                                                    {
                                                        kind: "Argument",
                                                        name: {kind: "Name", value: "where"},
                                                        value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                                {
                                                                    kind: "ObjectField",
                                                                    name: {kind: "Name", value: "markedAsAnswered"},
                                                                    value: {
                                                                        kind: "ObjectValue",
                                                                        fields: [
                                                                            {
                                                                                kind: "ObjectField",
                                                                                name: {kind: "Name", value: "equals"},
                                                                                value: {kind: "BooleanValue", value: false},
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "markedAsAnswered"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "participants"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "status"}},
                                            {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                            {kind: "Field", name: {kind: "Name", value: "rbac"}},
                                            {kind: "Field", name: {kind: "Name", value: "conferenceStatus"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetRoomInfoQuery, GetRoomInfoQueryVariables>;
export const UpdateBreakoutConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateBreakoutConfig"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "deleteBreakoutRoomIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "upsertBreakoutRooms"}},
                    type: {
                        kind: "ListType",
                        type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpsertBreakoutRoomInput"}}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mainBreakoutRoom"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UpdateMainBreakoutRoom"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "allowRoomNavigation"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateBreakoutRoomsConfig"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "deleteBreakoutRoomIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "deleteBreakoutRoomIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "upsertBreakoutRooms"},
                                value: {kind: "Variable", name: {kind: "Name", value: "upsertBreakoutRooms"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "mainBreakoutRoom"},
                                value: {kind: "Variable", name: {kind: "Name", value: "mainBreakoutRoom"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "allowRoomNavigation"},
                                value: {kind: "Variable", name: {kind: "Name", value: "allowRoomNavigation"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                        },
                    },
                ],
            },
        },
        ...BreakoutRoomsConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateBreakoutConfigMutation, UpdateBreakoutConfigMutationVariables>;
export const GetChatMessagesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetChatMessages"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "take"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "before"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "skipFiles"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "chatGroupId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "chatMessages"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "equals"},
                                                        value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "chatGroupId"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "equals"},
                                                        value: {kind: "Variable", name: {kind: "Name", value: "chatGroupId"}},
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "last"},
                                value: {kind: "Variable", name: {kind: "Name", value: "take"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "before"},
                                value: {kind: "Variable", name: {kind: "Name", value: "before"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "createdAt"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatMessage"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "messageFiles"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "chatMessage"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "sessionId"},
                                                        value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                                {
                                                                    kind: "ObjectField",
                                                                    name: {kind: "Name", value: "equals"},
                                                                    value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                                                },
                                                            ],
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "chatGroupId"},
                                                        value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                                {
                                                                    kind: "ObjectField",
                                                                    name: {kind: "Name", value: "equals"},
                                                                    value: {kind: "Variable", name: {kind: "Name", value: "chatGroupId"}},
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "createdAt"},
                                            value: {kind: "EnumValue", value: "desc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "skip"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "skipFiles"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatFile"}}],
                        },
                    },
                ],
            },
        },
        ...OneChatMessageFragmentDoc.definitions,
        ...OneChatFileFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetChatMessagesQuery, GetChatMessagesQueryVariables>;
export const GetChatGroupsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetChatGroups"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "chatGroups"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "createdAt"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatGroup"}}],
                        },
                    },
                ],
            },
        },
        ...OneChatGroupFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetChatGroupsQuery, GetChatGroupsQueryVariables>;
export const GetUnreadSessionChatCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUnreadSessionChatCount"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "unreadSessionChatCount"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUnreadSessionChatCountQuery, GetUnreadSessionChatCountQueryVariables>;
export const GiphySearchDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GiphySearch"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "limit"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "GiphyMediaType"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "query"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "giphySearch"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offset"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "limit"},
                                value: {kind: "Variable", name: {kind: "Name", value: "limit"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "type"},
                                value: {kind: "Variable", name: {kind: "Name", value: "type"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "query"},
                                value: {kind: "Variable", name: {kind: "Name", value: "query"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GiphySearchQuery, GiphySearchQueryVariables>;
export const SendChatMessageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendChatMessage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "chatGroupId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "body"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "replyToMessageId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendToAllBreakoutRooms"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendChatMessage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "chatGroupId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "chatGroupId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "body"},
                                value: {kind: "Variable", name: {kind: "Name", value: "body"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "replyToMessageId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "replyToMessageId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendToAllBreakoutRooms"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendToAllBreakoutRooms"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantIds"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendChatMessageMutation, SendChatMessageMutationVariables>;
export const UploadFileForChatMessageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UploadFileForChatMessage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadFileForChatMessage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "uuid"}},
                                {kind: "Field", name: {kind: "Name", value: "uploadUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadFileForChatMessageMutation, UploadFileForChatMessageMutationVariables>;
export const ConfirmUploadFileForChatMessageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ConfirmUploadFileForChatMessage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "uuid"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "size"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "confirmUploadFileForChatMessage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "uuid"},
                                value: {kind: "Variable", name: {kind: "Name", value: "uuid"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "type"},
                                value: {kind: "Variable", name: {kind: "Name", value: "type"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "size"},
                                value: {kind: "Variable", name: {kind: "Name", value: "size"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneChatFile"}}],
                        },
                    },
                ],
            },
        },
        ...OneChatFileFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ConfirmUploadFileForChatMessageMutation, ConfirmUploadFileForChatMessageMutationVariables>;
export const DeleteOneMessageFileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOneMessageFile"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneMessageFile"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneMessageFileMutation, DeleteOneMessageFileMutationVariables>;
export const DeleteOneChatMessageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOneChatMessage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneChatMessage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneChatMessageMutation, DeleteOneChatMessageMutationVariables>;
export const SetParticipantConferenceStatusDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetParticipantConferenceStatus"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "newStatus"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ConferenceParticipantStatus"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateParticipantConferenceStatus"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "newStatus"},
                                value: {kind: "Variable", name: {kind: "Name", value: "newStatus"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetParticipantConferenceStatusMutation, SetParticipantConferenceStatusMutationVariables>;
export const KickParticipantFromSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "KickParticipantFromSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "ban"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "session"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "kickFromSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "kickedParticipantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "ban"},
                                value: {kind: "Variable", name: {kind: "Name", value: "ban"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {kind: "Variable", name: {kind: "Name", value: "session"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<KickParticipantFromSessionMutation, KickParticipantFromSessionMutationVariables>;
export const AskParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AskParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "askToUnmute"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "askToStartCamera"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "askParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "askToUnmute"},
                                value: {kind: "Variable", name: {kind: "Name", value: "askToUnmute"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "askToStartCamera"},
                                value: {kind: "Variable", name: {kind: "Name", value: "askToStartCamera"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AskParticipantMutation, AskParticipantMutationVariables>;
export const AnswerParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AnswerParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "askToUnmute"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "askToStartCamera"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "answerParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "askToUnmute"},
                                value: {kind: "Variable", name: {kind: "Name", value: "askToUnmute"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "askToStartCamera"},
                                value: {kind: "Variable", name: {kind: "Name", value: "askToStartCamera"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AnswerParticipantMutation, AnswerParticipantMutationVariables>;
export const SaveContextualNoteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveContextualNote"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "session"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "noteJson"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveContextualNote"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {kind: "Variable", name: {kind: "Name", value: "session"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "noteJson"},
                                value: {kind: "Variable", name: {kind: "Name", value: "noteJson"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "contextualNoteJson"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveContextualNoteMutation, SaveContextualNoteMutationVariables>;
export const SendContextualNoteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendContextualNote"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendEmail"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendToEvernote"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendToNotion"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendContextualNote"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendEmail"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendToEvernote"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendToEvernote"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendToNotion"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendToNotion"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendContextualNoteMutation, SendContextualNoteMutationVariables>;
export const UpdateOneGuestDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOneGuest"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "GuestUpdateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneGuest"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOneGuestMutation, UpdateOneGuestMutationVariables>;
export const GetNotesSyncInfoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetNotesSyncInfo"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {kind: "Field", name: {kind: "Name", value: "isNotionSynced"}},
                    {kind: "Field", name: {kind: "Name", value: "isEvernoteSynced"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getNotionParentPages"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "NotionParentPage"}}],
                        },
                    },
                ],
            },
        },
        ...NotionParentPageFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetNotesSyncInfoQuery, GetNotesSyncInfoQueryVariables>;
export const HaveIAnsweredFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "HaveIAnsweredForm"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "resourceResultId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "hasParticipantAnsweredForm"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactWhere"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "resourceResultWhere"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "resourceResultId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HaveIAnsweredFormQuery, HaveIAnsweredFormQueryVariables>;
export const FormParticipantsCanSeeResultsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "FormParticipantsCanSeeResults"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "StringValue", value: "participantsCanViewAnswers", block: false},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FormParticipantsCanSeeResultsMutation, FormParticipantsCanSeeResultsMutationVariables>;
export const FormOwnerCanAnswerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "FormOwnerCanAnswer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "StringValue", value: "ownerCanAnswer", block: false},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FormOwnerCanAnswerMutation, FormOwnerCanAnswerMutationVariables>;
export const FormToggleAnonymityDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "FormToggleAnonymity"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "StringValue", value: "anonymousMode", block: false},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FormToggleAnonymityMutation, FormToggleAnonymityMutationVariables>;
export const GetGoogleAuthUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetGoogleAuthUrl"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getGoogleAuthUrl"}}]},
        },
    ],
} as unknown as DocumentNode<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>;
export const GetGoogleFilesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetGoogleFiles"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileName"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileType"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "GoogleFileType"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "nextPageToken"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleFiles"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileType"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "nextPageToken"},
                                value: {kind: "Variable", name: {kind: "Name", value: "nextPageToken"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGoogleFilesQuery, GetGoogleFilesQueryVariables>;
export const GetGoogleFileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetGoogleFile"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleFile"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGoogleFileQuery, GetGoogleFileQueryVariables>;
export const ChangeGoogleFilePermissionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ChangeGoogleFilePermission"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "role"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changeGoogleFilePermission"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "role"},
                                value: {kind: "Variable", name: {kind: "Name", value: "role"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChangeGoogleFilePermissionMutation, ChangeGoogleFilePermissionMutationVariables>;
export const ChangeGoogleFilesPermissionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ChangeGoogleFilesPermission"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "role"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changeGoogleFilesPermission"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "role"},
                                value: {kind: "Variable", name: {kind: "Name", value: "role"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChangeGoogleFilesPermissionMutation, ChangeGoogleFilesPermissionMutationVariables>;
export const CreateGoogleFileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateGoogleFile"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createGoogleFile"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "type"},
                                value: {kind: "Variable", name: {kind: "Name", value: "type"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateGoogleFileMutation, CreateGoogleFileMutationVariables>;
export const DeleteGoogleFilesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteGoogleFiles"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteGoogleFiles"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileIds"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteGoogleFilesMutation, DeleteGoogleFilesMutationVariables>;
export const RenameGoogleFileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RenameGoogleFile"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "renameGoogleFile"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RenameGoogleFileMutation, RenameGoogleFileMutationVariables>;
export const CheckGoogleFileInUseDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CheckGoogleFileInUse"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkGoogleFileInUse"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "fileId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "fileId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "startAt"}},
                                {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                                {kind: "Field", name: {kind: "Name", value: "occurrenceId"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recurrenceData"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "FragmentSpread", name: {kind: "Name", value: "DesignerSessionRecurrenceData"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...DesignerSessionRecurrenceDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CheckGoogleFileInUseMutation, CheckGoogleFileInUseMutationVariables>;
export const CheckUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "CheckURL"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "iframeCheckUrl"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "isFrameable"}},
                                {kind: "Field", name: {kind: "Name", value: "reason"}},
                                {kind: "Field", name: {kind: "Name", value: "contentType"}},
                                {kind: "Field", name: {kind: "Name", value: "fileName"}},
                                {kind: "Field", name: {kind: "Name", value: "fileSize"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CheckUrlQuery, CheckUrlQueryVariables>;
export const GetWebsiteTitleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetWebsiteTitle"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getWebsiteTitle"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetWebsiteTitleQuery, GetWebsiteTitleQueryVariables>;
export const GetWebsiteDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetWebsiteDetails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "icon"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "idealIconSize"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "titleFromOG"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getWebsiteDetails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "icon"},
                                value: {kind: "Variable", name: {kind: "Name", value: "icon"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "idealIconSize"},
                                value: {kind: "Variable", name: {kind: "Name", value: "idealIconSize"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "titleFromOG"},
                                value: {kind: "Variable", name: {kind: "Name", value: "titleFromOG"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "title"}},
                                {kind: "Field", name: {kind: "Name", value: "description"}},
                                {kind: "Field", name: {kind: "Name", value: "icon"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetWebsiteDetailsQuery, GetWebsiteDetailsQueryVariables>;
export const GetPspdfkitTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetPspdfkitToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "documentId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "layer"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getPspdfkitToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "documentId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "documentId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "layer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "layer"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPspdfkitTokenQuery, GetPspdfkitTokenQueryVariables>;
export const CreatePspdfkitDocumentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreatePspdfkitDocument"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "documentUrl"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createPspdfkitDocument"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "documentUrl"},
                                value: {kind: "Variable", name: {kind: "Name", value: "documentUrl"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "documentId"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreatePspdfkitDocumentMutation, CreatePspdfkitDocumentMutationVariables>;
export const CreateOnePollDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateOnePoll"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "PollCreateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOnePoll"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateOnePollMutation, CreateOnePollMutationVariables>;
export const DeleteOnePollDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOnePoll"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOnePoll"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOnePollMutation, DeleteOnePollMutationVariables>;
export const UpdateOnePollDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOnePoll"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "PollUpdateInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOnePoll"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOnePollMutation, UpdateOnePollMutationVariables>;
export const SubmitPollAnswersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SubmitPollAnswers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "pollId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "chosenOptionIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "textAnswer"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "submitPollAnswers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "pollId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "pollId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "chosenOptionIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "chosenOptionIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "textAnswer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "textAnswer"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubmitPollAnswersMutation, SubmitPollAnswersMutationVariables>;
export const PollUpdatesForOwnerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "PollUpdatesForOwner"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "pollUpdatesForOwners"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "allPollsOnSession"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "polls"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "questionBody"}},
                                                        {kind: "Field", name: {kind: "Name", value: "active"}},
                                                        {kind: "Field", name: {kind: "Name", value: "completed"}},
                                                        {kind: "Field", name: {kind: "Name", value: "pollType"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "options"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "id"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "textAnswers"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "id"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "text"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                                                ],
                                                            },
                                                        },
                                                        {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                                        {kind: "Field", name: {kind: "Name", value: "makeResultsAnonymous"}},
                                                        {kind: "Field", name: {kind: "Name", value: "showResultsToParticipants"}},
                                                        {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "individualPolls"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "questionBody"}},
                                            {kind: "Field", name: {kind: "Name", value: "active"}},
                                            {kind: "Field", name: {kind: "Name", value: "completed"}},
                                            {kind: "Field", name: {kind: "Name", value: "pollType"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "options"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                                                        {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                                                        {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                                        {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                                                        {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "textAnswers"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "text"}},
                                                        {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                                    ],
                                                },
                                            },
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                            {kind: "Field", name: {kind: "Name", value: "makeResultsAnonymous"}},
                                            {kind: "Field", name: {kind: "Name", value: "showResultsToParticipants"}},
                                            {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "individualPollOptions"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                                            {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                            {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                                            {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "individualPollTextAnswers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "textAnswers"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "text"}},
                                                        {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pollUniqueVotes"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PollUpdatesForOwnerSubscription, PollUpdatesForOwnerSubscriptionVariables>;
export const PollUpdatesForParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "PollUpdatesForParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "pollUpdatesForParticipants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "allPollsOnSession"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "polls"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "questionBody"}},
                                                        {kind: "Field", name: {kind: "Name", value: "active"}},
                                                        {kind: "Field", name: {kind: "Name", value: "completed"}},
                                                        {kind: "Field", name: {kind: "Name", value: "pollType"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "options"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "id"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "textAnswers"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "id"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "text"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                                                ],
                                                            },
                                                        },
                                                        {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                                        {kind: "Field", name: {kind: "Name", value: "makeResultsAnonymous"}},
                                                        {kind: "Field", name: {kind: "Name", value: "showResultsToParticipants"}},
                                                        {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "individualPolls"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "questionBody"}},
                                            {kind: "Field", name: {kind: "Name", value: "active"}},
                                            {kind: "Field", name: {kind: "Name", value: "completed"}},
                                            {kind: "Field", name: {kind: "Name", value: "pollType"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "options"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                                                        {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                                                        {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                                        {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                                                        {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "textAnswers"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "text"}},
                                                        {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                                    ],
                                                },
                                            },
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                            {kind: "Field", name: {kind: "Name", value: "makeResultsAnonymous"}},
                                            {kind: "Field", name: {kind: "Name", value: "showResultsToParticipants"}},
                                            {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "individualPollOptions"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "optionBody"}},
                                            {kind: "Field", name: {kind: "Name", value: "submittedByOwner"}},
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                            {kind: "Field", name: {kind: "Name", value: "participantIds"}},
                                            {kind: "Field", name: {kind: "Name", value: "numberOfVotes"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "individualPollTextAnswers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "textAnswers"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "text"}},
                                                        {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pollUniqueVotes"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "numberOfUniqueVotes"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PollUpdatesForParticipantSubscription, PollUpdatesForParticipantSubscriptionVariables>;
export const GetSessionQuestionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionQuestions"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "questionsContainer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "questions"},
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {kind: "Name", value: "orderBy"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "createdAt"},
                                                        value: {kind: "EnumValue", value: "asc"},
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionQuestionsQuery, GetSessionQuestionsQueryVariables>;
export const GetSessionQuestionsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionQuestionsCount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSessionQuestionsCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSessionQuestionsCountQuery, GetSessionQuestionsCountQueryVariables>;
export const GetNewQuestionsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetNewQuestionsCount"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "newQuestionsCount"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "count"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetNewQuestionsCountQuery, GetNewQuestionsCountQueryVariables>;
export const GetQuestionsFromSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetQuestionsFromSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "session"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getQuestionsFromSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {kind: "Variable", name: {kind: "Name", value: "session"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetQuestionsFromSessionQuery, GetQuestionsFromSessionQueryVariables>;
export const CreateQuestionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateQuestion"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneQuestion"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "text"},
                                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionContainerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const ToggleQuestionAsAnsweredDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ToggleQuestionAsAnswered"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "question"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "value"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "toggleQuestionAsAnswered"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "question"},
                                value: {kind: "Variable", name: {kind: "Name", value: "question"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "value"},
                                value: {kind: "Variable", name: {kind: "Name", value: "value"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ToggleQuestionAsAnsweredMutation, ToggleQuestionAsAnsweredMutationVariables>;
export const MarkQuestionAsAnsweringDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "MarkQuestionAsAnswering"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "questionBeingAnsweredId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "questionsContainerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "markQuestionAsAnswering"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionBeingAnsweredId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "questionBeingAnsweredId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionsContainerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "questionsContainerId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MarkQuestionAsAnsweringMutation, MarkQuestionAsAnsweringMutationVariables>;
export const StopAnsweringQuestionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "StopAnsweringQuestions"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "questionsContainerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "stopAnsweringQuestions"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionsContainerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "questionsContainerId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StopAnsweringQuestionsMutation, StopAnsweringQuestionsMutationVariables>;
export const DeleteOneQuestionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOneQuestion"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneQuestion"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneQuestionMutation, DeleteOneQuestionMutationVariables>;
export const QaTogglePublicPrivateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "QATogglePublicPrivate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "properties"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {kind: "NamedType", name: {kind: "Name", value: "PropertyCreateCustomInput"}},
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {kind: "Variable", name: {kind: "Name", value: "properties"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneArtifact"}}],
                        },
                    },
                ],
            },
        },
        ...OneArtifactFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<QaTogglePublicPrivateMutation, QaTogglePublicPrivateMutationVariables>;
export const QaToggleUpVoteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "QAToggleUpVote"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "question"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "toggleQuestionUpVote"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "question"},
                                value: {kind: "Variable", name: {kind: "Name", value: "question"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<QaToggleUpVoteMutation, QaToggleUpVoteMutationVariables>;
export const QaUpdateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "QAUpdate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "questionsContainerUpdate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionsContainerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "question"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<QaUpdateSubscription, QaUpdateSubscriptionVariables>;
export const QuestionsBeingAnsweredDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "QuestionsBeingAnswered"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "questionsBeingAnswered"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionsContainerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<QuestionsBeingAnsweredSubscription, QuestionsBeingAnsweredSubscriptionVariables>;
export const QuestionAnsweredForParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "QuestionAnsweredForParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "questionAnsweredForParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionsContainerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "containerId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneQuestion"}}],
                        },
                    },
                ],
            },
        },
        ...OneQuestionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<QuestionAnsweredForParticipantSubscription, QuestionAnsweredForParticipantSubscriptionVariables>;
export const GetSlidoUrlDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSlidoUrlDetails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSlidoUrlDetails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "title"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSlidoUrlDetailsQuery, GetSlidoUrlDetailsQueryVariables>;
export const GetEditorTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetEditorToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "readOnly"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getEditorToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "readOnly"},
                                value: {kind: "Variable", name: {kind: "Name", value: "readOnly"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEditorTokenQuery, GetEditorTokenQueryVariables>;
export const GetTranscriptionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetTranscriptions"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "take"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "before"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "preferredLanguage"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "skipPinned"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "transcripts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "take"},
                                value: {kind: "Variable", name: {kind: "Name", value: "take"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "before"},
                                value: {kind: "Variable", name: {kind: "Name", value: "before"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneTranscriptMessage"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "skip"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "skipPinned"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pinnedTranscriptions"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneTranscriptMessage"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OneTranscriptMessageFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetTranscriptionsQuery, GetTranscriptionsQueryVariables>;
export const GetTranslatedTranscriptionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetTranslatedTranscription"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "transcriptId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "transcriptContentTranslation"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "transcriptId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "transcriptId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "text"}},
                                {kind: "Field", name: {kind: "Name", value: "language"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTranslatedTranscriptionQuery, GetTranslatedTranscriptionQueryVariables>;
export const GetSummaryTranslationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSummaryTranslation"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "summary"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "summaryTranslation"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "summary"},
                                value: {kind: "Variable", name: {kind: "Name", value: "summary"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSummaryTranslationQuery, GetSummaryTranslationQueryVariables>;
export const SearchSessionTranscriptsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SearchSessionTranscripts"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "query"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "searchSessionTranscripts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "query"},
                                value: {kind: "Variable", name: {kind: "Name", value: "query"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "text"}},
                                {kind: "Field", name: {kind: "Name", value: "language"}},
                                {kind: "Field", name: {kind: "Name", value: "isOriginal"}},
                                {kind: "Field", name: {kind: "Name", value: "transcriptionId"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchSessionTranscriptsQuery, SearchSessionTranscriptsQueryVariables>;
export const GetTranscriptFileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetTranscriptFile"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "format"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timezoneOffset"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "preferredLanguage"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "transcriptFile"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "format"},
                                value: {kind: "Variable", name: {kind: "Name", value: "format"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timezoneOffset"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timezoneOffset"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "preferredLanguage"},
                                value: {kind: "Variable", name: {kind: "Name", value: "preferredLanguage"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "path"}},
                                {kind: "Field", name: {kind: "Name", value: "url"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTranscriptFileQuery, GetTranscriptFileQueryVariables>;
export const TogglePinTranscriptionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "TogglePinTranscription"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "toggleTranscriptionPin"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TogglePinTranscriptionMutation, TogglePinTranscriptionMutationVariables>;
export const GetTwitchUrlDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetTwitchUrlDetails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getTwitchUrlDetails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "type"}},
                                {kind: "Field", name: {kind: "Name", value: "title"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTwitchUrlDetailsQuery, GetTwitchUrlDetailsQueryVariables>;
export const AccessListItemsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "AccessListItems"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "AccessListItemWhereInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "accessListItems"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AccessListItemInfo"}}],
                        },
                    },
                ],
            },
        },
        ...AccessListItemInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AccessListItemsQuery, AccessListItemsQueryVariables>;
export const CreateOneAccessListItemDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateOneAccessListItem"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AccessListItemCreateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneAccessListItem"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AccessListItemInfo"}}],
                        },
                    },
                ],
            },
        },
        ...AccessListItemInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateOneAccessListItemMutation, CreateOneAccessListItemMutationVariables>;
export const DeleteOneAccessListItemDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteOneAccessListItem"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneAccessListItem"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneAccessListItemMutation, DeleteOneAccessListItemMutationVariables>;
export const WorkspaceBookingEventsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "WorkspaceBookingEvents"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspaceBookingEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "description"}},
                                {kind: "Field", name: {kind: "Name", value: "slug"}},
                                {kind: "Field", name: {kind: "Name", value: "duration"}},
                                {kind: "Field", name: {kind: "Name", value: "additionalDuration"}},
                                {kind: "Field", name: {kind: "Name", value: "ownerId"}},
                                {kind: "Field", name: {kind: "Name", value: "ownerFirstName"}},
                                {kind: "Field", name: {kind: "Name", value: "ownerLastName"}},
                                {kind: "Field", name: {kind: "Name", value: "ownerProfilePicture"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "payments"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "publicWorkspaceInfo"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "branding"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicBookingEventBranding"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...PaymentConfigFragmentDoc.definitions,
        ...PublicBookingEventBrandingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WorkspaceBookingEventsQuery, WorkspaceBookingEventsQueryVariables>;
export const GetBookingEventListDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingEventList"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "name"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingEventLitItem"}}],
                        },
                    },
                ],
            },
        },
        ...BookingEventLitItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBookingEventListQuery, GetBookingEventListQueryVariables>;
export const GetBookingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingEvent"}}],
                        },
                    },
                ],
            },
        },
        ...BookingEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBookingEventQuery, GetBookingEventQueryVariables>;
export const GetBookingContactsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingContacts"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeSelf"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingEventContacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeSelf"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeSelf"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingEventContact"}}],
                        },
                    },
                ],
            },
        },
        ...BookingEventContactFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBookingContactsQuery, GetBookingContactsQueryVariables>;
export const GetBookingSchedulesListDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingSchedulesList"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingSchedules"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "name"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingSchedule"}}],
                        },
                    },
                ],
            },
        },
        ...BookingScheduleFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBookingSchedulesListQuery, GetBookingSchedulesListQueryVariables>;
export const GetPublicBookingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetPublicBookingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timezone"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "publicBookingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "slug"},
                                value: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timezone"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timezone"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicBookingEvent"}}],
                        },
                    },
                ],
            },
        },
        ...PublicBookingEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetPublicBookingEventQuery, GetPublicBookingEventQueryVariables>;
export const GetPublicBookingEventAvailableSlotsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetPublicBookingEventAvailableSlots"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timezone"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "duration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "from"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "to"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "troubleshoot"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "publicBookingEventAvailableSlots"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timezone"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timezone"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "duration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "duration"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "to"},
                                value: {kind: "Variable", name: {kind: "Name", value: "to"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "troubleshoot"},
                                value: {kind: "Variable", name: {kind: "Name", value: "troubleshoot"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicBookingEventAvailableSlot"}}],
                        },
                    },
                ],
            },
        },
        ...PublicBookingEventAvailableSlotFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetPublicBookingEventAvailableSlotsQuery, GetPublicBookingEventAvailableSlotsQueryVariables>;
export const GetPublicBookingAvailableDateSlotsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetPublicBookingAvailableDateSlots"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookTimeSlots"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicBookingEventAvailableSlot"}}],
                        },
                    },
                ],
            },
        },
        ...PublicBookingEventAvailableSlotFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetPublicBookingAvailableDateSlotsQuery, GetPublicBookingAvailableDateSlotsQueryVariables>;
export const GetEventBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetEventBookings"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "BookingWhereInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "after"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "ID"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookings"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "first"},
                                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cursor"},
                                value: {kind: "Variable", name: {kind: "Name", value: "after"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Booking"}}],
                        },
                    },
                ],
            },
        },
        ...BookingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetEventBookingsQuery, GetEventBookingsQueryVariables>;
export const GetEventBookingsInitialDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetEventBookingsInitial"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "bookingEventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "from"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "to"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingsCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "bookingEventId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingsCount"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookings"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "event"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "id"},
                                                        value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                                {
                                                                    kind: "ObjectField",
                                                                    name: {kind: "Name", value: "equals"},
                                                                    value: {
                                                                        kind: "Variable",
                                                                        name: {kind: "Name", value: "bookingEventId"},
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "session"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "OR"},
                                                        value: {
                                                            kind: "ListValue",
                                                            values: [
                                                                {
                                                                    kind: "ObjectValue",
                                                                    fields: [
                                                                        {
                                                                            kind: "ObjectField",
                                                                            name: {kind: "Name", value: "startAt"},
                                                                            value: {
                                                                                kind: "ObjectValue",
                                                                                fields: [
                                                                                    {
                                                                                        kind: "ObjectField",
                                                                                        name: {kind: "Name", value: "lte"},
                                                                                        value: {
                                                                                            kind: "Variable",
                                                                                            name: {kind: "Name", value: "to"},
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        kind: "ObjectField",
                                                                                        name: {kind: "Name", value: "gte"},
                                                                                        value: {
                                                                                            kind: "Variable",
                                                                                            name: {kind: "Name", value: "from"},
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    kind: "ObjectValue",
                                                                    fields: [
                                                                        {
                                                                            kind: "ObjectField",
                                                                            name: {kind: "Name", value: "plannedEnd"},
                                                                            value: {
                                                                                kind: "ObjectValue",
                                                                                fields: [
                                                                                    {
                                                                                        kind: "ObjectField",
                                                                                        name: {kind: "Name", value: "lte"},
                                                                                        value: {
                                                                                            kind: "Variable",
                                                                                            name: {kind: "Name", value: "to"},
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        kind: "ObjectField",
                                                                                        name: {kind: "Name", value: "gte"},
                                                                                        value: {
                                                                                            kind: "Variable",
                                                                                            name: {kind: "Name", value: "from"},
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    kind: "ObjectValue",
                                                                    fields: [
                                                                        {
                                                                            kind: "ObjectField",
                                                                            name: {kind: "Name", value: "startAt"},
                                                                            value: {
                                                                                kind: "ObjectValue",
                                                                                fields: [
                                                                                    {
                                                                                        kind: "ObjectField",
                                                                                        name: {kind: "Name", value: "lte"},
                                                                                        value: {
                                                                                            kind: "Variable",
                                                                                            name: {kind: "Name", value: "from"},
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                        {
                                                                            kind: "ObjectField",
                                                                            name: {kind: "Name", value: "plannedEnd"},
                                                                            value: {
                                                                                kind: "ObjectValue",
                                                                                fields: [
                                                                                    {
                                                                                        kind: "ObjectField",
                                                                                        name: {kind: "Name", value: "gte"},
                                                                                        value: {
                                                                                            kind: "Variable",
                                                                                            name: {kind: "Name", value: "to"},
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "first"},
                                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Booking"}}],
                        },
                    },
                ],
            },
        },
        ...BookingsCountFragmentDoc.definitions,
        ...BookingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetEventBookingsInitialQuery, GetEventBookingsInitialQueryVariables>;
export const GetBookingsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingsCount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "bookingEventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingsCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "bookingEventId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingsCount"}}],
                        },
                    },
                ],
            },
        },
        ...BookingsCountFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBookingsCountQuery, GetBookingsCountQueryVariables>;
export const GetBookingEventsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingEventsCount"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookingEventsCount"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "all"}},
                                {kind: "Field", name: {kind: "Name", value: "disabled"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetBookingEventsCountQuery, GetBookingEventsCountQueryVariables>;
export const GetBookedEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookedEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeEvent"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookedEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "token"},
                                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "FragmentSpread", name: {kind: "Name", value: "BookedEvent"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "event"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeEvent"}},
                                                },
                                            ],
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicBookingEvent"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...BookedEventFragmentDoc.definitions,
        ...PublicBookingEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetBookedEventQuery, GetBookedEventQueryVariables>;
export const UpsertBookingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertBookingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "create"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "BookingEventCreateInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "update"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "BookingEventUpdateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertOneBookingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "create"},
                                value: {kind: "Variable", name: {kind: "Name", value: "create"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "update"},
                                value: {kind: "Variable", name: {kind: "Name", value: "update"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingEvent"}}],
                        },
                    },
                ],
            },
        },
        ...BookingEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertBookingEventMutation, UpsertBookingEventMutationVariables>;
export const DeleteBookingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteBookingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneBookingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reason"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteBookingEventMutation, DeleteBookingEventMutationVariables>;
export const ToggleBookingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "toggleBookingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "disabled"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cancelBookings"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "toggleBookingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "disabled"},
                                value: {kind: "Variable", name: {kind: "Name", value: "disabled"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cancelBookings"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cancelBookings"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reason"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingEvent"}}],
                        },
                    },
                ],
            },
        },
        ...BookingEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ToggleBookingEventMutation, ToggleBookingEventMutationVariables>;
export const CloneBookingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CloneBookingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "cloneOneBookingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingEvent"}}],
                        },
                    },
                ],
            },
        },
        ...BookingEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CloneBookingEventMutation, CloneBookingEventMutationVariables>;
export const BookEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "bookEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timeSlot"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "duration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "additionalInformation"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "guests"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "questionnare"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timeZone"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "availableAssignees"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "paymentProvider"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isPreview"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "bookEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "bookingEventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timeSlot"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timeSlot"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "duration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "duration"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "additionalInformation"},
                                value: {kind: "Variable", name: {kind: "Name", value: "additionalInformation"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "questionnare"},
                                value: {kind: "Variable", name: {kind: "Name", value: "questionnare"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "guests"},
                                value: {kind: "Variable", name: {kind: "Name", value: "guests"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timeZone"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timeZone"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "availableAssignees"},
                                value: {kind: "Variable", name: {kind: "Name", value: "availableAssignees"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "paymentProvider"},
                                value: {kind: "Variable", name: {kind: "Name", value: "paymentProvider"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isPreview"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isPreview"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookedEvent"}}],
                        },
                    },
                ],
            },
        },
        ...BookedEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BookEventMutation, BookEventMutationVariables>;
export const UpsertBookingScheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertBookingSchedule"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "create"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "BookingScheduleCreateInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "update"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "BookingScheduleUpdateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertOneBookingSchedule"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "create"},
                                value: {kind: "Variable", name: {kind: "Name", value: "create"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "update"},
                                value: {kind: "Variable", name: {kind: "Name", value: "update"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingSchedule"}}],
                        },
                    },
                ],
            },
        },
        ...BookingScheduleFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertBookingScheduleMutation, UpsertBookingScheduleMutationVariables>;
export const UpsertBookingCollaboratorDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertBookingCollaborator"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "create"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "CreateBookingCollaboratorInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "update"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UpdateBookingCollaboratorInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertOneBookingCollaborator"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "create"},
                                value: {kind: "Variable", name: {kind: "Name", value: "create"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "update"},
                                value: {kind: "Variable", name: {kind: "Name", value: "update"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookingCollaborator"}}],
                        },
                    },
                ],
            },
        },
        ...BookingCollaboratorFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertBookingCollaboratorMutation, UpsertBookingCollaboratorMutationVariables>;
export const DeleteBookingCollaboratorDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteBookingCollaborator"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneBookingCollaborator"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteBookingCollaboratorMutation, DeleteBookingCollaboratorMutationVariables>;
export const CancelBookingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "cancelBooking"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "cancelBooking"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reason"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Booking"}}],
                        },
                    },
                ],
            },
        },
        ...BookingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CancelBookingMutation, CancelBookingMutationVariables>;
export const DeleteBookingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteBooking"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneBooking"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteBookingMutation, DeleteBookingMutationVariables>;
export const SeenBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "seenBookings"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "ids"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "seenBookings"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "ids"},
                                value: {kind: "Variable", name: {kind: "Name", value: "ids"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "seen"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SeenBookingsMutation, SeenBookingsMutationVariables>;
export const CancelBookedEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CancelBookedEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "bookedEventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "cancelBookedEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "bookedEventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "bookedEventId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "token"},
                                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reason"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CancelBookedEventMutation, CancelBookedEventMutationVariables>;
export const RescheduleBookedEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RescheduleBookedEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "bookedEventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timeSlot"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "additionalInformation"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "guests"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timeZone"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "paymentProvider"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "rescheduleBookedEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "bookedEventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "bookedEventId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "token"},
                                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timeSlot"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timeSlot"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "additionalInformation"},
                                value: {kind: "Variable", name: {kind: "Name", value: "additionalInformation"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "guests"},
                                value: {kind: "Variable", name: {kind: "Name", value: "guests"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timeZone"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timeZone"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "paymentProvider"},
                                value: {kind: "Variable", name: {kind: "Name", value: "paymentProvider"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BookedEvent"}}],
                        },
                    },
                ],
            },
        },
        ...BookedEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RescheduleBookedEventMutation, RescheduleBookedEventMutationVariables>;
export const ChangeBookingAssigneeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ChangeBookingAssignee"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "bookingId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "collaboratorId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changeBookingAssignee"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "bookingId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "bookingId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "collaboratorId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "collaboratorId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Booking"}}],
                        },
                    },
                ],
            },
        },
        ...BookingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ChangeBookingAssigneeMutation, ChangeBookingAssigneeMutationVariables>;
export const OnNewBookingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnNewBooking"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onNewBooking"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "bookingsCount"}},
                                {kind: "Field", name: {kind: "Name", value: "seen"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "booking"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Booking"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...BookingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OnNewBookingSubscription, OnNewBookingSubscriptionVariables>;
export const IsBookingSlugUniqueDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "IsBookingSlugUnique"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkIfBookingSlugUnique"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "slug"},
                                value: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "isUnique"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IsBookingSlugUniqueQuery, IsBookingSlugUniqueQueryVariables>;
export const GetExternalCalendarsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetExternalCalendars"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "from"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "until"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendar"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getMicrosoftEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendar"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getAppleEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendar"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...ExternalCalendarFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetExternalCalendarsQuery, GetExternalCalendarsQueryVariables>;
export const OnBookingEventUpdateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnBookingEventUpdate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onBookingEventUpdate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "bookingEventId"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnBookingEventUpdateSubscription, OnBookingEventUpdateSubscriptionVariables>;
export const GetBookingAnswersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetBookingAnswers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getBookingAnswers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "form"}},
                                {kind: "Field", name: {kind: "Name", value: "answers"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetBookingAnswersQuery, GetBookingAnswersQueryVariables>;
export const GetCalendarEventWithParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCalendarEventWithParticipants"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendarEventWithParticipants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventCustom"}}],
                        },
                    },
                ],
            },
        },
        ...CalendarEventCustomFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetCalendarEventWithParticipantsQuery, GetCalendarEventWithParticipantsQueryVariables>;
export const GetCalendarEventsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCalendarEvents"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "from"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "until"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeNormal"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeRecurrent"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeExternal"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendarEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "include"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "includeNormal"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventCustom"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendarRecurrentEvents"},
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "include"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "includeRecurrent"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventCustom"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "include"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "includeExternal"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "events"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventCustom"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendar"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getMicrosoftEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "include"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "includeExternal"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "events"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventCustom"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendar"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getAppleEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "include"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "includeExternal"}},
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "events"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CalendarEventCustom"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ExternalCalendar"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...CalendarEventCustomFragmentDoc.definitions,
        ...ExternalCalendarFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetCalendarEventsQuery, GetCalendarEventsQueryVariables>;
export const UpdateParticipantInviteStatusDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateParticipantInviteStatus"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "inviteStatus"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ParticipantInviteStatus"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateParticipantInviteStatus"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "inviteStatus"},
                                value: {kind: "Variable", name: {kind: "Name", value: "inviteStatus"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reason"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateParticipantInviteStatusMutation, UpdateParticipantInviteStatusMutationVariables>;
export const DeleteSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "session"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "session"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSessionMutation, DeleteSessionMutationVariables>;
export const DeleteSessionOccurrenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteSessionOccurrence"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "occurrenceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteSessionOccurrence"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "occurrenceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "occurrenceId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSessionOccurrenceMutation, DeleteSessionOccurrenceMutationVariables>;
export const DeleteSessionOccurrenceAndFollowingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteSessionOccurrenceAndFollowing"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "occurrenceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteSessionOccurrenceAndFollowing"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "occurrenceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "occurrenceId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSessionOccurrenceAndFollowingMutation, DeleteSessionOccurrenceAndFollowingMutationVariables>;
export const CreateSessionOccurrenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateSessionOccurrence"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "occurrenceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createSessionOccurrence"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "occurrenceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "occurrenceId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateSessionOccurrenceMutation, CreateSessionOccurrenceMutationVariables>;
export const RemoveYourselfFromSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RemoveYourselfFromSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "soft"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "upcoming"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "removeParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "soft"},
                                value: {kind: "Variable", name: {kind: "Name", value: "soft"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "upcoming"},
                                value: {kind: "Variable", name: {kind: "Name", value: "upcoming"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveYourselfFromSessionMutation, RemoveYourselfFromSessionMutationVariables>;
export const CalendarSettingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "calendarSettings"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "calendarSettings"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "view"}},
                                {kind: "Field", name: {kind: "Name", value: "live"}},
                                {kind: "Field", name: {kind: "Name", value: "invitations"}},
                                {kind: "Field", name: {kind: "Name", value: "bookings"}},
                                {kind: "Field", name: {kind: "Name", value: "events"}},
                                {kind: "Field", name: {kind: "Name", value: "tasks"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "externalCalendars"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "calendarId"}},
                                            {kind: "Field", name: {kind: "Name", value: "calendarTitle"}},
                                            {kind: "Field", name: {kind: "Name", value: "enabled"}},
                                            {kind: "Field", name: {kind: "Name", value: "type"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CalendarSettingsQuery, CalendarSettingsQueryVariables>;
export const DeleteMicrosoftConnectionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteMicrosoftConnection"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "provider"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteUserMicrosoftProviders"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "ListValue", values: [{kind: "Variable", name: {kind: "Name", value: "email"}}]},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "provider"},
                                value: {kind: "Variable", name: {kind: "Name", value: "provider"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteMicrosoftConnectionMutation, DeleteMicrosoftConnectionMutationVariables>;
export const SyncAppleAccountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SyncAppleAccount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "password"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "syncAppleAccount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "username"},
                                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "password"},
                                value: {kind: "Variable", name: {kind: "Name", value: "password"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "email"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SyncAppleAccountMutation, SyncAppleAccountMutationVariables>;
export const DeleteAppleCalendarConnectionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteAppleCalendarConnection"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteUserAppleProviders"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "ListValue", values: [{kind: "Variable", name: {kind: "Name", value: "email"}}]},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteAppleCalendarConnectionMutation, DeleteAppleCalendarConnectionMutationVariables>;
export const EnforceSpotlightDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "EnforceSpotlight"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "StringValue", value: "enforceSpotlight", block: false},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EnforceSpotlightMutation, EnforceSpotlightMutationVariables>;
export const SetEnableReactionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetEnableReactions"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "enableReactionsType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionFlagsType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setEnableReactions"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "enableReactionsType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "enableReactionsType"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "Field", name: {kind: "Name", value: "enableReactionsType"}}],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetEnableReactionsMutation, SetEnableReactionsMutationVariables>;
export const SetAllowScreenShareDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetAllowScreenShare"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "allowScreenShareType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionFlagsType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setAllowScreenShare"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "allowScreenShareType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "allowScreenShareType"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "Field", name: {kind: "Name", value: "allowScreenShareType"}}],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetAllowScreenShareMutation, SetAllowScreenShareMutationVariables>;
export const SetShareScreenParticipantIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetShareScreenParticipantId"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "stopPresenting"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "shareParticipantScreen"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "stopPresenting"},
                                value: {kind: "Variable", name: {kind: "Name", value: "stopPresenting"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetShareScreenParticipantIdMutation, SetShareScreenParticipantIdMutationVariables>;
export const SetSessionHardMuteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetSessionHardMute"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "hardMute"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionFlagsType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setSessionHardMute"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "hardMute"},
                                value: {kind: "Variable", name: {kind: "Name", value: "hardMute"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "Field", name: {kind: "Name", value: "allowMicrophoneType"}}],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetSessionHardMuteMutation, SetSessionHardMuteMutationVariables>;
export const SetSessionDisableParticipantsVideoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetSessionDisableParticipantsVideo"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "allowCameraType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionFlagsType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setSessionDisableParticipantsVideo"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "allowCameraType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "allowCameraType"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "allowCameraType"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetSessionDisableParticipantsVideoMutation, SetSessionDisableParticipantsVideoMutationVariables>;
export const SendSessionEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendSessionEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionEventInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantsIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendSessionEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantsIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantsIds"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendSessionEventMutation, SendSessionEventMutationVariables>;
export const MuteParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "MuteParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "muteParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MuteParticipantMutation, MuteParticipantMutationVariables>;
export const StopCameraParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "StopCameraParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "stopCameraParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StopCameraParticipantMutation, StopCameraParticipantMutationVariables>;
export const GetCustomEmailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCustomEmails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspacePermissionsId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getCustomEmails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspacePermissionsId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspacePermissionsId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "templateId"}},
                                {kind: "Field", name: {kind: "Name", value: "subject"}},
                                {kind: "Field", name: {kind: "Name", value: "body"}},
                                {kind: "Field", name: {kind: "Name", value: "bodyTitle"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "customEmailContent"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomEmailContent"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "disableSending"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getCustomEmailSignature"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspacePermissionsId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspacePermissionsId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "signature"}},
                                {kind: "Field", name: {kind: "Name", value: "footer"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getCustomEmailsLanguage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspacePermissionsId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspacePermissionsId"}},
                            },
                        ],
                    },
                ],
            },
        },
        ...CustomEmailContentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetCustomEmailsQuery, GetCustomEmailsQueryVariables>;
export const UpsertCustomEmailDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertCustomEmail"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "templateId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "subject"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "body"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "bodyTitle"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "customEmailContent"}},
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {kind: "NamedType", name: {kind: "Name", value: "UpsertCustomEmailContentInput"}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "disableSending"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertCustomEmail"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "templateId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "templateId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "subject"},
                                value: {kind: "Variable", name: {kind: "Name", value: "subject"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "body"},
                                value: {kind: "Variable", name: {kind: "Name", value: "body"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "bodyTitle"},
                                value: {kind: "Variable", name: {kind: "Name", value: "bodyTitle"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "customEmailContent"},
                                value: {kind: "Variable", name: {kind: "Name", value: "customEmailContent"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "disableSending"},
                                value: {kind: "Variable", name: {kind: "Name", value: "disableSending"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "templateId"}},
                                {kind: "Field", name: {kind: "Name", value: "subject"}},
                                {kind: "Field", name: {kind: "Name", value: "body"}},
                                {kind: "Field", name: {kind: "Name", value: "bodyTitle"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "customEmailContent"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomEmailContent"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "disableSending"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...CustomEmailContentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertCustomEmailMutation, UpsertCustomEmailMutationVariables>;
export const UpdateCustomEmailSignatureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateCustomEmailSignature"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "signature"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "footer"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateCustomEmailSignature"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "signature"},
                                value: {kind: "Variable", name: {kind: "Name", value: "signature"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "footer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "footer"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "signature"}},
                                {kind: "Field", name: {kind: "Name", value: "footer"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateCustomEmailSignatureMutation, UpdateCustomEmailSignatureMutationVariables>;
export const UpdateCustomEmailsLanguageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateCustomEmailsLanguage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateCustomEmailsLanguage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateCustomEmailsLanguageMutation, UpdateCustomEmailsLanguageMutationVariables>;
export const DeleteCustomEmailDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteCustomEmail"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteCustomEmail"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "templateId"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCustomEmailMutation, DeleteCustomEmailMutationVariables>;
export const DesignerCommitStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerCommitState"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerCommitState"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "Field", name: {kind: "Name", value: "commitInProgress"}}],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerCommitStateQuery, DesignerCommitStateQueryVariables>;
export const CreateOneSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateOneSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionCreateInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "meetingRegion"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "meetingRegion"},
                                value: {kind: "Variable", name: {kind: "Name", value: "meetingRegion"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionNoCache"}}],
                        },
                    },
                ],
            },
        },
        ...SessionNoCacheFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateOneSessionMutation, CreateOneSessionMutationVariables>;
export const UpdateOneSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOneSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionUpdateInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "hasParticipants"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isApplyingTemplate"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isApplyingTemplate"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isApplyingTemplate"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "FragmentSpread",
                                    name: {kind: "Name", value: "SessionNoCache"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "skip"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "hasParticipants"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...SessionNoCacheFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateOneSessionMutation, UpdateOneSessionMutationVariables>;
export const UpdateParticipantsInSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateParticipantsInSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "deletedParticipantIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "upsertParticipantJSONs"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "followUpOfSessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateParticipantsInSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "deletedParticipantIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "deletedParticipantIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "upsertParticipantJSONs"},
                                value: {kind: "Variable", name: {kind: "Name", value: "upsertParticipantJSONs"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "followUpOfSessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "followUpOfSessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionNoCache"}}],
                        },
                    },
                ],
            },
        },
        ...SessionNoCacheFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateParticipantsInSessionMutation, UpdateParticipantsInSessionMutationVariables>;
export const UpdateParticipantsInSessionStandaloneDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateParticipantsInSessionStandalone"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "deletedParticipantIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "upsertParticipantJSONs"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "followUpOfSessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateParticipantsInSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "deletedParticipantIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "deletedParticipantIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "upsertParticipantJSONs"},
                                value: {kind: "Variable", name: {kind: "Name", value: "upsertParticipantJSONs"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "followUpOfSessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "followUpOfSessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "participants"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateParticipantsInSessionStandaloneMutation, UpdateParticipantsInSessionStandaloneMutationVariables>;
export const DesignerStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerState"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerState"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "selectedAgendaItemId"}},
                                {kind: "Field", name: {kind: "Name", value: "selectedMacroIdToConfig"}},
                                {kind: "Field", name: {kind: "Name", value: "selectedMicroIdToConfig"}},
                                {kind: "Field", name: {kind: "Name", value: "selectedMacroIdToPreview"}},
                                {kind: "Field", name: {kind: "Name", value: "selectedMicroIdToPreview"}},
                                {kind: "Field", name: {kind: "Name", value: "typeOfBlockToAdd"}},
                                {kind: "Field", name: {kind: "Name", value: "isAddingBlock"}},
                                {kind: "Field", name: {kind: "Name", value: "isConfig"}},
                                {kind: "Field", name: {kind: "Name", value: "isPreview"}},
                                {kind: "Field", name: {kind: "Name", value: "selectedResourceId"}},
                                {kind: "Field", name: {kind: "Name", value: "templateMode"}},
                                {kind: "Field", name: {kind: "Name", value: "toolStep"}},
                                {kind: "Field", name: {kind: "Name", value: "deleteAgendaItemIds"}},
                                {kind: "Field", name: {kind: "Name", value: "isSessionPreview"}},
                                {kind: "Field", name: {kind: "Name", value: "checkedRecurrenceEditForSessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "recurrenceEditType"}},
                                {kind: "Field", name: {kind: "Name", value: "configurationStep"}},
                                {kind: "Field", name: {kind: "Name", value: "dirty"}},
                                {kind: "Field", name: {kind: "Name", value: "followUpOfSessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "preventRestore"}},
                                {kind: "Field", name: {kind: "Name", value: "configurationConfirm"}},
                                {kind: "Field", name: {kind: "Name", value: "configInProgress"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerStateQuery, DesignerStateQueryVariables>;
export const DesignerSessionIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerSessionId"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerSessionId"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "sessionId"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerSessionIdQuery, DesignerSessionIdQueryVariables>;
export const DesignerSessionLockedDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerSessionLocked"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerSessionLocked"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "locked"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerSessionLockedQuery, DesignerSessionLockedQueryVariables>;
export const DesignerSessionChangedByDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerSessionChangedBy"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerSessionChangedBy"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "changedBy"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerSessionChangedByQuery, DesignerSessionChangedByQueryVariables>;
export const DesignerEditStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerEditState"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerEditState"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "isEditing"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerEditStateQuery, DesignerEditStateQueryVariables>;
export const DesignerRestoreUncommitedDraftDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "DesignerRestoreUncommitedDraft"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "designerRestoreUncommitedDraft"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "success"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DesignerRestoreUncommitedDraftQuery, DesignerRestoreUncommitedDraftQueryVariables>;
export const GetPaymentProvidersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetPaymentProviders"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getPaymentProviders"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentProvider"}}],
                        },
                    },
                ],
            },
        },
        ...PaymentProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetPaymentProvidersQuery, GetPaymentProvidersQueryVariables>;
export const GetProviderLinkedConfigsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetProviderLinkedConfigs"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getProviderLinkedConfigs"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "sessionEvent"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "session"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "name"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "bookingEvent"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProviderLinkedConfigsQuery, GetProviderLinkedConfigsQueryVariables>;
export const UpsertPaymentConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertPaymentConfig"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "create"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PaymentConfigCreateInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "update"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PaymentConfigUpdateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertPaymentConfig"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "create"},
                                value: {kind: "Variable", name: {kind: "Name", value: "create"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "update"},
                                value: {kind: "Variable", name: {kind: "Name", value: "update"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                        },
                    },
                ],
            },
        },
        ...PaymentConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertPaymentConfigMutation, UpsertPaymentConfigMutationVariables>;
export const DeletePaymentConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeletePaymentConfig"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deletePaymentConfig"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PaymentConfig"}}],
                        },
                    },
                ],
            },
        },
        ...PaymentConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<DeletePaymentConfigMutation, DeletePaymentConfigMutationVariables>;
export const SessionRecordingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "sessionRecording"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionRecording"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionRecording"}}],
                        },
                    },
                ],
            },
        },
        ...SessionRecordingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionRecordingQuery, SessionRecordingQueryVariables>;
export const RecordingUpdatedDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "RecordingUpdated"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onRecordingUpdated"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recording"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionRecording"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...MemorySessionRecordingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RecordingUpdatedSubscription, RecordingUpdatedSubscriptionVariables>;
export const UseResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "useResource"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resource"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceFull"}}],
                        },
                    },
                ],
            },
        },
        ...ResourceFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UseResourceQuery, UseResourceQueryVariables>;
export const UseResourcesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UseResources"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "ResourceWhereInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resources"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceFull"}}],
                        },
                    },
                ],
            },
        },
        ...ResourceFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UseResourcesQuery, UseResourcesQueryVariables>;
export const FindResourceByNameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "FindResourceByName"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "content"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "StringFilter"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "StringFilter"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "StringFilter"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resources"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "content"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "content"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "userId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "userId"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "type"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "type"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceFull"}}],
                        },
                    },
                ],
            },
        },
        ...ResourceFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<FindResourceByNameQuery, FindResourceByNameQueryVariables>;
export const UseResourcesForRenderingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UseResourcesForRendering"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "container"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resxResources"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "type"},
                                value: {kind: "Variable", name: {kind: "Name", value: "type"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "container"},
                                value: {kind: "Variable", name: {kind: "Name", value: "container"}},
                            },
                        ],
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "hasData"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "resources"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceFull"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...ResourceFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UseResourcesForRenderingQuery, UseResourcesForRenderingQueryVariables>;
export const UseResourceResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UseResourceResult"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "resultId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resourceResult"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "resultId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "FragmentSpread", name: {kind: "Name", value: "ResourceResultFull"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...ResourceResultFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UseResourceResultQuery, UseResourceResultQueryVariables>;
export const GetSessionResourceNamesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionResourceNames"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionResourceNames"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "names"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceName"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...ResourceNameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionResourceNamesQuery, GetSessionResourceNamesQueryVariables>;
export const ResxResourceUpdatedNotifyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "resxResourceUpdatedNotify"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resxResourceUpdated"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "resourceId"}},
                                {kind: "Field", name: {kind: "Name", value: "resourceType"}},
                                {kind: "Field", name: {kind: "Name", value: "specificationVersion"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResxResourceUpdatedNotifySubscription, ResxResourceUpdatedNotifySubscriptionVariables>;
export const ResxResultUpdatedNotifyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "resxResultUpdatedNotify"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "resxResultUpdated"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "resultId"}},
                                {kind: "Field", name: {kind: "Name", value: "resultType"}},
                                {kind: "Field", name: {kind: "Name", value: "specificationVersion"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResxResultUpdatedNotifySubscription, ResxResultUpdatedNotifySubscriptionVariables>;
export const SkipSeenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "skipSeen"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "segment"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "expiry"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "skipSeen"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "segment"},
                                value: {kind: "Variable", name: {kind: "Name", value: "segment"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "expiry"},
                                value: {kind: "Variable", name: {kind: "Name", value: "expiry"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SkipSeenMutation, SkipSeenMutationVariables>;
export const GetUserCustomBackgroundsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUserCustomBackgrounds"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getRemoteUser"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "user"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "customBackgrounds"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "url"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserCustomBackgroundsQuery, GetUserCustomBackgroundsQueryVariables>;
export const GetCustomBackgroundUploadUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCustomBackgroundUploadUrl"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [{kind: "Field", name: {kind: "Name", value: "getCustomBackgroundUploadUrl"}}],
            },
        },
    ],
} as unknown as DocumentNode<GetCustomBackgroundUploadUrlQuery, GetCustomBackgroundUploadUrlQueryVariables>;
export const CommitCustomBackgroundDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CommitCustomBackground"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "uploadUrl"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "commitCustomBackground"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "uploadUrl"},
                                value: {kind: "Variable", name: {kind: "Name", value: "uploadUrl"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "customBackgrounds"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "url"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CommitCustomBackgroundMutation, CommitCustomBackgroundMutationVariables>;
export const DeleteCustomBackgroundDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteCustomBackground"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "backgroundUrl"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteCustomBackground"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "backgroundUrl"},
                                value: {kind: "Variable", name: {kind: "Name", value: "backgroundUrl"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "customBackgrounds"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "url"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCustomBackgroundMutation, DeleteCustomBackgroundMutationVariables>;
export const PaddleSubscriptionDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "PaddleSubscriptionDetails"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paddleSubscriptionDetails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SubscriptionDetails"}}],
                        },
                    },
                ],
            },
        },
        ...SubscriptionDetailsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<PaddleSubscriptionDetailsQuery, PaddleSubscriptionDetailsQueryVariables>;
export const UserOrganizationsPaddleSubscriptionDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UserOrganizationsPaddleSubscriptionDetails"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userOrganizationsPaddleSubscriptionDetails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SubscriptionDetails"}}],
                        },
                    },
                ],
            },
        },
        ...SubscriptionDetailsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UserOrganizationsPaddleSubscriptionDetailsQuery, UserOrganizationsPaddleSubscriptionDetailsQueryVariables>;
export const PaddlePlansDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "PaddlePlans"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paddlePlans"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "plan"}},
                                {kind: "Field", name: {kind: "Name", value: "billing_type"}},
                                {kind: "Field", name: {kind: "Name", value: "billing_period"}},
                                {kind: "Field", name: {kind: "Name", value: "trial_days"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "initial_price"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "currency"}},
                                            {kind: "Field", name: {kind: "Name", value: "amount"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recurring_price"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "currency"}},
                                            {kind: "Field", name: {kind: "Name", value: "amount"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "modifiers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "type"}},
                                            {kind: "Field", name: {kind: "Name", value: "pricePerUnitInDollars"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaddlePlansQuery, PaddlePlansQueryVariables>;
export const PaddleOfferPlansDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "PaddleOfferPlans"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paddleOfferPlans"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "plan"}},
                                {kind: "Field", name: {kind: "Name", value: "billing_type"}},
                                {kind: "Field", name: {kind: "Name", value: "billing_period"}},
                                {kind: "Field", name: {kind: "Name", value: "trial_days"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "initial_price"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "currency"}},
                                            {kind: "Field", name: {kind: "Name", value: "amount"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recurring_price"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "currency"}},
                                            {kind: "Field", name: {kind: "Name", value: "amount"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "modifiers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "type"}},
                                            {kind: "Field", name: {kind: "Name", value: "pricePerUnitInDollars"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaddleOfferPlansQuery, PaddleOfferPlansQueryVariables>;
export const GetSkipTrialCouponDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSkipTrialCoupon"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getSkipTrialCoupon"}}]},
        },
    ],
} as unknown as DocumentNode<GetSkipTrialCouponQuery, GetSkipTrialCouponQueryVariables>;
export const PaddleTrialPlansDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "PaddleTrialPlans"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "paddleTrialPlans"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "plan"}},
                                {kind: "Field", name: {kind: "Name", value: "billing_type"}},
                                {kind: "Field", name: {kind: "Name", value: "billing_period"}},
                                {kind: "Field", name: {kind: "Name", value: "trial_days"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "initial_price"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "currency"}},
                                            {kind: "Field", name: {kind: "Name", value: "amount"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recurring_price"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "currency"}},
                                            {kind: "Field", name: {kind: "Name", value: "amount"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "modifiers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "type"}},
                                            {kind: "Field", name: {kind: "Name", value: "pricePerUnitInDollars"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaddleTrialPlansQuery, PaddleTrialPlansQueryVariables>;
export const UserPaddleTransactionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UserPaddleTransactions"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "page"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userPaddleTransactions"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "page"},
                                value: {kind: "Variable", name: {kind: "Name", value: "page"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "UserPaddleTransactionsInfo"}}],
                        },
                    },
                ],
            },
        },
        ...UserPaddleTransactionsInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UserPaddleTransactionsQuery, UserPaddleTransactionsQueryVariables>;
export const ChangeSubscriptionPlanDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ChangeSubscriptionPlan"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "planId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changeSubscriptionPlan"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "planId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "planId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>;
export const CreateSubscriptionModifierDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateSubscriptionModifier"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "modifierType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SubscriptionModifierType"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "quantity"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createSubscriptionModifier"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "modifierType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "modifierType"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "quantity"},
                                value: {kind: "Variable", name: {kind: "Name", value: "quantity"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateSubscriptionModifierMutation, CreateSubscriptionModifierMutationVariables>;
export const GetSessionsUsingResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionsUsingResource"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "resourceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSessionsByResourceId"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "resourceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "resourceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionUsingResult"}}],
                        },
                    },
                ],
            },
        },
        ...OneSessionUsingResultFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionsUsingResourceQuery, GetSessionsUsingResourceQueryVariables>;
export const GetSessionsUsingAgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionsUsingAgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "agendaTemplateId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSessionsByAgendaTemplateId"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "agendaTemplateId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "agendaTemplateId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionUsingResult"}}],
                        },
                    },
                ],
            },
        },
        ...OneSessionUsingResultFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionsUsingAgendaTemplateQuery, GetSessionsUsingAgendaTemplateQueryVariables>;
export const GetContactsAndGroupsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetContactsAndGroups"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeSelf"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "contacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeSelf"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeSelf"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactInfo"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "contactGroups"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactGroupInfo"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "GoogleSyncedEmail"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getMicrosoftSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MicrosoftSyncedEmail"}}],
                        },
                    },
                ],
            },
        },
        ...ContactInfoFragmentDoc.definitions,
        ...ContactGroupInfoFragmentDoc.definitions,
        ...GoogleSyncedEmailFragmentDoc.definitions,
        ...MicrosoftSyncedEmailFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetContactsAndGroupsQuery, GetContactsAndGroupsQueryVariables>;
export const GetContactsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetContacts"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ContactWhereInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeSelf"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "contacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeSelf"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeSelf"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactInfo"}}],
                        },
                    },
                ],
            },
        },
        ...ContactInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetContactsQuery, GetContactsQueryVariables>;
export const CreateGroupDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateGroup"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "contactIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneContactGroup"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "name"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "contactIds"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "contactIds"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactGroupInfo"}}],
                        },
                    },
                ],
            },
        },
        ...ContactGroupInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateGroup"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "addContactIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "removeContactIds"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneContactGroup"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "name"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "contacts"},
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "addContactIds"},
                                                        value: {kind: "Variable", name: {kind: "Name", value: "addContactIds"}},
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {kind: "Name", value: "removeContactIds"},
                                                        value: {kind: "Variable", name: {kind: "Name", value: "removeContactIds"}},
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactGroupInfo"}}],
                        },
                    },
                ],
            },
        },
        ...ContactGroupInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const DeleteGroupDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteGroup"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "groupId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneContactGroup"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "groupId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "groupId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const AddContactDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AddContact"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {
                        kind: "NonNullType",
                        type: {kind: "NamedType", name: {kind: "Name", value: "ContactCreateWithoutOwnerAndGroupInput"}},
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneContact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactInfo"}}],
                        },
                    },
                ],
            },
        },
        ...ContactInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AddContactMutation, AddContactMutationVariables>;
export const UpdateContactDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateContact"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {
                        kind: "NonNullType",
                        type: {kind: "NamedType", name: {kind: "Name", value: "ContactUpdateWithoutOwnerAndGroupInput"}},
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneContact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactInfo"}}],
                        },
                    },
                ],
            },
        },
        ...ContactInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateContactMutation, UpdateContactMutationVariables>;
export const MoveContactsToBinDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "MoveContactsToBin"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "addContactsToBin"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "contactsIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MoveContactsToBinMutation, MoveContactsToBinMutationVariables>;
export const RecoverContactsFromBinDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RecoverContactsFromBin"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "recoverContactsFromBin"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "contactsIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RecoverContactsFromBinMutation, RecoverContactsFromBinMutationVariables>;
export const DeleteContactsFromBinDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteContactsFromBin"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteContactsFromBin"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "contactsIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteContactsFromBinMutation, DeleteContactsFromBinMutationVariables>;
export const ImportCsvContactsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ImportCsvContacts"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "contacts"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {kind: "NamedType", name: {kind: "Name", value: "ContactCreateWithoutOwnerAndGroupInput"}},
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "groupId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "importCsvContacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "contacts"},
                                value: {kind: "Variable", name: {kind: "Name", value: "contacts"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "groupId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "groupId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ContactInfo"}}],
                        },
                    },
                ],
            },
        },
        ...ContactInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ImportCsvContactsMutation, ImportCsvContactsMutationVariables>;
export const ExportContactsToCsvDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ExportContactsToCsv"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "groupId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "exportContactsToCsv"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "contactsIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "contactsIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "groupId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "groupId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ExportContactsToCsvMutation, ExportContactsToCsvMutationVariables>;
export const OnGoogleContactsSyncFinishedDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnGoogleContactsSyncFinished"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userEmail"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onGoogleContactsSyncFinished"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "userEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "userEmail"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "userEmail"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnGoogleContactsSyncFinishedSubscription, OnGoogleContactsSyncFinishedSubscriptionVariables>;
export const OnMicrosoftContactsSyncFinishedDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnMicrosoftContactsSyncFinished"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userEmail"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onMicrosoftContactsSyncFinished"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "userEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "userEmail"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "userEmail"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnMicrosoftContactsSyncFinishedSubscription, OnMicrosoftContactsSyncFinishedSubscriptionVariables>;
export const ConvertingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "Converting"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "converting"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "artifacts"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                                            {kind: "Field", name: {kind: "Name", value: "fileId"}},
                                            {kind: "Field", name: {kind: "Name", value: "completed"}},
                                            {kind: "Field", name: {kind: "Name", value: "url"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConvertingQuery, ConvertingQueryVariables>;
export const SearchParticipantsByEmailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SearchParticipantsByEmails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "term"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "take"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "searchParticipantsByEmails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "term"},
                                value: {kind: "Variable", name: {kind: "Name", value: "term"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "take"},
                                value: {kind: "Variable", name: {kind: "Name", value: "take"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "data"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SearchParticipantsByEmailsQuery, SearchParticipantsByEmailsQueryVariables>;
export const GetSessionSettingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionSettings"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionSettings"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionSettingsFragment"}}],
                        },
                    },
                ],
            },
        },
        ...SessionSettingsFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionSettingsQuery, GetSessionSettingsQueryVariables>;
export const SaveSessionSettingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveSessionSettings"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "settingsFlags"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionSettingsFlagsInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveSessionSettings"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "settingsFlags"},
                                value: {kind: "Variable", name: {kind: "Name", value: "settingsFlags"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionSettingsFragment"}}],
                        },
                    },
                ],
            },
        },
        ...SessionSettingsFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SaveSessionSettingsMutation, SaveSessionSettingsMutationVariables>;
export const NotificationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "Notifications"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "notifications"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "title"}},
                                {kind: "Field", name: {kind: "Name", value: "body"}},
                                {kind: "Field", name: {kind: "Name", value: "cta"}},
                                {kind: "Field", name: {kind: "Name", value: "ctaUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const AllSessionsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "AllSessions"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
                    type: {
                        kind: "ListType",
                        type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionOrderByInput"}}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "from"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "until"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "take"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "skip"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "owner"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "invited"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "attended"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "declined"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessions"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionFilterEnum"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "withRecording"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "withoutRecording"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "withSessionTags"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantEmails"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "lifecycle"}},
                    type: {
                        kind: "ListType",
                        type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionLifecycle"}}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantInviteStatuses"}},
                    type: {
                        kind: "ListType",
                        type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ParticipantInviteStatus"}}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sortByParticipants"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sortByParticipantStatus"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sortByRecording"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sortByDuration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sortBySessionTags"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "searchTerm"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userSessions"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "from"},
                                value: {kind: "Variable", name: {kind: "Name", value: "from"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "until"},
                                value: {kind: "Variable", name: {kind: "Name", value: "until"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "take"},
                                value: {kind: "Variable", name: {kind: "Name", value: "take"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "skip"},
                                value: {kind: "Variable", name: {kind: "Name", value: "skip"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "owner"},
                                value: {kind: "Variable", name: {kind: "Name", value: "owner"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "invited"},
                                value: {kind: "Variable", name: {kind: "Name", value: "invited"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "attended"},
                                value: {kind: "Variable", name: {kind: "Name", value: "attended"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "declined"},
                                value: {kind: "Variable", name: {kind: "Name", value: "declined"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessions"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessions"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "withRecording"},
                                value: {kind: "Variable", name: {kind: "Name", value: "withRecording"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "withoutRecording"},
                                value: {kind: "Variable", name: {kind: "Name", value: "withoutRecording"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "withSessionTags"},
                                value: {kind: "Variable", name: {kind: "Name", value: "withSessionTags"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantEmails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantEmails"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantInviteStatuses"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantInviteStatuses"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "lifecycle"},
                                value: {kind: "Variable", name: {kind: "Name", value: "lifecycle"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sortByParticipants"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sortByParticipants"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sortByParticipantStatus"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sortByParticipantStatus"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sortByRecording"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sortByRecording"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sortByDuration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sortByDuration"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sortBySessionTags"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sortBySessionTags"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "searchTerm"},
                                value: {kind: "Variable", name: {kind: "Name", value: "searchTerm"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "sessions"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemoryListSession"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "hasSearchTerm"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...MemoryListSessionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AllSessionsQuery, AllSessionsQueryVariables>;
export const GetMemorySessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetMemorySession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {kind: "Argument", name: {kind: "Name", value: "checkPlan"}, value: {kind: "BooleanValue", value: true}},
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "FragmentSpread", name: {kind: "Name", value: "OneSession"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "childOfBreakoutRooms"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recordings"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionRecording"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OneSessionFragmentDoc.definitions,
        ...BreakoutRoomsConfigFragmentDoc.definitions,
        ...MemorySessionRecordingFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetMemorySessionQuery, GetMemorySessionQueryVariables>;
export const LocalMemoryParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalMemoryParticipants"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionParticipant"}}],
                        },
                    },
                ],
            },
        },
        ...MemorySessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalMemoryParticipantsQuery, LocalMemoryParticipantsQueryVariables>;
export const LocalMemoryParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalMemoryParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionParticipant"}}],
                        },
                    },
                ],
            },
        },
        ...MemorySessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalMemoryParticipantQuery, LocalMemoryParticipantQueryVariables>;
export const FullMemorySessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "FullMemorySession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "checkPlan"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                    defaultValue: {kind: "BooleanValue", value: true},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeRegistrationAnswers"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                    defaultValue: {kind: "BooleanValue", value: true},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "checkPlan"},
                                value: {kind: "Variable", name: {kind: "Name", value: "checkPlan"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeRegistrationAnswers"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeRegistrationAnswers"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "FragmentSpread", name: {kind: "Name", value: "OneSession"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "childOfBreakoutRooms"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "recordings"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionRecording"}}],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "macroArtifacts"},
                        name: {kind: "Name", value: "artifacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "macroartifactforsession_sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "order"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MemorySessionParticipant"}}],
                        },
                    },
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "currentParticipant"},
                        name: {kind: "Name", value: "getCurrentParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CurrentParticipantData"}}],
                        },
                    },
                ],
            },
        },
        ...OneSessionFragmentDoc.definitions,
        ...BreakoutRoomsConfigFragmentDoc.definitions,
        ...MemorySessionRecordingFragmentDoc.definitions,
        ...OneMacroArtifactFragmentDoc.definitions,
        ...FullAgendaItemFragmentDoc.definitions,
        ...MemorySessionParticipantFragmentDoc.definitions,
        ...CurrentParticipantDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<FullMemorySessionQuery, FullMemorySessionQueryVariables>;
export const GetFeedbackSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetFeedbackSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", alias: {kind: "Name", value: "__id"}, name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "startAt"}},
                                {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "organizationPlan"}},
                                {kind: "Field", name: {kind: "Name", value: "collectFeedback"}},
                                {kind: "Field", name: {kind: "Name", value: "showExitPageCta"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "exitPageOffer"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "currentParticipant"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "feedbackForm"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "feedbackFormAnswers"}},
                                {kind: "Field", name: {kind: "Name", value: "workspaceId"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetFeedbackSessionQuery, GetFeedbackSessionQueryVariables>;
export const GetNullableCurrentParticipantIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetNullableCurrentParticipantId"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", alias: {kind: "Name", value: "__id"}, name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "nullableCurrentParticipant"},
                                    selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetNullableCurrentParticipantIdQuery, GetNullableCurrentParticipantIdQueryVariables>;
export const GetGuestInviteSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetGuestInviteSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "description"}},
                                {kind: "Field", name: {kind: "Name", value: "startAt"}},
                                {kind: "Field", name: {kind: "Name", value: "plannedEnd"}},
                                {kind: "Field", name: {kind: "Name", value: "organizationPlan"}},
                                {kind: "Field", name: {kind: "Name", value: "ownerHalfDayClock"}},
                                {kind: "Field", name: {kind: "Name", value: "ownerLanguage"}},
                                {kind: "FragmentSpread", name: {kind: "Name", value: "LobbyPictureImageDetails"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...LobbyPictureImageDetailsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetGuestInviteSessionQuery, GetGuestInviteSessionQueryVariables>;
export const GetSessionMessagesCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionMessagesCount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSessionMessagesCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSessionMessagesCountQuery, GetSessionMessagesCountQueryVariables>;
export const CapturePayPalOrderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CapturePayPalOrder"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "paymentId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "orderId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "capturePaypalPayment"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "paymentId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "paymentId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "orderId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "paymentId"}},
                                {kind: "Field", name: {kind: "Name", value: "paymentStatus"}},
                                {kind: "Field", name: {kind: "Name", value: "redirectUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CapturePayPalOrderMutation, CapturePayPalOrderMutationVariables>;
export const CancelPayPalOrderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CancelPayPalOrder"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "paymentId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "orderId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "cancelPaypalPayment"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "paymentId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "paymentId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "orderId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "paymentId"}},
                                {kind: "Field", name: {kind: "Name", value: "paymentStatus"}},
                                {kind: "Field", name: {kind: "Name", value: "redirectUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CancelPayPalOrderMutation, CancelPayPalOrderMutationVariables>;
export const SetSessionTimerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetSessionTimer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "state"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionTimerState"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "initialDuration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "currentDuration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setSessionTimer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "state"},
                                value: {kind: "Variable", name: {kind: "Name", value: "state"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "initialDuration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "initialDuration"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "currentDuration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "currentDuration"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetSessionTimerMutation, SetSessionTimerMutationVariables>;
export const MyRoomsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "MyRooms"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "rooms"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomData"}}],
                        },
                    },
                ],
            },
        },
        ...RoomDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MyRoomsQuery, MyRoomsQueryVariables>;
export const DeleteRoomDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteRoom"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteRoom"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reason"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reason"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "deleted"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteRoomMutation, DeleteRoomMutationVariables>;
export const CloneRoomDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CloneRoom"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "cloneRoom"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomData"}}],
                        },
                    },
                ],
            },
        },
        ...RoomDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CloneRoomMutation, CloneRoomMutationVariables>;
export const ToggleRoomActiveStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ToggleRoomActiveState"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "active"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "toggleRoomActiveState"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "active"},
                                value: {kind: "Variable", name: {kind: "Name", value: "active"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "active"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ToggleRoomActiveStateMutation, ToggleRoomActiveStateMutationVariables>;
export const ShareRoomDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ShareRoom"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "shareWithWorkspaceInput"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "RoomShareWorkspaceInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userEmails"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "shareRoom"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "shareWithWorkspaceInput"},
                                value: {kind: "Variable", name: {kind: "Name", value: "shareWithWorkspaceInput"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "userEmails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "userEmails"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomWithSessionInstances"}}],
                        },
                    },
                ],
            },
        },
        ...RoomWithSessionInstancesFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ShareRoomMutation, ShareRoomMutationVariables>;
export const IsRoomSessionInstanceEmptyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "IsRoomSessionInstanceEmpty"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isRoomSessionInstanceEmpty"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "isEmpty"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IsRoomSessionInstanceEmptyQuery, IsRoomSessionInstanceEmptyQueryVariables>;
export const RoomInstancesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "RoomInstances"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "room"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "roomId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RoomWithSessionInstances"}}],
                        },
                    },
                ],
            },
        },
        ...RoomWithSessionInstancesFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RoomInstancesQuery, RoomInstancesQueryVariables>;
export const IsRoomSlugUniqDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "IsRoomSlugUniq"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomSlug"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkIfRoomSlugUniq"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomSlug"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomSlug"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "isUnique"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IsRoomSlugUniqQuery, IsRoomSlugUniqQueryVariables>;
export const AgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "AgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AgendaTemplateQuery, AgendaTemplateQueryVariables>;
export const GetAgendaTemplateForEditingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetAgendaTemplateForEditing"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetAgendaTemplateForEditingQuery, GetAgendaTemplateForEditingQueryVariables>;
export const CommitAgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CommitAgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "newAgendaTemplate"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "commitAgendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "newAgendaTemplate"},
                                value: {kind: "Variable", name: {kind: "Name", value: "newAgendaTemplate"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CommitAgendaTemplateMutation, CommitAgendaTemplateMutationVariables>;
export const DeleteAgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteAgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneAgendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteAgendaTemplateMutation, DeleteAgendaTemplateMutationVariables>;
export const GenerateTemplateNameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GenerateTemplateName"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "generateTemplateName"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GenerateTemplateNameQuery, GenerateTemplateNameQueryVariables>;
export const GetAgendaTemplatesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetAgendaTemplates"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaTemplates"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...AgendaTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetAgendaTemplatesQuery, GetAgendaTemplatesQueryVariables>;
export const UpdateOneAgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOneAgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AgendaTemplateUpdateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneAgendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateOneAgendaTemplateMutation, UpdateOneAgendaTemplateMutationVariables>;
export const CreateOneAgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateOneAgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AgendaTemplateCreateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneAgendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateOneAgendaTemplateMutation, CreateOneAgendaTemplateMutationVariables>;
export const WatchdogAgendaTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "WatchdogAgendaTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "agendaTemplateId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "watchdogAgendaTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "agendaTemplateId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "agendaTemplateId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "agendaTemplate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaTemplateBasicInfo"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "deletedAgendaTemplate"}},
                                {kind: "Field", name: {kind: "Name", value: "deletedAgendaItemIds"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "upsertAgendaItems"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...AgendaTemplateBasicInfoFragmentDoc.definitions,
        ...FullAgendaItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WatchdogAgendaTemplateSubscription, WatchdogAgendaTemplateSubscriptionVariables>;
export const GetSyncedEmailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSyncedEmails"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "GoogleSyncedEmail"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getMicrosoftSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MicrosoftSyncedEmail"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getAppleSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AppleSyncedEmail"}}],
                        },
                    },
                ],
            },
        },
        ...GoogleSyncedEmailFragmentDoc.definitions,
        ...MicrosoftSyncedEmailFragmentDoc.definitions,
        ...AppleSyncedEmailFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSyncedEmailsQuery, GetSyncedEmailsQueryVariables>;
export const GetAllSyncedAccountsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetAllSyncedAccounts"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getGoogleSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "GoogleSyncedEmail"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getMicrosoftSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MicrosoftSyncedEmail"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getAppleSyncedEmails"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AppleSyncedEmail"}}],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "isNotionSynced"}},
                    {kind: "Field", name: {kind: "Name", value: "isEvernoteSynced"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getNotionParentPages"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "NotionParentPage"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "zapierKey"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "tokenPreview"}},
                            ],
                        },
                    },
                    {kind: "Field", name: {kind: "Name", value: "isHubspotSynced"}},
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getStripeSyncedAccount"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "StripeSyncedAccount"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getPayPalSyncedAccount"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PayPalSyncedAccount"}}],
                        },
                    },
                ],
            },
        },
        ...GoogleSyncedEmailFragmentDoc.definitions,
        ...MicrosoftSyncedEmailFragmentDoc.definitions,
        ...AppleSyncedEmailFragmentDoc.definitions,
        ...NotionParentPageFragmentDoc.definitions,
        ...StripeSyncedAccountFragmentDoc.definitions,
        ...PayPalSyncedAccountFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetAllSyncedAccountsQuery, GetAllSyncedAccountsQueryVariables>;
export const UpdateAppleExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateAppleExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "providerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "syncCalendarId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateAppleExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "providerId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "syncCalendarId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "syncCalendarId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateAppleExternalProviderMutation, UpdateAppleExternalProviderMutationVariables>;
export const UpdateExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "providerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "syncCalendarId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "providerId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "syncCalendarId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "syncCalendarId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateExternalProviderMutation, UpdateExternalProviderMutationVariables>;
export const DeleteUserGoogleProvidersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteUserGoogleProviders"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "provider"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteUserGoogleProviders"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "email"},
                                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "provider"},
                                value: {kind: "Variable", name: {kind: "Name", value: "provider"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteUserGoogleProvidersMutation, DeleteUserGoogleProvidersMutationVariables>;
export const SaveGoogleTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "saveGoogleToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "redirectUri"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveGoogleToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "redirectUri"},
                                value: {kind: "Variable", name: {kind: "Name", value: "redirectUri"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "email"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveGoogleTokenMutation, SaveGoogleTokenMutationVariables>;
export const SaveNotionTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveNotionToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveNotionToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveNotionTokenMutation, SaveNotionTokenMutationVariables>;
export const SaveNotionNoteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveNotionNote"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "title"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "note"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveNotionNote"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "title"},
                                value: {kind: "Variable", name: {kind: "Name", value: "title"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "note"},
                                value: {kind: "Variable", name: {kind: "Name", value: "note"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveNotionNoteMutation, SaveNotionNoteMutationVariables>;
export const SetNotionDefaultParentPageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetNotionDefaultParentPage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "parentPageId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setNotionDefaultParentPage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "parentPageId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "parentPageId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetNotionDefaultParentPageMutation, SetNotionDefaultParentPageMutationVariables>;
export const DeleteUserNotionProvidersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteUserNotionProviders"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "deleteUserNotionProviders"}}]},
        },
    ],
} as unknown as DocumentNode<DeleteUserNotionProvidersMutation, DeleteUserNotionProvidersMutationVariables>;
export const GetEvernoteAuthUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetEvernoteAuthUrl"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getEvernoteAuthUrl"}}]},
        },
    ],
} as unknown as DocumentNode<GetEvernoteAuthUrlQuery, GetEvernoteAuthUrlQueryVariables>;
export const SaveEvernoteTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "saveEvernoteToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveEvernoteToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveEvernoteTokenMutation, SaveEvernoteTokenMutationVariables>;
export const SaveEvernoteNoteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveEvernoteNote"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "title"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "noteJson"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveEvernoteNote"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "title"},
                                value: {kind: "Variable", name: {kind: "Name", value: "title"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "noteJson"},
                                value: {kind: "Variable", name: {kind: "Name", value: "noteJson"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveEvernoteNoteMutation, SaveEvernoteNoteMutationVariables>;
export const DeleteUserEvernoteProvidersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteUserEvernoteProviders"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "deleteUserEvernoteProviders"}}]},
        },
    ],
} as unknown as DocumentNode<DeleteUserEvernoteProvidersMutation, DeleteUserEvernoteProvidersMutationVariables>;
export const SaveMicrosoftTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "saveMicrosoftToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "scopes"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveMicrosoftToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "scopes"},
                                value: {kind: "Variable", name: {kind: "Name", value: "scopes"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "email"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveMicrosoftTokenMutation, SaveMicrosoftTokenMutationVariables>;
export const GetHubspotDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetHubspotDetails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getHubspotDetails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "email"},
                                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "token"},
                                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "status"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetHubspotDetailsQuery, GetHubspotDetailsQueryVariables>;
export const GetHubspotAuthUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetHubspotAuthUrl"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getHubspotAuthUrl"}}]},
        },
    ],
} as unknown as DocumentNode<GetHubspotAuthUrlQuery, GetHubspotAuthUrlQueryVariables>;
export const SaveHubspotTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveHubspotToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveHubspotToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveHubspotTokenMutation, SaveHubspotTokenMutationVariables>;
export const DeleteUserHubspotProvidersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteUserHubspotProviders"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "deleteUserHubspotProviders"}}]},
        },
    ],
} as unknown as DocumentNode<DeleteUserHubspotProvidersMutation, DeleteUserHubspotProvidersMutationVariables>;
export const GetStripeOAuthUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetStripeOAuthUrl"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getStripeOAuthUrl"}}]},
        },
    ],
} as unknown as DocumentNode<GetStripeOAuthUrlQuery, GetStripeOAuthUrlQueryVariables>;
export const SaveStripeTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveStripeToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "saveStripeToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveStripeTokenMutation, SaveStripeTokenMutationVariables>;
export const DeleteStripeProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteStripeProvider"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "deleteStripeAccount"}}]},
        },
    ],
} as unknown as DocumentNode<DeleteStripeProviderMutation, DeleteStripeProviderMutationVariables>;
export const GetPayPalIntegrationUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetPayPalIntegrationUrl"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getPaypalIntegrationUrl"}}]},
        },
    ],
} as unknown as DocumentNode<GetPayPalIntegrationUrlQuery, GetPayPalIntegrationUrlQueryVariables>;
export const SavePaypalAccountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SavePaypalAccount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "merchantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "permissionsGranted"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "accountStatus"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "consentStatus"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "productIntentId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isEmailConfirmed"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "riskStatus"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "savePaypalAccount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "merchantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "merchantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "permissionsGranted"},
                                value: {kind: "Variable", name: {kind: "Name", value: "permissionsGranted"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "accountStatus"},
                                value: {kind: "Variable", name: {kind: "Name", value: "accountStatus"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "consentStatus"},
                                value: {kind: "Variable", name: {kind: "Name", value: "consentStatus"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "productIntentId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "productIntentId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isEmailConfirmed"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isEmailConfirmed"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "riskStatus"},
                                value: {kind: "Variable", name: {kind: "Name", value: "riskStatus"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SavePaypalAccountMutation, SavePaypalAccountMutationVariables>;
export const DeletePayPalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeletePayPalProvider"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "deletePaypalAccount"}}]},
        },
    ],
} as unknown as DocumentNode<DeletePayPalProviderMutation, DeletePayPalProviderMutationVariables>;
export const GetFeedbackFormsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetFeedbackForms"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userFeedbackForms"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                        },
                    },
                ],
            },
        },
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetFeedbackFormsQuery, GetFeedbackFormsQueryVariables>;
export const GetFeedbackFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetFeedbackForm"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "feedbackForm"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "feedbackFormId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                        },
                    },
                ],
            },
        },
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetFeedbackFormQuery, GetFeedbackFormQueryVariables>;
export const GetFeedbackFormsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetFeedbackFormsCount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "feedbackFormCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "feedbackFormId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "settingsWithForm"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "list"}},
                                            {kind: "Field", name: {kind: "Name", value: "count"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "sessionsWithForm"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "list"}},
                                            {kind: "Field", name: {kind: "Name", value: "count"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "bookingsWithForm"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "list"}},
                                            {kind: "Field", name: {kind: "Name", value: "count"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "eventsWithForm"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "list"}},
                                            {kind: "Field", name: {kind: "Name", value: "count"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetFeedbackFormsCountQuery, GetFeedbackFormsCountQueryVariables>;
export const CreateFeedbackFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateFeedbackForm"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "feedbackForm"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "FeedbackFormInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createFeedbackForm"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "feedbackForm"},
                                value: {kind: "Variable", name: {kind: "Name", value: "feedbackForm"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                        },
                    },
                ],
            },
        },
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateFeedbackFormMutation, CreateFeedbackFormMutationVariables>;
export const DeleteFeedbackFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteFeedbackForm"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteFeedbackForm"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "feedbackFormId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                        },
                    },
                ],
            },
        },
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<DeleteFeedbackFormMutation, DeleteFeedbackFormMutationVariables>;
export const UpdateFeedbackFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateFeedbackForm"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "feedbackForm"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "FeedbackFormInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateFeedbackForm"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "feedbackFormId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "feedbackFormId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "feedbackForm"},
                                value: {kind: "Variable", name: {kind: "Name", value: "feedbackForm"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FeedbackForm"}}],
                        },
                    },
                ],
            },
        },
        ...FeedbackFormFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateFeedbackFormMutation, UpdateFeedbackFormMutationVariables>;
export const UpdateFeedbackFormAnswersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateFeedbackFormAnswers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "answers"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateFeedbackFormAnswers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "answers"},
                                value: {kind: "Variable", name: {kind: "Name", value: "answers"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneSession"}}],
                        },
                    },
                ],
            },
        },
        ...OneSessionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateFeedbackFormAnswersMutation, UpdateFeedbackFormAnswersMutationVariables>;
export const MyProductToolsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "MyProductTools"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionIdForOwnerTimezone"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "myProductTools"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ProductTool"}}],
                        },
                    },
                ],
            },
        },
        ...ProductToolFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MyProductToolsQuery, MyProductToolsQueryVariables>;
export const GetMyProductToolIconUploadUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "GetMyProductToolIconUploadUrl"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "myProductToolId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getMyProductToolIconUploadUrl"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "photoSuffix"},
                                value: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "myProductToolId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "myProductToolId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetMyProductToolIconUploadUrlMutation, GetMyProductToolIconUploadUrlMutationVariables>;
export const ConfirmMyProductToolIconUploadDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ConfirmMyProductToolIconUpload"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "myProductToolId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionIdForOwnerTimezone"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "confirmMyProductToolIconUpload"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "photoSuffix"},
                                value: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "myProductToolId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "myProductToolId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ProductTool"}}],
                        },
                    },
                ],
            },
        },
        ...ProductToolFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ConfirmMyProductToolIconUploadMutation, ConfirmMyProductToolIconUploadMutationVariables>;
export const UpsertOneMyProductToolDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertOneMyProductTool"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "update"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "MyProductToolUpdateInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "create"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "MyProductToolCreateInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionIdForOwnerTimezone"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertOneMyProductTool"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "update"},
                                value: {kind: "Variable", name: {kind: "Name", value: "update"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "create"},
                                value: {kind: "Variable", name: {kind: "Name", value: "create"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ProductTool"}}],
                        },
                    },
                ],
            },
        },
        ...ProductToolFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertOneMyProductToolMutation, UpsertOneMyProductToolMutationVariables>;
export const DeleteOneMyProductToolDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOneMyProductTool"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneMyProductTool"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneMyProductToolMutation, DeleteOneMyProductToolMutationVariables>;
export const BrandingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "Branding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "BrandingWhereInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "branding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}}],
                        },
                    },
                ],
            },
        },
        ...BrandingInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<BrandingQuery, BrandingQueryVariables>;
export const SessionBrandingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SessionBranding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionBranding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}}],
                        },
                    },
                ],
            },
        },
        ...BrandingInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionBrandingQuery, SessionBrandingQueryVariables>;
export const SessionBrandingFromTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SessionBrandingFromToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {
                        kind: "NonNullType",
                        type: {kind: "NamedType", name: {kind: "Name", value: "SessionBrandingFromTokenWhereInput"}},
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionBrandingFromToken"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}}],
                        },
                    },
                ],
            },
        },
        ...BrandingInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SessionBrandingFromTokenQuery, SessionBrandingFromTokenQueryVariables>;
export const CreateOneBrandingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateOneBranding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "BrandingCreateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneBranding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}}],
                        },
                    },
                ],
            },
        },
        ...BrandingInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateOneBrandingMutation, CreateOneBrandingMutationVariables>;
export const DeleteOneBrandingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOneBranding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneBranding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneBrandingMutation, DeleteOneBrandingMutationVariables>;
export const UploadBrandingAssetDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UploadBrandingAsset"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadBrandingAsset"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "uploadUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "publicUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadBrandingAssetMutation, UploadBrandingAssetMutationVariables>;
export const ConfirmUploadBrandingLogoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ConfirmUploadBrandingLogo"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "confirmUploadBrandingLogo"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmUploadBrandingLogoMutation, ConfirmUploadBrandingLogoMutationVariables>;
export const UpdateOneBrandingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOneBranding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "BrandingUpdateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneBranding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}}],
                        },
                    },
                ],
            },
        },
        ...BrandingInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateOneBrandingMutation, UpdateOneBrandingMutationVariables>;
export const ConfigCsdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ConfigCsd"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "domain"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "configureCsd"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "domain"},
                                value: {kind: "Variable", name: {kind: "Name", value: "domain"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "fqdn"}},
                                {kind: "Field", name: {kind: "Name", value: "domain"}},
                                {kind: "Field", name: {kind: "Name", value: "root"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfigCsdMutation, ConfigCsdMutationVariables>;
export const CleanupCsdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CleanupCsd"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "cleanupCsd"}}]},
        },
    ],
} as unknown as DocumentNode<CleanupCsdMutation, CleanupCsdMutationVariables>;
export const GetUserHubspotPropertiesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUserHubspotProperties"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getUserHubspotProperties"}}]},
        },
    ],
} as unknown as DocumentNode<GetUserHubspotPropertiesQuery, GetUserHubspotPropertiesQueryVariables>;
export const GetUserHubspotEventsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUserHubspotEvents"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getUserHubspotEvents"}}]},
        },
    ],
} as unknown as DocumentNode<GetUserHubspotEventsQuery, GetUserHubspotEventsQueryVariables>;
export const MapUserHubspotPropertiesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "MapUserHubspotProperties"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mappings"}},
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {kind: "NamedType", name: {kind: "Name", value: "UserHubspotSessionsPropertyMapping"}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "config"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UserHubspotEventConfig"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "mapUserHubspotProperties"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "mappings"},
                                value: {kind: "Variable", name: {kind: "Name", value: "mappings"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "config"},
                                value: {kind: "Variable", name: {kind: "Name", value: "config"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MapUserHubspotPropertiesMutation, MapUserHubspotPropertiesMutationVariables>;
export const GetCountRecentlyJoinedUsersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCountRecentlyJoinedUsers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "since"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "countRecentlyJoinedUsers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "since"},
                                value: {kind: "Variable", name: {kind: "Name", value: "since"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCountRecentlyJoinedUsersQuery, GetCountRecentlyJoinedUsersQueryVariables>;
export const GetOrganizationMembersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetOrganizationMembers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "excludeSelf"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "organization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "members"},
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {kind: "Name", value: "excludeSelf"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "excludeSelf"}},
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetOrganizationMembersQuery, GetOrganizationMembersQueryVariables>;
export const GetWorkspaceMembersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetWorkspaceMembers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "excludeSelf"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspace"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "members"},
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {kind: "Name", value: "excludeSelf"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "excludeSelf"}},
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>;
export const AddOrganizationMemberDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AddOrganizationMember"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "memberToAdd"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AddMemberToOrganizationPayload"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "addMemberToOrganization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "memberToAdd"},
                                value: {kind: "Variable", name: {kind: "Name", value: "memberToAdd"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "newMember"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "duplicatedEmail"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AddOrganizationMemberMutation, AddOrganizationMemberMutationVariables>;
export const UpsertOrganizationMembersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertOrganizationMembers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "membersToUpsert"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {kind: "NamedType", name: {kind: "Name", value: "UpsertMembersOfOrganizationPayload"}},
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendInviteEmail"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertMembersOfOrganization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "membersToUpsert"},
                                value: {kind: "Variable", name: {kind: "Name", value: "membersToUpsert"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendInviteEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendInviteEmail"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "newMembers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "updatedMembers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "skippedMembersCount"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpsertOrganizationMembersMutation, UpsertOrganizationMembersMutationVariables>;
export const UpdateOrganizationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOrganization"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "OrganizationUpdateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneOrganization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "isActivated"}},
                                {kind: "Field", name: {kind: "Name", value: "companyName"}},
                                {kind: "Field", name: {kind: "Name", value: "companyWebsite"}},
                                {kind: "Field", name: {kind: "Name", value: "onboardingCompleted"}},
                                {kind: "Field", name: {kind: "Name", value: "processingSubscription"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const EditOrganizationMembersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "EditOrganizationMembers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "membersToEdit"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {kind: "NamedType", name: {kind: "Name", value: "EditOrganizationMembersPayload"}},
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "editOrganizationMembers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "membersToEdit"},
                                value: {kind: "Variable", name: {kind: "Name", value: "membersToEdit"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "updatedMembers"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "errorMessage"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<EditOrganizationMembersMutation, EditOrganizationMembersMutationVariables>;
export const DeleteOrganizationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOrganization"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOrganization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const SendInviteEmailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendInviteEmails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendInviteEmails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}]},
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SendInviteEmailsMutation, SendInviteEmailsMutationVariables>;
export const SuspendOrReactivateMembersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SuspendOrReactivateMembers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "suspend"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "suspendOrReactivateMembers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "suspend"},
                                value: {kind: "Variable", name: {kind: "Name", value: "suspend"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                                {kind: "Field", name: {kind: "Name", value: "suspended"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SuspendOrReactivateMembersMutation, SuspendOrReactivateMembersMutationVariables>;
export const SetMemberWorkspaceRoleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetMemberWorkspaceRole"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "newRoleId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setMemberWorkspaceRole"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "newRoleId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "newRoleId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "userId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "userId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Member"}}]},
                    },
                ],
            },
        },
        ...MemberFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SetMemberWorkspaceRoleMutation, SetMemberWorkspaceRoleMutationVariables>;
export const GetOffersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetOffers"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userOffers"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetOffersQuery, GetOffersQueryVariables>;
export const GetSessionOffersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSessionOffers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionOffers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionOffersQuery, GetSessionOffersQueryVariables>;
export const CreateOfferDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateOffer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offer"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "OfferInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneOffer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offer"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateOfferMutation, CreateOfferMutationVariables>;
export const DeleteOfferDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteOffer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneOffer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const UpdateOfferDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOffer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offer"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "OfferInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneOffer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offer"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateOfferMutation, UpdateOfferMutationVariables>;
export const UpdateOfferImageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOfferImage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadOfferAsset"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "uploadUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "publicUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOfferImageMutation, UpdateOfferImageMutationVariables>;
export const AddOffersInSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AddOffersInSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offersIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "addOffersInSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offersIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offersIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AddOffersInSessionMutation, AddOffersInSessionMutationVariables>;
export const RemoveOfferFromSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RemoveOfferFromSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "removeOfferFromSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<RemoveOfferFromSessionMutation, RemoveOfferFromSessionMutationVariables>;
export const OnShowOfferDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnShowOffer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onShowOffer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "offer"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "list"}},
                                {kind: "Field", name: {kind: "Name", value: "deleted"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OnShowOfferSubscription, OnShowOfferSubscriptionVariables>;
export const ShowOfferInSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ShowOfferInSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "showOffer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "offerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "offerId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Offer"}}]},
                    },
                ],
            },
        },
        ...OfferFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ShowOfferInSessionMutation, ShowOfferInSessionMutationVariables>;
export const UpdateOneUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "updateOneUser"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UserUpdateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneUser"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RemoteUser"}}],
                        },
                    },
                ],
            },
        },
        ...RemoteUserFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateOneUserMutation, UpdateOneUserMutationVariables>;
export const RemoveUserProfilePictureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "removeUserProfilePicture"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "removeUserProfilePicture"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveUserProfilePictureMutation, RemoveUserProfilePictureMutationVariables>;
export const UploadProfilePictureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "uploadProfilePicture"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadUserProfilePicture"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "photoSuffix"},
                                value: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>;
export const ConfirmUploadProfilePictureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "confirmUploadProfilePicture"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "confirmProfilePictureUpload"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "photoSuffix"},
                                value: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "profilePicture"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmUploadProfilePictureMutation, ConfirmUploadProfilePictureMutationVariables>;
export const DeleteManyUsersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteManyUsers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteManyUsers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteManyUsersMutation, DeleteManyUsersMutationVariables>;
export const GetWorkspaceSenderEmailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetWorkspaceSenderEmails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspaceSenderEmails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "customSmtpServerId"}},
                                {kind: "Field", name: {kind: "Name", value: "externalProviderId"}},
                                {kind: "Field", name: {kind: "Name", value: "workspaceSenderEmailType"}},
                                {kind: "Field", name: {kind: "Name", value: "senderEmail"}},
                                {kind: "Field", name: {kind: "Name", value: "isDefault"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetWorkspaceSenderEmailsQuery, GetWorkspaceSenderEmailsQueryVariables>;
export const UpsertDefaultEmailSenderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpsertDefaultEmailSender"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "customSmtpServerId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "externalProviderId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "upsertDefaultEmailSender"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "customSmtpServerId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "customSmtpServerId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "externalProviderId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "externalProviderId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpsertDefaultEmailSenderMutation, UpsertDefaultEmailSenderMutationVariables>;
export const DeleteDefaultEmailSenderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteDefaultEmailSender"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteDefaultEmailSender"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteDefaultEmailSenderMutation, DeleteDefaultEmailSenderMutationVariables>;
export const GetWebhooksDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetWebhooks"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "webhooks"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "userId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "userId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Webhook"}}],
                        },
                    },
                ],
            },
        },
        ...WebhookFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetWebhooksQuery, GetWebhooksQueryVariables>;
export const CreateWebhookDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateWebhook"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "headers"}},
                    type: {kind: "ListType", type: {kind: "NamedType", name: {kind: "Name", value: "WebhookHeaderInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "trigger"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WebhookTrigger"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "permissionType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WebhookPermissionType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createWebhook"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "headers"},
                                value: {kind: "Variable", name: {kind: "Name", value: "headers"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "trigger"},
                                value: {kind: "Variable", name: {kind: "Name", value: "trigger"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "permissionType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "permissionType"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Webhook"}}],
                        },
                    },
                ],
            },
        },
        ...WebhookFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateWebhookMutation, CreateWebhookMutationVariables>;
export const UpdateWebhookDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "updateWebhook"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "headers"}},
                    type: {kind: "ListType", type: {kind: "NamedType", name: {kind: "Name", value: "WebhookHeaderInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "trigger"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WebhookTrigger"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateWebhook"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "headers"},
                                value: {kind: "Variable", name: {kind: "Name", value: "headers"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "trigger"},
                                value: {kind: "Variable", name: {kind: "Name", value: "trigger"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Webhook"}}],
                        },
                    },
                ],
            },
        },
        ...WebhookFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateWebhookMutation, UpdateWebhookMutationVariables>;
export const DeleteWebhookDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteWebhook"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteWebhook"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteWebhookMutation, DeleteWebhookMutationVariables>;
export const WorkspaceRolesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "WorkspaceRoles"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspace"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "roles"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspaceRoleInfo"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...WorkspaceRoleInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WorkspaceRolesQuery, WorkspaceRolesQueryVariables>;
export const CreateOneWorkspaceRoleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "createOneWorkspaceRole"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WorkspaceRoleCreateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOneWorkspaceRole"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspaceRoleInfo"}}],
                        },
                    },
                ],
            },
        },
        ...WorkspaceRoleInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateOneWorkspaceRoleMutation, CreateOneWorkspaceRoleMutationVariables>;
export const UpdateOneWorkspaceRoleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "updateOneWorkspaceRole"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WorkspaceRoleCreateInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneWorkspaceRole"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspaceRoleInfo"}}],
                        },
                    },
                ],
            },
        },
        ...WorkspaceRoleInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateOneWorkspaceRoleMutation, UpdateOneWorkspaceRoleMutationVariables>;
export const UpdateWorkspaceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "updateWorkspace"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateWorkspace"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "slug"},
                                value: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "slug"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const DeleteWorkspaceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteWorkspace"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteWorkspace"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "success"}},
                                {kind: "Field", name: {kind: "Name", value: "errorMessage"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const DeleteOneWorkspaceRoleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "deleteOneWorkspaceRole"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "WhereIdInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteOneWorkspaceRole"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOneWorkspaceRoleMutation, DeleteOneWorkspaceRoleMutationVariables>;
export const PublicApiKeysDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "PublicApiKeys"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "publicApiKeys"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "PublicApiKeyInfo"}}],
                        },
                    },
                ],
            },
        },
        ...PublicApiKeyInfoFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<PublicApiKeysQuery, PublicApiKeysQueryVariables>;
export const CreatePublicApiKeyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreatePublicApiKey"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "role"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PublicApiKeyRole"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "integration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PublicApiIntegration"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createPublicApiKey"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "role"},
                                value: {kind: "Variable", name: {kind: "Name", value: "role"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "integration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "integration"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "token"}},
                                {kind: "Field", name: {kind: "Name", value: "role"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreatePublicApiKeyMutation, CreatePublicApiKeyMutationVariables>;
export const RevokePublicApiKeyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RevokePublicApiKey"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "revokePublicApiKey"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RevokePublicApiKeyMutation, RevokePublicApiKeyMutationVariables>;
export const RevokePublicApiKeysDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RevokePublicApiKeys"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "ids"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "revokePublicApiKeys"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "ids"},
                                value: {kind: "Variable", name: {kind: "Name", value: "ids"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RevokePublicApiKeysMutation, RevokePublicApiKeysMutationVariables>;
export const GetCelloTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCelloToken"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getCelloToken"}}]},
        },
    ],
} as unknown as DocumentNode<GetCelloTokenQuery, GetCelloTokenQueryVariables>;
export const GetRemoteUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetRemoteUser"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getRemoteUser"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "user"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "RemoteUser"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "guestDoesNotExist"}},
                                {kind: "Field", name: {kind: "Name", value: "userSuspended"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "updateTs"},
                                    directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...RemoteUserFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetRemoteUserQuery, GetRemoteUserQueryVariables>;
export const RunFirstLoginEventsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RunFirstLoginEvents"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "previousSessionDetails"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PreviousSessionDetails"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "externalReferral"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "utmCookies"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UtmCookies"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "celloUcc"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "rewardfulReferral"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "lastVersionSeen"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "runFirstLoginEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "previousSessionDetails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "previousSessionDetails"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "externalReferral"},
                                value: {kind: "Variable", name: {kind: "Name", value: "externalReferral"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "utmCookies"},
                                value: {kind: "Variable", name: {kind: "Name", value: "utmCookies"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "celloUcc"},
                                value: {kind: "Variable", name: {kind: "Name", value: "celloUcc"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "rewardfulReferral"},
                                value: {kind: "Variable", name: {kind: "Name", value: "rewardfulReferral"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "lastVersionSeen"},
                                value: {kind: "Variable", name: {kind: "Name", value: "lastVersionSeen"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstLogin"}},
                                {kind: "Field", name: {kind: "Name", value: "seen"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RunFirstLoginEventsMutation, RunFirstLoginEventsMutationVariables>;
export const GetCentrifugoTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCentrifugoToken"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getCentrifugoToken"}}]},
        },
    ],
} as unknown as DocumentNode<GetCentrifugoTokenQuery, GetCentrifugoTokenQueryVariables>;
export const ExecSvcClientDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ExecSvcClient"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "operation"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "args"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "execSvc"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "operation"},
                                value: {kind: "Variable", name: {kind: "Name", value: "operation"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "args"},
                                value: {kind: "Variable", name: {kind: "Name", value: "args"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "success"}},
                                {kind: "Field", name: {kind: "Name", value: "error"}},
                                {kind: "Field", name: {kind: "Name", value: "result"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ExecSvcClientMutation, ExecSvcClientMutationVariables>;
export const GetRemoteApplicationStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetRemoteApplicationState"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getApplicationState"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AllRemoteApplicationState"}}],
                        },
                    },
                ],
            },
        },
        ...AllRemoteApplicationStateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetRemoteApplicationStateQuery, GetRemoteApplicationStateQueryVariables>;
export const ApplicationStateUpdatedDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "ApplicationStateUpdated"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "applicationStateChanges"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AllRemoteApplicationState"}}],
                        },
                    },
                ],
            },
        },
        ...AllRemoteApplicationStateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ApplicationStateUpdatedSubscription, ApplicationStateUpdatedSubscriptionVariables>;
export const MyProductToolDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "MyProductTool"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "myProductToolId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionIdForOwnerTimezone"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "myProductTool"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "myProductToolId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "myProductToolId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ProductTool"}}],
                        },
                    },
                ],
            },
        },
        ...ProductToolFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MyProductToolQuery, MyProductToolQueryVariables>;
export const GetMiroTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetMiroToken"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getMiroToken"}}]},
        },
    ],
} as unknown as DocumentNode<GetMiroTokenQuery, GetMiroTokenQueryVariables>;
export const SetSlideDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetSlide"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "slide"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "StringValue", value: "currentSlide", block: false},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "slide"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetSlideMutation, SetSlideMutationVariables>;
export const ToggleSlideshowControlsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ToggleSlideshowControls"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "StringValue", value: "navigationEnabled", block: false},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "mode"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ToggleSlideshowControlsMutation, ToggleSlideshowControlsMutationVariables>;
export const OnStartGenerateAgendaDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnStartGenerateAgenda"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeTitleAndDescription"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onStartGenerateAgenda"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "prompt"},
                                value: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeTitleAndDescription"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeTitleAndDescription"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "agendaItem"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                            {kind: "Field", name: {kind: "Name", value: "duration"}},
                                            {kind: "Field", name: {kind: "Name", value: "description"}},
                                            {kind: "Field", name: {kind: "Name", value: "order"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "titleAndDescription"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "title"}},
                                            {kind: "Field", name: {kind: "Name", value: "description"}},
                                        ],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "error"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnStartGenerateAgendaSubscription, OnStartGenerateAgendaSubscriptionVariables>;
export const OnGenerateAnythingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnGenerateAnything"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "temperature"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Float"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "subscriptionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onGenerateAnything"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "prompt"},
                                value: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "temperature"},
                                value: {kind: "Variable", name: {kind: "Name", value: "temperature"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "subscriptionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "subscriptionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "chunk"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnGenerateAnythingSubscription, OnGenerateAnythingSubscriptionVariables>;
export const OnGeneratePollDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnGeneratePoll"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onGeneratePoll"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "prompt"},
                                value: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "question"}},
                                {kind: "Field", name: {kind: "Name", value: "answers"}},
                                {kind: "Field", name: {kind: "Name", value: "error"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnGeneratePollSubscription, OnGeneratePollSubscriptionVariables>;
export const OnStartGenerateSummaryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnStartGenerateSummary"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "action"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "GenerateSummaryAction"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onStartGenerateSummary"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "action"},
                                value: {kind: "Variable", name: {kind: "Name", value: "action"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "prompt"},
                                value: {kind: "Variable", name: {kind: "Name", value: "prompt"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "chunk"}},
                                {kind: "Field", name: {kind: "Name", value: "model"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnStartGenerateSummarySubscription, OnStartGenerateSummarySubscriptionVariables>;
export const GetCsdForSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCsdForSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "csdOfSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "fqdn"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCsdForSessionQuery, GetCsdForSessionQueryVariables>;
export const GetRootUrlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetRootUrl"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "target"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getRootUrl"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "target"},
                                value: {kind: "Variable", name: {kind: "Name", value: "target"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetRootUrlQuery, GetRootUrlQueryVariables>;
export const ToggleEventPublicDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ToggleEventPublic"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isPublic"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "toggleEventPublic"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isPublic"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isPublic"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ToggleEventPublicMutation, ToggleEventPublicMutationVariables>;
export const SyncGoogleDriveDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SyncGoogleDrive"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "syncGoogleDrive"}}]},
        },
    ],
} as unknown as DocumentNode<SyncGoogleDriveMutation, SyncGoogleDriveMutationVariables>;
export const SearchUnsplashPhotosDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SearchUnsplashPhotos"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "searchQuery"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "page"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "perPage"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "searchUnsplashPhotos"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "searchQuery"},
                                value: {kind: "Variable", name: {kind: "Name", value: "searchQuery"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "page"},
                                value: {kind: "Variable", name: {kind: "Name", value: "page"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "perPage"},
                                value: {kind: "Variable", name: {kind: "Name", value: "perPage"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "photos"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "regularUrl"}},
                                            {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                            {kind: "Field", name: {kind: "Name", value: "username"}},
                                            {kind: "Field", name: {kind: "Name", value: "download_location"}},
                                        ],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "category"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchUnsplashPhotosQuery, SearchUnsplashPhotosQueryVariables>;
export const SubmitProblemDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SubmitProblem"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "screenshotUuid"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "description"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "deviceInfo"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "trace"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "session"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "category"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ReportProblemCategory"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "submitProblem"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "screenshotUuid"},
                                value: {kind: "Variable", name: {kind: "Name", value: "screenshotUuid"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "description"},
                                value: {kind: "Variable", name: {kind: "Name", value: "description"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "deviceInfo"},
                                value: {kind: "Variable", name: {kind: "Name", value: "deviceInfo"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "trace"},
                                value: {kind: "Variable", name: {kind: "Name", value: "trace"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {kind: "Variable", name: {kind: "Name", value: "session"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "category"},
                                value: {kind: "Variable", name: {kind: "Name", value: "category"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubmitProblemMutation, SubmitProblemMutationVariables>;
export const UploadProblemScreenshotDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UploadProblemScreenshot"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadProblemScreenshot"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "uuid"}},
                                {kind: "Field", name: {kind: "Name", value: "uploadUrl"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadProblemScreenshotMutation, UploadProblemScreenshotMutationVariables>;
export const GetExternalClientsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "getExternalClients"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "externalClients"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "clientId"}},
                                {kind: "Field", name: {kind: "Name", value: "clientSecret"}},
                                {kind: "Field", name: {kind: "Name", value: "iconUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "claims"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetExternalClientsQuery, GetExternalClientsQueryVariables>;
export const GetUserNamesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUserNames"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getUserNames"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "users"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                            {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                            {kind: "Field", name: {kind: "Name", value: "userProfilePicture"}},
                                            {kind: "Field", name: {kind: "Name", value: "email"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserNamesQuery, GetUserNamesQueryVariables>;
export const GetUserCountryCodeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUserCountryCode"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "getUserCountryCode"}}]},
        },
    ],
} as unknown as DocumentNode<GetUserCountryCodeQuery, GetUserCountryCodeQueryVariables>;
export const ConfirmUploadEventImageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "confirmUploadEventImage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "primary"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "confirmUploadEventImage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "primary"},
                                value: {kind: "Variable", name: {kind: "Name", value: "primary"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmUploadEventImageMutation, ConfirmUploadEventImageMutationVariables>;
export const UploadEventImageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "uploadEventImage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "primary"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadEventImage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "primary"},
                                value: {kind: "Variable", name: {kind: "Name", value: "primary"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadEventImageMutation, UploadEventImageMutationVariables>;
export const SessionEventBySlugDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SessionEventBySlug"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "slug"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "event"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "Field", name: {kind: "Name", value: "slug"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionEventBySlugQuery, SessionEventBySlugQueryVariables>;
export const UploadSessionSpeakerPhotoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "uploadSessionSpeakerPhoto"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isThumbnail"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadSessionSpeakerPhoto"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isThumbnail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isThumbnail"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadSessionSpeakerPhotoMutation, UploadSessionSpeakerPhotoMutationVariables>;
export const ConfirmUploadSessionSpeakerPhotoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "confirmUploadSessionSpeakerPhoto"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isThumbnail"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "confirmUploadSessionSpeakerPhoto"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "cacheBuster"},
                                value: {kind: "Variable", name: {kind: "Name", value: "cacheBuster"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isThumbnail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isThumbnail"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmUploadSessionSpeakerPhotoMutation, ConfirmUploadSessionSpeakerPhotoMutationVariables>;
export const GetSpeakersHistoryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSpeakersHistory"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSpeakersHistory"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SpeakerDetails"}}],
                        },
                    },
                ],
            },
        },
        ...SpeakerDetailsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSpeakersHistoryQuery, GetSpeakersHistoryQueryVariables>;
export const GetUserEventsListDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetUserEventsList"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "take"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "skip"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventFilters"}},
                    type: {
                        kind: "ListType",
                        type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionEventFilters"}}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "ownerIdsFilter"}},
                    type: {kind: "ListType", type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "orderByDate"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "orderByName"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "SortOrder"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "searchTerm"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeEventsCount"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionEvents"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "take"},
                                value: {kind: "Variable", name: {kind: "Name", value: "take"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "skip"},
                                value: {kind: "Variable", name: {kind: "Name", value: "skip"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventFilters"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventFilters"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "ownerIdsFilter"},
                                value: {kind: "Variable", name: {kind: "Name", value: "ownerIdsFilter"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderByDate"},
                                value: {kind: "Variable", name: {kind: "Name", value: "orderByDate"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderByName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "orderByName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "searchTerm"},
                                value: {kind: "Variable", name: {kind: "Name", value: "searchTerm"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneEventListItem"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionEventsCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventFilters"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventFilters"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "ownerIdsFilter"},
                                value: {kind: "Variable", name: {kind: "Name", value: "ownerIdsFilter"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "searchTerm"},
                                value: {kind: "Variable", name: {kind: "Name", value: "searchTerm"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: {kind: "Name", value: "include"},
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: {kind: "Name", value: "if"},
                                        value: {kind: "Variable", name: {kind: "Name", value: "includeEventsCount"}},
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        ...OneEventListItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetUserEventsListQuery, GetUserEventsListQueryVariables>;
export const SessionEventsOwnersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SessionEventsOwners"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionEventsOwners"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionEventsOwnersQuery, SessionEventsOwnersQueryVariables>;
export const PublishEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "PublishEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "publishEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionEventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionEvent"}}],
                        },
                    },
                ],
            },
        },
        ...SessionEventFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<PublishEventMutation, PublishEventMutationVariables>;
export const OwnerFeedbacksDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "OwnerFeedbacks"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "ownerFeedbacks"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionName"}},
                                {kind: "Field", name: {kind: "Name", value: "source"}},
                                {kind: "Field", name: {kind: "Name", value: "message"}},
                                {kind: "Field", name: {kind: "Name", value: "rating"}},
                                {kind: "Field", name: {kind: "Name", value: "technicalDetails"}},
                                {kind: "Field", name: {kind: "Name", value: "sentToProductBoard"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OwnerFeedbacksQuery, OwnerFeedbacksQueryVariables>;
export const GiveFeedbackDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "GiveFeedback"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateFeedbackInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createFeedback"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GiveFeedbackMutation, GiveFeedbackMutationVariables>;
export const GiveOwnerFeedbackDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "GiveOwnerFeedback"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateFeedbackInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createOwnerFeedback"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GiveOwnerFeedbackMutation, GiveOwnerFeedbackMutationVariables>;
export const TryJoinSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "TryJoinSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "tryJoinSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "state"}},
                                {kind: "Field", name: {kind: "Name", value: "instanceId"}},
                                {kind: "Field", name: {kind: "Name", value: "tokenNeedsRefresh"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "participant"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", alias: {kind: "Name", value: "pid"}, name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                alias: {kind: "Name", value: "type"},
                                                name: {kind: "Name", value: "__typename"},
                                            },
                                        ],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "redirectTo"}},
                                {kind: "Field", name: {kind: "Name", value: "isRoom"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TryJoinSessionMutation, TryJoinSessionMutationVariables>;
export const DeleteBookingScheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteBookingSchedule"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteBookingSchedule"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteBookingScheduleMutation, DeleteBookingScheduleMutationVariables>;
export const AnswerParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AnswerParticipants"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "hasBeenApproved"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "answerWaitingParticipants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "hasBeenApproved"},
                                value: {kind: "Variable", name: {kind: "Name", value: "hasBeenApproved"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantIds"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AnswerParticipantsMutation, AnswerParticipantsMutationVariables>;
export const StartSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "StartSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "backstage"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "startSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "backstage"},
                                value: {kind: "Variable", name: {kind: "Name", value: "backstage"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                                {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StartSessionMutation, StartSessionMutationVariables>;
export const StartSessionFromBackstageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "StartSessionFromBackstage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "startSessionFromBackstage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "session"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                                {kind: "Field", name: {kind: "Name", value: "actualStart"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StartSessionFromBackstageMutation, StartSessionFromBackstageMutationVariables>;
export const EndSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "EndSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "endSession"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EndSessionMutation, EndSessionMutationVariables>;
export const EndBackstageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "EndBackstage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "endBackstage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EndBackstageMutation, EndBackstageMutationVariables>;
export const SessionIdOfRoomDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SessionIdOfRoom"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "roomSlug"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionIdOfRoom"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "roomSlug"},
                                value: {kind: "Variable", name: {kind: "Name", value: "roomSlug"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "active"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionIdOfRoomQuery, SessionIdOfRoomQueryVariables>;
export const SendReactionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "sendReaction"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "reactionType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ReactionType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendReaction"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "reactionType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "reactionType"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendReactionMutation, SendReactionMutationVariables>;
export const OnReactionSendDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnReactionSend"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onReactionSend"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                {kind: "Field", name: {kind: "Name", value: "reactionType"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnReactionSendSubscription, OnReactionSendSubscriptionVariables>;
export const OnPushSessionViewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnPushSessionView"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onPushSessionView"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionView"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnPushSessionViewSubscription, OnPushSessionViewSubscriptionVariables>;
export const PushSessionViewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "pushSessionView"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionView"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "pushSessionView"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionView"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionView"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PushSessionViewMutation, PushSessionViewMutationVariables>;
export const RequestArtifactControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RequestArtifactControl"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "requestControl"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RequestArtifactControlMutation, RequestArtifactControlMutationVariables>;
export const SetArtifactControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetArtifactControl"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "willControlTool"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setControl"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "willControlTool"},
                                value: {kind: "Variable", name: {kind: "Name", value: "willControlTool"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetArtifactControlMutation, SetArtifactControlMutationVariables>;
export const GetCalendarFileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCalendarFile"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSessionCalendarFile"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "ics"}},
                                {kind: "Field", name: {kind: "Name", value: "google"}},
                                {kind: "Field", name: {kind: "Name", value: "yahoo"}},
                                {kind: "Field", name: {kind: "Name", value: "outlook"}},
                                {kind: "Field", name: {kind: "Name", value: "outlookOffice"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCalendarFileQuery, GetCalendarFileQueryVariables>;
export const GetCoverLinksHistoryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCoverLinksHistory"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getRemoteUser"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "user"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "lobbyPictureLinksHistory"},
                                                arguments: [
                                                    {
                                                        kind: "Argument",
                                                        name: {kind: "Name", value: "orderBy"},
                                                        value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                                {
                                                                    kind: "ObjectField",
                                                                    name: {kind: "Name", value: "createdAt"},
                                                                    value: {kind: "EnumValue", value: "desc"},
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "url"}},
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "lobbyPicturesUploadedByUserHistory"},
                                                arguments: [
                                                    {
                                                        kind: "Argument",
                                                        name: {kind: "Name", value: "orderBy"},
                                                        value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                                {
                                                                    kind: "ObjectField",
                                                                    name: {kind: "Name", value: "createdAt"},
                                                                    value: {kind: "EnumValue", value: "desc"},
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "url"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCoverLinksHistoryQuery, GetCoverLinksHistoryQueryVariables>;
export const UploadLobbyPictureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UploadLobbyPicture"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "uploadLobbyPicture"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "photoSuffix"},
                                value: {kind: "Variable", name: {kind: "Name", value: "photoSuffix"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "Field", name: {kind: "Name", value: "originalUploadUrl"}}],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadLobbyPictureMutation, UploadLobbyPictureMutationVariables>;
export const DeleteCoverHistoryImageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteCoverHistoryImage"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "pictureWasUploaded"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteCoverHistoryImage"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "pictureWasUploaded"},
                                value: {kind: "Variable", name: {kind: "Name", value: "pictureWasUploaded"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCoverHistoryImageMutation, DeleteCoverHistoryImageMutationVariables>;
export const CommitLobbyPictureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CommitLobbyPicture"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "originalPictureUrl"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "y"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Float"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "pictureWasUploaded"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "canBeRepositioned"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "unsplashDownloadLocationUrl"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isDefaultImage"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "commitLobbyPicture"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "originalPictureUrl"},
                                value: {kind: "Variable", name: {kind: "Name", value: "originalPictureUrl"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "y"},
                                value: {kind: "Variable", name: {kind: "Name", value: "y"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "pictureWasUploaded"},
                                value: {kind: "Variable", name: {kind: "Name", value: "pictureWasUploaded"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "canBeRepositioned"},
                                value: {kind: "Variable", name: {kind: "Name", value: "canBeRepositioned"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "unsplashDownloadLocationUrl"},
                                value: {kind: "Variable", name: {kind: "Name", value: "unsplashDownloadLocationUrl"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isDefaultImage"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isDefaultImage"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "originalPictureUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "pictureWasUploaded"}},
                                {kind: "Field", name: {kind: "Name", value: "y"}},
                                {kind: "Field", name: {kind: "Name", value: "canBeRepositioned"}},
                                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                {kind: "Field", name: {kind: "Name", value: "isDefaultImage"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CommitLobbyPictureMutation, CommitLobbyPictureMutationVariables>;
export const ChangeGuestDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "changeGuestDetails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changeGuestDetails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "email"},
                                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "status"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChangeGuestDetailsMutation, ChangeGuestDetailsMutationVariables>;
export const RequestPermissionToJoinDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RequestPermissionToJoin"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "requestPermissionToJoin"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "status"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RequestPermissionToJoinMutation, RequestPermissionToJoinMutationVariables>;
export const ProvideSessionPasscodeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ProvideSessionPasscode"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "passcode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "provideSessionPasscode"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "passcode"},
                                value: {kind: "Variable", name: {kind: "Name", value: "passcode"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "submittedPasscode"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProvideSessionPasscodeMutation, ProvideSessionPasscodeMutationVariables>;
export const GiveConsentToRecordDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "GiveConsentToRecord"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "giveConsentToRecord"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "consentedToRecord"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GiveConsentToRecordMutation, GiveConsentToRecordMutationVariables>;
export const GetResourceCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetResourceCount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includePdf"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includePpt"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeDoc"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeExcel"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeKeynote"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includePages"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeNumbers"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeVideo"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeGallery"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeAirtable"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeAdobexd"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeBlackboard"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeCanva"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeFigma"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeForm"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeMentimeter"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includePitch"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeSlido"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeTwitch"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeVimeo"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeYoutube"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeBoardmix"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getResourcesCount"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pptCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includePpt"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pdfCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includePdf"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "docCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeDoc"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "excelCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeExcel"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "keynoteCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeKeynote"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pagesCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includePages"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "numbersCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeNumbers"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "videoCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeVideo"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "galleryCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeGallery"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "airtableCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeAirtable"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "adobexdCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeAdobexd"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "blackboardCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeBlackboard"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "canvaCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeCanva"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "figmaCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeFigma"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "formCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeForm"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "mentimeterCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeMentimeter"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "pitchCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includePitch"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "slidoCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeSlido"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "twitchCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeTwitch"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "vimeoCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeVimeo"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "youtubeCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeYoutube"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "boardmixCount"},
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: {kind: "Name", value: "include"},
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: {kind: "Name", value: "if"},
                                                    value: {kind: "Variable", name: {kind: "Name", value: "includeBoardmix"}},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetResourceCountQuery, GetResourceCountQueryVariables>;
export const UnsubscribePreferencesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UnsubscribePreferences"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getUnsubscribePreference"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "token"},
                                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "sendAppEmails"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UnsubscribePreferencesQuery, UnsubscribePreferencesQueryVariables>;
export const UpdateUnsubscribePreferenceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateUnsubscribePreference"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendAppEmails"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateUnsubscribePreference"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "token"},
                                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendAppEmails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendAppEmails"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "sendAppEmails"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUnsubscribePreferenceMutation, UpdateUnsubscribePreferenceMutationVariables>;
export const SendMarketingEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "sendMarketingEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventName"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventBody"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sendOnce"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendMarketingEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "email"},
                                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventBody"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventBody"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sendOnce"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sendOnce"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendMarketingEventMutation, SendMarketingEventMutationVariables>;
export const JoinWithPublicLinkDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "JoinWithPublicLink"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "joinWithPublicLink"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "email"},
                                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "validationError"}},
                                {kind: "Field", name: {kind: "Name", value: "fatalError"}},
                                {kind: "Field", name: {kind: "Name", value: "redirectToLogin"}},
                                {kind: "Field", name: {kind: "Name", value: "redirectTo"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "joinDetails"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "access_token"}},
                                            {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<JoinWithPublicLinkMutation, JoinWithPublicLinkMutationVariables>;
export const CheckSessionExistsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CheckSessionExists"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkSessionExists"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "exists"}},
                                {kind: "Field", name: {kind: "Name", value: "guestAuthIsValid"}},
                                {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                {kind: "Field", name: {kind: "Name", value: "askForGuestEmail"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionLifecycle"}},
                                {kind: "Field", name: {kind: "Name", value: "isParentOfRecurrence"}},
                                {kind: "Field", name: {kind: "Name", value: "eventSlug"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CheckSessionExistsMutation, CheckSessionExistsMutationVariables>;
export const JoinWithInviteLinkDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "JoinWithInviteLink"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "isAuthenticated"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "joinWithInviteLink"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "isAuthenticated"},
                                value: {kind: "Variable", name: {kind: "Name", value: "isAuthenticated"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "error"}},
                                {kind: "Field", name: {kind: "Name", value: "access_token"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "linkAlreadyUsed"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<JoinWithInviteLinkMutation, JoinWithInviteLinkMutationVariables>;
export const CheckGuestCodeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CheckGuestCode"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkGuestCode"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "outcome"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionLifecycle"}},
                                {kind: "Field", name: {kind: "Name", value: "hasMemoryAccess"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CheckGuestCodeMutation, CheckGuestCodeMutationVariables>;
export const UpdateGuestInfoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateGuestInfo"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setGuestInfo"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateGuestInfoMutation, UpdateGuestInfoMutationVariables>;
export const UpdateUserOnboardingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateUserOnboarding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "planning"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "OnboardingFieldType"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "role"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "OnboardingFieldType"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "meetingTypes"}},
                    type: {
                        kind: "ListType",
                        type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "OnboardingFieldType"}}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "termsAccepted"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateUserOnboarding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "planning"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "planning"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "role"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "role"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "meetingTypes"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "meetingTypes"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "termsAccepted"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "termsAccepted"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "onboarding"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserOnboardingMutation, UpdateUserOnboardingMutationVariables>;
export const SendAnalyticsEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendAnalyticsEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventName"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "data"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendAnalyticsEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "data"},
                                value: {kind: "Variable", name: {kind: "Name", value: "data"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendAnalyticsEventMutation, SendAnalyticsEventMutationVariables>;
export const UpdateOrganizationOnboardingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateOrganizationOnboarding"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "size"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "userOnboarding"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UserOnboardingInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationName"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "onboardingCompleted"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOrganizationOnboarding"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "size"},
                                value: {kind: "Variable", name: {kind: "Name", value: "size"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "userOnboarding"},
                                value: {kind: "Variable", name: {kind: "Name", value: "userOnboarding"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "onboardingCompleted"},
                                value: {kind: "Variable", name: {kind: "Name", value: "onboardingCompleted"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "size"}},
                                {kind: "Field", name: {kind: "Name", value: "onboarding"}},
                                {kind: "Field", name: {kind: "Name", value: "onboardingCompleted"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "plan"}},
                                {kind: "Field", name: {kind: "Name", value: "reverseTrialCancellationDate"}},
                                {kind: "Field", name: {kind: "Name", value: "subscriptionTrialUsed"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOrganizationOnboardingMutation, UpdateOrganizationOnboardingMutationVariables>;
export const ChangePasswordDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "ChangePassword"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "oldPassword"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "newPassword"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "changePassword"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "oldPassword"},
                                value: {kind: "Variable", name: {kind: "Name", value: "oldPassword"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "newPassword"},
                                value: {kind: "Variable", name: {kind: "Name", value: "newPassword"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "isSuccess"}},
                                {kind: "Field", name: {kind: "Name", value: "invalidPassword"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const GlobalNotificationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "GlobalNotifications"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "globalNotifications"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "calendar"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "deleteSessionId"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "upsertSession"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "upsertParticipants"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "id"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "status"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "isOwner"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "email"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "avatar"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "token"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "rbac"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "token"}},
                                                                ],
                                                            },
                                                        },
                                                        {kind: "Field", name: {kind: "Name", value: "deletedParticipantIds"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "entireEvent"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "FragmentSpread",
                                                                        name: {kind: "Name", value: "CalendarEventCustom"},
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "organizationUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "planEffectiveCancellationDate"}},
                                            {kind: "Field", name: {kind: "Name", value: "planNextPaymentDate"}},
                                            {kind: "Field", name: {kind: "Name", value: "subscriptionId"}},
                                            {kind: "Field", name: {kind: "Name", value: "paddleSubscriptionState"}},
                                            {kind: "Field", name: {kind: "Name", value: "reverseTrialCancellationDate"}},
                                            {kind: "Field", name: {kind: "Name", value: "reverseTrialExpired"}},
                                            {kind: "Field", name: {kind: "Name", value: "plan"}},
                                            {kind: "Field", name: {kind: "Name", value: "processingSubscription"}},
                                            {kind: "Field", name: {kind: "Name", value: "recordingActivity"}},
                                            {kind: "Field", name: {kind: "Name", value: "livestreamActivity"}},
                                            {kind: "Field", name: {kind: "Name", value: "billingPeriod"}},
                                            {kind: "Field", name: {kind: "Name", value: "referralDiscountCode"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "subscriptionModifiers"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "quantity"}},
                                                        {kind: "Field", name: {kind: "Name", value: "type"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "workspacePermissionsUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "activeWorkspace"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "role"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "FragmentSpread", name: {kind: "Name", value: "WorkspaceRoleInfo"}},
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "workspace"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "name"}},
                                                        {kind: "Field", name: {kind: "Name", value: "slug"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "branding"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "FragmentSpread", name: {kind: "Name", value: "BrandingInfo"}},
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "organizationPermissionsUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                                            {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "organization"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "FragmentSpread", name: {kind: "Name", value: "OrganizationOnUser"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "oneOrganizationPermissionUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                                            {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "userUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "marketingEmailsEnabled"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...CalendarEventCustomFragmentDoc.definitions,
        ...WorkspaceRoleInfoFragmentDoc.definitions,
        ...BrandingInfoFragmentDoc.definitions,
        ...OrganizationOnUserFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GlobalNotificationsSubscription, GlobalNotificationsSubscriptionVariables>;
export const CreteHubspotContactDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreteHubspotContact"},
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "createHubspotContact"}}]},
        },
    ],
} as unknown as DocumentNode<CreteHubspotContactMutation, CreteHubspotContactMutationVariables>;
export const UpdateArtifactPropertyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "updateArtifactProperty"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "key"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "value"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "key"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "key"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "value"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "value"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "properties"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "key"}},
                                            {kind: "Field", name: {kind: "Name", value: "value"}},
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateArtifactPropertyMutation, UpdateArtifactPropertyMutationVariables>;
export const PushDrawerStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "PushDrawerState"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "leftDrawer"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PushDrawerStateInput"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "rightDrawer"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PushDrawerStateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "pushDrawerState"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "leftDrawer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "leftDrawer"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "rightDrawer"},
                                value: {kind: "Variable", name: {kind: "Name", value: "rightDrawer"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PushDrawerStateMutation, PushDrawerStateMutationVariables>;
export const CurrentHostDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "CurrentHost"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "currentHost"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "type"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CurrentHostQuery, CurrentHostQueryVariables>;
export const SetSessionOrderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SetSessionOrder"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "order"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateSessionOrder"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "order"},
                                value: {kind: "Variable", name: {kind: "Name", value: "order"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetSessionOrderMutation, SetSessionOrderMutationVariables>;
export const UpdateSessionTagsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateSessionTags"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "tags"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionTagsInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateSessionTags"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "tags"},
                                value: {kind: "Variable", name: {kind: "Name", value: "tags"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "sessionTags"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSessionTagsMutation, UpdateSessionTagsMutationVariables>;
export const PauseAgendaItemDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "PauseAgendaItem"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "pauseAgendaItem"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PauseAgendaItemMutation, PauseAgendaItemMutationVariables>;
export const GetSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeRegistrationAnswers"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                    defaultValue: {kind: "BooleanValue", value: false},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeRegistrationAnswers"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeRegistrationAnswers"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "FragmentSpread", name: {kind: "Name", value: "OneSession"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "childOfBreakoutRooms"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OneSessionFragmentDoc.definitions,
        ...BreakoutRoomsConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetSessionQuery, GetSessionQueryVariables>;
export const LocalAgendaItemsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalAgendaItems"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "order"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalAgendaItemsQuery, LocalAgendaItemsQueryVariables>;
export const LocalAgendaItemDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalAgendaItem"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "order"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "agendaItemId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "order"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "order"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "agendaItemId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalAgendaItemQuery, LocalAgendaItemQueryVariables>;
export const LocalAgendaItemFindOneDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalAgendaItemFindOne"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "agendaItemId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "agendaItemId"}},
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                ],
            },
        },
        ...FullAgendaItemFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalAgendaItemFindOneQuery, LocalAgendaItemFindOneQueryVariables>;
export const LocalMacroArtifactsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalMacroArtifacts"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "macroArtifacts"},
                        name: {kind: "Name", value: "artifacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "macroartifactforsession_sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                        },
                    },
                ],
            },
        },
        ...OneMacroArtifactFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalMacroArtifactsQuery, LocalMacroArtifactsQueryVariables>;
export const LocalMacroArtifactDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalMacroArtifact"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "macroArtifact"},
                        name: {kind: "Name", value: "artifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                        },
                    },
                ],
            },
        },
        ...OneMacroArtifactFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalMacroArtifactQuery, LocalMacroArtifactQueryVariables>;
export const LocalArtifactDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalArtifact"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "artifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneArtifact"}}],
                        },
                    },
                ],
            },
        },
        ...OneArtifactFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalArtifactQuery, LocalArtifactQueryVariables>;
export const LocalParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalParticipants"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalParticipantsQuery, LocalParticipantsQueryVariables>;
export const LocalParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalParticipantQuery, LocalParticipantQueryVariables>;
export const LocalCurrentParticipantDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalCurrentParticipant"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "currentParticipant"},
                        name: {kind: "Name", value: "getCurrentParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CurrentParticipantData"}}],
                        },
                    },
                ],
            },
        },
        ...CurrentParticipantDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LocalCurrentParticipantQuery, LocalCurrentParticipantQueryVariables>;
export const LocalSessionOrderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalSessionOrder"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "order"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LocalSessionOrderQuery, LocalSessionOrderQueryVariables>;
export const FullSessionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "FullSession"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "includeRegistrationAnswers"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                    defaultValue: {kind: "BooleanValue", value: false},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "session"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "id"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "includeRegistrationAnswers"},
                                value: {kind: "Variable", name: {kind: "Name", value: "includeRegistrationAnswers"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "FragmentSpread", name: {kind: "Name", value: "OneSession"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "childOfBreakoutRooms"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "collaborativeSession"}},
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "macroArtifacts"},
                        name: {kind: "Name", value: "artifacts"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "macroartifactforsession_sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "agendaItems"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "orderBy"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "order"},
                                            value: {kind: "EnumValue", value: "asc"},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                        },
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {kind: "Name", value: "sessionId"},
                                            value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "currentParticipant"},
                        name: {kind: "Name", value: "getCurrentParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CurrentParticipantData"}}],
                        },
                    },
                ],
            },
        },
        ...OneSessionFragmentDoc.definitions,
        ...BreakoutRoomsConfigFragmentDoc.definitions,
        ...OneMacroArtifactFragmentDoc.definitions,
        ...FullAgendaItemFragmentDoc.definitions,
        ...SessionParticipantFragmentDoc.definitions,
        ...CurrentParticipantDataFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<FullSessionQuery, FullSessionQueryVariables>;
export const GetParticipantConferenceTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "getParticipantConferenceToken"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: {kind: "Name", value: "currentParticipant"},
                        name: {kind: "Name", value: "getCurrentParticipant"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "conferenceToken"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "lobby"}},
                                            {kind: "Field", name: {kind: "Name", value: "main"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetParticipantConferenceTokenQuery, GetParticipantConferenceTokenQueryVariables>;
export const SendSessionDiffDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "sendSessionDiff"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "diff"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Json"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "persist"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "releaseLock"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendSessionDiff"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "diff"},
                                value: {kind: "Variable", name: {kind: "Name", value: "diff"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "persist"},
                                value: {kind: "Variable", name: {kind: "Name", value: "persist"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "releaseLock"},
                                value: {kind: "Variable", name: {kind: "Name", value: "releaseLock"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendSessionDiffMutation, SendSessionDiffMutationVariables>;
export const DiscardEventChangesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "discardEventChanges"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "discardEventChanges"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DiscardEventChangesMutation, DiscardEventChangesMutationVariables>;
export const UnlockEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "unlockEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "unlockEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UnlockEventMutation, UnlockEventMutationVariables>;
export const LockEventDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "lockEvent"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "setChanged"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "lockEvent"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "setChanged"},
                                value: {kind: "Variable", name: {kind: "Name", value: "setChanged"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LockEventMutation, LockEventMutationVariables>;
export const OnSessionDiffSendDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "OnSessionDiffSend"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "onSessionDiffSend"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                {kind: "Field", name: {kind: "Name", value: "collaborativeSessionJson"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionDiffJson"}},
                                {kind: "Field", name: {kind: "Name", value: "persisted"}},
                                {kind: "Field", name: {kind: "Name", value: "discarded"}},
                                {kind: "Field", name: {kind: "Name", value: "corrupted"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnSessionDiffSendSubscription, OnSessionDiffSendSubscriptionVariables>;
export const GetParticipantsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetParticipants"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ParticipantWhereInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "participants"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionParticipant"}}],
                        },
                    },
                ],
            },
        },
        ...SessionParticipantFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetParticipantsQuery, GetParticipantsQueryVariables>;
export const LayoutContentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LayoutContent"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "content"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "isFullscreen"}},
                                {kind: "Field", name: {kind: "Name", value: "audienceMobileOpenState"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LayoutContentQuery, LayoutContentQueryVariables>;
export const LeftDrawerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LeftDrawer"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "leftDrawer"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "state"}},
                                {kind: "Field", name: {kind: "Name", value: "isDetached"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LeftDrawerQuery, LeftDrawerQueryVariables>;
export const RightDrawerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "RightDrawer"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "rightDrawer"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "state"}},
                                {kind: "Field", name: {kind: "Name", value: "isDetached"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RightDrawerQuery, RightDrawerQueryVariables>;
export const LeftDrawerInPreviewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LeftDrawerInPreview"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "leftDrawerInPreview"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "state"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LeftDrawerInPreviewQuery, LeftDrawerInPreviewQueryVariables>;
export const RightDrawerInPreviewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "RightDrawerInPreview"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "rightDrawerInPreview"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "state"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RightDrawerInPreviewQuery, RightDrawerInPreviewQueryVariables>;
export const LayoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "Layout"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "leftDrawer"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "state"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "rightDrawer"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "state"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "leftDrawerInPreview"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "state"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "rightDrawerInPreview"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "state"}}]},
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "content"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "isFullscreen"}},
                                {kind: "Field", name: {kind: "Name", value: "audienceMobileOpenState"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LayoutQuery, LayoutQueryVariables>;
export const SendSessionFlagsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendSessionFlags"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "flags"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "SessionFlags"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "setSessionFlags"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "flags"},
                                value: {kind: "Variable", name: {kind: "Name", value: "flags"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionFlagsNoDiff"}},
                                {kind: "Field", name: {kind: "Name", value: "view"}},
                            ],
                        },
                    },
                ],
            },
        },
        ...OneSessionFlagsNoDiffFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SendSessionFlagsMutation, SendSessionFlagsMutationVariables>;
export const WatchdogDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "Watchdog"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "watchdog"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "session"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneSessionNoDiff"}}],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "deletedSession"}},
                                {kind: "Field", name: {kind: "Name", value: "deletedMacroArtifactIds"}},
                                {kind: "Field", name: {kind: "Name", value: "deletedAgendaItemIds"}},
                                {kind: "Field", name: {kind: "Name", value: "deletedParticipantIds"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "resourceNames"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "resultId"}},
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                            {kind: "Field", name: {kind: "Name", value: "artifactId"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "upsertMacroArtifacts"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "OneMacroArtifact"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "upsertAgendaItems"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FullAgendaItem"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "upsertRecordings"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "SessionRecording"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "upsertParticipants"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ParticipantUpdate"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "chatUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ChatUpdate"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "newTranscript"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "sessionId"}},
                                            {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                            {kind: "Field", name: {kind: "Name", value: "sourceAudio"}},
                                            {kind: "Field", name: {kind: "Name", value: "sourceLanguage"}},
                                            {kind: "Field", name: {kind: "Name", value: "sourceTimestamp"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "content"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "text"}},
                                                        {kind: "Field", name: {kind: "Name", value: "language"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "deleteQuestion"},
                                    selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "drawerState"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "leftDrawer"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "state"}},
                                                        {kind: "Field", name: {kind: "Name", value: "isDetached"}},
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "rightDrawer"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "state"}},
                                                        {kind: "Field", name: {kind: "Name", value: "isDetached"}},
                                                        {kind: "Field", name: {kind: "Name", value: "activeComponent"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "breakoutRoomArtifact"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "breakoutRoomsConfig"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}},
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "childOfBreakoutRooms"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "BreakoutRoomsConfig"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OneSessionNoDiffFragmentDoc.definitions,
        ...OneMacroArtifactFragmentDoc.definitions,
        ...FullAgendaItemFragmentDoc.definitions,
        ...SessionRecordingFragmentDoc.definitions,
        ...ParticipantUpdateFragmentDoc.definitions,
        ...ChatUpdateFragmentDoc.definitions,
        ...BreakoutRoomsConfigFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WatchdogSubscription, WatchdogSubscriptionVariables>;
export const WatchdogPrivateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "WatchdogPrivate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "watchdogPrivate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "approvalRequestsAsOwner"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "requestForParticipantIdResolved"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "requestingParticipant"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "dataWithNullableEmail"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "avatar"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "isGuest"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "email"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "token"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "userId"}},
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "approvalRequestsResponse"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "hasBeenApproved"}},
                                            {kind: "Field", name: {kind: "Name", value: "participantId"}},
                                        ],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "participantIndividualMute"}},
                                {kind: "Field", name: {kind: "Name", value: "participantIndividualStopCamera"}},
                                {kind: "Field", name: {kind: "Name", value: "softMuteAll"}},
                                {kind: "Field", name: {kind: "Name", value: "softStopCameraAll"}},
                                {kind: "Field", name: {kind: "Name", value: "stopScreenShare"}},
                                {kind: "Field", name: {kind: "Name", value: "nameOfJoinDeniedParticipant"}},
                                {kind: "Field", name: {kind: "Name", value: "sessionEndedByLimitation"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "chatUpdate"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ChatUpdate"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...ChatUpdateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WatchdogPrivateSubscription, WatchdogPrivateSubscriptionVariables>;
export const UserUpdatesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "subscription",
            name: {kind: "Name", value: "UserUpdates"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userUpdates"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "agendaTemplates"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "AgendaTemplate"}}],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "resource"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "ResourceFull"}}],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...AgendaTemplateFragmentDoc.definitions,
        ...ResourceFullFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UserUpdatesSubscription, UserUpdatesSubscriptionVariables>;
export const LocalUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalUser"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "isAuthenticated"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                    },
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "localUser"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "email"}},
                                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                {kind: "Field", name: {kind: "Name", value: "role"}},
                                {kind: "Field", name: {kind: "Name", value: "language"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LocalUserQuery, LocalUserQueryVariables>;
export const SaveTakeawaysDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SaveTakeaways"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "properties"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {kind: "NamedType", name: {kind: "Name", value: "PropertyCreateCustomInput"}},
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateOneArtifact"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "artifactId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "artifactId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "properties"},
                                value: {kind: "Variable", name: {kind: "Name", value: "properties"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "properties"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "key"}},
                                            {kind: "Field", name: {kind: "Name", value: "value"}},
                                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveTakeawaysMutation, SaveTakeawaysMutationVariables>;
export const SendTakeawaysDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendTakeaways"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sendTakeaways"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendTakeawaysMutation, SendTakeawaysMutationVariables>;
export const LocalVersionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "LocalVersion"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "version"},
                        directives: [{kind: "Directive", name: {kind: "Name", value: "client"}}],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "isMismatch"}},
                                {kind: "Field", name: {kind: "Name", value: "chunkLoadErr"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LocalVersionQuery, LocalVersionQueryVariables>;
export const CreateWorkspaceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateWorkspace"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "branding"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "BrandingCreateInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createWorkspace"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "slug"},
                                value: {kind: "Variable", name: {kind: "Name", value: "slug"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "branding"},
                                value: {kind: "Variable", name: {kind: "Name", value: "branding"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "WorkspacePermission"}}],
                        },
                    },
                ],
            },
        },
        ...WorkspacePermissionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const SwitchWorkspaceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SwitchWorkspace"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "switchWorkspace"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "activeWorkspace"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SwitchWorkspaceMutation, SwitchWorkspaceMutationVariables>;
export const SwitchOrganizationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SwitchOrganization"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "switchOrganization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "activeOrganization"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SwitchOrganizationMutation, SwitchOrganizationMutationVariables>;
export const AcceptOrganizationInviteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AcceptOrganizationInvite"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "acceptOrganizationInvite"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AcceptOrganizationInviteMutation, AcceptOrganizationInviteMutationVariables>;
export const RemoveMembersFromOrganizationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RemoveMembersFromOrganization"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "removeMembersFromOrganization"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "emails"},
                                value: {kind: "Variable", name: {kind: "Name", value: "emails"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "organizationId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "organizationId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveMembersFromOrganizationMutation, RemoveMembersFromOrganizationMutationVariables>;
export const SessionsShallowSearchDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SessionsShallowSearch"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "term"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "pageToken"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "time"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "sessionsShallowSearch"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "term"},
                                value: {kind: "Variable", name: {kind: "Name", value: "term"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "pageToken"},
                                value: {kind: "Variable", name: {kind: "Name", value: "pageToken"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "time"},
                                value: {kind: "Variable", name: {kind: "Name", value: "time"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "sessions"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "recurrenceId"}},
                                            {kind: "Field", name: {kind: "Name", value: "name"}},
                                            {kind: "Field", name: {kind: "Name", value: "ownedBySelf"}},
                                            {kind: "Field", name: {kind: "Name", value: "plannedStart"}},
                                            {kind: "Field", name: {kind: "Name", value: "start"}},
                                            {kind: "Field", name: {kind: "Name", value: "end"}},
                                            {kind: "Field", name: {kind: "Name", value: "lifecycle"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "highlight"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "name"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "participants"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "name"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "email"}},
                                                                ],
                                                            },
                                                        },
                                                        {kind: "Field", name: {kind: "Name", value: "resourceNames"}},
                                                    ],
                                                },
                                            },
                                            {kind: "Field", name: {kind: "Name", value: "isBooking"}},
                                            {kind: "Field", name: {kind: "Name", value: "isEvent"}},
                                        ],
                                    },
                                },
                                {kind: "Field", name: {kind: "Name", value: "pageToken"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SessionsShallowSearchQuery, SessionsShallowSearchQueryVariables>;
export const CreateSessionRecordingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateSessionRecording"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "recordingId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "extraInput"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "UpdateRecordingInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createSessionRecording"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "recordingId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "recordingId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "extraInput"},
                                value: {kind: "Variable", name: {kind: "Name", value: "extraInput"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateSessionRecordingMutation, CreateSessionRecordingMutationVariables>;
export const UpdateSessionRecordingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateSessionRecording"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "recordingId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "input"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateRecordingInput"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateSessionRecording"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "recordingId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "recordingId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "input"},
                                value: {kind: "Variable", name: {kind: "Name", value: "input"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSessionRecordingMutation, UpdateSessionRecordingMutationVariables>;
export const StopSessionRecordingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "StopSessionRecording"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "stopSessionRecording"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StopSessionRecordingMutation, StopSessionRecordingMutationVariables>;
export const DeleteSessionRecordingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteSessionRecordings"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "recordingIds"}},
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteSessionRecordings"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "recordingIds"},
                                value: {kind: "Variable", name: {kind: "Name", value: "recordingIds"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSessionRecordingsMutation, DeleteSessionRecordingsMutationVariables>;
export const CustomSmtpServersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "CustomSMTPServers"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "customSmtpServers"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMTPServer"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmtpServerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CustomSmtpServersQuery, CustomSmtpServersQueryVariables>;
export const CreateCustomSmtpServerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateCustomSMTPServer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "host"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "port"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "username"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "password"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderName"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "additionalConfig"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "configType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CustomSmtpServerConfigType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createCustomSmtpServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "host"},
                                value: {kind: "Variable", name: {kind: "Name", value: "host"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "port"},
                                value: {kind: "Variable", name: {kind: "Name", value: "port"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "username"},
                                value: {kind: "Variable", name: {kind: "Name", value: "username"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "password"},
                                value: {kind: "Variable", name: {kind: "Name", value: "password"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "additionalConfig"},
                                value: {kind: "Variable", name: {kind: "Name", value: "additionalConfig"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "verified"},
                                value: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "enabled"},
                                value: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "configType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "configType"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMTPServer"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmtpServerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateCustomSmtpServerMutation, CreateCustomSmtpServerMutationVariables>;
export const CreateCustomSpfServerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateCustomSPFServer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderName"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderDomain"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "additionalConfig"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "configType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CustomSmtpServerConfigType"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createCustomSmtpSpfServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderDomain"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderDomain"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "additionalConfig"},
                                value: {kind: "Variable", name: {kind: "Name", value: "additionalConfig"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "verified"},
                                value: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "enabled"},
                                value: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "configType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "configType"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMTPServer"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmtpServerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateCustomSpfServerMutation, CreateCustomSpfServerMutationVariables>;
export const UpdateCustomSmtpServerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateCustomSMTPServer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderName"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "host"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "port"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "username"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "password"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "assignToWorkspaceId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "removeFromWorkspaceId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateCustomSmtpServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "host"},
                                value: {kind: "Variable", name: {kind: "Name", value: "host"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "port"},
                                value: {kind: "Variable", name: {kind: "Name", value: "port"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "username"},
                                value: {kind: "Variable", name: {kind: "Name", value: "username"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "password"},
                                value: {kind: "Variable", name: {kind: "Name", value: "password"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderEmail"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderEmail"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderName"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderName"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "verified"},
                                value: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "enabled"},
                                value: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "assignToWorkspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "assignToWorkspaceId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "removeFromWorkspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "removeFromWorkspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMTPServer"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmtpServerFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateCustomSmtpServerMutation, UpdateCustomSmtpServerMutationVariables>;
export const DeleteCustomSmtpServerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteCustomSMTPServer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteCustomSmtpServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCustomSmtpServerMutation, DeleteCustomSmtpServerMutationVariables>;
export const SendCustomSmtpVerificationCodeEmailDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendCustomSMTPVerificationCodeEmail"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "verifyCustomSmtpServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendCustomSmtpVerificationCodeEmailMutation, SendCustomSmtpVerificationCodeEmailMutationVariables>;
export const CheckCustomSmtpVerificationCodeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CheckCustomSMTPVerificationCode"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkCodeCustomSmtpServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CheckCustomSmtpVerificationCodeMutation, CheckCustomSmtpVerificationCodeMutationVariables>;
export const GenerateCustomSmtpSpfServerTxtDataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "GenerateCustomSmtpSpfServerTxtData"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderDomain"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "generateCustomSmtpSpfServerTxtData"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderDomain"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderDomain"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "host"}},
                                {kind: "Field", name: {kind: "Name", value: "value"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GenerateCustomSmtpSpfServerTxtDataMutation, GenerateCustomSmtpSpfServerTxtDataMutationVariables>;
export const VerifyCustomSmtpSpfServerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "VerifyCustomSmtpSpfServer"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderDomain"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "verifyCustomSmtpSpfServer"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderDomain"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderDomain"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "mailjetVerification"}},
                                {kind: "Field", name: {kind: "Name", value: "spfVerification"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<VerifyCustomSmtpSpfServerMutation, VerifyCustomSmtpSpfServerMutationVariables>;
export const CreateUploadUrlForTranscriptDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateUploadUrlForTranscript"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "mimeType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createUploadUrlForTranscript"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "mimeType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "mimeType"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "uploadUrl"}},
                                {kind: "Field", name: {kind: "Name", value: "url"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateUploadUrlForTranscriptMutation, CreateUploadUrlForTranscriptMutationVariables>;
export const CreateTranscriptDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateTranscript"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "url"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "timestamp"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Float"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "noise"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Int"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "language"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "duration"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createTranscript"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "participantId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "participantId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "url"},
                                value: {kind: "Variable", name: {kind: "Name", value: "url"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "timestamp"},
                                value: {kind: "Variable", name: {kind: "Name", value: "timestamp"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "noise"},
                                value: {kind: "Variable", name: {kind: "Name", value: "noise"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "language"},
                                value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "duration"},
                                value: {kind: "Variable", name: {kind: "Name", value: "duration"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateTranscriptMutation, CreateTranscriptMutationVariables>;
export const GetSessionUtmAnalyticsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "getSessionUtmAnalytics"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getSessionUtmAnalytics"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "sessionId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "sessionId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSessionUtmAnalyticsQuery, GetSessionUtmAnalyticsQueryVariables>;
export const GetAllCnamesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetAllCnames"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getAllCnames"},
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Cname"}}]},
                    },
                ],
            },
        },
        ...CnameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetAllCnamesQuery, GetAllCnamesQueryVariables>;
export const GetCnameInfoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetCnameInfo"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getCnameInfo"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Cname"}}]},
                    },
                ],
            },
        },
        ...CnameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<GetCnameInfoQuery, GetCnameInfoQueryVariables>;
export const CreateCnameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateCname"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "domain"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "target"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createCname"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "domain"},
                                value: {kind: "Variable", name: {kind: "Name", value: "domain"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "target"},
                                value: {kind: "Variable", name: {kind: "Name", value: "target"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Cname"}}]},
                    },
                ],
            },
        },
        ...CnameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateCnameMutation, CreateCnameMutationVariables>;
export const DeleteCnameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteCname"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteCname"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCnameMutation, DeleteCnameMutationVariables>;
export const UpdateCnameFaviconDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateCnameFavicon"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "faviconUrl"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateCnameFavicon"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "faviconUrl"},
                                value: {kind: "Variable", name: {kind: "Name", value: "faviconUrl"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Cname"}}]},
                    },
                ],
            },
        },
        ...CnameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateCnameFaviconMutation, UpdateCnameFaviconMutationVariables>;
export const AssignCnameWorkspaceIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "AssignCnameWorkspaceId"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "assignCnameWorkspaceId"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Cname"}}]},
                    },
                ],
            },
        },
        ...CnameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AssignCnameWorkspaceIdMutation, AssignCnameWorkspaceIdMutationVariables>;
export const DisconnectCnameWorkspaceIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DisconnectCnameWorkspaceId"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "disconnectCnameWorkspaceId"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "Cname"}}]},
                    },
                ],
            },
        },
        ...CnameFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<DisconnectCnameWorkspaceIdMutation, DisconnectCnameWorkspaceIdMutationVariables>;
export const SearchPaletteDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "SearchPalette"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "input"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "PaletteSearchInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "searchPalette"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "input"},
                                value: {kind: "Variable", name: {kind: "Name", value: "input"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "isPublic"}},
                                {kind: "Field", name: {kind: "Name", value: "lastUsed"}},
                                {kind: "Field", name: {kind: "Name", value: "mode"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaletteItemFile"}},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "resourceId"}},
                                            {kind: "Field", name: {kind: "Name", value: "file_type"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaletteItemTool"}},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "resourceId"}},
                                            {kind: "Field", name: {kind: "Name", value: "tool_type"}},
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {kind: "NamedType", name: {kind: "Name", value: "PaletteItemAgenda"}},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "agendaId"}},
                                            {kind: "Field", name: {kind: "Name", value: "sharedWith"}},
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchPaletteQuery, SearchPaletteQueryVariables>;
export const GetRegistrationAnswersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "getRegistrationAnswers"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "eventId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "getRegistrationAnswers"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "eventId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "eventId"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetRegistrationAnswersQuery, GetRegistrationAnswersQueryVariables>;
export const RequestDeleteAccountConfirmCodeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "RequestDeleteAccountConfirmCode"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [{kind: "Field", name: {kind: "Name", value: "requestUserDeleteConfirmationCode"}}],
            },
        },
    ],
} as unknown as DocumentNode<RequestDeleteAccountConfirmCodeMutation, RequestDeleteAccountConfirmCodeMutationVariables>;
export const DeleteAccountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteAccount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "confirmationCode"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteSelf"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "confirmationCode"},
                                value: {kind: "Variable", name: {kind: "Name", value: "confirmationCode"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const UserOrganizationsForDeleteAccountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "UserOrganizationsForDeleteAccount"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "where"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "OrganizationWhereInput"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "userOrganizations"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "where"},
                                value: {kind: "Variable", name: {kind: "Name", value: "where"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                                {kind: "Field", name: {kind: "Name", value: "plan"}},
                                {kind: "Field", name: {kind: "Name", value: "planEffectiveCancellationDate"}},
                                {kind: "Field", name: {kind: "Name", value: "planNextPaymentDate"}},
                                {kind: "Field", name: {kind: "Name", value: "paddleSubscriptionState"}},
                                {kind: "Field", name: {kind: "Name", value: "subscriptionId"}},
                                {kind: "Field", name: {kind: "Name", value: "membersCount"}},
                                {
                                    kind: "Field",
                                    name: {kind: "Name", value: "members"},
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {kind: "Field", name: {kind: "Name", value: "id"}},
                                            {kind: "Field", name: {kind: "Name", value: "email"}},
                                            {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                            {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                            {
                                                kind: "Field",
                                                name: {kind: "Name", value: "organizationPermissions"},
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                                        {kind: "Field", name: {kind: "Name", value: "inviteStatus"}},
                                                        {kind: "Field", name: {kind: "Name", value: "suspended"}},
                                                        {kind: "Field", name: {kind: "Name", value: "permissionType"}},
                                                        {
                                                            kind: "Field",
                                                            name: {kind: "Name", value: "organization"},
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {kind: "Field", name: {kind: "Name", value: "id"}},
                                                                    {kind: "Field", name: {kind: "Name", value: "name"}},
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserOrganizationsForDeleteAccountQuery, UserOrganizationsForDeleteAccountQueryVariables>;
export const MessageExternalProvidersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "MessageExternalProviders"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "messageExternalProviders"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MessageExternalProvider"}}],
                        },
                    },
                ],
            },
        },
        ...MessageExternalProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MessageExternalProvidersQuery, MessageExternalProvidersQueryVariables>;
export const WorkspaceMessageExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "WorkspaceMessageExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "workspaceMessageExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "workspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "workspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MessageExternalProvider"}}],
                        },
                    },
                ],
            },
        },
        ...MessageExternalProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<WorkspaceMessageExternalProviderQuery, WorkspaceMessageExternalProviderQueryVariables>;
export const CreateMessageExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateMessageExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "providerType"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "MessageExternalProviderType"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "messageType"}},
                    type: {
                        kind: "NonNullType",
                        type: {kind: "NamedType", name: {kind: "Name", value: "MessageExternalProviderMessageType"}},
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "clientId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "authToken"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "apiKey"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "apiSecret"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderNumber"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "options"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createMessageExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "providerType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "providerType"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "messageType"},
                                value: {kind: "Variable", name: {kind: "Name", value: "messageType"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "clientId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "clientId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "authToken"},
                                value: {kind: "Variable", name: {kind: "Name", value: "authToken"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "apiKey"},
                                value: {kind: "Variable", name: {kind: "Name", value: "apiKey"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "apiSecret"},
                                value: {kind: "Variable", name: {kind: "Name", value: "apiSecret"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderNumber"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderNumber"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "options"},
                                value: {kind: "Variable", name: {kind: "Name", value: "options"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "verified"},
                                value: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "enabled"},
                                value: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MessageExternalProvider"}}],
                        },
                    },
                ],
            },
        },
        ...MessageExternalProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateMessageExternalProviderMutation, CreateMessageExternalProviderMutationVariables>;
export const UpdateMessageExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateMessageExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "senderNumber"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "clientId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "authToken"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "apiKey"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "apiSecret"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "assignToWorkspaceId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "removeFromWorkspaceId"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateMessageExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "senderNumber"},
                                value: {kind: "Variable", name: {kind: "Name", value: "senderNumber"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "verified"},
                                value: {kind: "Variable", name: {kind: "Name", value: "verified"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "enabled"},
                                value: {kind: "Variable", name: {kind: "Name", value: "enabled"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "clientId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "clientId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "authToken"},
                                value: {kind: "Variable", name: {kind: "Name", value: "authToken"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "apiKey"},
                                value: {kind: "Variable", name: {kind: "Name", value: "apiKey"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "apiSecret"},
                                value: {kind: "Variable", name: {kind: "Name", value: "apiSecret"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "assignToWorkspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "assignToWorkspaceId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "removeFromWorkspaceId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "removeFromWorkspaceId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "MessageExternalProvider"}}],
                        },
                    },
                ],
            },
        },
        ...MessageExternalProviderFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateMessageExternalProviderMutation, UpdateMessageExternalProviderMutationVariables>;
export const SendCodeMessageExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "SendCodeMessageExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "receiverNumber"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "verifyMessageExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "receiverNumber"},
                                value: {kind: "Variable", name: {kind: "Name", value: "receiverNumber"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendCodeMessageExternalProviderMutation, SendCodeMessageExternalProviderMutationVariables>;
export const CheckCodeMessageExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CheckCodeMessageExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "code"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "checkCodeMessageExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "code"},
                                value: {kind: "Variable", name: {kind: "Name", value: "code"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CheckCodeMessageExternalProviderMutation, CheckCodeMessageExternalProviderMutationVariables>;
export const DeleteMessageExternalProviderDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteMessageExternalProvider"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteMessageExternalProvider"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteMessageExternalProviderMutation, DeleteMessageExternalProviderMutationVariables>;
export const CustomSmsMessageTemplatesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "CustomSMSMessageTemplates"},
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "customMessageTemplates"},
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMSMessageTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmsMessageTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CustomSmsMessageTemplatesQuery, CustomSmsMessageTemplatesQueryVariables>;
export const CreateCustomSmsMessageTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "CreateCustomSMSMessageTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "templateId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "body"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "config"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "disableSending"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "createCustomMessageTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "templateId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "templateId"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "body"},
                                value: {kind: "Variable", name: {kind: "Name", value: "body"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "config"},
                                value: {kind: "Variable", name: {kind: "Name", value: "config"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "disableSending"},
                                value: {kind: "Variable", name: {kind: "Name", value: "disableSending"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMSMessageTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmsMessageTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CreateCustomSmsMessageTemplateMutation, CreateCustomSmsMessageTemplateMutationVariables>;
export const UpdateCustomSmsMessageTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "UpdateCustomSMSMessageTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "body"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "config"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Json"}},
                },
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "disableSending"}},
                    type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "updateCustomMessageTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "name"},
                                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "body"},
                                value: {kind: "Variable", name: {kind: "Name", value: "body"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "config"},
                                value: {kind: "Variable", name: {kind: "Name", value: "config"}},
                            },
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "disableSending"},
                                value: {kind: "Variable", name: {kind: "Name", value: "disableSending"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "CustomSMSMessageTemplate"}}],
                        },
                    },
                ],
            },
        },
        ...CustomSmsMessageTemplateFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<UpdateCustomSmsMessageTemplateMutation, UpdateCustomSmsMessageTemplateMutationVariables>;
export const GetEntitiesUsingSmsTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {kind: "Name", value: "GetEntitiesUsingSMSTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "templateId"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "entitiesUsingCustomMessageTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "templateId"},
                                value: {kind: "Variable", name: {kind: "Name", value: "templateId"}},
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {kind: "Field", name: {kind: "Name", value: "id"}},
                                {kind: "Field", name: {kind: "Name", value: "name"}},
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEntitiesUsingSmsTemplateQuery, GetEntitiesUsingSmsTemplateQueryVariables>;
export const DeleteCustomSmsMessageTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "DeleteCustomSMSMessageTemplate"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "deleteCustomMessageTemplate"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "id"},
                                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
                            },
                        ],
                        selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCustomSmsMessageTemplateMutation, DeleteCustomSmsMessageTemplateMutationVariables>;
export const SubscribeUserToAllMarketingEmailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {kind: "Name", value: "subscribeUserToAllMarketingEmails"},
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {kind: "Variable", name: {kind: "Name", value: "subscribe"}},
                    type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}}},
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {kind: "Name", value: "subscribeUserToAllMarketingEmails"},
                        arguments: [
                            {
                                kind: "Argument",
                                name: {kind: "Name", value: "subscribe"},
                                value: {kind: "Variable", name: {kind: "Name", value: "subscribe"}},
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubscribeUserToAllMarketingEmailsMutation, SubscribeUserToAllMarketingEmailsMutationVariables>;
export type AllTypeNamesWithoutInputs =
    | "Query"
    | "StringNullableFilter"
    | "String"
    | "NestedStringNullableFilter"
    | "AccessListItem"
    | "ID"
    | "User"
    | "Int"
    | "DateTime"
    | "CustomBackground"
    | "CustomSubDomainConfig"
    | "Json"
    | "LobbyPicture"
    | "Float"
    | "Boolean"
    | "WeekDay"
    | "SortOrder"
    | "UrlHistory"
    | "UserToOrganizationPermission"
    | "UserInviteStatus"
    | "Organization"
    | "BillingPeriod"
    | "PaddleSubscriptionState"
    | "OrganizationPlan"
    | "SubscriptionModifier"
    | "SubscriptionModifierType"
    | "OrganizationPermissionType"
    | "Participant"
    | "ParticipantChangedBy"
    | "ConferenceParticipantStatus"
    | "ConferenceToken"
    | "ControlStatus"
    | "ParticipantDataWithNullableEmail"
    | "ParticipantInviteStatus"
    | "PaymentTransaction"
    | "CheckoutSessionStatus"
    | "PaymentIntendStatus"
    | "PaymentProvider"
    | "SessionTranscript"
    | "SessionTranscriptContent"
    | "Session"
    | "AgendaItem"
    | "AgendaItemSpeaker"
    | "SessionSpeaker"
    | "AgendaItemSpeakerDiff"
    | "AgendaTemplate"
    | "Artifact"
    | "ArtifactTag"
    | "BreakoutRoomsConfiguration"
    | "BreakoutRoomsParticipantMap"
    | "UntypedArtifactData"
    | "ArtifactDescriptor"
    | "ArtifactMetaDescriptor"
    | "ArtifactMetaAuthorDescriptor"
    | "CapabilityDescriptor"
    | "DependencyDescriptor"
    | "SupportedTypesDescriptor"
    | "ClientEntryDescriptor"
    | "ArtifactFeatureTag"
    | "Property"
    | "PropertyDiff"
    | "ResourceResult"
    | "Resource"
    | "ResourceProcessingStatus"
    | "ResourceResultStatus"
    | "ResourceResultDiff"
    | "ArtifactDiff"
    | "AgendaItemType"
    | "AgendaItemDiff"
    | "SessionFlagsType"
    | "CollectFeedbackType"
    | "SessionEvent"
    | "Offer"
    | "OfferDiff"
    | "PaymentConfig"
    | "BookingEvent"
    | "Booking"
    | "BookingCollaborator"
    | "BookingSchedule"
    | "BookingScheduleUsedBy"
    | "BookingCollaborationDistribution"
    | "BookingEventScheduleSource"
    | "SessionEventState"
    | "SessionEventDiff"
    | "FeedbackForm"
    | "RecurrenceData"
    | "RepeatingFrequency"
    | "RecurrenceDataDiff"
    | "SessionLifecycle"
    | "MemoryAccessType"
    | "ParticipantsMacroAccess"
    | "Poll"
    | "PollOption"
    | "PollType"
    | "PollTextAnswer"
    | "PrivateChatAccess"
    | "QuestionsContainer"
    | "Question"
    | "BoolFilter"
    | "NestedBoolFilter"
    | "SessionRecordingType"
    | "SessionRecording"
    | "SessionRecordingPermission"
    | "RecordingProcessingStatus"
    | "SessionRecordingState"
    | "Room"
    | "Workspace"
    | "Branding"
    | "WorkspaceRole"
    | "WorkspacePermission"
    | "Webhook"
    | "WebhookHeader"
    | "PublicApiIntegration"
    | "WebhookPermissionType"
    | "WebhookTrigger"
    | "RoomDiff"
    | "SessionTag"
    | "ExitPageCtaType"
    | "SessionSource"
    | "SessionTimerState"
    | "SessionDiff"
    | "SessionView"
    | "ParticipantSource"
    | "ParticipantStatus"
    | "ParticipantStatusChangedBy"
    | "UserToWorkspacePermission"
    | "PublicBookingEventAvailableSlot"
    | "BookedEvent"
    | "PublicBookingEvent"
    | "PublicBookingEventCollaborator"
    | "BookingEventContact"
    | "BookingEventContactAttendanceEnum"
    | "BookingEventContactParticipantType"
    | "BookingEventContactRegistrationAnswer"
    | "BookingEventContactSessionStatus"
    | "BookingEventsCount"
    | "StringFilter"
    | "NestedStringFilter"
    | "DateTimeNullableFilter"
    | "NestedDateTimeNullableFilter"
    | "NullableDbFilter"
    | "IsNullDbFilter"
    | "NotNullDbFilter"
    | "BoolNullableFilter"
    | "NestedBoolNullableFilter"
    | "ParticipantListRelationFilter"
    | "SessionRecordingListRelationFilter"
    | "DateTimeFilter"
    | "NestedDateTimeFilter"
    | "IntFilter"
    | "NestedIntFilter"
    | "BookingsCount"
    | "CalendarCustomEvent"
    | "EventForEvent"
    | "ExternalCalendarEvent"
    | "ExternalCalendarEventDate"
    | "CalendarProvider"
    | "InstanceOfRecurrenceForEvent"
    | "ParticipantForEvent"
    | "CalendarSettings"
    | "CalendarView"
    | "ExternalCalendarFilter"
    | "ChatGroup"
    | "ChatMessage"
    | "BookingSlugUniqueResult"
    | "RoomSlugUniqResult"
    | "ConfigOption"
    | "Module"
    | "ModuleType"
    | "ConfigOptionListRelationFilter"
    | "CustomContactGroup"
    | "ContactGroupSource"
    | "CustomContact"
    | "ContactSource"
    | "Content"
    | "AudienceMobileOpenState"
    | "Converting"
    | "ArtifactsConversionJob"
    | "CurrentHost"
    | "HostType"
    | "CustomMessageTemplate"
    | "CustomSmtpServer"
    | "CustomSmtpServerConfigType"
    | "DesignerCommitState"
    | "DesignerEditState"
    | "DesignerRestoreDraft"
    | "DesignerSessionChangedBy"
    | "DesignerSessionId"
    | "DesignerSessionLocked"
    | "DesignerState"
    | "BlockType"
    | "ChooseToolStep"
    | "RecurrenceEditType"
    | "ConfigurationStep"
    | "ConfigurationConfirmType"
    | "EntityUsingCustomMessageTemplate"
    | "ExternalClient"
    | "FeedbackFormsCount"
    | "ListAndCount"
    | "Cname"
    | "ExternalEventsAndCalendars"
    | "ExternalCalendar"
    | "AppleSyncedEmailData"
    | "ApplicationState"
    | "BookingFormAnswers"
    | "CustomEmailSignatureResult"
    | "CustomEmail"
    | "CustomEmailContent"
    | "CustomEmailContentType"
    | "GoogleFileType"
    | "GoogleSyncedEmailData"
    | "GoogleSyncedEmailGrantedPermission"
    | "HubspotContact"
    | "MicrosoftSyncedEmailData"
    | "MicrosoftSyncedEmailGrantedPermission"
    | "NotionParentPage"
    | "PayPalSyncedAccount"
    | "GetRemoteUserResult"
    | "ResourcesCount"
    | "SessionAnalytics"
    | "SessionCalendarFile"
    | "SessionsUsingResourceResult"
    | "SlidoUrlDetails"
    | "StripeSyncedAccount"
    | "TwitchUrlDetails"
    | "UnsubscribePreference"
    | "GetUserNamesResult"
    | "GetUserNameResult"
    | "WebsiteDetails"
    | "GiphyMediaType"
    | "IframeCheckUrlPayload"
    | "EvernoteSyncStatus"
    | "RoomIsEmptyResult"
    | "LeftDrawer"
    | "DrawerState"
    | "LeftDrawerInPreview"
    | "LocalUser"
    | "MessageExternalProvider"
    | "MessageExternalProviderMessageType"
    | "MessageExternalProviderType"
    | "MessageFile"
    | "MyProductTool"
    | "NewQuestionsCount"
    | "Notification"
    | "Feedback"
    | "FeedbackSource"
    | "PaddlePlan"
    | "PaddlePlanPrice"
    | "PaddleSubscriptionModifierPrice"
    | "SubscriptionDetailsPayload"
    | "PaddlePaymentDetails"
    | "PaddlePaymentInfo"
    | "PublicApiKey"
    | "PublicApiKeyRole"
    | "PublicWorkspaceInfo"
    | "RequestFeedback"
    | "ResxResourcesList"
    | "RightDrawer"
    | "RightDrawerInPreview"
    | "PaletteItemFileType"
    | "PaletteItemMode"
    | "PaletteSearchOrderBy"
    | "PaletteItemPermissionType"
    | "PaletteItemToolType"
    | "PaletteItemBase"
    | "ParticipantSearchResult"
    | "UnSplashPhotoCategories"
    | "UnSplashPhoto"
    | "SessionEventFilters"
    | "SessionIdOfRoom"
    | "ResourceNames"
    | "ResourceName"
    | "SessionSettings"
    | "SessionSettingsSource"
    | "ShallowSearchResult"
    | "SessionResult"
    | "Highlight"
    | "ParticipantHighlight"
    | "TranscriptFile"
    | "UserToOrganizationPermissionListRelationFilter"
    | "UserPaddleTransaction"
    | "SessionFilterEnum"
    | "UserSessionsResult"
    | "Version"
    | "PublicBookingEventListItem"
    | "WorkspaceSenderEmail"
    | "WorkspaceSenderEmailType"
    | "Mutation"
    | "AddMemberToOrganizationPayload"
    | "AddMemberToOrganizationResponse"
    | "PaypalPayment"
    | "ChangePasswordResult"
    | "CheckGuestCodeResult"
    | "CheckGuestCodeOutcome"
    | "CheckRegistrationResponse"
    | "CheckSessionExistsResult"
    | "PspdfKitDocumentResponse"
    | "GeneratedPublicApiKey"
    | "AfterRegistrationOffer"
    | "TranscriptUploadUrl"
    | "DefaultEmailSender"
    | "DefaultMessageSender"
    | "DeleteRoomResult"
    | "DeleteWorkspaceResult"
    | "EditOrganizationMembersPayload"
    | "EditOrganizationMembersResponse"
    | "ExecResult"
    | "CustomSmtpSpfServerTxtData"
    | "joinWithInviteLinkResult"
    | "JoinWithPublicLinkResult"
    | "SessionJoinDetails"
    | "UserHubspotEventConfig"
    | "UserHubspotSessionsPropertyMapping"
    | "RegisterToEventResponse"
    | "PreviousSessionDetails"
    | "UtmCookies"
    | "SaveGoogleTokenResponse"
    | "SaveMicrosoftTokenResponse"
    | "NotionSyncStatus"
    | "ReactionType"
    | "SessionFlags"
    | "ReportProblemCategory"
    | "SyncAppleAccountResponse"
    | "ToogleRoomResult"
    | "TryJoinSessionResult"
    | "ParticipantSessionState"
    | "UpdateMainBreakoutRoom"
    | "ContactGroupUpdateOneCustom"
    | "modifiedContacts"
    | "OnboardingFieldType"
    | "BrandingAsset"
    | "UploadAnonymousFileResult"
    | "LobbyPictureUploadUrls"
    | "OfferAsset"
    | "UpsertMembersOfOrganizationPayload"
    | "UpsertMembersOfOrganizationResponse"
    | "CustomSmtpSpfServerVerificationResponse"
    | "GoogleDocTextWrite"
    | "Subscription"
    | "GlobalNotificationsResult"
    | "CalendarNotificationResult"
    | "UpsertCalendarEvent"
    | "BookingEventUpdateEvent"
    | "StringChunkPayload"
    | "GeneratedPollsPayload"
    | "OnGoogleContactsSyncFinishedResult"
    | "OnMicrosoftContactsSyncFinishedResult"
    | "OnPushSessionViewResult"
    | "OnReactionSendResult"
    | "OnRecordingUpdated"
    | "onSessionDiffSendResult"
    | "OfferContainerUpdatePayload"
    | "GeneratedAgendaItemPayload"
    | "GeneratedAgendaItem"
    | "GeneratedTitleAndDescription"
    | "GenerateSummaryAction"
    | "PollSubscriptionOwnerPayload"
    | "PollSubscriptionParticipantPayload"
    | "QaQuestionsContainerUpdatePayload"
    | "ResxResourceUpdatePayload"
    | "ResxResultUpdatePayload"
    | "UserUpdatesPayload"
    | "SessionWatchdogUpdateResult"
    | "ChatUpdateWatchdogResult"
    | "DeleteChatFile"
    | "DeleteChatMessage"
    | "DeleteQuestion"
    | "PushDrawerStateResult"
    | "PushDrawerState"
    | "SessionParticipantUpdatesResult"
    | "ParticipantAction"
    | "ParticipantChangedByData"
    | "AgendaTemplateWatchdogResult"
    | "SessionWatchdogUpdatePrivateResult"
    | "ApprovalRequestsAsOwnerResult"
    | "WaitForApprovalInSessionResult"
    | "Any"
    | "AppleExternalProvider"
    | "BigInt"
    | "Byte"
    | "ChatFilesSubscriptionPayload"
    | "ChatMessageReplySubscriptionPayload"
    | "ParticipantSubscriptionPayload"
    | "ChatMessageSubscriptionPayload"
    | "Currency"
    | "Duration"
    | "EmailAddress"
    | "ExternalAccount"
    | "ExternalProvider"
    | "Function"
    | "GUID"
    | "GeneratePublicGuestIfNecessaryResponse"
    | "GuestLoginSessionIdResponse"
    | "NoSessionFoundResponse"
    | "GooglePermissions"
    | "HSL"
    | "HSLA"
    | "HexColorCode"
    | "IPv4"
    | "IPv6"
    | "ISBN"
    | "IntNullableFilter"
    | "NestedIntNullableFilter"
    | "JSON"
    | "JSONObject"
    | "JWT"
    | "JsonFilter"
    | "LocalDate"
    | "LocalEndTime"
    | "LocalTime"
    | "Long"
    | "MAC"
    | "MemoryFieldAnalytics"
    | "MicrosoftPermissions"
    | "NegativeFloat"
    | "NegativeInt"
    | "NonEmptyString"
    | "NonNegativeFloat"
    | "NonNegativeInt"
    | "NonPositiveFloat"
    | "NonPositiveInt"
    | "Object"
    | "ObjectID"
    | "PaletteItemAgenda"
    | "PaletteItemFile"
    | "PaletteItemTool"
    | "PhoneNumber"
    | "Port"
    | "PositiveFloat"
    | "PositiveInt"
    | "PostalCode"
    | "RGB"
    | "RGBA"
    | "ReportedProblem"
    | "SafeInt"
    | "Time"
    | "URL"
    | "USCurrency"
    | "UUID"
    | "UnsignedFloat"
    | "UnsignedInt"
    | "UtcOffset"
    | "Void";
