import {useRef, useState} from "@workhorse/api/rendering";
import AdminMicroController from "./AdminMicroController";
import AdminMicroControllerToggler from "./AdminMicroControllerToggler";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import Popover from "@ui/core/components/Popover";
import Slide from "@ui/core/components/Slide";
import classes from "@workhorse/style/CommonModules/microController.module.scss";
import designer from "@workhorse/api/designer";
import {cls} from "@ui/cdk/util/util";
import {useAgendaItems} from "@workhorse/providers/SessionDataProviders";
import {ArtifactTag} from "@generated/artifacts";
import Typography from "@ui/cdk/Typography";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import {MobileState} from "@workhorse/declarations";
import {AgendaItem, SessionData} from "@workhorse/declarations/dataTypes";
import {useTranslation} from "react-i18next";

export const configurableArtifacts: Array<ArtifactTag> = [
    "flowos/breakout",
    "flowos/canva",
    "flowos/gallery",
    "flowos/figma",
    "flowos/video",
    "flowos/blackboard",
    "flowos/form",
    "flowos/pdf",
    "flowos/ppt",
    "flowos/doc",
    "flowos/excel",
    "flowos/keynote",
    "flowos/pages",
    "flowos/numbers",
    "flowos/vimeo",
    "flowos/youtube",
];

const SlideTransition = (props) => <Slide {...props} direction="up" />;

export type MicroControllerInjectorProps = {
    session: SessionData;
    inLobby: boolean;
    agendaItemTitle?: string;
    isAssistant?: boolean;
    mobileState: MobileState;
    hasAgenda: boolean;
    handleCloseParentMenu: (event?: any, reason?: string) => void;
};

function MicroControllerInjector(props: MicroControllerInjectorProps) {
    const {t} = useTranslation();
    const {isMobile} = props.mobileState;
    const [drawerLeftState] = useDrawerLeftToggler();
    const {isAssistant, inLobby, handleCloseParentMenu} = props;

    const agendaItems = useAgendaItems();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
        handleCloseParentMenu(null, "clickAway");
    };

    const hasConfigurableArtifactsInAgenda = agendaItems
        ?.filter((a) => {
            if (a.processing) {
                return false;
            }
            if (a.title === designer.constants.DUMMY_AGENDA_ITEM_TITLE && a.order !== props.session.order) {
                return false;
            }
            return true;
        })
        .find((a) => a.artifact && a.artifact.artifactId && configurableArtifacts.includes(a.artifact.artifactId));

    const handleToggle = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    return (
        <>
            {hasConfigurableArtifactsInAgenda ? (
                <div onClick={handleToggle} className={cls(classes.microControllerButtonWrapper, !props.hasAgenda ? "p-10" : undefined)}>
                    <div className="flex flex-row gap-10">
                        <div className={cls("flex flex-col flex-center-all", classes.microControllerButton)}>
                            <div className={classes.menuItemIconContainer}>
                                <div>
                                    <AdminMicroControllerToggler
                                        ref={buttonRef}
                                        isLobby={inLobby}
                                        handleToggle={handleToggle}
                                        isActive={Boolean(anchorEl)}
                                        inDrawer={false}
                                    />
                                </div>
                            </div>
                            <Popover
                                id={"tool-popover"}
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                onClick={(e) => e.stopPropagation()}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                // TransitionComponent={isMobile ? SlideTransition : undefined}
                                classes={{
                                    paper: cls(props.hasAgenda ? classes.agendaPaper : classes.paper),
                                }}
                            >
                                {anchorEl ? (
                                    <AdminMicroController
                                        isAssistant={isAssistant}
                                        session={props.session}
                                        mobileState={props.mobileState}
                                        inLobby={inLobby}
                                        hasAgenda={props.hasAgenda}
                                    />
                                ) : null}
                            </Popover>
                        </div>
                        <Typography color="tertiary" fontWeight="bold" className={cls("pr-10", classes.microControllerButtonText)}>
                            {t("agenda.menu.agenda_items_settings") ?? "Agenda items settings"}
                        </Typography>
                    </div>
                    <ArrowRightRoundedIcon fontSize="small" />
                </div>
            ) : null}
        </>
    );
}

export default MicroControllerInjector;
