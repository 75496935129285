import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import designer, {DUMMY_AGENDA_ITEM_TITLE} from "@workhorse/api/designer";
import {useEffect, useState} from "@workhorse/api/rendering";
import {MicroControllerProps} from "@workhorse/declarations";
import {AllowRoomsNavigationConfigurationPropertyType, ALLOW_ROOMS_NAVIGATION_INITIAL_CONFIG_PROPERTY_KEY} from "../api/constants";
import classes from "./MicroController.module.scss";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {GetBreakoutConfigDocument, UpdateBreakoutConfigDocument} from "@generated/data";

export function BreakoutMicroController(props: MicroControllerProps) {
    const {t} = useTranslation();
    const {createdAt, id: artifactId} = props.currentMicro;
    const [isEditMode] = useDesignerIsEditing();
    const exists = !!createdAt;

    const {data, loading: queryLoading} = useQuery(GetBreakoutConfigDocument, {
        variables: {
            id: artifactId,
        },
        skip: !exists,
    });

    const allowedFromConfig = data?.artifact?.breakoutRoomsConfig?.allowRoomNavigation ?? true;

    const [allowed, setAllowed] = useState(allowedFromConfig);

    useEffect(() => {
        setAllowed(allowedFromConfig);
    }, [allowedFromConfig]);

    const [update, {loading: mutationLoading}] = useMutation(UpdateBreakoutConfigDocument);

    if (!exists) {
        return null;
    }

    const loading = queryLoading || mutationLoading;

    const handleClick = async () => {
        if (loading) {
            return;
        }
        setAllowed(!allowed);
        update({
            variables: {
                artifactId,
                allowRoomNavigation: !allowed,
            },
        });

        const navigationConfig: AllowRoomsNavigationConfigurationPropertyType = {
            allowRoomNavigation: !allowed,
        };

        designer.api.artifact.updateArtifact({
            artifact: {
                properties: [
                    {
                        key: ALLOW_ROOMS_NAVIGATION_INITIAL_CONFIG_PROPERTY_KEY,
                        value: JSON.stringify(navigationConfig),
                    },
                ],
            },
            id: artifactId,
        });

        if (!isEditMode) {
            designer.commit();
        }
    };

    return (
        <div className={cls(classes.root, "flex flex-col fullw")}>
            {props.hasAgenda ? (
                <div className="flex flex-row">
                    <img className="mr-10" src="/icons/breakout-enabled.svg" style={{width: 20}} />
                    <Typography>
                        {props.agendaItemTitle === DUMMY_AGENDA_ITEM_TITLE ? "Breakout" : props.agendaItemTitle ?? "Breakout"}
                    </Typography>
                </div>
            ) : null}
            <div className={cls("flex flex-row fullw", !props.hasAgenda ? "mt-0" : "mt-24")}>
                <Typography className="mr-auto" color="quaternary" variant="base">
                    {t("artifacts.breakout.micro_controller.allow_choose_rooms") ?? "Allow participants to choose rooms"}
                </Typography>
                <Switch data-id="allow-choose-rooms" checked={allowed} disabled={loading ? true : false} onClick={handleClick} />
            </div>
        </div>
    );
}
