import {HostType} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import {Fragment, useContext, useEffect, useReducer, useState} from "@workhorse/api/rendering";
import {hasNavigation} from "@workhorse/components/drawers/DrawerLeft";
import {WithClassName, WithLayoutState} from "@workhorse/declarations";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useIsLobby, useIsPreJoin} from "@workhorse/providers/SessionDataProviders";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {useDrawerLeftWidth} from "@workhorse/providers/useDrawerLeftWidth";
import {useUserInfo} from "@workhorse/providers/User";
import {useLocation} from "react-router-dom";
import NavigationHeader from "../navigation/NavigationHeader";
import classes from "./style/Header.module.scss";

type HeaderPortalProps = WithClassName & {
    withNavigation?: boolean;
};

function HeaderPortal(props: HeaderPortalProps) {
    const {isMobile} = useMobile();

    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set} = useContext(InjectorContext);
    const ids = (Object.keys(existing) as Array<keyof typeof existing>).filter((x) => x !== "classNames");
    const className = cls("flex flex11-auto flex-align-center", cls(...(existing.classNames || [])), props.className);

    useEffect(() => {
        set("inHeader", inject);
    }, []);

    return (
        <div id="app-header" className={cls(className)}>
            {isMobile && props.withNavigation ? (
                <NavigationHeader />
            ) : (
                ids.map((k) => {
                    return <Fragment key={k}>{existing[k]}</Fragment>;
                })
            )}
        </div>
    );
}

const disabledForHostTypes = [
    HostType.Book,
    HostType.BookedEvent,
    HostType.Bookings,
    HostType.ChooseOrganization,
    HostType.OrganizationInvite,
];

type AppHeaderProps = WithLayoutState;

function AppHeader({}: AppHeaderProps) {
    const [disableTransition, setDisableTransition] = useState(true);

    const {isGuest} = useUserInfo();
    const {isMobile} = useMobile();
    const [drawerLeftState] = useDrawerLeftToggler();
    const {isPreJoin} = useIsPreJoin();

    const {pathname, hash, state} = useLocation();
    const shouldRemainOpaque = pathname.includes("/templates");

    const drawerLeftWidth = useDrawerLeftWidth();
    const hostType = useHostType();

    // const isDark = localStorage.getItem("theme") === "dark";
    const isDark = false;

    const {isSessionsUIHidden, sessionsUIHeader, sessionsUIHeaderHovered, panelCooldown, hoverSessionsUIPanel, unhoverSessionsUIPanel} =
        useSessionsUIToggle();
    const {isLobby: inLobby} = useIsLobby();

    const withNavigation = hasNavigation(hostType);

    useEffect(() => {
        if (hostType !== HostType.Player) {
            setDisableTransition(false);
        } else {
            setDisableTransition(true);
        }
    }, [hostType]);

    const style =
        !withNavigation || isMobile
            ? {}
            : {
                  paddingLeft: drawerLeftWidth[drawerLeftState.state],
              };

    if (disabledForHostTypes.includes(hostType)) {
        return null;
    }

    const handleHoverOn = () => {
        if (!panelCooldown) {
            hoverSessionsUIPanel("header");
        }
    };

    const handleHoverOff = () => {
        unhoverSessionsUIPanel("header");
    };

    return (
        <header
            id="main-app-header"
            className={cls(
                "without-nav-transition",
                withNavigation || inLobby ? classes.pageHeader : classes.playerHeader,
                inLobby && classes.lobbyHeader,
                isPreJoin && classes.playerPreJoin,
                classes.disableTransition,
                isSessionsUIHidden && classes.playerHeaderSessionsUi,
                isSessionsUIHidden &&
                    (sessionsUIHeader || (sessionsUIHeaderHovered && !panelCooldown)) &&
                    classes.playerHeaderSessionsUiOpen,
                shouldRemainOpaque && classes.opaque
            )}
            style={style}
            onMouseEnter={handleHoverOn}
            onMouseLeave={handleHoverOff}
        >
            <div className={cls("fullw", withNavigation && classes.wrapper, isMobile && classes.mobile)}>
                <HeaderPortal key="header-portal-not-mobile" withNavigation={withNavigation} />
            </div>
        </header>
    );
}

export default AppHeader;
