import {useState, useCallback} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";

export interface VisibilityLocations {
    grid: boolean;
    audience: boolean;
    spotlight: boolean;
    floatingSpeaker: boolean;
}

type VisibilityMap = Record<string, VisibilityLocations | undefined>;

function createEmptyLocations(): VisibilityLocations {
    return {
        grid: false,
        audience: false,
        spotlight: false,
        floatingSpeaker: false,
    };
}

function isAnyLocationVisible(locations: VisibilityLocations) {
    return locations.audience || locations.grid || locations.spotlight || locations.floatingSpeaker;
}

function getParticipantLocations(map: VisibilityMap, participantId: string): VisibilityLocations {
    return map[participantId] ?? createEmptyLocations();
}

function createLocations(map: VisibilityMap, participantId: string, key: keyof VisibilityLocations, value: boolean): VisibilityLocations {
    return {
        ...getParticipantLocations(map, participantId),
        [key]: value,
    };
}

function didLocationChange(map: VisibilityMap, participantId: string, key: keyof VisibilityLocations, value: boolean): boolean {
    const locations = map[participantId];
    return locations == null || locations[key] !== value;
}

function updateVisibleLocation(map: VisibilityMap, participantId: string, key: keyof VisibilityLocations, value: boolean): VisibilityMap {
    if (!didLocationChange(map, participantId, key, value)) {
        return map;
    }

    return {
        ...map,
        [participantId]: createLocations(map, participantId, key, value),
    };
}

function updateParticipantsLocation(map: VisibilityMap, key: keyof VisibilityLocations, participantIds: string[]) {
    let changed = false;

    const newMap: VisibilityMap = {};
    const mergedParticipantIds = new Set([...Object.keys(map), ...participantIds]);

    for (const pid of mergedParticipantIds) {
        const previousValue = map[pid]?.[key];
        const currentValue = participantIds.includes(pid);

        if (previousValue !== currentValue) {
            continue;
        }

        changed = true;
        newMap[pid] = createLocations(map, pid, key, currentValue);
    }

    if (!changed) {
        return map;
    }

    return newMap;
}

function useParticipantsVisibilityStore() {
    const [map, setMap] = useState<VisibilityMap>({});

    const setVisibility = useCallback((location: keyof VisibilityLocations, participantId: string, value: boolean) => {
        setMap((current) => updateVisibleLocation(current, participantId, location, value));
    }, []);

    const setVisibleParticipants = useCallback((location: keyof VisibilityLocations, participantIds: string[]) => {
        setMap((current) => updateParticipantsLocation(current, location, participantIds));
    }, []);

    const getVisibility = useCallback(
        (participantId: string) => {
            const locations = getParticipantLocations(map, participantId);
            const isVisible = isAnyLocationVisible(locations);
            return {isVisible, locations};
        },
        [map]
    );

    return {
        getVisibility,
        setVisibility,
        setVisibleParticipants,
    };
}

export const [ParticipantsVisibilityProvider, useParticipantsVisibility] = createContextProvider(
    {
        name: "ParticipantsVisibility",
    },
    useParticipantsVisibilityStore
);
