import {
    BreakoutRoomInitialConfig,
    BREAKOUT_ROOMS_INITIAL_CONFIG_PROPERTY_KEY,
    InitialConfigurationPropertyType,
} from "@artifacts/breakout/api/constants";
import {BreakoutRoomsConfigFragment, OneSessionParticipantsFragment, UpdateBreakoutConfigDocument} from "@generated/data";
import designer from "@workhorse/api/designer";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {shuffleArray} from "@workhorse/util/array";
import {participantIsAssistant} from "@workhorse/api/conference2";
import {v4 as uuid} from "uuid";
import apollo from "@workhorse/api/apollo";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useIsMounted} from "@workhorse/api/isMounted";

type Participants = OneSessionParticipantsFragment["participants"];

export interface RoomInfo {
    id: string;
    name: string;
    description: string;
    participants: string[];
    created: boolean;
    updated: boolean;
    deleted: boolean;
}

function createEmptyRoom(name: string) {
    return {
        id: uuid(),
        name,
        description: "",
        participants: [],
        created: false,
        updated: false,
        deleted: false,
    };
}

function mapRoomsFromConfig(config: BreakoutRoomsConfigFragment): RoomInfo[] {
    return config.breakoutRooms
        .map((room) => {
            const participants = config.participantsMap
                .filter((item) => item.desiredRoomId === room.id)
                .map((item) => item.participantInParent.id);

            return {
                id: room.id,
                name: room.name,
                description: room.description || "",
                participants,
                created: true,
                updated: false,
                deleted: false,
            };
        })
        .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base",
            })
        );
}

function mapRoomsFromInitialConfig(config: InitialConfigurationPropertyType): RoomInfo[] {
    return config.breakoutRooms.map((room) => ({
        id: uuid(),
        name: room.name,
        description: room.description || "",
        participants: room.participantIds,
        created: false,
        updated: false,
        deleted: false,
    }));
}

function initializeStateFromConfig(config?: BreakoutRoomsConfigFragment | null, initialConfig?: string): RoomInfo[] {
    if (config != null) {
        return mapRoomsFromConfig(config);
    }

    if (initialConfig) {
        try {
            const config = JSON.parse(initialConfig);
            return mapRoomsFromInitialConfig(config);
        } catch (e) {
            //
        }
    }

    return [createEmptyRoom("Room 1"), createEmptyRoom("Room 2")];
}

export function addRooms(rooms: RoomInfo[], items: number): RoomInfo[] {
    let lastIndex = 0;
    for (const room of rooms) {
        const index = Number(room.name.replace("Room ", ""));
        if (index >= lastIndex) {
            lastIndex = index;
        }
    }

    const newRooms: RoomInfo[] = [];
    for (items; items > 0; items--) {
        const roomNr = lastIndex + 1 + newRooms.length;
        newRooms.push(createEmptyRoom("Room " + roomNr));
    }
    return [...rooms, ...newRooms];
}

function removeRooms(rooms: RoomInfo[], items: number): RoomInfo[] {
    const existing = rooms.slice(0, rooms.length - items);
    const removed = rooms
        .slice(rooms.length - items)
        .filter((room) => room.created === true)
        .map((room) => ({
            ...room,
            deleted: true,
        }));
    return [...existing, ...removed];
}

export function changeNumberOfRooms(rooms: RoomInfo[], items: number) {
    const active = rooms.filter((room) => room.deleted === false);
    const diff = items - active.length;

    if (diff > 0) {
        return addRooms(rooms, diff);
    }

    if (diff < 0) {
        return removeRooms(rooms, rooms.length - items);
    }

    return rooms;
}

export function updateRoomById(rooms: RoomInfo[], id: string, data: Partial<RoomInfo>): RoomInfo[] {
    const index = rooms.findIndex((item) => item.id === id);

    if (index === -1) {
        return rooms;
    }

    const list = rooms.slice();
    list[index] = {
        ...list[index],
        ...data,
        updated: true,
    };

    return list;
}

function filterUnassigned(rooms: RoomInfo[], participants: Participants) {
    return participants.filter((participant) => !rooms.some((room) => room.participants.includes(participant.id)));
}

function getRoomsWithFewestParticipants(rooms: RoomInfo[]): number[] {
    const map: Record<number, number[] | undefined> = {};
    let key = Number.MAX_SAFE_INTEGER;

    rooms.forEach((room, index) => {
        if (room.deleted) {
            return;
        }

        const len = room.participants.length;
        if (len < key) {
            key = len;
        }

        if (map[len] == null) {
            map[len] = [];
        }

        map[len]?.push(index);
    });

    return map[key] ?? [];
}

function autoAssignParticipants(rooms: RoomInfo[], participants: Participants): RoomInfo[] {
    if (participants.length === 0) {
        return rooms;
    }
    const participantIds = participants.filter((participant) => canBeAutoAssigned(participant)).map((participant) => participant.id);
    return autoAssignParticipantsByIds(rooms, participantIds);
}

export function autoAssignParticipantsByIds(rooms: RoomInfo[], participants: string[]): RoomInfo[] {
    if (participants.length === 0) {
        return rooms;
    }

    const list = rooms.slice();

    let roomsMap = getRoomsWithFewestParticipants(rooms);
    let mapIndex = 0;

    for (const participantId of participants) {
        if (mapIndex >= roomsMap.length) {
            mapIndex = 0;
            roomsMap = getRoomsWithFewestParticipants(list);
        }

        const index = roomsMap[mapIndex];
        const room = list[index];

        list[index] = {
            ...room,
            updated: true,
            participants: [...room.participants, participantId],
        };

        mapIndex++;
    }

    return list;
}

function shuffleAssignedParticipants(rooms: RoomInfo[]) {
    const list = rooms.slice();
    const roomIndexes: number[] = [];
    const participants: string[] = [];

    rooms.forEach((room, index) => {
        if (room.deleted) {
            return;
        }

        roomIndexes.push(index);
        participants.push(...room.participants);
    });

    shuffleArray(participants);

    for (const roomIndex of roomIndexes) {
        const room = list[roomIndex];
        list[roomIndex] = {
            ...room,
            updated: true,
            participants: [],
        };
    }

    let index = 0;
    for (const participant of participants) {
        if (index >= roomIndexes.length) {
            index = 0;
        }

        const roomIndex = roomIndexes[index];
        const room = list[roomIndex];
        list[roomIndex] = {
            ...room,
            participants: [...room.participants, participant],
        };
        index++;
    }

    return list;
}

function assignParticipantToRoom(rooms: RoomInfo[], participantId: string, roomId?: string) {
    let roomIndex = -1;

    const list = rooms.map((room, index) => {
        if (room.id === roomId) {
            roomIndex = index;
        }

        const participantIndex = room.participants.indexOf(participantId);

        if (participantIndex === -1) {
            return room;
        }

        const participants = room.participants.slice();
        participants.splice(participantIndex, 1);

        return {
            ...room,
            updated: true,
            participants,
        };
    });

    if (roomIndex === -1) {
        return list;
    }

    const room = list[roomIndex];

    list[roomIndex] = {
        ...room,
        updated: true,
        participants: [...room.participants, participantId],
    };

    return list;
}

export function unassignAllParticipants(rooms: RoomInfo[]) {
    let updated = false;
    const copy = rooms.slice();

    rooms.forEach((room, index) => {
        if (room.participants.length === 0) {
            return;
        }

        updated = true;
        copy[index] = {
            ...room,
            updated: true,
            participants: [],
        };
    });

    return updated ? copy : rooms;
}

function updateInitialData(artifactId: string, isEditMode: boolean, rooms: RoomInfo[]) {
    const breakoutRooms: BreakoutRoomInitialConfig[] = rooms
        .filter((room) => !room.deleted)
        .map((room) => ({
            id: room.id,
            name: room.name,
            description: room.description,
            participantIds: room.participants,
        }));

    const config: InitialConfigurationPropertyType = {
        breakoutRooms,
    };

    designer.api.artifact.updateArtifact({
        artifact: {
            isConfigured: true,
            properties: [
                {
                    key: BREAKOUT_ROOMS_INITIAL_CONFIG_PROPERTY_KEY,
                    value: JSON.stringify(config),
                },
            ],
            // data: {
            //     [BREAKOUT_ROOMS_INITIAL_CONFIG_PROPERTY_KEY]: JSON.stringify(config),
            // },
        },
        id: artifactId,
    });

    if (!isEditMode) {
        designer.commit();
    }
}

function createMutationPayload(artifactId: string, rooms: RoomInfo[], participants: Participants) {
    const deleteBreakoutRoomIds = rooms.filter((room) => room.created && room.deleted).map((room) => room.id);
    const upsertBreakoutRooms = rooms
        .filter((room) => !room.deleted && (room.updated || !room.created))
        .map((room) => ({
            id: room.id,
            name: room.name,
            description: room.description,
            participantIds: room.participants,
        }));

    const mainBreakoutRoom = {
        participantIds: filterUnassigned(rooms, participants).map((p) => p.id),
    };

    return {
        artifactId,
        upsertBreakoutRooms,
        deleteBreakoutRoomIds,
        mainBreakoutRoom,
    };
}

function update(id: string, rooms: RoomInfo[], participants: Participants, isEditMode: boolean, persist: boolean, artifactPersist = true) {
    if (artifactPersist) {
        updateInitialData(id, isEditMode, rooms);
        if (!persist) {
            return new Promise<void>((resolve) => resolve());
        }
    }

    if (persist) {
        return apollo.client.mutate({
            mutation: UpdateBreakoutConfigDocument,
            variables: createMutationPayload(id, rooms, participants),
        });
    }
}

export function canBeAutoAssigned(participant: Participants[0]) {
    const isAssistant = participantIsAssistant(participant);
    return !participant.isOwner && !isAssistant;
}

export type GuestMap = Record<string, number | undefined>;

export function createGuestMap(participants: OneSessionParticipantsFragment["participants"]): GuestMap {
    let guestNo = -1;
    const map: Record<string, number | undefined> = {};

    participants.forEach((participant) => {
        const hasName = !!participant.dataWithNullableEmail?.firstName || !!participant.dataWithNullableEmail?.lastName;
        if (!hasName) {
            guestNo++;
            map[participant.id] = guestNo;
        }
    });

    return map;
}

export type ParticipantInfoMap = Record<
    string,
    {id: string; desiredRoom?: string; joinedRoom?: string; joinedMainRoom: boolean} | undefined
>;

function createParticipantsInfoMap(config?: BreakoutRoomsConfigFragment | null): ParticipantInfoMap {
    const map: ParticipantInfoMap = {};

    if (config == null) {
        return map;
    }

    config.participantsMap.forEach((item) => {
        map[item.participantInParent.id] = {
            id: item.id,
            desiredRoom: item.desiredRoomId ?? undefined,
            joinedRoom: item.participantInBreakoutRoom?.sessionId,
            joinedMainRoom: item.participantInParent.status === "JOINED_SESSION",
        };
    });

    return map;
}

export function getParticipantRoomState(isMainRoom: boolean, roomId?: string, info?: ParticipantInfoMap[0]) {
    const isInRoom = isMainRoom ? info?.joinedMainRoom !== false : info?.joinedRoom === roomId;
    const desiresRoom = info?.desiredRoom == null || info.desiredRoom === roomId;
    return {isInRoom, desiresRoom};
}

export function getSortedRoomsFromConfig(rooms: BreakoutRoomsConfigFragment["breakoutRooms"] | null = null) {
    return (
        rooms?.slice().sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base",
            })
        ) ?? []
    );
}

export function useConfigApi(
    id: string,
    config: BreakoutRoomsConfigFragment | null = null,
    initialConfig: string,
    participants: Participants,
    isEditMode = false,
    realtime = false
) {
    const skipInitialRef = useRef(false);
    const skipUpdateRef = useRef(false);

    const [rooms, setRooms] = useState<RoomInfo[]>(() => {
        skipInitialRef.current = true;
        return initializeStateFromConfig(config, initialConfig);
    });

    const activeRooms = useMemo(() => rooms.filter((room) => !room.deleted), [rooms]);
    const participantsInfoMap = useMemo(() => createParticipantsInfoMap(config), [config]);

    const [actionsLocks, setActionsLocks] = useState<string[]>([]);

    const isMounted = useIsMounted();

    const apiOptions = {
        id,
        isEditMode,
        realtime,
        rooms,
        participants,
        persist: config != null,
    };
    const optionsRef = useRef(apiOptions);
    optionsRef.current = apiOptions;

    useEffect(() => {
        if (config) {
            skipUpdateRef.current = true;
            setRooms(mapRoomsFromConfig(config));
        }
    }, [config]);

    useEffect(() => {
        const options = optionsRef.current;

        if (skipInitialRef.current) {
            skipInitialRef.current = false;
            return;
        }

        if (skipUpdateRef.current) {
            skipUpdateRef.current = false;
            return;
        }

        if (!options.realtime) {
            return;
        }

        const updateArtifactInitialConfig = () => update(id, rooms, options.participants, false, true);

        update(id, rooms, options.participants, false, true, false);
        const timeoutId = setTimeout(() => updateArtifactInitialConfig(), 2000);

        return () => {
            clearTimeout(timeoutId);

            if (!isMounted()) {
                updateArtifactInitialConfig();
            }
        };
    }, [id, rooms, isMounted]);

    const persist = useCallback(() => {
        const options = optionsRef.current;
        return update(options.id, options.rooms, options.participants, options.isEditMode, options.persist);
    }, []);

    const persistWithRooms = useCallback((rooms: RoomInfo[]) => {
        const options = optionsRef.current;
        if (!options.realtime) {
            setRooms(rooms);
            return;
        }
        return update(options.id, rooms, options.participants, options.isEditMode, options.persist);
    }, []);

    const setNumberOfRooms = useCallback((items: number) => {
        setRooms((current) => changeNumberOfRooms(current, items));
    }, []);

    const updateRoom = useCallback((id: string, data: Partial<RoomInfo>) => {
        setRooms((current) => updateRoomById(current, id, data));
    }, []);

    const autoAssign = useCallback((participants: OneSessionParticipantsFragment["participants"]) => {
        setRooms((current) => autoAssignParticipants(current, filterUnassigned(current, participants)));
    }, []);

    const autoAssingIds = useCallback((participants: string[]) => {
        setRooms((current) => autoAssignParticipantsByIds(current, participants));
    }, []);

    const assignToRoom = useCallback((participantId: string, roomId?: string) => {
        setRooms((current) => assignParticipantToRoom(current, participantId, roomId));
    }, []);

    const unassignAll = useCallback(() => {
        setRooms((current) => unassignAllParticipants(current));
    }, []);

    const shuffleParticipants = useCallback(() => {
        setRooms((current) => shuffleAssignedParticipants(current));
    }, []);

    const getUnassignedParticipants = useCallback(
        (participants: OneSessionParticipantsFragment["participants"]) => filterUnassigned(rooms, participants),
        [rooms]
    );

    const addActionLock = useCallback(() => {
        const id = makeId();
        setActionsLocks((current) => [...current, id]);

        return () => {
            setActionsLocks((current) => {
                const copy = current.slice();
                const index = copy.indexOf(id);
                if (index === -1) {
                    return current;
                }
                copy.splice(index, 1);
                return copy;
            });
        };
    }, []);

    return {
        rooms: activeRooms,
        allRooms: rooms,
        actionsLocked: actionsLocks.length > 0,
        breakoutConfig: config,
        allowRoomNavigation: config?.allowRoomNavigation ?? true,
        participantsInfoMap,
        artifactId: id,
        addActionLock,
        persist,
        persistWithRooms,
        updateRoom,
        setNumberOfRooms,
        autoAssign,
        autoAssingIds,
        assignToRoom,
        unassignAll,
        shuffleParticipants,
        getUnassignedParticipants,
    };
}

export type BreakoutConfigApi = ReturnType<typeof useConfigApi>;
