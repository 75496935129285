import classes from "./styles/PreJoinConferenceControls.module.scss";
import PreJoinConferenceSettingsToggler from "./PreJoinConferenceSettingsToggler";
import PreJoinConferenceBackgroundToggler from "./PreJoinConferenceBackgroundToggler";
import OneTooltip, {OneTooltipRef} from "@ui/cdk/OneTooltip";
import {cls} from "@ui/cdk/util";
import {BackgroundFilter} from "@workhorse/api/conference2/lib/VideoReplacement";
import LocalVideoToggler from "../../components/conferenceControls/LocalVideo/LocalVideoToggler";
import LocalAudioToggler from "../../components/conferenceControls/LocalAudio/LocalAudioToggler";
import SwitchCameraButton from "../../components/conferenceControls/SwitchCameraButton";
import {useState, useMemo} from "@workhorse/api/rendering";
import {BlockedPermissionsPopover} from "../../components/BlockedPermissionsModal";
import {DeviceUnreadablePopover} from "../../components/DeviceUnreadableModal";
import browserInfo from "@workhorse/api/BrowserInfo";

type PreJoinConferenceControlsProps = {
    tooltipConnectorRef: OneTooltipRef;
    voiceFocusEnabled?: boolean;
    isVoiceFocusSupported?: boolean;
    audioInput: string;
    audioOutput: string;
    videoInput: string;
    audioInputs: MediaDeviceInfo[];
    audioOutputs: MediaDeviceInfo[];
    videoInputs: MediaDeviceInfo[];
    supportsBackgroundFilter: boolean;
    filter: BackgroundFilter;
    videoCustomFilter: string | null;
    onChangeAudioInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAudioOutput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeVideo: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onVoiceFocusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterChange: (filter: BackgroundFilter) => void;
    showTooltip: (e?: any, message?: string) => void;
    sessionId: string;
    isMobile: boolean;
    isPortrait?: boolean;
};

const PreJoinConferenceControls = (props: PreJoinConferenceControlsProps) => {
    const [microphoneBlockedElement, setMicrophoneBlockedElement] = useState<HTMLElement | null | undefined>(null);
    const [microphoneUnreadableElement, setMicrophoneUnreadableElement] = useState<HTMLElement | null | undefined>(null);
    const [cameraBlockedElement, setCameraBlockedElement] = useState<HTMLElement | null | undefined>(null);
    const [cameraUnreadableElement, setCameraUnreadableElement] = useState<HTMLElement | null | undefined>(null);

    const isMobileUA = useMemo(() => browserInfo.isMobile(), []);
    const isMobileOrTabletUA = useMemo(
        () => browserInfo.isMobile() || browserInfo.isTablet() || browserInfo.isIOS() || browserInfo.isIpadOS(),
        []
    );

    return (
        <div
            className={cls(classes.root, props.isMobile && !props.isPortrait && classes.mobileLandscape)}
            key="pre-join-screen-conference-controls"
        >
            <OneTooltip
                connector={props.tooltipConnectorRef}
                className={classes.tooltip}
                key="pre-join-screen-conference-controls-tooltip"
            />

            {isMobileUA ? (
                <li className={cls(classes.controlButton, "hidden")} />
            ) : (
                <PreJoinConferenceSettingsToggler
                    className={classes.controlButton}
                    classes={{
                        active: "active-blue",
                        disabled: "disabled",
                    }}
                    voiceFocusEnabled={props.voiceFocusEnabled}
                    isVoiceFocusSupported={props.isVoiceFocusSupported}
                    audioInput={props.audioInput}
                    audioOutput={props.audioOutput}
                    videoInput={props.videoInput}
                    audioInputs={props.audioInputs}
                    audioOutputs={props.audioOutputs}
                    videoInputs={props.videoInputs}
                    onChangeAudioInput={props.onChangeAudioInput}
                    onChangeAudioOutput={props.onChangeAudioOutput}
                    onChangeVideo={props.onChangeVideo}
                    onVoiceFocusChange={props.onVoiceFocusChange}
                    isMobile={props.isMobile}
                    key="pre-join-screen-conference-controls-settings"
                />
            )}
            <ul
                className={cls(classes.audioVideoControls, props.isMobile && !props.isPortrait && classes.mobileLandscape)}
                key="pre-join-screen-conference-controls-audio-video"
            >
                <LocalVideoToggler
                    className={classes.controlButton}
                    permissionDeniedText="No video"
                    sessionId={props.sessionId}
                    showTooltip={props.showTooltip}
                    isPreJoin
                    onPermissionBlocked={setCameraBlockedElement}
                    onDeviceUnreadable={setCameraUnreadableElement}
                />
                <LocalAudioToggler
                    className={classes.controlButton}
                    permissionDeniedText="No audio"
                    sessionId={props.sessionId}
                    showTooltip={props.showTooltip}
                    isPreJoin
                    onPermissionBlocked={setMicrophoneBlockedElement}
                    onDeviceUnreadable={setMicrophoneUnreadableElement}
                />
            </ul>

            {cameraBlockedElement && (
                <BlockedPermissionsPopover
                    anchorEl={cameraBlockedElement}
                    cameraBlocked={true}
                    onClose={() => {
                        setCameraBlockedElement(undefined);
                    }}
                />
            )}
            {cameraUnreadableElement && (
                <DeviceUnreadablePopover
                    anchorEl={cameraUnreadableElement}
                    cameraUnreadable={true}
                    onClose={() => {
                        setCameraUnreadableElement(undefined);
                    }}
                />
            )}
            {microphoneBlockedElement && (
                <BlockedPermissionsPopover
                    anchorEl={microphoneBlockedElement}
                    micBlocked={true}
                    onClose={() => {
                        setMicrophoneBlockedElement(undefined);
                    }}
                />
            )}
            {microphoneUnreadableElement && (
                <DeviceUnreadablePopover
                    anchorEl={microphoneUnreadableElement}
                    micUnreadable={true}
                    onClose={() => {
                        setMicrophoneUnreadableElement(undefined);
                    }}
                />
            )}

            {isMobileOrTabletUA ? (
                <SwitchCameraButton className={classes.controlButton} />
            ) : (
                <PreJoinConferenceBackgroundToggler
                    className={classes.controlButton}
                    classes={{
                        active: "active-blue",
                        disabled: "disabled",
                    }}
                    filter={props.filter}
                    onChangeFilter={props.onFilterChange}
                    videoCustomFilter={props.videoCustomFilter}
                    isDisabled={!props.supportsBackgroundFilter}
                    isMobile={props.isMobile}
                    key="pre-join-screen-conference-controls-background"
                />
            )}
        </div>
    );
};

export default PreJoinConferenceControls;
