import {BackgroundFilter, createVideoFxConfig} from "../VideoReplacement/VideoReplacementBackgrounds";
import {browserBehavior, getDeviceIdFromStream} from "../../utils";
import {StreamManager, StreamManagerResult} from "./StreamManager";
import {equals} from "ramda";
import {verifyDesktopPermission} from "./desktop-permission";
import browserInfo from "@workhorse/api/BrowserInfo";
import {VideoReplacementProcessor} from "../VideoReplacementWebgl2/VideoReplacementProcessor";
import {VideoPresets} from "livekit-client";

export interface VideoStreamManagerResult extends StreamManagerResult {
    videoTransformedStream?: MediaStream;
}

export function getVideoPreset() {
    return browserBehavior.supportsSimulcast() ? VideoPresets.h1080 : VideoPresets.h540;
}

export function getVideoSimulcastLayers() {
    return [VideoPresets.h216, VideoPresets.h540];
}

export class VideoStreamManager extends StreamManager<VideoStreamManagerResult> {
    protected filter: BackgroundFilter = BackgroundFilter.NONE;
    protected customFilter: string | null = null;
    protected videoReplacement?: VideoReplacementProcessor;

    static getVideoPreset() {
        return browserBehavior.supportsSimulcast() ? VideoPresets.h1080 : VideoPresets.h540;
    }

    getDefaultConstraints() {
        const videoPreset = getVideoPreset();
        const constraints: MediaTrackConstraints = {
            width: {ideal: videoPreset.resolution.width},
            height: {ideal: videoPreset.resolution.height},
            frameRate: {ideal: 15},
        };
        return constraints;
    }

    createConstraints(constraints?: MediaTrackConstraints | null): MediaStreamConstraints {
        return {
            audio: false,
            video: {
                ...this.getDefaultConstraints(),
                ...constraints,
            },
        };
    }

    updateConstraints(constraints?: MediaTrackConstraints | null) {
        this.constraints = this.createConstraints(constraints);
    }

    constraintsDidChange(constraints?: MediaTrackConstraints | null) {
        return !equals(this.constraints, this.createConstraints(constraints));
    }

    async stopStream() {
        this.stopBackgroundFilter();
        return super.stopStream();
    }

    async setStream(constraints?: MediaTrackConstraints | null) {
        const desktopPermission = await verifyDesktopPermission("camera");

        // true by default for non-desktop
        if (!desktopPermission) {
            return this.createDesktopNotAllowedResult();
        }

        const result = await this.replaceStream(this.createConstraints(constraints));

        if (result.error) {
            this.events.emit("video-error", result.error);
        }

        if (result.stream) {
            this.events.emit("video-success");
        }

        const deviceId = getDeviceIdFromStream(result.stream);

        if (deviceId) {
            this.updateConstraints({
                ...constraints,
                deviceId: {exact: deviceId},
            });
        }

        return result;
    }

    protected async createStream(constraints: MediaStreamConstraints) {
        const result: VideoStreamManagerResult = await super.createStream(constraints);
        result.videoTransformedStream = await this.createBackgroundFilter();
        return result;
    }

    protected stopBackgroundFilter() {
        this.videoReplacement?.stop();
        this.videoReplacement = undefined;
    }

    async updateBackgroundFilter(filter = BackgroundFilter.NONE, customFilter: string | null = null) {
        this.filter = filter;
        this.customFilter = customFilter;

        if (!this.videoReplacement) {
            return this.createBackgroundFilter();
        }

        const config = await createVideoFxConfig(this.filter, this.customFilter);

        if (!config) {
            return this.stopBackgroundFilter();
        }

        this.videoReplacement?.updateConfig(config);
        return this.videoReplacement?.getOutputStream();
    }

    protected async createBackgroundFilter() {
        try {
            if (!this.stream || !this.isActive()) {
                return undefined;
            }

            const config = await createVideoFxConfig(this.filter, this.customFilter);

            if (!config) {
                await this.stopBackgroundFilter();
                return;
            }

            this.videoReplacement = new VideoReplacementProcessor(this.stream, config);
            return this.videoReplacement.getOutputStream();
        } catch (e) {
            await this.stopBackgroundFilter();
            this.events.emit("background-filter-error", e);
            console.log(e);
            return undefined;
        }
    }

    protected createDesktopNotAllowedResult(): VideoStreamManagerResult {
        const error = new DOMException("Not allowed by system", "NotAllowedError");
        this.events.emit("video-error", error);

        return {
            error,
            stream: null,
        };
    }
}
