import {AgendaItemType, GetAgendaTemplatesDocument} from "@generated/data";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {commitCreateAgendaTemplate} from "@workhorse/api/designer/methods";
import OnboardingFeatureToggler from "@workhorse/api/onboarding/components/OnboardingFeatureToggler";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {useHistory, useLocation} from "@workhorse/api/routing";
import {useUserInfo} from "@workhorse/providers/User";
import {BroadcastChannel} from "broadcast-channel";
import {templatesSections} from "../../utils";
import classes from "./styles/AgendaTemplatesListHeader.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import FeedbackButton from "@workhorse/components/FeedbackButton";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import CelloButton from "@workhorse/components/CelloButton";

const AgendaTemplatesListHeader = () => {
    const user = useUserInfo();

    const setEditState = designer.state.update;

    const history = useHistory();
    const {search} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const shouldCreate = searchParams.get("create");
    const {mixpanelTrack} = useMixpanelTracker();
    const [disabled, setDisabled] = useState(false);

    const [timeoutNotify, setTimeoutNotify] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [newAgendaError, setNewAgendaError] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const createNewAgendaTemplate = async () => {
        setDisabled(true);

        const templateId = makeId();

        const existing = apollo.cache.readQuery({
            query: GetAgendaTemplatesDocument,
            variables: {
                workspaceId: user.activeWorkspace.id,
            },
        });

        let title = "Agenda";
        if (existing?.agendaTemplates?.some((t) => t?.name === title)) {
            let i = 1;
            while (existing?.agendaTemplates?.some((t) => t?.name === title + " (" + i + ")")) {
                i++;
            }
            title = title + " (" + i + ")";
        }

        designer.state.initializeOrResetState(templateId + "_template");

        const create = designer.api.session.create({
            session: {
                name: title,
                id: templateId + "_template",
            },
            agendaItems: [],
            hasAgenda: true,
            isTemplate: true,
        });

        setEditState({
            sessionId: create,
            templateMode: true,
            dirty: true,
        });

        const agendaItemId = designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Planned,
            title: "",
        });

        designer.api.agendaItem.addArtifact({
            agendaItemId: agendaItemId,
            addDefaultArtifact: true,
            overrides: {},
        });

        await commitCreateAgendaTemplate({
            notifyAfterMs: 5000,
            shouldRetry: false,
            abortAfterMs: 35000,
            notifyCb: (failed, retryAttemptNo) => {
                setTimeoutNotify((c) => (c !== failed ? failed : c));
            },
            timedOutCb: (failed, retryAttemptNo) => {
                if (!failed) {
                    setNewAgendaError(false);
                }
                setTimedOut((c) => (c !== failed ? failed : c));
            },
        }).catch((error) => {
            setNewAgendaError(true);
            console.log("error on creating New Agenda Template", error);
        });

        apollo.client.writeQuery({
            query: GetAgendaTemplatesDocument,
            variables: {
                workspaceId: user.activeWorkspace.id,
            },
            data: {
                agendaTemplates: [
                    ...(existing?.agendaTemplates ?? []),
                    {
                        id: templateId,
                        name: title,
                        updatedAt: new Date(),
                        hasEditorAccess: false,
                        duration: 0,
                        isPublic: false,
                        workspaceId: user.activeWorkspace.id,
                        user: {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                        },
                        __typename: "AgendaTemplate",
                    },
                ],
            },
        });

        if (shouldCreate) {
            const templateCreatedBC = new BroadcastChannel("tc-bc");

            templateCreatedBC.postMessage(new Date().toISOString());
        }

        history.push({
            pathname: templatesSections[0].path + "/" + templateId + (shouldCreate === "home-notification" ? "?ai-copilot=true" : ""),
        });
        mixpanelTrack("frontend-new-agenda", "agendas");
        setDisabled(false);
    };

    useEffect(() => {
        if (shouldCreate) {
            createNewAgendaTemplate();
        }
    }, [shouldCreate]);

    const btnDisabled = newAgendaError || timedOut ? false : disabled || timeoutNotify;

    const tooltipMsg = newAgendaError || timedOut ? "Could not create the agenda template due to a network failure. Please try again." : "";

    const disableTooltip = !(newAgendaError || timedOut);

    const onCreateBookingTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const onCreateBookingTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <div className="flex flex-justify-between flex-align-center py-5">
            <div className="flex flex-items-center">
                <Typography color="primary" fontWeight="bolder" variant="lg">
                    Agendas
                </Typography>
                <OnboardingFeatureToggler feature="agendaDashboard" className="ml-8" />
            </div>

            <div className="flex flex-items-center">
                <Tooltip
                    arrow
                    placement="bottom"
                    title={tooltipMsg}
                    open={newAgendaError || timedOut || tooltipOpen}
                    onOpen={onCreateBookingTooltipOpen}
                    onClose={onCreateBookingTooltipClose}
                    disableFocusListener={disableTooltip}
                    disableHoverListener={disableTooltip}
                    disableTouchListener={disableTooltip}
                >
                    <Button
                        onClick={createNewAgendaTemplate}
                        size="small"
                        data-id="agendas-button-new-agenda"
                        className={classes.newAgendaButton}
                        disabled={btnDisabled}
                        loading={btnDisabled}
                    >
                        New agenda
                    </Button>
                </Tooltip>
                <FeedbackButton className="ml-12" />
                <CelloButton className="ml-12" />
            </div>
        </div>
    );
};

export default AgendaTemplatesListHeader;
