import clientEvents from "@api/events/client";
import {
    ApolloClient,
    ApolloClientOptions,
    createHttpLink,
    DocumentNode,
    from,
    fromPromise,
    InMemoryCache,
    NormalizedCacheObject,
    ServerError,
    split,
} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {createPersistedQueryLink} from "@apollo/client/link/persisted-queries";
import {RetryLink} from "@apollo/client/link/retry";
import {getMainDefinition} from "@apollo/client/utilities";
import clientTypedefs from "@generated/client-typedefs";
import {
    CalendarView,
    ConfigurationStep,
    DesignerEditState,
    DesignerSessionId,
    DesignerState,
    ExternalProvider,
    GetRemoteUserDocument,
    GetRemoteUserQuery,
} from "@generated/data";
import env from "@generated/environment";
import hashes from "@generated/hashes/client.json";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import {isEventRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {WorkspaceAccess} from "./access/WorkspaceAccess";
import timeoutLink, {Mutations, QueriesAndMutations} from "./apollo-link-timeout";
import fieldResolvers from "./apolloFieldResolvers";
import {mergeFn} from "./apolloFieldResolvers/apollo.model.merge.rules";
import apolloFullSessionManipulator, {writeFromFullSession} from "./apolloFieldResolvers/apolloFullSessionManipulator";
import {AuthService} from "./authService";
import cacheDefaults from "./cacheDefaults";
import designer from "./designer";
import {
    designerCommitDefaultState,
    designerDefaultState,
    designerEditStateDefault,
    designerSessionIdDefault,
} from "./designer/lib/designer-state/client-defaults";
import {persistedField} from "./persistField";
import toast from "./toast";
import {usePregeneratedHashes} from "./usePregeneratedHashes";
import {subscriptionManagerLink} from "./apollo-link-subscription-manager";
import {ApolloOperationContext} from "./apolloTypes";

export type {ApolloOperationContext};

const queryUrl = env.clientReadUrl;
const mutationUrl = env.clientWriteUrl;

const typeDefs = [clientTypedefs];

let gotNotAuthenticatedError = false;

let pendingRequests: Array<() => void> = [];

const addPendingRequest = (pendingRequest: () => void) => {
    pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
    pendingRequests.forEach((callback) => callback());
    pendingRequests = [];
};

const createPendingPromise = () =>
    new Promise<void>((resolve) => {
        addPendingRequest(resolve);
    });

const trySilentLogin = async () => {
    gotNotAuthenticatedError = true;
    try {
        await AuthService.getInstance().getUser();
        gotNotAuthenticatedError = false;
    } catch (err) {
        console.warn(err);
        console.warn(`[Auth service]: error, logging out`);
        AuthService.getInstance().logout();
    }
};

const mutationsThatCanBeRetried: Mutations[] = [
    "CheckSessionExists",
    "CreateOneSession",
    "TryJoinSession",
    "JoinWithInviteLink",
    "JoinWithPublicLink",
];

function allowRetryForMutation(mutation: Mutations) {
    return mutationsThatCanBeRetried.includes(mutation);
}

function noCacheFetchOptions(options: RequestInit | undefined) {
    let headers: RequestInit["headers"] | null = null;
    if (typeof window !== "undefined" && window.location.pathname.toLowerCase().startsWith("/memory")) {
        const tokenFromQueryString = new URLSearchParams(location.search).get("token");
        if (tokenFromQueryString) {
            headers = {
                "X-Invite-Code": tokenFromQueryString,
            };
        }
    }
    return !options
        ? {
              headers: {
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  ...headers,
              },
          }
        : {
              ...options,
              headers: {
                  ...options.headers,
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  ...headers,
              },
          };
}

export class Apollo {
    public constructor(clientSchema: DocumentNode[], options?: ApolloClientOptions<NormalizedCacheObject>, initDesigner?: boolean) {
        Object.keys(cacheDefaults).forEach((queryKey) => {
            this.cache.writeQuery(cacheDefaults[queryKey]);
        });
        this.createClient(clientSchema, options);
    }

    private createTransport = () => {
        const queryHttpLink = createHttpLink({
            fetch: (uri, options) => fetch(uri, noCacheFetchOptions(options)),
            uri: queryUrl,
            credentials: "include",
        });

        const mutationHttpLink = createHttpLink({
            fetch: (uri, options) => fetch(uri, noCacheFetchOptions(options)),
            uri: mutationUrl,
            credentials: "include",
        });

        const persistedLink = createPersistedQueryLink({
            useGETForHashedQueries: false,
            generateHash: usePregeneratedHashes(hashes),
        });

        const errorLink = onError(({graphQLErrors, networkError, response, forward, operation}) => {
            const definition = getMainDefinition(operation.query);
            const op = definition.kind === "OperationDefinition" && definition.operation;
            console.log("graphQLErrors", graphQLErrors, operation);
            if (graphQLErrors) {
                if (
                    !response?.data &&
                    response?.errors?.length &&
                    response?.errors?.some((e) => e.message === "Unauthorized!" || e.message === "Not authorized") &&
                    definition.name?.value === "FullSession" &&
                    window.location.href.includes("/event/")
                ) {
                    window.location.href = "/unauthorized/event";
                }

                graphQLErrors.map((error) => {
                    console.log("error", error);
                    console.error(
                        `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}, ${op}=${operation.operationName}`
                    );

                    if (error.extensions?.code && error.extensions.code === "TOAST") {
                        toast(error.message, {type: "error", duration: 3000});
                        if (response) {
                            response.errors = undefined;
                        }
                    }

                    const limitedFeature = error?.extensions?.code as LimitedFeature;
                    if ((networkError as ServerError)?.statusCode === 418 || Object.values(LimitedFeature).includes(limitedFeature)) {
                        if (limitedFeature && Object.values(LimitedFeature).includes(limitedFeature)) {
                            clientEvents.emit("feature-limit-error", {
                                feature: limitedFeature,
                                message: error.message,
                            });
                        }
                    }
                });
                if (
                    graphQLErrors.find((error) => {
                        return error.extensions?.code === "UNAUTHENTICATED";
                    }) ||
                    (networkError as ServerError)?.statusCode === 401
                ) {
                    if (gotNotAuthenticatedError) {
                        return fromPromise(createPendingPromise()).flatMap(() => {
                            return forward(operation);
                        });
                    }
                    return fromPromise(trySilentLogin()).flatMap(() => {
                        resolvePendingRequests();
                        return forward(operation);
                    });
                } else if (operation.operationName === "GetRemoteUser") {
                    // retries only this operation and only 1 time
                    return forward(operation);
                }
            }

            if (networkError && (networkError as ServerError)?.statusCode !== 418) {
                console.log(`[Network error]: ${networkError} ${op}=${operation.operationName}`);
                console.error(networkError);
            }
        });

        const httpLink = split(
            ({query}) => {
                const definition = getMainDefinition(query);
                return definition.kind === "OperationDefinition" && definition.operation === "mutation";
            },
            mutationHttpLink,
            queryHttpLink
        );

        const retryLink = new RetryLink({
            attempts(count, operation, error) {
                const definition = getMainDefinition(operation.query);
                const opName = operation.operationName as QueriesAndMutations;
                const {shouldRetry, maxRetryCount, onNetworkError, cancelRef, response} = operation.getContext() as ApolloOperationContext;
                operation.setContext({retryAttemptNo: count});
                const op = definition.kind === "OperationDefinition" && definition.operation;
                const aborted = cancelRef?.aborted;

                const canRetry =
                    response?.status !== 401 &&
                    !aborted &&
                    !!error &&
                    (shouldRetry === false ? false : shouldRetry || op !== "mutation" || allowRetryForMutation(opName as Mutations));

                if (!canRetry) {
                    if (!aborted && error && onNetworkError) {
                        onNetworkError(count);
                    }
                    return false;
                }

                if (count > (maxRetryCount ? (maxRetryCount <= 2 ? maxRetryCount : 1) : 1)) {
                    console.warn(`[Network retry]: exceeded retry count for ${op}=${operation.operationName}. Aborting.`);
                    return false;
                }

                console.warn(`[Network retry]: retry ${count} for ${op}=${operation.operationName}, error=${error.message}`);

                return true;
            },
            delay(count, operation, error) {
                const definition = getMainDefinition(operation.query);
                const op = definition.kind === "OperationDefinition" && definition.operation;
                return 1500 + Math.floor(Math.random() * 10000);
            },
        });

        return {
            link: from([
                ...(env.environmentName === "development" || env.environmentName?.startsWith("devbox-") ? [] : [persistedLink]),
                retryLink,
                errorLink,
                timeoutLink,
                apolloFullSessionManipulator,
                subscriptionManagerLink,
                httpLink,
            ]),
        };
    };

    private createClient = (clientSchema: DocumentNode[], options?: ApolloClientOptions<NormalizedCacheObject>) => {
        const {link} = this.createTransport();

        this.client = new ApolloClient({
            // options goes first
            // technically, whatever options are passed here, they cannot override the link or the cache
            ...options,
            credentials: "include",
            defaultOptions: {
                query: {
                    errorPolicy: "ignore",
                },
                mutate: {
                    errorPolicy: "ignore",
                },
                watchQuery: {
                    errorPolicy: "ignore",
                },
            },
            resolvers: {
                AgendaTemplate: {
                    isReadOnly(template, existing, {cache}: {cache: InMemoryCache}) {
                        const userData = cache.diff<GetRemoteUserQuery>({
                            query: GetRemoteUserDocument,
                            optimistic: true,
                            returnPartialData: true,
                        }).result;

                        const userWorkspacePermissions = userData?.getRemoteUser.user?.workspacePermissions.find(
                            (wp) => wp.activeWorkspace
                        );

                        const userWorkspaceId = userWorkspacePermissions?.workspace?.id;

                        if (!userWorkspaceId) {
                            return true;
                        }

                        const user = userData?.getRemoteUser.user;

                        let canEdit = false;

                        if (user != null) {
                            const workspaceAccess = new WorkspaceAccess(user.id, userWorkspacePermissions?.role);
                            canEdit = workspaceAccess.canEditAgenda(template.userId, template.hasEditorAccess);
                        }
                        const isPublic = template?.isPublic;

                        return isPublic ? !canEdit : false;
                    },
                },
            },
            link,

            // the cache is not re-created
            // should createClient() be called with custom options
            // forcing this.client to be re-created
            cache: this.cache,
            typeDefs: clientSchema,
        });
    };

    public cache = new InMemoryCache({
        // IMPORTANT client.readFragment won't work without this
        // TODO remove this and use cache.identify
        // @ts-ignore
        dataIdFromObject: (object) => object.id,
        typePolicies: {
            Version: {
                keyFields: ["__typename"],
                fields: {
                    isMismatch: {
                        read(existing) {
                            return existing ?? false;
                        },
                    },
                },
            },
            GetRemoteUserResult: {
                keyFields: ["__typename"],
                fields: {
                    updateTs: {
                        read(existing) {
                            return existing ?? new Date();
                        },
                    },
                },
                merge(existing, incoming) {
                    return {...(existing ?? {}), ...incoming, updateTs: new Date()};
                },
            },

            WorkspaceRole: {
                keyFields: ["id", "__typename"],
                fields: {
                    clientOnly: {
                        read(existing) {
                            return existing ?? false;
                        },
                    },
                },
                // merge(existing, incoming) {
                //     return {...(existing ?? {}), ...incoming};
                // },
            },
            ArtifactDescriptor: {
                keyFields: ["tag"],
            },
            Resource: {
                fields: {
                    readOnly: {
                        read(existing, {cache}) {
                            return false;
                        },
                    },
                },
            },
            AgendaTemplate: {
                keyFields: ["__typename", "id"],
                fields: {
                    agendaItems: {
                        merge: false,
                    },
                },
            },
            SessionsUsingResourceResult: {
                keyFields: ["sessionId"],
            },
            TemplateTag: {
                keyFields: ["__typename", "id"],
                fields: {
                    agendaTemplates: {
                        merge: false,
                    },
                },
            },
            Artifact: {
                fields: fieldResolvers.Artifact,
                merge: mergeFn.Artifact,
            },
            AgendaItem: {
                fields: fieldResolvers.AgendaItem,
                merge: mergeFn.AgendaItem,
            },
            RecurrenceData: {
                fields: fieldResolvers.RecurrenceData,
                // merge: mergeFn.RecurrenceData,
            },
            SessionEvent: {
                fields: fieldResolvers.Event,
            },
            Room: {
                keyFields: ["__typename", "id"],
                fields: fieldResolvers.Room,
                merge: mergeFn.Room,
            },
            CalendarCustomEvent: {
                keyFields: ["id", "__typename", "occurrenceId"],
                fields: {
                    id: {
                        read(existing: string) {
                            return existing.replace("sfe-", "");
                        },
                    },
                    instanceId: {
                        read(existing: string | undefined) {
                            if (!existing?.startsWith("sfe-")) {
                                return existing;
                            }
                            return existing.replace("sfe-", "");
                        },
                    },
                    startAt: {
                        read(existing) {
                            if (existing) {
                                return new Date(existing);
                            }

                            return existing;
                        },
                    },
                    plannedEnd: {
                        read(existing) {
                            if (existing) {
                                return new Date(existing);
                            }

                            return existing;
                        },
                    },
                    occurrenceId: {
                        read(existing) {
                            if (existing) {
                                return new Date(existing);
                            }

                            return existing;
                        },
                    },
                    participants: {
                        merge: false,
                    },
                    recurrenceParentId: {
                        read(existing) {
                            if (!existing) {
                                return null;
                            }

                            return existing;
                        },
                    },
                },

                merge: true,
            },
            EventForEvent: {
                keyFields: ["id", "__typename"],
                fields: {
                    id: {
                        read(existing) {
                            return existing;
                        },
                    },
                },
            },
            ParticipantForEvent: {
                keyFields: ["id", "__typename"],
                fields: {
                    id: {
                        read(existing) {
                            return existing;
                        },
                    },
                },
            },
            InstanceOfRecurrenceForEvent: {
                keyFields: ["id", "__typename"],
                fields: {
                    id: {
                        read(existing) {
                            return existing;
                        },
                    },
                },
            },
            ExternalCalendar: {
                keyFields: ["id", "__typename"],
            },
            ExternalCalendarEvent: {
                keyFields: ["id", "__typename"],
                fields: {
                    calendarId: {
                        read: (existing) => {
                            return existing ? existing : null;
                        },
                    },
                    type: {
                        read: (existing) => {
                            return existing ? existing : null;
                        },
                    },
                    startAt: {
                        read: (existing) => {
                            return existing ? new Date(existing) : new Date();
                        },
                    },
                    plannedEnd: {
                        read: (existing) => {
                            return existing ? new Date(existing) : new Date();
                        },
                    },
                    occurrenceId: {
                        read: (existing) => {
                            return existing ? new Date(existing) : null;
                        },
                    },
                },
            },
            Chat: {
                keyFields: ["id", "__typename"],
            },
            ChatMessage: {
                keyFields: ["id", "__typename"],
            },
            MessageFile: {
                keyFields: ["id", "__typename"],
            },
            ChatGroup: {
                keyFields: ["id", "__typename"],
                fields: {
                    clientOnly: {
                        read(existing) {
                            return existing ?? false;
                        },
                    },
                    unreadCount: {
                        read(existing) {
                            return existing ?? 0;
                        },
                    },
                    fetchedMessagesOverNetwork: {
                        read(existing) {
                            return existing ?? false;
                        },
                    },
                },
            },
            Poll: {
                keyFields: ["id", "__typename"],
                fields: {
                    resultsSubmitted: {
                        read(existing) {
                            return existing ?? false;
                        },
                    },
                },
                merge: true,
            },
            PollOption: {
                keyFields: ["id", "__typename"],
                fields: {},
                merge: true,
            },
            PollTextAnswer: {
                keyFields: ["id", "__typename"],
                fields: {
                    answeredByCurrentUser: {
                        read(existing) {
                            return existing ?? false;
                        },
                    },
                },
                merge: true,
            },
            Session: {
                fields: fieldResolvers.Session,
                merge: mergeFn.Session,
            },
            ResourceNames: {
                merge(existing, incoming, ctx) {
                    if (!existing && incoming) {
                        return incoming;
                    }
                    if (existing.names.length && !incoming.names.length) {
                        return existing;
                    }
                    if (existing.names.length && incoming.names.length) {
                        return incoming;
                    }
                    if (!existing.names.length && incoming.names.length) {
                        return incoming;
                    }

                    return incoming;
                },
            },
            Participant: {
                fields: {
                    ...fieldResolvers.Participant,
                },
                merge: mergeFn.Participant,
            },
            ParticipantDataWithNullableEmail: {
                merge: (existing, incoming) => {
                    const merged = existing?.email && !incoming.email ? {...incoming, email: existing.email} : incoming;
                    return merged;
                },
            },

            CalendarSettings: {
                keyFields: ["__typename"],
                fields: {
                    view: persistedField<CalendarView>("hay.calendarSettings.view", CalendarView.Weeks),
                    live: persistedField<boolean>("hay.calendarSettings.live", true),
                    invitations: persistedField<boolean>("hay.calendarSettings.invitations", true),
                    bookings: persistedField<boolean>("hay.calendarSettings.bookings", true),
                    events: persistedField<boolean>("hay.calendarSettings.events", true),
                    externalCalendars: persistedField<ExternalProvider[]>("hay.calendarSettings.external", []),
                },
            },
            Question: {
                keyFields: ["id", "__typename"],
                merge: (existing, incoming, {mergeObjects}) => {
                    return mergeObjects(existing, incoming);
                },
            },
            ContactGroup: {
                fields: {
                    contacts: {
                        merge: false,
                    },
                },
            },
            Contact: {
                keyFields: ["id", "__typename"],
                fields: {
                    group: {
                        merge: false,
                    },
                },
            },
            CustomContact: {
                keyFields: ["id", "__typename"],
            },
            CustomContactGroup: {
                keyFields: ["id", "__typename"],
            },
            QuestionsContainer: {
                fields: {
                    questions: {
                        merge: false,
                    },
                },
                merge: (existing, incoming, {mergeObjects}) => {
                    return mergeObjects(existing, incoming);
                },
            },
            BreakoutRoomsConfiguration: {
                keyFields: ["id", "__typename"],
                fields: {
                    breakoutRooms: {
                        merge: false,
                    },
                },
                merge: (existing, incoming, {mergeObjects}) => {
                    return mergeObjects(existing, incoming);
                },
            },
            BreakoutRoomsParticipantMap: {
                keyFields: ["id", "__typename"],
            },
            Slideshow: {
                merge: (existing, incoming, {mergeObjects}) => {
                    return mergeObjects(existing, incoming);
                },
            },
            Property: {
                fields: fieldResolvers.Property,
                merge: true,
            },
            ResourceResult: {
                fields: fieldResolvers.ResourceResult,
            },
            User: {
                keyFields: ["__typename", "id"],
                merge: true,
                fields: {
                    seen: {
                        merge(existing, incoming) {
                            return {...existing, ...incoming};
                        },
                    },
                    workspacePermissions: {
                        merge: false,
                    },
                },
            },
            SessionTranscript: {
                keyFields: ["__typename", "id"],
            },
            SessionTranscriptContent: {
                keyFields: ["__typename", "id"],
            },
            PaddlePlan: {
                keyFields: ["__typename", "id"],
            },
            UserToOrganizationPermission: {
                keyFields: ["__typename", "id"],
            },
            Organization: {
                keyFields: ["__typename", "id"],
            },
            UserToWorkspacePermission: {
                keyFields: ["__typename", "id"],
            },
            Workspace: {
                keyFields: ["__typename", "id"],
            },
            Folder: {
                keyFields: ["__typename", "id"],
            },
            AgendaItemSpeaker: {
                fields: fieldResolvers.AgendaItemSpeaker,
            },
            SessionWatchdogUpdateResult: {
                merge: false,
                fields: fieldResolvers.SessionWatchdogUpdateResult,
            },
            OnNewPendingUsersCountPayload: {
                merge: true,
                keyFields: ["__typename"],
            },
            UserPaddleTransaction: {
                keyFields: ["__typename", "orderId"],
            },
            SessionSettings: {
                keyFields: ["__typename", "id"],
            },
            Subscription: {
                fields: {
                    watchdog: {
                        keyArgs: ["sessionId"],
                    },
                    watchdogPrivate: {
                        keyArgs: ["sessionId"],
                    },
                    globalNotifications: {
                        merge: false,
                    },
                    participantConferenceStatus: {
                        merge: false,
                    },
                    sharedResourcesUpdate: {
                        merge: false,
                    },
                    formAnswerCreated: {
                        merge: false,
                    },
                    formAnswerCreatedEmpty: {
                        merge: false,
                    },
                    questionsContainerUpdate: {
                        merge: false,
                    },
                    questionsBeingAnswered: {
                        merge: false,
                    },
                    questionAnsweredForParticipant: {
                        merge: false,
                    },
                    resxResourceUpdated: {
                        merge: false,
                    },
                    processPresentationJobStatus: {
                        merge: false,
                    },
                    onNotification: {
                        merge: false,
                    },
                    onSessionCreateUpdateDelete: {
                        merge: false,
                    },
                    waitForApprovalInSessionAsOwner: {
                        merge: false,
                    },
                    waitForApprovalInSession: {
                        merge: false,
                    },
                    sessionParticipantUpdates: {
                        merge: false,
                    },
                    breakoutRooms: {
                        merge: false,
                    },
                    onNewBooking: {
                        merge: false,
                    },
                },
            },
            Branding: {
                keyFields: ["__typename", "id"],
            },
            Query: {
                fields: {
                    userSessions: {
                        keyArgs: ["where"],
                    },
                    getRemoteUser: {
                        merge(existing, incoming) {
                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                    calendarRecurrentEvents: {
                        keyArgs: false,
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[], {args}) => {
                            // console.log("FORCE merging recurrent calendar events", {existing, incoming, args});
                            return incoming;
                        },
                    },
                    calendarEvents: {
                        keyArgs: false,
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[], {args}) => {
                            if ((args?.from as Date)?.getTime() === 0) {
                                // it means we are manually modifying the cache

                                // console.log("FORCE merging calendar events", {existing, incoming, args});
                                return incoming;
                            }

                            const uniqueEvents = existing ? [...existing] : [];
                            // biome-ignore lint/complexity/noForEach: <explanation>
                            incoming.forEach((x) => {
                                const found = uniqueEvents.find(
                                    (y) =>
                                        y.__ref.substring(0, y.__ref.indexOf(`"occurrenceId"`)) ===
                                        x.__ref.substring(0, x.__ref.indexOf(`"occurrenceId"`))
                                );
                                if (!found) {
                                    // console.log("adding calendar event", {x}, "to", {uniqueEvents});
                                    uniqueEvents.push(x);
                                } else {
                                    // console.log("replacing calendar event", {x}, "in", {uniqueEvents});
                                    uniqueEvents.splice(uniqueEvents.indexOf(found), 1, x);
                                }
                            });

                            // console.log("merging calendar events", {existing, incoming, merged: uniqueEvents, args});
                            return uniqueEvents;
                        },
                    },
                    getGoogleEvents: {
                        keyArgs: false,
                        merge: (
                            existing: {calendars: {__ref: string}[]; events: {__ref: string}[]} | null,
                            incoming: {calendars: {__ref: string}[]; events: {__ref: string}[]} | null
                        ) => {
                            const uniqueEvents = existing?.events ? [...existing.events] : [];
                            incoming?.events?.forEach((x) => {
                                if (!uniqueEvents.find((y) => y.__ref === x.__ref)) {
                                    uniqueEvents.push(x);
                                }
                            });

                            const merged = {
                                calendars: incoming?.calendars ?? [],
                                events: uniqueEvents,
                            };
                            return merged;
                        },
                    },
                    getMicrosoftEvents: {
                        keyArgs: false,
                        merge: (
                            existing: {calendars: {__ref: string}[]; events: {__ref: string}[]} | null,
                            incoming: {calendars: {__ref: string}[]; events: {__ref: string}[]} | null
                        ) => {
                            const uniqueEvents = existing?.events ? [...existing.events] : [];
                            incoming?.events?.forEach((x) => {
                                if (!uniqueEvents.find((y) => y.__ref === x.__ref)) {
                                    uniqueEvents.push(x);
                                }
                            });

                            const merged = {
                                calendars: incoming?.calendars ?? [],
                                events: uniqueEvents,
                            };
                            return merged;
                        },
                    },
                    getAppleEvents: {
                        keyArgs: false,
                        merge: (
                            existing: {calendars: {__ref: string}[]; events: {__ref: string}[]} | null,
                            incoming: {calendars: {__ref: string}[]; events: {__ref: string}[]} | null
                        ) => {
                            const uniqueEvents = existing?.events ? [...existing.events] : [];
                            incoming?.events?.forEach((x) => {
                                if (!uniqueEvents.find((y) => y.__ref === x.__ref)) {
                                    uniqueEvents.push(x);
                                }
                            });

                            const merged = {
                                calendars: incoming?.calendars ?? [],
                                events: uniqueEvents,
                            };
                            return merged;
                        },
                    },
                    agendaTemplate: {
                        keyArgs: ["where"],
                        merge: false,
                    },

                    bookTimeSlots: {
                        merge: (_, incoming) => incoming,
                    },
                    bookings: {
                        keyArgs: ["where"],
                        merge: false,
                    },
                    contacts: {
                        merge: false,
                    },
                    artifacts: {
                        keyArgs: ["where"],
                        read(existing, {variables, readField}) {
                            if (existing?.length) {
                                if ((variables as any)?.excludeDeleted) {
                                    return existing.filter((ref) => {
                                        const isDeleted = readField("isDeleted", ref);
                                        return !isDeleted;
                                    });
                                }
                            }
                            return existing ?? [];
                        },
                        merge: false,
                    },
                    designerEditState: {
                        read(existing) {
                            if (existing) {
                                return existing;
                            }
                            const isEvent = isEventRoute(window.location.pathname);
                            if (isEvent) {
                                return designerEditStateDefault;
                            }
                            const sessionIdData = sessionStorage.getItem(designer.storageKey.SESSION_ID);
                            const storageData = sessionStorage.getItem(designer.storageKey.EDIT_STATE);

                            let decoded: DesignerEditState | undefined = undefined;
                            let decodedId: DesignerSessionId | undefined = undefined;

                            if (storageData && sessionIdData) {
                                try {
                                    decoded = JSON.parse(storageData) as DesignerEditState;
                                    decodedId = JSON.parse(sessionIdData) as DesignerSessionId;
                                } catch (e) {
                                    console.log("could not decode storage edit state or sessionId data");
                                }
                            }

                            return decodedId && decoded && decodedId.sessionId === decoded.sessionId ? decoded : designerEditStateDefault;
                        },
                        merge(existing, incoming, {mergeObjects}) {
                            const output = mergeObjects(existing, incoming);
                            const isEvent = isEventRoute(window.location.pathname);
                            if (!isEvent) {
                                sessionStorage.setItem(designer.storageKey.EDIT_STATE, JSON.stringify(output));
                            }
                            return output;
                        },
                    },
                    designerSessionId: {
                        read(existing) {
                            if (existing) {
                                return existing;
                            }
                            const storageData = sessionStorage.getItem(designer.storageKey.SESSION_ID);

                            let decoded: DesignerSessionId | undefined = undefined;

                            if (storageData) {
                                try {
                                    decoded = JSON.parse(storageData) as DesignerSessionId;
                                } catch (e) {
                                    console.log("could not decode storage sessionId");
                                }
                            }
                            return decoded ?? designerSessionIdDefault;
                        },
                        merge(existing, incoming, {mergeObjects}) {
                            const output = mergeObjects(existing, incoming);
                            const isEvent = isEventRoute(window.location.pathname);
                            if (!isEvent) {
                                sessionStorage.setItem(designer.storageKey.SESSION_ID, JSON.stringify(output));
                            }
                            return output;
                        },
                    },
                    commitInProgress: {
                        read(existing) {
                            if (existing) {
                                return existing;
                            }
                            return designerCommitDefaultState;
                        },
                    },
                    designerState: {
                        read(existing) {
                            if (existing) {
                                return existing;
                            }
                            const sessionIdData = sessionStorage.getItem(designer.storageKey.SESSION_ID);
                            const storageData = sessionStorage.getItem(designer.storageKey.STATE);

                            let decoded: DesignerState | undefined = undefined;
                            let decodedId: DesignerSessionId | undefined = undefined;

                            if (storageData && sessionIdData) {
                                try {
                                    decoded = JSON.parse(storageData) as DesignerState;
                                    decodedId = JSON.parse(sessionIdData) as DesignerSessionId;
                                } catch (e) {
                                    console.log("could not decode storage session state or sessionId data");
                                }
                            }
                            if (decoded?.preventRestore) {
                                return designerDefaultState;
                            }

                            return decodedId && decoded && decodedId.sessionId === decoded.sessionId
                                ? {...decoded, configurationStep: ConfigurationStep.Preview}
                                : designerDefaultState;
                        },
                        merge(existing, incoming, {mergeObjects}) {
                            const output = mergeObjects(existing, incoming);
                            const isEvent = isEventRoute(window.location.pathname);
                            if (!isEvent) {
                                sessionStorage.setItem(designer.storageKey.STATE, JSON.stringify(output));
                            }
                            return output;
                        },
                    },
                    designerRestoreUncommitedDraft: {
                        read(existing, {variables, cache}) {
                            const {checkOnly, sessionId} = (variables ?? {}) as {checkOnly?: boolean; sessionId: string};
                            const isEvent = isEventRoute(window.location.pathname);
                            if (isEvent) {
                                return false;
                            }

                            if (!sessionId) {
                                // console.log("NO session id found for restore");
                                return false;
                            }
                            if (existing || cache.extract()[sessionId]) {
                                // console.log("NOT writing from restore coz exists");
                                return existing || true;
                            }

                            const storageData = sessionStorage.getItem(designer.storageKey.SESSION);

                            let decoded: DesignerApiSession | undefined = undefined;
                            if (storageData) {
                                try {
                                    decoded = JSON.parse(storageData) as DesignerApiSession;
                                } catch (e) {
                                    console.log("could not decode storage session state or sessionId data");
                                }
                            }
                            if (
                                decoded &&
                                decoded.session?.id === sessionId &&
                                "createdAt" in decoded.session &&
                                !decoded.session.createdAt &&
                                !(!!decoded.session.recurrenceData?.id || !!decoded.session.instanceOfRecurrence?.session?.id)
                            ) {
                                writeFromFullSession(sessionId, decoded);
                                return true;
                            }
                            return false;
                        },
                    },
                    agendaItems: {
                        keyArgs: ["where"],
                        read(existing, {variables, readField}) {
                            if (existing?.length) {
                                if ((variables as any)?.excludeDeleted) {
                                    return existing.filter((ref) => {
                                        const isDeleted = readField("isDeleted", ref);
                                        return !isDeleted;
                                    });
                                }
                            }

                            if (existing?.length) {
                                if ((variables as any)?.noDiff) {
                                    return existing.filter((ref) => {
                                        const createdAt = readField("createdAt", ref);
                                        return !!createdAt;
                                    });
                                }
                            }

                            return existing ?? [];
                        },
                        merge: false,
                    },
                    participants: {
                        keyArgs: ["where"],
                        merge: false,
                        read: (existing, {variables, readField}) => {
                            const participants = existing! ?? [];
                            const recorderRef = participants.find((participantRef) => readField("isRecorder", participantRef));
                            if (recorderRef) {
                                return participants.filter((ref) => !ref || !ref.__ref || ref.__ref !== recorderRef.__ref);
                            }
                            return participants ?? [];
                        },
                    },
                    session: {
                        keyArgs: ["where"],
                    },
                    // getSessionsByResourceId: {
                    //     keyArgs: ["resourceId"],
                    //     merge: false,
                    // },
                    // getSessionsByAgendaTemplateId: {
                    //     keyArgs: ["agendaTemplateId"],
                    //     merge: false,
                    // },
                    templateTags: {
                        merge: false,
                    },
                    resources: {
                        merge: false,
                    },
                    chatMessages: {
                        keyArgs: ["where", ["sessionId", "chatGroupId", ["equals"]]],
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[], {variables, args}) => {
                            const merged = existing ? existing.slice(0) : [];
                            const beforeId = args?.before?.id;

                            if ((variables as any).isDelete) {
                                return incoming ?? [];
                            }
                            for (let i = 0; i < incoming.length; ++i) {
                                if (!merged.find((m) => m.__ref === incoming[i].__ref)) {
                                    if (beforeId) {
                                        const beforeIndex = merged.findIndex((m) => m.__ref.includes(beforeId));
                                        if (beforeIndex > -1) {
                                            merged.splice(beforeIndex, 0, incoming[i]);
                                        } else {
                                            merged.push(incoming[i]);
                                        }
                                    } else {
                                        merged.push(incoming[i]);
                                    }
                                }
                            }

                            return merged;
                        },
                    },
                    transcripts: {
                        keyArgs: ["sessionId", "preferredLanguage"],
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[], {args, variables}) => {
                            const merged = existing ? existing.slice(0) : [];
                            const beforeId = args?.before;

                            for (let i = 0; i < incoming.length; ++i) {
                                if (!merged.find((m) => m.__ref === incoming[i].__ref)) {
                                    if (beforeId) {
                                        const beforeIndex = merged.findIndex((m) => m.__ref.includes(beforeId));
                                        if (beforeIndex > -1) {
                                            merged.splice(beforeIndex, 0, incoming[i]);
                                        } else {
                                            merged.push(incoming[i]);
                                        }
                                    } else {
                                        merged.push(incoming[i]);
                                    }
                                }
                            }

                            return merged;
                        },
                    },
                    messageFiles: {
                        keyArgs: ["where", ["chatMessage", ["sessionId", "chatGroupId", ["equals"]]]],
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[], {variables}) => {
                            const merged = existing ? existing.slice(0) : [];

                            if ((variables as any).isDelete) {
                                return incoming ?? [];
                            }

                            for (let i = 0; i < incoming.length; ++i) {
                                if (!merged.find((m) => m.__ref === incoming[i].__ref)) {
                                    merged.push(incoming[i]);
                                }
                            }

                            return merged;
                        },
                    },
                    chatGroups: {
                        keyArgs: ["sessionId"],
                        merge: false,
                    },
                    organization: {
                        merge: true,
                    },
                    workspace: {
                        merge: true,
                    },
                    rooms: {
                        keyArgs: ["workspaceId"],
                        merge: false,
                    },
                    version: {
                        read(existing) {
                            return existing ?? {isMismatch: false};
                        },
                    },
                    userPaddleTransactions: {
                        keyArgs: false,
                        merge(existing = [], incoming) {
                            return [...existing, ...incoming];
                        },
                    },
                    sessionEvents: {
                        keyArgs: ["workspaceId"],
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[], {args}) => {
                            if (args?.take === 999) {
                                return incoming;
                            }

                            const merged = existing ? existing.slice(0) : [];

                            for (let i = 0; i < incoming.length; ++i) {
                                if (!merged.find((m) => m.__ref === incoming[i].__ref)) {
                                    merged.push(incoming[i]);
                                }
                            }

                            return merged;
                        },
                    },
                    sessionEventsCount: {
                        keyArgs: ["workspaceId"],
                        merge: false,
                    },
                    sessionEventsOwners: {
                        keyArgs: [],
                        merge: (existing: {__ref: string}[] | null, incoming: {__ref: string}[]) => {
                            const merged = existing ? existing.slice(0) : [];

                            for (let i = 0; i < incoming.length; ++i) {
                                if (!merged.find((m) => m.__ref === incoming[i].__ref)) {
                                    merged.push(incoming[i]);
                                }
                            }

                            return merged;
                        },
                    },
                    bookingEvents: {
                        keyArgs: ["workspaceId"],
                    },
                },
                merge: true,
            },
        },
    });

    public client: ApolloClient<NormalizedCacheObject>;
}

const apollo = new Apollo(typeDefs);

export default apollo;
