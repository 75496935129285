import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {useTheme} from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";
import Typography from "@ui/cdk/Typography";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";

export type TextFieldProps = {
    fontSize?: "big";
    dialogStyle?: boolean;
    fontWeight?: "bold";
    maxLength?: number;
    showMaxLength?: boolean;
    errorStyles?: boolean;
    caretColor?: string;
    hideError?: boolean;
    renderValueAsPlaceHolder?: boolean;
    classes?: {
        wrapper?: string;
        root?: string;
        count?: string;
    };
} & ExtractProps<typeof MuiTextField>;

const useStyles = makeStyles((theme: Theme) => ({
    textFieldParrent: {
        position: "relative",
        width: "100%",
    },
    big: {
        "& input": {
            fontSize: 24,
            fontWeight: "bold",
        },
    },
    bold: {
        fontWeight: 500,
    },
    inputPadding: {
        padding: "10px 0",
    },
    inputDefault: {
        "&::placeholder": {
            color: theme.main.palette.gray[300],
            opacity: 1,
        },
    },

    default: {
        marginBottom: 10,

        "& .MuiInput-underline:before": {
            display: "none",
        },
        "& .MuiInput-underline:after": {
            display: "none",
        },
        "& textarea::placeholder": {
            color: theme.main.palette.gray[300],
            opacity: 1,
        },
        position: "relative",
    },
    count: {
        color: theme.main.palette.text.senary,
        right: 0,
        position: "absolute",
        bottom: -25,
        "&.fosTypography": {
            marginTop: theme.spacing(1),
        },
    },
    countMultiline: {
        right: 0,
        bottom: -16,
    },
    dialogStyle: {
        "& .MuiInput-underline:before": {
            display: "block",
            borderColor: theme.main.palette.gray[300],
        },
        "&:hover": {
            "& .MuiInput-underline:before": {
                borderColor: theme.main.palette.gray[300],
            },
        },
        "& .MuiInput-underline:after": {
            display: "block",
        },
    },
    error: {
        "& .MuiInput-underline:before": {
            borderColor: theme.main.palette.red[600],
        },
        "&:hover": {
            "& .MuiInput-underline:before": {
                borderColor: theme.main.palette.red[600],
            },
        },
    },
    errorColor: {
        color: theme.main.palette.red[600],
    },
    hideError: {
        "& .MuiInput-underline:before": {
            borderColor: theme.main.palette.gray[300],
            transition: "none",
        },
        "&:hover": {
            "& .MuiInput-underline:before": {
                borderColor: theme.main.palette.gray[300],
            },
        },
        "& + $errorColor": {
            color: theme.main.palette.gray[300],
        },
    },
}));

export default function TextField({
    className,
    inputProps,
    fontSize,
    dialogStyle,
    fontWeight,
    maxLength,
    showMaxLength,
    value,
    errorStyles,
    caretColor,
    hideError,
    defaultValue,
    renderValueAsPlaceHolder,
    placeholder,
    ...other
}: TextFieldProps) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={cls(classes.textFieldParrent, other.classes?.wrapper)}>
            <MuiTextField
                {...other}
                defaultValue={renderValueAsPlaceHolder ? "" : defaultValue}
                placeholder={renderValueAsPlaceHolder ? (defaultValue as string) : placeholder}
                value={renderValueAsPlaceHolder ? "" : value}
                inputProps={{
                    ...inputProps,
                    style: {
                        caretColor: caretColor || theme.main.palette.red[400],
                    },
                    maxLength: maxLength || 200,
                }}
                className={cls(
                    classes.default,
                    fontSize === "big" && classes.big,
                    dialogStyle && classes.dialogStyle,
                    fontWeight === "bold" && classes.bold,
                    className,
                    !hideError && errorStyles && classes.error,
                    hideError && classes.hideError
                )}
            />
            {maxLength && showMaxLength ? (
                <Typography
                    component="div"
                    variant="sm"
                    className={cls(
                        classes.count,
                        other.multiline && classes.countMultiline,
                        (value as string)?.length === maxLength && classes.errorColor,
                        other.classes?.count
                    )}
                >{`${(value as string)?.length || 0}/${maxLength}`}</Typography>
            ) : null}
        </div>
    );
}
