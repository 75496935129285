import Input from "@ui/cdk/Input";
import {cls, collapseWhiteSpace} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {useRef, useState} from "@workhorse/api/rendering";
import {updateTemplateNameInList} from "../../utils";
import classes from "./style/AgendaTemplatesEditorName.module.scss";
import {ReactComponent as EditIcon} from "../../../../assets/media/edit-icon.svg";
import {useUserInfo} from "@workhorse/providers/User";

type AgendaTemplatesEditorNameProps = {
    value: string;
    isReadOnly?: boolean;
    onBlur: () => Promise<void>;
};

const AgendaTemplatesEditorName = (props: AgendaTemplatesEditorNameProps) => {
    const {value, isReadOnly, onBlur: commit} = props;

    const [name, setName] = useState(value);

    const {activeWorkspace} = useUserInfo();

    const inputRef = useRef<HTMLInputElement>(null);

    const onBlur = () => {
        const nameTrimmed = collapseWhiteSpace(name?.trim() ?? "");
        if (nameTrimmed) {
            designer.api.session.update({
                name: nameTrimmed,
            });

            updateTemplateNameInList(nameTrimmed, activeWorkspace.id);

            setName(nameTrimmed);
        } else {
            setName(value.trim());
        }
        commit();
    };

    const handleFocusInput = () => {
        inputRef.current?.focus();
    };

    const onChangeName = (e) => {
        setName(e.target.value);
    };

    return (
        <div className="fullw flex flex-align-center">
            <EditIcon className={classes.editIcon} onClick={handleFocusInput} />

            <Input
                fontWeightVariant="boldest"
                data-id="agenda-title"
                typographyVariant="xl3"
                inputRef={inputRef}
                placeholder="Add agenda title"
                shrinkLabel={true}
                customVariant="plain"
                value={name}
                disabled={isReadOnly}
                className={cls(classes.input)}
                onChange={onChangeName}
                formControlClassName={classes.container}
                onBlur={onBlur}
                maxCharCount={60}
            ></Input>
        </div>
    );
};

export default AgendaTemplatesEditorName;
