import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {SessionOnboardingType, useIsPaletteWanted, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {usePaletteOpen} from "../command-palette/CommandPaletteProviders";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {TooltipButtonArrow, WithTooltipChildren} from "./onboarding-tooltips-utils";
import classes from "./onboarding.module.scss";
import {useDeferredValue} from "./utils";
import {useFullScreenState} from "@workhorse/providers/state";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

type OnboardingShareButtonProps = WithTooltipChildren & {
    renderOnlyChildren?: boolean;
};

export function OnboardingShareButton(props: OnboardingShareButtonProps) {
    const {onboardingType, tooltip, completedFlows, index, next} = useOnboardingSession();
    const [paletteOpen] = usePaletteOpen();
    const isPaletteWanted = useIsPaletteWanted(tooltip);
    const [noIntroTransition, setNoIntroTransition] = useState(false);
    const {isTablet} = useMobile();

    // these will make the component rerender and align itself properly when the UI states change
    const isFullScreen = useFullScreenState();
    const {sessionsUIHidden, sessionsUIFooter} = useSessionsUIToggle();

    const showPaletteOpen = isPaletteWanted && !paletteOpen && sessionsUIFooter;
    const showPaletteInfo = tooltip === "palette-tool-info";
    const showProductInfo = tooltip === "palette-my-product";
    const showShareInfo = tooltip === "palette-file-info" || tooltip === "palette-generic-info";
    const showShareLink = tooltip === "palette-link-info";
    const showEmbedTool = tooltip === "palette-tool-info";
    const showShareSettings = isTablet && tooltip === "share-settings";

    let title = (
        <>
            <Typography className="title" fontWeight="bolder">
                Embed presentations
            </Typography>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                From here, you can embed many different types of tools and files – including your presentations.
            </Typography>
        </>
    );

    if (showPaletteOpen) {
        title = (
            <>
                <Typography className="title" fontWeight="bolder">
                    Embed presentations
                </Typography>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    From here, you can embed many different types of tools and files – including your presentations.
                </Typography>
            </>
        );
    }

    if (showPaletteInfo) {
        title = (
            <>
                <Typography className="title" fontWeight="bolder">
                    Embed almost anything
                </Typography>

                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    Apart from your own product, you can also embed slide decks, videos, smartboards, websites, and more.
                    <br />
                    <br />
                    Bye-bye share screen!
                </Typography>

                <Button
                    variant="primary"
                    onClick={() =>
                        next({
                            delay: 1000,
                        })
                    }
                    className={cls("ml-auto mt-8", classes.button)}
                    size="small"
                    data-id="onboarding-button-strike2-step5-next"
                >
                    Next <TooltipButtonArrow />
                </Button>
            </>
        );
    }

    if (showProductInfo) {
        title = (
            <>
                <Typography className="title" fontWeight="bolder">
                    Embed your product
                </Typography>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    From here, you can embed many different types of tools and files – including your own digital product.
                </Typography>
            </>
        );
    }

    if (onboardingType === SessionOnboardingType.NewUser || showShareInfo) {
        // if completedFlows > 1, it means the user completed the inital flow and at least another one as well
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        {completedFlows.length > 1 ? "Want to share something else?" : "Let’s embed something on screen"}
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    {completedFlows.length > 1
                        ? "Click on Share."
                        : `${isTablet ? `Click on the "Share" button from the menu to begin.` : "Click on Share."}`}
                </Typography>
            </>
        );
    }

    if (showShareLink) {
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        Want to share something else?
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    Click on Share.
                </Typography>
            </>
        );
    }

    if (showEmbedTool) {
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        Want to share something else?
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    Click on Share.
                </Typography>
            </>
        );
    }

    if (showShareSettings) {
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        Stop sharing?
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    To stop sharing a file or link, click on the "Detach file" button in the menu.
                </Typography>
            </>
        );
    }

    const delay = 1000;
    let skipTooltip =
        (!showPaletteOpen && !showPaletteInfo) || (!showPaletteOpen && !showShareInfo) || (!showPaletteOpen && !showShareLink);

    if (showShareSettings) {
        skipTooltip = false;
    }

    const deferSkip = useDeferredValue(tooltip, skipTooltip, true, delay);

    useEffect(() => {
        if ((tooltip === "palette-file-info" || tooltip === "palette-file-demo") && !skipTooltip) {
            setNoIntroTransition(true);
        } else {
            setNoIntroTransition(false);
        }
    }, [tooltip, skipTooltip]);

    return props.renderOnlyChildren ? (
        props.children
    ) : (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement="top-start"
            arrow={true}
            skip={noIntroTransition ? skipTooltip : deferSkip}
            classes={{tooltip: classes.tooltipBigger}}
            noIntroTransition={noIntroTransition}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
