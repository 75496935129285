import {ConferenceParticipantStatus, ParticipantChangedBy} from "@generated/data";
import {useEffect, useState} from "@workhorse/api/rendering";
import {AllParticipants, Participant} from "@workhorse/declarations/dataTypes";
import {microNotify} from "./micro-notification-center";
import {MuteNotification, MuteNotificationType} from "./micro-notification-center/notifications/MuteNotification";
import {SpotlightNotification, SpotlightNotificationType} from "./micro-notification-center/notifications/SpotlightNotification";
import {useParticipants} from "@workhorse/providers/SessionDataProviders";

type PendingOwnerRequestsProps = {
    myParticipantId: string;
    isAssistant: boolean;
    sessionId: string;
};

const makePending = (participants: AllParticipants) => {
    return participants.filter((p) => [ConferenceParticipantStatus.InvitedToSpeak].indexOf(p.conferenceStatus) !== -1);
};

function PendingOwnerRequests(props: PendingOwnerRequestsProps) {
    const {myParticipantId} = props;

    // TODO: maca does this need optimization ?
    const participants = useParticipants();

    const [pending, setPending] = useState<Participant[]>(() => makePending(participants));

    useEffect(() => {
        const newPending = makePending(participants).filter((p) => p.statusChangedBy?.participantId === myParticipantId);
        const newIds = newPending.map((p) => p.id);

        // biome-ignore lint/complexity/noForEach: <explanation>
        pending.forEach((p) => {
            if (newIds.indexOf(p.id) == -1) {
                const current = participants.find((pp) => pp.id === p.id);
                if (current) {
                    const newStatus = current.conferenceStatus;
                    const changedByHimself =
                        current.changedBy === ParticipantChangedBy.Himself || current.id === current.statusChangedBy?.participantId;
                    if (newStatus === ConferenceParticipantStatus.Participant) {
                        const oldStatus = p.conferenceStatus;
                        const {firstName, lastName} = p.dataWithNullableEmail;
                        const name = `${firstName} ${lastName}`;

                        if (changedByHimself) {
                            if (oldStatus === ConferenceParticipantStatus.InvitedToSpeak) {
                                microNotify<SpotlightNotificationType>({
                                    component: SpotlightNotification,
                                    message: {
                                        user: name,
                                        action: "decline",
                                    },
                                    type: "spotlight",
                                });
                            } else {
                                microNotify<MuteNotificationType>({
                                    component: MuteNotification,
                                    message: {
                                        user: name,
                                        action: "revoked-unmute",
                                    },
                                    type: "mute-unmute",
                                });
                            }
                        }
                    }
                }
            }
        });

        setPending(newPending);
    }, [participants, myParticipantId]);

    return <></>;
}

export default PendingOwnerRequests;
