import {ArrayItem} from "@workhorse/declarations";
import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";

export type AgendaItemArtifact = NonNullable<ArrayItem<DesignerApiSession["agendaItems"]>["artifact"]>;

export type RemoveArtifactFromAgendaItemInput = {
    agendaItemId: string;
};

export class RemoveArtifactFromAgendaItem extends DesignerAction<RemoveArtifactFromAgendaItemInput> {
    commit() {
        return designer.api.agendaItem.addArtifact({
            agendaItemId: this.input.agendaItemId,
            addDefaultArtifact: true,
            overrides: {},
        });
    }
}
