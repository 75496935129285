import {useDeviceManagerEvents, useMediaMute} from "@workhorse/api/conference2";
import {useConferenceRoom} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";

type MutedAudioAnalyzerProps = {
    onSpeak?: () => void;
};

export function MutedAudioAnalyzer({onSpeak}: MutedAudioAnalyzerProps) {
    const {roomStatus} = useConferenceRoom();

    if (!roomStatus.connected) {
        return null;
    }

    return <MutedAudioAnalyzerController onSpeak={onSpeak} />;
}

function MutedAudioAnalyzerController({onSpeak}: MutedAudioAnalyzerProps) {
    const {mediaMuted} = useMediaMute();

    useDeviceManagerEvents({
        listen: !mediaMuted,
        onAudioVolume(data) {
            if (data > 0.3) {
                onSpeak?.();
            }
        },
    });

    return <></>;
}
