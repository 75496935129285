import {cls} from "@ui/cdk/util/util";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";
import {useChimeIsBlocked, useLocalNetworkState} from "@workhorse/providers/PresenceProvider";
import {ReactComponent as WifiNoInternet} from "../assets/media/wifi-no-internet.svg";
import classes from "./style/OnlineOfflineDetector.module.scss";
import {v4 as uuidv4} from "uuid";
import {makeVar, useReactiveVar} from "@workhorse/api/data";
import {clearWorkerTimeout, setWorkerTimeout} from "@workhorse/timerWorker";
import {useTranslation} from "react-i18next";

type OfflineMessageProps = {
    isOffline: boolean;
};

function OfflineMessage({isOffline}: OfflineMessageProps) {
    const {t} = useTranslation();
    const [show, setShow] = useState(isOffline);

    useEffect(() => {
        if (!isOffline && show) {
            const timeout = setTimeout(() => {
                setShow(false);
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        } else if (isOffline && !show) {
            setShow(true);
        }
    }, [isOffline, show]);

    return (
        <div className={cls(classes.offline, show && classes.offlineShow)} aria-hidden={!show}>
            <div
                className={cls(
                    classes.offlineMessage,
                    show && classes.offlineMessageShow,
                    show && isOffline && classes.offlineMessageDisconnected,
                    show && !isOffline && classes.offlineMessageWelcomeBack
                )}
            >
                <WifiNoInternet />
                <span>
                    {isOffline ? (
                        <>{t("player.notifications.network.connectivity_issues")}</>
                    ) : (
                        <>{t("player.notifications.network.connectivity_issues_resolved")}</>
                    )}
                </span>
            </div>
            <div className={cls(classes.offlineBackdrop, show && classes.offlineBackdropShow)} />
        </div>
    );
}

function TimeoutBackdrop() {
    return <div className={cls(classes.offline, classes.offlineShow, classes.timeoutBackdrop)}></div>;
}

type OnlineOfflineDetectorProps = WithChildren;

export const timeoutUid = uuidv4();
export const operationTimeout = makeVar(false);

let healthCheck: Promise<boolean> | null = null;
let timer: string | null = null;

let pendingPromise: Promise<boolean> | null = null;

async function doHealthCheckBase() {
    const controller = new AbortController();
    const timer = setTimeout(() => {
        controller.abort("Aborting pixel health check. No response was received in the allowed timeframe of 2sec.");
    }, 2000);
    try {
        const res = await fetch(`${process.env.REACT_APP_RESOURCES_CDN_ROOT}/.well-known/pixel.png`, {
            headers: {
                Pragma: "no-cache",
                Accept: "image/png",
            },
            cache: "no-store",
            signal: controller.signal,
            mode: "cors",
        });
        clearTimeout(timer);
        const isImg = res.headers.get("content-type") === "image/png";

        if (isImg && res.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        clearTimeout(timer);
        console.error(`[Health-check]: could not fetch pixel.`, e);
        return false;
    }
}

async function doHealthCheck() {
    if (pendingPromise) {
        return pendingPromise;
    }

    pendingPromise = doHealthCheckBase();
    const result = await pendingPromise;
    pendingPromise = null;
    return result;
}

export function useOnlineOfflineDetector() {
    const [isOffline, setIsOffline] = useState(false);
    const {isDown, isFluctuating} = useLocalNetworkState();
    const operationTimedOut = useReactiveVar(operationTimeout);
    const chimeIsBlocked = useChimeIsBlocked();
    const prev = useRef({
        isOffline,
        isDown,
        isFluctuating,
    });
    const wasOffline = prev.current.isOffline && !isOffline;
    const wasDown = prev.current.isDown && !isDown;
    const wasFluctuating = prev.current.isFluctuating && !isFluctuating;

    useEffect(() => {
        const onOnline = () => {
            setIsOffline(false);
        };

        const onOffline = () => {
            doHealthCheck().then((res) => {
                if (!res) {
                    setIsOffline(true);
                }
            });
        };

        window.addEventListener("online", onOnline);
        window.addEventListener("offline", onOffline);

        return () => {
            window.removeEventListener("online", onOnline);
        };
    }, []);

    useEffect(() => {
        prev.current.isOffline = isOffline;
    }, [isOffline]);

    useEffect(() => {
        if (!isDown && wasDown && isOffline) {
            setIsOffline(false);
        }
        prev.current.isDown = isDown;
    }, [isDown]);

    useEffect(() => {
        if (!isFluctuating && wasFluctuating && isOffline) {
            setIsOffline(false);
        }
        prev.current.isFluctuating = isFluctuating;
    }, [isFluctuating]);

    const showUnstableConnection = () => {
        return !isOffline && !operationTimedOut && isFluctuating && !isDown;
    };

    const showConnectionDown = () => {
        return !isOffline && !operationTimedOut && isDown;
    };

    return {
        operationTimedOut,
        isOffline,
        isDown,
        wasOffline,
        wasDown,
        wasFluctuating,
        isFluctuating,
        chimeIsBlocked,
        showUnstableConnection,
        showConnectionDown,
    };
}

export function OnlineOfflineDetector({children}: OnlineOfflineDetectorProps) {
    const {isOffline, isFluctuating, isDown, operationTimedOut} = useOnlineOfflineDetector();

    return (
        <>
            {children}
            <OfflineMessage isOffline={isOffline} />
            {!isOffline && !isFluctuating && !isDown && operationTimedOut && <TimeoutBackdrop />}
        </>
    );
}
