import React, {useCallback, useRef, useState, useMemo, useEffect} from "@workhorse/api/rendering";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {FlatCategItem, ActionCategory, linkItem} from "./actionCategTree";
import classes from "./style/PaletteAction.module.scss";
import {PaletteActiveCateg, paletteActiveCategDefault} from "./CommandPaletteProviders";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ProcessPaletteLink from "./ProcessPaletteLink";
import {usePaletteActionArg} from "./PaletteActionProvider";
import {setHighlightedItem} from "./CommandPalette";
import PaletteKeyIcon from "./PaletteKeyIcon";
import {isMacOS} from "@workhorse/util";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {OnboardingPaletteAction} from "../onboarding-tooltips";
import {useTranslation} from "react-i18next";

type PaletteActionProps = {
    item: FlatCategItem;
    setPaletteActiveCateg: React.Dispatch<React.SetStateAction<PaletteActiveCateg>>;
    getSearchStr: () => string;
    rootRef: HTMLDivElement | undefined;
    scrollIntoView: (node: HTMLElement, location: "start" | "end") => void;
    targetId?: string;
};

const getActionDataId = (item: FlatCategItem) => {
    switch (item?.categ) {
        case "File": {
            if (item?.title === "Upload a file") {
                return "palette-action-uploada-file";
            }
            return "palette-action-sharea-file";
            break;
        }
        case "Link": {
            return "palette-action-opena-link";
            break;
        }
        case "Tool": {
            if (item?.parentId === undefined) {
                return "palette-action-embed-a-tool";
            }
            return "palette-tools-entry-tool";
            break;
        }
        case "Agenda": {
            if (item?.title === "Add an agenda") {
                return "palette-action-addan-agenda";
            }
            return "palette-action-new-agenda";
            break;
        }
        default: {
            break;
        }
    }
};

export default function PaletteRenderOneAction(props: PaletteActionProps) {
    const {t} = useTranslation();
    const {item, setPaletteActiveCateg, getSearchStr, rootRef, scrollIntoView} = props;
    const {setActionArg} = usePaletteActionArg();
    const {categ, id, parentId, title, toolType, translateKey, icon: Icon, next, isPlaceholder, ctrlShortcut, isAction} = item;

    const isMac = isMacOS();
    const {isMobile} = useMobile();
    // this applies to placeholder actions
    // i.e. links (the item that appears AFTER a link has been validated)
    const [canShowItem, setCanShowItem] = useState(false);

    const urlHandlerRef = useRef<(() => Promise<void>) | null>(null);

    const finalActionCooldown = useRef(false);
    const finalActionCooldownTimeout = useRef<NodeJS.Timeout | null>(null);

    const setAsActiveAction = useCallback(() => {
        if (urlHandlerRef.current != null) {
            if (finalActionCooldown.current) {
                return;
            } else {
                urlHandlerRef.current();

                finalActionCooldown.current = true;
                finalActionCooldownTimeout.current = setTimeout(() => {
                    finalActionCooldown.current = false;
                }, 2000);
            }
        }

        if (item.urlBased || (item.next && item.next.length > 0 && !item.hasNoResource)) {
            setPaletteActiveCateg((current) => {
                if (item.urlBased) {
                    return {
                        ...paletteActiveCategDefault,
                        name: ActionCategory.Link,
                        id: linkItem?.id,
                        parentId: undefined,
                        prevCateg: current,
                        searchStr: undefined,
                        targetId: id,
                    };
                }

                return {
                    ...paletteActiveCategDefault,
                    id,
                    name: categ,
                    parentId,
                    highlightId: item.firstSubItemId,
                    searchStr: undefined,
                };
            });
            return;
        }

        if (!item.next || item.next.length === 0 || item.hasNoResource) {
            let actionArg = item.actionArg;
            const noResource = item.hasNoResource && item.next?.length;
            if (noResource) {
                actionArg = item.next?.[0]?.actionArg;
            }
            if (actionArg) {
                setActionArg({
                    ...actionArg,
                    actionId: noResource ? item.next?.[0]?.id : item.id,
                });
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            if (finalActionCooldownTimeout.current) {
                clearTimeout(finalActionCooldownTimeout.current);
            }
        };
    }, []);

    const setAsHighlighted: React.MouseEventHandler<HTMLDivElement> = (e) => {
        const direction = e.currentTarget.getAttribute("data-direction") as "next" | "prev" | undefined;
        if (rootRef) {
            setHighlightedItem(rootRef, item.id);
            if (direction) {
                const location = direction === "next" ? "end" : "start";
                e.currentTarget.removeAttribute("data-direction");
                scrollIntoView(e.currentTarget.parentElement!, location);
            }
        }
    };

    const checkIfHighlighted: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (!e.currentTarget.classList.contains("highlighted")) {
            setAsHighlighted(e);
        }
    };

    const displayTitle = useMemo(() => {
        if (translateKey) {
            return t(translateKey.replace("-", "."));
        }

        if (toolType) {
            return t(`artifacts.${toolType}.title`);
        }

        if (title) {
            return title;
        }

        if (isPlaceholder) {
            return getSearchStr();
        }

        return "";
    }, [translateKey, toolType, title, isPlaceholder, t, getSearchStr]);

    return (
        <>
            {isPlaceholder && canShowItem && (
                <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
                    {t("palette.result") || "Result"}
                </Typography>
            )}
            <div
                data-id="palette-action"
                className={cls(
                    "flex",
                    classes.paletteAction,
                    isPlaceholder && !canShowItem && classes.invisible,
                    classes.onHoverShowTxt,
                    isPlaceholder && !canShowItem && "invisible"
                )}
                aria-label={title}
            >
                <OnboardingPaletteAction item={item}>
                    <div
                        className={cls("flex flex-align-center flex00-100 flex-justify-between", classes.paletteActionInner)}
                        onClick={!isPlaceholder || canShowItem ? setAsActiveAction : undefined}
                        onMouseEnter={!isPlaceholder || canShowItem ? setAsHighlighted : undefined}
                        onMouseMove={!isPlaceholder || canShowItem ? checkIfHighlighted : undefined}
                        data-palette-id={item.id}
                        data-id={getActionDataId(item)}
                    >
                        {!isPlaceholder ? (
                            <Typography
                                key="no-ph"
                                component="span"
                                variant={isMobile ? "sm" : "base"}
                                fontWeight="bold"
                                color="septenary"
                                className={cls("flex flex-align-center overflow-hidden flex00-100")}
                            >
                                {Icon ? (
                                    typeof Icon === "string" ? (
                                        <LazyLoadImage width={isMobile ? 18 : 26} height={isMobile ? 18 : 26} src={Icon} />
                                    ) : (
                                        <span className={cls("flex flex-center-all", classes.icon)}>
                                            <Icon {...(isMobile ? {width: 18} : null)} />
                                        </span>
                                    )
                                ) : null}
                                <span data-id="action-title" className={classes.ellipsisTxt}>
                                    {displayTitle}
                                </span>
                                {item.artifactTag && !isMobile && (
                                    <span className={cls(classes.grey300, "hidden-lbl")}>
                                        {item.next?.length && !item.hasNoResource
                                            ? t("palette.tools.view_resources") ?? "View resources"
                                            : t("palette.tools.embed_this_tool") ?? "Embed this tool"}
                                    </span>
                                )}
                                {isAction && !isMobile && (
                                    <span className={cls(classes.grey300, "hidden-lbl")}>
                                        <PaletteKeyIcon text={`Enter`} />
                                    </span>
                                )}
                                {ctrlShortcut && !isMobile && (
                                    <div className="flex ml-auto">
                                        <PaletteKeyIcon text={isMac ? "⌘" : "Ctrl"} />
                                        <PaletteKeyIcon className="ml-5" text={isMac ? "⌃" : "Alt"} />
                                        <PaletteKeyIcon className="ml-5" text={ctrlShortcut.toUpperCase()} />
                                    </div>
                                )}
                                {((!ctrlShortcut && !isAction) || isMobile) && (
                                    <span className={cls(classes.grey300, "pt-4 hidden-icon")}>
                                        <ChevronRightIcon className={classes.rightArrowIcon} />
                                    </span>
                                )}
                            </Typography>
                        ) : (
                            <ProcessPaletteLink onURLClickRef={urlHandlerRef} setCanShowItem={setCanShowItem} targetId={props.targetId} />
                        )}
                    </div>
                </OnboardingPaletteAction>
            </div>
        </>
    );
}
