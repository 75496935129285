import loadable from "@workhorse/api/loadable";
import React from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";

const JoinedFromOtherDevice = loadable(() => import("../home/components/JoinedFromOtherDevice/JoinedFromOtherDevice"));

type Props = WithMobileState;

function DisconnectHOC(props: Props) {
    return (
        <div className="route-hoc flex-justify-center" key="disconnect-page-hoc" style={{padding: 0}} id="disconnect-page-hoc-wrapper">
            <JoinedFromOtherDevice />
        </div>
    );
}

export default DisconnectHOC;
