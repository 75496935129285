import {HostType} from "@generated/data";
import browserInfo from "@workhorse/api/BrowserInfo";
import {supportsWebRTC} from "@workhorse/api/conference2";
import React, {ReactNode, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {useLocation} from "@workhorse/api/routing";
import {WithChildren} from "@workhorse/declarations";
import UserBrowserPrompt from "@workhorse/pages/user/UserBrowserPrompt";
import {UserMobileBlocker, UserMobileBlockeriOSTablet} from "@workhorse/pages/user/UserMobileBlocker";
import {useHostType} from "./HostTypeProvider";
import {useGlobalResizeObserver} from "./ResizeObserverProvider";
import UserNativeBrowserBlocker from "@workhorse/pages/user/UserNativeBrowserBlocker";

const BrowserAndMobileChecker = (props: WithChildren) => {
    const [mobileWidth, setMobileWidth] = useState<boolean>(false);

    // TODO: @vasi/Stefana check if we can use localResizeObserver
    const onResize = (width: number) => {
        if (width < 500 && !mobileWidth) {
            setMobileWidth(true);
        }

        if (width >= 500 && mobileWidth) {
            setMobileWidth(false);
        }
    };

    useGlobalResizeObserver(onResize);

    return <Blocker mobileWidth={mobileWidth}>{props.children}</Blocker>;
};

const allowedRoutesOnMobile = [
    HostType.Player,
    HostType.Notfound,
    HostType.Home,
    HostType.Feedback,
    HostType.Waiting,
    HostType.Connect,
    HostType.Sessionended,
    HostType.Book,
    HostType.Bookings,
    HostType.BookedEvent,
    HostType.Memory,
];

function RedirectToDefaultBrowser() {
    useEffect(() => {
        window.location.href = `intent:${window.location.href}#Intent;end`;
    }, []);

    return <></>;
}

function Blocker({mobileWidth, children}: {mobileWidth: boolean; children?: ReactNode}) {
    const hostType = useHostType();
    const [userContinue, setUserContinue] = useState<boolean>(() =>
        sessionStorage.getItem("browser_unsupported_prompted") === "true" ? true : false
    );

    const handleUserContinue = () => {
        setUserContinue(true);
        sessionStorage.setItem("browser_unsupported_prompted", "true");
    };

    const device = browserInfo.info.device;
    const browser = browserInfo.info.browser;
    const os = browserInfo.info.os;
    const isInstagram = browserInfo.info.ua?.toLowerCase?.()?.includes?.("instagram");

    const isDeviceMobile = device.type === "mobile";
    const browserName = browser.name;

    const unsupportedBrowsers: string[] = ["Tor"];
    const blockedBrowsers: string[] = [];

    const location = useLocation();
    const isRouteAllowed =
        hostType === HostType.Connect ||
        hostType === HostType.Disconnect ||
        (isDeviceMobile && allowedRoutesOnMobile.includes(hostType)) ||
        location.pathname.includes("user/logout") ||
        location.pathname.includes("user/login");

    const isBrowserUnsupported = unsupportedBrowsers.includes(browserName || "");
    const isBrowserBlocked = blockedBrowsers.includes(browserName || "");

    const isIOSAndNotSafari = os.name === "iOS" && browser.name !== "Mobile Safari";

    const isDevMode = process.env.NODE_ENV === "development";
    const webRTCSupported = useMemo(() => supportsWebRTC(), []);

    const isEmbeded = location.search.includes("embed");
    const isBookPage = location.pathname.split("/")?.filter((x) => x)?.[0] === "book";

    if ((browserName === "Facebook" || isInstagram) && hostType === HostType.Player) {
        return (
            <>
                <RedirectToDefaultBrowser />
                <UserNativeBrowserBlocker showMobileRedirect={true && os.name !== "iOS"} />
            </>
        );
    }

    if (isIOSAndNotSafari && !webRTCSupported && !isDevMode && !isBookPage) {
        return <UserMobileBlockeriOSTablet />;
    }

    if (isRouteAllowed) {
        return <>{children}</>;
    }

    if (isDeviceMobile && mobileWidth && !isEmbeded && !isBookPage) {
        return <UserMobileBlocker />;
    }

    if ((isBrowserUnsupported || isBrowserBlocked) && !userContinue && !isEmbeded && !isBookPage) {
        return <UserBrowserPrompt isBlocker={isBrowserBlocked} handleUserContinue={handleUserContinue} />;
    }

    return <>{children}</>;
}

export default BrowserAndMobileChecker;
