import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {useTranslation} from "react-i18next";
import classes from "../styles/BookTroubleshootBanner.module.scss";

type BookTroubleshootBannerProps = {
    onDone(): void;
};

export const BookTroubleshootBanner = (props: BookTroubleshootBannerProps) => {
    const {onDone} = props;
    const {t} = useTranslation();

    return (
        <div className={classes.wrapper}>
            <Typography fontWeight="bolder">{t("booking.troubleshoot_in_progress")}</Typography>
            <div className={classes.actions}>
                <Link
                    href={
                        "https://resources.sessions.us/resources/help-center/account-settings-tech-guide/troubleshoot-for-the-booking-page"
                    }
                    className={classes.link}
                    target="_blank"
                >
                    {t("g.learn_more")}
                </Link>
                <Button size="smallest" variant="tertiary" onClick={onDone}>
                    {t("g.done")}
                </Button>
            </div>
        </div>
    );
};
