import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import NotificationWrapper from "./NotificationWrapper";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import {cls} from "@ui/cdk/util";
import Typography from "@ui/cdk/Typography";
import classes from "./style/RecordingInformation.module.scss";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useTranslation} from "react-i18next";
import {SessionRecordingType} from "@generated/data";

const RecordingInformation = () => {
    const {t} = useTranslation();

    const {isActive, recordingType} = useRecordingProcessor();

    const lastShownRef = useRef<"started" | "stopped" | null>(null);
    const [notification, setNotification] = useState<"started" | "stopped" | null>(null);

    const timeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const lastShown = lastShownRef.current;

        if (isActive && lastShown !== "started") {
            timeout.current && clearTimeout(timeout.current);
            lastShownRef.current = "started";
            setNotification("started");
            return;
        }

        if (lastShown === "started") {
            lastShownRef.current = "stopped";
            setNotification("stopped");

            timeout.current = setTimeout(() => {
                setNotification(null);
            }, 3000);
        }
    }, [isActive]);

    useEffect(() => {
        return () => {
            timeout.current && clearTimeout(timeout.current);
        };
    }, []);

    const handleCloseNotification = () => {
        setNotification(null);
    };

    if (notification === "started") {
        const transKey =
            recordingType === SessionRecordingType.Livestream
                ? "participant.livestreaming.start_notification"
                : recordingType === SessionRecordingType.Recording
                ? "participant.recording.start_notification"
                : "participant.livestreaming_recording.start_notification";

        return (
            <NotificationWrapper onClose={handleCloseNotification}>
                <RadioButtonCheckedRoundedIcon className={cls(classes.icon, classes.iconStarted)} />
                <Typography>{t(transKey)}</Typography>
            </NotificationWrapper>
        );
    }

    if (notification === "stopped") {
        const transKey =
            recordingType === SessionRecordingType.Livestream
                ? "participant.livestreaming.stop_notification"
                : recordingType === SessionRecordingType.Recording
                ? "participant.recording.stop_notification"
                : "participant.livestreaming_recording.stop_notification";

        return (
            <NotificationWrapper onClose={handleCloseNotification}>
                <RadioButtonCheckedRoundedIcon className={cls(classes.icon, classes.iconStopped)} />
                <Typography>{t(transKey)}</Typography>
            </NotificationWrapper>
        );
    }

    return null as unknown as JSX.Element;
};

export default RecordingInformation;
