import {AgendaItem, AgendaTemplateFragment} from "@generated/data";
import {cls} from "@ui/cdk/util";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {commitCreateAgendaTemplate} from "@workhorse/api/designer/methods";
import {FuseOptions, useFuseWorker} from "@workhorse/api/fuse";
import {useCallback, useMemo, useState} from "@workhorse/api/rendering";
import {useParams} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {useUserInfo} from "@workhorse/providers/User";
import AgendaTemplatesSectionHeader from "../../AgendaTemplatesHeader/AgendaTemplatesSectionHeader/AgendaTemplatesSectionHeader";
import {createTemplatesComparator, getTemplate, TemplatesParams} from "../../utils";
import AgendaTemplatesTableBody from "./AgendaTemplatesTableBody";
import AgendaTemplatesTableEmptyStates from "./AgendaTemplatesTableEmptyStates";
import AgendaTemplatesTableHead from "./AgendaTemplatesTableHead";
import classes from "./style/AgendaTemplatesTable.module.scss";

interface Props {
    title: string;
    agendaTemplates: AgendaTemplateFragment[];
    onClickAgenda: (agendaTemplateId?: string) => void;
}

export type AgendaTemplateSortField = "name" | "duration" | "updatedAt" | "owner";

export type AgendaTemplateSort = {
    field: AgendaTemplateSortField;
    direction: "asc" | "desc";
};

const fuseOptions: FuseOptions<AgendaTemplateFragment> = {
    keys: ["name"],
    threshold: 0.3,
    shouldSort: true,
};

export function AgendaTemplatesTable(props: Props) {
    const {section} = useParams<TemplatesParams>();

    const [search, setSearch] = useState("");
    const [ownerFilter, setOwnerFilter] = useState<string[]>([]);
    const [sort, setSort] = useState<AgendaTemplateSort>({field: "name", direction: "asc"});

    const user = useUserInfo();

    const setEditState = designer.state.update;

    const sorted = useMemo(() => {
        const initialSorted = props.agendaTemplates.slice().sort(createTemplatesComparator(sort));
        const ownerFiltered =
            ownerFilter.length === 0 ? initialSorted : initialSorted.filter((agenda) => ownerFilter.includes(agenda.user.id));
        const sectionFiltered = ownerFiltered.filter((agenda) => {
            if (section === "my-agendas") {
                return agenda.user.id === user.id;
            }

            if (section === "workspace") {
                return agenda.isPublic;
            }

            if (section === "shared") {
                return agenda.user.id !== user.id && agenda.hasEditorAccess;
            }
        });

        return sectionFiltered;
    }, [sort, ownerFilter, props.agendaTemplates]);

    const [filteredSorted] = useFuseWorker(sorted, search, fuseOptions);

    const clearSearch = useCallback(() => {
        setSearch("");
    }, []);

    useMemo(() => {
        clearSearch();
        setOwnerFilter([]);
    }, [clearSearch, section]);

    const isSearching = search !== "";
    const noTemplates = sorted.length === 0;

    const agendaOwners = props.agendaTemplates
        .map((template) => template.user)
        .filter((owner, index, self) => {
            return self.findIndex((o) => o.id === owner.id) === index;
        });

    const hasActionsColumn = true;

    const duplicateAgendaTemplate = async (id: string) => {
        designer.state.setDesignerCommitState(true);
        const result = await getTemplate(id);
        const template = result.data.agendaTemplate;
        if (!template) {
            return;
        }
        const templateId = makeId();
        const newTitle = template.name + " Copy";
        designer.state.initializeOrResetState(templateId + "_template");
        const create = designer.api.session.create({
            session: {
                name: newTitle,
                id: templateId + "_template",
            },
            agendaItems: [],
            hasAgenda: true,
            isTemplate: true,
        });
        setEditState({
            sessionId: create,
            templateMode: true,
            dirty: true,
        });
        designer.api.session.importAgenda({
            session: {
                description: "",
                name: "",
                createdFromTemplateId: template?.id,
            },
            agendaItems: template?.agendaItems.length ? (template?.agendaItems as unknown as DeepMandatoryWithNullVals<AgendaItem>[]) : [],
            macroArtifacts: [],
            bypassResourcePublicCheck: template?.isPublic,
            callback: async () => {
                await commitCreateAgendaTemplate();
                toast("Agenda duplicated successfully!", {
                    type: "success",
                    position: "top",
                    duration: 3000,
                });
                props.onClickAgenda(templateId);
            },
        });
    };

    return (
        <>
            <AgendaTemplatesSectionHeader
                owners={agendaOwners}
                ownerFilter={ownerFilter}
                setOwnerFilter={setOwnerFilter}
                noOfAgendas={props.agendaTemplates.length}
                tagName={props.title}
            />
            <div className={cls(classes.scrollContainer, "flex flex-col flex11-auto overflow-hidden")}>
                <TableContainer className="flex flex-col fullh fullw flex00-100 overflow-hidden">
                    <Table className={cls(classes.tableRoot, "fullh flex flex-col flex00-100 overflow-hidden")}>
                        <AgendaTemplatesTableHead
                            searchTerm={search}
                            onSearch={setSearch}
                            disabledSearch={noTemplates}
                            hasActionsColumn={hasActionsColumn}
                            className={classes.tableHead}
                            tagName={props.title}
                            sort={sort}
                            onSort={setSort}
                        />
                        {useMemo(() => {
                            return filteredSorted.length > 0 ? (
                                <AgendaTemplatesTableBody
                                    agendaTemplates={filteredSorted}
                                    hasActionsColumn={hasActionsColumn}
                                    onClickAgenda={props.onClickAgenda}
                                    isSearching={isSearching}
                                    tagName={props.title}
                                    duplicateAgendaTemplate={duplicateAgendaTemplate}
                                />
                            ) : (
                                <AgendaTemplatesTableEmptyStates isSearching={isSearching} />
                            );
                        }, [filteredSorted, hasActionsColumn, props.onClickAgenda, props.title, isSearching])}
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
