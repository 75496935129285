import {makeVar} from "@workhorse/api/data";

export const actionProfile = makeVar<Action | undefined>(undefined);
export const routeNavigation = makeVar<string | undefined>(undefined);
export const enableEndSessionModal = makeVar<boolean>(false);
export const enableEventLeaveModal = makeVar<boolean>(false);
export const enableRoomLeaveModal = makeVar<boolean>(false);
export const enableBookingLeaveModal = makeVar<boolean>(false);
export const enableFeedbackFormLeaveModal = makeVar<boolean>(false);

export enum Action {
    logout = "logout",
    manageAccount = "manageAccount",
}
