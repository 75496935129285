import {cls} from "@ui/cdk/util/util";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {WithLayoutState} from "@workhorse/declarations";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useKeyboard} from "@workhorse/providers/KeyboardProvider";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {useDrawerLeftWidth} from "@workhorse/providers/useDrawerLeftWidth";
import FooterRightPortal from "./components/FooterRightPortal";
import FooterPortal from "./components/FooterPortal";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";
import classes from "./styles/Footer.module.scss";
import {HostType} from "@generated/data";
import {useHostType} from "@workhorse/providers/HostTypeProvider";

type FooterProps = WithLayoutState;

function Footer(props: FooterProps) {
    const {isFullScreen, mobileState} = props;
    const [drawerLeftState] = useDrawerLeftToggler();
    const drawerLeftWidth = useDrawerLeftWidth();

    const {isMobile} = mobileState;
    const {isPortrait} = useDeviceOrientation();
    const {isKeyboardOpen} = useKeyboard();

    const hostType = useHostType();

    const {isSessionsUIHidden, sessionsUIFooter, sessionsUIFooterHovered, panelCooldown, hoverSessionsUIPanel, unhoverSessionsUIPanel} =
        useSessionsUIToggle();

    const [portalClassName, setPortalClassName] = useState<string>("");
    const [portalRightClassName, setPortalRightClassName] = useState<string>("");

    const handleHoverOn = () => {
        if (!panelCooldown) {
            hoverSessionsUIPanel("footer");
        }
    };

    const handleHoverOff = () => {
        unhoverSessionsUIPanel("footer");
    };

    const hideFooter = isMobile && ((isFullScreen && !isPortrait) || isKeyboardOpen);

    const footerClassNames = cls(
        "app-footer",
        isSessionsUIHidden && classes.rootSessionsUi,
        isSessionsUIHidden && (sessionsUIFooter || (sessionsUIFooterHovered && !panelCooldown)) && classes.rootSessionsUiOpen,
        portalClassName,
        portalRightClassName,
        hideFooter && "hidden-but-displayed",
        isMobile && hostType === HostType.Memory && "app-footer-memory"
    );

    const paddingLeft = drawerLeftWidth[drawerLeftState.state] && !isMobile ? drawerLeftWidth[drawerLeftState.state] + 15 : 0;

    const style = useMemo(() => {
        return {
            paddingLeft,
        } as React.CSSProperties;
    }, [paddingLeft]);

    return (
        <footer
            id={"app-footer"}
            className={footerClassNames}
            onMouseOver={handleHoverOn}
            onMouseLeave={handleHoverOff}
            aria-label="footer"
        >
            <div
                className={cls(
                    "flex flex00-100 relative fullh",
                    !isMobile && isFullScreen && "pl-12",
                    isSessionsUIHidden && !isFullScreen && "flex-align-center",
                    isFullScreen && !isSessionsUIHidden && "flex-align-start"
                )}
            >
                <FooterPortal setClassName={setPortalClassName} />
                <FooterRightPortal setClassName={setPortalRightClassName} />
            </div>
        </footer>
    );
}

export default Footer;
