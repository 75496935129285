import {useCallback, useMemo} from "@workhorse/api/rendering";
import {GetEvernoteAuthUrlDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useLoginPopup, UseLoginPopupMessage} from "./login-popup";
import {useMutation} from "@workhorse/dataApi";

export type UseEvernoteLoginProps = {
    onLogin?: () => void;
    onLoginFailure?: (message: UseLoginPopupMessage) => void;
    onLogout?: () => void;
    onLogoutFailure?: () => void;
};

export const useEvernoteLogin = (props: UseEvernoteLoginProps) => {
    const {onLogin, onLoginFailure, onLogout, onLogoutFailure} = props;

    const requestLogin = useLoginPopup({eventName: "evernote-login", onLogin, onLoginFailure});

    const [removeToken] = useMutation("DeleteUserEvernoteProvidersDocument");

    const request = useCallback(async () => {
        const evernoteAuthUrl = await apollo.client.query({
            query: GetEvernoteAuthUrlDocument,
            fetchPolicy: "network-only",
        });

        const url = evernoteAuthUrl.data.getEvernoteAuthUrl;

        if (!url) {
            onLoginFailure?.(UseLoginPopupMessage.Error);
            return;
        }

        await requestLogin(url);
    }, [onLoginFailure, requestLogin]);

    const disconnect = useCallback(() => {
        removeToken()
            .then((response) => {
                if (response.data?.deleteUserEvernoteProviders) {
                    onLogout?.();
                }
            })
            .catch(() => {
                onLogoutFailure?.();
            });
    }, [onLogout, onLogoutFailure, removeToken]);

    return useMemo(() => [request, disconnect], [request, disconnect]);
};
