import {PublicBookingEventAvailableSlotFragment, PublicBookingEventCollaborator} from "@generated/data";
import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import TimeRange from "@ui/cdk/TimeRange";
import {cls} from "@ui/cdk/util";
import {useMemo, useState} from "@workhorse/api/rendering";
import {addMinutes, subMinutes} from "date-fns";
import {useTranslation} from "react-i18next";
import classes from "../styles/BookTimeSlots.module.scss";

const conflicts = {
    PAST: {
        label: "PAST",
        description: "booking.conflict.past_description",
    },
    RULE_SCHEDULE_TO: {
        label: "Availability",
        description: "booking.conflict.rule_schedule_to_description",
    },
    RULE_SCHEDULE_WINDOW: {
        label: "Availability",
        description: "booking.conflict.rule_schedule_window_description",
    },
    RULE_SCHEDULE_DAY_UNAVAILABLE: {
        label: "Schedule",
        description: "booking.conflict.rule_schedule_day_unavailable_description",
    },
    RULE_MIN_BOOKING_WINDOW: {
        label: "Limits",
        description: "booking.conflict.rule_min_booking_window_description",
    },
    RULE_MAX_SESSIONS_PER_DAY: {
        label: "Limits",
        description: "booking.conflict.rule_max_sessions_per_day_description",
    },
    CONFLICT_EXTERNAL_CALENDAR: {
        label: "Conflict",
        description: "booking.conflict.conflict_external_calendar_description",
        description_collaborators: "booking.conflict.conflict_external_calendar_description_collaborators",
    },
    CONFLICT_SESSIONS: {
        label: "Conflict",
        description: "booking.conflict.conflict_sessions_description",
    },
} as const;

type FormattedConflicts = {
    labels: string[];
    descriptions: Record<string, string[]>;
};

type BookTimeTroubleshootSlotProps = {
    slot: PublicBookingEventAvailableSlotFragment;
    isHalfDayClock?: boolean;
    offset: number;
    duration: number;
    collaborators?: PublicBookingEventCollaborator[];
    bookingEventId: string;
};

const BookTimeTroubleshootSlot = (props: BookTimeTroubleshootSlotProps) => {
    const {slot, offset, duration, isHalfDayClock, collaborators, bookingEventId} = props;
    const [detailsOpen, setDetailsOpen] = useState(false);
    const {t} = useTranslation();

    const hasMultipleCollaborators = (collaborators?.length ?? 0) > 1;

    const {labels, descriptions} = useMemo(() => {
        if (!slot.conflicts?.length) return {labels: [], descriptions: {}} as FormattedConflicts;
        const labels = new Set<string>();
        const descriptions: Record<string, string[]> = {};

        for (const conflict of slot.conflicts) {
            const content = conflict ? conflicts[conflict as keyof typeof conflicts] : null;

            if (content) {
                labels.add(content.label);
                descriptions[content.label] = descriptions[content.label] ?? [];
                descriptions[content.label].push(
                    hasMultipleCollaborators && "description_collaborators" in content
                        ? content.description_collaborators
                        : content.description
                );
            }
        }

        return {labels: Array.from(labels), descriptions};
    }, [slot.conflicts, hasMultipleCollaborators]);

    const settingsUrl = `${window.location.origin}/booking-event/${bookingEventId.replace("p-", "")}`;

    return (
        <div className={classes.troubleshootSlot}>
            <div>
                <TimeRange
                    start={subMinutes(new Date(slot.day), offset)}
                    end={addMinutes(new Date(slot.day), duration - offset)}
                    isHalfDayClock={isHalfDayClock}
                    className={classes.troubleshootSlotTimeRange}
                />
            </div>
            <div
                className={cls(
                    classes.troubleshootSlotStatus,
                    slot.available === false ? classes.troubleshootSlotStatusUnavailable : classes.troubleshootSlotStatusAvailable
                )}
            >
                {slot.available === false ? t("g.unavailable") : t("g.available")}
            </div>
            {slot.available === false && labels.length ? (
                <>
                    <div className={classes.troubleshootSlotConflicts}>
                        {labels.map((label) => (
                            <span className={classes.troubleshootSlotConflictsBadge} key={label}>
                                {label}
                            </span>
                        ))}
                    </div>
                    <div className={classes.troubleshootSlotDescription}>
                        <div className={classes.troubleshootSlotDescriptionAction}>
                            <Button variant="plain" size="smallest" onClick={() => setDetailsOpen((prev) => !prev)}>
                                {detailsOpen ? t("g.hide_info") : t("g.more_info")}
                            </Button>
                        </div>
                        {detailsOpen ? (
                            <div>
                                {Object.keys(descriptions).map((key) => (
                                    <div key={key} className={classes.troubleshootSlotDescriptionItem}>
                                        <div className={classes.troubleshootSlotDescriptionItemLabel}>{key}</div>
                                        <ul className={classes.troubleshootSlotDescriptionItemList}>
                                            {descriptions[key].map((i) => (
                                                <li key={i}>{t(i)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                                <div className={classes.troubleshootSlotDescriptionAction}>
                                    <Link href={settingsUrl} className={classes.troubleshootSlotDescriptionActionLink} icon target="_blank">
                                        {t("g.go_to_settings")}
                                    </Link>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            ) : (collaborators?.length ?? 0) > 1 ? (
                <div className={classes.troubleshootSlotCollaborators}>
                    {slot.collaborators?.map((id) => collaborators?.find((c) => c.collaboratorId === id)?.userName ?? "").join(", ")}
                </div>
            ) : null}
        </div>
    );
};

export default BookTimeTroubleshootSlot;
