import MicRoundedIcon from "@material-ui/icons/MicRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import Button from "@ui/cdk/Button";
import MenuItem from "@ui/cdk/MenuItem";
import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Popover from "@ui/core/components/Popover";
import Select from "@ui/core/components/Select";
import {Fragment, useState, useEffect, useRef} from "@workhorse/api/rendering";
import {LocalAudioInputPreview, AudioOutputPreview, AudioPreviewIcon} from "@workhorse/components/audio-preview";
import {WithClassName} from "@workhorse/declarations";
import classes from "./styles/PreJoinConferenceSettingsToggler.module.scss";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import {usePreJoinSettings} from "../providers/PreJoinSettingsProvider";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {browserBehavior} from "@workhorse/api/conference2";
import Tooltip from "@ui/cdk/Tooltip";
import HelpOutlined from "@material-ui/icons/HelpOutlined";
import {useTranslation} from "react-i18next";

type PreJoinConferenceSettingsTogglerProps = {
    classes?: {
        active?: string;
        disabled?: string;
    };
    isDisabled?: boolean;
    voiceFocusEnabled?: boolean;
    isVoiceFocusSupported?: boolean;
    audioInput: string;
    audioOutput: string;
    videoInput: string;
    audioInputs: MediaDeviceInfo[];
    audioOutputs: MediaDeviceInfo[];
    videoInputs: MediaDeviceInfo[];
    onChangeAudioInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAudioOutput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeVideo: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onVoiceFocusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isMobile: boolean;
} & WithClassName;

const PreJoinConferenceSettingsToggler = (props: PreJoinConferenceSettingsTogglerProps) => {
    const {t} = useTranslation();
    const {isPortrait} = useDeviceOrientation();
    const {menuOpen, setMenuOpen} = usePreJoinSettings();

    const [outputPlaying, setOutputPlaying] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);

    const anchorEl = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (!menuOpen) {
            setOutputPlaying(false);
        }
    }, [menuOpen]);

    useEffect(() => {
        if (!isPortrait && props.isMobile && menuOpen) {
            setMenuOpen(false);
        }
    }, [isPortrait, props.isMobile, menuOpen, setMenuOpen]);

    const handleChangeTab = (tab: number) => {
        setActiveTab(tab);
        setOutputPlaying(false);
    };

    const canChangeOutput = browserBehavior.supportsSetSinkId() && props.audioOutputs.length > 0;

    return (
        <Fragment key="pre-join-conference-settings">
            <MenuItem
                data-id="prejoin-button-settings"
                ref={anchorEl}
                button
                className={cls(props.className, menuOpen && props.classes?.active, props.isDisabled && props.classes?.disabled)}
                onClick={() => setMenuOpen((current) => !current)}
                disabled={props.isDisabled}
                key="pre-join-conference-settings-btn"
            >
                <SettingsIcon className={classes.buttonIcon} key="pre-join-conference-settings-icon" />
                <Typography className={classes.buttonLabel} key="pre-join-conference-settings-label">
                    {t("prejoin.settings")}
                </Typography>
            </MenuItem>
            {menuOpen ? (
                <Popover
                    key="pre-join-conference-settings-popover"
                    open={menuOpen}
                    anchorEl={anchorEl.current}
                    onClose={() => setMenuOpen(false)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <div className={classes.header} key="pre-join-conference-settings-header">
                        <Button
                            variant="plain"
                            onClick={handleChangeTab.bind(null, 0)}
                            className={cls(classes.headerBtn, activeTab === 0 && classes.headerBtnActive)}
                            key="pre-join-conference-settings-header-audio"
                        >
                            <MicRoundedIcon key="pre-join-conference-settings-header-audio-icon" />
                            <Typography fontWeight="bold" key="pre-join-conference-settings-header-audio-text">
                                {t("participant.mic.audio")}
                            </Typography>
                        </Button>
                        <Button
                            variant="plain"
                            onClick={handleChangeTab.bind(null, 1)}
                            className={cls(classes.headerBtn, activeTab === 1 && classes.headerBtnActive)}
                            key="pre-join-conference-settings-header-video"
                        >
                            <VideocamRoundedIcon key="pre-join-conference-settings-header-audio-icon" />
                            <Typography fontWeight="bold" key="pre-join-conference-settings-header-audio-text">
                                {t("participant.camera.video")}
                            </Typography>
                        </Button>
                    </div>
                    {activeTab === 0 ? (
                        <div className={classes.body} key="pre-join-conference-settings-body-0">
                            <div className={classes.category} key="pre-join-conference-settings-mic">
                                <Typography variant="sm" fontWeight="bold" component="label" key="pre-join-conference-settings-mic-label">
                                    {t("participant.mic.title")}
                                </Typography>
                                {props.audioInputs.length > 0 ? (
                                    <Fragment key="pre-join-conference-settings-mic-inputs">
                                        <Select
                                            onChange={props.onChangeAudioInput}
                                            value={props.audioInput}
                                            className={classes.menuSelect}
                                            IconComponent={ArrowDropDownRoundedIcon}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center",
                                                },
                                                getContentAnchorEl: null,
                                                classes: {
                                                    paper: classes.menuSelectPaper,
                                                },
                                            }}
                                            disableUnderline
                                            key="pre-join-conference-settings-mic-selector"
                                        >
                                            {props.audioInputs.map((device) => (
                                                <MenuItem key={device.deviceId} value={device.deviceId} className={classes.menuItem}>
                                                    {device.deviceId === "default" ? t("participant.mic.system_default_mic") : device.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <LocalAudioInputPreview
                                            className={classes.audioPreview}
                                            key="pre-join-conference-settings-mic-preview"
                                        />
                                    </Fragment>
                                ) : (
                                    <Typography key="pre-join-conference-settings-mic-none">
                                        {t("participant.mic.no_mic_detected")}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.category} key="pre-join-conference-settings-speakers">
                                <Typography
                                    variant="sm"
                                    fontWeight="bold"
                                    component="label"
                                    key="pre-join-conference-settings-speakers-label"
                                >
                                    {t("participant.mic.speakers.title")}

                                    {!canChangeOutput && (
                                        <Tooltip title={t("participant.mic.info_tooltip_device_select_not_supported") ?? ""}>
                                            <HelpOutlined className={classes.hint} fontSize="small" />
                                        </Tooltip>
                                    )}
                                </Typography>

                                <Fragment key="pre-join-conference-settings-speakers-outputs">
                                    <Select
                                        onChange={canChangeOutput ? props.onChangeAudioOutput : () => undefined}
                                        defaultValue={canChangeOutput ? props.audioOutput : "default"}
                                        className={cls(classes.menuSelect, classes.audio)}
                                        IconComponent={(props) => (
                                            <>
                                                <AudioPreviewIcon onClick={() => setOutputPlaying(true)} />
                                                <ArrowDropDownRoundedIcon {...props} />
                                            </>
                                        )}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                            },
                                            getContentAnchorEl: null,
                                            classes: {
                                                paper: classes.menuSelectPaper,
                                            },
                                        }}
                                        disableUnderline
                                        key="pre-join-conference-settings-speakers-select"
                                    >
                                        {canChangeOutput &&
                                            props.audioOutputs.map((device) => (
                                                <MenuItem key={device.deviceId} value={device.deviceId} className={classes.menuItem}>
                                                    {device.deviceId === "default"
                                                        ? t("participant.mic.speakers.system_default_speakers")
                                                        : device.label}
                                                </MenuItem>
                                            ))}
                                        {!canChangeOutput && (
                                            <MenuItem value="default" className={classes.menuItem}>
                                                {t("participant.mic.speakers.system_default_speakers")}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <AudioOutputPreview
                                        deviceId={props.audioOutput}
                                        playing={outputPlaying}
                                        setPlaying={setOutputPlaying}
                                        className={classes.audioPreview}
                                        key="pre-join-conference-settings-speakers-preview"
                                    />
                                </Fragment>
                            </div>
                            {props.isVoiceFocusSupported && (
                                <div
                                    className={cls("fullw my-8 flex flex-items-center flex-justify-between", classes.category)}
                                    key="pre-join-conference-settings-noise"
                                >
                                    <Typography
                                        component="label"
                                        variant="sm"
                                        fontWeight="bold"
                                        key="pre-join-conference-settings-noise-label"
                                    >
                                        {t("participant.mic.noise_reduction")}
                                    </Typography>
                                    <Switch
                                        color="primary"
                                        checked={props.voiceFocusEnabled ?? false}
                                        onChange={props.onVoiceFocusChange}
                                        key="pre-join-conference-settings-speakers-switch"
                                        aria-label={
                                            t(
                                                props.voiceFocusEnabled
                                                    ? "aria_announcer.noise_reduction_enabled"
                                                    : "aria_announcer.noise_reduction_disabled"
                                            ) ?? ""
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    ) : activeTab === 1 ? (
                        <div className={classes.body} key="pre-join-conference-settings-body-1">
                            <div className={classes.category} key="pre-join-conference-settings-camera">
                                {props.videoInputs.length > 0 ? (
                                    <Fragment key="pre-join-conference-settings-camera-inputs">
                                        <Typography
                                            variant="sm"
                                            fontWeight="bold"
                                            component="label"
                                            key="pre-join-conference-settings-camera-label"
                                        >
                                            {t("participant.camera.title")}
                                        </Typography>
                                        <Select
                                            onChange={props.onChangeVideo}
                                            value={props.videoInput}
                                            className={classes.menuSelect}
                                            IconComponent={ArrowDropDownRoundedIcon}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center",
                                                },
                                                getContentAnchorEl: null,
                                                classes: {
                                                    paper: classes.menuSelectPaper,
                                                },
                                            }}
                                            disableUnderline
                                            key="pre-join-conference-settings-camera-select"
                                        >
                                            {props.videoInputs.map((device) => (
                                                <MenuItem key={device.deviceId} value={device.deviceId} className={classes.menuItem}>
                                                    {device.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Fragment>
                                ) : (
                                    <Typography key="pre-join-conference-settings-camera-none">
                                        {t("participant.camera.no_camera_detected")}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    ) : null}
                    {props.isMobile ? (
                        <Button variant="ghost" className={classes.cancelButtonMobile} onClick={() => setMenuOpen(false)}>
                            {t("g.cancel")}
                        </Button>
                    ) : null}
                </Popover>
            ) : null}
        </Fragment>
    );
};

export default PreJoinConferenceSettingsToggler;
