import {useEffect} from "react";
import {useDevices} from "../..";
import {RemoteAudio} from "../../components/RemoteAudio";
import {useRoomStore} from "../../providers/ConferenceRoomProvider";
import {supportsWebAudio} from "../DeviceManager/AudioContext";

function useAudioIds() {
    const value = useRoomStore((state) => state.publicationMap);
    const ids: string[] = Object.keys(value)
        .filter((id) => !!value[id])
        .sort((a, b) => a.localeCompare(b));
    return ids;
}

export function AudioOutputIndividualStreamsController() {
    const ids = useAudioIds();
    const {canSetSpeaker, getCurrentSpeakerDevice} = useDevices();
    const device = getCurrentSpeakerDevice();
    const {deviceManager} = useDevices();

    useEffect(() => {
        if (!supportsWebAudio() || !canSetSpeaker) {
            return;
        }

        if (!device) {
            return;
        }

        try {
            const id = device === "default" ? "" : device;
            // @ts-expect-error no type
            deviceManager.audioContext.setSinkId(id).catch((e) => console.log(e));
        } catch (e) {
            console.log("Sink id most likely not supported", e);
        }
    }, [deviceManager, device, canSetSpeaker]);

    return (
        <>
            {ids.map((id) => (
                <RemoteAudio key={id} participantId={id} sinkId={device} supportsSetSinkId={canSetSpeaker} />
            ))}
        </>
    );
}
