export const beautifyRoleName = (roleName: string) => {
    switch (roleName) {
        case "OWNER":
            return "Owner";
        case "EDITOR":
            return "Editor";
        case "MEMBER":
            return "Member";
        default:
            return roleName;
    }
};
