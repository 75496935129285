import {isEditorEmpty, mapToEditorParagraph, parseAsTipTapJSON} from "@api/editor";
import {AcceptedTimezone, getLocalTimezone} from "@common/utils/timezones";
import {bookEventValidationSchema} from "@common/validation/book-event.validation";
import {emailRegex} from "@common/validation/utils";
import {PublicBookingEventFragment} from "@generated/data";
import {cls} from "@ui/cdk/util";
import {
    QuestionnareForm,
    ScheduleBookingInput,
    bookSlot,
    setParentNotification,
    useParentNotification,
    useBookingView,
    windowRedirect,
} from "@workhorse/api/booking";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {useUserInfo} from "@workhorse/providers/User";
import {Formik, FormikProps} from "formik";
import {useHistory, useLocation} from "react-router-dom";
import classes from "../styles/BookEvent.module.scss";
import BookEventDateTime from "./BookEventDateTime";
import {BookEventDetails} from "./BookEventDetails";
import {BookEventDuration} from "./BookEventDuration";
import {useTranslation} from "react-i18next";
import {BookEventCheckout} from "./BookEventCheckout";
import BookEventContentWrapper from "./BookEventContentWrapper";
import {is24HTimeFormat} from "@workhorse/api/booking";
import BookPreviewNotification from "./BookPreviewNotification";

type BookEventProps = {
    event: PublicBookingEventFragment;
    view?: "page" | "component" | "widget";
    shouldRedirect?: boolean;
    onCancel?: () => void;
    onBook?: () => void;
    isBookADemo?: boolean;
};

export enum BookingStep {
    DURATION = "duration",
    DATE_TIME = "date-time",
    DETAILS = "details",
    QUESTIONNARE = "questionnare",
    CHECKOUT = "checkout",
}

export const BookEvent = ({event, view, shouldRedirect, isBookADemo, onCancel, onBook}: BookEventProps) => {
    const [step, setStep] = useState<BookingStep>(() => (event.additionalDuration?.length ? BookingStep.DURATION : BookingStep.DATE_TIME));
    const [timezone, setTimezone] = useState(getLocalTimezone() as string);
    const [duration, setDuration] = useState(event.duration);
    const [bookingError, setBookingError] = useState<string | null>(null);
    const [bookingTimedOut, setBookingTimedOut] = useState(false);
    const history = useHistory();
    const [{nonce}] = useBookingView();
    const {t, i18n} = useTranslation();
    const userInfo = useUserInfo();
    const {search} = useLocation();

    const isPreview = useMemo(() => {
        const params = new URLSearchParams(search);
        if (params.get("preview") !== "true") {
            return false;
        }

        return event.collaborators.some((c) => c.userId === userInfo.id) || event.ownerId === userInfo.id;
    }, [search, userInfo.id, event.ownerId, event.collaborators]);

    const [isHalfDayClock, setIsHalfDayClock] = useState(() =>
        userInfo.role === "USER" ? userInfo.halfDayClock ?? !is24HTimeFormat() : event.ownerHalfDayClock ?? !is24HTimeFormat()
    );

    const isTroubleshootMode = useMemo(() => {
        const params = new URLSearchParams(search);
        if (params.get("troubleshoot") !== "true") {
            return false;
        }

        return event.collaborators.some((user) => user.userId === userInfo.id) || event.ownerId === userInfo.id;
    }, [search, userInfo.id, event.ownerId, event.collaborators]);

    useEffect(() => {
        if (userInfo.role === "USER") {
            setIsHalfDayClock(userInfo.halfDayClock ?? !is24HTimeFormat());
        } else {
            setIsHalfDayClock(event?.ownerHalfDayClock ?? !is24HTimeFormat());
        }
    }, [userInfo, i18n.language]);

    const bookWrapperRef = useRef<HTMLDivElement>(null);

    const initialValues: ScheduleBookingInput = useMemo(() => {
        return {
            date: new Date(),
            additionalInformation: "",
            guests: [],
            questionnare: {},
            availableAssignees: [],
            paymentProvider: event.payments?.length ? event.payments[0]?.paymentProviders?.[0]?.name : undefined,
            paymentTerms: event.payments?.[0]?.terms ? isEditorEmpty(parseAsTipTapJSON(event.payments[0].terms)) : true,
        };
    }, [event]);

    const formImperativeRef = useRef<FormikProps<ScheduleBookingInput>>(null);
    const pendingGuestRef = useRef("");
    const maxComponentHeight = useRef<null | number>(null);

    const handleOnStepChange = (newStep: BookingStep) => {
        if (newStep !== BookingStep.DATE_TIME) {
            setBookingError(null);
            setBookingTimedOut(false);
        }
        setStep(newStep);
    };

    const handleOnScheduleBooking = async (values: ScheduleBookingInput) => {
        setBookingTimedOut(false);
        setBookingError(null);
        if (!event.id) {
            return true;
        }

        const guests = values.guests;
        if (pendingGuestRef.current) {
            if (emailRegex.test(pendingGuestRef.current)) {
                guests.push(pendingGuestRef.current);
            } else {
                const label = (event.questionnare as QuestionnareForm)?.formItems?.find((item) => item.type === "Guests")?.question;

                formImperativeRef.current?.setErrors({guests: t("g.form.needs_to_be_valid_email", {label}) ?? ""});
                return true;
            }
            pendingGuestRef.current = "";
        }

        await bookSlot(
            {
                id: event.id,
                timeSlot: values.date,
                duration: duration,
                timeZone: timezone,
                additionalInformation: mapToEditorParagraph(values.additionalInformation),
                questionnare: values.questionnare ?? {},
                availableAssignees: values.availableAssignees,
                guests,
                paymentProvider: values.paymentProvider,
                isPreview,
            },
            timezone as AcceptedTimezone,
            {
                shouldRetry: false,
                abortAfterMs: 35000,
                timedOutCb: (failed, retryAttemptNo) => {
                    setBookingTimedOut((c) => (c !== failed ? failed : c));
                    if (!failed) {
                        setBookingError(null);
                    }
                },
            }
        )
            .then((res) => {
                if ("error" in res) {
                    return setBookingError(res.error);
                }

                if (res.requestPayment && res.checkoutUrl) {
                    return windowRedirect(res.checkoutUrl);
                }

                onBook?.();

                if (event.afterRegistrationRedirectUrl) {
                    try {
                        let redirectUrl: string;
                        if (
                            event.afterRegistrationRedirectUrl.startsWith("http") ||
                            event.afterRegistrationRedirectUrl.startsWith("https")
                        ) {
                            redirectUrl = event.afterRegistrationRedirectUrl;
                        } else {
                            redirectUrl = `https://${event?.afterRegistrationRedirectUrl}`;
                        }
                        const url = new URL(redirectUrl);
                        windowRedirect(url.href, true);
                        return;
                    } catch (err) {
                        console.error("error on creating or accessing redirect url", err);
                    }
                }

                if (shouldRedirect !== false) {
                    history.replace(
                        `/book/${event.slug ?? event.id}/successful/${res.id}?${
                            res.token ? `token=${res.token}&` : ""
                        }view=${view}&nonce=${nonce}`
                    );
                }
            })
            .catch((error) => {
                setBookingError(error);
            })
            .finally(() => true);
    };

    const notification = useParentNotification();

    useEffect(() => {
        if (bookWrapperRef.current) {
            setParentNotification({
                loaded: true,
                height: bookWrapperRef.current.scrollHeight < 800 ? bookWrapperRef.current.scrollHeight : 800,
            });
        }
    }, [step, notification, bookWrapperRef]);

    const bookingTitleTooltip = event?.name;
    const showBookingTitleTooltip = bookingTitleTooltip ? bookingTitleTooltip.length > 65 : false;

    const handleFormSubmit = () => {
        const guests = formImperativeRef.current?.values.guests ?? [];
        if (pendingGuestRef.current) {
            if (emailRegex.test(pendingGuestRef.current)) {
                guests.push(pendingGuestRef.current);
            }

            pendingGuestRef.current = "";
        }

        if (event.payments?.length) {
            return handleOnStepChange(BookingStep.CHECKOUT);
        }

        return formImperativeRef.current?.submitForm();
    };

    const handleCheckoutSubmit = (provider?: string) => {
        if (provider) {
            formImperativeRef.current?.setFieldValue("paymentProvider", provider);
        }

        return formImperativeRef.current?.submitForm();
    };

    return (
        <div className={cls("fullw fullh flex flex-col flex-center-all", step, view === "page" ? "py-20" : "")}>
            {isPreview ? <BookPreviewNotification eventSlug={event.slug ?? event.id ?? ""} /> : null}
            <div className={view === "page" ? classes.wrapper : classes.componentWrapper}>
                <div ref={bookWrapperRef} className={classes.content}>
                    <Formik
                        initialValues={initialValues}
                        innerRef={formImperativeRef}
                        validationSchema={bookEventValidationSchema}
                        onSubmit={handleOnScheduleBooking}
                        validateOnBlur={true}
                        validateOnChange={true}
                    >
                        {({values, errors, touched, setFieldValue, setFieldTouched, submitForm}) => {
                            return (
                                <BookEventContentWrapper
                                    event={event}
                                    values={values}
                                    duration={duration}
                                    step={step}
                                    showBookingTitleTooltip={showBookingTitleTooltip}
                                    bookingTitleTooltip={bookingTitleTooltip}
                                    timezone={timezone as AcceptedTimezone}
                                    isHalfDayClock={isHalfDayClock}
                                    setIsHalfDayClock={setIsHalfDayClock}
                                    onChangeTimeZone={setTimezone}
                                    isBookADemo={isBookADemo}
                                    handleCloseBookADemo={onCancel}
                                >
                                    {step === BookingStep.DURATION ? (
                                        <BookEventDuration
                                            event={event}
                                            duration={duration}
                                            onChangeDuration={setDuration}
                                            onNext={() => handleOnStepChange(BookingStep.DATE_TIME)}
                                            onCancel={onCancel}
                                        />
                                    ) : step === BookingStep.DATE_TIME ? (
                                        <BookEventDateTime
                                            duration={duration}
                                            value={values.date}
                                            timezone={timezone as AcceptedTimezone}
                                            defaultTimezone={event.timeZone as AcceptedTimezone}
                                            event={event}
                                            onChangeTimeZone={setTimezone}
                                            onChange={setFieldValue.bind(null, "date")}
                                            onCancel={onCancel}
                                            onBack={
                                                event.additionalDuration?.length
                                                    ? handleOnStepChange.bind(null, BookingStep.DURATION)
                                                    : undefined
                                            }
                                            onFinish={handleOnStepChange.bind(null, BookingStep.DETAILS)}
                                            isBookADemo={isBookADemo}
                                            bookingError={bookingError}
                                            setBookingError={setBookingError}
                                            isHalfDayClock={isHalfDayClock}
                                            setIsHalfDayClock={setIsHalfDayClock}
                                            isTroubleshootMode={isTroubleshootMode}
                                        />
                                    ) : step === BookingStep.DETAILS ? (
                                        <BookEventDetails
                                            event={event}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            timezone={timezone as AcceptedTimezone}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            onBack={handleOnStepChange.bind(null, BookingStep.DATE_TIME)}
                                            onFinish={handleFormSubmit}
                                            pendingGuestRef={pendingGuestRef}
                                            bookingError={bookingError}
                                            bookingTimedOut={bookingTimedOut}
                                            duration={duration}
                                            questionnare={event.questionnare as QuestionnareForm}
                                            isPreview={isPreview}
                                        />
                                    ) : (
                                        <BookEventCheckout
                                            event={event}
                                            values={values}
                                            timezone={timezone as AcceptedTimezone}
                                            duration={duration}
                                            onBack={() => handleOnStepChange(BookingStep.DETAILS)}
                                            onCheckout={handleCheckoutSubmit}
                                            bookingError={bookingError}
                                            bookingTimedOut={bookingTimedOut}
                                        />
                                    )}
                                </BookEventContentWrapper>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
