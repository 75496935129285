import {raiseHand} from "@artifacts/conference/api/conferenceControllerApi";
import React, {forwardRef} from "@workhorse/api/rendering";
import MenuItem from "@ui/cdk/MenuItem";
import {ReactComponent as RaiseHand} from "../../../../../assets/media/raise-hand-icon.svg";
import {ReactComponent as HandDown} from "../../../../../assets/media/hand-down-icon.svg";
import {cls} from "@ui/cdk/util";
import ReactionsMenu from "./components/ReactionsMenu";
import {useReaction} from "@workhorse/providers/SessionReactionsProvider";
import {ReactionType, SessionFlagsType} from "@generated/data";
import classes from "./styles/RaiseHandToggler.module.scss";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useMyParticipant} from "@workhorse/providers/SessionDataProviders";
import shouldDisableFlag from "@workhorse/api/session-settings/utils/shouldDisableFlag";
import {useClientEvent} from "@api/events/client";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {getGlobalKeybindString} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";

type RaiseHandTogglerProps = {
    className?: string;
    participantId: string;
    showTooltip?: (e?: any, message?: string) => void;
    isSpotlight: boolean;
    isRaisingHand: boolean;
    hideText?: boolean;
    enableReactionsType: SessionFlagsType;
};

const RaiseHandToggler = forwardRef((props: RaiseHandTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {isRaisingHand, isSpotlight, participantId, showTooltip, hideText, enableReactionsType} = props;
    const currentParticipant = useMyParticipant();

    const disabled = shouldDisableFlag(currentParticipant, enableReactionsType);

    const {isMobileOrTablet} = useMobile();

    const {triggerReaction} = useReaction();

    const toggleRaiseHand = async () => {
        if (disabled || isSpotlight) {
            return;
        }

        ariaAnnouncerMessageVar(
            isRaisingHand
                ? t("aria_announcer.you_lowered_your_hand") ?? "You lowered your hand"
                : t("aria_announcer.you_raised_your_hand") ?? "You raised your hand"
        );

        raiseHand(participantId, isRaisingHand);
        if (!isRaisingHand) {
            triggerReaction(ReactionType.Raisehand);
        }
    };

    const onMouseEnter = (e: any) => {
        const message = disabled ? t("participant.reaction.reactions_are_disabled") ?? "Reactions are disabled" : "";
        if (!message) {
            return;
        }
        showTooltip?.(e, message);
    };

    const onMouseLeave = () => {
        showTooltip?.();
    };

    const className = cls(props.className, isRaisingHand && enableReactionsType && "active-blue");

    useClientEvent("player-keybind-toggle-raisehand", () => {
        toggleRaiseHand();
    });

    return (
        <ReactionsMenu disabled={disabled || isMobileOrTablet}>
            <MenuItem
                data-id="raise-hand"
                ref={ref}
                button
                className={cls(enableReactionsType && classes.root, className)}
                onClick={toggleRaiseHand}
                disabled={disabled}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                aria-label={`${
                    isRaisingHand
                        ? t("participant.reaction.hand_down") ?? "Hand down"
                        : t("participant.reaction.raise_hand") ?? "Raise hand"
                }, (${getGlobalKeybindString("toggleRaiseHand")})`}
                role="button"
                tabIndex={0}
            >
                {isRaisingHand ? <HandDown /> : <RaiseHand />}
                {hideText ? null : (
                    <span className="text">
                        {isRaisingHand
                            ? t("participant.reaction.hand_down") ?? "Hand down"
                            : t("participant.reaction.raise_hand") ?? "Raise hand"}
                    </span>
                )}
            </MenuItem>
        </ReactionsMenu>
    );
});

export default RaiseHandToggler;
