import React, {forwardRef} from "@workhorse/api/rendering";
import {usePreview} from "react-dnd-preview";
import classes from "./style/AgendaItemDragPreview.module.scss";

type DragPreviewLayerProps = {};

const AgendaItemDragPreview = forwardRef((props: DragPreviewLayerProps, ref: React.MutableRefObject<any>) => {
    const {display, item, style, ref: previewRef}: any = usePreview();

    if (!display) {
        document.body.style.cursor = "default";

        return null;
    }

    if (item.type !== "templates-agenda-item") {
        return null;
    }

    document.body.style.cursor = "grabbing";

    const updatedStyle = {
        ...style,
        transform: `${style?.transform} rotate(-15deg) scale(0.86)`,
        WebkitTransform: `${style?.transform} rotate(-15deg) scale(0.86)`,
        zIndex: 999,
    };

    return (
        <div ref={previewRef} className={classes.agendaItem} style={updatedStyle}>
            Drop this before or after another agenda item
        </div>
    );
});

export default AgendaItemDragPreview;
