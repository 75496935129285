import menuClasses from "./styles/ToolControlMenu.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import MenuList from "@ui/core/components/MenuList";
import React, {useMemo, useState} from "@workhorse/api/rendering";
import {ClickAwayListener} from "@material-ui/core";

type ToolControlMenuProps = Omit<ExtractProps<typeof Tooltip>, "title"> & {
    options: JSX.Element | JSX.Element[];
    onCloseTooltip?: () => void;
    onOpenTooltip?: () => void;
    openOnClick?: boolean;
};

function ToolControlMenu(props: ToolControlMenuProps) {
    const {children, options, classes: propsClasses, onOpenTooltip, onCloseTooltip, openOnClick, ...otherProps} = props;
    const menuOpts = useMemo(() => {
        return React.Children.map(options, (child) => {
            return child.type === React.Fragment ? child.props?.children || null : child;
        });
    }, [options]);

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const toggleTooltipOpen = () => {
        setOpen(!open);
    };

    return openOnClick ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    {...otherProps}
                    open={open}
                    placement={props.placement || "top"}
                    classes={{
                        ...propsClasses,
                        tooltip: cls(menuClasses.tooltip, propsClasses?.tooltip),
                        popper: cls(menuClasses.popper, propsClasses?.popper),
                    }}
                    interactive
                    disableFocusListener
                    disableHoverListener
                    title={<MenuList className={menuClasses.menuList}>{menuOpts}</MenuList>}
                >
                    <div onClick={toggleTooltipOpen}>{children}</div>
                </Tooltip>
            </div>
        </ClickAwayListener>
    ) : (
        <Tooltip
            {...otherProps}
            onOpen={onOpenTooltip}
            onClose={onCloseTooltip}
            placement={props.placement || "top"}
            classes={{
                ...propsClasses,
                tooltip: cls(menuClasses.tooltip, propsClasses?.tooltip),
                popper: cls(menuClasses.popper, propsClasses?.popper),
            }}
            interactive
            disableFocusListener
            title={<MenuList className={menuClasses.menuList}>{menuOpts}</MenuList>}
        >
            {children}
        </Tooltip>
    );
}

export default ToolControlMenu;
