import {HostType, OrganizationPlan} from "@generated/data";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util/util";
import {AuthService} from "@workhorse/api/authService";
import {Link, useHistory} from "@workhorse/api/routing";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import {WithHostType} from "@workhorse/declarations";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "./styles/GuestHeader.module.scss";
import {useTranslation} from "react-i18next";
import LanguageSelector from "@workhorse/components/language-selector/LanguageSelector";
import {isFeatureAvailable} from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {setSessionGuestUtmCookies} from "@workhorse/api/user";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";

type HeaderProps = {
    sessionName?: string;
    organizationPlan?: OrganizationPlan;
    ownerLanguage?: string;
} & WithHostType;

const GuestHeader = (props: HeaderProps) => {
    const {t} = useTranslation();
    const sessionId = useSessionIdFromRoute();

    const featureIsAvailable = props.organizationPlan && isFeatureAvailable(LimitedFeature.WHITE_LABELING, props.organizationPlan);

    const loginUrl = "/";

    const {isMobile} = useMobile();

    const history = useHistory();
    const hostType = props.hostType;

    const showSignUp = !featureIsAvailable && !isMobile;

    const onLogin = () => {
        setSessionGuestUtmCookies(sessionId);
        AuthService.getInstance().login(history.location.pathname, true);
    };

    const onRegister = () => {
        setSessionGuestUtmCookies(sessionId);
        AuthService.getInstance().register(history.location.pathname);
    };

    return hostType === HostType.Feedback ? (
        <div
            className={cls("flex flex-justify-between flex-items-center flex11-auto", classes.root, classes.feedbackPage)}
            key="guest-header"
        >
            {featureIsAvailable ? (
                <div className={classes.logoContainer}>
                    <BrandingLogo size="small" variant="dark" />
                </div>
            ) : (
                <Link
                    to={loginUrl}
                    className={classes.logoContainer}
                    key="header-logo-link"
                    aria-label={t("header.go_to_app_page") ?? "Go to app page"}
                >
                    <BrandingLogo size="small" variant="dark" />
                </Link>
            )}
        </div>
    ) : (
        <div className={cls("flex flex-justify-between flex-items-center ", classes.root, classes.waitingPage)} key="guest-header">
            <div className={cls("fullw flex flex-align-center flex-justify-between flex11-auto", classes.header)} key="guest-header">
                <div className={classes.headerContainerLeft}>
                    {featureIsAvailable ? (
                        <div className={classes.headerLogo}>
                            <BrandingLogo size="small" variant="dark" />
                        </div>
                    ) : (
                        <Link
                            to={loginUrl}
                            key="header-logo-link"
                            aria-label={t("header.go_to_app_page") ?? "Go to app page"}
                            className={classes.headerLogo}
                        >
                            <BrandingLogo size="small" variant="dark" />
                        </Link>
                    )}

                    <div className={cls("ml-12", classes.sessionInfo)}>
                        <Typography fontWeight="bolder" noWrap>
                            {props.sessionName ?? t("g.loading") ?? "Loading..."}
                        </Typography>
                    </div>
                </div>

                <div className={classes.headerContainerRight}>
                    <LanguageSelector isGuest={true} skipUpdate={true} ownerLanguage={props.ownerLanguage} />
                    <Button data-id="guest-login" variant="tertiary" size="small" onClick={onLogin}>
                        {t("user.log_in") ?? "Log in"}
                    </Button>
                    {showSignUp && (
                        <Button data-id="guest-register" variant="primary" size="small" onClick={onRegister}>
                            {t("user.sign_up") ?? "Sign up"}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GuestHeader;
