import {memo, useCallback, useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {PaletteActiveCateg} from "./CommandPaletteProviders";
import {ActionCategory, categMap, isValidLink} from "./actionCategTree";
import {
    CommandPaletteSearchResults,
    usePaletteResultList,
    usePaletteResults,
    usePaletteSearchFilters,
} from "./CommandPaletteResultsProvider";
import {isPaletteItemFile, isPaletteItemAgenda, isPaletteItemTool} from "@workhorse/api/palette";
import {usePaletteExcludedItems} from "./PaletteActionProvider";
import {PaletteItemMode, PaletteSearchInput, PaletteSearchOrderBy, SearchPaletteDocument, SearchPaletteQuery} from "@generated/data";
import apollo from "@workhorse/api/apollo";

type SearchActionsLeafProps = {
    searchStr?: string;
    activeCateg: PaletteActiveCateg;
};

export default memo(
    function CommandPaletteSearch(props: SearchActionsLeafProps) {
        const {setPaletteResults} = usePaletteResults();
        const {searchFilters} = usePaletteSearchFilters();
        const {resultList: resultListCurrent, setResultList} = usePaletteResultList();
        const {excludedItems} = usePaletteExcludedItems();
        const {activeCateg, searchStr} = props;

        const excludeAgendas = useMemo(() => {
            const agendaBranchId = categMap.find((obj) => !obj.parentId && obj.categ === ActionCategory.Agenda)?.id;
            return agendaBranchId && excludedItems.includes(agendaBranchId) ? true : false;
        }, [excludedItems]);

        const excludeFiles = useMemo(() => {
            const fileBranchId = categMap.find((obj) => !obj.parentId && obj.categ === ActionCategory.File)?.id;
            return fileBranchId && excludedItems.includes(fileBranchId) ? true : false;
        }, [excludedItems]);

        const preventSearch =
            isValidLink(searchStr) ||
            activeCateg.name === ActionCategory.Link ||
            (activeCateg.name === ActionCategory.Tool && !activeCateg.parentId);

        const setResults = useCallback(
            (res: SearchPaletteQuery["searchPalette"]) => {
                setPaletteResults((current) =>
                    !res.length && !current.files.length && !current.recent.length && !current.tools.length && !current.agendas.length
                        ? current
                        : res.reduce(
                              (all, item) => {
                                  if (isPaletteItemFile(item)) {
                                      all.files.push(item);
                                  } else if (isPaletteItemTool(item)) {
                                      all.tools.push(item);
                                  } else if (isPaletteItemAgenda(item)) {
                                      all.agendas.push(item);
                                  }

                                  return all;
                              },
                              {
                                  files: [],
                                  recent: res,
                                  tools: [],
                                  agendas: [],
                              } as CommandPaletteSearchResults
                          )
                );
            },
            [searchFilters]
        );

        const t = useRef<NodeJS.Timeout | null>(null);

        const search = useCallback(
            (filters: PaletteSearchInput | undefined) => {
                const isInstant = t.current === null;
                if (t.current !== null) {
                    clearTimeout(t.current);
                }
                const mode: PaletteSearchInput["mode"] =
                    !filters?.mode && excludeAgendas
                        ? [PaletteItemMode.File, PaletteItemMode.Tool]
                        : excludeFiles
                        ? [PaletteItemMode.Agenda]
                        : filters?.mode;
                t.current = setTimeout(
                    () => {
                        apollo.client
                            .query({
                                query: SearchPaletteDocument,
                                variables: {
                                    input: {
                                        ...filters,
                                        mode,
                                    },
                                },
                                fetchPolicy: "no-cache",
                            })
                            .then((res) => {
                                setResults(res.data.searchPalette);
                            });
                    },
                    isInstant ? 0 : 600
                );
            },
            [excludeAgendas, excludeFiles]
        );

        const prevCateg = useRef(activeCateg);

        useEffect(() => {
            const categChanged = prevCateg.current.id !== activeCateg.id;
            if (categChanged) {
                setResultList([]);
                setResults([]);
            }
            prevCateg.current = activeCateg;
        }, [activeCateg.id]);

        const resultList = useRef(resultListCurrent);
        resultList.current = resultListCurrent;

        useEffect(() => {
            const list = resultList.current;
            if (preventSearch) {
                if (list.length) {
                    setResultList([]);
                }
                setResults([]);
                return;
            }

            switch (activeCateg.name) {
                case ActionCategory.Agenda: {
                    if (list.length !== 1 || !list.includes("agendas")) {
                        setResultList(["agendas"]);
                    }
                    search({
                        ...searchFilters,
                        orderBy: PaletteSearchOrderBy.Recent,
                        query: searchStr,
                        mode: [PaletteItemMode.Agenda],
                    });
                    break;
                }
                case ActionCategory.File: {
                    if (list.length !== 1 || !list.includes("files")) {
                        setResultList(["files"]);
                    }
                    search({
                        ...searchFilters,
                        orderBy: PaletteSearchOrderBy.Recent,
                        query: searchStr,
                        mode: [PaletteItemMode.File],
                    });
                    break;
                }
                case ActionCategory.Link: {
                    if (list.length) {
                        setResultList([]);
                    }
                    search({
                        ...searchFilters,
                        orderBy: PaletteSearchOrderBy.Recent,
                        query: searchStr,
                        mode: [PaletteItemMode.Tool],
                    });
                    break;
                }
                case ActionCategory.Tool: {
                    if (list.length !== 1 || !list.includes("tools")) {
                        setResultList(["tools"]);
                    }
                    if (!searchFilters.toolType) {
                        setResults([]);
                        return;
                    }
                    search({
                        ...searchFilters,
                        orderBy: PaletteSearchOrderBy.Recent,
                        query: searchStr,
                        mode: [PaletteItemMode.Tool],
                    });
                    break;
                }
                default: {
                    if (!searchStr) {
                        if (list.length !== 1 || !list.includes("recent")) {
                            setResultList(["recent"]);
                        }
                    } else {
                        if (list.length !== 3 || !list.includes("files") || !list.includes("tools") || !list.includes("agendas")) {
                            setResultList(["files", "tools", "agendas"]);
                        }
                    }
                    search({
                        ...searchFilters,
                        orderBy: PaletteSearchOrderBy.Recent,
                        query: searchStr,
                        limit: !searchStr?.trim() ? 4 : undefined,
                    });
                    break;
                }
            }
        }, [activeCateg.id, activeCateg.name, activeCateg.parentId, searchStr, searchFilters, excludeAgendas]);

        return <></>;
    },
    (prev, next) =>
        prev.activeCateg.id === next.activeCateg.id &&
        prev.activeCateg.parentId === next.activeCateg.parentId &&
        prev.searchStr === next.searchStr
);
