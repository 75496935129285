import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {useUserInfo} from "@workhorse/providers/User";
import {useRef, useState, useEffect} from "@workhorse/api/rendering";
import NavigationUserDetails from "../navigation/components/NavigationUserDetails";
import {cls} from "@ui/cdk/util";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {AvatarSizeTypes} from "@ui/cdk/Avatar/types";
import {useTranslation} from "react-i18next";
import {OrganizationPlan} from "@generated/data";

type UserProfileMenuProps = {
    className?: string;
    isGuest?: boolean;
    avatarSize?: AvatarSizeTypes;
    organizationPlan?: OrganizationPlan;
};

function UserProfileMenu(props: UserProfileMenuProps) {
    const {t} = useTranslation();
    const {className, isGuest, avatarSize, organizationPlan} = props;
    const [open, setOpen] = useState<boolean>(false);
    const {firstName, lastName, profilePicture} = useUserInfo();

    const {isMobile} = useMobile();
    const {isPortrait} = useDeviceOrientation();

    const ref = useRef<HTMLDivElement>(null);

    const handleProfileOpen = () => setOpen((open) => !open);

    useEffect(() => {
        if (!isPortrait && isMobile && open) {
            setOpen(false);
        }
    }, [isPortrait, isMobile, open]);

    return (
        <>
            <div
                onClick={handleProfileOpen}
                ref={ref}
                className={cls("flex flex-items-center", open && "open", className)}
                data-id="player-button-myprofile"
                aria-label={t("user.aria.my_profile") ?? "My Profile"}
                aria-haspopup="true"
                aria-expanded={open}
                role="button"
            >
                <ProfilePicture
                    firstName={firstName}
                    lastName={lastName}
                    avatar={profilePicture}
                    avatarSize={avatarSize || "M"}
                    avatarVariant="rounded"
                    disableTooltip
                    isGuest={isGuest}
                />
            </div>
            {open ? (
                <NavigationUserDetails
                    open={open}
                    anchorEl={ref.current}
                    onClose={handleProfileOpen}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    organizationPlan={organizationPlan}
                />
            ) : null}
        </>
    );
}

export default UserProfileMenu;
