import {createContextProvider} from "@workhorse/api/utils/context";
import {useCallback, useMemo, useRef, useState} from "react";

function useBreakoutInfoProvider() {
    const [roomsWithParticipants, setRoomsWithParticipants] = useState<string[]>([]);
    const [onlineParticipants, setOnlineParticipants] = useState<string[]>([]);
    const [debounceNotifications, setDebounceNotifications] = useState(false);

    const updateRoomInfo = useCallback((roomId: string, hasParticipants: boolean) => {
        if (hasParticipants) {
            setRoomsWithParticipants((current) => {
                const index = current.indexOf(roomId);
                if (index !== -1) {
                    return current;
                }
                return [...current, roomId];
            });
        } else {
            setRoomsWithParticipants((current) => {
                const index = current.indexOf(roomId);
                if (index === -1) {
                    return current;
                }
                const copy = current.slice();
                copy.splice(index);
                return copy;
            });
        }
    }, []);

    const resetOnlineParticipantsId = useRef<NodeJS.Timeout | null>(null);

    const setOnlineParticipantsWrapper = useCallback((ids: string[]) => {
        if (resetOnlineParticipantsId.current) {
            clearTimeout(resetOnlineParticipantsId.current);
        }
        setOnlineParticipants(ids);
        setDebounceNotifications(true);
    }, []);

    const resetOnlineParticipants = useCallback(() => {
        if (resetOnlineParticipantsId.current) {
            clearTimeout(resetOnlineParticipantsId.current);
        }
        resetOnlineParticipantsId.current = setTimeout(() => {
            setOnlineParticipants([]);
            setDebounceNotifications(false);
        }, 14000);
    }, []);

    const roomsHaveParticipants = roomsWithParticipants.length !== 0;

    return useMemo(
        () => ({
            onlineParticipants,
            debounceNotifications,
            roomsHaveParticipants,
            updateRoomInfo,
            setOnlineParticipants: setOnlineParticipantsWrapper,
            resetOnlineParticipants,
        }),
        [
            onlineParticipants,
            debounceNotifications,
            roomsHaveParticipants,
            updateRoomInfo,
            setOnlineParticipantsWrapper,
            resetOnlineParticipants,
        ]
    );
}

export const [BreakoutProvider, useBreakoutInfo] = createContextProvider(
    {
        name: "BreakoutInfoProvider",
    },
    useBreakoutInfoProvider
);
