import {CheckSessionExistsDocument, JoinWithPublicLinkDocument, LocalUserDocument, SessionLifecycle} from "@generated/data";
import environment from "@generated/environment";
import {collapseWhiteSpace} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {AuthService} from "@workhorse/api/authService";
import {useMutation} from "@workhorse/api/data";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import Loading from "@workhorse/components/Loading";
import {WithHostType} from "@workhorse/declarations";
import GuestInfoDialog from "./GuestInfoDialog";
import {isRoom} from "@workhorse/providers/CurrentSessionIdProvider";

const SessionPublicLink = (props: {sessionId: string} & WithHostType) => {
    const isMounted = useRef(true);
    const participantIdRef = useRef("");

    const [sessionOpen, setSessionOpen] = useState<boolean>(false);
    const [askForGuestEmail, setAskForGuestEmail] = useState<boolean>(false);
    const [sessionData, setSessionData] = useState<{lifecycle: SessionLifecycle; isParentOfRecurrence: boolean}>({
        lifecycle: SessionLifecycle.NotStarted,
        isParentOfRecurrence: false,
    });

    const history = useHistory();

    const [checkSessionExists] = useMutation(CheckSessionExistsDocument);

    const checkSession = useCallback(async () => {
        setSessionOpen(false);
        const res = await checkSessionExists({variables: {sessionId: props.sessionId}});

        if (!isMounted.current) {
            return;
        }

        if (res.data?.checkSessionExists?.exists === true) {
            if (res.data.checkSessionExists.eventSlug) {
                window.location.href = `${environment.eventBaseURL}/${res.data.checkSessionExists.eventSlug}`;
            }

            if (res.data.checkSessionExists.guestAuthIsValid != null) {
                console.log(`[SessionPublicLink::checkSession] removeItem access_token`);
                AuthService.getInstance().logout(window.location.href);
            }

            if (res.data.checkSessionExists.participantId) {
                participantIdRef.current = res.data.checkSessionExists.participantId;
            }

            if (res.data.checkSessionExists.sessionLifecycle === SessionLifecycle.Ended) {
                history.replace(`/memory/session/${props.sessionId}`);
                return;
            }

            if (res.data.checkSessionExists.sessionLifecycle || res.data.checkSessionExists.isParentOfRecurrence) {
                setSessionData((prev) => ({
                    lifecycle: res.data?.checkSessionExists?.sessionLifecycle || prev.lifecycle,
                    isParentOfRecurrence: res.data?.checkSessionExists?.isParentOfRecurrence || prev.isParentOfRecurrence,
                }));
            }

            if (res.data.checkSessionExists.askForGuestEmail) {
                setAskForGuestEmail(true);
            }

            setSessionOpen(true);
        } else {
            history.push("/404");
        }
    }, [checkSessionExists, history, props.sessionId, setSessionData]);

    useEffect(() => {
        isMounted.current = true;
        checkSession().catch((err) => console.log(err));

        return () => {
            isMounted.current = false;
        };
    }, [checkSession]);

    if (!sessionOpen) {
        return <Loading location="SessionPublicLink.tsx" />;
    }

    return (
        // <BrowserAndMobileChecker>
        <NewGuestHandler
            askForGuestEmail={askForGuestEmail}
            hostType={props.hostType}
            sessionId={props.sessionId}
            sessionLifecycle={sessionData.lifecycle}
            isParentOfRecurrence={sessionData.isParentOfRecurrence}
        />
        // </BrowserAndMobileChecker>
    );
};

function NewGuestHandler(
    props: {
        sessionId: string;
        askForGuestEmail: boolean;
        sessionLifecycle: SessionLifecycle;
        isParentOfRecurrence: boolean;
    } & WithHostType
) {
    const history = useHistory();
    const [trackEvent] = useTrackingEvents();
    const [joinWithPublicLink, joinWithPublicLinkResult] = useMutation(JoinWithPublicLinkDocument);

    const onGuestInfoSubmit = useCallback(
        async (name: string, email?: string) => {
            const nameWithWhitespaceCollapsed = collapseWhiteSpace(name);
            const language = localStorage.getItem("user-language") ?? undefined;

            try {
                const res = await joinWithPublicLink({
                    variables: {
                        name: nameWithWhitespaceCollapsed,
                        sessionId: props.sessionId,
                        email: email,
                        language,
                    },
                });

                if (!res.data?.joinWithPublicLink) {
                    return;
                }

                if (res.data.joinWithPublicLink.redirectTo) {
                    window.location.href = res.data.joinWithPublicLink.redirectTo;
                }

                if (res.data.joinWithPublicLink.fatalError) {
                    toast(res.data.joinWithPublicLink.fatalError, {type: "error"});
                    history.push("/");
                    return;
                }

                if (res.data.joinWithPublicLink.validationError) {
                    toast(res.data.joinWithPublicLink.validationError, {type: "warning"});
                }

                if (res.data.joinWithPublicLink.redirectToLogin) {
                    AuthService.getInstance().login(`/session/${props.sessionId}`);
                    return;
                }

                if (res.data.joinWithPublicLink.joinDetails) {
                    await AuthService.getInstance().loginGuest(res.data.joinWithPublicLink.joinDetails.access_token);

                    apollo.client.writeQuery({
                        query: LocalUserDocument,
                        data: {
                            isAuthenticated: true,
                            localUser: {
                                __typename: "LocalUser",
                                email: "pending@guest.sessions.us",
                                firstName: "pending",
                                lastName: "guest",
                                role: "GUEST",
                                language: "en-GB",
                            },
                        },
                    });

                    trackEvent("guest_joined_session", {event_category: TrackingEventCategory.GuestActions});
                }
            } catch (err) {
                console.log(err);
            }
        },
        [history, joinWithPublicLink, props.sessionId, trackEvent]
    );

    const isRoomAndNotStarted = props.sessionLifecycle === SessionLifecycle.NotStarted && isRoom();

    useEffect(() => {
        if (!joinWithPublicLinkResult.called && (props.sessionLifecycle === SessionLifecycle.Started || isRoomAndNotStarted)) {
            onGuestInfoSubmit("", " ");
        }
    }, [joinWithPublicLinkResult.called, onGuestInfoSubmit, props.sessionLifecycle, isRoomAndNotStarted]);

    return props.sessionLifecycle === SessionLifecycle.Started || isRoomAndNotStarted ? (
        <Loading />
    ) : (
        <GuestInfoDialog
            sessionId={props.sessionId}
            hostType={props.hostType}
            askForGuestEmail={props.askForGuestEmail}
            onSubmit={onGuestInfoSubmit}
            isParentOfRecurrence={props.isParentOfRecurrence}
        />
    );
}

export default SessionPublicLink;
