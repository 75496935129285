import VolumeUp from "@material-ui/icons/VolumeUp";
import {cls} from "@ui/cdk/util";
import classes from "./AudioPreview.module.scss";

interface Props {
    onClick?: () => void;
    className?: string;
}

export function AudioPreviewIcon(props: Props) {
    return <VolumeUp className={cls(classes.previewIcon, props.className)} onClick={props.onClick} />;
}
