import {AcceptedTimezone, getLocalTimezone} from "@common/utils";
import TimePicker, {DateTimePickerProps} from "@ui/cdk/DateTimePicker";
import {useRef, useState} from "@workhorse/api/rendering";
import classes from "./event-timepicker.module.scss";
import pickerClasses from "@workhorse/components/CreatePlannedSession/styles/CreateScheduledSessionDialog.module.scss";
import {addMinutes} from "date-fns";
import {alignDate} from "@ui/cdk/DateTimePicker/utils";

const localTimezone = getLocalTimezone();

interface Props {
    startDate: Date;
    endDate: Date;
    timezone: AcceptedTimezone;
    onDateChange: (start: Date, end: Date) => void;
    disabled: boolean;
    isHalfDayClock?: boolean;
}

export function EventsTimePicker(props: Props) {
    const timePickerRefStart = useRef<HTMLDivElement>();
    const timePickerRefEnd = useRef<HTMLDivElement>();

    const {startDate, endDate, disabled} = props;

    const onStartOrEndTimeChange: DateTimePickerProps["onChange"] = (value) => {
        props.onDateChange(value.startDate, value.endDate);
    };

    const onCalendarChooseDay = (arg: {startDate?: Date; endDate?: Date}) => {
        const duration = Math.ceil((endDate.getTime() - startDate.getTime()) / 1000 / 60);

        if (arg.startDate) {
            const newStart = alignDate(startDate, arg.startDate) as Date;
            const newEnd = addMinutes(newStart, duration);
            props.onDateChange(newStart, newEnd);
            return;
        }

        if (arg.endDate == null) {
            return;
        }

        const minEnd = addMinutes(startDate, 15);
        if (arg.endDate.getTime() >= minEnd.getTime()) {
            const aligned = alignDate(endDate, arg.endDate);
            props.onDateChange(props.startDate, aligned as Date);
        }
    };

    return (
        <TimePicker
            classes={{
                dateInput: classes.dateInput,
                autocompletePaper: pickerClasses.autocompletePaper,
            }}
            startDate={startDate}
            endDate={endDate}
            dateFormat={props.isHalfDayClock ? "12h" : "24h"}
            variant="outlined"
            fullWidth={true}
            disabled={disabled}
            allowDatesInThePast={true}
            forTimezone={props.timezone}
            localTimezone={localTimezone}
            inlineCalendar={true}
            refStart={timePickerRefStart}
            refEnd={timePickerRefEnd}
            inSessionDialog={true}
            onCalendarChooseDay={onCalendarChooseDay}
            onChange={onStartOrEndTimeChange}
        />
    );
}
