import Tooltip from "@ui/cdk/Tooltip";
import React, {forwardRef} from "@workhorse/api/rendering";
import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {useMobile} from "@workhorse/providers/MobileProvider";
import ControllerDisabledIcon from "./icons/controller-disabled.svg";
import ControllerEnabledIcon from "./icons/controller-enabled.svg";

type AdminMicroControllerTogglerProps = {
    isLobby: boolean;
    inDrawer: boolean;
    handleToggle: (event: any) => void;
    isActive: boolean;
};

const AdminMicroControllerToggler = forwardRef(
    (props: AdminMicroControllerTogglerProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
        const {isLobby, inDrawer, handleToggle, isActive} = props;

        const {isMobile} = useMobile();

        return (
            <Tooltip placement="top" arrow={true} title="As the Host, you can control the session's features">
                <ArtifactActionsButton
                    badgeContent={0}
                    onClick={handleToggle}
                    ref={ref}
                    isActive={isActive}
                    activeIcon={ControllerEnabledIcon}
                    inactiveIcon={ControllerDisabledIcon}
                    placement={isMobile ? "onRight" : "onBottom"}
                    buttonDataId="player-controller-toggler"
                    controllerToggler={true}
                    isLobby={isLobby}
                    inDrawer={inDrawer}
                />
            </Tooltip>
        );
    }
);

export default AdminMicroControllerToggler;
