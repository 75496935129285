import {useCallback, useMemo, useRef} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useCameraHiddenStorage, useCameraMirrorSelfViewStorage} from "@workhorse/providers/UserDeviceSettingsStorageProvider";

const useCameraSettingsStore = () => {
    const {cameraHidden, setCameraHidden} = useCameraHiddenStorage();
    const {cameraMirrorSelfView, setCameraMirrorSelfView} = useCameraMirrorSelfViewStorage();

    const innerRef = useRef({
        cameraHidden,
        cameraMirrorSelfView,
    });

    innerRef.current.cameraHidden = cameraHidden;
    innerRef.current.cameraMirrorSelfView = cameraMirrorSelfView;

    const toggleCameraMirrorSelfView = useCallback(() => {
        setCameraMirrorSelfView(!innerRef.current.cameraMirrorSelfView);
    }, []);

    const toggleCameraHidden = useCallback(() => {
        setCameraHidden(!innerRef.current.cameraHidden);
    }, []);

    return useMemo(
        () => ({
            cameraMirrorSelfView,
            toggleCameraMirrorSelfView,
            cameraHidden,
            toggleCameraHidden,
        }),
        [cameraMirrorSelfView, toggleCameraMirrorSelfView, cameraHidden, toggleCameraHidden]
    );
};

export const [CameraSettingsProvider, useCameraSettings] = createContextProvider(
    {
        name: "CameraSettings",
    },
    useCameraSettingsStore
);
