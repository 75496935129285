import {AgendaItemType, CheckUrlDocument} from "@generated/data";
import environment from "@generated/environment";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {setIframeResource, setPresentationResource, setSmartBoardResource} from "./session-onboarding-resources";

export function createProperty(key: string, value: string) {
    return {
        __typename: "Property" as const,
        id: makeId(),
        key,
        value,
        createdAt: null as unknown as Date,
        updatedAt: null as unknown as Date,
        isDeleted: null,
        oldId: null,
        update: null,
    };
}

export function createSimpleDescription(description: string) {
    return {
        0: {
            alignment: "left",
            children: [
                {
                    bold: false,
                    italic: false,
                    strickenThrough: false,
                    text: description,
                    underline: false,
                },
            ],
            indentation: 0,
            type: "paragraph",
        },
    };
}

export async function createConferenceArtifact() {
    designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Hi there 👋",
        descriptionJson: createSimpleDescription("Welcome to your first session. It’s great to have you here!"),
    });
}

export async function createPresentationArtifact(userId: string) {
    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Slideshow presentation",
        descriptionJson: createSimpleDescription(
            "The first agenda item automatically loads a slideshow. Control the slides for everyone or let your participants click through them at their own pace."
        ),
    });

    const artifactId = makeId();

    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/ppt",
            isConfigured: true,
            properties: [createProperty("currentSlide", "0")],
        },
    });

    const presentationName = "Share with Sessions";
    const presentationUrl = `${environment.staticServiceUrl}/assets/onboarding/strike1/presentation/share_with_sessions.pptx`;

    await setPresentationResource(userId, artifactId, presentationName, presentationUrl);
}

export async function getValidWebsiteUrl(companyWebsite?: string) {
    const sessionsUrl = "https://sessions.us/";

    if (!companyWebsite) {
        return sessionsUrl;
    }

    const validWebsite = await apollo.client.query({
        query: CheckUrlDocument,
        variables: {url: companyWebsite},
    });

    if (validWebsite.data.iframeCheckUrl?.isFrameable !== true) {
        return sessionsUrl;
    }

    return companyWebsite;
}

export async function createWebsiteShowCaseArtifact(userId: string, website?: string) {
    const validWebsite = await getValidWebsiteUrl(website);

    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Showcase a website",
        descriptionJson: createSimpleDescription("Interact with a website directly in your session."),
    });

    const artifactId = makeId();
    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/iframe",
            isConfigured: true,
            properties: [
                createProperty("isScrollable", "true"),
                createProperty("enableParticipantControls", "true"),
                createProperty("doReload", new Date().toISOString()),
            ],
        },
    });

    await setIframeResource(userId, artifactId, "Sessions Showcase", validWebsite);
}

export async function createSmartBoardArtifact(userId: string) {
    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Brainstorm like a pro",
        descriptionJson: createSimpleDescription("Smartboard makes it easy to think as a team."),
    });

    const artifactId = makeId();
    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/blackboard",
            isConfigured: true,
        },
    });

    await setSmartBoardResource(userId, artifactId, "Onboarding Board");
}

export async function createHelpPageArtifact(userId: string) {
    const agendaItemId = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        title: "Need help?",
        descriptionJson: createSimpleDescription("Our Help Center has loads of useful info. Plus, a human is just a click away."),
    });

    const artifactId = makeId();
    designer.api.agendaItem.addArtifact({
        agendaItemId: agendaItemId,
        overrides: {
            id: artifactId,
            artifactId: "flowos/iframe",
            isConfigured: true,
            properties: [
                createProperty("isScrollable", "true"),
                createProperty("enableParticipantControls", "true"),
                createProperty("doReload", new Date().toISOString()),
            ],
        },
    });

    await setIframeResource(userId, artifactId, "Sessions Help", "https://resources.sessions.us/resources/help-center");
}
