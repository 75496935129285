import {createContextProvider} from "@workhorse/api/utils/context";
import {
    ConnectionQuality,
    ConnectionState,
    DataPacket_Kind,
    DisconnectReason,
    LocalParticipant,
    LocalTrackPublication,
    ParticipantEvent,
    RemoteParticipant,
    RemoteTrackPublication,
    Room,
    RoomEvent,
    Track,
} from "livekit-client";
import {RoomEventCallbacks} from "livekit-client/dist/src/room/Room";

import {useMemo} from "react";
import {throttle} from "throttle-debounce";
import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";
import {ActiveSpeakersScore, computeActiveSpeakers, PendingActiveSpeakersVolumes, pickActiveSpeaker} from "./ConferenceActiveSpeakers";
import {mapToInternalConnectionStatus, RoomConnectionStatus} from "./ConferenceRoomConnectionStatus";
import {encodeRoomData, handleRoomData, RoomData, RoomDataQuality, RoomDataType, RoomDataVolume} from "./ConferenceRoomData";
import {roomOptions} from "./ConferenceRoomOptions";
import {
    createParticipantRemoteStatus,
    initializeParticipantRemoteStatus,
    RemoteStatus,
    RemoteStatusMap,
    removeParticipantRemoteStatus,
    updateRemoteStatusMapById,
} from "./ConferenceRoomParticipantStatus";
import {
    createParticipantPublication,
    createScreenShareState,
    initializeParticipantPublications,
    Publication,
    PublicationMap,
    removeParticipantPublication,
    resubscribeCameraPublication,
    SharingState,
    trackLogger,
    updatePublicationMapById,
} from "./ConferenceRoomParticipantTracks";
import {isAnyLocationVisible, updateVisibilityMapById, VisibilityLocations, VisibilityMap} from "./ConferenceRoomVisibility";

type SubscriptionError = NonNullable<Parameters<RoomEventCallbacks["trackSubscriptionFailed"]>[2]>;

export type ParticipantsRoster = Record<string, true | undefined>;

export type RoomStore = {
    room: Room | null;
    isLobby: boolean;
    sessionId: string | null;
    volumes: Record<string, number | undefined>;
    roomStatus: RoomConnectionStatus;
    publicationMap: PublicationMap;
    remoteStatusMap: RemoteStatusMap;
    visibilityMap: VisibilityMap;
    localConnectionQuality: ConnectionQuality;
    sharingState: SharingState;
    activeSpeakersScore: ActiveSpeakersScore;
    joinedParticipants: ParticipantsRoster;
    createRoom: (sessionId: string, isLobby: boolean) => Room;
    deleteRoom: (Room: Room) => void;
    setVisibility: (participantId: string, location: keyof VisibilityLocations, value: boolean) => void;
    sendData: (data: RoomData, kind?: DataPacket_Kind) => void;
    setVolume: (id: string, volume: number) => void;
    disableAllIncomingVideos: boolean;
    pauseIncomingVideos: (enable: boolean) => void;
    lastToggleIncomingVideoAction: "individual" | "global" | "none";
    individualTogglePauseIncomingStream: (pid: string, newCameraPausedState: boolean) => void;
    updateLocalConnectionQuality: (newQuality: ConnectionQuality) => void;
};

export const useRoomStore = create<RoomStore>((set, get) => {
    let scoreTimestamps: Record<string, number | undefined> = {};
    let pendingSpeakerVolumes: PendingActiveSpeakersVolumes = {};
    let activeSpeakerIntervalId: NodeJS.Timeout | null = null;
    const volumeTimeouts: Record<string, NodeJS.Timeout | undefined> = {};

    // ** EVENTS **

    const onTrackPublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
        replacePublication(participant, pub);
        replaceRemoteStatus(participant, pub);
    };

    const onTrackUnpublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
        removePublication(participant, pub);
        removeRemoteStatus(participant, pub);
    };

    const onTrackSubscribed = (track: Track, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
        replacePublication(participant, publication);
        replaceRemoteStatus(participant, publication);
    };

    const onTrackUnsubscribed = (track: Track, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
        replacePublication(participant, publication);
        replaceRemoteStatus(participant, publication);
    };

    const onTrackSubscriptionFailed = (trackSid: string, participant: RemoteParticipant, reason?: SubscriptionError) => {
        const track = participant.getTrackPublicationBySid(trackSid);
        trackLogger.warn("track subscription failed", "-", participant.identity, trackSid, track, reason);
    };

    const onTrackStreamStateChanged = (pub: RemoteTrackPublication, streamState: Track.StreamState, participant: RemoteParticipant) => {
        replacePublication(participant, pub, streamState);
        replaceRemoteStatus(participant, pub);
    };

    const onTrackMuted = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
        trackLogger.debug("track muted", "-", participant.identity, pub);
        replaceRemoteStatus(participant, pub);
    };

    const onTrackUnmuted = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
        trackLogger.debug("track unmuted", "-", participant.identity, pub);
        replaceRemoteStatus(participant, pub);
    };

    const onLocalTrackPublish = (pub: LocalTrackPublication) => {
        const participant = get().room?.localParticipant;

        if (!participant) {
            return;
        }

        replacePublication(participant, pub);
        replaceRemoteStatus(participant, pub);
    };

    const onLocalTrackUnpublished = (pub: LocalTrackPublication) => {
        const participant = get().room?.localParticipant;

        if (!participant) {
            return;
        }

        removePublication(participant, pub);
        removeRemoteStatus(participant, pub);
    };

    const onLocalTrackMuteUnmute = (pub: LocalTrackPublication, participant?: LocalParticipant) => {
        if (!participant) {
            return;
        }

        trackLogger.debug("local track muted", "-", participant.identity, pub);
        replaceRemoteStatus(participant, pub);
    };

    const onLocalTrackMuted = (pub: LocalTrackPublication) => {
        const participant = get().room?.localParticipant;
        trackLogger.debug("local track muted", "-", participant?.identity, pub);
        onLocalTrackMuteUnmute(pub, participant);
    };

    const onLocalTrackUnmuted = (pub: LocalTrackPublication) => {
        const participant = get().room?.localParticipant;
        trackLogger.debug("local track unmuted", "-", participant?.identity, pub);
        onLocalTrackMuteUnmute(pub, participant);
    };

    const onConnectionQualityChanged = (quality: ConnectionQuality, participant: RemoteParticipant) => {
        // we thought of only reacting to ConnectionQuality.Lost if succesfully implemented scoring algo
        // check MeetingJoiner.tsx for implementation of webrtc-issue-detector
        // if (quality === ConnectionQuality.Lost) {
        setParticipantRemoteStatus(participant.identity, {
            connectionQuality: quality,
        });
        // }
    };

    const onParticipantConnected = (participant: RemoteParticipant) => {
        const isLobby = get().isLobby;
        const visibilityMap = get().visibilityMap;
        const disableAllIncomingVideos = get().disableAllIncomingVideos;
        const publicationsData = initializeParticipantPublications(
            participant,
            visibilityMap,
            // upon initial connect, the global setting is taken into consideration
            // aka, did the user pause all incoming videos at some point?
            disableAllIncomingVideos
        );

        // if incoming videos are disabled globally, the remote status data
        // of an individual participant is NOT going to have
        const remoteStatusData = initializeParticipantRemoteStatus(participant, disableAllIncomingVideos);
        setParticipantPublications(participant.identity, publicationsData);
        setParticipantRemoteStatus(participant.identity, remoteStatusData);
        addParticipantToRoster(participant.identity);
    };

    const onParticipantDisconnected = (participant: RemoteParticipant) => {
        removeParticipant(participant.identity);
        removeParticipantFromRoster(participant.identity);
    };

    const onVolumeData = (data: RoomDataVolume, id: string) => {
        setVolume(id, data.volume);
    };

    const onQualityData = (data: RoomDataQuality, id: string) => {
        const statusMap = get().remoteStatusMap[id];
        if (statusMap) {
            setParticipantRemoteStatus(id, {
                connectionQuality: data.quality,
            });
        }
    };

    const onData = (data: Uint8Array, participant?: RemoteParticipant) => {
        handleRoomData(data, participant, {
            onVolumeData,
            onQualityData,
        });
    };

    const onConnect = async () => {
        const room = get().room;
        const sessionId = get().sessionId;

        const publicationMap: PublicationMap = {};
        const remoteStatusMap: RemoteStatusMap = {};

        const participants = room?.remoteParticipants.values() ?? [];
        const visibilityMap = get().visibilityMap;
        const localParticipant = room?.localParticipant;
        const disableAllIncomingVideos = get().disableAllIncomingVideos;

        const joinedParticipants: Record<string, true | undefined> = {};

        for (const participant of participants) {
            joinedParticipants[participant.identity] = true;
            publicationMap[participant.identity] = initializeParticipantPublications(participant, visibilityMap, disableAllIncomingVideos);
            remoteStatusMap[participant.identity] = initializeParticipantRemoteStatus(participant, disableAllIncomingVideos);
        }

        if (localParticipant) {
            joinedParticipants[localParticipant.identity] = true;
            publicationMap[localParticipant.identity] = initializeParticipantPublications(localParticipant, visibilityMap, false);
            remoteStatusMap[localParticipant.identity] = initializeParticipantRemoteStatus(localParticipant, disableAllIncomingVideos);
        }

        const sharingState = createScreenShareState(publicationMap, localParticipant?.identity);

        set({
            publicationMap,
            remoteStatusMap,
            sharingState,
            joinedParticipants,
            roomStatus: mapToInternalConnectionStatus(ConnectionState.Connected),
        });
    };

    // ** HELPERS **

    const addParticipantToRoster = (participantId: string) => {
        set((state) => {
            if (state.joinedParticipants[participantId] === true) {
                return state;
            }
            return {
                joinedParticipants: {
                    ...state.joinedParticipants,
                    [participantId]: true,
                },
            };
        });
    };

    const removeParticipantFromRoster = (participantId: string) => {
        set((state) => {
            if (state.joinedParticipants[participantId] == null) {
                return state;
            }
            return {
                joinedParticipants: {
                    ...state.joinedParticipants,
                    [participantId]: undefined,
                },
            };
        });
    };

    const replacePublication = (
        participant: RemoteParticipant | LocalParticipant,
        pub: RemoteTrackPublication | LocalTrackPublication,
        streamState?: Track.StreamState
    ) => {
        const visibilityMap = get().visibilityMap;
        const publications = get().publicationMap[participant.identity];
        const statusMap = get().remoteStatusMap[participant.identity];
        const disableAllIncomingVideos = get().disableAllIncomingVideos;

        const disableIncomingVideo = !statusMap
            ? disableAllIncomingVideos
            : get().lastToggleIncomingVideoAction !== "individual"
            ? disableAllIncomingVideos
            : !!statusMap?.cameraPaused;
        const publicationsData = createParticipantPublication(
            participant,
            pub,
            publications,
            visibilityMap,
            disableIncomingVideo,
            streamState
        );
        setParticipantPublications(participant.identity, publicationsData);
    };

    const removePublication = (participant: RemoteParticipant | LocalParticipant, pub: RemoteTrackPublication | LocalTrackPublication) => {
        const publications = get().publicationMap[participant.identity];
        const publicationsData = removeParticipantPublication(publications, pub.source);
        setParticipantPublications(participant.identity, publicationsData);
    };

    const replaceRemoteStatus = (
        participant: RemoteParticipant | LocalParticipant,
        pub: RemoteTrackPublication | LocalTrackPublication
    ) => {
        const currentStatus = get().remoteStatusMap[participant.identity];
        const disableAllIncomingVideos = get().disableAllIncomingVideos;
        const lastToggleIncomingVideoAction = get().lastToggleIncomingVideoAction;
        const pauseIncomingCamera = !currentStatus
            ? disableAllIncomingVideos
            : lastToggleIncomingVideoAction !== "individual"
            ? disableAllIncomingVideos
            : !!currentStatus?.cameraPaused;
        const remoteStatusData = createParticipantRemoteStatus(currentStatus, pub, pauseIncomingCamera);
        setParticipantRemoteStatus(participant.identity, remoteStatusData);
    };

    const removeRemoteStatus = (participant: RemoteParticipant | LocalParticipant, pub: RemoteTrackPublication | LocalTrackPublication) => {
        const currentStatus = get().remoteStatusMap[participant.identity];
        const remoteStatusData = removeParticipantRemoteStatus(currentStatus, pub);
        setParticipantRemoteStatus(participant.identity, remoteStatusData);
    };

    const replaceScreenShareState = (publications: PublicationMap) => {
        const localParticipant = get().room?.localParticipant;
        const sharingState = createScreenShareState(publications, localParticipant?.identity);
        set({sharingState});
    };

    const initializedActiveSpeakers = () => {
        // if (activeSpeakerIntervalId) {
        //     clearInterval(activeSpeakerIntervalId);
        // }
        // activeSpeakerIntervalId = setInterval(() => {
        //     set((state) => ({
        //         activeSpeakersScore: depreciateActiveSpeakers(state.activeSpeakersScore, scoreTimestamps),
        //     }));
        // }, 2000);
    };
    const pickActiveSpeakers = throttle(3000, () => {
        const current = get().activeSpeakersScore;
        const pickedId = pickActiveSpeaker(current, pendingSpeakerVolumes);

        if (!pickedId) {
            pendingSpeakerVolumes = {};
            return;
        }

        const volume = pendingSpeakerVolumes[pickedId] ?? 0;
        scoreTimestamps[pickedId] = Date.now();
        pendingSpeakerVolumes = {};

        set({
            activeSpeakersScore: computeActiveSpeakers(current, pickedId, volume),
        });
    });

    const updateActiveSpeakers = (id: string, volume: number) => {
        let currentPendingVolume = pendingSpeakerVolumes[id];

        if (currentPendingVolume == null) {
            currentPendingVolume = volume;
        } else {
            currentPendingVolume += volume;
            currentPendingVolume /= 2;
        }
        pendingSpeakerVolumes[id] = currentPendingVolume;
        pickActiveSpeakers();
    };

    const stopActiveSpeakers = () => {
        scoreTimestamps = {};
        pendingSpeakerVolumes = {};

        if (activeSpeakerIntervalId) {
            clearInterval(activeSpeakerIntervalId);
            activeSpeakerIntervalId = null;
        }
    };

    // ** STATE UPDATES **

    const setParticipantPublications = (id: string, data: Partial<Publication>) => {
        set((state) => ({
            publicationMap: updatePublicationMapById(state.publicationMap, id, data),
        }));
        replaceScreenShareState(get().publicationMap);
    };

    const setParticipantRemoteStatus = (id: string, status: Partial<RemoteStatus>) => {
        set((state) => ({
            remoteStatusMap: updateRemoteStatusMapById(state.remoteStatusMap, id, status),
        }));
    };

    const removeParticipant = (id: string) => {
        set((state) => {
            let newPublicationMap = state.publicationMap;
            let newRemoteStatusMap = state.remoteStatusMap;
            let newSharingState = state.sharingState;

            if (state.publicationMap[id]) {
                newPublicationMap = {...state.publicationMap, [id]: undefined};
            }

            if (state.remoteStatusMap[id]) {
                newRemoteStatusMap = {...state.remoteStatusMap, [id]: undefined};
            }

            if (state.sharingState.identity === id) {
                newSharingState = {};
            }

            return {
                publicationMap: newPublicationMap,
                remoteStatusMap: newRemoteStatusMap,
                sharingState: newSharingState,
            };
        });
    };

    const setVisibility = (id: string, location: keyof VisibilityLocations, value: boolean) => {
        set((state) => {
            const changed = updateVisibilityMapById(state.visibilityMap, id, location, value);

            if (changed === false) {
                return state;
            }

            const disableIncomingVideo =
                state.lastToggleIncomingVideoAction !== "individual"
                    ? state.disableAllIncomingVideos
                    : !!state.remoteStatusMap[id]?.cameraPaused;

            resubscribeCameraPublication(state.room?.getParticipantByIdentity(id), changed, disableIncomingVideo);

            return {visibilityMap: changed};
        });
    };

    const setVolume = (id: string, volume: number) => {
        const currentVolume = get().volumes[id];

        if (currentVolume === volume && volume === 0) {
            return;
        }

        const timeout = volumeTimeouts[id];

        if (timeout) {
            clearTimeout(timeout);
        }

        volumeTimeouts[id] = setTimeout(() => {
            setVolume(id, 0);
        }, 50);

        set((state) => ({
            volumes: {...state.volumes, [id]: volume},
        }));

        updateActiveSpeakers(id, volume);
    };

    const sendData = (data: RoomData, kind = DataPacket_Kind.RELIABLE) => {
        const room = get().room;
        const status = get().roomStatus;

        if (!room || !status.connected) {
            return;
        }

        room.localParticipant.publishData(encodeRoomData(data), {
            reliable: false,
        });
    };

    const onDisconnect = (reason: DisconnectReason) => {
        const state: RoomConnectionStatus["state"] =
            reason === DisconnectReason.DUPLICATE_IDENTITY ? "joinedFromAnotherDevice" : ConnectionState.Disconnected;
        set({roomStatus: mapToInternalConnectionStatus(state)});
    };

    const onRoomStateChanged = (state: ConnectionState) => {
        const status = get().roomStatus;

        console.log("ROOM STATUS CHANGED", {current: status.state, incoming: state});

        if (state === ConnectionState.Connected) {
            onConnect();
        }

        if (state === ConnectionState.Connecting) {
            set({roomStatus: mapToInternalConnectionStatus(state)});
        }

        if (state === ConnectionState.Reconnecting) {
            set({roomStatus: mapToInternalConnectionStatus(state)});
        }
    };

    const handleListeners = (room: Room, action: "on" | "off") => {
        const handleRoomListener: Room[typeof action] = (e, listener) => {
            if (action === "on") {
                room.on(e, listener);
            } else {
                room.off(e, listener);
            }
            return room;
        };
        const handleParticipantListener: LocalParticipant[typeof action] = (e, listener) => {
            if (action === "on") {
                room.localParticipant.on(e, listener);
            } else {
                room.localParticipant.off(e, listener);
            }
            return room.localParticipant;
        };

        handleRoomListener(RoomEvent.Disconnected, onDisconnect);
        handleRoomListener(RoomEvent.ConnectionStateChanged, onRoomStateChanged);

        handleRoomListener(RoomEvent.ParticipantConnected, onParticipantConnected);
        handleRoomListener(RoomEvent.ParticipantDisconnected, onParticipantDisconnected);

        handleRoomListener(RoomEvent.TrackPublished, onTrackPublished);
        handleRoomListener(RoomEvent.TrackUnpublished, onTrackUnpublished);
        handleRoomListener(RoomEvent.TrackMuted, onTrackMuted);
        handleRoomListener(RoomEvent.TrackUnmuted, onTrackUnmuted);
        handleRoomListener(RoomEvent.TrackStreamStateChanged, onTrackStreamStateChanged);
        handleRoomListener(RoomEvent.TrackSubscribed, onTrackSubscribed);
        handleRoomListener(RoomEvent.TrackUnsubscribed, onTrackUnsubscribed);
        handleRoomListener(RoomEvent.TrackSubscriptionFailed, onTrackSubscriptionFailed);

        handleRoomListener(RoomEvent.DataReceived, onData);

        handleRoomListener(RoomEvent.ConnectionQualityChanged, onConnectionQualityChanged);

        handleParticipantListener(ParticipantEvent.LocalTrackPublished, onLocalTrackPublish);
        handleParticipantListener(ParticipantEvent.LocalTrackUnpublished, onLocalTrackUnpublished);
        handleParticipantListener(ParticipantEvent.TrackMuted, onLocalTrackMuted);
        handleParticipantListener(ParticipantEvent.TrackUnmuted, onLocalTrackUnmuted);
    };

    const addListeners = (room: Room) => {
        handleListeners(room, "on");
    };

    const removeListeners = (room: Room) => {
        handleListeners(room, "off");
    };

    const pauseIncomingVideos = (newState: boolean) => {
        set({
            disableAllIncomingVideos: newState,
            lastToggleIncomingVideoAction: "global",
        });
        const map = get().publicationMap;
        const visibilityMap = get().visibilityMap;
        for (const pid of Object.keys(map)) {
            const pub = map[pid];
            // no camera or is local
            if (!pub?.camera || pub.camera instanceof LocalTrackPublication) {
                continue;
            }
            setParticipantRemoteStatus(pid, {cameraPaused: newState});
            // paused = true
            if (newState) {
                pub.camera.setEnabled(false);
                continue;
            }
            // paused = false
            if (isAnyLocationVisible(visibilityMap[pid])) {
                pub.camera.setEnabled(true);
            }
        }
    };

    const individualTogglePauseIncomingStream = (pid: string, newCameraPausedState: boolean) => {
        const pub = get().publicationMap[pid];
        set((state) => ({
            lastToggleIncomingVideoAction: "individual",
            remoteStatusMap: updateRemoteStatusMapById(state.remoteStatusMap, pid, {
                cameraPaused: newCameraPausedState,
            }),
        }));
        pub?.camera?.setEnabled(!newCameraPausedState);
    };

    const updateLocalConnectionQuality = (newQuality: ConnectionQuality) => {
        const myPid = get().room?.localParticipant.identity;
        if (myPid) {
            setParticipantRemoteStatus(myPid, {
                connectionQuality: newQuality,
            });
            sendData(
                {
                    type: RoomDataType.Quality,
                    quality: newQuality,
                },
                DataPacket_Kind.LOSSY
            );
        }
    };

    const createRoom = (sessionId: string, isLobby: boolean) => {
        const room = new Room(roomOptions);

        initializedActiveSpeakers();
        addListeners(room);

        set({
            ...initialState,
            room,
            isLobby,
            sessionId,
            pauseIncomingVideos,
            individualTogglePauseIncomingStream,
            updateLocalConnectionQuality,
            roomStatus: mapToInternalConnectionStatus(room.state),
        });

        return room;
    };

    const deleteRoom = (room: Room) => {
        stopActiveSpeakers();
        removeListeners(room);

        set((state) => {
            // Already deleted
            if (state.room == null) {
                return state;
            }

            // Not the same room, prevent race condition
            if (state.room !== room) {
                return state;
            }

            return initialState;
        });
    };

    const initialState: Omit<
        RoomStore,
        "visibilityMap" | "setVisibility" | "createRoom" | "deleteRoom" | "sendData" | "setVolume" | "individualTogglePauseIncomingStream"
    > = {
        room: null,
        sessionId: null,
        isLobby: false,
        volumes: {},
        roomStatus: mapToInternalConnectionStatus("initial"),
        publicationMap: {},
        remoteStatusMap: {},
        sharingState: {},
        activeSpeakersScore: {},
        joinedParticipants: {},
        localConnectionQuality: ConnectionQuality.Unknown,
        disableAllIncomingVideos: false,
        pauseIncomingVideos,
        updateLocalConnectionQuality,
        lastToggleIncomingVideoAction: "none",
    };

    return {
        ...initialState,
        visibilityMap: {},
        createRoom,
        deleteRoom,
        setVisibility,
        sendData,
        setVolume,
        individualTogglePauseIncomingStream,
        updateLocalConnectionQuality,
    };
});

function useConferenceRoomStore() {
    const {room, roomStatus, createRoom, deleteRoom} = useRoomStore(
        useShallow((state) => ({
            room: state.room,
            roomStatus: state.roomStatus,
            createRoom: state.createRoom,
            deleteRoom: state.deleteRoom,
        }))
    );

    return useMemo(
        () => ({
            room,
            roomStatus,
            createRoom,
            deleteRoom,
        }),
        [room, roomStatus, createRoom, deleteRoom]
    );
}

export const [ConferenceRoomProvider, useConferenceRoom] = createContextProvider(
    {
        name: "ConferenceRoomProvider",
    },
    useConferenceRoomStore
);
