import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import MenuItem from "@ui/cdk/MenuItem";
import {cls} from "@ui/cdk/util";
import React, {forwardRef} from "@workhorse/api/rendering";
import {useFullScreenState} from "@workhorse/providers/state";
import {exitFullScreen, requestFullScreen} from "@workhorse/util";
import {useTranslation} from "react-i18next";

type FullScreenTogglerProps = {
    disabled?: boolean;
    className?: string;
};

const FullScreenToggler = forwardRef((props: FullScreenTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {disabled} = props;

    const isFullScreen = useFullScreenState();

    const setFullScreen = () => {
        const root = document.documentElement;
        if (root && !isFullScreen) {
            requestFullScreen();
        } else if (isFullScreen) {
            exitFullScreen();
        }
    };

    const className = cls(props.className, isFullScreen && !disabled && "active-blue");

    return (
        <MenuItem
            className={className}
            onClick={setFullScreen}
            disabled={disabled}
            button
            ref={ref}
            data-id="player-button-full-screen"
            role="button"
            tabIndex={0}
            aria-label={t("participant.fullscreen.toggle_fullscreen") ?? "Toggle fullscreen"}
        >
            {!isFullScreen ? <FullscreenIcon fontSize="small" /> : <FullscreenExitIcon fontSize="small" />}
        </MenuItem>
    );
});

export default FullScreenToggler;
