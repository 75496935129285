import {
    SetSessionHardMuteDocument,
    SetShareScreenParticipantIdDocument,
    SetAllowScreenShareDocument,
    SendSessionEventDocument,
    MuteParticipantDocument,
    StopCameraParticipantDocument,
    EnforceSpotlightDocument,
    SetEnableReactionsDocument,
    SetSessionDisableParticipantsVideoDocument,
    SessionFlagsType,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";

export const setEnableReactions = (sessionId: string, enableReactionsType: SessionFlagsType) => {
    return apollo.client.mutate({
        mutation: SetEnableReactionsDocument,
        variables: {
            sessionId,
            enableReactionsType,
        },
        fetchPolicy: "no-cache",
    });
};

// TODO: advanced settings
export const setAllowScreenShare = (sessionId: string, allowScreenShareType: SessionFlagsType) => {
    return apollo.client.mutate({
        mutation: SetAllowScreenShareDocument,
        variables: {
            sessionId,
            allowScreenShareType,
        },
        fetchPolicy: "no-cache",
    });
};

export const setShareScreenParticipantId = (sessionId: string, participantId: string | null) => {
    apollo.client.mutate({
        mutation: SetShareScreenParticipantIdDocument,
        variables: {
            sessionId,
            stopPresenting: participantId == null ? true : false,
        },
        fetchPolicy: "no-cache",
    });
};

export const softMuteAllParticipants = (sessionId: string, participantsIds: string[]) => {
    return apollo.client.mutate({
        mutation: SendSessionEventDocument,
        variables: {
            sessionId,
            participantsIds,
            data: {
                softMuteAll: true,
            },
        },
        fetchPolicy: "no-cache",
    });
};

export const softStopCameraAllParticipants = (sessionId: string, participantsIds: string[]) => {
    return apollo.client.mutate({
        mutation: SendSessionEventDocument,
        variables: {
            sessionId,
            participantsIds,
            data: {
                softStopCameraAll: true,
            },
        },
        fetchPolicy: "no-cache",
    });
};

export const emitStopScreenShare = (sessionId: string, participantsIds: string[]) => {
    apollo.client.mutate({
        mutation: SendSessionEventDocument,
        variables: {
            sessionId,
            participantsIds,
            data: {
                stopScreenShare: true,
            },
        },
        fetchPolicy: "no-cache",
    });
};

export const setSessionHardMute = (sessionId: string, hardMute: SessionFlagsType) => {
    return apollo.client.mutate({
        mutation: SetSessionHardMuteDocument,
        variables: {
            sessionId,
            hardMute,
        },
        fetchPolicy: "no-cache",
    });
};

export const setSessionDisableParticipantsVideo = (sessionId: string, allowCameraType: SessionFlagsType) => {
    return apollo.client.mutate({
        mutation: SetSessionDisableParticipantsVideoDocument,
        variables: {
            sessionId,
            allowCameraType,
        },
        fetchPolicy: "no-cache",
    });
};

export const muteParticipant = (participantId: string) => {
    return apollo.client.mutate({
        mutation: MuteParticipantDocument,
        variables: {
            participantId,
        },
        fetchPolicy: "no-cache",
    });
};

export const stopCameraParticipant = (participantId: string) => {
    return apollo.client.mutate({
        mutation: StopCameraParticipantDocument,
        variables: {
            participantId,
        },
        fetchPolicy: "no-cache",
    });
};

export const enforceSpotlight = (artifactId: string, mode: "true" | "false") => {
    return apollo.client.mutate({
        mutation: EnforceSpotlightDocument,
        variables: {
            artifactId,
            mode,
        },
    });
};
