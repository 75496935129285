import apollo from "@workhorse/api/apollo";
import {GetUserCustomBackgroundsDocument, LocalUserDocument} from "@generated/data";
import {VideoFxConfig} from "./VideoReplacementConfig";

export enum BackgroundFilter {
    NONE = "0",
    BLUR = "1",
    LIGHTBLUR = "2",
    CUSTOM = "CUSTOM",
    ACOUSTIC = "Acoustic.jpg",
    ACOUSTIC2 = "Acoustic-2.jpg",
    DOORS = "Doors.jpg",
    DOORS2 = "Doors-2.jpg",
    FLAMINGOS = "Flamingos.jpg",
    FRAMES = "Frames.jpg",
    LIBRARY = "Library.jpg",
    LIVINGROOM = "Livingroom.jpg",
    LIVINGROOM2 = "Livingroom-2.jpg",
    LIVINGROOM3 = "Livingroom-3.jpg",
    LIVINGROOM4 = "Livingroom-4.jpg",
    MINIMAL = "Minimal.jpg",
    MINIMALIST = "Minimalist.jpg",
    OFFICE = "Office.jpg",
    OFFICE2 = "Office-2.jpg",
    OPEN = "Open.jpg",
    PATTERN = "Pattern.jpg",
    SKYNET = "Skynet.jpg",
    SKYNET2 = "Skynet-2.jpg",
    SKYNET3 = "Skynet-3.jpg",
    SUMMER = "Summer.jpg",
    VIEW = "View.jpg",
    WHITE = "White.jpg",
    WINDOWS = "Windows.jpg",
}

export type BackgroundFilterGeneric = Extract<BackgroundFilter, BackgroundFilter.NONE | BackgroundFilter.BLUR | BackgroundFilter.LIGHTBLUR>;

export const backgroundFiltersGeneric: BackgroundFilterGeneric[] = [
    BackgroundFilter.NONE,
    BackgroundFilter.LIGHTBLUR,
    BackgroundFilter.BLUR,
];

export const backgroundImages = Object.values(BackgroundFilter).filter(
    (item) => ![...backgroundFiltersGeneric, BackgroundFilter.CUSTOM].includes(item)
);

export const backgroundPreviewImages = backgroundImages.map((item) => `${item}.preview`);

export const getBackgroundFilterUrl = (filterType: BackgroundFilter, isPreview = false) => {
    const fileName = isPreview ? `${filterType.slice(0, -4).concat(".preview.jpg")}` : filterType;

    return `${window.origin}/virtual-background/${fileName}`;
};

export async function createVideoFxConfig(filterType?: BackgroundFilter, customFilter?: string | null): Promise<VideoFxConfig | undefined> {
    if (filterType == null || filterType === BackgroundFilter.NONE) {
        return;
    }

    if (filterType === BackgroundFilter.LIGHTBLUR) {
        return {
            blur: 0.5,
        };
    }

    if (filterType === BackgroundFilter.BLUR) {
        return {
            blur: 0.8,
        };
    }

    if (filterType !== BackgroundFilter.CUSTOM) {
        return {
            image: getBackgroundFilterUrl(filterType),
        };
    }

    if (!customFilter) {
        return;
    }

    const localUser = await apollo.client.readQuery({
        query: LocalUserDocument,
    });

    const isGuest = localUser?.localUser?.role.toUpperCase() === "GUEST";

    if (isGuest) {
        return;
    }

    const bgs = await apollo.client.query({query: GetUserCustomBackgroundsDocument});
    const img = bgs.data.getRemoteUser.user?.customBackgrounds.find((bg) => bg.id === customFilter);
    const url = img?.url;

    if (!url) {
        return;
    }

    return {
        image: url,
    };
}
