export function createVideoReplacementLogger(namespace: string) {
    return {
        log: (...args: any[]) => {
            console.log(`[video-replacement-${namespace}]`, ...args);
        },
        warn: (...args: any[]) => {
            console.warn(`[video-replacement-${namespace}]`, ...args);
        },
        error: (...args: any[]) => {
            console.error(`[video-replacement-${namespace}]`, ...args);
        },
    };
}
