import {PublicBookingEventFragment} from "@generated/data";
import {cls} from "@ui/cdk/util";
import classes from "../styles/BookEventDateTime.module.scss";
import {TFunction} from "i18next";
import {useTranslation} from "react-i18next";
import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import {useEffect, useMemo} from "@workhorse/api/rendering";
import {getCorrectPlural} from "@workhorse/util/strings";
import BookEventDurationSlots from "./BookEventDurationSlots";
import {setParentNotification} from "@workhorse/api/booking";

type BookEventDurationProps = {
    event: PublicBookingEventFragment;
    duration: number;
    onChangeDuration: (duration: number) => void;
    onNext: () => void;
    onCancel?: () => void;
};

function toDurationOption(duration: number, t: TFunction) {
    if (duration >= 3600) {
        const hours = Math.floor(duration / 3600);
        const minutes = (duration % 3600) / 60;
        return {
            text: `${hours} ${t(`g.time.${getCorrectPlural("hour", hours)}`)}${
                minutes ? ` ${minutes} ${t(`g.time.${getCorrectPlural("minute", minutes)}`)}` : ""
            }`,
            value: duration,
        };
    } else {
        const minutes = duration / 60;
        return {text: `${minutes} ${t(`g.time.${getCorrectPlural("minute", minutes)}`)}`, value: duration};
    }
}

export const BookEventDuration = ({event, duration, onChangeDuration, onNext, onCancel}: BookEventDurationProps) => {
    const {t} = useTranslation();

    const durationOptions = useMemo(() => {
        const options: {text: string; value: number}[] = [toDurationOption(event.duration, t)];

        if (event.additionalDuration) {
            const parsed = event.additionalDuration.map((item) => toDurationOption(item, t));
            options.push(...parsed);
        }

        return options.sort((a, b) => a.value - b.value);
    }, [event.duration, event.additionalDuration, t]);

    useEffect(() => {
        setParentNotification({loaded: true});
    }, []);

    return (
        <>
            <div className={classes.calendar}>
                <Typography className={classes.date}>{t("booking.duration.select_duration")}</Typography>
                <div className={classes.durationContent}>
                    <BookEventDurationSlots options={durationOptions} onSelect={onChangeDuration} duration={duration} />
                </div>
            </div>
            <div className={classes.footer}>
                <div />
                <div className={classes.footerActions}>
                    {onCancel && (
                        <Button className="mr-8" variant="quaternary" onClick={onCancel}>
                            {t("g.cancel")}
                        </Button>
                    )}
                    <Button data-id="confirm" className={cls(classes.footerButton)} onClick={onNext}>
                        {t("g.next")}
                    </Button>
                </div>
            </div>
        </>
    );
};
