import {isAfter, isFuture, differenceInCalendarDays, isPast} from "date-fns";
import {OrganizationPlan} from ".";

export const isReverseTrialActive = (trialCancellationDate: Date | undefined | null) => {
    if (!trialCancellationDate) {
        return false;
    }
    return isAfter(new Date(trialCancellationDate), new Date());
};

export const reverseTrialDaysLeft = (trialCancellationDate: Date | undefined | null) => {
    if (!trialCancellationDate) {
        return 0;
    }

    if (!isFuture(new Date(trialCancellationDate))) {
        return 0;
    }

    return differenceInCalendarDays(new Date(trialCancellationDate), new Date());
};

export const isReverseTrialWeirdState = (trialCancellationDate: Date | undefined | null) => {
    if (!trialCancellationDate) {
        return false;
    }

    return isPast(new Date(trialCancellationDate));
};

export const ltdPlans: OrganizationPlan[] = [
    OrganizationPlan.LtdTier_1,
    OrganizationPlan.LtdTier_2,
    OrganizationPlan.LtdTier_3,
    OrganizationPlan.LtdTier_4,
    OrganizationPlan.LtdTier_5,
    OrganizationPlan.LtdTier_6,
    OrganizationPlan.SessionsSupporter,
];

export const appSumoPlans: OrganizationPlan[] = [
    OrganizationPlan.AppsumoTier_1,
    OrganizationPlan.AppsumoTier_2,
    OrganizationPlan.AppsumoTier_3,
    OrganizationPlan.AppsumoTier_4,
    OrganizationPlan.AppsumoTier_5,
];

export type AppSumoPlan =
    | OrganizationPlan.AppsumoTier_1
    | OrganizationPlan.AppsumoTier_2
    | OrganizationPlan.AppsumoTier_3
    | OrganizationPlan.AppsumoTier_4
    | OrganizationPlan.AppsumoTier_5;

export type LtdPlan =
    | OrganizationPlan.LtdTier_1
    | OrganizationPlan.LtdTier_2
    | OrganizationPlan.LtdTier_3
    | OrganizationPlan.LtdTier_4
    | OrganizationPlan.LtdTier_5
    | OrganizationPlan.LtdTier_6
    | OrganizationPlan.SessionsSupporter;

export const isAppSumoPlan = (plan: OrganizationPlan) => {
    return appSumoPlans.includes(plan);
};

export const isStandardPlan = (plan: OrganizationPlan) => {
    return [OrganizationPlan.Solo, OrganizationPlan.Starter, OrganizationPlan.Pro, OrganizationPlan.Business].includes(plan);
};

export const isUpgradablePlan = (plan: OrganizationPlan) => {
    return ![OrganizationPlan.SessionsSupporter].includes(plan);
};

export const isLtdTierPlan = (plan: OrganizationPlan) => {
    return ltdPlans.includes(plan);
};

export const isLtdPlan = (plan: OrganizationPlan) => {
    return ltdPlans.includes(plan) || appSumoPlans.includes(plan);
};
