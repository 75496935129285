import {useClientEvent} from "@api/events/client";
import svc from "@api/service/client";
import {
    CreateSessionRecordingDocument,
    RecordingProcessingStatus,
    SessionLifecycle,
    SessionRecordingState,
    UpdateSessionRecordingDocument,
    SessionRecordingType,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useMemo} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useQuery, writeQuery} from "@workhorse/dataApi";
import {useEffect, useRef, useState} from "react";
import {v4 as uuid} from "uuid";
import {useRecordingTimer} from "./RecordingTimerProvider";
import {useSession} from "./SessionDataProviders";
import {useHistory, useLocation} from "@workhorse/api/routing";
import {useTranslation} from "react-i18next";

function useRecordingProcessorStore() {
    const {t} = useTranslation();

    const history = useHistory();
    const location = useLocation<{recordingStarted?: boolean}>();

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const recordingStartedParam = searchParams.get("recordingStarted") === "true";

    const recordingStartedState = location.state?.recordingStarted === true;

    const [isWatchdogMounted, setIsWatchdogMounted] = useState(false);

    const {id: currentSessionId, name: sessionName, autoRecording, lifecycle, backstage, actualStart, recordingType} = useSession();

    const isSessionStarted = lifecycle === SessionLifecycle.Started;

    const {data} = useQuery("SessionRecordingDocument", {
        variables: {
            where: {
                id: currentSessionId,
            },
        },
        skip: !currentSessionId,
        fetchPolicy: "cache-and-network",
    });

    console.log("recording data", data);

    const recordingCreatedAt = data?.sessionRecording?.startedAt;

    const currentRecording = data?.sessionRecording;
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isAllowed, setIsAllowed] = useState(true);
    const [showDiskSpaceWarning, setShowDiskSpaceWarning] = useState(false);

    const [sessionId, setSessionId] = useState<string | null>(null);
    const [recordingId, setRecordingId] = useState<string | null>(null);

    useEffect(() => {
        // console.log("currentRecording", currentRecording?.state);
        if (currentRecording?.state === SessionRecordingState.InProgress && !isActive) {
            resumeAfterRefresh();
        }
        if (currentRecording?.state === SessionRecordingState.Completed && isActive) {
            setIsActive(false);
        }
        if (!currentRecording && isActive) {
            setIsActive(false);
        }
    }, [currentRecording, isActive]);

    const [showRetryMessage, setShowRetryMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const onDismissRetryMessage = () => {
        setShowRetryMessage(false);
    };

    const onDismissErrorMessage = () => {
        setShowErrorMessage(false);
    };

    useEffect(() => {
        if (currentRecording?.state === SessionRecordingState.Starting) {
            intervalRef.current = setInterval(() => {
                try {
                    const recordingInfoStr = localStorage.getItem("recordingStartedAt");
                    const recordingInfo = JSON.parse(recordingInfoStr || "{}");
                    if (!recordingInfo.createdAt || recordingInfo.id !== currentRecording.id) {
                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                        }
                        setShowRetryMessage(false);

                        localStorage.removeItem("recordingStartedAt");
                        console.error("No recording info found in localStorage");
                        return;
                    }
                    const createdAt = new Date(recordingInfo.createdAt).getTime();
                    const now = new Date().getTime();
                    const diff = now - createdAt;
                    if (diff > 59 * 2000) {
                        localStorage.removeItem("recordingStartedAt");

                        apollo.client
                            .mutate({
                                mutation: UpdateSessionRecordingDocument,
                                variables: {
                                    recordingId: currentRecording.id,
                                    input: {
                                        state: SessionRecordingState.Stopping,
                                    },
                                },
                            })
                            .then(() => {
                                apollo.cache.evict({
                                    id: currentRecording.id,
                                });
                            });

                        setShowRetryMessage(true);
                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                        }

                        console.error("Recording tool longer than 1 minute to start. Stopping and showing retry popup");
                        return;
                    }
                } catch (e) {
                    if (intervalRef.current) {
                        localStorage.removeItem("recordingStartedAt");
                        clearInterval(intervalRef.current);
                    }
                }
            }, 5000);
        } else if (
            currentRecording?.state === SessionRecordingState.Completed &&
            currentRecording.processingStatus === RecordingProcessingStatus.Error
        ) {
            setShowErrorMessage(true);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [currentRecording]);

    const {startTimer, stopTimer} = useRecordingTimer();
    const recordingState = data?.sessionRecording?.state;
    const resetState = () => {
        stopTimer();
        setIsActive(false);
        setIsPaused(false);
        setRecordingId(null);
        setSessionId(null);
        setIsAllowed(true);
        setShowDiskSpaceWarning(false);
        localStorage.removeItem("recording-in-progress");
    };

    const start = async (sessionId: string, name: string) => {
        try {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            if (showRetryMessage) {
                setShowRetryMessage(false);
            }
            const recordingId = uuid();
            const response = await apollo.client.mutate({
                mutation: CreateSessionRecordingDocument,
                variables: {
                    recordingId,
                    sessionId,
                    extraInput: {name},
                },
            });
            if (!response?.data) {
                const errorText =
                    recordingType === SessionRecordingType.Livestream
                        ? "participant.recording.start_livestreaming_error_2"
                        : recordingType === SessionRecordingType.RecordingLivestream
                        ? "participant.recording.start_livestreaming_recording_error_2"
                        : "participant.recording.start_recording_error_2";
                toast(t(errorText), {type: "error"});
                return false;
            }

            localStorage.setItem("recordingStartedAt", JSON.stringify({id: recordingId, createdAt: new Date().toISOString()}));

            return true;
        } catch (e) {
            if (e instanceof Error && e.name === "QuotaExceededError") {
                setShowDiskSpaceWarning(true);
            }
            // TODO: maca translate this
            // toast(t("participant.recording.start_recording_error"), {type: "error"});
            toast(t(e.message), {type: "error"});
            console.log(e);
            return false;
        }
    };

    const stop = async () => {
        if (data?.sessionRecording?.id) {
            writeQuery("SessionRecordingDocument", {
                variables: {
                    where: {
                        id: currentSessionId,
                    },
                },
                data: {
                    ...data,
                    sessionRecording: {
                        ...data.sessionRecording,
                        state: SessionRecordingState.Completed,
                    },
                },
            });
            await apollo.client.mutate({
                mutation: UpdateSessionRecordingDocument,
                variables: {
                    recordingId: data.sessionRecording.id,
                    input: {
                        state: SessionRecordingState.Stopping,
                    },
                },
            });

            stopTimer();
            setIsActive(false);
            localStorage.removeItem("recording-in-progress");
        }
    };

    const resumeAfterRefresh = () => {
        let startTimerValue = 0;
        if (recordingCreatedAt) {
            const now = new Date();
            const createdAt = new Date(recordingCreatedAt);
            startTimerValue = (now.getTime() - createdAt.getTime()) / 1000;
        }

        console.log("in resume after refresh", startTimerValue);

        startTimer(startTimerValue);
        setIsPaused(false);

        setIsActive(true);
        setSessionId(sessionId);
    };

    const dismissPending = () => {
        localStorage.removeItem("recording-in-progress");
    };

    useClientEvent("watchdog-mounted", (isMounted: boolean) => {
        setIsWatchdogMounted(isMounted);
    });

    // Replace search param with location state, so it doesn't appear in URL.
    // Search param is used when coming from the event page, extension or by opening the URL in a different tab.
    useEffect(() => {
        if (recordingStartedParam) {
            searchParams.delete("recordingStarted");
            history.replace({
                state: {
                    ...location.state,
                    recordingStarted: true,
                },
                search: searchParams.toString(),
            });
        }
    }, [recordingStartedParam, searchParams, location.state, history]);

    // Automatically start recording when the session is started from event page or instant session
    useEffect(() => {
        if (
            isSessionStarted &&
            (!isActive || currentRecording?.state === SessionRecordingState.InProgress) &&
            autoRecording &&
            recordingStartedState &&
            actualStart &&
            isWatchdogMounted
        ) {
            const {recordingStarted, ...restState} = location.state;

            history.replace({
                state: {
                    ...restState,
                },
            });
        }
    }, [
        actualStart,
        isWatchdogMounted,
        autoRecording,
        isActive,
        isSessionStarted,
        recordingStartedState,
        location.state,
        history,
        currentRecording?.state,
    ]);

    return {
        start,
        stop,
        isActive,
        isPaused,
        recordingType,
        recordingId,
        isAllowed,
        showDiskSpaceWarning,
        dismissPending,
        resumeAfterRefresh,
        resetState,
        recordingState,
        showRetryMessage,
        showErrorMessage,
        onDismissErrorMessage,
        onDismissRetryMessage,
    };
}

export const [RecordingProcessorProvider, useRecordingProcessor] = createContextProvider(
    {
        name: "RecordingProcessor",
    },
    useRecordingProcessorStore
);
