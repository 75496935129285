import {createContext, ReactNode, useCallback, useContext, useState} from "@workhorse/api/rendering";

function useMediaMuteStore() {
    const [mediaMuted, setMediaMute] = useState(false);

    const toggleMediaMute = useCallback(() => {
        setMediaMute((value) => !value);
    }, []);

    return {
        mediaMuted,
        setMediaMute,
        toggleMediaMute,
    };
}

type MediaMuteStore = ReturnType<typeof useMediaMuteStore>;
const MediaMuteContext = createContext<MediaMuteStore | null>(null);

export function MediaMuteProvider({children}: {children: ReactNode}) {
    const value = useMediaMuteStore();
    return <MediaMuteContext.Provider value={value}>{children}</MediaMuteContext.Provider>;
}

export const useMediaMute = () => {
    const value = useContext(MediaMuteContext);
    if (!value) {
        throw new Error("Cannot use 'useMediaMute' hook without 'MediaMuteProvider'");
    }
    return value as MediaMuteStore;
};
