import {debounce} from "@workhorse/api/utils/debouncer";

export enum SoundActionType {
    ParticipantEntered = "ParticipantEntered",
    Notification = "Notification",
    SessionEnding = "SessionEnding",
    SessionStarted = "SessionStarted",
    RequestToJoin = "RequestToJoin",
}

export const playSoundAction = async (action?: SoundActionType) => {
    const audio = new Audio();
    audio.muted = true;
    audio.volume = 0;
    switch (action) {
        case SoundActionType.ParticipantEntered:
            audio.src = window.location.origin + "/bubble-pop-swoosh-5.mp3";
            break;
        case SoundActionType.Notification:
            audio.src = window.location.origin + "/plastic-tube-container-lid-pop-open.mp3";
            break;
        case SoundActionType.SessionEnding:
            audio.src = window.location.origin + "/bubble-pop-swoosh-5.mp3";
            break;
        case SoundActionType.SessionStarted:
            audio.src = window.location.origin + "/plastic-tube-container-lid-pop-open.mp3";
            break;
        case SoundActionType.RequestToJoin:
            audio.src = window.location.origin + "/request-to-join.mp3";
            break;
        default:
            break;
    }
    try {
        audio.muted = false;
        audio.volume = 0.2;
        await audio.play().catch((e) => {
            console.log(`[PlaySoundErrorPromiseCatch]: action=${action}, error=${e?.message}`);
        });
    } catch (e) {
        console.log(`[PlaySoundErrorTryCatch]: action=${action}, error=${e?.message}`);
    }
};

export const playSoundActionDebounced = (action: SoundActionType, delay: number | undefined = 1500) =>
    debounce(() => playSoundAction(action), delay)();
