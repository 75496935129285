import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import {useState} from "@workhorse/api/rendering";
import classes from "./styles/SessionContent.module.scss";

// this is the ratio for 1720 x 720 = 2.3(8)
// 16/9 would be 1.7(7) and so on
const myTool = {ratio: 2.388, headerHeight: 59};

function SizerForIframeInMicros() {
    const [root] = useState<HTMLDivElement | null>(() => document.querySelector(":root"));

    const [setObservedNode] = useLocalResizeObserver((width, height) => {
        let myToolIframeHeight = height - myTool.headerHeight;
        let myToolIframeWidth = myToolIframeHeight * myTool.ratio;

        let myToolOuterHeight = myToolIframeHeight + myTool.headerHeight;
        let myToolOuterWidth = myToolIframeWidth;

        let myToolIframeNoControlWidth = height * myTool.ratio;
        let myToolIframeNoControlHeight = height;

        if (myToolIframeWidth > width) {
            myToolIframeWidth = width;
            myToolOuterWidth = width;
            myToolIframeHeight = myToolIframeWidth / myTool.ratio;
            myToolOuterHeight = myToolIframeHeight + myTool.headerHeight;
        }

        if (myToolIframeNoControlWidth > width) {
            myToolIframeNoControlWidth = width;
            myToolIframeNoControlHeight = width / myTool.ratio;
        }

        root?.style.setProperty("--app-micro-height", `${height}px`);
        root?.style.setProperty("--app-micro-width", `${width}px`);
        root?.style.setProperty("--app-mytool-iframe-height", `${myToolIframeHeight}px`);
        root?.style.setProperty("--app-mytool-iframe-width", `${myToolIframeWidth}px`);
        root?.style.setProperty("--app-mytool-outer-height", `${myToolOuterHeight}px`);
        root?.style.setProperty("--app-mytool-outer-width", `${myToolOuterWidth}px`);
        root?.style.setProperty("--app-mytool-nocontrol-height", `${myToolIframeNoControlHeight}px`);
        root?.style.setProperty("--app-mytool-nocontrol-width", `${myToolIframeNoControlWidth}px`);
    });
    return <div className={classes.sizerForMicros} ref={setObservedNode} />;
}

export default SizerForIframeInMicros;
