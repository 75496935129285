import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {useDevices} from "@workhorse/api/conference2";
import {forwardRef} from "@workhorse/api/rendering";
import {ReactComponent as NoiseReductionEnabled} from "../../../../../../assets/media/noise-reduction-enabled.svg";
import {ReactComponent as NoiseReductionDisabled} from "../../../../../../assets/media/noise-reduction-disabled.svg";
import {useTranslation} from "react-i18next";

function NoiseReductionOn() {
    const {t} = useTranslation();
    return (
        <>
            <NoiseReductionEnabled />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.mic.disable_noise_reduction")}
            </Typography>
        </>
    );
}

function NoiseReductionOff() {
    const {t} = useTranslation();
    return (
        <>
            <NoiseReductionDisabled />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.mic.enable_noise_reduction")}
            </Typography>
        </>
    );
}

const NoiseReductionOption = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {noiseReduction, isVoiceFocusSupported, setNoiseReduction} = useDevices();

    const handleOnToggle = () => {
        setNoiseReduction((enabled) => !enabled);
    };

    if (!isVoiceFocusSupported) {
        return null;
    }

    return (
        <MenuItem ref={ref} onClick={handleOnToggle}>
            {noiseReduction && <NoiseReductionOn />}
            {!noiseReduction && <NoiseReductionOff />}
        </MenuItem>
    );
});

export default NoiseReductionOption;
