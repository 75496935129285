import {makeVar, useReactiveVar} from "@workhorse/api/data";
import {enableEndSessionModal} from "./header/headerUtils";
import {useHistory} from "@workhorse/api/routing";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {ReactComponent as WarningIcon} from "../assets/media/Warning.svg";
import React from "react";
import {Location, Action} from "history";
import {useUserInfo} from "@workhorse/providers/User";

export const disableNativePopup = makeVar<boolean>(false);

type History = ReturnType<typeof useHistory>;

interface LeavePageNativeProps {
    title: string;
    content: string;
    sessionId?: string;
    disabled?: boolean;
    parentSessionId?: string;
    participantId?: string;
    participantIdInParent?: string;
}

interface BlockerProps {
    title: string;
    content: string;
    history: History;
    isGuest: boolean;
    participantId?: string;
    participantIdInParent?: string;
    sessionId?: string;
    shouldBlock: boolean;
    parentSessionId?: string;
}

interface BlockerState {
    showPrompt: boolean;
    nextPath: string;
}

class RouterBlocker extends React.Component<BlockerProps, BlockerState> {
    constructor(props) {
        super(props);
        this.state = {
            nextPath: "",
            showPrompt: false,
        };
    }

    mounted = true;

    get history() {
        return this.props.history;
    }

    shouldBlock = (location: Location<{skipBlock?: boolean} | null>) => {
        const skipBlock = location.state?.skipBlock === true;
        return this.props.shouldBlock && this.mounted && !skipBlock;
    };

    blockCallback = (location, action: Action) => {
        if (!this.shouldBlock(location) || action !== "POP") {
            return;
        }

        this.setState({
            showPrompt: true,
            nextPath: location.pathname,
        });

        return false;
    };

    block = () => {
        this.unblock = this.history.block(this.blockCallback);
    };

    unblock = () => {
        // do nothing
    };

    componentDidMount = () => {
        this.block();
    };

    componentWillUnmount = () => {
        this.mounted = false;
        this.unblock();
    };

    handleConfirm = () => {
        this.unblock();
        const {participantId, parentSessionId, participantIdInParent, sessionId} = this.props;

        if (this.props.isGuest && (parentSessionId ?? sessionId)) {
            this.history.push(`/feedback/${parentSessionId ?? sessionId}/${participantIdInParent ?? participantId}`, {
                dontUseCacheData: true,
            });
            return;
        }

        if (this.state.nextPath) {
            this.history.push(this.state.nextPath, {
                dontUseCacheData: true,
            });
        }
    };

    handleCancel = () => {
        this.unblock();

        const unregister = this.history.listen(() => {
            this.block();
            unregister();
        });

        this.history.goForward();

        this.setState({
            showPrompt: false,
            nextPath: "",
        });
    };

    render() {
        return (
            <ConfirmationDialog
                open={this.state.showPrompt}
                title={this.props.title}
                content={this.props.content}
                Icon={WarningIcon}
                cancelButton="Cancel"
                submitButton="Confirm"
                disableBackdropClick
                cancelButtonVariant="destructive-secondary"
                onClose={this.handleCancel}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
            />
        );
    }
}

const LeavePageNative = (props: LeavePageNativeProps) => {
    const {title, content, disabled, sessionId, parentSessionId, participantId, participantIdInParent} = props;
    const popupDisabled = useReactiveVar(disableNativePopup);
    const endSessionModal = useReactiveVar(enableEndSessionModal);
    const forcePrompt = !popupDisabled && !endSessionModal && disabled !== true;

    const {isGuest} = useUserInfo();

    const history = useHistory();

    return (
        <RouterBlocker
            shouldBlock={forcePrompt}
            title={title}
            content={content}
            history={history}
            isGuest={isGuest}
            participantId={participantId}
            participantIdInParent={participantIdInParent}
            sessionId={sessionId}
            parentSessionId={parentSessionId}
        />
    );
};

export default LeavePageNative;
