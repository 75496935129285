import {Participant} from "@generated/data";
import {RefCallback, useCallback, useRef} from "@workhorse/api/rendering";
import {DeepPartial} from "@common/declarations";
import {fullScreenToggle} from "@workhorse/providers/state";

export * from "./Empty";
export * from "./artifacts";

export const convertToProperties = (values: Object, exclude?: Array<string>) => {
    const returnValue = Object.fromEntries(Object.entries(values).filter(([key]) => !["data", ...(exclude || [])].includes(key)));

    if (values["data"]) {
        returnValue["properties"] = Object.keys(values["data"]).map((key) => ({
            key,
            value: values["data"][key],
        }));
    }

    return returnValue;
};

export function checkMandatoryProperties(args: Array<any>): boolean {
    return args.every((arg) => arg && arg !== "");
}

export function checkInitialProperties(properties: Object, artifactData: Object): Array<{key: string; value: string}> {
    const propertiesToAdd: Object = {};
    Object.keys(properties).forEach((key) => {
        if (!artifactData.hasOwnProperty(key)) {
            propertiesToAdd[key] = properties[key];
        }
    });

    const propertiesToSubmit = Object.keys(propertiesToAdd).map((key) => ({
        key,
        value: propertiesToAdd[key],
    }));

    return propertiesToSubmit;
}

export type Breakpoint = "xs" | "sm" | "md" | "lg";

type BP = {
    [K in Breakpoint]: number;
};

export const breakpoints: BP = {
    xs: 0,
    sm: 767,
    md: 1024,
    lg: 1920,
};

export function screenWidth() {
    return (
        (typeof document !== "undefined" &&
            ((document.documentElement && document.documentElement.clientWidth) || (document.body && document.body.clientWidth))) ||
        0
    );
}

export function screenHeight() {
    return (
        (typeof document !== "undefined" &&
            ((document.documentElement && document.documentElement.clientHeight) || (document.body && document.body.clientHeight))) ||
        0
    );
}

export function makeBreakpoint(widthN?: number | undefined): Breakpoint {
    const breakpoints: BP = {
        xs: 0,
        sm: 767,
        md: 1024,
        lg: 1920,
    };
    const width = widthN || screenWidth();

    let allowed = Object.values(breakpoints).filter((x) => x >= width);

    if (width > breakpoints.lg) {
        allowed = [breakpoints.lg];
    }

    const max = Math.min.apply(null, allowed);

    let newBreakpoint = (Object.keys(breakpoints).filter((x) => breakpoints[x] === max)[0] as Breakpoint) || null;

    if (!newBreakpoint) {
        newBreakpoint = "md";
    }

    return newBreakpoint;
}

export const isDesktop = (breakpoint?: Breakpoint | undefined): boolean => {
    const bp = breakpoint || makeBreakpoint();
    return ["xs", "sm", "md"].indexOf(bp) < 0;
};

export const isHiResPortrait = (breakpoint: Breakpoint | undefined): boolean => {
    return isDesktop(breakpoint) && screenHeight() > screenWidth();
};

export const loadScript = (src: string, id?: string): Promise<HTMLScriptElement> => {
    return new Promise((res, rej) => {
        const script = document.createElement("script");

        script.src = src;
        script.type = "text/javascript";

        if (id) {
            script.id = id;
        }

        script.onerror = rej;
        script.async = true;
        script.onload = () => res(script);

        script.addEventListener("error", rej);
        script.addEventListener("load", () => res(script));

        document.head.appendChild(script);
    });
};

// export const loadScript = (src: string, done: () => void) => {
//     const js = document.createElement("script");

//     js.src = src;
//     js.onload = function () {
//         done();
// 	};
// 	js.onerror = (error) => {
// 		console.log(`Could not load script src "${src}";`)
// 	}
//     document.head.appendChild(js);
// };
export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDisplayTime(date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const format = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    const displayHours = hours ? hours : "12";
    const displayMinutes = minutes < 10 ? "0" + minutes : minutes;

    return displayHours + ":" + displayMinutes + format;
}

export function addMinutes(date, minutes): Date {
    return new Date(date.getTime() + minutes * 60000);
}

export const isMacOS = () => {
    return navigator.userAgent.toLowerCase().indexOf("mac os x") > -1;
};

export function isObject(o: any) {
    return o && !Array.isArray(o) && typeof o === "object";
}

export function removeUndefineds<T extends Record<string, any>>(o: T): T {
    return (Object.keys(o) as Array<keyof T>).reduce(
        (out, k) =>
            Object.assign(
                out,
                typeof o[k] == "undefined"
                    ? {}
                    : {
                          [k]: Array.isArray(o[k])
                              ? (o[k] as any).map((x) => (isObject(x) ? removeUndefineds(x) : x))
                              : isObject(o[k])
                              ? removeUndefineds(o[k])
                              : o[k],
                      }
            ),
        {}
    ) as T;
}

// colorLightness('#red', 10)
// if the parameter `percent` is higher then the color is lighter
export const colorLightness = (color: string, percent: number) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(`${(R * (100 + percent)) / 100}`);
    G = parseInt(`${(G * (100 + percent)) / 100}`);
    B = parseInt(`${(B * (100 + percent)) / 100}`);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    let RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    let GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    let BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
};

export const hex2Rgba = (hex: string, alpha?: number) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha !== undefined ? alpha : 1})`
        : "rgba(0,0,0,0)";
};

export function dateBefore(ref, msg) {
    return this.test({
        name: "dateBefore",
        exclusive: false,
        message: msg || "The start time must be before the end time",
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return new Date(value).getTime() < new Date(this.resolve(ref)).getTime();
        },
    });
}

export function minDuration(ref, msg) {
    return this.test({
        name: "minDuration",
        exclusive: false,
        message: msg || "The duration of a session must be at least 15 minutes.",
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return new Date(value).getTime() - new Date(this.resolve(ref)).getTime() >= 15 * 60 * 1000;
        },
    });
}

export function getWeekNumber(date: Date) {
    const local = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

    local.setUTCDate(local.getUTCDate() + 4 - (local.getUTCDay() || 7));

    var yearStart = new Date(Date.UTC(local.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((local.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);

    return weekNo;
}

export const getOrdinalNumber = (n: number) => {
    return n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : "";
};

export function timeDiff(targetDate) {
    function z(n) {
        return (n < 10 ? "0" : "") + n;
    }

    const timeDiff = targetDate - Number(new Date());
    const hours = (timeDiff / 3.6e6) | 0;
    const minutes = ((timeDiff % 3.6e6) / 6e4) | 0;
    const seconds = ((timeDiff % 6e4) / 1e3) | 0;

    const incomingDate = new Date(targetDate).getTime() > new Date().getTime();

    if (incomingDate) {
        return z(hours) + ":" + z(minutes) + ":" + z(seconds);
    } else {
        return "00:00:00";
    }
}

export const getParticipantsAvatars = (participants: DeepPartial<Participant>[]): string[] => {
    return participants.map(getParticipantAvatar);
};

export const getParticipantAvatar = ({dataWithNullableEmail}: DeepPartial<Participant>) => {
    if (dataWithNullableEmail && dataWithNullableEmail.avatar) return dataWithNullableEmail.avatar;

    // TODO: use default random user portrait. Leaving this for now
    return "https://randomuser.me/api/portraits/men/18.jpg";
};

export const requestFullScreen = (skipLayoutFullscreen?: boolean) => {
    const node = document.documentElement;
    try {
        if (typeof node.requestFullscreen == "function") {
            node.requestFullscreen();
        } else if (typeof (node as any).webkitRequestFullscreen === "function") {
            (node as any).webkitRequestFullscreen();
        } else if (typeof (node as any).mozRequestFullScreen === "function") {
            (node as any).mozRequestFullScreen();
        } else if (typeof (node as any).msRequestFullscreen === "function") {
            (node as any).msRequestFullscreen();
        }
    } catch (err) {
        console.log("cannot go fullscreen", err);
    }

    fullScreenToggle(undefined, true);
};

export const exitFullScreen = () => {
    try {
        if (typeof document.exitFullscreen === "function") {
            document.exitFullscreen();
        } else if (typeof (document as any).webkitExitFullscreen === "function") {
            (document as any).webkitExitFullscreen();
        } else if (typeof (document as any).mozExitFullscreen === "function") {
            (document as any).mozExitFullscreen();
        } else if (typeof (document as any).msExitFullscreen === "function") {
            (document as any).msExitFullscreen();
        }
    } catch (err) {
        console.log("exit fullscreen error", err);
    }

    fullScreenToggle(undefined, false);
};

export const canExecuteKeyPress = (key: string): boolean => {
    const elementsWithDataListening = document.querySelectorAll("[data-listening]");
    for (let node of elementsWithDataListening) {
        if (node instanceof HTMLElement && node.dataset.listening?.includes(key)) {
            return false;
        }
    }
    return true;
};

export const dateIsValid = (date: any) => {
    // @ts-ignore
    return date instanceof Date && !isNaN(date);
};

const avatarColors = [
    "#795dfb",
    "#a78bfa",
    "#faa6ce",
    "#b9dd83",
    "#fcd34d",
    "#e590ac",
    "#f59e0b",
    "#60ebbc",
    "#92d5fa",
    "#95809d",
    "#9ab4e1",
    "#83a6ff",
    "#e68de1",
    "#c4ba91",
    "#80d78f",
];

export const getHexColor = (string: string) => {
    const s = (string || "").split(" ").join("");
    let hash = 0;
    const len = s.length;
    for (let i = 0; i < len; i++) {
        hash = (hash << 5) - hash + s.charCodeAt(i);
        hash |= 0;
    }
    let idx = hash % avatarColors.length;
    if (idx < 0) {
        idx = idx * -1;
    }
    if (idx > avatarColors.length - 1) {
        idx = avatarColors.length - 1;
    }

    return avatarColors[idx];
};

export const getColor = (string: string, opacity: number = 1) => {
    const hex = getHexColor(string);
    return hex2Rgba(hex, opacity);
};

export const getQueryParams = (s?: string): Map<string, string> => {
    if (!s || typeof s !== "string" || s.length < 2) {
        return new Map();
    }

    const a: [string, string][] = s
        .substr(1) // remove `?`
        .split("&") // split by `&`
        .map((x) => {
            const a = x.split("=");
            return [a[0], a[1]];
        }); // split by `=`

    return new Map(a);
};

export const makeInitials = (firstName: string | null | undefined, lastName: string | null | undefined): string => {
    return !firstName && !lastName
        ? "G"
        : [firstName ? firstName.trim().charAt(0).toUpperCase() : null, lastName ? lastName.trim().charAt(0).toUpperCase() : null]
              .filter((x) => x !== null)
              .join("");
};

export const tagsDelimiters = /[,;\s\<\>]|&lt;|&gt;|mailto:/;
export const emailDelimiters = /[,;\s\<\>]|&lt;|&gt;|mailto:/;
export const vttRegex =
    /(\d{2}[:]\d{2}[:]\d{2}[.,]\d{3})[ ]-->[ ](\d{2}[:]\d{2}[:]\d{2}[.,]\d{3})(.*)\r?\n([\s\S]*?)\s*(?:(?:\r?\n){2}|\Z)/g;
export const srtRegex = /(.*\n)?(\d\d:\d\d:\d\d),(\d\d\d --> \d\d:\d\d:\d\d),(\d\d\d)/g;
export const VimeoRegex = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
export const YoutubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
export const YoutubeIdRegex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
export const VimeoIdRegex = /vimeo.*\/(\d+)/i;
export const VimeoIdUnlistedRegex = /vimeo.*\/(\d+\/\w+)/i;
export const TwitchRegex = /^(http\:\/\/|https\:\/\/)?(www\.|player\.|clips\.)?(twitch\.tv\/)(.*)$/;
export const AdobeXDRegex = /(https\:\/\/xd\.adobe\.com\/)(view|embed)\/(([0-9a-zA-Z\-])*\/?)(([0-9a-zA-Z\-])*\/?)?(\?([^\s"'`])*)?/;
export const HlsRegex = /.*\.m3u8/;
export const GoogleDocsRegex = /^(https|http):\/\/docs.google.com\/document\/d\/*/i;
export const GoogleSheetsRegex = /^(https|http):\/\/docs.google.com\/spreadsheets\/d\/*/i;
export const GoogleSlidesRegex = /^(https|http):\/\/docs.google.com\/presentation\/d\/*/i;
export const GoogleFormsRegex = /^(https|http):\/\/docs.google.com\/forms\/d\/*/i;

export const fileNamePieces = (fileName: string) => {
    const len = (fileName || "").length;
    const ext = (fileName || "").slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    return {
        noExt: fileName.substr(0, len - ext.length - 1),
        ext,
    };
};

export const avg = (nrs: number[]) => {
    return nrs.reduce((pv, nr) => nr + pv, 0) / nrs.length;
};

export const compare2Objects = (x, y): boolean => {
    var p;

    if (isNaN(x) && isNaN(y) && typeof x === "number" && typeof y === "number") {
        return true;
    }

    if (x === y) {
        return true;
    }

    if (
        (typeof x === "function" && typeof y === "function") ||
        (x instanceof Date && y instanceof Date) ||
        (x instanceof RegExp && y instanceof RegExp) ||
        (x instanceof String && y instanceof String) ||
        (x instanceof Number && y instanceof Number)
    ) {
        return x.toString() === y.toString();
    }

    if (!(x instanceof Object && y instanceof Object)) {
        return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
        return false;
    }

    if (x.constructor !== y.constructor) {
        return false;
    }

    if (x.prototype !== y.prototype) {
        return false;
    }

    for (p in y) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
            return false;
        } else if (typeof y[p] !== typeof x[p]) {
            return false;
        }
    }

    for (p in x) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
            return false;
        } else if (typeof y[p] !== typeof x[p]) {
            return false;
        }

        switch (typeof x[p]) {
            case "object":
            case "function":
                if (!compare2Objects(x[p], y[p])) {
                    return false;
                }
                break;

            default:
                if (x[p] !== y[p]) {
                    return false;
                }
                break;
        }
    }

    return true;
};

export function inputValueEqualsFromDate(str: string, date: Date) {
    return date.getHours() + ":" + date.getMinutes() === str.trim();
}

type GenerateSessionNameArg = {
    quickSession?: boolean;
    isRoom?: boolean;
    userName?: string;
};

// export const generateSessionName = (halfDayClock: boolean, when: Date = new Date()) => {
export const generateSessionName = (arg?: GenerateSessionNameArg) => {
    const {quickSession, isRoom, userName} = arg ?? {};
    // const date = (
    //     dateFormat(when, ["dayOfMonth", {sep: " "}, "monthShort", {sep: " "}]) +
    //     dateFormat(when, halfDayClock ? "fullTime12h" : "fullTime24h").toUpperCase()
    // ).trim();

    // return `New session [${date}]`;

    return quickSession ? "Instant Session" : isRoom ? (userName ? `${capitalize(userName)}'s room` : "New room") : "New Session";
};

export function useRefCb<T>(): [(instance: T | null) => void, React.RefObject<T>] {
    const ref = useRef<T | null>(null);

    const cb: RefCallback<T> = useCallback((node) => {
        ref.current = node;

        return node;
    }, []);

    return [cb, ref];
}

export const isContentOverflowed = (target: HTMLElement): boolean => {
    const getWidth = (children: HTMLCollection) =>
        Array.from(children || []).reduce(
            (acc, child) => acc + Math.max(child.children.length ? getWidth(child.children) : 0, child.getBoundingClientRect().width),
            0
        );

    return getWidth(target.children) > target.getBoundingClientRect().width;
};

export const delay = (duration: number) => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, duration);
    });
};

export function isEmptyObject(value = {}): boolean {
    return Object.keys(value).length === 0 && value.constructor === Object;
}
