import {getContactByEmail} from "@common/utils/contacts";
import defaultAvatar from "@common/utils/guest-common-avatar";
import {ReactionType} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import {CSSProperties} from "@workhorse/api/rendering";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import {useTranslation} from "react-i18next";
import {reactions} from "../api";
import classes from "../styles/ReactionEntry.module.scss";

type ReactionEntryProps = {
    className?: string;
    reactionType: ReactionType;
    participantId: string;
    style?: CSSProperties;
};

const ReactionEntry = (props: ReactionEntryProps) => {
    const {t} = useTranslation();
    const {className, reactionType, participantId, style} = props;

    const participant = useParticipant({participantId});
    const dataWithNullableEmail = participant?.dataWithNullableEmail;

    const firstName = dataWithNullableEmail?.firstName;
    const lastName = dataWithNullableEmail?.lastName;
    const email = dataWithNullableEmail?.email;
    const avatar = dataWithNullableEmail?.avatar;
    const isGuest = dataWithNullableEmail?.isGuest;

    const contact = email ? getContactByEmail(email) : null;
    const displayFirstName = contact ? contact.firstName : firstName;
    const displayLastName = contact ? contact.lastName : lastName;

    return (
        <div data-id="reaction-entry" className={cls("flex flex-items-center", classes.root, className)} style={style} key="reaction-entry">
            <img
                src={reactions[reactionType].iconSmall}
                alt={t(`participant.reaction.${reactions[reactionType].name}`) ?? reactions[reactionType].name}
                key="reaction-entry-icon"
                className={classes.reactionImage}
            />
            <ParticipantDisplayDetails
                firstName={displayFirstName}
                lastName={displayLastName}
                showName={false}
                showProfilePicture={true}
                avatar={isGuest && !firstName && !lastName ? defaultAvatar : avatar}
                participantIsAssistant={false}
                avatarSize="M"
                avatarVariant="rounded"
                classes={{
                    wrap: classes.participantInfo,
                }}
                isUser={!isGuest}
            />
        </div>
    );
};

export default ReactionEntry;
