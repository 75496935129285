import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {cls} from "@ui/cdk/util";
import classes from "../styles/OfferCard.module.scss";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import {useState, useCallback} from "@workhorse/api/rendering";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import Fade from "@ui/core/components/Fade";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {useMutation} from "@workhorse/dataApi";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {offerShow} from "@workhorse/pages/player/playerStateVars";
import toast from "@workhorse/api/toast";
import PictureInPictureRoundedIcon from "@material-ui/icons/PictureInPictureRounded";
import {useTranslation} from "react-i18next";
import {openExternalLink} from "@workhorse/util/links";

type OfferCardProps = {
    id: string;
    title: string;
    description: string;
    ctaUrl: string;
    ctaText: string;
    imageUrl: string;
    onEditOffer: (offerId: string) => void;
    onDeleteOffer: (offerId: string) => void;
    isMacro: boolean;
    shareOfferOpen: boolean;
    sessionId: string;
    isOrganizer?: boolean;
    isMemoryMode: boolean;
    userId: string;
    offerUserId: string;
};

const OfferCard = (props: OfferCardProps) => {
    const {t} = useTranslation();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [showMoreActions, setShowMoreActions] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [removeOfferFromSession] = useMutation("RemoveOfferFromSessionDocument");
    const [showOffer] = useMutation("ShowOfferInSessionDocument");

    const openMenu = Boolean(menuAnchorEl);

    const shownOffer = offerShow();

    function handleOfferClick() {
        try {
            new URL(props.ctaUrl);
            openExternalLink(props.ctaUrl);
        } catch {
            openExternalLink(`https://${props.ctaUrl}`);
        }
    }

    const handleClickMenuPopover = useCallback(
        (event) => {
            setMenuAnchorEl(event.currentTarget);
        },
        [setMenuAnchorEl]
    );

    const handleCloseMenuPopover = useCallback(() => {
        setMenuAnchorEl(null);
    }, []);

    const handleEditOffer = () => {
        props.onEditOffer(props.id);
        handleCloseMenuPopover();
    };

    const toggleDeleteConfirmation = () => {
        setOpenDeleteDialog(!openDeleteDialog);
        handleCloseMenuPopover();
    };

    const handleDeleteOffer = () => {
        if (!props.shareOfferOpen && props.isMacro) {
            handleRemoveOfferFromSession();
        } else {
            props.onDeleteOffer(props.id);
        }
        toggleDeleteConfirmation();
    };

    const handleRemoveOfferFromSession = async () => {
        if (shownOffer?.offer?.id === props.id) {
            await showOffer({
                variables: {
                    sessionId: props.sessionId,
                    offerId: "",
                },
            });
        }

        await removeOfferFromSession({
            variables: {
                sessionId: props.sessionId,
                offerId: props.id,
            },
        });
    };

    const handleDisplayOffer = async () => {
        await showOffer({
            variables: {
                sessionId: props.sessionId,
                offerId: props.id,
            },
        });
    };

    const canEditOffer = props.userId === props.offerUserId;

    return (
        <div
            className={cls(classes.offerCardBody, props.isMacro ? classes.offerCardBodyMacro : undefined)}
            onMouseEnter={() => setShowMoreActions(true)}
            onMouseLeave={() => setShowMoreActions(false)}
        >
            {showMoreActions && !props.isMacro && (
                <div className={classes.moreActionsWrapper}>
                    <Button
                        variant="plain"
                        className={cls(classes.moreActions, openMenu && classes.moreActionsOpen)}
                        onClick={handleClickMenuPopover}
                    >
                        <MoreVertRoundedIcon />
                    </Button>
                </div>
            )}
            <div className={cls(classes.offerCardImageWrapper, props.isMacro ? classes.offerCardImageWrapperMacro : undefined)}>
                <img src={props.imageUrl} />
            </div>
            <div
                className={cls("flex flex-col gap-10 flex-align-start flex-justify-between fullh", props.isMacro ? "p-12" : "px-24 py-12")}
            >
                <div className="flex flex-col fullw">
                    <Typography
                        variant="xl2"
                        fontWeight="bolder"
                        color="primary"
                        className={cls(!props.isMacro && "pr-36", classes.offerCardTitle)}
                    >
                        {props.title}
                    </Typography>
                    <Typography variant="base" color="secondary" className={classes.offerCardDescription}>
                        {props.description}
                    </Typography>
                </div>

                <Button className={cls(props.isMacro ? "fullw" : undefined)} onClick={handleOfferClick} endIcon={<OpenInNewRoundedIcon />}>
                    {props.ctaText}
                </Button>

                {props.isMacro && props.isOrganizer && !props.isMemoryMode && (
                    <div className="flex flex-col fullw">
                        <div className={classes.divider} />
                        <div
                            className={cls(
                                "flex flex-wrap flex-row gap-10",
                                props.shareOfferOpen ? "flex-justify-end" : "flex-justify-between"
                            )}
                        >
                            <Button
                                onClick={handleDisplayOffer}
                                startIcon={props.shareOfferOpen ? <AddRoundedIcon /> : <PictureInPictureRoundedIcon />}
                                size="small"
                                variant="tertiary"
                                className="flex11-auto"
                            >
                                {t("g.display") ?? "Display"}
                            </Button>
                            <div className="flex flex11-auto flex-align-center gap-10">
                                {canEditOffer && (
                                    <Button
                                        onClick={handleEditOffer}
                                        startIcon={<EditRoundedIcon />}
                                        size="small"
                                        variant="tertiary"
                                        className="flex11-auto"
                                    >
                                        {t("g.edit") ?? "Edit"}
                                    </Button>
                                )}
                                {props.isMacro && !props.shareOfferOpen && (
                                    <Button
                                        onClick={toggleDeleteConfirmation}
                                        startIcon={<DeleteRoundedIcon />}
                                        size="small"
                                        variant="destructive-secondary"
                                        className="flex11-auto"
                                    >
                                        {t("g.remove") ?? "Remove"}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Menu
                id="offer-actions-menu"
                open={openMenu}
                anchorEl={menuAnchorEl}
                onClose={handleCloseMenuPopover}
                TransitionComponent={Fade}
                classes={{paper: classes.moreMenuPaper}}
                anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                transformOrigin={{horizontal: "center", vertical: -11}}
                getContentAnchorEl={null}
            >
                <MenuItem className={cls(classes.menuItem)} onClick={handleEditOffer} data-id="edit-offer" variant="primary">
                    <EditRoundedIcon />
                    Edit offer
                </MenuItem>

                <MenuItem
                    className={cls(classes.menuItem, "red")}
                    onClick={toggleDeleteConfirmation}
                    data-id="delete-offer"
                    variant="destructive"
                >
                    <DeleteRoundedIcon />
                    Delete offer
                </MenuItem>
            </Menu>

            <ConfirmationDialog
                key="more-options-menu-delete-offer-dialog"
                title={props.shareOfferOpen ? "Remove offer?" : "Delete offer?"}
                content={
                    props.shareOfferOpen
                        ? "This offer will not be available for participants anymore. You can always add it back."
                        : "Are you sure you want to delete this offer?"
                }
                cancelButton="Cancel"
                minWidth
                submitButton="Delete"
                onClose={toggleDeleteConfirmation}
                open={openDeleteDialog}
                submitButtonVariant="destructive-secondary"
                onConfirm={handleDeleteOffer}
                variant="warning"
                isDeleteConfirmation
            />
        </div>
    );
};

export default OfferCard;
