import {InMemoryCache} from "@apollo/client";
import {ModelsWithFieldResolver} from "@workhorse/providers/graphql/diff.resolvers";
import {MergeFn} from "./types";

const mergeFn: MergeFn = {};

type WriteToUpdateFnArg = {
    existing: any;
    incoming: any;
    mergeObjects: (...args: any[]) => any;
    cache?: InMemoryCache;
};

function writeToUpdate(model: keyof ModelsWithFieldResolver, {existing, incoming, mergeObjects, cache}: WriteToUpdateFnArg) {
    // if (cache) {
    //     const now = cache.extract(true)[existing.__ref];
    //     console.log("session now", now);
    // }
    // if (model === "Session") {
    //     console.log("merging session", {existing, incoming});
    // }
    // const fields = modelsWithFieldResolver[model];
    // const update = existing.update ?? {};
    // (fields ?? []).forEach((key) => {
    //     const incomingValue = incoming[key] instanceof Date ? incoming[key].toISOString() : incoming[key];
    //     const existingValue = existing[key] instanceof Date ? existing[key].toISOString() : existing[key];
    //     if (incomingValue == existingValue) {
    //         delete update[key];
    //     } else {
    //         update[key] = incomingValue;
    //     }
    //     incoming[key] = existingValue;
    // });
    // existing.update = update;
    // incoming.update = update;
    const merged = mergeObjects(existing, incoming);
    // console.log("merged session", merged);
    // if(cache){
    //     cache.write({
    //         query: GetSessionDocument,
    //         result:{

    //         },
    //         variables:{
    //             id: existing.id
    //         }
    //     })
    // }

    return merged;
}

mergeFn.Session = true;

// function merge(existing, incoming, {mergeObjects, variables, readField, cache, args}) {
//     // in case no data exists (i.e. fresh local session create)
//     // we write directly on the Session model NOT the update field
//     // meaning we return whatever data is incoming
//     if (!existing) {
//         return incoming;
//     }
//     // const {isEditMode} = variables || {};
//     // if (!isEditMode) {
//     //     // if (existing.update && !incoming.update) {
//     //     //     (incoming as any).update = existing.update;
//     //     // }
//     //     return mergeObjects(existing, incoming);
//     // }
//     // const createdAt = readField("createdAt", existing);

//     // const isCommited = !!createdAt;

//     // // data exists and has been commited (createdAt != null)
//     // // here we write on the `update` object
//     // if (isEditMode && isCommited) {
//     //     return writeToUpdate("Session", {existing, incoming, mergeObjects, cache});
//     // }
//     return mergeObjects(existing, incoming);
// };

mergeFn.AgendaItem = true;
// function merge(existing, incoming, {mergeObjects, variables, readField, cache}) {
//     if (!existing) {
//         return incoming;
//     }
//     // const {isEditMode} = variables || {};
//     // if (!isEditMode) {
//     //     return mergeObjects(existing, incoming);
//     // }
//     // const createdAt = readField("createdAt", existing);
//     // const isCommited = !!createdAt;

//     // // data exists and has been commited (createdAt != null)
//     // // here we write on the `update` object
//     // if (isEditMode && isCommited) {
//     //     return writeToUpdate("AgendaItem", {existing, incoming, mergeObjects});
//     // }
//     return mergeObjects(existing, incoming);
// };

mergeFn.Artifact = true;
// function merge(existing, incoming, {mergeObjects, variables, readField, cache}) {
//     if (!existing) {
//         return incoming;
//     }
//     // const {isEditMode} = variables || {};
//     // if (!isEditMode) {
//     //     return mergeObjects(existing, incoming);
//     // }
//     // const createdAt = readField("createdAt", existing);
//     // const isCommited = !!createdAt;

//     // // data exists and has been commited (createdAt != null)
//     // // here we write on the `update` object
//     // if (isEditMode && isCommited) {
//     //     return writeToUpdate("Artifact", {existing, incoming, mergeObjects});
//     // }
//     return mergeObjects(existing, incoming);
// };

mergeFn.Participant = true;

// function merge(existing, incoming, {mergeObjects}) {
//     if (!existing) {
//         return incoming;
//     }
//     return mergeObjects(existing, incoming);
// };

mergeFn.Room = true;

export {mergeFn};
