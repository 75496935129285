import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {useTranslation} from "react-i18next";

const AudioSettingsOption = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();

    const {isMobileOrTablet} = useMobile();
    const {show} = useSessionSettingsDialogDisplay();

    const showPage = isMobileOrTablet ? "device" : "device.audio";

    const handleOnToggle = () => show(showPage);

    return (
        <MenuItem ref={ref} onClick={handleOnToggle}>
            <SettingsVoiceIcon />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.mic.audio_settings")}
            </Typography>
        </MenuItem>
    );
});

export default AudioSettingsOption;
