import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import {EventProps} from "@workhorse/declarations";
import SessionDataProviders from "@workhorse/providers/SessionDataProviders";
import {CreateEvent} from "./create-event";
import {Event} from "./event/event";
import {useEffect} from "@workhorse/api/rendering";
import {Location} from "history";

function EventHoc(props: EventProps) {
    const id = props.match?.params.id;

    const {canCreateEvents} = useWorkspaceAccess();

    useEffect(() => {
        if (!id && !canCreateEvents()) {
            props.history.push("/unauthorized");
        }
    }, []);

    return (
        <SessionDataProviders isEditMode>
            <div className="route-hoc flex-align-start event-hoc fullw">
                {id ? <Event id={id} location={props.location as Location} /> : <CreateEvent />}
            </div>
        </SessionDataProviders>
    );
}

export default EventHoc;
