import {RecurrenceData, RepeatingFrequency, WeekDay} from "@generated/data";
import {format} from "date-fns";

type RuleData = Omit<RecurrenceData, "session" | "sessionId" | "instances" | "sessionRulePairs">;

const getMonthDayLabel = (position: number) => {
    if (position === 1) {
        return "1st";
    }

    if (position === 21) {
        return "21st";
    }

    if (position === 31) {
        return "31st";
    }

    if (position === 2) {
        return "2nd";
    }

    if (position === 22) {
        return "22nd";
    }
    if (position === 3) {
        return "3rd";
    }

    if (position === 23) {
        return "23rd";
    }

    return `${position}th`;
};

const getWeekdayName = (day: WeekDay) => {
    switch (day) {
        case WeekDay.Mo:
            return "Monday";
        case WeekDay.Tu:
            return "Tuesday";
        case WeekDay.We:
            return "Wednesday";
        case WeekDay.Th:
            return "Thursday";
        case WeekDay.Fr:
            return "Friday";
        case WeekDay.Sa:
            return "Saturday";
    }
    return "Sunday";
};

const getEndingText = (rule: RuleData) => {
    if (rule.until) {
        return `until ${format(new Date(rule.until), "EEEE, dd.MM.y")}`;
    }
    if (rule.count) {
        return `for ${rule.count} times`;
    }

    return null;
};

const displayRecurrenceRuleText = (rule: RuleData) => {
    const endingText = getEndingText(rule);
    if (rule.frequency === RepeatingFrequency.Daily) {
        if (rule.interval && rule.interval > 1) {
            return `Every ${rule.interval} days ${endingText ? endingText : ""}`;
        }
        return `Daily ${endingText ? endingText : ""}`;
    }
    if (rule.frequency === RepeatingFrequency.Weekly) {
        let ruleStartString = "Weekly";
        if (rule.interval && rule.interval > 1) {
            ruleStartString = `Every ${rule.interval} weeks`;
        }
        if (rule.byDay.length > 0) {
            const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"].filter((day: WeekDay) => rule.byDay.includes(day));

            const weekDays = days.map((day: WeekDay) => getWeekdayName(day));
            return `${ruleStartString} on ${weekDays.join(", ")} ${endingText ? endingText : ""}`;
        }
        return `${ruleStartString} ${endingText ? endingText : ""}`;
    }
    if (rule.frequency === RepeatingFrequency.Monthly) {
        let ruleStartString = "Monthly";
        if (rule.interval && rule.interval > 1) {
            ruleStartString = `Every ${rule.interval} months`;
        }
        if (rule.byDay.length > 0) {
            if (rule.bySetPos && rule.bySetPos > 0) {
                return `${ruleStartString} on the ${getMonthDayLabel(rule.bySetPos)} ${getWeekdayName(rule.byDay[0])} ${
                    endingText ? endingText : ""
                }`;
            }
            return `${ruleStartString} on ${getWeekdayName(rule.byDay[0])} ${endingText ? endingText : ""}`;
        }
        if (rule.byMonthDay.length > 0) {
            return `${ruleStartString} on the ${getMonthDayLabel(rule.byMonthDay[0])} ${endingText ? endingText : ""}`;
        }
        return `${ruleStartString} ${endingText ? endingText : ""}`;
    }

    return null;
};

export const displayRecurrenceText = (recurrenceData: RecurrenceData) => {
    return displayRecurrenceRuleText(recurrenceData);
};
