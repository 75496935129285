import classes from "./styles/DocumentSyncDialog.module.scss";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {GoogleLogin} from "@ui/cdk/GoogleLogin";
import {ReactComponent as SessionsIcon} from "../../../../frontend/assets/media/sync-sessions.svg";
import {ReactComponent as GoogleIcon} from "../../../../frontend/assets/media/sync-google.svg";
import {ReactComponent as SyncIcon} from "../../../../frontend/assets/media/sync.svg";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import CloudQueueRoundedIcon from "@material-ui/icons/CloudQueueRounded";
import Accordion from "@ui/core/components/Accordion";
import AccordionSummary from "@ui/core/components/AccordionSummary";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import AccordionDetails from "@ui/core/components/AccordionDetails";
import {useState, useEffect, useRef} from "@workhorse/api/rendering";

const permissionsData: {title: string; description: string[]; icon: JSX.Element}[] = [
    {
        title: "See, edit, create, and delete all of your Google Drive files",
        description: [
            "See your files",
            "Upload and download your files",
            "Delete your files",
            "See the names and emails of people you share files with",
            "Share and stop sharing your files with others",
            "Remove people from your files",
            "Organize your Drive",
        ],
        icon: <CloudQueueRoundedIcon />,
    },
];

type DocumentSyncDialogProps = {
    dialogRef: React.MutableRefObject<DialogImperativeRef>;
    onCancel: () => void;
    onConfirm: () => void;
    loading: boolean;
};

export function DocumentSyncDialogOpen({onConfirm, onCancel}: {onConfirm: () => void; onCancel: () => void}) {
    const dialogRef = useRef<DialogImperativeRef>();

    useEffect(() => {
        dialogRef.current?.toggle?.(true);
    }, []);

    return <DocumentSyncDialog dialogRef={dialogRef} onCancel={onCancel} onConfirm={onConfirm} loading={false} />;
}

function DocumentSyncDialog(props: DocumentSyncDialogProps) {
    const {dialogRef, onCancel, onConfirm, loading} = props;

    const [expandedLine, setExpandedLine] = useState<number>();

    const handleChange = (index: number, event: React.ChangeEvent) => {
        setExpandedLine(expandedLine !== index ? index : undefined);
    };

    return (
        <Dialog imperativeRef={dialogRef} classes={{paper: classes.root}} disableBackdropClick disableEscapeKeyDown>
            <div className="flex flex-items-center mb-20">
                <Typography variant="xl2" fontWeight="bolder" color="secondary" className="text-center">
                    Synchronize your Google Account for easy access to your documents.
                </Typography>
            </div>

            <div className={classes.sync}>
                <GoogleIcon />
                <SyncIcon />
                <SessionsIcon />
            </div>

            <div className="flex flex-col mx-12">
                <Typography variant="xl" fontWeight="bolder" className="mb-24">
                    Sessions will be able to:
                </Typography>
                {permissionsData.map((permission, i) => (
                    <div key={`permission-${i}`}>
                        <Accordion expanded={expandedLine === i} onChange={handleChange.bind(null, i)} className={classes.accordion}>
                            <AccordionSummary expandIcon={<ArrowDropDownRoundedIcon />}>
                                {permission.icon}
                                <Typography fontWeight={expandedLine === i ? "boldest" : "normal"}>{permission.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {permission.description.map((text, i) => (
                                    <Typography key={`permission-text-${i}`}>{text}</Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                        <div className={classes.separator} />
                    </div>
                ))}
            </div>

            <div className={classes.footer}>
                <Button variant="quaternary" onClick={onCancel}>
                    Cancel
                </Button>

                <GoogleLogin
                    synced={false}
                    scopes={["profile", "email", "https://www.googleapis.com/auth/drive"]}
                    renderLogin={(renderProps) => (
                        <Button onClick={renderProps.onClick} loading={loading} disabled={loading}>
                            Sync
                        </Button>
                    )}
                    onLogin={onConfirm}
                />
            </div>
        </Dialog>
    );
}

export default DocumentSyncDialog;
