import {DesignerAction} from "../../action";
import {makeId} from "../../utils";
import designer from "../..";
import {AgendaItem, Artifact} from "@workhorse/declarations/dataTypes";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";

export interface CopyArtifactToOneItemInput {
    sourceId: string;
    targetId: string;
}

export class CopyArtifactToOneItem extends DesignerAction<CopyArtifactToOneItemInput> {
    public commit() {
        const now = null as unknown as Date;
        const agendaItems = designer.currentAgendaItems();

        const sourceItem = agendaItems?.find((item) => item.id === this.input.sourceId);
        const targetItem = agendaItems?.find((item) => item.id === this.input.targetId);

        let modifiedAgendaItems = agendaItems;

        if (agendaItems && sourceItem && targetItem) {
            if (sourceItem.artifact !== undefined && sourceItem.artifact !== null) {
                modifiedAgendaItems = agendaItems.map((item) => {
                    if (item.id === targetItem.id && item.id !== sourceItem.id) {
                        return {
                            ...item,
                            artifact: {
                                ...sourceItem.artifact,
                                id: makeId(),
                                properties: sourceItem.artifact?.properties.map((p) => ({
                                    ...p,
                                    id: makeId(),
                                    createdAt: now,
                                    updatedAt: now,
                                })),
                                createdAt: now,
                                updatedAt: now,
                            } as DeepMandatoryWithNullVals<Artifact>,
                        };
                    }
                    return item;
                });
            }
        }

        designer.updateCurrentSession(
            {
                agendaItems: modifiedAgendaItems,
            },
            {
                strategy: "replace",
            }
        );

        return "true";
    }
}
