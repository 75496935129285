import {HostType, OrganizationPlan} from "@generated/data";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactsIcon from "@material-ui/icons/Contacts";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ForumRoundedIcon from "@material-ui/icons/ForumRounded";
import HelpIcon from "@material-ui/icons/Help";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import Popover, {PopoverProps} from "@ui/core/components/Popover";
import {useRemoteApplicationState} from "@workhorse/api/application/RemoteApplicationStateProvider";
import {AuthService} from "@workhorse/api/authService";
import {
    Action,
    actionProfile,
    enableBookingLeaveModal,
    enableEndSessionModal,
    enableEventLeaveModal,
    enableFeedbackFormLeaveModal,
    enableRoomLeaveModal,
    routeNavigation,
} from "@workhorse/components/header/headerUtils";
import {releaseNotesDialogEnabled} from "@workhorse/components/releaseNotes/releaseNotesApi";
import {BuildInfoJSON} from "@workhorse/declarations";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useIsLobby, useIsPreJoin} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {useHistory, useLocation} from "@workhorse/api/routing";
import NavigationUserDetailsHeader from "./NavigationUserDetailsHeader";
import NavigationUserDetailsItem from "./NavigationUserDetailsItem";
import FeedbackIcon from "@material-ui/icons/Feedback";
import classes from "./style/NavigationUserDetails.module.scss";
import {reportDialogEnabled} from "@workhorse/components/reportProblem/reportDialogApi";
import {useTranslation} from "react-i18next";
import env from "@generated/environment";
import {languageAllowedHostTypes} from "@workhorse/i18n";
import LanguageSelector from "@workhorse/components/language-selector/LanguageSelector";
import NavigationDownloadManagerItem from "./NavigationDownloadManagerItem";
import {ReactComponent as DownloadIcon} from "../../../assets/media/folder_download.svg";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import {useUserAccess} from "@workhorse/api/access/hooks";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Typography from "@ui/cdk/Typography";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import {isDesktopApp} from "@sessions/desktop-api";
import {openExternalAppDownload} from "@workhorse/util/links";
import {setSessionGuestUtmCookies} from "@workhorse/api/user";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import browserInfo from "@workhorse/api/BrowserInfo";
import mixpanel from "mixpanel-browser";

type NavigationUserDetailsProps = Pick<PopoverProps, "open" | "anchorEl" | "anchorOrigin" | "transformOrigin" | "disablePortal"> & {
    onClose: () => void;
    planName?: string;
    organizationPlan?: OrganizationPlan;
};

const NavigationUserDetails = (props: NavigationUserDetailsProps) => {
    const {t} = useTranslation();
    const {onClose, organizationPlan, planName, ...rest} = props;

    const {
        bp: {isMobileBP},
    } = useMobile();

    const history = useHistory();
    const location = useLocation();
    const {applicationState} = useRemoteApplicationState();

    const hostType = useHostType();
    const isPlayer = hostType === HostType.Player;
    const isEvent = hostType === HostType.Event;
    const isRoom = hostType === HostType.Rooms && location.pathname !== "/rooms";
    const isBooking = hostType === HostType.NewBooking;
    const isFeedbackForm = location.pathname.includes("feedback-forms") && location.pathname !== "/user/feedback-forms";
    const isLanguageAllowed = languageAllowedHostTypes.includes(hostType);

    const {isPreJoin} = useIsPreJoin();
    const {isLobby: inLobby} = useIsLobby();
    const {isGuest} = useUserInfo();
    const sessionId = useSessionIdFromRoute();

    const access = useUserAccess();
    // const canSeeWorkspaceSettings = access.organization().canEditMembers();
    const canSeeWorkspaceSettings = true;
    const canSeeOrganizationSettings = access.organization().role.toLowerCase() === "owner";
    const isOrganizationOwner = access.organization().canCreateWorkspaces();
    const hasReferralAccess = access.workspace().canSeeReferralProgram();

    const isSessionView = isPlayer && !inLobby && !isPreJoin;

    const canDownloadApp = browserInfo.OS() === "Mac" || browserInfo.OS() === "Windows";

    const {featureIsAvailable} = useFeatureInfo({
        feature: LimitedFeature.WHITE_LABELING,
        plan: organizationPlan ?? undefined,
    });

    const buildInfoJSON = process.env.REACT_APP_BUILD_INFO;
    // There is no type for the Build Info JSON as of now - it will be updated
    let buildInfo = {} as BuildInfoJSON;

    if (buildInfoJSON) {
        try {
            buildInfo = JSON.parse(buildInfoJSON);
        } catch (e) {
            console.error(e);
        }
    }

    const isNewVersionReleased = applicationState?.releaseInfo?.versionTag !== buildInfo.tag;

    const handleOnShowReleaseNotes = () => {
        if (isNewVersionReleased) {
            onClose();
            // don't take any action on click when deploying
            return;
        }

        const currentState = releaseNotesDialogEnabled();
        releaseNotesDialogEnabled({
            ...currentState,
            enable: true,
            toggleManual: true,
        });

        onClose();
    };

    const handleGoToDownloadApp = () => {
        openExternalAppDownload();

        onClose();
    };

    const toggleConfirmDialog = (action: Action | undefined, enable: boolean, isEvent?: boolean) => {
        if (isEvent) {
            enableEventLeaveModal(enable);
        } else {
            enableEndSessionModal(enable);
        }
        actionProfile(action);
    };

    const handleLogOut = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.detail === 1) {
            if (isSessionView || isEvent) {
                toggleConfirmDialog(Action.logout, true, isEvent);
            } else {
                localStorage.clear();
                sessionStorage.clear();
                AuthService.getInstance().logout();
                mixpanel.reset();
            }
        }

        e.stopPropagation();

        onClose();
    };

    const onLogin = () => {
        setSessionGuestUtmCookies(sessionId);
        localStorage.removeItem("logging_in");
        AuthService.getInstance().login(history.location.pathname, true);
    };

    const displayInHeader = isMobileBP || isPlayer;

    const preventNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url) => {
        if (isEvent || isRoom || isBooking || isFeedbackForm) {
            routeNavigation(url);
            if (isEvent) {
                enableEventLeaveModal(true);
            }
            if (isRoom) {
                enableRoomLeaveModal(true);
            }
            if (isBooking) {
                enableBookingLeaveModal(true);
            }

            if (isFeedbackForm) {
                enableFeedbackFormLeaveModal(true);
            }
            e.preventDefault();
        }
    };

    const onCloseWithPreventContacts = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        preventNavigation(e, "/contacts");
        onClose();
    };

    const onCloseWithPreventProfile = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>, route: string) => {
        const event = e as React.MouseEvent<HTMLAnchorElement, MouseEvent>;
        preventNavigation(event, route);
        onClose();
    };

    const handleOnShowFeedback = () => {
        reportDialogEnabled(true);
        onClose();
    };

    const handleGoToSubscriptions = () => {
        history.push("/user/organization/subscriptions");
        onClose();
    };

    const handleOpenCello = () => {
        // @ts-ignore
        window.Cello("open");

        onClose();
    };

    return (
        <Popover
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            onClose={onClose}
            classes={{
                paper: cls(classes.paper, displayInHeader && classes.paperHeader),
            }}
            {...rest}
        >
            {displayInHeader ? (
                <>
                    <NavigationUserDetailsHeader />

                    <div className={classes.separator}></div>

                    <NavigationUserDetailsItem
                        icon={<FeedbackIcon />}
                        text={t("routes.feedback") ?? "Feedback"}
                        onClick={handleOnShowFeedback}
                    />

                    {isGuest && featureIsAvailable ? null : (
                        <NavigationUserDetailsItem
                            icon={<HelpIcon />}
                            text={t("routes.help_center") ?? "Help center"}
                            href="https://resources.sessions.us/resources/help-center"
                            target="_blank"
                            onClick={onClose}
                        />
                    )}

                    {isLanguageAllowed && <LanguageSelector isGuest={isGuest} />}

                    <div className={classes.separator}></div>

                    {isGuest ? (
                        <Button data-id="guest-login" className={classes.loginButton} onClick={onLogin}>
                            {t("user.log_in")}
                        </Button>
                    ) : (
                        <NavigationUserDetailsItem icon={<ExitToAppIcon />} text={t("user.log_out") ?? "Log out"} onClick={handleLogOut} />
                    )}
                </>
            ) : (
                <>
                    <NavigationUserDetailsHeader />

                    <div className={classes.separator}></div>

                    {isOrganizationOwner && (
                        <>
                            <div
                                className="p-16 flex flex-justify-between flex-align-center cursor-pointer"
                                onClick={handleGoToSubscriptions}
                            >
                                <Typography fontWeight="bold">Active plan:</Typography>
                                <div className={classes.planBadge}>{planName}</div>
                            </div>
                            <div className={classes.separator}></div>
                        </>
                    )}

                    <NavigationUserDetailsItem
                        icon={<AccountCircleIcon />}
                        text={t("user.account_settings") ?? "Account settings"}
                        to="/user/settings/profile"
                        onClick={(e) => onCloseWithPreventProfile(e, "/user/settings/profile")}
                        data-id="menu-button-view-profile"
                        variant="primary"
                    />

                    {canSeeWorkspaceSettings && (
                        <NavigationUserDetailsItem
                            icon={<GroupWorkIcon />}
                            text={t("user.workspace_settings") ?? "Workspace settings"}
                            to="/user/workspace/general"
                            onClick={(e) => onCloseWithPreventProfile(e, "/user/workspace/general")}
                            data-id="menu-button-view-workspace"
                        />
                    )}
                    {canSeeOrganizationSettings && (
                        <NavigationUserDetailsItem
                            icon={<BusinessRoundedIcon />}
                            text={t("user.organization_settings") ?? "Organization settings"}
                            to="/user/organization/general"
                            onClick={(e) => onCloseWithPreventProfile(e, "/user/organization/general")}
                            data-id="menu-button-view-organization"
                        />
                    )}

                    {hasReferralAccess && (
                        <NavigationUserDetailsItem
                            icon={<MonetizationOnRoundedIcon />}
                            text={t("user.referral_program") ?? "Referral program"}
                            onClick={handleOpenCello}
                            data-id="menu-button-referral-program"
                        />
                    )}
                    <NavigationUserDetailsItem
                        icon={<ContactsIcon />}
                        text={t("user.contacts") ?? "Contacts"}
                        to="/contacts"
                        onClick={onCloseWithPreventContacts}
                        data-id="menu-button-contacts"
                    />

                    {/* {isDesktopApp() && <NavigationDownloadManagerItem icon={<DownloadIcon />} text={"Downloads"} />} */}

                    {!isDesktopApp() && canDownloadApp && (
                        <NavigationUserDetailsItem
                            href={env.desktopAppDownloadURL}
                            icon={<DownloadIcon />}
                            text={"Download app"}
                            onClick={handleGoToDownloadApp}
                        />
                    )}

                    <div className={classes.separator}></div>

                    <NavigationUserDetailsItem
                        data-id="button-open-release-notes"
                        icon={<NewReleasesIcon />}
                        text={t("user.release_notes") ?? "Release notes"}
                        onClick={handleOnShowReleaseNotes}
                    />

                    {/* <NavigationUserDetailsItem
                        icon={<ForumRoundedIcon />}
                        endIcon={<OpenInNewRoundedIcon />}
                        text={t("user.join_our_community") ?? "Join our community"}
                        href={env.slack.workspace}
                        target="_blank"
                        onClick={onClose}
                    />

                    <NavigationUserDetailsItem
                        icon={<HelpIcon />}
                        endIcon={<OpenInNewRoundedIcon />}
                        text={t("routes.help_center") ?? "Help center"}
                        href="https://resources.sessions.us/resources/help-center"
                        target="_blank"
                        onClick={onClose}
                    /> */}

                    {isLanguageAllowed && <LanguageSelector isGuest={isGuest} />}

                    <div className={classes.separator}></div>

                    {isGuest ? (
                        <Button data-id="guest-login" className={classes.loginButton} onClick={onLogin}>
                            {t("user.log_in") ?? "Log in"}
                        </Button>
                    ) : (
                        <NavigationUserDetailsItem
                            icon={<ExitToAppIcon />}
                            text={t("user.log_out") ?? "Log out"}
                            onClick={handleLogOut}
                            data-id="menu-button-log-out"
                            variant="destructive"
                        />
                    )}
                </>
            )}
        </Popover>
    );
};

export default NavigationUserDetails;
