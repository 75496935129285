import {getContactByEmail} from "@common/utils/contacts";
import {ParticipantAction, SessionLifecycle} from "@generated/data";
import {useHistory} from "@workhorse/api/routing";
import {rbac, readRemoteUser} from "@workhorse/api/user";
import {disableNativePopup} from "@workhorse/components/LeavePageNative";
import {readQuery, writeQuery} from "@workhorse/dataApi";
import {setIsKicked} from "@workhorse/pages/feedback/feedbackStateVars";
import {
    MicroNotificationProps,
    MicroNotificationsDebouncerRef,
    UserJoinedOrLeftMessage,
} from "@workhorse/pages/player/micro-notification-center";
import singleUseCaseIsAssistant from "@workhorse/pages/player/singleUseCaseIsAssistant";
import {playSoundAction, playSoundActionDebounced, SoundActionType} from "@workhorse/pages/player/soundActions";
import {readSession} from "../SessionDataProviders";
import {WatchdogPayload} from "./Watchdog";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {t} from "i18next";
import {RECORDING_USER_EMAIL} from "@common/recording/constants";

type Options = {
    sessionId: string;
    myParticipantId: string;
    history: ReturnType<typeof useHistory>;
    isOwner: boolean;
    debouncedNotificationsConnectorRef: React.MutableRefObject<MicroNotificationsDebouncerRef>;
    notificationsDisabled: boolean;
};

export function sessionParticipantUpdates(options: Options, updates: NonNullable<WatchdogPayload["watchdog"]["upsertParticipants"]>) {
    const {sessionId, myParticipantId, history, debouncedNotificationsConnectorRef, notificationsDisabled} = options;
    const {lifecycle} = readSession({sessionId}) ?? {};
    const user = readRemoteUser();
    const isRecorder = user?.getRemoteUser.user?.email === RECORDING_USER_EMAIL;
    const localParticipants =
        readQuery("LocalParticipantsDocument", {
            variables: {
                id: sessionId,
                noDiff: true,
            },
        })?.participants ?? [];

    const participantsMap = localParticipants.reduce((agg, item) => {
        agg.set(item.id, item);
        return agg;
    }, new Map());
    for (const participantUpdate of updates) {
        if (participantUpdate.participant.id === myParticipantId) {
            const hasBecomeAssistant = rbac(participantUpdate.participant, "session.isAssistant");
            if (hasBecomeAssistant !== singleUseCaseIsAssistant()) {
                singleUseCaseIsAssistant(hasBecomeAssistant);
            }
        }

        switch (participantUpdate.action) {
            case ParticipantAction.Ban:
            case ParticipantAction.Kick: {
                const participantId = participantUpdate.participant.id;
                if (participantId === myParticipantId) {
                    setIsKicked({
                        kicked: participantUpdate.action === ParticipantAction.Kick,
                        banned: participantUpdate.action === ParticipantAction.Ban,
                        removed: false,
                    });
                    disableNativePopup(true);

                    history.push({
                        pathname: `/feedback/${sessionId}/${myParticipantId}`,
                        state: {
                            isParticipantInSession: true,
                        },
                    });
                }
                break;
            }
            case ParticipantAction.Invite:
            case ParticipantAction.Enter: {
                if (lifecycle === SessionLifecycle.NotStarted) {
                    playSoundActionDebounced(SoundActionType.ParticipantEntered);
                }
                break;
            }
            case ParticipantAction.Leave: {
                if (!(participantUpdate.participant.id === myParticipantId)) {
                    break;
                }
                break;
            }
            default: {
                // orchestrator.onParticipantUpdate(sessionId, participantUpdate);
                break;
            }
        }

        if (
            participantUpdate.action === ParticipantAction.Enter &&
            options.isOwner &&
            document.visibilityState === "hidden" &&
            !isRecorder
        ) {
            playSoundAction(SoundActionType.Notification);
        }

        if (
            participantUpdate.action &&
            [ParticipantAction.Enter, ParticipantAction.Leave].includes(participantUpdate.action) &&
            participantUpdate.participant.id !== myParticipantId &&
            !notificationsDisabled &&
            participantUpdate.participant.hasAttendedSession &&
            !isRecorder
        ) {
            const {firstName: d_firstName, lastName: d_lastName, email = ""} = participantUpdate.participant.dataWithNullableEmail || {};
            const contact = getContactByEmail(email);
            const firstName = contact ? contact.firstName : d_firstName;
            const lastName = contact ? contact.lastName : d_lastName;
            const displayName = `${firstName} ${lastName}`;
            debouncedNotificationsConnectorRef.current.notify?.({
                component: UserJoinedOrLeftNotification,
                message: {
                    participantId: participantUpdate.participant.id,
                    user: displayName,
                    action: participantUpdate.action === ParticipantAction.Enter ? "entered" : "left",
                },
                type: "user-joined-or-left",
            });

            //notify screen readers of participant joining or leaving
            ariaAnnouncerMessageVar(
                t(
                    participantUpdate.action === ParticipantAction.Enter
                        ? "aria_announcer.x_has_entered_the_session"
                        : "aria_announcer.x_has_left_the_session",
                    {displayName}
                ) ?? ""
            );
        }

        participantsMap.set(participantUpdate.participant.id, participantUpdate.participant);
    }

    writeQuery("LocalParticipantsDocument", {
        variables: {
            id: sessionId,
        },
        data: {
            __typename: "Query",
            participants: Array.from(participantsMap.values()),
        },
    });
}

function UserJoinedOrLeftNotification(props: MicroNotificationProps<UserJoinedOrLeftMessage>) {
    if (props.messages.length === 1) {
        const message = props.messages[0].message;

        const actionText = props.messages[0].message.isRoom
            ? message.action === "entered"
                ? t("player.notifications.participants.entered_the_room")
                : t("player.notifications.participants.left_the_room")
            : message.action === "entered"
            ? t("player.notifications.participants.entered")
            : t("player.notifications.participants.left");

        return (
            <>
                <strong>{message.user}</strong> {actionText}.
            </>
        );
    } else {
        const leftNo = props.messages.filter((m) => m.message.action === "left").length;
        const joinedNo = props.messages.filter((m) => m.message.action === "entered").length;
        const isRoom = props.messages[0].message.isRoom;

        return (
            <>
                {leftNo ? (
                    <>
                        {isRoom
                            ? leftNo === 1
                                ? t("player.notifications.participants.one_participant_left_the_room")
                                : t("player.notifications.participants.x_participants_left_the_room", {participants_count: leftNo})
                            : leftNo === 1
                            ? t("player.notifications.participants.one_participant_left")
                            : t("player.notifications.participants.x_participants_left", {participants_count: leftNo})}
                    </>
                ) : null}
                {leftNo && joinedNo ? <> {t("g.and")} </> : null}
                {joinedNo ? (
                    <>
                        {isRoom
                            ? joinedNo === 1
                                ? t("player.notifications.participants.one_participant_entered_the_room")
                                : t("player.notifications.participants.x_participants_entered_the_room", {participants_count: joinedNo})
                            : joinedNo === 1
                            ? t("player.notifications.participants.one_participant_entered")
                            : t("player.notifications.participants.x_participants_entered", {participants_count: joinedNo})}
                    </>
                ) : null}
            </>
        );
    }
}
