import {useClientEvent} from "@api/events/client";
import {SessionRecordingState, SessionRecordingType} from "@generated/data";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import {useRef, useState} from "@workhorse/api/rendering";
import {useLocation} from "@workhorse/api/routing";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import updateSessionFlags from "@workhorse/api/session-settings/utils/updateSessionFlags";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {getGlobalKeybindString} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {ReactComponent as RecordingMenuIcon} from "../../../../api/session-settings/sections/assets/recording-menu.svg";
import {ReactComponent as StopRecording} from "../../../../assets/media/stop-recording.svg";
import StartRecordingDialog from "../RecordingDialogs/StartRecordingDialog";
import LocalParticipantControlMenu from "./LocalParticipantControlMenu";
import {RecordingTogglerButton} from "./RecordingToggler";

export interface RecordingTogglerProps {
    className?: string;
    sessionId: string;
    sessionName: string;
    participantId: string;
    showTooltip?: (e?: any, message?: string) => void;
    backstage?: boolean;
    hideText?: boolean;
    onClick?: () => void;
    livestreamDisabledError: string | null;
    isRoomOrBooking?: boolean;
    isMobile?: boolean;
}

export type RecordingButtonProps = Omit<JSX.IntrinsicElements["li"], "ref"> & {
    className?: string;
    isActive: boolean;
    sessionId: string;
    onToggleRecording: () => void;
    // onToggleLivestream: () => void;
    disabled: boolean;
    hideText?: boolean;
    isStarting?: boolean;
    recordingType: SessionRecordingType;
    livestreamDisabledError: string | null;

    isRoomOrBooking?: boolean;
    isMobile?: boolean;
};

function RecordingButton(props: RecordingButtonProps) {
    const {isActive, sessionId, onToggleRecording, className, hideText, isStarting, recordingType, isRoomOrBooking, isMobile, ...liProps} =
        props;

    const {canUseRecording, triggerGenericAccessToast} = useWorkspaceAccess();

    const {t} = useTranslation();

    const triggerToastError = () => {
        triggerGenericAccessToast();
    };

    const {show} = useSessionSettingsDialogDisplay();

    const startRecordingKey = "participant.recording.start_recording";
    const stopRecordingKey = "participant.recording.stop_recording";

    const recordingActive =
        isActive &&
        (props.recordingType === SessionRecordingType.Recording || props.recordingType === SessionRecordingType.RecordingLivestream);

    return (
        <LocalParticipantControlMenu
            options={[
                <MenuItem key={"menuitem-recording-settings"} onClick={() => show("general.recording")}>
                    <RecordingMenuIcon />
                    <Typography color="secondary" fontWeight="bold">
                        {t("participant.recording.recording_settings")}
                    </Typography>
                </MenuItem>,

                <RecordingTogglerButton
                    disabled={isStarting}
                    key={"menuitem-recording-toggler"}
                    active={isActive}
                    onToggle={canUseRecording() ? onToggleRecording : triggerToastError}
                    recordingType={props.recordingType}
                />,
            ]}
            disabled={props.disabled}
        >
            <MenuItem
                data-id="start-recording"
                button
                {...liProps}
                className={className}
                onClick={liProps.disabled ? undefined : canUseRecording() ? onToggleRecording : triggerToastError}
                role="button"
                tabIndex={0}
                aria-label={`${recordingActive ? t(stopRecordingKey) : t(startRecordingKey)}, (${getGlobalKeybindString(
                    "toggleRecording"
                )})`}
            >
                {!hideText && (
                    <Typography color="nonary" fontWeight="bold" className="mr-4">
                        {recordingActive ? t(stopRecordingKey) : t(startRecordingKey)}
                    </Typography>
                )}
                {recordingActive ? <StopRecording /> : <RadioButtonCheckedRoundedIcon />}
            </MenuItem>
        </LocalParticipantControlMenu>
    );
}

export function RecordingToggler(props: RecordingTogglerProps) {
    const location = useLocation<{recordingStarted?: boolean}>();

    const {participantId, sessionId, sessionName, showTooltip, backstage, hideText, onClick} = props;
    const {canUseRecording, triggerGenericAccessToast} = useWorkspaceAccess();

    const [selectedRecType, setSelectedRecType] = useState<SessionRecordingType>(SessionRecordingType.Recording);

    const {isGuest} = useUserInfo();

    const {t} = useTranslation();

    const confirmationDialogRef = useRef<DialogImperativeRef>();

    const [disabled, setDisabled] = useState(false);

    const {isActive, start, stop, recordingState, recordingType} = useRecordingProcessor();

    const isRecordingActive =
        isActive && (recordingType === SessionRecordingType.Recording || recordingType === SessionRecordingType.RecordingLivestream);
    const isLivestreamActive = isActive && recordingType === SessionRecordingType.Livestream;
    const isLivestreamRecordingActive = isActive && recordingType === SessionRecordingType.RecordingLivestream;

    const keybindRecording = getGlobalKeybindString("toggleRecording");

    const recordingStarted = location.state?.recordingStarted === true;

    const toggleRecording = () => {
        if (recordingState === SessionRecordingState.Starting || disabled) {
            return;
        }

        if (isRecordingActive || isLivestreamRecordingActive) {
            ariaAnnouncerMessageVar(t("aria_announcer.stopped_recording") || "Stopped recording");

            return stop();
        }

        onClick?.();
        confirmationDialogRef.current?.toggle?.();
    };

    const confirmationOkRecording = async () => {
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 3000);

        confirmationDialogRef?.current?.toggle?.();

        if (recordingType !== selectedRecType) {
            await updateSessionFlags(sessionId, {recordingType: selectedRecType});
        }
        start(sessionId, sessionName);

        localStorage.setItem("transcript-or-recording-started", `${sessionId} ${participantId}`);

        ariaAnnouncerMessageVar(t("aria_announcer.started_recording") ?? "Started recording");
    };

    const confirmationCancel = () => {
        if (confirmationDialogRef?.current?.isOpen) {
            confirmationDialogRef?.current?.toggle?.();
        }
    };

    const onMouseEnter = (e: any) => {
        const message = backstage
            ? t("participant.recording.recording_unavailable_in_backstage")
            : `${isActive ? t("participant.recording.stop_recording") : t("participant.recording.start_recording")}  (${keybindRecording})`;
        showTooltip?.(e, message);
    };

    const onMouseLeave = () => {
        showTooltip?.();
    };

    const className = cls(props.className, (isRecordingActive || isLivestreamRecordingActive) && !disabled && "active-recording");

    useClientEvent("player-keybind-toggle-recording", () => {
        if (
            !recordingStarted &&
            !backstage &&
            !isGuest &&
            (recordingState ? ![SessionRecordingState.Starting, SessionRecordingState.Stopping].includes(recordingState) : true) &&
            !isLivestreamActive
        ) {
            if (canUseRecording()) {
                toggleRecording();
            } else {
                triggerGenericAccessToast();
            }
        }
    });

    if (isGuest) {
        return null;
    }

    const startRecordingKey = "participant.recording.start_recording";
    const stopRecordingKey = "participant.recording.stop_recording";

    return (
        <>
            <RecordingButton
                className={className}
                isActive={isActive}
                sessionId={sessionId}
                onToggleRecording={toggleRecording}
                hideText={hideText}
                isStarting={recordingState === SessionRecordingState.Starting}
                recordingType={recordingType}
                livestreamDisabledError={props.livestreamDisabledError}
                disabled={
                    recordingStarted ||
                    disabled ||
                    backstage ||
                    (!!recordingState && [SessionRecordingState.Starting, SessionRecordingState.Stopping].includes(recordingState)) ||
                    isLivestreamActive
                }
                aria-label={`${
                    isRecordingActive || isLivestreamRecordingActive ? t(stopRecordingKey) : t(startRecordingKey)
                }, (${getGlobalKeybindString("toggleRecording")})`}
                isRoomOrBooking={props.isRoomOrBooking}
                isMobile={props.isMobile}
            />

            <StartRecordingDialog
                disabled={recordingState === SessionRecordingState.Starting || disabled}
                dialogRef={confirmationDialogRef}
                onOk={confirmationOkRecording}
                onCancel={confirmationCancel}
                selectedRecType={selectedRecType}
                setRecordingType={setSelectedRecType}
                canLivestream={!props.livestreamDisabledError}
            />
        </>
    );
}
