import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useMemo} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import {useUserInfo} from "@workhorse/providers/User";
import {TemplatesSectionPaths, templatesSections} from "../../../utils";
import classes from "./style/AgendaTemplatesEditorHeaderBreadcrumbs.module.scss";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import {ReactComponent as SlashDividerIcon} from "../../../../../assets/media/slash_divider_icon.svg";

type AgendaTemplatesEditorHeaderBreadcrumbsProps = {
    templateName?: string;
    section: TemplatesSectionPaths;
};

const AgendaTemplatesEditorHeaderBreadcrumbs = (props: AgendaTemplatesEditorHeaderBreadcrumbsProps) => {
    const history = useHistory();
    const {section} = props;

    const user = useUserInfo();
    const organizationName = user.activeOrganizationPermission.organization.name ?? "Workspace";

    const goBack = () => {
        history.push({
            pathname: "/templates/" + section,
        });
    };
    const sectionName = useMemo(() => templatesSections.find((s) => s.path === section)?.title, [section]);

    return (
        <div className={cls("flex flex-align-center", classes.root)}>
            <div data-id="go-back" onClick={goBack} className="flex flex-items-center cursor-pointer mr-12">
                <ArrowBackRoundedIcon />
                <Typography color="primary" fontWeight="bolder" variant="lg" noWrap>
                    {sectionName !== "Workspace" ? sectionName : `${organizationName} agendas`}
                </Typography>
            </div>
            <SlashDividerIcon />
            <Typography color="blueGray400" fontWeight="bolder" noWrap variant="lg">
                {props.templateName}
            </Typography>
        </div>
    );
};

export default AgendaTemplatesEditorHeaderBreadcrumbs;
