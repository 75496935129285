import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Button from "@ui/cdk/Button";
import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {memo, useMemo, useState} from "@workhorse/api/rendering";
import {WithClassName} from "@workhorse/declarations";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useCurrentParticipant, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {getEmptyText, ParticipantsCategory, participantsCategoryInfo} from "../utils";
import ParticipantEntry from "./ParticipantEntry";
import ParticipantsCategoryMenu from "./ParticipantsCategoryMenu";
import classes from "./styles/ParticipantsCategoryBlock.module.scss";
import {useTranslation} from "react-i18next";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type ParticipantsCategoryBlockProps = WithClassName & {
    type: ParticipantsCategory;
    listKey: string;
    isOwner: boolean;
    isAssistant: boolean;
    currentParticipantId: string;
    isMemoryMode: boolean;
    sessionId: string;
    emptyText?: string;
    onWaitingForApproval: (participantIds: string[], hasBeenApproved: boolean) => void;
    sessionActualStart?: Date | null | undefined;
    sessionDisabledNotifications?: boolean;
};

function ParticipantsCategoryBlock(props: ParticipantsCategoryBlockProps) {
    const {t} = useTranslation();
    const {
        type,
        listKey,
        isOwner,
        isAssistant,
        currentParticipantId,
        isMemoryMode,
        onWaitingForApproval,
        className,
        sessionActualStart,
        sessionDisabledNotifications,
    } = props;

    const participants = useParticipantsStore(useShallow((state) => state[listKey] as Participant[]));
    const hasSearchValue = useParticipantsStore(({searchValue}) => !!searchValue);

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const [height, setHeight] = useState<number | undefined>(undefined);
    const [setNode] = useLocalResizeObserver((width, height) => setHeight(height));
    const currentParticipant = useCurrentParticipant();
    const isInvited = type === ParticipantsCategory.INVITED;
    const {isLobby} = useIsLobby();

    const handleToggleCollapse = () => {
        if (isInvited) {
            return;
        }

        setIsCollapsed(!isCollapsed);
    };

    const handleAdmitAll = () => {
        const participantIds = participants.map((p) => p.id);
        onWaitingForApproval(participantIds, true);
    };

    let guestIndex = 0;

    const sortedParticipants = useMemo(() => [...participants].sort((p) => (p.id === currentParticipant.id ? -1 : 1)), [participants]);

    const categoryInfo = participantsCategoryInfo[type];
    const emptyText = getEmptyText(type, !!participants.length, hasSearchValue, isMemoryMode, isLobby);

    return (
        <div
            data-id={`${categoryInfo.title.replace(/\s/g, "-").toLowerCase()}`}
            className={cls(classes.root, isInvited ? classes.rootInvited : classes.rootSimple, className)}
        >
            <div className={classes.header}>
                <div className={classes.headerInfo} onClick={handleToggleCollapse}>
                    {!isInvited ? (
                        <>
                            <IconButton
                                size="small"
                                className={cls(classes.dropdown, isCollapsed && classes.dropdownCollapsed)}
                                aria-label={`Toggle ${categoryInfo.title} list collapse`}
                            >
                                <KeyboardArrowDownRoundedIcon />
                            </IconButton>
                            <Typography variant="lg" color="secondary" fontWeight="boldest">
                                {t(`macro.participants.category.${categoryInfo.title}`)}
                            </Typography>
                            <Typography
                                component="div"
                                variant="sm"
                                fontWeight="bold"
                                className={cls(classes.count, classes[`count${categoryInfo.color}`])}
                            >
                                <span data-id="count">{participants.length}</span>
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="sm" color="senary" fontWeight="bold">
                            {isMemoryMode ? t("macro.participants.not_joined") : t(`macro.participants.category.${categoryInfo.title}`)}
                        </Typography>
                    )}
                </div>

                {!isMemoryMode && (isOwner || isAssistant) && type === ParticipantsCategory.WAITING_TO_JOIN && (
                    <Button data-id="accept-all" size="small" variant="plain" className={classes.admitAll} onClick={handleAdmitAll}>
                        {t("macro.participants.admit_all")}
                    </Button>
                )}

                {!isMemoryMode &&
                    (isOwner || isAssistant) &&
                    (type === ParticipantsCategory.ATTENDEES || type === ParticipantsCategory.SPEAKERS) && (
                        <ParticipantsCategoryMenu type={type} participants={participants} />
                    )}
            </div>
            <div className={cls(classes.body, isCollapsed && classes.bodyCollapsed)} style={{maxHeight: isCollapsed ? undefined : height}}>
                <div ref={setNode} className="flex flex-col">
                    {sortedParticipants.length ? (
                        sortedParticipants.map((p, index) => {
                            if (!p.dataWithNullableEmail.firstName) {
                                guestIndex++;
                            }

                            return (
                                <ParticipantEntry
                                    key={`pid-${p.id}-${type}`}
                                    index={index}
                                    guestIndex={guestIndex}
                                    className={cls(!p.dataWithNullableEmail.isGuest && "user")}
                                    participant={p}
                                    type={type}
                                    isOwner={isOwner}
                                    isAssistant={isAssistant}
                                    currentParticipantId={currentParticipantId}
                                    onWaitingForApproval={onWaitingForApproval}
                                    isMemoryMode={isMemoryMode}
                                    sessionActualStart={sessionActualStart}
                                    sessionDisabledNotifications={sessionDisabledNotifications}
                                />
                            );
                        })
                    ) : emptyText ? (
                        <div className={classes.emptyText}>{t(emptyText)}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default memo(ParticipantsCategoryBlock);
