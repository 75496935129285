import {useClientEvent} from "@api/events/client";
import {SessionRecordingState, SessionRecordingType} from "@generated/data";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import {useRef, useState} from "@workhorse/api/rendering";
import {useLocation} from "@workhorse/api/routing";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import updateSessionFlags from "@workhorse/api/session-settings/utils/updateSessionFlags";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {getGlobalKeybindString} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";
import {LivestreamTogglerButton} from "./LivestreamToggler";
import LocalParticipantControlMenu from "./LocalParticipantControlMenu";
import {RecordingButtonProps, RecordingTogglerProps} from "./Recording";

import {ReactComponent as LivestreamIcon} from "../../../../assets/media/livestream-icon.svg";
import {ReactComponent as LivestreamRecordingIcon} from "../../../../assets/media/livestream-recording-icon.svg";
import StartLivestreamDialog from "../RecordingDialogs/StartLivestreamDialog";

type LivestreamButtonProps = Omit<RecordingButtonProps, "onToggleRecording"> & {
    onToggleLivestream: () => void;
    isRoomOrBooking?: boolean;
    isMobile?: boolean;
};

function LivestreamButton(props: LivestreamButtonProps) {
    const {isActive, sessionId, onToggleLivestream, className, hideText, isStarting, recordingType, ...liProps} = props;

    const {canUseRecording, triggerGenericAccessToast} = useWorkspaceAccess();

    const {t} = useTranslation();

    const triggerToastError = () => {
        triggerGenericAccessToast();
    };

    const {show} = useSessionSettingsDialogDisplay();

    const startLivestreamKey = "participant.recording.start_livestreaming";
    const stopLivestreamKey = "participant.recording.stop_livestreaming";

    const disabledLivestream = props.recordingType?.includes(SessionRecordingType.Livestream) && !!props.livestreamDisabledError;

    const livestreamRecordingActive = props.recordingType === SessionRecordingType.RecordingLivestream;
    const livestreamActive = props.recordingType === SessionRecordingType.Livestream;

    return (
        <LocalParticipantControlMenu
            options={[
                <MenuItem key={"menuitem-recording-settings"} onClick={() => show("general.livestreaming")}>
                    <LivestreamIcon />
                    <Typography color="secondary" fontWeight="bold">
                        {t("participant.livestreaming.livestreaming_settings")}
                    </Typography>
                </MenuItem>,
                <LivestreamTogglerButton
                    disabled={isStarting || disabledLivestream}
                    key={"menuitem-recording-toggler"}
                    active={isActive}
                    onToggle={canUseRecording() ? onToggleLivestream : triggerToastError}
                    recordingType={props.recordingType}
                    livestreamDisabledError={props.livestreamDisabledError}
                />,
            ]}
            disabled={props.disabled}
        >
            <MenuItem
                data-id="start-recording"
                button
                {...liProps}
                className={className}
                onClick={liProps.disabled ? undefined : canUseRecording() ? onToggleLivestream : triggerToastError}
                role="button"
                tabIndex={0}
                aria-label={`${
                    livestreamActive || livestreamRecordingActive ? t(stopLivestreamKey) : t(startLivestreamKey)
                }, (${getGlobalKeybindString("toggleLivestream")})`}
            >
                {!hideText && (
                    <Typography color="nonary" fontWeight="bold" className="mr-4">
                        {livestreamActive || livestreamRecordingActive ? t(stopLivestreamKey) : t(startLivestreamKey)}
                    </Typography>
                )}
                {livestreamRecordingActive ? <LivestreamRecordingIcon /> : <LivestreamIcon />}
            </MenuItem>
        </LocalParticipantControlMenu>
    );
}

export function LivestreamToggler(props: RecordingTogglerProps) {
    const location = useLocation<{recordingStarted?: boolean}>();

    const {participantId, sessionId, sessionName, showTooltip, backstage, hideText, onClick} = props;
    const {canUseRecording, triggerGenericAccessToast} = useWorkspaceAccess();

    const [selectedRecType, setSelectedRecType] = useState<SessionRecordingType>(SessionRecordingType.Livestream);

    const {isGuest} = useUserInfo();

    const {t} = useTranslation();

    const confirmationDialogLivestreamRef = useRef<DialogImperativeRef>();

    const [disabled, setDisabled] = useState(false);

    const {isActive, start, stop, recordingState, recordingType} = useRecordingProcessor();

    const isRecordingActive = isActive && recordingType === SessionRecordingType.Recording;
    const isLivestreamActive = isActive && recordingType === SessionRecordingType.Livestream;
    const isLivestreamRecordingActive = isActive && recordingType === SessionRecordingType.RecordingLivestream;

    const keybindLivestream = getGlobalKeybindString("toggleLivestream");

    const recordingStarted = location.state?.recordingStarted === true;

    const toggleLivestream = () => {
        if (recordingState === SessionRecordingState.Starting || disabled) {
            return;
        }

        if (isLivestreamActive || isLivestreamRecordingActive) {
            ariaAnnouncerMessageVar(t("aria_announcer.stopped_livestream") || "Stopped livestreaming");

            return stop();
        }
        onClick?.();
        confirmationDialogLivestreamRef.current?.toggle?.();
    };

    const confirmationOkLivestream = async () => {
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 3000);

        confirmationDialogLivestreamRef?.current?.toggle?.();

        if (recordingType !== selectedRecType) {
            await updateSessionFlags(sessionId, {recordingType: selectedRecType});
        }
        start(sessionId, sessionName);

        localStorage.setItem("transcript-or-recording-started", `${sessionId} ${participantId}`);

        ariaAnnouncerMessageVar(t("aria_announcer.started_livestream") ?? "Started livestreaming");
    };

    const confirmationCancel = () => {
        if (confirmationDialogLivestreamRef?.current?.isOpen) {
            confirmationDialogLivestreamRef?.current?.toggle?.();
        }
    };

    // const onMouseEnter = (e: any) => {
    //     const message = backstage
    //         ? t("participant.recording.recording_unavailable_in_backstage")
    //         : `${isActive ? t("participant.recording.stop_recording") : t("participant.recording.start_recording")}  (${keybindLivestream})`;
    //     showTooltip?.(e, message);
    // };

    // const onMouseLeave = () => {
    //     showTooltip?.();
    // };

    const className = cls(props.className, (isLivestreamActive || isLivestreamRecordingActive) && !disabled && "active-recording");

    useClientEvent("player-keybind-toggle-livestream", () => {
        if (
            !recordingStarted &&
            !backstage &&
            !isGuest &&
            (recordingState ? ![SessionRecordingState.Starting, SessionRecordingState.Stopping].includes(recordingState) : true) &&
            !props.isRoomOrBooking &&
            !isRecordingActive
        ) {
            if (canUseRecording()) {
                toggleLivestream();
            } else {
                triggerGenericAccessToast();
            }
        }
    });

    if (isGuest) {
        return null;
    }

    const startLivestreamKey = "participant.recording.start_livestreaming";
    const stopLivestreamKey = "participant.recording.stop_livestreaming";

    return (
        <>
            <LivestreamButton
                className={className}
                isActive={isActive}
                sessionId={sessionId}
                onToggleLivestream={toggleLivestream}
                hideText={hideText}
                isStarting={recordingState === SessionRecordingState.Starting}
                recordingType={recordingType}
                livestreamDisabledError={props.livestreamDisabledError}
                disabled={
                    recordingStarted ||
                    disabled ||
                    backstage ||
                    (!!recordingState && [SessionRecordingState.Starting, SessionRecordingState.Stopping].includes(recordingState)) ||
                    isRecordingActive
                }
                aria-label={`${
                    isLivestreamActive || isLivestreamRecordingActive ? t(stopLivestreamKey) : t(startLivestreamKey)
                }, (${getGlobalKeybindString("toggleRecording")})`}
                isRoomOrBooking={props.isRoomOrBooking}
                isMobile={props.isMobile}
            />

            <StartLivestreamDialog
                disabled={recordingState === SessionRecordingState.Starting || disabled}
                dialogRef={confirmationDialogLivestreamRef}
                onOk={confirmationOkLivestream}
                onCancel={confirmationCancel}
                selectedRecType={selectedRecType}
                setRecordingType={setSelectedRecType}
            />
        </>
    );
}
