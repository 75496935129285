import ShareIcon from "@material-ui/icons/Share";
import classes from "./styles/ShareSessionLink.module.scss";
import Button, {ButtonProps} from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {WithClassName} from "@workhorse/declarations";
import {useTranslation} from "react-i18next";

type ShareSessionLinkProps = {
    url: string;
    extraText?: string;
    withMarginLeftAuto?: boolean;
    variant?: ButtonProps["variant"];
    hideIcon?: boolean;
} & WithClassName;

function ShareSessionLink(props: ShareSessionLinkProps) {
    const {t} = useTranslation();

    const {url, extraText, variant = "plain", withMarginLeftAuto, hideIcon} = props;

    const handleShareLink = () => {
        navigator.share({
            title: t("lobby.share_link.join") ?? undefined,
            url: url,
        });
    };

    return (
        <Button
            onClick={handleShareLink}
            className={cls(
                classes.shareBtn,
                withMarginLeftAuto && classes.withMarginLeftAuto,
                extraText && classes.withExtraText,
                props.className
            )}
            variant={variant}
            aria-label={t("lobby.share_link.name") ?? undefined}
        >
            {hideIcon ? null : <ShareIcon className={classes.shareIcon} />}
            {extraText ? <Typography className={classes.extraText}>{extraText}</Typography> : null}
        </Button>
    );
}

export default ShareSessionLink;
