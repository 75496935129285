import {useState} from "@workhorse/api/rendering";
import {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import Watchdog from "@workhorse/providers/Watchdog";
import AssistantDetector from "./AssistantDetector";
import LoadPlayerSession from "./LoadPlayerSession";
import PendingJoinApprovals from "./PendingJoinApprovals";

export type PendingParticipant = NonNullable<
    NonNullable<SubscriptionPayload<"WatchdogPrivateDocument">["watchdogPrivate"]["approvalRequestsAsOwner"]>["requestingParticipant"]
>;

type IPlayerPrivacyLayerProps = {
    sessionId: string;
    isOwner: boolean;
    participantId: string;
    isAssistant: boolean;
    skipJoinLayers: boolean;
    sessionIsPublished: boolean;
    tryJoinCalled: boolean;
    isLeaving: boolean;
};

function PlayerPrivacyLayer(props: IPlayerPrivacyLayerProps) {
    const {isOwner, participantId, skipJoinLayers, tryJoinCalled} = props;
    const [isAssistant, setIsAssistant] = useState(props.isAssistant);

    const renderCondition = !skipJoinLayers || tryJoinCalled;

    return (
        <>
            {renderCondition && (
                <Watchdog
                    sessionId={props.sessionId}
                    isAssistant={isAssistant}
                    isOwner={isOwner}
                    myParticipantId={participantId}
                    key="watchdog"
                />
            )}
            {(renderCondition || skipJoinLayers) && (
                <LoadPlayerSession
                    isJoined={renderCondition}
                    sessionId={props.sessionId}
                    isOwner={props.isOwner}
                    key="player-session-loader"
                />
            )}

            {(isOwner || isAssistant) && renderCondition && (
                <PendingJoinApprovals sessionId={props.sessionId} key="pending-join-approvals" />
            )}
            {renderCondition && <AssistantDetector onChange={setIsAssistant} key="assistant-detector" />}
        </>
    );
}

export default PlayerPrivacyLayer;
