import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {useQuery, writeQuery} from "@workhorse/dataApi";
import {useMemo} from "../rendering";
import {createContextProvider} from "../utils/context";
import {RemoteApplicationState} from "./state";

function RemoteApplicationStateSubscriber() {
    const onData = (data: SubscriptionPayload<"ApplicationStateUpdatedDocument">) => {
        if (data?.applicationStateChanges) {
            writeQuery("GetRemoteApplicationStateDocument", {
                data: {
                    __typename: "Query",
                    getApplicationState: data.applicationStateChanges,
                },
            });

            const applicationState = (data?.applicationStateChanges?.state ?? {}) as RemoteApplicationState;
            if (applicationState.currentOperation === "DEPLOY_IN_PROGRESS") {
                console.log("deploy in progress");
                // window.location.reload();
            }
        }
    };
    return <GenericSubscriber document="ApplicationStateUpdatedDocument" onData={onData} />;
}

function useRemoteApplicationStore() {
    const {data} = useQuery("GetRemoteApplicationStateDocument", {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-only",
    });

    const applicationState = (data?.getApplicationState?.state ?? {}) as RemoteApplicationState;

    return useMemo(() => ({applicationState}), [data]);
}

const [RemoteApplicationStateProvider, useRemoteApplicationState] = createContextProvider(
    {
        name: "RemoteApplicationStateProvider",
        strict: true,
    },
    useRemoteApplicationStore
);
export {useRemoteApplicationState, RemoteApplicationStateSubscriber};
export default RemoteApplicationStateProvider;
