import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import {CustomResolvers} from "./types";

export const SessionEvent: CustomResolvers["Event"] = {
    ...fieldResolvers.SessionEvent,
    // colorPalette: {
    //     read: (existing, {fieldName, readField, variables}) => {
    //         console.log("in read", existing, fieldName, variables);
    //         if (!(variables as any)?.noDiff) {
    //             const update = readField("update") as object;
    //             if (update && fieldName in update) {
    //                 return update?.[fieldName];
    //             }
    //         }

    //         return existing;
    //     },
    // },
};
