import {ConferenceParticipantStatus, ParticipantStatus, PrivateChatAccess} from "@generated/data";
import {MenuProps} from "@ui/core/components/Menu";
import {useCallback, useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useIsLobby, useMyParticipant, useParticipant, useSession} from "@workhorse/providers/SessionDataProviders";
import {useRemoteParticipantStatus} from "../ConferenceRoomProvider";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type SpeakerActionsState = {
    anchorEl: HTMLElement;
    participantId: string;
} & Pick<MenuProps, "transformOrigin" | "anchorOrigin" | "className" | "classes">;

function useParticipantActionsStore() {
    const [state, setState] = useState<SpeakerActionsState | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = useCallback((value: SpeakerActionsState) => {
        setState(value);
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return useMemo(
        () => ({
            open,
            close,
            value: state,
            isOpen,
        }),
        [open, close, state, isOpen]
    );
}

export const [ParticipantActionsProvider, useParticipantActions] = createContextProvider(
    {
        name: "ParticipantActions",
        strict: false,
    },
    useParticipantActionsStore
);

type ParticipantActions = {
    // Self actions
    selfAudio: boolean;
    selfVideo: boolean;
    selfHideSelfView: boolean;
    selfRemoveFromSpotlight: boolean;
    selfRemoveRaisingHand: boolean;
    // Only for the guest
    selfChangeMyName: boolean;

    // Spotlight actions
    addToSpotlight: boolean;
    removeFromSpotlight: boolean;
    removeRaisingHand: boolean;

    // Microphone actions
    mute: boolean;
    askToUnmute: boolean;

    // Camera actions
    stopCamera: boolean;
    askToStartCamera: boolean;

    makeAssistant: boolean;
    removeAssistant: boolean;
    showMakeAssistantDisabled: boolean;

    removeFromSession: boolean;
    removeParticipant: boolean;

    sendPrivateMessage: boolean;
    viewAnswers: boolean;
};

export const useHaveActionsOnParticipant = (participantId: string): [boolean, ParticipantActions] => {
    const {isLobby} = useIsLobby();

    const {
        id,
        isOwner,
        isSpeaker,
        isAssistant,
        isTargetAssistant,
        isTargetOwner,
        isGuest,
        isInLobby,
        isJoined,
        isKicked,
        isTargetGuest,
        isTargetRaisingHand,
        isTargetSpeaker,
        isTargetSpotlight,
        isWaitingForApproval,
        isCurrentParticipantPendingSpeaker,
        isCurrentParticipantSpeaker,
        isTargetInvitedByTheOwner,
        isMainParticipant,
    } = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant, participantsMap, isParticipantAssistant}) => ({
            id: currentParticipant?.id ?? "",
            isOwner: currentParticipant?.isOwner ?? false,
            isSpeaker: !!currentParticipant?.speakerDetails?.id,
            isAssistant: amIAssistant,
            isGuest: !!currentParticipant?.dataWithNullableEmail?.isGuest,
            isTargetOwner: participantsMap[participantId]?.isOwner,
            isTargetAssistant: isParticipantAssistant(participantId),
            isTargetSpotlight: participantsMap[participantId]?.conferenceStatus === ConferenceParticipantStatus.Speaker,
            isTargetRaisingHand: participantsMap[participantId]?.conferenceStatus === ConferenceParticipantStatus.PendingSpeaker,
            isTargetGuest: !!participantsMap[participantId]?.dataWithNullableEmail?.isGuest,
            isTargetSpeaker: !!participantsMap[participantId]?.speakerDetails?.id,
            isInLobby: participantsMap[participantId]?.status === ParticipantStatus.InLobby,
            isJoined: participantsMap[participantId]?.status === ParticipantStatus.JoinedSession,
            isWaitingForApproval: participantsMap[participantId]?.status === ParticipantStatus.WaitForApproval,
            isKicked: participantsMap[participantId]?.status === ParticipantStatus.Kicked,
            isCurrentParticipantSpeaker: currentParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker,
            isCurrentParticipantPendingSpeaker: currentParticipant?.conferenceStatus === ConferenceParticipantStatus.PendingSpeaker,
            isTargetInvitedByTheOwner: participantsMap[participantId]?.invitedByTheOwner,
            isMainParticipant: participantsMap[participantId]?.isMainParticipant,
        }))
    );

    const {cameraOn, micOn} = useRemoteParticipantStatus(participantId); // {muted: true, videoEnabled: false}; // useAttendeeStatus(chimeAttendeeId);

    const session = useSession();

    const isOrganizer = isOwner || isAssistant;
    const isSelf = participantId === id;

    const isTargetOrganizer = isTargetOwner || isTargetAssistant;

    const isPrivateChatHidden =
        session.privateChatAccess === PrivateChatAccess.Disabled ||
        (session.privateChatAccess === PrivateChatAccess.Moderators &&
            !isSpeaker &&
            !isOrganizer &&
            !isTargetSpeaker &&
            !isTargetOrganizer);

    const actions: ParticipantActions = {
        selfAudio: !isLobby && isSelf,
        selfVideo: !isLobby && isSelf,
        selfHideSelfView: !isLobby && isSelf,
        selfRemoveFromSpotlight: !isLobby && isSelf && isCurrentParticipantSpeaker,
        selfRemoveRaisingHand: !isLobby && isSelf && isCurrentParticipantPendingSpeaker,
        selfChangeMyName: isSelf && isGuest,

        addToSpotlight: !isLobby && isJoined && isOrganizer,
        removeFromSpotlight: !isLobby && !isSelf && isTargetSpotlight && isOrganizer,
        removeRaisingHand: !isLobby && !isSelf && isTargetRaisingHand && isOrganizer,

        mute: !isLobby && isJoined && !isSelf && !!micOn && isOrganizer,
        askToUnmute: !isLobby && isJoined && !isSelf && !micOn && isOrganizer,

        stopCamera: !isLobby && isJoined && !isSelf && !!cameraOn && isOrganizer,
        askToStartCamera: !isLobby && isJoined && !isSelf && !cameraOn && isOrganizer,

        makeAssistant: !isSelf && !isTargetOrganizer && isOrganizer,
        removeAssistant: !isSelf && isTargetAssistant && isOrganizer,

        showMakeAssistantDisabled: !isSelf && !isTargetOrganizer && isOrganizer && isTargetGuest,

        removeFromSession: !isSelf && !isTargetOwner && isOrganizer && (isJoined || isInLobby),
        removeParticipant:
            !isSelf && !isTargetOwner && !isWaitingForApproval && !isKicked && !(isJoined || isInLobby) && !!isTargetInvitedByTheOwner,

        sendPrivateMessage: !isSelf && (isJoined || isInLobby) && !isPrivateChatHidden,
        viewAnswers: Boolean(isOrganizer && isMainParticipant),
    };

    const haveActions = Object.values(actions).some((value) => value);

    return useMemo(
        () => [
            haveActions,
            {
                ...actions,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            haveActions,
            actions.selfAudio,
            actions.selfVideo,
            actions.selfHideSelfView,
            actions.selfRemoveFromSpotlight,
            actions.selfRemoveRaisingHand,
            actions.selfChangeMyName,
            actions.addToSpotlight,
            actions.removeFromSpotlight,
            actions.removeRaisingHand,
            actions.mute,
            actions.askToUnmute,
            actions.stopCamera,
            actions.askToStartCamera,
            actions.makeAssistant,
            actions.removeAssistant,
            actions.removeFromSession,
            actions.removeParticipant,
            actions.sendPrivateMessage,
            actions.viewAnswers,
        ]
    );
};
