import {HTMLProps, useEffect, useState, memo, useMemo} from "@workhorse/api/rendering";
import {RemoteTrack, RemoteTrackPublication, LocalTrackPublication} from "livekit-client";
import {useConferenceParticipant} from "../../providers/ConferenceRoomProvider";
import {AudioElementWithSinkId} from "../../types";
import {useOrderedAsyncReplaceableTask} from "../../lib/useAsyncReplaceableTask";
import {supportsWebAudio} from "../../lib/DeviceManager/AudioContext";

interface Props extends Omit<HTMLProps<HTMLAudioElement>, "ref" | "onResize"> {
    sinkId?: string;
    participantId: string;
    supportsSetSinkId: boolean;
}

interface TrackProps {
    publication: RemoteTrackPublication;
    sinkId?: string;
    supportsSetSinkId: boolean;
}

function attach(track: RemoteTrack, audioNode?: HTMLAudioElement | null) {
    if (audioNode) {
        track.attach(audioNode);
    } else {
        track.attach();
    }
}

function detach(track: RemoteTrack, audioNode?: HTMLAudioElement | null) {
    if (audioNode) {
        track.detach(audioNode);
    } else {
        track.detach();
    }
}

function TrackAudio({publication, sinkId, supportsSetSinkId}: TrackProps) {
    const [audioNode, setAudioNode] = useState<HTMLAudioElement | null>(null);
    const enqueue = useOrderedAsyncReplaceableTask();

    useEffect(() => {
        if (!audioNode && !supportsWebAudio) {
            return;
        }

        let currentTrack = publication.track ?? null;

        const attachTrack = (track: RemoteTrack) => {
            if (currentTrack) {
                detach(currentTrack, audioNode);
            }
            currentTrack = track;
            attach(track, audioNode);
        };

        const detachTrack = () => {
            if (currentTrack) {
                detach(currentTrack, audioNode);
            }
            currentTrack = null;
        };

        if (currentTrack) {
            attachTrack(currentTrack);
        }

        publication.on("subscribed", attachTrack);
        publication.on("unsubscribed", detachTrack);

        return () => {
            detachTrack();
            publication.off("subscribed", attachTrack);
            publication.off("unsubscribed", detachTrack);
        };
    }, [audioNode, publication]);

    useEffect(() => {
        if (!supportsSetSinkId || !sinkId || !audioNode) {
            return;
        }

        const element = audioNode as AudioElementWithSinkId;

        enqueue(() => {
            return element.setSinkId(sinkId).catch((e) => console.log(e));
        });
    }, [supportsSetSinkId, sinkId, audioNode]);

    if (supportsWebAudio()) {
        return null;
    }

    return <audio ref={setAudioNode} autoPlay={true} />;
}

export const RemoteAudio = memo((props: Props) => {
    const {participantId, sinkId, supportsSetSinkId} = props;

    const {microphone, screen_share_audio, isLocalParticipant} = useConferenceParticipant(participantId);

    const isLocal = useMemo(() => {
        return isLocalParticipant || (!!microphone && microphone instanceof LocalTrackPublication);
    }, [microphone, isLocalParticipant]);

    if (isLocal) {
        return null;
    }

    return (
        <>
            {microphone && <TrackAudio publication={microphone} sinkId={sinkId} supportsSetSinkId={supportsSetSinkId} />}
            {screen_share_audio && <TrackAudio publication={screen_share_audio} sinkId={sinkId} supportsSetSinkId={supportsSetSinkId} />}
        </>
    );
});

RemoteAudio.displayName = "RemoteAudio";
