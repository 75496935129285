import browserInfo from "@workhorse/api/BrowserInfo";
import {isVideoFxConfigImage, VideoFxConfig} from "../VideoReplacement";
import {BackgroundConfig} from "./helpers/BackgroundHelper";
import {SegmentationConfig} from "./helpers/SegmentationHelper";
import {loadTFLite, loadTFLiteModel, TFLite} from "./helpers/TFLite";
import {createTimerWorker} from "./helpers/timer";
import {buildWebGL2Pipeline} from "./Pipeline";
import {simd} from "wasm-feature-detect";
import {PostProcessingConfig} from "./PostProcessing";

const logger = console;

type RenderingPipeline = {
    render(): Promise<void>;
    updatePostProcessingConfig(newPostProcessingConfig: PostProcessingConfig): void;
    cleanUp(): void;
};

export class VideoReplacementProcessor {
    private started = false;
    private stopped = false;

    private beginTime = 0;
    private previousTime = 0;
    private eventCount = 0;
    private currentFps = 0;
    private frameCount = 0;
    private targetTimerTimeoutMs: number;
    private frameDurations: number[] = [];

    private tflite: TFLite | null = null;
    private pipeline: RenderingPipeline | null = null;
    private renderTimeoutId: number | null = null;
    private fpsIntervalId: NodeJS.Timeout | null = null;

    private isSimdSupported = false;

    private videoElement: HTMLVideoElement;
    private videoWidth: number;
    private videoHeight: number;

    private backgroundElement: HTMLImageElement | null = null;

    private outputCanvas: HTMLCanvasElement;

    private outputStream: MediaStream | undefined;

    private timerWorker = createTimerWorker();

    constructor(private stream: MediaStream, private config: VideoFxConfig, private frameRate = 20) {
        const {width, height} = this.getSizeFromStream(stream);
        this.targetTimerTimeoutMs = 1000 / this.frameRate;
        this.outputCanvas = this.createOutputCanvas(width, height);
        this.videoElement = this.createVideo(stream, width, height);
    }

    async initTflite() {
        this.isSimdSupported = await simd();
        const tflite = await loadTFLite(this.isSimdSupported);
        await loadTFLiteModel(tflite, this.getSegmentationConfig());
        this.tflite = tflite;
        return tflite;
    }

    createPipeline(tflite: TFLite) {
        const prevPipeline = this.pipeline;
        this.pipeline = null;
        prevPipeline?.cleanUp();

        const source = {
            width: this.videoWidth,
            height: this.videoHeight,
            htmlElement: this.videoElement,
        };

        this.removeImage();

        if (isVideoFxConfigImage(this.config)) {
            this.backgroundElement = this.createImage(this.config.image);
        }

        this.pipeline = buildWebGL2Pipeline(
            source,
            this.backgroundElement,
            this.getBackgroundConfig(),
            this.getSegmentationConfig(),
            this.outputCanvas,
            tflite,
            this.timerWorker,
            this.addFrameEvent
        );

        this.pipeline.updatePostProcessingConfig(this.getPostProcessingConfig());
    }

    async start() {
        this.started = true;

        const tflite = await this.initTflite();

        if (this.stopped) {
            // tflite.destroy({});
            return;
        }

        this.createPipeline(tflite);
        this.render();

        // this.fpsIntervalId = setInterval(() => {
        //     logger.log("fps:", this.currentFps);
        // }, 1000);
    }

    stop() {
        if (this.renderTimeoutId) {
            this.timerWorker.clearTimeout(this.renderTimeoutId);
        }

        this.timerWorker.terminate();

        if (this.fpsIntervalId) {
            clearInterval(this.fpsIntervalId);
        }

        this.pipeline?.cleanUp();
        // this.tflite?.destroy({});
        this.removeVideo();
        this.removeImage();
    }

    private beginFrame() {
        this.beginTime = Date.now();
    }

    private endFrame() {
        const time = Date.now();
        this.frameDurations[this.eventCount] = time - this.beginTime;
        this.frameCount++;
        if (time >= this.previousTime + 1000) {
            this.currentFps = (this.frameCount * 1000) / (time - this.previousTime);
            this.previousTime = time;
            this.frameCount = 0;
        }
        this.eventCount = 0;
    }

    private addFrameEvent = () => {
        const time = Date.now();
        this.frameDurations[this.eventCount] = time - this.beginTime;
        this.beginTime = time;
        this.eventCount++;
    };

    private render = async () => {
        const startTime = performance.now();

        this.beginFrame();

        await this.pipeline?.render().catch((err) => {
            logger.error("error rendering", err);
        });

        this.endFrame();

        this.renderTimeoutId = this.timerWorker.setTimeout(
            this.render,
            Math.max(0, this.targetTimerTimeoutMs - (performance.now() - startTime))
        );
    };

    updateConfig(config: VideoFxConfig) {
        this.config = config;

        if (this.started && this.tflite) {
            this.createPipeline(this.tflite);
        }
    }

    setInputStream(stream: MediaStream) {
        this.videoElement.srcObject = stream;
    }

    getOutputStream() {
        if (this.outputStream) {
            return this.outputStream;
        }

        this.outputStream = this.outputCanvas.captureStream(this.frameRate);
        return this.outputStream;
    }

    private createVideo(stream: MediaStream, width: number, height: number) {
        this.videoWidth = width;
        this.videoHeight = height;

        const videoElement = document.createElement("video");
        videoElement.setAttribute("autoplay", "");
        videoElement.setAttribute("muted", "");
        videoElement.setAttribute("playsinline", "");
        videoElement.style.display = "none";
        videoElement.srcObject = stream;

        videoElement.onplay = () => {
            logger.log("video started");
        };

        videoElement.onloadeddata = () => {
            this.handleVideoLoaded();
        };

        videoElement.onresize = () => {
            this.adjustCanvasSize(videoElement.videoWidth, videoElement.videoHeight);
        };

        document.body.appendChild(videoElement);

        return videoElement;
    }

    private createImage(src: string) {
        const imageElement = document.createElement("img");
        imageElement.src = src;
        imageElement.hidden = true;
        imageElement.crossOrigin = "anonymous";
        imageElement.style.position = "absolute";
        imageElement.style.width = "100%";
        imageElement.style.height = "100%";
        imageElement.style.objectFit = "cover";
        document.body.appendChild(imageElement);
        return imageElement;
    }

    private createOutputCanvas(width: number, height: number) {
        try {
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            return canvas;
        } catch (e) {
            logger.error("cannot create output canvas", e);
            throw e;
        }
    }

    private async adjustCanvasSize(width: number, height: number) {
        if (this.videoWidth === width && this.videoHeight === height) {
            return;
        }

        this.videoWidth = width;
        this.videoHeight = height;
        this.outputCanvas.width = width;
        this.outputCanvas.height = height;
    }

    private removeVideo() {
        this.videoElement.remove();
    }

    private removeImage() {
        this.backgroundElement?.remove();
    }

    private handleVideoLoaded() {
        this.start();
    }

    private getSegmentationConfig(): SegmentationConfig {
        return {
            model: "mlkit",
            backend: this.isSimdSupported ? "wasmSimd" : "wasm",
            inputResolution: "256x144",
            pipeline: "webgl2",
            targetFps: this.frameRate,
            deferInputResizing: false,
        };
    }

    private getBackgroundConfig(): BackgroundConfig {
        if (isVideoFxConfigImage(this.config)) {
            return {type: "image", url: this.config.image};
        }
        return {type: "blur", weight: this.config.blur};
    }

    private getPostProcessingConfig(): PostProcessingConfig {
        return {
            smoothSegmentationMask: true,
            jointBilateralFilter: {sigmaSpace: 2, sigmaColor: 0.1},
            coverage: [0.4, 0.6],
            lightWrapping: 0.3,
            blendMode: "screen",
        };
    }

    private getSizeFromStream(stream: MediaStream) {
        const settings = this.getSettingsFromStream(stream);
        return {width: settings.width ?? 0, height: settings.height ?? 0};
    }

    private getSettingsFromStream(stream: MediaStream) {
        const videoTrack = stream.getVideoTracks()[0];

        if (!videoTrack) {
            throw new Error("No video track found");
        }

        return videoTrack.getSettings();
    }
}
