import {useEffect} from "@workhorse/api/rendering";
import {useParams} from "@workhorse/api/routing";
import {useQuery} from "@workhorse/dataApi";

type RoomSessionIdFetcherProps = {
    setSessionId: (id: string) => void;
    setRoomIsInactive: (state: boolean) => void;
};

export default function RoomSessionIdFetcher(props: RoomSessionIdFetcherProps) {
    const {setSessionId, setRoomIsInactive} = props;
    const params = useParams<{roomSlug: string}>();
    const {data} = useQuery("SessionIdOfRoomDocument", {
        variables: {
            roomSlug: encodeURI(params.roomSlug).toLowerCase(),
        },
    });

    useEffect(() => {
        if (data?.sessionIdOfRoom?.sessionId) {
            setSessionId(data.sessionIdOfRoom.sessionId);
            setRoomIsInactive(!(data.sessionIdOfRoom.active ?? false));
        } else if (data?.sessionIdOfRoom?.sessionId === null && data?.sessionIdOfRoom?.active === false) {
            if (parent.top) {
                parent.top.location.pathname = "/404";
            }
        }
    }, [data]);

    return <></>;
}
