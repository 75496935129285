import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as MobileBlockerImage} from "../../assets/media/MobileBlockerImage.svg";
import {ReactComponent as ChromeLogo} from "../../assets/media/chrome-logo.svg";
import {ReactComponent as SafariLogo} from "../../assets/media/safari-logo.svg";
import classes from "./styles/UserMobileBlocker.module.scss";
import BrandingLogo from "@ui/cdk/BrandingLogo";

interface Props {
    showMobileRedirect?: boolean;
}

const UserNativeBrowserBlocker = (props: Props) => {
    return (
        <div className={cls("flex flex-col flex-items-center", classes.root)}>
            <BrandingLogo className={classes.logo} />
            <MobileBlockerImage className="mt-24" />
            <div className={classes.content}>
                <Typography variant="xl3" className={classes.contentHeader}>
                    You’re using a web browser we don’t support yet.
                </Typography>
                <Typography className={cls("flex flex-col", classes.contentBody)}>
                    <span>Please use one of these options to improve your experience.</span>
                    <div className="fullw flex flex-justify-around flex-items-center">
                        <div>
                            <ChromeLogo className={classes.browserLogo} />
                            <Typography color="primary" fontWeight="bolder">
                                Chrome
                            </Typography>
                            <Typography>Latest version</Typography>
                        </div>
                        <div>
                            <SafariLogo className={classes.browserLogo} />
                            <Typography color="primary" fontWeight="bolder">
                                Safari
                            </Typography>
                            <Typography>Latest version</Typography>
                        </div>
                    </div>
                </Typography>
            </div>
            {props.showMobileRedirect ? (
                <Button component="a" variant="primary" href={`intent:${window.location.href}#Intent;end`} className={classes.backBtn}>
                    Open in Browser
                </Button>
            ) : (
                <Button component={Link} variant="primary" href="https://app.sessions.us/" className={classes.backBtn}>
                    Back to home
                </Button>
            )}
        </div>
    );
};

export default UserNativeBrowserBlocker;
