import {t} from "i18next";

export const getCorrectPlural = (word: string, value: number) => {
    return word + (value > 1 || value === 0 ? "s" : "");
};

export const ariaLabelBasedOnClass = (className: string) => {
    const classNameLower = className.toLowerCase();

    if (classNameLower.includes("actions") || classNameLower.includes("options") || classNameLower.includes("more")) {
        return t("g.actions") ?? "Actions";
    } else if (classNameLower.includes("settings")) {
        return t("g.settings") ?? "Settings";
    } else if (classNameLower.includes("menu")) {
        return t("g.menu") ?? "Menu";
    } else if (classNameLower.includes("close")) {
        return t("g.close") ?? "Close";
    } else if (classNameLower.includes("add")) {
        return t("g.add") ?? "Add";
    } else if (classNameLower.includes("remove") || classNameLower.includes("delete")) {
        return t("g.remove") ?? "Remove";
    } else if (classNameLower.includes("edit")) {
        return t("g.edit") ?? "Edit";
    } else if (classNameLower.includes("cancel")) {
        return t("g.cancel") ?? "Cancel";
    } else if (classNameLower.includes("submit")) {
        return t("g.submit") ?? "Submit";
    } else if (classNameLower.includes("reset")) {
        return t("g.reset") ?? "Reset";
    } else if (classNameLower.includes("search")) {
        return t("g.search") ?? "Search";
    } else if (classNameLower.includes("download")) {
        return t("g.download") ?? "Download";
    } else if (classNameLower.includes("upload")) {
        return t("g.upload") ?? "Upload";
    } else if (classNameLower.includes("copy")) {
        return t("g.copy") ?? "Copy";
    }

    return undefined;
};
