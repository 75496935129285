import {createContextProvider} from "@workhorse/api/utils/context";
import {useGlobalResizeVars} from "./ResizeObserverProvider";

function useBreakpointStore() {
    const {breakpoint} = useGlobalResizeVars();
    return breakpoint;
}

export const [BreakpointProvider, useBreakpoint] = createContextProvider(
    {
        name: "Breakpoint",
    },
    useBreakpointStore
);
