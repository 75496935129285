import {Maybe, OrganizationPlan, PaddleSubscriptionDetailsDocument, UpdateOrganizationDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import toast from "@workhorse/api/toast";
import {loadScript} from "@workhorse/util";
import env from "@generated/environment";
import environment from "@generated/environment";
import clientEvents from "@api/events/client";
import {pushDataLayerEvent} from "@workhorse/api/tracking";

export const handleCancelSubscription = async () => {
    await loadPaddleScript();

    let successCallbackCalled = false;

    const isCsd = environment.authService.appRoot !== environment.authService.originalAppRoot;
    if (isCsd) {
        clientEvents.emit("subscriptions-unavailable-csd");
        return null;
    }

    return apollo.client
        .query({
            query: PaddleSubscriptionDetailsDocument,
            fetchPolicy: "network-only",
        })
        .then((subscriptionDetails) => {
            if (!subscriptionDetails.data?.paddleSubscriptionDetails?.cancel_url) {
                toast("Something went wrong, please try again later", {
                    type: "error",
                });
                return;
            }

            // @ts-ignore
            const Paddle = window.Paddle;

            Paddle.Checkout.open({
                override: subscriptionDetails.data?.paddleSubscriptionDetails?.cancel_url,
                successCallback: function (data) {
                    if (successCallbackCalled) {
                        return;
                    }
                    toast("Subscription successfully cancelled", {
                        type: "success",
                    });
                    successCallbackCalled = true;
                },
            });
        });
};

export enum SubscriptionPlanAction {
    upgrade = "Upgrade",
    downgrade = "Downgrade",
    getPlan = "Get plan",
    cancel = "Cancel",
    renew = "Renew",
}

type DeterminePlanActionArgs = {
    isTrial: boolean;
    processing: boolean;
    isCancelled: boolean;
    activePlan: OrganizationPlan | null;
    plan: OrganizationPlan;
    isReverseTrial: boolean;
    permanentPlan?: Maybe<OrganizationPlan>;
    hasReferralDiscount?: boolean;
};

type DetermineActinPlan = (args: DeterminePlanActionArgs) => SubscriptionPlanAction | null;

export const determinePlanAction: DetermineActinPlan = ({
    isCancelled,
    isTrial,
    processing,
    activePlan,
    plan,
    permanentPlan,
    isReverseTrial,
    hasReferralDiscount,
}) => {
    if (processing) {
        return null;
    }

    if (isReverseTrial) {
        if (hasReferralDiscount) {
            return SubscriptionPlanAction.getPlan;
        }

        return null;
    }

    if (
        permanentPlan &&
        [OrganizationPlan.ProLtd, OrganizationPlan.ProLtdSaaszilla].includes(permanentPlan) &&
        plan !== OrganizationPlan.Business
    ) {
        return null;
    }

    if (
        activePlan &&
        [OrganizationPlan.ProLtd, OrganizationPlan.ProLtdSaaszilla].includes(activePlan) &&
        [
            OrganizationPlan.Pro,
            OrganizationPlan.Starter,
            OrganizationPlan.ProLtd,
            OrganizationPlan.ProLtdSaaszilla,
            OrganizationPlan.Solo,
        ].includes(plan)
    ) {
        return null;
    }

    let action: SubscriptionPlanAction | null = SubscriptionPlanAction.getPlan;

    if (activePlan === OrganizationPlan.Free) {
        return action;
    }

    if (plan === activePlan) {
        action = SubscriptionPlanAction.cancel;
        if (isCancelled) {
            action = SubscriptionPlanAction.renew;
        }
    }

    if (
        activePlan === OrganizationPlan.Solo &&
        [OrganizationPlan.Pro, OrganizationPlan.Business, OrganizationPlan.Starter].includes(plan)
    ) {
        action = SubscriptionPlanAction.upgrade;
        if (isCancelled) {
            action = SubscriptionPlanAction.getPlan;
        }
        if (isTrial) {
            action = null;
        }
    }

    if (activePlan === OrganizationPlan.Starter && [OrganizationPlan.Solo].includes(plan)) {
        if (isTrial) {
            action = null;
        }
        if (!isTrial) {
            action = SubscriptionPlanAction.downgrade;
        }

        if (isCancelled) {
            action = SubscriptionPlanAction.getPlan;
        }
    }

    if (activePlan === OrganizationPlan.Starter && [OrganizationPlan.Pro, OrganizationPlan.Business].includes(plan)) {
        action = SubscriptionPlanAction.upgrade;
        if (isCancelled) {
            action = SubscriptionPlanAction.getPlan;
        }
        if (isTrial) {
            action = null;
        }
    }

    if (activePlan === OrganizationPlan.Pro && plan === OrganizationPlan.Business) {
        action = SubscriptionPlanAction.upgrade;
        if (isCancelled) {
            action = SubscriptionPlanAction.getPlan;
        }
        if (isTrial) {
            action = null;
        }
    }

    if (activePlan === OrganizationPlan.Pro && [OrganizationPlan.Starter, OrganizationPlan.Solo].includes(plan)) {
        if (isTrial) {
            action = null;
        }
        if (!isTrial) {
            action = SubscriptionPlanAction.downgrade;
        }

        if (isCancelled) {
            action = SubscriptionPlanAction.getPlan;
        }
    }

    if (
        activePlan === OrganizationPlan.Business &&
        [OrganizationPlan.Starter, OrganizationPlan.Pro, OrganizationPlan.Solo].includes(plan)
    ) {
        if (isTrial) {
            action = null;
        }
        if (!isTrial) {
            action = SubscriptionPlanAction.downgrade;
        }
        if (isCancelled) {
            action = SubscriptionPlanAction.getPlan;
        }
    }

    return action;
};

export const planActionLabel = (action: SubscriptionPlanAction, trialAvailable: boolean) => {
    switch (action) {
        case SubscriptionPlanAction.upgrade:
            return "Upgrade";
        case SubscriptionPlanAction.downgrade:
            return "Downgrade";
        case SubscriptionPlanAction.getPlan:
            return trialAvailable ? "Start free trial" : "Get plan";
        case SubscriptionPlanAction.cancel:
            return "Cancel";
        case SubscriptionPlanAction.renew:
            return "Renew";
        default:
            return action;
    }
};

export const planActionButtonVariant = (action: SubscriptionPlanAction, isRecommended: boolean) => {
    switch (action) {
        case SubscriptionPlanAction.upgrade:
            return "primary";
        case SubscriptionPlanAction.downgrade:
            return "secondary";
        case SubscriptionPlanAction.getPlan:
            if (isRecommended) {
                return "primary";
            }
            return "secondary";
        case SubscriptionPlanAction.cancel:
            return "tertiary";
        case SubscriptionPlanAction.renew:
            return "primary";
        default:
            return "primary";
    }
};

const doPaddleSetup = () => {
    // @ts-expect-error
    const Paddle = window.Paddle;

    if (env.paddleSandbox) {
        Paddle.Environment.set("sandbox");
    }
    Paddle.Setup({
        vendor: parseInt(env.paddleVendorId),
        eventCallback: (data) => {
            if (data.event === "Checkout.PaymentComplete") {
                // Check to ensure the payment has not been flagged for manual fraud review
                if (!data.eventData.flagged) {
                    const checkoutId = data.eventData.checkout.id;
                    Paddle.Order.details(checkoutId, (data) => {
                        if (!!data) {
                            // Order data, downloads, receipts etc... available within 'data' variable
                        } else {
                            // Order processing delay - order details cannot be retrieved at the moment
                        }
                    });
                } else {
                    // Payment has not been fully processed at the moment, so order details cannot be retrieved
                    console.log("Transaction pending review");
                }
            }

            if (
                data.event === "Checkout.Customer.Details" ||
                data.event === "Checkout.Coupon.Applied" ||
                data.event === "Checkout.CountryChanged" ||
                data.event === "Checkout.Coupon.Remove"
            ) {
                const priceDataTaxRate = data.eventData.checkout.prices.customer.items[0].tax_rate;
                const priceData = data.eventData.checkout.prices.customer.items[0].unit_price;

                clientEvents.emit("order-price-update", {
                    gross: priceData.gross,
                    gross_after_discount: priceData.gross_after_discount,
                    gross_discount: priceData.gross_discount,
                    net: priceData.net,
                    tax: priceData.tax,
                    tax_percentage: priceDataTaxRate,
                    net_discount: priceData.net_discount,
                    net_after_discount: priceData.net_after_discount,
                    tax_after_discount: priceData.tax_after_discount,
                });
            }

            if (data.event === "Checkout.Loaded") {
                clientEvents.emit("checkout-loaded", true);

                const coupon = data?.eventData?.checkout?.coupon?.coupon_code;

                clientEvents.emit("paddle-coupon-on-load", coupon);
            }

            if (data.event === "Checkout.Coupon.Remove") {
                clientEvents.emit("paddle-coupon-remove", true);
            }

            if (data.event === "Checkout.Coupon.Applied") {
                clientEvents.emit("paddle-coupon-change", data.eventData.checkout.coupon.coupon_code);
            }

            console.log("paddle event data for lr debugging", data.event);
        },
    });
    // setSetupDone(true);
};

export const loadPaddleScript = () => {
    return new Promise((resolve, reject) => {
        loadScript("https://cdn.paddle.com/paddle/paddle.js")
            .then(() => {
                const paddleSetupInterval = setInterval(() => {
                    // @ts-expect-error
                    if (window.Paddle) {
                        doPaddleSetup();
                        clearInterval(paddleSetupInterval);
                        resolve(true);
                    }
                }, 100);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getCancelMessage = (cancelReason?: string, customReason?: string) => {
    if (!cancelReason) {
        return "";
    }
    return customReason ? `Cancel reason: ${cancelReason}\nUser message: ${customReason}` : `Cancel reason: ${cancelReason}` ?? "";
};
