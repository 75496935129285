import {useStreamAudioDbAnalyzer} from "@workhorse/api/conference2/hooks/useLocalAudioDbAnalyzer";
import {cls} from "@ui/cdk/util/util";
import {useEffect, useState} from "react";
import classes from "./audio-preview.module.scss";

interface IndicatorProps {
    max: number;
    value: number;
    index: number;
    className?: string;
}

function Indicator(props: IndicatorProps) {
    const enabled = props.value >= (0.9 / props.max) * props.index;
    return <div className={cls(classes.indicator, props.className, enabled && classes.enabled)} />;
}

function createIndicators(value: number, maxItems: number, className?: string) {
    const items: JSX.Element[] = [];
    for (let i = 1; i <= maxItems; i++) {
        items.push(<Indicator key={i} className={className} value={value} index={i} max={maxItems} />);
    }
    return items;
}

interface InputProps {
    stream?: MediaStream;
    className?: string;
}

export function AudioInputPreview(props: InputProps) {
    const [db, setDb] = useState(0);
    useStreamAudioDbAnalyzer(props.stream, setDb);

    useEffect(() => {
        setDb(0);
    }, [props.stream]);

    return <div className={cls(classes.container, props.className)}>{createIndicators(db, 6)}</div>;
}
