import {useCallback, useMemo, useState} from "../rendering";
import {createContextProvider} from "../utils/context";
import {BookView} from "./definitions";

type BookingLoadingParts = "ScheduleList" | "Event" | "EventBookings";

export type BookingView = {
    view: BookView;
    nonce: string;

    preview: boolean;
};

const useBookingViewStore = (): [BookingView, (newState: Partial<BookingView>) => void] => {
    const [state, setState] = useState<BookingView>({view: "page", nonce: "", preview: false});

    const setView = useCallback((newState: Partial<BookingView>) => {
        setState((s) => ({...s, ...newState}));
    }, []);

    return [state, setView];
};

export const [BookingViewProvider, useBookingView] = createContextProvider(
    {
        name: "BookingView",
    },
    useBookingViewStore
);
