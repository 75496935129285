import browserInfo from "@workhorse/api/BrowserInfo";
import {useRef, useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {throttle} from "throttle-debounce";

interface Options {
    spacing: number;
    itemWidth: number;
    visibleItems: number;
    wrapperWidth: number;
    disableContinuousScroll: boolean;
}

export function getComputedIndex(offset: number, spacing: number, itemWidth, dir?: "left" | "right") {
    const itemSize = itemWidth + spacing * 2;
    const ratio = offset / itemSize;
    let ratioFixed: number;

    if (dir == null) {
        ratioFixed = Math.round(ratio);
    } else {
        ratioFixed = dir === "left" ? Math.floor(ratio) : Math.ceil(ratio);
    }

    return ratioFixed;
}

function getScrollPageSize(visibleItems: number) {
    if (visibleItems <= 5) {
        return visibleItems - 1;
    }

    if (visibleItems <= 8) {
        return 4;
    }

    if (visibleItems <= 12) {
        return 5;
    }

    if (visibleItems <= 15) {
        return 6;
    }

    return 7;
}

function getContinuosScrollVelocity(startedAt?: Date) {
    let timeDiff = 1000;

    if (startedAt) {
        timeDiff = new Date().getTime() - startedAt.getTime() + 300;
    }

    let velocity = timeDiff / 1000;
    velocity = Math.max(velocity, 1);
    velocity = Math.min(velocity, 2);

    return velocity;
}

export function useAudienceScroll(options: Options) {
    const [scrollNode, setScrollNode] = useState<HTMLDivElement | null>(null);

    const [reachedLeftEnd, setReachedLeftEnd] = useState(true);
    const [reachedRightEnd, setReachedRightEnd] = useState(false);

    const optionsRef = useRef(options);
    optionsRef.current = options;

    const alignRef = useRef<NodeJS.Timeout | undefined>();
    const scrollRef = useRef<{id?: NodeJS.Timeout; locked: boolean}>({
        locked: false,
    });
    const continousScrollRef = useRef<{id?: NodeJS.Timeout; enabled: boolean; started: boolean; startedAt?: Date}>({
        enabled: false,
        started: false,
    });

    const lockWhileScrolling = useCallback((node: HTMLElement) => {
        const handleScroll = () => {
            if (scrollRef.current.id) {
                clearTimeout(scrollRef.current.id);
            }

            scrollRef.current.locked = true;
            node.style.pointerEvents = "none";

            scrollRef.current.id = setTimeout(() => {
                scrollRef.current.locked = false;
                node.style.pointerEvents = "";
                node.removeEventListener("scroll", handleScroll);
            }, 30);
        };
        node.addEventListener("scroll", handleScroll);
    }, []);

    const scrollOnePage = useCallback((node: HTMLElement, dir: "left" | "right") => {
        const {spacing, visibleItems, itemWidth} = optionsRef.current;

        if (continousScrollRef.current.started) {
            return;
        }

        const itemSize = itemWidth + spacing * 2;
        const index = getComputedIndex(node.scrollLeft, spacing, itemWidth);
        const sign = dir === "left" ? 1 : -1;
        const skip = sign * getScrollPageSize(visibleItems);

        node.scrollTo({
            left: itemSize * (index + skip),
            behavior: "smooth",
        });
    }, []);

    const scrollOneItem = useCallback(
        (dir: "left" | "right") => {
            if (!scrollNode) {
                return;
            }

            if (continousScrollRef.current.started) {
                return;
            }

            const {spacing, itemWidth} = optionsRef.current;

            const itemSize = itemWidth + spacing * 2;
            const index = getComputedIndex(scrollNode.scrollLeft, spacing, itemWidth);
            const sign = dir === "left" ? -1 : 1;

            scrollNode.scrollTo({
                left: itemSize * (index + sign),
                behavior: "smooth",
            });
        },
        [scrollNode]
    );

    const alignItems = useCallback((node: HTMLElement, dir?: "left" | "right") => {
        const {spacing, itemWidth} = optionsRef.current;

        const itemSize = itemWidth + spacing * 2;
        const index = getComputedIndex(node.scrollLeft, spacing, itemWidth, dir);

        node.scrollTo({
            left: index * itemSize,
            behavior: "smooth",
        });
    }, []);

    const alignItemsDelayed = useCallback(
        (delay: number, node: HTMLElement, dir?: "left" | "right") => {
            if (alignRef.current) {
                clearTimeout(alignRef.current);
            }
            alignRef.current = setTimeout(() => alignItems(node, dir), delay);
        },
        [alignItems]
    );

    const startContinousScroll = useCallback(
        (dir: "left" | "right") => {
            const {disableContinuousScroll} = optionsRef.current;

            if (disableContinuousScroll) {
                return;
            }

            continousScrollRef.current.enabled = true;

            if (!continousScrollRef.current.startedAt) {
                continousScrollRef.current.startedAt = new Date();
            }

            const scroll = () => {
                if (!continousScrollRef.current.enabled || !scrollNode) {
                    return;
                }

                continousScrollRef.current.started = true;

                const velocity = getContinuosScrollVelocity(continousScrollRef.current.startedAt);

                const sign = dir === "left" ? -1 : 1;
                const modifier = browserInfo.isSafari() ? 10 : 5;

                scrollNode.scrollBy({
                    left: sign * modifier * velocity,
                    behavior: "auto",
                });

                if (browserInfo.isSafari()) {
                    return setTimeout(() => {
                        scroll();
                    }, 25);
                }

                requestAnimationFrame(scroll);
            };

            continousScrollRef.current.id = setTimeout(() => {
                scroll();
            }, 300);
        },
        [scrollNode]
    );

    const stopContinousScroll = useCallback(
        (dir?: "left" | "right") => {
            continousScrollRef.current.enabled = false;
            continousScrollRef.current.startedAt = undefined;

            if (continousScrollRef.current.id) {
                clearTimeout(continousScrollRef.current.id);
            }

            if (!continousScrollRef.current.started) {
                return;
            }

            setTimeout(() => {
                continousScrollRef.current.started = false;
            }, 10);

            if (scrollNode) {
                alignItems(scrollNode, dir);
            }
        },
        [scrollNode, alignItems]
    );

    const scrollBy = useCallback((node: HTMLDivElement, value: number) => {
        node.scrollBy({
            left: value,
            behavior: "auto",
        });
    }, []);

    useEffect(() => {
        if (!scrollNode) {
            return;
        }

        let keyLockId: NodeJS.Timeout | undefined;

        const handleWheel = (e: WheelEvent) => {
            if (e.deltaX === 0) {
                e.preventDefault();
                scrollBy(scrollNode, e.deltaY);
            }

            alignItemsDelayed(800, scrollNode);
        };

        const preventMiddleClickScroll = (e: MouseEvent) => {
            if (e.button === 1) {
                e.preventDefault();
            }
        };

        const checkScrollEnd = throttle(
            100,
            () => {
                requestAnimationFrame(() => {
                    const reachedLeftEnd = scrollNode.scrollLeft < 50;
                    const reachedRightEnd = scrollNode.offsetWidth + scrollNode.scrollLeft > scrollNode.scrollWidth - 50;

                    setReachedLeftEnd(reachedLeftEnd);
                    setReachedRightEnd(reachedRightEnd);
                });
            },
            {
                // TODO: vasi if stuff doesn't work in audience scroll, check this first
                noTrailing: false,
            }
        );

        const lockKeyScroll = (e: KeyboardEvent) => {
            if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                scrollNode.style.overflowX = "hidden";

                // Just in case somthing prevents keyUp event
                if (keyLockId) {
                    clearTimeout(keyLockId);
                }
                keyLockId = setTimeout(() => {
                    scrollNode.style.overflowX = "";
                }, 300);
            }
        };

        const unlockKeyScroll = (e: KeyboardEvent) => {
            if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                scrollNode.style.overflowX = "";
            }
        };

        scrollNode.addEventListener("wheel", handleWheel);
        scrollNode.addEventListener("scroll", checkScrollEnd);
        scrollNode.addEventListener("mousedown", preventMiddleClickScroll);
        window.addEventListener("keydown", lockKeyScroll);
        window.addEventListener("keyup", unlockKeyScroll, {capture: true});

        return () => {
            scrollNode.removeEventListener("wheel", handleWheel);
            scrollNode.removeEventListener("scroll", checkScrollEnd);
            scrollNode.removeEventListener("mousedown", preventMiddleClickScroll);
            window.removeEventListener("keydown", lockKeyScroll);
            window.removeEventListener("keyup", unlockKeyScroll);
        };
    }, [scrollNode, scrollBy, alignItemsDelayed]);

    useEffect(() => {
        if (scrollNode) {
            alignItems(scrollNode);
        }
    }, [scrollNode, options.spacing, options.itemWidth, alignItems]);

    useEffect(() => {
        if (options.disableContinuousScroll) {
            // stopContinousScroll();
        }
    }, [options.disableContinuousScroll, stopContinousScroll]);

    return {
        scrollNode,
        reachedLeftEnd,
        reachedRightEnd,
        alignItems,
        setScrollNode,
        scrollOneItem,
        scrollOnePage,
        startContinousScroll,
        stopContinousScroll,
        lockWhileScrolling,
    };
}
