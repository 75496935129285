import {DrawerState, HostType} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import {Fragment, useContext, useEffect, useMemo, useReducer, useState} from "@workhorse/api/rendering";
import {WithHostType, WithMobileState} from "@workhorse/declarations";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {useDrawerLeftWidth} from "@workhorse/providers/useDrawerLeftWidth";
import Navigation from "../navigation";
import {withErrorBoundary} from "../WithErrorBoundary";
import classes from "./styles/Drawers.module.scss";
import {DraggableDialog} from "@workhorse/pages/player/components/DraggableDialog";

const navigationHostTypes: HostType[] = [
    HostType.Home,
    HostType.Base,
    HostType.Templates,
    HostType.Resources,
    HostType.Booking,
    HostType.NewBooking,
    HostType.Contacts,
    HostType.Memory,
    HostType.User,
    HostType.Events,
    HostType.Event,
    HostType.Files,
    HostType.Rooms,
];

export function hasNavigation(hostType: HostType) {
    return navigationHostTypes.includes(hostType);
}

type DrawerLeftProps = {
    isFullScreen: boolean;
    isGuest: boolean;
    isAuthenticated: boolean;
} & WithHostType &
    WithMobileState;

function DrawerLeft(props: DrawerLeftProps) {
    const {isFullScreen, hostType, isGuest, isAuthenticated} = props;
    const {isMobile, isMobileOrTablet} = useMobile();

    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set, unset} = useContext(InjectorContext);
    const {isPortrait} = useDeviceOrientation();
    const [{isClosed, isSemiOpen, isFullyOpen, isDetached, state}, toggle] = useDrawerLeftToggler();
    const drawerLeftWidth = useDrawerLeftWidth();
    const widthPx = drawerLeftWidth[state === "isClosed" ? "isFullyOpen" : state] || 0;

    const ids = (Object.keys(existing) as Array<keyof typeof existing>).filter((x) => x !== "classNames");

    const withNavigation = !isMobile && hasNavigation(hostType) && !isGuest && isAuthenticated;
    const [expandableEffect, setExpandableEffect] = useState(withNavigation && state === "isSemiOpen");

    const className = cls((ids.length > 0 || withNavigation) && "with-content", cls.apply(null, existing.classNames), state);

    const isPlayer = hostType === HostType.Player;

    const style = useMemo(() => {
        const out = {
            marginLeft: isClosed
                ? isMobile
                    ? `-${drawerLeftWidth.isFullyOpenMobile}`
                    : -widthPx
                : isSemiOpen
                ? drawerLeftWidth.isSemiOpen - drawerLeftWidth.isFullyOpen
                : isPlayer && !isMobile
                ? 12
                : 0,
            minWidth: isMobile ? drawerLeftWidth.isFullyOpenMobile : drawerLeftWidth.isFullyOpen - (isPlayer ? 12 : 0),
            maxWidth: isMobile ? drawerLeftWidth.isFullyOpenMobile : drawerLeftWidth.isFullyOpen - (isPlayer ? 12 : 0),
            bottom: isPlayer ? 68 : undefined,
        } as React.CSSProperties;

        if (isFullScreen) {
            out.top = 12;
        }

        return out;
    }, [isFullScreen, state, isMobile, hostType]);

    useEffect(() => {
        set("inDrawerLeft", inject);
    }, []);

    useEffect(() => {
        if (state === "isFullyOpen" && isMobile && !isPortrait) {
            toggle(DrawerState.Closed, "DrawerLeft:92");
        }
    }, [state, isMobile, isPortrait]);

    useEffect(() => {
        if (withNavigation && state === "isSemiOpen") {
            const timeout = setTimeout(() => {
                setExpandableEffect(true);
            }, 200);

            return () => clearTimeout(timeout);
        } else {
            setExpandableEffect(false);
        }
    }, [state, withNavigation]);

    const hideDrawer = isFullScreen && isMobile;
    const fullHeight = withNavigation;

    return !isMobile && isDetached && isFullyOpen ? (
        <DraggableDialog
            id="leftDrawer"
            width={330}
            height={640}
            defaultWidth={330}
            defaultPosition="top-left"
            defaultHeight={640}
            isSpeakerWidget={false}
            enableResizing={true}
        >
            <div className={cls("drawer-left", "isDetached", className)}>
                {ids.map((k) => {
                    return <Fragment key={k}>{existing[k]}</Fragment>;
                })}
            </div>
        </DraggableDialog>
    ) : (
        <div
            id={"drawer-left"}
            className={cls(
                "drawer-left",
                className,
                hideDrawer && "hidden-but-displayed",
                fullHeight && classes.fullHeight,
                withNavigation && "flex-justify-start",
                !isMobileOrTablet && withNavigation && state === "isSemiOpen" && "expandable",
                !isMobileOrTablet && expandableEffect && "expandable-effect",
                !isMobile && isPlayer && classes.playerLeftToggler,
                state
            )}
            style={style}
        >
            {!withNavigation ? (
                ids.map((k) => {
                    return <Fragment key={k}>{existing[k]}</Fragment>;
                })
            ) : (
                <Navigation key="drawer-navigation" />
            )}
        </div>
    );
}

export default withErrorBoundary(DrawerLeft, true);
