import TableCell from "@ui/cdk/Table/TableCell";
import TableHead from "@ui/cdk/Table/TableHead";
import TableRow from "@ui/cdk/Table/TableRow";
import TableSearchInput from "@ui/cdk/Table/TableSearchInput/TableSearchInput";
import Typography from "@ui/cdk/Typography";
import {useMemo} from "@workhorse/api/rendering";
import {throttle} from "throttle-debounce";
import {RoomsHeadCellType, RoomsSort} from "./utils";
import RoomsTableHeaderSortButton from "./RoomsTableHeaderSortButton";
import classes from "./style/RoomsTableHead.module.scss";

type RoomsTableHeadProps = {
    tableColumns: RoomsHeadCellType;
    onSearch: (value: string) => void;
    disabledSearch: boolean;
    searchTerm: string;
    sort: RoomsSort;
    onSort: (roomsSort: RoomsSort) => void;
};

function RoomsTableHead(props: RoomsTableHeadProps) {
    const {tableColumns, onSearch, disabledSearch, searchTerm, sort, onSort} = props;

    const handleSearchInputChange = useMemo(() => throttle(300, onSearch), [onSearch]);
    const canSort = !searchTerm;

    return (
        <TableHead className={classes.root}>
            <TableRow>
                {tableColumns.map((column) => (
                    <TableCell
                        key={column.id}
                        size={column.size ?? (column.type === "title" || column.type === "date" ? "large" : "unset")}
                    >
                        <div className="flex flex-items-center">
                            {column.isSearchInput ? (
                                <div className="flex flex-items-center">
                                    <TableSearchInput
                                        dataId="rooms-input-search"
                                        value={searchTerm}
                                        placeholder="Search rooms"
                                        onChange={(e) => handleSearchInputChange(e.currentTarget.value)}
                                        disabled={disabledSearch}
                                        onClear={() => onSearch("")}
                                    />
                                </div>
                            ) : (
                                <Typography>{column.label}</Typography>
                            )}
                            {column.sortable && (
                                <RoomsTableHeaderSortButton
                                    columnId={column.id}
                                    activeColumnId={sort.field}
                                    direction={sort.direction}
                                    onSort={onSort}
                                    canSort={canSort}
                                />
                            )}
                        </div>
                    </TableCell>
                ))}
                {/* empty cell for [more options] button */}
                <TableCell size="medium" />
            </TableRow>
        </TableHead>
    );
}

export default RoomsTableHead;
