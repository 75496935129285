import browserInfo from "@workhorse/api/BrowserInfo";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useEffect, useState} from "@workhorse/api/rendering";

export function usePermissionStatus(name: "camera" | "microphone", warn = true) {
    const isMounted = useIsMounted();

    const [value, setValue] = useState<PermissionState | "unset">("unset");

    if (warn) {
        console.warn(`WARNING:
'usePermissionStatus' works with Chromium ONLY, please use it only if you know what you are doing.
If you want to find out if you have camera or microphone access, then there are other hooks.
        `);
    }

    useEffect(() => {
        if (browserInfo.isAppleShit(16) || !browserInfo.isChromium()) {
            return;
        }
        let status: PermissionStatus | undefined;

        const checkPermission = async () => {
            try {
                setValue("unset");

                status = await navigator.permissions.query({name: name as PermissionName});

                if (!isMounted()) {
                    return;
                }

                setValue(status.state);

                status.onchange = function () {
                    if (isMounted()) {
                        setValue(this.state);
                    }
                };
            } catch (e) {
                // do nothing
            }
        };

        checkPermission();

        return () => {
            if (status) {
                status.onchange = null;
            }
        };
    }, [name, isMounted]);

    return value;
}
