import {OneSessionFlagsNoDiffNoIdFragment, SessionRecordingType} from "@generated/data";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import LivestreamingOutputUrlsSelect from "@workhorse/api/session-settings/sections/LivestreamingSection/components/LivestreamingOutputUrlsSelect";

import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import classes from "./styles/GeneralSettings.module.scss";
import {SessionLivestreamOutputUrlListJsonType} from "@sessions/common/recording";
import {Trans, useTranslation} from "react-i18next";
import Link from "@ui/cdk/Link";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";

type LivestreamingSettingsProps = {
    isMobile: boolean;
    autoRecording: boolean;
    fullSizeVideosInRecording: boolean;
    recordingConferenceView?: string | null;
    recordingPresentationView?: string | null;
    handleOnChange: <TKey extends keyof OneSessionFlagsNoDiffNoIdFragment>(
        key: string,
        value: OneSessionFlagsNoDiffNoIdFragment[TKey]
    ) => void;
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
    disabled?: boolean;
    title?: JSX.Element;
    parentClassName?: string;
    isEventSection?: boolean;
    isBookingSection?: boolean;
    isRoomSection?: boolean;
    livestreamOutputUrls: SessionLivestreamOutputUrlListJsonType;
    recordingType: SessionRecordingType;
    onGoToRecordingSection?: () => void;
};

const LivestreamingSettings = (props: LivestreamingSettingsProps) => {
    const {
        handleOnChange,
        isMobile,
        sessionTemplateNotificationDismissed,
        setSessionTemplateNotificationDismissed,
        title,
        parentClassName,
        isRoomSection,
        isEventSection,
        isBookingSection,
        onGoToRecordingSection,
    } = props;
    const {t} = useTranslation();

    return (
        <div className={parentClassName ?? cls("fullh fullw flex flex-col", isMobile ? "p-16" : "p-24", classes.root)}>
            {title ?? (
                <div className="mb-6">
                    <Typography variant="lg" fontWeight="bolder" color="secondary">
                        {t("participant.livestreaming.livestreaming_settings") || "Live streaming settings"}
                    </Typography>
                </div>
            )}
            <Typography
                variant="base"
                fontWeight="bold"
                color="secondary"
                component="div"
                className="flex flex-align-center flex-wrap mb-6"
            >
                {t("g.need_help")}{" "}
                <Link href={getHelpCenterArticleLink("knowledge-base/live-streaming")}>
                    {t("player.settings.general.livestreaming.learn_how_to_setup")}
                </Link>
            </Typography>

            <Typography color="secondary" className="flex flex-align-center flex-wrap gap-4 mt-16">
                <Trans i18nKey="participant.livestreaming.settings_shared_recording">
                    The layout for the live stream will be the same as for the recording.
                    <Link className="cursor-pointer px-0  py-0 leading-unset" onClick={onGoToRecordingSection}>
                        Review it here.
                    </Link>
                </Trans>
            </Typography>

            <LivestreamingOutputUrlsSelect
                urls={props.livestreamOutputUrls?.urls || []}
                onChange={handleOnChange.bind(null, "livestreamOutputUrls")}
            />

            <div className={classes.divider} />
            <SessionTemplateSettingsNotification
                type={isRoomSection ? "room" : isEventSection ? "event" : isBookingSection ? "booking" : "planned"}
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={cls((isBookingSection || isEventSection) && classes.noNegativeMargin)}
            />
        </div>
    );
};

export default LivestreamingSettings;
