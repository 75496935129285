import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import classes from "../../../../styles/GenericSection.module.scss";
import {ReactComponent as TimerTogglerIcon} from "../../../../../../assets/media/macro-toggler-timer.svg";

import {useTranslation} from "react-i18next";

type MacroPollsTogglerProps = {
    enabled: boolean;
    onChange: (macroArtifactId: string) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const WidgetTimerToggler = (props: MacroPollsTogglerProps) => {
    const {enabled, onChange, showIcon, disabled, className} = props;

    const {t} = useTranslation();

    const handleOnChange = () => onChange("timer");

    return (
        <div className={cls(classes.togglerWrapper, className)}>
            {showIcon ? (
                <div className={classes.iconWrapper}>
                    <TimerTogglerIcon />
                </div>
            ) : null}
            <TogglerContainer
                title={
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("macro.timer.title")}
                    </Typography>
                }
                secondTitle={
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.widgets.timer.description")}
                    </Typography>
                }
                checked={enabled}
                name="timerWidget"
                onChange={handleOnChange}
                togglePlacement="secondTitle"
                label=" "
                className={cls(classes.toggler)}
                disabled={disabled}
            />
        </div>
    );
};

export default WidgetTimerToggler;
