import {useEffect, useRef} from "@workhorse/api/rendering";
import {ActionCategTreeItem} from "./actionCategTree";
import {usePaletteActionArg} from "./PaletteActionProvider";

type PaletteActionSetterProps = {
    item?: ActionCategTreeItem;
};

export default function PaletteSetAction(props: PaletteActionSetterProps) {
    const {actionArg, setActionArg} = usePaletteActionArg();

    const t = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const instantSet = t.current === null;
        if (t.current != null) {
            clearTimeout(t.current);
            t.current = null;
        }
        if (props.item?.actionArg) {
            const {action, artifactTag, isTool} = props.item?.actionArg;
            if (
                actionArg === undefined ||
                actionArg.action !== action ||
                actionArg.artifactTag !== artifactTag ||
                actionArg.isTool !== isTool
            ) {
                t.current = setTimeout(
                    () => {
                        setActionArg(props.item?.actionArg);
                    },
                    instantSet ? 0 : 500
                );
            }
        } else {
            if (actionArg) {
                setActionArg(undefined);
            }
        }
    }, [props.item]);

    useEffect(() => {
        return () => {
            setActionArg(undefined);
        };
    }, []);

    return <></>;
}
