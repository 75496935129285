import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import Button from "@ui/cdk/Button";
import {useState} from "@workhorse/api/rendering";
import classes from "./style/GiveAccess.module.scss";
import {AccessListItemInfoFragment} from "@generated/data";
import GiveAccessDialog from "./GiveAccessDialog";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type GiveAccessProps = {
    resourceId?: string;
    agendatemplateId?: string;
    giveAccess: (email: string) => Promise<boolean>;
    removeAccess: (id: string) => Promise<void>;
    accessList: AccessListItemInfoFragment[];
    refetchAccessList: () => Promise<void>;
};

const GiveAccess = (props: GiveAccessProps) => {
    const {resourceId, agendatemplateId, accessList, giveAccess, removeAccess, refetchAccessList} = props;
    const [open, setOpen] = useState(false);
    const {mixpanelTrack} = useMixpanelTracker();
    const toggleDialog = () => {
        setOpen(!open);
        mixpanelTrack("frontend-share-agenda", "agendas");
    };

    return (
        <>
            <Button variant="plain" onClick={toggleDialog} className={classes.shareBtn} data-id="agendas-button-share-agenda">
                <ShareRoundedIcon />
                Share
            </Button>

            {open && (
                <GiveAccessDialog
                    open={open}
                    onClose={toggleDialog}
                    giveAccess={giveAccess}
                    removeAccess={removeAccess}
                    accessList={accessList}
                    resourceId={resourceId}
                    agendatemplateId={agendatemplateId}
                    refetchAccessList={refetchAccessList}
                />
            )}
        </>
    );
};

export default GiveAccess;
