import {AgendaItemType, ConfigurationStep, DrawerState} from "@generated/data";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import {cls} from "@ui/cdk/util/util";
import browserInfo from "@workhorse/api/BrowserInfo";
import designer from "@workhorse/api/designer";
import {useState, useEffect, useRef} from "@workhorse/api/rendering";
import {DrawerHookState, WithMobileState} from "@workhorse/declarations";
import {AgendaItem, SessionData} from "@workhorse/declarations/dataTypes";
import MicroControllerInjector, {configurableArtifacts} from "@workhorse/pages/player/microController/MicroControllerInjector";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import React from "react";
import classes from "../style/AgendaActionsMenu.module.scss";
import AgendaSettings from "./AgendaSettings";
import DetachAgenda from "./DetachAgenda";
import EditAgenda from "./EditAgenda";
import ToggleAgenda from "./ToggleAgenda";
import clientEvents from "@api/events/client";

type AgendaActionsProps = {
    agendaActionsAnchorEl: null | HTMLElement;
    handleClose: (any, string) => void;
    onMouseLeave: (event: React.MouseEvent<HTMLElement>) => void;
    toggleLeftDrawer: (newState?: DrawerState | undefined) => void;
    toggleDetach: (newState?: boolean) => void;
    drawerLeftState: DrawerHookState;
    inLobby: boolean;
    session: SessionData;
    isAssistant: boolean;
    agendaItems: AgendaItem[];
    onDelete: ((e?: any) => void) | ((e?: any) => Promise<void>);
    isAdmin: boolean;
    anchorOrigin?: {
        vertical: "top" | "bottom" | "center" | number;
        horizontal: "left" | "right" | "center" | number;
    };
    transformOrigin?: {
        vertical: "top" | "bottom" | "center" | number;
        horizontal: "left" | "right" | "center" | number;
    };
} & WithMobileState;

const AgendaActionsMenu = (props: AgendaActionsProps) => {
    const {
        agendaActionsAnchorEl,
        handleClose,
        drawerLeftState,
        toggleLeftDrawer,
        toggleDetach,
        session,
        inLobby,
        isAssistant,
        mobileState,
        agendaItems,
        onDelete,
        isAdmin,
    } = props;

    const {isMobile} = mobileState;

    const [agendaSettingsAnchorEl, setAgendaSettingsAnchorEl] = useState<null | HTMLElement>(null);
    const commitInProgress = useDesignerSessionCommitState();

    const rootRef = useRef<HTMLDivElement>(null);

    const hasAgenda = agendaItems.some((a) => a.type === AgendaItemType.Planned);

    const hasConfigurableArtifactsInAgenda = agendaItems
        ?.filter((a) => {
            if (a.title === designer.constants.DUMMY_AGENDA_ITEM_TITLE && a.order !== props.session.order) {
                return false;
            }
            return true;
        })
        .find((a) => a.artifact && a.artifact.artifactId && configurableArtifacts.includes(a.artifact.artifactId));

    const onToggle = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (drawerLeftState?.isClosed) {
            toggleLeftDrawer(DrawerState.FullyOpen);
        } else {
            toggleLeftDrawer(DrawerState.Closed);
        }
        handleClose(e, "clickAway");
    };

    const toggleEditStep = (e) => {
        e.stopPropagation();
        if (drawerLeftState?.isClosed) {
            toggleLeftDrawer(DrawerState.FullyOpen);
        }

        if (drawerLeftState.isDetached) {
            toggleDetach(false);
        }

        if (designer.state.getSnapshot().configurationStep !== ConfigurationStep.Edit) {
            designer.state.setDesignerEditState(true);
            designer.state.update({
                configurationStep: ConfigurationStep.Edit,
            });
        } else {
            designer.state.setDesignerEditState(false);
            designer.state.update({
                configurationStep: ConfigurationStep.Preview,
            });
            designer.undoChanges({from: ["agendaItems"]});
        }

        handleClose(e, "clickAway");
    };

    const onAgendaSettingsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAgendaSettingsAnchorEl(event.currentTarget);
    };

    const onAgendaSettingsClose = () => {
        setAgendaSettingsAnchorEl(null);
        handleClose(null, "clickAway");
    };

    useEffect(() => {
        const agendaShortcuts = (e: KeyboardEvent) => {
            if (e.altKey && e.shiftKey) {
                onToggle(e as any);
                return;
            }

            const key = browserInfo.isMacOS() ? (e.code ? e.code.toLowerCase() : undefined) : e.key ? e.key.toLowerCase() : undefined;
            if (!e.altKey || (key && !(browserInfo.isMacOS() ? ["keyz", "keyb"] : ["z", "b"]).includes(key))) {
                return;
            }
            switch (key) {
                case "z":
                case "keyz": {
                    toggleEditStep(e);
                    break;
                }
                case "b":
                case "keyb": {
                    onDelete(e);
                    break;
                }
                default: {
                    break;
                }
            }
        };
        document.body.addEventListener("keydown", agendaShortcuts);

        return () => {
            document.body.removeEventListener("keydown", agendaShortcuts);
        };
    }, [onToggle, toggleEditStep, onDelete]);

    return (
        <Menu
            ref={rootRef}
            id="agenda-actions-menu"
            aria-labelledby="agenda-actions-menu"
            anchorEl={agendaActionsAnchorEl}
            open={Boolean(agendaActionsAnchorEl)}
            getContentAnchorEl={null}
            onClose={(event, reason) => handleClose(event, reason)}
            anchorOrigin={
                props.anchorOrigin ?? {
                    vertical: "top",
                    horizontal: "left",
                }
            }
            hideBackdrop={!isMobile}
            transformOrigin={
                props.transformOrigin ?? {
                    vertical: "bottom",
                    horizontal: "left",
                }
            }
            onClick={(e) => e.stopPropagation()}
            onMouseLeave={props.onMouseLeave}
            classes={{
                paper: cls(classes.tooltip, classes.paper),
                list: cls(classes.menuList, classes.list),
            }}
            className={classes.menu}
            BackdropProps={{
                className: classes.backdrop,
            }}
            variant="menu"
        >
            {/* <MenuItem
                button
                onClick={onToggle}
                classes={{
                    root: "blue",
                }}
            >
                <ToggleAgenda key="local-agenda-toggle" drawerLeftState={drawerLeftState} />
            </MenuItem>
            <li className="separator" key="local-agenda-separator-1" /> */}

            <MenuItem button onClick={onAgendaSettingsOpen}>
                <AgendaSettings
                    onAgendaSettingsClose={onAgendaSettingsClose}
                    inLobby={inLobby}
                    isAdmin={isAdmin}
                    agendaSettingsAnchorEl={agendaSettingsAnchorEl}
                    agendaItems={agendaItems}
                    session={session}
                />
            </MenuItem>
            <MenuItem data-id="edit-agenda" button onClick={toggleEditStep} disabled={commitInProgress}>
                <EditAgenda key="local-agenda-edit" />
            </MenuItem>

            <li className="separator" key="local-agenda-separator-2" />
            <MenuItem
                data-id="detach-agenda"
                disabled={commitInProgress}
                button
                onClick={onDelete}
                variant="destructive"
                noDivider
                classes={{
                    root: "red",
                }}
            >
                <DetachAgenda key="local-agenda-detach" />
            </MenuItem>
        </Menu>
    );
};

export default React.memo(AgendaActionsMenu);
