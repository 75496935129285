import {makeStyles} from "@material-ui/core";
import React, {
    createElement,
    forwardRef,
    Fragment,
    JSXElementConstructor,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "@workhorse/api/rendering";
import {isMacOS} from "@workhorse/util";
import {animated, useSpring} from "react-spring";
import {cls} from "@ui/cdk/util/util";
import browserInfo from "@workhorse/api/BrowserInfo";

export type OmitThisProps<T> = Omit<T, "scrollTop" | "scrollLeft" | "component" | "children" | "className" | "ref">;
export type IntoViewLocation = "start" | "end" | "center";
export type ScrollShadowShowOn = "start" | "end" | "both";
export type ShadowOnScroll = {
    showOn: ScrollShadowShowOn;
    offsetTop?: number;
    offsetBottom?: number;
};

export type ShadowType = "white" | "grey" | "none";

type ScrollContainerComponents =
    | "div"
    | React.ComponentType<{
          id?: string;
          style?: React.CSSProperties;
          onMouseMove?: (event: React.MouseEvent<unknown, MouseEvent>) => void;
          onMouseEnter?: (event: React.MouseEvent<unknown, MouseEvent>) => void;
          onMouseLeave?: (event: React.MouseEvent<unknown, MouseEvent>) => void;
          onTouchMove?: (event: React.TouchEvent<unknown>) => void;
          onTouchStart?: (event: React.TouchEvent<unknown>) => void;
          onTouchEnd?: (event: React.TouchEvent<unknown>) => void;
      }>;

export type ScrollContainerProps<T extends ScrollContainerComponents = "div"> = {
    children?: React.ReactNode | React.ReactNode[];
    component?: T;
    className?: string;
    wrapperClassName?: string;
    axis?: "x" | "y";
    hideScrollbar?: boolean;
    scrollBarWide?: boolean;
    shadowOnScroll?: ShadowOnScroll;
    shadowBorderRadius?: boolean;
    shadowGradient?: ShadowType;
    shadowIsSmall?: boolean;
    scrollBarOffsetTop?: number;
    scrollBarOffsetBottom?: number;
    stickyHeader?: ReactElement<any, string | JSXElementConstructor<any>>;
    isMobile?: boolean;
    connector: ScrollSinkConnector;
    filterBy?: string;
} & OmitThisProps<React.ComponentProps<T>>;

function P() {
    return <div role="list"></div>;
}

export type WithStickyProps<T, TComponent = HTMLDivElement> = React.HTMLAttributes<TComponent> &
    T & {
        isSticky?: boolean;
    };

export type InternalRef = {
    set: (node: HTMLElement) => void;
    node: HTMLElement | null;
};

export type ScrollIntoViewArg = {
    node?: HTMLElement;
    index?: number;
    location?: IntoViewLocation;
    offset?: number;
    preventIfVisible?: boolean;
};

export type ScrollSinkConnector = React.MutableRefObject<ScrollContainerRef>;

export type ScrollContainerRef = {
    scrollTo?: React.Dispatch<React.SetStateAction<number | undefined>>;
    setScrollState?: React.Dispatch<
        React.SetStateAction<
            Partial<{
                onTop: boolean;
                onBottom: boolean;
                onLeft: boolean;
                onRight: boolean;
                scrollable: boolean;
            }>
        >
    >;
    scrollChildIntoView?: (arg: ScrollIntoViewArg) => void;
    onScrollStarted?: () => void;
    onScrollLeftEnded?: (scrollLeft: number) => void;
    onScrollTopEnded?: (scrollTop: number) => void;
    setVisibleChildren?: (indexes: number[]) => void;
    getVisibleChildren?: () => number[];
    instantScrollTo?: (scroll: number) => void;
};

const useStyles = makeStyles((theme) => ({
    rootVertical: {
        overflow: "hidden",
        overflowY: "overlay" as any,
        WebkitOverflowScrolling: "touch",

        "&.isFirefox": {
            overflowY: "auto",
        },
    },
    rootHorizontal: {
        overflow: "hidden",
    },
    parent: {
        overflow: "hidden",
        position: "relative",
    },
    hideScrollbar: {
        "&$rootHorizontal, &$rootVertical": {
            "&::-webkit-scrollbar": {
                width: "0",
            },
        },
    },
    scrollBarWide: {
        "&$rootHorizontal, &$rootVertical": {
            "&::-webkit-scrollbar": {
                width: 8,
            },
        },
    },
    root: {
        "&$rootHorizontal, &$rootVertical": {
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
            },
        },
    },
    showScrollbar: {
        "&$rootHorizontal, &$rootVertical": {
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(200, 200, 200, 0.5)",
            },
        },
    },
    shadow: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        pointerEvents: "none",
        overflow: "hidden",
        "&::before, &::after": {
            content: "''",
            left: 0,
            position: "absolute",
            height: 46,
            width: "100%",
            zIndex: 1,
            "mix-blend-mode": "multiply",
            opacity: 0,
            pointerEvents: "none",
            transition: "opacity 400ms ease-in-out",
        },

        "&.small::before, &.small::after": {
            height: 20,
        },

        "&.small::after": {
            bottom: -20,
        },
        "&.small::before": {
            top: -20,
        },

        "&.shadow-start, &.shadow-both": {
            "&::before": {
                opacity: 1,
            },
        },
        "&.shadow-end, &.shadow-both": {
            "&::after": {
                opacity: 1,
            },
        },
        "&.shadow-border-radius": {
            "&.shadow-start": {
                borderRadius: "12px 12px 0 0",
            },
            "&.shadow-end": {
                borderRadius: "0 0 12px 12px",
            },
        },
    },
    greyShadow: {
        "&::before, &::after": {
            mixBlendMode: "multiply",
            height: 17,
            borderRadius: "50%",
            width: "100%",
            filter: "blur(10px)",
            background: theme.main.palette.cdkPalette.gradients.scrollContainer.grey,
        },
        "&::before": {
            top: 0,
        },
        "&::after": {
            bottom: 0,
        },
    },
    whiteShadow: {
        "&::before": {
            top: 0,
            height: 10,
            backgroundImage: theme.main.palette.cdkPalette.gradients.scrollContainer.white,
        },
        "&::after": {
            bottom: 0,
            height: 10,
            backgroundImage: theme.main.palette.cdkPalette.gradients.scrollContainer.white,
        },
    },
    noneShadow: {
        visibility: "hidden",
    },
    closedShadowWidth: {
        "&$greyShadow::before, &$greyShadow::after": {
            background: "radial-gradient(circle at 100% 0, rgb(229 234 238 / 80%), rgba(229, 234, 238, 0))",
        },
    },
    stickyHeader: {
        position: "sticky",
        top: 0,
        zIndex: 1,
    },
    stickyAnchor: {
        position: "absolute",
        top: -1,
        left: 0,
        right: 0,
        height: 1,
    },
}));

type ScrollbarStyleProps = {
    offsetTop?: number;
    offsetBottom?: number;
};

const useScrollBarStyle = makeStyles({
    scrollbarWithOffset: {
        "&::-webkit-scrollbar-track": {
            marginTop: (props: ScrollbarStyleProps) => props.offsetTop || 0,
            marginBottom: (props: ScrollbarStyleProps) => props.offsetBottom || 0,
        },
    },
});
/**
 * @stickyHeader : the component passed here needs to have WithStickyProps<T> type. It will receive the isSticky prop that tells us if the element sticky position is true or false
 */
const ScrollContainer = forwardRef(
    <T extends ScrollContainerComponents = "div">(props: ScrollContainerProps<T>, ref: React.MutableRefObject<any>) => {
        const classes = useStyles();
        const {
            component: ScrollableContainer = "div",
            className,
            wrapperClassName,
            connector,
            axis = "y",
            filterBy,
            shadowGradient = "white",
            hideScrollbar,
            shadowOnScroll,
            shadowBorderRadius = false,
            shadowIsSmall = false,
            scrollBarOffsetTop,
            scrollBarOffsetBottom,
            scrollBarWide,
            children,
            onMouseMove: onMouseMoveProp,
            onMouseLeave: onMouseLeaveProp,
            onTouchMove: onTouchMoveProp,
            onTouchStart: onTouchStartProp,
            onTouchEnd: onTouchEndProp,
            stickyHeader,
            isMobile,
            ...other
        } = props;

        const scrollbarStyle = useScrollBarStyle({offsetTop: scrollBarOffsetTop, offsetBottom: scrollBarOffsetBottom});

        const innerRef = useRef<InternalRef>({
            set: (node: HTMLElement) => {
                innerRef.current.node = node;
                if (ref) {
                    if (typeof ref === "function") {
                        // @ts-expect-error
                        ref(node);
                    } else {
                        ref.current = node;
                    }
                }
            },
            node: null,
        });

        const prop = axis === "y" ? "scrollTop" : "scrollLeft";
        const isFirefox = browserInfo.isFirefox();

        const [scrollVal, setScrollVal] = useSpring({scroll: 0}, []);
        const [scrollState, setScrollState] = useState({
            onTop: false,
            onBottom: false,
            onLeft: false,
            onRight: false,
            scrollable: false,
        });

        const scrollTo = (target: number) => {
            if (!innerRef.current.node) {
                return;
            }

            connector.current.onScrollStarted?.();

            setScrollVal.current[0].start({
                reset: true,
                from: {scroll: innerRef.current.node[prop]},
                to: {scroll: target},
                onRest: () => {
                    if (axis === "x" && connector.current.onScrollLeftEnded) {
                        connector.current.onScrollLeftEnded(target);
                    } else if (axis === "y" && connector.current.onScrollTopEnded) {
                        connector.current.onScrollTopEnded(target);
                    }
                    if (axis === "y") {
                        if (connector.current.setVisibleChildren) {
                            connector.current.setVisibleChildren(computeVisibleChildren());
                        }
                    }
                },
            });
        };

        const instantScrollTo = (target: number) => {
            if (!innerRef.current.node) {
                return;
            }

            innerRef.current.node[prop] = target;
            setScrollVal.current[0].set({scroll: target});
        };

        const scrollChildIntoView = useCallback(
            (arg: ScrollIntoViewArg) => {
                const {node = null, index, location = "end", offset = 0} = arg;
                const parent = innerRef.current.node;
                const children = parent?.childNodes;

                const byChildIndex = typeof index === "number" && node === null;
                if (!parent || (byChildIndex ? !children![index!] : !parent.contains(node))) {
                    return;
                }

                let childMatched: HTMLElement = byChildIndex ? (children![index!] as HTMLElement) : null!;
                let childNodeIdx: number | undefined = undefined;

                if (typeof node !== "number" && childMatched === null) {
                    parent.childNodes.forEach((child, i) => {
                        if (!childMatched && child.isSameNode(node)) {
                            childMatched = child as HTMLElement;
                            childNodeIdx = i;
                        }
                    });
                }

                if (childMatched === null) {
                    return;
                }

                if (arg.preventIfVisible && childNodeIdx !== undefined) {
                    const visibleItems = computeVisibleChildren();
                    // console.log({visibleItems, childNodeIdx});
                    if (visibleItems.includes(childNodeIdx)) {
                        return;
                    }
                }

                const isVerticalScroll = axis === "y";

                const {scrollTop, scrollHeight, scrollLeft, scrollWidth} = parent;

                const {y: parentTop, height: parentHeight, x: parentLeft, width: parentWidth} = parent.getBoundingClientRect();
                const {y: childTop, height: childHeight, x: childLeft, width: childWidth} = childMatched.getBoundingClientRect();

                const target = isVerticalScroll ? scrollHeight : scrollWidth;

                let goto = isVerticalScroll
                    ? scrollTop +
                      childTop -
                      parentTop +
                      (location === "end"
                          ? -parentHeight + childHeight
                          : location === "center"
                          ? -(parentHeight / 2 + childHeight / 2)
                          : 0) +
                      offset
                    : scrollLeft +
                      (childLeft + childWidth * (location === "start" ? -1 : 1) - parentLeft) +
                      (location === "end" ? -parentWidth : location === "center" ? -(parentWidth / 2 + childWidth / 2) : 0) +
                      offset;

                if (goto >= target) {
                    goto = target;
                }
                if (goto <= 0) {
                    goto = 0;
                }
                scrollTo(goto);
            },
            [innerRef.current.node]
        );

        const computeVisibleChildren = () => {
            const node = innerRef.current.node;

            if (!node) {
                return [];
            }

            const {y, x, width: parentWidth, height: parentHeight} = node.getBoundingClientRect();

            const visibleIndexes: number[] = [];
            const children = node.children;

            for (let i = children.length - 1; i >= 0; i--) {
                const child = children[i];
                if (!child) {
                    continue;
                }
                const {y: childY, x: childX, width: childWidth, height: childHeight} = child.getBoundingClientRect();
                const isVisible =
                    axis === "y"
                        ? childY < y
                            ? childY + childHeight > y
                            : childY < y + parentHeight
                        : childX < x
                        ? childX + childWidth > x
                        : childX < x + parentWidth;

                if (isVisible) {
                    visibleIndexes.push(i);
                }
            }

            return visibleIndexes;
        };

        useEffect(() => {
            connector.current.scrollTo = scrollTo;
            connector.current.scrollChildIntoView = scrollChildIntoView;
            connector.current.getVisibleChildren = computeVisibleChildren;
            connector.current.instantScrollTo = instantScrollTo;
        }, [innerRef.current.node, scrollChildIntoView, scrollTo]);

        useEffect(() => {
            if (connector.current.setScrollState) {
                connector.current.setScrollState(scrollState);
            }
        }, [scrollState]);

        useEffect(() => {
            if (innerRef.current.node && connector.current.setVisibleChildren) {
                connector.current.setVisibleChildren(computeVisibleChildren());
            }
        }, []);

        const scrollTimeout = useRef<ReturnType<typeof setTimeout>>(null!);

        const scrollHandler = (node: HTMLElement) => {
            if (!node || !scrollState.scrollable) {
                return;
            }
            const onTop = node.scrollTop <= 1 ? true : false;
            const onLeft = node.scrollLeft <= 1 ? true : false;
            const onBottom = node.scrollTop >= node.scrollHeight - node.clientHeight - 1 ? true : false;
            const onRight = node.scrollLeft >= node.scrollWidth - node.clientWidth - 1 ? true : false;
            if (
                axis === "y"
                    ? onTop !== scrollState.onTop || onBottom !== scrollState.onBottom
                    : onLeft !== scrollState.onLeft || onRight !== scrollState.onRight
            ) {
                axis === "y"
                    ? setScrollState((c) => ({
                          ...c,
                          onTop,
                          onBottom,
                      }))
                    : setScrollState((c) => ({
                          ...c,
                          onLeft,
                          onRight,
                      }));
            }
            if (axis === "y" && connector.current.setVisibleChildren) {
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
                scrollTimeout.current = setTimeout(() => {
                    connector.current.setVisibleChildren!(computeVisibleChildren());
                }, 300);
            }
        };

        const onScroll = (e: any) => {
            const {scrollTop, scrollHeight, scrollLeft, scrollWidth, clientHeight, clientWidth} = e.currentTarget;

            if (isMacOS() && !isMobile) {
                const scrollTopMax = scrollHeight - clientHeight;
                const scrollLeftMax = scrollWidth - clientWidth;
                if (axis === "y" && (scrollTop < 0 || scrollTop > scrollTopMax)) {
                    e.currentTarget.scrollTop = scrollTop < 0 ? 0 : scrollTopMax;
                    e.preventDefault();
                } else if ((axis === "x" && scrollLeft < 0) || scrollLeft > scrollLeftMax) {
                    e.currentTarget.scrollLeft = scrollLeft < 0 ? 0 : scrollLeftMax;
                    e.preventDefault();
                }
            }
            scrollHandler(e.currentTarget);

            if (axis === "y" && connector?.current?.onScrollTopEnded) {
                connector?.current?.onScrollTopEnded(scrollTop);
            } else if (axis === "x" && connector?.current?.onScrollLeftEnded) {
                connector?.current?.onScrollLeftEnded(scrollTop);
            }
        };

        useEffect(() => {
            setTimeout(() => {
                if (innerRef.current.node) {
                    scrollHandler(innerRef.current.node);
                }
            }, 800);
        }, []);

        useEffect(() => {
            const isScrollable =
                axis === "y" && innerRef.current.node && innerRef.current.node.scrollHeight - innerRef.current.node.clientHeight > 2
                    ? true
                    : false;
            if (isScrollable !== scrollState.scrollable) {
                setScrollState((c) => ({
                    ...c,
                    scrollable: isScrollable,
                }));
            }
        }, [children]);

        const wrapperClasses = cls("flex flex11-auto", classes.parent, axis === "y" && "flex-col", wrapperClassName);
        const scrollShadowClassName = cls(
            shadowOnScroll && classes.shadow,
            shadowOnScroll &&
                "shadow-" +
                    (shadowOnScroll.showOn === "both"
                        ? !scrollState.onTop && !scrollState.onBottom
                            ? "both"
                            : scrollState.onBottom
                            ? "start"
                            : "end"
                        : shadowOnScroll.showOn === "start" && !scrollState.onTop
                        ? "start"
                        : shadowOnScroll.showOn === "end" && !scrollState.onBottom
                        ? "end"
                        : "none"),
            shadowOnScroll && classes[shadowGradient + "Shadow"],
            shadowOnScroll && shadowBorderRadius && "shadow-border-radius"
        );

        const timer = useRef<ReturnType<typeof setTimeout> | number>(0);

        function scrollbarHide(event) {
            const target = event.currentTarget;
            clearTimeout(timer.current);

            target.classList.add(classes.showScrollbar);

            timer.current = setTimeout(() => {
                target.classList.remove(classes.showScrollbar);
            }, 1500);
        }

        const onMouseMove = (event) => {
            if (onMouseMoveProp) {
                onMouseMoveProp(event);
            }

            if (hideScrollbar) {
                return;
            }

            scrollbarHide(event);
        };

        const onMouseLeave = (event) => {
            if (onMouseLeaveProp) {
                onMouseLeaveProp(event);
            }

            scrollbarHide(event);
        };

        const onTouchMove = (event) => {
            if (onTouchMoveProp) {
                onTouchMoveProp(event);
            }

            if (hideScrollbar) {
                return;
            }

            scrollbarHide(event);
        };

        const onTouchStart = (event) => {
            if (onTouchStartProp) {
                onTouchStartProp(event);
            }

            if (hideScrollbar) {
                return;
            }

            scrollbarHide(event);
        };

        const onTouchEnd = (event) => {
            if (onTouchEndProp) {
                onTouchEndProp(event);
            }

            if (hideScrollbar) {
                return;
            }

            scrollbarHide(event);
        };

        const [sticky, setSticky] = useState(false);
        const preventMultipleUpdates = useRef(false);
        useEffect(() => {
            if (stickyHeader && stickyAnchorRef.current && scrollState.scrollable) {
                const handler = (entries) => {
                    // entries is an array of observed dom nodes
                    // we're only interested in the first one at [0]
                    // because that's our stickyAnchorRef.
                    // Here observe whether or not that node is in the viewport
                    if (!preventMultipleUpdates.current) {
                        if (entries[0].isIntersecting) {
                            if (scrollState.scrollable) {
                                preventMultipleUpdates.current = true;
                                setSticky(false);
                            }
                        } else {
                            setSticky(true);
                            preventMultipleUpdates.current = true;
                        }
                        setTimeout(() => {
                            preventMultipleUpdates.current = false;
                        }, 100);
                    }
                };

                const observer = new window.IntersectionObserver(handler);

                observer.observe(stickyAnchorRef.current);
                if (!scrollState.scrollable) {
                    observer.disconnect();
                }
                return () => {
                    observer.disconnect();
                };
            }
        }, [scrollState.scrollable]);

        const stickyAnchorRef = useRef<HTMLDivElement | null>(null);

        return createElement(
            shadowOnScroll ? "div" : Fragment,
            shadowOnScroll
                ? {
                      // @ts-ignore
                      className: wrapperClasses,
                      ref,
                  }
                : {},

            // @ts-ignore
            createElement(
                // @ts-ignore
                animated(ScrollableContainer),
                {
                    ...other,
                    [prop]: scrollVal?.scroll ?? 0,
                    ref: innerRef.current.set,
                    onScroll,
                    className: cls(
                        classes.root,
                        "flex flex11-auto",
                        axis === "y" && "flex-col",
                        className,
                        classes[axis === "y" ? "rootVertical" : "rootHorizontal"],
                        "mozilla-scrollbar",
                        isFirefox && "isFirefox",
                        scrollbarStyle.scrollbarWithOffset,
                        hideScrollbar && classes.hideScrollbar,
                        scrollBarWide && classes.scrollBarWide
                    ),
                    onMouseMove,
                    onMouseLeave,
                    onTouchMove,
                    onTouchStart,
                    onTouchEnd,
                } as any,
                <>
                    {stickyHeader ? <div className={classes.stickyAnchor} ref={stickyAnchorRef}></div> : null}
                    {stickyHeader
                        ? useMemo(
                              () =>
                                  React.cloneElement(stickyHeader, {
                                      isSticky: sticky,
                                      className: cls(stickyHeader.props.className, classes.stickyHeader),
                                  }),
                              [sticky, stickyHeader.props]
                          )
                        : null}
                    {children}
                </>
            ),
            shadowOnScroll && scrollState.scrollable ? (
                <div
                    className={cls(scrollShadowClassName, shadowIsSmall && "small")}
                    style={{top: shadowOnScroll.offsetTop || 0, bottom: shadowOnScroll.offsetBottom || 0}}
                />
            ) : null
        );
    }
);

ScrollContainer.displayName = "ScrollContainer";

export default ScrollContainer as <T extends ScrollContainerComponents = "div">(
    props: ScrollContainerProps<T> & {ref?: React.MutableRefObject<any> | null}
) => ReturnType<typeof ScrollContainer>;
