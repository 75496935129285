import {RECORDING_USER_EMAIL} from "@common/recording/constants";
import {SessionLifecycle} from "@generated/data";
import {Fragment, useState} from "@workhorse/api/rendering";
import {QueryPayload, QueryRenderer} from "@workhorse/dataApi";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User/UserInfoProvider";
import BreakoutPlayerEntry from "./components/BreakoutMenu/BreakoutPlayerEntry";
import DevicesInformationDialogs from "./components/DevicesInformationDialogs";
import Player from "./PlayerNew";
import PlayerRedirects from "./PlayerRedirects";
import PreJoinScreenEntry from "./pre-join-screen/PreJoinScreenEntry";

export type LoadedSessionProps = {
    sessionId: string;
    isOwner: boolean;
    isJoined: boolean;
} & QueryPayload<"GetSessionDocument">;

function LoadedSession(props: LoadedSessionProps) {
    const {
        createdAt,
        lifecycle,
        id: sessionId,
        currentParticipant,
        childOfBreakoutRooms,
        order,
        event,
        backstage,
    } = props.data.session ?? {};

    const user = useUserInfo();

    const myParticipantIsRecorder = user.email === RECORDING_USER_EMAIL;

    const sessionStarted = lifecycle === SessionLifecycle.Started;
    const sessionEnded = lifecycle === SessionLifecycle.Ended;
    const sessionIsPublished = !!createdAt;
    const {isLobby} = useIsLobby();

    // useMemo(() => {
    //     if (sessionEnded || backstage || myParticipantIsRecorder) {
    //         return;
    //     }
    //     const isAssistant = rbac(currentParticipant.pid, "session.isAssistant");

    //     if (event && !sessionStarted) {
    //         if (isAssistant || props.isOwner) {
    //             window.location.href = `/event/${sessionId}/general`;
    //         } else {
    //             window.location.href = `${environment.eventBaseURL}/${event.slug}`;
    //         }
    //     }
    // }, [event, sessionStarted, sessionId, currentParticipant, props.isOwner, sessionEnded, backstage, myParticipantIsRecorder]);

    // before we decide if we should mount the final player layer
    // there are plenty scenarios that may trigger a redirect
    // but we avoid computing them here, so we delegate that job to a leaf component
    // in order to prevent state-triggered re-renders
    // once it is determined that no redirect should happen, canMount becomes true
    // allowing the payer to mount
    const [canMount, setCanMount] = useState(false);

    return (
        <>
            <PlayerRedirects key={props.data.session.backstage + ""} session={props.data.session} setCanMountPlayer={setCanMount} />
            <Fragment key="device-info-dialogs">
                {sessionIsPublished && canMount && sessionStarted && <DevicesInformationDialogs isRecorder={myParticipantIsRecorder} />}
            </Fragment>
            {/* {sessionIsPublished && currentParticipant && isJoined && (
                <KeepAlive
                    participantId={currentParticipant.pid}
                    sessionId={sessionId}
                    sessionLifecycle={lifecycle}
                    sessionOrder={order}
                    sessionUpdatedAt={updatedAt}
                />
            )} */}
            {canMount && (
                <PreJoinScreenEntry
                    session={props.data.session}
                    skip={!props.isJoined || myParticipantIsRecorder}
                    isOwner={props.isOwner}
                    isJoined={props.isJoined}
                >
                    <Fragment key="breakout-player-entry">
                        {props.isJoined && (
                            <BreakoutPlayerEntry
                                sessionId={sessionId}
                                sessionCurrentOrder={order}
                                sessionStarted={sessionStarted}
                                isChild={!!childOfBreakoutRooms}
                                myParticipantId={currentParticipant.pid}
                                isLobby={isLobby}
                                sessionIsPublished={sessionIsPublished}
                            />
                        )}
                    </Fragment>
                    <Player isJoined={props.isJoined} session={props.data.session} />
                </PreJoinScreenEntry>
            )}
        </>
    );
}

const LoadPlayerSession = QueryRenderer({
    component: LoadedSession,
    /**
     * this is the only instance of QueryRenderer which uses one document
     * but actually fetches data through another document entirely under-the-hood (FullSession)
     * the point is to fetch the data that changes most often into separate namespaces
     * and write them to the cache as such
     *
     * i.e. this query will result in the following dataset being retreived from the server
     * data:{
     *      session{...} (this doesn't contain the below relations anymore except for the currentParticipant)
     *      agendaItems[...]
     *      macroArtifacts[...]
     *      participants[...]
     *      currentParticipant{...}
     * }
     *
     * however, the resulting component will react to changes made only to
     * data:{
     *      session{...}
     * }
     *
     */

    queryDoc: "GetSessionDocument",
    options: (props) => {
        return {
            variables: {
                id: props.sessionId,
            },
        };
    },
    // @ts-expect-error not defined in type because it is used only here
    isPlayerSessionLoader: true,
});

export default LoadPlayerSession;
