import {ConferenceParticipantStatus} from "@generated/data";
import {useEffect} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";
import {useDataEvent} from "@workhorse/dataApi";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useShallow} from "zustand/react/shallow";
import {useDevices, useForceMuteStatus} from "../providers";
import {useParticipantsStore} from "../providers/ParticipantsProvider/LocalParticipantsStore";
import {useForceDisableVideo} from "../providers/VideoDisableProvider";

export function ParticipantDeviceEvents() {
    const {setVideoEnabled, setAudioMuted} = useDevices();
    const {muteStatus} = useForceMuteStatus();

    const isForceMute = muteStatus === "hard";

    const self = useParticipantsStore(
        useShallow(({currentParticipant}) => ({
            id: currentParticipant?.id,
            isOwner: currentParticipant?.isOwner,
            conferenceStatus: currentParticipant?.conferenceStatus,
        }))
    );

    const isOwner = self?.isOwner ?? false;
    const isSpeaker = self?.conferenceStatus === ConferenceParticipantStatus.Speaker;
    const isAssistant = rbac(self.id, "session.isAssistant");
    const {childOfBreakoutRooms} = useSession();
    const isBreakoutChild = !!childOfBreakoutRooms;

    const {isHardVideoDisable} = useForceDisableVideo();

    useEffect(() => {
        if (isForceMute && !isBreakoutChild) {
            setAudioMuted(true);
        }
    }, [isForceMute, setAudioMuted]);

    useEffect(() => {
        if (isHardVideoDisable) {
            setVideoEnabled(false, false);
        }
    }, [isHardVideoDisable, setVideoEnabled]);

    useDataEvent("onWatchdogPrivate", (data) => {
        if (data?.watchdogPrivate?.softStopCameraAll) {
            if (!isSpeaker && !isAssistant && !isOwner) {
                setVideoEnabled(false);
            }
        }

        if (data.watchdogPrivate?.participantIndividualStopCamera) {
            setVideoEnabled(false);
        }
    });

    return <></>;
}
