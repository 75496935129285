import {useClientEvent} from "@api/events/client";
import {
    ResourceFullFragmentDoc,
    ResxResourceUpdatedNotifyDocument,
    UseResourceDocument,
    UseResourcesForRenderingDocument,
} from "@generated/data";
import {WithChildren} from "@workhorse/declarations";
import apollo from "../apollo";
import {useEffect} from "../rendering";
import {debounce} from "../utils/debouncer";
import {renderedContainers} from "./hooks";

const debouncedUpdateCache = debounce(
    (id: string) => {
        apollo.client
            .query({
                query: UseResourceDocument,
                variables: {
                    id,
                },
                fetchPolicy: "no-cache",
            })
            .then(({data, errors, loading}) => {
                if (loading) {
                    return;
                }
                if (errors) {
                    throw errors;
                }

                apollo.client.writeFragment({
                    fragment: ResourceFullFragmentDoc,
                    data: {
                        ...data.resource,
                    },
                    broadcast: true,
                });
            });
    },
    200 // debounce with 200ms delay
);

export const ResxProvider = ({children}: WithChildren) => {
    useEffect(() => {
        apollo.client
            .subscribe({
                query: ResxResourceUpdatedNotifyDocument,
                variables: {},
            })
            .subscribe({
                next: (result) => {
                    if (result.data?.resxResourceUpdated?.resourceId) {
                        debouncedUpdateCache(result.data?.resxResourceUpdated?.resourceId);
                    }
                },
            });
    }, []);

    useClientEvent("contextual-properties", (props, ctx) => {
        if (!!props["resx/cache/evict"]) {
            const evictTarget = props["resx/cache/evict"];

            apollo.cache.evict({
                id: evictTarget.id,
                broadcast: true,
            });
        }

        if (!!props["resx/cache/load"]) {
            const loadTarget = props["resx/cache/load"];

            apollo.client
                .query({
                    query: UseResourceDocument,
                    variables: {
                        id: loadTarget.id,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(({data, errors, loading}) => {
                    if (loading) return;
                    if (errors) throw errors;

                    apollo.client.writeFragment({
                        fragment: ResourceFullFragmentDoc,
                        data: {
                            ...data.resource,
                        },
                        broadcast: true,
                    });

                    if (loadTarget.append) {
                        const containers = renderedContainers()[loadTarget.type]?.containers ?? {};
                        for (let container of Object.keys(containers)) {
                            const existingData = apollo.client.readQuery({
                                query: UseResourcesForRenderingDocument,
                                variables: {
                                    type: loadTarget.type,
                                    container,
                                },
                            });

                            const resxResourcesData = {
                                ...existingData?.resxResources,
                                hasData: existingData?.resxResources?.hasData ?? false,
                                resources: data.resource
                                    ? [data.resource!, ...(existingData?.resxResources?.resources ?? [])]
                                    : [...(existingData?.resxResources?.resources ?? [])],
                            };
                            apollo.client.writeQuery({
                                query: UseResourcesForRenderingDocument,
                                variables: {
                                    type: loadTarget.type,
                                    container,
                                },
                                data: {
                                    ...existingData,
                                    resxResources: resxResourcesData,
                                },
                                broadcast: true,
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log("loading resx failed", err);
                });
        }
    });

    return children as unknown as JSX.Element;
};
