import MuiCheckbox, {CheckboxProps as MuiCheckboxProps} from "@material-ui/core/Checkbox";
import type {Theme} from "@material-ui/core";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as CheckboxCheckedIcon} from "../../../../frontend/assets/media/CheckboxCheckedIcon.svg";
import {ReactComponent as CheckboxUncheckedIcon} from "../../../../frontend/assets/media/CheckboxUncheckedIcon.svg";
import styles from "./checkbox.module.scss";

type CheckboxSizes = "extra-small" | "small" | "medium" | "big";

export type CheckboxProps = Omit<MuiCheckboxProps, "color" | "size"> & {
    variant?: keyof Pick<Theme["main"]["palette"], "gray" | "blue" | "purple" | "green" | "yellow" | "red" | "primary">;
    size?: CheckboxSizes;
};

const Checkbox = (props: CheckboxProps) => {
    const {variant: color = "blue", size = "small", ...rest} = props;
    const checkboxClasses = cls(styles["size-" + size ?? ""], styles["color-" + color]);
    return (
        <MuiCheckbox
            className={checkboxClasses}
            classes={{
                root: checkboxClasses,
                checked: styles.fillWhite,
                disabled: styles.disabled,
            }}
            checkedIcon={props.checkedIcon || <CheckboxCheckedIcon className={styles.checkedIcon} />}
            icon={props.icon || <CheckboxUncheckedIcon />}
            color="default"
            {...rest}
        />
    );
};

export default Checkbox;
