import React, {useEffect} from "@workhorse/api/rendering";
import {EventEmitter, EventNames, EventListener} from "eventemitter3";
import {EventsMap} from "./EventsMap";

const clientEvents = new EventEmitter<EventsMap>();

export const useClientEvent = <T extends EventNames<EventsMap>>(eventName: T, listener: EventListener<EventsMap, T>, context?: any) => {
    const removeListener = () => {
        clientEvents.off(eventName, listener, context);
    };

    useEffect(() => {
        clientEvents.on(eventName, listener, context);
        return removeListener;
    }, [listener, removeListener, context]);

    return {removeListener};
};

/**
 * Currently not used.
 *
 * Persisted  in case we can find an use-case of client-side events.
 */
export default clientEvents;
