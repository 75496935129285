import toWav from "audiobuffer-to-wav";

type WebkitWindow = typeof window & {
    webkitAudioContext: AudioContext;
};

const AudioContext = window.AudioContext || (window as WebkitWindow).webkitAudioContext;

const context = new AudioContext();

export class AudioStreamSegment {
    size = 0;
    timestamp = Date.now();
    duration?: number;

    private data: Blob[] = [];
    private mediaRecorder: MediaRecorder;

    private stopPromise: Promise<void> | null = null;

    private supportsWebm = MediaRecorder.isTypeSupported("audio/webm;codec=opus");

    private mimeType = this.supportsWebm ? "audio/webm;codec=opus" : "audio/mp4;codec=mp3";
    private fileExtension = this.supportsWebm ? "webm" : "mp4";

    constructor(stream: MediaStream, private onSizeChanged?: (nr: number) => void) {
        this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: this.mimeType,
        });
        this.mediaRecorder.ondataavailable = (e) => {
            this.addData(e.data);
        };
    }

    private addData(data: Blob) {
        this.data.push(data);
        this.size += data.size;
        this.onSizeChanged?.(this.size);
    }

    start() {
        try {
            this.mediaRecorder.start(1000);
        } catch (e) {
            console.log(e);
        }
    }

    stop() {
        this.duration = Date.now() - this.timestamp;

        if (this.mediaRecorder.state === "inactive") {
            this.stopPromise = Promise.resolve();
            return;
        }

        this.stopPromise = new Promise((resolve) => {
            this.mediaRecorder.onstop = () => {
                resolve();
            };

            if (this.mediaRecorder.state === "inactive") {
                resolve();
            }
        });

        this.mediaRecorder.stop();
    }

    async createFile(name: string) {
        if (this.stopPromise) {
            await this.stopPromise;
        }

        if (this.supportsWebm) {
            const file = new File(this.data, name + "." + this.fileExtension, {
                type: this.mimeType,
            });

            return {file, mimeType: "audio/webm"};
        }

        const blob = new Blob(this.data, {
            type: this.mimeType,
        });

        const audioBuffer = await context.decodeAudioData(await blob.arrayBuffer());

        const audio = toWav(audioBuffer);

        const file = new File([audio], name + ".wav", {
            type: "audio/wav",
        });

        return {file, mimeType: "audio/wav"};
    }
}
