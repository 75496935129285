import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import {muteParticipant} from "@workhorse/api/conference/sessionApi";
import classes from "./styles/ParticipantEntry.module.scss";
import IconButton from "@ui/core/components/IconButton";

type ParticipantEntryMuteActionProps = {
    renderingForAdmin: boolean;
    renderingForAssistant: boolean;
    participantId: string;
    muted: boolean;
};

function ParticipantEntryMuteAction(props: ParticipantEntryMuteActionProps) {
    const {renderingForAdmin, renderingForAssistant, participantId, muted} = props;

    const handleClick = () => {
        muteParticipant(participantId);
    };

    return (
        <IconButton
            size="small"
            className={classes.participantActionBtn}
            onClick={handleClick}
            disabled={!((renderingForAdmin || renderingForAssistant) && !muted)}
            aria-label={`${!muted ? "Mute participant" : "Participant is muted"}`}
        >
            {muted && <MicOff className="red" />}
            {!muted && <Mic />}
        </IconButton>
    );
}

export default ParticipantEntryMuteAction;
