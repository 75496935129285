import Button from "@ui/cdk/Button";
import Dialog from "@ui/core/components/Dialog";
import Typography from "@ui/cdk/Typography";
import style from "./style/StartRecordingDialog.module.scss";
import recording from "../../../../assets/media/recording.png";

interface Props {
    open: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

export function StopRecordingDialog(props: Props) {
    return (
        <Dialog
            open={props.open}
            className="flex flex-col flex-center-all"
            BackdropProps={{
                className: "standard-backdrop",
            }}
            classes={{
                paper: style.dialogPaper,
            }}
        >
            <div className={style.header}>
                <img className={style.image} src={recording} />
                <Typography variant="xl3" component="h2" color="primary">
                    You’re about to stop recording
                </Typography>
            </div>
            <div className={style.info}>
                <Typography color="primary">By switching rooms your current recording will stop.</Typography>
            </div>
            <div className={style.footer}>
                <Button className={style.cancelButton} type="button" variant="tertiary" size="large" onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button type="button" onClick={props.onOk} size="large">
                    Ok
                </Button>
            </div>
        </Dialog>
    );
}
