import loadable from "@workhorse/api/loadable";

const OrganizationInvite = loadable(() => import("./OrganizationInvite"));

function OrganizationInviteHOC(): JSX.Element {
    return (
        <div className="route-hoc flex-align-start organization-invite-hoc">
            <OrganizationInvite />
        </div>
    );
}

export default OrganizationInviteHOC;
