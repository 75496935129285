import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./styles/WorkspaceContent.module.scss";
import {ContentInfo} from "../OnboardingWorkspaceDialog";

type WorkspaceContentProps = {
    contentInfo: ContentInfo;
};

function WorkspaceContent(props: WorkspaceContentProps) {
    const {contentInfo} = props;
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {contentInfo.headerIcon && <img src={contentInfo.headerIcon} alt="header-icon" />}
                <Typography variant="xl3" color="secondary" fontWeight="bolder">
                    {contentInfo.headerText}
                </Typography>
            </div>
            <Typography color="quaternary" className={classes.description}>
                {contentInfo.description}
            </Typography>
            <div className={cls("flex flex-col fullw fullh", classes.body)}>{contentInfo.children}</div>
        </div>
    );
}

export default WorkspaceContent;
