import {browserBehavior, AudioElementWithSinkId, useDeviceManagerEvents} from "@workhorse/api/conference2";
import {useElementAudioDbAnalyzer} from "@workhorse/api/conference2/hooks/useAudioElementDbAnalyzer";
import {useLocalAudioDbAnalyzer} from "@workhorse/api/conference2/hooks/useLocalAudioDbAnalyzer";
import {cls} from "@ui/cdk/util/util";
import {useCallback, useEffect, useState} from "react";
import classes from "./AudioPreview.module.scss";

interface IndicatorProps {
    max: number;
    value: number;
    index: number;
    className?: string;
}

const hasSinkId = browserBehavior.supportsSetSinkId();

function Indicator(props: IndicatorProps) {
    const enabled = props.value >= (0.9 / props.max) * props.index;
    return <div className={cls(classes.indicator, props.className, enabled && classes.enabled)} />;
}

function createIndicators(value: number, maxItems: number, className?: string) {
    const items: JSX.Element[] = [];
    for (let i = 1; i <= maxItems; i++) {
        items.push(<Indicator key={i} className={className} value={value} index={i} max={maxItems} />);
    }
    return items;
}

interface InputProps {
    deviceId: string;
    className?: string;
}

export function AudioInputPreview(props: InputProps) {
    const [db, setDb] = useState(0);
    useLocalAudioDbAnalyzer(props.deviceId, setDb);

    useEffect(() => {
        setDb(0);
    }, [props.deviceId]);

    return <div className={cls(classes.container, props.className)}>{createIndicators(db, 6)}</div>;
}

interface InputStreamProps {
    className?: string;
}

export function LocalAudioInputPreview(props: InputStreamProps) {
    const [db, setDb] = useState(0);

    useDeviceManagerEvents({
        onAudioVolume: setDb,
        onAudioSuccess: () => setDb(0),
        onAudioError: () => setDb(0),
    });

    return <div className={cls(classes.container, props.className)}>{createIndicators(db, 6)}</div>;
}

interface OutputProps {
    deviceId: string;
    playing: boolean;
    setPlaying: (value: boolean) => void;
    className?: string;
}

export function AudioOutputPreview(props: OutputProps) {
    const {deviceId, playing, setPlaying} = props;
    const [db, setDb] = useState(0);

    const [node, setNode] = useState<HTMLAudioElement | null>(null);
    const [destination, setDestination] = useState<AudioElementWithSinkId | null>(null);

    const {connect, resume, disconnect} = useElementAudioDbAnalyzer(setDb);

    const setRef = useCallback((node: HTMLAudioElement | null) => setNode(node), []);

    useEffect(() => {
        setDb(0);
    }, [deviceId]);

    useEffect(() => {
        let cancelled = false;

        if (!node || !playing) {
            return;
        }

        const run = async () => {
            await resume();

            if (!cancelled) {
                await node.play();
            }
        };

        run().catch((e) => console.log(e));

        return () => {
            cancelled = true;
        };
    }, [node, playing, resume]);

    useEffect(() => {
        if (!node) {
            return;
        }

        let cancelled = false;

        const run = async () => {
            await disconnect();

            if (cancelled) {
                return;
            }

            const result = connect(node);
            if (!result) {
                return;
            }

            const dest = new Audio();
            dest.srcObject = result.stream;
            dest.autoplay = true;

            if (hasSinkId) {
                setDestination(dest as AudioElementWithSinkId);
            }
        };

        run().catch((e) => console.log(e));

        return () => {
            cancelled = true;
            disconnect();
        };
    }, [node, setPlaying, connect, disconnect]);

    useEffect(() => {
        destination?.setSinkId(deviceId || "default").catch((e) => console.log(e));
    }, [destination, deviceId]);

    return (
        <>
            <div className={cls(classes.container, props.className)} onClick={() => node?.play()}>
                {createIndicators(db, 6)}
            </div>
            <audio
                ref={setRef}
                src={window.origin + "/audio/chime-synth.mp3"}
                onPause={() => setPlaying(false)}
                onEnded={() => setPlaying(false)}
            />
        </>
    );
}
