import {serialize} from "@api/editor";
import {cls} from "@ui/cdk/util";
import designer from "@workhorse/api/designer";
import {useEffect, useRef, useState, memo, useCallback, useMemo} from "@workhorse/api/rendering";
import Editor from "@workhorse/components/editor";
import classes from "./style/AgendaItemDescription.module.scss";
import {SessionsEditor} from "@workhorse/components/editor/api";

type AgendaItemDescriptionProps = {
    value: any;
    id: string;
    readOnly?: boolean;
    onFocusDescription: (preventLocking?: boolean) => void;
    onBlur?: () => Promise<void>;
    className?: string;
    inEventSettings?: boolean;
    shouldUpdateEditorContent?: boolean;
    setShouldUpdateEditorContent?: (value: boolean) => void;
};

type AgendaItemDescriptionReadOnlyProps = {
    value: any;
    className?: string;
};

const AgendaItemDescription = (props: AgendaItemDescriptionProps) => {
    if (props.readOnly) {
        return <AgendaItemDescriptionReadOnly value={props.value} className={props.className} />;
    }
    return <AgendaItemDescriptionEditMode {...props} />;
};

const AgendaItemDescriptionReadOnly = (props: AgendaItemDescriptionReadOnlyProps) => {
    const description = useMemo(() => props.value, [props.value]);

    const serialized = useMemo(() => serialize(description), [description]);

    return (
        <div
            className={cls(classes.serializedDescription, props.className)}
            dangerouslySetInnerHTML={{
                __html: serialized,
            }}
        />
    );
};

const AgendaItemDescriptionEditMode = (props: AgendaItemDescriptionProps) => {
    const {
        value,
        id,
        readOnly,
        onFocusDescription,
        onBlur: commit,
        inEventSettings,
        shouldUpdateEditorContent,
        setShouldUpdateEditorContent,
    } = props;

    const [description, setDescription] = useState<any>(value);
    const editorRef = useRef<SessionsEditor | null>(null);
    useEffect(() => {
        setDescription(value);
    }, [value]);

    const descriptionRef = useRef<any>(value);

    useEffect(() => {
        descriptionRef.current = description;
    }, [description]);

    useEffect(() => {
        if (shouldUpdateEditorContent) {
            editorRef.current?.commands.setContent(description, false);
            setShouldUpdateEditorContent?.(false);
        }
    }, [description]);

    const onBlur = useCallback(
        (event: React.FocusEvent<HTMLDivElement>) => {
            const relatedTarget = event?.relatedTarget as HTMLElement;
            const isOutOfScopeEditing = relatedTarget?.classList.contains("editing");

            const isEditingTarget = relatedTarget?.classList.contains("editing_" + id) || isOutOfScopeEditing;

            designer.api.agendaItem.update({
                id,
                agendaItem: {
                    descriptionJson: descriptionRef.current,
                },
            });
            if (!isEditingTarget && !inEventSettings) {
                commit?.();
            }
            if (inEventSettings && !isOutOfScopeEditing) {
                designer.commit({
                    source: "agenda",
                });
            }
        },
        [id, commit]
    );

    const onChangeDescription = useCallback((value) => {
        setDescription(value);
    }, []);

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        let preventLocking = false;
        const relatedTarget = event?.relatedTarget as HTMLElement;
        const isEditingTarget = relatedTarget?.classList.contains("editing_" + id);

        if (isEditingTarget) {
            preventLocking = true;
        }

        onFocusDescription(preventLocking);
    };

    return (
        <Editor
            editorRef={editorRef}
            toolbarPortal={`slate-toolbar-slot-${id}`}
            toolbarTheme="floating"
            className={classes.editor}
            placeholder={readOnly ? "" : "Add agenda item description"}
            classes={{
                editable: "editing_" + id,
            }}
            renderToolbarOnFocus
            readOnly={readOnly}
            onFocus={onFocus}
            onBlur={onBlur}
            value={description}
            hideToolbarItems={["headings", "indentation", "alignment"]}
            onChange={onChangeDescription}
            maxLength={5000}
        />
    );
};

export default memo(AgendaItemDescription);
