import React, {useEffect, useRef, useState} from "react";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import classes from "./CouponEditor.module.scss";
import Button from "@ui/cdk/Button";
import {useClientEvent} from "@api/events/client";
import toast from "@workhorse/api/toast";
import {differenceInSeconds} from "date-fns";

type CouponEditorProps = {
    couponState?: string | null;
    onCouponUpdate: (coupon: string) => void;
    couponPermanent?: string | null;
};

const CouponEditor: React.FC<CouponEditorProps> = ({couponState, onCouponUpdate, couponPermanent}) => {
    const [newCouponCode, setNewCouponCode] = useState(couponState);

    const [mode, setMode] = useState<"view" | "edit">("view");

    const handleCouponCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewCouponCode(event.target.value);

        if (!errorPossible.current) {
            errorPossible.current = true;
        }
    };

    let lastToastedRef = useRef<Date | null>(null);

    let errorPossible = useRef(false);

    const handleAddCoupon = () => {
        if (newCouponCode && newCouponCode?.trim() !== "") {
            onCouponUpdate(newCouponCode);
            setNewCouponCode(newCouponCode);
        }
        handleView();
    };

    const handleEdit = () => {
        setMode("edit");
    };

    const handleView = () => {
        setMode("view");
    };

    const currentApplied = couponState;

    const throttleToastMessageRef = useRef(false);

    useClientEvent("paddle-coupon-on-load", (coupon: string | null) => {
        console.log("in client event", coupon);
        setNewCouponCode(couponState);
        if (newCouponCode && newCouponCode !== coupon) {
            if (throttleToastMessageRef.current) {
                return;
            }

            toast("Invalid discount code", {
                type: "error",
            });
            throttleToastMessageRef.current = true;
            setTimeout(() => {
                throttleToastMessageRef.current = false;
            }, 1200);
        }
        handleView();
    });

    useClientEvent("paddle-coupon-remove", () => {
        setNewCouponCode("");
        handleView();
    });

    useClientEvent("paddle-coupon-change", (coupon: string) => {
        setNewCouponCode(coupon);
        handleView();
    });

    return (
        <div className={classes.root}>
            <Typography variant="lg" fontWeight="bold" className="mb-8">
                Discount code
            </Typography>
            {mode === "view" ? (
                <div className="flex flex-justify-between">
                    <Typography variant="base">{currentApplied ?? "Add a discount code"}</Typography>
                    <div>
                        <Button onClick={handleEdit} size="small" variant="secondary">
                            {currentApplied ? "Change" : "Add"} discount code{" "}
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <Input
                        type="text"
                        size="small"
                        value={newCouponCode}
                        onChange={handleCouponCodeChange}
                        endAdornment={
                            <Button onClick={handleAddCoupon} size="smallest" variant="plain">
                                Save
                            </Button>
                        }
                        placeholder="Enter coupon code"
                    />
                </div>
            )}
        </div>
    );
};

export default CouponEditor;
