import ToggleButton from "@ui/cdk/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@ui/cdk/ToggleButtonGroup/ToggleButtonGroup";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {SessionSection} from "./UsageHistory";
import classes from "./style/UsageHistoryHeader.module.scss";
import {cls} from "@ui/cdk/util";
import UsageHistoryFiltersButton from "./UsageHistoryFilters";

type UsageHistoryHeaderProps = {
    onClose: () => void;
    selectedSection: SessionSection | "all";
    setSelectedSection: React.Dispatch<React.SetStateAction<SessionSection | "all">>;
    filter: {personal: boolean; others: boolean};
    setFilter: React.Dispatch<
        React.SetStateAction<{
            personal: boolean;
            others: boolean;
        }>
    >;
    canSeeOthersSessions: boolean;
    itemTitle: string;
};
const UsageHistoryHeader = (props: UsageHistoryHeaderProps) => {
    const {onClose, selectedSection, setSelectedSection, filter, setFilter, canSeeOthersSessions, itemTitle} = props;

    const handleSectionChange = (event: React.MouseEvent<HTMLElement>, newSection: SessionSection) => {
        if (newSection !== null) {
            setSelectedSection(newSection);
        } else {
            setSelectedSection("all");
        }
    };

    return (
        <>
            <div className={cls("flex flex-align-center", classes.header)}>
                <Typography variant="xl" fontWeight="boldest" color="secondary" className={cls("ml-5", classes.title)}>
                    {itemTitle}
                </Typography>
            </div>

            <CloseRoundedIcon data-id="close" className={classes.closeIcon} onClick={onClose} />

            <div className={cls("flex flex-justify-between flex-align-center", classes.tabContainer)}>
                <ToggleButtonGroup value={selectedSection} onChange={handleSectionChange} exclusive>
                    <ToggleButton data-id="all" value={"all"}>
                        View all
                    </ToggleButton>
                    <ToggleButton data-id={SessionSection.upcoming} value={SessionSection.upcoming}>
                        Future
                    </ToggleButton>
                    <ToggleButton data-id={SessionSection.live} value={SessionSection.live}>
                        Live
                    </ToggleButton>
                    <ToggleButton data-id={SessionSection.past} value={SessionSection.past}>
                        Passed
                    </ToggleButton>
                </ToggleButtonGroup>

                {canSeeOthersSessions && <UsageHistoryFiltersButton filter={filter} setFilter={setFilter} />}
            </div>
        </>
    );
};

export default UsageHistoryHeader;
