import {useReactiveVar} from "@workhorse/api/data";
import {memo, useEffect} from "@workhorse/api/rendering";
import singleUseCaseIsAssistant from "./singleUseCaseIsAssistant";

type AssistantDetectorProps = {
    onChange: React.Dispatch<React.SetStateAction<boolean>>;
};

function AssistantDetector(props: AssistantDetectorProps) {
    const isAssistant = useReactiveVar(singleUseCaseIsAssistant);

    useEffect(() => {
        props.onChange((c) => (c !== isAssistant ? isAssistant : c));
    }, [isAssistant]);

    return null;
}

export default memo(AssistantDetector, () => true);
