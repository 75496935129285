import {useRef} from "@workhorse/api/rendering";
import sectionStyles from "../styles/BackgroundEffectsSection.module.scss";
import genericStyles from "../../../styles/GenericSection.module.scss";
import videoStyles from "../../VideoSection/styles/VideoSection.module.scss";
import Typography from "@ui/cdk/Typography/Typography";
import {useMutation} from "@workhorse/api/data";
import {CommitCustomBackgroundDocument} from "@generated/data";
import AddIcon from "@material-ui/icons/Add";
import {query} from "@workhorse/dataApi";
import imageCompression from "browser-image-compression";
import {cls} from "@ui/cdk/util";
import {useTranslation} from "react-i18next";

const compressionOptions = {
    maxSizeMB: 1.2, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
};

const styles = {...genericStyles, ...videoStyles, ...sectionStyles};

type ParticipantBackgroundCustomImageUploadProps = {
    className?: string;
};

const ParticipantBackgroundCustomImageUpload = (props: ParticipantBackgroundCustomImageUploadProps) => {
    const {t} = useTranslation();
    const {className} = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [updateCustomBackgrounds] = useMutation(CommitCustomBackgroundDocument);

    const checkAndUpload = async (files: File[]): Promise<void> => {
        for (let i = 0; i < files.length; i++) {
            const uploadUrl = (await query("GetCustomBackgroundUploadUrlDocument")).data.getCustomBackgroundUploadUrl;

            const file = await imageCompression(files[i], compressionOptions);

            await fetch(uploadUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type ? file.type : "application/octet-stream",
                },
            });

            await updateCustomBackgrounds({
                variables: {
                    uploadUrl,
                },
            });
        }
    };

    const handleClick = async () => {
        if (!inputRef.current) {
            return;
        }

        inputRef.current.onchange = async (_) => {
            if (!inputRef.current) {
                return;
            }

            const files = Array.from(inputRef.current.files ?? []);
            await checkAndUpload(files);
        };

        inputRef.current.click();
    };

    return (
        <div className={cls(styles.filter, className)}>
            <input ref={inputRef} type="file" accept="image/*" hidden multiple />
            <div className={styles.filterSvg} onClick={handleClick}>
                <AddIcon />
            </div>
            <Typography variant="xs" color="blueGray400" className={styles.filterTitle}>
                {t("player.settings.device.background_effects.custom_image")}
            </Typography>
        </div>
    );
};

export default ParticipantBackgroundCustomImageUpload;
