import WarningIcon from "@material-ui/icons/Warning";
import Typography from "@ui/cdk/Typography/Typography";
import classes from "./RecordingWarning.module.scss";

interface Props {
    className?: string;
    title?: string | null;
    description?: string | null;
}

export function SessionOptionWarning({className = "", title, description}: Props) {
    return (
        <div className={`${classes.root} flex flex-row ${className}`}>
            <WarningIcon className="mr-12" />
            <div className="flex flex-col">
                {title && <Typography fontWeight="bold">{title}</Typography>}
                {description && <Typography>{description}</Typography>}
            </div>
        </div>
    );
}
