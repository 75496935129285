import {makeId} from "@workhorse/api/designer/lib/utils";
import {CalendarWorkerData, CalendarWorkerResponse} from "@workhorse/workers/calendar.worker";
import {max, min} from "date-fns";
import CalendarWebWorker from "../../workers/calendar.worker?worker";
import {CalendarPeriod} from "./definitions";

type WorkerEvent = MessageEvent<CalendarWorkerResponse>;

export class CalendarWorker {
    pending: {id: string; period: CalendarPeriod; promise: Promise<CalendarWorkerResponse>}[] = [];
    private worker = new CalendarWebWorker();
    private alreadyExpandedPeriod: CalendarPeriod | null = null;

    expandEvents(eventsData: Omit<CalendarWorkerData, "workId">) {
        const pending = this.pending.find(
            (p) =>
                p.period.startDate.getTime() === eventsData.periodToExpand.startDate.getTime() &&
                p.period.endDate.getTime() === eventsData.periodToExpand.endDate.getTime()
        );

        if (pending) {
            // console.log("Using existing pending promise!");
            return null;
        }

        const workId = makeId();

        const direction =
            this.alreadyExpandedPeriod == null
                ? "both"
                : eventsData.periodToExpand.startDate < this.alreadyExpandedPeriod.startDate &&
                  eventsData.periodToExpand.endDate > this.alreadyExpandedPeriod.endDate
                ? "both"
                : eventsData.periodToExpand.startDate < this.alreadyExpandedPeriod.startDate
                ? "backward"
                : "forward";

        const newPeriodToExpand: CalendarPeriod =
            this.alreadyExpandedPeriod == null
                ? eventsData.periodToExpand
                : eventsData.periodToExpand.startDate.getTime() === this.alreadyExpandedPeriod.startDate.getTime() &&
                  eventsData.periodToExpand.endDate.getTime() === this.alreadyExpandedPeriod.endDate.getTime()
                ? eventsData.periodToExpand
                : direction === "both"
                ? eventsData.periodToExpand
                : {
                      startDate:
                          direction === "backward"
                              ? eventsData.periodToExpand.startDate
                              : max([this.alreadyExpandedPeriod.endDate, eventsData.periodToExpand.startDate]),
                      endDate:
                          direction === "backward"
                              ? min([this.alreadyExpandedPeriod.startDate, eventsData.periodToExpand.endDate])
                              : eventsData.periodToExpand.endDate,
                  };

        // console.log("Required period:", eventsData.periodToExpand);
        // console.log("Existing period:", this.alreadyExpandedPeriod);
        // console.log("New period to expand:", newPeriodToExpand);

        const inputData: CalendarWorkerData = {
            ...eventsData,
            periodToExpand: newPeriodToExpand,
            workId,
        };

        const promise = new Promise<CalendarWorkerResponse>((resolve, reject) => {
            const handleResult = (event: WorkerEvent) => {
                if (event.data.workId !== workId) {
                    // console.log("Ignoring worker result for old workId", event.data.workId);
                    return;
                }

                // Remove pending promise
                this.pending = this.pending.filter((p) => p.id !== workId);

                resolve(event.data);
            };
            this.worker.addEventListener("message", handleResult);
            this.worker.postMessage(inputData);
        });

        this.alreadyExpandedPeriod = this.alreadyExpandedPeriod
            ? {
                  startDate:
                      this.alreadyExpandedPeriod.startDate.getTime() < inputData.periodToExpand.startDate.getTime()
                          ? this.alreadyExpandedPeriod.startDate
                          : inputData.periodToExpand.startDate,
                  endDate:
                      this.alreadyExpandedPeriod.endDate.getTime() > inputData.periodToExpand.endDate.getTime()
                          ? this.alreadyExpandedPeriod.endDate
                          : inputData.periodToExpand.endDate,
              }
            : inputData.periodToExpand;

        this.pending.push({id: workId, period: eventsData.periodToExpand, promise});

        return promise;
    }

    terminate() {
        this.worker.terminate();
    }
}
