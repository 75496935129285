import Fuse from "fuse.js";
import {useMemo, useState, useEffect} from "@workhorse/api/rendering";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {makeCancelable} from "./utils/promise";
import {FuseWorker} from "./fuse-worker";

export type FuseOptions<T> = Fuse.IFuseOptions<T>;

export function useFuse<T>(list: T[], search?: string, options?: Fuse.IFuseOptions<T>, index?: Fuse.FuseIndex<T>) {
    const fuseList = useMemo(() => {
        return {
            list,
            fuse: new Fuse(list, options, index),
        };
    }, [list, options, index]);

    const results = useMemo(() => {
        if (!search) {
            return fuseList.list;
        }
        return fuseList.fuse.search(search).map((result) => result.item);
    }, [fuseList, search]);

    return results;
}

export function useFuseWorker<T>(items: T[], search?: string, options?: Fuse.IFuseOptions<T>) {
    const [id, setId] = useState<string>();
    const [fuse, setFuse] = useState<FuseWorker<T>>();
    const [result, setResult] = useState<Array<Fuse.FuseResult<T>> | null>(null);
    const [searching, setSearching] = useState<boolean>(false);

    const resultMapped = useMemo(() => {
        return result?.map((result) => result.item);
    }, [result]);

    useEffect(() => {
        if (!fuse) {
            return;
        }

        const promise = makeCancelable(fuse.init(items, options));
        promise
            .then(() => setId(makeId()))
            .catch(() => {
                // do nothing
            });

        return () => {
            promise.cancel();
        };
    }, [items, fuse, options]);

    useEffect(() => {
        if (!fuse || !id || !search) {
            setResult(null);
            setSearching(false);
            return;
        }

        setSearching(true);

        const handleResult = (result: Array<Fuse.FuseResult<T>>) => {
            setResult(result);
        };

        const promise = makeCancelable(fuse.search(search));
        promise
            .then(handleResult)
            .catch(() => {
                // do nothing
            })
            .finally(() => {
                setSearching(false);
            });

        return () => {
            promise.cancel();
        };
    }, [id, search, fuse]);

    useEffect(() => {
        const fuse = new FuseWorker<T>();
        setFuse(fuse);

        return () => {
            fuse.terminate();
        };
    }, []);

    if (!search || !result) {
        return [items, false, []] as [T[], boolean, Array<Fuse.FuseResult<T>>];
    }

    return [resultMapped, searching, result] as [T[], boolean, Array<Fuse.FuseResult<T>>];
}
