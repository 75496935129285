import {useState} from "react";
import Typography from "@ui/cdk/Typography";
import ColorPicker from "@workhorse/pages/user/profile/Theming/Components/ColorPicker";
import {capitalize} from "@ui/cdk/util/util";
import {baseColors} from "../../../../pages/user/profile/Theming/utils";
import {RGBColor} from "react-color";
import classes from "./style/EventsLandingPageImagesUpload.module.scss";
import {cls} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {useSession} from "@workhorse/providers/SessionDataProviders";

type EventsLandingPageColorPickersProps = {
    readOnly: boolean;
    classes?: {
        fakeSelect?: string;
    };
    preventCommit?: boolean;
};

const EventsLandingPageColorPickers = (props: EventsLandingPageColorPickersProps) => {
    const {readOnly} = props;
    const {event} = useSession();

    const basePalette = event?.colorPalette;
    const onChangePaletteColor = (color: RGBColor, colorName: string) => {
        designer.api.event.update({
            colorPalette: {...basePalette, [colorName]: color},
        });
        if (props.preventCommit) {
            return;
        }
        designer.commit();
    };

    return (
        <div className={classes.configuration}>
            <div className={cls("mt-40 flex flex-justify-between", classes.title)}>
                <Typography component="div" color="blueGray400" variant="lg">
                    Colors
                </Typography>
            </div>
            {Object.keys(basePalette ?? {}).map((key, index) => (
                <div
                    key={`event-color-picker-${index}`}
                    className={index < Object.keys(basePalette ?? {}).length - 1 ? "mb-16 w-100" : undefined}
                >
                    <Typography variant="sm" color="secondary" fontWeight="bold">
                        {capitalize(key)} color
                    </Typography>
                    <div data-id="event-button-primary">
                        <ColorPicker
                            onChangeColor={onChangePaletteColor}
                            colorName={key}
                            readOnly={readOnly}
                            color={basePalette[key]}
                            key={key}
                            logo={event?.photoUrl ?? ""}
                            limitBrightness
                            classes={props.classes}
                        ></ColorPicker>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EventsLandingPageColorPickers;
