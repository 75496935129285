import {useUserInfo} from "@workhorse/providers/User";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";

import * as limitations from "@sessions/common/subscription-limitations";
import clientEvents from "@api/events/client";
import {OrganizationPlan} from "@generated/data";
import {useMemo} from "react";

type UseFeatureGuard = {
    feature: LimitedFeature;
    value?: number;
    plan?: OrganizationPlan;
};

export const isFeatureAvailable = (feature: LimitedFeature, plan: OrganizationPlan, value?: number) => {
    return value ? value < limitations.limitationsByFeatures(feature)[plan] : limitations.limitationsByFeatures(feature)[plan];
};

const useFeatureInfo = (props: UseFeatureGuard) => {
    const {activeOrganizationPermission} = useUserInfo();
    const organizationPlan = props.plan ?? activeOrganizationPermission.organization.plan;

    const emit = (message: string | {key: string; options: Record<string, string | number | boolean>}) => {
        clientEvents.emit("feature-limit-error", {
            feature: props.feature,
            message: message,
        });
    };

    const featureIsAvailable = props.value
        ? props.value < limitations.limitationsByFeatures(props.feature)[organizationPlan]
        : limitations.limitationsByFeatures(props.feature)[organizationPlan];

    const checkFeatureLimit = (feature: LimitedFeature, value?: number) => {
        return isFeatureAvailable(feature, organizationPlan, value);
    };

    return useMemo(
        () => ({featureIsAvailable, emitFeatureLimitError: emit, checkFeatureLimit}),
        [featureIsAvailable, emit, checkFeatureLimit]
    );
};

export default useFeatureInfo;
