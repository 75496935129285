import {cls} from "@ui/cdk/util";
import MacroHeader from "@workhorse/components/header/MacroHeader";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useTranslation} from "react-i18next";
import classes from "./styles/TimerContainer.module.scss";
import TimerBody from "./TimerBody";

type TimerContainerProps = {
    isMemoryMode: boolean;
    initialDuration?: number;
    timerStartAt?: Date;
};

function TimerContainer(props: TimerContainerProps) {
    const {isMemoryMode, initialDuration, timerStartAt} = props;

    const {t} = useTranslation();

    const {isMobile} = useMobile();

    return (
        <div className={cls("flex flex-col", classes.root)} role="region" aria-label={t("macro.timer.aria.timer_panel") ?? "Timer panel"}>
            {!isMobile && (
                <MacroHeader
                    marginLess
                    playerRadius
                    title={t("macro.timer.title") ?? "Timer"}
                    artifactId={"timer"}
                    isMemoryMode={isMemoryMode}
                    key="timer-container-header"
                    hideMacroStateMenu
                />
            )}

            <TimerBody key={`timer-body-${timerStartAt}-${initialDuration}`} />
        </div>
    );
}

export default TimerContainer;
