import {emailRegex} from "@common/validation/utils";
import {ContactGroupInfoFragment, ContactInfoFragment, SpeakerDetailsFragment} from "@generated/data";
import TagInput from "@ui/cdk/TagInput/TagInput";
import {tagsDelimiters} from "@workhorse/util";
import {FocusEventHandler, KeyboardEventHandler} from "react";
import {Tag} from "./ContactFetcher";

interface Props {
    item: Tag;
    contactMap: Record<string, ContactInfoFragment | undefined>;
    groupMap: Record<string, ContactGroupInfoFragment | undefined>;
    speakerMap: Record<string, SpeakerDetailsFragment | undefined>;
    onRemove?: () => void;
    onUpdate?: (value: string) => void;
    icon?: JSX.Element;
    disabled?: boolean;
}

export function ContactFetcherTag(props: Props) {
    const itemType = props.item.type;
    const itemValue = props.item.value;
    const canEdit = itemType === "email";

    let value = itemValue;
    let error = false;

    if (itemType === "contact") {
        const contact = props.contactMap[itemValue];
        const values = [contact?.firstName, contact?.lastName].filter((name) => !!name);
        value = values.length ? values.join(" ") : contact?.email ?? "";
    }

    if (itemType === "group") {
        const group = props.groupMap[itemValue];
        value = group?.name || "";
    }

    if (itemType === "email") {
        error = !emailRegex.test(itemValue);
    }

    if (itemType === "speaker") {
        const speaker = props.speakerMap[itemValue];
        if (speaker?.name) {
            value = speaker?.name;
        } else if (speaker?.email) {
            value = speaker.email;
        } else {
            value = "Speaker";
        }
    }

    const handleUpdate = (value: string) => {
        if (!value) {
            props.onRemove?.();
            return;
        }

        props.onUpdate?.(value);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        if (!canEdit) {
            return;
        }
        handleUpdate(e.currentTarget.value);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (!canEdit) {
            return;
        }
        if (e.key === "Enter") {
            handleUpdate(e.currentTarget.value);
        }
    };

    // TODO: @maca - check this in all usages
    return value ? (
        <TagInput
            defaultValue={value}
            delimiters={tagsDelimiters}
            color="blueGray"
            onRemove={props.disabled ? undefined : props.onRemove}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            error={error}
            editable={canEdit}
            avatar={props.icon}
        />
    ) : null;
}
