import {UtmCookies} from "@generated/data";
import Cookies from "js-cookie";

export const getUserUtmCookies = () => {
    const utmCookies: UtmCookies = {};

    if (Cookies.get("utm_campaign")) {
        utmCookies.utmCampaign = Cookies.get("utm_campaign");
        Cookies.remove("utm_campaign", {
            domain: ".sessions.us",
            path: "/",
        });
    }
    if (Cookies.get("utm_medium")) {
        utmCookies.utmMedium = Cookies.get("utm_medium");
        Cookies.remove("utm_medium", {
            domain: ".sessions.us",
            path: "/",
        });
    }
    if (Cookies.get("utm_source")) {
        utmCookies.utmSource = Cookies.get("utm_source");
        Cookies.remove("utm_source", {
            domain: ".sessions.us",
            path: "/",
        });
    }
    if (Cookies.get("utm_timestamp")) {
        utmCookies.utmTimestamp = Cookies.get("utm_timestamp");
        Cookies.remove("utm_timestamp", {
            domain: ".sessions.us",
            path: "/",
        });
    }
    if (Cookies.get("website_location")) {
        utmCookies.websiteLocation = Cookies.get("website_location");
        Cookies.remove("website_location", {
            domain: ".sessions.us",
            path: "/",
        });
    }

    return utmCookies;
};

export const getPreviousGuestSession = () => {
    const previousSessionId = Cookies.get("previous_guest_session");
    const previousParticipantId = Cookies.get("previous_participant_id");
    if (previousSessionId && previousParticipantId) {
        Cookies.remove("previous_guest_session", {path: "/"});
        Cookies.remove("previous_participant_id", {path: "/"});

        return {previousSessionId, previousParticipantId};
    }

    return null;
};

export const getCelloUniqueCampaignCode = (): string | undefined => {
    const celloUniqueCampaignCode = Cookies.get("cello-referral");
    if (celloUniqueCampaignCode) {
        Cookies.remove("ucc", {path: "/"});
        return celloUniqueCampaignCode;
    }

    return undefined;
};

export const getRewardfulReferral = (): string | undefined => {
    const rewardfulReferral = Cookies.get("rewardful.referral");
    if (rewardfulReferral) {
        Cookies.remove("rewardful.referral", {path: "/"});
        return rewardfulReferral;
    }

    return undefined;
};

export const setSessionGuestUtmCookies = (sessionId?: string | null) => {
    Cookies.set("utm_source", "sessionGuest", {
        expires: 1,
        domain: `.${window.location.hostname}`,
    });
    if (sessionId) {
        Cookies.set("utm_campaign", sessionId, {
            expires: 1,
            domain: `.${window.location.hostname}`,
        });
    }
};

export const setBookingGuestUtmCookies = (bookingEventId?: string | null) => {
    Cookies.set("utm_source", "bookingGuest", {
        expires: 1,
        domain: `.${window.location.hostname}`,
    });
    if (bookingEventId) {
        const campaign = bookingEventId.startsWith("p-") ? bookingEventId.substring(2) : bookingEventId;
        Cookies.set("utm_campaign", campaign, {
            expires: 1,
            domain: `.${window.location.hostname}`,
        });
    }
};
