import {useMutation, useQuery} from "@apollo/client";
import {emailRegex} from "@common/validation/utils";
import {
    AddOrganizationMemberDocument,
    EditOrganizationMembersDocument,
    GetOrganizationMembersDocument,
    GetRemoteUserDocument,
    MemberFragment,
    OrganizationPermissionType,
    SetMemberWorkspaceRoleDocument,
    UserInviteStatus,
    WorkspaceRolesDocument,
} from "@generated/data";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import FormikFieldWrapper from "@ui/cdk/FormikFieldWrapper";
import Input from "@ui/cdk/Input";
import Link from "@ui/cdk/Link";
import Select from "@ui/cdk/Select";
import Tooltip from "@ui/cdk/Tooltip";
import {ExtractProps} from "@ui/cdk/typings/global";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {ApolloOperationContext} from "@workhorse/api/apollo";
import {getResourcesBaseLink} from "@workhorse/api/help/article";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {useUserInfo} from "@workhorse/providers/User";
import {HyperlinkExternal} from "@workhorse/util/links";
import emojiStrip from "emoji-strip";
import {Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {OrganizationUserManagementRoleCheckbox} from "../OrganizationUserManagementRole";
import {UserManagementTableType} from "../UserManagementTable";
import {memberFormSchema} from "../utils";
import {WorkspaceUserManagementRoleSelect} from "../WorkspaceUserManagementRole";
import classes from "./MemberEditDialog.module.scss";
import {toastErrorMessage} from "./utils";

type Props = Omit<ExtractProps<typeof Dialog>, "onSubmit" | "onClose" | "imperativeRef"> & {
    open: boolean;
    member?: Partial<MemberFragment>;
    onClose?: () => void;
    onSubmit?: () => void;
    onCsvImport?: () => void;
    type: UserManagementTableType;
};

type MemberFormProps = {
    email: string;
    firstName: string;
    lastName: string;
    department?: string;
    country?: string;
    workspaceIds: string[];
};

const NAME_MAX_LENGTH = 60;
const EMAIL_MAX_LENGTH = 200;

const validationSchema = Yup.object().shape({
    email: Yup.string().email().max(EMAIL_MAX_LENGTH, `Needs to have a maximum of ${EMAIL_MAX_LENGTH} characters`),
    firstName: Yup.string()
        .trim()
        .max(NAME_MAX_LENGTH, `Needs to have a maximum of ${NAME_MAX_LENGTH} characters`)
        .test("is-valid", "The name cannot contain special characters", (value) => {
            return !value?.includes("%");
        }),
    lastName: Yup.string()
        .trim()
        .max(NAME_MAX_LENGTH, `Needs to have a maximum of ${NAME_MAX_LENGTH} characters`)
        .test("is-valid", "The name cannot contain special characters", (value) => {
            return !value?.includes("%");
        }),
});

export function MemberEditDialog(props: Props) {
    const {member, open, onClose, onSubmit, ...dialogProps} = props;

    const dialogRef = useRef<DialogImperativeRef>(null);

    const [emailError, setEmailError] = useState<string | null>(null);
    const [emailExists, setEmailExists] = useState(false);

    const user = useUserInfo();
    const {mixpanelTrack} = useMixpanelTracker();
    const workspaceId = user.activeWorkspace.id;

    useEffect(() => {
        dialogRef.current?.toggle?.(open);
    }, [open]);

    const isCreateMode = !member?.id;
    const memberCurrentWorkspace = member?.workspacePermissions?.find((p) => p.workspace.id === workspaceId);
    const isMemberActiveOrSuspended =
        memberCurrentWorkspace?.inviteStatus === UserInviteStatus.Active || member?.organizationPermissions?.[0]?.suspended;

    const formRef = useRef<FormikProps<MemberFormProps>>(null);

    const {data, error, loading} = useQuery(WorkspaceRolesDocument, {
        variables: {
            workspaceId,
        },
        onCompleted: (data) => {
            if (data?.workspace?.roles.find((role) => role.name === "MEMBER")) {
                if (!selectedRole) {
                    setSelectedRole(data?.workspace?.roles.find((role) => role.name === "MEMBER")?.id);
                }
            }
        },
    });

    const workspaces = member?.workspacePermissions?.map((w) => w.workspace);

    const defaultRole =
        props.member?.workspacePermissions?.find((p) => p.workspace.id === user.activeWorkspace?.id)?.role.id ??
        data?.workspace?.roles.find((role) => role.name === "MEMBER")?.id;
    const [selectedRole, setSelectedRole] = useState<string | undefined>(defaultRole);
    const [selectedOrgRole, setSelectedOrgRole] = useState<OrganizationPermissionType>(
        member?.organizationPermissions?.[0].permissionType ?? OrganizationPermissionType.Member
    );

    const isMemberOrganizationOwner =
        member?.organizationPermissions?.[0].permissionType === OrganizationPermissionType.Owner ||
        selectedOrgRole === OrganizationPermissionType.Owner;

    const [addMember] = useMutation(AddOrganizationMemberDocument);
    const [editMembers] = useMutation(EditOrganizationMembersDocument);
    const [setRole] = useMutation(SetMemberWorkspaceRoleDocument);

    const [disabled, setDisabled] = useState(false);

    const [timeoutNotify, setTimeoutNotify] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [confirmMembersError, setConfirmMembersError] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const createMember = async (values: MemberFormProps, context?: ApolloOperationContext) => {
        const res = await addMember({
            variables: {
                memberToAdd: {
                    country: values.country,
                    email: values.email,
                    department: values.department,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    sendInviteEmail: true,
                    workspaceIds: values.workspaceIds,
                },
            },
            update: (cache, {data}) => {
                if (!data?.addMemberToOrganization.newMember) {
                    return;
                }

                const existingQuery = cache.readQuery({
                    query: GetOrganizationMembersDocument,
                    variables: {
                        organizationId: user.activeOrganizationPermission.organization.id,
                        excludeSelf: false,
                    },
                });

                if (!existingQuery?.organization) {
                    return;
                }

                cache.writeQuery({
                    query: GetOrganizationMembersDocument,
                    variables: {
                        organizationId: user.activeOrganizationPermission.organization.id,
                        excludeSelf: false,
                    },
                    data: {
                        ...existingQuery,
                        organization: {
                            ...existingQuery.organization,
                            members: [data.addMemberToOrganization.newMember, ...existingQuery.organization.members],
                        },
                    },
                });
            },
            context,
        });

        const duplicatedEmail = res.data?.addMemberToOrganization?.duplicatedEmail;

        if (duplicatedEmail === true) {
            setEmailExists(true);
            toast(`This member has already been added to a workspace`, {type: "error"});
            return;
        }

        if (!res.data) {
            toast(`Error adding member!`, {type: "error"});
            return;
        }

        toast(`Member successfully added`, {type: "success"});

        return res.data?.addMemberToOrganization.newMember;
    };

    const updateMember = async (id: string, values: MemberFormProps, context?: ApolloOperationContext) => {
        const res = await editMembers({
            variables: {
                membersToEdit: [
                    {
                        userId: id,
                        country: values.country,
                        email: values.email,
                        department: values.department,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        workspaceIds: values.workspaceIds,
                        sendInviteEmail: false,
                    },
                ],
            },
            context,
            refetchQueries: [
                {
                    query: GetRemoteUserDocument,
                },
            ],
        });

        toastErrorMessage(res.data?.editOrganizationMembers?.errorMessage);

        toast(`Member successfully updated`, {type: "success"});

        return res.data?.editOrganizationMembers.updatedMembers?.[0];
    };

    const handleSubmit = async (values: MemberFormProps) => {
        setDisabled(true);
        try {
            // if the orgRole is owner, the user gets added automatically in backend in every workspace.
            const workspaceIds = isMemberOrganizationOwner ? [user.workspacePermissions[0].workspace.id] : values.workspaceIds;

            const normalizedValues: MemberFormProps = {
                ...values,
                email: values.email.trim().toLowerCase(),
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                department: values.department?.trim(),
                workspaceIds: workspaceIds,
            };

            let result: void | MemberFragment | undefined | null;

            if (member?.id) {
                result = await updateMember(member.id, normalizedValues, {
                    notifyAfterMs: 5000,
                    shouldRetry: false,
                    abortAfterMs: 35000,
                    notifyCb: (failed, retryAttemptNo) => {
                        setTimeoutNotify((c) => (c !== failed ? failed : c));
                    },
                    timedOutCb: (failed, retryAttemptNo) => {
                        if (!failed) {
                            setConfirmMembersError(false);
                        }
                        setTimedOut((c) => (c !== failed ? failed : c));
                    },
                }).catch((error) => {
                    setConfirmMembersError(true);
                    console.log("error on confirm update members", error);
                });
            } else {
                result = await createMember(normalizedValues, {
                    notifyAfterMs: 5000,
                    shouldRetry: false,
                    abortAfterMs: 35000,
                    notifyCb: (failed, retryAttemptNo) => {
                        setTimeoutNotify((c) => (c !== failed ? failed : c));
                    },
                    timedOutCb: (failed, retryAttemptNo) => {
                        if (!failed) {
                            setConfirmMembersError(false);
                        }
                        setTimedOut((c) => (c !== failed ? failed : c));
                    },
                }).catch((error) => {
                    setConfirmMembersError(true);
                    console.log("error on confirm create members", error);
                });
            }

            if (!result) {
                props.onClose?.();
                return;
            }

            if (props.type === "workspace") {
                const role = result.workspacePermissions[0].role.id;

                if (role === selectedRole || !selectedRole) {
                    props.onClose?.();
                    return;
                }

                await setRole({
                    variables: {
                        userId: result.id,
                        newRoleId: selectedRole!,
                    },
                });
            } else {
                const role = result.organizationPermissions[0].permissionType;
                if (role === selectedOrgRole || !selectedOrgRole) {
                    props.onClose?.();
                    return;
                }

                editMembers({
                    variables: {
                        membersToEdit: [{userId: result.id, role: selectedOrgRole, sendInviteEmail: false}],
                    },
                });
            }

            props.onClose?.();
        } catch (e) {
            toast(e.message, {
                type: "error",
            });
        }
        setDisabled(false);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailExists(false);
        formRef.current?.setFieldValue("email", emojiStrip(event.target.value));
        if (emailError) {
            setEmailError(null);
        }
    };

    const handleCountryChange = (value: string | undefined) => {
        formRef.current?.setFieldValue("country", emojiStrip(value ?? ""));
    };

    const checkFormIsValid = () => {
        const regex = emailRegex.test(formRef.current?.values.email ?? "");
        return !isMemberActiveOrSuspended ? regex : regex && formRef.current?.values.firstName && formRef.current?.values.lastName;
    };

    const btnDisabled = confirmMembersError || timedOut ? false : disabled || timeoutNotify;

    const tooltipMsg = confirmMembersError || timedOut ? "Could not confirm the actions due to a network failure. Please try again." : "";

    const disableTooltip = !(confirmMembersError || timedOut);

    const onConfirmMembersTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const onConfirmMembersTooltipClose = () => {
        setTooltipOpen(false);
    };

    const workspaceIdsOptions = user.workspacePermissions.map((w) => ({
        value: w.workspace.id,
        text: w.workspace.name,
    }));

    return (
        <Dialog
            {...dialogProps}
            imperativeRef={dialogRef}
            onClose={onClose}
            BackdropProps={{
                className: "standard-backdrop",
            }}
            classes={{
                container: "standard-dialog-container",
            }}
            PaperProps={{
                className: cls("standard-dialog-paper", classes.dialogPaper),
            }}
        >
            <div className={classes.header}>
                <Typography>{isCreateMode ? "Add member" : "Edit member"}</Typography>
            </div>
            <div>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        firstName: member?.firstName ?? "",
                        lastName: member?.lastName ?? "",
                        email: member?.email ?? "",
                        department: member?.department ?? "",
                        country: member?.country ?? "",
                        workspaceIds: workspaces?.map((w) => w.id) ?? [user.activeWorkspace?.id] ?? [],
                    }}
                    validateOnChange
                    validateOnBlur
                    //TO DO: this
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, touched, errors, setFieldValue}) => {
                        return (
                            <Form>
                                <div className={classes.formContent}>
                                    <div className={classes.field}>
                                        <FormikFieldWrapper
                                            name={"email"}
                                            as={Input}
                                            value={values.email}
                                            label={"Email"}
                                            required={true}
                                            maxCharCount={200}
                                            showTypedCharCount={true}
                                            error={!!errors.email && touched.email}
                                            helperText={errors.email}
                                            placeholder={"Email"}
                                            disabled={memberCurrentWorkspace?.inviteStatus === UserInviteStatus.Active}
                                            onChange={handleEmailChange}
                                        />
                                        {emailExists && (
                                            <Typography variant="sm" className={classes.required}>
                                                Email already exists!
                                            </Typography>
                                        )}
                                    </div>

                                    {memberFormSchema.fields.map((field, index) => (
                                        <div key={index} className={classes.field}>
                                            <FormikFieldWrapper
                                                key={field.name}
                                                name={field.name}
                                                as={Input}
                                                value={values[field.name]}
                                                label={field.title}
                                                required={field.required || isMemberActiveOrSuspended}
                                                maxCharCount={60}
                                                showTypedCharCount={true}
                                                error={!!errors[field.name] && touched[field.name]}
                                                helperText={errors[field.name]}
                                                placeholder={field.placeholder}
                                            />
                                        </div>
                                    ))}

                                    <div className="flex fullw flex-justify-between">
                                        {props.type === "workspace" ? (
                                            <div className={cls(classes.field, classes.halfSize, "mt-3-important")}>
                                                <div className="mt-8 flex flex-align-center">
                                                    <Typography color="secondary" variant="sm" fontWeight="bold">
                                                        Organization role
                                                    </Typography>
                                                    <Tooltip
                                                        title={
                                                            <div className="flex flex-col pb-8">
                                                                <Typography className="mb-8">
                                                                    You can set up custom roles that suit your organization’s needs under
                                                                    Workspace Settings → Member Roles.
                                                                </Typography>

                                                                <HyperlinkExternal
                                                                    href={getResourcesBaseLink(
                                                                        "resources/help-center/organizations/workspace"
                                                                    )}
                                                                    target="_blank"
                                                                    key="workspace-article-url"
                                                                    rel="noopener noreferrer"
                                                                    className={classes.tooltipLink}
                                                                >
                                                                    Learn more
                                                                </HyperlinkExternal>
                                                            </div>
                                                        }
                                                        arrow
                                                        interactive
                                                        placement="top-start"
                                                    >
                                                        <InfoOutlinedIcon className={classes.tooltipIcon} />
                                                    </Tooltip>
                                                </div>
                                                <WorkspaceUserManagementRoleSelect
                                                    className="fullw"
                                                    large={true}
                                                    workspaceId={workspaceId}
                                                    value={selectedRole}
                                                    onChange={setSelectedRole}
                                                />
                                            </div>
                                        ) : (
                                            <OrganizationUserManagementRoleCheckbox
                                                className="fullw"
                                                value={selectedOrgRole}
                                                onChange={setSelectedOrgRole}
                                            />
                                        )}
                                        {/* <div className={cls(classes.field, classes.halfSize, "mt-3-important")}>
                                            <Typography className="mt-8" color="secondary" variant="sm" fontWeight="bold">
                                                Country
                                            </Typography>
                                            <CountrySelect variant="small" value={values.country} onChange={handleCountryChange} />
                                        </div> */}
                                    </div>

                                    <Typography className="mt-8" color="secondary" variant="sm" fontWeight="bold">
                                        Workspaces
                                    </Typography>
                                    <Tooltip
                                        title={
                                            isMemberOrganizationOwner ? "Organization owners are by default part of every workspace." : ""
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        <Select
                                            name="workspaces"
                                            data-id="workspaces-select"
                                            options={workspaceIdsOptions}
                                            menuItemProps={{
                                                classes: {
                                                    root: classes.menuItem,
                                                    selected: classes.menuItemSelected,
                                                },
                                            }}
                                            value={values.workspaceIds}
                                            onChange={(e, val) => {
                                                setFieldValue("workspaceIds", e.target.value);
                                            }}
                                            variant="standard"
                                            multiple
                                            displayEmpty
                                            disableUnderline
                                            placeholder="Select workspaces"
                                            renderValue={(value: string[]) => {
                                                if (value.length) {
                                                    return (
                                                        <p>
                                                            {value
                                                                .map(
                                                                    (item) =>
                                                                        (workspaceIdsOptions ?? []).find((option) => option.value === item)
                                                                            ?.text
                                                                )
                                                                .join(", ")}
                                                        </p>
                                                    );
                                                } else {
                                                    return <div className={classes.selectPlaceholder}>Select workspaces</div>;
                                                }
                                            }}
                                            withCheckboxes
                                            disabled={isMemberOrganizationOwner}
                                            classes={{
                                                root: classes.workspaceSelect,
                                            }}
                                            wrapperClassName={cls(
                                                values.workspaceIds.length === 0 && classes.workspaceSelectContainerWithError,
                                                classes.workspaceSelectContainer
                                            )}
                                        />
                                    </Tooltip>
                                    {values.workspaceIds.length === 0 ? (
                                        <Typography variant="sm" className={cls("mb-6", classes.required)}>
                                            You have to select at least one workspace.
                                        </Typography>
                                    ) : null}

                                    {isCreateMode && props.onCsvImport && (
                                        <div className="flex flex-align-center">
                                            <Typography className="p-8">Want to import multiple members? </Typography>
                                            <Link
                                                data-id="multiple-import"
                                                className={classes.exampleLink}
                                                onClick={() => {
                                                    props.onClose?.();
                                                    props.onCsvImport?.();
                                                    mixpanelTrack("frontend-import-members", "organization");
                                                }}
                                            >
                                                Click here.
                                            </Link>
                                        </div>
                                    )}
                                </div>

                                <div className={classes.buttons}>
                                    <Button variant="quaternary" onClick={props.onClose}>
                                        Cancel
                                    </Button>
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={values.workspaceIds.length === 0 ? "Please select at least one workspace." : tooltipMsg}
                                        open={confirmMembersError || timedOut || tooltipOpen}
                                        onOpen={onConfirmMembersTooltipOpen}
                                        onClose={onConfirmMembersTooltipClose}
                                        disableFocusListener={disableTooltip}
                                        disableHoverListener={disableTooltip}
                                        disableTouchListener={disableTooltip}
                                    >
                                        <Button
                                            data-id="confirm"
                                            variant="primary"
                                            type="submit"
                                            withMarginLeft
                                            disabled={btnDisabled || !checkFormIsValid() || emailExists || values.workspaceIds.length === 0}
                                            loading={btnDisabled}
                                        >
                                            Confirm
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Dialog>
    );
}
