import {cls} from "@ui/cdk/util/util";
import {Children, cloneElement, ReactElement} from "react";
import {forwardRef} from "react";
import Avatar from "../Avatar";
import styles from "./style/avatarGroup.module.scss";
import {AvatarGroupProps} from "./types";

function AvatarGroupWithRef(props: AvatarGroupProps, ref?: any) {
    const {
        /**
         * @param {Node[]} component Component type. @default "div"
         */
        children,
        /**
         * @param {AvatarVariantTypes} variant The variant of the avatar component. @default "circular"
         */
        avatarVariant = "circular",
        /**
         * @param {AvatarSizeTypes} size The size of the component. @default "M"
         */
        avatarSize = "XL",
        /**
         * @param {Number} max The number of displayed avatars. The rest will be hidden. @default 3
         */
        max = 5,
        /**
         * @param {Number} spacing The spacing between avatars in pixels. @default 16
         */
        spacing = 20,
        /**
         * @param {Boolean} reversed if `true` the avatars will be displayed with the first one on top, instead of the last. @default false
         */
        reversed = false,
        /**
         * @param {Boolean} className Makes the border of the Avatar white. @default false
         */
        withWhiteBorder = false,
        /**
         * @param {String} className Adds a className to the root of the component.
         */
        className,
        /**
         * @param {String} classes ClassNames for sub-components. Can be: `extraAvatar`.
         */
        classes,
        /**
         * @param {React.ReactNode} customExtraAvatarsComponent Component to replace the `extraAvatar` indicator that shows when the number of items is greater then the max.
         */
        customExtraAvatarsComponent,
        ...rest
    } = props;

    const classNames = cls("fosAvatarGroup", styles.root, className);

    const extraAvatars = children.length > max ? children.length - max + 1 : 0;

    const childrens = children.slice(0, children.length - extraAvatars);

    return (
        <div className={classNames} ref={ref} {...rest} key="avatar-group">
            {Children.map(childrens, (child, i) =>
                cloneElement(child as ReactElement<any>, {
                    style: {
                        marginLeft: i > 0 ? spacing * -1 : 0,
                        zIndex: reversed ? childrens.length - i : i + 1,
                        ...(child as ReactElement<any>).props.style,
                    },
                    // withWhiteBorder,
                    key: `avatarGroup-${i}`,
                    "data-id": "participant-card",
                })
            )}
            {extraAvatars
                ? customExtraAvatarsComponent ?? (
                      <Avatar
                          size={avatarSize}
                          variant={avatarVariant}
                          style={{marginLeft: spacing * -1, zIndex: childrens.length + 1}}
                          className={cls(styles.avatarExtra, "avatarExtra", classes?.extraAvatar)}
                          withWhiteBorder={withWhiteBorder}
                          key="avatarGroup-extra"
                      >
                          +{extraAvatars}
                      </Avatar>
                  )
                : null}
        </div>
    );
}

const AvatarGroup = forwardRef(AvatarGroupWithRef) as (props: AvatarGroupProps & {ref?: any}) => ReturnType<typeof AvatarGroupWithRef>;
export {AvatarGroupWithRef};

export default AvatarGroup;
