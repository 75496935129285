import {useEffect, useState} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";
import {createPortal} from "react-dom";

export function BreakoutMenuPortal({children}: WithChildren) {
    const [portal, setPortal] = useState<HTMLElement | null>(null);

    useEffect(() => {
        const element = document.createElement("div");
        document.body.appendChild(element);
        setPortal(element);
        return () => {
            element.remove();
        };
    }, []);

    if (!portal) {
        return null;
    }

    return createPortal(<div>{children}</div>, portal);
}
