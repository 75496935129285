import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {ReactComponent as AddSpeaker} from "../../../../assets/media/add-speaker2.svg";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

type MySpeakersTablePlaceholderProps = {
    hasSpeakers: boolean;
    openNewSpeaker: () => void;
};

function MySpeakersTablePlaceholder(props: MySpeakersTablePlaceholderProps) {
    const {hasSpeakers, openNewSpeaker} = props;

    const title = hasSpeakers ? "No speakers found" : "Add some speakers to your event";
    const subtitle = hasSpeakers
        ? "No speakers were found that match your search criteria"
        : "You can add your event collaborators as speakers or invite someone else";

    return (
        <div className={"fullw flex flex-col flex11-auto flex-center-all py-32 px-24"}>
            <AddSpeaker className="mb-16" />
            <Typography variant="lg" fontWeight="bold" color="secondary" className="mb-8">
                {title}
            </Typography>
            <Typography color="tertiary">{subtitle}</Typography>
            {hasSpeakers ? null : (
                <Button size="small" variant="tertiary" onClick={openNewSpeaker} data-id="event-button-add-speakers" className="mt-16">
                    <AddRoundedIcon className="mr-4" />{" "}
                    <Typography component="span" fontWeight="bolder">
                        New speaker
                    </Typography>
                </Button>
            )}
        </div>
    );
}

export default MySpeakersTablePlaceholder;
