import {HostType} from "@generated/data";
import {useEffect} from "@workhorse/api/rendering";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";

const allowedHostTypes = [HostType.Player, HostType.Feedback, HostType.Book, HostType.Bookings];

const UILanguageUpdater = () => {
    const {i18n} = useTranslation();
    const hostType = useHostType();

    const {language} = useUserInfo();

    let lang: string | undefined = language;
    if (lang === undefined) {
        lang = localStorage.getItem("user-language") ?? "en";
    }

    useEffect(() => {
        if (allowedHostTypes.includes(hostType)) {
            if (lang && i18n.language !== lang) {
                i18n.changeLanguage(lang);
            }
        } else {
            if (i18n.language !== "en") {
                i18n.changeLanguage("en");
            }
        }

        return () => {
            if (i18n.language !== "en") {
                i18n.changeLanguage("en");
            }
        };
    }, [i18n.language, hostType, i18n, language]);

    return null;
};

export default UILanguageUpdater;
