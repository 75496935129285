import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import {cls} from "@ui/cdk/util/util";
import {ReactNode, useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {useAudienceScrollMobile} from "./hooks/useAudienceScrollMobile";
import classes from "./style/ConferenceAudience.module.scss";

interface Props {
    children: ReactNode;
    isGrid: boolean;
    numberOfItems: number;
    onMount?: (node: HTMLDivElement | null) => void;
}

function getItemSize(wrapperWidth: number) {
    return (wrapperWidth - 30) / 3;
}

export function ConferenceAudienceScrollMobile({onMount, isGrid, children, numberOfItems}: Props) {
    const [root] = useState<HTMLDivElement | null>(() => document.querySelector(":root"));
    const [wrapperWidth, setWidth] = useState(0);

    const {setScroll} = useAudienceScrollMobile({
        isGrid,
        itemWidth: isGrid ? getItemSize(wrapperWidth) : 48,
        itemHeight: 75,
        spacing: 4,
        wrapperWidth,
    });

    const onResize = useCallback((width: number) => {
        setWidth(width);
    }, []);
    const [setWrapperNode] = useLocalResizeObserver(onResize);

    useEffect(() => {
        root?.style.setProperty("--app-participant-mobile-width", `${getItemSize(wrapperWidth)}px`);
    }, [root, wrapperWidth]);

    return (
        <div className={classes.mobileRoot}>
            <div ref={setWrapperNode} className={cls(classes.root)}>
                <div ref={onMount} className={isGrid ? classes.mobileGrid : classes.mobileContainer}>
                    <div
                        ref={setScroll}
                        className={cls(
                            isGrid ? classes.scrollableGrid : cls(classes.scrollable, numberOfItems < 5 && classes.countLessThan5)
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
