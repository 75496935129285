import {getLocalTimezone} from "@common/utils";
import type {BookView} from "@workhorse/api/booking/definitions";
import {BookingViewProvider, useBookingView} from "@workhorse/api/booking/providers";
import loadable from "@workhorse/api/loadable";
import {BookRouteMatch, RouteHOCProps} from "@workhorse/declarations";
import {useEffect} from "react";
import {Route, useLocation} from "react-router-dom";
import {useQuery} from "@workhorse/api/data";
import {GetPublicBookingEventDocument, PublicBookingEvent, PublicBookingEventFragment} from "@generated/data";
import Loading from "@workhorse/components/Loading";
// there is no guarantee the user will land on the Book every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const Book = loadable(() => import("./Book"));
const BookSuccessful = loadable(() => import("./BookingSuccessful"));

type BookHOCProps = RouteHOCProps<BookRouteMatch>;

/**
 * Get Booking view for embedding (page, component, widget)
 */
export const getView = (search: string): {view: BookView; nonce: string; preview: boolean} => {
    const query = new URLSearchParams(search);
    const preview = query.get("preview") === "true";
    const nonce = query.get("nonce") ?? "";

    let view: BookView =
        (["page", "component", "widget"].includes(query.get("view") || "page") && (query.get("view") as BookView)) || "page";

    if (view !== "page" && window.parent === window) {
        view = "page";
    }

    return {view, nonce, preview};
};

// a HOC for the Book and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child
function BookingHOC(props: BookHOCProps & {event?: PublicBookingEventFragment | null}): JSX.Element | null {
    const [_, setViewState] = useBookingView();

    const {search} = useLocation();

    useEffect(() => {
        const {view, nonce, preview} = getView(search);
        console.log(view, nonce, preview);
        setViewState({view, nonce, preview});
    }, [search, setViewState]);

    const id = props.match?.params.bookingEventId as string;

    if (id === "successful") {
        return null;
    }

    return (
        <div className="route-hoc flex-align-start book-hoc" key="book-hoc">
            <Book event={props.event} key={`book-entry-point-${id}`} />
        </div>
    );
}

function BookingSuccessfulHOC(props: BookHOCProps & {event?: PublicBookingEventFragment | null; force?: boolean}): JSX.Element {
    const [_, setViewState] = useBookingView();

    const {search} = useLocation();

    useEffect(() => {
        const {view, nonce} = getView(search);

        setViewState({view, nonce});
    }, [search, setViewState]);

    return (
        <div className="route-hoc flex-align-start book-successful-hoc" key="book-successful-hoc">
            <BookSuccessful bookingId={props.match?.params.bookingId as string} event={props.event} key={`book-successful-entry-point`} />
        </div>
    );
}

function BookRouteManagerHOC(props: BookHOCProps): JSX.Element {
    const bookingEventId = props.match?.params.bookingEventId as string;
    const bookingEventPrefix = props.match?.params.bookingEventPrefix as string;
    const encodedBookingEventId = bookingEventId ? encodeURI(bookingEventId) : null;

    const slug =
        !encodedBookingEventId || encodedBookingEventId === "successful"
            ? bookingEventPrefix
            : `${bookingEventPrefix}/${encodedBookingEventId}`;

    const {data: bookedEventData, loading} = useQuery(GetPublicBookingEventDocument, {
        variables: {
            slug: slug,
            timezone: getLocalTimezone() as string,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
    });
    const event = bookedEventData?.publicBookingEvent;

    useEffect(() => {
        if (!event?.branding) {
            return;
        }

        const root = document.querySelector<HTMLElement>(":root");
        const styleTag = document.createElement("style");

        if (root && event.branding.sessionBackground) {
            root.style.setProperty("--app-background", `transparent url("${event.branding.sessionBackground}") no-repeat center/cover`);
            root.style.setProperty("--app-player-background", `transparent`);
        }

        if (event.branding.styleTag) {
            styleTag.className = "custom-theme";
            styleTag.innerHTML = event.branding.styleTag;
            const existingBranding = document.querySelector("head style.custom-theme");
            existingBranding?.remove();
            document.head.append(styleTag);
        }

        return () => {
            styleTag.remove();
            root?.style.removeProperty("--app-background");
            root?.style.removeProperty("--app-player-background");
        };
    }, [event]);

    if (loading) {
        return <Loading />;
    }

    return (
        <BookingViewProvider>
            <Route exact path="/book/:bookingEventPrefix" render={() => <BookingHOC {...props} event={event} />} />

            <Route exact path="/book/:bookingEventPrefix/:bookingEventId" render={() => <BookingHOC {...props} event={event} />} />

            <Route
                exact
                path="/book/:bookingEventPrefix/successful/:bookingId"
                render={() => <BookingSuccessfulHOC {...props} event={event} force />}
            />
            <Route
                exact
                path="/book/:bookingEventPrefix/:bookingEventId/successful/:bookingId"
                render={() => <BookingSuccessfulHOC {...props} event={event} force />}
            />
        </BookingViewProvider>
    );
}

export default BookRouteManagerHOC;
