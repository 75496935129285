import {useClientEvent} from "@api/events/client";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {useEffect, useRef, useState} from "react";

export function AgendaItemLimitDialog() {
    const [open, setOpen] = useState(false);

    useClientEvent("agenda-items-limit-error", (event) => {
        setOpen(true);
    });
    const imperativeRef = useRef<DialogImperativeRef>();

    useEffect(() => {
        imperativeRef.current?.toggle?.(open);
    }, [open]);

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            classes={{
                paper: "p-20 mw-400",
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop-with-backdrop-filter",
                },
            }}
            imperativeRef={imperativeRef}
            open={open}
            disableBackdropClick
        >
            <Typography className="mb-20" fontWeight="boldest" variant="xl">
                Maximum allowed agenda items reached
            </Typography>

            <div>
                <div>
                    <Typography className="mb-20" fontWeight="bold" variant="base">
                        You have reached the limit of 30 agenda items. You cannot add more at this time.
                    </Typography>
                </div>
            </div>

            <div className="flex flex-col">
                <Button onClick={onClose}>Confirm</Button>
            </div>
        </Dialog>
    );
}
