/* eslint-disable react/no-unescaped-entities */
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as AllowMicrophoneIcon} from "../../assets/allow-mic.svg";
import Select from "@ui/cdk/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {SessionFlagsType} from "@generated/data";
import {useTranslation} from "react-i18next";

const styles = {...genericStyles};

type SessionHardMuteSelectorProps = {
    value: SessionFlagsType;
    onChange: (val: SessionFlagsType) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const SessionHardMuteSelector = ({value, onChange, showIcon = false, disabled, className}: SessionHardMuteSelectorProps) => {
    const {t} = useTranslation();

    const options = [
        {value: SessionFlagsType.Everyone, text: t("g.session.values_role.everyone") ?? "Everyone"},
        {value: SessionFlagsType.Speakers, text: t("g.session.values_role.speakers_hosts") ?? "Speakers & Hosts"},
        {value: SessionFlagsType.Hosts, text: t("g.session.values_role.hosts") ?? "Hosts"},
    ];

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sessionFlagsType = event.target.value as SessionFlagsType;
        onChange(sessionFlagsType);
    };

    return (
        <div className={cls(styles.togglerWrapper, className)}>
            {showIcon ? (
                <div className={styles.iconWrapper}>
                    <AllowMicrophoneIcon />
                </div>
            ) : null}
            <div className="flex flex-justify-between flex-align-center fullw">
                <div className={styles.textWrapper}>
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("player.settings.general.session.allow_mic.title")}
                    </Typography>
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.session.allow_mic.description")}
                    </Typography>
                </div>

                <Select
                    data-id="allow-microphone-settings"
                    className={styles.selectInput}
                    options={options}
                    value={value}
                    IconComponent={ExpandMoreRoundedIcon}
                    onChange={handleOnChange}
                    MenuProps={{
                        classes: {
                            paper: styles.selectMenuPaper,
                        },
                    }}
                    wrapperClassName={styles.selectFormControl}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default SessionHardMuteSelector;
