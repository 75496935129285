import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useState} from "@workhorse/api/rendering";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {useDevices} from "../../../../api/conference2/providers/MediaProvider/DevicesProvider";
import classes from "./Warning.module.scss";
import {useTranslation} from "react-i18next";

export function useAudioCorruptWarning() {
    const [dismissed, setDismissed] = useState(false);
    const {show} = useSessionSettingsDialogDisplay();

    const {usingFallbackAudio} = useDevices();

    return {
        show: usingFallbackAudio && !dismissed,
        onDismiss: () => setDismissed(true),
        onSettings: () => {
            setDismissed(true);
            show("device.audio");
        },
    };
}

interface Props {
    onDismiss: () => void;
    onSettings: () => void;
}

export function AudioCorruptWarning(props: Props) {
    const {t} = useTranslation();
    return (
        <div className={classes.root}>
            <div className={`${classes.header} flex flex-row`}>
                <WarningRoundedIcon className={classes.headerIcon} />
                <Typography fontWeight="bolder">{t("player.notifications.mic.microphone_issue_detected")}</Typography>
                {/* <IconButton className={classes.closeButton} onClick={props.onDismiss}>
                    <CloseRoundedIcon />
                </IconButton> */}
            </div>
            <Typography className="mt-4">
                {browserInfo.isSafari()
                    ? t("player.notifications.mic.unable_to_detect_audio")
                    : t("player.notifications.mic.ensure_your_microphone_is_connected")}
            </Typography>
            <div className="flex flex-row mt-12">
                <Button className="mr-12" variant="tertiary" size="small" onClick={props.onSettings}>
                    {t("player.notifications.mic.audio_settings")}
                </Button>
                <Button
                    className={classes.dismissButton}
                    variant="plain"
                    size="small"
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    {t("g.refresh_page")}
                </Button>
            </div>
        </div>
    );
}
