import LocalParticipantControlMenu from "../../LocalParticipantControlMenu";
import BackgroundEffectsOption from "./BackgroundEffectsOption";
import HideSelfViewOption from "./HideSelfViewOption";
import VideoSettingsOption from "./VideoSettingsOption";
import VideoToggleOption from "./VideoToggleOption";
import classes from "./styles/LocalVideoMenu.module.scss";
import {browserBehavior} from "@workhorse/api/conference2";
import GlobalVideoToggler from "./GlobalVideoToggler";

type LocalVideoMenuProps = {children: JSX.Element; disabled?: boolean; onVideoToggle?: () => void};

function LocalVideoMenu(props: LocalVideoMenuProps) {
    const {onVideoToggle, ...rest} = props;

    const options = [
        <GlobalVideoToggler key="global-video-toggler" />,
        <HideSelfViewOption key="local-video-hide-self-view" />,
        browserBehavior.supportsBackgroundFilter() ? <BackgroundEffectsOption key="local-video-background-effects" /> : null,
        <VideoSettingsOption key="local-video-video-settings" />,
        <li className="separator" key="local-video-separator" />,
        <VideoToggleOption onVideoToggle={onVideoToggle} key="local-video-video-toggle" />,
    ].filter(Boolean) as JSX.Element[];

    return props.disabled ? props.children : <LocalParticipantControlMenu options={options} classes={{popper: classes.popper}} {...rest} />;
}

export default LocalVideoMenu;
