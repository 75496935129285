import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";

type EventData = NonNullable<NonNullable<DesignerApiSession["session"]>["event"]>;
type UpdateEventInput = Partial<Omit<EventData, "id" | "__typename">>;

export class UpdateEvent extends DesignerAction<UpdateEventInput> {
    commit() {
        const session = designer.currentSession();
        const event = session?.event;

        if (!event) {
            return "";
        }

        designer.updateCurrentSession(
            {
                session: {
                    ...session,
                    event: {
                        ...event,
                        ...this.input,
                    },
                },
            },
            {
                strategy: "replace",
            }
        );

        return "";
    }
}
