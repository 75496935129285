import React, {forwardRef} from "@workhorse/api/rendering";
import {CDKComponentProps} from "./cdk.utils";
import Time, {TimeProps} from "./Time";

type TimeRangeProps = CDKComponentProps & {
    start: Date | string;
    end: Date | string;

    timePropsStart?: Omit<TimeProps, "time">;
    timePropsEnd?: Omit<TimeProps, "time">;

    /**
     * @description The separator
     * @default <span>&mdash</span>;
     */
    separator?: string | JSX.Element;
    isHalfDayClock?: boolean;
};

const TimeRange = forwardRef((props: TimeRangeProps, ref: React.MutableRefObject<HTMLDivElement | null>): JSX.Element => {
    const start = new Date(props.start);
    const end = new Date(props.end);
    const separator = props.separator || <span>&ndash;</span>;

    return (
        <div ref={ref} className={props.className} style={props.style}>
            <Time {...props.timePropsStart} time={start} isHalfDayClock={props.isHalfDayClock} /> {separator}{" "}
            <Time {...props.timePropsEnd} time={end} isHalfDayClock={props.isHalfDayClock} />
        </div>
    );
});

export default TimeRange;
