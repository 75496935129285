import {isObj, isObjOrArray} from "./utils";
import {modelsWithFieldResolver} from "./common-diff-resolvers";
import {valuesAreEqual} from "./computeDiff";

export function createDiff(existing: any, incoming: any, undoChanges?: boolean) {
    if (Array.isArray(existing) && Array.isArray(incoming)) {
        existing.forEach((unchanged, idx) => {
            if (
                unchanged.isDeleted ||
                !isObjOrArray(unchanged) ||
                !unchanged.id ||
                !unchanged.__typename ||
                (modelsWithFieldResolver[unchanged.__typename] ?? []).length === 0
            ) {
                if (unchanged.isDeleted && undoChanges) {
                    unchanged.isDeleted = false;
                }
                return false;
            }
            const changed = incoming.find((obj) => {
                return obj && ((obj.id && obj.id === unchanged.id) || (obj.oldId && obj.oldId === unchanged.id));
            });

            // if an object with the same id was NOT found in incoming
            // OR (it was found but it exists only locally (aka createdAt is missing)
            // AND we're undoing changes)
            // remove from existing
            if (!changed || (undoChanges && "createdAt" in changed && !changed.createdAt)) {
                existing.splice(idx, 1);
                return false;
            }
            createDiff(unchanged, changed, undoChanges);
        });

        // push new items that don't exist in `existing` and
        // exist only locally (aka just created)
        // in case we're undoing changes, this step is skipped
        // because there's nothing to process
        if (!undoChanges) {
            incoming.forEach((changed, idx) => {
                const exists = existing.find((obj) => {
                    return obj && ((obj.id && obj.id === changed.id) || (changed.oldId && changed.oldId === obj.id));
                });
                if (!exists) {
                    existing.splice(idx, 0, changed);
                }
            });
        }
    } else if (isObj(existing) && isObj(incoming)) {
        const updateableKeys = modelsWithFieldResolver[existing.__typename] ?? [];
        const hasDiff = updateableKeys.length > 0;
        // undoChanges is checked first because we might be undoing changes for UNCOMMITED data
        // and here... we don't want to assign incoming over existing like below
        // we just let existing be... whatever it is (because it contains unchanged data)
        if (hasDiff && undoChanges && existing.createdAt) {
            existing.update = null;
            existing.oldId = null;
            existing.isDeleted = false;
            // no point in going any further since we're keeping all the relations
            // as they were before changes
            return;
        }

        if (hasDiff) {
            existing.isDeleted = incoming.isDeleted || false;
        }

        const isUpdate = hasDiff && existing.createdAt && ("createdAt" in incoming ? !!incoming.createdAt : true) && !incoming.isDeleted;
        const isReplace =
            hasDiff &&
            existing.createdAt &&
            "createdAt" in incoming &&
            !incoming.createdAt &&
            incoming.id &&
            existing.id &&
            !(existing.id === incoming.id) &&
            !incoming.isDeleted;

        if (isReplace) {
            existing.update = {
                __typename: `${existing.__typename}Diff`,
                newId: incoming.id,
            };
            existing.oldId = existing.id;
            existing.isDeleted = false;
        }
        if (!isReplace && isUpdate) {
            delete existing.oldId;
            existing.update = {__typename: `${existing.__typename}Diff`};
        }
        const keys = Object.keys(existing);

        keys.forEach((key) => {
            if (!key || key === "update" || key === "oldId" || key === "isDeleted") {
                return false;
            }

            if (updateableKeys.indexOf(key) !== -1) {
                const oldVal = existing[key] instanceof Date ? existing[key].toISOString() : existing[key];
                const newVal = key in incoming ? (incoming[key] instanceof Date ? incoming[key].toISOString() : incoming[key]) : oldVal;

                // TODO: get all json type fields from schema and remove the hardcoded list
                if (
                    !valuesAreEqual(
                        oldVal,
                        newVal,
                        [
                            "descriptionJson",
                            "colorPalette",
                            "socialLinks",
                            "speakerOrderJson",
                            "utm",
                            "isPublic",
                            "reminders",
                            "messageReminders",
                            "restrictedWidgets",
                            "disabledNotifications",
                        ].includes(key)
                    )
                ) {
                    existing.update[key] = newVal;
                }
            } else if (isObjOrArray(existing[key]) && isObjOrArray(incoming[key])) {
                createDiff(existing[key], incoming[key], undoChanges);
            } else if (
                existing[key] === null &&
                isObjOrArray(incoming[key]) &&
                (Array.isArray(incoming[key]) ? false : modelsWithFieldResolver[incoming[key].__typename])
            ) {
                existing[key] = incoming[key];
            }
        });
    }
}
