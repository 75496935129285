import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {addMinutes, format, isSameDay, set} from "date-fns";
import TimeInput from "./TimeInput";
import {InputProps} from "@ui/cdk/Input";
import {AutocompleteProps} from "@ui/cdk/Autocomplete";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {generateAutocompleteHours} from "../../utils";
import DatePicker, {DatePickerProps, OnChangeArgs} from "../../DatePicker";
import classes from "../styles/TimeRangePicker.module.scss";
import {useTranslation} from "react-i18next";

type AcArgs = NonNullable<AutocompleteProps["onInputChange"]>;
type OnInputChangeArg<T extends Parameters<AcArgs> = Parameters<AcArgs>> = {
    start?: {
        e: T[0];
        value: T[1];
        reason: T[2];
    };
    end?: {
        e: T[0];
        value: T[1];
        reason: T[2];
    };
};

export type OnTimeRangePickerInputChange<T extends Parameters<AcArgs> = Parameters<AcArgs>> = (arg: OnInputChangeArg<T>) => void;

type TimeRangePickerProps = {
    dateFrom: Date;
    dateTo: Date;
    setDateFrom: (date: Date) => void;
    setDateTo: (date: Date, userSelectedStart?: Date) => void;
    onCalendarChooseDay: (value: Partial<OnChangeArgs>) => void;
    minDuration?: number | null;
    allowDatesInThePast?: boolean;
    small?: boolean;
    dateFormat: "12h" | "24h";
    fromToLabels?: boolean;
    separators?: boolean;
    fullWidth?: boolean;
    label?: InputProps["label"];
    onInputChange?: OnTimeRangePickerInputChange;
    errorOn?: "start" | "end";
    offset: number;
    setValuesOnMount?: boolean;
    inlineCalendar?: boolean;
    forTimezone: string;
    localTimezone: string;
    inSessionDialog?: boolean;
    classes?: {
        timeInput?: string;
        dateInput?: string;
        autocompletePaper?: string;
    };
    refStart: React.MutableRefObject<any>;
    refEnd: React.MutableRefObject<any>;
    onClose?: () => void;
    disabled?: boolean;
    datesOnly?: boolean;
};

const TimeRangePicker = (props: TimeRangePickerProps) => {
    const {t} = useTranslation();
    const {
        dateFrom,
        dateTo,
        setDateFrom,
        setDateTo,
        onInputChange,
        onCalendarChooseDay,
        minDuration,
        allowDatesInThePast,
        small,
        dateFormat,
        fromToLabels,
        inSessionDialog,
        separators = false,
        fullWidth = false,
        errorOn,
        label,
        offset,
        setValuesOnMount,
        inlineCalendar,
        forTimezone,
        localTimezone,
        refStart,
        refEnd,
        disabled,
        datesOnly,
    } = props;

    const [calendarChosenStartDay, setCalendarChosenStartDay] = useState(dateFrom);
    const [calendarChosenEndDay, setCalendarChosenEndDay] = useState(dateTo);

    useEffect(() => {
        if (!isSameDay(addMinutes(calendarChosenStartDay, offset), addMinutes(dateFrom, offset))) {
            setCalendarChosenStartDay(dateFrom);
        }
        if (!isSameDay(addMinutes(calendarChosenEndDay, offset), addMinutes(dateTo, offset))) {
            setCalendarChosenEndDay(dateTo);
        }
    }, [dateFrom, dateTo, offset]);

    const hourListStart = useMemo(() => {
        return generateAutocompleteHours({
            day: calendarChosenStartDay,
            dateArg: dateFrom,
            timezoneOffset: offset,
            addOption: dateFrom,
            allowPastDate: allowDatesInThePast || false,
        });
    }, [dateFrom, offset, calendarChosenStartDay]);

    const hourListEnd = useMemo(() => {
        const endIsSameDayWithStart = isSameDay(dateFrom, dateTo);
        const relativeToDate = endIsSameDayWithStart
            ? dateFrom
            : set(dateTo, {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
              });

        const minDate = endIsSameDayWithStart ? addMinutes(relativeToDate, 15) : relativeToDate;
        const addOption = dateTo.getTime() > dateFrom.getTime() + 60 * 2 * 1000 ? dateTo : undefined;

        return generateAutocompleteHours({
            day: calendarChosenEndDay,
            dateArg: relativeToDate,
            timezoneOffset: offset,
            onlyFutureValues: true,
            addOption,
            endingMinDate: minDate,
            allowPastDate: allowDatesInThePast || false,
        });
    }, [hourListStart, dateFrom, dateTo, offset, calendarChosenEndDay]);

    const endValue: Date = useMemo(() => {
        const userSelectedOpt =
            dateTo && dateFrom
                ? hourListEnd.opts.find((o) => {
                      return o["12h"] === format(dateTo, "hh:mm a");
                  })
                : null;
        if (userSelectedOpt) {
            return userSelectedOpt.date;
        }
        return hourListEnd.defaultOption.date;
    }, [dateFrom, dateTo, hourListStart, hourListEnd]);

    useEffect(() => {
        if (!setValuesOnMount) {
            return;
        }
        setDateFrom(hourListStart.defaultOption.date);
        setDateTo(endValue, hourListStart.defaultOption.date);
    }, []);

    const aligned = useRef(false);

    useEffect(() => {
        if (allowDatesInThePast) {
            return;
        }
        const align = () => {
            if (dateFrom) {
                const now = new Date();
                const selectedStartIsInThePast = now.getTime() - dateFrom.getTime() > 1000;
                if (selectedStartIsInThePast) {
                    const newStart = new Date(now.setMinutes(now.getMinutes() + 1, 0, 0));
                    setDateFrom(newStart);
                }
            }
        };
        const intv = setInterval(() => align(), 1000);
        if (!aligned.current) {
            aligned.current = true;
            align();
        }
        return () => {
            clearInterval(intv);
        };
    }, [dateFrom, dateTo, offset]);

    const onStartInputChange: AutocompleteProps["onInputChange"] = (e, newValue, reason) => {
        if (onInputChange) {
            onInputChange({
                start: {
                    e,
                    value: newValue,
                    reason,
                },
            });
        }
    };

    const onEndInputChange: AutocompleteProps["onInputChange"] = (e, newValue, reason) => {
        if (onInputChange) {
            onInputChange({
                end: {
                    e,
                    value: newValue,
                    reason,
                },
            });
        }
    };

    // const lastTimeDayChanged = useRef(new Date().getTime());
    // const [dayChanged, setDayChanged] = useState(lastTimeDayChanged.current);

    const chooseDay = useRef(onCalendarChooseDay);
    chooseDay.current = onCalendarChooseDay;

    const onCalendarChooseStartDay: DatePickerProps["onChange"] = (value) => {
        const newStartDay = addMinutes(value.startDate, -offset);
        setCalendarChosenStartDay(newStartDay);
        chooseDay.current({
            startDate: newStartDay,
        });
    };

    const onCalendarChooseEndDay: DatePickerProps["onChange"] = (value) => {
        const newEndDay = value.startDate ? addMinutes(value.startDate, -offset) : dateTo;
        setCalendarChosenEndDay(newEndDay);
        chooseDay.current({
            endDate: newEndDay,
        });
    };

    const now = addMinutes(new Date(), offset);

    return (
        <div className={cls(classes.timePicker, small && classes.small, fullWidth && "fullw", inSessionDialog && classes.inSessionDialog)}>
            <div className={cls(classes.group, datesOnly && classes.datesOnly, "time-range-group")}>
                {fromToLabels ? (
                    <Typography component="div" variant="lg" className={classes.fromToLabel} noWrap>
                        {t("cdk.time_picker.from") ?? "From"}
                    </Typography>
                ) : null}
                {inlineCalendar || datesOnly ? (
                    <DatePicker
                        dataId="session-start-date"
                        onChange={onCalendarChooseStartDay}
                        startDate={addMinutes(calendarChosenStartDay, offset)}
                        minDate={now}
                        allowDatesInThePast={allowDatesInThePast}
                        className={cls(classes.datePicker, props.classes?.dateInput)}
                        classes={{
                            placeholder: classes.placeholder,
                        }}
                        showStartAndEndOfOtherMonths={false}
                        disabled={disabled}
                        forTimezone={forTimezone}
                        localTimezone={localTimezone}
                        displayDate
                        key="date-picker-start"
                    />
                ) : null}
                {!datesOnly ? (
                    <TimeInput
                        small={small}
                        dateFormat={dateFormat}
                        allowDatesInThePast={allowDatesInThePast}
                        onChange={setDateFrom}
                        onInputChange={onStartInputChange}
                        value={dateFrom}
                        fullWidth={!inlineCalendar && fullWidth}
                        label={label}
                        disabled={disabled}
                        error={errorOn === "start"}
                        hourList={hourListStart}
                        className={cls(inlineCalendar ? classes.timeInput : "", props.classes?.timeInput)}
                        classes={{
                            autocompletePaper: props.classes?.autocompletePaper,
                        }}
                        key="from"
                        ref={refStart}
                        offsetInMin={offset}
                        onClose={props.onClose}
                    />
                ) : null}
            </div>
            {!datesOnly ? (
                <div>
                    {fromToLabels || inSessionDialog ? (
                        <Typography component="div" variant="lg" className={classes.fromToLabel} noWrap>
                            {t("cdk.time_picker.to") ?? "to"}
                        </Typography>
                    ) : null}
                    {!fromToLabels && separators ? (
                        <Typography component="div" variant="lg" className={classes.spacer}>
                            -
                        </Typography>
                    ) : null}
                </div>
            ) : null}

            {!datesOnly ? (
                <div className={cls(classes.group, "time-range-group")}>
                    {datesOnly ? (
                        <DatePicker
                            dataId="session-end-date"
                            onChange={onCalendarChooseEndDay}
                            startDate={addMinutes(calendarChosenEndDay, offset)}
                            // endDate={dateTo}
                            minDate={addMinutes(addMinutes(dateFrom, offset), 15)}
                            allowDatesInThePast={allowDatesInThePast}
                            disabled={disabled}
                            className={cls(classes.datePicker, props.classes?.dateInput)}
                            classes={{
                                placeholder: classes.placeholder,
                            }}
                            showStartAndEndOfOtherMonths={false}
                            forTimezone={forTimezone}
                            localTimezone={localTimezone}
                            displayDate
                            key="date-picker-end"
                        />
                    ) : (
                        <TimeInput
                            small={small}
                            dateFormat={dateFormat}
                            allowDatesInThePast={allowDatesInThePast}
                            onChange={setDateTo}
                            onInputChange={onEndInputChange}
                            value={endValue}
                            fullWidth={!inlineCalendar && fullWidth}
                            label={label}
                            error={errorOn === "end"}
                            hourList={hourListEnd}
                            disabled={disabled}
                            isEnd={true}
                            className={cls(inlineCalendar ? classes.timeInput : "", props.classes?.timeInput)}
                            classes={{
                                autocompletePaper: props.classes?.autocompletePaper,
                            }}
                            ref={refEnd}
                            offsetInMin={offset}
                            key="to"
                            onClose={props.onClose}
                        />
                    )}
                    <span></span>
                </div>
            ) : null}
        </div>
    );
};

export default TimeRangePicker;
