import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {SeenSegment} from "@workhorse/api/seen";
import {OnboardingFeatureConfiguration} from "../provider";
import classes from "./styles/OnboardingFeaturesJourneyGoal.module.scss";
import {useOnboardingFeatureSeen} from "../hooks";

type OnboardingFeaturesJourneyGoalProps = Omit<OnboardingFeatureConfiguration, "href" | "steps" | "quest"> & {
    segment: Exclude<SeenSegment, "GLOBALS.incident">;

    onClick?: () => void;
};

export default function OnboardingFeaturesJourneyGoal({segment, title, subtitle, onClick}: OnboardingFeaturesJourneyGoalProps) {
    const seen = useOnboardingFeatureSeen(segment);
    let dataIdString = "";

    switch (segment) {
        case "ONBOARDING_FEATURES.onboardingSessionFirstStrike": {
            dataIdString = "get-started-entry-strike1";
            break;
        }
        case "ONBOARDING_FEATURES.onboardingSessionSecondStrikeOwner": {
            dataIdString = "get-started-entry-strike2";
            break;
        }
        case "ONBOARDING_FEATURES.onboardingSessionSecondStrikeMember": {
            dataIdString = "get-started-entry-strike2";
            break;
        }
        case "ONBOARDING_FEATURES.helpCenter": {
            dataIdString = "onboarding-click-learn-more";
            break;
        }
        default: {
            break;
        }
    }
    return (
        <div className={cls("flex flex-row flex-align-center gap-12 py-16 px-24", classes.root, seen ? classes.seen : classes.unseen)}>
            <div className="cursor-pointer" onClick={onClick} data-id={dataIdString}>
                {seen ? <CheckRoundedIcon className={classes.checkbox} /> : <div className={classes.empty} />}
            </div>

            <div className={cls("cursor-pointer", classes.details)} onClick={onClick} data-id={dataIdString}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </div>

            <div>
                <IconButton className={cls("flex flex-center-all", classes.action)} onClick={onClick} data-id={dataIdString}>
                    <ArrowForwardRoundedIcon />
                </IconButton>
            </div>
        </div>
    );
}
