import {ResourceProcessingStatus} from "@generated/data";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import designer from "@workhorse/api/designer";
import {getMaxSessionOrder} from "@workhorse/api/designer/lib/utils";
import {useEffect} from "@workhorse/api/rendering";
import ToolConfirmationNotification from "@workhorse/pages/designer/ToolConfiguration/components/ToolConfirmationModeNotification";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {readSession, useAgendaItems} from "@workhorse/providers/SessionDataProviders";

type ProcessingFinishedConfirmationProps = {
    inLobby?: boolean;
    processingStatus: ResourceProcessingStatus;
};
const nullDate = null as unknown as Date;

const ProcessingFinishedConfirmation = (props: ProcessingFinishedConfirmationProps) => {
    const agendaItems = useAgendaItems();
    const {onboardingType} = useOnboardingSession();

    useEffect(() => {
        if (props.inLobby) {
            return;
        }
        const currentSessionId = designer.state.getSessionId();
        if (!currentSessionId) {
            return;
        }
        const existingSession = readSession({sessionId: currentSessionId});
        if (existingSession) {
            const order = getMaxSessionOrder();

            writeFromFullSession(currentSessionId, {
                session: {
                    ...existingSession,
                    // order: -1,
                    order,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (props.inLobby || onboardingType === SessionOnboardingType.NewUser) {
            if (props.processingStatus === ResourceProcessingStatus.Finished) {
                onConfirmNotification();
            }
        }
    }, [props.processingStatus, props.inLobby, onboardingType]);

    const onConfirmNotification = () => {
        const convertingAgendaItem = agendaItems.find((item) => item.processing);
        if (convertingAgendaItem) {
            const order = getMaxSessionOrder();
            designer.api.agendaItem.update({
                id: convertingAgendaItem.id,
                agendaItem: {
                    processing: false,
                },
            });
            designer.api.session.update({
                order,
            });
            designer.state.update({
                selectedAgendaItemId: null,
            });

            designer.commit({
                forceOrderUpdate: order,
            });
        }
    };

    const onCancelNotification = () => {
        const convertingAgendaItem = agendaItems.find((item) => item.processing);
        console.log("on cancel", convertingAgendaItem);
        if (convertingAgendaItem) {
            designer.api.agendaItem.remove({
                id: convertingAgendaItem.id,
            });
            designer.state.update({
                selectedAgendaItemId: null,
            });
            designer.commit();
        }
    };

    return (
        <ToolConfirmationNotification
            open={true}
            handleConfirm={onConfirmNotification}
            inLobby={props.inLobby}
            onNotificationClose={onCancelNotification}
        />
    );
};

export default ProcessingFinishedConfirmation;
