import IconButton from "@ui/core/components/IconButton";
import classes from "./style/RoomsTableHeaderSortButton.module.scss";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import {cls} from "@ui/cdk/util";
import {RoomsSort, RoomsSortField} from "./utils";

type RoomsTableHeaderSortButtonProps = {
    columnId: string;
    activeColumnId: string;
    direction: "asc" | "desc";
    onSort: (eventSort: RoomsSort) => void;
    canSort: boolean;
};

function RoomsTableHeaderSortButton(props: RoomsTableHeaderSortButtonProps) {
    const {columnId, activeColumnId, direction, onSort, canSort} = props;

    const handleSort = () => {
        if (columnId === activeColumnId) {
            onSort({field: columnId as RoomsSortField, direction: direction === "asc" ? "desc" : "asc"});
        } else {
            onSort({field: columnId as RoomsSortField, direction: "asc"});
        }
    };

    return (
        <IconButton className={cls("ml-6", classes.root, !canSort && classes.disabled)} onClick={handleSort}>
            {activeColumnId === columnId && canSort ? (
                direction === "asc" ? (
                    <ArrowUpwardRoundedIcon className={classes.selected} />
                ) : (
                    <ArrowDownwardRoundedIcon className={classes.selected} />
                )
            ) : (
                <ArrowUpwardRoundedIcon />
            )}
        </IconButton>
    );
}

export default RoomsTableHeaderSortButton;
