import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {useDevices, isNotAllowedError} from "@workhorse/api/conference2";
import {useForceDisableVideo} from "@workhorse/api/conference2/providers/VideoDisableProvider";
import {forwardRef} from "@workhorse/api/rendering";
import commons from "../../../../commons/style.module.scss";
import {getGlobalKeybind} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";

function VideOn() {
    const {t} = useTranslation();
    return <Typography fontWeight="bold">{t("participant.camera.turn_off")}</Typography>;
}

function VideoOff() {
    const {t} = useTranslation();
    return <Typography fontWeight="bold">{t("participant.camera.turn_on")}</Typography>;
}

interface Props {
    onVideoToggle?: () => void;
}

const VideoToggleOption = forwardRef<unknown, Props>((props, ref) => {
    const {videoEnabled, videoError, hasVideoInput} = useDevices();
    const {isHardVideoDisable} = useForceDisableVideo();
    const canEnable = hasVideoInput && !isHardVideoDisable;

    const active = videoEnabled && !isNotAllowedError(videoError);

    const keybinds = getGlobalKeybind("toggleVideo");

    return (
        <MenuItem ref={ref} onClick={props.onVideoToggle} disabled={!canEnable} className={`flex-center-all ${active ? "blue" : "red"}`}>
            {!canEnable && <VideoOff />}
            {canEnable && active && <VideOn />}
            {canEnable && !active && <VideoOff />}
            <div className="flex flex-align-center ml-12">
                {keybinds.map((keybind, index) => (
                    <div key={index} className={commons.keyBind}>
                        {keybind}
                    </div>
                ))}
            </div>
        </MenuItem>
    );
});

export default VideoToggleOption;
