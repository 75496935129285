import {systemEnv} from "@generated/environment/system";
import {partialEnvironment} from "./Environment";

export const defaultEnvironment = partialEnvironment({
    guestTokenDuration: parseInt(systemEnv.guestTokenDuration),
    remoteRecorder: {
        apiUrl: "https://surf-api.flowos.dev/_api/recording",
        apiSecret: "ilovecats",
        publicBaseUrl: "https://storage.googleapis.com/surf-recordings-dev",
    },
    cnameDomain: "cname.sessions.flowos.dev",
});
