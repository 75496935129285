import {makeStyles} from "@material-ui/core";
import MicOff from "@material-ui/icons/MicOff";
import Typography from "@ui/cdk/Typography";
import {MicroNotificationProps} from "../api";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    muteNotification: {
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            width: 18,
            height: 18,
            marginRight: theme.spacing(0.75),
        },
    },
}));

export type MuteNotificationType = {
    user?: string;
    action: "mute-everyone" | "media-mute" | "soft-mute" | "unmute" | "revoked-unmute" | "cancelled-unmute-invitation";
};

export const MuteNotification = (props: MicroNotificationProps<MuteNotificationType>) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {action, user: displayName} = props.messages[0].message;

    if (action === "mute-everyone") {
        return (
            <div className={classes.muteNotification}>
                <MicOff />
                <Typography>
                    {t("player.notifications.mic.everyone_has_been_muted_by_host") ?? <>Everyone has been muted by the host.</>}
                </Typography>
            </div>
        );
    }

    if (action === "media-mute") {
        return (
            <div className={classes.muteNotification}>
                <MicOff />
                <Typography>
                    {t("player.notifications.mic.everyone_has_been_muted_by_system") ?? <>Everyone has been muted by the system.</>}
                </Typography>
            </div>
        );
    }

    if (action === "soft-mute") {
        return (
            <div className={classes.muteNotification}>
                <MicOff />
                <Typography>
                    {t("player.notifications.mic.muted_by_host") ?? (
                        <>
                            Your mic has been <strong>muted</strong> by the host.
                        </>
                    )}
                </Typography>
            </div>
        );
    }

    if (action === "unmute") {
        return (
            <div className={classes.muteNotification}>
                <MicOff />
                <Typography>
                    {t("player.notifications.mic.unmuted_by_host") ?? (
                        <>
                            Your mic has been <strong>unmuted</strong> by the system.
                        </>
                    )}
                </Typography>
            </div>
        );
    }

    return null as unknown as JSX.Element;
};
