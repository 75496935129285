import {getLocalTimezone} from "@common/utils/timezones";
import {DesignerSessionLockedDocument, SessionEventState, SessionParticipantFragment, SessionSettingsSource} from "@generated/data";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import InputLabel from "@ui/core/components/InputLabel";
import {useOrganizationAccess} from "@workhorse/api/access/hooks";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {useInitialSessionFlags} from "@workhorse/components/CreatePlannedSession/utils";
import {enableEventLeaveModal, routeNavigation} from "@workhorse/components/header/headerUtils";
import {Tag, TagType} from "@workhorse/pages/designer/CreateSession/ContactFetcher";
import {useUserInfo} from "@workhorse/providers/User";
import {composeInitialSlug, removeSpecialChars} from "@workhorse/util/url";
import {addMinutes} from "date-fns";
import {useHistory} from "react-router";
import {randomStr} from "../rooms/utils";
import {defaultPalette, extractRGBObject} from "../user/profile/Theming/utils";
import {EventsTimePicker} from "./components/event-timepicker";
import classes from "./create-event.module.scss";
import EventCollaborators from "./event-collaborators/event-collaborators";
import EventHeader from "./event/event-header";
import LeaveEventModal from "./event/event-leave-modal/LeaveEventModal";
import {ParticipantWithSpeakerDetailsPayload} from "./event/event-speakers/utils";
import common from "./event/eventCommons.module.scss";
import {defaultEventRegistrationForm} from "./event/utils";

const eventFallbackOrg = randomStr(8);

const localTimezone = getLocalTimezone();

export function useCreateEventContactFetcher(params: {initial?: (() => Tag[]) | Tag[]; setError: (error: boolean) => void}) {
    const {initial, setError} = params;
    const [pending, setPending] = useState<Tag[]>(initial ?? []);

    const pendingMap = useMemo(() => {
        const map = new Map<string, string | undefined>();
        for (const item of pending) {
            const key = item.type + "$" + item.value;
            map[key] = true;
        }
        return map;
    }, [pending]);

    const addPendingItem = useCallback(
        (type: TagType, value: string) => {
            setPending((current) => {
                const index = current.findIndex((item) => item.type === type && item.value === value);
                if (index >= 0) {
                    return current;
                }
                const item = {
                    type,
                    value,
                };
                //add only contacts or groups
                if (type === "contact" || type === "group") {
                    setError(false);
                    return [...current, item];
                } else {
                    setError(true);
                    return current;
                }
            });
        },
        [setError]
    );

    const removePendingItem = useCallback((type: TagType, value: string) => {
        setPending((current) => {
            const index = current.findIndex((item) => item.type === type && item.value === value);
            if (index < 0) {
                return current;
            }
            const copy = [...current];
            copy.splice(index, 1);
            return copy;
        });
    }, []);

    const updatePendingItem = useCallback((type: TagType, value: string, newValue: string) => {
        setPending((current) => {
            const index = current.findIndex((item) => item.type === type && item.value === value);
            if (index < 0) {
                return current;
            }
            const copy = [...current];
            copy[index] = {
                type,
                value: newValue,
            };
            return copy;
        });
    }, []);

    const itemIsPending = useCallback(
        (type: TagType, value: string) => {
            const key = type + "$" + value;
            return pendingMap[key] === true;
        },
        [pendingMap]
    );

    const pendingPop = useCallback(() => {
        setPending((current) => {
            return current.slice(0, current.length - 1);
        });
    }, []);

    return {
        pending,
        addPendingItem,
        updatePendingItem,
        removePendingItem,
        itemIsPending,
        pendingPop,
        setPending,
    };
}

export function CreateEvent() {
    const [title, setTitle] = useState("");
    const [titleErrorText, setTitleErrorText] = useState("");
    const titleRef = useRef<HTMLInputElement>();

    const history = useHistory();
    const userInfo = useUserInfo();
    const organizationAccess = useOrganizationAccess();
    const canInviteMembers = organizationAccess.canEditMembers();
    const {mixpanelTrack} = useMixpanelTracker();
    // const [contactFetcherError, setContactFetcherError] = useState(false);
    // const contactFetcher = useCreateEventContactFetcher({
    //     initial: [],
    //     setError: setContactFetcherError,
    // });
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        enableEventLeaveModal(false);
        routeNavigation(undefined);
    }, []);

    const [timezone] = useState(localTimezone);

    const [startDate, setStartDate] = useState(() => {
        let now = new Date();
        const nowMin = now.getMinutes();
        const diff = 60 - nowMin;
        now = now.getMinutes() > 30 ? new Date(addMinutes(now, diff).setSeconds(0, 0)) : new Date(now.setMinutes(30, 0, 0));
        return now;
    });

    const [endDate, setEndDate] = useState(() => addMinutes(startDate, 15));

    const [timeoutNotify, setTimeoutNotify] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [createEventError, setCreateEventError] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [collaborators, setCollaborators] = useState<SessionParticipantFragment[]>([]);

    const logoDefaultURL = "https://site.sessions.flowos.com/_content/images/sessions-logo-small.svg";
    const bannerDefaultURL = "https://static.dev.sessions.flowos.com/assets/events/cover%20events%20gradient%20(1).png";

    const branding = userInfo.activeWorkspace?.branding;

    const shouldApplyCustomBranding = branding?.active;
    const customPalette = branding?.palette?.primary?.[500] && {
        primary: extractRGBObject(branding?.palette?.primary?.[500]),
    };

    const initialSessionFlags = useInitialSessionFlags(SessionSettingsSource.Event, userInfo.activeWorkspace.id);

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleTitleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        setTitleErrorText("");
    };

    const handleSubmit = async () => {
        setDisabled(true);

        const title = titleRef.current?.value;

        if (!title) {
            return;
        }

        if (title.trim().length === 0) {
            setTitleErrorText("Title can't be empty");
            return;
        }

        const titleTrimmed = title.trim();

        const organizationName = removeSpecialChars(userInfo.activeOrganizationPermission.organization.name ?? "");
        const organizationId = userInfo.activeOrganizationPermission.organization.id;

        const workspaceSlug = userInfo.activeWorkspace.slug;

        const fullUserName = removeSpecialChars(userInfo.firstName ?? "") + "-" + removeSpecialChars(userInfo.lastName ?? "");
        const eventNameForSlug = removeSpecialChars(titleTrimmed);
        const slug = composeInitialSlug(fullUserName, organizationId, organizationName, eventNameForSlug, workspaceSlug);

        const sessionId = designer.api.session.create({
            session: {
                name: titleTrimmed,
                syncToAgenda: false,
                isPrivate: false,

                event: {
                    state: SessionEventState.Draft,
                    slug: slug,
                    colorPalette: shouldApplyCustomBranding && customPalette ? customPalette : defaultPalette,
                    registrationForm: defaultEventRegistrationForm(titleTrimmed, userInfo.forms.registrationForm),
                    utm: undefined,
                    isPublic: false,
                    registrationAnswers: [],
                    photoUrl: shouldApplyCustomBranding ? branding?.logo ?? logoDefaultURL : logoDefaultURL,
                    bannerUrl: shouldApplyCustomBranding ? branding?.sessionBackground ?? bannerDefaultURL : bannerDefaultURL,
                    socialLinks: {
                        facebook: "",
                        youtube: "",
                        twitter: "",
                        linkedin: "",
                        slack: "",
                        instagram: "",
                    },
                    __typename: "SessionEvent",
                    createdAt: null as unknown as Date,
                    updatedAt: null as unknown as Date,
                    hasRegistration: true,
                    requireApproval: false,
                    id: makeId(),
                    isDeleted: false,
                    oldId: null as unknown as string,
                    update: null,
                    speakerOrderJson: {},
                    embedUrl: "",
                    payments: [],
                    afterRegistrationOfferId: null,
                    afterRegistrationRedirectUrl: null,
                    afterRegistrationOffer: null,
                    maxSeats: null,
                    seatsTaken: 0,
                    language: "en",
                },
                startAt: startDate,
                plannedEnd: endDate,
                sendEmailsAutomatically: false,
                ...initialSessionFlags,
                __typename: "Session",
            },
        });

        designer.state.initializeOrResetState(sessionId);

        if (collaborators.length) {
            designer.participantsPayload = {
                sessionId,
                upsertParticipantJSONs: [
                    ...(collaborators.map((p) => ({
                        ...p,
                        isApproved: true,
                        susbmittedPasscode: true,
                    })) ?? []),
                ],
            };
        }

        setTimeoutNotify(false);
        setTimedOut(false);
        setCreateEventError(false);
        setTooltipOpen(false);
        await designer
            .commitCreateSession({
                notifyAfterMs: 5000,
                shouldRetry: false,
                abortAfterMs: 35000,
                notifyCb: (failed, retryAttemptNo) => {
                    setTimeoutNotify((c) => (c !== failed ? failed : c));
                },
                timedOutCb: (failed, retryAttemptNo) => {
                    if (!failed) {
                        setCreateEventError(false);
                    }
                    setTimedOut((c) => (c !== failed ? failed : c));
                },
            })
            .catch((error) => {
                setCreateEventError(true);
                console.log("create event error", error);
            });

        apollo.cache.writeQuery({
            query: DesignerSessionLockedDocument,

            data: {
                __typename: "Query",
                designerSessionLocked: {
                    __typename: "DesignerSessionLocked",
                    locked: undefined,
                },
            },
            broadcast: false,
        });

        if (sessionId) {
            history.push(`/event/${sessionId}/general`);
        }
        setDisabled(false);
        mixpanelTrack("frontend-create-event", "events");
    };

    const btnDisabled = createEventError || timedOut ? false : !title || disabled || titleErrorText !== "" || timeoutNotify;

    const tooltipMsg = createEventError || timedOut ? "Could not create the event due to a network failure. Please try again." : "";

    const disableTooltip = !(createEventError || timedOut);

    const onCreateEventTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const onCreateEventTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <>
            <EventHeader />
            <div className={classes.root}>
                <div className={classes.container}>
                    <Typography className={cls(classes.title, "mt-30")} variant="xl3" fontWeight="boldest">
                        Create your new event
                    </Typography>
                    <Typography className={classes.description}>Let&apos;s start with the basic details</Typography>

                    <InputLabel htmlFor="event-title" className={cls(classes.inputLabel, "mt-30")}>
                        Event name <span>*</span>
                    </InputLabel>
                    <Input
                        id="event-title"
                        inputRef={titleRef}
                        className={classes.input}
                        placeholder="Event name"
                        maxCharCount={250}
                        showTypedCharCount
                        formControlClassName={classes.formControl}
                        onChange={handleTitleInputChange}
                        error={!!titleErrorText}
                        helperText={titleErrorText}
                        disabled={disabled}
                        // inputProps={{
                        //     "data-private": "lipsum",
                        // }}
                    />

                    <InputLabel className={cls(classes.inputLabel, "mt-20")}>
                        Date & time <span>*</span>
                    </InputLabel>
                    <EventsTimePicker
                        startDate={startDate}
                        endDate={endDate}
                        timezone={timezone}
                        disabled={disabled}
                        onDateChange={(start, end) => {
                            setStartDate(start);
                            setEndDate(end);
                        }}
                        isHalfDayClock={userInfo.halfDayClock}
                    />

                    <EventCollaborators
                        collaborators={collaborators}
                        setCollaborators={setCollaborators}
                        organizationName={userInfo.activeOrganizationPermission.organization.name}
                    />
                </div>
                <div className={classes.footer}>
                    <Button className={cls("ml-auto mr-15", common.secondaryButton, "px-22 py-12")} variant="quaternary" onClick={goBack}>
                        Cancel
                    </Button>
                    <Tooltip
                        arrow
                        placement="top"
                        title={tooltipMsg}
                        open={createEventError || timedOut || tooltipOpen}
                        onOpen={onCreateEventTooltipOpen}
                        onClose={onCreateEventTooltipClose}
                        disableFocusListener={disableTooltip}
                        disableHoverListener={disableTooltip}
                        disableTouchListener={disableTooltip}
                    >
                        <Button
                            className={cls(common.actionButton, "px-22 py-12")}
                            disabled={btnDisabled}
                            onClick={!btnDisabled ? handleSubmit : undefined}
                            loading={disabled}
                            data-id="event-button-create-event"
                        >
                            Create event
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <LeaveEventModal shouldShowPopup={false} sessionId={""} />
        </>
    );
}
