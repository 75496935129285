import {ResourcesTypes} from "@generated/artifacts/resources";
import React from "@workhorse/api/rendering";
import {openExternalLink} from "@workhorse/util/links";
import ExternalGenericCreateForm from "./components/ExternalGenericCreateForm";

type AdobeXDCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
};

const AdobeXDCreateForm = (props: AdobeXDCreateFormProps) => {
    const title = "Export from Adobe XD";
    const description = "Use our plugin to directly import your Adobe XD designs to Sessions.";
    const primaryActionLabel = "Get plugin";

    const primaryAction = () => {
        openExternalLink("https://exchange.adobe.com/creativecloud/plugindetails.html/app/cc/b5b659ac");
    };

    return (
        <ExternalGenericCreateForm
            {...props}
            title={title}
            description={description}
            primaryActionLabel={primaryActionLabel}
            primaryAction={primaryAction}
        />
    );
};
export default AdobeXDCreateForm;
