import {OrganizationPermissionType} from "@generated/data";
import {UserInfo} from "@workhorse/providers/User/UserInfoProvider";
import {AccessUtilities} from "./AccessUtilities";
import {OrganizationAccess} from "./OrganizationAccess";
import {WorkspaceAccess} from "./WorkspaceAccess";

const mockedPermission = <UserInfo["organizationPermissions"][0]>{
    id: "mocked",
    organization: {
        id: "mocked",
        isActivated: false,
        onboardingCompleted: false,
        __typename: "Organization",
    },
    permissionType: OrganizationPermissionType.Member,
    __typename: "UserToOrganizationPermission",
};

export class Access extends AccessUtilities {
    private organizations: Map<string, OrganizationAccess> = new Map();
    private workspaces: Map<string, WorkspaceAccess> = new Map();

    constructor(private user: UserInfo) {
        super();
    }

    public organizationById(organizationId: string): OrganizationAccess {
        const organizationAccess = this.organizations.get(organizationId);

        if (organizationAccess) {
            return organizationAccess;
        }

        const permission =
            this.user.id === "mocked"
                ? mockedPermission
                : this.user.organizationPermissions.find((p) => p.organization.id === organizationId);

        if (!permission) {
            throw new Error(`User ${this.user.id} does not have access to organization ${organizationId}`);
        }

        return new OrganizationAccess(this.user.id, permission);
    }

    public workspaceById(workspaceId: string): WorkspaceAccess {
        const workspaceAccess = this.workspaces.get(workspaceId);

        if (workspaceAccess) {
            return workspaceAccess;
        }

        const permission = this.user?.activeWorkspacePermissions;

        if (!permission) {
            throw new Error(`User ${this.user.id} does not have access to workspace ${workspaceId}`);
        }

        return new WorkspaceAccess(this.user.id, permission.role);
    }

    public organization(): OrganizationAccess {
        const organizationId = this.user.activeOrganizationPermission.organization.id;
        return this.organizationById(organizationId);
    }

    public workspace(): WorkspaceAccess {
        const workspaceId = this.user.activeWorkspace.id;
        return this.workspaceById(workspaceId);
    }
}
