import {useTranslation} from "react-i18next";
import {ReactComponent as RecordingConferenceIcon} from "../../../../../assets/media/recording-conference.svg";
import {RecordingViewSelect, RecordingView} from "./RecordingViewSelect";

import {ReactComponent as SessionViewCollaborationConferenceIcon} from "../../../../../assets/media/session-view-collaboration-conference.svg";
import {ReactComponent as SessionViewPresentationConferenceIcon} from "../../../../../assets/media/session-view-presentation-conference.svg";
import {ReactComponent as SessionViewAudienceConferenceIcon} from "../../../../../assets/media/session-view-audience-conference.svg";

interface Props {
    value?: string | null;
    className?: string;
    onChange: (val: RecordingView) => void;
}

export function RecordingConferenceViewSelect(props: Props) {
    const {t} = useTranslation();

    return (
        <RecordingViewSelect
            title={t("player.settings.general.layout.conference.title")}
            description={t("player.settings.general.layout.conference.description")}
            icon={<RecordingConferenceIcon />}
            value={props.value}
            defaultValue={RecordingView.Gallery}
            onChange={props.onChange}
            options={[
                {
                    value: RecordingView.Gallery,
                    text: t("header.session_view.collaboration"),
                    icon: SessionViewCollaborationConferenceIcon,
                },
                {
                    value: RecordingView.Spotlight,
                    text: t("header.session_view.spotlight"),
                    icon: SessionViewPresentationConferenceIcon,
                },
                {
                    value: RecordingView.Audience,
                    text: t("header.session_view.audience"),
                    icon: SessionViewAudienceConferenceIcon,
                },
            ]}
        />
    );
}
