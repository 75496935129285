import {useRoomStore} from "./ConferenceRoomProvider";
import {isAnyLocationVisible, createDefaultVisibilityLocations} from "./ConferenceRoomVisibility";

export function useConferenceStatusMap() {
    return useRoomStore((state) => state.remoteStatusMap);
}

export function useConferenceParticipant(participantId: string) {
    const value = useRoomStore((state) => state.publicationMap[participantId]);

    return {
        ...value,
        hasCamera: value?.camera != null,
        hasMic: value?.microphone != null,
        isSharing: value?.screen_share != null,
        isSharingAudio: value?.screen_share_audio != null,
    };
}

export function useInvididualCameraStreamPauseToggler() {
    return useRoomStore((state) => state.individualTogglePauseIncomingStream);
}

export function useGlobalVideoState() {
    return useRoomStore((state) => state.disableAllIncomingVideos);
}

export function useGlobalVideoStateSetter() {
    return useRoomStore((state) => state.pauseIncomingVideos);
}

export function useRemoteParticipantStatus(participantId: string) {
    const value = useRoomStore((state) => state.remoteStatusMap[participantId]);
    return {
        ...value,
    };
}

export function useParticipantVolumes() {
    return useRoomStore((state) => state.volumes);
}

export function useParticipantSpeakerScores() {
    return useRoomStore((state) => state.activeSpeakersScore);
}

export function useParticipantVolume(participantId: string) {
    const volume = useRoomStore((state) => state.volumes[participantId]);
    return volume ?? 0;
}

export function useRoomSendData() {
    return useRoomStore((state) => state.sendData);
}

export function useRoomSetVolume() {
    return useRoomStore((state) => state.setVolume);
}

export function useParticipantVisibility(participantId: string) {
    const value = useRoomStore((state) => state.visibilityMap[participantId]);
    return {isVisible: isAnyLocationVisible(value), locations: value ?? createDefaultVisibilityLocations()};
}

export function useParticipantSetVisibility() {
    return useRoomStore((state) => state.setVisibility);
}

export function useConferenceRoomCreateOrDelete() {
    const deleteRoom = useRoomStore((state) => state.deleteRoom);
    const createRoom = useRoomStore((state) => state.createRoom);
    return {createRoom, deleteRoom};
}

export function useRoomShareIdentity() {
    return useRoomStore((state) => state.sharingState);
}
