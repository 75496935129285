import {getDesktopApiOrThrow, isDesktopApp} from "@sessions/desktop-api";
import {cls} from "@ui/cdk/util";
import env from "@generated/environment";

export const artifactPluginLinks = {
    "flowos/figma": "https://www.figma.com/community/plugin/1011512061726401104/Sessions",
    "flowos/canva": "https://www.canva.com/apps/AAEmyTgqqBk-sessions",
    "flowos/adobexd": "https://exchange.adobe.com/apps/cc/b5b659ac",
};

const isApp = isDesktopApp();

/**
 * Opens external link in browser in a new tab. If app is running in desktop mode, it will open external link in default browser.
 * @param url - url to open
 *
 **/

export const openExternalLink = (url: string) => {
    if (isApp) {
        const openExternal = getDesktopApiOrThrow()?.openExternal;
        openExternal(url);
    } else {
        window.open(url, "_blank");
    }
};

export const openExternalAppDownload = () => {
    window.open(env.desktopAppDownloadURL, "_blank");
};

/**
 * Anchor element that opens external link in new tab or in default browser if app is running in desktop mode.
 *
 **/

export const HyperlinkExternal = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
    const {href, ...rest} = props;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        openExternalLink(href ?? "");

        props.onClick?.(e);
    };

    return <a {...rest} className={cls("cursor-pointer", props.className)} href={isApp ? undefined : href} onClick={handleClick} />;
};
