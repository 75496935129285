import {ReactNode, useState} from "@workhorse/api/rendering";
import UserDeviceSettingsStorageProvider from "@workhorse/providers/UserDeviceSettingsStorageProvider";

import {ActiveSpeakersProvider} from "../ActiveSpeakers";
import {ControlsProvider} from "../ControlsProvider";
import {JoinedFromOtherDeviceProvider} from "../JoinedFromOtherDeviceProvider";
import {LonelinessProvider} from "../LonelinessProvider";
import {MediaProvider} from "../MediaProvider";
import {MeetingQueueProvider} from "../MeetingQueueProvider";
import {MuteProvider} from "../MuteProvider";
import {ParticipantActionsProvider} from "../ParticipantsProvider";
import {ParticipantsVisibilityProvider} from "../ParticipantVisibilityProvider";
import {VideoDisableProvider} from "../VideoDisableProvider";
import {VideoTransformProvider} from "../VideoTransformToggleProvider";
import {CombinedProviders, withProps} from "@workhorse/api/utils/provider";
import {chimeIsBlocked} from "@workhorse/providers/PresenceProvider";
import {ConferenceRoomProvider} from "../ConferenceRoomProvider";
import {ConferenceRoomLocalVideoProvider} from "../ConferenceRoomProvider/ConferenceRoomLocalVideoProvider";
import {ConferenceRoomLocalAudioProvider} from "../ConferenceRoomProvider/ConferenceRoomLocalAudioProvider";
import {ConferenceRoomShareScreenProvider} from "../ConferenceRoomProvider/ConferenceRoomShareScreenProvider";

export function MeetingProvider({children}: {children: ReactNode}) {
    return (
        <CombinedProviders
            providers={[
                UserDeviceSettingsStorageProvider,
                ConferenceRoomProvider,
                ConferenceRoomLocalVideoProvider,
                ConferenceRoomLocalAudioProvider,
                ConferenceRoomShareScreenProvider,
                VideoTransformProvider,
                VideoDisableProvider,
                MediaProvider,
                ActiveSpeakersProvider,
                ParticipantsVisibilityProvider,
                MuteProvider,
                ControlsProvider,
                JoinedFromOtherDeviceProvider,
                MeetingQueueProvider,
                ParticipantActionsProvider,
                LonelinessProvider,
            ]}
        >
            {children}
        </CombinedProviders>
    );
}
