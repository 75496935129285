import Loading from "@workhorse/components/Loading";
import fallbackClasses from "./styles/OnboardingStepFallback.module.scss";

const OnboardingStepFallback = (
    <div className={fallbackClasses.width}>
        <Loading />
    </div>
);

export default OnboardingStepFallback;
