import {SpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {WorkspaceRole, WorkspacePermission, WorkspaceRoleInfoFragment} from "@generated/data";
import {UserInfo} from "@workhorse/providers/User/UserInfoProvider";
import {ResourceFullWithContent} from "../resx/utils";
import toast from "../toast";
import {AccessUtilities} from "./AccessUtilities";

type Resource = ResourceFullWithContent<keyof SpecifiersTypeMap, "v1" | "latest">;

export class WorkspaceAccess extends AccessUtilities {
    public role: WorkspaceRoleInfoFragment;

    constructor(public userId: string, role: WorkspaceRoleInfoFragment) {
        super();
        this.role = role;
    }

    hasPermission = (permission: WorkspacePermission) => {
        return this.role.permissions.includes(permission);
    };

    /** Custom branding **/

    canSeeCustomBranding = () => {
        return this.hasPermission(WorkspacePermission.ManageBranding);
    };

    canSeeCustomDomain = () => {
        return this.hasPermission(WorkspacePermission.ManageCustomSubdomain);
    };

    /** My Product tool **/

    canSeeMyProductTool = () => {
        return this.hasPermission(WorkspacePermission.ManageMyProductTool);
    };

    /** Resources **/

    canEditResource = (resource?: Resource) => {
        return resource?.owner?.id === this.userId;
    };

    canDeleteResource = (resource?: Resource) => {
        return resource?.owner?.id === this.userId;
    };

    canShareResources = () => {
        return this.hasPermission(WorkspacePermission.ManageResources);
    };

    canSeeOthersSessionsInResxHistory = () => {
        // TODO: maca what this
        return this.hasPermission(WorkspacePermission.AccessMemberContacts);
    };

    canAccessMemberContacts = () => {
        return this.hasPermission(WorkspacePermission.AccessMemberContacts);
    };

    canManageRoles = () => {
        return this.hasPermission(WorkspacePermission.ManageRoles);
    };

    /** Agendas **/

    canShareAgendas = () => {
        return this.hasPermission(WorkspacePermission.ManageAgendaTemplates);
    };

    canShareAgenda = (agendaOwnerId: string) => {
        return this.userId === agendaOwnerId;
    };

    canShareAgendaWithWorkspace = (agendaOwnerId: string) => {
        return this.canShareAgendas() && this.userId === agendaOwnerId;
    };

    canEditAgenda = (agendaOwnerId: string, preComputedAccess: boolean) => {
        return preComputedAccess || this.userId === agendaOwnerId;
    };

    canDeleteAgenda = (agendaOwnerId: string) => {
        return this.userId === agendaOwnerId;
    };
    canCreateBookings = () => {
        return this.hasPermission(WorkspacePermission.CreateBookings);
    };

    canCreateEvents = () => {
        return this.hasPermission(WorkspacePermission.CreateEvents);
    };

    canCreateRooms = () => {
        return this.hasPermission(WorkspacePermission.CreateRooms);
    };
    canCreateSessions = () => {
        return this.hasPermission(WorkspacePermission.CreateSessions);
    };

    canManageMembers = () => {
        return this.hasPermission(WorkspacePermission.ManageMembers);
    };

    canManageRooms = () => {
        return this.hasPermission(WorkspacePermission.ManageRooms);
    };

    canManageEvents = () => {
        return this.hasPermission(WorkspacePermission.ManageEvents);
    };

    canManageBookings = () => {
        return this.hasPermission(WorkspacePermission.ManageBookings);
    };

    canUseRecording = () => {
        return this.hasPermission(WorkspacePermission.UseRecording);
    };

    /** Rooms **/
    canShareRoom = (roomOwnerId: string) => {
        return this.userId === roomOwnerId;
    };

    canShareRoomWithWorkspace = (roomOwnerId: string) => {
        return this.userId === roomOwnerId;
    };

    canToggleRoom = (roomOwnerId: string) => {
        return this.userId === roomOwnerId;
    };

    canCloneRoom = (roomOwnerId: string) => {
        return this.userId === roomOwnerId;
    };

    canEditRoom = (roomOwnerId: string) => {
        return this.userId === roomOwnerId;
    };

    canDeleteRoom = (roomOwnerId: string) => {
        return this.userId === roomOwnerId;
    };

    canManageWebhooks = () => {
        return this.hasPermission(WorkspacePermission.WebhookAccess);
    };

    canSeeReferralProgram = () => {
        return this.hasPermission(WorkspacePermission.ReferralAccess);
    };

    canAddSmsReminder = () => {
        return this.hasPermission(WorkspacePermission.AddSmsReminder);
    };

    canListWorkspaceBookings = () => {
        return this.hasPermission(WorkspacePermission.ListWorkspaceBookings);
    };

    triggerGenericAccessToast = (message?: string) => {
        toast(message ?? "You don't have permission to access this feature. Please contact your workspace admin.", {
            type: "error",
        });
    };
}
