import {useCallback, useEffect} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";
import {fullScreenToggle, useFullScreenState} from "./state";

function WithDOMEvents(props: WithChildren) {
    const fullScreenListener = useCallback((method: "add" | "remove" = "add") => {
        const op = method === "add" ? "addEventListener" : "removeEventListener";
        const node = document.documentElement;
        if (!node) {
            return;
        }
        if (typeof node.requestFullscreen == "function") {
            document[op]("fullscreenchange", fullScreenToggle, false);
        } else if (typeof (node as any).webkitRequestFullscreen === "function") {
            document[op]("webkitfullscreenchange", fullScreenToggle, false);
        } else if (typeof (node as any).mozRequestFullScreen === "function") {
            document[op]("mozfullscreenchange", fullScreenToggle, false);
        } else if (typeof (node as any).msRequestFullscreen === "function") {
            document[op]("MSFullscreenChange", fullScreenToggle, false);
        }
    }, []);

    useEffect(() => {
        fullScreenListener("add");
        return () => {
            fullScreenListener("remove");
        };
    }, []);

    return props.children as unknown as JSX.Element;
}

export default WithDOMEvents;
