import environment from "@generated/environment";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {ReactComponent as RadioCheckedIcon} from "../../../../../../assets/media/RadioCheckedIcon.svg";
import {ReactComponent as RadioUncheckedIcon} from "../../../../../../assets/media/RadioUncheckedIcon.svg";
import OnboardingPersonaWrapper from "./OnboardingPersonaWrapper";
import classes from "./styles/OnboardingPersonaUseCase.module.scss";
import {industryOptions} from "./utils";
import {useState} from "@workhorse/api/rendering";

type OnboardingPersonaIndustryProps = {
    onClickStep: (step: number) => void;
    value: string;
    onChangeValue: (value: string) => void;
    otherValue: string;
    onChangeOtherValue: (value: string) => void;
    onSubmit: () => void;
    step: 1 | 2 | 3 | 4;
};

function OnboardingPersonaIndustry(props: OnboardingPersonaIndustryProps) {
    const {onClickStep, value, onChangeValue, onSubmit, step, otherValue, onChangeOtherValue} = props;

    const [otherError, setOtherError] = useState<string | null>(null);

    const handleChangeOtherValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const targetValue = e.target.value;

        onChangeOtherValue(targetValue);

        if (value === "other" && !targetValue.trim().length) {
            setOtherError("This field is required!");

            return;
        }

        setOtherError(null);
    };

    const handleSubmit = () => {
        if (value === "other" && !otherValue.trim().length) {
            setOtherError("This field is required!");

            return;
        }

        onSubmit();
    };

    const previewTitle = (
        <>
            Let you participants <span className={classes.blue}>join with just two clicks.</span>
        </>
    );

    return (
        <OnboardingPersonaWrapper
            step={step}
            preview={{
                title: previewTitle,
                description:
                    "There’s no need for them to download anything or sign up, they can simply hop into a session via their computer or mobile device.",
                body: `${environment.staticServiceUrl}/assets/onboarding/onboarding-role.png`,
            }}
            onClickStep={onClickStep}
        >
            <Typography variant="xl2" fontWeight="bolder" className="mb-16">
                What kind of work do you do?
            </Typography>

            <div className={classes.useCaseOptionContainer}>
                {industryOptions.map((o) => (
                    <div className={cls(classes.useCase, classes.useCaseOption)} onClick={() => onChangeValue(o.value)} key={o.value}>
                        <Typography color="secondary" fontWeight="bold" className="mr-auto">
                            {o.text}
                        </Typography>

                        {value === o.value ? <RadioCheckedIcon className={classes.checkedRadio} /> : <RadioUncheckedIcon />}
                    </div>
                ))}
            </div>

            {value === "other" && (
                <>
                    <Input
                        name="industryOther"
                        formControlClassName="mt-20 p-0"
                        className={cls(classes.input, otherError ? classes.inputError : "")}
                        placeholder="Other"
                        value={otherValue}
                        onChange={handleChangeOtherValue}
                        maxCharCount={60}
                        showTypedCharCount
                        required
                        error={!!otherError}
                    />

                    {otherError ? <Typography className={classes.errorText}>{otherError}</Typography> : null}
                </>
            )}

            <Button disabled={!value} onClick={handleSubmit} className={classes.button} variant="secondary">
                Continue
            </Button>
        </OnboardingPersonaWrapper>
    );
}

export default OnboardingPersonaIndustry;
