import classes from "../styles/PlayerHeader.module.scss";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import {useEffect, useState} from "@workhorse/api/rendering";
import {beautifySeconds} from "@workhorse/util/time";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useRecordingTimer} from "@workhorse/providers/RecordingTimerProvider";
import {SessionRecordingState, SessionRecordingType} from "@generated/data";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography/Typography";
import {useTranslation} from "react-i18next";
import {cls} from "@ui/cdk/util";

import {ReactComponent as LivestreamIcon} from "../../../../assets/media/livestream-icon.svg";
import {ReactComponent as LivestreamRecordingIcon} from "../../../../assets/media/livestream-recording-icon.svg";

function ElapsedTime() {
    const {t} = useTranslation();
    const {childOfBreakoutRooms} = useSession();
    const {getElapsedTime} = useRecordingTimer();
    const {recordingState} = useRecordingProcessor();
    const [count, setCount] = useState(0);

    useEffect(() => {
        function updateCount() {
            const newCount = getElapsedTime();
            setCount(newCount ?? 0);
        }

        const interval = setInterval(updateCount, 1000);
        updateCount();

        return () => {
            clearInterval(interval);
        };
    }, []);

    if (childOfBreakoutRooms?.id) {
        return null;
    }

    if (recordingState === SessionRecordingState.Starting) {
        return <>{t("recording.starting")}</>;
    }

    if (recordingState === SessionRecordingState.Stopping) {
        return <>{t("recording.stopping")}</>;
    }

    return <>{beautifySeconds(count)}</>;
}

type RecordingIndicatorProps = {
    isMobile: boolean;
    participantId: string;
    isPreJoin: boolean;
    recordingType?: SessionRecordingType;
};

export function RecordingIndicator(props: RecordingIndicatorProps) {
    const {isMobile, isPreJoin, recordingType} = props;

    const {t} = useTranslation();

    const {isActive, recordingState} = useRecordingProcessor();

    if (!recordingState) {
        return null;
    }

    const isStarting = recordingState === SessionRecordingState.Starting;
    const isStopping = recordingState === SessionRecordingState.Stopping;
    const isStartingOrStopping = isStarting || isStopping;

    if (!isActive && !isStartingOrStopping) {
        return null;
    }

    const actionText =
        (recordingType === SessionRecordingType.Livestream
            ? t("player.notifications.recording.status.live_streaming") || "Live stream"
            : recordingType === SessionRecordingType.Recording
            ? t("player.notifications.recording.status.recording") || "Recording"
            : t("player.notifications.recording.status.live_streaming_recording") || "Live streaming and recording") + " - ";

    const title = isActive ? (
        <>
            {actionText} <ElapsedTime />
        </>
    ) : (
        ""
    );

    return (
        <Tooltip title={title} placement="bottom">
            <div
                data-id="recording-indicator"
                className={cls(
                    classes.recordingIcon,
                    (isStartingOrStopping || (isPreJoin && !isMobile)) && classes.recordingIconWithText,
                    recordingType === SessionRecordingType.Livestream && classes.livestreamingIcon,
                    recordingType === SessionRecordingType.RecordingLivestream && classes.livestreamingRecordingIcon
                )}
            >
                {recordingType === SessionRecordingType.Livestream ? (
                    <LivestreamIcon />
                ) : recordingType === SessionRecordingType.Recording ? (
                    <RadioButtonCheckedRoundedIcon />
                ) : (
                    <LivestreamRecordingIcon />
                )}

                {!isPreJoin && isStartingOrStopping && (
                    <Typography fontWeight="bold">{t(isStarting ? "recording.starting" : "recording.stopping")}</Typography>
                )}
                {isPreJoin && !isMobile && (
                    <Typography color="primary" fontWeight="bold" className="ml-4 mr-4 nowrap">
                        {recordingType === SessionRecordingType.Livestream
                            ? t("participant.recording.livestream_active")
                            : recordingType === SessionRecordingType.Recording
                            ? t("participant.recording.recording_active")
                            : t("participant.recording.livestream_recording_active")}
                    </Typography>
                )}
            </div>
        </Tooltip>
    );
}
