import loadable from "@workhorse/api/loadable";
import {HomeRouteMatch, RouteHOCProps} from "@workhorse/declarations";

// there is no guarantee the user will land on the Home every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const Home = loadable(() => import("./Home"));

type HomeHOCProps = RouteHOCProps<HomeRouteMatch>;

// a HOC for the Base and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child
function HomeHOC(props: HomeHOCProps): JSX.Element {
    return (
        <div className="route-hoc flex-align-start home-hoc" key="home-hoc">
            <Home key="home-entry-point" />
        </div>
    );
}

export default HomeHOC;
