// DO NOT rely on this if your object/array contains functions or symbols
export const deepCompare = (arg1: object | [], arg2: object | []): boolean => {
    if ((Array.isArray(arg1) && !Array.isArray(arg2)) || typeof arg1 !== typeof arg2) {
        return false;
    }
    const keys = Object.keys(arg1);
    const keys2 = Object.keys(arg2);
    if (keys.length !== keys2.length) {
        return false;
    } else {
        const result = keys.reduce((res, key) => res + (keys2.indexOf(key) == -1 ? 1 : 0), 0);
        if (result !== 0) {
            // keys.lengths are equal but keys are different
            return false;
        }
    }

    let equal = true;
    for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        if (typeof arg1[k] !== typeof arg2[k]) {
            equal = false;
            break;
        }
        if (typeof arg1[k] == "object" && arg1[k] !== null) {
            if (arg2[k] == null) {
                equal = false;
                break;
            }
            equal = deepCompare(arg1[k], arg2[k]);
            if (!equal) {
                break;
            }
        }
        equal = arg1[k] === arg2[k];
        if (!equal) {
            break;
        }
    }
    return equal;
};
