import LocalParticipantIcons from "./LocalParticipantIcons";
import RemoteParticipantIcons from "./RemoteParticipantIcons";

type AudienceParticipantIconsProps = {
    className?: string;
    isLocalParticipant: boolean;
    participantId: string;
    isThumbnailMode?: boolean;
};

export default function ConferenceParticipantStatus(props: AudienceParticipantIconsProps) {
    if (props.isLocalParticipant) {
        return (
            <LocalParticipantIcons
                className={props.className}
                isThumbnailMode={props.isThumbnailMode}
                participantId={props.participantId}
            />
        );
    }

    return (
        <RemoteParticipantIcons
            className={props.className}
            participantId={props.participantId ?? null}
            isThumbnailMode={props.isThumbnailMode}
        />
    );
}
