import descriptor, {ArtifactTag, default as ArtifactsMap} from "@generated/artifacts";
import {FullSessionQuery, MyProductToolsDocument} from "@generated/data";
import FilterListIcon from "@material-ui/icons/FilterList";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import {capitalize, cls} from "@ui/cdk/util/util";
import MenuItem from "@ui/core/components/MenuItem";
import Select from "@ui/core/components/Select";
import {useQuery} from "@workhorse/api/data";
import {useEffect, useRef, useState, useMemo} from "@workhorse/api/rendering";
import {QueryPayload} from "@workhorse/dataApi";
import Artifact from "@workhorse/pages/designer/Artifacts/DesignerArtifact";
import {getDesignerArtifact} from "@workhorse/providers/DesignerSessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import classes from "./styles/DesignerToolSelector.module.scss";

enum ArtifactsFilteringType {
    all = "All tools",
    available = "Available",
    soon = "Coming soon",
}

enum ArtifactsSortingType {
    popularity = "Popularity",
    alphabetical = "A - Z",
    alphabeticalReverse = "Z - A",
}

enum ArtifactsLabels {
    all = "all",
    presentation = "presentation",
    collaboration = "collaboration",
    media = "media",
    prototyping = "prototyping",
}

interface DesignerToolSelectorProps {
    artifactId: string;
    agendaItemId?: string;
    macroArtifacts: FullSessionQuery["macroArtifacts"];
    childOfBreakoutRooms: QueryPayload<"GetSessionDocument">["data"]["session"]["childOfBreakoutRooms"];
    onArtifactSelect: (artifactId: string, myProductToolId?: string) => void;
    inPopover?: boolean;
    onClose?: () => void;
}

function DesignerToolSelector(props: DesignerToolSelectorProps) {
    const {artifactId, onArtifactSelect, agendaItemId, macroArtifacts, childOfBreakoutRooms, inPopover} = props;

    const artifact = getDesignerArtifact(artifactId);

    const user = useUserInfo();

    const [filteringCriteria, setFilteringCriteria] = useState<ArtifactsFilteringType>(ArtifactsFilteringType.all);
    const [sortingCriteria, setSortingCriteria] = useState<ArtifactsSortingType>(ArtifactsSortingType.popularity);
    const [labelCriteria, setLabelCriteria] = useState<ArtifactsLabels>(ArtifactsLabels.all);

    const [selectedArtifactTag, setSelectedArtifactTag] = useState<string | undefined>(artifact?.artifactId);

    const myProductToolArtifactDescriptor = Object.values(ArtifactsMap).find(
        (artifact) => artifact.meta.labels["artifacts.sessions.flowos.com/my-product-tool"] != null
    );

    const shouldQueryForMyProductTools = myProductToolArtifactDescriptor != null;

    const {data: myProductToolsQuery} = useQuery(MyProductToolsDocument, {
        skip: !shouldQueryForMyProductTools,
        variables: {
            workspaceId: user.activeWorkspace.id,
        },
    });

    const handleSelectArtifact = (artifactId: string, myProductToolId?: string) => {
        if (selectedArtifactTag !== artifactId) {
            setSelectedArtifactTag(artifactId);
        }
        onArtifactSelect(artifactId, myProductToolId);
    };

    const handleChangeFilteringCriteria = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilteringCriteria(event.target.value as unknown as ArtifactsFilteringType);
    };

    const handleChangeSortingCriteria = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortingCriteria(event.target.value as unknown as ArtifactsSortingType);
    };

    const handleChangeLabelCriteria = (label: string, event: React.MouseEvent<HTMLDivElement>) => {
        setLabelCriteria(label as unknown as ArtifactsLabels);
    };

    const scrollContainerRef = useRef<ScrollContainerRef>({});

    const configArtifactId = props.artifactId;

    const macrosInSession = macroArtifacts.map((artifact) => descriptor[artifact.artifactId as ArtifactTag].tag) || [];

    const microArtifacts = Object.values(ArtifactsMap).filter(
        (artifact) =>
            artifact.supportedTypes.some((supportedType) => supportedType.type === "micro") &&
            !artifact.disabled &&
            (childOfBreakoutRooms == null || artifact.tag !== "flowos/breakout") &&
            !artifact.meta.labels["artifacts.sessions.flowos.com/my-product-tool"]
    );

    useMemo(() => {
        if (myProductToolsQuery && myProductToolArtifactDescriptor) {
            microArtifacts.push(
                ...myProductToolsQuery.myProductTools
                    .filter((p) => p.enabled && p.url.length)
                    .map((tool) => {
                        return {
                            ...myProductToolArtifactDescriptor,
                            meta: {
                                ...myProductToolArtifactDescriptor.meta,
                                labels: {
                                    ...myProductToolArtifactDescriptor.meta.labels,
                                    "artifacts.sessions.flowos.com/custom-icon-url": tool.iconUrl,
                                    "artifacts.sessions.flowos.com/custom-name": tool.title,
                                    "artifacts.sessions.flowos.com/custom-description": tool.description,
                                    "artifacts.sessions.flowos.com/my-product-tool-id": tool.id,
                                },
                            },
                        };
                    })
            );
        }
    }, [myProductToolsQuery, myProductToolArtifactDescriptor, microArtifacts]);
    const [filteredArtifacts, setFilteredArtifacts] = useState([...microArtifacts]);

    const filterArtifacts = () => {
        const artifacts = microArtifacts
            .filter((a) => a.tag !== "flowos/conference") // Because conference is added by default
            .filter((a) => {
                const maturity = a.meta.labels["artifacts.sessions.flowos.com/maturity"];
                switch (filteringCriteria) {
                    case ArtifactsFilteringType.all:
                        return true;
                    case ArtifactsFilteringType.available:
                        return (maturity === undefined ? 10 : maturity) > 5;
                    case ArtifactsFilteringType.soon:
                        return (maturity === undefined ? 10 : maturity) <= 5;
                    default:
                        return true;
                }
            });
        return artifacts;
    };

    const filterArtifactsByLabel = (artifacts) => {
        if (labelCriteria === ArtifactsLabels.all) {
            return artifacts;
        }
        return artifacts.filter((a) => !!a.meta.labels[`artifacts.sessions.flowos.com/categories`]?.split(",").includes(labelCriteria));
    };

    const sortArtifacts = (artifacts) => {
        return artifacts.sort((a, b) => {
            switch (sortingCriteria) {
                case ArtifactsSortingType.popularity:
                    return (
                        (b?.meta?.labels?.["artifacts.sessions.flowos.com/popularity"] || 0) -
                        (a?.meta?.labels?.["artifacts.sessions.flowos.com/popularity"] || 0)
                    );
                case ArtifactsSortingType.alphabetical:
                    return a.meta.displayName.toLowerCase() < b.meta.displayName.toLowerCase() ? -1 : 1;
                case ArtifactsSortingType.alphabeticalReverse:
                    return a.meta.displayName.toLowerCase() > b.meta.displayName.toLowerCase() ? -1 : 1;
                default:
                    return (
                        (b?.meta?.labels?.["artifacts.sessions.flowos.com/popularity"] || 0) -
                        (a?.meta?.labels?.["artifacts.sessions.flowos.com/popularity"] || 0)
                    );
            }
        });
    };

    useEffect(() => {
        setFilteredArtifacts(sortArtifacts(filterArtifactsByLabel(filterArtifacts())));
    }, [filteringCriteria, sortingCriteria, labelCriteria, myProductToolsQuery?.myProductTools]);

    // const handleRemoveArtifact = () => {
    //     if (agendaItemId) {
    //         updateAgendaItem({
    //             id: agendaItemId,
    //             agendaItem: {
    //                 artifact: null,
    //             },
    //         });
    //         if (props.onClose) {
    //             props.onClose();
    //         }
    //     }
    // };

    return (
        <div className={classes.root} data-id="designer-tool-selector">
            {inPopover ? null : (
                <div className={cls("flex flex-justify-between", classes.headerContainer)}>
                    <Select
                        key="select-artifacts-sorting-criteria"
                        className={cls(classes.selectContainer, classes.sortContainer)}
                        variant="standard"
                        disableUnderline
                        value={sortingCriteria}
                        classes={{
                            icon: classes.selectContainerIcon,
                        }}
                        onChange={handleChangeSortingCriteria}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                            getContentAnchorEl: null,
                            classes: {
                                paper: cls(classes.sortContainer, classes.selectPaper),
                            },
                        }}
                    >
                        <MenuItem value={ArtifactsSortingType.popularity}>{ArtifactsSortingType.popularity}</MenuItem>
                        <MenuItem value={ArtifactsSortingType.alphabetical}>{ArtifactsSortingType.alphabetical}</MenuItem>
                        <MenuItem value={ArtifactsSortingType.alphabeticalReverse}>{ArtifactsSortingType.alphabeticalReverse}</MenuItem>
                    </Select>

                    <div className={classes.labels}>
                        {Object.keys(ArtifactsLabels).map((label) => (
                            <div
                                key={label}
                                className={cls(classes.label, labelCriteria === label && classes.active)}
                                onClick={handleChangeLabelCriteria.bind(this, label)}
                            >
                                {capitalize(label)}
                            </div>
                        ))}
                    </div>

                    <Select
                        key="select-artifacts-filtering-criteria"
                        className={classes.selectContainer}
                        startAdornment={<FilterListIcon className={classes.filterContainerIcon} />}
                        variant="standard"
                        disableUnderline
                        value={filteringCriteria}
                        classes={{
                            icon: classes.selectContainerIcon,
                        }}
                        onChange={handleChangeFilteringCriteria}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                            getContentAnchorEl: null,
                            classes: {
                                paper: cls(classes.filterContainer, classes.selectPaper),
                            },
                        }}
                    >
                        <MenuItem value={ArtifactsFilteringType.all}>{ArtifactsFilteringType.all}</MenuItem>
                        <MenuItem value={ArtifactsFilteringType.available}>{ArtifactsFilteringType.available}</MenuItem>
                        <MenuItem value={ArtifactsFilteringType.soon}>{ArtifactsFilteringType.soon}</MenuItem>
                    </Select>
                </div>
            )}

            <ScrollContainer
                shadowOnScroll={{showOn: "both"}}
                shadowGradient="grey"
                connector={scrollContainerRef}
                className={cls(classes.scrollContainer, inPopover && classes.scrollContainerInPopover)}
                isMobile={false}
            >
                <div className={cls(classes.artifactsContainer, inPopover && classes.artifactsContainerInPopover)}>
                    {filteredArtifacts.length ? (
                        filteredArtifacts.map((artifactDescriptor) => (
                            <Artifact
                                macrosInSession={macrosInSession}
                                type={"micro"}
                                key={artifactDescriptor.tag + agendaItemId}
                                inPopover={inPopover}
                                artifactDescriptor={artifactDescriptor}
                                hideSelectionInfo={true}
                                selectArtifact={handleSelectArtifact}
                                selectedArtifactTag={selectedArtifactTag}
                                isSelected={selectedArtifactTag === artifactDescriptor.tag}
                            />
                        ))
                    ) : (
                        <div>No artifacts found</div>
                    )}
                </div>
            </ScrollContainer>
        </div>
    );
}

export default DesignerToolSelector;
