import {makeStyles} from "@material-ui/core";
import LinearProgress from "@ui/cdk/LinearProgress";
import Typography from "@ui/cdk/Typography";
import {WithChildren} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        height: "100%",
        width: 461,
        "&.fosTypography": {
            margin: "0 auto",
        },
    },
    progressBar: {
        width: 165,
    },
    title: {
        fontWeight: 500,
        letterSpacing: "0.01em",
        color: theme.main.palette.text.secondary,
        "&.fosTypography": {
            marginBottom: 14,
        },
    },
    description: {
        fontWeight: 500,
        letterSpacing: "0.01em",
        color: theme.main.palette.text.senary,
        textAlign: "center",
        "&.fosTypography": {
            marginBottom: 57,
        },
    },
}));

type ProcessingComponentProps = {
    title?: string;
    content?: string;
} & WithChildren;

const ProcessingComponent = (props: ProcessingComponentProps) => {
    const classes = useStyles();

    return (
        <Typography
            component="div"
            variant="lg"
            data-id="processing-conversions"
            className={cls(classes.root, "fullw flex flex-center-all")}
        >
            <div className="flex flex-center-all flex-col">
                <Typography variant="xl2" className={classes.title}>
                    {props.title ?? "Just a second..."}
                </Typography>
                <Typography className={classes.description}>
                    {props.content ??
                        "Conversions may take a while. You can continue with the Agenda configuration and we'll attach the resource when it's done."}
                </Typography>
                <LinearProgress className={classes.progressBar} progressHeight={4} key="processing-progress" />
            </div>
        </Typography>
    );
};

export default ProcessingComponent;
