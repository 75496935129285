import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {forwardRef, useMemo} from "@workhorse/api/rendering";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import classes from "./styles/LobbyOwnerAvatar.module.scss";
import {useShallow} from "zustand/react/shallow";

type OwnerAvatarProps = JSX.IntrinsicElements["div"];

function OwnerAvatar(props: OwnerAvatarProps, ref: React.MutableRefObject<HTMLDivElement> | null) {
    const {firstName, lastName, avatar, id} = useParticipantsStore(
        useShallow((state) => ({
            firstName: state.ownerInfo?.dataWithNullableEmail.firstName ?? "",
            lastName: state.ownerInfo?.dataWithNullableEmail.lastName ?? "",
            avatar: state.ownerInfo?.dataWithNullableEmail.avatar ?? "",
            id: state.ownerInfo?.id,
        }))
    );

    if (!id) {
        return null;
    }

    return (
        <div ref={ref} {...props}>
            <ParticipantDisplayDetails
                className="flex flex-align-center"
                firstName={firstName}
                lastName={lastName}
                isUser={true}
                avatar={avatar}
                showProfilePicture
                disableTooltip
                avatarSize="M"
                avatarVariant="rounded"
                participantIsAssistant={false}
                classes={{
                    avatar: classes.avatar,
                    displayName: classes.displayName,
                }}
            />
        </div>
    );
}

export default forwardRef(OwnerAvatar);
