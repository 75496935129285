import {mutate} from "@workhorse/dataApi";
import {BaseRescheduleEventInfo, RecurrentEventActions} from "./definitions";
import {createSessionOccurrence} from "./session";
import {eventHasInstance, eventIsRecurrent} from "./utils";

const reschedule = async (sessionId: string, rescheduleInfo: BaseRescheduleEventInfo) => {
    await mutate("UpdateOneSessionDocument", {
        variables: {
            where: {
                id: sessionId,
            },
            hasParticipants: false,
            data: {
                startAt: {set: rescheduleInfo.to.startDate},
                plannedEnd: {set: rescheduleInfo.to.endDate},
            },
        },
        fetchPolicy: "no-cache",
    });

    return sessionId;
};

export const executeReschedule = async (rescheduleInfo: BaseRescheduleEventInfo, recurrenceAction: RecurrentEventActions) => {
    if (eventIsRecurrent(rescheduleInfo.event)) {
        const hasInstance = eventHasInstance(rescheduleInfo.event);

        if (recurrenceAction === "this-session" && hasInstance) {
            return reschedule(rescheduleInfo.event.id, rescheduleInfo);
        } else if (rescheduleInfo.event.occurrenceId) {
            return createSessionOccurrence(rescheduleInfo.event.id, rescheduleInfo.event.occurrenceId).then((res) => {
                if (res.data?.createSessionOccurrence?.id) {
                    return reschedule(res.data.createSessionOccurrence.id as string, rescheduleInfo);
                }

                return res.data?.createSessionOccurrence?.id as string;
            });
        }
    } else {
        return reschedule(rescheduleInfo.event.id, rescheduleInfo);
    }
};
