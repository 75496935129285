import {cls} from "@ui/cdk/util";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import FilledInput from "@workhorse/ui/core/components/FilledInput";
import FormControl from "@workhorse/ui/core/components/FormControl";
import {useDebounce} from "use-debounce";
import {ReactComponent as SearchIcon} from "../../assets/media/search-icon.svg";
import SearchResultsContainer from "./SearchResultsContainer";
import classes from "./style/SearchBar.module.scss";
import browserInfo from "@workhorse/api/BrowserInfo";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {isMacOS} from "@workhorse/util";
import Typography from "@ui/cdk/Typography/Typography";

type SearchBarProps = {
    classes?: {
        toggler?: string;
        root?: string;
    };
};

const SearchBar = (props: SearchBarProps) => {
    const {} = props;

    const [searchedValue, setSearchedValue] = useState<string>("");
    const [searchedValueWithDebounce] = useDebounce(searchedValue.trim(), 300);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);

    const dialogRef = useRef<DialogImperativeRef>();

    const isMobileOrTablet = browserInfo.isMobile() || browserInfo.isTablet() || browserInfo.isIOS() || browserInfo.isIpadOS();

    const macOS = isMacOS();

    const inputRef = useRef<HTMLElement>();

    const handleFocus = () => {
        setIsActive(true);
    };

    const handleChange = (e) => {
        if (e.target.value.length < 2000) {
            setSearchedValue(e.target.value);
        }
        setAnchorEl(e.target);
        setIsActive(true);
    };

    const handleToggleDialog = () => {
        dialogRef.current?.toggle?.();
    };

    const checkForModalsOpen = () => {
        const confirmationDialogs = document.querySelectorAll(`[data-open="true"]`);
        return !!confirmationDialogs.length;
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        const areOtherModalsOpen = checkForModalsOpen();

        if (event.altKey && !areOtherModalsOpen) {
            if (event.code === "KeyJ") {
                event.preventDefault();

                handleToggleDialog();
            }
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", handleKeyDown);

        return () => {
            document.body.removeEventListener("keydown", handleKeyDown);
        };
    }, [inputRef.current]);

    const handleClose = () => {
        setSearchedValue("");
    };

    return (
        <>
            <div className={props.classes?.toggler} role="presentation" onClick={handleToggleDialog}>
                <SearchIcon />
            </div>
            <Dialog
                imperativeRef={dialogRef}
                classes={{
                    paper: classes.dialogPaper,
                }}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackdrop,
                    },
                }}
                onClose={handleClose}
            >
                <div className={cls(classes.root, props.classes?.root)}>
                    <div className={classes.search}>
                        <FormControl fullWidth variant="filled">
                            <FilledInput
                                value={searchedValue}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                inputRef={inputRef}
                                className={classes.input}
                                placeholder={"Search a session"}
                                disableUnderline
                                data-id="menu-input-search-session"
                                role="searchbox"
                                margin="none"
                                inputProps={{
                                    className: classes.inputProps,
                                    "data-private": "lipsum",
                                }}
                                startAdornment={<SearchIcon />}
                                endAdornment={
                                    <Typography color="nonary" component="div" className={classes.shortcut}>
                                        <span>{macOS ? "⌥" : "Alt"}</span>
                                        <span>J</span>
                                    </Typography>
                                }
                                autoFocus
                            />
                            {searchedValueWithDebounce && searchedValueWithDebounce.length > 2 ? (
                                <SearchResultsContainer
                                    term={searchedValueWithDebounce}
                                    anchorEl={anchorEl}
                                    isActive={isActive}
                                    setIsActive={setIsActive}
                                    isMobileOrTablet={isMobileOrTablet}
                                />
                            ) : null}
                        </FormControl>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default SearchBar;
