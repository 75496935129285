import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {TooltipButtonArrow, WithTooltipChildren} from "./onboarding-tooltips-utils";
import List from "@ui/core/components/List";
import ListItem from "@ui/core/components/ListItem";
import {cls} from "@ui/cdk/util";
import style from "./onboarding.module.scss";

export function OnboardingAgenda(props: WithTooltipChildren) {
    const {next} = useOnboardingSession();
    return (
        <OnboardingTooltip
            tooltipId="agenda"
            title={
                <>
                    <Typography className="title" fontWeight="bolder">
                        Meet the Sessions Agenda
                    </Typography>

                    <List>
                        <ListItem className={style.tooltipBullet}>
                            Agendas are almost like virtual meeting assistants – they keep you on track, and load your tools and files at
                            exactly the right moment.
                        </ListItem>
                        <ListItem className={style.tooltipBullet}>
                            There’s no need to share your screen, you can embed almost anything.
                        </ListItem>
                        <ListItem className={style.tooltipBullet}>
                            Agendas are flexible – you can skip topics or go back to previous ones.
                        </ListItem>
                        <ListItem className={style.tooltipBullet}>Add, edit, or remove an agenda at any stage.</ListItem>
                        <ListItem className={style.tooltipBullet}>Save your agenda as a template and share it with your team.</ListItem>
                    </List>

                    <Button
                        variant="primary"
                        onClick={() =>
                            next({
                                delay: 1000,
                            })
                        }
                        className={cls("ml-auto mt-8", style.button)}
                        size="small"
                        data-id="onboarding-button-strike1-step1-next"
                    >
                        Next <TooltipButtonArrow />
                    </Button>
                </>
            }
            placement="right"
            arrow={true}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
