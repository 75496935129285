import {useTranslation} from "react-i18next";
import {ReactComponent as RecordingPresentationIcon} from "../../../../../assets/media/recording-presentation.svg";
import {RecordingView, RecordingViewSelect} from "./RecordingViewSelect";

import {ReactComponent as SessionViewSpeaker} from "../../../../../assets/media/session-speaker-view.svg";
import {ReactComponent as SessionViewPresentationConferenceIcon} from "../../../../../assets/media/session-view-presentation-conference.svg";
import {ReactComponent as SessionViewAudienceConferenceIcon} from "../../../../../assets/media/session-view-audience-conference.svg";

interface Props {
    value?: string | null;
    onChange: (val: RecordingView) => void;
    className?: string;
}

export function RecordingPresentationViewSelect(props: Props) {
    const {t} = useTranslation();

    return (
        <RecordingViewSelect
            title={t("player.settings.general.layout.presentation.title")}
            description={t("player.settings.general.layout.presentation.description")}
            icon={<RecordingPresentationIcon />}
            defaultValue={RecordingView.Spotlight}
            value={props.value}
            onChange={props.onChange}
            options={[
                {
                    value: RecordingView.Spotlight,
                    text: t("header.session_view.presentation"),
                    icon: SessionViewSpeaker,
                },
                {
                    value: RecordingView.Speaker,
                    text: t("header.session_view.spotlight"),
                    icon: SessionViewPresentationConferenceIcon,
                },
                {
                    value: RecordingView.Audience,
                    text: t("header.session_view.audience"),
                    icon: SessionViewAudienceConferenceIcon,
                },
            ]}
        />
    );
}
