import {useQuery} from "@apollo/client";
import {
    BillingPeriod,
    GetSkipTrialCouponDocument,
    OrganizationPermissionType,
    OrganizationPlan,
    PaddleOfferPlansDocument,
    PaddlePlansDocument,
    PaddleSubscriptionDetailsDocument,
    PaddleSubscriptionState,
    ReportProblemCategory,
    SubmitProblemDocument,
    SubscriptionModifierType,
} from "@generated/data";
import {isLtdPlan, isReverseTrialActive, reverseTrialDaysLeft} from "@sessions/common/subscription-limitations/utils";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {useMutation} from "@workhorse/api/data";
import {useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {reportDialogData, reportDialogDefaultValue, reportSubmitted} from "@workhorse/components/reportProblem/reportDialogApi";
import {UpgradePlanDialogPayment} from "@workhorse/components/subscriptions/UpgradePlanDialog/components/UpgradePlanDialogPayment";
import {useUserInfo} from "@workhorse/providers/User";
import {format} from "date-fns";
import classes from "../style/SubscriptionInfo.module.scss";
import {getCancelMessage, handleCancelSubscription} from "../utils";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import {isAppSumoPlan, isLtdTierPlan} from "@sessions/common/subscription-limitations/utils";

export const getPlanTitle = (plan?: OrganizationPlan) => {
    switch (plan) {
        case OrganizationPlan.Starter:
            return "Starter";
        case OrganizationPlan.ProLtd:
            return "Pro LTD ambassador";
        case OrganizationPlan.Business:
            return "Business";
        case OrganizationPlan.Pro:
            return "Pro";
        case OrganizationPlan.AppsumoTier_1:
            return "Appsumo Tier 1";
        case OrganizationPlan.AppsumoTier_2:
            return "Appsumo Tier 2";
        case OrganizationPlan.AppsumoTier_3:
            return "Appsumo Tier 3";
        case OrganizationPlan.AppsumoTier_4:
            return "Appsumo Tier 4";
        case OrganizationPlan.AppsumoTier_5:
            return "Appsumo Tier 5";
        case OrganizationPlan.ProLtdSaaszilla:
            return "Pro LTD";
        case OrganizationPlan.Solo:
            return "Solo";
        case OrganizationPlan.LtdTier_1:
            return "Lifeline Tier 1";
        case OrganizationPlan.LtdTier_2:
            return "Lifeline Tier 2";
        case OrganizationPlan.LtdTier_3:
            return "Lifeline Tier 3";
        case OrganizationPlan.LtdTier_4:
            return "Lifeline Tier 4";
        case OrganizationPlan.LtdTier_5:
            return "Lifeline Tier 5";
        case OrganizationPlan.LtdTier_6:
            return "Lifeline Tier 6";
        case OrganizationPlan.SessionsSupporter:
            return "Lifeline Patreon";
        default:
            return "Free";
    }
};

type SubscriptionInfoProps = {
    toggleState: boolean;
    has40Discount?: boolean;
};

const SubscriptionInfo = ({toggleState, has40Discount}: SubscriptionInfoProps) => {
    const user = useUserInfo();

    const {data} = useQuery(PaddlePlansDocument);
    const [submitProblem] = useMutation(SubmitProblemDocument);
    const {mixpanelTrack} = useMixpanelTracker();

    const reverseTrialCancellationDate = user.activeOrganizationPermission.organization.reverseTrialCancellationDate;

    const isReverseTrial = isReverseTrialActive(reverseTrialCancellationDate);
    const revTrialDaysLeft = reverseTrialDaysLeft(reverseTrialCancellationDate);

    const skipPaddleSubscriptionId =
        user.activeOrganizationPermission.permissionType !== OrganizationPermissionType.Owner ||
        !user.activeOrganizationPermission.organization.subscriptionId ||
        isReverseTrial;

    const processing = user.activeOrganizationPermission.organization?.processingSubscription;

    !user.activeOrganizationPermission.organization?.lastOrderId && !processing;

    const {data: subDetailsData} = useQuery(PaddleSubscriptionDetailsDocument, {
        skip: skipPaddleSubscriptionId,
    });

    const hasReferralDiscount = user.activeOrganizationPermission.organization.referralDiscountCode;

    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    const plan = user.activeOrganizationPermission.organization?.plan;
    const organization = user.activeOrganizationPermission.organization;
    const isYear = user.activeOrganizationPermission.organization?.billingPeriod === BillingPeriod.Yearly;

    const billingPeriod = user.activeOrganizationPermission.organization?.billingPeriod;
    const modifiers = organization.subscriptionModifiers;

    const isTrial = organization.paddleSubscriptionState === PaddleSubscriptionState.Trialing;

    const hideCost = isAppSumoPlan(plan) || isLtdTierPlan(plan);

    const nextPaymentDate = hideCost ? null : organization.planNextPaymentDate;
    const title = getPlanTitle(plan);

    const planInfo = data?.paddlePlans.find((p) => p.plan === plan && p.billing_type === billingPeriod);

    const membersModifierPrice =
        planInfo?.modifiers?.find((m) => m.type === SubscriptionModifierType.MoreUsers)?.pricePerUnitInDollars ?? 0;

    const recordingModifierPrice =
        planInfo?.modifiers?.find((m) => m.type === SubscriptionModifierType.MoreRecordingHours)?.pricePerUnitInDollars ?? 0;

    const extraMembersPrice = modifiers?.reduce((acc, m) => {
        if (m.type === SubscriptionModifierType.MoreUsers) {
            acc = acc + m.quantity * membersModifierPrice;
        }
        return acc;
    }, 0);

    const isCancelled =
        !!(organization.planEffectiveCancellationDate && !organization.planNextPaymentDate) &&
        ![OrganizationPlan.Free, OrganizationPlan.ProLtd, OrganizationPlan.ProLtdSaaszilla].includes(organization.plan);

    const extraRecordingHoursPrice = modifiers?.reduce((acc, m) => {
        if (m.type === SubscriptionModifierType.MoreRecordingHours) {
            acc = acc + m.quantity * recordingModifierPrice;
        }
        return acc;
    }, 0);

    let price = 0;

    if (subDetailsData?.paddleSubscriptionDetails?.next_payment?.amount) {
        price = subDetailsData?.paddleSubscriptionDetails?.next_payment?.amount
            ? Number(subDetailsData?.paddleSubscriptionDetails?.next_payment?.amount)
            : planInfo?.recurring_price?.[0]?.amount ?? 0;
    }
    price += extraMembersPrice + extraRecordingHoursPrice;
    price = Math.ceil(price);

    const canCancelPlan =
        !isCancelled &&
        [OrganizationPlan.Starter, OrganizationPlan.Pro, OrganizationPlan.Business, OrganizationPlan.Solo].includes(plan) &&
        !isReverseTrial;

    const permanentPlan = user.activeOrganizationPermission.organization?.permanentPlan;

    const [planOffer, setPlanOffer] = useState<OrganizationPlan | undefined>();

    const [skipTrialCoupon, setSkipTrialCoupon] = useState<string | undefined>();

    const [showPaddleDialog, setShowPaddleDialog] = useState(false);

    const onClosePaddleDialog = () => {
        setShowPaddleDialog(false);
    };

    const skipTrialGetPlan = async () => {
        const getPro = user.activeOrganizationPermission.organization.onboarding?.onboarding_planning?.value?.toLowerCase() === "team";

        const planToFind = getPro ? OrganizationPlan.Pro : OrganizationPlan.Solo;

        const GetSkipTrialCoupon = await apollo.client.query({
            query: GetSkipTrialCouponDocument,
        });

        const coupon = GetSkipTrialCoupon.data.getSkipTrialCoupon;

        if (!coupon) {
            toast("Something went wrong, please try again later.", {
                type: "error",
            });
            return;
        }

        setPlanOffer(planToFind);
        setSkipTrialCoupon(coupon);

        setShowPaddleDialog(true);
    };

    const handleCloseAll = () => {
        onClosePaddleDialog();
        setSkipTrialCoupon(undefined);
        setPlanOffer(undefined);
    };

    function handleOpenCancelDialog() {
        setIsCancelDialogOpen(true);
    }

    function handleCloseCancelDialog() {
        setIsCancelDialogOpen(false);
        reportDialogData(reportDialogDefaultValue);
    }

    function handleSubmitCancelSubscription() {
        const reportData = reportDialogData();
        const cancelMessage = getCancelMessage(reportData.cancelReason, reportData.customReason);
        submitProblem({
            variables: {
                screenshotUuid: undefined,
                description: cancelMessage,
                deviceInfo: reportData.device,
                category: ReportProblemCategory.Incident,
            },
        })
            .then((res) => {
                if (!res.data?.submitProblem) {
                    toast("Something went wrong submitting your cancel reason...", {type: "error"});
                    return;
                }

                reportDialogData(reportDialogDefaultValue);
                reportSubmitted(true);
            })
            .catch((err) => {
                toast("Something went wrong submitting your cancel reason...", {type: "error"});
                console.log("Error submitting cancel reason: ", err);
            });
        handleCancelSubscription();
        handleCloseCancelDialog();
        mixpanelTrack("frontend-cancel-plan", "organization");
    }

    const isLtdPlanActive = isLtdPlan(user.activeOrganizationPermission.organization.plan);

    return (
        <>
            <div className={cls(classes.root, "gap-12")}>
                <div className={classes.gradientTitle}>{title}</div>

                {isLtdPlanActive ? null : (
                    <div className={classes.body}>
                        <Typography variant="xl" className="mb-5">
                            {(isTrial && !isCancelled) || isReverseTrial ? (
                                <> You're on a free trial for this plan.</>
                            ) : isCancelled ? (
                                <>Your {title} plan subscription has been canceled.</>
                            ) : hideCost ? null : (
                                <>
                                    You are currently on the {title} plan. Your {isYear ? "yearly" : "monthly"} cost is{" "}
                                    <Typography variant="xl" component="span" fontWeight="bolder">
                                        ${price}
                                    </Typography>
                                    .{" "}
                                </>
                            )}
                        </Typography>
                        <div>
                            {isTrial && isCancelled ? (
                                <Typography color="secondary">
                                    You still have access to the free trial until{" "}
                                    <b>{format(new Date(organization.planEffectiveCancellationDate ?? new Date()), "MMM dd, yyyy")}</b>.
                                </Typography>
                            ) : isTrial ? (
                                <Typography color="secondary">
                                    Your free trial will be active until{" "}
                                    <b>{format(new Date(nextPaymentDate ?? new Date()), "MMM dd, yyyy")}</b>. After that, you&rsquo;ll be
                                    charged <b>${price}</b> {isYear ? " per year" : " per month"}. Cancel your subscription to avoid being
                                    charged.
                                </Typography>
                            ) : isCancelled ? (
                                <Typography color="secondary">
                                    {permanentPlan ? (
                                        <>
                                            <br />
                                            Your plan will be reverted to <b>{getPlanTitle(permanentPlan)}</b> upon expiration of your
                                            current benefits.
                                        </>
                                    ) : (
                                        <>
                                            You’ll still have access to its features until{" "}
                                            {organization.planEffectiveCancellationDate ? (
                                                <>
                                                    <b>{format(new Date(organization.planEffectiveCancellationDate), "MMM dd, yyyy")}</b>.
                                                </>
                                            ) : (
                                                <>the end of the current billing period.</>
                                            )}
                                        </>
                                    )}
                                </Typography>
                            ) : isReverseTrial ? (
                                <Typography color="secondary">
                                    Trial expires in <b>{revTrialDaysLeft} days</b>.
                                </Typography>
                            ) : (
                                <Typography color="secondary">
                                    {nextPaymentDate ? "Next payment date is " : ""}
                                    {nextPaymentDate ? <b>{format(new Date(nextPaymentDate ?? new Date()), "MMM dd, yyyy")}</b> : null}
                                </Typography>
                            )}
                        </div>
                    </div>
                )}

                <div className="flex flex-align-center ml-auto my-auto gap-6">
                    {canCancelPlan ? (
                        <Button onClick={handleOpenCancelDialog} size="small" variant="tertiary" className={classes.button}>
                            Cancel subscription
                        </Button>
                    ) : null}

                    {(isTrial || isReverseTrial) && !hasReferralDiscount && !isLtdPlanActive && (
                        <>
                            <Typography fontWeight="bold" className="mr-12" color="secondary">
                                Skip the trial and get {toggleState && has40Discount ? 40 : 20}% OFF
                            </Typography>
                            <Button variant="primary" className={cls(classes.button, "ml-auto my-auto")} onClick={skipTrialGetPlan}>
                                Get plan
                            </Button>
                        </>
                    )}
                </div>
            </div>

            {canCancelPlan && isCancelDialogOpen && (
                <CancelSubscriptionDialog onConfirm={handleSubmitCancelSubscription} onClose={handleCloseCancelDialog} />
            )}

            {showPaddleDialog && planOffer ? (
                <UpgradePlanDialogPayment
                    billingPeriod={toggleState ? BillingPeriod.Yearly : BillingPeriod.Monthly}
                    onClose={handleCloseAll}
                    plan={planOffer}
                    skipTrialCoupon={skipTrialCoupon}
                ></UpgradePlanDialogPayment>
            ) : null}
        </>
    );
};

export default SubscriptionInfo;
