import {usePaletteResults} from "./CommandPaletteResultsProvider";
import classes from "./style/CommandPaletteContent.module.scss";
import {cls} from "@ui/cdk/util";
import PaletteRenderOneResult, {ResultListProps} from "./PaletteRenderOneResult";
import Typography from "@ui/cdk/Typography";
import {isPaletteItemAgenda, isPaletteItemFile, isPaletteItemTool} from "@workhorse/api/palette";
import {categMap} from "./actionCategTree";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";

export default function RecentUsedResults(props: ResultListProps) {
    const {t} = useTranslation();
    const {hasSearchStr, includePath, highlightId} = props;
    const {paletteResults} = usePaletteResults();
    const items = paletteResults.recent;

    const {onboardingType} = useOnboardingSession();

    return items.length && onboardingType !== SessionOnboardingType.NewUser ? (
        <>
            <Typography component="span" variant="sm" className={cls(classes.areaTitle, classes.withSeparator)}>
                {t("palette.recent_shares") ?? "Recent shares"}
            </Typography>
            {items.map((item) => {
                const x = (() => {
                    if (isPaletteItemFile(item)) {
                        return {
                            crumbs: `${t("palette.files.title") ?? "Files"} / `,
                            txt: t("palette.files.share_this_file") ?? "Share this file",
                        };
                    } else if (isPaletteItemTool(item)) {
                        const tool = categMap.find((obj) => obj.toolType && obj.toolType === item.tool_type);
                        return {
                            crumbs: `${t("palette.tools.title") ?? "Tools"} / ${tool?.title} / `,
                            txt: t("palette.tools.embed_this_tool") ?? "Embed this tool",
                        };
                    } else if (isPaletteItemAgenda(item)) {
                        return {
                            crumbs: `${t("palette.agendas.title") ?? "Agendas"} / `,
                            txt: t("palette.agendas.share_this_agenda") ?? "Share this agenda",
                        };
                    }
                })();
                return (
                    <PaletteRenderOneResult
                        item={item}
                        key={item.id}
                        showTypeTitle={hasSearchStr}
                        includePath={includePath}
                        highlighted={highlightId === item.id}
                        setActionArg={props.setActionArg}
                        setPaletteActiveCateg={props.setPaletteActiveCateg}
                        rootRef={props.rootRef}
                        scrollIntoView={props.scrollIntoView}
                        crumbs={x?.crumbs}
                        onHighlightText={x?.txt}
                        isRecent={true}
                    />
                );
            })}
        </>
    ) : null;
}
