import {mapToEditorParagraph} from "@api/editor";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import AgendaGeneratorMessenger, {AIGeneratedAgendaItem} from "@workhorse/api/aiGenerator/AgendaGeneratorMessenger";
import AgendaGeneratorMenu from "@workhorse/api/aiGenerator/components/AgendaGeneratorMenu";
import {generateAgendaPrompt, handleCreateOrInsertAiAgenda} from "@workhorse/api/aiGenerator/utils";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useRef, useState} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import EventsLandingPageColorPickers from "@workhorse/pages/event/event/event-landing/EventsLandingPageColorPickers";
import EventsLandingPageImagesUpload from "@workhorse/pages/event/event/event-landing/EventsLandingPageImagesUpload";
import {useAiGeneratedAgenda} from "@workhorse/providers/AiGeneratedAgendaProvider";
import {useAgendaItems, useSession} from "@workhorse/providers/SessionDataProviders";
import {format} from "date-fns";
import classes from "./styles/OnboardingEventCreateDialog.module.scss";
import genericClasses from "./styles/OnboardingSessionDialog.module.scss";

import {ReactComponent as NoPollsIcon} from "../../../../../../artifacts/polls/assets/no-polls-icon.svg";
import {descriptionKeyVar} from "@workhorse/pages/event/event/event";
import {useHistory} from "react-router-dom";

import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import {composeInitialSlug, removeSpecialChars} from "@workhorse/util/url";
import {useUserInfo} from "@workhorse/providers/User";
import {Exact, GetSessionQuery} from "@generated/data";
import {ApolloQueryResult} from "@workhorse/api/data";

type OnboardingEventCreateDialogProps = {
    refetch:
        | ((
              variables?:
                  | Partial<
                        Exact<{
                            id: string;
                        }>
                    >
                  | undefined
          ) => Promise<ApolloQueryResult<GetSessionQuery>>)
        | undefined;
};

function OnboardingEventCreateDialog(props: OnboardingEventCreateDialogProps) {
    const {mixpanelTrack} = useMixpanelTracker();

    const dialogRef = useRef<DialogImperativeRef>();

    const [aiInput, setAiInput] = useState<string>("");

    const history = useHistory();
    const userInfo = useUserInfo();

    const session = useSession();

    const useAgendaImperativeRef = useRef<(() => void) | null>(null);

    const [generateClicked, setGenerateClicked] = useState<boolean>(false);

    const [startGenerating, setStartGenerating] = useState<boolean>(false);
    const [visibleInputValue, setVisibleInputValue] = useState<string>("");

    const agendaItems = useAgendaItems();

    const {handleOnError, saveGeneratedAgenda, generatedAgenda, selectedAgendaItems} = useAiGeneratedAgenda();

    const [generatedTitle, setGeneratedTitle] = useState<string>("");
    const [generatedDescription, setGeneratedDescription] = useState<string>("");

    const onAiInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVisibleInputValue(e.target.value);
        const promptForChatGPT = generateAgendaPrompt(e.target.value, 3600, 5, "webinar");
        setAiInput(promptForChatGPT);
        handleOnError("");
    };

    const onSubmitAiInput = () => {
        if (aiInput) {
            saveGeneratedAgenda(null);
            setStartGenerating(true);
            setGenerateClicked(true);
            handleOnError("");
        }
    };

    const onAgendaGenerationFinished = () => {
        setStartGenerating(false);
    };

    const handleMarkAsSeen = async () => {
        await markAsSeen({
            ONBOARDING_FEATURES: {
                eventsCreate: true,
            },
        });
    };

    const handleCloseDialog = () => {
        mixpanelTrack("frontend-onboarding-event-ai-skip", "events");
        handleMarkAsSeen();
    };

    const handleCreateMyFirstEvent = async () => {
        const agendaId = designer.api.artifact.addMacroArtifact({
            artifactName: "flowos/agenda",
            artifactTag: "flowos/agenda",
        });

        await designer.commit();

        handleCreateOrInsertAiAgenda(agendaItems, generatedAgenda, selectedAgendaItems, true);

        const organizationName = removeSpecialChars(userInfo.activeOrganizationPermission.organization.name ?? "");
        const organizationId = userInfo.activeOrganizationPermission.organization.id;

        const workspaceSlug = userInfo.activeWorkspace.slug;

        const fullUserName = removeSpecialChars(userInfo.firstName ?? "") + "-" + removeSpecialChars(userInfo.lastName ?? "");
        const eventNameForSlug = removeSpecialChars(generatedTitle.trim());
        const slug = composeInitialSlug(fullUserName, organizationId, organizationName, eventNameForSlug, workspaceSlug);

        await designer.commit({
            persistEvent: true,
            source: "event-save",
        });

        designer.api.session.update({
            // biome-ignore lint/complexity/noExtraBooleanCast: !!""
            name: !!generatedTitle ? generatedTitle : session.name,
            // biome-ignore lint/complexity/noExtraBooleanCast: !!""
            description: !!generatedDescription ? JSON.stringify(mapToEditorParagraph(generatedDescription ?? "")) : session.description,
        });

        designer.api.event.update({
            slug: slug || session.event?.slug,
        });

        await designer.commit({
            bypassEventCheck: true,
            persistEvent: true,
            source: "event-save",
        });

        mixpanelTrack("frontend-onboarding-event-ai-create", "events");
        handleMarkAsSeen();
        descriptionKeyVar(2);
        props.refetch?.();
        // window.location.reload();
    };

    const onAgendaGenerationError = (err: string) => {
        setStartGenerating(false);
        console.error("Error generating event with AI", err);
    };

    const onNewAgendaItemGenerated = async (agendaItem: AIGeneratedAgendaItem) => {
        const agendaWithId = {...agendaItem, id: makeId()};
        saveGeneratedAgenda(agendaWithId);

        // onChange?.();
    };

    return (
        <Dialog
            imperativeRef={dialogRef}
            open
            classes={{paper: cls(genericClasses.root, genericClasses.rootBase, classes.root)}}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={cls("flex flex-col", genericClasses.content)}>
                <div className={cls(genericClasses.info, classes.rootContent)}>
                    <div className={classes.columnsContainer}>
                        <div className={classes.left}>
                            <div className={classes.content}>
                                <Typography className="mb-24" fontWeight="bold" variant="xl">
                                    AI Copilot configuration
                                </Typography>
                                <div id="aiInputPortal"></div>

                                <EventsLandingPageColorPickers
                                    readOnly={false}
                                    classes={{
                                        fakeSelect: classes.colorSelect,
                                    }}
                                    preventCommit
                                />
                                <div className="my-20" />
                                <EventsLandingPageImagesUpload
                                    readOnly={false}
                                    classes={{
                                        uploaderRoot: classes.colorSelect,
                                    }}
                                    preventCommit
                                />
                            </div>
                        </div>
                        <div className={classes.right}>
                            <div className={classes.content}>
                                {generatedAgenda.length ? (
                                    <div className={cls(classes.eventDetailsContainer, "flex flex-col mb-16 gap-6")}>
                                        <Typography className="mb-14" fontWeight="bold" variant="xl">
                                            Event details
                                        </Typography>
                                        {generatedTitle ? (
                                            <Typography color="secondary">
                                                <b>Title:</b> {generatedTitle}
                                            </Typography>
                                        ) : (
                                            <Typography color="secondary">
                                                <b>Title: </b> <div className={cls(classes.textSkeleton, classes.short)}></div>
                                            </Typography>
                                        )}
                                        {generatedDescription ? (
                                            <Typography color="secondary" className="mt-8">
                                                <b>Description:</b> {generatedDescription}
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography color="secondary" className="mt-8">
                                                    <b>Description:</b> <div className={cls(classes.textSkeleton, classes.long)}></div>
                                                </Typography>
                                            </>
                                        )}
                                        <div className="flex flex-align-center gap-16 mt-8">
                                            <Typography color="secondary" className=" flex flex-align-center">
                                                {/* <b>Duration:</b> */}
                                                <AccessTimeRoundedIcon className="mr-4" />
                                                60 minutes
                                            </Typography>
                                            <Typography color="secondary" className=" flex flex-align-center">
                                                {/* <b>Starting at:</b>  */}
                                                <EventRoundedIcon className="mr-4" />
                                                {format(new Date(session.startAt!), "dd/MM/yyyy, HH:mm")}
                                            </Typography>
                                        </div>
                                    </div>
                                ) : null}

                                <div className={classes.agendaContainer}>
                                    {generatedAgenda.length ? (
                                        <Typography color="secondary" fontWeight="bold" variant="xl" className="mb-6">
                                            Event agenda
                                        </Typography>
                                    ) : null}
                                    <div className={classes.agendaItemsContainer}>
                                        <AgendaGeneratorMenu
                                            agendaDuration={60}
                                            decreaseAgendaItems={() => {}}
                                            increaseAgendaItems={() => {}}
                                            decreaseDuration={() => {}}
                                            increaseDuration={() => {}}
                                            generateClicked={generateClicked}
                                            isSingleAgendaItem={false}
                                            menuAnchorEl={null}
                                            mode={"inline"}
                                            numberOfAgendaItems={0}
                                            onAgendaDurationChange={() => {}}
                                            onAiInputChange={onAiInputChange}
                                            onAiPreviewToggle={() => {}}
                                            onNumberOfAgendaItemsChange={() => {}}
                                            onSubmitAiInput={onSubmitAiInput}
                                            setGenerateClicked={setGenerateClicked}
                                            setStartGenerating={setStartGenerating}
                                            startGenerating={startGenerating}
                                            visibleInputValue={visibleInputValue}
                                            handleUseAgendaItem={() => console.log("handle use")}
                                            inputPortalId={"aiInputPortal"}
                                            ref={useAgendaImperativeRef}
                                            classes={{
                                                inputFormControl: classes.aiInputFormControl,
                                                input: classes.aiInput,
                                                portalContainer: "mb-16",
                                                agendaFooter: classes.agendaFooter,
                                                agendaHeader: classes.agendaHeader,
                                            }}
                                            agendaPlaceholder={
                                                <div className="flex flex-col p-30">
                                                    <Typography variant="xl3" fontWeight="boldest" color="secondary" className="mb-24">
                                                        👋 Hi there!
                                                    </Typography>

                                                    <Typography color="secondary">
                                                        Your AI copilot is here to help you create your first event. Start by entering a
                                                        topic for your event.
                                                    </Typography>

                                                    <div className="mt-48 fullw flex flex-col flex-align-center">
                                                        <div className=" mb-10 mx-auto">
                                                            <NoPollsIcon />
                                                        </div>

                                                        <Typography color="tertiary">
                                                            The generated agenda items will be displayed here.
                                                        </Typography>
                                                    </div>
                                                </div>
                                            }
                                            hideAgendaItemCheckbox={true}
                                        />
                                    </div>
                                </div>

                                <AgendaGeneratorMessenger
                                    sessionId={session.id}
                                    prompt={aiInput}
                                    startGenerating={startGenerating}
                                    onAgendaGenerationFinished={onAgendaGenerationFinished}
                                    onAgendaItemGenerated={onNewAgendaItemGenerated}
                                    onAgendaGenerationError={onAgendaGenerationError}
                                    includeTitleAndDescription
                                    onTitleAndDescriptionGenerated={(title, description) => {
                                        console.log({title, description});
                                        setGeneratedTitle(title);
                                        setGeneratedDescription(description);
                                    }} // TODO: macaneata AI
                                />
                            </div>
                        </div>
                    </div>

                    <div className={cls(genericClasses.footer, "py-10")}>
                        <div className="flex flex-align-center gap-10 ml-auto">
                            <Button noFocusBorder variant="tertiary" onClick={handleCloseDialog} className="px-40">
                                Skip
                            </Button>
                            <Button
                                noFocusBorder
                                disabled={startGenerating || !generatedAgenda.length}
                                loading={startGenerating}
                                variant="primary"
                                onClick={handleCreateMyFirstEvent}
                                className="px-40"
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default OnboardingEventCreateDialog;
