import {useQuery} from "@apollo/client";
import {PaddlePlansDocument, OrganizationPlan, BillingPeriod} from "@generated/data";
import {FormControlLabel} from "@material-ui/core";
import {maximumParticipantsInSessionForPlan, sessionLengthLimitationByPlanInSeconds} from "@sessions/common/subscription-limitations";
import Button from "@ui/cdk/Button";
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {getPlanTitle} from "@workhorse/pages/user/profile/UsageEvaluation/components/SubscriptionInfo";
import {useUserInfo} from "@workhorse/providers/User";
import {ChangeEvent, Dispatch, SetStateAction, useState} from "react";
import classes from "./UpgradePlanDialogSelector.module.scss";

type UpgradePlanDialogSelectorProps = {
    selectedPlan: OrganizationPlan;
    onSelectPlan: Dispatch<SetStateAction<OrganizationPlan>>;
    billingPeriod: BillingPeriod;
    toggleBillingPeriod: () => void;
};

const planWeight = {
    [OrganizationPlan.Solo]: 0,
    [OrganizationPlan.Starter]: 1,
    [OrganizationPlan.Pro]: 2,
    [OrganizationPlan.Business]: 3,
};

const UpgradePlanDialogSelector = (props: UpgradePlanDialogSelectorProps) => {
    const {selectedPlan, onSelectPlan} = props;

    const {data} = useQuery(PaddlePlansDocument);

    const user = useUserInfo();

    const setPlan = (event: ChangeEvent<HTMLInputElement>) => {
        onSelectPlan(event.target.value as OrganizationPlan);
    };

    const sortedPlans = data?.paddlePlans
        .filter((p) => p.billing_type === props.billingPeriod)
        // .filter((p) => p.billing_type === props.billingType)
        .sort((a, b) => planWeight[a.plan] - planWeight[b.plan]);

    const toggleState = props.billingPeriod === BillingPeriod.Yearly;

    const trialAvailable = !user.activeOrganizationPermission.organization.subscriptionTrialUsed;

    return (
        <div>
            <div className="flex mb-8 mt-8">
                <div className={classes.buttonsSwitch}>
                    <Button
                        className={cls(toggleState && classes.switchButtonOn, classes.switchButton)}
                        size="small"
                        variant={toggleState ? "tertiary" : "quaternary"}
                        onClick={props.toggleBillingPeriod}
                    >
                        Annually <span className={classes.offer}>-20%</span>{" "}
                    </Button>
                    <Button
                        className={cls(!toggleState && classes.switchButtonOn, classes.switchButton)}
                        size="small"
                        variant={!toggleState ? "tertiary" : "quaternary"}
                        onClick={props.toggleBillingPeriod}
                    >
                        Monthly
                    </Button>
                </div>
            </div>

            {trialAvailable ? (
                <>
                    <Typography fontWeight="bold" color="primary" className="mb-8 mt-8 ml-5">
                        FREE for the first 14 days. You can cancel anytime.
                    </Typography>

                    <Typography color="primary" className="mb-8 ml-5">
                        After the trial, you will be charged the following amount:
                    </Typography>
                </>
            ) : null}

            <RadioGroup value={selectedPlan} onChange={setPlan}>
                {sortedPlans
                    ?.filter((plan) => {
                        const currentPlan = user.activeOrganizationPermission.organization.plan;
                        if (plan.plan === currentPlan) {
                            return false;
                        }
                        if (currentPlan === OrganizationPlan.Pro) {
                            return [OrganizationPlan.Business].includes(plan.plan);
                        }
                        if (currentPlan === OrganizationPlan.Starter) {
                            return [OrganizationPlan.Pro, OrganizationPlan.Business].includes(plan.plan);
                        }

                        if (currentPlan === OrganizationPlan.Business) {
                            return false;
                        }

                        return true;
                    })
                    .map((plan) => {
                        const price =
                            props.billingPeriod === BillingPeriod.Monthly
                                ? plan.recurring_price?.[0]?.amount ?? 0
                                : plan?.recurring_price?.[0].amount !== undefined
                                ? Number(Number((plan?.recurring_price?.[0]?.amount ?? 0) / 12).toFixed(2))
                                : 0;

                        return (
                            <FormControlLabel
                                className={cls(classes.label, selectedPlan === plan.plan && classes.selected)}
                                key={plan.id}
                                value={plan.plan}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography>
                                            <b>{getPlanTitle(plan.plan)} plan</b> {plan.initial_price?.[0].currency} {Math.ceil(price)}
                                            /month
                                        </Typography>
                                        <Typography color="tertiary">
                                            Includes up to {maximumParticipantsInSessionForPlan[plan.plan]} participants,
                                            {sessionLengthLimitationByPlanInSeconds[plan.plan] / 3600}h sessions and access to all features.
                                        </Typography>
                                    </div>
                                }
                            />
                        );
                    })}
            </RadioGroup>
        </div>
    );
};

export default UpgradePlanDialogSelector;
