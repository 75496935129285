import {ReactComponent as SpotlightIcon} from "../../components/conferenceControls/spotlight.svg";
import {MicroNotificationProps} from "../api";

export type SpotlightNotificationType = {
    user: string;
    action: "decline" | "remove" | "missed" | "revoke-spotlight-invitation" | "too-many-speakers-owner" | "too-many-speakers";
};

export const SpotlightNotification = (props: MicroNotificationProps<SpotlightNotificationType>) => {
    const {user: displayName, action} = props.messages[0].message;

    return (
        <div className="flex flex-items-center">
            <SpotlightIcon />
            {action === "decline"
                ? `${displayName} declined your spotlight request.`
                : action === "remove"
                ? `${displayName} removed you from spotlight.`
                : action === "revoke-spotlight-invitation"
                ? `You revoked the Spotlight invitation for ${name}.`
                : action === "too-many-speakers-owner"
                ? `${displayName} was unable to become speaker. Maximum number of speakers reached.`
                : action === "too-many-speakers"
                ? "Maximum number of speakers reached."
                : `You missed an invitation to Spotlight, from ${displayName}.`}
        </div>
    );
};
