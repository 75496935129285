import VideoOnIcon from "@material-ui/icons/Videocam";
import VideoOffIcon from "@material-ui/icons/VideocamOff";
import MenuItem from "@ui/cdk/MenuItem";
import {cls} from "@ui/cdk/util/util";
import {useDevices, useDeviceManagerEvents, isNotAllowedError} from "@workhorse/api/conference2";
import React, {forwardRef, MouseEventHandler, useRef} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useCameraHiddenStorage} from "@workhorse/providers/UserDeviceSettingsStorageProvider";
import LocalVideoMenu from "./components/LocalVideoMenu";
import {useForceDisableVideo} from "@workhorse/api/conference2/providers/VideoDisableProvider";
import {getGlobalKeybindString} from "@workhorse/util/keybinds";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useTranslation} from "react-i18next";

type LocalVideoTogglerProps = {
    disabled?: boolean;
    className?: string;
    shouldSaveDataToStorage?: boolean;
    permissionDeniedText?: string;
    sessionId: string;
    initialState?: boolean;
    isPreJoin?: boolean;
    isLobby?: boolean;
    onToggle?: () => void;
    showTooltip?: (e?: unknown, message?: string) => void;
    onPermissionBlocked?: (element?: HTMLElement | null) => void;
    onDeviceUnreadable?: (element?: HTMLElement | null) => void;
};

function NoCamera({className}: {className?: string}) {
    return <VideoOffIcon fontSize="small" className={className} />;
}

function CameraEnabled({className}: {className?: string}) {
    return <VideoOnIcon fontSize="small" className={className} />;
}

function CameraDisabled({className}: {className?: string}) {
    return <VideoOffIcon fontSize="small" className={className} />;
}

interface SelectorProps {
    active: boolean;
    hasVideoInput: boolean;
    cameraHidden: boolean;
    activeClass?: string;
    inactiveClass?: string;
    disabledClass?: string;
    cameraHiddenClass?: string;
}

function IconSelector(props: SelectorProps) {
    if (!props.hasVideoInput) {
        return <NoCamera className={props.disabledClass} />;
    }

    if (!props.active) {
        return <CameraDisabled className={props.inactiveClass} />;
    }

    return <CameraEnabled className={cls(props.activeClass, props.cameraHidden && props.cameraHiddenClass)} />;
}

function classSelector(props: SelectorProps) {
    if (!props.hasVideoInput) {
        return props.disabledClass;
    }

    if (!props.active) {
        return props.activeClass;
    }

    return props.inactiveClass;
}

const LocalVideoToggler = forwardRef((props: LocalVideoTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {showTooltip, isPreJoin} = props;

    const toggleClicked = useRef(false);

    const {isMobileOrTablet} = useMobile();
    const {cameraHidden} = useCameraHiddenStorage();

    const {videoEnabled, videoError, hasVideoInput, toggleVideo} = useDevices();
    const {isHardVideoDisable} = useForceDisableVideo();
    const forceCameraDisabled = isHardVideoDisable && !isPreJoin;

    const active = videoEnabled && !isNotAllowedError(videoError);

    const popoverRef = useRef<HTMLLIElement | null>(null);

    const keybind = getGlobalKeybindString("toggleVideo");

    const onMouseEnter: MouseEventHandler<HTMLLIElement> = (e) => {
        let message = isPreJoin
            ? t(active ? "prejoin.will_join_with_camera_on" : "prejoin.will_join_with_camera_off") ?? ""
            : forceCameraDisabled
            ? t("prejoin.camera_is_disabled") ?? ""
            : "";

        if (!hasVideoInput) {
            message = t("prejoin.no_camera") ?? "";
        }

        if (!message) {
            return;
        }

        showTooltip?.(e, message);
    };

    const onMouseLeave = () => {
        showTooltip?.();
    };

    const handleVideoToggle = async () => {
        if (!hasVideoInput || forceCameraDisabled) {
            return;
        }

        ariaAnnouncerMessageVar(t(active ? "aria_announcer.camera_off" : "aria_announcer.camera_on") ?? "");

        toggleClicked.current = true;
        toggleVideo();
    };

    const rootCls = cls(
        props.className,
        cameraHidden && "camera-hidden",
        classSelector({
            active,
            hasVideoInput,
            activeClass: "active-red",
            cameraHidden,
            cameraHiddenClass: "camera-hidden",
        })
    );

    useDeviceManagerEvents({
        onVideoSuccess() {
            props.onPermissionBlocked?.(null);
            toggleClicked.current = false;
        },
        onVideoNotAllowedError() {
            if (toggleClicked.current) {
                props.onPermissionBlocked?.(popoverRef.current);
                showTooltip?.();
            }
            toggleClicked.current = false;
        },
        onVideoUnreadableError() {
            if (toggleClicked.current) {
                props.onDeviceUnreadable?.(popoverRef.current);
                showTooltip?.();
            }
            toggleClicked.current = false;
        },
    });

    return (
        <LocalVideoMenu disabled={isPreJoin || isMobileOrTablet || forceCameraDisabled} onVideoToggle={handleVideoToggle}>
            <MenuItem
                ref={popoverRef}
                classes={{
                    root: "local-video-toggler",
                }}
                data-id="camera-on-off"
                className={rootCls}
                onClick={handleVideoToggle}
                disabled={!hasVideoInput || forceCameraDisabled}
                button
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                role="button"
                tabIndex={0}
                aria-label={`${t(active ? "participant.camera.turn_camera_off" : "participant.camera.turn_camera_on") ?? ""} ${
                    isMobileOrTablet ? "" : `, (${keybind})`
                }`}
            >
                <IconSelector
                    active={videoEnabled}
                    hasVideoInput={hasVideoInput}
                    activeClass="active-red"
                    cameraHidden={cameraHidden}
                    cameraHiddenClass="camera-hidden"
                />
            </MenuItem>
        </LocalVideoMenu>
    );
});

export default LocalVideoToggler;
