import {ConfigurationConfirmType, DrawerState} from "@generated/data";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import designer from "@workhorse/api/designer";
import ToolConfirmationNotification from "@workhorse/pages/designer/ToolConfiguration/components/ToolConfirmationModeNotification";
import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import {readQuery, writeQuery} from "@workhorse/dataApi";
import {orderBeforePreview} from "@workhorse/api/designer/lib/designer-state/designerStateVars";
import {readSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {useClientEvent} from "@api/events/client";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useEffect, useCallback} from "@workhorse/api/rendering";

type ToolConfirmationProps = {
    sessionId: string;
};
const nullDate = null as unknown as Date;

const ToolConfirmation = ({sessionId}: ToolConfirmationProps) => {
    const [designerState, set] = useDesignerSessionState();
    const {configurationConfirm, selectedAgendaItemId} = designerState;
    const [_, toggleLeftDrawer] = useDrawerLeftToggler();

    const {onboardingType} = useOnboardingSession();

    useClientEvent("palette-open", (isOpen) => {
        if (isOpen && configurationConfirm === ConfigurationConfirmType.Tool) {
            onCancelNotification();
        }
    });

    const onConfirmNotification = useCallback(() => {
        const agendaItems = designer.currentAgendaItems();
        const a = agendaItems?.find((a) => a.id === selectedAgendaItemId);

        const localAgendaItems = readQuery("LocalAgendaItemsDocument", {
            variables: {
                id: sessionId,
            },
        })?.agendaItems;

        writeFromFullSession(
            sessionId,
            {
                agendaItems:
                    localAgendaItems?.map((item) =>
                        item.order === -1
                            ? {
                                  ...item,
                                  isDeleted: true,
                              }
                            : item
                    ) ?? [],
            },
            true
        );

        setTimeout(() => {
            designer
                .commit({
                    agendaItemToResetId: a?.id,
                })
                .then(() => {
                    set({
                        configurationConfirm: null,
                        selectedAgendaItemId: null,
                    });
                });
        }, 100);
    }, [selectedAgendaItemId, sessionId, set]);

    const onCancelNotification = () => {
        const agendaItems = designer.currentAgendaItems();
        const a = agendaItems?.find((a) => a.id === selectedAgendaItemId);
        const newAgendaItem = a
            ? {
                  ...a,
                  __typename: "AgendaItem" as const,
                  createdAt: nullDate,
                  updatedAt: nullDate,
                  artifact: a?.artifact
                      ? {
                            ...a?.artifact,
                            createdAt: nullDate,
                            updatedAt: nullDate,
                            resourceResults:
                                a?.artifact?.resourceResults?.map((r) => ({
                                    ...r,
                                    createdAt: nullDate,
                                    updatedAt: nullDate,
                                })) ?? [],
                            properties: a?.artifact?.properties?.map((p) => ({
                                ...p,
                                createdAt: nullDate,
                                updatedAt: nullDate,
                            })),
                        }
                      : null,
              }
            : null;

        const localAgendaItems = readQuery("LocalAgendaItemsDocument", {
            variables: {
                id: sessionId,
            },
        })?.agendaItems;
        const agendaItemBeforePreview = localAgendaItems?.find((a) => a.order === -1) ?? null;

        if (selectedAgendaItemId && newAgendaItem) {
            writeFromFullSession(
                sessionId,
                {
                    agendaItems:
                        localAgendaItems?.map((item) =>
                            item.id === agendaItemBeforePreview?.id
                                ? {...item, order: a?.order ?? 0, isDeleted: false}
                                : item.id === selectedAgendaItemId
                                ? newAgendaItem
                                : item
                        ) ?? [],
                },
                true
            );
        }

        designer.undoChanges({
            from: ["agendaItems"],
        });

        const existingSession = readSession({sessionId: sessionId});
        if (selectedAgendaItemId) {
            designer.api.agendaItem.remove({
                // this is used only for preview. Do not use it for other purposes
                forceDelete: true,
                id: selectedAgendaItemId,
            });
        }
        const storedOrder = orderBeforePreview();

        if (existingSession && !agendaItemBeforePreview) {
            writeFromFullSession(sessionId, {
                session: {
                    ...existingSession,
                    order: storedOrder !== undefined ? storedOrder : (existingSession?.order ?? 0) - 1,
                },
            });
            if (storedOrder !== undefined) {
                orderBeforePreview(undefined);
            }
        }

        designer.state.initializeOrResetState(sessionId);
        set({
            configurationConfirm: null,
            selectedAgendaItemId: null,
        });
        designer.state.setDesignerCommitState(false);

        if (designer.currentMacroArtifacts().findIndex((a) => a.artifactId === "flowos/agenda") !== -1) {
            toggleLeftDrawer(DrawerState.FullyOpen);
        }
    };

    useEffect(() => {
        if (configurationConfirm === ConfigurationConfirmType.Tool && onboardingType === SessionOnboardingType.NewUser) {
            onConfirmNotification();
        }
    }, [configurationConfirm, onConfirmNotification, onboardingType]);

    if (configurationConfirm === ConfigurationConfirmType.Tool && onboardingType !== SessionOnboardingType.NewUser) {
        return (
            <ToolConfirmationNotification
                open={configurationConfirm === ConfigurationConfirmType.Tool}
                handleConfirm={onConfirmNotification}
                onNotificationClose={onCancelNotification}
            />
        );
    }
    return null;
};

export default ToolConfirmation;
