import browserInfo from "@workhorse/api/BrowserInfo";
const OS = browserInfo.OS();

type KeyCombination = {
    code?: string;
    altKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
    metaKey?: boolean;
};

type Keybind = {
    WindowsKeys: KeyCombination;
    MacKeys: KeyCombination;
};

export type KeybindAction =
    | "toggleAudio"
    | "toggleVideo"
    | "toggleScreenShare"
    | "toggleRaiseHand"
    | "toggleRecording"
    | "toggleLivestream"
    | "switchPlayerViewModeGallery"
    | "switchPlayerViewModeAudience"
    | "switchPlayerViewModeSpotlight"
    | "paletteShareFile"
    | "paletteShareLink"
    | "paletteShareTool"
    | "paletteShareAgenda"
    | "openPalette"
    | "toggleExpandAgenda";

export const globalKeybinds: Record<KeybindAction, Keybind> = {
    toggleAudio: {
        WindowsKeys: {
            altKey: true,
            code: "KeyA",
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyA",
        },
    },

    toggleVideo: {
        WindowsKeys: {
            altKey: true,
            code: "KeyV",
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyV",
        },
    },

    toggleScreenShare: {
        WindowsKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyS",
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyS",
        },
    },

    toggleRaiseHand: {
        WindowsKeys: {
            altKey: true,
            code: "KeyY",
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyY",
        },
    },

    toggleRecording: {
        WindowsKeys: {
            altKey: true,
            code: "KeyR",
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyR",
        },
    },

    toggleLivestream: {
        WindowsKeys: {
            altKey: true,
            code: "KeyL",
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
            code: "KeyL",
        },
    },

    switchPlayerViewModeGallery: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "Digit1",
        },
        MacKeys: {
            altKey: true,
            code: "Digit1",
        },
    },

    switchPlayerViewModeSpotlight: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "Digit2",
        },
        MacKeys: {
            altKey: true,
            code: "Digit2",
        },
    },

    switchPlayerViewModeAudience: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "Digit3",
        },
        MacKeys: {
            altKey: true,
            code: "Digit3",
        },
    },

    // keep these in sync with CommandPalette.tsx
    paletteShareFile: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "KeyF",
        },
        MacKeys: {
            metaKey: true,
            ctrlKey: true,
            code: "KeyF",
        },
    },

    paletteShareLink: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "KeyL",
        },
        MacKeys: {
            metaKey: true,
            ctrlKey: true,
            code: "KeyL",
        },
    },

    paletteShareTool: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "KeyE",
        },
        MacKeys: {
            metaKey: true,
            ctrlKey: true,
            code: "KeyE",
        },
    },

    paletteShareAgenda: {
        WindowsKeys: {
            altKey: true,
            ctrlKey: true,
            code: "KeyA",
        },
        MacKeys: {
            metaKey: true,
            ctrlKey: true,
            code: "KeyA",
        },
    },

    openPalette: {
        WindowsKeys: {
            ctrlKey: true,
            code: "KeyK",
        },
        MacKeys: {
            metaKey: true,
            code: "KeyK",
        },
    },

    toggleExpandAgenda: {
        WindowsKeys: {
            altKey: true,
            shiftKey: true,
        },
        MacKeys: {
            altKey: true,
            shiftKey: true,
        },
    },
};

export const checkGlobalKeybinds = (event: KeyboardEvent) => {
    const keybinds = globalKeybinds;

    for (const action in keybinds) {
        const keybind = keybinds[action as KeybindAction];
        const keys = OS.toLowerCase().includes("mac") ? keybind.MacKeys : keybind.WindowsKeys;

        if (
            event.altKey === !!keys.altKey &&
            event.ctrlKey === !!keys.ctrlKey &&
            event.shiftKey === !!keys.shiftKey &&
            event.metaKey === !!keys.metaKey &&
            event.code.toLowerCase() === keys.code?.toLowerCase()
        ) {
            return action as KeybindAction;
        }
    }

    return undefined;
};

/**
 * @param action - The action to get the keybind for
 * @returns A string array with the keys for the given action
 * @example getGlobalKeybind("toggleAudio") // ["Alt", "A"]
 *
 **/

export const getGlobalKeybind = (action: KeybindAction): string[] => {
    const keybinds = globalKeybinds;
    const foundAction = keybinds[action];
    const isMac = OS.toLowerCase().includes("mac");
    const keybind = isMac ? foundAction.MacKeys : foundAction.WindowsKeys;

    const keys: string[] = [];

    if (!isMac) {
        if (keybind.ctrlKey) {
            keys.push("Ctrl");
        }
        if (keybind.altKey) {
            keys.push("Alt");
        }
        if (keybind.shiftKey) {
            keys.push("Shift");
        }
    } else {
        if (keybind.metaKey) {
            keys.push("⌘");
        }
        if (keybind.altKey) {
            keys.push("⌥");
        }
        if (keybind.ctrlKey) {
            keys.push("⌃");
        }
        if (keybind.shiftKey) {
            keys.push("⇧");
        }
    }

    const code = keybind.code;
    if (code) {
        keys.push(code.replace("Key", "").replace("Digit", "").toUpperCase());
    }

    return keys;
};

/**
 * @param action - The action to get the keybind for
 * @returns A string with the keys for the given action joined by " + "
 * @example // "Ctrl + Shift + A"
 **/

export const getGlobalKeybindString = (action: KeybindAction): string => {
    const stringArray = getGlobalKeybind(action);
    return stringArray.join(" + ");
};
