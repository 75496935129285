import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {useTranslation} from "react-i18next";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as AutoRecordingIcon} from "../../assets/auto-recording.svg";
import {SessionOptionWarning} from "./SessionOptionWarning";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";

const styles = {...genericStyles};

type SessionAutoRecordingProps = {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    hideWarning?: boolean;
};

const SessionAutoRecordingToggler = ({enabled, onChange, showIcon, disabled, className, hideWarning}: SessionAutoRecordingProps) => {
    const {canUseRecording, triggerGenericAccessToast} = useWorkspaceAccess();

    const handleOnChange = () => {
        if (!canUseRecording()) {
            triggerGenericAccessToast();
            return;
        }

        onChange(!enabled);
    };
    const {t} = useTranslation();

    return (
        <div className={cls(styles.togglerWrapper, styles.togglerWrapperWithFooter, className)}>
            <div className="flex fullw">
                {showIcon ? (
                    <div className={styles.iconWrapper}>
                        <AutoRecordingIcon />
                    </div>
                ) : null}
                <TogglerContainer
                    title={
                        <Typography variant="base" fontWeight="bold" color="secondary">
                            {t("player.settings.general.session.automatic_recording.title")}
                        </Typography>
                    }
                    secondTitle={
                        <Typography color="quaternary" component="span">
                            {t("player.settings.general.session.automatic_recording.description")}
                        </Typography>
                    }
                    checked={enabled}
                    name="autoRecording"
                    onChange={handleOnChange}
                    togglePlacement="secondTitle"
                    label=" "
                    className={cls(styles.toggler)}
                    disabled={disabled}
                />
            </div>
            {enabled && !hideWarning && (
                <SessionOptionWarning
                    title={t("player.settings.general.session.automatic_recording.warning_title")}
                    description={t("player.settings.general.session.automatic_recording.warning_description")}
                    className="mt-8"
                />
            )}
        </div>
    );
};

export default SessionAutoRecordingToggler;
