import {forwardRef, useCallback, useMemo, useState, createElement} from "@workhorse/api/rendering";
import MuiInputAdornment from "@material-ui/core/InputAdornment";
import {InputProps} from "./Input";
import IconButton from "@ui/core/components/IconButton";
import {cls} from "@ui/cdk/util/util";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import classes from "./style/input.module.scss";

type MaskedInputProps = Omit<
    InputProps,
    "label" | "variant" | "margin" | "labelClassName" | "error" | "helperText" | "maxCharCount" | "showTypedCharCount"
>;

/**
 * @deprecated
 * NEVER USE THIS
 *
 * this component is used behind-the-scene by the Input component
 * made for masked input without the password type, so the browser won't try to save the password
 *
 */
const MaskedInput = forwardRef((props: MaskedInputProps, ref: any) => {
    const {
        type = "password",
        spellCheck = false,
        autoComplete = "off",

        endAdornment,
        ...rest
    } = props;

    const [vsb, setVsb] = useState(false);

    const toggle = useCallback(() => setVsb((c) => !c), []);

    const vsbToggler = useMemo(
        () => (
            <MuiInputAdornment position="end">
                {endAdornment}
                <IconButton className={cls(null)} onClick={toggle} size="medium">
                    {vsb ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </MuiInputAdornment>
        ),
        [vsb, endAdornment]
    );

    /**
     * Leave this as it is in case we re-enable the variant prop
     * so we can conditionally render the appropriate input based on the variant
     */
    return createElement(OutlinedInput, {
        key: "inp-el",
        type: "text",
        spellCheck,
        ...rest,
        className: cls(props.className, vsb ? "" : classes.masked),
        autoComplete,
        ref,
        endAdornment: vsbToggler,
    });
});

MaskedInput.displayName = "MaskedInput";

export default MaskedInput;
