import Button from "@ui/cdk/Button";
import {cls, roundToDecimals} from "@ui/cdk/util/util";
import {MutableRefObject, useRef, useState} from "@workhorse/api/rendering";
import {Session} from "@workhorse/declarations/dataTypes";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {Rnd} from "react-rnd";
import classes from "./styles/LobbyCoverImage.module.scss";
import {useTranslation} from "react-i18next";
import {DraggableEvent, DraggableData} from "react-draggable";

type LobbyCoverImageRepositionProps = {
    session: Session;
    imgRef: MutableRefObject<HTMLImageElement | null>;
    hideRnd: boolean;
    coverHeight: number;
    yPosition: number;
    setYPosition: React.Dispatch<React.SetStateAction<number>>;
    setIsCoverImageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    isRepositioning: boolean;
    toggleReposition: () => void;
    savePosition: (newYPosition: number) => void;
    setHideRnd: (value: React.SetStateAction<boolean>) => void;
    isMobile: boolean;
    drawerLeftOpen: boolean;
    drawerRightOpen: boolean;
    drawerLeftWidth: number;
    drawerRightWidth: number;
};

const LobbyCoverImageReposition = (props: LobbyCoverImageRepositionProps) => {
    const {
        session,
        imgRef,
        hideRnd,
        setHideRnd,
        coverHeight,
        yPosition,
        setYPosition,
        setIsCoverImageLoaded,
        isRepositioning,
        toggleReposition,
        savePosition,
        isMobile,
        drawerLeftOpen,
        drawerLeftWidth,
        drawerRightOpen,
        drawerRightWidth,
    } = props;
    const rndRef = useRef<Rnd>(null);

    const {t} = useTranslation();

    const [dragYPosition, setDragYPosition] = useState(yPosition ?? 0);
    setIsCoverImageLoaded(false);

    const handleCoverImageLoaded = () => {
        setIsCoverImageLoaded(true);
    };

    const handleOnDrag = (e: DraggableEvent, d: DraggableData) => {
        const currentHeight = imgRef.current?.height ?? 0;

        if (d.y > 0) {
            setHideRnd(true);
            setDragYPosition(0);
            if (yPosition !== 0) {
                setYPosition(0);
            }
        } else if (imgRef.current && d.y * -1 > currentHeight - coverHeight) {
            setHideRnd(true);
            setDragYPosition(100);
            if (yPosition !== 100) {
                setYPosition(100);
            }
        } else {
            setHideRnd(false);
            setDragYPosition(roundToDecimals(((d.y * -1) / (currentHeight - coverHeight)) * 100, 2));
        }
    };

    const handleOnDragStop = (e: DraggableEvent, d: DraggableData) => {
        checkAndSetValidYPosition(d.y);
    };

    const checkAndSetValidYPosition = (yPos: number) => {
        //checks if yPosition is out of bounds (> 0 or > -the height of the image)
        //if valid, sets the yPosition, else it sets it at the bounds (0 or 100)

        if (yPos > 0) {
            setDragYPosition(0);
        } else if (!!imgRef.current && -yPos / (imgRef.current.height - coverHeight) > 1) {
            setDragYPosition(100);
        } else if (imgRef.current) {
            const posToSet = roundToDecimals(Math.abs((yPos / (imgRef.current.height - coverHeight)) * 100), 2);

            setDragYPosition(posToSet);
        }

        setHideRnd(false);
    };

    const handleSavePosition = () => {
        savePosition(dragYPosition);
    };

    return (
        <>
            <div
                className={classes.dragImageTooltip}
                style={{
                    ...(drawerLeftOpen ? {left: `calc(50% + ${(drawerLeftWidth + 26) / 2}px)`} : null),
                    ...(drawerRightOpen ? {left: `calc(50% - ${(drawerRightWidth + 26) / 2}px`} : null),
                }}
            >
                {t("lobby.cover_image.drag_to_reposition")}
            </div>

            <Rnd
                className={hideRnd ? classes.rndHidden : ""}
                ref={rndRef}
                size={{width: "100%", height: `${imgRef.current ? imgRef.current?.height : 9999}px`}}
                position={{
                    x: 0,
                    y: imgRef.current
                        ? ((-imgRef.current.height + coverHeight) * dragYPosition) / 100 > -imgRef.current.height + coverHeight
                            ? ((-imgRef.current.height + coverHeight) * dragYPosition) / 100
                            : -imgRef.current.height + coverHeight
                        : 0,
                }}
                onDrag={handleOnDrag}
                onDragStop={handleOnDragStop}
                dragAxis="y"
            >
                {session.lobbyPicture?.originalPictureUrl ? (
                    <img
                        onLoad={handleCoverImageLoaded}
                        className={classes.coverImage}
                        src={session.lobbyPicture.originalPictureUrl}
                        alt={t("lobby.cover_image.name") ?? undefined}
                    />
                ) : null}
            </Rnd>

            <div
                className={cls(classes.coverImageRpsButtonContainer, isRepositioning && "visible centered")}
                style={{
                    ...(drawerLeftOpen ? {left: `calc(50% + ${(drawerLeftWidth + 26) / 2}px)`} : null),
                    ...(drawerRightOpen ? {left: `calc(50% - ${(drawerRightWidth + 26) / 2}px`} : null),
                }}
            >
                <Button variant="tertiary" onClick={toggleReposition}>
                    {t("g.cancel")}
                </Button>
                <Button variant="tertiary" onClick={handleSavePosition}>
                    {isMobile ? t("g.save") : t("lobby.cover_image.save_position")}
                </Button>
            </div>
        </>
    );
};

export default LobbyCoverImageReposition;
