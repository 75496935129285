import ConvertingNotification from "@workhorse/pages/designer/ToolConfiguration/components/ConvertingNotification";

type ProcessingFinishedConfirmationProps = {
    cancel: () => void;
};

const Converting = (props: ProcessingFinishedConfirmationProps) => {
    return <ConvertingNotification onNotificationClose={props.cancel} />;
};

export default Converting;
