import {setParticipantConferenceRole} from "@artifacts/conference/api/conferenceControllerApi";
import SpeakerActionsContainer from "@artifacts/conference/ui/SpeakerActionsContainer";
import {ConferenceParticipantStatus} from "@generated/data";
import Avatar from "@ui/cdk/Avatar";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {
    LocalVideo,
    participantIsAssistant,
    ParticipantVisible,
    RemoteVideo,
    useCameraSettings,
    useSpeakers,
} from "@workhorse/api/conference2";
import {useCallback, useState} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {getColor, makeInitials} from "@workhorse/util";
import {resizeGooglePicture} from "@workhorse/util/googleProfile";
import {ReactComponent as CloseIcon} from "../../../../assets/media/screenshare-close.svg";
import {ReactComponent as ExpandIcon} from "../../../../assets/media/screenshare-expand.svg";
import {ReactComponent as ShrinkIcon} from "../../../../assets/media/screenshare-shrink.svg";
import {ReactComponent as ResizeIcon} from "../../../../assets/media/screenshare_resize_icon.svg";
import {DraggableDialog, useDraggableDialogEvents} from "../DraggableDialog";
import PositionedContent from "../PositionedContent";
import SpeakerPopupParticipantInfo from "./SpeakerPopupParticipantInfo";
import SpeakerPopupParticipantProfile from "./SpeakerPopupParticipantProfile";
import classes from "./styles/SpeakerPopupVideo.module.scss";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {brandingVideoTileColor} from "@workhorse/pages/user/profile/Theming/utils";
import {useReactiveVar} from "@apollo/client";
import {customFirstLastName, defaultVideoTileBackgroundColor} from "@common/utils";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

interface VideoRendererProps {
    className?: string;
    participantId: string;
    isLocalParticipant: boolean;
    onResize?: (width: number, height: number) => void;
}

function VideoRenderer(props: VideoRendererProps) {
    if (props.isLocalParticipant) {
        return (
            <LocalVideoRenderer
                participantId={props.participantId}
                className={props.className}
                onResize={props.onResize}
                isLocalParticipant={true}
            />
        );
    }

    return <RemoteVideo className={props.className} participantId={props.participantId} onResize={props.onResize} />;
}

function LocalVideoRenderer(props: VideoRendererProps) {
    // const {isVideoEnabled} = {isVideoEnabled: false}; // useLocalVideo();
    const {cameraHidden} = useCameraSettings();

    if (/*!isVideoEnabled || */ cameraHidden) {
        return null;
    }

    return <LocalVideo className={props.className} onResize={props.onResize} />;
}

interface ControlsProps {
    firstName?: string;
    lastName?: string;
    isMobile: boolean;
    isMinimized: boolean;
    toggleSize: () => void;
}

function Controls(props: ControlsProps) {
    const {firstName = "", lastName = ""} = props;
    const displayName = firstName + " " + lastName;

    return (
        <>
            <div className={cls("flex", classes.header)}>
                <Typography className={classes.headerTitle} title={displayName}>
                    {displayName.trim()}&apos;s spotlight
                </Typography>
                {props.isMobile ? null : props.isMinimized ? (
                    <Tooltip arrow placement="bottom" title="Restore" key="restore-tooltip">
                        <ExpandIcon className={classes.headerIcon} onClick={props.toggleSize} key="restore-icon" />
                    </Tooltip>
                ) : (
                    <Tooltip arrow placement="bottom" title="Minimize" key="minimize-tooltip">
                        <ShrinkIcon className={classes.headerIcon} onClick={props.toggleSize} key="minimize-icon" />
                    </Tooltip>
                )}
                <RemoveSpotlights />
            </div>

            {!props.isMinimized && (
                <div className={cls("flex", classes.footer)}>
                    <ResizeIcon />
                </div>
            )}
        </>
    );
}

function RemoveSpotlights() {
    const isAdmin = useParticipantsStore(useShallow(({currentParticipant}) => currentParticipant?.isOwner ?? false));
    const speakers = useSpeakers();

    if (!isAdmin) {
        return null;
    }

    const stopSpotlights = () => {
        const promises = speakers.map((speaker) => setParticipantConferenceRole(speaker.id, ConferenceParticipantStatus.Participant));
        return Promise.all(promises);
    };

    return (
        <Tooltip arrow placement="bottom" title="Remove spotlights">
            <CloseIcon className={classes.headerIcon} onClick={stopSpotlights} />
        </Tooltip>
    );
}

interface SpeakerAvatarProps {
    avatar?: string | null;
    firstName?: string;
    lastName?: string;
}

function SpeakerAvatar(props: SpeakerAvatarProps) {
    const {avatar, firstName = "", lastName = ""} = props;

    if (avatar) {
        return (
            <div className={classes.avatar}>
                <img src={resizeGooglePicture(avatar, 1024)} alt="" />
            </div>
        );
    }

    const {customFirstName, customLastName} = customFirstLastName(firstName, lastName);

    const initials = makeInitials(customFirstName, customLastName);
    const hash = getColor(initials);

    return (
        <div
            className={classes.initialsWrapper}
            style={{
                backgroundColor: hash,
            }}
        >
            <Avatar className={classes.initials}>{initials}</Avatar>
        </div>
    );
}

type SpeakerPopupVideoProps = {
    tileId?: number;
    avatar?: string | null;
    firstName?: string;
    lastName?: string;
    participantId: string;
    isLocalParticipant: boolean;
    draggable?: boolean;
    controls?: boolean;
    participant: Participant;
};

export function SpeakerPopupVideo({
    tileId,
    avatar,
    firstName,
    lastName,
    participantId,
    isLocalParticipant,
    draggable = false,
    controls = false,
    participant,
}: SpeakerPopupVideoProps) {
    const status = useRemoteParticipantStatus(participantId);
    // const hasVideo = isLocalParticipant ? isVideoEnabled : tileId != null;
    const {cameraOn: hasVideo} = status;

    const {isMobile} = useMobile();
    const {id: sessionId} = useSession();

    const [width, setWidth] = useState<number>(hasVideo ? 0 : 328);
    const [height, setHeight] = useState<number>(hasVideo ? 0 : 185);
    const [minimized, setMinimized] = useState<boolean>(false);

    const events = useDraggableDialogEvents();
    const videoTileColor = useReactiveVar(brandingVideoTileColor);

    const handleVideoResize = useCallback((width: number, height: number) => {
        setWidth(width);
        setHeight(height);
    }, []);

    const toggleSize = () => {
        if (minimized) {
            events.expand();
        } else {
            events.minimize();
        }
    };

    const handleResetPosition = () => {
        events.resetPosition();
    };

    const video = (
        <div className={cls("wrapper169", classes.videoRoot, minimized && classes.videoMinimized)}>
            <div className={cls("inner", classes.videoInner)}>
                <div className={classes.videoHeader}>
                    {minimized ? (
                        <SpeakerPopupParticipantProfile participantId={participantId} isLocalParticipant={isLocalParticipant} />
                    ) : null}
                    <SpeakerActionsContainer
                        participantId={participantId}
                        onResetPosition={handleResetPosition}
                        location="speaker-popup-video"
                        toggleSize={toggleSize}
                        isMinimized={minimized}
                        resize={true}
                        sessionId={sessionId}
                        participantIsAssistant={participantIsAssistant(participant)}
                    />
                </div>
                {!minimized ? (
                    <>
                        <div
                            className={classes.speakerCircleContainer}
                            style={{
                                backgroundColor: videoTileColor ? videoTileColor : defaultVideoTileBackgroundColor,
                            }}
                        >
                            <div className={classes.speakerCircle}>
                                <SpeakerAvatar avatar={avatar} firstName={firstName} lastName={lastName} />
                            </div>
                        </div>
                        {hasVideo ? (
                            <VideoRenderer
                                className={cls(classes.video, (height === 0 || width === 0) && "hidden", "playing")}
                                participantId={participant.id}
                                isLocalParticipant={isLocalParticipant}
                                onResize={handleVideoResize}
                            />
                        ) : null}
                    </>
                ) : null}

                <ParticipantVisible participantId={participantId} location="floatingSpeaker" />
                {!minimized ? <SpeakerPopupParticipantInfo participantId={participantId} isLocalParticipant={isLocalParticipant} /> : null}
            </div>
        </div>
    );

    if (draggable) {
        return (
            <DraggableDialog
                id="draggable-spotlight"
                width={width}
                height={height}
                events={events}
                className={classes.rnd}
                onMinimizeChange={setMinimized}
                enableResizing={false}
                isSpeakerWidget={true}
                aspectRatio={1.77}
            >
                {video}
            </DraggableDialog>
        );
    }

    return (
        <PositionedContent top={30} right={30} width={width} height={height}>
            {video}
        </PositionedContent>
    );
}
