import {HostType, SessionLifecycle} from "@generated/data";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {PropsWithChildren, useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {readQuery, useQuery} from "@workhorse/dataApi";
import {WithChildren} from "@workhorse/declarations";
import {Participant} from "@workhorse/declarations/dataTypes";
import {FullMemorySession} from "@workhorse/pages/memory/MemoryEntrypoint";
import {makeSessionIdFromRoute, useSessionIdFromRoute} from "./CurrentSessionIdProvider";
import DesignerDataProviders from "./DesignerSessionDataProviders";
import {useHostType} from "./HostTypeProvider";
import {useShallow} from "zustand/react/shallow";

type SessionDataProvidersProps = {
    isEditMode?: boolean;
};

// SESSION
function useSessionDataStore(props?: SessionDataProvidersProps) {
    const designerSessionId = useQuery("DesignerSessionIdDocument")?.data?.designerSessionId?.sessionId!;
    const playerSessionId = useSessionIdFromRoute()!;
    const sessionId = (props?.isEditMode ? designerSessionId : playerSessionId)!;

    const {data} = useQuery("GetSessionDocument", {
        variables: {
            id: sessionId,
            noDiff: !props?.isEditMode,
        },
        skip: !sessionId,
    });

    return data?.session! ?? {};
}

const [SessionData, useSession, PlayerSessionContext] = createContextProvider(
    {
        name: "SessionData",
        strict: true,
    },
    useSessionDataStore
);

export {useSession, PlayerSessionContext};
export {useParticipants, PlayerParticipantsContext};
export {useCurrentParticipant};
export {useMyParticipant};
export {useAgendaItems, PlayerAgendaItemsContext};
export {useMacroArtifacts, PlayerMacroArtifactsContext};
export {useCurrentAgendaItem, PlayerCurrentAgendaItemContext};
export {useIsLobby};
export {useIsPreJoin};

// Participants
function useSessionParticipantsStore(props?: SessionDataProvidersProps & {value?: FullMemorySession["data"]["participants"]}) {
    const designerSessionId = useQuery("DesignerSessionIdDocument")?.data?.designerSessionId?.sessionId!;
    const playerSessionId = useSessionIdFromRoute()!;
    const sessionId = (props?.isEditMode ? designerSessionId : playerSessionId)!;
    const {data} = useQuery("LocalParticipantsDocument", {
        variables: {
            id: sessionId,
            noDiff: !props?.isEditMode,
        },
        skip: !sessionId || !!props?.value,
    });

    return (props?.value as Participant[]) ?? data?.participants! ?? [];
}

export function getParticipants() {
    const playerSessionId = useSessionIdFromRoute()!;
    const sessionId = playerSessionId!;
    const {data} = useQuery("LocalParticipantsDocument", {
        variables: {
            id: sessionId,
        },
        skip: !sessionId,
    });

    return data?.participants! ?? [];
}

const [SessionParticipantsProvider, useParticipants, PlayerParticipantsContext] = createContextProvider(
    {
        name: "SessionParticipantsProvider",
        strict: true,
    },
    useSessionParticipantsStore
);
export {SessionParticipantsProvider};

// CurrentParticipant
function useSessionCurrenParticipantStore(props?: SessionDataProvidersProps) {
    const designerSessionId = useQuery("DesignerSessionIdDocument")?.data?.designerSessionId?.sessionId!;
    const playerSessionId = useSessionIdFromRoute()!;
    const sessionId = (props?.isEditMode ? designerSessionId : playerSessionId)!;

    const {data} = useQuery("LocalCurrentParticipantDocument", {
        variables: {
            id: sessionId,
            noDiff: !props?.isEditMode,
        },
        skip: !sessionId,
    });
    return useMemo(() => data?.currentParticipant! ?? {}, [data?.currentParticipant]);
}

const [SessionCurrentParticipantProvider, useCurrentParticipant] = createContextProvider(
    {
        name: "SessionCurrentParticipantProvider",
        strict: true,
    },
    useSessionCurrenParticipantStore
);

// MyParticipant
// NOTE: to not be confused with currentParticipant
// this extracts the participant object from the participants array
// whereas currentParticipant is a different type of object
function useSessionMyParticipantStore(props?: SessionDataProvidersProps) {
    const currentParticipant = useSessionCurrenParticipantStore(props);
    const participants = useSessionParticipantsStore(props);
    const myParticipant = useMemo(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => participants.find((p) => p.id === currentParticipant.id)! ?? {},
        [participants, currentParticipant.id]
    );
    return useMemo(() => myParticipant, [myParticipant]);
}

const [SessionMyParticipantProvider, useMyParticipant] = createContextProvider(
    {
        name: "SessionMyParticipantProvider",
        strict: true,
    },
    useSessionMyParticipantStore
);

// AgendaItems
function useSessionAgendaItemsStore(props?: SessionDataProvidersProps) {
    const designerSessionId = useQuery("DesignerSessionIdDocument")?.data?.designerSessionId?.sessionId!;
    const playerSessionId = useSessionIdFromRoute()!;
    const sessionId = (props?.isEditMode ? designerSessionId : playerSessionId)!;

    const {data} = useQuery("LocalAgendaItemsDocument", {
        variables: {
            id: sessionId,
            noDiff: !props?.isEditMode,
            // @ts-ignore
            excludeDeleted: props?.isEditMode,
        },
        skip: !sessionId,
    });

    return data?.agendaItems || [];
}

const [SessionAgendaItemsProvider, useAgendaItems, PlayerAgendaItemsContext] = createContextProvider(
    {
        name: "SessionAgendaItemsProvider",
        strict: true,
    },
    useSessionAgendaItemsStore
);

// MacroArtifacts
function useSessionMacroArtifactsStore(props?: SessionDataProvidersProps) {
    const designerSessionId = useQuery("DesignerSessionIdDocument")?.data?.designerSessionId?.sessionId!;
    const playerSessionId = useSessionIdFromRoute()!;
    const sessionId = (props?.isEditMode ? designerSessionId : playerSessionId)!;

    const {data} = useQuery("LocalMacroArtifactsDocument", {
        variables: {
            id: sessionId,
            noDiff: !props?.isEditMode,
            // @ts-ignore
            excludeDeleted: props?.isEditMode,
        },
        skip: !sessionId,
    });
    return data?.macroArtifacts ?? [];
}

const [SessionMacroArtifactsProvider, useMacroArtifacts, PlayerMacroArtifactsContext] = createContextProvider(
    {
        name: "SessionMacroArtifactsProvider",
        strict: true,
    },
    useSessionMacroArtifactsStore
);

// currentAgendaItem
function useCurrentAgendaItemStore(props?: SessionDataProvidersProps) {
    const session = useSessionDataStore(props);
    const agendaItems = useSessionAgendaItemsStore(props);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const {order, id: sessionId} = session! ?? {};

    const currentAgendaItem = agendaItems.find((a) => a.order === order);

    return useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return currentAgendaItem! ?? {};
    }, [order, sessionId, currentAgendaItem]);
}

const [SessionCurrentAgendaItemProvider, useCurrentAgendaItem, PlayerCurrentAgendaItemContext] = createContextProvider(
    {
        name: "SessionCurrentAgendaItemProvider",
        strict: true,
    },
    useCurrentAgendaItemStore
);

// TODO: @maca - data api
function useCurrentIsLobbyStore() {
    const session = useSession();
    const {createdAt, lifecycle, quickSession, id, currentParticipant} = session;

    const canEnterSession = useParticipantsStore((state) => state.canEnterSession);

    const hostType = useHostType();

    const isLobby =
        hostType === HostType.Feedback
            ? false
            : !canEnterSession
            ? true
            : hostType === HostType.Templates
            ? false
            : !!id && (quickSession ? false : !createdAt || lifecycle === SessionLifecycle.NotStarted ? true : false);

    return useMemo(
        () => ({
            isLobby,
            myParticipantId: currentParticipant?.pid,
        }),
        [isLobby, currentParticipant?.pid]
    );
}

const [IsLobbyProvider, useIsLobby] = createContextProvider(
    {
        name: "SessionIsLobby",
        strict: true,
    },
    useCurrentIsLobbyStore
);

function useCurrentIsPreJoinStore() {
    const [isPreJoin, setIsPreJoin] = useState<boolean>(false);

    return useMemo(() => ({isPreJoin, setIsPreJoin}), [isPreJoin, setIsPreJoin]);
}

const [IsPreJoinProvider, useIsPreJoin] = createContextProvider(
    {
        name: "SessionIsPreJoin",
        strict: true,
    },
    useCurrentIsPreJoinStore
);

export function useParticipant(props: SessionDataProvidersProps & {participantId: string}) {
    const participants = useSessionParticipantsStore({
        isEditMode: props.isEditMode,
    });
    const thisParticipant = participants.find((p) => p.id === props.participantId);
    return useMemo(() => thisParticipant, [thisParticipant]);
}

export function readParticipant(props: SessionDataProvidersProps & {participantId: string}) {
    const participants = readParticipants({
        isEditMode: props.isEditMode,
    });
    return participants.find((p) => p.id === props.participantId);
}

export function readParticipants(
    props?: SessionDataProvidersProps & {
        sessionId?: string;
    }
) {
    const {sessionId} = props ?? {};
    const id = sessionId ?? makeSessionIdFromRoute(window.location.pathname)!;
    return (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        readQuery("LocalParticipantsDocument", {
            variables: {
                id,
                noDiff: !props?.isEditMode,
            },
        })?.participants! || []
    );
}

export function readMacroArtifact(props: SessionDataProvidersProps & {artifactId: string}) {
    return readQuery("LocalMacroArtifactDocument", {
        variables: {
            artifactId: props.artifactId,
            noDiff: !props.isEditMode,
        },
    });
}

export function readMacroArtifacts(props?: SessionDataProvidersProps & {sessionId?: string}) {
    const id = props?.sessionId ?? makeSessionIdFromRoute(window.location.pathname)!;

    return readQuery("LocalMacroArtifactsDocument", {
        variables: {
            id,
            noDiff: !props?.isEditMode,
        },
    });
}

export function readCurrentParticipant(props?: SessionDataProvidersProps & {sessionId?: string}) {
    const id = props?.sessionId ?? makeSessionIdFromRoute(window.location.pathname)!;
    return readQuery("LocalCurrentParticipantDocument", {
        variables: {
            id,
            noDiff: !props?.isEditMode,
        },
    })!.currentParticipant!;
}

export function readSession(props?: SessionDataProvidersProps & {sessionId?: string}) {
    const id = props?.sessionId ?? makeSessionIdFromRoute(window.location.pathname)!;
    return readQuery("GetSessionDocument", {
        variables: {
            id,
            noDiff: !props?.isEditMode,
        },
    })?.session;
}

export function useOwnerParticipant(props?: SessionDataProvidersProps) {
    const participants = useSessionParticipantsStore({
        isEditMode: props?.isEditMode,
    });
    const ownerParticipant = participants.find((p) => p.isOwner);
    return useMemo(() => ownerParticipant, [ownerParticipant]);
}

export function SessionDataProvidersWithDesignerProviders(props: PropsWithChildren<SessionDataProvidersProps>) {
    const {children, ...other} = props;
    return (
        <SessionData {...other}>
            <IsLobbyProvider>
                <IsPreJoinProvider>
                    <SessionCurrentParticipantProvider {...other}>
                        <SessionParticipantsProvider {...other}>
                            <SessionMyParticipantProvider {...other}>
                                <SessionAgendaItemsProvider {...other}>
                                    <SessionCurrentAgendaItemProvider {...other}>
                                        <SessionMacroArtifactsProvider {...other}>
                                            <DesignerDataProviders>{children}</DesignerDataProviders>
                                        </SessionMacroArtifactsProvider>
                                    </SessionCurrentAgendaItemProvider>
                                </SessionAgendaItemsProvider>
                            </SessionMyParticipantProvider>
                        </SessionParticipantsProvider>
                    </SessionCurrentParticipantProvider>
                </IsPreJoinProvider>
            </IsLobbyProvider>
        </SessionData>
    );
}

export default function SessionDataProviders(props: PropsWithChildren<SessionDataProvidersProps>) {
    const {children, ...other} = props;
    return (
        <SessionData {...other}>
            <IsLobbyProvider>
                <IsPreJoinProvider>
                    <SessionCurrentParticipantProvider {...other}>
                        <SessionParticipantsProvider {...other}>
                            <SessionMyParticipantProvider {...other}>
                                <SessionAgendaItemsProvider {...other}>
                                    <SessionCurrentAgendaItemProvider {...other}>
                                        <SessionMacroArtifactsProvider {...other}>{children}</SessionMacroArtifactsProvider>
                                    </SessionCurrentAgendaItemProvider>
                                </SessionAgendaItemsProvider>
                            </SessionMyParticipantProvider>
                        </SessionParticipantsProvider>
                    </SessionCurrentParticipantProvider>
                </IsPreJoinProvider>
            </IsLobbyProvider>
        </SessionData>
    );
}
