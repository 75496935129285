import {useCallback, useMemo, useState} from "@workhorse/api/rendering";
import {ReactComponent as SessionsLogoBorder} from "../../../assets/media/sessions-logos/sessions-logo-small-border.svg";
import {ReactComponent as AppleLogoBorder} from "../../../assets/media/apple-logo-border.svg";
import AddIcon from "@material-ui/icons/Add";
import classes from "../style/AppleLogin.module.scss";
import {cls} from "./util";
import Typography from "../Typography";
import Button from "../Button";
import Input from "@ui/cdk/Input";
import {appleCredentialsValidationSchema} from "@common/validation/apple-credentials.validation";
import Loading from "@workhorse/components/Loading";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {useMutation} from "@workhorse/dataApi";
import Tooltip from "../Tooltip";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import env from "@generated/environment";
import {HyperlinkExternal} from "@workhorse/util/links";

type AppleCredentials = {
    email: string;
    password: string;
};

type AppleLoginProps = {
    onClose: () => void;
    onAddCalendar: (credentials: AppleCredentials) => Promise<boolean>;
};

type AppleLoginState = AppleCredentials & {
    errors: Partial<AppleCredentials>;
};

const AppleLogin = ({onClose, onAddCalendar}: AppleLoginProps) => {
    const [state, setState] = useState<AppleLoginState>({email: "", password: "", errors: {}});
    const [loading, setLoading] = useState(false);

    const validateTarget = (target: keyof AppleCredentials, value: AppleCredentials[typeof target]): string | undefined => {
        try {
            appleCredentialsValidationSchema.validateSyncAt(target, {[target]: value});
        } catch (err) {
            return err.message;
        }

        return;
    };

    const handleOnChange = (target: keyof AppleCredentials, event: React.ChangeEvent<HTMLInputElement>) => {
        const msg = validateTarget(target, event.target.value);

        setState((state) => ({
            ...state,
            [target]: event.target.value,
            // we only revalidate if the error was previously shown via blur / submit validation
            errors: {...state.errors, [target]: state.errors[target] ? msg : undefined},
        }));
    };

    const handleOnBlur = (target: keyof AppleCredentials, event: React.FocusEvent<HTMLInputElement>) => {
        const msg = validateTarget(target, event.target.value);

        setState((state) => ({...state, errors: {...state.errors, [target]: msg}}));
    };

    const handleOnClose = () => {
        setState({email: "", password: "", errors: {}});

        onClose();
    };

    const handleOnSubmit = () => {
        const errors = {
            email: validateTarget("email", state.email),
            password: validateTarget("password", state.password),
        };

        setState((state) => ({...state, errors}));

        if (Object.values(errors).some((err) => !!err)) {
            return;
        }

        setLoading(true);

        onAddCalendar({email: state.email, password: state.password}).then((result) => {
            setLoading(false);
        });
    };

    const helpLink = `${env.helpCenterArticleBase}/help-center/calendar#9c4e1abd1d4347fbbbba8502de16986b`;

    return (
        <div className={cls("flex flex-col", classes.wrapper)}>
            {loading ? <Loading className={classes.loadingSpinner} /> : null}

            <div className={cls("fullw flex-col flex flex-center-all", loading && classes.loading)}>
                <div className="flex flex-align-center gap-24">
                    <SessionsLogoBorder />

                    <AddIcon className={classes.plus} />

                    <AppleLogoBorder />
                </div>

                <Typography className={classes.title}>Sessions need to authenticate with iCloud to connect to your calendar</Typography>

                <form className="fullw fullh">
                    <div className={cls("fullw flex flex-col", classes.input)}>
                        <Typography>iCloud Email Address</Typography>
                        <Input
                            value={state.email}
                            name="email"
                            type="email"
                            onChange={handleOnChange.bind(this, "email")}
                            onBlur={handleOnBlur.bind(this, "email")}
                            placeholder="address@apple.com"
                        />

                        {state.errors.email ? <Typography className={classes.inputError}>* {state.errors.email}</Typography> : null}
                    </div>

                    <div className={cls("fullw flex flex-col", classes.input)}>
                        <Typography className="flex flex-align-center">
                            iCloud App Specific Password{" "}
                            <Tooltip
                                arrow
                                title={
                                    <>
                                        <p>Two-factor authentication and an-app specific password are required to connect your account.</p>
                                        <p className={classes.titleTooltip}>
                                            <HyperlinkExternal href={helpLink} target="_blank">
                                                Learn more
                                            </HyperlinkExternal>
                                        </p>
                                    </>
                                }
                                placement="right"
                                interactive
                            >
                                <HelpOutlineRoundedIcon className={cls("ml-5", classes.titleIcon)} />
                            </Tooltip>
                        </Typography>
                        <Input
                            value={state.password}
                            name="password"
                            type="password"
                            onChange={handleOnChange.bind(this, "password")}
                            onBlur={handleOnBlur.bind(this, "password")}
                            placeholder="•••••••••••••"
                        />

                        {state.errors.password ? <Typography className={classes.inputError}>* {state.errors.password}</Typography> : null}
                    </div>
                </form>

                <Typography className={classes.message}>
                    Two-factor authentication and an-app specific password are required to connect your account. Visit our{" "}
                    <HyperlinkExternal href={getHelpCenterArticleLink("calendar")} target="_blank" rel="noreferrer">
                        Help Zone
                    </HyperlinkExternal>{" "}
                    to learn more.
                </Typography>

                <div className={classes.actions}>
                    <Button variant="plain" onClick={handleOnClose}>
                        Close
                    </Button>

                    <Button onClick={handleOnSubmit}>Add calendar</Button>
                </div>
            </div>
        </div>
    );
};

export type UseAppleLoginProps = {
    onLogin?: (email?: string) => void;
    onLoginFailure?: () => void;
    onLoginCancel?: () => void;
    onLogout?: () => void;
    onLogoutFailure?: () => void;
};

type UseAppleLoginReturnType = [() => void, (email: string) => void, () => void, undefined | JSX.Element];

export const useAppleLogin = (props: UseAppleLoginProps): UseAppleLoginReturnType => {
    const {onLogin, onLoginFailure, onLoginCancel, onLogout, onLogoutFailure} = props;

    const [component, setComponent] = useState<boolean>(false);

    const [syncAppleAccount] = useMutation("SyncAppleAccountDocument");

    const [removeToken] = useMutation("DeleteAppleCalendarConnectionDocument");

    const onClose = useCallback(() => {
        setComponent(false);
        onLoginCancel?.();
    }, [onLoginCancel]);

    const onAddCalendar = async (credentials: AppleCredentials) => {
        const result = await syncAppleAccount({
            variables: credentials,
        });

        const synced = result.data?.syncAppleAccount;

        if (synced) {
            onLogin?.(synced.email ?? undefined);
            setComponent(false);
        } else {
            onLoginFailure?.();
        }

        return !!synced;
    };

    const request = useCallback(() => {
        setComponent(true);
    }, []);

    const cancel = useCallback(() => {
        setComponent(false);
    }, []);

    const disconnect = useCallback(
        (email: string) => {
            removeToken({
                variables: {
                    email,
                },
            })
                .then((response) => {
                    if (response.data?.deleteUserAppleProviders) {
                        onLogout?.();
                    }
                })
                .catch(() => {
                    onLogoutFailure?.();
                });
        },
        [onLogout, onLogoutFailure, removeToken]
    );

    return useMemo(
        () => [request, disconnect, cancel, component ? <AppleLogin onClose={onClose} onAddCalendar={onAddCalendar} /> : undefined],
        [component, onAddCalendar, onClose, request, cancel, disconnect]
    );
};
