import Checkbox from "@ui/cdk/Checkbox";
import Typography from "@ui/cdk/Typography";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import IconButton from "@ui/core/components/IconButton";
import {useState} from "@workhorse/api/rendering";
import classes from "../styles/ImportOfferItem.module.scss";

type ImportOfferItemProps = {
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    onEditOffer: (offerId: string) => void;
    selectedOffers: string[];
    onSelectOffer: (event: React.ChangeEvent<HTMLInputElement>, offerId: string) => void;
    isImportedAlready: boolean;
};

const ImportOfferItem = (props: ImportOfferItemProps) => {
    const [showEditButton, setShowEditButton] = useState(false);

    const handleEditOffer = () => {
        props.onEditOffer(props.id);
    };

    const handleCheckOffer = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onSelectOffer(event, props.id);
    };

    return (
        <div className={classes.root} onMouseEnter={() => setShowEditButton(true)} onMouseLeave={() => setShowEditButton(false)}>
            <div className="mr-12">
                <Checkbox
                    onChange={handleCheckOffer}
                    checked={props.selectedOffers.includes(props.id) || props.isImportedAlready}
                    disabled={props.isImportedAlready}
                />
            </div>
            <div className={classes.imageWrapper}>
                <img src={props.imageUrl} alt={props.title} />
            </div>
            <div className={classes.offerText}>
                <Typography noWrap fontWeight="bolder">
                    {props.title}
                </Typography>
                <Typography noWrap color="secondary">
                    {props.description}
                </Typography>
            </div>
            {showEditButton && (
                <IconButton onClick={handleEditOffer} className={classes.editButton}>
                    <EditRoundedIcon />
                </IconButton>
            )}
        </div>
    );
};

export default ImportOfferItem;
