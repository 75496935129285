import {ExternalCalendarEventDate} from "@generated/data";
import {addDays, differenceInMilliseconds, format, isEqual, parseISO, startOfDay} from "date-fns";
import {format as formatTz} from "date-fns-tz";

export const getExternalEventDates = (
    start: ExternalCalendarEventDate,
    end: ExternalCalendarEventDate
): [Date, string | undefined, Date, string | undefined, boolean] => {
    const oneDayMilliseconds = 24 * 60 * 60 * 1000;

    if (start.date && end.date) {
        // start is 2022-03-09 and end is 2022-03-10 - for a 1 day event
        // start is 2022-03-09 and end is 2022-03-11 - for a 2 day event
        // so, we have to remove a day when parsing
        const startDate = startOfDay(parseISO(start.date));
        const endDate = parseISO(end.date);

        return [startDate, start.date, endDate, end.date, true];
    } else if (start.timeZone && end.timeZone && start.dateTime && end.dateTime) {
        let startDate: Date,
            endDate: Date,
            allDay = false;

        if (start.timeZone === "UTC" && !start.dateTime.endsWith("Z")) {
            startDate = parseISO(start.dateTime + "Z");
        } else if (!start.dateTime.includes("+") && !start.dateTime.includes("-") && !start.dateTime.includes("Z")) {
            const tz = formatTz(new Date(), "zzz", {timeZone: start.timeZone});

            startDate = parseISO(start.dateTime + tz);
        } else {
            startDate = parseISO(start.dateTime);
        }

        if (end.timeZone === "UTC" && !end.dateTime.endsWith("Z")) {
            // office when all day events for the date has no timezone
            endDate = parseISO(end.dateTime + "Z");

            const diff = differenceInMilliseconds(endDate, startDate);
            if (diff > 0 && diff / oneDayMilliseconds >= 1) {
                allDay = true;
            }
        } else if (!end.dateTime.includes("+") && !end.dateTime.includes("-") && !end.dateTime.includes("Z")) {
            const tz = formatTz(new Date(), "zzz", {timeZone: end.timeZone});

            endDate = parseISO(end.dateTime + tz);
        } else {
            endDate = parseISO(end.dateTime);
        }

        const diff = differenceInMilliseconds(endDate, startDate);
        allDay = allDay || diff >= oneDayMilliseconds;

        let startStr: string | undefined, endStr: string | undefined;

        if (allDay) {
            startStr = format(startDate, "yyyy-MM-dd");
            endStr = format(
                isEqual(startDate, startOfDay(startDate)) && diff % oneDayMilliseconds === 0 ? endDate : addDays(endDate, 1),
                "yyyy-MM-dd"
            );
        }

        return [startDate, startStr, endDate, endStr, allDay];
    } else {
        const startDate = parseISO(start.dateTime as string);
        const endDate = parseISO(end.dateTime as string);

        const diff = differenceInMilliseconds(endDate, startDate);
        const allDay = diff >= oneDayMilliseconds;

        let startStr: string | undefined, endStr: string | undefined;

        if (allDay) {
            startStr = format(startDate, "yyyy-MM-dd");
            endStr = format(
                isEqual(startDate, startOfDay(startDate)) && diff % oneDayMilliseconds === 0 ? endDate : addDays(endDate, 1),
                "yyyy-MM-dd"
            );
        }

        return [startDate, startStr, endDate, endStr, allDay];
    }
};
