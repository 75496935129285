import {cls} from "@ui/cdk/util/util";
import {ToolbarGenericProps} from "../api";
import classes from "./styles/Toolbar.module.scss";
import {ToolbarAlignment} from "./ToolbarAlignment";
import {ToolbarEmoji} from "./ToolbarEmoji";
import {ToolbarHeadings} from "./ToolbarHeadings";
import {ToolbarIndentation} from "./ToolbarIndentation";
import {ToolbarLists} from "./ToolbarLists";
import {ToolbarMarks} from "./ToolbarMarks";
import {ToolbarHistory} from "./ToolbarHistory";
import {ToolbarHyperlink} from "./ToolbarHyperlink";
import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import {useState} from "@workhorse/api/rendering";
import {ToolbarMoreOptions} from "./ToolbarMoreOptions";

export const Toolbar = (props: ToolbarGenericProps) => {
    const {toolbarTheme, hideToolbarItems, toolbarDirection, editor} = props;
    const [collapsed, setCollapsed] = useState(false);
    const editing = editor?.isEditable !== false;

    const onResize = (width: number, height: number) => {
        setCollapsed(width < 400);
    };

    const [ref, node] = useLocalResizeObserver(onResize);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.currentTarget.blur();
    };

    return (
        <div
            data-id="item-toolbar"
            ref={ref}
            className={cls(
                classes.root,
                toolbarTheme && classes[toolbarTheme],
                toolbarDirection && classes[toolbarDirection],
                props.className
            )}
            onMouseDown={handleMouseDown}
        >
            {editing ? (
                <>
                    {hideToolbarItems?.includes("headings") ? null : (
                        <>
                            <ToolbarHeadings {...props} />
                            <div className={classes.separator} />
                        </>
                    )}
                    <ToolbarMarks {...props} exclude={collapsed ? ["strike", "underline"] : undefined} />
                    <ToolbarHyperlink {...props} />
                    <div className={classes.separator} />
                    {hideToolbarItems?.includes("indentation") || collapsed ? null : (
                        <>
                            <ToolbarIndentation {...props} />
                            <div className={classes.separator} />
                        </>
                    )}
                    {hideToolbarItems?.includes("alignment") || collapsed ? null : (
                        <>
                            <ToolbarAlignment {...props} />
                            <div className={classes.separator} />
                        </>
                    )}
                    <ToolbarLists {...props} exclude={collapsed ? ["taskList"] : undefined} />
                    <ToolbarEmoji {...props} />
                    {collapsed ? null : <ToolbarHistory {...props} />}
                    {collapsed ? (
                        <>
                            <div className={classes.pushRight} />
                            <ToolbarMoreOptions {...props} />
                        </>
                    ) : null}
                </>
            ) : null}
        </div>
    );
};
