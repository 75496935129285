import IconButton from "@ui/core/components/IconButton";
import classes from "./style/TableHeadSortButton.module.scss";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import {cls} from "@ui/cdk/util";

type TableHeadSortButtonProps = {
    columnId: string;
    activeColumnId: string;
    direction: "asc" | "desc";
    onSort: (sort: {field: any; direction: "asc" | "desc"}) => void;
    canSort?: boolean;
};

const TableHeadSortButton = (props: TableHeadSortButtonProps) => {
    const {columnId, activeColumnId, direction, onSort, canSort} = props;

    const handleSort = () => {
        if (columnId === activeColumnId) {
            onSort({field: columnId, direction: direction === "asc" ? "desc" : "asc"});
        } else {
            onSort({field: columnId, direction: "asc"});
        }
    };

    return (
        <IconButton className={cls("ml-6", classes.root)} onClick={handleSort} disabled={!canSort}>
            {activeColumnId === columnId && canSort ? (
                direction === "asc" ? (
                    <ArrowUpwardRoundedIcon className={classes.selected} />
                ) : (
                    <ArrowDownwardRoundedIcon className={classes.selected} />
                )
            ) : (
                <ArrowUpwardRoundedIcon />
            )}
        </IconButton>
    );
};

export default TableHeadSortButton;
