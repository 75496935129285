import Typography from "@ui/cdk/Typography";
import {useIsWantedAgenda, useOnboardingSession, useWantedAgendaTitle} from "@workhorse/providers/OnboardingSessionProvider";
import {useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";

interface Props extends WithTooltipChildren {
    title: string;
}

export function OnboardingAgendaItem(props: Props) {
    const {tooltip} = useOnboardingSession();
    const wantedAgendaTitle = useWantedAgendaTitle(tooltip);

    const matchesItem = props.title === wantedAgendaTitle;
    const isWantedAgenda = useIsWantedAgenda(tooltip);
    const [drawer] = useDrawerLeftToggler();
    const isDrawerOpen = drawer.isFullyOpen;
    const show = matchesItem && !isWantedAgenda && isDrawerOpen;

    let title = "Click here to load the next agenda item.";

    if (tooltip === "begin-guide") {
        title = "Click here to begin your guided session.";
    }

    return (
        <OnboardingTooltip
            tooltipId={tooltip || ""}
            title={
                <Typography variant="sm" fontWeight="normal" color="background">
                    {title}
                </Typography>
            }
            placement="right"
            arrow={true}
            skip={!show}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
