import {makeVar, useReactiveVar, useSubscription} from "@apollo/client";
import {getContactByEmail} from "@common/utils";
import {OnReactionSendDocument, ReactionType} from "@generated/data";
import {useSessionInfo} from "@workhorse/api/conference2";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useEffect} from "@workhorse/api/rendering";
import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";
import {useShallow} from "zustand/react/shallow";

// * Screen readers will read the content of this variable when it changes.

export const ariaAnnouncerMessageVar = makeVar("");

const ARIAAnnouncer = () => {
    const {t} = useTranslation();
    const message = useReactiveVar(ariaAnnouncerMessageVar);

    const session = useSessionInfo();
    const sessionId = session?.sessionId;

    const currentParticipant = useCurrentParticipant();

    const {participantsMap} = useParticipantsStore(useShallow((state) => ({participantsMap: state.participantsMap})));

    const user = useUserInfo();

    useSubscription(OnReactionSendDocument, {
        skip: !currentParticipant.id || user.isPublicMemoryGuestMockd || !sessionId || sessionId.length === 0,
        variables: {
            sessionId,
        },
        onSubscriptionData: (result) => {
            const data = result.subscriptionData.data;

            if (!data || !data.onReactionSend) {
                return;
            }

            if (currentParticipant?.id === data.onReactionSend.participantId) {
                return;
            }

            if (!(currentParticipant?.isOwner || currentParticipant?.rbac?.session?.isAssistant)) {
                return;
            }

            const {participantId, reactionType} = data.onReactionSend;

            const foundParticipant = participantsMap[participantId];
            const {firstName: d_firstName, lastName: d_lastName, email = ""} = foundParticipant?.dataWithNullableEmail || {};
            const contact = getContactByEmail(email);
            const firstName = contact ? contact.firstName : d_firstName;
            const lastName = contact ? contact.lastName : d_lastName;
            const displayName = `${firstName} ${lastName}`;

            if (reactionType === ReactionType.Raisehand) {
                ariaAnnouncerMessageVar(
                    t("aria_announcer.participant_x_raised_their_hand", {name: displayName}) ??
                        `Participant ${displayName} raised their hand`
                );
            }
        },
    });

    useEffect(() => {
        if (session?.transcriptionActive) {
            ariaAnnouncerMessageVar(t("aria_announcer.transcription_now_active") ?? "Transcription is now active");
        } else {
            ariaAnnouncerMessageVar(t("aria_announcer.transcription_stopped") ?? "Transcription stopped");
        }
    }, [session?.transcriptionActive]);

    return (
        <div
            id="aria-announcer"
            aria-atomic="true"
            aria-live="polite"
            role="screen-reader"
            style={{
                position: "absolute",
                top: "-9999px",
                left: "-9999px",
            }}
        >
            {message}
        </div>
    );
};

export default ARIAAnnouncer;
