import {usePaletteResults} from "./CommandPaletteResultsProvider";
import classes from "./style/CommandPaletteContent.module.scss";
import {cls} from "@ui/cdk/util";
import PaletteRenderOneResult, {ResultListProps} from "./PaletteRenderOneResult";
import Typography from "@ui/cdk/Typography";
import {useTranslation} from "react-i18next";

export default function AgendaResults(props: ResultListProps) {
    const {t} = useTranslation();
    const {highlightId} = props;
    const {paletteResults} = usePaletteResults();
    const items = paletteResults.agendas;
    return items.length ? (
        <>
            <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
                {t("palette.agendas.title") ?? "Agendas"}
            </Typography>
            {items.map((item) => {
                return (
                    <PaletteRenderOneResult
                        item={item}
                        key={item.id}
                        highlighted={highlightId === item.id}
                        setActionArg={props.setActionArg}
                        setPaletteActiveCateg={props.setPaletteActiveCateg}
                        rootRef={props.rootRef}
                        scrollIntoView={props.scrollIntoView}
                        onHighlightText={t("palette.agendas.share_this_agenda") ?? "Share this agenda"}
                    />
                );
            })}
        </>
    ) : null;
}
