import {
    GetSessionsUsingAgendaTemplateDocument,
    GetSessionsUsingResourceDocument,
    SessionLifecycle,
    SessionsUsingResourceResult,
} from "@generated/data";
import Dialog from "@ui/core/components/Dialog";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import apollo from "@workhorse/api/apollo";
import {useEffect, useState, useMemo} from "@workhorse/api/rendering";
import {useUserInfo} from "@workhorse/providers/User";
import classes from "./style/UsageHistory.module.scss";
import UsageHistoryHeader from "./UsageHistoryHeader";
import UsageHistorySessionList from "./UsageHistorySessionList";

type UsageHistoryProps = {
    open: boolean;
    onClose: () => void;
    resourceId?: string;
    agendaTemplateId?: string;
    itemTitle?: string;
    templateId?: string;
};

export enum SessionSection {
    live = "live",
    upcoming = "upcoming",
    past = "past",
}

const UsageHistory = (props: UsageHistoryProps) => {
    const {open, onClose, resourceId, agendaTemplateId, itemTitle} = props;
    const workspaceAccess = useWorkspaceAccess();
    const canSeeOthersSessions = workspaceAccess.canSeeOthersSessionsInResxHistory();
    const userInfo = useUserInfo();

    const [filter, setFilter] = useState<{personal: boolean; others: boolean}>({
        personal: true,
        others: canSeeOthersSessions,
    });

    const [selectedSection, setSelectedSection] = useState<SessionSection | "all">("all");
    const [sessionsData, setSessionsData] = useState<SessionsUsingResourceResult[]>([]);

    const title = itemTitle || "Usage history";

    const getSessionsData = async () => {
        if (resourceId) {
            const res = await apollo.client.query({
                query: GetSessionsUsingResourceDocument,
                variables: {
                    resourceId: resourceId,
                },
                fetchPolicy: "cache-first",
            });
            return res.data.getSessionsByResourceId;
        } else if (agendaTemplateId) {
            const res = await apollo.client.query({
                query: GetSessionsUsingAgendaTemplateDocument,
                variables: {
                    agendaTemplateId: agendaTemplateId,
                },
                fetchPolicy: "cache-first",
            });
            return res.data.getSessionsByAgendaTemplateId;
        }
    };

    const initialize = async () => {
        const data = await getSessionsData();
        if (data?.length) {
            setSessionsData(data);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const currentLifecycle = useMemo(() => {
        return selectedSection === "past"
            ? SessionLifecycle.Ended
            : selectedSection === "upcoming"
            ? SessionLifecycle.NotStarted
            : selectedSection === "live"
            ? SessionLifecycle.Started
            : null;
    }, [selectedSection]);

    const filteredSessions = useMemo(() => {
        if (!currentLifecycle && !canSeeOthersSessions) {
            return sessionsData;
        }

        return sessionsData.filter((item) => {
            if (currentLifecycle && item.sessionLifecycle !== currentLifecycle) {
                return false;
            }

            if (!filter.others && userInfo.id !== item.ownerId) {
                return false;
            }

            if (!filter.personal && userInfo.id === item.ownerId) {
                return false;
            }

            return true;
        });
    }, [currentLifecycle, canSeeOthersSessions, sessionsData, filter]);

    return (
        <Dialog
            key="usage-history-dialog"
            open={open}
            onClose={onClose}
            classes={{paper: classes.paper}}
            BackdropProps={{
                style: {
                    backgroundColor: "rgba(100, 116, 139, 0.3)",
                },
            }}
        >
            <UsageHistoryHeader
                onClose={onClose}
                filter={filter}
                setFilter={setFilter}
                canSeeOthersSessions={canSeeOthersSessions}
                itemTitle={title}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
            />

            <UsageHistorySessionList sessions={filteredSessions} selectedSection={selectedSection} />
        </Dialog>
    );
};

export default UsageHistory;
