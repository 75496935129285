import Avatar from "@ui/cdk/Avatar";
import {cls} from "@ui/cdk/util/util";
import {forwardRef} from "react";
import Assistantbadge from "./assets/assistant-badge.svg";
import OnlineBadge from "./assets/online-badge.svg";
import OwnerBadge from "./assets/owner-badge.svg";
import RSVPAcceptedIcon from "./assets/rsvp-accepted.svg";
import RSVPDeclinedIcon from "./assets/rsvp-declined.svg";
import RSVPTentativeIcon from "./assets/rsvp-tentative.svg";
import styles from "./style/sessionsAvatar.module.scss";
import {SessionsAvatarIconLeft, SessionsAvatarIconRight, SessionsAvatarProps} from "./types";

function SessionsAvatarWithRef(props: SessionsAvatarProps, ref?: any) {
    const {
        /**
         * @param {SessionsAvatarIconLeft} iconLeft Adds an icon to the bottom left of the component.
         */
        iconLeft,
        /**
         * @param {SessionsAvatarIconRight} iconRight Adds an icon to the bottom right of the component.
         */
        iconRight,
        ...rest
    } = props;

    let rightIcon;

    switch (iconRight) {
        case SessionsAvatarIconRight.ACCEPTED:
            rightIcon = RSVPAcceptedIcon;
            break;
        case SessionsAvatarIconRight.DECLINED:
            rightIcon = RSVPDeclinedIcon;
            break;
        case SessionsAvatarIconRight.TENTATIVE:
            rightIcon = RSVPTentativeIcon;
            break;
        case SessionsAvatarIconRight.ONLINE:
            rightIcon = OnlineBadge;
            break;
        default:
            rightIcon = null;
    }

    let leftIcon;

    switch (iconLeft) {
        case SessionsAvatarIconLeft.ASSISTANT:
            leftIcon = Assistantbadge;
            break;
        case SessionsAvatarIconLeft.OWNER:
            leftIcon = OwnerBadge;
            break;
        default:
            leftIcon = null;
    }

    const classNames = cls("fosSessionsAvatar");

    const rightIconDisplay = rightIcon && (
        <img src={rightIcon} className={styles.icon} alt="avatar-right-icon" key="avatar-right-icon" role="presentation" />
    );
    const leftIconDisplay = leftIcon && (
        <img
            data-id={iconLeft ? "assistant-badge" : "owner-badge"}
            src={leftIcon}
            className={styles.icon}
            alt="avatar-left-icon"
            key="avatar-left-icon"
        />
    );

    return (
        <Avatar className={classNames} iconRight={rightIconDisplay} iconLeft={leftIconDisplay} {...rest} ref={ref} key="sessions-avatar" />
    );
}

const SessionsAvatar = forwardRef(SessionsAvatarWithRef) as (
    props: SessionsAvatarProps & {ref?: any}
) => ReturnType<typeof SessionsAvatarWithRef>;

export {SessionsAvatarWithRef};

export default SessionsAvatar;
