import React, {useMemo} from "@workhorse/api/rendering";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {categMap, FinalAction, PaletteActionArg, toolType} from "./actionCategTree";
import classes from "./style/PaletteAction.module.scss";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {isPaletteItemAgenda, isPaletteItemFile, isPaletteItemTool} from "@workhorse/api/palette";
// remove when all icons exist
import DefaultIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/PeopleOutlineOutlined";
import {ReactComponent as AgendaIcon} from "./icons/agenda-icon.svg";
import {
    getArtifactTagBasedOnFileType,
    getArtifactTagBasedOnToolType,
    getResourceTypeBasedOnToolType,
    getResourceTypeBasedOnFileType,
} from "./utils";
import {PaletteActiveCateg} from "./CommandPaletteProviders";
import {setHighlightedItem} from "./CommandPalette";
import {getResourceIcon} from "../resx/utils/auxiliary";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {ReactComponent as LinkingIcon} from "./icons/linking.svg";
import {getFileExtension} from "@workhorse/api/file_explorer/fileExplorer";
import {PaletteItemFileType, PaletteItemToolType, PaletteItemMode, PaletteItemBase} from "@generated/data";

export type ResultListProps = {
    highlightId?: string;
} & Pick<PaletteOneResultProps, "includePath" | "hasSearchStr" | "setActionArg" | "setPaletteActiveCateg" | "rootRef" | "scrollIntoView">;

type PaletteOneResultProps = {
    item: PaletteItemBase;
    highlighted?: boolean;
    includePath?: boolean;
    hasSearchStr?: boolean;
    showTypeTitle?: boolean;
    setActionArg: React.Dispatch<React.SetStateAction<PaletteActionArg | undefined>>;
    setPaletteActiveCateg: React.Dispatch<React.SetStateAction<PaletteActiveCateg>>;
    rootRef: HTMLDivElement | undefined;
    scrollIntoView: (node: HTMLElement, location: "start" | "end") => void;
    crumbs?: string;
    endIcon?: React.ReactNode;
    onHighlightText?: string;
    isRecent?: boolean;
};

type ExcludeFromIconMap = "miro" | "google_docs" | "google_sheets" | "google_forms" | "google_presentation";
type IconMap = {
    [K in Exclude<PaletteItemFileType | PaletteItemToolType, ExcludeFromIconMap>]?: ResourcesTypes;
};

const resxIcon: IconMap = {
    image: "flowos/gallery/resx/Gallery",
    pdf: "flowos/pdf/resx/Pdf",
    presentation: "flowos/ppt/resx/Ppt",
    doc: "flowos/doc/resx/Doc",
    excel: "flowos/excel/resx/Excel",
    video: "flowos/video/resx/Video",
    youtube: "flowos/youtube/resx/Youtube",
    vimeo: "flowos/vimeo/resx/Vimeo",
    form: "flowos/form/resx/Form",
    figma: "flowos/figma/resx/Figma",
    pitch: "flowos/pitch/resx/Pitch",
    adobexd: "flowos/adobexd/resx/AdobeXD",
    airtable: "flowos/airtable/resx/Airtable",
    canva: "flowos/canva/resx/Canva",
    mentimeter: "flowos/mentimeter/resx/Mentimeter",
    slido: "flowos/slido/resx/Slido",
    smartboard: "flowos/blackboard/resx/Blackboard",
    twitch: "flowos/twitch/resx/Twitch",
    keynote: "flowos/keynote/resx/Keynote",
    pages: "flowos/pages/resx/Pages",
    numbers: "flowos/numbers/resx/Numbers",
    boardmix: "flowos/boardmix/resx/Boardmix",
};

const icoByTool = (["google_docs", "google_forms", "google_presentation", "google_sheets", "miro"] as Array<ExcludeFromIconMap>).reduce(
    (all, k) => {
        const artifactTag = (Object.keys(toolType) as Array<keyof typeof toolType>).find((kk) => toolType[kk] === k);
        if (artifactTag) {
            all[k] = categMap.find((obj) => obj.artifactTag === artifactTag)?.icon as string;
        }
        return all;
    },
    {} as {
        [K in ExcludeFromIconMap]?: string;
    }
);

const getActionDataId = (itemMode: PaletteItemMode, isRecent: boolean) => {
    switch (itemMode) {
        case PaletteItemMode.File: {
            if (isRecent) {
                return "palette-entry-recentshare";
            }
            return "palette-files-entry-file";
        }
        case PaletteItemMode.Tool: {
            if (isRecent) {
                return "palette-entry-recentshare";
            }
            return "palette-tools-entry-tool";
        }
        case PaletteItemMode.Agenda: {
            if (isRecent) {
                return "palette-entry-recentshare";
            }
            return "palette-agendas-entry-agenda";
        }
        default: {
            break;
        }
    }
};

export default function PaletteRenderOneResult(props: PaletteOneResultProps) {
    const {item, setActionArg, rootRef, scrollIntoView, crumbs, endIcon, onHighlightText, isRecent} = props;
    const {mode, name} = item;
    const {isMobile} = useMobile();

    const Icon = useMemo(() => {
        if (isPaletteItemFile(item)) {
            const {file_type, name} = item;
            const extension = getFileExtension(name);
            return resxIcon[file_type] ? getResourceIcon(resxIcon[file_type]!, {name, type: extension} as any) : <DefaultIcon />;
        }
        if (isPaletteItemTool(item)) {
            const {tool_type} = item;
            return resxIcon[tool_type] ? (
                getResourceIcon(resxIcon[tool_type], undefined, true)
            ) : icoByTool[tool_type] ? (
                <LazyLoadImage width={26} height={26} src={icoByTool[tool_type]} />
            ) : (
                <DefaultIcon />
            );
        }
        if (isPaletteItemAgenda(item)) {
            return <AgendaIcon />;
        }
    }, []) as JSX.Element;

    const setAsActive = (e: React.MouseEvent<HTMLElement>) => {
        if (e.detail > 1) {
            return;
        }
        if (isPaletteItemAgenda(item)) {
            setActionArg({
                action: FinalAction.Use,
                isTool: mode === "tool",
                agendaId: item.agendaId,
                // artifactTag: getArtifactTagBasedOnFileType(item.type) || getArtifactTagBasedOnToolType(item.toolType),
                item,
                // resourceType: getResourceTypeBasedOnFileType(item.type) || getResourceTypeBasedOnToolType(item.toolType),
            });
        }
        if (isPaletteItemTool(item)) {
            setActionArg({
                action: FinalAction.Use,
                isTool: mode === "tool",
                artifactTag: getArtifactTagBasedOnToolType(item.tool_type),
                item,
                resourceType: getResourceTypeBasedOnToolType(item.tool_type),
            });
        }
        if (isPaletteItemFile(item)) {
            setActionArg({
                action: FinalAction.Use,
                isTool: mode === "tool",
                artifactTag: getArtifactTagBasedOnFileType(item.file_type),
                item,
                resourceType: getResourceTypeBasedOnFileType(item.file_type),
            });
        }
    };

    const setAsHighlighted: React.MouseEventHandler<HTMLDivElement> = (e) => {
        const direction = e.currentTarget.getAttribute("data-direction") as "next" | "prev" | undefined;
        if (rootRef) {
            setHighlightedItem(rootRef, item.id);
            if (direction) {
                const location = direction === "next" ? "end" : "start";
                e.currentTarget.removeAttribute("data-direction");
                scrollIntoView(e.currentTarget.parentElement!, location);
            }
        }
    };

    const checkIfHighlighted: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (!e.currentTarget.classList.contains("highlighted")) {
            setAsHighlighted(e);
        }
    };

    return (
        <div data-id="palette-recent-action" className={cls("flex", classes.paletteAction, classes.onHoverShowTxt)}>
            <div
                className={cls("flex flex-align-center flex00-100", classes.paletteActionInner)}
                onClick={setAsActive}
                onMouseEnter={setAsHighlighted}
                onMouseMove={checkIfHighlighted}
                data-palette-id={item.id}
                data-id={item.mode ? getActionDataId(item.mode, isRecent as boolean) : ""}
            >
                <Typography component="span" variant={isMobile ? "sm" : "base"} className={cls("flex flex-align-center white-text fullw")}>
                    {Icon}
                    <span data-id="action-title" className={classes.ellipsisTxt} data-private>
                        {crumbs}
                        {name}
                    </span>
                    <span className={"flex flex11-auto flex-align-center ml-10"}>
                        {item.isPublic && <PeopleIcon className={cls(classes.sharedItem)} />}
                        {onHighlightText && !isMobile && <span className={cls(classes.grey300, "hidden-lbl")}>{onHighlightText}</span>}
                        <span className={cls(classes.grey300, "pt-4 hidden-icon")}>
                            {endIcon ?? <LinkingIcon className={classes.resultIcon} />}
                        </span>
                    </span>
                </Typography>
            </div>
        </div>
    );
}
