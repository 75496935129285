import {BackgroundFilter} from "@workhorse/api/conference2/lib/VideoReplacement";
import {DevicesProvider, useDevices} from "@workhorse/api/conference2/providers/MediaProvider/DevicesProvider";
import {getCameras, getMicrophones, getSpeakers} from "@workhorse/api/conference2/utils";
import {useEffect, useState} from "@workhorse/api/rendering";
import {AudioInputPreview} from "./audio-preview";
import {browserBehavior} from "@workhorse/api/conference2";
import env from "@generated/environment";
import {Redirect} from "react-router-dom";

export default function DeviceTest() {
    if (env.environmentName === "production") {
        return <Redirect to="/" />;
    }

    return (
        <DevicesProvider>
            <Devices />
        </DevicesProvider>
    );
}

function Devices() {
    const [node, setNode] = useState<HTMLVideoElement | null>(null);
    const [audioNode, setAudioNode] = useState<HTMLAudioElement | null>(null);
    const [audioNode2, setAudioNode2] = useState<HTMLAudioElement | null>(null);

    const [audioSinkId, setAudioSinkId] = useState<string | null>(null);

    const {
        devices,
        audioEnabled,
        videoEnabled,
        audioStream,
        // voiceFocusDevice,
        videoStream,
        videoTransformedStream,
        noiseReduction,
        videoFilter,
        videoFilterModel,
        start,
        stop,
        setAudioEnabled,
        setVideoEnabled,
        setAudioConstraints,
        setVideoConstraints,
        setNoiseReduction,
        setVideoFilter,
        setVideoFilterModel,
    } = useDevices();

    useEffect(() => {
        start();
        return () => stop();
    }, [start, stop]);

    const microphones = getMicrophones(devices);
    const cameras = getCameras(devices);
    const speakers = getSpeakers(devices);

    useEffect(() => {
        if (!node) {
            return;
        }

        if (!videoStream) {
            node.srcObject = null;
            return;
        }

        if (videoTransformedStream) {
            node.srcObject = videoTransformedStream;
            return;
        }

        node.srcObject = videoStream;
        return;
    }, [node, videoStream, videoTransformedStream]);

    useEffect(() => {
        if (!audioNode) {
            return;
        }

        if (!audioStream) {
            audioNode.srcObject = null;
            return;
        }

        console.log(audioNode, audioStream);

        audioNode.srcObject = audioStream;
    }, [audioNode, audioStream]);

    useEffect(() => {
        if (!audioSinkId) {
            return;
        }

        console.log("set sink id");
        audioNode?.setSinkId(audioSinkId);
        audioNode2?.setSinkId(audioSinkId);
    }, [audioNode, audioNode2, audioSinkId]);

    return (
        <div className="flex flex-col p-8">
            <div className="mt-10">
                <button className="mr-8" onClick={() => setAudioEnabled((c) => !c)}>
                    Audio {audioEnabled ? "on" : "off"}
                </button>
                <button className="mr-8" onClick={() => setVideoEnabled((c) => !c)}>
                    Camera {videoEnabled ? "on" : "off"}
                </button>

                <button className="mr-8" onClick={() => setNoiseReduction((c) => !c)}>
                    Noise reduction: {noiseReduction ? "on" : "off"}
                </button>

                <button
                    className="mr-8"
                    onClick={() => {
                        setVideoConstraints((current) => {
                            return {
                                facingMode: current?.facingMode === "environment" ? "user" : "environment",
                            };
                        });
                    }}
                >
                    Toggle orientation
                </button>
            </div>

            {/* <span className="mt-12 mb-2">Mic: {voiceFocusDevice != null ? "(Noise reduction active)" : ""}</span> */}
            <select
                value={audioStream?.getTracks()[0]?.getSettings().deviceId}
                onChange={(e) => {
                    const deviceId = e.currentTarget.value;
                    setAudioConstraints({
                        deviceId,
                    });
                }}
            >
                {microphones.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                    </option>
                ))}
            </select>

            <AudioInputPreview stream={audioStream ?? undefined} />

            <span className="mt-12 mb-8">Speakers:</span>

            {browserBehavior.supportsSetSinkId() && (
                <select
                    onChange={(e) => {
                        setAudioSinkId(e.currentTarget.value);
                    }}
                >
                    {speakers.map((device) => (
                        <option key={device.deviceId} value={device.deviceId}>
                            {device.label}
                        </option>
                    ))}
                </select>
            )}

            <audio className="mt-8" ref={setAudioNode} autoPlay={true} loop={true} controls={true} />

            <audio className="mt-8" ref={setAudioNode2} src="/audio/chime-synth.mp3" controls={true} />

            <span className="mt-12 mb-2">Camera:</span>
            <select
                value={videoStream?.getTracks()[0]?.getSettings().deviceId}
                onChange={(e) => {
                    const deviceId = e.currentTarget.value;
                    setVideoConstraints({
                        deviceId,
                    });
                }}
            >
                {cameras.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                    </option>
                ))}
            </select>

            <span className="mt-12 mb-2">Camera filter:</span>

            <button
                className="mb-8"
                onClick={() => {
                    setVideoFilterModel((c) => (c === "landscape" ? "general" : "landscape"));
                }}
            >
                Model: {videoFilterModel}
            </button>

            <select
                value={videoFilter}
                onChange={(e) => {
                    setVideoFilter(e.currentTarget.value as BackgroundFilter);
                }}
            >
                {Object.entries(BackgroundFilter)
                    .filter(([k, v]) => k === BackgroundFilter.CUSTOM)
                    .map((item) => (
                        <option key={item[1]} value={item[1]}>
                            {item[0]}
                        </option>
                    ))}
            </select>

            <video
                style={{
                    display: "block",
                    width: "400px",
                }}
                className="mt-20"
                ref={setNode}
                muted={true}
                autoPlay={true}
                controls={false}
                playsInline={true}
                disableRemotePlayback={true}
                disablePictureInPicture={true}
            />
        </div>
    );
}
