/* eslint-disable react/react-in-jsx-scope */
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import Typography from "@ui/cdk/Typography";
import FormControl from "@ui/core/components/FormControl";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/UserView.module.scss";
import {useTranslation} from "react-i18next";

interface UserViewProps {
    isAdmin: boolean;
    isAssistant: boolean;
    assistantCanEndSessionForAll: boolean;
    ownerSessionEnd: string;
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    loading?: boolean;
}

const UserView = (props: UserViewProps) => {
    const {t} = useTranslation();
    const {isAdmin, ownerSessionEnd, handleRadioChange, isAssistant, assistantCanEndSessionForAll, loading} = props;

    return isAdmin || (isAssistant && assistantCanEndSessionForAll) ? (
        <div className={classes.infoContainer}>
            <div className={cls(classes.subtitle, classes.withMarginLeft)}>
                <FormControl>
                    <RadioGroup defaultValue={ownerSessionEnd} aria-label="session" name="customized-radios" onChange={handleRadioChange}>
                        <FormControlLabel
                            data-id="end-for-everyone"
                            value="everyone"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    {t("participant.leave_session.end_session_for_all")}
                                </Typography>
                            }
                            aria-label={t("participant.leave_session.end_session_for_all") ?? ""}
                            disabled={loading}
                        />
                        <Typography className={classes.radioText}>
                            {t("participant.leave_session.end_session_for_all_description")}
                        </Typography>
                        <FormControlLabel
                            data-id="end-for-me"
                            value="me"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    {t("participant.leave_session.leave_session")}
                                </Typography>
                            }
                            aria-label={t("participant.leave_session.leave_session") ?? ""}
                            classes={{
                                root: classes.radioLabelRoot,
                            }}
                            disabled={loading}
                        />
                        <Typography className={classes.radioText}>{t("participant.leave_session.leave_session_description")}</Typography>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    ) : (
        <div className={classes.infoContainer2}>
            <Typography className={classes.subtitle}>{t("participant.leave_session.info_memory")}</Typography>
        </div>
    );
};

export const UserViewBackstage = (props: UserViewProps) => {
    const {isAdmin, ownerSessionEnd, handleRadioChange, isAssistant, assistantCanEndSessionForAll, loading} = props;
    const {t} = useTranslation();

    return isAdmin || (isAssistant && assistantCanEndSessionForAll) ? (
        <div className={classes.infoContainer}>
            <div className={cls(classes.subtitle, classes.withMarginLeft)}>
                <FormControl>
                    <RadioGroup defaultValue={ownerSessionEnd} aria-label="session" name="customized-radios" onChange={handleRadioChange}>
                        <FormControlLabel
                            data-id="end-for-everyone"
                            value="everyone"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    {t("participant.leave_session.end_backstage_for_all") ?? "End backstage for all"}
                                </Typography>
                            }
                            aria-label="End session for all"
                            disabled={loading}
                        />
                        <Typography className={classes.radioText}>
                            {t("participant.leave_session.end_backstage_for_all_description") ??
                                "Ending this session for everyone will cut off access to all hosts and speakers to the session backstage. Make sure they finished working before closing."}
                        </Typography>
                        <FormControlLabel
                            data-id="end-for-me"
                            value="me"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    {t("participant.leave_session.leave_backstage") ?? "Leave backstage"}
                                </Typography>
                            }
                            aria-label="Leave session"
                            classes={{
                                root: classes.radioLabelRoot,
                            }}
                            disabled={loading}
                        />
                        <Typography className={classes.radioText}>
                            {t("participant.leave_session.leave_backstage_description") ??
                                "Leaving this session backstage will allow other hosts and speakers to keep working."}
                        </Typography>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    ) : (
        <div className={classes.infoContainer2}>
            <Typography className={classes.subtitle}>
                After the session has ended, you’ll find its information and resources in Memory.
            </Typography>
        </div>
    );
};

export default UserView;
