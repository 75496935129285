import {useTranslation} from "react-i18next";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import ConferenceVideoIcon from "./ConferenceVideoIcon";

export default function RemoteVideoIcon({
    participantId,
    isThumbnailMode,
    cameraPaused,
}: {
    participantId: string;
    isThumbnailMode?: boolean;
    cameraPaused?: boolean;
}) {
    const {t} = useTranslation();
    const {cameraOn} = useRemoteParticipantStatus(participantId);
    const pausedTxt = t("participant.camera.video_was_manually_paused");

    return <ConferenceVideoIcon isOff={!cameraOn} isPaused={cameraPaused} pausedTxt={pausedTxt} isThumbnailMode={isThumbnailMode} />;
}
