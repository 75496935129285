import {createTheme, StylesProvider, ThemeOptions, ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import {makeVar, useReactiveVar} from "@workhorse/api/data";
import React, {useEffect} from "@workhorse/api/rendering";
import MuiDefaultTheme from "./MainTheme";

const MuiTheme: unknown = Object.assign({}, MuiDefaultTheme, {});

const themeProvider = makeVar(responsiveFontSizes(createTheme(MuiTheme as ThemeOptions)));

export const updateTheme = (themePartial: Partial<ThemeOptions>) => {
    themeProvider(responsiveFontSizes(createTheme(Object.assign({}, MuiTheme, themePartial))));
};

const MaterialUIThemeProvider = (props: any) => {
    const theme = useReactiveVar(themeProvider);

    // useEffect(() => {
    //     document.documentElement.setAttribute("data-theme", "dark");
    // }, []);

    // useEffect(() => {
    //     console.log("here", loading, branding);
    //     if (!loading && branding) {
    //         applyBranding();
    //     }
    // }, [loading, branding, applyBranding]);

    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
        </StylesProvider>
    );
};

export default MaterialUIThemeProvider;
