import {useEffect, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";
import {useJoinedSpeaker} from "../../providers";

export function useActiveSpeakersEnabledStore() {
    const joinedSpeakers = useJoinedSpeaker();
    const shouldEnable = joinedSpeakers.length === 0;
    const currentAgendaItem = {} as ReturnType<typeof useCurrentAgendaItem>; // useCurrentAgendaItem();

    const {id: microId} = currentAgendaItem?.artifact! || {};

    const [enabled, setEnabled] = useState(shouldEnable);
    const [previousMicroId, setPreviousMicroId] = useState(microId);

    useEffect(() => {
        if (!shouldEnable) {
            setEnabled(false);
            return;
        }

        if (previousMicroId !== microId) {
            setEnabled(true);
            setPreviousMicroId(microId);
            return;
        }

        const id = setTimeout(() => {
            setEnabled(true);
        }, 4000);

        return () => clearTimeout(id);
    }, [shouldEnable, microId, previousMicroId]);

    return enabled;
}

export const [ActiveSpeakersEnabledProvider, useActiveSpeakersEnabled] = createContextProvider(
    {
        name: "ActiveSpeakersEnabled",
        strict: false,
    },
    useActiveSpeakersEnabledStore
);
