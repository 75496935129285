import {makeId} from "@workhorse/api/designer/lib/utils";
import {useIsMounted} from "@workhorse/api/isMounted";
import {useState, useEffect, useCallback} from "@workhorse/api/rendering";

type Task = () => Promise<void>;

interface PendingTask {
    id: string;
    callback: Task;
}

function resolve(task: PendingTask) {
    return task.callback().catch((e) => console.log(e));
}

function removeCurrentTask(current: PendingTask | undefined, incoming: PendingTask) {
    if (current?.id === incoming.id) {
        return undefined;
    }
    return current;
}

export function useOrderedAsyncQueue() {
    const isMounted = useIsMounted();

    const [running, setRunning] = useState(false);

    const [{pending, queue}, setStatus] = useState<{
        pending: PendingTask | undefined;
        queue: PendingTask[];
    }>({
        pending: undefined,
        queue: [],
    });

    const add = useCallback((task: Task) => {
        const pendingItem = {
            id: makeId(),
            callback: task,
        };
        setStatus((status) => ({
            pending: status.pending,
            queue: [...status.queue, pendingItem],
        }));
    }, []);

    useEffect(() => {
        setStatus((status) => {
            if (status.pending) {
                return status;
            }

            if (status.queue.length === 0) {
                return status;
            }

            const [next, ...rest] = status.queue;

            return {
                pending: next,
                queue: rest,
            };
        });
    }, [pending, queue]);

    useEffect(() => {
        if (running) {
            return;
        }

        if (!pending) {
            return;
        }

        const onFinish = () => {
            if (!isMounted()) {
                return;
            }
            setRunning(false);
        };

        setRunning(true);
        setStatus((current) => ({
            pending: removeCurrentTask(current.pending, pending),
            queue: current.queue,
        }));
        resolve(pending).then(onFinish);
    }, [running, pending, isMounted]);

    return add;
}
