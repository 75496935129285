import {HostType} from "@generated/data";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useHistory} from "@workhorse/api/routing";
import {enableRoomLeaveModal, routeNavigation} from "@workhorse/components/header/headerUtils";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {ReactComponent as SlashDividerIcon} from "../../assets/media/slash_divider_icon.svg";
import classes from "./style/room-header.module.scss";

type RoomHeaderBreadcrumbs = {
    roomName?: string;
};

const RoomHeaderBreadcrumbs = (props: RoomHeaderBreadcrumbs) => {
    const history = useHistory();
    const {roomName} = props;

    const hostType = useHostType();
    const isRooms = hostType === HostType.Rooms;
    const isRoomInstances = isRooms && history.location.pathname.toLowerCase().endsWith("instances");

    const goBack = () => {
        history.push({
            pathname: "/rooms",
        });
    };

    const preventNavigation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isRooms && !isRoomInstances) {
            e.preventDefault();
            routeNavigation("/rooms");
            enableRoomLeaveModal(true);
        } else {
            goBack();
        }
    };

    return (
        <div className={cls("flex flex-align-center flex11-auto", classes.breadcrumbs)}>
            <div onClick={preventNavigation} className="flex flex-items-center cursor-pointer mr-10">
                {roomName ? <ArrowBackRoundedIcon /> : null}
                <Typography color="primary" fontWeight="bolder" variant="lg" noWrap>
                    Rooms
                </Typography>
            </div>

            {roomName ? (
                <>
                    <SlashDividerIcon />
                    <Typography color="blueGray400" fontWeight="bolder" noWrap variant="lg">
                        {roomName}
                    </Typography>
                </>
            ) : null}
        </div>
    );
};

export default RoomHeaderBreadcrumbs;
