import loadable from "@workhorse/api/loadable";
import {ReactComponent as ExitPageIcon} from "../assets/exit-page-icon.svg";
import {SessionSettingsSection} from "../../definitions";

const LeaveSessionSection = loadable(() => import("./LeaveSessionSection"));
LeaveSessionSection.displayName = "Loadable:SessionSection";

const LeaveSessionSectionDefinition: SessionSettingsSection<"general.exitPage"> = {
    translateKey: "general.exit_page",
    kind: "general.exitPage",
    title: "Exit page",
    component: LeaveSessionSection,
    icon: ExitPageIcon,
    rbac: {
        "session.isAssistant": false,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default LeaveSessionSectionDefinition;
