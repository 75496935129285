import {participantIsAdmin, participantIsAssistant} from "@workhorse/api/conference2";
import {memo, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import AudienceParticipant from "./AudienceParticipant";
import {ConferenceAudienceScrollWrapper} from "./ConferenceAudienceScrollWrapper";
import classes from "./style/ConferenceAudience.module.scss";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import {useVisibleIds} from "@workhorse/providers/VisibleParticipantsProvider";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useConferenceAudience} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsHooks";

type ConferenceAudienceProps = {
    isAdmin: boolean;
    isAssistant: boolean;
    sessionId: string;
    myParticipantId: string;
};

type MemoParticipantProps = {
    id: string;
    containerNode: HTMLDivElement | null;
    currentParticipantId: string;
    renderingForAdmin: boolean;
    renderingForAssistant: boolean;
    isAvatarMode: boolean;
};

const MemoParticipant = memo(function Participant(props: MemoParticipantProps) {
    const participant = useParticipantsStore((state) => state.participantsMap[props.id]);
    const isAnAdmin = participantIsAdmin(participant);
    const isAnAssistant = participantIsAssistant(participant);
    const isLocalParticipant = participant.id === props.currentParticipantId;

    return (
        <div className={cls(classes.participantWrapper)}>
            <AudienceParticipant
                isLocalParticipant={isLocalParticipant}
                participantIsAdmin={isAnAdmin}
                participantIsAssistant={isAnAssistant}
                renderingForAdmin={props.renderingForAdmin}
                renderingForAssistant={props.renderingForAssistant}
                participant={participant}
                containerNode={props.containerNode}
                isAvatarMode={props.isAvatarMode}
                isThumbnailMode={true}
                pinnedModeEnabled={false}
                isPinned={false}
            />
        </div>
    );
});

function ConferenceAudience(props: ConferenceAudienceProps) {
    const {myParticipantId, isAdmin, isAssistant: renderingForAssistant} = props;
    const {visibleIds, meIsHidden} = useVisibleIds();
    const {avatar: isAvatarMode, setHiddenAudienceBar} = useSessionView();

    const audience = useConferenceAudience();

    const filteredAudience = useMemo(() => {
        if (visibleIds == null && meIsHidden !== true) {
            return audience;
        }

        return audience.filter((p) => {
            if (p.id === myParticipantId) {
                return !meIsHidden;
            }

            if (visibleIds && !visibleIds.includes(p.id)) {
                return false;
            }

            return true;
        });
    }, [audience, visibleIds, meIsHidden, myParticipantId]);

    const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        return () => {
            setHiddenAudienceBar(false);
        };
    }, []);

    setHiddenAudienceBar(filteredAudience.length === 0);

    if (filteredAudience.length === 0) {
        return null;
    }

    return (
        <ConferenceAudienceScrollWrapper onMount={setContainerNode}>
            {filteredAudience.map((p) => (
                <MemoParticipant
                    key={p.id}
                    id={p.id}
                    containerNode={containerNode}
                    currentParticipantId={myParticipantId}
                    renderingForAdmin={isAdmin}
                    renderingForAssistant={renderingForAssistant}
                    isAvatarMode={isAvatarMode}
                />
            ))}
        </ConferenceAudienceScrollWrapper>
    );
}

export default ConferenceAudience;
