import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {rbac} from "@workhorse/api/user";
import {Session} from "@workhorse/declarations/dataTypes";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import classes from "./styles/PreJoinInfoSession.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {useTranslation} from "react-i18next";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type PreJoinInfoSessionProps = {
    session: Session;
    isMobile?: boolean;
    isOwnerOnboarding: boolean;
};

const PreJoinInfoSession = (props: PreJoinInfoSessionProps) => {
    const {session, isMobile, isOwnerOnboarding} = props;

    const {t} = useTranslation();
    const isAssistant = rbac(session.currentParticipant.pid, "session.isAssistant");

    const {ownerFirstName, ownerLastName, isAdmin, ownerId, ownerAvatar, ownerEmail, ownerStatus} = useParticipantsStore(
        useShallow((state) => ({
            ownerFirstName: state.ownerInfo?.dataWithNullableEmail.firstName ?? "",
            ownerLastName: state.ownerInfo?.dataWithNullableEmail.lastName ?? "",
            isAdmin: state.currentParticipant?.isOwner ?? false,
            ownerId: state.ownerInfo?.id,
            ownerStatus: state.ownerInfo?.status,
            ownerAvatar: state.ownerInfo?.dataWithNullableEmail.avatar,
            ownerEmail: state.ownerInfo?.dataWithNullableEmail.email,
        }))
    );

    const showSessionTitleTooltip = session?.name?.length > 40;
    const sessionTitleTooltip = session?.name;

    const prejoinHeaderText = isOwnerOnboarding ? "Test Session" : "Ready to join?";

    return (
        <>
            <div className={cls("flex flex-col overflow-hidden", classes.root)} key="pre-join-info1">
                <Tooltip title={showSessionTitleTooltip ? sessionTitleTooltip : ""} arrow placement={isMobile ? "bottom" : "right"}>
                    <Typography
                        variant="xl3"
                        fontWeight="boldest"
                        className={cls(classes.title)}
                        aria-label={`${t("prejoin.session_name")}: ${session.name}`}
                    >
                        {prejoinHeaderText}
                    </Typography>
                </Tooltip>

                {ownerId && (
                    <div className="flex flex-align-center mt-12 pb-12">
                        <ParticipantDisplayDetails
                            key={`owner-${ownerId}`}
                            className={classes.participantAvatar}
                            id={ownerId}
                            avatarVariant="rounded"
                            firstName={ownerFirstName}
                            lastName={ownerLastName}
                            avatar={ownerAvatar}
                            avatarSize={"L"}
                            isOwner={true}
                            isUser={true}
                            participantIsAssistant={false}
                            renderingForAssistant={isAssistant}
                            renderingForAdmin={isAdmin}
                            showProfilePicture
                            index={0}
                            showRSVP
                            showInfo={true}
                            participantStatus={ownerStatus}
                            email={ownerEmail}
                            emailTooltip={(isAdmin || isAssistant) && !!ownerEmail?.trim()}
                            showName={false}
                        />
                        <div className="flex flex-col ml-8">
                            <Typography fontWeight="bolder">{ownerFirstName + " " + ownerLastName}</Typography>
                            <Typography variant="sm" fontWeight="bold" color="tertiary">
                                {t("participant.g.host")}
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PreJoinInfoSession;
