import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import Popover from "@ui/core/components/Popover";
import classes from "./style/DeviceUnreadableModal.module.scss";

type BlockedPermissionsPopoverProps = {
    anchorEl?: HTMLElement | null;
    micUnreadable?: boolean;
    cameraUnreadable?: boolean;
    onClose: () => void;
};

export function DeviceUnreadablePopover(props: BlockedPermissionsPopoverProps) {
    let title = "There seems to be a problem with your device";
    let body = "If not, switch to another device.";

    if (props.micUnreadable && props.cameraUnreadable !== true) {
        title = "There seems to be a problem with your microphone";
        body = "If not, switch to another microphone.";
    }

    if (props.cameraUnreadable && props.micUnreadable !== true) {
        title = "There seems to be a problem with your camera";
        body = "If not, switch to another camera.";
    }

    return (
        <Popover
            anchorEl={props.anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            transformOrigin={{vertical: "bottom", horizontal: "center"}}
            open={!!props.anchorEl}
            className="flex flex-col flex-center-all"
            classes={{
                paper: cls(classes.paper, classes.popoverPaper),
            }}
            onBackdropClick={props.onClose}
        >
            <Typography className="mb-12" fontWeight="boldest" variant="lg">
                {title}
            </Typography>
            <Typography>Make sure it&apos;s not being used by another application. {body}</Typography>
        </Popover>
    );
}
