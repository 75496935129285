import {ContactInfoFragment, SessionLifecycle, SpeakerDetailsFragment, SpeakerInfoFragment} from "@generated/data";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import Button from "@ui/cdk/Button";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useCallback, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {useMutation, writeQuery} from "@workhorse/dataApi";
import {
    getPendingContactsAndEmailsByCache,
    getPendingSpeakersByCache,
    Tag,
    TagType,
} from "@workhorse/pages/designer/CreateSession/ContactFetcher";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useAgendaItems, useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import common from "../eventCommons.module.scss";
import EventSpeakersTable from "./EventSpeakersTable";
import NewSpeakerDialog from "./NewSpeakerDialog";
import classes from "./style/EventSpeakers.module.scss";
import {emptySpeakerDetailsObj, hasAllSpeakerDetails, makeParticipantSpeakerData, SpeakerDisplayType} from "./utils";
import environment from "@generated/environment";
import MySpeakersDialog from "./MySpeakersDialog";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type EventSpeakersProps = {};

export function EventSpeakers(props: EventSpeakersProps) {
    const {} = props;
    const session = useSession();
    const speakerOrder = session.event?.speakerOrderJson ?? {speakers: []};

    const participants = useParticipants();
    const agendaItems = useAgendaItems();
    const {mixpanelTrack} = useMixpanelTracker();
    useEffect(() => {
        if (!speakerOrder.speakers || speakerOrder.speakers.length === 0) {
            const speakers = participants.filter((p) => !!p.speakerDetails).map((p) => p.speakerDetails!["id"]);

            if (speakers.length > 0) {
                designer.api.event.update({
                    speakerOrderJson: {speakers: speakers},
                });
            }
        }
    }, []);

    const baseLink = `${environment.eventBaseURL}/${session.event?.slug}`;

    const [newSpeakerOpen, setNewSpeakerOpen] = useState<boolean>(false);
    const [mySpeakersOpen, setMySpeakersOpen] = useState<boolean>(false);
    const [newSpeakerData, setNewSpeakerData] = useState<{firstName: string; lastName: string; email: string} | null>(null);

    const [selectedSpeaker, setSelectedSpeaker] = useState<string | null>(null);

    const [updateParticipants] = useMutation("UpdateParticipantsInSessionStandaloneDocument", {});
    const [lockEvent] = useMutation("LockEventDocument");

    const existingParticipantsEmails = participants.map((x) => x.dataWithNullableEmail?.email ?? "").filter((p) => p) as string[];

    const eventOwner = useMemo(() => {
        return participants.find((p) => p.isOwner);
    }, [participants]);

    const assistants = useMemo(() => {
        return participants.filter((p) => p?.rbac?.session?.isAssistant);
    }, [participants]);

    const teamMembers = useMemo(() => {
        return eventOwner ? [eventOwner, ...assistants] : assistants;
    }, [assistants, eventOwner]);
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () =>
            (session.lifecycle === SessionLifecycle.Started && !session.backstage) ||
            isLocked ||
            session.lifecycle === SessionLifecycle.Ended,
        [session.lifecycle, isLocked, session.backstage]
    );

    const teamMemberEmails: string[] = useMemo(() => {
        const emails: string[] = [];
        if (eventOwner?.dataWithNullableEmail?.email) {
            emails.push(eventOwner.dataWithNullableEmail.email);
        }

        for (const assistant of assistants) {
            if (assistant.dataWithNullableEmail?.email) {
                emails.push(assistant.dataWithNullableEmail?.email);
            }
        }
        return emails;
    }, [assistants, eventOwner]);

    const removeSpeakers = (ids: string[]) => {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        const teamMembersToRemoveSpeaker = teamMembers.filter((x) => ids.includes(x.id));

        const upsertedParticipants = teamMembersToRemoveSpeaker.map((a) => ({
            ...a,
            speakerDetails: null,
        }));

        if (teamMembersToRemoveSpeaker.length > 0) {
            updateParticipants({
                variables: {
                    sessionId: session.id,
                    upsertParticipantJSONs: upsertedParticipants.map((p) => ({...p, isApproved: true, submittedPasscode: true})),
                },
            })
                .then((res) => {
                    // refetch participants instead of evicting from cache
                    if (res.data?.updateParticipantsInSession) {
                        writeQuery("LocalParticipantsDocument", {
                            variables: {
                                id: session.id,
                            },
                            data: {
                                __typename: "Query",
                                participants: res.data.updateParticipantsInSession.participants,
                            },
                        });
                    }
                })
                .finally(() => {
                    designer.state.setDesignerCommitState(false);
                });
        }

        const deleted = ids.filter((id) => !teamMembersToRemoveSpeaker.map((a) => a.id).includes(id));

        if (deleted.length > 0) {
            updateParticipants({
                variables: {
                    sessionId: session.id,
                    deletedParticipantIds: deleted,
                },
            }).then(() => {
                for (const id of deleted) {
                    apollo.cache.evict({id: id});
                }
            });
        }
    };

    const handleRemoveSpeakers = async (speakerIds: string[]) => {
        designer.api.event.update({
            speakerOrderJson: {speakers: speakerOrder.speakers.filter((id) => !speakerIds.includes(id)) || []},
        });
        await designer.commit();

        const participantIds = participants
            .filter((x) => x?.speakerDetails?.id && speakerIds.includes(x.speakerDetails.id))
            .map((x) => x.id);
        removeSpeakers(participantIds);
    };

    function addSpeaker(contacts: ContactInfoFragment[], emails: string[], speakers: SpeakerDetailsFragment[]) {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        const existingParticipantsData: any[] = [];
        const newParticipantsData: any[] = [];

        const emailsForNewParticipants = emails.filter((x) => !existingParticipantsEmails.includes(x));
        const emailsMatchingTeamMembers = emails.filter((x) => teamMemberEmails.includes(x));

        // handle contacts
        if (contacts.length > 0) {
            for (const contact of contacts) {
                if (!contact.email) {
                    return;
                }

                const isEmailAlreadyAdded =
                    existingParticipantsEmails.includes(contact.email) || emailsMatchingTeamMembers.includes(contact.email);

                if (!isEmailAlreadyAdded) {
                    const spkDetails = {
                        ...emptySpeakerDetailsObj(),
                        name: (contact.firstName + " " + contact.lastName).trim(),
                        photoUrl: contact.userProfilePicture ?? "",
                    };

                    newParticipantsData.push({
                        ...makeParticipantSpeakerData(
                            contact.email,
                            {
                                ...spkDetails,
                                participantId: undefined,
                                email: contact.email,
                            },
                            null
                        ),
                        id: makeId(),
                    });
                } else {
                    const participant = participants.find((x) => x.dataWithNullableEmail?.email === contact.email);
                    if (participant) {
                        // continue only if participant is not already a speaker
                        // participant may already be a speaker if somebody tries to add a group of contacts
                        // when some of those contacts were already added as speakers previously
                        if (participant.speakerDetails === null) {
                            const spkDetails = {
                                ...emptySpeakerDetailsObj(),
                                name: (
                                    participant.dataWithNullableEmail.firstName +
                                    " " +
                                    participant.dataWithNullableEmail.lastName
                                ).trim(),
                                photoUrl: participant.dataWithNullableEmail.avatar ?? "",
                            };

                            const participantWithSpeakerDetails = {
                                ...participant,
                                speakerDetails: {
                                    ...spkDetails,
                                    id: makeId(),
                                },
                            };

                            existingParticipantsData.push(participantWithSpeakerDetails);
                        }
                    }
                }
            }
        }

        // handle emails
        if (emails.length > 0) {
            if (emailsForNewParticipants.length) {
                setNewSpeakerData({
                    firstName: "",
                    lastName: "",
                    email: emails[0],
                });

                openNewSpeaker();
                return false;
            }

            if (emailsMatchingTeamMembers.length) {
                const participant = participants.find((x) => x.dataWithNullableEmail?.email === emailsMatchingTeamMembers[0]);
                if (participant) {
                    const participantWithSpeakerDetails = {
                        ...participant,

                        speakerDetails: emptySpeakerDetailsObj(),
                    };

                    existingParticipantsData.push(participantWithSpeakerDetails);
                }
            }
        }

        // handle speakers
        if (speakers.length > 0) {
            for (const speaker of speakers) {
                if (!speaker.email) {
                    return;
                }

                const isEmailAlreadyAdded =
                    existingParticipantsEmails.includes(speaker.email) || emailsMatchingTeamMembers.includes(speaker.email);

                if (!isEmailAlreadyAdded) {
                    newParticipantsData.push({
                        ...makeParticipantSpeakerData(speaker.email, {
                            ...(speaker as any),
                            id: speaker.id,
                            email: speaker.email,
                        }),
                        id: makeId(),
                    });
                } else {
                    const participant = participants.find((x) => x.dataWithNullableEmail?.email === speaker.email);
                    if (participant) {
                        const {__typename, participantId, ...speakerDetails} = speaker as any;

                        const participantWithSpeakerDetails = {
                            ...participant,
                            speakerDetails: {
                                ...speakerDetails,
                                photoUrl: (speakerDetails.photoUrl || participant.dataWithNullableEmail.avatar) ?? "",
                            },
                        };

                        existingParticipantsData.push(participantWithSpeakerDetails);
                    }
                }
            }
        }

        if (!newParticipantsData.length && !existingParticipantsData.length) {
            return false;
        }

        designer.state.setDesignerCommitState(true);
        return updateParticipants({
            variables: {
                sessionId: session.id,
                upsertParticipantJSONs: [...newParticipantsData, ...existingParticipantsData].map((p) => ({
                    ...p,
                    isApproved: true,
                    submittedPasscode: true,
                })),
            },
            fetchPolicy: "no-cache",
        })
            .then((res) => {
                if (res.data?.updateParticipantsInSession) {
                    writeQuery("LocalParticipantsDocument", {
                        variables: {
                            id: session.id,
                        },
                        data: {
                            __typename: "Query",
                            participants: res.data.updateParticipantsInSession.participants,
                        },
                    });

                    designer.api.event.update({
                        speakerOrderJson: {
                            speakers: [
                                ...(speakerOrder.speakers || []),
                                ...res.data.updateParticipantsInSession.participants
                                    .filter((p) => !!p.speakerDetails)
                                    .map((p) => p.speakerDetails!["id"]),
                            ].filter((v, i, a) => a.indexOf(v) === i),
                        },
                    });
                    designer.commit();

                    return true;
                }
            })
            .finally(() => {
                designer.state.setDesignerCommitState(false);
            });
    }

    const eventSpeakers: Array<SpeakerDisplayType> = useMemo(() => {
        const speakers: Array<SpeakerDisplayType> = [];

        // biome-ignore lint/complexity/noForEach: <explanation>
        participants?.forEach((participant) => {
            const {dataWithNullableEmail} = participant;
            if (participant.speakerDetails) {
                speakers.push({
                    ...participant.speakerDetails,
                    email: participant.dataWithNullableEmail.email || null,
                    id: participant.speakerDetails?.id,
                    createdAt: participant.createdAt,
                    updatedAt: participant.updatedAt,
                    avatar: dataWithNullableEmail.avatar,
                    token: dataWithNullableEmail.token || undefined,
                });
            }
        });

        // biome-ignore lint/complexity/noForEach: <explanation>
        agendaItems?.forEach((agendaItem) => {
            if (agendaItem.agendaItemSpeakers) {
                // biome-ignore lint/complexity/noForEach: <explanation>
                agendaItem.agendaItemSpeakers
                    .filter((s) => speakers.findIndex((sp) => sp.id === s.speakerId) === -1)
                    .forEach((speaker) => {
                        const participant = participants?.find((p) => p.speakerDetails?.id === speaker.speakerId);
                        if (participant) {
                            const {dataWithNullableEmail} = participant;
                            speakers.push({
                                id: participant.speakerDetails?.id!,
                                email: participant.dataWithNullableEmail.email || null,
                                createdAt: participant.createdAt,
                                updatedAt: participant.updatedAt,
                                avatar: dataWithNullableEmail.avatar,
                                token: dataWithNullableEmail.token || undefined,
                                ...participant.speakerDetails,
                            });
                        }
                    });
            }
        });

        return speakers.map((s) => ({
            ...s,
            detailsComplete: hasAllSpeakerDetails(s),
        }));
    }, [participants, agendaItems]);

    function openNewSpeaker() {
        setMySpeakersOpen(false);
        setSelectedSpeaker(null);
        setNewSpeakerOpen(true);
        mixpanelTrack("frontend-new-speaker", "events");
    }

    function closeNewSpeaker() {
        setSelectedSpeaker(null);
        setNewSpeakerOpen(false);
    }

    function openMySpeakers() {
        setNewSpeakerOpen(false);
        setSelectedSpeaker(null);
        setMySpeakersOpen(true);
        mixpanelTrack("frontend-my-speakers", "events");
    }

    function closeMySpeakers() {
        setSelectedSpeaker(null);
        setMySpeakersOpen(false);
    }

    function openEditSpeaker(id: string) {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        setSelectedSpeaker(id);
        setNewSpeakerOpen(true);
    }

    const eventSpeakerEmails = useMemo(() => eventSpeakers.map((s) => s.email ?? ""), [eventSpeakers]);

    return (
        <div className={classes.root}>
            <div className={cls("fullh flex flex-col", classes.mainContainer)}>
                <div className="flex flex-align-center flex-justify-between mb-30">
                    <div className="flex flex-align-center">
                        <Typography fontWeight="bolder" variant="xl3" color="secondary">
                            Speakers
                        </Typography>
                        <Tooltip
                            title={
                                <div className="p-5">
                                    <Typography fontWeight="bolder" className="mb-5">
                                        What is a speaker?
                                    </Typography>
                                    <Typography variant="sm" className="mb-5">
                                        A speaker is a person who is presenting at the event. They can be a speaker, a panelist, a
                                        moderator, or a host.
                                    </Typography>
                                </div>
                            }
                            placement="right"
                            arrow
                        >
                            <HelpOutlineRoundedIcon className={cls("ml-6", common.infoIcon)} />
                        </Tooltip>
                    </div>
                    {eventNotUpdatable ? null : (
                        <div className="flex flex-items-center">
                            <Button
                                onClick={openMySpeakers}
                                variant="tertiary"
                                size="small"
                                data-id="event-button-my-speakers"
                                className={classes.speakerActionButton}
                            >
                                My speakers
                            </Button>
                            <Button
                                onClick={openNewSpeaker}
                                variant="tertiary"
                                size="small"
                                data-id="event-button-new-speaker"
                                className={classes.speakerActionButton}
                            >
                                <AddRoundedIcon className="mr-5" />
                                New speaker
                            </Button>
                        </div>
                    )}
                </div>

                {eventNotUpdatable ? null : (
                    <EventSpeakersTable
                        orderArray={(speakerOrder.speakers as string[]) || []}
                        removeSpeakers={handleRemoveSpeakers}
                        speakers={eventSpeakers}
                        setSpeakerToEdit={openEditSpeaker}
                        agendaItems={agendaItems}
                        readOnly={eventNotUpdatable}
                        baseLink={baseLink}
                        openNewSpeaker={openNewSpeaker}
                    />
                )}
            </div>
            {newSpeakerOpen && (
                <NewSpeakerDialog
                    mode={selectedSpeaker ? "edit" : "add"}
                    newSpeakerData={newSpeakerData}
                    setNewSpeakerData={setNewSpeakerData}
                    sessionId={session.id}
                    closeNewSpeaker={closeNewSpeaker}
                    speakerId={selectedSpeaker}
                    orderArray={(speakerOrder.speakers as string[]) || []}
                    eventSpeakerEmails={eventSpeakerEmails}
                />
            )}

            {mySpeakersOpen && (
                <MySpeakersDialog
                    eventSpeakers={eventSpeakers}
                    teamMembers={teamMembers}
                    onClose={closeMySpeakers}
                    openNewSpeaker={openNewSpeaker}
                    addSpeaker={addSpeaker}
                />
            )}
        </div>
    );
}
