import {ParticipantSessionState} from "@generated/data";
import {ApolloCancelRef} from "@workhorse/api/apolloTypes";
import {AuthService} from "@workhorse/api/authService";
import {useReactiveVar} from "@workhorse/api/data";
import designer, {ERROR_NO_SESSION_ID} from "@workhorse/api/designer";
import {createAgendaItemWithResource} from "@workhorse/api/designer/methods";
import {useIsMounted} from "@workhorse/api/isMounted";
import React, {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useHistory, useLocation} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {disableNativePopup} from "@workhorse/components/LeavePageNative";
import {mutate, readQuery} from "@workhorse/dataApi";
import {readCurrentParticipant, readSession, useSession} from "@workhorse/providers/SessionDataProviders";
import PlayerPrivacyLayer from "./PlayerPrivacyLayer";
import {setPreviousLocation, setSeenPrejoin, setSeenWaiting} from "./playerStateVars";

type EntrypointState = {
    tryJoinCalled: boolean;
    isOwner: boolean;
    isAssistant: boolean;
    participantId: string | undefined;
    skipJoinLayers: boolean;
    sessionIsPublished: boolean;
};

type PlayerEntrypointProps = {
    sessionId: string;
};

function PlayerEntryPoint(props: PlayerEntrypointProps) {
    const {sessionId} = props;

    const history = useHistory();

    const isMounted = useIsMounted();
    const [leaving, setLeaving] = useState(false);

    // combined state reduces re-renders by a count of 3
    const [state, setState] = useState<EntrypointState>(() => {
        const restored = readQuery("DesignerRestoreUncommitedDraftDocument", {
            variables: {
                // @ts-expect-error
                sessionId,
            },
        });

        const session = readSession({sessionId});

        const myParticipant = readCurrentParticipant({sessionId});

        return {
            tryJoinCalled: false,
            isOwner: myParticipant?.isOwner ?? false,
            isAssistant: false,
            participantId: session?.currentParticipant?.pid ?? undefined,
            needApproval: false,
            // for the fastify feature
            // AND in case somehow the owner creates a session and gets to refresh the browser
            // without commiting it
            // this state is updated ONCE by a leaf component which listens to session changes
            // when session.createdAt finally exists, this is set to false
            // forcing the user to go through the join/preJoin layers
            skipJoinLayers: session?.id && !session.createdAt ? true : false,
            sessionIsPublished: session && !!session.createdAt ? true : false,
        };
    });

    const unSkipJoinLayers = useCallback(
        (forceFetchSession?: boolean) =>
            setState((current) => ({
                ...current,
                sessionIsPublished: true,
                ...(forceFetchSession ? {skipJoinLayers: false} : null),
            })),
        []
    );

    const previousLocation = useReactiveVar(setPreviousLocation);
    const seenWaiting = useReactiveVar(setSeenWaiting);
    const gotoHome = previousLocation === `/waiting/${props.sessionId}`;

    useEffect(() => {
        setSeenPrejoin(true);
        setSeenWaiting(false);
    }, []);

    const cancelRef = useRef<ApolloCancelRef>({});

    const doTryJoin = async () => {
        const res = await mutate("TryJoinSessionDocument", {
            variables: {
                sessionId,
            },
            context: {
                cancelRef: cancelRef.current,
            } as $FixmeAny,
        }).catch((err) => {
            console.log("tryjoin err", err);
        });
        if (!res) {
            return;
        }
        if (res.data?.tryJoinSession) {
            if (
                res.data.tryJoinSession.isRoom &&
                res.data.tryJoinSession.redirectTo &&
                window.location.pathname.toLowerCase() !== res.data.tryJoinSession.redirectTo
            ) {
                history.replace(res.data.tryJoinSession.redirectTo);
                return;
            }
            if (
                res.data.tryJoinSession.instanceId
                //  && res.data.tryJoinSession.state !== "OWNER"
            ) {
                disableNativePopup(true);
                // TODO: maca, vasi - try this with data api. Maybe it works
                // history.replace(`/session/${res.data.tryJoinSession.instanceId}`);
                window.location.href = `/session/${res.data.tryJoinSession.instanceId}`;
                return;
            }

            if (res.data.tryJoinSession.tokenNeedsRefresh) {
                console.log(`[WithPlayerEntryPoint] removeItem access_token`);
                AuthService.getInstance().logout(window.location.href);
                return;
            }

            const participantId = res.data?.tryJoinSession?.participant?.pid ?? undefined;

            switch (res.data.tryJoinSession.state) {
                case ParticipantSessionState.Owner:
                    setState((c) => ({
                        ...c,
                        participantId,
                        tryJoinCalled: true,
                        isOwner: true,
                        sessionIsPublished: true,
                    }));
                    break;
                case ParticipantSessionState.CanJoin:
                    setState((c) => ({
                        ...c,
                        participantId,
                        tryJoinCalled: true,
                        sessionIsPublished: true,
                    }));
                    break;
                case ParticipantSessionState.Assistant:
                    setState((c) => ({
                        ...c,
                        participantId,
                        iAssistant: true,
                        tryJoinCalled: true,
                        sessionIsPublished: true,
                    }));
                    break;
                case ParticipantSessionState.NotAllowed:
                    if (res.data.tryJoinSession.redirectTo) {
                        window.location.href = res.data.tryJoinSession.redirectTo;
                        return;
                    }
                    disableNativePopup(true);
                    toast("You're not allowed to join this session!", {type: "warning"});
                    history.push("/");
                    break;
                case ParticipantSessionState.ExceededOrganizationLimits:
                    if (res.data.tryJoinSession.redirectTo) {
                        window.location.href = res.data.tryJoinSession.redirectTo;
                        return;
                    }
                    break;
                default:
                    break;
            }
        } else {
            console.log("try join redirect home");
            window.location.pathname = "/";
        }
    };

    useEffect(() => {
        if (state.tryJoinCalled || (state.skipJoinLayers && !state.sessionIsPublished)) {
            return;
        }
        // elimination of the useMutationHook reduces re-renders by 1
        doTryJoin();
    }, [state.participantId, state.sessionIsPublished]);

    useEffect(() => {
        return () => {
            cancelRef.current?.abort?.();
        };
    }, []);

    useEffect(() => {
        if (gotoHome) {
            history.replace(`/`);
        }

        return () => {
            setPreviousLocation(null);
        };
    }, [state.participantId, state.tryJoinCalled]);

    const proceedCondition = state.participantId && state.tryJoinCalled && !gotoHome ? true : false;
    const renderPrivacyLayer = proceedCondition || state.skipJoinLayers;

    return (
        <>
            {renderPrivacyLayer &&
                ((!leaving && (
                    <PlayerPrivacyLayer
                        sessionId={props.sessionId}
                        isOwner={state.isOwner}
                        participantId={state.participantId!}
                        isAssistant={state.isAssistant}
                        skipJoinLayers={state.skipJoinLayers}
                        sessionIsPublished={state.sessionIsPublished}
                        tryJoinCalled={state.tryJoinCalled}
                        isLeaving={leaving}
                        key="player-privacy-layer"
                    />
                )) ||
                    null)}

            {!state.sessionIsPublished && state.skipJoinLayers && <SessionCommiter unSkipJoinLayers={unSkipJoinLayers} />}
        </>
    );
}

type SessionCommiterProps = {
    unSkipJoinLayers: (forceFetchSession?: boolean) => void;
};

function SessionCommiter(props: SessionCommiterProps) {
    const session = useSession();
    const {createdAt, id} = session ?? {};

    const history = useHistory();
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    const location = useLocation<{}>();

    // console.log("in commiter", session);

    const cancelRef = useRef<ApolloCancelRef>({});

    useEffect(() => {
        // biome-ignore lint/complexity/noExtraBooleanCast: <explanation>
        if (!!createdAt) {
            // console.log("should unskip join layers");
            props.unSkipJoinLayers();
        } else if (session && id && "createdAt" in session && !createdAt) {
            // console.log("should commit session", {myParticipant, currentP: readCurrentParticipant()});
            designer
                .commitCreateSession({
                    cancelRef: cancelRef.current,
                })
                .then(async (res) => {
                    if (res?.id && res.quickSession) {
                        const resxId = location.state?.["resxId"];
                        const resxType = location.state?.["resxType"];
                        const artifactTag = location.state?.["artifactTag"];

                        if (resxId && resxType && artifactTag) {
                            await createAgendaItemWithResource(artifactTag, resxId, resxType, undefined, true);
                        }

                        history.replace({
                            pathname: history.location.pathname,
                            state: {
                                ...location.state,
                                ownerJustCreatedInstantSession: true,
                            },
                        });
                    }
                })
                .catch((err) => {
                    if (err instanceof Error && err.message === ERROR_NO_SESSION_ID) {
                        props.unSkipJoinLayers(true);
                    }
                });
        }
    }, [createdAt, id]);

    useEffect(() => {
        return () => {
            cancelRef.current.abort?.();
        };
    }, []);

    return <></>;
}

export default PlayerEntryPoint;
