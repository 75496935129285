import {cls} from "@ui/cdk/util";
import {memo} from "@workhorse/api/rendering";
import {useResourceResult} from "@workhorse/api/resx/hooks";
import {WithChildren} from "@workhorse/declarations";
import {useAgendaItems} from "@workhorse/providers/SessionDataProviders";
import classes from "./styles/ProcessingHandlerUi.module.scss";
import CircularProgress from "@ui/core/components/CircularProgress";
import Typography from "@ui/cdk/Typography";
import {getArtifactImage} from "@workhorse/util";
import ConversionNotifications from "../../ConversionNotifications";
import {ResourceProcessingStatus} from "@generated/data";
import descriptor from "@generated/artifacts";

type ProcessingUiHandlerProps = WithChildren & {
    inLobby?: boolean;
    isMobileOrTablet?: boolean;
    isAdmin?: boolean;
};

const ProcessingUiHandler = (props: ProcessingUiHandlerProps) => {
    const {inLobby, isAdmin, isMobileOrTablet} = props;
    const agendaItems = useAgendaItems();

    const convertingTool = isAdmin ? agendaItems.find((a) => a.processing) : undefined;
    const convertingMicroTag = convertingTool?.artifact?.artifactId;

    const {result} = useResourceResult(convertingTool?.artifact?.resourceResults?.[0]?.id);

    const convertingResult = result?.id && result.id === convertingTool?.artifact?.resourceResults?.[0]?.id ? result : undefined;

    const convertingResourceContent = convertingResult?.resource?.content as any;
    const progress =
        convertingResult?.resource?.processingStatus && convertingResult?.resource?.processingStatus !== ResourceProcessingStatus.Finished;

    if (convertingMicroTag) {
        return (
            <>
                {isMobileOrTablet ? null : progress ? (
                    <div className={cls("flex flex-center-all", classes.conversionProgress)}>
                        <CircularProgress size={15} thickness={7} className="mr-5" variant="indeterminate" />
                    </div>
                ) : (
                    <img
                        src={getArtifactImage(descriptor[convertingMicroTag].meta.icon ?? "")}
                        alt="icon"
                        className={classes.artifactImage}
                    />
                )}
                {isMobileOrTablet ? null : (
                    <Typography color="nonary" fontWeight="bold" className={classes.toolMenuTitle}>
                        {convertingResourceContent?.name || "Uploading file"}
                    </Typography>
                )}

                {convertingResult?.resource?.processingStatus ? (
                    <ConversionNotifications
                        inLobby={inLobby}
                        isConfigured={!!convertingTool?.artifact?.isConfigured}
                        processingStatus={convertingResult.resource.processingStatus}
                        progress={progress}
                    />
                ) : null}
            </>
        );
    }

    return <>{!isMobileOrTablet ? props.children : null}</>;
};

export default memo(ProcessingUiHandler);
