import {
    BackgroundFilter,
    backgroundFiltersGeneric,
    backgroundImages,
    getBackgroundFilterUrl,
} from "@workhorse/api/conference2/lib/VideoReplacement";
import {useEffect, useRef} from "@workhorse/api/rendering";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import {useMobile} from "@workhorse/providers/MobileProvider";
import Typography from "@ui/cdk/Typography/Typography";
import ParticipantBackgroundFilterGenericSelect from "./ParticipantBackgroundFilterGenericSelect";
import ParticipantBackgroundFilterImageSelect from "./ParticipantBackgroundFilterImageSelect";
import sectionStyles from "../styles/BackgroundEffectsSection.module.scss";
import genericStyles from "../../../styles/GenericSection.module.scss";
import videoStyles from "../../VideoSection/styles/VideoSection.module.scss";
import ParticipantBackgroundCustomImageUpload from "./ParticipantBackgroundCustomImageUpload";
import {useQuery} from "@workhorse/api/data";
import {GetUserCustomBackgroundsDocument} from "@generated/data";
import ParticipantBackgroundCustomImageSelect from "./ParticipantBackgroundCustomImageSelect";
import {useUserInfo} from "@workhorse/providers/User";
import {cls} from "@ui/cdk/util";
import {useTranslation} from "react-i18next";
import {useDeviceManagerEvents} from "@workhorse/api/conference2";
import toast from "@workhorse/api/toast";

const styles = {...genericStyles, ...videoStyles, ...sectionStyles};

type ParticipantBackgroundFilterSelectProps = {
    filter: string;
    videoCustomFilter: string | null;
    onChange: (filter: string) => void;
    classes?: {
        root?: string;
        filters?: string;
        effect?: string;
    };
};

const ParticipantBackgroundFilterSelect = (props: ParticipantBackgroundFilterSelectProps) => {
    const {t} = useTranslation();
    const {filter, onChange, videoCustomFilter} = props;

    const scrollContainerRef = useRef<ScrollContainerRef>({});
    const {isMobileOrTablet} = useMobile();

    const {isGuest} = useUserInfo();

    const {data} = useQuery(GetUserCustomBackgroundsDocument, {skip: isGuest});

    const customBackgrounds = [...(data?.getRemoteUser.user?.customBackgrounds ?? [])].reverse();

    const customBackgroundNotAvailable = videoCustomFilter && customBackgrounds.every((cb) => cb.id !== videoCustomFilter);

    useEffect(() => {
        if (customBackgroundNotAvailable) {
            onChange(BackgroundFilter.NONE);
        }
    }, [customBackgroundNotAvailable, onChange]);

    useDeviceManagerEvents({
        onBackgroundFilterError() {
            toast("Error while trying to create background filter.", {type: "error"});
            onChange(BackgroundFilter.NONE);
        },
    });

    return (
        <div className={cls(styles.filtersSelect, props.classes?.root)}>
            <ScrollContainer
                component="div"
                id="background-effects-section-filters-wrapper"
                connector={scrollContainerRef}
                axis="y"
                scrollBarOffsetTop={5}
                scrollBarOffsetBottom={5}
                isMobile={isMobileOrTablet}
                key="background-effects-section-filters-wrapper"
                className={styles.filtersWrapper}
            >
                <Typography variant="sm" fontWeight="bold" color="quaternary">
                    {t("player.settings.device.background_effects.title")}
                </Typography>
                <div className={cls(styles.filters, props.classes?.filters)}>
                    {backgroundFiltersGeneric.map((bf) => (
                        <ParticipantBackgroundFilterGenericSelect
                            key={bf}
                            filter={bf}
                            selected={filter === bf}
                            onClick={onChange.bind(null, bf)}
                            className={props.classes?.effect}
                        />
                    ))}
                </div>

                {!isGuest && (
                    <>
                        <Typography variant="sm" fontWeight="bold" color="quaternary">
                            {t("player.settings.device.background_effects.custom_images")}
                        </Typography>
                        <div className={cls(styles.filters, props.classes?.filters)}>
                            <ParticipantBackgroundCustomImageUpload className={props.classes?.effect} />
                            {customBackgrounds.map((bg) => (
                                <ParticipantBackgroundCustomImageSelect
                                    key={bg.id}
                                    url={bg.url}
                                    id={bg.id}
                                    selected={filter === BackgroundFilter.CUSTOM && videoCustomFilter === bg.id}
                                    onClick={onChange.bind(null, bg.id)}
                                    className={props.classes?.effect}
                                />
                            ))}
                        </div>
                    </>
                )}

                <Typography variant="sm" fontWeight="bold" color="quaternary">
                    {t("player.settings.device.background_effects.predefined_images")}
                </Typography>
                <div className={cls(styles.filters, props.classes?.filters)}>
                    {backgroundImages.map((bf, i) => (
                        <ParticipantBackgroundFilterImageSelect
                            key={bf}
                            preview={getBackgroundFilterUrl(bf, true)}
                            selected={filter === bf}
                            onClick={onChange.bind(null, bf)}
                            className={props.classes?.effect}
                        />
                    ))}
                </div>
            </ScrollContainer>
        </div>
    );
};

export default ParticipantBackgroundFilterSelect;
