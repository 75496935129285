/**
 * This file contains hardcoded objects used in resx
 * Remove this later when implementing the right stuff
 * TODO: Stefana/Maca
 */
import {ResourcesTypes} from "@generated/artifacts/resources";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {ResourceTypes} from "@workhorse/components/ResourcesDeleteConfirmationDialog";

import AdobeXDIcon from "../../../assets/media/adobexd.svg";
import AirtableIcon from "@artifacts/airtable/config/icons/airtable.svg";
import BoardmixIcon from "@artifacts/boardmix/config/icons/boardmix-resx-icon.svg";
import CanvaIcon from "../../../assets/media/resx-canva-icon.svg";
import FigmaIcon from "../../../assets/media/resx-figma-icon.svg";
import FormIcon from "@artifacts/form/assets/media/resx-form-icon.svg";
import GalleryIcon from "@artifacts/gallery/config/icons/gallery.svg";
import IframeIcon from "@artifacts/iframe/config/icons/iframe.svg";
import MentimeterIcon from "@artifacts/mentimeter/config/icons/mentimeter.svg";
import PdfIcon from "@artifacts/pdf/config/icons/pdf-resx-icon.svg";
import PptIcon from "@artifacts/ppt/config/icons/ppt-resx-icon.svg";
import DocIcon from "@artifacts/doc/config/icons/doc-resx-icon.svg";
import ExcelIcon from "@artifacts/excel/config/icons/excel-resx-icon.svg";
import KeynoteIcon from "@artifacts/keynote/config/icons/keynote-resx-icon.svg";
import PagesIcon from "@artifacts/pages/config/icons/pages-resx-icon.svg";
import NumbersIcon from "@artifacts/numbers/config/icons/numbers-resx-icon.svg";
import SlidoIcon from "../../../assets/media/resx-slido-icon.svg";
import PitchIcon from "../../../assets/media/resx-pitch-icon.svg";
import TwitchIcon from "../../../assets/media/resx-twitch-icon.svg";
import YoutubeIcon from "../../../assets/media/resx-youtube-icon.svg";
import VimeoIcon from "../../../assets/media/resx-vimeo-icon.svg";
import VideoIcon from "../../../assets/media/resx-video-icon.svg";
import BlackboardIcon from "../../../assets/media/resx-blackboard-icon.svg";
import {SpecifiersTypeMap, VersionedSpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {ArtifactTag} from "@generated/artifacts";
import {useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";
import {useMemo} from "@workhorse/api/rendering";
import {ControlStatus, HostType} from "@generated/data";
import {Artifact} from "@workhorse/declarations/dataTypes";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

export function hasIcon(type: ResourcesTypes) {
    switch (type) {
        case "flowos/video/resx/Video":
            return true;
        case "flowos/slideshow/resx/Slideshow":
            return true;
        case "flowos/gallery/resx/Gallery":
            return true;
        case "flowos/form/resx/Form":
            return true;
        default:
            return true;
    }
}

export function getResourceIcon<T extends ResourcesTypes>(type: T, content?: SpecifiersTypeMap[T], paletteFixup = false): JSX.Element {
    switch (type) {
        case "flowos/adobexd/resx/AdobeXD":
            return <img src={AdobeXDIcon} alt="adobexd" />;
        case "flowos/airtable/resx/Airtable":
            return <img src={AirtableIcon} alt="airtable" height="66%" />;
        case "flowos/canva/resx/Canva":
            return <img src={CanvaIcon} alt="canva" />;
        case "flowos/figma/resx/Figma":
            return <img src={FigmaIcon} alt="figma" />;
        case "flowos/form/resx/Form":
            return <img src={FormIcon} alt="form" />;
        case "flowos/iframe/resx/Iframe":
            return <img src={IframeIcon} alt="iframe" height="66%" />;
        case "flowos/mentimeter/resx/Mentimeter":
            return <img src={MentimeterIcon} alt="mentimeter" height="66%" />;
        case "flowos/slido/resx/Slido":
            return <img src={SlidoIcon} alt="slido" height="66%" />;
        case "flowos/pitch/resx/Pitch":
            return <img src={PitchIcon} alt="pitch" height={paletteFixup ? "26px" : "66%"} />;
        case "flowos/gallery/resx/Gallery":
            return <img src={GalleryIcon} alt="gallery" />;
        case "flowos/twitch/resx/Twitch":
            return <img src={TwitchIcon} alt="twitch" />;
        case "flowos/video/resx/Video":
            return <img src={VideoIcon} alt="video" />;
        case "flowos/youtube/resx/Youtube":
            return <img src={YoutubeIcon} alt="youtube" />;
        case "flowos/vimeo/resx/Vimeo":
            return <img src={VimeoIcon} alt="vimeo" />;
        case "flowos/ppt/resx/Ppt":
            return <img src={PptIcon} alt="ppt" />;
        case "flowos/doc/resx/Doc":
            return <img src={DocIcon} alt="doc" />;
        case "flowos/excel/resx/Excel":
            return <img src={ExcelIcon} alt="excel" />;
        case "flowos/keynote/resx/Keynote":
            return <img src={KeynoteIcon} alt="keynote" />;
        case "flowos/pages/resx/Pages":
            return <img src={PagesIcon} alt="pages" />;
        case "flowos/numbers/resx/Numbers":
            return <img src={NumbersIcon} alt="numbers" />;
        case "flowos/pdf/resx/Pdf":
            return <img src={PdfIcon} alt="pdf" />;
        case "flowos/blackboard/resx/Blackboard":
            return <img src={BlackboardIcon} alt="blackboard" />;
        case "flowos/boardmix/resx/Boardmix":
            return <img src={BoardmixIcon} alt="boardmix" />;
        default:
            return <img src={GalleryIcon} alt="icon" />;
    }
}

export function getResourceHeaders<
    TResourceType extends ResourcesTypes,
    TResourceVersion extends keyof VersionedSpecifiersTypeMap[TResourceType]["resource"]
>(type: TResourceType, version: TResourceVersion, displayProcessing?: boolean) /* : Headers<TResourceType, TResourceVersion> */ {
    switch (type) {
        case "flowos/adobexd/resx/AdobeXD":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.filesNumber": {
                    cellType: "number",
                    title: "Pages",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/airtable/resx/Airtable":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Name",
                },
                "content.airtableLink": {
                    cellType: "string",
                    title: "Link",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/canva/resx/Canva":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Design title",
                },
                "content.filesNumber": {
                    cellType: "number",
                    title: "Pages",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/figma/resx/Figma":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.filesNumber": {
                    cellType: "number",
                    title: "Pages",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/form/resx/Form":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.items.length": {
                    cellType: "number",
                    title: "Questions",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/gallery/resx/Gallery":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.filesNumber": {
                    cellType: "number",
                    title: "Images",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/iframe/resx/Iframe":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.url": {
                    cellType: "string",
                    title: "Link",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/mentimeter/resx/Mentimeter":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Name",
                },
                "content.votingLink": {
                    cellType: "string",
                    title: "Voting Link",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/pitch/resx/Pitch":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Name",
                },
                "content.link": {
                    cellType: "string",
                    title: "Pitch link",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/slideshow/resx/Slideshow":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.type": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
                ...(displayProcessing
                    ? {
                          processingStatus: {
                              cellType: "processing",
                              title: "Status",
                          },
                      }
                    : {}),
            };
        case "flowos/pdf/resx/Pdf":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.type": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/doc/resx/Doc":
        case "flowos/ppt/resx/Ppt":
        case "flowos/excel/resx/Excel":
        case "flowos/pages/resx/Pages":
        case "flowos/keynote/resx/Keynote":
        case "flowos/numbers/resx/Numbers":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.type": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
                ...(displayProcessing
                    ? {
                          processingStatus: {
                              cellType: "processing",
                              title: "Status",
                          },
                      }
                    : {}),
            };
        case "flowos/slido/resx/Slido":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Name",
                },
                "content.eventLink": {
                    cellType: "string",
                    title: "Link",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/twitch/resx/Twitch":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.type": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/video/resx/Video":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.extension": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
                ...(displayProcessing
                    ? {
                          processingStatus: {
                              cellType: "processing",
                              title: "Status",
                          },
                      }
                    : {}),
            };
        case "flowos/blackboard/resx/Blackboard":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },

                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
                isTemplate: {
                    title: "Type",
                    cellType: "isTemplate",
                },
            };
        case "flowos/youtube/resx/Youtube":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.type": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/vimeo/resx/Vimeo":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                "content.type": {
                    cellType: "string",
                    title: "Type",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
        case "flowos/boardmix/resx/Boardmix":
            return {
                "content.name": {
                    cellType: "string",
                    title: "Name",
                },
                "content.boardmixSource": {
                    cellType: "string",
                    title: "Link",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };

        default:
            return {
                "content.name": {
                    cellType: "string",
                    title: "Title",
                },
                updatedAt: {
                    cellType: "date",
                    title: "Date",
                },
            };
    }
}

export function getResourcesTextsAndTypes(type: ResourcesTypes) {
    let resourceType: string = ResourceTypes.resource,
        noResourcesTitle: string | JSX.Element = "",
        noResourcesBody: (string | JSX.Element)[] = [];

    switch (type) {
        case "flowos/form/resx/Form":
            resourceType = "Form";
            noResourcesTitle = "See what participants have to say";
            noResourcesBody = ["Get feedback via interactive quizzes, polls, and surveys, right here in Sessions."];
            break;
        case "flowos/gallery/resx/Gallery":
            resourceType = "Images";
            noResourcesTitle = "Show photos and other images during live sessions";
            noResourcesBody = ["You can upload .jpg or .png files."];
            break;
        case "flowos/pdf/resx/Pdf":
            resourceType = "PDFs";
            noResourcesTitle = "Show PDF files during live sessions";
            noResourcesBody = ["Ideal for showcasing slideshows, images, and docs."];
            break;
        case "flowos/doc/resx/Doc":
            resourceType = "Documents";
            noResourcesTitle = "Embed documents into live sessions";
            noResourcesBody = ["Choose if you want them to be view-only or interactive. "];
            break;
        case "flowos/ppt/resx/Ppt":
            resourceType = "Presentations";
            noResourcesTitle = "Embed your presentations into live sessions";
            noResourcesBody = ["Control the slides yourself or let participants view them at their own pace."];
            break;
        case "flowos/excel/resx/Excel":
            resourceType = "Spreadsheets";
            noResourcesTitle = "Embed spreadsheets into live sessions";
            noResourcesBody = ["Choose if you want them to be view-only or interactive."];
            break;
        case "flowos/pages/resx/Pages":
            resourceType = "Pages";
            noResourcesTitle = "Embed iWork Pages into live sessions";
            noResourcesBody = ["Choose if you want them to be view-only or interactive."];
            break;
        case "flowos/keynote/resx/Keynote":
            resourceType = "Keynote";
            noResourcesTitle = "Show iWork Keynote presentations during live sessions";
            noResourcesBody = ["Control the slides yourself or let participants view them at their own pace."];
            break;
        case "flowos/numbers/resx/Numbers":
            resourceType = "Numbers";
            noResourcesTitle = "Embed iWork Numbers spreadsheets into live sessions";
            noResourcesBody = ["Choose if you want them to be view-only or interactive."];
            break;
        case "flowos/video/resx/Video":
            resourceType = "Videos";
            noResourcesTitle = "Show videos during live sessions";
            noResourcesBody = ["You can upload .mp4 or .wmv files."];
            break;
        case "flowos/youtube/resx/Youtube":
            resourceType = "Youtube";
            noResourcesTitle = "Share video content with your audience during live sessions";
            noResourcesBody = ["Copy and paste any YouTube link to store it in your Tools."];
            break;
        case "flowos/vimeo/resx/Vimeo":
            resourceType = "Vimeo";
            noResourcesTitle = "Share video content with your audience during live sessions";
            noResourcesBody = ["Copy and paste any Vimeo video link to store it in your Tools."];
            break;
        case "flowos/figma/resx/Figma":
            resourceType = "Figma";
            noResourcesTitle = "Present your Figma designs during live sessions";
            noResourcesBody = ["Import your designs using the Sessions plugin for Figma."];
            break;
        case "flowos/airtable/resx/Airtable":
            resourceType = "Airtable";
            noResourcesTitle = "Review data and workflows with your team";
            noResourcesBody = ["Copy and paste your Airtable board’s “share publicly” link to embed it in your sessions."];
            break;
        case "flowos/iframe/resx/Iframe":
            noResourcesTitle = "Browse websites during live sessions:";
            noResourcesBody = [
                "Embed any website, as long as the site allows it.",
                "Alternatively, copy and paste an embed code.",
                "Attach the Websites tool to an agenda or live session. Participants will be able to browse and interact with the website.",
            ];
            break;
        case "flowos/mentimeter/resx/Mentimeter":
            resourceType = "Mentimeter";
            noResourcesTitle = "Bring interactive presentations into live sessions";
            noResourcesBody = [
                <>
                    Copy and paste your Mentimeter <i>public voting link</i> and let participants vote directly in Sessions.
                </>,
            ];
            break;
        case "flowos/slido/resx/Slido":
            resourceType = "Slido";
            noResourcesTitle = "Create interactive, hybrid meetings with polls, quizzes, and more";
            noResourcesBody = [
                <>
                    Copy and paste your Slido <i>event link</i> and let your participants vote directly in Sessions.
                </>,
            ];
            break;
        case "flowos/twitch/resx/Twitch":
            resourceType = "Twitch";
            noResourcesTitle = "Embed live streams into your sessions";
            noResourcesBody = ["Copy and paste any Twitch stream URL to save that channel here."];
            break;
        case "flowos/canva/resx/Canva":
            resourceType = "Canva";

            noResourcesTitle = "Showcase your Canva designs during live sessions";
            noResourcesBody = ["Import your designs using the Sessions plugin for Canva (music and video not compatible)."];
            break;
        case "flowos/adobexd/resx/AdobeXD":
            resourceType = "Adobe XD";
            noResourcesTitle = "Show your Adobe XD designs during live sessions";
            noResourcesBody = ["Import your designs using the Sessions plugin for Adobe XD."];
            break;
        case "flowos/pitch/resx/Pitch":
            resourceType = "Pitch";
            noResourcesTitle = "Bring interactive presentations into live sessions";
            noResourcesBody = [
                <>
                    Copy and paste your Pitch <i>public access link</i> to embed it in your sessions.
                </>,
            ];
            break;
        case "flowos/blackboard/resx/Blackboard":
            resourceType = "whiteboard";
            noResourcesTitle = "Work together on a whiteboard";
            noResourcesBody = ["Draw, write, and doodle together on this dynamic canvas."];
            break;
        case "flowos/boardmix/resx/Boardmix":
            resourceType = "Boardmix";
            noResourcesTitle = "Collaborate on a board";
            noResourcesBody = ["Copy and paste your Boardmix board’s link to embed it in your sessions."];
            break;
        default:
            break;
    }

    return {
        resourceType,
        noResourcesTitle,
        noResourcesBody,
    };
}

type OnboardingDetails = {
    onboardingTitle: string;
    onboardingLink: string;
};

export function getOnboardingDetails(tag?: ArtifactTag): OnboardingDetails {
    switch (tag) {
        case "flowos/video":
            return {
                onboardingTitle: "Upload videos and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/videos"),
            };

        case "flowos/youtube":
            return {
                onboardingTitle: "The world’s number one video sharing platform.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/youtube"),
            };

        case "flowos/vimeo":
            return {
                onboardingTitle: "The preferred video service for brand requirements and advanced support.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/vimeo"),
            };

        case "flowos/blackboard":
            return {
                onboardingTitle: "Draw, write and doodle together on this internal Sessions tool.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/tools/whiteboard-by-sessions"),
            };

        case "flowos/twitch":
            return {
                onboardingTitle: "Share live-stream channels centered around gaming, art, music, and more ",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/twitch"),
            };

        case "flowos/form":
            return {
                onboardingTitle: "Interact with your audience using this internal Sessions tool.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/tools/forms-by-session"),
            };

        case "flowos/gallery":
            return {
                onboardingTitle: "Upload images or photos and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/images"),
            };

        case "flowos/slideshow":
            return {
                onboardingTitle: "Compile slides and pages with Apple’s presentation software.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/keynote-presentations"),
            };

        case "flowos/canva":
            return {
                onboardingTitle: "Create graphic designs with this free-to-use cloud-based platform.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/canva"),
            };

        case "flowos/figma":
            return {
                onboardingTitle: "Design and collaborate on interfaces for mobile, tablet, desktop and other hardware.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/figma"),
            };

        case "flowos/adobexd":
            return {
                onboardingTitle: "Design, prototype, and share user experiences.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/adobe-xd"),
            };

        case "flowos/iframe":
            return {
                onboardingTitle:
                    "Share websites or embed codes with your participants. They’ll be able to browse and interact with the content.",
                onboardingLink: getHelpCenterArticleLink("iframe"),
            };

        case "flowos/mentimeter":
            return {
                onboardingTitle: "Add fun and interactive presentations, polls, quizzes and word clouds to your live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/mentimeter"),
            };

        case "flowos/slido":
            return {
                onboardingTitle: "Make your sessions fun and interactive by embedding Slido’s live polls, quizzes, word clouds, etc.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/slido"),
            };

        case "flowos/airtable":
            return {
                onboardingTitle: "Track, manage, and present data and workflows with Airtable boards.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/airtable"),
            };

        case "flowos/pitch":
            return {
                onboardingTitle: "Pitch helps you create beautifully designed presentations and slide decks.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/pitch"),
            };

        case "flowos/pdf":
            return {
                onboardingTitle: "Upload PDF files and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/pdfs"),
            };

        case "flowos/ppt":
            return {
                onboardingTitle: "Upload PowerPoint and other presentations and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/powerpoint-presentations"),
            };

        case "flowos/doc":
            return {
                onboardingTitle: "Upload documents from your computer and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/documents"),
            };
        case "flowos/excel":
            return {
                onboardingTitle: "Upload spreadsheets and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/spreadsheets"),
            };

        case "flowos/google-docs":
            return {
                onboardingTitle: "Create and edit text documents through your Google account.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/google-docs"),
            };

        case "flowos/google-sheets":
            return {
                onboardingTitle: "Create and edit spreadsheets through your Google account.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/google-sheets"),
            };

        case "flowos/google-slides":
            return {
                onboardingTitle: "Create and edit slideshows through your Google account.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/google-slides"),
            };

        case "flowos/google-forms":
            return {
                onboardingTitle: "Create and edit forms through your Google account.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/google-forms"),
            };

        case "flowos/keynote":
            return {
                onboardingTitle: "Upload iWork Keynote presentations and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/keynote-presentations"),
            };

        case "flowos/pages":
            return {
                onboardingTitle: "Upload iWork Pages files and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/pages"),
            };

        case "flowos/numbers":
            return {
                onboardingTitle: "Upload iWork Numbers spreadsheets and embed them into your agendas or live sessions.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/files/numbers"),
            };

        case "flowos/boardmix":
            return {
                onboardingTitle: "Track, manage, and present data and workflows with Boardmix boards.",
                onboardingLink: getHelpCenterArticleLink("tools-files-integrations/integrations/boardmix"),
            };

        default:
            return {onboardingTitle: "", onboardingLink: ""};
    }
}

/**
 * @description Returns true if everyone has controls, false if only the hosts, null if there are no controls
 */
function getCurrentArtifactControl(artifact: Artifact): boolean | null {
    const tag = artifact?.artifactId;

    switch (tag) {
        case "flowos/canva":
        case "flowos/figma":
        case "flowos/gallery":
            return artifact?.data?.enableParticipantControls === "true";
        case "flowos/doc":
        case "flowos/excel":
        case "flowos/keynote":
        case "flowos/numbers":
        case "flowos/pages":
        case "flowos/pdf":
        case "flowos/ppt":
        case "flowos/slideshow":
        case "flowos/adobexd":
            return artifact?.data?.navigationEnabled === "true";
        case "flowos/video":
        case "flowos/vimeo":
        case "flowos/youtube":
            return artifact?.data?.playMode === "false";
        case "flowos/blackboard":
        case "flowos/boardmix":
            return artifact?.data?.isCollaborative === "true";
        case "flowos/google-docs":
        case "flowos/google-sheets":
        case "flowos/google-slides":
        case "flowos/google-forms":
        case "flowos/iframe":
        case "flowos/mentimeter":
        case "flowos/slido":
        case "flowos/airtable":
        case "flowos/pitch":
        case "flowos/miro":
        case "flowos/twitch":
            return true;
        case "flowos/my-product-tool":
            return false;

        default:
            return null;
    }
}

type CurrentArtifactControlStatus = {
    canRequestControl: boolean;
    canTakeControl: boolean;
    canReturnControl: boolean;
    controllingParticipantId?: string;
    requestingParticipantId?: string;
    isCurrentParticipantControlling: boolean;
    enabledControls: boolean | null;
    hasControl: boolean;
};

export function useCurrentArtifactControlStatus(): CurrentArtifactControlStatus {
    const {artifact, agendaItemSpeakers} = useCurrentAgendaItem() ?? {};

    const enabledControls = artifact ? getCurrentArtifactControl(artifact) : null;

    const hostType = useHostType();
    const isPlayer = hostType === HostType.Player;
    const isFeedback = hostType === HostType.Feedback;

    const {
        controllingParticipantId: cId,
        requestingParticipantId: rId,
        currentParticipantSpeakerId,
        currentParticipantId,
        isAssistant,
        isOwner,
    } = useParticipantsStore(
        useShallow((state) => ({
            requestingParticipantId: state.requestingParticipantId,
            controllingParticipantId: state.controllingParticipantId,
            currentParticipantId: state.currentParticipant?.id,
            currentParticipantSpeakerId: state.currentParticipant?.speakerDetails?.id,
            isOwner: state.currentParticipant?.isOwner,
            isAssistant: state.amIaModerator && !state.currentParticipant?.isOwner,
        }))
    );

    const controllingParticipantId = enabledControls === false ? cId : undefined;

    // Set to true if the user is not in Player, so he can still interact with the resources from Files, Tools etc.
    const isCurrentParticipantControlling = isFeedback ? false : controllingParticipantId === currentParticipantId || !isPlayer;

    const requestingParticipantId = enabledControls === false ? rId : undefined;

    const isSpeaker = agendaItemSpeakers?.some((s) => s.speakerId === currentParticipantSpeakerId);

    const isOrganizer = isOwner || isAssistant || isSpeaker;

    const canRequestControl =
        !isOrganizer && !isCurrentParticipantControlling && artifact?.artifactId === "flowos/my-product-tool" && enabledControls === false;
    const canTakeControl = isOrganizer && !isCurrentParticipantControlling && enabledControls === false;
    const canReturnControl = !isOrganizer && isCurrentParticipantControlling && enabledControls === false;

    const hasControl = isCurrentParticipantControlling || !!enabledControls;

    return useMemo(
        () => ({
            enabledControls,
            isCurrentParticipantControlling,
            controllingParticipantId,
            requestingParticipantId,
            canRequestControl,
            canTakeControl,
            canReturnControl,
            hasControl,
        }),
        [
            enabledControls,
            isCurrentParticipantControlling,
            controllingParticipantId,
            requestingParticipantId,
            canRequestControl,
            canTakeControl,
            canReturnControl,
            hasControl,
        ]
    );
}
