import {useMutation, useQuery} from "@apollo/client";
import {SetMemberWorkspaceRoleDocument, WorkspaceRolesDocument} from "@generated/data";
import Select from "@ui/cdk/Select";
import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util";
import {useEffect} from "@workhorse/api/rendering";
import {useState} from "@workhorse/api/state";
import toast from "@workhorse/api/toast";
import {useUserInfo} from "@workhorse/providers/User";
import classes from "./UserManagementRoleSelect.module.scss";
import {beautifyRoleName} from "./utils";

interface Props {
    className?: string;
    value?: string;
    loading?: boolean;
    large?: boolean;
    onChange: (value: string) => void;
    disabled?: boolean;
    workspaceId: string;
}

export function WorkspaceUserManagementRoleSelect(props: Props) {
    const {data, error, loading} = useQuery(WorkspaceRolesDocument, {
        variables: {
            workspaceId: props.workspaceId,
        },
    });

    const user = useUserInfo();

    const selectOptions =
        data?.workspace?.roles
            .filter((r) => {
                if (props.disabled) {
                    return true;
                }
                if (r.name === "OWNER") {
                    return user?.activeWorkspacePermissions?.role.name === "OWNER";
                }
                return true;
            })
            .map((role) => ({
                value: role.id,
                text: beautifyRoleName(role.name),
            })) ?? [];

    if (!data && loading) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Select
                data-id="role-select"
                classes={{root: cls(classes.permissionSelect, props.large && "large")}}
                disableUnderline
                variant="standard"
                value={props.value}
                disabled={!!props.disabled}
                wrapperClassName={props.className}
                onChange={(e) => props.onChange(e.target.value as string)}
                onClick={(e) => e.stopPropagation()}
                options={selectOptions}
            />
        </div>
    );
}

interface WithUpdateProps {
    className?: string;
    userId: string;
    userRoleId: string;
    organizationId: string;
    disabled?: boolean;
    workspaceId: string;
    isOrganizationOwner?: boolean;
}

export function WorkspaceUserManagementRoleSelectWithUpdate(props: WithUpdateProps) {
    const [value, setValue] = useState(props.userRoleId);

    const [updateRole] = useMutation(SetMemberWorkspaceRoleDocument, {
        onError: (err) => {
            setValue(props.userRoleId);
            toast(err.message, {
                type: "error",
            });
        },
    });

    useEffect(() => {
        setValue(props.userRoleId);
    }, [props.userRoleId]);

    const handleChange = (value: string) => {
        if (value === props.userRoleId) {
            return;
        }
        setValue(value);
        updateRole({
            variables: {
                userId: props.userId,
                newRoleId: value,
            },
        });
    };

    return (
        <div className={classes.selectContainer}>
            <WorkspaceUserManagementRoleSelect
                disabled={props.disabled}
                value={value}
                onChange={handleChange}
                workspaceId={props.workspaceId}
            />
        </div>
    );
}
