import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {MutableRefObject} from "react";
import {useTranslation} from "react-i18next";
import recording from "../../../../assets/media/recording.png";
import classes from "./style/StartRecordingDialog.module.scss";

import {livestreamLimitByPlan, recordingLimitByPlan} from "@sessions/common/subscription-limitations";

import {SessionRecordingType, SubscriptionModifierType} from "@generated/data";

import Checkbox from "@ui/cdk/Checkbox";
import Link from "@ui/cdk/Link";
import {useUserInfo} from "@workhorse/providers/User";

interface Props {
    onOk?: () => void;
    onCancel?: () => void;
    dialogRef: MutableRefObject<DialogImperativeRef>;
    selectedRecType: SessionRecordingType;
    setRecordingType: (type: SessionRecordingType) => void;
    disabled?: boolean;
}

function StartLivestreamDialog(props: Props) {
    const {t} = useTranslation();
    const user = useUserInfo();

    const activePlan = user.activeOrganizationPermission.organization.plan ?? undefined;

    const planName = user.activeOrganizationPermission.organization.plan;

    const additionalRecordingHours =
        user.activeOrganizationPermission.organization.subscriptionModifiers?.filter(
            (m) => m.type === SubscriptionModifierType.MoreRecordingHours
        ).length ?? 0;
    const extraMembers = user.activeOrganizationPermission.organization.subscriptionModifiers?.filter(
        (m) => m.type === SubscriptionModifierType.MoreUsers
    ).length;

    const maxRecordings = Math.floor(recordingLimitByPlan(extraMembers ?? 1, additionalRecordingHours)[planName]);
    const maxLivestreaming = Math.floor(livestreamLimitByPlan[planName]);

    const recordingTimeLeftMs =
        maxRecordings -
        (user.activeOrganizationPermission?.organization.recordingActivity
            ? user.activeOrganizationPermission.organization.recordingActivity
            : 0);

    const livestreamTimeLeftMs = maxLivestreaming - (user.activeOrganizationPermission.organization.livestreamActivity ?? 0);

    const recordingTimeLeftMin = Math.floor(1.6667e-5 * recordingTimeLeftMs);
    const recordingHoursLeftHours = Math.floor(recordingTimeLeftMin >= 60 ? Math.floor(recordingTimeLeftMin / 60) : 0);

    const livestreamTimeLeftMin = Math.floor(1.6667e-5 * livestreamTimeLeftMs);
    const livestreamHoursLeftHours = Math.floor(livestreamTimeLeftMin >= 60 ? Math.floor(livestreamTimeLeftMin / 60) : 0);

    const recordingConfirmBodyKey =
        props.selectedRecType === SessionRecordingType.RecordingLivestream
            ? "participant.livestreaming_recording.confirm.body"
            : "participant.livestreaming.confirm.body";

    const startLivestreamKey =
        props.selectedRecType === SessionRecordingType.RecordingLivestream
            ? "participant.recording.start_livestreaming_recording"
            : "participant.recording.start_livestreaming";

    const handleToggleRecordingType = () => {
        props.setRecordingType?.(
            props.selectedRecType === SessionRecordingType.RecordingLivestream
                ? SessionRecordingType.Livestream
                : SessionRecordingType.RecordingLivestream
        );
    };

    const hasEnoughMinutesToStart =
        props.selectedRecType === SessionRecordingType.Livestream
            ? livestreamTimeLeftMin > 1
            : livestreamTimeLeftMin > 1 && recordingTimeLeftMin > 1;

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            BackdropProps={{
                className: "standard-backdrop",
            }}
            imperativeRef={props.dialogRef}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <div className={classes.header}>
                <img className={classes.image} src={recording} />
                <Typography variant="xl3" component="h2" color="primary">
                    {t("participant.recording.confirm.title")}
                    {/* {t("participant.recording.confirm.title_maintenance")} */}
                </Typography>
            </div>

            <div className={classes.info}>
                <Typography color="primary">{t(recordingConfirmBodyKey)}</Typography>

                <div className="flex flex-align-center my-10 gap-6">
                    <Checkbox
                        checked={props.selectedRecType === SessionRecordingType.RecordingLivestream}
                        onChange={handleToggleRecordingType}
                    />
                    <Typography fontWeight="bold">{t("player.settings.general.livestreaming.livestream_recording")}</Typography>
                </div>

                {props.selectedRecType === SessionRecordingType.Livestream && (
                    <Typography className="mb-10">{t("player.settings.general.livestreaming.cant_start_both_livestreaming")}</Typography>
                )}

                {/* <Typography color="primary">{t("participant.recording.confirm.body_maintenance")}</Typography> */}

                <Typography fontWeight="bolder" className="mt-16">
                    {props.selectedRecType === SessionRecordingType.Livestream ? (
                        // LIVESTREAM ONLY
                        livestreamTimeLeftMs !== Infinity &&
                        livestreamTimeLeftMs !== null &&
                        (livestreamHoursLeftHours > 0 ? (
                            t("limitations.dialog.livestream_x_hours_left", {hours_left: livestreamHoursLeftHours})
                        ) : livestreamTimeLeftMin > 1 ? (
                            t("limitations.dialog.livestream_x_minutes_left", {minutes_left: livestreamTimeLeftMin})
                        ) : (
                            <>
                                {t("limitations.dialog.no_more_livestream_hours_1")}

                                <Link className="p-0 mt-6" to="/user/organization/subscriptions/plans" target="_blank">
                                    {t("limitations.dialog.upgrade_your_plan")}
                                </Link>
                            </>
                        ))
                    ) : (
                        // RECORDING AND LIVESTREAM
                        <>
                            {recordingTimeLeftMs !== Infinity && recordingTimeLeftMs !== null && (
                                <>
                                    {recordingHoursLeftHours > 0 ? (
                                        t("limitations.dialog.recording_x_hours_left", {hours_left: recordingHoursLeftHours})
                                    ) : recordingTimeLeftMin > 1 ? (
                                        t("limitations.dialog.recording_x_minutes_left", {minutes_left: recordingTimeLeftMin})
                                    ) : (
                                        <>{t("limitations.dialog.no_more_recording_hours_1")}</>
                                    )}
                                    <br />
                                </>
                            )}

                            {livestreamTimeLeftMs !== Infinity &&
                                livestreamTimeLeftMs !== null &&
                                (livestreamHoursLeftHours > 0 ? (
                                    t("limitations.dialog.livestream_x_hours_left", {hours_left: livestreamHoursLeftHours})
                                ) : livestreamTimeLeftMin > 1 ? (
                                    t("limitations.dialog.livestream_x_minutes_left", {minutes_left: livestreamTimeLeftMin})
                                ) : (
                                    <>
                                        {t("limitations.dialog.no_more_livestream_hours_1")} <br />
                                    </>
                                ))}

                            {(livestreamTimeLeftMin <= 1 || recordingTimeLeftMin <= 1) && (
                                <>
                                    <Link className="p-0 mt-6" to="/user/organization/subscriptions/plans" target="_blank">
                                        {t("limitations.dialog.upgrade_your_plan")}
                                    </Link>
                                </>
                            )}
                        </>
                    )}
                </Typography>
            </div>

            <div className={classes.footer}>
                <Button className={classes.cancelButton} type="button" variant="tertiary" size="large" onClick={props.onCancel}>
                    {t("g.cancel")}
                </Button>
                <Button
                    disabled={!!props.disabled || !hasEnoughMinutesToStart}
                    data-id="confirm-start-livestream"
                    type="button"
                    onClick={props.onOk}
                    size="large"
                    autoFocus
                >
                    {t(startLivestreamKey)}
                </Button>
            </div>
        </Dialog>
    );
}

export default StartLivestreamDialog;
