import browserInfo from "@workhorse/api/BrowserInfo";
import EventEmitter from "eventemitter3";
import {requestDeviceSafely, stopMediaStream} from "../../utils/devices";
import {DeviceManagerEventsMap} from "./DeviceManager";

const BACKGROUND_REACTION_DELAY = 5000;

export interface StreamManagerResult {
    stream: MediaStream | null;
    error: Error | null;
}

type ExtendedMediaStreamTrack = MediaStreamTrack & {
    reallyStop?: () => void;
};

export class StreamManager<Result extends StreamManagerResult> {
    stream?: MediaStream;
    constraints?: MediaStreamConstraints;

    protected reacquireTrack = false;
    protected isInBackground = false;
    protected backgroundTimeout: ReturnType<typeof setTimeout> | undefined;

    constructor(
        protected readonly userId: string,
        protected readonly events: EventEmitter<DeviceManagerEventsMap>,
        protected readonly storageKey: string,
        protected readonly onStreamRestarted: (result: Result) => void,
        protected readonly onStreamEnded: (stream: MediaStream) => void
    ) {
        this.isInBackground = document.visibilityState === "hidden";
        document.addEventListener("visibilitychange", this.appVisibilityChangedListener);
    }

    stop() {
        this.stopStream();
        document.removeEventListener("visibilitychange", this.appVisibilityChangedListener);
    }

    async stopStream(isReplaced = false) {
        if (!this.stream) {
            return;
        }

        const tracks = this.stream.getTracks() as ExtendedMediaStreamTrack[];

        for (const track of tracks) {
            track.removeEventListener("ended", this.handleEnded);
            track.stop();
            track.reallyStop?.();
        }
    }

    isActive() {
        if (!this.stream) {
            return false;
        }

        const track = this.stream.getTracks()[0];

        if (!track) {
            return false;
        }

        return track.readyState === "live" && track.enabled;
    }

    getSettings() {
        return this.stream?.getTracks()[0]?.getSettings();
    }

    getStoredDeviceId() {
        const settings = this.getStoredSettings();
        if (settings) {
            return settings.deviceId;
        }
    }

    getStoredSettings() {
        const item = localStorage.getItem(this.getStorageSettingsKey());

        if (!item) {
            return;
        }

        try {
            return JSON.parse(item) as MediaTrackSettings;
        } catch (e) {
            // do nothing
        }
    }

    setStoredSettings(settings?: MediaTrackSettings) {
        localStorage.setItem(this.getStorageSettingsKey(), JSON.stringify(settings));
    }

    protected setupTracks() {
        if (!this.stream) {
            return;
        }

        const tracks = this.stream.getTracks() as ExtendedMediaStreamTrack[];

        for (const track of tracks) {
            track.addEventListener("ended", this.handleEnded);
            const stopTrack = track.stop;
            track.stop = () => {
                // do nothing
            };
            track.reallyStop = stopTrack;
        }
    }

    protected saveLatestSettings() {
        const settings = this.getSettings();
        this.setStoredSettings(settings);
    }

    protected async replaceStream(constraints: MediaStreamConstraints) {
        await this.stopStream(true);
        return this.createStream(constraints);
    }

    protected async createStream(constraints: MediaStreamConstraints) {
        this.constraints = constraints;

        let [stream, error] = await requestDeviceSafely(constraints);

        if (stream && !this.isStreamActive(stream)) {
            stopMediaStream(stream);
            stream = null;
        }

        this.stream = stream ?? undefined;
        this.setupTracks();

        if (stream) {
            this.saveLatestSettings();
        }

        return {
            stream,
            error,
        } as Result;
    }

    protected isStreamActive(stream: MediaStream) {
        return stream.active && stream.getTracks().every((track) => track.readyState === "live");
    }

    protected async restartStream() {
        this.reacquireTrack = false;

        // nothing to restart
        if (this.constraints == null) {
            return;
        }

        const result = await this.replaceStream(this.constraints);
        this.onStreamRestarted(result);
    }

    protected needsReAcquisition() {
        if (!this.stream || !this.reacquireTrack) {
            return false;
        }

        const track = this.stream.getTracks()[0];

        if (!track) {
            return false;
        }

        return track.readyState !== "live" || !track.enabled;
    }

    protected handleEnded = () => {
        if (this.isInBackground) {
            this.reacquireTrack = true;
        } else if (this.stream) {
            this.onStreamEnded(this.stream);
        }
    };

    protected appVisibilityChangedListener = () => {
        if (this.backgroundTimeout) {
            clearTimeout(this.backgroundTimeout);
        }

        // delay app visibility update if it goes to hidden
        // update immediately if it comes back to focus
        if (document.visibilityState === "hidden") {
            this.backgroundTimeout = setTimeout(() => this.handleAppVisibilityChanged(true), BACKGROUND_REACTION_DELAY);
        } else {
            this.handleAppVisibilityChanged(false);
        }
    };

    protected async handleAppVisibilityChanged(isInBackground: boolean) {
        this.isInBackground = isInBackground;

        if (!browserInfo.isMobile()) {
            return;
        }

        if (!this.isInBackground && this.needsReAcquisition()) {
            await this.restartStream();
        }
    }

    protected getStorageSettingsKey() {
        return `${this.storageKey}.settings:${this.userId}`;
    }
}
