import Typography from "@ui/cdk/Typography";
import classes from "./style/EventsLandingPageLanguageSelector.module.scss";
import {cls} from "@ui/cdk/util";
import LanguageSelectorComponent from "@workhorse/components/language-selector/components/LanguageSelectorComponent";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import designer from "@workhorse/api/designer";
import Tooltip from "@ui/cdk/Tooltip";
import commonClasses from "../../event/eventCommons.module.scss";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {useMutation} from "@workhorse/dataApi";

type EventsLandingPageLanguageSelectorProps = {
    readOnly: boolean;
};

function EventsLandingPageLanguageSelector(props: EventsLandingPageLanguageSelectorProps) {
    const {readOnly} = props;
    const {event, id} = useSession();
    const [lockEvent] = useMutation("LockEventDocument");

    const handleOnChangeLanguage = (language: string) => {
        lockEvent({
            variables: {
                id: id,
            },
        });

        designer.api.event.update({
            language: language,
        });
        designer.commit();
    };

    return (
        <div className={classes.configuration}>
            <div className={cls("mt-40 flex flex-justify-between", classes.title)}>
                <div className="flex flex-row flex-align-center gap-5">
                    <Typography component="div" color="tertiary" variant="lg">
                        Language
                    </Typography>

                    <Tooltip
                        title={
                            <div>
                                <Typography variant="sm">Select in what language the Event Page will be displayed.</Typography>
                            </div>
                        }
                        placement="right"
                        arrow
                    >
                        <InfoIcon className={cls(commonClasses.infoIcon)} />
                    </Tooltip>
                </div>
            </div>
            <LanguageSelectorComponent
                disabled={readOnly}
                language={event?.language ?? "en"}
                onChange={handleOnChangeLanguage}
                className="p-0"
            />
        </div>
    );
}

export default EventsLandingPageLanguageSelector;
