import DeleteIcon from "@material-ui/icons/Delete";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import Button from "@ui/cdk/Button";
import Menu from "@ui/cdk/Menu";
import {cls} from "@ui/cdk/util";
import Fade from "@ui/core/components/Fade";
import {useCallback} from "@workhorse/api/rendering";
import classes from "./style/AgendaTemplatesTableRowMenu.module.scss";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMixpanelTracker} from "@workhorse/api/tracking";
type Props = {
    anchorEl: HTMLElement | null;
    canDeleteAgenda: boolean;
    onClose: () => void;
    toggleUsageHistory: () => void;
    toggleDeleteTemplate: () => void;
    onDuplicateAgendaTemplate: () => void;
    isShared: boolean;
};

const AgendaTemplatesTableRowMenu = (props: Props) => {
    const {anchorEl, canDeleteAgenda, onClose, toggleUsageHistory, toggleDeleteTemplate, onDuplicateAgendaTemplate, isShared} = props;
    const open = Boolean(anchorEl);
    const isCommitting = useDesignerSessionCommitState();
    const {mixpanelTrack} = useMixpanelTracker();
    const handleToggleUsageHistory = useCallback(() => {
        toggleUsageHistory();
        onClose();
        mixpanelTrack("frontend-see-usage-history", "agendas");
    }, [toggleUsageHistory, onClose]);

    const handleToggleDeleteTemplate = useCallback(() => {
        toggleDeleteTemplate();
        onClose();
    }, [toggleDeleteTemplate, onClose]);

    const handleDuplicateAgendaTemplate = useCallback(() => {
        onDuplicateAgendaTemplate();
        onClose();
        mixpanelTrack("frontend-duplicate-agenda", "agendas");
    }, [onDuplicateAgendaTemplate, onClose]);

    return (
        <Menu
            id="fade-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            TransitionComponent={Fade}
            classes={{paper: classes.moreMenuPaper}}
            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            transformOrigin={{horizontal: "right", vertical: "top"}}
            getContentAnchorEl={null}
        >
            {!isShared && (
                <li className={cls(classes.paperButton, classes.paperButtonSimple)}>
                    <Button
                        fullWidth
                        startIcon={<FileCopyRoundedIcon />}
                        data-id="duplicate-agenda"
                        variant="quaternary"
                        onClick={handleDuplicateAgendaTemplate}
                        noFocusBorder
                        disabled={isCommitting}
                    >
                        Duplicate agenda
                    </Button>
                </li>
            )}
            <li className={cls(classes.paperButton, classes.paperButtonSimple)}>
                <Button
                    fullWidth
                    startIcon={<HistoryRoundedIcon />}
                    data-id="usage-history"
                    variant="quaternary"
                    onClick={handleToggleUsageHistory}
                    noFocusBorder
                >
                    See usage history
                </Button>
            </li>
            {canDeleteAgenda ? (
                <li className={cls(classes.paperButton)}>
                    <Button
                        fullWidth
                        startIcon={<DeleteIcon />}
                        data-id="delete-agenda-admin"
                        variant="destructive-quaternary"
                        onClick={handleToggleDeleteTemplate}
                        className={classes.deleteButton}
                        noFocusBorder
                    >
                        Delete agenda
                    </Button>
                </li>
            ) : null}
        </Menu>
    );
};

export default AgendaTemplatesTableRowMenu;
