import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/BlockedPermissionsModal.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@ui/core/components/IconButton";
import {useEffect, useRef} from "react";
import {BlockedPermissionsImg} from "./RequestPermissionsDialog";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {useTranslation} from "react-i18next";

type AudioRemovedDialogProps = {
    onClose: () => void;
};

export function AudioRemovedDialog(props: AudioRemovedDialogProps) {
    const imperativeRef = useRef<DialogImperativeRef>();

    const {t} = useTranslation();

    const {show} = useSessionSettingsDialogDisplay();

    useEffect(() => {
        imperativeRef.current?.toggle?.(true);
    }, []);

    const handleAudioSettings = () => {
        props.onClose();
        show("device.audio");
    };

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            classes={{
                paper: classes.paper,
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop-with-backdrop-filter",
                },
            }}
            imperativeRef={imperativeRef}
            open={true}
            disableBackdropClick
        >
            <div className={cls("flex flex-justify-between", classes.dialogHeader)}>
                <Typography className={classes.dialogHeaderTitle}>
                    {t("player.notifications.audio-ended.title") ?? "You are now muted!"}
                </Typography>
                <IconButton onClick={props.onClose} className={cls("p-0 mb-auto", classes.closeButton)}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <div>
                <div>
                    <BlockedPermissionsImg hideCamera={true} />
                    <Typography variant="lg" className={classes.infoTextSimple}>
                        {t("player.notifications.audio-ended.description") ??
                            "Your microphone has been disconnected. Please ensure your microphone is properly connected, and review your audio settings."}
                    </Typography>
                </div>
            </div>

            <div className="flex flex-row">
                <Button variant="secondary" className={cls("mb-15 p-15 mr-16", classes.halfFooterButton)} onClick={props.onClose}>
                    {t("g.close")}
                </Button>
                <Button variant="primary" className={cls("mb-15 p-15", classes.halfFooterButton)} onClick={handleAudioSettings}>
                    {t("player.notifications.mic.audio_settings") ?? "Audio Settings"}
                </Button>
            </div>
        </Dialog>
    );
}
