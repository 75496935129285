import {HostType} from "@generated/data";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useHistory} from "@workhorse/api/routing";
import {enableEventLeaveModal, routeNavigation} from "@workhorse/components/header/headerUtils";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {ReactComponent as SlashDividerIcon} from "../../../assets/media/slash_divider_icon.svg";
import classes from "./event-header.module.scss";

type EventHeaderBreadcrumbs = {
    eventName?: string;
};

const EventHeaderBreadcrumbs = (props: EventHeaderBreadcrumbs) => {
    const history = useHistory();
    const {eventName} = props;

    const isLocked = useDesignerSessionLocked();

    const user = useUserInfo();
    const hostType = useHostType();
    const isEvent = hostType === HostType.Event;
    const organizationName = user.activeOrganizationPermission.organization.name ?? "Workspace";

    const goBack = () => {
        history.push({
            pathname: "/events",
        });
    };

    const preventNavigation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isEvent) {
            e.preventDefault();
            routeNavigation("/events");
            enableEventLeaveModal(true);
        } else {
            goBack();
        }
    };

    return (
        <div className={cls("flex flex-align-center", classes.breadcrumbs)}>
            <div onClick={preventNavigation} className="flex flex-items-center cursor-pointer mr-10">
                <ArrowBackRoundedIcon />
                <Typography color="primary" fontWeight="bolder" variant="lg" noWrap>
                    {organizationName} events
                </Typography>
            </div>

            <SlashDividerIcon />

            {isLocked && (
                <>
                    <Tooltip
                        arrow
                        placement="top"
                        title={
                            <>
                                <Typography variant="base" fontWeight="bolder" className="mb-4">
                                    Someone else is editing this event
                                </Typography>
                                <Typography variant="sm">
                                    If you want to edit this event, you will need to wait until the other person is done.
                                </Typography>
                            </>
                        }
                    >
                        <LockRoundedIcon className={classes.lockedIcon} />
                    </Tooltip>
                </>
            )}
            <Typography color="blueGray400" fontWeight="bolder" noWrap variant="lg">
                {eventName}
            </Typography>
        </div>
    );
};

export default EventHeaderBreadcrumbs;
