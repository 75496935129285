import React, {createElement, useRef} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useUserAgentFeatures} from "@workhorse/providers/UserAgentFeaturesProvider";

export type ShareComponentProps = {
    onClick?: (e: React.MouseEvent) => void;
};

type ShareComponents = "button" | React.ComponentType<{onClick?: (e: React.MouseEvent) => void}>;

export type ShareProps<T extends ShareComponents> = {
    data: ShareData;
    component: T;
    fallback?: JSX.Element | null;
    children?: React.ReactNode | React.ReactNode[];
} & React.ComponentProps<T>;

export function Share<TComponent extends ShareComponents = "button">({
    data,
    component,
    fallback = null,
    children,
    ...componentProps
}: ShareProps<TComponent>) {
    const {share} = useUserAgentFeatures();
    const {
        ua: {isMobileOrTabletUA},
    } = useMobile();

    const isRequesting = useRef(false);

    const handleOnShareClick = async (...params: never[]) => {
        if (isRequesting.current) {
            return;
        }

        isRequesting.current = true;

        try {
            await navigator.share(data);
            componentProps?.onClick?.call(null, ...params);
        } catch (e) {
            console.error(e);
        }

        setTimeout(() => {
            isRequesting.current = false;
        }, 200);
    };

    const canShareData = navigator.canShare?.(data);
    const canShare = share && canShareData;
    const shouldShare = isMobileOrTabletUA && canShare;

    if (shouldShare) {
        return createElement(
            component,
            {
                ...(componentProps || {}),
                onClick: handleOnShareClick,
            },
            children
        );
    } else if (canShare) {
        console.warn(
            "Cannot share data using WebShare API",
            share ? "The API is not supported" : canShareData ? "The data is not supported" : "Unknown reason"
        );
    }

    return fallback;
}
