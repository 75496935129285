import type {AudioAnimationProps} from "./AnimatedMicrophoneIcon";
import {cls} from "@ui/cdk/util";
import style from "./styles/AudienceParticipant.module.scss";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import ConnectionQualityIcon from "./ConnectionQualityIcon";
import RemoteVideoIcon from "./RemoteVideoIcon";
import AnimatedMicrophoneIcon from "./AnimatedMicrophoneIcon";

type ParticipantIconsProps = {
    className?: string;
    participantId: string;
    isThumbnailMode?: boolean;
};

export default function RemoteParticipantIcons(props: ParticipantIconsProps & AudioAnimationProps) {
    const {micOn, isSharing, connectionQuality, cameraPaused} = useRemoteParticipantStatus(props.participantId);

    return (
        <>
            <span className={cls(style.participantIcons, props.className)}>
                {isSharing && (
                    <div className={cls(style.statusIconContainer, props.isThumbnailMode ? style.isThumbnail : "")}>
                        <ScreenShareIcon data-id="share-screen-icon" className="participant-icon" />
                    </div>
                )}
                <ConnectionQualityIcon connectionQuality={connectionQuality} isThumbnailMode={props.isThumbnailMode} />

                <RemoteVideoIcon cameraPaused={cameraPaused} participantId={props.participantId} isThumbnailMode={props.isThumbnailMode} />

                <AnimatedMicrophoneIcon isMuted={!micOn} isThumbnailMode={props.isThumbnailMode} participantId={props.participantId} />
            </span>
        </>
    );
}
