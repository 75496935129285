import {getLatestVersionNumber} from "@api/versioning";
import {getLocalTimezone} from "@common/utils/timezones";
import {HostType, LocalUserDocument, MutationRunFirstLoginEventsArgs, RunFirstLoginEventsDocument} from "@generated/data";
import environment from "@generated/environment";
import apollo from "@workhorse/api/apollo";
import {AuthService} from "@workhorse/api/authService";
import {useQuery} from "@workhorse/api/data";
import extension from "@workhorse/api/extension";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {Redirect, useHistory, useLocation} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {
    getCelloUniqueCampaignCode,
    getPreviousGuestSession,
    getUserUtmCookies,
    updateRemoteUser,
    useRemoteUser,
    getRewardfulReferral,
} from "@workhorse/api/user";
import Loading from "@workhorse/components/Loading";
import {WithChildren} from "@workhorse/declarations";
import {routes} from "@workhorse/pages";
import GoogleWorkspaceEventWarning from "@workhorse/pages/user/google/GoogleWorkspaceEventWarning";
import SessionPublicLink from "@workhorse/pages/user/guest/SessionPublicLink";
import UserInviteCode from "@workhorse/pages/user/guest/UserInviteCode";
import {useSessionIdFromRoute} from "./CurrentSessionIdProvider";
import ExtensionAuthProvider from "./ExtensionAuthProvider";
import {useHostType} from "./HostTypeProvider";

const RemoteUserRetriever = (props: WithChildren & {role: string}) => {
    const location = useLocation();
    const history = useHistory();
    const {data} = useRemoteUser({
        // for returnPartialData to work on first fetch
        // the fetchPolicy MUST be "networkOnly" or "no-cache"
        returnPartialData: true,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
    });

    const updatedTz = useRef(false);

    useEffect(() => {
        const run = async () => {
            if (data?.getRemoteUser?.user) {
                if (data.getRemoteUser.user.firstLogin) {
                    const previousSessionDetails = getPreviousGuestSession();
                    const referralCode = new URLSearchParams(location.search).get("ref") ?? undefined;

                    const cookies = document.cookie
                        .split(";")
                        .map((x) => x.trim())
                        .map((x) => x.split("=").map((y) => y.trim()))
                        .reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {});

                    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                    const externalReferral = cookies["_exre"];

                    const firstLoginData: MutationRunFirstLoginEventsArgs = {
                        ...(previousSessionDetails
                            ? {
                                  previousSessionDetails: {
                                      previousParticipantId: previousSessionDetails.previousParticipantId,
                                      sendEmailFromSessionId: previousSessionDetails.previousSessionId,
                                  },
                              }
                            : {}),
                        ...(referralCode ? {referralCode} : {}),
                        ...(externalReferral ? {externalReferral} : {}),
                        utmCookies: getUserUtmCookies(),
                        celloUcc: getCelloUniqueCampaignCode(),
                        rewardfulReferral: getRewardfulReferral(),
                        lastVersionSeen: getLatestVersionNumber(),
                    };

                    await apollo.client
                        .mutate({
                            mutation: RunFirstLoginEventsDocument,
                            variables: firstLoginData,
                        })
                        .then(() => {
                            if (referralCode) {
                                history.push("/");
                            }
                        });
                }

                if (props.role === "USER") {
                    if (
                        !updatedTz.current &&
                        (!data.getRemoteUser.user.timezone || data.getRemoteUser.user.timezone.toUpperCase().startsWith("GMT"))
                    ) {
                        // Update user timezone
                        // IMPORTANT: if this update fails or is not done
                        // default booking schedules are not created, thus not available
                        updateRemoteUser({
                            timezone: getLocalTimezone(),
                        });
                        updatedTz.current = true;
                    }
                }
            } else if (data?.getRemoteUser?.guestDoesNotExist === true) {
                console.log(`[RemoteUserChecker] removeItem access_token`);
                AuthService.getInstance().logout(environment.landingPage);
            } else if (data?.getRemoteUser.userSuspended === true) {
                toast("Your account has been suspended. Please contact your organization administrator.", {type: "warning"});
                setTimeout(() => {
                    AuthService.getInstance().logout();
                }, 3000);
            }
        };

        run();
    }, [data, history, location.search, props.role]);

    // The rest of the app should not be rendered until we have the remote user
    // otherwise dragons will happen
    return <>{(data?.getRemoteUser.user && props.children) || <Loading location="RemoteUserChecker.tsx->RemoteUserRetriever" />}</>;
};

function RemoteUserChecker(props: WithChildren) {
    const cachedUser = useQuery(LocalUserDocument);
    const history = useHistory();

    const hostType = useHostType();
    const isPublicRoute = routes[hostType]?.isPublic;
    const sessionId = useSessionIdFromRoute();

    const location = useLocation();
    const tokenFromParams = new URLSearchParams(location.search).get("token");
    const returnToFromParams = new URLSearchParams(location.search).get("returnToEventPage");

    if (returnToFromParams) {
        AuthService.getInstance().logout(returnToFromParams);
    }

    useEffect(() => {
        const currentTheme = localStorage.getItem("theme");
        if (cachedUser.data?.localUser?.role === "GUEST" && currentTheme === "dark") {
            document.documentElement.removeAttribute("data-theme");
        }
    }, [cachedUser.data?.localUser?.role]);

    if (hostType === HostType.Player && sessionId && sessionId.length > 0 && cachedUser.data) {
        const from = new URLSearchParams(location.search).get("from");
        if (location.pathname.startsWith("/join") && from && from === "googleWorkspace") {
            return <GoogleWorkspaceEventWarning sessionId={sessionId} />;
        }
        if (cachedUser.data.isAuthenticated === false) {
            if (tokenFromParams) {
                return <UserInviteCode token={tokenFromParams} sessionId={sessionId} />;
            } else {
                return <SessionPublicLink hostType={hostType} sessionId={sessionId} />;
            }
        } else if (cachedUser.data.isAuthenticated === true && location.pathname.startsWith("/join")) {
            // Backwards compatibility for old join links
            history.push(`/session/${sessionId}`);
        }
    }

    if (cachedUser.data && cachedUser.data.isAuthenticated === false && extension.isExtensionEnabled()) {
        return <ExtensionAuthProvider />;
    }

    if (cachedUser.data && cachedUser.data.isAuthenticated === false && !extension.isExtensionEnabled()) {
        if (sessionStorage.getItem("logout-confirm")) {
            window.close();
        }
    }

    if (cachedUser.data && cachedUser.data.isAuthenticated === false && !isPublicRoute) {
        AuthService.getInstance().login(history.location.pathname);

        return <Loading location="RemoteUserChecker.tsx" />;
    }

    // TODO: @Vasi/@Andrei Codreanu
    // a public route may be accessed by an authenticated user
    // which means we WANT to retrieve the user

    return cachedUser.data?.localUser && (!isPublicRoute || (cachedUser.data.isAuthenticated ?? false)) ? (
        <RemoteUserRetriever role={cachedUser.data.localUser.role}>{props.children}</RemoteUserRetriever>
    ) : (
        <>{props.children}</>
    );
}

function WithSessionId(props: WithChildren) {
    const sessionId = useSessionIdFromRoute();
    const hostType = useHostType();
    const isNonExistent = sessionId === "non-existent";
    return isNonExistent ? <Redirect to="/404" /> : hostType !== HostType.Player || sessionId ? <RemoteUserChecker {...props} /> : null;
}

export default WithSessionId;
