import {BackgroundFilter} from "@workhorse/api/conference2/lib/VideoReplacement";
import classes from "./styles/PreJoinConferenceContainer.module.scss";
import {Fragment, useMemo, useRef} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import PreJoinConferenceVideo from "./PreJoinConferenceVideo";
import {OneTooltipRef} from "@ui/cdk/OneTooltip";
import PreJoinConferenceControls from "./PreJoinConferenceControls";
import {cls} from "@ui/cdk/util";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useDevices} from "@workhorse/api/conference2/providers/MediaProvider/DevicesProvider";
import {useTranslation} from "react-i18next";

type PreJoinConferenceContainerProps = {
    participantId: string;
    sessionId: string;
    isMobile: boolean;
    isPortrait: boolean;
    audioPublishingIsDisabled?: boolean;
    videoPublishingIsDisabled?: boolean;
};

const PreJoinConferenceContainer = (props: PreJoinConferenceContainerProps) => {
    const {t} = useTranslation();
    const {participantId, sessionId, isMobile, isPortrait, audioPublishingIsDisabled, videoPublishingIsDisabled} = props;

    const isMobileOrTablet = useMemo(
        () => browserInfo.isMobile() || browserInfo.isTablet() || browserInfo.isIOS() || browserInfo.isIpadOS(),
        []
    );

    const {
        noiseReduction,
        videoFilter,
        videoCustomFilter,
        audioInputs,
        audioOutputs,
        videoInputs,
        isVoiceFocusSupported,
        isVideoFilterSupported,
        setNoiseReduction,
        setAudioConstraints,
        setVideoFilter,
        setVideoConstraints,
        setSpeakerDevice,
        getCurrentAudioDevice,
        getCurrentVideoDevice,
        getCurrentSpeakerDevice,
    } = useDevices();

    const audioInput = getCurrentAudioDevice() ?? "";
    const audioOutput = getCurrentSpeakerDevice() ?? "";
    const videoInput = getCurrentVideoDevice() ?? "";

    const backgroundWarningRef = useRef<boolean>(false);
    const tooltipConnectorRef: OneTooltipRef = useRef({});

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const showTooltip = (e?: any, message?: string) => {
        if (isMobileOrTablet) {
            return;
        }
        if (tooltipConnectorRef.current.setData) {
            tooltipConnectorRef.current.setData({
                anchorEl: e ? e.currentTarget : undefined,
                content: <span className="text">{message}</span>,
            });
        }
    };

    const handleChangeFilter = (filter: string) => {
        setVideoFilter(filter);

        if (filter !== BackgroundFilter.NONE && backgroundWarningRef.current === false) {
            backgroundWarningRef.current = true;
            toast(
                <Fragment key="pre-join-screen-conference-background-warning">
                    {t("player.notifications.camera.turning_bg_effects_on.turning")}{" "}
                    <strong>{t("player.notifications.camera.turning_bg_effects_on.bg_effects")}</strong>{" "}
                    {t("player.notifications.camera.turning_bg_effects_on.on_may_affect_performance")}
                </Fragment>,
                {
                    type: "warning",
                    duration: 5000,
                }
            );
        }
    };

    const onChangeAudioInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const deviceId = event.target.value;
        setAudioConstraints({deviceId});
    };

    const onChangeAudioOutput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const deviceId = event.target.value;
        setSpeakerDevice(deviceId);
    };

    const onChangeVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const deviceId = event.target.value;
        setVideoConstraints({deviceId});
    };

    const onVoiceFocusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNoiseReduction(event.target.checked);
    };

    return (
        <div
            className={cls(classes.root, isMobile && !isPortrait && classes.mobileLandscape)}
            role="region"
            aria-label={t("player.footer.aria.conference_controls") ?? "Conference controls"}
        >
            <PreJoinConferenceVideo
                participantId={participantId}
                audioPublishingIsDisabled={audioPublishingIsDisabled}
                videoPublishingIsDisabled={videoPublishingIsDisabled}
            />
            <PreJoinConferenceControls
                tooltipConnectorRef={tooltipConnectorRef}
                voiceFocusEnabled={noiseReduction}
                isVoiceFocusSupported={isVoiceFocusSupported}
                audioInput={audioInput}
                audioOutput={audioOutput}
                videoInput={videoInput}
                audioInputs={audioInputs}
                audioOutputs={audioOutputs}
                videoInputs={videoInputs}
                onChangeAudioInput={onChangeAudioInput}
                onChangeAudioOutput={onChangeAudioOutput}
                onChangeVideo={onChangeVideo}
                onVoiceFocusChange={onVoiceFocusChange}
                filter={videoFilter}
                videoCustomFilter={videoCustomFilter}
                onFilterChange={handleChangeFilter}
                showTooltip={showTooltip}
                supportsBackgroundFilter={isVideoFilterSupported}
                sessionId={sessionId}
                isMobile={isMobile}
                isPortrait={isPortrait}
            />
        </div>
    );
};

export default PreJoinConferenceContainer;
