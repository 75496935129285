import {SendSessionFlagsDocument, SessionFlags} from "@generated/data";
import apollo from "@workhorse/api/apollo";

const updateSessionFlags = async (sessionId: string, flags: SessionFlags) =>
    await apollo.client.mutate({
        mutation: SendSessionFlagsDocument,
        variables: {
            sessionId,
            flags,
        },
    });

export default updateSessionFlags;
