import {AddArtifactToAgendaItem} from "./actions/agenda-item/add-artifact-to-agenda-item";
import {CopyArtifactToMultipleItems} from "./actions/agenda-item/copy-artifact-to-multiple-items";
import {CopyArtifactToOneItem} from "./actions/agenda-item/copy-artifact-to-one-item";
import {CreateAgendaItemAction} from "./actions/agenda-item/create-agenda-item";
import {DuplicateAgendaItem} from "./actions/agenda-item/duplicate-agenda-item";
import {InsertAgendaItemAction} from "./actions/agenda-item/insert-item";
import {MoveAgendaItem} from "./actions/agenda-item/move-agenda-item";
import {RemoveAgendaItemAction} from "./actions/agenda-item/remove-agenda-item";
import {RemoveArtifactFromAgendaItem} from "./actions/agenda-item/remove-artifact-from-agenda-item";
import {UpdateAgendaItem} from "./actions/agenda-item/update-agenda-item";
import {UpdateAgendaItemLock} from "./actions/agenda-item/update-agenda-item-lock";
import {AddMacroArtifact} from "./actions/artifact/add-macro-artifact";
import {RemoveMacroArtifact} from "./actions/artifact/remove-macro-artifact";
import {ReplaceMacroArtifact} from "./actions/artifact/replace-macro-artifact";
import {SetArtifactResult} from "./actions/artifact/set-artifact-result";
import {UpdateArtifact} from "./actions/artifact/update-artifact";
import {UpdateMacroArtifact} from "./actions/artifact/update-macro-artifact";
import {UpdateEvent} from "./actions/event/update-event";
import {CreateSessionAction} from "./actions/session/create-session";
import {DetachAgendaAction} from "./actions/session/detach-agenda";
import {EvictSessionAction} from "./actions/session/evict-session";
import {ImportAgendaAction} from "./actions/session/import-agenda";
import {UpdateParticipants} from "./actions/session/update-participants";
import {UpdateSessionAction} from "./actions/session/update-session";
import {UpdateRoom} from "./actions/room/update-room";

export const actionsRegistry = {
    session: {
        create: CreateSessionAction,
        evict: EvictSessionAction,
        detachAgenda: DetachAgendaAction,
        update: UpdateSessionAction,
        updateParticipants: UpdateParticipants,
        importAgenda: ImportAgendaAction,
    },
    agendaItem: {
        create: CreateAgendaItemAction,
        remove: RemoveAgendaItemAction,
        addArtifact: AddArtifactToAgendaItem,
        removeArtifact: RemoveArtifactFromAgendaItem,
        update: UpdateAgendaItem,
        lock: UpdateAgendaItemLock,
        move: MoveAgendaItem,
        duplicate: DuplicateAgendaItem,
        insert: InsertAgendaItemAction,
        copyArtifactToMultipleItems: CopyArtifactToMultipleItems,
        copyArtifactToOneItem: CopyArtifactToOneItem,
    },
    artifact: {
        updateArtifact: UpdateArtifact,
        updateMacroArtifact: UpdateMacroArtifact,
        setArtifactResult: SetArtifactResult,
        addMacroArtifact: AddMacroArtifact,
        removeMacroArtifact: RemoveMacroArtifact,
        replaceMacroArtifact: ReplaceMacroArtifact,
    },
    event: {
        update: UpdateEvent,
    },
    room: {
        update: UpdateRoom,
    },
};
