import {MemberFragment, SessionParticipantFragment} from "@generated/data";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import TableCell from "@ui/cdk/Table/TableCell";
import TableRow from "@ui/cdk/Table/TableRow";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {ParticipantWithSpeakerDetailsPayload} from "../event/event-speakers/utils";
import classes from "./event-collaborators-table-row.module.scss";

type EventCollaboratorsTableRowProps = {
    collaborator: SessionParticipantFragment;
    onRemove: (id: string) => void;
};

function EventCollaboratorsTableRow(props: EventCollaboratorsTableRowProps) {
    const {collaborator, onRemove} = props;
    const {dataWithNullableEmail, id} = collaborator;
    const {firstName, lastName, email, avatar} = dataWithNullableEmail;
    const fullName = `${firstName} ${lastName}`.trim();

    return (
        <TableRow className={classes.root}>
            <TableCell size="unset">
                <div className="flex flex-align-center">
                    <ProfilePicture
                        avatar={avatar}
                        avatarVariant="rounded"
                        avatarSize="L"
                        firstName={firstName}
                        lastName={lastName}
                        className="mr-12"
                        email={email}
                    />
                    <div className="flex flex-col mr-12">
                        <Typography color="primary" fontWeight="bold" noWrap data-private>
                            {fullName}
                        </Typography>
                        <Typography color="nonary" fontWeight="normal" noWrap data-private>
                            {email}
                        </Typography>
                    </div>
                    <IconButton className={classes.closeBtn} onClick={() => onRemove(id)}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
            </TableCell>
        </TableRow>
    );
}

export default EventCollaboratorsTableRow;
