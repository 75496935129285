import {ParticipantStatus, SessionLifecycle} from "@generated/data";
import designer from "@workhorse/api/designer";
import {DesignerApiSessionUpdatePayload} from "@workhorse/declarations/dataTypes";
import {DesignerAction} from "../../action";
import {makeId} from "../../utils";

export type UpdateSessionActionInput = Partial<
    Omit<NonNullable<DesignerApiSessionUpdatePayload["session"]>, "id" | "oldId" | "createdAt" | "updatedAt" | "update">
>;

export class UpdateSessionAction extends DesignerAction<UpdateSessionActionInput> {
    commit() {
        const inputSession = this.input ?? ({} as UpdateSessionActionInput);
        const {lifecycle} = inputSession;
        const current = structuredClone({
            session: designer.currentSession(),
            currentParticipant: designer.currentCurrentParticipant(),
            participants: designer.currentParticipants(),
        });

        if (lifecycle === SessionLifecycle.Started && current?.currentParticipant.status !== ParticipantStatus.JoinedSession) {
            const myParticipantIndex = current?.participants?.findIndex((p) => p.id === current?.currentParticipant.id);
            if (myParticipantIndex !== -1) {
                current!.participants[myParticipantIndex!] = {
                    ...current!.participants[myParticipantIndex!],
                    status: ParticipantStatus.JoinedSession,
                };
            }
            current!.currentParticipant.status = ParticipantStatus.JoinedSession;
        } else {
            // @ts-expect-error
            delete current.participants;
            // @ts-expect-error
            delete current.currentParticipant;
        }

        const updated = designer.updateCurrentSession(
            {
                ...current,
                session: {
                    ...current?.session,
                    ...inputSession,
                },
            },
            {strategy: "replace"}
        );
        return updated?.session?.id!;
    }

    canRedo() {
        return false;
    }

    canUndo() {
        return false;
    }
}
