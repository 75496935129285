import {useQuery} from "@apollo/client";
import {MyRoomsDocument, RoomDataFragment} from "@generated/data";
import {cls} from "@ui/cdk/util";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import designer from "@workhorse/api/designer";
import {FuseOptions, useFuseWorker} from "@workhorse/api/fuse";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {useSeenSection} from "@workhorse/api/seen";
import toast from "@workhorse/api/toast";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {useUserInfo} from "@workhorse/providers/User";
import OnboardingRoomsCreateDialog from "../user/onboarding/components/onboarding-session-dialog/OnboardingRoomsCreateDialog";
import OnboardingRoomsWelcomeDialog from "../user/onboarding/components/onboarding-session-dialog/OnboardingRoomsWelcomeDialog";
import RoomsHeader from "./RoomsHeader";
import RoomsTableBody from "./RoomsTableBody";
import RoomsTableHead from "./RoomsTableHead";
import RoomsTableSkeleton from "./RoomsTableSkeleton";
import classes from "./style/RoomsTableContainer.module.scss";
import {createRoomsComparator, defaultRoomsSort, deleteRoom, RoomsHeadCellType, RoomsSort, toggleRoomActiveState} from "./utils";

const fuseOptions: FuseOptions<RoomDataFragment> = {
    keys: ["sessionTemplate.name"],
    threshold: 0.5,
    shouldSort: true,
};

const tableColumns: RoomsHeadCellType = [
    {id: "name", label: "Name", type: "title", isSearchInput: true, sortable: true},
    {id: "active", label: "Status", type: "roomState", sortable: true},
    {id: "owner", label: "Owner", type: "string", sortable: true},
    {id: "createdAt", label: "Created", type: "date", sortable: true},
    {id: "enterRoom", label: "", type: "enterRoom"},
    {id: "copyLink", label: "", type: "copyLink", size: "medium"},
];

function RoomsTableMain() {
    const [searchTerm, setSearchTerm] = useState("");
    const [sort, setSort] = useState<RoomsSort>(defaultRoomsSort);
    const [selectedRoomToDelete, setSelectedRoomToDelete] = useState<string | null>(null);
    const [selectedRoomToEnable, setSelectedRoomToEnable] = useState<string | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openActiveStateDialog, setOpenActiveStateDialog] = useState(false);
    const [ownerFilter, setOwnerFilter] = useState<string[]>([]);

    const userSeenOnboardingRoomsWelcome = useSeenSection("GLOBALS.userOnboardingRoomsWelcome");
    const userSeenOnboardingRoomsCreate = useSeenSection("GLOBALS.userOnboardingRoomsCreate");

    const {canCreateRooms} = useWorkspaceAccess();

    const comparator = useMemo(() => createRoomsComparator(sort), [sort]);

    const user = useUserInfo();

    const {loading, data: roomsQuery} = useQuery(MyRoomsDocument, {
        fetchPolicy: "cache-and-network",
        variables: {
            workspaceId: user.activeWorkspace?.id,
        },
    });

    const rooms = roomsQuery?.rooms ?? [];

    // memoizing this to prevent an infinite loop caused by fuse
    const sortedSessions = useMemo(
        () =>
            rooms
                .filter((room) => {
                    return ownerFilter.length === 0 || ownerFilter.includes(room.user.id);
                })
                .sort(comparator),
        [rooms, comparator, ownerFilter]
    );
    const [filtered] = useFuseWorker(sortedSessions, searchTerm, fuseOptions);

    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
    };

    const handleToggleDeleteDialog = (id: string) => {
        setSelectedRoomToDelete(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedRoomToDelete(null);
    };

    const handleToggleActiveStateDialog = (id: string) => {
        setSelectedRoomToEnable(id);
        setOpenActiveStateDialog(true);
    };

    const handleCloseActiveStateDialog = () => {
        setOpenActiveStateDialog(false);
        setSelectedRoomToEnable(null);
    };

    const handleDeleteRoom = () => {
        setOpenDeleteDialog(false);
        if (selectedRoomToDelete) {
            setSelectedRoomToDelete(null);
            deleteRoom(selectedRoomToDelete).then((res) => {
                if (!res.data?.deleteRoom?.deleted) {
                    toast("Something went wrong when deleting the room", {type: "error"});
                }
            });
        }
    };

    const toggleRoomState = (roomId: string) => {
        const room = rooms.find((room) => room.id === roomId);
        if (room?.id) {
            toggleRoomActiveState(room.id, !room.active).then((res) => {
                if (!(res.data?.toggleRoomActiveState?.active != null)) {
                    toast(`Something went wrong when ${room.active ? "disabling" : "enabling"} the room`, {type: "error"});
                }
            });
        }
    };

    // roomId is received only when the action is `enable`
    // which doesn't need a confirmation modal
    const handleActiveStateChangeConfirmed = () => {
        setOpenActiveStateDialog(false);
        if (selectedRoomToEnable) {
            toggleRoomState(selectedRoomToEnable);
        }
    };

    useEffect(() => {
        designer.state.initializeOrResetState(null);
        designer.clearPersistentStorage();
        designer.clearSessionStorage();
        designer.clearActionsHistory();
    }, []);

    const selectedRoomForStateChange = rooms.find((room) => room.id === selectedRoomToEnable);

    const roomOwners = useMemo(
        () =>
            rooms
                .map((room) => room.user)
                .filter((user, index, self) => {
                    return self.findIndex((o) => o.id === user.id) === index;
                }),
        [rooms]
    );

    if (loading) {
        return <RoomsTableSkeleton />;
    }

    const disabledSearch = rooms?.length === 0;

    return (
        <>
            <RoomsHeader count={filtered.length} ownerFilter={ownerFilter} setOwnerFilter={setOwnerFilter} roomOwners={roomOwners} />

            <TableContainer className={classes.root}>
                <Table className={cls(classes.tableRoot, "fullh flex flex-col flex00-100 overflow-hidden")}>
                    <RoomsTableHead
                        tableColumns={tableColumns}
                        onSearch={handleSearch}
                        disabledSearch={disabledSearch}
                        searchTerm={searchTerm}
                        sort={sort}
                        onSort={setSort}
                    />
                    <RoomsTableBody
                        isSearching={searchTerm.length > 0}
                        tableColumns={tableColumns}
                        rooms={filtered}
                        handleDelete={handleToggleDeleteDialog}
                        disableRoom={handleToggleActiveStateDialog}
                        enableRoom={toggleRoomState}
                    />
                </Table>
            </TableContainer>

            <ConfirmationDialog
                key="more-options-menu-activate-room-dialog"
                title={`${selectedRoomForStateChange?.active ? "Disable room" : "Enable room"} ?`}
                content={
                    selectedRoomForStateChange?.active
                        ? `No one will be able to access your room if you disable it.\nYou can activate it again later.`
                        : "Are you sure you want to enable this room?"
                }
                cancelButton="Cancel"
                minWidth
                submitButton={`${selectedRoomForStateChange?.active ? "Disable" : "Enable"}`}
                onClose={handleCloseActiveStateDialog}
                open={openActiveStateDialog}
                submitButtonVariant="primary"
                cancelButtonVariant="plain"
                onConfirm={handleActiveStateChangeConfirmed}
                variant="warning"
            />

            <ConfirmationDialog
                key="more-options-menu-delete-room-dialog"
                title="Delete room?"
                content="You won’t be able to undo this, are you sure you want to proceed?"
                cancelButton="Cancel"
                minWidth
                submitButton="Delete"
                onClose={handleCloseDeleteDialog}
                open={openDeleteDialog}
                submitButtonVariant="destructive-secondary"
                onConfirm={handleDeleteRoom}
                variant="warning"
                isDeleteConfirmation
            />

            {!userSeenOnboardingRoomsWelcome && !userSeenOnboardingRoomsCreate && canCreateRooms() && <OnboardingRoomsWelcomeDialog />}
            {userSeenOnboardingRoomsWelcome && !userSeenOnboardingRoomsCreate && canCreateRooms() && <OnboardingRoomsCreateDialog />}
        </>
    );
}

export default RoomsTableMain;
