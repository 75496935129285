import svc from "@api/service/client";
import {GoogleFileData, isPaletteItemFile, isPaletteItemTool} from "@workhorse/api/palette";
import {GoogleDocumentType} from "@artifacts/google-docs/frontend/utils";
import {HostType} from "@generated/data";
import environment from "@generated/environment";
import {togglePalette} from "@ui/cdk/util";
import {
    createAgendaItemWithResource,
    createAgendaItemWithTool,
    createAgendaItemWithWhiteBoard,
    createNewAgenda,
    importAgenda,
} from "@workhorse/api/designer/methods";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useUploadResource} from "@workhorse/providers/UploadResourceProvider";
import {artifactPluginLinks, openExternalLink} from "../../util/links";
import {ActionCategory, categMap, FinalAction} from "./actionCategTree";
import {createGoogleDocumentArtifact, useCreateGoogleDocument} from "./commandPaletteHooks";
import {usePaletteRecurrenceConfirmationDialog} from "./CommandPaletteProviders";
import {usePaletteSearchFilters} from "./CommandPaletteResultsProvider";
import {usePaletteActionArg} from "./PaletteActionProvider";

const uploadItem = categMap.find((obj) => obj.parentId && obj.categ === ActionCategory.File);
const embedToolList = categMap.filter((obj) => obj.parentId && obj.categ === ActionCategory.Tool);

export default function PaletteExecuteAction() {
    const {actionArg} = usePaletteActionArg();
    const {setSearchFilters} = usePaletteSearchFilters();
    const {triggerUpload} = useUploadResource();
    const createGoogleDocument = useCreateGoogleDocument();
    const {isMobileOrTablet} = useMobile();
    const {onboardingType, flow, next: nextTooltip, nextFlow, completeFlow, completedFlows} = useOnboardingSession();
    const session = useSession();
    const [trackEvent] = useTrackingEvents();
    const hostType = useHostType();
    const {
        recurrenceConfirmationState,
        setRecurrenceConfirmationState,
        reset: resetRecurrenceConfirmationState,
    } = usePaletteRecurrenceConfirmationDialog();
    const {open: recurrenceConfirmationIsOpen} = recurrenceConfirmationState;

    const recurrenceInstance = session.instanceOfRecurrence?.session.id;

    const finalActionCooldown = useRef(false);
    const finalActionCooldownTimeout = useRef<NodeJS.Timeout | null>(null);

    const isEvent = hostType === HostType.Event;
    const isRooms = hostType === HostType.Rooms;

    const completedFlowsRef = useRef(completedFlows);
    completedFlowsRef.current = completedFlows;

    const handleOpenLink = (link: string) => {
        openExternalLink(link);
    };

    useEffect(() => {
        if (actionArg) {
            if (onboardingType !== SessionOnboardingType.NewUser) {
                nextTooltip({
                    actionArg,
                    delay: 4000,
                });
            } else {
                // If the user uploads a file or selects a demo file
                // Proceeds to the next step of the New User File Onboarding Flow
                let shouldAdvanceTooltip = false;

                if (actionArg?.actionId === uploadItem?.id) {
                    shouldAdvanceTooltip = true;
                } else {
                    if (actionArg?.item && isPaletteItemFile(actionArg.item)) {
                        const argItem = actionArg.item;

                        if (argItem.resourceId?.includes("onboardingResource")) {
                            shouldAdvanceTooltip = true;
                        }
                    }
                }

                if (shouldAdvanceTooltip) {
                    if (flow === "initial-flow") {
                        nextFlow({
                            nextFlow: "share-a-file",
                            nextTooltip: 2,
                            saveTooltip: true,
                            completeFlow: true,
                        });
                    }
                    nextTooltip({
                        saveTooltip: true,
                        nextTooltip: 2,
                        delay: 5500,
                    });
                }
                // Complete the New User Tool Onboarding Flow
                else if (embedToolList.some((item) => item.id === actionArg.actionId)) {
                    if (!completedFlowsRef.current.includes("initial-flow")) {
                        completeFlow({
                            flowName: "initial-flow",
                        });
                    }
                    completeFlow({
                        flowName: "embed-a-tool",
                        delay: 4000,
                    });
                }
            }

            if (actionArg.action !== FinalAction.Search) {
                if (finalActionCooldown.current) {
                    return;
                } else {
                    finalActionCooldown.current = true;
                    finalActionCooldownTimeout.current = setTimeout(() => {
                        finalActionCooldown.current = false;
                    }, 2000);
                }
            }

            switch (actionArg.action) {
                case FinalAction.Create: {
                    switch (actionArg.isTool) {
                        case true: {
                            if (
                                session.onboardingType === SessionOnboardingType.NewUser ||
                                ((session.onboardingType === SessionOnboardingType.SecondStrikeMember ||
                                    session.onboardingType === SessionOnboardingType.SecondStrikeOwner) &&
                                    actionArg.artifactTag === "flowos/my-product-tool")
                            ) {
                                // Complete the New User Tool Onboarding Flow
                                completeFlow({
                                    flowName: "embed-a-tool",
                                    delay: 4000,
                                });

                                trackEvent("onboarding-embed-my-product", {event_category: TrackingEventCategory.OnboardingActions});
                            }
                            switch (actionArg.artifactTag) {
                                case "flowos/google-docs": {
                                    //check if the user is on a cname
                                    if (environment.authService.appRoot !== environment.authService.originalAppRoot) {
                                        handleOpenLink(`${environment.authService.originalAppRoot}/resources/google-docs?cnameToast=true`);
                                        break;
                                    }
                                    createGoogleDocument(actionArg.artifactTag, GoogleDocumentType.DOCUMENT, false, session.name);
                                    break;
                                }
                                case "flowos/google-forms": {
                                    if (environment.authService.appRoot !== environment.authService.originalAppRoot) {
                                        handleOpenLink(`${environment.authService.originalAppRoot}/resources/google-forms?cnameToast=true`);
                                        break;
                                    }
                                    createGoogleDocument(actionArg.artifactTag, GoogleDocumentType.FORMS, false, session.name);
                                    break;
                                }
                                case "flowos/google-sheets": {
                                    if (environment.authService.appRoot !== environment.authService.originalAppRoot) {
                                        handleOpenLink(
                                            `${environment.authService.originalAppRoot}/resources/google-sheets?cnameToast=true`
                                        );
                                        break;
                                    }
                                    createGoogleDocument(actionArg.artifactTag, GoogleDocumentType.SPREADSHEET, false, session.name);
                                    break;
                                }
                                case "flowos/google-slides": {
                                    if (environment.authService.appRoot !== environment.authService.originalAppRoot) {
                                        handleOpenLink(
                                            `${environment.authService.originalAppRoot}/resources/google-slides?cnameToast=true`
                                        );
                                        break;
                                    }
                                    createGoogleDocument(actionArg.artifactTag, GoogleDocumentType.PRESENTATION, false, session.name);
                                    break;
                                }
                                case "flowos/blackboard": {
                                    createAgendaItemWithWhiteBoard(session.name);
                                    break;
                                }
                                case "flowos/figma": {
                                    handleOpenLink(artifactPluginLinks["flowos/figma"]);
                                    togglePalette();
                                    break;
                                }
                                case "flowos/canva": {
                                    handleOpenLink(artifactPluginLinks["flowos/canva"]);
                                    togglePalette();
                                    break;
                                }
                                case "flowos/adobexd": {
                                    handleOpenLink(artifactPluginLinks["flowos/adobexd"]);
                                    togglePalette();
                                    break;
                                }
                                case "flowos/form": {
                                    svc.resx
                                        .createResource("flowos/form/resx/Form", {
                                            name: `${session.name}`,
                                            description: "This is your new form",
                                            items: [],
                                        })
                                        .then((r) => {
                                            setTimeout(() => {
                                                handleOpenLink(`${window.origin}/resources/form/` + r.id);
                                            }, 100);
                                        });
                                    togglePalette();
                                    break;
                                }
                                default: {
                                    createAgendaItemWithTool(actionArg);
                                    break;
                                }
                            }
                            break;
                        }
                        case false: {
                            if (actionArg?.actionId === uploadItem?.id) {
                                triggerUpload();
                            }
                            if (actionArg.isNewAgenda) {
                                createNewAgenda(isMobileOrTablet, isEvent);
                            }

                            break;
                        }
                        default: {
                            break;
                        }
                    }

                    break;
                }
                case FinalAction.Search: {
                    if (actionArg.toolType) {
                        setSearchFilters((current) => {
                            return {
                                ...current,
                                toolType: actionArg.toolType ? [actionArg.toolType] : undefined,
                            };
                        });
                    }
                    break;
                }

                case FinalAction.Use: {
                    if (actionArg.isTool && actionArg.item && isPaletteItemTool(actionArg.item) && actionArg.item?.resourceId) {
                        const resourceId = actionArg.item.resourceId;
                        if (actionArg.artifactTag === "flowos/google-docs") {
                            const result = JSON.parse(window.atob(resourceId)) as GoogleFileData;
                            createGoogleDocumentArtifact(actionArg.artifactTag, GoogleDocumentType.DOCUMENT, result.id);
                            break;
                        }
                        if (actionArg.artifactTag === "flowos/google-forms") {
                            const result = JSON.parse(window.atob(resourceId)) as GoogleFileData;
                            createGoogleDocumentArtifact(actionArg.artifactTag, GoogleDocumentType.FORMS, result.id);
                            break;
                        }
                        if (actionArg.artifactTag === "flowos/google-sheets") {
                            const result = JSON.parse(window.atob(resourceId)) as GoogleFileData;
                            createGoogleDocumentArtifact(actionArg.artifactTag, GoogleDocumentType.SPREADSHEET, result.id);
                            break;
                        }
                        if (actionArg.artifactTag === "flowos/google-slides") {
                            const result = JSON.parse(window.atob(resourceId)) as GoogleFileData;
                            createGoogleDocumentArtifact(actionArg.artifactTag, GoogleDocumentType.PRESENTATION, result.id);
                            break;
                        }
                    }

                    if (
                        actionArg?.item &&
                        (isPaletteItemFile(actionArg.item) || isPaletteItemTool(actionArg.item)) &&
                        actionArg.item.resourceId &&
                        actionArg.resourceType &&
                        actionArg.artifactTag
                    ) {
                        createAgendaItemWithResource(actionArg.artifactTag, actionArg.item.resourceId, actionArg.resourceType);
                        break;
                    }

                    if (actionArg.agendaId) {
                        if (recurrenceInstance && !recurrenceConfirmationIsOpen) {
                            setRecurrenceConfirmationState({
                                open: true,
                                action: importAgenda,
                                args: [actionArg.agendaId, {isEvent, isRooms}],
                            });
                        } else {
                            resetRecurrenceConfirmationState();

                            importAgenda(actionArg.agendaId, {isEvent, isRooms});
                        }
                    }

                    break;
                }
                default: {
                    break;
                }
            }
        }
        if (!actionArg?.action || actionArg?.action !== FinalAction.Search) {
            setSearchFilters((current) => {
                return current.toolType
                    ? {
                          ...current,
                          toolType: undefined,
                      }
                    : current;
            });
        }
    }, [actionArg, triggerUpload, isEvent, recurrenceInstance, recurrenceConfirmationIsOpen]);

    useEffect(() => {
        return () => {
            if (finalActionCooldownTimeout.current) {
                clearTimeout(finalActionCooldownTimeout.current);
            }
        };
    }, []);

    return <></>;
}
