import {SessionFlags, SessionLifecycle} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useMemo} from "@workhorse/api/rendering";
import RecordingSettings from "@workhorse/components/CreatePlannedSession/components/RecordingSettings";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import commonClasses from "../eventCommons.module.scss";

type EventRecordingProps = {
    isRoom?: boolean;
    onChange?: () => void;
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
};

export function EventRecording(props: EventRecordingProps) {
    const {isRoom, sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed} = props;
    const session = useSession();

    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => (!isRoom ? [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked : false),
        [session.lifecycle, isLocked]
    );

    const handleOnChange = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey]) => {
        lockEvent({
            variables: {
                id: session.id,
            },
        });

        designer.api.session.update({
            [key]: value,
        });
        props.onChange?.();

        designer.commit();
    };

    return (
        <RecordingSettings
            autoRecording={session.autoRecording}
            fullSizeVideosInRecording={session.fullSizeVideosInRecording}
            handleOnChange={handleOnChange}
            isMobile={false}
            sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
            setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
            recordingConferenceView={session.recordingConferenceView}
            recordingPresentationView={session.recordingPresentationView}
            disabled={eventNotUpdatable}
            isEventSection={true}
            parentClassName={commonClasses.mainContainer}
            title={
                <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-24">
                    Recording
                </Typography>
            }
        />
    );
}
