import {Maybe} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import {useEffect, useState} from "@workhorse/api/rendering";
import classes from "./styles/LobbyTimeLeftCounter.module.scss";
import {useTranslation} from "react-i18next";

type LobbyTimeLeftCounterProps = {
    countdownDate: Maybe<Date> | undefined;
};

const LobbyTimeLeftCounter = (props: LobbyTimeLeftCounterProps) => {
    const {t} = useTranslation();

    const [timeLeft, setTimeLeft] = useState<{d: number; h: number; m: number; s: number}>({d: 0, h: 0, m: 0, s: 0});

    const days = t("g.time.days").toUpperCase();
    const hours = t("g.time.hrs").toUpperCase();
    const minutes = t("g.time.min").toUpperCase();
    const seconds = t("g.time.sec").toUpperCase();

    useEffect(() => {
        const countdownDate = props.countdownDate?.getTime();
        const countdownTimer = setInterval(function () {
            const now = new Date().getTime();

            if (countdownDate) {
                const distance = countdownDate - now;

                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setTimeLeft({d: days > 0 ? days : 0, h: hours > 0 ? hours : 0, m: minutes > 0 ? minutes : 0, s: seconds > 0 ? seconds : 0});
            }
        }, 1000);

        return () => clearInterval(countdownTimer);
    }, [props.countdownDate]);

    return (
        <div className={classes.lobbyCounterContainer}>
            {timeLeft.d > 0 ? (
                <div className={classes.lobbyCounterCell}>
                    <Typography className={classes.value}>{timeLeft.d}</Typography>
                    <Typography className={classes.unit}>{days}</Typography>
                </div>
            ) : null}
            <div className={classes.lobbyCounterCell}>
                <Typography className={classes.value}>{timeLeft.h}</Typography>
                <Typography className={classes.unit}>{hours}</Typography>
            </div>
            <div className={classes.lobbyCounterCell}>
                <Typography className={classes.value}>{timeLeft.m}</Typography>
                <Typography className={classes.unit}>{minutes}</Typography>
            </div>
            <div className={classes.lobbyCounterCell}>
                <Typography className={classes.value}>{timeLeft.s}</Typography>
                <Typography className={classes.unit}>{seconds}</Typography>
            </div>
        </div>
    );
};

export default LobbyTimeLeftCounter;
