import {updateUnreadMessagesCount} from "@artifacts/chat/frontend/utils";
import {updateNewQuestionsCount} from "@artifacts/qa/frontend/utils";
import {EndSessionDocument, EndBackstageDocument} from "@generated/data";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@ui/cdk/Typography";
import Menu from "@ui/core/components/Menu";
import MenuItem from "@ui/core/components/MenuItem";
import {useMutation} from "@workhorse/api/data";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {assistantActionAllowed} from "@workhorse/api/user";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {disableNativePopup} from "@workhorse/components/LeavePageNative";
import {useFullScreenState} from "@workhorse/providers/state";
import {exitFullScreen} from "@workhorse/util";
import {ReactComponent as BaggageIcon} from "../../../../assets/media/baggage.svg";
import classes from "./styles/LeaveSessionButton.module.scss";
import {cls} from "@ui/cdk/util/util";
import {enableEndSessionModal} from "@workhorse/components/header/headerUtils";
import {useUserInfo} from "@workhorse/providers/User";
import {OnboardingFooter} from "@workhorse/components/onboarding-tooltips";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {ReactComponent as ExitToAppIcon} from "./exit-to-app.svg";
import LocalParticipantControlMenu from "./LocalParticipantControlMenu";
import apollo from "@workhorse/api/apollo";
import {useTranslation} from "react-i18next";
import {submittedVar} from "../../pre-join-screen/PreJoinScreenEntry";

type LeaveSessionButtonProps = {
    isAdmin: boolean;
    isAssistant: boolean;
    sessionId: string;
    participantId: string;
    className?: string;
    isWelcomeSession?: boolean;
    isBorChild?: boolean;
    parentSessionId?: string;
    open?: boolean;
    isMobile: boolean;
    parentParticipantId?: string;
    showTooltip: (e?: any, message?: string) => void;
    disableEnd?: boolean;
    backstage?: boolean;
};

export default function LeaveSessionButton(props: LeaveSessionButtonProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const {isAdmin, sessionId, isWelcomeSession, isAssistant, isMobile, showTooltip, isBorChild, parentSessionId, disableEnd, backstage} =
        props;

    const {onboardingType, tooltip, next} = useOnboardingSession();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const assistant = assistantActionAllowed(["session.endForAll"]);

    const close = () => {
        disableNativePopup(false);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.open === false) {
            close();
        }
    }, [props.open]);

    const {isGuest} = useUserInfo();
    const isFullScreen = useFullScreenState();

    const [endSessionMutation] = useMutation(EndSessionDocument);
    const [endBackstageMutation] = useMutation(EndBackstageDocument);

    const endSession = () => {
        endSessionMutation({variables: {sessionId: isBorChild ? parentSessionId ?? "" : sessionId}})
            .then((res) => {
                if (!res) {
                    toast("Problem ending session!");
                    console.error(`Problem ending session!`);
                } else {
                    updateUnreadMessagesCount(sessionId, undefined, true);
                    updateNewQuestionsCount(true);

                    history.push({
                        pathname: `/feedback/${parentSessionId ?? sessionId}`,
                        state: {
                            isParticipantInSession: true,
                        },
                    });
                }
            })
            .catch((err) => {
                toast("Problem ending session!");
                console.error(`Problem ending session: ${err}`);
            });
    };

    const endBackstage = async () => {
        await endBackstageMutation({
            variables: {
                sessionId,
            },
        })
            .then((res) => {
                if (!res) {
                    toast("Problem ending backstage!");
                    console.error(`Problem ending backstage!`);
                } else {
                    updateUnreadMessagesCount(sessionId, undefined, true);
                    updateNewQuestionsCount(true);
                    submittedVar(false);
                }
            })
            .catch((err) => {
                toast("Problem ending backstage!");
                console.error(`Problem ending backstage: ${err}`);
            });
    };

    const endForMe = () => {
        disableNativePopup(true);
        updateUnreadMessagesCount(sessionId, undefined, true);
        updateNewQuestionsCount(true);

        if (!isGuest) {
            if (parentSessionId) {
                apollo.cache.evict({id: parentSessionId});
                apollo.cache.gc();
            }
        }

        setTimeout(() => {
            if (backstage) {
                apollo.cache.evict({id: sessionId});
                if (parentSessionId) {
                    apollo.cache.evict({id: parentSessionId});
                }
                apollo.cache.gc();
                history.push(`/`);
                return;
            }

            if (isGuest) {
                history.push({
                    pathname: `/feedback/${parentSessionId ?? sessionId}/${props.parentParticipantId ?? props.participantId}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });
            } else {
                history.push({
                    pathname: `/feedback/${parentSessionId ?? sessionId}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });
            }
        }, 0);
    };

    const endForAll = () => {
        disableNativePopup(true);
        updateUnreadMessagesCount(sessionId, undefined, true);
        updateNewQuestionsCount(true);

        if (isFullScreen) {
            exitFullScreen();
        }

        if (backstage) {
            endBackstage();
        } else {
            endSession();
        }
    };

    const hasEndSessionOptions =
        (isAdmin || (isAssistant && assistant.canSessionEndForAll)) && onboardingType !== SessionOnboardingType.NewUser;

    const handleTogglerClick = (event: React.MouseEvent<HTMLElement>) => {
        if (tooltip === "footer-tools" || tooltip === "end-session") {
            next();
        }

        showTooltip();

        if (isAdmin || (isAssistant && assistant.canSessionEndForAll)) {
            enableEndSessionModal(true);
        } else {
            setOpenDialog((prev) => !prev);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    // const onMouseEnter = (e: any) => {
    //     const message = isAdmin ? "End session" : "Close session";
    //     showTooltip(e, message);
    // };

    // const onMouseLeave = () => {
    //     showTooltip();
    // };

    const options = [
        <MenuItem key="end-for-all" className={`flex-start red`} onClick={endForAll}>
            <Typography fontWeight="bold">
                {backstage ? t("participant.leave_session.end_backstage_for_all") : t("participant.leave_session.end_session_for_all")}
            </Typography>
        </MenuItem>,
        <MenuItem key="leave-session" className={`flex-start red`} onClick={endForMe}>
            <Typography fontWeight="bold">
                {backstage ? t("participant.leave_session.leave_backstage") : t("participant.leave_session.leave_session")}
            </Typography>
        </MenuItem>,
    ];

    const Toggler = (
        <MenuItem
            key="toggler"
            data-id="player-button-leave-session"
            className={cls("flex flex-wrap", props.className, "red")}
            onClick={handleTogglerClick}
            role="button"
            aria-label={t("participant.leave_session.aria.toggle_leave_session_dialog") ?? "Toggle leave session dialog"}
            tabIndex={0}
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
        >
            <ExitToAppIcon key="call-end" />
        </MenuItem>
    );

    const EndSessionMenu = [
        <MenuItem key="only-for-me" data-id="end-for-me" onClick={endForMe}>
            <PersonIcon fontSize="small" className="red" />
            {t("participant.leave_session.only_for_me") ?? "Only for me"}
        </MenuItem>,
        <MenuItem disabled={disableEnd} key="for-everyone" data-id="end-for-everyone" onClick={endForAll}>
            <PeopleIcon fontSize="small" className="red" />
            {t("participant.leave_session.for_everyone") ?? "For everyone"}
        </MenuItem>,
        isMobile ? (
            <MenuItem key=" cancel" onClick={close} button className={classes.cancelBtn}>
                <Typography>{t("g.cancel") ?? "Cancel"}</Typography>
            </MenuItem>
        ) : null,
    ];
    const [openDialog, setOpenDialog] = useState(false);

    const isGuestInNewOnboarding = onboardingType === SessionOnboardingType.NewUser && !(isAdmin || isAssistant);

    return (
        <>
            {!isAdmin &&
            !(isAssistant && assistant.canSessionEndForAll) &&
            (onboardingType !== SessionOnboardingType.NewUser || isGuestInNewOnboarding) ? (
                <ConfirmationDialog
                    submitButton={t("participant.leave_session.leave_session") ?? "Leave session"}
                    submitButtonVariant="destructive-secondary"
                    title={t("confirm.leave_this_session")}
                    Icon={BaggageIcon}
                    onConfirm={isWelcomeSession ? endForAll : endForMe}
                    cancelButton={t("g.cancel") ?? "Cancel"}
                    cancelButtonVariant="quaternary"
                    content={t("confirm.can_rejoin_session_from_link") ?? "You can rejoin the session from the link."}
                    key="participant-leave-confirmation-dialog"
                    onClose={onCloseDialog}
                    open={openDialog}
                />
            ) : null}
            <Menu
                key="end-session-actions"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: isMobile ? "left" : "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: isMobile ? "left" : "center",
                }}
                className={classes.leaveSessionMenu}
                classes={{
                    paper: classes.menuPaper,
                    list: classes.menuList,
                }}
            >
                {EndSessionMenu}
            </Menu>
            <OnboardingFooter>
                {hasEndSessionOptions ? <LocalParticipantControlMenu options={options}>{Toggler}</LocalParticipantControlMenu> : Toggler}
            </OnboardingFooter>
        </>
    );
}
