import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {forwardRef} from "react";
import {Link as LinkRouter} from "react-router-dom";
import styles from "./style/link.module.scss";
import {LinkProps} from "./types";
import {cls, capitalize} from "@ui/cdk/util/util";
import {openExternalLink} from "@workhorse/util/links";

function LinkWithRef(props: LinkProps, ref?: any) {
    const {
        /**
         * @param {Node | Node[]} children The children of the component.
         */
        children,
        /**
         * @param {LinkColorTypes} color The color of the component. @default "blue"
         */
        color = "blue",
        /**
         * @param {LinkUnderlineTypes} underline Controls when the component should have an underline. @default "hover"
         */
        underline = "hover",
        /**
         * @param {String} href The path of the anchor.
         */
        href,
        /**
         * @param {String} href The path of the link.
         */
        to,
        /**
         * @param {LinkSizeTypes} size The size of the component. @default "medium"
         */
        size = "medium",
        /**
         * @param {Boolean} icon Adds the open in new icon.
         */
        icon,
        /**
         * @param {Boolean} disabled Disables the link and adds styling to it.
         */
        disabled,
        /**
         * @param {String} className Adds a className to the root of the component.
         */
        className,
        ...rest
    } = props;

    const classNames = cls(
        "fosLink",
        styles[`underline${capitalize(underline)}`],
        styles[`color${capitalize(color)}`],
        styles[`size${capitalize(size)}`],
        styles.root,
        disabled && styles.disabled,
        className
    );

    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
        if (href?.startsWith("http")) {
            e.preventDefault();
            openExternalLink(href);
        }
        rest.onClick?.(e);
    };

    return href ? (
        <a className={classNames} href={href} ref={ref} {...rest} tabIndex={disabled ? -1 : 0} onClick={handleOnClick} key="link-href">
            {icon && <OpenInNewRoundedIcon className={styles.icon} key="link-href-icon" />}
            {children}
        </a>
    ) : to ? (
        <LinkRouter className={classNames} to={to} ref={ref} {...rest} tabIndex={disabled ? -1 : 0} key="link-to">
            {icon && <OpenInNewRoundedIcon className={styles.icon} key="link-to-icon" />}
            {children}
        </LinkRouter>
    ) : (
        <div className={classNames} ref={ref} {...rest} tabIndex={disabled ? -1 : 0} key="link-div" onClick={handleOnClick}>
            {icon && <OpenInNewRoundedIcon className={styles.icon} key="link-to-icon" />}
            {children}
        </div>
    );
}

const Link = forwardRef(LinkWithRef) as (props: LinkProps & {ref?: any}) => ReturnType<typeof LinkWithRef>;

export {LinkWithRef};
export default Link;
