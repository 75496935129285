import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {useMemo, useRef, useState} from "@workhorse/api/rendering";
import classes from "./style/MySpeakersDialog.module.scss";
import {useQuery} from "@apollo/client";
import {
    ContactInfoFragment,
    SpeakerInfoFragment,
    GetSpeakersHistoryDocument,
    SessionParticipantFragment,
    SpeakerDetailsFragment,
} from "@generated/data";
import Button from "@ui/cdk/Button";
import Loading from "@workhorse/components/Loading";
import {getCorrectPlural} from "@workhorse/util/strings";
import MySpeakersTable from "./MySpeakersTable";
import {emptySpeakerDetailsObj, SpeakerDisplayType} from "./utils";
import {useParticipants} from "@workhorse/providers/SessionDataProviders";
import {makeId} from "@workhorse/api/designer/lib/utils";

type MySpeakersDialogProps = {
    eventSpeakers: SpeakerDisplayType[];
    teamMembers: SessionParticipantFragment[];
    onClose: () => void;
    openNewSpeaker: () => void;
    addSpeaker: (
        contacts: ContactInfoFragment[],
        emails: string[],
        speakers: SpeakerDetailsFragment[]
    ) => boolean | Promise<boolean | undefined> | undefined;
};

function MySpeakersDialog(props: MySpeakersDialogProps) {
    const {eventSpeakers, teamMembers, onClose, openNewSpeaker, addSpeaker} = props;

    const {data, loading} = useQuery(GetSpeakersHistoryDocument, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const participants = useParticipants();

    const eventSpeakerParticipantEmails = useMemo(() => eventSpeakers.map((s) => s.email), [eventSpeakers]);

    const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);

    const [disabled, setDisabled] = useState<boolean>(false);

    const dialogRef = useRef<DialogImperativeRef>();

    const speakerHistory = data?.getSpeakersHistory ?? [];

    const speakers: SpeakerDetailsFragment[] = useMemo(() => {
        const teamMembersFiltered: SpeakerDetailsFragment[] = participants
            .filter(
                (obj, index, arr) =>
                    obj.dataWithNullableEmail.email &&
                    !eventSpeakerParticipantEmails.includes(obj.dataWithNullableEmail.email) &&
                    index === arr.findIndex((o) => obj.dataWithNullableEmail.email === o.dataWithNullableEmail.email)
            )
            .map((p) => ({
                id: p.speakerDetails?.id ?? makeId(),
                participantId: p.id,
                name: `${p.dataWithNullableEmail?.firstName} ${p.dataWithNullableEmail?.lastName}`.trim(),
                photoUrl: p.dataWithNullableEmail?.avatar ?? "",
                email: p.dataWithNullableEmail?.email ?? p.speakerDetails?.email,
                bio: "",
                title: "",
                company: "",
                website: "",
                socialLinks: [],
                sessions: [],
                ...p.speakerDetails,
            }));

        const speakersHistoryFiltered = speakerHistory.filter(
            (obj, index, arr) =>
                obj.email && !eventSpeakerParticipantEmails.includes(obj.email) && index === arr.findIndex((o) => obj.email === o.email)
        );

        return [...teamMembersFiltered, ...speakersHistoryFiltered];
    }, [teamMembers, speakerHistory, eventSpeakerParticipantEmails]);

    const handleAddOrRemoveSpeakers = async () => {
        setDisabled(true);

        const speakersToAdd = speakers.filter((s) => selectedSpeakers.includes(s.id));
        // .map((s) => {
        //     const existingParticipant = participants.find((p) => p.dataWithNullableEmail.email === s.email);

        //     return {
        //         ...s,
        //         ...existingParticipant,
        //         speakerDetails: {
        //             ...emptySpeakerDetailsObj(),
        //             participantId: existingParticipant?.id ?? s?.id,
        //             name: s.name,
        //             photoUrl: s.photoUrl ?? "",
        //         },
        //     };
        // });

        if (speakersToAdd.length) {
            await addSpeaker([], [], speakersToAdd as SpeakerDetailsFragment[]);
        }

        onClose();
    };

    return (
        <Dialog open imperativeRef={dialogRef} classes={{paper: classes.root}} onClose={onClose}>
            <div className={classes.header}>
                <div className="flex flex-items-center mb-6">
                    <Typography color="primary" variant="xl" fontWeight="bolder">
                        My speakers
                    </Typography>
                    {loading ? null : (
                        <Typography variant="sm" color="secondary" fontWeight="bold" className={classes.count}>
                            {speakers.length} {getCorrectPlural("speaker", speakers.length)}
                        </Typography>
                    )}
                </div>
                <Typography color="nonary">These are all speakers invited to your previous events</Typography>
            </div>

            {loading ? (
                <Loading relative className="my-40" />
            ) : (
                <MySpeakersTable
                    speakers={speakers}
                    openNewSpeaker={openNewSpeaker}
                    selectedSpeakers={selectedSpeakers}
                    setSelectedSpeakers={setSelectedSpeakers}
                />
            )}
            <div className={classes.footer}>
                <Button variant="plain" onClick={onClose} data-id="cancel-my-speaker">
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={handleAddOrRemoveSpeakers}
                    disabled={disabled || !selectedSpeakers.length}
                    loading={disabled}
                    data-id="add-my-speakers"
                >
                    Add speakers
                </Button>
            </div>
        </Dialog>
    );
}

export default MySpeakersDialog;
