import clientEvents from "@api/events/client";
import {DownloadStatus, getDesktopApiOrThrow, isDesktopApp} from "@sessions/desktop-api";
import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";

interface DownloadStore {
    items: DownloadStatus[];
    clearFile: (id: string) => void;
    downloadFile: (url: string, options?: DownloadFileOptions) => Promise<void>;
    downloadRecording: (url: string, options?: DownloadFileOptions) => Promise<void>;
    showFileFolder: (path: string) => Promise<void>;
}

interface DownloadFileOptions {
    fileName?: string;
    method?: string;
    mode?: RequestMode;
    referrerPolicy?: ReferrerPolicy;
    location?: string;
}

export function extractFilenameFromUrl(url: string): string {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
    return filename.split("?")[0];
}

export async function downloadFileWeb(url: string, options?: DownloadFileOptions): Promise<void> {
    const fileName = options?.fileName || extractFilenameFromUrl(url);
    const method = options?.method || "get";
    const mode = options?.mode;
    const referrerPolicy = options?.referrerPolicy || "no-referrer";

    try {
        const res = await fetch(url, {method, mode, referrerPolicy});
        const blob = await res.blob();
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName);
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
    } catch (error) {
        console.log("download file error", url, options, error);
    }
}

export async function downloadRecordingWeb(url: string): Promise<void> {
    try {
        const iframe = document.createElement("iframe");
        iframe.style.visibility = "collapse";
        iframe.style.display = "none";
        document.body.append(iframe);
        iframe.contentDocument?.write(`<form action="${`${url}`.replace(/\"/g, '"')}" method="GET"></form>`);
        iframe.contentDocument?.forms[0].submit();
        setTimeout(() => iframe.remove(), 2000);
    } catch (error) {
        console.log("download file error", url, error);
    }
}

const useDownloadStore = create<DownloadStore>((set, get) => {
    const intervalMap = new Map<string, NodeJS.Timeout>();

    const scheduleStatusUpdate = (id: string) => {
        const interval = setInterval(async () => {
            processStatus(id);
        }, 32);

        intervalMap.set(id, interval);
    };

    const cancelStatusUpdate = (id: string) => {
        const interval = intervalMap.get(id);
        if (interval) {
            clearInterval(interval);
            intervalMap.delete(id);
        }
    };

    const processStatus = async (id: string) => {
        try {
            const api = getDesktopApiOrThrow();
            const newStatus = await api.getDownloadStatus(id);

            if (newStatus == null) {
                cancelStatusUpdate(id);
                return;
            }

            const items = get().items;
            const index = items.findIndex((item) => item.id === id);

            if (index === -1) {
                cancelStatusUpdate(id);
                return;
            }

            const copy = [...items];
            copy[index] = newStatus;

            if (newStatus.completed || newStatus.cancelled || newStatus.error) {
                cancelStatusUpdate(id);
            }

            set({
                items: copy,
            });
        } catch (error) {
            cancelStatusUpdate(id);
            console.log("desktop download file error", id, error);
        }
    };

    const downloadFile = async (url: string, options?: DownloadFileOptions) => {
        if (!isDesktopApp()) {
            await downloadFileWeb(url, options);
            return;
        }

        try {
            const api = getDesktopApiOrThrow();
            const status = await api.startDownload(url, {
                fileName: options?.fileName,
            });

            set((state) => {
                return {
                    items: [...state.items, status],
                };
            });

            clientEvents.emit("file-downloading", status.id, options?.location);

            scheduleStatusUpdate(status.id);
        } catch (error) {
            console.log("desktop download file error", url, options, error);
        }
    };

    const downloadRecording = async (url: string, options?: DownloadFileOptions) => {
        if (!isDesktopApp()) {
            await downloadRecordingWeb(url);
            return;
        }

        try {
            const api = getDesktopApiOrThrow();
            const status = await api.startDownload(url, {
                fileName: options?.fileName,
            });

            set((state) => {
                return {
                    items: [...state.items, status],
                };
            });

            clientEvents.emit("file-downloading", status.id, options?.location);

            scheduleStatusUpdate(status.id);
        } catch (error) {
            console.log("desktop download file error", url, options, error);
        }
    };

    const showFileFolder = async (path: string) => {
        if (!isDesktopApp()) {
            console.log("open file folder not supported on web");
            return;
        }

        try {
            const api = getDesktopApiOrThrow();
            await api.showItemInFolder(path);
        } catch (error) {
            console.log("desktop open file folder error", path, error);
        }
    };

    const clearFile = (id: string) => {
        set((state) => {
            cancelStatusUpdate(id);
            return {
                items: state.items.filter((item) => item.id !== id),
            };
        });
    };

    return {
        items: [],
        clearFile,
        downloadFile,
        showFileFolder,
        downloadRecording,
    };
});

export function useDownloadFile() {
    return useDownloadStore((state) => state.downloadFile);
}

export function useDownloadRecordingFile() {
    return useDownloadStore((state) => state.downloadRecording);
}

export function useDownloadClear() {
    return useDownloadStore((state) => state.clearFile);
}

export function useDownloadItems() {
    return useDownloadStore((state) => state.items);
}

export function useDownloadIds() {
    return useDownloadStore(useShallow((state) => state.items.map((item) => item.id)));
}

export function useDownloadStatus(id: string) {
    return useDownloadStore((state) => state.items.find((item) => item.id === id));
}

export function useShowFileFolder() {
    return useDownloadStore((state) => state.showFileFolder);
}
