import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useRef} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import {SessionSettingsSection} from "../definitions";
import styles from "../styles/SessionSettingsSectionItem.module.scss";
type SessionSettingsSectionItemProps = SessionSettingsSection &
    JSX.IntrinsicElements["button"] & {
        selected: boolean;
        touched: boolean;
        error?: string;
        handleClick: () => void;
    };

export const SessionSettingsSectionItem = ({
    translateKey,
    kind,
    title,
    notice,
    component,
    icon: Icon,
    selected,
    touched,
    error,
    show,
    settingsStateCheck,
    className,
    style,
    handleClick,
    ...rest
}: SessionSettingsSectionItemProps) => {
    const {t} = useTranslation();

    const ref = useRef<HTMLButtonElement>(null);

    return (
        <button
            {...rest}
            ref={ref}
            className={cls(styles.root, className)}
            style={{
                ...style,
            }}
            onClick={handleClick}
        >
            <div
                data-id={title.replace(/\s/g, "-").toLowerCase()}
                className={cls(styles.item, selected && styles.itemSelected, touched && styles.itemTouched, error && styles.itemError)}
            >
                <Icon className={styles.icon} />
                <Typography fontWeight="bold">{t(`player.settings.${translateKey}.title`) || title}</Typography>
            </div>

            {!!error && <div className={styles.error}>{error}</div>}
        </button>
    );
};
