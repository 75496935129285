import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {useCallback, useMemo} from "@workhorse/api/rendering";
import {useReactiveVar} from "@apollo/client";
import {offerShow} from "@workhorse/pages/player/playerStateVars";
import {GetSessionOffersDocument, Offer} from "@generated/data";
import apollo from "@workhorse/api/apollo";

type OffersSubscriptionsProps = {
    isMemoryMode: boolean;
    sessionId: string;
};

const OffersSubscriptions = (props: OffersSubscriptionsProps) => {
    const {isMemoryMode = false, sessionId} = props;

    const onData = useCallback((data: SubscriptionPayload<"OnShowOfferDocument">) => {
        if (data?.onShowOffer) {
            if (data?.onShowOffer?.offer?.length && !data?.onShowOffer?.list) {
                offerShow({offer: data.onShowOffer.offer?.[0] as Offer});
            }

            if (!data?.onShowOffer?.offer?.length) {
                offerShow(null);
            }

            if (data?.onShowOffer?.list && data?.onShowOffer?.offer?.length) {
                for (const offer of data?.onShowOffer?.offer) {
                    if (offer?.sessionId) {
                        const existing = apollo.client.readQuery({
                            query: GetSessionOffersDocument,
                            variables: {
                                sessionId: sessionId,
                            },
                        });

                        const isAlreadyInCache = existing?.sessionOffers?.some((x) => x.id === offer?.id);

                        const newOffer = {
                            ...offer,
                            __typename: "Offer",
                        };

                        apollo.client.writeQuery({
                            query: GetSessionOffersDocument,
                            variables: {
                                sessionId: sessionId,
                            },
                            data: {
                                ...existing,
                                sessionOffers: isAlreadyInCache
                                    ? existing?.sessionOffers?.map((curOffer) => (curOffer.id === offer?.id ? newOffer : curOffer))
                                    : [...(existing?.sessionOffers ?? []), newOffer],
                            },
                        });
                    } else {
                        const existing = apollo.client.readQuery({
                            query: GetSessionOffersDocument,
                            variables: {
                                sessionId: sessionId,
                            },
                        });

                        apollo.client.writeQuery({
                            query: GetSessionOffersDocument,
                            variables: {
                                sessionId: sessionId,
                            },
                            data: {
                                ...existing,
                                sessionOffers: existing?.sessionOffers?.filter((curOffer) => curOffer.id !== offer?.id),
                            },
                        });
                    }
                }
            }
        }
    }, []);

    return (
        <>
            {useMemo(
                () =>
                    sessionId && !isMemoryMode ? (
                        <GenericSubscriber
                            document="OnShowOfferDocument"
                            onData={onData}
                            options={{
                                variables: {
                                    sessionId: sessionId,
                                },
                            }}
                            key="macro-offer-subscriber"
                        />
                    ) : null,
                [sessionId, isMemoryMode, onData]
            )}
        </>
    );
};

export default OffersSubscriptions;
