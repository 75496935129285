import MicroNotificationWrapper from "../micro-notification-center/NotificationWrapper";
import DownloadNotification from "./DownloadNotification";
import classes from "./style/RightNotificationsWrapper.module.scss";

type RightNotificationsWrapperProps = {
    sessionStarted: boolean;
};

function RightNotificationsWrapper(props: RightNotificationsWrapperProps) {
    const {sessionStarted} = props;

    return (
        <div className={classes.root}>
            {sessionStarted && <MicroNotificationWrapper />}
            <DownloadNotification />
        </div>
    );
}

export default RightNotificationsWrapper;
