import ReactionsContainer from "./components/ReactionsContainer";
import classes from "./styles/ReactionsWrapper.module.scss";

type ReactionsWrapperProps = {
    sessionId: string;
    disabledNotifications: string[];
};

const ReactionsWrapper = (props: ReactionsWrapperProps) => {
    const {sessionId, disabledNotifications} = props;

    return (
        <div className={classes.root} key="reactions-wrapper">
            <ReactionsContainer sessionId={sessionId} key="reaction-center" disabledNotifications={disabledNotifications} />
        </div>
    );
};

export default ReactionsWrapper;
