import {makeStyles} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import MuiDialog from "@ui/core/components/Dialog";
import Grow from "@ui/core/components/Grow";
import IconButton from "@ui/core/components/IconButton";
import Slide from "@ui/core/components/Slide";
import React, {forwardRef, MutableRefObject, useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";

const transitions = {
    Grow: forwardRef((props: any, ref: any) => <Grow {...props} ref={ref} direction="down" />),
    Slide: forwardRef((props: any, ref: any) => <Slide {...props} ref={ref} direction="down" />),
};

export type DialogImperativeRef =
    | {
          toggle?: (newState?: boolean) => void;
          isOpen?: boolean;
      }
    | null
    | undefined;

type MuiDialogProps = ExtractProps<typeof MuiDialog>;
type DialogProps = Omit<MuiDialogProps, "open"> & {
    imperativeRef: React.MutableRefObject<DialogImperativeRef>;
    open?: boolean;
    transition?: keyof typeof transitions;
};

const useStyles = makeStyles((theme) => ({
    closeDialogButton: {
        width: 42,
        height: 42,
        position: "absolute",
        top: 10,
        right: 10,
        color: theme.main.palette.text.secondary,
        zIndex: 10,
    },

    backdrop: {
        backgroundColor: "rgba(100, 116, 139, 0.8)",
        "&.no-backdrop-blur": {
            backdropFilter: "unset",
        },
    },

    paper: {
        borderRadius: 12,
        minHeight: 100,
        minWidth: 100,
    },
}));

export type CustomDialogProps = DialogProps & {
    noBackdropBlur?: true;
    showCloseBtn?: boolean;
};

const Dialog = forwardRef((props: CustomDialogProps, ref: MutableRefObject<any>) => {
    const {
        open = false,
        imperativeRef,
        noBackdropBlur,
        onClose: onCloseProp,
        transition,
        showCloseBtn,
        disableBackdropClick,
        onEscapeKeyDown,
        onBackdropClick,
        ...other
    } = props;
    const classes = useStyles();

    const [isOpen, setOpen] = useState(open);

    const toggle = useCallback(
        (newState?: boolean) => setOpen((currentState) => (typeof newState === "boolean" ? newState : !currentState)),
        []
    );

    const onClose = useCallback(
        (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
            if (disableBackdropClick && reason === "backdropClick") {
                return;
            }
            if (onCloseProp) {
                onCloseProp(event || {}, reason || "backdropClick");
                setOpen(false);
            } else {
                setOpen(false);
            }
        },
        [props.onClose]
    );

    const closeHandler = () => {
        onClose();
    };

    const preventCloseOnOutsideClick = disableBackdropClick || props.disableEscapeKeyDown;

    useEffect(() => {
        if (!imperativeRef.current) {
            imperativeRef.current = {};
        }
        imperativeRef.current.toggle = toggle;
        imperativeRef.current.isOpen = isOpen;
        return () => {
            imperativeRef.current = undefined;
        };
    }, [isOpen]);

    return (
        <MuiDialog
            data-id="dialog"
            {...other}
            open={isOpen}
            onClose={onClose}
            data-open={isOpen}
            // disableBackdropClick={preventCloseOnOutsideClick}
            disableEscapeKeyDown={preventCloseOnOutsideClick}
            BackdropProps={{
                ...props.BackdropProps,
                className: cls(classes.backdrop, (props.BackdropProps || {}).className, noBackdropBlur && "no-backdrop-blur"),
            }}
            PaperProps={{
                ...props.PaperProps,
                className: cls(classes.paper, (props.PaperProps || {}).className),
            }}
            className={props.className}
            ref={ref}
            {...(transition
                ? {
                      TransitionComponent: transitions[transition] as MuiDialogProps["TransitionComponent"],
                  }
                : null)}
        >
            {preventCloseOnOutsideClick && !showCloseBtn ? null : (
                <IconButton
                    data-id="close-dialog"
                    className={cls(classes.closeDialogButton, "close-dialog")}
                    onClick={closeHandler}
                    aria-label="Close dialog"
                >
                    <ClearIcon />
                </IconButton>
            )}
            {props.children}
        </MuiDialog>
    );
});

Dialog.displayName = "Dialog";

export default Dialog;
