import {useState} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";
import {createPortal} from "react-dom";
import classes from "./overlay.module.scss";

export function Overlay() {
    return <div className={classes.overlay} />;
}

export function OverlayPortal(props: WithChildren) {
    const [root] = useState(() => document.getElementById("root"));

    if (!root) {
        return null;
    }

    return createPortal(props.children, root);
}
