import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {Breakpoint, makeBreakpoint, screenHeight, screenWidth} from "@workhorse/util";
import {throttle} from "throttle-debounce";

export type OnResizeCallback = (width: number, height: number, breakpoint?: Breakpoint) => void;

function useGlobalResizeObserverStore() {
    const [width, setWidth] = useState(screenWidth());
    const [height, setHeight] = useState(screenHeight());

    const onResize = useMemo(
        () =>
            throttle(150, (width: number, height: number) => {
                setWidth(width);
                setHeight(height);
            }),
        []
    );

    const [setNode] = useLocalResizeObserver(onResize);

    useEffect(() => {
        setNode(document.documentElement);
    }, []);

    return useMemo(() => {
        return {
            width,
            height,
            breakpoint: makeBreakpoint(width),
        };
    }, [width, height]);
}

const [ResizeObserverProvider, useGlobalResizeVars] = createContextProvider(
    {
        name: "ResizeObserverProvider",
        strict: true,
    },
    useGlobalResizeObserverStore
);

export function useGlobalResizeObserver(onResize: OnResizeCallback) {
    const resizeVars = useGlobalResizeVars();

    const onResizeRef = useRef(onResize);
    onResizeRef.current = onResize;

    useEffect(() => {
        onResizeRef.current(resizeVars.width, resizeVars.height, resizeVars.breakpoint);
    }, [resizeVars]);
}
/**
 * @deprecated
 * DO NOT USE
 */
export {useGlobalResizeVars};
export {ResizeObserverProvider};
