import loadable from "@workhorse/api/loadable";
import {SessionSettingsSection} from "../../definitions";
import {ReactComponent as AudioIcon} from "../assets/session-audio-icon.svg";

const AudioSection = loadable(() => import("./AudioSection"));
AudioSection.displayName = "Loadable:AudioSection";

const AudioSectionDefinition: SessionSettingsSection<"device.audio"> = {
    translateKey: "device.audio",
    kind: "device.audio",
    title: "Audio settings",
    component: AudioSection,
    icon: AudioIcon,
};

export default AudioSectionDefinition;
