import {useVisibleCategItems} from "./CommandPaletteResultsProvider";
import {CategSearchItem, categMap, categMapForSearch, isValidLink, ActionCategory, categById} from "./actionCategTree";
import {FuseOptions, useFuse} from "@workhorse/api/fuse";
import {memo, useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {PaletteActiveCateg} from "./CommandPaletteProviders";
import {usePaletteExcludedItems} from "./PaletteActionProvider";

type SearchActionsLeafProps = {
    searchStr?: string;
    activeCateg: PaletteActiveCateg;
    setPaletteActiveCateg: React.Dispatch<React.SetStateAction<PaletteActiveCateg>>;
    setRootSearchStr: React.Dispatch<React.SetStateAction<string>>;
};

const fuseOptions: FuseOptions<CategSearchItem> = {
    keys: ["searchStr"],
    threshold: 0.2,
    shouldSort: true,
    // ignoreLocation: true,
    minMatchCharLength: 1,
    includeScore: true,
    useExtendedSearch: true,
};

/**
 * IMPORTANT !
 * DO NOT REMOVE MEMOIZATION
 * ...also, carefully consider changes to the `propsAreEqualFN`
 */
export default memo(
    function CommandPaletteRootSearch(props: SearchActionsLeafProps) {
        // TDB if we should obtain all necessary states from hooks
        // and completely memoize this, though it might not have much effect
        // at least with the current approach we prevent deffered states
        const {activeCateg: activeCategory, setPaletteActiveCateg, searchStr, setRootSearchStr} = props;
        const {setVisibleItems} = useVisibleCategItems();
        const {excludedItems} = usePaletteExcludedItems();

        const activeCateg = useRef(activeCategory);
        activeCateg.current = activeCategory;

        const prevCateg = useRef(activeCategory);

        const filteredSearchItems = useMemo(() => {
            return !activeCategory.name
                ? categMapForSearch.filter((member) => {
                      return (
                          !member.excludeFromRootSearch &&
                          !categMapForSearch.find((obj) => obj.id === member.parentId)?.isHidden &&
                          !excludedItems.includes(member.id)
                      );
                  })
                : categMapForSearch.filter((member) => {
                      return !excludedItems.includes(member.id) &&
                          member.categ === activeCategory.name &&
                          member.parentId &&
                          !member.isAction &&
                          !categMapForSearch.find((obj) => obj.id === member.parentId)?.isHidden
                          ? true
                          : false;
                  });
        }, [activeCategory.id]);

        const isLink = useMemo(() => isValidLink(searchStr), [searchStr]);

        const preventSearch = useMemo(() => {
            if (isLink) {
                return true;
            }
            const children = categById(activeCategory.name, activeCategory.id)?.next;
            return children && children.length < 2;
        }, [isLink, activeCategory.name, activeCategory.id]);

        // by default, if the search returns more than one result, nothing is shown
        // searching in tools however, allows more than one result
        // do NOT adapt this for other scenarios (ask Vasi)
        const allowMoreThanOneResult = useMemo(() => {
            return activeCategory.name === ActionCategory.Tool && !activeCategory.parentId;
        }, [activeCategory.name, activeCategory.id]);

        const results = useFuse(filteredSearchItems, preventSearch ? undefined : searchStr, fuseOptions);

        const t = useRef<NodeJS.Timeout | null>(null);

        useEffect(() => {
            if (isLink) {
                setVisibleItems({
                    isCommand: true,
                    items: categMap.filter((obj) => obj.categ === ActionCategory.Link && obj.parentId).map((obj) => obj.id),
                });
            }
        }, [isLink]);

        useEffect(() => {
            if (t.current != null) {
                clearTimeout(t.current);
                t.current = null;
            }
            const {name: currentCateg, id: currentCategId} = activeCateg.current;
            let visibleItems: string[] = [];

            if (preventSearch) {
                if (isLink) {
                    return;
                }
                if (currentCateg) {
                    visibleItems = categMap
                        .filter(
                            (item) => item.categ === currentCateg && (currentCategId ? item.parentId === currentCategId : item.parentId)
                        )
                        .map((item) => item.id);
                }
                setVisibleItems({
                    isCommand: false,
                    items: visibleItems,
                });
                return;
            }

            if (!searchStr) {
                if (!currentCateg) {
                    visibleItems = categMap.filter((obj) => !excludedItems.includes(obj.id) && !obj.parentId).map((item) => item.id);
                } else {
                    const parent = categMap.find((obj) => !excludedItems.includes(obj.id) && obj.categ === currentCateg && !obj.parentId);
                    visibleItems = categMap
                        .filter(
                            (obj) =>
                                !excludedItems.includes(obj.id) &&
                                (currentCategId ? obj.parentId === currentCategId : obj.parentId === parent?.id)
                        )
                        .map((obj) => obj.id);
                }
                setVisibleItems({
                    isCommand: false,
                    items: visibleItems,
                });
                return;
            }

            if (!results.length) {
                setVisibleItems((current) =>
                    !current.isCommand && !current.items.length
                        ? current
                        : {
                              isCommand: false,
                              items: [],
                          }
                );
                return;
            }

            // console.log({results});

            // got results and a searchStr
            if (results.length === 1 || allowMoreThanOneResult) {
                const resultIds = results.map((r) => r.id);
                const visibleIds = categMap
                    .filter((obj) => !excludedItems.includes(obj.id) && resultIds.includes(obj.id))
                    .map((obj) => obj.id);
                setVisibleItems({
                    isCommand: (results.length === 1 && results[0].isAction) ?? false,
                    items: visibleIds,
                });
            } else {
                setVisibleItems((current) =>
                    !current.isCommand && !current.items.length
                        ? current
                        : {
                              isCommand: false,
                              items: [],
                          }
                );
            }
        }, [results, searchStr]);

        useEffect(() => {
            prevCateg.current = activeCategory;
        }, [activeCategory]);

        return <></>;
    }
    // (prev, next) =>
    //     prev.activeCateg.id === next.activeCateg.id &&
    //     prev.activeCateg.parentId === next.activeCateg.parentId &&
    //     prev.searchStr === next.searchStr
);
