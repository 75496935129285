import {createContextProvider} from "@workhorse/api/utils/context";
import {useState} from "@workhorse/api/rendering";
import type {aiAgendaItem} from "@workhorse/api/aiGenerator/utils";

function useAiGeneratedAgendaStore() {
    const [generatedAgenda, setGeneratedAgenda] = useState<aiAgendaItem[]>([]);
    const [selectedAgendaItems, setSelectedAgendaItems] = useState<aiAgendaItem[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const saveGeneratedAgenda = (generatedAgendaItem: aiAgendaItem | null) => {
        if (!generatedAgendaItem) {
            setGeneratedAgenda([]);
        } else {
            setGeneratedAgenda((prev) => [...prev, generatedAgendaItem]);
        }
    };

    const selectAgendaItems = (selectedAgendaItem: aiAgendaItem | null) => {
        if (!selectedAgendaItem) {
            setSelectedAgendaItems([]);
            return;
        }
        const isSelected = !!selectedAgendaItems && selectedAgendaItems?.includes(selectedAgendaItem);

        if (isSelected) {
            setSelectedAgendaItems((prev) => prev.filter((item) => item !== selectedAgendaItem));
            return;
        } else {
            setSelectedAgendaItems((prev) => [...prev, selectedAgendaItem]);
        }
    };

    const handleOnError = (error: string) => {
        setErrorMessage(error);
    };

    return {
        generatedAgenda,
        saveGeneratedAgenda,
        selectedAgendaItems,
        selectAgendaItems,
        handleOnError,
        errorMessage,
    };
}

export const [AiGeneratedAgendaProvider, useAiGeneratedAgenda] = createContextProvider(
    {
        name: "AiGeneratedAgendaProvider",
        strict: true,
    },
    useAiGeneratedAgendaStore
);
