import {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {commitUpdateAgendaTemplate} from "@workhorse/api/designer/methods";
import React, {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useDrop} from "react-dnd";
import classes from "./style/AgendaItemDropZone.module.scss";
import {useTranslation} from "react-i18next";

type AgendaDropZoneProps = {
    isDragging: boolean;
    scrollContainerRef?: React.MutableRefObject<ScrollContainerRef>;
    afterArtifactId?: string;
};

type templateAgendaItemDragType = {
    id: string;
    type: "templates-agenda-item";
    isSelected: boolean;
};

const AgendaDropZone = ({isDragging, afterArtifactId, scrollContainerRef}: AgendaDropZoneProps) => {
    const {t} = useTranslation();
    const [isScrolling, setIsScrolling] = useState(false);
    const [{canDrop, isOver}, drop] = useDrop({
        accept: "templates-agenda-item",
        drop: (item: templateAgendaItemDragType) => {
            onDrop(item);

            // onDragLeave();
        },
        collect: (monitor) => {
            return {
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver(),
            };
        },
    });

    const onDrop = (item: templateAgendaItemDragType) => {
        const movedArtifact = item.id;

        const afterArtifact = afterArtifactId;

        if (!afterArtifact) {
            designer.api.agendaItem.move({
                id: movedArtifact,
            });
        } else {
            designer.api.agendaItem.move({
                id: movedArtifact,
                afterId: afterArtifact,
            });
        }
        if (!item.isSelected) {
            designer.api.agendaItem.lock({
                id: item.id,
                locked: null,
            });
        }
        commitUpdateAgendaTemplate();
    };

    const refToScroll = useRef<HTMLDivElement | null>(null);
    const isCloseToBottom = window.innerHeight - (refToScroll?.current?.getBoundingClientRect().bottom || 0) > 200;
    const isCloseToTop = refToScroll?.current?.getBoundingClientRect().top && refToScroll?.current?.getBoundingClientRect().top > 200;
    const isVisible = isCloseToBottom && isCloseToTop;

    useEffect(() => {
        if (!isScrolling) {
            if (isOver && !isVisible) {
                setIsScrolling(true);
                const index = refToScroll?.current?.parentNode?.children
                    ? Array.from(refToScroll?.current?.parentNode?.children).findIndex((el) => {
                          return el.getAttribute("data-id") === afterArtifactId;
                      })
                    : 0;

                scrollContainerRef?.current?.scrollChildIntoView &&
                    scrollContainerRef?.current?.scrollChildIntoView({
                        index: index,
                        location: !isCloseToTop ? "start" : "end",
                        offset: !isCloseToTop ? -140 : 220,
                    });
                setTimeout(() => {
                    setIsScrolling(false);
                }, 500);
            }
        }
    }, [isOver, isVisible, isScrolling]);
    return isDragging ? null : (
        <>
            <div ref={drop} className={cls(classes.droppableZone, canDrop && classes.droppableZoneActive)}></div>
            <div ref={refToScroll} data-id={afterArtifactId} className={cls(classes.dropZonePreview, isOver && classes.visiblePreview)}>
                <Typography variant="sm" className={classes.text}>
                    {t("g.drag_here")}
                </Typography>
            </div>
            {/* <div ref={refToScroll}>scroll</div> */}
        </>
    );
};

export default memo(AgendaDropZone);
