import {createElement, forwardRef} from "react";
import {Link} from "react-router-dom";
import {cls, capitalize} from "../util/util";
import styles from "./style/typography.module.scss";
import {TypographyComponents, TypographyProps} from "./types";

function TypographyWithRef<T extends TypographyComponents = "p">(props: TypographyProps<T>, ref?: unknown) {
    const {
        /**
         * @param {React.FunctionComponent} component Component type. @default "p"
         */
        component = "p",
        /**
         * @param {TypographyVariantTypes} variant The variant of the Typography component. @default "base"
         */
        variant = "base",
        /**
         * @param {Node | Node[]} children The content of the component.
         */
        children,
        /**
         * @param {Boolean} noWrap If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis. @default false
         */
        noWrap,
        /**
         * @param {String} className Adds a className to the root of the component.
         */
        className,
        // TODO: default this to "primary" after all the jss is gone
        color,
        /**
         * @param {TypographyFontWeight} fontWeight Font weight.
         */
        fontWeight,

        textDecorationLine,

        ...rest
    } = props;

    const classNames = cls(
        "fosTypography",
        styles.root,
        variant && styles[`variant${capitalize(variant)}`],
        noWrap && styles.noWrap,
        color && styles[color],
        fontWeight && styles[fontWeight],
        textDecorationLine && styles[textDecorationLine],
        className
    );

    return createElement(
        component,
        {
            className: classNames,
            ...rest,
            ref,
            key: "typography-component",
        },
        children
    );
}

const Typography = forwardRef(TypographyWithRef) as <T extends TypographyComponents = "p">(
    props: TypographyProps<T> & {ref?: unknown}
) => ReturnType<typeof TypographyWithRef>;

export {TypographyWithRef};

export default Typography;
