import {cls} from "@ui/cdk/util/util";
import {Fragment, useContext, useEffect, useReducer} from "@workhorse/api/rendering";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";

type FooterPortalProps = {
    setClassName: (className: string) => void;
};

function FooterPortal(props: FooterPortalProps) {
    const {setClassName} = props;

    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set, unset} = useContext(InjectorContext);
    const ids = (Object.keys(existing) as Array<keyof typeof existing>).filter((x) => x !== "classNames");
    const gotComponents = ids.filter((x) => !!existing[x]).length > 0;
    const className = cls("flex flex11-auto flex-align-center", cls.apply(null, existing.classNames), gotComponents && "with-content");

    useEffect(() => {
        set("inFooter", inject);
    }, []);

    useEffect(() => {
        setClassName(className);
    }, [className]);

    return (
        <>
            {ids.map((k) => {
                return <Fragment key={k}>{existing[k]}</Fragment>;
            })}
        </>
    );
}

export default FooterPortal;
