import {cls} from "@ui/cdk/util";
import {DraggableDialog} from "@workhorse/pages/player/components/DraggableDialog";
import classes from "./styles/TimerPopup.module.scss";
import TimerBody from "./TimerBody";

type TimerPopupProps = {
    isPopupMinimized: boolean;
    setIsPopupMinimized: React.Dispatch<React.SetStateAction<boolean>>;
    onTimesUp: () => void;
    initialDuration?: number;
    timerStartAt?: Date;
    isMobile: boolean;
};

function TimerPopup(props: TimerPopupProps) {
    const {isPopupMinimized, setIsPopupMinimized, onTimesUp, timerStartAt, initialDuration, isMobile} = props;

    if (isMobile) {
        return (
            <TimerBody
                className={cls(classes.root, classes.rootMobile)}
                isPopup
                onTimesUp={onTimesUp}
                isPopupMinimized={isPopupMinimized}
                setIsPopupMinimized={setIsPopupMinimized}
                key={`timer-body-${timerStartAt}-${initialDuration}`}
            />
        );
    }

    return (
        <DraggableDialog
            id="draggable-spotlight"
            width={0}
            height={0}
            defaultPosition="top-center"
            className={cls("min-width-max-content", classes.draggable)}
            enableResizing={false}
            isSpeakerWidget={false}
            wrapperClassName={classes.draggableWrapper}
        >
            <TimerBody
                className={classes.root}
                isPopup
                onTimesUp={onTimesUp}
                isPopupMinimized={isPopupMinimized}
                setIsPopupMinimized={setIsPopupMinimized}
                key={`timer-body-${timerStartAt}-${initialDuration}`}
            />
        </DraggableDialog>
    );
}

export default TimerPopup;
