import {useAgendaItems, useMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import artifactDescriptor from "@generated/artifacts";
import React, {Fragment, useMemo} from "@workhorse/api/rendering";
import {evaluateExpression} from "@workhorse/internal/expression";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {Artifact} from "@workhorse/declarations/dataTypes";

type Ghosts = {
    [K in string]: {
        artifact: Artifact;
        ghostComponents: Array<React.ComponentType<any>>;
    };
};

type PlayerGhostsProps = {
    sessionId: string;
};

// TODO: maca do we still use ghosts ?
export default function PlayerGhosts(props: PlayerGhostsProps) {
    const agendaItems = useAgendaItems();
    const macroArtifacts = useMacroArtifacts();
    const hostType = useHostType();

    const ghosts = useMemo(() => {
        return agendaItems
            ?.filter((a) => a && a.artifact)
            .map((a) => a.artifact)
            .concat(macroArtifacts as any)
            .reduce((all, artifact) => {
                if (!artifact) {
                    return all;
                }
                const {artifactId, isMacro, id} = artifact;
                const descriptor = artifactDescriptor[artifactId];

                const ghostComponents: Array<React.ComponentType<any>> =
                    descriptor?.ghosts?.reduce((all, ghostEntry) => {
                        const isValid = evaluateExpression({
                            exp: ghostEntry.preloadCondition,
                            type: !isMacro ? "micro" : "macro",
                            artifactEvaluation: ghostEntry.filter ? ghostEntry.filter : () => true,
                            isEditMode: false,
                            hostType,
                        });
                        if (!isValid) {
                            return all;
                        }
                        return all.concat(ghostEntry.loader);
                    }, []) ?? [];

                if (ghostComponents.length == 0) {
                    return all;
                }

                return {
                    ...all,
                    [id]: {
                        ghostComponents,
                        artifact,
                    },
                };
            }, {} ?? []) as Ghosts;
    }, [agendaItems, macroArtifacts, hostType]);

    return (
        <>
            {Object.keys(ghosts).map((id) => {
                const {artifact, ghostComponents = []} = ghosts[id];
                return (
                    <Fragment key={id}>
                        {ghostComponents.map((Component, i) => {
                            const agendaItem = agendaItems?.find((agendaItem) => agendaItem.artifact?.id === artifact.id);
                            return <Component agendaItem={agendaItem} key={i} />;
                        })}
                    </Fragment>
                );
            })}
        </>
    );
}

export {};
