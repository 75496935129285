import Typography from "@ui/cdk/Typography";
import {useState} from "@workhorse/api/rendering";
import OnboardingWorkspaceDialog from "@workhorse/pages/user/onboarding/components/onboarding-workspace/OnboardingWorkspaceDialog";
import {MemberCSVDialog, MemberEditDialog} from "@workhorse/pages/user/profile/UserManagement/MemberEditDialog";

type WorkspaceMembersListEmptyProps = {
    organizationName: string | undefined | null;
    collaboratorEmails: string[];
    title: string;
    subtitle: string;
};

function WorkspaceMembersListEmpty(props: WorkspaceMembersListEmptyProps) {
    const {organizationName, collaboratorEmails, title, subtitle} = props;

    const [editDialog, setEditDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [showWorkspaceSetupDialog, setShowWorkspaceSetupDialog] = useState<boolean>(false);

    const handleWorkspaceSetupDialog = () => {
        setShowWorkspaceSetupDialog((prev) => !prev);
    };
    const handleAddMember = () => {
        if (organizationName) {
            setEditDialog(true);
        } else {
            handleWorkspaceSetupDialog();
        }
    };

    return (
        <div className={"fullw flex flex-col flex11-auto flex-center-all py-32 px-24 fullh"}>
            <Typography variant="lg" fontWeight="bold" color="secondary" className="mb-8">
                {title}
            </Typography>
            <Typography color="tertiary">{subtitle}</Typography>

            {importDialog && (
                <MemberCSVDialog open={importDialog} existingEmails={collaboratorEmails} onClose={() => setImportDialog(false)} />
            )}

            {editDialog && (
                <MemberEditDialog
                    type="workspace"
                    open={editDialog}
                    onClose={() => setEditDialog(false)}
                    onCsvImport={() => setImportDialog(true)}
                />
            )}
            {showWorkspaceSetupDialog && <OnboardingWorkspaceDialog onClose={handleWorkspaceSetupDialog} />}
        </div>
    );
}

export default WorkspaceMembersListEmpty;
