import {makeVar, useReactiveVar} from "@workhorse/api/data";
import React, {useEffect, useRef} from "@workhorse/api/rendering";
import ReactDOM from "react-dom";
import {ToastObject} from "../../api/toast";
import classes from "./styles/Toast.module.scss";
import Toast from "./Toast";

export type InjectableToastDOMNodeID = "toast-container-bottom" | "toast-container-top" | "toast-container-right" | "toast-container-left";

type ToastPosition = "top" | "right" | "bottom" | "left";

type PosToContainer = {
    [K in ToastPosition]: InjectableToastDOMNodeID;
};

const posToNode: PosToContainer = {
    bottom: "toast-container-bottom",
    top: "toast-container-top",
    left: "toast-container-left",
    right: "toast-container-right",
};

export const existingToasts = makeVar<ToastObject[]>([]);

type InjectableToastContainerProps = {
    id: string;
    children: React.ReactNode | React.ReactNode[];
};

const createToastContainer = (toastNodeId: InjectableToastDOMNodeID) => {
    const node = document.createElement("div");
    node.setAttribute("id", toastNodeId);
    const toastPosition = toastNodeId.replace("toast-container-", "");
    node.setAttribute("class", `${classes.toastContainer} ${classes[toastPosition]}`);
    document.body.appendChild(node);
};

function InjectInto(props: InjectableToastContainerProps & {domNodeId: InjectableToastDOMNodeID}) {
    const {domNodeId, id} = props;
    let node = document.getElementById(domNodeId);

    if (!node) {
        createToastContainer(domNodeId);
        node = document.getElementById(domNodeId);
    }

    return !node ? null : ReactDOM.createPortal(props.children, node, id);
}

function ToastProvider() {
    const toasts = useReactiveVar(existingToasts);

    const lastPosRemoved = useRef<ToastPosition | null>(null);

    useEffect(() => {
        for (const toastObject of toasts) {
            if (!toastObject.timeout && toastObject.duration && !toastObject.permanent) {
                toastObject.timeout = setTimeout(() => {
                    manualClose(toastObject.uid);
                }, toastObject.duration);
            }
        }

        if (lastPosRemoved.current) {
            // Check the toasts that are the same position with the one which was deleted
            const toastsOfSamePos = toasts.filter((o) => o.position === lastPosRemoved.current);

            if (toastsOfSamePos.length === 0) {
                // ...remove dom node
                document.body.removeChild(document.getElementById(posToNode[lastPosRemoved.current]) as Node);
                lastPosRemoved.current = null;
            }
        }
    }, [toasts]);

    const manualClose = (toastId: string) => {
        const currentToast = toasts.find((object) => object.uid === toastId);

        // Add the position of toast which is removed
        lastPosRemoved.current = (currentToast && (currentToast.position as ToastPosition)) || null;

        if (currentToast) {
            clearTimeout(currentToast.timeout);
        }
        existingToasts(existingToasts().filter((o) => o.uid !== toastId));
    };

    return (
        <>
            {toasts.map((toastObject) => (
                <InjectInto key={toastObject.uid} id={toastObject.uid} domNodeId={posToNode[toastObject?.position || ""]}>
                    <Toast onClose={manualClose} {...toastObject} />
                </InjectInto>
            ))}
        </>
    );
}

export default ToastProvider;
