import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util/util";
import {ComponentProps, forwardRef, useCallback, useState} from "@workhorse/api/rendering";
import {WithChildren, WithClassName, WithStyle} from "@workhorse/declarations";
import {useClickOutside} from "../../api";
import {useTranslation} from "react-i18next";

type ToolbarItemProps = WithClassName &
    WithStyle &
    WithChildren &
    ComponentProps<"button"> & {
        tooltip: string;
    } & {
        isMobile: boolean;
    };

export const ToolbarItem = forwardRef((props: ToolbarItemProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
    const {t} = useTranslation();
    const {children, tooltip, onMouseOver, onMouseLeave, isMobile, ...attr} = props;

    const [open, setOpen] = useState(false);

    const handleMouseOver = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!isMobile) {
                setOpen(true);
            }

            if (onMouseOver) {
                onMouseOver(event);
            }
        },
        [onMouseOver]
    );

    const handleMouseLeave = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setOpen(false);

            if (onMouseLeave) {
                onMouseLeave(event);
            }
        },
        [onMouseLeave]
    );

    const handleClickOutside = useCallback(() => {
        if (open) {
            setOpen(false);
        }
    }, [open]);

    useClickOutside("click", handleClickOutside);

    const title = t(`g.text_editor.${tooltip.toLowerCase().replace(/\s/g, "_")}`);

    return (
        <Tooltip title={title} disableFocusListener disableHoverListener disableTouchListener open={open}>
            <button
                data-id={tooltip.toLowerCase().replace(/\s/g, "-")}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                onMouseOut={handleMouseLeave}
                {...attr}
                className={cls("editor-toolbar-button", attr.className)}
                ref={ref}
                aria-label={title}
            >
                {children}
            </button>
        </Tooltip>
    );
});
