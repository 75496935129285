import clientEvents from "@api/events/client";
import svc from "@api/service/client";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {useEffect} from "@workhorse/api/rendering";
import {editingResource} from "../../../api";
import RandExp from "randexp";
import {useHistory} from "@workhorse/api/routing";
import {modifyResourceCounter} from "./utils";
import {ArtifactTag} from "@generated/artifacts";
import {useSeenSection} from "@workhorse/api/seen";

type CreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    isToolsSection?: boolean;
    artifactTag: ArtifactTag;
};

const SmartboardCreateForm = (props: CreateFormProps) => {
    const history = useHistory();

    const userSeenOnboardingToolUploaded = useSeenSection("GLOBALS.userOnboardingToolUploaded");

    useEffect(() => {
        const newRoomLink = new RandExp(/[0-9a-f]{20},[a-zA-Z0-9_-]{22}/).gen();

        svc.resx
            .createResource("flowos/blackboard/resx/Blackboard", {
                name: "New Whiteboard",
                roomId: newRoomLink,
            })
            .then((r) => {
                modifyResourceCounter("flowos/blackboard/resx/Blackboard", "add");
                props.handleClose();

                setTimeout(() => {
                    clientEvents.emit("click-resource-row", {
                        resourceId: r.id,
                    });

                    clientEvents.emit("new-resource-uploaded", {
                        id: r.id,
                        type: props.type,
                        artifactTag: props.artifactTag,
                    });

                    if (!props.isToolsSection) {
                        editingResource(r.id);

                        return;
                    }

                    if (userSeenOnboardingToolUploaded) {
                        history.push(`/resources/blackboard/` + r.id);
                    }
                }, 100);
            });
    }, []);

    return <></>;
};
export default SmartboardCreateForm;
