import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import NotificationWrapper from "./NotificationWrapper";
import {useSessionInfo} from "@workhorse/api/conference2";
import {cls} from "@ui/cdk/util";
import Typography from "@ui/cdk/Typography";
import classes from "./style/TranscriptInformation.module.scss";
import MacroTranscriptIcon from "@workhorse/components/MacroTranscriptIcon";
import {useTranslation} from "react-i18next";

const TranscriptInformation = () => {
    const {t} = useTranslation();

    const session = useSessionInfo();

    const isActive = session.transcriptionActive;

    const lastShownRef = useRef<"started" | "stopped" | null>(null);
    const [notification, setNotification] = useState<"started" | "stopped" | null>(null);

    const timeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const lastShown = lastShownRef.current;

        if (isActive && lastShown !== "started") {
            timeout.current && clearTimeout(timeout.current);
            lastShownRef.current = "started";
            setNotification("started");
            return;
        }

        if (lastShown === "started") {
            lastShownRef.current = "stopped";
            setNotification("stopped");

            timeout.current = setTimeout(() => {
                setNotification(null);
            }, 3000);
        }
    }, [isActive]);

    useEffect(() => {
        return () => {
            timeout.current && clearTimeout(timeout.current);
        };
    }, []);

    const handleCloseNotification = () => {
        setNotification(null);
    };

    if (notification === "started") {
        return (
            <NotificationWrapper onClose={handleCloseNotification}>
                <MacroTranscriptIcon className={cls(classes.icon, classes.iconStarted)} />
                <Typography>{t("participant.transcribing.start_notification")}</Typography>
            </NotificationWrapper>
        );
    }

    if (notification === "stopped") {
        return (
            <NotificationWrapper onClose={handleCloseNotification}>
                <MacroTranscriptIcon className={cls(classes.icon, classes.iconStopped)} />
                <Typography>{t("participant.transcribing.stop_notification")}</Typography>
            </NotificationWrapper>
        );
    }

    return null as unknown as JSX.Element;
};

export default TranscriptInformation;
