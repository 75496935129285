import env from "@generated/environment";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {useUser} from "@workhorse/components/user";
import mixpanel from "mixpanel-browser";

declare global {
    interface Window {
        gtag?: (key: string, action: string, config?: any) => void;
    }
}

export enum TrackingEventCategory {
    SessionActions = "session_actions",
    ArtifactActions = "artifact_actions",
    ContactsActions = "contacts_actions",
    GuestActions = "guest_actions",
    MemoryActions = "memory_actions",
    HomeActions = "home_actions",
    TemplateActions = "template_actions",
    BaseActions = "base_actions",
    PlayerActions = "player_actions",
    TourActions = "tour_actions",
    LoginActions = "login_actions",
    ProfileActions = "profile_actions",
    DesignerActions = "designer_actions",
    HelpActions = "help_actions",
    SyncActions = "sync_actions",
    OnboardingActions = "onboarding_actions",
}

export interface TrackingEventConfig {
    event_category?: TrackingEventCategory;
    event_label?: string;
    value?: number;
    non_interaction?: boolean;
    traits?: {[key: string]: string};
}

let userLoadedForMixpanelInit = false;

export const useTrackingEvents = () => {
    // const trackingId = env.gaMeasurementId;
    // const innerTrendsId = env.innerTrendsId;
    // const {
    //     ua: {isMobileUA},
    // } = useMobile();

    // const user = useUser();
    // const hasOrgPermissions = Array.isArray(user?.organizationPermissions);
    // const organizationData = hasOrgPermissions ? user?.activeOrganizationPermission.organization : false;
    // const organization = organizationData
    //     ? {
    //           id: organizationData.id,
    //           name: organizationData.name ?? organizationData.companyName,
    //       }
    //     : {};

    return [
        (action: string, config: TrackingEventConfig = {}) => {
            // const hasGoogleAnalytics = !!window.gtag && !!trackingId;
            // const hasInnerTrends = !!innerTrendsId;
            // if (hasGoogleAnalytics) {
            // window.gtag!("event", action, config);
            // }
        },
    ];
};

export const useMixpanelTracker = () => {
    const user = useUser();
    const isGuest = user?.role.toUpperCase() === "GUEST";

    if (env.mixpanelToken && !userLoadedForMixpanelInit && user?.id && !isGuest) {
        mixpanel.init(env.mixpanelToken, {
            api_host: "https://europe-west3-fos-sessions-dev.cloudfunctions.net/mixpanelProxy",
            persistence: "cookie",
            // cross_site_cookie: true,
            // cross_subdomain_cookie: true,
            // debug: env.environmentName !== "production" ? true : false,
            loaded: () => {
                userLoadedForMixpanelInit = true;
                mixpanel.identify(user?.id);
            },
        });
    }
    const mixpanelTrack = (eventName: string, section?: string, source?: string) => {
        if (isGuest) return;
        return mixpanel.track(eventName, {
            distinct_id: user?.id,
            section,
            source,
        });
    };

    const mixpanelPageView = (pageName: string) => {
        if (isGuest) return;
        return mixpanel.track_pageview({page: pageName});
    };

    return {mixpanelTrack, mixpanelPageView};
};

function saveToDataLayer(event: {[key: string]: any}) {
    (window as any).dataLayer.push(event);
}

export function pushDataLayerEvent(event: {[key: string]: any}) {
    if (!(window as any).dataLayer) (window as any).dataLayer = [];
    const alreadyPushed = (window as any)?.dataLayer.find((e: any) => e?.context);

    if (alreadyPushed) return;

    saveToDataLayer(event);
}

function executeGA(googleAnalyticsId) {
    window["dataLayer"] = window["dataLayer"] || [];
    window["gtag"] = function () {
        window["dataLayer"].push(arguments);
    };

    window["gtag"]("js", new Date() + "");
    window["gtag"]("config", googleAnalyticsId);
}

function executeGTM() {
    window["dataLayer"] = window["dataLayer"] || [];

    saveToDataLayer({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
    });
}

function initializeGA(googleAnalyticsId) {
    const gtaScript = document.createElement("script");
    gtaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    gtaScript.async = true;
    gtaScript.id = "sessions-ga";
    document.body.appendChild(gtaScript);

    gtaScript.onload = () => {
        executeGA(googleAnalyticsId);
    };
}

function initializeGTM(googleTagManagerId) {
    const gtmScript = document.createElement("script");
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`;
    gtmScript.async = true;
    gtmScript.id = "sessions-gtm";
    document.head.appendChild(gtmScript);

    gtmScript.onload = () => {
        executeGTM();
    };
}

function initializeProfitwell(userEmail: string) {
    window["dataLayer"] = window["dataLayer"] || [];

    saveToDataLayer({
        event: "start_pw",
        pw_user_email: userEmail,
    });
}

export const useGoogleAnalyticsTracker = () => {
    const initialRenderTimestamp = useRef<number>(new Date().getTime());

    const googleAnalyticsId = env.gaMeasurementId ?? "";

    const googleTagManagerId = env.googleTagManagerId ?? "";

    function activateGoogleAnalytics() {
        initializeGA(googleAnalyticsId);
        initializeGTM(googleTagManagerId);
    }

    useEffect(() => {
        initialRenderTimestamp.current = new Date().getTime();

        const interval = setInterval(() => {
            // Make the check for only 10s
            if (initialRenderTimestamp.current + 10000 <= new Date().getTime()) {
                clearInterval(interval);
            }

            if (typeof window !== "undefined") {
                const hasCookieBotConsent = window["Cookiebot"]?.["bulkconsent"];

                if (hasCookieBotConsent) {
                    activateGoogleAnalytics();
                    clearInterval(interval);
                }
            }
        }, 200);

        return () => {
            clearInterval(interval);
        };
    }, []);
};

export const Tracking = () => {
    // useCsdTracker();
    useMixpanelTracker();
    useGoogleAnalyticsTracker();
    return null;
};
