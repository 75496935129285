import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {useRef} from "@workhorse/api/rendering";
import {FormRenderer} from "@sessions/forms";
import classes from "./styles/ViewAnswersDialog.module.scss";
import {useQuery} from "@workhorse/api/data";
import {GetUserCountryCodeDocument} from "@generated/data";

type ViewAnswersDialogProps = {
    onClose: () => void;
    answers?: any;
    form?: any;
};

const ViewAnswersDialog = (props: ViewAnswersDialogProps) => {
    const {onClose, answers, form} = props;

    const dialogRef = useRef<DialogImperativeRef>();

    const {data} = useQuery(GetUserCountryCodeDocument);
    const userCountryCode = data?.getUserCountryCode ?? undefined;

    return (
        <Dialog
            open
            imperativeRef={dialogRef}
            classes={{paper: classes.root}}
            onClose={onClose}
            disableAutoFocus
            disableEnforceFocus
            disableScrollLock
        >
            <Typography color="primary" variant="xl" fontWeight="bolder">
                Registration form responses
            </Typography>

            <div className={classes.content}>
                <FormRenderer
                    isMobile={false}
                    open={true}
                    handleSubmit={(form: any) => {}}
                    form={form}
                    answers={answers}
                    readOnly={true}
                    userCountryCode={userCountryCode}
                />
            </div>

            <Button onClick={onClose} variant="tertiary" className={classes.button}>
                Close
            </Button>
        </Dialog>
    );
};

export default ViewAnswersDialog;
