import CloseIcon from "@material-ui/icons/Close";
import React from "@workhorse/api/rendering";
import {WithClassName} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import {MicroNotification} from "./api";
import classes from "./styles/MicroNotification.module.scss";

type MicroNotificationComponentProps = WithClassName & {
    notification: MicroNotification;
    onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
    disableClose?: boolean;
};

export const MicroNotificationComponent = ({
    notification,
    onClose,
    className,
    disableClose,
}: MicroNotificationComponentProps): JSX.Element => {
    const Component = notification.component;

    return (
        <div key="notification" className={cls(classes.root, className)}>
            <Component key={notification.type} messages={notification.componentProps} />

            {!disableClose && (
                <div key="close-notification" className={cls(classes.close, "close-button")} onClick={onClose}>
                    <CloseIcon />
                </div>
            )}
        </div>
    );
};
