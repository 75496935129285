import {DeepPartial} from "@common/declarations/generic";
import {HostType, LocalUserDocument, OrganizationPlan, RemoteUserFragment, UserInviteStatus} from "@generated/data";
import {useQuery} from "@workhorse/api/data";
import {useRemoteUser} from "@workhorse/api/user";
import {makeHostTypeFromRoute} from "@workhorse/util/makeHostTypeFromRoute";

export interface UserStructure extends Omit<RemoteUserFragment, "__typename"> {
    isAuthenticated: boolean;
    role: string;
}

const now = new Date();

const mockedUser = <RemoteUserFragment>{
    createdAt: now,
    email: " ",
    emailNotificationsEnabled: false,
    emailNotificationsMinutes: 0,
    firstLogin: false,
    firstName: "",
    halfDayClock: false,
    id: "mocked",
    language: "",
    lastName: "",
    organizationPermissions: [
        {
            id: "mocked",
            permissionType: "MEMBER",
            activeOrganization: true,
            inviteStatus: UserInviteStatus.Active,
            suspended: false,
            organization: {
                createdAt: now,
                id: "mocked",
                eventActivity: {},
                membersCount: 0,
                subscriptionTrialUsed: false,
                plan: OrganizationPlan.Free,
                recordingActivity: 0,
                isActivated: true,
                onboardingCompleted: true,
                __typename: "Organization",
                subscriptionModifiers: [],
            },
        },
    ],
    pushNotificationsEnabled: false,
    pushNotificationsMinutes: 0,
    referralCode: "",
    seenHomeIntercom: false,
    seenTour: true,
    seenWelcomeSession: true,
    timezone: "",
    workspacePermissions: [
        {
            id: "mocked",
            activeWorkspace: true,
            role: {
                id: "mocked",
                name: "MEMBER",
                __typename: "WorkspaceRole",
                permissions: [],
                clientOnly: false,
                createdAt: new Date(),
            },
            workspace: {
                id: "mocked",
                name: "mocked",
                __typename: "Workspace",
                membersCount: 0,
                organizationId: "mocked",
            },
        },
    ],
    __typename: "User",
    company: "",
    country: "",
    externalCalendarsSettings: [],
    firstDayOfWeek: "MO",
    defaultBookingCalendars: [],
    marketingEmailsEnabled: true,
    tags: [],
};

/**
 * @deprecated - Do not use `useUser`, try to use `useUserInfo` - or create special user context
 */
export const useUser = (): UserStructure => {
    const isMemory = makeHostTypeFromRoute(location.pathname) === HostType.Memory;
    const remoteUser = useRemoteUser({fetchPolicy: "cache-only", returnPartialData: true});
    const localUser = useQuery(LocalUserDocument);
    const remoteData = remoteUser.data?.getRemoteUser?.user! ?? (isMemory ? mockedUser : null);

    return {
        ...remoteData,
        isAuthenticated: !!localUser?.data?.isAuthenticated,
        role: remoteData?.id === "mocked" ? "guest" : localUser.data?.localUser?.role ?? "Undefined",
    };
};
