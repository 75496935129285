import Typography from "@ui/cdk/Typography";
import {MicroNotificationProps} from "../api";
import {useTranslation} from "react-i18next";

export type ScreenShareNotificationType = {
    fullName: string;
};

const ScreenShareNotification = (props: MicroNotificationProps<ScreenShareNotificationType>) => {
    const {t} = useTranslation();
    const {messages} = props;
    return (
        <Typography key="screen-share-notification-message">
            {t("player.notifications.screen_share.x_started", {name: messages[0].message.fullName})}
        </Typography>
    );
};

export default ScreenShareNotification;
