import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import {ReactComponent as AirplayIcon} from "../../../../../../assets/media/air_play.svg";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {useTranslation} from "react-i18next";

const BackgroundEffectsOption = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {isMobileOrTablet} = useMobile();
    const {show} = useSessionSettingsDialogDisplay();

    const showPage = isMobileOrTablet ? "device" : "device.backgroundEffects";

    const handleOnToggle = () => show(showPage);

    return (
        <MenuItem ref={ref} onClick={handleOnToggle}>
            <AirplayIcon />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.camera.background_effects")}
            </Typography>
        </MenuItem>
    );
});

export default BackgroundEffectsOption;
