import {useMutation} from "@apollo/client";
import {RequestArtifactControlDocument, SetArtifactControlDocument} from "@generated/data";
import CancelRounded from "@material-ui/icons/CancelRounded";
import Button from "@ui/cdk/Button";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography/Typography";
import IconButton from "@ui/core/components/IconButton";
import toast from "@workhorse/api/toast";
import {useCurrentArtifactControlStatus} from "@workhorse/components/resx/utils/auxiliary";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import {ReactComponent as EveryoneIcon} from "../../../../../assets/media/interaction-status-everyone.svg";
import {ReactComponent as SingleIcon} from "../../../../../assets/media/interaction-status-single.svg";
import classes from "../../styles/PlayerHeader.module.scss";
import {useState} from "@workhorse/api/rendering";
import RequestControlNotification from "./components/RequestControlNotification";
import GuestNotification from "./components/GuestNotification";
import ApprovalsComponent from "./components/ApprovalsComponent";
import apollo from "@workhorse/api/apollo";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {SessionData} from "@workhorse/declarations/dataTypes";
import PlayerToolMenuHeader from "./PlayerToolMenuHeader";
import {useAgendaItems} from "@workhorse/providers/SessionDataProviders";

export const takeControl = async (sessionId: string, participantId: string) => {
    return apollo.client
        .mutate({
            mutation: SetArtifactControlDocument,
            variables: {
                sessionId,
                participantId,
                willControlTool: true,
            },
        })
        .then((res) => {
            if (res.data?.setControl) {
                toast(t("player.header.control_status.toast.you_are_now_presenting") ?? "You are now presenting.", {
                    type: "success",
                });
            }
        })
        .catch(() =>
            toast(t("player.header.control_status.toast.take_control_error") ?? "Something went wrong with taking the control.", {
                type: "error",
            })
        );
};

type PlayerSessionControlStatusProps = {
    currentParticipantId: string;
    session: SessionData;
    isOrganizer: boolean;
    isMobile: boolean;
};

const PlayerSessionControlStatus = (props: PlayerSessionControlStatusProps) => {
    const {t} = useTranslation();
    const {currentParticipantId, session, isOrganizer, isMobile} = props;
    const sessionId = session.id;

    const [firstRequest, setFirstRequest] = useState<boolean>(false);

    const [setArtifactControl] = useMutation(SetArtifactControlDocument);
    const [requestArtifactControl] = useMutation(RequestArtifactControlDocument);

    const agendaItems = useAgendaItems();

    const {
        enabledControls,
        controllingParticipantId,
        requestingParticipantId,
        isCurrentParticipantControlling,
        canTakeControl,
        canRequestControl,
        canReturnControl,
    } = useCurrentArtifactControlStatus();

    const textOverwriteArtifacts = {
        "flowos/form": t("artifacts.form.form_options"),
        "flowos/breakout": t("artifacts.breakout.breakout_options"),
    };

    const {order} = session;
    const currentAgendaItem = agendaItems.find((a) => a.order === order);

    const convertingTool = isOrganizer ? agendaItems.find((a) => a.processing) : undefined;
    const convertingMicroTag = convertingTool?.artifact?.artifactId;
    const currentMicroTag = convertingMicroTag ?? currentAgendaItem?.artifact?.artifactId;

    const controllingParticipant = useParticipant({participantId: controllingParticipantId ?? ""});
    const requestingParticipant = useParticipant({participantId: requestingParticipantId ?? ""});

    const controllingParticipantFirstName = controllingParticipant?.dataWithNullableEmail.firstName.trim();

    const logo = enabledControls ? <EveryoneIcon /> : <SingleIcon />;

    const text = isCurrentParticipantControlling
        ? t("player.header.control_status.you_are_presenting") ?? "You are presenting"
        : controllingParticipant
        ? `${controllingParticipantFirstName} ${t("player.header.control_status.someone_else_presents") ?? "presents"}`
        : enabledControls
        ? t("player.header.control_status.everyone_can_interact") ?? "Everyone can interact"
        : currentMicroTag
        ? textOverwriteArtifacts[currentMicroTag]
        : null ?? t("player.header.control_status.presenting_mode") ?? "Presenting mode";

    const handleReturnControl = () => {
        if (!controllingParticipant) {
            return;
        }

        setArtifactControl({
            variables: {
                sessionId,
                participantId: controllingParticipant.id,
                willControlTool: false,
            },
        })
            .then((res) => {
                if (res.data?.setControl) {
                    toast(t("player.header.control_status.toast.you_are_no_longer_presenting") ?? "You are no longer presenting.", {
                        type: "success",
                    });
                }
            })
            .catch(() =>
                toast(t("player.header.control_status.return_control_error") ?? "Something went wrong with returning the control.", {
                    type: "error",
                })
            );
    };

    const handleTakeControl = () => {
        takeControl(sessionId, currentParticipantId);
    };

    const handleRequestControl = () => {
        requestArtifactControl({
            variables: {
                sessionId,
            },
        })
            .then((res) => {
                if (res.data?.requestControl) {
                    toast(t("player.header.control_status.request_control_success") ?? "The request was successfully sent.", {
                        type: "success",
                    });
                }
            })
            .catch(() =>
                toast(t("player.header.control_status.request_control_error") ?? "Something went wrong with requesting the control.", {
                    type: "error",
                })
            );

        localStorage.setItem("shouldShowNotification", "false");
    };

    const handleNotificationConfirm = (participantId: string, willControlTool: boolean) => {
        setArtifactControl({
            variables: {
                sessionId,
                participantId,
                willControlTool,
            },
        });

        if (willControlTool) {
            localStorage.setItem("shouldShowNotification", "true");
        }
    };

    const handleNotificationClose = () => {
        setFirstRequest(false);
    };

    const showNotification = JSON.parse(localStorage.getItem("shouldShowNotification") as string);

    if (isCurrentParticipantControlling) {
        localStorage.setItem("shouldShowNotification", "false");
    } else {
        localStorage.setItem("shouldShowNotification", "true");
    }

    if (enabledControls === null && currentMicroTag === "flowos/conference") {
        return null;
    }

    return (
        <>
            {!isMobile && (
                <>
                    {/* since the indicator is now combined with the tool control menu, i moved the code from here to the tool menu component */}
                    <PlayerToolMenuHeader session={session} isOrganizer={isOrganizer} logo={logo} text={text} />

                    {canReturnControl && (
                        <Tooltip
                            placement="bottom"
                            title={
                                t("player.header.control_status.info_tooltip_give_control") ?? "Click here to give control back to the host"
                            }
                        >
                            <IconButton onClick={handleReturnControl} className={classes.interactionStatusCancelBtn}>
                                <CancelRounded fontSize="small" color="action" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {(canTakeControl || canRequestControl) && (
                        <Button
                            size="small"
                            variant="tertiary"
                            className={classes.interactionStatusControlButton}
                            onClick={canTakeControl ? handleTakeControl : handleRequestControl}
                            aria-label={
                                canTakeControl
                                    ? t("player.header.control_status.take_control") ?? "Take control"
                                    : t("player.header.control_status.request_control") ?? "Request control"
                            }
                            data-id={canTakeControl ? "take-control-button" : "request-control-button"}
                        >
                            <EveryoneIcon />{" "}
                            {canTakeControl
                                ? t("player.header.control_status.take_control") ?? "Take control"
                                : t("player.header.control_status.request_control") ?? "Request control"}
                        </Button>
                    )}
                </>
            )}

            {isOrganizer && (
                <ApprovalsComponent
                    requestingParticipant={requestingParticipant}
                    onDecision={handleNotificationConfirm}
                    onNotificationClose={handleNotificationClose}
                />
            )}

            {!isOrganizer && isCurrentParticipantControlling && (
                <GuestNotification showNotification={showNotification} onNotificationClose={handleNotificationClose} />
            )}

            {!isOrganizer && firstRequest && (
                <RequestControlNotification
                    handleRequestControl={handleRequestControl}
                    firstRequest={firstRequest}
                    onNotificationClose={handleNotificationClose}
                />
            )}
        </>
    );
};

export default PlayerSessionControlStatus;
