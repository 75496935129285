import React, {useEffect, useState} from "react";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import classes from "./style/EventsLandingPageImagesUpload.module.scss";
import commonClasses from "../eventCommons.module.scss";
import toast from "@workhorse/api/toast";
import {useMutation} from "@workhorse/api/data";
import {ConfirmUploadEventImageDocument, UploadEventImageDocument} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import Tooltip from "@ui/cdk/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import designer from "@workhorse/api/designer";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {cls} from "@ui/cdk/util";

type EventsLandingPageImagesUploadProps = {
    readOnly: boolean;
    classes?: {
        uploaderRoot?: string;
    };
    preventCommit?: boolean;
};

const EventsLandingPageImagesUpload = (props: EventsLandingPageImagesUploadProps) => {
    const {readOnly} = props;
    const {event} = useSession();
    const [logo, setLogo] = useState(event?.photoUrl);
    const [banner, setBanner] = useState(event?.bannerUrl);
    const [loading, setLoading] = useState<"logo" | "banner" | null>(null);

    useEffect(() => {
        setLogo(event?.photoUrl);
    }, [event?.photoUrl]);

    useEffect(() => {
        setBanner(event?.bannerUrl);
    }, [event?.bannerUrl]);

    const [uploadLogo] = useMutation(UploadEventImageDocument);
    const [confirmUploadLogo] = useMutation(ConfirmUploadEventImageDocument);

    const onLogoChange = (e: React.ChangeEvent<HTMLInputElement>, primary: boolean) => {
        setLoading(primary ? "logo" : "banner");
        designer.state.setDesignerCommitState(true);
        try {
            if (e.target?.files?.length && e.target.files?.length > 0) {
                const file = e.target.files[0];
                const maximumUploadSize = 5;
                const sizeMb = parseFloat((file.size / (1024 * 1024)).toFixed(2));

                const acceptedTypes = ["image/jpeg", "image/jpg", "image/png"];
                if (!acceptedTypes.includes(file.type)) {
                    toast("Please upload one of the following formats: jpg, png, jpeg", {
                        type: "error",
                    });
                    throw Error("Bad format");
                }
                if (sizeMb > maximumUploadSize) {
                    toast("File can't exceed 5mb", {
                        type: "error",
                    });
                    throw Error("Max file size exceeded");
                }

                const logError = (err: any) => {
                    toast(`Error occurred when uploading event image`, {type: "error"});
                    throw Error(`Error occurred when uploading event image: ${err}`);
                };

                const eventId = event?.id;

                if (!eventId) {
                    logError("Event id is not defined");
                    return;
                }

                const cacheBuster = Date.now().toString();
                uploadLogo({variables: {primary, eventId, cacheBuster}})
                    .then((uploadUrlRes) => {
                        if (!uploadUrlRes || !uploadUrlRes.data?.uploadEventImage) {
                            logError("No data returned from server.");
                            return;
                        }

                        fetch(uploadUrlRes.data.uploadEventImage, {
                            method: "PUT",
                            body: file,
                            headers: {
                                "Content-Type": !!file.type ? file.type : "application/octet-stream",
                            },
                        }).then((fetchResponse) => {
                            if (fetchResponse.ok) {
                                confirmUploadLogo({variables: {primary, eventId, cacheBuster}}).then(async (confirmationResponse) => {
                                    if (!confirmationResponse || !confirmationResponse.data?.confirmUploadEventImage) {
                                        logError("No data returned for upload confirmation.");
                                    } else {
                                        if (primary) {
                                            designer.api.event.update({
                                                photoUrl: confirmationResponse.data?.confirmUploadEventImage,
                                                bannerUrl: banner,
                                            });
                                            if (!props.preventCommit) {
                                                await designer.commit();
                                            }
                                            setLogo(confirmationResponse.data?.confirmUploadEventImage);
                                        } else {
                                            designer.api.event.update({
                                                photoUrl: logo,
                                                bannerUrl: confirmationResponse.data?.confirmUploadEventImage,
                                            });
                                            if (!props.preventCommit) {
                                                await designer.commit();
                                            }
                                            setBanner(confirmationResponse.data?.confirmUploadEventImage);
                                        }
                                        setLoading(null);
                                    }
                                });
                            } else {
                                logError("Fetch response is not 200.");
                            }
                        });
                    })
                    .catch((err) => {
                        logError(`Error occurred when uploading profile picture: ${err}`);
                    });
            } else {
                throw Error("Upload was canceled");
            }
        } catch (e) {
            setLoading(null);
            designer.state.setDesignerCommitState(false);

            console.error(e);
        }
    };

    return (
        <div>
            <div className={cls("flex flex-justify-between", classes.title)}>
                <Typography component="div" color="blueGray400" variant="lg">
                    Images
                </Typography>
            </div>
            <div className="flex flex-row gap-48 mt-16">
                <div>
                    <Typography variant="sm" color="secondary" className="flex flex-align-center flex-justify-between" fontWeight="bold">
                        Logo image
                        <Tooltip placement="top" title="The logo should be about 150x50 pixels." arrow>
                            <InfoIcon className={commonClasses.infoIcon} />
                        </Tooltip>
                    </Typography>
                    <div className={cls(classes.logoUploaderRoot, props.classes?.uploaderRoot)} data-id="event-button-logo">
                        <input
                            accept=".png, .jpg, .jpeg"
                            className={classes.uploadInput}
                            id="logo-uploader"
                            type="file"
                            disabled={readOnly}
                            name={`profilePic`}
                            onChange={(e) => onLogoChange(e, true)}
                        />

                        <label htmlFor="logo-uploader">
                            {logo && loading !== "logo" ? (
                                <img className={classes.logo} src={logo ? `${logo}` : `${event?.photoUrl}`} />
                            ) : (
                                <Skeleton animation="wave" width="100%" height="100%" variant="rect" />
                            )}
                            <span className={classes.uploadIcon}>
                                <AddAPhotoIcon />
                            </span>
                        </label>
                    </div>
                </div>
                <div>
                    <Typography variant="sm" color="secondary" className="flex flex-align-center flex-justify-between" fontWeight="bold">
                        Banner image
                        <Tooltip placement="top" title="The banner should be about 1905x475 pixels." arrow>
                            <InfoIcon className={commonClasses.infoIcon} />
                        </Tooltip>
                    </Typography>
                    <div className={cls(classes.logoUploaderRoot, props.classes?.uploaderRoot)} data-id="event-button-banner">
                        <input
                            accept=".png, .jpg, .jpeg"
                            className={classes.uploadInput}
                            id="banner-uploader"
                            type="file"
                            disabled={readOnly}
                            name={`bannerPic`}
                            onChange={(e) => onLogoChange(e, false)}
                        />

                        <label htmlFor="banner-uploader">
                            {banner && loading !== "banner" ? (
                                <img className={classes.logo} src={banner ? `${banner}` : `${event?.bannerUrl}`} />
                            ) : (
                                <Skeleton animation="wave" width="100%" height="100%" variant="rect" />
                            )}
                            <span className={classes.uploadIcon}>
                                <AddAPhotoIcon />
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EventsLandingPageImagesUpload;
