import browserInfo from "@workhorse/api/BrowserInfo";
import {AudioPresets, RoomOptions, supportsDynacast, supportsVP9, version} from "livekit-client";
import {conferenceContext, supportsWebAudio} from "../../lib/DeviceManager/AudioContext";

export const videoCodec = supportsVP9() && !browserInfo.isFirefox() ? "vp9" : "vp8";
console.log("should use codec", videoCodec, "SDK version=", version);

export const roomOptions: RoomOptions = {
    adaptiveStream: false,
    // VERY useful
    // disables the encoding and uploading
    // of simulcast layers that are not consumed by any subscribers
    dynacast: supportsDynacast(),
    publishDefaults: {
        dtx: false,
        red: false,
        forceStereo: false,
        audioPreset: AudioPresets.speech,
        screenShareSimulcastLayers: [],
    },
    webAudioMix: supportsWebAudio()
        ? {
              audioContext: conferenceContext,
          }
        : false,
    stopLocalTrackOnUnpublish: false,
    // reconnectPolicy: {
    //     nextRetryDelayInMs(context) {
    //         return null;
    //     },
    // },
    // videoCaptureDefaults: {
    //     // as per the docs, this is the main captured resolution
    //     // and the base simulcast layer
    //     // this cannot be lower than the highest specified `videoSimulcastLayers`
    //     // lower resolutions can only be encoded if the capture is made
    //     // to a gte resolution than our desired layers
    //     // also, there seems to be a bug where vp9 encodes 3 layers regardless of the config specified
    //     // so our simulcast layers should include a mid res, even if just for show
    //     resolution: browserInfo.isMobile() ? VideoPresets.h540.resolution : VideoPresets.h1080.resolution,
    // },
    audioCaptureDefaults: {
        noiseSuppression: true,
        autoGainControl: true,
        echoCancellation: true,
    },
};
