import {GetContactsDocument, ParticipantForEvent} from "@generated/data";
import apollo from "@workhorse/api/apollo";

export const makeSureContactDataIsCached = (email: string | undefined | null) => {
    if (!email) {
        return null;
    }
    return apollo.client.query({
        query: GetContactsDocument,
        variables: {
            where: {
                email: {
                    equals: email,
                },
            },
        },
    });
};

export const getContactByEmail = (email: string | undefined | null) => {
    if (!email) {
        return null;
    }

    const contactsData = apollo.cache.readQuery({
        query: GetContactsDocument,
        variables: {
            where: {
                email: {
                    equals: email,
                },
            },
        },
    });

    return contactsData?.contacts?.[0];
};

export const isContact = (participant: Pick<ParticipantForEvent, "email">) =>
    participant.email ? !!getContactByEmail(participant.email) : false;
