import {WorkspacePreviewData} from "../OnboardingWorkspaceDialog";
import WorkspacePreviewSmall from "./WorkspacePreviewSmall";

type WorkspacePreviewProps = {} & WorkspacePreviewData;

const WorkspacePreview = (props: WorkspacePreviewProps) => {
    const companyLogo = props.companyLogo;
    const companyName = props.companyName || "XYZ Company";
    const companyWebsite = props.companyWebsite || "www.company.com";
    const sessionBackground = props.sessionBackground;

    return (
        <WorkspacePreviewSmall
            companyName={companyName}
            companyLogo={companyLogo}
            companyWebsite={companyWebsite}
            sessionBackground={sessionBackground}
        />
    );
};

export default WorkspacePreview;
