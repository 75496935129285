import {getContactByEmail} from "@common/utils/contacts";
import {ConferenceParticipantStatus, ContactInfoFragment} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import SvgIcon from "@ui/core/components/SvgIcon";
import {useHaveActionsOnParticipant} from "@workhorse/api/conference2";
import {useEffect, useState} from "@workhorse/api/rendering";
import {Participant} from "@workhorse/declarations/dataTypes";
import {getColor, makeInitials} from "@workhorse/util";
import {ReactComponent as StarAssistantIcon} from "../../components/conferenceControls/star-assistant.svg";
import {ReactComponent as StarOwnerIcon} from "../../components/conferenceControls/star-owner.svg";
import ParticipantReaction from "../../components/Reactions/components/ParticipantReaction";
import {AudienceParticipantAvatar} from "./audience-participant-components/AudienceParticipantAvatar";
import {AudienceParticipantConferenceStatusInfo} from "./audience-participant-components/AudienceParticipantConferenceStatusInfo";
import {AudienceParticipantConnecting} from "./audience-participant-components/AudienceParticipantConnecting";
import {AudienceParticipantInitials} from "./audience-participant-components/AudienceParticipantInitials";
import {AudienceParticipantName} from "./audience-participant-components/AudienceParticipantName";
import ConferenceRemoteParticipantStatus from "./audience-participant-components/ConferenceParticipantStatus";
import {AudienceParticipantVideo} from "./audience-participant-components/AudienceParticipantVideo";
import {AudienceParticipantVisibilityDetector} from "./audience-participant-components/AudienceParticipantVisbilityDetector";
import classes from "./audience-participant-components/styles/AudienceParticipant.module.scss";
import AudienceParticipantActions from "./AudienceParticipantActions";
import {useTranslation} from "react-i18next";
import TogglePinParticipantButton from "../../components/TogglePinParticipantButton";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {brandingVideoTileColor} from "@workhorse/pages/user/profile/Theming/utils";
import {useReactiveVar} from "@apollo/client";
import {customFirstLastName, defaultVideoTileBackgroundColor} from "@common/utils";
import {ConnectionQuality} from "livekit-client";

type AudienceParticipantProps = {
    participant: Participant;
    isLocalParticipant: boolean;
    participantIsAdmin: boolean;
    participantIsAssistant: boolean;
    renderingForAdmin: boolean;
    renderingForAssistant: boolean;
    containerNode?: HTMLDivElement | null;
    isAvatarMode: boolean;
    //for the audience bar in the top of the page
    isThumbnailMode?: boolean;
    pinnedModeEnabled?: boolean;
    isPinned?: boolean;
};

const AudienceParticipant = (props: AudienceParticipantProps) => {
    const {t} = useTranslation();
    const {
        participant,
        isLocalParticipant,
        renderingForAdmin,
        participantIsAdmin,
        renderingForAssistant,
        participantIsAssistant,
        isAvatarMode,
        containerNode,
        isThumbnailMode,
        pinnedModeEnabled,
        isPinned,
    } = props;

    const conferenceStatus = participant.conferenceStatus;
    const {email} = participant.dataWithNullableEmail ?? {};
    const participantRemoteStatus = useRemoteParticipantStatus(participant.id);

    const [contact, setContact] = useState<ContactInfoFragment | undefined | null>(null);

    useEffect(() => {
        if (email) {
            setContact(getContactByEmail(email));
        }
    }, [email]);

    // useEffect(() => {
    //     if (email && !contact) {
    //         makeSureContactDataIsCached(email)?.then(() => {
    //             setContact(getContactByEmail(email));
    //         });
    //     }
    // }, [contact, email]);

    const firstName = contact ? contact.firstName : participant.dataWithNullableEmail?.firstName.trim();
    const lastName = contact ? contact.lastName : participant.dataWithNullableEmail?.lastName.trim();
    const avatar = contact ? contact.userProfilePicture : participant.dataWithNullableEmail?.avatar;

    const {customFirstName, customLastName} = customFirstLastName(firstName, lastName);

    const participantName = isLocalParticipant
        ? t("participant.g.you") ?? "You"
        : `${customFirstName}${customLastName ? " " + customLastName : ""}`;

    const initials = makeInitials(customFirstName, customLastName);
    const hash = getColor(initials);
    const videoTileColor = useReactiveVar(brandingVideoTileColor);

    const isSpeaker = conferenceStatus === ConferenceParticipantStatus.Speaker;

    const [hasActions] = useHaveActionsOnParticipant(participant.id);

    const renderVideoCond =
        !isAvatarMode && ((participantRemoteStatus.cameraOn && !participantRemoteStatus.cameraPaused) || isLocalParticipant);

    return (
        <div
            className={cls(
                classes.root,
                (isLocalParticipant || isSpeaker || participantIsAdmin || participantIsAssistant) && "no-hover",
                "audience-avatar",
                isAvatarMode && "just-avatar"
            )}
            data-participant
        >
            {isAvatarMode && (participantIsAdmin || participantIsAssistant) ? (
                <span className={classes.participantNameStarOnly}>
                    <SvgIcon component={participantIsAdmin ? StarOwnerIcon : StarAssistantIcon} />
                </span>
            ) : null}
            <div
                data-id="audience-participant"
                className={cls("flex flex11-100 flex-center-all", classes.innerParticipant)}
                style={{background: videoTileColor ?? defaultVideoTileBackgroundColor}}
            >
                {renderVideoCond && (
                    <AudienceParticipantVideo
                        participantId={participant.id}
                        isLocalParticipant={isLocalParticipant}
                        key={`video-${participant.id}`}
                    />
                )}
                {!isAvatarMode && pinnedModeEnabled ? (
                    <TogglePinParticipantButton
                        participantId={participant.id}
                        className={cls(classes.participantSignal, classes.videoPinButton, classes.pinned)}
                    />
                ) : null}
                <AudienceParticipantVisibilityDetector
                    participantId={participant.id}
                    containerNode={containerNode}
                    forceInvisible={isAvatarMode}
                />
                {isAvatarMode ||
                (participantRemoteStatus != null && participantRemoteStatus.connectionQuality !== null) ||
                participantRemoteStatus.cameraPaused ? (
                    <div className={classes.audienceCircle} style={avatar ? undefined : {background: hash}}>
                        {avatar ? (
                            <AudienceParticipantAvatar isAvatarMode={isAvatarMode} avatarImg={avatar} key={`avatar-${participant.id}`} />
                        ) : (
                            <AudienceParticipantInitials initials={initials} key={`initials-${participant.id}`} />
                        )}
                    </div>
                ) : (
                    <AudienceParticipantConnecting key={`connecting-${participant.id}`} />
                )}

                {hasActions ? <AudienceParticipantActions key="audience-participant-actions" participantId={participant.id} /> : null}

                {!isAvatarMode ? (
                    <Typography
                        variant="xs"
                        component="span"
                        // TODO: @vasi
                        // FIXME: the "with-avatar-or-video" className should be applied when
                        // `avatar` exists or video started... but here we don't know if video is started or not
                        // so this needs refactoring
                        // maybe render this entire Typography component inside AudienceParticipantStatus TBD
                        className={cls(
                            "flex flex-center-all",
                            classes.name,
                            isThumbnailMode ? classes.isThumbnail : "",
                            avatar && "with-avatar-or-video"
                        )}
                        key={`name-${participant.id}`}
                    >
                        <AudienceParticipantName
                            participantDisplayName={participantName}
                            participantIsOwner={participantIsAdmin}
                            participantIsAssistant={participantIsAssistant}
                            isThumbnailMode={isThumbnailMode}
                        />

                        <ConferenceRemoteParticipantStatus
                            participantId={participant.id}
                            isLocalParticipant={isLocalParticipant}
                            isThumbnailMode={isThumbnailMode}
                        />
                    </Typography>
                ) : null}

                {!isAvatarMode ? (
                    <AudienceParticipantConferenceStatusInfo
                        participant={participant}
                        renderingForAdmin={renderingForAdmin || renderingForAssistant}
                    />
                ) : null}
                {/* Hiding Poor / Bad Connection indicator for how - unreliable */}
                {/* {chimeAttendeeId && (
                    <AudienceParticipantConnectionStatus
                        key={`connection-status-${participant.id}-${chimeAttendeeId}`}
                        chimeAttendeeId={chimeAttendeeId}
                    />
                )} */}
            </div>

            <ParticipantReaction pId={participant.id} className={classes.reaction} key="audience-participant-participant-reaction" />
        </div>
    );
};

export default AudienceParticipant;
