import defaultAvatar from "@common/utils/guest-common-avatar";
import {ParticipantStatus} from "@generated/data";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {participantIsAssistant, useHaveActionsOnParticipant} from "@workhorse/api/conference2";
import {forwardRef, useEffect, useState} from "@workhorse/api/rendering";
import {WithClassName} from "@workhorse/declarations";
import {Participant} from "@workhorse/declarations/dataTypes";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import {getParticipantPressenceInfo, ParticipantPresenceHistoryJsonType, ParticipantsCategory} from "../utils";
import ParticipantEntryActions from "./ParticipantEntryActions";
import ParticipantEntryStatus from "./ParticipantEntryStatus";
import classes from "./styles/ParticipantEntry.module.scss";
import {useInView} from "react-intersection-observer";
import {useTranslation} from "react-i18next";
import {playSoundActionDebounced, SoundActionType} from "@workhorse/pages/player/soundActions";

type ParticipantEntryProps = WithClassName & {
    participant: Participant & {presenceHistory: ParticipantPresenceHistoryJsonType};
    type: ParticipantsCategory;
    isOwner: boolean;
    isAssistant: boolean;
    currentParticipantId: string;
    guestIndex: number;
    isMemoryMode: boolean;
    onWaitingForApproval: (participantIds: string[], hasBeenApproved: boolean) => void;
    index: number;
    sessionActualStart?: Date | null | undefined;
    sessionDisabledNotifications?: boolean;
};

const ParticipantEntry = forwardRef((props: ParticipantEntryProps, ref: any) => {
    const {t} = useTranslation();
    const {
        className,
        participant,
        type,
        isOwner,
        isAssistant,
        currentParticipantId,
        onWaitingForApproval,
        guestIndex,
        isMemoryMode,
        sessionActualStart,
        sessionDisabledNotifications,
    } = props;

    const {dataWithNullableEmail, isOwner: isParticipantOwner, status, id: participantId, conferenceStatus} = participant;

    const {firstName, lastName, email, isGuest, avatar} = dataWithNullableEmail;

    const [menuOpen, setMenuOpen] = useState<boolean>(() => false);
    const [showCopyAction, setShowCopyAction] = useState<boolean>(false);
    const notJoined = status !== ParticipantStatus.JoinedSession;
    const isParticipantAssistant = participantIsAssistant(participant);
    const isOrganizer = isOwner || isAssistant;

    const isLocalParticipant = currentParticipantId === participantId;

    const [haveActions] = useHaveActionsOnParticipant(participantId);

    const handleToggleCopyAction = () => {
        setShowCopyAction(!showCopyAction);
    };

    const userTimeInfo = getParticipantPressenceInfo(participant, sessionActualStart);

    useEffect(() => {
        if (sessionDisabledNotifications) return;

        if (isOrganizer && type === ParticipantsCategory.WAITING_TO_JOIN) {
            const key = `sound:${participantId}-${participant.dataWithNullableEmail.firstName}-${participant.dataWithNullableEmail.email}`;

            if (sessionStorage.getItem(key) === null) {
                playSoundActionDebounced(SoundActionType.RequestToJoin);
                sessionStorage.setItem(key, "true");
            }
        }
    }, []);

    return (
        <ParticipantDisplayDetails
            onMouseEnter={handleToggleCopyAction}
            onMouseLeave={handleToggleCopyAction}
            ref={ref}
            type={type}
            data-id="participant-display-details"
            key={`${participantId}-${type}`}
            className={cls(className, classes.participantRoot, menuOpen && classes.participantRootOpen)}
            classes={{
                wrap: classes.participantInfo,
                displayName: classes.participantName,
                avatar: classes.participantAvatar,
                customStatus: classes.participantCustomStatus,
                timeInfoIcon: classes.timeInfoIcon,
                emailCopied: classes.emailCopied,
            }}
            firstName={firstName}
            lastName={lastName}
            index={guestIndex}
            showName={isOrganizer && isGuest ? !!firstName : true}
            email={email?.trim()}
            showEmail={isMemoryMode || (!firstName && isOrganizer && notJoined && isGuest)}
            emailTooltip={!isLocalParticipant && isOrganizer && !!email?.trim()}
            participantStatus={status}
            isOwner={isParticipantOwner}
            isUser={!isGuest}
            avatar={isGuest && !firstName && !lastName ? defaultAvatar : avatar}
            showProfilePicture={true}
            participantIsAssistant={isParticipantAssistant}
            showKickedInfo
            showRSVP={false}
            customStatus={
                <ParticipantEntryStatus
                    isParticipantOwner={isParticipantOwner}
                    isParticipantAssistant={isParticipantAssistant}
                    conferenceStatus={conferenceStatus}
                    showRole={!isMemoryMode || (isMemoryMode && !isOrganizer)}
                />
            }
            hideRightBadge
            avatarSize="M"
            avatarVariant="rounded"
            isCurrent={currentParticipantId === participant.id}
            isMemoryMode={isMemoryMode}
            showCopyAction={showCopyAction}
            userTimeInfo={userTimeInfo}
            isOrganizer={isOrganizer}
        >
            {isOrganizer && type === ParticipantsCategory.WAITING_TO_JOIN && (
                <div className={classes.admitBtns}>
                    <Button
                        data-id="decline"
                        size="small"
                        variant="plain"
                        onClick={onWaitingForApproval.bind(null, [participantId], false)}
                    >
                        {t("participant.g.deny")}
                    </Button>
                    <Button data-id="accept" size="small" variant="plain" onClick={onWaitingForApproval.bind(null, [participantId], true)}>
                        {t("participant.g.admit")}
                    </Button>
                </div>
            )}

            {!isMemoryMode && haveActions && type !== ParticipantsCategory.WAITING_TO_JOIN && (
                <ParticipantEntryActions
                    participantId={participantId}
                    isOwner={isOwner}
                    isAssistant={isAssistant}
                    isLocalParticipant={isLocalParticipant}
                    onToggleMoreOptions={setMenuOpen}
                />
            )}
        </ParticipantDisplayDetails>
    );
});

const ParticipantEntryWrapper = (props) => {
    const {ref, inView} = useInView({initialInView: props.index < 10});

    if (!inView) {
        return <div ref={ref} style={{height: "48px"}} />;
    }

    return <ParticipantEntry ref={ref} {...props} />;
};

export default ParticipantEntryWrapper;
