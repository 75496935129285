import {forwardRef} from "react";
import {TableCellProps} from "./types";
import MuiTableCell from "@material-ui/core/TableCell";
import {capitalize, cls} from "@ui/cdk/util";
import styles from "./style/TableCell.module.scss";

const TableCell = forwardRef((props: TableCellProps, ref?: any) => {
    const {
        /**
         * @param {TableCellType} type The cell type. @default "default"
         */
        type = "default",
        /**
         * @param {TableCellSize} size The cell size. @default "unset"
         */
        size = "unset",

        ellipsis = false,

        ...rest
    } = props;

    const className = cls(
        "fosTableCell",
        props.className,
        styles.tableCell,
        styles[`tableCell${capitalize(type)}`],
        styles[`tableCell${capitalize(size)}`],
        ellipsis && styles.ellipsis
    );

    return (
        <MuiTableCell {...rest} ref={ref} className={className}>
            {props.children}
        </MuiTableCell>
    );
});

TableCell.displayName = "TableCell";

export default TableCell;
