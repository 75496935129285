import {makeStyles} from "@material-ui/core";
import Typography from "@ui/cdk/Typography";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import React from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import Switch from "@ui/cdk/Switch";

const useStyles = makeStyles((theme) => ({
    switch: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& > p": {
            marginLeft: theme.spacing(2),
        },
    },
    label: {
        "&.fosTypography, & .MuiTypography-root": {
            color: theme.main.palette.text.secondary,
            fontSize: 14,
            fontWeight: 500,
            margin: 0,
            userSelect: "none",
        },
        "&.fosTypography": {
            marginRight: 19,
            cursor: "pointer",
        },
        "& .MuiTypography-root": {
            marginLeft: 4,
        },
    },
    secondTitle: {
        color: theme.main.palette.gray[400],
        "&.fosTypography": {
            marginTop: 0,
        },
    },
    isSwitch: {
        "& $label": {
            "&.fosTypography, & .MuiTypography-root": {
                color: theme.main.palette.gray[400],
            },
            "&.checked": {
                "&.fosTypography, & .MuiTypography-root": {
                    color: theme.main.palette.text.duodenary,
                },
            },
        },

        "& .MuiSwitch-track": {
            background: theme.main.palette.primary[500],
        },
    },
}));

interface TogglerProps {
    checked: boolean;
    name: string;
    onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
    title?: JSX.Element | string;
    secondTitle?: JSX.Element | string;
    label?: string;
    labelLeft?: string;
    className?: string;
    isSwitch?: boolean;
    togglePlacement?: "title" | "secondTitle";
    disabled?: boolean;
}

const TogglerContainer = (props: TogglerProps) => {
    const classes = useStyles();

    const {checked, name, title, secondTitle, label, onChange, className, isSwitch, labelLeft, togglePlacement = "title", disabled} = props;

    const handleClick = () => {
        onChange();
    };

    return (
        <div className={cls(isSwitch && classes.isSwitch, className)}>
            <div className="flex flex-justify-between flex-center-align">
                {title}

                <div className={classes.switch}>
                    {labelLeft ? (
                        <Typography className={cls(classes.label, !checked && "checked")} onClick={handleClick}>
                            {labelLeft}
                        </Typography>
                    ) : null}
                    {togglePlacement === "title" && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={checked}
                                    onChange={onChange}
                                    name={name}
                                    id={name}
                                    // className={classes.switch}
                                    disabled={disabled}
                                />
                            }
                            label={label ? label : checked ? "On" : "Off"}
                            className={cls(classes.label, checked && "checked")}
                        />
                    )}
                </div>
            </div>

            <div className="flex flex-justify-between flex-center-align fullw">
                {secondTitle && <Typography className={classes.secondTitle}>{secondTitle}</Typography>}
                {togglePlacement === "secondTitle" && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={checked}
                                onChange={onChange}
                                name={name}
                                id={name}
                                // className={classes.switch}
                                disabled={disabled}
                            />
                        }
                        label={label ? label : checked ? "On" : "Off"}
                        className={cls(classes.label, checked && "checked")}
                    />
                )}
            </div>
        </div>
    );
};

export default TogglerContainer;
