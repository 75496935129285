import {ResourceResultFullFragmentDoc, ResxResultUpdatedNotifyDocument, UseResourceResultDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useEffect} from "@workhorse/api/rendering";
import {debounce} from "@workhorse/api/utils/debouncer";
import {WithChildren} from "@workhorse/declarations";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {onBeforeDeleteResourceInLiveSession} from "../designer/methods";
// https://github.com/pubkey/broadcast-channel#methods
import {BroadcastChannel} from "broadcast-channel";

const debouncedUpdateCacheResult = debounce(
    (id: string) => {
        apollo.client
            .query({
                query: UseResourceResultDocument,
                variables: {
                    resultId: id,
                },
                fetchPolicy: "no-cache",
            })
            .then(({data, errors, loading}) => {
                if (loading) {
                    return;
                }

                if (errors) {
                    return errors;
                }

                apollo.client.writeFragment({
                    fragment: ResourceResultFullFragmentDoc,
                    fragmentName: "ResourceResultFull",
                    id,
                    data: {
                        ...data.resourceResult,
                    },
                    broadcast: true,
                });
            });
    },
    200 // debounce with 200ms delay
);

export const ResxResultProvider = ({children}: WithChildren) => {
    const sessionId = useSessionIdFromRoute();

    useEffect(() => {
        if (!sessionId) {
            return;
        }

        const doNecessaryCleanups = function (ev) {
            if (ev) {
                onBeforeDeleteResourceInLiveSession(ev);
            }
        };
        const ResourcesBroadcastChannel = new BroadcastChannel("resx-bc");
        ResourcesBroadcastChannel.onmessage = doNecessaryCleanups;

        apollo.client
            .subscribe({
                query: ResxResultUpdatedNotifyDocument,
                variables: {sessionId},
            })
            .subscribe({
                next: (result) => {
                    if (result.data?.resxResultUpdated?.resultId) {
                        debouncedUpdateCacheResult(result.data?.resxResultUpdated?.resultId);
                    }
                },
            });

        return () => {
            ResourcesBroadcastChannel.onmessage = null;
        };
    }, [sessionId]);

    return children as unknown as JSX.Element;
};
