import Tooltip, {TooltipProps} from "@ui/cdk/Tooltip";
import MenuList from "@ui/core/components/MenuList";
import MenuItem from "@ui/cdk/MenuItem";
import {ReactComponent as WindowActionIcon} from "@assets/media/window-action.svg";
import {ReactComponent as DetachWindowIcon} from "@assets/media/detach-window.svg";
import {ReactComponent as AttachWindowIcon} from "@assets/media/attach-window.svg";
import {ReactComponent as SyncWindowIcon} from "@assets/media/sync-window.svg";
import IconButton from "@ui/core/components/IconButton";
import classes from "./style/MacroStateMenu.module.scss";
import {cls} from "@ui/cdk/util";
import {useCallback, useEffect, useRef, useState} from "react";
import toast from "@workhorse/api/toast";
import {useClickOutside} from "../editor/api";
import {useTranslation} from "react-i18next";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";

type MacroStateMenuProps = {
    isOrganizer: boolean;
    isDetached: boolean;
    onDetach(): void;
    onSync(): void;
    extraIcons?: boolean;
    tooltipPlacement?: TooltipProps["placement"];
    isLobby?: boolean;
    name: string;
    shouldHideSync?: boolean;
    extraSettingsComponent?: JSX.Element | React.ReactNode | null;
    isAgendaMacro?: boolean;
};
const MacroStateMenu = (props: MacroStateMenuProps) => {
    const {t} = useTranslation();

    const {
        name,
        isOrganizer,
        isDetached,
        onDetach,
        onSync,
        isLobby,
        tooltipPlacement = "bottom-end",
        shouldHideSync,
        isAgendaMacro,
        extraSettingsComponent,
    } = props;
    const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>(false);

    const menuRef = useRef<null | HTMLDivElement>(null);
    const handleOnSync = useCallback(() => {
        onSync();
        setIsMenuOpen(false);
        toast(`${name} ${t("macro.g.successfully_opened")}!`, {type: "success", duration: 1500});
    }, [onSync]);

    const handleClickOutside = (event: MouseEvent) => {
        if (event.target && !menuRef.current?.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex" ref={menuRef}>
            {(isAgendaMacro && isOrganizer) || (!isLobby && isOrganizer) ? (
                <Tooltip
                    key="tt-1"
                    open={isMenuOpen}
                    placement={tooltipPlacement}
                    interactive
                    classes={{
                        tooltip: classes.tooltip,
                        popper: classes.popper,
                    }}
                    enterTouchDelay={0}
                    title={
                        <MenuList data-id="local-menu" className={classes.menuList}>
                            {isDetached ? (
                                <MenuItem onClick={() => onDetach()}>
                                    <AttachWindowIcon className="rnd-btn" />{" "}
                                    <span className="fullw">{t("macro.g.attach_window") ?? "Attach window"}</span>
                                </MenuItem>
                            ) : (
                                <MenuItem onClick={() => onDetach()}>
                                    <DetachWindowIcon /> <span className="fullw">{t("macro.g.detach_window") ?? "Detach window"}</span>
                                </MenuItem>
                            )}
                            {shouldHideSync ? null : (
                                <MenuItem onClick={handleOnSync}>
                                    <SyncWindowIcon />{" "}
                                    <span className="fullw">{t("macro.g.sync_for_everyone") ?? "Sync for everyone"}</span>
                                </MenuItem>
                            )}
                            {extraSettingsComponent}
                        </MenuList>
                    }
                >
                    <IconButton
                        className={cls(classes.button, classes.buttonOptions)}
                        aria-label={t("macro.g.panel_options") ?? ""}
                        onClick={() => setIsMenuOpen(true)}
                    >
                        <WindowActionIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip
                    placement="bottom"
                    title={isDetached ? t("macro.g.attach_window") ?? "Attach window" : t("macro.g.detach_window") ?? "Detach window"}
                    enterTouchDelay={0}
                >
                    {isDetached ? (
                        <IconButton className={cls(classes.button, classes.buttonOptions)} aria-label="Open options">
                            <AttachWindowIcon onClick={() => onDetach()} className={classes.icon} />
                        </IconButton>
                    ) : (
                        <IconButton
                            className={cls(classes.button, classes.buttonOptions)}
                            aria-label={
                                isDetached ? t("macro.g.attach_window") ?? "Attach window" : t("macro.g.detach_window") ?? "Detach window"
                            }
                            onClick={() => onDetach()}
                        >
                            <DetachWindowIcon className={classes.icon} />
                        </IconButton>
                    )}
                </Tooltip>
            )}
        </div>
    );
};

export default MacroStateMenu;
