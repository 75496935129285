import {GetCalendarFileDocument, GetCalendarFileQuery, OneSessionFragment, SessionCalendarFile} from "@generated/data";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import Menu from "@ui/core/components/Menu";
import {useLazyQuery} from "@workhorse/api/data";
import React, {useState, Fragment, useEffect} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import classes from "./styles/SendCalendarInviteMenu.module.scss";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import MenuItem from "@ui/core/components/MenuItem";
import AppleLogo from "../../../assets/media/calendar-apple.svg";
import OutlookLogo from "../../../assets/media/calendar-outlook.svg";
import GoogleLogo from "../../../assets/media/calendar-google.svg";
import YahooLogo from "../../../assets/media/calendar-yahoo.png";
import {cls} from "@ui/cdk/util/util";
import {useTranslation} from "react-i18next";
import {openExternalLink} from "@workhorse/util/links";

type SendCalendarInviteMenuProps = {
    session: OneSessionFragment;
};

const SendCalendarInviteMenu = (props: SendCalendarInviteMenuProps) => {
    const {t} = useTranslation();
    const {session} = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [calendarData, setCalendarData] = useState<SessionCalendarFile>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const [getCalendarFile] = useLazyQuery(GetCalendarFileDocument, {
        onCompleted: (data: GetCalendarFileQuery) => {
            if (data.getSessionCalendarFile) {
                setCalendarData(data.getSessionCalendarFile);
            }
            setIsLoading(false);
        },
        onError: (e) => {
            console.error(e);
            setIsLoading(false);
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        setIsLoading(true);

        getCalendarFile({
            variables: {
                sessionId: session.id,
            },
        });
    }, [session]);

    const calendarOptions = [
        {
            icon: AppleLogo,
            name: "Apple calendar",
            url: calendarData?.ics,
        },
        {
            icon: GoogleLogo,
            name: "Google",
            url: calendarData?.google,
        },
        {
            icon: OutlookLogo,
            name: "Outlook",
            url: calendarData?.ics,
        },
        {
            icon: OutlookLogo,
            name: "Outlook.com",
            url: calendarData?.outlook,
        },
        {
            icon: OutlookLogo,
            name: "Microsoft Outlook",
            url: calendarData?.outlookOffice,
        },
        {
            icon: YahooLogo,
            name: "Yahoo",
            url: calendarData?.yahoo,
        },
    ];

    const handleCalendarOption = (url?: string) => {
        if (!url) {
            return;
        }

        if (url.startsWith("BEGIN")) {
            const filename = `${session.name}.ics`;
            const blob = new Blob([url], {type: "text/calendar;charset=utf-8"});
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else if (url.startsWith("https://")) {
            openExternalLink(url);
        }
    };

    return (
        <Fragment key="send-calendar-invite-menu">
            <Button
                variant="plain"
                size="small"
                onClick={handleToggleMenu}
                className={cls(classes.addCalendarBtn, Boolean(anchorEl) && classes.addCalendarBtnActive)}
                key="send-calendar-invite-menu-btn"
            >
                <CalendarTodayRoundedIcon key="send-calendar-invite-menu-icon" />
                {t("lobby.add_to_calendar")}
            </Button>

            <Menu
                id="send-calendar-invite-menu-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleToggleMenu}
                MenuListProps={{
                    className: classes.menuListRoot,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                getContentAnchorEl={undefined}
                PaperProps={{
                    className: classes.paperRoot,
                }}
                key="send-calendar-invite-menu-menu"
            >
                {isLoading ? (
                    <div>
                        <Loading key="send-calendar-invite-menu-loading" relative />
                    </div>
                ) : (
                    calendarOptions.map((o, i) => (
                        <MenuItem
                            className={classes.menuItem}
                            key={"send-calendar-invite-menu-option-" + i}
                            onClick={handleCalendarOption.bind(this, o.url)}
                        >
                            <img src={o.icon} key={"send-calendar-invite-menu-option-img-" + i} />
                            <Typography color="secondary" key={"send-calendar-invite-menu-option-text-" + i}>
                                {o.name}
                            </Typography>
                        </MenuItem>
                    ))
                )}
            </Menu>
        </Fragment>
    );
};

export default SendCalendarInviteMenu;
