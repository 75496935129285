import {parseAsTipTapJSON, serialize} from "@api/editor";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import {PublicBookingEventFragment, PublicBookingEventListItem} from "@sessions/common/generated/data-types";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {useMemo, useState} from "@workhorse/api/rendering";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import classes from "../styles/WorkspaceBookings.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {useTranslation} from "react-i18next";
import CreditCardIcon from "@material-ui/icons/CreditCardRounded";
import {currencies} from "@sessions/currencies";

type WorkspaceBookingCardProps = {
    booking: Pick<
        PublicBookingEventFragment,
        | "id"
        | "name"
        | "slug"
        | "description"
        | "duration"
        | "ownerFirstName"
        | "ownerLastName"
        | "ownerProfilePicture"
        | "additionalDuration"
        | "payments"
    >;
    isMobile: boolean;
};

const WorkspaceBookingCard = ({booking, isMobile}: WorkspaceBookingCardProps) => {
    const {t} = useTranslation();

    const descriptionHtml = serialize(parseAsTipTapJSON(booking.description));
    const descriptionText = descriptionHtml.replace(/<[^>]+>/g, "  ");

    const hasDescription = descriptionText.length > 0;

    const descriptionPreview = descriptionText.slice(0, 200) + (descriptionText.length > 200 ? "..." : "");
    const descriptionPreviewMobile = descriptionText.slice(0, 100) + (descriptionText.length > 100 ? "..." : "");

    const fee = useMemo(() => {
        if (!booking.payments?.length) {
            return null;
        }
        const paymentConfig = booking.payments[0];
        const currency = currencies.find((c) => c.value === paymentConfig.currency);

        return `${currency?.symbol ?? ""}${(paymentConfig.amount / 100).toFixed(2)} ${paymentConfig.currency}`.trim();
    }, [booking.payments]);

    const url = `${window.location.origin}/book/${booking.slug ?? `p-${booking.id}`}`;

    const [showMore, setShowMore] = useState(isMobile ? descriptionText.length < 100 : descriptionText.length < 200);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const hasMultipleDuration = booking.additionalDuration && booking.additionalDuration?.length > 0;

    return (
        <div key={booking.id} className={classes.bookingCard}>
            <div className={classes.columnLeft}>
                <Typography className={classes.bookingTitle}>{booking.name}</Typography>
                {hasDescription && (
                    <Typography color="tertiary" component="div">
                        <div
                            className={classes.description}
                            // biome-ignore lint/security/noDangerouslySetInnerHtml: i know
                            dangerouslySetInnerHTML={{
                                __html: showMore ? descriptionHtml : isMobile ? descriptionPreviewMobile : descriptionPreview,
                            }}
                        />
                        {descriptionText.length > 200 ? (
                            <Button size="smallest" variant="plain" className="mt-10" onClick={handleShowMore}>
                                {showMore ? `[${t("g.show_less")}]` : `[${t("g.show_more")}]`}
                            </Button>
                        ) : null}
                    </Typography>
                )}
            </div>

            <div className={classes.columnRight}>
                <div className={classes.items}>
                    <div className="flex flex-align-center gap-6">
                        <ProfilePicture
                            firstName={booking.ownerFirstName}
                            lastName={booking.ownerLastName}
                            avatar={booking.ownerProfilePicture}
                            avatarSize="S"
                            avatarVariant="rounded"
                            disableTooltip
                        />

                        <Typography color="secondary" fontWeight="bolder">
                            {booking.ownerFirstName} {booking.ownerLastName}
                        </Typography>
                    </div>

                    <div className="flex flex-align-center gap-6">
                        <AccessTimeRoundedIcon />
                        {hasMultipleDuration ? (
                            <Tooltip
                                title={
                                    <>
                                        {t("booking.workspace_bookings.allows_for_multiple_durations")} <br /> <br />
                                        <div>
                                            {booking.duration / 60} {t("g.time.min")}
                                        </div>{" "}
                                        {booking.additionalDuration?.map((d, i) => (
                                            <div
                                                key={`duration-${
                                                    // biome-ignore lint/suspicious/noArrayIndexKey: taci
                                                    i
                                                }`}
                                            >
                                                {d / 60} {t("g.time.min")}
                                            </div>
                                        ))}
                                    </>
                                }
                                arrow
                                placement="top"
                            >
                                <Typography color="secondary" fontWeight="bolder">
                                    {t("booking.event.multiple_durations")}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography color="secondary" fontWeight="bolder">
                                {booking.duration / 60} {t("g.time.min")}
                            </Typography>
                        )}
                    </div>
                    {fee ? (
                        <div className="flex flex-align-center gap-6">
                            <CreditCardIcon />

                            <Typography color="secondary" fontWeight="bolder">
                                {fee}
                            </Typography>
                        </div>
                    ) : null}
                </div>

                <div className={"mr-auto mt-12"}>
                    <a href={url} className={classes.seeBookingButton} target="_blank" rel="noreferrer">
                        <Button size="small">{t("booking.workspace_bookings.see_booking")}</Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default WorkspaceBookingCard;
