import {RefCallback, useCallback, useLayoutEffect, useRef, useState} from "@workhorse/api/rendering";
import {ResizeObserverCallBack, ResizeObserverEntry, ResizeObserverType} from "@workhorse/providers/GlobalResizeObserver";

declare const ResizeObserver: {
    prototype: ResizeObserverType;
    new (callback: ResizeObserverCallBack): ResizeObserverType;
};

export type OnLocalResizeCallback = (width: number, height: number) => void;

export function useLocalResizeObserver<T extends HTMLElement = HTMLElement>(onResize: OnLocalResizeCallback) {
    const [node, setNode] = useState<T | null>(null);

    const onResizeRef = useRef(onResize);
    onResizeRef.current = onResize;

    const onRefChange: RefCallback<T> = useCallback((node) => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (!node) {
            return;
        }

        const cb = (entry: ResizeObserverEntry) => {
            const {height, width} = entry.contentRect;
            onResizeRef.current(width, height);
        };

        const observer = new ResizeObserver((entries) => cb(entries[0]));
        observer.observe(node);

        return () => {
            observer.disconnect();
        };
    }, [node]);

    return [onRefChange, node] as [RefCallback<T>, T | null];
}
