import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {ReactComponent as TabEmpty} from "../../../../assets/media/tab-empty.svg";
import classes from "./style/RegistrationEmptyState.module.scss";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {EventAccessType} from "./event-registration";
import Typography from "@ui/cdk/Typography";
import environment from "@generated/environment";
import toast from "@workhorse/api/toast";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Link from "@ui/cdk/Link";
import {useMixpanelTracker} from "@workhorse/api/tracking";

import {ReactComponent as FreeAccessGraphic} from "../../../../assets/media/people-group.svg";
import {ReactComponent as LockedGraphic} from "../../../../assets/media/event-locked.svg";

type RegistrationEmptyStateProps = {
    addForm: () => void;
    isEventLocked?: boolean;
    eventAccessType?: EventAccessType;
    slug?: string;
    id?: string;
};

const RegistrationEmptyState = (props: RegistrationEmptyStateProps) => {
    const {addForm, isEventLocked} = props;
    const {mixpanelTrack} = useMixpanelTracker();

    const baseURL = environment.eventBaseURL + "/";

    const handleOnSessionLinkCopy = () => {
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
        mixpanelTrack("frontend-copy-event-link", "events");
    };

    return (
        <div className={cls("flex flex-col flex-align-center", classes.root)}>
            {props.eventAccessType === EventAccessType.FreeAccess ? (
                <>
                    <FreeAccessGraphic className={classes.emptyStateGraphic} />

                    <Typography className="mb-10" variant="lg" color="tertiary" fontWeight="bold">
                        Anyone with the link will be able to join your event.
                    </Typography>

                    <CopyToClipboard text={baseURL + props.slug} onCopy={handleOnSessionLinkCopy}>
                        <Button variant="plain" size="small" onClick={() => {}}>
                            <FileCopyOutlinedIcon className="mr-4" />
                            Copy event link
                        </Button>
                    </CopyToClipboard>
                </>
            ) : props.eventAccessType === EventAccessType.Private ? (
                <>
                    <LockedGraphic className={classes.emptyStateGraphic} />

                    <Typography className="mb-10" fontWeight="bold" variant="lg" color="tertiary">
                        Only invited participants will be able to join your event.
                    </Typography>

                    <Link to={`/event/${props.id}/people`}>
                        <AddRoundedIcon className="mr-4" />
                        Invite participants
                    </Link>
                </>
            ) : (
                <>
                    <TabEmpty />

                    <Button disabled={isEventLocked} variant="plain" size="small" onClick={addForm}>
                        <AddRoundedIcon />
                        Add registration form
                    </Button>
                </>
            )}
        </div>
    );
};

export default RegistrationEmptyState;
