import {BreakoutRoomsConfigFragment} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import CircularProgress from "@ui/core/components/CircularProgress";
import Snackbar from "@ui/core/components/Snackbar";
import {useState, useEffect, useRef} from "@workhorse/api/rendering";
import {HistoryState, useHistory} from "@workhorse/api/routing";
import classes from "./BreakoutAssignNotifier.module.scss";
import CloseIcon from "@material-ui/icons/CloseRounded";
import {useSessionInfo} from "@workhorse/api/conference2/providers/SessionInfoProvider";
import {useBreakoutNavigation} from "@artifacts/breakout/api/hooks";

type BreakoutAssignNotifierProps = {
    sessionStarted: boolean;
    isChild: boolean;
    myParticipantId?: string;
    borConfig?: BreakoutRoomsConfigFragment | null;
    sessionId?: string;
    borAgendaItemId?: string | null;
};

const ASSIGN_TIMEOUT = 10000;

function BreakoutAssignNotifier(props: BreakoutAssignNotifierProps) {
    const {sessionStarted, borConfig, isChild, myParticipantId, sessionId, borAgendaItemId} = props;
    const [nextSessionId, setNextSessionId] = useState<string | null | undefined>(null);
    const {parentSessionId} = useSessionInfo();

    const history = useHistory<HistoryState>();
    const breakoutTimeoutDisabled =
        !nextSessionId ||
        nextSessionId === sessionId ||
        (!isChild ? !borAgendaItemId : false) ||
        (history.location.state?.breakoutTimeoutDisabled ?? false);

    const borAssignTimeout = useRef<NodeJS.Timeout | null>(null);

    const navigate = useBreakoutNavigation();

    useEffect(() => {
        const fromChild = parentSessionId != null;

        if (nextSessionId) {
            if (breakoutTimeoutDisabled) {
                if (borAssignTimeout.current !== null) {
                    clearInterval(borAssignTimeout.current);
                }
                if (nextSessionId !== sessionId) {
                    navigate(nextSessionId, undefined, fromChild);
                }
                return;
            }
            const start = new Date().getTime();
            borAssignTimeout.current = setInterval(() => {
                if (new Date().getTime() >= start + ASSIGN_TIMEOUT) {
                    clearInterval(borAssignTimeout.current!);

                    navigate(nextSessionId, undefined, fromChild);
                }
            }, 1000);
        }
        return () => {
            if (borAssignTimeout.current !== null) {
                clearInterval(borAssignTimeout.current);
            }
        };
    }, [nextSessionId]);

    useEffect(() => {
        if (!sessionStarted) {
            return;
        }
        if (!borAgendaItemId && !isChild) {
            if (nextSessionId !== (parentSessionId ?? sessionId)) {
                setNextSessionId(parentSessionId ?? sessionId);
            }
            return;
        }
        const room =
            myParticipantId &&
            borConfig &&
            borConfig.participantsMap.find(
                (item) => (!isChild ? item.participantInParent.id : item.participantInBreakoutRoom?.id) === myParticipantId
            );
        const targetSessionId = !borConfig || !room || !room.desiredRoomId ? sessionId! : room.desiredRoomId;
        const targetIsParent = targetSessionId === (isChild ? parentSessionId : sessionId);
        const roomExists = targetIsParent ? true : !!borConfig?.breakoutRooms.find((r) => r.id === targetSessionId);
        if (targetSessionId !== nextSessionId) {
            setNextSessionId(!roomExists ? parentSessionId ?? sessionId : targetSessionId);
        }
    }, [borConfig, sessionId, sessionStarted, isChild, myParticipantId, borAgendaItemId]);

    const goingToMainRoom = nextSessionId && borConfig?.parentOfBreakoutRooms.sessionId === nextSessionId ? true : false;
    const leavingMainRoom =
        nextSessionId && nextSessionId !== sessionId && sessionId === borConfig?.parentOfBreakoutRooms.sessionId ? true : false;
    const roomName = nextSessionId
        ? borConfig?.breakoutRooms.find((room) => {
              return room.id === nextSessionId;
          })?.name
        : null;

    return (nextSessionId && !breakoutTimeoutDisabled ? (
        <BreakoutAssignNotifyCard
            sessionId={nextSessionId}
            key={nextSessionId}
            goingToMainRoom={goingToMainRoom}
            leavingMainRoom={leavingMainRoom}
            roomName={roomName}
        />
    ) : null) as unknown as JSX.Element;
}

type BreakoutAssignNotifyCardProps = {
    sessionId: string;
    goingToMainRoom: boolean;
    leavingMainRoom: boolean;
    roomName?: string | null;
};

function BreakoutAssignNotifyCard(props: BreakoutAssignNotifyCardProps) {
    const {sessionId, goingToMainRoom, leavingMainRoom, roomName} = props;
    const [timeLeft, setTimeLeft] = useState(ASSIGN_TIMEOUT);
    const [open, setOpen] = useState(!!sessionId);

    useEffect(() => {
        let t: NodeJS.Timeout | null = null;
        if (sessionId && open) {
            t = setInterval(() => {
                setTimeLeft((current) => {
                    if (current - 1000 <= 0) {
                        setOpen(false);
                    }
                    return current - 1000;
                });
            }, 1000);
        }
        return () => {
            if ((!sessionId || !open) && t != null) {
                clearInterval(t);
            }
        };
    }, [sessionId, open]);

    const closeNotification = () => {
        setOpen(false);
    };

    const pctLeft = (timeLeft * 100) / ASSIGN_TIMEOUT;

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <div data-id="assign-notification" className={cls("flex", classes.root)}>
                <div className={cls("flex flex11-auto flex-center-all")}>
                    <div className={cls("flex flex00-auto flex-center-all", classes.timeleft)}>
                        <div className={classes.skew}>
                            <CircularProgress color="primary" value={pctLeft} variant="determinate" />
                        </div>
                        <Typography variant="lg" className={cls("flex flex-center-all", classes.timeTxt)}>
                            {timeLeft / 1000}
                        </Typography>
                    </div>
                    <div className={cls("flex flex11-auto flex-col", classes.middleTxt)}>
                        <Typography variant="lg" fontWeight="bold">
                            {goingToMainRoom ? "Breakout room ending" : leavingMainRoom ? "Breakout rooms starting" : "Breakout rooms"}
                        </Typography>
                        <Typography variant="base">
                            {goingToMainRoom
                                ? "You are being transferred back to the Main Room"
                                : leavingMainRoom
                                ? `You are being transferred to${roomName ? `: "${roomName}"` : " another room"}`
                                : "You are being transferred to another room"}
                        </Typography>
                    </div>
                </div>
                <div className={cls("flex", classes.closeBtnWrap)}>
                    <button className={cls("flex flex-center-all", classes.closeBtn)} onClick={closeNotification}>
                        <CloseIcon fontSize="small" />
                    </button>
                </div>
            </div>
        </Snackbar>
    );
}

export default BreakoutAssignNotifier;
