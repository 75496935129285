import {useParticipantReaction} from "@workhorse/providers/SessionReactionsProvider";
import {reactions} from "@workhorse/pages/player/components/Reactions/api";
import {Transition, TransitionGroup} from "react-transition-group";
import classes from "../styles/ParticipantReaction.module.scss";
import {cls} from "@ui/cdk/util";
import {ReactionType} from "@generated/data";
import {useTranslation} from "react-i18next";
const TRANSITION_DURATION = 200;

type ParticipantReactionProps = {
    pId: string;
    className?: string;
    isLogoBig?: boolean;
};

const ParticipantReaction = (props: ParticipantReactionProps) => {
    const {t} = useTranslation();
    const {pId, className, isLogoBig} = props;

    const participantReactions = useParticipantReaction(pId);
    const showReaction = participantReactions.filter((r) => r.timestamp <= new Date().getTime()).slice(-1)[0];

    const isRaiseHand = showReaction?.type === ReactionType.Raisehand;

    return (
        <TransitionGroup component={null} key="participant-reaction-transition-group">
            {showReaction ? (
                <Transition
                    mountOnEnter
                    unmountOnExit
                    timeout={TRANSITION_DURATION}
                    key={"participant-reaction-transition-" + showReaction.id}
                >
                    {(state) => (
                        <img
                            data-id="participant-reaction"
                            src={isLogoBig ? reactions[showReaction.type].iconBig : reactions[showReaction.type].iconSmall}
                            alt={t(`participant.reaction.${reactions[showReaction.type].key}`) || reactions[showReaction.type].name}
                            className={cls(
                                classes.root,
                                isRaiseHand && isLogoBig
                                    ? classes[`raiseHand-${state}`]
                                    : isRaiseHand && !isLogoBig
                                    ? classes[`raiseHandBig-${state}`]
                                    : classes[state],
                                className
                            )}
                            style={{transitionDuration: `${TRANSITION_DURATION}ms`}}
                            key={"participant-reaction-" + showReaction.id}
                        />
                    )}
                </Transition>
            ) : null}
        </TransitionGroup>
    );
};

export default ParticipantReaction;
