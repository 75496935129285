import simpleArtifactsMap, {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import {SessionsEditor} from "@workhorse/components/editor/api";
export {isNodeEmpty, isList} from "@tiptap/core";
export {generateHTML, generateJSON} from "@tiptap/html";
import type {JSONContent, Editor} from "@tiptap/core";
import type {Level as EditorHeadingLevel} from "@tiptap/extension-heading";
export {generateText} from "@tiptap/react";

export type {EditorHeadingLevel, JSONContent, Editor};
export type EditorTextFormatting = "bold" | "italic" | "strike" | "underline" | "strickenThrough";

export type CustomGenericText = {text: string; marks?: {type: EditorTextFormatting}[]} & Record<EditorTextFormatting, boolean>;

export type CustomArtifactText = {text: string};

export type CustomText = CustomGenericText | CustomArtifactText;

export type EditorAlignment = "left" | "center" | "right";
export type EditorHeadingElement = "heading";
export type EditorListElement = "bulletList" | "orderedList" | "taskList";
export type EditorListItemElement = "listItem" | "taskItem";
export type EditorParagraphElement = "paragraph";
export type EditorTextElement = "text";

export type EditorArtifactElement = SimpleArtifactsTag;

export type EditorElement = EditorHeadingElement | EditorListElement | EditorListItemElement | EditorParagraphElement | EditorTextElement;

export type CustomArtifactElement = {type: EditorArtifactElement; order: number; icon: string; name: string; children: CustomText[]};

export type SlateElementType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "ul" | "ol" | "li" | "paragraph";

export type SlateGenericElement = {
    type: SlateElementType;
    alignment: EditorAlignment;
    indentation: number;
    children: (CustomText | CustomGenericElement)[];
};

export type CustomGenericElement = {
    type: EditorElement;
    content: JSONContent[];
    attrs?: Record<string, any>;
    text?: string;
};

export type CustomElement = CustomGenericElement;

export type EditorPlugin = (editor: SessionsEditor) => SessionsEditor;

export type EditorEvent = (editor: SessionsEditor, event: React.KeyboardEvent | React.SyntheticEvent) => void;

export type EnabledPluggedArtifact = {
    id: EditorArtifactElement;
    name: string;
    order: number;
    enabled: boolean;
    icon?: string | undefined;
};

export type ArtifactsEditor = SessionsEditor & {
    toggleArtifact: (artifact: EditorArtifactElement, order: number) => void | boolean;
    artifactStatus: () => EnabledPluggedArtifact[];
    normalizeArtifacts: () => void;
};

export type LimitedEditor = SessionsEditor & {
    getStringLength: () => number;
    hasReachedMaxLength: boolean;
};

export type CollaborationEditor = SessionsEditor & {
    onCollaborationChange: (editing: boolean, editingDetails: CollaborationEditor["editingDetails"]) => void;
    onCollaborationRequest: (request: boolean) => void;

    requestControl: () => void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editingDetails: (props: any) => JSX.Element | null;
    editingRequest: boolean;
    editing: boolean;
};

export const EDITOR_TEXT_FORMATTING: EditorTextFormatting[] = ["bold", "italic", "strike", "strickenThrough", "underline"];

export const EDITOR_HEADINGS: EditorHeadingElement[] = ["heading"];
export const EDITOR_HEADING_LEVELS: EditorHeadingLevel[] = [1, 2, 3, 4, 5, 6];
export const EDITOR_TEXT_ALIGNMENTS: EditorAlignment[] = ["left", "center", "right"];

export const EDITOR_ARTIFACTS: EditorArtifactElement[] = Object.keys(simpleArtifactsMap) as EditorArtifactElement[];

export const EDITOR_LISTS: EditorListElement[] = ["bulletList", "orderedList"];
export const EDITOR_LIST_ITEM: EditorListItemElement[] = ["listItem"];

export const EDITOR_GENERIC_ELEMENT: EditorParagraphElement[] = ["paragraph"];

export const BLOCK_TYPES: Record<string, EditorElement> = {
    P: "paragraph",
};

export const BLOCK_TYPES_REVERSED: Record<string, string> = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    ul: "ul",
    li: "li",
    ol: "ol",
    paragraph: "p",
};

export const INDENTATION = 20;
