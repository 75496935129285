import env from "@generated/environment";
import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useRef} from "@workhorse/api/rendering";
import {MicroControllerProps} from "@workhorse/declarations";
import {updateArtifactProperty} from "@workhorse/providers/state";
import classes from "@workhorse/style/CommonModules/microController.module.scss";
import {Field, Form, Formik, FormikProps} from "formik";
import descriptor from "@generated/artifacts";
import {noop} from "@common/utils/noop";
import {DUMMY_AGENDA_ITEM_TITLE} from "@workhorse/api/designer";
import {useTranslation} from "react-i18next";

interface FormValuesProps {
    isCollaborative: boolean;
}

function MicroController(props: MicroControllerProps) {
    const {t} = useTranslation();
    const {currentMicro: artifact} = props;

    const formRef = useRef<FormikProps<FormValuesProps>>(null);

    const initialValues = {
        isCollaborative: artifact.data?.isCollaborative ? artifact.data.isCollaborative === "true" : true,
    } as FormValuesProps;

    const iconSrc = `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[props.currentMicro.artifactId]?.meta?.icon}.svg`;

    const onChangeCollaborativeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateArtifactProperty(artifact.id, "isCollaborative", event.target.checked ? "true" : "false");
        formRef.current?.setFieldValue("isCollaborative", event.target.checked);
    };

    return (
        <>
            {props.hasAgenda ? (
                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.titleContainer)}>
                    <div className="flex fullw">
                        <img className={classes.artifactIcon} src={iconSrc}></img>
                        <Typography className={cls(classes.artifactTitle, classes.label)}>
                            {props.agendaItemTitle && props.agendaItemTitle !== DUMMY_AGENDA_ITEM_TITLE
                                ? props.agendaItemTitle
                                : "Whiteboard"}
                        </Typography>
                    </div>
                </div>
            ) : null}

            <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={noop}>
                {({values}) => (
                    <Form className={cls("flex flex-wrap flex00-auto fullw")}>
                        <Field name="isCollaborative">
                            {() => (
                                <div
                                    className={cls(
                                        "flex flex-justify-between flex-align-center fullw",
                                        classes.settingsContainer,
                                        !props.hasAgenda ? classes.settingsNoMarginTop : undefined
                                    )}
                                >
                                    <Typography className={classes.settingsTitle}>
                                        {t("artifacts.g.micro_controller.collaborative")}
                                    </Typography>
                                    <Switch
                                        data-id="collaborative"
                                        color="primary"
                                        onChange={onChangeCollaborativeMode}
                                        checked={values.isCollaborative}
                                    />
                                </div>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default {
    MicroController,
};
