import {Offer} from "@generated/data";
import React from "react";
import ImportOfferItem from "./ImportOfferItem";
import classes from "../styles/ImportOfferList.module.scss";
import Button from "@ui/cdk/Button";
import OfferEmptyState from "./OfferEmptyState";

type ImportOfferListProps = {
    userOffers: Offer[] | undefined;
    sessionOffers: Offer[] | undefined;
    onEditOffer: (offerId: string) => void;
    selectedOffers: string[];
    onSelectOffer: (event: React.ChangeEvent<HTMLInputElement>, offerId: string) => void;
};

const ImportOfferList = (props: ImportOfferListProps) => {
    const {userOffers, sessionOffers, onEditOffer, selectedOffers, onSelectOffer} = props;

    return (
        <div className={classes.root}>
            {userOffers?.length ? (
                <div className={classes.listContainer}>
                    {userOffers?.map((offer) => {
                        const isImportedAlready = sessionOffers?.some((sessionOffer) => sessionOffer.id === offer.id) ?? false;
                        return (
                            <ImportOfferItem
                                key={offer.id}
                                id={offer.id}
                                title={offer.title}
                                description={offer.description}
                                imageUrl={offer.imageUrl}
                                onEditOffer={onEditOffer}
                                selectedOffers={selectedOffers}
                                onSelectOffer={onSelectOffer}
                                isImportedAlready={isImportedAlready}
                            />
                        );
                    })}
                </div>
            ) : (
                <OfferEmptyState isOrganizer={true} />
            )}
        </div>
    );
};

export default ImportOfferList;
