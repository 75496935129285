import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/BlockedPermissionsModal.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@ui/core/components/IconButton";
import {useEffect, useRef} from "react";
import {BlockedPermissionsImg} from "./RequestPermissionsDialog";

type BlockedPermissionsDialogProps = {
    open: boolean;
    hasCamera: boolean;
    onClose: () => void;
};

export function NoDevicesDialog(props: BlockedPermissionsDialogProps) {
    const imperativeRef = useRef<DialogImperativeRef>();

    useEffect(() => {
        imperativeRef.current?.toggle?.(props.open);
    }, [props.open]);

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            classes={{
                paper: classes.paper,
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop-with-backdrop-filter",
                },
            }}
            imperativeRef={imperativeRef}
            open={props.open}
            disableBackdropClick
        >
            <div className={cls("flex flex-justify-between", classes.dialogHeader)}>
                <Typography className={classes.dialogHeaderTitle}>
                    {props.hasCamera ? "No microphone detected" : "No microphone and camera detected"}
                </Typography>

                <IconButton onClick={props.onClose} className={cls("p-0 mb-auto", classes.closeButton)}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <div>
                <div>
                    <BlockedPermissionsImg hideCamera={props.hasCamera} />

                    <Typography variant="lg" className={classes.infoTextSimple}>
                        Sessions needs to access your microphone so that others can hear you.
                    </Typography>
                </div>
            </div>

            <div className="flex flex-col">
                <Button variant="primary" className={cls("mb-15", classes.footerButton)} onClick={props.onClose}>
                    Continue without devices
                </Button>
            </div>
        </Dialog>
    );
}
