import {setParticipantConferenceRole} from "@artifacts/conference/api/conferenceControllerApi";
import {ConferenceParticipantStatus, ParticipantChangedBy} from "@generated/data";
import {ConfirmationSnackbarImperativeRef} from "@ui/cdk/ConfirmationSnackbar";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {Participant} from "@workhorse/declarations/dataTypes";
import {microNotify} from "../micro-notification-center/api";
import {
    AssistantRoleNotification,
    AssistantRoleNotificationType,
} from "../micro-notification-center/notifications/AssistantRoleNotification";
import {SpotlightNotification, SpotlightNotificationType} from "../micro-notification-center/notifications/SpotlightNotification";

type ConferenceStatusChangesProps = {
    myParticipantObj: Participant;
    isAssistant: boolean;
};

export default function ConferenceStatusChanges(props: ConferenceStatusChangesProps) {
    const {isAssistant, myParticipantObj} = props;
    const {conferenceStatus: myConferenceStatus, id: myParticipantId, changedBy, statusChangedBy, isOwner} = myParticipantObj;

    const prevStatus = useRef(myConferenceStatus);
    const prevAssistantStatus = useRef(isAssistant ? true : undefined);

    const beSpeakerConfirmationRef: ConfirmationSnackbarImperativeRef = useRef();
    const unmuteRevokedRef: ConfirmationSnackbarImperativeRef = useRef();
    const beSpeakerRevokedRef: ConfirmationSnackbarImperativeRef = useRef();

    const muteMicrophoneRef: ConfirmationSnackbarImperativeRef = useRef();
    const assistantNotificationRef: ConfirmationSnackbarImperativeRef = useRef();

    // precisely because it is possible to have multiple messages shown at the same time
    // every time the status changes, the first thing we do, is close whatever might be open
    useEffect(() => {
        if (beSpeakerConfirmationRef.current?.isOpen && beSpeakerConfirmationRef.current?.toggle) {
            beSpeakerConfirmationRef.current.toggle();
        }
        if (unmuteRevokedRef.current?.isOpen && unmuteRevokedRef.current?.toggle) {
            unmuteRevokedRef.current.toggle();
        }
        if (beSpeakerRevokedRef.current?.isOpen && beSpeakerRevokedRef.current?.toggle) {
            beSpeakerRevokedRef.current.toggle();
        }
        if (muteMicrophoneRef.current?.isOpen && muteMicrophoneRef.current?.toggle) {
            muteMicrophoneRef.current.toggle();
        }
        if (assistantNotificationRef.current?.isOpen && assistantNotificationRef.current?.toggle) {
            assistantNotificationRef.current.toggle();
        }
    }, [myConferenceStatus]);

    useEffect(() => {
        const invitedToSpeak = myConferenceStatus === ConferenceParticipantStatus.InvitedToSpeak;

        const byHimself =
            changedBy === ParticipantChangedBy.Himself ||
            (isOwner && changedBy === ParticipantChangedBy.Owner) ||
            statusChangedBy?.participantId === myParticipantId;

        const initiatedByMe = statusChangedBy?.participantId === myParticipantId;

        const declinedSpeaker =
            myConferenceStatus === ConferenceParticipantStatus.Participant &&
            prevStatus.current === ConferenceParticipantStatus.PendingSpeaker &&
            !byHimself;

        const invitationToSpeakerRevoked =
            myConferenceStatus === ConferenceParticipantStatus.Participant &&
            prevStatus.current === ConferenceParticipantStatus.InvitedToSpeak &&
            !byHimself;

        const removedSpeaker =
            myConferenceStatus === ConferenceParticipantStatus.Participant &&
            prevStatus.current === ConferenceParticipantStatus.Speaker &&
            !byHimself;

        if (invitedToSpeak && beSpeakerConfirmationRef.current?.toggle) {
            beSpeakerConfirmationRef.current.toggle();
        }

        if (invitationToSpeakerRevoked) {
            microNotify<SpotlightNotificationType>({
                component: SpotlightNotification,
                message: {
                    user:
                        statusChangedBy?.firstName && statusChangedBy.lastName
                            ? `${statusChangedBy?.firstName} ${statusChangedBy?.lastName}`
                            : "The host",
                    action: "missed",
                },
                type: "spotlight",
            });
        }

        if (declinedSpeaker) {
            microNotify<SpotlightNotificationType>({
                component: SpotlightNotification,
                message: {
                    user:
                        statusChangedBy?.firstName && statusChangedBy.lastName
                            ? `${statusChangedBy?.firstName} ${statusChangedBy?.lastName}`
                            : "The host",
                    action: "decline",
                },
                type: "spotlight",
            });
        }
        if (removedSpeaker) {
            microNotify<SpotlightNotificationType>({
                component: SpotlightNotification,
                message: {
                    user:
                        statusChangedBy?.firstName && statusChangedBy.lastName
                            ? `${statusChangedBy?.firstName} ${statusChangedBy?.lastName}`
                            : "The host",
                    action: "remove",
                },
                type: "spotlight",
            });
        }

        if (
            initiatedByMe &&
            statusChangedBy?.unableToBecomeSpeaker === true &&
            prevStatus.current === ConferenceParticipantStatus.InvitedToSpeak
        ) {
            microNotify<SpotlightNotificationType>({
                component: SpotlightNotification,
                message: {
                    user: "",
                    action: "too-many-speakers",
                },
                type: "spotlight",
            });
        }
        prevStatus.current = myConferenceStatus;
    }, [myConferenceStatus]);

    useEffect(() => {
        if ((isAssistant && !prevAssistantStatus.current) || (!isAssistant && prevAssistantStatus.current)) {
            microNotify<AssistantRoleNotificationType>({
                component: AssistantRoleNotification,
                message: {
                    user:
                        statusChangedBy?.firstName && statusChangedBy.lastName
                            ? `${statusChangedBy?.firstName} ${statusChangedBy?.lastName}`
                            : "The host",
                    action: isAssistant ? "assigned" : "unassigned",
                },
                type: "assistant-role",
            });
        }
        prevAssistantStatus.current = isAssistant;
    }, [isAssistant]);

    const onAcceptedToBeSpeaker = () => {
        setParticipantConferenceRole(myParticipantId, ConferenceParticipantStatus.Speaker);
    };

    const onRejectedToBeSpeaker = () => {
        setParticipantConferenceRole(myParticipantId, ConferenceParticipantStatus.Participant);
    };

    return <></>;
}
