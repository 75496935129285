import {createContextProvider} from "@workhorse/api/utils/context";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useRoomStore} from "../ConferenceRoomProvider";
import {useParticipantsStore} from "../ParticipantsProvider/LocalParticipantsStore";

type ParticipantVolumes = Array<{
    participantId: string;
    volume: number;
}>;

const maxActiveSpeakers = 1;

function normalizeVolumes(volumes: Record<string, number | undefined>): ParticipantVolumes {
    const keys = Object.keys(volumes);
    const normalized: ParticipantVolumes = [];

    for (const key of keys) {
        const volume = Number(volumes[key]);

        if (Number.isNaN(volume)) {
            continue;
        }

        normalized.push({
            volume,
            participantId: key,
        });
    }

    return normalized;
}

function sortVolumes(volume: ParticipantVolumes) {
    return volume.slice().sort((a, b) => b.volume - a.volume);
}

function rebuildParticipantList(currentIds: string[], volumes: ParticipantVolumes) {
    const speakers = volumes.filter((item) => item.volume > 0);

    if (speakers.length >= maxActiveSpeakers) {
        return sortVolumes(speakers)
            .slice(0, maxActiveSpeakers)
            .map((item) => item.participantId);
    }

    const speakerIds = speakers.map((item) => item.participantId);
    const remaining = currentIds.filter((id) => !speakerIds.includes(id));
    return speakerIds.concat(remaining).slice(0, maxActiveSpeakers);
}

function supplementActiveParticipants(activeParticipantIds: string[], participants: Participant[]) {
    // ids from chime might lag behind the actual participant list, so we filter them again
    const joinedActiveParticipantIds = participants
        .filter((participant) => activeParticipantIds.includes(participant.id))
        .map((participant) => participant.id);

    // If we have active participants return without supplementing
    if (joinedActiveParticipantIds.length) {
        return joinedActiveParticipantIds;
    }

    // Else, select the maximum possible participants to supplement
    const sortedParticipants = participants
        .slice()
        .sort((a, b) => (a.dataWithNullableEmail?.firstName ?? "").localeCompare(b.dataWithNullableEmail?.firstName));

    const fillerIds = sortedParticipants.slice(0, maxActiveSpeakers).map((participant) => participant.id);
    return fillerIds;
}

function sortByPreviousList(previousParticipants: Participant[], currentIds: string[]) {
    const previousIds = previousParticipants.map((participant) => participant.id);
    const missingIds = previousIds.filter((id) => !currentIds.includes(id));
    const newIds = currentIds.filter((id) => !previousIds.includes(id));

    const sortedIds: string[] = [];

    for (const previousId of previousIds) {
        if (!missingIds.includes(previousId)) {
            sortedIds.push(previousId);
            continue;
        }

        const newId = newIds.pop();
        if (newId) {
            sortedIds.push(newId);
        }
    }

    sortedIds.push(...newIds);

    return sortedIds;
}

function buildActiveSpeakers(participants: Participant[], previousParticipants: Participant[], activeParticipantIds: string[]) {
    const allIds = supplementActiveParticipants(activeParticipantIds, participants);
    const sortedIds = sortByPreviousList(previousParticipants, allIds);

    const currentParticipants = participants
        .filter((participant) => sortedIds.includes(participant.id))
        .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));

    return currentParticipants;
}

export function useActiveParticipantIds() {
    const volumes = useRoomStore((state) => state.activeSpeakersScore);
    const [participantIds, setParticipantIds] = useState<string[]>([]);

    useEffect(() => {
        const normalized = normalizeVolumes(volumes);
        setParticipantIds((currentIds) => rebuildParticipantList(currentIds, normalized));
    }, [volumes]);

    return participantIds;
}

export function useActiveSpeakersStore() {
    const participants = useParticipantsStore((state) => state.joinedFullList);
    const activeParticipantIds = useActiveParticipantIds();

    const [activeParticipants, setActiveParticipants] = useState<Participant[]>(() =>
        buildActiveSpeakers(participants, [], activeParticipantIds)
    );

    const previousParticipantsRef = useRef(activeParticipants);

    useEffect(() => {
        setActiveParticipants((previous) => buildActiveSpeakers(participants, previous, activeParticipantIds));
    }, [participants, activeParticipantIds]);

    return useMemo(() => {
        const previous = previousParticipantsRef.current;

        if (activeParticipants.length !== previous.length) {
            previousParticipantsRef.current = activeParticipants;
            return activeParticipants;
        }

        for (let i = 0; i < activeParticipants.length; i++) {
            if (activeParticipants[i] !== previous[i]) {
                previousParticipantsRef.current = activeParticipants;
                return activeParticipants;
            }
        }

        return previous;
    }, [activeParticipants]);
}

export const [ActiveSpeakersListProvider, useActiveSpeakers] = createContextProvider(
    {
        name: "ActiveSpeakersList",
    },
    useActiveSpeakersStore
);
