import {useDevices} from "@workhorse/api/conference2";
import {useTranslation} from "react-i18next";
import {cls} from "@ui/cdk/util";
import style from "./styles/AudienceParticipant.module.scss";
import VideoOffIcon from "@material-ui/icons/VideocamOff";
import {ReactComponent as VideoPoorIcon} from "../../../../../assets/media/video-poor.svg";
import Tooltip from "@ui/cdk/Tooltip";

export default function LocalVideoIcon(props: {
    className?: string;
    participantId: string;
    isThumbnailMode?: boolean;
    onClick?: () => void;
}) {
    const {t} = useTranslation();
    const {videoStream} = useDevices();

    const poorConnection = false; // useAttendeePoorVideo(props.participantId);

    if (!videoStream) {
        return (
            <div
                className={cls(style.statusIconContainer, style.statusOff, props.className, props.isThumbnailMode ? style.isThumbnail : "")}
                onClick={props.onClick}
            >
                <VideoOffIcon data-id="video-off" />
            </div>
        );
    }

    if (poorConnection) {
        return (
            <Tooltip title={t("participant.camera.video_was_paused_due_to_connection") ?? ""} arrow={true}>
                <VideoPoorIcon className={props.className} data-id="video-poor" onClick={props.onClick} />
            </Tooltip>
        );
    }

    return null;
}
