import {forwardRef, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {Session} from "@workhorse/declarations/dataTypes";
import LobbyCoverImageContent from "./LobbyCoverImageContent";
import LobbyCoverImageReposition from "./LobbyCoverImageReposition";
import classes from "./styles/LobbyCoverImage.module.scss";
import {uploadLobbyPicture} from "./utils";
import useTheme from "@material-ui/core/styles/useTheme";
import {cls} from "@ui/cdk/util/util";
import {useDrawerLeftWidth} from "@workhorse/providers/useDrawerLeftWidth";
import {useDrawerRightWidth} from "@workhorse/providers/useDrawerRightWidth";
import {useDrawerLeftToggler, useDrawerRightToggler} from "@workhorse/providers/state";
import {useTranslation} from "react-i18next";

type LobbyCoverImageContainerProps = {
    session: Session;
    isMobile: boolean;
    handleToggleCoverMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
    setIsCoverImageUploaded: React.Dispatch<React.SetStateAction<boolean>>;
    isCoverImageUploaded: boolean;
    isRepositioning: boolean;
    setIsRepositioning: React.Dispatch<React.SetStateAction<boolean>>;
    isAdmin: boolean;
    isAssistant: boolean;
    isDefaultLobbyImage: boolean;
};

const LobbyCoverImageContainer = forwardRef((props: LobbyCoverImageContainerProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
    const {t} = useTranslation();
    const {session, handleToggleCoverMenu, isMobile, isDefaultLobbyImage} = props;

    const [yPosition, setYPosition] = useState<number>(session.lobbyPicture?.y ?? 50);

    const theme = useTheme();
    const coverHeight = props.isMobile ? theme.main.heights.coverHeightMobile : theme.main.heights.coverHeightDesktop;
    const [hideRnd, setHideRnd] = useState(true);
    const [isCoverImageLoaded, setIsCoverImageLoaded] = useState(false);
    const canChangeCover = props.isAdmin;

    const [drawerRightState] = useDrawerRightToggler();
    const [drawerLeftState] = useDrawerLeftToggler();
    const dwLeftWidth = useDrawerLeftWidth();
    const dwRightWidth = useDrawerRightWidth();

    const imgRef = useRef<HTMLImageElement | null>(null);

    const toggleReposition = () => {
        setIsCoverImageLoaded(false);
        props.setIsRepositioning(!props.isRepositioning);
    };

    const savePosition = async (newYPosition: number) => {
        setYPosition(newYPosition);
        setIsCoverImageLoaded(false);
        setHideRnd(true);

        if (session.lobbyPicture) {
            await uploadLobbyPicture(
                session.id,
                {...session.lobbyPicture, y: newYPosition, canBeRepositioned: true},
                undefined,
                undefined,
                undefined,
                isDefaultLobbyImage
            );
            props.setIsRepositioning(false);
        }
    };

    const handleCoverImageLoaded = () => {
        setIsCoverImageLoaded(true);
        props.setIsCoverImageUploaded(true);
    };

    useEffect(() => {
        if (props.isCoverImageUploaded) {
            setYPosition(props.session.lobbyPicture?.y ?? 50);
        }
    }, [props.isCoverImageUploaded, props.session.lobbyPicture?.originalPictureUrl, props.session.lobbyPicture?.y]);

    useEffect(() => {
        const header = document.getElementById("main-app-header");
        if (!header) {
            return;
        }

        if (props.isRepositioning && !header.classList.contains("no-pointer-events")) {
            header.classList.add("no-pointer-events");
        } else if (!props.isRepositioning && header.classList.contains("no-pointer-events")) {
            header.classList.remove("no-pointer-events");
        }
    }, [props.isRepositioning]);

    return (
        <div className={cls("flex fullw flex00-100", classes.containerBase)}>
            {session.lobbyPicture?.originalPictureUrl ? (
                <div className={classes.coverImage}>
                    {/* becomes visible when trying to drag the image out of bounds, so it looks like the image just stops at the bounds */}
                    {/* rnd sets initial y position based on its height*/}

                    <img
                        style={{
                            top: imgRef.current ? ((-imgRef.current.height + coverHeight) * yPosition) / 100 : 0,
                            visibility:
                                (isCoverImageLoaded && hideRnd && !props.isRepositioning) ||
                                (props.isRepositioning && !hideRnd) ||
                                (!props.isRepositioning && !isCoverImageLoaded && !props.isCoverImageUploaded)
                                    ? "hidden"
                                    : "visible",

                            height: "auto",
                        }}
                        src={session.lobbyPicture.originalPictureUrl}
                        alt={t("lobby.cover_image.name") ?? undefined}
                        ref={imgRef}
                    />
                </div>
            ) : null}

            {props.isRepositioning ? (
                <LobbyCoverImageReposition
                    session={session}
                    imgRef={imgRef}
                    hideRnd={hideRnd}
                    coverHeight={coverHeight}
                    yPosition={yPosition}
                    setYPosition={setYPosition}
                    setHideRnd={setHideRnd}
                    setIsCoverImageLoaded={setIsCoverImageLoaded}
                    isRepositioning={props.isRepositioning}
                    toggleReposition={toggleReposition}
                    savePosition={savePosition}
                    isMobile={isMobile}
                    drawerLeftOpen={drawerLeftState.isFullyOpen}
                    drawerLeftWidth={dwLeftWidth.isFullyOpen}
                    drawerRightOpen={drawerRightState.isFullyOpen}
                    drawerRightWidth={dwRightWidth.isFullyOpen}
                />
            ) : (
                <LobbyCoverImageContent
                    session={session}
                    handleCoverImageLoaded={handleCoverImageLoaded}
                    isCoverImageUploaded={props.isCoverImageUploaded}
                    yPosition={yPosition}
                    ref={ref}
                    handleToggleCoverMenu={handleToggleCoverMenu}
                    isMobile={isMobile}
                    canChangeCover={canChangeCover}
                    drawerLeftOpen={drawerLeftState.isFullyOpen}
                    drawerLeftWidth={dwLeftWidth.isFullyOpen}
                    drawerRightOpen={drawerRightState.isFullyOpen}
                    drawerRightWidth={dwRightWidth.isFullyOpen}
                />
            )}
        </div>
    );
});

export default LobbyCoverImageContainer;
