import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";
import {OnboardingPaletteToolsActionList} from "../onboarding-tooltips/onboarding-palette-tools-action-list";
import {categById, categMap} from "./actionCategTree";
import {currentHighlightedItem, setHighlightedItem} from "./CommandPalette";
import {CommandPaletteFilters} from "./CommandPaletteFilters";
import {PaletteActiveCateg, useBeResultCount, usePaletteActiveCateg, usePaletteHasSearchString} from "./CommandPaletteProviders";
import {PaletteVisibleItems, usePaletteResultList, useVisibleCategItems} from "./CommandPaletteResultsProvider";
import PaletteContentSessionInfoGetter from "./PaletteContentSessionInfoGetter";
import PaletteExecuteAction from "./PaletteExecuteAction";
import PaletteItemInfoArea from "./PaletteItemInfoArea";
import PaletteRenderOneAction from "./PaletteRenderOneAction";
import PaletteSetAction from "./PaletteSetAction";
import {PaletteUploadError} from "./PaletteUploadError";
import RenderBEsearchResults from "./RenderBEsearchResults";
import classes from "./style/CommandPaletteContent.module.scss";
import {useTranslation} from "react-i18next";

export function computeVisibleItems(activeCateg: PaletteActiveCateg, hasSearchStr: boolean, visibleItems: PaletteVisibleItems) {
    const out = categMap.filter((obj) => visibleItems.items.includes(obj.id) && !obj.isHidden);
    return out;
}

type MainTitleProps = {
    showItemsCount: number;
    title: string;
    isResult?: boolean;
};

function MainTitle(props: MainTitleProps) {
    const {t} = useTranslation();
    const {showItemsCount, title, isResult} = props;
    const beResultCount = useBeResultCount();
    return (showItemsCount ? !isResult : !beResultCount) ? (
        <Typography component="span" variant="sm" className={cls(classes.areaTitle)}>
            {showItemsCount + beResultCount === 0 ? t("g.no_results" ?? "No results found") : title}
        </Typography>
    ) : null;
}

type CommandPaletteContentProps = {} & WithChildren;
export default function CommandPaletteContent(props: CommandPaletteContentProps) {
    const {t} = useTranslation();
    const {activeCateg, setPaletteActiveCateg} = usePaletteActiveCateg();
    const {visibleItems} = useVisibleCategItems();
    const {hasSearchStr, getSearchStr} = usePaletteHasSearchString();
    const {resultList} = usePaletteResultList();

    const [isOnboarding, setIsOnboarding] = useState<boolean | null>(false);

    const scrollRef = useRef<ScrollContainerRef>({});

    const [rootRef, setRootRef] = useState<HTMLDivElement>();
    const ref = useCallback((node: HTMLDivElement) => {
        setRootRef(node);
    }, []);

    const current = useMemo(() => {
        if (visibleItems.isCommand) {
            const child = categMap.find((obj) => visibleItems.items.includes(obj.id));
            const item = categMap.find((obj) => obj.id === child?.parentId);
            return {
                item,
                parent: categMap.find((obj) => obj.id === item?.parentId),
            };
        }
        return {
            item: categById(activeCateg.name, activeCateg.id),
            parent: categById(activeCateg.name, activeCateg.parentId),
        };
    }, [activeCateg.id, activeCateg.name, activeCateg.parentId, visibleItems.isCommand]);

    const showItems = useMemo(() => {
        if (!isOnboarding) {
            return computeVisibleItems(activeCateg, hasSearchStr, visibleItems);
        } else {
            const items = computeVisibleItems(activeCateg, hasSearchStr, visibleItems);
            //arrange items so that blackboard is first
            const blackboardIndex = items.findIndex((item) => item.artifactTag === "flowos/blackboard");
            if (blackboardIndex !== -1) {
                items.splice(0, 0, items.splice(blackboardIndex, 1)[0]);
            }
            return items;
        }
    }, [activeCateg.name, activeCateg.id, activeCateg.parentId, visibleItems, hasSearchStr, isOnboarding]);

    useEffect(() => {
        const highlightId = currentHighlightedItem(rootRef);
        if (showItems.length && (!highlightId || showItems.map((obj) => obj.id).indexOf(highlightId) === -1)) {
            setHighlightedItem(rootRef, showItems[0]?.id);
        }
    }, [showItems]);

    const title = useMemo(() => {
        let out =
            !activeCateg.name || (showItems.length === 1 && showItems[0].isAction)
                ? t("palette.actions") ?? "Actions"
                : t("palette.tools.title") ?? "Tools";
        if (showItems.length === 1 && showItems[0].isPlaceholder) {
            out = t("palette.result") ?? "Result";
        }
        return out;
    }, [showItems, activeCateg.name, resultList]);

    const isResult = useMemo(() => {
        return showItems.length === 1 && showItems[0].isPlaceholder;
    }, [showItems]);

    const scrollIntoView = useCallback((node: HTMLElement, location: "start" | "end") => {
        scrollRef.current.scrollChildIntoView?.({
            node,
            location,
            preventIfVisible: true,
        });
    }, []);

    return (
        <div className={cls("fullw flex flex-col overflow-hidden flex11-auto")}>
            <div
                id="palette-content-root"
                className={cls("fullw flex11-auto overflow-hidden flex-col", classes.paletteResultsRootInner)}
                ref={ref}
            >
                <div
                    className={cls(
                        "fullw flex flex-col overflow-hidden",
                        classes.paletteContentMainActionsOuter,
                        resultList.length && "with-border-btm",
                        showItems.length === 0 && classes.hide,
                        showItems.length > 0 && title === "Tools" && !resultList.length && "can-scroll"
                    )}
                >
                    <OnboardingPaletteToolsActionList activeCateg={activeCateg}>
                        <ScrollContainer
                            connector={scrollRef}
                            isMobile={false}
                            shadowOnScroll={{showOn: "both"}}
                            shadowGradient="none"
                            shadowIsSmall={true}
                            wrapperClassName={cls("fullw flex flex00-100 flex-col", classes.paletteContentMainActions)}
                            component="div"
                            className={cls("fullw flex flex00-100 flex-col", classes.paletteContentMainActionsInner)}
                        >
                            <MainTitle showItemsCount={showItems.length} title={title} isResult={isResult} />
                            {showItems?.map((item) => {
                                return (
                                    <PaletteRenderOneAction
                                        setPaletteActiveCateg={setPaletteActiveCateg}
                                        item={item}
                                        key={item.id}
                                        getSearchStr={getSearchStr}
                                        rootRef={rootRef}
                                        scrollIntoView={scrollIntoView}
                                        targetId={activeCateg.targetId}
                                    />
                                );
                            })}
                            <PaletteUploadError category={activeCateg} />
                            <CommandPaletteFilters activeCategory={activeCateg} />
                            <RenderBEsearchResults rootRef={rootRef} scrollIntoView={scrollIntoView} key="be-search" />
                        </ScrollContainer>
                    </OnboardingPaletteToolsActionList>
                </div>
                {current.item?.hasInfoArea && <PaletteItemInfoArea item={current.item} />}
            </div>
            {props.children}

            <PaletteSetAction item={current.item} />
            <PaletteExecuteAction />

            <PaletteContentSessionInfoGetter setIsOnboarding={setIsOnboarding} />
        </div>
    );
}
