import {FileRef} from "@api/resx/ref";
import svc from "@api/service/client";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {FileMimeType} from "@sessions/common/various";
import {getFileExtension} from "@workhorse/api/file_explorer/fileExplorer";
import React from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {ProcessingResx} from "@workhorse/pages/resources/components/ResxFileProgressPreview";
import {getDesignerArtifact} from "@workhorse/providers/DesignerSessionDataProviders";
import {ResourceRowExtra} from "../../../utils";
import UploadInputHidden from "./components/UploadInputHidden";
import {modifyResourceCounter} from "./utils";

type VideoCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    onDoubleClick: (
        resourceId: ResourceRowExtra["id"],
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
        calledFromCreate?: boolean
    ) => void;
    onChangeProcessingResx?: (values?: ProcessingResx) => void;
};

const VideoCreateForm = (props: VideoCreateFormProps) => {
    const {type, handleClose, artifactId, onDoubleClick, onChangeProcessingResx} = props;

    const artifact = getDesignerArtifact(artifactId);

    const createUploadResource = async (uploadedFile: FileRef) => {
        const extension = getFileExtension(uploadedFile.fileName ?? "");
        await svc.resx
            .createResource("flowos/video/resx/Video", {
                name: uploadedFile.fileName ?? "",
                videoSource: uploadedFile.publicPath,
                type: "Uploaded",
                extension: extension,
                uploadedVideo: uploadedFile,
            })
            .then((res) => {
                if (res) {
                    onChangeProcessingResx?.({
                        id: res.id,
                        type: "flowos/video/resx/Video",
                        isUploading: false,
                    });
                    setTimeout(() => {
                        onDoubleClick(res.id, undefined, true);
                    }, 0);

                    modifyResourceCounter("flowos/video/resx/Video", "add", true);

                    handleClose();
                }
            })
            .catch((err) => {
                toast(err.message ?? "Error creating video. Please try again later", {
                    type: "error",
                    duration: 3000,
                });
            });
    };

    return (
        <UploadInputHidden
            acceptedTypes={[FileMimeType.Video]}
            acceptedTypesText={"videos"}
            dropDescriptionText={"You can upload .mp4, .mkv, .avi, .mov, .wmv, .flv, .webm directly from your computer."}
            createResource={createUploadResource}
            onClose={handleClose}
            onChangeProcessingResx={onChangeProcessingResx}
            type={type}
        />
    );
};
export default VideoCreateForm;
