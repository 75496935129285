import {AuthService} from "@workhorse/api/authService";
import {ReactComponent as WelcomeWaveHand} from "../../frontend/assets/media/welcome-wave-hand.svg";
import classes from "./ExtensionAuthProvider/style/ExtensionAuthProvider.module.scss";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import BrandingLogo from "@ui/cdk/BrandingLogo";

export default function ExtensionAuthProvider() {
    function login(provider: "google" | "linkedin" | "email") {
        AuthService.getInstance().loginWithPopup(provider);
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <BrandingLogo />
            </div>

            <WelcomeWaveHand width={30} />
            <Typography variant="xl3" fontWeight="boldest">
                Welcome to Sessions
            </Typography>
            <Typography variant="lg" color="tertiary">
                Please login with your Sessions account
            </Typography>

            <div className={cls("flex flex-col", classes.buttonContainer)}>
                <Button
                    className={classes.continueWithButton}
                    variant="primary"
                    onClick={() => {
                        login("email");
                    }}
                >
                    Login
                </Button>
            </div>
        </div>
    );
}
