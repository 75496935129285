import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as MobileBlockerImage} from "../../assets/media/MobileBlockerImage.svg";
import classes from "./styles/UserMobileBlocker.module.scss";
import BrandingLogo from "@ui/cdk/BrandingLogo";

export const UserMobileBlocker = () => {
    return (
        <div className={cls("flex flex-col flex-items-center", classes.root)}>
            <BrandingLogo className={classes.logo} />
            <MobileBlockerImage className="mt-24" />
            <div className={classes.content}>
                <Typography variant="xl3" className={classes.contentHeader}>
                    The Mobile version of Sessions for this page is still under construction.
                </Typography>
                <Typography className={cls("flex flex-col", classes.contentBody)}>
                    <span>We’re working hard to bring it to you as soon as possible.</span>
                    <span>However, you can still enjoy Sessions.</span>
                </Typography>
            </div>
            <Button component={Link} variant="primary" href="https://app.sessions.us/" className={classes.backBtn}>
                Back to home
            </Button>
        </div>
    );
};

export const UserMobileBlockeriOSTablet = () => {
    return (
        <div className={cls("flex flex-col flex-items-center", classes.root)}>
            <BrandingLogo className={classes.logo} />
            <MobileBlockerImage className="mt-24" />
            <div className={classes.content}>
                <Typography variant="xl3" className={classes.contentHeader}>
                    Unsupported browser for your device.
                </Typography>
                <Typography className={cls("flex flex-col", classes.contentBody)}>
                    <span>We’re working hard to bring it to you as soon as possible.</span>
                    <span>
                        <>However, you can still enjoy Sessions on </>
                        <span className={classes.textHighlight}>Safari</span> {"or by upgrading to iOS 14.3 or later"}
                    </span>
                </Typography>
            </div>
            <Button component={Link} variant="primary" href="https://sessions.us/" className={classes.backBtn}>
                Back to website
            </Button>
        </div>
    );
};
