import browserInfo from "@workhorse/api/BrowserInfo";
import {EventEmitter} from "eventemitter3";
import {areDummyDevices, enumerateDevicesSafely, hasCamera, hasMicrophone, requestDeviceSafely, stopMediaStream} from "../../utils/devices";
import {conferenceContext} from "./AudioContext";
import {AudioStreamManager, AudioStreamManagerResult} from "./AudioStreamManager";
import {VideoStreamManager, VideoStreamManagerResult} from "./VideoStreamManager";

export type MediaPermission = "unset" | "denied" | "granted";

export interface DeviceManagerEventsMap {
    "audio-error": unknown;
    "video-error": unknown;
    "audio-detected": undefined;
    "audio-volume": number;
    "devices-init-error": unknown;
    "audio-success": undefined;
    "video-success": undefined;
    "audio-removed": undefined;
    "video-removed": undefined;
    "audio-ended": undefined;
    "video-ended": undefined;
    "background-filter-error": unknown;
    "devices-init-success": undefined;
}

export type DeviceManagerEvents = typeof EventEmitter<DeviceManagerEventsMap>;

interface DeviceCandidate {
    deviceId: string;
    isCertain: boolean;
}

export class DeviceManager {
    events = new EventEmitter<DeviceManagerEventsMap>();

    audio: AudioStreamManager;
    video: VideoStreamManager;

    audioContext = conferenceContext;

    private emptyAudioStream?: MediaStream;

    constructor(
        readonly userId: string,
        readonly onAudioStreamRestarted: (result: AudioStreamManagerResult) => void,
        readonly onVideoStreamRestarted: (result: VideoStreamManagerResult) => void,
        readonly onAudioStreamEnded: (stream: MediaStream) => void | Promise<void>,
        readonly onVideoStreamEnded: (stream: MediaStream) => void | Promise<void>
    ) {
        this.audio = new AudioStreamManager(userId, this.events, "devices.audio", this.onAudioStreamRestarted, this.onAudioStreamEnded);
        this.video = new VideoStreamManager(userId, this.events, "devices.video", this.onVideoStreamRestarted, this.onVideoStreamEnded);
    }

    findAudioDeviceCandidate(devices: MediaDeviceInfo[], preferredDeviceId?: string) {
        const candidateId = preferredDeviceId || this.audio.getSettings()?.deviceId || this.audio.getStoredDeviceId();
        return this.findDeviceIdByKind(devices, "audioinput", candidateId);
    }

    findVideoDeviceCandidate(devices: MediaDeviceInfo[], preferredDeviceId?: string) {
        const candidateId = preferredDeviceId || this.video.getSettings()?.deviceId || this.video.getStoredDeviceId();
        return this.findDeviceIdByKind(devices, "videoinput", candidateId);
    }

    findDeviceIdByKind(devices: MediaDeviceInfo[], kind: MediaDeviceKind, deviceId?: string): DeviceCandidate | undefined {
        if (areDummyDevices(devices) || devices.length === 0) {
            if (!deviceId) {
                return undefined;
            }
            return {
                deviceId: deviceId,
                isCertain: false,
            };
        }

        const devicesByKind = devices.filter((device) => device.kind === kind);
        const foundDevice = devicesByKind.find((device) => device.deviceId === deviceId);
        const selectedDevice = foundDevice?.deviceId || devicesByKind[0]?.deviceId;

        if (!selectedDevice) {
            if (!deviceId) {
                return undefined;
            }
            return {
                deviceId: deviceId,
                isCertain: false,
            };
        }

        return {
            deviceId: selectedDevice,
            isCertain: true,
        };
    }

    async getDevices(promptPermissions = false): Promise<{devices: MediaDeviceInfo[]; error?: unknown}> {
        let devices = await enumerateDevicesSafely();
        console.log("devices: enumerate devices", devices);

        const isEmpty = devices.length === 0;
        const areDummy = areDummyDevices(devices);

        if (!promptPermissions) {
            return {devices};
        }

        if (this.audio.isActive() || this.video.isActive()) {
            return {devices};
        }

        const withMicrophone = hasMicrophone(devices);
        const withCamera = hasCamera(devices);

        let audioConstraints: MediaTrackConstraints | false = false;
        let videoConstraints: MediaTrackConstraints | false = false;

        if (withMicrophone) {
            audioConstraints = this.audio.getDefaultConstraints();
            const deviceId = this.findAudioDeviceCandidate(devices)?.deviceId;
            if (deviceId) {
                audioConstraints.deviceId = {ideal: deviceId};
            }
        }

        if (withCamera) {
            videoConstraints = this.video.getDefaultConstraints();
            const deviceId = this.findVideoDeviceCandidate(devices)?.deviceId;
            if (deviceId) {
                videoConstraints.deviceId = {ideal: deviceId};
            }
        }

        const [stream, error] = await requestDeviceSafely({
            audio: audioConstraints,
            video: videoConstraints,
        });

        if (error) {
            this.events.emit("devices-init-error", error);
        }

        if (stream) {
            this.events.emit("devices-init-success");

            const audioSettings = stream.getAudioTracks()[0]?.getSettings();
            const videoSettings = stream.getVideoTracks()[0]?.getSettings();

            if (audioSettings && browserInfo.isFirefox()) {
                this.audio.setStoredSettings(audioSettings);
            }
            if (videoSettings && browserInfo.isFirefox()) {
                this.video.setStoredSettings(videoSettings);
            }
        }

        if (!isEmpty && !areDummy) {
            stopMediaStream(stream);
            return {devices, error};
        }

        devices = await enumerateDevicesSafely();
        stopMediaStream(stream);

        return {devices, error};
    }

    getEmptyAudioStream(): MediaStream {
        if (!this.emptyAudioStream) {
            this.emptyAudioStream = this.createEmptyAudioStream();
        }
        return this.emptyAudioStream;
    }

    pause() {
        this.audioContext.suspend();
    }

    async handleInteraction() {
        await this.resumeAudioContext();
    }

    private async resumeAudioContext() {
        if (this.audioContext.state === "running") {
            return;
        }

        const error = await this.audioContext.resume().catch((error: unknown) => error);

        if (error) {
            console.log("audioContext resume failed", error);
        } else {
            console.log("audioContext resumed");
        }
    }

    private createEmptyAudioStream(): MediaStream {
        const defaultSampleRate = 48000;
        const outputNode = this.audioContext.createMediaStreamDestination();

        const source = this.audioContext.createBufferSource();

        try {
            source.buffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 5, this.audioContext.sampleRate);
        } catch (error) {
            if (error && error.name === "NotSupportedError") {
                source.buffer = this.audioContext.createBuffer(1, defaultSampleRate * 5, defaultSampleRate);
            } else {
                throw error;
            }
        }

        // Some browsers will not play audio out the MediaStreamDestination
        // unless there is actually audio to play, so we add a small amount of
        // noise here to ensure that audio is played out.
        source.buffer.getChannelData(0)[0] = 0.0003;
        source.loop = true;
        source.connect(outputNode);
        source.start();
        return outputNode.stream;
    }
}
