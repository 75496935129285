import {SessionsUsingResourceResult} from "@generated/data";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import {cls} from "@ui/cdk/util";
import {ScrollState} from "../searchbar/utils";
import UsageHistorySessionItem from "./UsageHistorySessionItem";
import classes from "./style/UsageHistorySessionList.module.scss";
import UsageHistoryEmptyState from "./UsageHistoryEmptyState";
import {SessionSection} from "./UsageHistory";

type UsageHistorySectionProps = {
    sessions: SessionsUsingResourceResult[];
    selectedSection: SessionSection | "all";
};

type UsageHistorySessionItem = {
    id: string;
    title: string;
    description: JSX.Element | string;
    iconUrl: string;
    tooltip: string;
    color: string;
    link: string;
    ownerData: {
        firstName: string | undefined | null;
        lastName: string | undefined | null;
        profilePicture: string | undefined | null;
    };
};

const UsageHistorySessionList = (props: UsageHistorySectionProps) => {
    const {sessions, selectedSection} = props;

    const searchResultsRef = useRef<HTMLDivElement | null>(null);
    const [scrollState, setScrollState] = useState<ScrollState>({});
    const scrollContainerRef = useRef<ScrollContainerRef>({
        setScrollState,
    });

    const sorted = sessions.slice().sort((a, b) => (new Date(b?.startAt ?? 0).getTime() > new Date(a?.startAt ?? 0).getTime() ? 1 : -1));

    useEffect(() => {
        if (scrollContainerRef && scrollContainerRef.current) {
            scrollContainerRef.current.instantScrollTo?.(0);
        }
    }, [selectedSection]);

    return (
        <div className={classes.content}>
            {sorted.length ? (
                <ScrollContainer
                    component="div"
                    key="scroll-container"
                    axis="y"
                    ref={searchResultsRef}
                    connector={scrollContainerRef}
                    isMobile={false}
                    scrollBarOffsetBottom={10}
                    scrollBarOffsetTop={5}
                    className={cls("flex flex-col", classes.scrollContainer)}
                >
                    {sorted.map((session, index) => (
                        <UsageHistorySessionItem key={`usage-history-event-${session.sessionId}-${index}`} session={session} />
                    ))}
                </ScrollContainer>
            ) : (
                <UsageHistoryEmptyState />
            )}
        </div>
    );
};

export default UsageHistorySessionList;
