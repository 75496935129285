import {AuthService} from "@workhorse/api/authService";
import {useEffect} from "@workhorse/api/rendering";

const AuthenticationState = () => {
    useEffect(() => {
        AuthService.getInstance()
            .getUser()
            .then((decodedToken) => {
                window.parent.postMessage(
                    {
                        authenticated:
                            decodedToken?.permissions && decodedToken.permissions.length > 0
                                ? decodedToken.permissions.find((p) => p.toUpperCase() === "GUEST") == null
                                : false,
                        _sessions: true,
                    },
                    "https://sessions.us"
                );
            });
    }, []);

    return null;
};

export default AuthenticationState;
