import {getStreamSettings, getValueFromConstrainDOMString, useDevices} from "@workhorse/api/conference2";
import React, {forwardRef} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as SwitchCameraIcon} from "../../../../assets/media/switch_camera.svg";
import MenuItem from "@ui/cdk/MenuItem";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useTranslation} from "react-i18next";

type SwitchCameraButtonProps = {
    className?: string;
};

export const SwitchCameraButton = forwardRef((props: SwitchCameraButtonProps, ref: React.MutableRefObject<any>) => {
    const {t} = useTranslation();
    const {videoStream, setVideoConstraints} = useDevices();

    const getNextFacingMode = (current: string) => {
        return current === "environment" ? "user" : "environment";
    };

    const handleSwitchCamera = () => {
        setVideoConstraints((current) => {
            const facingMode = current?.facingMode ?? getStreamSettings(videoStream)?.facingMode;
            if (facingMode != null) {
                const primitive = getValueFromConstrainDOMString(facingMode);
                if (primitive) {
                    const nextFacingMode = getNextFacingMode(primitive);

                    ariaAnnouncerMessageVar(
                        (nextFacingMode === "environment"
                            ? t("aria_announcer.switching_to_back_camera")
                            : t("aria_announcer.switching_to_front_camera")) ?? ""
                    );
                    return {facingMode: nextFacingMode};
                }
            }
            return {facingMode: "user"};
        });
    };

    return (
        <MenuItem
            ref={ref}
            button
            className={cls(props.className)}
            onClick={handleSwitchCamera}
            role="button"
            aria-label={t("participant.camera.switch_camera") ?? "Switch camera"}
        >
            <SwitchCameraIcon />
        </MenuItem>
    );
});

export default SwitchCameraButton;
