import {answerParticipant} from "@artifacts/conference/api/conferenceControllerApi";
import MicRoundedIcon from "@material-ui/icons/MicRounded";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import ConfirmationSnackbar, {ConfirmationSnackbarImperativeRef} from "@ui/cdk/ConfirmationSnackbar";
import {useDevices, useForceMuteStatus} from "@workhorse/api/conference2";
import {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useForceDisableVideo} from "@workhorse/api/conference2/providers/VideoDisableProvider";
import {useSessionSettingsDialog} from "@workhorse/api/session-settings";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import classes from "./style/AskedParticipant.module.scss";
import {useTranslation} from "react-i18next";

import {useDebounce} from "use-debounce";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

const enum SnackbarType {
    UNMUTE_MIC = "unmuteMic",
    START_CAMERA = "startCamera",
}

function AskedParticipant() {
    const {t} = useTranslation();
    const {askToUnmutedImmediate, askToStartCameraImmediate} = useParticipantsStore(
        useShallow(({currentParticipant}) => ({
            askToUnmutedImmediate: currentParticipant?.askToUnmute ?? false,
            askToStartCameraImmediate: currentParticipant?.askToStartCamera,
        }))
    );
    const sessionId = useSessionIdFromRoute()!;

    const [askToUnmute] = useDebounce(askToUnmutedImmediate, 500);
    const [askToStartCamera] = useDebounce(askToStartCameraImmediate, 500);

    const {
        store: {open: settingsDialogOpen},
    } = useSessionSettingsDialog();

    const {audioEnabled, audioMuted, videoEnabled, setAudioEnabled, setAudioMuted, setVideoEnabled} = useDevices();
    const {muteStatus} = useForceMuteStatus();
    const {isHardVideoDisable} = useForceDisableVideo();

    const forceMute = muteStatus === "hard";
    const audioOff = !audioEnabled || audioMuted;

    const unmuteMicrophoneRef: ConfirmationSnackbarImperativeRef = useRef();
    const startCameraRef: ConfirmationSnackbarImperativeRef = useRef();

    const [popupsOrder, setPopupsOrder] = useState<SnackbarType[]>([]);

    const showMicSnackbar = popupsOrder[0] === SnackbarType.UNMUTE_MIC;
    const showCameraSnackbar = popupsOrder[0] === SnackbarType.START_CAMERA;

    useEffect(() => {
        if (settingsDialogOpen) {
            return;
        }

        if (askToUnmute && audioOff && !unmuteMicrophoneRef.current?.isOpen) {
            unmuteMicrophoneRef.current?.toggle?.();
            setPopupsOrder((prevState) => [...prevState, SnackbarType.UNMUTE_MIC]);
        } else if (!askToUnmute || !audioOff) {
            setPopupsOrder((prevState) => prevState.filter((type) => type !== SnackbarType.UNMUTE_MIC));
        }
    }, [settingsDialogOpen, askToUnmute, audioOff, sessionId]);

    useEffect(() => {
        if (settingsDialogOpen) {
            return;
        }

        if (askToStartCamera && !videoEnabled && !startCameraRef.current?.isOpen) {
            startCameraRef.current?.toggle?.();
            setPopupsOrder((prevState) => [...prevState, SnackbarType.START_CAMERA]);
        } else if (!askToStartCamera || videoEnabled) {
            setPopupsOrder((prevState) => prevState.filter((type) => type !== SnackbarType.START_CAMERA));
        }
    }, [settingsDialogOpen, videoEnabled, askToStartCamera, sessionId]);

    // Handles close & answer for when the participant is asked and the state of cam / mic is set HARD off and he can't answer
    useEffect(() => {
        if (forceMute && unmuteMicrophoneRef.current?.isOpen) {
            handleRejectToUnmute();
            unmuteMicrophoneRef.current?.toggle?.();
            setPopupsOrder((prevState) => prevState.filter((type) => type !== SnackbarType.UNMUTE_MIC));
        }
    }, [forceMute]);

    useEffect(() => {
        if (isHardVideoDisable && startCameraRef.current?.isOpen) {
            handleRejectToTurnCameraOn();
            startCameraRef.current?.toggle?.();
            setPopupsOrder((prevState) => prevState.filter((type) => type !== SnackbarType.START_CAMERA));
        }
    }, [isHardVideoDisable]);

    const handleAcceptToUnmute = () => {
        setAudioEnabled(true);
        setAudioMuted(false);
        answerParticipant(sessionId, {askToUnmute: false});
    };

    const handleRejectToUnmute = () => {
        answerParticipant(sessionId, {askToUnmute: false});
    };

    const handleAcceptToTurnCameraOn = () => {
        setVideoEnabled(true);
        answerParticipant(sessionId, {askToStartCamera: false});
    };

    const handleRejectToTurnCameraOn = () => {
        answerParticipant(sessionId, {askToStartCamera: false});
    };

    return (
        <>
            <ConfirmationSnackbar
                imperativeRef={unmuteMicrophoneRef}
                title={t("player.notifications.mic.unmute_your_mic") ?? "Unmute your microphone"}
                message={
                    t("player.notifications.mic.unmute_your_mic_description") ?? "A moderator would like you to unmute your microphone."
                }
                confirmButtonText={t("participant.mic.unmute") ?? "Unmute"}
                onConfirm={handleAcceptToUnmute}
                rejectButtonText={t("g.dismiss") ?? "Dismiss"}
                onReject={handleRejectToUnmute}
                key="unmute-snackbar"
                icon={<MicRoundedIcon />}
                classes={{
                    badge: classes.grayBadge,
                    snackbar: showMicSnackbar ? classes.higherZIndex : classes.lowerZIndex,
                }}
            />

            <ConfirmationSnackbar
                imperativeRef={startCameraRef}
                title={t("player.notifications.camera.turn_on_your_camera") ?? "Turn on your camera"}
                message={
                    t("player.notifications.camera.turn_on_your_camera_description") ?? "A moderator would like you to turn on your camera."
                }
                confirmButtonText={t("participant.camera.turn_on") ?? "Turn on"}
                onConfirm={handleAcceptToTurnCameraOn}
                rejectButtonText={t("g.dismiss") ?? "Dismiss"}
                onReject={handleRejectToTurnCameraOn}
                key="turn-on-camera-snackbar"
                icon={<VideocamRoundedIcon />}
                classes={{
                    badge: classes.grayBadge,
                    snackbar: showCameraSnackbar ? classes.higherZIndex : classes.lowerZIndex,
                }}
            />
        </>
    );
}

export default memo(AskedParticipant, () => true);
