import env from "@generated/environment";
import {v4 as uuid} from "uuid";

export interface FileRef {
    fileRefType: "upload" | "download" | "base";
    createdAt: string;
    uploadedAt?: string;
    empty: boolean;
    namespace: string;
    id: string;
    fileName?: string;
    fileSize?: number;
    path: string;
    publicPath: string;
}

export interface UploadFileRef extends FileRef {
    fileRefType: "upload";
    uploadUrl: string;
}

export interface DownloadFileRef extends FileRef {
    fileRefType: "download";
    signedDownloadUrl: string;
    simpleDownloadUrl: string;
}

export const makeEmptyFileRef = (seed?: {fileName?: string; id?: string; namespace?: string; fileSize?: number}): FileRef => {
    const namespace = seed?.namespace ?? uuid();
    const id = seed?.id ?? uuid();
    const path = `resx/${namespace}/${id}`;

    return {
        empty: true,
        fileRefType: "base",
        namespace,
        id,
        createdAt: new Date().toISOString(),
        fileName: seed?.fileName,
        fileSize: seed?.fileSize,
        path,
        publicPath: `${env.staticServiceUrl}/${path}`,
    };
};

export const confirmUploadOnFileRef = <T extends FileRef>(ref: T): T => {
    return {
        ...ref,
        empty: false,
        uploadedAt: new Date().toISOString(),
    };
};

export const updateFileRefInfo = <T extends FileRef>(ref: T, update: Partial<T>): T => {
    return {
        ...ref,
        ...update,
    };
};

export const isUploadFileRef = (ref: FileRef): ref is UploadFileRef => {
    return ref.fileRefType == "upload";
};

export const isDownloadFileRef = (ref: FileRef): ref is DownloadFileRef => {
    return ref.fileRefType == "download";
};
