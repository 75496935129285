import {WithChildren} from "@workhorse/declarations";
import React, {Fragment, memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useMyParticipant, useSession} from "./SessionDataProviders";
import {useVisibleIds} from "./VisibleParticipantsProvider";
import {ParticipantStatus} from "@generated/data";
import {rbac} from "@workhorse/api/user";
import {useConferenceStatusMap, useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";

type ParticipantStreamingStateProps = {
    setVisibleIds: React.Dispatch<React.SetStateAction<string[]>>;
    participantId: string;
    visibleIds: string[];
};

function ParticipantStreamingState(props: ParticipantStreamingStateProps) {
    const {participantId, setVisibleIds, visibleIds} = props;

    const {cameraOn, micOn} = useRemoteParticipantStatus(participantId);

    const noAudio = !micOn;
    const noVideo = !cameraOn;

    const t = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (t.current != null) {
            clearTimeout(t.current);
        }
        if (visibleIds.includes(participantId)) {
            if (noAudio && noVideo) {
                // removing from visibleIds
                setVisibleIds((c) => (c.includes(participantId) ? c.filter((id) => id !== participantId) : c));
            }
        } else if (!noAudio || !noVideo) {
            // is visible
            t.current = setTimeout(() => {
                setVisibleIds((c) => (!c.includes(participantId) ? [...c, participantId] : c));
            }, 1500);
        }
    }, [noAudio, noVideo, participantId, visibleIds]);

    useEffect(() => {
        return () => {
            // attendee is gone
            // remove from visible ids
            setVisibleIds((current) => {
                return current?.includes(participantId) ? current.filter((id) => id !== participantId) : current;
            });
        };
    }, []);

    return <></>;
}

const ParticipantsVisibilityDetector = memo(
    function ParticipantsVisibilityDetector({
        hideNonStreamingParticipants,
        requestPermissionToJoin,
        provideSessionPasscode,
    }: {
        hideNonStreamingParticipants: boolean;
        requestPermissionToJoin: boolean;
        provideSessionPasscode: boolean;
    }) {
        // const map = useParticipantIdAttendeeIdMap();
        const map = useConferenceStatusMap();
        const participants = useParticipantsStore((state) => state.joinedFullList);
        const {id: myParticipantId} = useMyParticipant();
        const {visibleIds, setVisibleIds} = useVisibleIds();

        /**
         * there's no difference between visibleIds and streamingIds
         * except for the naming and
         * `visibleIds` is nullable whereas `streamingIds` is not
         * but... they both hold the same list of which participant id's have at least 1 stream ON
         * `streamingIds` is populated regardless of whether `hideNonStreamingParticipants` is true or false
         * meaning that if `hideNonStreamingParticipants` becomes active during a live session
         * we can take `streamingIds` and set it as `visibleIds`
         * this prevents the screen from going blank and being re-populated with active streams
         */
        const [streamingIds, setStreamingIds] = useState<string[]>([]);

        useEffect(() => {
            if (hideNonStreamingParticipants) {
                setVisibleIds(streamingIds);
            } else if (visibleIds !== null) {
                setVisibleIds(null);
            }
        }, [hideNonStreamingParticipants, streamingIds]);

        return (
            <>
                {participants.map(({id: participantId, status, isApproved, isOwner, isRecorder, rbac: pRbac, submittedPasscode}) => {
                    const noCompute =
                        isRecorder ||
                        participantId === myParticipantId ||
                        !map[participantId] ||
                        !map[participantId]?.connected ||
                        status !== ParticipantStatus.JoinedSession ||
                        (requestPermissionToJoin && !isApproved && !isOwner && !rbac({rbac: pRbac}, "session.isAssistant")) ||
                        (provideSessionPasscode && !submittedPasscode && !isOwner && !rbac({rbac: pRbac}, "session.isAssistant"));

                    return noCompute ? (
                        <Fragment key={participantId} />
                    ) : (
                        <ParticipantStreamingState
                            key={participantId}
                            setVisibleIds={setStreamingIds}
                            participantId={participantId}
                            visibleIds={streamingIds}
                        />
                    );
                })}
            </>
        );
    },
    (p, n) => p.hideNonStreamingParticipants === n.hideNonStreamingParticipants
);

const WithParticipantsVisibilityDetector = memo(
    function WithParticipantsVisibilityDetector() {
        const {hideNonStreamingParticipants, requestPermissionToJoin, id, provideSessionPasscode} = useSession();

        return (
            <ParticipantsVisibilityDetector
                key={`sess-${id}`}
                hideNonStreamingParticipants={hideNonStreamingParticipants}
                requestPermissionToJoin={requestPermissionToJoin}
                provideSessionPasscode={provideSessionPasscode}
            />
        );
    },
    () => true
);

export default function ComputeVisibleParticipants(props: WithChildren) {
    return (
        <>
            <Fragment key="children">{props.children}</Fragment>
            <WithParticipantsVisibilityDetector />
        </>
    );
}
