import {useCallback, useRef, useState} from "@workhorse/api/rendering";
import {ToolbarGenericProps, useClickOutside} from "../api";
import {ToolbarItem} from "./components";
import MoreRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import Popover from "@ui/core/components/Popover";
import classes from "./styles/Toolbar.module.scss";
import {ToolbarMarks} from "./ToolbarMarks";
import {ToolbarHyperlink} from "./ToolbarHyperlink";
import {ToolbarIndentation} from "./ToolbarIndentation";
import {ToolbarAlignment} from "./ToolbarAlignment";
import {ToolbarHistory} from "./ToolbarHistory";
import {ToolbarLists} from "./ToolbarLists";

export const ToolbarMoreOptions = (props: ToolbarGenericProps) => {
    const {editor, onActionDone} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const ref = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                event.target &&
                !ref.current?.contains(event.target as Node) &&
                !modalRef.current?.contains(event.target as Node) &&
                anchorEl
            ) {
                setAnchorEl(undefined);
            }
        },
        [anchorEl]
    );

    useClickOutside("click", handleClickOutside);

    const handleClose = useCallback(
        (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            if (reason === "backdropClick") {
                return;
            }
            setAnchorEl(undefined);
            onActionDone();
        },
        [onActionDone]
    );

    return (
        <>
            <ToolbarItem
                tooltip="More"
                className={classes.button}
                onClick={handleOpenMenu}
                disabled={!editor.isEditable}
                isMobile={props.isMobile}
                ref={ref}
            >
                <MoreRoundedIcon />
            </ToolbarItem>
            <Popover
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
            >
                <div className={classes.verticalList} ref={modalRef}>
                    <ToolbarMarks {...props} exclude={["bold", "italic"]} />
                    <ToolbarLists {...props} exclude={["bulletList", "orderedList"]} />
                    <ToolbarIndentation {...props} />
                    <ToolbarAlignment {...props} />
                    <ToolbarHistory {...props} />
                </div>
            </Popover>
        </>
    );
};
