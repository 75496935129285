import {useCallback, useEffect} from "@workhorse/api/rendering";

type Handler = (event: MouseEvent) => void;

const cbs: Handler[] = [];
const addedEvents: (keyof HTMLElementEventMap)[] = [];

export const useClickOutside = (event: keyof HTMLElementEventMap, handler: Handler) => {
    const eventHandler = useCallback((event: MouseEvent) => {
        cbs.forEach((cb) => cb(event));
    }, []);

    useEffect(() => {
        cbs.push(handler);

        if (!addedEvents.includes(event)) {
            addedEvents.push(event);
            document.body.addEventListener(event, eventHandler);
        }

        return () => {
            addedEvents.splice(addedEvents.indexOf(event), 1);
            cbs.splice(cbs.indexOf(handler), 1);

            if (!addedEvents.includes(event)) {
                document.body.removeEventListener(event, eventHandler);
            }
        };
    }, [event, handler, eventHandler]);
};
