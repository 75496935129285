import {useCallback, useEffect, useState} from "@workhorse/api/rendering";

// TODO: remove when typescript is updated

export type WakeLockType = "screen";

export interface WakeLock {
    /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/WakeLock/request) */
    request(type?: WakeLockType): Promise<WakeLockSentinel>;
}

export interface WakeLockSentinelEventMap {
    release: Event;
}

export interface WakeLockSentinel extends EventTarget {
    /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/WakeLockSentinel/release_event) */
    onrelease: ((this: WakeLockSentinel, ev: Event) => any) | null;
    /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/WakeLockSentinel/released) */
    readonly released: boolean;
    /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/WakeLockSentinel/type) */
    readonly type: WakeLockType;
    /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/WakeLockSentinel/release) */
    release(): Promise<void>;
    addEventListener<K extends keyof WakeLockSentinelEventMap>(
        type: K,
        listener: (this: WakeLockSentinel, ev: WakeLockSentinelEventMap[K]) => any,
        options?: boolean | AddEventListenerOptions
    ): void;
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
    removeEventListener<K extends keyof WakeLockSentinelEventMap>(
        type: K,
        listener: (this: WakeLockSentinel, ev: WakeLockSentinelEventMap[K]) => any,
        options?: boolean | EventListenerOptions
    ): void;
    removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}

const navigator = window?.navigator as Navigator & {
    wakeLock?: WakeLock;
};

async function safelyReleaseWakeLock(lock: WakeLockSentinel | null) {
    await lock?.release().catch((error) => {
        console.error("Failed to release screen wake lock", error);
    });
}

export function safelyAcquireWakeLock() {
    if (navigator.wakeLock == null) {
        return {
            getLock: () => Promise.resolve(null),
            clean: () => Promise.resolve(),
        };
    }

    let lock = null as WakeLockSentinel | null;

    const promise = navigator.wakeLock
        .request("screen")
        .then((wakeLock) => {
            lock = wakeLock;
            return wakeLock;
        })
        .catch((error) => {
            console.error("Failed to acquire screen wake lock", error);
            return null;
        });

    const getLock = async () => {
        if (lock != null) {
            return lock;
        } else {
            return promise;
        }
    };

    const clean = async () => {
        const lock = await getLock();
        return safelyReleaseWakeLock(lock);
    };

    return {
        getLock,
        clean,
    };
}

export function useWakeLock(enabled = true) {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const {clean} = safelyAcquireWakeLock();

        return () => {
            clean?.();
        };
    }, [enabled]);
}

export function WakeLock({enabled = true}: {enabled?: boolean}) {
    useWakeLock(enabled);
    return null;
}
