import TableBody from "@ui/cdk/Table/TableBody";
import TableCell from "@ui/cdk/Table/TableCell";
import TableHead from "@ui/cdk/Table/TableHead";
import TableRow from "@ui/cdk/Table/TableRow";
import {cls} from "@ui/cdk/util";
import Table from "@ui/core/components/Table";
import classes from "./style/RoomsTableSkeleton.module.scss";

const numberOfSkeletons = Array.from(Array(6).keys());

function RoomsTableSkeleton() {
    return (
        <div className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell size="large">
                            <div className={cls(classes.skeletonItem, classes.tall)}></div>
                        </TableCell>
                        <TableCell size="large">
                            <div className={classes.skeletonItem}></div>
                        </TableCell>
                        <TableCell size="unset">
                            <div className={classes.skeletonItem}></div>
                        </TableCell>
                        <TableCell size="unset">
                            <div className={classes.skeletonItem}></div>
                        </TableCell>

                        <TableCell size="medium">
                            <div className={cls(classes.skeletonItem, classes.small)}></div>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {numberOfSkeletons.map((s, i) => (
                        <TableRow key={`${s}_${i}`} className={classes.row}>
                            <TableCell size="large">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell size="large">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell size="unset">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>
                            <TableCell size="unset">
                                <div className={classes.skeletonItem}></div>
                            </TableCell>

                            <TableCell size="medium">
                                <div className={cls(classes.skeletonItem, classes.small)}></div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default RoomsTableSkeleton;
