import {useClientEvent} from "@api/events/client";
import svc from "@api/service/client";
import {DUMMY_AGENDA_ITEM_TITLE} from "@common/designer";
import {AgendaItemType, ChooseToolStep} from "@generated/data";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls, togglePalette} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import designer from "@workhorse/api/designer";
import {commitTemplate} from "@workhorse/api/designer/methods";
import {manager} from "@workhorse/api/dndClient";
import {useCallback, useMemo, useRef, useState} from "@workhorse/api/rendering";
import secondsToTime from "@workhorse/api/utils/secondsToTime";
import {ActionCategory, categMap} from "@workhorse/components/command-palette/actionCategTree";
import {paletteActiveCategDefault, usePaletteActiveCateg} from "@workhorse/components/command-palette/CommandPaletteProviders";
import {usePaletteExcludedItems} from "@workhorse/components/command-palette/PaletteActionProvider";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import DesignerToolPlaceholder from "@workhorse/pages/designer/ToolConfiguration/DesignerToolPlaceholder";
import AgendaItemCreateButton from "@workhorse/pages/templates/AgendaTemplatesEditor/AgendaItemCreateButton/AgendaItemCreateButton";
import AgendaItemEditor from "@workhorse/pages/templates/AgendaTemplatesEditor/AgendaItemEditor/AgendaItemEditor";
import AgendaItemDragPreview from "@workhorse/pages/templates/AgendaTemplatesEditor/AgendaItemEditor/components/AgendaItemDragPreview/AgendaItemDragPreview";
import AgendaTemplatesEditorSubHeader from "@workhorse/pages/templates/AgendaTemplatesEditor/AgendaTemplatesEditorSubHeader/AgendaTemplatesEditorSubHeader";
import {generateTemplateNameQuery} from "@workhorse/pages/templates/utils";
import {useDesignerSessionCommitState, useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useAgendaItems, useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {DndProvider} from "react-dnd";
import AddSpeakerDialog from "./AddSpeakerDialog";
import EventAgendaEmptyState from "./EventAgendaEmptyState";
import classes from "./style/EventAgendaEditor.module.scss";

import {SpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import AgendaGeneratorContainer from "@workhorse/api/aiGenerator/AgendaGeneratorContainer";
import {AIGeneratedAgendaItem} from "@workhorse/api/aiGenerator/AgendaGeneratorMessenger";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {FocusedAgendaItem} from "@workhorse/api/designer/lib/utils/agendaItemUtils";
import {getResourceResult} from "@workhorse/api/resx/hooks";
import toast from "@workhorse/api/toast";
import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import {useAiGeneratedAgenda} from "@workhorse/providers/AiGeneratedAgendaProvider";

type EventAgendaEditorProps = {
    readOnly?: boolean;
    toggleAgendaPalette: () => void;
    isRoom?: boolean;
    onChange?: () => void;
    backstage?: boolean;
};

const EventAgendaEditor = (props: EventAgendaEditorProps) => {
    const {readOnly, toggleAgendaPalette, isRoom, onChange, backstage} = props;
    const agendaItems = useAgendaItems()!;

    const currentDesignerTemplate = useSession();
    const user = useUserInfo();
    const [editState, _] = useDesignerSessionState();
    const mobileState = useMobile();
    const isReadOnly = readOnly;
    const [updateMultiple, setUpdateMultiple] = useState(false);

    const [openDeleteSelected, setOpenDeleteSelected] = useState<boolean>(false);
    const commitInProgress = useDesignerSessionCommitState();
    const setEditState = designer.state.update;
    const {selectedAgendaItemId, dirty} = editState;

    //used to show the AI copilot button only on the latest focused item
    const [focusedAgendaItem, setFocusedAgendaItem] = useState<FocusedAgendaItem | null>(null);

    const {setExcludedItems} = usePaletteExcludedItems();
    const {setPaletteActiveCateg} = usePaletteActiveCateg();

    const [open, setOpen] = useState(false);
    const [selectedAgendaItem, setSelectedAgendaItem] = useState<string | undefined>(undefined);
    const [selected, setSelected] = useState<string[]>([]);
    const selectedRef = useRef(selected);
    selectedRef.current = selected;
    const dialogRef = useRef<DialogImperativeRef>(null);
    const [disableSaveTemplateButton, setDisableSaveTemplateButton] = useState(false);

    const {saveGeneratedAgenda, handleOnError} = useAiGeneratedAgenda();

    const handleClickAddSpeaker = (agendaItemId: string) => {
        setSelectedAgendaItem(agendaItemId);
        setOpen(true);
    };

    const onToggle = useCallback(() => {
        dialogRef.current?.toggle?.();
    }, []);

    const onCloseToolsDialog = useCallback(() => {
        onToggle();

        if (dirty && selectedAgendaItemId) {
            designer.undo({
                field: "agendaItem",
                id: selectedAgendaItemId,
            });
        } else {
            designer.resetBatching();
        }

        setEditState({
            selectedMicroIdToConfig: null,
            selectedMicroIdToPreview: null,
            selectedAgendaItemId: null,
            dirty: true,
        });
    }, [onToggle, dirty, selectedAgendaItemId, setEditState]);

    const toggleDialog = () => {
        setOpen(!open);
    };
    const sortedAgendaItems = useMemo(() => agendaItems?.slice().sort((a, b) => a.order - b.order), [agendaItems]);

    const onOpenConfigDialog = useCallback(() => {
        setEditState({
            dirty: false,
        });

        onToggle();
    }, [onToggle, setEditState]);

    const configure = useCallback(
        (step: ChooseToolStep, agendaItemId: string, artifactId?: string) => {
            setEditState({
                toolStep: step,
                selectedAgendaItemId: agendaItemId,
                selectedMicroIdToConfig: null,
                selectedMicroIdToPreview: artifactId,
            });

            onOpenConfigDialog();
        },
        [onOpenConfigDialog, setEditState]
    );
    const commit = useCallback(async () => {
        if (!isRoom) {
            await designer.commit();
        }
        onChange?.();
    }, []);

    const onConfirmToolsDialog = useCallback(async () => {
        onToggle();

        if (updateMultiple && selected.length > 1) {
            designer.api.agendaItem.copyArtifactToMultipleItems({ids: selected});
        }

        setUpdateMultiple(false);
        await commit();
    }, [onToggle, updateMultiple, selected, commit]);

    const createNewAgendaItem = useCallback(() => {
        const id = designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Planned,
            title: "",
        });

        if (!isRoom) {
            designer.commit();
        }
        onChange?.();
    }, []);

    const onTogglePalette = (agendaItemId: string) => {
        setExcludedItems(categMap.filter((obj) => obj.categ === ActionCategory.Agenda).map((obj) => obj.id));
        setPaletteActiveCateg({
            ...paletteActiveCategDefault,
            isForced: true,
        });
        setTimeout(() => {
            togglePalette();
        }, 0);
        onChange?.();
    };

    useClientEvent("finished-config-process", async (agendaItemId) => {
        if (agendaItemId) {
            setEditState({
                selectedMicroIdToConfig: null,
                selectedMicroIdToPreview: null,
                selectedAgendaItemId: null,
                dirty: true,
            });
            if (!isRoom) {
                designer.commit();
            }
        }
    });

    useClientEvent("palette-open", async (isOpen) => {
        if (!isOpen) {
            if (designer.state.getSnapshot().configInProgress) {
                return;
            }
            setEditState({
                selectedMicroIdToConfig: null,
                selectedMicroIdToPreview: null,
                selectedAgendaItemId: null,
                dirty: true,
            });
        }
    });

    const hasAgenda = agendaItems.length && agendaItems.some((a) => a.title !== DUMMY_AGENDA_ITEM_TITLE);

    const totalAgendaDuration = useMemo(
        () => agendaItems.reduce((acc, item) => (acc += item.duration ? item.duration : 0), 0),
        [agendaItems]
    );
    const timeItems = secondsToTime(totalAgendaDuration, true, true, true, false);

    const onRemoveAgenda = () => {
        setSelected([]);
        designer.api.session.detachAgenda({
            type: AgendaItemType.Instant,
        });
        if (!isRoom) {
            designer.commit();
        }
        onChange?.();
    };

    const onSelect = useCallback((id: string) => {
        setSelected((prev) => {
            if (prev.includes(id)) {
                return prev.filter((i) => i !== id);
            } else {
                return [...prev, id];
            }
        });
    }, []);

    const toggleDeleteAgendaItems = useCallback(() => {
        setOpenDeleteSelected((prev) => !prev);
    }, []);

    const handleDeleteAgendaItems = useCallback(() => {
        selected.forEach((id) => {
            designer.api.agendaItem.remove({id});
        });
        setSelected([]);
        toggleDeleteAgendaItems();
        commit();
    }, [selected, toggleDeleteAgendaItems, commit]);

    const beforeUpdateMultiple = useCallback(() => {
        setUpdateMultiple(true);
    }, []);

    const selectedWithTool = useMemo(() => {
        let withTool = 0;
        selected.forEach((id) => {
            const agendaItem = sortedAgendaItems.find((a) => a.id === id);
            if (agendaItem?.artifact?.artifactId !== "flowos/conference") {
                withTool++;
            }
        });
        return withTool;
    }, [sortedAgendaItems, selected]);

    const createTemplate = async () => {
        setDisableSaveTemplateButton(true);
        if (!currentDesignerTemplate?.id) {
            setDisableSaveTemplateButton(false);
            toast("Error saving template", {
                type: "error",
            });
            return;
        }

        const {data} = await generateTemplateNameQuery(currentDesignerTemplate.name);
        const name = data.generateTemplateName;
        const template = {
            name,
            id: makeId(),
            agendaItems: agendaItems
                ?.filter((a) => !a.isDeleted)
                .map((a) => ({
                    ...a,
                    id: makeId(),
                    startAt: null,
                    endedAt: null,
                    timeSpentInSeconds: null,
                    artifact:
                        a.artifact && Object.keys(a.artifact).length
                            ? {
                                  ...a.artifact,
                                  id: makeId(),
                                  properties: a.artifact?.properties?.map((p) => ({...p, id: makeId()})) ?? [],
                              }
                            : null,
                })),
        };

        for (const item of template.agendaItems) {
            if (!item.artifact?.resourceResults?.[0]?.id) {
                continue;
            }

            const {result} = await getResourceResult(item.artifact?.resourceResults?.[0]?.id);
            if (!result) {
                continue;
            }

            const newResult = await svc.resx.createResult(
                result.type as keyof SpecifiersTypeMap,
                "latest",
                result.resourceId ?? undefined,
                undefined,
                result.resourceContentSnapshot
            );
            const now = null as unknown as Date;

            if (newResult?.id) {
                item.artifact.resourceResults = [
                    {
                        createdAt: now,
                        id: newResult.id,
                        __typename: "ResourceResult",
                        isDeleted: false,
                        oldId: null,
                        update: null,
                        updatedAt: now,
                    },
                ];
            } else {
                item.artifact = designer.constants.makeDefaultConferenceArtifact(item.artifact.id, item.id) as typeof item.artifact;
            }
        }

        commitTemplate({
            name: template.name,
            description: undefined,
            agendaItems: template.agendaItems as DeepMandatoryWithNullVals<DesignerApiSession["agendaItems"]>,
        });

        toast(`Template ${name} created`);
        setDisableSaveTemplateButton(true);
        setTimeout(() => {
            setDisableSaveTemplateButton(false);
        }, 10000);
        onChange?.();
    };
    const onNewAgendaItemGenerated = async (agendaItem: AIGeneratedAgendaItem) => {
        const agendaWithId = {...agendaItem, id: makeId()};
        saveGeneratedAgenda(agendaWithId);
        onChange?.();
    };

    const onAgendaGenerationError = (error: string) => {
        console.log("ERROR on AI Generated Agenda from AGENDA", error);
        handleOnError("There was a problem on generating the agenda. Please try again later.");
    };

    if (backstage) {
        return <EventAgendaEmptyState backstage={true} />;
    }

    return (
        <>
            <Dialog
                PaperProps={{
                    className: cls(classes.paper),
                }}
                imperativeRef={dialogRef}
                onClose={onCloseToolsDialog}
                disableBackdropClick
                data-open={(!!dialogRef.current?.isOpen).toString()}
            >
                <IconButton className={cls(classes.closeDialogButton, "close-dialog")} onClick={onCloseToolsDialog}>
                    <ClearIcon />
                </IconButton>

                {currentDesignerTemplate?.id ? (
                    <DesignerToolPlaceholder
                        className={cls("flex flex-col restricted-padding flex flex-col flex11-auto")}
                        currentSessionDetails={currentDesignerTemplate}
                        inDialog={true}
                        onClose={onConfirmToolsDialog}
                        readOnly={true}
                        mobileState={mobileState}
                    />
                ) : null}
            </Dialog>
            {hasAgenda ? (
                <div className={cls("flex flex-col", classes.root)}>
                    <div className={cls("flex flex-items-center", classes.header)}>
                        <div className="flex11-auto mr-12 flex">
                            <div className="flex flex-items-center flex11-auto">
                                {totalAgendaDuration > 0 && (
                                    <div className={cls("flex flex-items-center mr-8", classes.totalTimeContainer)}>
                                        <Typography fontWeight="bold" color="secondary" variant="sm" component="div">
                                            {totalAgendaDuration >= 60 * 60 ? (
                                                <>
                                                    {timeItems.hours}&nbsp;
                                                    {timeItems.minutes}
                                                </>
                                            ) : (
                                                `${totalAgendaDuration / 60} minutes`
                                            )}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                            <div className="flex">
                                <Button
                                    disabled={readOnly || commitInProgress}
                                    onClick={onRemoveAgenda}
                                    size="small"
                                    className="editing"
                                    variant="destructive-quaternary"
                                    data-id="remove-agenda-button"
                                >
                                    Remove agenda
                                </Button>
                                <Button
                                    disabled={disableSaveTemplateButton || commitInProgress}
                                    withMarginLeft
                                    className="editing"
                                    variant="plain"
                                    onClick={createTemplate}
                                    size="small"
                                    data-id="save-agenda-button"
                                >
                                    Save as agenda
                                </Button>
                                {/* TODO: add this button when it doesn't break the agenda */}
                                {/* <Button
                                    disabled={readOnly || commitInProgress}
                                    onClick={() => {
                                        setSelected([]);
                                        props.toggleAgendaPalette();
                                    }}
                                    withMarginLeft
                                    className="editing"
                                    variant="secondary"
                                    size="small"
                                    data-id="change-agenda-button"
                                >
                                    Change agenda
                                </Button> */}
                                <AgendaGeneratorContainer
                                    sessionId={currentDesignerTemplate?.id}
                                    onAgendaGenerationError={onAgendaGenerationError}
                                    onAgendaItemGenerated={onNewAgendaItemGenerated}
                                    disabled={readOnly || commitInProgress}
                                    isEventAgenda={true}
                                    isRoom={isRoom}
                                    isSingleAgendaItem={false}
                                />
                            </div>
                        </div>
                    </div>

                    {isReadOnly ? null : (
                        <AgendaTemplatesEditorSubHeader
                            createNewAgendaItem={createNewAgendaItem}
                            selected={selected}
                            isReadOnly={!!isReadOnly}
                            inEventSection={true}
                            setEditState={setEditState}
                            commit={commit}
                            unlockAgendaItem={() => {}}
                            toggleDeleteAgendaItems={toggleDeleteAgendaItems}
                            beforeUpdateMultiple={beforeUpdateMultiple}
                            onOpenConfigDialog={onOpenConfigDialog}
                            mobileState={mobileState}
                            setSelected={setSelected}
                            selectedWithTool={selectedWithTool}
                        />
                    )}
                    {/* @ts-expect-error types missmatch */}
                    <DndProvider manager={manager} key="dnd-provider-agenda">
                        <div id="agendaTemplatesEditorScrollContainer" className={cls("mozilla-scrollbar", classes.agendaBodyContainer)}>
                            {sortedAgendaItems.map((item) => (
                                <AgendaItemEditor
                                    key={item.id}
                                    readOnly={!!props.readOnly}
                                    openConfigDialog={onTogglePalette}
                                    onSelect={onSelect}
                                    mobileState={{isMobile: false}}
                                    commitInProgress={commitInProgress}
                                    commitAfterUpdate={commit}
                                    agendaItem={item}
                                    inEventSettings={!isRoom}
                                    openPreview={() => configure(ChooseToolStep.Preview, item.id, item.artifact?.artifactId)}
                                    onAddSpeaker={handleClickAddSpeaker}
                                    focusedAgendaItem={focusedAgendaItem}
                                    setFocusedAgendaItem={setFocusedAgendaItem}
                                    isSelected={selected.includes(item.id)}
                                    sessionId={currentDesignerTemplate?.id}
                                />
                            ))}
                        </div>
                        <AgendaItemDragPreview />
                    </DndProvider>

                    {!isReadOnly ? (
                        <>
                            <ConfirmationDialog
                                key="templates-delete-agenda-items-dialog"
                                title={`Delete agenda item${selected.length > 1 ? "s" : ""}?`}
                                content="Your agenda will be adjusted accordingly."
                                cancelButton={"Cancel"}
                                minWidth
                                submitButton="Delete"
                                onClose={toggleDeleteAgendaItems}
                                open={openDeleteSelected}
                                submitButtonVariant="destructive-secondary"
                                onConfirm={handleDeleteAgendaItems}
                                variant="warning"
                                isDeleteConfirmation
                            />
                        </>
                    ) : null}
                    {!isReadOnly ? <AgendaItemCreateButton inEventSettings={true} createNewAgendaItem={createNewAgendaItem} /> : null}
                </div>
            ) : (
                <EventAgendaEmptyState toggleAgendaPalette={props.toggleAgendaPalette} readOnly={props.readOnly} />
            )}

            {selectedAgendaItem && open && (
                <AddSpeakerDialog
                    eventId={currentDesignerTemplate.id}
                    open={open}
                    onClose={toggleDialog}
                    agendaItemId={selectedAgendaItem}
                />
            )}
        </>
    );
};

export default EventAgendaEditor;
