import {cls} from "@ui/cdk/util/util";
import {NavLink, useHistory} from "@workhorse/api/routing";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import {enableEndSessionModal} from "@workhorse/components/header/headerUtils";
import {useIsLobby, useIsPreJoin} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import classes from "../styles/PlayerHeader.module.scss";
import {useTranslation} from "react-i18next";
import {useMobile} from "@workhorse/providers/MobileProvider";

export default function PlayerSessionsLogo() {
    const {t} = useTranslation();
    const {isGuest} = useUserInfo();

    const history = useHistory();

    const {isLobby} = useIsLobby();
    const {isPreJoin} = useIsPreJoin();

    const {isMobile} = useMobile();

    const isSessionView = !isLobby && !isPreJoin;

    const preventNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isSessionView) {
            enableEndSessionModal(true);
            e.preventDefault();
        } else if (isLobby) {
            history.push("/");
        }
    };

    return isGuest ? (
        <div className={cls("flex flex-col flex-center-all", classes.logo)}>
            <BrandingLogo size="small" customSize={isMobile ? "small" : "big"} variant="dark" />
        </div>
    ) : (
        <NavLink
            replace={true}
            to="/"
            className={cls("flex flex-col flex-center-all", classes.logo)}
            onClick={preventNavigation}
            key="nav-link"
            role="heading"
            aria-label={t("header.aria.go_home") ?? "Go Home"}
        >
            <BrandingLogo size="small" customSize={isMobile ? "small" : "big"} variant="dark" />
        </NavLink>
    );
}
