import {UpdateOneGuestDocument} from "@generated/data";
import toast from "@workhorse/api/toast";
import {readRemoteUser, updateCachedRemoteUser} from "@workhorse/api/user";
import {LanguageSelectorType} from "../LanguageSelector";
import LanguageSelectorComponent from "./LanguageSelectorComponent";
import {useEffect, useState} from "@workhorse/api/rendering";
import apollo from "@workhorse/api/apollo";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";

function GuestLanguageSelector(props: LanguageSelectorType) {
    const {skipUpdate} = props;
    const {i18n} = useTranslation();
    const {search} = useLocation();

    const localStorageLang = localStorage.getItem("user-language");
    const [language, setLanguage] = useState<string>(i18n.language ?? "en");

    const sessionOwnerLanguage = props.ownerLanguage;

    const handleChangeLanguage = (lang: string) => {
        setLanguage(lang);
        localStorage.setItem("user-language", lang);

        if (language !== lang) {
            i18n.changeLanguage(lang);
        }

        if (skipUpdate) return;

        apollo.client
            .mutate({
                mutation: UpdateOneGuestDocument,
                variables: {
                    data: {
                        language: lang,
                    },
                },
            })
            .then((res) => {
                if (res.data?.updateOneGuest) {
                    const remoteUserData = readRemoteUser();

                    if (!remoteUserData?.getRemoteUser?.user?.id) {
                        return;
                    }

                    updateCachedRemoteUser({
                        user: {
                            language: lang,
                        },
                    });
                }
            })
            .catch(() => {
                toast("Something went wrong with changing the language.", {type: "error"});
            });
    };

    useEffect(() => {
        if (sessionOwnerLanguage) {
            if (!localStorageLang || (localStorageLang === sessionOwnerLanguage && sessionOwnerLanguage !== language)) {
                handleChangeLanguage(sessionOwnerLanguage);
            }
        }
    }, [sessionOwnerLanguage]);

    useEffect(() => {
        if (props.checkUrlQueryForLanguage) {
            const urlParams = new URLSearchParams(search);
            const language = urlParams.get("language");

            if (language && i18n.language !== language) {
                handleChangeLanguage(language);
            }
        }
    }, [search]);

    return <LanguageSelectorComponent {...props} language={language} onChange={handleChangeLanguage} />;
}

export default GuestLanguageSelector;
