import clientEvents from "@api/events/client";
import {AgendaItemType} from "@generated/data";
import {ArrayItem} from "@workhorse/declarations";
import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";
import {getMaxSessionOrder, makeEmpty, makeId} from "../../utils";

type DesignerApiAgendaItem = ArrayItem<DesignerApiSession["agendaItems"]>;

export type LocalCreateAgendaItemInput = Omit<
    NonNullable<DesignerApiAgendaItem>,
    "id" | "oldId" | "createdAt" | "updatedAt" | "artifact"
> & {
    doNotUpdateCreatePls?: boolean;
    overrideOrder?: boolean;
};

export type CreateAgendaItemInput = Omit<DesignerApiAgendaItem, "artifact">;

export function createAgendaItem(
    input?: Partial<Omit<DesignerApiAgendaItem, "createdAt" | "updatedAt" | "id" | "oldId" | "artifact">>
): DesignerApiAgendaItem {
    const id = makeId();
    const agendaItemWithDefaults = Object.assign({}, designer.constants.agendaItemDefaults, input ?? {}, {
        artifact: designer.constants.makeDefaultConferenceArtifact(makeId(), id),
    });
    const now = null as unknown as Date;

    const {
        __typename,
        descriptionJson,
        duration,
        endedAt,
        isDeleted = false,
        order,
        startAt,
        timeSpentInSeconds,
        processing,
        title,
        type,
        update,
        userConfiguredDuration,
        artifact,
        locked,
        agendaItemSpeakers,
        speakerNotes,
        isPaused,
        speakerNotesJson,
    } = agendaItemWithDefaults;

    return {
        __typename,
        id,
        oldId: id,
        artifact,
        createdAt: now,
        descriptionJson,
        duration,
        endedAt,
        isDeleted,
        order,
        startAt,
        timeSpentInSeconds,
        processing,
        title,
        type,
        update,
        updatedAt: now,
        userConfiguredDuration,
        locked,
        agendaItemSpeakers,
        speakerNotes,
        isPaused,
        speakerNotesJson,
    };
}

export class CreateAgendaItemAction extends DesignerAction<LocalCreateAgendaItemInput> {
    public commit() {
        const agendaItems = designer.currentAgendaItems()?.slice(0);
        const plannedUndeleted = agendaItems.filter(
            (a) => a.type === AgendaItemType.Planned && !a.isDeleted && a.title !== designer.constants.DUMMY_AGENDA_ITEM_TITLE
        )?.length;
        if (plannedUndeleted >= 30) {
            clientEvents.emit("agenda-items-limit-error", true);
            return "";
        }

        const dummyAgendaItem = agendaItems.find((a) => a.title === designer.constants.DUMMY_AGENDA_ITEM_TITLE && !a.isDeleted);

        if (dummyAgendaItem && !this.input.doNotUpdateCreatePls) {
            designer.api.agendaItem.update({
                id: dummyAgendaItem.id,
                agendaItem: {
                    ...this.input,
                    order: this.input.order ?? dummyAgendaItem.order,
                    type: this.input.type ?? AgendaItemType.Planned,
                    createdAt: dummyAgendaItem.createdAt,
                    updatedAt: dummyAgendaItem.updatedAt,
                    title:
                        this.input.title && this.input.title.trim() !== designer.constants.DUMMY_AGENDA_ITEM_TITLE
                            ? this.input.title.trim()
                            : "",
                    // IMPORTANT
                    // DO NOT add the artifact here because the diff mechanism has specific fields
                    // that need to be set on operations such as replace or create
                    // which is why the artifact MUST be added via its designer method
                },
            });

            if (!dummyAgendaItem.artifact) {
                designer.api.agendaItem.addArtifact({
                    agendaItemId: dummyAgendaItem.id,
                    addDefaultArtifact: true,
                    overrides: {},
                });
            }

            return dummyAgendaItem.id;
        }

        const order =
            this.input.overrideOrder && this.input.order !== undefined
                ? this.input.order
                : agendaItems.filter((a) => !a.isDeleted).length === 0
                ? 0
                : this.input.order || getMaxSessionOrder() + 1;

        if ("doNotUpdateCreatePls" in this.input) {
            delete this.input.doNotUpdateCreatePls;
        }
        if ("overrideOrder" in this.input) {
            delete this.input.overrideOrder;
        }

        const newAgendaItem: DesignerApiAgendaItem = {
            ...createAgendaItem(this.input),
            order,
        };
        agendaItems.push(newAgendaItem);

        designer.updateCurrentSession(
            {
                agendaItems,
            },
            {
                strategy: "replace",
            }
        );

        return newAgendaItem.id;
    }
}
