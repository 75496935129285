import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import Input from "@ui/cdk/Input";
import IconButton from "@ui/core/components/IconButton";
import {useRef} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import classes from "./style/MySpeakersSearchbar.module.scss";

type ChatHeaderSearchbarProps = {
    value: string;
    setValue: (value: string) => void;
};

function MySpeakersSearchbar(props: ChatHeaderSearchbarProps) {
    const {value, setValue} = props;

    const {t} = useTranslation();

    const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = setTimeout(() => {
            setValue(e.target.value);
        }, 200);
    };

    const handleClearSearchValue = () => {
        setValue("");
    };

    const handleKeyDownSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            handleClearSearchValue();
        }
    };

    return (
        <Input
            value={value}
            onChange={handleChangeSearchValue}
            onKeyDown={handleKeyDownSearchInput}
            placeholder={t("g.search") ?? undefined}
            variant="standard"
            maxCharCount={60}
            formControlClassName={classes.searchBar}
            startAdornment={<SearchRoundedIcon />}
            className="rnd-btn"
            endAdornment={
                value ? (
                    <IconButton size="small" onClick={handleClearSearchValue}>
                        <CloseRoundedIcon />
                    </IconButton>
                ) : undefined
            }
        />
    );
}

export default MySpeakersSearchbar;
