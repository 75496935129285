import {makeStyles} from "@material-ui/core";
import Typography from "@ui/cdk/Typography";
import React from "@workhorse/api/rendering";
import {ReactComponent as BackgroundWithBalls} from "../../../assets/media/BackgroundWithBalls.svg";
import BrandingLogo from "@ui/cdk/BrandingLogo";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.main.palette.elevation.onSurface[0],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        borderRadius: 12,
        "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
    },
    paper: {
        minWidth: "35%",
        maxHeight: "80%",
        display: "flex",
        maxWidth: 632,
        zIndex: 99,
        background: theme.main.palette.elevation.surface[0],
        borderRadius: 12,
        boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    },
    header: {
        color: theme.main.palette.text.primary,
    },
    background: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
}));

const CodeAlreadyUsedDialog: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            {/* <LeavePageNative title="Leave session" content="You are about to leave this session. Do you want to continue?" /> */}

            <div className={classes.root}>
                <BackgroundWithBalls className={classes.background} />
                <Typography variant="xl5" component="div" className={classes.header}>
                    Private link is used by another user!
                </Typography>
                <BrandingLogo />
            </div>
        </>
    );
};

export default CodeAlreadyUsedDialog;
