import {PaymentConfigFragment} from "@generated/data";
import {mutate} from "@workhorse/dataApi";

export const upsertPaymentConfig = async (
    config: PaymentConfigFragment,
    connections?: {bookingEventId?: string; sessionEventId?: string}
) => {
    const variables = config.id
        ? {
              update: {
                  id: config.id,
                  enabled: config.enabled,
                  amount: config.amount,
                  currency: config.currency,
                  terms: config.terms,
                  paymentProviderIds: config.paymentProviders?.map((p) => p?.id ?? "") ?? [],
              },
          }
        : {
              create: {
                  enabled: config.enabled,
                  amount: config.amount,
                  currency: config.currency,
                  terms: config.terms,
                  paymentProviderIds: config.paymentProviders?.map((p) => p?.id ?? "") ?? [],
                  bookingEventId: connections?.bookingEventId,
                  sessionEventId: connections?.sessionEventId,
              },
          };

    return await mutate("UpsertPaymentConfigDocument", {
        variables: variables,
    }).then(({errors, data}) => {
        if (errors || !data?.upsertPaymentConfig) {
            if (errors) {
                console.error(errors);
            }
            throw new Error("There was a problem when upserting a payment config");
        } else {
            return data.upsertPaymentConfig;
        }
    });
};

export const deletePaymentConfig = async (id: string) => {
    return await mutate("DeletePaymentConfigDocument", {
        variables: {id},
    }).then(({errors, data}) => {
        if (errors || !data?.deletePaymentConfig) {
            if (errors) {
                console.error(errors);
            }
            throw new Error("There was a problem when upserting a payment config");
        } else {
            return data.deletePaymentConfig;
        }
    });
};
