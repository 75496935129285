import {useCallback, useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import Loading from "@workhorse/components/Loading";

type LoaderState = {
    active: boolean;
    message?: string;
};

const useMainLoadingStore = () => {
    const [state, setState] = useState<LoaderState>({
        active: false,
    });

    const setLoaderState = useCallback((newState: Partial<LoaderState>) => {
        setState((c) => ({...c, ...newState}));
    }, []);

    return useMemo(
        () => ({
            state,
            setLoaderState,
        }),
        [state, setLoaderState]
    );
};

export const [MainLoadingProvider, useMainLoading] = createContextProvider(
    {
        name: "MainLoading",
    },
    useMainLoadingStore
);

export const MainLoadingState = () => {
    const {
        state: {active, message},
    } = useMainLoading();

    return active ? <Loading location="main" key="root-loading-indicator" aboveAll message={message} /> : null;
};
