import {getLocalTimezone} from "@common/utils";
import {ROOM_NAME_MAX_LENGTH} from "@common/validation/room.validation";
import {IsRoomSlugUniqDocument, SessionLifecycle, SessionSettingsSource, SessionSource} from "@generated/data";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import {markAsSeen} from "@workhorse/api/seen";
import SessionLockSessionToggler from "@workhorse/api/session-settings/sections/AccessSection/components/SessionLockSessionToggler";
import SessionAutoRecordingToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionAutoRecording";
import SessionAutoTranscribingToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionAutoTranscribing";
import {SessionOptionWarning} from "@workhorse/api/session-settings/sections/SessionSection/components/SessionOptionWarning";
import toast from "@workhorse/api/toast";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {readRemoteUser} from "@workhorse/api/user";
import {getInitialSessionFlags} from "@workhorse/components/CreatePlannedSession/utils";
import {evictLocalQueries} from "@workhorse/providers/HostTypeProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {generateSessionName} from "@workhorse/util";
import {removeSpecialChars} from "@workhorse/util/url";
import CopyToClipboard from "react-copy-to-clipboard";
import inputClasses from "../../../../rooms/views/style/RoomInput.module.scss";
import classes from "./styles/OnboardingSessionDialog.module.scss";

type FormValues = {name: string; slug: string; requestPermissionToJoin: boolean; autoRecording: boolean; autoTranscribing: boolean};

const currentTimezone = getLocalTimezone();

async function createRoom(values: FormValues) {
    const {name, slug, requestPermissionToJoin, autoRecording, autoTranscribing} = values;

    designer.state.initializeOrResetState(null);
    designer.clearPersistentStorage();
    designer.clearSessionStorage();
    designer.clearActionsHistory();

    const {firstName} = readRemoteUser()?.getRemoteUser.user ?? {};
    const generatedName = generateSessionName({isRoom: true, userName: firstName});

    const initialSessionFlags = getInitialSessionFlags(SessionSettingsSource.Room);

    const id = makeId();

    designer.state.initializeOrResetState(id);

    const createdSessId = designer.api.session.create({
        session: {
            id,
            name: name,
            generatedName,
            timeZone: currentTimezone,
            timeDependency: false,
            quickSession: true,
            lifecycle: SessionLifecycle.NotStarted,
            source: SessionSource.Room,
            startAt: undefined,
            room: {
                __typename: "Room",
                id: makeId(),
                active: true,
                // @ts-expect-error because createdAt shouldn't be defined here
                createdAt: undefined,
                slug: slug,
            },
            ...initialSessionFlags,
            requestPermissionToJoin,
            autoRecording,
            autoTranscribing,
            __typename: "Session",
        },
    });

    const res = await designer.commitCreateSession();

    if (res?.id) {
        evictLocalQueries(res.id);
    }

    return res?.room?.id;
}

type OnboardingRoomsCreateDialogProps = {
    onClose?: () => void;
};

function OnboardingRoomsCreateDialog(props: OnboardingRoomsCreateDialogProps) {
    const {onClose} = props;

    const history = useHistory();

    const session = useSession();
    const user = useUserInfo();

    const defaultRoomName = session.generatedName ?? "";
    const workspaceSlug = user?.activeWorkspace?.slug ?? "";
    const host = `https://${parent.top?.location.host}/room/`;
    const baseURL = `${host}${workspaceSlug}` + (workspaceSlug ? "/" : "");

    const initialValues: FormValues = {
        name: `${user.firstName.trim()}'s room`,
        slug: `${user.firstName.trim()}-room`.toLowerCase(),
        requestPermissionToJoin: false,
        autoRecording: false,
        autoTranscribing: false,
    };

    const [values, setValues] = useState<FormValues>(initialValues);
    const [loading, setLoading] = useState<boolean>(false);
    const [urlNotUniqueError, setUrlNotUniqueError] = useState<boolean>(false);

    const {mixpanelTrack} = useMixpanelTracker();

    const dialogRef = useRef<DialogImperativeRef>();

    const handleMarkAsSeen = async () => {
        await markAsSeen({
            GLOBALS: {
                userOnboardingRoomsCreate: true,
            },
        });
    };

    const handleCheckUniqueSlug = async (slug: string) => {
        const res = await apollo.client.query({
            query: IsRoomSlugUniqDocument,
            variables: {
                roomSlug: slug,
            },
            fetchPolicy: "network-only",
        });

        const isUnique = !!res.data.checkIfRoomSlugUniq?.isUnique;

        return isUnique;
    };

    const handleCloseDialog = () => {
        mixpanelTrack("frontend-onboarding-rooms-create-dismissed", "rooms");
        handleMarkAsSeen();
        onClose?.();
    };

    const handleConfirm = async () => {
        setUrlNotUniqueError(false);
        setLoading(true);

        const slug = `${workspaceSlug + (workspaceSlug ? "/" : "") + values.slug}`;

        const isSlugUnique = await handleCheckUniqueSlug(slug);

        if (!isSlugUnique) {
            setUrlNotUniqueError(true);
            setLoading(false);

            return;
        }

        const roomId = await createRoom({...values, slug});

        if (!roomId) {
            toast("Something went wrong with creating the room!", {type: "error"});
            setLoading(false);

            return;
        }

        mixpanelTrack("frontend-onboarding-rooms-create", "rooms");
        handleMarkAsSeen();
        onClose?.();
        setLoading(false);
        history.replace(`/rooms/${roomId}/instances`);
    };

    const handleOnSessionLinkCopy = () => {
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
    };

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const eventName = e.currentTarget.name;
        const eventValue = e.currentTarget.value;

        if (eventName === "slug") {
            setUrlNotUniqueError(false);
        }

        if (eventName === "name") {
            const cleanValue = removeSpecialChars(e.currentTarget.value);

            setValues((prev) => ({...prev, [eventName]: eventValue, slug: cleanValue}));

            return;
        }

        setValues((prev) => ({...prev, [eventName]: eventValue}));
    };

    const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        const eventName = e.currentTarget.name;
        const eventValue = e.currentTarget.value;

        if (!eventValue.trim().length) {
            setValues((prev) => ({...prev, [eventName]: initialValues[eventName]}));

            return;
        }

        if (eventName === "slug") {
            const cleanValue = removeSpecialChars(e.currentTarget.value);
            setValues((prev) => ({...prev, slug: cleanValue}));
        }
    };

    const handleToggleChange = (key: string, value: boolean) => {
        setValues((prev) => ({...prev, [key]: value}));
    };

    return (
        <Dialog
            imperativeRef={dialogRef}
            open
            classes={{paper: cls(classes.root, classes.rootRooms)}}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={cls("flex flex-col", classes.content)}>
                <Typography variant="xl3" color="primary" fontWeight="boldest">
                    Create Room
                </Typography>
                <div className={classes.info}>
                    <Input
                        data-id="room-name"
                        name="name"
                        formControlClassName="p-0"
                        className={classes.input}
                        placeholder={defaultRoomName}
                        value={values.name}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        maxCharCount={ROOM_NAME_MAX_LENGTH}
                        showTypedCharCount
                        required
                        label="Room name"
                        autoComplete="off"
                        autoFocus
                    />

                    <Input
                        data-id="room-slug"
                        name="slug"
                        formControlClassName="mt-30 p-0"
                        className={cls(classes.input, urlNotUniqueError ? classes.inputError : "")}
                        value={values.slug}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        required
                        label="Room url"
                        autoComplete="off"
                        startAdornment={<Typography className={inputClasses.baseUrl}>{baseURL}</Typography>}
                        inputProps={{
                            "data-private": "lipsum",
                        }}
                        endAdornment={
                            <CopyToClipboard text={baseURL + values.slug} onCopy={handleOnSessionLinkCopy}>
                                <IconButton className={classes.urlCopyIcon}>
                                    <FileCopyOutlinedIcon />
                                </IconButton>
                            </CopyToClipboard>
                        }
                        error={urlNotUniqueError}
                    />

                    {urlNotUniqueError ? (
                        <Typography className={classes.errorText}>This room URL has already been taken.</Typography>
                    ) : null}

                    <SessionLockSessionToggler
                        enabled={values.requestPermissionToJoin}
                        onChange={handleToggleChange.bind(null, "requestPermissionToJoin")}
                        showIcon={true}
                    />

                    <div className={classes.separator} />

                    <SessionAutoRecordingToggler
                        enabled={values.autoRecording}
                        onChange={handleToggleChange.bind(null, "autoRecording")}
                        showIcon={true}
                        hideWarning={true}
                    />

                    <div className={classes.separator} />

                    <SessionAutoTranscribingToggler
                        enabled={values.autoTranscribing}
                        onChange={handleToggleChange.bind(null, "autoTranscribing")}
                        showIcon={true}
                        hideWarning={true}
                    />
                    {(values.autoRecording || values.autoTranscribing) && (
                        <SessionOptionWarning
                            className="mt-12"
                            description="Recording or transcribing a session without the consent of all participants may be illegal and actionable. You should obtain consent to record this session from all participants."
                        />
                    )}
                </div>
                <div className={classes.footer}>
                    <Button noFocusBorder variant="tertiary" onClick={handleCloseDialog}>
                        Skip
                    </Button>
                    <Button noFocusBorder variant="primary" onClick={handleConfirm} loading={loading} disabled={loading}>
                        Create Room
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default OnboardingRoomsCreateDialog;
