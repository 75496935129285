import {OrganizationPermissionType, SwitchWorkspaceDocument} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useMemo, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";

import {useMutation} from "@apollo/client";

import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import {UserInfo, useUserInfo} from "@workhorse/providers/User/UserInfoProvider";

import classes from "./style/NavigationSelectWorkspaceMenu.module.scss";

import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AddWorkspaceDialog from "./AddWorkspaceDialog";

import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import Tooltip from "@ui/cdk/Tooltip";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SwapHorizRoundedIcon from "@material-ui/icons/SwapHorizRounded";
import Button from "@ui/cdk/Button";
import Backdrop from "@ui/core/components/Backdrop";
import IconButton from "@ui/core/components/IconButton";
import {useUserAccess} from "@workhorse/api/access/hooks";
import {markAsSeen, useSeenSection} from "@workhorse/api/seen";
import {beautifyRoleName} from "@workhorse/pages/user/profile/UserManagement/WorkspaceUserManagementRole/utils";
import {switchWorkspaceWithToast} from "@workhorse/pages/user/workspace/utils";

type NavigationSelectWorkspaceMenuProps = {
    user: UserInfo;

    menuAnchorEl: HTMLElement | null;
    setMenuAnchorEl: (menuAnchorEl: HTMLElement | null) => void;

    isDrawerOpen: boolean;
    navbarTheme: string | null;
};

const sessionsLogomark = "https://site.sessions.flowos.com/_content/images/sessions-logo-small-flat.svg";

function NavigationSelectWorkspaceMenu(props: NavigationSelectWorkspaceMenuProps) {
    const [switchWorkspace] = useMutation(SwitchWorkspaceDocument, {
        // TODO: maca - don't refetch this, include this in the getRemoteQuery that's done after changing the workspace
        // refetchQueries: [
        //     {
        //         query: GetSessionSettingsDocument,
        //     },
        // ],
    });

    const user = useUserInfo();
    const access = useUserAccess();

    const canManageWorkspaces = access.organization().canCreateWorkspaces();
    const canManageMembers = access.organization().canEditMembers();

    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.WORKSPACES,
        value: props.user.workspacePermissions.length,
    });

    const userSeenMultipleOrgSelect = useSeenSection("GLOBALS.multipleOrganizationsSelect");
    const hasMultipleOrganizations = props.user.organizationPermissions.length > 1;

    const activeOrg = props.user.activeOrganizationPermission.organization;

    const [addWorkspaceDialogOpen, setAddWorkspaceDialogOpen] = useState(false);

    const history = useHistory();

    const handleMarkMultipleOrgsTooltipAsSeen = () => {
        markAsSeen({
            GLOBALS: {
                multipleOrganizationsSelect: true,
            },
        });
    };

    const handleToggleMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        if (props.menuAnchorEl) {
            props.setMenuAnchorEl(null);
        } else {
            props.setMenuAnchorEl(event.currentTarget);
        }

        if (!userSeenMultipleOrgSelect && hasMultipleOrganizations) {
            handleMarkMultipleOrgsTooltipAsSeen();
        }

        event.stopPropagation();
    };

    const handleGoToManageOrganization = () => {
        history.push("/user/organization/general");
        props.setMenuAnchorEl(null);
    };

    const handleGoToManageWorkspace = () => {
        history.push("/user/workspace/general");
        props.setMenuAnchorEl(null);
    };

    const handleGoToUsersManagement = () => {
        history.push("/user/organization/user-management");
        props.setMenuAnchorEl(null);
    };

    const handleOpenAddWorkspaceDialog = () => {
        setAddWorkspaceDialogOpen(true);
    };

    const handleCloseAddWorkspaceDialog = () => {
        setAddWorkspaceDialogOpen(false);
    };

    const handleSwitchWorkspace = (workspaceId: string) => {
        switchWorkspaceWithToast(workspaceId);

        history.push("/");
        props.setMenuAnchorEl(null);
    };

    const handleGoToChooseOrganization = () => {
        history.push("/choose-organization");
        props.setMenuAnchorEl(null);
    };

    // const goToWorkspaceSettings = async (event: React.MouseEvent<HTMLButtonElement>, workspaceId: string) => {
    //     event.stopPropagation();
    //     switchWorkspaceWithToast(workspaceId);

    //     //if location is already on the workspace settings page, we need to reload the page to get the new data
    //     if (history.location.pathname === "/user/workspace/general") {
    //         history.go(0);
    //     } else {
    //         history.push("/user/workspace/general");
    //     }
    //     props.setMenuAnchorEl(null);
    // };

    const orderedWorkspaces = useMemo(() => {
        const copyOfArr = [...props.user.workspacePermissions];
        return (
            copyOfArr.sort((a, b) => {
                if (a.activeWorkspace) {
                    return -1;
                }
                if (b.activeWorkspace) {
                    return 1;
                }
                return 0;
            }) ?? []
        );
    }, [props.user.workspacePermissions]);

    const isMultipleOrgTooltipOpen = hasMultipleOrganizations && !userSeenMultipleOrgSelect && !props.menuAnchorEl;

    return (
        <>
            {user.invitedOrganizationPermissions.length > 0 ? (
                <div
                    className={cls(classes.pendingOrgInviteNotification, props.isDrawerOpen && classes.drawerOpen)}
                    onClick={() => {
                        history.push("/choose-organization");
                    }}
                >
                    <NotificationImportantIcon />
                    {props.isDrawerOpen ? "You have pending organization invites!" : null}
                </div>
            ) : null}
            <div className={cls(classes.selectContainer, props.navbarTheme ? classes[props.navbarTheme] : "")}>
                <Backdrop open={isMultipleOrgTooltipOpen} />

                <Tooltip
                    title={
                        hasMultipleOrganizations && !userSeenMultipleOrgSelect ? (
                            <div className="fullw flex flex-col gap-8">
                                <div className="fullw flex flex-col gap-4">
                                    <Typography fontWeight="bolder">You are now a member of multiple organizations.</Typography>
                                    <Typography>You can switch between organizations from the workspace select menu.</Typography>
                                </div>
                                <div className="fullw flex flex-row-reverse">
                                    <Button size="smallest" onClick={handleMarkMultipleOrgsTooltipAsSeen}>
                                        Got it
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            ""
                        )
                    }
                    open={isMultipleOrgTooltipOpen}
                    arrow
                    placement="right"
                    classes={{
                        tooltip: classes.multipleOrgsTooltip,
                        arrow: classes.multipleOrgsTooltipArrow,
                    }}
                    interactive
                >
                    <div
                        onClick={handleToggleMenu}
                        className={cls(
                            classes.workspaceSelect,
                            props.menuAnchorEl ? classes.menuOpen : "",
                            props.isDrawerOpen ? classes.drawerOpen : ""
                        )}
                    >
                        <div className={"flex flex-align-center gap-8 fullw minw0"}>
                            <img
                                className={classes.workspaceIconSmall}
                                src={
                                    props.user.activeWorkspace.branding?.logomark ??
                                    props.user.activeWorkspace.branding?.logo ??
                                    sessionsLogomark
                                }
                            />
                            {props.isDrawerOpen ? (
                                <Typography fontWeight="bolder" noWrap>
                                    {props.user.activeWorkspace.name || "Default workspace"}
                                </Typography>
                            ) : null}
                        </div>
                        {props.isDrawerOpen ? props.menuAnchorEl ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon /> : null}
                    </div>
                </Tooltip>
            </div>

            <Menu
                open={Boolean(props.menuAnchorEl)}
                anchorEl={props.menuAnchorEl}
                onClose={handleToggleMenu}
                anchorOrigin={{
                    vertical: -12,
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                getContentAnchorEl={null}
                classes={{
                    paper: classes.menuPaper,
                }}
            >
                <div className={classes.menuListContainer}>
                    <>
                        <div className="py-10 px-16 gap-4 flex flex-col">
                            <div className="fullw flex flex-align-center flex-justify-between">
                                <div className="flex flex-col">
                                    {(activeOrg?.name?.length ?? 0) > 20 ? (
                                        <Tooltip title={activeOrg?.name ?? ""} arrow placement="right">
                                            <Typography variant="lg" fontWeight="bolder" noWrap color="secondary">
                                                {activeOrg.name}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography variant="lg" fontWeight="bolder" noWrap color="secondary">
                                            {activeOrg.name ?? "Organization"}
                                        </Typography>
                                    )}

                                    <Typography color="tertiary">
                                        {activeOrg.membersCount} {activeOrg.membersCount === 1 ? "member" : "members"}
                                    </Typography>
                                </div>

                                {user.activeOrganizationPermission.permissionType === OrganizationPermissionType.Owner ? (
                                    <Tooltip title="Organization settings" arrow placement="top">
                                        <IconButton onClick={handleGoToManageOrganization} className={classes.settingsButton}>
                                            <SettingsOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    </>
                    {canManageMembers || hasMultipleOrganizations ? <div className={classes.divider} /> : null}
                    {canManageMembers && (
                        <MenuItem onClick={handleGoToUsersManagement}>
                            <PersonAddOutlinedIcon />
                            <Typography>Invite & manage members</Typography>
                        </MenuItem>
                    )}
                    {hasMultipleOrganizations && (
                        <MenuItem variant="primary" onClick={handleGoToChooseOrganization}>
                            <SwapHorizRoundedIcon />
                            <Typography>Switch organization</Typography>
                        </MenuItem>
                    )}

                    <div className={classes.divider} />

                    <div className="fullw py-10 px-16 flex flex-align-center flex-justify-between">
                        <Typography variant="lg" fontWeight="bolder" noWrap color="secondary">
                            Workspaces
                        </Typography>

                        <Tooltip title="Workspace settings" arrow placement="top">
                            <IconButton onClick={handleGoToManageWorkspace} className={classes.settingsButton}>
                                <SettingsOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.divider} />

                    <div className={classes.workspaceList}>
                        {orderedWorkspaces.map((p, index) => {
                            const isActive = p.workspace.id === props.user.activeWorkspace.id;

                            return (
                                <MenuItem
                                    key={p.workspace.id}
                                    onClick={() => handleSwitchWorkspace(p.workspace.id)}
                                    className={cls(classes.menuItem, isActive && classes.active)}
                                >
                                    <div className="fullw minw0 flex flex-align-center">
                                        <img
                                            className={classes.workspaceIcon}
                                            src={p.workspace.branding?.logomark || p.workspace.branding?.logo || sessionsLogomark}
                                        />
                                        <div className="fullw minw0 flex flex-col">
                                            {p.workspace.name.length > 20 ? (
                                                <Tooltip title={p.workspace.name} arrow placement="right">
                                                    <Typography color="secondary" fontWeight="bolder" noWrap>
                                                        {p.workspace.name}
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                <Typography color="secondary" fontWeight="bolder" noWrap>
                                                    {p.workspace.name}
                                                </Typography>
                                            )}
                                            <Typography color="tertiary">{beautifyRoleName(p.role.name)}</Typography>
                                        </div>
                                    </div>

                                    {isActive ? (
                                        <div className={classes.iconContainer}>
                                            {isActive ? <CheckRoundedIcon className={classes.checkIcon} /> : null}
                                            {/* <Tooltip title="Settings" arrow placement="top">
                                                <IconButton
                                                    className={classes.settingsButton}
                                                    onClick={(e) => goToWorkspaceSettings(e, p.workspace.id)}
                                                >
                                                    <SettingsOutlinedIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                        </div>
                                    ) : (
                                        <div className={classes.labelSwitch}>Switch</div>
                                    )}
                                </MenuItem>
                            );
                        })}
                    </div>
                </div>

                {/* {canManageMembers || canManageWorkspaces ? <div className={classes.divider}></div> : null}

                {canManageWorkspaces && (
                    <MenuItem onClick={handleGoToManageWorkspaces}>
                        <SettingsOutlinedIcon />
                        <Typography>Manage workspaces</Typography>
                    </MenuItem>
                )} */}

                {/* {access.organization().canCreateWorkspaces() && (
                    <>
                        <div className={classes.divider}></div>
                        <Tooltip
                            title={featureIsAvailable ? "" : "You cannot create more workspaces with your current plan."}
                            arrow
                            placement="top"
                        >
                            <div>
                                <MenuItem
                                    onClick={() => {
                                        handleOpenAddWorkspaceDialog();
                                        props.setMenuAnchorEl(null);
                                    }}
                                    disabled={!featureIsAvailable}
                                >
                                    <AddRoundedIcon />
                                    <Typography>Add a workspace</Typography>
                                </MenuItem>
                            </div>
                        </Tooltip>
                    </>
                )} */}
            </Menu>

            <AddWorkspaceDialog
                open={addWorkspaceDialogOpen}
                onClose={handleCloseAddWorkspaceDialog}
                userId={props.user.id}
                organizationName={props.user.activeOrganizationPermission.organization.name || ""}
                noOfWorkspaces={props.user.workspacePermissions.length}
            />
        </>
    );
}

export default NavigationSelectWorkspaceMenu;
