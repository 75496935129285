import {
    DeleteOneQuestionDocument,
    GetNewQuestionsCountDocument,
    GetSessionQuestionsDocument,
    QaTogglePublicPrivateDocument,
    StopAnsweringQuestionsDocument,
    ToggleQuestionAsAnsweredDocument,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useQuery} from "@workhorse/api/data";
import designer from "@workhorse/api/designer";
import {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {AgendaItem} from "@workhorse/declarations/dataTypes";
import {questionShow} from "@workhorse/pages/player/playerStateVars";
import {getColor, makeInitials} from "@workhorse/util";
import {differenceInMilliseconds} from "date-fns";
import {t} from "i18next";

export enum QaTab {
    OPEN = "Open",
    ANSWERED = "Answered",
}

export const useNewQuestionsCount = () => {
    const count = useQuery(GetNewQuestionsCountDocument);

    return count.data?.newQuestionsCount.count;
};

export const updateNewQuestionsCount = (resetCounter: boolean) => {
    const dataCount = apollo.cache.readQuery({
        query: GetNewQuestionsCountDocument,
    });

    const currentCount = dataCount?.newQuestionsCount.count || 0;

    apollo.client.writeQuery({
        query: GetNewQuestionsCountDocument,
        data: {
            newQuestionsCount: {
                __typename: "NewQuestionsCount",
                count: resetCounter ? 0 : currentCount + 1,
            },
        },
    });
};

export const onNewQuestion = (data: SubscriptionPayload<"QaUpdateDocument">, containerId: string): boolean => {
    const queryData = apollo.cache.readQuery({
        query: GetSessionQuestionsDocument,
        variables: {
            containerId: containerId,
        },
    });

    if (!data?.questionsContainerUpdate?.question) {
        return false;
    }

    const savedQuestion = queryData?.questionsContainer?.questions.find((q) => q.id === data.questionsContainerUpdate?.question?.id);

    const newQuestion = Object.assign(
        {
            __typename: "Question",
            participant: {
                __typename: "Participant",
                dataWithNullableEmail: {
                    __typename: "ParticipantDataWithNullableEmail",
                },
            },
        },
        data.questionsContainerUpdate.question
    );

    const newQuestionsArray = [
        ...(queryData?.questionsContainer?.questions || []).filter((q) => q.id !== newQuestion.id),
        newQuestion,
    ].sort((q1, q2) => differenceInMilliseconds(new Date(q1.createdAt), new Date(q2.createdAt)));

    apollo.client.writeQuery({
        query: GetSessionQuestionsDocument,
        variables: {
            containerId: containerId,
        },
        data: {
            __typename: "Query",
            ...queryData,
            questionsContainer: {
                ...queryData?.questionsContainer,
                __typename: "QuestionsContainer",
                id: containerId,
                questions: newQuestionsArray,
            },
        },
    });

    return !savedQuestion;
};

export const endQuestion = (containerId: string, questionId: string) => {
    return apollo.client
        .mutate({
            mutation: StopAnsweringQuestionsDocument,
            variables: {
                questionsContainerId: containerId,
            },
        })
        .then((res) => {
            if (!res.data?.stopAnsweringQuestions?.id) {
                return;
            }

            return apollo.client
                .mutate({
                    mutation: ToggleQuestionAsAnsweredDocument,
                    variables: {
                        question: {id: questionId},
                        value: true,
                    },
                })
                .then((res) => {
                    if (!res.data?.toggleQuestionAsAnswered.id) {
                        return;
                    }
                    questionShow(null);
                });
        });
};

export const toggleStatus = async (artifactId: string, mode: "true" | "false", editMode = false, currentAgendaItem?: AgendaItem) => {
    if (editMode) {
        designer.api.artifact.updateArtifact({
            id: artifactId,

            artifact: {
                properties: [
                    {
                        key: "publicStatus",
                        value: mode,
                    },
                ],
                // data: {
                //     ...(currentAgendaItem?.artifact?.data ?? {}),
                //     publicStatus: mode,
                // },
            },
        });
    } else {
        await apollo.client.mutate({
            mutation: QaTogglePublicPrivateDocument,
            variables: {
                artifactId,
                properties: [
                    {
                        key: "publicStatus",
                        value: mode,
                    },
                ],
            },
        });
    }
};

export const deleteQuestion = (id: string) => {
    return apollo.client.mutate({
        mutation: DeleteOneQuestionDocument,
        variables: {
            where: {
                id,
            },
        },
    });
};

export const onDeleteQuestion = (containerId: string, questionId: string) => {
    const questionShowing = questionShow();

    const isThisQuestionShowing = questionId === questionShowing?.question?.id;

    if (isThisQuestionShowing) {
        questionShow(null);
    }

    const queryData = apollo.cache.readQuery({
        query: GetSessionQuestionsDocument,
        variables: {
            containerId,
        },
    });

    if (!queryData?.questionsContainer) {
        return;
    }

    apollo.client.writeQuery({
        query: GetSessionQuestionsDocument,
        variables: {
            containerId,
        },
        data: {
            ...queryData,
            questionsContainer: {
                ...queryData?.questionsContainer,
                questions: queryData.questionsContainer.questions.filter((q) => q.id !== questionId),
            },
        },
    });
};

export function getDisplayName(
    firstName: string | undefined,
    lastName: string | undefined,
    ownMessage: boolean | undefined,
    isAnonymous: boolean | undefined
) {
    if (ownMessage) {
        return `${t("participant.g.you")}${isAnonymous ? ` (${t("g.anonymous")})` : ""}`;
    }

    return isAnonymous ? t("g.anonymous") ?? "" : `${firstName ?? ""} ${lastName ?? ""}`.trim();
}

export function getDisplayNameColor(
    firstName: string | undefined,
    lastName: string | undefined,
    ownMessage: boolean | undefined,
    isAnonymous: boolean | undefined
) {
    if (ownMessage || isAnonymous) {
        return undefined;
    }

    const initials = makeInitials(firstName, lastName);
    const displayColor = getColor(initials);

    return displayColor;
}
