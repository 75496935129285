import clientEvents from "@api/events/client";
import {AgendaTemplateDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import designer from "@workhorse/api/designer";
import {readDesignerAgendaItems} from "@workhorse/providers/DesignerSessionDataProviders";
import {readQuery, writeQuery} from "@workhorse/dataApi";
import {WatchdogAgendaTemplatePayload} from "./AgendaTemplateWatchdog";
import {getTemplate} from "./utils";

export const agendaTemplateUpdater = {
    onSubscriptionUpsertAgendaItems: async (
        sessionId: string,
        agendaItems: NonNullable<WatchdogAgendaTemplatePayload["watchdogAgendaTemplate"]["upsertAgendaItems"]>
    ) => {
        const existing = readDesignerAgendaItems();

        const newItems = agendaItems.filter((agendaItem) => !existing.find((a) => a.id === agendaItem.id));
        const existingItems = agendaItems.filter((agendaItem) => existing.find((a) => a.id === agendaItem.id));

        for (const item of existingItems) {
            if (item.id) {
                const existingItem = existing.find((a) => a.id === item.id);
                const writing = {
                    ...existingItem,
                    ...item,
                };

                writeQuery("LocalAgendaItemFindOneDocument", {
                    variables: {
                        agendaItemId: item.id,
                        sessionId: sessionId,
                    },
                    data: {
                        __typename: "Query",
                        agendaItems: [writing],
                    },
                    broadcast: true,
                });
            }
        }

        if (newItems.length) {
            const existing = readDesignerAgendaItems();

            const newAgendaItems = existing
                .map((agendaItem) => {
                    return agendaItems.find((a) => a.id == agendaItem.id) ?? agendaItem;
                })
                .concat(newItems.filter((agendaItem) => !existing.find((a) => a.id === agendaItem.id)))
                .sort((a, b) => {
                    return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
                });
            writeFromFullSession(
                sessionId,
                {
                    agendaItems: newAgendaItems,
                },
                true
            );
        }
        existingItems.forEach((obj) => {
            if (obj.artifact) {
                clientEvents.emit("artifact-updated", {artifact: obj.artifact});
            }
        });
        newItems.forEach((obj) => {
            if (obj.artifact) {
                clientEvents.emit("artifact-updated", {artifact: obj.artifact});
            }
        });

        return true;
    },

    onSubscriptionDeleteAgendaItems: (agendaItemIds: string[]) => {
        agendaItemIds.forEach((p) => {
            apollo.cache.evict({
                id: p,
            });
        });
    },

    templateUpdates: async (
        incomingSessionReadOnly: NonNullable<WatchdogAgendaTemplatePayload["watchdogAgendaTemplate"]["agendaTemplate"]>
    ) => {
        const incomingSession = structuredClone(incomingSessionReadOnly);
        const templateId = incomingSession.id.replace("agenda-template-watchdog-", "");
        const designerTemplateId = templateId + "_template";

        const existingSession = designer.currentSession();
        const existingTemplate = await getTemplate(templateId);

        if (existingSession && existingSession.id === designerTemplateId) {
            designer.api.session.update({
                name: incomingSession.name ?? existingSession.name,
                description: incomingSession.description ?? existingSession.description,
            });

            if (existingTemplate.data.agendaTemplate) {
                apollo.client.writeQuery({
                    query: AgendaTemplateDocument,
                    variables: {
                        where: {
                            id: templateId,
                        },
                    },
                    data: {
                        agendaTemplate: {
                            ...existingTemplate.data.agendaTemplate,
                            ...incomingSession,
                            id: existingTemplate.data.agendaTemplate.id,
                        },
                    },
                });
            }
        }
    },

    deleteTemplate: async (templateId: string) => {
        const deletingId = templateId;
        designer.state.initializeOrResetState(null);
        designer.clearPersistentStorage();
        designer.clearActionsHistory();

        apollo.cache.evict({
            id: apollo.cache.identify({
                __typename: "AgendaTemplate",
                id: deletingId,
            }),
        });

        window.location.href = "/templates/";
    },
};
