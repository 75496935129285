import Avatar from "@ui/cdk/Avatar";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import React, {ReactNode} from "@workhorse/api/rendering";
import Highlighter from "react-highlight-words";

interface Props {
    children?: React.ReactNode;
    className?: string;
    classes?: {
        wrap?: string;
        content?: string;
        details?: string;
    };
    content: string;
    details: string;
    avatar?: string;
    initials?: ReactNode;
    avatarBackground?: string;
    highlightDetails?: boolean;
    search: string;
    delimiter?: ReactNode;
}

export function ContactFetcherDetails(props: Props) {
    const terms = props.search.split(" ");
    return (
        <div className={cls("flex flex-row ", props.className)}>
            <div>
                {props.avatar ? (
                    <Avatar
                        className="fosSessionsAvatar mr-8"
                        size="L"
                        imgProps={{src: props.avatar}}
                        backgroundColor={props.avatarBackground}
                    />
                ) : (
                    <Avatar className="fosSessionsAvatar mr-8" size="L" backgroundColor={props.avatarBackground}>
                        {props.initials}
                    </Avatar>
                )}
            </div>
            <div className={props.classes?.wrap} data-id="participant-item-info">
                <Typography className={props.classes?.content}>
                    <Highlighter searchWords={terms} autoEscape={true} textToHighlight={props.content} />
                </Typography>
                {props.delimiter}
                <Typography component="span" variant="sm" className={props.classes?.details}>
                    {props.highlightDetails !== false ? (
                        <Highlighter searchWords={terms} autoEscape={true} textToHighlight={props.details} />
                    ) : (
                        props.details
                    )}
                </Typography>
            </div>
            {props.children}
        </div>
    );
}
