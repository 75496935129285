import {ArtifactTag} from "@generated/artifacts";
import env from "@generated/environment";
import Radio from "@ui/cdk/Radio";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {hasFeatureFlag, useRemoteApplicationState} from "@workhorse/api/application/state";
import InfoBackdrop from "@workhorse/components/InfoBackdrop";
import {useUser} from "@workhorse/components/user";
import {ArtifactDescriptor, ArtifactType} from "@workhorse/internal/artifact";
import {useEffect, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import classes from "./style/DesignerArtifact.module.scss";

export interface IArtifactsListArtifact {
    artifactDescriptor: ArtifactDescriptor<any>;
    type: ArtifactType;
    macrosInSession?: Array<string | null | undefined>;
    selectArtifact: (artifactId: string, myProductToolId?: string) => void;
    selectedArtifactTag?: string;
    hideSelectionInfo?: boolean;
    inPopover?: boolean;
    isSelected?: boolean;
}

const DesignerArtifact = ({
    artifactDescriptor,
    type,
    macrosInSession,
    selectArtifact,
    selectedArtifactTag,
    hideSelectionInfo,
    inPopover,
    isSelected,
}: IArtifactsListArtifact) => {
    const currentUser = useUser();
    const {applicationState} = useRemoteApplicationState();

    const inSession =
        artifactDescriptor.capabilities.findIndex((cap) => cap.type === "macro") && macrosInSession?.includes(artifactDescriptor.tag);

    const addArtifact = () => {
        selectArtifact(artifactDescriptor.tag, artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/my-product-tool-id"]);
    };

    const hasSystemTag = artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/is-system"];

    const maturity =
        artifactDescriptor?.meta?.labels["artifacts.sessions.flowos.com/maturity"] !== undefined
            ? artifactDescriptor?.meta?.labels["artifacts.sessions.flowos.com/maturity"]
            : 10;
    const isImmature = env.production && maturity <= 5;

    const featureFlag =
        !!applicationState?.featureFlags && artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/feature-flag"]
            ? hasFeatureFlag(
                  artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/feature-flag"],
                  currentUser.email,
                  applicationState
              )
            : false;

    const isVisibleForMe =
        featureFlag ||
        (artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/visible-for"]
            ? currentUser.email.includes(artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/visible-for"])
            : true);

    const content = isVisibleForMe ? (
        <div
            onClick={addArtifact}
            data-id={`designer-artifacts-list-${artifactDescriptor.tag}`}
            className={cls(
                "flex flex-justify-between flex-items-center",
                classes.artifactContainer,
                inSession ? classes.inSession : "",
                isImmature ? classes.inSession : "",
                isSelected && !inPopover && classes.artifactSelected,
                inPopover && classes.inPopover
            )}
        >
            {hasSystemTag ? (
                <Typography component="div" variant="xs" className={cls("flex flex-center-all", classes.tag)}>
                    System
                </Typography>
            ) : null}
            {isImmature ? (
                <Typography component="div" variant="xs" className={cls("flex flex-center-all", classes.tag, classes.blueTag)}>
                    Soon
                </Typography>
            ) : null}

            <LazyLoadImage
                width={inPopover ? 50 : 61}
                height={inPopover ? 50 : 61}
                className={classes.avatarIcon}
                src={
                    artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/custom-icon-url"]
                        ? artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/custom-icon-url"]
                        : `${env.publicResourcesCdnRoot ?? ""}/icons/${artifactDescriptor.meta.icon}.svg`
                }
            />

            <div className={classes.artifactInfo}>
                <div className={cls("flex flex-items-center", inPopover && "flex11-100")}>
                    {hideSelectionInfo ? null : <Radio className={classes.radioRoot} disableRipple checked={isSelected} size="small" />}
                    <Typography component="div" variant="lg" className={classes.artifactName}>
                        {artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/custom-name"]
                            ? artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/custom-name"]
                            : artifactDescriptor.meta.displayName}
                    </Typography>
                </div>
                {inPopover ? null : (
                    <Typography component="div" variant="sm" className={classes.artifactDescription}>
                        {artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/custom-description"]
                            ? artifactDescriptor.meta.labels["artifacts.sessions.flowos.com/custom-description"]
                            : artifactDescriptor.meta.description}
                    </Typography>
                )}
            </div>
        </div>
    ) : (
        <></>
    );


    return isVisibleForMe ? (
        artifactDescriptor.tag === ("flowos/slideshow" as ArtifactTag) ? (
            <InfoBackdrop
                offset="-5px"
                placement="top"
                hostBasedSchema="PLAYER.useTool"
                hasCloseHandler={false}
                // withBackDrop
            >
                {content}
            </InfoBackdrop>
        ) : (
            content
        )
    ) : null;
};

export default DesignerArtifact;
