import {HostType} from "@generated/data";
import {useConferenceRoom} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {makeVar, useReactiveVar} from "@workhorse/api/data";
import {useEffect, useMemo, useState, useRef} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useHostType} from "./HostTypeProvider";

function useLocalNetworkStateStore() {
    const hostType = useHostType();
    const {room, roomStatus} = useConferenceRoom();
    const [state, setLocalNetworkState] = useState({
        isDown: hostType === HostType.Player && roomStatus.disconnected,
        isFluctuating: false,
    });

    useEffect(() => {
        if (!room || hostType !== HostType.Player) {
            return;
        }
        let t: NodeJS.Timeout | null = null;
        if (roomStatus.reconnecting && !state.isDown) {
            console.log("setting banner timeout", new Date().getTime());
            t = setTimeout(() => {
                console.log("SHOWING banner at time", new Date().getTime());
                setLocalNetworkState({
                    isDown: true,
                    isFluctuating: false,
                });
            }, 5000);
        } else if (roomStatus.connected && state.isDown) {
            setLocalNetworkState({
                isDown: false,
                isFluctuating: false,
            });
        }
        return () => {
            if (t != null) {
                clearTimeout(t);
            }
        };
    }, [room, roomStatus, state.isDown, state.isFluctuating]);

    useEffect(() => {
        if (hostType !== HostType.Player) {
            setLocalNetworkState({
                isDown: false,
                isFluctuating: false,
            });
        }
    }, [hostType]);
    return useMemo(() => ({...state, setLocalNetworkState}), [state.isDown, state.isFluctuating]);
}

export const [LocalNetworkStateProvider, useLocalNetworkState] = createContextProvider(
    {
        name: "LocalNetworkStateProvider",
        strict: true,
    },
    useLocalNetworkStateStore
);

export const chimeIsBlocked = makeVar(false);

function useChimeIsBlockedProvider() {
    const isBlocked = useReactiveVar(chimeIsBlocked);
    const hostType = useHostType();
    useEffect(() => {
        if (hostType !== HostType.Player) {
            chimeIsBlocked(false);
        }
    }, [hostType]);
    return isBlocked;
}

export const [ChimeIsBlockedProvider, useChimeIsBlocked] = createContextProvider(
    {
        name: "LocalNetworkStateProvider",
        strict: true,
    },
    useChimeIsBlockedProvider
);
