import {ConfigurationStep, SessionLifecycle, SendSessionFlagsDocument} from "@generated/data";
import env from "@generated/environment";
import Button from "@ui/cdk/Button";
import ActionConfirmationDialog from "@ui/cdk/Dialog/ActionConfirmationDialog";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import React, {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {assistantActionAllowed} from "@workhorse/api/user";
import dialogClasses from "@workhorse/components/calendar/dialog/styles/EventActionsDialog.module.scss";
import {WithSessionEditState} from "@workhorse/declarations";
import {Session} from "@workhorse/declarations/dataTypes";
import {useDesignerIsEditing, useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useAgendaItems, useMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import {orchestrator} from "@workhorse/providers/state";
import {timeDiff} from "@workhorse/util";
import {differenceInMinutes} from "date-fns";
import classes from "./styles/StartSessionButton.module.scss";
import {useTranslation} from "react-i18next";
import {markAsSeen, useSeenSection} from "@workhorse/api/seen";

type StartSessionButtonProps = {
    session: Session;
    isAdmin: boolean;
    isAssistant: boolean;
    className?: string;
} & WithSessionEditState;

function StartSessionButton(props: StartSessionButtonProps) {
    const {t} = useTranslation();
    const {session, isAdmin, isAssistant, sessionEditState} = props;

    const [disabled, setDisabled] = useState(false);
    const [timeoutNotify, setTimeoutNotify] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [startSessionError, setStartSessionError] = useState(false);

    const commitInProgress = useDesignerSessionCommitState();
    const [isEditMode, toggleEditMode] = useDesignerIsEditing();
    const agendaItems = useAgendaItems();
    const macroArtifacts = useMacroArtifacts();
    const firstSesionId = useSeenSection("GLOBALS.userOnboardingFirstSessionId");
    const userSeenGetStartedWidget = useSeenSection("GLOBALS.getStartedWidget");

    const {configurationStep} = sessionEditState;

    const {startAt} = session || {};
    const [, setSessionTimeLeft] = useState(timeDiff(startAt));

    const [isTimeToStartSession, setIsTimeToStartSession] = useState(false);
    const [isTimeToAllowSessionStart, setIsTimeToAllowSessionStart] = useState(false);

    useEffect(() => {
        if (startAt) {
            const fn = () => {
                const newIsTimeToStartSession =
                    env.disableStartupTimer === "true" || (!!startAt && differenceInMinutes(new Date(startAt), new Date()) <= 10);
                setIsTimeToStartSession(newIsTimeToStartSession);
                setIsTimeToAllowSessionStart(
                    !newIsTimeToStartSession && !!startAt && differenceInMinutes(new Date(startAt), new Date()) <= 60
                );
            };

            const i = setInterval(fn, 60 * 1000);
            fn();

            return () => clearInterval(i);
        }
    }, [startAt]);

    const updateTimer = () => {
        setSessionTimeLeft(timeDiff(startAt));
    };
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const startSession = async (sessionId: string) => {
        setDisabled(true);

        // in case there are unsaved changes when starting session, discard them
        designer.undoChanges({
            from: ["agendaItems"],
        });
        const noAgenda =
            agendaItems.length === 0 || (agendaItems.length === 1 && agendaItems[0].title === designer.constants.DUMMY_AGENDA_ITEM_TITLE);

        if (noAgenda) {
            const agendaMacro = macroArtifacts.find((a) => a.artifactId === "flowos/agenda");

            if (agendaMacro) {
                apollo.cache.evict({
                    id: agendaMacro.id,
                });
                apollo.cache.gc();
            }
        }

        setTimeoutNotify(false);
        setTimedOut(false);
        setStartSessionError(false);
        setTooltipOpen(false);

        await orchestrator
            .startSession(sessionId, {
                notifyAfterMs: 5000,
                shouldRetry: false,
                abortAfterMs: 35000,
                notifyCb: (failed, retryAttemptNo) => {
                    setTimeoutNotify((c) => (c !== failed ? failed : c));
                },
                timedOutCb: (failed, retryAttemptNo) => {
                    if (!failed) {
                        setStartSessionError(false);
                    }
                    setTimedOut((c) => (c !== failed ? failed : c));
                },
            })
            .catch((error) => {
                console.log("start session error", error);
                setStartSessionError(true);
            });
        setDisabled(false);
    };

    const startConfirmationDialogImperativeRef = useRef<DialogImperativeRef>({});

    const toggleStartConfirmationDialog = () => {
        startConfirmationDialogImperativeRef.current?.toggle?.();
    };

    const actualStartSession = async () => {
        if (isEditMode) {
            toggleEditMode(false);
        }

        if (!userSeenGetStartedWidget && !firstSesionId) {
            await markAsSeen({
                GLOBALS: {
                    userOnboardingFirstSessionId: session.id,
                },
            });
        }

        await markAsSeen({
            GET_STARTED_WIDGET: {
                "start-session": true,
            },
        });

        await startSession(session.id);
    };

    const handleStartSession = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e.detail > 1) {
            return;
        }

        if ([ConfigurationStep.Edit, ConfigurationStep.Create].indexOf(configurationStep) !== -1) {
            toggleStartConfirmationDialog();
        } else {
            actualStartSession();
        }
    };

    useEffect(() => {
        updateTimer();

        const interval = setInterval(() => {
            if (!session.startAt) {
                return;
            }
            const incomingDate = new Date(session.startAt).getTime() > new Date().getTime();

            if (!incomingDate) {
                clearInterval(interval);
                return;
            }
            updateTimer();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [session.startAt, updateTimer]);

    const btnDisabled =
        startSessionError || timedOut
            ? false
            : !(isTimeToStartSession || isTimeToAllowSessionStart) || commitInProgress || disabled || timeoutNotify;

    const tooltipMsg =
        startSessionError || timedOut
            ? t("error.start_session_network_failure")
            : t("lobby.info_tooltip_session_can_start_one_hour_before");
    const disableTooltip = startSessionError || timedOut ? false : isTimeToStartSession || isTimeToAllowSessionStart;

    const onStartSessionTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const onStartSessionTooltipClose = () => {
        setTooltipOpen(false);
    };

    const joinBackstage = async () => {
        await orchestrator.startSession(session.id, undefined, true);
    };

    return (
        <>
            <Tooltip
                arrow
                placement="top"
                title={tooltipMsg}
                open={startSessionError || timedOut || tooltipOpen}
                onOpen={onStartSessionTooltipOpen}
                onClose={onStartSessionTooltipClose}
                disableFocusListener={disableTooltip}
                disableHoverListener={disableTooltip}
                disableTouchListener={disableTooltip}
            >
                <Button
                    data-id="start-session-button"
                    className={cls("fullw fullh", btnDisabled && classes.disabled, props.className)}
                    onClick={!btnDisabled ? handleStartSession : undefined}
                    variant={isTimeToAllowSessionStart ? "secondary" : "primary"}
                    disabled={btnDisabled}
                    loading={disabled && !commitInProgress}
                    spinnerClassName={classes.spinner}
                >
                    {disabled && !commitInProgress
                        ? t("g.starting_session")
                        : session.event && (isAdmin || isAssistant)
                        ? "Start event"
                        : t("g.start_session")}
                </Button>
            </Tooltip>

            {session.event && (isAdmin || isAssistant) ? (
                <Button
                    data-id="start-backstage-button"
                    // className={cls("fullw fullh", classes.btn, btnDisabled && classes.disabled, props.className)}
                    onClick={joinBackstage}
                    withMarginLeft
                    variant={"secondary"}
                    loading={disabled && !commitInProgress}
                    spinnerClassName={classes.spinner}
                >
                    Join Backstage
                </Button>
            ) : null}

            <ActionConfirmationDialog
                key="start-session-confirmation-dialog"
                imperativeRef={startConfirmationDialogImperativeRef}
                headerText={t("agenda.warning_changes_will_be_lost") ?? ""}
            >
                <div className={dialogClasses.body}>
                    <Typography className={dialogClasses.bodyHeader}>{t("confirm.continue_without_attaching_agenda")}</Typography>
                </div>

                <div className="flex flex-justify-end">
                    <Button variant="quaternary" onClick={toggleStartConfirmationDialog}>
                        {t("g.cancel")}
                    </Button>

                    <Button data-id="start-session-button" variant="primary" withMarginLeft onClick={actualStartSession}>
                        {t("g.start_session")}
                    </Button>
                </div>
            </ActionConfirmationDialog>
        </>
    );
}

export default StartSessionButton;
