import {ReportProblemCategory} from "@generated/data";
import {makeVar} from "@workhorse/api/state";
import UAParser from "ua-parser-js";
import {isDesktopApp} from "@sessions/desktop-api";

export const reportDialogEnabled = makeVar<boolean>(false);
export const reportSubmitted = makeVar<boolean>(false);

export interface ReportDialogData {
    inputValue: string;
    file: File | null;
    device: UAParser.IResult & {isDesktopApp: boolean};
    cancelReason?: string;
    customReason?: string;
    category: string;
}

export const reportDialogDefaultValue = {
    inputValue: "",
    file: null,
    device: {
        ...new UAParser().getResult(),
        isDesktopApp: isDesktopApp(),
    },
    cancelReason: "",
    customReason: "",
    category: "",
};

export const reportDialogData = makeVar<ReportDialogData>(reportDialogDefaultValue);
export default () => null;

export const reportProblemCategoryChoices: {[key: string]: {label: string; category: ReportProblemCategory}} = {
    question: {
        label: "feedback.category.question",
        category: ReportProblemCategory.Question,
    },
    "request-feature": {
        label: "feedback.category.request_feature",
        category: ReportProblemCategory.Task,
    },
    "report-problem": {
        label: "feedback.category.report_problem",
        category: ReportProblemCategory.Problem,
    },
    other: {
        label: "feedback.category.other",
        category: ReportProblemCategory.Incident,
    },
};
