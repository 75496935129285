import React, {Fragment} from "@workhorse/api/rendering";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMobile} from "@workhorse/providers/MobileProvider";
import FooterPlayer from "../FooterPlayer";
import FooterLobby from "../lobby/FooterLobby";

type SessionFooterProps = {
    session: SessionData;
    sessionStarted: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    isLobby: boolean;
    isSpeaker: boolean;
    backstage?: boolean;
};

function SessionFooter(props: SessionFooterProps) {
    const {session, isAdmin, isAssistant, sessionStarted, isLobby, isSpeaker} = props;
    const {currentParticipant} = session;
    const mobileState = useMobile();
    const [isEditMode] = useDesignerIsEditing();
    // in editMode, the designer will inject its own stuff in the footer
    // so... not handled here, at least not yet
    const renderLobbyCond = isEditMode ? !sessionStarted : isLobby ? true : !sessionStarted;

    return (
        <>
            <Fragment key="lobby">
                {renderLobbyCond && <FooterLobby session={session} isAdmin={isAdmin} isAssistant={isAssistant} isSpeaker={isSpeaker} />}
            </Fragment>
            <Fragment key="footer">
                {!renderLobbyCond && sessionStarted && !isLobby && !mobileState.isMobile && (
                    <FooterPlayer
                        session={session}
                        myParticipantId={currentParticipant.pid}
                        isAssistant={isAssistant}
                        isAdmin={isAdmin}
                        // false cause we never rendering this in the lobby
                        inLobby={false}
                        isSpeaker={isSpeaker}
                    />
                )}
            </Fragment>
        </>
    );
}

export default SessionFooter;
