import {ContactGroupInfoFragment, ContactInfoFragment, SpeakerDetailsFragment} from "@generated/data";
import SearchIcon from "@material-ui/icons/Search";
import Input, {InputProps} from "@ui/cdk/Input";
import IconButton from "@ui/core/components/IconButton";
import InputAdornment from "@ui/core/components/InputAdornment";
import React, {useMemo} from "@workhorse/api/rendering";
import {ClipboardEventHandler, FocusEvent, KeyboardEvent, KeyboardEventHandler, memo} from "react";
import {throttle} from "throttle-debounce";
import {Tag, TagType} from "./ContactFetcher";
import {ContactFetcherTag} from "./ContactFetcherTag";
import classes from "./styles/ContactFetcher.module.scss";

interface ContactFetcherProps {
    label?: InputProps["label"];
    placeholder?: string;
    tags: Tag[];
    contactMap: Record<string, ContactInfoFragment | undefined>;
    groupMap: Record<string, ContactGroupInfoFragment | undefined>;
    speakerMap: Record<string, SpeakerDetailsFragment | undefined>;
    onSearch: (value: string) => void;
    onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onArrowUp: () => void;
    onArrowDown: () => void;
    onSpace: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onReturn: (value: string) => void;
    onBackspace: (value: string) => void;
    onRemoveItem: (type: TagType, value: string) => void;
    onUpdateItem: (type: TagType, value: string, newValue: string) => void;
    setInputValueRef: React.MutableRefObject<((value: string) => void) | undefined> | undefined;
    hideIcon?: boolean;
    tagIcon?: JSX.Element;
    disabled?: boolean;
    doNotReturnOnPaste?: boolean;
    doNotAddOnBlur?: boolean;
    isFetchingUserNames?: boolean;
}

function formatEmail(email: string) {
    const trimmed = email.trim();
    if (trimmed.startsWith("<") && trimmed.endsWith(">")) {
        return trimmed.substr(1, trimmed.length - 2).trim();
    }
    return trimmed;
}

function split(value: string[], by: string) {
    const items = [] as string[];
    for (const current of value) {
        const split = current.split(by);
        for (const item of split) {
            items.push(item);
        }
    }
    return items;
}

export const ContactFetcherInput = memo((props: ContactFetcherProps) => {
    const debouncedSearch = useMemo(() => throttle(200, props.onSearch), [props.onSearch]);

    const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        if (event.key === "ArrowUp") {
            event.preventDefault();
            props.onArrowUp();
            return;
        }

        if (event.key === "ArrowDown") {
            event.preventDefault();
            props.onArrowDown();
            return;
        }

        if (event.key === "Backspace") {
            props.onBackspace(event.currentTarget.value);
            return;
        }

        if (event.key === "Enter") {
            event.preventDefault();
            props.onReturn(event.currentTarget.value);
            return;
        }

        // space key
        if (event.key === " ") {
            props.onSpace(event);
            return;
        }
    };

    const handlePaste: ClipboardEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text");
        let emails = split([paste], ",");
        emails = split(emails, ";");
        emails = split(emails, "\n");
        emails = split(emails, " ");
        emails.map(formatEmail).filter(Boolean).forEach(props.onReturn);
    };

    return (
        <Input
            data-id="add-participant-email-input"
            setValueRef={props.setInputValueRef}
            className={classes.participantInput}
            placeholder={
                props.isFetchingUserNames
                    ? "Please wait..."
                    : props.tags.length
                    ? ""
                    : props.placeholder ?? "Add email addresses or search contacts"
            }
            label={props.label}
            onKeyDown={handleInputKeyDown}
            onFocus={props.onFocus}
            onBlur={props.doNotAddOnBlur ? undefined : props.onBlur}
            onChange={(e) => debouncedSearch(e.target.value)}
            onPaste={props.doNotReturnOnPaste ? undefined : handlePaste}
            multiline={true}
            disabled={props.disabled}
            maxRows={2.319}
            withChips={true}
            startAdornment={
                <>
                    {props.tags.length === 0 && !props.hideIcon ? (
                        <InputAdornment position="start">
                            <IconButton type="submit" size="small" className={classes.searchIcon}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : null}
                    {props.tags.map((item) => (
                        <ContactFetcherTag
                            key={`${item.type}-${item.value}`}
                            item={item}
                            contactMap={props.contactMap}
                            groupMap={props.groupMap}
                            disabled={props.disabled}
                            speakerMap={props.speakerMap}
                            onRemove={() => props.onRemoveItem(item.type, item.value)}
                            onUpdate={(newValue) => props.onUpdateItem(item.type, item.value, newValue)}
                            icon={props.tagIcon}
                        />
                    ))}
                </>
            }
        />
    );
});

ContactFetcherInput.displayName = "ContactFetcherInput";
