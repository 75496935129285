import React, {Fragment, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {PlayerRouteMatch, RouteHOCProps} from "@workhorse/declarations";
import {HistoryState, useHistory} from "@workhorse/api/routing";
import Loading from "@workhorse/components/Loading";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import UserInviteCode from "../user/guest/UserInviteCode";
import PlayerEntryPoint from "./PlayerEntryPoint";
import {PlayerHocCleanup} from "./PlayerHocCleanup";
import RoomSessionIdFetcher from "./RoomSessionIdFetcher";
import loadable from "@workhorse/api/loadable";

const RoomInactivePage = loadable(() => import("./RoomInactivePage"), {fallback: <Loading />});

export interface PlayerHocProps extends RouteHOCProps<PlayerRouteMatch> {}

/***
 * why the hoc
 * see ../base/index.tsx
 */
function PlayerHOC(props: PlayerHocProps) {
    const history = useHistory<HistoryState>();
    const isRoom = history.location.pathname.startsWith("/room/");

    const [sessionIdOfRoom, setSessionIdOfRoom] = useState<string>();
    const [roomIsInactive, setRoomIsInactive] = useState(false);
    const tokenFromParams = new URLSearchParams(history.location.search).get("token");
    const sessionIdFromRoute = useSessionIdFromRoute();
    const sessionId = isRoom ? sessionIdOfRoom : sessionIdFromRoute;

    const gotSessionId = !!sessionId && sessionId !== "/";

    const guestToken = useRef(tokenFromParams);

    const onGuestDone = () => {
        guestToken.current = null;
        history.replace(`/session/${sessionId}`);
    };

    const entrypointRenderCond = !guestToken.current && gotSessionId && (isRoom ? !roomIsInactive : true) ? true : false;

    return (
        <div className="route-hoc player-hoc flex-justify-center" style={{padding: 0}} id="player-hoc-wrapper" key={sessionId}>
            <Fragment>
                {guestToken.current && (
                    <UserInviteCode
                        key="invite-code"
                        sessionId={gotSessionId ? sessionId : null}
                        token={guestToken.current}
                        onDone={onGuestDone}
                    />
                )}
            </Fragment>
            <Fragment>
                {entrypointRenderCond && (
                    <Fragment key="all">
                        <PlayerHocCleanup sessionId={sessionId!} key={`cleanup-${sessionId}`} />
                        <PlayerEntryPoint sessionId={sessionId!} key={`entrypoint-${sessionId}`} />
                    </Fragment>
                )}
            </Fragment>
            <Fragment>
                {!guestToken.current && !entrypointRenderCond && !roomIsInactive && <Loading location="Player->index.tsx" />}
            </Fragment>
            <Fragment>
                {isRoom && !sessionIdOfRoom && (
                    <RoomSessionIdFetcher setSessionId={setSessionIdOfRoom} setRoomIsInactive={setRoomIsInactive} />
                )}
            </Fragment>
            <Fragment>{isRoom && sessionIdOfRoom && roomIsInactive && <RoomInactivePage />}</Fragment>
        </div>
    );
}

export default PlayerHOC;
