import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/PaletteKeyIcon.module.scss";

type PaletteKeyIconProps = {
    text: string;
    className?: string;
};

const PaletteKeyIcon = ({text, className}: PaletteKeyIconProps) => {
    return (
        <div className={cls("flex flex-justify-center flex-items-center", classes.container, className)}>
            <Typography fontWeight="bolder">{text}</Typography>
        </div>
    );
};

export default PaletteKeyIcon;
