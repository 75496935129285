import {togglePalette} from "@ui/cdk/util";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {WithChildren} from "@workhorse/declarations";
import ConfirmationDialog from "../ConfirmationDialog";
import {ActionCategory} from "./actionCategTree";
import {
    CommandPaletteResultListProvider,
    CommandPaletteResultsProvider,
    CommandPaletteSearchFiltersProvider,
    CommandPaletteVisibleCategItemsProvider,
    usePaletteResultList,
    usePaletteResults,
} from "./CommandPaletteResultsProvider";
import {useTranslation} from "react-i18next";

type CommandPaletteRootSearchString = string;

// This is used only to tell if the palette is open or not, if used stateful two palettes are rendered.
// DO NOT TOUCH setOpen
function usePaletteOpenStore() {
    const [open, setOpen] = useState(false);
    return useMemo(() => [open, setOpen] as const, [open]);
}

const [PaletteOpenProvider, usePaletteOpen] = createContextProvider({name: "PaletteOpen"}, usePaletteOpenStore);
export {usePaletteOpen};
export {usePaletteRootSearchString};
export {usePaletteHasSearchString};
export {usePaletteActiveCateg};
export {usePaletteRecurrenceConfirmationDialog};

function usePaletteRootSearchStringStore() {
    const [rootSearchStr, setRootSearchStr] = useState<CommandPaletteRootSearchString>("");

    return useMemo(
        () => ({
            rootSearchStr,
            setRootSearchStr,
        }),
        [rootSearchStr]
    );
}

const [CommandPaletteRootSearchStrProvider, usePaletteRootSearchString] = createContextProvider(
    {
        name: "CommandPaletteRootSearchStrProvider",
        strict: true,
    },
    usePaletteRootSearchStringStore
);

function usePaletteHasSearchStringStore() {
    const {rootSearchStr} = usePaletteRootSearchString();
    const [hasSearchStr, setHasSearchStr] = useState<boolean>(false);

    const str = useRef(rootSearchStr);
    str.current = rootSearchStr;

    const getSearchStr = useCallback(() => {
        return str.current;
    }, []);

    useEffect(() => {
        if (!rootSearchStr && hasSearchStr) {
            setHasSearchStr(false);
        } else if (rootSearchStr && !hasSearchStr) {
            setHasSearchStr(true);
        }
    }, [rootSearchStr, hasSearchStr]);

    return useMemo(() => {
        return {
            hasSearchStr,
            getSearchStr,
        };
    }, [hasSearchStr]);
}

const [CommandPaletteHasSearchStrProvider, usePaletteHasSearchString] = createContextProvider(
    {
        name: "CommandPaletteHasSearchStrProvider",
        strict: true,
    },
    usePaletteHasSearchStringStore
);

export type PaletteActiveCateg = {
    id: string | undefined;
    name: ActionCategory | undefined;
    parentId: string | undefined;
    searchStr: string | undefined;
    isForced: boolean;
    targetId?: string;
    prevCateg?: Omit<PaletteActiveCateg, "prevCateg" | "targetId">;
};

export const paletteActiveCategDefault: PaletteActiveCateg = {
    name: undefined,
    parentId: undefined,
    id: undefined,
    searchStr: undefined,
    isForced: false,
    prevCateg: undefined,
    targetId: undefined,
};

function usePaletteActiveCategStore() {
    const [activeCateg, setPaletteActiveCateg] = useState<PaletteActiveCateg>(paletteActiveCategDefault);

    return useMemo(
        () => ({
            activeCateg,
            setPaletteActiveCateg,
        }),
        [activeCateg]
    );
}

const [PaletteActiveCategProvider, usePaletteActiveCateg] = createContextProvider(
    {
        name: "PaletteActiveCategProvider",
        strict: true,
    },
    usePaletteActiveCategStore
);

export function useBeResultCount() {
    const {resultList} = usePaletteResultList();
    const {paletteResults} = usePaletteResults();
    return useMemo(() => {
        return resultList.reduce((sum, k) => sum + paletteResults[k]?.length ?? 0, 0);
    }, [resultList, paletteResults]);
}

export function usePaletteRecurrenceConfirmationDialogStore() {
    const [recurrenceConfirmationState, setRecurrenceConfirmationState] =
        useState<RecurrenceConfirmationState>(recurrenceConfirmationDefaultState);

    const reset = () => {
        setRecurrenceConfirmationState(recurrenceConfirmationDefaultState);
    };
    return {
        recurrenceConfirmationState,
        setRecurrenceConfirmationState,
        reset,
    };
}

const [PaletteRecurrenceConfirmationDialogProvider, usePaletteRecurrenceConfirmationDialog] = createContextProvider(
    {
        name: "PaletteActiveCategProvider",
        strict: true,
    },
    usePaletteRecurrenceConfirmationDialogStore
);

type RecurrenceConfirmationState = {
    action: ((...args: unknown[]) => void) | undefined;
    open: boolean;
    args: unknown[] | undefined;
};

export const recurrenceConfirmationDefaultState = {
    action: undefined,
    open: false,
    args: [],
};

const CommandPaletteRecurrenceConfirmationDialogProvider = (props: WithChildren) => {
    const {t} = useTranslation();
    const {recurrenceConfirmationState, reset} = usePaletteRecurrenceConfirmationDialog();
    const {action, args, open} = recurrenceConfirmationState;

    const executeRef = useRef<((...args: unknown[]) => void) | undefined>();
    executeRef.current = action;

    const argsRef = useRef<unknown[] | undefined>();
    argsRef.current = args;

    const executeActionAfterCheck = (e) => {
        togglePalette();
        if (argsRef.current?.length) {
            executeRef?.current?.(...argsRef.current);
        } else {
            executeRef?.current?.();
        }
        reset();
    };

    return (
        <>
            {props.children}
            {open ? (
                <ConfirmationDialog
                    key="agenda-on-recurrence-confirmation"
                    title={t("palette.recurrence.confirm_action")}
                    content={t("palette.recurrence.confirm_action_description") ?? ""}
                    cancelButton={t("g.cancel") ?? ""}
                    minWidth
                    submitButton={t("g.confirm") ?? ""}
                    abovePalette
                    onClose={(e) => {
                        e?.stopPropagation();
                        reset();
                    }}
                    open={open}
                    onConfirm={executeActionAfterCheck}
                ></ConfirmationDialog>
            ) : null}
        </>
    );
};

export default function CommandPaletteProviders(props: WithChildren) {
    return (
        <PaletteRecurrenceConfirmationDialogProvider>
            <CommandPaletteRecurrenceConfirmationDialogProvider>
                <PaletteOpenProvider>
                    <CommandPaletteRootSearchStrProvider>
                        <CommandPaletteHasSearchStrProvider>
                            <PaletteActiveCategProvider>
                                <CommandPaletteVisibleCategItemsProvider>
                                    <CommandPaletteResultListProvider>
                                        <CommandPaletteSearchFiltersProvider>
                                            <CommandPaletteResultsProvider>{props.children}</CommandPaletteResultsProvider>
                                        </CommandPaletteSearchFiltersProvider>
                                    </CommandPaletteResultListProvider>
                                </CommandPaletteVisibleCategItemsProvider>
                            </PaletteActiveCategProvider>
                        </CommandPaletteHasSearchStrProvider>
                    </CommandPaletteRootSearchStrProvider>
                </PaletteOpenProvider>
            </CommandPaletteRecurrenceConfirmationDialogProvider>
        </PaletteRecurrenceConfirmationDialogProvider>
    );
}
