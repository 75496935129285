import Typography from "@ui/cdk/Typography";
import WarningIcon from "@material-ui/icons/WarningRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import classes from "./style/PaymentsEmbeddingNotification.module.scss";
import {markAsSeen} from "@workhorse/api/seen";

export const PaymentsEmbeddingNotification = () => {
    const close = () => {
        markAsSeen({GLOBALS: {eventPaymentsEmbedding: true}});
    };

    return (
        <div className={classes.wrapper}>
            <div>
                <WarningIcon />
            </div>
            <div>
                <Typography fontWeight="bolder">Is this event page embedded in your website or elsewhere?</Typography>
                <Typography>Remember to update the embed code, otherwise Payments won't show up.</Typography>
            </div>
            <div className={classes.close} role="button" onClick={close}>
                <CloseRoundedIcon />
            </div>
        </div>
    );
};
