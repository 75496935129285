import {RefCallback, useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {DraggableDialogPosition} from "./useDraggableDialog";
import classes from "./DraggableDialog.module.scss";
import {useDrawerRightToggler} from "@workhorse/providers/state";

interface DraggableDialogPositioningProps {
    windowWidth: number;
    windowHeight: number;
    onChange?: (position: DraggableDialogPosition) => void;
}

export function DraggableDialogPositioning(props: DraggableDialogPositioningProps) {
    const {windowWidth, windowHeight, onChange} = props;

    const [rightDrawerState] = useDrawerRightToggler();

    const [node, setNode] = useState<HTMLDivElement | null>(null);

    const mountRef: RefCallback<HTMLDivElement> = useCallback((node) => {
        setNode(node);
    }, []);

    useEffect(() => {
        if (!node) {
            return;
        }
        const id = requestAnimationFrame(() => {
            const rect = node.getBoundingClientRect();
            onChange?.({x: rect.right - windowWidth, y: rect.top - windowHeight});
        });
        return () => {
            cancelAnimationFrame(id);
        };
    }, [node, windowWidth, windowHeight, onChange, rightDrawerState]);

    return <div className={classes.calcPosition} ref={mountRef} />;
}
