import {EventApi, EventInput} from "@fullcalendar/react";
import {CalendarEventCustomFragment, CalendarView, ParticipantInviteStatus, SessionLifecycle, SortOrder} from "@generated/data";
import {rrule} from "@sessions/common/recurrence";
import {WithMobileState} from "@workhorse/declarations";

export type CalendarPeriod = {
    startDate: Date;
    endDate: Date;
};

export type ForgedExternalCalendarEventRuleInfo = {
    rrule: Partial<rrule.Options>[] | string[];
    exdate: Date[];
} | null;

export type RecurrentEventActions = "this-session" | "this-and-following-sessions" | "all-sessions";

export enum EventInstantOrPlannedFilter {
    Instant = "INSTANT",
    Planned = "PLANNED",
}

export type EventInstantOrPlannedFilters = {
    filters: Array<EventInstantOrPlannedFilter>;
};

export enum EventOrderBy {
    Duration = "Duration",
    EndDate = "EndDate",
    StartDate = "StartDate",
}

export type EventSort = {
    sortOrder?: SortOrder;
    sortBy?: EventOrderBy;
};

export type EventsFilters = {
    participantsEmails?: string[];
    instantOrPlannedFilters?: EventInstantOrPlannedFilters;
    lifecycleFilters?: SessionLifecycle[];
    participantInviteStatuses?: ParticipantInviteStatus[];
    workspaceId?: string;
};

export type EventsPaginationParams = {
    cursor: string | {parentId: string; occurrenceId: Date};
    take: number;
};

export type EventsSortFilterParams = {
    startDate?: Date;
    endDate?: Date;
} & EventSort &
    EventsFilters &
    EventsPaginationParams;

export enum CalendarDefaultEventSources {
    "Draft" = "Draft",
    "Invitation" = "Invitation",
    "Published" = "Published",
}

export enum IntervalViewMode {
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    YEAR = "year",
    ALL = "all",
}

export type CalendarBaseEventInput = Required<Omit<EventInput, "id" | "title" | "start" | "end" | "allDay" | "color" | "editable">> & {
    id: string;
    title: string;

    allDay: boolean;
    color: string;
    editable: boolean;

    extendedProps: {
        source: CalendarEventCustomFragment;
        groupId: string;
    };
} & (
        | {
              start: Date;
              end: Date | undefined;
          }
        | {
              startStr: string;
              endStr: string;
          }
    );

export type CalendarRecurrentEventInput = CalendarBaseEventInput &
    Required<
        Pick<EventInput, "rrule" | "duration"> & {
            exdate: string[];
        }
    >;

export type CalendarEventInput = CalendarBaseEventInput | CalendarRecurrentEventInput;

export type BaseEventBox = {
    avatars: {
        max: number;
        position: "top" | "bottom";
        hide: boolean;
    };
};

export type BaseLeafComponent<TElement = Element> = React.DetailedHTMLProps<React.HTMLAttributes<TElement>, TElement> &
    React.HTMLAttributes<TElement>;

export type IBaseEvent<T = CalendarEventCustomFragment> = (
    args: BaseLeafComponent<HTMLDivElement> & {
        event: T;
        userEmail: string;
        activeWorkspaceId: string;

        /**
         * @description Duration in minutes
         */
        duration: number;

        view: CalendarView;

        allDay: boolean;
        halfDayClock: boolean;

        box?: BaseEventBox;

        onMouseTitleOver: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
        onMouseTitleLeave: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

        onMouseTimeOver: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
        onMouseTimeLeave: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    }
) => JSX.Element;

export type BaseTooltipActionInfoPositionDelta = {
    left: number;
    top: number;
};

export type BaseTooltipActionInfo = {
    event: CalendarEventCustomFragment;
    target: HTMLElement;

    positionDelta: BaseTooltipActionInfoPositionDelta;
};

export type BaseTooltipCreateActionInfo = {
    event: {startAt: Date; plannedEnd: Date};
    target: HTMLElement;

    positionDelta: BaseTooltipActionInfoPositionDelta;
};

export type BaseEditEventActionInfo = {
    type: "edit";
    recurrentType?: RecurrentEventActions;
    event: CalendarEventCustomFragment;
};

export type BasePlanEventActionInfo = {
    type: "plan";
    event: CalendarEventCustomFragment;
};

export type BaseDeleteEventActionInfo = {
    type: "delete";
    recurrentType?: RecurrentEventActions;
    event: CalendarEventCustomFragment;
};

export type BaseCreateEventInfo = {
    type: "create";
    period: CalendarPeriod;
    recurrentDays: number;
};

export type BaseCreateTaskInfo = {
    type: "create_task";
    period: CalendarPeriod;
};

export type BaseEditTaskInfo = {
    type: "edit_task";
    event: CalendarEventCustomFragment;
};

export type BaseDeleteTaskInfo = {
    type: "delete_task";
    event: CalendarEventCustomFragment;
};

export type BasePlayEventInfo = {
    type: "play";
    event: CalendarEventCustomFragment;
};

export type BaseRSVPEventInfo = {
    type: "rsvp";
    event: CalendarEventCustomFragment;
    status: ParticipantInviteStatus;
};

export type BaseRescheduleEventInfo = {
    type: "reschedule";
    name: string;
    resize: boolean;

    from: CalendarPeriod;
    to: CalendarPeriod;
    revert: () => void;

    event: CalendarEventCustomFragment;
    calendarEvent: EventApi;
};

export type BaseRemoveYourselfEventActionInfo = {
    type: "removeYourself";
    recurrentType?: RecurrentEventActions;
    event: CalendarEventCustomFragment;
};

export type BaseActionInfo =
    | BaseCreateEventInfo
    | BaseEditEventActionInfo
    | BasePlanEventActionInfo
    | BaseDeleteEventActionInfo
    | BasePlayEventInfo
    | BaseRescheduleEventInfo
    | BaseRSVPEventInfo
    | BaseRemoveYourselfEventActionInfo
    | BaseCreateTaskInfo
    | BaseEditTaskInfo
    | BaseDeleteTaskInfo;

export type BaseActionDialogRef =
    | {
          updateActionInfo: (actionInfo: BaseActionInfo) => void;
          isOpen?: () => boolean | undefined;
          toggle?: () => void;
      }
    | undefined
    | null;

export type BaseActionDialogPartProps<TBaseActionInfo extends BaseActionInfo> = {
    actionInfo: TBaseActionInfo;
    onRecurrentEventActionSelect: (recurrentEventAction: RecurrentEventActions) => void;
    onBookingEventAction: (reason: string) => void;
};

export type BaseActionDialogPart<TBaseActionInfo extends BaseActionInfo = BaseActionInfo> = (
    props: BaseActionDialogPartProps<TBaseActionInfo>
) => JSX.Element | null;

export type BaseActionDialogProps = {
    imperativeRef: React.MutableRefObject<BaseActionDialogRef | null>;

    onConfirm?: (actionInfo: BaseActionInfo, recurrentEventAction: RecurrentEventActions, reason?: string) => void;
    onCancel?: () => void;

    headerText?: string;
    subtitle?: string;
    confirmButtonText?: string;
} & WithMobileState;
