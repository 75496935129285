import {AnswerParticipantsDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";

export const answerParticipants = (sessionId: string, participantIds: string[], hasBeenApproved: boolean) => {
    return apollo.client.mutate({
        mutation: AnswerParticipantsDocument,
        variables: {
            sessionId,
            participantIds,
            hasBeenApproved,
        },
    });
};

export const evictSession = (sessionId?: string) => {
    if (!sessionId) {
        return;
    }
    apollo.cache.evict({id: sessionId});
    apollo.cache.gc();
};
