import {
    ChatUpdateFragment,
    ConfirmUploadFileForChatMessageDocument,
    DeleteOneChatMessageDocument,
    DeleteOneMessageFileDocument,
    GetChatGroupsDocument,
    GetChatMessagesDocument,
    GetUnreadSessionChatCountDocument,
    MessageFile,
    OneChatGroupFragment,
    OneChatMessageFragment,
    SendChatMessageDocument,
    UploadFileForChatMessageDocument,
} from "@generated/data";
import {roundToDecimals} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {makeVar, useQuery} from "@workhorse/api/data";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useMemo} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {readCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {getColor, makeInitials} from "@workhorse/util";
import axios from "axios";
import {t} from "i18next";

export const acceptedVideos = [".mp4", ".webm", ".gif", ".avi", ".mov"];
export const acceptedPictures = [".png", ".jpg", ".svg", ".jpeg", ".heic"];
export const acceptedDocuments = [".doc", ".docx", ".xls", ".xlsx", ".pdf", ".ppt", ".pptx", ".txt"];
export const acceptedFileTypes = [...acceptedDocuments, ...acceptedPictures, ...acceptedVideos];

export const acceptedMaxFileSize = 104857600; // 100 MB in Bytes
export const acceptedMaxGiphySize = 5242880; // 5 MB in Bytes

const MAX_CACHED_CHAT_MESSAGES_COUNT = 150;

export type InputStateType = {
    value: string;
    isSubmitting: boolean;
    pendingFile: File | null;
    replyMessage: ReplyMessageType | null;
    pendingFileProgress: number | null;
};

export const initialInputState = {
    value: "",
    pendingFile: null,
    replyMessage: null,
    pendingFileProgress: null,
    isSubmitting: false,
};

export type ChatMessageFileType = File | MessageFile;

export enum ChatFileType {
    IMAGE = "Image",
    VIDEO = "Video",
    DOCUMENT = "Document",
}

export enum ChatView {
    GROUP,
    PRIVATE,
    FILES,
    NEW_CHAT,
    PRIVATE_CHAT,
}

export enum ChatReactionTab {
    EMOJI = "Emoji",
    GIF = "GIF",
}

export type ChatViewType = {
    view: ChatView;
    chatGroupId: string | null;
};

export type ReplyMessageType = {
    userName: string;
    messageId: string | undefined;
    messageBody?: string;
    files?: Array<MessageFile>;
    timestamp: Date;
    timeFormat: string;
};

export const messageBatch = 30;

export const initialChatView: ChatViewType = {
    chatGroupId: null,
    view: ChatView.GROUP,
};

export const activeChatView = makeVar<ChatViewType>(initialChatView);

// chatGroupId: string for specific group, null for session chat, undefined for total
export const useChatUnreadMessageCount = (sessionId: string, chatGroupId: string | null | undefined) => {
    const sessionChatCount =
        useQuery(GetUnreadSessionChatCountDocument, {
            skip: !!chatGroupId,
        })?.data?.unreadSessionChatCount ?? 0;

    const {data: queryData} = useQuery(GetChatGroupsDocument, {
        fetchPolicy: "cache-only",
        variables: {
            sessionId,
        },
    });

    const chatGroupCount = chatGroupId ? queryData?.chatGroups?.find((group) => group.id === chatGroupId)?.unreadCount ?? 0 : 0;

    const totalCount =
        chatGroupId === undefined
            ? queryData?.chatGroups?.reduce((acc, group) => acc + (group.unreadCount ?? 0), sessionChatCount) ?? 0
            : 0;

    return useMemo(
        () => (chatGroupId === undefined ? totalCount : chatGroupId === null ? sessionChatCount : chatGroupCount),
        [chatGroupId, sessionChatCount, chatGroupCount, totalCount]
    );
};

export const updateUnreadMessagesCount = (
    sessionId: string,
    // string is used for a specific group chat, null for the session chat, undefined for all groups
    // this way.. we can reset all chat groups to 0
    chatGroupId: string | null | undefined,
    resetCounter: boolean,
    chatMessage?: OneChatMessageFragment
) => {
    const currentParticipant = readCurrentParticipant({sessionId});

    if (!currentParticipant || chatMessage?.participantId === currentParticipant.id) {
        return;
    }

    const resetAllContainers = chatGroupId === undefined && resetCounter;

    if (chatGroupId || resetAllContainers) {
        const queryData = apollo.cache.readQuery({
            query: GetChatGroupsDocument,
            variables: {
                sessionId,
            },
        });

        if (!queryData) {
            return;
        }

        apollo.client.writeQuery({
            query: GetChatGroupsDocument,
            variables: {
                sessionId,
            },
            data: {
                ...queryData,
                chatGroups: queryData.chatGroups.map((group) =>
                    group.id === chatGroupId || resetAllContainers
                        ? {...group, unreadCount: resetCounter ? 0 : (group.unreadCount ?? 0) + 1}
                        : group
                ),
            },
        });
    }

    if (chatGroupId === null || resetAllContainers) {
        const sessionChatCount = apollo.cache.readQuery({query: GetUnreadSessionChatCountDocument})?.unreadSessionChatCount ?? 0;

        apollo.client.writeQuery({
            query: GetUnreadSessionChatCountDocument,
            data: {
                unreadSessionChatCount: resetCounter ? 0 : sessionChatCount + 1,
                __typename: "Query",
            },
        });
    }
};

export const onNewMessage = (
    newChatMessage: ChatUpdateFragment["newChatMessage"],
    newChatFile: ChatUpdateFragment["newChatFile"],
    sessionId: string
) => {
    if (!newChatMessage) {
        return;
    }

    const newMessage = Object.assign({}, newChatMessage);

    newMessage.__typename = "ChatMessage";

    if (!newMessage.replyToMessage) {
        newMessage.replyToMessage = null;
    }

    apollo.client.writeQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            chatGroupId: newChatMessage.chatGroupId,
            take: messageBatch,
            before: null,
            skipFiles: false,
        },
        data: {
            chatMessages: [newMessage],
            messageFiles: newChatFile ? [newChatFile] : undefined,
            __typename: "Query",
        },
    });
};

export const onNewChatGroup = (newChatGroup: ChatUpdateFragment["newChatGroup"], sessionId: string, isDelete?: boolean) => {
    if (!newChatGroup) {
        return;
    }

    const queryData = apollo.cache.readQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
    });

    if (!queryData) {
        return;
    }

    const chatGroups = queryData.chatGroups.filter((group) => group.id !== newChatGroup.id);

    apollo.client.writeQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
        data: {
            chatGroups: isDelete ? chatGroups : [...chatGroups, {...newChatGroup, messages: []}],
            __typename: "Query",
        },
    });
};

export const onDeleteMessage = (sessionId: string, chatGroupId: string | null, messageId?: string, fileId?: string) => {
    const queryData = apollo.cache.readQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            chatGroupId,
            take: messageBatch,
            before: null,
            skipFiles: false,
        },
    });

    if (!queryData || !messageId) {
        return;
    }

    // When someone deletes a files, if the message has body text, we only delete the file, otherwise, we delete both the file and the message.
    const currentMessageFile = fileId && queryData.chatMessages.find((m) => m.id === messageId);
    const currentMessageFileWithoutBody = currentMessageFile && !currentMessageFile.body.length;

    // We need to delete the messages that were replies to the deleted message
    const linkedRepliesToTheDeletedMessages: string[] = currentMessageFileWithoutBody || !currentMessageFile ? [messageId] : [];

    for (const m of queryData.chatMessages) {
        if (!m.replyToMessage) {
            continue;
        }

        if (linkedRepliesToTheDeletedMessages.includes(m.replyToMessage.id)) {
            linkedRepliesToTheDeletedMessages.push(m.id);
        }
    }

    const newChatMessages = queryData.chatMessages.filter((m) => {
        if (currentMessageFileWithoutBody && m.id === currentMessageFile.chatmessageId) {
            return false;
        }

        if (linkedRepliesToTheDeletedMessages.includes(m.id)) {
            return false;
        }

        return true;
    });

    const newChatFiles = queryData.messageFiles?.filter((f) => f.chatmessageId !== messageId);

    apollo.client.writeQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            chatGroupId,
            take: messageBatch,
            before: null,
            skipFiles: false,
            // @ts-expect-error This is needed so we can use it in apollo.ts to stop it from merging existing & incoming.
            isDelete: true,
        },
        data: {
            ...queryData,
            messageFiles: newChatFiles,
            chatMessages: newChatMessages,
        },
    });
};

export const resetChatPaginationResults = (sessionId: string, chatGroupId: string | null) => {
    const queryData = apollo.cache.readQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            chatGroupId,
            take: messageBatch,
            before: null,
            skipFiles: true,
        },
    });

    if (!queryData) {
        return;
    }

    const newMessages = queryData.chatMessages.slice(-messageBatch);

    apollo.client.writeQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            take: messageBatch,
            before: null,
            skipFiles: true,
        },
        data: {
            ...queryData,
            chatMessages: newMessages,
        },
    });
};

export const sendChatMessageMutation = (
    body: string,
    sessionId: string,
    chatGroupId: string | null,
    replyToMessageId?: string,
    fileId?: string,
    sendToAllBreakoutRooms?: boolean
) => {
    let participantIds: string[] | undefined = undefined;

    if (chatGroupId) {
        const chatGroup = getChatGroup(sessionId, chatGroupId);

        participantIds = chatGroup?.clientOnly ? chatGroup?.participants.map((p) => p.id) : undefined;
    }

    return apollo.client.mutate({
        mutation: SendChatMessageDocument,
        variables: {
            body,
            sessionId,
            chatGroupId: chatGroupId ?? undefined,
            replyToMessageId,
            fileId,
            sendToAllBreakoutRooms,
            participantIds,
        },
    });
};

export function isFile(file: ChatMessageFileType): file is File {
    return file instanceof File;
}

export function isMessageFile(file: ChatMessageFileType): file is MessageFile {
    if ("downloadLink" in file) {
        return true;
    }

    return false;
}

export function isFileAccepted(file: ChatMessageFileType): boolean {
    const fileTypesRegex = new RegExp(`${acceptedFileTypes.join("|")}$`);

    return !file?.name ? false : fileTypesRegex.test(file.name.toLowerCase());
}

export function getMaxFileSize(file: ChatMessageFileType): number {
    return file.type === "image/giphy" ? acceptedMaxGiphySize : acceptedMaxFileSize;
}

export function getFileUrl(file: ChatMessageFileType): string {
    if (isFile(file)) {
        return URL.createObjectURL(file);
    }

    return file?.downloadLink ?? "";
}

export function getFileType(file: ChatMessageFileType): ChatFileType {
    return file?.type?.startsWith("image")
        ? ChatFileType.IMAGE
        : file?.type?.startsWith("video")
        ? ChatFileType.VIDEO
        : ChatFileType.DOCUMENT;
}

export function deleteChatMessage(messageId: string) {
    return apollo.client.mutate({
        mutation: DeleteOneChatMessageDocument,
        variables: {
            where: {
                id: messageId,
            },
        },
    });
}

export function deleteChatFile(fileId: string) {
    return apollo.client.mutate({
        mutation: DeleteOneMessageFileDocument,
        variables: {
            where: {
                id: fileId,
            },
        },
    });
}

export function getDisplayName(firstName: string | undefined, lastName: string | undefined, ownMessage: boolean) {
    if (ownMessage) {
        return t("participant.g.you") ?? "You";
    }

    return `${firstName ?? ""} ${lastName ?? ""}`.trim();
}

export function getDisplayNameColor(firstName: string | undefined, lastName: string | undefined, ownMessage: boolean) {
    if (ownMessage) {
        return undefined;
    }

    const initials = makeInitials(firstName, lastName);
    const displayColor = getColor(initials);

    return displayColor;
}

export function uploadFileMessage(file: File, sessionId: string, onUploadProgress?: (progress: number) => void) {
    return apollo.client
        .mutate({
            mutation: UploadFileForChatMessageDocument,
            variables: {
                sessionId,
            },
        })
        .then((uploadResponse) => {
            if (!uploadResponse?.data?.uploadFileForChatMessage) {
                toast("Something went wrong with uploading the file.", {type: "error"});
                return null;
            }

            return axios
                .request({
                    url: uploadResponse.data.uploadFileForChatMessage.uploadUrl,
                    method: "PUT",
                    data: file as BodyInit,
                    headers: {
                        "Content-Type": file instanceof File && !!file.type ? file.type : "application/octet-stream",
                    },
                    onUploadProgress: (p) => {
                        const progress = roundToDecimals((p.loaded / (p.total ?? 1)) * 100, 0);
                        onUploadProgress?.(progress);
                    },
                })

                .then(() => {
                    if (uploadResponse?.data?.uploadFileForChatMessage) {
                        return apollo.client
                            .mutate({
                                mutation: ConfirmUploadFileForChatMessageDocument,
                                variables: {
                                    sessionId,
                                    uuid: uploadResponse.data.uploadFileForChatMessage.uuid,
                                    name: file.name,
                                    type: file.type,
                                    size: file.size,
                                },
                            })
                            .then((confirmUploadResponse) => {
                                if (!confirmUploadResponse || !confirmUploadResponse.data?.confirmUploadFileForChatMessage) {
                                    toast("Something went wrong with uploading the file.", {type: "error"});
                                    return null;
                                }

                                return confirmUploadResponse.data.confirmUploadFileForChatMessage;
                            });
                    } else {
                        toast("Something went wrong with uploading the file.", {type: "error"});
                        return null;
                    }
                });
        });
}

export function readChatGroupByParticipantIds(sessionId: string, participantIds: string[]): string | null {
    const queryData = apollo.cache.readQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
    });

    if (!queryData?.chatGroups) {
        return null;
    }

    const currentParticipant = readCurrentParticipant({sessionId});

    if (!currentParticipant) {
        return null;
    }

    const allParticipantIds = [currentParticipant.id, ...participantIds];

    const chatGroupId = queryData.chatGroups.find((group) => group.participants.every((p) => allParticipantIds.includes(p.id)))?.id;

    return chatGroupId ?? null;
}

export function writeChatGroupLocally(sessionId: string, participantIds: string[]): string | null {
    const queryData = apollo.cache.readQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
    });

    if (!queryData) {
        return null;
    }

    const currentParticipant = readCurrentParticipant({sessionId});

    if (!currentParticipant) {
        return null;
    }

    const allParticipantIds = [currentParticipant.id, ...participantIds];

    const chatGroupId = makeId();

    apollo.client.writeQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
        data: {
            ...queryData,
            chatGroups: [
                ...queryData.chatGroups,
                {
                    id: chatGroupId,
                    messages: [],
                    participants: allParticipantIds.map((id) => ({id, __typename: "Participant"})),
                    clientOnly: true,
                    fetchedMessagesOverNetwork: true,
                    __typename: "ChatGroup",
                },
            ],
        },
    });

    return chatGroupId;
}

export function getChatGroupByParticipantIds(sessionId: string, participantIds: string[]): string | null {
    const foundChatGroupId = readChatGroupByParticipantIds(sessionId, participantIds);

    const chatGroupId = foundChatGroupId ? foundChatGroupId : writeChatGroupLocally(sessionId, participantIds);

    if (chatGroupId) {
        handleInitialChatMessagesTrim(sessionId, chatGroupId);
    }

    return chatGroupId;
}

export function getChatGroup(sessionId: string, chatGroupId: string | null): OneChatGroupFragment | null | undefined {
    const queryData = apollo.cache.readQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
    });

    const chatGroup = queryData?.chatGroups.find((group) => group.id === chatGroupId);

    return chatGroup ?? null;
}

export function useChatGroup(sessionId: string, chatGroupId: string | null): OneChatGroupFragment | null | undefined {
    const queryData = apollo.cache.readQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
    });

    const chatGroup = queryData?.chatGroups.find((group) => group.id === chatGroupId);

    return useMemo(() => chatGroup ?? null, [chatGroup]);
}

export const handleInitialViewType = (sessionId: string, totalCount: number, isPrivateChatHidden: boolean): ChatViewType => {
    const sessionChatCount = apollo.cache.readQuery({query: GetUnreadSessionChatCountDocument})?.unreadSessionChatCount ?? 0;

    const queryData = apollo.cache.readQuery({
        query: GetChatGroupsDocument,
        variables: {
            sessionId,
        },
    });

    const firstChatGroupWithUnreadMessages = queryData?.chatGroups.find((group) => group.unreadCount > 0);

    if (totalCount === firstChatGroupWithUnreadMessages?.unreadCount && !isPrivateChatHidden) {
        return {
            chatGroupId: firstChatGroupWithUnreadMessages.id,
            view: ChatView.PRIVATE_CHAT,
        };
    }

    if (sessionChatCount === 0 && firstChatGroupWithUnreadMessages && !isPrivateChatHidden) {
        return {
            chatGroupId: null,
            view: ChatView.PRIVATE,
        };
    }

    return {
        chatGroupId: null,
        view: ChatView.GROUP,
    };
};

export const handleInitialChatMessagesTrim = (sessionId: string, chatGroupId: string | null) => {
    const queryData = apollo.cache.readQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            chatGroupId,
            take: messageBatch,
            before: null,
            skipFiles: false,
        },
    });

    if (!queryData || queryData.chatMessages.length <= MAX_CACHED_CHAT_MESSAGES_COUNT) {
        return;
    }

    const sortedMessages = queryData.chatMessages.slice().sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    apollo.client.writeQuery({
        query: GetChatMessagesDocument,
        variables: {
            sessionId,
            chatGroupId,
            take: messageBatch,
            before: null,
            skipFiles: false,
            // @ts-expect-error This is needed so we can use it in apollo.ts to stop it from merging existing & incoming.
            isDelete: true,
        },
        data: {
            ...queryData,
            chatMessages: sortedMessages.slice(-MAX_CACHED_CHAT_MESSAGES_COUNT),
        },
    });
};
