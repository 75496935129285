import env from "@generated/environment";
import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import React, {useRef} from "@workhorse/api/rendering";
import {MicroControllerProps} from "@workhorse/declarations";
import {updateArtifactProperty} from "@workhorse/providers/state";
import classes from "@workhorse/style/CommonModules/microController.module.scss";
import {Field, Form, Formik, FormikProps} from "formik";
import artifactDescriptor from "@generated/artifacts";
import designer from "@workhorse/api/designer";
import Tooltip from "@ui/cdk/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useTranslation} from "react-i18next";

interface FormValuesProps {
    mute: boolean;
    loop: boolean;
    playMode: boolean;
    source: string;
}

function MicroController(props: MicroControllerProps) {
    const {t} = useTranslation();
    const artifact = props.currentMicro;
    const descriptor = artifactDescriptor[artifact.artifactId];

    const formRef = useRef<FormikProps<FormValuesProps>>(null);

    const iconSrc = `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor?.meta?.icon}.svg`;

    const initialValues = {
        mute: artifact.data?.mute === "true",
        loop: artifact.data?.loop === "true",
        playMode: artifact.data?.playMode ? artifact.data.playMode === "true" : true,
        source: artifact.data.source ?? "",
    } as FormValuesProps;

    const onChangeMute = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateArtifactProperty(artifact.id, "mute", event.target.checked ? "true" : "false");
        formRef.current?.setFieldValue("mute", event.target.checked);
    };

    const onChangeLoop = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateArtifactProperty(artifact.id, "loop", event.target.checked ? "true" : "false");
        formRef.current?.setFieldValue("loop", event.target.checked);
    };

    const onChangePlayMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateArtifactProperty(artifact.id, "playMode", event.target.checked.toString());
        formRef.current?.setFieldValue("playMode", event.target.checked);
    };

    const onSubmitForm = (values: FormValuesProps) => {};

    return (
        <>
            {props.hasAgenda ? (
                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.titleContainer)}>
                    <div className="flex fullw">
                        <img className={classes.artifactIcon} src={iconSrc}></img>
                        <Typography className={cls(classes.artifactTitle, classes.label)}>
                            {props.agendaItemTitle && props.agendaItemTitle !== designer.constants.DUMMY_AGENDA_ITEM_TITLE
                                ? props.agendaItemTitle
                                : "Vimeo"}
                        </Typography>
                    </div>
                </div>
            ) : null}

            <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmitForm}>
                {({values}) => (
                    <Form className={cls("flex flex-wrap flex00-auto fullw")}>
                        {/* {isEditMode ? null : (
                            <Typography component="div" className={classes.label}>
                                {"Play mode"}
                            </Typography>
                        )} */}

                        <Field name="playMode">
                            {() => (
                                <div
                                    className={cls(
                                        "flex flex-justify-between flex-align-center fullw",
                                        classes.settingsContainer,
                                        !props.hasAgenda ? classes.settingsNoMarginTop : undefined
                                    )}
                                >
                                    <div className="flex flex-row flex-align-center gap-5">
                                        <Typography className={classes.settingsTitle}>
                                            {t("artifacts.g.micro_controller.present_to_participants")}
                                        </Typography>
                                        <Tooltip
                                            title={t("artifacts.g.micro_controller.present_to_participants_description") ?? ""}
                                            arrow
                                            placement="top"
                                        >
                                            <InfoOutlinedIcon className={classes.tooltipIcon} />
                                        </Tooltip>
                                    </div>
                                    <Switch
                                        data-id="enable-present"
                                        color="primary"
                                        onChange={onChangePlayMode}
                                        checked={values.playMode?.toString() === "true"}
                                    />
                                </div>
                            )}
                        </Field>

                        {/* {isEditMode ? null : (
                            <Typography component="div" className={classes.label}>
                                {"Video settings"}
                            </Typography>
                        )} */}
                        {/*
                        <Field name="mute">
                            {() => (
                                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.settingsContainer)}>
                                    <Typography className={classes.settingsTitle}>Mute video</Typography>
                                    <Switch color="primary" onChange={onChangeMute} checked={values.mute?.toString() === "true"} />
                                </div>
                            )}
                        </Field> */}

                        <Field name="loop">
                            {() => (
                                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.settingsContainer)}>
                                    <Typography className={classes.settingsTitle}>
                                        {t("artifacts.g.micro_controller.loop_video") ?? ""}
                                    </Typography>
                                    <Switch data-id="loop" color="primary" onChange={onChangeLoop} checked={values.loop} />
                                </div>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default {
    MicroController,
};
