import {ResourcesTypes} from "@generated/artifacts/resources";
import {openExternalLink} from "@workhorse/util/links";
import ExternalGenericCreateForm from "./components/ExternalGenericCreateForm";

type CanvaCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
};

const CanvaCreateForm = (props: CanvaCreateFormProps) => {
    const title = "Export from Canva";
    const description = "Use our plugin to directly import your designs to Sessions";
    const primaryActionLabel = "Get plugin";
    const primaryAction = () => {
        openExternalLink("https://www.canva.com/apps/AAEmyTgqqBk-sessions");
    };

    return (
        <>
            <ExternalGenericCreateForm
                {...props}
                title={title}
                description={description}
                primaryActionLabel={primaryActionLabel}
                primaryAction={primaryAction}
            />
        </>
    );
};
export default CanvaCreateForm;
