import svc from "@api/service/client";
import {checkIfIframeable, getWebsiteTitle, processLink} from "@artifacts/iframe/api/iframeUtils";
import {VideoV1} from "@sessions/common/resources";
import {ResourcesTypes} from "@generated/artifacts/resources";
import TrendingFlatRoundedIcon from "@material-ui/icons/TrendingFlatRounded";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import Input from "@ui/core/components/Input";
import InputAdornment from "@ui/core/components/InputAdornment";
import InputLabel from "@ui/core/components/InputLabel";
import React, {useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import Loading from "@workhorse/components/Loading";
import {ResourceRowExtra} from "../../../utils";
import formClasses from "./styles/CreateForm.module.scss";
import classes from "./styles/IframeCreateForm.module.scss";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import clientEvents from "@api/events/client";
import {modifyResourceCounter} from "./utils";
import {HyperlinkExternal, openExternalLink} from "@workhorse/util/links";

type IframeCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    onDoubleClick: (
        resourceId: ResourceRowExtra["id"],
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
        videoType?: VideoV1["type"]
    ) => void;
};

const IframeCreateForm = (props: IframeCreateFormProps) => {
    const {handleClose} = props;

    const [link, setLink] = useState<string | undefined>("");
    const [embed, setEmbed] = useState<string | undefined>("");
    const [focus, setFocus] = useState<"website" | "embed" | "none">("none");
    const [linktype, setLinktype] = useState<"website" | "embed">("website");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(false);

    const onChangeLink = (e) => {
        if (error) {
            setError(null);
        }
        setLink(e.target.value);
        if (e.target.value !== "") {
            setFocus("website");
        } else {
            setFocus("none");
        }
        setLinktype("website");
    };

    const onChangeEmbed = (e) => {
        if (error) {
            setError(null);
        }
        setEmbed(e.target.value);
        if (e.target.value !== "") {
            setFocus("embed");
        } else {
            setFocus("none");
        }
        setLinktype("embed");
    };

    const chooseLinkToCheck = (websiteOrEmbed: "website" | "embed") => {
        setLinktype(websiteOrEmbed);
        checkLinkAndCreateResource();
        modifyResourceCounter("flowos/iframe/resx/Iframe", "add");
    };

    const checkLinkAndCreateResource = async () => {
        const iframeLink =
            linktype === "website"
                ? link?.toUpperCase() === link
                    ? link?.toLowerCase()
                    : link
                : embed?.toUpperCase() === embed
                ? embed?.toLowerCase()
                : embed;

        if (iframeLink) {
            const processedLink = processLink(iframeLink);

            if (processedLink.endsWith("error")) {
                setShowError(true);
                setError("* Please add a valid https link!");
                return;
            }

            const resultError = await checkIfIframeable(processedLink);

            if (resultError === null) {
                const linkTitle = await getWebsiteTitle(processedLink);

                createLinkResource(processedLink, linkTitle);
            } else {
                setShowError(true);
                setError(resultError);
            }
        }
    };

    const createLinkResource = async (url: string, urlTitle: string) => {
        error && setShowError(true);
        if (error) {
            return;
        }

        setLoading(true);

        const name = urlTitle || "Website Title";
        try {
            const res = await svc.resx.createResource("flowos/iframe/resx/Iframe", {
                name: name,
                url: url,
            });
            if (res) {
                handleClose();
                // editingResource(res.id);
                setLink(undefined);
                setEmbed(undefined);
                setError(null);
                setShowError(false);
                setTimeout(() => {
                    clientEvents.emit("click-resource-row", {
                        resourceId: res.id,
                    });
                }, 100);
            }
            setLoading(false);
        } catch (error) {
            toast("Error", {
                type: "error",
            });
            setLoading(false);
        }
    };

    const onKeyDown = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>) => {
        if (e.type === "keydown" && e.key === "Enter" && !e.shiftKey && (link || embed)) {
            e.stopPropagation();
            checkLinkAndCreateResource();
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.inputGroup}>
                <InputLabel
                    className={cls("flex flex-items-center", formClasses.inputLabel, classes.inputLabel)}
                    htmlFor="iframe-link-input"
                >
                    <Typography variant="sm">Website link</Typography>
                    <HyperlinkExternal
                        className={cls("flex", classes.articleLink)}
                        href={getHelpCenterArticleLink("iframe")}
                        target="_blank"
                        key="iframe-create-form-article-url"
                        rel="noopener noreferrer"
                    >
                        <HelpOutlineIcon key="iframe-create-form-article-icon" />
                    </HyperlinkExternal>
                </InputLabel>

                <Input
                    id="iframe-link-input"
                    placeholder="Paste link here"
                    onChange={onChangeLink}
                    onKeyDown={onKeyDown}
                    onFocus={onChangeLink}
                    value={link}
                    disableUnderline
                    className={cls(formClasses.inputRoot, classes.inputRoot)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                data-id="submit-link"
                                className={formClasses.inputIcon}
                                aria-label="iframe link"
                                onClick={() => chooseLinkToCheck("website")}
                                disabled={!(focus === "website")}
                                style={focus === "website" ? {display: "initial"} : {display: "none"}}
                            >
                                <TrendingFlatRoundedIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </div>

            <div className={classes.inputGroup}>
                <InputLabel
                    className={cls("flex flex-items-center", formClasses.inputLabel, classes.inputLabel)}
                    htmlFor="iframe-embed-input"
                >
                    <Typography variant="sm">Embed code</Typography>
                    <a
                        className={cls("flex", classes.articleLink)}
                        onClick={() => openExternalLink(getHelpCenterArticleLink("iframe"))}
                        key="iframe-create-form-article-url"
                    >
                        <HelpOutlineIcon key="iframe-create-form-article-icon" />
                    </a>
                </InputLabel>

                <Input
                    id="iframe-embed-input"
                    placeholder='Example: <iframe width="560" height="315" src="https:// www.example.com" title="Interesting website"></iframe>'
                    onChange={onChangeEmbed}
                    onKeyDown={onKeyDown}
                    onFocus={onChangeEmbed}
                    value={embed}
                    disableUnderline
                    className={cls(formClasses.inputRoot, classes.inputRoot, "iframe")}
                    rows={5}
                    inputComponent="textarea"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                className={formClasses.inputIcon}
                                aria-label="iframe embed"
                                onClick={() => chooseLinkToCheck("embed")}
                                disabled={!(focus === "embed")}
                                style={focus === "embed" ? {display: "initial"} : {display: "none"}}
                            >
                                <TrendingFlatRoundedIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </div>

            {error !== null && showError && (
                <Typography component="div" variant="sm" className={classes.errorText}>
                    {error}
                </Typography>
            )}

            {loading ? <Loading zIndex={999}></Loading> : null}
        </div>
    );
};
export default IframeCreateForm;
