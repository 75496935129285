import {Class} from "type-fest";
import {DesignerAction} from "./action";
import {actionsRegistry} from "./registry";

type OnCommitFn = (action: DesignerAction) => string;

type ActionsRegistry = typeof actionsRegistry;

type Actions = {
    [key: string]: Class<any> | Actions;
};

type ActionsProxy<T extends Actions> = {
    [k in keyof T]: T[k] extends Class<any>
        ? (...args: ConstructorParameters<T[k]>) => string
        : T[k] extends Actions
        ? ActionsProxy<T[k]>
        : never;
};

type ActionsRegistryProxy = ActionsProxy<ActionsRegistry>;

class Handler implements ProxyHandler<Function> {
    constructor(private onCommit: OnCommitFn, private action: any = actionsRegistry) {}

    get(target: Function, name: string): any {
        return new Proxy(function () {}, new Handler(this.onCommit, this.action[name]));
    }

    apply(_: any, __: any, args: any[]) {
        return this.onCommit(new this.action(...args));
    }
}

export const makeProxy = (onCommit: OnCommitFn) => {
    return new Proxy(() => {}, new Handler(onCommit)) as unknown as ActionsRegistryProxy;
};
