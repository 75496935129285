import {useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useUser, UserStructure} from "@workhorse/components/user";
import LogRocket from "logrocket";
import env from "@generated/environment";
import {RECORDING_USER_EMAIL} from "@common/recording/constants";
import {is24HTimeFormat} from "@workhorse/api/booking";
import {HostType, UserInviteStatus, UserToWorkspacePermission, UserToOrganizationPermission} from "@generated/data";
import {useHostType} from "../HostTypeProvider";
import setupLogRocketReact from "logrocket-react";

const enableLogRocket = env.enableLogrocket;
const logrocketProject = env.logrocketProject;

export type UserInfo = Pick<
    UserStructure,
    | "id"
    | "role"
    | "jobRole"
    | "company"
    | "createdAt"
    | "email"
    | "emailNotificationsEnabled"
    | "emailNotificationsMinutes"
    | "pushNotificationsEnabled"
    | "pushNotificationsMinutes"
    | "externalReferral"
    | "customSubDomainConfig"
    | "firstLogin"
    | "firstName"
    | "lastLoginDate"
    | "lastVersionSeen"
    | "halfDayClock"
    | "industry"
    | "isAuthenticated"
    | "timezone"
    | "profilePicture"
    | "lastName"
    | "organizationPermissions"
    | "workspacePermissions"
    | "seen"
    | "forms"
    | "language"
    | "defaultLobbyPicture"
    | "firstDayOfWeek"
    | "department"
    | "country"
    | "marketingEmailsEnabled"
    | "tags"
> & {
    isGuest: boolean;
    isRecorder: boolean;
    externalCalendarsSettings: {
        calendarId: string;
        checked: boolean;
    }[];
    defaultBookingCalendars: {
        calendarId: string;
        checked: boolean;
    }[];
    isPublicMemoryGuestMockd: boolean;
    activeWorkspace: UserStructure["workspacePermissions"][0]["workspace"];
    activeWorkspacePermissions: UserStructure["workspacePermissions"][0];
    activeOrganizationPermission: UserStructure["organizationPermissions"][0];
    invitedOrganizationPermissions: UserStructure["organizationPermissions"];
};

/**
 * @description User Info Defaults
 *              Usable for unknown users that visit public routes.
 */
const userInfoDefaults: Partial<UserInfo> = {
    halfDayClock: !is24HTimeFormat(),
    tags: [],
};

function addDefaults<T extends Record<string, $FixmeAny>>(obj: T, def: Partial<T>): T {
    return Object.entries(obj)
        .filter(([k, v]) => !(k in def && typeof v === "undefined"))
        .reduce(
            (acc, [k, v]) => {
                // @ts-ignore expected
                acc[k] = v;
                return acc;
            },
            {...obj, ...def}
        );
}

const useUserInfoStore = (): UserInfo => {
    const user = useUser();
    const {
        id,
        role,
        jobRole,
        company,
        createdAt,
        email,
        emailNotificationsEnabled,
        emailNotificationsMinutes,
        pushNotificationsEnabled,
        pushNotificationsMinutes,
        externalReferral,
        customSubDomainConfig,
        firstLogin,
        firstName,
        lastLoginDate,
        lastVersionSeen,
        halfDayClock,
        industry,
        isAuthenticated,
        timezone,
        profilePicture,
        lastName,
        organizationPermissions = user.organizationPermissions ?? [],
        workspacePermissions = user.workspacePermissions ?? [],
        seen,
        forms,
        language,
        defaultLobbyPicture,
        externalCalendarsSettings,
        defaultBookingCalendars,
        firstDayOfWeek,
        department,
        country,
        marketingEmailsEnabled,
        tags = user.tags ?? [],
    } = user;

    const hostType = useHostType();
    const isGuest = role.toUpperCase() === "GUEST";
    const isRecorder = email === RECORDING_USER_EMAIL;
    const isPublicMemoryGuestMockd = id === "mocked";
    const isPublicBookMePage = hostType === HostType.Book || hostType === HostType.BookedEvent || hostType === HostType.Bookings;
    const isPublicPaymentPage = hostType === HostType.Payments;

    const identifiedInLogRocket = useRef(false);

    useEffect(() => {
        if (id && !identifiedInLogRocket.current && enableLogRocket && !isRecorder) {
            identifiedInLogRocket.current = true;
            console.log("initializing logRocket for released version", env.sessionsReleaseVer);
            LogRocket.init(`cmjqw2/${logrocketProject}`, {
                shouldParseXHRBlob: true,
                release: env.sessionsReleaseVer,
            });
            setupLogRocketReact(LogRocket);

            LogRocket.identify(id, {
                name: `${firstName} ${lastName}`,
                email: email,
                isGuest,
                timezone,
            });
        }
    }, [user]);

    return useMemo(() => {
        let activeWorkspacePermission = workspacePermissions.find((wp) => wp.activeWorkspace);

        if (!activeWorkspacePermission) {
            // FOSS-16623 - hotfix for /book public route, when user is not authenticated
            if (!isAuthenticated && (isPublicBookMePage || isPublicPaymentPage)) {
                activeWorkspacePermission = {id: "mocked", workspace: {id: "local"}} as UserToWorkspacePermission;
            } else {
                throw new Error("No active workspace found");
            }
        }

        let activeOrganizationPermission = organizationPermissions.find((op) => op.activeOrganization);

        if (!activeOrganizationPermission) {
            if (!isAuthenticated && isPublicBookMePage) {
                activeOrganizationPermission = {id: "mocked", organization: {id: "local"}} as UserToOrganizationPermission;
            } else {
                throw new Error("No active organization found");
            }
        }

        if (activeOrganizationPermission.suspended && window.location.pathname !== "/choose-organization") {
            window.location.href = "/choose-organization";

            throw new Error("User is suspended");
        }

        const invitedOrganizationPermissions = organizationPermissions.filter((op) => op.inviteStatus !== UserInviteStatus.Active);

        const userWithDefaults = addDefaults(
            {
                id,
                role,
                jobRole,
                company,
                createdAt,
                email,
                emailNotificationsEnabled,
                emailNotificationsMinutes,
                pushNotificationsEnabled,
                pushNotificationsMinutes,
                externalReferral,
                customSubDomainConfig,
                firstLogin,
                firstName,
                lastLoginDate,
                lastVersionSeen,
                halfDayClock,
                industry,
                isAuthenticated,
                timezone,
                profilePicture,
                lastName,
                organizationPermissions: organizationPermissions.filter(
                    (op) => op.inviteStatus === UserInviteStatus.Active || op.inviteStatus === UserInviteStatus.LinkAccessed
                ),
                workspacePermissions,
                isGuest,
                seen,
                forms,
                isRecorder,
                language,
                defaultLobbyPicture,
                externalCalendarsSettings,
                defaultBookingCalendars,
                isPublicMemoryGuestMockd,
                firstDayOfWeek,
                department,
                country,
                activeWorkspace: activeWorkspacePermission?.workspace,
                activeWorkspacePermissions: activeWorkspacePermission,
                activeOrganizationPermission: activeOrganizationPermission,
                marketingEmailsEnabled,
                invitedOrganizationPermissions,
                tags,
            },
            userInfoDefaults
        );

        return userWithDefaults;
    }, [
        id,
        role,
        jobRole,
        company,
        createdAt,
        email,
        emailNotificationsEnabled,
        emailNotificationsMinutes,
        pushNotificationsEnabled,
        pushNotificationsMinutes,
        externalReferral,
        customSubDomainConfig,
        firstLogin,
        firstName,
        lastLoginDate,
        lastVersionSeen,
        halfDayClock,
        industry,
        isAuthenticated,
        timezone,
        profilePicture,
        lastName,
        organizationPermissions,
        workspacePermissions,
        isGuest,
        seen,
        isRecorder,
        forms,
        language,
        defaultLobbyPicture,
        externalCalendarsSettings,
        defaultBookingCalendars,
        isPublicMemoryGuestMockd,
        firstDayOfWeek,
        department,
        country,
        isPublicBookMePage,
        marketingEmailsEnabled,
        tags,
    ]);
};

export const [UserInfoProvider, useUserInfo] = createContextProvider(
    {
        name: "UserInfo",
    },
    useUserInfoStore
);
