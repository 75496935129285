import {getHelpCenterArticleLink} from "../help/article";
import {OnboardingFeature} from "../seen";

export const wideVideoList = ["rooms"];

export const getDataIdString = (feature: OnboardingFeature) => {
    const defaultString = "onboarding-card-";

    switch (feature) {
        case "memoryDashboard": {
            return defaultString + "memory-";
            break;
        }
        case "base": {
            return defaultString + "calendar-";
            break;
        }
        case "files": {
            return defaultString + "files-";
            break;
        }
        case "tools": {
            return defaultString + "tools-";
            break;
        }
        case "agendaDashboard": {
            return defaultString + "agendas-";
            break;
        }
        case "bookingDashboard": {
            return defaultString + "bookme-";
        }
        case "events": {
            return defaultString + "events-";
            break;
        }
        case "rooms": {
            return defaultString + "rooms-";
            break;
        }
        default: {
            break;
        }
    }
};

export const getHelpCenterLink = (feature: OnboardingFeature) => {
    switch (feature) {
        case "memoryDashboard": {
            return getHelpCenterArticleLink("memory");
        }
        case "base": {
            return getHelpCenterArticleLink("calendar");
        }
        case "files": {
            return getHelpCenterArticleLink("tools-files-integrations/files");
        }
        case "tools": {
            return getHelpCenterArticleLink("tools-files-integrations/tools");
        }
        case "agendaDashboard": {
            return getHelpCenterArticleLink("agenda-templates");
        }
        case "bookingDashboard": {
            return getHelpCenterArticleLink("bookings");
        }
        case "events": {
            return getHelpCenterArticleLink("events-webinars");
        }
        case "rooms": {
            return getHelpCenterArticleLink("knowledge-base/rooms");
        }
        default: {
            return getHelpCenterArticleLink("/");
        }
    }
};
