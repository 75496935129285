import {workletId} from "./worklet-id";
import {RnnoiseProcessorOptions, RnnoiseWorkletOptions} from "./worklet-options";

export class RnnoiseWorkletNode extends AudioWorkletNode {
    constructor(context: AudioContext, {maxChannels, wasmBinary, model}: Readonly<RnnoiseProcessorOptions>) {
        const workletOptions: RnnoiseWorkletOptions = {
            processorOptions: {
                maxChannels,
                wasmBinary,
                model,
            },
        };
        super(context, workletId, workletOptions);
    }

    destroy() {
        this.port.postMessage("destroy");
    }
}
