import {OneSessionFlagsNoDiffNoIdFragment} from "@generated/data";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {RecordingConferenceViewSelect} from "@workhorse/api/session-settings/sections/RecordingSection/components/RecordingConferenceViewSelect";
import {RecordingPresentationViewSelect} from "@workhorse/api/session-settings/sections/RecordingSection/components/RecordingPresentationViewSelect";
import SessionFullSizeVideosInRecording from "@workhorse/api/session-settings/sections/RecordingSection/components/SessionFullSizeVideosInRecording";
import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import classes from "./styles/GeneralSettings.module.scss";

type RecordingSettingsProps = {
    isMobile: boolean;
    autoRecording: boolean;
    fullSizeVideosInRecording: boolean;
    recordingConferenceView?: string | null;
    recordingPresentationView?: string | null;
    handleOnChange: <TKey extends keyof OneSessionFlagsNoDiffNoIdFragment>(
        key: string,
        value: OneSessionFlagsNoDiffNoIdFragment[TKey]
    ) => void;
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
    disabled?: boolean;
    title?: JSX.Element;
    parentClassName?: string;
    isEventSection?: boolean;
    isBookingSection?: boolean;
    isRoomSection?: boolean;
};

const RecordingSettings = (props: RecordingSettingsProps) => {
    const {
        handleOnChange,
        isMobile,
        sessionTemplateNotificationDismissed,
        setSessionTemplateNotificationDismissed,
        title,
        parentClassName,
        isRoomSection,
        isEventSection,
        isBookingSection,
    } = props;

    return (
        <div className={parentClassName ?? cls("fullh fullw flex flex-col", isMobile ? "p-16" : "p-24", classes.root)}>
            {title ?? (
                <div className="mb-12">
                    <Typography variant="lg" fontWeight="bolder" color="secondary">
                        Recording settings
                    </Typography>
                </div>
            )}

            <SessionFullSizeVideosInRecording
                enabled={props.fullSizeVideosInRecording}
                onChange={handleOnChange.bind(null, "fullSizeVideosInRecording")}
                showIcon={true}
            />

            {isEventSection ? <div className={classes.divider} /> : null}

            <Typography variant="lg" fontWeight="bolder" color="secondary" className={isEventSection ? "mt-16" : undefined}>
                Preferred layout
            </Typography>

            <RecordingConferenceViewSelect
                value={props.recordingConferenceView}
                onChange={handleOnChange.bind(null, "recordingConferenceView")}
            />

            {isEventSection ? <div className={classes.divider} /> : null}

            <RecordingPresentationViewSelect
                value={props.recordingPresentationView}
                onChange={handleOnChange.bind(null, "recordingPresentationView")}
            />

            <div className={classes.divider} />

            <SessionTemplateSettingsNotification
                type={isRoomSection ? "room" : isEventSection ? "event" : isBookingSection ? "booking" : "planned"}
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={cls((isBookingSection || isEventSection) && classes.noNegativeMargin)}
            />
        </div>
    );
};

export default RecordingSettings;
