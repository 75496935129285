import env from "@generated/environment";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import Popover from "@ui/core/components/Popover";
import browserInfo from "@workhorse/api/BrowserInfo";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {useEffect, useRef} from "react";
import classes from "./style/BlockedPermissionsModal.module.scss";
import {useTranslation} from "react-i18next";
import {useDesktopInfo} from "@workhorse/providers/DesktopInfoProvider";
import {isDesktopApp, Platform} from "@sessions/desktop-api";

function getAndroidImageSrc() {
    if (browserInfo.info.browser.name === "Opera") {
        return "Android_Opera";
    }

    if (browserInfo.isFirefox()) {
        return "Android_Firefox";
    }

    if (browserInfo.isChromium()) {
        return "Android_Chrome";
    }

    return "Android_Default browser";
}

function getIosImageSrc() {
    if (browserInfo.isSafari()) {
        return "iOS_Safari";
    }
    return "iOS_Chrome";
}

function getMobileImageSrc() {
    if (browserInfo.isIOS()) {
        return getIosImageSrc();
    }

    return getAndroidImageSrc();
}

function getDesktopImageSrc() {
    if (browserInfo.info.browser.name === "Edge") {
        return "Desktop_Edge_Desktop";
    }

    if (browserInfo.info.browser.name === "Opera") {
        return "Desktop_Opera_Desktop";
    }

    if (browserInfo.isFirefox()) {
        return "Desktop_Firefox_Desktop";
    }

    if (browserInfo.isSafari()) {
        return "Desktop_Safari_Desktop";
    }

    return "Desktop_Chrome_Desktop";
}

function getDesktopAppImageSrc(platform?: Platform) {
    if (platform === "darwin") {
        return "Desktop_App_Mac";
    }

    if (platform === "win32") {
        return "Desktop_Windows";
    }

    return "Desktop_Windows";
}

export function getDesktopAppPermissionBlockedGif(platform?: Platform) {
    return `${env.staticServiceUrl}/assets/modals/${getDesktopAppImageSrc(platform)}`;
}

export function getPermissionBlockedGif() {
    return `${env.staticServiceUrl}/assets/modals/${browserInfo.isMobile() ? getMobileImageSrc() : getDesktopImageSrc()}`;
}

export function getGenericPermissionBlockedGif(platform?: Platform) {
    if (!isDesktopApp()) {
        return getPermissionBlockedGif();
    }
    return getDesktopAppPermissionBlockedGif(platform);
}

type BlockedPermissionsContentProps = {
    micBlocked?: boolean;
    cameraBlocked?: boolean;
};

export function BlockedPermissionsContent(props: BlockedPermissionsContentProps) {
    const {t} = useTranslation();

    let content = t("devices.cannot_access_cam_and_mic") ?? "We cannot access your microphone and camera.";

    if (props.micBlocked && props.cameraBlocked !== true) {
        content = t("devices.cannot_access_mic") ?? "We cannot access your microphone. ";
    }

    if (props.cameraBlocked && props.micBlocked !== true) {
        content = t("devices.cannot_access_cam") ?? "We cannot access your camera.";
    }

    const {platform} = useDesktopInfo();

    return (
        <div>
            <div className={classes.videoBox}>
                <video
                    loop={true}
                    muted={true}
                    autoPlay={true}
                    controls={false}
                    playsInline={true}
                    disablePictureInPicture={true}
                    className={classes.infoImage}
                >
                    <source src={getGenericPermissionBlockedGif(platform) + ".webm"} type="video/webm" />
                    <source src={getGenericPermissionBlockedGif(platform) + ".mp4"} type="video/mp4" />
                </video>
            </div>
            <div>
                <Typography variant="lg" className={classes.infoText}>
                    {content} {t("devices.follow_the_instructions") ?? "Follow the instructions on screen to check permissions."}
                </Typography>
                <Link
                    href={getHelpCenterArticleLink("account-settings-tech-guide/audio-video-setup")}
                    target="_blank"
                    className={classes.linkText}
                >
                    <Typography variant="lg">{t("g.read_more") ?? "Read more"}</Typography>
                </Link>
            </div>
        </div>
    );
}

type BlockedPermissionsDialogProps = {
    title?: string;
    open: boolean;
    micBlocked?: boolean;
    cameraBlocked?: boolean;
    onClose: () => void;
};

export function BlockedPermissionsDialog(props: BlockedPermissionsDialogProps) {
    const {t} = useTranslation();
    const imperativeRef = useRef<DialogImperativeRef>();

    useEffect(() => {
        imperativeRef.current?.toggle?.(props.open);
    }, [props.open]);

    let title = t("devices.cam_and_mic_blocked") ?? "Camera and microphone are blocked";

    if (props.micBlocked && props.cameraBlocked !== true) {
        title = t("devices.mic_blocked") ?? "Your microphone seems to be blocked";
    }

    if (props.cameraBlocked && props.micBlocked !== true) {
        title = t("devices.cam_blocked") ?? "Your camera seems to be blocked";
    }

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            classes={{
                paper: classes.paper,
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop-with-backdrop-filter",
                },
            }}
            imperativeRef={imperativeRef}
            open={props.open}
            disableBackdropClick
        >
            <div className={cls("flex flex-justify-between", classes.dialogHeader)}>
                <Typography className={classes.dialogHeaderTitle}>
                    {title} {props.title}
                </Typography>

                <IconButton onClick={props.onClose} className={cls("p-0 mb-auto", classes.closeButton)}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <div className="mb-30">
                <BlockedPermissionsContent micBlocked={props.micBlocked} cameraBlocked={props.cameraBlocked} />
            </div>

            <div className="flex flex-col">
                <Button variant="primary" className={cls("mb-15", classes.footerButton)} onClick={props.onClose}>
                    {t("devices.ive_allowed_access") ?? "I've allowed access"}
                </Button>
                <Button variant="plain" className={classes.footerButton} onClick={props.onClose}>
                    {t("devices.continue_without_permissions") ?? "Continue without permissions"}
                </Button>
            </div>
        </Dialog>
    );
}

type BlockedPermissionsPopoverProps = {
    anchorEl?: HTMLElement | null;
    micBlocked?: boolean;
    cameraBlocked?: boolean;
    onClose: () => void;
};

export function BlockedPermissionsPopover(props: BlockedPermissionsPopoverProps) {
    const {t} = useTranslation();

    let title = t("devices.cam_and_mic_blocked") ?? "Camera and microphone are blocked";

    if (props.micBlocked && props.cameraBlocked !== true) {
        title = t("devices.mic_blocked") ?? "Your microphone seems to be blocked";
    }

    if (props.cameraBlocked && props.micBlocked !== true) {
        title = t("devices.cam_blocked") ?? "Your camera seems to be blocked";
    }

    return (
        <Popover
            anchorEl={props.anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            transformOrigin={{vertical: "bottom", horizontal: "center"}}
            open={!!props.anchorEl}
            className="flex flex-col flex-center-all"
            classes={{
                paper: cls(classes.paper, classes.popoverPaper),
            }}
            onBackdropClick={props.onClose}
        >
            <div>
                <div className={cls("flex flex-justify-between", classes.dialogHeader)}>
                    <Typography className={classes.dialogHeaderTitle}>{title}</Typography>
                </div>
                <BlockedPermissionsContent micBlocked={props.micBlocked} cameraBlocked={props.cameraBlocked} />
            </div>
        </Popover>
    );
}
