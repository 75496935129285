import {useCallback, useEffect, useRef, useState} from "react";
import {debounce} from "throttle-debounce";
import {getComputedIndex} from "./useAudienceScroll";

interface Options {
    spacing: number;
    isGrid: boolean;
    itemWidth: number;
    itemHeight: number;
    wrapperWidth: number;
}

export function useAudienceScrollMobile(options: Options) {
    const [scrollNode, setScrollNode] = useState<HTMLElement | null>(null);

    const optionsRef = useRef(options);
    optionsRef.current = options;

    const setScroll = useCallback((node: HTMLElement | null) => {
        setScrollNode(node);
    }, []);

    useEffect(() => {
        if (!scrollNode) {
            return;
        }

        const start = {x: 0, y: 0};
        const delta = {x: 0, y: 0};

        const setStart = (e: TouchEvent) => {
            start.x = e.touches[0].pageX;
            start.y = e.touches[0].pageY;
        };

        const setDelta = (e: TouchEvent) => {
            delta.x = start.x - e.touches[0].pageX;
            delta.y = start.y - e.touches[0].pageY;
        };

        const setWheelDelta = (e: WheelEvent) => {
            delta.x = e.deltaX;
            delta.y = e.deltaY;
        };

        const alignItemsHorizontally = () => {
            const {spacing, itemWidth} = optionsRef.current;

            if (delta.x === 0) {
                return;
            }

            const currentOffset = scrollNode.scrollLeft;
            const dir: "left" | "right" = delta.x < 0 ? "left" : "right";

            const itemSize = itemWidth + spacing * 2;
            const index = getComputedIndex(currentOffset, spacing, itemWidth, dir);

            delta.x = 0;
            scrollNode.scrollTo({
                left: index * itemSize,
                behavior: "smooth",
            });
        };

        const alignItemsVertically = () => {
            const {spacing, itemHeight} = optionsRef.current;

            if (delta.y === 0) {
                return;
            }

            const currentOffset = scrollNode.scrollTop;

            const itemSize = itemHeight + spacing * 2;
            const ratio = currentOffset / itemSize;
            const ratioFixed = delta.y < 0 ? Math.floor(ratio) : Math.ceil(ratio);

            delta.y = 0;
            scrollNode.scrollTo({
                top: ratioFixed * itemSize + 2,
                behavior: "smooth",
            });
        };

        const alignItems = debounce(700, () => {
            const {isGrid} = optionsRef.current;
            if (isGrid) {
                alignItemsVertically();
            } else {
                alignItemsHorizontally();
            }
        });

        scrollNode.addEventListener("scroll", alignItems);
        scrollNode.addEventListener("touchstart", setStart);
        scrollNode.addEventListener("touchmove", setDelta);
        scrollNode.addEventListener("wheel", setWheelDelta);

        return () => {
            scrollNode.removeEventListener("scroll", alignItems);
            scrollNode.removeEventListener("touchstart", setStart);
            scrollNode.removeEventListener("touchmove", setDelta);
            scrollNode.removeEventListener("wheel", setWheelDelta);
        };
    }, [scrollNode]);

    return {
        setScroll,
    };
}
