import {makeStyles} from "@material-ui/core";

export const useInfoTooltipStyles = makeStyles((theme) => ({
    muiPopper: {
        zIndex: 1300,
        pointerEvents: "initial",
        borderRadius: theme.spacing(0.75),
    },
    withShadow: {
        boxShadow: "6px 2px 20px 4px rgb(0,0,0,0.1)!important",
    },
    blueHighlighted: {
        "&.fosButton": {
            boxShadow: `0px 0px 0px 3px ${theme.main.palette.blue[400]}, 0px 0px 0px 11px ${theme.main.palette.elevation.onSurface[0]}`,
        },
        "&:hover": {
            background: theme.main.palette.elevation.surface[0],
        },
    },
    infoTooltip: {
        borderRadius: theme.spacing(0.75),
        backgroundColor: `${theme.main.palette.elevation.above[5]}!important`,
        boxShadow: "inset 0px 1px 2px rgba(255, 255, 255, 0.3)",
        margin: 0,
        padding: 0,
        textAlign: "center",
    },

    tooltipInner: {
        padding: `15px 15px`,
        color: theme.main.palette.text.tooltipPrimary,
        "& .exclamation": {
            paddingRight: theme.spacing(2),
        },
    },
    tooltipTitle: {
        color: theme.main.palette.text.tooltipPrimary,
        fontWeight: "bold",
        "&.fosTypography": {
            fontSize: theme.typography.pxToRem(13),
        },
    },
    tooltipTextWrapper: {
        paddingRight: 30,
        alignItems: "center",
    },
    closeTooltip: {
        position: "absolute",
        top: 1,
        right: 0,
        color: theme.main.palette.text.tooltipPrimary,
        cursor: "pointer",
    },
    closeTooltipIcon: {
        width: theme.typography.pxToRem(20),
        height: theme.typography.pxToRem(20),
    },
    tooltipArrow: {
        color: `${theme.main.palette.elevation.above[5]}!important`,
        fontSize: 20,
    },
    backdrop: {
        zIndex: 1302,
        "&.MuiBackdrop-invisible": {
            backdropFilter: "unset",
        },
    },
    child: {
        zIndex: 1303,
    },
    messageBody: {
        display: "block",
        color: theme.main.palette.text.tooltipPrimary,
        fontWeight: 700,
        "&.fosTypography": {
            marginTop: 15,
            fontSize: theme.typography.pxToRem(13),
        },
    },

    leftInfoTooltip: {
        maxWidth: 385,
    },
    unsetMaxWidth: {
        maxWidth: "unset",
    },
    tooltipHeader: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        // marginBottom: theme.spacing(0.75),
        "& .MuiSvgIcon-root": {
            width: 12,
            height: 12,
            margin: theme.spacing(0, 1, 0, 0.5),
            color: theme.main.palette.text.tooltipPrimary,
        },
    },
    tooltipFooter: {
        marginBottom: theme.spacing(0.5),
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        "& .fosLink": {
            padding: 0,
        },
    },
    learnMore: {
        textTransform: "uppercase",
        color: theme.main.palette.blue[500],
        letterSpacing: "0.4px",
        fontWeight: 400,
        "&.fosTypography": {
            fontSize: theme.typography.pxToRem(10),
        },
    },
    articleUrl: {
        color: theme.main.palette.blue[500],
        fontWeight: 600,
        paddingBottom: theme.spacing(0.125),
        borderBottom: `1px solid ${theme.main.palette.blue[500]}`,
        "&.fosTypography": {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    gotItBtn: {
        "&.fosButton": {
            textTransform: "uppercase",
            borderRadius: 10,
            boxShadow: "none",
            background: theme.main.palette.blue[500],
            padding: theme.spacing(0.75, 1.5),
            fontWeight: 600,
            color: theme.main.palette.basic.background,
            fontSize: theme.typography.pxToRem(11),
            "&:hover": {
                background: theme.main.palette.blue[500],
                boxShadow: "none",
            },
        },
    },
    communityBtn: {
        borderRadius: 4,
        fontWeight: 500,
        "&.fosLink": {
            color: theme.main.palette.basic.background,
            padding: theme.spacing(0.75, 1),
            "&:hover": {
                color: theme.main.palette.basic.background,
            },
        },
    },
}));

export const useVideoTutorialTooltipClasses = makeStyles((theme) => ({
    tooltipInner: {
        "& .play-btn-container": {
            height: 200,
            top: 56,
        },

        // Video element
        "& .video-js": {
            borderRadius: 12,
            height: 200,

            "& > video": {
                borderRadius: 10,
                objectFit: "contain",
                background: theme.main.palette.elevation.background[0],
            },
        },

        // Window button
        "& .vjs-picture-in-picture-control.vjs-control.vjs-button": {
            display: "none",
        },

        // Play button thumbnail
        "& .vjs-big-play-button": {
            border: "none !important",
            backgroundColor: "transparent !important",
            "& .vjs-icon-placeholder": {
                "&:before": {
                    content: "'' !important",
                },
            },
        },

        // Captions button & menu
        "& .vjs-subs-caps-button": {
            display: "none",
        },
        "& .vjs-texttrack-settings": {
            display: "none",
        },

        "& .vjs-fullscreen-control": {
            order: 2,
        },

        // Progress bar
        "& .vjs-progress-control.vjs-control": {
            order: 1,
            margin: theme.spacing(0, 1),
            "& .vjs-play-progress:before": {
                fontSize: theme.typography.pxToRem(30),
            },

            "& .vjs-progress-holder.vjs-slider.vjs-slider-horizontal": {
                color: theme.main.palette.primary[500],
                height: 6,
                borderRadius: 20,
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                "& .vjs-load-progress": {
                    borderRadius: 20,
                    "& div": {
                        borderRadius: 20,
                    },
                },
                "& .vjs-play-progress.vjs-slider-bar": {
                    borderRadius: 20,
                    "&:before": {
                        fontSize: theme.typography.pxToRem(16),
                        top: -5,
                    },
                },
            },
        },

        // Control Bar
        "& .vjs-control-bar": {
            "& > *": {
                zIndex: 2,
            },
            background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), 70%, rgba(0, 0, 0, 0.6))",
            padding: theme.spacing(0, 1, 1, 1),
            borderRadius: "0 0 12px 12px",
            width: "auto",

            // All buttons (play / pause / volume)
            "& .vjs-control": {
                width: theme.typography.pxToRem(30),
                height: theme.typography.pxToRem(30),
                marginRight: theme.spacing(1),
            },
            "& .vjs-button > .vjs-icon-placeholder:before": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: theme.typography.pxToRem(26),
            },

            // Volume button + slider
            "& .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal": {
                "& .vjs-volume-bar.vjs-slider-bar.vjs-slider.vjs-slider-horizontal": {
                    color: theme.main.palette.primary[500],
                    borderRadius: 20,
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    height: theme.typography.pxToRem(5),
                    display: "flex",
                    alignItems: "center",
                    margin: `${theme.typography.pxToRem(theme.spacing(1.5))} 0 ${theme.typography.pxToRem(
                        theme.spacing(1.5)
                    )} ${theme.typography.pxToRem(theme.spacing(1.25))}`,
                    "& .vjs-play-progress:before": {
                        fontSize: theme.typography.pxToRem(30),
                    },
                    "& .vjs-volume-level": {
                        borderRadius: 20,
                        fontSize: theme.typography.pxToRem(15),
                    },
                },
            },

            // Timer
            "& .vjs-remaining-time.vjs-time-control.vjs-control": {
                fontSize: theme.typography.pxToRem(18),
                lineHeight: 1.78,
                letterSpacing: 0.36,
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                marginRight: theme.spacing(3),
                marginLeft: "-16px",
            },
        },
    },
    tooltip: {
        maxWidth: 380,
        background: "rgba(255, 255, 255, 0.8)",
        boxShadow: "inset 0px 1px 2px rgb(255 255 255 / 30%)",
    },
    arrow: {
        background: "rgba(255, 255, 255, 0.8)",
    },
    tooltipTextWrapper: {
        padding: 0,
    },
    closeHandler: {
        color: theme.main.palette.text.tooltipPrimary,
        height: 13,
        width: 13,
        top: 12,
        right: 12,
    },
    tooltipHeader: {
        // marginBottom: theme.spacing(2.5),
    },
    tooltipTitle: {
        fontWeight: 500,
        "&.fosTypography": {
            fontSize: theme.typography.pxToRem(18),
        },
    },
    messageBody: {
        color: theme.main.palette.text.tooltipSecondary,
        fontWeight: 500,
        "&.fosTypography": {
            margin: theme.spacing(1.875, 0, 2.5, 0),
            fontSize: theme.typography.pxToRem(14),
        },
    },
}));
