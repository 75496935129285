import {SessionLifecycle} from "@generated/data";
import {useSessionInfo} from "@workhorse/api/conference2/providers/SessionInfoProvider";
import {getPlayerDomain} from "@workhorse/api/csd";
import CopyText from "./CopyText";
import ShareSessionLink from "./ShareSessionLink";
import classes from "./styles/PermissionForm.module.scss";
import {useMobile} from "@workhorse/providers/MobileProvider";

type PermissionFormProps = {
    isCreateDialog?: boolean;

    liveSessionId?: string;

    sessionId: string;
    isAssistant?: boolean;

    sessionLifecycle?: SessionLifecycle | null;
};

const PermissionsForm = (props: PermissionFormProps) => {
    const {sessionLifecycle, sessionId, liveSessionId, isAssistant, isCreateDialog} = props;

    const {isMobile} = useMobile();
    const {parentSessionId} = useSessionInfo();

    const isStarted = sessionLifecycle === SessionLifecycle.Started;
    const hasSwitch = !isStarted;

    // TODO: @laur CSD
    const url = `${window.location.protocol}//${getPlayerDomain()}/session/${parentSessionId ?? liveSessionId ?? sessionId}`;

    return (
        <div className={classes.root}>
            <div className={classes.checkboxContainer}>
                {!isCreateDialog ? (
                    isMobile ? (
                        <ShareSessionLink url={url} withMarginLeftAuto />
                    ) : (
                        <CopyText url={url} hasIcon={true} showUrl={!isMobile}></CopyText>
                    )
                ) : null}
            </div>
        </div>
    );
};

export default PermissionsForm;
