import {cls} from "@ui/cdk/util";
import classes from "./style/DesignerToolPlaceholderSkeleton.module.scss";

const numberOfSkeletons = Array.from(Array(5).keys());

const DesignerToolPlaceholderSkeleton = () => {
    return (
        <div className={classes.root}>
            <div className={cls("flex py-16 px-30", classes.whiteBg)}>
                <div className="flex">
                    <div className={cls(classes.skeletonItem, classes.w48, classes.h48, "mr-10")}></div>
                    <div>
                        <div className={cls(classes.skeletonItem, classes.w376, classes.h16, "mb-12")}></div>
                        <div className={cls(classes.skeletonItem, classes.w268, classes.h16)}></div>
                    </div>
                </div>
            </div>
            <div className="px-30 py-20">
                <div className="flex flex-justify-between mb-30">
                    <div className={cls(classes.skeletonItem, classes.w120, classes.h40)}></div>
                    <div className={cls(classes.skeletonItem, classes.w340, classes.h40)}></div>
                </div>
                <div className="flex flex-justify-between mb-30">
                    <div className={cls(classes.skeletonItem, classes.w92, classes.h24)}></div>
                    <div className={cls(classes.skeletonItem, classes.w92, classes.h24)}></div>
                </div>

                {numberOfSkeletons.map((s, i) => (
                    <div className={cls(classes.skeletonItem, "mb-26")} key={`${s}_${i}`}></div>
                ))}
            </div>
            <div className={cls("flex flex-justify-end px-30 py-15", classes.whiteBg)}>
                <div className={cls(classes.skeletonItem, classes.w80, classes.h40, "mr-10")}></div>
                <div className={cls(classes.skeletonItem, classes.w80, classes.h40)}></div>
            </div>
        </div>
    );
};

export default DesignerToolPlaceholderSkeleton;
