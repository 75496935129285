import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import {useDeferredValue} from "./utils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export function OnboardingFooter(props: WithTooltipChildren) {
    const {tooltip, next, completeFlow, completedFlows} = useOnboardingSession();

    const selectedTooltip = tooltip?.startsWith("footer-") ? tooltip : "-1";
    const skip = selectedTooltip === "-1" || completedFlows.includes("end-session");

    let title = <></>;

    if (tooltip === "footer-tools" || tooltip === "footer-end-session") {
        title = (
            <>
                <div className="flex flex-align-center">
                    <InfoOutlinedIcon className="mr-6" />
                    <Typography className="title" fontWeight="bolder">
                        Done exploring?
                    </Typography>
                </div>
                <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                    Whenever you are ready, you can end the session from here.
                </Typography>
            </>
        );
    }

    const handleClick = () => {
        next({
            saveTooltip: true,
        });
        completeFlow({
            flowName: "end-session",
        });
    };

    const deferSkip = useDeferredValue(tooltip, skip, true, 5000);

    return (
        <OnboardingTooltip
            tooltipId={selectedTooltip}
            title={
                <>
                    {title}

                    {tooltip === "footer-end-session" && (
                        <div className="mt-12 fullw flex flex-justify-end">
                            <Button variant="secondary" size="small" onClick={handleClick}>
                                Got it
                            </Button>
                        </div>
                    )}
                </>
            }
            placement="top"
            arrow={true}
            skip={deferSkip}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
