import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {OnboardingFeature} from "@workhorse/api/seen";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {ReactComponent as AnimatedTipsIcon} from "../../../assets/media/animated-tips-icon.svg";
import {useOnboardingFeature, useOnboardingFeaturesJourney} from "../hooks";
import {useFeaturesOnboardingConfiguration} from "../provider";
import classes from "./styles/OnboardingFeatureToggler.module.scss";

type OnboardingFeatureTogglerProps = {
    feature: OnboardingFeature;
    className?: string;
};

function OnboardingFeatureToggler(props: OnboardingFeatureTogglerProps) {
    const {feature, className} = props;

    const {isMobileOrTablet} = useMobile();
    const {configuration, setJourneyOpen, setFeaturesOpen} = useFeaturesOnboardingConfiguration();
    const journey = useOnboardingFeaturesJourney(configuration);
    const config = useOnboardingFeature(feature);

    const handleClick = () => {
        if (feature === "home") {
            setJourneyOpen((prev) => !prev);
        } else {
            setFeaturesOpen((prev) => ({...prev, [feature]: !prev[feature]}));
        }
    };

    if ((feature === "home" && journey === false) || (feature !== "home" && (!config || !config.component)) || isMobileOrTablet) {
        return null;
    }

    return (
        <Button variant="plain" className={cls(classes.root, className)} onClick={handleClick}>
            <AnimatedTipsIcon />
        </Button>
    );
}

export default OnboardingFeatureToggler;
