import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import ParticipantDisplayDetails from "../../../user/profile/ParticipantDisplayDetails";
import classes from "./styles/SpeakerPopupParticipantProfile.module.scss";

type SpeakerPopupParticipantInfo = {
    participantId: string;
    isLocalParticipant: boolean;
};

function SpeakerPopupParticipantProfile(props: SpeakerPopupParticipantInfo) {
    const {participantId} = props;

    const participant = useParticipant({participantId});

    const firstName = participant?.dataWithNullableEmail.firstName.trim();
    const lastName = participant?.dataWithNullableEmail.lastName.trim();
    const avatar = participant?.dataWithNullableEmail?.avatar;
    const isUser = !participant?.dataWithNullableEmail?.isGuest;

    return (
        <ParticipantDisplayDetails
            className={classes.root}
            classes={{displayName: classes.displayName, avatar: classes.avatar, status: classes.status}}
            avatar={avatar}
            firstName={firstName}
            lastName={lastName}
            participantIsAssistant={false}
            showProfilePicture={true}
            avatarSize="M"
            avatarVariant="rounded"
            disableTooltip={true}
            isUser={isUser}
            // showInfo={false}
        />
    );
}

export default SpeakerPopupParticipantProfile;
