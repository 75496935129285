import {safelyParseJSON} from "@common/utils";
import {useCallback, useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";

type StorageLocation = "localStorage" | "sessionStorage";

const useStorageStore = () => {
    const setItem = useCallback((destination: StorageLocation, key: string, value: any) => {
        const storage = destination === "localStorage" ? localStorage : sessionStorage;

        storage.setItem(key, JSON.stringify(value));
    }, []);

    const getItem = useCallback((destination: StorageLocation, key: string, defaultValue?: any) => {
        const storage = destination === "localStorage" ? localStorage : sessionStorage;
        const item = storage.getItem(key);

        return item ? safelyParseJSON(item, defaultValue) : defaultValue;
    }, []);

    const removeItem = useCallback((destination: StorageLocation, key: string) => {
        const storage = destination === "localStorage" ? localStorage : sessionStorage;

        storage.removeItem(key);
    }, []);

    const cleanup = useCallback((destination: StorageLocation | "all", force: false) => {
        const storages =
            destination === "all" ? [localStorage, sessionStorage] : destination === "localStorage" ? [localStorage] : [sessionStorage];

        storages.forEach((storage) => storage.clear());
    }, []);

    return useMemo(() => ({setItem, getItem, removeItem, cleanup}), [setItem, getItem]);
};

export const [StorageProvider, useStorage] = createContextProvider(
    {
        name: "StorageProvider",
    },
    useStorageStore
);

export type StorageContext =
    | string
    | boolean
    | number
    | {
          [key: string]: StorageContext;
      };

function getContextualKey<TContext extends StorageContext>(ctx: TContext): string {
    return typeof ctx !== "object"
        ? ctx.toString()
        : Object.entries(ctx)
              .sort(([k1], [k2]) => k1.localeCompare(k2))
              .map(([k, v]) => `${k}=${getContextualKey(v)}`)
              .join();
}

export const useContextualizedStorage = (ctx?: StorageContext) => {
    const {setItem, getItem, removeItem} = useStorage();

    const contextualizeKey = useCallback((key: string, ctx?: StorageContext) => (ctx ? `${key}-${getContextualKey(ctx)}` : key), [ctx]);

    const setContextualizedItem = useCallback(
        (destination: StorageLocation, key: string, value: any, ctx?: StorageContext) => {
            setItem(destination, contextualizeKey(key, ctx), value);
        },
        [setItem, contextualizeKey]
    );

    const getContextualizedItem = useCallback(
        (destination: StorageLocation, key: string, defaultValue?: any, ctx?: StorageContext) => {
            return getItem(destination, contextualizeKey(key, ctx), defaultValue);
        },
        [getItem, contextualizeKey]
    );

    const removeContextualizedItem = useCallback(
        (destination: StorageLocation, key: string, ctx?: StorageContext) => {
            removeItem(destination, contextualizeKey(key, ctx));
        },
        [getItem, contextualizeKey]
    );

    return useMemo(
        () => ({setContextualizedItem, getContextualizedItem, removeContextualizedItem, contextualizeKey}),
        [setContextualizedItem, getContextualizedItem, removeContextualizedItem, contextualizeKey]
    );
};
