import {ResourcesTypes} from "@generated/artifacts/resources";
import {ArtifactTag} from "@generated/artifacts";
import {PaletteItemFileType, PaletteItemToolType} from "@generated/data";

export const getArtifactTagBasedOnFileType = (fileType?: PaletteItemFileType): ArtifactTag | undefined => {
    if (!fileType) {
        return undefined;
    }
    switch (fileType) {
        case PaletteItemFileType.Presentation: {
            return "flowos/ppt";
        }
        case PaletteItemFileType.Doc: {
            return "flowos/doc";
        }
        case PaletteItemFileType.Excel: {
            return "flowos/excel";
        }
        case PaletteItemFileType.Pdf: {
            return "flowos/pdf";
        }
        case PaletteItemFileType.Keynote: {
            return "flowos/keynote";
        }
        case PaletteItemFileType.Pages: {
            return "flowos/pages";
        }
        case PaletteItemFileType.Numbers: {
            return "flowos/numbers";
        }
        case PaletteItemFileType.Video: {
            return "flowos/video";
        }
        case PaletteItemFileType.Image: {
            return "flowos/gallery";
        }
        default: {
            return undefined;
        }
    }
};

export const getArtifactTagBasedOnToolType = (toolType?: PaletteItemToolType): ArtifactTag | undefined => {
    if (!toolType) {
        return undefined;
    }
    switch (toolType) {
        case "form": {
            return "flowos/form";
        }
        case "adobexd": {
            return "flowos/adobexd";
        }
        case "youtube": {
            return "flowos/youtube";
        }
        case "vimeo": {
            return "flowos/vimeo";
        }
        case "airtable": {
            return "flowos/airtable";
        }
        case "smartboard": {
            return "flowos/blackboard";
        }
        case "canva": {
            return "flowos/canva";
        }
        case "figma": {
            return "flowos/figma";
        }
        case "mentimeter": {
            return "flowos/mentimeter";
        }
        case "pitch": {
            return "flowos/pitch";
        }
        case "slido": {
            return "flowos/slido";
        }
        case "twitch": {
            return "flowos/twitch";
        }
        case "miro": {
            return "flowos/miro";
        }
        case "google_docs": {
            return "flowos/google-docs";
        }
        case "google_sheets": {
            return "flowos/google-sheets";
        }
        case "google_presentation": {
            return "flowos/google-slides";
        }
        case "google_forms": {
            return "flowos/google-forms";
        }
        case "boardmix": {
            return "flowos/boardmix";
        }
        default: {
            return undefined;
        }
    }
};

export const getResourceTypeBasedOnToolType = (toolType?: PaletteItemToolType): ResourcesTypes | undefined => {
    if (!toolType) {
        return undefined;
    }
    switch (toolType) {
        case "form": {
            return "flowos/form/resx/Form";
        }
        case "adobexd": {
            return "flowos/adobexd/resx/AdobeXD";
        }
        case "airtable": {
            return "flowos/airtable/resx/Airtable";
        }
        case "smartboard": {
            return "flowos/blackboard/resx/Blackboard";
        }
        case "canva": {
            return "flowos/canva/resx/Canva";
        }
        case "figma": {
            return "flowos/figma/resx/Figma";
        }
        case "mentimeter": {
            return "flowos/mentimeter/resx/Mentimeter";
        }

        case "pitch": {
            return "flowos/pitch/resx/Pitch";
        }
        case "slido": {
            return "flowos/slido/resx/Slido";
        }
        case "twitch": {
            return "flowos/twitch/resx/Twitch";
        }
        case "youtube": {
            return "flowos/youtube/resx/Youtube";
        }
        case "vimeo": {
            return "flowos/vimeo/resx/Vimeo";
        }
        case "boardmix": {
            return "flowos/boardmix/resx/Boardmix";
        }

        default: {
            return undefined;
        }
    }
};

export const getResourceTypeBasedOnFileType = (fileType?: PaletteItemFileType): ResourcesTypes | undefined => {
    if (!fileType) {
        return undefined;
    }

    switch (fileType) {
        case "presentation": {
            return "flowos/ppt/resx/Ppt";
        }
        case "doc": {
            return "flowos/doc/resx/Doc";
        }
        case "excel": {
            return "flowos/excel/resx/Excel";
        }
        case "pdf": {
            return "flowos/pdf/resx/Pdf";
        }
        case "keynote": {
            return "flowos/keynote/resx/Keynote";
        }
        case "pages": {
            return "flowos/pages/resx/Pages";
        }
        case "numbers": {
            return "flowos/numbers/resx/Numbers";
        }
        case "video": {
            return "flowos/video/resx/Video";
        }
        case "image": {
            return "flowos/gallery/resx/Gallery";
        }
        default: {
            return undefined;
        }
    }
};

export const videoBasedArtifactTags = ["flowos/youtube", "flowos/vimeo", "flowos/twitch", "flowos/video"];
