import {useState} from "@workhorse/api/rendering";
import {useCallback, useRef} from "react";
import {AudioContext, useAudioDbAnalyzer} from "../lib/useAudioAnalyzer";

export function initializeElementAnalyser(element: HTMLMediaElement) {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaElementSource(element);
    const destination = audioContext.createMediaStreamDestination();

    const analyserNode = audioContext.createAnalyser();
    analyserNode.smoothingTimeConstant = 0.2;
    analyserNode.fftSize = 256;

    audioSource.connect(analyserNode);
    analyserNode.connect(destination);

    return {analyserNode, audioSource, audioContext, element, stream: destination.stream};
}

export function useElementAudioDbAnalyzer(cb?: (db: number) => void) {
    const [analyserNode, setAnalyzerNode] = useState<AnalyserNode | null>(null);

    const connectionRef = useRef<ReturnType<typeof initializeElementAnalyser> | null>(null);

    const connect = useCallback((element: HTMLMediaElement) => {
        if (!element) {
            setAnalyzerNode(null);
            return;
        }

        const connection = initializeElementAnalyser(element);
        connectionRef.current = connection;

        setAnalyzerNode(connection.analyserNode);

        return connection;
    }, []);

    const resume = useCallback(async () => {
        if (!connectionRef.current) {
            return;
        }
        const {audioContext} = connectionRef.current;
        return audioContext.resume();
    }, []);

    const disconnect = useCallback(async () => {
        setAnalyzerNode(null);

        if (!connectionRef.current) {
            return;
        }

        const {audioSource, analyserNode, element, audioContext, stream} = connectionRef.current;
        connectionRef.current = null;

        audioSource.disconnect();
        analyserNode.disconnect();
        element.remove();
        stream.getTracks().forEach((track) => track.stop());

        await audioContext.close();
    }, []);

    useAudioDbAnalyzer(analyserNode, cb);

    return {connect, disconnect, resume};
}
