import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import {forwardRef, useCallback, useState} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import Input from "@ui/cdk/Input";
import {InputProps} from "../Input/Input";
import style from "./style/autocomplete.module.scss";
import {cls} from "@ui/cdk/util/util";

type MuiAutocompleteProps = ExtractProps<typeof MuiAutocomplete>;

export type AutocompleteProps = Omit<MuiAutocompleteProps, "disablePortal" | "renderInput"> & {
    /**
     * @deprecated
     *
     * this makes the Popper appear on top of the input
     * so it has been set to false as a default
     * passing this prop will do nothing
     */
    disablePortal?: boolean;

    /**
     * This prop is optional
     * The Autocomplete component has its own renderInput function which uses @ui/cdk/Input
     */
    renderInput?: MuiAutocompleteProps["renderInput"];

    /**
     * pass a label component/string to the input element
     */
    label?: InputProps["label"];
    error?: InputProps["error"];
    info?: InputProps["info"];
    helperText?: InputProps["helperText"];

    /**
     * make this a required field
     */
    required?: boolean;
};

function AutocompleteWithRef(props: AutocompleteProps, ref: any) {
    const {disablePortal, renderInput, label, required, error, info, helperText, className: classNameProp, ...other} = props;
    const className = cls(style.fosAutocomplete, classNameProp);

    const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (navigator.maxTouchPoints > 2) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const renderAutocompleteInput: MuiAutocompleteProps["renderInput"] = useCallback(
        (params) => {
            const {InputProps, ...rest} = params;
            const {className, endAdornment, ref: refProp, startAdornment} = InputProps || {};

            return (
                <Input
                    label={label}
                    required={required}
                    {...rest}
                    className={className}
                    endAdornment={endAdornment}
                    ref={refProp}
                    startAdornment={startAdornment}
                    error={error}
                    helperText={helperText}
                    onClick={onInputClick}
                />
            );
        },
        [error, helperText]
    );

    return (
        <MuiAutocomplete
            fullWidth
            renderInput={renderInput || renderAutocompleteInput}
            disablePortal={false}
            className={className}
            {...other}
            ref={ref}
        />
    );
}

const Autocomplete = forwardRef(AutocompleteWithRef);

export default Autocomplete;
