import React, {forwardRef} from "@workhorse/api/rendering";
import MuiBackdrop, {BackdropProps} from "@material-ui/core/Backdrop";

function BackdropWithRef(props: BackdropProps, ref: any) {
    return <MuiBackdrop {...props} ref={ref} />;
}

const Backdrop = forwardRef(BackdropWithRef) as typeof BackdropWithRef;

export default Backdrop;
