import React, {useCallback, useEffect} from "@workhorse/api/rendering";
import {EventEmitter, EventNames, EventListener} from "eventemitter3";
import {EventMap} from "./EventsMap";

export const dataEvents = new EventEmitter<EventMap>();
export type AllEvents = keyof EventMap;

export const useDataEvent = <T extends EventNames<EventMap>>(eventName: T, listener: (payload: EventMap[T]) => void, context?: any) => {
    const removeListener = useCallback(() => {
        dataEvents.off(eventName, listener, context);
    }, [listener, context]);

    useEffect(() => {
        dataEvents.on(eventName, listener, context);
        return removeListener;
    }, [listener, removeListener, context]);

    return {removeListener};
};
