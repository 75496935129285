import {cls} from "@ui/cdk/util/util";
import CircularProgress from "@ui/core/components/CircularProgress";
import React, {createElement, forwardRef, Fragment} from "react";
import {ReactComponent as Caret} from "./assets/caret.svg";
import styles from "./style/button.module.scss";
import {ariaLabelBasedOnClass} from "@workhorse/util/strings";

export type ButtonVariant =
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "plain"
    | "destructive"
    | "destructive-secondary"
    | "destructive-tertiary"
    | "destructive-quaternary"
    | "ghost";

type ButtonComponent =
    | "button"
    | "a"
    | "div"
    | "span"
    | "label"
    | "li"
    | React.ComponentType<{
          onClick?: (e: React.MouseEvent) => void;
      }>;

interface ButtonBaseProps {
    id?: string;
    children?: React.ReactNode | React.ReactNode[];
    variant?: ButtonVariant;
    darkThemeVariant?: ButtonVariant;
    className?: string;
    classes?: any;
    capitalize?: boolean;
    size?: "smallest" | "small" | "medium" | "large";
    startIcon?: React.ReactNode | React.ReactNode[];
    endIcon?: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
    elevation?: number;
    wait?: boolean;
    rounded?: boolean;
    disclosure?: boolean;
    loading?: boolean;
    spinnerClassName?: string;
    open?: boolean;
    withMarginLeft?: boolean;
    paddingLarge?: boolean;
    fullWidth?: boolean;
    type?: "button" | "submit" | "reset";
    inverted?: boolean;
    noBorder?: boolean;
    noFocusBorder?: boolean;
}

export type ButtonProps<T extends ButtonComponent = "button"> = {
    component?: T;
} & ButtonBaseProps &
    Omit<React.ComponentProps<T>, keyof ButtonBaseProps>;

function ButtonWithRef<T extends ButtonComponent = "button">(props: ButtonProps<T>, ref: any) {
    const {
        component = "button",
        variant = "primary",
        className: classNameProp,
        children,
        capitalize = true,
        size = "medium",
        startIcon,
        endIcon,
        disabled: disabledProp,
        elevation = 1,
        wait = false,
        disclosure,
        withMarginLeft,
        paddingLarge,
        open,
        darkThemeVariant,
        fullWidth,
        type,
        rounded,
        inverted,
        noBorder = false,
        loading = false,
        noFocusBorder = false,
        spinnerClassName,
        ...rest
    } = props;

    const disabled = disabledProp;

    /**
     * assigning a specific className based on a certain prop
     */
    const className = cls(
        "fosButton flex flex00-auto flex-center-all",
        variant && styles[variant],
        darkThemeVariant && styles["dark" + darkThemeVariant],
        styles[size],
        withMarginLeft && styles.withMarginLeft,
        paddingLarge && styles.paddingLarge,
        classNameProp,
        fullWidth && styles.fullWidth,
        rounded && styles.rounded,
        inverted && styles.inverted,
        !noBorder && styles.border,
        noFocusBorder && styles.noFocusBorder
    );

    let ariaLabel = props["aria-label"];

    if (ariaLabel === undefined && children && children[1] === undefined && typeof children[0] !== "string") {
        if (classNameProp || props["data-id"]) {
            const newAriaLabel = ariaLabelBasedOnClass(classNameProp || props["data-id"]);

            if (ariaLabel) {
                ariaLabel = newAriaLabel;
            }
        }
    }

    return createElement(
        component,
        {
            className,
            disabled,
            ref,
            type: type ?? "button",
            ...rest,
            "aria-label": ariaLabel,
            key: "button-component",
        },

        <Fragment key="button-component-chilren">
            {startIcon ? startIcon : null}
            {loading && (
                <CircularProgress
                    className={cls("mr-8 no-svg-mg", styles.loading, spinnerClassName)}
                    size="18px"
                    thickness={5}
                    color="inherit"
                />
            )}
            {children ? <span className={"flex flex-items-center flex-justify-center fullh"}>{children}</span> : null}
            {endIcon ? endIcon : null}
            {disclosure ? <Caret className={cls(styles.disclosure, open && styles.openDisclosure)} key="button-component-caret" /> : null}
        </Fragment>
    );
}

const Button = forwardRef(ButtonWithRef) as <T extends ButtonComponent = "button">(
    props: ButtonProps<T> & {ref?: any}
) => ReturnType<typeof ButtonWithRef>;

export {ButtonWithRef};

export default Button;
