import {memo, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import MicroNotificationCenter from "./MicroNotificationCenter";
import classes from "./styles/NotificationWrapper.module.scss";

const MicroNotificationWrapper = () => {
    const [hasVisibleChildren, setHasVisibleChildren] = useState(false);

    return (
        <div className={cls(classes.root, !hasVisibleChildren && classes.pointerNone)}>
            <MicroNotificationCenter setHasVisibleChildren={setHasVisibleChildren} />
        </div>
    );
};

export default memo(MicroNotificationWrapper);
