import loadable from "@workhorse/api/loadable";
import {BookedEventRouteProps} from "@workhorse/declarations";
import {useEffect} from "@workhorse/api/rendering";
import {Route, useLocation} from "@workhorse/api/routing";
import {useQuery} from "@workhorse/api/data";
import {BookedEventFragment, GetBookedEventDocument, GetBookedEventQuery, PublicBookingEvent} from "@generated/data";
import Loading from "@workhorse/components/Loading";
import {BookingViewProvider} from "@workhorse/api/booking";
import PageNotFound from "../base/components/PageNotFound";
import {useUserInfo} from "@workhorse/providers/User";
import {AuthService} from "@workhorse/api/authService";
// there is no guarantee the user will land on the Book every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const BookedEventUpdate = loadable(() => import("./BookedEventUpdate"));
const BookedEventSuccessful = loadable(() => import("./BookedEventSuccessful"));

export type BookedEventDetails = Exclude<GetBookedEventQuery["bookedEvent"], undefined | null>;

function BookendEventHOC(props: BookedEventRouteProps & {booking: BookedEventDetails; token?: string | null}): JSX.Element {
    const id = props.match?.params.bookedEventId as string;
    const action = props.match?.params.bookedEventAction as string;

    return (
        <div className="route-hoc flex-align-start book-hoc" key="book-hoc">
            <BookedEventUpdate key={`book-entry-point-${id}`} action={action} booking={props.booking} token={props.token} />
        </div>
    );
}

function BookendEventSuccessfulHOC(props: BookedEventRouteProps & {booking: BookedEventDetails}): JSX.Element {
    const action = props.match?.params.bookedEventAction as string;

    return (
        <div className="route-hoc flex-align-start book-successful-hoc" key="book-successful-hoc">
            <BookedEventSuccessful key={`booked-event-${action}-successful-entry-point`} booking={props.booking} action={action} />
        </div>
    );
}

function BookRouteManagerHOC(props: BookedEventRouteProps): JSX.Element {
    const bookedEventId = props.match?.params.bookedEventId as string;
    const {search} = useLocation();
    const tokenFromParams = new URLSearchParams(search).get("token");
    const user = useUserInfo();

    const notAuthenticated = !tokenFromParams && !user.isAuthenticated;

    const {data: bookedEventData, loading} = useQuery(GetBookedEventDocument, {
        variables: {
            id: bookedEventId,
            token: tokenFromParams,
            includeEvent: true,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        skip: notAuthenticated,
    });

    const booking = bookedEventData?.bookedEvent;
    const branding = booking?.event?.branding;

    useEffect(() => {
        if (notAuthenticated) {
            AuthService.getInstance().login(window.location.pathname);
        }
    }, [notAuthenticated]);

    useEffect(() => {
        if (!branding) {
            return;
        }

        const root = document.querySelector<HTMLElement>(":root");
        const styleTag = document.createElement("style");

        if (root && branding.sessionBackground) {
            root.style.setProperty("--app-background", `transparent url("${branding.sessionBackground}") no-repeat center/cover`);
            root.style.setProperty("--app-player-background", `transparent`);
        }

        if (branding.styleTag) {
            styleTag.className = "custom-theme";
            styleTag.innerHTML = branding.styleTag;
            const existingBranding = document.querySelector("head style.custom-theme");
            existingBranding?.remove();
            document.head.append(styleTag);
        }

        return () => {
            styleTag.remove();
            root?.style.removeProperty("--app-background");
            root?.style.removeProperty("--app-player-background");
        };
    }, [branding]);

    if (loading || notAuthenticated) {
        return <Loading />;
    }

    if (!booking) {
        return <PageNotFound />;
    }

    return (
        <BookingViewProvider>
            <Route
                exact
                path="/booked-event/:bookEventId/:bookedEventAction"
                render={() => <BookendEventHOC {...props} booking={booking} token={tokenFromParams} />}
            />
            <Route
                exact
                path="/booked-event/:bookedEventId/:bookedEventAction/successful"
                render={() => <BookendEventSuccessfulHOC {...props} booking={booking} />}
            />
        </BookingViewProvider>
    );
}

export default BookRouteManagerHOC;
