import {useEffect} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {RouteHOCProps} from "@workhorse/declarations";

type RegisterHOCProps = RouteHOCProps<{ref?: string}>;

const UserRegister = (props: RegisterHOCProps) => {
    useEffect(() => {
        window.location.replace("/user/login");
    }, []);

    return <Loading location="UserRegister.tsx" />;
};

export default UserRegister;
