import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import IconButton from "@ui/core/components/IconButton";
import classes from "./styles/ParticipantEntry.module.scss";
import {useParticipantActions} from "@workhorse/api/conference2";
import ParticipantEntryMuteAction from "./ParticipantEntryMuteAction";
import {useEffect} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util";
import TogglePinParticipantButton from "@workhorse/pages/player/components/TogglePinParticipantButton";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";

type ParticipantEntryActionsProps = {
    participantId: string;
    isOwner: boolean;
    isAssistant: boolean;
    isLocalParticipant: boolean;
    onToggleMoreOptions: (open: boolean) => void;
};

function ParticipantEntryActions(props: ParticipantEntryActionsProps) {
    const {pinnedModeEnabled} = useSessionView();

    const {participantId, isOwner, isAssistant, isLocalParticipant, onToggleMoreOptions} = props;
    const {connected, micOn} = useRemoteParticipantStatus(participantId);

    const {open, value, isOpen} = useParticipantActions();

    const {isLobby} = useIsLobby();

    const handleOnShowParticipantActionMenu = (e) => {
        open({
            anchorEl: e.currentTarget,
            participantId,
            className: "participant-entry-actions",
            anchorOrigin: {vertical: "center", horizontal: "left"},
            transformOrigin: {vertical: "center", horizontal: "right"},
            classes: {paper: classes.moreOptionsPaper},
        });
    };

    useEffect(() => {
        if (value?.participantId === participantId && value.className === "participant-entry-actions" && isOpen) {
            onToggleMoreOptions(true);
        } else {
            onToggleMoreOptions(false);
        }
    }, [participantId, value?.participantId, value?.className, onToggleMoreOptions, isOpen]);

    return (
        <div className="flex flex-items-center ml-2">
            {pinnedModeEnabled && (
                <TogglePinParticipantButton
                    participantId={participantId}
                    overrideClasses={true}
                    className={cls(classes.participantActionBtn, "more-options-btn")}
                />
            )}

            {connected && !isLocalParticipant && !isLobby && (
                <ParticipantEntryMuteAction
                    renderingForAdmin={isOwner}
                    renderingForAssistant={isAssistant}
                    participantId={participantId}
                    muted={!micOn}
                />
            )}
            <IconButton
                data-id="participant-actions"
                size="small"
                className={cls(classes.participantActionBtn, "more-options-btn")}
                onClick={handleOnShowParticipantActionMenu}
                aria-label="Participant Actions"
            >
                <MoreVertRoundedIcon />
            </IconButton>
        </div>
    );
}

export default ParticipantEntryActions;
