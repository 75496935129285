import loadable from "@workhorse/api/loadable";
import {ReactComponent as VideoSectionIcon} from "../assets/session-video-icon.svg";
import {SessionSettingsSection} from "../../definitions";

const VideoSection = loadable(() => import("./VideoSection"));
VideoSection.displayName = "Loadable:VideoSection";

const VideoSectionDefinition: SessionSettingsSection<"device.video"> = {
    translateKey: "device.video",
    kind: "device.video",
    title: "Video settings",
    component: VideoSection,
    icon: VideoSectionIcon,
    settingsStateCheck(pv, cv) {
        return pv["device.backgroundEffects"]?.filter === cv["device.backgroundEffects"]?.filter;
    },
};

export default VideoSectionDefinition;
