import MuiToggleButton, {ToggleButtonProps} from "@material-ui/lab/ToggleButton";
import type {Theme} from "@material-ui/core/styles";
import {cls} from "@ui/cdk/util/util";
import styles from "./style/ToggleButton.module.scss";

type CDKToggleButtonProps = Omit<ToggleButtonProps, "color"> & {
    variant?: keyof Pick<Theme["main"]["palette"], "gray" | "blue" | "purple" | "green" | "yellow" | "red">;
};

const ToggleButton = (props: CDKToggleButtonProps) => {
    return <MuiToggleButton classes={{root: styles.root}} {...props} />;
};

export default ToggleButton;
