// eslint-disable-next-line import/no-webpack-loader-syntax
import Spinner from "-!svg-react-loader!../assets/media/spinner.svg";
import {cls} from "@ui/cdk/util/util";
import {useEffect} from "@workhorse/api/rendering";
import {WithClassName} from "@workhorse/declarations";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import CircularProgress from "@ui/core/components/CircularProgress";
import classes from "./style/Loading.module.scss";
import {useUser} from "./user";
import {isFeatureAvailable} from "./subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {OrganizationPlan} from "@generated/data";

type LoadingProps = WithClassName & {
    relative?: boolean;
    withBackground?: boolean;

    message?: string | JSX.Element;
    aboveAll?: boolean;

    /**
     * @deprecated - use overAll
     */
    zIndex?: number;
    location?: string;
};

export default function Loading(props: LoadingProps) {
    const {relative, withBackground, zIndex, className, message, aboveAll, location} = props;
    const style: {zIndex?: number} = {};
    const sessionId = useSessionIdFromRoute();
    const hostType = useHostType();
    const user = useUser();

    const branding = user.workspacePermissions?.find((w) => w.activeWorkspace)?.workspace.branding;
    const featureIsAvailable = isFeatureAvailable(
        LimitedFeature.WHITE_LABELING,
        user?.organizationPermissions?.find((p) => p.activeOrganization)?.organization.plan ?? OrganizationPlan.Free
    );

    const hasCustomLogo = branding?.active && featureIsAvailable;

    if (zIndex) {
        style.zIndex = zIndex;
    }

    useEffect(() => {
        if (!location) {
            return;
        }

        let n = 0;

        const t = setInterval(() => {
            n += 2;

            if (n <= 6) {
                return;
            }

            console.log(
                `[StuckInLoading]: ${n} seconds for location=${location} sessionId=${sessionId} hostType=${hostType} userId=${user.id} role=${user.role}`
            );
        }, 2000);

        return () => {
            clearInterval(t);
        };
    }, [location, sessionId, hostType, user.id, user.role]);

    return (
        <div
            style={style}
            className={cls(
                classes.root,
                relative && classes.rootRelative,
                withBackground && classes.withBackground,
                aboveAll && classes.aboveAll,
                className
            )}
        >
            {hasCustomLogo || !branding ? (
                <CircularProgress size={50} thickness={8} className={cls(classes.circularProgress, user ? classes.colored : "")} />
            ) : (
                <Spinner />
            )}
            {message}
        </div>
    );
}
