import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";
import FormatListNumberedRoundedIcon from "@material-ui/icons/FormatListNumberedRounded";
import {ReactComponent as TasklistIcon} from "./icons/checklist-icon.svg";
import {useCallback, useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {EditorListElement, isArtifactElement, ToolbarGenericProps, useClickOutside} from "../api";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";

export const ToolbarLists = (props: ToolbarGenericProps & {exclude?: EditorListElement[]}) => {
    const {editor, onActionDone, exclude} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const ref = useRef<HTMLButtonElement>(null);

    const disabled = isArtifactElement(editor) || editor.isEditable === false;

    return (
        <>
            {exclude?.includes("bulletList") ? null : (
                <ToolbarItem
                    tooltip="Unordered list"
                    className={cls(classes.button, editor.isActive("bulletList") && classes.buttonActive)}
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    isMobile={props.isMobile}
                    disabled={disabled}
                >
                    <FormatListBulletedRoundedIcon />
                </ToolbarItem>
            )}
            {exclude?.includes("orderedList") ? null : (
                <ToolbarItem
                    tooltip="Ordered list"
                    className={cls(classes.button, editor.isActive("orderedList") && classes.buttonActive)}
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    disabled={disabled}
                    isMobile={props.isMobile}
                >
                    <FormatListNumberedRoundedIcon />
                </ToolbarItem>
            )}
            {exclude?.includes("taskList") ? null : (
                <ToolbarItem
                    tooltip="Task list"
                    className={cls(classes.button, editor.isActive("taskList") && classes.buttonActive)}
                    onClick={() => editor.chain().focus().toggleTaskList().run()}
                    disabled={disabled}
                    isMobile={props.isMobile}
                >
                    <TasklistIcon />
                </ToolbarItem>
            )}
        </>
    );
};
