import NewOffer, {NewOfferProps} from "./NewOffer";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import classes from "../styles/NewOfferWrapper.module.scss";
import {useRef} from "@workhorse/api/rendering";

type NewOfferWrapperProps = NewOfferProps & {};

function NewOfferWrapper(props: NewOfferWrapperProps) {
    const dialogRef = useRef<DialogImperativeRef>();

    if (props.isMacro) {
        return <NewOffer {...props} />;
    }

    return (
        <Dialog open imperativeRef={dialogRef} classes={{paper: classes.root}} onClose={props.onClose}>
            <NewOffer {...props} />
        </Dialog>
    );
}

export default NewOfferWrapper;
