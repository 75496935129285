import {BookingFragment, BookingSchedule, BookingScheduleFragment, SessionLifecycle} from "@generated/data";
import {dateFormat} from "@ui/cdk/DateFormat";
import {Interval} from "@workhorse/api/booking";
import {endOfWeekLocale, startOfWeekLocale, WeekStartsOn} from "@workhorse/api/calendar";
import {BookingScheduleCreate} from "@workhorse/pages/newBooking/NewBooking";
import {getCorrectPlural} from "@workhorse/util/strings";
import {
    differenceInSeconds,
    endOfMonth,
    endOfToday,
    endOfYear,
    isAfter,
    isSameYear,
    isToday,
    isTomorrow,
    isYesterday,
    startOfMonth,
    startOfToday,
    startOfYear,
} from "date-fns";

export const bookingHasPassed = (booking: BookingFragment): boolean => {
    return booking.session &&
        booking.session.plannedEnd &&
        !isAfter(new Date(booking.session.plannedEnd), Date.now()) &&
        booking.session.lifecycle === SessionLifecycle.Ended
        ? true
        : booking.session?.lifecycle === SessionLifecycle.Ended;
};

export const bookingHasNotPassed = (booking: BookingFragment): boolean => {
    return booking.session && booking.session.startAt
        ? new Date(booking.session.startAt) > new Date() && booking.session?.lifecycle === SessionLifecycle.NotStarted
        : booking.session?.lifecycle === SessionLifecycle.NotStarted;
};

export const formatDay = (interval: Interval, startAt: Date): string => {
    if (interval === "today") {
        return "";
    }

    if (isYesterday(startAt)) {
        return "Yesterday";
    }
    if (isToday(startAt)) {
        return "Today";
    }
    if (isTomorrow(startAt)) {
        return "Tomorrow";
    }

    switch (interval) {
        case "this-week":
            return dateFormat(startAt, "weekday");
        case "this-month":
            return dateFormat(startAt, ["dayOfMonth-ss"]);
        case "this-year":
            return dateFormat(startAt, ["dayOfMonth", "monthShort"]);
        default:
            return dateFormat(startAt, ["dayOfMonth", "monthShort", !isSameYear(new Date(startAt), new Date()) && "year"]);
    }
};

export const formatTimeLeft = (startAt: Date, _endTime?: Date, short?: boolean, _startsInText?: string): string => {
    const startsInText = _startsInText ?? "starts in ";
    const endTime = _endTime ?? new Date();
    const diffSec = differenceInSeconds(new Date(startAt), new Date(endTime));
    let diff = Math.floor(diffSec / 60);

    if (diff === 0) {
        diff = 1;
    } else if (diff < 0) {
        diff = -1 * diff;
    } else if (diff < 60) {
        return startsInText + `${diff}${short ? "min" : getCorrectPlural(" minute", diff)}`;
    }

    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;
    if (hours < 24) {
        return (
            startsInText +
            `${hours}${short ? "h" : getCorrectPlural(" hour", hours)} ${
                minutes > 0 ? `${minutes}${short ? "min" : getCorrectPlural(" minute", minutes)}` : ""
            }`
        );
    }

    const days = Math.floor(hours / 24);
    const hoursLeft = hours % 24;
    if (days < 30) {
        return (
            startsInText +
            `${days}${short ? "d" : getCorrectPlural(" day", days)} ${
                hoursLeft > 0 ? `${hoursLeft}${short ? "h" : getCorrectPlural(" hour", hoursLeft)}` : ""
            }`
        );
    }

    const months = Math.floor(days / 30);
    const daysLeft = days % 30;
    if (months < 12) {
        return (
            startsInText +
            `${months} ${getCorrectPlural("month", months)} ${daysLeft > 0 ? `and ${daysLeft} ${getCorrectPlural("day", daysLeft)}` : ""}`
        );
    }

    const years = Math.floor(months / 12);
    const monthsLeft = months % 12;
    return (
        startsInText +
        `${years} ${getCorrectPlural("year", years)} ${monthsLeft > 0 ? `and ${monthsLeft} ${getCorrectPlural("month", monthsLeft)}` : ""}`
    );
};

export const nullSchedule: BookingScheduleFragment = {
    days: [],
    default: false,
    hours: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
    },
    id: "",
    name: "",
    temporary: false,
};

export const defaultTemporarySchedule: BookingScheduleCreate = {
    name: "Custom schedule",
    days: ["mon", "tue", "wed", "thu", "fri"],
    hours: {
        mon: [[9 * 60, 17 * 60]],
        tue: [[9 * 60, 17 * 60]],
        wed: [[9 * 60, 17 * 60]],
        thu: [[9 * 60, 17 * 60]],
        fri: [[9 * 60, 17 * 60]],
        sat: [[9 * 60, 17 * 60]],
        sun: [[9 * 60, 17 * 60]],
    },
};

export const CUSTOM_SCHEDULE = "Custom Schedule";

export const BOOKING_ALL_DAYS = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const getIntervals = (weekStartsOn: WeekStartsOn): Record<Interval, {start?: Date; end?: Date}> => {
    return {
        today: {
            start: startOfToday(),
            end: endOfToday(),
        },
        "this-week": {
            start: startOfWeekLocale(new Date(), weekStartsOn),
            end: endOfWeekLocale(new Date(), weekStartsOn),
        },
        "this-month": {
            start: startOfMonth(new Date()),
            end: endOfMonth(new Date()),
        },
        "this-year": {
            start: startOfYear(new Date()),
            end: endOfYear(new Date()),
        },
        all: {},
    };
};

export const getOneInterval = (interval: Interval, weekStartsOn: WeekStartsOn): {start?: Date; end?: Date} => {
    switch (interval) {
        case "today":
            return {start: startOfToday(), end: endOfToday()};
        case "this-week":
            return {start: startOfWeekLocale(new Date(), weekStartsOn), end: endOfWeekLocale(new Date(), weekStartsOn)};
        case "this-month":
            return {start: startOfMonth(new Date()), end: endOfMonth(new Date())};
        case "this-year":
            return {start: startOfYear(new Date()), end: endOfYear(new Date())};
        case "all":
            return {};
        default:
            return {};
    }
};

export const INTERVALS_TEXT_MAP: Record<Interval, string> = {
    today: "Today's",
    "this-week": "This week's",
    "this-month": "This month's",
    "this-year": "This year's",
    all: "All",
};

export const is24HTimeFormat = () => {
    const timeFormat = new Intl.DateTimeFormat(navigator.language, {hour: "numeric"}).format().slice(-2);
    return Number.isInteger(Number(timeFormat));
};
