import {useWorkspaceAccess} from "@workhorse/api/access/hooks";
import loadable from "@workhorse/api/loadable";
import {NewBookingProps} from "@workhorse/declarations";
import {useHistory} from "react-router-dom";
import {useEffect} from "@workhorse/api/rendering";

const NewBooking = loadable(() => import("./NewBooking"));

function NewBookingHOC(props: NewBookingProps): JSX.Element {
    const history = useHistory();

    const {canCreateBookings} = useWorkspaceAccess();
    const eventId = props.match?.params.eventId;

    useEffect(() => {
        if (!canCreateBookings() && !eventId) {
            history.replace("/unauthorized");
        }
    }, []);

    return (
        <div className="route-hoc flex-align-start new-booking-hoc">
            <NewBooking eventId={eventId} />
        </div>
    );
}

export default NewBookingHOC;
