import {AgendaItemType, SessionEventState, SessionLifecycle, AccessListItemsDocument} from "@generated/data";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useState, useMemo, useEffect, useCallback} from "@workhorse/api/rendering";
import SelectAgenda from "@workhorse/components/SelectAgenda";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {AgendaItem} from "@workhorse/declarations/dataTypes";
import {getTemplate} from "@workhorse/pages/templates/utils";
import commonClasses from "../eventCommons.module.scss";
import EventAgendaEditor from "./EventAgendaEditor";
import TemplateProviders from "@workhorse/pages/templates/TemplateProviders";
import {useAgendaItems, useSession} from "@workhorse/providers/SessionDataProviders";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {BroadcastChannel} from "broadcast-channel";
import {useMutation} from "@workhorse/dataApi";
import apollo from "@workhorse/api/apollo";
import {useUserInfo} from "@workhorse/providers/User";
import {ActionCategory, categMap} from "@workhorse/components/command-palette/actionCategTree";
import {usePaletteExcludedItems} from "@workhorse/components/command-palette/PaletteActionProvider";
import {usePaletteActiveCateg, paletteActiveCategDefault} from "@workhorse/components/command-palette/CommandPaletteProviders";
import {useClientEvent} from "@api/events/client";
import {cls, togglePalette} from "@ui/cdk/util/util";

export function EventAgenda() {
    const session = useSession();
    const agendaItems = useAgendaItems();
    const [lockEvent] = useMutation("LockEventDocument");
    const {id: userId} = useUserInfo();

    const [value, setValue] = useState<string>(session.createdFromTemplateId ?? "");
    const [processingAgenda, setProcessingAgenda] = useState<boolean>(false);

    const {setExcludedItems} = usePaletteExcludedItems();
    const {setPaletteActiveCateg} = usePaletteActiveCateg();

    const agendaCategItem = categMap.find((obj) => obj.categ === ActionCategory.Agenda);

    const resetPalette = () => {
        setExcludedItems(categMap.filter((obj) => obj.categ !== ActionCategory.Agenda).map((obj) => obj.id));
        setPaletteActiveCateg({
            ...paletteActiveCategDefault,
            isForced: true,
        });
    };

    const unResetPalette = () => {
        setExcludedItems([]);
    };

    useEffect(() => {
        resetPalette();
        return () => {
            unResetPalette();
        };
    }, []);

    useClientEvent("palette-open", async (isOpen) => {
        if (!isOpen) {
            resetPalette();
        }
    });

    const toggleAgendaPalette = useCallback(() => {
        togglePalette();
        setPaletteActiveCateg(
            agendaCategItem
                ? {
                      id: agendaCategItem.id,
                      name: agendaCategItem.categ,
                      parentId: agendaCategItem.parentId,
                      searchStr: undefined,
                      isForced: false,
                  }
                : paletteActiveCategDefault
        );
    }, []);

    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () =>
            (session.lifecycle === SessionLifecycle.Started && !session.backstage) ||
            isLocked ||
            session.lifecycle === SessionLifecycle.Ended,
        [session.lifecycle, isLocked, session.backstage]
    );

    const eventPublished = useMemo(() => session.event?.state === SessionEventState.Published, [session.event?.state]);

    const commit = useCallback(() => {
        if (!eventPublished) {
            return designer.commit();
        }

        lockEvent({
            variables: {
                id: session.id,
                setChanged: true,
            },
        });

        return new Promise((resolve) => resolve(false));
    }, [eventPublished]);

    const [bustaCache, setBustaCache] = useState<string>(new Date().toISOString());

    useEffect(() => {
        const bustaDaCache = function (ev) {
            if (ev) {
                setBustaCache(new Date().toISOString());
            }
        };
        const templateCreatedBC = new BroadcastChannel("tc-bc");
        templateCreatedBC.onmessage = bustaDaCache;
    }, []);

    const handleOnChange = async (newValue: string) => {
        setProcessingAgenda(true);

        setValue(newValue);

        designer.undoChanges({
            from: ["agendaItems"],
        });

        if (!newValue) {
            designer.api.session.detachAgenda({
                type: AgendaItemType.Instant,
            });
            setProcessingAgenda(false);
            commit();

            return;
        }

        designer.state.setDesignerCommitState(true);

        const templateData = await getTemplate(newValue, true);
        const template = templateData.data.agendaTemplate;

        designer.api.session.detachAgenda({
            type: AgendaItemType.Instant,
        });

        const macroArtifacts = designer.currentMacroArtifacts();
        if (!macroArtifacts.find((a) => a.artifactId === "flowos/agenda")) {
            designer.api.artifact.addMacroArtifact({
                artifactName: "Agenda",
                artifactTag: "flowos/agenda",
            });
        }

        const templateAccessList = await apollo.client.query({
            query: AccessListItemsDocument,
            variables: {
                where: {
                    agendatemplateId: {
                        equals: template?.id,
                    },
                },
            },
        });

        designer.api.session.importAgenda({
            session: {
                description: "",
                name: "",
                timeDependency: true,
                createdFromTemplateId: undefined,
            },
            agendaItems: template?.agendaItems.length ? (template?.agendaItems as unknown as DeepMandatoryWithNullVals<AgendaItem>[]) : [],
            macroArtifacts: [],
            bypassResourcePublicCheck: template?.isPublic || templateAccessList?.data?.accessListItems?.some((a) => a.user?.id === userId),
            callback: () => {
                setProcessingAgenda(false);
                commit().then(() => {
                    designer.state.setDesignerCommitState(false);
                });
            },
        });
    };

    return (
        <div className={cls(commonClasses.mainContainerAgenda, "px-16", "py-16")}>
            <TemplateProviders>
                <EventAgendaEditor backstage={session.backstage} toggleAgendaPalette={toggleAgendaPalette} readOnly={eventNotUpdatable} />
            </TemplateProviders>
        </div>
    );
}
