import {useUser} from "@workhorse/components/user";
import {useRemoteApplicationState} from "./RemoteApplicationStateProvider";
export {useRemoteApplicationState};

export interface RemoteApplicationStateReleaseInfo {
    releaseTimestamp: number;
    tosUpdateTimestamp: number;
    versionTag: string;
    versionName: string;
    versionSummary: string;
    versionUrl: string;
}

export interface MobileApplicationStateReleaseInfo {
    versionSummary: string;
    versionName: string;
    versionUrl: string;
    forceUpdate: boolean;
}

export type RemoteApplicationStateFeatureFlagType = "GATE" | "SET" | "UNSET";

export interface RemoteApplicationStateFeatureFlagGate {
    type: "GATE";
    tag: string;
    interval: [number, number];
}

export interface RemoteApplicationStateFeatureFlagSet {
    type: "SET" | "UNSET";
    tag: string;
}

export type RemoteApplicationStateFeatureFlags = RemoteApplicationStateFeatureFlagSet | RemoteApplicationStateFeatureFlagGate;

export type RemoteApplicationStateCurrentOperation = "NONE" | "DEPLOY_IN_PROGRESS" | "FINISHED_DEPLOY";

export type RemoteApplicationStateActiveNotificationType = "PANIC" | "INCIDENT" | "INFO" | "WARNING";

export interface RemoteApplicationStateActiveNotification {
    type: RemoteApplicationStateActiveNotificationType;
    content: string;
    tag?: string;
}

export interface RemoteApplicationState {
    lastOperationChangeTimestamp?: number;
    lastUpdatedTimestamp: number;
    releaseInfo: RemoteApplicationStateReleaseInfo;
    androidReleaseInfo?: MobileApplicationStateReleaseInfo;
    iosReleaseInfo?: MobileApplicationStateReleaseInfo;
    previousReleaseInfo?: RemoteApplicationStateReleaseInfo;
    featureFlags: RemoteApplicationStateFeatureFlags[];
    currentOperation: RemoteApplicationStateCurrentOperation;
    activeNotifications: RemoteApplicationStateActiveNotification[];
}

// export const useRemoteApplicationState = () => {
//     const query = useQuery(GetRemoteApplicationStateDocument, {
//         fetchPolicy: "cache-first",
//         nextFetchPolicy: "cache-only",
//     });

//     const applicationState = query.error
//         ? undefined
//         : query.loading
//         ? undefined
//         : (query.data?.getApplicationState?.state as RemoteApplicationState | null) ?? null;

//     return {query, applicationState};
// };

export const useHasFeatureFlag = (flag: string) => {
    const user = useUser();
    if (!user || !user.email) return false;

    if (user.email.endsWith("@flowos.com") || user.email.endsWith("@sessions.us")) return true;

    const {applicationState} = useRemoteApplicationState();

    if (!applicationState) return false;

    return hasFeatureFlag(flag, user.email, applicationState!!) || user?.flags?.[flag] == "SET";
};

export const hasFeatureFlag = (flag: string, userEmail: string, applicationState: RemoteApplicationState) => {
    return !!(
        applicationState &&
        applicationState.featureFlags &&
        applicationState.featureFlags.find((f) => f.tag === flag && f.type === "SET")
    );
};

export const useHasFeatureFlags = (...flags: string[]) => {
    const user = useUser();

    // TODO: this is a hack, the user should always have email if he is logged in
    if (!user || !user.email) {
        // no user - all flags are false
        return flags.map(() => false);
    }

    if (user.email.endsWith("@flowos.com") || user.email.endsWith("@sessions.us")) {
        // flowos user - all flags are true
        return flags.map(() => true);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {applicationState} = useRemoteApplicationState();

    // normal users - get actual flags
    return flags.map(
        (flag) =>
            !!(
                applicationState &&
                applicationState.featureFlags &&
                applicationState.featureFlags.find((f) => f.tag === flag && f.type === "SET")
            ) || user?.flags?.[flag] == "SET"
    );
};
