import {useSubscription} from "@apollo/client";
import {GeneratedAgendaItem, OnStartGenerateAgendaDocument} from "@generated/data";
import {memo} from "../rendering";

type AgendaGeneratorMessengerProps = {
    sessionId: string;
    prompt: string;
    startGenerating: boolean;
    onAgendaItemGenerated: (agendaItem: AIGeneratedAgendaItem) => void;
    onTitleAndDescriptionGenerated?: (title: string, description: string) => void;
    onAgendaGenerationFinished: () => void;
    onAgendaGenerationError: (error: string) => void;
    includeTitleAndDescription?: boolean;
};

export type AIGeneratedAgendaItem = Omit<GeneratedAgendaItem, "__typename">;

const AgendaGeneratorMessenger = (props: AgendaGeneratorMessengerProps) => {
    const {includeTitleAndDescription = false} = props;

    useSubscription(OnStartGenerateAgendaDocument, {
        variables: {
            sessionId: props.sessionId,
            prompt: props.prompt,
            includeTitleAndDescription,
        },
        shouldResubscribe: true,
        skip: props.startGenerating === false || props.prompt.length === 0,
        fetchPolicy: "no-cache",
        onSubscriptionData: ({subscriptionData}) => {
            if (subscriptionData.data?.onStartGenerateAgenda) {
                if (subscriptionData.data.onStartGenerateAgenda.error) {
                    props.onAgendaGenerationError(subscriptionData.data.onStartGenerateAgenda.error);
                    return;
                }

                if (subscriptionData.data.onStartGenerateAgenda.agendaItem?.order === -1) {
                    props.onAgendaGenerationFinished();
                    return;
                }

                if (subscriptionData.data.onStartGenerateAgenda.agendaItem) {
                    props.onAgendaItemGenerated(subscriptionData.data.onStartGenerateAgenda.agendaItem);
                }

                if (subscriptionData.data.onStartGenerateAgenda.titleAndDescription) {
                    props.onTitleAndDescriptionGenerated?.(
                        subscriptionData.data.onStartGenerateAgenda.titleAndDescription.title,
                        subscriptionData.data.onStartGenerateAgenda.titleAndDescription.description
                    );
                }
            }
        },
    });

    return null;
};

export default memo(AgendaGeneratorMessenger, (prevProps, nextProps) => prevProps.startGenerating === nextProps.startGenerating);
