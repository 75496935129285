import {ContactInfoFragment, FullAgendaItemFragment, SpeakerInfoFragment} from "@generated/data";
import {cls} from "@ui/cdk/util";
import designer from "@workhorse/api/designer";
import {useEffect, useMemo, useState} from "@workhorse/api/rendering";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {getCorrectPlural} from "@workhorse/util/strings";
import EventSpeakersTableRow from "./EventSpeakersTableRow";
import classes from "./style/EventSpeakersTable.module.scss";
import {createSpeakersComparator, defaultSpeakersSort, SpeakerDisplayType, SpeakersSortField} from "./utils";

import Checkbox from "@ui/cdk/Checkbox";
import TableBody from "@ui/cdk/Table/TableBody";
import TableRow from "@ui/cdk/Table/TableRow";
import TableCell from "@ui/cdk/Table/TableCell";
import {TableCellSize} from "@ui/cdk/Table/TableCell/types";
import TableHead from "@ui/cdk/Table/TableHead";
import TableHeadSortButton from "@ui/cdk/Table/TableHeadSortButton";
import Table from "@ui/core/components/Table";
import TableContainer from "@ui/core/components/TableContainer";
import EventSpeakersTableActionHead from "./EventSpeakersTableActionHead";
import EventSpeakersListEmptyState from "./EventSpeakersListEmptyState";

type EventSpeakersTableProps = {
    agendaItems: FullAgendaItemFragment[];
    speakers: SpeakerDisplayType[];
    setSpeakerToEdit: (speakerId: string) => void;
    removeSpeakers: (ids: string[]) => void;
    readOnly: boolean;
    orderArray: string[];
    baseLink: string;
    openNewSpeaker: () => void;
};

const speakersTableHeadCells = [
    {id: "checkbox", label: "", size: "small" as TableCellSize},
    {id: "order", label: "Order", size: "unset" as TableCellSize},
    {id: "name", label: "Full name", size: "unset" as TableCellSize},
    {id: "email", label: "Email", size: "unset" as TableCellSize},
    {id: "status", label: "Status", size: "medium" as TableCellSize},
    {id: "copyLink", label: "", size: "medium" as TableCellSize},
    {id: "actions", label: "", size: "medium" as TableCellSize},
];

const sortableHeadCells: SpeakersSortField[] = ["name", "order", "email", "status"];

const EventSpeakersTable = (props: EventSpeakersTableProps) => {
    const {speakers, setSpeakerToEdit, removeSpeakers, agendaItems, readOnly, orderArray, baseLink, openNewSpeaker} = props;
    const [selected, setSelected] = useState<string[]>([]);
    const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
    const [deletingAgendaSpeakers, setDeletingAgendaSpeakers] = useState<{agendaItemId: string; speakerIds: string[]}[]>([]);
    const [sort, setSort] = useState<{
        field: SpeakersSortField;
        direction: "asc" | "desc";
    }>(defaultSpeakersSort);

    const handleSpeakerOrderChange = (id: string, mode: "increment" | "decrement") => {
        const index = orderArray.indexOf(id);

        if (index === -1) {
            return;
        }

        const newSOrderSpeakers = [...orderArray];

        if (mode === "decrement") {
            if (index === 0) {
                return;
            }

            newSOrderSpeakers[index] = newSOrderSpeakers[index - 1];
            newSOrderSpeakers[index - 1] = id;
        } else {
            if (index === newSOrderSpeakers.length - 1) {
                return;
            }

            newSOrderSpeakers[index] = newSOrderSpeakers[index + 1];
            newSOrderSpeakers[index + 1] = id;
        }

        designer.api.event.update({
            speakerOrderJson: {
                speakers: newSOrderSpeakers,
            },
        });
        designer.commit();
    };

    const sortedSpeakers = useMemo(
        () =>
            speakers
                .slice()
                .map((speaker) => {
                    const order = orderArray.indexOf(speaker.id);
                    return {
                        ...speaker,
                        order: order === -1 ? 999 : order,
                    };
                })
                .sort(createSpeakersComparator(sort)),
        [speakers, sort, orderArray]
    );

    const toggleSelectAll = () => {
        if (selected.length) {
            setSelected([]);
        } else {
            setSelected(sortedSpeakers.map((speaker) => speaker.id));
        }
    };

    const handleToggleDeleteDialog = (ids: string[]) => {
        const newState = [...deletingAgendaSpeakers];

        for (const agendaItem of agendaItems) {
            const agendaItemSpeakerIds = agendaItem.agendaItemSpeakers.map((s) => s.speakerId);

            const intersection = ids.filter((id) => agendaItemSpeakerIds.includes(id));

            if (intersection.length > 0) {
                const index = newState.findIndex((i) => i.agendaItemId === agendaItem.id);

                if (index === -1) {
                    newState.push({agendaItemId: agendaItem.id, speakerIds: intersection});
                } else {
                    newState[index].speakerIds = [...newState[index].speakerIds, ...intersection].filter(
                        (id, i, arr) => arr.indexOf(id) === i
                    );
                }
            }
        }

        if (newState !== deletingAgendaSpeakers) {
            setDeletingAgendaSpeakers(newState);
        }

        setIdsToDelete(ids);
    };

    const removeSelectedSpeakers = async (ids?: string[]) => {
        for (const item of deletingAgendaSpeakers) {
            const agendaItem = agendaItems.find((a) => a.id === item.agendaItemId);

            const speakersIdsToRemove = item.speakerIds;
            const speakersToRemove =
                agendaItem?.agendaItemSpeakers
                    .filter((s) => item.speakerIds.includes(s.speakerId))
                    .map((s) => {
                        return {
                            ...s,
                            isDeleted: s.createdAt ? true : undefined,
                        };
                    }) ?? [];

            designer.api.agendaItem.update({
                id: item.agendaItemId,
                agendaItem: {
                    agendaItemSpeakers: [
                        ...((agendaItem?.agendaItemSpeakers.filter((s) => !speakersIdsToRemove.includes(s.speakerId)) as any) ?? []),
                        ...speakersToRemove.filter((s) => s.isDeleted),
                    ],
                },
            });
        }
        if (deletingAgendaSpeakers.length) {
            await designer.commit({
                persistEvent: true,
            });
        }
        if (ids) {
            removeSpeakers(ids);
        }
        setIdsToDelete([]);
        setSelected([]);
    };

    const handleCloseDeleteDialog = () => {
        setIdsToDelete([]);
        setDeletingAgendaSpeakers([]);
    };

    return (
        <>
            <div className={classes.root}>
                {selected.length > 0 && !readOnly && (
                    <EventSpeakersTableActionHead
                        toggleSelectAll={toggleSelectAll}
                        allSelected={selected.length === sortedSpeakers.length}
                        selected={selected}
                        onToggleDeleteDialog={handleToggleDeleteDialog}
                    />
                )}

                <TableContainer className={classes.tableContainerRoot}>
                    <Table className={cls(classes.tableRoot, "fullh flex flex-col overflow-hidden")}>
                        <TableHead className={cls(classes.tableHead, selected.length ? classes.hiddenHeader : "")}>
                            <TableRow>
                                {speakersTableHeadCells
                                    .filter((s) => {
                                        return speakers.length > 1 ? true : s.id !== "order";
                                    })
                                    .map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            size={cell.size}
                                            className={cls(
                                                cell.id === "checkbox" ? classes.cellCheckbox : "",
                                                cell.id === "order" ? classes.cellOrder : ""
                                            )}
                                        >
                                            <div className="flex flex-align-center">
                                                {cell.id === "checkbox" ? (
                                                    <Checkbox
                                                        checked={Boolean(selected.length)}
                                                        onChange={toggleSelectAll}
                                                        disabled={readOnly}
                                                    />
                                                ) : (
                                                    cell.label
                                                )}
                                                {sortableHeadCells.includes(cell.id as SpeakersSortField) && (
                                                    <TableHeadSortButton
                                                        columnId={cell.id}
                                                        activeColumnId={sort.field}
                                                        direction={sort.direction}
                                                        onSort={setSort}
                                                        canSort={true}
                                                    />
                                                )}
                                            </div>
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {sortedSpeakers.length > 0 ? (
                                sortedSpeakers.map((speaker) => (
                                    <EventSpeakersTableRow
                                        key={speaker.id}
                                        speaker={speaker}
                                        setSpeakerToEdit={setSpeakerToEdit}
                                        setSelected={setSelected}
                                        selected={selected}
                                        removeSpeakers={handleToggleDeleteDialog}
                                        readOnly={readOnly}
                                        withOrderColumn={speakers.length > 1}
                                        maxOrder={(orderArray ?? []).length - 1}
                                        handleSpeakerOrderChange={handleSpeakerOrderChange}
                                        baseLink={baseLink}
                                        thisSpeakerOrder={orderArray.indexOf(speaker.id)}
                                        currentSpeakerOrderSort={sort.field === "order" ? sort.direction : undefined}
                                    />
                                ))
                            ) : (
                                <EventSpeakersListEmptyState openNewSpeaker={openNewSpeaker} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <ConfirmationDialog
                key="event-speakers-delete-event-dialog"
                title={`Remove ${getCorrectPlural("speaker", idsToDelete.length)}?`}
                content={
                    <>
                        {deletingAgendaSpeakers.length === 0 ? (
                            `Are you sure you want to remove ${idsToDelete.length === 1 ? "this" : `these`} ${getCorrectPlural(
                                "speaker",
                                idsToDelete.length
                            )}?`
                        ) : (
                            <>
                                There {deletingAgendaSpeakers.length !== 1 ? "are" : "is"} {deletingAgendaSpeakers.length} agenda{" "}
                                {getCorrectPlural("item", deletingAgendaSpeakers.length)} that will be affected by this change.
                                <br />
                                {`Are you sure you want to remove ${
                                    idsToDelete.length === 1 ? "this" : `these ${idsToDelete.length}`
                                } ${getCorrectPlural("speaker", idsToDelete.length)}?`}
                            </>
                        )}
                    </>
                }
                cancelButton="Cancel"
                minWidth
                submitButton="Remove"
                onClose={handleCloseDeleteDialog}
                open={idsToDelete.length > 0}
                submitButtonVariant="destructive-secondary"
                onConfirm={removeSelectedSpeakers.bind(null, idsToDelete)}
                variant="warning"
                isDeleteConfirmation
            />
        </>
    );
};

export default EventSpeakersTable;
