import {useEffect, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {Orientation} from "@workhorse/declarations";

function computeScreenOrientation(): Orientation {
    return window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape";
}

export function supportsScreenOrientation() {
    if (screen == null || screen.orientation == null) {
        return false;
    }
    return true;
}

export function getScreenOrientationFromApi(): Orientation {
    const orientation = screen.orientation.type;
    return orientation.startsWith("landscape") ? "landscape" : "portrait";
}

export function getScreenOrientation() {
    if (supportsScreenOrientation()) {
        return getScreenOrientationFromApi();
    } else {
        return computeScreenOrientation();
    }
}

function useDeviceOrientationStore() {
    const [orientation, setOrientation] = useState(() => getScreenOrientation());

    useEffect(() => {
        if (!supportsScreenOrientation()) {
            return;
        }

        const handleOrientionChange = () => {
            setOrientation(getScreenOrientationFromApi());
        };

        screen.orientation.addEventListener("change", handleOrientionChange);

        return () => {
            screen.orientation.removeEventListener("change", handleOrientionChange);
        };
    }, []);

    useEffect(() => {
        if (supportsScreenOrientation()) {
            return;
        }

        const handleOrientionChange = () => {
            requestAnimationFrame(() => {
                setOrientation(computeScreenOrientation());
            });
        };

        window.addEventListener("resize", handleOrientionChange);

        return () => {
            window.removeEventListener("resize", handleOrientionChange);
        };
    }, []);

    return {
        orientation,
        isPortrait: orientation === "portrait",
    };
}

export const [DeviceOrientationProvider, useDeviceOrientation] = createContextProvider(
    {
        name: "Deviceorientation",
    },
    useDeviceOrientationStore
);
