export type ActiveSpeakersScore = {[pId: string]: number | undefined};
export type ActiveSpeakersTimestamps = {[pId: string]: number | undefined};
export type PendingActiveSpeakersVolumes = {[pId: string]: number | undefined};

interface ActiveSpeakerOptions {
    speakerWeight: number;
    cutoffThreshold: number;
    silenceThreshold: number;
    takeoverRate: number;
}

const defaultOptions: ActiveSpeakerOptions = {
    speakerWeight: 0.55,
    cutoffThreshold: 0.01,
    silenceThreshold: 0.1,
    takeoverRate: 0.2,
};

export function computeActiveSpeakers(
    current: ActiveSpeakersScore,
    participantId: string,
    volume: number | null,
    options: Partial<ActiveSpeakerOptions> = {}
) {
    const opt = {...defaultOptions, ...options};

    const scores = {
        ...current,
    };

    if (volume == null) {
        volume = 0;
    }

    if (scores[participantId] == null) {
        scores[participantId] = 0;
    }

    if (volume > opt.silenceThreshold) {
        volume = 1.0;
    } else {
        volume = 0.0;
    }

    const score = scores[participantId]! * opt.speakerWeight + volume * (1 - opt.speakerWeight);
    scores[participantId] = score;

    for (const otherParticipantId in scores) {
        if (otherParticipantId === participantId) {
            continue;
        }

        const otherScore = scores[otherParticipantId]!;
        scores[otherParticipantId] = Math.max(otherScore - opt.takeoverRate * volume, 0);
    }

    if (score < opt.cutoffThreshold) {
        scores[participantId] = 0;
        return scores;
    }

    scores[participantId] = score;

    return scores;
}

export function depreciateActiveSpeakers(
    current: ActiveSpeakersScore,
    timestamps: ActiveSpeakersTimestamps,
    waitMs = 1000,
    options: Partial<ActiveSpeakerOptions> = {}
) {
    let scores = {
        ...current,
    };

    const now = Date.now();

    let changed = false;

    for (const participantId in scores) {
        const lastTimestamp = timestamps[participantId];

        if (lastTimestamp != null && now - lastTimestamp < waitMs) {
            continue;
        }

        if (scores[participantId] == null || scores[participantId] === 0) {
            continue;
        }

        changed = true;
        scores = computeActiveSpeakers(scores, participantId, 0, options);
    }

    if (!changed) {
        return current;
    }

    return scores;
}

export function pickActiveSpeaker(current: ActiveSpeakersScore, volumes: PendingActiveSpeakersVolumes) {
    const ids = Object.keys(volumes);
    const bestSpeakerId = findHighestIdInMap(current);

    if (bestSpeakerId && ids.includes(bestSpeakerId)) {
        return bestSpeakerId;
    }

    return findHighestIdInMap(volumes);
}

export function findHighestIdInMap(map: ActiveSpeakersScore | PendingActiveSpeakersVolumes) {
    let highestId: string | undefined;
    let highestScore: number = 0;

    for (const id in map) {
        const score = map[id] ?? 0;

        if (score > highestScore) {
            highestId = id;
            highestScore = score;
        }
    }

    return highestId;
}
