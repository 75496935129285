import {useUserInfo} from "@workhorse/providers/User";
import {useMemo} from "react";
import {createContextProvider} from "../utils/context";
import {Access} from "./Access";

function useAccessStore() {
    const user = useUserInfo();
    console.log("userInfo", user);
    return useMemo(() => new Access(user), [user]);
}

export const [UserAccessProvider, useUserAccess] = createContextProvider(
    {
        name: "UserAccess",
    },
    useAccessStore
);

export function useOrganizationAccessById(organizationId: string) {
    const access = useUserAccess();
    return access.organizationById(organizationId);
}

export function useWorkspaceAccessById(workspaceId: string) {
    const access = useUserAccess();
    return access.workspaceById(workspaceId);
}

export function useOrganizationAccess() {
    const access = useUserAccess();
    return access.organization();
}

export function useWorkspaceAccess() {
    const access = useUserAccess();
    return access.workspace();
}
