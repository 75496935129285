import LocalParticipantControlMenu from "../../LocalParticipantControlMenu";
import AudioSettingsOption from "./AudioSettingsOption";
import AudioTogglerOption from "./AudioTogglerOption";
import NoiseReductionOption from "./NoiseReductionOption";
import classes from "./styles/LocalAudioMenu.module.scss";

type LocalAudioMenuProps = {children: JSX.Element; disabled?: boolean; onAudioToggle?: () => void};

function LocalAudioMenu(props: LocalAudioMenuProps) {
    const {onAudioToggle, ...rest} = props;

    const options = [
        <NoiseReductionOption key="local-audio-noise-reduction" />,
        <AudioSettingsOption key="local-audio-audio-settings" />,
        <li className="separator" key="local-audio-separator" />,
        <AudioTogglerOption onAudioToggle={onAudioToggle} key="local-audio-audio-toggler" />,
    ];

    return props.disabled ? props.children : <LocalParticipantControlMenu options={options} classes={{popper: classes.popper}} {...rest} />;
}

export default LocalAudioMenu;
