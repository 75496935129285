import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import {markAsSeen} from "@workhorse/api/seen";
import {createOnboardingSession} from "@workhorse/pages/user/onboarding/api/session-onboarding";
import MyProductToolContainer from "@workhorse/pages/user/profile/MyProductTool/MyProductToolProfileContainer";
import {useUserInfo} from "@workhorse/providers/User";
import classes from "./styles/OnboardingProductToolDialog.module.scss";
import {SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";

type OnboardingProductToolDialogProps = {
    onClose?: () => void;
};

function OnboardingProductToolDialog(props: OnboardingProductToolDialogProps) {
    const {onClose} = props;

    const dialogRef = useRef<DialogImperativeRef>();

    const user = useUserInfo();

    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);
            const result = await createOnboardingSession(SessionOnboardingType.SecondStrikeOwner, user.id);
            if (result == null) {
                return;
            }
            history.push(`/session/${result.id}`);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);

            await markAsSeen({
                ONBOARDING_FEATURES: {
                    onboardingSessionSecondStrikeOwner: true,
                },
            });

            onClose?.();
        }
    };

    return (
        <Dialog key="dialog-dialog" imperativeRef={dialogRef} open={true} classes={{paper: classes.root}} onClose={onClose}>
            <div className={classes.header}>
                <Typography variant="lg" fontWeight="bold">
                    My Product tool
                </Typography>
                <Typography color="quaternary">
                    Bring your digital product directly into your sessions – it’s great for demos and training.
                </Typography>
            </div>
            <MyProductToolContainer
                classes={{
                    root: classes.productRoot,
                    body: classes.productBody,
                    footer: classes.productFooter,
                }}
                confirmDisabled={loading}
                onConfirm={handleConfirm}
                allowInitialDataSubmit
                isOnboarding={true}
            />
        </Dialog>
    );
}

export default OnboardingProductToolDialog;
