import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";

type RoomData = NonNullable<NonNullable<DesignerApiSession["session"]>["room"]>;
type UpdateRoomInput = Partial<Omit<RoomData, "id" | "__typename">>;

export class UpdateRoom extends DesignerAction<UpdateRoomInput> {
    commit() {
        const session = designer.currentSession();
        const room = session?.room;

        if (!room) {
            return "";
        }

        designer.updateCurrentSession(
            {
                session: {
                    ...session,
                    room: {
                        ...room,
                        ...this.input,
                        slug: (this.input.slug ?? room.slug)?.toLowerCase(),
                    },
                },
            },
            {
                strategy: "replace",
            }
        );

        return "";
    }
}
