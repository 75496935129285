import menuClasses from "./styles/LocalParticipantControlMenu.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import MenuList from "@ui/core/components/MenuList";

type LocalParticipantControlMenuProps = Omit<ExtractProps<typeof Tooltip>, "title"> & {
    options: JSX.Element | JSX.Element[];
    isAdmin?: boolean;
    isAssistant?: boolean;
    sessionId?: string;
    canEnable?: boolean;
    participantId?: string;
    disabled?: boolean;
};

function LocalParticipantControlMenu(props: LocalParticipantControlMenuProps) {
    const {children, options, classes: propsClasses, isAdmin, isAssistant, sessionId, canEnable, participantId, ...otherProps} = props;
    return (
        <Tooltip
            {...otherProps}
            placement="top"
            classes={{
                ...propsClasses,
                tooltip: cls(menuClasses.tooltip, propsClasses?.tooltip),
                popper: cls(menuClasses.popper, propsClasses?.popper),
            }}
            interactive
            title={
                props.disabled ? (
                    ""
                ) : (
                    <MenuList data-id="local-menu" className={menuClasses.menuList}>
                        {options}
                    </MenuList>
                )
            }
        >
            {children}
        </Tooltip>
    );
}

export default LocalParticipantControlMenu;
