import classes from "../style/GeneratedAgendaItemPreview.module.scss";
import {cls} from "@ui/cdk/util/util";
import Typography from "@ui/cdk/Typography/Typography";
import Checkbox from "@ui/cdk/Checkbox";
import {useEffect} from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";
import {GeneratedAgendaItemPayload} from "@generated/data";
import {AIGeneratedAgendaItem} from "../AgendaGeneratorMessenger";

type GeneratedAgendaItemPreviewProps = {
    agendaItem: AIGeneratedAgendaItem & {id: string};
    selectAgendaItems?: (agendaItem: AIGeneratedAgendaItem & {id: string}) => void;
    selectedAgendaItems?: Array<Omit<GeneratedAgendaItemPayload, "__typename"> & {id: string}>;
    isSingleAgendaItem: boolean;
    hideCheckbox?: boolean;
} & WithMobileState;

const GeneratedAgendaItemPreview = (props: GeneratedAgendaItemPreviewProps) => {
    const {agendaItem, selectAgendaItems, selectedAgendaItems, isSingleAgendaItem, hideCheckbox} = props;

    const isSelected = selectedAgendaItems?.some((selectedAgendaItem) => selectedAgendaItem.id === agendaItem.id);

    const {isMobile} = props.mobileState;

    const handleToggleSelect = () => {
        selectAgendaItems?.(agendaItem);
    };

    useEffect(() => {
        const el = document.getElementById("agenda-preview-scroll-section");
        if (!el) {
            return;
        }
        el.scrollTop = el.scrollHeight;
    }, []);

    return (
        <div className="flex flex-row flex-align-start my-12 gap-15">
            {!isSingleAgendaItem && !hideCheckbox && (
                <Checkbox
                    data-id="agenda-checkbox"
                    className={classes.checkbox}
                    checked={isSelected}
                    onTouchEnd={isMobile ? (hideCheckbox ? undefined : handleToggleSelect) : undefined}
                    onChange={isMobile ? undefined : hideCheckbox ? undefined : handleToggleSelect}
                />
            )}
            <div
                className={cls(
                    classes.agendaItemWrapper,
                    "flex flex-col flex-align-start fullw",
                    isSelected && classes.selectedAgendaItem,
                    isSingleAgendaItem && classes.agendaItemWrapperisSingleItem
                )}
                onClick={!isSingleAgendaItem && !hideCheckbox ? handleToggleSelect : undefined}
            >
                <div className={cls("flex flex-row flex-justify-between fullw mb-12")}>
                    <Typography variant="base" fontWeight="boldest" className="mb-4">
                        {agendaItem.name}
                    </Typography>
                    <Typography variant="sm" color="nonary">
                        {agendaItem.duration}:00
                    </Typography>
                </div>
                <Typography variant="sm" color="nonary">
                    {agendaItem.description}
                </Typography>
            </div>
        </div>
    );
};

export default GeneratedAgendaItemPreview;
