import {useUserInfo} from "@workhorse/providers/User";
import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {createContextProvider} from "../../../utils/context";
import {useConferenceRoomCreateOrDelete} from "../ConferenceRoomProvider";

function useJoinedFromOtherDeviceStore() {
    const [joinedFromOtherDevice, setJoinedFromOtherDevice] = useState(false);
    const history = useHistory();
    const {isGuest} = useUserInfo();

    const setJoinedFromOtherDeviceWrapper = useCallback(
        (value: boolean) => {
            if (value) {
                if (isGuest) {
                    history.push("/disconnect", {
                        skipBlock: true,
                        joinedFromOtherDevice: true,
                    });
                } else {
                    history.push("/", {
                        skipBlock: true,
                        joinedFromOtherDevice: true,
                    });
                }
            }
            setJoinedFromOtherDevice(value);
        },
        [isGuest]
    );

    return {
        joinedFromOtherDevice,
        setJoinedFromOtherDevice: setJoinedFromOtherDeviceWrapper,
    };
}

export const [JoinedFromOtherDeviceProvider, useJoinedFromOtherDevice] = createContextProvider(
    {
        name: "JoinedFromOtherDevice",
    },
    useJoinedFromOtherDeviceStore
);
