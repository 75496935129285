import MicroControllerInjector, {configurableArtifacts} from "@workhorse/pages/player/microController/MicroControllerInjector";
import DetachFileOption from "./DetachFileOption";
import PreviewFileOption from "./PreviewFileOption";
import classes from "./styles/ToolMenuItems.module.scss";
import ToolControlMenu from "./ToolControlMenu";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {MobileState} from "@workhorse/declarations";
import {ArtifactTag} from "@generated/artifacts";
import AdminMicroController from "@workhorse/pages/player/microController/AdminMicroController";
import {Fragment, useEffect} from "@workhorse/api/rendering";
import browserInfo from "@workhorse/api/BrowserInfo";
import designer from "@workhorse/api/designer";
import {OnboardingToolMenu} from "@workhorse/components/onboarding-tooltips/onboarding-tool-menu";
import {cls} from "@ui/cdk/util";

type ToolMenuItemsProps = {
    children: JSX.Element;
    disabled?: boolean;
    onDetach: () => void;
    onPreview: () => void;
    onChangeFile: () => void;
    session: SessionData;
    isAssistant: boolean;
    mobileState: MobileState;
    inLobby: boolean;
    currentMicroTag: ArtifactTag;
    hasAgenda: boolean;
    disablePreview?: boolean;
    handleClose: () => void;
    onOpenTooltip?: () => void;
    onCloseTooltip?: () => void;
    placement?:
        | "top-start"
        | "top"
        | "top-end"
        | "right-start"
        | "right"
        | "right-end"
        | "bottom-start"
        | "bottom"
        | "bottom-end"
        | "left-start"
        | "left"
        | "left-end";
    onlyCurrentAgendaItem?: boolean;
    openOnClick?: boolean;
    className?: string;
};

function ToolMenuItems(props: ToolMenuItemsProps) {
    const {
        disabled,
        children,
        onChangeFile,
        onDetach,
        onPreview,
        session,
        isAssistant,
        mobileState,
        inLobby,
        currentMicroTag,
        hasAgenda,
        handleClose,
        disablePreview,
        onlyCurrentAgendaItem,
        className,
        ...rest
    } = props;

    const hasConfigurableArtifactsInAgenda = configurableArtifacts.includes(currentMicroTag);

    useEffect(() => {
        const fileAndToolShortcuts = (e: KeyboardEvent) => {
            const key = browserInfo.isMacOS() ? (e.code ? e.code.toLowerCase() : undefined) : e.key ? e.key.toLowerCase() : undefined;
            if (!e.altKey || (key && !["b", "keyb"].includes(key))) {
                return;
            }
            switch (key) {
                case "b":
                case "keyb": {
                    if (!designer.state.getSnapshot().configurationConfirm) {
                        onDetach();
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        };
        document.body.addEventListener("keydown", fileAndToolShortcuts);

        return () => {
            document.body.removeEventListener("keydown", fileAndToolShortcuts);
        };
    }, [onDetach]);

    const options = [
        <Fragment key="in-lobby">
            {inLobby && (
                <>
                    <PreviewFileOption disabled={disablePreview} onClick={onPreview} key="preview-file-option" />
                    <li className="separator" key="tool-separator-1" />

                    {hasAgenda ? (
                        <MicroControllerInjector
                            key={`micro-controller-injector-${session.id}`}
                            inLobby={inLobby}
                            mobileState={mobileState}
                            isAssistant={isAssistant}
                            session={session}
                            hasAgenda={hasAgenda}
                            handleCloseParentMenu={handleClose}
                        />
                    ) : hasConfigurableArtifactsInAgenda ? (
                        <AdminMicroController
                            key={`admin-micro-controller-${session.id}`}
                            isAssistant={isAssistant}
                            session={session}
                            mobileState={mobileState}
                            inLobby={inLobby}
                            hasAgenda={hasAgenda}
                            onlyAgendaItemWithThisOrder={session.order}
                        />
                    ) : null}
                </>
            )}
        </Fragment>,

        <Fragment key="not-lobby">
            {!inLobby && hasConfigurableArtifactsInAgenda && (
                <AdminMicroController
                    key={`admin-micro-controller-${session.id}`}
                    isAssistant={isAssistant}
                    session={session}
                    mobileState={mobileState}
                    inLobby={inLobby}
                    hasAgenda={hasAgenda}
                    onlyAgendaItemWithThisOrder={session.order}
                />
            )}
        </Fragment>,
        // <Fragment key="has-cfg">{hasConfigurableArtifactsInAgenda && <li className="separator" key="tool-separator-2" />}</Fragment>,

        // <OnboardingToolMenu key="tooltip-detach-file-option" placement="right" variant="stop-share">
        //     <DetachFileOption currentMicroTag={currentMicroTag} onClick={onDetach} key="detach-file-option" />
        // </OnboardingToolMenu>,
    ];

    return disabled ? (
        children
    ) : (
        <ToolControlMenu options={options} classes={{popper: cls(classes.popper, className)}} placement={props.placement} {...rest}>
            {children}
        </ToolControlMenu>
    );
}

export default ToolMenuItems;
