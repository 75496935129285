import {ExitPageCtaType, GetOffersDocument, GetRemoteUserDocument, GetSessionSettingsDocument, OfferInput} from "@generated/data";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Button from "@ui/cdk/Button";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import {useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import Loading from "@workhorse/components/Loading";
import MacroHeader from "@workhorse/components/header/MacroHeader";
import {useMutation, useQuery} from "@workhorse/dataApi";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useTranslation} from "react-i18next";
import {ReactComponent as FolderIcon} from "./assets/folder_offers_outline.svg";
import ImportOfferList from "./components/ImportOfferList";
import NewOfferWrapper from "./components/NewOfferWrapper";
import OfferCard from "./components/OfferCard";
import OfferEmptyState from "./components/OfferEmptyState";
import classes from "./styles/OffersContainer.module.scss";
import common from "../../styles/UserSettingsCommons.module.scss";
import {useUserInfo} from "@workhorse/providers/User";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type OffersContainerProps = {
    isMacro: boolean;
    isOrganizer: boolean;
    isMemoryMode: boolean;
    isMobile: boolean;
    userId: string;
};

const OffersContainer = (props: OffersContainerProps) => {
    const {isMacro, isOrganizer, isMemoryMode, isMobile, userId} = props;
    const {t} = useTranslation();
    const {mixpanelTrack} = useMixpanelTracker();

    const [newOfferData, setNewOfferData] = useState<{
        title: string;
        description: string;
        ctaUrl: string;
        ctaText: string;
        imageUrl: string;
    } | null>(null);

    const [selectedOffer, setSelectedOffer] = useState<string | null>("");
    const [newOfferOpen, setNewOfferOpen] = useState(false);
    const [shareOfferOpen, setShareOfferOpen] = useState(false);
    const [selectedOffers, setSelectedOffers] = useState<string[]>([]);

    const scrollContainerRef = useRef<ScrollContainerRef>({});
    const myOffersBodyRef = useRef<HTMLDivElement | null>(null);
    const mainContainerRef = useRef<HTMLDivElement>(null);

    const session = useSession();
    const user = useUserInfo();
    const {data, loading} = useQuery("GetOffersDocument", {
        skip: isMemoryMode,
    });

    const {data: sessionOffers, loading: sessionOffersLoading} = useQuery("GetSessionOffersDocument", {
        variables: {
            sessionId: session?.id,
        },
        skip: !session?.id,
    });

    const [createOneOffer] = useMutation("CreateOfferDocument");
    const [updateOneOffer] = useMutation("UpdateOfferDocument");
    const [deleteOneOffer] = useMutation("DeleteOfferDocument");
    const [addOffersInSession] = useMutation("AddOffersInSessionDocument");

    function closeNewOffer() {
        setSelectedOffer(null);
        setNewOfferOpen(false);
    }

    function onAddNewOffer() {
        setSelectedOffer(null);
        setNewOfferOpen(true);
        mixpanelTrack("frontend-create-offer", "user");
    }

    function onAddExistingOffer() {
        setShareOfferOpen(true);
    }

    function onCloseShareOffer() {
        setNewOfferOpen(false);
        setShareOfferOpen(false);
    }

    function onEditOffer(offerId: string) {
        setSelectedOffer(offerId);
        setNewOfferOpen(true);
    }

    async function onCreateOffer(offer: OfferInput) {
        await createOneOffer({
            variables: {
                offer: {
                    ...offer,
                },
                sessionId: session?.id,
            },
        })
            .then(async (res) => {
                if (res.data?.createOneOffer) {
                    apollo.client.writeQuery({
                        query: GetOffersDocument,
                        data: {
                            ...data?.userOffers,
                            userOffers: [...(data?.userOffers ?? []), res?.data?.createOneOffer],
                        },
                    });

                    return toast("Your offer was created successfully.", {
                        type: "success",
                    });
                } else {
                    console.log(res);
                    return toast("Error on creating the offer. Please try again.", {
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log("error on creating offer", err);
                return toast("Error on creating the offer. Please try again.", {
                    type: "error",
                });
            });
    }

    async function onUpdateOffer(offerId: string, offer: OfferInput, shouldPublish: boolean) {
        await updateOneOffer({
            variables: {
                offerId: offerId,
                offer: {
                    ...offer,
                },
                sessionId: shouldPublish ? session?.id : undefined,
            },
        })
            .then(async (res) => {
                if (res.data?.updateOneOffer?.id) {
                    apollo.client.writeQuery({
                        query: GetOffersDocument,
                        data: {
                            ...data?.userOffers,
                            userOffers:
                                data?.userOffers?.map((offer) => (offer?.id === offerId ? res?.data?.updateOneOffer! : offer!)) ?? [],
                        },
                    });

                    return toast("Your offer was updated successfully.", {
                        type: "success",
                    });
                } else {
                    console.log(res);
                    return toast("Error on updating the offer. Please try again.", {
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log("error on updating offer", err);
                return toast("Error on updating the offer. Please try again.", {
                    type: "error",
                });
            });
    }

    async function onDeleteOffer(offerId: string) {
        const offerToDelete = data?.userOffers?.find((offer) => offer?.id === offerId);

        if (offerToDelete) {
            await deleteOneOffer({variables: {offerId: offerToDelete?.id}})
                .then((res) => {
                    if (res?.data?.deleteOneOffer?.id) {
                        apollo.cache.evict({
                            id: apollo.cache.identify(offerToDelete),
                        });

                        const existing = apollo.client.readQuery({
                            query: GetSessionSettingsDocument,
                            variables: {
                                workspaceId: user.activeWorkspace.id,
                            },
                        });
                        const updatedTemplates = existing?.sessionSettings?.map((templateSettings) => {
                            if (templateSettings.exitPageOfferId === offerId) {
                                return {
                                    ...templateSettings,
                                    exitPageOfferId: null,
                                    showExitPageCta: ExitPageCtaType.SessionsCta,
                                };
                            }

                            return templateSettings;
                        });

                        apollo.client.writeQuery({
                            query: GetSessionSettingsDocument,
                            variables: {
                                workspaceId: user.activeWorkspace.id,
                            },
                            data: {
                                ...existing,
                                sessionSettings: updatedTemplates ?? [],
                            },
                        });

                        return toast("Your offer was deleted successfully.", {
                            type: "success",
                        });
                    } else {
                        console.log(res);
                        return toast("Error on deleting the offer. Please try again.", {
                            type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log("error on deleting offer", err);
                    return toast("Error on deleting the offer. Please try again.", {
                        type: "error",
                    });
                });
        }
    }

    function handleSelectOffer(event: React.ChangeEvent<HTMLInputElement>, id: string) {
        if (event.target.checked) {
            setSelectedOffers([...selectedOffers, id]);
        } else {
            setSelectedOffers(selectedOffers.filter((offerId) => offerId !== id));
        }
    }

    async function handleImportOffers() {
        await addOffersInSession({
            variables: {
                sessionId: session?.id,
                offersIds: selectedOffers,
            },
        })
            .then((res) => {
                if (res?.data?.addOffersInSession) {
                    setSelectedOffers([]);
                    setShareOfferOpen(false);
                    return toast("Your offers have been imported successfully.", {
                        type: "success",
                    });
                } else {
                    console.log(res);
                    return toast("Something went wrong. Please try again later.", {
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                return toast("Something went wrong. Please try again later.", {
                    type: "error",
                });
            });
    }

    function handleCancelImport() {
        setShareOfferOpen(false);
        setSelectedOffers([]);
    }

    const useDataFromUser = !isMemoryMode && (!isMacro || (isMacro && shareOfferOpen));

    const offersToRender = useDataFromUser ? data?.userOffers : sessionOffers?.sessionOffers;
    const selectedOfferIsPublished = sessionOffers?.sessionOffers?.find((offer) => offer?.id === selectedOffer) ? true : false;

    if (loading || sessionOffersLoading) {
        return <Loading />;
    }

    return (
        <div className={classes.root}>
            <div
                className={cls(
                    classes.offersContainer,
                    !isMacro && common.root,
                    isMacro && classes.offersContainerMacro,
                    isMobile && !shareOfferOpen && !newOfferOpen && classes.offersContainerMobile
                )}
                ref={mainContainerRef}
            >
                {!isMacro ? (
                    <div className={cls("flex fullw flex-align-center flex-justify-between")}>
                        <div className="flex flex-col flex-justify-start mr-auto">
                            <Typography variant="xl" color="primary" fontWeight="bolder">
                                {t("macro.offers.title") ?? "Offers"}
                            </Typography>
                            <Typography variant="base" color="tertiary" className="mt-5">
                                Manage your offers
                            </Typography>
                        </div>

                        {!newOfferOpen && (
                            <Button onClick={onAddNewOffer} variant="secondary" startIcon={<AddRoundedIcon />} size="small">
                                {t("macro.offers.create_offer") ?? "Create offer"}
                            </Button>
                        )}
                    </div>
                ) : (
                    <>
                        {!isMobile && (
                            <div className={classes.offerMacroHeaderWrapper}>
                                <MacroHeader
                                    onBack={onCloseShareOffer}
                                    withBackButton={(!!selectedOfferIsPublished && shareOfferOpen) || (shareOfferOpen && !newOfferOpen)}
                                    marginLess
                                    playerRadius
                                    title={shareOfferOpen ? t("macro.offers.my_offers") : t("macro.offers.title") ?? "Offers"}
                                    artifactId={"offers"}
                                    isMemoryMode={isMemoryMode}
                                />
                            </div>
                        )}
                    </>
                )}
                <ScrollContainer
                    component="div"
                    id="my-offers-body"
                    className={cls("relative fullh flex flex-col flex11-auto rnd-btn", classes.offersScrollContainer)}
                    connector={scrollContainerRef}
                    ref={myOffersBodyRef}
                    key={"my-offers-scroll-container"}
                    shadowBorderRadius={true}
                    shadowGradient="white"
                    scrollBarOffsetTop={10}
                    scrollBarOffsetBottom={10}
                    isMobile={isMobile}
                >
                    {(!newOfferOpen && isMacro && !shareOfferOpen) || (!isMacro && !shareOfferOpen) ? (
                        <>
                            {Array.isArray(offersToRender) && offersToRender?.length ? (
                                <div
                                    className={cls("flex flex-col flex-align-center gap-24 ", isMacro && classes.offersListContainerMacro)}
                                >
                                    {Array.isArray(offersToRender) &&
                                        offersToRender?.map((offer) => (
                                            <OfferCard
                                                key={offer.id}
                                                id={offer.id}
                                                title={offer.title}
                                                description={offer.description}
                                                imageUrl={offer.imageUrl}
                                                ctaUrl={offer.ctaUrl}
                                                ctaText={offer.ctaText}
                                                onEditOffer={onEditOffer}
                                                onDeleteOffer={onDeleteOffer}
                                                isMacro={isMacro}
                                                shareOfferOpen={shareOfferOpen}
                                                sessionId={session?.id}
                                                isOrganizer={isOrganizer}
                                                isMemoryMode={isMemoryMode}
                                                offerUserId={offer.userId}
                                                userId={userId}
                                            />
                                        ))}
                                </div>
                            ) : (
                                <OfferEmptyState isOrganizer={isOrganizer} />
                            )}
                        </>
                    ) : shareOfferOpen && isMacro && !newOfferOpen ? (
                        <ImportOfferList
                            userOffers={data?.userOffers}
                            sessionOffers={sessionOffers?.sessionOffers}
                            onEditOffer={onEditOffer}
                            selectedOffers={selectedOffers}
                            onSelectOffer={handleSelectOffer}
                        />
                    ) : (
                        <NewOfferWrapper
                            mode={selectedOffer ? "edit" : "add"}
                            newOfferData={newOfferData}
                            setNewOfferData={setNewOfferData}
                            onClose={closeNewOffer}
                            offerId={selectedOffer}
                            offers={data?.userOffers || []}
                            onCreateOffer={onCreateOffer}
                            onUpdateOffer={onUpdateOffer}
                            isMacro={isMacro}
                            selectedOfferIsPublished={selectedOfferIsPublished}
                        />
                    )}
                </ScrollContainer>
                {isOrganizer && isMacro && !isMemoryMode && !newOfferOpen && (
                    <div className={cls("flex flex-row py-12 px-16 gap-10 flex-justify-between", classes.offersContainerFooter)}>
                        {!shareOfferOpen ? (
                            <div className="flex flex-row fullw gap-10">
                                <div className="fullw">
                                    <Button
                                        fullWidth
                                        startIcon={<AddRoundedIcon />}
                                        variant="tertiary"
                                        onClick={onAddNewOffer}
                                        size="small"
                                    >
                                        {t("macro.offers.create_offer") ?? "Create offer"}
                                    </Button>
                                </div>
                                <div className="fullw">
                                    <Button
                                        fullWidth
                                        startIcon={<FolderIcon />}
                                        variant="tertiary"
                                        onClick={onAddExistingOffer}
                                        size="small"
                                    >
                                        {t("macro.offers.my_offers") ?? "My offers"}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                {isMobile && (
                                    <Button fullWidth variant="plain" onClick={handleCancelImport}>
                                        {t("g.cancel")}
                                    </Button>
                                )}
                                {data?.userOffers?.length ? (
                                    <Button
                                        variant="primary"
                                        fullWidth={!isMobile}
                                        disabled={!selectedOffers.length}
                                        onClick={handleImportOffers}
                                    >
                                        {t("g.import") ?? "Import"}
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        startIcon={<AddRoundedIcon />}
                                        variant="tertiary"
                                        onClick={onAddNewOffer}
                                        size="small"
                                    >
                                        {t("macro.offers.create_offer") ?? "Create offer"}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
            {newOfferOpen && !isMacro && (
                <NewOfferWrapper
                    mode={selectedOffer ? "edit" : "add"}
                    newOfferData={newOfferData}
                    setNewOfferData={setNewOfferData}
                    onClose={closeNewOffer}
                    offerId={selectedOffer}
                    offers={data?.userOffers || []}
                    onCreateOffer={onCreateOffer}
                    onUpdateOffer={onUpdateOffer}
                    isMacro={isMacro}
                    selectedOfferIsPublished={selectedOfferIsPublished}
                />
            )}
        </div>
    );
};

export default OffersContainer;
