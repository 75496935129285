import {useMemo} from "@workhorse/api/rendering";
import {OnboardingFeature, SeenSegment, useSeenSection, useSeenSections} from "@workhorse/api/seen";
import {MemberFeatures, OwnerFeatures, useFeaturesOnboardingConfiguration} from "./provider";
import {useUserInfo} from "@workhorse/providers/User";

export function useOnboardingFeature(feature: OnboardingFeature) {
    const {configuration} = useFeaturesOnboardingConfiguration();
    const cfg = useMemo(() => Object.entries(configuration).find(([feat]) => feat === feature), [feature, configuration]);

    if (!cfg) {
        return false;
    }

    return cfg[1];
}

export function useOnboardingFeaturesJourney(configuration: OwnerFeatures | MemberFeatures): false | number {
    const seenKeys = useMemo(
        () =>
            Object.entries(configuration)
                .filter(([k, v]) => v.quest)
                .map(([key]) => `ONBOARDING_FEATURES.${key}` as Exclude<SeenSegment, "GLOBALS.incident">),
        [configuration]
    );

    const seenList = useOnboardingFeaturesSeen(seenKeys);
    const journey = (seenList.reduce((acc, seen) => acc + (seen ? 1 : 0), 0) * 100) / seenList.length;

    return journey;
}

export function useOnboardingFeatureSeen(segment: Exclude<SeenSegment, "GLOBALS.incident">) {
    const user = useUserInfo();

    const seen = useSeenSection(segment);

    if (segment === "ONBOARDING_FEATURES.onboardingWorkspaceSetup") {
        return !!user.activeOrganizationPermission.organization.isActivated;
    }

    return seen;
}

export function useOnboardingFeaturesSeen(segments: Exclude<SeenSegment, "GLOBALS.incident">[]) {
    const user = useUserInfo();

    const seen = useSeenSections(segments);

    const seenSegments = seen.map((x, i) => {
        if (segments[i] === "ONBOARDING_FEATURES.onboardingWorkspaceSetup") {
            return !!user.activeOrganizationPermission.organization.isActivated;
        }

        return x;
    });

    return seenSegments;
}
