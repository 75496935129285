import Typography from "@ui/cdk/Typography";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import {AudienceParticipantName} from "../../session-view-components/session-audience/audience-participant-components/AudienceParticipantName";
import ConferenceParticipantStatus from "../../session-view-components/session-audience/audience-participant-components/ConferenceParticipantStatus";
import {participantIsAssistant, participantIsAdmin} from "@workhorse/api/conference2";
import {cls} from "@ui/cdk/util";
import classes from "./styles/SpeakerPopupParticipantInfo.module.scss";

type SpeakerPopupParticipantInfo = {
    participantId: string;
    isLocalParticipant: boolean;
};

function SpeakerPopupParticipantInfo(props: SpeakerPopupParticipantInfo) {
    const {participantId, isLocalParticipant} = props;

    const participant = useParticipant({participantId});

    const firstName = participant?.dataWithNullableEmail.firstName;
    const lastName = participant?.dataWithNullableEmail.lastName;
    const email = participant?.dataWithNullableEmail.email;

    const participantFirstName = firstName;
    const participantLastName = lastName;

    const participantName = `${participantFirstName} ${participantLastName}`.trim();

    return (
        <Typography variant="xs" component="div" className={cls("flex flex-center-all", classes.root)}>
            <AudienceParticipantName
                participantDisplayName={participantName}
                participantIsAssistant={participantIsAssistant(participant)}
                participantIsOwner={participantIsAdmin(participant)}
            />

            <ConferenceParticipantStatus isLocalParticipant={isLocalParticipant} participantId={participantId} />
        </Typography>
    );
}

export default SpeakerPopupParticipantInfo;
