import {getReactionsList} from "../../../Reactions/api";
import LocalParticipantControlMenu from "../../LocalParticipantControlMenu";
import RaiseHandTogglerOption from "./RaiseHandTogglerOption";
import classes from "./styles/ReactionsMenu.module.scss";

type ReactionsMenuProps = {children: JSX.Element; disabled?: boolean};

function ReactionsMenu(props: ReactionsMenuProps) {
    const {children, disabled} = props;

    const options = [
        ...getReactionsList(),
        <div className="separator" key="reactions-separator" />,
        <RaiseHandTogglerOption key="reactions-raise-hand-toggler" />,
    ];

    return disabled ? children : <LocalParticipantControlMenu options={options} classes={{popper: classes.popper}} {...props} />;
}

export default ReactionsMenu;
