import {SessionUpdateInput} from "../../generated/data-types";
import {Session, OmitTypenames} from "../types";

type RoomInput = OmitTypenames<NonNullable<NonNullable<NonNullable<Session>["session"]>["room"]>>;
type RoomUpdatePayload = NonNullable<SessionUpdateInput["room"]>;

const eventUpdateKeys: Array<keyof RoomInput> = ["active", "slug"];

function buildRoomUpdatePayload(input: RoomInput): RoomUpdatePayload {
    const payload: RoomUpdatePayload["update"] = {};
    eventUpdateKeys.forEach((k) => {
        switch (k) {
            case "active": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }
            case "slug": {
                if (k in input) {
                    payload[k] = {
                        set: input[k],
                    };
                }
                break;
            }

            default:
                break;
        }
    });
    return {
        update: payload,
    };
}

export default buildRoomUpdatePayload;
