import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {ReactComponent as BulletListIcon} from "../../../assets/media/bullet-list.svg";
import {ReactComponent as ToolsIcon} from "../../../assets/media/navbar-left-icons/tools.svg";
import classes from "./styles/OnboardingNewUserDialog.module.scss";

import MicOnIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import VideoOnIcon from "@material-ui/icons/Videocam";
import VideoOffIcon from "@material-ui/icons/VideocamOff";
import {areDummyDevices, enumerateDevicesSafely, useDeviceManagerEvents, useDevices} from "@workhorse/api/conference2";
import Loading from "@workhorse/components/Loading";
import {ReactComponent as IconFile} from "../../../components/command-palette/icons/share-file.svg";
import {ReactComponent as IconEmbedTool} from "../../../components/command-palette/icons/embed-tool.svg";
import {ReactComponent as IconOpenLink} from "../../../components/command-palette/icons/open-link.svg";

type OnboardingNewUserDialogProps = {
    canSkip?: boolean;
};

enum DialogStates {
    initial = "initial",
    requestPermission = "request-permission",
    permissionDenied = "permission-denied",
}

const cardsList = [
    {
        title: "Share files",
        description: "",
        icon: <IconFile />,
        color: "",
    },
    {
        title: "Open links",
        description: "",
        icon: <IconOpenLink />,
        color: "",
    },
    {
        title: "Embed tools",
        description: "",
        icon: <IconEmbedTool />,
        color: "",
    },
];

const OnboardingNewUserDialog = (props: OnboardingNewUserDialogProps) => {
    const {start, devices} = useDevices();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [dialogState, setDialogState] = useState<DialogStates>(DialogStates.initial);
    const [requested, setRequested] = useState<boolean>(false);
    const [hasPermissions, setHasPermissions] = useState<boolean>(false);

    console.log("devices", devices);

    useDeviceManagerEvents({
        listen: true,

        onInitError: handlePermissionsClosed,
        onAudioError: handlePermissionsClosed,
        onVideoError: handlePermissionsClosed,

        onInitNotAllowedError: handlePermissionDenied,
        onAudioNotAllowedError: handlePermissionDenied,
        onVideoNotAllowedError: handlePermissionDenied,

        onInitSuccess() {
            handlePermissionsSuccess();
        },
        onAudioSuccess() {
            handlePermissionsSuccess();
        },
        onVideoSuccess() {
            handlePermissionsSuccess();
        },
    });

    const {tooltip, flow, completedFlows, next, nextFlow, readSavedStep} = useOnboardingSession();
    const dialogRef = useRef<DialogImperativeRef>();

    const handleStartOnboarding = useCallback(() => {
        const savedStep = readSavedStep() || 0;

        if (flow && completedFlows.includes(flow)) {
            nextFlow({
                nextTooltip: 0,
            });
            setIsOpen(false);
            return;
        }

        if (flow === "initial-flow" && savedStep === 0) {
            next({delay: 1000, saveTooltip: true, nextTooltip: 1});
        } else {
            next({delay: 1000, nextTooltip: savedStep});
        }

        setIsOpen(false);
    }, [readSavedStep, completedFlows, flow, nextFlow, next]);

    useEffect(() => {
        checkForPermissions();
    }, []);

    function handlePermissionsSuccess() {
        setHasPermissions(true);
        if (dialogState === DialogStates.requestPermission) {
            handleStartOnboarding();
        }
    }

    async function checkForPermissions() {
        const currentDevices = await enumerateDevicesSafely();

        if (!(currentDevices.length === 0 || areDummyDevices(currentDevices))) {
            setHasPermissions(true);
        }

        setIsLoading(false);
    }

    const handleClick = () => {
        if (hasPermissions) {
            start();
            handleStartOnboarding();
            setIsOpen(false);
        } else {
            if (dialogState === DialogStates.initial) {
                setDialogState(DialogStates.requestPermission);
            }
        }
    };

    const handleRequestPermission = () => {
        if (!hasPermissions) {
            start();
            setRequested(true);
        } else {
            handleStartOnboarding();
            setIsOpen(false);
        }
    };

    function handlePermissionsClosed() {
        setDialogState(DialogStates.permissionDenied);
    }

    function handlePermissionDenied() {
        setDialogState(DialogStates.permissionDenied);
    }

    let content = (
        <>
            <div className="mb-30 ">
                <Typography className={cls("mb-16", classes.titleOnboarding)}>
                    <span>👋</span> Get a taste of what a real session would be like
                </Typography>
                <Typography color="blueGray400" fontWeight="bold">
                    In the next step, you’ll enter a private session that was specially designed to help you get to know Sessions.
                </Typography>
            </div>
            <div className="mb-24 fullw flex flex-col">
                {cardsList.map((card, index) => (
                    <div className={cls("flex flex-row items-center", classes.card)} key={index}>
                        <div className={classes.cardIcon}>{card.icon}</div>

                        <div className="flex flex-col">
                            <Typography className="mb-4" variant="lg">
                                {card.title}
                            </Typography>
                            <Typography className={classes.description} color="secondary">
                                {card.description}
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
            <Button onClick={handleClick} className="fullw" id="onboarding-lets-try-it">
                Let&apos;s try it
            </Button>
        </>
    );

    switch (dialogState) {
        case "request-permission": {
            content = (
                <>
                    <div className="mb-30 ">
                        <Typography className={cls("mb-16", classes.titleOnboarding)}>
                            <span>📹</span> Allow access to your devices
                        </Typography>
                        <Typography color="blueGray400" fontWeight="bold">
                            You’ll be alone in this session, but if you switch on your camera and microphone, you’ll get an idea of what a
                            real session would be like.{" "}
                        </Typography>
                    </div>
                    <div className={classes.cardBig}>
                        <div className={classes.icon}>
                            <MicOnIcon />
                        </div>

                        <div className={classes.icon}>
                            <VideoOnIcon />
                        </div>
                    </div>

                    <Button onClick={handleRequestPermission} disabled={requested} className="fullw">
                        Request permissions
                    </Button>
                </>
            );
            break;
        }

        case "permission-denied": {
            content = (
                <>
                    <div className="mb-30 ">
                        <Typography className={cls("mb-16", classes.titleOnboarding)}>
                            <span>🚫</span> Cannot access devices
                        </Typography>
                        <Typography color="blueGray400" fontWeight="bold">
                            We recommend using your camera and microphone, but it’s okay if you want to continue without them.
                        </Typography>
                    </div>
                    <div className={classes.cardBig}>
                        <div className={cls(classes.icon, "disabled")}>
                            <MicOffIcon />
                        </div>

                        <div className={cls(classes.icon, "disabled")}>
                            <VideoOffIcon />
                        </div>
                    </div>

                    <div className="fullw flex">
                        <Button
                            onClick={handleStartOnboarding}
                            className="flex flex11-auto"
                            variant="secondary"
                            id="onboarding-cannot-access-devices-continue-without"
                        >
                            Continue without devices
                        </Button>

                        {/* // TODO: Octav */}
                        {/* <Button
                            onClick={handleRequestPermission}
                            className="flex flex11-auto ml-30"
                            //  disabled={permissions === "denied"}
                            id="onboarding-cannot-access-devices-try-again"
                        >
                            Try again
                        </Button> */}
                    </div>
                </>
            );
            break;
        }
    }

    return isLoading ? (
        <Loading />
    ) : isOpen ? (
        <Dialog
            imperativeRef={dialogRef}
            open={isOpen}
            classes={{paper: classes.paper, root: classes.root}}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={cls("fullw", classes.content)}>
                <div className={cls("fullw", classes.container)}>{content}</div>
            </div>
        </Dialog>
    ) : null;
};

export default OnboardingNewUserDialog;
