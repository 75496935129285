import {cls} from "@ui/cdk/util";
import {CSSProperties, ReactNode, useState} from "@workhorse/api/rendering";
import classes from "./DraggableDialog.module.scss";
import {DraggableDialogPosition, DraggableDialogSize} from "./useDraggableDialog";

function getWrapperStyle(
    pointerEvents: boolean,
    gap: number,
    defaultWidth: number,
    defaultHeight: number,
    currentWidth?: number,
    currentHeight?: number
) {
    let wrapperStyle: CSSProperties;
    if (currentWidth && currentHeight) {
        wrapperStyle = {
            top: `calc(-${currentHeight - gap}px)`,
            left: `calc(-${currentWidth - gap}px)`,
            width: `calc(100vw + ${2 * (currentWidth - gap)}px)`,
            height: `calc(100vh + ${2 * (currentHeight - gap)}px)`,
        };
    } else {
        wrapperStyle = {
            top: `calc(-${defaultHeight - gap}px)`,
            left: `calc(-${defaultWidth - gap}px)`,
            width: `calc(100vw + ${2 * (defaultWidth - gap)}px)`,
            height: `calc(100vh + ${2 * (defaultHeight - gap)}px)`,
        };
    }

    wrapperStyle.pointerEvents = pointerEvents ? "none" : undefined;

    return wrapperStyle;
}

interface DraggableDialogWrapperProps {
    id: string;

    gap: number;
    pointerEvents: boolean;

    defaultWidth: number;
    defaultHeight: number;

    size?: DraggableDialogSize;
    position?: DraggableDialogPosition;

    children?: ReactNode;

    className?: string;
}

export function DraggableDialogWrapper(props: DraggableDialogWrapperProps) {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    return (
        <>
            <div
                ref={setRef}
                id={props.id}
                className={classes.bounds}
                style={getWrapperStyle(
                    props.pointerEvents,
                    props.gap,
                    props.defaultWidth,
                    props.defaultHeight,
                    props.size?.width,
                    props.size?.height
                )}
            />
            {ref != null && (
                <div data-id="speaker-popup" className={cls(classes.wrapper, props.className)}>
                    {props.children}
                </div>
            )}
        </>
    );
}
