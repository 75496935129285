import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {
    routeNavigation,
    enableEventLeaveModal,
    enableRoomLeaveModal,
    enableBookingLeaveModal,
    enableFeedbackFormLeaveModal,
} from "@workhorse/components/header/headerUtils";
import {WithClassName} from "@workhorse/declarations";
import classes from "./style/NavigationItem.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {useMixpanelTracker} from "@workhorse/api/tracking";

type NavigationItemProps = WithClassName & {
    icon?: JSX.Element;

    url: string;
    isEvent: boolean;
    isRoom: boolean;
    text?: string;
    isBooking: boolean;
    current?: boolean;
    navbarTheme?: string | null;
    isMinimized?: boolean;
    hideTextWhenMinimized?: boolean;
    isFeedbackForm: boolean;
};

const NavigationItem = ({
    text,
    icon,
    url,
    current,
    className,
    isEvent,
    isRoom,
    isBooking,
    navbarTheme,
    isMinimized,
    hideTextWhenMinimized,
    isFeedbackForm,
}: NavigationItemProps) => {
    const {mixpanelPageView} = useMixpanelTracker();

    const preventNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const location = text?.toLocaleLowerCase().replaceAll(" ", "-");
        if (location) {
            mixpanelPageView(location);
        }

        if (isEvent || isRoom || isBooking || isFeedbackForm) {
            routeNavigation(url);

            if (isEvent) {
                enableEventLeaveModal(true);
            }
            if (isRoom) {
                enableRoomLeaveModal(true);
            }
            if (isBooking) {
                enableBookingLeaveModal(true);
            }
            if (isFeedbackForm) {
                enableFeedbackFormLeaveModal(true);
            }
            e.preventDefault();
        }
    };

    const showText = !isMinimized || !hideTextWhenMinimized;

    return (
        <Link
            to={url}
            onClick={preventNavigation}
            className={cls(
                "flex",
                classes.container,
                current && classes.current,
                isMinimized && classes.drawerClosed,
                navbarTheme === "light" && classes.navbarLight,
                className
            )}
            underline="none"
            role="tab"
        >
            <div className={classes.content}>
                <Tooltip title={showText ? "" : text ? text : ""} arrow>
                    <div className={classes.iconContainer}>{icon}</div>
                </Tooltip>
                {text && showText && <Typography className={classes.tabName}>{text}</Typography>}
            </div>
        </Link>
    );
};

export default NavigationItem;
