import * as msal from "@azure/msal-node";
import env from "@generated/environment";
import {useCallback, useMemo, useState} from "@workhorse/api/rendering";
import {useMutation} from "@workhorse/dataApi";
import {useLoginPopup} from "./login-popup";
import {markAsSeen} from "@workhorse/api/seen";

const getMicrosoftPublicClient = () => {
    return new msal.PublicClientApplication({
        auth: {
            clientId: env.microsoft.clientId,
            authority: env.microsoft.authority,
        },
        system: {
            loggerOptions: {
                loggerCallback(loglevel, message, containsPii) {
                    console.log(message);
                },
                piiLoggingEnabled: false,
                logLevel: msal.LogLevel.Verbose,
            },
        },
    });
};

export type UseOffice365LoginProps = {
    onLogin?: (email?: string) => void;
    onLoginFailure?: () => void;
    onLogout?: () => void;
    onLogoutFailure?: () => void;
};

type UseOffice265LoginReturnType = [(scopes: string[]) => void, (email: string) => void];

export const useOffice365Login = (props: UseOffice365LoginProps): UseOffice265LoginReturnType => {
    const {onLogin, onLoginFailure, onLogout, onLogoutFailure} = props;

    const [client] = useState(getMicrosoftPublicClient());

    const requestLogin = useLoginPopup({eventName: "office-365-login", onLogin, onLoginFailure});

    const [removeToken] = useMutation("DeleteMicrosoftConnectionDocument");

    const request = useCallback(
        async (scopes: string[]) => {
            localStorage.setItem("office-365-sync-scopes", scopes.join(" "));
            const authCodeUrlParameters: msal.AuthorizationUrlRequest = {
                scopes,
                redirectUri: `${env.authService.appRoot}/user/microsoft/oauth2callback`,
                prompt: "select_account",
            };

            const url = await client.getAuthCodeUrl(authCodeUrlParameters);
            markAsSeen({
                GLOBALS: {
                    syncEmailNotification: true,
                },
            });
            await requestLogin(url);
        },
        [requestLogin, client]
    );

    const disconnect = useCallback(
        (email: string) => {
            removeToken({
                variables: {
                    email,
                },
            })
                .then((response) => {
                    if (response.data?.deleteUserMicrosoftProviders) {
                        onLogout?.();
                    }
                })
                .catch(() => {
                    onLogoutFailure?.();
                });
        },
        [onLogout, onLogoutFailure, removeToken]
    );

    return useMemo(() => [request, disconnect], [request, disconnect]);
};
