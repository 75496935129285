import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Button from "@ui/cdk/Button";
import LinearProgress from "@ui/cdk/LinearProgress";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {CSSProperties, ReactNode, useRef} from "@workhorse/api/rendering";
import FilesDropzone from "@workhorse/components/FilesDropzone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import classes from "./FileDrop.module.scss";

interface FileStats {
    id: string | number;
    name: string;
}

interface Props {
    accept?: string;
    files?: FileStats[] | null;
    error?: ReactNode;
    multiple?: boolean;
    progress?: number;
    title?: string;
    subtitle?: string;
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
    onDrop?: (files?: File[] | null) => void;
    onBrowse?: (files?: FileList | null) => void;
}

export function FileDrop(props: Props) {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <FilesDropzone onNoFileSystem={props.onDrop} multipleUpload={props.multiple} classes={{dropzone: classes.dropZone}}>
            <div className={cls("flex flex-col fullw flex-align-center", props.className)} style={props.style}>
                <div className={cls("fullw flex-col flex flex-align-center", classes.uploadContainer)}>
                    <input
                        data-id="upload-file-input"
                        className={classes.displayNone}
                        type="file"
                        multiple={props.multiple}
                        accept={props.accept}
                        ref={inputRef}
                        onChange={(e) => props.onBrowse?.(e.target.files)}
                    />
                    <CloudUploadIcon className={classes.uploadIcon} />

                    <Typography fontWeight="bolder" color="octonary">
                        {props.title ?? "Drag a file and drop it here"}
                    </Typography>

                    <Typography variant="sm" color="quaternary">
                        {props.subtitle ?? "You can upload a file directly from your computer."}
                    </Typography>

                    {props.error ? <div className={cls("mt-10", classes.error)}>{props.error}</div> : null}

                    <Button variant="plain" className="mt-24" onClick={() => inputRef.current?.click()}>
                        <FolderRoundedIcon className="mr-5" />
                        Browse files
                    </Button>
                </div>

                {props.files?.map((file) => (
                    <div key={file.id} className="flex fullw flex-align-center mt-32">
                        <InsertDriveFileIcon className={classes.fileIcon} />
                        <div className="fullw">
                            <div className="flex flex-justify-between fullw">
                                <Typography color="secondary">{file.name}</Typography>
                                {props.progress != null && <Typography color="senary">{props.progress}%</Typography>}
                            </div>
                            {props.progress != null && <LinearProgress variant="determinate" value={props.progress} className="fullw" />}
                        </div>
                        {props.progress === 100 && <CheckCircleIcon className={classes.successIcon} />}
                    </div>
                ))}

                {props.children}
            </div>
        </FilesDropzone>
    );
}
