import {FileMimeType} from "@sessions/common/various";

export enum FileDenied {
    DENIED = "DENIED",
}

export type HeaderToMimeType = {
    [K in FileMimeType]?: string[];
};

export const headerToMimeType = {
    [FileMimeType.Image]: ["image/*"],
    [FileMimeType.Video]: ["video/*"],
    [FileMimeType.Audio]: ["audio/*"],
    [FileMimeType.Pdf]: ["application/pdf"],
    [FileMimeType.Document]: [
        "application/vnd.ms*",
        "application/vnd.openxmlformats*",
        "application/vnd.oasis.opendocument*",
        "text/plain",
        "application/msword",
        ".doc, .docx, .odt, .pages",
    ],
    [FileMimeType.Presentation]: [
        "application/vnd.ms*",
        "application/vnd.openxmlformats*",
        "application/vnd.oasis.opendocument*",
        ".ppt, .pptx, .odp, .key",
    ],
    [FileMimeType.Spreadsheet]: [
        "application/vnd.ms*",
        "application/vnd.openxmlformats*",
        "application/vnd.oasis.opendocument*",
        ".xls, .xlsx, .ods, .numbers",
    ],
    [FileMimeType.Archive]: [
        "application/zip",
        "application/vnd.rar",
        "application/x-tar",
        "application/x-7z-compressed",
        "application/gzip",
        "application/x-bzip",
        "application/x-bzip2",
    ],
};

export const getFileExtension = (fileName: string): string => {
    return fileName.match(/[^.]\w+$/)?.[0]?.toUpperCase() ?? "";
};

// headera `504b34` and `d0cf11e0` are associated with all Apple and OpenOffice document types.
// We need to check futher into the file info in order to return the proper mimetype.
const getDocumentMimeType = (file: File) => {
    const extention = getFileExtension(file.name);

    switch (extention) {
        case "DOC":
        case "DOCX":
        case "ODT":
        case "PAGES":
            return FileMimeType.Document;

        case "PPT":
        case "PPTX":
        case "ODP":
        case "KEY":
            return FileMimeType.Presentation;

        case "XLS":
        case "XLSX":
        case "ODS":
        case "NUMBERS":
            return FileMimeType.Spreadsheet;
        default:
            return FileMimeType.Unknown;
    }
};

export const getFileHeader = async (file: File, hasFilter?: boolean) => {
    return new Promise<keyof FileTypes>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = async (e) => {
            if (!e || !e.target || !e.target.result) {
                return;
            }
            const arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(0, 4);
            const header = arr.reduce((m, n) => m + n.toString(16), "");

            let type: any = undefined;

            switch (header) {
                // gifs, pngs and all jpg/jpegs
                case "89504e47":
                case "47494638":
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":

                // svg
                case "3c737667":
                case "3c3f786d":

                // webp
                case "57454250": {
                    type = FileMimeType.Image;
                    break;
                }

                // There are some cases when .avi and .webp files will have the same signature
                case "52494646": {
                    if (/image/.test(file.type)) {
                        type = FileMimeType.Image;
                        break;
                    }
                    if (/video/.test(file.type)) {
                        type = FileMimeType.Video;
                        break;
                    }
                    break;
                }

                case "25504446": {
                    type = FileMimeType.Pdf;
                    break;
                }

                case "504b0304": {
                    type = FileMimeType.Presentation;
                    break;
                }

                case "d0cf11e0":
                case "504b34": {
                    type = getDocumentMimeType(file);
                    break;
                }

                case "377abcaf":
                case "52617221": {
                    type = FileMimeType.Archive;
                    break;
                }

                // mp4
                case "66747970":
                case "00020":
                case "00018":
                case "0001c":

                // FLV
                case "464c561":

                // MPEG4
                case "00000018":

                // MPG
                case "000001ba":

                // MOV
                case "6d6f6f76":
                case "6674797071742020":
                case "00014":

                // MKV, MKA, mks, mk3d, & WEBM
                case "1a45dfa3":

                // WMV
                case "3026b275": {
                    type = FileMimeType.Video;
                    break;
                }

                // FLAC
                case "664c6143":

                // mp3
                case "49443303": {
                    type = FileMimeType.Audio;
                    break;
                }

                default: {
                    type = hasFilter ? FileDenied.DENIED : FileMimeType.Unknown;
                    break;
                }
            }

            resolve(type);
        };

        fileReader.readAsArrayBuffer(file.slice(0, 10240));
    });
};

export type FileTypes = {
    [K in FileMimeType]?: File[];
} & {
    [K in FileDenied]?: File[];
};

export type FileMimeTypes = FileMimeType & FileDenied;

export type AcceptedFileTypes = Array<keyof FileTypes>;

export const filterFiles = async (files: File[], sizeLimit, acceptedTypes?: AcceptedFileTypes) => {
    const out: FileTypes = {};
    const hasFilter = Array.isArray(acceptedTypes) && acceptedTypes.length > 0;

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (sizeLimit && file.size > sizeLimit) {
            if (!out[FileDenied.DENIED]) {
                out[FileDenied.DENIED] = [];
            }
            out[FileDenied.DENIED]?.push(file);
            continue;
        }
        await getFileHeader(files[i], hasFilter).then((type) => {
            const isAccepted = hasFilter ? (acceptedTypes || []).indexOf(type) > -1 : true;
            const typeKey = isAccepted ? type : FileDenied.DENIED;
            if (!out[typeKey]) {
                out[typeKey] = [];
            }

            out[typeKey]?.push(files[i]);
        });
    }

    return out;
};

export const mergeFiles = (existing: FileTypes, incoming: FileTypes) => {
    return Object.keys(existing)
        .concat(Object.keys(incoming))
        .reduce((out, type) => {
            return {
                ...out,
                [type]: (Array.isArray(existing[type]) ? existing[type] : []).concat(Array.isArray(incoming[type]) ? incoming[type] : []),
            };
        }, {});
};
