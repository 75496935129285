import React, {useMemo, Fragment} from "@workhorse/api/rendering";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useMacroArtifacts, useParticipants} from "@workhorse/providers/SessionDataProviders";
import {evaluateExpression} from "@workhorse/internal/expression";
import artifactDescriptor from "@generated/artifacts";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {MacroArtifactProps} from "@workhorse/declarations";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import Participants from "./session-participants/Participants";
import {orchestrator} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import {useDataEvent} from "@workhorse/dataApi";
import OffersWrapper from "@workhorse/pages/user/profile/UserOffers/OffersWrapper";
import TimerUi from "./session-timer/TimerUi";

type MacroLoaders = {
    [K in string]: React.ComponentType<MacroArtifactProps<{}>>[];
};

type SessionMacrosProps = {
    isLobby: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    sessionId: string;
    sessionStarted: boolean;
    myParticipantId: string;
};

function SessionMacros(props: SessionMacrosProps) {
    const {isAdmin, isAssistant, isLobby, sessionId, sessionStarted, myParticipantId} = props;
    const mobileState = useMobile();
    const macroArtifacts = useMacroArtifacts();
    const [isEditMode] = useDesignerIsEditing();
    const hostType = useHostType();
    const {isRecorder} = useUserInfo();

    useDataEvent("onWatchdog", async (data) => {
        if (data.watchdog?.drawerState && !isRecorder) {
            orchestrator.onSubscriptionDrawerChange(sessionId, data.watchdog?.drawerState);
        }
    });

    const macroComponents = useMemo(() => {
        return macroArtifacts?.reduce((all, macro) => {
            const descriptor = artifactDescriptor[macro.artifactId];
            const entries = descriptor.entries
                .filter((entry) => {
                    return evaluateExpression({
                        exp: entry.preloadCondition,
                        type: "macro",
                        artifactEvaluation: entry.filter ? entry.filter : () => true,
                        isEditMode,
                        hostType,
                    });
                })
                .map((entry) => entry.loader);

            return {
                ...all,
                [macro.id]: entries,
            };
        }, {}) as MacroLoaders;
    }, [macroArtifacts]);

    return (
        <div className="macros-wrapper absolute-fill pointer-events-none">
            <ErrorBoundary mobileState={mobileState} location="SessionMacroLoaderParticipants">
                <Participants
                    key={"participants"}
                    isLobby={isLobby}
                    isAdmin={isAdmin}
                    isAssistant={isAssistant}
                    sessionId={sessionId}
                    sessionStarted={sessionStarted}
                    mobileState={mobileState}
                    myParticipantId={myParticipantId}
                />
            </ErrorBoundary>

            {macroArtifacts?.map((artifact) => {
                const loaders = macroComponents[artifact.id];

                return (
                    <Fragment key={artifact.id}>
                        {loaders.length > 0 && (
                            <ErrorBoundary mobileState={mobileState} location="SessionMacroLoader">
                                {loaders.map((MacroLoader, i) => {
                                    return (
                                        <MacroLoader
                                            key={artifact.id}
                                            artifact={artifact}
                                            isLobby={isLobby}
                                            isAdmin={isAdmin}
                                            isAssistant={isAssistant}
                                            sessionId={sessionId}
                                            sessionStarted={sessionStarted}
                                            mobileState={mobileState}
                                            myParticipantId={myParticipantId}
                                        />
                                    );
                                })}
                            </ErrorBoundary>
                        )}
                    </Fragment>
                );
            })}
            <ErrorBoundary mobileState={mobileState} location="SessionMacroLoaderOffers">
                <OffersWrapper key={"offers"} isLobby={isLobby} isMacro={true} isMemoryMode={false} />
            </ErrorBoundary>

            <ErrorBoundary mobileState={mobileState} location="SessionMacroLoaderTimer">
                <TimerUi key={"timer"} isMemoryMode={false} />
            </ErrorBoundary>
        </div>
    );
}

export default SessionMacros;
