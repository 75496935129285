import {PaymentConfigFragment, PaymentProvider} from "@generated/data";
import {currencies} from "@sessions/currencies";
import {ChangeEventHandler, FormEventHandler, memo, useCallback, useEffect, useMemo, useRef} from "react";
import {useUserInfo} from "@workhorse/providers/User";
import {Formik, useFormikContext} from "formik";
import {PaymentConfigSchema, schema} from "./schema";
import Typography from "@ui/cdk/Typography";
import Checkbox from "@ui/cdk/Checkbox";
import FormikFieldWrapper from "@ui/cdk/FormikFieldWrapper";
import EditorInput from "../editor/EditorInput";
import FormControl from "@ui/cdk/Input/FormControl";
import classes from "./styles/PaymentsForm.module.scss";
import Input from "@ui/cdk/Input";
import Select from "@ui/cdk/Select";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import {ReactComponent as StripeIcon} from "../../assets/media/stripe_icon.svg";
import {ReactComponent as PayPalIcon} from "../../assets/media/paypal-mark-color.svg";

export type PaymentsFormValues = PaymentConfigSchema;

export type PaymentsFormProps = {
    config: PaymentConfigSchema;
    availableProviders: PaymentProvider[];
    onChange: (values: PaymentConfigSchema) => void;
    onSubmit?: (values: PaymentConfigSchema) => void;
};

const PaymentsFormComponent = memo((props: Pick<PaymentsFormProps, "availableProviders" | "onChange">) => {
    const {availableProviders, onChange} = props;
    const {setFieldValue, validateForm, values, errors, dirty, setFieldError, submitForm} = useFormikContext<PaymentConfigSchema>();
    const userInfo = useUserInfo();

    const timeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (dirty) {
            timeout.current = setTimeout(() => {
                tryPushChange(values);
            }, 600);
        }

        () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [dirty, values]);

    const tryPushChange = useCallback(async (values: PaymentConfigSchema) => {
        const errors = await validateForm(values);
        const minValue = getMinValue();

        if (!Object.keys(errors).length) {
            if (values.amount < minValue) {
                setFieldError("amount", `Amount can’t be less than ${minValue.toFixed(2)}`);
            } else {
                onChange(values);
            }
        }
    }, []);

    const getMinValue = () => {
        const currency = currencies.find((curr) => curr.value === values.currency);
        if (!currency) return 1;
        return currency.min / 100;
    };

    const onChangeAmount: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        const amount = event.target.value ? parseFloat(event.target.value) : null;
        setFieldValue("amount", amount ?? "");

        if (amount && !isNaN(amount) && amount > 2147483647 / 100) {
            if (!errors.amount) {
                setFieldError("amount", `Amount can’t be more than ${(2147483647 / 100).toLocaleString()}`);
            }

            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
    }, []);

    const onChangeCurrency: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const value = event.target.value;
        const amountValue = values.amount * 100;
        const currency = currencies.find((curr) => curr.value === value);

        if (!currency) return;

        if (!amountValue || amountValue < currency.min) {
            setFieldValue("amount", currency.min / 100);
        }

        setFieldValue("currency", value);
    };

    const onToggleProvider = (provider: PaymentProvider) => {
        const currentProviders = [...values.paymentProviders];
        const currentIndex = currentProviders.findIndex((p) => p.id === provider.id);

        if (currentIndex > -1) {
            currentProviders.splice(currentIndex, 1);
        } else {
            currentProviders.push({id: provider.id, name: provider.name, accountName: provider.accountName ?? undefined});
        }

        setFieldValue("paymentProviders", currentProviders);
    };

    const onFormSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        submitForm();
    };

    const currentCurrency = useMemo(() => {
        return currencies.find((c) => c.value === values.currency)!;
    }, [values.currency]);

    // Collaborators can add or modify the config
    // but ONLY the owner of the config can update the payment providers

    const isOwner = userInfo.id === values.ownerId;
    const providerList = isOwner ? availableProviders : values.paymentProviders;

    return (
        <form noValidate onSubmit={onFormSubmit}>
            <div>
                <Input
                    label="Entry fee"
                    data-id="payment-form-amount"
                    className={classes.amountInput}
                    onChange={onChangeAmount}
                    type="number"
                    inputProps={{min: currentCurrency.min / 100}}
                    value={values.amount}
                    shrinkLabel={false}
                    startAdornment={currentCurrency.symbol}
                    classes={{adornedStart: classes.amountInputSymbol}}
                    labelClassName={classes.label}
                    endAdornment={
                        <Select
                            className={classes.currencyInput}
                            options={currencies.map((item) => ({text: `${item.value}`, value: item.value}))}
                            value={values.currency}
                            onChange={onChangeCurrency}
                            classes={{root: classes.currencyInputSelect, icon: classes.currencyInputSelectIcon}}
                        />
                    }
                    error={Boolean(errors.amount)}
                    helperText={errors.amount}
                />
            </div>
            <div>
                <FormControl
                    label="Accepted payment methods:"
                    data-id="payment-form-provider"
                    shrinkLabel={false}
                    variant="outlined"
                    className={classes.providerInput}
                    labelClassName={classes.label}
                >
                    <div className={classes.providerInputItems}>
                        {providerList?.map((p) => (
                            <FormControlLabel
                                key={p.id}
                                name={p.name}
                                value={p.id}
                                className={classes.providerInputOption}
                                control={
                                    <Checkbox
                                        variant="blue"
                                        disabled={providerList.length === 1 || !isOwner}
                                        onChange={() => onToggleProvider(p)}
                                    />
                                }
                                label={
                                    <div className={classes.providerInputLabel}>
                                        <Typography>{`${p.name === "paypal-payments" ? "PayPal" : "Stripe"}${
                                            p.accountName ? ` (${p.accountName})` : ""
                                        }`}</Typography>
                                        <span className={classes.providerInputIcon}>
                                            {p.name === "paypal-payments" ? (
                                                <PayPalIcon width="24" height="24" />
                                            ) : (
                                                <StripeIcon width="24" height="24" />
                                            )}
                                        </span>
                                    </div>
                                }
                                labelPlacement="end"
                                checked={values.paymentProviders?.some((provider) => provider?.id === p.id)}
                                classes={{label: classes.providerInputLabel}}
                            />
                        ))}
                    </div>
                </FormControl>
            </div>
            <div>
                <FormikFieldWrapper
                    data-id="booking-payments-terms"
                    key="payments-terms"
                    name="terms"
                    as={EditorInput}
                    value={values.terms}
                    label="Terms:"
                    placeholder={
                        "Let your participants know if you have any terms, conditions, or other important information when it comes to bookings, payments, and refunds."
                    }
                    maxCharCount={5000}
                    showTypedCharCount={true}
                    labelClassName={classes.label}
                    onChange={(content) => setFieldValue("terms", JSON.stringify(content))}
                />
            </div>
        </form>
    );
});

export const PaymentsForm = (props: Omit<PaymentsFormProps, "config"> & {config: PaymentConfigFragment}) => {
    const {config, availableProviders, onSubmit, onChange} = props;

    const initialValues: PaymentConfigSchema = useMemo(() => {
        return {
            id: config.id,
            amount: config.amount / 100,
            currency: config.currency as PaymentConfigSchema["currency"],
            enabled: config.enabled,
            ownerId: config.ownerId,
            terms: config.terms ?? undefined,
            paymentProviders:
                config.paymentProviders?.map((item) => ({
                    id: item?.id ?? "",
                    name: item?.name ?? "",
                    accountName: item?.accountName ?? "",
                })) ?? [],
        };
    }, [config]);

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => onSubmit?.(values)}>
            <PaymentsFormComponent availableProviders={availableProviders ?? []} onChange={onChange} />
        </Formik>
    );
};
