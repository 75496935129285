import {
    CreateSessionOccurrenceDocument,
    DeleteSessionDocument,
    DeleteSessionOccurrenceAndFollowingDocument,
    DeleteSessionOccurrenceDocument,
    ParticipantInviteStatus,
    RemoveYourselfFromSessionDocument,
    UpdateParticipantInviteStatusDocument,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {evictCalendarEventsFromCache} from "./events";

export const deleteSession = async (sessionId: string) => {
    const res = await apollo.client.mutate({
        mutation: DeleteSessionDocument,
        variables: {
            session: {
                id: sessionId,
            },
        },
    });

    if (res.data?.deleteOneSession?.id) {
        apollo.cache.evict({id: res.data.deleteOneSession.id});
        apollo.cache.gc();
    }
};

export const createSessionOccurrence = (sessionId: string, occurrenceId: Date) => {
    return apollo.client.mutate({
        mutation: CreateSessionOccurrenceDocument,
        variables: {
            sessionId,
            occurrenceId,
        },
        update: () => {
            evictCalendarEventsFromCache(sessionId, occurrenceId);
        },
    });
};

export const deleteSessionOccurrence = (sessionId: string, occurrenceId: Date) => {
    return apollo.client.mutate({
        mutation: DeleteSessionOccurrenceDocument,
        variables: {
            sessionId,
            occurrenceId,
        },
    });
};

export const deleteSessionOccurrenceAndFollowing = (sessionId: string, occurrenceId: Date) => {
    return apollo.client.mutate({
        mutation: DeleteSessionOccurrenceAndFollowingDocument,
        variables: {
            sessionId,
            occurrenceId,
        },
    });
};

export const updateInviteStatus = (sessionId: string, inviteStatus: ParticipantInviteStatus, reason?: string) => {
    return apollo.client.mutate({
        mutation: UpdateParticipantInviteStatusDocument,
        variables: {
            sessionId,
            inviteStatus,
            reason,
        },
    });
};

export const removeYourselfFromSession = (sessionId: string, soft: boolean | undefined, upcoming: boolean | undefined) => {
    return apollo.client.mutate({
        mutation: RemoveYourselfFromSessionDocument,
        variables: {
            sessionId,
            soft,
            upcoming,
        },
    });
};
