import React, {HTMLProps, useEffect, useRef} from "@workhorse/api/rendering";

export function ContentShare(props: Omit<HTMLProps<HTMLVideoElement>, "ref">) {
    const {className, ...rest} = props;

    // const audioVideo = useAudioVideo();
    // const {tileId} = useContentShareState();
    const tileId = 0;
    const videoRef = useRef<HTMLVideoElement | null>(null);

    // useEffect(() => {
    //     if (!audioVideo || !videoRef.current || !tileId) {
    //         return;
    //     }

    //     const videoEl = videoRef.current;

    //     audioVideo.bindVideoElement(tileId, videoEl);

    //     return () => {
    //         videoEl.srcObject = null;
    //         const tile = audioVideo.getVideoTile(tileId);
    //         if (tile) {
    //             audioVideo.unbindVideoElement(tileId);
    //         }
    //     };
    // }, [audioVideo, tileId]);

    return <video {...rest} ref={videoRef} className={`ch-content-share--${tileId} ${className || ""}`} playsInline />;
}
