import Button from "@ui/cdk/Button";

import Typography from "@ui/cdk/Typography";
import {useHistory, useLocation} from "@workhorse/api/routing";
import {useTranslation} from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import classes from "../styles/BookPreviewNotification.module.scss";
import toast from "@workhorse/api/toast";

const BookPreviewNotification = (props: {eventSlug: string}) => {
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();

    const handleLinkCopied = () => {
        toast(<p>{t("g.toast.link_copied")}</p>);
    };

    const url = `${window.location.origin}/book/${props.eventSlug}`;

    return (
        <div className={classes.wrapper}>
            <Typography>
                <strong>{t("g.this_is_a_preview")}</strong> {t("booking.preview_explainer")}
            </Typography>
            <CopyToClipboard text={url} onCopy={handleLinkCopied}>
                <Button variant="plain" size="smallest">
                    {t("booking.copy_booking_link")}
                </Button>
            </CopyToClipboard>
        </div>
    );
};

export default BookPreviewNotification;
