import StarsIcon from "@material-ui/icons/Stars";
import {ConferenceParticipantStatus} from "@generated/data";
import classes from "./styles/ParticipantEntry.module.scss";
import {ReactComponent as SpotlightIcon} from "../../../../../assets/media/spotlight-icon.svg";
import {ReactComponent as RaiseHandIcon} from "../../../../../assets/media/raise-hand-icon.svg";
import {useTranslation} from "react-i18next";

type ParticipantEntryStatusProps = {
    isParticipantOwner: boolean;
    isParticipantAssistant: boolean;
    conferenceStatus: ConferenceParticipantStatus;
    showRole?: boolean;
};

function ParticipantEntryStatus(props: ParticipantEntryStatusProps) {
    const {t} = useTranslation();
    const {isParticipantOwner, isParticipantAssistant, conferenceStatus, showRole} = props;

    const raisedHand = conferenceStatus === ConferenceParticipantStatus.PendingSpeaker;
    const isSpeaker = conferenceStatus === ConferenceParticipantStatus.Speaker;

    return (
        <div className={classes.participantStatusContainer}>
            {isParticipantOwner && (
                <span className={classes.participantCustomStatusOwner}>
                    <StarsIcon />
                    {showRole && <span>{t("participant.g.host")}</span>}
                </span>
            )}
            {isParticipantAssistant && (
                <span className={classes.participantCustomStatusAssistant}>
                    <StarsIcon />
                    {showRole && <span>{t("participant.g.assistant")}</span>}
                </span>
            )}
            {raisedHand && (
                <span>
                    <RaiseHandIcon />
                    <span>{t("participant.g.raised_hand")}</span>
                </span>
            )}
            {isSpeaker && (
                <span>
                    <SpotlightIcon />
                    {showRole && <span>{t("participant.g.spotlight")}</span>}
                </span>
            )}
        </div>
    );
}

export default ParticipantEntryStatus;
