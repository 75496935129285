import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/BlockedPermissionsModal.module.scss";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import onboardingPreJoinSrc from "../../../../assets/media/onboarding-prejoin.png";
import classes2 from "./PreJoinExplainDemoDialog.module.scss";
import InfoIcon from "@material-ui/icons/InfoOutlined";

type PreJoinExplainDemoDialogProps = {
    onClose: () => void;
};

export function PreJoinExplainDemoDialog(props: PreJoinExplainDemoDialogProps) {
    const {t} = useTranslation();

    const imperativeRef = useRef<DialogImperativeRef>();

    useEffect(() => {
        imperativeRef.current?.toggle?.(true);
    }, []);

    function handleCloseDialog() {
        imperativeRef.current?.toggle?.(false);
    }

    const title = t("player.onboarding.initial-pre-join.title") ?? "Want to see what a session looks like?";

    const description =
        t("player.onboarding.initial-pre-join.description") ??
        "In the next step, you can set up your camera and microphone and add a background image. Once you are ready, join the session.";

    const descriptionOnboarding =
        t("player.onboarding.initial-pre-join.description-extra1") ??
        "You’ll be alone, it’s just a test session for you to try things out.";

    const button = t("player.onboarding.initial-pre-join.button1") ?? "Set up camera and microphone";

    return (
        <Dialog
            className="flex flex-col flex-center-all"
            classes={{
                paper: classes.paper,
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop-with-backdrop-filter",
                },
            }}
            imperativeRef={imperativeRef}
            open={true}
        >
            <div className={cls("flex flex-justify-between", classes.dialogHeader, "pb-0")}>
                <Typography className={classes.dialogHeaderTitle}>{title}</Typography>
            </div>
            <div>
                <img
                    className={cls(classes2.prejoinOnboardingImg, "mt-10 mb-10")}
                    src={onboardingPreJoinSrc}
                    alt=""
                    width="836"
                    height="466"
                />
                <div className="mb-20 flex flex-col gap-10">
                    <Typography variant="base">{description}</Typography>

                    <div className="flex flex-row gap-3 flex-align-center">
                        <InfoIcon className={classes2.infoIcon} />
                        <Typography variant="base" fontWeight="bolder">
                            {descriptionOnboarding}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <Button variant="primary" className={cls("mb-15", classes.footerButton)} onClick={handleCloseDialog}>
                    <strong>{button}</strong>
                </Button>
            </div>
        </Dialog>
    );
}
