import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import {CustomResolvers} from "./types";

// diff resolvers are not generated for the Participant model
// when/if they will ever be needed, it will be safe to remove the createdAt and updatedAt resolvers
// because they will be included in the generated resolvers
// TODO: @vasi
export const Participant: CustomResolvers["Participant"] = {
    ...(fieldResolvers as any).Participant,
    statusChangedBy: {
        read(existing, {readField, cache}) {
            const participantId = readField("id");
            if (!participantId) {
                return {
                    __typename: "ParticipantStatusChangedBy",
                    participantId: null,
                    firstName: null,
                    lastName: null,
                    unableToBecomeSpeaker: null,
                };
            }
            // @ts-expect-error
            const participant = cache.extract(true)[participantId];
            if (!participant) {
                return {
                    __typename: "ParticipantStatusChangedBy",
                    participantId: null,
                    firstName: null,
                    lastName: null,
                    unableToBecomeSpeaker: null,
                };
            }
            return participant.statusChangedBy === undefined
                ? {
                      __typename: "ParticipantStatusChangedBy",
                      participantId: null,
                      firstName: null,
                      lastName: null,
                      unableToBecomeSpeaker: null,
                  }
                : participant.statusChangedBy;
        },
        merge: true,
    },
    rbac: {
        merge: true,
    },
    // createdAt: {
    //     // TODO: @maca - data api - does this work ?
    //     // disallow overwriting createdAt once the model has been commited
    //     merge(existing, incoming, {variables}) {
    //         const nullOutput = null as unknown as Date;
    //         if (!(variables as any)?.noDiff) {
    //             return existing ?? nullOutput;
    //         }
    //         return incoming;
    //     },
    // },
    // updatedAt: {
    //     // TODO: @maca - data api - does this work ?
    //     // disallow overwriting updatedAt once the model has been commited
    //     merge(existing, incoming, {variables}) {
    //         const nullOutput = null as unknown as Date;
    //         if (!(variables as any)?.noDiff) {
    //             return existing ?? nullOutput;
    //         }
    //         return incoming;
    //     },
    // },
};
