import Button from "@ui/cdk/Button";
import FormikFieldWrapper from "@ui/cdk/FormikFieldWrapper";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {collapseWhiteSpace} from "@ui/cdk/util/util";
import {useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {useMutation} from "@workhorse/dataApi";
import {Form, Formik, FormikProps} from "formik";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {ReactComponent as CloudUploadIcon} from "../assets/cloud-upload-outline.svg";
import classes from "../styles/NewOffer.module.scss";

type FormValuesProps = {
    title: string;
    description: string;
    ctaUrl: string;
    ctaText: string;
    imageUrl: string;
};

export type NewOfferProps = {
    onClose: () => void;
    mode: "edit" | "add";
    offerId: string | null;
    newOfferData: {
        title: string;
        description: string;
        ctaUrl: string;
        ctaText: string;
        imageUrl: string;
    } | null;
    setNewOfferData: React.Dispatch<
        React.SetStateAction<{
            title: string;
            description: string;
            ctaUrl: string;
            ctaText: string;
            imageUrl: string;
        } | null>
    >;
    offers: any[];
    onUpdateOffer: (offerId: string, offer: FormValuesProps, shouldPublish: boolean) => void;
    onCreateOffer: (offer: FormValuesProps) => void;
    isMacro: boolean;
    selectedOfferIsPublished: boolean;
};

const titleMaximumCharacters = 30;
const descriptionMaximumCharacters = 100;
const ctaTextMaximumCharacters = 20;

const NewOffer = (props: NewOfferProps) => {
    const {onClose, mode, offerId, newOfferData, setNewOfferData, offers, onUpdateOffer, onCreateOffer, isMacro, selectedOfferIsPublished} =
        props;

    const {t} = useTranslation();

    // importing {t} directly from i18next doesn't work in this case so i moved the schema into the component
    const re = /^(ftp|http|https):\/\/(www.)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[a-zA-Z0-9#-]+\/?)*$/;

    const newOfferFormSchema = {
        fields: [
            {
                title: t("g.photo") ?? "Photo",
                name: "imageUrl",
                typeField: "text",
                required: true,
                placeholder: "",
                maxCharCount: undefined,
            },
            {
                title: t("g.headline") ?? "Headline",
                name: "title",
                typeField: "text",
                required: true,
                placeholder: t("g.form.field_placeholder.offer_headline") ?? "Enter a name...",
                maxCharCount: titleMaximumCharacters,
            },
            {
                title: t("g.description") ?? "Description",
                name: "description",
                typeField: "text",
                required: true,
                placeholder: t("g.form.field_placeholder.offer_description") ?? "Enter a description...",
                maxCharCount: descriptionMaximumCharacters,
            },
            {
                title: t("g.cta") ?? "CTA",
                name: "ctaText",
                typeField: "text",
                required: true,
                placeholder: t("g.form.field_placeholder.offer_cta_text") ?? "Enter button label...",
                maxCharCount: ctaTextMaximumCharacters,
            },
            {
                title: t("g.link") ?? "Link",
                name: "ctaUrl",
                typeField: "text",
                required: true,
                placeholder: "https://www.website.us",
                maxCharCount: undefined,
            },
        ],
        initialValues: {
            title: "",
            description: "",
            ctaUrl: "",
            ctaText: "",
            imageUrl: "",
        },
        validationSchema: Yup.object().shape({
            imageUrl: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "This field is mandatory"),
            title: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "This field is mandatory")
                .max(titleMaximumCharacters, `Needs to a have maximum of ${titleMaximumCharacters} characters`),

            description: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "This field is mandatory")
                .max(descriptionMaximumCharacters, `Needs to a have maximum of ${descriptionMaximumCharacters} characters`),
            ctaText: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "This field is mandatory")
                .max(ctaTextMaximumCharacters, `Needs to a have maximum of ${ctaTextMaximumCharacters} characters`),
            ctaUrl: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "This field is mandatory")
                .test("check-valid-url", t("g.form.field_invalid_url") ?? "The URL is not valid.", function (value) {
                    if (!value) return false;
                    if (value.startsWith("http://") || value.startsWith("https://")) {
                        try {
                            const url = new URL(value);
                            if (!url.origin.match(re)) {
                                return false;
                            }
                            return true;
                        } catch {
                            return false;
                        }
                    } else {
                        try {
                            const url = new URL(`https://${value}`);
                            if (!url.origin.match(re)) {
                                return false;
                            }
                            return true;
                        } catch {
                            return false;
                        }
                    }
                }),
        }),
    };

    const formRef = useRef<FormikProps<FormValuesProps>>(null);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState({
        fileName: "",
        fileSize: 0,
    });

    const [uploadOfferAsset] = useMutation("UpdateOfferImageDocument");

    const offerToEdit = offers.find((x) => x?.id === offerId);

    const offerInitialValues = {
        title: offerToEdit?.title,
        description: offerToEdit?.description,
        ctaUrl: offerToEdit?.ctaUrl,
        ctaText: offerToEdit?.ctaText,
        imageUrl: offerToEdit?.imageUrl,
    };

    const formInitialValues = offerToEdit
        ? offerInitialValues
        : newOfferData
        ? {
              ...newOfferFormSchema.initialValues,
              ...newOfferData,
          }
        : newOfferFormSchema.initialValues;

    const handleSubmit = (values: FormValuesProps) => {
        if (mode === "add") {
            onCreateOffer(values);
            onClose();
            return;
        } else {
            if (!offerId) {
                return;
            }
            onUpdateOffer(offerId, values, selectedOfferIsPublished);
            onClose();
        }
    };

    const onUploadOfferAsset = async (file: File) => {
        try {
            const maximumUploadSize = 5;
            const sizeMb = parseFloat((file.size / (1024 * 1024)).toFixed(2));
            const acceptedTypes = ["image/jpeg", "image/jpg", "image/png"];
            if (!acceptedTypes.includes(file.type)) {
                toast("Please upload one of the following formats: PNG, JPG, JPEG", {
                    type: "error",
                });
                return;
            }

            if (sizeMb > maximumUploadSize) {
                toast("Photo can't exceed 5mb", {
                    type: "error",
                });
                return;
            }

            const cacheBuster = Date.now().toString();
            const uploadUrlRes = await uploadOfferAsset({variables: {cacheBuster}});
            const {uploadUrl, publicUrl} = uploadUrlRes.data?.uploadOfferAsset ?? {};

            if (!uploadUrl || !publicUrl) {
                throw new Error("No data returned from server.");
            }

            const fetchResponse = await fetch(uploadUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": !!file.type ? file.type : "application/octet-stream",
                },
            });

            if (fetchResponse.ok) {
                setFileInfo({
                    fileName: file.name,
                    fileSize: sizeMb,
                });
                return publicUrl;
            } else {
                throw new Error("Fetch response is not 200.");
            }
        } catch (err) {
            console.error(`Error occurred when uploading offer picture: ${err.message}`);
            return toast(`Error occurred when uploading offer picture`, {type: "error"});
        }
    };

    const onChangeImage = async (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
        const file = e?.target?.files?.[0];

        if (!file) {
            return;
        }

        setLoading(true);
        const publicUrl = await onUploadOfferAsset(file);
        setLoading(false);

        if (publicUrl) {
            setFieldValue("imageUrl", publicUrl);
        }
    };

    const onDropImage = async (e: React.DragEvent<HTMLDivElement>, setFieldValue: (field: string, value: any) => void) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("e.dataTransfer.files", e.dataTransfer.files);
        const file = e?.dataTransfer?.files?.[0];
        if (!file) {
            return;
        }

        setLoading(true);
        const publicUrl = await onUploadOfferAsset(file);
        setLoading(false);

        if (publicUrl) {
            setFieldValue("imageUrl", publicUrl);
        }
    };

    const onRemoveImage = (setFieldValue: (field: string, value: any) => void) => {
        setFieldValue("imageUrl", "");
    };

    const urlFieldIndex = newOfferFormSchema.fields.findIndex((field) => field.name === "ctaUrl");
    const urlField = newOfferFormSchema.fields[urlFieldIndex].name;

    const imageFieldIndex = newOfferFormSchema.fields.findIndex((field) => field.name === "imageUrl");

    return (
        <div className={cls("flex flex-col", classes.root, isMacro && classes.rootMacro)}>
            {!isMacro && (
                <div className="flex flex-col flex-justify-between flex-align-start mb-24">
                    <Typography fontWeight="bolder" variant="xl3" color="primary" className="mb-5">
                        {mode === "add" ? "New offer" : "Edit offer"}
                    </Typography>
                    {mode === "add" && (
                        <Typography variant="base" color="secondary" className="mb-5">
                            Create an offer to attract and delight your audience
                        </Typography>
                    )}
                </div>
            )}
            <div className="flex flex-col gap-16 flex-align-start fullw fullh">
                <Formik
                    innerRef={formRef}
                    enableReinitialize
                    initialValues={formInitialValues}
                    validationSchema={newOfferFormSchema.validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        const cleanedValues = Object.keys(values).reduce((acc, key) => {
                            if (typeof values[key] === "string") {
                                acc[key] = collapseWhiteSpace(values[key]).trim();
                            } else {
                                acc[key] = values[key];
                            }
                            return acc;
                        }, {} as FormValuesProps);

                        handleSubmit(cleanedValues);
                        setSubmitting(false);
                        setNewOfferData(null);
                    }}
                >
                    {({values, touched, errors, isSubmitting, setFieldValue}) => {
                        const formValues = offerToEdit ? offerInitialValues : values;

                        let isValuesChanged = false;
                        if (mode === "edit" && offerToEdit) {
                            for (const key in offerInitialValues) {
                                if (offerInitialValues[key] !== values[key]) {
                                    isValuesChanged = true;
                                    break;
                                }
                            }
                        }

                        const imageUrlErrorText = errors[newOfferFormSchema.fields[imageFieldIndex].name];
                        const isImageUrlError = !!imageUrlErrorText && touched[newOfferFormSchema.fields[imageFieldIndex].name];

                        return (
                            <Form className={classes.formContainer}>
                                <div className="flex flex-col flex-justify-between">
                                    <div className={classes.formInputContainer}>
                                        {values.imageUrl ? (
                                            <div className={cls("flex flex-row gap-16 flex-align-center", classes.uploadedPhotoPreview)}>
                                                <img src={formValues.imageUrl} alt="offer image" />
                                                <div>
                                                    {fileInfo.fileName && fileInfo.fileSize ? (
                                                        <>
                                                            <Typography variant="sm" color="secondary" fontWeight="bold">
                                                                {fileInfo.fileName}
                                                            </Typography>
                                                            <div className="flex flex-row gap-5">
                                                                <Typography variant="sm" color="secondary">
                                                                    {fileInfo.fileSize} MB
                                                                </Typography>
                                                                <span>-</span>
                                                                <Typography
                                                                    variant="sm"
                                                                    color="secondary"
                                                                    fontWeight="bold"
                                                                    className={classes.removePhoto}
                                                                    onClick={() => onRemoveImage(setFieldValue)}
                                                                >
                                                                    {t("g.cancel") ?? "Cancel"}
                                                                </Typography>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            variant="sm"
                                                            color="secondary"
                                                            fontWeight="bold"
                                                            className={classes.removePhoto}
                                                            onClick={() => onRemoveImage(setFieldValue)}
                                                        >
                                                            {t("g.remove") ?? "Remove"}
                                                        </Typography>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <label htmlFor="offer-image-upload" role="button">
                                                <input
                                                    id="offer-image-upload"
                                                    name="imageUrl"
                                                    type="file"
                                                    accept="image/*"
                                                    className={classes.uploadInput}
                                                    onChange={(event) => {
                                                        onChangeImage(event, setFieldValue);
                                                    }}
                                                    data-private="lipsum"
                                                />
                                                <div
                                                    key="new-offer-upload-image"
                                                    className={cls(
                                                        classes.photoUploadInput,
                                                        formValues?.imageUrl && classes.photoUploadInputWithSrc,
                                                        isImageUrlError && classes.photoUploadInputError
                                                    )}
                                                    onDrop={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        onDropImage(event, setFieldValue);
                                                    }}
                                                    onDragOver={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <>
                                                        <div className={classes.uploadIcon}>
                                                            <CloudUploadIcon />
                                                        </div>
                                                        <div className="flex flex-col flex-items-center flex-justify-center gap-4">
                                                            <Typography variant="sm" color="secondary">
                                                                <span className={classes.uploadMainText}>
                                                                    {" "}
                                                                    {t("g.click_to_upload") ?? "Click to upload"}{" "}
                                                                </span>{" "}
                                                                {t("g.or") ?? "or"} {t("g.drag_and_drop") ?? "drag and drop"}
                                                            </Typography>
                                                            <Typography variant="sm" color="secondary">
                                                                PNG, JPG or JPEG with an aspect ratio of 4:3
                                                            </Typography>
                                                        </div>
                                                    </>
                                                </div>
                                                <Typography variant="sm" color="red400">
                                                    {imageUrlErrorText}
                                                </Typography>
                                            </label>
                                        )}

                                        {newOfferFormSchema.fields
                                            .filter((field) => field.name !== "imageUrl")
                                            .map((field) => {
                                                return (
                                                    <div key={`new-offer-form-field-${field.name}`}>
                                                        <FormikFieldWrapper
                                                            key={field.name}
                                                            name={field.name}
                                                            as={Input}
                                                            value={formValues[field.name]}
                                                            inputProps={{
                                                                className: classes.formInput,
                                                                "data-private": "lipsum",
                                                            }}
                                                            labelClassName={classes.formInputLabel}
                                                            label={
                                                                <>
                                                                    {field.title}{" "}
                                                                    {field.required ? <span className={classes.required}>*</span> : null}
                                                                </>
                                                            }
                                                            maxCharCount={field.maxCharCount}
                                                            error={!!errors[field.name] && touched[field.name]}
                                                            helperText={errors[field.name]}
                                                            placeholder={field.placeholder}
                                                            showTypedCharCount={field.name !== "ctaUrl"}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <div className={cls("fullw flex flex-row flex-justify-end mt-auto ", isMacro ? "pb-15" : "")}>
                                        <Button onClick={onClose} variant="plain" size="small">
                                            {t("g.cancel") ?? "Cancel"}
                                        </Button>
                                        <Button
                                            withMarginLeft
                                            size="small"
                                            type="submit"
                                            disabled={isSubmitting || (mode === "edit" && !isValuesChanged)}
                                        >
                                            {mode === "add"
                                                ? t("macro.offers.create_offer") ?? "Create offer"
                                                : t("g.save_changes") ?? "Save changes"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default NewOffer;
