import {cls} from "@ui/cdk/util/util";
import {WithClassName} from "@workhorse/declarations";
import classes from "./styles/LobbyCoverImage.module.scss";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "@workhorse/api/rendering";
import env from "@generated/environment";

const defaultCoverImage = `${env.staticServiceUrl}/lobbyPictures/gradient-02.png`;

type LobbyCoverImageProps = WithClassName & {
    url: string;
    y: number;
    onImageLoad?: () => void;
};

const LobbyCoverImage = (props: LobbyCoverImageProps) => {
    const [source, setSource] = useState<string | undefined>(props.url);
    const {t} = useTranslation();

    const handleImageOnLoad = () => {
        if (props.onImageLoad) {
            props.onImageLoad();
        }
    };

    const handleLoadError = () => {
        setSource(defaultCoverImage);
    };

    // updating with props.key makes the image flicker whilst useEffect doesn't
    useEffect(() => {
        setSource(props.url);
    }, [props.url]);

    return (
        <div className={cls(classes.coverImage, props.className)}>
            <img
                onError={handleLoadError}
                onLoad={handleImageOnLoad}
                src={source}
                alt={t("lobby.cover_image.name") ?? undefined}
                style={{objectPosition: `center ${props.y}%`}}
            />
        </div>
    );
};

export default LobbyCoverImage;
