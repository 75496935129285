import {ReactComponent as AgendaSettingsIcon} from "../../../../player/assets/media/agenda_settings.svg";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/AgendaActionsMenu.module.scss";
import Typography from "@ui/cdk/Typography";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import AgendaSettingsOptions from "./AgendaSettingsOptions";
import {AgendaItem, SessionData} from "@workhorse/declarations/dataTypes";
import {useTranslation} from "react-i18next";

type AgendaSettingsProps = {
    onAgendaSettingsClose: () => void;
    inLobby: boolean;
    isAdmin: boolean;
    agendaSettingsAnchorEl: null | HTMLElement;
    agendaItems: AgendaItem[];
    session: SessionData;
};
const AgendaSettings = (props: AgendaSettingsProps) => {
    const {t} = useTranslation();
    const {onAgendaSettingsClose, inLobby, isAdmin, agendaSettingsAnchorEl, agendaItems, session} = props;

    return (
        <div className="flex flex-row flex-items-center flex-justify-between fullw">
            <div className="flex flex-row flex-items-center">
                <AgendaSettingsIcon className="mr-10" />
                <Typography color="nonary" fontWeight="bold">
                    {t("agenda.menu.agenda_settings") ?? "Agenda settings"}
                </Typography>
            </div>
            {/* <div className={cls(classes.commandKey, "flex flex-row flex-items-center")}>
            <Typography color="nonary" fontWeight="bold" className="mr-4">
                ⌘C
            </Typography>
        </div> */}
            {agendaSettingsAnchorEl ? (
                <AgendaSettingsOptions
                    agendaSettingsAnchorEl={agendaSettingsAnchorEl}
                    onAgendaSettingsClose={onAgendaSettingsClose}
                    inLobby={inLobby}
                    isAdmin={isAdmin}
                    session={session}
                />
            ) : null}
            <ArrowRightRoundedIcon fontSize="small" />
        </div>
    );
};

export default AgendaSettings;
