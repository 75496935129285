import {RoomNotifier} from "@artifacts/breakout/ui/components/RoomNotifier/RoomNotifier";
import Radio from "@ui/cdk/Radio";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./BreakoutMenuItem.module.scss";
import HelpIcon from "@material-ui/icons/LiveHelp";
import HandIcon from "@material-ui/icons/PanTool";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    title: string;
    roomId: string;
    artifactId: string;
    current: boolean;
    participants: number;
    notify: boolean;
    showParticipants?: boolean;
    onClick: () => void;
    onChange?: (roomId: string, hasInfo: boolean) => void;
}

export function BreakoutMenuItem(props: Props) {
    const {t} = useTranslation();
    const {roomId, showParticipants = false, onChange} = props;
    const handleChange = useCallback(
        (qa: boolean, hand: boolean, hasParticipants: boolean) => onChange?.(roomId, qa || hand || (showParticipants && hasParticipants)),
        [roomId, showParticipants, onChange]
    );

    return (
        <div data-id="select-room-title" className={cls(classes.root, props.current && "active")} onClick={props.onClick}>
            <Radio className={cls(classes.radio, props.current && "active")} checked={props.current} />
            <div className={classes.container + " ml-12"}>
                <Typography className={classes.title} title={props.title} variant="lg" fontWeight="bold">
                    {props.title}
                </Typography>
                <Typography className={classes.info} variant="sm">
                    {t("participant.g.n_participants", {count: props.participants})}
                </Typography>
            </div>
            {props.notify && (
                <RoomNotifier id={props.roomId} artifactId={props.artifactId} skipSubs={props.current} onChange={handleChange}>
                    {(qa, hand, hasParticipants) => (
                        <div className="ml-auto mt-auto mb-auto">
                            {qa && <HelpIcon className={"ml-8 " + classes.infoIcon} />}
                            {hand && <HandIcon className={"ml-8 " + classes.infoIcon} />}
                            {showParticipants && hasParticipants && <GroupAddIcon className={"ml-8 " + classes.infoIcon} />}
                        </div>
                    )}
                </RoomNotifier>
            )}
        </div>
    );
}
