import {Offer, OneQuestionFragment} from "@generated/data";
import {makeVar} from "@workhorse/api/data";

export const joinedSessionVar = makeVar(false);
export const startingSession = makeVar(false);
/**
 * https://flowos.atlassian.net/browse/FOSS-5164
 * for registered users, while in waiting for approval state, and after being declined
 * if user clicked on the logo, he would be redirected to home
 * if while on home he clicks the browser back button, he would go back on the /waiting/:sessionId route
 * however he wouldn't have passed through the tryJoinSession intermediate layer
 */
export const setSeenPrejoin = makeVar<boolean>(false);

/**
 * https://flowos.atlassian.net/browse/FOSS-5163
 *
 */
export const setPreviousLocation = makeVar<string | null>(null);

/**
 * https://flowos.atlassian.net/browse/FOSS-5224
 * after being approved by the owner, the router MUST replace NOT push /session/:sessionId
 * if push... comes the bug
 */
export const setSeenWaiting = makeVar<boolean>(false);

/**
 * there are certain effects that need to execute on navigation between artifacts
 * by either the owner or assistants
 * the effects have no way of knowing who triggered the change
 * meaning in case of just one assistant (or more)
 * they will all execute it
 */
export const setPidThatChangedArtifact = makeVar<string | null>(null);

export const setMaxRowsParticipants = makeVar<number>(0);

export const isPreviewOpen = makeVar<boolean>(false);

export const enableNotifications = makeVar<boolean>(true);

export const questionShow = makeVar<{question: OneQuestionFragment; containerId: string} | null>(null);

export const offerShow = makeVar<{offer: Offer} | null>(null);

export type BreakoutLeaveNotifier = {
    onCancel?: () => void;
    onConfirm?: () => void;
    open?: boolean;
};
export const setBreakoutLeaveNotifier = makeVar<BreakoutLeaveNotifier>({});

// a room can be started by somebody else other than the owner or the assistant
// meaning there is NO start-session event emitted on the FE
// but the tryJoinSession mutation starts a room even if the user joining is a guest
// the mutation will return a boolean flag indicating that the auto-recording should start
// but that mutation is called before the session is fetched, meaning the recording provider
// will not start the recording because session.autoRecording will be undefined
