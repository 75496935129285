import Tooltip from "@ui/cdk/Tooltip";
import {capitalize, cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useUserAccess} from "@workhorse/api/access/hooks";
import {useEffect} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import {ReactComponent as CelloIcon} from "../assets/media/cello-gift.svg";
import classes from "./style/CelloButton.module.scss";

type CelloButtonProps = {
    className?: string;
    separator?: "none" | "left" | "right" | "both" | "leftWithSiblings" | "rightWithSiblings";
};

function CelloButton(props: CelloButtonProps) {
    const {className, separator = "none"} = props;
    const {t} = useTranslation();

    const access = useUserAccess();
    const hasReferralAccess = access.workspace().canSeeReferralProgram();

    const separatorClassName = classes[`root${capitalize(separator)}`];

    const handleOpenCello = () => {
        // @ts-ignore
        window?.Cello?.("open");
    };

    const handleCloseCello = () => {
        // @ts-ignore
        window?.Cello?.("close");
    };

    useEffect(() => {
        return () => {
            handleCloseCello();
        };
    }, []);

    if (!hasReferralAccess) {
        return null;
    }

    return (
        <div className={cls(classes.root, separatorClassName, className)}>
            <div className={classes.separator} />

            <Tooltip arrow placement="bottom" title={t("referral.refer_a_friend") ?? "Refer a friend"}>
                <IconButton
                    aria-label={t("referral.aria.toggle_cello_dialog") ?? "Toggle Cello dialog"}
                    className={classes.button}
                    onClick={handleOpenCello}
                    id="cello-btn"
                >
                    <CelloIcon />
                </IconButton>
            </Tooltip>

            <div className={classes.separator} />
        </div>
    );
}

export default CelloButton;
