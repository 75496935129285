import React from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util";
import classes from "./style/NavigationUserDetailsHeader.module.scss";
import Typography from "@ui/cdk/Typography";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";

const NavigationUserDetailsHeader = () => {
    const {t} = useTranslation();
    const {id, email, firstName, lastName, profilePicture} = useUserInfo();

    const hasEmailAddress = email?.trim().length > 0 && !email?.startsWith(id);

    return (
        <div className={cls("flex", classes.container)}>
            <ProfilePicture
                firstName={firstName}
                lastName={lastName}
                avatar={profilePicture}
                avatarSize="L"
                avatarVariant="rounded"
                key="button-user-menu-picture"
                disableTooltip
            />

            <div className={cls("flex flex-col flex-align-start flex-justify-center", classes.textContainer)}>
                <Typography fontWeight="bolder" color="secondary" noWrap>
                    {firstName ? `${firstName} ${lastName}` : t("participant.g.guest")}
                </Typography>

                {hasEmailAddress && (
                    <Typography color="nonary" noWrap>
                        {email}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default NavigationUserDetailsHeader;
