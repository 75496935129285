import {CONTACTS_BIN_GROUP_NAME} from "@common/declarations";
import {ContactInfoFragment, ContactSource, ContactGroupInfoFragment, ContactGroupSource} from "@generated/data";

export function isContactEditable(contact: ContactInfoFragment) {
    return !isContactFromWorkspace(contact) && !isContactExternal(contact);
}

export function isContactExternal(contact: ContactInfoFragment) {
    return [ContactSource.Microsoft, ContactSource.Google].includes(contact.source);
}

export function isContactFromWorkspace(contact: ContactInfoFragment) {
    return contact.group.some((group) => group.id.startsWith("workspace-"));
}

export function isWorkspaceGroup(group: Pick<ContactGroupInfoFragment, "id">) {
    return group.id.startsWith("workspace-");
}

export function isBinGroup(group: Pick<ContactGroupInfoFragment, "name">) {
    return group.name === CONTACTS_BIN_GROUP_NAME;
}

export function isJoinableGroup(group: ContactGroupInfoFragment) {
    return !isWorkspaceGroup(group) && !isBinGroup(group);
}

export function isExternalGroup(group: ContactGroupInfoFragment) {
    return [ContactGroupSource.Google, ContactGroupSource.Microsoft].includes(group.source);
}
