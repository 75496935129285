import {fieldResolvers as modelsWithFieldResolver} from "@workhorse/providers/graphql/diff.resolvers";
import {AgendaItemArtifact} from "../actions/agenda-item/add-artifact-to-agenda-item";

type ArtifactRelations = Pick<AgendaItemArtifact, "properties" | "resourceResults">;

const relations: Array<keyof ArtifactRelations> = ["resourceResults", "properties"];

export function replaceArtifact(
    existing: AgendaItemArtifact | null | undefined,
    incoming: AgendaItemArtifact,
    keys: typeof relations = relations
): AgendaItemArtifact {
    if (!existing) {
        return incoming;
    }

    keys.forEach((k) => {
        switch (k) {
            case "properties": {
                if (
                    !(k in existing) ||
                    !Array.isArray(existing[k]) ||
                    (existing[k].length === 0 && k in incoming && incoming[k].length > 0)
                ) {
                    existing[k] = incoming[k].filter((obj) => {
                        return obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename] && !obj.createdAt;
                    });
                    break;
                }
                if (k in existing && Array.isArray(existing[k])) {
                    existing[k].forEach((obj, idx) => {
                        // an object is considered replaceable if
                        // IS NOT currently marked as deleted (isDeleted === false)
                        // HAS an id
                        // HAS a typename
                        // AND that typename has a diff (aka modelsWithFieldResolver[typename] must exist)
                        // HAS a createdAt timestamp, otherwise it is not a replace

                        const hasDiff = obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename];
                        const isReplaceable = !obj.isDeleted && hasDiff && !!obj.createdAt;
                        if (isReplaceable) {
                            obj.isDeleted = true;
                        } else if (hasDiff && !obj.createdAt) {
                            existing[k].splice(idx, 1);
                        }
                    });
                }
                if (k in incoming && k in existing && Array.isArray(existing[k]) && Array.isArray(incoming[k])) {
                    incoming[k].forEach((obj) => {
                        const hasDiff = obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename];
                        if (hasDiff && !obj.createdAt && !obj.isDeleted) {
                            existing[k].push(obj);
                        }
                    });
                }
                break;
            }
            case "resourceResults": {
                if (
                    !(k in existing) ||
                    !Array.isArray(existing[k]) ||
                    (existing[k].length === 0 && k in incoming && incoming[k].length > 0)
                ) {
                    existing[k] = incoming[k].filter((obj) => {
                        return obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename] && !obj.createdAt;
                    });
                    break;
                }
                if (k in existing && Array.isArray(existing[k])) {
                    existing[k].forEach((obj, idx) => {
                        // an object is considered replaceable if
                        // IS NOT currently marked as deleted (isDeleted === false)
                        // HAS an id
                        // HAS a typename
                        // AND that typename has a diff (aka modelsWithFieldResolver[typename] must exist)
                        // HAS a createdAt timestamp, otherwise it is not a replace
                        // console.log("result: before stuff", obj);
                        const hasDiff = obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename];
                        const isReplaceable = !obj.isDeleted && hasDiff && !!obj.createdAt;
                        if (isReplaceable) {
                            obj.isDeleted = true;
                        } else if (hasDiff && !obj.createdAt) {
                            existing[k].splice(idx, 1);
                        }
                    });
                }
                if (k in incoming && k in existing && Array.isArray(existing[k]) && Array.isArray(incoming[k])) {
                    incoming[k].forEach((obj) => {
                        const hasDiff = obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename];
                        if (hasDiff && !obj.createdAt && !obj.isDeleted) {
                            existing[k].push(obj);
                        }
                    });
                }
                break;
            }
            default: {
                break;
            }
        }
        // @ts-expect-error because the types are seen as a union here... dunno why
        incoming[k] = existing[k];
    });
    const returning = Object.assign(existing, incoming);

    return returning;
}

export type FocusedAgendaItem = {
    agendaItemId: string;
    elem: "title" | "description" | "duration";
};
