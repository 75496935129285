import {useState, useEffect} from "@workhorse/api/rendering";
import Snackbar from "@ui/core/components/Snackbar";
import classes from "./styles/ApprovalsComponent.module.scss";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {cls} from "@ui/cdk/util/util";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import {useTranslation} from "react-i18next";

interface Props {
    showNotification: boolean;
    onNotificationClose: () => void;
}

const GuestNotification = (props: Props) => {
    const {t} = useTranslation();
    const {showNotification} = props;
    const [isOpen, setIsOpen] = useState<boolean>(showNotification ? true : false);
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress == 0 ? 100 : prevProgress - 20));
        }, 900);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress === 0) {
            handleCloseSnackBar();
        }
    }, [progress]);

    const handleCloseSnackBar = () => {
        setIsOpen(false);
        props.onNotificationClose();
    };

    return (
        <Snackbar
            open={isOpen}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            className={classes.root}
            autoHideDuration={5000}
            onClose={handleCloseSnackBar}
            disableWindowBlurListener
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={classes.circularMessageWrapper}>
                    <CircularProgressWithLabel type="short" variant="determinate" thickness={5} value={progress} />
                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            {t("player.notifications.control_status.you_ve_been_given_control") ?? "You’ve been given control"}
                        </Typography>
                        <Typography variant="base">
                            {t("player.notifications.control_status.feel_free_to_interact") ?? "Feel free to interact with the product."}
                        </Typography>
                    </div>
                </div>

                <div className={classes.closeIcon} onClick={handleCloseSnackBar}>
                    <CloseRoundedIcon />
                </div>
            </div>
        </Snackbar>
    );
};

export default GuestNotification;
