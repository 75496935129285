import {CalendarSettings, CalendarSettingsDocument, CalendarView, GetCalendarEventsDocument, GetCalendarEventsQuery} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {useUserInfo} from "@workhorse/providers/User";
import {endOfDay, isAfter, isBefore, isEqual, max, min, startOfDay} from "date-fns";
import {endOfWeekLocale, expandEvents} from ".";
import {makeVar, useQuery, useReactiveVar} from "../data";
import {useCallback, useEffect, useMemo, useState} from "../rendering";
import {createContextProvider} from "../utils/context";
import {calendarEndOfMonth, calendarStartOfMonth, dayOfTheWeekToNumber, startOfWeekLocale, WeekStartsOn} from "./date";
import {CalendarPeriod} from "./definitions";
import {calendarExternalEventsLoading, computeExternalCalendar, requestExternalCalendars} from "./external-provider";

export const calendarFetchingPeriod = makeVar<CalendarPeriod | false>(false);

export const calendarLoadedPeriod = makeVar<CalendarPeriod>({
    startDate: calendarStartOfMonth(Date.now()),
    endDate: calendarEndOfMonth(Date.now()),
});

export type FocusedCalendarEvent = {id: string; date: Date; occurrenceId?: Date};

export const calendarFocusedEvent = makeVar<FocusedCalendarEvent | undefined>(undefined);

export const focusCalendarOnEvent = (event: FocusedCalendarEvent) => {
    calendarChosenDay(event.date);
    calendarFocusedEvent(event);
};

export const calendarChosenDay = makeVar<Date>(new Date());
export const currentRequestPeriod = makeVar<CalendarPeriod | false>(false);

export const calendarFirstLoad = makeVar<boolean>(true);

let debounce: ReturnType<typeof setTimeout> | undefined;

const getFetchPeriod = (chosenDay: Date, view: CalendarView, weekStartsOn: WeekStartsOn) => {
    const fetchPeriod: Record<CalendarView, CalendarPeriod> = {
        [CalendarView.Days]: {
            startDate: startOfDay(chosenDay),
            endDate: endOfDay(chosenDay),
        },
        [CalendarView.Weeks]: {
            startDate: startOfWeekLocale(chosenDay, weekStartsOn),
            endDate: endOfWeekLocale(chosenDay, weekStartsOn),
        },
        [CalendarView.Months]: {
            startDate: calendarStartOfMonth(chosenDay, weekStartsOn),
            endDate: calendarEndOfMonth(chosenDay, weekStartsOn),
        },
    };

    return fetchPeriod[view];
};

export type UseCalendarProps = {
    disableExternal?: boolean;
    calendarView?: CalendarView;
};

function useCalendarEventsStore(props: UseCalendarProps) {
    const fetchingPeriod = useReactiveVar(calendarFetchingPeriod);
    const chosenDay = useReactiveVar(calendarChosenDay);

    const user = useUserInfo();
    const weekStartsOn = dayOfTheWeekToNumber(user.firstDayOfWeek ?? 1);

    const [calendarEventsQuery, setCalendarEventsQuery] = useState<GetCalendarEventsQuery>(
        apollo.client
            .watchQuery({
                query: GetCalendarEventsDocument,
                fetchPolicy: "cache-only",
                returnPartialData: true,
                variables: {
                    from: new Date(0),
                    until: new Date(0),
                    includeExternal: true,
                    includeNormal: true,
                    includeRecurrent: true,
                },
            })
            .getCurrentResult().data ?? {
            calendarEvents: [],
            calendarRecurrentEvents: [],
            getAppleEvents: [],
            getGoogleEvents: [],
            getMicrosoftEvents: [],
        }
    );

    useEffect(() => {
        const observable = apollo.client
            .watchQuery({
                query: GetCalendarEventsDocument,
                fetchPolicy: "cache-only",
                returnPartialData: true,
                variables: {
                    from: new Date(0),
                    until: new Date(0),
                    includeExternal: true,
                    includeNormal: true,
                    includeRecurrent: true,
                },
            })
            .subscribe((result) => {
                setCalendarEventsQuery(result.data);
            });

        return () => {
            observable.unsubscribe();
        };
    }, []);

    // useEffect(() => {
    //     console.log("EXECUTING EXPAND", loadedPeriod);
    //     expandEvents(loadedPeriod, []);
    // }, [loadedPeriod]);

    const externalEventsLoading = useReactiveVar(calendarExternalEventsLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const disableExternal = useMemo(() => props.disableExternal, []);

    const calendarViewFirstFetch = useMemo(() => props.calendarView || CalendarView.Weeks, []);
    // console.log("use calendar", {chosenDay, props, fetchingPeriod, calendarViewFirstFetch});

    const fetchCalendarEvents = useCallback(
        (variables: {from: Date; until: Date; includeNormal: boolean; includeRecurrent: boolean; includeExternal: boolean}) =>
            apollo.client.query({query: GetCalendarEventsDocument, fetchPolicy: "network-only", variables}),
        []
    );

    const fetchData = useCallback(
        (period: CalendarPeriod) => {
            if (debounce) {
                clearTimeout(debounce);
            }

            let requestedPeriod = currentRequestPeriod();
            if (requestedPeriod) {
                currentRequestPeriod(
                    (requestedPeriod = {
                        startDate: min([requestedPeriod.startDate, period.startDate]),
                        endDate: max([requestedPeriod.endDate, period.endDate]),
                    })
                );
            } else {
                currentRequestPeriod(period);
                requestedPeriod = period;
            }

            const job = async () => {
                if (!requestedPeriod) {
                    return;
                }

                const loadedPeriod = calendarLoadedPeriod();
                const firstLoad = calendarFirstLoad();

                // console.log("use calendar job starting...", {requestedPeriod, loadedPeriod, period, firstLoad});

                if (
                    (isBefore(loadedPeriod.startDate, requestedPeriod.startDate) ||
                        isEqual(loadedPeriod.startDate, requestedPeriod.startDate)) &&
                    (isAfter(loadedPeriod.endDate, requestedPeriod.endDate) || isEqual(loadedPeriod.endDate, requestedPeriod.endDate)) &&
                    !firstLoad
                ) {
                    console.log("use calendar job not loading events for this period");
                    return;
                }

                calendarFirstLoad(false);

                if (!firstLoad) {
                    const newLoadedPeriod = {
                        startDate: min([loadedPeriod.startDate, requestedPeriod.startDate]),
                        endDate: max([loadedPeriod.endDate, requestedPeriod.endDate]),
                    };

                    await expandEvents("all", newLoadedPeriod);
                    calendarLoadedPeriod(newLoadedPeriod);
                }

                fetchCalendarEvents({
                    from: period.startDate,
                    until: period.endDate,
                    includeExternal: disableExternal == null || disableExternal === false,
                    includeNormal: true,
                    includeRecurrent: false,
                }).then((result) => {
                    if (!result.data) {
                        return;
                    }

                    if (result.data.getAppleEvents || result.data.getGoogleEvents || result.data.getMicrosoftEvents) {
                        computeExternalCalendar(result.data);
                    }
                });

                debounce = undefined;
                currentRequestPeriod(false);
            };

            debounce = setTimeout(job, 1500);
        },
        [fetchCalendarEvents, disableExternal]
    );

    useEffect(() => {
        const firstLoad = calendarFirstLoad();

        if (!firstLoad) {
            return;
        }

        const firstFetchPeriod = getFetchPeriod(chosenDay, calendarViewFirstFetch, weekStartsOn);

        calendarLoadedPeriod(firstFetchPeriod);
        calendarFetchingPeriod(firstFetchPeriod);

        console.log("FIRST FETCH", calendarViewFirstFetch, {
            includeExternal: disableExternal == null || disableExternal === false,
            includeRecurrent: true,
            includeNormal: true,
            firstFetchPeriod,
        });

        fetchCalendarEvents({
            from: firstFetchPeriod.startDate,
            until: firstFetchPeriod.endDate,
            includeExternal: disableExternal == null || disableExternal === false,
            includeRecurrent: true,
            includeNormal: true,
        }).then(async (result) => {
            setCalendarEventsQuery(result.data);

            if (result.data.getGoogleEvents || result.data.getAppleEvents || result.data.getMicrosoftEvents) {
                computeExternalCalendar(result.data);
            }

            if (
                result.data.calendarRecurrentEvents?.length ||
                result.data.getGoogleEvents?.events.length ||
                result.data.getAppleEvents?.events.length ||
                result.data.getMicrosoftEvents?.events.length
            ) {
                await expandEvents("all", calendarLoadedPeriod());
            }

            calendarFirstLoad(false);
            // Fetch the entire month after first fetch
            // calendarChosenDay(new Date(chosenDay));
        });
    }, []);

    useEffect(() => {
        const firstLoad = calendarFirstLoad();

        if (firstLoad) {
            return;
        }

        calendarFetchingPeriod({
            startDate: calendarStartOfMonth(chosenDay, weekStartsOn),
            endDate: calendarEndOfMonth(chosenDay, weekStartsOn),
        });
    }, [chosenDay]);

    useEffect(() => {
        const firstLoad = calendarFirstLoad();

        if (firstLoad) {
            return;
        }

        if (fetchingPeriod) {
            fetchData(fetchingPeriod);
        }
    }, [fetchingPeriod]);

    useEffect(() => {
        const firstLoad = calendarFirstLoad();

        if (firstLoad) {
            return;
        }

        if (!externalEventsLoading || externalEventsLoading !== "load") {
            return;
        }

        calendarExternalEventsLoading("loading");

        const fetchPeriod = calendarLoadedPeriod();

        requestExternalCalendars(fetchPeriod, disableExternal).then(async (externalCalendar) => {
            if (!externalCalendar) {
                return;
            }
            computeExternalCalendar(externalCalendar);

            if (
                externalCalendar.getGoogleEvents?.events.length ||
                externalCalendar.getAppleEvents?.events.length ||
                externalCalendar.getMicrosoftEvents?.events.length
            ) {
                await expandEvents("all", calendarLoadedPeriod());
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalEventsLoading]);

    return useMemo(() => {
        return calendarEventsQuery as Omit<GetCalendarEventsQuery, "__typename">;
    }, [calendarEventsQuery]);
}

const [CalendarEventsProvider, useCalendar] = createContextProvider(
    {
        name: "CalendarEvents",
        strict: true,
    },
    useCalendarEventsStore
);

export {CalendarEventsProvider, useCalendar};

export const useCalendarSettings = (): {
    settings: CalendarSettings | undefined;
    updateSettings: (newSettings: Partial<CalendarSettings>) => void;
    views: CalendarView[];
} => {
    const {data: calendarSettingsData} = useQuery(CalendarSettingsDocument, {returnPartialData: true});

    const updateSettings = useCallback(
        (newSettings: Partial<CalendarSettings>) => {
            if (!calendarSettingsData?.calendarSettings) {
                return;
            }

            apollo.client.writeQuery({
                query: CalendarSettingsDocument,
                data: {
                    calendarSettings: {
                        ...calendarSettingsData.calendarSettings,
                        ...newSettings,
                    },
                },
            });
        },
        [calendarSettingsData?.calendarSettings]
    );

    return {
        settings: calendarSettingsData?.calendarSettings,
        updateSettings,
        views: Object.entries(CalendarView).map(([_, value]) => value),
    };
};
