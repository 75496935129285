export enum TwitchPlayerTheme {
    light = "light",
    dark = "dark",
}

export enum TwitchPlayerChatMode {
    default = "default",
    mobile = "mobile",
}

export enum TwitchPlayerLayout {
    video = "video",
    videoWithChat = "video-with-chat",
}

export enum TwitchPlayerType {
    channel = "Channel",
    video = "Video",
    collection = "Collection",
    clip = "Clip",
}

export interface TwitchPlayerClip {
    [TwitchPlayerType.clip]: string;
}

export interface TwitchPlayerVideo {
    [TwitchPlayerType.video]: string;
}

export interface TwitchPlayerChannel {
    [TwitchPlayerType.channel]: string;
}

export interface TwitchPlayerCollection {
    [TwitchPlayerType.video]: string;
    [TwitchPlayerType.collection]: string;
}

export interface TwitchParsedUrlObject {
    playerType: TwitchPlayerType | "";
    playerData: TwitchPlayerStream | {};
}

export type TwitchPlayerStream = TwitchPlayerChannel | TwitchPlayerCollection | TwitchPlayerVideo | TwitchPlayerClip;
