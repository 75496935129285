import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@ui/cdk/Button";
import Badge from "@ui/core/components/Badge";
import Slide from "@ui/core/components/Slide";
import Snackbar from "@ui/core/components/Snackbar";
import React, {useEffect, useState} from "@workhorse/api/rendering";
import classes from "./style/ConfirmationSnackbar.module.scss";
import Typography from "./Typography";
import IconButton from "@ui/core/components/IconButton";
import {cls} from "./util";

export type ConfirmationSnackbarImperativeRef = React.MutableRefObject<
    | {
          toggle?: (mode?: boolean) => void;
          isOpen?: boolean;
      }
    | undefined
    | null
>;

type ConfirmationSnackbarProps = {
    imperativeRef: ConfirmationSnackbarImperativeRef;
    onConfirm?: () => void;
    onReject?: () => void;
    confirmButtonText?: string;
    rejectButtonText?: string;
    children?: React.ReactNode | React.ReactNode[];
    title?: string | JSX.Element;
    message?: string | JSX.Element;
    icon?: JSX.Element;
    notCloseOnClickaway?: boolean;
    badge?: JSX.Element;
    hasCloseBtn?: boolean;
    classes?: {
        snackbar?: string;
        root?: string;
        badge?: string;
        text?: string;
    };
};

const SlideTransition = (props: any) => <Slide {...props} direction="down" />;

function ConfirmationSnackbar(props: ConfirmationSnackbarProps) {
    const {
        imperativeRef,
        onReject,
        onConfirm,
        confirmButtonText,
        rejectButtonText,
        title,
        message,
        icon,
        notCloseOnClickaway,
        badge,
        hasCloseBtn,
    } = props;
    const [open, setOpen] = useState(false);

    const close = (event, reason) => {
        if (reason !== "clickaway" && notCloseOnClickaway) {
            setOpen(false);
        }
    };

    const toggle = (mode?: boolean) => {
        setOpen((c) => (mode !== undefined ? mode : !c));
    };

    useEffect(() => {
        if (imperativeRef) {
            if (!imperativeRef.current) {
                imperativeRef.current = {};
            }
            imperativeRef.current.toggle = toggle;
            imperativeRef.current.isOpen = open;
        }
        return () => {
            imperativeRef.current = null;
        };
    }, [open]);

    const reject = () => {
        setOpen(false);
        if (onReject) {
            onReject();
        }
    };

    const confirm = () => {
        setOpen(false);
        if (onConfirm) {
            onConfirm();
        }
    };

    const Content = (
        <div className={cls(classes.content, props.classes?.root)}>
            <Badge
                className={cls(classes.badgeRoot, props.classes?.badge)}
                badgeContent={badge}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                invisible={badge ? false : true}
                classes={{
                    badge: classes.badgeBadge,
                }}
            >
                {icon}
            </Badge>
            {title || message ? (
                <div className={cls(classes.text, props.classes?.text)}>
                    {title && (
                        <Typography variant="lg" fontWeight="bolder">
                            {title}
                        </Typography>
                    )}
                    {message && <Typography className="mt-4">{message}</Typography>}
                </div>
            ) : (
                props.children
            )}
            {(rejectButtonText || confirmButtonText) && (
                <div className={classes.btnsContainer}>
                    {rejectButtonText ? (
                        <Button data-id="decline-request-button" onClick={reject} variant="tertiary">
                            {rejectButtonText}
                        </Button>
                    ) : null}
                    {confirmButtonText ? (
                        <Button data-id="accept-request-button" withMarginLeft onClick={confirm} variant="primary">
                            {confirmButtonText}
                        </Button>
                    ) : null}
                </div>
            )}
            {hasCloseBtn && (
                <IconButton size="small" onClick={confirm} className={classes.closeBtn}>
                    <CloseRoundedIcon />
                </IconButton>
            )}
        </div>
    );

    return (
        <Snackbar
            open={open}
            onClose={close}
            TransitionComponent={SlideTransition}
            className={cls(classes.snackbar, props.classes?.snackbar)}
            message={Content}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            ContentProps={{
                className: classes.paper,
            }}
        />
    );
}

export default ConfirmationSnackbar;
