import {cls} from "@ui/cdk/util/util";
import {LocalVideo, RemoteVideo, useCameraSettings, useDevices, useIsAnySpeaker} from "@workhorse/api/conference2";
import style from "./styles/AudienceParticipant.module.scss";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import {useRemoteParticipantStatus} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";

type VideoProps = {
    participantId: string;
};

type VideoManagerProps = {
    participantId: string;
    isLocalParticipant: boolean;
};

export function AudienceParticipantVideo(props: VideoManagerProps) {
    const {isSpeaker: isAnySpeaker} = useIsAnySpeaker(props.participantId);
    const {ctx, myPinnedVideoStreams} = useSessionView();
    const isSpeaker = isAnySpeaker && myPinnedVideoStreams.includes(props.participantId);

    if (isSpeaker && ctx === "conference") {
        return null;
    }

    if (props.isLocalParticipant) {
        return <AudienceParticipantLocalVideo participantId={props.participantId} key={`local-video-${props.participantId}`} />;
    }

    return <AudienceParticipantRemoteVideo participantId={props.participantId} />;
}

function AudienceParticipantLocalVideo(props: {participantId: string}) {
    const {videoStream, videoTransformedStream} = useDevices();
    const {cameraHidden} = useCameraSettings();
    const isVideoEnabled = !!videoStream || !!videoTransformedStream;

    if (!isVideoEnabled || cameraHidden) {
        return null;
    }

    return (
        <div className={style.participantVideoWrapper}>
            <LocalVideo />
        </div>
    );
}

function AudienceParticipantRemoteVideo(props: VideoProps) {
    return (
        <div className={cls(style.participantVideoWrapper, style.withBackground)}>
            <RemoteVideo participantId={props.participantId} isAudience={true} />
        </div>
    );
}
