import Fab from "@material-ui/core/Fab";
import PlayArrowIcon from "@material-ui/icons/PlayArrowRounded";
import React, {useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/VideoPlayButton.module.scss";
import {WithClassName} from "@workhorse/declarations";
import {OnboardingPlayButton} from "@workhorse/components/onboarding-tooltips/onboarding-play-button";
import {useOnboardingSession, SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";

type VideoPlayButtonProps = WithClassName & {
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    canClick: boolean;
    hidden?: boolean;
    bottomCut?: number;
    isMemoryRecording?: boolean;
};

const VideoPlayButton = (props: VideoPlayButtonProps) => {
    const {onClick, canClick, hidden, bottomCut, isMemoryRecording} = props;
    const [hasBeenClicked, setHasBeenClicked] = useState(false);
    const {tooltip, onboardingType, next} = useOnboardingSession();

    const style = {
        cursor: canClick ? "pointer" : "initial",
        ...(bottomCut
            ? {
                  transform: `translateY(${bottomCut / 2}px)`,
              }
            : null),
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick(event);

        if (!hasBeenClicked) {
            setHasBeenClicked(true);
        }

        if (onboardingType === SessionOnboardingType.NewUser && next && tooltip === "tool-controls") {
            next();
        }
    };

    return (
        <div
            className={cls("flex flex-center-all", "play-btn-container", classes.playBtnContainer, props.className)}
            onClick={handleClick}
            style={bottomCut ? {bottom: bottomCut} : undefined}
        >
            <OnboardingPlayButton hasBeenClicked={hasBeenClicked}>
                <Fab
                    data-id={isMemoryRecording ? "memory-button-playrecording" : "play-video-button"}
                    className={cls("custom", classes.playBtn, hidden && "hidden-videoplay-btn")}
                    style={style}
                    variant="circular"
                    size="large"
                    color="primary"
                >
                    <PlayArrowIcon className={classes.playBtnSvg} />
                </Fab>
            </OnboardingPlayButton>
        </div>
    );
};

export default VideoPlayButton;
