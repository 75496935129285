import {AcceptedTimezone, getLocalTimezone} from "@common/utils/timezones";
import {
    GetPublicBookingAvailableDateSlotsDocument,
    PublicBookingEventAvailableSlotFragment,
    PublicBookingEventCollaborator,
} from "@generated/data";
import TimeRange from "@ui/cdk/TimeRange";
import {cls} from "@ui/cdk/util";
import {useEffect, useMemo} from "@workhorse/api/rendering";
import QueryRenderer, {WithQueryPayloadFromDocument} from "@workhorse/components/QueryRenderer";
import {addMinutes, isEqual, subMinutes} from "date-fns";
import classes from "../styles/BookTimeSlots.module.scss";
import {getAvailableSlotsForDay} from "@workhorse/api/booking";
import {getTimezoneDTSOffset} from "@common/utils/timezones";
import BookTimeTroubleshootSlot from "./BookTimeTroubleshootSlot";

export type BookTimeSlotsProps = WithQueryPayloadFromDocument<typeof GetPublicBookingAvailableDateSlotsDocument> & {
    duration: number;
    defaultTimezone: AcceptedTimezone;
    timezone: AcceptedTimezone;
    selected?: Date;
    chosenDate?: Date | null;
    onChange: (newSlot: PublicBookingEventAvailableSlotFragment | null) => void;
    isHalfDayClock?: boolean;
    isTroubleshootMode?: boolean;
    collaborators?: PublicBookingEventCollaborator[];
    bookingEventId: string;
};

export const BookTimeSlots = QueryRenderer(
    ({
        data,
        duration,
        isTroubleshootMode,
        isHalfDayClock,
        timezone,
        selected,
        chosenDate,
        collaborators,
        bookingEventId,
        onChange,
    }: BookTimeSlotsProps) => {
        const offset =
            getTimezoneDTSOffset(getLocalTimezone(), chosenDate ?? new Date()) - getTimezoneDTSOffset(timezone, chosenDate ?? new Date());

        const {availableSlots, availableHours} = useMemo(() => {
            if (!chosenDate) {
                return {availableSlots: [], availableHours: []};
            }

            const availableSlots = getAvailableSlotsForDay(chosenDate, data?.bookTimeSlots ?? [], timezone);

            return {
                availableSlots,
                availableHours: availableSlots.map(({day}) => {
                    return new Date(day);
                }),
            };
        }, [data, timezone, chosenDate, offset]);

        const isSelectedAvailable = useMemo(() => {
            if (!selected) return false;

            return availableHours.some((slot) => isEqual(slot, selected));
        }, [availableHours, selected]);

        useEffect(() => {
            const firstSlot = availableSlots[0];

            if (firstSlot && !isSelectedAvailable) {
                onChange(isTroubleshootMode ? null : firstSlot);
            }
        }, [availableSlots, isSelectedAvailable]);

        return (
            <div className={classes.root} data-id="time-interval-table">
                {availableSlots.map((slot) =>
                    isTroubleshootMode && (slot.available === false || (collaborators?.length ?? 0) > 1) ? (
                        <BookTimeTroubleshootSlot
                            key={slot.id}
                            slot={slot}
                            duration={duration}
                            offset={offset}
                            isHalfDayClock={isHalfDayClock}
                            collaborators={collaborators}
                            bookingEventId={bookingEventId}
                        />
                    ) : (
                        <div
                            data-id="time-interval-slot"
                            key={slot.id}
                            className={cls(classes.timeSlot, selected && isEqual(new Date(slot.day), selected) && classes.timeSlotSelected)}
                            onClick={() => onChange(slot)}
                        >
                            <TimeRange
                                start={subMinutes(new Date(slot.day), offset)}
                                end={addMinutes(new Date(slot.day), duration - offset)}
                                isHalfDayClock={isHalfDayClock}
                            />
                        </div>
                    )
                )}
            </div>
        );
    },
    GetPublicBookingAvailableDateSlotsDocument,
    {
        fetchPolicy: "cache-only",
        nextFetchPolicy: "cache-only",
    }
);
