import loadable from "@workhorse/api/loadable";
import {ReactComponent as BackgroundEffectsIcon} from "../assets/session-effects-icon.svg";
import {SessionSettingsSection} from "../../definitions";
import {browserBehavior} from "@workhorse/api/conference2";

const BackgroundEffectsSection = loadable(() => import("./BackgroundEffectsSection"));
BackgroundEffectsSection.displayName = "Loadable:BackgroundEffectsSection";

const BackgroundEffectsSectionDefinition: SessionSettingsSection<"device.backgroundEffects"> = {
    translateKey: "device.background_effects",
    kind: "device.backgroundEffects",
    title: "Background effects",
    component: BackgroundEffectsSection,
    icon: BackgroundEffectsIcon,
    show: browserBehavior.supportsBackgroundFilter(),
    settingsStateCheck(pv, cv) {
        return pv["device.video"]?.cameraMirrorSelfView === cv["device.video"]?.cameraMirrorSelfView;
    },
};

export default BackgroundEffectsSectionDefinition;
