import {useQuery} from "@apollo/client";
import {AgendaTemplateFragment, GetAgendaTemplatesDocument} from "@generated/data";
import {useMemo} from "@workhorse/api/rendering";
import {useParams} from "@workhorse/api/routing";
import {TemplatesParams, getTemplateSectionByRoute} from "../utils";
import {AgendaTemplatesTable} from "./AgendaTemplatesTable/AgendaTemplatesTable";
import {useUserInfo} from "@workhorse/providers/User";

type AgendaTemplatesListProps = {
    onClick: (templateId: string) => Promise<void>;
    workspaceId: string;
};

const AgendaTemplatesList = (props: AgendaTemplatesListProps) => {
    const {onClick} = props;
    const params = useParams<TemplatesParams>();

    const {activeWorkspace} = useUserInfo();
    const templateSection = useMemo(() => getTemplateSectionByRoute(params), [params]);

    const {data} = useQuery(GetAgendaTemplatesDocument, {
        variables: {
            workspaceId: activeWorkspace.id,
        },
    });

    const templates = data?.agendaTemplates ?? [];

    console.log("templates", templates);
    return (
        <AgendaTemplatesTable
            agendaTemplates={templates as AgendaTemplateFragment[]}
            title={templateSection?.title ?? "Agendas"}
            onClickAgenda={onClick}
        />
    );
};

export default AgendaTemplatesList;
