import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import InfoTooltip from "@workhorse/components/InfoTooltip";
import genericStyles from "../../styles/GenericSection.module.scss";
import {useTranslation} from "react-i18next";
import {ReactComponent as ExitPageOfferIcon} from "../assets/exit-page-offer-icon.svg";
import ExitPageOffersContainer from "./ExitPageOffersContainer";
import designer from "@workhorse/api/designer";
import {ExitPageCtaType, SessionLifecycle} from "@generated/data";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import Select from "@ui/cdk/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const styles = {...genericStyles};

type ExitPageOfferTogglerProps = {
    value: ExitPageCtaType;
    onChange: (enabled: ExitPageCtaType) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    isRoom?: boolean;
    exitPageOfferId: string | undefined;
    sessionLifecycle?: SessionLifecycle;
    onSelectOffer?: (offerId: string) => void;
};

const ExitPageOfferToggler = ({
    value,
    onChange,
    showIcon = false,
    disabled,
    className,
    exitPageOfferId,
    sessionLifecycle,
    onSelectOffer,
}: ExitPageOfferTogglerProps) => {
    const {t} = useTranslation();

    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.EXIT_PAGE_OFFER,
    });

    const options = [
        {value: ExitPageCtaType.None, text: t("g.none") || "None"},
        {value: ExitPageCtaType.CustomCta, text: t("player.settings.general.exit_page.final_cta_toggler.custom_cta") || "Custom CTA"},
    ];

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as ExitPageCtaType;
        if (!featureIsAvailable) {
            emitFeatureLimitError(t("limitations.notifications.please_upgrade_plan") ?? "");
            return;
        }

        if (value === ExitPageCtaType.CustomCta) {
            designer.api.session.update({
                exitPageOfferId: undefined,
            });
        }

        if (sessionLifecycle === SessionLifecycle.Started) {
            designer.commit();
        }
        onChange(value);
    };

    return (
        <div className="flex flex-col flex-justify-start fullw pt-15">
            <div className={cls(styles.togglerWrapper, className)}>
                {showIcon ? (
                    <div className={styles.iconWrapper}>
                        <ExitPageOfferIcon />
                    </div>
                ) : null}
                <div className="flex flex-justify-between flex-align-center fullw">
                    <div className={styles.textWrapper}>
                        <div className="flex flex-row flex-align-center gap-5">
                            <Typography variant="base" fontWeight="bold" color="secondary">
                                {t("player.settings.general.exit_page.final_cta_toggler") ?? "Final call to action"}
                            </Typography>
                        </div>
                        <Typography color="quaternary" component="span">
                            {t("player.settings.general.exit_page.final_cta_description") ??
                                "Give participants a last chance to visit your website or access a special deal"}
                        </Typography>
                    </div>

                    <Select
                        data-id="collect-feedback-settings"
                        options={options}
                        value={options.findIndex((option) => option.value === value) !== -1 ? value : ExitPageCtaType.None}
                        IconComponent={ExpandMoreRoundedIcon}
                        onChange={handleOnChange}
                        className={genericStyles.selectInput}
                        MenuProps={{
                            classes: {
                                paper: genericStyles.selectMenuPaper,
                            },
                        }}
                        wrapperClassName={genericStyles.selectFormControl}
                        disabled={disabled}
                    />
                </div>
            </div>

            {value === ExitPageCtaType.CustomCta ? (
                <ExitPageOffersContainer
                    exitPageOfferId={exitPageOfferId}
                    sessionLifecycle={sessionLifecycle}
                    onSelectOffer={onSelectOffer}
                />
            ) : null}
        </div>
    );
};

export default ExitPageOfferToggler;
