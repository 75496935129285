import {BackgroundFilter} from "@workhorse/api/conference2/lib/VideoReplacement";
import {useCallback, useMemo} from "@workhorse/api/rendering";
import {useLocalStorage} from "@workhorse/api/storage";
import {createContextProvider} from "@workhorse/api/utils/context";
import {WithChildren} from "@workhorse/declarations";
import {useUserInfo} from "./User";

export enum NotificationSettings {
    CHAT_DISABLED = "chatNotificationsDisabled",
    QUESTIONS_DISABLED = "questionsNotificationsDisabled",
    PARTICIPANTS_JOINING_DISABLED = "participantsJoiningNotificationsDisabled",
    REACTIONS_DISABLED = "reactionsNotificationsDisabled",
}

const useDeviceSettingsStore = () => {
    const {isGuest, id: userId} = useUserInfo();

    const ctx = isGuest ? "guest" : userId;

    const [videoDeviceId, setVideoDeviceId] = useLocalStorage({
        key: "user.video.deviceId",
        defaultValue: "",
        ctx,
    });

    const [cameraEnabled, setCameraEnabled] = useLocalStorage({
        key: "user.video.enabled",
        defaultValue: true,
        ctx,
    });

    const [cameraMirrorSelfView, setCameraMirrorSelfView] = useLocalStorage({
        key: "user.video.mirrorSelfView",
        defaultValue: true,
        ctx,
    });

    const [cameraHidden, setCameraHidden] = useLocalStorage({
        key: "user.video.hidden",
        defaultValue: false,
        ctx,
    });

    const [cameraBackgroundFilter, setCameraBackgroundFilter] = useLocalStorage<BackgroundFilter>({
        key: "user.video.backgroundFilter",
        defaultValue: BackgroundFilter.NONE,
        ctx,
    });

    const [audioInputDeviceId, setAudioInputDeviceId] = useLocalStorage({
        key: "user.audio.input.deviceId",
        defaultValue: "",
        ctx,
    });

    const [audioOutputDeviceId, setAudioOutputDeviceId] = useLocalStorage({
        key: "user.audio.output.deviceId",
        defaultValue: "",
        ctx,
    });

    const [audioNoiseReduction, setAudioNoiseReduction] = useLocalStorage({
        key: "user.audio.noiseReduction",
        defaultValue: false,
        ctx,
    });

    const [microphoneEnabled, setMicrophoneEnabled] = useLocalStorage({
        key: "user.audio.input.enabled",
        defaultValue: true,
        ctx,
    });

    return useMemo(
        () => ({
            videoDeviceId,
            setVideoDeviceId,
            cameraEnabled,
            setCameraEnabled,
            cameraMirrorSelfView,
            setCameraMirrorSelfView,
            cameraHidden,
            setCameraHidden,
            cameraBackgroundFilter,
            setCameraBackgroundFilter,
            audioInputDeviceId,
            setAudioInputDeviceId,
            audioOutputDeviceId,
            setAudioOutputDeviceId,
            audioNoiseReduction,
            setAudioNoiseReduction,
            microphoneEnabled,
            setMicrophoneEnabled,
        }),
        [
            videoDeviceId,
            cameraEnabled,
            cameraMirrorSelfView,
            cameraHidden,
            cameraBackgroundFilter,
            audioInputDeviceId,
            audioOutputDeviceId,
            audioNoiseReduction,
            microphoneEnabled,
        ]
    );
};

const useNotificationSettingsStore = () => {
    const [chatNotificationsDisabled, setChatNotificationsDisabled] = useLocalStorage({
        key: `${NotificationSettings.CHAT_DISABLED}`,
        defaultValue: localStorage.getItem(`${NotificationSettings.CHAT_DISABLED}`) === "true",
    });

    const [questionsNotificationsDisabled, setQuestionsNotificationsDisabled] = useLocalStorage({
        key: `${NotificationSettings.QUESTIONS_DISABLED}`,
        defaultValue: localStorage.getItem(`${NotificationSettings.QUESTIONS_DISABLED}`) === "true",
    });

    const [participantsJoiningNotificationsDisabled, setParticipantsJoiningNotificationsDisabled] = useLocalStorage({
        key: `${NotificationSettings.PARTICIPANTS_JOINING_DISABLED}`,
        defaultValue: localStorage.getItem(`${NotificationSettings.PARTICIPANTS_JOINING_DISABLED}`) === "true",
    });

    const [reactionsNotificationsDisabled, setReactionsNotificationsDisabled] = useLocalStorage({
        key: `${NotificationSettings.REACTIONS_DISABLED}`,
        defaultValue: localStorage.getItem(`${NotificationSettings.REACTIONS_DISABLED}`) === "true",
    });

    const toggleNotifications = useCallback((key: NotificationSettings) => {
        switch (key) {
            case NotificationSettings.CHAT_DISABLED:
                setChatNotificationsDisabled((prevState) => !prevState);
                break;
            case NotificationSettings.QUESTIONS_DISABLED:
                setQuestionsNotificationsDisabled((prevState) => !prevState);
                break;
            case NotificationSettings.PARTICIPANTS_JOINING_DISABLED:
                setParticipantsJoiningNotificationsDisabled((prevState) => !prevState);
                break;
            case NotificationSettings.REACTIONS_DISABLED:
                setReactionsNotificationsDisabled((prevState) => !prevState);
                break;
        }
    }, []);

    const setAllNotificationsDisabled = useCallback((disabled: boolean) => {
        setChatNotificationsDisabled(disabled);
        setQuestionsNotificationsDisabled(disabled);
        setParticipantsJoiningNotificationsDisabled(disabled);
        setReactionsNotificationsDisabled(disabled);
    }, []);

    return useMemo(
        () => ({
            chatNotificationsDisabled,
            questionsNotificationsDisabled,
            participantsJoiningNotificationsDisabled,
            reactionsNotificationsDisabled,
            toggleNotifications,
            setAllNotificationsDisabled,
        }),
        [
            chatNotificationsDisabled,
            questionsNotificationsDisabled,
            participantsJoiningNotificationsDisabled,
            reactionsNotificationsDisabled,
            toggleNotifications,
            setAllNotificationsDisabled,
        ]
    );
};

const [DeviceSettingsProvider, useDeviceSettingsStorage] = createContextProvider(
    {
        name: "DeviceSettingsProvider",
    },
    useDeviceSettingsStore
);

const useVideoDeviceIdStore = () => {
    const {videoDeviceId, setVideoDeviceId} = useDeviceSettingsStorage();

    return useMemo(
        () => ({
            videoDeviceId,
            setVideoDeviceId,
        }),
        [videoDeviceId, setVideoDeviceId]
    );
};

const [VideoDeviceIdStorageProvider, useDeviceSettingsIdStorage] = createContextProvider(
    {
        name: "VideoDeviceIdStorageProvider",
    },
    useVideoDeviceIdStore
);

const useCameraEnabledStore = () => {
    const {cameraEnabled, setCameraEnabled} = useDeviceSettingsStorage();

    return useMemo(() => ({cameraEnabled, setCameraEnabled}), [cameraEnabled, setCameraEnabled]);
};

const [CameraEnabledStorageProvider, useCameraEnabledStorage] = createContextProvider(
    {
        name: "CameraEnabledProvider",
    },
    useCameraEnabledStore
);

const useCameraMirrorSelfViewStore = () => {
    const {cameraMirrorSelfView, setCameraMirrorSelfView} = useDeviceSettingsStorage();

    return useMemo(
        () => ({
            cameraMirrorSelfView,
            setCameraMirrorSelfView,
        }),
        [cameraMirrorSelfView, setCameraMirrorSelfView]
    );
};

const [CameraMirrorSelfViewStorageProvider, useCameraMirrorSelfViewStorage] = createContextProvider(
    {
        name: "CameraMirrorSelfViewProvider",
    },
    useCameraMirrorSelfViewStore
);

const useCameraHiddenStore = () => {
    const {cameraHidden, setCameraHidden} = useDeviceSettingsStorage();

    return useMemo(() => ({cameraHidden, setCameraHidden}), [cameraHidden, setCameraHidden]);
};

const [CameraHiddenStorageProvider, useCameraHiddenStorage] = createContextProvider(
    {
        name: "CameraHiddenProvider",
    },
    useCameraHiddenStore
);

const useCameraBackgroundFilterStore = () => {
    const {cameraBackgroundFilter, setCameraBackgroundFilter} = useDeviceSettingsStorage();

    return useMemo(
        () => ({
            cameraBackgroundFilter,
            setCameraBackgroundFilter,
        }),
        [cameraBackgroundFilter, setCameraBackgroundFilter]
    );
};

const [CameraBackgroundFilterStorageProvider, useCameraBackgroundFilterStorage] = createContextProvider(
    {
        name: "CameraBackgroundFilterProvider",
    },
    useCameraBackgroundFilterStore
);

const useAudioInputDeviceIdStore = () => {
    const {audioInputDeviceId, setAudioInputDeviceId} = useDeviceSettingsStorage();

    return useMemo(() => ({audioInputDeviceId, setAudioInputDeviceId}), [audioInputDeviceId, setAudioInputDeviceId]);
};

const [AudioInputDeviceIdProvider, useAudioInputDeviceIdStorage] = createContextProvider(
    {
        name: "AudioInputDeviceIdProvider",
    },
    useAudioInputDeviceIdStore
);

const useAudioOutputDeviceIdStore = () => {
    const {audioOutputDeviceId, setAudioOutputDeviceId} = useDeviceSettingsStorage();

    return useMemo(
        () => ({
            audioOutputDeviceId,
            setAudioOutputDeviceId,
        }),
        [audioOutputDeviceId, setAudioOutputDeviceId]
    );
};

const [AudioOutputDeviceIdProvider, useAudioOutputDeviceIdStorage] = createContextProvider(
    {
        name: "AudioOutputDeviceIdProvider",
    },
    useAudioOutputDeviceIdStore
);

const useAudioNoiseReductionStore = () => {
    const {audioNoiseReduction, setAudioNoiseReduction} = useDeviceSettingsStorage();

    return useMemo(
        () => ({
            audioNoiseReduction,
            setAudioNoiseReduction,
        }),
        [audioNoiseReduction, setAudioNoiseReduction]
    );
};

const [NoiseReductionStorageProvider, useNoiseReductionStorage] = createContextProvider(
    {
        name: "AudioNoiseReductionProvider",
    },
    useAudioNoiseReductionStore
);

const useMicrophoneEnabledStore = () => {
    const {microphoneEnabled, setMicrophoneEnabled} = useDeviceSettingsStorage();

    return useMemo(() => ({microphoneEnabled, setMicrophoneEnabled}), [microphoneEnabled, setMicrophoneEnabled]);
};

const [MicrophoneEnabledStorageProvider, useMicrophoneEnabledStorage] = createContextProvider(
    {
        name: "MicrophoneEnabledProvider",
    },
    useMicrophoneEnabledStore
);

export const [NotificationsSettingsProvider, useNotificationsSettings] = createContextProvider(
    {
        name: "NotificationsSettings",
    },
    useNotificationSettingsStore
);

export {
    useDeviceSettingsStorage,
    useDeviceSettingsIdStorage,
    useCameraEnabledStorage,
    useCameraMirrorSelfViewStorage,
    useCameraHiddenStorage,
    useCameraBackgroundFilterStorage,
    useAudioInputDeviceIdStorage,
    useAudioOutputDeviceIdStorage,
    useNoiseReductionStorage,
    useMicrophoneEnabledStorage,
};

export default function UserDeviceSettingsStorageProvider({children}: WithChildren) {
    return (
        <DeviceSettingsProvider>
            <VideoDeviceIdStorageProvider>
                <CameraEnabledStorageProvider>
                    <CameraMirrorSelfViewStorageProvider>
                        <CameraHiddenStorageProvider>
                            <CameraBackgroundFilterStorageProvider>
                                <AudioInputDeviceIdProvider>
                                    <AudioOutputDeviceIdProvider>
                                        <NoiseReductionStorageProvider>
                                            <NotificationsSettingsProvider>
                                                <MicrophoneEnabledStorageProvider>{children}</MicrophoneEnabledStorageProvider>
                                            </NotificationsSettingsProvider>
                                        </NoiseReductionStorageProvider>
                                    </AudioOutputDeviceIdProvider>
                                </AudioInputDeviceIdProvider>
                            </CameraBackgroundFilterStorageProvider>
                        </CameraHiddenStorageProvider>
                    </CameraMirrorSelfViewStorageProvider>
                </CameraEnabledStorageProvider>
            </VideoDeviceIdStorageProvider>
        </DeviceSettingsProvider>
    );
}
