import {cls} from "@ui/cdk/util/util";
import classes from "./styles/BrandingLogo.module.scss";
import {useReactiveVar} from "@workhorse/api/data";
import {brandingLogo, brandingLogomark} from "@workhorse/pages/user/profile/Theming/utils";
import {capitalize} from "@workhorse/util";
import {ReactComponent as SessionsLogoBigDark} from "../../../assets/media/sessions-logos/sessions-logo-big-dark.svg";
import {ReactComponent as SessionsLogoBigLight} from "../../../assets/media/sessions-logos/sessions-logo-big-light.svg";
import {ReactComponent as SessionsLogoSmall} from "../../../assets/media/sessions-logos/sessions-logo-small-flat.svg";
import {DrawerHookState} from "@workhorse/declarations";

type BrandingLogoProps = {
    size?: "small" | "big";
    customSize?: "small" | "big";
    variant?: "light" | "dark";
    className?: string;
    onClick?: () => void;
};

function BrandingLogoIcon(props: BrandingLogoProps) {
    const {size, variant = "light"} = props;

    const customBrandingLogo = useReactiveVar(brandingLogo);
    const customBrandingLogomark = useReactiveVar(brandingLogomark);

    if (customBrandingLogo) {
        if (size === "small") {
            return <img src={customBrandingLogomark ?? customBrandingLogo} alt="custom-branding-logomark" className={props.className} />;
        } else {
            return <img src={customBrandingLogo} alt="custom-branding-logo" className={props.className} />;
        }
    }

    if (size === "small") {
        return <SessionsLogoSmall className={props.className} />;
    }

    if (variant === "light") {
        return <SessionsLogoBigLight className={props.className} />;
    }

    if (variant === "dark") {
        return <SessionsLogoBigDark className={props.className} />;
    }

    return null;
}

function BrandingLogo(props: BrandingLogoProps) {
    const {size = "big", customSize = size, onClick} = props;

    const customBrandingLogo = useReactiveVar(brandingLogo);

    const className = cls(classes.root, "logo", classes[`root${capitalize(customBrandingLogo ? customSize : size)}`], props.className);

    return (
        <div className={className} onClick={onClick}>
            <BrandingLogoIcon {...props} />
        </div>
    );
}

export default BrandingLogo;
