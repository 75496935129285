import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {ReactComponent as AddSpeaker} from "../../../../assets/media/add-speaker2.svg";
import common from "../eventCommons.module.scss";

type Props = {
    openNewSpeaker: () => void;
};

const EventSpeakersListEmptyState = (props: Props) => {
    const {openNewSpeaker} = props;

    return (
        <div className={cls("fullw flex flex-col flex11-auto flex-center-all mt-10", common.emptyState)}>
            <AddSpeaker className="mb-32" />
            <Typography variant="xl" fontWeight="bolder" color="tertiary" className="mb-8">
                Add some speakers to your event
            </Typography>
            <Typography color="tertiary" variant="lg" className="mb-32">
                You can add your event collaborators as speakers or invite someone else
            </Typography>
            <Button variant="tertiary" className={common.tertiaryButton} onClick={openNewSpeaker} data-id="event-button-add-speakers">
                <AddRoundedIcon className="mr-4" /> New speaker
            </Button>
        </div>
    );
};

export default EventSpeakersListEmptyState;
