import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {useState} from "@workhorse/api/rendering";
import {UtmSet, Utm} from "./types";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import classes from "./style/UtmSetEditor.module.scss";
import {capitalize} from "@ui/cdk/util";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import UtmLink from "./utml-link";
import environment from "@generated/environment";

type UtmSetEditorProps = {
    utm: Utm;
    onChange: (utmSetName: string, utmSet: UtmSet) => void;
    onRemoveSet: (utmSetName: string) => void;
    onCancel: () => void;
    slug?: string;
};

const sortWeights = {
    utm_campaign: 3,
    utm_content: 1,
    utm_medium: 2,
    utm_source: 4,
};

const UtmSetEditor = (props: UtmSetEditorProps) => {
    const [utmSet, setUtmSet] = useState(Object.values(props.utm)[0]);
    const utmSetName = Object.keys(props.utm)[0];
    const onConfirm = () => {
        props.onChange(utmSetName, utmSet);
    };
    const onRemoveSet = () => {
        props.onRemoveSet(utmSetName);
    };

    const utmParams = Object.keys(utmSet)
        .filter((key) => !!utmSet[key])
        .map((key) => `${key}=${encodeURI(utmSet[key])}`)
        .join("&");

    const baseURL = environment.eventBaseURL;

    const link = encodeURI(`${baseURL}/${props.slug ?? ""}${utmParams ? "?" + utmParams : ""}`);

    return (
        <div className={classes.root}>
            <Typography variant="lg" fontWeight="bolder">
                UTM codes
            </Typography>

            <div className="flex flex-col">
                {Object.keys(utmSet)
                    .sort((a, b) => sortWeights[b] - sortWeights[a])
                    .map((key) => (
                        <div key={utmSetName + key}>
                            <Input
                                maxCharCount={60}
                                showTypedCharCount
                                labelClassName={classes.label}
                                value={utmSet[key]}
                                classes={{
                                    input: classes.input,
                                }}
                                label={capitalize(key.replace("utm_", ""))}
                                onChange={(e) => {
                                    setUtmSet({
                                        ...utmSet,
                                        [key]: e.target.value,
                                    });
                                }}
                            ></Input>
                        </div>
                    ))}
                <Input
                    disabled
                    labelClassName={classes.label}
                    value={link}
                    classes={{
                        input: classes.input,
                    }}
                    className={"pr-0"}
                    label={"URL with UTM code"}
                    endAdornment={
                        <UtmLink utmSet={utmSet} slug={props.slug ?? ""}>
                            <Button size="small" variant="quaternary" className={classes.specialButton}>
                                <FileCopyIcon /> Copy
                            </Button>
                        </UtmLink>
                    }
                ></Input>
                <DialogFooter noPadding noBorder className={classes.dialogFooter}>
                    <Button fullWidth variant="tertiary" onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <Button withMarginLeft fullWidth variant="primary" onClick={onConfirm}>
                        Confirm
                    </Button>
                </DialogFooter>
            </div>
        </div>
    );
};

export default UtmSetEditor;
