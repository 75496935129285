import {
    ChangeGuestDetailsDocument,
    ProvideSessionPasscodeDocument,
    RequestPermissionToJoinDocument,
    GiveConsentToRecordDocument,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {writeFragment} from "@workhorse/dataApi";
import {readCurrentParticipant} from "@workhorse/providers/SessionDataProviders";

export const requestPermissionToJoin = (sessionId: string) => {
    return apollo.client.mutate({
        mutation: RequestPermissionToJoinDocument,
        variables: {
            sessionId,
        },
        update: (_, res) => {
            if (res.data) {
                const currentParticipantData = readCurrentParticipant();

                if (currentParticipantData?.id) {
                    writeFragment({
                        fragment: "ParticipantStatusFragmentDoc",
                        id: currentParticipantData.id,
                        data: {
                            status: res.data.requestPermissionToJoin.status,
                        },
                    });
                }
            }
        },
    });
};

export const provideSessionPasscode = (sessionId: string, passcode: string) => {
    return apollo.client.mutate({
        mutation: ProvideSessionPasscodeDocument,
        variables: {
            sessionId,
            passcode,
        },
        errorPolicy: "all",
    });
};

export const consentToBeingRecorded = async (sessionId: string) => {
    const res = await apollo.client.mutate({
        mutation: GiveConsentToRecordDocument,
        variables: {
            sessionId,
        },
    });

    return res;
};

export const changeGuestDetails = (sessionId: string, name?: string, email?: string) => {
    return apollo.client.mutate({
        mutation: ChangeGuestDetailsDocument,
        variables: {
            sessionId,
            name,
            email,
        },
    });
};
