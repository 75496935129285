import OnboardingPersonaIndustry from "./components/OnboardingPersonaIndustry";
import OnboardingPersonaMeetingTypes from "./components/OnboardingPersonaMeetingTypes";
import OnboardingPersonaRole from "./components/OnboardingPersonaRole";
import OnboardingPersonaUseCase from "./components/OnboardingPersonaUseCase";
import {useState} from "@workhorse/api/rendering";
import {UpdateOrganizationOnboardingDocument} from "@generated/data";
import {useMutation} from "@apollo/client";
import {getSavedOnboardingStep, industryOptions, meetingTypesOptions, PersonaUseCase, roleOptions} from "./components/utils";
import {capitalize} from "@workhorse/util";
import toast from "@workhorse/api/toast";

type OnboardingPersonaContainerProps = {
    onboarding: any; // JSON;
    organizationName: string;
    userId: string;
};

function OnboardingPersonaContainer(props: OnboardingPersonaContainerProps) {
    const {onboarding, organizationName, userId} = props;

    const [updateOrganizationOnboarding] = useMutation(UpdateOrganizationOnboardingDocument);

    const personaPlanning = onboarding?.onboarding_planning;
    const personaIndustry = onboarding?.onboarding_industry;
    const personaRole = onboarding?.onboarding_role;

    const completedSteps = [!!personaPlanning, !!personaIndustry, !!personaRole];

    const savedOnboardingStep = getSavedOnboardingStep(userId);
    const lastComplatedStep = completedSteps.lastIndexOf(true) + 1; // First index is 0
    const lastAvailableStep = lastComplatedStep + 1; // Go to the step after the completed one

    const initialAvailableStep = !savedOnboardingStep || savedOnboardingStep > lastComplatedStep ? lastAvailableStep : savedOnboardingStep;

    const [activeStep, setActiveStep] = useState<number>(initialAvailableStep);

    const initialPersonaValues = {
        useCase: personaPlanning?.["key"],
        industry: personaIndustry?.["key"] ?? "",
        role: personaRole?.["key"] ?? "",
        meetingTypes: [],
    };

    const [personaValues, setPersonaValues] = useState<{
        useCase?: PersonaUseCase;
        industry: string;
        role: string;
        meetingTypes: string[];
    }>(initialPersonaValues);

    const initialOrganizationName = initialPersonaValues.useCase ? organizationName : "";
    const initialIndustryOtherValue = initialPersonaValues.industry === "other" ? personaIndustry?.["value"] : "";
    const initialRoleOtherValue = initialPersonaValues.role === "other" ? personaRole?.["value"] : "";
    const initialMeetingTypesOtherValue = "";

    const [localOrganizationName, setLocalOrganizationName] = useState<string>(initialOrganizationName);
    const [localIndustryOtherValue, setLocalIndustryOtherValue] = useState<string>(initialIndustryOtherValue);
    const [localRoleOtherValue, setLocalRoleOtherValue] = useState<string>(initialRoleOtherValue);
    const [localMeetingTypesOtherValue, setLocalMeetingTypesOtherValue] = useState<string>(initialMeetingTypesOtherValue);

    const handleChangeStep = (step: number) => {
        sessionStorage.setItem(`onboarding-persona-${userId}`, step + "");
        setActiveStep(step);
    };

    const handleSubmitUseCase = () => {
        const value = personaValues.useCase;

        if (!value) {
            return;
        }

        const orgName = value === "team" && localOrganizationName.trim() ? localOrganizationName.trim() : organizationName;

        updateOrganizationOnboarding({
            variables: {
                userOnboarding: {
                    planning: {key: value, value: capitalize(value)},
                },
                organizationName: orgName,
            },
        })
            .then((res) => {
                if (res.data?.updateOrganizationOnboarding?.id) {
                    setLocalOrganizationName(orgName);

                    handleChangeStep(2);
                }
            })
            .catch(() => {
                toast("Something went wrong with selecting the option!", {type: "error"});
            });
    };

    const handleSubmitIndustry = () => {
        const value = personaValues.industry;

        const selectedOption = industryOptions.find((x) => x.value === value);

        if (!selectedOption) {
            return;
        }

        updateOrganizationOnboarding({
            variables: {
                userOnboarding: {
                    industry: {
                        key: selectedOption.value,
                        value: selectedOption.value === "other" ? localIndustryOtherValue.trim() : selectedOption.text,
                    },
                },
                organizationName,
            },
        })
            .then((res) => {
                if (res.data?.updateOrganizationOnboarding?.id) {
                    handleChangeStep(3);
                }
            })
            .catch(() => {
                toast("Something went wrong with submitting the options!", {type: "error"});
            });
    };

    const handleSubmitRole = () => {
        const value = personaValues.role;

        const selectedOption = roleOptions.find((x) => x.value === value);

        if (!selectedOption) {
            return;
        }

        updateOrganizationOnboarding({
            variables: {
                userOnboarding: {
                    role: {
                        key: selectedOption.value,
                        value: selectedOption.value === "other" ? localRoleOtherValue.trim() : selectedOption.text,
                    },
                },
                organizationName,
            },
        })
            .then((res) => {
                if (res.data?.updateOrganizationOnboarding?.id) {
                    handleChangeStep(4);
                }
            })
            .catch(() => {
                toast("Something went wrong with submitting the options!", {type: "error"});
            });
    };

    const handleSubmitMeetingTypes = () => {
        const values = personaValues.meetingTypes;

        const selectedOptions = meetingTypesOptions
            .filter((x) => values.includes(x.value))
            .map((x) => {
                const value = x.value === "other" ? localMeetingTypesOtherValue.trim() : x.text;

                return {
                    key: x.value,
                    value: value,
                };
            });

        if (!selectedOptions) {
            return;
        }

        updateOrganizationOnboarding({
            variables: {
                userOnboarding: {
                    meetingTypes: selectedOptions,
                },
                organizationName,
                onboardingCompleted: true,
            },
        }).catch(() => {
            toast("Something went wrong with submitting the options!", {type: "error"});
        });
    };

    if (activeStep === 1) {
        return (
            <OnboardingPersonaUseCase
                onClickStep={handleChangeStep}
                value={personaValues.useCase}
                onChangeValue={(value) => setPersonaValues((prev) => ({...prev, useCase: value}))}
                organizationName={localOrganizationName}
                onChangeOrganizationName={(value) => setLocalOrganizationName(value)}
                onSubmit={handleSubmitUseCase}
                step={1}
            />
        );
    }

    if (activeStep === 2) {
        return (
            <OnboardingPersonaIndustry
                onClickStep={handleChangeStep}
                value={personaValues.industry}
                onChangeValue={(value) => setPersonaValues((prev) => ({...prev, industry: value}))}
                otherValue={localIndustryOtherValue}
                onChangeOtherValue={(value) => setLocalIndustryOtherValue(value)}
                onSubmit={handleSubmitIndustry}
                step={2}
            />
        );
    }

    if (activeStep === 3) {
        return (
            <OnboardingPersonaRole
                onClickStep={handleChangeStep}
                value={personaValues.role}
                onChange={(value) => setPersonaValues((prev) => ({...prev, role: value}))}
                otherValue={localRoleOtherValue}
                onChangeOtherValue={(value) => setLocalRoleOtherValue(value)}
                onSubmit={handleSubmitRole}
                step={3}
            />
        );
    }

    return (
        <OnboardingPersonaMeetingTypes
            onClickStep={handleChangeStep}
            values={personaValues.meetingTypes}
            onChange={(values) => setPersonaValues((prev) => ({...prev, meetingTypes: values}))}
            otherValue={localMeetingTypesOtherValue}
            onChangeOtherValue={(value) => setLocalMeetingTypesOtherValue(value)}
            onSubmit={handleSubmitMeetingTypes}
            step={4}
        />
    );
}

export default OnboardingPersonaContainer;
