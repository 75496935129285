import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {DesignerAction} from "../../action";

interface EvictSessionInput {
    id: string;
}

export class EvictSessionAction extends DesignerAction<EvictSessionInput> {
    commit() {
        const sessionId = this.input.id;

        // apollo.cache.evict({
        //     id: sessionId,
        // });

        if (sessionId == designer.state.getSessionId()) {
            designer.state.initializeOrResetState(null);
            designer.clearActionsHistory();
            designer.clearPersistentStorage();
        }

        return sessionId;
    }
}
