import {useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {ReactComponent as WarningIcon} from "../assets/media/Warning.svg";

export type RouterPromptProps = {
    when?: boolean | undefined;
    onOK?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => boolean;
    onCancel?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => boolean;
    onClose?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => boolean;
    title: string;
    content: string;
    okText?: string;
    cancelText?: string;
    additionalCancelButton?: boolean;
    show?: boolean;
};

const RouterPrompt = (props: RouterPromptProps) => {
    const {
        when,
        onOK = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => true,
        onCancel = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => false,
        onClose = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => false,
        title,
        content,
        okText = "Confirm",
        cancelText = "Cancel",
        additionalCancelButton = false,
        show = false,
    } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const handleOK = useCallback(
        async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (show) {
                onOK?.(e);
                return;
            }

            if (onOK) {
                const canRoute = await Promise.resolve(onOK(e));
                if (canRoute) {
                    history.block(() => {});
                    history.push(currentPath);
                }
            }
        },
        [show, currentPath, history, onOK]
    );

    const handleCancel = useCallback(
        async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (show) {
                onCancel?.(e);
                return;
            }

            if (onCancel) {
                const canRoute = await Promise.resolve(onCancel(e));
                if (canRoute) {
                    history.block(() => {});
                    history.push(currentPath);
                }
            }
            setShowPrompt(false);
        },
        [show, currentPath, history, onCancel]
    );

    const handleClose = useCallback(
        async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (show) {
                onClose?.(e);
                return;
            }

            if (onClose) {
                const canRoute = await Promise.resolve(onClose(e));
                if (canRoute) {
                    history.block(() => {});
                    history.push(currentPath);
                }
            }
            setShowPrompt(false);
        },
        [show, currentPath, history, onClose]
    );

    return (
        <ConfirmationDialog
            open={showPrompt || show}
            onClose={handleClose}
            onConfirm={handleOK}
            onCancel={handleCancel}
            title={title}
            content={content}
            cancelButton={cancelText}
            submitButton={okText}
            Icon={WarningIcon}
            disableBackdropClick
            additionalCancelButton={additionalCancelButton}
            cancelButtonVariant="destructive-secondary"
        />
    );
};

export default RouterPrompt;
