import {
    LocalParticipant,
    LocalTrackPublication,
    Participant,
    RemoteParticipant,
    RemoteTrackPublication,
    Track,
    // VideoQuality,
} from "livekit-client";
import {createRoomLogger} from "./ConferenceRoomLogger";
import {setIncomingQuality} from "./ConferenceRoomTrackQuality";
import {isAnyLocationVisible, VisibilityMap} from "./ConferenceRoomVisibility";

export type Publication = {
    isLocalParticipant: boolean;
    isPausedDueToCongestion: boolean;

    [Track.Source.Camera]: RemoteTrackPublication | null;
    [Track.Source.ScreenShare]: RemoteTrackPublication | null;
    [Track.Source.ScreenShareAudio]: RemoteTrackPublication | null;
    [Track.Source.Microphone]: RemoteTrackPublication | null;
};

export type PublicationMap = {
    [K in string]?: Publication;
};

export type SharingState = {
    identity?: string;
    isLocalSharing?: boolean;
};

export const trackLogger = createRoomLogger("ROOM_TRACK");

export function createDefaultPublications(): Publication {
    return {
        microphone: null,
        camera: null,
        screen_share: null,
        screen_share_audio: null,
        isLocalParticipant: false,
        isPausedDueToCongestion: false,
    };
}

export function subscribePublication(
    participant: RemoteParticipant | LocalParticipant,
    publication: RemoteTrackPublication | LocalTrackPublication,
    visibilityMap: VisibilityMap,
    incomingVideosDisabled: boolean
) {
    if (publication instanceof LocalTrackPublication) {
        return;
    }

    if (!publication.isSubscribed) {
        publication.setSubscribed(true);
    }

    if (publication.source === Track.Source.ScreenShare) {
        setIncomingQuality(publication, visibilityMap, participant.identity, incomingVideosDisabled);
    }

    if (publication.source === Track.Source.Camera) {
        const isVisible = isAnyLocationVisible(visibilityMap[participant.identity]);
        setIncomingQuality(publication, visibilityMap, participant.identity, incomingVideosDisabled);
        publication.setEnabled(incomingVideosDisabled ? false : isVisible);
    }
}

export function resubscribeCameraPublication(
    participant: Participant | undefined,
    visibilityMap: VisibilityMap,
    incomingVideosDisabled: boolean
) {
    if (!(participant instanceof RemoteParticipant)) {
        return;
    }

    const publication = participant.getTrackPublication(Track.Source.Camera);

    if (!publication || publication instanceof LocalTrackPublication) {
        return;
    }

    subscribePublication(participant, publication, visibilityMap, incomingVideosDisabled);
}

export function createParticipantPublication(
    participant: RemoteParticipant | LocalParticipant,
    pub: RemoteTrackPublication | LocalTrackPublication,
    current: Publication | undefined,
    visibilityMap: VisibilityMap,
    incomingVideosDisabled: boolean,
    streamState?: Track.StreamState
) {
    subscribePublication(participant, pub, visibilityMap, incomingVideosDisabled);

    const existing = current ?? createDefaultPublications();

    const isLocalParticipant = participant instanceof LocalParticipant;
    const isPausedDueToCongestion = streamState === Track.StreamState.Paused && isAnyLocationVisible(visibilityMap[participant.identity]);

    const data: Publication = {
        ...existing,
        [pub.source]: pub,
        isLocalParticipant,
        isPausedDueToCongestion,
    };

    return data;
}

export function removeParticipantPublication(current: Publication | undefined, source: Track.Source) {
    const existing = current ?? createDefaultPublications();

    const data: Publication = {
        ...existing,
        [source]: null,
    };

    return data;
}

export function updatePublicationMapById(map: PublicationMap, id: string, data: Partial<Publication>) {
    const current = map[id] ?? createDefaultPublications();

    const result = {
        ...current,
        ...data,
    };

    return {
        ...map,
        [id]: result,
    };
}

export function initializeParticipantPublications(
    participant: RemoteParticipant | LocalParticipant,
    visibilityMap: VisibilityMap,
    incomingVideosDisabled: boolean
) {
    const publications = participant.getTrackPublications();
    const data = createDefaultPublications();
    data.isLocalParticipant = participant instanceof LocalParticipant;

    for (const publication of publications) {
        // Subscribe only to remote tracks
        if (publication instanceof RemoteTrackPublication) {
            subscribePublication(participant, publication, visibilityMap, incomingVideosDisabled);
        }
        data[publication.source] = publication;
    }

    return data;
}

export function createScreenShareState(publicationMap: PublicationMap, localParticipantId?: string): SharingState {
    const entries = Object.entries(publicationMap);

    for (const [id, publications] of entries) {
        if (!publications?.screen_share) {
            continue;
        }

        if (id === localParticipantId) {
            return {identity: id, isLocalSharing: true};
        }

        return {identity: id, isLocalSharing: false};
    }

    return {};
}

export function getAudioTracksFromRemoteParticipant(participant: RemoteParticipant) {
    const publications = participant.audioTrackPublications.values();
    const tracks: MediaStreamTrack[] = [];

    for (const pub of publications) {
        const track = pub.track?.mediaStreamTrack;

        if (track) {
            tracks.push(track);
        }
    }

    return tracks;
}
