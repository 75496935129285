import designer from "../..";
import {DesignerAction} from "../../action";
import {move} from "../../utils";

export interface MoveAgendaItemInput {
    id: string;
    afterId?: string;
    beforeId?: string;
}

export class MoveAgendaItem extends DesignerAction<MoveAgendaItemInput> {
    commit() {
        const agendaItemsIncludingDeleted = designer.currentAgendaItems();
        const deleted = agendaItemsIncludingDeleted.filter((item) => item.isDeleted);

        const agendaItems = agendaItemsIncludingDeleted.filter((item) => !item.isDeleted);

        const movedItem = agendaItems.find((item) => item.id === this.input.id);
        const moveAfter = agendaItems.find((item) => item.id === this.input.afterId);
        const moveBefore = agendaItems.find((item) => item.id === this.input.beforeId);

        const currentIndex = movedItem?.order ?? 0;

        const newIndex = moveBefore?.order
            ? moveBefore.order < 0
                ? 0
                : moveBefore.order
            : moveAfter?.order !== undefined
            ? moveAfter.order < currentIndex
                ? moveAfter.order + 1
                : moveAfter.order
            : 0;

        const currentItems = agendaItems.slice().sort((a, b) => (a.order > b.order ? 1 : -1));

        if (!currentItems) {
            return "No agenda items found";
        }
        const moved = move(currentItems, currentIndex, newIndex).map((item, index) => ({
            ...item,
            order: index,
        }));

        designer.updateCurrentSession(
            {
                agendaItems: moved.concat(deleted ?? []),
            },
            {
                strategy: "replace",
            }
        );

        return "true";
    }
}
