import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useRef} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import classes from "./styles/OnboardingHelpCenterDialog.module.scss";
import {openExternalLink} from "@workhorse/util/links";

type OnboardingHelCenterDialogProps = {
    onClose?: () => void;
};

function OnboardingHelCenterDialog(props: OnboardingHelCenterDialogProps) {
    const {onClose} = props;

    const dialogRef = useRef<DialogImperativeRef>();

    const handleMarkAsSeen = async () => {
        await markAsSeen({
            ONBOARDING_FEATURES: {
                helpCenter: true,
            },
        });

        onClose?.();
    };

    const handleSubmit = () => {
        openExternalLink("https://resources.sessions.us/resources/help-center");
        handleMarkAsSeen();
    };

    const handleClose = () => {
        onClose?.();
    };

    return (
        <Dialog imperativeRef={dialogRef} open={true} classes={{paper: classes.root}} disableBackdropClick disableEscapeKeyDown>
            <div className="flex flex-items-center mb-20">
                <Typography variant="xl3" fontWeight="bolder" color="secondary">
                    Sessions Help Center
                </Typography>
            </div>
            <Typography color="quaternary" variant="lg" className="prewrap">
                Our Help Center has loads of useful info. Plus, someone from our team is just a click away.
            </Typography>
            <div className={cls("flex flex-items-center flex-justify-between", classes.buttons)}>
                <Button variant="quaternary" onClick={handleClose} data-id="onboarding-button-strike3-maybe-later">
                    Maybe later
                </Button>

                <Button onClick={handleSubmit} data-id="onboarding-button-strike3-go">
                    Go to Help Center
                </Button>
            </div>
        </Dialog>
    );
}

export default OnboardingHelCenterDialog;
