import React, {forwardRef} from "@workhorse/api/rendering";
import MuiOutlinedInput from "@material-ui/core/OutlinedInput";
import {ExtractProps} from "@workhorse/declarations";
import classes from "./style/OutlinedInput.module.scss";
import {cls} from "../util/util";
import Tooltip from "../Tooltip";
import {FormControl} from "@material-ui/core";

type InputProps = ExtractProps<typeof MuiOutlinedInput> & {
    styled?: boolean;
    plain?: boolean;
    labelProps?: JSX.IntrinsicAttributes;
    renderValueAsPlaceholder?: boolean;
};

const OutlinedInput = forwardRef(
    ({styled, plain, label, labelProps, renderValueAsPlaceholder, ...other}: InputProps, ref: React.MutableRefObject<any | null>) => {
        let styledProps: Partial<InputProps> = {};
        if (styled && !plain) {
            styledProps = {
                classes: {
                    ...(other?.classes ?? {}),
                    root: cls(other?.classes?.root ?? "", classes.root),

                    focused: classes.focused,
                    input: classes.input,
                },
            };
        }

        if (plain) {
            styledProps = {
                classes: {
                    ...(other?.classes ?? {}),
                    root: classes.plainRoot,
                    // focused: classes.plainFocused,
                    input: classes.plainInput,
                },
            };
        }

        return (
            <FormControl
                // className={customVariant === "primary" ? classes.selectRootPrimary : ""}
                fullWidth={other.fullWidth ? true : false}
            >
                {label ? (
                    <label className={cls(classes.label)} {...(labelProps ?? {})}>
                        {label}
                        {other.required ? (
                            <Tooltip placement="top" arrow={true} title="This is a mandatory field.">
                                <sup className={classes.sup}>{"* "}</sup>
                            </Tooltip>
                        ) : null}
                    </label>
                ) : null}
                <MuiOutlinedInput
                    {...other}
                    {...styledProps}
                    defaultValue={renderValueAsPlaceholder ? "" : other.defaultValue}
                    placeholder={renderValueAsPlaceholder ? (other.defaultValue as string) : other.placeholder}
                    value={renderValueAsPlaceholder ? "" : other.value}
                    ref={ref}
                ></MuiOutlinedInput>
            </FormControl>
        );
    }
);

OutlinedInput.displayName = "OutlinedInput";

export default OutlinedInput;
