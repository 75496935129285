import {ReactComponent as UnderlineBusiness} from "@assets/media/underline_business.svg";
import {ReactComponent as UnderlineStarter} from "@assets/media/underline_enterprise.svg";
import {ReactComponent as UnderlinePro} from "@assets/media/underline_pro.svg";
import {BillingPeriod, OrganizationPlan, PaddlePlansDocument, PaddleSubscriptionState} from "@generated/data";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {
    bookingLimitByPlan,
    maximumNumberOfEventsPerPlan,
    maximumNumberOfMembersInOrganization,
    maximumParticipantsInSessionForPlan,
    sessionLengthLimitationByPlanInSeconds,
} from "@sessions/common/subscription-limitations";
import {
    getFirstAvailablePlan,
    getNextAvailablePlan,
    isFeatureSuggestedInNextPlan,
    LimitedFeature,
} from "@sessions/common/subscription-limitations/features";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography/Typography";
import {roundToDecimals} from "@ui/cdk/util";
import {PropsWithChildren, useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import {getPlanTitle} from "@workhorse/pages/user/profile/UsageEvaluation/components/SubscriptionInfo";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useUserInfo} from "@workhorse/providers/User";
import UpgradePlanDialogPayment from "./components/UpgradePlanDialogPayment";
import UpgradePlanDialogSelector from "./components/UpgradePlanDialogSelector";
import classes from "./style/UpgradePlanDialog.module.scss";

import {useQuery} from "@apollo/client";
import {cls} from "@ui/cdk/util/util";

import {Trans, useTranslation} from "react-i18next";
import {basePricing} from "@workhorse/pages/user/profile/UsageEvaluation/static";
import {isAppSumoPlan, isLtdPlan} from "@sessions/common/subscription-limitations/utils";

type UpgradePlanDialogProps = {
    title?: string | {key: string; options?: Record<string, string | number>};
    limitedFeature: LimitedFeature | null;
    onClose?: () => void;
};

type FeatureRendererProps = PropsWithChildren<{
    plan: OrganizationPlan;
    feature: LimitedFeature;
}>;

const FeatureRenderer = (props: FeatureRendererProps) => {
    if (!isFeatureSuggestedInNextPlan(props.plan, props.feature)) {
        return null;
    }
    return <li>{props.children}</li>;
};

const getFeatureList = (
    t: (key: string, options?: Record<string, string | number>) => string,
    plan: OrganizationPlan,
    nextPlan: OrganizationPlan
) => {
    return (
        <div className={classes.withBorderTop}>
            <strong>{t("limitations.dialog.all_features_from_plan_x_plus", {plan_name: getPlanTitle(plan)})}</strong>
            <ul className={classes.list}>
                <FeatureRenderer feature={LimitedFeature.SESSION_LENGTH} plan={plan}>
                    {t("limitations.dialog.up_to_x_hours_per_session", {
                        hours: roundToDecimals(sessionLengthLimitationByPlanInSeconds[nextPlan] / 3600, 2),
                    })}
                </FeatureRenderer>
                <FeatureRenderer feature={LimitedFeature.PARTICIPANTS_IN_SESSION} plan={plan}>
                    {t("limitations.dialog.up_to_x_participants_per_session", {
                        participants: maximumParticipantsInSessionForPlan[nextPlan],
                    })}
                </FeatureRenderer>

                <FeatureRenderer feature={LimitedFeature.ORGANIZATION_MEMBERS} plan={plan}>
                    {t("limitations.dialog.x_team_member_accounts", {members: maximumNumberOfMembersInOrganization[nextPlan]})}
                </FeatureRenderer>
                <FeatureRenderer feature={LimitedFeature.EVENTS} plan={plan}>
                    <span>
                        {maximumNumberOfEventsPerPlan[nextPlan] === Infinity
                            ? t("limitations.dialog.unlimited_events_per_month")
                            : t("limitations.dialog.x_events_per_month", {
                                  events: maximumNumberOfEventsPerPlan[nextPlan],
                              })}
                    </span>
                </FeatureRenderer>
                <FeatureRenderer feature={LimitedFeature.BOOKING} plan={plan}>
                    {t("limitations.dialog.x_bookings_per_user", {
                        bookings: bookingLimitByPlan[nextPlan] === Infinity ? "Unlimited" : bookingLimitByPlan[nextPlan],
                    })}
                </FeatureRenderer>
                {/* <FeatureRenderer feature={LimitedFeature.MEMORY} plan={plan}>
                    {memoryHistoryLimitationByPlanInDays[nextPlan] === Infinity
                        ? "Unlimited "
                        : memoryHistoryLimitationByPlanInDays[nextPlan] + " days "}
                    memory
                </FeatureRenderer>

                <FeatureRenderer feature={LimitedFeature.BOOKING} plan={plan}>
                    {bookingLimitByPlan[nextPlan]} bookings per user
                </FeatureRenderer> */}
                {/* {isCopilotEnabledByPlan[nextPlan] ? (
                    <FeatureRenderer feature={LimitedFeature.COPILOT} plan={plan}>
                        <CheckRoundedIcon />
                        <strong>AI Copilot</strong>
                    </FeatureRenderer>
                ) : null}

                {isCustomBrandingEnabledByPlan[nextPlan] ? (
                    <FeatureRenderer feature={LimitedFeature.BRANDING} plan={plan}>
                        <CheckRoundedIcon />
                        <strong>Custom branding and domain</strong>
                    </FeatureRenderer>
                ) : null}

                <FeatureRenderer feature={LimitedFeature.RECORDING} plan={plan}>
                    <CheckRoundedIcon />
                    <strong>
                        {recordingLimitByPlan(2)[nextPlan] === Infinity
                            ? "Unlimited"
                            : recordingLimitByPlan(membersCount)[nextPlan] / 1000 / 60 + "h"}{" "}
                        Cloud recording
                    </strong>
                    , unlimited storage
                </FeatureRenderer>

                {isCoBrowsingEnabledByPlan[nextPlan] ? (
                    <FeatureRenderer feature={LimitedFeature.COBROWSING} plan={plan}>
                        <CheckRoundedIcon />
                        <strong>Co-browse your website or app</strong>
                    </FeatureRenderer>
                ) : null}

                {areWebhooksEnabledByPlan[nextPlan] ? (
                    <FeatureRenderer feature={LimitedFeature.WEBHOOKS} plan={plan}>
                        <CheckRoundedIcon />
                        <strong>Webhooks</strong>
                    </FeatureRenderer>
                ) : null} */}
            </ul>
        </div>
    );
};

function UpgradePlanDialog(props: UpgradePlanDialogProps) {
    const {t} = useTranslation();
    const {title, onClose} = props;

    const {activeOrganizationPermission} = useUserInfo();

    const processing = activeOrganizationPermission.organization?.processingSubscription;
    const plan = activeOrganizationPermission.organization.plan;
    const isTrial = activeOrganizationPermission.organization.paddleSubscriptionState === PaddleSubscriptionState.Trialing;

    const {data} = useQuery(PaddlePlansDocument);

    const nextPlan = getFirstAvailablePlan(plan, props.limitedFeature);

    const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(BillingPeriod.Yearly);
    const isAppSumo = isAppSumoPlan(plan);

    const planInfo = data?.paddlePlans.find((paddlePlan) => paddlePlan.plan === nextPlan && paddlePlan.billing_type === billingPeriod);
    const priceShown = Number(Number((planInfo?.recurring_price?.[0]?.amount ?? 0) / 12).toFixed(2));

    const [chosenPlan, setChosenPlan] = useState<OrganizationPlan>(nextPlan);

    const [step, setStep] = useState(0);
    const {isMobile} = useMobile();

    const dialogRef = useRef<DialogImperativeRef>();

    const handleConfirm = () => {
        // if (isAppSumo) {
        //     window.location.href = appSumoInvoiceItemId
        //         ? `https://appsumo.com/account/redemption/${appSumoInvoiceItemId}/change-plan`
        //         : "https://appsumo.com/products/sessions/";
        //     onClose?.();
        //     return;
        // }
        setStep((step) => step + 1);
    };

    const handleClose = () => {
        setStep(0);
        onClose?.();
    };

    const toggleBillingPeriod = () => {
        setBillingPeriod((prevState) => (prevState === BillingPeriod.Monthly ? BillingPeriod.Yearly : BillingPeriod.Monthly));
    };

    const renderedTitle = title
        ? typeof title === "string"
            ? t(title)
            : title.key && title.options
            ? t(title.key, title.options).toString()
            : null
        : null;

    const trialAvailable = !activeOrganizationPermission.organization.subscriptionTrialUsed;

    const isLtdUser = isLtdPlan(plan);

    if (isLtdUser) {
        return (
            <Dialog
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
                imperativeRef={dialogRef}
                open
                classes={{paper: cls(classes.paper, "p-30")}}
                onClose={onClose}
            >
                <>
                    <Typography variant="xl" fontWeight="bolder" className="mb-8">
                        Feature unavailable
                    </Typography>

                    <Typography color="nonary" className="mb-8">
                        To use this feature please upgrade your plan.
                    </Typography>

                    <Link className="ml-0 mt-20" to="/user/organization/subscriptions/plans?ltd=true" target="_blank">
                        <OpenInNewRoundedIcon className="mr-6" />
                        {t("limitations.dialog.see_all_plans")}
                    </Link>
                </>
            </Dialog>
        );
    }

    return (
        <Dialog
            disableAutoFocus
            disableEnforceFocus
            disableScrollLock
            imperativeRef={dialogRef}
            open
            classes={{paper: step !== 2 ? classes.paper : cls(classes.paper, "px-0", "py-0")}}
            onClose={onClose}
        >
            <>
                {step === 2 ? null : !trialAvailable ? (
                    <Typography variant="xl" fontWeight="bolder" className="mb-8">
                        {step === 0
                            ? t("limitations.dialog.upgrade_to_the_x_plan", {plan_name: getPlanTitle(nextPlan)})
                            : t("limitations.dialog.upgrade_your_plan")}
                    </Typography>
                ) : (
                    <Typography variant="xl" fontWeight="bolder" className="mb-8">
                        {step === 0 ? `Start free trial for ${getPlanTitle(nextPlan)} plan` : "Start free trial"}
                    </Typography>
                )}

                {step === 2 ? null : (
                    <Typography color="nonary" className="mb-8">
                        {step === 0 ? renderedTitle : t("limitations.dialog.flexible_pricing")}
                    </Typography>
                )}

                {step === 0 ? (
                    <div className="relative">
                        <Typography variant="xl" fontWeight="bolder">
                            {getPlanTitle(nextPlan)}
                        </Typography>

                        <Typography variant="sm" className={cls("mb-10", classes.planPrice)}>
                            {nextPlan === OrganizationPlan.Pro ? <UnderlinePro className={classes.blob} /> : null}
                            {nextPlan === OrganizationPlan.Business ? <UnderlineBusiness className={classes.blob} /> : null}
                            {nextPlan === OrganizationPlan.Starter ? <UnderlineStarter className={classes.blob} /> : null}
                            {trialAvailable ? (
                                <> FREE for 14 days</>
                            ) : (
                                <Trans i18nKey="limitations.dialog.from_x_usd_month">
                                    From ${{price: priceShown}}
                                    {/* @ts-ignore */}
                                    <span>/month</span>
                                </Trans>
                            )}
                        </Typography>

                        {trialAvailable ? (
                            <Typography variant="sm" fontWeight="bold" color="primary" className="mb-8">
                                Free trial for 14 days. <br />
                                You can cancel at any time. <br />
                                You will not be charged during the trial.
                            </Typography>
                        ) : null}

                        {trialAvailable ? (
                            <Typography fontWeight="bold" color="primary">
                                After the trial, the cost will be ${priceShown}/month
                            </Typography>
                        ) : null}
                    </div>
                ) : null}

                {step === 0 ? <>{getFeatureList(t, plan, nextPlan)}</> : null}
                {step === 1 ? (
                    <UpgradePlanDialogSelector
                        billingPeriod={billingPeriod}
                        toggleBillingPeriod={toggleBillingPeriod}
                        onSelectPlan={setChosenPlan}
                        selectedPlan={chosenPlan}
                    />
                ) : null}

                {step !== 2 && !isAppSumo ? (
                    <div className={classes.footer}>
                        <div className={classes.seeAll}>
                            {step === 0 ? (
                                <Link to="/user/organization/subscriptions/plans" target="_blank">
                                    <OpenInNewRoundedIcon className="mr-6" />
                                    {t("limitations.dialog.see_all_plans")}
                                </Link>
                            ) : null}
                        </div>

                        {!isTrial ? (
                            <>
                                <Button variant="tertiary" size="small" onClick={onClose}>
                                    {t("g.not_now")}
                                </Button>
                                {!isMobile && !processing ? (
                                    <Button withMarginLeft size="small" onClick={handleConfirm}>
                                        {/* <RocketIcon /> */}
                                        {t("g.upgrade")}
                                    </Button>
                                ) : null}
                            </>
                        ) : (
                            <Button disabled size="small">
                                Upgrading is not available during trial
                            </Button>
                        )}
                    </div>
                ) : isAppSumo ? (
                    <Button variant="plain">
                        <a href="mailto:support@sessions.us">Contact us</a>
                    </Button>
                ) : null}

                {step === 2 ? (
                    <UpgradePlanDialogPayment
                        billingPeriod={billingPeriod}
                        onClose={handleClose}
                        plan={chosenPlan}
                    ></UpgradePlanDialogPayment>
                ) : null}
            </>
        </Dialog>
    );
}

export default UpgradePlanDialog;
