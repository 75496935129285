import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import Typography from "@ui/cdk/Typography/Typography";
import React from "react";
import classes from "../style/AgendaImportConfirmationDialog.module.scss";

type AgendaImportConfirmationDialogProps = {
    imperativeRef: React.MutableRefObject<DialogImperativeRef>;
    onDialogClose: () => void;
    onConfirmImport: (e: React.MouseEvent<HTMLElement>) => void;
};

const AgendaImportConfirmationDialog = (props: AgendaImportConfirmationDialogProps) => {
    const {imperativeRef, onDialogClose, onConfirmImport} = props;

    return (
        <div>
            <Dialog
                imperativeRef={imperativeRef}
                onClose={onDialogClose}
                classes={{
                    paper: classes.dialogPaper,
                }}
                disableBackdropClick
                disableEscapeKeyDown
                showCloseBtn
            >
                <div className="flex flex-align-center">
                    <Typography variant="xl3" fontWeight="boldest" color="duodenary">
                        Use this agenda?
                    </Typography>
                </div>
                <div className="my-20 fullw">
                    <Typography variant="base" fontWeight="normal" color="tertiary">
                        If you have an existing agenda, it will be replaced. You&apos;ll be able to edit this agenda in the next step.
                    </Typography>
                </div>

                <DialogFooter noBorder noPadding className={classes.dialogFooter}>
                    <Button onClick={onDialogClose} variant="quaternary">
                        Cancel
                    </Button>
                    <Button onClick={onConfirmImport} variant="primary">
                        Use agenda
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default AgendaImportConfirmationDialog;
