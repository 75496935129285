import {WithChildren} from "@workhorse/declarations";
import {DevicesProvider, useDevices, useDeviceManagerEvents, useStreamSettings} from "./DevicesProvider";
import {SilentMicProvider} from "./SilentMicProvider";

export function MediaProvider(props: WithChildren) {
    return (
        <DevicesProvider>
            <SilentMicProvider>{props.children}</SilentMicProvider>
        </DevicesProvider>
    );
}

export {useDevices, useStreamSettings, useDeviceManagerEvents};
