import {forwardRef, Fragment, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {ExtendComponent} from "@workhorse/declarations";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {cls, capitalize} from "@ui/cdk/util/util";
import MuiInputAdornment from "@material-ui/core/InputAdornment";
import PasswordInput from "./PasswordInput";
import InputCharCounter, {InputCharCounterImperativeRef} from "./InputCharCounter";
import FormControl, {MuiFormControlProps, MuiInputLabelProps} from "./FormControl";
import classes from "./style/input.module.scss";
import {ColorVariant, TypographyFontWeight, TypographyVariantTypes} from "../Typography/types";
import MaskedInput from "./MaskedInput";

type InputFieldSize = "small" | "medium" | "large";

type FormControlProps<T extends MuiInputLabelProps = MuiInputLabelProps, F extends MuiFormControlProps = MuiFormControlProps> = {
    /**
     * @param {InputLabelProps} [default=undefined]
     *
     * This should not be defined, but it is passed by other MUI components
     */
    InputLabelProps?: T;

    /**
     * @param {boolean} [default=false] Whether it is a required field
     */
    required?: boolean;

    /**
     * @param {"outlined"|"filled"} [default="outlined"] The variant that should be used for the input
     * by default only outlined exists at the moment, and "filled" should NOT be used
     */
    variant?: T["variant"];

    /**
     * @param {"outlined"|"plain"} [default="outlined"] The variant that should be used for the input
     */

    customVariant?: "outlined" | "plain";

    /**
     * @see material docs for OutlinedInput for this prop
     */
    margin?: T["margin"];
    children?: React.ReactNode | React.ReactNode[];
    className?: string;

    /**
     * @param {string|undefined} [default=undefined] ClassName to be applied on the input label component
     */
    labelClassName?: string;

    /**
     * @param {string|undefined} [default=undefined] ClassName to be applied on the wrapper (FormControl) component
     */
    formControlClassName?: string;

    /**
     * @param {string|React.ReactNode|undefined} [default=undefined] The input label
     * Can be a string, or a ReactNode
     * The asterisk will be auto-added if required=true
     */
    label?: string | React.ReactNode | React.ReactNode[];

    /**
     * @param {boolean|undefined} [default=undefined] An error string to show
     * NOTE: cannot have both error & info
     * error will be prioritezed over info
     */
    error?: boolean;

    /**
     * @param {boolean|undefined} [default=undefined] An error string to show
     * NOTE: cannot have both error & info
     * error will be prioritezed over info
     */
    info?: boolean;

    /**
     * @param {string|undefined} [default=undefined]
     *
     * A text to be shown
     * if props.error == true this will be shown as an error, otherwise as an info
     * NOTE: whether it is an info text or an error, error or info must explicitely be true
     * if undefined, nothing will be shown
     * also, cannot have both error & info
     * error will be prioritezed over info
     */
    helperText?: string;

    /**
     * @param {boolean} [default=false]
     *
     * By default, the OutlinedInput will shrink/grow the label on/out of focus
     * Our components show the label on top of the input
     * Set this to true, if you want to keep the shrinking effect
     */
    shrinkLabel?: boolean;

    /**
     * @param {TypographyVariantTypes} [default=undefined] Typography variant
     */
    typographyVariant?: TypographyVariantTypes;
    /**
     * @param {ColorVariant} [default=undefined] Color variant
     */
    colorVariant?: ColorVariant;
    /**
     * @param {TypographyFontWeight} [default=undefined] Font weight variant
     */
    fontWeightVariant?: TypographyFontWeight;

    /**
     * @param {InputFieldSize} [default="medium"]
     */
    size?: InputFieldSize;
};

export type CustomInputProps<
    T = {
        /**
         * @param {number|undefined} [default=undefined] Whether to enforce a maximum number of characters
         */
        maxCharCount?: number;

        /**
         * @param {boolean|undefined} [default=undefined]
         * if true, the input's endAdornment (if exists) will be combined
         * with an element showing how many characters were typed
         * can be used in conjunction with maxCharCount to show something like
         * i.e. n/max
         */
        showTypedCharCount?: boolean;

        /**
         * @param {boolean|undefined} [default=undefined]
         * if true and rendering a password field, a button will be added
         * which will togge showing/masking the password
         */
        showHidePasswordCharacters?: boolean;

        /**
         * @param {boolean|undefined} [default=undefined]
         * if true it will render multiple values as chips
         */
        withChips?: boolean;

        /**
         * imperative way to control input
         */
        setValueRef?: React.MutableRefObject<((value: string) => void) | undefined>;
    }
> = Omit<FormControlProps, keyof T> & T;

export type InputProps = ExtendComponent<typeof OutlinedInput, "component", CustomInputProps>;

function InputWithRef(props: InputProps, ref: any) {
    const {
        label,
        variant = "outlined",
        margin,
        labelClassName,
        formControlClassName,
        error,
        helperText: helperTextProp,
        info,
        maxCharCount,
        showTypedCharCount,
        shrinkLabel = false,
        InputLabelProps,

        customVariant = "outlined",
        colorVariant,
        fontWeightVariant,
        typographyVariant,
        type = "text",
        endAdornment,
        spellCheck = false,
        autoComplete = undefined,
        onChange: onChangeProp,
        withChips = false,
        size = "medium",
        value: valueProp = "",
        setValueRef,
        ...rest
    } = props;

    const counterIRef: InputCharCounterImperativeRef = useRef();

    const [value, setValue] = useState<string>(valueProp as string);
    const valRef = useRef(value);

    if (setValueRef) {
        setValueRef.current = setValue;
    }

    const [helperText, setHelperText] = useState(helperTextProp);
    const [helperState, setHelperState] = useState({info: info && !error, error: error});

    useEffect(() => {
        if (value !== valueProp) {
            const newVal =
                maxCharCount && (valueProp as string).length > maxCharCount
                    ? (valueProp as string).substr(0, maxCharCount)
                    : (valueProp as string);
            setValue(newVal);
        }
    }, [valueProp]);

    useEffect(() => {
        if (counterIRef.current?.setCount) {
            counterIRef.current.setCount(((value as string) || "").length);
        }
    }, [value]);

    useEffect(() => {
        if (info !== helperState.info || error !== helperState.error || helperTextProp !== helperText) {
            if (helperTextProp !== helperText) {
                setHelperText(helperTextProp);
            }
            if (info !== helperState.info || error !== helperState.error) {
                setHelperState({
                    info: info && !error,
                    error,
                });
            }
        }
    }, [info, error, helperTextProp]);

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
        let val = e.target.value;
        setValue(val);
        const reachedMax = maxCharCount && val.length > maxCharCount;
        if (reachedMax) {
            val = val.substr(0, maxCharCount);
            e.target.value = val;
            setValue(val);
        }
        if (showTypedCharCount && counterIRef.current?.setCount) {
            counterIRef.current.setCount(val.length);
        }
        if (!!!val && props.required) {
            setHelperState;
        }
        if (onChangeProp) {
            onChangeProp(e);
        }
    };

    // The deprecation warning (PasswordInput) here, is ok
    // the PasswordInput has been marked as deprecated to prevent users from using it directly
    // as you can see, it is used under-the-hood
    const ElementToRender = useMemo(
        () => (type !== "password" ? (type === "text-masked" ? MaskedInput : OutlinedInput) : PasswordInput),
        []
    );

    const CharCounter = useMemo(
        () =>
            showTypedCharCount && !props.readOnly ? (
                <InputCharCounter value={value as string} iRef={counterIRef} maxCharCount={maxCharCount} />
            ) : null,
        [showTypedCharCount, maxCharCount]
    );

    const EndAdornment = useMemo(() => {
        const endAdornmentElements = endAdornment ? (
            showTypedCharCount ? (
                <>
                    <Fragment key="end-adornment-prop">{endAdornment}</Fragment>
                    <Fragment key="char-counter">{CharCounter}</Fragment>
                </>
            ) : (
                endAdornment
            )
        ) : showTypedCharCount ? (
            CharCounter
        ) : null;
        return endAdornmentElements ? (
            type === "password" || type === "text-masked" ? (
                /**
                 * A password input which has a button to show the password
                 * will add its own wrapper around the existing endAdornment elements
                 */
                endAdornmentElements
            ) : (
                <MuiInputAdornment position="end">{endAdornmentElements}</MuiInputAdornment>
            )
        ) : null;
    }, [endAdornment, showTypedCharCount]);

    const formControlStyles: React.CSSProperties | undefined = useMemo(() => {
        if (!props.multiline || !withChips || !props.maxRows) {
            return undefined;
        }
        // TODO: @Vasi define maxHeight for small and large inputs
        const out: React.CSSProperties = {
            // @ts-ignore
            maxHeight: props.maxRows * 44,
            overflow: "hidden",
            overflowY: "auto",
        };
        return out;
    }, [props.multiline, props.maxRows, withChips]);

    return (
        <FormControl
            label={label}
            required={props.required}
            variant={variant}
            className={cls(
                "flex fullw",
                props.multiline && classes.multiline,
                props.multiline && showTypedCharCount && "with-counter",
                withChips && classes.withChips,
                withChips && props.multiline && props.maxRows && classes.multilineMaxRowsWithChips,
                formControlClassName,
                classes[customVariant],
                colorVariant && classes[colorVariant],
                fontWeightVariant && classes[fontWeightVariant],
                typographyVariant && classes[`variant${capitalize(typographyVariant)}`]
            )}
            labelClassName={labelClassName}
            error={error === true}
            info={info === true}
            helperText={helperText}
            shrinkLabel={shrinkLabel}
            InputLabelProps={InputLabelProps}
        >
            <ElementToRender
                value={value}
                onChange={type === "number" ? onChangeProp : onChange}
                endAdornment={EndAdornment}
                spellCheck={spellCheck}
                autoComplete={autoComplete}
                ref={ref}
                style={formControlStyles}
                type={type}
                {...rest}
            />
        </FormControl>
    );
}

const Input = forwardRef(InputWithRef);

export default Input;
