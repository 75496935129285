import {makeVar} from "@workhorse/api/state";
import {v4} from "uuid";
import {enableNotifications} from "../playerStateVars";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MicroNotificationProps<TMessage = any> = {
    messages: Pick<MicroNotification<TMessage>, "type" | "message">[];
};

export type MicroNotificationVariant = "default" | "persistent";
export type MicroNotificationPosition = "default" | "topCenter";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MicroNotification<TMessage = any> = {
    id: string;
    type: string;
    message: TMessage;
    component: (props: MicroNotificationProps) => JSX.Element;
    componentProps: MicroNotificationProps["messages"];
    onClick?: () => void;
    onClose?: () => void;
    variant?: MicroNotificationVariant;
    position?: MicroNotificationPosition;
    disableClose?: boolean;
    className?: string;
};

export const microNotification = makeVar<MicroNotification[]>([]);

export type MicroNotificationMessage<TMessage> = Omit<MicroNotification<TMessage>, "componentProps" | "id">;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function microNotify<TMessage = any>(notification: MicroNotificationMessage<TMessage>): void {
    const notificationsEnabled = enableNotifications();

    if (!notificationsEnabled) {
        return;
    }

    const notifications = microNotification()
        .reverse()
        .map((n) => ({...n}));

    if (!notifications.length || notification.type !== notifications[0].type) {
        microNotification([
            ...(notifications || []).reverse(),
            {...notification, id: v4(), componentProps: [{type: notification.type, message: notification.message}]},
        ]);
    } else {
        notifications[0].componentProps.push({type: notification.type, message: notification.message});

        microNotification(notifications.reverse());
    }
}

export function clearAllNotificationsOfType(type: string): void {
    const notifications = microNotification();

    microNotification([...notifications.filter((n) => n.type !== type)]);
}

export function clearAllNotifications(): void {
    microNotification([]);
}
