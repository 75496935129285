import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {Breakpoint} from "@workhorse/util";
import {SessionRecordingState} from "@generated/data";

export type OnResizeCallback = (width: number, height: number, breakpoint?: Breakpoint) => void;

function useRecordingTimerStore() {
    const refElapsedTime = useRef<number>(0);
    const refIntervalId = useRef<NodeJS.Timeout | null>(null);

    const startTimer = useCallback((startAt?: number) => {
        if (startAt !== undefined) {
            refElapsedTime.current = Math.round(startAt);
        }

        if (refIntervalId.current) {
            clearInterval(refIntervalId.current);
        }
        refIntervalId.current = setInterval(() => {
            refElapsedTime.current++;
        }, 1000);
    }, []);

    const stopTimer = useCallback(() => {
        if (refIntervalId.current) {
            clearInterval(refIntervalId.current);
        }
        refIntervalId.current = null;
    }, []);

    const getElapsedTime = useCallback(() => {
        return refElapsedTime.current;
    }, []);

    useEffect(() => {
        return () => {
            if (refIntervalId.current) {
                clearInterval(refIntervalId.current);
            }
        };
    }, []);

    return {
        getElapsedTime,
        startTimer,
        stopTimer,
    };
}

const [RecordingTimerProvider, useRecordingTimer] = createContextProvider(
    {
        name: "RecordingTimerProvider",
        strict: true,
    },
    useRecordingTimerStore
);

export {useRecordingTimer};
export {RecordingTimerProvider};
