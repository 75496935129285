import environment from "@generated/environment";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useRef} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import classes from "./style/AgendaTemplatesOnboardingDialog.module.scss";
import {AgendaItemType, GetAgendaTemplatesDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {commitCreateAgendaTemplate} from "@workhorse/api/designer/methods";
import {useUserInfo} from "@workhorse/providers/User";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useState} from "@workhorse/api/rendering";
import {useHistory} from "react-router-dom";
import {templatesSections} from "../utils";
import Tooltip from "@ui/cdk/Tooltip";

type OnboardingAgendaTemplateDialogProps = {
    onClose?: () => void;
};

function OnboardingAgendaTemplateDialog(props: OnboardingAgendaTemplateDialogProps) {
    const {onClose} = props;

    const dialogRef = useRef<DialogImperativeRef>();

    const history = useHistory();
    const user = useUserInfo();
    const {mixpanelTrack} = useMixpanelTracker();

    const [disabled, setDisabled] = useState(false);
    const [timeoutNotify, setTimeoutNotify] = useState(false);
    const [newAgendaError, setNewAgendaError] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const shouldPushToAgenda = useRef(true);

    const setEditState = designer.state.update;

    const handleMarkAsSeen = async () => {
        await markAsSeen({
            GLOBALS: {
                userOnboardingAgenda: true,
            },
            GET_STARTED_WIDGET: {
                "create-agenda": true,
            },
        });
    };

    const handleSkip = () => {
        mixpanelTrack("frontend-onboarding-agenda-dismissed", "agendas");
        handleMarkAsSeen();
        onClose?.();
    };

    const handleCreateAgenda = () => {
        setDisabled(true);
        mixpanelTrack("frontend-onboarding-create-agenda", "agendas");
        createNewAgendaTemplate();
        onClose?.();
    };

    const createNewAgendaTemplate = async () => {
        if (!shouldPushToAgenda.current) {
            return;
        }

        const templateId = makeId();

        const existing = apollo.cache.readQuery({
            query: GetAgendaTemplatesDocument,
            variables: {
                workspaceId: user.activeWorkspace.id,
            },
        });

        let title = "Agenda";
        if (existing?.agendaTemplates?.some((t) => t?.name === title)) {
            let i = 1;
            while (existing?.agendaTemplates?.some((t) => t?.name === title + " (" + i + ")")) {
                i++;
            }
            title = title + " (" + i + ")";
        }

        designer.state.initializeOrResetState(templateId + "_template");

        const create = designer.api.session.create({
            session: {
                name: title,
                id: templateId + "_template",
            },
            agendaItems: [],
            hasAgenda: true,
            isTemplate: true,
        });

        setEditState({
            sessionId: create,
            templateMode: true,
            dirty: true,
        });

        const agendaItemId = designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Planned,
            title: "",
        });

        designer.api.agendaItem.addArtifact({
            agendaItemId: agendaItemId,
            addDefaultArtifact: true,
            overrides: {},
        });

        await commitCreateAgendaTemplate({
            notifyAfterMs: 5000,
            shouldRetry: false,
            abortAfterMs: 35000,
            notifyCb: (failed, retryAttemptNo) => {
                setTimeoutNotify((c) => (c !== failed ? failed : c));
            },
            timedOutCb: (failed, retryAttemptNo) => {
                if (!failed) {
                    setNewAgendaError(false);
                }
                setTimedOut((c) => (c !== failed ? failed : c));
            },
        }).catch((error) => {
            setNewAgendaError(true);
            console.log("error on creating New Agenda Template", error);
        });

        apollo.client.writeQuery({
            query: GetAgendaTemplatesDocument,
            variables: {
                workspaceId: user.activeWorkspace.id,
            },
            data: {
                agendaTemplates: [
                    ...(existing?.agendaTemplates ?? []),
                    {
                        id: templateId,
                        name: title,
                        updatedAt: new Date(),
                        hasEditorAccess: false,
                        duration: 0,
                        isPublic: false,
                        workspaceId: user.activeWorkspace.id,
                        user: {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                        },
                        __typename: "AgendaTemplate",
                    },
                ],
            },
        });

        handleMarkAsSeen();

        history.push({
            pathname: templatesSections[0].path + "/" + templateId + "?ai-copilot=true",
        });
        shouldPushToAgenda.current = false;
        setDisabled(false);
    };

    const btnDisabled = newAgendaError || timedOut ? false : disabled || timeoutNotify;

    const tooltipMsg = newAgendaError || timedOut ? "Could not create the agenda template due to a network failure. Please try again." : "";

    const disableTooltip = !(newAgendaError || timedOut);

    const onCreateAgendaTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const onCreateAgendaTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <Dialog
            imperativeRef={dialogRef}
            open
            classes={{paper: cls(classes.root, classes.rootBase)}}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={cls("flex flex-col", classes.content)}>
                <img
                    className={cls("fullw", classes.image)}
                    src={`${environment.staticServiceUrl}/onboarding/agenda-dashboard/agenda-templates.png`}
                />

                <div className={classes.info}>
                    <div className={classes.title}>
                        <Typography variant="xl3" color="primary" fontWeight="boldest">
                            Unlock the power of interactive agendas 📚
                        </Typography>
                    </div>
                    <Typography component="div" color="secondary" variant="lg" className={classes.description}>
                        Imagine having a virtual assistant who helps you plan the perfect flow for your sessions and then ensures that
                        everything runs smoothly and on time during your live interactions.
                    </Typography>

                    <Typography component="div" color="secondary" variant="lg" className={classes.description}>
                        It’ll even load your slides, videos, and tools on screen right when you need them, so you can focus on what’s really
                        important – your participants. From here, you can create and manage agendas and share them with your team.
                    </Typography>
                    <Typography component="div" color="primary" variant="lg" fontWeight="bolder">
                        Ready to create your first agenda?
                    </Typography>
                </div>
                <div className={classes.footer}>
                    <Button noFocusBorder variant="tertiary" onClick={handleSkip} loading={btnDisabled} disabled={btnDisabled}>
                        Skip
                    </Button>
                    <Tooltip
                        arrow
                        placement="top"
                        title={tooltipMsg}
                        open={newAgendaError || timedOut || tooltipOpen}
                        onOpen={onCreateAgendaTooltipOpen}
                        onClose={onCreateAgendaTooltipClose}
                        disableFocusListener={disableTooltip}
                        disableHoverListener={disableTooltip}
                        disableTouchListener={disableTooltip}
                    >
                        <Button noFocusBorder variant="primary" onClick={handleCreateAgenda} loading={btnDisabled} disabled={btnDisabled}>
                            Create agenda with the AI Copilot
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
    );
}

export default OnboardingAgendaTemplateDialog;
