import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import IconButton from "@ui/core/components/IconButton";
import classes from "./styles/TimerInput.module.scss";
import Input from "@ui/cdk/Input";
import {ReactComponent as ColumnIcon} from "../../../../../assets/media/column.svg";
import {digitsOnlyRegex, getDisplayValue} from "../utils";
import Typography from "@ui/cdk/Typography";

type TimerInputProps = {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    setTotalValue: React.Dispatch<React.SetStateAction<number>>;
    isActive: boolean;
    prevValueRef: React.MutableRefObject<number>;
    onEditTimer: (currentDuration: number, initialDuration: number) => void;
};

function TimerInput(props: TimerInputProps) {
    const {value, setValue, setTotalValue, isActive, prevValueRef, onEditTimer} = props;

    const handleChangeMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = e.target.value;
        const isValidNumber = digitsOnlyRegex.test(stringValue);
        const value = Number(stringValue);

        if (!isValidNumber || value > 60) {
            e.preventDefault();
            return;
        }

        setValue((prev) => {
            const seconds = Math.floor(prev % 60);
            const validSeconds = value > 59 ? 0 : seconds;

            return value * 60 + validSeconds;
        });
    };

    const handleChangeSeconds = (e: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = e.target.value;
        const isValidNumber = digitsOnlyRegex.test(stringValue);
        const value = Number(stringValue);

        if (!isValidNumber || value > 59) {
            e.preventDefault();
            return;
        }

        setValue((prev) => {
            const minutes = Math.floor(prev / 60);
            const validMinutes = minutes > 59 ? 59 : minutes;

            return value + validMinutes * 60;
        });
    };

    // const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    const displayMinutes = getDisplayValue(minutes);
    const displaySeconds = getDisplayValue(seconds);

    const disabledSubstract = (minutes === 1 && seconds === 0) || minutes < 1;
    const disabledIncrement = minutes > 59 || (minutes >= 59 && seconds > 0);

    const handleSubstract = () => {
        setValue((prev) => {
            const newValue = prev - 60;

            const difference = newValue - value;

            setTotalValue((prev) => {
                const newTotalValue = prev + difference;

                onEditTimer(newValue, newTotalValue);
                return newTotalValue;
            });

            prevValueRef.current = newValue;

            return newValue;
        });
    };
    const handleIncrement = () => {
        setValue((prev) => {
            const newValue = prev + 60;

            const difference = newValue - value;

            setTotalValue((prev) => {
                const newTotalValue = prev + difference;

                onEditTimer(newValue, newTotalValue);
                return newTotalValue;
            });

            prevValueRef.current = newValue;

            return newValue;
        });
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();

        prevValueRef.current = value;
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const difference = value - prevValueRef.current;
        setTotalValue((prev) => prev + difference);
        prevValueRef.current = value;
    };

    return (
        <div className={classes.root}>
            <IconButton className={classes.button} onClick={handleSubstract} disabled={disabledSubstract}>
                <RemoveRoundedIcon />
            </IconButton>
            {isActive ? (
                <>
                    <Typography className={classes.displayTime}>{displayMinutes}</Typography>
                    <ColumnIcon className="mx-4" />
                    <Typography className={classes.displayTime}>{displaySeconds}</Typography>
                </>
            ) : (
                <>
                    <Input
                        formControlClassName={classes.inputWrapper}
                        classes={{
                            // root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={displayMinutes}
                        onChange={handleChangeMinutes}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        type="number"
                    />
                    <ColumnIcon className="mx-4" />
                    <Input
                        formControlClassName={classes.inputWrapper}
                        classes={{
                            // root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={displaySeconds}
                        onChange={handleChangeSeconds}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        type="number"
                    />
                </>
            )}
            <IconButton className={classes.button} onClick={handleIncrement} disabled={disabledIncrement}>
                <AddRoundedIcon />
            </IconButton>
        </div>
    );
}

export default TimerInput;
