import {CalendarView} from "@generated/data";
import {IDateFormatStyle} from "@ui/cdk/DateFormat";
import {addDays, endOfDay, endOfMonth, endOfWeek, isBefore, isSameWeek, startOfDay, startOfMonth, startOfWeek} from "date-fns";
import {isAfter} from "date-fns";
import {CalendarPeriod} from "./definitions";

export type DateOrTimestamp = Date | number;
export type DateString = string;

export type CalendarViewType<TType> = {[key in CalendarView]: TType};
export type CalendarDateFn = (date: DateOrTimestamp) => Date;
export type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const dayOfTheWeekToNumber = (day: string): WeekStartsOn => {
    switch (day.toLowerCase()) {
        case "monday":
        case "mon":
        case "mo":
            return 1;
        case "tuesday":
        case "tue":
        case "tu":
            return 2;
        case "wednesday":
        case "wed":
        case "we":
            return 3;
        case "thursday":
        case "thu":
        case "th":
            return 4;
        case "friday":
        case "fri":
        case "fr":
            return 5;
        case "saturday":
        case "sat":
        case "sa":
            return 6;
        case "sunday":
        case "sun":
        case "su":
            return 0;
    }

    return 0;
};

export const daysOfTheWeekArray = (format: "D" | "DD" | "DDD" | "DDDD" = "D", firstDayOfTheWeek: string | WeekStartsOn) => {
    const firstDay = typeof firstDayOfTheWeek === "string" ? dayOfTheWeekToNumber(firstDayOfTheWeek) : firstDayOfTheWeek;
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return [...days.slice(firstDay), ...days.slice(0, firstDay)].map((day) =>
        format.length === 4 ? day.toUpperCase() : day.substr(0, format.length).toUpperCase()
    );
};

export const startOfWeekLocale = (date: number | Date, firstDayOfWeek?: WeekStartsOn) =>
    startOfWeek(date, {weekStartsOn: firstDayOfWeek ?? 1});
export const endOfWeekLocale = (date: number | Date, firstDayOfWeek?: WeekStartsOn) =>
    endOfDay(endOfWeek(date, {weekStartsOn: firstDayOfWeek ?? 1}));
export const isSameWeekLocale = (date1: DateOrTimestamp, date2: DateOrTimestamp, firstDayOfWeek?: WeekStartsOn): boolean =>
    isSameWeek(date1, date2, {weekStartsOn: firstDayOfWeek ?? 1});

export const calendarStartOfMonth = (date: number | Date, firstDayOfWeek?: WeekStartsOn) =>
    startOfWeekLocale(startOfMonth(date), firstDayOfWeek ?? 1);
export const calendarEndOfMonth = (date: number | Date, firstDayOfWeek?: WeekStartsOn) => {
    const start = startOfWeekLocale(startOfMonth(date), firstDayOfWeek ?? 1);

    return endOfDay(addDays(start, 41));
};

export const isBetween = (date: DateOrTimestamp, before: DateOrTimestamp, after: DateOrTimestamp): boolean => {
    return isAfter(date, before) && isBefore(date, after);
};

export const CALENDAR_VIEW_MAP: CalendarViewType<string> = {
    [CalendarView.Days]: "timeGridDay",
    [CalendarView.Weeks]: "timeGridWeek",
    [CalendarView.Months]: "dayGridMonth",
};

export const CALENDAR_REVERSED_VIEW_MAP = {
    timeGridDay: CalendarView.Days,
    timeGridWeek: CalendarView.Weeks,
    dayGridMonth: CalendarView.Months,
};

export const CURRENT_PERIOD_BUTTON_TEXT: CalendarViewType<string> = {
    [CalendarView.Days]: "Today",
    [CalendarView.Weeks]: "This week",
    [CalendarView.Months]: "This month",
};

type CalendarPeriodFormat = IDateFormatStyle[] | Record<keyof CalendarPeriod, IDateFormatStyle[]>;

export const CURRENT_PERIOD_FORMAT: CalendarViewType<CalendarPeriodFormat> = {
    [CalendarView.Days]: [
        {format: "weekday", fontWeight: 600},
        {sep: " - "},
        {format: "month", fontWeight: 400},
        {format: "dayOfMonth-ss", fontWeight: 400},
    ],
    [CalendarView.Weeks]: {
        startDate: [
            {format: "month", fontWeight: 600},
            {format: "dayOfMonth", fontWeight: 600},
            {sep: " - ", fontWeight: 600},
        ],
        endDate: [
            {format: "month", fontWeight: 600},
            {format: "dayOfMonth", fontWeight: 600},
        ],
    },
    [CalendarView.Months]: [
        {format: "month", fontWeight: 600},
        {sep: " - ", fontWeight: 600},
        {format: "year", fontWeight: 400},
    ],
};

export const START_OF_PERIOD: CalendarViewType<CalendarDateFn> = {
    [CalendarView.Days]: startOfDay,
    [CalendarView.Weeks]: startOfWeekLocale,
    [CalendarView.Months]: startOfMonth,
};

export const END_OF_PERIOD: CalendarViewType<CalendarDateFn> = {
    [CalendarView.Days]: endOfDay,
    [CalendarView.Weeks]: endOfWeekLocale,
    [CalendarView.Months]: endOfMonth,
};
