import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import {CustomResolvers} from "./types";

export const AgendaItem: CustomResolvers["AgendaItem"] = {
    // IMPORTANT - this will override the apollo resolver - make sure you add everything here
    ...fieldResolvers.AgendaItem,
    order: {
        ...fieldResolvers.AgendaItem.order,
        merge: (existing, incoming, {variables}) => {
            if ((variables as any).override) {
                return incoming;
            }
            // when attaching tool over agenda without editing, the current agenda item is set to order -1
            // we override the merge functionality to prevent order restoring when a watchdog upsert commes before the tool is confirmed
            if (existing === -1 && typeof incoming === "number" && incoming !== -1) {
                return existing;
            }
            return incoming;
        },
    },
    descriptionJson: {
        ...fieldResolvers.AgendaItem.descriptionJson,
        merge: (existing, incoming, {mergeObjects}) => {
            return incoming && typeof incoming === "object" ? incoming : {};
        },
    },
    artifact: {
        read(existing, {variables, readField}) {
            if (existing && (variables as any)?.excludeDeleted) {
                const isDeleted = readField("isDeleted", existing);
                if (isDeleted) {
                    return null;
                }
            }
            return existing;
        },
    },
    agendaItemSpeakers: {
        // @ts-expect-error
        read(existing, {variables, readField}) {
            if (existing && existing.length) {
                if ((variables as any)?.excludeDeleted) {
                    const output = existing.filter((ref) => {
                        const isDeleted = readField("isDeleted", ref);
                        return !isDeleted;
                    });
                    return output;
                }
                if (!(variables as any)?.noDiff) {
                    return existing;
                } else {
                    const output = existing.filter((ref) => {
                        const isComitted = !!readField("createdAt", ref);
                        return isComitted;
                    });
                    return output;
                }
            }
            return [];
        },
        merge: false,
    },
};
