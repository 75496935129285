import loadable from "@workhorse/api/loadable";
import {RouteHOCProps} from "@workhorse/declarations";

const ContactsEntrypoint = loadable(() => import("./ContactsEntrypoint"));

type ContactsHOCProps = RouteHOCProps<{}>;

function ContactsHoc(props: ContactsHOCProps): JSX.Element {
    return (
        <div className="route-hoc flex-align-start contacts-hoc" key="contacts-hoc">
            <ContactsEntrypoint key="contacts-entry-point" />
        </div>
    );
}

export default ContactsHoc;
