import apollo from "@workhorse/api/apollo";
import {
    ApolloQueryResult,
    DocumentNode,
    NetworkStatus,
    OperationVariables,
    QueryHookOptions,
    QueryLazyOptions,
    QueryResult,
    TypedDocumentNode,
    useLazyQuery,
    useQuery,
} from "@workhorse/api/data";
import React, {ReactNode, createElement, memo, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {isObject} from "@workhorse/util";
import Loading from "./Loading";

const deepQueryResultMerge = (existing: any, incoming: any, prependOnMerge: boolean = false) => {
    return Object.keys(existing || {}).reduce((out, key) => {
        return {
            ...out,
            [key]: Array.isArray(existing[key])
                ? prependOnMerge
                    ? incoming[key].concat(existing[key])
                    : existing[key].concat(incoming[key])
                : isObject(existing[key])
                ? deepQueryResultMerge(existing[key], incoming[key], prependOnMerge)
                : incoming[key],
        };
    }, {});
};

type QueryPayload<T> = Pick<QueryResult<T>, "data">["data"];

export interface WithQueryPayloadFromGenerated<T = any, TVars = OperationVariables> {
    data: QueryPayload<T>;
    /**
     * @deprecated use `refetchSame` method
     */
    refetch: (variables?: Partial<TVars>) => Promise<ApolloQueryResult<T>>;
    refetchSame: () => void;
    paginate: (mode: PaginationMode, options: QueryLazyOptions<TVars>) => void;
}

export type WithQueryPayloadFromDocument<T extends TypedDocumentNode> = T extends TypedDocumentNode<infer T, infer TVars>
    ? {
          data: QueryPayload<T>;
          /**
           * @deprecated use `refetchSame` method
           */
          refetch: (variables?: Partial<TVars>) => Promise<ApolloQueryResult<T>>;
          refetchSame: () => void;
          paginate: (mode: PaginationMode, options: QueryLazyOptions<TVars>) => void;
      }
    : never;

type PaginationMode = "continuous" | "single-page";

const QueryContainer = memo(
    function QueryContainer<Props = any, T = any, TVars = OperationVariables>({
        Component,
        props,
        document,
        options,
        preloader,
    }: {
        Component: React.ComponentType<Props>;
        props: Props;
        document: DocumentNode | TypedDocumentNode<T, TVars>;
        options?: QueryHookOptions<T, TVars>;
        preloader?: React.ReactNode | React.ReactNode[];
    }) {
        const paginationMode = useRef<PaginationMode>("continuous");

        const docName = useRef(
            (() => {
                const docDef = document.definitions.find((x) => x.kind === "OperationDefinition");
                return docDef && docDef.kind === "OperationDefinition" ? docDef.name?.value ?? "unknown" : "unknown";
            })()
        );

        const {data, loading, error, refetch, networkStatus} = useQuery(document, {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            notifyOnNetworkStatusChange: true,
            ...options,
        });

        // @ts-expect-error
        const {errorHandler, ...otherProps} = props;

        const [lazyFetch] = useLazyQuery(document, {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            onCompleted: (lazyData) => {
                apollo.client.writeQuery({
                    query: document,
                    data:
                        paginationMode.current === "continuous"
                            ? deepQueryResultMerge(data, lazyData, ((options || {}).variables || ({} as any)).hasOwnProperty("before"))
                            : lazyData,
                    variables: options?.variables,
                });
            },
        });

        const paginate = (mode: PaginationMode, lazyOptions: QueryLazyOptions<TVars>) => {
            paginationMode.current = mode;
            lazyFetch(lazyOptions);
        };

        const refetchSame = () => refetch((options || {}).variables || {});

        // we want to re-compute and update our output
        // ONLY when we-re 100% sure that loading has finished
        // AND data exists
        const [watcher, setWatcher] = useState<boolean>(false);

        // mitigating an un-necessary re-render when the fetched data comes directly from the cache
        const initialState = useRef({
            firstLoad: false,
            dataAvailable: !loading && data ? true : false,
        });

        const isCacheOnly =
            options?.fetchPolicy === "cache-only" || (initialState.current.firstLoad && options?.nextFetchPolicy === "cache-only");

        // this is a good example why canUpdate doesn't need to be in the dep array of the useEffect
        // canUpdate is automatically re-computed when loading changes meaning whatever is executed inside the effect
        // will have acces to the new value of canUpdate... the linter rule is not wrong, just poorly designed
        const canUpdate = initialState.current.firstLoad || !initialState.current.dataAvailable;

        useEffect(() => {
            const isReady = networkStatus == NetworkStatus.ready || isCacheOnly;
            if (isReady && (data || isCacheOnly) && canUpdate) {
                setWatcher((c) => !c);
            }
            if (!initialState.current.firstLoad && (data || isCacheOnly)) {
                initialState.current.firstLoad = true;
            }
        }, [data, networkStatus, isCacheOnly]);

        if (error) {
            if (errorHandler) {
                errorHandler(error);
            } else {
                throw error;
            }
        }

        // @ts-ignore
        // if (props.log) {
        //     console.log({hanged: error || (loading && !data && !initialState.current.firstLoad), loading});
        // }

        return useMemo(
            () =>
                !(options || {}).hasOwnProperty("skip") && (error || (loading && !data && !initialState.current.firstLoad)) ? (
                    <>{preloader === false ? null : preloader || <Loading location={`QueryRenderer->${docName.current}`} />}</>
                ) : (
                    // @ts-ignore
                    <Component
                        {...otherProps}
                        data={data}
                        refetch={refetch}
                        paginate={paginate}
                        refetchSame={refetchSame}
                        key="qr-component"
                    />
                ),
            [watcher, error, props]
        );
    },
    (p, n) => p === n
);

/**@deprecated Please use the component from DataFetcher.tsx */
function QueryRenderer<Props = any, T = any, TVars = OperationVariables>(
    Component: React.ComponentType<Props>,
    document: DocumentNode | TypedDocumentNode<T, TVars>,
    options?: Omit<QueryHookOptions<T, TVars>, "variables" | "fetchPolicy" | "nextFetchPolicy" | "client" | "skip"> & {
        variables?: QueryHookOptions<T, TVars>["variables"] | ((props: Props) => QueryHookOptions<T, TVars>["variables"]);
        fetchPolicy?: QueryHookOptions<T, TVars>["fetchPolicy"] | ((props: Props) => QueryHookOptions<T, TVars>["fetchPolicy"]);
        nextFetchPolicy?: QueryHookOptions<T, TVars>["nextFetchPolicy"] | ((props: Props) => QueryHookOptions<T, TVars>["nextFetchPolicy"]);
        client?: QueryHookOptions<T, TVars>["client"] | ((props: Props) => QueryHookOptions<T, TVars>["client"]);
        skip?: boolean | ((props: Props) => boolean);
    },
    preloader?: React.ReactNode | React.ReactNode[] | false | ((props: Props) => React.ReactNode | React.ReactNode[])
) {
    return (
        props: Omit<Props, "data" | "refetch" | "refetchSame" | "paginate"> & {
            children?: React.ReactNode | React.ReactNode[];
            errorHandler?: (error: any) => void;
            log?: boolean;
        }
    ) =>
        // need to pipe this throuhg, to a functional component that allows us to use hooks
        // computing our logic here WON'T WORK
        // because React will complain about the rules of hooks
        createElement(QueryContainer, {
            Component,
            props,
            document,
            options:
                options &&
                Object.assign(
                    {},
                    options,
                    typeof options.variables == "function"
                        ? {
                              variables: (options.variables as Function)(props),
                          }
                        : {},
                    typeof options.fetchPolicy === "function"
                        ? {
                              fetchPolicy: (options.fetchPolicy as Function)(props),
                          }
                        : {},
                    typeof options.nextFetchPolicy === "function"
                        ? {
                              nextFetchPolicy: (options.nextFetchPolicy as Function)(props),
                          }
                        : {},
                    typeof options.client === "function"
                        ? {
                              client: (options.client as Function)(props),
                          }
                        : {},
                    typeof options.skip === "function"
                        ? {
                              skip: (options.skip as Function)(props),
                          }
                        : {}
                ),
            preloader: preloader as ReactNode | ReactNode[],
            key: "query-container",
        });
}

export default QueryRenderer;
