import {
    AnswerParticipantDocument,
    AskParticipantDocument,
    ConferenceParticipantStatus,
    KickParticipantFromSessionDocument,
    OneParticipantConferenceStatusFragmentDoc,
    SetParticipantConferenceStatusDocument,
    UpdateParticipantsInSessionDocument,
    AnswerParticipantMutationVariables,
    AskParticipantMutationVariables,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import toast from "@workhorse/api/toast";
import {rbac} from "@workhorse/api/user";
import {readParticipants} from "@workhorse/providers/SessionDataProviders";

export const raiseHand = (participantId: string, isRaisingHand: boolean) => {
    apollo.client.mutate({
        mutation: SetParticipantConferenceStatusDocument,
        variables: {
            participantId,
            newStatus: isRaisingHand ? ConferenceParticipantStatus.Participant : ConferenceParticipantStatus.PendingSpeaker,
        },
        fetchPolicy: "no-cache",
    });
};

export const forceHandDownByOwner = (participantId: string) => {
    apollo.client.mutate({
        mutation: SetParticipantConferenceStatusDocument,
        variables: {
            participantId,
            newStatus: ConferenceParticipantStatus.Participant,
        },
        fetchPolicy: "no-cache",
    });
};

export const inviteToSpeak = (participantId: string) => {
    const currentStatus = apollo.cache.readFragment({
        fragment: OneParticipantConferenceStatusFragmentDoc,
        fragmentName: "OneParticipantConferenceStatus",
        id: apollo.cache.identify({__ref: participantId}),
    });

    if (currentStatus?.conferenceStatus === ConferenceParticipantStatus.InvitedToSpeak) {
        toast("Already invited.", {type: "warning", position: "top", duration: 5000});
        return;
    }

    apollo.client.mutate({
        mutation: SetParticipantConferenceStatusDocument,
        variables: {
            participantId,
            newStatus: ConferenceParticipantStatus.Speaker,
        },
        fetchPolicy: "no-cache",
    });
};

export const setParticipantConferenceRole = (participantId: string, newStatus: ConferenceParticipantStatus) => {
    apollo.client.mutate({
        mutation: SetParticipantConferenceStatusDocument,
        variables: {
            participantId,
            newStatus,
        },
        fetchPolicy: "no-cache",
    });
};

export const kickParticipant = (participantId: string, sessionId: string, ban = false) => {
    return apollo.client.mutate({
        mutation: KickParticipantFromSessionDocument,
        variables: {
            participantId,
            ban,
            session: {
                id: sessionId,
            },
        },
        fetchPolicy: "no-cache",
    });
};

export const toggleAssistantRole = async (participantId: string, sessionId: string) => {
    const isAssistant = rbac(participantId, "session.isAssistant");

    const sessionParticipantsData = readParticipants({
        sessionId,
    });

    const currentParticipant = sessionParticipantsData?.find((p) => p.id === participantId);
    if (currentParticipant) {
        apollo.client.mutate({
            mutation: UpdateParticipantsInSessionDocument,
            variables: {
                sessionId: sessionId,
                upsertParticipantJSONs: [
                    {
                        ...currentParticipant,
                        ...(isAssistant && !currentParticipant.isApproved ? {isApproved: true} : null),
                        ...(isAssistant && !currentParticipant.submittedPasscode ? {submittedPasscode: true} : null),
                        rbac: {
                            ...(currentParticipant.rbac ?? {}),
                            session: {
                                ...(currentParticipant.rbac?.session ?? {}),
                                isAssistant: !isAssistant,
                            },
                        },
                    },
                ],
            },
        });
    } else {
        toast("Could not find participant in session.", {type: "error"});
    }
};

export const askParticipant = (
    participantId: string,
    {askToStartCamera, askToUnmute}: Partial<Omit<AskParticipantMutationVariables, "participantId">>
) => {
    return apollo.client.mutate({
        mutation: AskParticipantDocument,
        variables: {
            participantId,
            askToUnmute,
            askToStartCamera,
        },
        fetchPolicy: "no-cache",
    });
};

export const answerParticipant = (
    sessionId: string,
    {askToStartCamera, askToUnmute}: Partial<Omit<AnswerParticipantMutationVariables, "sessionId">>
) => {
    return apollo.client.mutate({
        mutation: AnswerParticipantDocument,
        variables: {
            sessionId,
            askToUnmute,
            askToStartCamera,
        },
        fetchPolicy: "no-cache",
    });
};
