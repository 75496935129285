import {SelectProps} from "@ui/cdk/Select/Select";
import GuestLanguageSelector from "./components/GuestLanguageSelector";
import UserLanguageSelector from "./components/UserLanguageSelector";

export type LanguageSelectorType = {
    className?: string;
    selectProps?: SelectProps;
    skipUpdate?: boolean;
    ownerLanguage?: string;
    checkUrlQueryForLanguage?: boolean;
};

type LanguageSelectorProps = {
    isGuest: boolean;
} & LanguageSelectorType;

function LanguageSelector(props: LanguageSelectorProps & {isGuest: boolean}) {
    const {isGuest, ...rest} = props;

    return isGuest ? <GuestLanguageSelector {...rest} /> : <UserLanguageSelector {...rest} />;
}

export default LanguageSelector;
