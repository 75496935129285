import toast from "@workhorse/api/toast";
import CopyToClipboard from "react-copy-to-clipboard";
import {UtmSet} from "./types";
import environment from "@generated/environment";
import Button from "@ui/cdk/Button";
import {PropsWithChildren} from "@workhorse/api/rendering";

import Typography from "@ui/cdk/Typography";

type UtmLinkProps = PropsWithChildren<{
    utmSet: UtmSet;
    slug: string;
}>;

const UtmLink = (props: UtmLinkProps) => {
    const utmSet = props.utmSet;
    const slug = props.slug;
    const utmParams = Object.keys(utmSet)
        .filter((key) => !!utmSet[key])
        .map((key) => `${key}=${encodeURI(utmSet[key])}`)
        .join("&");

    const baseURL = environment.eventBaseURL;

    const link = `${baseURL}/${slug}${encodeURI(utmParams ? "?" + utmParams : "")}`;

    const onCopy = () => {
        toast("Copied to clipboard", {type: "success"});
    };
    return (
        <div>
            <CopyToClipboard text={link} onCopy={onCopy}>
                {props.children}
            </CopyToClipboard>
        </div>
    );
};

export default UtmLink;
