import {useQuery} from "@apollo/client";
import {GetWorkspaceMembersDocument, MemberFragment} from "@generated/data";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {FuseOptions, useFuseWorker} from "@workhorse/api/fuse";
import React, {useMemo, useRef, useState} from "@workhorse/api/rendering";
import {useUserInfo} from "@workhorse/providers/User";
import IconButton from "@workhorse/ui/core/components/IconButton";
import InputAdornment from "@workhorse/ui/core/components/InputAdornment";
import {ReactComponent as SearchIcon} from "../../../assets/media/search-icon.svg";
import classes from "./styles/WorkspaceMemberSelector.module.scss";
import WorkspaceMemberSelectorEntry from "./WorkspaceMemberSelectorEntry";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import environment from "@generated/environment";
import {CircularProgress} from "@material-ui/core";
import {useOrganizationAccess, useWorkspaceAccess} from "@workhorse/api/access/hooks";

const membersOptions: FuseOptions<MemberFragment> = {
    keys: [["email"], ["firstName"], ["lastName"]],
    threshold: 0.3,
    shouldSort: true,
    includeScore: true,
};

type WorkspaceMemberSelectorProps = {
    filteredMemberEmails?: string[];
    className?: string;
    onAddMember: (member: MemberFragment) => void;
};

function WorkspaceMemberSelector(props: WorkspaceMemberSelectorProps) {
    const {className, filteredMemberEmails, onAddMember} = props;

    const user = useUserInfo();
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>("");
    const [showResults, setShowResults] = useState<boolean>(false);

    const {data, loading} = useQuery(GetWorkspaceMembersDocument, {
        variables: {
            workspaceId: user.activeWorkspace.id,
            excludeSelf: true,
        },
    });

    const {canAccessMemberContacts} = useWorkspaceAccess();
    const canAccessContacts = canAccessMemberContacts();

    const organizationAccess = useOrganizationAccess();
    const canManageMembers = organizationAccess.canEditMembers();
    const members = data?.workspace?.members ?? [];

    const filteredAddedMembers = useMemo(
        () => members.filter((x) => !filteredMemberEmails?.includes(x.email)),
        [members, filteredMemberEmails]
    );

    const [filteredMembers] = useFuseWorker(filteredAddedMembers, value, membersOptions);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setValue(value);
    };

    const handleClearInput = () => {
        setValue("");
    };

    const handleFocus = () => {
        setShowResults(true);
    };

    const handleBlur = () => {
        setShowResults(false);
        inputRef.current?.blur();
    };

    const clearInput = () => {
        setValue("");
    };

    function goToManageMembers() {
        window.open(`${environment.authService.appRoot}/user/workspace/user-management`, "_blank");
    }

    return (
        <div className={cls(classes.root, className)}>
            <Input
                value={value}
                placeholder="Search members"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={classes.searchInput}
                endAdornment={
                    value ? (
                        <InputAdornment position="end">
                            <IconButton type="submit" size="small" onClick={handleClearInput}>
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : loading ? (
                        <InputAdornment position="end">
                            <CircularProgress size={20} thickness={6} />
                        </InputAdornment>
                    ) : undefined
                }
                startAdornment={
                    <InputAdornment position="start">
                        <IconButton type="submit" size="small">
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
                inputProps={{
                    "data-private": "lipsum",
                    ref: inputRef,
                }}
                disabled={loading}
            />
            {canAccessContacts && showResults && filteredMembers.length > 0 && (
                <div className={classes.searchResults}>
                    <div className={classes.searchResultsList}>
                        {filteredMembers.map((m) => (
                            <WorkspaceMemberSelectorEntry
                                member={m}
                                key={m.id}
                                onClick={onAddMember}
                                handleBlur={handleBlur}
                                clearInput={clearInput}
                            />
                        ))}
                    </div>
                </div>
            )}

            {canAccessContacts && showResults && filteredMembers.length === 0 && (
                <div className={classes.noMembers}>
                    <Typography variant="base" color="blueGray400" fontWeight="normal">
                        No members found
                    </Typography>
                    {canManageMembers && (
                        <Button
                            variant="tertiary"
                            size="small"
                            endIcon={<OpenInNewRoundedIcon />}
                            onClick={goToManageMembers}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            Manage members
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

export default WorkspaceMemberSelector;
