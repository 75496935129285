import {GOOGLE_SYNC_SCOPES_ONLY_MAIL, MICROSOFT_SYNC_SCOPES_ONLY_MAIL} from "@common/external-providers/scopes";
import {GetSyncedEmailsDocument} from "@generated/data";
import environment from "@generated/environment";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography/Typography";
import {useGoogleLogin, useOffice365Login} from "@ui/cdk/util";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import {calendarExternalEventsLoading} from "@workhorse/api/calendar";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import {markAsSeen, useSeenSection} from "@workhorse/api/seen";
import toast from "@workhorse/api/toast";
import {readQuery} from "@workhorse/dataApi";
import {useTranslation} from "react-i18next";
import {ReactComponent as GmailIcon} from "../../../../assets/media/gmail-icon.svg";
import {ReactComponent as MarkedEmailIcon} from "../../../../assets/media/mark-email-read.svg";
import {ReactComponent as OutlookIcon} from "../../../../assets/media/ms-outlook-logo.svg";
import classes from "./styles/SyncEmailNotification.module.scss";

type SyncEmailNotificationProps = {
    isBooking: boolean;
};

type EmailProviderName = {
    GOOGLE: "Gmail";
    MICROSOFT: "MS Outlook";
};

type EmailProviderNameKeys = keyof EmailProviderName;
type EmailProviderNameValues = EmailProviderName[EmailProviderNameKeys];

const EMAIL_PROVIDER_NAME: EmailProviderName = {
    GOOGLE: "Gmail",
    MICROSOFT: "MS Outlook",
};

const SyncEmailNotification = (props: SyncEmailNotificationProps) => {
    const {t} = useTranslation();
    const {isBooking} = props;

    const imperativeRef = useRef<any | null>(null);
    const seenSyncEmailNotification = useSeenSection("GLOBALS.syncEmailNotification");
    const accounts = readQuery("GetAllSyncedAccountsDocument");

    const hasGmailMailSendSync = accounts?.getGoogleSyncedEmails?.[0]?.permissions
        ?.filter((p) => p.granted)
        ?.some((p) => p.permission === "MailSend");

    const hasOutlookMailSendSync = accounts?.getMicrosoftSyncedEmails?.[0]?.permissions
        ?.filter((p) => p.granted)
        ?.some((p) => p.permission === "MailSend");

    const isSynced = hasGmailMailSendSync || hasOutlookMailSendSync;

    const [showNotification, setShowNotification] = useState(!(seenSyncEmailNotification || isSynced));

    const handleClose = () => {
        markAsSeen({
            GLOBALS: {
                syncEmailNotification: true,
            },
        });
        setShowNotification(false);
    };

    const history = useHistory();
    const currentLocation = history.location.pathname;

    const handleSyncEmail = async () => {
        if (environment.authService.appRoot !== environment.authService.originalAppRoot) {
            if (currentLocation !== "/") {
                window.open(`${environment.authService.originalAppRoot}${currentLocation}?emailSync=true`, "_blank");
                return;
            } else {
                history.push(`/user/account/?emailSync=true`);
                return;
            }
        }
        if (imperativeRef?.current?.toggle) {
            imperativeRef.current.toggle();
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            const params = new URLSearchParams(window.location.search);
            const shouldOpenEmailSync = params.get("emailSync") === "true";

            if (shouldOpenEmailSync) {
                toast(
                    <div>
                        {t("user.sync_email.description")}
                        <br /> {t("user.sync_email.description2")}
                    </div>,
                    {
                        type: "info",
                        duration: 10000,
                        className: classes.cnameToast,
                    }
                );
                handleSyncEmail();
                const newUrl = new URL(window.location.href);
                newUrl.searchParams.delete("emailSync");
                window.history.replaceState({}, document.title, newUrl.href);
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            {showNotification ? (
                <div
                    className={cls(
                        classes.root,
                        "flex flex-row gap-12 flex-justify-between flex-align-center",
                        isBooking ? classes.rootIsBooking : ""
                    )}
                >
                    <div className={cls("flex flex-row fullw", isBooking && "flex-align-center")}>
                        <div className={classes.iconWrapper}>
                            <MarkedEmailIcon />
                        </div>
                        <div className={cls("flex flex-row flex-wrap flex-justify-between gap-12 fullw", isBooking && "flex-align-center")}>
                            <div className="flex flex-row flex-wrap gap-8">
                                <Typography variant="lg" color="blueGray400" fontWeight="bold" className={classes.notificationTitle}>
                                    {t("user.sync_email.sync_your_email")}
                                </Typography>
                                <Typography variant="lg" color="quaternary" className={classes.notificationSubtitle}>
                                    {t("user.sync_email.sync_your_email_description")}
                                </Typography>
                            </div>
                            <Button variant="tertiary" size="small" onClick={handleSyncEmail}>
                                {t("user.sync_email.sync_my_email")}
                            </Button>
                        </div>
                    </div>
                    <div className={cls(classes.closeIcon, isBooking && classes.closeIconIsBooking)} onClick={handleClose}>
                        <CloseRoundedIcon />
                    </div>
                </div>
            ) : null}

            <EmailConnections imperativeRef={imperativeRef} setShowNotification={setShowNotification} />
        </>
    );
};

type EmailConnectionsProps = {
    imperativeRef: React.MutableRefObject<any | null>;
    subtitle?: string;
    setShowNotification?: (showNotification: boolean) => void;
    onLoginSuccess?: () => void;
};

export const EmailConnections = ({imperativeRef, subtitle, setShowNotification, onLoginSuccess}: EmailConnectionsProps) => {
    const {t} = useTranslation();
    const dialogImperativeRef = useRef<DialogImperativeRef>();
    const [chosenProvider, setChosenProvider] = useState<string>();
    const [touched, setTouched] = useState(false);
    const [syncing, setSyncing] = useState(false);

    const onLogin = () => {
        setTouched(true);

        apollo.client
            .query({
                query: GetSyncedEmailsDocument,
                fetchPolicy: "network-only",
            })
            .then((res) => {
                if (chosenProvider) {
                    onLoginSuccess?.();
                    toast(<>{t("user.sync_email.sync_success", {email_provider: EMAIL_PROVIDER_NAME[chosenProvider]})}</>);
                    setSyncing(false);
                    setShowNotification?.(false);
                    handleOnClose();
                }
            });
    };

    const onLoginFailure = () => {
        setSyncing(false);
        toast(<>{t("user.sync_email.sync_error")}</>);
        setShowNotification?.(true);
    };

    const [signInWithGoogle] = useGoogleLogin({
        onLogin,
        onLoginFailure,
    });

    const [signInWithOffice365] = useOffice365Login({onLogin, onLoginFailure});

    const handleOnClose = () => {
        setChosenProvider(undefined);

        if (imperativeRef?.current?.toggle) {
            imperativeRef.current.toggle();
        }

        if (touched) {
            calendarExternalEventsLoading("load");
        }
    };

    const handleOnChoseProvider = (provider: EmailProviderNameValues) => {
        setChosenProvider(provider);
    };

    const handleOnAddEmailProvider = () => {
        switch (chosenProvider) {
            case EMAIL_PROVIDER_NAME.GOOGLE:
                signInWithGoogle(GOOGLE_SYNC_SCOPES_ONLY_MAIL);
                setSyncing(true);
                break;

            case EMAIL_PROVIDER_NAME.MICROSOFT:
                signInWithOffice365(MICROSOFT_SYNC_SCOPES_ONLY_MAIL);
                setSyncing(true);
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        imperativeRef.current = dialogImperativeRef.current;
    }, []);

    return (
        <Dialog onClose={handleOnClose} imperativeRef={dialogImperativeRef}>
            <div className={cls(classes.dialog, syncing && classes.dialogSyncing)}>
                <Typography className={classes.dialogTitle}>Email connections</Typography>

                {subtitle && <Typography className={classes.dialogSubtitle}>{subtitle}</Typography>}

                <Typography className={classes.dialogSubtitle}>Which email would you like to sync?</Typography>

                <div className={classes.dialogProviderList}>
                    <div
                        className={cls(
                            classes.dialogProvider,
                            chosenProvider === EMAIL_PROVIDER_NAME.GOOGLE && classes.dialogProviderChosen
                        )}
                        onClick={handleOnChoseProvider.bind(this, EMAIL_PROVIDER_NAME.GOOGLE)}
                    >
                        <GmailIcon className={classes.dialogProviderIcon} />
                        <Typography>Gmail</Typography>
                    </div>

                    <div
                        className={cls(
                            classes.dialogProvider,
                            chosenProvider === EMAIL_PROVIDER_NAME.MICROSOFT && classes.dialogProviderChosen
                        )}
                        onClick={handleOnChoseProvider.bind(this, EMAIL_PROVIDER_NAME.MICROSOFT)}
                    >
                        <OutlookIcon className={classes.dialogProviderIcon} />

                        <Typography>MS Outlook</Typography>
                    </div>
                </div>

                <div className={classes.dialogActions}>
                    <Button variant="quaternary" onClick={handleOnClose} data-id="sync-email-close-button">
                        Close
                    </Button>

                    <Button onClick={handleOnAddEmailProvider} disabled={!chosenProvider} data-id="sync-email-confirm-button">
                        Sync email
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default SyncEmailNotification;
