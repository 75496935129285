import {MacroArtifactProps} from "@workhorse/declarations";
import TimerWrapper from "./components/TimerWrapper";

type TimerUiProps = {
    isMemoryMode: boolean;
};

function TimerUi(props: TimerUiProps) {
    return <>{!props.isMemoryMode && <TimerWrapper {...props} />}</>;
}

export default TimerUi;
