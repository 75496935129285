import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {ActionCategory, categMap, toolType, urlBasedArtifacts} from "@workhorse/components/command-palette/actionCategTree";
import {usePaletteExcludedItems} from "@workhorse/components/command-palette/PaletteActionProvider";
import {SessionOnboardingType, useIsWantedAgenda, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";
import {usePaletteOpen} from "../command-palette/CommandPaletteProviders";

export function OnboardingController() {
    const {onboardingType, flow, tooltip, next, nextFlow, reset, index, completeFlow, completedFlows} = useOnboardingSession();
    const isWantedAgenda = useIsWantedAgenda(tooltip);
    const isOnboarding = onboardingType != null;
    const currentAgendaItem = useCurrentAgendaItem();

    const {setExcludedItems} = usePaletteExcludedItems();
    const [paletteOpen] = usePaletteOpen();

    const indexRef = useRef(index);
    indexRef.current = index;

    useEffect(() => {
        if (!isOnboarding) {
            return;
        }

        nextFlow();

        if (currentAgendaItem && currentAgendaItem.artifact) {
            //array of the toolType keys
            const toolArtifacts = Object.keys(toolType).filter((item) => urlBasedArtifacts.includes(item as SimpleArtifactsTag) === false);

            if (toolArtifacts.includes(currentAgendaItem.artifact.artifactId)) {
                if (!completedFlows.includes("embed-a-tool")) {
                    completeFlow({
                        flowName: "embed-a-tool",
                    });
                }
            } else if (urlBasedArtifacts.includes(currentAgendaItem.artifact.artifactId)) {
                if (!completedFlows.includes("open-a-link")) {
                    completeFlow({
                        flowName: "open-a-link",
                    });
                }
            }
        }

        const startTimer = setTimeout(() => {
            // for new user, tooltip initializes with index -1
            // tooltip advances when the user starts from OnboardingNewUserDialog
            if (onboardingType !== SessionOnboardingType.NewUser && indexRef.current === -1) {
                next({nextTooltip: 0});
            }
        }, 4000);

        if (flow === "embed-a-tool" && index === 1) {
            next({nextTooltip: 0});
        }

        return () => {
            reset();
            clearTimeout(startTimer);
        };
    }, [isOnboarding, next, reset, nextFlow]);

    useEffect(() => {
        if (onboardingType === SessionOnboardingType.NewUser) {
            setExcludedItems(categMap.filter((obj) => obj.categ === ActionCategory.Agenda).map((obj) => obj.id));
        }
    }, [onboardingType, paletteOpen, setExcludedItems]);

    const skipBeginGuide = isOnboarding && tooltip == "begin-guide" && isWantedAgenda;

    useEffect(() => {
        if (!skipBeginGuide) {
            return;
        }
        next({delay: 1000, ifTooltip: "begin-guide"});
    }, [skipBeginGuide, next]);

    useEffect(() => {
        // resets the timer if the user closes the palette when shown the demo files tooltip
        const timer1 = setTimeout(() => {
            if (onboardingType === SessionOnboardingType.NewUser && !paletteOpen) {
                if (tooltip === "palette-demo-file" && indexRef.current < 2) {
                    next({nextTooltip: 0});
                }
            }
        }, 3000);

        let timer2: NodeJS.Timeout | undefined;

        // advances the flow automatically after the user opens a link
        const timer3 = setTimeout(() => {
            if (onboardingType === SessionOnboardingType.NewUser && flow === "open-a-link" && tooltip === "tool-controls") {
                completeFlow({
                    flowName: "open-a-link",
                });
            }
        }, 7000);

        function resetTimer() {
            if (timer2) {
                clearTimeout(timer2);
            }
            timer2 = setTimeout(checkAndContinueFlow, 7000);
        }

        function checkAndContinueFlow() {
            // advances the flow if the user does nothing when prompted to open share settings

            if (onboardingType === SessionOnboardingType.NewUser && flow === "share-a-file" && tooltip === "share-settings") {
                if (tooltip === "share-settings") {
                    completeFlow({
                        flowName: "share-a-file",
                    });
                }
            }
        }

        function inactivityTime() {
            window.onload = resetTimer;

            //dom events
            document.addEventListener("mousemove", resetTimer, false);
            document.addEventListener("mousedown", resetTimer, false);
            document.addEventListener("keydown", resetTimer, false);
        }

        inactivityTime();

        return () => {
            clearTimeout(timer1);
            if (timer2) {
                clearTimeout(timer2);
            }
            clearTimeout(timer3);

            //dom events
            document.removeEventListener("mousemove", resetTimer, false);
            document.removeEventListener("mousedown", resetTimer, false);
            document.removeEventListener("keydown", resetTimer, false);
        };
    }, [next, paletteOpen, flow, onboardingType, tooltip, completeFlow]);

    return <></>;
}
