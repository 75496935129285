export const beautifyRoleName = (roleName: string) => {
    switch (roleName) {
        case "OWNER":
            return "Admin";
        case "MEMBER":
            return "Basic";
        default:
            return roleName;
    }
};
