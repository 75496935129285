import {cls} from "@ui/cdk/util";
import SvgIcon from "@ui/core/components/SvgIcon";
import {ReactComponent as StarAssistantIcon} from "../../../components/conferenceControls/star-assistant.svg";
import {ReactComponent as StarOwnerIcon} from "../../../components/conferenceControls/star-owner.svg";
import style from "./styles/AudienceParticipant.module.scss";

type AudienceParticipantNameProps = {
    className?: string;
    participantIsOwner: boolean;
    participantDisplayName: string;
    participantIsAssistant: boolean;
    isThumbnailMode?: boolean;
};

export function AudienceParticipantName(props: AudienceParticipantNameProps) {
    const {participantDisplayName, participantIsOwner, participantIsAssistant, isThumbnailMode} = props;

    let Icon;
    if (participantIsOwner) {
        Icon = StarOwnerIcon;
    } else if (participantIsAssistant) {
        Icon = StarAssistantIcon;
    }

    return (
        <>
            <div className={cls(style.participantNameContainer, isThumbnailMode ? style.isThumbnail : "")}>
                {Icon && <SvgIcon component={Icon} className={cls(style.participantNameStar, props.className, "icon")} />}
                <span
                    data-id="participant-name"
                    className={cls(style.participantName, props.className, "name")}
                    title={participantDisplayName}
                >
                    {participantDisplayName}
                </span>
            </div>
        </>
    );
}
