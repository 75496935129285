import {ConferenceParticipantStatus} from "@generated/data";
import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import MenuItem from "@ui/cdk/MenuItem";
import OneTooltip, {OneTooltipRef} from "@ui/cdk/OneTooltip";
import {cls} from "@ui/cdk/util/util";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import {Fragment, useCallback, useRef, useState, useEffect} from "@workhorse/api/rendering";
import {Participant, SessionData} from "@workhorse/declarations/dataTypes";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {BlockedPermissionsPopover} from "../BlockedPermissionsModal";
import {DeviceUnreadablePopover} from "../DeviceUnreadableModal";
import FullScreenToggler from "../conferenceControls/FullScreen/FullScreenToggler";
import LeaveSessionButton from "../conferenceControls/LeaveSessionButton";
import LocalAudioToggler from "../conferenceControls/LocalAudio/LocalAudioToggler";
import LocalVideoToggler from "../conferenceControls/LocalVideo/LocalVideoToggler";
import NotificationsToggler from "../conferenceControls/NotificationsToggler";
import RaiseHandToggler from "../conferenceControls/Reactions/RaiseHandToggler";
import ReactionsToggler from "../conferenceControls/Reactions/ReactionsToggler";
import {RecordingToggler} from "../conferenceControls/Recording";
import ShareScreenToggler from "../conferenceControls/ShareScreen/ShareScreenToggler";
import SwitchCameraButton from "../conferenceControls/SwitchCameraButton";
import classes from "./styles/LocalParticipantControls.module.scss";
import {useFullScreenState} from "@workhorse/providers/state";
import {useTranslation} from "react-i18next";
import {useClientEvent} from "@api/events/client";
import {throttle} from "throttle-debounce";
import {SessionLivestreamOutputUrlListJsonType} from "@sessions/common/recording";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {LivestreamToggler} from "../conferenceControls/Livestream";

type LocalParticipantControlProps = {
    options: {
        allowScreenShare: boolean;
        isConferenceMicro: boolean;
        currentMicroId?: string;
    };
    participant: Participant;
    isAdmin: boolean;
    isAssistant: boolean;
    sessionId: string;
    screenSharePid: string | null | undefined;
    session: SessionData;
    isLobby: boolean;
};

function LocalParticipantControls(props: LocalParticipantControlProps) {
    const {t, i18n} = useTranslation();
    const {isLobby, isAdmin, isAssistant, session} = props;

    const commitInProgress = useDesignerSessionCommitState();

    const {
        ua: {isMobileUA, isTabletUA, isMobileOrTabletUA},
    } = useMobile();
    const isFullScreen = useFullScreenState();

    const [microphoneBlockedElement, setMicrophoneBlockedElement] = useState<HTMLElement | null | undefined>(null);
    const [microphoneUnreadableElement, setMicrophoneUnreadableElement] = useState<HTMLElement | null | undefined>(null);
    const [cameraBlockedElement, setCameraBlockedElement] = useState<HTMLElement | null | undefined>(null);
    const [cameraUnreadableElement, setCameraUnreadableElement] = useState<HTMLElement | null | undefined>(null);

    const [menuOffsetX, setMenuOffsetX] = useState(0);
    const [appFooterRightDivWidth, setAppFooterRightDivWidth] = useState(100);

    const child = session?.childOfBreakoutRooms;
    const isBorChild = !!child;

    const parentSessionId = isBorChild ? child?.parentOfBreakoutRooms?.sessionId : undefined;

    const menuRef = useRef<HTMLDivElement>(null);

    const tooltipConnectorRef: OneTooltipRef = useRef({});

    const showTooltip = (e?: $FixmeAny, message?: string) => {
        if (isMobileOrTabletUA) {
            return;
        }
        if (tooltipConnectorRef.current.setData) {
            tooltipConnectorRef.current.setData({
                anchorEl: e ? e.currentTarget : undefined,
                content: <span className="text">{message}</span>,
            });
        }
    };

    const [menuState, setMenuState] = useState<"small" | "large">();

    const {conferenceStatus, id: myParticipantId, speakerDetails} = props.participant || {};
    const isWelcomeSession = props.session.onboardingType !== null ? true : false;

    const parentParticipantId = isBorChild
        ? child?.participantsMap?.find((r) => r.participantInBreakoutRoom?.id === myParticipantId)?.participantInParent.id
        : undefined;

    const isSpeaker = !!speakerDetails?.id;
    const isSpotlight = conferenceStatus === ConferenceParticipantStatus.Speaker;

    const isRaisingHand = conferenceStatus === ConferenceParticipantStatus.PendingSpeaker;

    const {featureIsAvailable} = useFeatureInfo({
        feature: LimitedFeature.LIVESTREAM,
    });

    const livestreamUrls = (session.livestreamOutputUrls as SessionLivestreamOutputUrlListJsonType)?.urls;
    const livestreamDisabledError = !featureIsAvailable
        ? t("participant.livestreaming.not_available_for_plan")
        : livestreamUrls?.length === 0 || !livestreamUrls
        ? t("participant.livestreaming.no_urls_configured")
        : null;

    const isRoomOrBooking = !!session.room?.id || session.isBooking;

    const updateMenuOffsetX = useCallback(
        (width: number) => {
            if (!isMobileOrTabletUA) {
                const threshold = Math.floor(
                    width / 2 + appFooterRightDivWidth + (appFooterRightDivWidth / width) * appFooterRightDivWidth + 50
                );
                //the more space the app footer right div takes up, the more we need to account for it, that's why
                //appFooterRightDivWidth / width is there.
                //+ 50px to account for margins & padding

                if (width < threshold) {
                    setMenuOffsetX(Math.floor(threshold - width));
                } else {
                    setMenuOffsetX(0);
                }
                //app footer right is about 400px usually. the offset will start at around a width of 1200px (small laptop screens
                //and people who use > 100% zoom on their browser)
            }
        },
        [appFooterRightDivWidth, isMobileOrTabletUA]
    );

    const onResize = useCallback(
        throttle(100, (width: number) => {
            const newMenuState = width - 84 < 600 ? "small" : "large";
            setMenuState(newMenuState);

            updateMenuOffsetX(width);
        }),
        [updateMenuOffsetX, isFullScreen]
    );

    const isLargeMenu = menuState === "large";

    const ConferenceControls = [
        <LocalVideoToggler
            key="local-video-toggler"
            className={cls(classes.controlButton, classes.order0)}
            permissionDeniedText="No video"
            sessionId={props.sessionId}
            onPermissionBlocked={setCameraBlockedElement}
            onDeviceUnreadable={setCameraUnreadableElement}
            showTooltip={showTooltip}
            isLobby={isLobby}
        />,
        <LocalAudioToggler
            key="local-audio-toggler"
            className={cls(classes.controlButton, classes.order1)}
            permissionDeniedText="No audio"
            sessionId={props.sessionId}
            onPermissionBlocked={setMicrophoneBlockedElement}
            onDeviceUnreadable={setMicrophoneUnreadableElement}
            showTooltip={showTooltip}
        />,

        isMobileOrTabletUA && (
            <SwitchCameraButton
                key="switch-camera-button"
                className={cls(classes.controlButton, isMobileUA ? classes.order3 : classes.order3)}
            />
        ),

        isLobby || isWelcomeSession || isMobileOrTabletUA ? undefined : (
            <ShareScreenToggler
                key="screen-share-toggler"
                isAdmin={isAdmin}
                isAssistant={isAssistant}
                isSpeaker={isSpeaker}
                sessionId={props.sessionId}
                className={cls(classes.controlButton, !isMobileOrTabletUA && classes.order4)}
                allowScreenShareType={props.session.allowScreenShareType}
                showTooltip={showTooltip}
                hideText
            />
        ),
    ].filter((c) => !!c);

    const [rootRef, rootNode] = useLocalResizeObserver(onResize);

    const updateAppFooterRightDivWidth = () => {
        setAppFooterRightDivWidth(document.getElementById("app-footer-right")?.offsetWidth ?? 300);
    };

    useClientEvent("macro-loaded", ({id}) => {
        updateAppFooterRightDivWidth();
    });

    useEffect(() => {
        updateAppFooterRightDivWidth();
        if (rootNode) {
            onResize(rootNode.offsetWidth);
        }
    }, [i18n.language, onResize, rootNode]);

    return (
        <div id="local-participant-controls" className={classes.root} ref={rootRef}>
            {/* Only show the menu when we know what size it is (small / large) */}
            {(menuState || isMobileOrTabletUA) && (
                <div className={cls(classes.backdropRoot, isFullScreen && classes.fullscreen)}>
                    <div
                        className={cls(classes.backdropContainer, isFullScreen && classes.fullscreen)}
                        style={{
                            marginRight: menuOffsetX ? `${menuOffsetX}px` : undefined,
                        }}
                    >
                        <div className={classes.content}>
                            <div ref={menuRef}>
                                <ul
                                    data-id="conference-controls"
                                    className={cls(
                                        "flex flex-center-all conference-controls",
                                        classes.listRoot,
                                        isMobileUA && classes.mobile
                                    )}
                                    aria-label={t("player.footer.aria.conference_controls") ?? "Conference controls"}
                                    role="region"
                                >
                                    <OneTooltip connector={tooltipConnectorRef} />
                                    {/* // This is a dummy for the DrawerRightActionButton component. */}
                                    {isMobileUA && <MenuItem button className={cls("hidden", classes.controlButton, classes.order7)} />}
                                    {isLargeMenu || isMobileOrTabletUA ? (
                                        <Fragment key="large-menu-or-mobile-conference-controls">{ConferenceControls}</Fragment>
                                    ) : null}
                                    {!isMobileUA && <div className={cls(classes.separator, classes.order2)} />}
                                    {isTabletUA ? (
                                        <ReactionsToggler className={cls(classes.controlButton, classes.order5)} hideText />
                                    ) : null}
                                    {!isMobileOrTabletUA ? (
                                        <RaiseHandToggler
                                            participantId={props.participant.id}
                                            className={cls(classes.controlButton, classes.order5)}
                                            isSpotlight={isSpotlight}
                                            isRaisingHand={isRaisingHand}
                                            hideText
                                            enableReactionsType={session.enableReactionsType!}
                                            showTooltip={showTooltip}
                                        />
                                    ) : null}

                                    {(isAdmin || isAssistant) && !session.childOfBreakoutRooms?.id && !isMobileUA ? (
                                        <RecordingToggler
                                            className={cls(classes.controlButton, classes.order6)}
                                            sessionId={props.sessionId}
                                            sessionName={props.session.name}
                                            participantId={props.participant.id}
                                            showTooltip={showTooltip}
                                            backstage={props.session.backstage}
                                            hideText
                                            livestreamDisabledError={livestreamDisabledError}
                                            isRoomOrBooking={isRoomOrBooking}
                                        />
                                    ) : null}

                                    {(isAdmin || isAssistant) &&
                                    !session.childOfBreakoutRooms?.id &&
                                    !isMobileUA &&
                                    !livestreamDisabledError ? (
                                        <LivestreamToggler
                                            className={cls(classes.controlButton, classes.order6)}
                                            sessionId={props.sessionId}
                                            sessionName={props.session.name}
                                            participantId={props.participant.id}
                                            showTooltip={showTooltip}
                                            backstage={props.session.backstage}
                                            hideText
                                            livestreamDisabledError={livestreamDisabledError}
                                            isRoomOrBooking={isRoomOrBooking}
                                        />
                                    ) : null}
                                    {/* {!isAdmin && !isAssistant && !isMobileUA && (
                                        <NotificationsToggler
                                            className={cls(classes.controlButton, classes.order6)}
                                            sessionId={props.sessionId}
                                            showTooltip={showTooltip}
                                            hideText
                                            key="local-notifications-toggler"
                                        />
                                    )} */}
                                    {!isMobileUA && (
                                        <FullScreenToggler
                                            className={cls(classes.controlButton, classes.order7)}
                                            key="full-screen-toggler"
                                        />
                                    )}
                                    {!isMobileUA && <div className={cls(classes.separator, classes.order8)} />}
                                    {!isLobby ? (
                                        <LeaveSessionButton
                                            key="leave-session-button"
                                            isAdmin={isAdmin}
                                            isAssistant={isAssistant}
                                            sessionId={props.sessionId}
                                            participantId={props.participant.id}
                                            className={cls(
                                                classes.controlButton,
                                                isMobileUA ? classes.order2 : classes.order9,
                                                classes.leaveSessionButton
                                            )}
                                            disableEnd={commitInProgress}
                                            isWelcomeSession={isWelcomeSession}
                                            isMobile={isMobileOrTabletUA}
                                            open={!open}
                                            showTooltip={showTooltip}
                                            isBorChild={isBorChild}
                                            parentParticipantId={parentParticipantId}
                                            parentSessionId={parentSessionId}
                                            backstage={session.backstage}
                                        />
                                    ) : null}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {cameraBlockedElement && (
                        <BlockedPermissionsPopover
                            anchorEl={cameraBlockedElement}
                            cameraBlocked={true}
                            onClose={() => {
                                setCameraBlockedElement(undefined);
                            }}
                        />
                    )}
                    {cameraUnreadableElement && (
                        <DeviceUnreadablePopover
                            anchorEl={cameraUnreadableElement}
                            cameraUnreadable={true}
                            onClose={() => {
                                setCameraUnreadableElement(undefined);
                            }}
                        />
                    )}
                    {microphoneBlockedElement && (
                        <BlockedPermissionsPopover
                            anchorEl={microphoneBlockedElement}
                            micBlocked={true}
                            onClose={() => {
                                setMicrophoneBlockedElement(undefined);
                            }}
                        />
                    )}
                    {microphoneUnreadableElement && (
                        <DeviceUnreadablePopover
                            anchorEl={microphoneUnreadableElement}
                            micUnreadable={true}
                            onClose={() => {
                                setMicrophoneUnreadableElement(undefined);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default LocalParticipantControls;
