import Button from "@ui/cdk/Button";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {useState} from "@workhorse/api/rendering";
import EventCollaboratorsAccessList from "./event-collaborators-access-list";
import EventCollaboratorsDialog from "./event-collaborators-dialog";
import classes from "./event-collaborators.module.scss";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import {cls} from "@ui/cdk/util";
import {MemberFragment, SessionParticipantFragment} from "@generated/data";

type EventCollaboratorsProps = {
    collaborators: SessionParticipantFragment[];
    setCollaborators: (collaborators: SessionParticipantFragment[]) => void;
    organizationName: string | undefined | null;
    onAddEventCollaborators?: (collaborators: SessionParticipantFragment[]) => void;
    onRemoveEventCollaborator?: (email: string) => void;
};

function EventCollaborators(props: EventCollaboratorsProps) {
    const {collaborators, setCollaborators, organizationName, onAddEventCollaborators, onRemoveEventCollaborator} = props;

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    return (
        <div className={classes.root}>
            <div className="flex flex-row gap-12">
                <Typography variant="sm" fontWeight="bold" color="secondary">
                    Invite event collaborators
                </Typography>

                <Tooltip
                    title={
                        <div className="p-5">
                            <Typography fontWeight="bolder" className="mb-5">
                                Event collaborators
                            </Typography>
                            <Typography variant="sm" className="mb-5">
                                Add workspace members to the event. They&apos;ll be able to make changes to the event&apos;s settings,
                                create resources, and act as co-hosts.
                            </Typography>
                        </div>
                    }
                    placement="right"
                    arrow
                >
                    <HelpOutlineRoundedIcon className={cls(classes.infoIcon, "mb-8")} />
                </Tooltip>
            </div>

            <Button variant="plain" size="smallest" onClick={() => setIsDialogOpen(true)}>
                {collaborators.length ? "Manage" : "Add"} collaborators
            </Button>
            <EventCollaboratorsAccessList collaborators={collaborators} />
            {isDialogOpen && (
                <EventCollaboratorsDialog
                    organizationName={organizationName}
                    collaborators={collaborators}
                    onClose={() => setIsDialogOpen(false)}
                    setCollaborators={setCollaborators}
                    onAddEventCollaborators={onAddEventCollaborators}
                    onRemoveEventCollaborator={onRemoveEventCollaborator}
                />
            )}
        </div>
    );
}

export default EventCollaborators;
