import {SessionUpdateInput, OneMacroArtifactFragment as MacroArtifact} from "../../generated/data-types";
import {Session, ArrayItem, OmitTypenames} from "../types";

type MacroArtifactsInput = OmitTypenames<Session>["macroArtifacts"];
type MacroArtifactsUpdatePayload = NonNullable<SessionUpdateInput["macroArtifacts"]>;
type MacroArtifactInput = ArrayItem<MacroArtifactsInput>;
type MacroArtifactUpdatePayload = NonNullable<ArrayItem<MacroArtifactsUpdatePayload["update"]>>;
type MacroArtifactCreatePayload = NonNullable<ArrayItem<MacroArtifactsUpdatePayload["create"]>>;

export default function buildMacroArtifactsUpdatePayload(input: MacroArtifactsInput): MacroArtifactsUpdatePayload {
    let deletedItems = input.filter((obj) => obj?.id && obj.isDeleted).map((obj) => obj.id);
    const replacedItems = input
        .filter((obj) => obj && !obj.isDeleted && obj.id && obj.oldId && !(obj.id === obj.oldId))
        .map((obj) => obj.oldId!);
    const newOrUpdatedItems = input.filter((obj) => obj?.id && deletedItems.indexOf(obj.id) === -1);
    const newItems = newOrUpdatedItems.filter((obj) => obj?.id && !obj.createdAt).map(buildCreateMacroArtifactPayload);
    const updateItems = newOrUpdatedItems.filter((obj) => obj?.id && !!obj.createdAt).map(buildMacroArtifactUpdatePayload);
    deletedItems = deletedItems.concat(replacedItems);
    return {
        ...(deletedItems.length
            ? {
                  delete: deletedItems.map((id) => ({id})),
              }
            : null),
        ...(newItems.length ? {create: newItems} : null),
        ...(updateItems.length
            ? {
                  update: updateItems,
              }
            : null),
    };
}

const createKeys: Array<Exclude<keyof MacroArtifactCreatePayload, "createdAt" | "updatedAt">> = [
    "endedAt",
    "order",
    "startAt",
    "userConfiguredDuration",
    "artifactId",
    "isSystemArtifact",
    "isConfigured",
    "isMacro",
    "name",
    "properties",
    "description",
    "id",
];

function buildCreateMacroArtifactPayload(artifact: MacroArtifactInput): MacroArtifactCreatePayload {
    const payload: MacroArtifactCreatePayload = {} as MacroArtifactCreatePayload;
    createKeys.forEach((k) => {
        switch (k) {
            case "id": {
                if (k in artifact) {
                    payload[k] = artifact[k];
                }
                break;
            }
            case "description": {
                if (k in artifact) {
                    payload[k] = artifact[k];
                }
                break;
            }
            case "artifactId": {
                if (k in artifact) {
                    payload[k] = artifact[k];
                }
                break;
            }
            case "isConfigured": {
                if (k in artifact) {
                    payload[k] = artifact[k];
                }
                break;
            }
            case "isSystemArtifact": {
                if (k in artifact) {
                    payload[k] = artifact[k] as boolean;
                }
                break;
            }
            case "name": {
                if (k in artifact) {
                    payload[k] = artifact[k];
                }
                break;
            }
            case "properties": {
                if (k in artifact) {
                    payload[k] = buildArtifactUpdatePropertiesPayload(artifact[k]);
                }
                break;
            }
            default:
                break;
        }
    });
    return payload;
}

const updateKeys: Array<Exclude<keyof MacroArtifactUpdatePayload["data"], "id" | "createdAt" | "updatedAt">> = [
    "endedAt",
    "order",
    "startAt",
    "userConfiguredDuration",
    "artifactId",
    "isSystemArtifact",
    "isConfigured",
    "isMacro",
    "name",
    "properties",
    "description",
];

function buildMacroArtifactUpdatePayload(artifact: MacroArtifactInput): MacroArtifactUpdatePayload {
    const payload: MacroArtifactUpdatePayload["data"] = {};
    updateKeys.forEach((k) => {
        switch (k) {
            case "deniedSpeakerUserIds": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k] as string[],
                    };
                }
                break;
            }
            case "description": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "durationInSeconds": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "endedAt": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "isConfigured": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "isSystemArtifact": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k] as boolean,
                    };
                }
                break;
            }
            case "name": {
                if (k in artifact) {
                    payload[k] = {
                        set: artifact[k],
                    };
                }
                break;
            }
            case "properties": {
                if (k in artifact) {
                    payload[k] = buildArtifactUpdatePropertiesPayload(artifact[k]);
                }
                break;
            }
            default: {
                break;
            }
        }
    });
    return {
        where: {
            id: artifact.id,
        },
        data: payload,
    };
}

type ArtifactPropertiesUpdatePayload = NonNullable<MacroArtifactUpdatePayload>["data"]["properties"];

function buildArtifactUpdatePropertiesPayload(input: MacroArtifact["properties"]): ArtifactPropertiesUpdatePayload {
    let deletedItems = input.filter((obj) => obj?.id && obj.isDeleted).map((obj) => obj.id);
    const replacedItems = input
        .filter((obj) => obj && !obj.isDeleted && obj.id && obj.oldId && !(obj.id === obj.oldId))
        .map((obj) => obj.oldId!);
    const newOrUpdatedItems = input.filter((obj) => obj?.id && deletedItems.indexOf(obj.id) === -1);
    const newItems = newOrUpdatedItems.filter((obj) => obj?.id && !obj.createdAt && "key" in obj && "value" in obj);
    const updateItems = newOrUpdatedItems.filter((obj) => obj?.id && !!obj.createdAt && "value" in obj);
    deletedItems = deletedItems.concat(replacedItems);
    return {
        ...(deletedItems.length
            ? {
                  delete: deletedItems.map((id) => ({id})),
              }
            : null),
        ...(newItems.length
            ? {
                  create: newItems.map((p) => {
                      return {
                          id: p.id,
                          key: p.key,
                          value: p.value,
                      };
                  }),
              }
            : null),
        ...(updateItems
            ? {
                  update: updateItems.map((p) => {
                      return {
                          where: {
                              id: p.id,
                          },
                          data: {
                              value: {
                                  set: p.value,
                              },
                          },
                      };
                  }),
              }
            : null),
    };
}
