import {BOOK_EVENT_MAX_GUESTS_COUNT} from "@common/validation/book-event.validation";
import {emailRegex} from "@common/validation/utils";
import {ContactInfoFragment, GetContactsDocument} from "@generated/data";
import Input from "@ui/cdk/Input";
import TagInput from "@ui/cdk/TagInput";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {ScheduleBookingInput} from "@workhorse/api/booking";
import {useLazyQuery} from "@workhorse/api/data";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import ContactFetcherPaper from "@workhorse/pages/designer/CreateSession/ContactFetcherPaper";
import {emailDelimiters} from "@workhorse/util";
import {FormikErrors} from "formik/dist/types";
import {toKeyName} from "is-hotkey";
import {useDebounce} from "use-debounce";
import classes from "../styles/BookEventDetails.module.scss";
import {useTranslation} from "react-i18next";

type BookEventDetailsGuestsProps = {
    isUser: boolean;
    pendingGuestRef: React.MutableRefObject<string>;
    values: ScheduleBookingInput["guests"];
    errors: FormikErrors<ScheduleBookingInput>["guests"];

    exclude: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (newValue: any) => void;
    onBlur: () => void;
    isPreview?: boolean;
    label?: string;
};

type GuestsProps = {
    guests: string[];
    onRemove: (index: number, guest: string) => void;
    onKeyDown: (guest: string, e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur: (guest: string, e: React.FocusEvent<HTMLInputElement>) => void;
};

const Guests = (props: GuestsProps) => {
    const {guests, onRemove, onKeyDown, onBlur} = props;
    return (
        <>
            {guests.map((guest, index) => (
                <TagInput
                    key={`tag-input-${guest}`}
                    defaultValue={guest}
                    delimiters={emailDelimiters}
                    color="blueGray"
                    onBlur={onBlur.bind(this, guest)}
                    onRemove={onRemove.bind(null, index, guest)}
                    onKeyDown={onKeyDown.bind(this, guest)}
                    error={!emailRegex.test(guest)}
                />
            ))}
        </>
    );
};

function BookEventDetailsGuests(props: BookEventDetailsGuestsProps) {
    const {t} = useTranslation();
    const {isUser, pendingGuestRef, values, errors, exclude, onChange, onBlur, label} = props;

    const [newGuest, setNewGuest] = useState("");
    const [suggestions, setSuggestions] = useState<ContactInfoFragment[]>([]);
    const [selectedOption, setSelectedOption] = useState(-1);
    const paperRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [debounceValue] = useDebounce(newGuest, 500);

    const handleGuestsChange = (emails: string[]) => {
        const isAddingHimself = emails.find((email) => email.toLowerCase() === exclude.toLocaleLowerCase());

        if (isAddingHimself) {
            toast(<p>{t("booking.page.cant_add_yourself_as_guest")}</p>, {
                type: "error",
            });
            pendingGuestRef.current = "";

            return;
        }

        onChange(emails.filter((email) => email !== exclude));

        pendingGuestRef.current = "";
    };

    useEffect(() => {
        if (!isUser) {
            return;
        }
        setSearchTerm(debounceValue);
    }, [debounceValue]);

    const [getContactsData, {data: contactsData}] = useLazyQuery(GetContactsDocument, {
        fetchPolicy: "network-only",
    });

    const handleClickOutside = (event) => {
        if (props.isPreview || paperRef.current?.contains(event.target) || inputRef.current?.contains(event.target)) {
            return;
        } else {
            setNewGuest("");
        }
    };

    useEffect(() => {
        if (!isUser) {
            return;
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!isUser) {
            return;
        }
        getContactsData({
            variables: {
                where: {
                    email: {contains: searchTerm},
                },
            },
        });
    }, [isUser]);

    useEffect(() => {
        if (!isUser) {
            return;
        }
        if (searchTerm) {
            if (contactsData?.contacts) {
                setSuggestions(
                    (contactsData?.contacts ?? [])
                        .filter((contact) => !values.find((x) => x === contact?.email))
                        .filter(
                            (contact) =>
                                contact.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                contact.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                contact.email.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                );
            }
        } else {
            setSuggestions([]);
        }
        if (selectedOption === -1 && contactsData?.contacts?.length) {
            setSelectedOption(0);
        } else {
            setSelectedOption(-1);
        }
    }, [contactsData, searchTerm]);

    const handleOnNewGuestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setNewGuest(value);
        pendingGuestRef.current = value;
    };

    const handleOnNewGuestKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (toKeyName("backspace") === toKeyName(e.key)) {
            if (newGuest === "") {
                const newGuests = values.slice(0, -1);
                handleGuestsChange(newGuests);
            }
        } else if (
            (newGuest &&
                toKeyName("space") === toKeyName(e.key) &&
                target.selectionStart === target.selectionEnd &&
                target.selectionStart === newGuest.length) ||
            ((toKeyName("enter") === toKeyName(e.key) || toKeyName("tab") === toKeyName(e.key) || emailDelimiters.test(e.key)) &&
                emailRegex.test(newGuest))
        ) {
            if (!values.includes(newGuest)) {
                const newGuests = [...values, newGuest];
                handleGuestsChange(newGuests);
            }

            setNewGuest("");
            e.preventDefault();
            e.stopPropagation();
        } else if (toKeyName("enter") === toKeyName(e.key) && selectedOption === -1) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleClickOption = (emails: string[]) => {
        const newGuests = [...values, ...emails];
        handleGuestsChange(newGuests);
        setSelectedOption(-1);
        setNewGuest("");
        inputRef.current?.focus();
    };

    const handleKeyDown = useCallback(
        (e) => {
            if (!e.key) {
                return;
            }
            const totalOptionsCount = suggestions.length;
            let current = selectedOption;

            if (toKeyName("arrowup") === toKeyName(e.key)) {
                setSelectedOption((prev) => {
                    if (prev > 0) {
                        current = prev - 1;
                        return current;
                    }
                    current = totalOptionsCount - 1;
                    return current;
                });
            }
            if (toKeyName("arrowdown") === toKeyName(e.key)) {
                setSelectedOption((prev) => {
                    if (prev < totalOptionsCount - 1) {
                        current = prev + 1;
                        return current;
                    }

                    current = 0;
                    return current;
                });
            }

            const element = document.getElementById(`result-paper-${current}`);
            element?.scrollIntoView({behavior: "smooth"});

            if ((toKeyName("enter") === toKeyName(e.key) || toKeyName("tab") === toKeyName(e.key)) && current !== -1) {
                e.preventDefault();
                if (emailRegex.test(suggestions[current].email)) {
                    handleClickOption([suggestions[current].email]);
                }
            }
        },
        [suggestions, selectedOption]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [suggestions, selectedOption]);

    const handleOnPaste = (e: React.ClipboardEvent) => {
        handleGuestsChange([
            ...values,
            ...e.clipboardData
                .getData("text/plain")
                .split(emailDelimiters)
                .filter((p) => !values.find((x) => x === p) && p.trim()),
        ]);

        e.preventDefault();
        e.stopPropagation();
    };

    const handleOnRemoveGuest = (index: number) => {
        const newGuests = [...values];
        newGuests.splice(index, 1);
        handleGuestsChange(newGuests);
    };

    const handleBlurTagInput = (guest: string, e: React.FocusEvent<HTMLInputElement>) => {
        const newList: string[] = [];
        for (let p of values) {
            if (p === guest) {
                newList.push(e.target.value);
            } else {
                newList.push(p);
            }
        }
        handleGuestsChange(newList);
        inputRef.current?.focus();
    };

    const handleKeyDownTagInput = (guest: string, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (toKeyName("tab") === toKeyName(e.key) || toKeyName("enter") === toKeyName(e.key)) {
            const newList: string[] = [];
            for (let p of values) {
                if (p !== guest) {
                    newList.push(p);
                }
            }
            newList.push((e.target as HTMLInputElement).value);
            handleGuestsChange(newList);
            inputRef.current?.focus();
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (exclude !== "") {
            onChange(values.filter((email) => email !== exclude));
        }
    }, [exclude]);

    const hasErrors = typeof errors === "string" ? !!errors : !!errors?.length;

    return (
        <div className={classes.contentField}>
            <div className={classes.contentLabel}>{label ?? t("booking.page.add_guests")}</div>
            <div>
                <Input
                    data-id="guest-email"
                    type="email"
                    inputRef={inputRef}
                    startAdornment={
                        values.length ? (
                            <Guests
                                guests={values}
                                onRemove={handleOnRemoveGuest}
                                onKeyDown={handleKeyDownTagInput}
                                onBlur={handleBlurTagInput}
                            />
                        ) : undefined
                    }
                    className={classes.guests}
                    formControlClassName={cls(
                        classes.formControl,
                        classes.input,
                        classes.formControlGuests,
                        hasErrors ? classes.formControlError : ""
                    )}
                    multiline
                    value={newGuest}
                    onChange={handleOnNewGuestChange}
                    maxRows={2.319}
                    withChips={true}
                    onKeyDown={handleOnNewGuestKeyDown}
                    onPaste={handleOnPaste}
                    onBlur={onBlur}
                    placeholder={t("g.form.field_placeholder.guests") ?? ""}
                />

                {suggestions.length ? (
                    <div className={classes.searchResults} ref={paperRef}>
                        {suggestions.map((contact, index) => (
                            <ContactFetcherPaper
                                key={`suggestion-${contact.id}`}
                                selected={index === selectedOption}
                                contact={contact}
                                handleClearParticipant={setNewGuest.bind(null, "")}
                                handleClickOption={handleClickOption}
                                index={index}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
            {errors ? <div className={classes.errorText}>{typeof errors === "string" ? errors : errors[0]}</div> : null}
        </div>
    );
}

export default BookEventDetailsGuests;
