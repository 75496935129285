import {GetUserCountryCodeDocument, SessionLifecycle} from "@generated/data";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import Button from "@ui/cdk/Button";
import Select from "@ui/cdk/Select";
import Switch from "@ui/cdk/Switch";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Dialog from "@ui/core/components/Dialog";
import designer from "@workhorse/api/designer";
import {useRef, useState, useMemo} from "@workhorse/api/rendering";
import {updateRemoteUser} from "@workhorse/api/user";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import {FormBuilder, FormBuilderState, FormItem, FormRenderer} from "@sessions/forms";
import {ReactComponent as IconRegistrationForm} from "../../../../assets/media/icon-registration-form.svg";
import commonClasses from "../eventCommons.module.scss";
import AddFieldDialog from "./AddFieldDialog";
import EventAfterRegistrationAction from "./EventAfterRegistrationAction";
import RegistrationEmptyState from "./RegistrationEmptyState";
import classes from "./style/event-registration.module.scss";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {useQuery, useReactiveVar} from "@workhorse/api/data";
import EventParticipantsLimit from "./EventParticipantsLimit";
import ConfirmDialog from "./ConfirmDialog";
import {paymentConfigState} from "../event-payments/upsertPaymentConfigs";

type EventRegistrationProps = {
    refetch?: () => Promise<unknown>;
};

export type FormBuilderRef = {
    onClose: () => void;
};

export type RegistrationForm = {
    name?: string;
    description?: string;
    button?: string;
    formItems: FormItem[];
};

export enum EventAccessType {
    FreeAccess = "Free access",
    Private = "Private",
    RegistrationForm = "Registration form",
}

interface EventAccessSelectOption {
    value: EventAccessType;
    text: string;
}

const options = [
    {
        value: EventAccessType.FreeAccess,
        text: EventAccessType.FreeAccess,
    },
    {
        value: EventAccessType.Private,
        text: EventAccessType.Private,
    },
    {
        value: EventAccessType.RegistrationForm,
        text: EventAccessType.RegistrationForm,
    },
];

const makeEventAccessType = (hasRegistration: boolean, isPrivate: boolean) => {
    if (isPrivate) {
        return EventAccessType.Private;
    }
    if (hasRegistration) {
        return EventAccessType.RegistrationForm;
    }
    return EventAccessType.FreeAccess;
};

export function EventRegistration(props: EventRegistrationProps) {
    // const {} = props;

    const isLocked = useDesignerSessionLocked();
    const session = useSession();
    const checkedRequireApproval = session?.event?.requireApproval ?? false;

    const currentEventAccessType = makeEventAccessType(!!session?.event?.hasRegistration, !!session?.isPrivate);

    const locked = (session.lifecycle === SessionLifecycle.Started && !session.backstage) || isLocked;

    const [addFieldOpen, setAddFieldOpen] = useState<boolean>(false);
    const [addFieldMode, setAddFieldMode] = useState<"create" | "create-custom" | "edit">();
    const [formPreviewOpen, setFormPreviewOpen] = useState(false);
    const [confirmType, setConfirmType] = useState<EventAccessType | null>(null);

    const mainContainerRef = useRef<HTMLDivElement>(null);
    const formBuilderRef = useRef<FormBuilderRef>(null);
    const localPaymentsConfig = useReactiveVar(paymentConfigState);

    const user = useUserInfo();
    const userForms = useMemo(
        () => ({
            ...user.forms,
            registrationForm: user.forms?.registrationForm?.filter((item) => item.type !== "Guests"),
        }),
        [user.forms]
    );

    const [lockEvent] = useMutation("LockEventDocument");

    const openFormPreview = () => {
        setFormPreviewOpen(true);
    };

    const closeFormPreview = () => {
        setFormPreviewOpen(false);
    };

    const toggleAddField = (open: boolean) => {
        if (open) {
            openAddField();
        } else {
            closeAddField();
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        closeAddField();

        const val = event.target.value as EventAccessType;
        const hasPaymentConfig = localPaymentsConfig?.some((item) => item.state !== "delete");

        switch (val) {
            case EventAccessType.FreeAccess: {
                if (hasPaymentConfig) {
                    setConfirmType(val);
                } else {
                    designer.api.event.update({
                        hasRegistration: false,
                    });

                    designer.api.session.update({
                        isPrivate: false,
                    });
                }
                break;
            }
            case EventAccessType.Private: {
                if (hasPaymentConfig) {
                    setConfirmType(val);
                } else {
                    designer.api.event.update({
                        hasRegistration: false,
                    });
                    designer.api.session.update({
                        isPrivate: true,
                    });
                }

                break;
            }

            case EventAccessType.RegistrationForm:
                designer.api.event.update({
                    hasRegistration: true,
                });
                designer.api.session.update({
                    isPrivate: false,
                });
                // designer.api.session.update({})
                break;

            default:
                break;
        }

        if (!event.target.checked) {
            designer.api.event.update({
                afterRegistrationOfferId: null,
                afterRegistrationRedirectUrl: null,
            });
        }

        designer.commit();
    };

    const addForm = () => {
        designer.api.event.update({
            hasRegistration: true,
        });

        designer.commit();
    };

    const handleSwitchRequireApproval = (event: React.ChangeEvent<HTMLInputElement>) => {
        designer.api.event.update({
            requireApproval: event.target.checked,
        });

        designer.commit();
    };

    function openAddField() {
        setAddFieldOpen(true);
    }

    function closeAddField() {
        formBuilderRef.current?.onClose();
        setAddFieldOpen(false);
    }

    const onSubmit = (form: FormBuilderState) => {
        const updateForm: RegistrationForm = {
            formItems: form.activeEditableItems ?? [],
            name: form.name ?? "",
            description: form.description ?? "",
            button: form.button ?? "",
        };

        designer.api.event.update({
            registrationForm: updateForm,
        });

        const userGuestsField = user.forms?.registrationForm?.find((item) => item.type === "Guests");

        const registrationForm = form.usableFormItems?.map((item) => item.item)?.concat([userGuestsField]);

        updateRemoteUser({
            forms: {
                registrationForm,
            },
        });

        designer.commit();

        closeAddField();
    };

    const onConfirmChange = async () => {
        if (confirmType) {
            designer.api.event.update({
                hasRegistration: confirmType === EventAccessType.RegistrationForm,
            });
            designer.api.session.update({
                isPrivate: confirmType === EventAccessType.Private,
            });
        }

        if (localPaymentsConfig) {
            const configs = localPaymentsConfig.map((item) => ({state: "delete", value: item.value} as const)) ?? [];
            paymentConfigState(configs);
            props.refetch?.();
            designer.api.event.update({payments: []});
        }

        designer.commit();
        setConfirmType(null);
    };

    const eventForm = session.event?.registrationForm as RegistrationForm;

    const {data} = useQuery(GetUserCountryCodeDocument);
    const userCountryCode = data?.getUserCountryCode ?? undefined;

    return (
        <div className={classes.root}>
            <div className={cls("fullh flex flex-col", classes.mainContainer)} ref={mainContainerRef}>
                <div className="flex flex-justify-between mb-30">
                    <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-2">
                        Registration
                    </Typography>
                    {currentEventAccessType === EventAccessType.RegistrationForm ? (
                        <Button variant="secondary" size="small" onClick={openFormPreview} className={classes.previewButton}>
                            <VisibilityRoundedIcon />
                            Preview form
                        </Button>
                    ) : null}
                </div>

                {currentEventAccessType === EventAccessType.RegistrationForm ? <EventParticipantsLimit /> : null}

                <div className={"fullw flex flex-justify-between"}>
                    <div className=" flex flex-align-center ">
                        <div className={classes.iconForm}>
                            <IconRegistrationForm />
                        </div>
                        <div>
                            <div className="flex flex-align-center">
                                <Typography fontWeight="bold" color="secondary" className="mr-6">
                                    Event access type
                                </Typography>
                                {currentEventAccessType === EventAccessType.RegistrationForm ? (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            "Create a registration form for your event. Guests will be able to fill out this form to register for your event."
                                        }
                                        arrow
                                    >
                                        <InfoIcon className={commonClasses.infoIcon} />
                                    </Tooltip>
                                ) : null}
                            </div>
                            <Typography color="tertiary">Set event access type for your participants</Typography>
                        </div>
                    </div>

                    <Select
                        data-id="registration-settings"
                        className={classes.selectInput}
                        options={options}
                        value={currentEventAccessType}
                        disabled={!!locked}
                        IconComponent={ExpandMoreRoundedIcon}
                        onChange={handleOnChange}
                        MenuProps={{
                            classes: {
                                paper: classes.selectMenuPaper,
                            },
                        }}
                        renderOption={(option: EventAccessSelectOption) => {
                            return (
                                <div className="flex flex-align-center">
                                    <p className="mt-auto mb-auto">{option.text}</p>
                                </div>
                            );
                        }}
                        renderValue={(value: string) => {
                            const item = options.find((item) => item.value === value);

                            if (!item) {
                                return value;
                            }

                            return (
                                <div className="flex">
                                    <p className="mt-auto mb-auto">{item.text}</p>
                                </div>
                            );
                        }}
                    />
                </div>

                {currentEventAccessType === EventAccessType.RegistrationForm ? (
                    <>
                        <EventAfterRegistrationAction />
                        {/* <div className="fullw flex flex-align-center flex-justify-between mt-8">
                        <Typography color="tertiary">Require manual approval for registered guests.</Typography>

                        <Switch disabled={!!locked || !checked} checked={checkedRequireApproval} onChange={handleSwitchRequireApproval} />
                    </div> */}

                        <div className="flex flex-col mt-32">
                            <FormBuilder
                                ref={formBuilderRef}
                                disabled={!!locked}
                                showRegistrationButtonForm={true}
                                userForms={userForms}
                                handleClose={closeAddField}
                                preventDeletionByType={["Email"]}
                                disabledTypes={["Name", "Email"]}
                                preventDuplicateByType={["PhoneNumber"]}
                                formItems={eventForm?.formItems}
                                formDescription={eventForm?.description}
                                formName={eventForm?.name}
                                button={eventForm.button}
                                onSubmit={onSubmit}
                                toggleAddEditDialog={toggleAddField}
                                onChangeMode={setAddFieldMode}
                            />
                        </div>

                        <Dialog open={formPreviewOpen} classes={{paper: classes.previewFormDialogPaper}}>
                            <FormRenderer
                                isMobile={false}
                                open={formPreviewOpen}
                                handleClose={closeFormPreview}
                                handleSubmit={(form: any) => {}}
                                form={{
                                    items: eventForm?.formItems ?? [],
                                    name: eventForm?.name ?? "",
                                    description: eventForm?.description ?? "",
                                }}
                                submitText={eventForm.button}
                                classes={{
                                    resend: classes.resendButton,
                                    submit: classes.footerButton,
                                }}
                                isPreview={false}
                                userCountryCode={userCountryCode}
                            />
                        </Dialog>
                    </>
                ) : (
                    <RegistrationEmptyState
                        slug={session.event?.slug}
                        eventAccessType={currentEventAccessType}
                        addForm={addForm}
                        isEventLocked={!!locked}
                        id={session.id}
                    />
                )}
            </div>

            {addFieldOpen && <AddFieldDialog onClose={closeAddField} mode={addFieldMode} />}
            {confirmType && <ConfirmDialog type={confirmType} onClose={() => setConfirmType(null)} onConfirm={onConfirmChange} />}
        </div>
    );
}
