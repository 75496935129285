import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {OrganizationPlan, PaddlePlansDocument} from "@generated/data";
import {getPlanTitle} from "@workhorse/pages/user/profile/UsageEvaluation/components/SubscriptionInfo";
import classes from "./OrderSummary.module.scss";
import {useEffect, useState} from "react";
import {useClientEvent} from "@api/events/client";
import {useQuery} from "@apollo/client";
import Loading from "@workhorse/components/Loading";

export interface OrderPrice {
    gross: number;
    gross_after_discount: number;
    gross_discount: number;
    net: number;
    tax: number;
    tax_percentage: number;
    net_discount: number;
    net_after_discount: number;
    tax_after_discount: number;
}

interface OrderSummaryProps {
    skipTrialDiscount?: boolean;
    referralDiscount?: boolean;
    plan: OrganizationPlan;
    isYearly: boolean;
    monthlyPrice?: number;
    yearlyPrice?: number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({plan, referralDiscount, skipTrialDiscount, isYearly, monthlyPrice, yearlyPrice}) => {
    const [orderPrice, setOrderPrice] = useState<OrderPrice | null>(null);

    const [loading, setLoading] = useState(true);

    useClientEvent("order-price-update", (price: OrderPrice) => {
        setOrderPrice(price);
    });

    useClientEvent("checkout-loaded", () => {
        setLoading(false);
    });

    useClientEvent("paddle-set-loading", () => {
        setLoading(true);
    });

    if (!orderPrice) return null;

    let netPrice = orderPrice.net;

    let pricePerMonth = (isYearly ? yearlyPrice : monthlyPrice) ?? netPrice;

    let vatPercentage = orderPrice.tax_percentage;

    let vat = orderPrice.tax_after_discount;

    let discount = orderPrice.net_discount;

    let strickenPrice = isYearly && monthlyPrice ? monthlyPrice * 12 : netPrice;

    // if (skipTrialDiscount) {
    //     pricePerMonth += pricePerMonth * 0.2;
    //     vat += vat * 0.4;
    //     discount += discount * 0.4;
    //     strickenPrice += strickenPrice * 0.4;
    // }

    const total = orderPrice.gross_after_discount.toFixed(2);

    return (
        <div className={classes.root}>
            <Typography variant="lg" fontWeight="bold">
                Order summary
            </Typography>
            <Typography variant="sm">{getPlanTitle(plan)} subscription</Typography>

            <ul className={classes.orderSummaryContainer}>
                {loading ? (
                    <div className={classes.orderItemSkeleton}></div>
                ) : (
                    <li>
                        <Typography color="nonary">
                            ${pricePerMonth} x {isYearly ? "12 months" : "1 month"}
                        </Typography>
                        <Typography fontWeight="bold">${isYearly ? (pricePerMonth * 12).toFixed(2) : pricePerMonth.toFixed(2)}</Typography>
                    </li>
                )}

                {discount ? (
                    <>
                        {loading ? (
                            <div className={classes.orderItemSkeleton}></div>
                        ) : (
                            <li>
                                <Typography fontWeight="boldest" color="nonary">
                                    Discount
                                </Typography>
                                <Typography fontWeight="boldest">-${discount.toFixed(2)}</Typography>
                            </li>
                        )}
                        {loading ? (
                            <div className={classes.orderItemSkeleton}></div>
                        ) : (
                            <li>
                                <Typography fontWeight="bold" color="nonary">
                                    Total before VAT
                                </Typography>
                                <Typography fontWeight="bold">${orderPrice.net_after_discount.toFixed(2)}</Typography>
                            </li>
                        )}
                    </>
                ) : null}

                {loading ? (
                    <div className={classes.orderItemSkeleton}></div>
                ) : (
                    <li>
                        <Typography color="nonary">VAT ({(vatPercentage * 100).toFixed(0)}%)</Typography>
                        <Typography fontWeight="bold">${vat}</Typography>
                    </li>
                )}
            </ul>
            <div className={classes.horizontalDivider}></div>
            <div className={classes.total}>
                <div className="flex fullw flex-justify-between">
                    <Typography color="nonary">Total due today</Typography>
                </div>

                {loading ? (
                    <div className={classes.totalSkeleton}></div>
                ) : (
                    <div className="flex fullw flex-justify-between">
                        <div></div>
                        <Typography variant="xl5" color="secondary" fontWeight="bold">
                            ${total}
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderSummary;
