import {FileRef} from "@api/resx/ref";
import {FileMimeType} from "@sessions/common/various";
import {getFileHeader, headerToMimeType} from "@workhorse/api/file_explorer/fileExplorer";
import React from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {ErrorType, uploadOneResource} from "../../../../api";
import {ProcessingResx} from "@workhorse/pages/resources/components/ResxFileProgressPreview";
import {ResourcesTypes} from "@generated/artifacts/resources";

type ObjectFileType = {type: FileMimeType; multiple: boolean};

export type AcceptedTypes = Array<FileMimeType | ObjectFileType>;

type UploadInputHiddenProps = {
    multipleUpload?: boolean;
    acceptedTypes: AcceptedTypes;
    acceptedTypesText?: string;
    dropDescriptionText?: string;
    onChangeProcessingResx?: (values?: ProcessingResx, shouldRedirect?: boolean) => void;
    createResource?: (uploadedFile: FileRef) => Promise<void>;
    createResources?: (uploadedFiles: FileRef[]) => Promise<void>;
    onClose?: () => void;
    showHeader?: boolean;
    type: ResourcesTypes;
};

interface CheckedFile extends File {
    isAccepted?: boolean;
}

const UploadInputHidden = (props: UploadInputHiddenProps) => {
    const {
        multipleUpload,
        acceptedTypes,
        createResource,
        createResources,
        onClose,
        onChangeProcessingResx,
        showHeader = true,
        type,
    } = props;

    const restrictToMimeTypes: string[] = [].concat.apply(
        [],
        acceptedTypes.map((x) => (typeof x === "string" ? x : x.type)).map((x) => headerToMimeType[x])
    );

    async function fileCanBeAcepted(file: File) {
        const fileType = (await getFileHeader(file)) as FileMimeType;
        if (acceptedTypes.indexOf(fileType) === -1) {
            return false;
        }
        return true;
    }

    async function handleUploadSelection(event: React.ChangeEvent<HTMLInputElement>) {
        const files = Array.from(event.target.files ?? []);
        event.currentTarget.value = "";
        await handleUploadResources(files);
    }

    function onFailure(fileName: string, errorType: ErrorType) {
        toast(`File ${fileName} could not be uploaded. ${errorType}`, {
            title: "Upload failed",
            type: "error",
        });
    }

    async function handleUploadResources(files: File[]) {
        const checkedFiles = (await Promise.all(
            files.map(async (file) => {
                const isAccepted = await fileCanBeAcepted(file);
                if (!isAccepted) {
                    toast(`File ${file.name} couldn't be uploaded because type is not supported`, {
                        type: "error",
                        duration: 4000,
                    });
                }
                (file as CheckedFile).isAccepted = isAccepted;
                return file;
            })
        )) as CheckedFile[];

        const okFilesToBeUploaded = (checkedFiles as CheckedFile[]).filter((f) => {
            if (f.isAccepted) {
                delete f.isAccepted;
                return true;
            }
            return false;
        });

        if (okFilesToBeUploaded.length === 0) {
            if (onClose) {
                onChangeProcessingResx?.(undefined);
                onClose();
                return;
            }
        }

        onChangeProcessingResx?.(
            {
                id: "",
                type: type,
                isUploading: true,
            },
            true
        );

        const fileRefs = await Promise.all(
            okFilesToBeUploaded.map((file) => {
                return uploadOneResource(file, onFailure);
            })
        );

        if (fileRefs.length > 0 && (createResources || createResource)) {
            if (createResources) {
                await createResources(fileRefs);
            } else if (createResource) {
                await Promise.all(
                    fileRefs.map((fileRef) => {
                        return createResource(fileRef);
                    })
                );
            }
        }
    }

    return (
        <input
            id="resources-upload-input-hidden"
            multiple={multipleUpload}
            type="file"
            onChange={handleUploadSelection}
            style={{display: "none"}}
            {...(restrictToMimeTypes.length > 0 ? {accept: restrictToMimeTypes.join(",")} : null)}
            key="input"
        />
    );
};

export default UploadInputHidden;
