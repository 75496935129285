import {
    ContactGroupInfoFragment,
    ContactInfoFragment,
    GetContactsAndGroupsDocument,
    GetSpeakersHistoryDocument,
    SearchParticipantsByEmailsDocument,
    SessionParticipantFragment,
    SpeakerDetailsFragment,
} from "@generated/data";
import {InputProps} from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import {useQuery} from "@workhorse/api/data";
import {FuseOptions, useFuseWorker} from "@workhorse/api/fuse";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {FocusEvent, KeyboardEvent} from "react";
import {isValidGroup} from "@workhorse/pages/contacts/utils/api";
import {ContactFetcherContact} from "./ContactFetcherContact";
import {ContactFetcherGroup} from "./ContactFetcherGroup";
import {ContactFetcherInput} from "./ContactFetcherInput";
import {ContactFetcherParticipant} from "./ContactFetcherParticipant";
import {ContactFetcherSpeaker} from "./ContactFetcherSpeaker";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import classes from "./styles/ContactFetcher.module.scss";
import {isBinGroup} from "@workhorse/pages/contacts/utils/contact";
import Fuse from "fuse.js";
import apollo from "@workhorse/api/apollo";
import {emailRegex} from "@common/validation/utils";
import InviteMemberConfirmDialog from "./InviteMemberConfirmDialog";
import {isContactFromWorkspace} from "@workhorse/pages/contacts/utils/contact";
import designer from "@workhorse/api/designer";
import {useDesignerSessionCommitState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";

export const maxParticipantsNumber = 250;

export type TagType = "contact" | "group" | "participant" | "email" | "speaker";

export interface Tag {
    type: TagType;
    value: string;
}

function createMap<T extends {id: string}>(items: T[]) {
    const map: Record<string, T> = {};
    for (const item of items) {
        map[item.id] = item;
    }
    return map;
}

function createGroupContactsMap(contacts: ContactInfoFragment[]) {
    const map: Record<string, ContactInfoFragment[] | undefined> = {};
    for (const contact of contacts) {
        for (const group of contact.group) {
            if (map[group.id] == null) {
                map[group.id] = [];
            }
            // @ts-expect-error dumb ts version
            map[group.id].push(contact);
        }
    }
    return map;
}

export function getPendingContactsAndEmails(
    pending: Tag[],
    contactMap: Record<string, ContactInfoFragment>,
    groupContactsMap: Record<string, ContactInfoFragment[] | undefined>
) {
    const contacts: ContactInfoFragment[] = [];
    const emails: string[] = [];

    for (const tag of pending) {
        if (tag.type === "contact" && contactMap[tag.value]) {
            contacts.push(contactMap[tag.value]);
        }
        if (tag.type === "group") {
            const groupContacts = groupContactsMap[tag.value] ?? [];
            contacts.push(...groupContacts);
        }
        if (tag.type === "email") {
            emails.push(tag.value);
        }
        if (tag.type === "participant") {
            emails.push(tag.value);
        }
    }

    return {contacts, emails};
}

export function getPendingSpeakers(pending: Tag[], speakersMap: Record<string, SpeakerDetailsFragment>) {
    const speakers: SpeakerDetailsFragment[] = [];

    for (const tag of pending) {
        if (tag.type === "speaker" && speakersMap[tag.value]) {
            speakers.push(speakersMap[tag.value]);
        }
    }

    return speakers;
}

export function getPendingContactsAndEmailsByCache(pending: Tag[], includeSelf?: boolean) {
    const data = apollo.cache.readQuery({
        query: GetContactsAndGroupsDocument,
        variables: {
            includeSelf: includeSelf,
        },
    });
    const contacts = data?.contacts ?? [];
    const contactsMap = createMap(contacts);
    const groupContactsMap = createGroupContactsMap(contacts);
    return getPendingContactsAndEmails(pending, contactsMap, groupContactsMap);
}

export function getPendingSpeakersByCache(pending: Tag[]) {
    const data = apollo.cache.readQuery({
        query: GetSpeakersHistoryDocument,
    });

    const speakersMap = createMap(data?.getSpeakersHistory ?? []);
    return getPendingSpeakers(pending, speakersMap as Record<string, SpeakerDetailsFragment>);
}

export function getPendingEmailsByCache(pending: Tag[], includeSelf?: boolean) {
    const {emails, contacts} = getPendingContactsAndEmailsByCache(pending, includeSelf);
    const contactsEmails = contacts.map((contact) => contact.email);
    return [...emails, ...contactsEmails];
}

export function useContactFetcher(initial?: (() => Tag[]) | Tag[]) {
    const [pending, setPending] = useState<Tag[]>(initial ?? []);

    const pendingMap = useMemo(() => {
        const map = new Map<string, string | undefined>();
        for (const item of pending) {
            const key = item.type + "$" + item.value;
            map[key] = true;
        }
        return map;
    }, [pending]);

    const addPendingItem = useCallback((type: TagType, value: string) => {
        setPending((current) => {
            const index = current.findIndex((item) => item.type === type && item.value === value);
            if (
                current.length >= 250 ||
                (designer.participantsPayload?.upsertParticipantJSONs && designer.participantsPayload?.upsertParticipantJSONs.length >= 250)
            ) {
                return current;
            }
            if (index >= 0) {
                return current;
            }
            const item = {
                type,
                value,
            };
            return [...current, item];
        });
    }, []);

    const removePendingItem = useCallback((type: TagType, value: string) => {
        setPending((current) => {
            const index = current.findIndex((item) => item.type === type && item.value === value);
            if (index < 0) {
                return current;
            }
            const copy = [...current];
            copy.splice(index, 1);
            return copy;
        });
    }, []);

    const updatePendingItem = useCallback((type: TagType, value: string, newValue: string) => {
        setPending((current) => {
            const index = current.findIndex((item) => item.type === type && item.value === value);
            if (index < 0) {
                return current;
            }
            const copy = [...current];
            copy[index] = {
                type,
                value: newValue,
            };
            return copy;
        });
    }, []);

    const itemIsPending = useCallback(
        (type: TagType, value: string) => {
            const key = type + "$" + value;
            return pendingMap[key] === true;
        },
        [pendingMap]
    );

    const pendingPop = useCallback(() => {
        setPending((current) => {
            return current.slice(0, current.length - 1);
        });
    }, []);

    return {
        pending,
        addPendingItem,
        updatePendingItem,
        removePendingItem,
        itemIsPending,
        pendingPop,
        setPending,
    };
}

function useSelection() {
    const [selected, setSelected] = useState(-1);

    const selectDecrease = useCallback(() => {
        setSelected((current) => {
            if (current <= -1) {
                return -1;
            }
            return current - 1;
        });
    }, []);

    const selectIncrease = useCallback((max: number) => {
        setSelected((current) => {
            if (current >= max - 1) {
                return max - 1;
            }
            return current + 1;
        });
    }, []);

    const selectFix = useCallback((max: number) => {
        setSelected((current) => {
            if (current <= -1) {
                return -1;
            }
            if (current >= max - 1) {
                return max - 1;
            }
            return current;
        });
    }, []);

    const selectReset = useCallback(() => {
        setSelected(-1);
    }, []);

    return {
        selected,
        selectDecrease,
        selectIncrease,
        selectFix,
        selectReset,
    };
}

interface DataOptions {
    skipContacts?: boolean;
    skipGroups?: boolean;
    skipEmails?: boolean;
    skipParticipants?: boolean;
    skipSpeakers?: boolean;
    filterContacts?: (contact: ContactInfoFragment) => boolean;
    filterSpeakers?: (speaker: SpeakerDetailsFragment) => boolean;
    filterParticipants?: (participant: SessionParticipantFragment) => boolean;
    includeSelf?: boolean;
}

const contactOptions: FuseOptions<ContactInfoFragment> = {
    keys: ["email", "firstName", "lastName"],
    threshold: 0.3,
    shouldSort: true,
    includeScore: true,
};

const groupOptions: FuseOptions<ContactGroupInfoFragment> = {
    keys: ["name"],
    threshold: 0.3,
    shouldSort: true,
    includeScore: true,
};

const speakerOptions: FuseOptions<SpeakerDetailsFragment> = {
    keys: [["email"], ["name"]],
    threshold: 0.3,
    shouldSort: true,
    includeScore: true,
};

function getParticipantValue(participant: SessionParticipantFragment) {
    return participant.dataWithNullableEmail?.email ?? participant.id;
}

function getSpeakerValue(speaker: SessionParticipantFragment) {
    return speaker.id;
}

function getUniqueContactsByEmail(contacts: ContactInfoFragment[]) {
    return Object.values(
        contacts.reduce((agg, contact) => {
            const email = contact.email.toLowerCase();
            if (agg[email]) {
                if (isContactFromWorkspace(contact)) {
                    agg[email] = contact;
                }
            } else {
                agg[email] = contact;
            }

            return agg;
        }, {} as Record<string, ContactInfoFragment>)
    );
}

type AllItems =
    | {
          type: "contact";
          value: Fuse.FuseResult<ContactInfoFragment>;
      }
    | {
          type: "group";
          value: Fuse.FuseResult<ContactGroupInfoFragment>;
      }
    | {
          type: "participant";
          value: SessionParticipantFragment;
      }
    | {
          type: "speaker";
          value: Fuse.FuseResult<SpeakerDetailsFragment>;
      };

function useData(search: string, isPending: (type: TagType, value: string) => boolean, options: DataOptions) {
    const {data: contactsAndGroups, loading: contactsLoading} = useQuery(GetContactsAndGroupsDocument, {
        skip: options.skipContacts && options.skipGroups,
        variables: {
            includeSelf: options.includeSelf,
        },
    });

    // Feature is disabled. No one knows the purpose of this feature so we leave the code for now in case it was something important
    // const {data: participants, loading: participantsLoading} = useQuery(SearchParticipantsByEmailsDocument, {
    //     fetchPolicy: "cache-first",
    //     skip: options.skipParticipants || !!search,
    //     variables: {
    //         term: search,
    //     },
    // });

    const {data: speakers, loading: speakersLoading} = useQuery(GetSpeakersHistoryDocument, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        skip: options.skipSpeakers ?? true,
    });

    const dataContacts = useMemo(() => {
        if (contactsAndGroups?.contacts == null || options.skipContacts === true) {
            return emptyArray;
        }
        return getUniqueContactsByEmail(contactsAndGroups.contacts.filter((contact) => !contact.group.some(isBinGroup)));
    }, [contactsAndGroups?.contacts, options.skipContacts]);

    const dataGroups = useMemo(() => {
        if (contactsAndGroups?.contactGroups == null || options.skipGroups === true) {
            return emptyArray;
        }
        return contactsAndGroups.contactGroups.filter((group) => isValidGroup(group, []) && !isBinGroup(group));
    }, [contactsAndGroups?.contactGroups, options.skipGroups]);

    // const dataParticipants = useMemo(() => {
    //     if (participants?.searchParticipantsByEmails == null || options.skipParticipants === true) {
    //         return emptyArray;
    //     }
    //     return participants.searchParticipantsByEmails.map((item) => item.data);
    // }, [participants?.searchParticipantsByEmails, options.skipParticipants]);

    const dataSpeakers = useMemo(() => {
        if (speakers?.getSpeakersHistory == null || options.skipSpeakers === true) {
            return emptyArray;
        }
        return speakers.getSpeakersHistory;
    }, [speakers?.getSpeakersHistory, options.skipSpeakers]);

    const contactMap = useMemo(() => {
        return createMap(dataContacts);
    }, [dataContacts]);

    const groupMap = useMemo(() => {
        return createMap(dataGroups);
    }, [dataGroups]);

    const speakerMap = useMemo(() => {
        return createMap(dataSpeakers) as Record<string, SpeakerDetailsFragment>;
    }, [dataSpeakers]);

    const groupContactsMap = useMemo(() => {
        return createGroupContactsMap(dataContacts);
    }, [dataContacts]);

    const filterContacts = options.filterContacts;
    const filteredContacts = useMemo(() => {
        return dataContacts.filter((contact) => {
            if (filterContacts?.(contact) === false) {
                return false;
            }

            if (isPending("contact", contact.id)) {
                return false;
            }
            if (contact.userSuspended) {
                return false;
            }

            if (options.skipSpeakers === false && dataSpeakers.map((s) => s.email).includes(contact.email)) {
                return false;
            }

            return true;
        });
    }, [dataContacts, dataSpeakers, filterContacts, isPending, options.skipSpeakers]);

    const filteredGroups = useMemo(() => {
        return dataGroups.filter((group) => isPending("group", group.id) === false);
    }, [dataGroups, isPending]);

    // const filterParticipants = options.filterParticipants;
    // const filteredParticipants = useMemo(() => {
    //     return dataParticipants.filter((participant) => {
    //         if (filterParticipants?.(participant) === false) {
    //             return false;
    //         }

    //         if (isPending("participant", getParticipantValue(participant))) {
    //             return false;
    //         }

    //         return true;
    //     });
    // }, [dataParticipants, filterParticipants, isPending]);

    const filterSpeakers = options.filterSpeakers;
    const filteredSpeakers = useMemo(() => {
        return dataSpeakers.filter((speaker) => {
            if (filterSpeakers?.(speaker) === false) {
                return false;
            }

            // TODO: speakershistory maca check this
            // if (isPending("speaker", speaker.participantId)) {
            //     return false;
            // }

            return true;
        });
    }, [dataSpeakers, filterSpeakers, isPending]);

    const contactSearch = options.skipContacts === true ? "" : search;
    const [searchedContacts, contactsPending, fuseContacts] = useFuseWorker(filteredContacts, contactSearch, contactOptions);

    const groupSearch = options.skipGroups === false ? "" : search;
    const [searchedGroups, groupsPending, fuseGroups] = useFuseWorker(filteredGroups, groupSearch, groupOptions);

    const speakerSearch = options.skipSpeakers === true ? "" : search;
    const [searchedSpeakers, speakersPending, fuseSpeakers] = useFuseWorker(filteredSpeakers, speakerSearch, speakerOptions);

    const items = useMemo(() => {
        const fuseItems = [
            ...fuseSpeakers.map((speaker) => ({type: "speaker" as const, value: speaker})),
            ...fuseContacts.map((contact) => ({type: "contact" as const, value: contact})),
            ...fuseGroups.map((group) => ({type: "group" as const, value: group})),
        ];
        fuseItems.sort((a, b) => (a.value.score ?? 0) - (b.value.score ?? 0));
        const items: AllItems[] = [
            ...fuseItems,
            // ...filteredParticipants.map((participant) => ({type: "participant" as const, value: participant})),
        ];
        return items.slice(0, 30);
    }, [
        fuseContacts,
        fuseGroups,
        // filteredParticipants,
        fuseSpeakers,
    ]);

    return {
        items,
        contacts: contactSearch ? searchedContacts : emptyArray,
        groups: groupSearch ? searchedGroups : emptyArray,
        // participants: search ? filteredParticipants : emptyArray,
        participants: [],
        speakers: speakerSearch ? searchedSpeakers : emptyArray,
        contactMap,
        speakerMap,
        groupMap,
        groupContactsMap,
        loading: contactsLoading || contactsPending || groupsPending || speakersLoading || speakersPending,
    };
}

export type ContactFetcherApi = ReturnType<typeof useContactFetcher>;

interface ContactFetcherProps {
    api?: ContactFetcherApi;
    className?: string;
    isSessionDialog?: boolean;
    label?: InputProps["label"];
    placeholder?: string;
    skipContacts?: boolean;
    skipGroups?: boolean;
    skipEmails?: boolean;
    skipParticipants?: boolean;
    skipSpeakers?: boolean;
    guestInviteText?: string;
    filterContacts?: (contact: ContactInfoFragment) => boolean;
    filterSpeakers?: (speaker: SpeakerDetailsFragment) => boolean;
    filterParticipants?: (participant: SessionParticipantFragment) => boolean;
    onSubmit?: (contacts: ContactInfoFragment[], emails: string[], speakers: SpeakerDetailsFragment[]) => void;
    hideIcon?: boolean;
    disableSubmit?: boolean;
    disableShowResults?: boolean;
    classes?: {
        root?: string;
    };
    tagIcon?: JSX.Element;
    clearOnSubmit?: boolean;
    doNotAddOnBlur?: boolean;
    doNotAddOnPaste?: boolean;
    disabled?: boolean;
    includeSelf?: boolean;
    autoFocusFirstResult?: boolean;
    inviteToWorkspace?: boolean;
    inviteModalContent?: {
        title: string;
        body: string;
    };
    onReturn?: (value: string) => void;
    onFocus?: () => void;
    roleId?: string;
    isFetchingUserNames?: boolean;
}

const emptyArray = [];

const ContactFetcher = (props: ContactFetcherProps) => {
    const {isSessionDialog = false, clearOnSubmit = true, disabled = false, onReturn, disableSubmit, disableShowResults, onFocus} = props;
    const isCommiting = useDesignerSessionCommitState();

    const [search, setSearch] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [inviteMemberDialog, setInviteMemberDialog] = useState(false);
    const [resultNode, setResultNode] = useState<HTMLElement | null>(null);
    const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false);
    const [hasExactMatch, setHasExactMatch] = useState(false);

    const nativeApi = useContactFetcher();
    const {selected, selectIncrease, selectDecrease, selectReset} = useSelection();
    const {pending, itemIsPending, addPendingItem, removePendingItem, updatePendingItem, pendingPop, setPending} = props.api ?? nativeApi;

    const {items, contactMap, speakerMap, groupMap, groupContactsMap} = useData(search, itemIsPending, {
        skipContacts: props.skipContacts,
        skipGroups: props.skipGroups,
        skipParticipants: props.skipEmails,
        skipSpeakers: props.skipSpeakers,
        filterContacts: props.filterContacts,
        filterSpeakers: props.filterSpeakers,
        filterParticipants: props.filterParticipants,
        includeSelf: props.includeSelf,
    });

    const totalItems = items.length;

    const inputSetValueRef = useRef<(value: string) => void>();

    const selectionMap = useRef<Record<number, Tag>>({});
    selectionMap.current = {};

    const skipBlurRef = useRef(false);

    const pendingRef = useRef(pending);
    pendingRef.current = pending;

    const contactMapRef = useRef(contactMap);
    contactMapRef.current = contactMap;

    const speakerMapRef = useRef(speakerMap);
    speakerMapRef.current = speakerMap;

    const groupContactsRef = useRef(groupContactsMap);
    groupContactsRef.current = groupContactsMap;

    const onSubmitRef = useRef(props.onSubmit);
    onSubmitRef.current = props.onSubmit;

    const inviteMemberEmailRef = useRef<null | string>(null);

    const findContact = useCallback((value: string) => {
        const email = value.toLowerCase().trim();
        return Object.values(contactMapRef.current).find((contact) => contact.email === email);
    }, []);

    const addEmail = useCallback(
        (email: string) => {
            const contact = findContact(email);
            if (contact) {
                addPendingItem("contact", contact.id);
            } else {
                addPendingItem("email", email.trim());
            }
        },
        [findContact, addPendingItem]
    );

    const handleSearch = useCallback(
        (value: string) => {
            setSearch(value);
            selectReset();
        },
        [selectReset]
    );

    const resetInput = useCallback(() => {
        inputSetValueRef.current?.("");
        skipBlurRef.current = false;
        setSearch("");
        if (!clearOnSubmit) {
            return;
        }
        selectReset();
    }, [selectReset, clearOnSubmit]);

    const handleSubmit = useCallback(() => {
        const {contacts, emails} = getPendingContactsAndEmails(pendingRef.current, contactMapRef.current, groupContactsRef.current);
        const speakers = getPendingSpeakers(pendingRef.current, speakerMapRef.current as Record<string, SpeakerDetailsFragment>);
        const invalidEmails = emails.filter((email) => !emailRegex.test(email));
        onSubmitRef.current?.(
            contacts,
            emails.filter((email) => !invalidEmails.includes(email)),
            speakers
        );
        return {invalidEmails};
    }, []);

    const handleAdd = useCallback(
        (value: string) => {
            if (skipBlurRef.current) {
                skipBlurRef.current = false;
                return;
            }
            const selectedItem = selectionMap.current[selected];

            if (selectedItem) {
                if (selectedItem.type === "contact" && props.inviteToWorkspace) {
                    const contact = contactMap[selectedItem.value];

                    if (!isContactFromWorkspace(contact)) {
                        onInviteMember(contact.email);
                    } else {
                        addPendingItem(selectedItem.type, selectedItem.value);
                    }
                } else {
                    addPendingItem(selectedItem.type, selectedItem.value);
                }
                resetInput();
                return;
            }
            if (props.skipEmails === true) {
                return;
            }

            const trimmed = value.trim();
            if (!trimmed) {
                return;
            }

            if (!emailRegex.test(trimmed)) {
                // return resetInput();
                setHasInvalidEmails(true);
            } else if (emailRegex.test(trimmed)) {
                setHasInvalidEmails(false);
            }

            if (props.inviteToWorkspace) {
                onInviteMember(value);
            } else {
                addEmail(trimmed);
            }

            if (clearOnSubmit) {
                resetInput();
            }
        },
        [addPendingItem, addEmail, resetInput, props.skipEmails, props.inviteToWorkspace, selected, clearOnSubmit]
    );

    const handleArrowUp = useCallback(() => {
        selectDecrease();
    }, [selectDecrease]);

    const handleArrowDown = useCallback(() => {
        selectIncrease(totalItems);
    }, [selectIncrease, totalItems]);

    const handleBackspace = useCallback(
        (value: string) => {
            if (value) {
                return;
            }
            if (clearOnSubmit) {
                pendingPop();
            } else {
                const last = pendingRef.current[pendingRef.current.length - 1];
                if (last) {
                    removePendingItem(last.type, last.value);
                }
            }
        },
        [pendingPop, clearOnSubmit, removePendingItem]
    );

    const handleSpace = useCallback(
        (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.currentTarget.value;
            const trimmed = value.trim();

            if (!emailRegex.test(trimmed)) {
                setHasInvalidEmails(true);
                return;
            }

            e.preventDefault();
            addEmail(trimmed);
            resetInput();
        },
        [addEmail, resetInput]
    );

    const handleFocus = useCallback(() => {
        setShowResult(true);
        onFocus?.();
    }, []);

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setShowResult(false);
            if (skipBlurRef.current) {
                skipBlurRef.current = false;
                return;
            }

            handleAdd(e.target.value);
        },
        [handleAdd]
    );

    const handleReturn = useCallback(
        (value: string) => {
            resetInput();
            if (onReturn) {
                onReturn(value);
            }

            if (!value) {
                const {invalidEmails} = handleSubmit();
                if (!clearOnSubmit || disableSubmit) {
                    return;
                }

                setPending(invalidEmails.map((email) => ({type: "email", value: email})));
                setHasInvalidEmails(invalidEmails.length > 0);
                return;
            }

            return handleAdd(value);
        },
        [onReturn, handleAdd, handleSubmit, clearOnSubmit, resetInput, setPending]
    );

    const selectEmail = (email: string) => {
        addEmail(email);
        resetInput();
        skipBlurRef.current = true;
    };

    const selectContact = (id: string) => {
        addPendingItem("contact", id);
        resetInput();
        skipBlurRef.current = true;
    };

    const selectGroup = (id: string) => {
        addPendingItem("group", id);
        resetInput();
        skipBlurRef.current = true;
    };

    const selectParticipant = (id: string) => {
        addPendingItem("participant", id);
        resetInput();
        skipBlurRef.current = true;
    };

    const selectSpeaker = (id: string) => {
        addPendingItem("speaker", id);
        resetInput();
        skipBlurRef.current = true;
    };

    const onInviteMember = (email: string) => {
        inviteMemberEmailRef.current = email;
        setInviteMemberDialog(true);
        resetInput();
        skipBlurRef.current = true;
    };

    const onMemberInvited = () => {
        selectEmail(inviteMemberEmailRef.current!);
        inviteMemberEmailRef.current = null;
        setInviteMemberDialog(false);
    };

    useEffect(() => {
        resultNode?.scrollTo({
            top: (selected - 1) * 64,
            behavior: "auto",
        });
    }, [resultNode, selected]);

    //auto selects the first list result
    //e.g. when skipEmails={true} and the user searches for a contact
    useEffect(() => {
        if (props.autoFocusFirstResult && selected === -1) {
            selectIncrease(totalItems);
        }
    }, [selectIncrease, totalItems, props.autoFocusFirstResult, selected]);

    // When search string is email, check if exact match is found in contacts
    useEffect(() => {
        if (emailRegex.test(search)) {
            setHasExactMatch(!!findContact(search));
        }
    }, [search, items]);

    useEffect(() => {
        const {emails} = getPendingContactsAndEmails(pendingRef.current, contactMapRef.current, groupContactsRef.current);
        const invalidEmails = emails.filter((email) => !emailRegex.test(email));
        setHasInvalidEmails(invalidEmails.length > 0);
    }, [pending]);

    let itemIndex = -1;

    const guestInvite = search && emailRegex.test(search) && !hasExactMatch && props.skipEmails !== true && (
        <div
            className={cls(classes.inviteParticipantContainer, selected < 0 && classes.selected)}
            onMouseDown={props.inviteToWorkspace ? () => onInviteMember(search) : () => selectEmail(search)}
        >
            <IconButton className={classes.addIcon}>
                <AddCircleOutlineRoundedIcon />
            </IconButton>
            <Typography className={classes.typography}>{`${props.guestInviteText ?? "Invite guest"}: `}</Typography>
            <Typography className={cls(classes.typography, classes.currentParticipantName)}>{search}</Typography>
        </div>
    );

    const list = items.map((item) => {
        itemIndex++;

        if (item.type === "contact") {
            const contact = item.value.item;
            const isSelected = selected === itemIndex;
            selectionMap.current[itemIndex] = {type: "contact", value: contact.id};
            const onSelect =
                props.inviteToWorkspace && !isContactFromWorkspace(contact)
                    ? () => onInviteMember(contact.email)
                    : () => selectContact(contact.id);

            return (
                <ContactFetcherContact
                    key={contact.id}
                    index={itemIndex}
                    selected={isSelected}
                    contact={contact}
                    search={search}
                    onClick={onSelect}
                />
            );
        }

        if (item.type === "group") {
            const group = item.value.item;
            const isSelected = selected === itemIndex;
            selectionMap.current[itemIndex] = {type: "group", value: group.id};
            return (
                <ContactFetcherGroup
                    key={group.id}
                    index={itemIndex}
                    selected={isSelected}
                    group={group}
                    groupContacts={groupContactsMap[group.id] ?? emptyArray}
                    search={search}
                    onClick={() => {
                        selectGroup(group.id);
                    }}
                />
            );
        }

        if (item.type === "participant") {
            const participant = item.value;
            const isSelected = selected === itemIndex;
            const selectionValue = getParticipantValue(participant);
            selectionMap.current[itemIndex] = {type: "participant", value: selectionValue};
            return (
                <ContactFetcherParticipant
                    key={participant.id}
                    index={itemIndex}
                    selected={isSelected}
                    participant={participant}
                    search={search}
                    onClick={() => {
                        selectParticipant(selectionValue);
                    }}
                />
            );
        }

        if (item.type === "speaker") {
            const speaker = item.value.item;
            const isSelected = selected === itemIndex;
            const selectionValue = speaker.id;
            selectionMap.current[itemIndex] = {type: "speaker", value: selectionValue};
            return (
                <ContactFetcherSpeaker
                    key={speaker.id}
                    index={itemIndex}
                    selected={isSelected}
                    speaker={speaker}
                    search={search}
                    onClick={() => {
                        selectSpeaker(selectionValue);
                    }}
                />
            );
        }
    });

    return (
        <div className={cls(classes.root, isSessionDialog && classes.rootInSessionDialog, props.classes?.root)}>
            <div className={cls(classes.search, props.className)}>
                <ContactFetcherInput
                    label={props.label}
                    placeholder={props.placeholder}
                    contactMap={contactMap}
                    groupMap={groupMap}
                    speakerMap={speakerMap}
                    tags={pending}
                    onSearch={handleSearch}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    disabled={disabled || isCommiting}
                    onArrowUp={handleArrowUp}
                    onArrowDown={handleArrowDown}
                    onBackspace={handleBackspace}
                    onSpace={handleSpace}
                    onReturn={handleReturn}
                    onRemoveItem={removePendingItem}
                    onUpdateItem={updatePendingItem}
                    setInputValueRef={inputSetValueRef}
                    hideIcon={props.hideIcon}
                    tagIcon={props.tagIcon}
                    doNotReturnOnPaste={props.doNotAddOnPaste}
                    doNotAddOnBlur={props.doNotAddOnBlur}
                    isFetchingUserNames={props.isFetchingUserNames}
                />
            </div>
            {hasInvalidEmails && <span className={classes.error}>Please specify a valid email</span>}
            {(pending.length >= 250 ||
                (designer.participantsPayload?.upsertParticipantJSONs &&
                    designer.participantsPayload?.upsertParticipantJSONs.length >= 250)) && (
                <span className={classes.error}>
                    {isCommiting
                        ? "Please wait untill we finish inviting all pending participants"
                        : "You can invite a max. of 250 participants at a time."}
                </span>
            )}
            {showResult && (guestInvite || items.length > 0) && !disableShowResults && (
                <div className={classes.searchResults}>
                    <div className={classes.searchResultsList} ref={setResultNode}>
                        {guestInvite}
                        {list}
                    </div>
                </div>
            )}
            {inviteMemberDialog ? (
                <InviteMemberConfirmDialog
                    content={props.inviteModalContent}
                    member={{email: inviteMemberEmailRef.current ?? ""}}
                    open={inviteMemberDialog}
                    onClose={() => setInviteMemberDialog(false)}
                    onSuccess={onMemberInvited}
                    roleId={props.roleId}
                />
            ) : null}
        </div>
    );
};

export default ContactFetcher;
