import {addHours} from "date-fns";
import {format} from "date-fns";
import {set} from "date-fns";
import {setMinutes} from "date-fns";

export const cleanDate = (date?: Date) => {
    return set(date ?? new Date(), {
        seconds: 0,
        milliseconds: 0,
    });
};
const generateSessionStartDate = () => {
    const now = new Date();
    const nowMinutes = parseInt(format(now, "mm"));
    return cleanDate(nowMinutes < 15 ? setMinutes(now, 15) : setMinutes(addHours(now, 1), 0));
};

export default generateSessionStartDate;
