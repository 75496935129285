export const servicesSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "properties": {
        "externalAccount": {
            "properties": {
                "deleteExternalAccounts": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getExternalAccounts": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getToken": {
                    "propertyOrder": [],
                    "type": "object"
                }
            },
            "propertyOrder": [
                "getExternalAccounts",
                "deleteExternalAccounts",
                "getToken"
            ],
            "required": [
                "deleteExternalAccounts",
                "getExternalAccounts",
                "getToken"
            ],
            "type": "object"
        },
        "externalToken": {
            "properties": {
                "createExternalToken": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "denyAccessExternalToken": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getExternalTokenForRead": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getExternalTokenForWrite": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "tryCompleteExternalToken": {
                    "propertyOrder": [],
                    "type": "object"
                }
            },
            "propertyOrder": [
                "createExternalToken",
                "getExternalTokenForRead",
                "getExternalTokenForWrite",
                "tryCompleteExternalToken",
                "denyAccessExternalToken"
            ],
            "required": [
                "createExternalToken",
                "denyAccessExternalToken",
                "getExternalTokenForRead",
                "getExternalTokenForWrite",
                "tryCompleteExternalToken"
            ],
            "type": "object"
        },
        "integrationsKeyValue": {
            "properties": {
                "deleteValue": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getValue": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "saveValue": {
                    "propertyOrder": [],
                    "type": "object"
                }
            },
            "propertyOrder": [
                "getValue",
                "saveValue",
                "deleteValue"
            ],
            "required": [
                "deleteValue",
                "getValue",
                "saveValue"
            ],
            "type": "object"
        },
        "resx": {
            "properties": {
                "copyResource": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "createResource": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "createResourceWithVersion": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "createResult": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "deleteResource": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "downloadFileRef": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getRelatedSessionNames": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getResourcesOfType": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getSmartboard": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "markFileRefAsUploaded": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "persistSmartboard": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "startResourceProcessing": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "toggleResourcePermission": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "toggleResourceTemplate": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "updateResource": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "updateResourceProcessingStatus": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "updateResourceUpdatedAt": {
                    "description": "the only purpose of this method is to emulate a better \"last used\" functionality",
                    "propertyOrder": [],
                    "type": "object"
                },
                "updateResourceWithVersion": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "updateResult": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "updateResultSnapshot": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "uploadDrawingServiceImage": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "uploadFileRef": {
                    "propertyOrder": [],
                    "type": "object"
                }
            },
            "propertyOrder": [
                "createResource",
                "copyResource",
                "createResult",
                "createResourceWithVersion",
                "updateResource",
                "updateResourceUpdatedAt",
                "updateResultSnapshot",
                "updateResult",
                "updateResourceWithVersion",
                "deleteResource",
                "uploadFileRef",
                "downloadFileRef",
                "markFileRefAsUploaded",
                "updateResourceProcessingStatus",
                "startResourceProcessing",
                "getRelatedSessionNames",
                "getResourcesOfType",
                "toggleResourcePermission",
                "toggleResourceTemplate",
                "getSmartboard",
                "persistSmartboard",
                "uploadDrawingServiceImage"
            ],
            "required": [
                "copyResource",
                "createResource",
                "createResourceWithVersion",
                "createResult",
                "deleteResource",
                "downloadFileRef",
                "getRelatedSessionNames",
                "getResourcesOfType",
                "getSmartboard",
                "markFileRefAsUploaded",
                "persistSmartboard",
                "startResourceProcessing",
                "toggleResourcePermission",
                "toggleResourceTemplate",
                "updateResource",
                "updateResourceProcessingStatus",
                "updateResourceUpdatedAt",
                "updateResourceWithVersion",
                "updateResult",
                "updateResultSnapshot",
                "uploadDrawingServiceImage",
                "uploadFileRef"
            ],
            "type": "object"
        },
        "session": {
            "properties": {
                "getSessions": {
                    "propertyOrder": [],
                    "type": "object"
                }
            },
            "propertyOrder": [
                "getSessions"
            ],
            "required": [
                "getSessions"
            ],
            "type": "object"
        },
        "user": {
            "properties": {
                "getUserByEmail": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getUserById": {
                    "propertyOrder": [],
                    "type": "object"
                },
                "getUsers": {
                    "propertyOrder": [],
                    "type": "object"
                }
            },
            "propertyOrder": [
                "getUsers",
                "getUserByEmail",
                "getUserById"
            ],
            "required": [
                "getUserByEmail",
                "getUserById",
                "getUsers"
            ],
            "type": "object"
        }
    },
    "propertyOrder": [
        "resx",
        "externalToken",
        "externalAccount",
        "user",
        "integrationsKeyValue",
        "session"
    ],
    "required": [
        "externalAccount",
        "externalToken",
        "integrationsKeyValue",
        "resx",
        "session",
        "user"
    ],
    "type": "object"
};
