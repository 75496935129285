import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import {CustomResolvers} from "./types";

export const RecurrenceData: CustomResolvers["RecurrenceData"] = {
    ...fieldResolvers.RecurrenceData,
    instances: {
        merge: false,
    },
    exclude: {
        // @ts-ignore
        read: (existing) => {
            return existing?.map((date) => new Date(date));
        },
    },
};
