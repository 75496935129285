import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {useCameraSettings} from "@workhorse/api/conference2";
import {forwardRef} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";

function HideSelfViewOn() {
    const {t} = useTranslation();

    return (
        <>
            <VisibilityRoundedIcon />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.camera.hide_self_view")}
            </Typography>
        </>
    );
}

function HideSelfViewOff() {
    const {t} = useTranslation();

    return (
        <>
            <VisibilityOffRoundedIcon />
            <Typography color="secondary" fontWeight="bold">
                {t("participant.camera.show_self_view")}
            </Typography>
        </>
    );
}

const HideSelfViewOption = forwardRef((_, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {cameraHidden, toggleCameraHidden} = useCameraSettings();

    const handleOnToggle = () => {
        toggleCameraHidden();
    };

    return (
        <MenuItem ref={ref} onClick={handleOnToggle}>
            {!cameraHidden && <HideSelfViewOn />}
            {cameraHidden && <HideSelfViewOff />}
        </MenuItem>
    );
});

export default HideSelfViewOption;
