import {Session} from "@generated/data";
import DeleteForever from "@material-ui/icons/DeleteForever";
import ConfirmationDialog, {ConfirmationDialogImperativeRef} from "@ui/cdk/ConfirmationDialog";
import {dateFormat} from "@ui/cdk/DateFormat";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import List from "@ui/core/components/List";
import ListItem from "@ui/core/components/ListItem";
import ListItemSecondaryAction from "@ui/core/components/ListItemSecondaryAction";
import ListItemText from "@ui/core/components/ListItemText";
import React from "@workhorse/api/rendering";
import {displayRecurrenceText} from "@workhorse/pages/designer/CreateSession/SessionRecurrenceText";
import {isAfter} from "date-fns";
import classes from "./style/ResourcesDeleteConfirmationDialog.module.scss";

export enum ResourceTypes {
    form = "form",
    slideshow = "slideshow",
    file = "file",
    folder = "folder",
    gallery = "gallery",
    video = "video",
    youtube = "youtube",
    image = "image",
    document = "document",
    resource = "resource",
    figma = "figma",
    canva = "canva",
}

type ResourcesDeleteConfirmationDialogProps = {
    sessions?: Partial<Session>[];
    dialogRef: React.MutableRefObject<ConfirmationDialogImperativeRef>;
    onConfirm: () => void;
    onCancel?: () => void;
    resourceName?: string | null;
    resourcesNumber?: number;
    resourceType: string;
};

const ResourcesDeleteConfirmationDialog = (props: ResourcesDeleteConfirmationDialogProps) => {
    const {dialogRef, sessions, onConfirm, resourceName, resourcesNumber = 1, resourceType, onCancel} = props;

    const DeleteIcon = <DeleteForever className={classes.deleteIcon} />;

    const sortedSessions = sessions?.sort((a, b) =>
        isAfter(new Date(a.startAt ?? new Date()), new Date(b.startAt ?? new Date())) ? -1 : 1
    );

    const sessionDateDisplay = (session: Partial<Session>) =>
        `${dateFormat(new Date(session.startAt ?? new Date()), ["weekday", {sep: ", "}, "dayOfMonth", "monthShort", "fullTime24h"])}${
            session.recurrenceData?.id ? `, ${displayRecurrenceText(session.recurrenceData)}` : ""
        }`;

    const resourceDisplayName = resourceName ?? "resource";

    const handleOnCancel = () => {
        onCancel?.();
    };

    return (
        <ConfirmationDialog
            connector={dialogRef}
            title={"Are you sure you want to..."}
            message={`Delete ${resourceDisplayName}?`}
            icon={DeleteIcon}
            onReject={handleOnCancel}
            onConfirm={onConfirm}
            cancelButtonText="Cancel"
            confirmButtonText="Delete"
            confirmButtonVariant="destructive-secondary"
            classes={{
                contentBlock: classes.dialogContentBlock,
                contentHeader: classes.dialogContentHeader,
            }}
            isMobile={false}
        >
            {sessions ? (
                <Typography component="div" className={classes.info}>
                    {sessions.length > 0 ? (
                        <>
                            Deleting {resourceDisplayName} will affect
                            <Tooltip
                                interactive={true}
                                arrow={false}
                                placement="bottom"
                                classes={{
                                    tooltip: classes.tooltip,
                                }}
                                title={
                                    <div className={classes.tooltipContent}>
                                        <Typography variant="sm" className={classes.tooltipHeader}>
                                            Affected sessions
                                        </Typography>
                                        <List className={classes.list}>
                                            {sortedSessions?.map((session) => (
                                                <ListItem
                                                    key={session.id}
                                                    disableGutters
                                                    divider
                                                    className={classes.listItem}
                                                    classes={{
                                                        divider: classes.divider,
                                                    }}
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemPrimary,
                                                            secondary: classes.listItemSecondary,
                                                        }}
                                                        primary={session.name}
                                                        secondary={sessionDateDisplay(session)}
                                                    />
                                                    <ListItemSecondaryAction>"Published"</ListItemSecondaryAction>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                }
                            >
                                <span className={classes.totalSessions}>
                                    &nbsp;{sessions.length} {sessions.length > 1 ? "sessions" : "session"}&nbsp;
                                </span>
                            </Tooltip>
                            which {sessions.length > 1 ? "are" : "is"} using{" "}
                            {resourceType === ResourceTypes.folder
                                ? "files from this folder"
                                : resourcesNumber > 1
                                ? `these ${resourceType}s`
                                : `this ${resourceType}`}
                            .
                        </>
                    ) : (
                        <>Deleting {resourceDisplayName} will not affect any previously configured sessions.</>
                    )}{" "}
                    This operation can't be undone.
                </Typography>
            ) : (
                <Typography component="div" className={classes.info}>
                    This operation can't be undone.
                </Typography>
            )}
        </ConfirmationDialog>
    );
};

export default ResourcesDeleteConfirmationDialog;
