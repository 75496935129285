import svc from "@api/service/client";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {VersionedSpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {
    AgendaItemType,
    AgendaTemplateDocument,
    ChooseToolStep,
    CommitAgendaTemplateDocument,
    ConfigurationConfirmType,
    DeleteSessionDocument,
    FullAgendaTemplateFragment,
    MyProductTool,
    MyProductToolsDocument,
    SessionLifecycle,
    ConfigurationStep,
    SessionNoCacheFragment,
    DrawerState,
    FindResourceByNameDocument,
    ResourceProcessingStatus,
    GetRemoteUserDocument,
} from "@generated/data";
import apollo, {ApolloOperationContext} from "@workhorse/api/apollo";
import {mutate, readQuery, writeQuery} from "@workhorse/dataApi";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {AgendaItem, Artifact, DesignerApiSession as Session} from "@workhorse/declarations/dataTypes";
import {evictLocalQueries} from "@workhorse/providers/HostTypeProvider";
import designer from ".";
import {getResourceResult} from "../resx/hooks";
import toast from "../toast";
import {createAgendaItem} from "./lib/actions/agenda-item/create-agenda-item";
import {getMaxSessionOrder, makeId, removeTypenameKey} from "./lib/utils";
import {TEMPLATE_FOLDER_NAME_FOR_USERS} from "@sessions/common/agenda-templates";
import {AgendaTemplateTagData, buildCreateAgendaTemplatePayload, buildUpdateAgendaTemplatePayload} from "./lib/templates/payloads";
import {PartialDeep} from "type-fest";
import {makeTemplateIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {OmitTypenames} from "@sessions/common/diff/types";
import {PaletteActionArg} from "@workhorse/components/command-palette/actionCategTree";
import descriptor, {ArtifactTag} from "@generated/artifacts";
import {readMacroArtifacts, readSession} from "@workhorse/providers/SessionDataProviders";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import {togglePalette} from "@ui/cdk/util";
import {getTemplate} from "@workhorse/pages/templates/utils";
import {orderBeforePreview} from "./lib/designer-state/designerStateVars";
import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import RandExp from "randexp";
import clientEvents from "@api/events/client";
import {setLeftDrawerState} from "@workhorse/providers/state";
import {DOCUMENT_RESOURCE_TYPES, getDocumentToken} from "./lib/utils/getDocumentToken";
import {ResourceFullWithContent} from "../resx/utils";

import {activeWorkspacePermissions} from "@workhorse/providers/User/utils";

const toolsWithoutPreview: SimpleArtifactsTag[] = ["flowos/miro", "flowos/breakout", "flowos/break", "flowos/my-product-tool"];

const resourcesWithProcessing: ResourcesTypes[] = [
    "flowos/ppt/resx/Ppt",
    "flowos/pdf/resx/Pdf",
    "flowos/doc/resx/Doc",
    "flowos/excel/resx/Excel",
    "flowos/keynote/resx/Keynote",
    "flowos/pages/resx/Pages",
    "flowos/numbers/resx/Numbers",
    "flowos/video/resx/Video",
];

export const insertAgendaItemAbove = (input: string[], duration = 0, userId?: string) => {
    const data = {
        agendaItems: designer.currentAgendaItems(),
        session: designer.currentSession(),
    };

    const agendaItemsSelected = data?.agendaItems.filter((a) => input.includes(a.id) && !a.isDeleted);

    const min = agendaItemsSelected?.length ? Math.min(...agendaItemsSelected.map((a) => a.order)) : null;

    if (min === null) {
        return;
    }

    const agendaItem = agendaItemsSelected?.find((a) => a.order === min);
    if (!agendaItem?.id) {
        return;
    }
    designer.api.agendaItem.insert({
        agendaItem: createAgendaItem({
            ...designer.constants.agendaItemDefaults,
            title: "",
            duration,
            order: undefined,
            type: AgendaItemType.Planned,
            locked: userId ?? null,
        }),
        ids: [agendaItem.id],
        location: "before",
    });
};

export const insertAgendaItemBelow = (input: string[], duration = 0, userId?: string) => {
    const data = {
        agendaItems: designer.currentAgendaItems(),
        session: designer.currentSession(),
    };

    const agendaItemsSelected = data?.agendaItems.filter((a) => input.includes(a.id) && !a.isDeleted);

    const max = agendaItemsSelected?.length ? Math.max(...agendaItemsSelected.map((a) => a.order)) : null;

    if (max !== 0 && !max) {
        return;
    }

    const agendaItem = agendaItemsSelected?.find((a) => a.order === max);
    if (!agendaItem?.id) {
        return;
    }

    designer.api.agendaItem.insert({
        agendaItem: createAgendaItem({
            ...designer.constants.agendaItemDefaults,
            title: "",
            duration,
            order: undefined,
            type: AgendaItemType.Planned,
            locked: userId ?? null,
        }),
        ids: [agendaItem.id],
        location: "after",
    });
};

type setArtifactResourceResultInput<TType extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[TType]["result"]> = {
    id: string;
    resourceSlotData: {
        type: TType;
        config: {
            resource: string;
        };
    };
};

export async function setArtifactResourceResult<TType extends ResourcesTypes, K extends keyof VersionedSpecifiersTypeMap[TType]["result"]>(
    data: setArtifactResourceResultInput<TType, K>
): Promise<string | undefined> {
    const artifactId = data.id;

    const resourceId = data.resourceSlotData.config.resource;
    let isConfigured: boolean | undefined = undefined;
    const result = await svc.resx.createResult(data.resourceSlotData.type, "latest", resourceId);

    const requiresProcessing = resourcesWithProcessing.includes(data.resourceSlotData.type);

    if (requiresProcessing && result?.id) {
        const {result: fullResult} = await getResourceResult(result.id);
        const resource = fullResult?.resource;

        if (
            resource?.processingStatus &&
            ![ResourceProcessingStatus.NotRequired, ResourceProcessingStatus.Finished].includes(resource?.processingStatus)
        ) {
            isConfigured = false;
        }

        // FOSS-16340 - generate token for document type resources

        if (DOCUMENT_RESOURCE_TYPES.includes(data.resourceSlotData.type)) {
            const documentContent = resource?.content as ResourceFullWithContent<"flowos/pdf/resx/Pdf", "latest">["content"] | undefined;

            if (documentContent && documentContent.documentId) {
                const documentTokenProperty = await getDocumentToken(data.resourceSlotData.type, artifactId, documentContent.documentId);

                if (documentTokenProperty) {
                    designer.api.artifact.updateArtifact({
                        id: artifactId,
                        artifact: {
                            properties: [documentTokenProperty],
                        },
                    });
                }
            }
        }
    }

    const now = null as unknown as Date;
    if (result?.id) {
        designer.api.artifact.setArtifactResult({
            artifactId,
            isConfigured,
            resourceResult: {
                id: result.id,
                createdAt: now,
                isDeleted: false,
                oldId: null,
                update: null,
                updatedAt: now,
                __typename: "ResourceResult",
            },
        });
    } else {
        toast("You no longer have access to this resource", {
            type: "error",
        });
    }

    return result?.id ?? undefined;
}

export const deleteSession = async (sessionId: string, onSuccess?: Function) => {
    return await apollo.client
        .mutate({
            mutation: DeleteSessionDocument,
            variables: {
                session: {id: sessionId},
            },
        })
        .then((res) => {
            if (res.data?.deleteOneSession?.id) {
                onSuccess?.();
            }
        });
};
export const onBeforeDeleteResourceInLiveSession = async (resourceId: string) => {
    const agendaItems = designer.currentAgendaItems();
    const itemsWithResults = agendaItems.filter((a) => a.artifact?.resourceResults?.[0]?.id);

    if (!agendaItems.length || !itemsWithResults) {
        return;
    }

    for (const item of itemsWithResults) {
        const {result} = await getResourceResult(item.artifact?.resourceResults?.[0]?.id);
        const resource = result?.resource;
        if (resource?.id !== resourceId) {
            continue;
        }
        designer.api.agendaItem.removeArtifact({
            agendaItemId: item.id,
        });
    }
};

export const commitTemplate = async (
    localSession?: {
        id?: string;
        name?: string;
        description?: string;
        agendaItems?: DeepMandatoryWithNullVals<Session["agendaItems"]>;
    },
    unmounting?: boolean,
    bypassDirtyCheck?: boolean
    // wasPublic?: boolean,
    // keepResults?: boolean
) => {
    const isDirty = designer.state.getSnapshot().dirty;

    const session = localSession || designer.currentSession();
    const OgAgendaItems = localSession?.agendaItems || designer.currentAgendaItems();

    if (!bypassDirtyCheck && !isDirty && !localSession) {
        if (unmounting && session?.id) {
            evictLocalQueries(session.id);

            designer.state.initializeOrResetState(null);
            designer.clearActionsHistory();
            designer.clearPersistentStorage();
        }

        return;
    }

    if (!Object.keys(session ?? {}).length && !localSession) {
        return;
    }

    const agendaItems = localSession?.agendaItems ? structuredClone(localSession.agendaItems) : structuredClone(OgAgendaItems);
    const originalAgendaItems = localSession?.agendaItems ? localSession.agendaItems : structuredClone(agendaItems);

    removeTypenameKey(agendaItems);
    const commitId = session?.id ? session?.id.replace("_template", "") : makeId();

    const sessionToCommit: any = {
        // ...session,
        id: commitId,
        name: localSession?.name ?? session?.name,
        description: localSession?.description ?? session?.description,
        __typename: "AgendaTemplate",

        agendaItems: agendaItems
            .filter((a) => !a.isDeleted)
            .map((x) => ({
                ...x,
                update: undefined,
                isDeleted: undefined,
                __typename: "AgendaItem",
                descriptionJson: x.descriptionJson,
                createdAt: undefined,
                updatedAt: undefined,
                oldId: undefined,
                artifact:
                    x.artifact && Object.keys(x.artifact).length
                        ? {
                              ...x.artifact,
                              __typename: "Artifact",
                              createdAt: undefined,
                              updatedAt: undefined,
                              update: undefined,
                              isDeleted: undefined,
                              oldId: undefined,
                              resourceResults: x.artifact.resourceResults?.filter((r) => !r.isDeleted),
                              properties: Object.keys(originalAgendaItems.find((a) => a.id === x.id)?.artifact?.data ?? {}).map((key) => ({
                                  //   __typename: "Property",
                                  key: key,
                                  value: originalAgendaItems.find((a) => a.id === x.id)?.artifact?.data[key],
                              })) as DeepMandatoryWithNullVals<Artifact>["properties"],
                          }
                        : designer.constants.makeDefaultConferenceArtifact(makeId(), x.id),
            })),
    };

    await apollo.client
        .mutate({
            mutation: CommitAgendaTemplateDocument,
            variables: {
                newAgendaTemplate: sessionToCommit,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            apollo.client.writeQuery({
                query: AgendaTemplateDocument,
                variables: {
                    where: {
                        id: res.data?.commitAgendaTemplate.id,
                    },
                },
                data: {
                    agendaTemplate: res.data?.commitAgendaTemplate,
                },
            });

            if (unmounting && session?.id) {
                evictLocalQueries(session.id);
                return;
            }

            if (!res.data?.commitAgendaTemplate.id) {
                return;
            }
        });
};

export const commitUpdateAgendaTemplate = async (
    isPublic?: boolean,
    localSession?: {
        id?: string;
        name?: string;
        description?: string;
        agendaItems?: DeepMandatoryWithNullVals<Session["agendaItems"]>;
    },
    unmounting?: boolean,
    bypassDirtyCheck?: boolean
    // wasPublic?: boolean,
    // keepResults?: boolean
) => {
    const isDirty = designer.state.getSnapshot().dirty;

    const session = localSession || designer.currentSession();

    if (!bypassDirtyCheck && !isDirty && !localSession) {
        if (unmounting && session?.id) {
            evictLocalQueries(session.id);

            designer.state.initializeOrResetState(null);
            designer.clearActionsHistory();
            designer.clearPersistentStorage();
        }

        return;
    }

    if (!Object.keys(session ?? {}).length && !localSession) {
        return;
    }

    const commitId = session?.id ? session?.id.replace("_template", "") : null;

    if (!commitId) {
        return;
    }

    const templateIdFromRoute = makeTemplateIdFromRoute(location.pathname);

    if (templateIdFromRoute !== commitId) {
        return;
    }

    designer.state.setDesignerCommitState(true);

    const diff = designer.extractDiff() as PartialDeep<Session>;

    const diffStripped = removeTypenameKey(diff, {preserveDiffFields: true, preserveTimestamps: true}) as OmitTypenames<Session>;

    if (!diffStripped.session && commitId) {
        // @ts-expect-error do not fix this
        diffStripped.session = {
            __typename: "Session",
            id: commitId,
        };
    }

    const payload = buildUpdateAgendaTemplatePayload(diffStripped, isPublic);

    if (!Object.keys(payload).length) {
        designer.state.setDesignerCommitState(false);
        return;
    }

    return await mutate("UpdateOneAgendaTemplateDocument", {
        variables: {
            where: {
                id: commitId,
            },
            data: payload,
        },
        fetchPolicy: "no-cache",
    })
        .then(async (res) => {
            if (res.data?.updateOneAgendaTemplate?.id) {
                // designer.state.setDesignerCommitState(false);
                console.log("writing in cache", res.data.updateOneAgendaTemplate);
                designer.clearPersistentStorage();
                const currentId = res.data?.updateOneAgendaTemplate?.id;
                apollo.client.writeQuery({
                    query: AgendaTemplateDocument,
                    variables: {
                        where: {
                            id: currentId,
                        },
                    },
                    data: {
                        __typename: "Query",
                        agendaTemplate: res.data?.updateOneAgendaTemplate,
                    },
                });

                return res.data.updateOneAgendaTemplate;
            }
        })
        .finally(() => {
            // designer.state.setDesignerCommitState(false);
        });
};

export const commitCreateAgendaTemplate = async (context?: ApolloOperationContext) => {
    const current = removeTypenameKey(designer.changedData()!);

    designer.state.initializeOrResetState(current.session!.id);

    designer.state.setDesignerEditState(true);
    designer.state.setDesignerCommitState(true);

    const commitData = buildCreateAgendaTemplatePayload({
        ...current,
        session: {
            ...current.session!,
            id: current?.session?.id ? current?.session?.id.replace("_template", "") : makeId(),
        },
    });

    return await mutate("CreateOneAgendaTemplateDocument", {
        variables: {
            data: {
                ...commitData,
            },
        },
        context,
        fetchPolicy: "default",
    })
        .then(async (createResponse) => {
            let session: FullAgendaTemplateFragment | undefined = undefined;

            session = createResponse.data?.createOneAgendaTemplate ?? undefined;
            if (session && session.id) {
                designer.clearActionsHistory();
                designer.clearSessionStorage();
                designer.state.update({
                    templateMode: true,
                });
            }
            return session;
        })
        .finally(() => {
            designer.state.setDesignerCommitState(false);
        });
};

interface AddAgendaItemOptions {
    togglePalette?: boolean;
    actionId?: string;
    skipPreview?: boolean;
}

export const addAgendaItemWithTool = async (
    artifactTag: ArtifactTag,
    callback?: (idOfArtifact: string) => Promise<unknown>,
    options?: AddAgendaItemOptions
) => {
    designer.state.update({
        configInProgress: true,
    });
    const macros = designer.currentMacroArtifacts();
    const templateMode = designer.state.getSnapshot().templateMode;
    let isAgendaLessSession = templateMode
        ? false
        : macros.findIndex((a) => a.artifactId === "flowos/agenda") === -1 ||
          designer.currentAgendaItems()?.every((a) => a.type === AgendaItemType.Instant);

    const isEditing = designer.state.getDesignerEditState();

    const sessionOrder = designer.currentSession()?.order;
    const sessionId = designer.state.getSessionId();
    const isSessionStarted = designer.currentSession()?.lifecycle === SessionLifecycle.Started;

    const editingAgendaItemId =
        !isEditing && !isAgendaLessSession && isSessionStarted
            ? designer.currentAgendaItems().find((item) => item.order === sessionOrder)?.id
            : !isSessionStarted
            ? designer.state.getSnapshot().selectedAgendaItemId
            : isAgendaLessSession
            ? designer.state.getSnapshot().selectedAgendaItemId
            : designer.state.getSnapshot().selectedAgendaItemId ??
              designer.currentAgendaItems().find((item) => item.order === sessionOrder)?.id;

    const isMyProductTool = artifactTag === "flowos/my-product-tool";
    let myProductTool: MyProductTool | null = null;

    if (isMyProductTool) {
        const remoteUserQuery = apollo.cache.readQuery({
            query: GetRemoteUserDocument,
        });

        const workspaceId = activeWorkspacePermissions(remoteUserQuery?.getRemoteUser.user?.workspacePermissions)?.workspace?.id;

        if (workspaceId) {
            const {data: myProductToolsQuery} = await apollo.client.query({
                query: MyProductToolsDocument,
                variables: {
                    workspaceId,
                },
            });

            const productToolId = options?.actionId?.replace("-next", "");
            const ind = myProductToolsQuery?.myProductTools?.findIndex((t) => t.id === productToolId) ?? 0;

            const myProductToolData = myProductToolsQuery?.myProductTools?.[ind];

            myProductTool = myProductToolData;
        }
    }
    const order = getMaxSessionOrder() + 1;
    let shouldStoreOrder = false;
    let sessionHadAgendaButWasRemoved: boolean | undefined = undefined;

    if (!isAgendaLessSession && !editingAgendaItemId) {
        const configurationStep = designer.state.getSnapshot().configurationStep;

        // we are creating a new agenda while adding a standalone tool
        if (configurationStep === ConfigurationStep.Create) {
            designer.undoChanges({
                from: ["agendaItems"],
            });
            // we are creating a new agenda while adding a standalone tool and there is already an old agenda in the session
            if (designer.currentAgendaItems().some((a) => a.type === AgendaItemType.Planned && a.createdAt)) {
                designer.api.session.detachAgenda({
                    type: AgendaItemType.Instant,
                });
            }
        } else {
            designer.api.session.detachAgenda({
                type: AgendaItemType.Instant,
            });
        }

        const agendaId = macros.find((a) => a.artifactId === "flowos/agenda")?.id;
        if (agendaId) {
            designer.api.artifact.removeMacroArtifact({
                id: agendaId,
            });
        }
        isAgendaLessSession = true;

        setLeftDrawerState(DrawerState.Closed);
        shouldStoreOrder = true;
        sessionHadAgendaButWasRemoved = true;
    }

    let currentAgendaItemId: string | undefined | null;

    if (editingAgendaItemId && isSessionStarted && !isAgendaLessSession && !isEditing) {
        const agendaItem = designer.currentAgendaItems().find((item) => item.id === editingAgendaItemId);
        if (!sessionId) {
            return;
        }

        const localAgendaItems = readQuery("LocalAgendaItemsDocument", {
            variables: {
                id: sessionId,
            },
        });

        writeFromFullSession(sessionId, {
            agendaItems:
                localAgendaItems?.agendaItems?.map((a) => (a.id === editingAgendaItemId ? {...a, order: -1, isDeleted: true} : a)) ?? [],
        });

        currentAgendaItemId = designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            title: agendaItem?.title ?? designer.constants.agendaItemDefaults.title,
            descriptionJson: agendaItem?.descriptionJson,
            speakerNotes: agendaItem?.speakerNotes ?? "",
            speakerNotesJson: agendaItem?.speakerNotesJson,
            type: AgendaItemType.Planned,
            order: agendaItem?.order ?? getMaxSessionOrder() + 1,
            overrideOrder: true,
            doNotUpdateCreatePls: true,
        });
    } else {
        currentAgendaItemId = isAgendaLessSession
            ? designer.api.agendaItem.create({
                  ...designer.constants.agendaItemDefaults,
                  type: AgendaItemType.Instant,
                  order: editingAgendaItemId ? undefined : order,
                  overrideOrder: editingAgendaItemId ? undefined : !editingAgendaItemId,
                  doNotUpdateCreatePls: true,
              })
            : editingAgendaItemId;
    }

    if (!templateMode && options?.togglePalette !== false) {
        togglePalette();
    }
    if (!currentAgendaItemId) {
        designer.state.update({
            configInProgress: false,
        });
        return;
    }

    if (!editingAgendaItemId) {
        designer.api.session.update({
            order,
        });
    }

    designer.state.update({
        selectedAgendaItemId: currentAgendaItemId,
    });

    const currentArtifactDescriptor = descriptor[artifactTag as ArtifactTag];

    const defaultProperties = currentArtifactDescriptor.defaultProperties || {};

    // TODO - SILVIU - document token
    const addedMicro = designer.api.agendaItem.addArtifact({
        agendaItemId: currentAgendaItemId,
        // addDefaultArtifact: isConfiguredByDefault,
        overrides: {
            artifactId: currentArtifactDescriptor.tag as ArtifactTag,
            description:
                isMyProductTool && myProductTool?.description ? myProductTool?.description : currentArtifactDescriptor.meta.description,
            name: isMyProductTool && myProductTool?.title ? myProductTool?.title : currentArtifactDescriptor.meta.displayName,
            isConfigured: true,
            properties:
                isMyProductTool && myProductTool?.id
                    ? [
                          {
                              __typename: "Property",
                              id: makeId(),
                              key: "myProductToolId",
                              value: myProductTool?.id,
                              createdAt: null as unknown as Date,
                              updatedAt: null as unknown as Date,
                              isDeleted: null,
                              oldId: null,
                              update: null,
                          },
                      ]
                    : Object.keys(defaultProperties).map((key) => ({
                          __typename: "Property",
                          id: makeId(),
                          key,
                          value: defaultProperties[key],
                          createdAt: null as unknown as Date,
                          updatedAt: null as unknown as Date,
                          isDeleted: null,
                          oldId: null,
                          update: null,
                      })),
        },
    });

    if (callback) {
        await callback(addedMicro);
    }

    const addedArtifactIsConfigured = designer.currentAgendaItems().find((a) => a.artifact?.id === addedMicro)?.artifact?.isConfigured;

    switch (addedArtifactIsConfigured) {
        case false: {
            // adding a tool that is processing in a session without agenda (lobby and live)
            if (isAgendaLessSession && !sessionHadAgendaButWasRemoved && !editingAgendaItemId) {
                console.log("case: unconfiguredToolInAgendaLessSession");
                await unconfiguredToolInAgendaLessSession({
                    currentAgendaItemId,
                    order,
                });
            }

            // add a tool that is processing in a session with agenda (lobby and live)
            if (sessionHadAgendaButWasRemoved && !editingAgendaItemId) {
                console.log("case: unconfiguredToolInSessionWithAgenda");
                await unconfiguredToolInSessionWithAgenda({
                    currentAgendaItemId,
                    shouldStoreOrder,
                });
            }

            // adding tool that is processing on an agenda item (lobby, live, template) - edit mode
            if (editingAgendaItemId && (!isSessionStarted || isEditing)) {
                console.log("case: unconfiguredToolOnAgendaItem");
                await unconfiguredToolOnAgendaItem({
                    shouldTogglePalette: options?.togglePalette !== false && templateMode,
                });
                if (!isEditing) {
                    designer.commit();
                }
            }

            // adding tool that is processing on an agenda item live session without edit mode
            if (editingAgendaItemId && isSessionStarted && !isEditing) {
                console.log("case: toolOnAgendaItemInLiveSession");
                await toolOnAgendaItemInLiveSession({
                    currentAgendaItemId,
                });
            }
            break;
        }
        case true: {
            // tool is configured and is added in a live session without agenda
            if (isSessionStarted && isAgendaLessSession && !sessionHadAgendaButWasRemoved && !editingAgendaItemId) {
                console.log("case: configuredToolInAgendaLsessSessionLive");
                await configuredToolInAgendaLsessSessionLive({
                    currentAgendaItemId,
                    order,
                    skipPreview: options?.skipPreview ?? toolsWithoutPreview.includes(artifactTag),
                });
            }

            // tool is configured and is added in lobby without agenda
            if (!isSessionStarted && isAgendaLessSession && !sessionHadAgendaButWasRemoved && !editingAgendaItemId) {
                console.log("case: configuredToolInAgendaLsessSessionLobby");
                await configuredToolInAgendaLsessSessionLobby({
                    order,
                });
            }

            // tool is configured and is added in lobby with agenda
            if (!isSessionStarted && sessionHadAgendaButWasRemoved && !editingAgendaItemId) {
                console.log("case: configuredToolInLobbyWithAgenda");
                await configuredToolInLobbyWithAgenda({
                    order,
                });
            }

            // tool is configured and is added in live session with agenda
            if (isSessionStarted && sessionHadAgendaButWasRemoved && !editingAgendaItemId) {
                console.log("case: configuredToolLiveSessionWithAgenda");
                await configuredToolLiveSessionWithAgenda({
                    order,
                    currentAgendaItemId,
                    shouldStoreOrder,
                });
            }

            // tool is configured and is added on agendaItem in lobby in edit mode
            if (editingAgendaItemId && (!isSessionStarted || isEditing)) {
                console.log("case: confiugredToolOnAgendaItem");
                await confiugredToolOnAgendaItem({
                    shouldTogglePalette: options?.togglePalette !== false && templateMode,
                });
            }

            // tool is configured and is added on agendaItem in live session without edit mode
            if (editingAgendaItemId && isSessionStarted && !isEditing) {
                console.log("case: toolOnAgendaItemInLiveSession");
                await toolOnAgendaItemInLiveSession({
                    currentAgendaItemId,
                });
            }

            break;
        }
    }
    designer.state.update({
        configInProgress: false,
    });
    clientEvents.emit("finished-config-process", currentAgendaItemId);
};

const confiugredToolOnAgendaItem = async ({shouldTogglePalette}: {shouldTogglePalette: boolean}) => {
    designer.state.update({
        selectedAgendaItemId: null,
    });
    if (shouldTogglePalette) {
        togglePalette();
    }
};

const toolOnAgendaItemInLiveSession = async ({currentAgendaItemId}: {currentAgendaItemId: string}) => {
    designer.state.setDesignerCommitState(true);
    designer.state.update({
        configurationConfirm: ConfigurationConfirmType.Tool,
    });

    designer.api.agendaItem.update({
        id: currentAgendaItemId,
        agendaItem: {
            createdAt: new Date(),
            updatedAt: new Date(),
            type: AgendaItemType.Planned,
        },
    });

    const currentMicro = designer.currentAgendaItems().find((a) => a.id === currentAgendaItemId)?.artifact;
    if (currentMicro) {
        designer.api.artifact.updateArtifact({
            id: currentMicro.id,
            mockResult: true,
            artifact: {
                createdAt: new Date(),
                updatedAt: new Date(),
                update: null,
            },
        });
    }
};

const configuredToolLiveSessionWithAgenda = async ({
    order,
    currentAgendaItemId,
    shouldStoreOrder,
}: {
    order: number;
    currentAgendaItemId: string;
    shouldStoreOrder: boolean;
}) => {
    designer.state.setDesignerCommitState(true);
    designer.state.update({
        configurationConfirm: ConfigurationConfirmType.Tool,
    });

    designer.api.agendaItem.update({
        id: currentAgendaItemId,
        agendaItem: {
            createdAt: new Date(),
            updatedAt: new Date(),
            order,
        },
    });

    const currentMicro = designer.currentAgendaItems().find((a) => a.id === currentAgendaItemId)?.artifact;
    if (currentMicro) {
        designer.api.artifact.updateArtifact({
            id: currentMicro.id,
            mockResult: true,
            artifact: {
                createdAt: new Date(),
                updatedAt: new Date(),
            },
        });
    }

    const currentSessionId = designer.state.getSessionId();
    if (!currentSessionId) {
        return;
    }
    const existingSession = readSession({sessionId: currentSessionId});
    if (shouldStoreOrder) {
        orderBeforePreview(existingSession?.order);
    }

    if (existingSession) {
        writeFromFullSession(currentSessionId, {
            session: {
                ...existingSession,
                order: order,
            },
        });
    }
};

const configuredToolInLobbyWithAgenda = async ({order}: {order: number}) => {
    designer.api.session.update({
        order,
    });
    designer.state.update({
        selectedAgendaItemId: null,
        configurationStep: ConfigurationStep.Preview,
    });
    await designer.commit();
};

const configuredToolInAgendaLsessSessionLobby = async ({order}: {order: number}) => {
    const existingConverting = designer.currentAgendaItems().find((a) => a.processing);
    if (existingConverting) {
        designer.api.agendaItem.remove({
            id: existingConverting.id,
        });
    }

    designer.api.session.update({
        order,
    });
    designer.state.update({
        selectedAgendaItemId: null,
    });
    await designer.commit();
};

const configuredToolInAgendaLsessSessionLive = async ({
    currentAgendaItemId,
    order,
    skipPreview,
}: {
    currentAgendaItemId: string;
    order: number;
    skipPreview: boolean;
}) => {
    const existingConverting = designer.currentAgendaItems().find((a) => a.processing);
    if (existingConverting) {
        designer.api.agendaItem.remove({
            id: existingConverting.id,
        });
    }

    if (!skipPreview) {
        designer.state.setDesignerCommitState(true);

        designer.state.update({
            toolStep: null,
            configurationConfirm: ConfigurationConfirmType.Tool,
        });

        designer.api.agendaItem.update({
            id: currentAgendaItemId,
            agendaItem: {
                createdAt: new Date(),
                updatedAt: new Date(),
            },
        });

        const currentMicro = designer.currentAgendaItems().find((a) => a.id === currentAgendaItemId)?.artifact;
        if (currentMicro) {
            designer.api.artifact.updateArtifact({
                id: currentMicro.id,
                mockResult: true,
                artifact: {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                },
            });
        }

        const currentSessionId = designer.state.getSessionId();
        if (!currentSessionId) {
            return;
        }
        const existingSession = readSession({sessionId: currentSessionId});
        if (existingSession) {
            writeFromFullSession(currentSessionId, {
                session: {
                    ...existingSession,
                    order: order,
                },
            });
        }
    } else {
        designer.state.update({
            selectedAgendaItemId: null,
        });
        designer.commit();
    }
};

const unconfiguredToolOnAgendaItem = async ({shouldTogglePalette}: {shouldTogglePalette: boolean}) => {
    designer.state.update({
        selectedAgendaItemId: null,
    });
    if (shouldTogglePalette) {
        togglePalette();
    }
};

const unconfiguredToolInSessionWithAgenda = async ({
    currentAgendaItemId,
    shouldStoreOrder,
}: {
    currentAgendaItemId: string;
    shouldStoreOrder: boolean;
}) => {
    const agendaItems = designer.currentAgendaItems();

    const toRemove = agendaItems.filter((a) => a.id !== currentAgendaItemId);

    for (const agendaItem of toRemove) {
        designer.api.agendaItem.remove({
            id: agendaItem.id,
        });
    }

    designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        type: AgendaItemType.Instant,
        order: 0,
        doNotUpdateCreatePls: true,
        overrideOrder: true,
    });

    designer.api.agendaItem.update({
        id: currentAgendaItemId,
        agendaItem: {
            order: 1,
            processing: true,
        },
    });

    if (shouldStoreOrder) {
        orderBeforePreview(designer.currentSession()?.order);
    }

    designer.api.session.update({
        order: 0,
    });

    designer.state.update({
        configurationStep: ConfigurationStep.Preview,
    });

    await designer.commit();
};

const unconfiguredToolInAgendaLessSession = async ({currentAgendaItemId, order}: {currentAgendaItemId: string; order: number}) => {
    const existingConverting = designer.currentAgendaItems().find((a) => a.processing);
    if (existingConverting) {
        designer.api.agendaItem.remove({
            id: existingConverting.id,
        });
    }
    designer.api.agendaItem.update({
        id: currentAgendaItemId,
        agendaItem: {
            // order: -1,
            processing: true,
        },
    });

    designer.api.session.update({
        order: order - 1,
    });

    designer.state.update({
        selectedAgendaItemId: null,
    });

    await designer.commit();
};

export const createAgendaItemWithTool = async (arg: PaletteActionArg) => {
    if (!arg.artifactTag) {
        return;
    }
    await addAgendaItemWithTool(arg.artifactTag, undefined, {actionId: arg.actionId});
};

export const createAgendaItemWithResource = async (
    artifactTag: SimpleArtifactsTag,
    resourceId: string,
    type: ResourcesTypes,
    callback?: (artifactId: string) => Promise<void>,
    skipPreview?: boolean
) => {
    const cb = async (idOfArtifact: string) => {
        await setArtifactResourceResult({
            id: idOfArtifact,
            resourceSlotData: {
                type: type,
                config: {
                    resource: resourceId,
                },
            },
        });

        await callback?.(idOfArtifact);
    };
    await addAgendaItemWithTool(artifactTag, cb, {skipPreview});
};

export const createAgendaItemWithWhiteBoard = async (resxName?: string) => {
    const newRoomLink = new RandExp(/[0-9a-f]{20},[a-zA-Z0-9_-]{22}/).gen();

    const nameToCreate = resxName ? (resxName.length > 30 ? resxName.substring(0, 27) + "..." : resxName) : "New Whiteboard";

    const resourceLocator = await svc.resx.createResource("flowos/blackboard/resx/Blackboard", {
        name: nameToCreate,
        roomId: newRoomLink,
    });

    await createAgendaItemWithResource("flowos/blackboard", resourceLocator.id, "flowos/blackboard/resx/Blackboard");
};

export const importAgenda = async (agendaId: string, options?: {isEvent?: boolean; isRooms?: boolean}) => {
    const {isEvent, isRooms} = options ?? {};
    if (!isEvent) {
        designer.undoChanges({
            from: ["agendaItems", "macroArtifacts"],
        });
    }
    togglePalette();
    designer.state.setDesignerCommitState(true);

    if (!isEvent) {
        designer.state.update({
            configurationStep: ConfigurationStep.Preview,
        });
    }

    const templateData = await getTemplate(agendaId, true);

    const isSessionStarted = designer.currentSession()?.lifecycle === SessionLifecycle.Started;

    const template = templateData.data.agendaTemplate;

    const macroArtifacts = readMacroArtifacts()?.macroArtifacts;
    const hasAgenda = macroArtifacts?.findIndex((a) => a.artifactId === "flowos/agenda") !== -1;
    designer.api.session.detachAgenda({
        type: AgendaItemType.Instant,
    });
    if (!hasAgenda) {
        const agendaId = designer.api.artifact.addMacroArtifact({
            artifactName: "flowos/agenda",
            artifactTag: "flowos/agenda",
        });
    }

    if (!isEvent && !isRooms) {
        designer.state.setDesignerEditState(true);
        setLeftDrawerState(DrawerState.FullyOpen);
    }

    designer.api.session.importAgenda({
        session: {
            description: "",
            name: "",
            timeDependency: true,
            createdFromTemplateId: template?.id,
        },
        agendaItems: template?.agendaItems.length ? (template?.agendaItems as unknown as DeepMandatoryWithNullVals<AgendaItem>[]) : [],
        macroArtifacts: [],
        callback: () => {
            designer.state.update({
                configurationStep: ConfigurationStep.Preview,
                selectedAgendaItemId: null,
            });
            if (isSessionStarted) {
                designer.state.update({
                    configurationConfirm: ConfigurationConfirmType.Agenda,
                });
            }
            if (designer.currentSession()?.room) {
                designer.state.setDesignerCommitState(false);
            }

            if (!isSessionStarted && !designer.currentSession()?.room) {
                designer.commit({
                    isApplyingTemplate: true,
                    createdFromTemplateId: template?.id,
                });
            }
        },
        bypassResourcePublicCheck: template?.isPublic,
    });
};

export const createNewAgenda = (isMobileOrTablet?: boolean, isEvent?: boolean, dontTogglePalette?: boolean) => {
    if (!isEvent) {
        designer.undoChanges({
            from: ["agendaItems", "macroArtifacts"],
        });
    }

    if (!dontTogglePalette) {
        togglePalette();
    }

    const macroArtifacts = readMacroArtifacts()?.macroArtifacts;
    const hasAgenda = macroArtifacts?.findIndex((a) => a.artifactId === "flowos/agenda") !== -1;
    designer.api.session.detachAgenda({
        type: AgendaItemType.Instant,
    });
    if (!hasAgenda) {
        const agendaId = designer.api.artifact.addMacroArtifact({
            artifactName: "flowos/agenda",
            artifactTag: "flowos/agenda",
        });
    }
    const id = designer.api.agendaItem.create({
        ...designer.constants.agendaItemDefaults,
        title: "",
        type: AgendaItemType.Planned,
        duration: 0,
    });

    if (!isEvent) {
        designer.state.setDesignerEditState(true);
        designer.state.update({
            configurationStep: ConfigurationStep.Create,
        });

        setLeftDrawerState(DrawerState.FullyOpen);
    }
};

export const findResourceByKey = async (userId: string, type: string, key: string, value: string) => {
    const resources = await apollo.client.query({
        query: FindResourceByNameDocument,
        variables: {
            content: {
                contains: `"${key}":"${value}"`,
            },
            userId: {
                equals: userId,
            },
            type: {
                equals: type,
            },
        },
        fetchPolicy: "network-only",
    });

    const resource = resources.data.resources[0];

    if (resource == null) {
        return null;
    }

    return resource;
};
