import {cls} from "@ui/cdk/util/util";
import {useEffect, useState} from "@workhorse/api/rendering";
import classes from "../styles/AgendaBox.module.scss";
import {differenceInSeconds} from "date-fns";
import {isAgendaPausedVar} from "../../agenda-footer/AgendaNavigation";
import {useReactiveVar} from "@apollo/client";
import {memo} from "@workhorse/api/rendering";

type AgendaBoxTimeProps = {
    duration: number;
    timeSpentInSeconds: number;
    startAt: Date;
    isActive: boolean;
    className?: string;
    isPaused: boolean;
    onPause?: (isPaused: boolean, duration: number, startAt?: string | null) => void;
};

function formatTime(time: string | number) {
    return Number(time) < 10 ? `0${time}` : `${time}`;
}

const displayTime = (isActive: boolean, duration: number, currentAgendaItemDuration: number, timeSpentInSeconds: number) => {
    const passedHours = Math.floor(((isActive ? currentAgendaItemDuration : timeSpentInSeconds) % (60 * 60 * 24)) / (60 * 60))
        .toString()
        .padStart(2, "0");
    const passedMinutes = Math.floor(((isActive ? currentAgendaItemDuration : timeSpentInSeconds) % (60 * 60)) / 60)
        .toString()
        .padStart(2, "0");
    const passedSeconds = Math.floor((isActive ? currentAgendaItemDuration : timeSpentInSeconds) % 60)
        .toString()
        .padStart(2, "0");

    let seconds: string | number, minutes: string | number, hours: string | number;

    minutes = Math.floor(duration / 60);
    hours = Math.floor(duration / 3600);

    if (minutes >= 60) {
        minutes = minutes % 60;
    }

    seconds = formatTime(duration);
    minutes = formatTime(minutes);
    hours = formatTime(hours);

    if (isActive && !duration) {
        if (passedHours !== "00") {
            return <span className={classes.passedTime}>{`${passedHours}:${passedMinutes}:${passedSeconds}`}</span>;
        } else {
            return <span className={classes.passedTime}>{`${passedMinutes}:${passedSeconds}`}</span>;
        }
    } else if (!isActive && !duration) {
        if (currentAgendaItemDuration > 0) {
            return <span>{`${passedMinutes}:${passedSeconds}`}</span>;
        } else {
            return <span>{""}</span>;
        }
    } else if (isActive && duration) {
        if (hours !== "00") {
            return (
                <>
                    <span className={classes.passedTime}>{`${passedHours}:${passedMinutes}:${passedSeconds}`}</span>
                    <span className={classes.totalTime}>{`/ ${hours}:${minutes}:00`}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={classes.passedTime}>{`${passedMinutes}:${passedSeconds}`}</span>
                    <span className={classes.totalTime}>{`/ ${minutes}:00`}</span>
                </>
            );
        }
    } else if (!isActive && duration) {
        if (currentAgendaItemDuration > 0) {
            if (hours !== "00") {
                return <span>{`${passedHours}:${passedMinutes}:${passedSeconds} / ${hours}:${minutes}:00`}</span>;
            } else {
                return <span>{`${passedMinutes}:${passedSeconds} / ${minutes}:00`}</span>;
            }
        } else {
            if (hours !== "00") {
                return <span>{`${hours}:${minutes}:00`}</span>;
            } else {
                return <span>{`${minutes}:00`}</span>;
            }
        }
    }
};

const AgendaBoxTime = (props: AgendaBoxTimeProps) => {
    const {duration, timeSpentInSeconds = 0, startAt, isActive, className, isPaused} = props;

    const [currentAgendaItemDuration, setCurrentAgendaItemDuration] = useState(() => {
        return timeSpentInSeconds + (isPaused ? 0 : differenceInSeconds(new Date(), new Date(startAt ?? new Date())));
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (isActive && !isPaused) {
                setCurrentAgendaItemDuration(timeSpentInSeconds + differenceInSeconds(new Date(), new Date(startAt ?? new Date())));
            } else {
                setCurrentAgendaItemDuration(timeSpentInSeconds);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused]);

    return (
        <div className={cls("flex flex-align-center flex-center-all", classes.agendaBoxTime, className)}>
            <div className={cls(classes.agendaBoxTime)}>
                {displayTime(isActive, duration, currentAgendaItemDuration, timeSpentInSeconds)}
            </div>
        </div>
    );
};

export default memo(AgendaBoxTime);
