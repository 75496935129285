import * as Yup from "yup";
import {currencies} from "@sessions/currencies";

const paymentProviderSchema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
    accountName: Yup.string().optional().nullable(),
});

export const schema = Yup.object({
    id: Yup.string().trim(),
    enabled: Yup.boolean().required(),
    amount: Yup.number()
        .required()
        .max(2147483647 / 100, `Amount can’t be more than ${(2147483647 / 100).toLocaleString()}`),
    currency: Yup.string()
        .oneOf(currencies.map(({value}) => value))
        .required(),
    terms: Yup.string().optional(),
    ownerId: Yup.string().required(),
    paymentProviders: Yup.array(paymentProviderSchema) as Yup.ArraySchema<
        Yup.InferType<typeof paymentProviderSchema>[],
        typeof paymentProviderSchema,
        any,
        any,
        any,
        any
    >,
});

export type PaymentConfigSchema = Yup.InferType<typeof schema>;
