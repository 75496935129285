import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import InfoTooltip from "@workhorse/components/InfoTooltip";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as LockSessionIcon} from "../../assets/lock-session.svg";
import {useTranslation} from "react-i18next";

const styles = {...genericStyles};

type SessionLockSessionTogglerProps = {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    isRoom?: boolean;
};

const SessionLockSessionToggler = ({enabled, onChange, showIcon = false, disabled, className, isRoom}: SessionLockSessionTogglerProps) => {
    const {t} = useTranslation();
    const handleOnChange = () => onChange(!enabled);
    return (
        <div className={cls(styles.togglerWrapper, className)}>
            {showIcon ? (
                <div className={styles.iconWrapper}>
                    <LockSessionIcon />
                </div>
            ) : null}
            <TogglerContainer
                title={
                    <Typography variant="base" fontWeight="bold" color="secondary" className="flex flex-center-all">
                        {isRoom
                            ? t("player.settings.general.access.lock_room.title") ?? "Lock room"
                            : t("player.settings.general.access.lock_session.title") ?? "Lock session"}
                        {isRoom ? (
                            <InfoTooltip
                                key="info-lock-room"
                                title={"Lock room"}
                                arrow
                                placement="top"
                                openOnHover={true}
                                info={
                                    t("player.settings.general.access.lock_room.info_tooltip") ??
                                    "Locking your room improves the overall security of your session and keeps unwanted visitors out."
                                }
                                className="mt-auto ml-5"
                            ></InfoTooltip>
                        ) : null}
                    </Typography>
                }
                secondTitle={
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.access.lock_session.description")}
                    </Typography>
                }
                checked={enabled}
                name="lockSession"
                onChange={handleOnChange}
                togglePlacement="secondTitle"
                label=" "
                className={cls(styles.toggler)}
                disabled={disabled}
            />
        </div>
    );
};

export default SessionLockSessionToggler;
