let worker: Worker | null = null;

function b(a?): string {
    // @ts-expect-error
    return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
}
function uuid() {
    return b();
}

type Timers = {
    [K: string]: Function;
};

const intervalCallbacks: Timers = {};
const timeoutCallbacks: Timers = {};

export function setupTimerWorker() {
    if (!worker) {
        worker = new Worker("/timer-worker.js");
    }

    worker.onmessage = (message) => {
        const input = message.data;
        if (input.__sessions_timer_interval) {
            intervalCallbacks[input.key]?.();
        } else if (input.__sessions_timer_timeout) {
            timeoutCallbacks[input.key]?.();
        }
    };
}

export function setWorkerInterval(callback: Function, timer: number): string | null {
    if (!worker) {
        return null;
    }

    const key = uuid();
    intervalCallbacks[key] = callback;

    worker.postMessage({
        __sessions: true,
        key,
        timer,
        type: "setInterval",
    });

    return key;
}

export function clearWorkerInterval(key: string): boolean {
    if (!worker) {
        return false;
    }

    if (!intervalCallbacks[key]) {
        console.warn(`Interval with key ${key} does not exist`);
        return false;
    }

    worker.postMessage({
        __sessions: true,
        key,
        type: "clearInterval",
    });

    delete intervalCallbacks[key];
    // @ts-ignore
    key = null;

    return true;
}

export function setWorkerTimeout(callback: Function, timer: number): string | null {
    if (!worker) {
        return null;
    }

    const key = uuid();
    timeoutCallbacks[key] = callback;

    worker.postMessage({
        __sessions: true,
        key,
        timer,
        type: "setTimeout",
    });

    return key;
}

export function clearWorkerTimeout(key: string, canNotExist?: boolean): boolean {
    if (!worker) {
        return false;
    }

    if (!timeoutCallbacks[key]) {
        if (!canNotExist) {
            console.warn(`Timeout with key ${key} does not exist`);
        }
        return false;
    }

    worker.postMessage({
        __sessions: true,
        key,
        type: "clearTimeout",
    });

    delete timeoutCallbacks[key];
    // @ts-ignore
    key = null;

    return true;
}
