import {differenceInMinutes, isValid} from "date-fns";
import {timezones, AcceptedTimezone} from "@generated/timezones";
import {fromZonedTime, formatInTimeZone, getTimezoneOffset as getTzOffset} from "date-fns-tz";
import {getTimezone, getCountryForTimezone} from "countries-and-timezones";
export * from "@generated/timezones";
export * from "countries-and-timezones";

function getCorrectLocalTimezone(timezone: string) {
    switch (timezone) {
        case "Asia/Rangoon":
            return "Asia/Yangon";
        case "Asia/Calcutta":
            return "Asia/Kolkata";
        case "Asia/Katmandu":
            return "Asia/Kathmandu";
        case "America/Indianapolis":
            return "America/Indiana/Indianapolis";
        case "America/Louisville":
            return "America/Indiana/Indianapolis";
        case "America/Buenos_Aires":
            return "America/Argentina/Buenos_Aires";
        case "America/Godthab":
            return "America/Nuuk";
        case "Asia/Saigon":
            return "Asia/Ho_Chi_Minh";
        default:
            return timezone;
    }
}

let tzLogged = false;

/**
 * The generated list seems not to fit it's purpuse so will try to add some fallbacks:
 * 1. We'll check local list for timezone
 * 2. We'll fallback to countries-and-timezones package;
 * 3. We'll default to browser data;
 */

export function getLocalTimezone(list?: AcceptedTimezone[]): AcceptedTimezone {
    const local = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let output: AcceptedTimezone = local as AcceptedTimezone;

    const correctLocal = getCorrectLocalTimezone(local);
    const searchResult = (list ?? Object.keys(timezones)).find((zone) => zone === correctLocal);

    if (searchResult) {
        output = searchResult as AcceptedTimezone;
    } else {
        // No timezone data, let's search the list;
        const timezoneData = getTimezone(local);

        if (timezoneData) {
            output = (timezoneData.aliasOf ?? timezoneData.name) as AcceptedTimezone;
        }
    }

    if (!tzLogged) {
        tzLogged = true;
        console.log(`getLocalTimezone intl.API.result=${local}, output=${output}`);
    }

    return output;
}

export const getTimezoneOffsetString = (tzKey: string, date: Date) => {
    let offset = getTzOffset(tzKey, date);

    if (offset === 0) {
        return "UTC";
    }

    const hours = offset / 60;
    const minutes = offset % 60;

    return `${offset < 0 ? "-" : ""}${hours > 9 ? `0${Math.abs(hours)}` : `${Math.abs(hours)}`}:${
        minutes !== 0 ? Math.abs(minutes) : "00"
    }`;
};

// Since the timezones list may not contain the computed key, we need to create the string on-demand.
// Plus the cached list does not take into considaration DST offsets.

export const toTimezoneString = (key: AcceptedTimezone, date: Date = new Date()) => {
    if (!isValid(date)) {
        date = new Date();
    }

    const data = getTimezone(key);

    if (!data) {
        const offset = getTimezoneOffsetString(key, date);
        return `UTC${offset ? `${offset} ` : ""}${key}`;
    }

    const listValue = timezones[key];
    const isDST = data.dstOffset && data.utcOffset !== data.dstOffset && Math.abs(date.getTimezoneOffset()) === Math.abs(data.dstOffset);

    return `UTC${isDST ? data.dstOffsetStr : data.utcOffset !== 0 ? data.utcOffsetStr : ""} ${
        listValue ? listValue.slice(10) : `${getCountryForTimezone(data.name)?.name}`
    }${isDST ? `, DST` : ""}`;
};

export type TimezoneDiffCb = (d1: Date, d2: Date) => number;

/**
 * given a timezone, we compute an offset in minutes relative to our local timezone
 */
export function getTimezoneOffset(timezone: AcceptedTimezone, previousTimezone?: AcceptedTimezone): number {
    return getTimezoneDifference(previousTimezone || getLocalTimezone(), timezone);
}

export function getTimezoneDifference(tz1: AcceptedTimezone, tz2: AcceptedTimezone, diff: TimezoneDiffCb = differenceInMinutes): number {
    const now = new Date();
    return diff(toTimezone(now, tz1), toTimezone(now, tz2));
}

export function toTimezone(date: Date, timezone: string) {
    return fromZonedTime(date, timezone);
}

// It is not reliable to check dst based on the user's timezone as it may not be in one where dst is applicable.
// Also we need to make the check based on a specific date in order to correctly determine if we should take DST offset into consideration or not;
// Since in the southern hemisphere DST hapens from around Sept - Apr, we cannot rely on month range to decide if date is in DST.
// The only thing we can be sure of is that DST time is ahead of standard GTM time.

export const isDST = (date: Date, tz: AcceptedTimezone = getLocalTimezone()) => {
    // Setting dates
    const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const janDate = new Date(date.getFullYear(), 0, 1);
    const julDate = new Date(date.getFullYear(), 6, 1);

    // Add midday hours in utc to observe the difference
    currentDate.setUTCHours(12);
    janDate.setUTCHours(12);
    julDate.setUTCHours(12);

    // Format shifted dates in wanted timezone
    const current = formatInTimeZone(currentDate, tz, "HH:mm");
    const jan = formatInTimeZone(janDate, tz, "HH:mm");
    const jul = formatInTimeZone(julDate, tz, "HH:mm");

    const hasDST = jan !== jul;

    return hasDST && ((current > jan && current === jul) || (current > jul && current === jan));
};

export const getTimezoneDisplayName = (date: Date, timezone: AcceptedTimezone, name: "partial" | "full" = "partial") => {
    const details = getTimezone(timezone);

    const utcStr = isDST(date, timezone)
        ? details.dstOffset === 0
            ? ""
            : details.dstOffsetStr
        : details.utcOffset === 0
        ? ""
        : details.utcOffsetStr;

    const tzName = `${(name === "partial" ? timezone.substring(timezone.lastIndexOf("/") + 1) : timezone)?.replaceAll(
        "_",
        " "
    )}, UTC${utcStr}`;

    return tzName;
};

export const getAcceptedTimezonesList = (date?: Date) => {
    try {
        const forDate = date && isValid(date) ? date : new Date();
        const accepetedTimezones = Object.keys(timezones) as AcceptedTimezone[];

        return accepetedTimezones.reduce((agg, key) => {
            const details = getTimezone(key);
            const utcStr = isDST(forDate, key)
                ? details.dstOffset === 0
                    ? ""
                    : details.dstOffsetStr
                : details.utcOffset === 0
                ? ""
                : details.utcOffsetStr;
            agg[key] = `UTC${utcStr} ${timezones[key].slice(10)}`;
            return agg;
        }, {} as Record<AcceptedTimezone, string>);
    } catch (error) {
        console.error(error);
        console.log("error parsing timezones for list", date);
        return timezones;
    }
};

export const getTimezoneDTSOffset = (timezone: AcceptedTimezone, date?: Date) => {
    const forDate = date && isValid(date) ? date : new Date();
    const details = getTimezone(timezone);
    return isDST(forDate, timezone) ? details.dstOffset : details.utcOffset;
};
