import {SessionLifecycle, ParticipantInviteStatus, CalendarEventCustomFragment} from "@generated/data";
import {EventInstantOrPlannedFilter, EventsFilters} from "./definitions";

const filterEventsByInstantOrPlanned = (events: CalendarEventCustomFragment[], filters?: EventInstantOrPlannedFilter[]) => {
    if (!filters?.length || filters.length === 2) {
        return events;
    }
    if (filters[0] === EventInstantOrPlannedFilter.Instant) {
        return events.filter((event) => event.quickSession);
    }
    if (filters[0] === EventInstantOrPlannedFilter.Planned) {
        return events.filter((event) => !event.quickSession);
    }
    return [];
};

const filterEventsByParticipantsEmails = (events: CalendarEventCustomFragment[], emails?: string[]) => {
    if (!emails || emails.length === 0) {
        return events;
    }
    return events.filter((event) => event.participants.map((p) => p.email).find((email) => email && emails.indexOf(email) !== -1));
};

const filterEventsByLifecycle = (
    events: CalendarEventCustomFragment[],
    lifecycleFilters?: SessionLifecycle[]
): CalendarEventCustomFragment[] => {
    if (!lifecycleFilters) {
        return events;
    }
    return events.filter((event) => lifecycleFilters?.indexOf(event.lifecycle) !== -1);
};

const filterEventsByInviteStatus = (events: CalendarEventCustomFragment[], participantInviteStatuses?: ParticipantInviteStatus[]) => {
    if (!participantInviteStatuses) {
        return events;
    }
    return events.filter((event) => participantInviteStatuses.indexOf(event.currentParticipantInviteStatus) !== -1);
};

export const filterEvents = (events: CalendarEventCustomFragment[], filters: EventsFilters) => {
    return filterEventsByLifecycle(
        filterEventsByParticipantsEmails(
            filterEventsByInstantOrPlanned(
                filterEventsByInviteStatus(events, filters.participantInviteStatuses),
                filters.instantOrPlannedFilters?.filters
            ),
            filters.participantsEmails
        ),
        filters.lifecycleFilters
    );
};
