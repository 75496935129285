import InfoIcon from "@material-ui/icons/Info";
import Alert from "@material-ui/lab/Alert";
import {cls} from "@ui/cdk/util";
import Backdrop from "@ui/core/components/Backdrop";
import Snackbar from "@ui/core/components/Snackbar";
import AlertTitle from "@ui/lab/components/AlertTitle";
import React from "@workhorse/api/rendering";
import {ToastObject} from "../../api/toast";
import {ReactComponent as ErrorIcon} from "../../assets/media/toast-error.svg";
import {ReactComponent as SuccessIcon} from "../../assets/media/toast-success.svg";
import {ReactComponent as WarningIcon} from "../../assets/media/Warning.svg";
import classes from "./styles/Toast.module.scss";

type ToastProps = Partial<ToastObject> & {
    onClose: (toastId: string) => void;
};

const Toast = (props: ToastProps) => {
    const {content, type, uid, className, title, icon: PropsIcon, preventClose} = props;

    const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
        props.onClose(uid || "");
    };

    let icon;
    switch (type) {
        case "success":
            icon = <SuccessIcon />;
            break;
        case "error":
            icon = <ErrorIcon />;
            break;
        case "warning":
            icon = (
                <span className={classes.warning}>
                    <WarningIcon />
                </span>
            );
            break;
        case "networkIssue":
            icon = <span className={cls(classes.warning, classes.warningNetworkIssue)}>{PropsIcon ?? <WarningIcon />}</span>;
            break;
        default:
            icon = (
                <span className={classes.info}>
                    <InfoIcon />
                </span>
            );
            break;
    }

    return (
        <>
            {preventClose ? <Backdrop open={true} className={classes.toastBackdrop} /> : null}
            <Snackbar
                className={cls(classes.snackbar, className, type === "networkIssue" && classes.networkIssues)}
                open={true}
                onClose={handleDelete}
                ClickAwayListenerProps={{
                    mouseEvent: false,
                }}
            >
                <Alert
                    data-id="toast-message"
                    icon={!title && icon}
                    onClose={handleDelete}
                    className={cls(classes.alert, title && classes.withTitle)}
                    role="alert"
                    aria-live="assertive"
                    action={preventClose ? <i className="hidden-root"></i> : undefined}
                >
                    {title && (
                        <AlertTitle>
                            {icon}
                            {title}
                        </AlertTitle>
                    )}
                    {content}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Toast;
