import {HostType} from "@generated/data";

export {default as BASE} from "@workhorse/pages/base";
export {default as BOOK} from "@workhorse/pages/book";
export {default as BOOKING} from "@workhorse/pages/booking";
export {default as NEW_BOOKING} from "@workhorse/pages/newBooking";
export {default as BOOKED_EVENT} from "@workhorse/pages/bookedEvent";
export {default as CONNECT} from "@workhorse/pages/connect";
export {default as CONTACTS} from "@workhorse/pages/contacts";
export {default as NOTFOUND} from "@workhorse/pages/default";
export {default as DISCONNECT} from "@workhorse/pages/disconnect";
export {default as EVENT} from "@workhorse/pages/event";
export {default as EVENTS} from "@workhorse/pages/events";
export {default as FEEDBACK} from "@workhorse/pages/feedback";
export {default as HOME} from "@workhorse/pages/home";
export {default as MEMORY} from "@workhorse/pages/memory";
export {default as DESIGNER, default as PLAYER} from "@workhorse/pages/player";
export {default as RESOURCES} from "@workhorse/pages/resources";
export {default as FILES} from "@workhorse/pages/resources/files";
export {default as SESSIONENDED} from "@workhorse/pages/session-ended";
export {default as TEMPLATES} from "@workhorse/pages/templates";
export {default as USER} from "@workhorse/pages/user";
export {default as DEVICETEST} from "@workhorse/pages/device-test";
export {default as ROOMS} from "@workhorse/pages/rooms";
export {default as UNAUTHORIZED} from "@workhorse/pages/unauthorized";
export {default as UNABLE_TO_JOIN} from "@workhorse/pages/unable-to-join";
export {default as CHOOSE_ORGANIZATION} from "@workhorse/pages/choose-organization";
export {default as ORGANIZATION_INVITE} from "@workhorse/pages/organization-invite";
export {default as PAYMENTS} from "@workhorse/pages/payments";
//workspace bookings
export {default as BOOKINGS} from "@workhorse/pages/bookings";

type Routes = {
    [K in Exclude<HostType, HostType.Test | HostType.Studio | HostType.Unsubscribe | HostType.Buildup | HostType.Designer>]: {
        path: string | Array<string>;
        guestAllowed: boolean;
        isPublic?: boolean;
        exact?: boolean;
    };
};

type PublicRoutes = {
    [K in HostType.Book]: {
        path: string | Array<string>;
    };
};

// please match the keys in this object to the aliased exports above
export const routes: Routes = {
    [HostType.Home]: {
        path: ["/", "/?create&templateId=:templateId"],
        guestAllowed: false,
    },

    [HostType.Base]: {
        path: "/base",
        guestAllowed: false,
    },

    [HostType.Booking]: {
        path: ["/booking", "/booking/:bookingId"],
        guestAllowed: false,
    },

    [HostType.NewBooking]: {
        path: "/booking-event/:eventId?",
        guestAllowed: false,
    },
    [HostType.Bookings]: {
        path: "/bookings/:workspaceId",
        guestAllowed: true,
        isPublic: true,
    },
    [HostType.Book]: {
        path: [
            "/book/:bookingEventPrefix",
            "/book/:bookingEventPrefix/:bookingEventId",
            "/book/:bookingEventPrefix/successful/:bookingId",
            "/book/:bookingEventPrefix/:bookingEventId/successful/:bookingId",
        ],
        guestAllowed: true,
        isPublic: true,
    },
    [HostType.BookedEvent]: {
        path: [
            "/booked-event/:bookedEventId/:bookedEventAction",
            "/booked-event/:bookedEventId/:bookedEventAction/successful",
            "/booked-event/:bookedEventId/:bookedEventAction/successful?token=:token",
        ],
        guestAllowed: true,
        isPublic: true,
    },

    [HostType.Player]: {
        path: ["/session/:sessionId?", "/join/:sessionId?", "/room/:roomSlug+"],
        guestAllowed: true,
    },

    [HostType.Templates]: {
        path: ["/templates", "/templates/:section", "/templates/:section/:templateId?", "/templates/:section?create=:create"],
        guestAllowed: false,
    },

    [HostType.Memory]: {
        path: ["/memory", "/memory/session/:sessionId?"],
        guestAllowed: true,
        isPublic: true,
    },

    [HostType.Events]: {
        path: ["/events", "/events/:eventId?", "/events/landingPage/:eventId?"],
        guestAllowed: false,
    },

    [HostType.Files]: {
        path: ["/files", "/files/:resourceType", "/files/:resourceType/:resourceId"],
        guestAllowed: false,
    },

    [HostType.Resources]: {
        path: ["/resources", "/resources/:resourceType", "/resources/:resourceType/:resourceId"],
        guestAllowed: false,
    },

    [HostType.Contacts]: {
        path: "/contacts",
        guestAllowed: false,
    },

    [HostType.User]: {
        path: "/user/*",
        guestAllowed: false,
    },

    [HostType.Connect]: {
        path: "/connect/:writeKey",
        guestAllowed: true,
    },

    [HostType.Disconnect]: {
        path: "/disconnect",
        guestAllowed: true,
    },

    [HostType.Feedback]: {
        path: "/feedback/:sessionId?/:participantId?",
        guestAllowed: true,
    },

    [HostType.Sessionended]: {
        path: "/session-ended/:sessionId?",
        guestAllowed: true,
    },

    [HostType.Event]: {
        path: "/event/:id?",
        guestAllowed: false,
        exact: false,
    },

    [HostType.Unauthorized]: {
        path: ["/unauthorized/event", "/unauthorized/event/:id", "/unauthorized"],
        guestAllowed: true,
        exact: false,
    },

    [HostType.UnableToJoin]: {
        path: ["/unable-to-join/:sessionId"],
        guestAllowed: true,
        exact: false,
    },

    [HostType.Devicetest]: {
        path: "/device-test",
        guestAllowed: false,
    },

    [HostType.Rooms]: {
        path: [
            "/rooms",
            "/rooms/:sessionId",
            "/rooms/:sessionId/agenda",
            "/rooms/:sessionId/settings",
            "/rooms/:roomId/instances",
            "/rooms/:sessionId/widgets",
            "/rooms/:sessionId/automations",
            "/rooms/:sessionId/permissions",
            "/rooms/:sessionId/recording",
            "/rooms/:sessionId/exit-page",
        ],
        guestAllowed: false,
    },

    [HostType.ChooseOrganization]: {
        path: "/choose-organization",
        guestAllowed: false,
    },
    [HostType.OrganizationInvite]: {
        path: "/organization-invite/:organizationId",
        guestAllowed: true,
    },

    [HostType.Payments]: {
        path: ["/payments/paypal/capture", "/payments/paypal/cancel"],
        guestAllowed: true,
        isPublic: true,
    },

    // deprecated
    [HostType.Waiting]: {
        path: "/waiting/:sessionId?",
        guestAllowed: true,
    },

    [HostType.Notfound]: {
        path: "*",
        guestAllowed: true,
    },
};
