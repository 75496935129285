import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {cls} from "@ui/cdk/util/util";
import Popover, {PopoverProps} from "@ui/core/components/Popover";
import React from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {dateIsValid} from "@workhorse/util";
import {cleanDate} from "@workhorse/util/generateSessionStartDate";
import {format, isSameDay, isSameYear, isThisYear, isToday, isValid} from "date-fns";
import {useTranslation} from "react-i18next";
import {ReactComponent as CalendarIcon} from "./assets/date-picker-calendar-icon.svg";
import Calendar from "./components/Calendar/Calendar";
import classes from "./components/styles/DatePicker.module.scss";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import {getDateFnsLocale, localeShortMonthDayDateFnsFormat} from "@sessions/common/utils/languages";

export type OnChangeArgs = {
    startDate: Date;
    endDate?: Date;
};

export type DatePickerProps = {
    startDate?: Date;
    endDate?: Date;
    onChange: (date: OnChangeArgs) => void;
    allowDatesInThePast?: boolean;
    showSpacer?: boolean;
    withoutToggler?: boolean;
    minDate?: Date;
    showStartAndEndOfOtherMonths?: boolean;
    forTimezone: string;
    localTimezone: string;
    displayDate?: boolean;
    className?: string;
    classes?: {
        calendar?: string;
        placeholder?: string;
        dateInput?: string;
    };
    dataId?: string;
    disabled?: boolean;
};

const DatePicker = (props: DatePickerProps) => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language;
    const {
        startDate,
        endDate,
        onChange,
        allowDatesInThePast = false,
        showSpacer,
        withoutToggler,
        minDate,
        className,
        showStartAndEndOfOtherMonths,
        forTimezone,
        localTimezone,
        displayDate,
        dataId,
        disabled,
    } = props;
    const dateFrom = startDate;
    const dateTo = endDate;
    const {isTablet} = useMobile();
    const onChangeDateFrom = (date: Date) => {
        if (date) {
            if (dateFrom && dateTo) {
                onChange({
                    startDate: date,
                    endDate: cleanDate(new Date(date.getTime() + (dateTo.getTime() - dateFrom?.getTime()))),
                });
            }
            if (!dateTo) {
                onChange({
                    startDate: date,
                });
            }
        }
    };

    const dateChange = (startDate: Date) => {
        onChangeDateFrom(startDate);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const popoverPosition: Pick<PopoverProps, "transformOrigin" | "anchorOrigin"> = displayDate
        ? {
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              },
              transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
              },
          }
        : {
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
              },
          };

    return (
        <div className={cls(className, classes.picker, props.classes?.dateInput)}>
            {withoutToggler ? (
                <Calendar
                    startDate={dateFrom}
                    endDate={dateTo}
                    onDateChange={dateChange}
                    closePopup={handleClose}
                    disablePastDates
                    className={cls(classes.smallCalendar, props.classes?.calendar)}
                    showStartAndEndOfOtherMonths={showStartAndEndOfOtherMonths}
                    bigger
                />
            ) : (
                <>
                    {displayDate ? (
                        <div
                            data-id={dataId}
                            className={cls(
                                props.classes?.placeholder,
                                anchorEl && classes.activeEl,
                                "flex flex-row flex-align-center flex-justify-between"
                            )}
                            onClick={disabled ? undefined : handleClick}
                        >
                            {!isTablet && <CalendarIcon />}

                            {dateFrom && dateIsValid(dateFrom)
                                ? format(new Date(dateFrom), localeShortMonthDayDateFnsFormat[i18n.language], {
                                      locale: getDateFnsLocale(i18n.language),
                                  })
                                : "dd/mm/yyyy"}
                        </div>
                    ) : (
                        <div
                            data-id={dataId}
                            className={cls(classes.calendarButton, anchorEl ? `${classes.selectedCalendar} active` : "")}
                            onClick={disabled ? undefined : handleClick}
                        >
                            {dateFrom && dateIsValid(dateFrom) ? (
                                <>
                                    {isToday(dateFrom)
                                        ? (t("g.time.today") ?? "Today") +
                                          `${
                                              dateTo && !isSameDay(dateFrom, dateTo)
                                                  ? format(dateTo!, ` - eee, MMM dd`, {
                                                        locale: getDateFnsLocale(lang),
                                                    })
                                                  : ""
                                          }`
                                        : format(
                                              dateFrom!,
                                              `${dateFrom && dateTo && !isSameDay(dateFrom, dateTo) ? "E" : "iiii"}, ${
                                                  dateFrom && dateTo && !isSameDay(dateFrom, dateTo) ? "MMM" : "MMMM"
                                              } dd${
                                                  dateFrom &&
                                                  dateTo &&
                                                  ((!isThisYear(dateTo) && isSameDay(dateFrom, dateTo)) || !isSameYear(dateFrom, dateTo))
                                                      ? ", yyyy"
                                                      : ""
                                              }`,
                                              {
                                                  locale: getDateFnsLocale(lang),
                                              }
                                          )}{" "}
                                    {!isToday(dateFrom!) && dateFrom && dateTo && !isSameDay(dateFrom, dateTo)
                                        ? " - " +
                                          format(
                                              dateTo!,
                                              `E, LLL dd${!isThisYear(dateFrom) || !isSameYear(dateFrom, dateTo) ? ", yyyy" : ""}`,
                                              {
                                                  locale: getDateFnsLocale(lang),
                                              }
                                          )
                                        : ""}
                                </>
                            ) : (
                                t("cdk.time_picker.choose_date") ?? "Choose date"
                            )}
                        </div>
                    )}

                    {showSpacer ? <FiberManualRecordIcon className={classes.dot}>.</FiberManualRecordIcon> : null}

                    <Popover
                        id={"calendar-popover"}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        classes={{
                            paper: classes.popoverPaper,
                        }}
                        {...popoverPosition}
                    >
                        <Calendar
                            startDate={isValid(dateFrom) ? dateFrom : undefined}
                            endDate={isValid(dateTo) ? dateTo : undefined}
                            onDateChange={dateChange}
                            closePopup={handleClose}
                            minDate={minDate}
                            disablePastDates
                            showStartAndEndOfOtherMonths={showStartAndEndOfOtherMonths}
                            className={cls(classes.smallCalendar, props.classes?.calendar)}
                        />
                    </Popover>
                </>
            )}
        </div>
    );
};

export default DatePicker;
