import {ArtifactTag} from "@generated/artifacts";
import {AgendaItemType} from "@generated/data";
import {AgendaItemArtifact} from "./actions/agenda-item/add-artifact-to-agenda-item";
import {CreateAgendaItemInput} from "./actions/agenda-item/create-agenda-item";
import {getEmptyValue, parseAsTipTapJSON} from "@api/editor";

export const DUMMY_AGENDA_ITEM_TITLE = "________auto________";
export function makeDefaultConferenceArtifact(artifactUuid: string, agendaItemId: string) {
    const now = null as unknown as Date;
    return <AgendaItemArtifact>{
        __typename: "Artifact",
        agendaItems: [
            {
                __typename: "AgendaItem",
                id: agendaItemId,
            },
        ],
        artifactId: "flowos/conference" as ArtifactTag,
        data: {
            // muteEveryone: "false",
        },
        description: "default conference artifact",
        durationInSeconds: 900,
        endedAt: null,
        files: [],
        id: artifactUuid,
        oldId: artifactUuid,
        isConfigured: true,
        isMacro: false,
        isSystemArtifact: false,
        name: "conference",
        order: null,
        properties: [],
        resourceResults: [],
        startAt: null,
        updatedAt: now,
        createdAt: now,
        userConfiguredDuration: false,
        isDeleted: false,
        update: null,
    };
}

export const agendaItemDefaults: Omit<CreateAgendaItemInput, "createdAt" | "updatedAt" | "id" | "oldId"> = {
    title: DUMMY_AGENDA_ITEM_TITLE,
    descriptionJson: getEmptyValue(),
    __typename: "AgendaItem",
    duration: 0,
    order: 0,
    startAt: null,
    type: AgendaItemType.Instant,
    userConfiguredDuration: false,
    timeSpentInSeconds: null,
    processing: false,
    isDeleted: false,
    update: null,
    endedAt: null,
    locked: null,
    agendaItemSpeakers: [],
    speakerNotes: "",
    isPaused: false,
    speakerNotesJson: parseAsTipTapJSON(""),
};

export const storageKey = {
    SESSION: "designer-last-session",
    STATE: "designer-state",
    SESSION_ID: "designer-session-id",
    EDIT_STATE: "designer-edit-state",
};

export const diffConstant = {
    isDeleted: null,
    oldId: null,
    update: null,
};

export const ERROR_NO_SESSION_ID = "cannot_commit_session_without_id";
