import {AutocompleteProps} from "@ui/cdk/Autocomplete";
import {InputProps} from "@ui/cdk/Input";
import React, {forwardRef, useMemo} from "@workhorse/api/rendering";
import TimeInputAutocomplete from "./TimeInputAutocomplete";
import {dateOptions} from "../../utils";
import {WithClassName} from "@workhorse/declarations";

export type TimeInputProps = WithClassName & {
    value: Date;
    onChange: (value: Date) => void;
    onInputChange?: AutocompleteProps["onInputChange"];
    allowDatesInThePast?: boolean;
    small?: boolean;
    dateFormat: "12h" | "24h";
    fullWidth?: boolean;
    label?: InputProps["label"];
    error?: boolean;
    isEnd?: boolean;
    hourList: {
        opts: Array<dateOptions>;
        defaultOption: dateOptions;
        end?: dateOptions | undefined;
    };
    classes?: {
        autocompletePaper?: string;
    };
    offsetInMin: number;
    disableGrouping?: boolean;
    onClose?: () => void;
    disabled?: boolean;
};

const TimeInput = forwardRef((props: TimeInputProps, ref: any) => {
    const {
        offsetInMin,
        value,
        onChange,
        onInputChange,
        allowDatesInThePast,
        small,
        dateFormat,
        fullWidth,
        label,
        error,
        isEnd,
        hourList,
        disableGrouping,
        disabled,
    } = props;

    const onChangeTime = (newValue: dateOptions) => {
        // the else makes sure the user won't be stuck in the autocomplete part of the picker
        if (newValue.date) {
            onChange(newValue.date);
        } else {
            onChange(value);
        }
    };

    const onChangeInput: AutocompleteProps["onInputChange"] = (e, newValue, reason) => {
        if (onInputChange) {
            onInputChange(e, newValue, reason);
        }
    };

    const defaultValue: Date = useMemo(() => {
        return hourList.defaultOption?.date;
    }, [value, hourList.defaultOption?.date]);

    return (
        <TimeInputAutocomplete
            id={`time-${isEnd ? "end" : "start"}-autocomplete`}
            key={`time-${isEnd ? "end" : "start"}-autocomplete`}
            options={hourList.opts}
            dateFormat={dateFormat}
            value={defaultValue}
            onChange={onChangeTime}
            onChangeInput={onChangeInput}
            allowDatesInThePast={allowDatesInThePast}
            small={small}
            halfWidth={fullWidth}
            disabled={disabled}
            error={error}
            label={isEnd ? undefined : label}
            isEnd={isEnd}
            className={props.className}
            classes={{
                paper: props.classes?.autocompletePaper,
            }}
            ref={ref}
            offsetInMin={offsetInMin}
            disableGrouping={disableGrouping}
            onClose={props.onClose}
        />
    );
});

export default TimeInput;
