import {GetBookingEventsCountDocument, GetRemoteUserDocument, SwitchWorkspaceDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import toast from "@workhorse/api/toast";

export const switchWorkspaceWithToast = async (workspaceId: string) => {
    const existingUser = apollo.cache.readQuery({
        query: GetRemoteUserDocument,
    });

    const currentWorkspaceId = existingUser?.getRemoteUser?.user?.workspacePermissions.find(
        (workspace) => workspace.activeWorkspace === true
    )?.workspace.id;

    await apollo.client
        .mutate({
            mutation: SwitchWorkspaceDocument,
            variables: {workspaceId},
            refetchQueries: [
                {
                    query: GetBookingEventsCountDocument,
                },
            ],
        })
        .then((res) => {
            if (res.data?.switchWorkspace) {
                const userInfo = apollo.cache.readQuery({
                    query: GetRemoteUserDocument,
                });

                const workspaceName = userInfo?.getRemoteUser?.user?.workspacePermissions.find(
                    (workspace) => workspace.activeWorkspace === true
                )?.workspace.name;
                const switchedWorkspaceId = userInfo?.getRemoteUser?.user?.workspacePermissions.find(
                    (workspace) => workspace.activeWorkspace === true
                )?.workspace.id;
                const isSameWorkspace = currentWorkspaceId === switchedWorkspaceId;

                if (workspaceName && !isSameWorkspace) {
                    toast(`Switched to ${workspaceName} workspace.`, {type: "info"});
                }
            }
        })
        .catch((err) => {
            console.log("Error on switching workspace: ", err);
            toast(`An error has occurred while trying to switch your workspace. Please try again.`, {type: "error"});
        });
};
