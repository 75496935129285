import {CustomResolvers} from "./types";

export const SessionWatchdogUpdateResult: CustomResolvers["SessionWatchdogUpdateResult"] = {
    deletedAgendaItemIds: {
        merge: false,
    },
    deletedParticipantIds: {
        merge: false,
    },
    session: {
        merge: false,
    },
    upsertAgendaItems: {
        merge: false,
    },
    deletedMacroArtifactIds: {
        merge: false,
    },
    upsertMacroArtifacts: {
        merge: false,
    },
    upsertParticipants: {
        merge: false,
    },
};
