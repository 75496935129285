import Button from "@ui/cdk/Button";
import classes from "./style/FeedbackButton.module.scss";
import {capitalize, cls} from "@ui/cdk/util";
import {reportDialogEnabled} from "./reportProblem/reportDialogApi";
import {useTranslation} from "react-i18next";
import {useState, useRef} from "@workhorse/api/rendering";
import env from "@generated/environment";

import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import Fade from "@ui/core/components/Fade";

import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

import {ReactComponent as BookDemoIcon} from "../assets/media/book-demo.svg";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import MapRoundedIcon from "@material-ui/icons/MapRounded";
import ForumRoundedIcon from "@material-ui/icons/ForumRounded";
import FeedbackIcon from "@material-ui/icons/Feedback";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";

import {getResourcesBaseLink} from "@workhorse/api/help/article";

import {GetPublicBookingEventDocument} from "@generated/data";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import {BookingViewProvider} from "@workhorse/api/booking";
import {useQuery} from "@workhorse/api/data";
import {BookEvent} from "@workhorse/pages/book/components/BookEvent";
import {useOrganizationAccess} from "@workhorse/api/access/hooks";
import {getLocalTimezone} from "@common/utils/timezones";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {openExternalLink} from "@workhorse/util/links";

type FeedbackButtonProps = {
    className?: string;
    separator?: "none" | "left" | "right" | "both" | "leftWithSiblings" | "rightWithSiblings";
};

function FeedbackButton(props: FeedbackButtonProps) {
    const {className, separator = "leftWithSiblings"} = props;
    const {t} = useTranslation();
    const {isMobile} = useMobile();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);

    const [onBooking, setOnBooking] = useState(false);
    const hostType = useHostType();

    const bookingDialogRef = useRef<DialogImperativeRef>();

    const organizationAccess = useOrganizationAccess();
    const isOrganizationOwner = organizationAccess.canCreateWorkspaces();

    const {data: bookedEventData, loading} = useQuery(GetPublicBookingEventDocument, {
        variables: {
            // Booking with us
            slug: "p-fe6f2ba4-85a5-4301-8dd7-be647f9f0310",
            timezone: getLocalTimezone() as string,
        },
        skip: !onBooking,
        fetchPolicy: "network-only",
    });

    const handleClickBookDemo = () => {
        setOnBooking(true);
    };

    const handleCloseBookDemo = () => {
        setOnBooking(false);
    };

    const handleOpenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setMenuAnchorEl(e.currentTarget);
    };

    const handleShowFeedback = () => {
        reportDialogEnabled(true);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleClickHelpCenter = () => {
        openExternalLink(getResourcesBaseLink(""));
        handleCloseMenu();
    };

    const handleClickRoadmap = () => {
        openExternalLink("https://portal.productboard.com/sessions/1-roadmap/tabs/3-in-progress");
        handleCloseMenu();
    };

    // const handleClickReportProblem = () => {
    //     window.open("mailto:support@sessions.us?subject=Sessions Feedback", "_blank");
    //     handleCloseMenu();
    // };

    // const handleClickContactBusiness = () => {
    //     window.open("mailto:contact@sessions.us?subject=Sessions Inquiry", "_blank");
    //     handleCloseMenu();
    // };

    const separatorClassName = classes[`root${capitalize(separator)}`];

    const handleClickJoinCommunity = () => {
        openExternalLink(env.slack.workspace ?? "https://app.sessions.us");
        handleCloseMenu();
    };

    return (
        <>
            <div className={cls(classes.root, separatorClassName, className)}>
                <div className={classes.separator} />
                <Button
                    size="small"
                    variant="tertiary"
                    className={cls(classes.button, menuOpen && classes.buttonActive)}
                    onClick={handleOpenMenu}
                    aria-label={t("feedback.aria.toggle_feedback_dialog") ?? "Toggle feedback dialog"}
                >
                    <HelpOutlineRoundedIcon className={classes.buttonIcon} />{" "}
                    {isMobile ? null : <div className={classes.buttonText}>{t("g.help")}</div>}
                    {/* <span className={classes.buttonText}>Help</span> */}{" "}
                    {/* {menuOpen ? <ExpandLessRoundedIcon className="ml-4" /> : <ExpandMoreRoundedIcon className="ml-4" />} */}
                </Button>
                <div className={classes.separator} />
            </div>
            {menuAnchorEl ? (
                <Menu
                    id="fade-menu"
                    open={menuOpen}
                    anchorEl={menuAnchorEl}
                    onClose={handleCloseMenu}
                    TransitionComponent={Fade}
                    classes={{paper: classes.menu}}
                    anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                    transformOrigin={{horizontal: "right", vertical: -12}}
                    getContentAnchorEl={null}
                >
                    {isOrganizationOwner && hostType !== HostType.Player
                        ? [
                              <MenuItem key="1" className={classes.menuItem} onClick={handleClickBookDemo}>
                                  <BookDemoIcon /> {t("user.book_a_demo")}
                              </MenuItem>,
                              <li key="2" className={classes.menuItemSeparator} />,
                          ]
                        : null}
                    <MenuItem className={classes.menuItem} onClick={handleClickHelpCenter} noDivider>
                        <HelpRoundedIcon /> {t("routes.help_center")}
                        <OpenInNewRoundedIcon className="ml-auto" />
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={handleShowFeedback} noDivider>
                        <FeedbackIcon /> {t("user.contact_support")}
                    </MenuItem>

                    <MenuItem className={classes.menuItem} onClick={handleClickRoadmap} noDivider>
                        <MapRoundedIcon /> {t("user.check_roadmap")} <OpenInNewRoundedIcon className="ml-auto" />
                    </MenuItem>

                    <li className={classes.menuItemSeparator} />

                    <MenuItem className={classes.menuItem} onClick={handleClickJoinCommunity}>
                        <ForumRoundedIcon />
                        {t("user.join_our_community")}
                        <OpenInNewRoundedIcon className="ml-auto" />
                    </MenuItem>
                </Menu>
            ) : null}

            {onBooking && bookedEventData?.publicBookingEvent != null && !loading ? (
                // do not remove these keys cause otherwise will block clicks
                <Dialog
                    key="dialog-booking"
                    imperativeRef={bookingDialogRef}
                    open={onBooking}
                    disableBackdropClick={true}
                    onEscapeKeyDown={handleCloseBookDemo}
                    classes={{paper: classes.bookPaper}}
                >
                    <BookingViewProvider>
                        <BookEvent
                            view="component"
                            event={bookedEventData?.publicBookingEvent}
                            shouldRedirect={false}
                            onBook={handleCloseBookDemo}
                            onCancel={handleCloseBookDemo}
                            isBookADemo={true}
                        />
                    </BookingViewProvider>
                </Dialog>
            ) : null}
        </>
    );
}

export default FeedbackButton;
