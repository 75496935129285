import loadable from "@workhorse/api/loadable";
import React, {useMemo} from "@workhorse/api/rendering";
import {HomeRouteMatch, RouteHOCProps} from "@workhorse/declarations";

// there is no guarantee the user will land on the Home every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const SessionEnded = loadable(() => import("./SessionEnded"));

type SessionEndedProps = RouteHOCProps<HomeRouteMatch>;

// a HOC for the Base and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child
function SessionEndedHOC(props: SessionEndedProps): JSX.Element {
    return useMemo(
        () => (
            <div className="route-hoc flex-align-start" key="home-hoc">
                <SessionEnded />
            </div>
        ),
        []
    );
}

export default SessionEndedHOC;
