import loadable from "@workhorse/api/loadable";
import {FeedbackRouteMatch, RouteHOCProps} from "@workhorse/declarations";

const FeedbackInitializer = loadable(() => import("./FeedbackInitializer"));

type FeedbackHOCProps = RouteHOCProps<FeedbackRouteMatch>;

function FeedbackHOC(props: FeedbackHOCProps) {
    return <FeedbackInitializer {...props} />;
}

export default FeedbackHOC;
