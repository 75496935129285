import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import classes from "./onboarding.module.scss";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useCurrentAgendaItem} from "@workhorse/providers/SessionDataProviders";
import {ArtifactTag} from "@generated/artifacts";
import {useSessionsUIToggle} from "@workhorse/providers/SessionsUIToggleProvider";

const artifactsWithControlsTooltip: ArtifactTag[] = [
    "flowos/doc",
    "flowos/excel",
    "flowos/keynote",
    "flowos/ppt",
    "flowos/numbers",
    "flowos/pages",
    "flowos/pdf",
    "flowos/slideshow",
];

export function OnboardingPresentationControls(props: WithTooltipChildren) {
    const {onboardingType, tooltip, next} = useOnboardingSession();
    const skip = tooltip !== "tool-controls" || onboardingType !== SessionOnboardingType.NewUser;

    //makes the component rerender when the sessionsUIHidden changes
    const {sessionsUIHidden} = useSessionsUIToggle();

    const currentAgendaItem = useCurrentAgendaItem();

    if (!currentAgendaItem) {
        return props.children;
    }

    const artifact = currentAgendaItem.artifact;
    if (!artifact) {
        return props.children;
    }

    if (!artifactsWithControlsTooltip.includes(artifact.artifactId)) {
        return props.children;
    }

    const handleButtonClick = () => {
        if (tooltip === "tool-controls") {
            next({
                saveTooltip: true,
            });
        }
    };

    const title = (
        <>
            <div className="flex flex-align-center">
                <InfoOutlinedIcon className="mr-6" />
                <Typography className="title" fontWeight="bolder">
                    Change slides
                </Typography>
            </div>
            <Typography className="mt-6" variant="sm" fontWeight="normal">
                Click to change to the next slide. It’ll change for everyone else too.
            </Typography>
            <div className="mt-12 fullw flex flex-justify-end">
                <Button variant="secondary" size="small" onClick={handleButtonClick}>
                    Got it
                </Button>
            </div>
        </>
    );

    return (
        <>
            <OnboardingTooltip tooltipId={tooltip ?? "-1"} title={title} placement="bottom" arrow={true} skip={skip}>
                <div className={classes.anchor}></div>
            </OnboardingTooltip>
            {props.children}
        </>
    );
}
