import {DesignerApiSession as Session} from "@sessions/common/declarations/dataTypes";
import structuredClone from "@ungap/structured-clone";
import {isObj, isObjOrArray} from "./utils";
import {modelsWithFieldResolver} from "./common-diff-resolvers";

// NOTE: this function returns void
// all changes are added to existing which is a clone of existing unaltered data
export function createDiff(existing: any, incoming: any, undoChanges?: boolean) {
    if (Array.isArray(existing) && Array.isArray(incoming)) {
        existing.forEach((unchanged, idx) => {
            if (
                unchanged.isDeleted ||
                !isObjOrArray(unchanged) ||
                !unchanged.id ||
                !unchanged.__typename ||
                (modelsWithFieldResolver[unchanged.__typename] ?? []).length === 0
            ) {
                if (unchanged.isDeleted && undoChanges) {
                    unchanged.isDeleted = false;
                }
                return false;
            }
            const changed = incoming.find((obj) => {
                return obj && ((obj.id && obj.id === unchanged.id) || (obj.oldId && obj.oldId === unchanged.id));
            });

            // if an object with the same id was NOT found in incoming
            // OR (it was found but it exists only locally (aka createdAt is missing)
            // AND we're undoing changes)
            // remove from existing
            if (!changed || (undoChanges && "createdAt" in changed && !changed.createdAt)) {
                existing.splice(idx, 1);
                return false;
            }
            createDiff(unchanged, changed, undoChanges);
        });

        // push new items that don't exist in `existing` and
        // exist only locally (aka just created)
        // in case we're undoing changes, this step is skipped
        // because there's nothing to process
        if (!undoChanges) {
            incoming.forEach((changed, idx) => {
                const exists = existing.find((obj) => {
                    return obj && ((obj.id && obj.id === changed.id) || (changed.oldId && changed.oldId === obj.id));
                });
                if (!exists) {
                    existing.splice(idx, 0, changed);
                }
            });
        }
        // else {
        //     incoming.forEach((changed, idx) => {
        //         if (changed.id && changed.__typename && changed.createdAt && changed.isDeleted) {
        //             changed.isDeleted = false;
        //         }
        //     });
        // }
    } else if (isObj(existing) && isObj(incoming)) {
        const updateableKeys = modelsWithFieldResolver[existing.__typename] ?? [];
        // console.log("updatableKeys", updateableKeys, structuredClone(existing), structuredClone(incoming));
        const hasDiff = updateableKeys.length > 0;
        // undoChanges is checked first because we might be undoing changes for UNCOMMITED data
        // and here... we don't want to assign incoming over existing like below
        // we just let existing be... whatever it is (because it contains unchanged data)
        if (hasDiff && undoChanges && existing.createdAt) {
            existing.update = null;
            existing.oldId = null;
            existing.isDeleted = false;
            // no point in going any further since we're keeping all the relations
            // as they were before changes
            return;
        }
        // not adding the diff for entities that don't have a createdAt timestamp
        // because they don't exist in the database yet, meaning there's no update to perform
        if (hasDiff && !existing.createdAt) {
            existing.update = null;
            existing.oldId = null;
            existing.isDeleted = false;
            delete incoming.update;
            delete incoming.oldId;
            delete incoming.isDeleted;
            // assign incoming over whatever existing (UNCOMITTED data) we have
            Object.assign(existing, incoming);
            return;
        }

        if (hasDiff) {
            existing.isDeleted = incoming.isDeleted || false;
        }

        const isUpdate = hasDiff && existing.createdAt && ("createdAt" in incoming ? !!incoming.createdAt : true) && !incoming.isDeleted;
        const isReplace =
            hasDiff &&
            existing.createdAt &&
            "createdAt" in incoming &&
            !incoming.createdAt &&
            incoming.id &&
            existing.id &&
            !(existing.id === incoming.id) &&
            !incoming.isDeleted;

        if (isReplace) {
            existing.update = {
                __typename: `${existing.__typename}Diff`,
                newId: incoming.id,
            };
            existing.oldId = existing.id;
            existing.isDeleted = false;
        }
        if (!isReplace && isUpdate) {
            delete existing.oldId;
            existing.update = {__typename: `${existing.__typename}Diff`};
        }
        const keys = Object.keys(existing);

        // console.log("isUpdate", isUpdate);
        // console.log("!isReplace", isReplace);
        // console.log("existing.__typename", existing.__typename);

        keys.forEach((key) => {
            if (!key || key === "update" || key === "oldId" || key === "isDeleted") {
                return false;
            }

            if (updateableKeys.indexOf(key) !== -1) {
                const oldVal = existing[key] instanceof Date ? existing[key].toISOString() : existing[key];
                const newVal = key in incoming ? (incoming[key] instanceof Date ? incoming[key].toISOString() : incoming[key]) : oldVal;

                // if (existing.__typename === "SessionEvent") {
                //     console.log("oldVal, newVal", {oldVal: structuredClone(oldVal), newVal: structuredClone(newVal)}, key);
                //     console.log(
                //         "valuesAreEqual",
                //         valuesAreEqual(oldVal, newVal, ["descriptionJson", "colorPalette", "socialLinks"].includes(key))
                //     );
                // }

                if (
                    !valuesAreEqual(
                        oldVal,
                        newVal,
                        [
                            "descriptionJson",
                            "colorPalette",
                            "socialLinks",
                            "speakerOrderJson",
                            "utm",
                            "isPublic",
                            "reminders",
                            "messageReminders",
                            "restrictedWidgets",
                            "disabledNotifications",
                        ].includes(key)
                    )
                ) {
                    existing.update[key] = newVal;
                }

                // console.log("after update", structuredClone(existing));
            } else if (isObjOrArray(existing[key]) && isObjOrArray(incoming[key])) {
                createDiff(existing[key], incoming[key], undoChanges);
            } else if (
                existing[key] === null &&
                isObjOrArray(incoming[key]) &&
                (Array.isArray(incoming[key]) ? false : modelsWithFieldResolver[incoming[key].__typename])
            ) {
                existing[key] = incoming[key];
            }
        });
    }
}

export function valuesAreEqual(a: any, b: any, deepCompare?: boolean) {
    if (deepCompare || (isObj(a) && isObj(b))) {
        const areEqual = deepObjectEquals(a, b);
        return areEqual;
    }

    if (Array.isArray(a) && Array.isArray(b)) {
        return a.length == b.length && a.every((el) => (isObj(el) ? true : b.indexOf(el) !== -1));
    }
    return a === b;
}

function deepObjectEquals(obj1, obj2) {
    const JSONstringifyOrder = (obj) => {
        const keys = {};
        JSON.stringify(obj, (key, value) => {
            keys[key] = null;
            return value;
        });
        return JSON.stringify(obj, Object.keys(keys).sort());
    };
    return JSONstringifyOrder(obj1) === JSONstringifyOrder(obj2);
}

const nonDiffKeys = ["id", "__typename", "createdAt", "updatedAt", "oldId"];
function isObjIsCommitedAndMayHaveDiff(obj: any) {
    return isObj(obj) ? (obj.id && obj.__typename && obj.createdAt ? true : false) : false;
}

function isObjNotCommitedAndMayHaveDiff(obj: any) {
    return isObj(obj) ? (obj.id && obj.__typename && !obj.createdAt ? true : false) : false;
}

function isObjAndMayHaveDiff(obj: any) {
    return isObj(obj) && obj.id && obj.__typename ? true : false;
}

export function extractDiff(existing: any, changed: any, doLog?: boolean) {
    if (Array.isArray(existing) && Array.isArray(changed)) {
        const diff1 = existing
            .map((obj) => {
                if (isObjAndMayHaveDiff(obj)) {
                    const changedObj = changed.find((o) => o && o?.id === obj?.id);
                    const objDiff = changedObj ? extractDiff(obj, changedObj, doLog) : null;

                    if (objDiff === null || Object.keys(objDiff).filter((x) => x && nonDiffKeys.indexOf(x) === -1).length === 0) {
                        return null;
                    }
                    return objDiff;
                }
                // else if (isObjNotCommitedAndMayHaveDiff(obj)) {
                //     if (obj.__typename === "AgendaItem") {
                //         console.log("at agenda item NOT commited", {existing: structuredClone(obj)});
                //     }
                //     return Object.keys(obj).filter((x) => x && nonDiffKeys.indexOf(x) === -1).length > 0 ? obj : null;
                // }
                return null;
            })
            .filter((x) => x != null);

        const diff2 = changed
            .map((obj) => {
                if (isObj(obj)) {
                    const existingObj = existing.find((o) => o && o?.id === obj?.id);
                    if (!existingObj && obj && obj.id) {
                        return obj;
                    }
                    return null;
                }
                return null;
            })
            .filter((x) => x != null);

        const output = diff1.concat(diff2);
        return output.length === 0 ? undefined : output;
    }
    const updateableKeys = existing && isObj(existing) && existing.__typename ? modelsWithFieldResolver[existing.__typename] ?? [] : [];
    if (updateableKeys.length > 0 && isObj(existing) && isObj(changed)) {
        if (
            existing.createdAt &&
            "createdAt" in changed &&
            !changed.createdAt &&
            changed.oldId &&
            changed.oldId === existing.id &&
            !(changed.id === existing.id)
        ) {
            return changed;
        }
    }

    const output = Object.assign(
        {},
        existing.id ? {id: existing.id} : {},
        existing.__typename ? {__typename: existing.__typename} : {},
        changed.isDeleted ? {isDeleted: true} : {},
        existing.createdAt ? {createdAt: existing.createdAt} : {},
        existing.updatedAt ? {updatedAt: existing.updatedAt} : {},
        existing.oldId ? {oldId: existing.oldId} : {}
    );

    let dontExcludeIfEmptyArray: string[] = [];

    Object.keys(existing).forEach((k) => {
        if (k === "update") {
            return false;
        }
        const isUpdateableKey = updateableKeys.indexOf(k) !== -1;
        const oldVal = existing[k] instanceof Date ? existing[k].toISOString() : existing[k];
        const newVal = changed[k] instanceof Date ? changed[k].toISOString() : changed[k];
        // TODO: add createdAt and updatedAt fields for every model
        // cannot ensure proper diff extraction without them
        // because the diff is a combination of both changed things
        // as well as new things which don't have a createdAt by design
        const notCommited = !existing.createdAt && updateableKeys.length > 0;

        if (isUpdateableKey) {
            // if (["descriptionJson", "colorPalette", "socialLinks"].includes(k)) {
            //     console.log(valuesAreEqual(oldVal, newVal, ["descriptionJson", "colorPalette", "socialLinks"].includes(k)), oldVal, newVal);
            // }

            if (
                (k in existing &&
                    k in changed &&
                    !valuesAreEqual(
                        oldVal,
                        newVal,
                        ["descriptionJson", "colorPalette", "socialLinks", "speakerOrderJson", "utm", "isPublic"].includes(k)
                    )) ||
                notCommited
            ) {
                if (Array.isArray(newVal)) {
                    dontExcludeIfEmptyArray.push(k);
                }
                output[k] = newVal;
            }
        } else {
            if (isObjOrArray(existing[k]) && isObjOrArray(changed[k])) {
                let diff: any = null;
                if (k === "recurrenceData" && !existing[k].createdAt && !changed[k].createdAt) {
                    diff = changed[k];
                } else {
                    diff = extractDiff(existing[k], changed[k], doLog);
                }

                if (
                    isObjOrArray(diff) &&
                    (isObj(diff)
                        ? diff.__typename == "Session"
                            ? true
                            : Object.keys(diff).filter((dk) => nonDiffKeys.indexOf(dk) === -1).length > 0
                        : diff.length > 0 && dontExcludeIfEmptyArray.indexOf(k) === -1)
                ) {
                    output[k] = diff;
                }
            }
        }
    });
    Object.keys(output).forEach((k) => {
        if (
            output[k] === undefined ||
            (isObjOrArray(output[k]) &&
                (isObj(output[k])
                    ? output[k].__typename !== "Session" && Object.keys(output[k]).filter((k) => nonDiffKeys.indexOf(k) === -1).length === 0
                    : output[k].length === 0 && dontExcludeIfEmptyArray.indexOf(k) === -1))
        ) {
            delete output[k];
        }
        if (k === "childOfBreakoutRooms" && output[k] && !output[k].childOfBreakoutRooms) {
            delete output[k];
        }
    });

    return output;
}

function ingestIsReplace(existing: any, diff: any) {
    return isObj(existing) &&
        isObj(diff) &&
        diff.oldId &&
        diff.oldId === existing.id &&
        existing.id !== diff.id &&
        existing.createdAt &&
        "createdAt" in diff &&
        !diff.createdAt
        ? true
        : false;
}

function deepIngest(existing: any, diff: any) {
    if (diff === undefined || typeof existing !== typeof diff || (existing && !diff)) {
        return;
    }
    if (Array.isArray(existing)) {
        existing.forEach((obj, idx) => {
            const diffObj = diff.find((o) => o?.id === obj?.id || o?.oldId === obj?.id);
            if (diffObj) {
                const isReplace = ingestIsReplace(obj, diffObj);
                if (isReplace) {
                    existing[idx] = diffObj;
                    return false;
                }
                deepIngest(obj, diffObj);
            }
        });
        diff.forEach((obj, idx) => {
            const exists = existing.find((o) => o?.id === obj?.id || (o?.id && o.id === obj?.oldId));
            if (!exists && obj) {
                existing.splice(idx, 0, obj);
            }
        });
    } else {
        Object.keys(existing).forEach((k) => {
            const updateableKeys = modelsWithFieldResolver[existing.__typename] ?? [];
            if (updateableKeys.indexOf(k) !== -1) {
                // we don't merge createdAt and updatedAt since they could write an older timestamp over a new one
                // but we do merge if the diff has marked them as null though technically that should never happen here
                if (k in diff && (["createdAt", "updatedAt"].indexOf(k) !== -1 ? existing.createdAt && !diff[k] : true)) {
                    existing[k] = diff[k];
                }
            } else {
                const mayHaveDiff =
                    Array.isArray(existing[k]) ||
                    (isObj(existing[k]) && existing[k].__typename && existing[k].__typename in modelsWithFieldResolver);
                if (mayHaveDiff && diff[k]) {
                    const isReplace = !Array.isArray(existing[k]) && ingestIsReplace(existing[k], diff[k]);
                    if (isReplace) {
                        Object.assign(existing[k], diff[k]);
                        return false;
                    }
                    deepIngest(existing[k], diff[k]);
                }
            }
        });
    }
}

// there is a single use case for this method
// when making changes and refreshing the page before commiting them
// what happens at that point, is the session is newly fetched from the backend
// but it may contain newer data, that we haven't changed before the refresh
// so the aim is to ingest only things that were changed/added
export function ingestDiff(current: Session, diff: any) {
    (Object.keys(current) as Array<keyof Session>).forEach((k) => {
        deepIngest(current[k], diff[k]);
    });
}

export function removeNewArrayItems(data: any) {
    if (Array.isArray(data)) {
        data = data.filter((obj) => (isObj(obj) && "id" in obj && "__typename" in obj ? !!obj.createdAt : true));
    } else {
        if (isObj(data)) {
            Object.keys(data).forEach((k) => {
                if (isObjOrArray(data[k])) {
                    removeNewArrayItems(data[k]);
                }
            });
        }
    }
}
function createNestedDiffWhenReplace(existing: any, incoming: any) {
    if (Array.isArray(existing)) {
        existing.forEach((obj) => {
            if (!isObj(obj)) {
                return false;
            }
            // an object is considered replaceable if
            // IS NOT currently marked as deleted (isDeleted === false)
            // HAS an id
            // HAS a typename
            // AND that typename has a diff (aka modelsWithFieldResolver[typename] must exist)
            // HAS a createdAt timestamp, otherwise it is not a replace
            const isReplaceable = !obj.isDeleted && obj.id && obj.__typename && modelsWithFieldResolver[obj.__typename] && !!obj.createdAt;
            if (isReplaceable) {
                obj.isDeleted = true;
            }
        });
    } else {
    }
}

export function resetDiff(data: any) {
    if (!isObj(data)) {
        return;
    }
    Object.keys(data).forEach((k) => {
        if (["participants", "currentParticipant"].indexOf(k) !== -1) {
            return false;
        }
        if (isObjOrArray(data[k])) {
            if (Array.isArray(data[k])) {
                data[k].forEach((d) => resetDiff(d));
            } else if (isObj(data[k])) {
                resetDiff(data[k]);
            }
        }
    });
    if (data.__typename && modelsWithFieldResolver[data.__typename]) {
        data.update = {__typename: `${data.__typename}Diff`};
        data.isDeleted = false;
        data.oldId = null;
    }
}

export function removeTypenameKey<T>(
    data: T,
    opts?: {
        preserveTimestamps?: boolean;
        preserveDiffFields?: boolean;
    },
    level: number = 0
): T {
    const input = !level ? structuredClone(data) : data;
    if (typeof input == "object" && input != null) {
        // console.log("object");
        if (Array.isArray(input)) {
            // console.log("array");
            input.forEach((item) => {
                removeTypenameKey(item, opts, level + 1);
            });
        } else {
            const keys = Object.keys(input);
            for (let key of keys) {
                if (
                    ["__typename", "data", "update"]
                        .concat(opts?.preserveTimestamps ? [] : ["createdAt", "updatedAt"])
                        .concat(opts?.preserveDiffFields ? [] : ["oldId", "isDeleted"])
                        .includes(key)
                ) {
                    delete input[key];
                } else {
                    removeTypenameKey(input[key], opts, level + 1);
                }
            }
        }
    }
    return input;
}
