import loadable from "@workhorse/api/loadable";
import {OnboardingFeature, markAsSeen} from "@workhorse/api/seen";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useOnboardingFeature} from "./hooks";
import {OnboardingFeatureConfiguration} from "./provider";

const OnboardingFeaturePopup = loadable(() => import("./components/OnboardingFeaturePopup"));

type OnboardingFeaturesProps = {
    feature: OnboardingFeature;
};

function OnboardingFeatures({feature}: OnboardingFeaturesProps) {
    const {isMobileOrTablet} = useMobile();

    const config = useOnboardingFeature(feature);

    const handleOnCloseOnboarding = () => {
        markAsSeen({
            ONBOARDING_FEATURES: {
                [feature]: true,
            },
        });
    };

    if (isMobileOrTablet || !config || !config.component) {
        return null;
    }

    return (
        <OnboardingFeaturePopup
            feature={feature}
            {...(config as Required<OnboardingFeatureConfiguration>)}
            onClose={handleOnCloseOnboarding}
        />
    );
}

export default OnboardingFeatures;
