import {SessionFlags, SessionLifecycle} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useMemo} from "@workhorse/api/rendering";
import SessionAutoRecordingToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionAutoRecording";
import SessionAutoStartToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionAutoStart";
import SessionAutoTranscribingToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionAutoTranscribing";
import SessionSendEndSessionEmailToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionSendEndSessionEmailToggler";
import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import commonClasses from "../eventCommons.module.scss";
import classes from "./event-automations.module.scss";

type EventAutomationsProps = {
    isRoom?: boolean;
    onChange?: () => void;
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
    isTranscriptHidden: boolean;
};

export function EventAutomations(props: EventAutomationsProps) {
    const {isRoom, sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed, isTranscriptHidden} = props;
    const session = useSession();

    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => (!isRoom ? [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked : false),
        [session.lifecycle, isLocked]
    );

    const handleOnChange = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey]) => {
        lockEvent({
            variables: {
                id: session.id,
            },
        });

        designer.api.session.update({
            [key]: value,
        });
        props.onChange?.();

        designer.commit();
    };

    return (
        <div className={commonClasses.mainContainer}>
            <div className="flex flex-align-center flex-justify-between">
                <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-24">
                    Automations
                </Typography>
            </div>

            <SessionAutoRecordingToggler
                enabled={session.autoRecording}
                onChange={handleOnChange.bind(null, "autoRecording")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <SessionAutoTranscribingToggler
                enabled={session.autoTranscribing}
                onChange={handleOnChange.bind(null, "autoTranscribing")}
                showIcon={true}
                disabled={eventNotUpdatable || isTranscriptHidden}
            />
            <div className={classes.separator} />

            <SessionAutoStartToggler
                enabled={session.autoStartSession}
                onChange={handleOnChange.bind(null, "autoStartSession")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />
            <div className={classes.separator} />

            <SessionSendEndSessionEmailToggler
                enabled={session.sendEndSessionEmail}
                onChange={handleOnChange.bind(null, "sendEndSessionEmail")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <SessionTemplateSettingsNotification
                type="event"
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={commonClasses.sessionTemplateNotification}
            />
        </div>
    );
}
