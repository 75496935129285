import {cls} from "@ui/cdk/util";
import classes from "./style/FilterOwner.module.scss";
import FilterListIcon from "@material-ui/icons/FilterListRounded";
import Typography from "@ui/cdk/Typography";

import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import Button from "@ui/cdk/Button";
import FilterOwnerPopover from "./FilterOwnerPopover";

import React, {useState} from "@workhorse/api/rendering";

type FilterOwnerProps = {
    owners: {
        id: string;
        firstName: string;
        lastName: string;
        profilePicture?: string;
    }[];
    ownerFilter: string[];
    setOwnerFilter: (ownerFilter: string[]) => void;
    disabled?: boolean;
    className?: string;
    isAgendaSection?: boolean;
};

const FilterOwner = (props: FilterOwnerProps) => {
    const {owners, ownerFilter, setOwnerFilter, disabled, isAgendaSection} = props;
    const [ownerFilterPopoverAnchorEl, setOwnerFilterPopoverAnchorEl] = useState(null);

    const handleOwnerFilterPopoverOpen = (event) => {
        setOwnerFilterPopoverAnchorEl(event.currentTarget);
    };

    const handleOwnerFilterPopoverClose = () => {
        setOwnerFilterPopoverAnchorEl(null);
    };

    const getOwnerFilterButtonLabel = () => {
        if (ownerFilter.length === 0 || ownerFilter.length === owners.length) {
            return "All owners";
        } else if (ownerFilter.length === 1) {
            const selectedOwner = owners.find((owner) => owner.id === ownerFilter[0]);
            return selectedOwner?.firstName + " " + selectedOwner?.lastName;
        } else {
            return ownerFilter.length + " owners";
        }
    };

    const ownerFilterButtonLabel = getOwnerFilterButtonLabel();

    return (
        <>
            <Button
                variant="plain"
                className={cls(classes.filterButton, ownerFilterPopoverAnchorEl ? classes.active : "", props.className)}
                onClick={handleOwnerFilterPopoverOpen}
                data-id={isAgendaSection ? "agendas-dropdown-allowners" : "events-dropdown-owners"}
                disabled={disabled}
            >
                <Typography className={classes.filterButtonName}>{ownerFilterButtonLabel}</Typography>
                {ownerFilterPopoverAnchorEl ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
            </Button>

            {ownerFilterPopoverAnchorEl && (
                <FilterOwnerPopover
                    owners={owners}
                    anchorEl={ownerFilterPopoverAnchorEl}
                    onClose={handleOwnerFilterPopoverClose}
                    ownerFilter={ownerFilter}
                    setOwnerFilter={setOwnerFilter}
                />
            )}
        </>
    );
};

export default FilterOwner;
