import {CalendarProvider} from "@generated/data";
import {ReactComponent as ICalIcon} from "../../../assets/media/ical-logo.svg";
import {ReactComponent as Microsoft365Icon} from "../../../assets/media/microsoft-365-logo.svg";
import {ReactComponent as GoogleIcon} from "../../../assets/media/profile-google.svg";

type BaseExternalEventIconProps = React.SVGProps<SVGSVGElement> & {
    type: CalendarProvider;
};

export const CalendarIcon = ({type, width = 13, height = 13, ...htmlProps}: BaseExternalEventIconProps) => {
    const iconProps = {width, height, ...htmlProps};

    switch (type) {
        case CalendarProvider.Google:
            return <GoogleIcon {...iconProps} />;

        case CalendarProvider.Microsoft:
            return <Microsoft365Icon {...iconProps} />;

        case CalendarProvider.Apple:
            return <ICalIcon {...iconProps} />;

        default:
            return <></>;
    }
};
