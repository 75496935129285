import {Fragment, useContext, useEffect, useMemo, useReducer, useState} from "@workhorse/api/rendering";
import {InjectorContext, injectReducer} from "@workhorse/providers/InjectorsProvider";
import {WithMobileState} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import {useDrawerLeftToggler, useDrawerRightToggler} from "./state";
import {useDrawerLeftWidth} from "./useDrawerLeftWidth";
import {useDrawerRightWidth} from "./useDrawerRightWidth";
import {useIsLobby} from "./SessionDataProviders";
import {useSessionView} from "./SessionViewProvider";

type SubHeaderProps = {isFullScreen: boolean} & WithMobileState;
type SubHeaderPortalProps = {
    setClassName: (className: string) => void;
};

function SubHeaderPortal(props: SubHeaderPortalProps) {
    const [existing, inject] = useReducer(injectReducer, {}, () => ({}));
    const {set, unset} = useContext(InjectorContext);
    const ids = (Object.keys(existing) as Array<keyof typeof existing>).filter((x) => x !== "classNames");
    const gotComponents = ids.filter((x) => !!existing[x]).length > 0;
    const className = cls("flex flex11-auto flex-align-center", cls.apply(null, existing.classNames), gotComponents && "with-content");

    useEffect(() => {
        set("inSubHeader", inject);
    }, []);

    useEffect(() => {
        props.setClassName(className);
    }, [className]);

    return (
        <>
            {ids.map((k) => {
                return <Fragment key={k}>{existing[k]}</Fragment>;
            })}
        </>
    );
}

function SubHeader(props: SubHeaderProps) {
    const {mobileState, isFullScreen} = props;
    const {isLobby} = useIsLobby();
    const {isMobile} = mobileState;
    const [drawerLeftState] = useDrawerLeftToggler();
    const [drawerRightState] = useDrawerRightToggler();
    const drawerLeftWidth = useDrawerLeftWidth();
    const drawerRightWidth = useDrawerRightWidth();

    const {audience, hiddenAudienceBar} = useSessionView();

    const [className, setClassName] = useState("");

    const hideSubHeader = isMobile && isFullScreen && !isLobby;

    const subHeaderClassNames = cls(
        "app-sub-header",
        (!audience || hiddenAudienceBar) && !isLobby && "hidden",
        className,
        hideSubHeader && "hidden-but-displayed"
    );

    const style = useMemo(() => {
        return {
            paddingLeft: isMobile
                ? isLobby
                    ? 0
                    : 16
                : isLobby
                ? 0
                : drawerLeftWidth[drawerLeftState.state]
                ? drawerLeftWidth[drawerLeftState.state] + (isLobby ? 0 : 15)
                : isLobby
                ? 0
                : 15,
            paddingRight: isLobby
                ? 0
                : isMobile || drawerRightState.isDetached
                ? 16
                : drawerRightWidth[drawerRightState.state]
                ? drawerRightWidth[drawerRightState.state] + 30
                : 15,
        } as React.CSSProperties;
    }, [drawerLeftState.state, drawerRightState.state, drawerRightState.isDetached, isMobile, isLobby, isFullScreen]);

    return (
        <div id={"app-sub-header"} className={subHeaderClassNames} style={style}>
            <SubHeaderPortal setClassName={setClassName} />
        </div>
    );
}

export default SubHeader;
