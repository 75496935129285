export default function secondsToTime(
    secs: number,
    short?: boolean,
    noSpace?: boolean,
    shorter?: boolean,
    padded = true,
    showTimeUnit = true
) {
    secs = Math.round(secs);

    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
        hours: hours ? (
            <>
                <span>{hours < 10 && padded ? "0" + hours : hours}</span>
                {showTimeUnit
                    ? `${short ? (noSpace ? "" : " ") + (shorter ? "h" : "hr") : " hour"}${!short && hours > 1 ? "s" : ""}`
                    : null}
            </>
        ) : null,
        minutes: minutes ? (
            <>
                <span>{minutes < 10 && padded ? "0" + minutes : minutes}</span>
                {showTimeUnit
                    ? `${short ? (noSpace ? "" : " ") + (shorter ? "m" : "min") : " minute"}${!short && minutes > 1 ? "s" : ""}`
                    : null}
            </>
        ) : null,
        seconds: seconds ? (
            <>
                <span>{seconds < 10 && padded ? "0" + seconds : seconds}</span>
                {showTimeUnit
                    ? `${short ? (noSpace ? "" : " ") + (shorter ? "s" : "sec") : " second"}${!short && seconds > 1 ? "s" : ""}`
                    : null}
            </>
        ) : null,
    };
    return obj;
}

export function timeSpent(seconds: number) {
    let mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds - mins * 60);
    const hrs = Math.floor(mins / 60);
    mins = mins - hrs * 60;
    const hrsTxt = hrs ? `${hrs < 10 ? "0" : ""}${hrs}` : null;
    const minsTxt = `${mins > 0 ? (mins < 10 ? "0" : "") + mins : "00"}`;
    const secsTxt = `${secs > 0 ? (secs < 10 ? "0" : "") + secs : "00"}`;
    return (hrsTxt ? [hrsTxt] : []).concat([minsTxt, secsTxt]).join(":");
}
