import loadable from "@workhorse/api/loadable";
import {SessionSettingsSection} from "../../definitions";
import {ReactComponent as AutomationsIcon} from "../assets/session-automations-icon.svg";
import {SessionLifecycle} from "@generated/data";

const AutomationSection = loadable(() => import("./AutomationSection"));
AutomationSection.displayName = "Loadable:AutomationSection";

const AutomationSectionDefinition: SessionSettingsSection<"general.automation"> = {
    translateKey: "general.automation",
    kind: "general.automation",
    title: "Automation",
    component: AutomationSection,
    icon: AutomationsIcon,
    showIfStarted: (lifecycle) => lifecycle !== SessionLifecycle.Started,
};

export default AutomationSectionDefinition;
