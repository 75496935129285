import {HostType} from "@generated/data";
import {useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useJoinedSpeaker} from "../ParticipantsProvider";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";
import {useSession} from "@workhorse/providers/SessionDataProviders";

const useMeAloneInSessionStore = () => {
    const {gotAttendees, myParticipantId, isOwnerOrAssistant, joinedParticipants} = useParticipantsStore(
        useShallow((state) => ({
            gotAttendees: state.allParticipants.filter((p) => p.lastSeenTimestamp && p.id !== state.currentParticipant?.id).length,
            myParticipantId: state.currentParticipant?.id,
            isOwnerOrAssistant: state.amIaModerator,
            joinedParticipants: state.joinedFullList,
        }))
    );

    const speakers = useJoinedSpeaker();
    const hostType = useHostType();

    const {childOfBreakoutRooms, event} = useSession();

    const isChildSession = !!childOfBreakoutRooms?.parentOfBreakoutRooms?.sessionId;
    const notPlayer = hostType !== HostType.Player;
    const participantListIsEmpty = !joinedParticipants.length
        ? !myParticipantId
        : joinedParticipants.length === 1 && joinedParticipants[0].id === myParticipantId;
    const gotSpeakers = !!speakers.length;

    const isEvent = !!event;

    const isAloneInSession =
        !notPlayer && !isChildSession && isOwnerOrAssistant && participantListIsEmpty && !gotSpeakers && !gotAttendees && !isEvent;

    return useMemo(() => isAloneInSession, [isAloneInSession]);
};

export const [LonelinessProvider, useMeAloneInSession] = createContextProvider(
    {
        name: "LonelinessProvider",
    },
    useMeAloneInSessionStore
);
