/* eslint-disable react/react-in-jsx-scope */
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import Typography from "@ui/cdk/Typography";
import FormControl from "@ui/core/components/FormControl";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/UserView.module.scss";

interface UserViewProps {
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserView = (props: UserViewProps) => {
    const {handleRadioChange} = props;

    return (
        <div className={classes.infoContainer}>
            <div className={cls(classes.subtitle, classes.withMarginLeft)}>
                <FormControl>
                    <RadioGroup defaultValue={"save"} aria-label="session" name="customized-radios" onChange={handleRadioChange}>
                        <FormControlLabel
                            data-id="save-event"
                            value="save"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    Save
                                </Typography>
                            }
                        />
                        <Typography className={classes.radioText}>
                            Event will be updated with the latest changes and all speakers will be notified in case there are any changes to
                            their schedule.
                        </Typography>
                        {/* <FormControlLabel
                            data-id="do-nothing-event"
                            value="doNothing"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    Do not save
                                </Typography>
                            }
                            classes={{
                                root: classes.radioLabelRoot,
                            }}
                        />
                        <Typography className={classes.radioText}>
                            Your changes are stored for 30 minutes so you can pick up where you left off. After that they will be discarded.
                        </Typography> */}

                        <FormControlLabel
                            data-id="discard-event"
                            value="discard"
                            control={<Radio size="medium" />}
                            label={
                                <Typography variant="lg" className={classes.radioLabel}>
                                    Discard changes
                                </Typography>
                            }
                            classes={{
                                root: classes.radioLabelRoot,
                            }}
                        />
                        <Typography className={classes.radioText}>
                            The event will be restored to the last saved version. All unsaved changes will be lost.
                        </Typography>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
};

export default UserView;
