import loadable from "@workhorse/api/loadable";
import {matchPath, Route, useLocation} from "@workhorse/api/routing";
import {ExtractProps} from "@workhorse/declarations";
import NotFound from "@workhorse/pages/default";
/**
 * already have lazy-loading logic within
 */
import UserUnsubscribe from "@workhorse/pages/unsubscribe";
import UserAuthFail from "@workhorse/pages/user/auth/UserAuthFail";
import UserAuthState from "@workhorse/pages/user/auth/UserAuthState";
import UserHackLogin from "@workhorse/pages/user/auth/UserHackLogin";
import UserHackRegister from "@workhorse/pages/user/auth/UserHackRegister";
import UserOrganizationRegister from "@workhorse/pages/user/auth/UserOrganizationRegister";
import UserLogin from "@workhorse/pages/user/auth/UserLogin";
import UserLoginSuccess from "@workhorse/pages/user/auth/UserLoginSuccess";
import UserRegister from "@workhorse/pages/user/auth/UserRegister";

/**
 * large (or with deep dependencies) components that need to be lazily loaded
 */

const LogoutConfirm = loadable(() => import("@workhorse/pages/user/auth/LogoutConfirm"));
const UserInviteCode = loadable(() => import("@workhorse/pages/user/guest/UserInviteCode"));
const HubspotLanding = loadable(() => import("@workhorse/pages/user/guest/HubspotLanding"));
const GoogleOAuth2Callback = loadable(() => import("@workhorse/pages/user/google/GoogleOAuth2Callback"));
const MicrosoftOAuth2Callback = loadable(() => import("@workhorse/pages/user/microsoft/MicrosoftOAuth2Callback"));
const NotionOAuth2Callback = loadable(() => import("@workhorse/pages/user/notion/NotionOAuth2Callback"));
const EvernoteOAuth2Callback = loadable(() => import("@workhorse/pages/user/evernote/EvernoteOAuth2Callback"));
const HubspotOAuth2Callback = loadable(() => import("@workhorse/pages/user/hubspot/HubspotOAuth2Callback"));
const StripeOAuth2Callback = loadable(() => import("@workhorse/pages/user/stripe/StripeOAuth2Callback"));
const PayPalCallback = loadable(() => import("@workhorse/pages/user/paypal/PayPalCallback"));

const authRouteComponents = {
    UserHackLogin,
    UserHackRegister,
    UserAuthFail,
    UserLogin,
    UserLoginSuccess,
    UserRegister,
    UserOrganizationRegister,
    UserUnsubscribe,
    NotFound,
    UserAuthState,
    LogoutConfirm,
    UserInviteCode,
    HubspotLanding,
    GoogleOAuth2Callback,
    MicrosoftOAuth2Callback,
    NotionOAuth2Callback,
    EvernoteOAuth2Callback,
    HubspotOAuth2Callback,
    StripeOAuth2Callback,
    PayPalCallback,
};

type GenericRouteProps = ExtractProps<typeof Route>;

type AuthRoutePaths = {
    [K in keyof typeof authRouteComponents]?: Omit<GenericRouteProps, "component">;
};

export const authPaths: AuthRoutePaths = {
    LogoutConfirm: {
        path: "/user/logout-confirm",
        exact: true,
    },
    UserHackLogin: {
        path: "/user/hack/login",
        exact: true,
    },
    UserHackRegister: {
        path: "/user/hack/register",
        exact: true,
    },
    UserAuthFail: {
        path: "/user/fail",
        exact: true,
    },
    UserLogin: {
        path: "/user/login",
        exact: true,
    },
    UserLoginSuccess: {
        path: "/user/login-success",
        exact: true,
    },
    UserRegister: {
        path: "/user/register",
        exact: true,
    },
    UserOrganizationRegister: {
        path: "/user/org-register",
        exact: true,
    },
    UserInviteCode: {
        path: "/user/invite/:inviteCode",
        exact: true,
    },
    HubspotLanding: {
        path: "/user/join",
        exact: true,
    },
    UserUnsubscribe: {
        path: "/unsubscribe/:token",
        exact: true,
    },
    NotFound: {
        path: "/404",
        exact: true,
    },
    UserAuthState: {
        path: "/user/authenticationState",
        exact: true,
    },
    GoogleOAuth2Callback: {
        path: "/user/google/oauth2callback",
        exact: true,
    },
    MicrosoftOAuth2Callback: {
        path: "/user/microsoft/oauth2callback",
        exact: true,
    },
    NotionOAuth2Callback: {
        path: "/user/notion/oauth2callback",
        exact: true,
    },
    EvernoteOAuth2Callback: {
        path: "/user/evernote/oauth2callback",
        exact: true,
    },
    HubspotOAuth2Callback: {
        path: "/user/hubspot/oauth2callback",
        exact: true,
    },
    StripeOAuth2Callback: {
        path: "/user/stripe/oauth2callback",
        exact: true,
    },
    PayPalCallback: {
        path: "/user/paypal/callback",
        exact: true,
    },
};

export function useIsAuthAllowedRoute() {
    const location = useLocation();
    const isAuthAllowedRoute = !!Object.keys(authPaths).find((pathKey) => matchPath(location.pathname, authPaths[pathKey]));
    return isAuthAllowedRoute;
}

export default authRouteComponents;
