import {HostType} from "@generated/data";
import {useDevices} from "@workhorse/api/conference2";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useHostType} from "./HostTypeProvider";
import {useSession} from "./SessionDataProviders";
import {useSessionView} from "./SessionViewProvider";
import {useCameraHiddenStorage} from "./UserDeviceSettingsStorageProvider";

function useVisibleIdsStore() {
    const {grid} = useSessionView();
    const hostType = useHostType();
    const session = useSession();
    const {cameraHidden} = useCameraHiddenStorage();
    const {audioEnabled, audioMuted, videoEnabled} = useDevices();
    const meIsMuted = !audioEnabled || audioMuted;
    const globalHideActive = session?.hideNonStreamingParticipants ? true : false;

    const meIsHidden = cameraHidden || (meIsMuted && !videoEnabled && globalHideActive) ? true : false;

    const prevSessionId = useRef(session?.id);
    const sessIdChanged = prevSessionId.current && session?.id && prevSessionId.current !== session?.id ? true : false;
    prevSessionId.current = session?.id;

    const [floatingInSpotlightOrActiveSpeakers, setInMainView] = useState<string[]>([]);
    const setParticipantIsInMainView = useCallback((participantIdOrIds: string | string[], inMainView?: boolean) => {
        if (Array.isArray(participantIdOrIds)) {
            setInMainView((current) => {
                return current.length === participantIdOrIds.length && !current.some((id) => !participantIdOrIds.includes(id))
                    ? current
                    : participantIdOrIds;
            });
            return;
        }
        setInMainView((current) => {
            if (inMainView != null) {
                return !inMainView && current.includes(participantIdOrIds)
                    ? current.filter((pid) => pid !== participantIdOrIds)
                    : inMainView && !current.includes(participantIdOrIds)
                    ? [...current, participantIdOrIds]
                    : current;
            }
            return current.includes(participantIdOrIds)
                ? current.filter((pid) => pid !== participantIdOrIds)
                : [...current, participantIdOrIds];
        });
    }, []);

    const [visibleIds, setVisibleIds] = useState<string[] | null>(() => {
        return globalHideActive ? [] : null;
    });

    useEffect(() => {
        if (hostType !== HostType.Player) {
            setVisibleIds(null);
            setInMainView([]);
        } else if (sessIdChanged) {
            if (globalHideActive) {
                setVisibleIds([]);
            } else {
                setVisibleIds(null);
            }
            setInMainView([]);
        }
    }, [hostType, sessIdChanged]);

    useEffect(() => {
        if (grid) {
            setInMainView((current) => (current.length ? [] : current));
        }
    }, [grid]);

    return useMemo(
        () => ({
            floatingInSpotlightOrActiveSpeakers,
            setParticipantIsInMainView,
            meIsHidden,
            visibleIds,
            setVisibleIds,
        }),
        [meIsHidden, visibleIds, setVisibleIds, floatingInSpotlightOrActiveSpeakers]
    );
}

export const [VisibleParticipantsProvider, useVisibleIds] = createContextProvider(
    {
        name: "VisibleParticipantsProvider",
    },
    useVisibleIdsStore
);
