import React, {useEffect} from "react";
import Menu from "@ui/cdk/Menu";
import MenuItem from "@ui/cdk/MenuItem";
import classes from "../style/AgendaItemsActionsMenu.module.scss";
import {cls} from "@ui/cdk/util/util";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {AgendaItem} from "@workhorse/declarations/dataTypes";
import {useAgendaItems, useCurrentAgendaItem, useSession} from "@workhorse/providers/SessionDataProviders";
import {assistantActionAllowed} from "@workhorse/api/user";
import AgendaItemMenuItem from "./AgendaItemMenuItem";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {DrawerHookState} from "@workhorse/declarations";
import {DrawerState} from "@generated/data";

type AgendaItemsActionsMenuProps = {
    agendaItemsAnchorEl: null | HTMLElement;
    handleClose: (any, string) => void;
    onMouseLeave: (event: React.MouseEvent<HTMLElement>) => void;
    inLobby: boolean;
    sessionId: string;
    isAssistant: boolean;
    isAdmin: boolean;
    session: SessionData;
    isMobile: boolean;
    toggleLeftDrawer: (newState?: DrawerState | undefined) => void;
    drawerLeftState: DrawerHookState;
    isTemplatePreview: boolean;
    currentAgendaItem: AgendaItem;
    sessionOrder: number;
};

const AgendaItemsActionsMenu = (props: AgendaItemsActionsMenuProps) => {
    const {
        agendaItemsAnchorEl,
        handleClose,
        onMouseLeave,
        inLobby,
        sessionId,
        isAssistant,
        isAdmin,
        session,
        isMobile,
        toggleLeftDrawer,
        drawerLeftState,
        sessionOrder,
        isTemplatePreview,
        currentAgendaItem,
    } = props;

    const agendaItems = useAgendaItems();

    const agendaItemsToShow = () => {
        if (sessionOrder === 0) {
            return agendaItems.slice(0, 3);
        }

        if (sessionOrder === agendaItems.length - 1) {
            return agendaItems.slice(-3);
        }

        if (sessionOrder >= 1) {
            return agendaItems.slice(sessionOrder - 1, sessionOrder + 2);
        }
        return [];
    };

    const assistant = assistantActionAllowed(["session.artifactNav"]);
    const changeArtifactAllowed = isAdmin || (isAssistant && assistant.canSessionArtifactNav);

    return (
        <Menu
            id="agenda-items-actions-menu"
            aria-labelledby="agenda-items-actions-menu"
            anchorEl={agendaItemsAnchorEl}
            open={Boolean(agendaItemsAnchorEl)}
            onClose={(event, reason) => handleClose(event, reason)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            onClick={(e) => e.stopPropagation()}
            classes={{
                paper: cls(classes.tooltip, classes.paper),
                list: cls(classes.menuList, classes.list),
            }}
            BackdropProps={{
                className: classes.backdrop,
            }}
            hideBackdrop
            className={classes.menu}
            MenuListProps={{onMouseLeave: onMouseLeave}}
            variant="menu"
        >
            {!agendaItemsToShow
                ? null
                : agendaItemsToShow().map((agendaItem, index, displayedAgendaItems) => {
                      const {
                          title = "",
                          artifact,
                          id,
                          order,
                          descriptionJson = {},
                          duration,
                          timeSpentInSeconds,
                          startAt,
                          isPaused,
                      } = agendaItem;

                      return (
                          <AgendaItemMenuItem
                              index={index}
                              agendaItems={agendaItems}
                              key={id}
                              title={title!}
                              displayedAgendaItems={displayedAgendaItems}
                              order={order}
                              description={descriptionJson}
                              id={id}
                              duration={duration!}
                              artifact={artifact}
                              changeArtifactButtonEnabled={changeArtifactAllowed && !inLobby && currentAgendaItem?.order === order}
                              inLobby={inLobby}
                              sessionId={sessionId}
                              session={session}
                              isMobile={isMobile}
                              toggleLeftDrawer={toggleLeftDrawer}
                              isActive={order === sessionOrder}
                              timeSpentInSeconds={timeSpentInSeconds!}
                              startAt={startAt!}
                              isAssistant={isAssistant}
                              isAdmin={isAdmin}
                              isPaused={!!isPaused}
                          />
                      );
                  })}
        </Menu>
    );
};

export default AgendaItemsActionsMenu;
