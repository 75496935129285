import {Session} from "@workhorse/declarations/dataTypes";
import Inject from "@workhorse/providers/Injector";
import EventHeaderActions from "./event-header-actions";
import EventHeaderBreadcrumbs from "./event-header-breadcrumbs";

type EventHeaderProps = {
    session?: Session;
    shouldWarnAboutSlug?: boolean;
};

const EventHeader = (props: EventHeaderProps) => {
    const {session, shouldWarnAboutSlug} = props;
    return (
        <Inject target="inHeader" id="events-dashboard-header" className="overflow-hidden">
            <div className="fullw">
                <div className={"flex flex-justify-between py-5"}>
                    <EventHeaderBreadcrumbs eventName={session ? session.name : "New event"} />
                    {session && <EventHeaderActions session={session} shouldWarnAboutSlug={shouldWarnAboutSlug} />}
                </div>
            </div>
        </Inject>
    );
};

export default EventHeader;
