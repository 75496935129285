import LikeSmall from "../assets/like-small.png";
import ClapSmall from "../assets/clap-small.png";
import LoveSmall from "../assets/love-small.png";
import LaughSmall from "../assets/laugh-small.png";
import DislikeSmall from "../assets/dislike-small.png";
import CelebrateSmall from "../assets/celebrate-small.png";
import FlushedSmall from "../assets/flushed-small.png";
import AstonishedSmall from "../assets/astonished-small.png";
import RaiseHandSmall from "../assets/raise-hand-small.png";
import LikeBig from "../assets/like-big.png";
import ClapBig from "../assets/clap-big.png";
import LoveBig from "../assets/love-big.png";
import LaughBig from "../assets/laugh-big.png";
import DislikeBig from "../assets/dislike-big.png";
import CelebrateBig from "../assets/celebrate-big.png";
import FlushedBig from "../assets/flushed-big.png";
import AstonishedBig from "../assets/astonished-big.png";
import RaiseHandBig from "../assets/raise-hand-big.png";
import {ReactionType} from "@generated/data";
import ReactionItem from "../../conferenceControls/Reactions/components/ReactionItem";

export type ReactionMapType = {
    iconSmall: string;
    iconBig: string;
    type: ReactionType;
    name: string;
    key: string;
};

export const reactions: {[key in ReactionType]: ReactionMapType} = {
    [ReactionType.Raisehand]: {
        iconSmall: RaiseHandSmall,
        iconBig: RaiseHandBig,
        type: ReactionType.Raisehand,
        name: "Raise hand",
        key: "raise_hand",
    },
    [ReactionType.Like]: {
        iconSmall: LikeSmall,
        iconBig: LikeBig,
        type: ReactionType.Like,
        name: "OK",
        key: "ok",
    },
    [ReactionType.Clap]: {
        iconSmall: ClapSmall,
        iconBig: ClapBig,
        type: ReactionType.Clap,
        name: "Applause",
        key: "applause",
    },
    [ReactionType.Love]: {
        iconSmall: LoveSmall,
        iconBig: LoveBig,
        type: ReactionType.Love,
        name: "Love",
        key: "love",
    },
    [ReactionType.Celebrate]: {
        iconSmall: CelebrateSmall,
        iconBig: CelebrateBig,
        type: ReactionType.Celebrate,
        name: "Celebration",
        key: "celebration",
    },
    [ReactionType.Dislike]: {
        iconSmall: DislikeSmall,
        iconBig: DislikeBig,
        type: ReactionType.Dislike,
        name: "Disapproval",
        key: "disapproval",
    },
    [ReactionType.Laugh]: {
        iconSmall: LaughSmall,
        iconBig: LaughBig,
        type: ReactionType.Laugh,
        name: "Laugh",
        key: "laugh",
    },
    [ReactionType.Flushed]: {
        iconSmall: FlushedSmall,
        iconBig: FlushedBig,
        type: ReactionType.Flushed,
        name: "Flushed",
        key: "flushed",
    },
    [ReactionType.Astonished]: {
        iconSmall: AstonishedSmall,
        iconBig: AstonishedBig,
        type: ReactionType.Astonished,
        name: "Surprised",
        key: "surprised",
    },
};

export const getReactionsList = (displayRaiseHand?: boolean) =>
    Object.values(reactions)
        .filter((r) => (displayRaiseHand ? true : r.type !== ReactionType.Raisehand))
        .map((r, i) => <ReactionItem {...r} key={"reactions-toggler-item-" + i} />);
