import {ConnectionState} from "livekit-client";

export type RoomConnectionStatus = {
    connected: boolean;
    connecting: boolean;
    reconnecting: boolean;
    disconnected: boolean;
    joinedFromAnotherDevice: boolean;
    state: ConnectionState | "initial" | "joinedFromAnotherDevice";
};

export function mapToInternalConnectionStatus(state: RoomConnectionStatus["state"]): RoomConnectionStatus {
    const connected = state === ConnectionState.Connected;
    const connecting = state === ConnectionState.Connecting;
    const reconnecting = state === ConnectionState.Reconnecting;
    const disconnected = state === ConnectionState.Disconnected;
    const joinedFromAnotherDevice = state === "joinedFromAnotherDevice";
    return {
        connected,
        connecting,
        reconnecting,
        disconnected,
        joinedFromAnotherDevice,
        state,
    };
}
