import {TEMPLATE_FOLDER_NAME_FOR_USERS} from "@sessions/common/agenda-templates";
import TableCell from "@ui/cdk/Table/TableCell";
import TableHead from "@ui/cdk/Table/TableHead";
import TableHeadSortButton from "@ui/cdk/Table/TableHeadSortButton";
import TableRow from "@ui/cdk/Table/TableRow";
import TableSearchInput from "@ui/cdk/Table/TableSearchInput/TableSearchInput";
import Typography from "@ui/cdk/Typography";
import {useMemo} from "@workhorse/api/rendering";
import {throttle} from "throttle-debounce";
import {AgendaTemplateSort, AgendaTemplateSortField} from "./AgendaTemplatesTable";
import classes from "./style/AgendaTemplatesTableHead.module.scss";

type AgendaTemplatesTableHeadProps = {
    className?: string;
    searchTerm: string;
    disabledSearch: boolean;
    hasActionsColumn: boolean;
    onSearch: (value: string) => void;
    tagName: string;
    sort: {field: AgendaTemplateSortField; direction: "asc" | "desc"};
    onSort: (sort: AgendaTemplateSort) => void;
};

type HeadCellsIds = "name" | "duration" | "updatedAt" | "owner" | "actions";

type HeadCell = {
    id: HeadCellsIds;
    label?: string;
    searchInput?: boolean;
};

const headCells: HeadCell[] = [
    {id: "name", searchInput: true},
    {id: "duration", label: "Duration"},
    {id: "updatedAt", label: "Last modified"},
    {id: "owner", label: "Owner"},
    {id: "actions"},
];

const AgendaTemplatesTableHead = (props: AgendaTemplatesTableHeadProps) => {
    const {searchTerm, disabledSearch, className, onSearch, sort, onSort} = props;

    const handleSearchInputChange = useMemo(() => throttle(300, onSearch), [onSearch]);
    const canSort = searchTerm === "";

    const cells = headCells.filter((cell) => {
        if (cell.id === "actions" && !props.hasActionsColumn) {
            return false;
        }
        if (cell.id === "owner" && props.tagName === TEMPLATE_FOLDER_NAME_FOR_USERS) {
            return false;
        }
        return true;
    });

    return (
        <TableHead className={className}>
            <TableRow>
                {cells.map((item) => (
                    <TableCell
                        key={item.id}
                        size={item.searchInput ? "large" : item.id === "actions" ? "medium" : "unset"}
                        type={item.searchInput ? "input" : "default"}
                        className={classes.cell}
                        // className={cls(item.id === "email" && classes.customWidth)}
                    >
                        <div className="flex flex-align-center">
                            {item.searchInput ? (
                                <div className="flex flex-items-center">
                                    <TableSearchInput
                                        value={searchTerm}
                                        placeholder="Search for an agenda"
                                        onChange={(e) => handleSearchInputChange(e.currentTarget.value)}
                                        disabled={disabledSearch}
                                        onClear={() => props.onSearch("")}
                                    />
                                </div>
                            ) : (
                                <Typography>{item.label}</Typography>
                            )}
                            {item.id !== "actions" && (
                                <TableHeadSortButton
                                    columnId={item.id}
                                    activeColumnId={sort.field}
                                    direction={sort.direction}
                                    onSort={onSort}
                                    canSort={canSort}
                                />
                            )}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default AgendaTemplatesTableHead;
