import {CollaborativeSession, DesignerApiSession} from "@common/declarations/dataTypes";
import {
    DesignerSessionChangedByDocument,
    DesignerSessionLockedDocument,
    DrawerState,
    Exact,
    GetSessionQuery,
    OnSessionDiffSendDocument,
    SessionEventState,
    SessionLifecycle,
    UnlockEventDocument,
} from "@generated/data";
import {ingestDiff, resetDiff} from "@sessions/common/diff/computeDiff";
import {dateFormat} from "@ui/cdk/DateFormat";
import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import {ApolloQueryResult, makeVar, useReactiveVar, useSubscription} from "@workhorse/api/data";
import designer from "@workhorse/api/designer";
import {ReactNode, useEffect, useState} from "@workhorse/api/rendering";
import {NavLink, Route, Switch, useHistory} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {enableEventLeaveModal, routeNavigation} from "@workhorse/components/header/headerUtils";
import {QueryPayload, QueryRenderer, useMutation} from "@workhorse/dataApi";
import {sendWorkerMessage} from "@workhorse/eventUnlockWorker";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {evictLocalQueries} from "@workhorse/providers/HostTypeProvider";
import {useParticipants, useSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import Watchdog from "@workhorse/providers/Watchdog";
import Typography from "@workhorse/ui/cdk/Typography";
import {Location} from "history";
import {EventAdvancedSettings} from "./event-advanced-settings/event-advanced-settings";
import {EventAgenda} from "./event-agenda/event-agenda";
import EventAnalytics from "./event-analytics/event-analytics";
import {EventAutomations} from "./event-automations/event-automations";
import EventHeader from "./event-header";
import EventLandingPage from "./event-landing/event-landing";
import LeaveEventModal from "./event-leave-modal/LeaveEventModal";
import EventLeaveSettings from "./event-leave-settings/event-leave-settings";
import {EventPeople} from "./event-people/event-people";
import {EventPermissions} from "./event-permissions/event-permissions";
import {EventRecording} from "./event-recording/event-recording";
import {EventLivestreaming} from "./event-livestreaming/event-livestreaming";
import {EventRegistration} from "./event-registration/event-registration";
import {EventSettings} from "./event-settings/event-settings";
import EventSkeleton from "./event-skeleton/event-skeleton";
import {EventSpeakers} from "./event-speakers/event-speakers";
import {EventWidgets} from "./event-widgets/event-widgets";
import classes from "./event.module.scss";
import {discardingChanges} from "./eventVars";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {EventPayments} from "./event-payments";
import {useSeenSection} from "@workhorse/api/seen";
import OnboardingEventCreateDialog from "@workhorse/pages/user/onboarding/components/onboarding-session-dialog/OnboardingEventCreateDialog";
import {isBefore} from "date-fns";
import {paymentConfigState} from "./event-payments/upsertPaymentConfigs";

interface NavProps {
    className?: string;
    to: string;
    exact?: boolean;
    children: ReactNode;
}

function Nav(props: NavProps) {
    const {to, exact, children, className, ...rest} = props;
    return (
        <NavLink {...rest} to={to} activeClassName={classes.linkActive} exact={exact ?? true} className={cls(classes.link, className)}>
            {children}
        </NavLink>
    );
}

type Props = {
    id: string;
    location?: Location;
} & QueryPayload<"GetSessionDocument">;

const eventOnboardingReleaseDate = new Date("2024-04-15T13:08:36.342Z");

export const descriptionKeyVar = makeVar(1);

function EventWithData(props: Props) {
    const {data, refetch} = props;
    const [lockEvent] = useMutation("LockEventDocument");

    const user = useUserInfo();

    const eventsOnboardingCreateSeen = useSeenSection("ONBOARDING_FEATURES.eventsCreate");

    const showOnboarding =
        !eventsOnboardingCreateSeen &&
        isBefore(eventOnboardingReleaseDate, new Date(user.organizationPermissions?.[0].organization.createdAt));

    const descriptionKey = useReactiveVar(descriptionKeyVar);

    const [isMenuHidden, setIsMenuHidden] = useState(false);
    const [shouldWarnAboutSlug, setShouldWarnAboutSlug] = useState(false);

    const [sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed] = useState<boolean>(false);

    const isLocked = useDesignerSessionLocked();
    const history = useHistory();
    const {mixpanelTrack} = useMixpanelTracker();

    const session = useSession();
    const [_, toggleRightDrawer] = useDrawerRightToggler();

    useEffect(() => {
        if (data.session.lifecycle === SessionLifecycle.Ended) {
            history.replace(`/memory/session/${data.session.id}`);
        }

        toggleRightDrawer(DrawerState.Closed);

        enableEventLeaveModal(false);
        routeNavigation(undefined);
    }, []);

    const isTranscriptHidden = session?.restrictedWidgets.disabled?.some((x) => x === "flowos/transcript");
    const isAutoTranscribingEnabled = session.autoTranscribing;

    useEffect(() => {
        if (isTranscriptHidden && isAutoTranscribingEnabled) {
            lockEvent({
                variables: {
                    id: session.id,
                },
            });

            designer.api.session.update({
                autoTranscribing: false,
            });

            designer.commit();
        }
    }, [isTranscriptHidden, isAutoTranscribingEnabled, session.id, lockEvent]);

    useEffect(() => {
        const currentLocation = props.location?.pathname?.split("/").pop();
        mixpanelTrack(`frontend-navigate-${currentLocation}`, "events");
    }, [props.location]);

    // Cleanup payments configs from memory
    useEffect(() => {
        if (data.session.event?.payments.length) {
            paymentConfigState(data.session.event?.payments?.map((config) => ({state: "ready", value: config})) ?? []);
        }
        return () => {
            paymentConfigState(null);
        };
    }, []);

    const showEventNameTooltip = session?.name?.length > 20;
    const eventNameTooltip = showEventNameTooltip ? session?.name : "";
    const routeToGoTo = useReactiveVar(routeNavigation);

    if (!Object.keys(session ?? {}).length) {
        return <EventSkeleton />;
    }

    return (
        <>
            {showOnboarding && <OnboardingEventCreateDialog refetch={refetch} />}

            <EventHeader session={session} shouldWarnAboutSlug={shouldWarnAboutSlug} />
            <div className={classes.root}>
                <EventDiffSubscription refetch={refetch} sessionId={props.id} />

                {isMenuHidden ? null : (
                    <div className={classes.menu}>
                        <div className="mb-30 flex flex-col">
                            <Tooltip arrow placement="right" title={eventNameTooltip}>
                                <Typography className="ml-15" color="secondary" variant="lg" fontWeight="bolder" noWrap>
                                    {session.name}
                                </Typography>
                            </Tooltip>
                            <Typography className="ml-15 mt-2" color="senary" variant="sm" fontWeight="bolder">
                                {dateFormat(session.startAt, [
                                    "weekdayShort",
                                    {sep: ","},
                                    "monthShort",
                                    "dayOfMonth",
                                    {sep: ", "},
                                    user?.halfDayClock ? "fullTime" : "fullTime24h",
                                ])}
                            </Typography>
                        </div>

                        <div className="mb-30 flex flex-col">
                            <Typography className={classes.category} color="quinary" fontWeight="bolder">
                                Setup
                            </Typography>
                            <Nav data-id="event-left-menu-general" to={`/event/${props.id}/general`}>
                                General
                            </Nav>
                            <Nav data-id="event-left-menu-speakers" to={`/event/${props.id}/session-speakers`}>
                                Speakers
                            </Nav>
                            <Nav data-id="event-left-menu-agenda" to={`/event/${props.id}/session-agenda`}>
                                Agenda
                            </Nav>
                            <Nav data-id="event-left-menu-payments" to={`/event/${props.id}/payments`}>
                                Payments
                            </Nav>
                        </div>

                        <div className="mb-30 flex flex-col">
                            <Typography className={classes.category} color="quinary" fontWeight="bolder">
                                Settings
                            </Typography>
                            <Nav data-id="event-left-menu-settings-access" to={`/event/${props.id}/advanced-settings`}>
                                Access
                            </Nav>
                            <Nav data-id="event-left-menu-settings-participants" to={`/event/${props.id}/permissions`}>
                                Participants permissions
                            </Nav>
                            <Nav data-id="event-left-menu-settings-widgets" to={`/event/${props.id}/widgets`}>
                                Widgets
                            </Nav>
                            <Nav data-id="event-left-menu-settings-automation" to={`/event/${props.id}/automations`}>
                                Automations
                            </Nav>
                            <Nav data-id="event-left-menu-settings-recording" to={`/event/${props.id}/recording`}>
                                Recording
                            </Nav>
                            <Nav data-id="event-left-menu-settings-livestream" to={`/event/${props.id}/livestreaming`}>
                                Live streaming
                            </Nav>
                            <Nav data-id="event-left-menu-settings-exit" to={`/event/${props.id}/exit-page`}>
                                Exit page
                            </Nav>
                        </div>

                        <div className="mb-30 flex flex-col">
                            <Typography className={classes.category} color="quinary" fontWeight="bolder">
                                Extra
                            </Typography>
                            <Nav data-id="event-left-menu-registration" to={`/event/${props.id}/registration`}>
                                Registration
                            </Nav>
                            <Nav data-id="event-left-menu-landing-page" to={`/event/${props.id}/landing-page`}>
                                Landing page
                            </Nav>
                            <Nav data-id="event-left-menu-participants" to={`/event/${props.id}/people`}>
                                Participants
                            </Nav>
                            <Nav data-id="event-left-menu-analytics" to={`/event/${props.id}/analytics`}>
                                Sharing and tracking
                            </Nav>
                        </div>
                    </div>
                )}
                <div className={classes.container}>
                    <Switch location={props.location}>
                        <Route
                            path="/event/:id/general"
                            exact={true}
                            render={() => (
                                <EventSettings
                                    key={props.id + descriptionKey}
                                    isRoom={false}
                                    setShouldWarnAboutSlug={setShouldWarnAboutSlug}
                                />
                            )}
                        />
                        <Route path="/event/:id/session-speakers" exact={true} render={() => <EventSpeakers key={props.id} />} />
                        <Route path="/event/:id/session-agenda" exact={true} render={() => <EventAgenda key={props.id} />} />
                        <Route path="/event/:id/payments" exact={true} render={() => <EventPayments key={props.id} refetch={refetch} />} />
                        <Route path="/event/:id/landing-page" exact={true} render={() => <EventLandingPage key={props.id} />} />

                        <Route
                            path="/event/:id/advanced-settings"
                            exact={true}
                            key="advanced-settings"
                            render={() => (
                                <EventAdvancedSettings
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    key={props.id}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/widgets"
                            exact={true}
                            key="advanced-settings-widgets"
                            render={() => (
                                <EventWidgets
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    key={props.id}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/permissions"
                            exact={true}
                            key="advanced-settings-perm"
                            render={() => (
                                <EventPermissions
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    key={props.id}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/automations"
                            exact={true}
                            key="advanced-settings-automation"
                            render={() => (
                                <EventAutomations
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    isTranscriptHidden={isTranscriptHidden}
                                    key={props.id}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/recording"
                            exact={true}
                            key="advanced-settings-recording"
                            render={() => (
                                <EventRecording
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    key={props.id}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/livestreaming"
                            exact={true}
                            key="advanced-settings-livestreaming"
                            render={() => (
                                <EventLivestreaming
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    key={props.id}
                                    onGoToRecordingSection={() => {
                                        history.push(`/event/${props.id}/recording`);
                                    }}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/exit-page"
                            exact={true}
                            key="advanced-settings-exit-page"
                            render={() => (
                                <EventLeaveSettings
                                    sessionTemplateNotificationDismissed={sessionTemplateNotificationDismissed}
                                    setSessionTemplateNotificationDismissed={setSessionTemplateNotificationDismissed}
                                    key={props.id}
                                />
                            )}
                        />
                        <Route
                            path="/event/:id/people"
                            exact={true}
                            key="people"
                            render={() => <EventPeople key={props.id} isMemoryMode={false} />}
                        />
                        <Route
                            path="/event/:id/registration"
                            exact={true}
                            key="registration"
                            render={() => <EventRegistration key={props.id} refetch={refetch} />}
                        />
                        <Route
                            path="/event/:id/analytics"
                            exact={true}
                            key="analytics"
                            render={() => <EventAnalytics key={props.id} isMenuHidden={isMenuHidden} setIsMenuHidden={setIsMenuHidden} />}
                        />
                    </Switch>
                </div>
            </div>
            <Watchdog
                isOwner={true}
                isAssistant={false}
                myParticipantId={session?.currentParticipant?.pid}
                sessionId={session.id}
                eventMode
            />
            <LeaveEventModal
                shouldShowPopup={!isLocked}
                shouldStartEvent={routeToGoTo === undefined && session?.event?.state === SessionEventState.Published}
                sessionId={props.id}
            />
        </>
    );
}

const EventDiffSubscription = (props: {
    sessionId: string;
    refetch:
        | ((
              variables?:
                  | Partial<
                        Exact<{
                            id: string;
                        }>
                    >
                  | undefined
          ) => Promise<ApolloQueryResult<GetSessionQuery>>)
        | undefined;
}) => {
    useSubscription(OnSessionDiffSendDocument, {
        variables: {
            sessionId: props.sessionId,
        },
        onSubscriptionData: (data) => {
            const subData = data.subscriptionData.data?.onSessionDiffSend;

            if (subData) {
                if (subData.corrupted) {
                    toast(
                        "Error while saving event. Your changes were reverted to the last functional state. Please refresh the page and try again.",
                        {
                            type: "error",
                            duration: 150000,
                        }
                    );

                    props.refetch?.();
                    return;
                }

                const collaborativeSession: CollaborativeSession | null = subData.collaborativeSessionJson;

                apollo.cache.writeQuery({
                    query: DesignerSessionChangedByDocument,
                    data: {
                        __typename: "Query",

                        designerSessionChangedBy: {
                            __typename: "DesignerSessionChangedBy",
                            changedBy: collaborativeSession?.changesBy,
                        },
                    },
                });
                delete collaborativeSession?.changesBy;

                if (subData.persisted && collaborativeSession) {
                    const updated: Omit<CollaborativeSession, "participants" | "currentParticipant"> & {
                        participants?: CollaborativeSession["participants"];
                        currentParticipant?: CollaborativeSession["currentParticipant"];
                    } = {...structuredClone(collaborativeSession), participants: undefined, currentParticipant: undefined};

                    if (updated) {
                        resetDiff(updated);

                        delete updated.participants;
                        delete updated.currentParticipant;
                        delete updated.locked;

                        writeFromFullSession(props.sessionId, updated, true);
                    }
                    return;
                }

                const locked = collaborativeSession?.locked;
                const currentLocked = apollo.cache.readQuery({
                    query: DesignerSessionLockedDocument,
                });

                if (locked !== currentLocked?.designerSessionLocked?.locked || subData.discarded) {
                    apollo.cache.writeQuery({
                        query: DesignerSessionLockedDocument,

                        data: {
                            __typename: "Query",
                            designerSessionLocked: {
                                __typename: "DesignerSessionLocked",
                                locked: subData.discarded ? undefined : locked,
                            },
                        },
                        broadcast: true,
                    });
                    props.refetch?.();
                }

                if (subData.sessionDiffJson) {
                    delete collaborativeSession?.locked;
                    const updated = structuredClone(designer.changedData());

                    if (updated) {
                        ingestDiff(updated, subData.sessionDiffJson);

                        const afterDiffIngest = structuredClone(updated) as Partial<DesignerApiSession>;
                        delete afterDiffIngest.participants;
                        delete afterDiffIngest.currentParticipant;

                        writeFromFullSession(props.sessionId, afterDiffIngest, true);
                    }
                }
            }
        },
    });

    useEffect(() => {
        const onUnload = (e) => {
            if (discardingChanges()) {
                designer.state.initializeOrResetState(null);
                evictLocalQueries(props.sessionId);
                return;
            }
            sendWorkerMessage(props.sessionId);
        };

        designer.state.setDesignerEditState(true);

        window.addEventListener("unload", onUnload);

        return () => {
            designer.state.setDesignerEditState(false);
            designer.state.initializeOrResetState(null);
            window.removeEventListener("unload", onUnload);

            apollo.cache.writeQuery({
                query: DesignerSessionLockedDocument,

                data: {
                    __typename: "Query",
                    designerSessionLocked: {
                        __typename: "DesignerSessionLocked",
                        locked: undefined,
                    },
                },
                broadcast: true,
            });
            if (!discardingChanges()) {
                apollo.client.mutate({
                    mutation: UnlockEventDocument,
                    variables: {
                        id: props.sessionId,
                    },
                });
            }
        };
    }, []);

    return null;
};

const Event = QueryRenderer({
    component: EventWithData,

    queryDoc: "GetSessionDocument",
    options: (props) => {
        return {
            variables: {
                id: props.id,
                includeRegistrationAnswers: true,
            },
        };
    },
    // @ts-expect-error not defined in type because it is used only here
    isPlayerSessionLoader: true,

    preloader: <EventSkeleton />,
});

export {Event};
