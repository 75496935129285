import {useCallback, useEffect, useRef} from "./rendering";

export function useIsMounted() {
    const ref = useRef(false);

    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        };
    }, []);

    return useCallback(() => ref.current, []);
}
