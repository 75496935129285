import Typography from "@ui/cdk/Typography";
import {useState} from "@workhorse/api/rendering";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import classes from "./style/EventParticipantsLimit.module.scss";
import Checkbox from "@ui/cdk/Checkbox";
import Input from "@ui/cdk/Input";
import designer from "@workhorse/api/designer";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {useMutation} from "@workhorse/dataApi";

const MAX_ALLOWED_PARTICIPANTS = 10000;

const EventParticipantsLimit = () => {
    const session = useSession();

    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();
    const {mixpanelTrack} = useMixpanelTracker();

    const [hasLimit, setHasLimit] = useState<boolean>(!!session?.event?.maxSeats);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [participantsLimit, setParticipantsLimit] = useState<number | null>(session?.event?.maxSeats ?? null);

    function handleOnChangeLimit(e: React.ChangeEvent<HTMLInputElement>) {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        setHasLimit(e.target.checked);
        if (!e.target.checked) {
            designer.api.event.update({
                maxSeats: null,
            });
            designer.commit();
        }
        mixpanelTrack("frontend-event-participants-limit-toggle", "events");
    }

    function handleOnChangeParticipantsNumber(e: React.ChangeEvent<HTMLInputElement>) {
        lockEvent({
            variables: {
                id: session.id,
            },
        });

        setParticipantsLimit(parseInt(e.target.value));

        mixpanelTrack("frontend-event-participants-limit-change", "events");
    }

    function handleUpdateLimit(e: React.FocusEvent<HTMLInputElement>) {
        if (parseInt(e.target.value) > MAX_ALLOWED_PARTICIPANTS) {
            setErrorMessage(`Participants limit reached.`);
            return;
        }

        const canUpdate = Number(e.target.value) >= Number(session?.event?.seatsTaken);

        if (!canUpdate) {
            setErrorMessage("Participants limit can't be less than the initial value.");
        } else {
            setErrorMessage("");
        }

        const updatedValue = canUpdate ? parseInt(e.target.value) : Number(session?.event?.seatsTaken);

        setParticipantsLimit(updatedValue);
        if (canUpdate) {
            designer.api.event.update({
                maxSeats: updatedValue,
                seatsTaken: session?.event?.seatsTaken,
            });
            designer.commit();
        }
    }

    return (
        <div className="flex flex-col fullw mb-24">
            <div className="flex flex-row fullw flex-justify-between fullw flex-align-center ">
                <div className="flex fullw flex-row flex-align-start flex-justify-start">
                    <Checkbox
                        className={classes.checkboxWrapper}
                        data-id="checkbox"
                        onChange={handleOnChangeLimit}
                        checked={hasLimit}
                        disabled={isLocked}
                    />

                    <div className="flex flex-col flex-align-start">
                        <Typography fontWeight="bold">Participants limit</Typography>
                        <Typography color="tertiary">Limit the number of participants that can register for the event</Typography>
                    </div>
                </div>

                {hasLimit ? (
                    <Input
                        disabled={isLocked}
                        type="number"
                        inputProps={{
                            min: 1,
                        }}
                        name="event-participants-limit"
                        onChange={handleOnChangeParticipantsNumber}
                        onBlur={handleUpdateLimit}
                        value={participantsLimit ?? ""}
                        formControlClassName={classes.participantsLimitInput}
                    />
                ) : null}
            </div>
            {errorMessage && <Typography color="red400">{errorMessage}</Typography>}
        </div>
    );
};

export default EventParticipantsLimit;
