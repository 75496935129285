import {ContactGroupInfoFragment, ContactInfoFragment} from "@generated/data";
import Paper from "@ui/core/components/Paper";
import {rbac} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import {cls} from "@ui/cdk/util/util";
import classes from "./styles/ContactFetcherPaper.module.scss";

interface ContactFetcherPaperProps {
    contact?: ContactInfoFragment;
    participant?: Participant;
    group?: ContactGroupInfoFragment;
    groupContacts?: ContactInfoFragment[];
    contactList?: boolean;
    handleClearParticipant: () => void;
    guestIndex?: number;
    handleClickOption: (emails: string[]) => void;
    selected: boolean;
    index: number;
}

const ContactFetcherPaper = (props: ContactFetcherPaperProps) => {
    const {contact, participant, group, contactList, groupContacts, handleClearParticipant, handleClickOption, guestIndex, selected} =
        props;

    const handleClick = () => {
        if (contact) {
            handleClickOption([contact.email]);
        }
        if (participant && participant.dataWithNullableEmail?.email) {
            handleClickOption([participant.dataWithNullableEmail?.email]);
        }

        if (groupContacts?.length) {
            handleClickOption(groupContacts.map((c) => c.email));
        }
        handleClearParticipant();
    };

    const participantIsAssistant = !participant ? false : rbac(participant.id, "session.isAssistant");

    return (
        <Paper
            elevation={2}
            className={cls("flex flex-items-center", classes.paperClass, selected && classes.selected)}
            onClick={handleClick}
            id={`result-paper-${props.index}`}
        >
            <ParticipantDisplayDetails
                classes={{
                    email: classes.searchResultEmail,
                    displayName: classes.searchResultName,
                    wrap: classes.infoBlock,
                }}
                showEmail={contact ? true : !!participant?.dataWithNullableEmail?.email}
                showName={!guestIndex}
                showProfilePicture
                firstName={contact ? contact.firstName : participant ? participant.dataWithNullableEmail?.firstName : group?.name}
                lastName={contact ? contact.lastName : participant ? participant.dataWithNullableEmail?.lastName : ""}
                email={
                    contact ? contact.email : participant ? participant.dataWithNullableEmail?.email : `${groupContacts?.length} Members`
                }
                avatar={contact ? contact.userProfilePicture : participant?.dataWithNullableEmail?.avatar}
                isUser={!participant?.dataWithNullableEmail?.isGuest}
                index={guestIndex}
                className={"flex flex-items-center"}
                delimiter={guestIndex ? undefined : <div className={classes.dot} />}
                avatarSize="L"
                renderingForAdmin={true}
                renderingForAssistant={false}
                participantIsAssistant={participantIsAssistant}
                // key={
                //     contact ? contact.email : participant ? participant.dataWithNullableEmail?.email : `${group?.contacts?.length} Members`
                // }
            />
        </Paper>
    );
};

export default ContactFetcherPaper;
