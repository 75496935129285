import {useEffect} from "@workhorse/api/rendering";
import {useDataEvent} from "@workhorse/dataApi";
import {useScreenShareOptions} from "../../providers/ConferenceRoomProvider";
import {useOrderedAsyncReplaceableTask} from "../useAsyncReplaceableTask";

export function ScreenShareStreamController() {
    const {stream, publishShareScreen, isLocalUserSharing, stopShareScreen, shareDisabled} = useScreenShareOptions();

    const screenShareEnqueue = useOrderedAsyncReplaceableTask();

    useDataEvent("onWatchdogPrivate", (data) => {
        if (data.watchdogPrivate?.stopScreenShare && isLocalUserSharing) {
            stopShareScreen();
        }
    });

    /**
     * cleanup is handled somewhere else, no need here
     */
    useEffect(() => {
        if (!stream || shareDisabled) {
            screenShareEnqueue(() => stopShareScreen());
            return;
        }

        screenShareEnqueue(() => publishShareScreen(stream));
    }, [stream, shareDisabled]);

    return null as unknown as JSX.Element;
}
