import {templatesSections, TemplatesSectionPaths} from "../utils";
import Link from "@ui/cdk/Link";
import {useParams} from "@workhorse/api/routing";
import classes from "./style/TemplatesLeftMenu.module.scss";
import {cls} from "@ui/cdk/util";
import {useUserInfo} from "@workhorse/providers/User";

const TemplatesLeftMenu = () => {
    const params = useParams<{
        section: TemplatesSectionPaths;
    }>();

    const user = useUserInfo();
    const workspaceName = user.activeWorkspace?.name ?? "Workspace";

    return (
        <div className={classes.root}>
            <div className={classes.templatesList}>
                {templatesSections.map((section) => (
                    <Link
                        data-id={section.path}
                        key={section.path}
                        to={"/templates/" + section.path}
                        className={cls(classes.templatesListItem, params.section === section.path ? classes.selected : "")}
                    >
                        {section.title !== "Workspace" ? section.title : `${workspaceName} agendas`}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default TemplatesLeftMenu;
