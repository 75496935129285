import {serialize} from "@api/editor";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {EditorSerializedContent} from "@workhorse/components/editor";
import {AgendaItem} from "@workhorse/declarations/dataTypes";
import classes from "./AgendaBoxPreview.module.scss";
import artifactDescriptor, {ArtifactTag} from "@generated/artifacts";
import env from "@generated/environment";
import AcUnitOutlinedIcon from "@material-ui/icons/AcUnitOutlined";
import {getToolName} from "@workhorse/api/utils/getToolName";

interface AgendaBoxPreviewProps {
    agendaItem: AgendaItem;
    isActive: boolean;
    setActive: (id: string) => void;
}

const AgendaBoxPreview = ({agendaItem, isActive, setActive}: AgendaBoxPreviewProps) => {
    const {descriptionJson, id} = agendaItem;

    const handleClick = () => {
        setActive(id);
    };

    const artifact = agendaItem.artifact;

    const descriptor = artifactDescriptor[(artifact?.artifactId as ArtifactTag) || "flowos/conference"];

    const icon = descriptor?.meta?.icon ? (
        <img src={`${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor?.meta?.icon}.svg`} />
    ) : (
        <AcUnitOutlinedIcon />
    );
    const toolName = getToolName(artifact?.id, artifact?.artifactId ?? "flowos/conference");

    const text = serialize(descriptionJson);
    const descriptionIsEmpty =
        (text || "").trim() === "" ||
        !descriptionJson.length ||
        (descriptionJson.lenth === 1 && descriptionJson?.[0].children?.[0].text === "");

    return (
        <div className={cls(classes.agendaBox, isActive && classes.active)} onClick={handleClick}>
            <Typography className={classes.agendaBoxTitle}>{agendaItem.title}</Typography>
            <Typography>
                <EditorSerializedContent content={descriptionJson} />
            </Typography>

            <div className={cls("flex", classes.agendaPreviewTool)}>
                {icon}
                <Typography variant="base" fontWeight="bolder" component="span">
                    {toolName?.nameByResource || toolName?.nameByDescriptor}
                </Typography>
            </div>
        </div>
    );
};

export default AgendaBoxPreview;
