import toast from "@workhorse/api/toast";
import {cls} from "@ui/cdk/util/util";
import CopyToClipboard from "react-copy-to-clipboard";
import classes from "./styles/CopyText.module.scss";
import ShareIcon from "@material-ui/icons/Share";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@ui/cdk/Tooltip";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useTranslation} from "react-i18next";

type CopyTextProps = {
    url: string;
    hasIcon?: boolean;
    showUrl?: boolean;
};

const CopyText = (props: CopyTextProps) => {
    const {t} = useTranslation();
    const {url, showUrl = true, hasIcon = true} = props;

    const {isMobile} = useMobile();

    const handleLinkCopied = () => {
        toast(t("g.toast.link_copied_success") ?? "The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });
    };

    return (
        <CopyToClipboard text={url} onCopy={handleLinkCopied}>
            <div className={cls("flex flex-items-center", classes.root, !showUrl && classes.withoutUrl)}>
                {showUrl ? (
                    <div className={cls("flex flex-items-center flex-justify-between minw0", showUrl && classes.copyUrl)}>
                        <div data-id="shareable-link" className={classes.copyText} data-private>
                            {url}
                        </div>
                    </div>
                ) : null}
                {hasIcon ? (
                    isMobile ? (
                        <ShareIcon className={classes.shareIcon} />
                    ) : (
                        <Tooltip title={t("g.copy_session_link") ?? "Copy session link"} placement="top" arrow>
                            <FileCopyOutlined className={classes.copyIcon} />
                        </Tooltip>
                    )
                ) : null}
            </div>
        </CopyToClipboard>
    );
};

export default CopyText;
