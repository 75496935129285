import environment from "@generated/environment";
import {useCallback, useEffect} from "@workhorse/api/rendering";
import {ConnectionQuality, DisconnectReason, LogLevel, Room, RoomConnectOptions, RoomEvent, setLogLevel} from "livekit-client";
import {useMeetingQueue} from "../providers";
import {useConferenceRoom, useRoomStore} from "../providers/ConferenceRoomProvider/ConferenceRoomProvider";
import {useJoinedFromOtherDevice} from "../providers/JoinedFromOtherDeviceProvider";
import {MeetingController} from "./MeetingController";
import {AudioInputStreamController} from "./MeetingController/AudioInputStreamController";
import {ScreenShareStreamController} from "./MeetingController/ScreenShareStreamController";
import {VideoInputStreamController} from "./MeetingController/VideoInputStreamController";
import apollo from "@workhorse/api/apollo";
import {GetParticipantConferenceTokenDocument} from "@generated/data";
import WebRTCIssueDetector, {INetworkScoresCalculator, IssueType, NetworkScoresCalculator} from "webrtc-issue-detector";
import {clearWorkerTimeout, setWorkerTimeout} from "@workhorse/timerWorker";
import {useEmbedSessions} from "@workhorse/api/embed";

interface MeetingJoinerProps {
    sessionId: string;
    myParticipantId: string;
    canMountPublishers: boolean;
    isLobby?: boolean;
    canMountAudioPublisher: boolean;
    canMountVideoPublisher: boolean;
}

const meetingUrl = environment.liveKitURL;

const roomConnectOptions: RoomConnectOptions = {};

setLogLevel(LogLevel.debug);

async function acquireToken(sessionId: string, isLobby: boolean) {
    try {
        const {data, error, errors, networkStatus} = await apollo.client.query({
            query: GetParticipantConferenceTokenDocument,
            variables: {
                sessionId,
            },
            fetchPolicy: "network-only",
        });

        if (error || errors) {
            console.error("LIVEKIT - error refreshing token", {error, errors, networkStatus});
        }

        if (data.currentParticipant.conferenceToken == null) {
            console.log("LIVEKIT - no token found");
            return null;
        }

        if (isLobby) {
            return data.currentParticipant.conferenceToken.lobby;
        }

        return data.currentParticipant.conferenceToken.main;
    } catch (e) {
        console.log("LIVEKIT - caught error refreshing token", e);
        return null;
    }
}

let issueDetector: WebRTCIssueDetector | undefined = undefined;
// type RTCmetric = {
//     inbound: number[];
//     outbound: number[];
// };

// // last 5 round trips
// // we consider high-rtt if at least 3 of the last 5 samples are higher than 400ms
// const last5rttSamples: RTCmetric = {
//     inbound: [],
//     outbound: [],
// };

// // last 5 packet loss samples
// // we consider high-packet-loss if at least 3 of the last 5 samples are higher than 5%
// const last5packetLossPctSamples: RTCmetric = {
//     inbound: [],
//     outbound: [],
// };

// // last 5 jitter samples
// // jitter is the measure of variability at which packets arrive at the SDK sensors
// // high jitter can result in audio quality problems on the call, such as crackling and choppy audio.
// // we consider high-hitter if at least 3 of the last 5 jitter samples are above 100ms
// const last5jitterSamples: RTCmetric = {
//     inbound: [],
//     outbound: [],
// };

// function lowest3of5(arr: number[]) {
//     const max1 = Math.max.apply(null, arr);
//     const arr2 = arr.filter((n) => n !== max1);
//     const max2 = Math.max.apply(null, arr2);
//     return arr2.filter((n) => n !== max2);
// }

// function avg(arr: number[]) {
//     return arr.reduce((sum, n) => sum + n, 0) / arr.length;
// }

// function computeQuality() {
//     let highRttInbound = false;
//     let highRttOutbound = false;
//     if (last5rttSamples.inbound.length === 5) {
//         // const lowest3rttInbound = lowest3of5(last5rttSamples.inbound);
//         // highRttInbound = avg(lowest3rttInbound) >= 400;
//         highRttInbound = Math.max.apply(null, last5rttSamples.inbound) >= 400;
//     }
//     if (!highRttInbound && last5rttSamples.outbound.length === 5) {
//         // const lowest3rttOutbound = lowest3of5(last5rttSamples.outbound);
//         // highRttOutboud = avg(lowest3rttOutbound) >= 400;
//         highRttOutbound = Math.max.apply(null, last5rttSamples.outbound) >= 400;
//     }

//     let highPacketLossInbound = false;
//     let highPacketLossOutbound = false;
//     if (last5packetLossPctSamples.inbound.length === 5) {
//         // const lowest3PacketLossInbound = lowest3of5(last5packetLossPctSamples.inbound);
//         // highPacketLossInbound = avg(lowest3PacketLossInbound) >= 5;
//         highPacketLossInbound = Math.max.apply(null, last5packetLossPctSamples.inbound) >= 5;
//     }
//     if (!highPacketLossInbound && last5packetLossPctSamples.outbound.length === 5) {
//         // const lowest3PacketLossOutbound = lowest3of5(last5packetLossPctSamples.outbound);
//         // highPacketLossOutbound = avg(lowest3PacketLossOutbound) >= 5;
//         highPacketLossOutbound = Math.max.apply(null, last5packetLossPctSamples.outbound) >= 5;
//     }

//     let highJitterInbound = false;
//     let highJitterOutbound = false;
//     if (last5jitterSamples.inbound.length === 5) {
//         // const lowest3jitterInbound = lowest3of5(last5jitterSamples.inbound);
//         // highJitterInbound = avg(lowest3jitterInbound) >= 100;
//         highJitterInbound = Math.max.apply(null, last5jitterSamples.inbound) >= 30;
//         console.log("maxJitterInbound", Math.max.apply(null, last5jitterSamples.inbound));
//     }
//     if (!highJitterInbound && last5jitterSamples.outbound.length === 5) {
//         // const lowest3jitterOutbound = lowest3of5(last5jitterSamples.outbound);
//         // highJitterOutbound = avg(lowest3jitterOutbound) >= 100;
//         highJitterOutbound = Math.max.apply(null, last5jitterSamples.outbound) >= 30;
//         console.log("maxJitterOUTbound", Math.max.apply(null, last5jitterSamples.outbound));
//     }

//     const result = {
//         highRtt: highRttInbound || highRttOutbound,
//         highPacketLoss: highPacketLossInbound || highPacketLossOutbound,
//         highJitter: highJitterInbound || highJitterOutbound,
//     };

//     if (!result.highRtt && !result.highPacketLoss && !result.highJitter) {
//         // ideal scenario
//         return ConnectionQuality.Excellent;
//     } else if (!result.highJitter) {
//         return ConnectionQuality.Good;
//     }
//     return ConnectionQuality.Poor;
// }

const stopWebRTCissueDetector = () => {
    // last5rttSamples.inbound = [];
    // last5rttSamples.outbound = [];

    // last5packetLossPctSamples.inbound = [];
    // last5packetLossPctSamples.outbound = [];

    // last5jitterSamples.inbound = [];
    // last5jitterSamples.outbound = [];

    if (issueDetector) {
        issueDetector.stopWatchingNewPeerConnections();
        issueDetector = undefined;
    }
};

// function mapQuality(q: ConnectionQuality) {
//     return Object.keys(ConnectionQuality).find((k) => ConnectionQuality[k] === q);
// }

// let timer: string | null = null;

// function debouncedSendQuality(newQuality: ConnectionQuality) {
//     if (timer != null) {
//         clearWorkerTimeout(timer);
//         timer = null;
//     }
//     timer = setWorkerTimeout(() => {
//         console.log("sending my quality", mapQuality(newQuality));
//         useRoomStore.getState().updateLocalConnectionQuality(newQuality);
//     }, 300);
// }

// const sampleSize = 4;

const startWebRTCissueDetector = () => {
    stopWebRTCissueDetector();
    issueDetector = new WebRTCIssueDetector({
        // onNetworkScoresUpdated(scores) {
        //     if (scores.statsSamples.inboundStatsSample) {
        //         if (!Number.isNaN(scores.statsSamples.inboundStatsSample?.rtt)) {
        //             last5rttSamples.inbound = last5rttSamples.inbound.slice(-sampleSize).concat(scores.statsSamples.inboundStatsSample.rtt);
        //         }
        //         if (!Number.isNaN(scores.statsSamples.inboundStatsSample.packetsLoss)) {
        //             last5packetLossPctSamples.inbound = last5packetLossPctSamples.inbound
        //                 .slice(-sampleSize)
        //                 .concat(scores.statsSamples.inboundStatsSample.packetsLoss);
        //         }
        //         if (!Number.isNaN(scores.statsSamples.inboundStatsSample.avgJitter)) {
        //             last5jitterSamples.inbound = last5jitterSamples.inbound
        //                 .slice(-sampleSize)
        //                 .concat(scores.statsSamples.inboundStatsSample.avgJitter);
        //         }
        //     }
        //     if (scores.statsSamples.outboundStatsSample) {
        //         if (!Number.isNaN(scores.statsSamples.outboundStatsSample?.rtt)) {
        //             last5rttSamples.outbound = last5rttSamples.outbound
        //                 .slice(-sampleSize)
        //                 .concat(scores.statsSamples.outboundStatsSample.rtt);
        //         }
        //         if (!Number.isNaN(scores.statsSamples.outboundStatsSample.packetsLoss)) {
        //             last5packetLossPctSamples.outbound = last5packetLossPctSamples.outbound
        //                 .slice(-sampleSize)
        //                 .concat(scores.statsSamples.outboundStatsSample.packetsLoss);
        //         }
        //         if (!Number.isNaN(scores.outbound)) {
        //             last5jitterSamples.outbound = last5jitterSamples.outbound
        //                 .slice(-sampleSize)
        //                 .concat(scores.statsSamples.outboundStatsSample.avgJitter);
        //         }
        //     }
        //     const newQuality = computeQuality();
        //     debouncedSendQuality(newQuality);
        // },
        onIssues(issues) {
            for (const issue of issues) {
                console.log(`[WebRTC issue]: ${issue.type}, reason=${issue.reason}, stats=${JSON.stringify(issue.statsSample)}`);
            }
        },
    });
    issueDetector.watchNewPeerConnections();
};

export function MeetingJoiner(props: MeetingJoinerProps) {
    const {isLobby, sessionId} = props;
    const {room, roomStatus, createRoom, deleteRoom} = useConferenceRoom();

    console.log("MEETING STATUS IN JOINER", roomStatus);

    const enqueueAction = useMeetingQueue();
    const {embedMode} = useEmbedSessions();
    const isMobileApp = embedMode === "mobile";

    const {setJoinedFromOtherDevice} = useJoinedFromOtherDevice();

    const joinMeetingAction = useCallback(async (sessionId: string, room: Room, meetingUrl: string, isLobby = false) => {
        try {
            let startTime = Date.now();
            if (props.canMountPublishers) {
                startWebRTCissueDetector();
            }

            const token = await acquireToken(sessionId, isLobby);

            if (!token) {
                console.log("LIVEKIT - no token found");
                return;
            }

            await room.prepareConnection(meetingUrl, token);
            console.log(`LIVEKIT - prewarmed ${isLobby ? "lobby" : "main"} connection in ${Date.now() - startTime}ms`);

            startTime = Date.now();

            await room.connect(meetingUrl, token, roomConnectOptions);
            const server = await room.engine.getConnectedServerAddress();

            console.log(`LIVEKIT - join ${isLobby ? "lobby" : "main"}`, {
                duration: `${Date.now() - startTime}ms`,
                status: room?.state,
                roomName: room?.name,
                server,
            });
        } catch (e) {
            console.log(`LIVEKIT - joinError ${isLobby ? "lobby" : "main"} `, e);
        }
    }, []);

    const leaveMeetingAction = useCallback(async (room: Room, isLobby = false) => {
        try {
            console.log(`LIVEKIT - Trying to leave ${isLobby ? "lobby" : "main"} meeting`);
            stopWebRTCissueDetector();
            await room.disconnect();
            console.log(`LIVEKIT - Left ${isLobby ? "lobby" : "main"} meeting`);
        } catch (e) {
            console.log(`LIVEKIT - cannot leave ${isLobby ? "lobby" : "main"} meeting`, e);
        }
    }, []);

    const joinMeeting = useCallback(
        (sessionId: string, room: Room, meetingUrl: string, isLobby?: boolean) => {
            enqueueAction(() => joinMeetingAction(sessionId, room, meetingUrl, isLobby));
        },
        [joinMeetingAction, enqueueAction]
    );

    const leaveMeeting = useCallback(
        (room: Room, isLobby?: boolean) => {
            enqueueAction(() => leaveMeetingAction(room, isLobby));
        },
        [leaveMeetingAction, enqueueAction]
    );

    useEffect(() => {
        const room = createRoom(sessionId, isLobby ?? false);

        joinMeeting(sessionId, room, meetingUrl, isLobby);

        const handleDisconnect = (reason: DisconnectReason) => {
            if (reason === DisconnectReason.DUPLICATE_IDENTITY || reason === DisconnectReason.CLIENT_INITIATED) {
                return;
            }
            console.log("LIVEKIT - disconnected trying to reconnect");
            joinMeeting(sessionId, room, meetingUrl, isLobby);
        };

        room.addListener(RoomEvent.Disconnected, handleDisconnect);

        return () => {
            room.removeListener(RoomEvent.Disconnected, handleDisconnect);
            deleteRoom(room);
            leaveMeeting(room, isLobby);
        };
    }, [sessionId, isLobby, joinMeeting, leaveMeeting, createRoom, deleteRoom]);

    useEffect(() => {
        if (!room || !roomStatus.joinedFromAnotherDevice || isMobileApp) {
            return;
        }
        setJoinedFromOtherDevice(true);
    }, [room, roomStatus.joinedFromAnotherDevice, setJoinedFromOtherDevice]);

    if (!props.canMountPublishers) {
        return null;
    }

    return (
        <>
            <AudioInputStreamController disableBroadcast={!props.canMountAudioPublisher} />
            <VideoInputStreamController disableBroadcast={!props.canMountVideoPublisher} />
            <ScreenShareStreamController />
            {roomStatus.connected && <MeetingController />}
        </>
    );
}
