import {AgendaTemplateCreateInput, AgendaTemplateUpdateInput} from "@generated/data";
import {readQuery} from "@workhorse/dataApi";
import {DesignerApiSession as Session} from "@workhorse/declarations/dataTypes";
import {buildAgendaItemCreatePayload} from "@sessions/common/diff/payloads/buildAgendaItemCreatePayload";

import {IsObject} from "@workhorse/declarations";
import {addMinutes, differenceInMinutes, startOfDay} from "date-fns";
import buildAgendaItemsUpdatePayload from "@sessions/common/diff/payloads/buildAgendaItemsUpdatePayload";
import {TEMPLATE_FOLDER_NAME_FOR_USERS} from "@sessions/common/agenda-templates";
import {OmitTypenames} from "@sessions/common/diff/types";

const adjustExcludedDate = (date: Date, dtStart: Date): Date => {
    const dateDelta = differenceInMinutes(date, startOfDay(date));
    const startDelta = differenceInMinutes(dtStart, startOfDay(dtStart));
    if (dateDelta !== startDelta) {
        return addMinutes(startOfDay(date), startDelta);
    }

    return date;
};

type IsObjectOrArray<T> = T extends Array<any> ? true : IsObject<T> extends true ? true : false;

// if the Session model ever changes, add excluded fields that cannot be updated, in this type
// everything will fail in cascade and force you to adapt the logic below
type ExcludedSessionKeys =
    | "actualStart"
    | "artifacts"
    | "chat"
    | "childOfBreakoutRooms"
    | "chimeMeeting"
    | "createdAt"
    | "deletionInProgress"
    | "draftForLiveSession"
    | "flow"
    | "id"
    | "lifecycle"
    | "liveDraft"
    | "notifications"
    | "occurrenceId"
    | "updatedAt"
    | "endedAt"
    | "createdFromYourFirstSessionTemplate"
    | "followUpOfSessionId"
    | "isChildOfBreakoutRooms"
    | "recordings"
    | "speakerUserIds"
    | "templateFolder"
    | "bookingEvents"
    | "user"
    // participants never get updated via this method
    | "participants";

type SessionUpdateableKeys = Exclude<keyof AgendaTemplateUpdateInput, ExcludedSessionKeys>;

const updateableKeys: Array<SessionUpdateableKeys> = ["agendaItems", "name", "description", "isPublic"];

type SessionUpdateInputPayload = OmitTypenames<Session>;

export type AgendaTemplateTagData = {
    id: string;
    action: "connect" | "disconnect";
};

export function buildUpdateAgendaTemplatePayload(input: SessionUpdateInputPayload, isPublic?: boolean): AgendaTemplateUpdateInput {
    const {agendaItems, session} = input!;
    const templateId = (session?.id ?? "").replace("_template", "");

    if (!session) {
        throw new Error(`cannot build update payload for agenda template because... no template duah!`);
    }

    const updatePayload: Pick<AgendaTemplateUpdateInput, SessionUpdateableKeys> = {};
    // console.log("recurrence: 0 ", session);
    updateableKeys.forEach((k) => {
        switch (k) {
            case "description": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }

            case "name": {
                if (k in session) {
                    updatePayload[k] = {
                        set: session[k],
                    };
                }
                break;
            }

            case "agendaItems": {
                if (agendaItems) {
                    updatePayload[k] = buildAgendaItemsUpdatePayload(agendaItems);
                }
                break;
            }

            case "isPublic": {
                if (isPublic !== undefined) {
                    updatePayload[k] = {
                        set: isPublic,
                    };
                }
                break;
            }

            default: {
                break;
            }
        }
    });

    return updatePayload;
}

export function buildCreateAgendaTemplatePayload(input: OmitTypenames<Session>): AgendaTemplateCreateInput {
    const {user} = readQuery("GetRemoteUserDocument")?.getRemoteUser! ?? {};

    const {agendaItems, session} = input!;

    const {id, name, description} = session! ?? {};

    return {
        id,
        name,
        description,

        agendaItems: agendaItems?.length
            ? {
                  create: agendaItems.map(buildAgendaItemCreatePayload),
              }
            : undefined,

        user: {
            connect: {
                id: user?.id,
            },
        },
    };
}
