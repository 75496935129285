import {cls} from "@ui/cdk/util/util";
import {Fragment, createElement, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import DrawerLeft from "@workhorse/components/drawers/DrawerLeft";
import DrawerRight from "@workhorse/components/drawers/DrawerRight";
import Footer from "@workhorse/components/footer";
import Header from "@workhorse/components/header";
import {OnboardingMicro} from "@workhorse/components/onboarding-tooltips";
import {OverlayOnboarding} from "@workhorse/components/overlay-onboarding";
import {WithChildren, WithHostType} from "@workhorse/declarations";
import {useHostType} from "./HostTypeProvider";
import LazyDialogs from "./LazyDialogs";
import {MainLoadingState} from "./MainLoadingState";
import {useMobile} from "./MobileProvider";
import {useOnboardingBackdrop} from "./OnboardingSessionProvider";
import {useIsLobby} from "./SessionDataProviders";
import SubHeader from "./SubHeader";
import {useUserInfo} from "./User";
import WithDOMEvents from "./WithDOMEvents";
import {useDrawerLeftToggler, useDrawerRightToggler, useFullScreenState} from "./state";
import {useDrawerLeftWidth} from "./useDrawerLeftWidth";
import {useDrawerRightWidth} from "./useDrawerRightWidth";
import {HostType} from "@generated/data";
import ARIAAnnouncer from "@workhorse/components/ARIAAnnouncer";
import {useEmbedSessions} from "@workhorse/api/embed";

type LayoutProps = {} & WithChildren;

type MainContentProps = {
    isMobile: boolean;
    isFullScreen: boolean;
} & WithChildren &
    WithHostType;

function MainContent(props: MainContentProps) {
    const {isMobile, hostType, isFullScreen} = props;
    const {isLobby} = useIsLobby();

    const prevHostType = useRef(hostType);
    const [mounted, setMounted] = useState(false);

    const isChooseOrgPage = hostType === HostType.ChooseOrganization || hostType === HostType.OrganizationInvite;
    const chooseOrgClasses = "no-transition-effect no-left-padding";

    useEffect(() => {
        setTimeout(() => {
            prevHostType.current = hostType;
        }, 150);
    }, [hostType]);

    const [drawerLeftState] = useDrawerLeftToggler();
    const [drawerRightState] = useDrawerRightToggler();
    const drawerLeftWidth = useDrawerLeftWidth();
    const drawerRightWidth = useDrawerRightWidth();

    const location = useOnboardingBackdrop();

    const mainContentStyles: React.CSSProperties = useMemo(() => {
        return {
            paddingLeft: isMobile || drawerLeftState.isDetached ? 0 : drawerLeftWidth[drawerLeftState.state],
            paddingRight:
                isMobile || drawerRightState.isDetached
                    ? 0
                    : drawerRightWidth[drawerRightState.state]
                    ? drawerRightWidth[drawerRightState.state] + 12
                    : 0,
        };
    }, [drawerLeftWidth, drawerLeftState.state, hostType]);

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, []);

    return !mounted
        ? null
        : (createElement(
              WithDOMEvents,
              {key: "with-dom-events"},
              <div
                  id={"main-content-wrapper"}
                  style={mainContentStyles}
                  className={cls("main-content-wrapper", isLobby && "with-lobby", isChooseOrgPage && chooseOrgClasses)}
              >
                  <OnboardingMicro>
                      <div className={cls("main-content", isFullScreen && "fullscreen", location === "player" && "near-overlay")}>
                          {props.children}
                      </div>
                  </OnboardingMicro>
              </div>
          ) as unknown as JSX.Element);
}

function Layout(props: LayoutProps) {
    const {isGuest, isAuthenticated, isRecorder} = useUserInfo();
    const hostType = useHostType();

    const {uiElementsToRemove} = useEmbedSessions();

    const isPlayer = hostType === HostType.Player;
    const isFullScreen = useFullScreenState();
    const mobileState = useMobile();

    if (isRecorder && !isPlayer) {
        return <>{props.children}</>;
    }

    return (
        <>
            {!uiElementsToRemove.includes("header") ? <Header key="header" isFullScreen={isFullScreen} mobileState={mobileState} /> : null}

            {!uiElementsToRemove.includes("sub_header") ? (
                <SubHeader key="sub-header" isFullScreen={isFullScreen} mobileState={mobileState} />
            ) : null}

            <MainContent isFullScreen={isFullScreen} isMobile={mobileState.isMobile} key="main-content" hostType={hostType}>
                {props.children}
            </MainContent>

            {!uiElementsToRemove.includes("footer") ? <Footer key="footer" isFullScreen={isFullScreen} mobileState={mobileState} /> : null}

            {!uiElementsToRemove.includes("right_drawer") ? (
                <DrawerRight key="drawer-right" isFullScreen={isFullScreen} mobileState={mobileState} hostType={hostType} />
            ) : null}

            {!uiElementsToRemove.includes("left_drawer") ? (
                <DrawerLeft
                    key="drawer-left"
                    isFullScreen={isFullScreen}
                    mobileState={mobileState}
                    hostType={hostType}
                    isGuest={isGuest}
                    isAuthenticated={isAuthenticated}
                />
            ) : null}

            {!uiElementsToRemove.includes("lazy_dialogs") ? (
                <LazyDialogs key="lazy-dialogs" isGuest={isGuest} hostType={hostType} mobileState={mobileState} />
            ) : null}

            <MainLoadingState />
            <OverlayOnboarding />
            <ARIAAnnouncer />
        </>
    );
}

export default Layout;
