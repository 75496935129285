import TableBody from "@ui/cdk/Table/TableBody";
import {RoomsHeadCellType} from "./utils";
import RoomsTableEmptyState from "./RoomsTableEmptyState";
import RoomsTableRow from "./RoomsTableRow";
import classes from "./style/RoomsTableBody.module.scss";
import {RoomDataFragment} from "@generated/data";
import {useUserInfo} from "@workhorse/providers/User";

type RoomsTableBodyProps = {
    tableColumns: RoomsHeadCellType;
    rooms: RoomDataFragment[];
    handleDelete: (id: string) => void;
    disableRoom: (id: string) => void;
    isSearching: boolean;
    enableRoom: (roomId: string) => void;
};

function RoomsTableBody(props: RoomsTableBodyProps) {
    const {rooms, tableColumns, handleDelete, isSearching, disableRoom, enableRoom} = props;
    const user = useUserInfo();

    if (rooms.length === 0) {
        return <RoomsTableEmptyState isSearching={isSearching} />;
    }

    return (
        <TableBody className={classes.root}>
            {rooms.map((room) => (
                <RoomsTableRow
                    key={room.id}
                    room={room}
                    tableColumns={tableColumns}
                    onDelete={handleDelete}
                    disableRoom={disableRoom}
                    enableRoom={enableRoom}
                    extra={{
                        halfDayClock: user.halfDayClock,
                    }}
                />
            ))}
        </TableBody>
    );
}

export default RoomsTableBody;
