import EventsLandingPageImagesUpload from "./EventsLandingPageImagesUpload";
import EventsLandingPageColorPickers from "./EventsLandingPageColorPickers";
import EventsLandingPageSocialLinks from "./EventsLandingPageSocialLinks";
import Typography from "@ui/cdk/Typography";
import commonClasses from "../eventCommons.module.scss";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {SessionLifecycle} from "@generated/data";
import {useMemo} from "@workhorse/api/rendering";
import EventSkeleton from "../event-skeleton/event-skeleton";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import EventsLandingPageEmbed from "./EventsLandingPageEmbed";
import EventsLandingPageLanguageSelector from "./EventsLandingPageLanguageSelector";

const EventsLandingPage = () => {
    const {event, lifecycle, backstage, id} = useSession();

    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => (lifecycle === SessionLifecycle.Started && !backstage) || isLocked || lifecycle === SessionLifecycle.Ended,
        [lifecycle, isLocked, backstage]
    );

    if (!event) {
        return <EventSkeleton />;
    }

    return (
        <div className={commonClasses.mainContainer}>
            <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-30">
                Landing page
            </Typography>
            <EventsLandingPageImagesUpload readOnly={eventNotUpdatable} />
            <EventsLandingPageColorPickers readOnly={eventNotUpdatable} />

            <EventsLandingPageLanguageSelector readOnly={eventNotUpdatable} />
            <EventsLandingPageEmbed readOnly={eventNotUpdatable} />
            <EventsLandingPageSocialLinks readOnly={eventNotUpdatable} />
        </div>
    );
};

export default EventsLandingPage;
