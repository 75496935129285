import {SessionFlags, SessionLifecycle} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useMemo} from "@workhorse/api/rendering";
import MemoryAccessOption from "@workhorse/api/session-settings/sections/AccessSection/components/MemoryAccessOption";
import SessionConsentOption from "@workhorse/api/session-settings/sections/AccessSection/components/SessionConsentOption";
import SessionLockSessionToggler from "@workhorse/api/session-settings/sections/AccessSection/components/SessionLockSessionToggler";
import SessionRequestGuestEmailToggler from "@workhorse/api/session-settings/sections/SessionSection/components/SessionRequestGuestEmailToggler";
import SessionRequestPasscode, {
    generateSessionPasscode,
} from "@workhorse/api/session-settings/sections/SessionSection/components/SessionRequestPasscode";
import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import commonClasses from "../eventCommons.module.scss";
import classes from "./style/EventAdvancedSettings.module.scss";

export enum SettingsPresetsValues {
    MODERATORS = "moderators",
    EVERYONE = "everyone",
    CUSTOM = "custom",
}

type EventAdvancedSettingsProps = {
    isRoom?: boolean;
    onChange?: () => void;
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
};

export function EventAdvancedSettings(props: EventAdvancedSettingsProps) {
    const {isRoom, sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed} = props;

    const session = useSession();

    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => (!isRoom ? [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked : false),
        [session.lifecycle, isLocked, isRoom]
    );

    const handleOnChange = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey]) => {
        if (!isRoom) {
            lockEvent({
                variables: {
                    id: session.id,
                },
            });
        }

        const updateSessionPasscode =
            key === "provideSessionPasscode"
                ? {
                      passcodeToJoin: value ? generateSessionPasscode() : null,
                      bypassSessionPasscode: value,
                  }
                : {};

        designer.api.session.update({
            [key]: value,
            ...updateSessionPasscode,
        });
        props.onChange?.();

        if (!isRoom) {
            designer.commit();
        }
    };

    return (
        <div className={commonClasses.mainContainer}>
            <div className="flex flex-align-center flex-justify-between">
                <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-24">
                    Access settings
                </Typography>
            </div>

            <SessionLockSessionToggler
                enabled={session.requestPermissionToJoin}
                onChange={handleOnChange.bind(null, "requestPermissionToJoin")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <SessionRequestGuestEmailToggler
                enabled={session.askForGuestEmail}
                onChange={handleOnChange.bind(null, "askForGuestEmail")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />
            <div className={classes.separator} />

            <SessionConsentOption
                enabled={session.requireConsentToRecord}
                onChange={handleOnChange.bind(null, "requireConsentToRecord")}
                showIcon={true}
            />
            <div className={classes.separator} />

            <MemoryAccessOption
                value={session.memoryAccessType}
                onChange={handleOnChange.bind(null, "memoryAccessType")}
                className={classes.memoryAccess}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <SessionRequestPasscode
                enabledRequest={session.provideSessionPasscode}
                enabledBypass={session.bypassSessionPasscode}
                passcodeValue={session.passcodeToJoin ?? undefined}
                onChangeToggleRequest={handleOnChange.bind(null, "provideSessionPasscode")}
                onChangePasscodeValue={handleOnChange.bind(null, "passcodeToJoin")}
                onChangeToggleBypass={handleOnChange.bind(null, "bypassSessionPasscode")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <SessionTemplateSettingsNotification
                type="event"
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={commonClasses.sessionTemplateNotification}
            />
        </div>
    );
}
