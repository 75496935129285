import Tooltip from "@ui/cdk/Tooltip";
import {useTranslation} from "react-i18next";

interface Props {
    children: React.ReactElement;
    enabled: boolean;
}

export function WithJoinTooltip(props: Props) {
    const {t} = useTranslation();
    if (!props.enabled) {
        return <>{props.children}</>;
    }

    return <Tooltip title={t("prejoin.info_tooltip_join_button") ?? ""}>{props.children}</Tooltip>;
}
