import {makeStyles} from "@material-ui/core";
import MuiTooltip from "@material-ui/core/Tooltip";
import React, {forwardRef} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "./util";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: theme.typography.pxToRem(11),
        padding: "6px 11px",
        borderRadius: 8,
        backgroundColor: `${theme.main.palette.elevation.above[4]} !important`,
        boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
        color: `${theme.main.palette.text.tooltipPrimary} !important`,
    },
    arrow: {
        "&:before": {
            backgroundColor: `${theme.main.palette.elevation.above[4]} !important`,
        },
    },
}));

export type TooltipProps = ExtractProps<typeof MuiTooltip>;

const Tooltip = forwardRef((props: TooltipProps, ref: React.MutableRefObject<any>) => {
    const classes = useStyles();
    return (
        <MuiTooltip
            {...props}
            classes={{
                ...props.classes,
                tooltip: cls(classes.tooltip, props.classes?.tooltip),
                arrow: cls(classes.arrow, props.classes?.arrow),
            }}
        />
    );
});

Tooltip.displayName = "Tooltip";

export default Tooltip;
