import {BillingPeriod, OrganizationPlan} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import classes from "./BillingCycle.module.scss";
import {cls} from "@ui/cdk/util";
import {useState} from "@workhorse/api/rendering";
import {OrderPrice} from "./OrderSummary";
import {useClientEvent} from "@api/events/client";

type BillingCycleProps = {
    plan: OrganizationPlan;
    selected: BillingPeriod;
    onSelect: (period: BillingPeriod) => void;
    monthlyPrice: number;
    yearlyPrice: number;
    skipTrialDiscount?: boolean;
};

const BillingCycle = (props: BillingCycleProps) => {
    const [orderPrice, setOrderPrice] = useState<OrderPrice | null>(null);

    const [loading, setLoading] = useState(true);

    useClientEvent("order-price-update", (price: OrderPrice) => {
        setOrderPrice(price);
    });

    useClientEvent("paddle-set-loading", () => {
        setLoading(true);
    });

    useClientEvent("checkout-loaded", () => {
        setLoading(false);
    });

    let monthlyPrice = props.selected === BillingPeriod.Monthly ? orderPrice?.net_after_discount ?? props.monthlyPrice : props.monthlyPrice;

    let yearlyPrice =
        props.selected === BillingPeriod.Yearly && orderPrice?.net_after_discount ? orderPrice?.net_after_discount / 12 : props.yearlyPrice;

    if (props.skipTrialDiscount) {
        if (props.selected === BillingPeriod.Monthly) {
            yearlyPrice = yearlyPrice - yearlyPrice * 0.2;
        }
        if (props.selected === BillingPeriod.Yearly) {
            monthlyPrice = monthlyPrice - monthlyPrice * 0.2;
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant="lg" color="primary" className="mb-20" fontWeight="bold">
                Choose billing cycle
            </Typography>

            <div className={classes.body}>
                <div
                    onClick={() => props.onSelect(BillingPeriod.Monthly)}
                    className={cls(classes.billingCycleCard, props.selected === BillingPeriod.Monthly && classes.selected)}
                >
                    <div className={classes.header}>
                        <Typography variant="base" fontWeight="bolder">
                            Monthly
                        </Typography>
                        {props.selected === BillingPeriod.Monthly ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                    </div>

                    <div className={classes.cardBody}>
                        {loading ? (
                            <div className={classes.priceSkeleton}></div>
                        ) : (
                            <div className={classes.price}>
                                <Typography variant="xl3" fontWeight="bolder">
                                    ${monthlyPrice.toFixed(2)}
                                </Typography>{" "}
                                <Typography variant="sm" color="tertiary" className="mb-7">
                                    per month
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    onClick={() => props.onSelect(BillingPeriod.Yearly)}
                    className={cls(classes.billingCycleCard, props.selected === BillingPeriod.Yearly && classes.selected)}
                >
                    <div className={classes.header}>
                        <Typography variant="base" fontWeight="bolder">
                            Annually
                        </Typography>
                        {props.selected === BillingPeriod.Yearly ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                    </div>

                    <div className={classes.cardBody}>
                        <div className={classes.badge}>SAVE 20%</div>

                        {loading ? (
                            <div className={classes.priceSkeleton}></div>
                        ) : (
                            <div className={classes.price}>
                                <Typography variant="xl3" fontWeight="bolder">
                                    ${yearlyPrice.toFixed(2)}
                                </Typography>{" "}
                                <Typography variant="sm" color="tertiary" className="mb-7">
                                    per month
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingCycle;
