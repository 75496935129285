import {ArtifactTag} from "@generated/artifacts";
import {DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {MacroArtifact} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";
import {makeId} from "../../utils";

export type AddMacroArtifactInput = {
    artifactName: string;
    artifactTag: ArtifactTag;
    overrides?: Partial<DeepMandatoryWithNullVals<MacroArtifact>>;
};

export class AddMacroArtifact extends DesignerAction<AddMacroArtifactInput> {
    commit() {
        const macroArtifacts = designer.currentMacroArtifacts();
        const existing = macroArtifacts.find((a) => a.artifactId === this.input.artifactTag);

        if (existing) {
            return existing.id;
        }

        const now = null as unknown as Date;

        const newArtifactId = makeId();

        designer.updateCurrentSession(
            {
                macroArtifacts: macroArtifacts.concat({
                    artifactId: this.input.artifactTag,
                    data: {},
                    description: "",
                    durationInSeconds: 0,
                    endedAt: null,
                    id: newArtifactId,
                    oldId: newArtifactId,
                    isConfigured: false,
                    name: this.input.artifactName,
                    order: 0,
                    properties: [],
                    startAt: null,
                    updatedAt: now,
                    createdAt: now,
                    isMacro: true,
                    isDeleted: false,
                    update: null,
                    ...(this.input.overrides ?? {}),
                    __typename: "Artifact",
                }),
            },
            {
                strategy: "replace",
            }
        );

        return this.input.overrides?.id ?? newArtifactId;
    }
}
