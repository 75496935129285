import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import classes from "./onboarding.module.scss";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {useEffect} from "@workhorse/api/rendering";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

type OnboardingPlayButtonProps = WithTooltipChildren & {
    hasBeenClicked: boolean;
};

export function OnboardingPlayButton(props: OnboardingPlayButtonProps) {
    const {tooltip, flow, completedFlows, next, nextFlow} = useOnboardingSession();

    let title = (
        <>
            <div className="flex flex-align-center">
                <InfoOutlinedIcon className="mr-6" />
                <Typography className="title" fontWeight="bolder">
                    Play the video for everyone
                </Typography>
            </div>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                Once you press play, the video will play for your participants too.
            </Typography>
        </>
    );

    const skip = (tooltip === "tool-controls" && props.hasBeenClicked) || tooltip !== "tool-controls";

    useEffect(() => {
        if (props.hasBeenClicked && flow === "open-a-link") {
            nextFlow({
                completeFlow: true,
                nextTooltip: 0,
                delay: 2000,
            });
        }
    }, [props.hasBeenClicked, flow, nextFlow, completedFlows]);

    return (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement="top"
            arrow={true}
            skip={skip}
            classes={{tooltip: classes.tooltipBigger}}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
