import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {forwardRef} from "@workhorse/api/rendering";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";

const PreviewFileOption = forwardRef(
    ({onClick, disabled}: {onClick: () => void; disabled?: boolean}, ref: React.MutableRefObject<unknown>) => {
        return (
            <MenuItem
                data-id="preview"
                ref={ref}
                disabled={disabled}
                onClick={onClick}
                classes={{
                    root: "blue",
                }}
            >
                <OpenInBrowserIcon className="svg-blue-500" />
                Preview
            </MenuItem>
        );
    }
);

export default PreviewFileOption;
