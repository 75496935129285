import {SessionData} from "@workhorse/declarations/dataTypes";
import SessionAudienceParticipants from "./SessionAudienceParticipants";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/SessionAudience.module.scss";
import Inject from "@workhorse/providers/Injector";

type SessionAudienceProps = {
    session: SessionData;
    myParticipantId: string;
    isJoined: boolean;
    sessionStarted: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    sessionIsPublished: boolean;
};

function SessionAudience(props: SessionAudienceProps) {
    const {session, myParticipantId, isAdmin, isAssistant, sessionIsPublished, isJoined} = props;
    const {id: sessionId} = session;

    return (
        <Inject
            target="inSubHeader"
            id="session-audience-injected"
            className={cls("flex-center-all session-audience", classes.sessionAudience)}
        >
            <SessionAudienceParticipants
                key="session-audience-participants"
                sessionId={sessionId}
                myParticipantId={myParticipantId}
                isAdmin={isAdmin}
                isAssistant={isAssistant}
                sessionIsPublished={sessionIsPublished}
                isJoined={isJoined}
            />
        </Inject>
    );
}

export default SessionAudience;
