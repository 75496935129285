import {useState, useEffect, useRef} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/input.module.scss";

export type InputCharCounterImperativeRef = React.MutableRefObject<
    | {
          setCount?: React.Dispatch<React.SetStateAction<number>>;
      }
    | undefined
>;

export type InputCharCounterProps = {
    iRef: InputCharCounterImperativeRef;
    maxCharCount?: number;
    value: string;
};

function InputCharCounter(props: InputCharCounterProps) {
    const {iRef, maxCharCount, value} = props;
    const [count, setCount] = useState((value || "").trim().length);

    useEffect(() => {
        iRef.current = {
            setCount,
        };
    }, []);

    return (
        <span className={cls(classes.charCounter, count === maxCharCount && "max-count-reached")}>
            <span>
                {count}
                {maxCharCount ? `/${maxCharCount}` : null}
            </span>
        </span>
    );
}

export default InputCharCounter;
