import {makeId} from "@workhorse/api/designer/lib/utils";
import {AudioStreamSegment} from "./AudioStreamSegment";
import {Queue} from "async-await-queue";
import services from "@api/service/client";
import {makeRoomSlugFromRoute, getSessionIdByRoomSlug, isRoom, makeSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {readCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {clearWorkerInterval, clearWorkerTimeout, setWorkerInterval, setWorkerTimeout} from "@workhorse/timerWorker";
import apollo from "@workhorse/api/apollo";
import {CreateTranscriptDocument, CreateUploadUrlForTranscriptDocument} from "@generated/data";

export let NOISE_TIMEOUT = 4000;
export let NOISE_THRESHOLD = 0.2;
export let SUFFICIENT_NOISE = 2;

export let SEGMENT_MAX_SIZE_LIMIT = 400 * 1024;
export let SEGMENT_CLEANUP_LIMIT = 12 * 1024;

export let LOG_NOISE = false;

export let LOG_SEGMENT_SIZE = false;

export let LANGUAGE_HINT: string | undefined = undefined;

const segLogger = (br: number) => {
    if (LOG_SEGMENT_SIZE) {
        console.log("TRANSCRIBER: SEGMENT SIZE", br / 1024);
    }
};

export class AudioStreamTranscriber {
    private segment: AudioStreamSegment;

    order = 0;

    instanceId = makeId();

    noiseIndex = 0;
    isSilent = true;
    noiseTimeout?: string | null;

    periodicCheckId?: string | null;

    stopped = true;

    constructor(private stream: MediaStream, private queue: Queue) {
        this.segment = new AudioStreamSegment(stream, segLogger);
    }

    setLanguageHint(value?: string) {
        LANGUAGE_HINT = value;
    }

    setLogSegmentSize(value: boolean) {
        LOG_SEGMENT_SIZE = value;
    }

    setNoiseTimeout(value: number) {
        NOISE_TIMEOUT = value;
    }

    setNoiseThreshold(value: number) {
        NOISE_THRESHOLD = value;
    }

    setSufficientNoise(value: number) {
        SUFFICIENT_NOISE = value;
    }

    setSegmentMaxSizeLimit(value: number) {
        SEGMENT_MAX_SIZE_LIMIT = value * 1024;
    }

    setSegmentCleanupLimit(value: number) {
        SEGMENT_CLEANUP_LIMIT = value * 1024;
    }

    setLogNoise(value: boolean) {
        LOG_NOISE = value;
    }

    start() {
        if (!this.stopped) {
            return;
        }

        console.log("TRANSCRIBER: start");

        this.stopped = false;
        this.segment = new AudioStreamSegment(this.stream);
        this.segment.start();

        this.startPeriodicCheck();
    }

    stop() {
        if (this.stopped) {
            return;
        }

        console.log("TRANSCRIBER: stop");

        this.scheduleSegment(true);

        this.stopped = true;
        this.isSilent = true;
        this.noiseIndex = 0;
        this.segment.stop();

        this.clearNoiseTimeout();
        this.clearPeriodicCheck();
    }

    reset() {
        this.isSilent = true;
        this.noiseIndex = 0;
        this.clearNoiseTimeout();

        this.segment.stop();
        this.segment = new AudioStreamSegment(this.stream, segLogger);
        this.segment.start();
    }

    addNoise(db: number) {
        if (this.stopped) {
            return;
        }

        if (LOG_NOISE) {
            console.log("TRANSCRIBER: noise", db);
        }

        if (db < NOISE_THRESHOLD) {
            return;
        }

        this.noiseIndex += 1;
        this.isSilent = false;

        this.scheduleNoiseTimeout();
    }

    startPeriodicCheck() {
        this.periodicCheckId = setWorkerInterval(() => {
            this.checkSegment();
        }, 500);
    }

    clearPeriodicCheck() {
        if (this.periodicCheckId) {
            clearWorkerInterval(this.periodicCheckId);
        }
    }

    scheduleNoiseTimeout() {
        this.clearNoiseTimeout();

        this.noiseTimeout = setWorkerTimeout(() => {
            this.isSilent = true;
            console.log("TRANSCRIBER:", "is silent, checking segment");
            this.checkSegment();
        }, NOISE_TIMEOUT);
    }

    clearNoiseTimeout() {
        if (this.noiseTimeout) {
            clearWorkerTimeout(this.noiseTimeout, true);
        }
    }

    checkSegment() {
        this.clearSegment();
        this.scheduleSegment();
    }

    canSendSegment(final = false) {
        if (this.segment.size === 0) {
            return false;
        }

        if (final === false && !this.isSilent && this.segment.size < SEGMENT_MAX_SIZE_LIMIT) {
            return false;
        }

        return this.noiseIndex >= SUFFICIENT_NOISE;
    }

    clearSegment() {
        if (this.noiseIndex >= SUFFICIENT_NOISE) {
            return;
        }

        if (this.segment.size > SEGMENT_CLEANUP_LIMIT) {
            // console.log("TRANSCRIBER:", "clearing segment");
            this.reset();
        }
    }

    scheduleSegment(final = false) {
        if (!this.canSendSegment(final)) {
            return;
        }

        console.log("TRANSCRIBER:", "sending segment");

        this.order += 1;
        const segmentRef = this.segment;
        const noise = this.noiseIndex;

        if (!final) {
            this.reset();
        }

        let sessionId: string | null = null;
        let roomSlug: string | null = null;

        if (isRoom()) {
            roomSlug = makeRoomSlugFromRoute(window.location.pathname);
        } else {
            sessionId = makeSessionIdFromRoute(window.location.pathname);
        }

        this.queue.run(() => {
            return this.sendSegment(segmentRef, sessionId, roomSlug, noise);
        });
    }

    async getSessionId(sessionId: string | null, roomSlug: string | null) {
        if (sessionId) {
            return sessionId;
        }

        if (roomSlug) {
            return getSessionIdByRoomSlug(roomSlug);
        }

        return null;
    }

    async sendSegment(segment: AudioStreamSegment, maybeSessionId: string | null, maybeRoomSlug: string | null, noise: number) {
        const payload = new FormData();
        payload.append("model", "whisper-1");

        try {
            const sessionId = await this.getSessionId(maybeSessionId, maybeRoomSlug);

            if (!sessionId) {
                return console.error("TRANSCRIBER:", "No session id found for transcriber");
            }

            const currentParticipant = readCurrentParticipant({sessionId});
            const participantId = currentParticipant?.id;

            if (!participantId) {
                return console.error("TRANSCRIBER:", "No participant id found for transcriber");
            }

            const {file, mimeType} = await segment.createFile(this.instanceId + "-" + this.order);

            const createUploadUrlMutation = await apollo.client.mutate({
                mutation: CreateUploadUrlForTranscriptDocument,
                variables: {
                    sessionId,
                    participantId,
                    mimeType,
                },
            });

            if (!createUploadUrlMutation.data) {
                throw new Error("TRANSCRIBER: Failed to create upload url");
            }

            const {uploadUrl, url} = createUploadUrlMutation.data.createUploadUrlForTranscript;

            const res = await fetch(uploadUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                },
            });

            if (!res.ok) {
                console.error("TRANSCRIBER:", "Failed to upload segment", res);
                return;
            }

            const createTranscriptMutation = await apollo.client.mutate({
                mutation: CreateTranscriptDocument,
                variables: {
                    sessionId,
                    participantId,
                    url,
                    timestamp: segment.timestamp,
                    noise,
                    language: LANGUAGE_HINT,
                    duration: segment.duration,
                },
            });

            if (!createTranscriptMutation.data) {
                throw new Error("TRANSCRIBER: Failed to create transcript");
            }
        } catch (e) {
            console.error(e);
        }
    }
}
