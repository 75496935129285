import env from "@generated/environment";
import cookie from "js-cookie";
import designer from "./designer";
import extension from "./extension";
import {getDesktopApi} from "@sessions/desktop-api";

export type UserInfo = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    permissions: Array<string>;
    newToken?: string;
};

export class AuthService {
    private static instance: AuthService;
    private userId: string;

    private constructor() {
        this.userId = localStorage.getItem("current_user") ?? "invalid";
        window.addEventListener(
            "storage",
            (ev) => {
                if (ev.key?.startsWith("logged_out")) {
                    setTimeout(() => {
                        sessionStorage.clear();
                        if (extension.isExtensionEnabled()) {
                            extension.drawer.destroy();
                            return;
                        }
                    }, 500);
                }

                if (ev.key === "current_user" && ev.newValue !== this.userId) {
                    sessionStorage.clear();

                    window.location.reload();
                }
            },
            {}
        );
    }

    public static getInstance(): AuthService {
        if (AuthService.instance === undefined) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    private tries: number = 0;

    private onGetUserCb = [] as ((user: UserInfo | null) => void)[];

    public async getUser(getUserCb?: (user: UserInfo | null) => void): Promise<UserInfo | null> {
        if (getUserCb && this.onGetUserCb.indexOf(getUserCb) === -1) {
            this.onGetUserCb.push(getUserCb);
        }
        return await this.getUserMethod()
            .then((res) => {
                this.tries = 0;
                this.onGetUserCb.forEach((fn) => {
                    fn(res);
                });
                return res;
            })
            .catch((err) => {
                console.error(`[AuthService error]: `, err);
                if (this.tries > 5) {
                    console.warn(`[AuthService]: failed ${this.tries + 1} time(s) to get a response from the auth api, refreshing`);
                    if (window) {
                        parent.top?.location.reload();
                    }
                }
                this.tries++;
                return new Promise((resolve) => {
                    setTimeout(async () => {
                        console.warn(`[AuthService]: try ${this.tries + 1} to get a response from the auth api`);
                        const res = await this.getUser();
                        resolve(res);
                    }, 1500);
                });
            });
    }

    public async getUserMethod(): Promise<UserInfo | null> {
        if (env.environmentName === "development" || env.environmentName?.startsWith("devbox-")) {
            return await this.getUserDev();
        }

        const body = await fetch(this.getUrl("api/userinfo"), {
            method: "GET",
            credentials: "include",
            cache: "no-store",
            headers: {
                "cache-control": "no-store",
            },
        }).then((res) => {
            if (res.status === 200) {
                return res.json() as Promise<UserInfo>;
            }
        });

        if (!body) {
            return Promise.resolve(null);
        }

        if ((body as any).message === "reload") {
            if (window) {
                parent.top?.location.reload();
            }
        }

        if (!body.permissions) {
            return Promise.resolve(null);
        }

        const userInfo = body as UserInfo;
        this.userId = userInfo.id;

        localStorage.setItem("current_user", userInfo.id);

        return userInfo;
    }

    private async getUserDev(): Promise<UserInfo | null> {
        const token = cookie.get(env.authService.cookieName);
        if (!token) {
            return Promise.resolve(null);
        }

        const userInfo = await fetch(this.getUrl("api/userinfo"), {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
            },
            body: JSON.stringify({
                token: token,
            }),
        }).then((res) => {
            if (res.status === 200) {
                return res.json() as Promise<UserInfo>;
            }
        });

        if (!userInfo) {
            return Promise.resolve(null);
        }

        this.userId = userInfo.id;
        if (userInfo.newToken) {
            if (env.environmentName === "development") {
                document.cookie = `${env.authService.cookieName}=${userInfo.newToken}; path=/`;
            } else {
                const path = window.location.hostname.replace("primary.", "").replace("secondary.", "");
                document.cookie = `${env.authService.cookieName}=${userInfo.newToken}; path=/; domain=${path}`;
            }
        }

        localStorage.setItem("current_user", userInfo.id);

        return userInfo;
    }

    public login(redirectUrl?: string, forceLogin?: boolean) {
        const csdReturn = new URLSearchParams(window.location.search).get("csd_return");
        if (csdReturn) {
            localStorage.setItem("after_login_csd_return", csdReturn);
        }

        const returnTo = redirectUrl
            ? `${window.location.origin}${!redirectUrl.startsWith("/") ? `/${redirectUrl}` : redirectUrl}`
            : `${window.location.origin}`;

        if (forceLogin) {
            this.logoutBasic().then(() => {
                window.location.href = this.getUrl("login", returnTo);
            });
        } else {
            window.location.href = this.getUrl("login", returnTo);
        }
    }

    public loginWithPopup(provider: string) {
        const csdReturn = new URLSearchParams(window.location.search).get("csd_return");
        if (csdReturn) {
            localStorage.setItem("after_login_csd_return", csdReturn);
        }

        window["login_popup_callback"] = () => {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        };
        const popupUrl = `${window.location.origin}/user/login?popup=true&provider=${provider}`;
        const popupTarget = "_blank";
        const popupFeatures = "location=no,toolbar=no,width=500,height=500,left=100,top=100;";
        window.open(popupUrl, popupTarget, popupFeatures);
    }

    public register(redirectUrl?: string) {
        const params = new URLSearchParams(window.location.search);
        const externalProvider = params.has("er") ? params.get("er") : localStorage.getItem("er");

        const returnTo = redirectUrl ? `${window.location.origin}${redirectUrl}` : `${window.location.origin}`;

        if (externalProvider) {
            window.location.href = `${this.getUrl("register", returnTo)}&externalRef=${externalProvider}`;
        } else {
            window.location.href = this.getUrl("register", returnTo);
        }
    }

    public socialLogin(provider: string, redirectUrl?: string) {
        console.log(`socialLogin redirectUrl: ${redirectUrl}`);

        const returnTo = redirectUrl ? `${window.location.origin}${redirectUrl}` : `${window.location.origin}`;
        if (returnTo) {
            window.location.href = `${this.getUrl("login")}?returnTo=${returnTo}&oauthProvider=${provider}`;
        } else {
            window.location.href = `${this.getUrl("login")}?oauthProvider=${provider}`;
        }
    }

    public async logout(returnTo?: string): Promise<void> {
        await this.logoutBasic();

        window.location.href = this.getUrl("logout", returnTo);
    }

    public async logoutBasic(): Promise<void> {
        designer.clearPersistentStorage();
        localStorage.removeItem("user_login_check");

        await fetch(this.getUrl("api/logout"), {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        this.userId = "invalid";

        localStorage.setItem("current_user", this.userId);

        if (env.environmentName === "development" || env.environmentName?.startsWith("devbox-")) {
            if (env.environmentName === "development") {
                document.cookie = `${env.authService.cookieName}=invalid; path=/; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
            } else {
                const path = window.location.hostname.replace("primary.", "").replace("secondary.", "");
                document.cookie = `${env.authService.cookieName}=invalid; path=/; domain=${path}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
            }
        }
    }

    private getUrl(path: string, returnTo?: string) {
        const targetPath = path.length === 1 ? "" : path;
        if (env.environmentName === "development" || env.environmentName?.startsWith("devbox-")) {
            if (path === "login") {
                return "/user/hack/login" + (returnTo ? "?returnTo=" + returnTo : "");
            }
            if (path === "register") {
                return "/user/hack/register";
            }
            return `${env.clientWriteUrl.replace("graphql", "")}${path.replace("api", "api/auth")}`;
        }
        if (returnTo) {
            // return `${window.location.protocol}//auth.${window.location.host}/${targetPath}?returnTo=${returnTo}`;
            return `${env.authService.root}/${targetPath}?returnTo=${returnTo}`;
        }
        // return `${window.location.protocol}//auth.${window.location.host}/${targetPath}`;
        return `${env.authService.root}/${targetPath}`;
    }

    public async loginGuest(guestSession: string, state?: Record<string, unknown>): Promise<Record<string, unknown> | null> {
        const url = this.getUrl("api/guest/login");
        await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
            },
            body: JSON.stringify({
                sessionId: guestSession,
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                }
            })
            .then((body) => {
                if (body) {
                    if (env.environmentName === "development" || env.environmentName?.startsWith("devbox-")) {
                        if (env.environmentName === "development") {
                            document.cookie = `${env.authService.cookieName}=${body.token}; path=/`;
                        } else {
                            const path = window.location.hostname.replace("primary.", "").replace("secondary.", "");
                            document.cookie = `${env.authService.cookieName}=${body.token}; path=/; domain=${path}`;
                        }
                    }
                }
            })
            .catch((error) => {
                if (!(error instanceof Response)) {
                    console.warn(`[Login error] - guest - ${error.message}`);
                }
            });

        return state ?? null;
    }
}

export async function handleDesktopAuth() {
    try {
        const api = getDesktopApi();

        if (!api) {
            return;
        }

        if (!document.referrer) {
            return;
        }

        const url = new URL(document.referrer);

        if (!url.hostname.includes("auth")) {
            return;
        }

        // @ts-ignore
        await api.authReady();
    } catch (e) {
        console.error(e);
    }
}
