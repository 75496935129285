import {simd} from "wasm-feature-detect";

type LoadRnnoiseOptions = {
    url: string;
    simdUrl: string;
};

export async function loadRnnoise({url, simdUrl}: LoadRnnoiseOptions, init?: RequestInit) {
    const hasSimd = await simd();
    console.log({hasSimd}, "simd");
    const loadUrl = hasSimd ? simdUrl : url;
    console.log("loading with url", loadUrl);
    const result = await fetch(loadUrl, init);
    const binary = await result.arrayBuffer();
    return binary;
}
