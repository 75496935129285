import {DeepOmit} from "@common/declarations";
import {SpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {AgendaItemType} from "@generated/data";
import {setArtifactResourceResult} from "@workhorse/api/designer/methods";
import {getResultFragment} from "@workhorse/api/resx/hooks";
import {ArrayItem, DeepMandatoryWithNullVals} from "@workhorse/declarations";
import {AgendaItem, DesignerApiSession} from "@workhorse/declarations/dataTypes";
import designer from "../..";
import {DesignerAction} from "../../action";
import {getMaxSessionOrder, makeId} from "../../utils";
import {createAgendaItem, CreateAgendaItemInput} from "./create-agenda-item";

type DesignerApiAgendaItem = ArrayItem<DesignerApiSession["agendaItems"]>;

type AgendaInputOverride = Partial<Omit<CreateAgendaItemInput, "artifact">> & {
    artifact?: Partial<DeepMandatoryWithNullVals<AgendaItem["artifact"]>>;
};

export type DuplicateAgendaItemInput = {
    id: string;
    overrides?: DeepOmit<AgendaInputOverride, "id">;
    callBack?: Function;
};

const diffConstants = {
    isDeleted: false,
    oldId: null,
    update: null,
    createdAt: null as unknown as Date,
    updatedAt: null as unknown as Date,
};

export class DuplicateAgendaItem extends DesignerAction<DuplicateAgendaItemInput> {
    public commit() {
        const agendaItems = designer.currentAgendaItems().slice(0);

        const source = agendaItems.find((a) => a.id === this.input.id);
        const newArtifactId = makeId();

        if (!source) {
            return "";
        }

        const {artifact: artifactOverrides = {}, ...agendaItemOverrides} = (this.input.overrides as AgendaInputOverride) || {};

        const newAgendaItem: DesignerApiAgendaItem = {
            ...createAgendaItem({
                ...source,
                ...agendaItemOverrides,
            }),
            ...diffConstants,
            __typename: "AgendaItem",
            type: (source.update?.type || source.type) as AgendaItemType,
            userConfiguredDuration: (source.update?.userConfiguredDuration || source.userConfiguredDuration) as boolean,
            order: getMaxSessionOrder() + 1,
            locked: null,
        };

        agendaItems.push(newAgendaItem);

        designer.updateCurrentSession(
            {
                agendaItems,
            },
            {
                strategy: "replace",
            }
        );

        designer.api.agendaItem.addArtifact({
            agendaItemId: newAgendaItem.id,
            overrides: {
                ...source.artifact,
                ...artifactOverrides,
                ...diffConstants,
                id: newArtifactId,
                properties: (artifactOverrides?.properties || source.artifact?.properties || [])
                    .filter(({isDeleted}) => !isDeleted)
                    .map((prop) => ({
                        ...prop,
                        ...diffConstants,
                        id: makeId(),
                    })),
                resourceResults: (artifactOverrides?.resourceResults || source.artifact?.resourceResults || [])
                    .filter(({isDeleted}) => !isDeleted)
                    .map((resxResult) => ({
                        ...resxResult,
                        ...diffConstants,
                        id: makeId(),
                    })),
            },
        });

        const results = artifactOverrides?.resourceResults || source.artifact?.resourceResults || [];

        if (!results.length) {
            this.input.callBack?.();
            return newAgendaItem.id;
        }
        // adding resx result to newly created artifact
        (async () => {
            const promises = results.map((resxResult) => {
                const {result} = getResultFragment(resxResult.id);
                const resource = result?.resource;

                if (!resource) {
                    console.error(
                        "Cannot attach resx result to new artifact.",
                        "resxResult.id",
                        resxResult.id,
                        "artifactId",
                        newArtifactId
                    );
                    return;
                }

                return setArtifactResourceResult({
                    id: newArtifactId,
                    resourceSlotData: {
                        type: resource.type as keyof SpecifiersTypeMap,
                        config: {
                            resource: resource.id,
                        },
                    },
                });
            });

            await Promise.all(promises);
            this.input.callBack?.();
        })();

        return newAgendaItem.id;
    }
}
