import toast from "@workhorse/api/toast";
import {updateRemoteUser} from "@workhorse/api/user";
import {useUserInfo} from "@workhorse/providers/User";
import {LanguageSelectorType} from "../LanguageSelector";
import LanguageSelectorComponent from "./LanguageSelectorComponent";

function UserLanguageSelector(props: LanguageSelectorType) {
    const {skipUpdate} = props;

    const {language} = useUserInfo();

    const handleChangeLanguage = (language: string) => {
        localStorage.setItem("user-language", language);

        if (skipUpdate) {
            return;
        }

        updateRemoteUser({
            language,
        }).catch(() => {
            toast("Something went wrong with changing the language.", {type: "error"});
        });
    };

    return <LanguageSelectorComponent {...props} language={language} onChange={handleChangeLanguage} />;
}

export default UserLanguageSelector;
