import {useQuery} from "@apollo/client";
import {ConferenceParticipantStatus, GetBreakoutConfigDocument, ParticipantStatus} from "@generated/data";
import {ReactNode, useEffect} from "@workhorse/api/rendering";
import {rbac} from "@workhorse/api/user";

interface Props {
    id: string;
    artifactId: string;
    skipSubs?: boolean;
    onChange?: (hasQuestions: boolean, hasRaisedHands: boolean, hasParticipants: boolean, hasParticipantsOrAdmins: boolean) => void;
    children?: (hasQuestions: boolean, hasRaisedHands: boolean, hasParticipants: boolean, hasParticipantsOrAdmins: boolean) => ReactNode;
}

export function RoomNotifier(props: Props) {
    const {onChange} = props;

    const {data} = useQuery(GetBreakoutConfigDocument, {
        variables: {id: props.artifactId},
    });

    const isParentRoom = data?.artifact?.breakoutRoomsConfig?.parentOfBreakoutRooms.sessionId === props.id;

    const participants = isParentRoom
        ? data?.artifact?.breakoutRoomsConfig?.participantsMap.map((p) => p.participantInParent)
        : data?.artifact?.breakoutRoomsConfig?.participantsMap
              .map((p) => p.participantInBreakoutRoom)
              .filter((p) => !!p && p.sessionId === props.id)
              .map((p) => p!) ?? [];

    const questionsContainer = isParentRoom
        ? data?.artifact?.breakoutRoomsConfig?.parentOfBreakoutRooms?.questionsContainer
        : data?.artifact?.breakoutRoomsConfig?.breakoutRooms.find((p) => p.id === props.id)?.questionsContainer;

    const hasParticipants =
        participants?.some((el) => el.status === ParticipantStatus.JoinedSession && !el.isOwner && !rbac(el, "session.isAssistant")) ??
        false;

    const hasParticipantsOrAdmins = participants?.some((el) => el.status === ParticipantStatus.JoinedSession) ?? false;
    const hasQuestions = questionsContainer?.questions.some((el) => el.markedAsAnswered === false) ?? false;
    const hasRaisedHands =
        participants?.some(
            (el) => el.conferenceStatus === ConferenceParticipantStatus.PendingSpeaker && el.status === ParticipantStatus.JoinedSession
        ) ?? false;

    useEffect(() => {
        onChange?.(hasQuestions, hasRaisedHands, hasParticipants, hasParticipantsOrAdmins);

        return () => {
            onChange?.(false, false, false, false);
        };
    }, [hasQuestions, hasRaisedHands, hasParticipants, hasParticipantsOrAdmins, onChange]);

    return <>{props.children?.(hasQuestions, hasRaisedHands, hasParticipants, hasParticipantsOrAdmins)}</>;
}
