import {makeId} from "@workhorse/api/designer/lib/utils";

export const SPECIAL_CHARS = {
    '"': "",
    "'": "",
    "`": "",
    "´": "",
    "<": "",
    ">": "",
    "{": "",
    "}": "",
    "[": "",
    "]": "",
    "\\": "",
    "/": "",
    "|": "",
    "#": "",
    "?": "",
    "¿": "",
    "!": "",
    ")": "",
    "(": "",
    "^": "",
    "%": "",
    ",": "",
    ";": "",
    ":": "",
    " ": "-",
    ă: "a",
    â: "a",
    ș: "s",
    ț: "t",
    î: "i",
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ü: "u",
    ö: "o",
    ä: "a",
    ß: "ss",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ç: "c",
    ñ: "n",
    ê: "e",
    ë: "e",
    ô: "o",
    û: "u",
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "yo",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "kh",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ъ: "ie",
    ы: "y",
    ь: "",
    э: "e",
    ю: "yu",
    я: "ya",
    ã: "a",
    å: "a",
    ï: "i",
    ń: "n",
    ǹ: "n",
    ṅ: "n",
    ň: "n",
    ņ: "n",
    ɲ: "n",
    ƞ: "n",
    ṇ: "n",
    ṋ: "n",
    ṉ: "n",
    ǌ: "nj",
    ŋ: "ng",
    õ: "o",
    ÿ: "y",
    ś: "s",
    ṡ: "s",
    ŝ: "s",
    š: "s",
    ş: "s",
    ṥ: "s",
    ṧ: "s",
    ṣ: "s",
    ṩ: "s",
    ѡ: "",
    ѣ: "",
    ѥ: "",
    ѧ: "",
    ѩ: "",
    ѫ: "",
    ѭ: "",
    ѯ: "",
    ѱ: "",
    ѳ: "",
    ѵ: "",
    ѷ: "",
    ѹ: "",
    ѻ: "",
    ѽ: "",
    ѿ: "",
    ҁ: "",
    "҅": "",
    "҆": "",
    "\u0483": "",
    "\u0484": "",
    а҅: "",
    а҆: "",
    "҇": "",
    "҉": "",
    ҍ: "",
    ҏ: "",
    ґ: "",
    ғ: "",
    ҕ: "",
    җ: "",
    ҙ: "",
    қ: "",
    ҝ: "",
    ҟ: "",
    ҡ: "",
    ң: "",
    ҥ: "",
    ҧ: "",
    ҩ: "",
    ҫ: "",
    ҭ: "",
    ү: "",
    ұ: "",
    ҳ: "",
    һ: "",
    ҽ: "",
    ҿ: "",
    ӂ: "",
    ӄ: "",
    ӈ: "",
    ӌ: "",
    ӑ: "",
    ӓ: "",
    ӕ: "",
    ӗ: "",
    ә: "",
    ӛ: "",
    ӝ: "",
    ӟ: "",
    ӡ: "",
    ӣ: "",
    ӥ: "",
    ӧ: "",
    ө: "",
    ӫ: "",
    ӭ: "",
    ӯ: "",
    ӱ: "",
    ӳ: "",
    ӵ: "",
    ӹ: "",
    "҂": "",
    "\u0488": "",
    Ҵ: "",
    ҵ: "",
    Ҷ: "",
    ҷ: "",
    Ҹ: "",
    ҹ: "",
    "\u04C0": "",
    "\u04CF": "",
};

export const removeSpecialChars = (str: string) => {
    let cleanedStr = str.toLocaleLowerCase?.().trim?.();

    for (const char of Object.keys(SPECIAL_CHARS)) {
        cleanedStr = cleanedStr.replaceAll(char, SPECIAL_CHARS[char]).trim?.();
    }
    if (!cleanedStr) {
        cleanedStr = makeId();
    }

    const encodedStr = encodeURI(cleanedStr);

    return encodedStr;
};

export const getBaseUrl = (
    host: string,
    userName: string,
    organizationId: string | undefined,
    organizationName: string | undefined | null
) => {
    const formattedUserName = removeSpecialChars(userName);
    const digitsOrganizationId = organizationId?.length ? organizationId.slice(0, 5) : makeId().slice(0, 5);
    let formattedOrganizationName: string;

    if (organizationName) {
        formattedOrganizationName = removeSpecialChars(organizationName);
        return `${host}${formattedOrganizationName}-${digitsOrganizationId}/`;
    }

    return `${host}${formattedUserName}-${digitsOrganizationId}/`;
};

export const composeInitialSlug = (
    fullUserName: string,
    organizationId: string | undefined,
    organizationName: string | undefined | null,
    generatedName: string,
    workspaceSlug: string | undefined | null
) => {
    const cleanUserName = removeSpecialChars(fullUserName);
    const digitsOrganizationId = organizationId?.length ? organizationId.slice(0, 5) : makeId().slice(0, 5);
    const cleanGeneratedName = removeSpecialChars(generatedName);
    let cleanOrganizationName: string;

    if (workspaceSlug) {
        return `${workspaceSlug}/${cleanGeneratedName}`;
    }

    if (organizationName) {
        cleanOrganizationName = removeSpecialChars(organizationName);
        return `${cleanOrganizationName}-${digitsOrganizationId}/${cleanGeneratedName}`;
    }

    return `${cleanUserName}-${digitsOrganizationId}/${cleanGeneratedName}`;
};

export const getSlugParts = (slug: string) => {
    const slugParts = slug.split("/");
    let lockedSlugPart = "";
    let editableSlugPart = "";
    if (slugParts.length > 1) {
        lockedSlugPart = slugParts[0];
        editableSlugPart = slugParts[slugParts.length - 1];
    } else {
        lockedSlugPart = "";
        editableSlugPart = slugParts[0];
    }

    return {
        lockedSlugPart,
        editableSlugPart,
    };
};

export const getLockedPartOfSlug = (slug: string) => {
    const slugParts = slug.split("/");
    const lockedPart = slugParts[0];
    return lockedPart;
};

export const getEditablePartOfSlug = (slug: string) => {
    const slugParts = slug.split("/");
    const editablePart = slugParts[slugParts.length - 1];
    return editablePart;
};

export const composeSlug = (lockedPart: string, editablePart: string) => {
    const cleanLocked = removeSpecialChars(lockedPart);
    const cleanEditable = removeSpecialChars(editablePart);
    return `${cleanLocked}/${cleanEditable}`;
};
