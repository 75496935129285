import Typography from "@ui/cdk/Typography";
import commonClasses from "../eventCommons.module.scss";

import EventPaymentsEmptyState from "./EventPaymentsEmptyState";
import {GetPaymentProvidersDocument, SessionEventState} from "@generated/data";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useQuery, useReactiveVar} from "@workhorse/api/data";
import {useUserInfo} from "@workhorse/providers/User";
import {PaymentsForm, PaymentsFormValues} from "@workhorse/components/payments";
import {PaymentConfigState, paymentConfigState} from "./upsertPaymentConfigs";
import {useEffect} from "react";
import Button from "@ui/cdk/Button";
import {useSeenSection} from "@workhorse/api/seen";
import {PaymentsEmbeddingNotification} from "./PaymentsEmbeddingNotification";
import classes from "./style/EventPayments.module.scss";

type EventPaymentsProps = {
    refetch?: () => Promise<unknown>;
};

export function EventPayments(props: EventPaymentsProps) {
    const session = useSession();
    const paymentsConfig = useReactiveVar(paymentConfigState);
    const seenEmbeddingNotification = useSeenSection("GLOBALS.eventPaymentsEmbedding");

    const isEventPublished = session.event?.state === SessionEventState.Published;
    const eventHasPayments = Boolean(session.event?.payments?.length);

    const userInfo = useUserInfo();

    const {data: paymentsProvidersData, loading} = useQuery(GetPaymentProvidersDocument, {fetchPolicy: "network-only"});

    const availableProviders = paymentsProvidersData?.getPaymentProviders ?? null;

    useEffect(() => {
        if (!paymentsConfig) {
            paymentConfigState(session.event?.payments?.map((config) => ({state: "ready", value: config})) ?? []);
        }
    }, []);

    const handleAddPayments = async () => {
        if (availableProviders?.length && session.event?.id) {
            const provider = availableProviders[0];

            const config = {
                id: "",
                amount: 100,
                currency: "USD",
                terms: "",
                enabled: true,
                paymentProviders: [
                    {
                        id: provider.id,
                        name: provider.name,
                        accountName: provider.accountName ?? "",
                    },
                ],
                ownerId: userInfo.id,
            };

            const newState: PaymentConfigState = [...(paymentsConfig ?? []), {state: "update", value: config}];

            paymentConfigState(newState);
        }
    };

    const handleOnPaymentChanged = (values: PaymentsFormValues, index: number) => {
        const configs = [...(paymentsConfig ?? [])];
        configs.splice(index, 1, {
            state: "update",
            value: {
                ...values,
                id: values.id ?? "",
                amount: values.amount * 100,
            },
        });

        paymentConfigState(configs);
    };

    const handleDeleteConfig = async () => {
        const configs = paymentsConfig?.map((item) => ({state: "delete", value: item.value} as const)) ?? [];
        paymentConfigState(configs);
    };

    if (loading || !paymentsConfig) {
        return null;
    }

    const hasPayments = paymentsConfig.some(({state}) => state !== "delete");

    return (
        <div className={commonClasses.mainContainer}>
            <div className="flex flex-align-center flex-justify-between mb-30">
                <Typography fontWeight="bolder" variant="xl3" color="secondary">
                    Payments
                </Typography>
                {hasPayments ? (
                    <Button variant="destructive-tertiary" size="small" onClick={() => handleDeleteConfig()}>
                        Remove Payment
                    </Button>
                ) : null}
            </div>
            <div className="py-24 fullh">
                {hasPayments ? (
                    paymentsConfig.map((config, index) =>
                        config.state === "delete" ? null : (
                            <PaymentsForm
                                key={config.value.id ?? index}
                                availableProviders={availableProviders ?? []}
                                config={config.value}
                                onChange={(values) => handleOnPaymentChanged(values, index)}
                            />
                        )
                    )
                ) : (
                    <EventPaymentsEmptyState
                        onEnable={handleAddPayments}
                        canEnable={availableProviders ? availableProviders.length > 0 : false}
                        hasRegistration={session.event?.hasRegistration}
                        eventId={session.id}
                    />
                )}
            </div>
            {!eventHasPayments && hasPayments && isEventPublished && !seenEmbeddingNotification ? (
                <div className={classes.notification}>
                    <PaymentsEmbeddingNotification />
                </div>
            ) : null}
        </div>
    );
}
