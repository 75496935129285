import {useRef, useState} from "@workhorse/api/rendering";
import {SessionData} from "@workhorse/declarations/dataTypes";

import {ArtifactTag} from "@generated/artifacts";
import {AgendaItemType, ChooseToolStep} from "@generated/data";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import designer from "@workhorse/api/designer";
import {getMaxSessionOrder} from "@workhorse/api/designer/lib/utils";
import {
    useDesignerIsEditing,
    useDesignerSessionCommitState,
    useDesignerSessionState,
} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useAgendaItems, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useFullScreenState} from "@workhorse/providers/state";
import {filesType} from "../../../components/PlayerTools/AddToolsInPlayerButton";
import ToolMenuItems from "../../../components/PlayerTools/ToolMenu/components/ToolMenuItems";
import headerClasses from "../../styles/PlayerHeader.module.scss";
import {OnboardingToolMenu} from "@workhorse/components/onboarding-tooltips/onboarding-tool-menu";
import {configurableArtifacts} from "@workhorse/pages/player/microController/MicroControllerInjector";

type PlayerToolMenuHeaderProps = {
    session: SessionData;
    isOrganizer: boolean;
    onClick?: () => void;
    logo: JSX.Element;
    text: string;
};

const PlayerToolMenuHeader = (props: PlayerToolMenuHeaderProps) => {
    const {session, isOrganizer, onClick, logo, text} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const agendaItems = useAgendaItems();
    const isFullScreen = useFullScreenState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const dialogRef = useRef<DialogImperativeRef>(null);

    const {isLobby: inLobby} = useIsLobby();
    const hasAgenda = agendaItems.some((a) => a.type === AgendaItemType.Planned);

    const [, set] = useDesignerSessionState();
    const commitInProgress = useDesignerSessionCommitState();

    const [toolPreviewMode, setToolPreviewMode] = useState(false);

    const mobileState = useMobile();
    const {isMobile} = mobileState;

    const [isEditMode, toggleEditMode] = useDesignerIsEditing();
    const {completeFlow} = useOnboardingSession();

    const {order} = session;
    const currentAgendaItem = agendaItems.find((a) => a.order === order);

    const convertingTool = isOrganizer ? agendaItems.find((a) => a.processing) : undefined;
    const convertingMicroTag = convertingTool?.artifact?.artifactId;
    const currentMicroTag = convertingMicroTag ?? currentAgendaItem?.artifact?.artifactId;
    const currentMicroTagNotConverting = currentAgendaItem?.artifact?.artifactId;

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeFile = () => {};

    const onPreview = () => {
        setToolPreviewMode(true);
        setTimeout(() => {
            const convertingTool = agendaItems.find((item) => item.processing);

            if (convertingTool) {
                return;
            }

            if (!isEditMode) {
                toggleEditMode(true);
            }

            const selectedAgendaItem = convertingTool ?? agendaItems?.find((a) => a.order === order);
            const selectedAgendaItemId = selectedAgendaItem?.id;

            set({
                toolStep: ChooseToolStep.Preview,
                selectedResourceId: null,
                selectedAgendaItemId: selectedAgendaItemId ?? agendaItems[0].id,
                selectedMacroIdToConfig: null,
                selectedMicroIdToPreview: (selectedAgendaItem ?? agendaItems[0]).artifact?.id,
            });
            dialogRef.current?.toggle?.();
        }, 0);
    };

    const onDetach = () => {
        if (inLobby) {
            designer.api.session.detachAgenda({
                type: AgendaItemType.Instant,
            });
        } else {
            const convertingAgendaItem = agendaItems.find((item) => item.processing);
            if (convertingAgendaItem) {
                designer.api.agendaItem.remove({
                    id: convertingAgendaItem.id,
                });
            } else {
                const order = getMaxSessionOrder() + 1;
                designer.api.agendaItem.create({
                    ...designer.constants.agendaItemDefaults,
                    type: AgendaItemType.Instant,
                    order,
                    doNotUpdateCreatePls: true,
                });
                designer.api.session.update({
                    order,
                });
            }
        }
        set({
            selectedAgendaItemId: null,
            selectedMacroIdToConfig: null,
            selectedMicroIdToPreview: null,
        });
        designer.commit();
        onClick?.();

        if (session.onboardingType === SessionOnboardingType.NewUser && currentMicroTag && filesType.includes(currentMicroTag)) {
            completeFlow({
                flowName: "share-a-file",
            });
        }
    };

    return (
        <>
            {currentMicroTag &&
            currentMicroTag !== "flowos/conference" &&
            configurableArtifacts.includes(currentMicroTag) &&
            isOrganizer &&
            !isMobile &&
            props.session.onboardingType !== SessionOnboardingType.FirstStrike ? (
                <OnboardingToolMenu shouldSkip={isMenuOpen}>
                    <ToolMenuItems
                        disabled={commitInProgress}
                        onDetach={onDetach}
                        onPreview={onPreview}
                        onChangeFile={onChangeFile}
                        session={session}
                        isAssistant={isOrganizer}
                        mobileState={mobileState}
                        inLobby={inLobby}
                        currentMicroTag={(currentMicroTagNotConverting ?? "") as ArtifactTag}
                        hasAgenda={false}
                        disablePreview={!!convertingTool}
                        handleClose={handleClose}
                        onCloseTooltip={() => setIsMenuOpen(false)}
                        onOpenTooltip={() => setIsMenuOpen(true)}
                        placement="bottom-end"
                        onlyCurrentAgendaItem={true}
                        openOnClick={true}
                        className="mt-8"
                    >
                        <div className={cls(headerClasses.interactionStatus, headerClasses.clickable)} data-id="tool-control-status">
                            {logo}
                            <Typography fontWeight="bold">{text}</Typography>
                            <ExpandMoreRoundedIcon />
                        </div>
                    </ToolMenuItems>
                </OnboardingToolMenu>
            ) : (
                <div className={headerClasses.interactionStatus} data-id="tool-control-status">
                    {logo}
                    <Typography fontWeight="bold">{text}</Typography>
                </div>
            )}
        </>
    );
};

export default PlayerToolMenuHeader;
