import {AcceptedTimezone, getLocalTimezone, getTimezoneOffset} from "@common/utils/timezones";
import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";

/**
 * NOTE
 * very weird situation
 * calling getLocalTimezone results in "google-timezones-json"
 * not existing in utils so the function will throw an error
 * for trying to parse an unexisting object
 * which is why we imported it here and we're passing it as an argument
 * i believe this has something to do with either the chunking mechanism
 * or tree-shaking
 */
import {addMinutes} from "date-fns";
import {CustomResolvers} from "./types";

const localTimezone = getLocalTimezone();

export const Session: CustomResolvers["Session"] = {
    ...fieldResolvers.Session,
    generatedName: {
        read(existing) {
            return existing ?? null;
        },
    },

    byTimezoneStartAt: {
        read(existing, {readField}) {
            const sessionTimezone = (readField("timeZone") || localTimezone) as string;
            const startAtProp = readField("startAt") as Date | string | null | undefined;
            if (!startAtProp) {
                return null;
            }
            const startAt = startAtProp instanceof Date ? startAtProp : new Date(startAtProp);
            const diff = getTimezoneOffset(localTimezone, sessionTimezone as AcceptedTimezone);
            const d = addMinutes(startAt, -diff);
            return d;
        },
    },
    byTimezonePlannedEnd: {
        read(existing, {readField}) {
            const sessionTimezone = (readField("timeZone") || localTimezone) as string;
            const startAtProp = readField("startAt") as Date | string | null | undefined;
            const plannedEndProp = readField("plannedEnd") as Date | string | null | undefined;
            if (!startAtProp || !plannedEndProp) {
                return null;
            }
            const startAt = startAtProp instanceof Date ? startAtProp : new Date(startAtProp);
            const plannedEnd = plannedEndProp instanceof Date ? plannedEndProp : new Date(plannedEndProp);
            const duration = Math.ceil((plannedEnd.getTime() - startAt.getTime()) / 1000 / 60);
            const diff = getTimezoneOffset(localTimezone, sessionTimezone as AcceptedTimezone);
            const d = addMinutes(startAt, -diff);

            const e = addMinutes(d, duration);
            return e;
        },
    },

    polls: {
        merge: (existing, incoming, {mergeObjects, readField}) => {
            // console.log("YUU", existing, incoming);
            const merged: any[] = [];

            const pollIdToIndex: Record<string, number> = Object.create(null);

            if (existing) {
                existing
                    ?.filter(
                        (existingPoll) =>
                            incoming.find((incomingPoll) => readField("id", incomingPoll) === readField("id", existingPoll)) != null
                    )
                    ?.forEach((poll, i) => {
                        pollIdToIndex[poll.__ref] = i;
                    });
            }
            // console.log("YUU DEBUG pollIdToIndex", pollIdToIndex);

            incoming?.forEach((poll) => {
                const id = poll.__ref;

                if (!id) {
                    return;
                }

                const index = pollIdToIndex[id];
                if (typeof index === "number") {
                    // console.log("YUU merged called");
                    merged[index] = mergeObjects(merged[index], poll);
                } else {
                    pollIdToIndex[id] = merged.length;
                    merged.push(poll);
                    // console.log("YUU pushing", poll);
                }
            });

            // console.log("YUU returing", merged);

            return merged;
        },
    },
};
