import Tooltip from "@ui/cdk/Tooltip";
import {ReactComponent as TranscriptIcon} from "../../assets/media/transcript-header-info.svg";
import classes from "../styles/PlayerHeader.module.scss";
import IconButton from "@ui/core/components/IconButton";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useTranslation} from "react-i18next";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";

type PlayerSessionTranscriptProps = {
    isMobile: boolean;
    isPreJoin: boolean;
};

const PlayerSessionTranscript = (props: PlayerSessionTranscriptProps) => {
    const {t} = useTranslation();
    const {isMobile, isPreJoin} = props;

    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive: isComponentActive, setActiveComponent} = useDrawerRightActiveComponent("flowos/transcript");

    const isActive = isComponentActive && rightDrawerState.isFullyOpen;

    const handleToggleTranscriptMacro = () => {
        if (isPreJoin) {
            return;
        }

        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent("flowos/transcript");
        }

        if ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) {
            toggleRightDrawer();
        }
    };

    return (
        <Tooltip title={t("macro.transcript.info_tooltip_transcript_is_active") ?? ""} placement="bottom">
            <IconButton
                className={cls(
                    classes.transcriptIcon,
                    isPreJoin && classes.transcriptIconPreJoin,
                    isPreJoin && !isMobile && classes.transcriptIconWithText
                )}
                onClick={handleToggleTranscriptMacro}
            >
                <TranscriptIcon />
                {isPreJoin && !isMobile && (
                    <Typography color="primary" fontWeight="bold" className="ml-4 mr-4 nowrap">
                        {t("macro.transcript.info_tooltip_transcript_is_active")}
                    </Typography>
                )}
            </IconButton>
        </Tooltip>
    );
};

export default PlayerSessionTranscript;
