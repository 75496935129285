import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useGlobalResizeObserver} from "@workhorse/providers/ResizeObserverProvider";
import EventEmitter from "eventemitter3";
import {RndDragCallback, RndResizeCallback} from "react-rnd";

export type DraggableDialogPosition = {
    x: number;
    y: number;
};

export type DraggableDialogSize = {
    width: number;
    height: number;
};

type EventCallback<T = void> = (args: T) => void;

export function useDraggableDialogEvents() {
    return useMemo(() => {
        const events = new EventEmitter();
        function createOn<T>(event: string) {
            return (callback: EventCallback<T>) => {
                events.on(event, callback);
            };
        }
        function createOff<T>(event: string) {
            return (callback: EventCallback<T>) => {
                events.off(event, callback);
            };
        }
        function createEmit<T = void>(event: string) {
            return (args: T) => {
                events.emit(event, args);
            };
        }
        return {
            expand: createEmit("expand"),
            onExpand: createOn("expand"),
            offExpand: createOff("expand"),
            minimize: createEmit("minimize"),
            onMinimize: createOn("minimize"),
            offMinimize: createOff("minimize"),
            resetPosition: createEmit("resetPosition"),
            onResetPosition: createOn("resetPosition"),
            offResetPosition: createOn("resetPosition"),
        };
    }, []);
}

export type DraggableDialogEvent = ReturnType<typeof useDraggableDialogEvents>;

export function useDraggableDialog(
    gap: number,
    defaultHeight: number,
    minimizedWidth: number,
    minimizedHeight: number,
    width: number,
    height: number,
    events?: DraggableDialogEvent,
    onMinimizeChange?: (minimized: boolean) => void
) {
    const {isPortrait} = useDeviceOrientation();

    const {isMobile, isTablet} = useMobile();

    const aspectRatio = width / height || 1;
    const defaultWidth = defaultHeight * aspectRatio;

    const [pointerEvents, setPointerEvents] = useState(true);

    const [size, setSize] = useState<DraggableDialogSize>(() => ({
        width: minimizedWidth,
        height: minimizedWidth / aspectRatio,
    }));

    const [lastSize, setLastSize] = useState<DraggableDialogSize>(() => ({
        width: minimizedWidth,
        height: minimizedWidth / aspectRatio,
    }));

    const [position, setPosition] = useState<DraggableDialogPosition>();

    const [windowWidth, setWindowWidth] = useState<number>(() => window.innerWidth);
    const [windowHeight, setWindowHeight] = useState<number>(() => window.innerHeight);

    const [rightCorner, setRightCorner] = useState<DraggableDialogPosition>();

    const minimized = size?.height <= minimizedHeight;

    const options = {
        width,
        height,
        defaultWidth,
        defaultHeight,
        minimizedWidth,
        minimizedHeight,
        gap,
        size,
        lastSize,
        position,
        minimized,
        onMinimizeChange,
        rightCorner,
    };
    const optionsRef = useRef(options);
    optionsRef.current = options;

    const checkBoundaries = useCallback((wWidth: number, wHeight: number) => {
        const {defaultWidth, defaultHeight, size, gap} = optionsRef.current;

        const shareSize = size ?? {width: defaultWidth, height: defaultHeight};

        setWindowWidth(wWidth);
        setWindowHeight(wHeight);

        setPosition((position) => {
            if (!position) {
                return position;
            }

            let x = position.x;

            if (x < -(wWidth + shareSize.width - gap)) {
                x = -(wWidth + shareSize.width - gap);
            }

            let y = position.y;

            if (y < -(wHeight + shareSize.height - gap)) {
                y = -(wHeight + shareSize.height - gap);
            }

            if (x === position.x && y === position.y) {
                return position;
            }

            return {x, y};
        });
    }, []);

    useGlobalResizeObserver(checkBoundaries);

    const handleRightCornerChange = useCallback((position: DraggableDialogPosition) => {
        setRightCorner(position);
    }, []);

    const onDragStart = useCallback(() => {
        setPointerEvents(false);
    }, []);

    const onResizeStart = useCallback(() => {
        setPointerEvents(false);
    }, []);

    const onDragStop: RndDragCallback = useCallback((e, d) => {
        setPosition({x: d.x, y: d.y});
        setPointerEvents(true);
    }, []);

    const onResizeStop: RndResizeCallback = useCallback((e, d, ref, delta, position) => {
        setSize({width: ref.offsetWidth, height: ref.offsetHeight});
        setPosition({x: position.x, y: position.y});
        setPointerEvents(true);
    }, []);

    const handleMinimize = useCallback((newSize?: DraggableDialogSize) => {
        const {size, minimizedWidth, minimizedHeight} = optionsRef.current;
        setLastSize(newSize ?? size);
        setSize({width: minimizedWidth, height: minimizedHeight});
    }, []);

    const handleExpand = useCallback(() => {
        setSize(optionsRef.current.lastSize);
    }, []);

    const handleResetPosition = useCallback(() => {
        const {rightCorner, defaultWidth, gap} = optionsRef.current;

        if (!rightCorner) {
            return;
        }

        setPosition({
            x: rightCorner.x - defaultWidth - gap,
            y: rightCorner.y + gap,
        });
    }, []);

    useEffect(() => {
        const {onMinimizeChange} = optionsRef.current;
        onMinimizeChange?.(minimized);
    }, [minimized]);

    useEffect(() => {
        if (!events) {
            return;
        }

        events.onMinimize(handleMinimize);
        events.onExpand(handleExpand);
        events.onResetPosition(handleResetPosition);

        return () => {
            events.offMinimize(handleMinimize);
            events.offExpand(handleExpand);
            events.offResetPosition(handleResetPosition);
        };
    }, [events, handleMinimize, handleExpand, handleResetPosition]);

    useEffect(() => {
        if (!isMobile) {
            return;
        }

        const id = requestAnimationFrame(() => {
            const {minimizedWidth, minimizedHeight} = optionsRef.current;
            setPosition({x: -(window.innerWidth + minimizedWidth) / 2, y: -(window.innerHeight + minimizedHeight) / 2});
        });

        return () => {
            cancelAnimationFrame(id);
        };
    }, [isMobile]);

    useEffect(() => {
        const {minimizedWidth} = optionsRef.current;
        setSize({
            width: minimizedWidth,
            height: minimizedWidth / aspectRatio,
        });
    }, [aspectRatio, isPortrait]);

    useEffect(() => {
        if (!isTablet) {
            return;
        }

        const timer = setTimeout(() => {
            handleResetPosition();
        }, 400);

        return () => {
            clearTimeout(timer);
        };
    }, [isPortrait, isTablet, handleResetPosition]);

    return {
        windowWidth,
        windowHeight,
        pointerEvents,
        size,
        position,
        minimized,
        setSize,
        setPosition,
        setPointerEvents,
        onDragStart,
        onDragStop,
        onResizeStart,
        onResizeStop,
        handleExpand,
        handleMinimize,
        rightCorner,
        handleRightCornerChange,
    };
}
