import {default as MuiMenu} from "@material-ui/core/Menu";
import {cls} from "../util";
import {MenuProps} from "./types";
import styles from "./style/Menu.module.scss";
import {forwardRef} from "react";

const Menu = forwardRef((props: MenuProps, ref?: any) => {
    return (
        <MuiMenu
            {...props}
            ref={ref}
            className={cls("fosMenu", styles.menu, props.className)}
            classes={{...props.classes, list: cls(styles.list, props.classes?.list), paper: cls(styles.paper, props.classes?.paper)}}
        >
            {props.children}
        </MuiMenu>
    );
});

Menu.displayName = "Menu";

export default Menu;
