import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/EmbedLinkDescription.module.scss";
import env from "@generated/environment";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {usePaletteActiveCateg} from "./CommandPaletteProviders";
import {ActionCategory, categMap, urlBasedArtifacts} from "./actionCategTree";
import {useTranslation} from "react-i18next";

const urlBasedTools = categMap.filter((obj) => obj.artifactTag && urlBasedArtifacts.includes(obj.artifactTag));

type ArtifactItemProps = {
    title?: string;
    icon?: string;
};

const ArtifactItem = ({icon, title}: ArtifactItemProps) => {
    return (
        <div className={cls("flex flex-items-center mb-15 mr-18", classes.artifactItem)}>
            {icon && <LazyLoadImage width={18} height={18} src={icon} />}
            <Typography variant="sm" color="septenary" className="ml-5">
                {title}
            </Typography>
        </div>
    );
};

type EmbedLinkDescriptionProps = {
    show: boolean;
};

export default function EmbedLinkDescription(props: EmbedLinkDescriptionProps) {
    const {t} = useTranslation();
    const {activeCateg} = usePaletteActiveCateg();
    const canShow = activeCateg.name === ActionCategory.Link && props.show;

    return canShow ? (
        <div className={cls("fullw flex flex-col", classes.root)}>
            <Typography className={cls("mb-10", classes.white)} variant="sm" fontWeight="bold">
                {t("palette.links.integrated_apps") ?? "Integrated apps"}
            </Typography>
            <Typography variant="sm" fontWeight="bold" color="septenary">
                {t("palette.links.integrated_apps_description") ??
                    "Links from these apps work seamlessly in Sessions and all participants can collaborate on them."}
            </Typography>
            <div className="flex fullw mt-20 mb-5 flex-wrap">
                {urlBasedTools.map((item) => (
                    <ArtifactItem key={item.id} title={item.title} icon={item.icon as string} />
                ))}
            </div>
            <div className="flex fullw flex-justify-between flex-items-center">
                <div className={classes.line} />
                <Typography variant="sm" color="septenary" className="ml-5">
                    {t("g.or") ?? "or"}
                </Typography>
                <div className={classes.line} />
            </div>
            <Typography className={cls("mt-15", classes.white)} variant="sm" fontWeight="bold">
                {t("palette.links.external_links") ?? "External links"}
            </Typography>
            <Typography variant="sm" fontWeight="bold" color="septenary" className="mt-10">
                {t("palette.links.external_links_description") ??
                    "These links will be embedded as fully functional web pages. Certain websites may not allow this."}
            </Typography>
        </div>
    ) : null;
}
