import {RoomDataFragment, RoomShareWorkspaceInput, ShareRoomDocument} from "@generated/data";
import {TableCellSize} from "@ui/cdk/Table/TableCell/types";
import apollo from "@workhorse/api/apollo";
import {mutate, query} from "@workhorse/dataApi";

export type RoomsHeadCellType = Array<{
    id: string;
    label: string;
    type: RoomsTableCellType;
    isSearchInput?: boolean;
    sortable?: boolean;
    size?: TableCellSize;
}>;

export type RoomsTableCellType = "string" | "title" | "date" | "roomState" | "enterRoom" | "copyLink";

export type RoomsSortField = keyof Pick<RoomDataFragment, "createdAt" | "active"> | "name";
export type RoomsSort = {
    field: RoomsSortField;
    direction: "asc" | "desc";
};

export const defaultRoomsSort: RoomsSort = {
    field: "name",
    direction: "desc",
};

export function createRoomsComparator(
    sort: RoomsSort
): (a: RoomDataFragment & {name: string}, b: RoomDataFragment & {name: string}) => number {
    switch (sort.field) {
        case "name": {
            if (sort.direction === "asc") {
                return (a, b) =>
                    !a.sessionTemplate?.name || !b.sessionTemplate?.name ? 0 : a.sessionTemplate.name.localeCompare(b.sessionTemplate.name);
            }
            return (a, b) =>
                !a.sessionTemplate?.name || !b.sessionTemplate?.name ? 0 : b.sessionTemplate.name.localeCompare(a.sessionTemplate.name);
        }

        case "createdAt": {
            if (sort.direction === "asc") {
                return (a, b) => (!a.createdAt || !b.createdAt ? 0 : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
            }
            return (a, b) => (!a.createdAt || !b.createdAt ? 0 : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        }

        case "active": {
            if (sort.direction === "asc") {
                return (a, b) => +a.active - +b.active;
            }
            return (a, b) => +b.active - +a.active;
        }

        default:
            return () => 0;
    }
}

export async function deleteRoom(roomId: string) {
    return await mutate("DeleteRoomDocument", {
        variables: {
            roomId,
        },
        refetchQueries: ["MyRooms"],
    });
}

export async function cloneRoom(roomId: string) {
    return await mutate("CloneRoomDocument", {
        variables: {
            roomId,
        },
        refetchQueries: ["MyRooms"],
        errorPolicy: "all",
    });
}

export async function checkIfRoomSessionInstanceEmpty(roomId: string) {
    return await query("IsRoomSessionInstanceEmptyDocument", {
        variables: {
            roomId,
        },
    });
}

export async function toggleRoomActiveState(roomId: string, newActiveState?: boolean) {
    return await mutate("ToggleRoomActiveStateDocument", {
        variables: {
            roomId,
            active: newActiveState,
        },
        refetchQueries: ["MyRooms", "RoomInstances"],
    });
}

export async function shareRoom(roomId: string, userEmails: string[] | undefined, shareWithWorkspaceInput: RoomShareWorkspaceInput) {
    return apollo.client.mutate({
        mutation: ShareRoomDocument,
        variables: {
            roomId,
            shareWithWorkspaceInput,
            userEmails,
        },
    });
}

const dec2hex = (dec: number) => {
    return dec.toString(16).padStart(2, "0");
};

export const randomStr = (len: number) => {
    const arr = new Uint8Array((len || 20) / 2);
    crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
};
