import env from "@generated/environment";
import Switch from "@ui/cdk/Switch";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import React, {useRef} from "@workhorse/api/rendering";
import classes from "@workhorse/style/CommonModules/microController.module.scss";
import {Field, Form, Formik, FormikProps} from "formik";
import {SlideShowMicroControllerFormElements, SlideShowMicroControllerProps} from "../config/slideshowConfigTypes";
import {toggleSlideshowControls} from "./slideshowApi";
import descriptor from "@generated/artifacts";
import Tooltip from "@ui/cdk/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {DUMMY_AGENDA_ITEM_TITLE} from "@workhorse/api/designer";
import {useTranslation} from "react-i18next";

function MicroController(props: SlideShowMicroControllerProps) {
    const {t} = useTranslation();
    const {currentMicro} = props;

    const formRef = useRef<FormikProps<Partial<SlideShowMicroControllerFormElements>>>(null);

    const iconSrc = `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[props.currentMicro.artifactId]?.meta?.icon}.svg`;

    const initialValues = {
        navigationEnabled: currentMicro.data.navigationEnabled,
    } as Partial<SlideShowMicroControllerFormElements>;

    const onSubmit = (values: Partial<SlideShowMicroControllerFormElements>) => {};

    const onChangeSlideshowNavigation = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleSlideshowControls(currentMicro.id, !event.target.checked);
        formRef.current?.setFieldValue("navigationEnabled", !event.target.checked);
    };

    return (
        <>
            {props.hasAgenda ? (
                <div className={cls("flex flex-justify-between flex-align-center fullw", classes.titleContainer)}>
                    <div className="flex fullw">
                        <img className={classes.artifactIcon} src={iconSrc}></img>
                        <Typography className={cls(classes.artifactTitle, classes.label)}>
                            {props.agendaItemTitle && props.agendaItemTitle !== DUMMY_AGENDA_ITEM_TITLE
                                ? props.agendaItemTitle
                                : "Slideshow"}
                        </Typography>
                    </div>
                </div>
            ) : null}

            <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
                {({values}) => (
                    <Form className={cls("flex flex-wrap flex00-auto fullw")}>
                        <Field name="navigationEnabled">
                            {() => (
                                <div
                                    className={cls(
                                        "flex flex-justify-between flex-align-center fullw",
                                        classes.settingsContainer,
                                        !props.hasAgenda ? classes.settingsNoMarginTop : undefined
                                    )}
                                >
                                    <div className="flex flex-row flex-align-center gap-5">
                                        <Typography className={classes.settingsTitle}>
                                            {t("artifacts.g.micro_controller.present_to_participants")}
                                        </Typography>
                                        <Tooltip
                                            title={t("artifacts.g.micro_controller.present_to_participants_description") ?? ""}
                                            arrow
                                            placement="top"
                                        >
                                            <InfoOutlinedIcon className={classes.tooltipIcon} />
                                        </Tooltip>
                                    </div>
                                    <Switch
                                        color="primary"
                                        onChange={onChangeSlideshowNavigation}
                                        checked={values.navigationEnabled?.toString() === "false"}
                                    />
                                </div>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default {
    MicroController,
};
