import {makeId} from "@workhorse/api/designer/lib/utils";
import {ReactNode, useCallback, useState, useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";

type Resolve<T> = (value: T | PromiseLike<T>) => void;
type Reject = (reason?: any) => void;
type Callback<T> = (resolve: Resolve<T>, reject: Reject, key: string) => ReactNode;

function useAsyncDialogRendererStore() {
    const [dialogs, setDialogs] = useState<ReactNode[]>([]);

    const addNode = useCallback((node: ReactNode) => {
        setDialogs((dialogs) => [...dialogs, node]);
    }, []);

    const removeNode = useCallback((node: ReactNode) => {
        setDialogs((dialogs) => dialogs.filter((dialog) => dialog !== node));
    }, []);

    const createDialog = useCallback(
        async <T = void>(callback: Callback<T>) => {
            return new Promise<T>((resolve, reject) => {
                const onResolve: Resolve<T> = (value) => {
                    removeNode(node);
                    resolve(value);
                };

                const onReject: Reject = (reason) => {
                    removeNode(node);
                    reject(reason);
                };

                const node = callback(onResolve, onReject, makeId());
                addNode(node);
            });
        },
        [addNode, removeNode]
    );

    return useMemo(
        () => ({
            dialogs,
            createDialog,
        }),
        [dialogs, createDialog]
    );
}

export const [AsyncDialogRendererProvider, useAsyncDialog] = createContextProvider(
    {
        name: "AsyncDialogRenderer",
    },
    useAsyncDialogRendererStore
);
