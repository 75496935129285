import {ReactComponent as ShareFileIcon} from "./icons/share-file.svg";
import {ReactComponent as OpenLinkIcon} from "./icons/open-link.svg";
import {ReactComponent as EmbedToolIcon} from "./icons/embed-tool.svg";
import {ReactComponent as AddAgendaIcon} from "./icons/add-agenda.svg";
import {ReactComponent as SearchAgendasIcon} from "./icons/search-agendas.svg";
import {ReactComponent as PasteLinkIcon} from "./icons/paste-link.svg";
import {ReactComponent as AddBoxIcon} from "./icons/add-box.svg";
import {v4 as uuid} from "uuid";
import artifactDescriptor from "@generated/artifacts";
import simpleArtifactsMap, {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import env from "@generated/environment";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {isHTMLTag} from "@artifacts/iframe/api/iframeUtils";
import {t} from "i18next";
import {PaletteItemBase, PaletteItemToolType} from "@generated/data";

type ToolTypeByArtifactTag = {
    [K in SimpleArtifactsTag]?: PaletteItemToolType;
};

export const toolType: ToolTypeByArtifactTag = {
    "flowos/adobexd": PaletteItemToolType.Adobexd,
    "flowos/airtable": PaletteItemToolType.Airtable,
    "flowos/blackboard": PaletteItemToolType.Smartboard,
    "flowos/canva": PaletteItemToolType.Canva,
    "flowos/google-docs": PaletteItemToolType.GoogleDocs,
    "flowos/google-sheets": PaletteItemToolType.GoogleSheets,
    "flowos/google-slides": PaletteItemToolType.GooglePresentation,
    "flowos/google-forms": PaletteItemToolType.GoogleForms,
    "flowos/figma": PaletteItemToolType.Figma,
    "flowos/form": PaletteItemToolType.Form,
    "flowos/mentimeter": PaletteItemToolType.Mentimeter,
    "flowos/miro": PaletteItemToolType.Miro,
    "flowos/pitch": PaletteItemToolType.Pitch,
    "flowos/slido": PaletteItemToolType.Slido,
    "flowos/twitch": PaletteItemToolType.Twitch,
    "flowos/vimeo": PaletteItemToolType.Vimeo,
    "flowos/youtube": PaletteItemToolType.Youtube,
    "flowos/boardmix": PaletteItemToolType.Boardmix,
};

export function isValidLink(str?: string) {
    if (str && isHTMLTag(str)) {
        return true;
    }

    return (
        typeof str === "string" &&
        str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null
    );
}

export enum ActionCategory {
    File = "File",
    Link = "Link",
    Tool = "Tool",
    Agenda = "Agenda",
}

export enum FinalAction {
    Create = "Create",
    Use = "Use",
    Search = "Search",
}

export type PaletteActionArg = {
    action: FinalAction;
    isTool: boolean;
    isNewAgenda?: boolean;
    artifactTag?: SimpleArtifactsTag;
    toolType?: PaletteItemToolType;
    agendaId?: string;
    item?: PaletteItemBase;
    actionId?: string;
    resourceType?: ResourcesTypes;
};

export enum PaletteKeyShortcut {
    UploadFile = "u",
    NewAgenda = "f",
}

export type ActionCategTreeItem = {
    id: string;
    // basically used only for root actions
    // in combination with ctrlKey
    ctrlShortcut?: "f" | "l" | "e" | "a";

    // this should be an enum
    shortcut?: PaletteKeyShortcut;

    // if an item has NO title, it will not be rendered by default
    title?: string;
    translateKey?: string;

    // description, title and command are used for fuzzy search
    description?: string;

    searchPlaceholder?: string;
    searchPlaceholderTranslateKey?: string;

    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
    activeIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
    actionArg?: PaletteActionArg;
    next?: Array<ActionCategTreeItem>;
    isPlaceholder?: boolean;
    artifactTag?: SimpleArtifactsTag;
    toolType?: PaletteItemToolType;
    isAction?: boolean;
    excludeFromRootSearch?: boolean;
    urlBased?: boolean;
    linkPlaceholder?: string;
    hasNoResource?: boolean;
    hasInfoArea?: boolean;
    isHidden?: boolean;
};

type ActionCategoryTree = {
    [K in ActionCategory]: ActionCategTreeItem;
};

const excludedArtifacts: Array<SimpleArtifactsTag> = [
    "flowos/iframe",
    "flowos/polls",
    "flowos/qa",
    "flowos/agenda",
    "flowos/conference",
    "flowos/contextual-notes",
    "flowos/chat",
    "flowos/slideshow",
    "flowos/gallery",
    "flowos/transcript",
    "flowos/takeaways",
    "flowos/video",
];

const noResourceArtifact: Array<SimpleArtifactsTag> = ["flowos/break", "flowos/breakout", "flowos/miro", "flowos/my-product-tool"];

export const urlBasedArtifacts: Array<SimpleArtifactsTag> = [
    "flowos/iframe",
    "flowos/mentimeter",
    "flowos/airtable",
    "flowos/pitch",
    "flowos/slido",
    "flowos/youtube",
    "flowos/vimeo",
    "flowos/video",
    "flowos/twitch",
    "flowos/boardmix",
];

export const artifactsWithPlugin: Array<SimpleArtifactsTag> = ["flowos/figma", "flowos/canva", "flowos/adobexd"];
const vowels = ["a", "e", "i", "o", "u"]; // for the "an" in "an agenda"

let artifactItems: ActionCategTreeItem[] = [];

export function makeArtifactItems(add?: ActionCategTreeItem[], remove?: string[]) {
    let items = (Object.keys(simpleArtifactsMap) as Array<SimpleArtifactsTag>)
        .filter((artifactTag) => {
            if (artifactDescriptor[artifactTag].capabilities.some(({type}) => type === "files")) {
                return false;
            }

            return !excludedArtifacts.includes(artifactTag) && !(artifactTag === "flowos/my-product-tool");
        })
        .map((artifactTag) => {
            const descriptor = artifactDescriptor[artifactTag];
            const {description, icon, displayName, labels} = descriptor.meta;
            const noChild = noResourceArtifact.includes(artifactTag);
            const displayNameFiltered = displayName.replace("s by Sessions", "").replace("by Sessions", "");

            const linkPlaceholder = urlBasedArtifacts.includes(artifactTag)
                ? vowels.includes(displayNameFiltered[0].toLowerCase())
                    ? `Paste an ${displayNameFiltered} url`
                    : `Paste a ${displayNameFiltered} url`
                : undefined;

            return {
                id: uuid(),
                artifactTag,
                description,
                icon: labels["artifacts.sessions.flowos.com/custom-icon-url"] ?? `${env.publicResourcesCdnRoot ?? ""}/icons/${icon}.svg`,
                searchPlaceholder: t(`artifacts.${descriptor.meta.name}.search_placeholder`) ?? `Search for ${displayName} resources...`,
                title: displayName,
                translateKey: `artifacts.${descriptor.meta.name}.title`,
                actionArg: {
                    action: FinalAction.Search,
                    isTool: true,
                    artifactTag,
                    toolType: toolType[artifactTag],
                },
                // if need be for some specific type of results to be shown by tool type
                // this s where they're specified
                // available values "tools", "files", "agendas", "recent"
                toolType: toolType[artifactTag],
                excludeFromRootSearch: true,
                hasNoResource: noChild,
                hasInfoArea: artifactsWithPlugin.includes(artifactTag),
                next: [
                    {
                        id: uuid(),
                        title: noResourceArtifact.includes(artifactTag)
                            ? `${displayNameFiltered}`
                            : artifactsWithPlugin.includes(artifactTag)
                            ? t(`artifacts.${descriptor.meta.name}.open`) ?? `Open ${displayNameFiltered}`
                            : t(`artifacts.${descriptor.meta.name}.create`) ?? `Create ${displayNameFiltered}`,
                        searchPlaceholder: `Create a new ${displayNameFiltered}`,
                        icon: noResourceArtifact.includes(artifactTag)
                            ? labels["artifacts.sessions.flowos.com/custom-icon-url"] ??
                              `${env.publicResourcesCdnRoot ?? ""}/icons/${icon}.svg`
                            : AddBoxIcon,
                        isAction: true,
                        urlBased: urlBasedArtifacts.includes(artifactTag),
                        linkPlaceholder: linkPlaceholder,
                        actionArg: {
                            action: FinalAction.Create,
                            isTool: true,
                            artifactTag,
                            toolType: toolType[artifactTag],
                        },
                        hasNoResource: noChild,
                        translateKey: noResourceArtifact.includes(artifactTag)
                            ? `${displayNameFiltered}`
                            : artifactsWithPlugin.includes(artifactTag)
                            ? `artifacts.${descriptor.meta.name}.open`
                            : `artifacts.${descriptor.meta.name}.create`,
                    },
                ],
            };
        })
        .concat(add ?? ([] as any));

    if (remove && Array.isArray(remove)) {
        items = items.filter((itm) => !(remove as string[]).includes(itm.id));
    }

    artifactItems = items;
}

makeArtifactItems();

export let categTree: ActionCategoryTree = {} as any;

const fileCategId = uuid();
const fileNextCategId = uuid();

export function makeCategTree() {
    categTree = {
        [ActionCategory.File]: {
            id: fileCategId,
            title: "Share a file",
            translateKey: "palette.files.share_a_file",
            // description: "Share an existing file from your storage, or share a newly uploaded file",
            icon: ShareFileIcon,
            ctrlShortcut: "f",
            searchPlaceholder: "Search in existing files or upload new ones...",
            searchPlaceholderTranslateKey: "palette.files.placeholder_search",
            next: [
                {
                    id: fileNextCategId,
                    title: "Upload a file",
                    translateKey: "palette.files.upload_a_file",
                    isAction: true,
                    icon: AddBoxIcon,
                    actionArg: {
                        action: FinalAction.Create,
                        isTool: false,
                    },
                },
            ],
        },

        [ActionCategory.Link]: {
            id: uuid(),
            title: "Open a link",
            translateKey: "palette.links.open_a_link",
            // description: "Open a link",

            icon: OpenLinkIcon,
            activeIcon: PasteLinkIcon,
            ctrlShortcut: "l",
            searchPlaceholder: "Paste a link",
            searchPlaceholderTranslateKey: "palette.links.placeholder_search",
            next: [
                {
                    id: uuid(),
                    isPlaceholder: true,
                },
            ],
        },
        [ActionCategory.Tool]: {
            id: uuid(),
            title: "Embed a tool",
            translateKey: "palette.tools.embed_a_tool",
            // description: "Embed a tool",
            searchPlaceholder: "Search for a tool...",
            searchPlaceholderTranslateKey: "palette.tools.placeholder_search",
            icon: EmbedToolIcon,

            ctrlShortcut: "e",
            next: artifactItems,
        },
        [ActionCategory.Agenda]: {
            id: uuid(),
            title: "Add an agenda",
            translateKey: "palette.agendas.add_an_agenda",
            icon: AddAgendaIcon,
            activeIcon: SearchAgendasIcon,
            ctrlShortcut: "a",
            searchPlaceholder: "Search for an agenda...",
            searchPlaceholderTranslateKey: "palette.agendas.placeholder_search",
            next: [
                {
                    id: uuid(),
                    title: "New agenda",
                    translateKey: "palette.agendas.new_agenda",
                    isAction: true,
                    actionArg: {
                        action: FinalAction.Create,
                        isTool: false,
                        isNewAgenda: true,
                    },
                    icon: AddBoxIcon,
                    // next: [
                    //     {
                    //         id: uuid(),
                    //         title: "Create sub agenda 1",
                    //     },
                    //     {
                    //         id: uuid(),
                    //         title: "Create sub agenda 2",
                    //         next: [
                    //             {
                    //                 id: uuid(),
                    //                 title: "Create sub 2 option",
                    //             },
                    //         ],
                    //     },
                    // ],
                },
            ],
        },
    };
}

makeCategTree();

export type CategSearchItem = {
    searchStr?: string;
    categ: ActionCategory;
    parentId?: string;
    firstSubItemId: string | undefined;
    isHidden?: boolean;
} & Pick<ActionCategTreeItem, "ctrlShortcut" | "id" | "excludeFromRootSearch" | "isAction" | "hasNoResource">;

export type FlatCategItem = ActionCategTreeItem & {categ: ActionCategory; parentId?: string; firstSubItemId?: string};

type CategSearchMap = Array<CategSearchItem>;
type CategFlatMap = Array<FlatCategItem>;

function parseCategForSearch(mainCategory: ActionCategory, treeItems: ActionCategTreeItem[], out: CategSearchMap, parentId?: string) {
    let len = treeItems.length;
    let pushed: string[] = [];

    while (len--) {
        const i = treeItems.length - len - 1;
        const item = treeItems[i];

        ["title" /*"description"*/].forEach((str) => {
            if (!item[str] || pushed.indexOf(item[str]) !== -1) {
                return false;
            }
            // in case we have identical title & description...
            // don't add both because they point to the same action and path
            pushed.push(item[str]);

            out.push({
                searchStr: item[str],
                categ: mainCategory,
                parentId,
                id: item.id,
                ctrlShortcut: item.ctrlShortcut,
                firstSubItemId: item.next?.[0]?.id,
                excludeFromRootSearch: item.excludeFromRootSearch,
                isAction: item.isAction,
                hasNoResource: item.hasNoResource,
            });
        });

        if (item.next) {
            parseCategForSearch(mainCategory, item.next, out, item.id);
        }
    }
}

function flattenCategItems(mainCategory: ActionCategory, treeItems: ActionCategTreeItem[], out: CategFlatMap, parentId?: string) {
    let len = treeItems.length;

    while (len--) {
        const i = treeItems.length - len - 1;
        const item = treeItems[i];

        out.push({
            categ: mainCategory,
            parentId,
            firstSubItemId: item.next?.[0]?.id,
            ...item,
        });

        if (item.next) {
            flattenCategItems(mainCategory, item.next, out, item.id);
        }
    }
}

export let categMapForSearch: CategSearchMap = [] as any;

export function makeCategMapForSearch() {
    categMapForSearch = (Object.keys(categTree) as ActionCategory[]).reduce((acc, mainCategory) => {
        parseCategForSearch(mainCategory, [categTree[mainCategory]], acc);
        return acc;
    }, [] as CategSearchMap);
}

makeCategMapForSearch();

export let categMap: CategFlatMap = [];

export let linkItem = categMap.find((obj) => obj.categ === ActionCategory.Link && !obj.parentId);

export let mainCategories = Object.keys(ActionCategory) as (keyof typeof ActionCategory)[];
export let mainCategoryIds = mainCategories.map((categ) => categTree[categ].id);

export function makeCategMap() {
    categMap = (Object.keys(categTree) as ActionCategory[]).reduce((acc, mainCategory) => {
        flattenCategItems(mainCategory, [categTree[mainCategory]], acc);
        return acc;
    }, [] as CategFlatMap);

    linkItem = categMap.find((obj) => obj.categ === ActionCategory.Link && !obj.parentId);
    mainCategories = Object.keys(ActionCategory) as (keyof typeof ActionCategory)[];
    mainCategoryIds = mainCategories.map((categ) => categTree[categ].id);
}

makeCategMap();

/**
 *
 * @param mainCateg
 * @param parent
 * @returns the parent of a sub-category
 */
export function parentOfCateg(mainCateg: ActionCategory | undefined, childId?: string) {
    if (!mainCateg) {
        return undefined;
    }
    if (!childId) {
        return categMap.find((obj) => !obj.parentId && obj.categ === mainCateg);
    }
    const child = categMap.find((obj) => obj.id === childId);
    return categMap.find((obj) => obj.id === child?.parentId);
}

export function categById(mainCateg: ActionCategory | undefined, childId?: string) {
    if (!mainCateg) {
        return undefined;
    }
    if (!childId) {
        return categMap.find((obj) => !obj.parentId && obj.categ === mainCateg);
    }
    return categMap.find((obj) => obj.id === childId);
}
