let queue: Array<() => Promise<void>> = [];
let processingQueue: boolean = false;

async function processQueue() {
    if (processingQueue || queue.length === 0) {
        return;
    }
    processingQueue = true;
    const fn = queue.shift();
    await fn?.().then(() => {
        processingQueue = false;
        processQueue();
    });
}

export function addToQueue(fn: () => Promise<void>) {
    queue.push(fn);
    processQueue();
}
