import {MainTheme} from "./types";

const drawerMarginLeft = 36;

// TODO: Enforce type when MainTheme is stable
const mainTheme: {
    [key: string]: any;
    main: MainTheme;
} = {
    transitions: {create: () => "none"},
    typography: {
        // "Twemoji Country Flags" is only used to polyfill flags on windows
        // This is safe because the font is loaded such that the browser will only use it for country flag emojis and not for any other characters. Therefore, the browser will simply use the next font in the list for every character except country flags.
        // Furthermore, browsers that have native support for country flags will not load font at all, and therefore will simply ignore it in the font-family list. Browsers that do not have native country flags will render country flags using "Twemoji Country Flags" and all other characters as before.

        fontFamily: "'Twemoji Country Flags', 'Inter', 'Arial', sans-serif",
        fontSize: 14,
    },
    breakpoints: {
        keys: ["xs", "sm", "md", "lg"],
        values: {
            xs: 0,
            sm: 767,
            md: 1024,
            lg: 1920,
        },
    },
    avatar: {
        color: {
            1: "var(--sessions-avatar-color-01)",
            2: "var(--sessions-avatar-color-02)",
            3: "var(--sessions-avatar-color-03)",
            4: "var(--sessions-avatar-color-04)",
            5: "var(--sessions-avatar-color-05)",
            6: "var(--sessions-avatar-color-06)",
            7: "var(--sessions-avatar-color-07)",
            8: "var(--sessions-avatar-color-08)",
            9: "var(--sessions-avatar-color-09)",
            10: "var(--sessions-avatar-color-10)",
            11: "var(--sessions-avatar-color-11)",
            12: "var(--sessions-avatar-color-12)",
            13: "var(--sessions-avatar-color-13)",
            14: "var(--sessions-avatar-color-14)",
            15: "var(--sessions-avatar-color-15)",
        },
    },
    main: {
        palette: {
            basic: {
                background: "var(--sessions-white)",
                foreground: "var(--sessions-black)",
            },
            outline: {
                primary: "var(--sessions-outline-primary)",
            },
            immutable: {
                blue: {
                    "500": "var(--sessions-immutable-blue-500)",
                },
            },
            cdkPalette: {
                border: {
                    primary: "var(--sessions-container-border-primary)",
                    secondary: "var(--sessions-container-border-secondary)",
                    tertiary: "var(--sessions-container-border-tertiary)",
                    quaternary: "var(--sessions-container-border-quaternary)",
                    quinary: "var(--sessions-container-border-quinary)",
                    senary: "var(--sessions-container-border-senary)",
                    septenary: "var(--sessions-container-border-septenary)",
                    octonary: "var(--sessions-container-border-octonary)",
                    nonary: "var(--sessions-container-border-nonary)",

                    innerBorder: "var(--sessions-container-inner-border)",
                },

                gradients: {
                    buttonBlue: {
                        light: "var(--sessions-button-blue-gradient-light)",
                        dark: "var(--sessions-button-blue-gradient-dark)",
                        darker: "var(--sessions-button-blue-gradient-darker)",
                        disabled: "var(--sessions-button-blue-gradient-disabled)",
                    },
                    buttonGrey: {
                        light: "var(--sessions-button-grey-gradient-light)",
                        dark: "var(--sessions-button-grey-gradient-dark)",
                        disabled: "var(--sessions-button-grey-gradient-disabled)",
                    },
                    buttonTertiary: {
                        dark: "var(--sessions-button-tertiary-gradient-dark)",
                        darker: "var(--sessions-button-tertiary-gradient-darker)",
                        disabled: "var(--sessions-button-tertiary-gradient-disabled)",
                    },
                    buttonPlain: {
                        dark: "var(--sessions-button-plain-gradient-dark)",
                        darker: "var(--sessions-button-plain-gradient-darker)",
                    },
                    buttonGhost: {
                        light: "var(--sessions-button-ghost-gradient-light)",
                        dark: "var(--sessions-button-ghost-gradient-dark)",
                        disabled: "var(--sessions-button-ghost-gradient-disabled)",
                    },
                    scrollContainer: {
                        white: "var(--sessions-scroll-shadow-white)",
                        grey: "var(--sessions-scroll-shadow-grey)",
                    },
                    homeButton: {
                        border: ["var(--sessions-home-button-border-gradient)", "var(--sessions-home-button-border-gradient-1)"],
                    },
                    switchPrimary: "var(--sessions-switch-primary-gradient)",
                },
            },
            gray: {
                50: "var(--sessions-grey-blue-50)",
                100: "var(--sessions-grey-blue-100)",
                200: "var(--sessions-grey-blue-200)",
                300: "var(--sessions-grey-blue-300)",

                400: "var(--sessions-grey-blue-400)",
                500: "var(--sessions-grey-blue-500)",
                600: "var(--sessions-grey-blue-600)",
                700: "var(--sessions-grey-blue-700)",
                800: "var(--sessions-grey-blue-800)",
                900: "var(--sessions-grey-blue-900)",
                T500: {
                    5: "var(--sessions-grey-blue-500-5)",
                    10: "var(--sessions-grey-blue-500-10)",
                    15: "var(--sessions-grey-blue-500-15)",
                    20: "var(--sessions-grey-blue-500-20)",
                    25: "var(--sessions-grey-blue-500-25)",
                    75: "var(--sessions-grey-blue-500-75)",
                    85: "var(--sessions-grey-blue-500-85)",
                    95: "var(--sessions-grey-blue-500-95)",
                },
                T300: {
                    30: "var(--sessions-grey-blue-300-30)",
                },
                T900: {
                    75: "var(--sessions-grey-blue-900-75)",
                },
            },
            neutralGray: {
                900: "var(--sessions-neutral-gray-900)",
                800: "var(--sessions-neutral-gray-800)",
                700: "var(--sessions-neutral-gray-700)",
                600: "var(--sessions-neutral-gray-600)",
                500: "var(--sessions-neutral-gray-500)",
                400: "var(--sessions-neutral-gray-400)",
                300: "var(--sessions-neutral-gray-300)",
                200: "var(--sessions-neutral-gray-200)",
                100: "var(--sessions-neutral-gray-100)",
                50: "var(--sessions-neutral-gray-50)",
                T500: {
                    15: "var(--sessions-neutral-gray-500-15)",
                    20: "var(--sessions-neutral-gray-500-20)",
                },
            },
            blue: {
                50: "var(--sessions-blue-50)",
                100: "var(--sessions-blue-100)",
                200: "var(--sessions-blue-200)",
                300: "var(--sessions-blue-300)",
                400: "var(--sessions-blue-400)",
                500: "var(--sessions-blue-500)",
                600: "var(--sessions-blue-600)",
                700: "var(--sessions-blue-700)",
                800: "var(--sessions-blue-800)",
                900: "var(--sessions-blue-900)",
                T500: {
                    5: "var(--sessions-blue-500-05)",
                    7: "var(--sessions-blue-500-07)",
                    10: "var(--sessions-blue-500-10)",
                    15: "var(--sessions-blue-500-15)",
                    20: "var(--sessions-blue-500-20)",
                },
            },
            purple: {
                50: "var(--sessions-purple-50)",
                100: "var(--sessions-purple-100)",
                200: "var(--sessions-purple-200)",
                400: "var(--sessions-purple-400)",
                300: "var(--sessions-purple-300)",
                500: "var(--sessions-purple-500)",
                600: "var(--sessions-purple-600)",
                700: "var(--sessions-purple-700)",
                T500: {
                    10: "var(--sessions-purple-500-10)",
                    15: "var(--sessions-purple-500-15)",
                    20: "var(--sessions-purple-500-20)",
                },
            },
            green: {
                700: "var(--sessions-green-700)",
                600: "var(--sessions-green-600)",
                500: "var(--sessions-green-500)",
                400: "var(--sessions-green-400)",
                300: "var(--sessions-green-300)",
                200: "var(--sessions-green-200)",
                100: "var(--sessions-green-100)",
                50: "var(--sessions-green-50)",
                T500: {
                    10: "var(--sessions-green-500-10)",
                    15: "var(--sessions-green-500-15)",
                    20: "var(--sessions-green-500-20)",
                },
            },
            yellow: {
                700: "var(--sessions-yellow-700)",
                600: "var(--sessions-yellow-600)",
                500: "var(--sessions-yellow-500)",
                400: "var(--sessions-yellow-400)",
                300: "var(--sessions-yellow-300)",
                200: "var(--sessions-yellow-200)",
                100: "var(--sessions-yellow-100)",
                50: "var(--sessions-yellow-50)",
                T500: {
                    10: "var(--sessions-yellow-500-10)",
                    15: "var(--sessions-yellow-500-15)",
                    20: "var(--sessions-yellow-500-20)",
                },
            },
            red: {
                700: "var(--sessions-red-700)",
                600: "var(--sessions-red-600)",
                500: "var(--sessions-red-500)",
                400: "var(--sessions-red-400)",
                300: "var(--sessions-red-300)",
                200: "var(--sessions-red-200)",
                100: "var(--sessions-red-100)",
                50: "var(--sessions-red-50)",
                T500: {
                    10: "var(--sessions-red-500-10)",
                    15: "var(--sessions-red-500-15)",
                    20: "var(--sessions-red-500-20)",
                },
            },
            elevation: {
                background: [
                    "var(--sessions-elevation-background)",
                    "var(--sessions-elevation-background-1)",
                    "var(--sessions-elevation-background-2)",
                    "var(--sessions-elevation-background-3)",
                    "var(--sessions-elevation-background-4)",
                    "var(--sessions-elevation-background-5)",
                    "var(--sessions-elevation-background-6)",
                ],
                surface: [
                    "var(--sessions-elevation-surface)",
                    "var(--sessions-elevation-surface-1)",
                    "var(--sessions-elevation-surface-2)",
                    "var(--sessions-elevation-surface-3)",
                    "var(--sessions-elevation-surface-4)",
                    "var(--sessions-elevation-surface-5)",
                    "var(--sessions-elevation-surface-6)",
                    "var(--sessions-elevation-surface-7)",
                    "var(--sessions-elevation-surface-8)",
                    "var(--sessions-elevation-surface-9)",
                    "var(--sessions-elevation-surface-10)",
                    "var(--sessions-elevation-surface-11)",
                ],
                onSurface: [
                    "var(--sessions-elevation-onSurface)",
                    "var(--sessions-elevation-onSurface-1)",
                    "var(--sessions-elevation-onSurface-2)",
                    "var(--sessions-elevation-onSurface-3)",
                    "var(--sessions-elevation-onSurface-4)",
                    "var(--sessions-elevation-onSurface-5)",
                    "var(--sessions-elevation-onSurface-6)",
                    "var(--sessions-elevation-onSurface-7)",
                    "var(--sessions-elevation-onSurface-8)",
                    "var(--sessions-elevation-onSurface-9)",
                ],
                above: [
                    "var(--sessions-elevation-above)",
                    "var(--sessions-elevation-above-1)",
                    "var(--sessions-elevation-above-2)",
                    "var(--sessions-elevation-above-3)",
                    "var(--sessions-elevation-above-4)",
                    "var(--sessions-elevation-above-5)",
                ],
            },
            primary: {
                "10": "var(--sessions-primary-10)",
                "50": "var(--sessions-primary-50)",
                "100": "var(--sessions-primary-100)",
                "200": "var(--sessions-primary-200)",
                "300": "var(--sessions-primary-300)",
                "400": "var(--sessions-primary-400)",
                "500": "var(--sessions-primary-500)",
                "600": "var(--sessions-primary-600)",
            },
            secondary: {
                "50": "var(--sessions-secondary-50)",
                "100": "var(--sessions-secondary-100)",
                "200": "var(--sessions-secondary-200)",
                "300": "var(--sessions-secondary-300)",
                "400": "var(--sessions-secondary-400)",
                "500": "var(--sessions-secondary-500)",
                "600": "var(--sessions-secondary-600)",
            },
            dark: {
                "50": "var(--sessions-dark-50)",
                "100": "var(--sessions-dark-100)",
                "200": "var(--sessions-dark-200)",
                "300": "var(--sessions-dark-300)",
                "400": "var(--sessions-dark-400)",
                "500": "var(--sessions-dark-500)",
                "600": "var(--sessions-dark-600)",
            },
            background: {
                primary: "var(--sessions-theme-bg-primary)",
                blur: "var(--sessions-theme-bg-blur)",
            },
            state: {
                backgroundHover: "var(--sessions-background-hover-color)",
                borderActive: "var(--sessions-border-active-color)",
                borderHover: "var(--sessions-border-hover-color)",
                errorPrimary: "var(--sessions-error-primary)",
                errorSecondary: "var(--sessions-error-secondary)",
                successPrimary: "var(--sessions-success-primary)",
                successSecondary: "var(--sessions-success-secondary)",
                warningPrimary: "var(--sessions-warning-primary)",
                warningSecondary: "var(--sessions-warning-secondary)",
                disabled: "var(--sessions-disabled)",
                textDisabled: "var(--sessions-text-disabled)",
                shadow: "var(--sessions-black-10)",
                selectItemSelected: "var(--sessions-select-item-selected)",
                listItemSelectedBackground: "var(--sessions-list-item-selected-background)",
            },
            text: {
                primary: "var(--sessions-text-primary)",
                secondary: "var(--sessions-text-secondary)",
                tertiary: "var(--sessions-text-tertiary)",
                quaternary: "var(--sessions-text-quaternary)",
                quinary: "var(--sessions-text-quinary)",
                senary: "var(--sessions-text-senary)",
                septenary: "var(--sessions-text-septenary)",
                octonary: "var(--sessions-text-octonary)",
                nonary: "var(--sessions-text-nonary)",
                denary: "var(--sessions-text-denary)",
                eleventh: "var(--sessions-text-eleventh)",
                duodenary: "var(--sessions-text-duodenary)",
                tridenary: "var(--sessions-text-tridenary)",
                tetradecenary: "var(--sessions-text-tetradecenary)",
                quindenary: "var(--sessions-text-quindenary)",
                sedenary: "var(--sessions-text-sedenary)",

                tooltipPrimary: "var(--sessions-text-tooltip-primary)",
                tooltipSecondary: "var(--sessions-text-tooltip-secondary)",
                tooltipTertiary: "var(--sessions-text-tooltip-tertiary)",
            },
            backdrop: {
                primary: "var(--sessions-standard-backdrop)",
                secondary: "var(--sessions-spinner-backdrop)",
                specialDrawer: "var(--sessions-special-drawer-backdrop)",
                transparent: "var(--sessions-transparent-backdrop)",
            },
            divider: {
                primary: "var(--sessions-divider-primary)",
                secondary: "var(--sessions-divider-secondary)",
                tertiary: "var(--sessions-divider-tertiary)",
                quaternary: "var(--sessions-divider-quaternary)",
            },
            switch: {
                thumbDefault: "var(--sessions-switch-thumb-default)",
                trackDefault: "var(--sessions-switch-track-default)",
                thumbDisabled: "var(--sessions-switch-thumb-disabled)",
                trackDisabled: "var(--sessions-switch-track-disabled)",
                thumbShadow: "var(--sessions-switch-thumb-shadow)",
            },
            badge: {
                background: {
                    purple: "var(--sessions-badge-background-purple)",
                },

                text: {
                    purple: "var(--sessions-badge-text-purple)",
                },
            },
        },
        player: {
            agenda: {
                placeholders: {
                    textSkeleton: "var(--sessions-player-agenda-placeholder-text-skeleton)",
                },
                agendaItem: {
                    hover: {
                        background: "var(--sessions-player-agenda-item-hover-background)",
                    },
                },
            },
            tools: {
                background: "var(--sessions-player-tools-background)",
            },
        },

        // Imported from Website
        // fontFamily: "'Inter', sans-serif, 'Roboto', 'Helvetica', 'Arial', sans-serif",
        typography: {
            xs: {
                fontSize: "var(--sessions-font-size-xs)",
                lineHeight: "var(--sessions-line-height-xs)",
            },
            sm: {
                fontSize: "var(--sessions-font-size-sm)",
                lineHeight: "var(--sessions-line-height-sm)",
            },
            base: {
                fontSize: "var(--sessions-font-size-base)",
                lineHeight: "var(--sessions-line-height-base)",
            },
            lg: {
                fontSize: "var(--sessions-font-size-lg)",
                lineHeight: "var(--sessions-line-height-lg)",
            },
            xl: {
                fontSize: "var(--sessions-font-size-xl)",
                lineHeight: "var(--sessions-line-height-xl)",
            },
            xl2: {
                fontSize: "var(--sessions-font-size-xl2)",
                lineHeight: "var(--sessions-line-height-xl2)",
            },
            xl3: {
                fontSize: "var(--sessions-font-size-xl3)",
                lineHeight: "var(--sessions-line-height-xl3)",
            },
            xl4: {
                fontSize: "var(--sessions-font-size-xl4)",
                lineHeight: "var(--sessions-line-height-xl4)",
            },
            xl5: {
                fontSize: "var(--sessions-font-size-xl5)",
                lineHeight: "var(--sessions-line-height-xl5)",
            },
            xl6: {
                fontSize: "var(--sessions-font-size-xl6)",
                lineHeight: "var(--sessions-line-height-xl6)",
            },
            xl7: {
                fontSize: "var(--sessions-font-size-xl7)",
                lineHeight: "var(--sessions-line-height-xl7)",
            },
        },

        heights: {
            actionsRightMobile: 64,
            footerMobile: 92,
            audienceAvatarRow: 75,
            coverHeightDesktop: 296,
            coverHeightMobile: 200,
        },

        base: {
            border: {
                primary: "var(--sessions-base-border-primary)",
                secondary: "var(--sessions-base-border-secondary)",
            },

            scrollbar: "var(--sessions-base-scrollbar)",

            backgrounds: {
                sidebar: {
                    main: "var(--sessions-base-backgrounds-sidebar-main)",
                    miniCalendar: {
                        currentWeek: "var(--sessions-base-backgrounds-sidebar-mini-calendar-current-week)",
                        currentDay: "var(--sessions-base-backgrounds-sidebar-mini-calendar-current-day)",
                        chosenDay: "var(--sessions-base-backgrounds-sidebar-mini-calendar-chosen-day)",
                    },
                    filters: {
                        active: "var(--sessions-base-backgrounds-sidebar-filters-active)",
                        disabled: "var(--sessions-base-backgrounds-sidebar-filters-disabled)",
                        inactive: "var(--sessions-base-backgrounds-sidebar-filters-inactive)",
                        disabledIcon: "var(--sessions-base-backgrounds-sidebar-filters-disabled-icon)",
                    },
                    calendarConnections: {
                        container: "var(--sessions-base-backgrounds-sidebar-calendar-connections-container)",
                        button: "var(--sessions-base-backgrounds-sidebar-calendar-connections-button)",
                    },
                },

                dialog: {
                    main: "var(--sessions-base-backgrounds-dialog-main)",

                    calendarConnections: {
                        calendar: "var(--sessions-base-backgrounds-dialog-calendar-connections-calendar)",
                    },
                },

                calendar: {
                    main: "var(--sessions-base-backgrounds-calendar-main)",

                    currentWeekDay: {
                        main: "var(--sessions-base-backgrounds-calendar-current-week-day-main)",
                        events: "var(--sessions-base-backgrounds-calendar-current-week-day-events)",
                        slot: "var(--sessions-base-backgrounds-calendar-current-week-day-slot)",
                    },

                    weekDay: {
                        main: "var(--sessions-base-backgrounds-calendar-week-day-main)",
                        events: "var(--sessions-base-backgrounds-calendar-week-day-events)",
                    },

                    weekendDay: {
                        header: "var(--sessions-base-backgrounds-calendar-weekend-day-header)",
                        slot: "var(--sessions-base-backgrounds-calendar-weekend-day-slot)",
                    },
                },

                skeleton: {
                    sidebar: "var(--sessions-base-backgrounds-skeleton-sidebar)",
                    calendar: "var(--sessions-base-backgrounds-skeleton-calendar)",
                },
            },

            foregrounds: {
                sidebar: {
                    miniCalendar: {
                        header: "var(--sessions-base-foregrounds-sidebar-mini-calendar-header)",
                        headerDays: "var(--sessions-base-foregrounds-sidebar-mini-calendar-header-days)",

                        date: "var(--sessions-base-foregrounds-sidebar-mini-calendar-date)",
                        navigation: "var(--sessions-base-foregrounds-sidebar-mini-calendar-navigation)",

                        day: "var(--sessions-base-foregrounds-sidebar-mini-calendar-day)",
                        currentDay: "var(--sessions-base-foregrounds-sidebar-mini-calendar-current-day)",
                        chosenDay: "var(--sessions-base-foregrounds-sidebar-mini-calendar-chosen-day)",

                        otherDays: "var(--sessions-base-foregrounds-sidebar-mini-calendar-other-days)",
                    },

                    tooltip: "var(--sessions-base-foregrounds-sidebar-tooltip)",

                    filters: {
                        header: "var(--sessions-base-foregrounds-sidebar-filters-header)",
                        content: "var(--sessions-base-foregrounds-sidebar-filters-content)",
                        main: "var(--sessions-base-foregrounds-sidebar-filters-main)",
                        disabled: "var(--sessions-base-foregrounds-sidebar-filters-disabled)",
                        icon: "var(--sessions-base-foregrounds-sidebar-filters-icon)",
                        disabledIcon: "var(--sessions-base-foregrounds-sidebar-filters-disabled-icon)",
                    },

                    calendarConnections: {
                        text: "var(--sessions-base-foregrounds-sidebar-calendar-connections-text)",
                        icon: "var(--sessions-base-foregrounds-sidebar-calendar-connections-icon)",
                    },
                },

                dialog: {
                    main: "var(--sessions-base-foregrounds-dialog-main)",

                    calendarConnections: {
                        primary: "var(--sessions-base-foregrounds-dialog-calendar-connections-primary)",
                        secondary: "var(--sessions-base-foregrounds-dialog-calendar-connections-secondary)",
                        disconnect: "var(--sessions-base-foregrounds-dialog-calendar-connections-disconnect)",
                    },
                },

                calendar: {
                    main: "var(--sessions-base-foregrounds-calendar-main)",

                    weekDay: {
                        title: "var(--sessions-base-foregrounds-calendar-week-day-title)",
                        subtitle: "var(--sessions-base-foregrounds-calendar-week-day-subtitle)",
                    },

                    currentWeekDay: {
                        title: "var(--sessions-base-foregrounds-calendar-current-week-day-title)",
                        subtitle: "var(--sessions-base-foregrounds-calendar-current-week-day-subtitle)",
                    },

                    daySlot: {
                        current: "var(--sessions-base-foregrounds-calendar-day-slot-current)",
                        other: "var(--sessions-base-foregrounds-calendar-day-slot-other)",
                    },

                    timeSlotLabel: {
                        main: "var(--sessions-base-foregrounds-calendar-time-slot-label-main)",
                        meridian: "var(--sessions-base-foregrounds-calendar-time-slot-label-meridian)",
                    },

                    timeSlotLabelPast: {
                        main: "var(--sessions-base-foregrounds-calendar-time-slot-label-past-main)",
                        meridian: "var(--sessions-base-foregrounds-calendar-time-slot-label-past-meridian)",
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#1d88f4",
        },
        secondary: {
            main: "#2ECC71",
        },
        pink: {
            main: "#e91e63",
        },
        error: {
            main: "#FB344F",
        },
    },

    heights: {
        appBarSm: 58,
        appBar: 58,
        appBarDesktop: 76,
        header: 68,
    },
    widths: {
        drawerLeft: 240,
        drawerLeftClosed: 0,
        drawerLeftClosedDesktop: 80,
        drawerLeftOpenDesktop: 330,
        drawerRight: 485,
        drawerRightClosed: 0,
    },
    margins: {
        left: drawerMarginLeft,
    },
    color: {},
    shadows: [
        "none",
        "0px 3px 4px rgba(29, 136, 244, 0.1)",
        "0px 3px 4px rgba(36, 42, 55, 0.1)",
        "0px -6px 20px rgba(29, 136, 244, 0.15), 0px 3px 4px rgba(29, 136, 244, 0.1)",
        "0px -6px 20px rgba(36, 42, 55, 0.15), 0px 3px 4px rgba(36, 42, 55, 0.1)",

        // EITHER DEFINE ALL THE SHADOWS
        // or NONE
        // ask... or read the docs
        "0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 5px 8px 0px rgba(0, 0, 0, 0.042), 0px 1px 14px 0px rgba(0, 0, 0, 0.036)",
        "0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036)",
        "0px 4px 5px -2px rgba(0, 0, 0, 0.06), 0px 7px 10px 1px rgba(0, 0, 0, 0.042), 0px 2px 16px 1px rgba(0, 0, 0, 0.036)",
        "0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042), 0px 3px 14px 2px rgba(0, 0, 0, 0.036)",
        "0px 5px 6px -3px rgba(0, 0, 0, 0.06), 0px 9px 12px 1px rgba(0, 0, 0, 0.042), 0px 3px 16px 2px rgba(0, 0, 0, 0.036)",
        "0px 6px 6px -3px rgba(0, 0, 0, 0.06), 0px 10px 14px 1px rgba(0, 0, 0, 0.042), 0px 4px 18px 3px rgba(0, 0, 0, 0.036)",
        "0px 6px 7px -4px rgba(0, 0, 0, 0.06), 0px 11px 15px 1px rgba(0, 0, 0, 0.042), 0px 4px 20px 3px rgba(0, 0, 0, 0.036)",
        "0px 7px 8px -4px rgba(0, 0, 0, 0.06), 0px 12px 17px 2px rgba(0, 0, 0, 0.042), 0px 5px 22px 4px rgba(0, 0, 0, 0.036)",
        "0px 7px 8px -4px rgba(0, 0, 0, 0.06), 0px 13px 19px 2px rgba(0, 0, 0, 0.042), 0px 5px 24px 4px rgba(0, 0, 0, 0.036)",
        "0px 7px 9px -4px rgba(0, 0, 0, 0.06), 0px 14px 21px 2px rgba(0, 0, 0, 0.042), 0px 5px 26px 4px rgba(0, 0, 0, 0.036)",
        "0px 8px 9px -5px rgba(0, 0, 0, 0.06), 0px 15px 22px 2px rgba(0, 0, 0, 0.042), 0px 6px 28px 5px rgba(0, 0, 0, 0.036)",
        "0px 8px 10px -5px rgba(0, 0, 0, 0.06), 0px 16px 24px 2px rgba(0, 0, 0, 0.042), 0px 6px 30px 5px rgba(0, 0, 0, 0.036)",
        "0px 8px 11px -5px rgba(0, 0, 0, 0.06), 0px 17px 26px 2px rgba(0, 0, 0, 0.042), 0px 6px 32px 5px rgba(0, 0, 0, 0.036)",
        "0px 9px 11px -5px rgba(0, 0, 0, 0.06), 0px 18px 28px 2px rgba(0, 0, 0, 0.042), 0px 7px 34px 6px rgba(0, 0, 0, 0.036)",
        "0px 9px 12px -6px rgba(0, 0, 0, 0.06), 0px 19px 29px 2px rgba(0, 0, 0, 0.042), 0px 7px 36px 6px rgba(0, 0, 0, 0.036)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.06), 0px 20px 31px 3px rgba(0, 0, 0, 0.042), 0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.06), 0px 20px 31px 3px rgba(0, 0, 0, 0.042), 0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.06), 0px 20px 31px 3px rgba(0, 0, 0, 0.042), 0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.06), 0px 20px 31px 3px rgba(0, 0, 0, 0.042), 0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.06), 0px 20px 31px 3px rgba(0, 0, 0, 0.042), 0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
    ],
    /**
     * @deprecated Please use `@ui/cdk/Button` instead.
     */
    designer: {
        bgSubmitBtn: "linear-gradient(99.87deg, #56ADF9 1.89%, #1D88F4 96%)",
        bgCancelBtn: "linear-gradient(99.87deg, #FFFFFF 1.89%, #F8FAFC 96%)",
        boxShadowSubmitBtn:
            "1px 1px 2px rgba(0, 0, 0, 0.5), 2px 4px 10px rgba(36, 42, 55, 0.12), -5px -5px 10px rgba(255, 255, 255, 0.05), inset 2px 3px 3px rgba(255, 255, 255, 0.2)",
        boxShadowCancelBtn:
            "1px 1px 2px rgba(0, 0, 0, 0.25), 2px 4px 10px rgba(36, 42, 55, 0.1), -5px -5px 10px rgba(255, 255, 255, 0.1), inset 2px 2px 3px rgba(255, 255, 255, 0.4)",
        bgSessionBtn: "linear-gradient(99.87deg, #1D88F4 1.89%, #48A3FF 96%)",
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
};

export default mainTheme;
