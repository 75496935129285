import {ResourcesTypes} from "@generated/artifacts/resources";
import {openExternalLink} from "@workhorse/util/links";
import ExternalGenericCreateForm from "./components/ExternalGenericCreateForm";

type FigmaCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
};

const FigmaCreateForm = (props: FigmaCreateFormProps) => {
    const title = "Export from Figma";
    const description = "Use our plugin to directly import your designs to Sessions.";
    const primaryActionLabel = "Get plugin";

    const primaryAction = () => {
        openExternalLink("https://www.figma.com/community/plugin/1011512061726401104/Sessions");
    };

    return (
        <>
            <ExternalGenericCreateForm
                {...props}
                title={title}
                description={description}
                primaryActionLabel={primaryActionLabel}
                primaryAction={primaryAction}
            />
        </>
    );
};
export default FigmaCreateForm;
