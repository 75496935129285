import clientEvents, {useClientEvent} from "@api/events/client";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {memo, useState} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {OnboardingLonelinessType} from "@workhorse/components/onboarding-tooltips/utils";
import {WithClassName} from "@workhorse/declarations";
import {createLonelinessOnboardingAgenda} from "@workhorse/pages/user/onboarding/api/session-loneliness-onboarding-agenda";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";
import classes from "./styles/LonelySpeakerOnboardingV2.module.scss";

export function getCompletedOnboardingCards(sessionId: string): OnboardingLonelinessType[] {
    try {
        const savedComplatedCards = sessionStorage.getItem(`user-onboarding-loneliness-${sessionId}`);

        if (!savedComplatedCards) {
            return [];
        }

        const parsedSavedComplatedCards = JSON.parse(savedComplatedCards) as OnboardingLonelinessType[];

        return parsedSavedComplatedCards;
    } catch (err) {
        return [];
    }
}

export const completeOnboardingCard = (
    sessionId: string,
    type: OnboardingLonelinessType,
    onComplete?: (type: OnboardingLonelinessType) => void
) => {
    const savedComplatedCards = getCompletedOnboardingCards(sessionId);

    if (!savedComplatedCards.includes(type)) {
        sessionStorage.setItem(`user-onboarding-loneliness-${sessionId}`, JSON.stringify([...savedComplatedCards, type]));
        clientEvents.emit("user-onboarding-loneliness-completed", type);
        onComplete?.(type);
    }
};

type CardType = {
    title: string;
    subtitle: string;
    type: OnboardingLonelinessType;
    actionText: string;
};

const cardsList: CardType[] = [
    {
        title: "player.onboarding.loneliness.speaker.cards.agenda.title",
        subtitle: "player.onboarding.loneliness.speaker.cards.agenda.description",
        type: "agenda",
        actionText: "g.add",
    },
    {
        title: "player.onboarding.loneliness.speaker.cards.participants.title",
        subtitle: "player.onboarding.loneliness.speaker.cards.participants.description",
        type: "participants",
        actionText: "g.invite",
    },
    {
        title: "player.onboarding.loneliness.speaker.cards.transcript.title",
        subtitle: "player.onboarding.loneliness.speaker.cards.transcript.description",
        type: "transcript",
        actionText: "g.start",
    },
];

export type LonelySpeakerProps = WithClassName & {
    sessionId: string;
};

const LonelySpeakerOnboardingV2 = memo(
    (props: LonelySpeakerProps) => {
        const {sessionId, className} = props;

        const {mixpanelTrack} = useMixpanelTracker();

        const user = useUserInfo();

        const {setActiveComponent} = useDrawerRightActiveComponent("participants");

        const [drawerRightState, toggleDrawerRight] = useDrawerRightToggler();

        const {t} = useTranslation();

        const [completedCards, setCompletedCards] = useState<OnboardingLonelinessType[]>(() => getCompletedOnboardingCards(sessionId));

        const handleMarkAsSeen = () => {
            markAsSeen({
                GLOBALS: {
                    useOnboardingLoneliness: true,
                },
            });
        };

        const handleCompleteCard = (type: OnboardingLonelinessType) => {
            setCompletedCards((prev) => {
                const values = prev.includes(type) ? prev : [...prev, type];

                const hasCompletedAllCards = cardsList.every((c) => values.includes(c.type));

                if (hasCompletedAllCards) {
                    handleMarkAsSeen();
                }

                return values;
            });
        };

        const handleCardClick = async (type: OnboardingLonelinessType) => {
            if (["participants", "transcript"].includes(type)) {
                setActiveComponent(type === "transcript" ? "flowos/transcript" : "participants");

                if (drawerRightState.isClosed) {
                    toggleDrawerRight();
                }
            }

            if (type === "agenda") {
                await createLonelinessOnboardingAgenda(user.id);
                mixpanelTrack("frontend-onboarding-lonely-completed", type, "player");
            }

            const savedComplatedCards = getCompletedOnboardingCards(sessionId);

            if (!savedComplatedCards.includes(type)) {
                sessionStorage.setItem(`user-onboarding-loneliness-${sessionId ?? ""}`, JSON.stringify([...savedComplatedCards, type]));
                handleCompleteCard(type);
            }

            setTimeout(() => {
                clientEvents.emit("user-onboarding-loneliness", type);
            }, 300);
        };

        useClientEvent("user-onboarding-loneliness-completed", (type: OnboardingLonelinessType) => {
            handleCompleteCard(type);
        });

        const firstUncompletedCard = cardsList.findIndex((c) => !completedCards.includes(c.type));

        return (
            <div className={className}>
                <div className={classes.root}>
                    <div className="flex flex-col pr-20">
                        <Typography variant="lg" fontWeight="bolder">
                            {t("player.onboarding.loneliness.speaker.title")} 🚀
                        </Typography>
                        <Typography variant="base" color="secondary" fontWeight="bold">
                            {t("player.onboarding.loneliness.speaker.subtitle")}
                        </Typography>
                    </div>
                    <div className={classes.cardsContainer}>
                        {cardsList.map((c, index) => {
                            const isCardCompleted = completedCards.includes(c.type);

                            return (
                                <div
                                    key={`lonely-onboarding-v2-card-${c.type}`}
                                    className={cls(classes.card, isCardCompleted && classes.cardCompleted)}
                                >
                                    <div className={classes.cardIcon}>
                                        {isCardCompleted ? (
                                            <DoneRoundedIcon />
                                        ) : (
                                            <Typography variant="sm" fontWeight="bolder">
                                                {index + 1}
                                            </Typography>
                                        )}
                                    </div>

                                    <div className="flex flex-col mr-8">
                                        <Typography color="secondary" fontWeight="bolder">
                                            {t(c.title)}
                                        </Typography>
                                        <Typography className={classes.cardSubtitle}>{t(c.subtitle)}</Typography>
                                    </div>

                                    <Button
                                        onClick={() => handleCardClick(c.type)}
                                        variant={firstUncompletedCard === index ? "primary" : "plain"}
                                        className={cls(classes.cardButton, firstUncompletedCard !== index ? classes.cardButtonOnHover : "")}
                                        size="small"
                                    >
                                        {t(c.actionText)}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>

                    <IconButton className={classes.closeButton} onClick={handleMarkAsSeen}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
            </div>
        );
    },
    (pv, cv) => pv.className === cv.className
);

export default LonelySpeakerOnboardingV2;
