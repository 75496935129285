import {CurrentHostDocument, HostType, LocalUserDocument} from "@generated/data";
import {PlayerArtifactDescription} from "@workhorse/api/expressionContext";
import apollo from "../api/apollo";

export interface isCurrentHost {
    isHome: boolean;
    isBase: boolean;
    isStudio: boolean;
    isPlayer: boolean;
    isDesigner: boolean;
    isBuildup: boolean;
    isMemory: boolean;
    isConnect: boolean;
}

export interface IExpressionContext {
    host: isCurrentHost;
    user: {
        isLoggedIn: boolean;
        role: string;
        hasClaim: Function;
    };
}

export interface IArtifactEvaluation {
    context: IExpressionContext;
    currentArtifact: PlayerArtifactDescription;
    artifacts: PlayerArtifactDescription[];
}

// TODO: should this be a singleton or a static class ?
const ExpressionContext = (hostType: HostType) => {
    const currentHost = hostType;
    const currentUser = apollo.cache.readQuery({
        query: LocalUserDocument,
    });

    const localUser = currentUser?.localUser;
    const isLoggedIn = currentUser ? currentUser.isAuthenticated : false;

    // TODO: Adapt this for more usescases. At the moment only checks user claims
    function hasClaim(claim: string) {
        if (!claim) return true;
        if (claim && !localUser) return false;
        const claimData = claim.split(":");
        const claimKey = claimData[0];
        const claimValue = claimData[1];

        if (localUser && localUser[claimKey] === claimValue) {
            return true;
        }

        return false;
    }

    const host = {
        isHome: currentHost == HostType.Home,
        isBase: currentHost == HostType.Base,
        isStudio: currentHost == HostType.Studio,
        isPlayer: currentHost == HostType.Player,
        isDesigner: currentHost == HostType.Designer,
        isBuildup: currentHost == HostType.Buildup,
        isMemory: currentHost == HostType.Memory,
        isConnect: currentHost == HostType.Connect,
        isDisconnect: currentHost === HostType.Disconnect,
    };

    const contextData: IExpressionContext = {
        user: {
            isLoggedIn,
            ...localUser,
            hasClaim,
        } as IExpressionContext["user"],
        host,
    };

    return contextData;
};

export default ExpressionContext;
