import {useCallback, useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {LocalAudioTrack, LocalVideoTrack, Room, ScreenSharePresets, Track, VideoPreset, VideoPresets} from "livekit-client";
import {useRoomShareIdentity} from "./ConferenceRoomHooks";
import {useLocalTrackManager} from "./ConferenceRoomLocalTrack";
import {createRoomLogger} from "./ConferenceRoomLogger";
import {useConferenceRoom} from "./ConferenceRoomProvider";
import {DisplaySource, isDesktopApp} from "@sessions/desktop-api";
import {SourceSelectorDialog, useSelectScreenShareSource} from "../ScreenshareProvider/DesktopScreenShareSource";
import {WithChildren} from "@workhorse/declarations";
import browserInfo from "@workhorse/api/BrowserInfo";
import {browserBehavior} from "../../utils";

const isChromium = browserInfo.isChromium();

function getBrowserShare() {
    return navigator.mediaDevices.getDisplayMedia({
        //@ts-expect-error `selfBrowserSurface` it's a new option to exclude current tab from sharing that our ts version does not recognize
        selfBrowserSurface: "exclude",
        audio: {
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
            channelCount: {ideal: 2},
        },
        ...(isChromium
            ? {
                  video: {
                      width: {ideal: 1920},
                      height: {ideal: 1080},
                      frameRate: {ideal: 15},
                  },
              }
            : null),
    });
}

function getDesktopSourceShare(source: DisplaySource) {
    return navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
            //@ts-expect-error missing type
            mandatory: {
                chromeMediaSource: "desktop",
                chromeMediaSourceId: source.id,
            },
        },
    });
}

const logger = createRoomLogger("SCREEN-SHARE-PUBLISHER");

function getMediaStreamVideoTrack(stream: MediaStream): MediaStreamTrack | null {
    return stream.getVideoTracks()[0] ?? null;
}

function getMediaStreamAudioTrack(stream: MediaStream): MediaStreamTrack | null {
    return stream.getAudioTracks()[0] ?? null;
}

function createLocalShareScreenVideoTrack(stream: MediaStream) {
    const videoTrack = getMediaStreamVideoTrack(stream);

    if (!videoTrack) {
        return null;
    }

    const track = new LocalVideoTrack(
        videoTrack,
        {
            deviceId: videoTrack.getSettings()?.deviceId,
        },
        true
    );

    track.mediaStream = stream;
    track.source = Track.Source.ScreenShare;

    return track;
}

function createLocalShareScreenAudioTrack(stream: MediaStream) {
    const audioTrack = getMediaStreamAudioTrack(stream);

    if (!audioTrack) {
        return null;
    }

    const track = new LocalAudioTrack(
        audioTrack,
        {
            deviceId: audioTrack.getSettings().deviceId,
        },
        true
    );

    track.mediaStream = stream;
    track.source = Track.Source.ScreenShareAudio;

    return track;
}

function publishVideoToRoom(room: Room, stream: MediaStream, track: LocalVideoTrack) {
    const enableSimulcast = browserBehavior.supportsSimulcast();

    return room.localParticipant.publishTrack(track, {
        source: Track.Source.ScreenShare,
        simulcast: enableSimulcast,
        videoCodec: "vp8",
        backupCodec: false,
        stream: `share-${room.localParticipant.identity}`,
        videoEncoding: enableSimulcast ? VideoPresets.h1080.encoding : VideoPresets.h720.encoding,
        videoSimulcastLayers: enableSimulcast ? [VideoPresets.h540] : undefined,
    });
}

function publishAudioToRoom(room: Room, stream: MediaStream, track: LocalAudioTrack) {
    return room.localParticipant.publishTrack(track, {
        source: Track.Source.ScreenShareAudio,
        stream: `share-${room.localParticipant.identity}`,
    });
}

function useShareScreenPublisherStore({selectSource}: {selectSource: () => Promise<DisplaySource>}) {
    const {room} = useConferenceRoom();
    const {identity: sharingParticipantId, isLocalSharing: isLocalUserSharing} = useRoomShareIdentity();

    const [shareMask, setShareMask] = useState<boolean>(false);
    const [shareDisabled, setShareDisabled] = useState(false);
    const [stream, setStream] = useState<MediaStream>();

    const {
        resetRef: resetVideoRef,
        unpublishTrack: unpublishShareScreenVideo,
        publishOrReplaceTrack: publishOrReplaceShareScreenVideo,
    } = useLocalTrackManager(
        room,
        Track.Source.ScreenShare,
        logger,
        publishVideoToRoom,
        createLocalShareScreenVideoTrack,
        getMediaStreamVideoTrack
    );

    const {
        resetRef: resetAudioRef,
        unpublishTrack: unpublishShareScreenAudio,
        publishOrReplaceTrack: publishOrReplaceShareScreenAudio,
    } = useLocalTrackManager(
        room,
        Track.Source.ScreenShareAudio,
        logger,
        publishAudioToRoom,
        createLocalShareScreenAudioTrack,
        getMediaStreamAudioTrack
    );

    const createMediaStream = useCallback(async () => {
        if (!isDesktopApp()) {
            return getBrowserShare();
        }

        const source = await selectSource();
        return getDesktopSourceShare(source);
    }, [selectSource]);

    const replaceStream = useCallback((stream?: MediaStream) => {
        setStream((current) => {
            if (!current) {
                return stream;
            }

            for (const track of current.getTracks()) {
                track.onended = null;
                track.stop();
            }

            return stream;
        });
    }, []);

    const stop = useCallback(() => {
        resetVideoRef();
        resetAudioRef();
        replaceStream();
    }, [resetVideoRef, resetAudioRef, replaceStream]);

    const handleStreamEnded = useCallback(() => {
        resetVideoRef();
        resetAudioRef();
        replaceStream();
        return Promise.all([unpublishShareScreenVideo(), unpublishShareScreenAudio()]);
    }, [unpublishShareScreenVideo, unpublishShareScreenAudio, resetVideoRef, resetAudioRef, replaceStream]);

    const unpublishShareScreen = useCallback(async () => {
        try {
            logger.info("Attempting to stop share screen");

            await unpublishShareScreenVideo();
            await unpublishShareScreenAudio();
        } catch (e) {}

        replaceStream();
    }, [room, replaceStream, unpublishShareScreenVideo, unpublishShareScreenAudio]);

    const startShareScreen = useCallback(async () => {
        let stream: MediaStream | undefined;

        try {
            if (shareDisabled) {
                logger.info("Share screen disabled, not starting");
                return;
            }

            stream = await createMediaStream();

            if (!stream) {
                return;
            }

            for (const track of stream.getTracks()) {
                track.onended = handleStreamEnded;
            }

            const isMaskNeeded = stream.getVideoTracks().some((track) => {
                if (track.label.includes("web-contents-media")) {
                    return false;
                }
                return true;
            });

            setShareMask(isMaskNeeded);
        } catch (e) {
            logger.error("Failed to start share screen", e);
            throw e;
        } finally {
            replaceStream(stream);
        }
    }, [createMediaStream, handleStreamEnded, replaceStream, shareDisabled]);

    const publishShareScreen = useCallback(
        async (stream: MediaStream) => {
            try {
                await publishOrReplaceShareScreenVideo(stream);
                await publishOrReplaceShareScreenAudio(stream);
            } catch (e) {
                logger.error("Failed to publish share screen", e);
            }
        },
        [startShareScreen]
    );

    const removeMask = useCallback(() => setShareMask(false), []);

    return useMemo(() => {
        return {
            sharingParticipantId,
            isLocalUserSharing: isLocalUserSharing || !!stream,
            shareMask,
            shareDisabled,
            stream,
            stop,
            setShareDisabled,
            startShareScreen,
            publishShareScreen,
            stopShareScreen: unpublishShareScreen,
            removeMask,
        };
    }, [
        stop,
        startShareScreen,
        unpublishShareScreen,
        publishShareScreen,
        shareMask,
        sharingParticipantId,
        shareDisabled,
        isLocalUserSharing,
        stream,
    ]);
}

export const [ConferenceRoomShareScreenBaseProvider, useScreenShareOptions] = createContextProvider(
    {
        name: "ConferenceRoomShareScreenProvider",
    },
    useShareScreenPublisherStore
);

export function ConferenceRoomShareScreenProvider(props: WithChildren) {
    const {dialogProps, selectSource} = useSelectScreenShareSource();

    return (
        <>
            {dialogProps && <SourceSelectorDialog {...dialogProps} />}
            <ConferenceRoomShareScreenBaseProvider selectSource={selectSource}>
                {useMemo(() => props.children, [props.children])}
            </ConferenceRoomShareScreenBaseProvider>
        </>
    );
}
