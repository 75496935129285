import classes from "./style/EventsLandingPageEmbed.module.scss";
import {cls} from "@ui/cdk/util/util";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {useState} from "@workhorse/api/rendering";
import designer from "@workhorse/api/designer";
import {useMutation} from "@workhorse/dataApi";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@ui/cdk/Tooltip";
import commonClasses from "../eventCommons.module.scss";
import {isValidUrl} from "@common/validation/utils";

type EventsLandingPageEmbedProps = {
    readOnly: boolean;
};

const EventsLandingPageEmbed = (props: EventsLandingPageEmbedProps) => {
    const {id, event} = useSession();

    const [lockEvent] = useMutation("LockEventDocument");

    const [inputValue, setInputValue] = useState<string>(event?.embedUrl ?? "");
    const [errorMessage, setErrorMessage] = useState<string>("");

    function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setInputValue(e.target.value);
    }

    function handleOnBlur(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const isEmpty = e.target.value === "";
        if (event?.embedUrl === e.target.value) return;

        if (isValidUrl(e.target.value) || isEmpty) {
            setErrorMessage("");
        }

        const url = isEmpty
            ? ""
            : e.target.value.startsWith("http://") || e.target.value.startsWith("https://")
            ? e.target.value
            : `https://${e.target.value}`;

        if (isValidUrl(url) || isEmpty) {
            designer.api.event.update({
                embedUrl: url,
            });
            designer.commit();
        } else {
            lockEvent({variables: {id}});
            setErrorMessage("Please enter a valid URL");
        }
    }

    return (
        <div className={classes.configuration}>
            <div className={cls("mt-40 flex flex-justify-between", classes.title)}>
                <div className="flex flex-row flex-align-center gap-6">
                    <Typography component="div" color="tertiary" variant="lg">
                        Embed Link
                    </Typography>
                    <Tooltip
                        placement="right"
                        arrow
                        title="Add a link that will be embedded into your event page. It could be any web page or a YouTube video."
                    >
                        <InfoIcon className={commonClasses.infoIcon} />
                    </Tooltip>
                </div>
            </div>

            <Input
                name="event-page-embed-link-input"
                id="event-page-embed-link-input"
                type="text"
                disabled={props.readOnly}
                className={cls(classes.socialLinkInput)}
                autoFocus={false}
                value={inputValue}
                formControlClassName={classes.socialLinkInputWrapper}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                inputProps={{
                    "data-private": "lipsum",
                }}
            />

            {errorMessage.length > 0 && (
                <Typography variant="sm" color="red400">
                    {errorMessage}
                </Typography>
            )}
        </div>
    );
};

export default EventsLandingPageEmbed;
