import {useReactiveVar} from "@workhorse/api/data";
import loadable from "@workhorse/api/loadable";
import {useMemo} from "@workhorse/api/rendering";
import {WithHostType} from "@workhorse/declarations";
import {releaseNotesDialogEnabled} from "./releaseNotesApi";

const ReleaseNotesDialog = loadable(() => import("../../components/releaseNotes/ReleaseNotesDialog"));

function ReleaseNotesLoader(props: WithHostType) {
    const IsOpen = useReactiveVar(releaseNotesDialogEnabled);
    /**
     * @TODO
     * determine if we should add a everLoaded param to keep the dialog mounted after first load
     */
    return useMemo(() => {
        return IsOpen ? <ReleaseNotesDialog hostType={props.hostType} /> : null;
    }, [IsOpen]) as JSX.Element;
}

export default ReleaseNotesLoader;
