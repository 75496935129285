import TogglerContainer from "@ui/cdk/TogglerContainer";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import classes from "../../../../styles/GenericSection.module.scss";
import LoyaltyRoundedIcon from "@material-ui/icons/LoyaltyRounded";

import {useTranslation} from "react-i18next";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";

type MacroPollsTogglerProps = {
    enabled: boolean;
    onChange: (macroArtifactId: string) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const WidgetOffersToggler = (props: MacroPollsTogglerProps) => {
    const {enabled, onChange, showIcon, disabled, className} = props;
    const {featureIsAvailable, emitFeatureLimitError} = useFeatureInfo({
        feature: LimitedFeature.OFFER_WIDGET,
    });
    const {t} = useTranslation();

    const handleOnChange = () => {
        if (!featureIsAvailable) {
            emitFeatureLimitError(t("limitations.notifications.please_upgrade_plan") ?? "");
            return;
        }
        onChange("offers");
    };

    return (
        <div className={cls(classes.togglerWrapper, className)}>
            {showIcon ? (
                <div className={classes.iconWrapper}>
                    <LoyaltyRoundedIcon />
                </div>
            ) : null}
            <TogglerContainer
                title={
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("macro.offers.title")}
                    </Typography>
                }
                secondTitle={
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.widgets.offers.description")}
                    </Typography>
                }
                checked={enabled}
                name="pollWidget"
                onChange={handleOnChange}
                togglePlacement="secondTitle"
                label=" "
                className={cls(classes.toggler)}
                disabled={disabled}
            />
        </div>
    );
};

export default WidgetOffersToggler;
