import Button from "@ui/cdk/Button";
import {Session} from "@workhorse/declarations/dataTypes";
import LobbyCoverImage from "./LobbyCoverImage";
import classes from "./styles/LobbyCoverImage.module.scss";
import ImageIcon from "@material-ui/icons/Image";
import {forwardRef} from "@workhorse/api/rendering";

import {cls} from "@ui/cdk/util";
import {useTranslation} from "react-i18next";

type LobbyCoverImageContentProps = {
    session: Session;
    handleCoverImageLoaded: () => void;
    isCoverImageUploaded: boolean;
    yPosition: number;
    handleToggleCoverMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isMobile: boolean;
    canChangeCover: boolean;
    drawerLeftOpen: boolean;
    drawerRightOpen: boolean;
    drawerLeftWidth: number;
    drawerRightWidth: number;
};

const LobbyCoverImageContent = forwardRef((props: LobbyCoverImageContentProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
    const {t} = useTranslation();

    const {
        session,
        handleCoverImageLoaded,
        isCoverImageUploaded,
        yPosition,
        handleToggleCoverMenu,
        isMobile,
        drawerLeftOpen,
        drawerLeftWidth,
        drawerRightOpen,
        drawerRightWidth,
    } = props;

    return (
        <div>
            {session.lobbyPicture?.originalPictureUrl ? (
                <LobbyCoverImage onImageLoad={handleCoverImageLoaded} url={session.lobbyPicture.originalPictureUrl} y={yPosition} />
            ) : null}
            {props.canChangeCover && !isMobile ? (
                <div
                    className={cls(classes.coverImageButtonContainer, "centered")}
                    style={{
                        ...(drawerLeftOpen ? {left: `calc(50% + ${(drawerLeftWidth + 26) / 2}px)`} : null),
                        ...(drawerRightOpen ? {left: `calc(50% - ${(drawerRightWidth + 26) / 2}px`} : null),
                    }}
                >
                    <Button ref={ref} variant="plain" onClick={handleToggleCoverMenu}>
                        <ImageIcon />
                        {t("lobby.cover_image.change_cover")}
                    </Button>
                </div>
            ) : null}
        </div>
    );
});

export default LobbyCoverImageContent;
