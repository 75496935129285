import React, {forwardRef} from "@workhorse/api/rendering";
import {ExtendComponent} from "@workhorse/declarations";
import {Field} from "formik";

type FieldWrapperComponents = React.ComponentType<unknown>;

type FieldWrapperProps<T extends FieldWrapperComponents> = {
    as?: T;
    validate?: any;
    name: string;
} & React.ComponentProps<T>;

function FieldWrapperWithRef<T extends FieldWrapperComponents>(props: FieldWrapperProps<T>, ref: any) {
    const {as, validate, name, ...other} = props;
    return <Field as={as} validate={validate} name={name} {...other} ref={ref} />;
}

const FieldWrapper = forwardRef(FieldWrapperWithRef) as <T extends FieldWrapperComponents>(
    props: FieldWrapperProps<T> & {ref?: any}
) => ReturnType<typeof FieldWrapperWithRef>;

export default FieldWrapper;
