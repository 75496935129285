import {SessionCreateInput} from "../../generated/data-types";
import {ArrayItem, OmitTypenames, Session} from "../types";
import {AgendaItemArtifact} from "./buildAgendaItemsUpdatePayload";

type InputAgendaItemForCreate = OmitTypenames<NonNullable<ArrayItem<Session["agendaItems"]>>>;
type CreateAgendaItemInput = NonNullable<ArrayItem<NonNullable<SessionCreateInput["agendaItems"]>["create"]>>;
type CreateAgendaItemArtifactInput = NonNullable<NonNullable<CreateAgendaItemInput["artifact"]>["create"]>;
type CreateArtifactPropertiesInput = NonNullable<NonNullable<CreateAgendaItemArtifactInput>["properties"]>;
type CreateArtifactResourceResultsInput = NonNullable<NonNullable<CreateAgendaItemArtifactInput>["resourceResults"]>;

export function buildAgendaItemCreatePayload(agendaItem: InputAgendaItemForCreate): CreateAgendaItemInput {
    const {
        id,
        type,
        order,
        descriptionJson,
        title,
        userConfiguredDuration,
        duration,
        artifact,
        locked,
        agendaItemSpeakers,
        processing,
        speakerNotes,
        speakerNotesJson,
    } = agendaItem;
    return {
        id,
        type,
        order,
        descriptionJson: descriptionJson
            ? Array.isArray(descriptionJson)
                ? Object.assign({}, descriptionJson)
                : descriptionJson
            : descriptionJson ?? {},
        title,
        userConfiguredDuration,
        processing,
        duration,
        locked,
        speakerNotes,
        speakerNotesJson: speakerNotesJson
            ? Array.isArray(speakerNotesJson)
                ? Object.assign({}, speakerNotesJson)
                : speakerNotesJson
            : speakerNotesJson ?? {},
        agendaItemSpeakers: agendaItemSpeakers?.length
            ? {
                  create:
                      agendaItemSpeakers.map((s) => ({
                          id: s.id,
                          speaker: {
                              connect: {
                                  id: s.speakerId,
                              },
                          },
                      })) ?? [],
              }
            : undefined,
        artifact: artifact
            ? {
                  create: buildAgendaItemArtifactCreatePayload(artifact),
              }
            : undefined,
    };
}

export function buildAgendaItemArtifactCreatePayload(artifact: NonNullable<AgendaItemArtifact>): CreateAgendaItemArtifactInput {
    const {id, artifactId, description, isConfigured, userConfiguredDuration, durationInSeconds, name} = artifact;
    return {
        id,
        artifactId,
        description,
        isConfigured,
        userConfiguredDuration,
        durationInSeconds,
        name,
        deniedSpeakerUserIds: (artifact as any).deniedSpeakerUserIds ? {set: (artifact as any).deniedSpeakerUserIds} : undefined,
        properties: artifact?.properties?.length ? buildArtifactProperties(artifact.properties) : undefined,
        resourceResults: artifact.resourceResults?.[0]?.id ? buildArtifactResourceResults(artifact.resourceResults) : undefined,
    };
}

function buildArtifactResourceResults(input: NonNullable<AgendaItemArtifact>["resourceResults"]): CreateArtifactResourceResultsInput {
    let deletedItems = input.filter((obj) => obj?.id && obj.isDeleted).map((obj) => obj.id);
    const replacedItems = input
        .filter((obj) => obj && !obj.isDeleted && obj.id && obj.oldId && !(obj.id === obj.oldId))
        .map((obj) => obj.oldId!);
    const newOrUpdatedItems = input.filter((obj) => obj?.id && deletedItems.indexOf(obj.id) === -1);
    const newItems = newOrUpdatedItems.filter((obj) => obj?.id && !obj.createdAt);
    // this relation doesn't support delete or update
    // const updateItems = newOrUpdatedItems.filter((obj) => obj?.id && !!obj.createdAt);
    // deletedItems = deletedItems.concat(replacedItems);
    return {
        ...(newItems.length
            ? {
                  connect: newItems.map((obj) => {
                      return {
                          id: obj.id,
                      };
                  }),
              }
            : null),
    };
}

function buildArtifactProperties(input: NonNullable<AgendaItemArtifact>["properties"]): CreateArtifactPropertiesInput {
    let deletedItems = input.filter((obj) => obj?.id && obj.isDeleted).map((obj) => obj.id);
    const replacedItems = input
        .filter((obj) => obj && !obj.isDeleted && obj.id && obj.oldId && !(obj.id === obj.oldId))
        .map((obj) => obj.oldId!);
    const newOrUpdatedItems = input.filter((obj) => obj?.id && deletedItems.indexOf(obj.id) === -1);
    const newItems = newOrUpdatedItems.filter((obj) => obj?.id && !obj.createdAt);
    // this relation doesn't support delete or update
    // const updateItems = newOrUpdatedItems.filter((obj) => obj?.id && !!obj.createdAt);
    // deletedItems = deletedItems.concat(replacedItems);
    return {
        ...(newItems.length
            ? {
                  create: newItems.map((obj) => {
                      return {
                          id: obj.id,
                          key: obj.key,
                          value: obj.value,
                      };
                  }),
              }
            : null),
    };
}
