import {LiveDocSourceType} from "../config/LiveDocLinkForm";

export interface Schema$FileList {
    /**
     * The list of files. If nextPageToken is populated, then this list may be incomplete and an additional page of results should be fetched.
     */
    files?: Schema$File[];
    /**
     * Whether the search process was incomplete. If true, then some search results may be missing, since all documents were not searched. This may occur when searching multiple drives with the "allDrives" corpora, but all corpora could not be searched. When this happens, it is suggested that clients narrow their query by choosing a different corpus such as "user" or "drive".
     */
    incompleteSearch?: boolean | null;
    /**
     * Identifies what kind of resource this is. Value: the fixed string "drive#fileList".
     */
    kind?: string | null;
    /**
     * The page token for the next page of files. This will be absent if the end of the files list has been reached. If the token is rejected for any reason, it should be discarded, and pagination should be restarted from the first page of results.
     */
    nextPageToken?: string | null;
}

export interface Schema$File {
    /**
     * A collection of arbitrary key-value pairs which are private to the requesting app.
     * Entries with null values are cleared in update and copy requests. These properties can only be retrieved using an authenticated request. An authenticated request uses an access token obtained with a OAuth 2 client ID. You cannot use an API key to retrieve private properties.
     */
    appProperties?: {
        [key: string]: string;
    } | null;
    /**
     * Capabilities the current user has on this file. Each capability corresponds to a fine-grained action that a user may take.
     */
    capabilities?: {
        canAddChildren?: boolean;
        canAddFolderFromAnotherDrive?: boolean;
        canAddMyDriveParent?: boolean;
        canChangeCopyRequiresWriterPermission?: boolean;
        canChangeViewersCanCopyContent?: boolean;
        canComment?: boolean;
        canCopy?: boolean;
        canDelete?: boolean;
        canDeleteChildren?: boolean;
        canDownload?: boolean;
        canEdit?: boolean;
        canListChildren?: boolean;
        canModifyContent?: boolean;
        canModifyContentRestriction?: boolean;
        canMoveChildrenOutOfDrive?: boolean;
        canMoveChildrenOutOfTeamDrive?: boolean;
        canMoveChildrenWithinDrive?: boolean;
        canMoveChildrenWithinTeamDrive?: boolean;
        canMoveItemIntoTeamDrive?: boolean;
        canMoveItemOutOfDrive?: boolean;
        canMoveItemOutOfTeamDrive?: boolean;
        canMoveItemWithinDrive?: boolean;
        canMoveItemWithinTeamDrive?: boolean;
        canMoveTeamDriveItem?: boolean;
        canReadDrive?: boolean;
        canReadRevisions?: boolean;
        canReadTeamDrive?: boolean;
        canRemoveChildren?: boolean;
        canRemoveMyDriveParent?: boolean;
        canRename?: boolean;
        canShare?: boolean;
        canTrash?: boolean;
        canTrashChildren?: boolean;
        canUntrash?: boolean;
    } | null;
    /**
     * Additional information about the content of the file. These fields are never populated in responses.
     */
    contentHints?: {
        indexableText?: string;
        thumbnail?: {
            image?: string;
            mimeType?: string;
        };
    } | null;
    /**
     * Restrictions for accessing the content of the file. Only populated if such a restriction exists.
     */
    contentRestrictions?: Schema$ContentRestriction[];
    /**
     * Whether the options to copy, print, or download this file, should be disabled for readers and commenters.
     */
    copyRequiresWriterPermission?: boolean | null;
    /**
     * The time at which the file was created (RFC 3339 date-time).
     */
    createdTime?: string | null;
    /**
     * A short description of the file.
     */
    description?: string | null;
    /**
     * ID of the shared drive the file resides in. Only populated for items in shared drives.
     */
    driveId?: string | null;
    /**
     * Whether the file has been explicitly trashed, as opposed to recursively trashed from a parent folder.
     */
    explicitlyTrashed?: boolean | null;
    /**
     * Links for exporting Docs Editors files to specific formats.
     */
    exportLinks?: {
        [key: string]: string;
    } | null;
    /**
     * The final component of fullFileExtension. This is only available for files with binary content in Google Drive.
     */
    fileExtension?: string | null;
    /**
     * The color for a folder as an RGB hex string. The supported colors are published in the folderColorPalette field of the About resource.
     * If an unsupported color is specified, the closest color in the palette will be used instead.
     */
    folderColorRgb?: string | null;
    /**
     * The full file extension extracted from the name field. May contain multiple concatenated extensions, such as "tar.gz". This is only available for files with binary content in Google Drive.
     * This is automatically updated when the name field changes, however it is not cleared if the new name does not contain a valid extension.
     */
    fullFileExtension?: string | null;
    /**
     * Whether there are permissions directly on this file. This field is only populated for items in shared drives.
     */
    hasAugmentedPermissions?: boolean | null;
    /**
     * Whether this file has a thumbnail. This does not indicate whether the requesting app has access to the thumbnail. To check access, look for the presence of the thumbnailLink field.
     */
    hasThumbnail?: boolean | null;
    /**
     * The ID of the file's head revision. This is currently only available for files with binary content in Google Drive.
     */
    headRevisionId?: string | null;
    /**
     * A static, unauthenticated link to the file's icon.
     */
    iconLink?: string | null;
    /**
     * The ID of the file.
     */
    id?: string | null;
    /**
     * Additional metadata about image media, if available.
     */
    imageMediaMetadata?: {
        aperture?: number;
        cameraMake?: string;
        cameraModel?: string;
        colorSpace?: string;
        exposureBias?: number;
        exposureMode?: string;
        exposureTime?: number;
        flashUsed?: boolean;
        focalLength?: number;
        height?: number;
        isoSpeed?: number;
        lens?: string;
        location?: {
            altitude?: number;
            latitude?: number;
            longitude?: number;
        };
        maxApertureValue?: number;
        meteringMode?: string;
        rotation?: number;
        sensor?: string;
        subjectDistance?: number;
        time?: string;
        whiteBalance?: string;
        width?: number;
    } | null;
    /**
     * Whether the file was created or opened by the requesting app.
     */
    isAppAuthorized?: boolean | null;
    /**
     * Identifies what kind of resource this is. Value: the fixed string "drive#file".
     */
    kind?: string | null;
    /**
     * The last user to modify the file.
     */
    lastModifyingUser?: Schema$User;
    /**
     * The MD5 checksum for the content of the file. This is only applicable to files with binary content in Google Drive.
     */
    md5Checksum?: string | null;
    /**
     * The MIME type of the file.
     * Google Drive will attempt to automatically detect an appropriate value from uploaded content if no value is provided. The value cannot be changed unless a new revision is uploaded.
     * If a file is created with a Google Doc MIME type, the uploaded content will be imported if possible. The supported import formats are published in the About resource.
     */
    mimeType?: string | null;
    /**
     * Whether the file has been modified by this user.
     */
    modifiedByMe?: boolean | null;
    /**
     * The last time the file was modified by the user (RFC 3339 date-time).
     */
    modifiedByMeTime?: string | null;
    /**
     * The last time the file was modified by anyone (RFC 3339 date-time).
     * Note that setting modifiedTime will also update modifiedByMeTime for the user.
     */
    modifiedTime?: string | null;
    /**
     * The name of the file. This is not necessarily unique within a folder. Note that for immutable items such as the top level folders of shared drives, My Drive root folder, and Application Data folder the name is constant.
     */
    name?: string | null;
    /**
     * The original filename of the uploaded content if available, or else the original value of the name field. This is only available for files with binary content in Google Drive.
     */
    originalFilename?: string | null;
    /**
     * Whether the user owns the file. Not populated for items in shared drives.
     */
    ownedByMe?: boolean | null;
    /**
     * The owners of the file. Currently, only certain legacy files may have more than one owner. Not populated for items in shared drives.
     */
    owners?: Schema$User[];
    /**
     * The IDs of the parent folders which contain the file.
     * If not specified as part of a create request, the file will be placed directly in the user's My Drive folder. If not specified as part of a copy request, the file will inherit any discoverable parents of the source file. Update requests must use the addParents and removeParents parameters to modify the parents list.
     */
    parents?: string[] | null;
    /**
     * List of permission IDs for users with access to this file.
     */
    permissionIds?: string[] | null;
    /**
     * The full list of permissions for the file. This is only available if the requesting user can share the file. Not populated for items in shared drives.
     */
    permissions?: Schema$Permission[];
    /**
     * A collection of arbitrary key-value pairs which are visible to all apps.
     * Entries with null values are cleared in update and copy requests.
     */
    properties?: {
        [key: string]: string;
    } | null;
    /**
     * The number of storage quota bytes used by the file. This includes the head revision as well as previous revisions with keepForever enabled.
     */
    quotaBytesUsed?: string | null;
    /**
     * Whether the file has been shared. Not populated for items in shared drives.
     */
    shared?: boolean | null;
    /**
     * The time at which the file was shared with the user, if applicable (RFC 3339 date-time).
     */
    sharedWithMeTime?: string | null;
    /**
     * The user who shared the file with the requesting user, if applicable.
     */
    sharingUser?: Schema$User;
    /**
     * Shortcut file details. Only populated for shortcut files, which have the mimeType field set to application/vnd.google-apps.shortcut.
     */
    shortcutDetails?: {
        targetId?: string;
        targetMimeType?: string;
    } | null;
    /**
     * The size of the file's content in bytes. This is applicable to binary files in Google Drive and Google Docs files.
     */
    size?: string | null;
    /**
     * The list of spaces which contain the file. The currently supported values are 'drive', 'appDataFolder' and 'photos'.
     */
    spaces?: string[] | null;
    /**
     * Whether the user has starred the file.
     */
    starred?: boolean | null;
    /**
     * Deprecated - use driveId instead.
     */
    teamDriveId?: string | null;
    /**
     * A short-lived link to the file's thumbnail, if available. Typically lasts on the order of hours. Only populated when the requesting app can access the file's content. If the file isn't shared publicly, the URL returned in Files.thumbnailLink must be fetched using a credentialed request.
     */
    thumbnailLink?: string | null;
    /**
     * The thumbnail version for use in thumbnail cache invalidation.
     */
    thumbnailVersion?: string | null;
    /**
     * Whether the file has been trashed, either explicitly or from a trashed parent folder. Only the owner may trash a file. The trashed item is excluded from all files.list responses returned for any user who does not own the file. However, all users with access to the file can see the trashed item metadata in an API response. All users with access can copy, download, export, and share the file.
     */
    trashed?: boolean | null;
    /**
     * The time that the item was trashed (RFC 3339 date-time). Only populated for items in shared drives.
     */
    trashedTime?: string | null;
    /**
     * If the file has been explicitly trashed, the user who trashed it. Only populated for items in shared drives.
     */
    trashingUser?: Schema$User;
    /**
     * A monotonically increasing version number for the file. This reflects every change made to the file on the server, even those not visible to the user.
     */
    version?: string | null;
    /**
     * Additional metadata about video media. This may not be available immediately upon upload.
     */
    videoMediaMetadata?: {
        durationMillis?: string;
        height?: number;
        width?: number;
    } | null;
    /**
     * Whether the file has been viewed by this user.
     */
    viewedByMe?: boolean | null;
    /**
     * The last time the file was viewed by the user (RFC 3339 date-time).
     */
    viewedByMeTime?: string | null;
    /**
     * Deprecated - use copyRequiresWriterPermission instead.
     */
    viewersCanCopyContent?: boolean | null;
    /**
     * A link for downloading the content of the file in a browser. This is only available for files with binary content in Google Drive.
     */
    webContentLink?: string | null;
    /**
     * A link for opening the file in a relevant Google editor or viewer in a browser.
     */
    webViewLink?: string | null;
    /**
     * Whether users with only writer permission can modify the file's permissions. Not populated for items in shared drives.
     */
    writersCanShare?: boolean | null;
}

export interface Schema$ContentRestriction {
    /**
     * Whether the content of the file is read-only. If a file is read-only, a new revision of the file may not be added, comments may not be added or modified, and the title of the file may not be modified.
     */
    readOnly?: boolean | null;
    /**
     * Reason for why the content of the file is restricted. This is only mutable on requests that also set readOnly=true.
     */
    reason?: string | null;
    /**
     * The user who set the content restriction. Only populated if readOnly is true.
     */
    restrictingUser?: Schema$User;
    /**
     * The time at which the content restriction was set (formatted RFC 3339 timestamp). Only populated if readOnly is true.
     */
    restrictionTime?: string | null;
    /**
     * The type of the content restriction. Currently the only possible value is globalContentRestriction.
     */
    type?: string | null;
}
export interface Schema$User {
    /**
     * A plain text displayable name for this user.
     */
    displayName?: string | null;
    /**
     * The email address of the user. This may not be present in certain contexts if the user has not made their email address visible to the requester.
     */
    emailAddress?: string | null;
    /**
     * Identifies what kind of resource this is. Value: the fixed string "drive#user".
     */
    kind?: string | null;
    /**
     * Whether this user is the requesting user.
     */
    me?: boolean | null;
    /**
     * The user's ID as visible in Permission resources.
     */
    permissionId?: string | null;
    /**
     * A link to the user's profile photo, if available.
     */
    photoLink?: string | null;
}

export interface Schema$Permission {
    /**
     * Whether the permission allows the file to be discovered through search. This is only applicable for permissions of type domain or anyone.
     */
    allowFileDiscovery?: boolean | null;
    /**
     * Whether the account associated with this permission has been deleted. This field only pertains to user and group permissions.
     */
    deleted?: boolean | null;
    /**
     * The "pretty" name of the value of the permission. The following is a list of examples for each type of permission:
     * - user - User's full name, as defined for their Google account, such as "Joe Smith."
     * - group - Name of the Google Group, such as "The Company Administrators."
     * - domain - String domain name, such as "thecompany.com."
     * - anyone - No displayName is present.
     */
    displayName?: string | null;
    /**
     * The domain to which this permission refers.
     */
    domain?: string | null;
    /**
     * The email address of the user or group to which this permission refers.
     */
    emailAddress?: string | null;
    /**
     * The time at which this permission will expire (RFC 3339 date-time). Expiration times have the following restrictions:
     * - They can only be set on user and group permissions
     * - The time must be in the future
     * - The time cannot be more than a year in the future
     */
    expirationTime?: string | null;
    /**
     * The ID of this permission. This is a unique identifier for the grantee, and is published in User resources as permissionId. IDs should be treated as opaque values.
     */
    id?: string | null;
    /**
     * Identifies what kind of resource this is. Value: the fixed string "drive#permission".
     */
    kind?: string | null;
    /**
     * Details of whether the permissions on this shared drive item are inherited or directly on this item. This is an output-only field which is present only for shared drive items.
     */
    permissionDetails?: Array<{
        inherited?: boolean;
        inheritedFrom?: string;
        permissionType?: string;
        role?: string;
    }> | null;
    /**
     * A link to the user's profile photo, if available.
     */
    photoLink?: string | null;
    /**
     * The role granted by this permission. While new values may be supported in the future, the following are currently allowed:
     * - owner
     * - organizer
     * - fileOrganizer
     * - writer
     * - commenter
     * - reader
     */
    role?: string | null;
    /**
     * Deprecated - use permissionDetails instead.
     */
    teamDrivePermissionDetails?: Array<{
        inherited?: boolean;
        inheritedFrom?: string;
        role?: string;
        teamDrivePermissionType?: string;
    }> | null;
    /**
     * The type of the grantee. Valid values are:
     * - user
     * - group
     * - domain
     * - anyone  When creating a permission, if type is user or group, you must provide an emailAddress for the user or group. When type is domain, you must provide a domain. There isn't extra information required for a anyone type.
     */
    type?: string | null;
    /**
     * Indicates the view for this permission. Only populated for permissions that belong to a view. published is the only supported value.
     */
    view?: string | null;
}

export enum GoogleDocumentType {
    SPREADSHEET = "application/vnd.google-apps.spreadsheet",
    DOCUMENT = "application/vnd.google-apps.document",
    PRESENTATION = "application/vnd.google-apps.presentation",
    FORMS = "application/vnd.google-apps.form",
}

export const driveFileTypeNameMap = {
    [GoogleDocumentType.DOCUMENT]: "Document",
    [GoogleDocumentType.SPREADSHEET]: "Spreadsheet",
    [GoogleDocumentType.PRESENTATION]: "Presentation",
    [GoogleDocumentType.FORMS]: "Form",
};

export interface GoogleDriveFilePermissions {
    allowFileDiscovery: boolean;
    id: string;
    kind: string;
    role: string;
    type: string;
    deleted?: boolean;
    displayName?: string;
    emailAddress?: string;
}

export type InternalDocumentType = {
    documentUrl: string;
    documentId: string;
    documentType: GoogleDocumentType;
    sourceType: LiveDocSourceType;
    documentName: string;
};

export type InternalDocumentTypeOptional = {
    documentUrl?: string;
    documentId?: string;
    documentType?: GoogleDocumentType;
    sourceType?: LiveDocSourceType;
    documentName?: string;
};

export interface GoogleDriveFile extends Schema$File {}

export type DocumentContainerConfig = Partial<InternalDocumentType>;

export enum documentPermissions {
    PUBLIC_COMMENT = "commenter",
    PUBLIC_READ = "reader",
    PUBLIC_WRITE = "writer",
}

export const permissionsOptions: Array<documentPermissions | "none"> = [
    documentPermissions.PUBLIC_WRITE,
    documentPermissions.PUBLIC_COMMENT,
    documentPermissions.PUBLIC_READ,
    "none",
];
export const permissionsOptionsLabels = {
    [documentPermissions.PUBLIC_WRITE]: "Public",
    [documentPermissions.PUBLIC_COMMENT]: "Comment only",
    [documentPermissions.PUBLIC_READ]: "Read only",
    none: "Restricted",
};

export const getPermissionsType = (file: GoogleDriveFile) => {
    const perms = file?.permissions?.find((p) => p.type === "anyone");
    if (perms?.role === "commenter") return documentPermissions.PUBLIC_COMMENT;
    if (perms?.role === "reader") return documentPermissions.PUBLIC_READ;
    if (perms?.role === "writer") return documentPermissions.PUBLIC_WRITE;
    return "none";
};
