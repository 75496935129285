import {BreakoutRoomsConfigFragment} from "@generated/data";
import {useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";

function useBreakoutConfigStore() {
    const borState = useState<BreakoutRoomsConfigFragment>();
    return borState;
}

export const [BreakoutConfigProvider, useBreakoutConfig] = createContextProvider(
    {
        name: "BreakoutConfigProvider",
        strict: true,
    },
    useBreakoutConfigStore
);
