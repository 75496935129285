import {useCurrentTemplate} from "@workhorse/pages/templates/TemplateProviders";
import {useQuery} from "@workhorse/dataApi";
import classes from "./style/AgendaItemUserEditing.module.scss";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";

type AgendaItemUserEditingProps = {
    userEditingId: string;
};

const AgendaItemUserEditing = (props: AgendaItemUserEditingProps) => {
    const {id, user} = useCurrentTemplate();
    const {data} = useQuery("AccessListItemsDocument", {
        variables: {
            where: {
                resourceId: {
                    equals: undefined,
                },
                agendatemplateId: {
                    equals: id,
                },
            },
        },
        fetchPolicy: "cache-only",
    });

    const userEditing =
        props.userEditingId === user?.id ? user : data?.accessListItems?.find((a) => a.user.id === props.userEditingId)?.user;

    return userEditing ? (
        <div className={classes.root}>
            <ProfilePicture
                // className={classes.listItem}
                firstName={userEditing.firstName}
                lastName={userEditing.lastName}
                avatar={userEditing.profilePicture}
                avatarSize="S"
                avatarVariant="rounded"
                key={userEditing.id}
            />
            <div className={classes.text}>{userEditing.firstName} editing...</div>
        </div>
    ) : null;
};

export default AgendaItemUserEditing;
