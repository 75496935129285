import {createContext, ReactNode, useCallback, useContext, useState} from "@workhorse/api/rendering";

function useHardMuteStore() {
    const [hardMuted, setHardMute] = useState(false);

    const toggleHardMute = useCallback(() => {
        setHardMute((value) => !value);
    }, []);

    return {
        hardMuted,
        setHardMute,
        toggleHardMute,
    };
}

type HardMuteStore = ReturnType<typeof useHardMuteStore>;
const HardMuteContext = createContext<HardMuteStore | null>(null);

export function HardMuteProvider({children}: {children: ReactNode}) {
    const value = useHardMuteStore();
    return <HardMuteContext.Provider value={value}>{children}</HardMuteContext.Provider>;
}

export const useHardMute = () => {
    const value = useContext(HardMuteContext);
    if (!value) {
        throw new Error("Cannot use 'useHardMute hook without 'HardMuteProvider'");
    }
    return value as HardMuteStore;
};
