import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Checkbox from "@ui/cdk/Checkbox";
import classes from "./style/CommandPaletteContent.module.scss";
import {usePaletteSearchFilters} from "./CommandPaletteResultsProvider";
import {PaletteActiveCateg} from "./CommandPaletteProviders";
import {ActionCategory} from "./actionCategTree";
import {useEffect} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import {PaletteItemFileType, PaletteItemPermissionType, PaletteSearchInput} from "@generated/data";

type SetFilters = ReturnType<typeof usePaletteSearchFilters>["setSearchFilters"];

interface CheckboxItems {
    id: string;
    label: string;
    translateKey: string;
    checked?: (filters: PaletteSearchInput) => boolean | undefined;
    onClick?: (PaletteSearchInput: SetFilters) => void;
}

function toggleFileType(filters: PaletteSearchInput, type: PaletteItemFileType) {
    if (filters?.fileType == null) {
        return [type];
    }
    if (filters.fileType.includes(type)) {
        return filters.fileType.filter((obj) => obj !== type);
    }
    return [...filters.fileType, type];
}

function togglePermission(filters: PaletteSearchInput, permission: PaletteItemPermissionType) {
    if (!filters?.permissions) {
        return [permission] as PaletteItemPermissionType[];
    }
    if (filters.permissions?.includes(permission)) {
        return filters.permissions?.filter((obj) => obj !== permission);
    }
    return [...(filters.permissions ?? []), permission];
}

const fileFilters: CheckboxItems[] = [
    {
        id: "all",
        label: "All",
        translateKey: "g.filetype.all",
        checked: (filters) => filters?.fileType == null || filters.fileType?.length === 0,
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: []})),
    },
    {
        id: "ppt",
        label: "Presentations",
        translateKey: "g.filetype.presentation_plural",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Presentation),
        onClick: (setFilters) =>
            setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Presentation)})),
    },
    {
        id: "keynote",
        label: "Keynote",
        translateKey: "g.filetype.keynote",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Keynote),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Keynote)})),
    },
    {
        id: "pdf",
        label: "PDF",
        translateKey: "g.filetype.pdf",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Pdf),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Pdf)})),
    },
    {
        id: "doc",
        label: "Documents",
        translateKey: "g.filetype.document_plural",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Doc),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Doc)})),
    },
    {
        id: "excel",
        label: "Spreadsheets",
        translateKey: "g.filetype.spreadsheet_plural",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Excel),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Excel)})),
    },
    {
        id: "pages",
        label: "Pages",
        translateKey: "g.filetype.pages",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Pages),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Pages)})),
    },
    {
        id: "numbers",
        label: "Numbers",
        translateKey: "g.filetype.numbers",
        checked: (filters) => filters?.fileType?.includes(PaletteItemFileType.Numbers),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Numbers)})),
    },
    {
        id: "images",
        label: "Images",
        translateKey: "g.filetype.image_plural",
        checked: (filters) => filters.fileType?.includes(PaletteItemFileType.Image),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Image)})),
    },
    {
        id: "videos",
        label: "Videos",
        translateKey: "g.filetype.video_plural",
        checked: (filters) => filters.fileType?.includes(PaletteItemFileType.Video),
        onClick: (setFilters) => setFilters((current) => ({...current, fileType: toggleFileType(current, PaletteItemFileType.Video)})),
    },
];

const agendaFilters: CheckboxItems[] = [
    {
        id: "all",
        label: "All",
        translateKey: "palette.agendas.all",
        checked: (filters) => !filters.permissions?.length,
        onClick: (setFilters) => setFilters((current) => ({...current, permissions: []})),
    },
    {
        id: "my_agendas",
        label: "My agendas",
        translateKey: "palette.agendas.my_agendas",
        checked: (filters) => filters?.permissions?.includes(PaletteItemPermissionType.Private),
        onClick: (setFilters) =>
            setFilters((current) => ({...current, permissions: togglePermission(current, PaletteItemPermissionType.Private)})),
    },
    {
        id: "shared_agendas",
        label: "Shared with me",
        translateKey: "palette.agendas.shared_agendas",
        checked: (filters) => filters?.permissions?.includes(PaletteItemPermissionType.Public),
        onClick: (setFilters) =>
            setFilters((current) => ({...current, permissions: togglePermission(current, PaletteItemPermissionType.Public)})),
    },
];

interface Props {
    activeCategory: PaletteActiveCateg;
}

function FileFilters() {
    const {t} = useTranslation();
    const {searchFilters, setSearchFilters} = usePaletteSearchFilters();

    useEffect(() => {
        return () => {
            setSearchFilters((current) => ({...current, fileType: []}));
        };
    }, [setSearchFilters]);

    return (
        <>
            <Typography component="span" variant="sm" className={cls(classes.areaTitle, classes.withSeparator)}>
                {t("palette.what_are_you_looking_for") ?? "What are you looking for?"}
            </Typography>
            <div className={cls("flex flex-row flex-wrap", classes.filtersWrapper)}>
                {fileFilters.map((filter) => (
                    <div
                        key={filter.id}
                        className={cls(
                            "flex flex-row ml-5 mr-5",
                            classes.checkboxContainer,
                            filter?.checked?.(searchFilters) && classes.activeFilter
                        )}
                        onClick={() => filter.onClick?.(setSearchFilters)}
                    >
                        <Checkbox variant="purple" className={classes.checkbox} checked={filter?.checked?.(searchFilters) ?? false} />
                        <Typography className={`mt-auto mb-auto ${classes.checkboxLabel}`} variant="sm">
                            {t(filter.translateKey) ?? filter.label}
                        </Typography>
                    </div>
                ))}
            </div>
        </>
    );
}

function AgendaFilters() {
    const {t} = useTranslation();
    const {searchFilters, setSearchFilters} = usePaletteSearchFilters();

    useEffect(() => {
        return () => {
            setSearchFilters((current) => ({...current, fileType: []}));
        };
    }, [setSearchFilters]);

    return (
        <>
            <Typography component="span" variant="sm" className={cls(classes.areaTitle, classes.withSeparator)}>
                {t("palette.what_are_you_looking_for") ?? "What are you looking for?"}
            </Typography>
            <div className={cls("flex flex-row flex-wrap", classes.filtersWrapper)}>
                {agendaFilters.map((filter) => (
                    <div
                        key={filter.id}
                        className={cls(
                            "flex flex-row ml-5 mr-5",
                            classes.checkboxContainer,
                            filter?.checked?.(searchFilters) && classes.activeFilter
                        )}
                        onClick={() => filter.onClick?.(setSearchFilters)}
                    >
                        <Checkbox variant="purple" className={classes.checkbox} checked={filter?.checked?.(searchFilters) ?? false} />
                        <Typography className={`mt-auto mb-auto ${classes.checkboxLabel}`} variant="sm">
                            {t(filter.translateKey) ?? filter.label}
                        </Typography>
                    </div>
                ))}
            </div>
        </>
    );
}

export function CommandPaletteFilters(props: Props) {
    if (props.activeCategory.name === ActionCategory.File) {
        return <FileFilters />;
    }

    if (props.activeCategory.name === ActionCategory.Agenda) {
        return <AgendaFilters />;
    }

    return null;
}
