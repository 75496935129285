import {ConferenceParticipantStatus} from "@generated/data";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useShallow} from "zustand/react/shallow";
import {useSessionInfo} from "../SessionInfoProvider";

function useVideoDisableStore() {
    const {allowCameraType} = useSessionInfo();

    const {id, isOwner, isSpeaker, isAssistant} = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            id: currentParticipant?.id ?? "",
            isOwner: currentParticipant?.isOwner ?? false,
            isSpeaker:
                !!currentParticipant?.speakerDetails?.id || currentParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker,
            isAssistant: amIAssistant,
        }))
    );

    const videoAllowed = sessionFlagBasedPermission(allowCameraType, {
        owner: isOwner,
        assistant: isAssistant,
        speaker: isSpeaker,
    });

    const isHardVideoDisable = !videoAllowed;

    return useMemo(() => {
        return {
            isHardVideoDisable,
        };
    }, [isHardVideoDisable]);
}

export const [VideoDisableProvider, useForceDisableVideo] = createContextProvider(
    {
        name: "VideoDisableProvider",
        strict: true,
    },
    useVideoDisableStore
);
