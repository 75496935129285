import {HTMLProps, useEffect, useRef} from "@workhorse/api/rendering";
import {useVideoNode} from "../../lib/useVideoNode";
import {useVideoObjectFit} from "../../lib/useVideoObjectFit";
import {useConferenceParticipant, useScreenShareOptions} from "../../providers/ConferenceRoomProvider";
import {LocalTrack} from "livekit-client";

interface LocalVideoProps extends Omit<HTMLProps<HTMLVideoElement>, "ref" | "onResize"> {
    onBind?: () => void;
    onUnbind?: () => void;
    onResize?: (width: number, height: number) => void;
    participantId: string;
}

export function LocalShareVideo(props: LocalVideoProps) {
    const {onBind, onUnbind, onResize, style, participantId, ...rest} = props;
    const {stream} = useScreenShareOptions();
    const {video, setVideo} = useVideoNode();
    useVideoObjectFit(video);

    const options = {
        onBind,
        onUnbind,
        onResize,
    };
    const optionsRef = useRef(options);
    optionsRef.current = options;

    useEffect(() => {
        if (!video || !stream) {
            return;
        }

        video.srcObject = stream;

        function onVideoResize(this: HTMLVideoElement) {
            const width = this.videoWidth;
            const height = this.videoHeight;
            if (width && height) {
                optionsRef.current.onResize?.(width, height);
            }
        }

        video.addEventListener("resize", onVideoResize);

        return () => {
            video.srcObject = null;
            video.removeEventListener("resize", onVideoResize);
        };
    }, [video, stream]);

    return <video {...rest} ref={setVideo} autoPlay={true} muted={true} controls={false} playsInline={true} />;
}
