import {useHardMute} from "@workhorse/api/conference2";
import {useEffect, Fragment} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import PlayerPortal from "../PlayerPortal";
import {joinedSessionVar} from "../playerStateVars";
import MicroArtifactLoader from "./MicroArtifactLoader";
import Lobby from "../lobby/Lobby";
import ToolConfirmation from "./ToolConfirmation";
import SessionDataProviders from "@workhorse/providers/SessionDataProviders";
import ProcessingUiHandler from "../components/PlayerTools/ToolMenu/components/ProcessingUiHandler";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useTranslation} from "react-i18next";
import ConsentToRecordNotification from "../components/ConsentToRecordNotification/ConsentToRecordNotification";
import {useEmbedSessions} from "@workhorse/api/embed";

type SessionContentProps = {
    isAdmin: boolean;
    isAssistant: boolean;
    isLobby: boolean;
    sessionStarted: boolean;
    hardMute?: boolean | null;
    sessionId: string;
    myParticipantId: string;
    sessionIsPublished: boolean;
    isQuickSession: boolean;
    isEvent: boolean;
    requireConsentToRecord: boolean;
};

export default function SessionContent(props: SessionContentProps) {
    const {t} = useTranslation();
    const {
        isLobby,
        isAssistant,
        isAdmin,
        sessionStarted,
        sessionId,
        hardMute,
        myParticipantId,
        sessionIsPublished,
        isQuickSession,
        isEvent,
        requireConsentToRecord: requireConsentToRecordProp,
    } = props;

    const mobileState = useMobile();
    const {isMobileOrTablet} = mobileState;

    const {uiElementsToRemove} = useEmbedSessions();

    const requireConsentToRecord = requireConsentToRecordProp && !uiElementsToRemove.includes("recording_consent");

    useEffect(() => {
        if (isLobby) {
            ariaAnnouncerMessageVar(t("aria_announcer.joined_lobby") ?? "You've now joined the lobby for an upcoming session.");
        } else {
            ariaAnnouncerMessageVar(t("aria_announcer.joined_live_session") ?? "You've now joined a live session.");
        }
    }, [isLobby, t]);

    return (
        <>
            <PlayerPortal />

            <Fragment key="micro-loader">
                {(sessionStarted || isQuickSession) && (
                    <MicroArtifactLoader
                        isAdmin={isAdmin}
                        isAssistant={isAssistant}
                        isLobby={isLobby}
                        mobileState={mobileState}
                        sessionId={sessionId}
                        myParticipantId={myParticipantId}
                        sessionStarted={sessionStarted}
                        isQuickSession={isQuickSession}
                    />
                )}
            </Fragment>
            <ToolConfirmation sessionId={sessionId} />

            {requireConsentToRecord && <ConsentToRecordNotification sessionId={sessionId} />}

            <Fragment key="side-effects">
                <SideEffects hardMute={hardMute} />
            </Fragment>

            <Fragment key="lobby">
                {isLobby && (
                    <Lobby sessionIsPublished={sessionIsPublished} mobileState={mobileState} isAssistant={isAssistant} isAdmin={isAdmin} />
                )}
            </Fragment>
            {isMobileOrTablet ? (
                <Fragment key="session-mobile-processing">
                    <SessionDataProviders isEditMode>
                        <ProcessingUiHandler isAdmin={isAdmin} inLobby={isLobby} isMobileOrTablet={true}></ProcessingUiHandler>
                    </SessionDataProviders>
                </Fragment>
            ) : null}
        </>
    );
}

type SideEffectProps = {
    hardMute?: boolean | null;
};

function SideEffects(props: SideEffectProps) {
    const {setHardMute} = useHardMute();

    useEffect(() => {
        joinedSessionVar(true);
    }, []);

    useEffect(() => {
        setHardMute(props.hardMute ?? false);
    }, [props.hardMute, setHardMute]);

    return <></>;
}
