import {useCallback, useEffect} from "@workhorse/api/rendering";

export const useDebouncedEffect = (effect: () => void, delay: number, deps: unknown[]): void => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

export const debounce = <T extends (...args: any) => void>(func: T, wait: number): T => {
    let timeout;

    return function executeFunction(...args: any) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    } as T;
};
