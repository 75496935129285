import {createContext, ReactNode, useContext, useState} from "@workhorse/api/rendering";
import {useCallback} from "react";

function useSystemSoftMuteStore() {
    const [softMuted, setSoftMute] = useState(false);

    const toggleSoftMute = useCallback(() => {
        setSoftMute((value) => !value);
    }, []);

    return {
        softMuted,
        setSoftMute,
        toggleSoftMute,
    };
}

type SystemSoftMuteStore = ReturnType<typeof useSystemSoftMuteStore>;
const SystemSoftMuteContext = createContext<SystemSoftMuteStore | null>(null);

export function SystemSoftMuteProvider({children}: {children: ReactNode}) {
    const value = useSystemSoftMuteStore();
    return <SystemSoftMuteContext.Provider value={value}>{children}</SystemSoftMuteContext.Provider>;
}

export const useSystemSoftMute = () => {
    const value = useContext(SystemSoftMuteContext);
    if (!value) {
        throw new Error("Cannot use 'useSystemSoftMute hook without 'SystemSoftMuteProvider'");
    }
    return value as SystemSoftMuteStore;
};
