import svc from "@api/service/client";
import {processLink} from "@artifacts/iframe/api/iframeUtils";
import {checkIfIframeable, AirtableLinkType, handleAirtableRegex, handleAirtableLink} from "@artifacts/airtable/api/utils";
import {VideoV1} from "@sessions/common/resources";
import {ResourcesTypes} from "@generated/artifacts/resources";
import Typography from "@ui/cdk/Typography";
import Input from "@ui/core/components/Input";
import InputLabel from "@ui/core/components/InputLabel";
import React, {useState} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import formClasses from "./styles/CreateForm.module.scss";
import classes from "./styles/IframeCreateForm.module.scss";
import InputAdornment from "@ui/core/components/InputAdornment";
import IconButton from "@ui/core/components/IconButton";
import TrendingFlatRoundedIcon from "@material-ui/icons/TrendingFlatRounded";
import {cls} from "@ui/cdk/util/util";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import clientEvents from "@api/events/client";
import {ResourceRowExtra} from "../../../utils";
import {modifyResourceCounter} from "./utils";
import {openExternalLink} from "@workhorse/util/links";

type AirtableCreateFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    onDoubleClick: (
        resourceId: ResourceRowExtra["id"],
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
        videoType?: VideoV1["type"]
    ) => void;
};

type AirtableLinkValuesType = {
    airtableLink: string;
    embedCode: string;
};

const AirtableCreateForm = (props: AirtableCreateFormProps) => {
    const {handleClose, onDoubleClick} = props;

    const [linkValues, setLinkValues] = useState<AirtableLinkValuesType>({airtableLink: "", embedCode: ""});
    const [errorValue, setErrorValue] = useState<string | null>(null);
    const [selectedField, setSelectedField] = useState<AirtableLinkType | null>(null);

    const [loading, setLoading] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target;

        if (errorValue) {
            setErrorValue(null);
        }

        setLinkValues({...linkValues, [name]: value});
    };

    const handleAddPresentation = async () => {
        if (!selectedField) {
            return;
        }

        const processedLink = processLink(linkValues[selectedField]);
        const errorProcessedLink = handleAirtableRegex(processedLink);

        if (errorProcessedLink) {
            setErrorValue(errorProcessedLink);
            return;
        }

        const validLink = handleAirtableLink(processedLink);

        const errorIframeable = await checkIfIframeable(validLink);

        if (errorIframeable) {
            setErrorValue(errorIframeable);
        } else {
            createLinkResource("Airtable", validLink);
        }
    };

    const createLinkResource = async (title: string, airtableLink: string) => {
        setLoading(true);

        try {
            const res = await svc.resx.createResource("flowos/airtable/resx/Airtable", {
                name: title,
                airtableLink,
            });
            if (res) {
                modifyResourceCounter("flowos/airtable/resx/Airtable", "add");
                handleClose();
                setTimeout(() => {
                    clientEvents.emit("click-resource-row", {
                        resourceId: res.id,
                    });
                }, 100);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleKeyDown = (e: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>) => {
        if (e.type === "keydown" && e.key === "Enter" && !e.shiftKey) {
            e.stopPropagation();
            handleAddPresentation();
        }
    };

    const handleFocus = (type: AirtableLinkType) => {
        setSelectedField(type);
    };

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e.detail > 1) {
            return;
        }
        handleAddPresentation();
    };

    return (
        <div className={classes.root} key="airtable-create">
            <div className={classes.inputGroup} key="airtable-create-airtableLink">
                <InputLabel
                    className={cls("flex flex-items-center", formClasses.inputLabel, classes.inputLabel)}
                    htmlFor="airtable-airtableLink-link"
                    key="airtable-create-airtableLink-label"
                >
                    <Typography variant="sm" key="airtable-create-airtableLink-label-title">
                        Airtable link
                    </Typography>
                    <a
                        className={cls("flex", classes.articleLink)}
                        onClick={(e) => openExternalLink(getHelpCenterArticleLink("tools-files-integrations/integrations/airtable"))}
                        key="airtable-create-form-article-url"
                    >
                        <HelpOutlineIcon key="airtable-create-form-article-icon" />
                    </a>
                </InputLabel>

                <Input
                    name={AirtableLinkType.AIRTABLE_LINK}
                    id="airtable-airtableLink-link"
                    placeholder="Paste link here"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus.bind(this, AirtableLinkType.AIRTABLE_LINK)}
                    value={linkValues.airtableLink}
                    disableUnderline
                    className={cls(formClasses.inputRoot, classes.inputRoot)}
                    key="airtable-create-airtableLink-input"
                    endAdornment={
                        selectedField === AirtableLinkType.AIRTABLE_LINK && linkValues.airtableLink.length ? (
                            <InputAdornment position="end">
                                <IconButton data-id="submit-link" className={formClasses.inputIcon} onClick={handleSubmit}>
                                    <TrendingFlatRoundedIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                />
            </div>

            <div className={classes.inputGroup} key="airtable-create-embedCode">
                <InputLabel
                    className={cls("flex flex-items-center", formClasses.inputLabel, classes.inputLabel)}
                    htmlFor="airtable-embedCode-link"
                    key="airtable-create-embedCode-label"
                >
                    <Typography variant="sm" key="airtable-create-embedCode-label-title">
                        Embed code
                    </Typography>
                    <a
                        className={cls("flex", classes.articleLink)}
                        onClick={() => openExternalLink(getHelpCenterArticleLink("tools-files-integrations/integrations/airtable"))}
                        key="airtable-create-form-article-url"
                    >
                        <HelpOutlineIcon key="airtable-create-form-article-icon" />
                    </a>
                </InputLabel>

                <Input
                    name={AirtableLinkType.EMBED_CODE}
                    id="airtable-embedCode-link"
                    placeholder='Example: <iframe class="airtable-embed" src="https://airtable.com/embed/shrVd7cMT7e13qsjL?backgroundColor=gray&viewControls=on"></iframe>'
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus.bind(this, AirtableLinkType.EMBED_CODE)}
                    value={linkValues.embedCode}
                    disableUnderline
                    className={cls(formClasses.inputRoot, classes.inputRoot, "airtable")}
                    inputComponent="textarea"
                    endAdornment={
                        selectedField === AirtableLinkType.EMBED_CODE && linkValues.embedCode.length ? (
                            <InputAdornment position="end">
                                <IconButton className={formClasses.inputIcon} onClick={handleSubmit}>
                                    <TrendingFlatRoundedIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                    key="airtable-create-embedCode-input"
                />
            </div>

            {errorValue !== null && (
                <Typography component="div" variant="sm" className={classes.errorText} key="airtable-create-embedCode-error">
                    {errorValue}
                </Typography>
            )}

            {loading ? <Loading zIndex={999} key="airtable-create-loading" /> : null}
        </div>
    );
};
export default AirtableCreateForm;
