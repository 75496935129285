import {SessionData} from "@workhorse/declarations/dataTypes";
import PlayerHeader from "../header/PlayerHeader";
import Inject from "@workhorse/providers/Injector";
import classes from "../header/styles/PlayerHeader.module.scss";
import {cls} from "@ui/cdk/util";

type SessionHeaderProps = {
    isAdmin: boolean;
    isAssistant: boolean;
    session: SessionData;
    isLobby: boolean;
};

function SessionHeader(props: SessionHeaderProps) {
    return (
        <Inject target="inHeader" id="header-player" className={cls("fullw flex-justify-between", classes.root)}>
            <PlayerHeader {...props} isPreJoin={false} />
        </Inject>
    );
}

export default SessionHeader;
