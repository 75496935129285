import {GetCelloTokenDocument, GetRemoteUserDocument} from "@generated/data";
import environment from "@generated/environment";
import apollo from "../apollo";

export class CelloService {
    private static instance: CelloService;
    private token: string | null = null;

    public static async getInstance() {
        if (!CelloService.instance) {
            CelloService.instance = new CelloService();
            await CelloService.instance.init();
        }

        return CelloService.instance;
    }

    public async init() {
        const userInfo = apollo.cache.readQuery({
            query: GetRemoteUserDocument,
        });

        if (!userInfo?.getRemoteUser.user) {
            throw new Error("User not found");
        }

        this.token = await this.queryToken();

        const tokenRefreshRoutine = async () => {
            console.log("CELLO: Token expiring, updating token...");

            this.token = await this.queryToken();

            try {
                // @ts-ignore
                await window.Cello("updateToken", this.token);

                console.log("CELLO: Token updated");
            } catch (error) {
                console.error("error updating cello token: ", error);
            }
        };

        // @ts-ignore
        window.cello = window.cello || {cmd: []};
        // @ts-ignore
        window.cello.cmd.push((cello) =>
            cello.boot({
                productId: environment.celloProductId,
                token: this.token,
                hideDefaultLauncher: true,
                email: userInfo.getRemoteUser.user?.email,
                firstName: userInfo.getRemoteUser.user?.firstName,
                lastName: userInfo.getRemoteUser.user?.lastName,
                onTokenExpiring: tokenRefreshRoutine,
                onTokenExpired: tokenRefreshRoutine,
                customLauncherSelector: "#cello-btn",
                productUserDetails: {
                    email: userInfo.getRemoteUser.user?.email,
                    firstName: userInfo.getRemoteUser.user?.firstName,
                    lastName: userInfo.getRemoteUser.user?.lastName,
                    fullName: `${userInfo.getRemoteUser.user?.firstName ?? ""} ${userInfo?.getRemoteUser.user?.lastName ?? ""}`.trim(),
                },
                // announcement: {
                //     selector: "#cello-btn",
                //     position: "left",
                // },
            })
        );
    }

    private async queryToken(): Promise<string> {
        const getCelloTokenResponse = await apollo.client.query({
            query: GetCelloTokenDocument,
            fetchPolicy: "network-only",
        });

        if (!getCelloTokenResponse) {
            throw new Error("Failed to get cello token");
        }

        return getCelloTokenResponse.data.getCelloToken;
    }

    public static open() {
        // @ts-ignore
        window.Cello("open");
    }

    public static show() {
        // @ts-ignore
        window.Cello("show");
    }
}
