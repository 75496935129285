import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {useTranslation} from "react-i18next";
import classes from "./style/UpgradeNotification.module.scss";

import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";

import Link from "@ui/cdk/Link";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";

interface UpgradeNotificationProps {
    isAgenda?: boolean;
    inLobby?: boolean;
    title: string | {key: string; options?: Record<string, string | number>};
    subtitle?: string | {key: string; options?: Record<string, string | number>};
    onClose: () => void;
}

const UpgradeNotification = (props: UpgradeNotificationProps) => {
    const {t} = useTranslation();
    const {title, subtitle, onClose} = props;

    const currentParticipant = useCurrentParticipant();
    const hostType = useHostType();

    if (!currentParticipant) {
        return null;
    }

    if (hostType !== HostType.Player) {
        onClose();
        return null;
    }

    const renderedTitle = title
        ? typeof title === "string"
            ? t(title)
            : title.key && title.options
            ? t(title.key, title.options).toString()
            : null
        : null;

    const renderedSubtitle = subtitle
        ? typeof subtitle === "string"
            ? t(subtitle)
            : subtitle.key && subtitle.options
            ? t(subtitle.key, subtitle.options).toString()
            : null
        : null;

    return (
        <div
            data-id="upgrade-notification"
            className={classes.root}
            onMouseEnter={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
            onMouseOver={(e) => e.stopPropagation()}
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={classes.circularMessageWrapper}>
                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            {renderedTitle}
                        </Typography>
                        {renderedSubtitle && (
                            <Typography variant="base" className={classes.description}>
                                {renderedSubtitle}
                            </Typography>
                        )}
                    </div>
                </div>
                <div className={cls(classes.buttonsContainer)}>
                    {/* <OpenInNewRoundedIcon className="mr-6" />
                        {t("limitations.dialog.see_all_plans")} */}

                    <Button data-id="ok" onClick={onClose} variant="ghost">
                        {t("g.not_now") ?? "Not now"}
                    </Button>

                    <Link to="/user/organization/subscriptions/plans" target="_blank">
                        <Button data-id="ok" onClick={onClose} variant="primary">
                            {t("g.upgrade") ?? "Upgrade"}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default UpgradeNotification;
