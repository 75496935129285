import browserInfo from "@workhorse/api/BrowserInfo";
import {useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {createPersistedState} from "@workhorse/api/utils/state";
import {useUserInfo} from "@workhorse/providers/User";
import {AudioStreamManagerResult} from "../../lib/DeviceManager/AudioStreamManager";
import {DeviceManager} from "../../lib/DeviceManager/DeviceManager";
import {VideoStreamManagerResult} from "../../lib/DeviceManager/VideoStreamManager";
import {useOrderedAsyncReplaceableTask} from "../../lib/useAsyncReplaceableTask";
import {BackgroundFilter} from "../../lib/VideoReplacement/VideoReplacementBackgrounds";
import {
    areDummyDevices,
    browserBehavior,
    getCameras,
    getDeviceIdFromConstraint,
    getDeviceIdFromStream,
    getMicrophones,
    getSpeakers,
    getStreamSettings,
    isNotAllowedError,
    isNotFoundError,
    isOverconstrainedError,
    isUnreadableDeviceError,
} from "../../utils";
import {migrateSettings} from "./migrate-settings";

function shouldEnableWebAudio() {
    return !browserInfo.isAndroid() && !browserInfo.isIOS() && !browserInfo.isSafari();
}

const usePersistedBoolean = createPersistedState<boolean>(
    (value) => {
        return value ? "1" : "0";
    },
    (serialized) => {
        return serialized === "1";
    }
);

const useNullablePersistedString = createPersistedState<string | null>(
    (value) => {
        return value ?? "";
    },
    (serialized) => {
        return serialized ?? null;
    }
);

const usePersistedVideoFilter = createPersistedState<BackgroundFilter>(
    (value) => {
        return value;
    },
    (serialized) => {
        if (isBackgroundFilterValue(serialized)) {
            return serialized;
        }
        return BackgroundFilter.NONE;
    }
);

function isBackgroundFilterValue(filter: string): filter is BackgroundFilter {
    if (Object.values(BackgroundFilter).includes(filter as BackgroundFilter)) {
        return true;
    }

    return false;
}

function useDevicesStore() {
    const user = useUserInfo();
    const userId = user.id;

    useMemo(() => migrateSettings(userId), [userId]);

    const [started, setStarted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

    const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
    const [audioError, setAudioError] = useState<unknown | null>(null);
    const [noiseReduction, setNoiseReduction] = usePersistedBoolean(`devices.audio.noise-reduction:${userId}`, false);
    const [isVoiceFocusSupported, setIsVoiceFocusSupported] = useState<boolean>(false);
    const [noiseSuppressedStream, setNoiseSuppressedStream] = useState<MediaStream | null>(null);

    const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
    const [videoError, setVideoError] = useState<unknown | null>(null);
    const [videoFilter, setVideoFilter] = usePersistedVideoFilter(`devices.video.filter:${userId}`, BackgroundFilter.NONE);
    const [videoCustomFilter, setVideoCustomFilter] = useNullablePersistedString(`devices.video.filter.custom:${userId}`, null);
    const [videoFilterModel, setVideoFilterModel] = useState<"landscape" | "general">("general");
    const [videoTransformedStream, setVideoTransformedStream] = useState<MediaStream | null>(null);
    const [isVideoFilterSupported] = useState(() => browserBehavior.supportsBackgroundFilter());

    const [audioConstraints, setAudioConstraints] = useState<MediaTrackConstraints | null>(null);
    const [videoConstraints, setVideoConstraints] = useState<MediaTrackConstraints | null>(null);

    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = usePersistedBoolean(`devices.video.enabled:${userId}`, true);
    const [audioMuted, setAudioMuted] = usePersistedBoolean(`devices.audio.muted:${userId}`, false);

    const [speakerDevice, setSpeakerDevice] = useNullablePersistedString(`devices.speakers.device:${userId}`, null);
    const [canSetSpeaker] = useState(() => browserBehavior.supportsSetSinkId());

    const [noAudioForProlongedTime, setNoAudioForProlongedTime] = useState(false);
    const [usingFallbackAudio, setUsingFallbackAudio] = useState(false);

    const options = {
        started,
        devices,
        audioEnabled,
        videoEnabled,
    };
    const optionsRef = useRef(options);
    optionsRef.current = options;

    const audioChangeEnqueue = useOrderedAsyncReplaceableTask();
    const videoChangeEnqueue = useOrderedAsyncReplaceableTask();
    const audioProcessorChangeEnqueue = useOrderedAsyncReplaceableTask();
    const videoProcessorChangeEnqueue = useOrderedAsyncReplaceableTask();

    const shouldResetConstraints = useCallback((err: unknown) => {
        if (isOverconstrainedError(err)) {
            return true;
        }

        if (isNotFoundError(err)) {
            return true;
        }

        return false;
    }, []);

    const updateAudioStream = useCallback(
        (result: AudioStreamManagerResult) => {
            setAudioStream(result.stream);
            setNoiseSuppressedStream(result.noiseSuppressedStream ?? null);

            if (result.stream == null) {
                setAudioEnabled(false);
            }

            if (result.stream != null) {
                console.log("devices: got audio stream");
                setUsingFallbackAudio(false);
            }

            if (result.error) {
                console.log("devices: audio error", result.error);

                if (shouldResetConstraints(result.error)) {
                    setAudioConstraints(null);
                }
            }
        },
        [setAudioEnabled, shouldResetConstraints]
    );

    const updateVideoStream = useCallback(
        (result: VideoStreamManagerResult) => {
            setVideoStream(result.stream);
            setVideoTransformedStream(result.videoTransformedStream ?? null);

            if (result.stream == null) {
                setVideoEnabled(false);
            }

            if (result.stream != null) {
                console.log("devices: got video stream");
            }

            if (result.error) {
                console.log("devices: video error", result.error);

                if (shouldResetConstraints(result.error)) {
                    setVideoConstraints(null);
                }
            }
        },
        [setVideoEnabled, shouldResetConstraints]
    );

    const resetAudioStream = useCallback(() => {
        setAudioStream(null);
        setNoiseSuppressedStream(null);
        setUsingFallbackAudio(false);
    }, []);

    const resetVideoStream = useCallback(() => {
        setVideoStream(null);
        setVideoTransformedStream(null);
    }, []);

    const [deviceManager] = useState(() => {
        const checkDevice = async (deviceId: string) => {
            const {devices} = await deviceManager.getDevices();
            const exists = devices.findIndex((device) => device.deviceId === deviceId) !== -1;
            const revokedPermission = devices.length === 0 || areDummyDevices(devices);
            return {
                exists,
                revokedPermission,
            };
        };

        const onAudioStreamRestarted = (result: AudioStreamManagerResult) => {
            updateAudioStream(result);
        };

        const onVideoStreamRestarted = (result: VideoStreamManagerResult) => {
            updateVideoStream(result);
        };

        const onAudioStreamEnded = async (stream: MediaStream) => {
            resetAudioStream();
            setAudioEnabled(false);
            deviceManager.events.emit("audio-ended");

            const deviceId = getDeviceIdFromStream(stream);

            if (!deviceId) {
                setAudioConstraints(null);
                return;
            }

            const {exists, revokedPermission} = await checkDevice(deviceId);

            if (!exists) {
                setAudioConstraints(null);
                deviceManager.events.emit("audio-removed");
            }

            if (revokedPermission) {
                // setMicrophonePermission("denied");
            }
        };

        const onVideoStreamEnded = async (stream: MediaStream) => {
            resetVideoStream();
            setVideoEnabled(false);
            deviceManager.events.emit("video-ended");

            const deviceId = getDeviceIdFromStream(stream);

            if (!deviceId) {
                setVideoConstraints(null);
                return;
            }

            const {exists, revokedPermission} = await checkDevice(deviceId);

            if (!exists) {
                setVideoConstraints(null);
                deviceManager.events.emit("video-removed");
            }

            if (revokedPermission) {
                // setCameraPermission("denied");
            }
        };

        return new DeviceManager(userId, onAudioStreamRestarted, onVideoStreamRestarted, onAudioStreamEnded, onVideoStreamEnded);
    });

    const start = useCallback(() => {
        setStarted(true);
        setLoading(true);
        setAudioEnabled(true);
        setNoAudioForProlongedTime(false);
    }, []);

    const stop = useCallback(() => {
        setStarted(false);
        setLoading(false);
        // fucking lobby
        // setAudioConstraints(null);
        // setVideoConstraints(null);
        setUsingFallbackAudio(false);
        setAudioError(null);
        setVideoError(null);
        setNoAudioForProlongedTime(false);
        deviceManager.pause();
    }, [deviceManager]);

    const toggleVideo = useCallback(() => {
        setVideoEnabled((enabled) => !enabled);
    }, [setVideoEnabled]);

    const toggleAudio = useCallback(() => {
        setAudioEnabled((current) => {
            if (!current) {
                setAudioMuted(false);
            } else {
                setAudioMuted((m) => !m);
            }
            return true;
        });
    }, [setAudioEnabled, setAudioMuted]);

    const createFallbackAudioConstraints = useCallback((): MediaTrackConstraints | null => {
        const devices = optionsRef.current.devices;
        const candidate = deviceManager.findAudioDeviceCandidate(devices);

        if (!candidate) {
            return null;
        }

        if (candidate.isCertain) {
            return {
                deviceId: {exact: candidate.deviceId},
            };
        }

        return {
            deviceId: {ideal: candidate.deviceId},
        };
    }, [deviceManager]);

    const createFallbackVideoConstraints = useCallback((): MediaTrackConstraints | null => {
        const devices = optionsRef.current.devices;
        const candidate = deviceManager.findVideoDeviceCandidate(devices);

        if (!candidate) {
            return null;
        }

        if (candidate.isCertain) {
            return {
                deviceId: {exact: candidate.deviceId},
            };
        }

        return {
            deviceId: {ideal: candidate.deviceId},
        };
    }, [deviceManager]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!started || !audioEnabled) {
            return audioChangeEnqueue(async () => {
                await deviceManager.audio.stopStream();
                resetAudioStream();
            });
        }

        audioChangeEnqueue(async () => {
            if (!optionsRef.current.audioEnabled) {
                return;
            }

            const constraints = audioConstraints ?? createFallbackAudioConstraints();

            if (deviceManager.audio.isActive() && !deviceManager.audio.constraintsDidChange(constraints)) {
                return;
            }

            console.log("device: request audio with constraints", JSON.stringify(constraints));
            const result = await deviceManager.audio.setStream(constraints);
            updateAudioStream(result);

            setAudioConstraints((current) => {
                // constraints got changed again in the meantime
                if (current !== audioConstraints) {
                    return current;
                }
                if (typeof deviceManager.audio.constraints?.audio === "object") {
                    return deviceManager.audio.constraints.audio;
                }
                return current;
            });
        });
    }, [
        started,
        loading,
        audioEnabled,
        audioConstraints,
        deviceManager,
        audioChangeEnqueue,
        updateAudioStream,
        resetAudioStream,
        createFallbackAudioConstraints,
    ]);

    useEffect(() => {
        audioProcessorChangeEnqueue(async () => {
            const stream = await deviceManager.audio.updateNoiseReduction(noiseReduction);
            setNoiseSuppressedStream(stream ?? null);
        });
    }, [noiseReduction, deviceManager, audioProcessorChangeEnqueue]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!started || !videoEnabled) {
            return videoChangeEnqueue(async () => {
                await deviceManager.video.stopStream();
                resetVideoStream();
            });
        }

        videoChangeEnqueue(async () => {
            if (!optionsRef.current.videoEnabled) {
                return;
            }

            const constraints = videoConstraints ?? createFallbackVideoConstraints();

            if (deviceManager.video.isActive() && !deviceManager.video.constraintsDidChange(constraints)) {
                return;
            }

            console.log("device: request video with constraints", JSON.stringify(constraints));
            const result = await deviceManager.video.setStream(constraints);

            updateVideoStream(result);

            setVideoConstraints((current) => {
                // constraints got changed again in the meantime
                if (current !== videoConstraints) {
                    return current;
                }
                if (typeof deviceManager.video.constraints?.video === "object") {
                    return deviceManager.video.constraints.video;
                }
                return current;
            });
        });
    }, [
        started,
        loading,
        videoEnabled,
        videoConstraints,
        deviceManager,
        videoChangeEnqueue,
        updateVideoStream,
        resetVideoStream,
        createFallbackVideoConstraints,
    ]);

    useEffect(() => {
        videoProcessorChangeEnqueue(async () => {
            const transformedStream = await deviceManager.video.updateBackgroundFilter(videoFilter, videoCustomFilter);
            setVideoTransformedStream(transformedStream ?? null);
        });
    }, [videoFilter, videoCustomFilter, deviceManager, videoProcessorChangeEnqueue]);

    const initDevices = useCallback(async () => {
        const result = await deviceManager.getDevices(true);

        if (optionsRef.current.started === false) {
            return;
        }

        if (isNotAllowedError(result.error)) {
            setAudioEnabled(false);
            setVideoEnabled(false);
        }

        setDevices(result.devices);
        setIsVoiceFocusSupported(shouldEnableWebAudio());
        setLoading(false);
    }, [deviceManager, setVideoEnabled]);

    const updateDevices = useCallback(async () => {
        const result = await deviceManager.getDevices(false);

        if (optionsRef.current.started === false) {
            return;
        }

        if (result.error == null) {
            setDevices(result.devices);
        }
    }, [deviceManager]);

    useEffect(() => {
        if (!started || navigator.mediaDevices == null) {
            return;
        }

        initDevices();

        const handleMissingLabels = () => {
            const currentDevices = optionsRef.current.devices;

            if (!areDummyDevices(currentDevices)) {
                return;
            }

            updateDevices();
        };

        const onAudioSuccess = () => {
            setAudioError(null);
            handleMissingLabels();
        };

        const onVideoSuccess = () => {
            setVideoError(null);
            handleMissingLabels();
        };

        const onAudioError = (error: unknown) => {
            setAudioError(error);

            if (isUnreadableDeviceError(error)) {
                handleMissingLabels();
            }
        };

        const onVideoError = (error: unknown) => {
            setVideoError(error);

            if (isUnreadableDeviceError(error)) {
                handleMissingLabels();
            }
        };

        navigator.mediaDevices.addEventListener("devicechange", updateDevices);
        deviceManager.events.on("audio-success", onAudioSuccess);
        deviceManager.events.on("video-success", onVideoSuccess);
        deviceManager.events.on("audio-error", onAudioError);
        deviceManager.events.on("video-error", onVideoError);

        return () => {
            navigator.mediaDevices.removeEventListener("devicechange", updateDevices);
            deviceManager.events.off("audio-success", onAudioSuccess);
            deviceManager.events.off("video-success", onVideoSuccess);
            deviceManager.events.off("audio-error", onAudioError);
            deviceManager.events.off("video-error", onVideoError);
        };
    }, [started, deviceManager, initDevices, updateDevices]);

    useEffect(() => {
        if (!started || !audioEnabled) {
            setNoAudioForProlongedTime(false);
            return;
        }

        const scheduleTimeout = () => {
            return setTimeout(() => {
                setNoAudioForProlongedTime(true);
            }, 30 * 1000);
        };

        let timeout = scheduleTimeout();

        const handleAudioDetected = () => {
            clearTimeout(timeout);
            timeout = scheduleTimeout();
            setNoAudioForProlongedTime(false);
        };

        deviceManager.events.on("audio-detected", handleAudioDetected);

        return () => {
            clearTimeout(timeout);
            deviceManager.events.off("audio-detected", handleAudioDetected);
        };
    }, [started, audioEnabled, deviceManager]);

    const devicesInfo = useMemo(() => {
        const audioInputs = getMicrophones(devices);
        const videoInputs = getCameras(devices);
        const audioOutputs = getSpeakers(devices);
        return {
            audioInputs,
            videoInputs,
            audioOutputs,
            hasAudioInput: audioInputs.length > 0,
            hasAudioOutput: audioOutputs.length > 0,
            hasVideoInput: videoInputs.length > 0,
            dummyDevices: areDummyDevices(devices),
        };
    }, [devices]);

    const getCurrentAudioDevice = useCallback(() => {
        const wanted = getDeviceIdFromConstraint(audioConstraints);
        return deviceManager.findAudioDeviceCandidate(devices, wanted ?? undefined)?.deviceId;
    }, [audioConstraints, devices, deviceManager]);

    const getCurrentVideoDevice = useCallback(() => {
        const wanted = getDeviceIdFromConstraint(videoConstraints);
        return deviceManager.findVideoDeviceCandidate(devices, wanted ?? undefined)?.deviceId;
    }, [videoConstraints, devices, deviceManager]);

    const getCurrentSpeakerDevice = useCallback(() => {
        if (!speakerDevice) {
            return devicesInfo.audioOutputs[0]?.deviceId;
        }

        const existing = devicesInfo.audioOutputs.find((device) => device.deviceId === speakerDevice)?.deviceId;

        if (!existing) {
            return devicesInfo.audioOutputs[0]?.deviceId;
        }

        return existing;
    }, [speakerDevice, devicesInfo.audioOutputs]);

    const handleSetVideoFilter = useCallback(
        (filter: string) => {
            if (isBackgroundFilterValue(filter)) {
                setVideoFilter(filter);
                setVideoCustomFilter(null);
            } else {
                setVideoFilter(BackgroundFilter.CUSTOM);
                setVideoCustomFilter(filter);
            }
        },
        [setVideoFilter, setVideoCustomFilter]
    );

    return useMemo(
        () => ({
            ...devicesInfo,
            ready: started && !loading,
            started,
            loading,
            devices,
            deviceManager,
            audioStream,
            audioError,
            videoStream,
            videoError,
            noiseReduction,
            noiseSuppressedStream,
            isVoiceFocusSupported,
            videoFilter,
            videoCustomFilter,
            videoFilterModel,
            videoTransformedStream,
            isVideoFilterSupported,
            audioEnabled,
            videoEnabled,
            audioMuted,
            canSetSpeaker,
            usingFallbackAudio,
            noAudioForProlongedTime,
            videoConstraints,
            audioConstraints,
            start,
            stop,
            initDevices,
            updateDevices,
            setAudioEnabled,
            setVideoEnabled,
            toggleAudio,
            toggleVideo,
            setAudioConstraints,
            setVideoConstraints,
            setNoiseReduction,
            setVideoFilter: handleSetVideoFilter,
            setVideoFilterModel,
            setAudioMuted,
            setSpeakerDevice,
            getCurrentAudioDevice,
            getCurrentVideoDevice,
            getCurrentSpeakerDevice,
            setUsingFallbackAudio,
        }),
        [
            started,
            loading,
            devices,
            devicesInfo,
            deviceManager,
            audioStream,
            audioError,
            videoStream,
            videoError,
            noiseReduction,
            noiseSuppressedStream,
            isVoiceFocusSupported,
            videoFilter,
            videoCustomFilter,
            videoFilterModel,
            videoTransformedStream,
            isVideoFilterSupported,
            audioEnabled,
            videoEnabled,
            audioMuted,
            canSetSpeaker,
            usingFallbackAudio,
            noAudioForProlongedTime,
            start,
            stop,
            initDevices,
            updateDevices,
            toggleAudio,
            toggleVideo,
            setAudioEnabled,
            setVideoEnabled,
            setAudioConstraints,
            setVideoConstraints,
            setNoiseReduction,
            handleSetVideoFilter,
            setVideoFilterModel,
            setAudioMuted,
            setSpeakerDevice,
            getCurrentAudioDevice,
            getCurrentVideoDevice,
            getCurrentSpeakerDevice,
            setUsingFallbackAudio,
        ]
    );
}

export const [DevicesProvider, useDevices] = createContextProvider(
    {
        name: "Devices",
    },
    useDevicesStore
);

export function useStreamSettings(stream?: MediaStream | null) {
    return useMemo(() => getStreamSettings(stream), [stream]);
}

interface DeviceEventsOptions {
    listen?: boolean;
    whenReady?: boolean;

    onInitSuccess?: () => void;
    onInitError?: (e: unknown) => void;
    onInitNotAllowedError?: (err: DOMException) => void;
    onInitUnreadableError?: (err: DOMException) => void;

    onAudioSuccess?: () => void;
    onAudioRemoved?: () => void;
    onAudioEnded?: () => void;

    onAudioError?: (err: unknown) => void;
    onAudioNotAllowedError?: (err: DOMException) => void;
    onAudioUnreadableError?: (err: DOMException) => void;

    onVideoSuccess?: () => void;
    onVideoRemoved?: () => void;
    onVideoEnded?: () => void;

    onVideoError?: (err: unknown) => void;
    onVideoNotAllowedError?: (err: DOMException) => void;
    onVideoUnreadableError?: (err: DOMException) => void;

    onAudioDetected?: () => void;
    onAudioVolume?: (volume: number) => void;

    onBackgroundFilterError?: (err: unknown) => void;
}

export function useDeviceManagerEvents(options: DeviceEventsOptions) {
    const {ready, deviceManager} = useDevices();

    const listen = options.listen !== false && (options.whenReady !== true ? true : ready);

    const optionsRef = useRef(options);
    optionsRef.current = options;

    useEffect(() => {
        if (!listen) {
            return;
        }

        const onInitSuccess = () => {
            optionsRef.current.onInitSuccess?.();
        };

        const onInitError = (err: unknown) => {
            optionsRef.current.onInitError?.(err);

            if (isUnreadableDeviceError(err)) {
                optionsRef.current.onInitUnreadableError?.(err);
            }

            if (isNotAllowedError(err)) {
                optionsRef.current.onInitNotAllowedError?.(err);
            }
        };

        const onAudioSuccess = () => {
            optionsRef.current.onAudioSuccess?.();
        };

        const onAudioRemoved = () => {
            optionsRef.current.onAudioRemoved?.();
        };

        const onAudioEnded = () => {
            optionsRef.current.onAudioEnded?.();
        };

        const onAudioError = (err: unknown) => {
            optionsRef.current.onAudioError?.(err);

            if (isUnreadableDeviceError(err)) {
                optionsRef.current.onAudioUnreadableError?.(err);
            }

            if (isNotAllowedError(err)) {
                optionsRef.current.onAudioNotAllowedError?.(err);
            }
        };

        const onVideoSuccess = () => {
            optionsRef.current.onVideoSuccess?.();
        };

        const onVideoRemoved = () => {
            optionsRef.current.onVideoRemoved?.();
        };

        const onVideoEnded = () => {
            optionsRef.current.onVideoEnded?.();
        };

        const onVideoError = (err: unknown) => {
            optionsRef.current.onVideoError?.(err);

            if (isUnreadableDeviceError(err)) {
                optionsRef.current.onVideoUnreadableError?.(err);
            }

            if (isNotAllowedError(err)) {
                optionsRef.current.onVideoNotAllowedError?.(err);
            }
        };

        const onAudioDetected = () => {
            optionsRef.current.onAudioDetected?.();
        };

        const onAudioVolume = (volume: number) => {
            optionsRef.current.onAudioVolume?.(volume);
        };

        const onBackgroundFilterError = (err: unknown) => {
            optionsRef.current.onBackgroundFilterError?.(err);
        };

        deviceManager.events.addListener("devices-init-success", onInitSuccess);
        deviceManager.events.addListener("devices-init-error", onInitError);
        deviceManager.events.addListener("audio-success", onAudioSuccess);
        deviceManager.events.addListener("audio-removed", onAudioRemoved);
        deviceManager.events.addListener("audio-ended", onAudioEnded);
        deviceManager.events.addListener("audio-error", onAudioError);
        deviceManager.events.addListener("audio-detected", onAudioDetected);
        deviceManager.events.addListener("audio-volume", onAudioVolume);
        deviceManager.events.addListener("video-success", onVideoSuccess);
        deviceManager.events.addListener("video-removed", onVideoRemoved);
        deviceManager.events.addListener("video-ended", onVideoEnded);
        deviceManager.events.addListener("video-error", onVideoError);
        deviceManager.events.addListener("background-filter-error", onBackgroundFilterError);

        return () => {
            deviceManager.events.removeListener("devices-init-success", onInitSuccess);
            deviceManager.events.removeListener("devices-init-error", onInitError);
            deviceManager.events.removeListener("audio-success", onAudioSuccess);
            deviceManager.events.removeListener("audio-removed", onAudioRemoved);
            deviceManager.events.removeListener("audio-ended", onAudioEnded);
            deviceManager.events.removeListener("audio-error", onAudioError);
            deviceManager.events.removeListener("audio-detected", onAudioDetected);
            deviceManager.events.removeListener("audio-volume", onAudioVolume);
            deviceManager.events.removeListener("video-success", onVideoSuccess);
            deviceManager.events.removeListener("video-removed", onVideoRemoved);
            deviceManager.events.removeListener("video-ended", onVideoEnded);
            deviceManager.events.removeListener("video-error", onVideoError);
            deviceManager.events.removeListener("background-filter-error", onBackgroundFilterError);
        };
    }, [listen, deviceManager]);
}
