export type PersonaUseCase = "personal" | "team";

export const industryOptions = [
    {value: "coaching", text: "Coaching"},
    {value: "education", text: "Education"},
    {value: "finance", text: "Finance"},
    {value: "hr", text: "HR & Culture"},
    {value: "marketing", text: "Marketing"},
    {value: "engineering", text: "Engineering"},
    {value: "customer-success", text: "Customer Success"},
    {value: "product-design", text: "Product Design"},
    {value: "sales", text: "Sales"},
    {value: "other", text: "Other"},
];

export const meetingTypesOptions = [
    {value: "meetings", text: "Team meetings"},
    {value: "workshops", text: "Workshops"},
    {value: "events", text: "Events"},
    {value: "demos", text: "Demos"},
    {value: "interviews", text: "Interviews"},
    {value: "trainings", text: "Trainings"},
    {value: "webinars", text: "Webinars"},
    {value: "other", text: "Other"},
];

export const roleOptions = [
    {value: "coach", text: "Coach"},
    {value: "trainer", text: "Trainer"},
    {value: "consultant", text: "Consultant"},
    {value: "freelancer", text: "Freelancer"},
    {value: "executive", text: "Executive (C-level / VP)"},
    {value: "startup-founder", text: "Start-up Founder"},
    {value: "manager", text: "Team Manager"},
    {value: "other", text: "Other"},
];

export const getSavedOnboardingStep = (userId: string): number | null => {
    const savedOnboardingStep = sessionStorage.getItem(`onboarding-persona-${userId}`) ?? "";

    const numberValue = parseInt(savedOnboardingStep);

    if (!Number.isNaN(numberValue)) {
        return numberValue;
    } else {
        return null;
    }
};
