import {AvatarProps} from "@ui/cdk/Avatar/types";

export enum SessionsAvatarIconRight {
    ONLINE,
    ACCEPTED,
    DECLINED,
    TENTATIVE,
}

export enum SessionsAvatarIconLeft {
    OWNER,
    ASSISTANT,
}

export type SessionsAvatarProps = {
    iconLeft?: SessionsAvatarIconLeft;
    iconRight?: SessionsAvatarIconRight;
} & Omit<AvatarProps, "iconRight" | "iconLeft">;
