import {SpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {getResourcesTextsAndTypes} from "../../utils/auxiliary";

export function getNewResxButtonTitle(resxType: string, isFilesSection?: boolean, isGoogleSynced?: boolean) {
    const actionText = isFilesSection ? "Upload" : "New";
    let resxTypeText = resxType.replace(" by Sessions", "");
    let fileText = "file";

    if (resxType.toLowerCase().includes("google")) {
        if (isGoogleSynced !== undefined && !isGoogleSynced) {
            return "Sync account";
        }
    }

    if (isFilesSection) {
        switch (resxTypeText) {
            case "PDFs": {
                resxTypeText = "PDF";
                fileText = "";
                break;
            }
            case "Keynotes":
            case "Keynote": {
                resxTypeText = "presentation";
                fileText = "";
                break;
            }
            case "Numbers": {
                resxTypeText = "spreadsheet";
                fileText = "";
                break;
            }
            default: {
                resxTypeText = (resxTypeText.endsWith("s") ? resxTypeText.slice(0, -1) : resxTypeText).toLowerCase();
                fileText = "";
                break;
            }
        }
    } else {
        if (resxTypeText) {
            resxTypeText = resxTypeText.endsWith("s") ? resxTypeText.slice(0, -1) : resxTypeText;
            fileText = "";
        }

        if (resxTypeText === "Youtube" || resxTypeText === "Vimeo") {
            fileText = "video";
        }

        if (resxTypeText === "whiteboard") {
            resxTypeText = "Whiteboard";
        }
    }

    return `${actionText} ${resxTypeText} ${fileText}`.trim();
}

export function getResxEmptyStateButtonTitle(resxType: keyof SpecifiersTypeMap, isFilesSection?: boolean) {
    switch (resxType) {
        case "flowos/adobexd/resx/AdobeXD":
        case "flowos/figma/resx/Figma":
        case "flowos/canva/resx/Canva": {
            return {text: "Get plugin", externalLink: true};
        }
        default: {
            const {resourceType, noResourcesTitle, noResourcesBody} = getResourcesTextsAndTypes(resxType);

            return {text: getNewResxButtonTitle(resourceType, isFilesSection), externalLink: false};
        }
    }
}
