import {forwardRef} from "@workhorse/api/rendering";
import {MenuItemProps} from "./types";
import {default as MuiMenuItem} from "@material-ui/core/MenuItem";
import {capitalize, cls} from "../util";
import styles from "./style/MenuItem.module.scss";
import CircularProgress from "@ui/core/components/CircularProgress";

const MenuItem = forwardRef((props: MenuItemProps, ref?: any) => {
    const {
        /**
         * @param {MenuItemVariantTypes} variant The variant to use. @default "secondary"
         */
        variant = "secondary",
        loading = false,
        horizontal = false,
        noDivider,
        ...rest
    } = props;

    return [
        variant === "destructive" && !noDivider ? (
            <div className={cls("fosMenuItemSeparator", styles.separatorSingle, horizontal && styles.separatorHorizontal)} key="fos-sep1" />
        ) : null,
        <MuiMenuItem
            {...rest}
            ref={ref}
            key="menu-item"
            className={cls("fosMenuItem", rest.className, styles.menuItem, styles[`menuItem${capitalize(variant)}`])}
        >
            {loading && <CircularProgress size="20px" thickness={5} />}

            {rest.children}
        </MuiMenuItem>,
        !noDivider ? <div className={cls("fosMenuItemSeparator", styles.separator)} key="fos-sep2" /> : null,
        variant === "primary" ? (
            <div className={cls("fosMenuItemSeparator", styles.separatorSingle, horizontal && styles.separatorHorizontal)} key="fos-sep3" />
        ) : null,
    ].filter((el) => el != null);
});

MenuItem.displayName = "MenuItem";

export default MenuItem;
