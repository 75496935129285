import {EditorArtifactElement} from ".";
import {Editor, EditorAlignment, EditorTextFormatting, EDITOR_ARTIFACTS, SessionsEditor} from "./definitions";

/**
 * @description Checks to see if formatting mark is active
 *
 * @param editor Editor
 * @param format `EditorTextFormatting` formatting mark
 * @returns `boolean` true if formatting mark is active, false otherwise
 */
export const isMarkActive = (editor: Editor, format: EditorTextFormatting): boolean => {
    return editor.isActive(format);
};

/**
 * @description Checks to see if a formatting mark is active
 *
 * @param editor `Editor`
 * @param format `EditorTextFormatting` formatting mark
 */
export const toggleMark = (editor: SessionsEditor, format: EditorTextFormatting) => {
    if (format === "underline") {
        return editor.chain().focus().toggleUnderline().run();
    }
    return editor.chain().focus().toggleMark(format).run();
};

/**
 * @description Changes the current block alignment to the wanted alignment
 *
 * @param editor `Editor`
 * @param alignment `EditorAlignment` the alignment to change to
 */
export const toggleAlignment = (editor: SessionsEditor, alignment: EditorAlignment) => {
    editor.chain().focus().setTextAlign(alignment).run();
};

export const isArtifactElement = (editor: Editor) => {
    const activeBlockType = "";

    return EDITOR_ARTIFACTS.includes(activeBlockType as EditorArtifactElement);
};
