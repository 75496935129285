import {useCallback, useMemo} from "@workhorse/api/rendering";
import env from "@generated/environment";
import {useLoginPopup, UseLoginPopupMessage} from "./login-popup";
import {useMutation} from "@workhorse/dataApi";

export type UsedNotionLoginProps = {
    onLogin?: () => void;
    onLoginFailure?: (message: UseLoginPopupMessage) => void;
    onLogout?: () => void;
    onLogoutFailure?: () => void;
};

export const useNotionLogin = (props: UsedNotionLoginProps) => {
    const {onLogin, onLoginFailure, onLogout, onLogoutFailure} = props;

    const requestLogin = useLoginPopup({eventName: "notion-login", onLogin, onLoginFailure});

    const [removeToken] = useMutation("DeleteUserNotionProvidersDocument");

    const request = useCallback(() => {
        const redirectUri = `${env.authService.appRoot}/user/notion/oauth2callback`;
        const url = `https://api.notion.com/v1/oauth/authorize?owner=user&client_id=${
            env.notion.clientId
        }&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}`;

        requestLogin(url);
    }, [requestLogin]);

    const disconnect = useCallback(() => {
        removeToken()
            .then((response) => {
                if (response.data?.deleteUserNotionProviders) {
                    onLogout?.();
                }
            })
            .catch(() => {
                onLogoutFailure?.();
            });
    }, [onLogout, onLogoutFailure, removeToken]);

    return useMemo(() => [request, disconnect], [request, disconnect]);
};
