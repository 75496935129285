import {safelyParseJSON} from "@common/utils";
import {StorageContext, useContextualizedStorage} from "@workhorse/providers/StorageProvider";
import {useCallback, useEffect, useState} from "./rendering";
import {isFn} from "@common/utils/isfn";

type UseStorageProps<TValue> = {
    key: string;
    defaultValue: TValue;
    ctx?: StorageContext;
    listen?: boolean;
};

type CB<TValue> = (oldValue: TValue) => TValue;

type UseStorageResult<TValue> = [TValue, React.Dispatch<React.SetStateAction<TValue>>, () => void];

export function useLocalStorage<TValue>({key, defaultValue, ctx, listen = false}: UseStorageProps<TValue>): UseStorageResult<TValue> {
    const storage = useContextualizedStorage(ctx);

    const [value, setValue] = useState<TValue>(() =>
        storage.getContextualizedItem("localStorage", storage.contextualizeKey(key, ctx), defaultValue)
    );

    const clear = useCallback(() => {
        storage.removeContextualizedItem("localStorage", storage.contextualizeKey(key, ctx));
        setValue(defaultValue);
    }, [key, ctx]);

    const update = useCallback(
        (value: TValue | CB<TValue>) => {
            setValue((oldValue) => {
                const val = isFn(value) ? value(oldValue) : value;

                storage.setContextualizedItem("localStorage", storage.contextualizeKey(key, ctx), val);

                return val;
            });
        },
        [key, ctx]
    );

    useEffect(() => {
        setValue(() => storage.getContextualizedItem("localStorage", storage.contextualizeKey(key, ctx), defaultValue));
    }, [key, ctx]);

    useEffect(() => {
        if (!listen) {
            return;
        }

        const listener = (event: StorageEvent) => {
            if (event.key === storage.contextualizeKey(key, ctx) && event.newValue && event.storageArea === localStorage) {
                setValue(safelyParseJSON(event.newValue, defaultValue));
            }
        };

        window.addEventListener("storage", listener);

        return () => window.removeEventListener("storage", listener);
    }, [listen, ctx]);

    return [value, update, clear];
}

export function useSessionStorage<TValue>({key, defaultValue, ctx, listen = false}: UseStorageProps<TValue>): UseStorageResult<TValue> {
    const storage = useContextualizedStorage(ctx);

    const [value, setValue] = useState<TValue>(() =>
        storage.getContextualizedItem("sessionStorage", storage.contextualizeKey(key, ctx), defaultValue)
    );

    const clear = useCallback(() => {
        storage.removeContextualizedItem("sessionStorage", storage.contextualizeKey(key, ctx));
        setValue(defaultValue);
    }, [key, ctx]);

    const update = useCallback(
        (value: TValue | CB<TValue>) => {
            setValue((oldValue) => {
                const val = isFn(value) ? value(oldValue) : value;

                storage.setContextualizedItem("sessionStorage", storage.contextualizeKey(key, ctx), val);

                return val;
            });
        },
        [key, ctx]
    );

    useEffect(() => {
        setValue(() => storage.getContextualizedItem("sessionStorage", storage.contextualizeKey(key, ctx), defaultValue));
    }, [key, ctx]);

    useEffect(() => {
        if (!listen) {
            return;
        }

        const listener = (event: StorageEvent) => {
            if (event.key === storage.contextualizeKey(key, ctx) && event.newValue && event.storageArea === sessionStorage) {
                setValue(safelyParseJSON(event.newValue, defaultValue));
            }
        };

        window.addEventListener("storage", listener);

        return () => window.removeEventListener("storage", listener);
    }, [listen, ctx]);

    return [value, update, clear];
}
