import {ContactGroupSource} from "@generated/data";

export type ContactsTableViewMode = "all" | "individual-group" | "removed" | "favorite" | "external" | "workspace";

export type ContactsConnection = {
    provider: ContactGroupSource;
    email: string;
    name: string;
};

export enum SessionsOrderBy {
    START_DATE,
    END_DATE,
    PARTICIPANTS_COUNT,
    DURATION,
}

export enum DummyGroupNames {
    FAVORITES = "Favorites",
    SESSIONS = "Sessions",
    BIN = "Trash",
    ALL = "All contacts",
}

export enum ContactsLocalStorageParams {
    groupId = "contacts_group_id",
    contactId = "contacts_contact_id",
    searchTerms = "contacts_search_terms",
}
