import {ConnectionQuality, LocalParticipant, LocalTrackPublication, RemoteParticipant, Track, TrackPublication} from "livekit-client";

export type RemoteStatus = {
    connected: boolean;
    cameraOn: boolean;
    micOn: boolean;
    isSharing: boolean;
    connectionQuality: ConnectionQuality;
    cameraPaused: boolean;
};

export type RemoteStatusMap = {
    [K in string]?: RemoteStatus;
};

export function createDefaultRemoteStatus(): RemoteStatus {
    return {
        cameraOn: false,
        connected: false,
        isSharing: false,
        micOn: false,
        connectionQuality: ConnectionQuality.Unknown,
        cameraPaused: false,
    };
}

function updateStatusBySource(data: RemoteStatus, source: Track.Source, value: boolean) {
    const copy = {...data};

    if (source === Track.Source.Camera) {
        copy.cameraOn = value;
    }

    if (source === Track.Source.Microphone) {
        copy.micOn = value;
    }

    if (source === Track.Source.ScreenShare) {
        copy.isSharing = value;
    }

    return copy;
}

function getPublicationRemoteStatus(pub: TrackPublication) {
    const source = pub.source;
    const isLocal = pub instanceof LocalTrackPublication;

    if (source === Track.Source.Camera) {
        return !pub.isMuted;
    }

    /**
     * NOTE: we're not subscribed yet at this point
     * it's safe to skip checking pub.isSubscribed
     * given we ultimately do subscribe to all audio publications
     */

    if (source === Track.Source.Microphone) {
        return !pub.isMuted;
    }

    if (source === Track.Source.ScreenShare) {
        return isLocal ? pub.track != null : !pub.isMuted;
    }

    return false;
}

export function createParticipantRemoteStatus(current: RemoteStatus | undefined, pub: TrackPublication, pauseIncomingCamera: boolean) {
    const existing = current ?? createDefaultRemoteStatus();
    const status = getPublicationRemoteStatus(pub);
    existing.cameraPaused = pauseIncomingCamera;
    return updateStatusBySource(existing, pub.source, status);
}

export function removeParticipantRemoteStatus(current: RemoteStatus | undefined, pub: TrackPublication) {
    const existing = current ?? createDefaultRemoteStatus();
    return updateStatusBySource(existing, pub.source, false);
}

export function initializeParticipantRemoteStatus(
    participant: RemoteParticipant | LocalParticipant,
    incomingVideosDisabled: boolean
): RemoteStatus {
    let data = createDefaultRemoteStatus();
    const publications = participant.getTrackPublications();

    data.connected = true;
    data.connectionQuality = participant.connectionQuality;
    if (!(participant instanceof LocalParticipant)) {
        data.cameraPaused = incomingVideosDisabled ?? false;
    }

    for (const publication of publications) {
        const source = publication.track?.source;

        if (!source) {
            continue;
        }

        const status = getPublicationRemoteStatus(publication);
        data = updateStatusBySource(data, source, status);
    }

    return data;
}

export function updateRemoteStatusMapById(map: RemoteStatusMap, id: string, data: Partial<RemoteStatus>) {
    const current = map[id] ?? createDefaultRemoteStatus();

    const result = {
        ...current,
        ...data,
    };

    return {
        ...map,
        [id]: result,
    };
}
