import {useMutation} from "@apollo/client";
import {EditOrganizationMembersDocument, OrganizationPermissionType} from "@generated/data";
import {FormControlLabel} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Checkbox from "@ui/cdk/Checkbox";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {getResourcesBaseLink} from "@workhorse/api/help/article";
import {useEffect} from "@workhorse/api/rendering";
import {useState} from "@workhorse/api/state";
import toast from "@workhorse/api/toast";
import {HyperlinkExternal} from "@workhorse/util/links";
import classes from "../WorkspaceUserManagementRole/UserManagementRoleSelect.module.scss";

interface Props {
    className?: string;
    value?: OrganizationPermissionType;
    onChange: (value: OrganizationPermissionType) => void;
    disabled?: boolean;
}

export function OrganizationUserManagementRoleCheckbox(props: Props) {
    const {value, disabled = false, onChange, className} = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChange(checked ? OrganizationPermissionType.Owner : OrganizationPermissionType.Member);
    };

    const label = (
        <div className="flex flex-align-center">
            <Typography color="secondary" fontWeight="bold">
                Organization admin
            </Typography>
            <Tooltip
                title={
                    <div className="flex flex-col pb-8">
                        <Typography className="mb-8">
                            By selecting this option, the new member will be granted full control over the organization's settings.
                        </Typography>

                        <HyperlinkExternal
                            href={getResourcesBaseLink("resources/help-center/organizations")}
                            target="_blank"
                            key="organization-article-url"
                            rel="noopener noreferrer"
                            className={classes.tooltipLink}
                        >
                            Learn more
                        </HyperlinkExternal>
                    </div>
                }
                arrow
                interactive
                placement="top-start"
            >
                <InfoOutlinedIcon className={classes.tooltipIcon} />
            </Tooltip>
        </div>
    );

    return (
        <FormControlLabel
            data-id="role-select"
            control={<Checkbox />}
            label={label}
            labelPlacement="end"
            checked={value === OrganizationPermissionType.Owner}
            onChange={handleChange}
            className={cls(classes.checkbox, className)}
            disabled={disabled}
        />
    );
}

interface WithUpdateProps {
    className?: string;
    userId: string;
    userOrganizationRole: OrganizationPermissionType;
    organizationId: string;
    disabled?: boolean;
}

export function OrganizationUserManagementRoleCheckboxWithUpdate(props: WithUpdateProps) {
    const [value, setValue] = useState(props.userOrganizationRole);

    const [editMembers] = useMutation(EditOrganizationMembersDocument, {
        onError: (err) => {
            setValue(props.userOrganizationRole);
            toast(err.message, {
                type: "error",
            });
        },
    });

    useEffect(() => {
        setValue(props.userOrganizationRole);
    }, [props.userOrganizationRole]);

    const handleChange = (value: OrganizationPermissionType) => {
        console.log("updating with value", value);
        if (value === props.userOrganizationRole) {
            return;
        }
        setValue(value);
        editMembers({
            variables: {
                membersToEdit: {userId: props.userId, role: value},
            },
        });
    };

    return <OrganizationUserManagementRoleCheckbox disabled={props.disabled} value={value} onChange={handleChange} />;
}
