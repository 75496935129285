import {HostType, SessionBrandingDocument} from "@generated/data";
import {useLazyQuery} from "@workhorse/api/data";
import {useEffect} from "@workhorse/api/rendering";
import {readRemoteUser} from "@workhorse/api/user";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {removeBranding, setBranding} from "./utils";

export const sessionBrandingHostTypes = [HostType.Player, HostType.Waiting, HostType.Sessionended, HostType.Feedback, HostType.Memory];

export const setDefaultBranding = () => {
    const remoteUserData = readRemoteUser();

    const branding = remoteUserData?.getRemoteUser?.user?.workspacePermissions.find((w) => w.activeWorkspace)?.workspace?.branding;

    if (branding?.active) {
        setBranding(branding);

        return;
    }

    removeBranding();
};

const useBranding = (params: {sessionId?: string; isOwnedByCurrentUser?: boolean; isSameWorkspace?: boolean; sessionExists?: boolean}) => {
    const {sessionId, isOwnedByCurrentUser, sessionExists, isSameWorkspace} = params;
    const hostType = useHostType();

    const [getSessionBranding] = useLazyQuery(SessionBrandingDocument, {
        onCompleted: (data) => {
            if (data.sessionBranding?.active) {
                setBranding(data.sessionBranding);
            }
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
    });

    const route = window.location.pathname;
    const allowedHostType = sessionBrandingHostTypes.includes(hostType) || (hostType === HostType.User && route.startsWith("/user/invite"));

    const alreadyHasBranding = isOwnedByCurrentUser && isSameWorkspace;

    const shouldUseSessionBranding = allowedHostType && !alreadyHasBranding && sessionId && !(sessionExists === false);

    useEffect(() => {
        if (shouldUseSessionBranding) {
            getSessionBranding({
                variables: {
                    sessionId,
                },
            });
        } else {
            setDefaultBranding();
        }
    }, [shouldUseSessionBranding]);

    return null;
};

export default useBranding;
