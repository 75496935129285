import apollo from "@workhorse/api/apollo";
import {CheckUrlDocument, GetWebsiteTitleDocument} from "@generated/data";

export const iframeUrlRegex =
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

type CheckReturnType = {
    link: string;
    error: string | null;
};

export const extractLink = (linkToExtract: string): CheckReturnType => {
    if (linkToExtract) {
        const parser = new DOMParser();
        const document = parser.parseFromString(linkToExtract, "text/html");
        const iframeDoc = document.querySelector("iframe");

        if (iframeDoc?.src) {
            const isUrlAllowed = checkAllowedUrl(iframeDoc.src);
            if (!isUrlAllowed) {
                return {link: "error", error: "* Please add a valid https link!"};
            } else {
                return {link: iframeDoc?.src, error: null};
            }
        } else {
            return {link: "error", error: "* Please add a valid https link!"};
        }
    } else {
        return {link: "error", error: "* Please add a valid https link!"};
    }
};

export const isHTMLTag = (textToCheck: string): boolean => {
    const check = textToCheck.trim().startsWith("<iframe") && textToCheck.trim().endsWith(">");
    return check;
};

export const formatLinkHttps = (url: string) => {
    const linkToFormat = url.trim();

    if (linkToFormat.startsWith("https://")) {
        return linkToFormat;
    } else {
        if (linkToFormat.startsWith("http://")) {
            return linkToFormat.replace("http://", "https://");
        } else {
            return `https://${linkToFormat}`;
        }
    }
};

export const checkAllowedUrl = (url: string) => {
    const isSessionUrl = url.includes(window.location.host);
    const isSessionBookUrl = url.includes(window.location.host + "/book/");

    return !isSessionUrl || (isSessionUrl && isSessionBookUrl);
};

export const processLink = (linkToProcess: string) => {
    const iframeLink =
        linkToProcess?.trim().toUpperCase() === linkToProcess.trim() ? linkToProcess?.trim().toLowerCase() : linkToProcess.trim();

    if (!isHTMLTag(iframeLink)) {
        const isUrlAllowed = checkAllowedUrl(iframeLink);
        if (!isUrlAllowed) {
            return "error";
        }
        return formatLinkHttps(iframeLink);
    } else {
        const extractedLink = extractLink(iframeLink).link;
        return formatLinkHttps(extractedLink);
    }
};

export const checkIfIframeable = async (linkToCheck: string) => {
    const isUrlValid = await apollo.client.query({
        query: CheckUrlDocument,
        variables: {
            url: linkToCheck,
        },
    });

    const isValid = (await isUrlValid.data.iframeCheckUrl?.isFrameable) && iframeUrlRegex.test(linkToCheck.toString());
    const reason = await isUrlValid.data.iframeCheckUrl?.reason;

    if (!isValid) {
        switch (reason) {
            default: {
                return "* Please add a valid https link!";
            }
            case "invalid": {
                return "* Please add a valid https link!";
            }
            case "notHttps": {
                return "* Please add a valid https link!";
            }
            case "policy": {
                return "* The website's policies prevent it from being embedded.";
            }
        }
    }

    return null;
};

export const getWebsiteTitle = async (url: string) => {
    const res = await apollo.client.query({
        query: GetWebsiteTitleDocument,
        variables: {
            url: url,
        },
    });

    if (!res.data.getWebsiteTitle) {
        return "Website title";
    }

    return res.data.getWebsiteTitle;
};
