import type {PaletteItemAgenda, PaletteItemBase, PaletteItemFile, PaletteItemTool, PaletteSearchInput} from "@generated/data";
import {useMemo, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";

export type CommandPaletteSearchResults = {
    tools: PaletteItemTool[];
    files: PaletteItemFile[];
    recent: PaletteItemBase[];
    agendas: PaletteItemAgenda[];
};

function usePaletteSearchResultsStore() {
    const [paletteResults, setPaletteResults] = useState<CommandPaletteSearchResults>({
        tools: [],
        files: [],
        recent: [],
        agendas: [],
    });

    return useMemo(
        () => ({
            paletteResults,
            setPaletteResults,
        }),
        [paletteResults]
    );
}

export const [CommandPaletteResultsProvider, usePaletteResults] = createContextProvider(
    {
        name: "CommandPaletteResultsProvider",
        strict: true,
    },
    usePaletteSearchResultsStore
);

export type PaletteVisibleItems = {
    isCommand: boolean;
    items: string[];
};

function usePaletteHiddenItemsStore() {
    const [visibleItems, setVisibleItems] = useState<PaletteVisibleItems>({
        isCommand: false,
        items: [],
    });

    return useMemo(
        () => ({
            visibleItems,
            setVisibleItems,
        }),
        [visibleItems]
    );
}

export const [CommandPaletteVisibleCategItemsProvider, useVisibleCategItems] = createContextProvider(
    {
        name: "CommandPaletteVisibleCategItemsProvider",
        strict: true,
    },
    usePaletteHiddenItemsStore
);

function usePaletteSearchFiltersStore() {
    const [searchFilters, setSearchFilters] = useState<PaletteSearchInput>({});

    return useMemo(
        () => ({
            searchFilters,
            setSearchFilters,
        }),
        [searchFilters]
    );
}

export const [CommandPaletteSearchFiltersProvider, usePaletteSearchFilters] = createContextProvider(
    {
        name: "CommandPaletteSearchFiltersProvider",
        strict: true,
    },
    usePaletteSearchFiltersStore
);

export type PaletteResultList = Array<keyof CommandPaletteSearchResults>;

function usePaletteResultListStore() {
    const [resultList, setResultList] = useState<PaletteResultList>([]);

    return useMemo(
        () => ({
            resultList,
            setResultList,
        }),
        [resultList]
    );
}

export const [CommandPaletteResultListProvider, usePaletteResultList] = createContextProvider(
    {
        name: "CommandPaletteResultListProvider",
        strict: true,
    },
    usePaletteResultListStore
);
