import {FileRef} from "@api/resx/ref";
import svc from "@api/service/client";
import {ResourcesTypes} from "@generated/artifacts/resources";
import {VideoV1} from "@sessions/common/resources";
import {FileMimeType} from "@sessions/common/various";
import {getFileExtension} from "@workhorse/api/file_explorer/fileExplorer";
import React from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {ProcessingResx} from "@workhorse/pages/resources/components/ResxFileProgressPreview";
import {ResourceRowExtra} from "../../../utils";
import UploadInputHidden from "./components/UploadInputHidden";
import {modifyResourceCounter} from "./utils";

type FilesCreateFormProps = {
    type: ResourcesTypes;
    acceptedExtensions: string[];
    acceptedType: FileMimeType;
    description: string;
    handleClose: () => void;
    artifactId: string;
    inPopover?: boolean;
    onDoubleClick: (
        resourceId: ResourceRowExtra["id"],
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
        videoType?: VideoV1["type"]
    ) => void;
    onChangeProcessingResx?: (values?: ProcessingResx) => void;
};

const FilesCreateForm = (props: FilesCreateFormProps) => {
    const {type, acceptedExtensions, acceptedType, description, handleClose, onChangeProcessingResx} = props;

    const createResource = async (uploadedFile: FileRef) => {
        const extension = getFileExtension(uploadedFile.fileName ?? "");
        if (!acceptedExtensions.includes(extension)) {
            toast(<p>{`Only ${acceptedExtensions.join(", ")} files are supported`}</p>, {
                type: "error",
                duration: 5000,
            });
            return onChangeProcessingResx?.(undefined);
        }

        try {
            const res = await svc.resx.createResource(type, {
                name: uploadedFile.fileName ?? "",
                type: extension as any,
                input: uploadedFile,
            });

            onChangeProcessingResx?.({
                id: res.id,
                type: type,
                isUploading: false,
            });

            await svc.resx.startResourceProcessing(res.id);

            modifyResourceCounter(type, "add", true);

            handleClose();
        } catch (err) {
            toast(<p>{err.message.replaceAll('"', "")}</p>, {
                type: "error",
                duration: 5000,
            });
        }
    };

    return (
        <>
            <UploadInputHidden
                acceptedTypes={[acceptedType]}
                dropDescriptionText={description}
                createResource={createResource}
                onClose={handleClose}
                onChangeProcessingResx={onChangeProcessingResx}
                type={type}
            />
        </>
    );
};
export default FilesCreateForm;
