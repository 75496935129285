import designer from "@workhorse/api/designer";
import {useCallback, useState} from "@workhorse/api/rendering";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import DesignerSessionPreview from "@workhorse/pages/designer/DesignerSessionPreview";
import {useCurrentTemplate} from "@workhorse/pages/templates/TemplateProviders";
import {useAgendaItems, useSession} from "@workhorse/providers/SessionDataProviders";
import {openExternalLink} from "@workhorse/util/links";

import AgendaTemplatesEditorHeaderActions from "./AgendaTemplatesEditorHeaderActions/AgendaTemplatesEditorHeaderActions";

type AgendaTemplatesEditorHeaderActionsContainerProps = {
    templateId: string;
};

const AgendaTemplatesEditorHeaderActionsContainer = (props: AgendaTemplatesEditorHeaderActionsContainerProps) => {
    const {templateId} = props;

    const agendaItems = useAgendaItems();
    const {mixpanelTrack} = useMixpanelTracker();
    const [previewOpen, setPreviewOpen] = useState(false);

    const onUseAgenda = () => {
        const currentDomain = window.location.href.split("/templates")[0];
        openExternalLink(`${currentDomain}/?create=true&templateId=` + templateId);
        mixpanelTrack("frontend-test-agenda", "agendas");
    };

    const setEditState = designer.state.update;

    const openPreview = useCallback(() => {
        setPreviewOpen(true);
        mixpanelTrack("frontend-preview-agenda", "agendas");
    }, []);

    const closePreview = useCallback(() => {
        setPreviewOpen(false);
    }, []);

    return (
        <>
            <AgendaTemplatesEditorHeaderActions
                agendaItemsLength={agendaItems.length}
                onUseAgenda={onUseAgenda}
                openPreview={openPreview}
            />
            <DesignerSessionPreview
                onClose={closePreview}
                currentTemplateId={templateId}
                open={previewOpen}
                setEditState={setEditState}
                mobileState={{
                    isMobile: false,
                }}
            />
        </>
    );
};

export default AgendaTemplatesEditorHeaderActionsContainer;
