import {makeEmptyFileRef} from "@api/resx/ref";
import svc from "@api/service/client";
import {sendChatMessageMutation} from "@artifacts/chat/frontend/utils";
import {DocV1, PptV1, ExcelV1} from "@sessions/common/resources";
import {extractLink, isHTMLTag} from "@artifacts/iframe/api/iframeUtils";
import {TwitchPlayerType} from "@artifacts/twitch/ui/common/models";
import {ResourcesTypes} from "@generated/artifacts/resources";
import type {SimpleArtifactsTag} from "@generated/artifacts/simple-map";
import {
    CheckUrlDocument,
    DrawerState,
    GetSlidoUrlDetailsDocument,
    GetTwitchUrlDetailsDocument,
    GetWebsiteDetailsDocument,
    ResourceProcessingStatus,
    SessionLifecycle,
} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import {cls, togglePalette} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {addAgendaItemWithTool, createAgendaItemWithResource, findResourceByKey} from "@workhorse/api/designer/methods";
import {useEffect, useMemo, useState, useRef} from "@workhorse/api/rendering";
import {makeCancelable} from "@workhorse/api/utils/promise";
import {drawerRightActiveComponent} from "@workhorse/providers/inject";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import prependHttp from "prepend-http";
import {ComponentType, MutableRefObject, useCallback} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import videojs from "video.js";
import {categMap} from "./actionCategTree";
import {usePaletteRootSearchString} from "./CommandPaletteProviders";
import {usePaletteActionCallback} from "./PaletteActionProvider";
import classes from "./style/PaletteAction.module.scss";
import {videoBasedArtifactTags} from "./utils";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import {t} from "i18next";
import Tooltip from "@ui/cdk/Tooltip";

function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
}

function isVideoUrl(url: string) {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const extensions = [".m3u8"];
    return extensions.some((ext) => pathname.endsWith(ext));
}

function getVideoName(url: string) {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    return pathname.split("/").pop();
}

function getVideoDuration(url: string) {
    const videoId = "video-" + Date.now();
    const video = document.createElement("video");
    video.id = videoId;
    video.style.display = "none";
    document.body.appendChild(video);

    const player = videojs(videoId, {
        muted: true,
        autoplay: false,
    });
    player.controls(false);
    player.playsinline(true);

    const src = `${url}?t=${new Date().getTime()}`;

    let t: NodeJS.Timeout | null = null;

    return new Promise<number>(async (resolve, reject) => {
        // this event fires more than once for some videos
        const onDurationChange = () => {
            const duration = player.duration();
            const seekableEnd = player.liveTracker.seekableEnd();
            // if this is a subsequent call, clear the timeout
            if (t != null) {
                clearTimeout(t);
            }
            // if duration exists and not infinite (aka livestream)
            if (duration && duration !== Infinity) {
                resolve(Math.floor(duration));
                return;
            }
            // if no duration or duration is infinite
            // we wait for another call to this in hopes we can extract the seekable end
            // which for some reason can be defined even if duration is not or duration is infinity
            else if (seekableEnd && seekableEnd !== Infinity) {
                resolve(Math.floor(seekableEnd));
                return;
            }
            // if none of the conditions above can be satisfied
            // this is most likely a livestream
            // but in case this isn't triggered again, we wait a max of 3.5 secs
            // and set whichever result best satisfies our need, or Infinity
            t = setTimeout(() => {
                const duration = player.duration();
                const seekableEnd = player.liveTracker.seekableEnd();
                const finalDuration =
                    duration && duration !== Infinity
                        ? Math.floor(duration)
                        : seekableEnd && seekableEnd !== Infinity
                        ? Math.floor(seekableEnd)
                        : Infinity;
                resolve(finalDuration);
            }, 3500);
        };
        try {
            player.src({
                src,
                type: "application/x-mpegURL",
                // @ts-ignore
                handleManifestRedirects: true,
            });

            player.on("durationchange", onDurationChange);
            await player.play();
            player.pause();
        } catch (e) {
            reject(e);
        } finally {
            player.off("durationchange", onDurationChange);
            player.dispose();
        }
    });
}

function isYoutubeUrl(url: string) {
    const domains = ["youtube.com", "m.youtube.com", "www.youtube.com", "youtu.be"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidYoutubeUrl(url: string) {
    /**
     * Matches:
     * - long urls: https://www.youtube.com/watch?v=12345678901
     * - share urls: https://youtu.be/12345678901
     * - mobile urls: https://m.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1
     * - long urls: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=smKgVuS
     */
    const regexp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    return regexp.test(url);
}

function isVimeoUrl(url: string) {
    const domains = ["vimeo.com", "www.vimeo.com"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidVimeoUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:\d+)/;
    return regexp.test(url);
}

function isTwitchUrl(url: string) {
    const domains = ["twitch.tv", "www.twitch.tv", "m.twitch.tv"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidTwitchUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.|m\.)?(?:twitch\.tv\/)(?:\w+)/;
    return regexp.test(url);
}

function isMiroUrl(url: string) {
    const domains = ["miro.com"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidMiroUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:miro\.com\/)(?:app\/)(?:board\/|live-embed\/)(?:\w+)/;
    return regexp.test(url);
}

function isBoardmixUrl(url: string) {
    const domains = ["boardmix."];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidBoardmixUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:boardmix(?:\.[a-z0-9]{2,3}){1,2}\/)app\/share\/(?:[\w\/.]+)/gi;
    return regexp.test(url);
}

function isAirtableUrl(url: string) {
    const domains = ["airtable.com", "www.airtable.com"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidAirtableUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:airtable\.com\/)(?:\w+)/;
    return regexp.test(url);
}

function isPitchUrl(url: string) {
    const domains = ["pitch.com", "www.pitch.com"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidPitchUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:pitch\.com\/)(?:public\/|embed\/|v\/)(?:\w+)/;
    return regexp.test(url);
}

function isSlidoUrl(url: string) {
    const domains = ["app.sli.do", "www.sli.do", "sli.do"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidSlidoUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:app\.)(?:sli\.do\/)(?:event\/)(?:\w+)/;
    return regexp.test(url);
}

function isMentiUrl(url: string) {
    const domains = ["menti.com", "www.menti.com"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidMentiUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:www\.)?(?:menti\.com\/)(?:\w+)/;
    return regexp.test(url);
}

function isGoogleDocsUrl(url: string) {
    const domains = ["docs.google.com/document"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isGoogleSheetsUrl(url: string) {
    const domains = ["docs.google.com/spreadsheets"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isGoogleSlidesUrl(url: string) {
    const domains = ["docs.google.com/presentation"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isGoogleFormsUrl(url: string) {
    const domains = ["docs.google.com/forms", "forms.gle"];
    return domains.some((domain) => url.startsWith("https://" + domain));
}

function isValidGoogleDocsUrl(url: string) {
    const regexp = /^(?:https?:\/\/)?(?:docs\.google\.com\/)(?:document\/|forms\/|spreadsheets\/|presentation\/)(?:d\/)(?:\w+)/;
    return regexp.test(url);
}

function isValidGoogleFormsUrl(url: string) {
    const shortenedRegexp = /^(?:https?:\/\/)?(?:forms\.gle\/)(?:\w+)/;
    return shortenedRegexp.test(url) || isValidGoogleDocsUrl(url);
}

export interface PaletteUrlInfo {
    url: string;
    tool: SimpleArtifactsTag;
    resource: ResourcesTypes | "none";
    isValid: boolean;
    errorMessage?: string;
    websiteTitle?: string;
    websiteImage?: string;
    extras?: Record<string, unknown> | null;
}

function normalizeUrl(searchUrl: string) {
    let url = searchUrl.trim().split(" ")[0];

    if (isHTMLTag(url)) {
        url = extractLink(url).link;
    }

    return prependHttp(url, {
        https: true,
    });
}

async function getWebsiteDetails(url: string, fetchExtra: boolean, fetchIcon = false, titleFromOG = false) {
    if (!fetchExtra) {
        return {
            websiteTitle: undefined,
            websiteImage: undefined,
        };
    }

    const details = await apollo.client.query({
        query: GetWebsiteDetailsDocument,
        variables: {
            url,
            icon: fetchIcon,
            idealIconSize: 32,
            titleFromOG,
        },
    });

    return {
        websiteTitle: details.data.getWebsiteDetails?.title ?? undefined,
        websiteImage: details.data.getWebsiteDetails?.icon ?? undefined,
    };
}

async function getTwitchDetails(url: string, isValid: boolean) {
    if (!isValid) {
        return;
    }

    const result = await apollo.client.query({
        query: GetTwitchUrlDetailsDocument,
        variables: {
            url,
        },
    });
    return result?.data.getTwitchUrlDetails;
}

async function getSlidoDetails(url: string, isValid: boolean) {
    if (!isValid) {
        return;
    }

    const result = await apollo.client.query({
        query: GetSlidoUrlDetailsDocument,
        variables: {
            url,
        },
    });
    return result?.data.getSlidoUrlDetails;
}

function fixGoogleDocsTitle(title?: string) {
    if (!title) {
        return title;
    }

    return title.substring(0, title.lastIndexOf(" -") || 50);
}

function fixMiroUrl(url: string) {
    if (url.startsWith("https://miro.com/app/live-embed/")) {
        return url;
    }
    return url.replace("https://miro.com/app/board/", "https://miro.com/app/live-embed/");
}

async function detectUrl(searchUrl: string): Promise<PaletteUrlInfo | null> {
    const url = normalizeUrl(searchUrl);

    if (!isValidUrl(url)) {
        return null;
    }

    if (isVideoUrl(url)) {
        return {
            url,
            tool: "flowos/video",
            resource: "flowos/video/resx/Video",
            isValid: true,
            websiteTitle: getVideoName(url),
        };
    }

    if (isYoutubeUrl(url)) {
        const isValid = isValidYoutubeUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            ...details,
            url,
            tool: "flowos/youtube",
            resource: "flowos/youtube/resx/Youtube",
            isValid,
            errorMessage: isValid ? undefined : "Invalid YouTube URL",
        };
    }

    if (isVimeoUrl(url)) {
        const isValid = isValidVimeoUrl(url);
        const details = await getWebsiteDetails(url, isValid, false, true);
        let websiteTitle = details.websiteTitle;
        if (websiteTitle?.trim().toLowerCase() === "verify to continue") {
            websiteTitle = `Vimeo - ${new Date().toLocaleDateString()}`;
        }

        return {
            ...details,
            url,
            tool: "flowos/vimeo",
            resource: "flowos/vimeo/resx/Vimeo",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Vimeo URL",
        };
    }

    if (isTwitchUrl(url)) {
        const isValid = isValidTwitchUrl(url);
        const details = await getTwitchDetails(url, isValid);

        return {
            url,
            tool: "flowos/twitch",
            resource: "flowos/twitch/resx/Twitch",
            isValid,
            websiteTitle: details?.title ?? "Twitch",
            errorMessage: isValid ? undefined : "Invalid Twitch URL",
            extras: {
                type: details?.type,
            },
        };
    }

    if (isMiroUrl(url)) {
        const isValid = isValidMiroUrl(url);
        const details = await getWebsiteDetails(url, isValid, false, true);

        return {
            ...details,
            url,
            tool: "flowos/miro",
            resource: "none",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Miro URL",
        };
    }

    if (isAirtableUrl(url)) {
        const isValid = isValidAirtableUrl(url);

        return {
            url,
            tool: "flowos/airtable",
            resource: "flowos/airtable/resx/Airtable",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Airtable URL",
            websiteTitle: `Airtable - ${new Date().toLocaleDateString()}`,
        };
    }

    if (isPitchUrl(url)) {
        const isValid = isValidPitchUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            ...details,
            url,
            tool: "flowos/pitch",
            resource: "flowos/pitch/resx/Pitch",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Pitch URL",
        };
    }

    if (isSlidoUrl(url)) {
        const isValid = isValidSlidoUrl(url);
        const details = await getSlidoDetails(url, isValid);

        return {
            url,
            tool: "flowos/slido",
            resource: "flowos/slido/resx/Slido",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Slido URL",
            websiteTitle: details?.title ?? "Slido",
        };
    }

    if (isMentiUrl(url)) {
        const isValid = isValidMentiUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            ...details,
            url,
            tool: "flowos/mentimeter",
            resource: "flowos/mentimeter/resx/Mentimeter",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Mentimeter URL",
        };
    }

    if (isGoogleDocsUrl(url)) {
        const isValid = isValidGoogleDocsUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            url,
            tool: "flowos/google-docs",
            resource: "none",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Google Docs URL",
            websiteTitle: fixGoogleDocsTitle(details.websiteTitle),
        };
    }

    if (isGoogleFormsUrl(url)) {
        const isValid = isValidGoogleFormsUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            url,
            tool: "flowos/google-forms",
            resource: "none",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Google Forms URL",
            websiteTitle: details.websiteTitle,
        };
    }

    if (isGoogleSlidesUrl(url)) {
        const isValid = isValidGoogleDocsUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            url,
            tool: "flowos/google-slides",
            resource: "none",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Google Slides URL",
            websiteTitle: fixGoogleDocsTitle(details.websiteTitle),
        };
    }

    if (isGoogleSheetsUrl(url)) {
        const isValid = isValidGoogleDocsUrl(url);
        const details = await getWebsiteDetails(url, isValid);

        return {
            url,
            tool: "flowos/google-sheets",
            resource: "none",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Google Sheets URL",
            websiteTitle: fixGoogleDocsTitle(details.websiteTitle),
        };
    }

    if (isBoardmixUrl(url)) {
        const isValid = isValidBoardmixUrl(url);

        return {
            url,
            tool: "flowos/boardmix",
            resource: "flowos/boardmix/resx/Boardmix",
            isValid,
            errorMessage: isValid ? undefined : "Invalid Boardmix URL. To embed a Boardmix board, please use the sharing link",
            websiteTitle: `Boardmix - ${new Date().toLocaleDateString()}`,
        };
    }

    const urlInfo = await apollo.client
        .query({
            query: CheckUrlDocument,
            variables: {url},
        })
        .catch((e) => null);

    const info = urlInfo?.data.iframeCheckUrl;
    const contentType = info?.contentType;

    if (contentType === "PDF") {
        return {
            url,
            tool: "flowos/pdf",
            resource: "flowos/pdf/resx/Pdf",
            isValid: true,
            extras: info,
        };
    }

    if (contentType === "PPT" || contentType === "ODP") {
        return {
            url,
            tool: "flowos/ppt",
            resource: "flowos/ppt/resx/Ppt",
            isValid: true,
            extras: info,
        };
    }

    if (contentType === "DOC" || contentType === "ODT") {
        return {
            url,
            tool: "flowos/doc",
            resource: "flowos/doc/resx/Doc",
            isValid: true,
            extras: info,
        };
    }

    if (contentType === "XLS" || contentType === "ODS") {
        return {
            url,
            tool: "flowos/excel",
            resource: "flowos/excel/resx/Excel",
            isValid: true,
            extras: info,
        };
    }

    const canEmbed = info?.isFrameable ?? false;
    const details = await getWebsiteDetails(url, canEmbed, true);

    return {
        ...details,
        url,
        tool: "flowos/iframe",
        resource: "flowos/iframe/resx/Iframe",
        isValid: canEmbed,
        errorMessage: canEmbed ? undefined : t("palette.links.webite_policies_prevent") ?? undefined,
    };
}

async function createMiroArtifact(url: string, name: string) {
    return addAgendaItemWithTool("flowos/miro", async (artifactId) => {
        designer.api.artifact.updateArtifact({
            id: artifactId,
            artifact: {
                name,
                isConfigured: true,
                properties: [
                    {
                        key: "source",
                        value: fixMiroUrl(url),
                    },
                ],
            },
        });
    });
}

async function createDocumentArtifact(tool: SimpleArtifactsTag, url: string, name: string) {
    const shouldMockResult =
        !designer.state.getSnapshot().selectedAgendaItemId && designer.currentSession()?.lifecycle === SessionLifecycle.Started;

    return addAgendaItemWithTool(tool, async (artifactId) => {
        designer.api.artifact.updateArtifact({
            id: artifactId,
            artifact: {
                name,
                isConfigured: true,
                properties: [
                    {
                        key: "documentUrl",
                        value: url,
                    },
                ],
            },
            mockResult: shouldMockResult || undefined,
        });
    });
}

async function updateVideoArtifact(artifactId: string, url: string) {
    const duration = await getVideoDuration(url).catch((e) => undefined);
    const isLiveStream = duration === Infinity;

    designer.api.artifact.updateArtifact({
        id: artifactId,
        artifact: {
            properties: [
                {
                    key: "videoIsPlaying",
                    value: `false;0;${new Date().getTime()}`,
                },
                {
                    key: "isLivestream",
                    value: isLiveStream ? "true" : "false",
                },
            ],
        },
    });
}

async function updateIframeArtifact(artifactId: string) {
    designer.api.artifact.updateArtifact({
        id: artifactId,
        artifact: {
            properties: [
                {
                    key: "isScrollable",
                    value: "true",
                },
                {
                    key: "enableParticipantControls",
                    value: "true",
                },
                {
                    key: "doReload",
                    value: new Date().toISOString(),
                },
            ],
        },
    });
}

async function createResource(
    userId: string,
    type: ResourcesTypes,
    url: string,
    name: string,
    urlInfo: PaletteUrlInfo
): Promise<{id: string} | void> {
    if (type === "flowos/video/resx/Video") {
        const foundResource = await findResourceByKey(userId, type, "videoSource", url);
        if (foundResource) {
            return foundResource;
        }

        const resourceLocator = await svc.resx.createResource("flowos/video/resx/Video", {
            name,
            type: "HLS",
            videoSource: url,
        });

        await svc.resx.updateResourceProcessingStatus(resourceLocator.id, {
            status: ResourceProcessingStatus.Finished,
        });

        return resourceLocator;
    }

    if (type === "flowos/youtube/resx/Youtube") {
        const foundResource = await findResourceByKey(userId, type, "videoSource", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/youtube/resx/Youtube", {
            name,
            type: "Youtube",
            videoSource: url,
        });
    }

    if (type === "flowos/vimeo/resx/Vimeo") {
        const foundResource = await findResourceByKey(userId, type, "videoSource", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/vimeo/resx/Vimeo", {
            name,
            type: "Vimeo",
            videoSource: url,
        });
    }

    if (type === "flowos/twitch/resx/Twitch") {
        const foundResource = await findResourceByKey(userId, type, "url", url);
        if (foundResource) {
            return foundResource;
        }

        let playerType: TwitchPlayerType | undefined;
        const extrasType = urlInfo.extras?.type;
        if (extrasType === TwitchPlayerType.video) {
            playerType = TwitchPlayerType.video;
        }
        if (extrasType === TwitchPlayerType.channel) {
            playerType = TwitchPlayerType.channel;
        }
        if (extrasType === TwitchPlayerType.clip) {
            playerType = TwitchPlayerType.clip;
        }

        return svc.resx.createResource("flowos/twitch/resx/Twitch", {
            name,
            url,
            type: playerType ?? "",
        });
    }

    if (type === "flowos/airtable/resx/Airtable") {
        const normalizedUrl = url.includes("airtable.com/embed/") ? url : url.replace("airtable.com/", "airtable.com/embed/");

        const foundResource = await findResourceByKey(userId, type, "airtableLink", normalizedUrl);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/airtable/resx/Airtable", {
            name,
            airtableLink: normalizedUrl,
        });
    }

    if (type === "flowos/pitch/resx/Pitch") {
        const foundResource = await findResourceByKey(userId, type, "link", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/pitch/resx/Pitch", {
            name,
            link: url,
        });
    }

    if (type === "flowos/slido/resx/Slido") {
        const foundResource = await findResourceByKey(userId, type, "eventLink", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/slido/resx/Slido", {
            name,
            eventLink: url,
        });
    }

    if (type === "flowos/mentimeter/resx/Mentimeter") {
        const foundResource = await findResourceByKey(userId, type, "votingLink", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/mentimeter/resx/Mentimeter", {
            name,
            votingLink: url,
        });
    }

    if (type === "flowos/boardmix/resx/Boardmix") {
        const foundResource = await findResourceByKey(userId, type, "boardmixSource", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/boardmix/resx/Boardmix", {
            name,
            boardmixSource: url,
        });
    }

    if (type === "flowos/pdf/resx/Pdf") {
        const foundResource = await findResourceByKey(userId, type, "publicPath", url);
        if (foundResource) {
            return foundResource;
        }

        const fileRef = makeEmptyFileRef({fileName: urlInfo.extras?.fileName as string, fileSize: urlInfo.extras?.fileSize as number});
        const res = await svc.resx.createResource(type, {
            type: "PDF",
            name: (urlInfo.extras?.fileName as string) ?? name,
            input: {...fileRef, publicPath: url},
        });

        await svc.resx.startResourceProcessing(res.id);
        return res;
    }

    if (type === "flowos/doc/resx/Doc") {
        const foundResource = await findResourceByKey(userId, type, "publicPath", url);
        if (foundResource) {
            return foundResource;
        }

        const fileRef = makeEmptyFileRef({fileName: urlInfo.extras?.fileName as string, fileSize: urlInfo.extras?.fileSize as number});
        const res = await svc.resx.createResource(type, {
            type: urlInfo.extras?.contentType as DocV1["type"],
            name: (urlInfo.extras?.fileName as string) ?? name,
            input: {...fileRef, publicPath: url},
        });

        await svc.resx.startResourceProcessing(res.id);
        return res;
    }

    if (type === "flowos/ppt/resx/Ppt") {
        const foundResource = await findResourceByKey(userId, type, "publicPath", url);
        if (foundResource) {
            return foundResource;
        }

        const fileRef = makeEmptyFileRef({fileName: urlInfo.extras?.fileName as string, fileSize: urlInfo.extras?.fileSize as number});
        const res = await svc.resx.createResource(type, {
            type: urlInfo.extras?.contentType as PptV1["type"],
            name: (urlInfo.extras?.fileName as string) ?? name,
            input: {...fileRef, publicPath: url},
        });

        await svc.resx.startResourceProcessing(res.id);
        return res;
    }

    if (type === "flowos/excel/resx/Excel") {
        const foundResource = await findResourceByKey(userId, type, "publicPath", url);
        if (foundResource) {
            return foundResource;
        }

        const fileRef = makeEmptyFileRef({fileName: urlInfo.extras?.fileName as string, fileSize: urlInfo.extras?.fileSize as number});
        const res = await svc.resx.createResource(type, {
            type: urlInfo.extras?.contentType as ExcelV1["type"],
            name: (urlInfo.extras?.fileName as string) ?? name,
            input: {...fileRef, publicPath: url},
        });

        await svc.resx.startResourceProcessing(res.id);
        return res;
    }

    if (type === "flowos/iframe/resx/Iframe") {
        const foundResource = await findResourceByKey(userId, type, "url", url);
        if (foundResource) {
            return foundResource;
        }

        return svc.resx.createResource("flowos/iframe/resx/Iframe", {
            name,
            url,
        });
    }
}

type CreateHandlerArg = {
    userId: string;
    sessionId?: string;
    urlInfo: PaletteUrlInfo;
    isEditing: boolean;
    openChat: () => void;
    cb?: (...arg: any) => any;
    isChatHidden: boolean;
};

function createHandler(arg: CreateHandlerArg) {
    const {userId, sessionId, isEditing, urlInfo, openChat, cb, isChatHidden} = arg;

    return async () => {
        if (urlInfo == null) {
            return;
        }

        const {url, tool, resource, isValid, websiteTitle} = urlInfo;

        if (tool === "flowos/iframe" && !isValid) {
            if (!sessionId || isEditing || isChatHidden) {
                return;
            }
            togglePalette();
            sendChatMessageMutation(url, sessionId, null, undefined, undefined, false).then(() => {
                openChat();
            });
            return;
        }

        if (!isValid) {
            return;
        }

        if (tool === "flowos/miro") {
            return createMiroArtifact(url, websiteTitle ?? url);
        }

        if (["flowos/google-docs", "flowos/google-sheets", "flowos/google-slides", "flowos/google-forms"].includes(tool)) {
            return createDocumentArtifact(tool, url, websiteTitle ?? url);
        }

        if (resource === "none") {
            return;
        }

        const resourceLocator = await createResource(userId, resource, url, websiteTitle ?? url, urlInfo);

        if (resourceLocator == null) {
            return;
        }

        if (cb) {
            cb(resourceLocator);
            return;
        }

        if (tool === "flowos/video") {
            return createAgendaItemWithResource(tool, resourceLocator.id, resource, (id: string) => updateVideoArtifact(id, url));
        }

        if (tool === "flowos/iframe") {
            return createAgendaItemWithResource(tool, resourceLocator.id, resource, updateIframeArtifact);
        }

        return createAgendaItemWithResource(tool, resourceLocator.id, resource);
    };
}

interface Props {
    onURLClickRef: MutableRefObject<(() => Promise<void>) | null>;
    setCanShowItem: (mode: boolean) => void;
    targetId?: string;
}

export default function ProcessPaletteLink(props: Props) {
    const target = useMemo(() => {
        const target = categMap.find((obj) => obj.id === props.targetId);
        return categMap.find((obj) => obj.id === target?.parentId);
    }, []);
    const [urlInfo, setUrlInfo] = useState<PaletteUrlInfo | null>(null);
    const [loading, setLoading] = useState(false);

    const {rootSearchStr} = usePaletteRootSearchString();
    const {actionCallback} = usePaletteActionCallback();
    const {onboardingType, next, nextFlow, completeFlow, completedFlows} = useOnboardingSession();

    const completedFlowsRef = useRef(completedFlows);
    completedFlowsRef.current = completedFlows;

    const [isEditing] = useDesignerIsEditing();

    const [, toggleRightDrawer] = useDrawerRightToggler();

    const openChat = useCallback(() => {
        drawerRightActiveComponent("flowos/chat");
        toggleRightDrawer(DrawerState.FullyOpen);
    }, []);

    const user = useUserInfo();
    const userId = user.id;

    const session = useSession();
    const sessionId = session.id;

    const isGroupChatHidden = !session.groupChatAccess;

    useEffect(() => {
        setLoading(true);
        setUrlInfo(null);
        props.onURLClickRef.current = null;

        // TODO @andreip throttle
        const promise = makeCancelable(detectUrl(rootSearchStr));
        promise
            .then((res: PaletteUrlInfo) => {
                let urlInfo = res;
                if (urlInfo && target?.artifactTag && urlInfo.tool !== target.artifactTag) {
                    urlInfo = {
                        ...urlInfo,
                        errorMessage: `This is not a valid ${target.title} link.  Make sure you copied the right link and try again.`,
                        isValid: false,
                        resource: "none",
                        // @ts-ignore
                        tool: undefined,
                        websiteImage: undefined,
                        websiteTitle: undefined,
                    };
                }
                setUrlInfo(urlInfo);

                async function handleClick() {
                    // Complete the New User Link Onboarding Flow
                    if (onboardingType === SessionOnboardingType.NewUser) {
                        if (!completedFlowsRef.current.includes("initial-flow")) {
                            completeFlow({
                                flowName: "initial-flow",
                            });
                        }

                        if (!target) {
                            //if there's no target, it means we're in the Open a link flow
                            if (videoBasedArtifactTags.includes(urlInfo.tool)) {
                                nextFlow({
                                    nextFlow: "open-a-link",
                                    nextTooltip: 1,
                                    saveTooltip: true,
                                    delay: 3000,
                                });
                            } else {
                                completeFlow({
                                    flowName: "open-a-link",
                                    delay: 3000,
                                });
                            }
                        } else {
                            //if there's a target, it means we're in the Embed a tool flow
                            completeFlow({
                                flowName: "embed-a-tool",
                                delay: 4000,
                            });
                        }
                    }

                    createHandler({userId, sessionId, urlInfo, isEditing, openChat, cb: actionCallback, isChatHidden: isGroupChatHidden})();
                }

                props.onURLClickRef.current = handleClick;
            })
            .catch((e) => {
                // ignore
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            promise.cancel();
        };
    }, [userId, sessionId, rootSearchStr, isEditing, openChat]);

    const artifact = useMemo(() => {
        if (urlInfo?.tool == null) {
            return null;
        }
        return categMap.find((obj) => obj?.artifactTag === urlInfo.tool);
    }, [urlInfo]);

    useEffect(() => {
        if (loading || urlInfo !== null) {
            props.setCanShowItem(true);
        } else {
            props.setCanShowItem(false);
        }
    }, [loading, urlInfo]);

    if (loading) {
        return (
            <>
                <div className="flex flex-align-center">
                    <div className={classes.linkProgressIcon} />
                    <div className={cls("ml-10", classes.linkProgressText)} />
                </div>
            </>
        );
    }

    if (urlInfo == null) {
        return null;
    }

    return (
        <Typography component="span" variant="base" className={cls("flex flex-align-center white-text overflow-hidden flex00-100")}>
            <UrlIcon urlInfo={urlInfo} ArtifactIcon={artifact?.icon} />
            <Tooltip
                classes={{
                    popper: classes.tooltipPopper,
                }}
                title={urlInfo?.errorMessage && urlInfo?.errorMessage?.length > 87 ? urlInfo?.errorMessage : ""}
                placement="top"
                arrow
            >
                <span data-id="action-link-info" className={classes.ellipsisTxt} style={{marginLeft: 10, marginRight: 30}} data-private>
                    <UrlMessage urlInfo={urlInfo} search={rootSearchStr} />
                </span>
            </Tooltip>
            <span data-id="action-link-suffix" className={classes.grey300}>
                <UrlMessageSuffix urlInfo={urlInfo} sessionId={sessionId} isEditing={isEditing} isChatHidden={isGroupChatHidden} />
            </span>
        </Typography>
    );
}

interface IconProps {
    urlInfo: PaletteUrlInfo;
    ArtifactIcon?: string | ComponentType | null;
}

function UrlIcon({urlInfo, ArtifactIcon}: IconProps) {
    if (urlInfo.tool === "flowos/iframe" && urlInfo.isValid === false) {
        return null;
    }

    if (urlInfo.tool === "flowos/iframe" && urlInfo.websiteImage) {
        return <LazyLoadImage width={26} height={26} src={urlInfo.websiteImage} />;
    }

    if (ArtifactIcon == null) {
        return null;
    }

    if (typeof ArtifactIcon === "string") {
        return <LazyLoadImage width={26} height={26} src={ArtifactIcon} />;
    }

    return <ArtifactIcon />;
}

interface MessageProps {
    search: string;
    urlInfo: PaletteUrlInfo;
}

function UrlMessage({search, urlInfo}: MessageProps) {
    if (urlInfo.errorMessage) {
        return <>{urlInfo.errorMessage}</>;
    }

    if (urlInfo.websiteTitle) {
        return <>{urlInfo.websiteTitle}</>;
    }

    return <>{search}</>;
}

interface SuffixProps {
    urlInfo: PaletteUrlInfo;
    sessionId?: string;
    isEditing: boolean;
    isChatHidden: boolean;
}

function UrlMessageSuffix({urlInfo, sessionId, isEditing, isChatHidden}: SuffixProps) {
    if (urlInfo.tool !== "flowos/iframe") {
        return urlInfo.isValid ? <>{t("palette.links.embed_this_resource") ?? "Embed this resource"}</> : null;
    }

    if (urlInfo.isValid === false) {
        if (!sessionId || isEditing || isChatHidden) {
            return <></>;
        }

        return <>{t("palette.links.share_with_chat") ?? "Share with chat..."}</>;
    }

    return <>{t("palette.links.show_this_website") ?? "Show this website..."}</>;
}
