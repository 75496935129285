import loadable from "@workhorse/api/loadable";
import {BookingRouteMatch, RouteHOCProps} from "@workhorse/declarations";
import {Switch, Route, useLocation, RouteChildrenProps} from "@workhorse/api/routing";
import {BookingContainer} from "./components/BookingContainer";

// there is no guarantee the user will land on the Booking every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const Booking = loadable(() => import("./Booking"));
const BookingDashboard = loadable(() => import("./BookingDashboard"));
const BookingSubscriber = loadable(() => import("./BookingSubscriber"));

type BookingHOCProps = RouteHOCProps<BookingRouteMatch>;

// a HOC for the Booking and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child
function BookingHOC(props: BookingHOCProps): JSX.Element {
    const bookingId = props.match?.params.bookingId;
    const location = useLocation();

    return (
        <div className="route-hoc flex-align-start booking-hoc" key="booking-hoc">
            <Switch location={location} key="bookings-routes-switch">
                <Route path="/booking/:bookingId" exact key="booking">
                    {(routeProps: RouteChildrenProps) => {
                        return <Booking key="booking-entry-point" bookingId={bookingId} {...routeProps} />;
                    }}
                </Route>
                <Route path="/booking" component={BookingDashboard} exact key="booking-dashboard"></Route>
            </Switch>

            <BookingSubscriber />
        </div>
    );
}

export default BookingHOC;
