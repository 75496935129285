import Button from "@ui/cdk/Button";
import Checkbox from "@ui/cdk/Checkbox";
import {cls} from "@ui/cdk/util/util";
import TextField from "@ui/core/components/TextField";
import React, {useEffect, useRef} from "@workhorse/api/rendering";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import classes from "./styles/GuestInfoForm.module.scss";
import {useTranslation} from "react-i18next";

const maximumCharactersLength = 100;

type GuestInfoFormProps = {
    askForGuestEmail?: boolean;
    onSubmit: (name: string, email?: string) => void;
};

const GuestInfoForm = (props: GuestInfoFormProps) => {
    const {t, i18n} = useTranslation();

    //importing {t} directly from i18next doesn't work in this case so i moved the schema into the component
    const schema = {
        fields: [
            {
                title: t("g.form.fields.name"),
                placeholder: t("g.form.field_placeholder.name"),
                name: "name",
                typeField: "text",
                required: true,
            },
            {
                title: t("prejoin.remember_my_name"),
                name: "rememberMe",
                typeField: "checkbox",
                required: false,
            },
            {
                title: t("g.form.fields.email"),
                placeholder: "name@example.com",
                name: "email",
                typeField: "email",
                required: true,
            },
        ],
        initialValues: {
            name: localStorage.getItem("guestUsername") || "",
            email: "",
            rememberMe: !!localStorage.getItem("guestUsername"),
            language: i18n.language,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "")
                .max(maximumCharactersLength, t("g.form.max_length", {max: maximumCharactersLength}) ?? ""),
        }),
        validationSchemaWithEmail: Yup.object().shape({
            name: Yup.string()
                .trim()
                .required(t("g.form.field_mandatory") ?? "")
                .max(maximumCharactersLength, t("g.form.max_length", {max: maximumCharactersLength}) ?? ""),
            email: Yup.string()
                .trim()
                .email(t("g.form.enter_valid_email") ?? "")
                .required(t("g.form.field_mandatory") ?? ""),
        }),
    };

    const {askForGuestEmail, onSubmit} = props;
    const [trackEvent] = useTrackingEvents();

    useEffect(() => {
        const currentTheme = localStorage.getItem("theme");
        if (currentTheme === "dark") {
            document.documentElement.removeAttribute("data-theme");
        }
    }, []);

    const formRef = useRef<FormikProps<{email: string; name: string; rememberMe: boolean}>>(null);

    const onChangeRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked && localStorage.getItem("guestUsername")) {
            localStorage.removeItem("guestUsername");
        }
        formRef.current?.setFieldValue(schema.fields[1].name, e.target.checked);
    };

    return (
        <div className={cls("flex flex-col flex-align-start flex-content-start", classes.root)}>
            <Formik
                innerRef={formRef}
                initialValues={schema.initialValues}
                validateOnChange
                validateOnBlur
                validationSchema={askForGuestEmail ? schema.validationSchemaWithEmail : schema.validationSchema}
                enableReinitialize
                onSubmit={(values, {setSubmitting}) => {
                    const {name, rememberMe, email} = values;

                    if (rememberMe) {
                        localStorage.setItem("guestUsername", name);
                    }

                    onSubmit(name, askForGuestEmail ? email : undefined);

                    setSubmitting(false);
                    trackEvent("guest_join_button", {event_category: TrackingEventCategory.GuestActions});
                }}
                key={"guestInfoForm"}
            >
                {({values}) => (
                    <Form className={cls("flex flex-col", classes.form)}>
                        <div className={cls("flex flex-col", classes.fields)}>
                            <div className={cls("flex flex-col", classes.field)} key={schema.fields[0].name}>
                                <label htmlFor={schema.fields[0].name} className={classes.formLabel}>
                                    {schema.fields[0].required ? <span className={classes.errorSymbol}>*</span> : null}
                                    {schema.fields[0].title}
                                </label>
                                <Field
                                    data-id="guest-name"
                                    variant="outlined"
                                    size="small"
                                    name={schema.fields[0].name}
                                    as={TextField}
                                    key={schema.fields[0].name}
                                    className={classes.formField}
                                    placeholder={schema.fields[0].placeholder}
                                    autoFocus
                                ></Field>
                                <ErrorMessage className={classes.errorText} name={schema.fields[0].name} component="div" />
                            </div>

                            {askForGuestEmail && (
                                <div className={cls("flex flex-col mt-8", classes.field)} key={schema.fields[2].name}>
                                    <label htmlFor={schema.fields[2].name} className={classes.formLabel}>
                                        {schema.fields[2].required ? <span className={classes.errorSymbol}>*</span> : null}
                                        {schema.fields[2].title}
                                    </label>
                                    <Field
                                        variant="outlined"
                                        size="small"
                                        name={schema.fields[2].name}
                                        as={TextField}
                                        key={schema.fields[2].name}
                                        className={classes.formField}
                                        placeholder={schema.fields[2].placeholder}
                                    ></Field>
                                    <ErrorMessage className={classes.errorText} name={schema.fields[2].name} component="div" />
                                </div>
                            )}

                            <div className={cls(`flex flex-row-reverse`, classes.field, classes.rememberMe)} key={schema.fields[1].name}>
                                <label htmlFor={schema.fields[1].name} className={classes.formLabel}>
                                    {schema.fields[1].required ? <span className={classes.errorSymbol}>*</span> : null}
                                    {schema.fields[1].title}
                                </label>
                                <Field
                                    variant="outlined"
                                    size="small"
                                    name={schema.fields[1].name}
                                    as={Checkbox}
                                    checked={values[schema.fields[1].name]}
                                    key={schema.fields[1].name}
                                    className={classes.formField}
                                    onChange={onChangeRememberMe}
                                ></Field>
                                <ErrorMessage className={classes.errorText} name={schema.fields[1].name} component="div" />
                            </div>
                        </div>

                        <Button size="large" className={classes.joinBtn} fullWidth variant="primary" type="submit">
                            {t("prejoin.join_now")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default GuestInfoForm;
