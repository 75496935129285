import {microNotify} from "../micro-notification-center";
import {getContactByEmail} from "@common/utils/contacts";
import {getParticipantName} from "@workhorse/pages/user/profile/ui/ProfilePicture";
import ScreenShareNotification, {ScreenShareNotificationType} from "../micro-notification-center/notifications/ScreenShareNotification";
import {useEffect} from "@workhorse/api/rendering";
import {useParticipant} from "@workhorse/providers/SessionDataProviders";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";

const ScreenShareInformation = () => {
    const {sharingParticipantId: participantId, isLocalUserSharing} = useScreenShareOptions();

    const participant = useParticipant({participantId: participantId ?? ""});

    const participantData = participant?.dataWithNullableEmail;

    const contact = participantData?.email ? getContactByEmail(participantData.email) : null;
    const displayFirstName = !!contact ? contact.firstName : participantData?.firstName;
    const displayLastName = !!contact ? contact.lastName : participantData?.lastName;

    const fullName = getParticipantName(displayFirstName, displayLastName);

    useEffect(() => {
        if (participant?.id && !isLocalUserSharing) {
            microNotify<ScreenShareNotificationType>({
                component: ScreenShareNotification,
                message: {
                    fullName,
                },
                type: "share-screen",
            });
        }
    }, [participantId, isLocalUserSharing, fullName]);

    return null as unknown as JSX.Element;
};

export default ScreenShareInformation;
