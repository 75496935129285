import type {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles(
    (theme: Theme) => ({
        "@global": {
            body: {
                background: theme.main.palette.elevation.surface[0],
                "-webkit-font-smoothing": "antialiased",
                "-moz-osx-font-smoothing": "grayscale",
            },
            ".MuiInputBase-root": {
                // color: "#495057"
            },
            ".MuiListItem-gutters": {
                paddingRight: 13,
                paddingLeft: 13,
            },
            //   '.my-palette-pink': {
            //     backgroundColor: theme.palette.pink.main,
            //     color: theme.main.palette.basic.background,
            //   },
            ".pac-container": {
                zIndex: 1300,
            },
            ".MuiTypography-h4": {
                letterSpacing: "-0.06px",
                fontSize: "1.2146rem",
                fontWeight: 500,
            },
            ".MuiTypography-h5": {
                // letterSpacing: "-0.05px",
                // fontSize: "16px",
                fontWeight: 500,
            },
            ".MuiButton-contained.custom,.MuiFab-primary.custom,.MuiIconButton-colorPrimary.custom, .MuiAvatar-circle.custom": {
                boxShadow:
                    "inset 2px 3px 3px 0 rgba(255, 255, 255, 0.2), -5px -5px 10px 0 rgba(255, 255, 255, 0.05), 2px 4px 10px 0 rgba(36, 42, 55, 0.12), 1px 1px 2px 0 rgba(0, 0, 0, 0.5)",
                background: "var(--sessions-video-play-button)",
                color: theme.main.palette.basic.background,
            },
            ".MuiButton-contained.custom,.MuiFab-primary.custom": {
                textTransform: "initial",

                borderRadius: 7,
                "& .MuiButton-label": {
                    background: "none",
                },
            },
            ".MuiButton-contained.custom": {
                padding: `${theme.typography.pxToRem(theme.spacing(2))} ${theme.typography.pxToRem(theme.spacing(3))}`,
            },
            ".MuiIconButton-colorPrimary.custom": {
                borderRadius: 10,
                background: theme.main.palette.elevation.surface[0],
                color: "rgba(29,136,244,1)",
                "&.active": {
                    background:
                        "linear-gradient(135deg, rgba(86,173,249,0.78) 0%,rgba(29,136,244,0.78) 51%,rgba(29,136,244,0.78) 57%,rgba(29,136,244,1) 100%)",
                    color: theme.main.palette.basic.background,
                },
            },
            ".MuiFormControl-root.app-input": {
                marginTop: 26,
                "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
                    transform: "translate(2px, -26px) scale(0.75)",
                    color: theme.main.palette.text.secondary,
                    fontSize: 16,
                    fontWeight: 500,
                    letterSpacing: "normal",

                    display: "flex",
                    "& .MuiInputLabel-shrink": {
                        transform: "translate(2px, -26px) scale(0.75)",
                    },
                    "& .MuiFormLabel-asterisk": {
                        order: -1,
                        color: "red",
                        marginRight: 5,
                    },
                },
                "& fieldset.MuiOutlinedInput-notchedOutline > legend": {
                    visibility: "hidden",
                    background: "none",
                    width: 0,
                },
            },
            ".MuiIconButton-root.custom.action-btn": {
                width: 36,
                height: 36,
                margin: "7px 0",
                "&.with-badge": {
                    margin: 0,
                },
            },
            ".MuiFab-primary.custom": {
                borderRadius: "50%",
            },
            ".MuiTypography-colorTextPrimary": {},
            ".MuiTypography-overline": {
                fontWeight: 500,
            },
            ".MuiIconButton-root:hover": {
                // background: "initial",
            },
            ".MuiTableContainer-root": {
                overflowX: "unset",
            },
            ".MuiTableRow-root.MuiTableRow-hover": {
                transition: theme.transitions.create("background-color", {duration: 300}),
            },
            ".MuiOutlinedInput-notchedOutline": {
                // borderRadius: 24,
                // borderColor: "rgba(0, 0, 0, 0.15)"
            },
            ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
                // transform: "translate(14px, -17px) scale(0.75);"
            },
            '.MuiFormControl-root > .MuiInputLabel-outlined[data-shrink="true"]': {
                // marginLeft: "initial"
            },
            ".MuiSelect-select:focus": {
                // borderRadius: 24
            },

            /* width */
            "*::-webkit-scrollbar": {
                width: 5,
                height: 5,
            },

            /* Track */
            "*::-webkit-scrollbar-track": {},

            /* Handle */
            "*::-webkit-scrollbar-thumb": {
                background: "rgba(200, 200, 200, 0.5)",
                borderRadius: 10,
            },

            /* Handle on hover */
            "*::-webkit-scrollbar-thumb:hover": {
                background: "rgba(200, 200, 200, 0.7)",
            },
            "button.MuiIconButton-root": {
                "&:hover": {
                    backgroundColor: "transparent",
                },
            },

            // '.MuiPaper-rounded':{
            //   borderRadius: 8
            // }
        },
    }),
    {name: "muiCssOverrides", index: 9999}
);

function MuiCssOverrides() {
    useStyle();
    return null;
}

export default MuiCssOverrides;
