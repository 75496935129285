import browserInfo from "@workhorse/api/BrowserInfo";
import {useEffect, useState} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {getScreenOrientation, supportsScreenOrientation} from "./DeviceOrientationProvider";

export function checkInputLikeElementActive(elem?: Element | null) {
    const element = elem ?? document.activeElement;

    return (
        (element as HTMLElement) &&
        (element?.nodeName === "INPUT" ||
            element?.nodeName === "TEXTAREA" ||
            (element?.nodeName === "DIV" && element?.getAttribute("contenteditable") === "true"))
    );
}

export function checkEditorActive(elem?: Element | null) {
    const element = elem ?? document.activeElement;

    return (
        element && element.nodeName === "DIV" && element.getAttribute("contenteditable") === "true" && element.classList.contains("editor")
    );
}

function checkKeyboardOpen() {
    if (window.visualViewport == null) {
        return false;
    }

    const orientation = getScreenOrientation();
    const minKeyboardHeight = orientation === "portrait" ? 300 : 200;

    let height = window.screen.height;

    if ((browserInfo.isIOS() || browserInfo.isIpadOS()) && !supportsScreenOrientation() && orientation === "landscape") {
        height = window.screen.width;
    }

    return height - minKeyboardHeight > window.visualViewport.height;
}

const hasVirtualKeyboard = browserInfo.isMobile() || browserInfo.isAndroid() || browserInfo.isIOS() || browserInfo.isIpadOS();

function useKeyboardStore() {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(() => checkKeyboardOpen());
    const [isInputActive, setIsInputActive] = useState(() => checkInputLikeElementActive());
    const [isEditorActive, setIsEditorActive] = useState(() => checkEditorActive() === true);

    useEffect(() => {
        if (!hasVirtualKeyboard) {
            return;
        }

        const resizeHandler = () => {
            requestAnimationFrame(() => {
                const keyboardOpen = checkKeyboardOpen();
                setIsKeyboardOpen(keyboardOpen);
                setIsInputActive(checkInputLikeElementActive());
                setIsEditorActive(checkEditorActive() === true);
            });
        };

        window.visualViewport?.addEventListener("resize", resizeHandler);

        return () => {
            window.visualViewport?.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const isOpen = hasVirtualKeyboard && isKeyboardOpen;

    return {
        isInputActive,
        isKeyboardOpen: isOpen,
        isKeyboardOpenEditor: isOpen && isEditorActive,
    };
}

export const [KeyboardProvider, useKeyboard] = createContextProvider(
    {
        name: "Keyboard",
    },
    useKeyboardStore
);
