import {useCallback, useState} from "@workhorse/api/rendering";
import {SetStateAction} from "react";

function isFunction<S, A>(func: ((...args: A[]) => S) | S): func is (...args: A[]) => S {
    return typeof func === "function";
}

export function createPersistedState<S>(serialize: (value: S) => string, deserialize: (value: string) => S) {
    return (key: string, initial: (() => S) | S) => {
        const initializeState = () => {
            const savedValue = localStorage.getItem(key);

            if (savedValue == null) {
                return isFunction(initial) ? initial() : initial;
            }

            return deserialize(savedValue);
        };

        const [value, setValue] = useState<S>(initializeState);

        const setValueWrapped = useCallback(
            (action: SetStateAction<S>, persist?: boolean) => {
                setValue((current) => {
                    const result = isFunction(action) ? action(current) : action;
                    if (persist !== false) {
                        localStorage.setItem(key, serialize(result));
                    }
                    return result;
                });
            },
            [key]
        );

        return [value, setValueWrapped] as const;
    };
}
