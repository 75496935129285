import {ParticipantVisible} from "@workhorse/api/conference2";
import {useInView} from "react-intersection-observer";

interface Props {
    forceInvisible: boolean;
    participantId: string;
    containerNode?: HTMLDivElement | null;
}

interface HandlerProps {
    inView: boolean;
    participantId: string;
}

function AudienceParticipantVisibilityHandler(props: HandlerProps) {
    if (!props.inView) {
        return null;
    }
    return <ParticipantVisible participantId={props.participantId} location="audience" />;
}

export function AudienceParticipantVisibilityDetector(props: Props) {
    const [inViewRef, inView] = useInView({
        root: props.containerNode,
    });

    return (
        <>
            <div ref={inViewRef} style={{position: "absolute", width: "100%", height: "100%", zIndex: -5}} />
            <AudienceParticipantVisibilityHandler participantId={props.participantId} inView={inView && props.forceInvisible !== true} />
        </>
    );
}
