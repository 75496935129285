import designer from "@workhorse/api/designer";
import {useEffect} from "@workhorse/api/rendering";

import {useUserInfo} from "./User";

const WorkspaceMyProductToolInitializer = () => {
    const user = useUserInfo();
    const workspaceId = user.activeWorkspace?.id;

    useEffect(() => {
        if (designer.productToolInitialized && user.isAuthenticated) {
            designer.myProductToolInPalette();
        }
    }, [workspaceId, user.isAuthenticated]);

    return <></>;
};

export default WorkspaceMyProductToolInitializer;
