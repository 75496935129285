import {RECORDING_USER_EMAIL} from "@common/recording/constants";
import {StopSessionRecordingDocument} from "@generated/data";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import loadable from "@workhorse/api/loadable";
import {useEffect} from "@workhorse/api/rendering";
import {NavLink} from "@workhorse/api/routing";
import {useLocalUser} from "@workhorse/api/user";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {existingContent} from "@workhorse/providers/inject";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import YouReLostPng from "../assets/media/you-re-lost.png";
import headClasses from "./header/style/Header.module.scss";
import classes from "./style/ErrorComponent.module.scss";

type ErrorComponentProps = {
    withHeader?: boolean;
};

const msg = {
    chunkErr: {
        primaryTitle: "Please refresh this page",
        secondaryTitle:
            "Please make sure you are not experiencing internet connectivity issues and that you don't have any browser extensions that could be blocking network requests.",
    },
};

const ChunkLoadErrorComponent = (props: ErrorComponentProps) => {
    const {withHeader = false} = props;
    const {primaryTitle, secondaryTitle} = msg.chunkErr;
    const mobileState = useMobile();
    const sessionId = useSessionIdFromRoute();

    const localUser = useLocalUser();
    const isRecorder = localUser?.localUser?.email === RECORDING_USER_EMAIL;

    const stopRecording = async () => {
        sessionStorage.removeItem("triedReloadForRecorder");
        if (sessionId && sessionId !== "non-existent") {
            const res = await apollo.client.mutate({
                mutation: StopSessionRecordingDocument,
                variables: {
                    sessionId,
                },
            });

            if (res.errors) {
                console.error("Error while stopping recording", res.errors);
            }
        }
    };

    useEffect(() => {
        if (isRecorder) {
            const triedReload = sessionStorage.getItem("triedReloadForRecorder");
            if (!triedReload) {
                sessionStorage.setItem("triedReloadForRecorder", "true");
                console.log("should reload for recorder");

                // window.location.reload();
            } else {
                stopRecording();
            }
        }
        return () => {
            existingContent["drawer-left"]({
                classNames: [],
            });

            existingContent["drawer-left-preview"]({
                classNames: [],
            });
        };
    }, []);

    const preventNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!(localUser?.localUser?.role === "USER")) {
            e.preventDefault();
        }
    };

    const reloadPage = () => {
        parent?.top?.location.reload();
    };

    return (
        <>
            {withHeader ? (
                <header
                    id="main-app-header"
                    className={cls(
                        "without-nav-transition",
                        headClasses.pageHeader,
                        headClasses.disableTransition,
                        headClasses.lobbyHeader
                    )}
                    // style={style}
                >
                    <div className={cls("fullw", mobileState.isMobile && headClasses.mobile)}>
                        <NavLink replace={true} to="/" onClick={preventNavigation} key="nav-link">
                            <BrandingLogo />
                        </NavLink>
                    </div>
                </header>
            ) : null}

            <div className={cls("flex flex-col flex-center-all", classes.root)}>
                <img className={cls(classes.image)} src={YouReLostPng} alt="error img" />
                <div className={classes.header}>
                    <Typography variant="lg" component="h1" className={classes.primaryTitle}>
                        {primaryTitle}
                    </Typography>

                    <Typography variant="base" component="h2" className={(classes.secondaryTitle, classes.chunkErrSecTitle)}>
                        {secondaryTitle}
                    </Typography>

                    <Button className={classes.reloadBtn} onClick={reloadPage}>
                        {"Reconnect"}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ChunkLoadErrorComponent;
