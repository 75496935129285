import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/UsageHistorySessionItem.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {SessionLifecycle, SessionsUsingResourceResult} from "@generated/data";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import Time from "@ui/cdk/Time";
import TimeRange from "@ui/cdk/TimeRange";
import {dateFormat} from "@ui/cdk/DateFormat";
import {useUserInfo} from "@workhorse/providers/User";
import {getSessionResultIconAndTooltip} from "../searchbar/utils";

export type SearchResultItemProps = {
    session: SessionsUsingResourceResult;
};

const UsageHistorySessionItem = ({session}: SearchResultItemProps) => {
    const user = useUserInfo();

    const isLive = session.sessionLifecycle === SessionLifecycle.Started;
    const isPast = session.sessionLifecycle === SessionLifecycle.Ended;

    const itemInfo = getSessionResultIconAndTooltip(session.ownerId === user.id, undefined, {
        isRecurrent: session.recurrenceId !== null,
        isBooking: session.isBooking || false,
        isEvent: session.isEvent || false,
    });

    const tooltip = itemInfo.tooltip;

    const link = session.endedAt ? `/memory/session/${session.sessionId}` : `/session/${session.sessionId}`;

    return (
        <Link
            data-id="history-list-item"
            href={link}
            className={cls("flex flex-justify-between flex-align-center", classes.resultItemContainer)}
        >
            <div className={cls("flex flex-align-center flex-justify-between", classes.resultItem)}>
                <div className="flex flex-align-center">
                    <Tooltip title={tooltip ?? ""} arrow placement="right">
                        <div className={cls(classes.resultIcon, itemInfo.color)}>
                            {itemInfo.iconUrl ? <img src={itemInfo.iconUrl}></img> : null}
                        </div>
                    </Tooltip>
                    <div className="flex flex-col">
                        <div className="flex flex-align-center">
                            <div data-id="title" className={classes.resultItemTitle}>
                                {session.name}
                            </div>
                        </div>
                        <Typography className={classes.resultItemDescription} variant="sm" color="eleventh" component="div">
                            <div className="flex flex-align-center" key={session.sessionId}>
                                <div className="flex flex-align-center">
                                    <EventRoundedIcon className={cls("mr-3", classes.participantIcon)} />
                                    <Typography variant="sm" className="mt-1">
                                        {session.startAt ? dateFormat(new Date(session.startAt), ["normalDate", "year"]) : ""}
                                    </Typography>
                                </div>
                                <div className="flex flex-align-center ml-12">
                                    <ScheduleRoundedIcon className={cls("mr-4", classes.participantIcon)} />
                                    {isLive ? "Live - Started at " : ""}
                                    {isPast ? (
                                        <TimeRange
                                            start={session.startAt ? new Date(session.startAt) : new Date()}
                                            end={session.endedAt ? new Date(session.endedAt) : new Date()}
                                            timePropsStart={{relativeMeridian: true}}
                                            timePropsEnd={{relativeMeridian: true}}
                                        />
                                    ) : (
                                        <Time className="ml-4" time={new Date(session.startAt || 0)} isHalfDayClock={user.halfDayClock} />
                                    )}
                                </div>
                            </div>
                        </Typography>
                    </div>
                </div>

                <ProfilePicture
                    avatarSize="S"
                    avatarVariant="rounded"
                    firstName={session.ownerFirstName}
                    lastName={session.ownerLastName}
                    avatar={session.ownerProfilePicture}
                />
            </div>
        </Link>
    );
};

export default UsageHistorySessionItem;
