import DeleteIcon from "@material-ui/icons/Delete";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import Button from "@ui/cdk/Button";
import Menu from "@ui/cdk/Menu";
import {cls} from "@ui/cdk/util";
import {useCallback, useState} from "@workhorse/api/rendering";
import classes from "./styles/AgendaTemplatesEditorActionsMenu.module.scss";

type AgendaTemplatesEditorActionsMenuProps = {
    canDeleteAgenda: boolean;
    toggleUsageHistory: () => void;
    toggleDeleteTemplate: () => void;
};

function AgendaTemplatesEditorActionsMenu(props: AgendaTemplatesEditorActionsMenuProps) {
    const {canDeleteAgenda, toggleUsageHistory, toggleDeleteTemplate} = props;

    const [adminAnchorEl, setAdminAnchorEl] = useState(null);

    const handleClickAdminPopover = useCallback(
        (event) => {
            setAdminAnchorEl(event.currentTarget);
        },
        [setAdminAnchorEl]
    );

    const handleCloseAdminPopover = useCallback(() => {
        setAdminAnchorEl(null);
    }, []);

    const handleToggleUsageHistory = useCallback(() => {
        toggleUsageHistory();
        handleCloseAdminPopover();
    }, [toggleUsageHistory, handleCloseAdminPopover]);

    const handleToggleDeleteTemplate = useCallback(() => {
        toggleDeleteTemplate();
        handleCloseAdminPopover();
    }, [toggleDeleteTemplate, handleCloseAdminPopover]);

    const openAdmin = Boolean(adminAnchorEl);

    return (
        <>
            <Button
                data-id="admin-more-actions"
                className={cls(classes.moreActions, openAdmin && classes.moreActionsOpen)}
                variant="tertiary"
                size="small"
                onClick={handleClickAdminPopover}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id={"admin-menu"}
                open={openAdmin}
                anchorEl={adminAnchorEl}
                onClose={handleCloseAdminPopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                getContentAnchorEl={null}
                PaperProps={{
                    className: classes.paperRoot,
                }}
            >
                <li className={cls(classes.paperButton, classes.paperButtonSimple)}>
                    <Button
                        fullWidth
                        startIcon={<HistoryRoundedIcon />}
                        data-id="see-usage-history"
                        variant="quaternary"
                        onClick={handleToggleUsageHistory}
                        noFocusBorder
                    >
                        See usage history
                    </Button>
                </li>

                {canDeleteAgenda ? (
                    <li className={cls(classes.paperButton)}>
                        <Button
                            fullWidth
                            startIcon={<DeleteIcon />}
                            data-id="delete-agenda-admin"
                            variant="destructive-quaternary"
                            onClick={handleToggleDeleteTemplate}
                            noFocusBorder
                        >
                            Delete agenda
                        </Button>
                    </li>
                ) : null}
            </Menu>
        </>
    );
}

export default AgendaTemplatesEditorActionsMenu;
