import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import {useState} from "@workhorse/api/rendering";
import genericStyles from "../../../styles/GenericSection.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {cls} from "@ui/cdk/util";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import classes from "./LivestreamingOutputUrlsSelect.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import {SessionLivestreamOutputUrlJsonType} from "@sessions/common/recording";
import {useTranslation} from "react-i18next";
import Link from "@ui/cdk/Link";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import InfoTooltip from "@workhorse/components/InfoTooltip";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";

const styles = {...genericStyles};

type LivestreamingOutputUrlsSelectProps = {
    urls: SessionLivestreamOutputUrlJsonType[];
    onChange: (json: {urls: SessionLivestreamOutputUrlJsonType[]}) => void;
    onGoToRecordingSection?: () => void;
};

const rtmpRegex = /^(rtmp|rtmps):\/\/[^ "]+$/;

const LivestreamingOutputUrlsSelect = (props: LivestreamingOutputUrlsSelectProps) => {
    const {urls, onChange} = props;
    const {t} = useTranslation();
    const {mixpanelTrack} = useMixpanelTracker();
    const hostType = useHostType();
    console.log("hostType", hostType);
    const mixpanelSection = hostType === HostType.Home ? "home" : "event";
    const [nameValue, setNameValue] = useState<string>("");
    const [urlValue, setUrlValue] = useState<string>("");
    const [keyValue, setKeyValue] = useState<string>("");

    const [addingNewUrl, setAddingNewUrl] = useState<boolean>(false);

    const [errorTextUrl, setErrorTextUrl] = useState<string>("");

    const handleChangeUrlValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUrlValue(event.target.value);
        setErrorTextUrl("");
    };

    const handleChangeNameValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameValue(event.target.value);
    };

    const handleChangeKeyValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyValue(event.target.value);
    };

    const handleAddUrl = () => {
        const sanitizedValue = urlValue.trim();
        const sanitizedKey = keyValue.trim();

        if (!rtmpRegex.test(sanitizedValue) || sanitizedValue.includes(" ")) {
            setErrorTextUrl(
                t("player.settings.general.livestreaming.output_urls.add_url_error") || "Invalid URL. Please enter a valid RTMP URL."
            );
            return;
        }

        if (sanitizedKey?.includes(" ")) {
            setErrorTextUrl(
                t("player.settings.general.livestreaming.output_urls.stream_key_error") ||
                    "Invalid stream key. Please enter a valid stream key."
            );
            return;
        }

        const urlWithKey = sanitizedKey ? `${sanitizedValue}/${sanitizedKey}` : sanitizedValue;

        onChange({urls: [...urls, {name: nameValue, url: urlWithKey}]});
        setUrlValue("");
        setNameValue("");
        setKeyValue("");
        setErrorTextUrl("");

        setAddingNewUrl(false);
        mixpanelTrack("frontend-live-streaming-save-url", mixpanelSection);
    };

    const handleRemoveUrl = (index: number) => {
        const newUrls = urls.filter((_, i) => i !== index);
        onChange({urls: newUrls});
        mixpanelTrack("frontend-live-streaming-delete-url", mixpanelSection);
    };

    function handleAddNewUrl() {
        setAddingNewUrl(true);
        mixpanelTrack("frontend-live-streaming-add-url", mixpanelSection);
    }
    return (
        <div className="flex flex-col flex-justify-between gap-15 py-12 mr-16 mt-10">
            {/* <div className="flex gap-15 flex-align-start">
                <div className={styles.iconWrapper}>
                    <ListAltRoundedIcon />
                </div>
                <div className={cls("flex flex-justify-between flex-align-center fullw flex11-auto")}>
                    <div className={"pr-10"}>
                        <div className="flex flex-col">
                            <Typography variant="base" fontWeight="bold" color="secondary">
                                {t("player.settings.general.livestreaming.output_urls.title")}
                            </Typography>

                            <Typography
                                variant="base"
                                fontWeight="bold"
                                color="secondary"
                                component="div"
                                className="flex flex-align-center"
                            >
                                Need help? <Link href={getHelpCenterArticleLink("knowledge-base/live-streaming")}>Learn how to set up your live stream.</Link>
                            </Typography>
                        </div>
                        <Typography color="quaternary" component="span">
                            {t("player.settings.general.livestreaming.output_urls.description")}
                        </Typography>
                    </div>
                </div>
            </div> */}

            <div className="fullw flex flex-col gap-8">
                <Typography fontWeight="bold" color="secondary">
                    {t("player.settings.general.livestreaming.output_urls.urls")}
                </Typography>
                <div className={cls(classes.urlListContainer, "mozilla-scrollbar")}>
                    {urls.length ? (
                        urls.map((item, index) => {
                            // let url = item.url.split("/").slice(0, -1).join("/");
                            // const streamKey = item.url.split("/").pop();

                            // if (streamKey) {
                            //     url += "/";
                            // }

                            return (
                                <div
                                    className={cls(classes.urlListItem, "flex flex-align-center fullw gap-8 py-6 flex-justify-between")}
                                    key={`rtmp-url-${index}`}
                                >
                                    <div className="flex flex-col gap-4 fullw minw0">
                                        <Typography fontWeight="bolder" noWrap color="secondary" className={classes.title}>
                                            {item.name}
                                        </Typography>
                                        <Typography color="tertiary" noWrap className={classes.url}>
                                            {item.url}
                                            {/* <span className={classes.urlStreamKey}>{streamKey}</span> */}
                                        </Typography>
                                    </div>
                                    <Tooltip placement="top" arrow title={t("g.remove") || "Remove"}>
                                        <IconButton onClick={() => handleRemoveUrl(index)} className={classes.removeButton}>
                                            <CloseRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        })
                    ) : (
                        <div className="mx-auto">
                            <Typography color="quaternary" variant="sm">
                                {t("player.settings.general.livestreaming.output_urls.no_urls_added")}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>

            {addingNewUrl ? (
                <div className={classes.newUrlContainer}>
                    <form className="flex flex-col fullw flex-align-end">
                        <div className="flex flex-col fullw gap-24">
                            <div className="flex flex-col gap-4">
                                <Typography color="secondary" variant="base" fontWeight="bold">
                                    {t("player.settings.general.livestreaming.output_urls.name")}
                                    <Typography color="red400" variant="base" component="span">
                                        {" *"}
                                    </Typography>
                                </Typography>
                                <Input
                                    value={nameValue}
                                    onChange={handleChangeNameValue}
                                    formControlClassName={cls(classes.inputFormControl)}
                                    fullWidth
                                    className={classes.inputRoot}
                                    placeholder={
                                        t("player.settings.general.livestreaming.output_urls.name_placeholder") ||
                                        "Add a name for your RTMP URL"
                                    }
                                    maxCharCount={60}
                                    showTypedCharCount
                                />
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-align-center ">
                                    <Typography color="secondary" variant="base" fontWeight="bold">
                                        {t("player.settings.general.livestreaming.output_urls.url")}
                                        <Typography color="red400" variant="base" component="span">
                                            {" *"}
                                        </Typography>
                                    </Typography>
                                    <InfoTooltip
                                        title={t("player.settings.general.livestreaming.output_urls.url")}
                                        info={t(`player.settings.general.livestreaming.rtmp_url_tooltip`) || ""}
                                        className="ml-6"
                                        openOnHover
                                        arrow
                                        placement="top"
                                    />
                                </div>
                                <Input
                                    value={urlValue}
                                    onChange={handleChangeUrlValue}
                                    formControlClassName={cls(classes.inputFormControl)}
                                    fullWidth
                                    className={classes.inputRoot}
                                    placeholder={
                                        t("player.settings.general.livestreaming.output_urls.url_placeholder") || "Add your RTMP URL here"
                                    }
                                />
                                {errorTextUrl && (
                                    <Typography color="red400" variant="sm" className="mt-6">
                                        {errorTextUrl}
                                    </Typography>
                                )}
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex flex-align-center ">
                                    <Typography color="secondary" variant="base" fontWeight="bold">
                                        {t("player.settings.general.livestreaming.output_urls.stream_key") || "Stream key (optional)"}
                                    </Typography>
                                    <InfoTooltip
                                        title={t("player.settings.general.livestreaming.output_urls.stream_key") || "Stream key (optional)"}
                                        info={t(`player.settings.general.livestreaming.stream_key_tooltip`) || ""}
                                        className="ml-6"
                                        openOnHover
                                        arrow
                                        placement="top"
                                    />
                                </div>
                                <Input
                                    value={keyValue}
                                    onChange={handleChangeKeyValue}
                                    formControlClassName={cls(classes.inputFormControl)}
                                    fullWidth
                                    className={classes.inputRoot}
                                    placeholder={
                                        t("player.settings.general.livestreaming.output_urls.stream_key_placeholder") ||
                                        "Add your stream key here"
                                    }
                                    type="text-masked"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="mt-16 flex gap-16">
                            <Button size="small" variant="quaternary" onClick={() => setAddingNewUrl(false)}>
                                {t("g.cancel") || "Cancel"}
                            </Button>

                            <Button
                                size="small"
                                variant="secondary"
                                onClick={handleAddUrl}
                                disabled={
                                    !urlValue ||
                                    !nameValue ||
                                    urls.findIndex((item) =>
                                        keyValue ? item.url === urlValue + "/" + keyValue : item.url === urlValue
                                    ) !== -1 ||
                                    !!errorTextUrl
                                }
                                type="submit"
                            >
                                {t("g.save") || "Save"}
                            </Button>
                        </div>
                    </form>
                </div>
            ) : (
                <Button className="ml-auto" size="small" variant="secondary" onClick={handleAddNewUrl}>
                    {t("player.settings.general.livestreaming.output_urls.add_url") || "Add URL"}
                </Button>
            )}
        </div>
    );
};

export default LivestreamingOutputUrlsSelect;
