import loadable from "@workhorse/api/loadable";
import {SessionSettingsSection} from "../../definitions";
import {ReactComponent as WidgetsIcon} from "../assets/session-widgets-icon.svg";

const WidgetsSection = loadable(() => import("./WidgetsSection"));
WidgetsSection.displayName = "Loadable:WidgetsSection";

const WidgetsSectionDefinition: SessionSettingsSection<"general.widgets"> = {
    translateKey: "general.widgets",
    kind: "general.widgets",
    title: "Widgets",
    component: WidgetsSection,
    icon: WidgetsIcon,
    rbac: {
        "session.isAssistant": true,
        isOwner: true,
        isUser: false,
        isGuest: false,
    },
};

export default WidgetsSectionDefinition;
