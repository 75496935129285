import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {SessionMessageRemindersJsonType, SessionReminderJsonType, SessionRemindersJsonType} from "@sessions/common/reminders/index";
import Button from "@ui/cdk/Button";
import Input from "@ui/cdk/Input/Input";
import Select from "@ui/cdk/Select";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import {useTranslation} from "react-i18next";
import {ReactComponent as SendReminderIcon} from "../../assets/send-reminder.svg";
import classes from "../styles/SessionSendReminder.module.scss";
import SessionSendReminderSMSMessages from "./SessionSendReminderSMSMessages";

type SessionSendReminderProps = {
    emailsValue: SessionRemindersJsonType;
    messagesValue?: SessionMessageRemindersJsonType;
    onChangeEmails: (SessionRemindersJsonType, hasChanged?: boolean) => void;
    onChangeMessages?: (SessionMessageRemindersJsonType, hasChanged?: boolean) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    triggerHasChanges?: () => void;
    isPlannedSession?: boolean;
    withSmsReminders?: boolean;

    isEvent?: boolean;
    isBooking?: boolean;
};

const SessionSendReminder = ({
    emailsValue,
    messagesValue,
    onChangeEmails,
    onChangeMessages,
    showIcon = false,
    disabled,
    className,
    triggerHasChanges,
    isPlannedSession,
    withSmsReminders,
    isEvent,
    isBooking,
}: SessionSendReminderProps) => {
    const {t} = useTranslation();
    const {mixpanelTrack} = useMixpanelTracker();
    const unitOptions = [
        {
            value: "minutes",
            text: t("g.time.minutes"),
            textSingular: t("g.time.minute"),
        },
        {value: "hours", text: t("g.time.hours"), textSingular: t("g.time.hour")},
        {value: "days", text: t("g.time.days"), textSingular: t("g.time.day")},
        {value: "weeks", text: t("g.time.weeks"), textSingular: t("g.time.week")},
    ];

    const [reminders, setReminders] = useState<SessionReminderJsonType[]>(() => {
        if (emailsValue && "reminders" in emailsValue && emailsValue.reminders !== null && emailsValue.reminders !== undefined) {
            const formattedReminders = emailsValue.reminders.map((reminder) => {
                const minutes =
                    reminder.minutes /
                    (reminder.unit === "minutes" ? 1 : reminder.unit === "hours" ? 60 : reminder.unit === "days" ? 1440 : 10080);
                return {
                    minutes,
                    unit: reminder.unit,
                };
            });
            return formattedReminders;
        }

        return [];
    });

    const intialRender = useRef<boolean>(false);

    const handleEmailValueChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newReminders = [...reminders];
        newReminders[index].minutes = parseInt(event.target.value);
        setReminders(newReminders);
        triggerHasChanges?.();
    };

    const handleEmailUnitChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newReminders = [...reminders];
        newReminders[index].unit = event.target.value;
        setReminders(newReminders);
        triggerHasChanges?.();
        if (isPlannedSession) {
            mixpanelTrack("frontend-add-reminder", undefined, "planned");
        }
    };

    const handleAddReminder = () => {
        const hasOneHourReminder = reminders?.some((reminder) => reminder?.unit === "hours" && reminder?.minutes === 1);
        const lastReminderValue = reminders?.length ? reminders?.[reminders?.length - 1].minutes : 0;
        setReminders([...reminders, {minutes: hasOneHourReminder ? lastReminderValue + 1 : 1, unit: "hours"}]);

        triggerHasChanges?.();
    };

    const handleRemoveReminder = (index: number) => {
        const newReminders = [...reminders];
        newReminders.splice(index, 1);
        setReminders(newReminders);

        triggerHasChanges?.();
    };

    useEffect(() => {
        const formattedReminders: SessionReminderJsonType[] = reminders.map((reminder) => {
            const minutes =
                reminder?.minutes *
                (reminder?.unit === "minutes" ? 1 : reminder?.unit === "hours" ? 60 : reminder?.unit === "days" ? 1440 : 10080);
            return {
                minutes,
                unit: reminder?.unit,
            };
        });

        onChangeEmails({reminders: formattedReminders}, intialRender.current);

        if (!intialRender.current) {
            intialRender.current = true;
        }
    }, [reminders]);

    return (
        <div className={cls(classes.togglerWrapper, className, "flex-col flex-align-start flex11-auto")}>
            <div className="flex flex-col flex-justify-between flex-align-start fullw">
                <div className={classes.titleWrapper}>
                    {showIcon ? <div className={classes.iconWrapper}>{<SendReminderIcon />}</div> : null}
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("session.dialog.email_reminders")}
                    </Typography>

                    <Tooltip
                        title={
                            t("session.dialog.email_reminders_info_tooltip") ??
                            "Choose when to receive a reminder of when the session starts."
                        }
                        arrow
                        placement="top"
                    >
                        <InfoOutlinedIcon className={classes.tooltipIcon} />
                    </Tooltip>
                </div>

                <div className={cls(classes.remindersWrapper, "flex flex-col my-10")}>
                    {reminders?.map((reminder: SessionReminderJsonType, index: number) => (
                        <div className="flex flex-row flex-align-center fullw gap-12 mb-10" key={`reminder-${index}`}>
                            <Input
                                className={classes.reminderValueInput}
                                type="number"
                                onChange={handleEmailValueChange.bind(null, index)}
                                value={reminder.minutes}
                                inputProps={{
                                    min: 1,
                                }}
                            />
                            <Select
                                data-id="email-reminder-select-unit"
                                className={classes.selectInput}
                                options={unitOptions}
                                value={reminder.unit}
                                IconComponent={ExpandMoreRoundedIcon}
                                onChange={handleEmailUnitChange.bind(null, index)}
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenuPaper,
                                    },
                                }}
                                disabled={disabled}
                                renderValue={(value: string) =>
                                    reminder?.minutes === 1 ? unitOptions.find((option) => option.value === value)?.textSingular : value
                                }
                            />

                            <IconButton
                                className={classes.deleteButton}
                                onClick={() => {
                                    handleRemoveReminder(index);
                                }}
                            >
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </div>
                    ))}
                    {reminders.length > 4 ? null : (
                        <Button variant="plain" onClick={handleAddReminder} className={classes.addReminderButton}>
                            {t("session.dialog.add_reminder")}
                        </Button>
                    )}
                </div>
            </div>

            {withSmsReminders && (
                <SessionSendReminderSMSMessages
                    disabled={disabled}
                    messagesValue={messagesValue}
                    onChangeMessages={onChangeMessages}
                    triggerHasChanges={triggerHasChanges}
                    isEvent={isEvent}
                    isBooking={isBooking}
                    showIcon={showIcon}
                />
            )}
        </div>
    );
};

export default SessionSendReminder;
