import {useClientEvent} from "@api/events/client";
import {completeOnboardingCard} from "@artifacts/conference/ui/LonelySpeakerOnboardingV2";
import {SessionLifecycle} from "@generated/data";
import env from "@generated/environment";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import Button from "@ui/cdk/Button";
import {cls} from "@ui/cdk/util";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {getPlayerDomainForSession} from "@workhorse/api/csd";
import {useEffect, useState} from "@workhorse/api/rendering";
import updateSessionFlags from "@workhorse/api/session-settings/utils/updateSessionFlags";
import toast from "@workhorse/api/toast";
import {TrackingEventCategory, useMixpanelTracker, useTrackingEvents} from "@workhorse/api/tracking";
import {OnboardingParticipantsFooter} from "@workhorse/components/onboarding-tooltips/onboarding-participants-footer";
import {OnboardingLonelinessType} from "@workhorse/components/onboarding-tooltips/utils";
import {Share} from "@workhorse/components/share";
import ParticipantsDialog from "@workhorse/pages/designer/CreateSession/ParticipantsDialog";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import CopyToClipboard from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {useShallow} from "zustand/react/shallow";
import classes from "./styles/ParticipantsFooter.module.scss";

type ParticipantsFooterProps = {
    sessionId: string;
    parentSessionId?: string;
    isOwner: boolean;
    isAssistant: boolean;
    sendEmailsAutomatically: boolean;
    requestPermissionToJoin: boolean;
    sessionLifecycle: SessionLifecycle;
    sessionName: string;
    isRoom: boolean;
    roomSlug?: string;
    provideSessionPasscode: boolean;
    passcodeToJoin?: string;

    sessionHideIcsGuestList?: boolean;
};

function ParticipantsFooter(props: ParticipantsFooterProps) {
    const {t} = useTranslation();
    const {
        sessionId,
        parentSessionId,
        isOwner,
        isAssistant,
        requestPermissionToJoin,
        sendEmailsAutomatically,
        sessionLifecycle,
        sessionName,
        isRoom,
        roomSlug,
        provideSessionPasscode,
        passcodeToJoin,
        sessionHideIcsGuestList,
    } = props;

    const {next} = useOnboardingSession();

    const {mixpanelTrack} = useMixpanelTracker();

    const [domain, setDomain] = useState<string>(env.csd?.realDomain ?? "");
    const [participantsDialogOpen, setParticipantsDialogOpen] = useState<boolean>(false);

    const {firstName, lastName} = useParticipantsStore(
        useShallow((state) => ({
            firstName: state.currentParticipant?.dataWithNullableEmail.firstName ?? "",
            lastName: state.currentParticipant?.dataWithNullableEmail.lastName ?? "",
        }))
    );

    const handleToggleHideICSGuests = () => {
        updateSessionFlags(sessionId, {
            hideIcsGuestList: !sessionHideIcsGuestList,
        });
    };

    const myParticipantName = `${firstName.trim()} ${lastName.trim()}`;

    const url =
        isRoom && roomSlug
            ? `${window.location.protocol}//${domain}/room/${roomSlug}`
            : `${window.location.protocol}//${domain}/session/${sessionId}`;

    const text = `${myParticipantName} has invited you to a session.

Session name: ${sessionName}

To join the session please use the following link:`;

    const [trackEvent] = useTrackingEvents();

    useEffect(() => {
        getPlayerDomainForSession(sessionId).then((domain) => {
            setDomain(domain);
        });
    }, []);

    const handleOnSessionLinkCopy = () => {
        trackEvent("onboarding-demo2-participants-copy-link", {event_category: TrackingEventCategory.OnboardingActions});

        next({delay: 1000, ifTooltip: "participants-footer"});
        toast(t("g.toast.link_copied_success"), {
            type: "success",
        });
    };

    const handleToggleParticipantsDialog = () => {
        completeOnboardingCard(sessionId, "participants", (type) => mixpanelTrack("frontend-onboarding-lonely-completed", type, "player"));

        trackEvent("onboarding-demo2-participants-invite", {event_category: TrackingEventCategory.OnboardingActions});

        setParticipantsDialogOpen(!participantsDialogOpen);
        // next only on close
        if (participantsDialogOpen) {
            next({delay: 1000, ifTooltip: "participants-footer"});
        }
    };

    const handleShareClick = () => {
        next({delay: 1000, ifTooltip: "participants-footer"});
    };

    const handleOnSessionPasscodeCopy = () => {
        toast(t("g.toast.passcode_copied_success"), {
            type: "success",
        });
    };

    useClientEvent("user-onboarding-loneliness", (id: OnboardingLonelinessType | null) => {
        if (id === "participants" && !isRoom) {
            handleToggleParticipantsDialog();
        }
    });

    return (
        <>
            <OnboardingParticipantsFooter hide={participantsDialogOpen}>
                <div className={cls(classes.root, "rnd-btn")}>
                    {(isOwner || isAssistant) && provideSessionPasscode && passcodeToJoin ? (
                        <CopyToClipboard text={passcodeToJoin} onCopy={handleOnSessionPasscodeCopy}>
                            <Button size="small" variant="tertiary" className={classes.passcode}>
                                <span data-private>{passcodeToJoin}</span>
                                <FileCopyOutlined />
                            </Button>
                        </CopyToClipboard>
                    ) : null}
                    <div className="fullw flex flex-items-center flex-justify-between gap-12">
                        <Share
                            data={{
                                text,
                                url,
                            }}
                            component={Button}
                            variant="tertiary"
                            size="small"
                            className={classes.button}
                            onClick={handleShareClick}
                            fallback={
                                <CopyToClipboard text={url} onCopy={handleOnSessionLinkCopy}>
                                    <Button size="small" variant="tertiary" className={classes.button}>
                                        {t("g.copy_session_link")}
                                    </Button>
                                </CopyToClipboard>
                            }
                        >
                            {t("g.share_session_link")}
                        </Share>
                        {(isOwner || isAssistant) && !isRoom && (
                            <Button
                                data-id="invite-participants"
                                size="small"
                                variant="tertiary"
                                onClick={handleToggleParticipantsDialog}
                                className={classes.button}
                            >
                                {t("g.invite_participants")}
                            </Button>
                        )}
                    </div>
                </div>
            </OnboardingParticipantsFooter>
            {participantsDialogOpen ? (
                <ParticipantsDialog
                    open={participantsDialogOpen}
                    onClose={handleToggleParticipantsDialog}
                    sessionId={parentSessionId ?? sessionId}
                    isAssistant={isAssistant}
                    sendEmailsAutomatically={sendEmailsAutomatically}
                    requestPermissionToJoin={requestPermissionToJoin}
                    sessionLifecycle={sessionLifecycle}
                    placeholder={t("placeholder.add_email_or_search_contacts") ?? ""}
                    onToggleICSGuestList={handleToggleHideICSGuests}
                    hideIcsGuestList={sessionHideIcsGuestList}
                />
            ) : null}
        </>
    );
}

export default ParticipantsFooter;
