import {useEffect} from "@workhorse/api/rendering";
import {useBreakoutConfig} from "./BreakoutConfigProvider";
import BreakoutAssignNotifier from "./BreakoutAssignNotifier";
import BreakoutLeaveNotifier from "./BreakoutLeaveNotifier";
import LeavePageNative from "@workhorse/components/LeavePageNative";
import {useCurrentAgendaItem, useSession} from "@workhorse/providers/SessionDataProviders";
import {useQuery} from "@apollo/client";
import {GetBreakoutConfigDocument} from "@generated/data";
import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";

type Session = ReturnType<typeof useSession>;

function useBreakoutArtifactConfig(skip = false) {
    const currentAgendaItem = useCurrentAgendaItem();

    const [designerState] = useDesignerSessionState();
    const {selectedAgendaItemId} = designerState;

    let artifactId;
    let agendaItemId;
    if (currentAgendaItem.artifact?.artifactId === "flowos/breakout") {
        artifactId = currentAgendaItem.artifact.id;
        agendaItemId = currentAgendaItem.id;
    }

    const {data} = useQuery(GetBreakoutConfigDocument, {
        variables: {
            id: artifactId ?? "",
        },
        skip: skip || artifactId == null || agendaItemId === selectedAgendaItemId,
    });

    const config = data?.artifact?.breakoutRoomsConfig ?? undefined;

    return {config, artifactId};
}

function useBreakoutChildConfig(session: Session, skip = false) {
    const config = session.childOfBreakoutRooms;
    const artifactId = config?.artifactIdOfBreakoutRooms;

    if (skip) {
        return {};
    }

    if (!config) {
        return {};
    }

    return {config, artifactId};
}

function useBreakoutRoomsConfig() {
    const session = useSession();
    const [, setBreakoutInfo] = useBreakoutConfig();

    const isChild = session.childOfBreakoutRooms != null;
    const {config: configFromChild, artifactId: idFromChild} = useBreakoutChildConfig(session, !isChild);
    const {config: configFromArtifact, artifactId: idFromArtifact} = useBreakoutArtifactConfig(isChild);

    const config = isChild ? configFromChild : configFromArtifact;
    const artifactId = isChild ? idFromChild : idFromArtifact;

    useEffect(() => {
        setBreakoutInfo(config);
        return () => {
            setBreakoutInfo(undefined);
        };
    }, [config, setBreakoutInfo]);

    return {config, artifactId};
}

type BreakoutPlayerEntryProps = {
    sessionCurrentOrder: number;
    sessionStarted: boolean;
    sessionId: string;
    isChild: boolean;
    myParticipantId: string;
    isLobby: boolean;
    sessionIsPublished: boolean;
};

function BreakoutPlayerEntry(props: BreakoutPlayerEntryProps) {
    const {sessionId, sessionStarted, isChild, myParticipantId, isLobby, sessionIsPublished} = props;

    const {config: borConfig, artifactId: borArtifactId} = useBreakoutRoomsConfig();
    const borParentId = borConfig?.parentOfBreakoutRooms.sessionId;
    const participantIdInParent = borConfig?.participantsMap.find((room) => room.participantInBreakoutRoom?.id === myParticipantId)
        ?.participantInParent.id;

    return (
        <>
            {!!borConfig && (
                <BreakoutAssignNotifier
                    isChild={isChild}
                    borConfig={borConfig}
                    sessionStarted={sessionStarted}
                    myParticipantId={myParticipantId}
                    sessionId={sessionId}
                    borAgendaItemId={borArtifactId}
                />
            )}
            {!!borConfig && <BreakoutLeaveNotifier />}
            <LeavePageNative
                title="Leave session"
                content="You are about to leave this session. Do you want to continue?"
                sessionId={sessionId}
                parentSessionId={borParentId}
                participantId={myParticipantId}
                participantIdInParent={participantIdInParent}
                disabled={isLobby || !sessionIsPublished}
            />
        </>
    );
}

export default BreakoutPlayerEntry;
