import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {useMobile} from "@workhorse/providers/MobileProvider";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@ui/cdk/Button";
import classes from "../styles/PlayerHeader.module.scss";
import Typography from "@ui/cdk/Typography/Typography";
import {useTranslation} from "react-i18next";

export default function PlayerSessionSettingsButton() {
    const {t} = useTranslation();
    const {show} = useSessionSettingsDialogDisplay();
    const {isMobileOrTablet} = useMobile();

    const handleOnShowSettings = () => {
        show(isMobileOrTablet ? "device" : "device.video");
    };

    return (
        <Button
            type="button"
            className={classes.settingsBtn}
            startIcon={<SettingsIcon />}
            onClick={handleOnShowSettings}
            variant="tertiary"
            data-id="player-button-settings"
            aria-label="Open settings dialog"
        >
            {!isMobileOrTablet && (
                <Typography color="secondary" fontWeight="bolder">
                    {t("header.player.settings")}
                </Typography>
            )}
        </Button>
    );
}
