import {useLocalResizeObserver} from "@ui/cdk/LocalResizeObserver";
import {cls} from "@ui/cdk/util/util";
import {ReactNode, useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {useFullScreenState} from "@workhorse/providers/state";
import {useAudienceScroll} from "./hooks/useAudienceScroll";
import SessionAudienceScrollControls from "./SessionAudienceScrollControls";
import classes from "./style/ConferenceAudience.module.scss";

type ConferenceAudienceScrollDesktopProps = {
    children: ReactNode;
    numberOfItems: number;
    onMount?: (node: HTMLDivElement | null) => void;
};

// padding left right = 10px at a height of 90 results in 16:9
const defaultParticipantBlockWidth = 180;

function getFitCount(width: number, blockSize: number) {
    return Math.floor(width / blockSize);
}

export function ConferenceAudienceScrollDesktop(props: ConferenceAudienceScrollDesktopProps) {
    const {onMount, numberOfItems, children} = props;
    const [spacing, setSpacing] = useState(0);
    const [fits, setFits] = useState(true);

    const [visibleItems, setVisibleItems] = useState(0);
    const [wrapperWidth, setWidth] = useState(0);

    const [root] = useState<HTMLDivElement | null>(() => document.querySelector(":root"));

    const onResize = useCallback((width: number) => {
        setWidth(width);
    }, []);
    const [setWrapperNode] = useLocalResizeObserver(onResize);

    const {setScrollNode, scrollOneItem, startContinousScroll, stopContinousScroll, alignItems, reachedLeftEnd, reachedRightEnd} =
        useAudienceScroll({
            spacing,
            visibleItems,
            wrapperWidth,
            itemWidth: defaultParticipantBlockWidth,
            disableContinuousScroll: fits,
        });

    const isFullScreen = useFullScreenState();

    // compute spacing
    useEffect(() => {
        const actualCount = numberOfItems;

        const fitCountMaxPadding = getFitCount(wrapperWidth, defaultParticipantBlockWidth + 20);
        if (fitCountMaxPadding >= actualCount) {
            setFits(true);
            setSpacing(20 / 2);
            setVisibleItems(fitCountMaxPadding);
            return;
        }

        const fitCount = getFitCount(wrapperWidth, defaultParticipantBlockWidth);

        setFits(fitCount >= actualCount);
        setVisibleItems(fitCount);

        const spacing = (wrapperWidth - 8 - fitCount * 160) / (fitCount - 1);
        setSpacing((spacing - 20) / 2);
    }, [wrapperWidth, isFullScreen, numberOfItems]);

    useEffect(() => {
        root?.style.setProperty("--app-participant-spacing", `0 ${spacing + 10}px`);
    }, [root, spacing]);

    return (
        <>
            <div ref={setWrapperNode} className={cls(classes.root)}>
                <div data-id="audience_container" ref={onMount} className={classes.container}>
                    <div ref={setScrollNode} className={cls(classes.scrollable)}>
                        {children}
                    </div>
                </div>
            </div>

            {/* <SessionAudienceScrollControls
                scrollOneItem={scrollOneItem}
                startContinuousScroll={startContinousScroll}
                stopContinuousScroll={stopContinousScroll}
                disableNext={reachedRightEnd || fits}
                disablePrev={reachedLeftEnd || fits}
            /> */}
        </>
    );
}
