import React, {createElement, memo} from "@workhorse/api/rendering";

import FileResults from "./FileResults";
import RecentUsedResults from "./RecentUsedResults";
import ToolResults from "./ToolResults";
import AgendaResults from "./AgendaResults";

import {CommandPaletteSearchResults, usePaletteResultList} from "./CommandPaletteResultsProvider";
import {usePaletteActiveCateg, usePaletteHasSearchString} from "./CommandPaletteProviders";
import {usePaletteActionArg} from "./PaletteActionProvider";

type ResultCompType = {
    [K in keyof CommandPaletteSearchResults]: React.ComponentType<any>;
};

const ResultComp: ResultCompType = {
    files: FileResults,
    tools: ToolResults,
    agendas: AgendaResults,
    recent: RecentUsedResults,
};

export default memo(
    function RenderBEsearchResults(props: {
        rootRef: HTMLDivElement | undefined;
        scrollIntoView: (node: HTMLElement, location: "start" | "end") => void;
    }) {
        const {hasSearchStr} = usePaletteHasSearchString();
        const {resultList} = usePaletteResultList();
        const {activeCateg, setPaletteActiveCateg} = usePaletteActiveCateg();

        const {setActionArg} = usePaletteActionArg();

        return (
            <>
                {resultList.map((type) => {
                    return createElement(
                        ResultComp[type],
                        {
                            key: type,
                            hasSearchStr,
                            includePath: !activeCateg.name,
                            setPaletteActiveCateg,
                            setActionArg,
                            rootRef: props.rootRef,
                            scrollIntoView: props.scrollIntoView,
                        },
                        null
                    );
                })}
            </>
        );
    },
    (prev, next) => prev.rootRef === next.rootRef && prev.scrollIntoView === next.scrollIntoView
);
