import Dialog from "@ui/core/components/Dialog";
import DialogTitle from "@ui/core/components/DialogTitle";
import {useCallback, useEffect, useState} from "@workhorse/api/rendering";
import {DisplaySource, getDesktopApiOrThrow} from "@sessions/desktop-api";
import classes from "./SourceSelectorDialog.module.scss";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@ui/core/components/IconButton";
import browserInfo from "@workhorse/api/BrowserInfo";

interface SourceSelectorDialogProps {
    open: true;
    scan: boolean;
    onResolve: (source: DisplaySource) => void;
    onReject: (reason: string) => void;
}

export function useSelectScreenShareSource() {
    const [dialogProps, setDialogProps] = useState<SourceSelectorDialogProps>();

    const selectSource = useCallback(() => {
        let onResolve = (source: DisplaySource) => {
            // init
        };

        let onReject = (reason: string) => {
            // init
        };

        let promise = new Promise<DisplaySource>((resolve, reject) => {
            onResolve = (source: DisplaySource) => {
                resolve(source);
                setDialogProps(undefined);
            };

            onReject = (reason: string) => {
                reject(reason);
                setDialogProps(undefined);
            };
        });

        setDialogProps({
            open: true,
            scan: true,
            onResolve,
            onReject,
        });

        return promise;
    }, []);

    return {
        dialogProps,
        selectSource,
    };
}

function SourceItem(props: {source: DisplaySource; onClick: () => void}) {
    return (
        <div onClick={props.onClick} className={classes.source}>
            <Typography className={classes.sourceName}>{props.source.name}</Typography>
            <div className={classes.sourceThumbnailContainer}>
                <img className={classes.sourceThumbnail} src={props.source.thumbnail} />
                {props.source.icon && <img className={classes.sourceIcon} src={props.source.icon} />}
            </div>
        </div>
    );
}

export function SourceSelectorDialog(props: SourceSelectorDialogProps) {
    const {scan, onResolve, onReject} = props;
    const [sources, setSources] = useState<DisplaySource[]>();

    useEffect(() => {
        if (scan !== true) {
            return;
        }

        const fetchSources = async () => {
            try {
                const desktopApi = getDesktopApiOrThrow();
                const sources = await desktopApi.getDisplaySources();

                if (sources.length === 0) {
                    onReject("No sources found to share! Might require additional permissions.");
                    return;
                }

                setSources(sources);
            } catch (e) {
                console.error(e);
                onReject(e.message);
            }
        };

        fetchSources();

        const id = setInterval(() => fetchSources(), 1000);

        return () => clearInterval(id);
    }, [scan, onResolve, onReject]);

    const items = sources?.map((source) => (
        <SourceItem
            source={source}
            key={source.id}
            onClick={() => {
                props.onResolve(source);
            }}
        />
    ));

    const handleClose = () => {
        props.onReject("abort");
    };

    if (items == null) {
        return null;
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <div className={classes.dialogHeader}>
                <Typography fontWeight="boldest" variant="xl" color="octonary">
                    Select a screen to share
                </Typography>

                <IconButton className="ml-auto" onClick={handleClose}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>

            <div className={classes.sources}>{items}</div>
        </Dialog>
    );
}
