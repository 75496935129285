import {AgendaTemplateFragment} from "@generated/data";
import TableBody from "@ui/cdk/Table/TableBody";
import {useState, useEffect} from "@workhorse/api/rendering";
import classes from "./style/AgendaTemplatesTableBody.module.scss";
import AgendaTemplatesTableRow from "./AgendaTemplatesTableRow";

type AgendaTemplatesTableBodyProps = {
    isSearching: boolean;
    agendaTemplates: AgendaTemplateFragment[];
    hasActionsColumn: boolean;
    onClickAgenda: (agendaTemplateId: string) => void;
    tagName: string;
    duplicateAgendaTemplate: (agendaTemplateId: string) => void;
};
const AgendaTemplatesTableBody = (props: AgendaTemplatesTableBodyProps) => {
    const {agendaTemplates, hasActionsColumn, isSearching, onClickAgenda, duplicateAgendaTemplate, tagName} = props;

    const [node, setNode] = useState<HTMLElement | null>(null);
    const [atTop, setAtTop] = useState(true);

    useEffect(() => {
        if (!node) {
            return;
        }

        node.scrollTo({
            top: 0,
            behavior: "auto",
        });
    }, [isSearching, node]);

    useEffect(() => {
        if (!node) {
            return;
        }

        node.addEventListener("scroll", () => {
            setAtTop(node.scrollTop === 0);
        });
    }, [node]);

    return (
        <TableBody ref={setNode} className={classes.root}>
            {agendaTemplates.map((agenda, index) => (
                <AgendaTemplatesTableRow
                    key={agenda.id}
                    atTop={atTop}
                    index={index}
                    table={node}
                    agenda={agenda}
                    hasActionsColumn={hasActionsColumn}
                    onClickAgenda={onClickAgenda}
                    tagName={tagName}
                    onDuplicateAgendaTemplate={duplicateAgendaTemplate.bind(null, agenda.id)}
                />
            ))}
        </TableBody>
    );
};

export default AgendaTemplatesTableBody;
