import {PublicBookingEventFragment} from "@generated/data";
import {useBreakpoint} from "@workhorse/providers/BreakpointProvider";
import Button from "@ui/cdk/Button";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useTranslation} from "react-i18next";
import {ScheduleBookingInput} from "@workhorse/api/booking";
import Radio from "@ui/cdk/Radio";
import Checkbox from "@ui/cdk/Checkbox";
import {AcceptedTimezone} from "@generated/timezones";
import Typography from "@ui/cdk/Typography";
import Tooltip from "@ui/cdk/Tooltip";
import {ReactComponent as VisaIcon} from "../../../assets/media/visa_icon.svg";
import {ReactComponent as MastercardIcon} from "../../../assets/media/mastercard_icon.svg";
import {ReactComponent as PaypalIcon} from "../../../assets/media/paypal_icon.svg";
import {ReactComponent as StripeBadge} from "../../../assets/media/powered_by_stripe.svg";
import EditorSerializedContent from "@workhorse/components/editor/EditorSerializedContent";
import detailsClasses from "../styles/BookEventDetails.module.scss";
import classes from "../styles/BookEventCheckout.module.scss";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import {cls} from "@ui/cdk/util";
import {useFormikContext} from "formik";
import {isEditorEmpty, parseAsTipTapJSON} from "@api/editor";

type Props = {
    event: PublicBookingEventFragment;
    values: ScheduleBookingInput;
    timezone: AcceptedTimezone;
    duration?: number;
    onBack(): void;
    onCheckout(provider?: string): void;
    bookingError?: string | null;
    bookingTimedOut?: boolean;
};

export const BookEventCheckout = (props: Props) => {
    const {event, values, timezone, duration, onBack, onCheckout, bookingError, bookingTimedOut} = props;
    const {setFieldValue} = useFormikContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {t} = useTranslation();
    const breakpoint = useBreakpoint();
    const isMobileOrTablet = ["xs", "sm", "md"].indexOf(breakpoint) !== -1;
    const paymentConfig = event.payments?.[0];

    useEffect(() => {
        if (bookingError || bookingTimedOut) {
            setIsSubmitting(false);
        }
    }, [bookingError, bookingTimedOut]);

    const handleCheckout = () => {
        setIsSubmitting(true);
        onCheckout();
    };

    if (!paymentConfig || !paymentConfig.paymentProviders?.length) {
        return null;
    }

    const multiple = paymentConfig.paymentProviders.length > 1;
    const hasTerms = paymentConfig.terms ? !isEditorEmpty(parseAsTipTapJSON(paymentConfig.terms)) : false;

    return (
        <div className={detailsClasses.root}>
            <div className={detailsClasses.header}>
                <div className={detailsClasses.title}>{t("g.checkout")}</div>
            </div>
            <div className="my-20">
                <Typography>{multiple ? t("g.select_payment_method") : t("g.payment_method")}</Typography>
                <div className="mt-6 flex flex-col align-center gap-12">
                    {paymentConfig.paymentProviders.length > 1 ? (
                        paymentConfig.paymentProviders.map((provider) => (
                            <FormControlLabel
                                data-id="booking-payments-provider"
                                classes={{label: "fullw"}}
                                className={cls(classes.providerOption)}
                                checked={values.paymentProvider === provider?.name}
                                control={<Radio className={classes.providerRadio} variant="blue" />}
                                label={
                                    <div className="fullw flex flex-align-start flex-justify-between">
                                        <div>
                                            <Typography variant="lg" fontWeight="bolder">
                                                {provider?.name === "stripe-payments" ? t("g.credit_card") : t("g.pay_pal")}
                                            </Typography>
                                            <Typography color="blueGray400">{t("checkout.credit_card.info")}</Typography>
                                            {provider?.name === "stripe-payments" ? (
                                                <span className="flex my-12">
                                                    <StripeBadge width="150px" />
                                                </span>
                                            ) : null}
                                        </div>
                                        {provider?.name === "stripe-payments" ? (
                                            <div className="flex flex-align-center mt-6 flex-justify-end gap-8">
                                                <span className="flex">
                                                    <VisaIcon width="44px" height="31px" />
                                                </span>
                                                <span className="flex">
                                                    <MastercardIcon width="44px" height="31px" />
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-align-center mt-6 flex-justify-end gap-8">
                                                <span className="flex">
                                                    <PaypalIcon width="44px" height="31px" />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                }
                                labelPlacement="end"
                                onClick={() => setFieldValue("paymentProvider", provider?.name)}
                            />
                        ))
                    ) : (
                        <div className={cls(classes.providerOption, classes.providerOptionSingle)}>
                            <div className={cls("fullw flex flex-align-start flex-justify-between")}>
                                <div>
                                    <Typography variant="lg" fontWeight="bolder">
                                        {paymentConfig.paymentProviders[0]?.name === "stripe-payments"
                                            ? t("g.credit_card")
                                            : t("g.pay_pal")}
                                    </Typography>
                                    {paymentConfig.paymentProviders[0]?.name === "stripe-payments" ? (
                                        <Typography color="blueGray400">{t("checkout.credit_card.info")}</Typography>
                                    ) : (
                                        <Typography color="blueGray400">{t("checkout.pay_pal.info")}</Typography>
                                    )}
                                    {paymentConfig.paymentProviders[0]?.name === "stripe-payments" ? (
                                        <span className="flex my-10">
                                            <StripeBadge width="150px" />
                                        </span>
                                    ) : null}
                                </div>
                                {paymentConfig.paymentProviders[0]?.name === "stripe-payments" ? (
                                    <div className={cls("flex flex-align-center mt-6 flex-justify-end gap-8", classes.paymentsIcons)}>
                                        <span className="flex">
                                            <VisaIcon width="44px" height="31px" />
                                        </span>
                                        <span className="flex">
                                            <MastercardIcon width="44px" height="31px" />
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex flex-align-center mt-6 flex-justify-end gap-8">
                                        <span className="flex">
                                            <PaypalIcon width="44px" height="31px" />
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.separator} />
            <div className={cls("my-20 fullh", classes.termsWrapper)}>
                {hasTerms ? (
                    <>
                        <Typography variant="xl" fontWeight="bolder">
                            {t("g.terms_amp_conditions")}
                        </Typography>
                        <div className={classes.termsContent}>
                            <EditorSerializedContent content={paymentConfig.terms} className={classes.terms} />
                        </div>
                        <div className={classes.separator} />
                        <FormControlLabel
                            data-id="booking-payments-terms"
                            name="paymentTerms"
                            classes={{label: "fullw"}}
                            className={cls(classes.termsCheckbox)}
                            checked={values.paymentTerms ?? false}
                            control={<Checkbox variant="blue" />}
                            label={<Typography fontWeight="bold">{t("checkout.terms_agree")}</Typography>}
                            labelPlacement="end"
                            onChange={() => setFieldValue("paymentTerms", !values.paymentTerms)}
                        />
                    </>
                ) : null}
            </div>
            <div className={detailsClasses.footer}>
                <Tooltip
                    arrow
                    placement="top"
                    title={
                        bookingError === "owner_booked"
                            ? t(`booking.page.error_schedule_owner_booked`) ??
                              "Unfortunately this slot is not available anymore, please choose another one."
                            : t(`booking.page.error_schedule_network_failure`) ??
                              "Could not schedule the session due to a network failure. Please try again."
                    }
                    open={!!bookingError || bookingTimedOut}
                    disableFocusListener={true}
                    disableHoverListener={true}
                    disableTouchListener={true}
                >
                    <Button
                        loading={isSubmitting}
                        onClick={() => handleCheckout()}
                        disabled={isSubmitting || !Boolean(values.paymentProvider && values.paymentTerms)}
                        data-id="schedule-session"
                    >
                        {t("checkout.action.proceed_to_payment")}
                    </Button>
                </Tooltip>
                <Button
                    disabled={isSubmitting}
                    className={detailsClasses.backBtn}
                    onClick={onBack}
                    variant={isMobileOrTablet ? "tertiary" : "quaternary"}
                >
                    {t("g.back")}
                </Button>
            </div>
        </div>
    );
};
