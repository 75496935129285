import Typography from "@ui/cdk/Typography";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import {cls} from "@ui/cdk/util/util";
import classes from "../style/AgendaActionsMenu.module.scss";
import {isMacOS} from "@workhorse/util";
import {useTranslation} from "react-i18next";

const DetachAgenda = () => {
    const {t} = useTranslation();
    const isMac = isMacOS();

    return (
        <div className={cls("flex flex-row flex-items-center flex-justify-between fullw", classes.detachAction)}>
            <div className={cls("flex flex-row flex-items-center")}>
                <RemoveCircleOutlineRoundedIcon fontSize="small" className="mr-10" />
                <Typography color="eleventh" fontWeight="bold" className="mr-4">
                    {t("agenda.menu.remove_agenda")}
                </Typography>
            </div>

            <div className="flex flex-align-center">
                <div className={classes.commandKey}>{!isMac ? "Alt" : "⌥"}</div>
                <div className={classes.commandKey}>B</div>
            </div>
        </div>
    );
};

export default DetachAgenda;
