import {isFn} from "@common/utils/isfn";
import {makeVar, useReactiveVar} from "@workhorse/api/data";
import React, {useCallback, useRef, useState} from "@workhorse/api/rendering";
import * as state from "../state";

export default state;

function useCoolState<TState>(init: TState | (() => TState)): [TState, React.Dispatch<React.SetStateAction<TState>>, () => TState];
function useCoolState<TState = undefined>(): [
    TState | undefined,
    React.Dispatch<React.SetStateAction<TState | undefined>>,
    () => TState | undefined
];
function useCoolState<TState>(init?: TState | (() => TState)) {
    const initialValue = init ? (isFn(init) ? init() : init) : undefined;
    const innerRef = useRef(initialValue);

    const [value, setInnerValue] = useState(initialValue);

    const getValue = useCallback(() => innerRef.current, []);
    const setValue = useCallback((newValue: TState | ((previousValue?: TState) => TState)) => {
        setInnerValue((pv) => {
            const nv = isFn(newValue) ? newValue(pv) : newValue;
            innerRef.current = nv;

            return nv;
        });
    }, []);

    return [value, setValue, getValue];
}

export {makeVar, useReactiveVar, useState, useCoolState};
