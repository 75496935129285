import Avatar from "@ui/cdk/Avatar";
import React from "@workhorse/api/rendering";

import style from "./styles/AudienceParticipant.module.scss";

type AudienceParticipantInitialsProps = {
    initials: string;
};

export function AudienceParticipantInitials(props: AudienceParticipantInitialsProps) {
    const {initials} = props;

    return (
        <div className={style.initialsWrapper}>
            <div className={style.initialsContainer}>
                <Avatar className={style.initials} key="avatar">
                    {initials}
                </Avatar>
                {/* <div
                    className={style.initialsOuter}
                    style={{
                        background: `conic-gradient(from 0.5turn, ${hash}, ${hashEnd})`,
                    }}
                /> */}
            </div>
        </div>
    );
}
