import {calendarExternalEventsLoading} from "@workhorse/api/calendar";
import loadable from "@workhorse/api/loadable";
import {memo, useEffect} from "@workhorse/api/rendering";
import {BaseRouteMatch, RouteHOCProps} from "@workhorse/declarations";

// there is no guarantee the user will land on the base every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const Base = loadable(() => import("./Base"));

export type BaseHocProps = RouteHOCProps<BaseRouteMatch>;

// a HOC for the Base and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child
function BaseHOC(props: BaseHocProps): JSX.Element {
    useEffect(() => {
        // We make sure to always load all of the external calendars
        // In order to provide a proper syncing experience
        // https://flowos.atlassian.net/browse/FOSS-7941
        calendarExternalEventsLoading("load");
    }, []);

    return (
        <div className="route-hoc base-hoc" key="base-hoc">
            <Base />
        </div>
    );
}

export default memo(BaseHOC);
