import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {readRemoteUser} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useMemo} from "@workhorse/api/rendering";
import {ConferenceParticipantStatus, ParticipantInviteStatus, ParticipantSource, ParticipantStatus} from "@generated/data";

export function useSpeakers() {
    return useParticipantsStore((state) => state.conferenceSpeakers);
}

export function useJoinedSpeaker() {
    return useParticipantsStore((state) => state.conferenceJoinedSpeakers);
}

export function usePendingSpeakers() {
    return useParticipantsStore((state) => state.conferencePendingSpeakers);
}

export function useForgedParticipants(): Participant[] {
    return useMemo(() => {
        const user = readRemoteUser();
        const userData = user?.getRemoteUser?.user;
        if (!userData) {
            return [];
        }
        return [
            {
                __typename: "Participant" as const,
                conferenceStatus: ConferenceParticipantStatus.Participant,
                dataWithNullableEmail: {
                    email: userData.email ?? null,
                    __typename: "ParticipantDataWithNullableEmail" as const,
                    firstName: userData.firstName,
                    isGuest: false,
                    token: "",
                    lastName: userData.lastName,
                    avatar: userData.profilePicture,
                    userId: userData.id,
                },
                isApproved: true,
                inviteStatus: ParticipantInviteStatus.NeedsAction,
                isOwner: false,
                mockedParticipant: false,
                muted: false,
                rbac: {},
                status: ParticipantStatus.JoinedSession,
                visible: true,
                isRecorder: false,
                changedBy: null,
                statusChangedBy: null,
                askToStartCamera: false,
                askToUnmute: false,
                createdAt: new Date(),
                hasAttendedSession: true,
                id: "self-forged-" + userData.id,
                invitedByTheOwner: false,
                source: ParticipantSource.App,
                updatedAt: new Date(),
                submittedPasscode: true,
            },
        ];
    }, []);
}
