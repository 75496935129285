import {makeStyles} from "@material-ui/core";
import Typography from "@ui/cdk/Typography";
import React from "@workhorse/api/rendering";
import {WithChildren, WithClassName} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";

const useStyles = makeStyles((theme) => ({
    dialogTitleRoot: {
        justifyContent: "flex-start",
        height: 80,
        flex: "0 0 80px",
        paddingLeft: 24,
        borderBottom: `solid 1px ${theme.main.palette.divider.primary}`,
        "&.no-bottom-border": {
            borderBottom: "none",
        },
        "&.small": {
            height: 50,
            flex: "0 0 50px",
        },
    },
}));

type DialogTitleProps = {
    title?: string | JSX.Element;
    small?: true;
    noBottomBorder?: true;
} & WithClassName &
    WithChildren;

function DialogTitle(props: DialogTitleProps) {
    const classes = useStyles();
    const {title, noBottomBorder = false, small = false, children} = props;

    return (
        <div
            className={cls(
                "flex flex-align-center flex00-auto",
                classes.dialogTitleRoot,
                noBottomBorder && "no-bottom-border",
                small && "small",
                props.className
            )}
        >
            {/* <Typography variant="lg" component="h5" className="dialog-title"> */}
            {children ?? title}
            {/* </Typography> */}
        </div>
    );
}

export default DialogTitle;
