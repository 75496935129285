import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {markAsSeen, useSeenSection} from "@workhorse/api/seen";
import {useIsPreJoin, useSession} from "@workhorse/providers/SessionDataProviders";

export function usePreJoinOnboarding() {
    const isOwner = useParticipantsStore((state) => state.currentParticipant?.isOwner);
    const isPreJoin = useIsPreJoin();
    const userSeenOnboardingInitialPreJoin = useSeenSection("GLOBALS.userOnboardingInitialPreJoin");
    const session = useSession();

    const isRoom = session?.room?.active;
    const isEvent = session?.event;
    const isBooking = session?.isBooking;
    const userSeenOnboarding = useSeenSection("GLOBALS.getStartedWidget");

    const showPreJoinOnboarding = isPreJoin && isOwner && !userSeenOnboarding && !isRoom && !isEvent && !isBooking;

    const dismissPreJoinOnboarding = () => {
        markAsSeen({
            GLOBALS: {
                userOnboardingInitialPreJoin: true,
            },
        });
    };

    return {showPreJoinOnboarding, dismissPreJoinOnboarding};
}
