import React, {useState, useEffect} from "react";
import Snackbar from "@ui/core/components/Snackbar";
import classes from "./styles/ToolConfirmationModeNotification.module.scss";
import Typography from "@ui/cdk/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {cls} from "@ui/cdk/util/util";
import toast from "@workhorse/api/toast";
import CircularProgressWithLabel from "@ui/cdk/CircularProgressWithLabel/CircularProgressWithLabel";
import Button from "@ui/cdk/Button";
import {useTranslation} from "react-i18next";

interface ConvertingNotificationProps {
    handleConfirm: () => void;
    open: boolean;
    onNotificationClose: () => void;
    isAgenda?: boolean;
    inLobby?: boolean;
}

const ConvertingNotification = (props: ConvertingNotificationProps) => {
    const {t} = useTranslation();
    const {handleConfirm, open, inLobby} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [progress, setProgress] = useState(100);
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress == 0 ? 100 : prevProgress - 10));
        }, 900);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress === 0) {
            handleOnControlRequest();
        }
    }, [progress]);

    useEffect(() => {
        setIsOpen(open);
    }, []);

    const handleCloseSnackBar = (e) => {
        e.stopPropagation();
        setIsOpen(false);
        props.onNotificationClose();
    };

    const handleOnControlRequest = (e?: any) => {
        e?.stopPropagation();
        setIsOpen(false);
        handleConfirm();
    };
    if (!isOpen) return null;
    if (inLobby) return null;

    return (
        <div
            data-id="tool-confirmation-notification"
            className={classes.root}
            onMouseEnter={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
            onMouseOver={(e) => e.stopPropagation()}
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={classes.circularMessageWrapper}>
                    <CircularProgressWithLabel className="svg-blue-500" type="long" variant="determinate" thickness={5} value={progress} />

                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            {props.isAgenda
                                ? t("player.notifications.tool.add_this_agenda") ?? "Add this agenda?"
                                : t("player.notifications.tool.embed_this_resource") ?? "Embed this resource?"}
                        </Typography>
                        <Typography variant="base" className={classes.description}>
                            {props.isAgenda
                                ? t("player.notifications.tool.agenda_confirmation")
                                : t("player.notifications.tool.resource_confirmation")}
                        </Typography>
                    </div>
                </div>
                <div className={cls(classes.buttonsContainer)}>
                    <Button
                        data-id="cancel"
                        variant="quaternary"
                        onClick={handleCloseSnackBar}
                        className={classes.notificationSecondaryButton}
                    >
                        {t("g.cancel") ?? "Cancel"}
                    </Button>
                    <Button data-id="confirm" onClick={handleOnControlRequest}>
                        {t("g.confirm") ?? "Confirm"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConvertingNotification;
