import {PublicBookingEventFragment} from "@generated/data";
import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util";
import {EditorSerializedContent} from "@workhorse/components/editor";
import classes from "../styles/BookEventDateTime.module.scss";
import {PropsWithChildren, useLayoutEffect, useRef, useState} from "react";
import {ScheduleBookingInput} from "@workhorse/api/booking";
import {BookingStep} from "./BookEvent";
import {AcceptedTimezone} from "@common/utils/timezones";
import BookDetails from "./BookDetails";
import {useMobile} from "@workhorse/providers/MobileProvider";
import Button from "@ui/cdk/Button";
import {useTranslation} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";

type BookEnvetContentWrapper = PropsWithChildren<{
    event: PublicBookingEventFragment;
    isBookADemo?: boolean;
    showBookingTitleTooltip?: boolean;
    bookingTitleTooltip: string;
    duration?: number;
    values: ScheduleBookingInput;
    timezone: AcceptedTimezone;
    step: BookingStep;
    isHalfDayClock: boolean;
    setIsHalfDayClock?: (state: boolean) => void;
    onChangeTimeZone?: (timeZone: AcceptedTimezone) => void;
    handleCloseBookADemo?: () => void;
}>;

type UiState = {
    isTextShrunk: boolean;
    isContentTooBig: boolean;
};

const BookEventContentWrapper = (props: BookEnvetContentWrapper) => {
    const {
        event,
        isBookADemo,
        showBookingTitleTooltip,
        bookingTitleTooltip,
        duration,
        children,
        step,
        values,
        timezone,
        isHalfDayClock,
        setIsHalfDayClock,
        onChangeTimeZone,
        handleCloseBookADemo,
    } = props;
    const {t} = useTranslation();
    const [uiState, setUiState] = useState<UiState>(() => ({
        isContentTooBig: false,
        isTextShrunk: false,
    }));
    const {isMobileOrTablet} = useMobile();

    const descriptionRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (isMobileOrTablet) {
            const divHeight = descriptionRef?.current?.getBoundingClientRect().height ?? 0;
            const maxHeight = 110;
            if (divHeight > maxHeight) {
                setUiState((prevState) => ({...prevState, isContentTooBig: true, isTextShrunk: true}));
            } else {
                setUiState((prevState) => ({...prevState, isContentTooBig: false, isTextShrunk: false}));
            }
        } else {
            setUiState((prevState) => ({...prevState, isContentTooBig: false, isTextShrunk: false}));
        }
    }, [event.description, isMobileOrTablet]);

    const toggleBoxDescriptionContent = (event: React.MouseEvent<HTMLSpanElement>) => {
        setUiState((prevState) => ({
            ...prevState,
            isTextShrunk: !prevState.isTextShrunk,
        }));

        event.stopPropagation();
    };

    const withDetails = step === BookingStep.DETAILS || step === BookingStep.CHECKOUT;

    return (
        <div className={cls(classes.root, isBookADemo && classes.rootBookADemo)}>
            <div data-id="booking-details" className={classes.details}>
                <div className="fullw flex flex-row flex-align-center flex-justify-between">
                    <Tooltip title={showBookingTitleTooltip ? bookingTitleTooltip : ""} placement="bottom" arrow>
                        <div className={classes.title}>{event.name}</div>
                    </Tooltip>
                    {isBookADemo && <CloseIcon className={classes.closeIcon} onClick={handleCloseBookADemo} />}
                </div>
                <BookDetails
                    event={event}
                    duration={duration}
                    date={withDetails ? values.date : undefined}
                    timezone={timezone}
                    isHalfDayClock={isHalfDayClock}
                    setIsHalfDayClock={setIsHalfDayClock}
                    onChangeTimeZone={onChangeTimeZone}
                    wrap
                />
                <div className={classes.separatorHorizontal} />
                <div className={cls(classes.info)}>
                    <div
                        className={cls(classes.infoDescription, isMobileOrTablet && !uiState.isTextShrunk && classes.infoDescriptionFull)}
                        data-id="booking-description"
                        ref={descriptionRef}
                    >
                        {event.description ? (
                            <EditorSerializedContent content={event.description} className={cls(classes.subTitle)} />
                        ) : null}
                    </div>
                    {uiState.isContentTooBig ? (
                        <div className={classes.showMoreToggle}>
                            {uiState.isTextShrunk ? (
                                <Button
                                    size="smallest"
                                    variant="plain"
                                    onClick={toggleBoxDescriptionContent}
                                    className={cls(classes.toggleContentText, classes.showMore)}
                                >
                                    [{t("g.show_more")}]
                                </Button>
                            ) : (
                                <Button
                                    className={classes.toggleContentText}
                                    size="smallest"
                                    variant="plain"
                                    onClick={toggleBoxDescriptionContent}
                                >
                                    [{t("g.show_less")}]
                                </Button>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={classes.separator} />
            <div className={classes.booking}>{children}</div>
        </div>
    );
};

export default BookEventContentWrapper;
