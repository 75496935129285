import Input from "@ui/cdk/Input";
import {cls} from "@ui/cdk/util/util";
import designer from "@workhorse/api/designer";
import {useEffect, useRef, useState, memo, useCallback} from "@workhorse/api/rendering";
import {generateAgendaItemName} from "@workhorse/pages/templates/utils";
import classes from "./style/AgendaItemTitle.module.scss";

type AgendaItemTitleProps = {
    value: string;
    id: string;
    readOnly?: boolean;
    // dialogState?: boolean | undefined;
    className?: string;
    onBlur?: () => Promise<void>;
    onFocusInput?: () => void;
    onFocusAgendaItem?: () => Promise<void>;
    inEventSettings?: boolean;
    order: number;
};

const AgendaItemTitle = (props: AgendaItemTitleProps) => {
    const {value, id, readOnly, className, onBlur: commit, onFocusInput, onFocusAgendaItem, inEventSettings} = props;

    const [title, setTitle] = useState(value);
    const [isTitleFocused, setIsTitleFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTitle(value);
    }, [value]);

    const onChangeTitle = useCallback((e) => {
        setTitle(e.target.value);
    }, []);

    const onBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            // Untitled agenda item

            setIsTitleFocused(false);
            const relatedTarget = event?.relatedTarget as HTMLElement;
            const isOutOfScopeEditing = relatedTarget?.classList.contains("editing");
            const isEditingTarget = relatedTarget?.classList.contains("editing_" + id) || isOutOfScopeEditing;
            if (!title) {
                const newName = generateAgendaItemName("Untitled agenda item", props?.order + 1);
                designer.api.agendaItem.update({
                    id,
                    agendaItem: {
                        title: newName,
                    },
                });
                setTitle(newName);
            } else {
                designer.api.agendaItem.update({
                    id,
                    agendaItem: {
                        title,
                    },
                });
            }
            if (!isEditingTarget && !inEventSettings) {
                commit?.();
            }
            if (inEventSettings && !isOutOfScopeEditing) {
                designer.commit({
                    source: "agenda",
                });
            }
        },
        [title, id, commit]
    );

    // useEffect(() => {
    //     if (title === "") {
    //         inputRef.current?.focus();
    //     } else {
    //         inputRef.current?.blur();
    //     }
    // }, [props.dialogState]);

    const onFocusTitle = useCallback(
        async (event: React.FocusEvent<HTMLInputElement>) => {
            onFocusInput?.();
            const relatedTarget = event?.relatedTarget as HTMLElement;
            const isEditingTarget = relatedTarget?.classList.contains("editing_" + id);
            if (!isEditingTarget) {
                await onFocusAgendaItem?.();
            }
            setIsTitleFocused(true);
        },
        [id, onFocusInput, onFocusAgendaItem]
    );
    return (
        <Input
            data-id="agendaItem-title"
            fontWeightVariant="bold"
            typographyVariant="xl"
            inputRef={inputRef}
            autoFocus={!title.length && !readOnly}
            colorVariant="secondary"
            placeholder={readOnly ? "..." : `Agenda item #${props?.order + 1}`}
            shrinkLabel={true}
            customVariant="plain"
            maxCharCount={1500}
            value={title}
            onFocus={onFocusTitle}
            // inputProps={{
            //     className: "editing_" + id,
            //     "data-private": "lipsum",
            // }}
            className={cls(classes.editor, className)}
            readOnly={readOnly}
            disabled={!!readOnly}
            onChange={onChangeTitle}
            onBlur={onBlur}
            showTypedCharCount={isTitleFocused}
        ></Input>
    );
};

export default memo(AgendaItemTitle);
