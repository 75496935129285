import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import classes from "./Warning.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import IconButton from "@ui/core/components/IconButton";
import {useEffect, useState} from "@workhorse/api/rendering";
import {videoProcessorEvents} from "@workhorse/api/conference2/lib/VideoReplacement";
import {useSessionSettingsDialogDisplay} from "@workhorse/api/session-settings";
import {ReactComponent as BackgroundIcon} from "../../../../assets/media/air_play.svg";
import {useTranslation} from "react-i18next";

export function useBackgroundUsageWarning() {
    const {t} = useTranslation();
    const [dismissed, setDismissed] = useState(false);
    const [latencyHits, setLatencyHits] = useState(0);
    const {show} = useSessionSettingsDialogDisplay();

    useEffect(() => {
        if (dismissed) {
            return;
        }

        const onHighLatency = (ms: number) => {
            if (ms > 120) {
                setLatencyHits((hits) => hits + 1);
            }
        };

        videoProcessorEvents.addListener("latency-too-high", onHighLatency);

        return () => {
            videoProcessorEvents.removeListener("latency-too-high", onHighLatency);
        };
    }, [dismissed]);

    useEffect(() => {
        const id = setTimeout(() => {
            setLatencyHits(0);
        }, 10000);

        return () => {
            clearTimeout(id);
        };
    }, [latencyHits]);

    return {
        show: latencyHits >= 3 && !dismissed,
        onDismiss: () => setDismissed(true),
        onSettings: () => {
            setDismissed(true);
            show("device.backgroundEffects");
        },
    };
}

interface Props {
    onDismiss: () => void;
    onSettings: () => void;
}

export function BackgroundUsageWarning(props: Props) {
    const {t} = useTranslation();
    return (
        <div className={classes.root}>
            <div className={`${classes.header} flex flex-row`}>
                <WarningRoundedIcon className={classes.headerIcon} />
                <Typography fontWeight="bolder">{t("player.notifications.camera.slow_performance")}</Typography>
                <IconButton className={classes.closeButton} onClick={props.onDismiss}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <Typography className="mt-4">{t("player.notifications.camera.slow_performance_description")}</Typography>
            <div className="flex flex-row mt-12">
                <Button className="mr-12" variant="tertiary" size="small" onClick={props.onSettings}>
                    <BackgroundIcon className={`${classes.backgroundSettingsIcon} mr-4`} />
                    {t("participant.camera.background_effects")}
                </Button>
                <Button className={classes.dismissButton} variant="plain" size="small" onClick={props.onDismiss}>
                    {t("g.dismiss")}
                </Button>
            </div>
        </div>
    );
}
