import {AgendaItemType, FullAgendaItemFragment} from "@generated/data";
import designer from "../designer";
import {createSimpleDescription} from "@workhorse/pages/user/onboarding/api/session-onboarding-agenda";
import {AIGeneratedAgendaItem} from "./AgendaGeneratorMessenger";

export type aiAgendaItem = AIGeneratedAgendaItem & {
    id: string;
};

export const AI_GENERATED_TITLE = "title";
export const AI_GENERATED_DESCRIPTION = "description";
export const AGENDA_ITEM_TITLE_MAX_LENGTH = 500;
export const AGENDA_ITEM_DESCRIPTION_MAX_LENGTH = 1500;

export const handleCreateOrInsertAiAgenda = (
    agendaItems: FullAgendaItemFragment[],
    generatedAgenda: aiAgendaItem[],
    selectedAgendaItems: aiAgendaItem[],
    shouldReplace: boolean
) => {
    const agendaToInsert = selectedAgendaItems.length ? selectedAgendaItems : generatedAgenda;

    if (!agendaToInsert.length) {
        return;
    }

    if (shouldReplace) {
        agendaItems?.forEach((agendaItemToRemove) => {
            designer.api.agendaItem.remove({
                id: agendaItemToRemove?.id,
            });
        });
    }
    agendaToInsert?.forEach((agendaItemToInsert, index) => {
        designer.api.agendaItem.create({
            ...designer.constants.agendaItemDefaults,
            type: AgendaItemType.Planned,
            order: shouldReplace ? index : agendaItems?.length + index,
            title: agendaItemToInsert?.name,
            duration: agendaItemToInsert?.duration * 60,
            locked: null,
            descriptionJson: createSimpleDescription(agendaItemToInsert?.description),
            doNotUpdateCreatePls: false,
            overrideOrder: shouldReplace,
        });
    });
};

export const generateAgendaPrompt = (userInput: string, duration: number, numberOfAgendaItems: number, whatAreWeSettingUp?: string) => {
    if (!userInput) return "";
    const hasNumberOfAgendaItems =
        !!numberOfAgendaItems && numberOfAgendaItems > 0 ? `a total number of exactly ${numberOfAgendaItems}` : "multiple";
    const hasDuration = !!duration && duration > 0 ? `with a total duration of the whole agenda of exactly ${duration / 60} minutes` : "";
    return `You have to setup a ${
        whatAreWeSettingUp ?? "meeting"
    }. Generate an agenda, having ${hasNumberOfAgendaItems} agenda items, ${hasDuration} based on the following topic "${userInput}".`;
};
