import {ArtifactPropsFragmentDoc} from "@generated/data";

import {fieldResolvers} from "@workhorse/providers/graphql/diff.resolvers";
import typeCast from "@workhorse/api/typeCast";
import {CustomResolvers} from "./types";

export const Artifact: CustomResolvers["Artifact"] = {
    ...fieldResolvers.Artifact,
    data: {
        read(_, {readField}) {
            const properties = ((readField("properties") as Array<any> | undefined | null) || []).map((ref) => {
                return {
                    key: readField("key", ref),
                    value: readField("value", ref),
                };
            });
            return typeCast(properties as any);
        },
    },
    files: {
        read(existing, {variables, readField}) {
            if (existing && existing.length) {
                if ((variables as any)?.excludeDeleted) {
                    const output = existing.filter((ref) => {
                        const isDeleted = readField("isDeleted", ref);
                        return !isDeleted;
                    });
                    return output;
                }
                if (!(variables as any)?.noDiff) {
                    return existing;
                } else {
                    const output = existing.filter((ref) => {
                        const isComitted = !!readField("createdAt", ref);
                        return isComitted;
                    });
                    return output;
                }
            }

            return [];
        },
        merge: false,
    },
    properties: {
        // @ts-expect-error
        read(existing, {variables, readField}) {
            if (existing && existing.length) {
                if ((variables as any)?.excludeDeleted) {
                    const output = existing.filter((ref) => {
                        const isDeleted = readField("isDeleted", ref);
                        return !isDeleted;
                    });
                    return output;
                }
                if (!(variables as any)?.noDiff) {
                    return existing;
                } else {
                    const output = existing.filter((ref) => {
                        const isComitted = !!readField("createdAt", ref);
                        return isComitted;
                    });
                    return output;
                }
            }

            return [];
        },
        merge: false,
    },

    resourceResults: {
        // @ts-expect-error
        read(existing, {variables, readField}) {
            if (existing && existing.length) {
                if ((variables as any)?.excludeDeleted) {
                    const output = existing.filter((ref) => {
                        const isDeleted = readField("isDeleted", ref);
                        return !isDeleted;
                    });
                    return output;
                }
                if (!(variables as any)?.noDiff) {
                    return existing;
                } else {
                    const output = existing.filter((ref) => {
                        const isComitted = !!readField("createdAt", ref);
                        return isComitted;
                    });
                    return output;
                }
            }
            return [];
        },
        merge: false,
    },
};
