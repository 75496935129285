import {ReactNode} from "@workhorse/api/rendering";
import {ActiveSpeakersEnabledProvider} from "./ActiveSpeakersEnabled";
import {ActiveSpeakersListProvider} from "./ActiveSpeakersList";

export function ActiveSpeakersProvider({children}: {children: ReactNode}) {
    return (
        <ActiveSpeakersEnabledProvider>
            <ActiveSpeakersListProvider>{children}</ActiveSpeakersListProvider>
        </ActiveSpeakersEnabledProvider>
    );
}
