import {ExecSvcClientDocument} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {ServiceError} from "./error";
import {SchemaService, ServiceMap} from "./types";

export const makeClient = <T extends ServiceMap>(servicesSchema: {properties: {[key: string]: SchemaService}}): T => {
    const services = {};

    for (const serviceName in servicesSchema.properties) {
        const service = servicesSchema.properties[serviceName];
        if (!service) {
            continue;
        }

        if (!services[serviceName]) {
            services[serviceName] = {};
        }

        const methods = service.properties;

        for (const method in methods) {
            if (!services[serviceName][method]) {
                services[serviceName][method] = async (...args: any[]) => {
                    const {data} = await apollo.client.mutate({
                        mutation: ExecSvcClientDocument,
                        variables: {
                            operation: `${serviceName}.${method}`,
                            args: JSON.stringify(args),
                        },
                    });

                    if (!data || !data.execSvc) {
                        throw new ServiceError(`Operation ${serviceName}.${method} did not execute succesfuly!`);
                    }

                    if (!data.execSvc.success) {
                        throw new ServiceError(data.execSvc.error!);
                    }

                    return JSON.parse(data.execSvc.result!);
                };
            }
        }
    }

    return services as unknown as T;
};
