import type {Color} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import type {Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import React, {forwardRef} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import {PaletteColor} from "@workhorse/providers/ThemeProvider/types";
import {cls} from "@ui/cdk/util/util";
import {CDKComponentProps} from "./cdk.utils";

export type SpecialButtonClassKeys = "root" | "container" | "startIcon" | "label";

export type SpecialButtonStyleProps = {
    iconBackgroundColor: string;
    iconBackgroundBorderColor: string;
};

const useStyles = makeStyles((theme) =>
    createStyles<SpecialButtonClassKeys, SpecialButtonStyleProps>({
        root: {
            color: theme.main.palette.text.secondary,
            fontSize: theme.typography.pxToRem(13),
            fontWeight: 500,
            textTransform: "initial",
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 10,
            borderColor: "transparent",

            // The button appears to have fixed width & height
            width: 175,
            height: 72,

            "&:hover": {
                backgroundImage: "none",
            },
        },
        container: {
            backgroundImage: `linear-gradient(to top, ${theme.main.palette.elevation.surface[0]}, ${theme.main.palette.elevation.onSurface[0]})`,
            borderColor: `${theme.main.palette.cdkPalette.border.secondary}`,
        },
        startIcon: {
            backgroundColor: (props) => props.iconBackgroundColor,
            // borderColor: (props) => props.iconBackgroundBorderColor,
            // border: "3px solid",
            padding: theme.spacing(0.75),
            borderRadius: 6,

            "& svg": {
                fill: theme.main.palette.basic.background,

                // width: 18,
                // height: 18,
            },
        },
        label: {
            whiteSpace: "nowrap",
        },
    })
);

type SpecialButtonActions = Pick<ExtractProps<typeof MuiButton>, "onClick">;

type SpecialButtonBasicProps = (CDKComponentProps<SpecialButtonClassKeys> & SpecialButtonActions) & {
    disableContainer: boolean;
    buttonProps: ExtractProps<typeof MuiButton>;
};

type SpecialButtonVariantProps = Partial<SpecialButtonBasicProps> & {
    specialAccent: keyof Theme["main"]["palette"];
    specialVariant?: keyof PaletteColor;
    specialLightVariant?: keyof PaletteColor;
};

type SpecialButtonAccentProps = Partial<SpecialButtonBasicProps> & {
    specialAccent?: "primary" | "secondary" | "error" | "warning" | "info" | "success";
};

const isWithVariant = (props: SpecialButtonVariantProps | SpecialButtonAccentProps): props is SpecialButtonVariantProps => {
    if ("specialVariant" in props) {
        return true;
    }

    return false;
};

export type SpecialButtonProps = {"data-id"?: string} & (SpecialButtonVariantProps | SpecialButtonAccentProps);

const SpecialButton = forwardRef((props: SpecialButtonProps, ref: any) => {
    const theme = useTheme();
    let iconBackgroundColor: string, iconBackgroundBorderColor: string;

    if (isWithVariant(props)) {
        iconBackgroundColor =
            theme.main.palette[props.specialAccent || "blue"][props.specialVariant ?? 500] ?? theme.main.palette["blue"][500];
        iconBackgroundBorderColor =
            theme.main.palette[props.specialAccent || "blue"][props.specialLightVariant ?? 400] ?? theme.main.palette["blue"][400];
    } else {
        iconBackgroundColor = theme.palette[props.specialAccent || "primary"].main;
        iconBackgroundBorderColor = theme.palette[props.specialAccent || "primary"].main;
    }

    const classes = useStyles({
        iconBackgroundColor,
        iconBackgroundBorderColor,
    });

    return (
        <MuiButton
            ref={ref}
            data-id={props["data-id"]}
            {...props.buttonProps}
            className={cls(
                !props.disableContainer && classes.container,
                !props.disableContainer && props.classes?.container,
                props.className
            )}
            classes={{
                root: cls(classes.root, props.classes?.root),
                startIcon: cls(classes.startIcon, props.classes?.startIcon),
            }}
            style={props.style}
            onClick={props.onClick}
        >
            {props.children}
        </MuiButton>
    );
});

SpecialButton.displayName = "SpecialButton";

export default SpecialButton;
