import {ConferenceParticipantStatus, ParticipantInviteStatus, ParticipantStatus, ParticipantSource} from "@generated/data";
import designer from "@workhorse/api/designer";
import {ArrayItem} from "@workhorse/declarations";
import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import {DesignerAction} from "../../action";
import {makeId} from "../../utils";

export interface UpdateParticipantsInput {
    // TODO: refactor - input should be only ids
    participantEmails: string[];
}

export type DesignerParticipant = Omit<NonNullable<ArrayItem<DesignerApiSession["participants"]>>, "rbac"> & {
    rbac: {[key: string]: any} | null;
};

export class UpdateParticipants extends DesignerAction<UpdateParticipantsInput> {
    commit() {
        const currentParticipants = designer.currentParticipants();
        const now = null as unknown as Date;

        const inputParticipants = this.input.participantEmails.filter((email) => {
            return !(currentParticipants || []).find((p) => {
                if (!p.dataWithNullableEmail.email) {
                    return p.id === email;
                }
                return p.dataWithNullableEmail.email && p.dataWithNullableEmail.email === email;
            });
        });

        const sessionParticipants: DesignerParticipant[] = [];
        (currentParticipants || []).forEach((p) => {
            if (this.input.participantEmails.find((x) => x === (p.dataWithNullableEmail.email ?? p.id))) {
                sessionParticipants.push({
                    ...p,
                    dataWithNullableEmail: {
                        ...p.dataWithNullableEmail,
                        email: p.dataWithNullableEmail.email === p.id ? null : p.dataWithNullableEmail.email,
                    },
                });
            }
        });

        const participants = sessionParticipants.concat(
            inputParticipants.map((email) => {
                const participant: DesignerParticipant = {
                    id: makeId(),
                    changedBy: null,
                    isApproved: false,
                    isOwner: false,
                    inviteStatus: ParticipantInviteStatus.NeedsAction,
                    mockedParticipant: false,
                    muted: false,
                    rbac: {},
                    isRecorder: false,
                    conferenceStatus: ConferenceParticipantStatus.Participant,
                    status: ParticipantStatus.NotJoined,
                    statusChangedBy: null,
                    __typename: "Participant",
                    dataWithNullableEmail: {
                        email: email,
                        __typename: "ParticipantDataWithNullableEmail",
                        firstName: "",
                        avatar: null,
                        lastName: "",
                        isGuest: false,
                        token: null,
                        userId: "",
                    },
                    lastSeenTimestamp: null,
                    hasAttendedSession: false,
                    invitedByTheOwner: true,
                    source: ParticipantSource.App,
                    updatedAt: now,
                    createdAt: now,
                    askToUnmute: false,
                    askToStartCamera: false,
                    speakerDetails: null,
                    controlStatus: null,
                    submittedPasscode: false,
                    paymentTransaction: null,
                    isMainParticipant: false,
                };
                return participant;
            })
        );

        designer.updateCurrentSession(
            {
                participants: participants,
            },
            {
                strategy: "replace",
            }
        );

        return "";
    }

    canRedo() {
        return false;
    }

    canUndo() {
        return false;
    }
}
