import {PaymentConfigFragment} from "@generated/data";
import {makeVar, useReactiveVar} from "@workhorse/api/data";
import {deletePaymentConfig, upsertPaymentConfig} from "@workhorse/api/payments/api";

export type PaymentConfigState = {state: "ready" | "update" | "delete"; value: PaymentConfigFragment}[];

export const paymentConfigState = makeVar<PaymentConfigState | null>(null);

export const usePaymentsHasUpdates = () => {
    const state = useReactiveVar(paymentConfigState);
    if (!state?.length) return false;
    return state.some((item) => item.state === "update" || item.state === "delete");
};

// An can have only one payment config but the user can play with tha state multiple times.
// Eg, Adding ding payment, then removing without saving then adding another and so on.
// so we need to keep track of all these changes and when user chooses to save or discard changes to properly update the db.

export const upsertPaymentConfigs = async (eventId: string) => {
    const state = paymentConfigState();
    if (state?.length) {
        await Promise.all(
            state
                .map((config) => {
                    if (config.state === "update") {
                        return upsertPaymentConfig(config.value, {sessionEventId: eventId});
                    } else if (config.state === "delete" && config.value.id) {
                        return deletePaymentConfig(config.value.id);
                    }

                    return null;
                })
                .filter(Boolean)
        );
        const updated = state.filter((item) => item.state !== "delete")[0];
        if (updated) {
            paymentConfigState([{...updated, state: "ready"}]);
        } else {
            paymentConfigState([]);
        }
    }
};
