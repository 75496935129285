import {Theme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/core";
import {useEffect, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {CDKComponentProps} from "./cdk.utils";
import {dateFormat} from "./DateFormat";
import {useUserInfo} from "@workhorse/providers/User";

export type TimeClassKeys = "root" | "meridian" | "relativeMeridian" | "rotateMeridian";

const useStyles = makeStyles<Theme, {}, TimeClassKeys>((theme) => ({
    root: {
        fontSize: "inherit",
    },
    meridian: {},
    relativeMeridian: {
        fontSize: "inherit",
    },
    rotateMeridian: {
        transform: "rotate(270deg)",
        transformOrigin: "center center",
        display: "inline-block",
    },
}));

export type TimeProps = CDKComponentProps<TimeClassKeys> & {
    classes?: ReturnType<typeof useStyles>;
    time?: Date | null | number | string;
    rotateMeridian?: boolean;
    noMeridian?: boolean;
    relativeMeridian?: boolean;
    isHalfDayClock?: boolean;
};

const Time = (props: Omit<TimeProps, "classes"> & {classes?: Partial<TimeProps["classes"]>}): JSX.Element => {
    const classes = useStyles();

    const user = useUserInfo();

    const time = new Date(props.time || Date.now());
    const meridian = time.getHours() < 12 ? "AM" : "PM";

    const timeFormat = props.isHalfDayClock ? "fullTime12h" : "fullTime24h";
    const formattedTime = dateFormat(time, timeFormat).replace(/(pm|am)/gi, "");
    const dataText = `${formattedTime}${!props.noMeridian && props.isHalfDayClock ? meridian : ""}`;

    return (
        <span className={cls(classes.root, props.classes && props.classes.root, props.className)} style={props.style} data-id={dataText}>
            {formattedTime}

            {!props.noMeridian && props.isHalfDayClock ? (
                <span
                    key={`meridian-${time}`}
                    data-key={`meridian-${meridian}-${time}`}
                    className={cls(
                        classes.meridian,
                        props.relativeMeridian && classes.relativeMeridian,
                        props.rotateMeridian && classes.rotateMeridian,
                        props.classes?.meridian,
                        props.rotateMeridian && props.classes?.rotateMeridian
                    )}
                >
                    {meridian}
                </span>
            ) : null}
        </span>
    );
};

export default Time;
