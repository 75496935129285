const BYTE_UNITS = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

function runPrettyBytes(size: number, index: number, decimals: number) {
    if (size < 1000 || index === BYTE_UNITS.length) {
        return size.toFixed(decimals) + " " + BYTE_UNITS[index];
    }
    return runPrettyBytes(size / 1000, index + 1, decimals);
}

export function prettyBytes(size: number, decimals = 1) {
    return runPrettyBytes(size, 0, decimals);
}
