import {ContactInfoFragment, Participant} from "@generated/data";
import Close from "@material-ui/icons/Close";
import MoreVert from "@material-ui/icons/MoreVert";
import {cls} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import ListItem from "@ui/core/components/ListItem";
import ListItemSecondaryAction from "@ui/core/components/ListItemSecondaryAction";
import MenuItem from "@ui/core/components/MenuItem";
import Popover from "@ui/core/components/Popover";
import {useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import classes from "./styles/ParticipantRenderer.module.scss";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import Tooltip from "@ui/cdk/Tooltip";
import {useTranslation} from "react-i18next";

// This component can receive either a Participant, either a Contact, either an email as string
type ParticipantRendererProps = {
    participant?: Partial<Participant>;
    contact?: ContactInfoFragment;
    email?: string;
    guestIndex?: number;
    handleRemoveParticipant: (email: string) => void;
    liveSessionId?: string;
    sessionId: string;
    contactExists: boolean;
    hideMakeAssistant?: boolean;

    handleToggleAssistantRole: (participantId: string) => void;
    isAssistantClientOnly?: boolean;
    isEditMode?: boolean;
    showSilhouetteAvatar?: boolean;
    isEventGuest?: boolean;
};

const ParticipantRenderer = (props: ParticipantRendererProps) => {
    const {t} = useTranslation();
    const {
        participant,
        contact,
        email,
        guestIndex,
        handleRemoveParticipant,
        sessionId,
        liveSessionId,
        contactExists,
        handleToggleAssistantRole,
        isAssistantClientOnly,
        isEditMode,
        hideMakeAssistant,
        showSilhouetteAvatar,
        isEventGuest,
    } = props;

    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

    const participantIsAssistant = isAssistantClientOnly ?? (!participant?.id ? false : !!participant.rbac?.session?.isAssistant);

    let data: {
        email: null | undefined | string;
        firstName: undefined | string;
        lastName: undefined | string;
        avatar: null | undefined | string;
    } = {
        email: null,
        firstName: undefined,
        lastName: undefined,
        avatar: undefined,
    };

    if (participant) {
        data = {
            email: participant.dataWithNullableEmail?.email,
            firstName: participant.dataWithNullableEmail?.firstName,
            lastName: participant.dataWithNullableEmail?.lastName,
            avatar: participant.dataWithNullableEmail?.avatar,
        };
    }
    if (contact) {
        data = {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            avatar: contact.userProfilePicture,
        };
    }
    if (email) {
        data = {
            email: email,
            firstName: undefined,
            lastName: undefined,
            avatar: showSilhouetteAvatar ? "https://static.dev.sessions.flowos.com/assets/guest-avatar.svg" : undefined,
        };
    }

    if (showSilhouetteAvatar && !data.avatar && !(data.firstName || data.lastName)) {
        data.avatar = "https://static.dev.sessions.flowos.com/assets/guest-avatar.svg";
    }

    const handleLinkCopied = () =>
        toast("The link was copied successfully!", {
            type: "success",
            position: "top",
            duration: 3000,
        });

    const showName = !data.email
        ? true
        : !guestIndex &&
          (((!participant?.dataWithNullableEmail?.isGuest || participant?.dataWithNullableEmail?.email) &&
              (!!participant?.dataWithNullableEmail?.firstName || !!participant?.dataWithNullableEmail?.lastName)) ||
              contactExists);

    const showDelimiter = !!data?.email?.trim() && !!(data.firstName || data.lastName);

    const isOwner = participant?.isOwner === true;
    const canBecomeAssistant = !!participant && !isOwner && participant?.dataWithNullableEmail?.isGuest !== true;

    const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMoreMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = () => {
        setMoreMenuAnchorEl(null);
    };

    const [trackEvent] = useTrackingEvents();

    const handleToggleAssistant = () => {
        if (!participantIsAssistant) {
            trackEvent("make-assistant", {
                event_category: isEditMode ? TrackingEventCategory.DesignerActions : TrackingEventCategory.PlayerActions,
            });
        }

        if (participant?.id) {
            handleToggleAssistantRole(participant.id);
        }

        // if (participant) {
        //     toggleAssistantRoleInDesigner(participant!.id!, participantIsAssistant, props.sessionId);
        // }
        handleCloseMoreMenu();
    };

    const handleRemoveParticipantFromMenu = (id: string) => {
        handleRemoveParticipant(id);
        handleCloseMoreMenu();
    };

    return (
        <ParticipantDisplayDetails
            renderComponent={ListItem}
            dontPassRestProps={["guestToken", "sessionStarted"]}
            disableTooltip
            showProfilePicture
            showEmail
            showName={showName}
            className={!isEventGuest ? classes.listItem : undefined}
            classes={{
                avatar: classes.avatarContainer,
                displayName: classes.listItemDisplayName,
                email: classes.listItemEmail,
                wrap: classes.listItemWrap,
            }}
            email={data.email}
            firstName={data.firstName}
            lastName={data.lastName}
            avatar={data.avatar}
            index={guestIndex}
            avatarVariant="rounded"
            avatarSize="S"
            participantStatus={participant?.status}
            delimiter={showDelimiter ? <div className={classes.dot} /> : undefined}
            isUser={!participant?.dataWithNullableEmail?.isGuest}
            renderingForAdmin={true}
            renderingForAssistant={true}
            participantIsAssistant={participantIsAssistant}
            // @ts-expect-error
            button={undefined}
        >
            {!isEventGuest && (
                <ListItemSecondaryAction data-id="participant-actions" className={cls("flex flex-row", classes.actionButtonsContainer)}>
                    {canBecomeAssistant && !hideMakeAssistant ? (
                        <>
                            <Tooltip
                                title={
                                    (!participantIsAssistant
                                        ? t("participant.action.make_assistant")
                                        : t("participant.action.remove_assistant")) ?? ""
                                }
                                arrow
                            >
                                <IconButton onClick={handleToggleAssistant} className={classes.makeAssistantBtn}>
                                    <StarRoundedIcon className={cls(participantIsAssistant && classes.activeAssistantStarIcon)} />
                                </IconButton>
                            </Tooltip>
                            <IconButton className={classes.secondaryActionBtn} onClick={handleOpenMoreMenu}>
                                <MoreVert />
                            </IconButton>
                            <Popover
                                classes={{paper: classes.paper}}
                                open={Boolean(moreMenuAnchorEl)}
                                anchorEl={moreMenuAnchorEl}
                                onClose={handleCloseMoreMenu}
                            >
                                <MenuItem
                                    data-id="make-remove-assistant"
                                    key="assistant-action"
                                    onClick={handleToggleAssistant}
                                    className={classes.menuItem}
                                >
                                    {!participantIsAssistant
                                        ? t("participant.action.make_assistant")
                                        : t("participant.action.remove_assistant")}
                                </MenuItem>
                                <MenuItem
                                    data-id="remove-participant"
                                    key="remove-action"
                                    onClick={handleRemoveParticipantFromMenu.bind(this, participant?.id)}
                                    className={classes.menuItem}
                                >
                                    {t("participant.action.remove_participant")}
                                </MenuItem>
                            </Popover>
                        </>
                    ) : !isOwner ? (
                        <IconButton
                            data-id="participants-form-remove-contact-button"
                            className={classes.secondaryActionBtn}
                            onClick={handleRemoveParticipant.bind(this, participant?.id)}
                        >
                            <Close />
                        </IconButton>
                    ) : null}
                </ListItemSecondaryAction>
            )}
        </ParticipantDisplayDetails>
    );
};

export default ParticipantRenderer;
