import {HostType, SkipSeenDocument} from "@generated/data";
import {TooltipProps} from "@ui/core/components/Tooltip";
import {Flatten} from "@ui/Forms/types";
import {makeVar} from "@workhorse/api/data";
import {readRemoteUser, updateCachedRemoteUser, updateRemoteUser} from "@workhorse/api/user";
import {useUserInfo} from "@workhorse/providers/User";
import apollo from "../apollo";

type Merge<T> = T extends infer O ? {[K in keyof O]: O[K]} : never;

type HowManyTimesToShowHighlight = "once" | "always" | "atFirstLogin" | ((...arg: any) => boolean);
type HideHighlightOn = "click" | "clickOutside" | "close";
export type HighlightItemConditions<T> = {
    howManyTimes: HowManyTimesToShowHighlight;
    message?:
        | {
              title?: string;
              body?: string;
          }
        | React.ComponentType<any>;
    closeOn?: HideHighlightOn[];
    onCloseMarkAsSeen: SeenSegment[];
    /**
     * if true, the seen state won't be persisted on the BE
     * sometimes it's needed at a local level, in order to just close the tooltip
     * for sections that need to be marked as seen in conjunction with other sections
     */
    markAsSeenOnlyLocally?: boolean;
    placement?: TooltipProps["placement"];
    withBackDrop?: boolean;
    withShadow?: boolean;
    isLeftToolTip?: boolean;
    withTransparentBackDrop?: boolean;
    timeout?: number;

    learnMoreUrl?: string;
    articleUrl?: string;
    canJoinCommunity?: boolean;
    videoUrl?: string;
    enableFullscreen?: boolean;
};

type CurrentLocation = `${HostType}`;

export type OnboardingFeature =
    | "onboardingSessionFirstStrike"
    | "onboardingSessionSecondStrikeOwner"
    | "onboardingSessionSecondStrikeMember"
    | "onboardingWorkspaceSetup"
    | "helpCenter"
    | "home"
    | "memoryDashboard"
    | "base"
    | "agendaDashboard"
    | "tools"
    | "files"
    | "bookingDashboard"
    | "events"
    | "eventsWelcome"
    | "eventsCreate"
    | "rooms";

export type SessionSettingsSeenType = "planned" | "booking" | "event" | "room";

export type HomeNotification =
    | "recordingDisabled"
    | "youtubeTutorials"
    | "trialAvailable"
    | "resyncGoogle"
    | "resyncMicrosoft"
    | "partnerWithUs"
    | "referralDiscount"
    | "desktopAppAvailable"
    | "redoOnboarding";

export type GetStartedItems =
    | "welcome-video"
    | "start-session"
    | "custom-branding"
    | "add-team-member"
    | "create-agenda"
    | "success"
    | "create-booking"
    | "sync-calendar"
    | "create-event";

type HostBasedHighlights = Merge<
    {
        [X in `${HostType.Player}`]: "devicesPreferences" | "resourceDoubleClick" | "useTool" | "openTools";
    } & {
        [K in `${HostType.Home}`]: "reviewSession";
    } & {
        GLOBALS:
            | "proUser"
            | "incident"
            | "userOnboardingTerms"
            | "requestApproved"
            | "pendingDismissed"
            | "googleWorkspace"
            | "userOnboardingSession"
            | "userOnboardingSessionUnsupported"
            | "userOnboardingSessionEnd"
            | "useOnboardingLoneliness"
            | "userMemberPending"
            | "lastTimeNewUsersSeen"
            | "syncEmailNotification"
            | "reverseTrialNotification"
            | "reverseTrialExpiredNotification"
            | "getStartedWidget"
            | "userSkippedOnboarding"
            | "userOnboardingCalendarSync"
            | "userOnboardingFileUploaded"
            | "userOnboardingToolUploaded"
            | "userOnboardingAgenda"
            | "userOnboardingInitialPreJoin"
            | "userOnboardingMemoryDemo"
            | "bookingPaymentsEmbedding"
            | "eventPaymentsEmbedding"
            | "userOnboardingFirstSessionId"
            | "userOnboardingRoomsWelcome"
            | "userOnboardingRoomsCreate"
            | "multipleOrganizationsSelect"
            | "userOnboardingBookingWelcome"
            | "userOnboardingBookingCreate"
            | "userOnboardingBookingPreview";

        ONBOARDING_FEATURES: OnboardingFeature;
        SESSION_TEMPLATE_SETTINGS: SessionSettingsSeenType;
        HOME_NOTIFICATIONS: HomeNotification;
        GET_STARTED_WIDGET: GetStartedItems;
    }
>;

type SystemHighlightKeys = CurrentLocation | keyof Omit<HostBasedHighlights, CurrentLocation>;

export type UpdateSeenArg = {
    [K in keyof HostBasedHighlights]?: {
        [X in HostBasedHighlights[K]]?: X extends "incident"
            ? string
            : X extends "lastTimeNewUsersSeen"
            ? Date
            : X extends "userOnboardingFirstSessionId"
            ? string
            : boolean;
    };
};

type SystemHighlights = {
    [K in SystemHighlightKeys]?: {
        [X in K extends keyof HostBasedHighlights ? HostBasedHighlights[K] : never]?: HighlightItemConditions<X>;
    };
};

export const HostTypeBasedHighlights: SystemHighlights = {
    PLAYER: {
        devicesPreferences: {
            howManyTimes: "once",
            onCloseMarkAsSeen: ["PLAYER.devicesPreferences"],
            withTransparentBackDrop: false,
        },
        resourceDoubleClick: {
            howManyTimes: "once",
            withTransparentBackDrop: true,
            onCloseMarkAsSeen: ["PLAYER.resourceDoubleClick"],
            markAsSeenOnlyLocally: true,
            message: {
                title: "Simply select it and click on Confirm.",
            },
            closeOn: ["click", "clickOutside", "close"],
        },
        useTool: {
            howManyTimes: "once",
            withTransparentBackDrop: true,
            onCloseMarkAsSeen: ["PLAYER.useTool"],
            markAsSeenOnlyLocally: true,
            message: {
                title: "Let's load a presentation and see how life works without sharing your screen.",
                body: "We prepared one for you, just click here.",
            },
            closeOn: ["click", "clickOutside"],
        },
        openTools: {
            howManyTimes: "once",
            withTransparentBackDrop: true,
            onCloseMarkAsSeen: ["PLAYER.useTool"],
            markAsSeenOnlyLocally: true,
            message: {
                title: "The magic of Sessions starts with our tools",
                body: "Click to see how it works.",
            },
            closeOn: ["click", "clickOutside"],
        },
    },
    HOME: {
        reviewSession: {
            howManyTimes: "once",
            message: {
                title: "This is Sessions Memory.",
                body: " You'll find all the info about your past sessions, tools, and files here.",
            },
            closeOn: ["click", "clickOutside"],
            onCloseMarkAsSeen: ["HOME.reviewSession"],
            withTransparentBackDrop: false,
        },
    },
};

type MarkAsSeenArg = Flatten<{
    [K in SystemHighlightKeys]: {
        [X in K extends keyof HostBasedHighlights ? HostBasedHighlights[K] : never]: null;
    };
}>;

export type SeenSegment = keyof MarkAsSeenArg;

type SeenSections = {
    [K in HostType]: {
        [X in `${K}` extends keyof HostBasedHighlights ? HostBasedHighlights[`${K}`] : never]: boolean;
    };
} & {
    [K in keyof Omit<HostBasedHighlights, CurrentLocation>]: {
        [X in `${K}` extends keyof HostBasedHighlights ? HostBasedHighlights[`${K}`] : never]: boolean;
    };
};

const markUserSection = async (section: UpdateSeenArg, onlyUpdateCache = false) => {
    const userData = readRemoteUser();

    if (!userData || !userData.getRemoteUser || !userData.getRemoteUser.user) {
        return;
    }
    const {user} = userData.getRemoteUser;
    const seen = structuredClone(user.seen);

    Object.keys(section).forEach((s) => {
        seen[s] = {
            ...(seen[s] ?? {}),
            ...section[s],
        };
    });

    if (onlyUpdateCache) {
        updateCachedRemoteUser({
            user: {
                seen,
            },
        });
        return;
    }

    return updateRemoteUser({
        seen,
    });
};

export async function markAsSeen(sections: UpdateSeenArg, onlyUpdateCache?: boolean) {
    return markUserSection(sections, onlyUpdateCache);
}

export async function skipSeen(segment: Exclude<SeenSegment, "GLOBALS.incident">, expiry?: number) {
    await apollo.client.mutate({
        mutation: SkipSeenDocument,
        variables: {
            segment,
            expiry,
        },
    });

    const p = segment.split(".");

    await markAsSeen({
        [p[0]]: {
            [p[1]]: false,
        },
    } as UpdateSeenArg);
    markAsSeen(
        {
            [p[0]]: {
                [p[1]]: true,
            },
        } as UpdateSeenArg,
        true
    );
}

export const evalHostBasedSchema = (segment: SeenSegment) => {
    const p = segment.split(".");
    return (HostTypeBasedHighlights[p[0]] || {})[p[1]];
};

export function useSeenSection<T extends SeenSegment = SeenSegment>(
    section: T
): T extends "GLOBALS.incident"
    ? string
    : T extends "GLOBALS.lastTimeNewUsersSeen"
    ? Date
    : T extends "GLOBALS.userOnboardingFirstSessionId"
    ? string
    : boolean {
    const user = useUserInfo();

    const p = section.split(".");
    const seen = ((user?.seen || {})[p[0]] || {})[p[1]] || false;

    return seen;
}

export function useSeenSections<
    TSeenSegments extends string = Exclude<SeenSegment, "GLOBALS.incident">,
    T extends TSeenSegments = TSeenSegments
>(sections: T[]): boolean[] {
    const user = useUserInfo();

    return sections.map((section) => {
        const p = section.split(".");
        const seen = ((user?.seen || {})[p[0]] || {})[p[1]] || false;

        return seen;
    });
}

export function seenSection<T extends SeenSegment = SeenSegment>(
    section: T
): (T extends "GLOBALS.incident" ? string : boolean) | undefined {
    const userData = readRemoteUser();

    if (!userData || !userData.getRemoteUser) {
        return undefined;
    }

    const user = userData.getRemoteUser.user;
    const p = section.split(".");
    return ((user?.seen || {})[p[0]] || {})[p[1]] || false;
}

export function seenSegmentToObj<T extends SeenSegment>(
    string: T,
    value: T extends "GLOBALS.incident"
        ? string
        : T extends "GLOBALS.lastTimeNewUsersSeen"
        ? Date
        : T extends "GLOBALS.userOnboardingFirstSessionId"
        ? string
        : boolean
) {
    const p = string.split(".");

    return {
        [p[0]]: {
            [p[1]]: value,
        },
    } as UpdateSeenArg;
}

export const infoBackdropIsOpen = makeVar<boolean>(false);
