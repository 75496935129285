import {cls} from "@ui/cdk/util/util";
import {ParticipantDeviceEvents} from "@workhorse/api/conference2";
import {Fragment} from "@workhorse/api/rendering";
import {assistantActionAllowed} from "@workhorse/api/user";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import HideSessionsUIButtons from "@workhorse/components/HideSessionsUIButtons";
import {OnboardingController} from "@workhorse/components/onboarding-tooltips";
import {WakeLock} from "@workhorse/components/wake-lock";
import {SessionData} from "@workhorse/declarations/dataTypes";
import Inject from "@workhorse/providers/Injector";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useCurrentAgendaItem, useMyParticipant} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import OnboardingEmbedSessionDialog from "../../user/onboarding/components/onboarding-session-dialog/OnboardingEmbedSessionDialog";
import PendingOwnerRequests from "../PendingOwnerRequests";
import AskedParticipant from "../components/AskedParticipant";
import ConferenceStatusChanges from "../components/ConferenceStatusChanges";
import LeaveSessionModal from "../components/LeaveSessionModal/LeaveSessionModal";
import LeftNotificationsWrapper from "../components/LeftNotificationsWrapper";
import LocalParticipantControls from "../components/LocalParticipantControls";
import {PlayerWarnings} from "../components/PlayerWarnings";
import ScreenShareInformation from "../components/ScreenShareInformation";
import classes from "../styles/FooterPlayer.module.scss";
import OnIsAssistantChanged from "./OnIsAssistantChanged";
import OnboardingNewUserDialog from "./OnboardingNewUserDialog";
import PlayerGhosts from "./PlayerGhosts";
import PlayerKeyboardEvents from "./PlayerKeyboardEvents";
import UILanguageUpdater from "./UILanguageUpdater";
import {ParticipantsMacroAccess} from "@generated/data";
import RightNotificationsWrapper from "../components/RightNotificationsWrapper";
import {isParticipantsMacroHidden} from "@workhorse/pages/memory/components/utils";

type SessionExtraProps = {
    session: SessionData;
    sessionStarted: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    isLobby: boolean;
};

type ArtifactBoolProp = "true" | "false";

type GenericMicroProperties = {
    muteEveryone: ArtifactBoolProp;
};

// NOT RENDERED in Preview mode
function SessionExtra(props: SessionExtraProps) {
    const {sessionStarted, isAdmin, isAssistant, isLobby} = props;
    const {
        id: sessionId,
        shareScreenPid,
        createdAt,
        enableReactionsType,
        childOfBreakoutRooms,
        quickSession,
        onboardingType,
        disabledNotifications,
        backstage,
        participantsMacroAccess,
    } = props.session;

    const {isRecorder} = useUserInfo();
    const myParticipantObj = useMyParticipant();
    const {id: myParticipantId, isOwner} = myParticipantObj;
    const mobileState = useMobile();

    const currentAgendaItem = useCurrentAgendaItem();

    const {tooltip} = useOnboardingSession();

    const sessionIsPublished = !!createdAt;
    const parentSessionId = childOfBreakoutRooms?.parentOfBreakoutRooms?.sessionId;
    const participantIdInParent = childOfBreakoutRooms?.participantsMap?.find((r) => {
        return r.participantInBreakoutRoom?.id === myParticipantId;
    })?.participantInParent.id;

    const {data: microData = {}, artifactId, id: currentMicroId} = currentAgendaItem?.artifact || {};

    const {muteEveryone} = microData as GenericMicroProperties;
    const renderingConferenceMicro = artifactId === "flowos/conference";

    const assistant = assistantActionAllowed(["session.acceptJoinReq"]);

    const isOrganizer = isOwner || isAssistant;

    const isSpeaker = !!myParticipantObj.speakerDetails?.id;

    const participantsMacroHidden = isParticipantsMacroHidden(participantsMacroAccess, isOrganizer, isSpeaker);

    const LocalParticipantControlsWithErrorBoundary = (
        <ErrorBoundary key="local-participant-controls-errb" mobileState={mobileState} location="SessionExtraLocalParticipantControls">
            <LocalParticipantControls
                options={{
                    allowScreenShare: true,
                    isConferenceMicro: renderingConferenceMicro,
                    currentMicroId,
                }}
                participant={myParticipantObj}
                isAdmin={isAdmin}
                isAssistant={isAssistant}
                sessionId={sessionId}
                screenSharePid={shareScreenPid}
                session={props.session}
                isLobby={!sessionStarted}
            />
        </ErrorBoundary>
    );

    return (
        <>
            <Fragment key="right-notifications-wrapper">
                <RightNotificationsWrapper sessionStarted={sessionStarted} />
            </Fragment>

            <Fragment key="left-notifications-wrapper">
                {sessionStarted && (
                    <LeftNotificationsWrapper
                        enableReactionsType={enableReactionsType}
                        sessionId={sessionId}
                        artifactId={artifactId!}
                        muteEveryone={muteEveryone}
                        isAdmin={isAdmin}
                        isAssistant={isAssistant}
                        isRecorder={isRecorder}
                        key="left-notifications-wrapper"
                        disabledNotifications={disabledNotifications}
                    />
                )}
            </Fragment>

            <Fragment key="ghosts">{sessionStarted && <PlayerGhosts sessionId={sessionId} />}</Fragment>

            <Fragment key="controls">
                {!isLobby && sessionStarted && (
                    <Inject
                        target="inFooter"
                        id="player-footer-local-controls"
                        className={cls("flex-center-all player-footer", classes.injectedComp)}
                    >
                        {LocalParticipantControlsWithErrorBoundary}
                    </Inject>
                )}
            </Fragment>

            {/* <Fragment key="perms">{!isLobby && <RequirePermissions />}</Fragment> */}

            <Fragment key="owner-req">
                {sessionIsPublished && (isAdmin || (isAssistant && assistant.canSessionAcceptJoinReq)) && (
                    <PendingOwnerRequests myParticipantId={myParticipantId} isAssistant={isAssistant} sessionId={sessionId} />
                )}
            </Fragment>

            <Fragment key="conf-statuses">
                {sessionIsPublished && <ConferenceStatusChanges myParticipantObj={myParticipantObj} isAssistant={isAssistant} />}
            </Fragment>

            {isLobby ? null : <Fragment key="asked-participant">{sessionIsPublished && <AskedParticipant />}</Fragment>}

            <LeaveSessionModal
                isAdmin={isAdmin}
                sessionId={sessionId}
                participantId={myParticipantId}
                backstage={backstage}
                parentSessionId={parentSessionId}
                participantIdInParent={participantIdInParent}
                isAssistant={isAssistant}
            />

            <PlayerWarnings />

            <OnboardingController />
            {sessionStarted && onboardingType === SessionOnboardingType.NewUser && <OnboardingNewUserDialog />}

            <ScreenShareInformation key="screen-share-information" />

            <Fragment key="hide-sessions-ui-buttons">{sessionStarted && !isRecorder && <HideSessionsUIButtons />}</Fragment>
            <OnIsAssistantChanged
                dontOpenParticipantsDrawer={mobileState.isMobileOrTablet || participantsMacroHidden}
                isAssistant={isAssistant}
                sessionId={sessionId}
                quickSession={quickSession}
                isLobby={isLobby}
            />

            <Fragment key="onboarding-embed-session-dialog">
                {sessionStarted && onboardingType === SessionOnboardingType.SecondStrikeOwner && (
                    <OnboardingEmbedSessionDialog type="embed-tool" />
                )}
            </Fragment>

            <ParticipantDeviceEvents />

            <PlayerKeyboardEvents />

            <UILanguageUpdater />

            {!isLobby && <WakeLock />}
        </>
    );
}

export default SessionExtra;
