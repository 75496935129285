import designer from "@workhorse/api/designer";
import {DesignerApiSession} from "@workhorse/declarations/dataTypes";
import RandExp from "randexp";

import services from "@api/service/client";
import {InitialConfigurationPropertyType} from "@artifacts/breakout/api/constants";
import descriptor, {ArtifactTag} from "@generated/artifacts";
import {AgendaItemType, Maybe, ResourceNameFragment, SessionParticipantFragment} from "@generated/data";
import {getResourceResult} from "@workhorse/api/resx/hooks";
import {ArrayItem} from "@workhorse/declarations";
import {DesignerAction} from "../../action";
import {durationBalancer, makeId} from "../../utils";
import {orchestrator} from "@workhorse/providers/state";
import {DOCUMENT_RESOURCE_TYPES, getDocumentToken} from "../../utils/getDocumentToken";
import {ResourceFullWithContent} from "@workhorse/api/resx/utils";

type ImportAgendaItems = Pick<DesignerApiSession, "agendaItems" | "macroArtifacts"> & {
    session?: {
        name?: string;
        timeDependency?: boolean;
        description?: string;
        createdFromTemplateId?: string;
    };
    callback?: () => void;
    bypassResourcePublicCheck?: Maybe<boolean>;
    oldSpeakers?: SessionParticipantFragment[];
    newSpeakers?: SessionParticipantFragment[];
};

const diffConstants = {
    isDeleted: false,
    oldId: null,
    update: null,
    createdAt: null as unknown as Date,
    updatedAt: null as unknown as Date,
};

export class ImportAgendaAction extends DesignerAction<ImportAgendaItems> {
    commit() {
        const current = {
            session: structuredClone(designer.currentSession()),
            macroArtifacts: structuredClone(designer.currentMacroArtifacts()),
        };
        (async () => {
            const {session: inputSession, agendaItems: inputAgendaItems, macroArtifacts: inputMacroArtifacts} = this.input;
            const {name = inputSession?.name ?? "", description = inputSession?.description ?? ""} = current?.session || {};

            const bypassResourcePublicCheck = !!this.input.bypassResourcePublicCheck;

            designer.api.session.update({
                ...inputSession,
                name,
                description,
                createdFromTemplateId: inputSession?.createdFromTemplateId,
            });

            if (inputMacroArtifacts?.length && current?.macroArtifacts) {
                current.macroArtifacts.forEach((macro) => {
                    const newMacro = inputMacroArtifacts?.find((m) => m.artifactId === macro.artifactId);
                    if (newMacro) {
                        designer.api.artifact.replaceMacroArtifact({
                            id: macro.id,
                            artifact: {
                                ...newMacro,
                                id: makeId(),
                                properties: macro.properties.map((p) => ({...p, id: makeId()})),
                            },
                        });
                    }
                });
            }

            const promises = structuredClone(inputAgendaItems)
                .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
                .map(async (agendaItem) => {
                    const {id, artifact, ...other} = agendaItem;
                    const newAgendaItemId = designer.api.agendaItem.create({
                        ...other,
                        type: AgendaItemType.Planned,
                        // updatedAt: null as unknown as Date,
                        // createdAt: null as unknown as Date,
                        endedAt: null,
                        agendaItemSpeakers: [],
                    });

                    if (other.agendaItemSpeakers.length) {
                        designer.api.agendaItem.update({
                            id: newAgendaItemId,
                            agendaItem: {
                                // @ts-expect-error typescript doesn't see the filter at line 117
                                agendaItemSpeakers:
                                    this.input.oldSpeakers?.length && this.input.newSpeakers?.length
                                        ? other.agendaItemSpeakers
                                              ?.map((speaker) => {
                                                  const oldParticipantEmail = this.input.oldSpeakers?.find(
                                                      (p) => p?.speakerDetails?.id && p.speakerDetails?.id === speaker.speakerId
                                                  )?.dataWithNullableEmail.email;

                                                  if (!oldParticipantEmail) {
                                                      return null;
                                                  }
                                                  const newSpeakerId = this.input.newSpeakers?.find(
                                                      (s) => s?.dataWithNullableEmail.email === oldParticipantEmail
                                                  )?.speakerDetails?.id;
                                                  if (!newSpeakerId) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...speaker,
                                                      __typename: "AgendaItemSpeaker" as const,
                                                      createdAt: null as unknown as Date,
                                                      updatedAt: null as unknown as Date,
                                                      agendaItemId: newAgendaItemId,
                                                      id: makeId(),
                                                      speakerId: newSpeakerId,
                                                  };
                                              })
                                              .filter((i) => !!i) ?? []
                                        : [],
                            },
                        });
                    }

                    const {properties: oldProperties = [], resourceResults, artifactId, ...artifactOther} = artifact ?? {};

                    const properties = oldProperties.map((property) => {
                        if (artifactId === ("flowos/blackboard" as ArtifactTag) && property.key === "roomId") {
                            property.value = new RandExp(/[0-9a-f]{20},[a-zA-Z0-9_-]{22}/).gen();
                        }

                        if (artifactId === ("flowos/breakout" as ArtifactTag) && property.key === "InitialBORConfiguration") {
                            const config = JSON.parse(property.value) as InitialConfigurationPropertyType | undefined;

                            config?.breakoutRooms.forEach((room) => {
                                room.id = makeId();
                                room.participantIds = [];
                            });

                            property.value = JSON.stringify(config);
                        }

                        return property;
                    });

                    const newArtifactId = makeId();
                    const resourceNames: ResourceNameFragment[] = [];

                    const newResourceResults = await Promise.all<ArrayItem<NonNullable<typeof resourceResults>>>(
                        (resourceResults || [])
                            .filter(({isDeleted}) => !isDeleted)
                            .map(async (resxResult) => {
                                const {result: oldResult} = await getResourceResult(resxResult.id);
                                if (!oldResult?.resource && !oldResult?.resourceContentSnapshot) {
                                    return null as any;
                                }

                                const isPublic = bypassResourcePublicCheck;

                                // FOR SMARTBOARD coming from public templates we need to create a new resource
                                if (isPublic && artifactId === "flowos/blackboard") {
                                    if (oldResult.resource?.content) {
                                        const newRoomLink = new RandExp(/[0-9a-f]{20},[a-zA-Z0-9_-]{22}/).gen();

                                        const resource = await services.resx.createResource("flowos/blackboard/resx/Blackboard", {
                                            ...(oldResult.resource.content as any),
                                            roomId: newRoomLink,
                                        });
                                        const result = await services.resx.createResult(
                                            "flowos/blackboard/resx/Blackboard",
                                            "latest",
                                            resource.id
                                        );
                                        if (!result) {
                                            return null as any;
                                        }

                                        return {
                                            id: result.id,
                                            ...diffConstants,
                                            __typename: "ResourceResult",
                                        };
                                    } else {
                                        return null;
                                    }
                                } else {
                                    const shouldUseSnapshot = !!(!oldResult?.resource && oldResult?.resourceContentSnapshot);
                                    const result = shouldUseSnapshot
                                        ? await services.resx.createResult(
                                              oldResult.type as any,
                                              "latest",
                                              undefined,
                                              undefined,
                                              oldResult.resourceContentSnapshot,
                                              bypassResourcePublicCheck,
                                              inputSession?.createdFromTemplateId
                                          )
                                        : oldResult.resource
                                        ? await services.resx.createResult(
                                              oldResult.resource.type as any,
                                              "latest",
                                              oldResult.resource.id,
                                              undefined,
                                              undefined,
                                              bypassResourcePublicCheck,
                                              inputSession?.createdFromTemplateId
                                          )
                                        : null;

                                    try {
                                        let name = "";
                                        const content = shouldUseSnapshot
                                            ? JSON.parse(oldResult.resourceContentSnapshot)
                                            : oldResult.resource?.content;

                                        name = content.name;

                                        if (current.session?.id && result?.id) {
                                            resourceNames.push({
                                                artifactId: newArtifactId,
                                                resultId: result.id,
                                                name,
                                                __typename: "ResourceName" as const,
                                            });
                                        }
                                    } catch (error) {
                                        console.log("error", error);
                                    }

                                    // FOSS-16340 - upsert document token for files resource types.

                                    try {
                                        if (result?.type && DOCUMENT_RESOURCE_TYPES.includes(result.type)) {
                                            const documentContent = (
                                                oldResult.resource as ResourceFullWithContent<"flowos/pdf/resx/Pdf", "latest">
                                            ).content;

                                            if (documentContent?.documentId) {
                                                const documentTokenProperty = await getDocumentToken(
                                                    result.type,
                                                    newArtifactId,
                                                    documentContent.documentId
                                                );

                                                if (documentTokenProperty) {
                                                    const propertyIndex = properties.findIndex(
                                                        ({key}) => key === documentTokenProperty.key
                                                    );

                                                    if (propertyIndex > -1) {
                                                        properties.splice(propertyIndex, 1, {
                                                            ...properties[propertyIndex],
                                                            ...documentTokenProperty,
                                                        });
                                                    } else {
                                                        properties.push({
                                                            id: makeId(),
                                                            __typename: "Property",
                                                            ...documentTokenProperty,
                                                            ...diffConstants,
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    } catch (error) {
                                        console.log("error", error);
                                    }

                                    if (!result) {
                                        return null as any;
                                    }
                                    return {
                                        id: result.id,
                                        ...diffConstants,
                                        __typename: "ResourceResult",
                                    };
                                }
                            })
                    );
                    if (resourceNames.length && current.session?.id) {
                        orchestrator.onSubscriptionResourceNames(current.session.id, resourceNames);
                    }

                    // handle followup sessions from old smartboards that don't have and shouldn't have a resource. We will create an empty resource for it
                    // this should be handled in the future for all artifacts with resources.
                    // TODO: create a artifactTag - resourceType map and use it here
                    // use descriptor[artifact.artifactId as ArtifactTag].capabilities.some((c) => c.type === "resx") to determine if artifact has resource capabilities
                    if (artifactId === "flowos/blackboard" && !resourceResults?.length) {
                        const newRoomLink = new RandExp(/[0-9a-f]{20},[a-zA-Z0-9_-]{22}/).gen();

                        const resource = await services.resx.createResource("flowos/blackboard/resx/Blackboard", {
                            name: "New Whiteboard",
                            roomId: newRoomLink,
                        });
                        if (resource.id) {
                            const result = await services.resx.createResult("flowos/blackboard/resx/Blackboard", "latest", resource.id);
                            if (result?.id) {
                                newResourceResults.push({
                                    id: result.id,
                                    ...diffConstants,
                                    __typename: "ResourceResult",
                                });
                            }
                        }
                    }

                    // TODO - SILVIU FOSS-16340 - document token

                    const shouldHaveResource =
                        artifactId &&
                        ["flowos/google-docs", "flowos/google-sheets", "flowos/google-slides", "flowos/google-forms"].includes(artifactId)
                            ? false
                            : descriptor[artifactId as ArtifactTag]?.capabilities.some((c) => c.type === "resx") || false;

                    const results = newResourceResults.filter((r) => !!r);
                    if (shouldHaveResource && !results.length) {
                        designer.api.agendaItem.addArtifact({
                            agendaItemId: newAgendaItemId,
                            overrides: {
                                ...diffConstants,
                                ...designer.constants.makeDefaultConferenceArtifact(newArtifactId, newAgendaItemId),
                            },
                        });
                    } else {
                        designer.api.agendaItem.addArtifact({
                            agendaItemId: newAgendaItemId,
                            overrides: {
                                ...artifactOther,
                                ...diffConstants,
                                id: newArtifactId,
                                artifactId,
                                properties: properties
                                    .filter(({isDeleted}) => !isDeleted)
                                    .map((prop) => ({
                                        ...prop,
                                        ...diffConstants,
                                        id: makeId(),
                                    })),
                                resourceResults: results,
                            },
                        });
                    }
                });

            await Promise.all(promises).then(() => {
                if (inputSession?.timeDependency) {
                    const updatedAgendaItems = structuredClone(
                        designer.currentAgendaItems().filter((a) => {
                            if (a.title === designer.constants.DUMMY_AGENDA_ITEM_TITLE && !a.isDeleted) {
                                return false;
                            }
                            return true;
                        }) ?? []
                    );
                    designer.updateCurrentSession({agendaItems: updatedAgendaItems}, {strategy: "replace"});
                }
                this.input.callback?.();
            });
        })();
        return current.session?.id!;
    }

    canRedo() {
        return false;
    }

    canUndo() {
        return false;
    }
}
