import {forwardRef} from "react";
import {TableHeadProps} from "./types";
import MuiTableHead from "@material-ui/core/TableHead";
import {cls} from "@ui/cdk/util";
import styles from "./style/TableHead.module.scss";

const TableHead = forwardRef((props: TableHeadProps, ref?: any) => {
    return (
        <MuiTableHead {...props} ref={ref} className={cls(`fosTableHead`, props.className, styles.tableHead)}>
            {props.children}
        </MuiTableHead>
    );
});

TableHead.displayName = "TableHead";

export default TableHead;
