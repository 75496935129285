import {AgendaItemType, ConferenceParticipantStatus} from "@generated/data";
import designer from "@workhorse/api/designer";
import React, {forwardRef} from "@workhorse/api/rendering";
import {useAgendaItems, useIsLobby, useMacroArtifacts, useMyParticipant, useSession} from "@workhorse/providers/SessionDataProviders";
import DrawerLeftActionButton from "../DrawerLeftActionButton";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type AgendaTogglerOptionProps = {
    onClick?: () => void;
    className?: string;
};

const AgendaTogglerOption = forwardRef((props: AgendaTogglerOptionProps, ref: React.MutableRefObject<unknown>) => {
    const {onClick, className} = props;

    const {isAssistant, isOwner, isSpeaker} = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            isOwner: !!currentParticipant?.isOwner,
            isSpeaker: !!currentParticipant?.speakerDetails || currentParticipant?.conferenceStatus === ConferenceParticipantStatus.Speaker,
            isAssistant: amIAssistant,
        }))
    );

    const {isLobby} = useIsLobby();
    const macroArtifacts = useMacroArtifacts();
    const agendaItems = useAgendaItems();
    const session = useSession();
    const {allowAgendaType} = session;

    const hasAgenda = !!macroArtifacts?.find((a) => a.artifactId === "flowos/agenda");

    const isOrganizer = isOwner || isAssistant;

    const isAgendaAllowed = sessionFlagBasedPermission(allowAgendaType, {
        owner: isOwner,
        assistant: isAssistant,
        speaker: isSpeaker,
    });

    const canSeeAgenda =
        (isLobby &&
            isAgendaAllowed &&
            agendaItems.some((a) => a.type === AgendaItemType.Planned || a.title !== designer.constants.DUMMY_AGENDA_ITEM_TITLE)) ||
        (!isLobby && hasAgenda && (isAgendaAllowed || isOrganizer));

    return <>{canSeeAgenda ? <DrawerLeftActionButton onClick={onClick} className={className} /> : null}</>;
});

export default AgendaTogglerOption;
