import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import {useCallback, useEffect, useState} from "@workhorse/api/rendering";
import getLastDayOccurrence from "@workhorse/api/utils/getLastDayOccurrence";
import {
    addDays,
    addMonths,
    eachDayOfInterval,
    endOfWeek,
    getDaysInMonth,
    isBefore,
    isSameDay,
    startOfDay,
    endOfMonth,
    startOfMonth,
    startOfWeek,
    subMonths,
} from "date-fns";
import {format} from "date-fns";
import Button from "../../../Button";
import {cls} from "@ui/cdk/util/util";
import classes from "../styles/Calendar.module.scss";
import {useTranslation} from "react-i18next";
import {getDateFnsLocale} from "@sessions/common/utils/languages";
import {dayOfTheWeekToNumber, WeekStartsOn} from "@workhorse/api/calendar";
import {useUserInfo} from "@workhorse/providers/User";

interface CalendarProps {
    startDate?: Date;
    endDate?: Date;
    onDateChange: (startDate: Date) => void;
    closePopup: () => void;
    disablePastDates?: boolean;
    bigger?: boolean;
    minDate?: Date;
    className?: string;
    showStartAndEndOfOtherMonths?: boolean;
    miniCalendar?: boolean;
}

const isWeekDayBeforeMonthStart = (day: string, monthStart: string, weekStartsOn: WeekStartsOn) => {
    const now = new Date();
    const daysOfTheWeek = eachDayOfInterval({
        start: startOfWeek(now, {
            weekStartsOn: weekStartsOn,
        }),
        end: endOfWeek(now, {
            weekStartsOn: weekStartsOn,
        }),
    })
        .map((d, index) => ({day: format(d, "iiiiii"), index}))
        .sort((a, b) => a.index - b.index)
        .map((d) => d.day);

    const startIndex = daysOfTheWeek.findIndex((d) => d === monthStart);
    const dayIndex = daysOfTheWeek.findIndex((d) => d === day);

    if (dayIndex < startIndex) {
        return true;
    }

    return false;
};

const isWeekDayAfterMonthEnd = (day: string, monthEnd: string, weekStartsOn: WeekStartsOn) => {
    const now = new Date();
    const daysOfTheWeek = eachDayOfInterval({
        start: startOfWeek(now, {
            weekStartsOn: weekStartsOn,
        }),
        end: endOfWeek(now, {
            weekStartsOn: weekStartsOn,
        }),
    })
        .map((d, index) => ({day: format(d, "iiiiii"), index}))
        .sort((a, b) => a.index - b.index)
        .map((d) => d.day);

    const startIndex = daysOfTheWeek.findIndex((d) => d === monthEnd);
    const dayIndex = daysOfTheWeek.findIndex((d) => d === day);

    if (dayIndex > startIndex) {
        return true;
    }

    return false;
};

const Calendar = (props: CalendarProps) => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language;
    const {
        startDate,
        endDate,
        onDateChange,
        closePopup,
        disablePastDates,
        bigger = false,
        minDate,
        className: classNameProp,
        showStartAndEndOfOtherMonths = false,
        miniCalendar,
    } = props;

    const user = useUserInfo();
    const firstDayOfWeekNo = dayOfTheWeekToNumber(user.firstDayOfWeek ?? "Monday");

    const [dateStart, setDateStart] = useState<Date>(startDate ?? new Date());

    useEffect(() => {
        if (startDate) {
            setDateStart(startDate);
        }
    }, [startDate]);

    const getDays = () => {
        const daysInMonth = getDaysInMonth(dateStart) + 1;

        const now = new Date();
        const daysInterval = eachDayOfInterval({
            start: startOfWeek(now, {
                weekStartsOn: firstDayOfWeekNo,
            }),
            end: endOfWeek(now, {
                weekStartsOn: firstDayOfWeekNo,
            }),
        });
        const daysOfWeek = daysInterval.reduce((a: Object, d, index) => {
            a[format(d, "iiiiii")] = {
                index,
                date: new Date(d),
                items: [],
            };
            return a;
        }, {});
        for (let index = 1; index < daysInMonth; index += 1) {
            const dateForTheDay = new Date(format(dateStart, `MM/${index}/yyyy`));
            const weekDayLong = format(dateForTheDay, "iiiiii");

            const isSelected = startDate
                ? startOfDay(new Date(startDate)).toISOString() === startOfDay(new Date(dateForTheDay)).toISOString()
                : false;

            const isEndDay =
                startDate && endDate && !isSameDay(startDate, new Date(endDate)) && isSameDay(new Date(endDate), new Date(dateForTheDay));

            const isBeforeToday = isBefore(startOfDay(dateForTheDay), startOfDay(minDate ?? now));

            const selectedDateForTheDay = () => {
                const hours = new Date(dateStart).getHours();
                const minutes = new Date(dateStart).getMinutes();
                const modifyingDate = new Date(dateForTheDay);
                const setting = new Date(new Date(modifyingDate.setHours(hours)).setMinutes(minutes));
                selectDate(setting, isBeforeToday && !!disablePastDates);
            };

            daysOfWeek[weekDayLong].items.push(
                <div
                    key={`${index}_day_button_${new Date(dateForTheDay).toISOString()}`}
                    className={cls(
                        classes.dayWrapper,
                        "calendar-dayWrapper",
                        isSelected ? `${classes.daySelected} calendar-daySelected` : "",
                        isEndDay ? classes.dayBetween : ""
                    )}
                >
                    <Button
                        key={`${index}_day_button_${new Date(dateForTheDay).toISOString()}`}
                        className={cls(
                            classes.day,
                            "calendar-day",
                            isBeforeToday && disablePastDates ? `${classes.disabled} calendar-dayDisabled` : ""
                        )}
                        type="button"
                        onClick={selectedDateForTheDay}
                        data-date={dateForTheDay}
                    >
                        {index < 10 ? "0" + index : index}
                    </Button>
                </div>
            );
        }

        return daysOfWeek;
    };

    const selectDate = (date: Date, isBeforeToday: boolean) => {
        if (isBeforeToday) {
            return;
        }

        const now = new Date();

        const currentDateHours = parseInt(format(startDate ?? now, "HH"));
        const currentDateMinutes = parseInt(format(startDate ?? now, "mm"));
        const setting = new Date(
            new Date(new Date(new Date(new Date(date.setMilliseconds(0)).setSeconds(0)).setHours(currentDateHours))).setMinutes(
                currentDateMinutes
            )
        );
        onDateChange(setting);
        setTimeout(() => {
            closePopup();
        }, 200);
        return;
    };

    const incrementMonth = () => {
        setDateStart(addMonths(dateStart, 1));
    };

    const decrementMonth = () => {
        setDateStart(subMonths(dateStart, 1));
    };

    const goToSpecificDay = (day: Date) => {
        selectDate(day, isBefore(day, new Date()));
    };

    const generateDays = useCallback(() => {
        const daysOfTheWeek = getDays();
        const monthStart = format(startOfMonth(new Date(dateStart)), "iiiiii");
        const monthEnd = format(endOfMonth(new Date(dateStart)), "iiiiii");

        const days = Object.keys(daysOfTheWeek).map((key, index) => (
            <div className={cls("flex11-auto", classes.weekWrapper)} key={`${key}_${index}`}>
                <Typography component="span" color="blueGray400" className={cls(classes.weekDay, "calendar-weekDay")}>
                    {t(`g.date.day.${key.toLowerCase()}`).substring(0, 2) ?? key.substring(0, 2)}
                </Typography>
                <div className={classes.week}>
                    {isWeekDayBeforeMonthStart(key, monthStart, firstDayOfWeekNo) ? (
                        <div className={cls(classes.dayWrapper, classes.dayPlaceholder, "calendar-dayWrapper")}>
                            {showStartAndEndOfOtherMonths ? (
                                <Button
                                    onClick={() => {
                                        goToSpecificDay(
                                            getLastDayOccurrence(addDays(endOfMonth(subMonths(new Date(dateStart), 1)), 1), key)
                                        );
                                    }}
                                    className={cls(classes.day, "calendar-day")}
                                    type="button"
                                >
                                    {format(getLastDayOccurrence(addDays(endOfMonth(subMonths(new Date(dateStart), 1)), 1), key), "dd")}
                                </Button>
                            ) : (
                                <div className={cls(classes.day, "hollow-day")} />
                            )}
                        </div>
                    ) : null}
                    {daysOfTheWeek[key].items.map((day) => day)}
                    {showStartAndEndOfOtherMonths && isWeekDayAfterMonthEnd(key, monthEnd, firstDayOfWeekNo) ? (
                        <div className={cls(classes.dayWrapper, classes.dayPlaceholder, "calendar-dayWrapper")}>
                            <Button
                                onClick={() =>
                                    goToSpecificDay(getLastDayOccurrence(addDays(startOfMonth(addMonths(new Date(dateStart), 1)), 6), key))
                                }
                                className={cls(classes.day, "calendar-day")}
                                type="button"
                            >
                                {format(getLastDayOccurrence(addDays(startOfMonth(addMonths(new Date(dateStart), 1)), 6), key), "dd")}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        ));
        return days;
    }, [dateStart]);

    return (
        <div className={cls("flex flex-col", classNameProp)} style={{minHeight: 305}}>
            <div className={cls("flex flex00-100 calendar-controls", classes.controls)}>
                <IconButton className={cls("calendar-control-prev calendar-nav", classes.control)} type="button" onClick={decrementMonth}>
                    <ChevronLeftIcon />
                </IconButton>
                <div className={cls("calendar-current-month", classes.current)}>
                    {format(dateStart, "MMMM, yyyy", {
                        locale: getDateFnsLocale(lang),
                    })}
                </div>

                <IconButton className={cls("calendar-control-next calendar-nav", classes.control)} type="button" onClick={incrementMonth}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <div className={cls("calendar-months flex flex00-auto", classes.month)}>
                {/* <div className={cls("calendar-month-divider", classes.monthDivider)}></div> */}
                {generateDays()}
            </div>
        </div>
    );
};

export default Calendar;
