import {sessionBackground} from "@workhorse/pages/user/profile/Theming/utils";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useReactiveVar} from "@workhorse/api/data";
import {useEffect, useState} from "@workhorse/api/rendering";
import env from "@generated/environment";

function SessionBackground() {
    const {isLobby} = useIsLobby();
    const [root] = useState<HTMLDivElement | null>(() => document.querySelector(":root"));
    const background =
        useReactiveVar(sessionBackground) ?? `${env.staticServiceUrl}/assets/session-backgrounds/default-background-white.jpg`;

    const isSessionView = !isLobby;

    useEffect(() => {
        root?.style.setProperty(
            "--app-background",
            background && isSessionView
                ? `transparent url("${background}") no-repeat center/cover`
                : "var(--sessions-elevation-background-4)"
        );
        root?.style.setProperty("--app-player-background", "transparent");
        return () => {
            root?.style.removeProperty("--app-background");
            root?.style.removeProperty("--app-player-background");
        };
    }, [background, isSessionView]);

    return null;
}

export default SessionBackground;
