import {AuthService} from "@workhorse/api/authService";
import {useEffect} from "@workhorse/api/rendering";
import Loading from "@workhorse/components/Loading";
import {RouteHOCProps} from "@workhorse/declarations";

type OrganizationRegisterHOCProps = RouteHOCProps<{ref?: string}>;

const UserOrganizationRegister = (props: OrganizationRegisterHOCProps) => {
    const refCode = new URLSearchParams(location.search).get("ref") ?? undefined;

    useEffect(() => {
        if (refCode) {
            AuthService.getInstance().register(`/?ref=${refCode}`);
        } else {
            AuthService.getInstance().register();
        }
    }, []);

    return <Loading location="UserOrganizationRegister.tsx" />;
};

export default UserOrganizationRegister;
