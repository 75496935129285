import {SessionParticipantFragment} from "@generated/data";
import Typography from "@ui/cdk/Typography";
import Snackbar from "@ui/core/components/Snackbar";
import {memo} from "@workhorse/api/rendering";
import classes from "./styles/ApprovalsComponent.module.scss";
import {cls} from "@ui/cdk/util/util";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {useTranslation} from "react-i18next";

interface ApprovalsComponentProps {
    onDecision: (participantId: string, willControlTool: boolean) => void;
    requestingParticipant?: Pick<SessionParticipantFragment, "id" | "dataWithNullableEmail">;
    onNotificationClose: () => void;
}

const ApprovalsComponent = (props: ApprovalsComponentProps) => {
    const {t} = useTranslation();
    const handleAccept = () => {
        if (props.requestingParticipant) {
            props.onDecision(props.requestingParticipant.id, true);
        }
    };

    const handleDecline = () => {
        if (props.requestingParticipant) {
            props.onDecision(props.requestingParticipant.id, false);
        }
    };

    return (
        <Snackbar
            open={props.requestingParticipant != null}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            className={classes.root}
            onClose={props.onNotificationClose}
        >
            <div className={cls(classes.notificationContentWrapper)}>
                <div className={cls(classes.participantContainer)}>
                    <ProfilePicture
                        avatar={props.requestingParticipant?.dataWithNullableEmail?.avatar ?? undefined}
                        avatarVariant="rounded"
                        avatarSize="XL"
                        firstName={props.requestingParticipant?.dataWithNullableEmail?.firstName}
                        lastName={props.requestingParticipant?.dataWithNullableEmail?.lastName}
                        className="mr-12"
                        email={props.requestingParticipant?.dataWithNullableEmail?.email}
                    />
                    <div className={classes.notificationTextWrapper}>
                        <Typography variant="lg" fontWeight="bolder">
                            {t("player.notifications.control_status.x_is_requesting_control", {
                                name: `${props.requestingParticipant?.dataWithNullableEmail.firstName} ${props.requestingParticipant?.dataWithNullableEmail.lastName}`,
                            })}
                        </Typography>
                        <Typography variant="base">
                            {t("player.notifications.control_status.x_is_requesting_control_description")}
                        </Typography>
                    </div>
                </div>

                <div className={cls(classes.buttonsContainer)}>
                    <Typography className={classes.ignoreButton} fontWeight="bold" onClick={handleDecline}>
                        {t("g.decline")}
                    </Typography>
                    <button className={classes.notificationPrimaryButton} onClick={handleAccept}>
                        {t("g.accept")}
                    </button>
                </div>
            </div>
        </Snackbar>
    );
};

export default memo(ApprovalsComponent, (prevProps, nextProps) => {
    if (prevProps.requestingParticipant?.id !== nextProps.requestingParticipant?.id) {
        return false;
    }

    return true;
});
