import loadable from "@workhorse/api/loadable";
import {useEffect} from "@workhorse/api/rendering";
import {useLocation} from "@workhorse/api/routing";
import {enableEventLeaveModal, routeNavigation} from "@workhorse/components/header/headerUtils";
import {RouteHOCProps} from "@workhorse/declarations";

const EventsEntrypoint = loadable(() => import("./EventsEntrypoint"));

type EventsHOCProps = RouteHOCProps<{}>;

function EventsHOC(props: EventsHOCProps): JSX.Element {
    const location = useLocation();

    useEffect(() => {
        enableEventLeaveModal(false);
        routeNavigation(undefined);
    }, []);

    return (
        <div className="route-hoc flex-align-start events-hoc" key="events-hoc">
            {/* <Switch location={location} key="events-routes-switch"> */}
            {/* <Route key="events"> */}
            {/* {(routeProps: RouteChildrenProps) => { */}
            <EventsEntrypoint location={location} />
            {/* </Route>
            </Switch> */}
        </div>
    );
}

export default EventsHOC;
