import browserInfo from "@workhorse/api/BrowserInfo";

class BrowserBehavior {
    supportsSimulcast() {
        return !browserInfo.isMobile() && !browserInfo.isTablet() && browserInfo.isChromium();
    }

    supportsBackgroundFilter() {
        return this.supportsCanvasCaptureStream() && this.supportsOffscreenCanvas2DCanvasContext();
    }

    supportsSetSinkId() {
        return this.hasNativeSetSinkId() && this.isSetSinkIdAllowed();
    }

    supportsWebRTC() {
        const anyRTCCheck = browserBehavior.hasChromiumWebRTC() || browserBehavior.hasWebKitWebRTC() || browserBehavior.hasFirefoxWebRTC();
        const rtcConstructors = ["RTCPeerConnection", "webkitRTCPeerConnection", "mozRTCPeerConnection", "RTCIceGatherer"];
        const hasRTCConstructors = rtcConstructors.some((prop) => prop in window);
        return anyRTCCheck && hasRTCConstructors;
    }

    hasChromiumWebRTC(): boolean {
        return browserInfo.isChromium();
    }

    hasWebKitWebRTC(): boolean {
        return browserInfo.isWebkit();
    }

    hasFirefoxWebRTC(): boolean {
        return browserInfo.isFirefox();
    }

    supportsCanvasCaptureStream() {
        return "captureStream" in HTMLCanvasElement.prototype;
    }

    supportsOffscreenCanvas2DCanvasContext() {
        try {
            const canvas = new OffscreenCanvas(1, 1);
            const context = canvas.getContext("2d");
            return context != null;
        } catch (e) {
            return false;
        }
    }

    private hasNativeSetSinkId() {
        return "setSinkId" in HTMLAudioElement.prototype;
    }

    private isSetSinkIdAllowed() {
        return !browserInfo.isFirefox();
    }
}

export const browserBehavior = new BrowserBehavior();
