import {cls} from "@ui/cdk/util";
import classes from "../styles/BookTimeSlots.module.scss";
import customClasses from "../styles/BookEventDuration.module.scss";

type BookEventDurationSlots = {
    options: {text: string; value: number}[];
    onSelect: (value: number) => void;
    duration: number;
};
const BookEventDurationSlots = (props: BookEventDurationSlots) => {
    const {options, onSelect, duration} = props;

    return (
        <div className={cls(classes.root, customClasses.options)}>
            {options.map((o) => (
                <div
                    key={o.value}
                    className={cls(classes.timeSlot, duration === o.value && classes.timeSlotSelected)}
                    style={{justifyContent: "center"}}
                    onClick={() => onSelect(o.value)}
                >
                    {o.text}
                </div>
            ))}
        </div>
    );
};

export default BookEventDurationSlots;
