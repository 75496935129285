import {FileRef} from "@api/resx/ref";
import {makeId} from "@workhorse/api/designer/lib/utils";
import {useRef, useState, useCallback, useMemo, ReactNode} from "@workhorse/api/rendering";
import {ErrorType, uploadOneResource} from "@workhorse/components/resx/api";
import svc from "@api/service/client";
import type {ResourcesTypes} from "@generated/artifacts/resources";
import {getFileExtension, getFileHeader} from "@workhorse/api/file_explorer/fileExplorer";
import {createContextProvider} from "@workhorse/api/utils/context";
import {createAgendaItemWithResource} from "@workhorse/api/designer/methods";
import {FileMimeType} from "@sessions/common/various";
import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";

type ResourceItemState = "uploading" | "processing" | "error" | "ready";

export interface ResourceUploadItem {
    key: string;
    name: string;
    namespaceId: string;
    fileRef?: FileRef;
    state: ResourceItemState;
    uploadProgress: number;
    error?: unknown;
    errorType?: ErrorType;
    resourceType: ResourcesTypes;
    resourceLocator?: {
        id: string;
        type: ResourcesTypes;
    };
}

type CreateFileOptions = {
    resourceType: ResourcesTypes;
    artifactTag: SimpleArtifactsTag;
    acceptedTypes: string[];
};

const mimeTypes = {
    image: ["image/*"],
    video: ["video/*"],
    pdf: ["application/pdf"],
    ppt: [
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
    doc: [
        "application/doc",
        "application/ms-doc",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xls: [
        "application/excel",
        "application/vnd.ms-excel",
        "application/x-excel",
        "application/x-msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    keynote: ["application/vnd.apple.keynote"],
    pages: ["application/vnd.apple.pages"],
    numbers: ["application/vnd.apple.numbers"],
    odt: ["application/vnd.oasis.opendocument.text"],
    odp: ["application/vnd.oasis.opendocument.presentation"],
    ods: ["application/vnd.oasis.opendocument.spreadsheet"],
};

const extensions = {
    ppt: [".ppt", ".pptx"],
    doc: [".doc", ".docx"],
    xls: [".xls", ".xlsx"],
    keynote: [".key"],
    numbers: [".numbers"],
    pages: [".pages"],
    odt: [".odt"],
    odp: [".odp"],
    ods: [".ods"],
};

const accept = [...Object.values(mimeTypes).flat(), ...Object.values(extensions).flat()].join(", ");

function filesAreImages(files: File[]) {
    return files.every((file) => file.type.startsWith("image/"));
}

async function checkFilesHeader(files: File[], type: FileMimeType) {
    try {
        const results = await Promise.all(files.map((file) => getFileHeader(file)));
        return results.every((result) => result === type);
    } catch (e) {
        return false;
    }
}

function filesAreVideos(files: File[]) {
    return files.every((file) => file.type.startsWith("video/"));
}

function filesArePDF(files: File[]) {
    return files.every((file) => mimeTypes.pdf.includes(file.type));
}

function filesAreOfType(files: File[], type: keyof typeof extensions) {
    const matchesMimeType = files.every((file) => mimeTypes[type]?.includes(file.type));
    if (matchesMimeType) {
        return true;
    }

    return files.every((file) => extensions[type].some((ext) => file.name.endsWith(ext)));
}

function useUploadResourceStore() {
    const uploadRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<Error>();
    const [resources, setResources] = useState<ResourceUploadItem[]>([]);

    const createResource = useCallback((namespaceId: string, resourceType: ResourcesTypes, key: string, file: File) => {
        setResources((current) => {
            return [
                ...current,
                {
                    key,
                    name: file.name,
                    namespaceId,
                    state: "uploading",
                    resourceType,
                    uploadProgress: 0,
                },
            ];
        });
    }, []);

    const updateResource = useCallback((key: string, data: Partial<ResourceUploadItem>) => {
        setResources((current) => {
            return current.map((resource) => {
                if (resource.key === key) {
                    return {...resource, ...data};
                }
                return resource;
            });
        });
    }, []);

    const updateResources = useCallback((namespaceId: string, data: Partial<ResourceUploadItem>) => {
        setResources((current) => {
            return current.map((resource) => {
                if (resource.namespaceId === namespaceId) {
                    return {...resource, ...data};
                }
                return resource;
            });
        });
    }, []);

    const deleteResources = useCallback((namespaceId: string) => {
        setResources((current) => {
            return current.filter((resource) => resource.namespaceId !== namespaceId);
        });
    }, []);

    const uploadFile = useCallback(
        async (namespaceId: string, resourceType: ResourcesTypes, file: File) => {
            const key = makeId();
            createResource(namespaceId, resourceType, key, file);

            const onError = (name: string, errorType: ErrorType) => {
                updateResource(key, {errorType, state: "error"});
            };

            const onProgress = (name: string, progress: number) => {
                updateResource(key, {uploadProgress: progress});
            };

            try {
                const result = await uploadOneResource(file, onError, onProgress);
                updateResource(key, {fileRef: result, state: "processing", uploadProgress: 100});
                return result;
            } catch (e) {
                updateResource(key, {error: e, state: "error"});
                throw e;
            }
        },
        [createResource, updateResource]
    );

    const createGallery = useCallback(
        async (files: File[]) => {
            const namespaceId = makeId();
            const oneFile = files.length === 1;

            try {
                const resourceLocator = await svc.resx.createResource(
                    "flowos/gallery/resx/Gallery",
                    {
                        name: "Gallery",
                        fileRefs: [],
                        filesNumber: 0,
                        order: [],
                    },
                    {
                        isHidden: true,
                    }
                );
                await createAgendaItemWithResource("flowos/gallery", resourceLocator.id, "flowos/gallery/resx/Gallery");
                const fileRefs = await Promise.all(files.map((file) => uploadFile(namespaceId, "flowos/gallery/resx/Gallery", file)));
                await svc.resx.updateResource(
                    resourceLocator.id,
                    resourceLocator.type,
                    {
                        name: oneFile ? files[0].name : "Gallery",
                        fileRefs,
                        filesNumber: fileRefs.length,
                        order: fileRefs.map((f) => f.id),
                    },
                    undefined,
                    {
                        isHidden: false,
                    }
                );

                updateResources(namespaceId, {state: "ready", resourceLocator});
            } catch (e) {
                updateResources(namespaceId, {state: "error", error: e});
            }
        },
        [uploadFile, updateResources]
    );

    const createVideo = useCallback(
        async (file: File) => {
            const namespaceId = makeId();

            try {
                const resourceLocator = await svc.resx.createResource(
                    "flowos/video/resx/Video",
                    {
                        name: "",
                        videoSource: "",
                        type: "Uploaded",
                    },
                    {
                        isHidden: true,
                    }
                );
                await createAgendaItemWithResource("flowos/video", resourceLocator.id, "flowos/video/resx/Video");
                const fileRef = await uploadFile(namespaceId, "flowos/video/resx/Video", file);
                await svc.resx.updateResource(
                    resourceLocator.id,
                    resourceLocator.type,
                    {
                        name: fileRef.fileName ?? "",
                        videoSource: fileRef.publicPath,
                        uploadedVideo: fileRef,
                    },
                    undefined,
                    {
                        isHidden: false,
                    }
                );
                await svc.resx.startResourceProcessing(resourceLocator.id);

                updateResources(namespaceId, {state: "ready", resourceLocator});
            } catch (e) {
                updateResources(namespaceId, {state: "error", error: e});
            }
        },
        [uploadFile, updateResources]
    );

    const createPDF = useCallback(
        async (file: File) => {
            const namespaceId = makeId();
            try {
                const resourceLocator = await svc.resx.createResource(
                    "flowos/pdf/resx/Pdf",
                    {
                        type: "PDF" as const,
                        name: "",
                    },
                    {
                        isHidden: true,
                    }
                );

                await createAgendaItemWithResource("flowos/pdf", resourceLocator.id, "flowos/pdf/resx/Pdf");
                const fileRef = await uploadFile(namespaceId, "flowos/pdf/resx/Pdf", file);

                await svc.resx.updateResource(
                    resourceLocator.id,
                    resourceLocator.type,
                    {
                        name: fileRef.fileName ?? "",
                        input: fileRef,
                    },
                    undefined,
                    {
                        isHidden: false,
                    }
                );

                await svc.resx.startResourceProcessing(resourceLocator.id);

                updateResources(namespaceId, {state: "ready", resourceLocator});
            } catch (e) {
                updateResources(namespaceId, {state: "error", error: e});
            }
        },
        [uploadFile, updateResources]
    );

    const createOfficeFile = useCallback(
        async (file: File, options: CreateFileOptions) => {
            const {artifactTag, resourceType, acceptedTypes} = options;
            const defaultType = acceptedTypes[0];
            const namespaceId = makeId();

            try {
                const resourceLocator = await svc.resx.createResource(
                    options.resourceType,
                    {
                        type: defaultType as any,
                        name: "",
                    },
                    {
                        isHidden: true,
                    }
                );

                await createAgendaItemWithResource(artifactTag, resourceLocator.id, resourceType);
                const fileRef = await uploadFile(namespaceId, resourceType, file);
                const extension = getFileExtension(fileRef.fileName ?? "");
                const type = acceptedTypes.find((el) => extension === el) ?? defaultType;

                await svc.resx.updateResource(
                    resourceLocator.id,
                    resourceLocator.type,
                    {
                        type: type as any,
                        name: fileRef.fileName ?? "",
                        input: fileRef,
                    },
                    undefined,
                    {
                        isHidden: false,
                    }
                );

                await svc.resx.startResourceProcessing(resourceLocator.id);
                updateResources(namespaceId, {state: "ready", resourceLocator});
            } catch (e) {
                updateResources(namespaceId, {state: "error", error: e});
            }
        },
        [uploadFile, updateResources]
    );

    const uploadFiles = useCallback(
        async (files: File[]) => {
            setError(undefined);

            if (files.length === 0) {
                return;
            }

            if (filesAreImages(files)) {
                const supported = await checkFilesHeader(files, FileMimeType.Image);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }
                return createGallery(files);
            }

            if (files.length > 1) {
                return setError(new Error("no_multiple_files"));
            }

            if (filesAreVideos(files)) {
                const supported = await checkFilesHeader(files, FileMimeType.Video);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }
                return createVideo(files[0]);
            }

            if (filesArePDF(files)) {
                const supported = await checkFilesHeader(files, FileMimeType.Pdf);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }
                return createPDF(files[0]);
            }

            if (filesAreOfType(files, "ppt")) {
                const supported = await checkFilesHeader(files, FileMimeType.Presentation);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/ppt/resx/Ppt",
                    artifactTag: "flowos/ppt",
                    acceptedTypes: ["PPT", "PPTX"],
                });
            }

            if (filesAreOfType(files, "doc")) {
                const supported = await checkFilesHeader(files, FileMimeType.Document);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }
                return createOfficeFile(files[0], {
                    resourceType: "flowos/doc/resx/Doc",
                    artifactTag: "flowos/doc",
                    acceptedTypes: ["DOC", "DOCX"],
                });
            }

            if (filesAreOfType(files, "xls")) {
                const supported = await checkFilesHeader(files, FileMimeType.Spreadsheet);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/excel/resx/Excel",
                    artifactTag: "flowos/excel",
                    acceptedTypes: ["XLS", "XLSX"],
                });
            }

            if (filesAreOfType(files, "keynote")) {
                const supported = await checkFilesHeader(files, FileMimeType.Presentation);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/keynote/resx/Keynote",
                    artifactTag: "flowos/keynote",
                    acceptedTypes: ["KEY"],
                });
            }

            if (filesAreOfType(files, "pages")) {
                const supported = await checkFilesHeader(files, FileMimeType.Document);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/pages/resx/Pages",
                    artifactTag: "flowos/pages",
                    acceptedTypes: ["PAGES"],
                });
            }

            if (filesAreOfType(files, "numbers")) {
                const supported = await checkFilesHeader(files, FileMimeType.Spreadsheet);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/numbers/resx/Numbers",
                    artifactTag: "flowos/numbers",
                    acceptedTypes: ["NUMBERS"],
                });
            }

            if (filesAreOfType(files, "odt")) {
                const supported = await checkFilesHeader(files, FileMimeType.Document);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/doc/resx/Doc",
                    artifactTag: "flowos/doc",
                    acceptedTypes: ["ODT"],
                });
            }

            if (filesAreOfType(files, "odp")) {
                const supported = await checkFilesHeader(files, FileMimeType.Presentation);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/ppt/resx/Ppt",
                    artifactTag: "flowos/ppt",
                    acceptedTypes: ["ODP"],
                });
            }

            if (filesAreOfType(files, "ods")) {
                const supported = await checkFilesHeader(files, FileMimeType.Spreadsheet);
                if (!supported) {
                    return setError(new Error("no_supported_files"));
                }

                return createOfficeFile(files[0], {
                    resourceType: "flowos/excel/resx/Excel",
                    artifactTag: "flowos/excel",
                    acceptedTypes: ["ODS"],
                });
            }

            return setError(new Error("no_supported_files"));
        },
        [createGallery, createVideo, createPDF, createOfficeFile]
    );

    const triggerUpload = useCallback(() => {
        if (uploadRef.current) {
            uploadRef.current.value = "";
        }
        uploadRef.current?.click();
    }, []);

    return useMemo(
        () => ({
            error,
            uploadRef,
            resources,
            setError,
            triggerUpload,
            uploadFiles,
            updateResources,
            deleteResources,
        }),
        [resources, error, triggerUpload, uploadFiles, updateResources, deleteResources]
    );
}

const [UploadResourceProviderBase, useUploadResource] = createContextProvider(
    {
        name: "UploadResource",
    },
    useUploadResourceStore
);

function UploadResourceHandler() {
    const {uploadRef, uploadFiles} = useUploadResource();
    return (
        <input
            ref={uploadRef}
            type="file"
            multiple={true}
            style={{display: "none"}}
            accept={accept}
            onChange={(e) => {
                const fileList = e.currentTarget.files;
                if (fileList != null) {
                    uploadFiles(Array.from(fileList));
                }
            }}
        />
    );
}

function UploadResourceProvider({children}: {children: ReactNode}) {
    return (
        <UploadResourceProviderBase>
            <UploadResourceHandler />
            {children}
        </UploadResourceProviderBase>
    );
}

export {UploadResourceProvider, useUploadResource};

export function UploadTest() {
    const {triggerUpload} = useUploadResource();
    return (
        <button style={{position: "fixed", top: 600, left: 500, zIndex: 99999999}} onClick={() => triggerUpload()}>
            Click
        </button>
    );
}
