import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {ReactComponent as NoContactsAddedIcon} from "../../../../assets/media/no-contacts-added.svg";

type EventAnalyticsTableNoResultsProps = {
    onCreate: () => void;
};
const EventAnalyticsTableNoResults = (props: EventAnalyticsTableNoResultsProps) => {
    return (
        <div className="fullw flex flex-col flex-align-center">
            <NoContactsAddedIcon />
            <Typography className="mt-24" color="tertiary" fontWeight="bolder">
                Start by creating a UTM code
            </Typography>
            <Typography className="mb-32" color="tertiary">
                UTM codes help you track where your traffic comes from when people visit your event page.
            </Typography>
            <Button variant="secondary" size="small" onClick={props.onCreate}>
                Create UTM code
            </Button>
        </div>
    );
};

export default EventAnalyticsTableNoResults;
