import React, {forwardRef} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {ReactionMapType, reactions} from "../../../Reactions/api";
import classes from "./styles/ReactionItem.module.scss";
import Tooltip from "@ui/cdk/Tooltip";
import MenuItem from "@ui/cdk/MenuItem";
import {useReaction} from "@workhorse/providers/SessionReactionsProvider";
import {useTranslation} from "react-i18next";

type ReactionItemProps = {
    className?: string;
} & ReactionMapType;

export const ReactionItem = forwardRef((props: ReactionItemProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {className, iconSmall, type, name} = props;

    const {triggerReaction} = useReaction();

    return (
        <Tooltip title={t(`participant.reaction.${reactions[type].key}`) || reactions[type].name} placement="top">
            <MenuItem
                data-id="reaction-item"
                ref={ref}
                className={cls(classes.reactionItem, className)}
                onClick={triggerReaction.bind(null, type)}
            >
                <img src={iconSmall} alt={name} />
            </MenuItem>
        </Tooltip>
    );
});

export default ReactionItem;
