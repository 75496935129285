import {useReactiveVar} from "@workhorse/api/data";
import loadable from "@workhorse/api/loadable";
import {useMemo} from "@workhorse/api/rendering";
import {WithMobileState} from "@workhorse/declarations";
import {reportDialogEnabled} from "./reportDialogApi";

const ReportProblemDialog = loadable(() => import("../../components/reportProblem/ReportProblemDialog"));

function ReportProblemLoader(props: WithMobileState) {
    const IsOpen = useReactiveVar(reportDialogEnabled);
    /**
     * @TODO
     * determine if we should add a everLoaded param to keep the dialog mounted after first load
     */
    return useMemo(() => {
        return IsOpen ? <ReportProblemDialog key="report-problem-dialog-loader" mobileState={props.mobileState} /> : null;
    }, [IsOpen]) as JSX.Element;
}

export default ReportProblemLoader;
