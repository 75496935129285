import {AccessListItem, AccessListItemsDocument} from "@generated/data";
import {useQuery, useMutation} from "@workhorse/dataApi";
import GiveAccess from "../GiveAccess/GiveAccess";

import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import classes from "./style/AccessList.module.scss";
import {useState} from "@workhorse/api/rendering";

import Typography from "@ui/cdk/Typography";
import Tooltip from "@ui/cdk/Tooltip";
import {cls} from "@ui/cdk/util";
import toast from "@workhorse/api/toast";

type AccessListProps = {
    resourceId?: string;
    agendatemplateId?: string;
    canGiveAccess?: boolean;
    onFirstAccess?: () => void;
};
const AccessList = (props: AccessListProps) => {
    const {resourceId, agendatemplateId, canGiveAccess} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const {data, refetch} = useQuery("AccessListItemsDocument", {
        variables: {
            where: {
                resourceId: {
                    equals: resourceId,
                },
                agendatemplateId: {
                    equals: agendatemplateId,
                },
            },
        },
    });

    const [deleteAccess] = useMutation("DeleteOneAccessListItemDocument");
    const [createAccess] = useMutation("CreateOneAccessListItemDocument");

    const removeAccess = async (id: string) => {
        await deleteAccess({
            variables: {
                where: {
                    id,
                },
            },
            refetchQueries: [
                {
                    query: AccessListItemsDocument,
                    variables: {
                        where: {
                            resourceId: {
                                equals: resourceId,
                            },
                            agendatemplateId: {
                                equals: agendatemplateId,
                            },
                        },
                    },
                },
            ],
            // update: (cache) => {
            //     console.log("in update");
            //     cache.evict({
            //         id: cache.identify({
            //             __ref: id,
            //         }),
            //     });
            //     cache.gc();
            // },
        });
    };

    const giveAccess = async (email: string) => {
        const response = await createAccess({
            variables: {
                data: {
                    ...(agendatemplateId
                        ? {
                              agendaTemplate: {
                                  connect: {
                                      id: agendatemplateId,
                                  },
                              },
                          }
                        : {}),
                    ...(resourceId
                        ? {
                              resource: {
                                  connect: {
                                      id: resourceId,
                                  },
                              },
                          }
                        : {}),
                    user: {
                        connect: {
                            email: email.toLowerCase(),
                        },
                    },
                },
            },
            refetchQueries: [
                {
                    query: AccessListItemsDocument,
                    variables: {
                        where: {
                            resourceId: {
                                equals: resourceId,
                            },
                            agendatemplateId: {
                                equals: agendatemplateId,
                            },
                        },
                    },
                },
            ],
        });

        if (!response.data) {
            toast(`Error giving access to ${email}. Only people from your organization are allowed to edit this agenda.`, {
                type: "error",
            });

            return false;
        }

        if (!data?.accessListItems.length) {
            props.onFirstAccess?.();
        }
        return true;
    };

    const refetchAccessList = async () => {
        await refetch();
    };

    return (
        <div className="flex flex-align-center">
            {data && data?.accessListItems?.length > 0 ? (
                <div className="flex flex-align-center mr-12">
                    {data?.accessListItems?.slice(0, 5).map((item) => (
                        <ProfilePicture
                            className={cls(classes.listItem, data?.accessListItems?.length > 5 && classes.listItemMore)}
                            firstName={item.user.firstName}
                            lastName={item.user.lastName}
                            avatar={item.user.profilePicture}
                            avatarSize="M"
                            avatarVariant="rounded"
                            key={item.user.id}
                            withWhiteBorder
                        />
                    ))}
                    {data?.accessListItems?.length > 5 ? (
                        <>
                            <Tooltip
                                open={Boolean(anchorEl)}
                                onClose={handleClosePopover}
                                interactive={true}
                                classes={{
                                    tooltip: classes.tooltip,
                                }}
                                placement="right"
                                title={
                                    <div className={cls("flex flex-col", classes.list)}>
                                        {data?.accessListItems?.map((item) => (
                                            <div
                                                aria-haspopup="true"
                                                key={item.id}
                                                className={cls(classes.participant, "flex flex-align-center flex-justify-start")}
                                            >
                                                <ProfilePicture
                                                    firstName={item.user.firstName}
                                                    lastName={item.user.lastName}
                                                    avatar={item.user.profilePicture}
                                                    avatarSize="M"
                                                    avatarVariant="rounded"
                                                    key={item.user.id}
                                                    withWhiteBorder
                                                />
                                                {(item.user.firstName + item.user.lastName).length > 30 ? (
                                                    <Tooltip
                                                        title={
                                                            <Typography className={cls("ml-8", classes.listItemName)}>
                                                                {item.user.firstName} {item.user.lastName}
                                                            </Typography>
                                                        }
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <Typography className={cls("ml-8", classes.listItemName)}>
                                                            {item.user.firstName} {item.user.lastName}
                                                        </Typography>
                                                    </Tooltip>
                                                ) : (
                                                    <Typography className={cls("ml-8", classes.listItemName)}>
                                                        {item.user.firstName} {item.user.lastName}
                                                    </Typography>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                }
                            >
                                <div className={classes.countContainer} onMouseOver={handleOpenPopover}>
                                    <Typography>+{data?.accessListItems?.length - 5}</Typography>
                                </div>
                            </Tooltip>
                        </>
                    ) : null}
                </div>
            ) : null}

            {canGiveAccess ? (
                <GiveAccess
                    {...props}
                    accessList={data?.accessListItems ?? []}
                    giveAccess={giveAccess}
                    removeAccess={removeAccess}
                    refetchAccessList={refetchAccessList}
                />
            ) : null}
        </div>
    );
};

export default AccessList;
