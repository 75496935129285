import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import genericStyles from "../../../styles/GenericSection.module.scss";
import {ReactComponent as WhoCanSeeAgendaIcon} from "../../assets/who-can-see-agenda.svg";
import Select from "@ui/cdk/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {SessionFlagsType} from "@generated/data";
import {useTranslation} from "react-i18next";

type HideAgendaSelectorProps = {
    value: SessionFlagsType;
    onChange: (enabled: SessionFlagsType) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const HideAgendaSelector = ({value, onChange, showIcon = false, disabled, className}: HideAgendaSelectorProps) => {
    const {t} = useTranslation();

    const options = [
        {value: SessionFlagsType.Everyone, text: t("g.session.values_role.everyone") ?? "Everyone"},
        {value: SessionFlagsType.Speakers, text: t("g.session.values_role.speakers_hosts") ?? "Speakers & Hosts"},
        {value: SessionFlagsType.Hosts, text: t("g.session.values_role.hosts") ?? "Hosts"},
    ];

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as SessionFlagsType;
        onChange(value);
    };

    return (
        <div className={cls(genericStyles.togglerWrapper, className)}>
            {showIcon ? (
                <div className={genericStyles.iconWrapper}>
                    <WhoCanSeeAgendaIcon />
                </div>
            ) : null}
            <div className="flex flex-justify-between flex-align-center fullw">
                <div className={genericStyles.textWrapper}>
                    <Typography variant="base" fontWeight="bold" color="secondary">
                        {t("player.settings.general.access.view_agenda.title") ?? "View agenda"}
                    </Typography>
                    <Typography color="quaternary" component="span">
                        {t("player.settings.general.access.view_agenda.description") ?? "Who can see the agenda"}
                    </Typography>
                </div>

                <Select
                    data-id="view-agenda-settings"
                    options={options}
                    value={value}
                    IconComponent={ExpandMoreRoundedIcon}
                    onChange={handleOnChange}
                    className={genericStyles.selectInput}
                    MenuProps={{
                        classes: {
                            paper: genericStyles.selectMenuPaper,
                        },
                    }}
                    wrapperClassName={genericStyles.selectFormControl}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default HideAgendaSelector;
