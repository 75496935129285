import loadable from "@workhorse/api/loadable";
import {useMemo} from "@workhorse/api/rendering";
import {RouteHOCProps} from "@workhorse/declarations";

// there is no guarantee the user will land on the base every time
// or on any route for that matter
// which is why we lazy-load them... ALL
const ConnectContainer = loadable(() => import("./ConnectContainer"));

export type ConnectRouteMatch = {writeKey: string};
export interface ConnectHocProps extends RouteHOCProps<ConnectRouteMatch> {}

// a HOC for the Base and all the main routes, may seem redundant
// but the purpose is to add a parent container and lazy load the main component
// not much logic should exist here, except passing props downstream
// and/or styling the wrapper to enforce some strict behavior for the child
function ConnectHOC(props: ConnectHocProps): JSX.Element {
    const {writeKey} = (props.match?.params ?? {}) as ConnectRouteMatch;

    return useMemo(
        () => (
            <div className="route-hoc base-hoc" style={{overflow: "unset"}} key="base-hoc">
                <ConnectContainer writeKey={writeKey} />
            </div>
        ),
        []
    );
}

export default ConnectHOC;
