import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {useGoogleLogin, UseGoogleLoginProps} from "../util";

type GoogleButtonProps = (props: {onClick: () => void}) => JSX.Element;

type GoogleLoginProps = UseGoogleLoginProps & {
    scopes: string[];

    synced: boolean;
    email?: string;

    renderLogin?: GoogleButtonProps;
    renderLogout?: GoogleButtonProps;
};

export const GoogleLogin = (params: GoogleLoginProps): JSX.Element | null => {
    const {synced, scopes, email, renderLogin, renderLogout, onLogin, onLoginFailure, onLogout} = params;

    const [signIn, signOut] = useGoogleLogin({onLogin, onLoginFailure, onLogout});

    const [trackEvent] = useTrackingEvents();

    if (!synced && renderLogin) {
        const button = renderLogin({
            onClick: () => {
                trackEvent("sync-google", {event_category: TrackingEventCategory.SyncActions});
                signIn(scopes);
            },
        });

        return <>{button}</>;
    } else if (renderLogout) {
        const button = renderLogout({onClick: signOut.bind(null, email)});

        return <>{button}</>;
    }

    return null;
};
