import {VideoFxConfig} from "./VideoReplacementConfig";

export enum VideoReplacementDataType {
    Init = "init",
    Stop = "stop",

    Update = "update",
    Process = "process",
    Resize = "resize",

    Error = "error",

    Latency = "latency",
    FrameRate = "frame-rate",
    PendingFramesExceeded = "pending-frames-exceeded",
}

type DataMap = {
    [VideoReplacementDataType.Init]: VideoReplacementDataInit;
    [VideoReplacementDataType.Stop]: undefined;

    [VideoReplacementDataType.Update]: VideoReplacementDataUpdate;
    [VideoReplacementDataType.Process]: VideoReplacementDataProcess;
    [VideoReplacementDataType.Resize]: VideoReplacementDataResize;

    [VideoReplacementDataType.Error]: string;

    [VideoReplacementDataType.Latency]: number;
    [VideoReplacementDataType.FrameRate]: number;
    [VideoReplacementDataType.PendingFramesExceeded]: VideoReplacementDataPendingFramesExceeded;
};

export interface VideoReplacementDataInit {
    webglCanvas: OffscreenCanvas;
    outputCanvas: OffscreenCanvas;
    helperCanvas: OffscreenCanvas;
    maskCanvas: OffscreenCanvas;
    config: VideoFxConfig;
    isFirefox: boolean;
    origin: string;
}

export interface VideoReplacementDataUpdate {
    config: VideoFxConfig;
}

export interface VideoReplacementDataProcess {
    buffer: ArrayBufferLike;
    width: number;
    height: number;
}

export interface VideoReplacementDataPendingFramesExceeded {
    pendingFrames: number;
    by: number;
}

export interface VideoReplacementDataResize {
    width: number;
    height: number;
}

type VideoReplacementData = {
    type: VideoReplacementDataType;
    data: DataMap[VideoReplacementDataType];
};

export class VideoReplacementProcessorWorkerAdapter {
    private callbackMap: Record<string, Array<(data: any) => void> | undefined> = {};

    constructor(private worker: Worker) {
        this.worker.addEventListener("message", this.onMessage.bind(this));
    }

    init(data: VideoReplacementDataInit) {
        this.push(VideoReplacementDataType.Init, data, [data.outputCanvas, data.webglCanvas, data.helperCanvas, data.maskCanvas]);
    }

    update(data: VideoReplacementDataUpdate) {
        this.push(VideoReplacementDataType.Update, data);
    }

    process(data: VideoReplacementDataProcess) {
        this.push(VideoReplacementDataType.Process, data, [data.buffer]);
    }

    stop() {
        this.push(VideoReplacementDataType.Stop, undefined);
    }

    push<T extends VideoReplacementDataType>(type: T, data: DataMap[T], transfer: Transferable[] = []) {
        this.worker.postMessage({type, data}, transfer);
    }

    on<T extends VideoReplacementDataType>(type: T, callback: (data: DataMap[T]) => void) {
        const callbacks = this.callbackMap[type] || [];
        callbacks.push(callback);
        this.callbackMap[type] = callbacks;
    }

    off<T extends VideoReplacementDataType>(type: T, callback: (data: DataMap[T]) => void) {
        const callbacks = this.callbackMap[type] || [];
        this.callbackMap[type] = callbacks.filter((c) => c !== callback);
    }

    terminate() {
        this.callbackMap = {};
    }

    private onMessage(e: MessageEvent<VideoReplacementData>) {
        const type = e.data.type;
        const data = e.data.data;
        const callbacks = this.callbackMap[type];

        if (!callbacks) {
            return;
        }

        for (const callback of callbacks) {
            callback(data);
        }
    }
}
