import loadable from "@workhorse/api/loadable";
import {RouteHOCProps, UnsubscribeRouteMatch} from "@workhorse/declarations";
import {Redirect} from "@workhorse/api/routing";

const Unsubscribe = loadable(() => import("./components/Unsubscribe"));

type UnsubscribeHocProps = RouteHOCProps<UnsubscribeRouteMatch>;

function UnsubscribeHOC(props: UnsubscribeHocProps) {
    if (!props.match?.params.token) {
        return <Redirect to="/" />;
    }

    return (
        <div className="route-hoc unsubscribe-hoc" key="unsubscribe-hoc">
            <Unsubscribe token={props.match?.params.token} />
        </div>
    );
}
export default UnsubscribeHOC;
