import {GoogleDocumentType} from "@artifacts/google-docs/frontend/utils";
import {ChangeGoogleFilePermissionDocument, CreateGoogleFileDocument, SessionLifecycle} from "@generated/data";
import {togglePalette} from "@ui/cdk/util";
import apollo from "@workhorse/api/apollo";
import designer from "@workhorse/api/designer";
import {addAgendaItemWithTool} from "@workhorse/api/designer/methods";
import {useCallback} from "@workhorse/api/rendering";
import {useAsyncDialog} from "@workhorse/providers/AsyncDialog";
import {DocumentSyncDialogOpen} from "@artifacts/google-docs/frontend/config/DocumentSyncDialog";
import {SimpleArtifactsTag} from "@generated/artifacts/simple-map";

const documentNames = {
    [GoogleDocumentType.DOCUMENT]: "document",
    [GoogleDocumentType.PRESENTATION]: "presentation",
    [GoogleDocumentType.SPREADSHEET]: "spreadsheet",
    [GoogleDocumentType.FORMS]: "form",
};

export function createGoogleDocumentArtifact(tool: SimpleArtifactsTag, type: GoogleDocumentType, documentId: string, togglePalette = true) {
    const shouldMockResult =
        !designer.state.getSnapshot().selectedAgendaItemId && designer.currentSession()?.lifecycle === SessionLifecycle.Started;

    return addAgendaItemWithTool(
        tool,
        async (artifactId) => {
            designer.api.artifact.updateArtifact({
                id: artifactId,
                artifact: {
                    isConfigured: true,
                    properties: [
                        {
                            key: "documentId",
                            value: documentId,
                        },
                        {
                            key: "documentType",
                            value: type,
                        },
                    ],
                },
                mockResult: shouldMockResult || undefined,
            });
        },
        {
            togglePalette,
        }
    );
}

export function useCreateGoogleDocument() {
    const {createDialog} = useAsyncDialog();

    const createGoogleDocument = useCallback(
        async (tool: SimpleArtifactsTag, type: GoogleDocumentType, isRetry = false, fileName?: string) => {
            if (!isRetry) {
                designer.state.update({
                    configInProgress: true,
                });

                togglePalette();
            }

            const nameToCreate = fileName ? (fileName.length > 30 ? fileName.substring(0, 27) + "..." : fileName) : "";
            const name = nameToCreate ? `${nameToCreate}` : "Sessions - Untitled " + documentNames[type];

            const result = await apollo.client.mutate({
                mutation: CreateGoogleFileDocument,
                variables: {
                    type,
                    name,
                },
            });
            const document = result.data?.createGoogleFile;

            if (!document) {
                if (isRetry) {
                    return;
                }

                const error = await createDialog<void>((resolve, reject, key) => {
                    return <DocumentSyncDialogOpen key={key} onCancel={() => reject("canceled")} onConfirm={() => resolve()} />;
                }).catch((err) => err);

                if (error) {
                    return;
                }

                return createGoogleDocument(tool, type, true);
            }

            await apollo.client.mutate({
                mutation: ChangeGoogleFilePermissionDocument,
                variables: {
                    fileId: document,
                    role: "reader",
                },
            });

            return createGoogleDocumentArtifact(tool, type, document, false);
        },
        [createDialog]
    );

    return createGoogleDocument;
}
