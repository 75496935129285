import {ReactElement} from "react";

export function TooltipButtonArrow() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M13.4324 9.17007H4.25741C3.79907 9.17007 3.42407 9.54507 3.42407 10.0034C3.42407 10.4617 3.79907 10.8367 4.25741 10.8367H13.4324V12.3284C13.4324 12.7034 13.8824 12.8867 14.1407 12.6201L16.4574 10.2951C16.6157 10.1284 16.6157 9.87007 16.4574 9.7034L14.1407 7.3784C13.8824 7.11174 13.4324 7.3034 13.4324 7.67007V9.17007Z"
            />
        </svg>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TooltipChildren = ReactElement<any, any>;

export interface WithTooltipChildren {
    children: TooltipChildren;
}

interface ChildrenParams {
    onClick: () => void;
}

export interface WithTooltipChildrenFunction {
    children: (params: ChildrenParams) => TooltipChildren;
}
