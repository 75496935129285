import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useUserInfo} from "@workhorse/providers/User";
import {useQuery} from "@apollo/client";
import {OrganizationPermissionType, PaddlePlansDocument, PaddleSubscriptionState} from "@generated/data";
import environment from "@generated/environment";
import {useClientEvent} from "@api/events/client";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";

declare global {
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    const profitwell: Function;
}

const PaddleProvider = ({children}) => {
    const isCsd = environment.authService.appRoot !== environment.authService.originalAppRoot;

    return (
        <>
            <PaddlePlansProvider />
            {isCsd ? <SubscriptionsCsdDialog /> : null}

            {children}
        </>
    );
};

const PaddlePlansProvider = () => {
    const [mounted, setMounted] = useState(false);

    const user = useUserInfo();
    const email = user.email;

    const profitWellStarter = useRef(false);

    useEffect(() => {
        const leInterval = setInterval(() => {
            if (profitwell && email && !profitWellStarter.current) {
                profitWellStarter.current = true;
                profitwell("start", {user_email: email});
                clearInterval(leInterval);
            }
        }, 1000);
        return () => {
            clearInterval(leInterval);
        };
    }, [email]);

    const isOwner = user.activeOrganizationPermission.permissionType === OrganizationPermissionType.Owner;
    const isTrial = user.activeOrganizationPermission.organization?.paddleSubscriptionState === PaddleSubscriptionState.Trialing;
    const hasSubscriptionState = user.activeOrganizationPermission.organization?.paddleSubscriptionState;

    useEffect(() => {
        setMounted(true);
    }, []);

    return hasSubscriptionState && mounted && isOwner ? (
        <>
            <PaddlePlansFetcher isTrial={isTrial} />
        </>
    ) : null;
};

const PaddlePlansFetcher = ({isTrial}: {isTrial: boolean}) => {
    const mountedRef = useRef(false);
    const {data, refetch} = useQuery(PaddlePlansDocument, {
        fetchPolicy: "network-only",
        skip: !mountedRef.current,
    });

    useEffect(() => {
        if (mountedRef.current) {
            refetch();
        }
    }, [isTrial, refetch]);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return <></>;
};

const SubscriptionsCsdDialog = () => {
    const [open, setOpen] = useState(false);

    useClientEvent("subscriptions-unavailable-csd", () => {
        setOpen(true);
    });

    const onClose = () => {
        setOpen(false);
    };

    const onConfirm = () => {
        window.open(environment.authService.originalAppRoot + "/user/organization/subscriptions", "_blank");
        onClose();
    };

    return (
        <ConfirmationDialog
            key="subscriptions-unavailable-csd-dialog"
            title={`Open Sessions in new tab to manage subscription`}
            content={`You can't manage your subscription from your custom domain, please open sessions.us in a new tab.`}
            cancelButton={"Cancel"}
            minWidth
            // Icon={Trash}
            submitButton={"Open Sessions in new tab"}
            onClose={onClose}
            open={open}
            cancelButtonVariant="quaternary"
            submitButtonVariant="primary"
            onConfirm={onConfirm}
            variant="warning"
        />
    );
};

export default PaddleProvider;
