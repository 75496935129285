import {publicSystemEnv} from "@generated/environment/system";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@ui/cdk/Button";
import Link from "@ui/cdk/Link";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import Backdrop from "@ui/core/components/Backdrop";
import IconButton from "@ui/core/components/IconButton";
import {makeVar, useReactiveVar} from "@workhorse/api/data";
import {cloneElement, createElement, useEffect, useRef} from "@workhorse/api/rendering";
import {
    evalHostBasedSchema,
    HighlightItemConditions,
    infoBackdropIsOpen,
    markAsSeen,
    SeenSegment,
    UpdateSeenArg,
    useSeenSection,
    seenSegmentToObj,
} from "@workhorse/api/seen";
import {ExtractProps} from "@workhorse/declarations";
import GenericVideoPlayer from "@workhorse/pages/player/components/GenericVideoPlayer";
import {cls} from "@ui/cdk/util/util";
import {ReactComponent as ExclamationMark} from "../..//assets/media/exclamation-mark.svg";
import {useInfoTooltipStyles} from "./infoTooltipClasses";
import {useMobile} from "@workhorse/providers/MobileProvider";

type InfoBackdropProps = {
    children: JSX.Element;
    hostBasedSchema?: SeenSegment;
    placement?: ExtractProps<typeof Tooltip>["placement"];
    borderStyle?: "blue" | "none";
    useExclamationMark?: boolean;
    hasCloseHandler?: boolean;
    withBackDrop?: boolean;
    mobileTooltipTitle?: string;
    withShadow?: boolean;

    withTransparentBackDrop?: boolean;
    onToggle?: (state?: boolean) => void;
    arrow?: boolean;
    innerWidth?: number;

    hasInfoIcon?: boolean;
    hasGotItButton?: boolean;
    learnMoreUrl?: string;

    articleUrl?: string;
    canJoinCommunity?: boolean;
    videoUrl?: string;

    classes?: {
        tooltip?: string;
        arrow?: string;
        tooltipInner?: string;
        tooltipTextWrapper?: string;
        tooltipFooter?: string;
        closeHandler?: string;
        tooltipHeader?: string;
        tooltipTitle?: string;
        messageBody?: string;
        exclamation?: string;
    };

    offset?: string;

    disableBackdrop?: boolean;
};

export const forceShowInfoTooltip = makeVar<SeenSegment | null>(null);

function InfoBackdrop(props: InfoBackdropProps) {
    const {hostBasedSchema, onToggle} = props;
    const alreadySeen = useSeenSection(hostBasedSchema!);
    const alreadyOpen = useReactiveVar(infoBackdropIsOpen);
    const forcedSchema = useReactiveVar(forceShowInfoTooltip);
    const forceShowCondition = hostBasedSchema && forcedSchema === hostBasedSchema;

    const shouldShow = forceShowCondition;
    const prevShow = useRef(shouldShow);

    useEffect(() => {
        if (!onToggle) {
            return;
        }
        if (shouldShow !== prevShow.current) {
            prevShow.current = shouldShow;
            onToggle(shouldShow);
        }
        return () => {
            if (onToggle) {
                onToggle(false);
            }
        };
    }, [shouldShow]);

    return <>{shouldShow ? createElement(WithInfoBackdrop, props) : props.children}</>;
}

function generateOffset(placement: InfoBackdropProps["placement"], defaultOffset?: string) {
    let offset = {
        enabled: true,
        offset: "0px, 0px",
    };
    if (defaultOffset) {
        offset.offset = defaultOffset;
        return offset;
    }
    // TODO: Add more cases when needed
    switch (placement) {
        case "top":
            offset.offset = "0px, 30px";
            break;
        case "left":
            offset.offset = "0px, 20px";
            break;
        case "left-start":
            offset.offset = "0px, 20px";
            break;
        case "bottom":
            offset.offset = "0px, 20px";
            break;
        case "right":
            offset.offset = "0px, 20px";
            break;
        default:
            break;
    }
    return offset;
}

function WithInfoBackdrop(props: InfoBackdropProps) {
    const classes = useInfoTooltipStyles();
    const {
        placement: placementProp = "top",
        children,
        borderStyle = "none",
        useExclamationMark = false,
        arrow = true,
        hasCloseHandler = true,
        hasInfoIcon = false,
        hasGotItButton = false,
        hostBasedSchema,
        withBackDrop = true,
        withTransparentBackDrop: withTransparentBackDropProp = false,
        classes: customClasses,
        innerWidth,
        onToggle,
        disableBackdrop = false,
        withShadow = false,
        mobileTooltipTitle,
    } = props;

    const {isMobile} = useMobile();

    const schema: Partial<HighlightItemConditions<any>> = hostBasedSchema ? evalHostBasedSchema(hostBasedSchema) : {};

    const placement = schema.placement || placementProp;
    const withBackdrop = schema.withBackDrop || withBackDrop;
    const hasShadow = schema.withShadow || withShadow;
    const withTransparentBackDrop = schema.withTransparentBackDrop || withTransparentBackDropProp;

    const videoUrl = schema.videoUrl || props.videoUrl;
    const articleUrl = schema.articleUrl || props.articleUrl;
    const learnMoreUrl = schema.learnMoreUrl || props.learnMoreUrl;
    const canJoinCommunity = schema.canJoinCommunity || props.canJoinCommunity;

    const {onClick: childOnClick, className: childClassName} = children.props;

    const className = cls(childClassName, classes.child, borderStyle !== "none" && classes[borderStyle + "Highlighted"], "highlighted");

    const onClick = () => {
        if (schema?.closeOn?.indexOf("click") !== -1) {
            closeTooltip();
        }
        if (childOnClick) {
            childOnClick();
        }
    };

    const closeTooltip = () => {
        if (forceShowInfoTooltip() != null) {
            forceShowInfoTooltip(null);
        }
        if (schema?.onCloseMarkAsSeen) {
            const segments = schema?.onCloseMarkAsSeen.reduce((all, segment) => {
                const obj = seenSegmentToObj(segment, true);
                Object.keys(obj).forEach((k) => {
                    if (!(k in all)) {
                        all[k] = obj;
                    } else {
                        all[k] = {
                            ...all[k],
                            ...obj,
                        };
                    }
                });
                return all;
            }, {} as UpdateSeenArg);
            markAsSeen(segments, schema?.markAsSeenOnlyLocally ? true : false);
            // schema?.onCloseMarkAsSeen.forEach((segment) => {
            //     /**
            //      * if schema.markAsSeenOnlyLocally === true, the change will not be persisted on the BE
            //      * third param is false by default
            //      */
            //     markAsSeen(segment, true, schema.markAsSeenOnlyLocally ? true : false);
            // });
        }
    };

    const onBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (schema?.closeOn?.indexOf("clickOutside") !== -1) {
            closeTooltip();
        }
    };

    const onClose = () => {
        if (schema?.closeOn?.indexOf("close") !== -1) {
            closeTooltip();
        }
    };

    useEffect(() => {
        if (schema?.timeout) {
            const closeTimeout = setTimeout(() => {
                closeTooltip();
            }, schema.timeout);
            return () => {
                clearTimeout(closeTimeout);
            };
        }
    }, []);

    const tooltipMessage = (
        <div className={cls("flex", classes.tooltipInner, customClasses?.tooltipInner)}>
            {useExclamationMark ? (
                <span className={cls("flex00-auto exclamation", customClasses?.exclamation)}>
                    <ExclamationMark />
                </span>
            ) : null}
            <div
                style={innerWidth ? {width: innerWidth} : {}}
                className={cls(
                    "flex flex-wrap flex11-auto",
                    hasCloseHandler && classes.tooltipTextWrapper,
                    customClasses?.tooltipTextWrapper
                )}
            >
                <>
                    {typeof schema?.message !== "object" && schema?.message ? schema.message : null}
                    {typeof schema?.message === "object" && (schema.message || {}).title ? (
                        <div className={cls(classes.tooltipHeader, customClasses?.tooltipHeader)}>
                            {hasInfoIcon ? <InfoOutlinedIcon /> : null}
                            <Typography
                                variant="sm"
                                fontWeight="normal"
                                component="span"
                                className={cls("flex11-100", classes.tooltipTitle, customClasses?.tooltipTitle)}
                            >
                                {isMobile && mobileTooltipTitle ? mobileTooltipTitle : schema.message.title}
                            </Typography>
                        </div>
                    ) : null}
                    {videoUrl ? (
                        <GenericVideoPlayer
                            enableFullscreen={schema.enableFullscreen || false}
                            src={videoUrl}
                            enablePlayButton
                            controls={true}
                            id="video-tutorial-tooltip"
                        />
                    ) : null}
                    {typeof schema?.message === "object" && (schema.message || {}).body ? (
                        <Typography component="span" className={cls("flex11-100", classes.messageBody, customClasses?.messageBody)}>
                            {schema.message.body}
                        </Typography>
                    ) : null}
                    {learnMoreUrl || articleUrl || hasGotItButton || canJoinCommunity ? (
                        <div className={cls(classes.tooltipFooter, customClasses?.tooltipFooter)}>
                            {learnMoreUrl ? (
                                <Link href={learnMoreUrl} target="_blank" color="inherit" underline="none">
                                    <Typography className={classes.learnMore}>Learn more</Typography>
                                </Link>
                            ) : null}
                            {articleUrl ? (
                                <Link href={articleUrl} target="_blank" color="inherit" underline="none">
                                    <Typography className={classes.articleUrl}>Full article read</Typography>
                                </Link>
                            ) : null}
                            {hasGotItButton ? (
                                <Button size="small" className={classes.gotItBtn} onClick={onClose}>
                                    Got it
                                </Button>
                            ) : null}
                            {canJoinCommunity ? (
                                <Button
                                    // @ts-ignore
                                    component={Link}
                                    href={publicSystemEnv.slackWorkspace}
                                    className={classes.communityBtn}
                                    target={"_blank"}
                                    key="community-link"
                                    underline="none"
                                    variant="primary"
                                >
                                    Community
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                </>
            </div>
            {hasCloseHandler ? (
                <IconButton className={cls(classes.closeTooltip, customClasses?.closeHandler)} onClick={onClose}>
                    <CloseIcon className={classes.closeTooltipIcon} />
                </IconButton>
            ) : null}
        </div>
    );

    return (
        <>
            {disableBackdrop ? null : (
                <Backdrop open={withBackdrop} className={classes.backdrop} invisible={withTransparentBackDrop} onClick={onBackdropClick} />
            )}
            <Tooltip
                open={true}
                title={tooltipMessage}
                placement={placement}
                arrow={arrow}
                classes={{
                    tooltip: cls(
                        "flex",
                        classes.infoTooltip,
                        innerWidth && classes.unsetMaxWidth,
                        customClasses?.tooltip,
                        hasShadow && classes.withShadow
                    ),
                    arrow: cls(classes.tooltipArrow, customClasses?.arrow),
                    popper: classes.muiPopper,
                }}
                PopperProps={{
                    popperOptions: {
                        modifiers: {
                            offset: {
                                ...generateOffset(placement, props.offset),
                            },
                        },
                    },
                }}
            >
                {cloneElement(props.children, {
                    onClick,
                    className,
                })}
            </Tooltip>
        </>
    );
}

export default InfoBackdrop;
