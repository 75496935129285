import {CombinedDesignerAction} from "../../action";
import {CopyArtifactToOneItem} from "./copy-artifact-to-one-item";

export type CopyArtifactToMultipleItemsInput = {
    ids: string[];
};

export class CopyArtifactToMultipleItems extends CombinedDesignerAction<CopyArtifactToMultipleItemsInput> {
    constructor(input: CopyArtifactToMultipleItemsInput) {
        super(
            input.ids
                .map((element) => {
                    return [() => new CopyArtifactToOneItem({sourceId: input.ids[0], targetId: element})];
                })
                .reduce((acc, cur) => acc.concat(cur), []),
            () => this.commitResults[0]
        );
    }
}
