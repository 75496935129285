import {makeVar} from "@apollo/client";
import {
    BrandingCreateInput,
    BrandingInfoFragment,
    BrandingUpdateInput,
    CreateOneBrandingDocument,
    UpdateOneBrandingDocument,
    UploadBrandingAssetDocument,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import toast from "@workhorse/api/toast";
import {readRemoteUser, updateCachedRemoteUser} from "@workhorse/api/user";
import imageCompression from "browser-image-compression";
import {HSLColor, RGBColor} from "react-color";
import pSBC from "shade-blend-color";
import {CustomPalette} from "./types";

const compressionOptions = {
    maxSizeMB: 0.7, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1080, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
};

export const brandingLogo = makeVar<string | null>(null);
export const sessionBackground = makeVar<string | null>(null);
export const brandingLogomark = makeVar<string | null>(null);
export const brandingNavbarTheme = makeVar<string | null>(null);
export const brandingVideoTileColor = makeVar<string | null>(null);

export const setBranding = (
    branding?: Pick<BrandingInfoFragment, "styleTag" | "logo" | "sessionBackground" | "logomark" | "navbarTheme" | "videoTileColor">
) => {
    if (branding?.styleTag) {
        const styleTag = document.createElement("style");
        styleTag.className = "custom-theme";
        styleTag.innerHTML = branding?.styleTag;
        const existingBranding = document.querySelector("head style.custom-theme");
        existingBranding?.remove();
        document.head.append(styleTag);
    } else {
        const existingBranding = document.querySelector("head style.custom-theme");
        existingBranding?.remove();
    }

    brandingLogo(branding?.logo ?? null);

    sessionBackground(branding?.sessionBackground ?? null);

    brandingLogomark(branding?.logomark ?? null);

    brandingNavbarTheme(branding?.navbarTheme ?? null);

    brandingVideoTileColor(branding?.videoTileColor ?? null);
};

export const removeBranding = () => {
    const existingBranding = document.querySelector("head style.custom-theme");
    brandingLogo(null);
    sessionBackground(null);
    brandingLogomark(null);
    brandingNavbarTheme(null);
    brandingVideoTileColor(null);
    existingBranding?.remove();
};

const darkShade: RGBColor = {
    r: 15,
    g: 23,
    b: 42,
};

const darkShadeSecondary: RGBColor = {
    r: 51,
    g: 65,
    b: 85,
};

const darkShadeTertiary: RGBColor = {
    r: 29,
    g: 29,
    b: 29,
};

const lightShade: RGBColor = {
    r: 255,
    g: 255,
    b: 255,
};

const toHex = (c: number) => {
    return c.toString(16).padStart(2, "0").toUpperCase();
};

export type baseColors = {
    primary: RGBColor;
};

export const defaultPalette: baseColors = {
    primary: {
        r: 31,
        g: 138,
        b: 244,
    },
};

export const generateColorPalette = (baseColors: baseColors) => {
    const colors: Partial<CustomPalette> = {};
    Object.keys(baseColors).forEach((color, index) => {
        const value = baseColors[color];
        const baseRgb = getRGBColor(value);

        colors[color] = {
            10: rgbaWithAlpha(baseRgb, 0.15),
            50: pSBC(0.9, baseRgb, getRGBColor(lightShade)),
            100: pSBC(0.8, baseRgb, getRGBColor(lightShade)),
            200: pSBC(0.6, baseRgb, getRGBColor(lightShade)),
            300: pSBC(0.4, baseRgb, getRGBColor(lightShade)),
            400: pSBC(0.15, baseRgb, getRGBColor(lightShade)),
            500: baseRgb,
            600: pSBC(0.045, baseRgb, getRGBColor(darkShade)),
        };
    });

    colors["dark"] = {
        100: pSBC(0.05, getRGBColor(baseColors.primary), getRGBColor(darkShadeTertiary)),
        200: pSBC(0.25, getRGBColor(baseColors.primary), getRGBColor(darkShadeTertiary)),
        300: pSBC(0.45, getRGBColor(baseColors.primary), getRGBColor(darkShadeTertiary)),
        400: pSBC(0.65, getRGBColor(baseColors.primary), getRGBColor(darkShadeTertiary)),
        500: pSBC(0.8, getRGBColor(baseColors.primary), getRGBColor(darkShadeTertiary)),
        600: pSBC(0.9, getRGBColor(baseColors.primary), getRGBColor(darkShadeTertiary)),
    };

    const darkRgba = colors.dark[600].replace("rgb", "rgba").replace(")", ", 0.65)");

    colors["bg"] = {
        primary: pSBC(0.95, getRGBColor(baseColors.primary), getRGBColor(lightShade)),
        secondary: pSBC(0.9, getRGBColor(baseColors.primary), getRGBColor(darkShadeSecondary)),
        tertiary: `linear-gradient(0deg, ${darkRgba}, ${darkRgba})`,
    };

    return colors as CustomPalette;
};

export const getRGBColor = (color: RGBColor) => {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
};

export const rgbaWithAlpha = (color: RGBColor | string, alpha: number) => {
    if (typeof color === "string") {
        return color.replace("rgb", "rgba").replace(")", `, ${alpha})`);
    }
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

export const extractRGBObject = (color: string): RGBColor => {
    const rgb = color.replace("rgb(", "").replace(")", "").split(",");
    return {
        r: +rgb[0],
        g: +rgb[1],
        b: +rgb[2],
    };
};

export const hexToRgb = (hex: string): RGBColor | null => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: Math.round(parseInt(result[1], 16)),
              g: Math.round(parseInt(result[2], 16)),
              b: Math.round(parseInt(result[3], 16)),
          }
        : null;
};

export const rgbToHex = (color: RGBColor) => {
    return `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}`;
};

export const generateVars = (palette: CustomPalette, doNotGenerateVars?: boolean) => {
    if (doNotGenerateVars) {
        return {};
    }
    const prefix = "--sessions";
    const vars = {};

    Object.keys(palette)
        .filter((k) => ["primary", "dark"].includes(k))
        .forEach((key) => {
            Object.keys(palette[key]).forEach((shadeKey) => {
                vars[`${prefix}-${key}-${shadeKey}`] = palette[key][shadeKey];
            });
        });

    vars["--sessions-button-blue-gradient-darker"] = palette.dark[300];

    vars["--sessions-theme-bg-blur"] = palette.bg.tertiary;
    vars["--sessions-video-play-button"] = palette.primary[500];

    return vars as {[color: string]: string};
};

export const generateStyleWithVars = (colors: {[color: string]: string}) => {
    const allColorsStr = Object.keys(colors).reduce((acc, key) => {
        acc = acc + `${key}: ${colors[key]};`;
        return acc;
    }, "");

    const tag = ":root {" + allColorsStr + " }";

    return tag;
};

export const compareColors = (color1: RGBColor, color2: RGBColor) => {
    if (!color1.a && !color2.a) {
        return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b;
    } else if (color1.a && !color2.a) {
        return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a === 1;
    } else if (!color1.a && color2.a) {
        return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color2.a === 1;
    } else {
        return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a === color2.a;
    }
};

export const compareBasePalettes = (defaultPalette: baseColors, customPalette: baseColors) => {
    const comparing = ["r", "g", "b"];
    for (const color of comparing) {
        if (defaultPalette.primary[color] !== customPalette.primary[color]) {
            return false;
        }
    }
    return true;
};

export const getBrandingDetails = (branding?: BrandingInfoFragment, userPalette?: baseColors) => {
    const basePalette = branding?.sourcePalette ?? userPalette ?? defaultPalette;
    const newPalette = generateColorPalette(basePalette);
    const anythingChanged = compareBasePalettes(defaultPalette, basePalette);
    const newVars = generateVars(newPalette, anythingChanged);
    const styleTagContent = generateStyleWithVars(newVars);

    return {basePalette, newPalette, styleTagContent};
};

export const rgbTohsl = (rgb: RGBColor): HSLColor => {
    const {r, g, b} = rgb;
    const v = Math.max(r, g, b),
        c = v - Math.min(r, g, b),
        f = 1 - Math.abs(v + v - c - 1);

    const h = c && (v == r ? (g - b) / c : v == g ? 2 + (b - r) / c : 4 + (r - g) / c);

    return {
        h: 60 * (h < 0 ? h + 6 : h),
        s: f ? c / f : 0,
        l: (v + v - c) / 2,
    };
};

export const hslTorgb = (hsl: HSLColor): RGBColor => {
    const {h, s, l} = hsl;
    const a = s * Math.min(l, 1 - l);

    const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    return {
        r: f(0),
        g: f(8),
        b: f(4),
    };
};

export const uploadAsset = async (file: File, compress?: boolean) => {
    try {
        const maximumUploadSize = 5;
        const sizeMb = parseFloat((file.size / (1024 * 1024)).toFixed(2));
        const acceptedTypes = ["image/jpeg", "image/jpg", "image/png"];
        console.log(file);
        if (!acceptedTypes.includes(file.type)) {
            toast("Please upload one of the following formats: jpg, png, jpeg", {
                type: "error",
            });
            return;
        }

        if (sizeMb > maximumUploadSize) {
            toast("File can't exceed 5mb", {
                type: "error",
            });
            return;
        }

        const maybeCompressedFile =
            compress && ["image/jpg", "image/jpeg", "image/png"].includes(file.type)
                ? await imageCompression(file, compressionOptions)
                : file;

        const cacheBuster = Date.now().toString();
        const uploadUrlRes = await apollo.client.mutate({mutation: UploadBrandingAssetDocument, variables: {cacheBuster}});
        const {uploadUrl, publicUrl} = uploadUrlRes.data?.uploadBrandingAsset ?? {};

        if (!uploadUrl || !publicUrl) {
            throw new Error("No data returned from server.");
        }

        const fetchResponse = await fetch(uploadUrl, {
            method: "PUT",
            body: maybeCompressedFile,
            headers: {
                "Content-Type": maybeCompressedFile.type ? maybeCompressedFile.type : "application/octet-stream",
            },
        });

        if (fetchResponse.ok) {
            return publicUrl;
        } else {
            throw new Error("Fetch response is not 200.");
        }
    } catch (err) {
        console.error(`Error occurred when uploading profile picture: ${err.message}`);
        return toast(`Error occurred when uploading profile picture`, {type: "error"});
    }
};

export const createBranding = async (data: BrandingCreateInput) => {
    const createdBranding = await apollo.client.mutate({
        mutation: CreateOneBrandingDocument,
        variables: {
            data,
        },
    });

    if (createdBranding.errors?.length || !createdBranding.data?.createOneBranding?.id) {
        return null;
    }

    const remoteUserData = readRemoteUser();

    if (!remoteUserData?.getRemoteUser?.user) {
        return null;
    }

    updateCachedRemoteUser({
        user: {
            workspacePermissions: remoteUserData?.getRemoteUser.user.workspacePermissions.map((workspacePermission) => {
                return {
                    ...workspacePermission,
                    branding: {
                        ...createdBranding?.data?.createOneBranding,
                        __typename: "Branding",
                    },
                };
            }),
        },
    });

    return createdBranding.data.createOneBranding;
};

export const updateBranding = async (data: BrandingUpdateInput) => {
    const updatedBranding = await apollo.client.mutate({
        mutation: UpdateOneBrandingDocument,
        variables: {
            data,
        },
    });

    if (updatedBranding.errors?.length || !updatedBranding.data?.updateOneBranding?.id) {
        return null;
    }

    return updatedBranding.data.updateOneBranding;
};
