import {forwardRef} from "@workhorse/api/rendering";
import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useTranslation} from "react-i18next";
import {ReactComponent as TogglerTimer} from "../../../../assets/media/macro-toggler-timer.svg";
import classes from "./styles/TimerToggler.module.scss";

type TimerTogglerProps = {
    artifactId: string;
    isLobby: boolean;
    onToggle: () => void;
    isActive: boolean;
    inDrawer: boolean;
};

const TimerToggler = forwardRef((props: TimerTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {artifactId, isLobby, onToggle, isActive, inDrawer} = props;

    const handleClick = () => {
        onToggle();
    };

    return (
        <ArtifactActionsButton
            badgeContent={0}
            onClick={handleClick}
            isActive={isActive}
            MacroIcon={
                <div className="flex flex-items-center">
                    <TogglerTimer />
                </div>
            }
            buttonDataId="player-button-timer"
            artifactId={artifactId}
            isLobby={isLobby}
            inDrawer={inDrawer}
            className={classes.btn}
            ref={ref}
            label={t("macro.timer.title") ?? "Timer"}
            ariaLabel={t("macro.timer.title") ?? "Timer"}
        />
    );
});

export default TimerToggler;
