import Box, {BoxProps} from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiSwitch, {SwitchProps} from "@material-ui/core/Switch";
import React, {forwardRef} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import Typography from "@ui/cdk/Typography";
import classes from "./style/Switch.module.scss";

type CustomSwitchProps<
    T = {
        label?: string;
        variant?: string;
        boxProps?: BoxProps;
        description?: string | React.ReactNode;
        color?: "primary" | "secondary" | "default";
    }
> = Omit<SwitchProps, keyof T> & T;

function SwitchWithRef(props: CustomSwitchProps, ref?: any) {
    const {description, label, variant, boxProps, color = "primary", ...other} = props;

    return (
        <Box {...boxProps} className={cls("form-control", boxProps?.className)}>
            {description ? (
                <Typography variant="base" color="blueGray400">
                    {description}
                </Typography>
            ) : null}
            <FormControlLabel
                control={
                    <MuiSwitch
                        color={color}
                        {...other}
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            root: cls(
                                classes.root,
                                color === "primary" && classes.primary,
                                color === "secondary" && classes.secondary,
                                color === "default" && classes.default
                            ),
                            switchBase: classes.switchBase,
                            checked: "checked",
                        }}
                        ref={ref}
                        inputProps={{value: props?.checked as unknown as string}}
                    />
                }
                label={label}
                classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                }}
                // value={props?.checked}
            />
        </Box>
    );
}

const Switch = forwardRef(SwitchWithRef) as (props: CustomSwitchProps & {ref?: any}) => ReturnType<typeof SwitchWithRef>;
export default Switch;
