import designer from "@workhorse/api/designer";
import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {agendaTemplateUpdater} from "./AgendaTemplateUpdater";

type WatchdogProps = {
    agendaTemplateId: string;
};

export type WatchdogAgendaTemplatePayload = SubscriptionPayload<"WatchdogAgendaTemplateDocument">;

function AgendaTemplateWatchdog(props: WatchdogProps) {
    const processData = async (data: WatchdogAgendaTemplatePayload) => {
        // console.log("AT:WATCHDOG", data.watchdogAgendaTemplate);

        const agendaTemplateid = data.watchdogAgendaTemplate.id.replace("agenda-template-watchdog-", "");

        // The order is: upsertAgendaItems, deleteAgendaItems, update session - if this order is changed, bad things will happen.
        if (data.watchdogAgendaTemplate.upsertAgendaItems) {
            // console.log("AT:WATCHDOG: upsertAgendaItems", data.watchdogAgendaTemplate.upsertAgendaItems);
            agendaTemplateUpdater.onSubscriptionUpsertAgendaItems(
                agendaTemplateid + "_template",
                data.watchdogAgendaTemplate.upsertAgendaItems
            );
        }

        if (data.watchdogAgendaTemplate.deletedAgendaItemIds) {
            // console.log("AT:WATCHDOG: deletedAgendaItemIds", data.watchdogAgendaTemplate.deletedAgendaItemIds);
            agendaTemplateUpdater.onSubscriptionDeleteAgendaItems(data.watchdogAgendaTemplate.deletedAgendaItemIds);
        }

        if (data.watchdogAgendaTemplate.agendaTemplate) {
            // console.log("AT:WATCHDOG: agendaTemplate", data.watchdogAgendaTemplate.agendaTemplate);
            agendaTemplateUpdater.templateUpdates(data.watchdogAgendaTemplate.agendaTemplate);
        }

        if (data.watchdogAgendaTemplate.deletedAgendaTemplate) {
            // console.log("AT:WATCHDOG: deletedAgendaTemplate", data.watchdogAgendaTemplate.deletedAgendaTemplate);
            // agendaTemplateUpdater.deleteTemplate(data.watchdogAgendaTemplate.deletedAgendaTemplate + "_template");
            // if (!window.top) {
            //     return;
            // }
            // const existingSession = readSession(sessionId);
            // // console.log("got deleted session");
            // // Recurrence instance was deleted, redirect to parent
            // // console.log("existing recurrence id", existingSession?.instanceOfRecurrence?.session.id);
            // if (existingSession?.instanceOfRecurrence?.session.id) {
            //     window.location.href = "/session/" + existingSession?.instanceOfRecurrence?.session.id;
            //     return;
            // }
            // if (existingSession?.childOfBreakoutRooms) {
            //     // Redirect to BOR parent session
            //     const childOfBreakoutRooms = existingSession.childOfBreakoutRooms;
            //     window.location.href = "/session/" + childOfBreakoutRooms.parentOfBreakoutRooms.sessionId;
            //     return;
            // }
            // window.top.location.href = "/";
            // return;
        }
        setTimeout(() => {
            designer.state.setDesignerCommitState(false);
        }, 500);
    };
    // NOTE
    // The GenericSubscriber is used as a wrapper here
    // to avoid adding a React.Fragment wrapper
    // but for cases where a wrapper/parent container already exists
    // it can be a simple child, alongside other children
    return (
        <>
            <GenericSubscriber
                document="WatchdogAgendaTemplateDocument"
                onData={processData}
                useCallbackQueue={true}
                options={{
                    variables: {
                        agendaTemplateId: props.agendaTemplateId,
                    },
                    shouldResubscribe: true,
                    fetchPolicy: "no-cache",
                }}
                waitForQuery={{
                    query: "GetAgendaTemplateForEditingDocument",
                    variables: {
                        id: props.agendaTemplateId,
                    },
                }}
                key="agendaTemplateWatchdog"
            />
        </>
    );
}
export default AgendaTemplateWatchdog;
