import loadable from "@workhorse/api/loadable";
import {ResourcesRouteMatch, RouteHOCProps} from "@workhorse/declarations";

const ResxEntryPoint = loadable(() => import("./components/ResxEntryPoint"));

type ResxHOCProps = RouteHOCProps<ResourcesRouteMatch>;

function ResxHOC(props: ResxHOCProps): JSX.Element {
    return (
        <div className="route-hoc flex-align-start resx-hoc" key="resx-hoc">
            <ResxEntryPoint key="resx-entry-point" />
        </div>
    );
}

export default ResxHOC;
