import Typography from "@ui/cdk/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {ActionCategory, categMap} from "../command-palette/actionCategTree";
import {PaletteActiveCateg} from "../command-palette/CommandPaletteProviders";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import classes from "./onboarding.module.scss";
import {useDeferredValue} from "./utils";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {cls} from "@ui/cdk/util";

type OnboardingToolMenuProps = WithTooltipChildren & {
    activeCateg: PaletteActiveCateg;
    shouldSkip?: boolean;
    placement?: "top" | "bottom" | "left" | "right";
};

export function OnboardingPaletteToolsActionList(props: OnboardingToolMenuProps) {
    const {tooltip} = useOnboardingSession();
    const {isTablet} = useMobile();

    const toolCateg = categMap.find((obj) => obj.parentId === undefined && obj.categ === ActionCategory.Tool);

    let title = (
        <>
            <div className="flex flex-align-center">
                <InfoOutlinedIcon className="mr-6" />
                <Typography className="title" fontWeight="bolder">
                    Try one of our native tools
                </Typography>
            </div>
            <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                Let’s add an interactive whiteboard.
            </Typography>
        </>
    );

    const skip = toolCateg ? props.activeCateg.id !== toolCateg.id : true;
    const deferredSkip = useDeferredValue(tooltip, skip, true, 1000);

    return (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement={!isTablet ? "right-start" : "top-end"}
            classes={{
                popper: isTablet ? classes.popperTablet : "",
                tooltip: cls(isTablet ? classes.tooltipTablet : ""),
            }}
            arrow={true}
            skip={deferredSkip}
            isPaletteTooltip={true}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
