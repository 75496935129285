import {makeStyles} from "@material-ui/core";
import Button from "@ui/cdk/Button";
import React from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: "14px 30px",
        justifyContent: "flex-end",
    },
}));

interface ModalFooterProps {
    closeDialog: () => void;
    handleEndSession: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    shouldStartEvent?: boolean;
}

const ModalFooter = (props: ModalFooterProps) => {
    const classes = useStyles();

    const {closeDialog, handleEndSession, className, shouldStartEvent} = props;

    return (
        <div className={cls("flex", classes.footer, className)}>
            <Button type="button" variant="quaternary" onClick={closeDialog}>
                Cancel
            </Button>
            <Button data-id="leave-end-session" type="button" variant="destructive-secondary" withMarginLeft onClick={handleEndSession}>
                {shouldStartEvent ? "Start" : "Leave"} event
            </Button>
        </div>
    );
};

export default ModalFooter;
