import descriptor, {ArtifactTag, default as ArtifactsMap} from "@generated/artifacts";
import {AgendaItemType, ChooseToolStep, DrawerState, MyProductToolDocument} from "@generated/data";
import {Popover} from "@material-ui/core";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls, togglePalette} from "@ui/cdk/util/util";
import IconButton from "@ui/core/components/IconButton";
import MenuItem from "@ui/core/components/MenuItem";
import Slide from "@ui/core/components/Slide";
import {useQuery, useReactiveVar} from "@workhorse/api/data";
import designer from "@workhorse/api/designer";
import {getMaxSessionOrder} from "@workhorse/api/designer/lib/utils";
import {forwardRef, useCallback, useRef, useState} from "@workhorse/api/rendering";
import {useResourceResult} from "@workhorse/api/resx/hooks";
import {ActionCategory, categMap} from "@workhorse/components/command-palette/actionCategTree";
import {paletteActiveCategDefault, usePaletteActiveCateg} from "@workhorse/components/command-palette/CommandPaletteProviders";
import {OnboardingShareButton} from "@workhorse/components/onboarding-tooltips/onboarding-share-button";
import {SessionData} from "@workhorse/declarations/dataTypes";
import DesignerToolPlaceholder from "@workhorse/pages/designer/ToolConfiguration/DesignerToolPlaceholder";
import controlsClasses from "@workhorse/pages/player/components/LocalParticipantControls/styles/LocalParticipantControls.module.scss";
import {brandingLogo} from "@workhorse/pages/user/profile/Theming/utils";
import {
    useDesignerIsEditing,
    useDesignerSessionCommitState,
    useDesignerSessionState,
} from "@workhorse/providers/DesignerSessionDataProviders";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {SessionOnboardingType, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import SessionDataProviders, {useAgendaItems, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useDrawerLeftToggler, useFullScreenState} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import {getArtifactImage, isMacOS} from "@workhorse/util";
import {useTranslation} from "react-i18next";
import {ReactComponent as AgendaToggleIcon} from "../../../../assets/media/agendaToggleIcon.svg";
import myProductDefaultIcon from "../../../../assets/media/icon-my-product-no-bg.svg";
import {ReactComponent as ShareIcon} from "../../../../assets/media/share_icon.svg";
import classes from "./style/AddToolsInPlayer.module.scss";
import ProcessingUiHandler from "./ToolMenu/components/ProcessingUiHandler";
import toolMenuClasses from "./ToolMenu/components/styles/ToolMenu.module.scss";

const SlideTransition = (props) => <Slide {...props} direction="up" />;

type AddToolsInPlayerButtonProps = {
    session: SessionData;
    hasMicroController: boolean;
    isAssistant: boolean;
    isAdmin: boolean;
    onClick?: () => void;
    className?: string;
    hideText?: boolean;
    isAddingAgenda?: boolean;
};

export const filesType: ArtifactTag[] = [
    "flowos/form",
    "flowos/gallery",
    "flowos/google-docs",
    "flowos/google-forms",
    "flowos/google-sheets",
    "flowos/google-slides",
    "flowos/pdf",
    "flowos/ppt",
    "flowos/doc",
    "flowos/excel",
    "flowos/slideshow",
    "flowos/video",
    "flowos/vimeo",
    "flowos/youtube",
];

function AddToolsInPlayerButton(props: AddToolsInPlayerButtonProps, ref: any) {
    const {t} = useTranslation();
    // probably prevent onClick in preview but show the button
    const {session, hasMicroController, isAssistant, onClick, className, hideText, isAddingAgenda} = props;
    const customBrandingLogo = useReactiveVar(brandingLogo);

    const {isGuest} = useUserInfo();
    const {isLobby: inLobby} = useIsLobby();
    const isMac = isMacOS();

    const agendaCategItem = categMap.find((obj) => obj.categ === ActionCategory.Agenda);
    const {setPaletteActiveCateg} = usePaletteActiveCateg();

    const isAdmin = props.isAdmin || (isAssistant && !isGuest);
    const dialogRef = useRef<DialogImperativeRef>(null);

    const [drawerLeftState, toggleLeftDrawer] = useDrawerLeftToggler();
    const agendaItems = useAgendaItems();
    const isFullScreen = useFullScreenState();

    const hasAgenda = agendaItems.some((a) => a.type === AgendaItemType.Planned);

    const [, set] = useDesignerSessionState();
    const commitInProgress = useDesignerSessionCommitState();

    const [toolPreviewMode, setToolPreviewMode] = useState(false);

    const convertingTool = isAdmin ? agendaItems.find((a) => a.processing) : undefined;
    const convertingMicroTag = convertingTool?.artifact?.artifactId;

    const {order} = session;

    const currentAgendaItem = agendaItems.find((a) => a.order === order);

    const {result} = useResourceResult(currentAgendaItem?.artifact?.resourceResults?.[0]?.id);
    const {completeFlow} = useOnboardingSession();

    const currentMicroName = currentAgendaItem?.artifact?.name;

    const currentMicroTag = convertingMicroTag ?? currentAgendaItem?.artifact?.artifactId;
    const currentMicroTagNotConverting = currentAgendaItem?.artifact?.artifactId;

    const resourceContent = result?.resource?.content as any;

    const [isEditMode, toggleEditMode] = useDesignerIsEditing();
    const mobileState = useMobile();
    const {isMobile, isMobileOrTablet} = mobileState;
    const [hidePopover, setHidePopover] = useState(false);

    const handleHidePopover = useCallback(() => {
        setHidePopover(false);
    }, []);

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const myProductToolArtifactDescriptor = currentMicroTag
        ? ArtifactsMap[currentMicroTag as any]?.tag === "flowos/my-product-tool"
        : false;

    const shouldQueryForMyProductTool = !!currentAgendaItem?.artifact?.data.myProductToolId;
    const {data: myProductToolQuery} = useQuery(MyProductToolDocument, {
        variables: {
            myProductToolId: currentAgendaItem?.artifact?.data.myProductToolId as string,
            sessionId: session.id,
        },
        skip: !shouldQueryForMyProductTool,
    });
    const showButtonAnimation = !isMobileOrTablet && !hasAgenda && !isAddingAgenda && currentMicroTag === "flowos/conference";
    const shouldShowAgendaButton = isMobile && inLobby && !hasAgenda;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        togglePalette();
        onClick?.();
    };

    const handleTogglePaletteAgenda = (event: React.MouseEvent<HTMLDivElement>) => {
        togglePalette();
        setPaletteActiveCateg(
            agendaCategItem
                ? {
                      id: agendaCategItem.id,
                      name: agendaCategItem.categ,
                      parentId: agendaCategItem.parentId,
                      searchStr: undefined,
                      isForced: false,
                  }
                : paletteActiveCategDefault
        );
    };

    const handleClose = () => {
        toggleEditMode(false);
        setAnchorEl(null);
        setToolPreviewMode(false);

        if (hidePopover) {
            setHidePopover(false);
        }
    };

    const handleCloseWithoutTogglingEditMode = () => {
        setAnchorEl(null);
        setToolPreviewMode(false);
        if (hidePopover) {
            setHidePopover(false);
        }
        if (isMobileOrTablet) {
            toggleLeftDrawer(DrawerState.FullyOpen, "AddToolsInPlayerButton:159");
        }
    };

    const onConfirm = (doNotCommit?: boolean) => {
        handleClose();
    };

    if (!props.isAdmin && !isAssistant) {
        return null;
    }

    if (session.childOfBreakoutRooms != null) {
        return null;
    }

    const onDetach = () => {
        if (inLobby) {
            designer.api.session.detachAgenda({
                type: AgendaItemType.Instant,
            });
        } else {
            const convertingAgendaItem = agendaItems.find((item) => item.processing);
            if (convertingAgendaItem) {
                designer.api.agendaItem.remove({
                    id: convertingAgendaItem.id,
                });
            } else {
                const order = getMaxSessionOrder() + 1;
                designer.api.agendaItem.create({
                    ...designer.constants.agendaItemDefaults,
                    type: AgendaItemType.Instant,
                    order,
                    doNotUpdateCreatePls: true,
                });
                designer.api.session.update({
                    order,
                });
            }
        }
        set({
            selectedAgendaItemId: null,
            selectedMacroIdToConfig: null,
            selectedMicroIdToPreview: null,
        });
        designer.commit();
        onClick?.();

        if (session.onboardingType === SessionOnboardingType.NewUser && currentMicroTag && filesType.includes(currentMicroTag)) {
            completeFlow({
                flowName: "share-a-file",
            });
        }
    };
    const onChangeFile = () => {};

    const onPreview = () => {
        setToolPreviewMode(true);
        setTimeout(() => {
            const convertingTool = agendaItems.find((item) => item.processing);

            if (convertingTool) {
                return;
            }

            if (!isEditMode) {
                toggleEditMode(true);
            }

            const selectedAgendaItem = convertingTool ?? agendaItems?.find((a) => a.order === order);
            const selectedAgendaItemId = selectedAgendaItem?.id;

            set({
                toolStep: ChooseToolStep.Preview,
                selectedResourceId: null,
                selectedAgendaItemId: selectedAgendaItemId ?? agendaItems[0].id,
                selectedMacroIdToConfig: null,
                selectedMicroIdToPreview: (selectedAgendaItem ?? agendaItems[0]).artifact?.id,
            });
            dialogRef.current?.toggle?.();
        }, 0);
    };

    const handleCloseDialog = () => {
        if (isEditMode) {
            toggleEditMode(false);
        }
        set({
            toolStep: ChooseToolStep.SelectTool,
            selectedResourceId: null,
            selectedAgendaItemId: null,
            selectedMacroIdToConfig: null,
            selectedMicroIdToPreview: null,
        });
        setToolPreviewMode(false);
        dialogRef.current?.toggle?.();
    };

    return (
        <>
            <div className={cls("tools-toggler-wrapper flex flex-align-start", className)} ref={ref}>
                {isAdmin && props.session.onboardingType !== SessionOnboardingType.FirstStrike ? (
                    <OnboardingShareButton renderOnlyChildren={isMobileOrTablet}>
                        <MenuItem
                            data-id="player-button-share"
                            onClick={isAdmin ? (shouldShowAgendaButton ? handleTogglePaletteAgenda : handleClick) : undefined}
                            ref={buttonRef}
                            component="div"
                            disabled={commitInProgress}
                            button
                            className={cls(
                                controlsClasses.controlButton,
                                classes.toolsButton,
                                !isAdmin && classes.disabledButton,
                                hideText && classes.toolsButtonIconOnly,
                                isMobileOrTablet ? classes.toolsButtonMobile : "",
                                showButtonAnimation && classes.buttonAnimation,
                                isFullScreen && "full-screen",
                                "share-button"
                            )}
                            role="button"
                            aria-label={t("palette.share") ?? ""}
                        >
                            <>
                                {shouldShowAgendaButton ? <AgendaToggleIcon /> : <ShareIcon />}
                                {((!(currentMicroTag && currentMicroTag !== "flowos/conference") &&
                                    !hideText &&
                                    !hasAgenda &&
                                    !isAddingAgenda) ||
                                    isMobileOrTablet ||
                                    (!(currentMicroTag && currentMicroTag !== "flowos/conference") &&
                                        !hideText &&
                                        !hasAgenda &&
                                        !isAddingAgenda &&
                                        session.isBooking)) && (
                                    <>
                                        <Typography className={!isMobileOrTablet ? "ml-8" : ""}>
                                            {shouldShowAgendaButton ? t("palette.agendas.add_agenda") : t("palette.share")}
                                        </Typography>{" "}
                                        {!isMobileOrTablet && (
                                            <div className="ml-12 flex flex-align-center mt-2">
                                                <div className={classes.keyBind}>{isMac ? "⌘" : "Ctrl"}</div>
                                                <div className={classes.keyBind}>K</div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        </MenuItem>
                    </OnboardingShareButton>
                ) : null}

                {currentMicroTag &&
                currentMicroTag !== "flowos/conference" &&
                !hasAgenda &&
                !isMobileOrTablet &&
                props.session.onboardingType !== SessionOnboardingType.FirstStrike ? (
                    <div className={classes.toolMenuContainer}>
                        <div className={toolMenuClasses.controlButtonInner}>
                            <div className={toolMenuClasses.titleContainer}>
                                <SessionDataProviders isEditMode>
                                    <ProcessingUiHandler isAdmin={isAdmin} inLobby={inLobby}>
                                        {
                                            <img
                                                src={
                                                    shouldQueryForMyProductTool && myProductToolQuery
                                                        ? myProductToolQuery.myProductTool.iconUrl ||
                                                          customBrandingLogo ||
                                                          myProductDefaultIcon
                                                        : getArtifactImage(descriptor[currentMicroTag].meta?.icon ?? "")
                                                }
                                                alt="icon"
                                                className={classes.artifactImage}
                                            />
                                        }
                                        <Typography
                                            data-id="tool-title"
                                            color="nonary"
                                            fontWeight="bold"
                                            className={toolMenuClasses.toolMenuTitle}
                                        >
                                            {shouldQueryForMyProductTool && myProductToolQuery
                                                ? myProductToolQuery.myProductTool.title
                                                : (resourceContent?.name ||
                                                      t(`artifacts.${descriptor[currentMicroTag].meta?.name.replace("-", ".")}.title`) ||
                                                      currentMicroName) ??
                                                  descriptor[currentMicroTag].meta?.displayName}
                                        </Typography>
                                    </ProcessingUiHandler>
                                </SessionDataProviders>
                            </div>
                        </div>

                        <Tooltip title={t("participant.share.stop_share") ?? "Stop sharing"} arrow placement="top">
                            <IconButton onClick={onDetach} className={classes.detachButton} disabled={!isAdmin || commitInProgress}>
                                <RemoveCircleOutlineRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}
            </div>

            {isAdmin && isMobileOrTablet && currentMicroTag && currentMicroTag !== "flowos/conference" && !hasAgenda ? (
                <MenuItem
                    data-id="detach-tool-mobile"
                    onClick={onDetach}
                    component="div"
                    disabled={commitInProgress}
                    button
                    className={cls(
                        controlsClasses.controlButton,
                        classes.toolsButton,
                        hideText && classes.toolsButtonIconOnly,
                        classes.mobileDetachButton
                    )}
                    role="button"
                    aria-label={t("player.footer.aria.tools") ?? "Tools"}
                >
                    <>
                        {/* <img src={ToolsInPlayerIcon} alt="tools-in-player-icon" className={classes.toolsIcon} /> */}
                        <Typography color="nonary" className="mr-8">
                            {filesType.includes(currentMicroTag)
                                ? t("player.footer.tool.detach_file")
                                : t("player.footer.tool.detach_tool")}
                        </Typography>
                        <RemoveCircleOutlineOutlinedIcon />
                    </>
                </MenuItem>
            ) : null}
            <SessionDataProviders isEditMode>
                {!toolPreviewMode ? (
                    <Popover
                        id={"tool-popover"}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        TransitionComponent={isMobile ? SlideTransition : undefined}
                        classes={{
                            paper: cls(classes.paper, hidePopover && classes.hiddenPaper, "add-tools-paper"),
                        }}
                        className={classes.popover}
                    >
                        <div className="fullh">
                            {anchorEl ? (
                                <DesignerToolPlaceholder
                                    onClose={handleClose}
                                    inDialog={true}
                                    handleHidePopover={handleHidePopover}
                                    handleCloseWithoutTogglingEditMode={handleCloseWithoutTogglingEditMode}
                                    inPopover={true}
                                    onConfirm={onConfirm}
                                    mobileState={mobileState}
                                    currentSessionDetails={session}
                                />
                            ) : null}
                        </div>
                    </Popover>
                ) : (
                    <Dialog
                        PaperProps={{
                            className: classes.dialogPaper,
                        }}
                        imperativeRef={dialogRef}
                        onClose={handleCloseDialog}
                        className={classes.dialogRoot}
                    >
                        <DesignerToolPlaceholder
                            className={cls("flex flex-col restricted-padding flex flex-col flex11-auto")}
                            currentSessionDetails={session}
                            mobileState={mobileState}
                            readOnly={true}
                            inDialog={!isMobile}
                            inPopover={false}
                            onClose={handleCloseDialog}
                        />
                    </Dialog>
                )}
            </SessionDataProviders>
        </>
    );
}

export default forwardRef(AddToolsInPlayerButton) as typeof AddToolsInPlayerButton;
