import {updateUnreadMessagesCount} from "@artifacts/chat/frontend/utils";
import {updateNewQuestionsCount} from "@artifacts/qa/frontend/utils";
import {EndBackstageDocument, EndSessionDocument} from "@generated/data";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import {AuthService} from "@workhorse/api/authService";
import {useMutation, useReactiveVar} from "@workhorse/api/data";
import React, {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {assistantActionAllowed} from "@workhorse/api/user";
import {Action, actionProfile, enableEndSessionModal} from "@workhorse/components/header/headerUtils";
import {disableNativePopup} from "@workhorse/components/LeavePageNative";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useRecordingProcessor} from "@workhorse/providers/RecordingProcessorProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";
import {ReactComponent as BaggageIcon} from "../../../../assets/media/baggage.svg";
import {SessionOnboardingType} from "../../../../providers/OnboardingSessionProvider/OnboardingSessionProvider";
import {submittedVar} from "../../pre-join-screen/PreJoinScreenEntry";
import OnboardingNewUserLeaveDialog from "../../session-view-components/OnboardingNewUserLeaveDialog";
import ModalFooter from "./ModalFooter";
import classes from "./styles/LeaveSessionModal.module.scss";
import UserView, {UserViewBackstage} from "./UserView";

type LeaveSessionButtonProps = {
    isAdmin: boolean;
    sessionId: string;
    parentSessionId?: string;
    participantIdInParent?: string;
    participantId: string;
    isAssistant: boolean;
    backstage?: boolean;
};

type EndModeType = "me" | "everyone";

export default function LeaveSessionButton(props: LeaveSessionButtonProps) {
    const {t} = useTranslation();

    const normalUserTitle = `${t("participant.leave_session.leave_session")}?`;
    const sessionOwnerTitle = `${t("participant.leave_session.end_session")}`;

    const history = useHistory();
    const {isAdmin, isAssistant, parentSessionId, participantIdInParent, backstage} = props;
    const sessionId = parentSessionId ?? props.sessionId;
    const participantId = participantIdInParent ?? props.participantId;
    const [ownerSessionEnd, setOwnerSessionEnd] = useState<EndModeType>("me");
    const enableModal = useReactiveVar(enableEndSessionModal);
    const {isGuest, isAuthenticated} = useUserInfo();
    const dialogImperativeRef = useRef<DialogImperativeRef>();
    const [endSessionMutation] = useMutation(EndSessionDocument);
    const [endBackstageMutation] = useMutation(EndBackstageDocument);
    const [loadingEndSession, setLoadingEndSession] = useState(false);

    const authService = AuthService.getInstance();
    const assistant = assistantActionAllowed(["session.endForAll"]);

    const {resetState, isActive} = useRecordingProcessor();
    const isRecording = isActive;
    // const isRecording = currentParticipant?.recordingStatus === ParticipantRecordingStatus.InProgress;

    const {onboardingType} = useOnboardingSession();

    const leaveSessionFromProfile = () => {
        const action = actionProfile();

        if (!action) {
            return;
        }

        if (action === Action.logout) {
            localStorage.clear();
            sessionStorage.clear();
            authService.logout();
        }

        if (action === Action.manageAccount) {
            history.push("/user/settings");
            enableEndSessionModal(false);
        }

        actionProfile(undefined);
    };

    const endSession = async () => {
        const profileAction = actionProfile();

        await endSessionMutation({variables: {sessionId}})
            .then((res) => {
                if (!res) {
                    toast(`${t("participant.leave_session.toast.end_session_error")}!`);
                    console.error(`Problem ending session!`);
                } else {
                    if (profileAction) {
                        leaveSessionFromProfile();
                        return;
                    }
                }
            })
            .catch((err) => {
                toast(`${t("participant.leave_session.toast.end_session_error")}!`);
                console.error(`Problem ending session: ${err}`);
            });
    };

    const endBackstage = async () => {
        await endBackstageMutation({
            variables: {
                sessionId,
            },
        })
            .then((res) => {
                if (!res) {
                    toast("Problem ending backstage!");
                    console.error(`Problem ending backstage!`);
                } else {
                    updateUnreadMessagesCount(sessionId, undefined, true);
                    updateNewQuestionsCount(true);
                    submittedVar(false);
                }
            })
            .catch((err) => {
                toast("Problem ending backstage!");
                console.error(`Problem ending backstage: ${err}`);
            });
    };

    const toggleDialog = () => {
        if (dialogImperativeRef.current?.toggle) {
            dialogImperativeRef.current.toggle();
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOwnerSessionEnd(event.target.value as EndModeType);
    };

    const handleEndSession = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, mode?: string) => {
        setLoadingEndSession(true);
        updateUnreadMessagesCount(sessionId, undefined, true);
        updateNewQuestionsCount(true);

        const endMode = mode ?? ownerSessionEnd;

        const profileAction = actionProfile();
        if (isGuest) {
            localStorage.setItem("seenPreferences", "false");
        }

        if ((isAdmin || (isAssistant && assistant.canSessionEndForAll)) && endMode === "everyone" && !backstage) {
            await endSession();
        }

        if ((isAdmin || (isAssistant && assistant.canSessionEndForAll)) && endMode === "everyone" && backstage) {
            await endBackstage();
        }

        if (((isAdmin || isAssistant) && endMode !== "everyone") || isAuthenticated) {
            if (profileAction) {
                leaveSessionFromProfile();
                return;
            }

            apollo.cache.evict({id: sessionId});
            if (parentSessionId) {
                apollo.cache.evict({id: parentSessionId});
            }
            apollo.cache.gc();

            if (backstage) {
                history.push(`/`);
                setLoadingEndSession(false);
                closeDialog();
                event.stopPropagation();
                return;
            }

            if (onboardingType === SessionOnboardingType.NewUser) {
                history.push(`/`);
            } else {
                history.push({
                    pathname: `/feedback/${sessionId}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });
            }
        }

        if (isGuest) {
            if (profileAction) {
                leaveSessionFromProfile();
                return;
            }

            if (onboardingType === SessionOnboardingType.NewUser) {
                history.push(`/`);
            } else {
                history.push({
                    pathname: `/feedback/${sessionId}/${participantId}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });
            }
        }

        if (isRecording) {
            resetState();
        }

        setLoadingEndSession(false);
        closeDialog();

        event.stopPropagation();
    };

    const closeDialog = () => {
        disableNativePopup(false);
        enableEndSessionModal(false);
        actionProfile(undefined);
        toggleDialog();
    };

    useEffect(() => {
        if (enableModal) {
            toggleDialog();
            disableNativePopup(true);
        }
    }, [enableModal]);

    return onboardingType === SessionOnboardingType.NewUser && (isAdmin || isAssistant) ? (
        <OnboardingNewUserLeaveDialog
            imperativeRef={dialogImperativeRef}
            onClose={closeDialog}
            onEndSession={(event) => handleEndSession(event, "everyone")}
        />
    ) : (
        <Dialog
            onClose={closeDialog}
            className={classes.root}
            PaperProps={{
                className: cls(classes.paper),
                "aria-label": `${t("participant.leave_session.leave_session")}?`,
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop",
                },
            }}
            imperativeRef={dialogImperativeRef}
            key="dialog-modal"
        >
            <Typography variant="xl3" className={cls("flex flex-items-center", classes.title)}>
                {isAdmin || isAssistant ? (
                    <CallRoundedIcon className={cls(classes.titleIcon, "red")} />
                ) : (
                    <BaggageIcon className={classes.titleIcon} />
                )}
                {isAdmin || isAssistant ? sessionOwnerTitle : normalUserTitle}
            </Typography>

            {backstage ? (
                <UserViewBackstage
                    isAdmin={isAdmin}
                    isAssistant={isAssistant}
                    assistantCanEndSessionForAll={assistant.canSessionEndForAll}
                    ownerSessionEnd={ownerSessionEnd}
                    handleRadioChange={handleRadioChange}
                    loading={loadingEndSession}
                />
            ) : (
                <UserView
                    isAdmin={isAdmin}
                    isAssistant={isAssistant}
                    assistantCanEndSessionForAll={assistant.canSessionEndForAll}
                    ownerSessionEnd={ownerSessionEnd}
                    handleRadioChange={handleRadioChange}
                    loading={loadingEndSession}
                />
            )}

            <ModalFooter
                isAdmin={isAdmin}
                isAssistant={isAssistant}
                assistantCanEndSessionForAll={assistant.canSessionEndForAll}
                closeDialog={closeDialog}
                handleEndSession={handleEndSession}
                className={cls("flex-items-center", classes.footer)}
                onboardingType={onboardingType}
                loading={loadingEndSession}
            />
        </Dialog>
    );
}
