import Typography from "@ui/cdk/Typography";
import Paper from "@ui/core/components/Paper";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import React, {useEffect, useMemo} from "@workhorse/api/rendering";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {cls} from "@ui/cdk/util/util";
import GuestHeader from "./GuestHeader";
import GuestInfoForm from "./GuestInfoForm";
import classes from "./styles/GuestInfoDialog.module.scss";
import {WithHostType} from "@workhorse/declarations";
import {useQuery} from "@workhorse/dataApi";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import {dateFormat} from "@ui/cdk/DateFormat";
import {useTranslation} from "react-i18next";
import {HyperlinkExternal} from "@workhorse/util/links";
import useBranding from "../profile/Theming/useBranding";
import {format} from "date-fns";
import {getDateFnsLocale, localeWeekdayOfMonthDateFnsFormat} from "@sessions/common/utils/languages";
import Tooltip from "@ui/cdk/Tooltip";
import {useState} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";

type GuestInfoProps = {
    sessionId: string;
    askForGuestEmail?: boolean;
    onSubmit: (name: string, email?: string) => void;
    isParentOfRecurrence?: boolean;
} & WithHostType;

const GuestInfoDialog: React.FC<GuestInfoProps> = (props: GuestInfoProps) => {
    const {t, i18n} = useTranslation();
    const [trackEvent] = useTrackingEvents();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const {isMobile} = useMobile();

    useBranding({sessionId: props.sessionId});

    useEffect(() => {
        trackEvent("guest_joined_landing", {event_category: TrackingEventCategory.GuestActions});
    }, [trackEvent]);

    const {data} = useQuery("GetGuestInviteSessionDocument", {
        variables: {
            id: props.sessionId,
        },
        fetchPolicy: "no-cache",
    });

    const session = data?.session;

    const organizationPlan = session?.organizationPlan;
    const ownerHalfDayClock = session?.ownerHalfDayClock;
    const ownerLanguage = session?.ownerLanguage || undefined;

    const lang = i18n.language;

    const startAt = new Date(session?.startAt ?? new Date());
    const plannedEnd = new Date(session?.plannedEnd ?? new Date());

    const isSessionAnotherYear = startAt.getFullYear() !== new Date().getFullYear();
    const isSessionOnMultipleDays = startAt.getDay() !== plannedEnd.getDay();

    const formatTime = (date: Date | null | number | string) => {
        const time = new Date(date || Date.now());
        let formattedTime = dateFormat(time, ownerHalfDayClock ? "fullTime12h" : "fullTime24h").toLocaleUpperCase();

        if (!ownerHalfDayClock) {
            formattedTime = formattedTime.replace(/(pm|am)/gi, "");
        }

        return formattedTime;
    };

    const titleTooltipText = session?.name ?? "";
    const showTitleTooltip = titleTooltipText.length > 40 ? true : false;

    const toggleOpenTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    return (
        <>
            <GuestHeader
                hostType={props.hostType}
                sessionName={session?.name}
                organizationPlan={organizationPlan ?? undefined}
                ownerLanguage={ownerLanguage}
            />
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <div className={classes.infoContainer}>
                        {session?.lobbyPicture?.originalPictureUrl && (
                            <div className={classes.lobbyPicture}>
                                <img
                                    src={session?.lobbyPicture?.originalPictureUrl}
                                    style={{
                                        objectPosition: `center ${session?.lobbyPicture?.y ?? 0}%`,
                                    }}
                                />
                            </div>
                        )}

                        <div className={classes.welcomeWrapper}>
                            <Tooltip
                                title={showTitleTooltip ? titleTooltipText : ""}
                                placement="top"
                                arrow
                                open={tooltipOpen}
                                onClick={isMobile ? toggleOpenTooltip : undefined}
                            >
                                <Typography component="div" className={cls("flex00-auto", classes.title)} noWrap>
                                    <span title={session?.name}>{session?.name}</span>
                                </Typography>
                            </Tooltip>
                        </div>

                        <div className={"flex flex-items-center"}>
                            <div className={classes.calendarIcon}>
                                <EventRoundedIcon />
                            </div>
                            {props.isParentOfRecurrence ? (
                                <Typography>Part of a recurring session</Typography>
                            ) : (
                                <div className={classes.calendarContent}>
                                    <Typography
                                        component="div"
                                        color="secondary"
                                        fontWeight="bolder"
                                        className="flex flex-row flex-align-center"
                                    >
                                        <div className="flex flex-items-center">
                                            <span data-id="session-date">
                                                {format(startAt, localeWeekdayOfMonthDateFnsFormat[lang], {
                                                    locale: getDateFnsLocale(lang),
                                                })}
                                            </span>
                                            {isSessionOnMultipleDays ? (
                                                <span data-id="session-multiple-days" className="ml-4">
                                                    -{" "}
                                                    {format(plannedEnd ?? startAt, localeWeekdayOfMonthDateFnsFormat[lang], {
                                                        locale: getDateFnsLocale(lang),
                                                    })}
                                                </span>
                                            ) : null}
                                        </div>
                                        {isSessionAnotherYear ? <span>{`, ${dateFormat(startAt, ["year"])}`}</span> : null}
                                    </Typography>
                                    <Typography color="tertiary" fontWeight="bold" variant="sm" data-id="session-time">
                                        {formatTime(startAt)}
                                        {" - "}
                                        {formatTime(plannedEnd)}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col p-20 ">
                        {useMemo(
                            () => (
                                <GuestInfoForm askForGuestEmail={props.askForGuestEmail} onSubmit={props.onSubmit} />
                            ),
                            [props.sessionId, props.askForGuestEmail, props.onSubmit]
                        )}

                        <Typography variant="base" className={classes.termsText}>
                            {t("prejoin.i_agree_to_the_terms_and_privacy.i_agree_to_the") ?? "I agree to the"}{" "}
                            <HyperlinkExternal
                                target="_blank"
                                href={getHelpCenterArticleLink("knowledge-base/terms-privacy/terms-and-conditions")}
                                rel="noreferrer"
                            >
                                {t("g.terms_and_conditions") ?? "Terms & Conditions"}
                            </HyperlinkExternal>
                            &nbsp;{t("g.and") ?? "and"}&nbsp;
                            <HyperlinkExternal
                                target="_blank"
                                href={getHelpCenterArticleLink("knowledge-base/terms-privacy/privacy-policy")}
                                rel="noreferrer"
                            >
                                {t("g.privacy_policy") ?? "Privacy Policy"}
                            </HyperlinkExternal>
                        </Typography>
                    </div>
                </Paper>
            </div>
        </>
    );
};

export default GuestInfoDialog;
