type RuntimeEventCallback<T = any> = (payload: T) => void;

class ExtensionControllerBase<T extends Record<string, RuntimeEventCallback> = any> {
    private callbacks: {[key: string]: ((args: any) => void)[]} = {};

    constructor() {
        if (!(window as any)._sessions_ingest_extension_event) {
            (window as any)._sessions_ingest_extension_event = [];
        }

        (window as any)._sessions_ingest_extension_event.push((key: string, payload?: any) => {
            this.triggerHandlers(key, payload);
        });

        window.onmessage = (event) => {
            const data = event.data;
            if (data?._sessions) {
                const key = data.key;
                const payload = data.payload;

                this.triggerHandlers(key, payload);
            }
        };
    }

    protected triggerHandlers(key: string, payload?: any) {
        if (this.callbacks[key]) {
            this.callbacks[key].forEach((callback) => callback(payload));
        }
    }

    protected sendMessageToRuntime(key: string, args?: any) {
        window.parent?.postMessage(
            {
                _sessions: true,
                key,
                args,
            },
            "*"
        );
    }

    protected onRuntimeMessage(key: string, callback: RuntimeEventCallback) {
        if (!this.callbacks[key]) {
            this.callbacks[key] = [];
        }

        this.callbacks[key].push(callback);
    }

    public addEventHandler<Q extends keyof T>(key: Q, callback: T[Q]) {
        this.onRuntimeMessage(key as string, callback);
        console.log(this.callbacks);
        return callback;
    }

    public removeEventHandler<Q extends keyof T>(key: Q, callback: T[Q]) {
        this.callbacks[key as string] = this.callbacks[key as string].filter((cb) => cb !== callback);
        console.log(this.callbacks);
    }
}

export type DrawerState = "close" | "open";
export type DrawerStateChangeHandler = RuntimeEventCallback<DrawerState>;

type ExtensionDrawerEventMap = {
    "drawer-state-change": DrawerStateChangeHandler;
};

class ExtensionDrawerController extends ExtensionControllerBase<ExtensionDrawerEventMap> {
    public close() {
        this.sendMessageToRuntime("drawer_close");
    }

    public open() {
        this.sendMessageToRuntime("drawer_open");
    }

    public destroy() {
        this.sendMessageToRuntime("drawer_destroy");
    }
}

export type ExtensionInfo = {
    version: string;
};

export type ExtensionInstallState = "installed" | "uninstalled";
export type ExtensionInstallStateChangeHandler = RuntimeEventCallback<ExtensionInstallState>;

type ExtensionApiEventMap = {
    "install-state-change": ExtensionInstallStateChangeHandler;
};

class ExtensionApi extends ExtensionControllerBase<ExtensionApiEventMap> {
    public drawer = new ExtensionDrawerController();

    public isExtensionEnabled(): boolean {
        return (window as any)._sessions_extension; // if this object is set, then the extension is enabled.
    }

    public isExtensionInstalled(): boolean {
        return !!this.getExtensionInfo();
    }

    public getExtensionInfo(): ExtensionInfo | undefined {
        const data = sessionStorage.getItem("sessions-extension-info");
        if (data) {
            try {
                return JSON.parse(data);
            } catch (ex) {}
        }

        return undefined;
    }
}

const extension = new ExtensionApi();

export default extension;
