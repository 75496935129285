import ClearIcon from "@material-ui/icons/Clear";
import Input from "@ui/cdk/Input";
import {cls} from "@ui/cdk/util/util";
import React, {memo} from "@workhorse/api/rendering";
import IconButton from "@workhorse/ui/core/components/IconButton";
import InputAdornment from "@workhorse/ui/core/components/InputAdornment";
import {ReactComponent as SearchIcon} from "../../../../assets/media/search-icon.svg";
import classes from "./style/TableSearchInput.module.scss";

export interface TableSearchInputProps {
    value: string;
    placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onClear?: () => void;
    rootClassName?: string;
    disabled?: boolean;
    dataId?: string;
}

const TableSearchInput = ({value, placeholder, onChange, rootClassName, onClear, disabled, dataId}: TableSearchInputProps) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }

        event.stopPropagation();
    };

    const handleClearInput = () => {
        if (onClear) {
            onClear();
        } else {
            onChange({target: {value: ""}} as any);
        }
    };

    return (
        <Input
            data-id={dataId ?? "search"}
            value={value}
            placeholder={placeholder ?? "Search"}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            className={cls(classes.searchInput, disabled && classes.disabledSearch, rootClassName)}
            endAdornment={
                value ? (
                    <InputAdornment position="end" className={classes.endAdornment}>
                        <IconButton type="submit" size="small" className={classes.searchIcon} onClick={handleClearInput}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }
            startAdornment={
                <InputAdornment position="start">
                    <IconButton type="submit" size="small" className={classes.searchIcon}>
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            }
            disabled={disabled}
            inputProps={{
                "data-private": "lipsum",
            }}
        />
    );
};

export default memo(TableSearchInput);
