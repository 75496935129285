import {RouteHOCProps} from "@workhorse/declarations";
import {Route, Switch} from "@workhorse/api/routing";
import loadable from "@workhorse/api/loadable";
import Loading from "@workhorse/components/Loading";
import RoomsContainer from "./RoomsContainer";

const CreateEditRoom = loadable(() => import("./CreateEditRoom"), {fallback: <Loading />});

export interface RoomsHocProps extends RouteHOCProps<{roomId?: string}> {}

/***
 * why the hoc
 * see ../base/index.tsx
 */
function RoomsHOC(props: RoomsHocProps) {
    return (
        <div className="route-hoc player-hoc flex-justify-center" style={{padding: 0}} id="rooms-hoc-wrapper">
            <Switch location={props?.location} key="switch">
                <Route exact path="/rooms" component={RoomsContainer} key="rooms-route" />
                <Route
                    exact
                    path={[
                        "/rooms/:sessionId",
                        "/rooms/:sessionId/agenda",
                        "/rooms/:sessionId/settings",
                        "/rooms/:roomId/instances",
                        "/rooms/:sessionId/widgets",
                        "/rooms/:sessionId/automations",
                        "/rooms/:sessionId/permissions",
                        "/rooms/:sessionId/recording",
                        "/rooms/:sessionId/exit-page",
                    ]}
                    component={CreateEditRoom}
                    key="rooms-create-edit-route"
                />
            </Switch>
        </div>
    );
}
export default RoomsHOC;
