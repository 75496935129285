import {createContext, useState} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";

type InjectArg = {
    component?: React.ReactNode | React.ReactNode[];
    id: string;
    remove?: boolean;
    className?: string;
};
export type AlreadyInjected = {[key: string]: React.ReactNode | React.ReactNode[]} & {
    classNames?: string[];
};
type InjectReducer = (current: AlreadyInjected, incoming: InjectArg) => AlreadyInjected;
export type InjectableIds =
    | "inHeader"
    | "inFooter"
    | "inDrawerLeft"
    | "inDrawerRight"
    | "inDrawerRightHeader"
    | "inFooterRight"
    | "inPlayer"
    | "inNavigationLeft"
    | "inSubHeader";

type InjectablePreviewIds =
    | "inFooter_preview"
    | "inDrawerLeft_preview"
    | "inDrawerRight_preview"
    | "inFooterRight_preview"
    | "inPlayer_preview"
    | "inSubHeader_preview";
type InjectorContextValue<
    T = {
        [K in InjectableIds]: (arg: InjectArg) => void;
    } & {
        [K in InjectablePreviewIds]: (arg: InjectArg) => void;
    }
> = T & {
    set: <X extends keyof T = keyof T>(methodId: X, method: T[X]) => void;
    unset: <X extends keyof T = keyof T>(methodId: X) => void;
};

export const InjectorContext = createContext<InjectorContextValue>({
    inHeader: () => {},
    inFooter: () => {},
    inDrawerLeft: () => {},
    inDrawerRight: () => {},
    inDrawerRightHeader: () => {},
    inFooterRight: () => {},
    inPlayer: () => {},
    inNavigationLeft: () => {},
    inSubHeader: () => {},

    inFooter_preview: () => {},
    inDrawerLeft_preview: () => {},
    inDrawerRight_preview: () => {},
    inFooterRight_preview: () => {},
    inPlayer_preview: () => {},
    inSubHeader_preview: () => {},

    set: () => {},
    unset: () => {},
});

export const injectReducer: InjectReducer = (current, incoming) => {
    const {classNames = []} = current;
    if (incoming.remove) {
        delete current[incoming.id];
        return {
            ...current,
            classNames: classNames.filter((className) => className !== incoming.className),
        };
    } else {
        return {
            ...current,
            [incoming.id]: incoming.component,
            classNames:
                incoming.className && classNames.indexOf(incoming.className) == -1 ? classNames.concat([incoming.className]) : classNames,
        };
    }
};

function InjectorsProvider(props: WithChildren) {
    const [injector, setInjector] = useState<InjectorContextValue>(() => {
        return {
            inHeader: () => {},
            inFooter: () => {},
            inDrawerLeft: () => {},
            inDrawerRight: () => {},
            inDrawerRightHeader: () => {},
            inFooterRight: () => {},
            inPlayer: () => {},
            inNavigationLeft: () => {},
            inSubHeader: () => {},

            inFooter_preview: () => {},
            inDrawerLeft_preview: () => {},
            inDrawerRight_preview: () => {},
            inFooterRight_preview: () => {},
            inPlayer_preview: () => {},
            inSubHeader_preview: () => {},

            set: (methodId, method) => {
                setInjector((current) => {
                    return {
                        ...current,
                        [methodId]: method,
                    };
                });
            },

            unset: (methodId) => {
                setInjector((current) => {
                    current[methodId] = () => {};
                    return current;
                });
            },
        };
    });

    return <InjectorContext.Provider value={injector}>{props.children}</InjectorContext.Provider>;
}

export default InjectorsProvider;
