import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {ReactComponent as TogglerPolls} from "../../../frontend/assets/media/macro-toggler-polls.svg";
import {forwardRef} from "@workhorse/api/rendering";

type PollsTogglerProps = {
    artifactId: string;
    myParticipantId: string;
    notInLobby: boolean;
    onToggle: () => void;
    isActive: boolean;
    inDrawer: boolean;
};

const PollsToggler = forwardRef((props: PollsTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {artifactId, notInLobby, onToggle, isActive, inDrawer} = props;

    return (
        <ArtifactActionsButton
            badgeContent={0}
            onClick={onToggle}
            isActive={isActive}
            MacroIcon={<TogglerPolls />}
            buttonDataId="player-button-polls"
            artifactId={artifactId}
            isLobby={!notInLobby}
            inDrawer={inDrawer}
            ref={ref}
        />
    );
});

export default PollsToggler;
