import {DesignerState} from "@generated/data";
import designer from "@workhorse/api/designer";
import {useMemo} from "@workhorse/api/rendering";
import {readRemoteUser} from "@workhorse/api/user";
import {createContextProvider} from "@workhorse/api/utils/context";
import {readQuery, useQuery} from "@workhorse/dataApi";
import {UseState, UseStateFromQuery, WithChildren} from "@workhorse/declarations";

// DESIGNER sessionId
function useDesignerSessionIdDataStore() {
    const sessionId = useQuery("DesignerSessionIdDocument").data?.designerSessionId?.sessionId;
    return sessionId ?? "";
}

const [DesignerSessionIdProvider, useDesignerSessionId] = createContextProvider(
    {
        name: "DesignerSessionId",
        strict: true,
    },
    useDesignerSessionIdDataStore
);

function useDesignerSessionLockedDataStore() {
    const locked = useQuery("DesignerSessionLockedDocument").data?.designerSessionLocked?.locked;
    const currentUserEmail = readRemoteUser()?.getRemoteUser?.user?.email;
    // console.log("loggr provider", locked, currentUserEmail);
    if (!locked) {
        return false;
    }

    if (!currentUserEmail) {
        return true;
    }
    return locked !== currentUserEmail;
}

const [DesignerSessionLockedProvider, useDesignerSessionLocked] = createContextProvider(
    {
        name: "DesignerSessionLocked",
        strict: true,
    },
    useDesignerSessionLockedDataStore
);

function useDesignerSessionChangedByDataStore() {
    const changedBy = useQuery("DesignerSessionChangedByDocument").data?.designerSessionChangedBy?.changedBy;
    return changedBy ?? [];
}

const [DesignerSessionChangedByProvider, useDesignerSessionChangedBy] = createContextProvider(
    {
        name: "DesignerSessionChangedBy",
        strict: true,
    },
    useDesignerSessionChangedByDataStore
);

export {useDesignerSessionChangedBy};
export {useDesignerSessionLocked};
export {useDesignerSessionId};
export {useDesignerSessionState};
export {useDesignerIsEditing};

// DESIGNER STATE

function useSessionStateDataStore(): UseStateFromQuery<DesignerState> {
    const {data} = useQuery("DesignerStateDocument")!;

    const set = designer.state.update.bind(designer);
    const state = useMemo(() => data?.designerState! ?? {}, [data?.designerState]);

    return [state, set];
}

const [DesignerStateProvider, useDesignerSessionState] = createContextProvider(
    {
        name: "DesignerState",
        strict: true,
    },
    useSessionStateDataStore
);

// IS_EDIT_MODE or not

function useSessionIsEditingStateStore(): UseState<boolean> {
    const sessionId = useDesignerSessionId()!;
    const {data} = useQuery("DesignerEditStateDocument")!;
    return useMemo(() => {
        return [
            data?.designerEditState?.sessionId === sessionId ? data?.designerEditState?.isEditing! ?? false : false,
            designer.state.setDesignerEditState.bind(designer),
        ];
    }, [data?.designerEditState]);
}

const [DesignerIsEditingProvider, useDesignerIsEditing] = createContextProvider(
    {
        name: "DesignerIsEditing",
        strict: true,
    },
    useSessionIsEditingStateStore
);

export function getDesignerArtifact(artifactId: string) {
    const agendaItems = designer.currentAgendaItems();
    const thisArtifact = agendaItems.find((a) => a.artifact && a.artifact.id === artifactId)?.artifact;
    return thisArtifact;
}

export function useDesignerSessionCommitState() {
    const {data} = useQuery("DesignerCommitStateDocument");
    const isCommiting = data?.designerCommitState?.commitInProgress ?? false;
    return useMemo(() => isCommiting, [isCommiting]);
}

type ReadDesignerDataArg = {
    sessionId?: string;
    noDiff?: boolean;
};

function makeReadVars(arg?: ReadDesignerDataArg) {
    const {sessionId: id = readQuery("DesignerSessionIdDocument")?.designerSessionId?.sessionId!} = arg ?? {};

    return Object.assign({id}, {noDiff: !!arg?.noDiff});
}

export function readDesignerParticipant(participantId: string) {
    const participants = readDesignerParticipants();
    return participants.find((p) => p.id === participantId);
}

export function readDesignerParticipants(arg?: ReadDesignerDataArg) {
    return (
        readQuery("LocalParticipantsDocument", {
            variables: makeReadVars(arg),
        })?.participants! || []
    );
}

export function readDesignerCurrentParticipant(arg?: ReadDesignerDataArg) {
    return (
        readQuery("LocalCurrentParticipantDocument", {
            variables: makeReadVars(arg),
        })!.currentParticipant! ?? {}
    );
}

export function readDesignerSession(arg?: ReadDesignerDataArg) {
    return (
        readQuery("GetSessionDocument", {
            variables: makeReadVars(arg),
        })?.session! ?? {}
    );
}

export function readDesignerAgendaItems(arg?: ReadDesignerDataArg) {
    return (
        readQuery("LocalAgendaItemsDocument", {
            variables: makeReadVars(arg),
        })?.agendaItems! ?? []
    );
}

export function readDesignerMacroArtifacts(arg?: ReadDesignerDataArg) {
    return (
        readQuery("LocalMacroArtifactsDocument", {
            variables: makeReadVars(arg),
        })?.macroArtifacts! ?? []
    );
}

export default function DesignerDataProviders(props: WithChildren) {
    return (
        <DesignerSessionIdProvider>
            <DesignerSessionLockedProvider>
                <DesignerSessionChangedByProvider>
                    <DesignerIsEditingProvider>
                        <DesignerStateProvider>{props.children}</DesignerStateProvider>
                    </DesignerIsEditingProvider>
                </DesignerSessionChangedByProvider>
            </DesignerSessionLockedProvider>
        </DesignerSessionIdProvider>
    );
}
