import {ArtifactTag} from "@generated/artifacts";
import {makeVar, ReactiveVar, useReactiveVar} from "@workhorse/api/data";
import React, {useMemo} from "@workhorse/api/rendering";

type InjectableDOMNodeID =
    | "drawer-left"
    | "drawer-right"
    | "app-header"
    | "app-footer"
    | "app-footer-right"
    | "drawer-left-preview"
    | "drawer-right-preview"
    | "app-footer-preview"
    | "app-footer-right-preview";

type InjectVar = {
    [key: string]: React.ReactNode | React.ReactNode[];
    classNames: Array<string>;
};

type ExistingContent = {
    [K in InjectableDOMNodeID]: ReactiveVar<InjectVar>;
} & {isPreview: ReactiveVar<boolean>};

export const existingContent: ExistingContent = {
    "drawer-left": makeVar<InjectVar>({
        classNames: [],
    }),
    "drawer-right": makeVar<InjectVar>({
        classNames: [],
    }),
    "app-header": makeVar<InjectVar>({
        classNames: [],
    }),
    "app-footer": makeVar<InjectVar>({
        classNames: [],
    }),
    "app-footer-right": makeVar<InjectVar>({
        classNames: [],
    }),

    "drawer-left-preview": makeVar<InjectVar>({
        classNames: [],
    }),
    "drawer-right-preview": makeVar<InjectVar>({
        classNames: [],
    }),
    // "app-header": makeVar<InjectVar>({
    //     classNames: [],
    // }),
    "app-footer-preview": makeVar<InjectVar>({
        classNames: [],
    }),
    "app-footer-right-preview": makeVar<InjectVar>({
        classNames: [],
    }),
    isPreview: makeVar<boolean>(false),
};

type InjectableContainerProps = {
    children: React.ReactNode | React.ReactNode[];
    id: string;
    className?: string | undefined;
    allowSameId?: boolean;
};

export type NonPreviewDomNodeId = Omit<
    InjectableDOMNodeID,
    "drawer-left-preview" | "drawer-right-preview" | "app-footer-preview" | "app-footer-right-preview"
>;

export type DrawerRightInjectableTag = ArtifactTag | "adminController" | "participants" | "offers" | "timer";

export const drawerRightActiveComponent = makeVar<DrawerRightInjectableTag | null>(null);

export const useDrawerRightActiveComponent = (tag: DrawerRightInjectableTag) => {
    const activeComponent = useReactiveVar(drawerRightActiveComponent);
    const setActiveComponent = (tag: DrawerRightInjectableTag | null) => drawerRightActiveComponent(tag);

    const isActive = activeComponent === tag;

    return useMemo(
        () => ({
            isActive,
            setActiveComponent,
        }),
        [isActive, setActiveComponent]
    );
};
