import {cls} from "@ui/cdk/util";
import {HTMLProps, useEffect, useRef} from "@workhorse/api/rendering";
import {useVideoNode} from "../../lib/useVideoNode";
import {useVideoObjectFit} from "../../lib/useVideoObjectFit";
import {useCameraSettings, useDevices} from "../../providers";
import classes from "../../styles/video.module.scss";

interface LocalVideoProps extends Omit<HTMLProps<HTMLVideoElement>, "ref" | "onResize"> {
    objectFit?: boolean;
    onBind?: () => void;
    onUnbind?: () => void;
    onResize?: (width: number, height: number) => void;
}

export function LocalVideo(props: LocalVideoProps) {
    const {objectFit, onBind, onUnbind, onResize, style, ...rest} = props;

    const {cameraMirrorSelfView} = useCameraSettings();
    const {videoStream, videoTransformedStream} = useDevices();
    const {video, setVideo} = useVideoNode();
    useVideoObjectFit(video);

    const options = {
        onBind,
        onUnbind,
        onResize,
    };
    const optionsRef = useRef(options);
    optionsRef.current = options;

    const stream = videoTransformedStream ?? videoStream;

    useEffect(() => {
        if (!video) {
            return;
        }

        const onVideoResize = () => {
            const width = video.videoWidth;
            const height = video.videoHeight;
            if (width && height) {
                optionsRef.current.onResize?.(width, height);
            }
        };

        video.addEventListener("resize", onVideoResize);

        return () => {
            video.removeEventListener("resize", onVideoResize);
        };
    }, [video, onResize]);

    useEffect(() => {
        if (!video || !stream) {
            return;
        }

        video.srcObject = stream;

        optionsRef.current.onBind?.();

        return () => {
            optionsRef.current.onUnbind?.();
        };
    }, [video, stream]);

    if (!stream) {
        return null;
    }

    return (
        <video
            {...rest}
            style={{
                transform: `rotateY(${cameraMirrorSelfView ? "180" : "0"}deg)`,
                ...style,
            }}
            ref={setVideo}
            autoPlay={true}
            muted={true}
            controls={false}
            playsInline={true}
            className={cls(cameraMirrorSelfView && "inverted", `ch-local-video ${classes.chimeVideo}`, rest.className)}
        />
    );
}
