import {ParticipantStatus} from "@generated/data";
import Snackbar from "@ui/core/components/Snackbar";
import {memo, useCallback, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {useDataEvent} from "@workhorse/dataApi";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useTranslation} from "react-i18next";
import PendingJoinApprovalEntry from "./components/PendingJoinApprovalEntry";
import {answerParticipants} from "./joinApi";
import classes from "./styles/PendingJoinApprovals.module.scss";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

type PendingJoinApprovalsProps = {
    sessionId: string;
};

function PendingJoinApprovals(props: PendingJoinApprovalsProps) {
    const {t} = useTranslation();
    const {sessionId} = props;

    const {isMobile} = useMobile();

    const {waitingToJoinParticipants} = useParticipantsStore(
        useShallow((state) => ({
            waitingToJoinParticipants: state.waitingToJoin,
        }))
    );

    const [hideSnackbar, setHideSnackbar] = useState<boolean>(false);

    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive: isComponentActive, setActiveComponent} = useDrawerRightActiveComponent("participants");

    const isActive = isComponentActive && rightDrawerState.isFullyOpen;

    const handleWaitingForApproval = useCallback(
        (participantIds: string[], hasBeenApproved: boolean) =>
            answerParticipants(sessionId, participantIds, hasBeenApproved)
                .then((res) => {
                    if (res.data?.answerWaitingParticipants) {
                        toast(
                            participantIds.length > 1
                                ? hasBeenApproved
                                    ? t("player.notifications.participants.participants_approved")
                                    : t("player.notifications.participants.participants_declined")
                                : hasBeenApproved
                                ? t("player.notifications.participants.participant_approved")
                                : t("player.notifications.participants.participant_declined"),
                            {
                                type: "success",
                            }
                        );
                    }
                })
                .catch(() => {
                    toast(t("g.something_went_wrong") + "...", {type: "error"});
                }),
        [sessionId]
    );

    const handleOpenParticipantsMacro = useCallback(() => {
        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent("participants");
        }

        if ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) {
            toggleRightDrawer();
        }
    }, [isActive, isMobile, rightDrawerState, toggleRightDrawer, setActiveComponent]);

    const handleAdmitAll = useCallback(() => {
        const participantIds = waitingToJoinParticipants.map((p) => p.id);
        handleWaitingForApproval(participantIds, true);
    }, [handleWaitingForApproval, waitingToJoinParticipants]);

    const onRequestReceiveAsOwner = useCallback(
        (data: SubscriptionPayload<"WatchdogPrivateDocument">) => {
            if (data.watchdogPrivate.approvalRequestsAsOwner && !isActive) {
                setHideSnackbar(false);
            }
        },
        [isActive]
    );

    useDataEvent("onWatchdogPrivate", onRequestReceiveAsOwner);

    useEffect(() => {
        if (isActive) {
            setHideSnackbar(true);
        }
    }, [isActive]);

    return (
        <>
            {!hideSnackbar && waitingToJoinParticipants.length > 0 && (
                <Snackbar
                    open={true}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    className={classes.root}
                >
                    <PendingJoinApprovalEntry
                        participants={waitingToJoinParticipants}
                        onWaitingForApproval={handleWaitingForApproval}
                        onManage={handleOpenParticipantsMacro}
                        onAdmitAll={handleAdmitAll}
                    />
                </Snackbar>
            )}
        </>
    );
}

export default memo(PendingJoinApprovals, (p, n) => p.sessionId === n.sessionId);
