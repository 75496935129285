import {RECORDING_USER_EMAIL} from "@common/recording/constants";
import {DrawerState, StopSessionRecordingDocument} from "@generated/data";
import AddIcon from "@material-ui/icons/Add";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HomeIcon from "@material-ui/icons/Home";
import SpecialButton from "@ui/cdk/SpecialButton";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import loadable from "@workhorse/api/loadable";
import {useEffect} from "@workhorse/api/rendering";
import {Link} from "@workhorse/api/routing";
import {useTrackingEvents} from "@workhorse/api/tracking";
import {useLocalUser} from "@workhorse/api/user";
import {WithMobileState} from "@workhorse/declarations";
import {makeSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {existingContent} from "@workhorse/providers/inject";
import {useDrawerLeftToggler, useFullScreenState} from "@workhorse/providers/state";
import Image from "../assets/media/error_page_chair.png";
import Header from "./header/Header";
import classes from "./style/ErrorComponent.module.scss";

const ReportProblemDialog = loadable(() => import("../components/reportProblem/ReportProblemDialog"));

type ErrorComponentProps = {
    primaryTitle: string;
    secondaryTitle: string;
    onLeave?: () => void;
    withHeader?: boolean;
} & WithMobileState;

const ErrorComponent = (props: ErrorComponentProps) => {
    const {primaryTitle, secondaryTitle, withHeader = false} = props;

    const localUser = useLocalUser();
    const [, toggleLeftDrawer] = useDrawerLeftToggler();
    const [trackEvent] = useTrackingEvents();
    const isFullScreen = useFullScreenState();
    const isRecorder = localUser?.localUser?.email === RECORDING_USER_EMAIL;

    const stopRecording = async () => {
        sessionStorage.removeItem("triedReloadForRecorder");
        const id = makeSessionIdFromRoute(window.location.pathname);
        if (id) {
            const res = await apollo.client.mutate({
                mutation: StopSessionRecordingDocument,
                variables: {
                    sessionId: id,
                },
            });

            if (res.errors) {
                console.error("Error while stopping recording", res.errors);
            }
        }
    };

    useEffect(() => {
        if (isRecorder) {
            const triedReload = sessionStorage.getItem("triedReloadForRecorder");
            if (!triedReload) {
                sessionStorage.setItem("triedReloadForRecorder", "true");

                window.location.reload();
            } else {
                stopRecording();
            }
        }
        return () => {
            existingContent["drawer-left"]({
                classNames: [],
            });

            existingContent["drawer-left-preview"]({
                classNames: [],
            });

            // console.log("toggling in ErrorComponent", DrawerState.Closed);

            toggleLeftDrawer(DrawerState.Closed, "ErrorComponent:53");
        };
    }, []);

    const handleNewSessionClick = () => {
        //    TODO:
        // createSession().then((res) => {
        //     trackEvent("create_session_scratch", {event_category: TrackingEventCategory.SessionActions});
        //     upsertCalendarEventsById([res.data?.createOneSession?.id as string]);
        // });
    };
    if (isRecorder) {
        return <div>SWW FOR RECORDER</div>;
    }

    return (
        <>
            {withHeader ? <Header isFullScreen={isFullScreen} mobileState={props.mobileState} /> : null}

            <div className={cls("flex flex-col flex-center-all", classes.root)}>
                <div className={classes.header}>
                    <Typography variant="xl7" component="h1" className={classes.primaryTitle}>
                        {primaryTitle}
                    </Typography>

                    <Typography variant="xl3" component="h2" className={classes.secondaryTitle}>
                        {secondaryTitle}
                    </Typography>
                </div>

                <img className={cls(classes.image, localUser?.isAuthenticated && classes.smallImage)} src={Image} alt="error img" />

                {localUser?.isAuthenticated && (
                    <div className="flex flex-col flex-center-all gap-24">
                        <Typography className={classes.localUserMessage}>
                            If the problem persists, you better head straight to one of these pages:
                        </Typography>

                        <div className={cls("flex gap-16", classes.specialBtnsWrapper)}>
                            <Link to="/" className={classes.link} onClick={() => props.onLeave && props.onLeave()}>
                                <SpecialButton
                                    specialAccent="purple"
                                    specialVariant={500}
                                    specialLightVariant={400}
                                    buttonProps={{
                                        startIcon: <HomeIcon />,
                                    }}
                                >
                                    {"Home"}
                                </SpecialButton>
                            </Link>

                            <Link className={classes.link} to="/base" onClick={() => props.onLeave && props.onLeave()}>
                                <SpecialButton
                                    specialAccent="green"
                                    specialVariant={500}
                                    specialLightVariant={400}
                                    buttonProps={{
                                        startIcon: <DateRangeIcon />,
                                    }}
                                >
                                    {"Calendar"}
                                </SpecialButton>
                            </Link>

                            <SpecialButton
                                specialAccent="red"
                                specialVariant={500}
                                specialLightVariant={400}
                                buttonProps={{
                                    startIcon: <AddIcon />,
                                }}
                                onClick={handleNewSessionClick}
                            >
                                {"New session"}
                            </SpecialButton>

                            <ReportProblemDialog key="report-problem-dialog-error-component" mobileState={props.mobileState} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ErrorComponent;
