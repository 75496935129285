import {makeStyles} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {useState, cloneElement} from "@workhorse/api/rendering";
import {ExtractProps} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/InfoTooltip.module.scss";

type InfoTooltipProps = Omit<ExtractProps<typeof Tooltip>, "children" | "open" | "onClose"> & {
    title?: string;
    info: string | JSX.Element;
    className?: string;
    children?: JSX.Element;
    openOnHover?: boolean;
    icon?: "info" | "help";
};

function InfoTooltip(props: InfoTooltipProps) {
    const [open, setOpen] = useState(false);
    const {title: body, info, className, placement = "bottom", children, openOnHover = false, icon, ...otherProps} = props;
    const title = (
        <div>
            {body ? (
                <Typography className={cls("flex flex-items-center", classes.title)} key="title">
                    <InfoOutlinedIcon />
                    {body}
                </Typography>
            ) : null}
            <Typography variant="sm" className={cls("flex", classes.info)} key="info">
                {info}
            </Typography>
        </div>
    );

    const openTooltip = () => {
        setOpen(true);
    };

    const closeTooltip = () => {
        setOpen(false);
    };

    return (
        <Tooltip
            placement={placement}
            {...otherProps}
            title={title}
            classes={{
                ...props.classes,
                tooltip: cls(classes.tooltip, props.classes?.tooltip),
                popper: cls(classes.root, props.classes?.popper),
                arrow: cls(classes.arrow, props.classes?.arrow),
            }}
            disableFocusListener={true}
            disableTouchListener={true}
            onClose={closeTooltip}
            open={open}
        >
            {children ? (
                cloneElement(children, {onClick: openTooltip})
            ) : (
                <span
                    className={cls(classes.icon, className, "info-icon", openOnHover ? classes.hoverable : "")}
                    onClick={openTooltip}
                    onMouseEnter={openOnHover ? openTooltip : undefined}
                >
                    {icon === "help" ? <HelpOutlineRoundedIcon /> : <InfoOutlinedIcon />}
                </span>
            )}
        </Tooltip>
    );
}

export default InfoTooltip;
