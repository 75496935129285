import {UserAccessProvider} from "@workhorse/api/access/hooks";
import React, {Fragment, useMemo} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useUser} from "@workhorse/components/user";
import {WithChildren} from "@workhorse/declarations";
import RemoteUserProvider from "./RemoteUserProvider";
import {UserInfoProvider, useUserInfo} from "./UserInfoProvider";
import {UserNotesProvider, useUserNotes} from "./UserNotesProvider";

export {useUserInfo, useUserNotes};

const useUserSystemStore = () => {
    const {id, email, role} = useUser();

    return useMemo(() => ({id, email, role}), [id, email, role]);
};

export const [UserSystemProvider, useUserSystem] = createContextProvider(
    {
        name: "UserSystem",
    },
    useUserSystemStore
);

type WithUserProps<TProviderProps extends WithChildren> = WithChildren & {
    /**
     * @description The provider (or Wrapper) to inject if the role is in the allowlist
     */
    provider: React.Provider<TProviderProps> | React.ComponentType<TProviderProps>;

    /**
     * @description The allowlist of roles that can use the provider (or Wrapper)
     * @default ["GUEST" | "USER"]
     */
    allowlist?: ("GUEST" | "USER")[];
} & TProviderProps;

/**
 *  @description This will allow you to inject a provider into the application only for wanted role types
 */
export function WithUser<TProviderProps extends WithChildren>({
    provider: Provider,
    children,
    allowlist = ["GUEST", "USER"],
    ...props
}: WithUserProps<TProviderProps>) {
    const {role} = useUserSystem();

    const isRoleAccepted = (allowlist as string[]).includes(role);
    const Wrapper = isRoleAccepted ? Provider : Fragment;
    const wrapperProps = (isRoleAccepted ? props : {}) as TProviderProps;

    return <Wrapper {...wrapperProps}>{children}</Wrapper>;
}

export function UserProvider({children}: WithChildren) {
    return (
        <RemoteUserProvider>
            <UserSystemProvider>
                <UserInfoProvider>
                    <UserAccessProvider>
                        <UserNotesProvider>{children}</UserNotesProvider>
                    </UserAccessProvider>
                </UserInfoProvider>
            </UserSystemProvider>
        </RemoteUserProvider>
    );
}
