import WorkspaceContent from "./components/WorkspaceContent";
import WorkspacePreview from "./components/WorkspacePreview";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "./styles/OnboardingWorkspace.module.scss";
import {cls} from "@ui/cdk/util";
import {ContentInfo, WorkspacePreviewData} from "./OnboardingWorkspaceDialog";

type OnboardingWorkspaceProps = {
    contentInfo: ContentInfo;
    workspacePreviewData: WorkspacePreviewData;
};

function OnboardingWorkspace(props: OnboardingWorkspaceProps) {
    const {contentInfo, workspacePreviewData} = props;

    const {
        bp: {isMobileOrTabletBP},
    } = useMobile();

    return (
        <div className={cls("flex flex-justify-between", classes.root)}>
            <WorkspaceContent contentInfo={contentInfo} />
            {!isMobileOrTabletBP && <WorkspacePreview {...workspacePreviewData} />}
        </div>
    );
}

export default OnboardingWorkspace;
