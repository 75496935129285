import {playSoundAction, SoundActionType} from "@workhorse/pages/player/soundActions";
import {useEffect} from "./rendering";

const useUnfocusedTabSoundNotification = (playSound: boolean, delay: number | undefined = 30000): void => {
    useEffect(() => {
        let interval: NodeJS.Timeout | null;

        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible" && interval) {
                clearInterval(interval);
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        if (playSound && document.visibilityState === "hidden") {
            interval = setInterval(() => {
                playSoundAction(SoundActionType.Notification);
            }, delay);
        }

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);

            if (interval) {
                clearInterval(interval);
            }
        };
    }, [playSound, delay]);
};

export default useUnfocusedTabSoundNotification;
