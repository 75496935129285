import {useMobile} from "@workhorse/providers/MobileProvider";
import OnboardingFeaturesJourneyPopup from "./components/OnboardingFeaturesJourneyPopup";
import {useOnboardingFeaturesJourney} from "./hooks";
import {useFeaturesOnboardingConfiguration} from "./provider";

function OnboardingFeaturesJourney() {
    const {isMobileOrTablet} = useMobile();
    const {configuration} = useFeaturesOnboardingConfiguration();
    const journey = useOnboardingFeaturesJourney(configuration);

    if (journey === false || isMobileOrTablet) {
        return null;
    }

    return <OnboardingFeaturesJourneyPopup journey={journey} />;
}

export default OnboardingFeaturesJourney;
