import {HostType} from "@generated/data";
import env from "@generated/environment";
import RecordingPlaceholder from "@ui/cdk/RecordingPlaceholder/RecordingPlaceholder";
import {getPlayerDomain} from "@workhorse/api/csd";
import {useEmbedSessions} from "@workhorse/api/embed";
import {createElement, useEffect} from "@workhorse/api/rendering";
import {Route, RouteChildrenProps, Switch, useLocation} from "@workhorse/api/routing";
import {useMixpanelTracker} from "@workhorse/api/tracking";
import * as Page from "@workhorse/pages";
import {useMobile} from "./MobileProvider";
import {useUserInfo} from "./User/UserInfoProvider";

function MainRoutes() {
    const location = useLocation();
    const mobileState = useMobile();
    const {isRecorder} = useUserInfo();
    const {mixpanelPageView} = useMixpanelTracker();

    const {embedMode} = useEmbedSessions();

    if (embedMode === "mobile" && !location.pathname.includes("/session")) {
        return null;
    }

    useEffect(() => {
        // if recorder reloads and sww doesn't appear anymore, we need to cleanup the session storage for future ssw
        const triedReload = sessionStorage.getItem("triedReloadForRecorder");

        if (isRecorder && triedReload) {
            setTimeout(() => {
                sessionStorage.removeItem("triedReloadForRecorder");
            }, 15 * 1000);
        }
    }, [isRecorder]);

    useEffect(() => {
        if (!env.csd?.disable) {
            if (!["/session", "/join", "/user/invite", "/feedback", "/waiting"].some((url) => location.pathname.includes(url))) {
                if (getPlayerDomain() != window.location.host) {
                    const redirect = (window.location.href + "").replace(window.location.host, getPlayerDomain());
                    window.location.replace(redirect);
                }
            }
        }

        const locations = ["/user/workspace/general", "/user/settings/profile", "/user/organization/general"];

        if (locations.some((loc) => loc === location.pathname)) {
            const path = location.pathname.split("/")[2];
            const pageName = path === "settings" ? "user-settings" : `${path}-settings`;
            mixpanelPageView(pageName);
        }
    }, [location.key]);

    return (
        <div className="route-transitioner">
            <Switch location={location}>
                {(Object.keys(Page.routes) as HostType[]).map((hostType) => {
                    const route = Page.routes[hostType];

                    const shouldRenderRecordingPlaceholder = isRecorder && hostType !== HostType.Player;

                    return (
                        <Route key={hostType} path={route.path} exact={route.exact ?? true}>
                            {
                                // using the Route.children function to account for situations
                                // where custom props should be passed to a route component
                                // ...though it should never be needed
                                (routeProps: RouteChildrenProps) => {
                                    return createElement(
                                        shouldRenderRecordingPlaceholder ? RecordingPlaceholder : Page[hostType] || Page.NOTFOUND,
                                        {
                                            ...routeProps,
                                            mobileState,
                                            ...(shouldRenderRecordingPlaceholder ? {hostType} : {}),
                                        }
                                    );
                                }
                            }
                        </Route>
                    );
                })}
            </Switch>
        </div>
    );
}

export default MainRoutes;
