import Typography from "@ui/cdk/Typography";
import {useIsWantedAgenda, useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";

export function OnboardingOpenAgenda(props: WithTooltipChildren) {
    const {tooltip} = useOnboardingSession();
    const isWantedAgenda = useIsWantedAgenda(tooltip);
    const [drawer] = useDrawerLeftToggler();
    const isDrawerOpen = drawer.isFullyOpen;
    const show = !isWantedAgenda && !isDrawerOpen;

    const title = (
        <Typography variant="sm" fontWeight="normal" color="background">
            Open agenda to resume onboarding.
        </Typography>
    );

    return (
        <OnboardingTooltip tooltipId={tooltip || ""} title={title} placement="top" arrow={true} skip={!show}>
            {props.children}
        </OnboardingTooltip>
    );
}
