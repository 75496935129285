import {addMinutes, differenceInMinutes, startOfDay} from "date-fns";

export function isObjOrArray(val: any) {
    return val != null && (Array.isArray(val) ? true : typeof val === "object" && !(val instanceof Date));
}

export function isObj(val) {
    return val != null && !Array.isArray(val) && typeof val === "object" && !(val instanceof Date);
}

export function adjustExcludedDate(date: Date, dtStart: Date) {
    const dateDelta = differenceInMinutes(date, startOfDay(date));
    const startDelta = differenceInMinutes(dtStart, startOfDay(dtStart));
    if (dateDelta !== startDelta) {
        return addMinutes(startOfDay(date), startDelta);
    }

    return date;
}
