import Button from "@ui/cdk/Button";
import {AuthService} from "@workhorse/api/authService";
import UserProfileMenu from "@workhorse/components/header/UserProfileMenu";
import {SessionData} from "@workhorse/declarations/dataTypes";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {useHistory} from "react-router";
import PlayerSessionSettingsButton from "./components/PlayerSessionSettingsButton";
import PlayerSessionsLogo from "./components/PlayerSessionsLogo";
import PlayerSessionTitle from "./components/PlayerSessionTitle";
import PlayerSessionViewMenu from "./components/PlayerSessionViewMenu";
import classes from "./styles/PlayerHeader.module.scss";
import PlayerSessionTranscript from "./components/PlayerSessionTranscript";
import FeedbackButton from "@workhorse/components/FeedbackButton";
import PlayerSessionTimeElapsed from "./components/PlayerSessionTimeElapsed";
import {RecordingIndicator} from "./components/RecordingIndicator";
import {BreakoutMenu} from "../components/BreakoutMenu";
import {EndBackstageDocument, OrganizationPlan, SessionLifecycle} from "@generated/data";
import PlayerSessionControlStatus from "./components/control-status/PlayerSessionControlStatus";
import {useTranslation} from "react-i18next";
import {orchestrator} from "@workhorse/providers/state";
import LanguageSelector from "@workhorse/components/language-selector/LanguageSelector";
import {useEffect, useState} from "@workhorse/api/rendering";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import ConfirmationDialog from "@workhorse/components/ConfirmationDialog";
import {setSessionGuestUtmCookies} from "@workhorse/api/user";
import {useMutation} from "@apollo/client";
import toast from "@workhorse/api/toast";
import {updateUnreadMessagesCount} from "@artifacts/chat/frontend/utils";
import {updateNewQuestionsCount} from "@artifacts/qa/frontend/utils";
import {submittedVar} from "../pre-join-screen/PreJoinScreenEntry";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";

type PlayerHeaderProps = {
    session: SessionData;
    isAdmin: boolean;
    isAssistant: boolean;
    isLobby: boolean;
    isPreJoin: boolean;
};

export default function PlayerHeader(props: PlayerHeaderProps) {
    const {t} = useTranslation();
    const {
        session: {
            childOfBreakoutRooms,
            name,
            currentParticipant: {pid: currentParticipantId},
            id: sessionId,
            organizationPlan,
            ownerLanguage,
        },
        isLobby,
        isAdmin,
        isAssistant,
        isPreJoin,
    } = props;

    const isSessionView = !isLobby && !isPreJoin;

    const history = useHistory();

    const {isGuest} = useUserInfo();
    const {isMobile} = useMobile();

    const {stopShareScreen} = useScreenShareOptions();

    const {featureIsAvailable} = useFeatureInfo({
        feature: LimitedFeature.WHITE_LABELING,
        plan: organizationPlan ?? undefined,
    });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [endBackstageMutation] = useMutation(EndBackstageDocument);

    const showSignUp = !featureIsAvailable && !isMobile;

    const onLogin = () => {
        setSessionGuestUtmCookies(sessionId);
        AuthService.getInstance().login(history.location.pathname, true);
    };

    const onRegister = () => {
        setSessionGuestUtmCookies(sessionId);
        AuthService.getInstance().register(history.location.pathname);
    };

    const isOrganizer = isAdmin || isAssistant;

    const isSessionStarted = props.session.lifecycle === SessionLifecycle.Started;

    const [isTimeToJoinBackstage, setIsTimeToJoinBackstage] = useState(
        props.session.startAt && isOrganizer ? new Date(props.session.startAt).getTime() - new Date().getTime() <= 3600000 : false
    );

    useEffect(() => {
        if (!props.session.startAt || !isOrganizer) {
            return;
        }
        const interval = setInterval(() => {
            const isIt = props.session.startAt ? new Date(props.session.startAt).getTime() - new Date().getTime() <= 3600000 : false;
            if (isTimeToJoinBackstage !== isIt) {
                setIsTimeToJoinBackstage(isIt);
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [props.session.startAt, isTimeToJoinBackstage, isOrganizer]);

    function handleStartEventFromBackstage() {
        setOpenConfirmDialog(true);
    }

    const endBackstage = async () => {
        await endBackstageMutation({
            variables: {
                sessionId,
            },
        })
            .then((res) => {
                if (!res) {
                    toast("Problem ending backstage!");
                    console.error(`Problem ending backstage!`);
                } else {
                    updateUnreadMessagesCount(sessionId, undefined, true);
                    updateNewQuestionsCount(true);
                    submittedVar(false);
                }
            })
            .catch((err) => {
                toast("Problem ending backstage!");
                console.error(`Problem ending backstage: ${err}`);
            });
    };

    async function confirmStartEventFromBackstage() {
        await stopShareScreen();
        setOpenConfirmDialog(false);
        orchestrator.startSessionFromBackstage(sessionId);
    }

    function cancelStartEventFromBackstage() {
        setOpenConfirmDialog(false);
    }

    return (
        <>
            <div className={`flex flex-items-center overflow-hidden pr-12 ${isSessionView ? "pt-8" : ""}`}>
                <PlayerSessionsLogo />

                <PlayerSessionTitle
                    name={childOfBreakoutRooms ? childOfBreakoutRooms.parentOfBreakoutRooms.name : name}
                    isAdmin={isAdmin}
                    isAssistant={isAssistant}
                    isLobby={isLobby}
                />

                {!isPreJoin && (
                    <>
                        {childOfBreakoutRooms ? (
                            <BreakoutMenu
                                roomName={props.session.name}
                                sessionId={sessionId}
                                parentName={childOfBreakoutRooms.parentOfBreakoutRooms.name}
                                config={childOfBreakoutRooms}
                                isAdmin={isAdmin}
                                isAssistant={isAssistant}
                                participantId={currentParticipantId}
                            />
                        ) : null}

                        {!isMobile && isSessionStarted && <PlayerSessionTimeElapsed session={props.session} />}
                    </>
                )}

                {props.session?.transcriptionActive && <PlayerSessionTranscript isMobile={isMobile} isPreJoin={isPreJoin} />}

                <RecordingIndicator
                    isMobile={isMobile}
                    participantId={currentParticipantId}
                    isPreJoin={isPreJoin}
                    recordingType={props.session?.recordingType}
                />

                {!isPreJoin && props.session.backstage && isOrganizer && isTimeToJoinBackstage ? (
                    <Button withMarginLeft size="small" onClick={handleStartEventFromBackstage} data-id="start-event-button">
                        {t("g.start_event") ?? "Start event"}
                    </Button>
                ) : null}

                {!isPreJoin && props.session.backstage && isOrganizer ? (
                    <Button
                        withMarginLeft
                        size="small"
                        variant="destructive-secondary"
                        onClick={endBackstage}
                        data-id="close-backstage-button"
                    >
                        {t("participant.leave_session.end_backstage_for_all") ?? "Close backstage"}
                    </Button>
                ) : null}
            </div>

            <div className={`flex flex-items-center pr-12 ${isSessionView ? "pt-8" : ""}`}>
                <div className="flex flex-items-center">
                    {isSessionView && (
                        <PlayerSessionControlStatus
                            currentParticipantId={currentParticipantId}
                            session={props.session}
                            isOrganizer={isOrganizer}
                            isMobile={isMobile}
                        />
                    )}

                    {isSessionView && !isMobile && <PlayerSessionViewMenu className={classes.sessionViewMenu} />}

                    {!isPreJoin && !isMobile && <PlayerSessionSettingsButton />}

                    {(isGuest && featureIsAvailable) || (isGuest && isMobile) ? null : (
                        <FeedbackButton
                            separator={!isSessionView && isGuest ? "right" : !isSessionView && isMobile && !isGuest ? "none" : undefined}
                            className={classes.feedbackButton}
                        />
                    )}
                </div>

                {!isSessionView && isGuest && !isMobile ? (
                    <div className={classes.guestButtons}>
                        <LanguageSelector isGuest={true} ownerLanguage={ownerLanguage ?? undefined} />
                        <Button data-id="guest-login" variant="tertiary" size="small" onClick={onLogin}>
                            {t("user.log_in") ?? "Log in"}
                        </Button>
                        {showSignUp && (
                            <Button data-id="guest-register" variant="primary" size="small" onClick={onRegister}>
                                {t("user.sign_up") ?? "Sign up"}
                            </Button>
                        )}
                    </div>
                ) : (
                    <UserProfileMenu isGuest={isGuest} className={classes.profileMenu} organizationPlan={organizationPlan ?? undefined} />
                )}
            </div>
            <ConfirmationDialog
                key="start-event-from-backstage-confirmation-dialog"
                title={t("g.start_event") ?? "Start event"}
                content={t("player.backstage.modal_description") ?? "Are you sure you want to leave backstage and start the event?"}
                cancelButton={t("g.cancel") ?? "Cancel"}
                minWidth
                submitButton={t("g.start_event") ?? "Start event"}
                onClose={cancelStartEventFromBackstage}
                open={openConfirmDialog}
                submitButtonVariant="primary"
                onConfirm={confirmStartEventFromBackstage}
                variant="warning"
            />
        </>
    );
}
