const facebookRegex =
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi;
export const linkedInRegex = /[(https:\/\/www\.linkedin.com)]{20}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)+/g;
const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/gi;
const slackRegex = /(?:(?:http|https):\/\/)?(?:www.)?([a-zA-Z0-9-]+).slack.com/g;
const twitterRegex = /(?:(?:http|https):\/\/)?(?:www.)?twitter.com\/([a-zA-Z0-9-/]+)/g;
const instagramRegex =
    /(?:https?:\/\/)?(?:www.)?instagram.com\/?([a-zA-Z0-9\.\_\-]+)?([p]+)?([reel]+)?([tv]+)?([stories]+)?\/([a-zA-Z0-9\-\_\.]+)\/??(([0-9]+)?\/?([a-zA-Z0-9\.\_\-]+)?([p]+)?([reel]+)?([tv]+)?([stories]+)?\/([a-zA-Z0-9\-\_\.]+)\/?([a-zA-Z0-9\-\_\.]+))?\/?/gi;

export const socialLinksRegex = {
    facebook: facebookRegex,
    linkedin: linkedInRegex,
    youtube: youtubeRegex,
    slack: slackRegex,
    twitter: twitterRegex,
    instagram: instagramRegex,
};
