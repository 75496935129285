export type VisibilityLocations = {
    grid: boolean;
    audience: boolean;
    spotlight: boolean;
    floatingSpeaker: boolean;
};

export type VisibilityMap = Record<string, VisibilityLocations | undefined>;

export function createDefaultVisibilityLocations(): VisibilityLocations {
    return {
        grid: false,
        audience: false,
        spotlight: false,
        floatingSpeaker: false,
    };
}

export function isAnyLocationVisible(locations?: VisibilityLocations) {
    if (locations == null) {
        return false;
    }
    return locations.audience || locations.grid || locations.spotlight || locations.floatingSpeaker;
}

export function updateVisibilityMapById(map: VisibilityMap, id: string, location: keyof VisibilityLocations, value: boolean) {
    const current = map[id];

    if (current == null && value === false) {
        return false;
    }

    if (current != null && current[location] === value) {
        return false;
    }

    const locations = {
        ...createDefaultVisibilityLocations(),
        ...current,
        [location]: value,
    };

    const isVisible = isAnyLocationVisible(locations);

    return {...map, [id]: isVisible ? locations : undefined};
}
