import React, {useEffect} from "@workhorse/api/rendering";

function isWithin169ratio(ratio: number) {
    return ratio > 1.75 && ratio < 1.79;
}

export function useVideoObjectFit(elem?: HTMLVideoElement | null) {
    useEffect(() => {
        if (!elem) {
            return;
        }

        const onElChange = (e: UIEvent) => {
            const el = e.currentTarget as HTMLVideoElement;
            const {videoHeight: h, videoWidth: w} = el;
            if (!w || !h) {
                return;
            }
            const ratio = w / h;
            let objectFit: "cover" | "contain" = "cover";
            if (ratio < 1 || !isWithin169ratio(ratio)) {
                objectFit = "contain";
            }
            el.style.objectFit = objectFit;
        };

        elem.addEventListener("resize", onElChange);
        elem.addEventListener("loadedmetadata", onElChange);

        return () => {
            elem.removeEventListener("resize", onElChange);
            elem.removeEventListener("loadedmetadata", onElChange);
        };
    }, [elem]);
}
