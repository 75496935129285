import VideoPlayButton from "@artifacts/video/ui/VideoPlayButton";
import {makeStyles} from "@material-ui/core";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {VideoJsPlayer} from "video.js";
import VREPlayer from "videojs-react-enhanced";
import {useReactiveVar} from "@workhorse/api/data";
import {basePlayer, setBasePlayerElapsed, setPlayStartTs} from "./genericVideoPlayerStateVars";

const useStyles = makeStyles((theme) => ({
    root: {
        "&.video-js": {
            width: "100%",
            height: "100%",
        },
        "&.video-js .vjs-tech": {
            objectFit: "cover",
            borderRadius: 12,
        },
        "&.video-controls-hidden": {
            opacity: 0,
        },
        "&.video-js .vjs-big-play-button": {
            display: "none",
        },
    },
}));

type GenericVideoPlayerProps = {
    src: string;
    muted?: boolean;
    controls?: boolean;
    autoplay?: VREPlayer.IPlayerOptions["autoplay"];
    className?: string;
    id?: string;

    /**
     * when rendering the same video in multiple places, they can all be sync'd
     * but only one (the first one that renders)
     * should have this prop=true
     */
    enableTimeTracking?: boolean;

    /**
     * if enableTimeTracking is enabled in one video
     * and another video is rendered with this prop's value === syncdVideoSrc
     * then this video will be sync'd with the other one
     */
    syncIfSrc?: string;

    // sometimes, a higher order functionality may need to wait for the video to actually start playing
    enablePlayStartTracking?: boolean;

    // start the video at a specific time
    startAt?: number | null;

    // enable play thumbnail button
    enablePlayButton?: boolean;

    // enables fullScreen
    enableFullscreen?: boolean;
};

function GenericVideoPlayer(props: GenericVideoPlayerProps) {
    const {
        src,
        muted = true,
        controls = false,
        className,
        id,
        enableTimeTracking,
        syncIfSrc,
        enablePlayStartTracking,
        startAt,
        enablePlayButton = false,
        enableFullscreen = false,
    } = props;
    const classes = useStyles();
    const syncInterval = useRef<any>(null);

    const [canPlay, setCanPlay] = useState(false);
    const [hidePlayButton, setHidePlayButton] = useState(false);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const isSyncd = syncIfSrc && syncIfSrc === src;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const syncTs = isSyncd ? useReactiveVar(setBasePlayerElapsed) : null;

    const playerOptions: VREPlayer.IPlayerOptions = {
        src,
        controls: controls,
        autoplay: undefined,
        muted: muted,
        loop: false,
    };

    const onReady = (player: VideoJsPlayer) => {
        playerRef.current = player;

        if (isSyncd) {
            if (syncTs !== null && syncTs > 0) {
                player.currentTime(syncTs);
            }
        } else {
            basePlayer(player);
            if (startAt) {
                player.currentTime(startAt);
            }
        }
    };

    const setSyncTs = () => {
        if (!playerRef.current) {
            clearInterval(syncInterval.current);
            return;
        }
        const elapsed = playerRef.current.currentTime();
        if (elapsed > 0) {
            setBasePlayerElapsed(elapsed);
        }
    };

    const syncFromOriginal = () => {
        if (!playerRef.current) {
            return;
        }
        const thisPlayerCurrentTime = playerRef.current.currentTime();
        const syncPlayerCurrentTime = setBasePlayerElapsed();
        if (syncPlayerCurrentTime === null) {
            return;
        }

        const diff = syncPlayerCurrentTime - thisPlayerCurrentTime;
        if (diff >= 0 && diff > 0.15) {
            playerRef.current.currentTime(syncPlayerCurrentTime);
        }
    };

    const onPlay = (e: any, player: VideoJsPlayer) => {
        if (enableTimeTracking) {
            setSyncTs();
            syncInterval.current = setInterval(() => setSyncTs(), 1000);
        }
        if (enablePlayStartTracking) {
            const now = new Date().getTime();
            setPlayStartTs(now);
        }
        if (enablePlayButton) {
            setHidePlayButton(true);
            const videoControls = document.querySelector(".vjs-control-bar");
            videoControls?.classList.remove("video-controls-hidden");
        }
    };

    const onPause = () => {
        if (enablePlayButton) {
            setHidePlayButton(false);
            if (!playerRef.current?.isFullscreen()) {
                const videoControls = document.querySelector(".vjs-control-bar");
                videoControls?.classList.add("video-controls-hidden");
            }
        }
    };

    const onSeeking = () => {
        if (enablePlayButton) {
            const videoControls = document.querySelector(".vjs-control-bar");
            if (videoControls?.classList.contains("video-controls-hidden")) {
                videoControls?.classList.remove("video-controls-hidden");
            }
        }
    };

    const onEnded = () => {
        if (enablePlayButton) {
            setHidePlayButton(false);
        }
        if (playerRef.current?.isFullscreen()) {
            playerRef.current?.exitFullscreen();
        }
    };

    useEffect(() => {
        const video = document.querySelector("video");
        const preventContextMenu = (event: Event) => {
            event.preventDefault();
        };
        video?.addEventListener("contextmenu", preventContextMenu);

        return () => {
            if (enableTimeTracking) {
                setBasePlayerElapsed(null);
                clearInterval(syncInterval.current);
                basePlayer(null);
            }
            video?.removeEventListener("contextmenu", preventContextMenu);
        };
    }, []);

    useEffect(() => {
        if (canPlay && playerRef.current) {
            playerRef.current.play();
        }
    }, [canPlay]);

    useEffect(() => {
        if (isSyncd) {
            if (!canPlay && syncTs !== null && typeof syncTs === "number") {
                setCanPlay(true);
            } else if (canPlay && syncTs !== null && typeof syncTs === "number") {
                syncFromOriginal();
            }
        }
    }, [syncTs]);

    useEffect(() => {
        if (!enableFullscreen || !playerRef.current) {
            return;
        }

        const body = document.body;
        const fullScreenListener = () => {
            if (!playerRef.current) {
                return;
            }
            if (playerRef.current.isFullscreen()) {
                return body && body.classList.add("hide-root");
            } else {
                if (body && body.classList.contains("hide-root")) {
                    return body.classList.remove("hide-root");
                }
            }
        };

        playerRef.current.on("fullscreenchange", fullScreenListener);

        return () => {
            if (!playerRef.current) {
                return;
            }
            playerRef.current.off("fullscreenchange", fullScreenListener);
            if (body && body.classList.contains("hide-root")) {
                return body.classList.remove("hide-root");
            }
        };
    }, []);

    const onClickPlay = () => {
        playerRef.current?.play();
    };

    return (
        <>
            <VREPlayer
                playerOptions={playerOptions}
                onReady={onReady}
                onPause={onPause}
                onPlay={onPlay}
                onEnded={onEnded}
                onSeeking={onSeeking}
                // wrapperClassName={cls(classes.root, className)}
                // videoElementClassName={cls(classes.root)}
                data-id={id}
            />
            {enablePlayButton && !hidePlayButton ? <VideoPlayButton canClick={true} onClick={onClickPlay} /> : null}
        </>
    );
}

export default GenericVideoPlayer;
