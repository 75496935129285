import {getContactByEmail} from "@common/utils/contacts";
import {ContactInfoFragment, Participant, ParticipantStatus, SessionLifecycle} from "@generated/data";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import Checkbox from "@ui/cdk/Checkbox";
import {InputProps} from "@ui/cdk/Input";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import List from "@ui/core/components/List";
import {useState} from "@workhorse/api/rendering";
import {WithClasses, WithMobileState} from "@workhorse/declarations";
import SyncEmailNotification from "@workhorse/pages/user/profile/UserAccounts/SyncEmailNotification";
import {useDesignerIsEditing} from "@workhorse/providers/DesignerSessionDataProviders";
import {useTranslation} from "react-i18next";
import {ReactComponent as AddParticipantsIcon} from "../../../assets/media/add-participants.svg";
import ContactFetcher, {ContactFetcherApi} from "./ContactFetcher";
import ParticipantRenderer from "./ParticipantRenderer";
import {AssistantsStatus} from "./ParticipantsDialog";
import PermissionForm from "./PermissionsForm";
import classes from "./styles/ParticipantsDialog.module.scss";

type ParticipantsDialogContentProps = WithClasses<"contactFetcher"> &
    WithMobileState & {
        contactFetcherApi: ContactFetcherApi;
        handleAddParticipantWithoutContactToList: (value: string) => void;
        handleAddContactsToList: (value: ContactInfoFragment[]) => void;
        currentEmailRef: React.MutableRefObject<string>;
        actualContacts: string[];
        error: boolean;
        setError: (value: boolean) => void;
        contactFetcherInputLabel?: InputProps["label"];
        isSessionDialog?: boolean;
        participants: Partial<Participant>[];
        contacts: ContactInfoFragment[];
        emailsOnly: string[];
        handleRemoveParticipant: (value: string) => void;
        sessionId: string;
        liveSessionId?: string;
        sessionLifecycle?: SessionLifecycle | null;
        assistants: AssistantsStatus;
        minimized?: boolean;
        toggleParticipantsMinimizer?: () => void;
        placeholder?: string;
        isAssistant?: boolean;
        inLobby?: boolean;
        hideMakeAssistant?: boolean;
        handleToggleAssistantRole: (participantId: string) => void;
        isCreateDialog?: boolean;
        isEventGuest?: boolean;
        isFetchingUserNames?: boolean;
        hideICSParticipantsList?: boolean;
        handleToggleICSParticipantsList?: () => void;
        isInstantSession?: boolean;
    };

function ParticipantsDialogContent(props: ParticipantsDialogContentProps) {
    const {t} = useTranslation();
    const {
        handleAddParticipantWithoutContactToList,
        handleAddContactsToList,
        currentEmailRef,
        actualContacts,
        error,
        setError,
        contactFetcherInputLabel,
        isSessionDialog,
        participants,
        contacts,
        emailsOnly,
        handleRemoveParticipant,
        sessionId,
        liveSessionId,
        sessionLifecycle,
        minimized = false,
        toggleParticipantsMinimizer,
        isAssistant,
        handleToggleAssistantRole,
        inLobby,
        assistants,
        isCreateDialog,
        hideMakeAssistant,
        isEventGuest,
        isFetchingUserNames,
    } = props;

    let guestIndex = 0;
    const [isEditMode] = useDesignerIsEditing();

    const [isContactSearchOpen, setIsContactSearchOpen] = useState(false);
    const totalInvitedCount = participants.length;

    return (
        <div
            className={cls("flex flex11-auto flex-col", classes.addParticipantDialogContent, isContactSearchOpen && classes.withSearchOpen)}
            key="dialog-content"
        >
            <ContactFetcher
                api={props.contactFetcherApi}
                label={contactFetcherInputLabel}
                placeholder={props.placeholder}
                onSubmit={(contacts, emails) => {
                    handleAddContactsToList(contacts);
                    emails.map((email) => handleAddParticipantWithoutContactToList(email));
                }}
                classes={{
                    root: classes.contactFetcherRoot,
                }}
                tagIcon={<SendRoundedIcon />}
                isFetchingUserNames={isFetchingUserNames}
            />

            {props.hideICSParticipantsList !== undefined && props.handleToggleICSParticipantsList && !props.isInstantSession && (
                <div className="flex flex-align-center mb-20">
                    <Checkbox checked={!props.hideICSParticipantsList} onChange={props.handleToggleICSParticipantsList} />
                    <Typography fontWeight="bold" color="secondary" className="ml-6">
                        {t("participant.dialog.show_guest_list_in_email")}
                    </Typography>
                </div>
            )}

            <div className={classes.extendedField}>
                <div className={classes.extendLine} />
                <div>
                    <div className={cls("flex flex-col flex11-auto", classes.participantsList)} key="participant-list">
                        {/* {isFetchingUserNames ? (
                            <div className={cls("flex relative", classes.loading)}>
                                <Loading message="Loading..." />
                            </div>
                        ) : null} */}
                        {totalInvitedCount === 0 ? (
                            <div className={classes.noParticipants}>
                                <AddParticipantsIcon />
                                <Typography variant="base" color="quaternary">
                                    {t("participant.dialog.placeholder")}
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <div className={classes.addedParticipantsTitle}>{t("participant.dialog.invited_participants")}</div>

                                <div className={classes.divider} />

                                <List className={"flex flex-col flex11-auto actual-list"}>
                                    {participants
                                        .filter((p) => p.status !== ParticipantStatus.Kicked)
                                        .map((x, index) => {
                                            const contactExists = !!getContactByEmail(x.dataWithNullableEmail?.email);

                                            const isAssistantClientOnly = assistants.find((p) => p.id === x.id)?.isAssistant;
                                            if (!contactExists && x.dataWithNullableEmail?.isGuest) {
                                                ++guestIndex;
                                            }

                                            return (
                                                <ParticipantRenderer
                                                    key={`participant-${index}`}
                                                    participant={x}
                                                    handleRemoveParticipant={handleRemoveParticipant}
                                                    guestIndex={
                                                        !contactExists &&
                                                        x.dataWithNullableEmail?.isGuest &&
                                                        !x.dataWithNullableEmail?.email
                                                            ? guestIndex
                                                            : 0
                                                    }
                                                    hideMakeAssistant={hideMakeAssistant}
                                                    sessionId={sessionId}
                                                    isAssistantClientOnly={isAssistantClientOnly}
                                                    liveSessionId={liveSessionId}
                                                    handleToggleAssistantRole={handleToggleAssistantRole}
                                                    contactExists={contactExists}
                                                    isEditMode={isEditMode}
                                                    showSilhouetteAvatar
                                                    isEventGuest={isEventGuest}
                                                />
                                            );
                                        })}
                                    {/* {contacts.map((x, index) => (
                                            <ParticipantRenderer
                                                key={`contact-${index}`}
                                                contact={x}
                                                handleRemoveParticipant={handleRemoveParticipant}
                                                guestIndex={guestIndex}
                                                sessionId={sessionId}
                                                liveSessionId={liveSessionId}
                                                isAssistantClientOnly={assistants.find((p) => p.id === x.id)?.isAssistant}
                                                handleToggleAssistantRole={handleToggleAssistantRole}
                                                contactExists={true}
                                                sessionView={props.sessionView}
                                            />
                                        ))} */}
                                    {/* {emailsOnly.map((x, index) => {
                                            const contactExists = !!getContactByEmail(x);
                                            if (!contactExists) {
                                                ++guestIndex;
                                            }
                                            return (
                                                <ParticipantRenderer
                                                    key={`emails-only-${index}`}
                                                    email={x}
                                                    handleRemoveParticipant={handleRemoveParticipant}
                                                    guestIndex={!contactExists ? guestIndex : 0}
                                                    sessionId={sessionId}
                                                    liveSessionId={liveSessionId}
                                                    isAssistantClientOnly={false}
                                                    handleToggleAssistantRole={handleToggleAssistantRole}
                                                    contactExists={contactExists}
                                                    sessionView={props.sessionView}
                                                />
                                            );
                                        })} */}
                                </List>
                            </div>
                        )}
                    </div>
                    <div>
                        {!isCreateDialog && <div className={classes.divider} />}

                        <PermissionForm
                            sessionLifecycle={sessionLifecycle}
                            isAssistant={isAssistant}
                            sessionId={sessionId}
                            liveSessionId={liveSessionId}
                            isCreateDialog={isCreateDialog}
                        />
                    </div>

                    <div>
                        <SyncEmailNotification isBooking={false} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ParticipantsDialogContent;
