import {useCallback, useEffect, useRef} from "react";
import {LocalAudioTrack, LocalTrackPublication, LocalVideoTrack, Room, RoomEvent, Track, TrackPublication} from "livekit-client";
import {waitForConnected} from "./ConfereceRoomUtils";
import {createRoomLogger} from "./ConferenceRoomLogger";

function toggleTrack(track: LocalAudioTrack | LocalVideoTrack, enabled: boolean) {
    if (enabled) {
        return track.unmute();
    } else {
        return track.mute();
    }
}

export function useLocalTrackManager<T extends LocalAudioTrack | LocalVideoTrack>(
    room: Room | null,
    source: Track.Source,
    logger: ReturnType<typeof createRoomLogger>,
    publishToRoom: (room: Room, stream: MediaStream, track: T) => Promise<LocalTrackPublication>,
    createTrack: (stream: MediaStream) => T | null,
    getMediaStreamTrack: (stream: MediaStream) => MediaStreamTrack | null,
    handleMute = false
) {
    const trackRef = useRef<T | null>(null);
    const enabledRef = useRef<boolean>(false);

    const resetRef = useCallback(() => {
        const track = trackRef.current;
        trackRef.current = null;
        return track;
    }, []);

    const getTrack = useCallback(() => {
        const publication = room?.localParticipant.getTrackPublication(source);
        const track = publication?.track as T | undefined;
        return track ?? null;
    }, [room, source]);

    const setEnabled = useCallback(
        async (enabled) => {
            const action = enabled ? "unmute" : "mute";
            try {
                logger.info(`Attempting to ${action} local ${source} track`);

                enabledRef.current = enabled;
                const track = getTrack();

                if (!track) {
                    logger.info(`Track missing won't ${action} ${source}, will be done at publish`);
                    return;
                }

                await toggleTrack(track, enabled);

                logger.info(`Successfully ${action} local ${source} track`);
            } catch (e) {
                logger.error(`Failed to ${action} local ${source} track`, e);
            }
        },
        [source, logger, getTrack]
    );

    const unpublishTrack = useCallback(
        async (forceTrack?: T) => {
            try {
                const track = forceTrack ?? getTrack();
                trackRef.current = null;

                if (!track) {
                    return;
                }

                logger.info(`Attempting to unpublish ${source} stream`);

                if (!room) {
                    logger.info(`Cannot unpublish ${source}, room missing, possibly because not joined yet. Non issue.`);
                    return;
                }

                await room.localParticipant.unpublishTrack(track, false);

                logger.info(`Successfully unpublished ${source} stream`);
            } catch (e) {
                logger.error(`Failed to unpublish ${source} stream`, e);
            }
        },
        [room, source, logger, getTrack]
    );

    const replaceTrack = useCallback(
        async (track: T, stream: MediaStream) => {
            try {
                logger.info(`Attempting to replace ${source} stream in existing publication`);

                const mediaStreamTrack = getMediaStreamTrack(stream);

                if (!mediaStreamTrack) {
                    logger.info(`Cannot replace ${source} stream, no ${source} tracks`);
                    unpublishTrack();
                    return;
                }

                await track.replaceTrack(mediaStreamTrack);

                logger.info(`Successfully replaced ${source} stream in existing publication`);
            } catch (e) {
                logger.error(`Failed to replace ${source} stream in existing publication, trying full publish`, e);
                await publishTrack(stream);
            }
        },
        [source, logger, getMediaStreamTrack, unpublishTrack]
    );

    const publishTrack = useCallback(
        async (stream: MediaStream) => {
            try {
                logger.info(`Attempting to publish ${source} stream`);

                if (!room) {
                    logger.info(`Cannot publish ${source}, room missing, possibly because not joined yet`);
                    return;
                }

                logger.info("Waiting for room to be connected");
                await waitForConnected(room);

                await unpublishTrack();

                const track = createTrack(stream);
                trackRef.current = track;

                if (!track) {
                    const mediaStreamTrack = getMediaStreamTrack(stream);
                    const hasMediaStreamTrack = mediaStreamTrack != null;
                    logger.info(`Cannot publish ${source}, track is null,`, hasMediaStreamTrack ? `has tracks` : `no tracks`);
                    return;
                }

                logger.info("Waiting for room to be connected");
                await waitForConnected(room);

                logger.info(`Room connected, attempting to publish ${source} stream`);
                await publishToRoom(room, stream, track);

                if (handleMute) {
                    const enabled = enabledRef.current;
                    logger.info(`${source} stream published, attempting to toggle`, enabled ? "unmute" : "mute");
                    await toggleTrack(track, enabled);
                }

                logger.info(`Successfully published ${source} stream`);
            } catch (e) {
                logger.error(`Failed to publish ${source} stream`, e);
                if (e instanceof Error) {
                    return e;
                }
            }
        },
        [room, source, logger, handleMute, publishToRoom, createTrack, getMediaStreamTrack, unpublishTrack]
    );

    const publishOrReplaceTrack = useCallback(
        (stream: MediaStream) => {
            const track = getTrack();

            if (!track) {
                return publishTrack(stream);
            }

            return replaceTrack(track, stream);
        },
        [getTrack, replaceTrack, publishTrack]
    );

    useEffect(() => {
        if (!room) {
            return;
        }

        const checkPublish = (pub: LocalTrackPublication) => {
            const currentTrack = trackRef.current;

            if (currentTrack === pub.track) {
                logger.info(`Local ${source} track publish event emitted.`);
                return;
            }

            if (pub.source === source) {
                logger.info(`Local ${source} track publish event emitted, but track is different from ours. Will reuse.`);
                trackRef.current = pub.track as T;
            }
        };

        const checkUnpublished = (pub: LocalTrackPublication) => {
            const currentTrack = trackRef.current;

            if (pub.track !== currentTrack) {
                return;
            }

            logger.warn(`Local ${source} track unexpected unpublish, will attempt to publish again.`);

            const stream = currentTrack.mediaStream;
            trackRef.current = null;

            if (!stream) {
                logger.warn(`Local ${source} track stream missing, cannot publish again.`);
                return;
            }

            publishTrack(stream);
        };

        room.on(RoomEvent.LocalTrackPublished, checkPublish);
        room.on(RoomEvent.LocalTrackUnpublished, checkUnpublished);

        return () => {
            room.off(RoomEvent.LocalTrackUnpublished, checkUnpublished);
            room.off(RoomEvent.LocalTrackPublished, checkPublish);
        };
    }, [room, logger, source, publishTrack]);

    return {
        resetRef,
        setEnabled,
        unpublishTrack,
        publishOrReplaceTrack,
    };
}
