import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import apollo from "@workhorse/api/apollo";
import {AuthService} from "@workhorse/api/authService";
import {useReactiveVar} from "@workhorse/api/data";
import designer from "@workhorse/api/designer";
import React, {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import toast from "@workhorse/api/toast";
import {Action, actionProfile, enableEventLeaveModal, routeNavigation} from "@workhorse/components/header/headerUtils";
import {ReactComponent as BaggageIcon} from "../../../../assets/media/baggage.svg";
import ModalFooter from "./ModalFooter";
import classes from "./styles/LeaveSessionModal.module.scss";
import UserView from "./UserView";
import {DiscardEventChangesDocument} from "@generated/data";
import {useQuery} from "@workhorse/dataApi";
import {discardingChanges} from "../eventVars";
import {orchestrator} from "@workhorse/providers/state";
import {paymentConfigState, upsertPaymentConfigs, usePaymentsHasUpdates} from "../event-payments/upsertPaymentConfigs";
import {useSession} from "@workhorse/providers/SessionDataProviders";

type LeaveEventModalProps = {
    sessionId: string;
    shouldShowPopup: boolean;
    shouldStartEvent?: boolean;
};

export default function LeaveEventModal(props: LeaveEventModalProps) {
    const history = useHistory();
    const {sessionId, shouldShowPopup: shouldShowPopupProp, shouldStartEvent} = props;
    const session = useSession();

    const {data} = useQuery("DesignerSessionChangedByDocument");
    // save
    // doNothing
    // discard
    const [popupAction, setPopupAction] = useState<string>("save");
    const isModalEnabled = useReactiveVar(enableEventLeaveModal);
    const paymentsUpdates = usePaymentsHasUpdates();

    const shouldShowPopup = shouldShowPopupProp && (!!data?.designerSessionChangedBy?.changedBy?.length || paymentsUpdates);

    const enableModal = shouldShowPopup && isModalEnabled;

    const currentProfileAction = useReactiveVar(actionProfile);
    const routeToGoTo = useReactiveVar(routeNavigation);
    const dialogImperativeRef = useRef<DialogImperativeRef>();
    const authService = AuthService.getInstance();

    const leaveSessionFromProfile = () => {
        const action = actionProfile();

        if (!action) {
            return;
        }

        if (action === Action.logout) {
            localStorage.clear();
            sessionStorage.clear();
            authService.logout();
        }

        if (action === Action.manageAccount) {
            history.push("/user/settings");
            enableEventLeaveModal(false);
        }

        actionProfile(undefined);
    };

    useEffect(() => {
        if (isModalEnabled && !shouldShowPopup) {
            if (routeToGoTo) {
                enableEventLeaveModal(false);
                routeNavigation(undefined);
                history.push({
                    pathname: routeToGoTo,
                });
                return;
            }
            if (currentProfileAction) {
                leaveSessionFromProfile();
                return;
            }
            history.push({
                pathname: "/",
            });
        }
    }, [isModalEnabled, shouldShowPopup, routeToGoTo, currentProfileAction, history]);

    const saveEvent = async () => {
        const profileAction = actionProfile();
        await designer
            .commit({
                persistEvent: true,
                releaseLock: true,
                source: "event-save",
            })
            .then(() => {
                if (profileAction) {
                    leaveSessionFromProfile();
                    return;
                }

                if (routeToGoTo) {
                    routeNavigation(undefined);
                    enableEventLeaveModal(false);
                    history.push({
                        pathname: routeToGoTo,
                    });
                    return;
                }
                if (!shouldStartEvent) {
                    history.push("/");
                }
                console.log("save event", session.event?.id);
            })
            .catch((err) => {
                toast("Problem saving changes!");
                console.error(`Problem saving changes: ${err}`);
            });
    };

    const joinEvent = () => {
        designer.state.initializeOrResetState(null);
        designer.clearPersistentStorage();
        designer.clearSessionStorage();
        designer.clearActionsHistory();
        window.location.href = `/session/${sessionId}`;
    };

    const startEvent = async () => {
        await orchestrator.startSession(sessionId);
        joinEvent();
    };

    const toggleDialog = () => {
        if (dialogImperativeRef.current?.toggle) {
            dialogImperativeRef.current.toggle();
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPopupAction(event.target.value);
    };

    const handleEndSession = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const profileAction = actionProfile();

        if (popupAction === "save") {
            await saveEvent();

            if (session.event?.id) {
                await upsertPaymentConfigs(session.event?.id);
                paymentConfigState(null);
            }

            if (shouldStartEvent) {
                await startEvent();
            }
        }

        if (popupAction === "doNothing") {
            if (profileAction) {
                leaveSessionFromProfile();
                return;
            }

            if (routeToGoTo) {
                routeNavigation(undefined);
                enableEventLeaveModal(false);
                history.push({
                    pathname: routeToGoTo,
                });
                return;
            }
            if (!shouldStartEvent) {
                history.push("/");
            }
        }

        if (popupAction === "discard") {
            discardingChanges(true);
            paymentConfigState(null);
            await apollo.client.mutate({
                mutation: DiscardEventChangesDocument,
                variables: {
                    id: sessionId,
                },
            });
            if (shouldStartEvent) {
                await startEvent();
            }
            if (profileAction) {
                leaveSessionFromProfile();
                return;
            }

            if (routeToGoTo) {
                routeNavigation(undefined);
                enableEventLeaveModal(false);
                history.push({
                    pathname: routeToGoTo,
                });
                return;
            }

            routeNavigation(undefined);
            enableEventLeaveModal(false);

            if (!shouldStartEvent) {
                history.push("/");
            }
            setTimeout(() => {
                discardingChanges(false);
            }, 1000);
        }

        closeDialog();

        event.stopPropagation();
    };

    const closeDialog = () => {
        enableEventLeaveModal(false);
        actionProfile(undefined);
        toggleDialog();
    };

    useEffect(() => {
        if (enableModal) {
            toggleDialog();
        }
    }, [enableModal]);

    return (
        <Dialog
            onClose={closeDialog}
            className={classes.root}
            PaperProps={{
                className: cls(classes.paper),
            }}
            BackdropProps={{
                classes: {
                    root: "standard-backdrop",
                },
            }}
            imperativeRef={dialogImperativeRef}
            key="dialog-modal"
        >
            <Typography variant="xl3" className={cls("flex flex-items-center", classes.title)}>
                <BaggageIcon className={classes.titleIcon} />
                {shouldStartEvent ? "Start event" : "Leave event"}
            </Typography>

            <UserView handleRadioChange={handleRadioChange} />

            <ModalFooter
                closeDialog={closeDialog}
                handleEndSession={handleEndSession}
                className={cls("flex-items-center", classes.footer)}
                shouldStartEvent={shouldStartEvent}
            />
        </Dialog>
    );
}
