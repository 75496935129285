import ReactDOM from "react-dom/client";
import App from "./App";
import {handleSessionsWorker as registerSessionsWorker} from "./serviceWorker";
import "./logRocket";
// import "./requestIdleCallback";
import {setupTimerWorker} from "./timerWorker";
import {setupEventUnlockerWorker} from "./eventUnlockWorker";
import {handleDesktopAuth} from "./api/authService";
import "./style/app.scss";
import "./i18n";

// setupLogging();
setupTimerWorker();
setupEventUnlockerWorker();
handleDesktopAuth();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>,
);

registerSessionsWorker();
