import {useEffect} from "@workhorse/api/rendering";
import {readQuery} from "@workhorse/dataApi";

type KeyboardEventsProps = {
    selected: string[];
    hiddenInputRef: React.MutableRefObject<HTMLInputElement | null>;
    createNewAgendaItem: () => void;
    duplicate: () => void;
    addTool: () => void;
    bulkDelete: () => void;
    insertAbove: () => void;
    insertBelow: () => void;
    removeTool: () => void;
};

const KeyboardEvents = (props: KeyboardEventsProps) => {
    const {hiddenInputRef, selected, createNewAgendaItem, addTool, bulkDelete, duplicate, insertAbove, insertBelow, removeTool} = props;

    const checkForModalsOpen = () => {
        const confirmationDialogs = document.querySelectorAll(`[data-open="true"]`);
        return !!confirmationDialogs.length;
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        const areOtherModalsOpen = checkForModalsOpen();

        // Disabled Undo / Redo
        // if (((!macOS && event.ctrlKey && event.altKey) || (macOS && event.metaKey && event.altKey)) && !areOtherModalsOpen) {
        //     if (event.code === "KeyZ") {
        //         undo();
        //         event.preventDefault();
        //     } else if (event.code === "KeyY") {
        //         redo();
        //         event.preventDefault();
        //     }
        // }

        if (event.altKey && !areOtherModalsOpen) {
            if (event.code === "KeyW") {
                if (hiddenInputRef && hiddenInputRef.current) {
                    hiddenInputRef.current.focus();
                }

                const commitInProgress = readQuery("DesignerCommitStateDocument")?.designerCommitState?.commitInProgress;
                if (commitInProgress) {
                    return;
                }
                createNewAgendaItem();
                event.preventDefault();
            }
        }

        if (event.altKey && !areOtherModalsOpen && selected.length >= 1) {
            if (hiddenInputRef && hiddenInputRef.current) {
                hiddenInputRef.current.focus();
            }

            if (event.code === "KeyC") {
                duplicate();
                event.preventDefault();
            } else if (event.code === "KeyG") {
                addTool();
                event.preventDefault();
            } else if (event.code === "KeyX") {
                bulkDelete();
                event.preventDefault();
            } else if (event.code === "KeyQ") {
                insertAbove();
                event.preventDefault();
            } else if (event.code === "KeyT") {
                insertBelow();
                event.preventDefault();
            } else if (event.code === "KeyH") {
                removeTool();
                event.preventDefault();
            }
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", handleKeyDown);

        return () => {
            document.body.removeEventListener("keydown", handleKeyDown);
        };
    }, [selected]);

    return null;
};

export default KeyboardEvents;
