import {buildAgendaItemCreatePayload} from "@sessions/common/diff/payloads/buildAgendaItemCreatePayload";
import {OmitTypenames} from "@sessions/common/diff/types";
import {SessionCreateInput} from "@generated/data";
import {readRemoteUser} from "@workhorse/api/user";
import {ArrayItem} from "@workhorse/declarations";
import {DesignerApiSession as Session, MacroArtifact} from "@workhorse/declarations/dataTypes";

export default function buildCreateSessionPayload(input: OmitTypenames<Session>): SessionCreateInput {
    const {user} = readRemoteUser()?.getRemoteUser! ?? {};

    const {agendaItems, macroArtifacts, session, participants} = input!;

    const {
        conferenceStatus,
        rbac,
        status,
        id: participantId,
        inviteStatus,
        isApproved,
        isOwner,
        muted,
        submittedPasscode,
    } = participants[0]! ?? {};

    const {
        id,
        name,
        sendEmailsAutomatically,
        description,
        createdFromTemplateId,
        enableReactionsType,
        endByWorker,
        lifecycle,
        order,
        startAt,
        plannedEnd,
        quickSession,
        source,
        timeDependency,
        timeZone,
        lobbyPicture,
        questionsContainer,
        polls,
        recurrenceData,
        lobbyAccess,
        showAgendaInLobby,
        allowCollaborationInLobby,
        requestPermissionToJoin,
        requireConsentToRecord,
        askForGuestEmail,
        allowCameraType,
        allowMicrophoneType,
        allowScreenShareType,
        memoryAccessType,
        allowAgendaType,
        hideNonStreamingParticipants,
        reminderInMinutes,
        syncToAgenda,
        autoRecording,
        autoTranscribing,
        onboardingType,
        event,
        transcriptionActive,
        room,
        reminders,
        messageReminders,
        disabledNotifications,
        autoStartSession,
        sendEndSessionEmail,
        fullSizeVideosInRecording,
        recordingConferenceView,
        recordingPresentationView,
        passcodeToJoin,
        provideSessionPasscode,
        bypassSessionPasscode,
        groupChatAccess,
        privateChatAccess,
        participantsMacroAccess,
        exitPageOfferId,
        collectFeedback,
        showExitPageCta,
        hideIcsGuestList,
        livestreamOutputUrls,
        recordingType,
        sessionTags,
        isTask,
        feedbackFormId,
        restrictedWidgets,
    } = session! ?? {};

    return {
        id,
        name,
        sendEmailsAutomatically,
        description,
        enableReactionsType,
        syncToAgenda,
        endByWorker,
        lifecycle,
        order,
        startAt,
        plannedEnd,
        quickSession,
        source,
        timeDependency,
        timeZone,
        lobbyAccess,
        showAgendaInLobby,
        allowCollaborationInLobby,
        requestPermissionToJoin,
        requireConsentToRecord,
        askForGuestEmail,
        allowCameraType,
        allowMicrophoneType,
        allowScreenShareType,
        memoryAccessType,
        allowAgendaType,
        hideNonStreamingParticipants,
        reminderInMinutes,
        autoRecording,
        autoTranscribing,
        onboardingType,
        transcriptionActive,
        reminders,
        messageReminders,
        disabledNotifications,
        autoStartSession,
        sendEndSessionEmail,
        fullSizeVideosInRecording,
        recordingConferenceView,
        recordingPresentationView,
        passcodeToJoin,
        provideSessionPasscode,
        bypassSessionPasscode,
        groupChatAccess,
        privateChatAccess,
        participantsMacroAccess,
        collectFeedback,
        showExitPageCta,
        hideIcsGuestList,
        livestreamOutputUrls,
        recordingType,
        isTask,
        restrictedWidgets,
        feedbackForm: feedbackFormId
            ? {
                  connect: {
                      id: feedbackFormId,
                  },
              }
            : undefined,
        exitPageOffer: exitPageOfferId
            ? {
                  connect: {
                      id: exitPageOfferId,
                  },
              }
            : undefined,
        event: event
            ? {
                  create: {
                      id: event.id,
                      slug: event.slug,
                      state: event.state,
                      bannerUrl: event.bannerUrl,
                      colorPalette: event.colorPalette,
                      photoUrl: event.photoUrl,
                      socialLinks: event.socialLinks,
                      isPublic: event.isPublic,
                      registrationForm: event.registrationForm,
                      utm: event.utm,
                      hasRegistration: event.hasRegistration,
                      speakerOrderJson: event.speakerOrderJson,
                      embedUrl: event.embedUrl,
                      afterRegistrationRedirectUrl: event.afterRegistrationRedirectUrl,
                      afterRegistrationOffer: event?.afterRegistrationOfferId
                          ? {
                                connect: {
                                    id: event.afterRegistrationOfferId,
                                },
                            }
                          : undefined,
                      maxSeats: event.maxSeats,
                      seatsTaken: event.seatsTaken,
                      payments: event.payments.length
                          ? {
                                create: event.payments.map((config) => {
                                    return {
                                        id: config.id,
                                        ownerId: user?.id ?? "",
                                        amount: config.amount,
                                        currency: config.currency,
                                        terms: config.terms,
                                        enabled: config.enabled,
                                        providers: {
                                            connect: config.paymentProviders.map((item) => ({
                                                id: item!.id.replace("provider-", ""),
                                            })),
                                        },
                                    };
                                }),
                            }
                          : undefined,
                  },
              }
            : undefined,
        agendaItems: agendaItems?.length
            ? {
                  create: agendaItems.filter((a) => !a.isDeleted).map(buildAgendaItemCreatePayload),
              }
            : undefined,

        macroArtifacts: macroArtifacts?.length
            ? {
                  create: macroArtifacts.map(buildMacroArtifactCreatePayload),
              }
            : undefined,

        createdFromTemplate: createdFromTemplateId ? {connect: {id: createdFromTemplateId}} : undefined,

        questionsContainer: questionsContainer
            ? {
                  create: {
                      id: questionsContainer.id,
                  },
              }
            : undefined,
        participants: {
            create: [
                {
                    conferenceStatus,
                    rbac,
                    status: status,
                    id: participantId,
                    inviteStatus,
                    isApproved,
                    isOwner,
                    muted,
                    invitedByTheOwner: true,
                    submittedPasscode,

                    user: {
                        connect: {
                            email: user!.email,
                        },
                    },
                },
            ],
        },
        recurrenceData: recurrenceData?.id
            ? {
                  create: {
                      id: recurrenceData.id,
                      // dtStart must have the exact time of day as the session startAt,
                      // otherwise creating an occurrence for it will fail

                      dtStart: startAt ?? recurrenceData.dtStart,
                      frequency: recurrenceData.frequency,
                      interval: recurrenceData.interval ? recurrenceData.interval : undefined,
                      byDay: recurrenceData.byDay && recurrenceData.byDay.length > 0 ? {set: recurrenceData.byDay} : undefined,
                      bySetPos: recurrenceData.bySetPos && recurrenceData.bySetPos > 0 ? recurrenceData.bySetPos : undefined,
                      byMonthDay:
                          recurrenceData.byMonthDay && recurrenceData.byMonthDay.length > 0 ? {set: recurrenceData.byMonthDay} : undefined,
                      byMonth: recurrenceData.byMonth && recurrenceData.byMonth.length > 0 ? {set: recurrenceData.byMonth} : undefined,
                      count: recurrenceData.count ? recurrenceData.count : undefined,
                      until: recurrenceData.until ? recurrenceData.until : undefined,
                  },
              }
            : undefined,
        room: room
            ? {
                  create: {
                      id: room.id,
                      slug: room.slug,
                      active: room.active ?? true,
                  },
              }
            : undefined,
        sessionTags: sessionTags?.length
            ? {
                  connect: sessionTags.map((tag) => {
                      return {id: tag.id};
                  }),
              }
            : undefined,
    };
}

function buildMacroArtifactCreatePayload(
    artifact: NonNullable<MacroArtifact>
): NonNullable<ArrayItem<NonNullable<SessionCreateInput["macroArtifacts"]>["create"]>> {
    const {id, artifactId, name, description, isMacro, isConfigured} = artifact;
    return {
        id,
        artifactId,
        name,
        description,
        isMacro,
        isConfigured,
        properties: artifact.properties
            ? {
                  create: artifact.properties,
              }
            : undefined,
    };
}
