import React, {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import TimerRoundedIcon from "@material-ui/icons/TimerRounded";
import {makeStyles} from "@material-ui/core";
import {cls} from "@ui/cdk/util/util";
import browserInfo from "@workhorse/api/BrowserInfo";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    icon: {
        color: theme.main.palette.text.senary,
        fontSize: theme.typography.pxToRem(20),
        position: "absolute",
        left: 12,
        top: "50%",
        transform: "translateY(-50%)",
    },
    input: {
        "-webkit-appearance": "none",
    },
    inputWithIcon: {
        paddingLeft: "40px!important",
        width: "100%",
    },
}));

export const isFunction = (fn) => Object.prototype.toString.call(fn) === "[object Function]";

export const isNumber = (n) => Object.prototype.toString.call(n) === "[object Number]";

const isSafari = browserInfo.isSafari();

type DurationInputMaskProps = {
    autoFocus: boolean;
    defaultValue?: number | string;
    maskDelay?: number;
    handleBlur?: Function;
    handleSubmit?: Function;
    handleChange?: Function;
    onKeyDown?: Function;
    onKeyUp?: Function;
    value: number | string;
    durationHasChanged?: Function;
    maxValue?: number;
    allowSeconds?: boolean;
    showIcon?: boolean;
    short?: boolean;
    formattedClassName?: string;
    autoSize?: boolean;
} & React.InputHTMLAttributes<any>;

const displayValue = (value: (string | number) & (string | number | readonly string[])) => {
    if (typeof value === "string") {
        return value.replace("m", " min");
    } else {
        return value;
    }
};

const displayFormattedValue = (value: (string | number) & (string | number | readonly string[]), formattedClassName: string) => {
    let formattedValue = value;

    if (typeof formattedValue === "string") {
        formattedValue = formattedValue
            .replace("h", "<span class='light'>h</span>")
            .replace("d", "<span class='light'>d</span>")
            .replace("m", "<span class='light'>m</span>");
        return <div className={formattedClassName} dangerouslySetInnerHTML={{__html: formattedValue}}></div>;
    }
    return value;
};

const DurationInputMask = (props: DurationInputMaskProps) => {
    const {t} = useTranslation();
    const {
        autoFocus = false,
        defaultValue = "",
        maskDelay,
        showIcon = true,
        maxValue,
        durationHasChanged,
        handleBlur: handleBlurProp,
        handleChange: handleChangeProp,
        allowSeconds,
        defaultValue: defaultValueProp,
        handleSubmit: handleSubmitProp,
        short,
        formattedClassName = "",
        autoSize,
        readOnly,
        ...other
    } = props;
    const classes = useStyles();

    const minute = 60;

    const hour = 60 * minute;

    const day = 24 * hour;

    const timer = useRef<any>(null);

    const convertToInteger = (value = "") => {
        if (typeof value === "number" || /^[0-9 ]+$/.test(value)) {
            return parseInt(value || "0", 10);
        }

        const match = value.matchAll(/(?<value>\d*)(?<unit>\w)/gi);

        const volumes = {
            m: minute,
            h: hour,
            d: day,
            s: 1,
        };

        // Ensure subsequent matches of same unit are filtered out, e.g. ['2d', '1d'] => ['2d']
        const uniqueMatches = [...match].reduce((prev, item, index) => {
            if (Object.keys(prev).find((prevKey) => prev[prevKey][2] === item[2])) {
                return prev;
            }

            return {...prev, [index]: item};
        }, {});

        return Object.keys(uniqueMatches).reduce((prev, key) => {
            const unit = uniqueMatches[key][2] || "m";
            return unit.length && volumes[unit] ? prev + volumes[unit] * uniqueMatches[key][1] : prev;
        }, 0);
    };

    const mask = (value) => {
        const intValue = convertToInteger(value);

        const hours = intValue % day;
        const minutes = intValue % hour;

        const duration: {d: number; h: number; m: number; s?: number} = {
            d: intValue >= day ? Math.floor(intValue / day) : 0,
            h: hours > 0 ? Math.floor(hours / hour) : 0,
            m: minutes > 0 ? Math.floor(minutes / minute) : 0,
        };
        if (props.allowSeconds) {
            const seconds = intValue % minute;
            duration.s = seconds;
        }
        const nextValue = Object.keys(duration)
            .reduce((prev, key) => {
                const durationValue = duration[key];
                return durationValue ? `${prev} ${durationValue}${key}` : prev;
            }, "")
            .trimStart();

        return nextValue || defaultValue;
    };

    const ref = useRef<any>(null);

    const [value, setValue] = useState(mask(props.value));

    useEffect(() => {
        if (autoFocus && ref.current) {
            ref?.current?.focus();
        }

        timer.current = null;
    }, []);

    useEffect(() => {
        if (props.value !== value) {
            setValue(mask(props.value));
        }
    }, [props.value]);

    const handleBlur = (event) => {
        setFocused(false);
        if (timer.current) {
            clearTimeout(timer.current);
        }
        let nextValue = mask(typeof value === "number" || /^[0-9 ]+$/.test(value as string) ? value + "m" : value);
        const returning = convertToInteger(nextValue as string);
        let doNotSet = false;
        if (maxValue && returning > convertToInteger(value as string) && returning > maxValue) {
            doNotSet = true;
        }
        if (!doNotSet) {
            setValue(nextValue);
            if (props.durationHasChanged && isFunction(props.durationHasChanged)) {
                props.durationHasChanged();
            }
            if (handleSubmitProp && isFunction(handleSubmitProp)) {
                handleSubmitProp();
            }
        }
        if (isFunction(props.handleBlur)) {
            props.handleBlur && props.handleBlur(doNotSet ? null : returning, convertToInteger(value as string), value, event);
        }
        if (doNotSet) {
            toast(`Your modification was discarded because you are exceeding the maximum allowed time for a session`, {
                type: "error",
            });
        }
    };
    const handleChange = (event) => {
        let {value = ""} = event.target;
        const nextValue = mask(value);
        const hasOnChangeFunction = isFunction(props.handleChange);
        const hasMaskDelay = isNumber(maskDelay) && maskDelay && maskDelay >= 0;

        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (maskDelay === 0) {
            value = nextValue;
        }

        setValue(value);
        if (hasOnChangeFunction && !hasMaskDelay) {
            props?.handleChange && props?.handleChange(convertToInteger(value), nextValue, value);
        }

        if (hasMaskDelay) {
            timer.current = setTimeout(() => handleMaskDelay(value, nextValue, hasOnChangeFunction), maskDelay);
        }
    };

    const handleMaskDelay = (value, nextValue, hasOnChangeFunction) => {
        setValue(nextValue);

        if (hasOnChangeFunction) {
            props.handleChange && props.handleChange(convertToInteger(value), nextValue, value);
        }
    };

    const onKeyDown = (event) => {
        if (isFunction(onKeyDown)) {
            props.onKeyDown && props.onKeyDown(event);
        }
    };

    const onKeyUp = (event) => {
        if (isFunction(onKeyUp)) {
            props.onKeyUp && props.onKeyUp(event);
        }
    };

    const [focused, setFocused] = useState(false);

    const onFocus = () => {
        setFocused(true);
    };

    useEffect(() => {
        if (focused) {
            ref.current?.focus();
        }
    }, [focused]);

    const size = Math.max((value + "").length - (isSafari ? 0 : 1), 1);

    return (
        <div className={classes.root}>
            {!focused || readOnly ? (
                <div onClick={onFocus}>
                    {short ? (
                        value ? (
                            displayFormattedValue(value, formattedClassName)
                        ) : (
                            <div className={formattedClassName}>{t("agenda.items.no_time")}</div>
                        )
                    ) : (
                        displayValue(value)
                    )}
                </div>
            ) : (
                <>
                    {showIcon ? <TimerRoundedIcon className={classes.icon} /> : null}

                    <input
                        data-id="input-duration"
                        {...other}
                        size={autoSize ? size : undefined}
                        className={cls(classes.input, other.className, showIcon && classes.inputWithIcon)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={onKeyDown}
                        placeholder={value + ""}
                        onKeyUp={onKeyUp}
                        ref={ref}
                        value={value ?? ""}
                    />
                </>
            )}
        </div>
    );
};

export default DurationInputMask;
