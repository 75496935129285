import {
    CalendarEventCustomFragment,
    GetCalendarEventsDocument,
    GetCalendarEventsQuery,
    GlobalNotificationsSubscription,
    SessionLifecycle,
    UpsertCalendarEvent,
} from "@generated/data";
import {areIntervalsOverlapping, differenceInMilliseconds, isAfter} from "date-fns";
import {isEqual} from "date-fns";
import apollo from "../apollo";
import {makeVar} from "../data";
import {useEffect, useRef, useState} from "../rendering";
import {calendarLoadedPeriod, useCalendar} from "./calendar";
import {CalendarWorker} from "./calendarWorker";
import {isBetween} from "./date";
import {CalendarPeriod, EventsPaginationParams, EventsSortFilterParams} from "./definitions";
import {filterEvents} from "./filter";
import {sortEvents} from "./sort";
import {getCalendarEventId} from "./utils";

export const EVENTS_PER_PAGE = 20;

export const eventsSortFiltersPagination = makeVar<EventsSortFilterParams & EventsPaginationParams>({
    take: EVENTS_PER_PAGE,
    cursor: "",
});

const calendarWorker = new CalendarWorker();

export const addCalendarEvent = (
    newEvent: NonNullable<NonNullable<NonNullable<GlobalNotificationsSubscription["globalNotifications"]>["calendar"]>["upsertSession"]>
) => {
    const existingCalendarEvents = apollo.cache.readQuery({
        query: GetCalendarEventsDocument,
        variables: {
            from: new Date(0),
            until: new Date(0),
            includeExternal: true,
            includeNormal: true,
            includeRecurrent: true,
        },
    });

    if (existingCalendarEvents) {
        if (newEvent.entireEvent) {
            apollo.cache.writeQuery({
                query: GetCalendarEventsDocument,
                variables: {
                    from: new Date(1),
                    until: new Date(1),
                    includeExternal: true,
                    includeNormal: true,
                    includeRecurrent: true,
                },
                data: newEvent.entireEvent.recurrenceData
                    ? {
                          calendarRecurrentEvents: (
                              existingCalendarEvents.calendarRecurrentEvents
                                  ?.filter((existingEvent) => !newEvent.entireEvent!.id.includes(existingEvent.id))
                                  .map((p) => ({...p, id: `sfe-${p.id}`})) ?? []
                          ).concat(
                              newEvent.entireEvent.id.includes("sfe-")
                                  ? newEvent.entireEvent
                                  : {...newEvent.entireEvent, id: `sfe-${newEvent.entireEvent.id}`}
                          ),
                      }
                    : {
                          calendarEvents: [
                              newEvent.entireEvent.id.includes("sfe-")
                                  ? newEvent.entireEvent
                                  : {...newEvent.entireEvent, id: `sfe-${newEvent.entireEvent.id}`},
                          ],
                      },
            });

            if (newEvent.entireEvent.recurrenceData) {
                expandEvents([newEvent.entireEvent.id], calendarLoadedPeriod());
            }
        } else if (newEvent.deletedParticipantIds?.length) {
            const existingEvent =
                existingCalendarEvents.calendarRecurrentEvents?.find((p) => p.id === newEvent.sessionId) ??
                existingCalendarEvents.calendarEvents?.find((p) => p.id === newEvent.sessionId);

            if (existingEvent) {
                apollo.cache.writeQuery({
                    query: GetCalendarEventsDocument,
                    variables: {
                        from: new Date(1),
                        until: new Date(1),
                        includeExternal: true,
                        includeNormal: true,
                        includeRecurrent: true,
                    },
                    data: existingEvent.recurrenceData
                        ? {
                              calendarRecurrentEvents: (
                                  existingCalendarEvents.calendarRecurrentEvents?.filter(
                                      (existingEvent) => !newEvent.sessionId.includes(existingEvent.id)
                                  ) ?? []
                              )
                                  .map((p) => ({...p, id: `sfe-${p.id}`}))
                                  .concat({
                                      ...existingEvent,
                                      id: existingEvent.id.includes("sfe-") ? existingEvent.id : `sfe-${existingEvent.id}`,
                                      participants: existingEvent.participants?.filter(
                                          (p) => !newEvent.deletedParticipantIds?.includes(p.id)
                                      ),
                                  }),
                          }
                        : {
                              calendarEvents: [
                                  {
                                      ...existingEvent,
                                      id: existingEvent.id.includes("sfe-") ? existingEvent.id : `sfe-${existingEvent.id}`,
                                      participants: existingEvent.participants?.filter(
                                          (p) => !newEvent.deletedParticipantIds?.includes(p.id)
                                      ),
                                  },
                              ],
                          },
                });

                if (existingEvent.recurrenceData) {
                    expandEvents([newEvent.sessionId], calendarLoadedPeriod());
                }
            }
        } else if (newEvent.upsertParticipants?.length) {
            const existingEvent =
                existingCalendarEvents.calendarRecurrentEvents?.find((p) => p.id === newEvent.sessionId) ??
                existingCalendarEvents.calendarEvents?.find((p) => p.id === newEvent.sessionId);

            if (existingEvent) {
                apollo.cache.writeQuery({
                    query: GetCalendarEventsDocument,
                    variables: {
                        from: new Date(1),
                        until: new Date(1),
                        includeExternal: true,
                        includeNormal: true,
                        includeRecurrent: true,
                    },
                    data: existingEvent.recurrenceData
                        ? {
                              calendarRecurrentEvents: (
                                  existingCalendarEvents.calendarRecurrentEvents?.filter(
                                      (existingEvent) => !newEvent.sessionId.includes(existingEvent.id)
                                  ) ?? []
                              )
                                  .map((p) => ({...p, id: `sfe-${p.id}`}))
                                  .concat({
                                      ...existingEvent,
                                      id: existingEvent.id.includes("sfe-") ? existingEvent.id : `sfe-${existingEvent.id}`,
                                      participants: existingEvent.participants.concat(newEvent.upsertParticipants),
                                  }),
                          }
                        : {
                              calendarEvents: [
                                  {
                                      ...existingEvent,
                                      id: existingEvent.id.includes("sfe-") ? existingEvent.id : `sfe-${existingEvent.id}`,
                                      participants: existingEvent.participants.concat(newEvent.upsertParticipants),
                                  },
                              ],
                          },
                });

                if (existingEvent.recurrenceData) {
                    expandEvents([newEvent.sessionId], calendarLoadedPeriod());
                }
            }
        }
    }
};

export const evictCalendarEventsFromCache = (sessionId: string, occurrenceId?: Date) => {
    // console.log(`evicting session ${`${sessionId} ${occurrenceId ? occurrenceId.toISOString() : ""}`}`);

    const existingCalendarEvents = apollo.cache.readQuery({
        query: GetCalendarEventsDocument,
        returnPartialData: true,
        variables: {
            from: new Date(0),
            until: new Date(0),
            includeExternal: true,
            includeNormal: true,
            includeRecurrent: true,
        },
    });

    if (existingCalendarEvents) {
        const normalEventsToEvict =
            existingCalendarEvents.calendarEvents?.filter((event) => {
                if (event.id.includes(sessionId)) {
                    if (occurrenceId) {
                        return (event.occurrenceId?.getTime() ?? 0) === occurrenceId.getTime();
                    }
                    return true;
                } else {
                    return false;
                }
            }) ?? [];

        const recurrentEventsToEvict = occurrenceId
            ? []
            : existingCalendarEvents.calendarRecurrentEvents?.filter((event) => event.id === sessionId) ?? [];

        const allEventsToEvict = normalEventsToEvict.concat(recurrentEventsToEvict);

        for (const event of allEventsToEvict) {
            apollo.cache.evict({
                id: apollo.cache.identify({
                    ...event,
                    id: `sfe-${event.id}`,
                }),
            });
        }
        apollo.cache.gc();
    }
};

export const expandEvents = async (eventIds: "all" | string[], period: CalendarPeriod) => {
    const existingEvents = apollo.cache.readQuery({
        query: GetCalendarEventsDocument,
        returnPartialData: true,
        variables: {
            from: new Date(0),
            until: new Date(0),
            includeExternal: true,
            includeNormal: true,
            includeRecurrent: true,
        },
    });

    const recurrentEventsToExpand =
        eventIds === "all"
            ? existingEvents?.calendarRecurrentEvents ?? []
            : (existingEvents?.calendarRecurrentEvents ?? []).filter((p) => {
                  return eventIds.find((x) => x.includes(p.id));
              });

    // console.log("expanding events", {
    //     eventIds,
    //     period,
    //     recurrentEventsToExpand,
    //     existingEvents,
    // });

    const existingExternalEvents = {
        google: existingEvents?.getGoogleEvents,
        apple: existingEvents?.getAppleEvents,
        microsoft: existingEvents?.getMicrosoftEvents,
    };

    // const start = Date.now();

    // console.log(
    //     `EXPANDING EVENTS REQUIRED INTERVAL ${period.startDate.toISOString()} ${period.endDate.toISOString()}`,
    //     {period},
    //     existingExternalEvents
    // );

    const calendarWorkerResult = await calendarWorker.expandEvents({
        periodToExpand: period,
        recurrentEvents: recurrentEventsToExpand,
        appleEvents: existingExternalEvents.apple,
        googleEvents: existingExternalEvents.google,
        microsoftEvents: existingExternalEvents.microsoft,
    });

    if (!calendarWorkerResult) {
        return;
    }

    const expandedEvents = calendarWorkerResult.expandedEvents;

    const expandedExternalEvents = {
        google: calendarWorkerResult.expandedGoogleEvents,
        apple: calendarWorkerResult.expandedAppleEvents,
        microsoft: calendarWorkerResult.expandedMicrosoftEvents,
    };

    // console.log(
    //     `TOTAL EXPANDED EVENTS: ${expandedEvents.length}`,
    //     {period}
    // );

    const refreshedEvents = apollo.cache.readQuery({
        query: GetCalendarEventsDocument,
        returnPartialData: true,
        variables: {
            from: new Date(0),
            until: new Date(0),
            includeExternal: true,
            includeNormal: true,
            includeRecurrent: true,
        },
    });

    const refreshedNormalEvents = (refreshedEvents?.calendarEvents ?? [])
        .map((p) => ({...p, id: "sfe-" + p.id}))
        .filter((p) => {
            if (!p.occurrenceId || !p.recurrenceParentId) {
                return true;
            }

            const recurrenceParent = recurrentEventsToExpand.find((x) => p.id.includes(x.id));

            if (!recurrenceParent) {
                return true;
            }

            return !isBetween(p.occurrenceId, calendarWorkerResult.expandedPeriod.startDate, calendarWorkerResult.expandedPeriod.endDate);
        });

    const existingEventsSet = new Set<string>(refreshedNormalEvents.map((p) => `${p.id}-${p.occurrenceId?.toISOString()}`) ?? []);

    if (refreshedEvents) {
        const newEvents: (typeof refreshedEvents)["calendarEvents"] = [
            ...refreshedNormalEvents,
            ...(expandedEvents?.filter((p) => existingEventsSet.has(`${p.id}-${p.occurrenceId?.toISOString()}`) === false) ?? []),
        ];

        // console.log("merging unique events", {refreshedNormalEvents, newEvents, existingEventsSet, expandedEvents});

        apollo.client.writeQuery({
            query: GetCalendarEventsDocument,
            variables: {
                from: new Date(0),
                until: new Date(0),
                includeExternal: true,
                includeNormal: true,
                includeRecurrent: true,
            },
            data: {
                ...refreshedEvents,
                calendarEvents: newEvents,
                calendarRecurrentEvents: refreshedEvents.calendarRecurrentEvents?.map((event) => ({
                    ...event,
                    id: "sfe-" + event.id,
                })),
                getGoogleEvents: expandedExternalEvents.google,
                getAppleEvents: expandedExternalEvents.apple,
                getMicrosoftEvents: expandedExternalEvents.microsoft,
            },
        });

        // console.log(
        //     "after write",
        //     apollo.client.readQuery({
        //         query: GetCalendarEventsDocument,
        //         variables: {
        //             from: new Date(1),
        //             until: new Date(1),
        //             includeExternal: true,
        //             includeNormal: true,
        //             includeRecurrent: true,
        //         },
        //     })
        // );
    }

    // const timeTookForExpand = Date.now() - start;
    // console.log("FINISHED EXPANDING EVENTS", {timeTookForExpand: timeTookForExpand + "ms"});

    return expandedEvents;
};
const compareFilters = (
    f1: Omit<EventsSortFilterParams, "cursor" | "take">,
    f2: Omit<EventsSortFilterParams, "cursor" | "take">
): boolean => {
    // HACK: we need to compare the filters, but we don't want to compare the stringified version
    return JSON.stringify(f1) === JSON.stringify(f2);
};

export const getUpcomingSessionIndex = (events: CalendarEventCustomFragment[]) => {
    const now = new Date();

    // We create groups of sessions that start at the same time
    const groups = events
        .filter((e) => !e.quickSession)
        .filter((e) => isAfter(e.plannedEnd, now))
        .filter((e) => e.lifecycle !== SessionLifecycle.Ended)
        .reduce(
            (accGroups, event, _, events) =>
                // if this event is present in another group
                // - we return the group
                // - otherwise, we create it
                accGroups.find((e) => Object.is(e, event))
                    ? accGroups
                    : [...accGroups, events.filter((e) => isEqual(event.startAt, e.startAt))],
            []
        )
        .filter((group) => group.length);

    // we get the first group that has a live session, otherwise, we get the first group in the list
    const group = groups.find((g) => g.find((e) => e.lifecycle === SessionLifecycle.Started)) || groups[0];

    if (!group?.length) {
        // there is no upcoming session
        return -1;
    }

    // we get the first live session or we default to the first not started session
    const event = group.find((e) => e.lifecycle === SessionLifecycle.Started) || group[0];

    if (event) {
        return events.findIndex((e) => Object.is(e, event));
    }

    return -1;
};

export const useSortedFilteredPaginatedEvents = (allEvents: CalendarEventCustomFragment[], params: EventsSortFilterParams) => {
    const {
        startDate,
        endDate,
        instantOrPlannedFilters,
        sortBy,
        sortOrder,
        participantsEmails,
        participantInviteStatuses,
        lifecycleFilters,
        take: takeSource = EVENTS_PER_PAGE,
        cursor,
        workspaceId,
    } = params;

    const [events, setEvents] = useState<CalendarEventCustomFragment[]>([]);

    const changedFiltersRef = useRef({
        instantOrPlannedFilters,
        lifecycleFilters,
        participantInviteStatuses,
        participantsEmails,
        sortBy,
        sortOrder,
        startDate,
        endDate,
        workspaceId,
    });

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }

        const filtersChanged = !compareFilters(changedFiltersRef.current, {
            instantOrPlannedFilters,
            lifecycleFilters,
            participantInviteStatuses,
            participantsEmails,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            workspaceId,
        });

        if (filtersChanged) {
            changedFiltersRef.current = {
                instantOrPlannedFilters,
                lifecycleFilters,
                participantInviteStatuses,
                participantsEmails,
                sortBy,
                sortOrder,
                startDate,
                endDate,
                workspaceId,
            };
        }

        let take = takeSource;

        const sessionsEvents = allEvents.filter((event) => {
            if (event.externalEvent) {
                return false;
            }

            if (event.isTask) {
                return false;
            }

            if (event.startAt && event.plannedEnd && !isAfter(event.plannedEnd, event.startAt)) {
                return false;
            }

            if (workspaceId && event.workspaceId && workspaceId !== event.workspaceId) {
                return false;
            }

            if (event.startAt && event.plannedEnd) {
                return areIntervalsOverlapping({start: event.startAt, end: event.plannedEnd}, {start: startDate, end: endDate});
            }

            return isAfter(event.startAt, startDate);
        });

        const correspondingEvents = sortEvents(
            filterEvents(sessionsEvents, {
                instantOrPlannedFilters,
                lifecycleFilters,
                participantsEmails,
                participantInviteStatuses,
            }),
            sortBy,
            sortOrder
        );

        let skip = 0;
        if (cursor !== "" && typeof cursor === "string") {
            skip = correspondingEvents.findIndex((e) => e.id === cursor);
        } else if (cursor !== "" && typeof cursor !== "string") {
            skip = correspondingEvents.findIndex(
                (e) =>
                    (e.instanceOfRecurrence?.sessionId || e.recurrenceParentId || e.id) === cursor.parentId &&
                    e.occurrenceId === cursor.occurrenceId &&
                    e.lifecycle !== SessionLifecycle.Ended
            );
        } else {
            const now = new Date();

            skip = getUpcomingSessionIndex(correspondingEvents);

            if (skip === -1) {
                const eventIndex = correspondingEvents
                    .map((e) => [differenceInMilliseconds(e.startAt, now), e.lifecycle])
                    .findIndex(([diff, lifecycle]) => Number(diff) > 0 && lifecycle !== SessionLifecycle.Ended);

                skip = Math.max(0, typeof eventIndex === "undefined" ? 0 : eventIndex - 9);
            } else {
                skip -= 9;
            }
        }

        if (take < 0) {
            take *= -1;
            skip -= take;
        }

        if (skip + take > correspondingEvents.length) {
            skip = correspondingEvents.length - take;
        }

        skip = Math.max(0, skip);

        const wantedEvents = correspondingEvents.slice(skip, skip + take);

        if (filtersChanged) {
            setEvents(wantedEvents);
        } else {
            setEvents((evs) => {
                const filteredEvs = filterEvents(
                    evs
                        .filter((e) => !wantedEvents.find((we) => getCalendarEventId(e) === getCalendarEventId(we)))
                        .filter((ev) => allEvents.find((e) => getCalendarEventId(e) === getCalendarEventId(ev)))
                        .map((ev) => allEvents.find((e) => getCalendarEventId(e) === getCalendarEventId(ev)) || ev),
                    {
                        instantOrPlannedFilters,
                        lifecycleFilters,
                        participantsEmails,
                        participantInviteStatuses,
                    }
                );

                const sortedEvents = sortEvents([...filteredEvs, ...wantedEvents], sortBy, sortOrder);

                return sortedEvents;
            });
        }
    }, [
        allEvents,
        cursor,
        takeSource,
        endDate,
        instantOrPlannedFilters,
        lifecycleFilters,
        participantInviteStatuses,
        participantsEmails,
        sortBy,
        sortOrder,
        startDate,
        workspaceId,
    ]);

    // useEffect(() => {
    //     // if all events were deleted
    //     if (events.length === 0 && allEvents.length > 0) {
    //         setEvents([]);
    //     }
    // }, [events, allEvents]);

    return {
        events,
    };
};

export const useCalendarEventBySessionId = (sessionId: string) => {
    const calendar = useCalendar();

    return calendar.calendarEvents?.find((p) => p.id === sessionId);
};

export const readCalendarEventBySessionId = (sessionId: string) => {
    const normalEvents = apollo.cache.readQuery<GetCalendarEventsQuery>({
        query: GetCalendarEventsDocument,
        variables: {
            from: new Date(),
            until: new Date(),
            includeNormal: true,
            includeRecurrent: false,
            includeExternal: false,
        },
    });

    return normalEvents?.calendarEvents?.find((p) => p.id === sessionId);
};
