import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import SearchResultsList from "./SearchResultsList";
import classes from "./style/SearchResultsContainer.module.scss";
import {ScrollState} from "./utils";

type SearchResultsContainerProps = {
    anchorEl: HTMLElement | null;
    term: string;
    isActive: boolean;
    isMobileOrTablet: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchResultsContainer = ({
    term,

    isMobileOrTablet,
    isActive,
    setIsActive,
}: SearchResultsContainerProps) => {
    const searchResultsRef = useRef<HTMLDivElement | null>(null);
    const [scrollState, setScrollState] = useState<ScrollState>({});
    const scrollContainerRef = useRef<ScrollContainerRef>({
        setScrollState,
    });
    const [hasResults, setHasResults] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const checkNextPageResults = useRef<() => void | null>(null);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (event.target && !ref.current?.contains(event.target as Node)) {
                setIsActive(false);
            }
        };

        document.body.addEventListener("click", handleClickOutside);
        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (scrollState.onBottom) {
            if (checkNextPageResults && checkNextPageResults.current) {
                checkNextPageResults.current();
            }
        }
    }, [scrollState]);

    return (
        <>
            <div ref={ref} className={cls(classes.root, !isActive && classes.hidden)}>
                <ScrollContainer
                    component="div"
                    id="search-results-body"
                    key="scroll-container"
                    axis="y"
                    scrollBarOffsetTop={12}
                    scrollBarOffsetBottom={12}
                    ref={searchResultsRef}
                    connector={scrollContainerRef}
                    isMobile={false}
                    className={cls("flex flex-col", classes.resultsContainer, !hasResults && classes.hidden)}
                    role="list"
                >
                    <SearchResultsList
                        term={term}
                        checkNextPageResults={checkNextPageResults}
                        isSearching={isSearching}
                        setIsSearching={setIsSearching}
                        setHasResults={setHasResults}
                    />
                </ScrollContainer>

                {isSearching || !hasResults ? (
                    <div className={cls("flex flex-col", classes.resultsContainer)}>
                        <div className={classes.noResultsFound}>
                            <Typography color="tertiary">{isSearching ? "Searching..." : "No results found."}</Typography>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default SearchResultsContainer;
