import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {ReactComponent as TogglerQA} from "../../../frontend/assets/media/macro-toggler-qa.svg";
import NewQuestionsCount from "./components/NewQuestionsCount";
import {forwardRef} from "@workhorse/api/rendering";

interface MacroQaTogglerProps {
    artifactId: string;
    isAdmin: boolean;
    isAssistant: boolean;
    assistantCanSeeAllQ: boolean;
    isActive: boolean;
    onToggle: () => void;
    setNewQuestionsCount: (number) => void;
    newQuestionsCount?: number;
    isLobby: boolean;
    inDrawer: boolean;
}

const MacroQaToggler = forwardRef((props: MacroQaTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {
        artifactId,
        isAdmin,
        isActive,
        onToggle,
        newQuestionsCount,
        setNewQuestionsCount,
        isAssistant,
        assistantCanSeeAllQ,
        isLobby,
        inDrawer,
    } = props;

    return (
        <>
            <ArtifactActionsButton
                badgeContent={(!isActive && (isAdmin || (isAssistant && assistantCanSeeAllQ)) && newQuestionsCount) || 0}
                onClick={onToggle}
                isActive={isActive}
                MacroIcon={<TogglerQA />}
                buttonDataId="player-button-questions"
                artifactId={artifactId}
                isLobby={isLobby}
                inDrawer={inDrawer}
                ref={ref}
            />
            {(isAdmin || (isAssistant && assistantCanSeeAllQ)) && <NewQuestionsCount setNewQuestionsCount={setNewQuestionsCount} />}
        </>
    );
});

export default MacroQaToggler;
