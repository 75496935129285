import {OnboardingJsonKeyValueType} from "@sessions/common/onboarding";

const MEETING_TYPES = ["meetings"];
const WEBINARS_TYPES = ["webinars", "workshops"];
const DEMOS_TYPES = ["demos", "trainings", "interviews"];

export enum OnboardingPersona {
    MEETINGS = "Meetings",
    WEBINARS = "Webinars",
    DEMOS = "Demos",
    GENERIC = "Generic",
}

export function getOnboardingPersona(meetingTypes?: OnboardingJsonKeyValueType[]): OnboardingPersona {
    const types = meetingTypes
        ?.map(({key}) =>
            MEETING_TYPES.includes(key)
                ? OnboardingPersona.MEETINGS
                : WEBINARS_TYPES.includes(key)
                ? OnboardingPersona.WEBINARS
                : DEMOS_TYPES.includes(key)
                ? OnboardingPersona.DEMOS
                : null
        )
        ?.filter((x) => !!x);

    const uniqueTypes = [...new Set(types)];

    return uniqueTypes.length === 1 ? uniqueTypes[0] ?? OnboardingPersona.GENERIC : OnboardingPersona.GENERIC;
}
