import clientEvents from "@api/events/client";
import {isNotAllowedError, useDevices, useForceDisableVideo, useForceMuteStatus} from "@workhorse/api/conference2";
import {useCallback, useEffect, useRef} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import {KeybindAction, checkGlobalKeybinds} from "@workhorse/util/keybinds";
import {useTranslation} from "react-i18next";

const PlayerKeyboardEvents = () => {
    const {t} = useTranslation();
    const {ctx, set, sync} = useSessionView();
    const {audioMuted, setAudioMuted, hasAudioInput, toggleVideo, videoEnabled, videoError} = useDevices();

    const {muteStatus} = useForceMuteStatus();
    const {isHardVideoDisable} = useForceDisableVideo();

    const forceMute = muteStatus === "hard";

    const videoActive = videoEnabled && !isNotAllowedError(videoError);

    const fn = useRef({
        handleFn: (event: KeyboardEvent) => {},
    });

    const onArtifactMessage = useCallback((event: MessageEvent) => {
        const {origin, data} = event;

        if (origin === window.origin && data.source === "pspdfkit") {
            const event = new KeyboardEvent(data.type, data.payload);
            fn.current.handleFn(event);
        }
    }, []);

    useEffect(() => {
        fn.current.handleFn = (event: KeyboardEvent) => {
            const action: KeybindAction | undefined = checkGlobalKeybinds(event);
            let stopPropagation = false;

            switch (action) {
                default:
                case undefined: {
                    break;
                }
                case "toggleAudio": {
                    if (!hasAudioInput || forceMute) {
                        return;
                    }

                    setAudioMuted(!audioMuted);
                    stopPropagation = true;

                    ariaAnnouncerMessageVar(
                        audioMuted ? t("aria_announcer.mic_on") ?? "Microphone on" : t("aria_announcer.mic_off") ?? "Microphone off"
                    );
                    break;
                }

                case "toggleVideo": {
                    if (isHardVideoDisable) {
                        return;
                    }

                    toggleVideo();
                    stopPropagation = true;

                    ariaAnnouncerMessageVar(
                        videoActive ? t("aria_announcer.camera_off") ?? "Camera off" : t("aria_announcer.camera_on") ?? "Camera on"
                    );

                    break;
                }

                case "toggleRecording": {
                    clientEvents.emit("player-keybind-toggle-recording", true);
                    stopPropagation = true;

                    break;
                }

                case "toggleLivestream": {
                    clientEvents.emit("player-keybind-toggle-livestream", true);
                    stopPropagation = true;

                    break;
                }

                case "toggleRaiseHand": {
                    clientEvents.emit("player-keybind-toggle-raisehand", true);
                    stopPropagation = true;

                    break;
                }

                case "toggleScreenShare": {
                    clientEvents.emit("player-keybind-toggle-screenshare", true);
                    stopPropagation = true;

                    break;
                }

                case "switchPlayerViewModeGallery": {
                    if (ctx !== "tool") {
                        set("collaboration", sync);
                    }
                    stopPropagation = true;
                    break;
                }

                case "switchPlayerViewModeSpotlight": {
                    set("presentation", sync);
                    stopPropagation = true;
                    break;
                }

                case "switchPlayerViewModeAudience": {
                    set("audience", sync);
                    stopPropagation = true;
                    break;
                }
            }

            if (stopPropagation) {
                event.preventDefault();
                event.stopPropagation();
            }
        };
    }, [set, sync, hasAudioInput, forceMute, isHardVideoDisable, toggleVideo, audioMuted, setAudioMuted, videoActive]);

    const handleFn = (event: KeyboardEvent) => {
        fn.current.handleFn(event);
    };

    useEffect(() => {
        document.body.addEventListener("keyup", handleFn);
        window.addEventListener("message", onArtifactMessage, {capture: true});

        return () => {
            document.body.removeEventListener("keyup", handleFn);
            window.removeEventListener("message", onArtifactMessage);
        };
    }, []);

    return null;
};

export default PlayerKeyboardEvents;
