import Typography from "@ui/cdk/Typography";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {WithTooltipChildren} from "./onboarding-tooltips-utils";
import {useOnboardingSession, useWantedPaletteAction, SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import {PaletteActiveCateg} from "../command-palette/CommandPaletteProviders";
import {PaletteVisibleItems} from "../command-palette/CommandPaletteResultsProvider";
import {useDeferredValue} from "./utils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import classes from "./onboarding.module.scss";
import {useEffect, useState} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {cls} from "@ui/cdk/util";
import clientEvents from "@api/events/client";
import {ActionCategTreeItem, categTree, ActionCategory, categMap} from "../command-palette/actionCategTree";
import {useUserInfo} from "@workhorse/providers/User";
import {useQuery} from "@apollo/client";
import {MyProductToolsDocument} from "@generated/data";

interface Props extends WithTooltipChildren {
    activeCategory: PaletteActiveCateg;
    visibleItems: PaletteVisibleItems;
    currentValue: string;
}

const demoLink = "https://www.youtube.com/watch?v=e9y0cH_LtwM";

export function OnboardingPaletteSearch(props: Props) {
    const [noIntroTransition, setNoIntroTransition] = useState(false);
    const [dontShow, setDontShow] = useState(false);

    const {onboardingType, tooltip} = useOnboardingSession();
    const action = useWantedPaletteAction(tooltip);
    const {isTablet} = useMobile();
    const user = useUserInfo();

    const shareToolAction = categTree[ActionCategory.Tool];

    const activeCateg = props.activeCategory;
    const workspaceId = user.activeWorkspace.id;

    const productName = useQuery(MyProductToolsDocument, {
        variables: {
            workspaceId,
        },
    }).data?.myProductTools?.[0]?.title;
    let placement: "right" | "top" | "top-end" = "right";

    let searchName = "";
    let shouldSearch = false;

    //onboarding tooltip my product
    if (action?.bySearch === true && activeCateg.id == null) {
        searchName = action?.searchItem?.searchStr?.replace("Create ", "") ?? "";
        shouldSearch = !props.visibleItems.items.includes(action.actionId);
    }

    if (searchName === "My Product Tool" && productName) {
        searchName = productName;
    }

    let title = (
        <Typography variant="sm" fontWeight="normal" color="background">
            Type <i>{searchName}</i>
        </Typography>
    );

    if (onboardingType === SessionOnboardingType.NewUser) {
        if (tooltip === "palette-generic-info" || tooltip === "palette-generic-and-file-info") {
            title = (
                <>
                    <div className="flex flex-align-center">
                        <Typography className="title" fontWeight="bolder">
                            This is where the magic happens
                        </Typography>
                    </div>
                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        Instead of sharing your screen, you can embed pretty much anything into your session.
                    </Typography>
                </>
            );
            placement = "top";
            shouldSearch = true;

            if (tooltip === "palette-generic-and-file-info" && !noIntroTransition) {
                setNoIntroTransition(true);
            }
        }

        if (activeCateg.name === "Link") {
            if (!activeCateg.prevCateg) {
                title = (
                    <>
                        <div className="flex flex-align-center">
                            <InfoOutlinedIcon className="mr-6" />
                            <Typography className="title" fontWeight="bolder">
                                Paste your link
                            </Typography>
                        </div>
                        <Typography
                            className="mt-6"
                            variant="sm"
                            fontWeight="normal"
                            color="septenary"
                            data-extra="palette-tooltip-clickable"
                        >
                            Don’t feel like finding a link? Click on this one to try it:{" "}
                            <a data-extra="palette-tooltip-clickable" onClick={addTextToInput}>
                                {demoLink}
                            </a>
                        </Typography>
                    </>
                );
                // if we're on the link prompt from the tool route
            } else if (activeCateg.prevCateg && activeCateg.prevCateg.parentId === shareToolAction.id) {
                const target = activeCateg.targetId && (categMap.find((obj) => obj.id === activeCateg.targetId) as ActionCategTreeItem);
                const name = target && target.title ? target?.title.replace("Create ", "") : "";

                title = (
                    <>
                        <div className="flex flex-align-center">
                            <InfoOutlinedIcon className="mr-6" />
                            <Typography className="title" fontWeight="bolder">
                                Paste your link
                            </Typography>
                        </div>
                        <Typography
                            className="mt-6"
                            variant="sm"
                            fontWeight="normal"
                            color="septenary"
                            data-extra="palette-tooltip-clickable"
                        >
                            {`Paste a ${name} link that you want to use.`}
                        </Typography>
                    </>
                );
            }
            if (!props.currentValue) {
                shouldSearch = true;
            } else {
                shouldSearch = false;
            }
            placement = "right";
        }
    }

    if (isTablet) {
        placement = "top-end";
    }

    //get the id=command-palette-search input element, and set the value to "dog"
    function addTextToInput() {
        clientEvents.emit("palette-search-value", {value: demoLink});
        setDontShow(true);
    }

    useEffect(() => {
        setDontShow(false);

        if (tooltip !== "palette-generic-and-file-info" && noIntroTransition) {
            setNoIntroTransition(false);
        }
    }, [noIntroTransition, tooltip, props.activeCategory]);

    const deferSkip = useDeferredValue(tooltip, !shouldSearch, true, 1000);

    return (
        <OnboardingTooltip
            tooltipId={tooltip ?? "-1"}
            title={title}
            placement={placement}
            arrow={true}
            skip={(noIntroTransition ? !shouldSearch : deferSkip) || dontShow}
            classes={{
                popper: isTablet ? classes.popperTablet : "",
                tooltip: cls(
                    "ml-24",
                    tooltip === "palette-generic-info" || tooltip === "palette-generic-and-file-info" ? classes.abovePalette : "",
                    isTablet ? classes.tooltipTablet : ""
                ),
            }}
            isPaletteTooltip={true}
            noIntroTransition={noIntroTransition}
            disableFocusListener={false}
            disableHoverListener={false}
            disableTouchListener={false}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
