function timePercentagePassed(startDate: Date, endDate: Date) {
    const range = endDate.getTime() - startDate.getTime();
    const diff = Math.max(0, endDate.getTime() - new Date().getTime());
    let percentage = Math.ceil(100 - (100 * diff) / range);

    if (percentage < 0) {
        percentage = 0;
    }

    return percentage;
}

function formatTime(startDate: Date | undefined, endDate: Date) {
    const time = startDate ? new Date(startDate).getTime() : new Date().getTime();

    const distance = new Date(endDate).getTime() - time;

    let hours: number | string = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    hours = hours < 10 ? `0${hours}` : hours;

    let minutes: number | string = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let onlyMinutes = Math.floor(distance / 60000);
    onlyMinutes = onlyMinutes < 0 ? 0 : onlyMinutes;

    let seconds: number | string = Math.floor((distance % (1000 * 60)) / 1000);
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return {
        seconds,
        minutes,
        hours,
        onlyMinutes,
    };
}

function formatTimeAsc(actualStart?: Date | null) {
    const time = actualStart ? new Date(actualStart).getTime() : new Date().getTime();

    const distance = new Date().getTime() - time;

    return {
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        onlyMinutes: Math.floor(distance / 60000),
        elapsedSec: Math.floor(distance / 1000),
    };
}

export default {
    formatTimeAsc,
    formatTime,
    timePercentagePassed,
};
