import browserInfo from "@workhorse/api/BrowserInfo";
import {createContextProvider} from "@workhorse/api/utils/context";
import {LocalVideoTrack, Room, supportsVP9, Track, VideoEncoding, VideoPreset, VideoPresets} from "livekit-client";
import {useMemo, useState} from "react";
import {getVideoPreset, getVideoSimulcastLayers} from "../../lib/DeviceManager/VideoStreamManager";
import {browserBehavior} from "../../utils";
import {useLocalTrackManager} from "./ConferenceRoomLocalTrack";
import {createRoomLogger} from "./ConferenceRoomLogger";
import {useConferenceRoom} from "./ConferenceRoomProvider";

const logger = createRoomLogger("VIDEO-PUBLISHER");

function getMediaStreamVideoTrack(stream: MediaStream): MediaStreamTrack | null {
    return stream.getVideoTracks()[0] ?? null;
}

function createLocalVideoTrack(stream: MediaStream): LocalVideoTrack | null {
    const streamTrack = getMediaStreamVideoTrack(stream);

    if (!streamTrack) {
        return null;
    }

    const track = new LocalVideoTrack(
        streamTrack,
        {
            deviceId: streamTrack.getSettings().deviceId,
        },
        true
    );

    track.mediaStream = stream;
    track.source = Track.Source.Camera;

    return track;
}

function publishToRoom(room: Room, stream: MediaStream, track: LocalVideoTrack) {
    const enableSimulcast = browserBehavior.supportsSimulcast();

    console.log("[PUBLISHING CAMERA] - simulcast enabled", enableSimulcast);

    return room.localParticipant.publishTrack(track, {
        source: Track.Source.Camera,
        stream: room.localParticipant.identity,
        simulcast: enableSimulcast,
        videoCodec: "vp8",
        backupCodec: false,
        videoEncoding: getVideoPreset().encoding,
        videoSimulcastLayers: enableSimulcast ? getVideoSimulcastLayers() : undefined,
    });
}

function useLocalVideoPublisherStore() {
    const {room} = useConferenceRoom();
    const [isCameraEnabled] = useState(false);

    const {publishOrReplaceTrack, unpublishTrack} = useLocalTrackManager(
        room,
        Track.Source.Camera,
        logger,
        publishToRoom,
        createLocalVideoTrack,
        getMediaStreamVideoTrack
    );

    return useMemo(
        () => ({
            isCameraEnabled,
            startPublishingVideo: publishOrReplaceTrack,
            stopPublishingVideo: unpublishTrack,
        }),
        [publishOrReplaceTrack, unpublishTrack, isCameraEnabled]
    );
}

export const [ConferenceRoomLocalVideoProvider, useLocalVideoPublisher] = createContextProvider(
    {
        name: "ConferenceRoomLocalVideoProvider",
    },
    useLocalVideoPublisherStore
);
