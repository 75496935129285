import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import DialogTitle from "@ui/cdk/Dialog/DialogTitle";
import Radio, {RadioGroup} from "@ui/cdk/Radio";
import Typography from "@ui/cdk/Typography";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import classes from "../style/CancelSubscriptionDialog.module.scss";
import {FormControlLabel} from "@material-ui/core";
import {cls} from "@ui/cdk/util";
import Input from "@ui/cdk/Input";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import {reportDialogData} from "@workhorse/components/reportProblem/reportDialogApi";

type CancelSubscriptionDialogProps = {
    onClose: () => void;
    onConfirm: () => void;
};

const choices = [
    {
        id: "high-cost",
        label: "Cost is too high",
    },
    {
        id: "not-enough-usage",
        label: "I don't use it enough",
    },
    {id: "missing-features", label: "Missing features I need"},
    {id: "found-better-alternative", label: "Found a better alternative"},
    {id: "difficult-to-use", label: "Too difficult to use"},
    {id: "technical-problems", label: "Technical problems"},
    {id: "other", label: "Other"},
];

const CancelSubscriptionDialog = (props: CancelSubscriptionDialogProps) => {
    const cancelSubscriptionDialogRef: React.MutableRefObject<DialogImperativeRef> = useRef({});
    const scrollContainerRef = useRef<ScrollContainerRef>({});

    const [selectedReason, setSelectedReason] = useState<string>("");
    const [otherReason, setOtherReason] = useState<string>("");

    const reportData = reportDialogData();

    function handleSelectReason(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedReason(event.target.value);

        if (event.target.value === "other") {
            scrollToBottom();
        }

        reportDialogData({
            ...reportData,
            cancelReason: event.target.value,
        });
    }

    function onChangeOtherReason(event: React.ChangeEvent<HTMLInputElement>) {
        setOtherReason(event.target.value);
        reportDialogData({
            ...reportData,
            customReason: event.target.value,
        });
    }

    function scrollToBottom() {
        if (scrollContainerRef.current?.scrollTo) {
            scrollContainerRef?.current?.scrollTo(130);
        }
    }

    const disabledSubmit = !selectedReason || (selectedReason === "other" && !otherReason);

    return (
        <Dialog classes={{paper: classes.root}} open={true} imperativeRef={cancelSubscriptionDialogRef} onClose={props.onClose}>
            <DialogTitle>
                <Typography variant="lg" fontWeight="bolder">
                    We're sad to see you go
                </Typography>
            </DialogTitle>

            <ScrollContainer
                component="div"
                className={cls(classes.scrollContainerBody)}
                id="cancel-subscription-reason-scroll-container"
                connector={scrollContainerRef}
                axis="y"
                scrollBarOffsetTop={10}
                scrollBarOffsetBottom={10}
                // isMobile={isMobile}
                key="cancel-subscription-reason-scroll-container"
            >
                <Typography variant="base" className="px-24 pt-24">
                    Your experience and satisfaction with Sessions matter to us. Before you go, could you share why you're considering
                    canceling your plan? Your feedback is invaluable and helps us improve.
                </Typography>

                <RadioGroup onChange={handleSelectReason} className="fullw">
                    {choices?.map((choice) => {
                        return (
                            <FormControlLabel
                                key={choice.id}
                                data-id="cancel-subscription-reason-radio-button"
                                value={choice.id}
                                checked={selectedReason === choice.id}
                                label={
                                    <div className={cls("flex flex-row fullw")}>
                                        <div className="flex flex-col">
                                            <Typography variant="base" fontWeight="bold">
                                                {choice.label}
                                            </Typography>
                                        </div>
                                    </div>
                                }
                                control={<Radio size="medium" />}
                                className={cls("flex flex-row fullw", classes.formControlLabel)}
                            />
                        );
                    })}
                </RadioGroup>

                {selectedReason === "other" && (
                    <div className="fullw">
                        <Input
                            name="cancel-subscription-other-reason"
                            id="cancel-subscription-other-reason"
                            type="text"
                            autoFocus={false}
                            value={otherReason}
                            formControlClassName={classes.otherReasonFormControl}
                            onChange={onChangeOtherReason}
                            minRows={4}
                            maxRows={4}
                            multiline
                            showTypedCharCount
                            maxCharCount={2000}
                        />
                    </div>
                )}
            </ScrollContainer>
            <DialogFooter>
                <Button onClick={props.onClose} variant="secondary">
                    I'll stay
                </Button>
                <Button disabled={disabledSubmit} onClick={props.onConfirm} variant="primary" withMarginLeft>
                    Cancel my plan
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default CancelSubscriptionDialog;
