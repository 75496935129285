import {WithChildren} from "@workhorse/declarations";
import {SessionSettingsDialogDisplayProvider, SessionSettingsDialogProvider} from "./providers";
import {SessionSettingsDialog} from "./SessionSettingsDialog";

export * from "./providers";

const SessionSettingsConsumer = ({children}: WithChildren) => (
    <>
        {children}
        <SessionSettingsDialog />
    </>
);

export const WithSessionSettingsProvider = ({children}: WithChildren) => (
    <SessionSettingsDialogProvider>
        <SessionSettingsDialogDisplayProvider>
            <SessionSettingsConsumer>{children}</SessionSettingsConsumer>
        </SessionSettingsDialogDisplayProvider>
    </SessionSettingsDialogProvider>
);
