import environment from "@generated/environment";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useRef} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import classes from "./styles/OnboardingSessionDialog.module.scss";
import {useUser} from "@workhorse/components/user";
import {OnboardingJsonType} from "@sessions/common/onboarding";
import {OnboardingPersona, getOnboardingPersona} from "../../api/onboarding-persona";

type ContentType = {
    videoUrl: string;
    title: string;
    body: string[];
};

const content: {[key in OnboardingPersona]: ContentType} = {
    [OnboardingPersona.GENERIC]: {
        videoUrl: `${environment.staticServiceUrl}/onboarding/home/home-dialog`,
        title: "Host meetings and webinars like a pro",
        body: [
            "Host all your meetings, training, demos, workshops and webinars on one platform",
            "Bring your sessions to life with interactive agendas and embedded content",
            "Let people book a time with you and showcase your digital product live",
            "Centralize information and set up a workspace for your team",
        ],
    },
    [OnboardingPersona.MEETINGS]: {
        videoUrl: `${environment.staticServiceUrl}/onboarding/home/home-dialog`,
        title: "Create captivating sessions and keep track of every detail",
        body: [
            "Add an interactive agenda",
            "Load presentations within seconds",
            "Work together on Google Docs, interactive whiteboards, and more",
            "Instantly access recordings and AI-generated transcripts",
        ],
    },
    [OnboardingPersona.DEMOS]: {
        videoUrl: `${environment.staticServiceUrl}/onboarding/home/home-dialog`,
        title: "Form true connections and let us take care of the admin",
        body: [
            "Set up interactive agendas for your workshops, demos, and training sessions",
            "Share your availability and let others book a time with you",
            "Showcase your digital product live",
            "Automatic follow-ups after each meeting",
        ],
    },
    [OnboardingPersona.WEBINARS]: {
        videoUrl: `${environment.staticServiceUrl}/onboarding/home/home-dialog`,
        title: "Set up professional online events in minutes",
        body: [
            "Create eye-catching landing pages and registration forms",
            "Wow your audience with interactive tools",
            "Set up an agenda once and use it again for future webinars",
            "Make it easy for speakers, collaborators, and your team members to work together",
        ],
    },
};

type OnboardingSessionDialogProps = {
    onClose?: () => void;
};

function OnboardingSessionDialog(props: OnboardingSessionDialogProps) {
    const {onClose} = props;

    const dialogRef = useRef<DialogImperativeRef>();
    const user = useUser();

    const userOnboarding = user.onboarding as OnboardingJsonType;

    const userOnboardingPersona = getOnboardingPersona(userOnboarding.onboarding_meeting_types);

    const handleCancel = async () => {
        // await markAsSeen({
        //     GLOBALS: {
        //         userOnboardingSessionEnd: true,
        //         userOnboardingSession: true,
        //     },
        // });
        onClose?.();
    };

    return (
        <Dialog imperativeRef={dialogRef} open classes={{paper: classes.root}} onClose={onClose}>
            <div className={cls("flex flex-col", classes.content)}>
                <video className={cls("fullw", classes.image)} autoPlay loop muted>
                    <source src={`${content[userOnboardingPersona].videoUrl}.webm`} type="video/webm" />
                    <source src={`${content[userOnboardingPersona].videoUrl}.mp4`} type="video/mp4" />
                </video>

                <div className={classes.info}>
                    <div className={classes.title}>
                        <Typography variant="xl2" color="primary" fontWeight="boldest">
                            {content[userOnboardingPersona].title}
                        </Typography>
                    </div>
                    <Typography component="div" color="secondary" className={classes.description}>
                        <ul>
                            {content[userOnboardingPersona].body.map((text, i) => (
                                <li key={`onboarding-session-dialog-body-${userOnboardingPersona}-${i}`}>{text}</li>
                            ))}
                        </ul>
                    </Typography>
                </div>
                <div className={classes.footer}>
                    <Button
                        noFocusBorder
                        variant="primary"
                        onClick={handleCancel}
                        fullWidth
                        data-id="onboarding-button-strike1-maybe-later"
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default OnboardingSessionDialog;
