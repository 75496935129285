import {HostType} from "@generated/data";
import {useState, useEffect, useMemo, useRef, useCallback} from "@workhorse/api/rendering";
import {createContextProvider} from "@workhorse/api/utils/context";
import {useHostType} from "./HostTypeProvider";
import {useMobile} from "./MobileProvider";
import {useIsLobby, useIsPreJoin} from "./SessionDataProviders";
import {useDrawerLeftToggler, useDrawerRightToggler} from "@workhorse/providers/state";
import {useDrawerRightActiveComponent} from "./inject";
import {useUserInfo} from "./User/UserInfoProvider";
import {exitFullScreen} from "@workhorse/util";
import {useClientEvent} from "@api/events/client";

const useSessionsUIToggleStore = () => {
    const hostType = useHostType();
    const {isLobby: inLobby} = useIsLobby();
    const {isPreJoin} = useIsPreJoin();
    const {isMobileOrTablet} = useMobile();
    const {isRecorder} = useUserInfo();

    const [drawerLeftState, toggleLeftDrawer] = useDrawerLeftToggler();
    const [drawerRightState, toggleRightDrawer] = useDrawerRightToggler();

    const {setActiveComponent} = useDrawerRightActiveComponent("adminController");

    const [sessionsUIHidden, setSessionsUIHidden] = useState<boolean>(isRecorder ? true : false);

    const [sessionsUIHeader, setSessionsUIHeader] = useState<boolean>(false);

    const [sessionsUIFooter, setSessionsUIFooter] = useState<boolean>(isRecorder ? false : true);

    const [sessionsUIFooterHovered, setSessionsUIFooterHovered] = useState(false);
    const [sessionsUIHeaderHovered, setSessionsUIHeaderHovered] = useState(false);
    const [panelCooldown, setPanelCooldown] = useState(false);

    const hoverOffTimer = useRef<NodeJS.Timeout>();

    const hoverSessionsUIPanel = useCallback(
        (panel: "header" | "footer") => {
            if (hoverOffTimer.current) {
                clearTimeout(hoverOffTimer.current);
            }
            if (panel === "header") {
                setSessionsUIHeaderHovered(true);
            } else {
                setSessionsUIFooterHovered(true);
            }
        },
        [setSessionsUIFooterHovered, setSessionsUIHeaderHovered]
    );

    const unhoverSessionsUIPanel = useCallback(
        (panel: "header" | "footer") => {
            if (hoverOffTimer.current) {
                clearTimeout(hoverOffTimer.current);
            }
            hoverOffTimer.current = setTimeout(() => {
                setSessionsUIHeaderHovered(false);
                setSessionsUIFooterHovered(false);
            }, 500);
        },
        [setSessionsUIFooterHovered, setSessionsUIHeaderHovered]
    );

    const handlePanelCooldown = useCallback(() => {
        setPanelCooldown(true);

        setTimeout(() => {
            setPanelCooldown(false);
        }, 1000);
    }, [setPanelCooldown]);

    const isSessionView = hostType === HostType.Player && !inLobby && !isPreJoin;
    const isSessionsUIHidden = isSessionView && sessionsUIHidden && !isMobileOrTablet;

    const options = {isSessionsUIHidden, drawerLeftState, drawerRightState};

    const optionsRef = useRef(options);
    optionsRef.current = options;

    // Closing the drawers when entering Hide Sessions UI
    useEffect(() => {
        const {drawerRightState, drawerLeftState} = optionsRef.current;
        if (isSessionsUIHidden) {
            if (drawerRightState.isFullyOpen) {
                toggleRightDrawer();
                setActiveComponent(null);
            }

            if (drawerLeftState.isFullyOpen) {
                toggleLeftDrawer(undefined, "SessionsUIToggleProvider:68");
            }
        }
    }, [isSessionsUIHidden]);

    // Closing Hide Sessions UI when opening drawers
    useEffect(() => {
        const {isSessionsUIHidden} = optionsRef.current;
        if (isSessionsUIHidden && (drawerRightState.isFullyOpen || drawerLeftState.isFullyOpen)) {
            exitFullScreen();
        }
    }, [drawerRightState.state, drawerLeftState.state]);

    const handleSetSessionUi = useCallback((state: boolean) => {
        setSessionsUIHidden(state);
        setSessionsUIFooter(false);
        setSessionsUIHeader(false);
    }, []);

    useClientEvent("fullscreen-mode", (isFullscreen: boolean) => {
        handleSetSessionUi(isFullscreen);
    });

    return useMemo(
        () => ({
            isSessionsUIHidden,
            sessionsUIHidden,
            setSessionsUIHidden,
            sessionsUIHeader,
            setSessionsUIHeader,
            sessionsUIFooter,
            setSessionsUIFooter,
            sessionsUIFooterHovered,
            sessionsUIHeaderHovered,
            hoverSessionsUIPanel,
            unhoverSessionsUIPanel,
            panelCooldown,
            handlePanelCooldown,
        }),
        [
            isSessionsUIHidden,
            sessionsUIHidden,
            setSessionsUIHidden,
            sessionsUIHeader,
            setSessionsUIHeader,
            sessionsUIFooter,
            setSessionsUIFooter,
            sessionsUIFooterHovered,
            sessionsUIHeaderHovered,
            hoverSessionsUIPanel,
            unhoverSessionsUIPanel,
            panelCooldown,
            handlePanelCooldown,
        ]
    );
};

export const [SessionsUIToggleProvider, useSessionsUIToggle] = createContextProvider(
    {
        name: "SessionsUIToggle",
    },
    useSessionsUIToggleStore
);
