import {SessionParticipantFragment} from "@generated/data";
import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useState} from "@workhorse/api/rendering";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import classes from "../../../components/access/AccessList/style/AccessList.module.scss";

type EventCollaboratorsAccessListProps = {
    collaborators: SessionParticipantFragment[];
};

function EventCollaboratorsAccessList(props: EventCollaboratorsAccessListProps) {
    const {collaborators} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    return (
        <div className="flex flex-align-center mr-12">
            {collaborators?.slice(0, 5).map((item) => (
                <ProfilePicture
                    className={cls(classes.listItem, collaborators?.length > 5 && classes.listItemMore)}
                    firstName={item.dataWithNullableEmail.firstName}
                    lastName={item.dataWithNullableEmail.lastName}
                    avatar={item.dataWithNullableEmail.avatar}
                    avatarSize="M"
                    avatarVariant="rounded"
                    key={item.id}
                    withWhiteBorder
                />
            ))}
            {collaborators?.length > 5 ? (
                <>
                    <Tooltip
                        open={Boolean(anchorEl)}
                        onClose={handleClosePopover}
                        interactive={true}
                        classes={{
                            tooltip: classes.tooltip,
                        }}
                        placement="right"
                        title={
                            <div className={cls("flex flex-col", classes.list)}>
                                {collaborators?.map((item) => (
                                    <div
                                        aria-haspopup="true"
                                        key={item.id}
                                        className={cls(classes.participant, "flex flex-align-center flex-justify-start")}
                                    >
                                        <ProfilePicture
                                            firstName={item.dataWithNullableEmail.firstName}
                                            lastName={item.dataWithNullableEmail.lastName}
                                            avatar={item.dataWithNullableEmail.avatar}
                                            avatarSize="M"
                                            avatarVariant="rounded"
                                            key={item.id}
                                            withWhiteBorder
                                        />
                                        {(item.dataWithNullableEmail.firstName + item.dataWithNullableEmail.lastName).length > 30 ? (
                                            <Tooltip
                                                title={
                                                    <Typography className={cls("ml-8", classes.listItemName)}>
                                                        {item.dataWithNullableEmail.firstName} {item.dataWithNullableEmail.lastName}
                                                    </Typography>
                                                }
                                                placement="top"
                                                arrow
                                            >
                                                <Typography className={cls("ml-8", classes.listItemName)}>
                                                    {item.dataWithNullableEmail.firstName} {item.dataWithNullableEmail.lastName}
                                                </Typography>
                                            </Tooltip>
                                        ) : (
                                            <Typography className={cls("ml-8", classes.listItemName)}>
                                                {item.dataWithNullableEmail.firstName} {item.dataWithNullableEmail.lastName}
                                            </Typography>
                                        )}
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <div className={classes.countContainer} onMouseOver={handleOpenPopover}>
                            <Typography>+{collaborators?.length - 5}</Typography>
                        </div>
                    </Tooltip>
                </>
            ) : null}
        </div>
    );
}

export default EventCollaboratorsAccessList;
