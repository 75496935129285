import {GetCsdForSessionDocument, GetRootUrlDocument} from "@generated/data";
import env from "@generated/environment";
import apollo from "../apollo";
import {readRemoteUser} from "../user";

export const getPlayerDomain = () => {
    if (env.csd?.disable) {
        return window.location.host;
    }

    const {getRemoteUser: getRemoteUserData} = readRemoteUser() ?? {};

    return getRemoteUserData?.user?.customSubDomainConfig?.fqdn ?? env.csd?.realDomain!;
};

export const getPlayerDomainForSession = async (sessionId: string, target = "frontend") => {
    const result = await apollo.client.query({
        query: GetRootUrlDocument,
        variables: {
            sessionId,
            target,
        },
        fetchPolicy: "network-only",
    });

    if (!result.error && result.data.getRootUrl) {
        return result.data.getRootUrl.replace("https://", "");
    }

    return window.location.host;
};
