import React, {Fragment, useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {useHistory} from "@workhorse/api/routing";
import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {setIsKicked} from "@workhorse/pages/feedback/feedbackStateVars";
import {
    MicroNotificationsDebouncer,
    MicroNotificationsDebouncerRef,
} from "@workhorse/pages/player/micro-notification-center/MicroNotificationsDebouncer";
import {readCurrentParticipant, readSession, useSession} from "@workhorse/providers/SessionDataProviders";
import {orchestrator} from "../state";
import {sessionParticipantUpdates} from "./sessionParticipantUpdates";
import {sessionUpdates} from "./sessionUpdates";
import {SessionLifecycle} from "@generated/data";
import clientEvents from "@api/events/client";
import {getNextAvailablePlan, LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {notificationIds} from "@workhorse/api/session-settings/sections/NotificationsSection/NotificationsSection";
import {getPlanTitle} from "@workhorse/pages/user/profile/UsageEvaluation/components/SubscriptionInfo";
import {useUserInfo} from "../User";
import {maximumParticipantsInSessionForPlan, sessionLengthLimitationByPlanInSeconds} from "@sessions/common/subscription-limitations";

type WatchdogProps = {
    sessionId: string;
    isOwner: boolean;
    isAssistant: boolean;
    myParticipantId: string;
    children?: React.ReactNode | React.ReactNode[];
    eventMode?: boolean;
};

export type WatchdogPayload = SubscriptionPayload<"WatchdogDocument">;
export type WatchdogPrivatePayload = SubscriptionPayload<"WatchdogPrivateDocument">;

function Watchdog(props: WatchdogProps) {
    const {isOwner, isAssistant, myParticipantId} = props;
    const history = useHistory();
    const user = useUserInfo();

    const plan = user.activeOrganizationPermission.organization.plan;
    // const isNotificationDisabled = session?.disabledNotifications.some((x) => x === notificationIds.PARTICIPANTS_ENTER_LEAVE);
    const session = useSession();

    const debouncedNotificationsConnectorRef = useRef<MicroNotificationsDebouncerRef>({
        compute: (messageWrappers, messageWrapper) => {
            const {message} = messageWrapper;

            if (message.action === "left") {
                // user left - we debounce
                return [...messageWrappers, messageWrapper];
            } else if (message.action === "entered" && messageWrappers.find(({message: m}) => m.participantId === message.participantId)) {
                // user left and returned - we cleanup
                return messageWrappers.filter(({message: m}) => m.participantId !== message.participantId);
            } else {
                // user joined (or maybe left 3s+ before and rejoined) - we notify
                return true;
            }
        },
    });

    const processData = async (data: WatchdogPayload) => {
        // @ts-expect-error isRecovered is set by the history logic in apollo-link-subscription-manager
        console.log(data.watchdog.isRecovered ? "WATCHDOG - (RECOVERED)" : "WATCHDOG", data.watchdog);

        const isNotificationDisabled = session?.disabledNotifications?.some((x) => x === notificationIds.PARTICIPANTS_ENTER_LEAVE);

        const sessionId = data.watchdog?.id?.replace("watchdog-", "");
        if (!sessionId) {
            return;
        }

        // if(data.watchdo)

        if (data.watchdog.upsertParticipants) {
            sessionParticipantUpdates(
                {
                    debouncedNotificationsConnectorRef,
                    history,
                    isOwner,
                    myParticipantId,
                    sessionId: props.sessionId,
                    notificationsDisabled: isNotificationDisabled ?? false,
                },
                data.watchdog.upsertParticipants
            );
        }

        const isSessionStartedEvent = data.watchdog.session?.lifecycle === SessionLifecycle.Started;

        if (props.eventMode && !isSessionStartedEvent) {
            return;
        }

        // The order is: upsertAgendaItems, deleteAgendaItems, update session - if this order is changed, bad things will happen.
        if (data.watchdog.upsertAgendaItems) {
            orchestrator.onSubscriptionUpsertAgendaItems(sessionId, data.watchdog.upsertAgendaItems);
        }

        if (data.watchdog.deletedAgendaItemIds) {
            orchestrator.onSubscriptionDeleteAgendaItems(sessionId, data.watchdog.deletedAgendaItemIds);
        }

        if (data.watchdog.session) {
            sessionUpdates(data.watchdog.session);
        }

        if (data.watchdog.deletedSession) {
            if (!window.top) {
                return;
            }

            const existingSession = readSession({sessionId});
            // console.log("got deleted session");
            // Recurrence instance was deleted, redirect to parent
            // console.log("existing recurrence id", existingSession?.instanceOfRecurrence?.session.id);
            if (existingSession?.instanceOfRecurrence?.session.id) {
                window.location.href = "/session/" + existingSession?.instanceOfRecurrence?.session.id;

                return;
            }
            if (existingSession?.childOfBreakoutRooms) {
                // Redirect to BOR parent session
                const childOfBreakoutRooms = existingSession.childOfBreakoutRooms;
                window.location.href = "/session/" + childOfBreakoutRooms.parentOfBreakoutRooms.sessionId;
                return;
            }

            window.top.location.href = "/";
            return;
        }
        if (data.watchdog.deletedParticipantIds) {
            const currentParticipant = readCurrentParticipant({sessionId});
            if (data.watchdog.deletedParticipantIds.find((p) => p === currentParticipant?.id) != null) {
                // He has been removed from the session
                // TODO: Alex - this is a temporary solution, we should have a better way to handle this - copilot suggested text, lol
                setIsKicked({kicked: true, banned: true, removed: true});

                history.push({
                    pathname: `/feedback/${sessionId}/${currentParticipant.id}`,
                    state: {
                        isParticipantInSession: true,
                    },
                });

                return;
            }
            orchestrator.onSubscriptionDeleteParticipants(sessionId, data.watchdog.deletedParticipantIds);
        }
        if (data.watchdog.deletedMacroArtifactIds) {
            orchestrator.onSubscriptionDeleteMacroArtifacts(sessionId, data.watchdog.deletedMacroArtifactIds);
        }

        if (data.watchdog.upsertMacroArtifacts) {
            orchestrator.onSubscriptionUpsertMacroArtifacts(sessionId, data.watchdog.upsertMacroArtifacts);
        }
        if (data.watchdog.resourceNames) {
            orchestrator.onSubscriptionResourceNames(sessionId, data.watchdog.resourceNames);
        }
        if (data.watchdog.upsertRecordings) {
            orchestrator.onSubscriptionUpsertRecordings(sessionId, data.watchdog.upsertRecordings);
        }
    };

    const processPrivateData = async (data: WatchdogPrivatePayload) => {
        console.log("WATCHDOG PRIVATE", data.watchdogPrivate);
        if (data.watchdogPrivate.nameOfJoinDeniedParticipant != null) {
            clientEvents.emit("feature-limit-error", {
                feature: LimitedFeature.PARTICIPANTS_IN_SESSION,

                message: data.watchdogPrivate.nameOfJoinDeniedParticipant
                    ? [
                          {
                              key: "limitations.dialog.participant_limit_reached",
                              options: {
                                  participant_name: data.watchdogPrivate.nameOfJoinDeniedParticipant,
                                  max_participants_number: maximumParticipantsInSessionForPlan[plan],
                                  plan_name: getPlanTitle(plan),
                              },
                          },
                          {
                              key: "limitations.dialog.participant_limit_reached_description",
                              options: {
                                  max_participants_number: maximumParticipantsInSessionForPlan[plan],
                                  plan_name: getPlanTitle(plan),
                              },
                          },
                      ]
                    : [
                          {
                              key: "limitations.dialog.participant_limit_reached_guest",
                              options: {
                                  participant_name: data.watchdogPrivate.nameOfJoinDeniedParticipant,
                                  max_participants_number: maximumParticipantsInSessionForPlan[plan],
                                  plan_name: getPlanTitle(plan),
                              },
                          },
                          {
                              key: "limitations.notifications.participant_limit_reached_description",
                              options: {
                                  max_participants_number: maximumParticipantsInSessionForPlan[plan],
                                  plan_name: getPlanTitle(plan),
                              },
                          },
                      ],
                display: "notification",
            });
        }

        if (data.watchdogPrivate.sessionEndedByLimitation != null) {
            if (data.watchdogPrivate.sessionEndedByLimitation === 0) {
                clientEvents.emit("feature-limit-error", {
                    feature: LimitedFeature.SESSION_LENGTH,
                    message: {
                        key: "limitations.notifications.session_reached_max_duration",
                        options: {
                            max_duration: sessionLengthLimitationByPlanInSeconds[plan] / 60,
                            plan_name: getPlanTitle(plan),
                        },
                    },
                });
            } else {
                clientEvents.emit("feature-limit-error", {
                    feature: LimitedFeature.SESSION_LENGTH,
                    message: [
                        {
                            key: "limitations.dialog.session_will_end_soon",
                            options: {},
                        },
                        {
                            key: "limitations.dialog.session_will_end_soon_description",
                            options: {
                                plan_name: getNextAvailablePlan(plan),
                            },
                        },
                    ],
                    display: "notification",
                });
            }
        }
    };

    useEffect(() => {
        return () => {
            clientEvents.emit("watchdog-mounted", false);
        };
    }, []);

    // NOTE
    // The GenericSubscriber is used as a wrapper here
    // to avoid adding a React.Fragment wrapper
    // but for cases where a wrapper/parent container already exists
    // it can be a simple child, alongside other children
    return (
        <>
            <GenericSubscriber
                document="WatchdogDocument"
                onData={processData}
                useCallbackQueue={true}
                options={{
                    variables: {
                        sessionId: props.sessionId,
                    },
                    shouldResubscribe: true,
                }}
                key="watchdog"
            />
            <GenericSubscriber
                document="WatchdogPrivateDocument"
                onData={processPrivateData}
                useCallbackQueue={true}
                options={{
                    variables: {
                        sessionId: props.sessionId,
                    },
                    shouldResubscribe: true,
                }}
                key="watchdogPrivate"
            />
            {props.eventMode ? null : <MicroNotificationsDebouncer key="notif" connectorRef={debouncedNotificationsConnectorRef} />}
            <Fragment key="children">{props.children}</Fragment>

            {useMemo(
                () => (
                    <GenericSubscriber
                        document={isOwner || isAssistant ? "PollUpdatesForOwnerDocument" : "PollUpdatesForParticipantDocument"}
                        onData={() => {}}
                        options={{
                            variables: {
                                sessionId: props.sessionId,
                            },
                        }}
                    />
                ),
                [isOwner, isAssistant, props.sessionId]
            )}
        </>
    );
}
export default Watchdog;
