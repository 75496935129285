import {SessionView, SessionViewContext} from "@workhorse/providers/SessionViewProvider";
import {ReactComponent as SessionViewCollaborationConferenceIcon} from "../../../assets/media/session-view-collaboration-conference.svg";
import {ReactComponent as SessionViewPresentationConferenceIcon} from "../../../assets/media/session-view-presentation-conference.svg";
import {ReactComponent as SessionViewAudienceConferenceIcon} from "../../../assets/media/session-view-audience-conference.svg";
import {getGlobalKeybind} from "@workhorse/util/keybinds";

type SessionViewInfoType = {
    translateKey: string;
    label: string;
    icon: {
        [ViewContext in SessionViewContext]: React.FunctionComponent<
            React.SVGProps<SVGSVGElement> & {
                title?: string | undefined;
            }
        >;
    };
    keybind?: string[];
};

export const SESSION_VIEW_MENU: {[View in SessionView]?: SessionViewInfoType} = {
    collaboration: {
        translateKey: "collaboration", // for internalization
        label: "Gallery",
        icon: {
            conference: SessionViewCollaborationConferenceIcon,
            tool: SessionViewCollaborationConferenceIcon,
        },
        keybind: getGlobalKeybind("switchPlayerViewModeGallery"),
    },
    spotlight: {
        translateKey: "spotlight", // for internalization
        label: "Spotlight",
        icon: {
            conference: SessionViewPresentationConferenceIcon,
            tool: SessionViewPresentationConferenceIcon,
        },
        keybind: getGlobalKeybind("switchPlayerViewModeSpotlight"),
    },
    audience: {
        translateKey: "audience", // for internalization
        label: "Audience",
        icon: {
            conference: SessionViewAudienceConferenceIcon,
            tool: SessionViewAudienceConferenceIcon,
        },
        keybind: getGlobalKeybind("switchPlayerViewModeAudience"),
    },
};
