import {ArtifactTag} from "@generated/artifacts";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {getArtifactImageById} from "@workhorse/util";
import ArtifactPlaceholderIcon from "../../../../../assets/media/artifact-placeholder.svg";
import {ReactComponent as ToolsIcon} from "../../icons/tools.svg";
import classes from "./styles/OnboardingEmbedSessionDialog.module.scss";
import {useHistory} from "@workhorse/api/routing";
import {useState, useRef, useEffect} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {useUserInfo} from "@workhorse/providers/User";
import {createOnboardingSession} from "../../api/session-onboarding";
import {useOnboardingSession, SessionOnboardingType} from "@workhorse/providers/OnboardingSessionProvider";
import MyProductBlank from "../../../../../assets/media/icon-my-product-no-bg.svg";
import {brandingLogo} from "@workhorse/pages/user/profile/Theming/utils";
import clientEvents from "@api/events/client";
import {MyProductToolsDocument} from "@generated/data";
import {useQuery} from "@apollo/client";

type ContentType = "embed-owner" | "embed-member" | "embed-tool";

type ContentInfoType = {
    title: string | JSX.Element;
    description: string | JSX.Element;
    icon?: JSX.Element;
    onConfirm: () => void;
    onClose?: () => void;
    className?: string;
};

const artifactsToDisplay: ArtifactTag[] = [
    "flowos/miro",
    "flowos/airtable",
    "flowos/mentimeter",
    "flowos/youtube",
    "flowos/pitch",
    "flowos/figma",
    "flowos/google-docs",
    "flowos/slido",
    "flowos/canva",
    "flowos/boardmix",
];

type OnboardingEmbedSessionDialogProps = {
    type: ContentType;
    onConfirm?: () => void;
    onClose?: () => void;
};

function OnboardingEmbedSessionDialog(props: OnboardingEmbedSessionDialogProps) {
    const {type, onConfirm, onClose} = props;

    const {next, tooltip} = useOnboardingSession();
    const user = useUserInfo();

    const workspaceId = user.activeWorkspace.id;

    const myProductTool = useQuery(MyProductToolsDocument, {
        variables: {
            workspaceId,
        },
    }).data?.myProductTools?.[0];
    const toolName = myProductTool?.title ?? "your tool";
    const customBrandingLogo = brandingLogo();

    const toolIcon = myProductTool?.iconUrl || customBrandingLogo || MyProductBlank;
    const dialogRef = useRef<DialogImperativeRef>();

    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const handleToggleProductTool = () => {
        onConfirm?.();
    };

    const handleCreateOnboardingSession = async () => {
        try {
            setLoading(true);
            const result = await createOnboardingSession(SessionOnboardingType.SecondStrikeMember, user.id);
            if (result == null) {
                return;
            }
            history.push(`/session/${result.id}`);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);

            await markAsSeen({
                ONBOARDING_FEATURES: {
                    onboardingSessionSecondStrikeMember: true,
                },
            });

            onConfirm?.();
        }
    };

    const handleEmbedToolContinue = () => {
        next({delay: 1000});
    };

    const content: {[key in ContentType]: ContentInfoType} = {
        "embed-owner": {
            title: "Embed your product in Sessions",
            description:
                "Bring your digital product or web app directly into your meetings, and allow your participants to try it out. It’s a game-changer for training, sales, and demos.",
            icon: <ToolsIcon />,
            onConfirm: handleToggleProductTool,
            onClose: onClose,
            className: classes.rootOwner,
        },
        "embed-member": {
            title: "Embed tools directly into a session",
            description:
                "Make your meetings more interactive and engaging by embedding tools and files. It’s great for slide decks, videos, smartboards, polls, Google docs, and more.",
            onClose: onClose,
            onConfirm: handleCreateOnboardingSession,
        },
        "embed-tool": {
            title: `Let’s see ${toolName} in action!`,
            description:
                "You’ve successfully created your own product tool. Now let’s enter a live session to try it out. You’ll be alone in this session, so feel free to take your time and play around.",
            onConfirm: handleEmbedToolContinue,
            icon: (
                <div className={classes.toolIconContainer}>
                    <img src={ArtifactPlaceholderIcon} alt="artifact-placeholder" />
                    <img src={toolIcon} alt="tool-icon" />
                </div>
            ),
        },
    };

    useEffect(() => {
        if (
            (type === "embed-tool" && tooltip === "tool-dialog" && !dialogRef.current?.isOpen) ||
            (type === "embed-tool" && tooltip !== "tool-dialog" && dialogRef.current?.isOpen)
        ) {
            if (type === "embed-tool" && tooltip === "tool-dialog" && !dialogRef.current?.isOpen) {
                clientEvents.emit("prevent-palette-open", true);
            } else {
                clientEvents.emit("prevent-palette-open", false);
            }
            dialogRef.current?.toggle?.();
        }
    }, [tooltip]);

    const dataIdString = (type === "embed-owner" || type === "embed-tool") && "onboarding-button-strike2-continue";

    return (
        <Dialog
            imperativeRef={dialogRef}
            open={type !== "embed-tool"}
            classes={{paper: cls(classes.root, content[type].className)}}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={classes.icons}>
                {Array.from(Array(3)).map((_, i) => (
                    <img src={ArtifactPlaceholderIcon} alt="artifact-placeholder" key={`artifact-placeholder-top-${i}`} />
                ))}
                {artifactsToDisplay.map((a) => {
                    if (a === "flowos/pitch" && content[type].icon) {
                        return content[type].icon;
                    }
                    return <img src={getArtifactImageById(a)} alt={a} key={a} />;
                })}
                {Array.from(Array(3)).map((_, i) => (
                    <img src={ArtifactPlaceholderIcon} alt="artifact-placeholder" key={`artifact-placeholder-bottom-${i}`} />
                ))}
            </div>
            <div className={classes.content}>
                <Typography variant="xl2" fontWeight="bolder" color="secondary" className="mb-15">
                    {content[type].title}
                </Typography>
                <Typography color="quaternary" className="mb-15">
                    {content[type].description}
                </Typography>

                <div className={cls("flex flex-items-center", classes.buttons)}>
                    {content[type]?.onClose && (
                        <Button variant="quaternary" onClick={content[type].onClose} data-id="onboarding-button-strike2-maybe-later">
                            Maybe later
                        </Button>
                    )}
                    <Button onClick={content[type].onConfirm} loading={loading} disabled={loading} data-id={dataIdString}>
                        Continue
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default OnboardingEmbedSessionDialog;
