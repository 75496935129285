import {cls} from "@ui/cdk/util/util";
import {ReactNode} from "@workhorse/api/rendering";
import classes from "./style/NotificationWrapper.module.scss";
import IconButton from "@ui/core/components/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

type NotificationWrapperType = {
    children?: ReactNode;
    className?: string;
    onClose?: () => void;
};

function NotificationWrapper(props: NotificationWrapperType) {
    const {children, className, onClose} = props;

    return (
        <div className={cls(classes.notification, className)}>
            {children}
            {onClose && (
                <IconButton onClick={onClose} className={classes.close}>
                    <CloseRoundedIcon className={classes.closeIcon} />
                </IconButton>
            )}
        </div>
    );
}

export default NotificationWrapper;
