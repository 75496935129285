import {BackgroundFilter} from "../../lib/VideoReplacement/VideoReplacementBackgrounds";

function getPreviousValue<T>(key: string, userId: string) {
    try {
        const itemKey = `${key}-${userId}`;
        const value = localStorage.getItem(itemKey);
        localStorage.removeItem(itemKey);

        if (value) {
            return JSON.parse(value) as T;
        }
    } catch (e) {
        //
    }
}

function setCurrentValue(key: string, userId: string, value: string) {
    localStorage.setItem(`${key}:${userId}`, value);
}

export function migrateSettings(userId: string) {
    const migrationStatus = localStorage.getItem(`devices.migration:${userId}`);

    if (migrationStatus === "v2") {
        return;
    }

    const v1VideoDeviceId = getPreviousValue<string>("user.video.deviceId", userId);
    const v1AudioInputDeviceId = getPreviousValue<string>("user.audio.input.deviceId", userId);
    const v1AudioOutputDeviceId = getPreviousValue<string>("user.audio.output.deviceId", userId);
    const v1BackgroundFilter = getPreviousValue<string>("user.video.backgroundFilter", userId);
    const v1NoiseReduction = getPreviousValue<boolean>("user.audio.noiseReduction", userId);

    const v1VideoEnabled = getPreviousValue<boolean>("user.video.enabled", userId);
    const v1AudioEnabled = getPreviousValue<boolean>("user.audio.input.enabled", userId);

    if (v1VideoDeviceId) {
        setCurrentValue("devices.video.settings", userId, JSON.stringify({deviceId: v1VideoDeviceId}));
    }

    if (v1AudioInputDeviceId) {
        setCurrentValue("devices.audio.settings", userId, JSON.stringify({deviceId: v1AudioInputDeviceId}));
    }

    if (v1AudioOutputDeviceId) {
        setCurrentValue("devices.speakers.device", userId, v1AudioOutputDeviceId);
    }

    if (v1BackgroundFilter) {
        if (Object.values(BackgroundFilter).includes(v1BackgroundFilter as BackgroundFilter)) {
            setCurrentValue("devices.video.filter", userId, v1BackgroundFilter);
        }
    }

    if (v1NoiseReduction) {
        setCurrentValue("devices.audio.noise-reduction", userId, "1");
    }

    if (v1VideoEnabled === false) {
        setCurrentValue("devices.video.enabled", userId, "0");
    }

    if (v1AudioEnabled === false) {
        setCurrentValue("devices.audio.muted", userId, "1");
    }

    localStorage.setItem(`devices.migration:${userId}`, "v2");
}
