import {CelloService} from "@workhorse/api/cello";
import {useEffect} from "@workhorse/api/rendering";
import {WithChildren} from "@workhorse/declarations";
import OnboardingWrapper from "./components/OnboardingWrapper";

const Onboarding = (props: WithChildren) => {
    const {children} = props;

    useEffect(() => {
        const service = new CelloService();
        service.init();
    }, []);

    return <OnboardingWrapper>{children}</OnboardingWrapper>;
};

export default Onboarding;
