import {useMobile} from "@workhorse/providers/MobileProvider";
import {AIGeneratedAgendaItem} from "../AgendaGeneratorMessenger";
import GeneratedAgendaItemPreview from "./GeneratedAgendaItemPreview";

type GeneratedAgendaPreviewProps = {
    generatedAgenda: Array<AIGeneratedAgendaItem & {id: string}> | never[];
    selectAgendaItems?: (agendaItem: AIGeneratedAgendaItem & {id: string}) => void;
    selectedAgendaItems?: Array<AIGeneratedAgendaItem & {id: string}> | never[];
    isSingleAgendaItem: boolean;
    hideCheckbox?: boolean;
};

const GeneratedAgendaPreview = (props: GeneratedAgendaPreviewProps) => {
    const {generatedAgenda, selectAgendaItems, selectedAgendaItems, isSingleAgendaItem, hideCheckbox} = props;

    const mobileState = useMobile();

    return (
        <>
            {isSingleAgendaItem ? (
                <GeneratedAgendaItemPreview
                    agendaItem={generatedAgenda[0]}
                    selectAgendaItems={selectAgendaItems}
                    mobileState={mobileState}
                    selectedAgendaItems={selectedAgendaItems}
                    isSingleAgendaItem={isSingleAgendaItem}
                    hideCheckbox={hideCheckbox}
                />
            ) : (
                <>
                    {generatedAgenda.map((agendaItem) => (
                        <div key={agendaItem?.id}>
                            <GeneratedAgendaItemPreview
                                agendaItem={agendaItem}
                                selectAgendaItems={selectAgendaItems}
                                mobileState={mobileState}
                                selectedAgendaItems={selectedAgendaItems}
                                isSingleAgendaItem={isSingleAgendaItem}
                                hideCheckbox={hideCheckbox}
                            />
                        </div>
                    ))}
                </>
            )}
        </>
    );
};

export default GeneratedAgendaPreview;
