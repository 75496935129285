import {SpeakerDetailsFragment} from "@generated/data";
import {cls} from "@ui/cdk/util/util";
import Paper from "@ui/core/components/Paper";
import {MouseEventHandler} from "@workhorse/api/rendering";
import {getColor, makeInitials} from "@workhorse/util";
import {ContactFetcherDetails} from "./ContactFetcherDetails";
import classes from "./styles/ContactFetcherPaper.module.scss";

interface Props {
    selected: boolean;
    index: number;
    search?: string;
    speaker: SpeakerDetailsFragment;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export function ContactFetcherSpeaker(props: Props) {
    const content: string[] = [];
    const {speaker} = props;
    const email = speaker.email;
    const thumbnailUrl = speaker.thumbnailUrl;
    const photoUrl = speaker.photoUrl;
    const name = speaker.name;

    if (name) {
        content.push(name);
    }

    if (!name) {
        content.push("(Untitled speaker)");
    }

    const initials = makeInitials(name, "");
    const backgroundColor = !thumbnailUrl ? getColor(initials) : undefined;

    return (
        <Paper
            elevation={2}
            className={cls("flex flex-items-center", classes.paperClass, props.selected && classes.selected)}
            onPointerDown={props.onClick}
            id={`result-paper-${props.index}`}
        >
            <ContactFetcherDetails
                classes={{
                    wrap: classes.infoBlock,
                    details: classes.searchResultEmail,
                    content: classes.searchResultName,
                }}
                search={props.search || ""}
                content={content.join(" ")}
                details={email || ""}
                avatar={thumbnailUrl || photoUrl || undefined}
                initials={initials}
                avatarBackground={backgroundColor}
                delimiter={<div className={classes.dot} />}
            />
        </Paper>
    );
}
