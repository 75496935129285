import {DrawerRightWidths} from "@workhorse/declarations";
import {useFullScreenState} from "./state";

export const useDrawerRightWidth = (): DrawerRightWidths => {
    // we'll be using this hook to compute different drawer widths based on location and session current view
    const isFullScreen = useFullScreenState();
    return {
        isSemiOpen: 93,
        isFullyOpen: 364,
        isDetached: 364,
        isFullyOpenMobile: "100%",
    };
};
