import {makeId} from "@workhorse/api/designer/lib/utils";
import {useState} from "@workhorse/api/rendering";
import {WithClassName} from "@workhorse/declarations";

function MacroTranscriptIcon(props: WithClassName) {
    const [id] = useState(() => makeId());

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <g clipPath={`url(#${id})`}>
                <path
                    d="M10.289 17.9749L9.93245 16.9489H11.6628C12.997 16.9489 14.0835 16.1263 14.539 15.0161C14.5816 15.0233 14.626 15.0269 14.6722 15.0269H16.7125C16.9052 15.0269 17.0669 14.9644 17.1974 14.8395C17.3276 14.715 17.3926 14.5606 17.3926 14.3763C17.3926 14.192 17.3276 14.0374 17.1974 13.9125C17.0669 13.788 16.9052 13.7258 16.7125 13.7258H14.7676C14.7559 13.4349 14.7024 13.139 14.6023 12.8446L14.4596 12.4247H16.7125C16.9052 12.4247 17.0669 12.3623 17.1974 12.2374C17.3276 12.1129 17.3926 11.9585 17.3926 11.7742C17.3926 11.5899 17.3276 11.4352 17.1974 11.3103C17.0669 11.1859 16.9052 11.1236 16.7125 11.1236H14.0172L13.5748 9.82256H16.7125C16.9052 9.82256 17.0669 9.76011 17.1974 9.63521C17.3276 9.51074 17.3926 9.35634 17.3926 9.17202C17.3926 8.9877 17.3276 8.83309 17.1974 8.70819C17.0669 8.58372 16.9052 8.52148 16.7125 8.52148H13.1325L11.7249 4.3817H17.3926C18.3725 4.3817 19.1668 5.17603 19.1668 6.15589V17.3924C19.1668 18.3723 18.3725 19.1666 17.3926 19.1666H11.9649C11.2095 19.1666 10.537 18.6884 10.289 17.9749Z"
                    fill="#2E90FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.84235 0.833313H2.60769C1.62783 0.833313 0.833496 1.62765 0.833496 2.60751V13.8441C0.833496 14.8239 1.62783 15.6183 2.60769 15.6183H11.6628C12.8782 15.6183 13.7338 14.4237 13.3425 13.2729L9.52211 2.03639C9.27754 1.31707 8.60211 0.833313 7.84235 0.833313ZM7.26961 7.99234C7.26961 8.76738 6.63993 9.39302 5.85988 9.39302C5.07983 9.39302 4.45015 8.76738 4.45015 7.99234V5.19098C4.45015 4.41594 5.07983 3.7903 5.85988 3.7903C6.63993 3.7903 7.26961 4.41594 7.26961 5.19098V7.99234ZM8.17653 8.3892C8.21412 8.16042 8.40679 7.99234 8.63704 7.99234C8.92369 7.99234 9.15394 8.24446 9.10695 8.5246C8.8767 9.92528 7.74891 11.0225 6.32979 11.2232V12.1944C6.32979 12.4512 6.11833 12.6613 5.85988 12.6613C5.60143 12.6613 5.38997 12.4512 5.38997 12.1944V11.2232C3.97084 11.0225 2.84306 9.92528 2.6128 8.5246C2.57051 8.24446 2.79607 7.99234 3.08271 7.99234C3.31297 7.99234 3.50563 8.16042 3.54323 8.3892C3.73589 9.4864 4.6992 10.3268 5.85988 10.3268C7.02055 10.3268 7.98387 9.4864 8.17653 8.3892Z"
                    fill="#2E90FA"
                />
            </g>
            <defs>
                <clipPath id={id}>
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default MacroTranscriptIcon;
