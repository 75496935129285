import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {useOnboardingSession} from "@workhorse/providers/OnboardingSessionProvider";
import {OnboardingTooltip} from "./onboarding-tooltip";
import {TooltipButtonArrow, WithTooltipChildren} from "./onboarding-tooltips-utils";
import {cls} from "@ui/cdk/util";
import style from "./onboarding.module.scss";

export function OnboardingProductHeader(props: WithTooltipChildren) {
    const {next} = useOnboardingSession();

    return (
        <OnboardingTooltip
            tooltipId={"my-product-header"}
            title={
                <>
                    <Typography className="title" fontWeight="bolder">
                        My Product control bar
                    </Typography>

                    <Typography className="mt-6" variant="sm" fontWeight="normal" color="septenary">
                        From here you can give participants the chance to control the mouse pointer and interact with your product.
                    </Typography>

                    <Button
                        variant="primary"
                        onClick={() =>
                            next({
                                delay: 1000,
                            })
                        }
                        className={cls("ml-auto mt-8", style.button)}
                        size="small"
                        data-id="onboarding-button-strike2-step3-next"
                    >
                        Next <TooltipButtonArrow />
                    </Button>
                </>
            }
            classes={{
                tooltip: style.microTooltip,
            }}
            placement="bottom"
            arrow={true}
        >
            {props.children}
        </OnboardingTooltip>
    );
}
