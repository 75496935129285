import {SessionLifecycle} from "@generated/data";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import {startingSession} from "@workhorse/pages/player/playerStateVars";
import {playSoundAction, SoundActionType} from "@workhorse/pages/player/soundActions";
import {readSession} from "../SessionDataProviders";
import {WatchdogPayload} from "./Watchdog";

export function sessionUpdates(incomingSessionReadOnly: NonNullable<WatchdogPayload["watchdog"]["session"]>) {
    const incomingSession = structuredClone(incomingSessionReadOnly);
    const sessionId = incomingSession.id.replace("watchdog-", "");
    // @ts-ignore
    delete incomingSession?.id;

    const existingSession = readSession({sessionId});

    if (existingSession && existingSession.id === sessionId) {
        if (incomingSession.recurrenceData?.firstInstance) {
            // Session was not recurrent, and now it is, we need to redirect from the parent to the child
            window.location.href = `/session/${incomingSession.recurrenceData.firstInstance.id}`;
        }

        const newSession = {
            ...existingSession,
            ...incomingSession,
            lifecycle: startingSession() ? SessionLifecycle.Started : incomingSession.lifecycle ?? existingSession.lifecycle,
            ...(incomingSession.lobbyPicture?.id
                ? {
                      lobbyPicture: {
                          ...existingSession.lobbyPicture,
                          ...incomingSession.lobbyPicture,
                      },
                  }
                : null),
        };
        writeFromFullSession(sessionId, {
            session: newSession,
        });

        const sessionJustStarted =
            existingSession?.lifecycle === SessionLifecycle.NotStarted && incomingSession?.lifecycle === SessionLifecycle.Started;
        if (sessionJustStarted) {
            playSoundAction(SoundActionType.SessionStarted);
        }
    }
}
