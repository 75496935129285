import {SessionTimerState} from "@generated/data";
import {differenceInSeconds} from "date-fns";

export const digitsOnlyRegex = /^\d+$/;

export const getIntialTimer = (isPopup?: boolean) => (isPopup ? 0 : 300); // seconds

export const getDisplayValue = (value: number) => (value < 10 ? "0" + value : value + "");

export const getInitialPopupState = (
    state?: SessionTimerState | null,
    timerStartAt?: Date | null,
    timerCurrentDuration?: number | null
) => {
    if (!state || ![SessionTimerState.Started, SessionTimerState.Paused].includes(state)) {
        return false;
    }

    const secondsPassed = differenceInSeconds(new Date(), timerStartAt ? new Date(timerStartAt) : new Date());
    const secondsLeft = (timerCurrentDuration ?? 0) - secondsPassed > 0 ? (timerCurrentDuration ?? 0) - secondsPassed : 0;

    return secondsLeft > 0;
};
