import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import {useIsMounted} from "@workhorse/api/isMounted";
import {forwardRef, useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {cls} from "@ui/cdk/util/util";
import {isMacOS} from "@workhorse/util";
import {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import NotAllowedDialog from "../../RecordingDialogs/NotAllowedDialog";
import browserInfo from "@workhorse/api/BrowserInfo";
import MenuItem from "@ui/cdk/MenuItem";
import {useMobile} from "@workhorse/providers/MobileProvider";
import ShareScreenMenu from "./components/ShareScreenMenu";
import {useCurrentAgendaItem, useMacroArtifacts} from "@workhorse/providers/SessionDataProviders";
import {SessionFlagsType} from "@generated/data";
import {sessionFlagBasedPermission} from "@workhorse/api/access/SessionFlagsBasedAccsess";
import {useClientEvent} from "@api/events/client";
import {getGlobalKeybindString} from "@workhorse/util/keybinds";
import {ariaAnnouncerMessageVar} from "@workhorse/components/ARIAAnnouncer";
import {useTranslation} from "react-i18next";
import {useMutation} from "@workhorse/dataApi";
import {useScreenShareOptions} from "@workhorse/api/conference2/providers/ConferenceRoomProvider";
import {emitStopScreenShare} from "@workhorse/api/conference/sessionApi";
type ShareScreenTogglerProps = {
    className?: string;
    sessionId: string;
    isAdmin: boolean;
    isAssistant: boolean;
    isSpeaker: boolean;
    allowScreenShareType: SessionFlagsType;
    showTooltip: (e?: any, message?: string) => void;
    hideText?: boolean;
};

function ShareScreenToggler(props: ShareScreenTogglerProps) {
    const {showTooltip, hideText, isAdmin, isAssistant, sessionId} = props;
    const [sendAnalyticsEvent] = useMutation("SendAnalyticsEventDocument");

    const isChromium = browserInfo.isChromium();

    const isMounted = useIsMounted();

    const {isMobileOrTablet} = useMobile();

    const {startShareScreen, stopShareScreen, shareDisabled, sharingParticipantId, isLocalUserSharing} = useScreenShareOptions();

    const [trackEvent] = useTrackingEvents();

    const isSharingActive = isLocalUserSharing || (!!sharingParticipantId && (isAdmin || isAssistant));

    const [sharing, setSharing] = useState<boolean>(isSharingActive);

    const notAllowedDialogRef = useRef<DialogImperativeRef>();

    const shareEnabled = sharingParticipantId != null;

    const popoverRef = useRef<HTMLLIElement | null>(null);

    const currentAgendaItem = useCurrentAgendaItem();
    const macroArtifacts = useMacroArtifacts();
    const hasAgenda = macroArtifacts.some((macro) => macro.artifactId === "flowos/agenda");
    const initialHasAgenda = useRef<boolean>(hasAgenda);
    const initialCurrentAgendaItemId = useRef<string | undefined>(currentAgendaItem.artifact?.id);

    useEffect(() => {
        if (
            (!initialHasAgenda.current && hasAgenda) ||
            (initialCurrentAgendaItemId.current !== currentAgendaItem.artifact?.id &&
                currentAgendaItem.artifact?.artifactId !== "flowos/conference")
        ) {
            if (isLocalUserSharing && !isAdmin && !isAssistant) {
                stopShareScreen();
                setSharing(false);
            }
            initialHasAgenda.current = hasAgenda;
            initialCurrentAgendaItemId.current = currentAgendaItem.artifact?.id;
        }
    }, [currentAgendaItem, hasAgenda, isAdmin, isAssistant, isLocalUserSharing]);

    useEffect(() => {
        setSharing(isSharingActive);
    }, [isSharingActive]);

    const togglingRef = useRef(false);

    const handleToggleNotAllowedDialog = useCallback(() => {
        notAllowedDialogRef.current?.toggle?.();
    }, []);

    const isSharingAllowed = sessionFlagBasedPermission(props.allowScreenShareType, {
        speaker: props.isSpeaker,
        owner: props.isAdmin,
        assistant: props.isAssistant,
    });

    const canEnable = !shareDisabled && isSharingAllowed && (shareEnabled ? sharing : true);

    const toggleShareScreen = async () => {
        if (!canEnable) {
            return;
        }

        if (sharing) {
            if (isLocalUserSharing) {
                stopShareScreen();
            } else if (sharingParticipantId) {
                emitStopScreenShare(sessionId, [sharingParticipantId]);
            }

            setSharing(false);
            return;
        }

        if (togglingRef.current) {
            return;
        }

        trackEvent("start-share-screen", {event_category: TrackingEventCategory.PlayerActions});

        try {
            togglingRef.current = true;
            await startShareScreen();

            // sendAnalyticsEvent({
            //     variables: {
            //         eventName: "share-screen",
            //         sessionId,
            //     },
            // });

            if (isMounted()) {
                setSharing(true);
            }
        } catch (e) {
            if (isMounted()) {
                setSharing(false);
            }
            if (
                e instanceof Error &&
                e.name === "NotAllowedError" &&
                isMacOS() &&
                (!isChromium || (isChromium && e.message === "Permission denied by system"))
            ) {
                handleToggleNotAllowedDialog();
            }
        } finally {
            togglingRef.current = false;
        }
    };

    const className = cls(props.className, sharing && shareEnabled && "active-blue");

    return (
        <>
            <ShareScreenButton
                className={className}
                disabled={!canEnable}
                stopShare={shareEnabled && sharing}
                onClick={toggleShareScreen}
                showTooltip={showTooltip}
                hideText={hideText}
                ref={popoverRef}
                allowScreenShare={canEnable}
                isAdmin={props.isAdmin}
                isAssistant={props.isAssistant}
                sessionId={props.sessionId}
                isMobileOrTablet={isMobileOrTablet}
                participantId={sharingParticipantId! /*TODO VASI */}
            />

            <NotAllowedDialog dialogRef={notAllowedDialogRef} onClick={handleToggleNotAllowedDialog} />
        </>
    );
}

interface ShareScreenButtonProps {
    disabled?: boolean;
    className?: string;
    stopShare?: boolean;
    onClick?: () => void | Promise<void>;
    showTooltip: (e?: any, message?: string) => void;
    hideText?: boolean;
    allowScreenShare: boolean;
    isAdmin: boolean;
    isAssistant: boolean;
    sessionId: string;
    isMobileOrTablet: boolean;
    participantId: string;
}

const ShareScreenButton = forwardRef((props: ShareScreenButtonProps, ref: React.MutableRefObject<HTMLLIElement | null>) => {
    const {t} = useTranslation();
    const {showTooltip, disabled, hideText, allowScreenShare, isAdmin, isAssistant, sessionId, isMobileOrTablet, participantId} = props;

    const keybind = getGlobalKeybindString("toggleScreenShare");

    const onMouseEnter = (e: any) => {
        if (!isAdmin && !isAssistant) {
            const message = !allowScreenShare
                ? t("participant.share.sharing_is_disabled")
                : disabled
                ? ""
                : props.stopShare === true
                ? t("participant.share.stop_share_screen")
                : `${t("participant.share.share_screen")} (${keybind})`;

            if (!message) {
                return;
            }
            showTooltip(e, message);
        }
    };

    const onMouseLeave = () => {
        showTooltip();
    };

    useClientEvent("player-keybind-toggle-screenshare", () => {
        if (props.stopShare) {
            ariaAnnouncerMessageVar(t("aria_announcer.stopped_share_screen") ?? "Stopped sharing screen");
        }

        props.onClick?.();
    });

    return (
        <ShareScreenMenu
            canEnable={!disabled}
            disabled={isMobileOrTablet || (!isAdmin && !isAssistant)}
            sessionId={sessionId}
            isAdmin={isAdmin}
            isAssistant={isAssistant}
            participantId={participantId}
        >
            <MenuItem
                data-id="player-button-share-screen"
                className={cls(props.className, disabled && "disabled")}
                onClick={props.onClick}
                disabled={disabled}
                button={true}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={ref}
                role="button"
                aria-label={`${
                    props.stopShare ? t("participant.share.stop_share_screen") : t("participant.share.share_screen")
                }, (${keybind})`}
                aria-disabled={disabled}
                tabIndex={0}
            >
                {props.stopShare === true ? <StopScreenShareIcon fontSize="small" /> : <ScreenShareIcon fontSize="small" />}
                {hideText ? null : (
                    <span className="text">
                        {props.stopShare === true ? t("participant.share.stop_share") : t("participant.share.share_screen")}
                    </span>
                )}
            </MenuItem>
        </ShareScreenMenu>
    );
});

export default ShareScreenToggler;
