import {Participant} from "@workhorse/declarations/dataTypes";
import classes from "../styles/PendingJoinApprovals.module.scss";
import AvatarGroup from "@ui/cdk/AvatarGroup";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {getContactByEmail} from "@common/utils";
import ParticipantDisplayDetails from "@workhorse/pages/user/profile/ParticipantDisplayDetails";
import {useTranslation} from "react-i18next";
import {playSoundActionDebounced, SoundActionType} from "../soundActions";
import {useEffect} from "react";

type PendingJoinApprovalsProps = {
    participants: Participant[];
    onWaitingForApproval: (participantIds: string[], hasBeenApproved: boolean) => void;
    onManage: () => void;
    onAdmitAll: () => void;
};

function PendingJoinApprovalEntry(props: PendingJoinApprovalsProps) {
    const {t} = useTranslation();
    const {participants, onWaitingForApproval, onManage, onAdmitAll} = props;

    const contact = getContactByEmail(participants[0].dataWithNullableEmail.email);

    const singleParticipantFirstName = contact ? contact.firstName : participants[0].dataWithNullableEmail.firstName;
    const singleParticipantLastName = contact ? contact.lastName : participants[0].dataWithNullableEmail.lastName;

    const singleParticipantFullName = `${singleParticipantFirstName.trim()} ${singleParticipantLastName.trim()}`.trim();

    // biome-ignore lint/correctness/useExhaustiveDependencies: This effect should only run once
    useEffect(() => {
        const key = `sound:${participants[0].id}-${participants[0].dataWithNullableEmail.firstName}-${participants[0].dataWithNullableEmail.email}`;

        if (sessionStorage.getItem(key) === null) {
            playSoundActionDebounced(SoundActionType.RequestToJoin);
            sessionStorage.setItem(key, "true");
        }
    }, []);

    return (
        <div data-id="approval-request" className="flex flex-items-center fullw">
            <AvatarGroup avatarSize="XL" max={3} spacing={30} avatarVariant="rounded">
                {participants.map((p) => (
                    <ParticipantDisplayDetails
                        key={"pending-join-approvals-" + p.id}
                        id={p.id}
                        firstName={p.dataWithNullableEmail.firstName}
                        lastName={p.dataWithNullableEmail.lastName}
                        email={p.dataWithNullableEmail.email}
                        avatar={p.dataWithNullableEmail.avatar}
                        avatarVariant="rounded"
                        avatarSize="XL"
                        showEmail={false}
                        showInfo={false}
                        showName={false}
                        showProfilePicture={true}
                        participantIsAssistant={false}
                        className={classes.avatar}
                        classes={{
                            avatar: "mr-0",
                        }}
                    />
                ))}
            </AvatarGroup>

            <Typography className={classes.text} variant="lg" fontWeight="bolder">
                {participants.length > 1
                    ? t("player.notifications.participants.guests_want_to_join", {
                          guests_count: participants.length,
                      })
                    : t("player.notifications.participants.guest_wants_to_join", {
                          guest_name: singleParticipantFullName,
                      })}
            </Typography>

            <div className={classes.btnContainer}>
                {participants.length === 1 && (
                    <>
                        <Button data-id="decline" variant="tertiary" onClick={onWaitingForApproval.bind(null, [participants[0].id], false)}>
                            {t("participant.g.deny")}
                        </Button>
                        <Button data-id="accept" withMarginLeft onClick={onWaitingForApproval.bind(null, [participants[0].id], true)}>
                            {t("participant.g.admit")}
                        </Button>
                    </>
                )}

                {participants.length > 1 && (
                    <>
                        <Button data-id="manage" variant="tertiary" onClick={onManage}>
                            {t("participant.g.manage")}
                        </Button>

                        <Button data-id="accept-all" withMarginLeft onClick={onAdmitAll}>
                            {t("participant.g.admit_all")}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
}

export default PendingJoinApprovalEntry;
