import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import IconButton from "@ui/core/components/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import classes from "./DeviceCheckWarning.module.scss";
import {useTranslation} from "react-i18next";
import {HyperlinkExternal} from "@workhorse/util/links";

interface DeviceCheckWarningProps {
    title: string | JSX.Element;
    body: string | JSX.Element;
    bodyGif?: string;
    primaryAction?: string | JSX.Element;
    secondaryAction?: string | JSX.Element;
    readMoreLink?: string;
    className?: string;
    onClose?: () => void;
    onPrimaryAction?: () => void;
    onSecondaryAction?: () => void;
}

export function DeviceCheckWarning(props: DeviceCheckWarningProps) {
    const {t} = useTranslation();
    const link = getHelpCenterArticleLink("account-settings-tech-guide/audio-video-setup");

    return (
        <div className={classes.root}>
            <div className={`${classes.header} flex flex-row`}>
                <WarningRoundedIcon className={classes.headerIcon} />
                <Typography fontWeight="bolder">{props.title}</Typography>
                <IconButton className={classes.closeButton} onClick={props.onClose}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <Typography className="mt-4">{props.body}</Typography>
            <div className="flex flex-row mt-12">
                {props.primaryAction && (
                    <Button className="mr-12" variant="tertiary" size="small" onClick={props.onPrimaryAction}>
                        {props.primaryAction}
                    </Button>
                )}
                {props.secondaryAction && (
                    <Button className={classes.secondaryButton} variant="plain" size="small" onClick={props.onSecondaryAction}>
                        {props.secondaryAction}
                    </Button>
                )}
                {props.readMoreLink && (
                    <Button
                        className={classes.secondaryButton}
                        component={HyperlinkExternal}
                        variant="plain"
                        size="small"
                        target="_blank"
                        href={link}
                    >
                        {t("g.read_more") ?? "Read more"} <OpenInNewIcon className="ml-6" />
                    </Button>
                )}
            </div>
        </div>
    );
}
