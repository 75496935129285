import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import Button from "@ui/cdk/Button";
import Checkbox from "@ui/cdk/Checkbox";
import Link from "@ui/cdk/Link";
import ScrollContainer, {ScrollContainerRef} from "@ui/cdk/ScrollContainer";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import Accordion from "@ui/core/components/Accordion";
import AccordionDetails from "@ui/core/components/AccordionDetails";
import AccordionSummary from "@ui/core/components/AccordionSummary";
import FormControlLabel from "@ui/core/components/FormControlLabel";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {useRef, useState} from "@workhorse/api/rendering";
import {markAsSeen} from "@workhorse/api/seen";
import {useMutation} from "@workhorse/dataApi";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {onboardingTerms} from "./onboarding-terms";
import classes from "./styles/OnboardingTerms.module.scss";
import BrandingLogo from "@ui/cdk/BrandingLogo";

type OnboardingTermsProps = {
    onbordingExistingUsers: boolean;
};

const OnboardingTerms = (props: OnboardingTermsProps) => {
    const {onbordingExistingUsers} = props;

    const {isMobile} = useMobile();
    const [expanded, setExpanded] = useState<string | null>(null);

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const [updateUserOnboarding] = useMutation("UpdateUserOnboardingDocument", {
        fetchPolicy: "default",
    });

    const handleChange = (key: string, event: React.ChangeEvent) => {
        setExpanded(expanded !== key ? key : null);
    };

    const handleClick = () => {
        updateUserOnboarding({
            variables: {
                termsAccepted: true,
            },
        })
            .then((res) => {
                if (res.data?.updateUserOnboarding?.onboarding?.termsAccepted) {
                    markAsSeen({
                        GLOBALS: {
                            userOnboardingTerms: true,
                        },
                    });
                }
            })
            .catch(() => {
                console.error("Error updating user onboarding!");
            });
    };

    const scrollContainerRef = useRef<ScrollContainerRef>({});

    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setTermsAccepted(checked);
    };

    return (
        <div className={cls("flex flex-col flex11-100", classes.root)}>
            <header className={classes.header}>
                <BrandingLogo />
            </header>
            <div className={cls("flex flex-col", classes.content)}>
                <div className={cls("flex flex-items-center mb-10", classes.contentTitle)}>
                    <Typography variant="xl3">{onbordingExistingUsers ? "Updated " : ""}Terms Of Service</Typography>
                </div>
                <Typography color="tertiary" className={classes.contentSubtitle}>
                    {onbordingExistingUsers ? (
                        <>We have updated Terms & Conditions.</>
                    ) : (
                        "Before using Sessions, please read our Terms & Conditions, Privacy Policy, and Cookie Policy (collectively “Terms”)."
                    )}
                </Typography>

                <div>
                    {onboardingTerms.map((t) => (
                        <Accordion
                            expanded={expanded === t.key}
                            onChange={handleChange.bind(null, t.key)}
                            key={t.key}
                            className={classes.termsAccordion}
                        >
                            <AccordionSummary expandIcon={<ArrowForwardIosRoundedIcon />}>
                                <Typography color="primary" fontWeight="bolder">
                                    {t.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ScrollContainer isMobile={isMobile} connector={scrollContainerRef} className={classes.scrollContainer}>
                                    <Typography component="div" color="primary" variant="sm" className={classes.termsBody}>
                                        {t.body}
                                    </Typography>
                                </ScrollContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
                <FormControlLabel
                    control={<Checkbox />}
                    label={<Typography>I agree with the Terms</Typography>}
                    labelPlacement="end"
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    className={classes.termsCheckbox}
                />
                <Button onClick={handleClick} size="large" disabled={!termsAccepted} className={classes.submitBtn}>
                    Start using Sessions
                </Button>
            </div>
            {isMobile ? null : (
                <footer className={cls("flex flex-items-center flex-justify-between", classes.footer)}>
                    <Typography>Copyright ©{new Date().getFullYear()} Sessions. All Rights Reserved</Typography>
                    <Typography component="div" className={"flex flex-items-center flex-justify-between"}>
                        <Link target="_blank" href={getHelpCenterArticleLink("knowledge-base/terms-privacy/privacy-policy")}>
                            Privacy Policy
                        </Link>
                        <div className={classes.footerDot} />
                        <Link target="_blank" href={getHelpCenterArticleLink("knowledge-base/terms-privacy/terms-and-conditions")}>
                            Terms & Conditions
                        </Link>
                    </Typography>
                </footer>
            )}
        </div>
    );
};

export default OnboardingTerms;
