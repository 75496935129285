import {ReactComponent as PinIcon} from "../../../assets/media/pin.svg";
import {ReactComponent as UnPinIcon} from "../../../assets/media/unpin.svg";
import {useSessionView} from "@workhorse/providers/SessionViewProvider";
import {cls} from "@ui/cdk/util";
import IconButton from "@ui/core/components/IconButton";
import classes from "@artifacts/conference/ui/styles/SpeakerActionsContainer.module.scss";

type TogglePinParticipantButtonProps = {
    className?: string;
    participantId: string;
    overrideClasses?: boolean;
};

export function TogglePinParticipantIcon({isPinned}: {isPinned?: boolean}) {
    if (isPinned) {
        return <UnPinIcon />;
    }
    return <PinIcon />;
}

export default function TogglePinParticipantButton(props: TogglePinParticipantButtonProps) {
    const {togglePinnedParticipant, pinnedVideoStreamsMap} = useSessionView();
    const isPinned = pinnedVideoStreamsMap[props.participantId] === true;

    const onClick = () => {
        togglePinnedParticipant(props.participantId);
    };

    const defaultClasses = cls("rnd-btn", classes.button, classes.buttonMoreOptions);

    return (
        <IconButton className={cls(props.overrideClasses !== true && defaultClasses, props.className)} onClick={onClick}>
            <TogglePinParticipantIcon isPinned={isPinned} />
        </IconButton>
    );
}
