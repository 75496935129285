import {collapseWhiteSpace} from "@ui/cdk/util";
import SessionIconBlueRecurrent from "../../assets/media/session-item-blue-recurrent.svg";

import SessionIconRecurrent from "../../assets/media/icon-calendar-item-recurrent.svg";
import SessionIcon from "../../assets/media/icon-calendar-item.svg";

export type ScrollState = Partial<{
    onTop: boolean;
    onBottom: boolean;
    onLeft: boolean;
    onRight: boolean;
    scrollable: boolean;
}>;

export const getSessionResultIconAndTooltip = (
    ownedBySelf: boolean,
    isUserParticipant?: boolean,
    sessionType?: {
        isRecurrent: boolean;
        isBooking: boolean;
        isEvent: boolean;
    }
) => {
    let color = "";
    if (isUserParticipant !== undefined && !isUserParticipant) {
        if (sessionType?.isRecurrent) {
            return {
                color: "gray",
                iconUrl: SessionIconRecurrent,
                tooltip: "This recurrent session is not owned by you and you are not a participant. You can only view the session.",
            };
        } else {
            return {
                color: "gray",
                iconUrl: SessionIcon,
                tooltip: "This session is not owned by you and you are not a participant. You can only view the session.",
            };
        }
    }

    if (sessionType?.isBooking) {
        color = "yellow";
    } else if (sessionType?.isEvent) {
        color = "purple";
    } else {
        color = "blue";
    }

    if (ownedBySelf) {
        if (sessionType?.isRecurrent) {
            return {
                color: color,
                iconUrl: SessionIconRecurrent,
                tooltip: "You published this recurring session",
            };
        } else {
            return {
                color: color,
                iconUrl: SessionIcon,
                tooltip: "You published this session",
            };
        }
    } else {
        if (sessionType?.isRecurrent) {
            return {
                color: color,
                iconUrl: SessionIconRecurrent,
                tooltip: "You were invited to this recurring session",
            };
        } else {
            return {
                color: color,
                iconUrl: SessionIcon,
                tooltip: "You were invited to this session",
            };
        }
    }
};

export const cleanText = (text: string) => {
    return collapseWhiteSpace(text.replaceAll("<em>", "").replaceAll("</em>", ""));
};
