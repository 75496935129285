import {ResourcesTypes} from "@generated/artifacts/resources";
import {makeStyles} from "@material-ui/core";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.main.palette.basic.background,
    },
    displayNone: {
        display: "none",
    },
    cardWrapper: {
        background: theme.main.palette.elevation.surface[9],
        borderRadius: "8px",
        boxShadow: "0px 10px 40px 10px rgba(68, 68, 79, 0.1), inset 0px 1px 2px rgba(255, 255, 255, 0.3)",
        backdropFilder: "blur(30px)",
    },
    descriptionWrapper: {
        width: "281px",
        background: theme.main.palette.basic.background,
        border: `1px solid ${theme.main.palette.cdkPalette.border.senary}`,
        boxSizing: "border-box",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px 20px 15px",
    },
    descriptionText: {
        color: theme.main.palette.text.secondary,
        fontWeight: 500,
    },
    descriptionLabelText: {
        color: theme.main.palette.gray[400],
        textTransform: "uppercase",
        "&.fosTypography": {
            marginBottom: "5px",
        },
    },
    title: {
        color: theme.main.palette.text.secondary,
        fontWeight: 500,
        "&.fosTypography": {
            marginBottom: "20px",
        },
    },
    actionsRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "10px",
    },
    secondaryActionLabel: {
        color: theme.main.palette.blue[500],
        cursor: "pointer",
    },
    primaryActionButton: {
        "&.fosButton": {
            color: theme.main.palette.basic.background,
            background: theme.main.palette.blue[500],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px 15px",
            borderRadius: "8px",
            fontSize: theme.typography.pxToRem(11),
            fontWeight: 600,
            lineHeight: "13px",
        },
    },
    buttonIcon: {
        marginRight: "5px",
        width: "16px",
        height: "16px",
    },
}));

type ExternalGenericFormProps = {
    type: ResourcesTypes;
    handleClose: () => void;
    artifactId: string;
    title: string;
    primaryActionLabel: string;
    secondaryActionLabel?: string;
    description: string;
    primaryAction: () => void;
    secondaryAction?: () => void;
};

const ExternalGenericCreateForm = (props: ExternalGenericFormProps) => {
    const classes = useStyles();

    const {title, description, primaryActionLabel, secondaryActionLabel, primaryAction, secondaryAction} = props;

    return (
        <div>
            <div>
                <Typography variant="sm" className={classes.title}>
                    {title}
                </Typography>
            </div>
            <div className={classes.descriptionWrapper}>
                <Typography variant="sm" className={classes.descriptionLabelText}>
                    How to:
                </Typography>
                <Typography variant="sm" className={classes.descriptionText}>
                    {description}
                </Typography>
                <div className={classes.actionsRow}>
                    {secondaryAction && (
                        <Typography variant="xs" className={classes.secondaryActionLabel} onClick={secondaryAction}>
                            {secondaryActionLabel}
                        </Typography>
                    )}
                    <Button className={classes.primaryActionButton} variant="primary" onClick={primaryAction}>
                        <OpenInNewRoundedIcon className={classes.buttonIcon} />
                        {primaryActionLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default ExternalGenericCreateForm;
