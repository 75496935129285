import clientEvents from "@api/events/client";
import services from "@api/service/client";
import descriptor, {ArtifactTag} from "@generated/artifacts";
import {SpecifiersTypeMap} from "@generated/artifacts/resources/specifiers";
import {
    AgendaItemType,
    ChooseToolStep,
    ConfigurationConfirmType,
    ConfigurationStep,
    DrawerState,
    ResourceProcessingStatus,
} from "@generated/data";
import env from "@generated/environment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@ui/cdk/Button";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import designer, {DUMMY_AGENDA_ITEM_TITLE} from "@workhorse/api/designer";
import {getMaxSessionOrder, makeId} from "@workhorse/api/designer/lib/utils";
import {useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useResourceResult} from "@workhorse/api/resx/hooks";
import ErrorBoundary from "@workhorse/components/ErrorBoundary";
import Loading from "@workhorse/components/Loading";
import {WithMobileState} from "@workhorse/declarations";
import {DesignerApiSession, SessionData} from "@workhorse/declarations/dataTypes";
import {useDesignerSessionState} from "@workhorse/providers/DesignerSessionDataProviders";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {readSession, useAgendaItems, useMacroArtifacts, useSession} from "@workhorse/providers/SessionDataProviders";
import useDesignerArtifacts, {ForgedArtifact} from "../api/useDesignerArtifacts";
import DesignerGhostRenderer from "../DesignerGhostRenderer";
import DesignerToolHeader from "./components/DesignerToolHeader";
import DesignerToolPopoverHeader from "./components/DesignerToolPopoverHeader";
import DesignerToolPreviewHeader from "./components/DesignerToolPreviewHeader";
import DesignerToolSelector from "./components/DesignerToolSelector";
import DesignerToolManager from "./components/DesingerToolManager";
import DesignerToolPlaceholderSkeleton from "./components/skeletons/DesignerToolPlaceholderSkeleton";
import classes from "./style/DesignerToolPlaceholder.module.scss";
import {writeFromFullSession} from "@workhorse/api/apolloFieldResolvers/apolloFullSessionManipulator";
import {useDrawerLeftToggler} from "@workhorse/providers/state";

type DesignerToolPlaceholderWithSessionProps = DesignerToolPlaceholderProps & {
    session: SessionData;
};

type DesignerToolPlaceholderProps = {
    className?: string;
    currentSessionDetails: SessionData;
    inDialog?: boolean;
    onClose?: () => void;
    onConfirm?: (doNotCommit?: boolean) => void;
    readOnly?: boolean;
    handleHidePopover?: () => void;
    inPopover?: boolean;
    handleCloseWithoutTogglingEditMode?: () => void;
    useConfigForBorPreview?: boolean;
} & WithMobileState;

// TODO: rename and move this component
function DesignerToolPlaceholder(props: DesignerToolPlaceholderWithSessionProps) {
    const {
        className,
        inDialog,
        onClose,
        readOnly: propsReadOnly,
        mobileState,
        onConfirm,
        inPopover,
        handleHidePopover,
        handleCloseWithoutTogglingEditMode,
    } = props;
    const currentSessionDetails = (props.session ?? undefined) as DesignerApiSession["session"];
    const agendaItems = useAgendaItems();
    const macroArtifacts = useMacroArtifacts();

    const {isMobile} = mobileState;
    const hostType = useHostType();
    const {isPortrait} = useDeviceOrientation();

    const [_, toggleLeftDrawer] = useDrawerLeftToggler();

    const [editState, setEditState] = useDesignerSessionState();

    const {
        selectedMicroIdToConfig,
        selectedMacroIdToConfig,
        selectedMicroIdToPreview,
        selectedMacroIdToPreview,
        selectedResourceId,
        sessionId: currentSessionId,
        selectedAgendaItemId,
        toolStep,
        configurationStep,
    } = editState;

    const [micro = {} as ForgedArtifact, ghosts] = useDesignerArtifacts({
        session: currentSessionDetails,
        agendaItems: (agendaItems ?? []) as DesignerApiSession["agendaItems"],
        macroArtifacts: (macroArtifacts ?? []) as DesignerApiSession["macroArtifacts"],
        selectedAgendaItemId,
        selectedMicroIdToConfig,
        selectedMicroIdToPreview,
    });

    const currentAgendaItem = agendaItems.find((a) => a.id === selectedAgendaItemId);
    const currentArtifact = currentAgendaItem?.artifact;

    const currentPreview =
        props.useConfigForBorPreview && micro.artifactId === "flowos/breakout" ? micro.components : micro.previewComponents;
    const currentHeader = micro.headerComponents;
    const currentConfig = micro.components;
    const isAgendaLessSession = agendaItems.filter((a) => !a.isDeleted).findIndex((a) => a.type === AgendaItemType.Planned) === -1;

    const {result} = useResourceResult(micro.resourceResults?.[0]?.id);
    const resource = result?.resource;

    const previewOnly = (!inPopover && configurationStep === ConfigurationStep.Preview) || propsReadOnly;
    const readOnly = propsReadOnly || previewOnly;
    const previousToolRef = useRef(toolStep);

    // TODO: maybe nicer arguments
    const handleArtifactSelect = (artifactTag: ArtifactTag, myProductToolId?: string) => {
        const order = getMaxSessionOrder() + 1;
        const currentAgendaItemId =
            inPopover && isAgendaLessSession
                ? designer.api.agendaItem.create({
                      ...designer.constants.agendaItemDefaults,
                      type: AgendaItemType.Instant,
                      order,
                      doNotUpdateCreatePls: true,
                  })
                : selectedAgendaItemId ?? agendaItems[0].id;

        if (inPopover && isAgendaLessSession) {
            designer.api.session.update({
                order,
            });
        }

        if (inPopover && isAgendaLessSession && selectedAgendaItemId !== currentAgendaItemId) {
            setEditState({
                selectedAgendaItemId: currentAgendaItemId,
            });
        }

        const currentArtifactDescriptor = descriptor[artifactTag as ArtifactTag];

        const selectedArtifactHasConfig =
            artifactTag &&
            descriptor[artifactTag].entries.some(
                (entry) => entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/config"]
            );

        if (!selectedArtifactHasConfig && inPopover) {
            handleHidePopover?.();
        }

        const isConfiguredByDefault = currentArtifactDescriptor?.meta?.labels?.["artifacts.sessions.flowos.com/configured-by-default"];
        if (currentAgendaItemId) {
            const addedMicro = designer.api.agendaItem.addArtifact({
                agendaItemId: currentAgendaItemId,
                // addDefaultArtifact: isConfiguredByDefault,
                overrides: {
                    artifactId: currentArtifactDescriptor.tag as ArtifactTag,
                    description: currentArtifactDescriptor.meta.description,
                    name: currentArtifactDescriptor.meta.displayName,
                    isConfigured: isConfiguredByDefault ? true : false,
                    properties: myProductToolId
                        ? [
                              {
                                  __typename: "Property",
                                  id: makeId(),
                                  key: "myProductToolId",
                                  value: myProductToolId,
                                  createdAt: null as unknown as Date,
                                  updatedAt: null as unknown as Date,
                                  isDeleted: null,
                                  oldId: null,
                                  update: null,
                              },
                          ]
                        : undefined,
                },
            });

            previousToolRef.current = toolStep;

            if (selectedArtifactHasConfig) {
                setEditState({
                    selectedMicroIdToConfig: addedMicro,
                    selectedMicroIdToPreview: null,

                    toolStep: ChooseToolStep.Config,
                    selectedAgendaItemId: currentAgendaItemId,
                    selectedResourceId: null,
                });
            } else {
                setEditState({
                    selectedMicroIdToPreview: addedMicro,
                    selectedMicroIdToConfig: null,
                    selectedResourceId: null,
                    toolStep: ChooseToolStep.Preview,
                    selectedAgendaItemId: currentAgendaItemId,
                });
            }
        }
    };

    const agendaIconSrc = `${env.publicResourcesCdnRoot ?? ""}/icons/agenda-enabled.svg`;

    const currentStep = toolStep;
    const previewArtifactId = selectedMicroIdToPreview || selectedMacroIdToPreview;
    const configArtifactId = selectedMicroIdToConfig || selectedMacroIdToConfig;
    const agendaItemId = selectedAgendaItemId ?? agendaItems?.[0]?.id;
    const currentMicro = micro
        ? micro
        : ChooseToolStep.Config && currentConfig && configArtifactId
        ? agendaItems.find((a) => a.artifact?.id === configArtifactId)?.artifact
        : ChooseToolStep.Preview && currentPreview && previewArtifactId
        ? agendaItems.find((a) => a.artifact?.id === previewArtifactId)?.artifact
        : null;

    useEffect(() => {
        if (isMobile && !isPortrait && (inPopover || inDialog) && onClose) {
            onClose();
        }
    }, [isMobile, isPortrait, inPopover, inDialog, onClose]);

    const goToSelect = () => {
        const isDefaultArtifact =
            currentAgendaItem?.title === DUMMY_AGENDA_ITEM_TITLE && currentArtifact?.artifactId === "flowos/conference";

        const isCreated = currentArtifact?.createdAt != null;

        if (inPopover) {
            setEditState({
                toolStep: isDefaultArtifact || !isCreated ? ChooseToolStep.SelectTool : ChooseToolStep.ManageTool,
            });
        } else {
            setEditState({
                toolStep: ChooseToolStep.SelectTool,
            });
        }
    };

    const goToConfig = () => {
        const artifactTag = agendaItems.find((a) => a.id === selectedAgendaItemId)?.artifact?.artifactId;

        const selectedArtifactHasConfig =
            artifactTag &&
            descriptor[artifactTag as ArtifactTag].entries.some(
                (entry) => entry.preloadCondition.includes("host.isDesigner") && entry.labels["artifacts.sessions.flowos.com/config"]
            );

        if (selectedArtifactHasConfig) {
            if (!isMobile) {
                previousToolRef.current = toolStep;
            }
            setEditState({
                toolStep: ChooseToolStep.Config,
                selectedMicroIdToConfig: selectedMicroIdToPreview,
                selectedMicroIdToPreview: null,
            });
        } else {
            setEditState({
                toolStep: ChooseToolStep.SelectTool,
            });
        }
    };

    // TODO mihai macaneata: make it more generic
    const onConfirmPreview = () => {
        clientEvents.emit("config-process-confirmed", micro.id);

        if (resource?.id) {
            services.resx.updateResourceUpdatedAt(resource.id, resource.type as keyof SpecifiersTypeMap);
        }

        onConfirm ? onConfirm() : onClose?.();

        setEditState({
            toolStep: null,
            selectedMicroIdToPreview: null,
            selectedMicroIdToConfig: null,
        });
    };

    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        if (
            inPopover &&
            !isMobile &&
            currentStep === ChooseToolStep.Preview &&
            micro.isConfigured &&
            !confirmed &&
            (resource?.processingStatus
                ? [ResourceProcessingStatus.NotRequired, ResourceProcessingStatus.Finished].includes(resource.processingStatus)
                : true)
        ) {
            console.log("here");
            handleHidePopover?.();

            setEditState({
                toolStep: null,
                configurationConfirm: ConfigurationConfirmType.Tool,
            });
            setConfirmed(true);
            if (selectedAgendaItemId) {
                designer.api.agendaItem.update({
                    id: selectedAgendaItemId,
                    agendaItem: {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                });
                designer.api.artifact.updateArtifact({
                    id: micro.id,
                    mockResult: true,
                    artifact: {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                });
            }
            const existingSession = readSession({sessionId: currentSessionId});
            if (existingSession) {
                writeFromFullSession(currentSessionId, {
                    session: {
                        ...existingSession,
                        order: (existingSession?.order ?? 0) + 1,
                    },
                });
            }
            onConfirm?.();
        }
    }, [currentStep, inPopover, isMobile, micro, onConfirm, confirmed, resource, selectedAgendaItemId]);

    const onConfirmConfig = () => {
        const itemToClick = document.querySelector(".resx-table-item-selected");

        if (!itemToClick) {
            if ((micro.artifactId as ArtifactTag) === "flowos/breakout") {
                setEditState({
                    selectedMicroIdToPreview: micro.id,
                    toolStep: ChooseToolStep.Preview,
                });
                return;
            }
            onConfirmPreview();
            return;
        }

        const event = new MouseEvent("dblclick", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        if (itemToClick) {
            itemToClick.dispatchEvent(event);
        }
    };

    const artifact = agendaItems.find((a) => a.id === selectedAgendaItemId)?.artifact;
    const artifactId = artifact?.id;
    const artifactTag = artifact?.artifactId;

    const onAttachAgenda = () => {
        designer.api.session.detachAgenda({
            type: AgendaItemType.Planned,
        });

        const agendaArtifactId = designer.api.artifact.addMacroArtifact({
            artifactName: "flowos/agenda",
            artifactTag: "flowos/agenda" as ArtifactTag,
        });

        setEditState({
            configurationStep: ConfigurationStep.Import,
        });

        handleCloseWithoutTogglingEditMode?.();
        toggleLeftDrawer(DrawerState.FullyOpen), "DesignerToolPlaceholder:410";
    };

    // TODO - maca - reimplement this
    useEffect(() => {
        if (
            currentStep === null ||
            (inPopover &&
                !isMobile &&
                ((currentStep === ChooseToolStep.Preview &&
                    !(
                        resource?.processingStatus &&
                        [ResourceProcessingStatus.Started, ResourceProcessingStatus.Queued, ResourceProcessingStatus.Required].includes(
                            resource?.processingStatus
                        )
                    )) ||
                    currentStep === null))
        ) {
            handleHidePopover?.();
        }
    }, [currentStep, inPopover, isMobile]);

    useEffect(() => {
        // In case some external update sets conference for the current agendaitem, all states need to be reset
        if (
            artifactTag === "flowos/conference" &&
            (currentStep !== ChooseToolStep.SelectTool || configurationStep === ConfigurationStep.Preview)
        ) {
            designer.state.update({
                selectedMicroIdToConfig: null,
                selectedMicroIdToPreview: null,
                selectedResourceId: null,
                toolStep: ChooseToolStep.SelectTool,
                configurationStep: ConfigurationStep.Edit,
            });
        }
    }, [artifactTag, currentStep, configurationStep]);

    return (
        <div className={cls("flex flex-col flex00-100", classes.root, inDialog && "p-0", (inPopover || isMobile) && classes.inPopover)}>
            <div
                className={cls(
                    "flex flex-col flex00-100",
                    currentStep && classes.selectToolWrapper,
                    currentStep === ChooseToolStep.ManageTool && "with-existing-tool"
                )}
            >
                {isMobile ? (
                    currentStep && currentStep !== ChooseToolStep.SelectTool
                ) : !previewOnly && !inPopover ? (
                    <DesignerToolHeader
                        key={"designer-tool-header" + configArtifactId ?? previewArtifactId ?? ""}
                        readOnly={readOnly}
                        inDialog={inDialog}
                        artifactId={artifactId ?? ""}
                        sessionId={currentSessionDetails?.id ?? ""}
                        mobileState={props.mobileState}
                        resultId={micro.resourceResults?.[0]?.id}
                    />
                ) : null}

                {previewOnly ? (
                    <DesignerToolPreviewHeader
                        sessionId={currentSessionId}
                        currentArtifact={currentArtifact}
                        artifactTag={artifactTag as ArtifactTag}
                    />
                ) : null}

                {!previewOnly && currentStep && (inPopover || isMobile) && (currentStep !== ChooseToolStep.Preview || isMobile) ? (
                    <DesignerToolPopoverHeader
                        currentArtifact={currentArtifact}
                        artifactTag={artifactTag as ArtifactTag}
                        isMobile={isMobile}
                        currentStep={currentStep}
                        onClose={onClose}
                        sessionId={currentSessionId}
                    />
                ) : null}

                {currentStep === ChooseToolStep.SelectTool ? (
                    <DesignerToolSelector
                        key={"designer-tool-selector" + previewArtifactId ?? configArtifactId ?? ""}
                        artifactId={previewArtifactId ?? configArtifactId ?? ""}
                        macroArtifacts={macroArtifacts}
                        childOfBreakoutRooms={currentSessionDetails?.childOfBreakoutRooms}
                        inPopover={inPopover || isMobile}
                        agendaItemId={agendaItemId ?? undefined}
                        onArtifactSelect={handleArtifactSelect}
                        onClose={onClose}
                    />
                ) : null}

                {currentStep === ChooseToolStep.ManageTool && agendaItemId && onConfirm ? (
                    <DesignerToolManager
                        onConfirm={onConfirm}
                        agendaItemId={agendaItemId}
                        agendaItems={agendaItems}
                        currentArtifact={currentArtifact}
                        sessionId={currentSessionId}
                    />
                ) : null}

                {currentStep === ChooseToolStep.Config && currentConfig && configArtifactId ? (
                    <div className={cls(className, classes.previewWrapper, inPopover && classes.inPopover)}>
                        {(currentConfig || []).map((MicroLoader, i) => (
                            <ErrorBoundary
                                key={"micro-" + currentMicro?.id + "-loader-" + i + currentSessionDetails?.id}
                                mobileState={mobileState}
                                location="DesignerMicroLoader"
                            >
                                <MicroLoader
                                    agendaItem={currentAgendaItem}
                                    isAdmin={true}
                                    isAssistant={false}
                                    inPopover={inPopover || isMobile}
                                    mobileState={mobileState}
                                    sessionId={currentSessionId}
                                    sessionEditState={editState}
                                    hostType={hostType}
                                    key={"micro-loader-config-" + currentMicro?.id + "-" + currentSessionDetails?.id}
                                />
                            </ErrorBoundary>
                        ))}
                    </div>
                ) : null}

                {currentStep === ChooseToolStep.Preview && currentPreview && previewArtifactId && (!inPopover || isMobile) ? (
                    // ||
                    // (inPopover &&
                    //     [ResourceProcessingStatus.Started, ResourceProcessingStatus.Queued, ResourceProcessingStatus.Required].includes(
                    //         resource?.processingStatus
                    //     )) ? (
                    <div className={cls(className, classes.previewWrapper, previewOnly && classes.previewOnly)}>
                        {(currentPreview || []).map((MicroLoader, i) => (
                            <ErrorBoundary
                                key={"micro-" + currentMicro?.id + "-loader-" + i + currentSessionDetails?.id}
                                mobileState={mobileState}
                                location="DesignerMicroLoader"
                            >
                                <MicroLoader
                                    agendaItem={currentAgendaItem}
                                    isAdmin={true}
                                    isAssistant={false}
                                    inPopover={inPopover || isMobile}
                                    sessionEditState={editState}
                                    sessionId={currentSessionId}
                                    mobileState={mobileState}
                                    hostType={hostType}
                                    key={"micro-loader-preview-" + currentMicro?.id + "-" + currentSessionDetails?.id}
                                />
                            </ErrorBoundary>
                        ))}
                    </div>
                ) : null}

                {currentStep === null ||
                (inPopover &&
                    !isMobile &&
                    ((currentStep === ChooseToolStep.Preview &&
                        !(
                            resource?.processingStatus &&
                            [ResourceProcessingStatus.Started, ResourceProcessingStatus.Queued, ResourceProcessingStatus.Required].includes(
                                resource?.processingStatus
                            )
                        )) ||
                        currentStep === null)) ? (
                    <div className={cls(className, classes.previewWrapper, "flex flex-center-all")}>
                        <Loading />
                    </div>
                ) : null}

                {toolStep === ChooseToolStep.Config && !readOnly ? (
                    <DialogFooter
                        className={cls(
                            classes.dialogFooter,
                            classes.sticky,
                            micro.artifactId === "flowos/breakout" && classes.dialogFooterWithConfirmiation
                        )}
                    >
                        <Button data-id="config-back-button" variant="tertiary" onClick={goToSelect}>
                            Back
                        </Button>
                        {isMobile && micro.artifactId !== "flowos/breakout" ? null : (
                            <Button
                                withMarginLeft
                                onClick={onConfirmConfig}
                                disabled={!!!selectedResourceId}
                                data-id="config-confirm-button"
                            >
                                Confirm
                            </Button>
                        )}
                    </DialogFooter>
                ) : null}
                {toolStep === ChooseToolStep.Preview && !readOnly && (!inPopover || isMobile) ? (
                    <DialogFooter className={cls(classes.dialogFooter, classes.dialogFooterPreview)}>
                        <Button data-id="preview-back-button" variant="tertiary" onClick={goToConfig}>
                            Back
                        </Button>
                        <Button withMarginLeft onClick={onConfirmPreview} data-id="preview-confirm-button">
                            {isMobile ? "Save" : "Confirm"}
                        </Button>
                    </DialogFooter>
                ) : null}

                {toolStep === ChooseToolStep.SelectTool && inPopover ? (
                    <DialogFooter className={cls(classes.inPopoverFooter, classes.dialogFooter)}>
                        <div
                            data-id="add-agenda-player"
                            className={cls("flex flex-justify-between", classes.toolsInfo)}
                            onClick={onAttachAgenda}
                        >
                            <div className="flex">
                                <img className={classes.agendaImage} src={agendaIconSrc}></img>

                                <div className="flex flex-col">
                                    <Typography variant="lg" color="primary" fontWeight="boldest" className="flex11-100">
                                        Add an agenda to your session
                                    </Typography>

                                    <Typography color="quaternary">And attach multiple tools into your agenda items</Typography>
                                </div>
                            </div>
                            <div className="flex flex-align-center">
                                <ArrowForwardIcon className={classes.arrowIcon} />
                            </div>
                        </div>
                    </DialogFooter>
                ) : null}
            </div>
            <DesignerGhostRenderer inPopover={inPopover} ghosts={ghosts} />
        </div>
    );
}

const DesignerToolPlaceholderSessionProvider = (props: DesignerToolPlaceholderProps) => {
    const designerSession = useSession();

    const [mounted, setMounted] = useState(props.inPopover ? false : true);

    useEffect(() => {
        if (props.inPopover && !mounted) {
            setMounted(true);
        }
    }, []);

    return designerSession && mounted ? (
        <DesignerToolPlaceholder {...props} session={designerSession} />
    ) : props.inPopover ? (
        <DesignerToolPlaceholderSkeleton />
    ) : (
        <>
            <Loading />
        </>
    );
};

export default DesignerToolPlaceholderSessionProvider;
