import env from "@generated/environment";
import {GetCentrifugoTokenDocument} from "@sessions/common/generated/data-types";
import {Centrifuge, TransportEndpoint} from "centrifuge";
import SockJS from "sockjs-client";
import apollo from "./apollo";

export class CentrifugeService {
    private static instance: CentrifugeService;
    public centrifuge: Centrifuge;

    private constructor(token: string) {
        const transports: TransportEndpoint[] = [
            {
                transport: "websocket",
                endpoint: `${env.centrifugalUrl || "https://live-service.sessions.flowos.dev"}/connection/websocket`,
            },
            {
                transport: "sockjs",
                endpoint: `${env.centrifugalUrl || "https://live-service.sessions.flowos.dev"}/connection/sockjs`,
            },
        ];

        this.centrifuge = new Centrifuge(transports, {
            sockjs: SockJS,
            token,
            getToken: async () => {
                const res = await apollo.client.query({
                    query: GetCentrifugoTokenDocument,
                    fetchPolicy: "network-only",
                });

                return res.data.getCentrifugoToken;
            },
        });

        this.centrifuge
            .on("connected", (ctx) => {
                console.info(`CENTRIFUGE: connected over ${ctx.transport}`);
            })
            .on("disconnected", (ctx) => {
                console.warn(`CENTRIFUGE: disconnected: ${ctx.code}, ${ctx.reason}`);
            })
            .on("error", (ctx) => {
                console.error(`CENTRIFUGE: error: ${JSON.stringify(ctx, null, 2)}`);
            })
            .connect();
    }

    public static getInstance(token: string): CentrifugeService {
        if (!CentrifugeService.instance) {
            CentrifugeService.instance = new CentrifugeService(token);
        }

        return CentrifugeService.instance;
    }
}
