import {dateFormat} from "@ui/cdk/DateFormat";
import TableCell from "@ui/cdk/Table/TableCell";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/RoomsTableCell.module.scss";
import Time from "@ui/cdk/Time";
import Button from "@ui/cdk/Button";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import Tooltip from "@ui/cdk/Tooltip";
import {useHistory} from "@workhorse/api/routing";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "@workhorse/api/toast";
import {SessionSource, CalendarProvider} from "@generated/data";
import {CalendarIcon} from "@workhorse/api/calendar";

type RoomsTableCellProps = {
    value: string | boolean;
    type: string;
    imageUrl?: string;
    isRoomActive?: boolean;
    halfDayClock?: boolean;
    source?: SessionSource;
};

function RoomsTableCell(props: RoomsTableCellProps) {
    const {type, value, isRoomActive, halfDayClock, source} = props;
    const history = useHistory();

    switch (type) {
        case "string":
        default:
            return (
                <TableCell className={classes.root}>
                    <p>{value}</p>
                </TableCell>
            );
        case "date": {
            return (
                <TableCell
                    size="large"
                    className={classes.root}
                    // data-private
                >
                    {dateFormat(value as string, ["weekday", {sep: ","}, "monthShort", "dayOfMonth", "year"])}
                    {" - "}
                    <Time time={value as string} isHalfDayClock={halfDayClock} />
                </TableCell>
            );
        }
        case "title":
            return (
                <TableCell className={classes.root} size="large">
                    <div className="flex flex-align-center" data-id="rooms-entry-room-name">
                        <Typography
                            fontWeight="boldest"
                            color="primary"
                            component="p"
                            // data-private
                            noWrap
                            className="flex flex-items-center"
                        >
                            {source === SessionSource.GoogleWorkspace ? <CalendarIcon className="mr-4" type={CalendarProvider.Google} width={20} height={18} /> : ""}
                            {value}
                        </Typography>
                    </div>
                </TableCell>
            );
        case "roomState": {
            let statusColor = "gray";
            switch (value) {
                case false: {
                    statusColor = "gray";
                    break;
                }

                case true: {
                    statusColor = "green";
                    break;
                }

                default: {
                    break;
                }
            }

            return (
                <TableCell className={cls(classes.root, classes.typeStatus)} size="unset">
                    <div data-id="rooms-entry-status" className={cls(classes.status, statusColor)}>
                        {!value ? "Inactive" : "Active"}
                    </div>
                </TableCell>
            );
        }
        case "copyLink": {
            const handleCopyLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>) => {
                event.preventDefault();
                event.stopPropagation();

                toast("The link was copied successfully!", {
                    type: "success",
                    position: "top",
                    duration: 3000,
                });
            };

            return (
                <TableCell className={classes.root} size="medium">
                    <CopyToClipboard text={value as string}>
                        <Button aria-label={"Copy room link"} variant="plain" onClick={handleCopyLink} data-id="rooms-entry-copy-link">
                            <Tooltip title="Copy room link" placement="top" arrow>
                                <LinkRoundedIcon className={classes.copyLinkIcon} />
                            </Tooltip>
                        </Button>
                    </CopyToClipboard>
                </TableCell>
            );
        }
        case "enterRoom": {
            const handleEnterRoom = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                event.preventDefault();
                event.stopPropagation();

                history.push({
                    pathname: `/room/${value}`,
                });
            };

            return (
                <TableCell className={classes.root} size="unset">
                    {isRoomActive ? (
                        <div className={classes.enterRoomButton} onClick={handleEnterRoom} data-id="rooms-entry-enter-room">
                            Enter room
                        </div>
                    ) : (
                        <div></div>
                    )}
                </TableCell>
            );
        }
    }
}

export default RoomsTableCell;
