import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import classes from "./style/SearchResultItem.module.scss";
import Tooltip from "@ui/cdk/Tooltip";

export type SearchResultItemProps = {
    title: JSX.Element;
    description?: JSX.Element;

    link: string;
    highlight: JSX.Element;
    foundByName: boolean;

    iconUrl?: string;
    color?: string;
    tooltip?: string;
};

const SearchResultItem = ({title, description, link, iconUrl, highlight, foundByName, tooltip, color}: SearchResultItemProps) => {
    return (
        <Link href={link} className={cls("flex flex-justify-between flex-align-center", classes.resultItemContainer)} role="listitem">
            <div className={cls("flex flex-align-center", classes.resultItem)}>
                <Tooltip title={tooltip ?? ""} arrow placement="right">
                    <div className={cls(classes.resultIcon, color)}>{iconUrl ? <img src={iconUrl}></img> : null}</div>
                </Tooltip>
                <div className="overflow-hidden">
                    <div className="flex flex-align-center">
                        <div className={classes.resultItemTitle}>{title}</div>
                        {foundByName === false ? <div className={classes.resultItemSubtitle}>{highlight}</div> : null}
                    </div>
                    <Typography className={classes.resultItemDescription}>{description}</Typography>
                </div>
            </div>
        </Link>
    );
};

export default SearchResultItem;
